import useAggregateGraphData from 'components/domain/dashboard/dashboard-card-chart/hooks/useAggregateGraphData';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useApolloPaging } from 'hooks';
import { costCodeEquipmentMetricQueryProvider } from 'hooks/aggregates/metrics/CostCodeMetricQueryProviders';
import { memberEquipmentMetricQueryProvider } from 'hooks/aggregates/metrics/MemberMetricQueryProviders';
import { projectEquipmentMetricQueryProvider } from 'hooks/aggregates/metrics/ProjectMetricQueryProviders';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeRange from 'types/TimeRange';
import { ReportType } from 'utils/aggregateUtils';

export default function useEquipmentMetricCardData(
  timeRange: ITimeRange<DateTime>,
  timeFrame: DashboardTimeFrame,
  reportType: ReportType,
  permissions: MemberPermission,
  executeImmediately: boolean,
  equipmentId?: string | null,
  memberId?: string | null,
  projectId?: string | null,
  costCodeId?: string | null
) {
  const { getAll } = useApolloPaging();

  const queryProvider = useMemo(() => {
    if (!isNil(projectId)) {
      return projectEquipmentMetricQueryProvider(getAll, [projectId], undefined, 'unarchived', true);
    } else if (!isNil(costCodeId)) {
      return costCodeEquipmentMetricQueryProvider(getAll, [costCodeId], undefined, 'unarchived', true);
    } else {
      return memberEquipmentMetricQueryProvider(
        getAll,
        permissions,
        !isNil(memberId) ? [memberId] : undefined,
        !isNil(equipmentId) ? [equipmentId] : undefined,
        undefined,
        'unarchived',
        reportType === ReportType.SECONDS
      );
    }
  }, [equipmentId, reportType, permissions, getAll, memberId, projectId, costCodeId]);

  return useAggregateGraphData(timeRange, timeFrame, reportType, queryProvider, executeImmediately);
}
