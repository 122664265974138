import { Align, Button, Justify, Row, Tray } from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Panel, Spinner } from 'components';
import LocationHistoryDialog from 'components/domain/location/LocationHistory/LocationHistoryDialog';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import TimeEntryLogsView from 'components/domain/time-entry-logs/TimeEntryLogs/TimeEntryLogsView';
import TimeEntryConflictDialog from 'components/domain/time-entry/TimeEntryConflictDialog/TimeEntryConflictDialog';
import TimeEntryView from 'components/domain/time-entry/TimeEntryView/TimeEntryView';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import TimeOffView from 'components/domain/time-off/TimeOffView/TimeOffView';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import DeleteTimeEntryOrTimeOffDialog from 'components/domain/time/DeleteTimeEntryOrTimeOffDialog/DeleteTimeEntryOrTimeOffDialog';
import TimesheetPrintHeader from 'containers/timesheets/TimesheetPrintHeader/TimesheetPrintHeader';
import TimesheetPrintSignature from 'containers/timesheets/TimesheetPrintSignature/TimesheetPrintSignature';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment, ReactNode, useCallback, useMemo, useReducer, useRef, useState } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import { numberOfDaysBetween } from 'utils/dateUtils';
import { t } from 'utils/localize';
import TimeEntryDataTable, { ITimeEntryDataTableRow } from '../TimeEntryDataTable/TimeEntryDataTable';
import TimeEntrySplitDialog from '../TimeEntrySplitDialog/TimeEntrySplitDialog';
import './TimeEntryDataReport.scss';

export interface ITimeEntryDataReportData {
  id: string;
  rows: ITimeEntryDataTableRow[];
  hasSignature: boolean;
}

export interface ITimeEntryDataReportProps<T extends ITimeEntryDataReportData = ITimeEntryDataReportData> {
  className?: ClassName;
  loading?: boolean;
  loadedAll?: boolean;
  data: T[];
  selectedIds: string[];
  columnSettings: IVisibleTableColumn[];
  timeRange: ITimeRange<DateTime>;
  emptyStateMessage?: string;
  setRef?: (element: HTMLElement | null) => void;
  onDataChange?: (memberIds: string[]) => void;
  onSelectChange?: (rows: ITimeEntryDataTableRow[]) => void;
  renderHeader?: (item: T) => ReactNode;
  applyPrintOptions: boolean;
  showSelectAll?: boolean;
  initialSortKey?: keyof ITimeEntryDataTableRow;
  rowHeight?: TableCellHeight;
}

interface IReducerState {
  row: ITimeEntryDataTableRow | null;
}

const initialState = {
  row: null,
};

type Action = { type: 'CLEAR' } | { type: 'SET_IDS'; payload: IReducerState };

function reducer(state: IReducerState, action: Action): IReducerState {
  if (action.type === 'CLEAR') {
    return initialState;
  } else if (action.type === 'SET_IDS') {
    return action.payload;
  }

  return state;
}

const TimeEntryDataReport = <T extends ITimeEntryDataReportData = ITimeEntryDataReportData>({
  className,
  loading,
  loadedAll,
  data,
  columnSettings,
  selectedIds,
  timeRange,
  emptyStateMessage,
  setRef,
  onDataChange,
  onSelectChange,
  renderHeader,
  applyPrintOptions,
  showSelectAll,
  initialSortKey,
  rowHeight,
}: ITimeEntryDataReportProps<T>) => {
  const [{ row }, dispatch] = useReducer(reducer, initialState);
  const memberId = row?.member?.id;
  const rowId = row?.id;

  const clear = useCallback(() => {
    dispatch({ type: 'CLEAR' });
  }, [dispatch]);

  const memberSettings = useMemberSettings();
  const selectedRow = useRef<ITimeEntryDataTableRow | null>(null);
  const timeOffOpenable = useOpenable();
  const timeOffId = useRef<string | null>(null);
  const openToast = useToastOpen();
  const deleteTimeOffOpenable = useOpenable();
  const openableDetails = useRef({ onClose: clear });
  const timeEntryDialogOpenable = useOpenable(openableDetails.current);
  const deleteTimeEntryDialogOpenable = useOpenable(openableDetails.current);
  const conflictDialogOpenable = useOpenable(openableDetails.current);
  const timeEntryViewOpenable = useOpenable(openableDetails.current);
  const timeEntryLogViewOpenable = useOpenable(openableDetails.current);
  const lockOpenable = useOpenable(openableDetails.current);
  const locationHistoryOpenable = useOpenable(openableDetails.current);
  const timeEntryConflictOpenable = useOpenable(openableDetails.current);
  const timeOffViewDialogDetails = useOpenable();
  const splitTimeEntryOpenable = useOpenable();
  const signatureValidationDetails = useOpenable();
  const nameFormatter = useEmployeeNameFormatter();

  const dialogMemberIds = useMemo(() => (memberId ? [memberId] : []), [memberId]);
  const timeIds = useMemo(() => (rowId ? [rowId] : []), [rowId]);

  const [newLockDate, setNewLockDate] = useState<DateTime>(DateTime.local());
  const [isSplit, setIsSplit] = useState<boolean>(false);
  const [splitHasLockAndSignatureViolation, setSplitHasLockAndSignatureViolation] = useState<boolean>(false);

  const printOptions = memberSettings?.web?.features?.timeCards?.printOptions;

  const setRowData = useCallback(
    (row: ITimeEntryDataTableRow) => {
      dispatch({ type: 'SET_IDS', payload: { row: row } });
    },
    [dispatch]
  );

  const onTimeEntryFormDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      timeEntryDialogOpenable.open();
    },
    [setRowData, timeEntryDialogOpenable]
  );

  const onDeleteTimeEntryFormDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      deleteTimeEntryDialogOpenable.open();
    },
    [setRowData, deleteTimeEntryDialogOpenable]
  );

  const onTimeEntryConflictDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      conflictDialogOpenable.open();
    },
    [conflictDialogOpenable, setRowData]
  );

  const onTimeEntryViewDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      timeEntryViewOpenable.open();
    },
    [setRowData, timeEntryViewOpenable]
  );

  const onLogViewDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      timeEntryLogViewOpenable.open();
    },
    [setRowData, timeEntryLogViewOpenable]
  );

  const onLocationHistoryOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      locationHistoryOpenable.open();
    },
    [locationHistoryOpenable, setRowData]
  );

  const onLockDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow, isSplit: boolean = false, hasSignatureViolation: boolean = false) => {
      setRowData(row);
      setNewLockDate(row.endDate);
      setIsSplit(isSplit);
      if (isSplit) {
        setSplitHasLockAndSignatureViolation(hasSignatureViolation);
      }
      lockOpenable.open();
    },
    [lockOpenable, setRowData]
  );

  const refreshData = useCallback(() => {
    onDataChange?.(dialogMemberIds ?? []);
  }, [dialogMemberIds, onDataChange]);

  const handleTimeEntrySubmit = useCallback(() => {
    refreshData();
    timeEntryDialogOpenable.close();
  }, [timeEntryDialogOpenable, refreshData]);

  const handleDeleteTimeEntry = useCallback(() => {
    refreshData();
    deleteTimeEntryDialogOpenable.close();
  }, [deleteTimeEntryDialogOpenable, refreshData]);

  const handleSplitEntrySubmit = useCallback(() => {
    refreshData();
    splitTimeEntryOpenable.close();
  }, [splitTimeEntryOpenable, refreshData]);

  const handleMoveLockDate = useCallback(() => {
    const currentRow = row;
    lockOpenable.close();
    setRowData(currentRow!);
    setNewLockDate(DateTime.local());

    if (isSplit) {
      if (splitHasLockAndSignatureViolation) {
        signatureValidationDetails.open();
      } else {
        splitTimeEntryOpenable.open();
      }
    } else {
      if (isNil(row?.timeOffType)) {
        timeEntryDialogOpenable.open();
      } else if (row) {
        handleOpenEditTimeOff(row);
      } else {
        throw new Error('Unexpected control flow in [TimeEntryDataReport]. Should only be triggered when row exists');
      }
    }

    refreshData();
  }, [lockOpenable, timeEntryDialogOpenable]);

  function handleTimeOffEditSubmit(data?: ITimeOffFormData | undefined) {
    if (data) {
      onDataChange?.(data.members);
    }
    timeOffOpenable.close();
  }

  const handleDelete = useCallback(
    (memberIds: string[]) => {
      onDataChange?.(memberIds);
      timeOffId.current = null;
      deleteTimeOffOpenable.close();
    },
    [deleteTimeOffOpenable, timeOffId, onDataChange]
  );

  const handleOpenDeleteTimeOff = useCallback(
    (row: ITimeEntryDataTableRow) => {
      timeOffId.current = row.id;
      deleteTimeOffOpenable.open();
    },
    [deleteTimeOffOpenable, timeOffId]
  );

  const handleDeleteTimeOffClose = useCallback(() => {
    timeOffId.current = null;
    deleteTimeOffOpenable.close();
  }, [deleteTimeOffOpenable, timeOffId]);

  const handleOpenEditTimeOff = useCallback(
    (row: ITimeEntryDataTableRow) => {
      timeOffId.current = row.id;
      timeOffOpenable.open();
    },
    [timeOffOpenable, timeOffId]
  );

  const onTimeOffViewDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      timeOffId.current = row.id;
      selectedRow.current = row;
      timeOffViewDialogDetails.open();
    },
    [timeOffId, selectedRow, timeOffViewDialogDetails]
  );

  const onSplitEntryViewDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      splitTimeEntryOpenable.open();
    },
    [setRowData, splitTimeEntryOpenable]
  );

  const onSignatureDialogOpen = useCallback(
    (row: ITimeEntryDataTableRow) => {
      setRowData(row);
      signatureValidationDetails.open();
    },
    [setRowData, signatureValidationDetails]
  );

  const onSignatureViolationContinue = () => {
    signatureValidationDetails.close();
    splitTimeEntryOpenable.open();
  };

  function handleError() {
    openToast({ label: 'Something went wrong.' });
  }

  return (
    <div
      className={classNames('time-entry-data-report', printOptions?.pageBreaks ? 'page-break' : '', className)}
      ref={setRef}
    >
      {data.map((item, index) => (
        <Fragment key={item.id}>
          {applyPrintOptions && printOptions?.pageBreaks && index !== 0 && (
            <TimesheetPrintHeader title={t('Time Entries')} timeRange={timeRange} />
          )}
          {renderHeader?.(item)}
          <TimeEntryDataTable
            onTimeEntryFormDialogOpen={onTimeEntryFormDialogOpen}
            onTimeOffFormDialogOpen={handleOpenEditTimeOff}
            onDeleteTimeOffFormDialogOpen={handleOpenDeleteTimeOff}
            onDeleteTimeEntryFormDialogOpen={onDeleteTimeEntryFormDialogOpen}
            onTimeEntryConflictDialogOpen={onTimeEntryConflictDialogOpen}
            onLockDialogOpen={onLockDialogOpen}
            onLocationHistoryDialogOpen={onLocationHistoryOpen}
            onTimeEntryViewDialogOpen={onTimeEntryViewDialogOpen}
            onTimeEntryLogViewDialogOpen={onLogViewDialogOpen}
            onTimeOffViewDialogOpen={onTimeOffViewDialogOpen}
            onSplitEntryViewDialogOpen={onSplitEntryViewDialogOpen}
            onSignatureDialogOpen={onSignatureDialogOpen}
            columnSettings={columnSettings}
            emptyStateMessage={emptyStateMessage}
            onSelectChange={item.rows.some((r) => !r.isArchived) ? onSelectChange : undefined}
            rows={item.rows}
            selectedIds={selectedIds}
            className={'pb-8'}
            loading={loading}
            showSelectAll={showSelectAll}
            initialSortKey={initialSortKey}
            rowHeight={rowHeight}
            hasSignature={item.hasSignature}
          />
          {applyPrintOptions && (
            <>
              {printOptions?.position === 'at-end-of-document' ? (
                data.length - 1 === index && <TimesheetPrintSignature />
              ) : (
                <TimesheetPrintSignature />
              )}
              {printOptions?.pageBreaks && <div className="time-entry-page-break"></div>}
            </>
          )}
        </Fragment>
      ))}

      {loading && (
        <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8 no-print">
          <Spinner />
        </Row>
      )}

      {isEmpty(data) && !loading && loadedAll && (
        <Row justify={Justify.CENTER} align={Align.CENTER} className="time-empty-entry-state">
          {t('There are no time entries or time offs for this date range.')}
        </Row>
      )}

      <TimeActionsFormDialog
        type={rowId ? 'edit' : 'add'}
        memberIds={dialogMemberIds}
        currentDate={memberId ? timeRange.endTime : null}
        timeEntryId={rowId}
        isOpen={timeEntryDialogOpenable.isOpen}
        onClose={timeEntryDialogOpenable.close}
        onSubmit={handleTimeEntrySubmit}
        onDelete={handleTimeEntrySubmit}
      />
      {rowId && (
        <DeleteTimeEntryOrTimeOffDialog
          timeEntryIds={timeIds}
          isOpen={deleteTimeEntryDialogOpenable.isOpen}
          onClose={deleteTimeEntryDialogOpenable.close}
          handleDelete={handleDeleteTimeEntry}
        />
      )}

      <LockDateDialog
        date={newLockDate}
        type={LockDateDialogType.SINGLE_EDIT}
        memberIds={dialogMemberIds}
        isOpen={lockOpenable.isOpen}
        onClose={lockOpenable.close}
        onMoveLockDate={handleMoveLockDate}
      />
      {rowId && row?.member && (
        <LocationHistoryDialog
          scope={rowId}
          isOpen={locationHistoryOpenable.isOpen}
          onClose={locationHistoryOpenable.close}
          member={row.member}
        />
      )}
      <TimeEntryConflictDialog isOpen={timeEntryConflictOpenable.isOpen} onClose={timeEntryConflictOpenable.close} />
      {rowId && (
        <TimeEntryView
          timeEntryId={rowId}
          isOpen={timeEntryViewOpenable.isOpen}
          onClose={timeEntryViewOpenable.close}
          onEdit={refreshData}
        />
      )}

      {rowId && (
        <TimeEntryLogsView
          timeEntryId={rowId}
          isOpen={timeEntryLogViewOpenable.isOpen}
          onClose={timeEntryLogViewOpenable.close}
        />
      )}

      {(timeOffId.current || !isEmpty(selectedIds)) && (
        <DeleteTimeEntryOrTimeOffDialog
          timeOffIds={timeOffId.current ? [timeOffId.current] : selectedIds}
          isOpen={deleteTimeOffOpenable.isOpen}
          onClose={handleDeleteTimeOffClose}
          handleDelete={handleDelete}
          handleError={handleError}
        />
      )}
      {timeOffId.current && selectedRow.current && (
        <HeaderDialog
          isOpen={timeOffViewDialogDetails.isOpen}
          title={t('Time Off')}
          subtitle={nameFormatter(
            selectedRow.current.member.firstName ?? '',
            selectedRow.current.member.lastName ?? ''
          )}
          onClose={timeOffViewDialogDetails.close}
        >
          <TimeOffView timeOffId={timeOffId.current} />
        </HeaderDialog>
      )}
      <TimeOffFormDialog
        memberIds={memberId ? [memberId] : null}
        currentDate={numberOfDaysBetween(timeRange.startTime, timeRange.endTime) === 1 ? timeRange.endTime : null}
        timeOffId={timeOffId.current}
        isOpen={timeOffOpenable.isOpen}
        onClose={timeOffOpenable.close}
        onSubmit={handleTimeOffEditSubmit}
        onEdit={(mId) => {
          if (mId) {
            onDataChange?.([mId]);
          }
          timeOffOpenable.close();
        }}
      />
      <TimeEntrySplitDialog
        timeEntryId={rowId}
        isOpen={splitTimeEntryOpenable.isOpen}
        onClose={splitTimeEntryOpenable.close}
        onSubmit={handleSplitEntrySubmit}
      />
      <HeaderDialog
        title={t('Signed Time Card')}
        isOpen={signatureValidationDetails.isOpen}
        onClose={signatureValidationDetails.close}
        divider={false}
      >
        <Panel className={'p-5'}>
          {t('This time card has already been signed. Continuing will require the employee to re-sign.')}
          <Tray className="tray-right pt-6" align="left">
            <Button className="right-button" type="secondary" onClick={signatureValidationDetails.close}>
              {t('Cancel')}
            </Button>
            <Button className="right-button" type="primary" onClick={onSignatureViolationContinue}>
              {t('Continue')}
            </Button>
          </Tray>
        </Panel>
      </HeaderDialog>
    </div>
  );
};

TimeEntryDataReport.defaultProps = {
  applyPrintOptions: true,
};

export default TimeEntryDataReport;
