import { useMutation } from '@apollo/client';
import { CREATE_EQUIPMENT, UPDATE_EQUIPMENT } from 'apollo/mutations/equipment-mutations';
import { IEquipmentCreateInput, IEquipmentEditInput } from 'types/Equipment';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { uuid } from 'utils/uuidUtils';
import useActiveMember from '../../store/useActiveMember';

export default function useEquipment() {
  const [createEquipmentMutation] = useMutation(CREATE_EQUIPMENT);
  const [editEquipmentMutation] = useMutation(UPDATE_EQUIPMENT);
  const activeMember = useActiveMember();

  function createEquipment(equipmentName: string, equipmentModelId: string, trackManualHours: boolean) {
    const entry: IEquipmentCreateInput = {
      id: uuid(),
      organizationId: activeMember.organizationId!,
      equipmentName,
      equipmentModelId,
      trackManualHours,
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return createEquipmentMutation({ variables: { equipment: entry } });
  }

  function editEquipment(
    equipmentId: string,
    equipmentName: string,
    equipmentModelId: string,
    trackManualHours: boolean
  ) {
    const entry: IEquipmentEditInput = {
      id: equipmentId,
      equipmentName,
      equipmentModelId,
      trackManualHours,
    };

    return editEquipmentMutation({ variables: { equipment: entry } });
  }

  function deleteEquipment(equipmentId: string) {
    const entry: IEquipmentEditInput = {
      id: equipmentId,
      deletedOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return editEquipmentMutation({ variables: { equipment: entry } });
  }

  return { createEquipment, editEquipment, deleteEquipment };
}
