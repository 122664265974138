import classNames from "classnames";
import { t } from "i18next";
import { ClassName } from "types/ClassName";
import './RecordableIncidentsForm.scss'

interface IRecordableIncidentsFormProps {
    className?: ClassName
}

const RecordableIncidentsForm = (props: IRecordableIncidentsFormProps) => {
    const { className } = props;

    const onLinkClicked = () => {
        window.open('https://www.osha.gov/recordkeeping/entry-faq');
    };

    const classes = classNames('recordable-incidents-form px-8 pb-8 pt-2', className);

    return (
        <div className={classes}>
            <h4 className={'fc-2 fw-light'}>{t('A recordable incident is one that has, or will, result in any of the following outcomes:')}</h4>
            <ul className={'pt-2'}>
                <li>
                    <h4 className={'fc-2 fw-light'}>{t('Death')}</h4>
                </li>
                <li>
                    <h4 className={'fc-2 fw-light'}>{t('Days away from work')}</h4>
                </li>
                <li>
                    <h4 className={'fc-2 fw-light'}>{t('Restricted work or transfer to another job')}</h4>
                </li>
                <li>
                    <h4 className={'fc-2 fw-light'}>{t('Medical treatment beyond first aid')}</h4>
                </li>
                <li>
                    <h4 className={'fc-2 fw-light'}>{t('Loss of consciousness')}</h4>
                </li>
                <li>
                    <h4 className={'fc-2 fw-light'}>{t('Significant injury or illness diagnosed by a physician or other licensed healthcare professional.')}</h4>
                </li>
            </ul>
            <div className={'fc-2 fw-light'}>
                {t('Always check the official OSHA guidelines before determining what is, or is not, a recordable incident. ')}
                {<span>
                    <a
                        className={'link fc-primary'}
                        onClick={onLinkClicked}
                    >
                        {t('View official guidelines')}
                    </a>
                    .
                </span>}
            </div>
        </div>
    )
}

export default RecordableIncidentsForm;