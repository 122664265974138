import { UnknownAction } from 'redux';
import IOrganization from 'types/Organization';
import { ORGANIZATION_SET } from '../organizationConstants';

export interface IStoreOrganizationSetAction extends UnknownAction {
  type: typeof ORGANIZATION_SET;
  payload: IOrganization;
}

export const organizationSetAction = (payload: IOrganization): IStoreOrganizationSetAction => ({
  payload,
  type: ORGANIZATION_SET,
});
