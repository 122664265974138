import { Query, QueryKey, useQueryClient } from '@tanstack/react-query';
import { isArray, isEqual } from 'lodash';
import { useCallback } from 'react';

export function getReactQueryInvalidateQueryPredicate(queryKey: QueryKey | string) {
  return (query: Query) =>
    isArray(queryKey)
      ? queryKey.every((key) => query.queryKey.some((qk) => isEqual(qk, key)))
      : query.queryKey.includes(queryKey);
}

export function useClearReactQueryKey(queryKey: QueryKey | string) {
  const client = useQueryClient();
  return useCallback(() => {
    return client.invalidateQueries({
      predicate: getReactQueryInvalidateQueryPredicate(queryKey),
    });
  }, [client, queryKey]);
}

export function useClearReactPassedQueryKey() {
  const client = useQueryClient();
  return useCallback(
    (queryKey: QueryKey | string) => {
      return client.invalidateQueries({
        predicate: getReactQueryInvalidateQueryPredicate(queryKey),
      });
    },
    [client]
  );
}
