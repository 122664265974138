import { SIMPLE_PROJECT_FRAGMENT } from 'apollo/fragments/project-fragments';
import { ProjectFragments } from 'containers/manage-projects/projects-queries';
import gql from 'graphql-tag';
import { BASIC_PROJECT_LABOR_METRICS_FRAGMENT, BASIC_PROJECT_TIME_METRICS_FRAGMENT } from './jit-project-queries';

export const PROJECT_QUERY = gql`
  query Projects($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      projectInfo {
        id
        number
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
    }
  }
`;

export const PROJECT_WITH_GPS_QUERY = gql`
  query ProjectsWithGps($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      rootProject {
        id
        projectInfo {
          id
          requireTimeEntryGps
        }
      }
      projectInfo {
        id
        requireTimeEntryGps
      }
      cursor
    }
  }
`;

export const PROJECT_WITH_CHILDREN_QUERY = gql`
  query ProjectsWithChildren($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      projectInfo {
        id
        number
        address1
        address2
        city
        country
        state
        postalCode
        phone
        latitude
        longitude
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      children {
        id
        title
        depth
        projectInfo {
          id
          number
          address1
          address2
          city
          country
          state
          postalCode
          phone
          latitude
          longitude
        }
      }
      cursor
    }
  }
`;

export const PROJECT_WITH_INFO_QUERY = gql`
  query ProjectsWithInfo($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      projectInfo {
        id
        number
        address1
        address2
        city
        country
        state
        postalCode
        phone
        latitude
        longitude
        additionalInfo
      }
      cursor
    }
  }
`;

export const SIMPLE_PROJECT_QUERY = gql`
  query SimpleProject($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      ...SimpleProject
    }
  }
  ${SIMPLE_PROJECT_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_TIME_QUERY = gql`
  query SimpleProjectWithTime(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      ...SimpleProject
    }
  }
  ${SIMPLE_PROJECT_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithSub($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      archivedOn
      ancestors {
        id
        archivedOn
      }
      children {
        id
        archivedOn
      }
      cursor
    }
  }
`;

export const SIMPLE_PROJECT_WITH_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_TIME_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithTimeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_METRIC_QUERY = gql`
  query SimpleProjectWithMetric(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      archivedOn
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_TIME_ONLY_METRIC_QUERY = gql`
  query SimpleProjectWithTimeOnlyMetric(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_MEMBER_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithMemberMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...ProjectLaborMetrics
        memberId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithMemberCostCodeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        costCodeIds: $costCodeIds
      ) {
        ...ProjectLaborMetrics
        memberId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithMemberEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        memberId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_COST_CODE_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithCostCodeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...ProjectLaborMetrics
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithCostCodeEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        equipmentId
        costCodeId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_PROJECT_WITH_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY = gql`
  query SimpleProjectWithEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const GET_PROJECT_ID_QUERY = gql`
  query ProjectId($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      cursor
    }
  }
`;

export const GET_PROJECT_TITLE_QUERY = gql`
  query ProjectTitle($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      cursor
    }
  }
`;

export const PROJECT_ANCESTORS_QUERY = gql`
  query ProjectsAncestorsQuery($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
    }
  }
`;

export const QUICK_SEARCH_PROJECT_ANCESTORS_QUERY = gql`
  query QuickSearchProjectsAncestorsQuery($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      ancestors {
        id
        title
        depth
        archivedOn
      }
      parentProject {
        id
        archivedOn
      }
      cursor
      ...ProjectDetails
    }
  }
  ${ProjectFragments.details}
`;

export const PROJECT_CLOCK_STATE_QUERY = gql`
  query ProjectClockState($filter: ProjectClockStateFilter) {
    projectClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;
