import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  CostCode,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Project,
  Scalars,
} from '__generated__/graphql';
import { PROJECT_ANCESTORS_QUERY } from 'apollo/queries/project-queries';
import {
  COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { Dictionary, first, isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { combineMetricFilters, metricConditionFilter, metricNullFilter } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { ICostCodeActivityTableRowInfo } from './useCostCodeActivity';

export interface ICostCodeProjectActivityTableRowInfo extends ICostCodeActivityTableRowInfo {
  projectId: string | null;
  project: Project | null;
}

interface CostCodeProjectMetricRow extends LaborMetricsInterface {
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  projectId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useCostCodeActivityProjectDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>
) {
  const client = useApolloClient();
  const { getAll } = useApolloPaging();
  const canViewCost = useHasCostPermission();
  const [data, setData] = useState<ICostCodeProjectActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<ICostCodeProjectActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'costCode',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY
          : COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? COST_CODES_WITH_PROJECT_METRICS_QUERY : COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getProjectsQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function getNoCostCodeMetricFilter(): Promise<(Project & ICursorable)[]> {
    let metricFilter = undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.MemberId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_EQUIPMENT:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          filterId === remainingDataItemId
            ? metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull)
            : metricConditionFilter(ConditionFieldType.EquipmentId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        metricFilter = metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull);
        break;
    }
    return await getAll<Project & ICursorable>('projectsWithTime', {
      query: getProjectsQuery(),
      variables: {
        first: 100,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        costCodeId: { isNull: true },
        ...hasTimeIdFilter(),
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: metricFilter,
      },
      fetchPolicy: 'network-only',
    });
  }

  function getSortField(item: ICostCodeProjectActivityTableRowInfo, key: keyof ICostCodeProjectActivityTableRowInfo) {
    if (key === 'costCode') {
      return getCostCodeDisplay(item.costCode);
    } else {
      return item[key];
    }
  }

  async function getProjects(projectIds: string[]) {
    if (isEmpty(projectIds)) {
      return [];
    }

    return await getAll<Project & ICursorable>('projects', {
      query: PROJECT_ANCESTORS_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: projectIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const projectLookup = await getProjectLookup(costCodes);
    const tableRows = mapNotNull(costCodes, (costCode) => createTableRowInfo(projectLookup, costCode));

    const remainingData = await createNoCostCodeRow(tableRows);

    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  async function getProjectLookup(data: CostCode[]) {
    const metrics = data.flatMap((costCode) => laborMetrics(costCode));
    const projectIds = mapNotNil(metrics, (item) => item.projectId);
    const projects = await getProjects(uniq(projectIds));

    return keyBy(projects, (project) => project.id);
  }

  const createTableRowInfo = (
    projectLookup: Dictionary<Project>,
    costCode: CostCode
  ): ICostCodeProjectActivityTableRowInfo | null => {
    const metrics = laborMetrics(costCode);

    let noProjectRow: ICostCodeProjectActivityTableRowInfo | undefined = undefined;
    if (metrics.some((metric) => isNil(metric.projectId))) {
      const noCostCode = first(metrics.filter((metric) => isNil(metric.projectId))) ?? emptyMetricRow(costCode.id);
      const metricTotal = getGeneratedMetricLaborTotals(noCostCode, true);

      noProjectRow = {
        id: costCode.id + remainingDataItemId,
        projectId: remainingDataItemId,
        project: null,
        costCode,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    }

    const unsortedProjectRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, true);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.projectId)) {
        return null;
      }

      return {
        id: costCode.id + metric.projectId,
        projectId: metric.projectId,
        project: projectLookup[metric.projectId] ?? null,
        costCode,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    const projectRows = sortByIgnoreCase(unsortedProjectRows, (row) => getFormattedPathFromProject(row.project, true));

    if (isEmpty(projectRows) && noProjectRow === undefined) {
      return null;
    } else {
      const costCodeTotalMetrics = aggregateActivityRows(
        noProjectRow === undefined ? projectRows : [...projectRows, noProjectRow]
      );
      return {
        ...costCodeTotalMetrics,
        id: costCode.id,
        projectId: null,
        project: null,
        costCode,
        detailRows: noProjectRow === undefined ? projectRows : [...projectRows, noProjectRow],
      };
    }
  };

  const createNoCostCodeRow = async (
    tableRows: ICostCodeProjectActivityTableRowInfo[]
  ): Promise<ICostCodeProjectActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost);

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Cost Code row
    }

    const projects = await getNoCostCodeMetricFilter();

    const unsortedProjectRows: ICostCodeProjectActivityTableRowInfo[] = projects.flatMap((project) => {
      const metrics = projectLaborMetrics(project);

      return mapNotNull(metrics, (metric) => {
        const metricTotal = getGeneratedMetricLaborTotals(metric, true);
        if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
          return null;
        }

        return {
          id: remainingDataItemId + project.id,
          projectId: project.id,
          project: project,
          costCode: null,
          ...activityReportUtils.metricToRowInfo(metricTotal),
        };
      });
    });

    const projectRows = sortByIgnoreCase(unsortedProjectRows, (row) => getFormattedPathFromProject(row.project, true));

    const remainingProjectData = calculateRemainingActivityData(
      projectRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingProjectData.totalHours > 0) {
      // add the no cost code item under no project details
      projectRows.push({
        ...remainingProjectData,
        id: remainingDataItemId + remainingDataItemId,
        project: null,
        costCode: null,
        projectId: remainingDataItemId,
      });
    }

    return {
      projectId: null,
      project: null,
      costCode: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: projectRows,
    };
  };

  const emptyMetricRow = (costCodeId: string): CostCodeProjectMetricRow => {
    return {
      costCodeId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (costCode: CostCode): CostCodeProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return costCode.costCodeMemberProjectLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return costCode.costCodeProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return costCode.costCodeProjectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const projectLaborMetrics = (project: Project): CostCodeProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return project.projectMemberCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return project.projectCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectCostCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
