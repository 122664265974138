import classNames from 'classnames';
import { useTimeEntryActionBarState } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import CostCodeTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/CostCodeTimeEntryDataReport/CostCodeTimeEntryDataReport';
import useCostCodeTimeEntryDataReportData from 'components/domain/time-entry/TimeEntryDataReport/CostCodeTimeEntryDataReport/hooks/useCostCodeTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { defaultTimeEntryColumns } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { usePrintComplexDataChannelListener } from 'contexts/ChannelContext/hooks/usePrintComplexDataChannelListener';
import { useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { useLoaderPromise } from 'hooks/promise';
import { first, isEmpty, isNil, isNull, map, partition, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import { t } from 'utils/localize';
import { useTimeEntryExportChannelListener } from '../hooks/useTimeEntryExportChannelListener';

export interface ICostCodeTimeEntryTableListeningContainerProps {
  className?: ClassName;
  scroller: HTMLElement | Document | null;
  timeRange: ITimeRange<DateTime>;
  equipmentIds?: string[];
  memberIds?: string[];
  projectIds?: string[];
  costCodeIds?: string[];
  excludeArchived?: boolean;
  projectIdWithDescendants?: boolean;
  applyPrintOptions: boolean;
}

const CostCodeTimeEntryTableListeningContainer = (props: ICostCodeTimeEntryTableListeningContainerProps) => {
  const {
    className,
    scroller,
    timeRange,
    equipmentIds,
    memberIds,
    projectIds,
    costCodeIds,
    excludeArchived,
    projectIdWithDescendants,
    applyPrintOptions,
  } = props;

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const { data, loading, loadedAll, loadAll, updateCostCodeIds } = useCostCodeTimeEntryDataReportData(
    scroller,
    timeRange,
    false,
    costCodeIds,
    equipmentIds,
    memberIds,
    projectIds,
    excludeArchived,
    true,
    projectIdWithDescendants
  );

  const [timeEntryActionBarState] = useTimeEntryActionBarState();
  const brand = useBrandTitle();

  useEffect(() => {
    if (!isEmpty(timeEntryActionBarState.entries)) {
      const ids = uniq(timeEntryActionBarState.entries.map((entry) => entry.costCode?.id ?? '')).filter(
        (id) => id.length > 0
      );
      updateCostCodeIds(ids);
    }
    if (!isEmpty(timeEntryActionBarState.memberIds)) {
      updateCostCodeIds(data.map((d) => d.id));
    }
    if (!isNull(timeEntryActionBarState.formData)) {
      const ids = timeEntryActionBarState.formData.members;
      updateCostCodeIds(ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntryActionBarState]);

  const exportWithLoader = useLoaderPromise(loadAll, t('Exporting...'));
  const printWithLoader = useLoaderPromise(loadAll, t('Preparing to print...'));

  useTimeEntryExportChannelListener(
    data,
    loadedAll,
    `${brand}-${t('activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}`,
    exportWithLoader
  );
  usePrintComplexDataChannelListener(data, loadedAll, printWithLoader);

  const classes = classNames('cost-code-time-entry-table-container', className);

  return (
    <CostCodeTimeEntryDataReport
      className={classes}
      columnSettings={defaultTimeEntryColumns}
      updateCostCodeIds={updateCostCodeIds}
      timeRange={timeRange}
      selectedIds={selectedIds}
      onSelectChange={onSelect}
      data={data}
      loading={loading}
      memberIds={memberIds}
      projectId={first(projectIds)}
      equipmentId={first(equipmentIds)}
      loadedAll={loadedAll}
      applyPrintOptions={applyPrintOptions}
    />
  );
};

export default CostCodeTimeEntryTableListeningContainer;
