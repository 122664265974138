import gql from 'graphql-tag';

export const MEMBER_LOCK_CREATE_QUERY = gql`
  mutation CreateMemberLock($lock: MemberLockCreateInput) {
    createMemberLock(memberLock: $lock) {
      id
      effectiveDate
      memberId
      createdOn
    }
  }
`;

export const MEMBER_LOCK_UPDATE_MUTATION = gql`
  mutation UpdateMemberLock($lock: MemberLockUpdateInput) {
    updateMemberLock(memberLock: $lock) {
      id
      effectiveDate
      memberId
      createdOn
    }
  }
`;
