import { useApolloClient } from '@apollo/client';
import { MEMBER_ARCHIVED_QUERY } from 'apollo/queries/member-queries';
import { isNil, some } from 'lodash';
import { useEffect, useState } from 'react';
import { IMember } from 'types';

export default function useCheckArchivedOfMembers(memberIds?: string[] | null) {
  const client = useApolloClient();
  const [memberArchived, setMemberArchived] = useState<boolean>(false);

  useEffect(() => {
    checkArchivedOfMembers();
  }, [memberIds]);

  async function checkArchivedOfMembers() {
    if (!isNil(memberIds)) {
      const results = await client.query<{ members: IMember[] }>({
        query: MEMBER_ARCHIVED_QUERY,
        variables: { filter: { id: { contains: memberIds } } },
        fetchPolicy: 'network-only',
      });
      setMemberArchived(some(results.data.members, (member) => !isNil(member.archivedOn)));
    }
  }

  return memberArchived;
}
