const PAYROLL_REPORT_VIEWED = 'Payroll Report Viewed';
const PAYROLL_REPORT_EXPORTED = 'Payroll Report Exported';
const PAYROLL_SCANNED = 'Payroll Scanned';
const PAYROLL_SCAN_RESULTS = 'Payroll Scan Results';
const PAYROLL_SCAN_CONFLICT_VIEW_OPENED = 'Payroll Scan Conflict View Opened';
const PAYROLL_SETTING_SET = 'Payroll Setting Set';
const PAYROLL_FILTER_SET = 'Payroll Filter Set';
const PAYROLL_TABLE_COLUMNS_SET = 'Payroll Table Columns Set';
const PAYROLL_HOURS_ROW_EVENT = 'Payroll Hours Row Event';
const PAYROLL_LABOR_COSTS_ROW_EVENT = 'Payroll Labor Costs Row Event';

const payrollAnalyticsConstants = {
  PAYROLL_REPORT_VIEWED,
  PAYROLL_REPORT_EXPORTED,
  PAYROLL_SCANNED,
  PAYROLL_SCAN_RESULTS,
  PAYROLL_SCAN_CONFLICT_VIEW_OPENED,
  PAYROLL_SETTING_SET,
  PAYROLL_FILTER_SET,
  PAYROLL_TABLE_COLUMNS_SET,
  PAYROLL_HOURS_ROW_EVENT,
  PAYROLL_LABOR_COSTS_ROW_EVENT,
};

export default payrollAnalyticsConstants;
