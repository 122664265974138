import {
  Align,
  Bar,
  Button,
  ButtonList,
  Checkbox,
  Justify,
  Label,
  Position,
  Row,
  Size,
  Theme,
} from '@busybusy/webapp-react-ui';
import { TimeCardIcon } from 'assets/icons';
import { Badge } from 'components';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import MoreButton from 'components/foundation/buttons/MoreButton/MoreButton';
import { TimeCardReportContext } from 'containers/timesheets/TimeCardReport/context/TimeCardReportContext';
import { useOrganization, usePermissions } from 'hooks';
import _, { isNil } from 'lodash';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { updateCheckedMemberIds } from 'store/timesheets/Timesheets';
import { IMember } from 'types';
import { secondsToHHmmString } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import LocalStore from 'utils/localStorageUtils';
import { t } from 'utils/localize';
import { formatTime } from 'utils/timeUtils';
import { ExpandedTimeCardReportContext } from '../ExpandedTimeCardsReport/context/ExpandedTimeCardReportContext';
import './TimeCardReportMemberHeader.scss';

export interface ITimeCardReportMemberHeaderProps {
  member: IMember;
  onViewEntries: (member: IMember) => void;
  onViewSignOff: (member: IMember) => void;
  isExpanded: boolean;
  showDecimalFormat?: boolean;
}

const TimeCardReportMemberHeader = (props: ITimeCardReportMemberHeaderProps) => {
  const { member, onViewEntries, onViewSignOff, isExpanded, showDecimalFormat } = props;
  const isPro = useFeatureFlags('PRO');
  const dispatch = useDispatch();
  const checkedItems = useSelector<IReduxState, string[]>((state) => state.timesheet.checkedMemberIds);
  const org = useOrganization();
  const isDailySignOffEnabled = org.safetySignature || org.timeAccuracy || org.breakPolicy;
  const { hasPermissionToManage } = usePermissions();
  const canManage = useRef<boolean>(hasPermissionToManage(member, 'manageTimeEntries'));
  const isArchived = useRef<boolean>(!isNil(member.archivedOn));
  const tableRowHeight = LocalStore.get('tableRowHeight') ?? 'p-4';

  const { getTimeCardReportDataForMember } = useContext(TimeCardReportContext);
  const { getExpandedTimeCardReportDataForMember } = useContext(ExpandedTimeCardReportContext);

  const formattedTotal = getTotal();

  function getTotal() {
    if (isExpanded) {
      if (!isNil(showDecimalFormat) && showDecimalFormat) {
        return getExpandedTimeCardReportDataForMember(member)?.totalSeconds
          ? formatTime({
              type: 'DECIMAL',
              seconds: getExpandedTimeCardReportDataForMember(member)!.totalSeconds,
              places: 2,
            })
          : '--:--';
      }
      return getExpandedTimeCardReportDataForMember(member)?.totalSeconds
        ? secondsToHHmmString(getExpandedTimeCardReportDataForMember(member)!.totalSeconds)
        : '--:--';
    }
    return getTimeCardReportDataForMember(member)?.totalSeconds
      ? secondsToHHmmString(getTimeCardReportDataForMember(member)!.totalSeconds)
      : '--:--';
  }

  function viewTimeEntries() {
    onViewEntries(member);
  }

  function renderDropdownContent(closeDropdown: () => void) {
    function handleViewSignOffDropdown(event: React.MouseEvent) {
      closeDropdown();
      event.stopPropagation();
      event.preventDefault();
      onViewSignOff(member);
    }

    return (
      <ButtonList>
        <Button onClick={handleViewSignOffDropdown}>{t('View Daily Sign-Off')}</Button>
      </ButtonList>
    );
  }

  const handleCheckboxChange = (checked: boolean) => {
    if (checked && !checkedItems.some((id) => id === member.id)) {
      dispatch(updateCheckedMemberIds([...checkedItems, member.id]));
    } else if (!checked && checkedItems.some((id) => id === member.id)) {
      dispatch(updateCheckedMemberIds(_.filter(checkedItems, (id) => id !== member.id)));
    }
  };

  return (
    <div className="time-card-employee-name-bar">
      <Bar justify={Justify.SPACE_BETWEEN} className={`bar-height-unset ${tableRowHeight}`}>
        <div className="left-container ">
          {canManage.current && !isArchived.current ? (
            <>
              <Checkbox
                className="no-print"
                onChange={handleCheckboxChange}
                checked={_.some(checkedItems, (id) => member.id === id)}
              />

              <div>
                <Label className={'employee-name-title pl-4'}>
                  <FormattedEmployeeName firstName={member.firstName ?? ''} lastName={member.lastName ?? ''} />
                </Label>
              </div>
            </>
          ) : (
            <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
              <Label className="pt-1">
                <FormattedEmployeeName firstName={member.firstName ?? ''} lastName={member.lastName ?? ''} />
              </Label>
              {isArchived.current ? (
                <Badge key={member.id + '-archived'} className={'ml-6'}>
                  {t('ARCHIVED')}
                </Badge>
              ) : undefined}
            </Row>
          )}
        </div>

        <div className="right-container">
          <Label
            className={`total-label pr-4 ${tableRowHeight === 'p-1' || tableRowHeight === 'p-2' ? 'pt-1' : 'pt-2'}`}
          >
            {formattedTotal}
          </Label>

          <IconButton
            className={`no-print  ${tableRowHeight === 'p-1' || tableRowHeight === 'p-2' ? 'small-icon' : ''}`}
            svg={TimeCardIcon}
            onClick={viewTimeEntries}
            theme={Theme.LIGHT}
            buttonSize={Size.SMALL}
            tooltipLabel={t('View Time Entries')}
          />

          {isPro && isDailySignOffEnabled && (
            <MoreButton
              className={`no-print  ${tableRowHeight === 'p-1' || tableRowHeight === 'p-2' ? 'small-icon' : 'pb-2'}`}
              position={Position.BOTTOM_END}
              renderContent={renderDropdownContent}
            />
          )}
        </div>
      </Bar>
    </div>
  );
};

export default TimeCardReportMemberHeader;
