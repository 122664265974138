import { COST_CODE_DISPLAY_FRAGMENT } from 'apollo/fragments/cost-code-fragments';
import { MEMBER_DISPLAY_FRAGMENT } from 'apollo/fragments/member-fragments';
import gql from 'graphql-tag';

export const SCHEDULING_SCHEDULED_MEMBER_FRAGMENT = gql`
  fragment SchedulingScheduledMember on Member {
    id
    ...MemberDisplay
    memberGroupId
    positionId
    archivedOn
    position {
      id
      level
      timeEvents
      viewSchedule
      manageSchedule
    }
  }
  ${MEMBER_DISPLAY_FRAGMENT}
`;

export const SCHEDULING_TIME_OFF_FIELDS_FRAGMENT = gql`
  fragment SchedulingTimeOffFields on TimeOff {
    id
    dateStamp
    type
    description
    paid
    seconds
    memberId
    member {
      id
      ...SchedulingScheduledMember
    }
  }
  ${SCHEDULING_SCHEDULED_MEMBER_FRAGMENT}
`;

export const RECURRING_SCHEDULE_LINK_FRAGMENT = gql`
  fragment RecurringScheduleLinkFields on RecurringScheduleReminderLink {
    id
    scheduleReminderId
    memberId
    member {
      id
      ...SchedulingScheduledMember
    }
  }
  ${SCHEDULING_SCHEDULED_MEMBER_FRAGMENT}
`;

export const SCHEDULING_REMINDER_PROJECT_FRAGMENT = gql`
  fragment SchedulingReminderProjectFields on Project {
    id
    title
    depth
    projectGroupId
    parentProjectId
    rootProjectId
    archivedOn
    ancestors {
      id
      title
      depth
      projectGroupId
      archivedOn
    }
    projectCostCodeLink {
      id
      costCodeId
      deletedOn
    }
  }
`;

export const SCHEDULING_REMINDER_COST_CODE_FRAGMENT = gql`
  fragment SchedulingReminderCostCodeFields on CostCode {
    ...CostCodeDisplay
    id
    archivedOn
    costCodeGroupId
  }
  ${COST_CODE_DISPLAY_FRAGMENT}
`;

export const SCHEDULING_REMINDER_EQUIPMENT_FRAGMENT = gql`
  fragment SchedulingReminderEquipmentFields on Equipment {
    archivedOn
    equipmentGroupId
    equipmentName
    id
    model {
      category {
        id
        title
      }
      equipmentCategoryId
      equipmentMakeId
      id
      make {
        id
        title
      }
      modelNumber
      title
      year
    }
  }
`;

export const RECURRING_SCHEDULE_FLAT_FRAGMENT = gql`
  fragment RecurringScheduleFlatFields on RecurringScheduleReminder {
    id
    startTime
    endTime
    type
    frequency
    frequencyExpiration
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    monthOfYear
    dayOfMonth
    notifyEmployee
    isDraft
    memberId
    payload
  }
`;

export const RECURRING_SCHEDULE_FRAGMENT = gql`
  fragment RecurringScheduleFields on RecurringScheduleReminder {
    id
    ...RecurringScheduleFlatFields
    projectId
    project {
      id
      ...SchedulingReminderProjectFields
    }
    costCodeId
    costCode {
      id
      ...SchedulingReminderCostCodeFields
    }
    equipmentId
    equipment {
      id
      ...SchedulingReminderEquipmentFields
    }
    links {
      id
      ...RecurringScheduleLinkFields
    }
  }
  ${RECURRING_SCHEDULE_FLAT_FRAGMENT}
  ${RECURRING_SCHEDULE_LINK_FRAGMENT}
  ${SCHEDULING_REMINDER_PROJECT_FRAGMENT}
  ${SCHEDULING_REMINDER_COST_CODE_FRAGMENT}
  ${SCHEDULING_REMINDER_EQUIPMENT_FRAGMENT}
`;
