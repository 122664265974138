import { Justify, Row } from '@busybusy/webapp-react-ui';
import { Spinner } from 'components';
import './TableSpinner.scss';

interface TableSpinnerProps {
  isVisible: boolean;
}

const TableSpinner = (props: TableSpinnerProps) => {
  const { isVisible } = props;
  if (isVisible) {
    return (
      <Row justify={Justify.CENTER} className="table-spinner py-5 no-print">
        <Spinner />
      </Row>
    );
  }

  return <></>;
};

export default TableSpinner;
