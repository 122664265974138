import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, NavigateProps, useLocation, useSearchParams } from 'react-router-dom';
import { authenticationSetTokenAction, IStoreAuthentication, IStoreAuthenticationSetTokenAction } from 'store/store';
import { encodeAuthToken } from 'utils/authenticationUtils';
interface IProcessEnv {
  [key: string]: string | undefined;
  REACT_APP_KEY?: string;
  REACT_APP_MEMBER?: string;
}
export interface IAppDotEnvBootstrapProps {
  pathname: string;
  searchParams: URLSearchParams;
  env: IProcessEnv;
  dispatchAuthenticationSetToken: (encodedAuthenticationToken: string | null) => IStoreAuthenticationSetTokenAction;
}

export const DotEnvBootstrapContainer: React.VFC = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const dispatchAuthenticationSetToken = (encodedAuthenticationToken: IStoreAuthentication['token']) =>
    dispatch(authenticationSetTokenAction(encodedAuthenticationToken));

  return (
    <DotEnvBootstrap
      pathname={pathname}
      searchParams={searchParams}
      env={process.env}
      dispatchAuthenticationSetToken={dispatchAuthenticationSetToken}
    />
  );
};

export const DOTENV_MEMBER_CONFIGURATION_ERROR_MESSAGE = `'REACT_APP_MEMBER' must be set to the associated memberId in your dotenv when 'REACT_APP_KEY' is used`;

export const DotEnvBootstrap: React.FC<IAppDotEnvBootstrapProps> = ({
  searchParams,
  pathname,
  dispatchAuthenticationSetToken,
  env,
}) => {
  const { REACT_APP_KEY, REACT_APP_MEMBER } = env;
  const keyAuthorization = REACT_APP_KEY ?? '';
  const memberId = REACT_APP_MEMBER ?? '';
  const encodedAuthenticationToken = encodeAuthToken(keyAuthorization, memberId);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info('Attempting to bootstrap authentication from .env configuration', {
      keyAuthorization,
      memberId,
      encodedAuthenticationToken,
    });
  }

  if (memberId === '') {
    return (
      <div>
        <h1>{DOTENV_MEMBER_CONFIGURATION_ERROR_MESSAGE}</h1>
        <h2>The app must be restarted before dotenv edits take effect</h2>
      </div>
    );
  }

  // persist the token to the store and to local storage
  dispatchAuthenticationSetToken(encodedAuthenticationToken);

  const redirectTo: NavigateProps['to'] = {
    pathname,
    search: searchParams.toString(),
  };

  return <Navigate to={redirectTo} replace={true} />;
};

export default DotEnvBootstrapContainer;
