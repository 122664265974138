import { useOrganization } from 'hooks';
import { useCallback } from 'react';
import { getPayPeriodTimeRange } from 'utils/payPeriodUtils';

export default function usePayPeriodTimeRangeGet() {
  const organization = useOrganization();

  return useCallback(
    (time: number) => {
      return getPayPeriodTimeRange(organization.organizationPayPeriod!, time);
    },
    [organization.organizationPayPeriod]
  );
}
