import _, { isFinite, isNumber, reduce, times, toNumber } from 'lodash';
import { KeysOfType } from 'types/util/KeysOfType';
import { AnyObject } from 'types/util/Object';
import { Optional } from 'types/util/Optional';
import { greaterThan, lessThan } from './compareUtils';

export type NumberScale = 'billions' | 'millions' | 'thousands';

export function createNumberWithSuffix(num: number) {
  if (num >= 10 && num <= 20) {
    return `${num.toString()}th`;
  }

  const modded = num % 10;
  if (modded === 1) {
    return `${num.toString()}st`;
  } else if (modded === 2) {
    return `${num.toString()}nd`;
  } else if (modded === 3) {
    return `${num.toString()}rd`;
  } else {
    return `${num.toString()}th`;
  }
}

export function removeSuffiFromNumber(suffixed: string): number {
  const desuffixed = suffixed.substring(0, suffixed.length - 2);
  return _.toNumber(desuffixed);
}

export function isNumberString(value: string) {
  return !isNaN(_.toNumber(value));
}

export function clampMax(n: number, max: number) {
  return n < max ? n : max;
}

export function clampMin(n: number, min: number) {
  return n > min ? n : min;
}

export function clampExtrema(n: number, min: number, max: number) {
  return n < max ? clampMin(n, min) : clampMax(n, max);
}

export function getNumberFormattedByComma(
  n: number,
  decimalPlaces: number,
  thousandsReplacement: string = 'K',
  millionsReplacement: string = 'M',
  billionsReplace: string = 'B'
) {
  const value = getAdjustedNumberByComma(n, decimalPlaces);

  switch (value.scale) {
    case 'thousands':
      return `${value.value}${thousandsReplacement}`;
    case 'millions':
      return `${value.value}${millionsReplacement}`;
    case 'billions':
      return `${value.value}${billionsReplace}`;
    default:
      return value.value.toString();
  }
}

export function getAdjustedNumberByComma(
  n: number,
  decimalPlaces: number
): { value: number; scale: NumberScale | null } {
  const hasBillions = n / 1_000_000_000 >= 1;

  if (hasBillions) {
    return { value: getNumberFormattedByBreakpoint(n, 1_000_000_000, decimalPlaces), scale: 'billions' };
  }

  const hasMillions = n / 1_000_000 >= 1;
  if (hasMillions) {
    return { value: getNumberFormattedByBreakpoint(n, 1_000_000, decimalPlaces), scale: 'millions' };
  }

  const hasThousand = n / 1_000 >= 1;

  if (hasThousand) {
    return { value: getNumberFormattedByBreakpoint(n, 1_000, decimalPlaces), scale: 'thousands' };
  }

  return { value: n, scale: null };
}

export function getNumberFormattedByBreakpoint(n: number, breakpoint: number, decimalPlaces: number) {
  const shouldReplace = n / breakpoint >= 1;
  if (shouldReplace) {
    return roundIgnoreZeroes(n / breakpoint, decimalPlaces);
  }

  return n;
}

export function roundIgnoreZeroes(n: number, decimalPlaces: number) {
  const fixed = n.toFixed(decimalPlaces);
  return parseFloat(fixed);
}

export function roundToOffsetPlace(n: number, offset: number, roundFn: (n: number) => number = Math.ceil) {
  const tens = parseFloat(Math.log10(n).toFixed(0));
  if (tens - offset < 0) {
    return n;
  }
  const divisor = Math.pow(10, tens - offset);
  return roundFn(n / divisor) * divisor;
}

export function isCloseTo(value1: number, value2: number, delta: number) {
  return Math.abs(value1 - value2) <= delta;
}

export function add(...numbers: Array<Optional<number>>): number {
  return reduce(numbers, (acc, cur) => acc + (cur ?? 0), 0);
}

export function getSafeNumber(n: Optional<number>): number {
  return n ?? 0;
}

export function sumKeys<T extends AnyObject>(obj: T, ...keys: Readonly<Array<KeysOfType<T, Optional<number>>>>) {
  return keys.reduce((acc, cur) => getSafeNumber(obj[cur]) + acc, 0);
}

export function convertStringToSafeNumber(s: Optional<string>) {
  return getSafeNumber(toNumber(s));
}

export function between(n: number, minimum: number, maximum: number, endInclusive: boolean = false) {
  return lessThan(n, maximum, endInclusive) && greaterThan(n, minimum, true);
}

export function negate(n: number) {
  if (n !== 0) {
    return n * -1;
  }

  return 0;
}

export function isValidNumber(n: any): n is number {
  return isFinite(n) && !isNaN(n) && isNumber(n);
}

export function isValidNonZeroNumber(n: any): n is number {
  return isValidNumber(n) && n !== 0;
}

export function getFirstEvenlyDivisibleNumber(n: number, candidates: number[]) {
  return candidates.find((candidate) => candidate % n === 0) ?? null;
}

export function timesStartingAtOne(n: number) {
  return times(n, (num) => num + 1);
}
