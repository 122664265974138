import { Button, IDatePickerPayload, Icon, Theme } from '@busybusy/webapp-react-ui';
import { PositionFilter } from '__generated__/graphql';
import { FilterListIcon } from 'assets/icons';
import classNames from 'classnames';
import EmployeeCheckList from 'components/domain/member/EmployeeCheckList/EmployeeCheckList';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { employeeSmartFilterFormDefaultData } from 'containers/bulletin/constants/constants';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { useState } from 'react';
import { ClassName } from 'types/ClassName';
import IMemberPermissionType from 'types/MemberPermissionType';
import MultiPicker from '../../../foundation/pickers/MultiPicker/MultiPicker';
import EmployeePicker from '../EmployeePicker/EmployeePicker';
import { EmployeeSmartFilterForm } from '../EmployeeSmartFilterForm/EmployeeSmartFilterForm';
import './EmployeeMultiPicker.scss';

export interface IEmployeeMultiPickerProps {
  readonly values: string[];
  permissions?: IMemberPermissionType;
  blacklistedIds?: string[];
  filterAlreadySelectedByBlacklist: boolean;
  onChange: (values: string[]) => void;
  error?: boolean;
  placeholder?: string;
  className?: ClassName;
  includeClockedIn?: boolean;
  showSmartFilter?: boolean;
  positionFilter?: PositionFilter;
}

export interface IEmployeeSmartFilterData {
  showClockedIn: boolean;
  showClockedOut: boolean;
  showScheduled: boolean;
  clockedInProjectId: string | null;
  clockedInCostCodeId: string | null;
  clockedInEquipmentId: string | null;
  scheduledProjectId: string | null;
  scheduledCostCodeId: string | null;
  scheduledEquipmentId: string | null;
  scheduledDateRestrictions: string | null;
  scheduledDate: IDatePickerPayload | null;
}
export interface IClockedInFilter {
  clockedInProjectId: string | null;
  clockedInCostCodeId: string | null;
  clockedInEquipmentId: string | null;
}

export interface IScheduledFilter {
  scheduledProjectId: string | null;
  scheduledCostCodeId: string | null;
  scheduledEquipmentId: string | null;
  scheduledDateRestrictions: string | null;
  scheduledDate: IDatePickerPayload | null;
}

const EmployeeMultiPicker = ({
  permissions,
  onChange,
  blacklistedIds,
  filterAlreadySelectedByBlacklist,
  values,
  error,
  placeholder,
  className,
  includeClockedIn,
  showSmartFilter,
  positionFilter,
}: IEmployeeMultiPickerProps) => {
  const checkListDialogDetails = useOpenable();
  const smartFilterDialog = useOpenable();

  const [employeeSmartFilterData, setEmployeeSmartFilterData] = useState<IEmployeeSmartFilterData>(
    employeeSmartFilterFormDefaultData
  );

  function getEmployeeSmartFilterData() {
    return employeeSmartFilterData;
  }

  function handleClearEmployeeSmartFilterData() {
    setEmployeeSmartFilterData(employeeSmartFilterFormDefaultData);
  }

  function handleOnSubmitSmartFilter(data: IEmployeeSmartFilterData) {
    setEmployeeSmartFilterData(data);

    smartFilterDialog.close();
  }

  function onEmployeeCheckListSubmit(memberIds: string[]) {
    checkListDialogDetails.close();
    onChange(memberIds);
  }

  function handleOnChange(newValues: string[]) {
    if (values.length > 1) {
      onChange(newValues.filter((value) => value !== null));
    } else {
      onChange(newValues);
    }
  }

  function renderItem(value: string | null, index: number) {
    const onMemberSelect = (id: string | null) => {
      if (index === 0 || index < values.length) {
        if (id !== null) {
          const newValues = [...values];
          newValues[index] = id;
          handleOnChange(newValues);
        } else {
          handleOnChange(values.filter((v, i) => i !== index));
        }
      }
    };

    return (
      <EmployeePicker
        className={index !== 0 ? 'mt-4' : ''}
        blacklistedIds={blacklistedIds ? values.concat(blacklistedIds) : values}
        permissions={permissions}
        key={value ? value : 'null'}
        error={error}
        value={value}
        onSelect={onMemberSelect}
        placeholder={placeholder}
        includeClockedIn={includeClockedIn}
        positionFilter={positionFilter}
      />
    );
  }

  function renderRightContent(isPrimary?: boolean) {
    if (showSmartFilter) {
      return (
        <Button type="icon" onClick={smartFilterDialog.open}>
          <Icon svg={FilterListIcon} theme={isPrimary ? Theme.PRIMARY : Theme.DARK} />
        </Button>
      );
    }
  }

  const filteredValues =
    filterAlreadySelectedByBlacklist && blacklistedIds
      ? values.filter((value) => !blacklistedIds.some((blacklistId) => blacklistId === value))
      : values;

  const classes = classNames('employee-multi-picker', className);

  return (
    <div className={classes}>
      <HeaderDialog
        title={t('Select Employees')}
        isOpen={checkListDialogDetails.isOpen}
        onClose={checkListDialogDetails.close}
        rightContent={renderRightContent(
          employeeSmartFilterData.showClockedIn ||
            employeeSmartFilterData.showClockedOut ||
            employeeSmartFilterData.showScheduled
        )}
      >
        <EmployeeCheckList
          checkedItems={filteredValues}
          enableGroups={true}
          enableSearch={true}
          onSubmit={onEmployeeCheckListSubmit}
          permissions={permissions}
          blacklistedIds={blacklistedIds}
          positionFilter={positionFilter}
          includeClockedIn={includeClockedIn || employeeSmartFilterData.showClockedIn}
          includeClockedOut={employeeSmartFilterData.showClockedOut}
          includeScheduled={employeeSmartFilterData.showScheduled}
          clockedInFilter={
            employeeSmartFilterData.showClockedIn
              ? {
                  clockedInCostCodeId: employeeSmartFilterData.clockedInCostCodeId,
                  clockedInEquipmentId: employeeSmartFilterData.clockedInEquipmentId,
                  clockedInProjectId: employeeSmartFilterData.clockedInProjectId,
                }
              : undefined
          }
          scheduledFilter={
            employeeSmartFilterData.showScheduled
              ? {
                  scheduledCostCodeId: employeeSmartFilterData.scheduledCostCodeId,
                  scheduledEquipmentId: employeeSmartFilterData.scheduledEquipmentId,
                  scheduledProjectId: employeeSmartFilterData.scheduledProjectId,
                  scheduledDateRestrictions: employeeSmartFilterData.scheduledDateRestrictions,
                  scheduledDate: employeeSmartFilterData.scheduledDate,
                }
              : undefined
          }
        />
      </HeaderDialog>
      <MultiPicker
        onAddClick={checkListDialogDetails.open}
        limit={3}
        renderItem={renderItem}
        // Null list item so there's an empty employee picker as a default
        values={filteredValues && filteredValues.length ? filteredValues : [null]}
      />
      <HeaderDialog title={t('Smart Filters')} isOpen={smartFilterDialog.isOpen} onClose={smartFilterDialog.close}>
        <EmployeeSmartFilterForm
          getFormData={getEmployeeSmartFilterData}
          onFormSubmit={handleOnSubmitSmartFilter}
          buttonTitle={t('Submit')}
          onClearFilters={handleClearEmployeeSmartFilterData}
          className={'p-5'}
        />
      </HeaderDialog>
    </div>
  );
};

EmployeeMultiPicker.defaultProps = {
  filterAlreadySelectedByBlacklist: true,
};

export default EmployeeMultiPicker;
