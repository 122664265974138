import { Menu, Position } from '@busybusy/webapp-react-ui';
import { MoreIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import IconButton, { IIconButtonProps } from 'components/foundation/buttons/IconButton/IconButton';
import { ReactNode } from 'react';

export interface IMoreButtonProps extends IIconButtonProps {
  renderContent: (close: () => void) => ReactNode;
  position?: Position;
  menuWidth?: string;
}

const MoreButton = (props: IMoreButtonProps) => {
  const { renderContent, position, className, menuWidth, ...iconButtonProps } = props;

  const classes = classNames('more-button', className);

  function renderTrigger(ref: any) {
    return <IconButton forwardRef={ref} className={classes} {...iconButtonProps} />;
  }

  return (
    <Menu width={menuWidth} className={className} position={position} trigger={renderTrigger} content={renderContent} />
  );
};

MoreButton.defaultProps = {
  ...IconButton.defaultProps,
  svg: MoreIcon,
};

export default MoreButton;
