import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { toNumber } from 'lodash';
import { isNumberString } from 'utils/numberUtils';
import BlockingTextField, { IBlockingTextFieldProps } from '../BlockingTextField/BlockingTextField';

export interface INumberTextFieldProps extends Omit<IBlockingTextFieldProps, 'isValueAllowed'> {
  minimum?: number;
  maximum?: number;
  maximumSignificantDigits?: number;
  isValueAllowed?: IBlockingTextFieldProps['isValueAllowed'];
}

function isStringWithinBounds(value: string, minimum?: number, maximum?: number, maximumSignificantDigits?: number) {
  if (!isNumberString(value)) {
    return false;
  }

  // Needs to be in here because they could put 12.32000 and toNumber truncates the unused 0s
  if (maximumSignificantDigits) {
    const [, decimal] = value.split('.');
    if (decimal && decimal.length > maximumSignificantDigits) {
      return false;
    }
  }

  const numberValue = toNumber(value);

  if (minimum && numberValue < minimum) {
    return false;
  }

  if (maximum && numberValue > maximum) {
    return false;
  }

  return true;
}

function NumberTextField({
  className,
  minimum,
  maximum,
  maximumSignificantDigits,
  isValueAllowed: isValueAllowed,
  ...textFieldProps
}: INumberTextFieldProps) {
  const classes = classNames('number-text-field', className);

  return (
    <BlockingTextField
      isValueAllowed={(value, event) => {
        if (isValueAllowed && !isValueAllowed(value, event)) {
          return false;
        }

        return isStringWithinBounds(value, minimum, maximum, maximumSignificantDigits);
      }}
      className={classes}
      {...textFieldProps}
    />
  );
}

export default NumberTextField;
