import { LogFeedTimeEntryLog, TimeLogTimeEntryState } from 'containers/time-log-feed/types/types';
import { collapseTimeLogFeedLogs } from 'containers/time-log-feed/utils/utils';
import { groupBy, first, maxBy, isEmpty, compact } from 'lodash';
import { DateTime } from 'luxon';
import BreakActionType from 'types/enum/BreakActionType';
import { Optional } from 'types/util/Optional';
import { mapNotNull } from 'utils/collectionUtils';
import dateTimeFormat from 'utils/constants/dateTimeFormat';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { compareIsoTimeStamps, dateTimeFromISOKeepZone, formatOptionalTimeTimeRange } from 'utils/dateUtils';
import { getDifferentObjectKeysByValue } from 'utils/objectUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import { Project } from '__generated__/graphql';

export function getTimeEntryLogToFromBreakLabel(startIso: string, endIso: Optional<string>) {
  return formatOptionalTimeTimeRange(
    DateTime.fromISO(startIso),
    endIso ? DateTime.fromISO(endIso) : null,
    DateTimeFormat.timeSimple
  );
}

export function getBreakLogsFromLogFeedTimeEntryLog(log: Optional<LogFeedTimeEntryLog>) {
  return (
    compact(
      log?.timeEntry?.breaks
        ?.map((brk) => brk.clientLogs)
        ?.flat()
        ?.filter((breakLog) => breakLog?.clientTimeEntryLogId === log.id)
    ) ?? []
  );
}

export function convertLogFeedTimeEntryLogIntoDisplay(log: LogFeedTimeEntryLog) {
  return {
    project: log.project ? getFormattedPathFromProject(log.project as Project, true) : '---',
    costCode: log.costCode ? getCostCodeDisplay(log.costCode) : '---',
    equipment: log.equipment ? getEquipmentDisplay(log.equipment) : '---',
    startTime: dateTimeFromISOKeepZone(log.startTime).toFormat(dateTimeFormat.dateAtTimeDay),
    endTime: log.endTime ? dateTimeFromISOKeepZone(log.endTime).toFormat(dateTimeFormat.dateAtTimeDay) : '---',
  };
}

export function parseLogsForBreakChanges(currentLog: LogFeedTimeEntryLog, previousLog: LogFeedTimeEntryLog) {
  const currentBreakLogs = getBreakLogsFromLogFeedTimeEntryLog(currentLog);
  // Not positive if the filter is needed but it probably joins on the new logs since it's joined on the time entry
  const previousBreakLogs =
    compact(previousLog?.timeEntry?.breaks?.map((brk) => brk.clientLogs)?.flat())?.filter(
      (prev) =>
        prev.clientTimeEntryLogId !== currentLog.id &&
        compareIsoTimeStamps(prev.createdOn, currentLog.createdOn, (first, second) => first < second)
    ) ?? [];

  const previousBreakLogsGrouped = groupBy(previousBreakLogs, 'timeEntryBreakId');
  const currentBreakLogsGrouped = groupBy(currentBreakLogs, 'timeEntryBreakId');

  return mapNotNull(Object.keys(currentBreakLogsGrouped), (key) => {
    const currentBreakLog = first(currentBreakLogsGrouped[key])!;
    const previousBreakLogs = previousBreakLogsGrouped[key];

    if (currentBreakLog.actionType === BreakActionType.BREAK_DELETE) {
      const previousRecent = maxBy(previousBreakLogs, ({ createdOn }) => DateTime.fromISO(createdOn).toSeconds())!;

      return {
        from: getTimeEntryLogToFromBreakLabel(previousRecent.startTime, previousRecent.endTime),
        to: null,
      };
    } else if (isEmpty(previousBreakLogs)) {
      return {
        from: null,
        to: getTimeEntryLogToFromBreakLabel(currentBreakLog.startTime, currentBreakLog.endTime),
      };
    } else {
      const previousRecent = maxBy(previousBreakLogs, ({ createdOn }) => DateTime.fromISO(createdOn).toSeconds())!;
      const relevantPrevious = { startTime: previousRecent.startTime, endTime: previousRecent.endTime };
      const relevantCurrent = { startTime: currentBreakLog?.startTime, endTime: currentBreakLog?.endTime };

      const diffedBreakKeys = getDifferentObjectKeysByValue(relevantPrevious, relevantCurrent);

      if (!isEmpty(diffedBreakKeys)) {
        return {
          from: getTimeEntryLogToFromBreakLabel(relevantPrevious.startTime, relevantPrevious.endTime),
          to: getTimeEntryLogToFromBreakLabel(relevantCurrent.startTime, relevantCurrent.endTime),
        };
      } else {
        return null;
      }
    }
  });
}
