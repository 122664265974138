import { IChildrenProps } from 'types/ChildrenProps';

export interface IConditionalContainerProps extends IChildrenProps {
  condition: boolean;
}

export default function ConditionalContainer({ children, condition }: IConditionalContainerProps) {
  if (!condition) {
    return null;
  }

  return <>{children}</>;
}
