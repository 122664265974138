import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import ErrorLine from 'components/foundation/ErrorLine/ErrorLine';
import AdjustingDateRange, {
  IAdjustingDateRangeProps,
} from 'components/foundation/ranges/AdjustingDateRange/AdjustingDateRange';
import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';

type Value = ITimeRange<DateTime | null>;

export interface IAdjustingDateRangeFormFieldProps<FormType extends { [field: string]: any }>
  extends IFormFieldDefaultProps<Value, FormType>,
  Omit<IAdjustingDateRangeProps, 'onChange' | 'value'> {
  onChange?: (value: Value) => void;
  className?: ClassName;
}

function AdjustingDateRangeFormField<FormType extends { [field: string]: any }>({
  form,
  name,
  validations,
  onChange,
  className,
  revalidateOnNewValidations,
  ...rest
}: IAdjustingDateRangeFormFieldProps<FormType>) {
  function renderField({ ...fieldProps }) {
    function handleOnChange(value: Value) {
      fieldProps.handleChange(value, onChange);
    }

    const value = form.state.data[name];
    return (
      <>
        <AdjustingDateRange value={value} onChange={handleOnChange} {...rest} />
        {fieldProps.hasError && <ErrorLine className="mt-2" />}
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      className={classNames('adjusting-date-range-form-field', className)}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

export default AdjustingDateRangeFormField;
