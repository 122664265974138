import { PROJECT_COST_CODE_QUANTITY_QUERY } from 'apollo/queries/project-cost-code-quantity-queries';
import { assignItemsToRanges, zipDaysToValue } from 'containers/dashboard/util/utils';
import { useAsyncApollo } from 'hooks';
import sumBy from 'lodash/sumBy';
import { DateTime } from 'luxon';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import IProjectCostCodeQuantity from 'types/ProjectCostCodeQuantity';
import ITimeRange from 'types/TimeRange';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';

export default function useProgressDashboardCard(
  timeRange: ITimeRange<DateTime>,
  ranges: Array<ITimeRange<number>>,
  memberId?: string,
  projectId?: string,
  costCodeId?: string
) {
  const fetch = useAsyncApollo<IProjectCostCodeQuantity & ICursorable>(
    'projectCostCodeQuantities',
    {
      query: PROJECT_COST_CODE_QUANTITY_QUERY,
      variables: {
        filter: {
          project: {
            idWithDescendants: getGraphQlEqualComparison(projectId),
            archivedOn: { isNull: true },
          },
          costCode: {
            id: getGraphQlEqualComparison(costCodeId),
            archivedOn: { isNull: true },
          },
          submittedByMember: {
            id: getGraphQlEqualComparison(memberId),
            permissions: {
              permissions: MemberPermission.CAN_MANAGE_PROJECT_COST_CODE_QUANTITY,
              operationType: OperationType.AND,
            },
          },
          deletedOn: { isNull: true },
          assignedOn: {
            between: {
              start: timeRange.startTime.toISO(),
              end: timeRange.endTime.toISO(),
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    },
    false
  );

  const data = zipDaysToValue(
    assignItemsToRanges(ranges, fetch.data ?? [], (item) => dateTimeFromISOWithoutZone(item.assignedOn))
  );

  return {
    queryData: fetch.data,
    data,
    total: sumBy(data, (datum) => datum.value),
    loading: fetch.loading,
    error: fetch.error,
    execute: fetch.execute,
  };
}
