import { useOrganization } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import TimeRangeType from 'types/TimeRangeType';
import { getPayPeriodTimeRange } from 'utils/payPeriodUtils';
import { getWeekRangeForTime } from 'utils/timeRangeUtils';

export default function useTimeRangeTypeTimeRangeConverter() {
  const { organizationOvertimePeriods, organizationPayPeriod } = useOrganization();
  return useCallback(
    (
      type: TimeRangeType,
      zone: 'system' | 'utc',
      time: DateTime = DateTime.local().setZone(zone, { keepLocalTime: true })
    ) => {
      switch (type) {
        case TimeRangeType.DAILY:
          return { startTime: time.startOf('day'), endTime: time.endOf('day').set({ millisecond: 0 }) };
        case TimeRangeType.WEEKLY:
          return getWeekRangeForTime(organizationOvertimePeriods!, time.toSeconds(), zone)!;
        case TimeRangeType.MONTHLY:
          return { startTime: time.startOf('month'), endTime: time.endOf('month').set({ millisecond: 0 }) };
        case TimeRangeType.YEARLY:
          return { startTime: time.startOf('year'), endTime: time.endOf('year').set({ millisecond: 0 }) };
        case TimeRangeType.PAY_PERIOD:
          return getPayPeriodTimeRange(organizationPayPeriod!, time.toSeconds(), zone);
        case TimeRangeType.CUSTOM:
        case TimeRangeType.ALL_TIME:
          return null;
      }
    },
    [organizationOvertimePeriods, organizationPayPeriod]
  );
}
