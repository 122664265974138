import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';

import './StateTemplate.scss';

export interface IStateTemplateProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  graphic?: ReactNode;
  footer?: ReactNode;
  className?: ClassName;
}

const StateTemplate: React.FunctionComponent<IStateTemplateProps> = (props) => {
  const { title, subtitle, footer, graphic, className } = props;

  const classes = classNames(
    {
      'state-template': true,
    },
    className
  );

  return (
    <div className={classes}>
      <div>{graphic}</div>
      {title && (
        <div>
          <h4 className="fw-semi-bold">{title}</h4>
        </div>
      )}
      {subtitle && (
        <div className="pt-1">
          <h6 className="fc-1">{subtitle}</h6>
        </div>
      )}
      {footer && <div className="pt-2">{footer}</div>}
    </div>
  );
};

export default StateTemplate;
