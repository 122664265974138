import { useLoader } from 'contexts/LoaderContext';
import { useCallback } from 'react';
import { t } from 'utils/localize';

export default function useLoaderPromise<T, Args extends any[]>(
  request: (...args: Args) => Promise<T>,
  loaderText: string = t('Loading...')
) {
  const { open, close } = useLoader();

  return useCallback(
    async (...args: Args) => {
      open(loaderText);
      try {
        const result = await request(...args);
        close();
        return result;
      } finally {
        close();
      }
    },
    [close, loaderText, open, request]
  );
}
