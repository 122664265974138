import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { useActiveMember, useOpenable } from 'hooks';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { ITimeEntryDataTableRow } from '../../TimeEntryDataTable/TimeEntryDataTable';
import TimeEntryDataTableHeader from '../../TimeEntryDataTable/TimeEntryDataTableHeader/TimeEntryDataTableHeader';
import TimeEntryDataReport from '../TimeEntryDataReport';
import useCheckArchivedOfCostCodes from '../hooks/useCheckArchivedOfCostCodes';
import useCheckArchivedOfMembers from '../hooks/useCheckArchivedOfMembers';
import useCheckArchivedOfProject from '../hooks/useCheckArchivedOfProject';
import useCheckCanMangeTimeForMembers from '../hooks/useCheckCanMangeTimeForMembers';
import useCheckDeletedOfEquipment from '../hooks/useCheckDeleteOfEquipment';
import ITimeEntryReportWithHeaderData from '../types/TimeEntryReportWithHeaderData';
import './EquipmentTimeEntryDataReport.scss';

export interface IEquipmentTimeEntryDataReportProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  selectedIds: string[];
  onSelectChange?: (rows: ITimeEntryDataTableRow[]) => void;
  columnSettings: IVisibleTableColumn[];
  data: ITimeEntryReportWithHeaderData[];
  loading: boolean;
  updateEquipmentIds: (equipmentIds: string[]) => void;
  memberIds?: string[] | null;
  projectId?: string | null;
  costCodeId?: string | null;
  loadedAll: boolean;
  applyPrintOptions: boolean;
}

const EquipmentTimeEntryDataReport = ({
  className,
  timeRange,
  columnSettings,
  selectedIds,
  onSelectChange,
  data,
  loading,
  updateEquipmentIds,
  memberIds,
  projectId,
  costCodeId,
  loadedAll,
  applyPrintOptions,
}: IEquipmentTimeEntryDataReportProps) => {
  const timeEntryDialogOpenable = useOpenable();
  const [equipmentId, setEquipmentId] = useState<string | null>(null);

  const activeMember = useActiveMember();
  const canManageTimeEntriesForAnyone = (activeMember.position?.manageTimeEntries ?? 0) > 0;
  const checkMembers = !isNilOrEmpty(memberIds) ? memberIds : canManageTimeEntriesForAnyone ? [activeMember.id!] : [];
  const memberArchived = useCheckArchivedOfMembers(checkMembers);
  const canManageMembers = useCheckCanMangeTimeForMembers(checkMembers);
  const checkArchivedOfProjects = useCheckArchivedOfProject();
  const [dialogProjectId, setDialogProjectId] = useState<string | null | undefined>(null);
  const checkArchivedOfCostCodes = useCheckArchivedOfCostCodes();
  const [dialogCostCodeId, setDialogCostCodeId] = useState<string | null | undefined>(null);
  const checkDeletedOfEquipment = useCheckDeletedOfEquipment();

  function renderHeader(item: ITimeEntryReportWithHeaderData) {
    async function handleAddEntry() {
      if (item.id === remainingDataItemId || (await checkDeletedOfEquipment([item.id]))) {
        setEquipmentId(null);
      } else {
        setEquipmentId(item.id);
      }
      if (await checkArchivedOfProjects(!isNil(projectId) ? [projectId] : null)) {
        setDialogProjectId(null);
      } else {
        setDialogProjectId(projectId);
      }
      if (await checkArchivedOfCostCodes(!isNil(costCodeId) ? [costCodeId] : null)) {
        setDialogCostCodeId(null);
      } else {
        setDialogCostCodeId(costCodeId);
      }
      timeEntryDialogOpenable.open();
    }

    return (
      <TimeEntryDataTableHeader
        title={item.title}
        total={item.total}
        openAddEntry={memberArchived === false && canManageMembers ? handleAddEntry : undefined}
      />
    );
  }

  function updateMemberIds(memberIds: string[]) {
    const equipmentIdsToUpdate: string[] = [];
    data.map((d) => {
      const rowMemberIds = d.rows.map((r) => r.member.id);
      const [matched] = _.partition(rowMemberIds, (rowMemberId) =>
        _.some(memberIds, (memberId) => memberId === rowMemberId)
      );
      if (!_.isEmpty(matched)) {
        equipmentIdsToUpdate.push(d.id);
      }
    });
    const uniqIds = _.uniq(equipmentIdsToUpdate);
    updateEquipmentIds(_.isEmpty(uniqIds) && equipmentId ? [equipmentId] : uniqIds);
    setEquipmentId(null);
  }

  function handleAddEntrySubmit(data: ITimeActionsFormData) {
    updateMemberIds(data.members);
    timeEntryDialogOpenable.close();
  }

  return (
    <>
      <TimeEntryDataReport
        data={data}
        loading={loading}
        columnSettings={columnSettings}
        className={classNames('equipment-time-entry-data-report', className)}
        selectedIds={selectedIds}
        onSelectChange={onSelectChange}
        timeRange={timeRange}
        renderHeader={renderHeader}
        onDataChange={updateMemberIds}
        loadedAll={loadedAll}
        applyPrintOptions={applyPrintOptions}
      />
      <TimeActionsFormDialog
        type={'add'}
        equipmentId={equipmentId}
        projectId={dialogProjectId}
        memberIds={memberIds ?? []}
        costCodeId={dialogCostCodeId}
        currentDate={timeRange.endTime}
        isOpen={timeEntryDialogOpenable.isOpen}
        onClose={timeEntryDialogOpenable.close}
        onSubmit={handleAddEntrySubmit}
        onDelete={handleAddEntrySubmit}
      />
    </>
  );
};

EquipmentTimeEntryDataReport.defaultProps = {
  applyPrintOptions: true,
};

export default EquipmentTimeEntryDataReport;
