import { ITimeGraphData } from 'hooks/aggregates/useMemberGraphAggregates';
import { groupBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import IProjectTimeMetrics from 'types/aggregate/ProjectLaborMetrics';
import { isArrayOfType } from './typeguard';

export function metricDataToTimeGraphData<T extends IJitLaborMetric | IProjectTimeMetrics>(
  metricData: T[],
  ranges: DateTime[]
): ITimeGraphData[] {
  // check if our data is tied to a project
  if (isArrayOfType<IProjectTimeMetrics>(metricData, 'descRt')) {
    return projectMetricDataToTimeGraphData(metricData, ranges);
  }

  // metric data not tied to a project
  return regularMetricDataToTimeGraphData(metricData, ranges);
}

export function regularMetricDataToTimeGraphData<T extends IJitLaborMetric>(
  metricData: T[],
  ranges: DateTime[]
): ITimeGraphData[] {
  const grouped = groupBy(metricData, (aggregate) => aggregate.start);

  return ranges.map((day) => {
    const value = grouped[day.toISODate()];
    const regularHours = sumBy(value, (data: T) => (data.rt ?? 0) + (data.pto ?? 0));
    const overtimeHours = sumBy(value, (data: T) => data.ot ?? 0);
    const doubleTimeHours = sumBy(value, (data: T) => data.dt ?? 0);

    return {
      day: day.toSeconds(),
      totalHours: regularHours + overtimeHours + doubleTimeHours,
      regularHours,
      overtimeHours,
      doubleTimeHours,
    };
  });
}

export function projectMetricDataToTimeGraphData<T extends IProjectTimeMetrics>(
  metricData: T[],
  ranges: DateTime[]
): ITimeGraphData[] {
  const grouped = groupBy(metricData, (aggregate) => aggregate.start);

  return ranges.map((day) => {
    const value = grouped[day.toISODate()];
    const regularHours = sumBy(value, (data: T) => (data.rt ?? 0) + (data.descRt ?? 0));
    const overtimeHours = sumBy(value, (data: T) => (data.ot ?? 0) + (data.descOt ?? 0));
    const doubleTimeHours = sumBy(value, (data: T) => (data.dt ?? 0) + (data.descDt ?? 0));

    return {
      day: day.toSeconds(),
      totalHours: regularHours + overtimeHours + doubleTimeHours,
      regularHours,
      overtimeHours,
      doubleTimeHours,
    };
  });
}
