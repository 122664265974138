import { FetchResult, useMutation } from '@apollo/client';
import { CREATE_TIME_OFFS } from 'apollo/mutations/time-off-mutations';
import { createTimeOffCreateInput } from 'components/domain/time-off/form/utils/utils';
import { DocumentNode } from 'graphql';
import { useActiveMember } from 'hooks/store';
import _ from 'lodash';
import TimeOffType from 'types/enum/TimeOffType';

export default function useTimeOffCreate<T>(createTimeOffDocumentNode: DocumentNode = CREATE_TIME_OFFS) {
  const activeMember = useActiveMember();
  const [createTimeOffMutations] = useMutation<T>(createTimeOffDocumentNode);

  return async function createTimeOff(
    dates: Date[],
    memberIds: string[],
    description: string | null,
    totals: { [key: string]: number } | null,
    paid: boolean,
    type: TimeOffType
  ): Promise<Array<FetchResult<T>>> {
    const timeOffInputs = createTimeOffCreateInput(dates, memberIds, description, totals, paid, type, activeMember.id!);
    const promises = _.chunk(timeOffInputs, 10).map(async (chunkedTimeOffs) => {
      return createTimeOffMutations({ variables: { timeOffs: chunkedTimeOffs } });
    });

    return (await Promise.all(promises)).flat();
  };
}
