import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { ApolloGetAll } from 'hooks/apollo/useApolloPaging';
import { DateTime } from 'luxon';
import IEquipment from 'types/Equipment';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { LaborMetricsInterval } from '__generated__/graphql';
import { MetricQueryProvider } from '../useMetricAggregates';
import { EQUIPMENT_LABOR_METRIC_QUERY, EQUIPMENT_TIME_METRIC_QUERY } from './equipment-metric-queries';

export function equipmentTimeMetricQueryProvider(
  getAll: ApolloGetAll<IEquipment>,
  equipmentIds?: string[],
  equipmentCategoryId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const equipments = await getAll('equipment', {
      query: EQUIPMENT_TIME_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: equipmentIds ? { contains: equipmentIds } : undefined,
          model: equipmentCategoryId ? { equipmentCategoryId: { equal: equipmentCategoryId } } : undefined,
          deletedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          equipmentWithTime: {
            startTime: start.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: end.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
          },
        },
      },
    });

    return equipments.flatMap((equipment) => equipment.equipmentLaborMetrics ?? []);
  };
}

export function equipmentLaborMetricQueryProvider(
  getAll: ApolloGetAll<IEquipment>,
  equipmentIds?: string[],
  equipmentCategoryId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const equipments = await getAll('equipment', {
      query: EQUIPMENT_LABOR_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: equipmentIds ? { contains: equipmentIds } : undefined,
          model: equipmentCategoryId ? { equipmentCategoryId: { equal: equipmentCategoryId } } : undefined,
          deletedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          equipmentWithTime: {
            startTime: start.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: end.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
          },
        },
      },
    });

    return equipments.flatMap((equipment) => equipment.equipmentLaborMetrics ?? []);
  };
}
