import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import { DashboardSettings } from 'containers/dashboard/types/types';
import useTimeLogFeedEnabled from 'containers/time-log-feed/useTimeLogFeedEnabled/useTimeLogFeedEnabled';
import useTimeLogNavigation from 'hooks/navigation/useTimeLogNavigation';
import { t } from 'i18next';
import { colors } from 'utils/constants/colors';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import useTimeLogFeedDashboardData from './hooks/useTimeLogFeedDashboardData/useTimeLogFeedDashboardData';
import TimeLogFeedDashboardCardList from './TimeLogFeedDashboardCardList/TimeLogFeedDashboardCardList';

export interface ITimeLogFeedDashboardCardProps {
  setting: DashboardSettings['timeLogs'];
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
}

function TimeLogFeedDashboardCardEnabledContainer({ setting, theme, className }: ITimeLogFeedDashboardCardProps) {
  const timeLogFeedEnabled = useTimeLogFeedEnabled();

  if (!timeLogFeedEnabled) {
    return null;
  }

  return <TimeLogFeedDashboardCard setting={setting} theme={theme} className={className} />;
}

function TimeLogFeedDashboardCard({ setting, theme, className }: ITimeLogFeedDashboardCardProps) {
  const navigate = useTimeLogNavigation();

  const { data, execute, loading } = useTimeLogFeedDashboardData();

  const classes = classNames('time-log-feed-dashboard-card', className);

  return (
    <DashboardContentCard
      subheaderValue={setting.title}
      tabColor={colors.primary}
      theme={theme}
      className={classes}
      rightLabel={t('View All')}
      onRightLabelClick={navigate}
    >
      {loading ? (
        <DashboardCardSpinner />
      ) : (
        <TimeLogFeedDashboardCardList theme={theme} data={data ?? []} reload={execute} />
      )}
    </DashboardContentCard>
  );
}

export default TimeLogFeedDashboardCardEnabledContainer;
