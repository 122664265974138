import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IEmployeeSettings,
  IEquipmentSettings,
} from 'containers/daily-project-report/DailyReportPreview/PreviewSettings/PreviewSettings';
import { DateTime } from 'luxon';
import { IDailyReportSettings, IMediaCollection } from 'types';
import { IDailyReportComment } from 'types/DailyProjectReport/DailyReportComment';
import { IDailyReportProgressMediaCollection } from 'types/DailyProjectReport/DailyReportProgressMediaCollection';
import { IDailyReportProgressSettings } from 'types/DailyProjectReport/DailyReportProgressSettings';
import IEquipment, { default as Equipment } from 'types/Equipment';
import ISafetySignature from 'types/SafetySignature';
import ITimeEntry from 'types/TimeEntry';
import IWeatherHourly from 'types/WeatherHourly';
import { t } from 'utils/localize';
import LocalStorage from 'utils/localStorageUtils';

export interface IStoreDailyReport {
  projectId: string | null | undefined;
  memberId: string | null | undefined;
  currentViewingDate: number | null | undefined;
  weatherData: IWeatherHourly[] | null | undefined;
  photoData: IMediaCollection[] | null | undefined;
  timeEntryData: ITimeEntry[] | null | undefined;
  safetyData: ISafetySignature[] | null | undefined;
  settingsData: IDailyReportSettings | null | undefined;
  weatherUnitType: string;
  weatherTimeOne: number;
  weatherTimeTwo: number;
  weatherTimeThree: number;
  showCorrectTime: boolean;
  showInjured: boolean;
  showEntries: boolean;
  showHours: boolean;
  showOperator: boolean;
  showMakeModel: boolean;
  showEquipmentHours: boolean;
  showCostCodeHours: boolean;
  showCompleted: boolean;
  safetyText: string;

  selectedProgressData: IDailyReportProgressMediaCollection[] | null | undefined;
  selectedSafetyPhotos: IDailyReportProgressMediaCollection[] | null | undefined;
  selectedColor: string;
  comments: IDailyReportComment[] | null | undefined;
  signOffSignature: string | null | undefined;
  onSiteEquipment: Equipment[] | null | undefined;
  checkedIds: string[];
  progressSettings: IDailyReportProgressSettings;
  showBreakCompliance: boolean;
  showSignInConcerns: boolean;
}

const busyReportSettings = LocalStorage.get('dailyReport');

let settingsData = {
  showCostCode: true,
  showEmployees: true,
  showEquipment: true,
  showProgress: true,
  showSafety: true,
  showSignature: true,
  showSubProject: false,
  showWeather: true,
  showCustom: true,
};

let weatherUnitType = 'auto';
let weatherTimeOne = 8;
let weatherTimeTwo = 12;
let weatherTimeThree = 17;
let showCorrectTime = true;
let showInjured = true;
let showEntries = true;
let showHours = true;
let showBreakCompliance = true;
let showSignInConcerns = true;
let showOperator = true;
let showEquipmentHours = true;
let showMakeModel = true;
let showCompleted = false;
let showCostCodeHours = true;
let safetyText = t('I certify that this report is correct to the best of my knowledge.');
let selectedProgressData = [];
let selectedSafetyPhotos = [];
let selectedColor = '#264968';
let comments = [];
const signOffSignature = null;
let progressSettings = {
  showProgressDetails: false,
  showProgressSummary: true,
  progressDetailsShowCompleted: true,
  progressDetailsShowEmployeeHours: true,
  progressSummaryShowCompleted: true,
  progressSummaryShowCostCodeHours: true,
  checklistActive: false,
};

if (busyReportSettings) {
  settingsData = busyReportSettings.settingsData;
  weatherUnitType = busyReportSettings.weatherUnitType;
  weatherTimeOne = busyReportSettings.weatherTimeOne;
  weatherTimeTwo = busyReportSettings.weatherTimeTwo;
  weatherTimeThree = busyReportSettings.weatherTimeThree;
  showCorrectTime = busyReportSettings.showCorrectTime;
  showInjured = busyReportSettings.showInjured;
  showEntries = busyReportSettings.showEntries;
  showHours = busyReportSettings.showHours;
  showOperator = busyReportSettings.showOperator;
  showEquipmentHours = busyReportSettings.showEquipmentHours;
  showMakeModel = busyReportSettings.showMakeModel;
  showCompleted = busyReportSettings.showCompleted;
  showCostCodeHours = busyReportSettings.showCostCodeHours;
  safetyText = busyReportSettings.safetyText;
  selectedProgressData = busyReportSettings.selectedProgressData;
  selectedSafetyPhotos = busyReportSettings.selectedSafetyPhotos;
  selectedColor = busyReportSettings.selectedColor;
  comments = busyReportSettings.comments;
  progressSettings = busyReportSettings.progressSettings ?? progressSettings;
  showSignInConcerns = busyReportSettings.showSignInConcerns;
  showBreakCompliance = busyReportSettings.showBreakCompliance;
}

export const initialState: IStoreDailyReport = {
  currentViewingDate: Math.round(DateTime.utc().toMillis() / 1000),
  projectId: null,
  memberId: null,
  weatherData: null,
  photoData: null,
  timeEntryData: null,
  safetyData: null,
  settingsData,
  weatherUnitType,
  weatherTimeOne,
  weatherTimeTwo,
  weatherTimeThree,
  showCorrectTime,
  showInjured,
  showEntries,
  showHours,
  showOperator,
  showEquipmentHours,
  showMakeModel,
  showCompleted,
  showCostCodeHours,
  safetyText,
  selectedProgressData,
  selectedSafetyPhotos,
  selectedColor,
  comments,
  signOffSignature,
  onSiteEquipment: [],
  checkedIds: [],
  progressSettings,
  showSignInConcerns,
  showBreakCompliance,
};

const slice = createSlice({
  name: 'dailyProjectReport',
  initialState,
  reducers: {
    setCheckedIds: (state: IStoreDailyReport, action: PayloadAction<string[]>) => {
      state.checkedIds = action.payload;

      return state;
    },
    setCostCodeSettings: (
      state: IStoreDailyReport,
      action: PayloadAction<{ showCompleted: boolean; showCostCodeHours: boolean }>
    ) => {
      state.showCompleted = action.payload.showCompleted;
      state.showCostCodeHours = action.payload.showCostCodeHours;
      return state;
    },
    setCreateDetails: (
      state: IStoreDailyReport,
      action: PayloadAction<{ currentViewingDate: number | null | undefined; projectId: string | null | undefined }>
    ) => {
      state.currentViewingDate = action.payload.currentViewingDate;
      state.projectId = action.payload.projectId;
      return state;
    },
    setEmployeeSettings: (state: IStoreDailyReport, action: PayloadAction<IEmployeeSettings>) => {
      state.showCorrectTime = action.payload.showCorrectTime;
      state.showHours = action.payload.showHours;
      state.showInjured = action.payload.showInjured;
      state.showEntries = action.payload.showEntries;
      state.showBreakCompliance = action.payload.showBreakCompliance;
      state.showSignInConcerns = action.payload.showSignInConcerns;
      return state;
    },
    setEquipmentSettings: (state: IStoreDailyReport, action: PayloadAction<IEquipmentSettings>) => {
      state.showOperator = action.payload.showOperator;
      state.showEquipmentHours = action.payload.showEquipmentHours;
      state.showMakeModel = action.payload.showMakeModel;
      return state;
    },
    setNewComment: (state: IStoreDailyReport, action: PayloadAction<IDailyReportComment[] | null | undefined>) => {
      state.comments = action.payload;
      return state;
    },
    setOnSiteEquipment: (state: IStoreDailyReport, action: PayloadAction<IEquipment[]>) => {
      state.onSiteEquipment = action.payload;
      return state;
    },
    setPhotoData: (state: IStoreDailyReport, action: PayloadAction<IMediaCollection[]>) => {
      state.photoData = action.payload;
      return state;
    },
    setProgressData: (state: IStoreDailyReport, action: PayloadAction<IDailyReportProgressMediaCollection[]>) => {
      state.selectedProgressData = action.payload;
      return state;
    },
    setSafetyData: (state: IStoreDailyReport, action: PayloadAction<ISafetySignature[]>) => {
      state.safetyData = action.payload;
      return state;
    },
    setSafetyPhotos: (state: IStoreDailyReport, action: PayloadAction<IDailyReportProgressMediaCollection[]>) => {
      state.selectedSafetyPhotos = action.payload;
      return state;
    },
    setSafetyText: (state: IStoreDailyReport, action: PayloadAction<string>) => {
      state.safetyText = action.payload;
      return state;
    },
    setSelectedColor: (state: IStoreDailyReport, action: PayloadAction<string>) => {
      state.selectedColor = action.payload;
      return state;
    },
    setSettings: (state: IStoreDailyReport, action: PayloadAction<IDailyReportSettings>) => {
      state.settingsData = action.payload;
      return state;
    },
    setSignOffSignature: (state: IStoreDailyReport, action: PayloadAction<string | null>) => {
      state.signOffSignature = action.payload;
      return state;
    },
    setTimeEntryData: (state: IStoreDailyReport, action: PayloadAction<ITimeEntry[]>) => {
      state.timeEntryData = action.payload;
      return state;
    },
    setWeatherData: (state: IStoreDailyReport, action: PayloadAction<{ weatherData: IWeatherHourly[] | null }>) => {
      state.weatherData = action.payload.weatherData;
      return state;
    },
    setWeatherTimes: (
      state: IStoreDailyReport,
      action: PayloadAction<{ weatherTimeOne: number; weatherTimeTwo: number; weatherTimeThree: number }>
    ) => {
      state.weatherTimeOne = action.payload.weatherTimeOne;
      state.weatherTimeTwo = action.payload.weatherTimeTwo;
      state.weatherTimeThree = action.payload.weatherTimeThree;
      return state;
    },
    setWeatherUnitType: (state: IStoreDailyReport, action: PayloadAction<string>) => {
      state.weatherUnitType = action.payload;
      return state;
    },
    setProgressSettings: (state: IStoreDailyReport, action: PayloadAction<IDailyReportProgressSettings>) => {
      state.progressSettings = action.payload;
      return state;
    },
  },
});

export const {
  setCheckedIds,
  setCostCodeSettings,
  setCreateDetails,
  setEmployeeSettings,
  setEquipmentSettings,
  setNewComment,
  setOnSiteEquipment,
  setPhotoData,
  setProgressData,
  setSafetyData,
  setSafetyPhotos,
  setSafetyText,
  setSelectedColor,
  setSettings,
  setSignOffSignature,
  setTimeEntryData,
  setWeatherData,
  setWeatherTimes,
  setWeatherUnitType,
  setProgressSettings,
} = slice.actions;
export default slice.reducer;
