import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPayStubsTableRow } from 'containers/payroll/payStubs/PayStubsTable/PayStubsTable';

export interface IStorePayStubsReport {
  checkedPayStubs: IPayStubsTableRow[];
}

export const initialState: IStorePayStubsReport = {
  checkedPayStubs: [],
};

const slice = createSlice({
  name: 'PayStubsReport',
  initialState,
  reducers: {
    updateCheckedPayStubs: (state: IStorePayStubsReport, action: PayloadAction<IPayStubsTableRow[]>) => {
      state.checkedPayStubs = action.payload;

      return state;
    },
    clearCheckedPayStubs: (state: IStorePayStubsReport) => {
      state.checkedPayStubs = [];
      return state;
    },
  },
});

export const { updateCheckedPayStubs, clearCheckedPayStubs } = slice.actions;
export default slice.reducer;
