import { isStringInUnionList } from 'utils/stringUtils';
import { OrganizationDocument } from '__generated__/graphql';
import {
  fileExtensionToIconLookup,
  supportedDocumentFileExtensions,
  supportedDocumentFileType,
  TSupportedDocumentFileExtensions,
  TSupportedDocumentFileType,
} from '../constants/constants';

export function isSupportedDocumentFileType(value: string): value is TSupportedDocumentFileType {
  return isStringInUnionList<TSupportedDocumentFileType>(value, supportedDocumentFileType);
}

export function isSupportedDocumentFileExtension(value: string): value is TSupportedDocumentFileExtensions {
  return isStringInUnionList<TSupportedDocumentFileExtensions>(value, supportedDocumentFileExtensions);
}

export function getDocumentFileName({ title, fileType }: Pick<OrganizationDocument, 'title' | 'fileType'>) {
  return `${title}.${fileType}`;
}

export function getDocumentFileIcon(fileType: TSupportedDocumentFileExtensions) {
  return fileExtensionToIconLookup[fileType];
}
