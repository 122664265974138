import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import {
  SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { first, groupBy, isEmpty, keys, sortBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { getMemberProjectCostCodeEquipmentMetricData } from 'utils/jitMetricQueries';
import { aggregateMetricAggregate } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals, getGeneratedProjectEquipmentMetricLaborTotals } from 'utils/metricUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import {
  ConditionNullableFieldType,
  Equipment,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Scalars,
} from '__generated__/graphql';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';

export interface IEquipmentDateActivityTableRowInfo extends IEquipmentActivityTableRowInfo {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

interface EquipmentMetricRow extends LaborMetricsInterface {
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useEquipmentActivityDateRangeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const remainingItemRef = useRef<IEquipmentDateActivityTableRowInfo>();
  const [data, setData] = useState<IEquipmentDateActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'equipment',
    SortDirection.ASCENDING,
    getSortField
  );

  function getSortField(item: IEquipmentDateActivityTableRowInfo, key: keyof IEquipmentDateActivityTableRowInfo) {
    if (key === 'equipment') {
      return getEquipmentDisplay(item.equipment);
    } else {
      return item[key];
    }
  }

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? SIMPLE_EQUIPMENT_WITH_METRIC_QUERY : SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function loadData() {
    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: intervalType,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const tableRows = mapNotNull(equipment, (e) => createTableRowInfo(e));

    remainingItemRef.current = await getNoEquipmentMetrics();

    setData(tableRows);
  }

  const createTableRowInfo = (equipment: Equipment): IEquipmentDateActivityTableRowInfo | null => {
    const metrics = laborMetrics(equipment);

    const unsortedDateRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedProjectEquipmentMetricLaborTotals(metric);
      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: equipment.id + metric.start,
        startDate: dateTimeFromISOWithoutZone(metric.start),
        endDate: dateTimeFromISOWithoutZone(metric.end),
        equipment,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });
    const dateRows = sortBy(unsortedDateRows, (row) => row.startDate.toSeconds());

    if (isEmpty(dateRows)) {
      return null;
    } else {
      const equipmentTotalMetrics = aggregateActivityRows(dateRows);
      return {
        ...equipmentTotalMetrics,
        id: equipment.id,
        startDate: null,
        endDate: null,
        equipment,
        detailRows: dateRows,
        equipmentCost: sumBy(dateRows, (row) => row.equipmentCost),
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: sumBy(dateRows, (row) => row.equipmentCost) + sumBy(dateRows, (row) => row.totalCost),
      };
    }
  };

  async function getNoEquipmentMetrics(): Promise<IEquipmentDateActivityTableRowInfo | undefined> {
    const metrics = await getMemberProjectCostCodeEquipmentMetricData(
      client,
      timeRange,
      canViewCost,
      intervalType,
      filterType === ActivityReportType.BY_EMPLOYEE ? filterId : undefined,
      filterType === ActivityReportType.BY_PROJECT ? filterId : undefined,
      filterType === ActivityReportType.BY_COST_CODE ? filterId : undefined,
      remainingDataItemId
    );

    const metricsByDate = groupBy(metrics, (data) => data.start);
    const dates = keys(metricsByDate);
    const dateRows = mapNotNull(dates, (date) => {
      const metricsOnDate = metricsByDate[date];
      const dateMetrics = aggregateMetricAggregate(metricsOnDate as IJitLaborMetric[]);

      const firstRow = first(metricsOnDate)!;

      // needs to igonore decendants when not filtering by a project id so we don't double count sub project data
      const ignoreDecendants = filterType === ActivityReportType.BY_PROJECT ? filterIdWithDescendants === false : true;
      const metricTotal = getGeneratedMetricLaborTotals(dateMetrics, ignoreDecendants);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + date,
        startDate: dateTimeFromISOWithoutZone(firstRow.start),
        endDate: dateTimeFromISOWithoutZone(firstRow.end),
        equipment: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
      };
    });

    if (!isEmpty(dateRows)) {
      const noEquipmentData = aggregateActivityRows(dateRows);

      return {
        equipment: null,
        startDate: null,
        endDate: null,
        ...noEquipmentData,
        detailRows: dateRows,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
      };
    } else {
      return undefined;
    }
  }

  const laborMetrics = (e: Equipment): EquipmentMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return e.equipmentProjectLaborMetrics;
      case ActivityReportType.BY_EMPLOYEE:
        return e.equipmentMemberLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return e.equipmentCostCodeLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return e.equipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
