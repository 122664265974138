import { MULTIPLE, TMultipleField } from '../BulkEditEntryForm/BulkEditEntryForm';

export enum BulkEditActionType {
  SET_ORIGINAL = 'SET_ORIGINAL',
  SET_CURRENT = 'SET_CURRENT',
  CLEAR_CURRENT = 'CLEAR_CURRENT',
  UNDO_CURRENT = 'UNDO_CURRENT',
  CLEAR = 'CLEAR',
  DISABLE_UNDO = 'DISABLE_UNDO',
}

export interface IUndoableClearableFieldState {
  original: TMultipleField<string>;
  current: TMultipleField<string>;
  undoable: boolean | null; // null to cover edge case of project cost codes :|
  clearable: boolean;
}

export type BulkEditActions =
  | { type: BulkEditActionType.SET_ORIGINAL; payload: { value: TMultipleField<string> } }
  | { type: BulkEditActionType.SET_CURRENT; payload: { value: TMultipleField<string> } }
  | { type: BulkEditActionType.CLEAR_CURRENT }
  | { type: BulkEditActionType.UNDO_CURRENT }
  | { type: BulkEditActionType.CLEAR }
  | { type: BulkEditActionType.DISABLE_UNDO };

export const reducerBulkEditInitialState = {
  original: null,
  current: null,
  undoable: false,
  clearable: false,
};

export function bulkEditReducer(
  state: IUndoableClearableFieldState,
  action: BulkEditActions
): IUndoableClearableFieldState {
  if (action.type === BulkEditActionType.SET_ORIGINAL) {
    const item = action.payload.value;
    return {
      original: item,
      current: item,
      undoable: false,
      clearable: item === MULTIPLE,
    };
  }

  if (action.type === BulkEditActionType.SET_CURRENT) {
    const item = action.payload.value;
    return {
      ...state,
      undoable: state.undoable === null ? null : item !== state.original,
      clearable: item !== MULTIPLE && state.original === MULTIPLE,
      current: item,
    };
  }

  if (action.type === BulkEditActionType.UNDO_CURRENT) {
    return {
      ...state,
      undoable: false,
      clearable: state.original === MULTIPLE,
      current: state.original,
    };
  }

  if (action.type === BulkEditActionType.CLEAR_CURRENT) {
    return {
      ...state,
      undoable: state.undoable === null ? null : state.original !== null,
      clearable: false,
      current: null,
    };
  }

  if (action.type === BulkEditActionType.CLEAR) {
    return {
      ...state,
      current: null,
      clearable: false,
      undoable: null, // Permanently breaks undo for project cost codes
    };
  }

  if (action.type === BulkEditActionType.DISABLE_UNDO) {
    return {
      ...state,
      undoable: null,
    };
  }

  return state;
}
