import gql from 'graphql-tag';

export const INCIDENT_DETAILS_FRAGMENT = gql`
  fragment IncidentDetails on Incident {
    id
    organizationId
    memberId
    type
    recordable
    projectId
    state
    illnessType
    caseNumber
    occurredAt
    occurrenceTimeSet
    employeeStartTime
    employeeJobTitle
    employeeBirthday
    employeeHireDate
    employeeSex
    employeeAddress1
    employeeCity
    employeeState
    employeePostalCode
    createdOn
    createdOnLocal
    submittedOn
    updatedOn
    deletedOn
    reviewedOn
    completedOn
    answers {
      id
      incidentId
      question
      type
      answer
    }
    project {
      id
      title
      depth
      ancestors {
        id
        title
        depth
      }
      children {
        id
        title
        depth
      }
    }
    member {
      id
      firstName
      lastName
      phone
    }
    medicalDetails {
      id
      incidentId
      treatmentER
      treatmentOvernight
      treatmentFirstAid
      treatmentDoctor
      treatmentNone
      treatmentOther
      facilityName
      facilityAddress
      facilityCity
      facilityState
      facilityPostalCode
      physicianName
    }
    submittedByMember {
      id
      firstName
      lastName
    }
    reviewedByMember {
      id
      firstName
      lastName
    }
    outcomeDetails {
      id
      type
      daysAway
      daysTransfer
      outcomeDate
    }
    completedByMember {
      id
      firstName
      lastName
    }
  }
`;

export const INCIDENTS_REPORTED_QUERY = gql`
  query Incident($after: String, $filter: IncidentFilter) {
    incidents(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      ...IncidentDetails
    }
  }
  ${INCIDENT_DETAILS_FRAGMENT}
`;
