import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { useOpenable } from 'hooks';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import { ITimeEntryDataTableRow } from '../../TimeEntryDataTable/TimeEntryDataTable';
import TimeEntryDataTableHeader from '../../TimeEntryDataTable/TimeEntryDataTableHeader/TimeEntryDataTableHeader';
import TimeEntryDataReport from '../TimeEntryDataReport';
import useCheckArchivedOfMembers from '../hooks/useCheckArchivedOfMembers';
import useCheckCanMangeTimeForMembers from '../hooks/useCheckCanMangeTimeForMembers';
import ITimeEntryReportWithHeaderData from '../types/TimeEntryReportWithHeaderData';
import './DateTimeEntryDataReport.scss';

export interface IDateTimeEntryDataReportProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  data: ITimeEntryReportWithHeaderData[];
  selectedIds: string[];
  onSelectChange?: (rows: ITimeEntryDataTableRow[]) => void;
  columnSettings: IVisibleTableColumn[];
  updateDates: (dates: string[]) => void;
  loading: boolean;
  memberIds?: string[] | null;
  loadedAll: boolean;
  applyPrintOptions: boolean;
}

const DateTimeEntryDataReport = (props: IDateTimeEntryDataReportProps) => {
  const {
    className,
    timeRange,
    data,
    columnSettings,
    selectedIds,
    onSelectChange,
    updateDates,
    loading,
    loadedAll,
    memberIds,
    applyPrintOptions,
  } = props;

  const timeEntryDialogOpenable = useOpenable();
  const [date, setDate] = useState<string | null>(null);
  const memberArchived = useCheckArchivedOfMembers(memberIds);
  const canManageMembers = useCheckCanMangeTimeForMembers(memberIds);

  function renderHeader(item: ITimeEntryReportWithHeaderData) {
    function handleAddEntry() {
      setDate(item.id);
      timeEntryDialogOpenable.open();
    }

    return (
      <TimeEntryDataTableHeader
        title={item.title}
        total={item.total}
        openAddEntry={memberArchived === false && canManageMembers ? handleAddEntry : undefined}
      />
    );
  }

  function updateMemberIds(memberIds: string[]) {
    const datesToUpdate: string[] = [];
    data.map((d) => {
      const rowMemberIds = d.rows.map((r) => r.member.id);
      const [matched] = _.partition(rowMemberIds, (rowMemberId) =>
        _.some(memberIds, (memberId) => memberId === rowMemberId)
      );
      if (!_.isEmpty(matched)) {
        datesToUpdate.push(d.id);
      }
    });
    const uniqDates = _.uniq(datesToUpdate);
    updateDates(_.isEmpty(uniqDates) && date ? [date] : uniqDates);
    setDate(null);
  }

  function handleAddEntrySubmit(data: ITimeActionsFormData) {
    updateMemberIds(data.members);
    timeEntryDialogOpenable.close();
  }

  return (
    <>
      <TimeEntryDataReport
        data={data}
        loading={loading}
        columnSettings={columnSettings}
        className={classNames('date-time-entry-data-report', className)}
        selectedIds={selectedIds}
        onSelectChange={onSelectChange}
        timeRange={timeRange}
        renderHeader={renderHeader}
        onDataChange={updateMemberIds}
        loadedAll={loadedAll}
        applyPrintOptions={applyPrintOptions}
      />
      <TimeActionsFormDialog
        type={'add'}
        currentDate={date ? DateTime.fromISO(date).plus({ days: 1 }) : timeRange.endTime}
        isOpen={timeEntryDialogOpenable.isOpen}
        onClose={() => {
          setDate(null);
          timeEntryDialogOpenable.close();
        }}
        onSubmit={handleAddEntrySubmit}
        onDelete={handleAddEntrySubmit}
        memberIds={memberIds ?? []}
      />
    </>
  );
};

DateTimeEntryDataReport.defaultProps = {
  applyPrintOptions: true,
};

export default DateTimeEntryDataReport;
