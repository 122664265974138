import { Button, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import { EquipmentIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { HeaderDialog, Panel } from 'components';
import { dashboardCardBarChartGradientYellowYellow } from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/constants/constants';
import DashboardCardBarChart from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/DashboardBarChart';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import HourMeterReportDialog from 'components/domain/hour-meter/HourMeterReportDialog/HourMeterReportDialog';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { convertDashboardTimeFrameToTimeRangeType, getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { getDashboardNavigationRange } from 'containers/scheduling/utils/utils';
import { useActiveMember, useEquipmentManagementListNavigation, useOpenable, useOrganization } from 'hooks';
import useOrganizationUpdate from 'hooks/models/organization/useOrganizationUpdate';
import useMachineVsOperatorReportNavigation from 'hooks/navigation/useMachineVsOperatorReportNavigation';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useFeatureFlags } from 'utils/features';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import useDashboardCardTotal from '../hooks/useDashboardCardTotal';
import useDashboardEquipmentHours from './hooks/useDashboardEquipmentHours';

export interface IEquipmentHoursDashboardCardProps {
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
  equipmentId?: string | null;
}

const EquipmentHoursDashboardCard = ({
  theme,
  className,
  setting,
  onHide,
  equipmentId,
}: IEquipmentHoursDashboardCardProps) => {
  const organization = useOrganization();
  const { timeRange, timeRanges } = useDashboardGraphDates(setting.options?.time)!; // Null force safe for all settings but `clockStatus`
  const { data, execute, loading, error } = useDashboardEquipmentHours(
    timeRange,
    timeRanges,
    setting.options!.time,
    equipmentId
  );

  const total = useDashboardCardTotal(
    timeRanges,
    null,
    null,
    data ?? [],
    setting.options?.time ?? 'payPeriod',
    loading
  );
  const [updateKey] = useOrganizationUpdate();
  const hourMeterReportDetails = useOpenable();
  const navigateToEquipmentManagement = useEquipmentManagementListNavigation();
  const activeMember = useActiveMember();
  const landingPagesIsEnabled = useFeatureFlags('LANDING_PAGES');

  const settingsDialogDetails = useOpenable();
  const machineVsOperatorHoursNavigation = useMachineVsOperatorReportNavigation();

  useEffect(() => {
    if (organization.trackEquipment) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.trackEquipment, timeRange]);

  function openEquipmentSettings() {
    updateKey('trackEquipment', true);
    settingsDialogDetails.open();
  }

  function openEquipmentManagement() {
    navigateToEquipmentManagement();
  }

  function getTimeRangeType() {
    const type = setting.options?.time ?? 'payPeriod';
    return convertDashboardTimeFrameToTimeRangeType(type);
  }

  function getNavRange() {
    return getDashboardNavigationRange(timeRange, timeRanges, setting.options?.time ?? 'payPeriod');
  }

  function openMachineVsOperatorReport() {
    const navRange = getNavRange();

    machineVsOperatorHoursNavigation({
      start_date: navRange.startTime.toFormat('M-d-yyyy'),
      end_date: navRange.endTime.toFormat('M-d-yyyy'),
      time_range_type: getTimeRangeType(),
    });
  }

  const classes = classNames('equipment-hours-dashboard-card', className);

  return (
    <>
      {organization.trackEquipment ? (
        error === null ? (
          <DashboardContentCard
            header={total}
            rightLabel={t('Details')}
            onRightLabelClick={
              equipmentId && landingPagesIsEnabled ? hourMeterReportDetails.open : openMachineVsOperatorReport
            }
            subheaderValue={getDashboardTimeFrameTitle(setting.title, setting.options?.time ?? 'payPeriod')}
            className={classes}
            theme={theme}
          >
            {loading ? (
              <DashboardCardSpinner />
            ) : (
              data && (
                <DashboardCardBarChart
                  data={data}
                  dataKey="value"
                  valueType="raw"
                  timeFrame={setting.options?.time ?? 'payPeriod'}
                  theme={theme}
                  {...dashboardCardBarChartGradientYellowYellow}
                />
              )
            )}
          </DashboardContentCard>
        ) : (
          <DashboardErrorCard theme={theme} onClick={execute} />
        )
      ) : activeMember.position?.manageCompanySettings ? (
        <DashboardEnableCard
          title={setting.title}
          svg={EquipmentIcon}
          buttonLabel={t('Enable Equipment')}
          onClick={openEquipmentSettings}
          theme={theme}
          onHide={onHide}
        />
      ) : null}
      <HeaderDialog
        title={t('Equipment Enabled')}
        isOpen={settingsDialogDetails.isOpen}
        onClose={settingsDialogDetails.close}
        position="center"
      >
        <Panel className="px-8 py-6">
          <div>
            {t(
              'Would you like to visit the equipment management page where you can create and edit equipment for your company?'
            )}
          </div>
          <Row justify={Justify.FLEX_END}>
            <Button type="secondary" onClick={settingsDialogDetails.close} className="mr-4">
              {t('No Thanks')}
            </Button>
            <Button type="primary" onClick={openEquipmentManagement}>
              {t('Manage')}
            </Button>
          </Row>
        </Panel>
      </HeaderDialog>
      {equipmentId && (
        <HourMeterReportDialog
          isOpen={hourMeterReportDetails.isOpen}
          onClose={hourMeterReportDetails.close}
          equipmentId={equipmentId}
          timeRange={getNavRange()}
          timeRangeType={getTimeRangeType()}
        />
      )}
    </>
  );
};

export default EquipmentHoursDashboardCard;
