import { ClassName, Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import * as React from 'react';
import { Component, ReactNode } from 'react';
import { LazyLoadPickerList, LazyLoadSearchablePickerList } from '../../..';
import Picker from '../Picker/Picker';

export interface ILazyLoadPickerProps<T extends { id: string; cursor?: string }> {
  'data': T[];
  'value': string | null; // UUID
  'loadedAll': boolean; // The lazy loader will continue to attempt network calls until loadedAll is set to false
  'getData': (after?: string) => Promise<T[]>;
  'didLoad': (items: T[], error: boolean, loadedAll: boolean) => void; // Needed to update the data and loadedAll props
  'renderRow': (row: T, index: number, e?: React.KeyboardEvent) => ReactNode;
  'valueTemplate': (value: string | null) => JSX.Element;
  'onSelect': (row: T | null) => void; // Needed to update the value prop
  'dropDownButtonRender'?: ReactNode; // Drop down button on the picker
  'closeButtonRender'?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
  'minWidth': string; // has default
  'renderSize'?: number; // has default
  'offset'?: number; // has default
  'placeholder'?: string;
  'loadingTemplate'?: ReactNode;
  'className'?: ClassName;
  'data-testid'?: string;
  'error'?: boolean;
  'dataError'?: boolean;
  'position'?: Position;
  'searchValue'?: string; // required for search
  'header'?: (sheet: LazyLoadPickerList<T>) => JSX.Element | JSX.Element[] | HTMLElement;
  'onOpen'?: () => void;
  'onClose'?: () => void;
  'willLoad'?: () => void;
  'onSearch'?: (value: string, e: React.ChangeEvent) => void; // required for search
  'disabled'?: boolean;
  'autofocus'?: boolean;
  'defaultFocusIndex'?: number;
  'onClear'?: () => void;
}

export class LazyLoadPicker<T extends { id: string; cursor?: string }> extends Component<ILazyLoadPickerProps<T>> {
  public static defaultProps: any;
  public picker?: HTMLElement;

  public componentDidMount = () => {
    if (this.props.autofocus && this.picker) {
      this.picker.focus();
    }
  };

  public handleSelect = (row: T | null) => {
    this.props.onSelect(row);
    if (this.picker) {
      this.picker.focus();
    }
  };

  public handleClear = () => {
    this.props.onSelect(null);
    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  public setPickerRef = (ref: HTMLElement) => {
    this.picker = ref;
  };

  public handleSearchChange = (value: string, e: React.ChangeEvent) => {
    if (this.props.onSearch) {
      this.props.onSearch(value, e);
    }
  };

  public renderPickerList = (closeMenu: () => void) => {
    const {
      value,
      getData,
      didLoad,
      onSearch,
      searchValue,
      loadedAll,
      renderRow,
      renderSize,
      offset,
      dataError,
      data,
      header,
      willLoad,
      defaultFocusIndex,
    } = this.props;

    const handleCloseMenu = (shouldFocus: boolean) => {
      if (this.picker && shouldFocus) {
        this.picker.focus();
      }
      closeMenu();
    };

    return (
      <>
        {onSearch && searchValue !== undefined ? (
          <LazyLoadSearchablePickerList
            getData={getData}
            didLoad={didLoad}
            renderRow={renderRow}
            value={value}
            data={data}
            error={dataError}
            loadedAll={loadedAll}
            closeMenu={handleCloseMenu}
            onSelect={this.handleSelect}
            renderSize={renderSize}
            offset={offset}
            willLoad={willLoad}
            searchValue={searchValue}
            onSearch={this.handleSearchChange}
            defaultFocusIndex={defaultFocusIndex}
          />
        ) : (
          <LazyLoadPickerList
            getData={getData}
            didLoad={didLoad}
            renderRow={renderRow}
            value={value}
            data={data}
            error={dataError}
            loadedAll={loadedAll}
            closeMenu={handleCloseMenu}
            onSelect={this.handleSelect}
            renderSize={renderSize}
            offset={offset}
            willLoad={willLoad}
            header={header}
            defaultFocusIndex={defaultFocusIndex}
          />
        )}
      </>
    );
  };

  public render() {
    const {
      value,
      placeholder,
      valueTemplate,
      className,
      'data-testid': testId,
      position,
      onClose,
      error,
      onOpen,
      closeButtonRender,
      dropDownButtonRender,
      disabled,
    } = this.props;

    const classes = classNames('basic-picker', className);

    return (
      <Picker
        value={value}
        error={error}
        onSelect={this.handleSelect}
        onClear={this.handleClear}
        className={classes}
        data-testid={testId}
        position={position}
        placeholder={placeholder}
        valueTemplate={valueTemplate}
        onOpen={onOpen}
        onClose={onClose}
        dropDownButtonRender={dropDownButtonRender}
        closeButtonRender={closeButtonRender}
        forwardRef={this.setPickerRef}
        minWidth={this.props.minWidth}
        contentTemplate={this.renderPickerList}
        disabled={disabled}
      />
    );
  }
}

LazyLoadPicker.defaultProps = {
  minWidth: '50px',
};

export default LazyLoadPicker;
