import { ClassName, IFormRender, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import BasicPickerFormField from 'components/foundation/form-fields/BasicPickerFormField/BasicPickerFormField';
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import Typography from 'components/foundation/text/Typography/Typography';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { getTimezoneObjects } from 'utils/timezoneUtils';
import { ITimeActionsFormData } from '../../hooks/useTimeActionsForm';
import './TimezoneFormField.scss';

export interface ITimezoneFormFieldProps {
  className?: ClassName;
  form: IFormRender<ITimeActionsFormData>;
  hasConflict: boolean;
}

const TimezoneFormField = (props: ITimezoneFormFieldProps) => {
  const { className, form, hasConflict } = props;
  const showTimezone = useOpenable();

  const timeZones: IBasicPickerValue<string>[] = getTimezoneObjects().map((zone) => {
    return { id: zone?.name, name: `${zone?.name} (${zone?.shortName})` } as unknown as IBasicPickerValue<string>;
  });

  const classes = classNames('timezone-form-field', className);

  return (
    <div className={classes}>
      <div className={`timezone-toggle ${hasConflict ? 'has-conflict' : ''}`}>
        <Typography tag="span" color="primary" onClick={showTimezone.toggle} fontSize={'12px'} selectable={false}>
          {!showTimezone.isOpen ? t('Show Time Zone') : t('Hide Time Zone')}
        </Typography>
      </div>
      {showTimezone.isOpen && (
        <div className="mb-5">
          <Label>{t('Time Zone')}</Label>
          <BasicPickerFormField form={form} name={'timezone'} data={timeZones} />
        </div>
      )}
    </div>
  );
};

export default TimezoneFormField;
