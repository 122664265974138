import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useApolloPaging } from 'hooks';
import { first, groupBy } from 'lodash';
import { mapNotNil } from 'utils/collectionUtils';
import { BudgetHours, BudgetHoursFilter } from '__generated__/graphql';

export default function useHoursBudgetData() {
  const client = useApolloClient();
  const { getAll } = useApolloPaging();

  async function getAllBudgetHoursData(filter: BudgetHoursFilter) {
    const results = await getAll<BudgetHours>('budgetHours', {
      query: BUDGET_HOURS_QUERY,
      variables: {
        first: 500,
        filter: {
          ...filter,
          isLatest: { equal: true },
        },
        sort: [{ createdOn: 'desc' }],
      },
      fetchPolicy: 'network-only',
    });
    return results.filter((budget) => budget.budgetSeconds > 0 || (budget.equipmentBudgetSeconds ?? 0) > 0);
  }

  async function getLatestBudgetHoursData(filter: BudgetHoursFilter) {
    const results = await client.query<{ budgetHours: BudgetHours[] }>({
      query: BUDGET_HOURS_QUERY,
      variables: {
        first: 1,
        filter: {
          ...filter,
          isLatest: { equal: true },
        },
        sort: [{ createdOn: 'desc' }],
      },
      fetchPolicy: 'network-only',
    });

    const filteredResults = results.data.budgetHours.filter(
      (budget) => budget.budgetSeconds > 0 || (budget.equipmentBudgetSeconds ?? 0) > 0
    );

    const groupedResults = groupBy(filteredResults, 'projectId');

    return mapNotNil(Object.keys(groupedResults), (key) => {
      return first(groupedResults[key]);
    });
  }

  return { getAllBudgetHoursData, getLatestBudgetHoursData };
}

export const BUDGET_HOURS_QUERY = gql`
  query budgetHoursQuery($filter: BudgetHoursFilter, $sort: [BudgetHoursSort!], $first: Int, $after: String) {
    budgetHours(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      projectId
      memberId
      budgetSeconds
      costCodeId
      equipmentId
      createdOn
      cursor
      equipmentBudgetSeconds
    }
  }
`;
