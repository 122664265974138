import { Align, DialogHeader, Divider, Justify, Label, List, ListItem, Row } from '@busybusy/webapp-react-ui';
import { CostCodeIcon, EquipmentIcon, FolderIcon, PasteIcon } from 'assets/icons';
import classNames from 'classnames';
import { EmptyState, IconButton, Spinner } from 'components';
import SearchBar from 'components/foundation/SearchBar/SearchBar';
import { EmployeeTabType } from 'containers/employee-timers/EmployeeTimersActionHeader/EmployeeTimersTabs';
import {
  useCostCodeManagementListNavigation,
  useEmployeeDashboardNavigation,
  useEquipmentManagementListNavigation,
  useProjectManagementListNavigation,
  useTimesheetsNavigation,
} from 'hooks';
import useQuickSearchGraylog from 'hooks/analytics/useQuickSearchGraylog';
import useCostCodeDashboardNavigation from 'hooks/navigation/useCostCodeDashboardNavigation';
import useEmployeesManagementListNavigation from 'hooks/navigation/useEmployeeManagementListNavigation';
import useEmployeesNavigation from 'hooks/navigation/useEmployeesNavigation';
import useEquipmentDashboardNavigation from 'hooks/navigation/useEquipmentDashboardNavigation';
import useProjectDashboardNavigation from 'hooks/navigation/useProjectDashboardNavigation';
import useWhichOS from 'hooks/utils/useWhichOS';
import { first, isEmpty, isNil } from 'lodash';
import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { projectsSetSelectedProjectAction } from 'store/projects/setProjects/projectsSetProjectsAction';
import { TimesheetView } from 'store/timesheets/Timesheets';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import { mapNotNil } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import './CommandPallet.scss';
import CommandPalletItem from './CommandPalletItem/CommandPalletItem';
import QuickSearchEmployeeCard from './QuickSearchEmployeeCard/QuickSearchEmployeeCard';
import useCommandPalletSearch, { ICommandPalletAction, ISearchableRoute } from './hooks/useCommandPalletSearch';
import useRoveFocus from './hooks/useRoveFocus';

export interface ICommandPalletProps {
  className?: ClassName;
  onClose: () => void;
  openSupport: () => void;
  onAddTimeEntry: () => void;
  onAddTimeOff: () => void;
}

const CommandPallet = (props: ICommandPalletProps) => {
  const { className, onClose, openSupport, onAddTimeEntry, onAddTimeOff } = props;

  const costCodeDashboardNavigation = useCostCodeDashboardNavigation();
  const employeeDashboardNavigation = useEmployeeDashboardNavigation();
  const projectDashboardNavigation = useProjectDashboardNavigation();
  const equipmentDashboardNavigation = useEquipmentDashboardNavigation();
  const timeCardsNavigation = useTimesheetsNavigation();
  const employeeManageNavigation = useEmployeesManagementListNavigation();
  const projectManageNavigation = useProjectManagementListNavigation();
  const costCodeManageNavigation = useCostCodeManagementListNavigation();
  const equipmentManageNavigation = useEquipmentManagementListNavigation();
  const employeeLandingNavigation = useEmployeesNavigation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMacintosh } = useWhichOS();
  const quickSearchUserEvents = useQuickSearchGraylog();

  const { search, handleSearch, data, loading } = useCommandPalletSearch();

  const { currentFocus, setCurrentFocus } = useRoveFocus(data.length);
  const searchRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'k') {
        if ((isMacintosh() && e.ctrlKey) || (!isMacintosh() && e.altKey)) {
          searchRef.current?.focus();
        }
      } else if (e.key === 'Enter') {
        // enter key selects first item
        if (currentFocus === -1) {
          first(document.getElementsByClassName('command-pallet-row-item  m-6'))?.parentElement?.click();
        }
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  function renderRouteRow(row: ISearchableRoute, index: number) {
    function handleClick() {
      if (row.path === '/support') {
        openSupport();
        onClose();
        return;
      }
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      navigate(row.path);
      quickSearchUserEvents.searchSelected('navigation');
      onClose();
    }
    return (
      <CommandPalletItem
        key={row.name}
        focus={currentFocus === index}
        setFocus={() => setCurrentFocus(index)}
        onEnter={handleClick}
        className={'command-pallet-row'}
        isClickable={true}
      >
        <ListItem className={'command-pallet-row-item m-6'} onClick={handleClick}>
          {row.name}
        </ListItem>
      </CommandPalletItem>
    );
  }

  function renderCostCodeRow(row: ICostCode, index: number) {
    function handleClick() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      costCodeDashboardNavigation({ cost_code_id: row.id });
      quickSearchUserEvents.searchSelected('cost_code');
      onClose();
    }

    function handleManagementNavigation() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      costCodeManageNavigation({ costCodeId: row.id });
      quickSearchUserEvents.searchSelected('cost_code');
      onClose();
    }

    return (
      <CommandPalletItem
        key={row.id}
        focus={currentFocus === index}
        setFocus={() => setCurrentFocus(index)}
        onEnter={handleClick}
        className={'command-pallet-row'}
        isClickable={false}
      >
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
          <ListItem className={'command-pallet-row-item m-6'} onClick={handleClick}>
            {getCostCodeDisplay(row)}
          </ListItem>
          <div className="mr-8">
            <IconButton svg={CostCodeIcon} onClick={handleClick} tooltipLabel={t('Dashboard')} />
            <IconButton svg={PasteIcon} onClick={handleManagementNavigation} tooltipLabel={t('Management List')} />
          </div>
        </Row>
      </CommandPalletItem>
    );
  }

  function renderEmployeeRow(row: IMember, index: number) {
    function handleClick() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      employeeDashboardNavigation({ employee_id: row.id, exitToLegacy: false });
      quickSearchUserEvents.searchSelected('employee');
      onClose();
    }

    function handleTimeCardsNavigation() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      timeCardsNavigation({ timesheet_view: TimesheetView.EXPANDED_TIME_CARDS, employee_id: row.id });
      quickSearchUserEvents.searchSelected('employee');
      onClose();
    }

    function handleManagementNavigation() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      employeeManageNavigation({ employeeId: row.id });
      quickSearchUserEvents.searchSelected('employee');
      onClose();
    }

    function handleEmployeeLandingNavigation() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      employeeLandingNavigation({ employee_id: row.id, exitToLegacy: false, filter_type: EmployeeTabType.SHOW_ALL });
      quickSearchUserEvents.searchSelected('employee');
      onClose();
    }

    return (
      <CommandPalletItem
        key={row.id}
        focus={currentFocus === index}
        setFocus={() => setCurrentFocus(index)}
        onEnter={handleClick}
        className={'command-pallet-row'}
        isClickable={false}
      >
        <QuickSearchEmployeeCard
          memberId={row.id}
          handleDashboardNavigation={handleClick}
          handleEmployeeLandingNavigation={handleEmployeeLandingNavigation}
          handleTimeCardsNavigation={handleTimeCardsNavigation}
          handleManagementNavigation={handleManagementNavigation}
        />
      </CommandPalletItem>
    );
  }

  function renderProjectRow(row: IProject, index: number) {
    async function handleClick() {
      projectDashboardNavigation({
        project_id: row.rootProjectId ?? row.id,
        subproject_id: !isNil(row.parentProjectId) ? row.id : undefined,
      });
      dispatch(projectsSetSelectedProjectAction({ selectedProject: row }));
      quickSearchUserEvents.searchSelected('project');
      onClose();
    }

    function handleManagementNavigation() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: row }));
      projectManageNavigation({ projectId: row.rootProjectId ?? row.id });
      quickSearchUserEvents.searchSelected('project');
      onClose();
    }

    return (
      <CommandPalletItem
        key={row.id}
        focus={currentFocus === index}
        setFocus={() => setCurrentFocus(index)}
        onEnter={handleClick}
        className={'command-pallet-row'}
        isClickable={false}
      >
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
          <ListItem className={'command-pallet-row-item m-6'} onClick={handleClick}>
            {getFormattedPathFromProject(row, true)}
          </ListItem>
          <div className="mr-8">
            <IconButton svg={FolderIcon} onClick={handleClick} tooltipLabel={t('Dashboard')} />
            <IconButton svg={PasteIcon} onClick={handleManagementNavigation} tooltipLabel={t('Management List')} />
          </div>
        </Row>
      </CommandPalletItem>
    );
  }

  function renderEquipmentRow(row: IEquipment, index: number) {
    function handleClick() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      equipmentDashboardNavigation({ equipment_id: row.id });
      quickSearchUserEvents.searchSelected('equipment');
      onClose();
    }

    function handleManagementNavigation() {
      dispatch(projectsSetSelectedProjectAction({ selectedProject: null }));
      equipmentManageNavigation({ equipment_id: row.id });
      quickSearchUserEvents.searchSelected('equipment');
      onClose();
    }

    return (
      <CommandPalletItem
        key={row.id}
        focus={currentFocus === index}
        setFocus={() => setCurrentFocus(index)}
        onEnter={handleClick}
        className={'command-pallet-row'}
        isClickable={false}
      >
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
          <ListItem className={'command-pallet-row-item m-6'} onClick={handleClick}>
            {getEquipmentDisplay(row)}
          </ListItem>
          <div className="mr-8">
            <IconButton svg={EquipmentIcon} onClick={handleClick} tooltipLabel={t('Dashboard')} />
            <IconButton svg={PasteIcon} onClick={handleManagementNavigation} tooltipLabel={t('Management List')} />
          </div>
        </Row>
      </CommandPalletItem>
    );
  }

  function renderActionRow(row: ICommandPalletAction, index: number) {
    function handleClick() {
      switch (row.type) {
        case 'add-time-entry':
          onAddTimeEntry();
          break;
        case 'add-time-off':
          onAddTimeOff();
          break;
        default:
          break;
      }
      quickSearchUserEvents.searchSelected('action');
      onClose();
    }
    return (
      <CommandPalletItem
        key={row.type}
        focus={currentFocus === index}
        setFocus={() => setCurrentFocus(index)}
        onEnter={handleClick}
        className={'command-pallet-row'}
        isClickable={true}
      >
        <ListItem className={'command-pallet-row-item m-6'} onClick={handleClick}>
          {row.name}
        </ListItem>
      </CommandPalletItem>
    );
  }

  function renderEmptyState() {
    return <>{search.length > 0 && isEmpty(data) && <EmptyState title={t('No results found')} />}</>;
  }

  function renderRows() {
    const nodes: ReactNode[] = [];
    const routes = mapNotNil(data, (d) => d.route);
    const members = mapNotNil(data, (d) => d.member);
    const projects = mapNotNil(data, (d) => d.project);
    const costCodes = mapNotNil(data, (d) => d.costCode);
    const equipment = mapNotNil(data, (d) => d.equipment);
    data.forEach((row, index) => {
      if (!isNil(row.route)) {
        if (isEmpty(nodes)) {
          nodes.push(<Label className={'my-6 header'}>{t('NAVIGATION')}</Label>);
        }
        nodes.push(renderRouteRow(row.route, index));
      }
      if (!isNil(row.member)) {
        if (routes.length === index) {
          nodes.push(<Divider />);
          nodes.push(<Label className={'my-6 header'}>{t('EMPLOYEES')}</Label>);
        }
        nodes.push(renderEmployeeRow(row.member, index));
      }
      if (!isNil(row.project)) {
        if (members.length + routes.length === index) {
          nodes.push(<Divider />);
          nodes.push(<Label className={'my-6 header'}>{t('PROJECTS')}</Label>);
        }
        nodes.push(renderProjectRow(row.project, index));
      }
      if (!isNil(row.costCode)) {
        if (projects.length + members.length + routes.length === index) {
          nodes.push(<Divider />);
          nodes.push(<Label className={'my-6 header'}>{t('COST CODES')}</Label>);
        }
        nodes.push(renderCostCodeRow(row.costCode, index));
      }
      if (!isNil(row.equipment)) {
        if (costCodes.length + projects.length + members.length + routes.length === index) {
          nodes.push(<Divider />);
          nodes.push(<Label className={'my-6 header'}>{t('EQUIPMENT')}</Label>);
        }
        nodes.push(renderEquipmentRow(row.equipment, index));
      }
      if (!isNil(row.action)) {
        if (equipment.length + costCodes.length + projects.length + members.length + routes.length === index) {
          nodes.push(<Divider />);
          nodes.push(<Label className={'my-6 header'}>{t('ACTIONS')}</Label>);
        }
        nodes.push(renderActionRow(row.action, index));
      }
    });
    return nodes;
  }

  function onSearch(searchString: string) {
    handleSearch(searchString);
    setCurrentFocus(-1);
  }

  const classes = classNames('command-pallet', className);

  return (
    <div className={classes}>
      <DialogHeader divider={true}>
        <SearchBar
          className="mr-6 py-4"
          onSearch={onSearch}
          search={search}
          inputRef={(input) => {
            searchRef.current = input;
            return currentFocus === -1 && input && input.focus();
          }}
        />
      </DialogHeader>

      {!loading && renderEmptyState()}
      {loading ? (
        <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8">
          <Spinner />
        </Row>
      ) : (
        <List>{renderRows()}</List>
      )}
      <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8">
        {isMacintosh() && <Label>{t('Use keyboard shortcut CTL+k to search.')}</Label>}
        {!isMacintosh() && <Label>{t('Use keyboard shortcut ALT+k to search.')}</Label>}
      </Row>
    </div>
  );
};

export default CommandPallet;
