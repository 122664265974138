import { useApolloClient } from '@apollo/client';
import {
  Avatar,
  Button,
  ButtonList,
  Col,
  Container,
  Dialog,
  DialogHeader,
  Divider,
  Justify,
  Position,
  Row,
  Size,
  Tray,
} from '@busybusy/webapp-react-ui';
import { EQUIPMENT_BY_ID_QUERY } from 'apollo/queries/equipment-queries';
import { ArrowBackIcon } from 'assets/icons';
import cameraImage from 'assets/images/camera.png';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { DetailsCard, DetailsItem, IconButton, MoreButton } from 'components';
import EditEquipmentFormDialog from 'components/domain/equipment/EditEquipmentDialog/EditEquipmentDialog';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useCurrencyFormatter, useOpenable } from 'hooks';
import useDeleteEquipmentCostHistory from 'hooks/models/equipment-cost-history/useDeleteEquipmentCostHistory';
import _, { isNil } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import IEquipment from 'types/Equipment';
import { equipmentUtils } from 'utils';
import { equipmentImageUrl, getCurrentEquipmentCostHistory, getFutureEquipmentCostHistory } from 'utils/equipmentUtils';
import { t } from 'utils/localize';
import EquipmentCostHistoryDialog from './EquipmentCostHistoryDialog/EquipmentCostHistoryDialog';
import ScheduleEquipmentHourlyCostChange from './ScheduleEquipmentHourlyCostChange/ScheduleEquipmentHourlyCostChange';
export interface IManageEquipmentDetailsDialogProps {
  className?: ClassName;
  equipment: IEquipment;
  isOpen: boolean;
  onClose: () => void;
  onEdit: (equipment: IEquipment) => void;
  onDelete: (equipment: IEquipment) => void;
}

const ManageEquipmentDetailsDialog = (props: IManageEquipmentDetailsDialogProps) => {
  const { className, equipment, isOpen, onClose, onEdit, onDelete } = props;

  const [data, setData] = useState<IEquipment>(equipment);
  const editDetails = useOpenable();
  const [editKey, setEditKey] = useState<string | null>(null);
  const deleteDetails = useOpenable();
  const equipmentCostHistoryDetails = useOpenable();
  const client = useApolloClient();
  const futureEquipmentCostHistory = getFutureEquipmentCostHistory(data.costHistory ?? []);
  const deleteEquipmentCostHistory = useDeleteEquipmentCostHistory();
  const currencyFormatter = useCurrencyFormatter();

  function handleDelete() {
    onDelete(data);
  }

  function handleDetailItemClick(key: string) {
    setEditKey(key);
    editDetails.open();
  }

  function handleEditClose() {
    setEditKey(null);
    editDetails.close();
  }

  async function getEquipment(id: string) {
    const results = await client.query<{ equipment: IEquipment[] }>({
      query: EQUIPMENT_BY_ID_QUERY,
      variables: {
        equipmentId: id,
      },
      fetchPolicy: 'network-only',
    });
    return results.data.equipment;
  }

  async function handleEditSubmit(equipmentId?: string) {
    if (!_.isNil(equipmentId)) {
      const newEquipment = _.first(await getEquipment(equipmentId));
      if (newEquipment) {
        onEdit(newEquipment);
        setData(newEquipment);
      }
    }
    setEditKey(null);
    editDetails.close();
  }

  function curriedDetailItemClick(key: string) {
    return () => handleDetailItemClick(key);
  }

  function handleAvatarClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    handleDetailItemClick('image');
  }

  function getEquipmentCostHistoryValue() {
    const latestHistory = getCurrentEquipmentCostHistory(data.costHistory ?? []);
    return latestHistory ? currencyFormatter(latestHistory.operatorCostRate ?? 0) + '/' + t('hr') : '---';
  }

  const renderDropDown = (closeDropdown: () => void) => {
    const handleViewHourlyCostHistory = (event: React.MouseEvent) => {
      closeDropdown();
      event.stopPropagation();
      event.preventDefault();
      equipmentCostHistoryDetails.open();
    };

    return (
      <ButtonList>
        <Button onClick={handleViewHourlyCostHistory}>{t('View Hourly Rate History')}</Button>
      </ButtonList>
    );
  };

  async function handleEquipmentCostHistoryDelete() {
    if (!isNil(futureEquipmentCostHistory)) {
      await deleteEquipmentCostHistory(futureEquipmentCostHistory.id);
      await handleEditSubmit(data.id);
    }
  }

  const classes = classNames('manage-equipment-details', className);

  return (
    <>
      <Dialog size="full" isOpen={isOpen} hasDismiss={false} onClose={onClose}>
        <div className={classes}>
          <DialogHeader divider={true}>
            <IconButton svg={ArrowBackIcon} onClick={onClose} />
          </DialogHeader>
          <Container className="py-4 px-8">
            <div>
              <Row justify={Justify.SPACE_BETWEEN} className="py-5">
                <Col align="center">
                  <Tray spacing={1}>
                    <Avatar
                      firstName={data.equipmentName.toUpperCase()}
                      size={Size.MEDIUM}
                      onClick={handleAvatarClick}
                      image={data.imageUrl ? equipmentImageUrl(data) : cameraImage}
                    />
                    <Col className="ml-3" align="start">
                      <h1 className="pb-1">{data.equipmentName}</h1>
                      <div className="subtitle fc-2">{equipmentUtils.makeModelString(data)}</div>
                    </Col>
                  </Tray>
                </Col>
                <Col align="start" className="align-right">
                  <Tray align="right" spacing={1}>
                    <MoreButton position={Position.BOTTOM_END} size={Size.MEDIUM} renderContent={renderDropDown} />
                    <Button type="secondary" onClick={deleteDetails.open}>
                      {t('Delete')}
                    </Button>
                  </Tray>
                </Col>
              </Row>
              <Divider />
              <Row className="pt-6 pb-4" gutter={10}>
                <Col size={6}>
                  <DetailsCard title={t('General')}>
                    <DetailsItem
                      title={t('Name')}
                      onClick={curriedDetailItemClick('name')}
                      value={data.equipmentName}
                    />
                    <DetailsItem
                      title={t('Make / Model / Year')}
                      onClick={curriedDetailItemClick('make')}
                      value={equipmentUtils.makeModelYearString(data)}
                    />
                    <DetailsItem
                      title={t('Hour Meter')}
                      onClick={curriedDetailItemClick('hourMeter')}
                      value={
                        data.trackManualHours
                          ? data.lastHours
                            ? data.lastHours?.runningHours.toString()
                            : '---'
                          : '---'
                      }
                    />
                    <DetailsItem
                      title={t('Hourly Rate')}
                      onClick={curriedDetailItemClick('hourlyCost')}
                      value={getEquipmentCostHistoryValue()}
                      hint={t(
                        'The cost to run the machine for one hour, minus labor costs. This should be the total of all non-operator costs such as fuel, maintenance, etc.'
                      )}
                      disabled={!isNil(futureEquipmentCostHistory)}
                    />
                  </DetailsCard>
                </Col>
                <Col className={'my-8'}>
                  {futureEquipmentCostHistory && (
                    <ScheduleEquipmentHourlyCostChange
                      costHistory={futureEquipmentCostHistory}
                      onDelete={handleEquipmentCostHistoryDelete}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </Dialog>
      {editKey && (
        <EditEquipmentFormDialog
          isOpen={editDetails.isOpen}
          onClose={handleEditClose}
          equipmentId={data.id}
          editKey={editKey}
          onSubmit={handleEditSubmit}
        />
      )}
      <DeleteConfirmationDialog
        isOpen={deleteDetails.isOpen}
        onClose={deleteDetails.close}
        onDelete={handleDelete}
        title={t('Delete equipment?')}
        showHeaderDivider={false}
      >
        {t('If you delete this item it will be gone forever. Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
      <EquipmentCostHistoryDialog
        equipment={data}
        isOpen={equipmentCostHistoryDetails.isOpen}
        onClose={() => {
          handleEditSubmit(data.id).then(() => {
            equipmentCostHistoryDetails.close();
          });
        }}
        onChange={() => {
          handleEditSubmit(data.id);
        }}
      />
    </>
  );
};

export default ManageEquipmentDetailsDialog;
