import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { getEndOfSundayWeek, getStartOfSundayWeek } from 'utils/dateUtils';
import { getWeekRangeForTime } from 'utils/timeRangeUtils';
import { useOrganization } from '../';

export default function useWeeklyTimeRangeGet() {
  const organization = useOrganization();

  return useCallback(
    (time: number) => {
      if (organization.organizationOvertimePeriods) {
        const weekRange = getWeekRangeForTime(organization.organizationOvertimePeriods!, time);

        if (weekRange) {
          return weekRange;
        }
      }

      const date = DateTime.fromSeconds(time, { zone: 'utc' });
      return { startTime: getStartOfSundayWeek(date), endTime: getEndOfSundayWeek(date) };
    },
    [organization.organizationOvertimePeriods]
  );
}
