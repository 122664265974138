import useMemberGlobalSettingsUpdate from 'hooks/models/member/useMemberGlobalSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { isEqual } from 'lodash';
import { getMigratedQuickTimeTemplates } from '../utils/utils';

export function useQuickTimeTemplateMigration() {
  const memberSettings = useMemberSettings();
  const templateSetting = memberSettings?.global?.timeEntryTemplate;
  const updateGlobalMemberSettings = useMemberGlobalSettingsUpdate();

  useOnMount(() => {
    function migrate() {
      if (templateSetting) {
        const migratedTemplates = getMigratedQuickTimeTemplates(templateSetting);
        if (!isEqual(migratedTemplates, templateSetting)) {
          return updateGlobalMemberSettings('timeEntryTemplate', migratedTemplates);
        }
      }
    }

    migrate();
  });
}
