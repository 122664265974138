import useBrandLogoImageAsset from 'hooks/meta/useBrandLogoImageAsset';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { t } from 'utils/localize';

export type IBrandLogoProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;

export const BrandLogo: React.FC<IBrandLogoProps> = (customProps) => {
  const brandImage = useBrandLogoImageAsset();

  const brandTitle = useBrandTitle();
  const defaultTitle = t(`${brandTitle} logo`);

  const defaultProps: IBrandLogoProps = {
    alt: defaultTitle,
    title: defaultTitle,
    height: 23,
  };

  const props: IBrandLogoProps = {
    ...defaultProps,
    ...customProps,
  };

  return <img src={brandImage} {...props} />;
};
