import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { DateTime } from 'luxon';
import { StoreAuthMemberProper } from 'store/types';
import { IMember } from 'types';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import DailySignOffForm from './DailySignOffForm';

export interface IDailySignOffDialogProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  member: Partial<IMember> | IMember | StoreAuthMemberProper;
  currentOpenEntry?: ITimeEntry | null;
  isOpen: boolean;
  fromReport: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const DailySignOffFormDialog = (props: IDailySignOffDialogProps) => {
  const { className, timeRange, member, isOpen, fromReport, onClose, onComplete, currentOpenEntry } = props;

  const classes = classNames('daily-time-sign-form-dialog', className);

  return (
    <HeaderDialog
      className={classes}
      isOpen={isOpen}
      title={t('Daily Sign-Off')}
      subtitle={fullName(member)}
      onClose={onClose}
      divider={true}
    >
      <DailySignOffForm
        timeRange={timeRange}
        member={member}
        onComplete={onComplete}
        fromReport={fromReport}
        currentOpenEntry={currentOpenEntry}
      />
    </HeaderDialog>
  );
};

export default DailySignOffFormDialog;
