import { IDialogProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import PanelContent from 'components/layout/PanelContent/PanelContent';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ILocation from 'types/Location';
import GoogleMap, { googleMapDefaultOptions } from '../GoogleMap/GoogleMap';
import './SimpleMapDialog.scss';

export interface ISimpleMapDialogProps extends Omit<IDialogProps, 'children'> {
  pinLocations: ILocation[];
  headerTitle?: string;
}

const SimpleMapDialog: React.FunctionComponent<ISimpleMapDialogProps> = (props) => {
  const { pinLocations, headerTitle, className, ...other } = props;

  const [t] = useTranslation();
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (other.isOpen) {
      // Just opened need to give dialog time to animate map in
      setTimeout(() => setShowMap(true), 0);
    } else {
      setShowMap(false);
    }
  }, [other.isOpen]);

  const classes = classNames('simple-map-dialog', className);

  return (
    <HeaderDialog className={classes} title={headerTitle ? headerTitle : t('Location')} {...other}>
      {showMap && (
        <PanelContent className="m-6">
          <GoogleMap mapId={'simple-map'} pinLocations={pinLocations} options={googleMapDefaultOptions} />
        </PanelContent>
      )}
    </HeaderDialog>
  );
};

export default SimpleMapDialog;
