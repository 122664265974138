import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { COST_CODES_WITH_EQUIPMENT_METRICS_QUERY } from 'containers/activity-reports/queries/cost-code-activity-queries';
import { ApolloGetAll } from 'hooks/apollo/useApolloPaging';
import { DateTime } from 'luxon';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { LaborMetricsInterval } from '__generated__/graphql';
import { MetricQueryProvider } from '../useMetricAggregates';
import { COST_CODE_LABOR_METRIC_QUERY, COST_CODE_TIME_METRIC_QUERY } from './cost-code-metric-queries';

export function costCodeTimeMetricQueryProvider(
  getAll: ApolloGetAll<ICostCode & ICursorable>,
  costCodeIds?: string[],
  costCodeGroupId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const costCodes = await getAll('costCodes', {
      query: COST_CODE_TIME_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: costCodeIds ? { contains: costCodeIds } : undefined,
          costCodeGroupId: costCodeGroupId ? { equal: costCodeGroupId } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          costCodesWithTime: {
            startTime: start.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: end.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
          },
        },
      },
    });

    return costCodes.flatMap((costCode) => costCode.costCodeLaborMetrics ?? []);
  };
}

export function costCodeLaborMetricQueryProvider(
  getAll: ApolloGetAll<ICostCode & ICursorable>,
  costCodeIds?: string[],
  costCodeGroupId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const costCodes = await getAll('costCodes', {
      query: COST_CODE_LABOR_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: costCodeIds ? { contains: costCodeIds } : undefined,
          costCodeGroupId: costCodeGroupId ? { equal: costCodeGroupId } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          costCodesWithTime: {
            startTime: start.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: end.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
          },
        },
      },
    });

    return costCodes.flatMap((costCode) => costCode.costCodeLaborMetrics ?? []);
  };
}

export function costCodeEquipmentMetricQueryProvider(
  getAll: ApolloGetAll<ICostCode & ICursorable>,
  costCodeIds?: string[],
  equipmentIds?: string[],
  archivedStatus?: ArchivedStatus,
  excludeNoProjectTime: boolean = true
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const members = await getAll('costCodes', {
      query: COST_CODES_WITH_EQUIPMENT_METRICS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        equipmentIds,
        filter: {
          id: costCodeIds ? { contains: costCodeIds } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
        },
        startTime: start.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: end.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        metricFilter: excludeNoProjectTime
          ? {
              operationType: 'and',
              nullConditions: { operator: 'isNotNull', field: 'equipmentId' },
            }
          : undefined,
      },
    });

    return members.flatMap((member) => member.costCodeEquipmentLaborMetrics ?? []);
  };
}
