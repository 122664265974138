import { Col, Divider } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import Panel from '../../Panel/Panel';
import NavHeader from '../NavHeader/NavHeader';
import NavList from '../NavList/NavList';
import './Navigation.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface INavigationProps {
  className?: ClassName;
  open: boolean;
  toggleMenu: () => void;
}

function Navigation(props: INavigationProps) {
  const { className, open, toggleMenu } = props;
  const isInIframe = useIsInIframe();

  const classes = classNames('navigation', 'no-print', { 'is-in-iframe': isInIframe }, className);

  return (
    <Panel medium={true} open={open} className={classes}>
      <Col>
        <NavHeader toggleMenu={toggleMenu} />
        <Divider />
        <NavList />
      </Col>
    </Panel>
  );
}

export default Navigation;
