import {
  Button,
  ButtonList,
  FlexItem,
  Icon,
  ISortPayload,
  ITableColumn,
  Position,
  SortDirection,
  TextAlign,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import {
  EquipmentSearch,
  EquipmentSearchFields,
  QueryManageEquipmentQueryVariables,
  SearchType,
} from '__generated__/graphql';
import { ListIcon } from 'assets/icons';
import classNames from 'classnames';
import { EmptyState, FeatureUnavailable, MoreButton, Panel, PanelContent } from 'components';
import CreateEquipmentFormDialog from 'components/domain/equipment/CreateEquipmentDialog/CreateEquipmentDialog';
import CSVImportEquipmentContainer from 'components/domain/equipment/CSVImportEquipment/CSVImportEquipmentContainer';
import { convertEquipmentToCsvEquipment } from 'components/domain/equipment/CSVImportEquipment/utils/utils';
import RowSettingsDialog from 'components/foundation/dialogs/RowSettingsDialog/RowSettingsDialog';
import LegacyMainHeader from 'components/layout/LegacyMainHeader/LegacyMainHeader';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useActiveMember, useOpenable, useOrganization, useQueryParams } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useEquipment from 'hooks/models/equipment/useEquipment';
import useEquipmentManagementSettingsUpdate from 'hooks/models/member-settings/useEquipmentManagementSettingsUpdate';
import useManagementSettingsUpdate from 'hooks/models/member-settings/useManagementSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import _, { compact, isNil, partition } from 'lodash';
import Papa from 'papaparse';
import * as React from 'react';
import { useState } from 'react';
import Helmet from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { ClassName } from 'types/ClassName';
import IEquipment from 'types/Equipment';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITableRowHeightSettings from 'types/TableRowHeightSettings';
import { Nullable } from 'types/util/Nullable';
import { mergeListsWithOverride } from 'utils/collectionUtils';
import { QueryParam } from 'utils/constants/queryParam';
import { isoTimeStampLocal } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { downloadText } from 'utils/fileUtils';
import { t } from 'utils/localize';
import LocalStore from 'utils/localStorageUtils';
import { typedObjectEntries } from 'utils/objectUtils';
import { columns, defaultVisibleColumns } from './equipment-table-columns';
import './ManageEquipment.scss';
import ManageEquipmentActionHeader, {
  ManageEquipmentViewType,
} from './ManageEquipmentActionHeader/ManageEquipmentActionHeader';
import ManageEquipmentDetailsDialog from './ManageEquipmentDetailsDialog/ManageEquipmentDetailsDialog';
import ManageEquipmentFilter from './ManageEquipmentFilter/ManageEquipmentFilter';
import ManageEquipmentMap from './ManageEquipmentMap/ManageEquipmentMap';
import { ManagementEquipment } from './types/types';
import useEquipmentManagementData from './hooks/useEquipmentManagementData';
import TemplatedTable from 'components/foundation/table/TemplatedTable/TemplatedTable';
import { getApolloSortDirection } from 'utils/tableUtils';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';

export interface IManageEquipmentProps {
  className?: ClassName;
}

export interface IManageEquipmentRowInfo {
  updatedByMember: ManagementEquipment['updatedByMember'];
  id: string;
  name: string;
  make: string;
  model: string;
  year: string;
  category: string;
  hourMeter: string;
  costHistory: string;
  type: string;
  equipment: IEquipment;
  cursor: string;
  createdOn: string;
  updatedOn: Nullable<string>;
}

const ManageEquipment = ({ className }: IManageEquipmentProps) => {
  const memberSettings = useMemberSettings();
  const updateEquipmentManagementSettings = useEquipmentManagementSettingsUpdate();
  const updateManagementLists = useManagementSettingsUpdate();

  const activeMember = useActiveMember();
  const org = useOrganization();
  const isFeatureEnabled = useFeatureFlags('MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EQUIPMENT');
  const { getParam } = useQueryParams();
  const viewType: ManageEquipmentViewType = getParam('viewType') ?? 'list';
  const filterEquipmentId = getParam(QueryParam.EQUIPMENT_ID);
  const filterCategoryId = getParam(QueryParam.EQUIPMENT_CATEGORY_ID);
  const filterSearch = getParam(QueryParam.SEARCH);
  const filterPanelDetails = useOpenable({ isOpen: true });
  const deleteDetails = useOpenable();
  const equipmentDetails = useOpenable();
  const createEquipmentDetails = useOpenable();
  const rowSettingsDialog = useOpenable();
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [visibleColumns, setVisibleColumns] = useState<IVisibleTableColumn[]>(getInitialVisibleColumns());
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.ASCENDING);
  const [sortBy, setSortBy] = useState<keyof IManageEquipmentRowInfo>('name');
  const [sortIsDirty, setSortIsDirty] = useState<boolean>(false);
  const defaultSectionSize = 15;
  const [checkedRows, setCheckedRows] = useState<IManageEquipmentRowInfo[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<IEquipment | null>(null);
  const equipmentActions = useEquipment();
  const importDetails = useOpenable();
  const reimportDialogDetails = useOpenable();
  const isManagementListsSearchEnabled = useFeatureFlags('MANAGEMENT_LISTS_SEARCH');

  const [rowHeight, setLocalRowHeight] = useState(
    memberSettings?.web?.features?.managementLists?.rowHeight ?? 'standard'
  );
  const setRowHeight = (newSetting: TableCellHeight) => {
    updateManagementLists([{ key: 'rowHeight', payload: newSetting }]);
    setLocalRowHeight(newSetting);
  };
  const useSmallIcon = rowHeight === 'cozy' || rowHeight === 'compact';
  const brand = useBrandTitle();

  const scroller = React.useRef<HTMLElement | null>(null);

  const variables = React.useMemo((): QueryManageEquipmentQueryVariables => {
    const sortDirection = getApolloSortDirection(sortDir);
    let sort;
    const secondarySort =
      sortBy === 'name' ? { lastHours: { runningHours: sortDirection } } : { equipmentName: sortDirection };

    switch (sortBy) {
      case 'name':
        sort = [{ equipmentName: sortDirection }, secondarySort];
        break;
      case 'make':
        sort = [
          { model: { make: { unknown: sortDirection } } },
          { model: { make: { title: sortDirection } } },
          secondarySort,
        ];
        break;
      case 'model':
        sort = [{ model: { unknown: sortDirection } }, { model: { title: sortDirection } }, secondarySort];
        break;
      case 'year':
        sort = [{ model: { year: sortDirection } }, secondarySort];
        break;
      case 'category':
        sort = [{ model: { category: { title: sortDirection } } }, secondarySort];
        break;
      case 'hourMeter':
        sort = [{ lastHours: { runningHours: sortDirection } }, secondarySort];
        break;
      case 'costHistory':
        sort = [secondarySort];
        break;
      case 'type':
        sort = [secondarySort];
        break;
      default:
        sort = [{ [sortBy]: sortDirection }, secondarySort];
        break;
    }

    return {
      filter: {
        id: getGraphQlEqualComparison(filterEquipmentId),
        model: filterCategoryId ? { category: { id: getGraphQlEqualComparison(filterCategoryId) } } : undefined,
        search: isManagementListsSearchEnabled ? getEquipmentSearch(filterSearch) : undefined,
        deletedOn: { isNull: true },
      },
      sort,
    };
  }, [filterSearch, isManagementListsSearchEnabled, filterCategoryId, filterEquipmentId, sortBy, sortDir]);

  const { data, isError, isLoading, loadedAll, loadAll, refetch, clearData } = useEquipmentManagementData(
    scroller.current,
    variables,
    defaultSectionSize
  );

  useOnMount(() => {
    const migrateData = () => {
      const hasNotMigrated = isNil(memberSettings?.web?.features?.managementLists?.equipment?.localStoreMigrated);
      const localRowHeight: ITableRowHeightSettings = LocalStore.get('table-row-height-settings');
      const managementListRowHeight = localRowHeight?.managementLists;

      if (managementListRowHeight || hasNotMigrated) {
        const localColumnSettings = LocalStore.get('equipment_management_columns');
        updateManagementLists(
          compact([
            managementListRowHeight
              ? {
                  key: 'rowHeight',
                  payload: managementListRowHeight ?? memberSettings?.web?.features?.managementLists?.rowHeight,
                }
              : null,
            hasNotMigrated
              ? {
                  key: 'equipment',
                  payload: {
                    localStoreMigrated: true,
                    columnSettings: localColumnSettings,
                  },
                }
              : null,
          ])
        );
        if (localRowHeight) {
          setLocalRowHeight(managementListRowHeight);
          const isEveryKeyNull = typedObjectEntries(localRowHeight).every(
            ([key, val]) => key === 'managementLists' || isNil(val)
          );
          if (isEveryKeyNull) {
            LocalStore.remove('table-row-height-settings');
          } else {
            LocalStore.set('table-row-height-settings', { ...localRowHeight, managementLists: null });
          }
        }
      }
    };

    migrateData();
  });

  function getInitialVisibleColumns() {
    const savedColumns = memberSettings?.web?.features?.managementLists?.equipment?.columnSettings;
    const merged = _.sortBy(
      mergeListsWithOverride(defaultVisibleColumns, savedColumns ?? [], 'key', [
        'position',
        'visible',
      ]) as IVisibleTableColumn[],
      (item) => item.position ?? 0
    );
    return merged;
  }

  function handleVisibleColumnsChange(newVisibleColumns: IVisibleTableColumn[]) {
    updateEquipmentManagementSettings([{ key: 'columnSettings', payload: newVisibleColumns }]);
    setVisibleColumns(newVisibleColumns);
  }

  const sortedRows = React.useMemo(() => {
    // Porting over similar behavior but this won't work with lazy loading correctly
    function sortRows(rows: IManageEquipmentRowInfo[]): IManageEquipmentRowInfo[] {
      switch (sortBy) {
        case 'hourMeter': {
          // Always show empty hour meter values at end of list regardless of sort. Only applicable when sorting hourMeter column.
          const [hasHourMeterRows, noHourMeterRows] = partition(rows, (row) => row.hourMeter !== '---');
          return hasHourMeterRows.concat(noHourMeterRows);
        }
        case 'costHistory': {
          // Always show empty cost history values at end of list regardless of sort. Only applicable when sorting costHistory column.
          const [hasCostHistoryRows, noCostHistoryRows] = partition(rows, (row) => row.costHistory !== '---');
          return hasCostHistoryRows.concat(noCostHistoryRows);
        }
        case 'type': {
          const sortedRows = _.sortBy(rows, (r) => r.type.toLowerCase(), sortDir.toLowerCase());
          return sortDir.toLowerCase() === 'asc' ? sortedRows : sortedRows.reverse();
        }
        default:
          return rows;
      }
    }

    return sortRows(data ?? []);
  }, [data]);

  function handleSort(sort: ISortPayload<IManageEquipmentRowInfo>) {
    setCheckedRows([]);
    setSortBy(sort.sortBy as any);
    setSortDir(sort.sortDir);
    setSortIsDirty(true);
    refetch();
  }

  function showManageEquipment() {
    return (activeMember.position?.manageEquipment ?? false) && org.trackEquipment ? org.trackEquipment : false;
  }

  function renderEmptyState() {
    return (
      <EmptyState
        title={t('No Equipment')}
        subtitle={t('Click on the create button above to add a piece of equipment.')}
      />
    );
  }

  function filterColumns(cols: Array<ITableColumn<IManageEquipmentRowInfo>>) {
    const columnMap = _.keyBy(visibleColumns, 'key');
    const visibleCols = _.sortBy(
      cols.filter((column: ITableColumn<IManageEquipmentRowInfo>) => {
        const match = columnMap[column.key];
        return match && match.visible === true;
      }),
      (column, index) => columnMap[column.key].position ?? index
    );
    visibleCols.unshift(getActionColumn());

    return visibleCols;
  }

  function handleRowClick(row: IManageEquipmentRowInfo) {
    setSelectedEquipment(row.equipment);
    equipmentDetails.open();
  }

  async function handleCheckAll(checked: boolean) {
    if (checked && !loadedAll) {
      const result = await loadAll();
      setCheckedRows(result);
    }
  }

  function handleSelectChange(newCheckedRows: IManageEquipmentRowInfo[]) {
    setCheckedRows(newCheckedRows);
  }

  function handleClearCheckedRows() {
    setCheckedRows([]);
  }

  async function handlePrint() {
    await loadAll();
    setImmediate(() => window.print());
  }

  function handleCsvExport(reimport: boolean) {
    return async () => {
      if (checkedRows.length > 0) {
        exportToCsv(checkedRows, false);
      } else {
        const allRows = await loadAll();
        exportToCsv(allRows, reimport);
      }
    };
  }

  function exportToCsv(allRows: IManageEquipmentRowInfo[], reimport: boolean) {
    if (allRows.length !== 0) {
      const csv = Papa.unparse(allRows.map((r) => r.equipment).map((e) => convertEquipmentToCsvEquipment(e, reimport)));
      handleClearCheckedRows();
      const title = reimport ? t('equipment-reimport') : t('equipment-export');
      downloadText(csv, `${brand}-${title}-${isoTimeStampLocal()}.csv`);
    } else {
      setErrorToastMessage(t('There is no data to export.'));
    }
  }

  function handleErrorToastClose() {
    setErrorToastMessage('');
  }

  function handleDelete() {
    if (selectedEquipment) {
      deleteEquipment([selectedEquipment]);
      setSelectedEquipment(null);
      if (equipmentDetails.isOpen) {
        equipmentDetails.close();
      }
    }
  }

  async function deleteEquipment(equipment: IEquipment[]) {
    const promises = equipment.map(({ id }) => {
      return equipmentActions.deleteEquipment(id);
    });

    await Promise.all(promises);
    refetch();
  }

  function handleCreateSubmit() {
    createEquipmentDetails.close();
    refetch();
  }

  function handleEditClose() {
    equipmentDetails.close();
    setSelectedEquipment(null);
  }

  function getActionColumn() {
    return {
      align: TextAlign.CENTER,
      cell: (row: IManageEquipmentRowInfo, _col: ITableColumn<IManageEquipmentRowInfo>) => (
        <MoreButton
          className={`${useSmallIcon ? 'small-icon' : ''}`}
          position={Position.BOTTOM_START}
          renderContent={(close: () => void) => {
            return (
              <ButtonList>
                <Button
                  onClick={async (e: React.MouseEvent<Element, MouseEvent>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    close();
                    setSelectedEquipment(row.equipment);
                    deleteDetails.open();
                  }}
                >
                  {t('Delete')}
                </Button>
              </ButtonList>
            );
          }}
        />
      ),
      cellClassName: 'px-1 no-print',
      headerClassName: 'no-print',
      key: 'actions',
      size: '60px',
      title: '',
      visibleByDefault: true,
    };
  }

  function renderList() {
    return (
      <PanelContent forwardRef={(ref) => (scroller.current = ref)} flex={true}>
        <FlexItem flexGrow={1}>
          <TemplatedTable
            emptyTemplate={renderEmptyState()}
            cols={filterColumns(columns)}
            data={sortedRows ?? []}
            minWidth={filterColumns(columns).length * 220 + 'px'}
            lazyLoad={false}
            sortBy={sortBy}
            sortDir={sortDir}
            sortIsDirty={sortIsDirty}
            selected={checkedRows}
            onSelectChange={handleSelectChange}
            onCheckAllChange={handleCheckAll}
            onSort={handleSort}
            onRowClick={handleRowClick}
            strokeCols={false}
            cellHeight={rowHeight}
            isError={isError}
            isLoading={isLoading}
          />
        </FlexItem>
      </PanelContent>
    );
  }

  function renderMap() {
    return (
      <ManageEquipmentMap
        onDetailsClick={(e) => {
          setSelectedEquipment(e);
          equipmentDetails.open();
        }}
        onDirectionsClick={(e) => {
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${e.lastLocation?.latitude},${e.lastLocation?.longitude}`,
            '_blank'
          );
        }}
        equipmentId={filterEquipmentId}
        categoryId={filterCategoryId}
        updatedEquipmentId={equipmentDetails.isOpen ? selectedEquipment?.id : undefined}
        search={filterSearch}
      />
    );
  }

  function renderMain() {
    return (
      <>
        {!showManageEquipment() && <Navigate to="/not-found" />}
        <Panel className={classes}>
          <LegacyMainHeader
            title={t('Equipment')}
            className="no-print"
            rightContent={
              <Button type="icon" onClick={filterPanelDetails.toggle}>
                <Icon svg={ListIcon} />
              </Button>
            }
          />
          <PanelContent>
            <Panel>
              <ManageEquipmentActionHeader
                checkedEquipment={checkedRows.map((r) => r.equipment)}
                clearCheckedEquipment={handleClearCheckedRows}
                openCreateDialog={createEquipmentDetails.open}
                onExportEquipment={handleCsvExport(false)}
                onImportEquipment={importDetails.open}
                onPrint={handlePrint}
                visibleColumns={visibleColumns}
                handleVisibleColumnsChange={handleVisibleColumnsChange}
                onDelete={deleteEquipment}
                className="no-print"
                onEditUsingSpreadsheetClick={reimportDialogDetails.open}
                viewType={viewType}
                onRowHeightClick={rowSettingsDialog.open}
              />
              <h2 className="print mb-5">{t('Equipment')}</h2>
              <PanelContent>{viewType === 'list' ? renderList() : renderMap()}</PanelContent>
            </Panel>
            <Panel medium={true} strokeLeft={true} className="no-print" open={filterPanelDetails.isOpen}>
              <ManageEquipmentFilter />
            </Panel>
          </PanelContent>
          <Toast isOpen={errorToastMessage.length !== 0} onClose={handleErrorToastClose} theme={Theme.DANGER}>
            {errorToastMessage}
          </Toast>
        </Panel>
      </>
    );
  }

  const classes = classNames('manage-equipment', className);

  return (
    <>
      <Helmet>
        <title>{t('Manage Equipment')}</title>
      </Helmet>
      {isFeatureEnabled ? renderMain() : FeatureUnavailable}
      {selectedEquipment && (
        <DeleteConfirmationDialog
          isOpen={deleteDetails.isOpen}
          onClose={deleteDetails.close}
          onDelete={handleDelete}
          title={t('Delete equipment?')}
          showHeaderDivider={false}
        >
          {t('If you delete this item it will be gone forever. Are you sure you want to proceed?')}
        </DeleteConfirmationDialog>
      )}
      {selectedEquipment && (
        <ManageEquipmentDetailsDialog
          isOpen={equipmentDetails.isOpen}
          onClose={handleEditClose}
          equipment={selectedEquipment}
          onEdit={() => refetch()}
          onDelete={handleDelete}
        />
      )}
      <CreateEquipmentFormDialog
        isOpen={createEquipmentDetails.isOpen}
        onClose={createEquipmentDetails.close}
        onSubmit={handleCreateSubmit}
        onImportClick={importDetails.open}
      />
      <CSVImportEquipmentContainer
        importDialogOpen={importDetails.isOpen}
        onImportDialogClose={importDetails.close}
        onImportComplete={clearData}
        onReimportDialogClose={reimportDialogDetails.close}
        reimportDialogOpen={reimportDialogDetails.isOpen}
        onDownloadList={handleCsvExport(true)}
      />
      <RowSettingsDialog
        isOpen={rowSettingsDialog.isOpen}
        onClose={rowSettingsDialog.close}
        initialValue={rowHeight}
        onSubmit={setRowHeight}
      />
    </>
  );
};

export const getEquipmentSearch = (search: string | null): EquipmentSearch | undefined => {
  if (search && search.length) {
    return {
      type: SearchType.Contains,
      fields: [EquipmentSearchFields.EquipmentName, EquipmentSearchFields.MakeName, EquipmentSearchFields.ModelName],
      value: search,
    };
  } else {
    return undefined;
  }
};

export default ManageEquipment;
