import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import './PseudoLinkText.scss';

interface IPseudoLinkText {
  label: string;
  onClick: () => void;
  className?: ClassName;
}

export default function PseudoLinkText({ onClick, label, className }: IPseudoLinkText) {
  return (
    <span className={classNames('pseudo-link-text', className)} onClick={onClick}>
      {label}
    </span>
  );
}
