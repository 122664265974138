import gql from 'graphql-tag';

export const equipmentMakesQuery = gql`
  query EquipmentMake($first: Int, $after: String, $filter: EquipmentMakeFilter) {
    equipmentMakes(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      review
      unknown
      cursor
    }
  }
`;

export const equipmentMakeQuery = gql`
  query EquipmentMakeById($makeId: Uuid!) {
    equipmentMakes(filter: { id: { equal: $makeId } }) {
      id
      title
      review
      unknown
      cursor
    }
  }
`;
