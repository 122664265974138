import useReduxSelector from 'hooks/store/useReduxSelector';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { isStoreSessionAuthenticated } from 'store/session/sessionType';
import { useFeatureFlags } from 'utils/features/hooks';
import { type IAccessControlProps } from '../AccessControl';
import { ValidateAgreementsCacheCheck } from './ValidateAgreementsCacheCheck';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IValidateValidateAgreementsProps extends Pick<IAccessControlProps, 'authenticate'> {}

export const ValidateAgreementsContainer: FunctionComponent<PropsWithChildren<IValidateValidateAgreementsProps>> = ({
  children,
  authenticate,
}) => {
  const enableFeature = useFeatureFlags('BLOCKING_POLICY_POPUP');
  const session = useReduxSelector((state) => state.session);

  if (enableFeature && authenticate && isStoreSessionAuthenticated(session)) {
    return <ValidateAgreementsCacheCheck session={session} children={children} />;
  }

  return <>{children}</>;
};
