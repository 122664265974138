import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    EquipmentLaborMetricsInterface: [
      'CostCodeEquipmentLaborMetrics',
      'EquipmentCostCodeLaborMetrics',
      'EquipmentLaborMetrics',
      'EquipmentMemberLaborMetrics',
      'MemberCostCodeEquipmentLaborMetrics',
      'MemberEquipmentLaborMetrics',
    ],
    EquipmentProjectLaborMetricsInterface: [
      'EquipmentProjectLaborMetrics',
      'MemberProjectCostCodeEquipmentLaborMetrics',
      'MemberProjectEquipmentLaborMetrics',
      'ProjectCostCodeEquipmentLaborMetrics',
      'ProjectEquipmentLaborMetrics',
    ],
    LaborMetricsInterface: [
      'CostCodeEquipmentLaborMetrics',
      'CostCodeLaborMetrics',
      'CostCodeMemberLaborMetrics',
      'CostCodeProjectLaborMetrics',
      'EquipmentCostCodeLaborMetrics',
      'EquipmentLaborMetrics',
      'EquipmentMemberLaborMetrics',
      'EquipmentProjectLaborMetrics',
      'MemberCostCodeEquipmentLaborMetrics',
      'MemberCostCodeLaborMetrics',
      'MemberEquipmentLaborMetrics',
      'MemberLaborMetrics',
      'MemberProjectCostCodeEquipmentLaborMetrics',
      'MemberProjectCostCodeLaborMetrics',
      'MemberProjectEquipmentLaborMetrics',
      'MemberProjectLaborMetrics',
      'OrganizationLaborMetrics',
      'ProjectCostCodeEquipmentLaborMetrics',
      'ProjectCostCodeLaborMetrics',
      'ProjectEquipmentLaborMetrics',
      'ProjectLaborMetrics',
      'ProjectMemberLaborMetrics',
    ],
    ProjectLaborMetricsInterface: [
      'CostCodeProjectLaborMetrics',
      'EquipmentProjectLaborMetrics',
      'MemberProjectCostCodeEquipmentLaborMetrics',
      'MemberProjectCostCodeLaborMetrics',
      'MemberProjectEquipmentLaborMetrics',
      'MemberProjectLaborMetrics',
      'ProjectCostCodeEquipmentLaborMetrics',
      'ProjectCostCodeLaborMetrics',
      'ProjectEquipmentLaborMetrics',
      'ProjectLaborMetrics',
      'ProjectMemberLaborMetrics',
    ],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: any; output: any };
  FileUrl: { input: string; output: string };
  Long: { input: number; output: number };
  NotEmptyString: { input: string; output: string };
  RetroLocalizedTimestamp: { input: string; output: string };
  RetroTimestamp: { input: string; output: string };
  Time: { input: string; output: string };
  Timestamp: { input: string; output: string };
  Uuid: { input: string; output: string };
  Variant: { input: any; output: any };
};

export enum ActiveStatus {
  Active = 'active',
  Archived = 'archived',
}

export type ActiveStatusComparison = {
  contains?: InputMaybe<Array<ActiveStatus>>;
  doesNotContain?: InputMaybe<Array<ActiveStatus>>;
  equal?: InputMaybe<ActiveStatus>;
  equalOrNull?: InputMaybe<ActiveStatus>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<ActiveStatus>;
};

export type AuthoritativeSignature = {
  __typename?: 'AuthoritativeSignature';
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  proxyMember: Member;
  proxyMemberId: Scalars['Uuid']['output'];
  signatureId?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['FileUrl']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type AuthoritativeSignatureCreateInput = {
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDst: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
};

export type AuthoritativeSignatureFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  proxyMember?: InputMaybe<MemberFilter>;
  proxyMemberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type AuthoritativeSignatureSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  proxyMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type AuthoritativeSignatureUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type BetweenFloat = {
  end?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['Float']['input']>;
};

export type BetweenInt = {
  end?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['Float']['input']>;
};

export type BetweenTimestamp = {
  end?: InputMaybe<Scalars['Timestamp']['input']>;
  start?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type BooleanComparison = {
  contains?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  equal?: InputMaybe<Scalars['Boolean']['input']>;
  equalOrNull?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BudgetCost = {
  __typename?: 'BudgetCost';
  costBudget: Scalars['Float']['output'];
  costCode?: Maybe<CostCode>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipment?: Maybe<Equipment>;
  equipmentCostBudget?: Maybe<Scalars['Float']['output']>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  isLatest: Scalars['Boolean']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['Uuid']['output']>;
  project: Project;
  projectId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type BudgetCostCreateInput = {
  costBudget: Scalars['Float']['input'];
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentCostBudget?: InputMaybe<Scalars['Float']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['Uuid']['input'];
};

export type BudgetCostFilter = {
  costBudget?: InputMaybe<FloatComparison>;
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentCostBudget?: InputMaybe<FloatComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  isLatest?: InputMaybe<BooleanComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type BudgetCostSort = {
  costBudget?: InputMaybe<SortOrder>;
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  equipmentCostBudget?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type BudgetHours = {
  __typename?: 'BudgetHours';
  budgetSeconds: Scalars['Long']['output'];
  costCode?: Maybe<CostCode>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipment?: Maybe<Equipment>;
  equipmentBudgetSeconds?: Maybe<Scalars['Long']['output']>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  isLatest: Scalars['Boolean']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['Uuid']['output']>;
  project: Project;
  projectId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type BudgetHoursCreateInput = {
  budgetSeconds: Scalars['Long']['input'];
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentBudgetSeconds?: InputMaybe<Scalars['Long']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['Uuid']['input'];
};

export type BudgetHoursFilter = {
  budgetSeconds?: InputMaybe<IntComparison>;
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentBudgetSeconds?: InputMaybe<IntComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  isLatest?: InputMaybe<BooleanComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type BudgetHoursSort = {
  budgetSeconds?: InputMaybe<SortOrder>;
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  equipmentBudgetSeconds?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type BusySignInQuestion = {
  __typename?: 'BusySignInQuestion';
  choices?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  questionDescription?: Maybe<Scalars['String']['output']>;
  questionPrompt?: Maybe<Scalars['String']['output']>;
  questionTitle: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  type: Scalars['String']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type BusySignInQuestionFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  questionTitle?: InputMaybe<StringComparison>;
  required?: InputMaybe<BooleanComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type BusySignInQuestionSort = {
  createdOn?: InputMaybe<SortOrder>;
  questionTitle?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ClientTimeEntryBreakLog = {
  __typename?: 'ClientTimeEntryBreakLog';
  /**
   * Pause = 10
   * Pause Location = 11
   * Resume = 20
   * Resume Location = 21
   * System Resume = 25
   * Manual = 50
   * Edit = 60
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['output'];
  clientTimeEntryLogId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  endDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  endLocationId?: Maybe<Scalars['Uuid']['output']>;
  endTime?: Maybe<Scalars['RetroTimestamp']['output']>;
  endTimeDst?: Maybe<Scalars['Boolean']['output']>;
  endTimeTrusted?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  mergedTimeEntryBreakId?: Maybe<Scalars['Uuid']['output']>;
  originalTimeEntryBreakId?: Maybe<Scalars['Uuid']['output']>;
  paidBreak?: Maybe<Scalars['Boolean']['output']>;
  startDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  startLocationId?: Maybe<Scalars['Uuid']['output']>;
  startTime: Scalars['RetroTimestamp']['output'];
  startTimeDst: Scalars['Boolean']['output'];
  startTimeTrusted: Scalars['Int']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeEntryBreak: TimeEntryBreak;
  timeEntryBreakCreatedOn: Scalars['Timestamp']['output'];
  timeEntryBreakDeletedOn?: Maybe<Scalars['Timestamp']['output']>;
  timeEntryBreakId: Scalars['Uuid']['output'];
  timeEntryId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  updaterMemberId: Scalars['Uuid']['output'];
};

export type ClientTimeEntryBreakLogCreateInput = {
  /**
   * Pause = 10
   * Pause Location = 11
   * Resume = 20
   * Resume Location = 21
   * System Resume = 25
   * Manual = 50
   * Edit = 60
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['input'];
  clientTimeEntryLogId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  endDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  endLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeDst?: InputMaybe<Scalars['Boolean']['input']>;
  endTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mergedTimeEntryBreakId?: InputMaybe<Scalars['Uuid']['input']>;
  originalTimeEntryBreakId?: InputMaybe<Scalars['Uuid']['input']>;
  paidBreak?: InputMaybe<Scalars['Boolean']['input']>;
  startDeviceTime: Scalars['Timestamp']['input'];
  startLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeDst: Scalars['Boolean']['input'];
  startTimeTrusted: Scalars['Int']['input'];
  timeEntryBreakCreatedOn: Scalars['Timestamp']['input'];
  timeEntryBreakDeletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  timeEntryBreakId: Scalars['Uuid']['input'];
  timeEntryId: Scalars['Uuid']['input'];
  updaterMemberId: Scalars['Uuid']['input'];
};

export type ClientTimeEntryBreakLogFilter = {
  clientTimeEntryLogId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeEntry?: InputMaybe<TimeEntryFilter>;
  timeEntryBreakId?: InputMaybe<UuidComparison>;
  timeEntryId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ClientTimeEntryBreakLogSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeEntry?: InputMaybe<TimeEntrySort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ClientTimeEntryLog = {
  __typename?: 'ClientTimeEntryLog';
  /**
   * Clock In = 10
   * Clock In Location = 11
   * Clock Out = 20
   * Clock Out Location = 21
   * System Clock Out = 25
   * Clock In At = 30
   * Clock Out At = 40
   * Manual = 50
   * Edit = 60
   * Break Action = 70
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['output'];
  costCode?: Maybe<CostCode>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  daylightSavingTime: Scalars['Boolean']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  endDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  endLocationId?: Maybe<Scalars['Uuid']['output']>;
  endTime?: Maybe<Scalars['RetroTimestamp']['output']>;
  endTimeTrusted?: Maybe<Scalars['Int']['output']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  mergedTimeEntryId?: Maybe<Scalars['Uuid']['output']>;
  metaDaylightSavingTime?: Maybe<Scalars['Boolean']['output']>;
  originalTimeEntryId?: Maybe<Scalars['Uuid']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  startDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  startLocationId?: Maybe<Scalars['Uuid']['output']>;
  startTime: Scalars['RetroTimestamp']['output'];
  startTimeTrusted: Scalars['Int']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeEntry: TimeEntry;
  timeEntryCreatedOn: Scalars['Timestamp']['output'];
  timeEntryDeletedOn?: Maybe<Scalars['Timestamp']['output']>;
  timeEntryId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  updaterMember: Member;
  updaterMemberId: Scalars['Uuid']['output'];
};

export type ClientTimeEntryLogCreateInput = {
  /**
   * Clock In = 10
   * Clock In Location = 11
   * Clock Out = 20
   * Clock Out Location = 21
   * System Clock Out = 25
   * Clock In At = 30
   * Clock Out At = 40
   * Manual = 50
   * Edit = 60
   * Break Action = 70
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['input'];
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  daylightSavingTime: Scalars['Boolean']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  endDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  endLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  mergedTimeEntryId?: InputMaybe<Scalars['Uuid']['input']>;
  metaDaylightSavingTime?: InputMaybe<Scalars['Boolean']['input']>;
  originalTimeEntryId?: InputMaybe<Scalars['Uuid']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  startDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  startLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeTrusted: Scalars['Int']['input'];
  timeEntryCreatedOn: Scalars['Timestamp']['input'];
  timeEntryDeletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  timeEntryId: Scalars['Uuid']['input'];
  updaterMemberId: Scalars['Uuid']['input'];
};

export type ClientTimeEntryLogFilter = {
  /**
   * Clock In = 10
   * Clock In Location = 11
   * Clock Out = 20
   * Clock Out Location = 21
   * System Clock Out = 25
   * Clock In At = 30
   * Clock Out At = 40
   * Manual = 50
   * Edit = 60
   * Break Action = 70
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType?: InputMaybe<IntComparison>;
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeEntry?: InputMaybe<TimeEntryFilter>;
  timeEntryId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  updaterMember?: InputMaybe<MemberFilter>;
};

export type ClientTimeEntryLogSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum ClockInRestrictionType {
  Off = 'off',
  Schedule = 'schedule',
  Time = 'time',
}

export enum ConditionFieldType {
  BreakDt = 'breakDt',
  BreakDtCents = 'breakDtCents',
  BreakOt = 'breakOt',
  BreakOtCents = 'breakOtCents',
  BreakOverburdenCents = 'breakOverburdenCents',
  BreakRt = 'breakRt',
  BreakRtCents = 'breakRtCents',
  BreakUpt = 'breakUpt',
  CostCodeId = 'costCodeId',
  DescBreakDt = 'descBreakDt',
  DescBreakDtCents = 'descBreakDtCents',
  DescBreakOt = 'descBreakOt',
  DescBreakOtCents = 'descBreakOtCents',
  DescBreakOverburdenCents = 'descBreakOverburdenCents',
  DescBreakRt = 'descBreakRt',
  DescBreakRtCents = 'descBreakRtCents',
  DescBreakUpt = 'descBreakUpt',
  DescDt = 'descDt',
  DescDtCents = 'descDtCents',
  DescOt = 'descOt',
  DescOtCents = 'descOtCents',
  DescOverburdenDtCents = 'descOverburdenDtCents',
  DescOverburdenOtCents = 'descOverburdenOtCents',
  DescOverburdenRtCents = 'descOverburdenRtCents',
  DescRt = 'descRt',
  DescRtCents = 'descRtCents',
  Dt = 'dt',
  DtCents = 'dtCents',
  EquipmentId = 'equipmentId',
  MemberId = 'memberId',
  Ot = 'ot',
  OtCents = 'otCents',
  OverburdenDtCents = 'overburdenDtCents',
  OverburdenOtCents = 'overburdenOtCents',
  OverburdenRtCents = 'overburdenRtCents',
  ProjectId = 'projectId',
  Pto = 'pto',
  PtoCents = 'ptoCents',
  Rt = 'rt',
  RtCents = 'rtCents',
  Upto = 'upto',
}

export enum ConditionNullableFieldType {
  CostCodeId = 'costCodeId',
  EquipmentId = 'equipmentId',
  MemberId = 'memberId',
  ProjectId = 'projectId',
}

export enum ConditionOperationListType {
  Contains = 'contains',
  DoesNotContain = 'doesNotContain',
}

export enum ConditionOperationNullType {
  IsNotNull = 'isNotNull',
  IsNull = 'isNull',
}

export enum ConditionOperationType {
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotEqual = 'notEqual',
}

export type CostCode = {
  __typename?: 'CostCode';
  archivedOn?: Maybe<Scalars['Timestamp']['output']>;
  costCode: Scalars['String']['output'];
  costCodeEquipmentLaborMetrics: Array<CostCodeEquipmentLaborMetrics>;
  costCodeGroup?: Maybe<CostCodeGroup>;
  costCodeGroupId?: Maybe<Scalars['Uuid']['output']>;
  costCodeLaborMetrics: Array<CostCodeLaborMetrics>;
  costCodeMemberEquipmentLaborMetrics: Array<MemberCostCodeEquipmentLaborMetrics>;
  costCodeMemberLaborMetrics: Array<CostCodeMemberLaborMetrics>;
  costCodeMemberProjectEquipmentLaborMetrics: Array<MemberProjectCostCodeEquipmentLaborMetrics>;
  costCodeMemberProjectLaborMetrics: Array<MemberProjectCostCodeLaborMetrics>;
  costCodeProjectEquipmentLaborMetrics: Array<ProjectCostCodeEquipmentLaborMetrics>;
  costCodeProjectLaborMetrics: Array<CostCodeProjectLaborMetrics>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  generalLiabilityId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  openTimeEntries: Array<TimeEntry>;
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  unitTitle?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
  workersCompId?: Maybe<Scalars['Uuid']['output']>;
};

export type CostCodeCostCodeEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeMemberEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeMemberLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeMemberProjectEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeMemberProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeProjectEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type CostCodeCostCodeProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type CostCodeClockState = {
  __typename?: 'CostCodeClockState';
  all: Scalars['Int']['output'];
  clockedIn: Scalars['Int']['output'];
  clockedInNotOnBreak: Scalars['Int']['output'];
  clockedOut: Scalars['Int']['output'];
  onBreak: Scalars['Int']['output'];
};

export type CostCodeClockStateFilter = {
  costCodeGroupId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentCategoryId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  memberGroupId?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  projectGroupId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  status?: InputMaybe<ActiveStatusComparison>;
};

export type CostCodeCreateInput = {
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  costCode: Scalars['String']['input'];
  costCodeGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  generalLiabilityId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  unitTitle?: InputMaybe<Scalars['String']['input']>;
  workersCompId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type CostCodeEquipmentLaborMetrics = EquipmentLaborMetricsInterface &
  LaborMetricsInterface & {
    __typename?: 'CostCodeEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId: Scalars['Uuid']['output'];
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type CostCodeFilter = {
  archivedOn?: InputMaybe<TimestampComparison>;
  costCode?: InputMaybe<StringComparison>;
  costCodeGroup?: InputMaybe<CostCodeGroupFilter>;
  costCodeGroupId?: InputMaybe<UuidComparison>;
  costCodesWithTime?: InputMaybe<CostCodeHasTimeFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  openTimeEntries?: InputMaybe<TimeEntryFilter>;
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkFilter>;
  quantities?: InputMaybe<ProjectCostCodeQuantityFilter>;
  search?: InputMaybe<CostCodeSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type CostCodeGroup = {
  __typename?: 'CostCodeGroup';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type CostCodeGroupCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  groupName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
};

export type CostCodeGroupFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  groupName?: InputMaybe<StringComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type CostCodeGroupSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  groupName?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type CostCodeGroupUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type CostCodeHasTimeFilter = {
  endTime: Scalars['Timestamp']['input'];
  equipmentId?: InputMaybe<UuidComparison>;
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  startTime: Scalars['Timestamp']['input'];
};

export type CostCodeLaborMetrics = LaborMetricsInterface & {
  __typename?: 'CostCodeLaborMetrics';
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export type CostCodeMemberLaborMetrics = LaborMetricsInterface & {
  __typename?: 'CostCodeMemberLaborMetrics';
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  costCodeId: Scalars['Uuid']['output'];
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['Uuid']['output']>;
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export type CostCodeProjectLaborMetrics = LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'CostCodeProjectLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId: Scalars['Uuid']['output'];
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type CostCodeSearch = {
  fields: Array<CostCodeSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum CostCodeSearchFields {
  CostCode = 'costCode',
  Title = 'title',
}

export type CostCodeSort = {
  archivedOn?: InputMaybe<SortOrder>;
  costCode?: InputMaybe<SortOrder>;
  costCodeGroup?: InputMaybe<CostCodeGroupSort>;
  createdOn?: InputMaybe<SortOrder>;
  openTimeEntries?: InputMaybe<TimeEntrySort>;
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkSort>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  unitTitle?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type CostCodeUpdateInput = {
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  costCode?: InputMaybe<Scalars['String']['input']>;
  costCodeGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  generalLiabilityId?: InputMaybe<Scalars['Uuid']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  unitTitle?: InputMaybe<Scalars['String']['input']>;
  workersCompId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type DailyProjectReport = {
  __typename?: 'DailyProjectReport';
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  project: Project;
  projectId: Scalars['Uuid']['output'];
  reportDate: Scalars['Timestamp']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  template?: Maybe<DailyProjectReportTemplate>;
  templateId?: Maybe<Scalars['Uuid']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type DailyProjectReportCreateInput = {
  createdBy: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['Uuid']['input'];
  reportDate: Scalars['Timestamp']['input'];
  templateId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type DailyProjectReportFilter = {
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  reportDate?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  template?: InputMaybe<DailyProjectReportTemplateFilter>;
  templateId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type DailyProjectReportSort = {
  createdBy?: InputMaybe<SortOrder>;
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  projectId?: InputMaybe<SortOrder>;
  reportDate?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  template?: InputMaybe<DailyProjectReportTemplateSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type DailyProjectReportTemplate = {
  __typename?: 'DailyProjectReportTemplate';
  appearance?: Maybe<Scalars['String']['output']>;
  audienceType: Scalars['String']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars['Uuid']['output']>;
  sections?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type DailyProjectReportTemplateCreateInput = {
  appearance?: InputMaybe<Scalars['String']['input']>;
  audienceType: Scalars['String']['input'];
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  sections?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type DailyProjectReportTemplateFilter = {
  audienceType?: InputMaybe<StringComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  position?: InputMaybe<PositionFilter>;
  positionId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type DailyProjectReportTemplateSort = {
  audienceType?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  positionId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type DailyProjectReportTemplateUpdateInput = {
  appearance?: InputMaybe<Scalars['String']['input']>;
  audienceType: Scalars['String']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  sections?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type DailyProjectReportUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  reportDate?: InputMaybe<Scalars['Timestamp']['input']>;
  templateId?: InputMaybe<Scalars['Uuid']['input']>;
};

export enum EmployeeAutoInvite {
  DontInvite = 'dontInvite',
  Email = 'email',
  Text = 'text',
  TextAndEmail = 'textAndEmail',
}

export type Equipment = {
  __typename?: 'Equipment';
  archivedOn?: Maybe<Scalars['Timestamp']['output']>;
  costHistory: Array<EquipmentCostHistory>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipmentCostCodeLaborMetrics: Array<EquipmentCostCodeLaborMetrics>;
  equipmentGroupId?: Maybe<Scalars['Uuid']['output']>;
  equipmentHourMeterMetrics: Array<EquipmentHourMeterMetrics>;
  equipmentLaborMetrics: Array<EquipmentLaborMetrics>;
  equipmentMemberCostCodeLaborMetrics: Array<MemberCostCodeEquipmentLaborMetrics>;
  equipmentMemberLaborMetrics: Array<EquipmentMemberLaborMetrics>;
  equipmentMemberProjectCostCodeLaborMetrics: Array<MemberProjectCostCodeEquipmentLaborMetrics>;
  equipmentMemberProjectLaborMetrics: Array<MemberProjectEquipmentLaborMetrics>;
  equipmentModelId?: Maybe<Scalars['Uuid']['output']>;
  equipmentName: Scalars['String']['output'];
  equipmentProjectCostCodeLaborMetrics: Array<ProjectCostCodeEquipmentLaborMetrics>;
  equipmentProjectLaborMetrics: Array<EquipmentProjectLaborMetrics>;
  fuelCapacity?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['FileUrl']['output']>;
  importTtlSeconds?: Maybe<Scalars['Int']['output']>;
  importType?: Maybe<Scalars['String']['output']>;
  lastHours?: Maybe<EquipmentHours>;
  lastHoursId?: Maybe<Scalars['Uuid']['output']>;
  lastLocation?: Maybe<EquipmentLocation>;
  lastLocationId?: Maybe<Scalars['Uuid']['output']>;
  lastSync?: Maybe<Scalars['Timestamp']['output']>;
  model?: Maybe<EquipmentModel>;
  openTimeEntries?: Maybe<Array<TimeEntry>>;
  organizationId: Scalars['Uuid']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  trackImoId?: Maybe<Scalars['Int']['output']>;
  trackManualHours: Scalars['Boolean']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type EquipmentEquipmentCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentHourMeterMetricsArgs = {
  end: Scalars['Date']['input'];
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentMemberCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentMemberLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentMemberProjectCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentMemberProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentProjectCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type EquipmentEquipmentProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type EquipmentCategory = {
  __typename?: 'EquipmentCategory';
  createdOn?: Maybe<Scalars['Timestamp']['output']>;
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipmentTypeId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['FileUrl']['output']>;
  organizationId?: Maybe<Scalars['Uuid']['output']>;
  review?: Maybe<Scalars['Boolean']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: EquipmentType;
  updatedOn: Scalars['Timestamp']['output'];
};

export type EquipmentCategoryCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentTypeId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type EquipmentCategoryFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipmentTypeId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  organizationId?: InputMaybe<UuidComparison>;
  review?: InputMaybe<BooleanComparison>;
  search?: InputMaybe<EquipmentCategorySearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentCategorySearch = {
  fields: Array<EquipmentCategorySearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum EquipmentCategorySearchFields {
  Title = 'title',
}

export type EquipmentCategorySort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type EquipmentCategoryUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentTypeId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentClockState = {
  __typename?: 'EquipmentClockState';
  all: Scalars['Int']['output'];
  clockedIn: Scalars['Int']['output'];
  clockedInNotOnBreak: Scalars['Int']['output'];
  clockedOut: Scalars['Int']['output'];
  onBreak: Scalars['Int']['output'];
};

export type EquipmentClockStateFilter = {
  costCodeGroupId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentCategoryId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  memberGroupId?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  projectGroupId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
};

export type EquipmentCostCodeLaborMetrics = EquipmentLaborMetricsInterface &
  LaborMetricsInterface & {
    __typename?: 'EquipmentCostCodeLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId?: Maybe<Scalars['Uuid']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId: Scalars['Uuid']['output'];
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type EquipmentCostHistory = {
  __typename?: 'EquipmentCostHistory';
  changeDate: Scalars['Timestamp']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipment: Equipment;
  equipmentId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  machineCostRate?: Maybe<Scalars['Float']['output']>;
  operatorCostRate?: Maybe<Scalars['Float']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type EquipmentCostHistoryCreateInput = {
  changeDate: Scalars['Timestamp']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  machineCostRate?: InputMaybe<Scalars['Float']['input']>;
  operatorCostRate?: InputMaybe<Scalars['Float']['input']>;
};

export type EquipmentCostHistoryFilter = {
  changeDate?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentCostHistorySort = {
  changeDate?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  machineCostRate?: InputMaybe<SortOrder>;
  operatorCostRate?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type EquipmentCostHistoryUpdateInput = {
  changeDate?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id: Scalars['ID']['input'];
  machineCostRate?: InputMaybe<Scalars['Float']['input']>;
  operatorCostRate?: InputMaybe<Scalars['Float']['input']>;
};

export type EquipmentCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  equipmentGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  equipmentModelId?: InputMaybe<Scalars['Uuid']['input']>;
  equipmentName: Scalars['String']['input'];
  fuelCapacity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importType?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['Uuid']['input'];
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  trackManualHours: Scalars['Boolean']['input'];
};

export type EquipmentFilter = {
  archivedOn?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipmentName?: InputMaybe<StringComparison>;
  equipmentWithTime?: InputMaybe<EquipmentHasTimeFilter>;
  id?: InputMaybe<UuidComparison>;
  model?: InputMaybe<EquipmentModelFilter>;
  openTimeEntries?: InputMaybe<TimeEntryFilter>;
  search?: InputMaybe<EquipmentSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentHasTimeFilter = {
  costCodeId?: InputMaybe<UuidComparison>;
  endTime: Scalars['Timestamp']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  startTime: Scalars['Timestamp']['input'];
};

export type EquipmentHourMeterMetrics = {
  __typename?: 'EquipmentHourMeterMetrics';
  end: Scalars['Date']['output'];
  equipmentId: Scalars['Uuid']['output'];
  hoursDiff?: Maybe<Scalars['Float']['output']>;
  start: Scalars['Date']['output'];
};

export type EquipmentHours = {
  __typename?: 'EquipmentHours';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipment: Equipment;
  equipmentId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  runningHours?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  submittedByMember?: Maybe<Member>;
  submittedByMemberId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type EquipmentHoursCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  equipmentId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  runningHours: Scalars['Float']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  submittedByMemberId: Scalars['Uuid']['input'];
};

export type EquipmentHoursFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedByMemberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentHoursSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  runningHours?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type EquipmentLaborMetrics = EquipmentLaborMetricsInterface &
  LaborMetricsInterface & {
    __typename?: 'EquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type EquipmentLaborMetricsInterface = {
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  equipmentCents?: Maybe<Scalars['Long']['output']>;
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export type EquipmentLocation = {
  __typename?: 'EquipmentLocation';
  altitude?: Maybe<Scalars['Float']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipment: Equipment;
  equipmentId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  utc: Scalars['Timestamp']['output'];
};

export type EquipmentLocationFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  latitude?: InputMaybe<FloatComparison>;
  longitude?: InputMaybe<FloatComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  utc?: InputMaybe<TimestampComparison>;
};

export type EquipmentLocationSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  utc?: InputMaybe<SortOrder>;
};

export type EquipmentMake = {
  __typename?: 'EquipmentMake';
  createdOn?: Maybe<Scalars['Timestamp']['output']>;
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Uuid']['output']>;
  review: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  unknown?: Maybe<Scalars['Boolean']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type EquipmentMakeCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type EquipmentMakeFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  model?: InputMaybe<EquipmentModelFilter>;
  review?: InputMaybe<BooleanComparison>;
  search?: InputMaybe<EquipmentMakeSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  unknown?: InputMaybe<BooleanComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentMakeSearch = {
  fields: Array<EquipmentMakeSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum EquipmentMakeSearchFields {
  Title = 'title',
}

export type EquipmentMakeSort = {
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  unknown?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type EquipmentMakeUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentMemberLaborMetrics = EquipmentLaborMetricsInterface &
  LaborMetricsInterface & {
    __typename?: 'EquipmentMemberLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId: Scalars['Uuid']['output'];
    id?: Maybe<Scalars['String']['output']>;
    memberId?: Maybe<Scalars['Uuid']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type EquipmentModel = {
  __typename?: 'EquipmentModel';
  category: EquipmentCategory;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipmentCategoryId: Scalars['Uuid']['output'];
  equipmentMakeId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['FileUrl']['output']>;
  make: EquipmentMake;
  modelNumber?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  unknown?: Maybe<Scalars['Boolean']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type EquipmentModelCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentCategoryId: Scalars['ID']['input'];
  equipmentMakeId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  modelNumber?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentModelFilter = {
  category?: InputMaybe<EquipmentCategoryFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipmentCategoryId?: InputMaybe<UuidComparison>;
  equipmentMakeId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  make?: InputMaybe<EquipmentMakeFilter>;
  search?: InputMaybe<EquipmentModelSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  unknown?: InputMaybe<BooleanComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentModelSearch = {
  fields: Array<EquipmentModelSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum EquipmentModelSearchFields {
  Title = 'title',
}

export type EquipmentModelSort = {
  category?: InputMaybe<EquipmentCategorySort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  make?: InputMaybe<EquipmentMakeSort>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  unknown?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export type EquipmentModelUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentCategoryId?: InputMaybe<Scalars['ID']['input']>;
  equipmentMakeId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  modelNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentProjectLaborMetrics = EquipmentProjectLaborMetricsInterface &
  LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'EquipmentProjectLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descEquipmentCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId: Scalars['Uuid']['output'];
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type EquipmentProjectLaborMetricsInterface = {
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  descBreakDt?: Maybe<Scalars['Long']['output']>;
  descBreakDtCents?: Maybe<Scalars['Long']['output']>;
  descBreakOt?: Maybe<Scalars['Long']['output']>;
  descBreakOtCents?: Maybe<Scalars['Long']['output']>;
  descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  descBreakRt?: Maybe<Scalars['Long']['output']>;
  descBreakRtCents?: Maybe<Scalars['Long']['output']>;
  descBreakUpt?: Maybe<Scalars['Long']['output']>;
  descDt?: Maybe<Scalars['Long']['output']>;
  descDtCents?: Maybe<Scalars['Long']['output']>;
  descEquipmentCents?: Maybe<Scalars['Long']['output']>;
  descOt?: Maybe<Scalars['Long']['output']>;
  descOtCents?: Maybe<Scalars['Long']['output']>;
  descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
  descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
  descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
  descRt?: Maybe<Scalars['Long']['output']>;
  descRtCents?: Maybe<Scalars['Long']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  equipmentCents?: Maybe<Scalars['Long']['output']>;
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export type EquipmentSearch = {
  fields: Array<EquipmentSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum EquipmentSearchFields {
  EquipmentName = 'equipmentName',
  MakeName = 'makeName',
  ModelName = 'modelName',
}

export enum EquipmentSelectionOptions {
  Off = 'off',
  Recommended = 'recommended',
  Required = 'required',
}

export type EquipmentSort = {
  archivedOn?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  equipmentName?: InputMaybe<SortOrder>;
  lastHours?: InputMaybe<EquipmentHoursSort>;
  model?: InputMaybe<EquipmentModelSort>;
  openTimeEntries?: InputMaybe<TimeEntrySort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type EquipmentType = {
  __typename?: 'EquipmentType';
  color?: Maybe<Scalars['Int']['output']>;
  createdOn?: Maybe<Scalars['Timestamp']['output']>;
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  display: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['Uuid']['output']>;
  review: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type EquipmentTypeCreateInput = {
  color?: InputMaybe<Scalars['Int']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type EquipmentTypeFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  display?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  organizationId?: InputMaybe<UuidComparison>;
  review?: InputMaybe<BooleanComparison>;
  search?: InputMaybe<EquipmentTypeSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type EquipmentTypeSearch = {
  fields: Array<EquipmentTypeSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum EquipmentTypeSearchFields {
  Title = 'title',
}

export type EquipmentTypeSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type EquipmentTypeUpdateInput = {
  color?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentUpdateInput = {
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  equipmentModelId?: InputMaybe<Scalars['Uuid']['input']>;
  equipmentName?: InputMaybe<Scalars['String']['input']>;
  fuelCapacity?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  importType?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  trackManualHours?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FlaggedTimeEntryLocation = {
  __typename?: 'FlaggedTimeEntryLocation';
  comment?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  edited: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastStatusUpdatedOn?: Maybe<Scalars['Timestamp']['output']>;
  locationData: Scalars['String']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  organizationId: Scalars['Uuid']['output'];
  reviewedByMember?: Maybe<Member>;
  reviewedByMemberId?: Maybe<Scalars['Uuid']['output']>;
  status?: Maybe<FlaggedTimeEntryLocationStatus>;
  submittedOn: Scalars['Timestamp']['output'];
  timeEntry: TimeEntry;
  timeEntryId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type FlaggedTimeEntryLocationCreateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  locationData: Scalars['String']['input'];
  memberId: Scalars['Uuid']['input'];
  status?: InputMaybe<FlaggedTimeEntryLocationStatus>;
  timeEntryId: Scalars['Uuid']['input'];
};

export type FlaggedTimeEntryLocationFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  edited?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  lastStatusUpdatedOn?: InputMaybe<TimestampComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  reviewedByMember?: InputMaybe<MemberFilter>;
  reviewedByMemberId?: InputMaybe<UuidComparison>;
  status?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeEntry?: InputMaybe<TimeEntryFilter>;
  timeEntryId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type FlaggedTimeEntryLocationSort = {
  comment?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  edited?: InputMaybe<SortOrder>;
  lastStatusUpdatedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  memberId?: InputMaybe<SortOrder>;
  reviewedByMember?: InputMaybe<MemberSort>;
  reviewedByMemberId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeEntry?: InputMaybe<TimeEntrySort>;
  timeEntryId?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum FlaggedTimeEntryLocationStatus {
  Authorized = 'authorized',
  Unauthorized = 'unauthorized',
}

export type FlaggedTimeEntryLocationUpdateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  locationData?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FlaggedTimeEntryLocationStatus>;
};

export type FloatComparison = {
  between?: InputMaybe<BetweenFloat>;
  contains?: InputMaybe<Array<Scalars['Float']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['Float']['input']>>;
  equal?: InputMaybe<Scalars['Float']['input']>;
  equalOrNull?: InputMaybe<Scalars['Float']['input']>;
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  greaterThanOrEqual?: InputMaybe<Scalars['Float']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Float']['input']>;
  lessThanOrEqual?: InputMaybe<Scalars['Float']['input']>;
  notEqual?: InputMaybe<Scalars['Float']['input']>;
};

export type Folder = {
  __typename?: 'Folder';
  color?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  type: FolderTypes;
  updatedOn: Scalars['Timestamp']['output'];
};

export type FolderCreateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: FolderTypes;
};

export type FolderFilter = {
  color?: InputMaybe<StringComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type FolderSort = {
  color?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum FolderTypes {
  Document = 'document',
}

export type FolderUpdateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<FolderTypes>;
};

export type Incident = {
  __typename?: 'Incident';
  answers: Array<IncidentAnswer>;
  caseNumber: Scalars['Int']['output'];
  completedBy?: Maybe<Scalars['Uuid']['output']>;
  completedByMember?: Maybe<Member>;
  completedOn?: Maybe<Scalars['Timestamp']['output']>;
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  employeeAddress1?: Maybe<Scalars['String']['output']>;
  employeeAddress2?: Maybe<Scalars['String']['output']>;
  employeeBirthday?: Maybe<Scalars['Date']['output']>;
  employeeCity?: Maybe<Scalars['String']['output']>;
  employeeHireDate?: Maybe<Scalars['Date']['output']>;
  employeeJobTitle?: Maybe<Scalars['String']['output']>;
  employeePostalCode?: Maybe<Scalars['String']['output']>;
  employeeSex?: Maybe<IncidentEmployeeSexes>;
  employeeStartTime?: Maybe<Scalars['Timestamp']['output']>;
  employeeState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  illnessType?: Maybe<IncidentIllnessTypes>;
  medicalDetails?: Maybe<IncidentMedicalDetails>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  occurredAt: Scalars['Timestamp']['output'];
  occurrenceTimeSet: Scalars['Boolean']['output'];
  organizationId: Scalars['Uuid']['output'];
  outcomeDetails?: Maybe<IncidentOutcomeDetails>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  recordable: Scalars['Boolean']['output'];
  reviewedBy?: Maybe<Scalars['Uuid']['output']>;
  reviewedByMember?: Maybe<Member>;
  reviewedOn?: Maybe<Scalars['Timestamp']['output']>;
  state: IncidentStates;
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  type: IncidentTypes;
  updatedOn: Scalars['Timestamp']['output'];
};

export type IncidentAnswer = {
  __typename?: 'IncidentAnswer';
  answer: Scalars['String']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  incident: Incident;
  incidentId: Scalars['Uuid']['output'];
  order: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  type: IncidentAnswerTypes;
  updatedOn: Scalars['Timestamp']['output'];
};

export type IncidentAnswerCreateInput = {
  answer: Scalars['String']['input'];
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  incidentId: Scalars['Uuid']['input'];
  order: Scalars['Int']['input'];
  question: Scalars['String']['input'];
  type: IncidentAnswerTypes;
};

export type IncidentAnswerFilter = {
  answer?: InputMaybe<StringComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  incident?: InputMaybe<IncidentFilter>;
  incidentId?: InputMaybe<UuidComparison>;
  question?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type IncidentAnswerSort = {
  answer?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  incidentId?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum IncidentAnswerTypes {
  ActivityBefore = 'activity_before',
  Custom = 'custom',
  Location = 'location',
  TypeDescription = 'type_description',
  WhatHappened = 'what_happened',
  WhatHarmed = 'what_harmed',
}

export type IncidentAnswerUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IncidentAnswerTypes>;
};

export type IncidentCreateInput = {
  completedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  createdOn: Scalars['RetroTimestamp']['input'];
  employeeAddress1?: InputMaybe<Scalars['String']['input']>;
  employeeAddress2?: InputMaybe<Scalars['String']['input']>;
  employeeBirthday?: InputMaybe<Scalars['Date']['input']>;
  employeeCity?: InputMaybe<Scalars['String']['input']>;
  employeeHireDate?: InputMaybe<Scalars['Date']['input']>;
  employeeJobTitle?: InputMaybe<Scalars['String']['input']>;
  employeePostalCode?: InputMaybe<Scalars['String']['input']>;
  employeeSex?: InputMaybe<IncidentEmployeeSexes>;
  employeeStartTime?: InputMaybe<Scalars['Timestamp']['input']>;
  employeeState?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  illnessType?: InputMaybe<IncidentIllnessTypes>;
  memberId: Scalars['Uuid']['input'];
  occurredAt: Scalars['Timestamp']['input'];
  occurrenceTimeSet: Scalars['Boolean']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  recordable: Scalars['Boolean']['input'];
  reviewedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  state: IncidentStates;
  type: IncidentTypes;
};

export enum IncidentEmployeeSexes {
  Female = 'female',
  Male = 'male',
}

export type IncidentFilter = {
  caseNumber?: InputMaybe<IntComparison>;
  completedBy?: InputMaybe<UuidComparison>;
  completedByMember?: InputMaybe<MemberFilter>;
  completedOn?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  employeeJobTitle?: InputMaybe<StringComparison>;
  employeeSex?: InputMaybe<StringComparison>;
  employeeStartTime?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  illnessType?: InputMaybe<StringComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  occurredAt?: InputMaybe<TimestampComparison>;
  occurrenceTimeSet?: InputMaybe<BooleanComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  recordable?: InputMaybe<BooleanComparison>;
  reviewedBy?: InputMaybe<UuidComparison>;
  reviewedByMember?: InputMaybe<MemberFilter>;
  reviewedOn?: InputMaybe<TimestampComparison>;
  state?: InputMaybe<StringComparison>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum IncidentIllnessTypes {
  Hearing = 'hearing',
  Other = 'other',
  Poisoning = 'poisoning',
  Respiratory = 'respiratory',
  Skin = 'skin',
}

export type IncidentMedicalDetails = {
  __typename?: 'IncidentMedicalDetails';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  facilityAddress?: Maybe<Scalars['String']['output']>;
  facilityCity?: Maybe<Scalars['String']['output']>;
  facilityName?: Maybe<Scalars['String']['output']>;
  facilityPostalCode?: Maybe<Scalars['String']['output']>;
  facilityState?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incident?: Maybe<Incident>;
  incidentId: Scalars['Uuid']['output'];
  physicianName?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  treatmentDoctor?: Maybe<Scalars['Boolean']['output']>;
  treatmentER?: Maybe<Scalars['Boolean']['output']>;
  treatmentFirstAid?: Maybe<Scalars['Boolean']['output']>;
  treatmentNone?: Maybe<Scalars['Boolean']['output']>;
  treatmentOther?: Maybe<Scalars['Boolean']['output']>;
  treatmentOvernight?: Maybe<Scalars['Boolean']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type IncidentMedicalDetailsCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  facilityAddress?: InputMaybe<Scalars['String']['input']>;
  facilityCity?: InputMaybe<Scalars['String']['input']>;
  facilityName?: InputMaybe<Scalars['String']['input']>;
  facilityPostalCode?: InputMaybe<Scalars['String']['input']>;
  facilityState?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  incidentId: Scalars['Uuid']['input'];
  physicianName?: InputMaybe<Scalars['String']['input']>;
  treatmentDoctor?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentER?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentFirstAid?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentNone?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentOther?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentOvernight?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IncidentMedicalDetailsFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  facilityAddress?: InputMaybe<StringComparison>;
  facilityCity?: InputMaybe<StringComparison>;
  facilityName?: InputMaybe<StringComparison>;
  facilityPostalCode?: InputMaybe<StringComparison>;
  facilityState?: InputMaybe<StringComparison>;
  id?: InputMaybe<UuidComparison>;
  incident?: InputMaybe<IncidentFilter>;
  incidentId?: InputMaybe<UuidComparison>;
  physicianName?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  treatmentDoctor?: InputMaybe<BooleanComparison>;
  treatmentER?: InputMaybe<BooleanComparison>;
  treatmentFirstAid?: InputMaybe<BooleanComparison>;
  treatmentNone?: InputMaybe<BooleanComparison>;
  treatmentOther?: InputMaybe<BooleanComparison>;
  treatmentOvernight?: InputMaybe<BooleanComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type IncidentMedicalDetailsSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  incidentId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type IncidentMedicalDetailsUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  facilityAddress?: InputMaybe<Scalars['String']['input']>;
  facilityCity?: InputMaybe<Scalars['String']['input']>;
  facilityName?: InputMaybe<Scalars['String']['input']>;
  facilityPostalCode?: InputMaybe<Scalars['String']['input']>;
  facilityState?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  physicianName?: InputMaybe<Scalars['String']['input']>;
  treatmentDoctor?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentER?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentFirstAid?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentNone?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentOther?: InputMaybe<Scalars['Boolean']['input']>;
  treatmentOvernight?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IncidentOutcomeDetails = {
  __typename?: 'IncidentOutcomeDetails';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  daysAway?: Maybe<Scalars['Int']['output']>;
  daysTransfer?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  incident: Incident;
  incidentId: Scalars['Uuid']['output'];
  outcomeDate?: Maybe<Scalars['Timestamp']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  type: IncidentOutcomeDetailsTypes;
  updatedOn: Scalars['Timestamp']['output'];
};

export type IncidentOutcomeDetailsCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  daysAway?: InputMaybe<Scalars['Int']['input']>;
  daysTransfer?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  incidentId: Scalars['Uuid']['input'];
  outcomeDate?: InputMaybe<Scalars['Timestamp']['input']>;
  type: IncidentOutcomeDetailsTypes;
};

export type IncidentOutcomeDetailsFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  daysAway?: InputMaybe<IntComparison>;
  daysTransfer?: InputMaybe<IntComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  incident?: InputMaybe<IncidentFilter>;
  incidentId?: InputMaybe<UuidComparison>;
  outcomeDate?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type IncidentOutcomeDetailsSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  incidentId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum IncidentOutcomeDetailsTypes {
  DaysAway = 'days_away',
  Death = 'death',
  Other = 'other',
  Transfer = 'transfer',
}

export type IncidentOutcomeDetailsUpdateInput = {
  daysAway?: InputMaybe<Scalars['Int']['input']>;
  daysTransfer?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  outcomeDate?: InputMaybe<Scalars['Timestamp']['input']>;
  type?: InputMaybe<IncidentOutcomeDetailsTypes>;
};

export type IncidentSort = {
  completedBy?: InputMaybe<SortOrder>;
  completedByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  occurredAt?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  projectId?: InputMaybe<SortOrder>;
  reviewedBy?: InputMaybe<SortOrder>;
  reviewedByMember?: InputMaybe<MemberSort>;
  submittedBy?: InputMaybe<SortOrder>;
  submittedByMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum IncidentStates {
  Completed = 'completed',
  Pending = 'pending',
  Reviewed = 'reviewed',
}

export enum IncidentTypes {
  Illness = 'illness',
  Injury = 'injury',
}

export type IncidentUpdateInput = {
  completedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  employeeAddress1?: InputMaybe<Scalars['String']['input']>;
  employeeAddress2?: InputMaybe<Scalars['String']['input']>;
  employeeBirthday?: InputMaybe<Scalars['Date']['input']>;
  employeeCity?: InputMaybe<Scalars['String']['input']>;
  employeeHireDate?: InputMaybe<Scalars['Date']['input']>;
  employeeJobTitle?: InputMaybe<Scalars['String']['input']>;
  employeePostalCode?: InputMaybe<Scalars['String']['input']>;
  employeeSex?: InputMaybe<IncidentEmployeeSexes>;
  employeeStartTime?: InputMaybe<Scalars['Timestamp']['input']>;
  employeeState?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  illnessType?: InputMaybe<IncidentIllnessTypes>;
  occurredAt?: InputMaybe<Scalars['Timestamp']['input']>;
  occurrenceTimeSet?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  recordable?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  state?: InputMaybe<IncidentStates>;
  type?: InputMaybe<IncidentTypes>;
};

export type Inspection = {
  __typename?: 'Inspection';
  answers: Array<InspectionQuestionAnswer>;
  assignmentId?: Maybe<Scalars['Uuid']['output']>;
  checklist: InspectionChecklist;
  checklistId: Scalars['Uuid']['output'];
  completedOn?: Maybe<Scalars['Timestamp']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inspectedOn: Scalars['Timestamp']['output'];
  organizationId: Scalars['Uuid']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  recurringAssignmentId?: Maybe<Scalars['Uuid']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionAssignment = {
  __typename?: 'InspectionAssignment';
  checklist: InspectionChecklist;
  checklistId: Scalars['Uuid']['output'];
  circumstanceValue?: Maybe<Scalars['Int']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  date: Scalars['Timestamp']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inspection: Array<Inspection>;
  limitCategories: Scalars['Boolean']['output'];
  limitedCategories: Array<InspectionAssignmentCategoryLink>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  notificationLinks: Array<InspectionAssignmentNotificationLink>;
  notificationTime?: Maybe<Scalars['String']['output']>;
  notifyCircumstance?: Maybe<InspectionNotifyCircumstanceTypes>;
  notifySubmission: Scalars['Boolean']['output'];
  organizationId: Scalars['Uuid']['output'];
  overrideRecurringAssignmentId?: Maybe<Scalars['Uuid']['output']>;
  overrideState?: Maybe<InspectionOverrideStateTypes>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  sendNotification: Scalars['Boolean']['output'];
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionAssignmentCategoryLink = {
  __typename?: 'InspectionAssignmentCategoryLink';
  assignment: InspectionAssignment;
  assignmentId: Scalars['Uuid']['output'];
  category: InspectionCategory;
  categoryId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionAssignmentCategoryLinkCreateInput = {
  assignmentId: Scalars['Uuid']['input'];
  categoryId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type InspectionAssignmentCategoryLinkFilter = {
  assignmentId?: InputMaybe<UuidComparison>;
  categoryId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type InspectionAssignmentCategoryLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionAssignmentCategoryLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type InspectionAssignmentCreateInput = {
  checklistId: Scalars['Uuid']['input'];
  circumstanceValue?: InputMaybe<Scalars['Int']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  date: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limitCategories: Scalars['Boolean']['input'];
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  notificationTime?: InputMaybe<Scalars['String']['input']>;
  notifyCircumstance?: InputMaybe<InspectionNotifyCircumstanceTypes>;
  notifySubmission: Scalars['Boolean']['input'];
  overrideRecurringAssignmentId?: InputMaybe<Scalars['Uuid']['input']>;
  overrideState?: InputMaybe<InspectionOverrideStateTypes>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  sendNotification: Scalars['Boolean']['input'];
};

export type InspectionAssignmentFilter = {
  checklist?: InputMaybe<InspectionChecklistFilter>;
  checklistId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  date?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  inspection?: InputMaybe<InspectionFilter>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  overrideState?: InputMaybe<InspectionOverrideStateTypesComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  state?: InputMaybe<InspectionAssignmentFilterStateInput>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum InspectionAssignmentFilterState {
  Completed = 'completed',
  Scheduled = 'scheduled',
}

export type InspectionAssignmentFilterStateInput = {
  equal: InspectionAssignmentFilterState;
};

export type InspectionAssignmentNotificationLink = {
  __typename?: 'InspectionAssignmentNotificationLink';
  assignment: InspectionAssignment;
  assignmentId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionAssignmentNotificationLinkCreateInput = {
  assignmentId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
};

export type InspectionAssignmentNotificationLinkFilter = {
  assignmentId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type InspectionAssignmentNotificationLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionAssignmentNotificationLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type InspectionAssignmentSort = {
  checklist?: InputMaybe<InspectionChecklistSort>;
  createdOn?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  inspection?: InputMaybe<InspectionSort>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  submittedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionAssignmentUpdateInput = {
  checklistId?: InputMaybe<Scalars['Uuid']['input']>;
  circumstanceValue?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  limitCategories?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  notificationTime?: InputMaybe<Scalars['String']['input']>;
  notifyCircumstance?: InputMaybe<InspectionNotifyCircumstanceTypes>;
  notifySubmission?: InputMaybe<Scalars['Boolean']['input']>;
  overrideRecurringAssignmentId?: InputMaybe<Scalars['Uuid']['input']>;
  overrideState?: InputMaybe<InspectionOverrideStateTypes>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InspectionCategory = {
  __typename?: 'InspectionCategory';
  checklist: InspectionChecklist;
  checklistId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions: Array<InspectionQuestion>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionCategoryCreateInput = {
  checklistId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type InspectionCategoryFilter = {
  checklist?: InputMaybe<InspectionChecklistFilter>;
  checklistId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type InspectionCategorySort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionCategoryUpdateInput = {
  checklistId?: InputMaybe<Scalars['Uuid']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionChecklist = {
  __typename?: 'InspectionChecklist';
  categories: Array<InspectionCategory>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  limitInspectors: Scalars['Boolean']['output'];
  memberLinks: Array<InspectionChecklistMemberLink>;
  name: Scalars['String']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionChecklistCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limitInspectors: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type InspectionChecklistFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  memberLinks?: InputMaybe<InspectionChecklistMemberLinkFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type InspectionChecklistMemberLink = {
  __typename?: 'InspectionChecklistMemberLink';
  checklist: InspectionChecklist;
  checklistId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionChecklistMemberLinkCreateInput = {
  checklistId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
};

export type InspectionChecklistMemberLinkFilter = {
  checklistId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type InspectionChecklistMemberLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionChecklistMemberLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type InspectionChecklistSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionChecklistUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  limitInspectors?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionCreateInput = {
  assignmentId?: InputMaybe<Scalars['Uuid']['input']>;
  checklistId: Scalars['Uuid']['input'];
  completedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inspectedOn: Scalars['Timestamp']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  recurringAssignmentId?: InputMaybe<Scalars['Uuid']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type InspectionFilter = {
  checklist?: InputMaybe<InspectionChecklistFilter>;
  checklistId?: InputMaybe<UuidComparison>;
  completedOn?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  inspectedOn?: InputMaybe<TimestampComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  score?: InputMaybe<IntComparison>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum InspectionNotifyCircumstanceTypes {
  Always = 'always',
  LowScore = 'low_score',
}

export enum InspectionOverrideStateTypes {
  Deleted = 'deleted',
  Edited = 'edited',
}

export type InspectionOverrideStateTypesComparison = {
  contains?: InputMaybe<Array<InspectionOverrideStateTypes>>;
  doesNotContain?: InputMaybe<Array<InspectionOverrideStateTypes>>;
  equal?: InputMaybe<InspectionOverrideStateTypes>;
  equalOrNull?: InputMaybe<InspectionOverrideStateTypes>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<InspectionOverrideStateTypes>;
};

export type InspectionQuestion = {
  __typename?: 'InspectionQuestion';
  category: InspectionCategory;
  categoryId: Scalars['Uuid']['output'];
  choices?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  recommendation?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  type: InspectionQuestionType;
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionQuestionAnswer = {
  __typename?: 'InspectionQuestionAnswer';
  answer?: Maybe<Scalars['String']['output']>;
  attachments: Array<InspectionQuestionAnswerAttachment>;
  comments?: Maybe<Scalars['String']['output']>;
  corrected?: Maybe<Scalars['Boolean']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  flagged: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inspection: Inspection;
  inspectionId: Scalars['Uuid']['output'];
  inspectionQuestion: InspectionQuestion;
  question: Scalars['String']['output'];
  questionId: Scalars['Uuid']['output'];
  severity?: Maybe<InspectionQuestionSeverityType>;
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type InspectionQuestionAnswerAttachment = {
  __typename?: 'InspectionQuestionAnswerAttachment';
  answer: InspectionQuestionAnswer;
  answerId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  inspection: Inspection;
  inspectionId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  uploadedBy: Scalars['Uuid']['output'];
  uploadedByMember: Member;
};

export type InspectionQuestionAnswerAttachmentCreateInput = {
  answerId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  fileType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inspectionId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};

export type InspectionQuestionAnswerAttachmentFilter = {
  answer?: InputMaybe<InspectionQuestionAnswerFilter>;
  answerId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  inspectionId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  uploadedBy?: InputMaybe<UuidComparison>;
  uploadedByMember?: InputMaybe<MemberFilter>;
};

export type InspectionQuestionAnswerAttachmentSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  uploadedBy?: InputMaybe<SortOrder>;
  uploadedByMember?: InputMaybe<MemberSort>;
};

export type InspectionQuestionAnswerAttachmentUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionQuestionAnswerCreateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  corrected?: InputMaybe<Scalars['Boolean']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  flagged: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  inspectionId: Scalars['Uuid']['input'];
  question: Scalars['String']['input'];
  questionId: Scalars['Uuid']['input'];
  severity?: InputMaybe<InspectionQuestionSeverityType>;
};

export type InspectionQuestionAnswerFilter = {
  corrected?: InputMaybe<BooleanComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  flagged?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  inspection?: InputMaybe<InspectionFilter>;
  inspectionId?: InputMaybe<UuidComparison>;
  inspectionQuestion?: InputMaybe<InspectionQuestionFilter>;
  questionId?: InputMaybe<UuidComparison>;
  severity?: InputMaybe<InspectionQuestionSeverityTypeComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type InspectionQuestionAnswerSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  inspection?: InputMaybe<InspectionSort>;
  question?: InputMaybe<InspectionQuestionSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionQuestionAnswerUpdateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  corrected?: InputMaybe<Scalars['Boolean']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  question?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<InspectionQuestionSeverityType>;
};

export type InspectionQuestionCreateInput = {
  categoryId: Scalars['Uuid']['input'];
  choices: Scalars['String']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  enabled: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
  question: Scalars['String']['input'];
  recommendation?: InputMaybe<Scalars['String']['input']>;
  type: InspectionQuestionType;
};

export type InspectionQuestionFilter = {
  category?: InputMaybe<InspectionCategoryFilter>;
  categoryId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  enabled?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<InspectionQuestionTypeComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum InspectionQuestionSeverityType {
  Critical = 'critical',
  Low = 'low',
  Moderate = 'moderate',
  Serious = 'serious',
}

export type InspectionQuestionSeverityTypeComparison = {
  contains?: InputMaybe<Array<InspectionQuestionSeverityType>>;
  doesNotContain?: InputMaybe<Array<InspectionQuestionSeverityType>>;
  equal?: InputMaybe<InspectionQuestionSeverityType>;
  equalOrNull?: InputMaybe<InspectionQuestionSeverityType>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<InspectionQuestionSeverityType>;
};

export type InspectionQuestionSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum InspectionQuestionType {
  TrueFalse = 'true_false',
}

export type InspectionQuestionTypeComparison = {
  contains?: InputMaybe<Array<InspectionQuestionType>>;
  doesNotContain?: InputMaybe<Array<InspectionQuestionType>>;
  equal?: InputMaybe<InspectionQuestionType>;
  equalOrNull?: InputMaybe<InspectionQuestionType>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<InspectionQuestionType>;
};

export type InspectionQuestionUpdateInput = {
  categoryId?: InputMaybe<Scalars['Uuid']['input']>;
  choices?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  recommendation?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InspectionQuestionType>;
};

export type InspectionSort = {
  checklist?: InputMaybe<InspectionChecklistSort>;
  completedOn?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  inspectedOn?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  submittedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type InspectionUpdateInput = {
  assignmentId?: InputMaybe<Scalars['Uuid']['input']>;
  checklistId?: InputMaybe<Scalars['Uuid']['input']>;
  completedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inspectedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  recurringAssignmentId?: InputMaybe<Scalars['Uuid']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type IntComparison = {
  between?: InputMaybe<BetweenInt>;
  contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['Int']['input']>>;
  equal?: InputMaybe<Scalars['Int']['input']>;
  equalOrNull?: InputMaybe<Scalars['Int']['input']>;
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqual?: InputMaybe<Scalars['Int']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqual?: InputMaybe<Scalars['Int']['input']>;
  notEqual?: InputMaybe<Scalars['Int']['input']>;
};

export enum JobCostingPromptOptions {
  Off = 'off',
  On = 'on',
  Required = 'required',
}

export type LaborMetricFilter = {
  conditions?: InputMaybe<Array<LaborMetricFilterCondition>>;
  listConditions?: InputMaybe<Array<LaborMetricFilterListCondition>>;
  nullConditions?: InputMaybe<Array<LaborMetricFilterNullCondition>>;
  operationType: OperationType;
};

export type LaborMetricFilterCondition = {
  field: ConditionFieldType;
  operator: ConditionOperationType;
  value?: InputMaybe<Scalars['Variant']['input']>;
};

export type LaborMetricFilterListCondition = {
  field: ConditionFieldType;
  operator: ConditionOperationListType;
  values?: InputMaybe<Array<Scalars['Variant']['input']>>;
};

export type LaborMetricFilterNullCondition = {
  field: ConditionNullableFieldType;
  operator: ConditionOperationNullType;
};

export type LaborMetricsInterface = {
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export enum LaborMetricsInterval {
  Custom = 'custom',
  Day = 'day',
  Month = 'month',
  OvertimePeriod = 'overtimePeriod',
  PayPeriod = 'payPeriod',
  Year = 'year',
}

export type MediaCollection = {
  __typename?: 'MediaCollection';
  assignedTo?: Maybe<Scalars['Uuid']['output']>;
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mediaEntries: Array<MediaEntry>;
  organizationId: Scalars['Uuid']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  source?: Maybe<MediaCollectionSourceTypes>;
  submittedOn: Scalars['Timestamp']['output'];
  tagLinks: Array<TagMediaCollectionLink>;
  title?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type MediaCollectionCreateInput = {
  assignedTo?: InputMaybe<Scalars['Uuid']['input']>;
  createdBy?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
  projectId: Scalars['Uuid']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MediaCollectionFilter = {
  assignedTo?: InputMaybe<UuidComparison>;
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  mediaEntries?: InputMaybe<MediaEntryFilter>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithAncestors?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  tagLinks?: InputMaybe<TagMediaCollectionLinkFilter>;
  title?: InputMaybe<StringComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MediaCollectionSort = {
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  mediaEntries?: InputMaybe<MediaEntrySort>;
  project?: InputMaybe<ProjectSort>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum MediaCollectionSourceTypes {
  Busybusy = 'busybusy',
  CompanyCam = 'companyCam',
}

export type MediaCollectionUpdateInput = {
  assignedTo?: InputMaybe<Scalars['Uuid']['input']>;
  createdBy?: InputMaybe<Scalars['Uuid']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MediaEntry = {
  __typename?: 'MediaEntry';
  annotationFileUrl?: Maybe<Scalars['FileUrl']['output']>;
  captureDate: Scalars['Timestamp']['output'];
  collection: MediaCollection;
  comment?: Maybe<Scalars['String']['output']>;
  comments: Array<MediaEntryComment>;
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  entryType: Scalars['Int']['output'];
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  lastAnnotatedAt?: Maybe<Scalars['Timestamp']['output']>;
  lastAnnotatedBy?: Maybe<Scalars['Uuid']['output']>;
  lastAnnotatedByMember?: Maybe<Member>;
  location?: Maybe<MediaEntryLocation>;
  mediaCollectionId: Scalars['Uuid']['output'];
  metaData?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
  videoThumbFileUrl?: Maybe<Scalars['FileUrl']['output']>;
};

export type MediaEntryComment = {
  __typename?: 'MediaEntryComment';
  comment: Scalars['String']['output'];
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  mediaEntry: MediaEntry;
  mediaEntryId?: Maybe<Scalars['Uuid']['output']>;
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type MediaEntryCommentCreateInput = {
  comment: Scalars['String']['input'];
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mediaEntryId: Scalars['Uuid']['input'];
};

export type MediaEntryCommentFilter = {
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  mediaEntry?: InputMaybe<MediaEntryFilter>;
  mediaEntryId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MediaEntryCommentSort = {
  comment?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  mediaEntry?: InputMaybe<MediaEntrySort>;
  mediaEntryId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MediaEntryCommentUpdateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type MediaEntryCreateInput = {
  captureDate: Scalars['Timestamp']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  entryType: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  mediaCollectionId: Scalars['Uuid']['input'];
  metaData?: InputMaybe<Scalars['String']['input']>;
};

export type MediaEntryFilter = {
  collection?: InputMaybe<MediaCollectionFilter>;
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  lastAnnotatedAt?: InputMaybe<TimestampComparison>;
  lastAnnotatedBy?: InputMaybe<UuidComparison>;
  lastAnnotatedByMember?: InputMaybe<MemberFilter>;
  location?: InputMaybe<MediaEntryLocationFilter>;
  mediaCollectionId?: InputMaybe<UuidComparison>;
  metaData?: InputMaybe<Scalars['String']['input']>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MediaEntryLocation = {
  __typename?: 'MediaEntryLocation';
  altitude?: Maybe<Scalars['Float']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  entry: MediaEntry;
  horizontalAccuracy?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  mediaEntryId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeDst: Scalars['Boolean']['output'];
  timeGmt: Scalars['RetroTimestamp']['output'];
  timeLocal: Scalars['RetroTimestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  verticalAccuracy?: Maybe<Scalars['Float']['output']>;
};

export type MediaEntryLocationCreateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  horizontalAccuracy?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  mediaEntryId: Scalars['Uuid']['input'];
  timeDst: Scalars['Boolean']['input'];
  timeLocal?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  verticalAccuracy?: InputMaybe<Scalars['Float']['input']>;
};

export type MediaEntryLocationFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  entry?: InputMaybe<MediaEntryFilter>;
  id?: InputMaybe<UuidComparison>;
  latitude?: InputMaybe<FloatComparison>;
  longitude?: InputMaybe<FloatComparison>;
  mediaEntryId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeGmt?: InputMaybe<TimestampComparison>;
  timeLocal?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MediaEntryLocationSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  entry?: InputMaybe<MediaEntrySort>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeGmt?: InputMaybe<SortOrder>;
  timeLocal?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MediaEntryLocationUpdateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  horizontalAccuracy?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  mediaEntryId?: InputMaybe<Scalars['Uuid']['input']>;
  timeDst?: InputMaybe<Scalars['Boolean']['input']>;
  timeLocal?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  verticalAccuracy?: InputMaybe<Scalars['Float']['input']>;
};

export type MediaEntrySort = {
  collection?: InputMaybe<MediaCollectionSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  lastAnnotatedAt?: InputMaybe<SortOrder>;
  location?: InputMaybe<MediaEntryLocationSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MediaEntryUpdateInput = {
  captureDate?: InputMaybe<Scalars['Timestamp']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Uuid']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  entryType?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  mediaCollectionId?: InputMaybe<Scalars['Uuid']['input']>;
  metaData?: InputMaybe<Scalars['String']['input']>;
};

export type Member = {
  __typename?: 'Member';
  archivedOn?: Maybe<Scalars['Timestamp']['output']>;
  canSurvey?: Maybe<Scalars['Boolean']['output']>;
  certifiedEmail?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  distantLocationFlaggingDisabled?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerificationRequired?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gustoContractorId?: Maybe<Scalars['String']['output']>;
  gustoEmployeeId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['FileUrl']['output']>;
  isSubContractor?: Maybe<Scalars['Boolean']['output']>;
  lastAccess?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  memberCostCodeEquipmentLaborMetrics: Array<MemberCostCodeEquipmentLaborMetrics>;
  memberCostCodeLaborMetrics: Array<MemberCostCodeLaborMetrics>;
  memberEquipmentLaborMetrics: Array<MemberEquipmentLaborMetrics>;
  memberGroup?: Maybe<MemberGroup>;
  memberGroupId?: Maybe<Scalars['Uuid']['output']>;
  memberLaborMetrics: Array<MemberLaborMetrics>;
  memberLock?: Maybe<MemberLock>;
  memberNumber?: Maybe<Scalars['String']['output']>;
  memberOvertimePeriods: Array<MemberOvertimePeriod>;
  memberProjectCostCodeEquipmentLaborMetrics: Array<MemberProjectCostCodeEquipmentLaborMetrics>;
  memberProjectCostCodeLaborMetrics: Array<MemberProjectCostCodeLaborMetrics>;
  memberProjectEquipmentLaborMetrics: Array<MemberProjectEquipmentLaborMetrics>;
  memberProjectLaborMetrics: Array<MemberProjectLaborMetrics>;
  memberSettings?: Maybe<Scalars['String']['output']>;
  memberTimeDocuments: Array<MemberTimeDocument>;
  memberUsageInfo?: Maybe<MemberUsageInfo>;
  notificationPreferences?: Maybe<OrganizationNotificationPreferences>;
  organization: Organization;
  organizationId: Scalars['Uuid']['output'];
  pendingEmail?: Maybe<Scalars['String']['output']>;
  perDiems: Array<PerDiem>;
  phone?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  position: Position;
  positionId: Scalars['Uuid']['output'];
  safetySignatures: Array<SafetySignature>;
  submittedOn: Scalars['Timestamp']['output'];
  timeEntries: Array<TimeEntry>;
  timeLocationRequired?: Maybe<Scalars['String']['output']>;
  timeOffs: Array<TimeOff>;
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
  username: Scalars['String']['output'];
  usernameUnique: Scalars['Boolean']['output'];
  verifiedEmail?: Maybe<Scalars['Boolean']['output']>;
  wageHistories?: Maybe<Array<WageHistory>>;
};

export type MemberMemberCostCodeEquipmentLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type MemberMemberCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type MemberMemberEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type MemberMemberLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type MemberMemberProjectCostCodeEquipmentLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type MemberMemberProjectCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type MemberMemberProjectEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type MemberMemberProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type MemberMemberTimeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: MemberTimeDocumentFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberTimeDocumentSort>>;
};

export type MemberMemberUsageInfoArgs = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type MemberPerDiemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: PerDiemFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PerDiemSort>>;
};

export type MemberSafetySignaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: SafetySignatureFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetySignatureSort>>;
};

export type MemberTimeEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: TimeEntryFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort>>;
};

export type MemberTimeOffsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter: TimeOffFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeOffSort>>;
};

export type MemberClockState = {
  __typename?: 'MemberClockState';
  all: Scalars['Int']['output'];
  clockedIn: Scalars['Int']['output'];
  clockedInNotOnBreak: Scalars['Int']['output'];
  clockedOut: Scalars['Int']['output'];
  onBreak: Scalars['Int']['output'];
};

export type MemberClockStateFilter = {
  costCodeGroupId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentCategoryId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  memberGroupId?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  positionId?: InputMaybe<UuidComparison>;
  projectGroupId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  status?: InputMaybe<ActiveStatusComparison>;
};

export enum MemberClockStateType {
  ClockedIn = 'clockedIn',
  ClockedInNotOnBreak = 'clockedInNotOnBreak',
  ClockedOut = 'clockedOut',
  OnBreak = 'onBreak',
}

export type MemberCostCodeEquipmentLaborMetrics = EquipmentLaborMetricsInterface &
  LaborMetricsInterface & {
    __typename?: 'MemberCostCodeEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId?: Maybe<Scalars['Uuid']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    memberId: Scalars['Uuid']['output'];
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type MemberCostCodeLaborMetrics = LaborMetricsInterface & {
  __typename?: 'MemberCostCodeLaborMetrics';
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['Uuid']['output'];
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export type MemberCreateInput = {
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  canSurvey?: InputMaybe<Scalars['Boolean']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  distantLocationFlaggingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gustoContractorId?: InputMaybe<Scalars['String']['input']>;
  gustoEmployeeId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSubContractor?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  memberGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  memberSettings?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  pendingEmail?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinCode?: InputMaybe<Scalars['String']['input']>;
  positionId: Scalars['Uuid']['input'];
  timeLocationRequired?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type MemberEquipmentLaborMetrics = EquipmentLaborMetricsInterface &
  LaborMetricsInterface & {
    __typename?: 'MemberEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    memberId: Scalars['Uuid']['output'];
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type MemberFilter = {
  archivedOn?: InputMaybe<TimestampComparison>;
  canSurvey?: InputMaybe<BooleanComparison>;
  clockState?: InputMaybe<MemberFilterClockState>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  distantLocationFlaggingDisabled?: InputMaybe<BooleanComparison>;
  email?: InputMaybe<StringComparison>;
  firstName?: InputMaybe<StringComparison>;
  hasSchedule?: InputMaybe<MemberHasScheduleFilter>;
  hasTime?: InputMaybe<MemberHasTimeFilter>;
  id?: InputMaybe<UuidComparison>;
  isSubContractor?: InputMaybe<BooleanComparison>;
  lastName?: InputMaybe<StringComparison>;
  memberGroup?: InputMaybe<MemberGroupFilter>;
  memberGroupId?: InputMaybe<UuidComparison>;
  memberLock?: InputMaybe<MemberLockFilter>;
  memberNumber?: InputMaybe<StringComparison>;
  memberOvertimePeriods?: InputMaybe<MemberOvertimePeriodFilter>;
  permissions?: InputMaybe<MemberPermissionFilter>;
  position?: InputMaybe<PositionFilter>;
  positionId?: InputMaybe<UuidComparison>;
  search?: InputMaybe<MemberSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeOffs?: InputMaybe<TimeOffFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
  username?: InputMaybe<StringComparison>;
  usernameUnique?: InputMaybe<BooleanComparison>;
};

export type MemberFilterClockState = {
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  state: MemberClockStateType;
};

export type MemberGpsStatus = {
  __typename?: 'MemberGpsStatus';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  localTime: Scalars['Timestamp']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  organizationId: Scalars['Uuid']['output'];
  status: MemberGpsStatusTypes;
  submittedOn: Scalars['Timestamp']['output'];
  timeEntry: TimeEntry;
  timeEntryId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type MemberGpsStatusCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  localTime: Scalars['Timestamp']['input'];
  status: MemberGpsStatusTypes;
  timeEntryId: Scalars['Uuid']['input'];
};

export type MemberGpsStatusFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  localTime?: InputMaybe<TimestampComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  status?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeEntry?: InputMaybe<TimeEntryFilter>;
  timeEntryId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberGpsStatusSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  localTime?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  memberId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeEntry?: InputMaybe<TimeEntrySort>;
  timeEntryId?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum MemberGpsStatusTypes {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export type MemberGpsStatusUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  status: MemberGpsStatusTypes;
};

export type MemberGroup = {
  __typename?: 'MemberGroup';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type MemberGroupCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  groupName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
};

export type MemberGroupFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  groupName?: InputMaybe<StringComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberGroupSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  groupName?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberGroupUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MemberHasScheduleFilter = {
  costCodeId?: InputMaybe<UuidComparison>;
  endTime?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type MemberHasTimeFilter = {
  costCodeId?: InputMaybe<UuidComparison>;
  endTime: Scalars['Timestamp']['input'];
  equipmentId?: InputMaybe<UuidComparison>;
  hasTimeOff?: InputMaybe<MemberHasTimeOff>;
  includeOpenEntry: Scalars['Boolean']['input'];
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  startTime: Scalars['Timestamp']['input'];
};

export enum MemberHasTimeOff {
  All = 'all',
  Paid = 'paid',
  Unpaid = 'unpaid',
}

export type MemberLaborMetrics = LaborMetricsInterface & {
  __typename?: 'MemberLaborMetrics';
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  memberId: Scalars['Uuid']['output'];
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  pto?: Maybe<Scalars['Long']['output']>;
  ptoCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
  upto?: Maybe<Scalars['Long']['output']>;
};

export type MemberLocation = {
  __typename?: 'MemberLocation';
  altitude?: Maybe<Scalars['Float']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  horizontalAccuracy?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeDst: Scalars['Boolean']['output'];
  timeGmt: Scalars['RetroTimestamp']['output'];
  timeLocal: Scalars['RetroTimestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  verticalAccuracy?: Maybe<Scalars['Float']['output']>;
};

export type MemberLocationCreateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  horizontalAccuracy?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  memberId: Scalars['Uuid']['input'];
  timeDst: Scalars['Boolean']['input'];
  timeGmt?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  timeLocal?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  verticalAccuracy?: InputMaybe<Scalars['Float']['input']>;
};

export type MemberLocationFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  latitude?: InputMaybe<FloatComparison>;
  longitude?: InputMaybe<FloatComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeGmt?: InputMaybe<TimestampComparison>;
  timeLocal?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberLocationSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeGmt?: InputMaybe<SortOrder>;
  timeLocal?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberLocationUpdateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  horizontalAccuracy?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  timeDst?: InputMaybe<Scalars['Boolean']['input']>;
  timeGmt?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  timeLocal?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  verticalAccuracy?: InputMaybe<Scalars['Float']['input']>;
};

export type MemberLock = {
  __typename?: 'MemberLock';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  effectiveDate?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  updaterMember: Member;
  updaterMemberId: Scalars['Uuid']['output'];
};

export type MemberLockCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  effectiveDate: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
};

export type MemberLockFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  effectiveDate?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  updaterMember?: InputMaybe<MemberFilter>;
  updaterMemberId?: InputMaybe<UuidComparison>;
};

export type MemberLockSort = {
  createdOn?: InputMaybe<SortOrder>;
  effectiveDate?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  updaterMember?: InputMaybe<MemberSort>;
};

export type MemberLockUpdateInput = {
  effectiveDate?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type MemberOvertimePeriod = {
  __typename?: 'MemberOvertimePeriod';
  adhereToOrg: Scalars['Boolean']['output'];
  changeDate: Scalars['Timestamp']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  dailyDoubletime?: Maybe<Scalars['Int']['output']>;
  dailyOvertime?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  saturdayMultiplier?: Maybe<Scalars['Float']['output']>;
  seventhDayDoubletime?: Maybe<Scalars['Int']['output']>;
  seventhDayOvertime?: Maybe<Scalars['Boolean']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  sundayMultiplier?: Maybe<Scalars['Float']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  weeklyOvertime?: Maybe<Scalars['Int']['output']>;
};

export type MemberOvertimePeriodCreateInput = {
  adhereToOrg: Scalars['Boolean']['input'];
  changeDate: Scalars['Timestamp']['input'];
  createdOn: Scalars['Timestamp']['input'];
  dailyDoubletime?: InputMaybe<Scalars['Int']['input']>;
  dailyOvertime?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  saturdayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  seventhDayDoubletime?: InputMaybe<Scalars['Int']['input']>;
  seventhDayOvertime?: InputMaybe<Scalars['Boolean']['input']>;
  sundayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  weeklyOvertime?: InputMaybe<Scalars['Int']['input']>;
};

export type MemberOvertimePeriodFilter = {
  changeDate?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberOvertimePeriodSort = {
  changeDate?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberOvertimePeriodUpdateInput = {
  adhereToOrg: Scalars['Boolean']['input'];
  changeDate: Scalars['Timestamp']['input'];
  dailyDoubletime?: InputMaybe<Scalars['Int']['input']>;
  dailyOvertime?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  saturdayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  seventhDayDoubletime?: InputMaybe<Scalars['Int']['input']>;
  seventhDayOvertime?: InputMaybe<Scalars['Boolean']['input']>;
  sundayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  weeklyOvertime?: InputMaybe<Scalars['Int']['input']>;
};

export type MemberPermissionFilter = {
  /** Set to true to never filter out the logged in user in the result. Set to false to always filter out the logged in user. Leave as null for default behavior. */
  includeSelf?: InputMaybe<Scalars['Boolean']['input']>;
  operationType: OperationType;
  permissions: Array<MemberPermissions>;
};

export enum MemberPermissions {
  CanManageProjectCostCodeQuantity = 'canManageProjectCostCodeQuantity',
  ManageEmployees = 'manageEmployees',
  ManageGpsApproval = 'manageGpsApproval',
  ManageMediaCollection = 'manageMediaCollection',
  ManagePerDiem = 'managePerDiem',
  ManageSchedule = 'manageSchedule',
  ManageTimeEntries = 'manageTimeEntries',
  ManageTimeOff = 'manageTimeOff',
  MemberLock = 'memberLock',
  RequestSignatures = 'requestSignatures',
  TimeEvents = 'timeEvents',
  ViewMediaCollection = 'viewMediaCollection',
  ViewMemberActivityReport = 'viewMemberActivityReport',
  ViewSchedule = 'viewSchedule',
}

export type MemberProjectCostCodeEquipmentLaborMetrics = EquipmentProjectLaborMetricsInterface &
  LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId?: Maybe<Scalars['Uuid']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descEquipmentCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    memberId: Scalars['Uuid']['output'];
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type MemberProjectCostCodeLaborMetrics = LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'MemberProjectCostCodeLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId?: Maybe<Scalars['Uuid']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    id?: Maybe<Scalars['String']['output']>;
    memberId: Scalars['Uuid']['output'];
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type MemberProjectEquipmentLaborMetrics = EquipmentProjectLaborMetricsInterface &
  LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'MemberProjectEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descEquipmentCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    memberId: Scalars['Uuid']['output'];
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type MemberProjectLaborMetrics = LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'MemberProjectLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    id?: Maybe<Scalars['String']['output']>;
    memberId: Scalars['Uuid']['output'];
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type MemberSearch = {
  fields: Array<MemberSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum MemberSearchFields {
  FirstName = 'firstName',
  LastName = 'lastName',
}

export type MemberSignInAnswer = {
  __typename?: 'MemberSignInAnswer';
  answer?: Maybe<Scalars['String']['output']>;
  audienceType?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  flagged: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  localTime: Scalars['Timestamp']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  memberSignInSubmissionId: Scalars['Uuid']['output'];
  positionId?: Maybe<Scalars['Uuid']['output']>;
  questionDescription?: Maybe<Scalars['String']['output']>;
  questionPrompt?: Maybe<Scalars['String']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  resolvedComment?: Maybe<Scalars['String']['output']>;
  resolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  resolverId?: Maybe<Scalars['Uuid']['output']>;
  resolverMember?: Maybe<Member>;
  restricted?: Maybe<Scalars['Boolean']['output']>;
  submission?: Maybe<MemberSignInSubmission>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type MemberSignInAnswerCreateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  audienceType: Scalars['String']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  flagged: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  localTime: Scalars['Timestamp']['input'];
  memberId: Scalars['Uuid']['input'];
  memberSignInSubmissionId: Scalars['Uuid']['input'];
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  questionDescription?: InputMaybe<Scalars['String']['input']>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  resolvedComment?: InputMaybe<Scalars['String']['input']>;
  resolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  resolverId?: InputMaybe<Scalars['Uuid']['input']>;
  restricted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MemberSignInAnswerFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  flagged?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  localTime?: InputMaybe<TimestampComparison>;
  memberId?: InputMaybe<UuidComparison>;
  memberSignInSubmissionId?: InputMaybe<UuidComparison>;
  resolvedOn?: InputMaybe<TimestampComparison>;
  resolverId?: InputMaybe<UuidComparison>;
  restricted?: InputMaybe<BooleanComparison>;
  submission?: InputMaybe<MemberSignInSubmissionFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberSignInAnswerSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  flagged?: InputMaybe<SortOrder>;
  localTime?: InputMaybe<SortOrder>;
  submission?: InputMaybe<MemberSignInSubmissionSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberSignInAnswerUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  resolvedComment?: InputMaybe<Scalars['String']['input']>;
  resolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  resolverId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type MemberSignInSubmission = {
  __typename?: 'MemberSignInSubmission';
  answers: Array<MemberSignInAnswer>;
  createdOn: Scalars['Timestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  createdOnOffset: Scalars['Int']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  flagged: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  localTime: Scalars['Timestamp']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  proxyMember: Member;
  proxyMemberId: Scalars['Uuid']['output'];
  resolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  resolverId?: Maybe<Scalars['Uuid']['output']>;
  restricted?: Maybe<Scalars['Boolean']['output']>;
  signatureId?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['FileUrl']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type MemberSignInSubmissionCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  createdOnDst: Scalars['Boolean']['input'];
  createdOnOffset: Scalars['Int']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  flagged: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  localTime: Scalars['Timestamp']['input'];
  memberId: Scalars['Uuid']['input'];
  proxyMemberId: Scalars['Uuid']['input'];
  resolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  resolverId?: InputMaybe<Scalars['Uuid']['input']>;
  restricted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MemberSignInSubmissionFilter = {
  answers?: InputMaybe<MemberSignInAnswerFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  flagged?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  localTime?: InputMaybe<TimestampComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  proxyMember?: InputMaybe<MemberFilter>;
  proxyMemberId?: InputMaybe<UuidComparison>;
  resolvedOn?: InputMaybe<TimestampComparison>;
  resolverId?: InputMaybe<UuidComparison>;
  restricted?: InputMaybe<BooleanComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberSignInSubmissionSort = {
  answers?: InputMaybe<MemberSignInAnswerSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  flagged?: InputMaybe<SortOrder>;
  localTime?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  proxyMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberSignInSubmissionUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  resolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  resolverId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type MemberSignOffAnswer = {
  __typename?: 'MemberSignOffAnswer';
  answer?: Maybe<Scalars['String']['output']>;
  audienceType: OrganizationSignOffQuestionAudience;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  flagged: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  localTime: Scalars['Timestamp']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  positionId?: Maybe<Scalars['Uuid']['output']>;
  questionDescription?: Maybe<Scalars['String']['output']>;
  questionPrompt?: Maybe<Scalars['String']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
  resolvedComment?: Maybe<Scalars['String']['output']>;
  resolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  resolverId?: Maybe<Scalars['Uuid']['output']>;
  resolverMember?: Maybe<Member>;
  submission?: Maybe<SafetySignature>;
  submissionId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type MemberSignOffAnswerCreateInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  audienceType: OrganizationSignOffQuestionAudience;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  flagged: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  localTime: Scalars['Timestamp']['input'];
  memberId: Scalars['Uuid']['input'];
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  questionDescription?: InputMaybe<Scalars['String']['input']>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  resolvedComment?: InputMaybe<Scalars['String']['input']>;
  resolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  resolverId?: InputMaybe<Scalars['Uuid']['input']>;
  submissionId: Scalars['Uuid']['input'];
};

export type MemberSignOffAnswerFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  flagged?: InputMaybe<BooleanComparison>;
  id?: InputMaybe<UuidComparison>;
  localTime?: InputMaybe<TimestampComparison>;
  memberId?: InputMaybe<UuidComparison>;
  resolvedOn?: InputMaybe<TimestampComparison>;
  resolverId?: InputMaybe<UuidComparison>;
  submission?: InputMaybe<SafetySignatureFilter>;
  submissionId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberSignOffAnswerSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  flagged?: InputMaybe<SortOrder>;
  localTime?: InputMaybe<SortOrder>;
  submission?: InputMaybe<SafetySignatureSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberSignOffAnswerUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  resolvedComment?: InputMaybe<Scalars['String']['input']>;
  resolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  resolverId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type MemberSort = {
  archivedOn?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  distantLocationFlaggingDisabled?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  isSubContractor?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  memberGroup?: InputMaybe<MemberGroupSort>;
  memberLock?: InputMaybe<MemberLockSort>;
  memberNumber?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  pinCode?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  submittedOn?: InputMaybe<SortOrder>;
  timeLocationRequired?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  wageHistories?: InputMaybe<WageHistorySort>;
};

export type MemberTimeDocument = {
  __typename?: 'MemberTimeDocument';
  authoritativeSignature?: Maybe<AuthoritativeSignature>;
  authoritativeSignatureId?: Maybe<Scalars['String']['output']>;
  canceled?: Maybe<Scalars['Timestamp']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  doubletimeSeconds: Scalars['Int']['output'];
  edited?: Maybe<Scalars['Timestamp']['output']>;
  endTime: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  overtimeSeconds: Scalars['Int']['output'];
  regularSeconds: Scalars['Int']['output'];
  selfSignature?: Maybe<SelfSignature>;
  selfSignatureId?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['Timestamp']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type MemberTimeDocumentCreateInput = {
  canceled?: InputMaybe<Scalars['Timestamp']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  startTime: Scalars['Timestamp']['input'];
};

export type MemberTimeDocumentFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type MemberTimeDocumentSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type MemberTimeDocumentUpdateInput = {
  authoritativeSignatureId?: InputMaybe<Scalars['Uuid']['input']>;
  canceled?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  endTime?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  selfSignatureId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type MemberUpdateInput = {
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  canSurvey?: InputMaybe<Scalars['Boolean']['input']>;
  distantLocationFlaggingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gustoContractorId?: InputMaybe<Scalars['String']['input']>;
  gustoEmployeeId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isSubContractor?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  memberGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  memberNumber?: InputMaybe<Scalars['String']['input']>;
  memberSettings?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pendingEmail?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pinCode?: InputMaybe<Scalars['String']['input']>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  timeLocationRequired?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MemberUsageInfo = {
  __typename?: 'MemberUsageInfo';
  firstApiAccess?: Maybe<Scalars['DateTime']['output']>;
  firstTimeTracking?: Maybe<Scalars['DateTime']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAuthoritativeSignature?: Maybe<AuthoritativeSignature>;
  createBudgetCost?: Maybe<BudgetCost>;
  createBudgetHours?: Maybe<BudgetHours>;
  createClientTimeEntryBreakLog?: Maybe<ClientTimeEntryBreakLog>;
  createClientTimeEntryBreakLogs?: Maybe<Array<ClientTimeEntryBreakLog>>;
  createClientTimeEntryLog?: Maybe<ClientTimeEntryLog>;
  createClientTimeEntryLogs?: Maybe<Array<ClientTimeEntryLog>>;
  createCostCode?: Maybe<CostCode>;
  createCostCodeGroup?: Maybe<CostCodeGroup>;
  createDailyProjectReport?: Maybe<DailyProjectReport>;
  createDailyProjectReportTemplate?: Maybe<DailyProjectReportTemplate>;
  createEquipment?: Maybe<Equipment>;
  createEquipmentCategory?: Maybe<EquipmentCategory>;
  createEquipmentCostHistory?: Maybe<EquipmentCostHistory>;
  createEquipmentHours?: Maybe<EquipmentHours>;
  createEquipmentMake?: Maybe<EquipmentMake>;
  createEquipmentModel?: Maybe<EquipmentModel>;
  createEquipmentType?: Maybe<EquipmentType>;
  createFlaggedTimeEntryLocation?: Maybe<FlaggedTimeEntryLocation>;
  createFolder?: Maybe<Folder>;
  createIncident?: Maybe<Incident>;
  createIncidentAnswer?: Maybe<IncidentAnswer>;
  createIncidentMedicalDetails?: Maybe<IncidentMedicalDetails>;
  createIncidentOutcomeDetails?: Maybe<IncidentOutcomeDetails>;
  createInspection?: Maybe<Inspection>;
  createInspectionAssignment?: Maybe<InspectionAssignment>;
  createInspectionAssignmentCategoryLink?: Maybe<InspectionAssignmentCategoryLink>;
  createInspectionAssignmentNotificationLink?: Maybe<InspectionAssignmentNotificationLink>;
  createInspectionCategory?: Maybe<InspectionCategory>;
  createInspectionChecklist?: Maybe<InspectionChecklist>;
  createInspectionChecklistMemberLink?: Maybe<InspectionChecklistMemberLink>;
  createInspectionQuestion?: Maybe<InspectionQuestion>;
  createInspectionQuestionAnswer?: Maybe<InspectionQuestionAnswer>;
  createInspectionQuestionAnswerAttachment?: Maybe<InspectionQuestionAnswerAttachment>;
  createInspectionQuestions?: Maybe<Array<InspectionQuestion>>;
  createMediaCollection?: Maybe<MediaCollection>;
  createMediaEntry?: Maybe<MediaEntry>;
  createMediaEntryComment?: Maybe<MediaEntryComment>;
  createMediaEntryLocation?: Maybe<MediaEntryLocation>;
  createMember?: Maybe<Member>;
  createMemberGpsStatus?: Maybe<MemberGpsStatus>;
  createMemberGroup?: Maybe<MemberGroup>;
  createMemberLocation?: Maybe<MemberLocation>;
  createMemberLock?: Maybe<MemberLock>;
  createMemberOvertimePeriod?: Maybe<MemberOvertimePeriod>;
  createMemberSignInAnswer?: Maybe<MemberSignInAnswer>;
  createMemberSignInSubmission?: Maybe<MemberSignInSubmission>;
  createMemberSignOffAnswer?: Maybe<MemberSignOffAnswer>;
  createMemberTimeDocument?: Maybe<MemberTimeDocument>;
  createObservation?: Maybe<Observation>;
  createObservationAnswer?: Maybe<ObservationAnswer>;
  createObservationAttachment?: Maybe<ObservationAttachment>;
  createObservationCategory?: Maybe<ObservationCategory>;
  createOrganizationDocument?: Maybe<OrganizationDocument>;
  createOrganizationNotificationPreferences?: Maybe<OrganizationNotificationPreferences>;
  createOrganizationOvertimePeriod?: Maybe<OrganizationOvertimePeriod>;
  createOrganizationPayPeriod?: Maybe<OrganizationPayPeriod>;
  createOrganizationSignInQuestion?: Maybe<OrganizationSignInQuestion>;
  createOrganizationSignOffQuestion?: Maybe<OrganizationSignOffQuestion>;
  createPerDiem?: Maybe<PerDiem>;
  createPolicyMemberAcceptance?: Maybe<PolicyMemberAcceptance>;
  createPosition?: Maybe<Position>;
  createPost?: Maybe<Post>;
  createPostAttachment?: Maybe<PostAttachment>;
  createPostComment?: Maybe<PostComment>;
  createPostRecipient?: Maybe<PostRecipient>;
  createPostViewer?: Maybe<PostViewer>;
  createProductRequest?: Maybe<Scalars['String']['output']>;
  createProgressBudget?: Maybe<ProgressBudget>;
  createProject?: Maybe<Project>;
  /** @deprecated replace with createBudgetCost() */
  createProjectCostBudget?: Maybe<ProjectCostBudget>;
  createProjectCostCodeLink?: Maybe<ProjectCostCodeLink>;
  createProjectCostCodeQuantity?: Maybe<ProjectCostCodeQuantity>;
  createProjectGroup?: Maybe<ProjectGroup>;
  /** @deprecated replace with createBudgetHours() */
  createProjectHoursBudget?: Maybe<ProjectHoursBudget>;
  createProjectInfo?: Maybe<ProjectInfo>;
  createRecurringInspectionAssignment?: Maybe<RecurringInspectionAssignment>;
  createRecurringInspectionAssignmentCategoryLink?: Maybe<RecurringInspectionAssignmentCategoryLink>;
  createRecurringInspectionAssignmentNotificationLink?: Maybe<RecurringInspectionAssignmentNotificationLink>;
  createRecurringScheduleReminder?: Maybe<RecurringScheduleReminder>;
  createRecurringScheduleReminderLink?: Maybe<RecurringScheduleReminderLink>;
  createSafetyReportsCategory?: Maybe<SafetyReportsCategory>;
  createSafetyReportsSource?: Maybe<SafetyReportsSource>;
  createSafetyReportsTrainingMaterial?: Maybe<SafetyReportsTrainingMaterial>;
  createSafetySignature?: Maybe<SafetySignature>;
  createSafetyTraining?: Maybe<SafetyTraining>;
  createSafetyTrainingAttachment?: Maybe<SafetyTrainingAttachment>;
  createSafetyTrainingAttendee?: Maybe<SafetyTrainingAttendee>;
  createScheduleReminder?: Maybe<ScheduleReminder>;
  createScheduleReminderLink?: Maybe<ScheduleReminderLink>;
  createSelfSignature?: Maybe<SelfSignature>;
  createTag?: Maybe<Tag>;
  createTagDocumentLink?: Maybe<TagDocumentLink>;
  createTagMediaCollectionLink?: Maybe<TagMediaCollectionLink>;
  createTimeEntries?: Maybe<Array<TimeEntry>>;
  createTimeEntry?: Maybe<TimeEntry>;
  createTimeEntryBreak?: Maybe<TimeEntryBreak>;
  createTimeEntryBreaks?: Maybe<Array<TimeEntryBreak>>;
  createTimeOff?: Maybe<TimeOff>;
  createTimeOffRequest?: Maybe<TimeOffRequest>;
  createTimeOffRequests?: Maybe<Array<TimeOffRequest>>;
  createTimeOffs?: Maybe<Array<TimeOff>>;
  createWageHistory?: Maybe<WageHistory>;
  updateAuthoritativeSignature?: Maybe<AuthoritativeSignature>;
  updateCostCode?: Maybe<CostCode>;
  updateCostCodeGroup?: Maybe<CostCodeGroup>;
  updateDailyProjectReport?: Maybe<DailyProjectReport>;
  updateDailyProjectReportTemplate?: Maybe<DailyProjectReportTemplate>;
  updateEquipment?: Maybe<Equipment>;
  updateEquipmentCategory?: Maybe<EquipmentCategory>;
  updateEquipmentCostHistory?: Maybe<EquipmentCostHistory>;
  updateEquipmentMake?: Maybe<EquipmentMake>;
  updateEquipmentModel?: Maybe<EquipmentModel>;
  updateEquipmentType?: Maybe<EquipmentType>;
  updateFlaggedTimeEntryLocation?: Maybe<FlaggedTimeEntryLocation>;
  updateFolder?: Maybe<Folder>;
  updateIncident?: Maybe<Incident>;
  updateIncidentAnswer?: Maybe<IncidentAnswer>;
  updateIncidentMedicalDetails?: Maybe<IncidentMedicalDetails>;
  updateIncidentOutcomeDetails?: Maybe<IncidentOutcomeDetails>;
  updateInspection?: Maybe<Inspection>;
  updateInspectionAssignment?: Maybe<InspectionAssignment>;
  updateInspectionAssignmentCategoryLink?: Maybe<InspectionAssignmentCategoryLink>;
  updateInspectionAssignmentNotificationLink?: Maybe<InspectionAssignmentNotificationLink>;
  updateInspectionCategory?: Maybe<InspectionCategory>;
  updateInspectionChecklist?: Maybe<InspectionChecklist>;
  updateInspectionChecklistMemberLink?: Maybe<InspectionChecklistMemberLink>;
  updateInspectionQuestion?: Maybe<InspectionQuestion>;
  updateInspectionQuestionAnswer?: Maybe<InspectionQuestionAnswer>;
  updateInspectionQuestionAnswerAttachment?: Maybe<InspectionQuestionAnswerAttachment>;
  updateMediaCollection?: Maybe<MediaCollection>;
  updateMediaEntry?: Maybe<MediaEntry>;
  updateMediaEntryComment?: Maybe<MediaEntryComment>;
  updateMediaEntryLocation?: Maybe<MediaEntryLocation>;
  updateMember?: Maybe<Member>;
  updateMemberGpsStatus?: Maybe<MemberGpsStatus>;
  updateMemberGroup?: Maybe<MemberGroup>;
  updateMemberLocation?: Maybe<MemberLocation>;
  updateMemberLock?: Maybe<MemberLock>;
  updateMemberOvertimePeriod?: Maybe<MemberOvertimePeriod>;
  updateMemberSignInAnswer?: Maybe<MemberSignInAnswer>;
  updateMemberSignInSubmission?: Maybe<MemberSignInSubmission>;
  updateMemberSignOffAnswer?: Maybe<MemberSignOffAnswer>;
  updateMemberTimeDocument?: Maybe<MemberTimeDocument>;
  updateObservation?: Maybe<Observation>;
  updateObservationAnswer?: Maybe<ObservationAnswer>;
  updateObservationAttachment?: Maybe<ObservationAttachment>;
  updateObservationCategory?: Maybe<ObservationCategory>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationDocument?: Maybe<OrganizationDocument>;
  updateOrganizationInfo?: Maybe<OrganizationInfo>;
  updateOrganizationNotificationPreferences?: Maybe<OrganizationNotificationPreferences>;
  updateOrganizationOvertimePeriod?: Maybe<OrganizationOvertimePeriod>;
  updateOrganizationPayPeriod?: Maybe<OrganizationPayPeriod>;
  updateOrganizationSignInQuestion?: Maybe<OrganizationSignInQuestion>;
  updateOrganizationSignOffQuestion?: Maybe<OrganizationSignOffQuestion>;
  updatePerDiem?: Maybe<PerDiem>;
  updatePosition?: Maybe<Position>;
  updatePost?: Maybe<Post>;
  updatePostAttachment?: Maybe<PostAttachment>;
  updatePostComment?: Maybe<PostComment>;
  updatePostRecipient?: Maybe<PostRecipient>;
  updatePostViewer?: Maybe<PostViewer>;
  updateProgressBudget?: Maybe<ProgressBudget>;
  updateProject?: Maybe<Project>;
  updateProjectCostCodeLink?: Maybe<ProjectCostCodeLink>;
  updateProjectCostCodeQuantity?: Maybe<ProjectCostCodeQuantity>;
  updateProjectGroup?: Maybe<ProjectGroup>;
  updateProjectInfo?: Maybe<ProjectInfo>;
  updateRecurringInspectionAssignment?: Maybe<RecurringInspectionAssignment>;
  updateRecurringInspectionAssignmentCategoryLink?: Maybe<RecurringInspectionAssignmentCategoryLink>;
  updateRecurringInspectionAssignmentNotificationLink?: Maybe<RecurringInspectionAssignmentNotificationLink>;
  updateRecurringScheduleReminder?: Maybe<RecurringScheduleReminder>;
  updateRecurringScheduleReminderLink?: Maybe<RecurringScheduleReminderLink>;
  updateSafetyReportsCategory?: Maybe<SafetyReportsCategory>;
  updateSafetyReportsSource?: Maybe<SafetyReportsSource>;
  updateSafetyReportsTrainingMaterial?: Maybe<SafetyReportsTrainingMaterial>;
  updateSafetySignature?: Maybe<SafetySignature>;
  updateSafetyTraining?: Maybe<SafetyTraining>;
  updateSafetyTrainingAttachment?: Maybe<SafetyTrainingAttachment>;
  updateSafetyTrainingAttendee?: Maybe<SafetyTrainingAttendee>;
  updateScheduleReminder?: Maybe<ScheduleReminder>;
  updateScheduleReminderLink?: Maybe<ScheduleReminderLink>;
  updateSelfSignature?: Maybe<SelfSignature>;
  updateTag?: Maybe<Tag>;
  updateTagDocumentLink?: Maybe<TagDocumentLink>;
  updateTagMediaCollectionLink?: Maybe<TagMediaCollectionLink>;
  updateTimeEntries?: Maybe<Array<TimeEntry>>;
  updateTimeEntry?: Maybe<TimeEntry>;
  updateTimeEntryBreak?: Maybe<TimeEntryBreak>;
  updateTimeEntryBreaks?: Maybe<Array<TimeEntryBreak>>;
  updateTimeOff?: Maybe<TimeOff>;
  updateTimeOffRequest?: Maybe<TimeOffRequest>;
  updateTimeOffRequests?: Maybe<Array<TimeOffRequest>>;
  updateTimeOffs?: Maybe<Array<TimeOff>>;
  updateWageHistory?: Maybe<WageHistory>;
};

export type MutationCreateAuthoritativeSignatureArgs = {
  authoritativeSignature?: InputMaybe<AuthoritativeSignatureCreateInput>;
};

export type MutationCreateBudgetCostArgs = {
  budgetCost?: InputMaybe<BudgetCostCreateInput>;
};

export type MutationCreateBudgetHoursArgs = {
  budgetHours?: InputMaybe<BudgetHoursCreateInput>;
};

export type MutationCreateClientTimeEntryBreakLogArgs = {
  clientTimeEntryBreakLog?: InputMaybe<ClientTimeEntryBreakLogCreateInput>;
};

export type MutationCreateClientTimeEntryBreakLogsArgs = {
  clientTimeEntryBreakLogs: Array<ClientTimeEntryBreakLogCreateInput>;
};

export type MutationCreateClientTimeEntryLogArgs = {
  clientTimeEntryLog?: InputMaybe<ClientTimeEntryLogCreateInput>;
};

export type MutationCreateClientTimeEntryLogsArgs = {
  clientTimeEntryLogs: Array<ClientTimeEntryLogCreateInput>;
};

export type MutationCreateCostCodeArgs = {
  costCode?: InputMaybe<CostCodeCreateInput>;
};

export type MutationCreateCostCodeGroupArgs = {
  costCodeGroup?: InputMaybe<CostCodeGroupCreateInput>;
};

export type MutationCreateDailyProjectReportArgs = {
  dailyProjectReport?: InputMaybe<DailyProjectReportCreateInput>;
};

export type MutationCreateDailyProjectReportTemplateArgs = {
  dailyProjectReportTemplate?: InputMaybe<DailyProjectReportTemplateCreateInput>;
};

export type MutationCreateEquipmentArgs = {
  equipment?: InputMaybe<EquipmentCreateInput>;
};

export type MutationCreateEquipmentCategoryArgs = {
  equipmentCategory?: InputMaybe<EquipmentCategoryCreateInput>;
};

export type MutationCreateEquipmentCostHistoryArgs = {
  equipmentCostHistory?: InputMaybe<EquipmentCostHistoryCreateInput>;
};

export type MutationCreateEquipmentHoursArgs = {
  equipmentHours?: InputMaybe<EquipmentHoursCreateInput>;
};

export type MutationCreateEquipmentMakeArgs = {
  equipmentMake?: InputMaybe<EquipmentMakeCreateInput>;
};

export type MutationCreateEquipmentModelArgs = {
  equipmentModel?: InputMaybe<EquipmentModelCreateInput>;
};

export type MutationCreateEquipmentTypeArgs = {
  equipmentType?: InputMaybe<EquipmentTypeCreateInput>;
};

export type MutationCreateFlaggedTimeEntryLocationArgs = {
  flaggedTimeEntryLocation?: InputMaybe<FlaggedTimeEntryLocationCreateInput>;
};

export type MutationCreateFolderArgs = {
  folder?: InputMaybe<FolderCreateInput>;
};

export type MutationCreateIncidentArgs = {
  incident?: InputMaybe<IncidentCreateInput>;
};

export type MutationCreateIncidentAnswerArgs = {
  incidentAnswer?: InputMaybe<IncidentAnswerCreateInput>;
};

export type MutationCreateIncidentMedicalDetailsArgs = {
  incidentMedicalDetails?: InputMaybe<IncidentMedicalDetailsCreateInput>;
};

export type MutationCreateIncidentOutcomeDetailsArgs = {
  incidentOutcomeDetails?: InputMaybe<IncidentOutcomeDetailsCreateInput>;
};

export type MutationCreateInspectionArgs = {
  inspection?: InputMaybe<InspectionCreateInput>;
};

export type MutationCreateInspectionAssignmentArgs = {
  assignment?: InputMaybe<InspectionAssignmentCreateInput>;
};

export type MutationCreateInspectionAssignmentCategoryLinkArgs = {
  assignmentCategoryLink?: InputMaybe<InspectionAssignmentCategoryLinkCreateInput>;
};

export type MutationCreateInspectionAssignmentNotificationLinkArgs = {
  assignmentNotificationLink?: InputMaybe<InspectionAssignmentNotificationLinkCreateInput>;
};

export type MutationCreateInspectionCategoryArgs = {
  category?: InputMaybe<InspectionCategoryCreateInput>;
};

export type MutationCreateInspectionChecklistArgs = {
  checklist?: InputMaybe<InspectionChecklistCreateInput>;
};

export type MutationCreateInspectionChecklistMemberLinkArgs = {
  checklistMemberLink?: InputMaybe<InspectionChecklistMemberLinkCreateInput>;
};

export type MutationCreateInspectionQuestionArgs = {
  question?: InputMaybe<InspectionQuestionCreateInput>;
};

export type MutationCreateInspectionQuestionAnswerArgs = {
  answer?: InputMaybe<InspectionQuestionAnswerCreateInput>;
};

export type MutationCreateInspectionQuestionAnswerAttachmentArgs = {
  questionAnswerAttachment?: InputMaybe<InspectionQuestionAnswerAttachmentCreateInput>;
};

export type MutationCreateInspectionQuestionsArgs = {
  questions: Array<InspectionQuestionCreateInput>;
};

export type MutationCreateMediaCollectionArgs = {
  mediaCollection?: InputMaybe<MediaCollectionCreateInput>;
};

export type MutationCreateMediaEntryArgs = {
  mediaEntry?: InputMaybe<MediaEntryCreateInput>;
};

export type MutationCreateMediaEntryCommentArgs = {
  mediaEntryComment?: InputMaybe<MediaEntryCommentCreateInput>;
};

export type MutationCreateMediaEntryLocationArgs = {
  mediaEntryLocation?: InputMaybe<MediaEntryLocationCreateInput>;
};

export type MutationCreateMemberArgs = {
  member?: InputMaybe<MemberCreateInput>;
};

export type MutationCreateMemberGpsStatusArgs = {
  memberGpsStatus?: InputMaybe<MemberGpsStatusCreateInput>;
};

export type MutationCreateMemberGroupArgs = {
  memberGroup?: InputMaybe<MemberGroupCreateInput>;
};

export type MutationCreateMemberLocationArgs = {
  memberLocation?: InputMaybe<MemberLocationCreateInput>;
};

export type MutationCreateMemberLockArgs = {
  memberLock?: InputMaybe<MemberLockCreateInput>;
};

export type MutationCreateMemberOvertimePeriodArgs = {
  memberOvertimePeriod?: InputMaybe<MemberOvertimePeriodCreateInput>;
};

export type MutationCreateMemberSignInAnswerArgs = {
  answer?: InputMaybe<MemberSignInAnswerCreateInput>;
};

export type MutationCreateMemberSignInSubmissionArgs = {
  submission?: InputMaybe<MemberSignInSubmissionCreateInput>;
};

export type MutationCreateMemberSignOffAnswerArgs = {
  answer?: InputMaybe<MemberSignOffAnswerCreateInput>;
};

export type MutationCreateMemberTimeDocumentArgs = {
  memberTimeDocument?: InputMaybe<MemberTimeDocumentCreateInput>;
};

export type MutationCreateObservationArgs = {
  observation?: InputMaybe<ObservationCreateInput>;
};

export type MutationCreateObservationAnswerArgs = {
  observationAnswer?: InputMaybe<ObservationAnswerCreateInput>;
};

export type MutationCreateObservationAttachmentArgs = {
  observationAttachment?: InputMaybe<ObservationAttachmentCreateInput>;
};

export type MutationCreateObservationCategoryArgs = {
  observationCategory?: InputMaybe<ObservationCategoryCreateInput>;
};

export type MutationCreateOrganizationDocumentArgs = {
  organizationDocument?: InputMaybe<OrganizationDocumentCreateInput>;
};

export type MutationCreateOrganizationNotificationPreferencesArgs = {
  notificationPreferences?: InputMaybe<OrganizationNotificationPreferencesCreateInput>;
};

export type MutationCreateOrganizationOvertimePeriodArgs = {
  organizationOvertimePeriod?: InputMaybe<OrganizationOvertimePeriodCreateInput>;
};

export type MutationCreateOrganizationPayPeriodArgs = {
  organizationPayPeriod?: InputMaybe<OrganizationPayPeriodCreateInput>;
};

export type MutationCreateOrganizationSignInQuestionArgs = {
  organizationSignInQuestion?: InputMaybe<OrganizationSignInQuestionCreateInput>;
};

export type MutationCreateOrganizationSignOffQuestionArgs = {
  organizationSignOffQuestion?: InputMaybe<OrganizationSignOffQuestionCreateInput>;
};

export type MutationCreatePerDiemArgs = {
  perDiem?: InputMaybe<PerDiemCreateInput>;
};

export type MutationCreatePolicyMemberAcceptanceArgs = {
  policyMemberAcceptance?: InputMaybe<PolicyMemberAcceptanceCreateInput>;
};

export type MutationCreatePositionArgs = {
  position?: InputMaybe<PositionCreateInput>;
};

export type MutationCreatePostArgs = {
  post?: InputMaybe<PostCreateInput>;
};

export type MutationCreatePostAttachmentArgs = {
  postAttachment?: InputMaybe<PostAttachmentCreateInput>;
};

export type MutationCreatePostCommentArgs = {
  postComment?: InputMaybe<PostCommentCreateInput>;
};

export type MutationCreatePostRecipientArgs = {
  postRecipient?: InputMaybe<PostRecipientCreateInput>;
};

export type MutationCreatePostViewerArgs = {
  postViewer?: InputMaybe<PostViewerCreateInput>;
};

export type MutationCreateProductRequestArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateProgressBudgetArgs = {
  progressBudget?: InputMaybe<ProgressBudgetCreateInput>;
};

export type MutationCreateProjectArgs = {
  project?: InputMaybe<ProjectCreateInput>;
};

export type MutationCreateProjectCostBudgetArgs = {
  projectCostBudget?: InputMaybe<ProjectCostBudgetCreateInput>;
};

export type MutationCreateProjectCostCodeLinkArgs = {
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkCreateInput>;
};

export type MutationCreateProjectCostCodeQuantityArgs = {
  projectCostCodeQuantity?: InputMaybe<ProjectCostCodeQuantityCreateInput>;
};

export type MutationCreateProjectGroupArgs = {
  projectGroup?: InputMaybe<ProjectGroupCreateInput>;
};

export type MutationCreateProjectHoursBudgetArgs = {
  projectHoursBudget?: InputMaybe<ProjectHoursBudgetCreateInput>;
};

export type MutationCreateProjectInfoArgs = {
  projectInfo?: InputMaybe<ProjectInfoCreateInput>;
};

export type MutationCreateRecurringInspectionAssignmentArgs = {
  assignment?: InputMaybe<RecurringInspectionAssignmentCreateInput>;
};

export type MutationCreateRecurringInspectionAssignmentCategoryLinkArgs = {
  assignmentCategoryLink?: InputMaybe<RecurringInspectionAssignmentCategoryLinkCreateInput>;
};

export type MutationCreateRecurringInspectionAssignmentNotificationLinkArgs = {
  assignmentNotificationLink?: InputMaybe<RecurringInspectionAssignmentNotificationLinkCreateInput>;
};

export type MutationCreateRecurringScheduleReminderArgs = {
  recurringScheduleReminder?: InputMaybe<RecurringScheduleReminderCreateInput>;
};

export type MutationCreateRecurringScheduleReminderLinkArgs = {
  recurringScheduleReminderLink?: InputMaybe<RecurringScheduleReminderLinkCreateInput>;
};

export type MutationCreateSafetyReportsCategoryArgs = {
  safetyReportsCategory?: InputMaybe<SafetyReportsCategoryCreateInput>;
};

export type MutationCreateSafetyReportsSourceArgs = {
  safetyReportsSource?: InputMaybe<SafetyReportsSourceCreateInput>;
};

export type MutationCreateSafetyReportsTrainingMaterialArgs = {
  safetyReportsTrainingMaterial?: InputMaybe<SafetyReportsTrainingMaterialCreateInput>;
};

export type MutationCreateSafetySignatureArgs = {
  safetySignature?: InputMaybe<SafetySignatureCreateInput>;
};

export type MutationCreateSafetyTrainingArgs = {
  safetyTraining?: InputMaybe<SafetyTrainingCreateInput>;
};

export type MutationCreateSafetyTrainingAttachmentArgs = {
  safetyTrainingAttachment?: InputMaybe<SafetyTrainingAttachmentCreateInput>;
};

export type MutationCreateSafetyTrainingAttendeeArgs = {
  safetyTrainingAttendee?: InputMaybe<SafetyTrainingAttendeeCreateInput>;
};

export type MutationCreateScheduleReminderArgs = {
  scheduleReminder?: InputMaybe<ScheduleReminderCreateInput>;
};

export type MutationCreateScheduleReminderLinkArgs = {
  scheduleReminderLink?: InputMaybe<ScheduleReminderLinkCreateInput>;
};

export type MutationCreateSelfSignatureArgs = {
  selfSignature?: InputMaybe<SelfSignatureCreateInput>;
};

export type MutationCreateTagArgs = {
  tag?: InputMaybe<TagCreateInput>;
};

export type MutationCreateTagDocumentLinkArgs = {
  tagDocumentLink?: InputMaybe<TagDocumentLinkCreateInput>;
};

export type MutationCreateTagMediaCollectionLinkArgs = {
  tagMediaCollectionLink?: InputMaybe<TagMediaCollectionLinkCreateInput>;
};

export type MutationCreateTimeEntriesArgs = {
  timeEntries: Array<TimeEntryCreateInput>;
};

export type MutationCreateTimeEntryArgs = {
  timeEntry?: InputMaybe<TimeEntryCreateInput>;
};

export type MutationCreateTimeEntryBreakArgs = {
  timeEntryBreak?: InputMaybe<TimeEntryBreakCreateInput>;
};

export type MutationCreateTimeEntryBreaksArgs = {
  timeEntryBreaks: Array<TimeEntryBreakCreateInput>;
};

export type MutationCreateTimeOffArgs = {
  timeOff?: InputMaybe<TimeOffCreateInput>;
};

export type MutationCreateTimeOffRequestArgs = {
  timeOffRequest?: InputMaybe<TimeOffRequestCreateInput>;
};

export type MutationCreateTimeOffRequestsArgs = {
  timeOffRequests: Array<TimeOffRequestCreateInput>;
};

export type MutationCreateTimeOffsArgs = {
  timeOffs: Array<TimeOffCreateInput>;
};

export type MutationCreateWageHistoryArgs = {
  wageHistory?: InputMaybe<WageHistoryCreateInput>;
};

export type MutationUpdateAuthoritativeSignatureArgs = {
  authoritativeSignature?: InputMaybe<AuthoritativeSignatureUpdateInput>;
};

export type MutationUpdateCostCodeArgs = {
  costCode?: InputMaybe<CostCodeUpdateInput>;
};

export type MutationUpdateCostCodeGroupArgs = {
  costCodeGroup?: InputMaybe<CostCodeGroupUpdateInput>;
};

export type MutationUpdateDailyProjectReportArgs = {
  dailyProjectReport?: InputMaybe<DailyProjectReportUpdateInput>;
};

export type MutationUpdateDailyProjectReportTemplateArgs = {
  dailyProjectReportTemplate?: InputMaybe<DailyProjectReportTemplateUpdateInput>;
};

export type MutationUpdateEquipmentArgs = {
  equipment?: InputMaybe<EquipmentUpdateInput>;
};

export type MutationUpdateEquipmentCategoryArgs = {
  equipmentCategory?: InputMaybe<EquipmentCategoryUpdateInput>;
};

export type MutationUpdateEquipmentCostHistoryArgs = {
  equipmentCostHistory?: InputMaybe<EquipmentCostHistoryUpdateInput>;
};

export type MutationUpdateEquipmentMakeArgs = {
  equipmentMake?: InputMaybe<EquipmentMakeUpdateInput>;
};

export type MutationUpdateEquipmentModelArgs = {
  equipmentModel?: InputMaybe<EquipmentModelUpdateInput>;
};

export type MutationUpdateEquipmentTypeArgs = {
  equipmentType?: InputMaybe<EquipmentTypeUpdateInput>;
};

export type MutationUpdateFlaggedTimeEntryLocationArgs = {
  flaggedTimeEntryLocation?: InputMaybe<FlaggedTimeEntryLocationUpdateInput>;
};

export type MutationUpdateFolderArgs = {
  folder?: InputMaybe<FolderUpdateInput>;
};

export type MutationUpdateIncidentArgs = {
  incident?: InputMaybe<IncidentUpdateInput>;
};

export type MutationUpdateIncidentAnswerArgs = {
  incidentAnswer?: InputMaybe<IncidentAnswerUpdateInput>;
};

export type MutationUpdateIncidentMedicalDetailsArgs = {
  incidentMedicalDetails?: InputMaybe<IncidentMedicalDetailsUpdateInput>;
};

export type MutationUpdateIncidentOutcomeDetailsArgs = {
  incidentOutcomeDetails?: InputMaybe<IncidentOutcomeDetailsUpdateInput>;
};

export type MutationUpdateInspectionArgs = {
  inspection?: InputMaybe<InspectionUpdateInput>;
};

export type MutationUpdateInspectionAssignmentArgs = {
  assignment?: InputMaybe<InspectionAssignmentUpdateInput>;
};

export type MutationUpdateInspectionAssignmentCategoryLinkArgs = {
  assignmentCategoryLink?: InputMaybe<InspectionAssignmentCategoryLinkUpdateInput>;
};

export type MutationUpdateInspectionAssignmentNotificationLinkArgs = {
  assignmentNotificationLink?: InputMaybe<InspectionAssignmentNotificationLinkUpdateInput>;
};

export type MutationUpdateInspectionCategoryArgs = {
  category?: InputMaybe<InspectionCategoryUpdateInput>;
};

export type MutationUpdateInspectionChecklistArgs = {
  checklist?: InputMaybe<InspectionChecklistUpdateInput>;
};

export type MutationUpdateInspectionChecklistMemberLinkArgs = {
  checklistMemberLink?: InputMaybe<InspectionChecklistMemberLinkUpdateInput>;
};

export type MutationUpdateInspectionQuestionArgs = {
  question?: InputMaybe<InspectionQuestionUpdateInput>;
};

export type MutationUpdateInspectionQuestionAnswerArgs = {
  answer?: InputMaybe<InspectionQuestionAnswerUpdateInput>;
};

export type MutationUpdateInspectionQuestionAnswerAttachmentArgs = {
  questionAnswerAttachment?: InputMaybe<InspectionQuestionAnswerAttachmentUpdateInput>;
};

export type MutationUpdateMediaCollectionArgs = {
  mediaCollection?: InputMaybe<MediaCollectionUpdateInput>;
};

export type MutationUpdateMediaEntryArgs = {
  mediaEntry?: InputMaybe<MediaEntryUpdateInput>;
};

export type MutationUpdateMediaEntryCommentArgs = {
  mediaEntryComment?: InputMaybe<MediaEntryCommentUpdateInput>;
};

export type MutationUpdateMediaEntryLocationArgs = {
  mediaEntryLocation?: InputMaybe<MediaEntryLocationUpdateInput>;
};

export type MutationUpdateMemberArgs = {
  member?: InputMaybe<MemberUpdateInput>;
};

export type MutationUpdateMemberGpsStatusArgs = {
  memberGpsStatus?: InputMaybe<MemberGpsStatusUpdateInput>;
};

export type MutationUpdateMemberGroupArgs = {
  memberGroup?: InputMaybe<MemberGroupUpdateInput>;
};

export type MutationUpdateMemberLocationArgs = {
  memberLocation?: InputMaybe<MemberLocationUpdateInput>;
};

export type MutationUpdateMemberLockArgs = {
  memberLock?: InputMaybe<MemberLockUpdateInput>;
};

export type MutationUpdateMemberOvertimePeriodArgs = {
  memberOvertimePeriod?: InputMaybe<MemberOvertimePeriodUpdateInput>;
};

export type MutationUpdateMemberSignInAnswerArgs = {
  answer?: InputMaybe<MemberSignInAnswerUpdateInput>;
};

export type MutationUpdateMemberSignInSubmissionArgs = {
  submission?: InputMaybe<MemberSignInSubmissionUpdateInput>;
};

export type MutationUpdateMemberSignOffAnswerArgs = {
  answer?: InputMaybe<MemberSignOffAnswerUpdateInput>;
};

export type MutationUpdateMemberTimeDocumentArgs = {
  memberTimeDocument?: InputMaybe<MemberTimeDocumentUpdateInput>;
};

export type MutationUpdateObservationArgs = {
  observation?: InputMaybe<ObservationUpdateInput>;
};

export type MutationUpdateObservationAnswerArgs = {
  observationAnswer?: InputMaybe<ObservationAnswerUpdateInput>;
};

export type MutationUpdateObservationAttachmentArgs = {
  observationAttachment?: InputMaybe<ObservationAttachmentUpdateInput>;
};

export type MutationUpdateObservationCategoryArgs = {
  observationCategory?: InputMaybe<ObservationCategoryUpdateInput>;
};

export type MutationUpdateOrganizationArgs = {
  organization?: InputMaybe<OrganizationUpdateInput>;
};

export type MutationUpdateOrganizationDocumentArgs = {
  organizationDocument?: InputMaybe<OrganizationDocumentUpdateInput>;
};

export type MutationUpdateOrganizationInfoArgs = {
  organizationInfo?: InputMaybe<OrganizationInfoUpdateInput>;
};

export type MutationUpdateOrganizationNotificationPreferencesArgs = {
  notificationPreferences?: InputMaybe<OrganizationNotificationPreferencesUpdateInput>;
};

export type MutationUpdateOrganizationOvertimePeriodArgs = {
  organizationOvertimePeriod?: InputMaybe<OrganizationOvertimePeriodUpdateInput>;
};

export type MutationUpdateOrganizationPayPeriodArgs = {
  organizationPayPeriod?: InputMaybe<OrganizationPayPeriodUpdateInput>;
};

export type MutationUpdateOrganizationSignInQuestionArgs = {
  organizationSignInQuestion?: InputMaybe<OrganizationSignInQuestionUpdateInput>;
};

export type MutationUpdateOrganizationSignOffQuestionArgs = {
  organizationSignOffQuestion?: InputMaybe<OrganizationSignOffQuestionUpdateInput>;
};

export type MutationUpdatePerDiemArgs = {
  perDiem?: InputMaybe<PerDiemUpdateInput>;
};

export type MutationUpdatePositionArgs = {
  position?: InputMaybe<PositionUpdateInput>;
};

export type MutationUpdatePostArgs = {
  post?: InputMaybe<PostUpdateInput>;
};

export type MutationUpdatePostAttachmentArgs = {
  postAttachment?: InputMaybe<PostAttachmentUpdateInput>;
};

export type MutationUpdatePostCommentArgs = {
  postComment?: InputMaybe<PostCommentUpdateInput>;
};

export type MutationUpdatePostRecipientArgs = {
  postRecipient?: InputMaybe<PostRecipientUpdateInput>;
};

export type MutationUpdatePostViewerArgs = {
  postViewer?: InputMaybe<PostViewerUpdateInput>;
};

export type MutationUpdateProgressBudgetArgs = {
  progressBudget?: InputMaybe<ProgressBudgetUpdateInput>;
};

export type MutationUpdateProjectArgs = {
  project?: InputMaybe<ProjectUpdateInput>;
};

export type MutationUpdateProjectCostCodeLinkArgs = {
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkUpdateInput>;
};

export type MutationUpdateProjectCostCodeQuantityArgs = {
  projectCostCodeQuantity?: InputMaybe<ProjectCostCodeQuantityUpdateInput>;
};

export type MutationUpdateProjectGroupArgs = {
  projectGroup?: InputMaybe<ProjectGroupUpdateInput>;
};

export type MutationUpdateProjectInfoArgs = {
  projectInfo?: InputMaybe<ProjectInfoUpdateInput>;
};

export type MutationUpdateRecurringInspectionAssignmentArgs = {
  assignment?: InputMaybe<RecurringInspectionAssignmentUpdateInput>;
};

export type MutationUpdateRecurringInspectionAssignmentCategoryLinkArgs = {
  assignmentCategoryLink?: InputMaybe<RecurringInspectionAssignmentCategoryLinkUpdateInput>;
};

export type MutationUpdateRecurringInspectionAssignmentNotificationLinkArgs = {
  assignmentNotificationLink?: InputMaybe<RecurringInspectionAssignmentNotificationLinkUpdateInput>;
};

export type MutationUpdateRecurringScheduleReminderArgs = {
  recurringScheduleReminder?: InputMaybe<RecurringScheduleReminderUpdateInput>;
};

export type MutationUpdateRecurringScheduleReminderLinkArgs = {
  recurringScheduleReminderLink?: InputMaybe<RecurringScheduleReminderLinkUpdateInput>;
};

export type MutationUpdateSafetyReportsCategoryArgs = {
  safetyReportsCategory?: InputMaybe<SafetyReportsCategoryUpdateInput>;
};

export type MutationUpdateSafetyReportsSourceArgs = {
  safetyReportsSource?: InputMaybe<SafetyReportsSourceUpdateInput>;
};

export type MutationUpdateSafetyReportsTrainingMaterialArgs = {
  safetyReportsTrainingMaterial?: InputMaybe<SafetyReportsTrainingMaterialUpdateInput>;
};

export type MutationUpdateSafetySignatureArgs = {
  safetySignature?: InputMaybe<SafetySignatureUpdateInput>;
};

export type MutationUpdateSafetyTrainingArgs = {
  safetyTraining?: InputMaybe<SafetyTrainingUpdateInput>;
};

export type MutationUpdateSafetyTrainingAttachmentArgs = {
  safetyTrainingAttachment?: InputMaybe<SafetyTrainingAttachmentUpdateInput>;
};

export type MutationUpdateSafetyTrainingAttendeeArgs = {
  safetyTrainingAttendee?: InputMaybe<SafetyTrainingAttendeeUpdateInput>;
};

export type MutationUpdateScheduleReminderArgs = {
  scheduleReminder?: InputMaybe<ScheduleReminderUpdateInput>;
};

export type MutationUpdateScheduleReminderLinkArgs = {
  scheduleReminderLink?: InputMaybe<ScheduleReminderLinkUpdateInput>;
};

export type MutationUpdateSelfSignatureArgs = {
  selfSignature?: InputMaybe<SelfSignatureUpdateInput>;
};

export type MutationUpdateTagArgs = {
  tag?: InputMaybe<TagUpdateInput>;
};

export type MutationUpdateTagDocumentLinkArgs = {
  tagDocumentLink?: InputMaybe<TagDocumentLinkUpdateInput>;
};

export type MutationUpdateTagMediaCollectionLinkArgs = {
  tagMediaCollectionLink?: InputMaybe<TagMediaCollectionLinkUpdateInput>;
};

export type MutationUpdateTimeEntriesArgs = {
  timeEntries: Array<TimeEntryUpdateInput>;
};

export type MutationUpdateTimeEntryArgs = {
  timeEntry?: InputMaybe<TimeEntryUpdateInput>;
};

export type MutationUpdateTimeEntryBreakArgs = {
  timeEntryBreak?: InputMaybe<TimeEntryBreakUpdateInput>;
};

export type MutationUpdateTimeEntryBreaksArgs = {
  timeEntryBreaks: Array<TimeEntryBreakUpdateInput>;
};

export type MutationUpdateTimeOffArgs = {
  timeOff?: InputMaybe<TimeOffUpdateInput>;
};

export type MutationUpdateTimeOffRequestArgs = {
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateInput>;
};

export type MutationUpdateTimeOffRequestsArgs = {
  timeOffRequests: Array<TimeOffRequestUpdateInput>;
};

export type MutationUpdateTimeOffsArgs = {
  timeOffs: Array<TimeOffUpdateInput>;
};

export type MutationUpdateWageHistoryArgs = {
  wageHistory?: InputMaybe<WageHistoryUpdateInput>;
};

export type Observation = {
  __typename?: 'Observation';
  actionDescription?: Maybe<Scalars['String']['output']>;
  answers: Array<ObservationAnswer>;
  attachments: Array<ObservationAttachment>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  date: Scalars['Timestamp']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  isAnonymous: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  locationDetails?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  observationDescription?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['Uuid']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  reportedBy?: Maybe<Scalars['Uuid']['output']>;
  reportedByMember?: Maybe<Member>;
  submittedOn: Scalars['Timestamp']['output'];
  type: ObservationTypes;
  updatedOn: Scalars['Timestamp']['output'];
};

export type ObservationAnswer = {
  __typename?: 'ObservationAnswer';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  observation: Observation;
  observationCategory: ObservationCategory;
  observationCategoryId: Scalars['Uuid']['output'];
  observationId: Scalars['Uuid']['output'];
  processedBy: Scalars['Uuid']['output'];
  processedByMember: Member;
  processedDescription?: Maybe<Scalars['String']['output']>;
  processedOn: Scalars['Timestamp']['output'];
  severity?: Maybe<ObservationSeverityType>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ObservationAnswerCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  observationCategoryId: Scalars['Uuid']['input'];
  observationId: Scalars['Uuid']['input'];
  processedDescription?: InputMaybe<Scalars['String']['input']>;
  processedOn: Scalars['Timestamp']['input'];
  severity?: InputMaybe<ObservationSeverityType>;
};

export type ObservationAnswerFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  observation?: InputMaybe<ObservationFilter>;
  observationCategory?: InputMaybe<ObservationCategoryFilter>;
  observationCategoryId?: InputMaybe<UuidComparison>;
  observationId?: InputMaybe<UuidComparison>;
  processedByMember?: InputMaybe<MemberFilter>;
  processedOn?: InputMaybe<TimestampComparison>;
  severity?: InputMaybe<ObservationSeverityTypeComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ObservationAnswerSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  observation?: InputMaybe<ObservationSort>;
  observationCategory?: InputMaybe<ObservationCategorySort>;
  processedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ObservationAnswerUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  observationCategoryId?: InputMaybe<Scalars['Uuid']['input']>;
  processedDescription?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<ObservationSeverityType>;
};

export type ObservationAttachment = {
  __typename?: 'ObservationAttachment';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  observation: Observation;
  observationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  uploadedBy: Scalars['Uuid']['output'];
  uploadedByMember: Member;
};

export type ObservationAttachmentCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  fileType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  observationId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};

export type ObservationAttachmentFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  observation?: InputMaybe<ObservationFilter>;
  observationId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  uploadedBy?: InputMaybe<UuidComparison>;
  uploadedByMember?: InputMaybe<MemberFilter>;
};

export type ObservationAttachmentSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  uploadedBy?: InputMaybe<SortOrder>;
  uploadedByMember?: InputMaybe<MemberSort>;
};

export type ObservationAttachmentUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ObservationCategory = {
  __typename?: 'ObservationCategory';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ObservationCategoryCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type ObservationCategoryFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ObservationCategorySort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ObservationCategoryUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ObservationCreateInput = {
  actionDescription?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  date: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAnonymous: Scalars['Boolean']['input'];
  latitude?: InputMaybe<SortOrder>;
  locationDetails?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<SortOrder>;
  observationDescription?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Uuid']['input'];
  type: ObservationTypes;
};

export type ObservationFilter = {
  answers?: InputMaybe<ObservationAnswerFilter>;
  attachments?: InputMaybe<ObservationAttachmentFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  date?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  isAnonymous?: InputMaybe<BooleanComparison>;
  latitude?: InputMaybe<FloatComparison>;
  longitude?: InputMaybe<FloatComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  reportedBy?: InputMaybe<UuidComparison>;
  reportedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<ObservationTypesComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum ObservationSeverityType {
  High = 'high',
  Low = 'low',
  Moderate = 'moderate',
}

export type ObservationSeverityTypeComparison = {
  contains?: InputMaybe<Array<ObservationSeverityType>>;
  doesNotContain?: InputMaybe<Array<ObservationSeverityType>>;
  equal?: InputMaybe<ObservationSeverityType>;
  equalOrNull?: InputMaybe<ObservationSeverityType>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<ObservationSeverityType>;
};

export type ObservationSort = {
  answers?: InputMaybe<ObservationAnswerSort>;
  attachments?: InputMaybe<ObservationAttachmentSort>;
  createdOn?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  projectId?: InputMaybe<SortOrder>;
  reportedByMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum ObservationTypes {
  PositiveFeedback = 'positiveFeedback',
  SafetyConcerns = 'safetyConcerns',
}

export type ObservationTypesComparison = {
  contains?: InputMaybe<Array<ObservationTypes>>;
  doesNotContain?: InputMaybe<Array<ObservationTypes>>;
  equal?: InputMaybe<ObservationTypes>;
  equalOrNull?: InputMaybe<ObservationTypes>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<ObservationTypes>;
};

export type ObservationUpdateInput = {
  actionDescription?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  locationDetails?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<SortOrder>;
  observationDescription?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  type?: InputMaybe<ObservationTypes>;
};

export enum OperationType {
  And = 'and',
  Or = 'or',
}

export type Organization = {
  __typename?: 'Organization';
  authoritativeSignature: Scalars['Boolean']['output'];
  betaTester?: Maybe<Scalars['Boolean']['output']>;
  breakPolicy: Scalars['Boolean']['output'];
  breakPolicyCalifornia: Scalars['Boolean']['output'];
  breakPolicyMessage?: Maybe<Scalars['String']['output']>;
  bulletin?: Maybe<Scalars['Boolean']['output']>;
  busySignInQuestion: Scalars['Boolean']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  customSignOff?: Maybe<Scalars['Boolean']['output']>;
  defaultBreakDuration?: Maybe<Scalars['String']['output']>;
  defaultEndTime?: Maybe<Scalars['String']['output']>;
  defaultStartTime?: Maybe<Scalars['String']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  demo: Scalars['Boolean']['output'];
  disclaimer?: Maybe<Scalars['String']['output']>;
  employeeAutoInvite: EmployeeAutoInvite;
  gustoCompanyId?: Maybe<Scalars['String']['output']>;
  hideUnassignedSubprojects: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  inRecoveryMode?: Maybe<Scalars['Timestamp']['output']>;
  lockOnSelfSignature?: Maybe<Scalars['Boolean']['output']>;
  minBreakSeconds?: Maybe<Scalars['Int']['output']>;
  minimumAppVersion?: Maybe<Scalars['String']['output']>;
  organizationInfo?: Maybe<OrganizationInfo>;
  organizationName: Scalars['String']['output'];
  organizationOvertimePeriods?: Maybe<Array<OrganizationOvertimePeriod>>;
  organizationPayPeriod?: Maybe<Array<OrganizationPayPeriod>>;
  organizationSettings?: Maybe<Scalars['String']['output']>;
  overtimeFlag: Scalars['Boolean']['output'];
  ownedBy: Scalars['Uuid']['output'];
  perDiem?: Maybe<Scalars['Boolean']['output']>;
  perDiemAmountTracking?: Maybe<Scalars['Boolean']['output']>;
  perDiemRequestPermission?: Maybe<PerDiemRequestPermission>;
  performanceScores?: Maybe<Scalars['Boolean']['output']>;
  requireCostCode: Scalars['Boolean']['output'];
  requireProject: Scalars['Boolean']['output'];
  revokedOn?: Maybe<Scalars['Timestamp']['output']>;
  safetySignature: Scalars['Boolean']['output'];
  safetySignatureMessage?: Maybe<Scalars['String']['output']>;
  signatureDate?: Maybe<Scalars['Timestamp']['output']>;
  stripeAccountCreatedOn?: Maybe<Scalars['Timestamp']['output']>;
  stripeAccountStatus?: Maybe<Scalars['String']['output']>;
  stripeAccountType?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  subscriptionStatus?: Maybe<OrganizationSubscriptionStatus>;
  timeAccuracy: Scalars['Boolean']['output'];
  timeAccuracyMessage?: Maybe<Scalars['String']['output']>;
  timeOffRequests: Scalars['Boolean']['output'];
  timeRounding: Scalars['Boolean']['output'];
  timeRoundingInterval: Scalars['Int']['output'];
  timeRoundingType: Scalars['Int']['output'];
  totalTimeFlag: Scalars['Int']['output'];
  trackBudgetCost?: Maybe<Scalars['Boolean']['output']>;
  trackBudgetEquipmentCosts?: Maybe<Scalars['Boolean']['output']>;
  trackBudgetEquipmentHours?: Maybe<Scalars['Boolean']['output']>;
  trackBudgetProgress?: Maybe<Scalars['Boolean']['output']>;
  trackBudgetProgressValue?: Maybe<Scalars['Boolean']['output']>;
  trackBudgets: Scalars['Boolean']['output'];
  trackCostCode: Scalars['Boolean']['output'];
  trackEquipment: Scalars['Boolean']['output'];
  trackPaidBreak: Scalars['Boolean']['output'];
  trackProject: Scalars['Boolean']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  useProjectCostCodeScoping?: Maybe<Scalars['Boolean']['output']>;
  webGps: Scalars['Boolean']['output'];
};

export type OrganizationDocument = {
  __typename?: 'OrganizationDocument';
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  folder?: Maybe<Folder>;
  folderId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedOn?: Maybe<Scalars['Timestamp']['output']>;
  limitAccess?: Maybe<OrganizationDocumentAccessTypes>;
  organizationId: Scalars['Uuid']['output'];
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars['Uuid']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  tagLinks: Array<TagDocumentLink>;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export enum OrganizationDocumentAccessTypes {
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
}

export type OrganizationDocumentCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limitAccess?: InputMaybe<OrganizationDocumentAccessTypes>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  title: Scalars['String']['input'];
};

export type OrganizationDocumentFilter = {
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  fileType?: InputMaybe<StringComparison>;
  folder?: InputMaybe<FolderFilter>;
  folderId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  limitAccess?: InputMaybe<StringComparison>;
  position?: InputMaybe<PositionFilter>;
  positionId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  search?: InputMaybe<OrganizationDocumentSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  tagLinks?: InputMaybe<TagDocumentLinkFilter>;
  title?: InputMaybe<StringComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type OrganizationDocumentSearch = {
  fields: Array<OrganizationDocumentSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum OrganizationDocumentSearchFields {
  FileType = 'fileType',
  Tag = 'tag',
  Title = 'title',
}

export type OrganizationDocumentSort = {
  createdBy?: InputMaybe<SortOrder>;
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  folder?: InputMaybe<FolderSort>;
  folderId?: InputMaybe<SortOrder>;
  lastUpdatedOn?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  projectId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type OrganizationDocumentUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['Uuid']['input']>;
  id: Scalars['ID']['input'];
  limitAccess?: InputMaybe<OrganizationDocumentAccessTypes>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationInfo = {
  __typename?: 'OrganizationInfo';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  currencyCode?: Maybe<Scalars['String']['output']>;
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  employeeMax?: Maybe<Scalars['Int']['output']>;
  employeeMin?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  organizationId: Scalars['Uuid']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  trade?: Maybe<Scalars['String']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type OrganizationInfoUpdateInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  employeeMax?: InputMaybe<Scalars['Int']['input']>;
  employeeMin?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  trade?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationLaborMetrics = LaborMetricsInterface & {
  __typename?: 'OrganizationLaborMetrics';
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  id?: Maybe<Scalars['String']['output']>;
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  pto?: Maybe<Scalars['Long']['output']>;
  ptoCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
  upto?: Maybe<Scalars['Long']['output']>;
};

export type OrganizationNotificationPreferences = {
  __typename?: 'OrganizationNotificationPreferences';
  breakReport: Scalars['Int']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  inaccurateTimeReport: Scalars['Int']['output'];
  injuryReport: Scalars['Int']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type OrganizationNotificationPreferencesCreateInput = {
  breakReport: Scalars['Int']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inaccurateTimeReport: Scalars['Int']['input'];
  injuryReport: Scalars['Int']['input'];
  memberId: Scalars['Uuid']['input'];
};

export type OrganizationNotificationPreferencesFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type OrganizationNotificationPreferencesSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type OrganizationNotificationPreferencesUpdateInput = {
  breakReport?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  inaccurateTimeReport?: InputMaybe<Scalars['Int']['input']>;
  injuryReport?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationOvertimePeriod = {
  __typename?: 'OrganizationOvertimePeriod';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  dailyDoubletime?: Maybe<Scalars['Int']['output']>;
  dailyOvertime?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  lengthInDays?: Maybe<Scalars['Int']['output']>;
  organizationId: Scalars['Uuid']['output'];
  saturdayMultiplier?: Maybe<Scalars['Float']['output']>;
  seventhDayDoubletime?: Maybe<Scalars['Int']['output']>;
  seventhDayOvertime?: Maybe<Scalars['Boolean']['output']>;
  startTime: Scalars['Timestamp']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  sundayMultiplier?: Maybe<Scalars['Float']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  weeklyOvertime?: Maybe<Scalars['Int']['output']>;
};

export type OrganizationOvertimePeriodCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  dailyDoubletime?: InputMaybe<Scalars['Int']['input']>;
  dailyOvertime?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
  saturdayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  seventhDayDoubletime?: InputMaybe<Scalars['Int']['input']>;
  seventhDayOvertime?: InputMaybe<Scalars['Boolean']['input']>;
  startTime: Scalars['Timestamp']['input'];
  sundayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  weeklyOvertime?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationOvertimePeriodFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type OrganizationOvertimePeriodSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type OrganizationOvertimePeriodUpdateInput = {
  dailyDoubletime?: InputMaybe<Scalars['Int']['input']>;
  dailyOvertime?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  saturdayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  seventhDayDoubletime?: InputMaybe<Scalars['Int']['input']>;
  seventhDayOvertime?: InputMaybe<Scalars['Boolean']['input']>;
  startTime: Scalars['Timestamp']['input'];
  sundayMultiplier?: InputMaybe<Scalars['Float']['input']>;
  weeklyOvertime?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationPayPeriod = {
  __typename?: 'OrganizationPayPeriod';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  dayOne: Scalars['Int']['output'];
  dayTwo?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  payPeriodType: Scalars['Int']['output'];
  startDate: Scalars['Timestamp']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type OrganizationPayPeriodCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  dayOne: Scalars['Int']['input'];
  dayTwo?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
  payPeriodType: Scalars['Int']['input'];
  startDate: Scalars['Timestamp']['input'];
};

export type OrganizationPayPeriodFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  payPeriodType?: InputMaybe<UuidComparison>;
  startDate?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type OrganizationPayPeriodSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type OrganizationPayPeriodUpdateInput = {
  dayOne?: InputMaybe<Scalars['Int']['input']>;
  dayTwo?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  payPeriodType?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type OrganizationSignInQuestion = {
  __typename?: 'OrganizationSignInQuestion';
  audienceType: OrganizationSignInQuestionAudience;
  choices?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  organizationId: Scalars['Uuid']['output'];
  positionId?: Maybe<Scalars['Uuid']['output']>;
  questionDescription?: Maybe<Scalars['String']['output']>;
  questionPrompt?: Maybe<Scalars['String']['output']>;
  questionTitle: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  type: OrganizationSignInQuestionType;
  updatedOn: Scalars['Timestamp']['output'];
};

export enum OrganizationSignInQuestionAudience {
  Everyone = 'everyone',
  NoOne = 'no_one',
  Position = 'position',
}

export type OrganizationSignInQuestionAudienceComparison = {
  contains?: InputMaybe<Array<OrganizationSignInQuestionAudience>>;
  doesNotContain?: InputMaybe<Array<OrganizationSignInQuestionAudience>>;
  equal?: InputMaybe<OrganizationSignInQuestionAudience>;
  equalOrNull?: InputMaybe<OrganizationSignInQuestionAudience>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<OrganizationSignInQuestionAudience>;
};

export type OrganizationSignInQuestionCreateInput = {
  audienceType: OrganizationSignInQuestionAudience;
  choices?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
  organizationId: Scalars['Uuid']['input'];
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  questionDescription?: InputMaybe<Scalars['String']['input']>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  questionTitle: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
  type: OrganizationSignInQuestionType;
};

export type OrganizationSignInQuestionFilter = {
  audienceType?: InputMaybe<OrganizationSignInQuestionAudienceComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  positionId?: InputMaybe<UuidComparison>;
  questionTitle?: InputMaybe<StringComparison>;
  required?: InputMaybe<BooleanComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<OrganizationSignInQuestionTypeComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type OrganizationSignInQuestionSort = {
  createdOn?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  questionTitle?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum OrganizationSignInQuestionType {
  TrueFalse = 'true_false',
}

export type OrganizationSignInQuestionTypeComparison = {
  contains?: InputMaybe<Array<OrganizationSignInQuestionType>>;
  doesNotContain?: InputMaybe<Array<OrganizationSignInQuestionType>>;
  equal?: InputMaybe<OrganizationSignInQuestionType>;
  equalOrNull?: InputMaybe<OrganizationSignInQuestionType>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<OrganizationSignInQuestionType>;
};

export type OrganizationSignInQuestionUpdateInput = {
  audienceType?: InputMaybe<OrganizationSignInQuestionAudience>;
  choices?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  questionDescription?: InputMaybe<Scalars['String']['input']>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<OrganizationSignInQuestionType>;
};

export type OrganizationSignOffQuestion = {
  __typename?: 'OrganizationSignOffQuestion';
  audienceType: OrganizationSignOffQuestionAudience;
  choices?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  organizationId: Scalars['Uuid']['output'];
  positionId?: Maybe<Scalars['Uuid']['output']>;
  questionDescription?: Maybe<Scalars['String']['output']>;
  questionPrompt?: Maybe<Scalars['String']['output']>;
  questionTitle: Scalars['String']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  type: OrganizationSignOffQuestionType;
  updatedOn: Scalars['Timestamp']['output'];
};

export enum OrganizationSignOffQuestionAudience {
  Everyone = 'everyone',
  NoOne = 'no_one',
  Position = 'position',
}

export type OrganizationSignOffQuestionCreateInput = {
  audienceType: OrganizationSignOffQuestionAudience;
  choices?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order: Scalars['Int']['input'];
  organizationId: Scalars['Uuid']['input'];
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  questionDescription?: InputMaybe<Scalars['String']['input']>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  questionTitle: Scalars['String']['input'];
  type: OrganizationSignOffQuestionType;
};

export type OrganizationSignOffQuestionFilter = {
  audienceType?: InputMaybe<StringComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  positionId?: InputMaybe<UuidComparison>;
  questionTitle?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type OrganizationSignOffQuestionSort = {
  createdOn?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  questionTitle?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum OrganizationSignOffQuestionType {
  TrueFalse = 'true_false',
}

export type OrganizationSignOffQuestionUpdateInput = {
  audienceType?: InputMaybe<OrganizationSignOffQuestionAudience>;
  choices?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  questionDescription?: InputMaybe<Scalars['String']['input']>;
  questionPrompt?: InputMaybe<Scalars['String']['input']>;
  questionTitle?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrganizationSignOffQuestionType>;
};

export type OrganizationSubscriptionStatus = {
  __typename?: 'OrganizationSubscriptionStatus';
  annualSubscriptionCount?: Maybe<Scalars['Int']['output']>;
  annualSubscriptionDate?: Maybe<Scalars['Timestamp']['output']>;
  annualSubscriptionInterval?: Maybe<Scalars['Int']['output']>;
  annualSubscriptionPendingInvoiceId?: Maybe<Scalars['Int']['output']>;
  cardLastFour?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  customerId?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  exempt?: Maybe<Scalars['Boolean']['output']>;
  hasTrialed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  nextAssessmentAt?: Maybe<Scalars['Timestamp']['output']>;
  organizationId: Scalars['Uuid']['output'];
  periodDay?: Maybe<Scalars['Int']['output']>;
  pricePointAction?: Maybe<Scalars['String']['output']>;
  pricePointHandle?: Maybe<Scalars['String']['output']>;
  productHandle?: Maybe<Scalars['String']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  subscribedThroughProduct?: Maybe<Scalars['Boolean']['output']>;
  subscriptionId?: Maybe<Scalars['Int']['output']>;
  subscriptionUpdatedAt?: Maybe<Scalars['Timestamp']['output']>;
  trialEndedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  userCanceled?: Maybe<Scalars['Boolean']['output']>;
};

export type OrganizationUpdateInput = {
  authoritativeSignature?: InputMaybe<Scalars['Boolean']['input']>;
  breakPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  breakPolicyCalifornia?: InputMaybe<Scalars['Boolean']['input']>;
  breakPolicyMessage?: InputMaybe<Scalars['String']['input']>;
  bulletin?: InputMaybe<Scalars['Boolean']['input']>;
  busySignInQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  customSignOff?: InputMaybe<Scalars['Boolean']['input']>;
  defaultBreakDuration?: InputMaybe<Scalars['String']['input']>;
  defaultEndTime?: InputMaybe<Scalars['String']['input']>;
  defaultStartTime?: InputMaybe<Scalars['String']['input']>;
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  employeeAutoInvite?: InputMaybe<EmployeeAutoInvite>;
  gustoCompanyId?: InputMaybe<Scalars['String']['input']>;
  hideUnassignedSubprojects?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  lockOnSelfSignature?: InputMaybe<Scalars['Boolean']['input']>;
  minBreakSeconds?: InputMaybe<Scalars['Int']['input']>;
  minimumAppVersion?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationSettings?: InputMaybe<Scalars['String']['input']>;
  overtimeFlag?: InputMaybe<Scalars['Boolean']['input']>;
  ownedBy?: InputMaybe<Scalars['Uuid']['input']>;
  perDiem?: InputMaybe<Scalars['Boolean']['input']>;
  perDiemAmountTracking?: InputMaybe<Scalars['Boolean']['input']>;
  perDiemRequestPermission?: InputMaybe<PerDiemRequestPermission>;
  performanceScores?: InputMaybe<Scalars['Boolean']['input']>;
  requireCostCode?: InputMaybe<Scalars['Boolean']['input']>;
  requireProject?: InputMaybe<Scalars['Boolean']['input']>;
  safetySignature?: InputMaybe<Scalars['Boolean']['input']>;
  safetySignatureMessage?: InputMaybe<Scalars['String']['input']>;
  signatureDate?: InputMaybe<Scalars['Timestamp']['input']>;
  stripeAccountCreatedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  timeAccuracy?: InputMaybe<Scalars['Boolean']['input']>;
  timeAccuracyMessage?: InputMaybe<Scalars['String']['input']>;
  timeOffRequests?: InputMaybe<Scalars['Boolean']['input']>;
  timeRounding?: InputMaybe<Scalars['Boolean']['input']>;
  timeRoundingInterval?: InputMaybe<Scalars['Int']['input']>;
  timeRoundingType?: InputMaybe<Scalars['Int']['input']>;
  totalTimeFlag?: InputMaybe<Scalars['Int']['input']>;
  trackBudgetCost?: InputMaybe<Scalars['Boolean']['input']>;
  trackBudgetEquipmentCosts?: InputMaybe<Scalars['Boolean']['input']>;
  trackBudgetEquipmentHours?: InputMaybe<Scalars['Boolean']['input']>;
  trackBudgetProgress?: InputMaybe<Scalars['Boolean']['input']>;
  trackBudgetProgressValue?: InputMaybe<Scalars['Boolean']['input']>;
  trackBudgets?: InputMaybe<Scalars['Boolean']['input']>;
  trackCostCode?: InputMaybe<Scalars['Boolean']['input']>;
  trackEquipment?: InputMaybe<Scalars['Boolean']['input']>;
  trackPaidBreak?: InputMaybe<Scalars['Boolean']['input']>;
  trackProject?: InputMaybe<Scalars['Boolean']['input']>;
  useProjectCostCodeScoping?: InputMaybe<Scalars['Boolean']['input']>;
  webGps?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PerDiem = {
  __typename?: 'PerDiem';
  amount?: Maybe<Scalars['Float']['output']>;
  answeredBy?: Maybe<Scalars['Uuid']['output']>;
  answeredByMember?: Maybe<Member>;
  answeredOn?: Maybe<Scalars['Timestamp']['output']>;
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  dateStamp: Scalars['Timestamp']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  organizationId: Scalars['Uuid']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  requestedAmount?: Maybe<Scalars['Float']['output']>;
  state: PerDiemStateTypes;
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type PerDiemCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  answeredOn?: InputMaybe<Scalars['Timestamp']['input']>;
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDst: Scalars['Boolean']['input'];
  dateStamp: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Float']['input']>;
  state: PerDiemStateTypes;
};

export type PerDiemFilter = {
  answeredByMember?: InputMaybe<MemberFilter>;
  answeredOn?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  dateStamp?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  state?: InputMaybe<PerDiemStateTypesComparison>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum PerDiemRequestPermission {
  All = 'all',
  OthersOnly = 'othersOnly',
  SelfOnly = 'selfOnly',
}

export type PerDiemSort = {
  amount?: InputMaybe<SortOrder>;
  answeredByMember?: InputMaybe<MemberSort>;
  answeredOn?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  dateStamp?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  requestedAmount?: InputMaybe<SortOrder>;
  submittedByMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum PerDiemStateTypes {
  PerDiem = 'perDiem',
  PerDiemFromRequest = 'perDiemFromRequest',
  PerDiemRequestDenied = 'perDiemRequestDenied',
  PerDiemRequestPending = 'perDiemRequestPending',
}

export type PerDiemStateTypesComparison = {
  contains?: InputMaybe<Array<PerDiemStateTypes>>;
  doesNotContain?: InputMaybe<Array<PerDiemStateTypes>>;
  equal?: InputMaybe<PerDiemStateTypes>;
  equalOrNull?: InputMaybe<PerDiemStateTypes>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<PerDiemStateTypes>;
};

export type PerDiemUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  answeredOn?: InputMaybe<Scalars['Timestamp']['input']>;
  dateStamp?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  requestedAmount?: InputMaybe<Scalars['Float']['input']>;
  state?: InputMaybe<PerDiemStateTypes>;
};

export type Policy = {
  __typename?: 'Policy';
  contentUrl?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  effectiveDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
  version?: Maybe<Scalars['Int']['output']>;
};

export type PolicyFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<IntComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type PolicyMemberAcceptance = {
  __typename?: 'PolicyMemberAcceptance';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  policy: Policy;
  policyId?: Maybe<Scalars['Int']['output']>;
  proxyMember: Member;
  proxyMemberId: Scalars['Uuid']['output'];
  signatureId?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['FileUrl']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type PolicyMemberAcceptanceCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deviceType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  policyId: Scalars['Int']['input'];
};

export type PolicyMemberAcceptanceFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  policy?: InputMaybe<PolicyFilter>;
  policyId?: InputMaybe<IntComparison>;
  proxyMember?: InputMaybe<MemberFilter>;
  proxyMemberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type PolicyMemberAcceptanceSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  policy?: InputMaybe<PolicySort>;
  proxyMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type PolicySort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  effectiveDate?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export type Position = {
  __typename?: 'Position';
  canEditPosition: Scalars['Boolean']['output'];
  canManageProjectCostCodeQuantity: Scalars['Int']['output'];
  canRequestTimeOff: Scalars['Boolean']['output'];
  clockInRestriction: ClockInRestrictionType;
  clockInRestrictionStartTime?: Maybe<Scalars['Time']['output']>;
  createIncident?: Maybe<Scalars['Boolean']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  dailyProjectPrompts: Scalars['Boolean']['output'];
  dailyProjectPromptsUseSubprojects: Scalars['Boolean']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipmentSelectionRequirement: EquipmentSelectionOptions;
  id: Scalars['ID']['output'];
  jobCostingPrompt: JobCostingPromptOptions;
  level: Scalars['Int']['output'];
  manageBudgets: Scalars['Boolean']['output'];
  manageBulletinPosts?: Maybe<Scalars['Boolean']['output']>;
  manageCompanySettings: Scalars['Boolean']['output'];
  manageCostCodes: Scalars['Boolean']['output'];
  manageEmployeeGroups: Scalars['Boolean']['output'];
  manageEmployees: Scalars['Int']['output'];
  manageEquipment: Scalars['Boolean']['output'];
  manageGpsApproval: Scalars['Int']['output'];
  manageIncident?: Maybe<Scalars['Boolean']['output']>;
  manageInspections?: Maybe<Scalars['Boolean']['output']>;
  manageMediaCollection: Scalars['Int']['output'];
  manageObservations?: Maybe<Scalars['Boolean']['output']>;
  managePerDiem: Scalars['Int']['output'];
  manageProjectGroups: Scalars['Boolean']['output'];
  manageProjects: Scalars['Boolean']['output'];
  manageSchedule: Scalars['Int']['output'];
  manageTimeEntries: Scalars['Int']['output'];
  manageTimeOff: Scalars['Int']['output'];
  manageTraining?: Maybe<Scalars['Boolean']['output']>;
  memberLock: Scalars['Int']['output'];
  organizationId: Scalars['Uuid']['output'];
  performInspections?: Maybe<Scalars['Boolean']['output']>;
  performTraining?: Maybe<Scalars['Boolean']['output']>;
  photoVerificationSettings?: Maybe<Scalars['String']['output']>;
  reportObservations?: Maybe<Scalars['Boolean']['output']>;
  requestSignatures: Scalars['Int']['output'];
  scopeByGroup: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeEvents: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  useKiosk?: Maybe<Scalars['Boolean']['output']>;
  viewAllActivityReport?: Maybe<Scalars['Boolean']['output']>;
  viewBudgets: Scalars['Boolean']['output'];
  viewEquipmentDetails: Scalars['Boolean']['output'];
  viewEquipmentStatus: Scalars['Boolean']['output'];
  viewMediaCollection: Scalars['Int']['output'];
  viewMemberActivityReport?: Maybe<Scalars['Int']['output']>;
  viewMemberExpenses: Scalars['Boolean']['output'];
  viewProjectExpenses: Scalars['Boolean']['output'];
  viewSchedule: Scalars['Int']['output'];
};

export type PositionCreateInput = {
  canManageProjectCostCodeQuantity?: InputMaybe<Scalars['Int']['input']>;
  canRequestTimeOff?: InputMaybe<Scalars['Boolean']['input']>;
  clockInRestriction: ClockInRestrictionType;
  clockInRestrictionStartTime?: InputMaybe<Scalars['String']['input']>;
  createIncident?: InputMaybe<Scalars['Boolean']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  dailyProjectPrompts: Scalars['Boolean']['input'];
  dailyProjectPromptsUseSubprojects: Scalars['Boolean']['input'];
  equipmentSelectionRequirement?: InputMaybe<EquipmentSelectionOptions>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobCostingPrompt?: InputMaybe<JobCostingPromptOptions>;
  level: Scalars['Int']['input'];
  manageBudgets: Scalars['Boolean']['input'];
  manageBulletinPosts?: InputMaybe<Scalars['Boolean']['input']>;
  manageCompanySettings: Scalars['Boolean']['input'];
  manageCostCodes: Scalars['Boolean']['input'];
  manageEmployeeGroups: Scalars['Boolean']['input'];
  manageEmployees: Scalars['Int']['input'];
  manageEquipment: Scalars['Boolean']['input'];
  manageGpsApproval?: InputMaybe<Scalars['Int']['input']>;
  manageIncident?: InputMaybe<Scalars['Boolean']['input']>;
  manageInspections?: InputMaybe<Scalars['Boolean']['input']>;
  manageMediaCollection: Scalars['Int']['input'];
  manageObservations?: InputMaybe<Scalars['Boolean']['input']>;
  managePerDiem?: InputMaybe<Scalars['Int']['input']>;
  manageProjectGroups: Scalars['Boolean']['input'];
  manageProjects: Scalars['Boolean']['input'];
  manageSchedule: Scalars['Int']['input'];
  manageTimeEntries: Scalars['Int']['input'];
  manageTimeOff?: InputMaybe<Scalars['Int']['input']>;
  manageTraining?: InputMaybe<Scalars['Boolean']['input']>;
  memberLock: Scalars['Int']['input'];
  organizationId: Scalars['Uuid']['input'];
  performInspections?: InputMaybe<Scalars['Boolean']['input']>;
  performTraining?: InputMaybe<Scalars['Boolean']['input']>;
  photoVerificationSettings?: InputMaybe<Scalars['String']['input']>;
  reportObservations?: InputMaybe<Scalars['Boolean']['input']>;
  requestSignatures: Scalars['Int']['input'];
  scopeByGroup: Scalars['Boolean']['input'];
  timeEvents: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  useKiosk?: InputMaybe<Scalars['Boolean']['input']>;
  viewAllActivityReport: Scalars['Boolean']['input'];
  viewBudgets?: InputMaybe<Scalars['Boolean']['input']>;
  viewEquipmentDetails: Scalars['Boolean']['input'];
  viewEquipmentStatus: Scalars['Boolean']['input'];
  viewMediaCollection?: InputMaybe<Scalars['Int']['input']>;
  viewMemberActivityReport: Scalars['Int']['input'];
  viewMemberExpenses: Scalars['Boolean']['input'];
  viewProjectExpenses: Scalars['Boolean']['input'];
  viewSchedule: Scalars['Int']['input'];
};

export type PositionFilter = {
  canEditPosition?: InputMaybe<BooleanComparison>;
  createIncident?: InputMaybe<BooleanComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  level?: InputMaybe<IntComparison>;
  manageBudgets?: InputMaybe<BooleanComparison>;
  manageBulletinPosts?: InputMaybe<BooleanComparison>;
  manageCompanySettings?: InputMaybe<BooleanComparison>;
  manageCostCodes?: InputMaybe<BooleanComparison>;
  manageEmployeeGroups?: InputMaybe<BooleanComparison>;
  manageEquipment?: InputMaybe<BooleanComparison>;
  manageIncident?: InputMaybe<BooleanComparison>;
  manageInspections?: InputMaybe<BooleanComparison>;
  manageObservations?: InputMaybe<BooleanComparison>;
  manageProjectGroups?: InputMaybe<BooleanComparison>;
  manageProjects?: InputMaybe<BooleanComparison>;
  manageTraining?: InputMaybe<BooleanComparison>;
  performInspections?: InputMaybe<BooleanComparison>;
  performTraining?: InputMaybe<BooleanComparison>;
  reportObservations?: InputMaybe<BooleanComparison>;
  scopeByGroup?: InputMaybe<BooleanComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  useKiosk?: InputMaybe<BooleanComparison>;
  viewBudgets?: InputMaybe<BooleanComparison>;
  viewMemberExpenses?: InputMaybe<BooleanComparison>;
};

export type PositionSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type PositionUpdateInput = {
  canManageProjectCostCodeQuantity?: InputMaybe<Scalars['Int']['input']>;
  canRequestTimeOff?: InputMaybe<Scalars['Boolean']['input']>;
  clockInRestriction?: InputMaybe<ClockInRestrictionType>;
  clockInRestrictionStartTime?: InputMaybe<Scalars['String']['input']>;
  createIncident?: InputMaybe<Scalars['Boolean']['input']>;
  dailyProjectPrompts?: InputMaybe<Scalars['Boolean']['input']>;
  dailyProjectPromptsUseSubprojects?: InputMaybe<Scalars['Boolean']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentSelectionRequirement?: InputMaybe<EquipmentSelectionOptions>;
  id: Scalars['ID']['input'];
  jobCostingPrompt?: InputMaybe<JobCostingPromptOptions>;
  level?: InputMaybe<Scalars['Int']['input']>;
  manageBudgets?: InputMaybe<Scalars['Boolean']['input']>;
  manageBulletinPosts?: InputMaybe<Scalars['Boolean']['input']>;
  manageCompanySettings?: InputMaybe<Scalars['Boolean']['input']>;
  manageCostCodes?: InputMaybe<Scalars['Boolean']['input']>;
  manageEmployeeGroups?: InputMaybe<Scalars['Boolean']['input']>;
  manageEmployees?: InputMaybe<Scalars['Int']['input']>;
  manageEquipment?: InputMaybe<Scalars['Boolean']['input']>;
  manageGpsApproval?: InputMaybe<Scalars['Int']['input']>;
  manageIncident?: InputMaybe<Scalars['Boolean']['input']>;
  manageInspections?: InputMaybe<Scalars['Boolean']['input']>;
  manageMediaCollection?: InputMaybe<Scalars['Int']['input']>;
  manageObservations?: InputMaybe<Scalars['Boolean']['input']>;
  managePerDiem?: InputMaybe<Scalars['Int']['input']>;
  manageProjectGroups?: InputMaybe<Scalars['Boolean']['input']>;
  manageProjects?: InputMaybe<Scalars['Boolean']['input']>;
  manageSchedule?: InputMaybe<Scalars['Int']['input']>;
  manageTimeEntries?: InputMaybe<Scalars['Int']['input']>;
  manageTimeOff?: InputMaybe<Scalars['Int']['input']>;
  manageTraining?: InputMaybe<Scalars['Boolean']['input']>;
  memberLock?: InputMaybe<Scalars['Int']['input']>;
  performInspections?: InputMaybe<Scalars['Boolean']['input']>;
  performTraining?: InputMaybe<Scalars['Boolean']['input']>;
  photoVerificationSettings?: InputMaybe<Scalars['String']['input']>;
  reportObservations?: InputMaybe<Scalars['Boolean']['input']>;
  requestSignatures?: InputMaybe<Scalars['Int']['input']>;
  scopeByGroup?: InputMaybe<Scalars['Boolean']['input']>;
  timeEvents?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  useKiosk?: InputMaybe<Scalars['Boolean']['input']>;
  viewAllActivityReport?: InputMaybe<Scalars['Boolean']['input']>;
  viewBudgets?: InputMaybe<Scalars['Boolean']['input']>;
  viewEquipmentDetails?: InputMaybe<Scalars['Boolean']['input']>;
  viewEquipmentStatus?: InputMaybe<Scalars['Boolean']['input']>;
  viewMediaCollection?: InputMaybe<Scalars['Int']['input']>;
  viewMemberActivityReport?: InputMaybe<Scalars['Int']['input']>;
  viewMemberExpenses?: InputMaybe<Scalars['Boolean']['input']>;
  viewProjectExpenses?: InputMaybe<Scalars['Boolean']['input']>;
  viewSchedule?: InputMaybe<Scalars['Int']['input']>;
};

export type Post = {
  __typename?: 'Post';
  acknowledgementRequired: Scalars['Boolean']['output'];
  allowComments: Scalars['Boolean']['output'];
  attachments: Array<PostAttachment>;
  comments: Array<PostComment>;
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  managementCanAccess: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['Uuid']['output'];
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars['Uuid']['output']>;
  postDate: Scalars['Timestamp']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  recipientType: PostRecipientTypes;
  recipients: Array<PostRecipient>;
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  type: PostTypes;
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type PostAttachment = {
  __typename?: 'PostAttachment';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  post: Post;
  postId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  uploadedBy: Scalars['Uuid']['output'];
  uploadedByMember: Member;
};

export type PostAttachmentCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  fileType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  postId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};

export type PostAttachmentFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  post?: InputMaybe<PostFilter>;
  postId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  uploadedBy?: InputMaybe<UuidComparison>;
  uploadedByMember?: InputMaybe<MemberFilter>;
};

export type PostAttachmentSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostSort>;
  postId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  uploadedBy?: InputMaybe<SortOrder>;
  uploadedByMember?: InputMaybe<MemberSort>;
};

export type PostAttachmentUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  postId?: InputMaybe<Scalars['Uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PostComment = {
  __typename?: 'PostComment';
  comment?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  post: Post;
  postId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type PostCommentCreateInput = {
  comment: Scalars['String']['input'];
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  postId: Scalars['Uuid']['input'];
};

export type PostCommentFilter = {
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  post?: InputMaybe<PostFilter>;
  postId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type PostCommentSort = {
  comment?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostSort>;
  postId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type PostCommentUpdateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type PostCreateInput = {
  acknowledgementRequired: Scalars['Boolean']['input'];
  allowComments: Scalars['Boolean']['input'];
  createdOn: Scalars['Timestamp']['input'];
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  managementCanAccess: Scalars['Boolean']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  postDate: Scalars['Timestamp']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  recipientType: PostRecipientTypes;
  title: Scalars['String']['input'];
  type: PostTypes;
};

export type PostFilter = {
  createdBy?: InputMaybe<UuidComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  position?: InputMaybe<PositionFilter>;
  positionId?: InputMaybe<UuidComparison>;
  postDate?: InputMaybe<TimestampComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  recipientType?: InputMaybe<StringComparison>;
  search?: InputMaybe<PostSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  type?: InputMaybe<StringComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type PostRecipient = {
  __typename?: 'PostRecipient';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  organizationId: Scalars['Uuid']['output'];
  post: Post;
  postId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type PostRecipientCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  postId: Scalars['Uuid']['input'];
};

export type PostRecipientFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  post?: InputMaybe<PostFilter>;
  postId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type PostRecipientSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  memberId?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostSort>;
  postId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum PostRecipientTypes {
  Everyone = 'everyone',
  Individuals = 'individuals',
  Position = 'position',
}

export type PostRecipientUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type PostSearch = {
  fields: Array<PostSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum PostSearchFields {
  Title = 'title',
}

export type PostSort = {
  createdBy?: InputMaybe<SortOrder>;
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  equipmentId?: InputMaybe<SortOrder>;
  position?: InputMaybe<PositionSort>;
  postDate?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  projectId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type PostStatus = {
  __typename?: 'PostStatus';
  unreadComments: Scalars['Int']['output'];
  unreadPosts: Scalars['Int']['output'];
};

export enum PostTypes {
  Bulletin = 'bulletin',
}

export type PostUpdateInput = {
  acknowledgementRequired?: InputMaybe<Scalars['Boolean']['input']>;
  allowComments?: InputMaybe<Scalars['Boolean']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id: Scalars['ID']['input'];
  managementCanAccess?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  positionId?: InputMaybe<Scalars['Uuid']['input']>;
  postDate?: InputMaybe<Scalars['Timestamp']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  recipientType?: InputMaybe<PostRecipientTypes>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PostTypes>;
};

export type PostViewer = {
  __typename?: 'PostViewer';
  acknowledged?: Maybe<Scalars['Boolean']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  latestCommentUpdatedOn?: Maybe<Scalars['Timestamp']['output']>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  organizationId: Scalars['Uuid']['output'];
  post: Post;
  postId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type PostViewerCreateInput = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  latestCommentUpdatedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  memberId: Scalars['Uuid']['input'];
  postId: Scalars['Uuid']['input'];
};

export type PostViewerFilter = {
  acknowledged?: InputMaybe<BooleanComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  post?: InputMaybe<PostFilter>;
  postId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type PostViewerSort = {
  acknowledged?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  memberId?: InputMaybe<SortOrder>;
  post?: InputMaybe<PostSort>;
  postId?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type PostViewerUpdateInput = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  latestCommentUpdatedOn?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type ProgressBudget = {
  __typename?: 'ProgressBudget';
  costCode: CostCode;
  costCodeId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  project: Project;
  projectId: Scalars['Uuid']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type ProgressBudgetCreateInput = {
  costCodeId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['Uuid']['input'];
  quantity: Scalars['Float']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type ProgressBudgetFilter = {
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  quantity?: InputMaybe<FloatComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
  value?: InputMaybe<FloatComparison>;
};

export type ProgressBudgetSort = {
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  quantity?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ProgressBudgetUpdateInput = {
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type Project = {
  __typename?: 'Project';
  allAccess?: Maybe<Scalars['Boolean']['output']>;
  ancestors?: Maybe<Array<Project>>;
  archivedOn?: Maybe<Scalars['Timestamp']['output']>;
  children?: Maybe<Array<Project>>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  openTimeEntries?: Maybe<Array<TimeEntry>>;
  organizationId: Scalars['Uuid']['output'];
  parentProject?: Maybe<Project>;
  parentProjectId?: Maybe<Scalars['Uuid']['output']>;
  projectCostCodeEquipmentLaborMetrics: Array<ProjectCostCodeEquipmentLaborMetrics>;
  projectCostCodeLaborMetrics: Array<ProjectCostCodeLaborMetrics>;
  projectCostCodeLink?: Maybe<Array<ProjectCostCodeLink>>;
  projectEquipmentLaborMetrics: Array<ProjectEquipmentLaborMetrics>;
  projectGroup?: Maybe<ProjectGroup>;
  projectGroupId?: Maybe<Scalars['Uuid']['output']>;
  projectInfo?: Maybe<ProjectInfo>;
  projectLaborMetrics: Array<ProjectLaborMetrics>;
  projectMemberCostCodeEquipmentLaborMetrics: Array<MemberProjectCostCodeEquipmentLaborMetrics>;
  projectMemberCostCodeLaborMetrics: Array<MemberProjectCostCodeLaborMetrics>;
  projectMemberEquipmentLaborMetrics: Array<MemberProjectEquipmentLaborMetrics>;
  projectMemberLaborMetrics: Array<ProjectMemberLaborMetrics>;
  rootProject?: Maybe<Project>;
  rootProjectId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  taskLoggable?: Maybe<Scalars['Boolean']['output']>;
  timeLoggable?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectProjectCostCodeEquipmentLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type ProjectProjectCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type ProjectProjectEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type ProjectProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type ProjectProjectMemberCostCodeEquipmentLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type ProjectProjectMemberCostCodeLaborMetricsArgs = {
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type ProjectProjectMemberEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type ProjectProjectMemberLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  start: Scalars['Date']['input'];
};

export type ProjectClockState = {
  __typename?: 'ProjectClockState';
  all: Scalars['Int']['output'];
  clockedIn: Scalars['Int']['output'];
  clockedInNotOnBreak: Scalars['Int']['output'];
  clockedOut: Scalars['Int']['output'];
  onBreak: Scalars['Int']['output'];
};

export type ProjectClockStateFilter = {
  costCodeGroupId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  depth?: InputMaybe<Scalars['Int']['input']>;
  equipmentCategoryId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  memberGroupId?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  projectGroupId?: InputMaybe<UuidComparison>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  status?: InputMaybe<ActiveStatusComparison>;
};

export type ProjectCostBudget = {
  __typename?: 'ProjectCostBudget';
  costBudget?: Maybe<Scalars['Float']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['Uuid']['output']>;
  project: Project;
  projectId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectCostBudgetCreateInput = {
  costBudget: Scalars['Float']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  projectId: Scalars['Uuid']['input'];
};

export type ProjectCostCodeEquipmentLaborMetrics = EquipmentProjectLaborMetricsInterface &
  LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId?: Maybe<Scalars['Uuid']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descEquipmentCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type ProjectCostCodeLaborMetrics = LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'ProjectCostCodeLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    costCodeId?: Maybe<Scalars['Uuid']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type ProjectCostCodeLink = {
  __typename?: 'ProjectCostCodeLink';
  costCode: CostCode;
  costCodeId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  project: Project;
  projectId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectCostCodeLinkCreateInput = {
  costCodeId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['Uuid']['input'];
};

export type ProjectCostCodeLinkFilter = {
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ProjectCostCodeLinkSort = {
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ProjectCostCodeLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type ProjectCostCodeQuantity = {
  __typename?: 'ProjectCostCodeQuantity';
  amount: Scalars['Float']['output'];
  assignedOn: Scalars['Timestamp']['output'];
  costCode: CostCode;
  costCodeId: Scalars['Uuid']['output'];
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastEditedBy?: Maybe<Scalars['Uuid']['output']>;
  lastEditedByMember?: Maybe<Member>;
  project: Project;
  projectId: Scalars['Uuid']['output'];
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectCostCodeQuantityCreateInput = {
  amount: Scalars['Float']['input'];
  assignedOn: Scalars['Timestamp']['input'];
  costCodeId: Scalars['Uuid']['input'];
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDst: Scalars['Boolean']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['Uuid']['input'];
  submittedBy: Scalars['Uuid']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCostCodeQuantityFilter = {
  assignedOn?: InputMaybe<TimestampComparison>;
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ProjectCostCodeQuantitySort = {
  assignedOn?: InputMaybe<SortOrder>;
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  project?: InputMaybe<ProjectSort>;
  submittedByMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ProjectCostCodeQuantityUpdateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  assignedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateInput = {
  allAccess?: InputMaybe<Scalars['Boolean']['input']>;
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
  parentProjectId?: InputMaybe<Scalars['Uuid']['input']>;
  projectGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  taskLoggable?: InputMaybe<Scalars['Boolean']['input']>;
  timeLoggable?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type ProjectEquipmentLaborMetrics = EquipmentProjectLaborMetricsInterface &
  LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'ProjectEquipmentLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descEquipmentCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    equipmentCents?: Maybe<Scalars['Long']['output']>;
    equipmentId?: Maybe<Scalars['Uuid']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId: Scalars['Uuid']['output'];
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type ProjectFilter = {
  archivedOn?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  depth?: InputMaybe<IntComparison>;
  hasQuantity?: InputMaybe<ProjectHasQuantityFilter>;
  id?: InputMaybe<UuidComparison>;
  idWithAncestors?: InputMaybe<UuidComparison>;
  idWithDescendants?: InputMaybe<UuidComparison>;
  openTimeEntries?: InputMaybe<TimeEntryFilter>;
  parentProject?: InputMaybe<ProjectFilter>;
  parentProjectId?: InputMaybe<UuidComparison>;
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkFilter>;
  projectGroup?: InputMaybe<ProjectGroupFilter>;
  projectGroupId?: InputMaybe<UuidComparison>;
  projectGroupIdWithDescendants?: InputMaybe<UuidComparison>;
  projectInfo?: InputMaybe<ProjectInfoFilter>;
  quantities?: InputMaybe<ProjectCostCodeQuantityFilter>;
  rootProject?: InputMaybe<ProjectFilter>;
  rootProjectId?: InputMaybe<UuidComparison>;
  search?: InputMaybe<ProjectSearch>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ProjectGroup = {
  __typename?: 'ProjectGroup';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectGroupCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  groupName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['Uuid']['input'];
};

export type ProjectGroupFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  groupName?: InputMaybe<StringComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ProjectGroupSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  groupName?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ProjectGroupUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type ProjectHasQuantityFilter = {
  endTime: Scalars['Timestamp']['input'];
  includeDescendants: Scalars['Boolean']['input'];
  startTime: Scalars['Timestamp']['input'];
};

export type ProjectHoursBudget = {
  __typename?: 'ProjectHoursBudget';
  budgetSeconds: Scalars['Long']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['Uuid']['output']>;
  project: Project;
  projectId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectHoursBudgetCreateInput = {
  budgetSeconds: Scalars['Long']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  projectId: Scalars['Uuid']['input'];
};

export type ProjectInfo = {
  __typename?: 'ProjectInfo';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  distantLocationFlaggingDisabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  locationRadius?: Maybe<Scalars['Int']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  project: Project;
  projectId: Scalars['Uuid']['output'];
  reminder?: Maybe<Scalars['Boolean']['output']>;
  requireTimeEntryGps?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ProjectInfoCreateInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  customer?: InputMaybe<Scalars['String']['input']>;
  distantLocationFlaggingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locationRadius?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Uuid']['input'];
  reminder?: InputMaybe<Scalars['Boolean']['input']>;
  requireTimeEntryGps?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectInfoFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  customer?: InputMaybe<StringComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  latitude?: InputMaybe<FloatComparison>;
  longitude?: InputMaybe<FloatComparison>;
  number?: InputMaybe<StringComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ProjectInfoSort = {
  address1?: InputMaybe<SortOrder>;
  address2?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  distantLocationFlaggingDisabled?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  locationRadius?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  projectId?: InputMaybe<SortOrder>;
  reminder?: InputMaybe<SortOrder>;
  requireTimeEntryGps?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ProjectInfoUpdateInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  distantLocationFlaggingDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locationRadius?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  reminder?: InputMaybe<Scalars['Boolean']['input']>;
  requireTimeEntryGps?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectLaborMetrics = LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'ProjectLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    id?: Maybe<Scalars['String']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId?: Maybe<Scalars['Uuid']['output']>;
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type ProjectLaborMetricsInterface = {
  breakDt?: Maybe<Scalars['Long']['output']>;
  breakDtCents?: Maybe<Scalars['Long']['output']>;
  breakOt?: Maybe<Scalars['Long']['output']>;
  breakOtCents?: Maybe<Scalars['Long']['output']>;
  breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  breakRt?: Maybe<Scalars['Long']['output']>;
  breakRtCents?: Maybe<Scalars['Long']['output']>;
  breakUpt?: Maybe<Scalars['Long']['output']>;
  descBreakDt?: Maybe<Scalars['Long']['output']>;
  descBreakDtCents?: Maybe<Scalars['Long']['output']>;
  descBreakOt?: Maybe<Scalars['Long']['output']>;
  descBreakOtCents?: Maybe<Scalars['Long']['output']>;
  descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
  descBreakRt?: Maybe<Scalars['Long']['output']>;
  descBreakRtCents?: Maybe<Scalars['Long']['output']>;
  descBreakUpt?: Maybe<Scalars['Long']['output']>;
  descDt?: Maybe<Scalars['Long']['output']>;
  descDtCents?: Maybe<Scalars['Long']['output']>;
  descOt?: Maybe<Scalars['Long']['output']>;
  descOtCents?: Maybe<Scalars['Long']['output']>;
  descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
  descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
  descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
  descRt?: Maybe<Scalars['Long']['output']>;
  descRtCents?: Maybe<Scalars['Long']['output']>;
  dt?: Maybe<Scalars['Long']['output']>;
  dtCents?: Maybe<Scalars['Long']['output']>;
  end: Scalars['Date']['output'];
  ot?: Maybe<Scalars['Long']['output']>;
  otCents?: Maybe<Scalars['Long']['output']>;
  overburdenDtCents?: Maybe<Scalars['Long']['output']>;
  overburdenOtCents?: Maybe<Scalars['Long']['output']>;
  overburdenRtCents?: Maybe<Scalars['Long']['output']>;
  rt?: Maybe<Scalars['Long']['output']>;
  rtCents?: Maybe<Scalars['Long']['output']>;
  start: Scalars['Date']['output'];
};

export type ProjectMemberLaborMetrics = LaborMetricsInterface &
  ProjectLaborMetricsInterface & {
    __typename?: 'ProjectMemberLaborMetrics';
    breakDt?: Maybe<Scalars['Long']['output']>;
    breakDtCents?: Maybe<Scalars['Long']['output']>;
    breakOt?: Maybe<Scalars['Long']['output']>;
    breakOtCents?: Maybe<Scalars['Long']['output']>;
    breakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    breakRt?: Maybe<Scalars['Long']['output']>;
    breakRtCents?: Maybe<Scalars['Long']['output']>;
    breakUpt?: Maybe<Scalars['Long']['output']>;
    descBreakDt?: Maybe<Scalars['Long']['output']>;
    descBreakDtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOt?: Maybe<Scalars['Long']['output']>;
    descBreakOtCents?: Maybe<Scalars['Long']['output']>;
    descBreakOverburdenCents?: Maybe<Scalars['Long']['output']>;
    descBreakRt?: Maybe<Scalars['Long']['output']>;
    descBreakRtCents?: Maybe<Scalars['Long']['output']>;
    descBreakUpt?: Maybe<Scalars['Long']['output']>;
    descDt?: Maybe<Scalars['Long']['output']>;
    descDtCents?: Maybe<Scalars['Long']['output']>;
    descOt?: Maybe<Scalars['Long']['output']>;
    descOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenDtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenOtCents?: Maybe<Scalars['Long']['output']>;
    descOverburdenRtCents?: Maybe<Scalars['Long']['output']>;
    descRt?: Maybe<Scalars['Long']['output']>;
    descRtCents?: Maybe<Scalars['Long']['output']>;
    dt?: Maybe<Scalars['Long']['output']>;
    dtCents?: Maybe<Scalars['Long']['output']>;
    end: Scalars['Date']['output'];
    id?: Maybe<Scalars['String']['output']>;
    memberId?: Maybe<Scalars['Uuid']['output']>;
    ot?: Maybe<Scalars['Long']['output']>;
    otCents?: Maybe<Scalars['Long']['output']>;
    overburdenDtCents?: Maybe<Scalars['Long']['output']>;
    overburdenOtCents?: Maybe<Scalars['Long']['output']>;
    overburdenRtCents?: Maybe<Scalars['Long']['output']>;
    projectId: Scalars['Uuid']['output'];
    rt?: Maybe<Scalars['Long']['output']>;
    rtCents?: Maybe<Scalars['Long']['output']>;
    start: Scalars['Date']['output'];
  };

export type ProjectSearch = {
  fields: Array<ProjectSearchFields>;
  type: SearchType;
  value: Scalars['NotEmptyString']['input'];
};

export enum ProjectSearchFields {
  Customer = 'customer',
  Number = 'number',
  Title = 'title',
}

export type ProjectSort = {
  archivedOn?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  depth?: InputMaybe<SortOrder>;
  openTimeEntries?: InputMaybe<TimeEntrySort>;
  parentProject?: InputMaybe<ProjectSort>;
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkSort>;
  projectGroup?: InputMaybe<ProjectGroupSort>;
  projectInfo?: InputMaybe<ProjectInfoSort>;
  rootProject?: InputMaybe<ProjectSort>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ProjectUpdateInput = {
  allAccess?: InputMaybe<Scalars['Boolean']['input']>;
  archivedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  projectGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  taskLoggable?: InputMaybe<Scalars['Boolean']['input']>;
  timeLoggable?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  authoritativeSignatures?: Maybe<Array<AuthoritativeSignature>>;
  budgetCosts?: Maybe<Array<BudgetCost>>;
  budgetHours?: Maybe<Array<BudgetHours>>;
  busySignInQuestions?: Maybe<Array<BusySignInQuestion>>;
  clientTimeEntryBreakLogs?: Maybe<Array<ClientTimeEntryBreakLog>>;
  clientTimeEntryLogs?: Maybe<Array<ClientTimeEntryLog>>;
  costCodeClockState: CostCodeClockState;
  costCodeGroups?: Maybe<Array<CostCodeGroup>>;
  costCodeLaborMetrics: Array<CostCodeLaborMetrics>;
  costCodes?: Maybe<Array<CostCode>>;
  dailyProjectReportTemplates?: Maybe<Array<DailyProjectReportTemplate>>;
  dailyProjectReports?: Maybe<Array<DailyProjectReport>>;
  equipment?: Maybe<Array<Equipment>>;
  equipmentCategories?: Maybe<Array<EquipmentCategory>>;
  equipmentClockState: EquipmentClockState;
  equipmentCostHistory?: Maybe<Array<EquipmentCostHistory>>;
  equipmentHours?: Maybe<Array<EquipmentHours>>;
  equipmentLaborMetrics: Array<EquipmentLaborMetrics>;
  equipmentLocations?: Maybe<Array<EquipmentLocation>>;
  equipmentMakes?: Maybe<Array<EquipmentMake>>;
  equipmentModelTitles?: Maybe<Array<Scalars['String']['output']>>;
  equipmentModels?: Maybe<Array<EquipmentModel>>;
  equipmentTypes?: Maybe<Array<EquipmentType>>;
  flaggedTimeEntryLocations?: Maybe<Array<FlaggedTimeEntryLocation>>;
  folders?: Maybe<Array<Folder>>;
  incidentAnswers?: Maybe<Array<IncidentAnswer>>;
  incidentMedicalDetails?: Maybe<Array<IncidentMedicalDetails>>;
  incidentOutcomeDetails?: Maybe<Array<IncidentOutcomeDetails>>;
  incidents?: Maybe<Array<Incident>>;
  inspectionAssignmentCategoryLinks?: Maybe<Array<InspectionAssignmentCategoryLink>>;
  inspectionAssignmentNotificationLinks?: Maybe<Array<InspectionAssignmentNotificationLink>>;
  inspectionAssignments?: Maybe<Array<InspectionAssignment>>;
  inspectionCategories?: Maybe<Array<InspectionCategory>>;
  inspectionChecklistMemberLinks?: Maybe<Array<InspectionChecklistMemberLink>>;
  inspectionChecklists?: Maybe<Array<InspectionChecklist>>;
  inspectionQuestionAnswerAttachments?: Maybe<Array<InspectionQuestionAnswerAttachment>>;
  inspectionQuestionAnswers?: Maybe<Array<InspectionQuestionAnswer>>;
  inspectionQuestions?: Maybe<Array<InspectionQuestion>>;
  inspections?: Maybe<Array<Inspection>>;
  mediaCollections?: Maybe<Array<MediaCollection>>;
  mediaEntries?: Maybe<Array<MediaEntry>>;
  mediaEntryComments?: Maybe<Array<MediaEntryComment>>;
  mediaEntryLocations?: Maybe<Array<MediaEntryLocation>>;
  memberClockState: MemberClockState;
  memberGpsStatuses?: Maybe<Array<MemberGpsStatus>>;
  memberGroups?: Maybe<Array<MemberGroup>>;
  memberLaborMetrics: Array<MemberLaborMetrics>;
  memberLocations?: Maybe<Array<MemberLocation>>;
  memberLocks?: Maybe<Array<MemberLock>>;
  memberOvertimePeriods?: Maybe<Array<MemberOvertimePeriod>>;
  memberProjectCostCodeEquipmentLaborMetrics: Array<MemberProjectCostCodeEquipmentLaborMetrics>;
  memberSignInAnswers?: Maybe<Array<MemberSignInAnswer>>;
  memberSignInSubmissions?: Maybe<Array<MemberSignInSubmission>>;
  memberSignOffAnswers?: Maybe<Array<MemberSignOffAnswer>>;
  memberTimeDocuments?: Maybe<Array<MemberTimeDocument>>;
  members?: Maybe<Array<Member>>;
  myPosts?: Maybe<Array<Post>>;
  observationAnswers?: Maybe<Array<ObservationAnswer>>;
  observationAttachments?: Maybe<Array<ObservationAttachment>>;
  observationCategories?: Maybe<Array<ObservationCategory>>;
  observations?: Maybe<Array<Observation>>;
  organization: Organization;
  organizationDocumentCount: Scalars['Int']['output'];
  organizationDocuments?: Maybe<Array<OrganizationDocument>>;
  organizationInfo: OrganizationInfo;
  organizationLaborMetrics: Array<OrganizationLaborMetrics>;
  organizationNotificationPreferences?: Maybe<Array<OrganizationNotificationPreferences>>;
  organizationOvertimePeriods?: Maybe<Array<OrganizationOvertimePeriod>>;
  organizationPayPeriods?: Maybe<Array<OrganizationPayPeriod>>;
  organizationSignInQuestions?: Maybe<Array<OrganizationSignInQuestion>>;
  organizationSignOffQuestions?: Maybe<Array<OrganizationSignOffQuestion>>;
  organizationSubscriptionStatus: OrganizationSubscriptionStatus;
  perDiems?: Maybe<Array<PerDiem>>;
  policies?: Maybe<Array<Policy>>;
  policyMemberAcceptances?: Maybe<Array<PolicyMemberAcceptance>>;
  positions?: Maybe<Array<Position>>;
  postAttachments?: Maybe<Array<PostAttachment>>;
  postComments?: Maybe<Array<PostComment>>;
  postRecipients?: Maybe<Array<PostRecipient>>;
  postStatus: PostStatus;
  postViewers?: Maybe<Array<PostViewer>>;
  posts?: Maybe<Array<Post>>;
  progressBudgets?: Maybe<Array<ProgressBudget>>;
  projectClockState: ProjectClockState;
  projectCostCodeEquipmentLaborMetrics: Array<ProjectCostCodeEquipmentLaborMetrics>;
  projectCostCodeLaborMetrics: Array<ProjectCostCodeLaborMetrics>;
  projectCostCodeLinks?: Maybe<Array<ProjectCostCodeLink>>;
  projectCostCodeQuantities?: Maybe<Array<ProjectCostCodeQuantity>>;
  projectGroups?: Maybe<Array<ProjectGroup>>;
  projectInfos?: Maybe<Array<ProjectInfo>>;
  projectLaborMetrics: Array<ProjectLaborMetrics>;
  projects?: Maybe<Array<Project>>;
  projectsWithTime?: Maybe<Array<Project>>;
  recurringInspectionAssignmentCategoryLinks?: Maybe<Array<RecurringInspectionAssignmentCategoryLink>>;
  recurringInspectionAssignmentNotificationLinks?: Maybe<Array<RecurringInspectionAssignmentNotificationLink>>;
  recurringInspectionAssignments?: Maybe<Array<RecurringInspectionAssignment>>;
  recurringScheduleReminderLinks?: Maybe<Array<RecurringScheduleReminderLink>>;
  recurringScheduleReminders?: Maybe<Array<RecurringScheduleReminder>>;
  safetyInspectionCategories?: Maybe<Array<SafetyInspectionCategory>>;
  safetyInspectionChecklists?: Maybe<Array<SafetyInspectionChecklist>>;
  safetyReportsCategories?: Maybe<Array<SafetyReportsCategory>>;
  safetyReportsSources?: Maybe<Array<SafetyReportsSource>>;
  safetyReportsTrainingMaterials?: Maybe<Array<SafetyReportsTrainingMaterial>>;
  safetySignatures?: Maybe<Array<SafetySignature>>;
  safetyTrainingAttachments?: Maybe<Array<SafetyTrainingAttachment>>;
  safetyTrainingAttendees?: Maybe<Array<SafetyTrainingAttendee>>;
  safetyTrainingCategories?: Maybe<Array<SafetyTrainingCategory>>;
  safetyTrainingDocuments?: Maybe<Array<SafetyTrainingDocument>>;
  safetyTrainingIndustries?: Maybe<Array<SafetyTrainingIndustry>>;
  safetyTrainings?: Maybe<Array<SafetyTraining>>;
  scheduleReminderLinks?: Maybe<Array<ScheduleReminderLink>>;
  scheduleReminders?: Maybe<Array<ScheduleReminder>>;
  selfSignatures?: Maybe<Array<SelfSignature>>;
  tagDocumentLinks?: Maybe<Array<TagDocumentLink>>;
  tagMediaCollectionLinks?: Maybe<Array<TagMediaCollectionLink>>;
  tags?: Maybe<Array<Tag>>;
  timeEntries?: Maybe<Array<TimeEntry>>;
  timeEntryBreaks?: Maybe<Array<TimeEntryBreak>>;
  timeEntryRekognition?: Maybe<Array<TimeEntryRekognition>>;
  timeOffRequests?: Maybe<Array<TimeOffRequest>>;
  timeOffs?: Maybe<Array<TimeOff>>;
  wageHistories?: Maybe<Array<WageHistory>>;
  weather?: Maybe<Weather>;
};

export type QueryAuthoritativeSignaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AuthoritativeSignatureFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<AuthoritativeSignatureSort>>;
};

export type QueryBudgetCostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BudgetCostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<BudgetCostSort>>;
};

export type QueryBudgetHoursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BudgetHoursFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<BudgetHoursSort>>;
};

export type QueryBusySignInQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BusySignInQuestionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<BusySignInQuestionSort>>;
};

export type QueryClientTimeEntryBreakLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClientTimeEntryBreakLogFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClientTimeEntryBreakLogSort>>;
};

export type QueryClientTimeEntryLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClientTimeEntryLogFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ClientTimeEntryLogSort>>;
};

export type QueryCostCodeClockStateArgs = {
  filter?: InputMaybe<CostCodeClockStateFilter>;
};

export type QueryCostCodeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CostCodeGroupSort>>;
};

export type QueryCostCodeLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryCostCodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CostCodeSort>>;
};

export type QueryDailyProjectReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DailyProjectReportTemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DailyProjectReportTemplateSort>>;
};

export type QueryDailyProjectReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DailyProjectReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DailyProjectReportSort>>;
};

export type QueryEquipmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort>>;
};

export type QueryEquipmentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentCategorySort>>;
};

export type QueryEquipmentClockStateArgs = {
  filter?: InputMaybe<EquipmentClockStateFilter>;
};

export type QueryEquipmentCostHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentCostHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentCostHistorySort>>;
};

export type QueryEquipmentHoursArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentHoursFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentHoursSort>>;
};

export type QueryEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryEquipmentLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentLocationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentLocationSort>>;
};

export type QueryEquipmentMakesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentMakeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentMakeSort>>;
};

export type QueryEquipmentModelTitlesArgs = {
  equipmentCategoryId: Scalars['Uuid']['input'];
  equipmentMakeId: Scalars['Uuid']['input'];
};

export type QueryEquipmentModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentModelFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentModelSort>>;
};

export type QueryEquipmentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentTypeSort>>;
};

export type QueryFlaggedTimeEntryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FlaggedTimeEntryLocationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FlaggedTimeEntryLocationSort>>;
};

export type QueryFoldersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FolderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<FolderSort>>;
};

export type QueryIncidentAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IncidentAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IncidentAnswerSort>>;
};

export type QueryIncidentMedicalDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IncidentMedicalDetailsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IncidentMedicalDetailsSort>>;
};

export type QueryIncidentOutcomeDetailsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IncidentOutcomeDetailsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IncidentOutcomeDetailsSort>>;
};

export type QueryIncidentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IncidentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<IncidentSort>>;
};

export type QueryInspectionAssignmentCategoryLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionAssignmentCategoryLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionAssignmentCategoryLinkSort>>;
};

export type QueryInspectionAssignmentNotificationLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionAssignmentNotificationLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionAssignmentNotificationLinkSort>>;
};

export type QueryInspectionAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionAssignmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionAssignmentSort>>;
};

export type QueryInspectionCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionCategorySort>>;
};

export type QueryInspectionChecklistMemberLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionChecklistMemberLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionChecklistMemberLinkSort>>;
};

export type QueryInspectionChecklistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionChecklistFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionChecklistSort>>;
};

export type QueryInspectionQuestionAnswerAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionQuestionAnswerAttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionQuestionAnswerAttachmentSort>>;
};

export type QueryInspectionQuestionAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionQuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionQuestionAnswerSort>>;
};

export type QueryInspectionQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionQuestionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionQuestionSort>>;
};

export type QueryInspectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InspectionSort>>;
};

export type QueryMediaCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaCollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MediaCollectionSort>>;
};

export type QueryMediaEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MediaEntrySort>>;
};

export type QueryMediaEntryCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaEntryCommentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MediaEntryCommentSort>>;
};

export type QueryMediaEntryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaEntryLocationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MediaEntryLocationSort>>;
};

export type QueryMemberClockStateArgs = {
  filter?: InputMaybe<MemberClockStateFilter>;
};

export type QueryMemberGpsStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberGpsStatusFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberGpsStatusSort>>;
};

export type QueryMemberGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberGroupSort>>;
};

export type QueryMemberLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryMemberLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberLocationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberLocationSort>>;
};

export type QueryMemberLocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberLockFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberLockSort>>;
};

export type QueryMemberOvertimePeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberOvertimePeriodFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberOvertimePeriodSort>>;
};

export type QueryMemberProjectCostCodeEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryMemberSignInAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberSignInAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberSignInAnswerSort>>;
};

export type QueryMemberSignInSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberSignInSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberSignInSubmissionSort>>;
};

export type QueryMemberSignOffAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberSignOffAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberSignOffAnswerSort>>;
};

export type QueryMemberTimeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberTimeDocumentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberTimeDocumentSort>>;
};

export type QueryMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberSort>>;
};

export type QueryMyPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PostSort>>;
};

export type QueryObservationAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ObservationAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ObservationAnswerSort>>;
};

export type QueryObservationAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ObservationAttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ObservationAttachmentSort>>;
};

export type QueryObservationCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ObservationCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ObservationCategorySort>>;
};

export type QueryObservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ObservationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ObservationSort>>;
};

export type QueryOrganizationDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationDocumentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationDocumentSort>>;
};

export type QueryOrganizationLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryOrganizationNotificationPreferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationNotificationPreferencesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationNotificationPreferencesSort>>;
};

export type QueryOrganizationOvertimePeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationOvertimePeriodFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationOvertimePeriodSort>>;
};

export type QueryOrganizationPayPeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationPayPeriodFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationPayPeriodSort>>;
};

export type QueryOrganizationSignInQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationSignInQuestionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationSignInQuestionSort>>;
};

export type QueryOrganizationSignOffQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationSignOffQuestionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationSignOffQuestionSort>>;
};

export type QueryPerDiemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PerDiemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PerDiemSort>>;
};

export type QueryPoliciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PolicySort>>;
};

export type QueryPolicyMemberAcceptancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyMemberAcceptanceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PolicyMemberAcceptanceSort>>;
};

export type QueryPositionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PositionSort>>;
};

export type QueryPostAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostAttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PostAttachmentSort>>;
};

export type QueryPostCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostCommentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PostCommentSort>>;
};

export type QueryPostRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostRecipientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PostRecipientSort>>;
};

export type QueryPostStatusArgs = {
  postType: PostTypes;
};

export type QueryPostViewersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostViewerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PostViewerSort>>;
};

export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<PostSort>>;
};

export type QueryProgressBudgetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProgressBudgetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProgressBudgetSort>>;
};

export type QueryProjectClockStateArgs = {
  filter?: InputMaybe<ProjectClockStateFilter>;
};

export type QueryProjectCostCodeEquipmentLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryProjectCostCodeLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryProjectCostCodeLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectCostCodeLinkSort>>;
};

export type QueryProjectCostCodeQuantitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeQuantityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectCostCodeQuantitySort>>;
};

export type QueryProjectGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectGroupSort>>;
};

export type QueryProjectInfosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectInfoFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectInfoSort>>;
};

export type QueryProjectLaborMetricsArgs = {
  end: Scalars['Date']['input'];
  filter?: InputMaybe<LaborMetricFilter>;
  interval: LaborMetricsInterval;
  start: Scalars['Date']['input'];
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectSort>>;
};

export type QueryProjectsWithTimeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  costCodeId?: InputMaybe<UuidComparison>;
  endTime: Scalars['Timestamp']['input'];
  equipmentId?: InputMaybe<UuidComparison>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ProjectSort>>;
  startTime: Scalars['Timestamp']['input'];
};

export type QueryRecurringInspectionAssignmentCategoryLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecurringInspectionAssignmentCategoryLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RecurringInspectionAssignmentCategoryLinkSort>>;
};

export type QueryRecurringInspectionAssignmentNotificationLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecurringInspectionAssignmentNotificationLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RecurringInspectionAssignmentNotificationLinkSort>>;
};

export type QueryRecurringInspectionAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecurringInspectionAssignmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RecurringInspectionAssignmentSort>>;
};

export type QueryRecurringScheduleReminderLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecurringScheduleReminderLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RecurringScheduleReminderLinkSort>>;
};

export type QueryRecurringScheduleRemindersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RecurringScheduleReminderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<RecurringScheduleReminderSort>>;
};

export type QuerySafetyInspectionCategoriesArgs = {
  reportType: Scalars['String']['input'];
};

export type QuerySafetyReportsCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetyReportsCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetyReportsCategorySort>>;
};

export type QuerySafetyReportsSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetyReportsSourceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetyReportsSourceSort>>;
};

export type QuerySafetyReportsTrainingMaterialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetyReportsTrainingMaterialFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetyReportsTrainingMaterialSort>>;
};

export type QuerySafetySignaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetySignatureFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetySignatureSort>>;
};

export type QuerySafetyTrainingAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetyTrainingAttachmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetyTrainingAttachmentSort>>;
};

export type QuerySafetyTrainingAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetyTrainingAttendeeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetyTrainingAttendeeSort>>;
};

export type QuerySafetyTrainingCategoriesArgs = {
  industryId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySafetyTrainingDocumentsArgs = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  industryId?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySafetyTrainingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetyTrainingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SafetyTrainingSort>>;
};

export type QueryScheduleReminderLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScheduleReminderLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ScheduleReminderLinkSort>>;
};

export type QueryScheduleRemindersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScheduleReminderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<ScheduleReminderSort>>;
};

export type QuerySelfSignaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SelfSignatureFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<SelfSignatureSort>>;
};

export type QueryTagDocumentLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TagDocumentLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagDocumentLinkSort>>;
};

export type QueryTagMediaCollectionLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TagMediaCollectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagMediaCollectionLinkSort>>;
};

export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TagSort>>;
};

export type QueryTimeEntriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort>>;
};

export type QueryTimeEntryBreaksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryBreakFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntryBreakSort>>;
};

export type QueryTimeEntryRekognitionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryRekognitionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntryRekognitionSort>>;
};

export type QueryTimeOffRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffRequestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeOffRequestSort>>;
};

export type QueryTimeOffsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeOffSort>>;
};

export type QueryWageHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<WageHistoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<WageHistorySort>>;
};

export type QueryWeatherArgs = {
  endTime: Scalars['DateTime']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type RecurringInspectionAssignment = {
  __typename?: 'RecurringInspectionAssignment';
  checklist: InspectionChecklist;
  checklistId: Scalars['Uuid']['output'];
  circumstanceValue?: Maybe<Scalars['Int']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Timestamp']['output']>;
  frequency: RecurringInspectionAssignmentFrequencyTypes;
  friday?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  limitCategories: Scalars['Boolean']['output'];
  limitedCategories: Array<RecurringInspectionAssignmentCategoryLink>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  monday?: Maybe<Scalars['Boolean']['output']>;
  monthOfYear?: Maybe<Scalars['Int']['output']>;
  notificationLinks: Array<RecurringInspectionAssignmentNotificationLink>;
  notificationTime?: Maybe<Scalars['String']['output']>;
  notifyCircumstance?: Maybe<RecurringInspectionNotifyCircumstanceTypes>;
  notifySubmission: Scalars['Boolean']['output'];
  organizationId: Scalars['Uuid']['output'];
  pausedOn?: Maybe<Scalars['Timestamp']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  resumedOn?: Maybe<Scalars['Timestamp']['output']>;
  saturday?: Maybe<Scalars['Boolean']['output']>;
  sendNotification: Scalars['Boolean']['output'];
  startDate: Scalars['Timestamp']['output'];
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  sunday?: Maybe<Scalars['Boolean']['output']>;
  thursday?: Maybe<Scalars['Boolean']['output']>;
  tuesday?: Maybe<Scalars['Boolean']['output']>;
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
  wednesday?: Maybe<Scalars['Boolean']['output']>;
};

export type RecurringInspectionAssignmentCategoryLink = {
  __typename?: 'RecurringInspectionAssignmentCategoryLink';
  assignment: RecurringInspectionAssignment;
  assignmentId: Scalars['Uuid']['output'];
  category: InspectionCategory;
  categoryId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type RecurringInspectionAssignmentCategoryLinkCreateInput = {
  assignmentId: Scalars['Uuid']['input'];
  categoryId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RecurringInspectionAssignmentCategoryLinkFilter = {
  assignmentId?: InputMaybe<UuidComparison>;
  categoryId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type RecurringInspectionAssignmentCategoryLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type RecurringInspectionAssignmentCategoryLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type RecurringInspectionAssignmentCreateInput = {
  checklistId: Scalars['Uuid']['input'];
  circumstanceValue?: InputMaybe<Scalars['Int']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  dayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Timestamp']['input']>;
  frequency: RecurringInspectionAssignmentFrequencyTypes;
  friday?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limitCategories: Scalars['Boolean']['input'];
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  monday?: InputMaybe<Scalars['Boolean']['input']>;
  monthOfYear?: InputMaybe<Scalars['Int']['input']>;
  notificationTime?: InputMaybe<Scalars['String']['input']>;
  notifyCircumstance?: InputMaybe<RecurringInspectionNotifyCircumstanceTypes>;
  notifySubmission: Scalars['Boolean']['input'];
  pausedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  resumedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  saturday?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotification: Scalars['Boolean']['input'];
  startDate: Scalars['Timestamp']['input'];
  sunday?: InputMaybe<Scalars['Boolean']['input']>;
  thursday?: InputMaybe<Scalars['Boolean']['input']>;
  tuesday?: InputMaybe<Scalars['Boolean']['input']>;
  wednesday?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecurringInspectionAssignmentFilter = {
  PausedOn?: InputMaybe<TimestampComparison>;
  ResumedOn?: InputMaybe<TimestampComparison>;
  checklist?: InputMaybe<InspectionChecklistFilter>;
  checklistId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endDate?: InputMaybe<TimestampComparison>;
  frequency?: InputMaybe<StringComparison>;
  id?: InputMaybe<UuidComparison>;
  inRange?: InputMaybe<RecurringInspectionAssignmentInRange>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  startDate?: InputMaybe<TimestampComparison>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum RecurringInspectionAssignmentFrequencyTypes {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export type RecurringInspectionAssignmentInRange = {
  endTime: Scalars['Timestamp']['input'];
  startTime: Scalars['Timestamp']['input'];
};

export type RecurringInspectionAssignmentNotificationLink = {
  __typename?: 'RecurringInspectionAssignmentNotificationLink';
  assignment: InspectionAssignment;
  assignmentId: Scalars['Uuid']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type RecurringInspectionAssignmentNotificationLinkCreateInput = {
  assignmentId: Scalars['Uuid']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
};

export type RecurringInspectionAssignmentNotificationLinkFilter = {
  assignmentId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type RecurringInspectionAssignmentNotificationLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type RecurringInspectionAssignmentNotificationLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type RecurringInspectionAssignmentSort = {
  checklist?: InputMaybe<InspectionChecklistSort>;
  createdOn?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  frequency?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  startDate?: InputMaybe<SortOrder>;
  submittedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type RecurringInspectionAssignmentUpdateInput = {
  checklistId?: InputMaybe<Scalars['Uuid']['input']>;
  circumstanceValue?: InputMaybe<Scalars['Int']['input']>;
  dayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Timestamp']['input']>;
  frequency?: InputMaybe<RecurringInspectionAssignmentFrequencyTypes>;
  friday?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  limitCategories?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  monday?: InputMaybe<Scalars['Boolean']['input']>;
  monthOfYear?: InputMaybe<Scalars['Int']['input']>;
  notificationTime?: InputMaybe<Scalars['String']['input']>;
  notifyCircumstance?: InputMaybe<RecurringInspectionNotifyCircumstanceTypes>;
  notifySubmission?: InputMaybe<Scalars['Boolean']['input']>;
  pausedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  resumedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  saturday?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Timestamp']['input']>;
  sunday?: InputMaybe<Scalars['Boolean']['input']>;
  thursday?: InputMaybe<Scalars['Boolean']['input']>;
  tuesday?: InputMaybe<Scalars['Boolean']['input']>;
  wednesday?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RecurringInspectionNotifyCircumstanceTypes {
  Always = 'always',
  LowScore = 'low_score',
}

export type RecurringScheduleReminder = {
  __typename?: 'RecurringScheduleReminder';
  costCode?: Maybe<CostCode>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  dayOfMonth?: Maybe<Scalars['Int']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  endTime: Scalars['Timestamp']['output'];
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  frequency: ScheduleReminderFrequencyTypes;
  frequencyExpiration?: Maybe<Scalars['Timestamp']['output']>;
  friday?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isDraft: Scalars['Boolean']['output'];
  links: Array<RecurringScheduleReminderLink>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  monday?: Maybe<Scalars['Boolean']['output']>;
  monthOfYear?: Maybe<Scalars['Int']['output']>;
  notifyEmployee: Scalars['Boolean']['output'];
  organizationId: Scalars['Uuid']['output'];
  payload?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  saturday?: Maybe<Scalars['Boolean']['output']>;
  startTime: Scalars['Timestamp']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  sunday?: Maybe<Scalars['Boolean']['output']>;
  thursday?: Maybe<Scalars['Boolean']['output']>;
  tuesday?: Maybe<Scalars['Boolean']['output']>;
  type: RecurringScheduleReminderTypes;
  updatedOn: Scalars['Timestamp']['output'];
  wednesday?: Maybe<Scalars['Boolean']['output']>;
};

export type RecurringScheduleReminderCreateInput = {
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  dayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  endTime: Scalars['Timestamp']['input'];
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  frequency: ScheduleReminderFrequencyTypes;
  frequencyExpiration?: InputMaybe<Scalars['Timestamp']['input']>;
  friday?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDraft: Scalars['Boolean']['input'];
  /** This gets set by the server and will eventually be removed */
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  monday?: InputMaybe<Scalars['Boolean']['input']>;
  monthOfYear?: InputMaybe<Scalars['Int']['input']>;
  notifyEmployee: Scalars['Boolean']['input'];
  /** This gets set by the server and will eventually be removed */
  organizationId?: InputMaybe<Scalars['Uuid']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  saturday?: InputMaybe<Scalars['Boolean']['input']>;
  startTime: Scalars['Timestamp']['input'];
  sunday?: InputMaybe<Scalars['Boolean']['input']>;
  thursday?: InputMaybe<Scalars['Boolean']['input']>;
  tuesday?: InputMaybe<Scalars['Boolean']['input']>;
  type: RecurringScheduleReminderTypes;
  wednesday?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecurringScheduleReminderFilter = {
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  frequency?: InputMaybe<StringComparison>;
  frequencyExpiration?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  inRange?: InputMaybe<RecurringSchedulesInRange>;
  isDraft?: InputMaybe<BooleanComparison>;
  links?: InputMaybe<RecurringScheduleReminderLinkFilter>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type RecurringScheduleReminderLink = {
  __typename?: 'RecurringScheduleReminderLink';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  schedule: RecurringScheduleReminder;
  scheduleReminderId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type RecurringScheduleReminderLinkCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  scheduleReminderId: Scalars['Uuid']['input'];
};

export type RecurringScheduleReminderLinkFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  schedule?: InputMaybe<RecurringScheduleReminderFilter>;
  scheduleReminderId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type RecurringScheduleReminderLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type RecurringScheduleReminderLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type RecurringScheduleReminderSort = {
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  frequency?: InputMaybe<SortOrder>;
  frequencyExpiration?: InputMaybe<SortOrder>;
  isDraft?: InputMaybe<SortOrder>;
  links?: InputMaybe<RecurringScheduleReminderLinkSort>;
  member?: InputMaybe<MemberSort>;
  project?: InputMaybe<ProjectSort>;
  startTime?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum RecurringScheduleReminderTypes {
  Break = 'break',
  Work = 'work',
}

export type RecurringScheduleReminderUpdateInput = {
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  dayOfMonth?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  endTime?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  frequency?: InputMaybe<ScheduleReminderFrequencyTypes>;
  frequencyExpiration?: InputMaybe<Scalars['Timestamp']['input']>;
  friday?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  monday?: InputMaybe<Scalars['Boolean']['input']>;
  monthOfYear?: InputMaybe<Scalars['Int']['input']>;
  notifyEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  saturday?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<Scalars['Timestamp']['input']>;
  sunday?: InputMaybe<Scalars['Boolean']['input']>;
  thursday?: InputMaybe<Scalars['Boolean']['input']>;
  tuesday?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<RecurringScheduleReminderTypes>;
  wednesday?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecurringSchedulesInRange = {
  endTime: Scalars['Timestamp']['input'];
  startTime: Scalars['Timestamp']['input'];
};

export type SafetyInspectionCategory = {
  __typename?: 'SafetyInspectionCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  questions?: Maybe<Array<SafetyInspectionCategoryQuestion>>;
  reportType: Scalars['String']['output'];
  sort?: Maybe<Scalars['Int']['output']>;
};

export type SafetyInspectionCategoryQuestion = {
  __typename?: 'SafetyInspectionCategoryQuestion';
  categoryId: Scalars['Int']['output'];
  failLinkName?: Maybe<Scalars['String']['output']>;
  failLinkUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  recommendResponse?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
};

export type SafetyInspectionChecklist = {
  __typename?: 'SafetyInspectionChecklist';
  name: Scalars['String']['output'];
  reportType: Scalars['String']['output'];
};

export type SafetyReportsCategory = {
  __typename?: 'SafetyReportsCategory';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetyReportsCategoryCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type SafetyReportsCategoryFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  name?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetyReportsCategorySort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetyReportsCategoryUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SafetyReportsSource = {
  __typename?: 'SafetyReportsSource';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetyReportsSourceCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type SafetyReportsSourceFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  name?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetyReportsSourceSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetyReportsSourceUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SafetyReportsTrainingMaterial = {
  __typename?: 'SafetyReportsTrainingMaterial';
  category?: Maybe<SafetyReportsCategory>;
  categoryId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  source?: Maybe<SafetyReportsSource>;
  sourceId?: Maybe<Scalars['Uuid']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetyReportsTrainingMaterialCreateInput = {
  categoryId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['Uuid']['input']>;
  title: Scalars['String']['input'];
};

export type SafetyReportsTrainingMaterialFilter = {
  category?: InputMaybe<SafetyReportsCategoryFilter>;
  categoryId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  source?: InputMaybe<SafetyReportsSourceFilter>;
  sourceId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedBy?: InputMaybe<UuidComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetyReportsTrainingMaterialSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  source?: InputMaybe<SafetyReportsSourceSort>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedBy?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetyReportsTrainingMaterialUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SafetySignature = {
  __typename?: 'SafetySignature';
  breakPolicyFollowed?: Maybe<Scalars['Boolean']['output']>;
  breakPolicyFollowedReason?: Maybe<Scalars['String']['output']>;
  breakPolicyFollowedResolvedBy?: Maybe<Scalars['Uuid']['output']>;
  breakPolicyFollowedResolvedByMember?: Maybe<Member>;
  breakPolicyFollowedResolvedComment?: Maybe<Scalars['String']['output']>;
  breakPolicyFollowedResolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  customAnswers: Array<MemberSignOffAnswer>;
  customQuestionsFlagged?: Maybe<Scalars['Boolean']['output']>;
  customQuestionsResolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  deviceType?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['RetroTimestamp']['output'];
  endTimeDst: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  incident?: Maybe<Incident>;
  incidentId?: Maybe<Scalars['Uuid']['output']>;
  injured?: Maybe<Scalars['Boolean']['output']>;
  injuredResolvedBy?: Maybe<Scalars['Uuid']['output']>;
  injuredResolvedByMember?: Maybe<Member>;
  injuredResolvedComment?: Maybe<Scalars['String']['output']>;
  injuredResolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  injuryDescription?: Maybe<Scalars['String']['output']>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  proxyMember: Member;
  proxyMemberId: Scalars['Uuid']['output'];
  signatureId?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['FileUrl']['output']>;
  startTime: Scalars['RetroTimestamp']['output'];
  startTimeDst: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeAccurate?: Maybe<Scalars['Boolean']['output']>;
  timeAccurateReason?: Maybe<Scalars['String']['output']>;
  timeAccurateResolvedBy?: Maybe<Scalars['Uuid']['output']>;
  timeAccurateResolvedByMember?: Maybe<Member>;
  timeAccurateResolvedComment?: Maybe<Scalars['String']['output']>;
  timeAccurateResolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetySignatureCreateInput = {
  breakPolicyFollowed?: InputMaybe<Scalars['Boolean']['input']>;
  breakPolicyFollowedReason?: InputMaybe<Scalars['String']['input']>;
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDst: Scalars['Boolean']['input'];
  createdOnOffset: Scalars['Int']['input'];
  customQuestionsFlagged?: InputMaybe<Scalars['Boolean']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeDst: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  incidentId?: InputMaybe<Scalars['Uuid']['input']>;
  injured?: InputMaybe<Scalars['Boolean']['input']>;
  injuryDescription?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['Uuid']['input'];
  proxyMemberId: Scalars['Uuid']['input'];
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeDst: Scalars['Boolean']['input'];
  timeAccurate?: InputMaybe<Scalars['Boolean']['input']>;
  timeAccurateReason?: InputMaybe<Scalars['String']['input']>;
};

export type SafetySignatureFilter = {
  breakPolicyFollowed?: InputMaybe<BooleanComparison>;
  breakPolicyFollowedResolvedBy?: InputMaybe<UuidComparison>;
  breakPolicyFollowedResolvedByMember?: InputMaybe<MemberFilter>;
  breakPolicyFollowedResolvedOn?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  customAnswers?: InputMaybe<MemberSignOffAnswerFilter>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  incidentId?: InputMaybe<UuidComparison>;
  injured?: InputMaybe<BooleanComparison>;
  injuredResolvedBy?: InputMaybe<UuidComparison>;
  injuredResolvedByMember?: InputMaybe<MemberFilter>;
  injuredResolvedOn?: InputMaybe<TimestampComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  proxyMember?: InputMaybe<MemberFilter>;
  proxyMemberId?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeAccurate?: InputMaybe<BooleanComparison>;
  timeAccurateResolvedBy?: InputMaybe<UuidComparison>;
  timeAccurateResolvedByMember?: InputMaybe<MemberFilter>;
  timeAccurateResolvedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetySignatureSort = {
  createdOn?: InputMaybe<SortOrder>;
  customAnswers?: InputMaybe<MemberSignOffAnswerSort>;
  deletedOn?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  proxyMember?: InputMaybe<MemberSort>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetySignatureUpdateInput = {
  breakPolicyFollowed?: InputMaybe<Scalars['Boolean']['input']>;
  breakPolicyFollowedReason?: InputMaybe<Scalars['String']['input']>;
  breakPolicyFollowedResolvedBy?: InputMaybe<Scalars['Uuid']['input']>;
  breakPolicyFollowedResolvedComment?: InputMaybe<Scalars['String']['input']>;
  breakPolicyFollowedResolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  customQuestionsFlagged?: InputMaybe<Scalars['Boolean']['input']>;
  customQuestionsResolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  incidentId?: InputMaybe<Scalars['Uuid']['input']>;
  injured?: InputMaybe<Scalars['Boolean']['input']>;
  injuredResolvedBy?: InputMaybe<Scalars['Uuid']['input']>;
  injuredResolvedComment?: InputMaybe<Scalars['String']['input']>;
  injuredResolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  injuryDescription?: InputMaybe<Scalars['String']['input']>;
  timeAccurate?: InputMaybe<Scalars['Boolean']['input']>;
  timeAccurateReason?: InputMaybe<Scalars['String']['input']>;
  timeAccurateResolvedBy?: InputMaybe<Scalars['Uuid']['input']>;
  timeAccurateResolvedComment?: InputMaybe<Scalars['String']['input']>;
  timeAccurateResolvedOn?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type SafetyTraining = {
  __typename?: 'SafetyTraining';
  attachments: Array<SafetyTrainingAttachment>;
  attendees: Array<SafetyTrainingAttendee>;
  completedOn?: Maybe<Scalars['Timestamp']['output']>;
  createdBy: Scalars['Uuid']['output'];
  createdByMember: Member;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  date: Scalars['Timestamp']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructor: Member;
  instructorId: Scalars['Uuid']['output'];
  instructorInformation?: Maybe<Scalars['String']['output']>;
  material: SafetyReportsTrainingMaterial;
  materialId: Scalars['Uuid']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['Uuid']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  sendNotifications: Scalars['Boolean']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  updatedBy?: Maybe<Scalars['Uuid']['output']>;
  updatedByMember?: Maybe<Member>;
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetyTrainingAttachment = {
  __typename?: 'SafetyTrainingAttachment';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['FileUrl']['output']>;
  id: Scalars['ID']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  training: SafetyTraining;
  trainingId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetyTrainingAttachmentCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  fileType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  trainingId: Scalars['Uuid']['input'];
};

export type SafetyTrainingAttachmentFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  training?: InputMaybe<SafetyTrainingFilter>;
  trainingId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetyTrainingAttachmentSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetyTrainingAttachmentUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type SafetyTrainingAttendee = {
  __typename?: 'SafetyTrainingAttendee';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  presentOn?: Maybe<Scalars['Timestamp']['output']>;
  signatureUrl?: Maybe<Scalars['FileUrl']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  training: SafetyTraining;
  trainingId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type SafetyTrainingAttendeeCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  presentOn?: InputMaybe<Scalars['Timestamp']['input']>;
  trainingId: Scalars['Uuid']['input'];
};

export type SafetyTrainingAttendeeFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  memberId?: InputMaybe<UuidComparison>;
  presentOn?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  training?: InputMaybe<SafetyTrainingFilter>;
  trainingId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetyTrainingAttendeeSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  presentOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetyTrainingAttendeeUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  presentOn?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type SafetyTrainingCategory = {
  __typename?: 'SafetyTrainingCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SafetyTrainingCreateInput = {
  completedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  date: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instructorId: Scalars['Uuid']['input'];
  instructorInformation?: InputMaybe<Scalars['String']['input']>;
  materialId: Scalars['Uuid']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  sendNotifications: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type SafetyTrainingDocument = {
  __typename?: 'SafetyTrainingDocument';
  category?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  spanishUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SafetyTrainingFilter = {
  attachments?: InputMaybe<SafetyTrainingAttachmentFilter>;
  attendees?: InputMaybe<SafetyTrainingAttendeeFilter>;
  completedOn?: InputMaybe<TimestampComparison>;
  createdByMember?: InputMaybe<MemberFilter>;
  createdOn?: InputMaybe<TimestampComparison>;
  date?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  instructor?: InputMaybe<MemberFilter>;
  instructorId?: InputMaybe<UuidComparison>;
  material?: InputMaybe<SafetyReportsTrainingMaterialFilter>;
  materialId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  projectIdWithAncestors?: InputMaybe<UuidComparison>;
  projectIdWithDescendants?: InputMaybe<UuidComparison>;
  sendNotifications?: InputMaybe<BooleanComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedByMember?: InputMaybe<MemberFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SafetyTrainingIndustry = {
  __typename?: 'SafetyTrainingIndustry';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SafetyTrainingSort = {
  attachments?: InputMaybe<SafetyTrainingAttachmentSort>;
  attendees?: InputMaybe<SafetyTrainingAttendeeSort>;
  completedOn?: InputMaybe<SortOrder>;
  createdByMember?: InputMaybe<MemberSort>;
  createdOn?: InputMaybe<SortOrder>;
  date?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<SortOrder>;
  instructor?: InputMaybe<MemberSort>;
  material?: InputMaybe<SafetyReportsTrainingMaterialSort>;
  project?: InputMaybe<ProjectSort>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedByMember?: InputMaybe<MemberSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SafetyTrainingUpdateInput = {
  completedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  date?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructorId?: InputMaybe<Scalars['Uuid']['input']>;
  instructorInformation?: InputMaybe<Scalars['String']['input']>;
  materialId?: InputMaybe<Scalars['Uuid']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  sendNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduleReminder = {
  __typename?: 'ScheduleReminder';
  costCode?: Maybe<CostCode>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  endTime: Scalars['Timestamp']['output'];
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  links: Array<ScheduleReminderLink>;
  member: Member;
  memberId: Scalars['Uuid']['output'];
  notifyEmployee?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['Uuid']['output'];
  overrideRecurringSchedule?: Maybe<RecurringScheduleReminder>;
  overrideRecurringScheduleId?: Maybe<Scalars['Uuid']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  repeat?: Maybe<Scalars['Int']['output']>;
  startTime: Scalars['Timestamp']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  /** ["work", "break"] */
  type: Scalars['String']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ScheduleReminderCreateInput = {
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  endTime: Scalars['Timestamp']['input'];
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  /** This gets set by the server and will eventually be removed */
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
  notifyEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  /** This gets set by the server and will eventually be removed */
  organizationId?: InputMaybe<Scalars['Uuid']['input']>;
  overrideRecurringScheduleId?: InputMaybe<Scalars['Uuid']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  /** ["work", "break"] */
  type: Scalars['String']['input'];
};

export type ScheduleReminderFilter = {
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  isDraft?: InputMaybe<BooleanComparison>;
  links?: InputMaybe<ScheduleReminderLinkFilter>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  overrideRecurringSchedule?: InputMaybe<RecurringScheduleReminderFilter>;
  overrideRecurringScheduleId?: InputMaybe<UuidComparison>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export enum ScheduleReminderFrequencyTypes {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Yearly = 'yearly',
}

export type ScheduleReminderLink = {
  __typename?: 'ScheduleReminderLink';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  schedule: ScheduleReminder;
  scheduleReminderId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type ScheduleReminderLinkCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  scheduleReminderId: Scalars['Uuid']['input'];
};

export type ScheduleReminderLinkFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  schedule?: InputMaybe<ScheduleReminderFilter>;
  scheduleReminderId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ScheduleReminderLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type ScheduleReminderLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type ScheduleReminderSort = {
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  isDraft?: InputMaybe<SortOrder>;
  links?: InputMaybe<ScheduleReminderLinkSort>;
  member?: InputMaybe<MemberSort>;
  overrideRecurringSchedule?: InputMaybe<RecurringScheduleReminderSort>;
  project?: InputMaybe<ProjectSort>;
  startTime?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type ScheduleReminderUpdateInput = {
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  endTime?: InputMaybe<Scalars['Timestamp']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id: Scalars['ID']['input'];
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  notifyEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  overrideRecurringScheduleId?: InputMaybe<Scalars['Uuid']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['Timestamp']['input']>;
  /** ["work", "break"] */
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchType {
  Contains = 'contains',
  StartsWith = 'startsWith',
}

export type SelfSignature = {
  __typename?: 'SelfSignature';
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  proxyMember: Member;
  proxyMemberId: Scalars['Uuid']['output'];
  signatureId?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['FileUrl']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type SelfSignatureCreateInput = {
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDst: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
};

export type SelfSignatureFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  proxyMember?: InputMaybe<MemberFilter>;
  proxyMemberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type SelfSignatureSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  proxyMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type SelfSignatureUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export enum SortOrder {
  Asc = 'asc',
  AscNullFirst = 'ascNullFirst',
  AscNullLast = 'ascNullLast',
  Desc = 'desc',
  DescNullFirst = 'descNullFirst',
  DescNullLast = 'descNullLast',
}

export type StringComparison = {
  contains?: InputMaybe<Array<Scalars['String']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['String']['input']>>;
  equal?: InputMaybe<Scalars['String']['input']>;
  equalOrNull?: InputMaybe<Scalars['String']['input']>;
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  greaterThanOrEqual?: InputMaybe<Scalars['String']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['String']['input']>;
  lessThanOrEqual?: InputMaybe<Scalars['String']['input']>;
  notEqual?: InputMaybe<Scalars['String']['input']>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  title: Scalars['String']['output'];
  type: TagTypes;
  updatedOn: Scalars['Timestamp']['output'];
};

export type TagCreateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: TagTypes;
};

export type TagDocumentLink = {
  __typename?: 'TagDocumentLink';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  document: OrganizationDocument;
  documentId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  tag: Tag;
  tagId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type TagDocumentLinkCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  documentId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  tagId: Scalars['Uuid']['input'];
};

export type TagDocumentLinkFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  document?: InputMaybe<OrganizationDocumentFilter>;
  documentId?: InputMaybe<UuidComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  tag?: InputMaybe<TagFilter>;
  tagId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TagDocumentLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  document?: InputMaybe<OrganizationDocumentSort>;
  submittedOn?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TagSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TagDocumentLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type TagFilter = {
  color?: InputMaybe<StringComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  title?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TagMediaCollectionLink = {
  __typename?: 'TagMediaCollectionLink';
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  mediaCollection: MediaCollection;
  mediaCollectionId: Scalars['Uuid']['output'];
  organizationId: Scalars['Uuid']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  tag: Tag;
  tagId: Scalars['Uuid']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type TagMediaCollectionLinkCreateInput = {
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mediaCollectionId: Scalars['Uuid']['input'];
  tagId: Scalars['Uuid']['input'];
};

export type TagMediaCollectionLinkFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  mediaCollection?: InputMaybe<MediaCollectionFilter>;
  mediaCollectionId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  tag?: InputMaybe<TagFilter>;
  tagId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TagMediaCollectionLinkSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  mediaCollection?: InputMaybe<MediaCollectionSort>;
  submittedOn?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TagSort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TagMediaCollectionLinkUpdateInput = {
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
};

export type TagSort = {
  color?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export enum TagTypes {
  Document = 'document',
  MediaCollection = 'mediaCollection',
}

export type TagUpdateInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TagTypes>;
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  /**
   * Clock In = 10
   * Clock In Location = 11
   * Clock Out = 20
   * Clock Out Location = 21
   * System Clock Out = 25
   * Clock In At = 30
   * Clock Out At = 40
   * Manual = 50
   * Edit = 60
   * Break Action = 70
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['output'];
  breaks: Array<TimeEntryBreak>;
  clientLogs: Array<ClientTimeEntryLog>;
  costCode?: Maybe<CostCode>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  daylightSavingTime: Scalars['Boolean']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  endLocation?: Maybe<MemberLocation>;
  endLocationId?: Maybe<Scalars['Uuid']['output']>;
  endTime?: Maybe<Scalars['RetroTimestamp']['output']>;
  endTimeTrusted?: Maybe<Scalars['Int']['output']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  flaggedTimeEntryLocation?: Maybe<FlaggedTimeEntryLocation>;
  hasLocation: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  member: Member;
  memberGpsStatuses: Array<MemberGpsStatus>;
  memberId: Scalars['Uuid']['output'];
  metaDaylightSavingTime?: Maybe<Scalars['Boolean']['output']>;
  openBreak?: Maybe<TimeEntryBreak>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Uuid']['output']>;
  startDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  startLocation?: Maybe<MemberLocation>;
  startLocationId?: Maybe<Scalars['Uuid']['output']>;
  startTime: Scalars['RetroTimestamp']['output'];
  startTimeTrusted: Scalars['Int']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type TimeEntryBreak = {
  __typename?: 'TimeEntryBreak';
  /**
   * Pause = 10
   * Pause Location = 11
   * Resume = 20
   * Resume Location = 21
   * System Resume = 25
   * Manual = 50
   * Edit = 60
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['output'];
  clientLogs: Array<ClientTimeEntryBreakLog>;
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  endDeviceTime?: Maybe<Scalars['Timestamp']['output']>;
  endLocation?: Maybe<MemberLocation>;
  endLocationId?: Maybe<Scalars['Uuid']['output']>;
  endTime?: Maybe<Scalars['RetroTimestamp']['output']>;
  endTimeDst?: Maybe<Scalars['Boolean']['output']>;
  endTimeTrusted?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  paidBreak?: Maybe<Scalars['Boolean']['output']>;
  startDeviceTime: Scalars['Timestamp']['output'];
  startLocation?: Maybe<MemberLocation>;
  startLocationId?: Maybe<Scalars['Uuid']['output']>;
  startTime: Scalars['RetroTimestamp']['output'];
  startTimeDst: Scalars['Boolean']['output'];
  startTimeTrusted: Scalars['Int']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  timeEntry: TimeEntry;
  timeEntryId: Scalars['Uuid']['output'];
  updatedOn?: Maybe<Scalars['Timestamp']['output']>;
};

export type TimeEntryBreakCreateInput = {
  /**
   * Pause = 10
   * Pause Location = 11
   * Resume = 20
   * Resume Location = 21
   * System Resume = 25
   * Manual = 50
   * Edit = 60
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['input'];
  createdOn: Scalars['Timestamp']['input'];
  endDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  endLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeDst?: InputMaybe<Scalars['Boolean']['input']>;
  endTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  paidBreak?: InputMaybe<Scalars['Boolean']['input']>;
  startDeviceTime: Scalars['Timestamp']['input'];
  startLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeDst: Scalars['Boolean']['input'];
  startTimeTrusted: Scalars['Int']['input'];
  timeEntryId: Scalars['Uuid']['input'];
};

export type TimeEntryBreakFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeEntry?: InputMaybe<TimeEntryFilter>;
  timeEntryId?: InputMaybe<UuidComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TimeEntryBreakSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeEntry?: InputMaybe<TimeEntrySort>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TimeEntryBreakUpdateInput = {
  /**
   * Pause = 10
   * Pause Location = 11
   * Resume = 20
   * Resume Location = 21
   * System Resume = 25
   * Manual = 50
   * Edit = 60
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType?: InputMaybe<Scalars['Int']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  endDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  endLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeDst?: InputMaybe<Scalars['Boolean']['input']>;
  endTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  paidBreak?: InputMaybe<Scalars['Boolean']['input']>;
  startDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  startLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeDst?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeEntryCreateInput = {
  /**
   * Clock In = 10
   * Clock In Location = 11
   * Clock Out = 20
   * Clock Out Location = 21
   * System Clock Out = 25
   * Clock In At = 30
   * Clock Out At = 40
   * Manual = 50
   * Edit = 60
   * Break Action = 70
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType: Scalars['Int']['input'];
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  createdOn: Scalars['Timestamp']['input'];
  daylightSavingTime: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  endLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  metaDaylightSavingTime?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  startDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  startLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeTrusted: Scalars['Int']['input'];
};

export type TimeEntryFilter = {
  breaks?: InputMaybe<TimeEntryBreakFilter>;
  costCode?: InputMaybe<CostCodeFilter>;
  costCodeId?: InputMaybe<UuidComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  endLocationId?: InputMaybe<UuidComparison>;
  endTime?: InputMaybe<TimestampComparison>;
  equipment?: InputMaybe<EquipmentFilter>;
  equipmentId?: InputMaybe<UuidComparison>;
  flaggedTimeEntryLocation?: InputMaybe<FlaggedTimeEntryLocationFilter>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberGpsStatuses?: InputMaybe<MemberGpsStatusFilter>;
  memberId?: InputMaybe<UuidComparison>;
  openBreak?: InputMaybe<TimeEntryBreakFilter>;
  project?: InputMaybe<ProjectFilter>;
  projectId?: InputMaybe<UuidComparison>;
  startLocationId?: InputMaybe<UuidComparison>;
  startTime?: InputMaybe<TimestampComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeRange?: InputMaybe<TimeRangeFilter>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TimeEntryRekognition = {
  __typename?: 'TimeEntryRekognition';
  confidence?: Maybe<Scalars['Float']['output']>;
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst?: Maybe<Scalars['Boolean']['output']>;
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['FileUrl']['output']>;
  referenceImageId?: Maybe<Scalars['String']['output']>;
  referenceImageUrl?: Maybe<Scalars['FileUrl']['output']>;
  resolvedOn?: Maybe<Scalars['Timestamp']['output']>;
  similarity?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  timeEntry: TimeEntry;
  timeEntryId: Scalars['Uuid']['output'];
  /** ["clock-in", "clock-out", "break-start", "break-end", "switch"] */
  type: Scalars['String']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type TimeEntryRekognitionFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  resolvedOn?: InputMaybe<TimestampComparison>;
  similarity?: InputMaybe<IntComparison>;
  source?: InputMaybe<StringComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  timeEntry?: InputMaybe<TimeEntryFilter>;
  timeEntryId?: InputMaybe<UuidComparison>;
  type?: InputMaybe<StringComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TimeEntryRekognitionSort = {
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  resolvedOn?: InputMaybe<SortOrder>;
  similarity?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  timeEntry?: InputMaybe<TimeEntrySort>;
  type?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TimeEntrySort = {
  breaks?: InputMaybe<TimeEntryBreakSort>;
  costCode?: InputMaybe<CostCodeSort>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  equipment?: InputMaybe<EquipmentSort>;
  flaggedTimeEntryLocation?: InputMaybe<FlaggedTimeEntryLocationSort>;
  member?: InputMaybe<MemberSort>;
  openBreak?: InputMaybe<TimeEntryBreakSort>;
  project?: InputMaybe<ProjectSort>;
  startTime?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TimeEntryUpdateInput = {
  /**
   * Clock In = 10
   * Clock In Location = 11
   * Clock Out = 20
   * Clock Out Location = 21
   * System Clock Out = 25
   * Clock In At = 30
   * Clock Out At = 40
   * Manual = 50
   * Edit = 60
   * Break Action = 70
   * Resolved Conflict = 80
   * Deleted = 90
   * Resolution Deleted = 100
   */
  actionType?: InputMaybe<Scalars['Int']['input']>;
  costCodeId?: InputMaybe<Scalars['Uuid']['input']>;
  daylightSavingTime?: InputMaybe<Scalars['Boolean']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  endLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  endTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  endTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
  equipmentId?: InputMaybe<Scalars['Uuid']['input']>;
  id: Scalars['ID']['input'];
  metaDaylightSavingTime?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  startDeviceTime?: InputMaybe<Scalars['Timestamp']['input']>;
  startLocationId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  startTimeTrusted?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeOff = {
  __typename?: 'TimeOff';
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDaylightSavingTime: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  dateStamp: Scalars['Timestamp']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  paid: Scalars['Boolean']['output'];
  seconds?: Maybe<Scalars['Int']['output']>;
  submittedOn: Scalars['Timestamp']['output'];
  submitterMember: Member;
  submitterMemberId: Scalars['Uuid']['output'];
  /**
   * Holiday = 10
   * Personal = 20
   * Sick = 30
   * Vacation = 40
   * Other = 50
   */
  type: Scalars['Int']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type TimeOffCreateInput = {
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDaylightSavingTime: Scalars['Boolean']['input'];
  dateStamp: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  paid: Scalars['Boolean']['input'];
  seconds?: InputMaybe<Scalars['Int']['input']>;
  submitterMemberId: Scalars['Uuid']['input'];
  /**
   * Holiday = 10
   * Personal = 20
   * Sick = 30
   * Vacation = 40
   * Other = 50
   */
  type: Scalars['Int']['input'];
};

export type TimeOffFilter = {
  createdOn?: InputMaybe<TimestampComparison>;
  dateStamp?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  paid?: InputMaybe<BooleanComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  submitterMember?: InputMaybe<MemberFilter>;
  submitterMemberId?: InputMaybe<UuidComparison>;
  type?: InputMaybe<IntComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TimeOffRequest = {
  __typename?: 'TimeOffRequest';
  answeredBy?: Maybe<Scalars['Uuid']['output']>;
  answeredByMember?: Maybe<Member>;
  answeredOn?: Maybe<Scalars['Timestamp']['output']>;
  approved?: Maybe<Scalars['Boolean']['output']>;
  createdOn: Scalars['RetroTimestamp']['output'];
  createdOnDst: Scalars['Boolean']['output'];
  createdOnLocal: Scalars['RetroLocalizedTimestamp']['output'];
  cursor: Scalars['String']['output'];
  dateStamp: Scalars['Timestamp']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  paid: Scalars['Boolean']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  seconds?: Maybe<Scalars['Int']['output']>;
  submittedBy: Scalars['Uuid']['output'];
  submittedByMember: Member;
  submittedOn: Scalars['Timestamp']['output'];
  /**
   * Holiday = 10
   * Personal = 20
   * Sick = 30
   * Vacation = 40
   * Other = 50
   */
  type: Scalars['Int']['output'];
  updatedOn: Scalars['Timestamp']['output'];
};

export type TimeOffRequestCreateInput = {
  createdOn?: InputMaybe<Scalars['RetroTimestamp']['input']>;
  createdOnDst: Scalars['Boolean']['input'];
  dateStamp: Scalars['Timestamp']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  seconds?: InputMaybe<Scalars['Int']['input']>;
  submittedBy: Scalars['Uuid']['input'];
  /**
   * Holiday = 10
   * Personal = 20
   * Sick = 30
   * Vacation = 40
   * Other = 50
   */
  type: Scalars['Int']['input'];
};

export type TimeOffRequestFilter = {
  answeredByMember?: InputMaybe<MemberFilter>;
  answeredOn?: InputMaybe<TimestampComparison>;
  approved?: InputMaybe<BooleanComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  dateStamp?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  paid?: InputMaybe<BooleanComparison>;
  submittedBy?: InputMaybe<UuidComparison>;
  submittedByMember?: InputMaybe<MemberFilter>;
  submittedOn?: InputMaybe<TimestampComparison>;
  type?: InputMaybe<IntComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type TimeOffRequestSort = {
  answeredByMember?: InputMaybe<MemberSort>;
  answeredOn?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  dateStamp?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  paid?: InputMaybe<SortOrder>;
  seconds?: InputMaybe<SortOrder>;
  submittedByMember?: InputMaybe<MemberSort>;
  submittedOn?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TimeOffRequestUpdateInput = {
  answeredBy?: InputMaybe<Scalars['Uuid']['input']>;
  answeredOn?: InputMaybe<Scalars['Timestamp']['input']>;
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  dateStamp?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  seconds?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Holiday = 10
   * Personal = 20
   * Sick = 30
   * Vacation = 40
   * Other = 50
   */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeOffSort = {
  createdOn?: InputMaybe<SortOrder>;
  dateStamp?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  paid?: InputMaybe<SortOrder>;
  seconds?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  submitterMember?: InputMaybe<MemberSort>;
  type?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
};

export type TimeOffUpdateInput = {
  createdOnDaylightSavingTime?: InputMaybe<Scalars['Boolean']['input']>;
  dateStamp?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  seconds?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Holiday = 10
   * Personal = 20
   * Sick = 30
   * Vacation = 40
   * Other = 50
   */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeRangeFilter = {
  endTime: Scalars['Timestamp']['input'];
  includeOpenTimeEntries: Scalars['Boolean']['input'];
  startTime: Scalars['Timestamp']['input'];
};

export type TimestampComparison = {
  between?: InputMaybe<BetweenTimestamp>;
  contains?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  equal?: InputMaybe<Scalars['Timestamp']['input']>;
  equalOrNull?: InputMaybe<Scalars['Timestamp']['input']>;
  greaterThan?: InputMaybe<Scalars['Timestamp']['input']>;
  greaterThanOrEqual?: InputMaybe<Scalars['Timestamp']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lessThan?: InputMaybe<Scalars['Timestamp']['input']>;
  lessThanOrEqual?: InputMaybe<Scalars['Timestamp']['input']>;
  notEqual?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type UuidComparison = {
  contains?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  doesNotContain?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  equal?: InputMaybe<Scalars['Uuid']['input']>;
  equalOrNull?: InputMaybe<Scalars['Uuid']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  notEqual?: InputMaybe<Scalars['Uuid']['input']>;
};

export type WageHistory = {
  __typename?: 'WageHistory';
  changeDate: Scalars['Timestamp']['output'];
  createdOn: Scalars['Timestamp']['output'];
  cursor: Scalars['String']['output'];
  deletedOn?: Maybe<Scalars['Timestamp']['output']>;
  effectiveRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  member: Member;
  memberId: Scalars['Uuid']['output'];
  overburden: Scalars['Float']['output'];
  submittedOn: Scalars['Timestamp']['output'];
  updatedOn: Scalars['Timestamp']['output'];
  wage: Scalars['Float']['output'];
  wageRate: Scalars['Int']['output'];
  workWeek?: Maybe<Scalars['Int']['output']>;
};

export type WageHistoryCreateInput = {
  changeDate: Scalars['Timestamp']['input'];
  createdOn: Scalars['Timestamp']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['Uuid']['input'];
  overburden: Scalars['Float']['input'];
  wage: Scalars['Float']['input'];
  wageRate: Scalars['Int']['input'];
  workWeek?: InputMaybe<Scalars['Int']['input']>;
};

export type WageHistoryFilter = {
  changeDate?: InputMaybe<TimestampComparison>;
  createdOn?: InputMaybe<TimestampComparison>;
  deletedOn?: InputMaybe<TimestampComparison>;
  id?: InputMaybe<UuidComparison>;
  member?: InputMaybe<MemberFilter>;
  memberId?: InputMaybe<UuidComparison>;
  submittedOn?: InputMaybe<TimestampComparison>;
  updatedOn?: InputMaybe<TimestampComparison>;
};

export type WageHistorySort = {
  changeDate?: InputMaybe<SortOrder>;
  createdOn?: InputMaybe<SortOrder>;
  deletedOn?: InputMaybe<SortOrder>;
  member?: InputMaybe<MemberSort>;
  overburden?: InputMaybe<SortOrder>;
  submittedOn?: InputMaybe<SortOrder>;
  updatedOn?: InputMaybe<SortOrder>;
  wage?: InputMaybe<SortOrder>;
  wageRate?: InputMaybe<SortOrder>;
};

export type WageHistoryUpdateInput = {
  changeDate?: InputMaybe<Scalars['Timestamp']['input']>;
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
  id: Scalars['ID']['input'];
  overburden?: InputMaybe<Scalars['Float']['input']>;
  wage?: InputMaybe<Scalars['Float']['input']>;
  wageRate?: InputMaybe<Scalars['Int']['input']>;
  workWeek?: InputMaybe<Scalars['Int']['input']>;
};

export type Weather = {
  __typename?: 'Weather';
  hours: Array<WeatherHourly>;
  metadata: WeatherMetadata;
  name: Scalars['String']['output'];
};

export type WeatherHourly = {
  __typename?: 'WeatherHourly';
  cloudCover: Scalars['Float']['output'];
  conditionCode: Scalars['String']['output'];
  daylight: Scalars['Boolean']['output'];
  forecastStart: Scalars['DateTime']['output'];
  humidity: Scalars['Float']['output'];
  precipitationAmount: Scalars['Float']['output'];
  precipitationChance: Scalars['Float']['output'];
  precipitationIntensity: Scalars['Float']['output'];
  precipitationType: Scalars['String']['output'];
  pressure: Scalars['Float']['output'];
  pressureTrend: Scalars['String']['output'];
  snowfallAmount: Scalars['Float']['output'];
  snowfallIntensity: Scalars['Float']['output'];
  temperature: Scalars['Float']['output'];
  temperatureApparent: Scalars['Float']['output'];
  temperatureDewPoint: Scalars['Float']['output'];
  windDirection: Scalars['Int']['output'];
  windGust: Scalars['Float']['output'];
  windSpeed: Scalars['Float']['output'];
};

export type WeatherMetadata = {
  __typename?: 'WeatherMetadata';
  attributionURL: Scalars['String']['output'];
  expireTime: Scalars['DateTime']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  units: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type CostCodeDisplayFragment = { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null };

export type EquipmentDisplayFragment = {
  __typename?: 'Equipment';
  id: string;
  equipmentName: string;
  model?: {
    __typename?: 'EquipmentModel';
    id: string;
    title?: string | null;
    year?: number | null;
    category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
    make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
  } | null;
};

export type InspectionChecklistFragmentFragment = {
  __typename?: 'InspectionChecklist';
  id: string;
  createdOn: string;
  deletedOn?: string | null;
  name: string;
  limitInspectors: boolean;
  categories: Array<{
    __typename?: 'InspectionCategory';
    id: string;
    name: string;
    createdOn: string;
    deletedOn?: string | null;
    checklistId: string;
    questions: Array<{
      __typename?: 'InspectionQuestion';
      id: string;
      question: string;
      order: number;
      enabled: boolean;
      deletedOn?: string | null;
    }>;
  }>;
  memberLinks: Array<{
    __typename?: 'InspectionChecklistMemberLink';
    id: string;
    memberId: string;
    deletedOn?: string | null;
  }>;
};

export type InspectionChecklistMemberLinkFragmentFragment = {
  __typename?: 'InspectionChecklistMemberLink';
  id: string;
  memberId: string;
  deletedOn?: string | null;
};

export type MemberDisplayFragment = {
  __typename?: 'Member';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
};

export type ProjectDisplayFragment = {
  __typename?: 'Project';
  id: string;
  title: string;
  depth: number;
  ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
};

export type SimpleProjectFragment = {
  __typename?: 'Project';
  id: string;
  title: string;
  depth: number;
  parentProjectId?: string | null;
  rootProjectId?: string | null;
  cursor: string;
  archivedOn?: string | null;
};

export type RecurringInspectionAssignmentCategoryLinkFragmentFragment = {
  __typename?: 'RecurringInspectionAssignmentCategoryLink';
  id: string;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string | null;
  assignmentId: string;
  categoryId: string;
  category: {
    __typename?: 'InspectionCategory';
    id: string;
    checklistId: string;
    name: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  };
};

export type RecurringInspectionAssignmentFragmentFragment = {
  __typename?: 'RecurringInspectionAssignment';
  id: string;
  pausedOn?: string | null;
  resumedOn?: string | null;
  startDate: string;
  endDate?: string | null;
  frequency: RecurringInspectionAssignmentFrequencyTypes;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  dayOfMonth?: number | null;
  monthOfYear?: number | null;
  sendNotification: boolean;
  description?: string | null;
  notifySubmission: boolean;
  notificationTime?: string | null;
  notifyCircumstance?: RecurringInspectionNotifyCircumstanceTypes | null;
  circumstanceValue?: number | null;
  member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  project?: {
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    ancestors?: Array<{ __typename?: 'Project'; depth: number; title: string }> | null;
  } | null;
  checklist: {
    __typename?: 'InspectionChecklist';
    id: string;
    name: string;
    categories: Array<{
      __typename?: 'InspectionCategory';
      id: string;
      name: string;
      deletedOn?: string | null;
      questions: Array<{
        __typename?: 'InspectionQuestion';
        id: string;
        question: string;
        enabled: boolean;
        deletedOn?: string | null;
        order: number;
      }>;
    }>;
  };
  limitedCategories: Array<{
    __typename?: 'RecurringInspectionAssignmentCategoryLink';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    categoryId: string;
    category: {
      __typename?: 'InspectionCategory';
      id: string;
      checklistId: string;
      name: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    };
  }>;
};

export type RecurringInspectionAssignmentNotificationLinkFragmentFragment = {
  __typename?: 'RecurringInspectionAssignmentNotificationLink';
  id: string;
  createdOn: string;
  updatedOn: string;
  deletedOn?: string | null;
  assignmentId: string;
  memberId: string;
};

export type CreateTimeEntryBreakMutationVariables = Exact<{
  entryBreak?: InputMaybe<TimeEntryBreakCreateInput>;
}>;

export type CreateTimeEntryBreakMutation = {
  __typename?: 'Mutation';
  createTimeEntryBreak?: { __typename?: 'TimeEntryBreak'; id: string } | null;
};

export type UpdateTimeEntryBreakMutationVariables = Exact<{
  entryBreak?: InputMaybe<TimeEntryBreakUpdateInput>;
}>;

export type UpdateTimeEntryBreakMutation = {
  __typename?: 'Mutation';
  updateTimeEntryBreak?: { __typename?: 'TimeEntryBreak'; id: string } | null;
};

export type CreateTimeEntryBreaksMutationVariables = Exact<{
  entryBreaks: Array<TimeEntryBreakCreateInput> | TimeEntryBreakCreateInput;
}>;

export type CreateTimeEntryBreaksMutation = {
  __typename?: 'Mutation';
  createTimeEntryBreaks?: Array<{ __typename?: 'TimeEntryBreak'; id: string }> | null;
};

export type UpdateTimeEntryBreaksMutationVariables = Exact<{
  entryBreaks: Array<TimeEntryBreakUpdateInput> | TimeEntryBreakUpdateInput;
}>;

export type UpdateTimeEntryBreaksMutation = {
  __typename?: 'Mutation';
  updateTimeEntryBreaks?: Array<{ __typename?: 'TimeEntryBreak'; id: string }> | null;
};

export type CreateClientTimeEntryBreakLogMutationVariables = Exact<{
  log?: InputMaybe<ClientTimeEntryBreakLogCreateInput>;
}>;

export type CreateClientTimeEntryBreakLogMutation = {
  __typename?: 'Mutation';
  createClientTimeEntryBreakLog?: {
    __typename?: 'ClientTimeEntryBreakLog';
    id: string;
    timeEntryBreakId: string;
  } | null;
};

export type CreateClientTimeEntryBreakLogsMutationVariables = Exact<{
  logs: Array<ClientTimeEntryBreakLogCreateInput> | ClientTimeEntryBreakLogCreateInput;
}>;

export type CreateClientTimeEntryBreakLogsMutation = {
  __typename?: 'Mutation';
  createClientTimeEntryBreakLogs?: Array<{
    __typename?: 'ClientTimeEntryBreakLog';
    id: string;
    timeEntryBreakId: string;
  }> | null;
};

export type CreateTimeEntryLogMutationVariables = Exact<{
  log?: InputMaybe<ClientTimeEntryLogCreateInput>;
}>;

export type CreateTimeEntryLogMutation = {
  __typename?: 'Mutation';
  createClientTimeEntryLog?: {
    __typename?: 'ClientTimeEntryLog';
    id: string;
    costCodeId?: string | null;
    description?: string | null;
    deviceType?: string | null;
    equipmentId?: string | null;
    endLocationId?: string | null;
    memberId: string;
    mergedTimeEntryId?: string | null;
    originalTimeEntryId?: string | null;
    projectId?: string | null;
    startLocationId?: string | null;
    timeEntryId: string;
    updaterMemberId: string;
    actionType: number;
    endDeviceTime?: string | null;
    endTime?: string | null;
    endTimeTrusted?: number | null;
    startDeviceTime?: string | null;
    startTime: string;
    startTimeTrusted: number;
    timeEntryCreatedOn: string;
    timeEntryDeletedOn?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      daylightSavingTime: boolean;
      endTime?: string | null;
      metaDaylightSavingTime?: boolean | null;
      actionType: number;
      description?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
      } | null;
      costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
      equipment?: {
        __typename?: 'Equipment';
        id: string;
        equipmentName: string;
        model?: {
          __typename?: 'EquipmentModel';
          id: string;
          title?: string | null;
          make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
          category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        } | null;
      } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startTimeDst: boolean;
        endTime?: string | null;
        endTimeDst?: boolean | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
      }>;
      startLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
      endLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
    };
    updaterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  } | null;
};

export type CreateTimeEntryLogsMutationVariables = Exact<{
  logs: Array<ClientTimeEntryLogCreateInput> | ClientTimeEntryLogCreateInput;
}>;

export type CreateTimeEntryLogsMutation = {
  __typename?: 'Mutation';
  createClientTimeEntryLogs?: Array<{
    __typename?: 'ClientTimeEntryLog';
    id: string;
    costCodeId?: string | null;
    description?: string | null;
    deviceType?: string | null;
    equipmentId?: string | null;
    endLocationId?: string | null;
    memberId: string;
    mergedTimeEntryId?: string | null;
    originalTimeEntryId?: string | null;
    projectId?: string | null;
    startLocationId?: string | null;
    timeEntryId: string;
    updaterMemberId: string;
    actionType: number;
    endDeviceTime?: string | null;
    endTime?: string | null;
    endTimeTrusted?: number | null;
    startDeviceTime?: string | null;
    startTime: string;
    startTimeTrusted: number;
    timeEntryCreatedOn: string;
    timeEntryDeletedOn?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      daylightSavingTime: boolean;
      endTime?: string | null;
      metaDaylightSavingTime?: boolean | null;
      actionType: number;
      description?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
      } | null;
      costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
      equipment?: {
        __typename?: 'Equipment';
        id: string;
        equipmentName: string;
        model?: {
          __typename?: 'EquipmentModel';
          id: string;
          title?: string | null;
          make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
          category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        } | null;
      } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startTimeDst: boolean;
        endTime?: string | null;
        endTimeDst?: boolean | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
      }>;
      startLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
      endLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
    };
    updaterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type CreateCostCodeGroupMutationVariables = Exact<{
  costCodeGroup?: InputMaybe<CostCodeGroupCreateInput>;
}>;

export type CreateCostCodeGroupMutation = {
  __typename?: 'Mutation';
  createCostCodeGroup?: { __typename?: 'CostCodeGroup'; id: string } | null;
};

export type UpdateCostCodeGroupMutationVariables = Exact<{
  costCodeGroup?: InputMaybe<CostCodeGroupUpdateInput>;
}>;

export type UpdateCostCodeGroupMutation = {
  __typename?: 'Mutation';
  updateCostCodeGroup?: { __typename?: 'CostCodeGroup'; id: string } | null;
};

export type CreateCostCodeMutationVariables = Exact<{
  costCode?: InputMaybe<CostCodeCreateInput>;
}>;

export type CreateCostCodeMutation = {
  __typename?: 'Mutation';
  createCostCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
};

export type UpdateCostCode2MutationVariables = Exact<{
  costCode?: InputMaybe<CostCodeUpdateInput>;
}>;

export type UpdateCostCode2Mutation = {
  __typename?: 'Mutation';
  updateCostCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
};

export type UpdateDailyProjectReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
}>;

export type UpdateDailyProjectReportMutation = {
  __typename?: 'Mutation';
  updateDailyProjectReport?: { __typename?: 'DailyProjectReport'; id: string; deletedOn?: string | null } | null;
};

export type DailyProjectReportMutationVariables = Exact<{
  dailyProjectReport?: InputMaybe<DailyProjectReportCreateInput>;
}>;

export type DailyProjectReportMutation = {
  __typename?: 'Mutation';
  createDailyProjectReport?: {
    __typename?: 'DailyProjectReport';
    id: string;
    projectId: string;
    createdBy: string;
    reportDate: string;
    fileUrl?: string | null;
  } | null;
};

export type CreateEquipmentHoursMutationVariables = Exact<{
  equipmentHours?: InputMaybe<EquipmentHoursCreateInput>;
}>;

export type CreateEquipmentHoursMutation = {
  __typename?: 'Mutation';
  createEquipmentHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null } | null;
};

export type CreateEquipmentMutationVariables = Exact<{
  equipment?: InputMaybe<EquipmentCreateInput>;
}>;

export type CreateEquipmentMutation = {
  __typename?: 'Mutation';
  createEquipment?: {
    __typename?: 'Equipment';
    id: string;
    equipmentName: string;
    equipmentModelId?: string | null;
    trackManualHours: boolean;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      year?: number | null;
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
    } | null;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null } | null;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
  } | null;
};

export type UpdateEquipmentMutationVariables = Exact<{
  equipment?: InputMaybe<EquipmentUpdateInput>;
}>;

export type UpdateEquipmentMutation = {
  __typename?: 'Mutation';
  updateEquipment?: {
    __typename?: 'Equipment';
    id: string;
    equipmentName: string;
    equipmentModelId?: string | null;
    trackManualHours: boolean;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      year?: number | null;
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
    } | null;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null } | null;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
  } | null;
};

export type UpdateIncidentMutationVariables = Exact<{
  incident?: InputMaybe<IncidentUpdateInput>;
}>;

export type UpdateIncidentMutation = {
  __typename?: 'Mutation';
  updateIncident?: {
    __typename?: 'Incident';
    id: string;
    organizationId: string;
    memberId: string;
    type: IncidentTypes;
    recordable: boolean;
    projectId?: string | null;
    state: IncidentStates;
    illnessType?: IncidentIllnessTypes | null;
    caseNumber: number;
    occurredAt: string;
    occurrenceTimeSet: boolean;
    employeeStartTime?: string | null;
    employeeJobTitle?: string | null;
    employeeBirthday?: string | null;
    employeeHireDate?: string | null;
    employeeSex?: IncidentEmployeeSexes | null;
    employeeAddress1?: string | null;
    employeeCity?: string | null;
    employeeState?: string | null;
    employeePostalCode?: string | null;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    reviewedOn?: string | null;
    completedOn?: string | null;
    answers: Array<{
      __typename?: 'IncidentAnswer';
      id: string;
      incidentId: string;
      question: string;
      type: IncidentAnswerTypes;
      answer: string;
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
      children?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
    };
    medicalDetails?: {
      __typename?: 'IncidentMedicalDetails';
      id: string;
      incidentId: string;
      treatmentER?: boolean | null;
      treatmentOvernight?: boolean | null;
      treatmentFirstAid?: boolean | null;
      treatmentDoctor?: boolean | null;
      treatmentNone?: boolean | null;
      treatmentOther?: boolean | null;
      facilityName?: string | null;
      facilityAddress?: string | null;
      facilityCity?: string | null;
      facilityState?: string | null;
      facilityPostalCode?: string | null;
      physicianName?: string | null;
    } | null;
    submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    reviewedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    outcomeDetails?: {
      __typename?: 'IncidentOutcomeDetails';
      id: string;
      type: IncidentOutcomeDetailsTypes;
      daysAway?: number | null;
      daysTransfer?: number | null;
      outcomeDate?: string | null;
    } | null;
    completedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type CreateInspectionCategoryMutationVariables = Exact<{
  category?: InputMaybe<InspectionCategoryCreateInput>;
}>;

export type CreateInspectionCategoryMutation = {
  __typename?: 'Mutation';
  createInspectionCategory?: { __typename?: 'InspectionCategory'; id: string; name: string; createdOn: string } | null;
};

export type UpdateInspectionCategoryMutationVariables = Exact<{
  category?: InputMaybe<InspectionCategoryUpdateInput>;
}>;

export type UpdateInspectionCategoryMutation = {
  __typename?: 'Mutation';
  updateInspectionCategory?: { __typename?: 'InspectionCategory'; id: string; name: string } | null;
};

export type DeleteInspectionCategoryMutationVariables = Exact<{
  category?: InputMaybe<InspectionCategoryUpdateInput>;
}>;

export type DeleteInspectionCategoryMutation = {
  __typename?: 'Mutation';
  updateInspectionCategory?: { __typename?: 'InspectionCategory'; id: string; deletedOn?: string | null } | null;
};

export type CreateInspectionChecklistMemberLinkMutationVariables = Exact<{
  checklistMemberLink?: InputMaybe<InspectionChecklistMemberLinkCreateInput>;
}>;

export type CreateInspectionChecklistMemberLinkMutation = {
  __typename?: 'Mutation';
  createInspectionChecklistMemberLink?: {
    __typename?: 'InspectionChecklistMemberLink';
    id: string;
    memberId: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateInspectionChecklistMemberLinkMutationVariables = Exact<{
  checklistMemberLink?: InputMaybe<InspectionChecklistMemberLinkUpdateInput>;
}>;

export type UpdateInspectionChecklistMemberLinkMutation = {
  __typename?: 'Mutation';
  updateInspectionChecklistMemberLink?: {
    __typename?: 'InspectionChecklistMemberLink';
    id: string;
    memberId: string;
    deletedOn?: string | null;
  } | null;
};

export type CreateInspectionChecklistMutationVariables = Exact<{
  checklist?: InputMaybe<InspectionChecklistCreateInput>;
}>;

export type CreateInspectionChecklistMutation = {
  __typename?: 'Mutation';
  createInspectionChecklist?: {
    __typename?: 'InspectionChecklist';
    id: string;
    createdOn: string;
    deletedOn?: string | null;
    name: string;
    limitInspectors: boolean;
    categories: Array<{
      __typename?: 'InspectionCategory';
      id: string;
      name: string;
      createdOn: string;
      deletedOn?: string | null;
      checklistId: string;
      questions: Array<{
        __typename?: 'InspectionQuestion';
        id: string;
        question: string;
        order: number;
        enabled: boolean;
        deletedOn?: string | null;
      }>;
    }>;
    memberLinks: Array<{
      __typename?: 'InspectionChecklistMemberLink';
      id: string;
      memberId: string;
      deletedOn?: string | null;
    }>;
  } | null;
};

export type UpdateInspectionChecklistMutationVariables = Exact<{
  checklist?: InputMaybe<InspectionChecklistUpdateInput>;
}>;

export type UpdateInspectionChecklistMutation = {
  __typename?: 'Mutation';
  updateInspectionChecklist?: {
    __typename?: 'InspectionChecklist';
    id: string;
    createdOn: string;
    deletedOn?: string | null;
    name: string;
    limitInspectors: boolean;
    categories: Array<{
      __typename?: 'InspectionCategory';
      id: string;
      name: string;
      createdOn: string;
      deletedOn?: string | null;
      checklistId: string;
      questions: Array<{
        __typename?: 'InspectionQuestion';
        id: string;
        question: string;
        order: number;
        enabled: boolean;
        deletedOn?: string | null;
      }>;
    }>;
    memberLinks: Array<{
      __typename?: 'InspectionChecklistMemberLink';
      id: string;
      memberId: string;
      deletedOn?: string | null;
    }>;
  } | null;
};

export type UpdateInspectionAssignmentMutationVariables = Exact<{
  inspectionAssignment?: InputMaybe<InspectionAssignmentUpdateInput>;
}>;

export type UpdateInspectionAssignmentMutation = {
  __typename?: 'Mutation';
  updateInspectionAssignment?: {
    __typename?: 'InspectionAssignment';
    id: string;
    memberId: string;
    checklistId: string;
    projectId?: string | null;
    date: string;
    sendNotification: boolean;
    notificationTime?: string | null;
    description?: string | null;
    limitCategories: boolean;
    notifySubmission: boolean;
    notifyCircumstance?: InspectionNotifyCircumstanceTypes | null;
    circumstanceValue?: number | null;
    deletedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    limitedCategories: Array<{
      __typename?: 'InspectionAssignmentCategoryLink';
      id: string;
      categoryId: string;
      deletedOn?: string | null;
    }>;
    notificationLinks: Array<{
      __typename?: 'InspectionAssignmentNotificationLink';
      id: string;
      memberId: string;
      deletedOn?: string | null;
    }>;
    inspection: Array<{
      __typename?: 'Inspection';
      id: string;
      completedOn?: string | null;
      deletedOn?: string | null;
    }>;
  } | null;
};

export type UpdateInspectionMutationVariables = Exact<{
  inspection?: InputMaybe<InspectionUpdateInput>;
}>;

export type UpdateInspectionMutation = {
  __typename?: 'Mutation';
  updateInspection?: {
    __typename?: 'Inspection';
    id: string;
    organizationId: string;
    checklistId: string;
    assignmentId?: string | null;
    recurringAssignmentId?: string | null;
    projectId?: string | null;
    submittedBy: string;
    updatedBy?: string | null;
    inspectedOn: string;
    description?: string | null;
    score?: number | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type CreateInspectionAssignmentMutationVariables = Exact<{
  assignment?: InputMaybe<InspectionAssignmentCreateInput>;
}>;

export type CreateInspectionAssignmentMutation = {
  __typename?: 'Mutation';
  createInspectionAssignment?: { __typename?: 'InspectionAssignment'; id: string } | null;
};

export type CreateInspectionAssignmentNotificationLinkMutationVariables = Exact<{
  assignmentNotificationLink?: InputMaybe<InspectionAssignmentNotificationLinkCreateInput>;
}>;

export type CreateInspectionAssignmentNotificationLinkMutation = {
  __typename?: 'Mutation';
  createInspectionAssignmentNotificationLink?: {
    __typename?: 'InspectionAssignmentNotificationLink';
    id: string;
  } | null;
};

export type UpdateInspectionAssignmentNotificationLinkMutationVariables = Exact<{
  assignmentNotificationLink?: InputMaybe<InspectionAssignmentNotificationLinkUpdateInput>;
}>;

export type UpdateInspectionAssignmentNotificationLinkMutation = {
  __typename?: 'Mutation';
  updateInspectionAssignmentNotificationLink?: {
    __typename?: 'InspectionAssignmentNotificationLink';
    id: string;
  } | null;
};

export type CreateInspectionAssignmentCategoryLinkMutationVariables = Exact<{
  assignmentCategoryLink?: InputMaybe<InspectionAssignmentCategoryLinkCreateInput>;
}>;

export type CreateInspectionAssignmentCategoryLinkMutation = {
  __typename?: 'Mutation';
  createInspectionAssignmentCategoryLink?: { __typename?: 'InspectionAssignmentCategoryLink'; id: string } | null;
};

export type UpdateInspectionAssignmentCategoryLinkMutationVariables = Exact<{
  assignmentCategoryLink?: InputMaybe<InspectionAssignmentCategoryLinkUpdateInput>;
}>;

export type UpdateInspectionAssignmentCategoryLinkMutation = {
  __typename?: 'Mutation';
  updateInspectionAssignmentCategoryLink?: { __typename?: 'InspectionAssignmentCategoryLink'; id: string } | null;
};

export type CreateInspectionQuestionMutationVariables = Exact<{
  question?: InputMaybe<InspectionQuestionCreateInput>;
}>;

export type CreateInspectionQuestionMutation = {
  __typename?: 'Mutation';
  createInspectionQuestion?: {
    __typename?: 'InspectionQuestion';
    id: string;
    question: string;
    enabled: boolean;
    order: number;
  } | null;
};

export type CreateInspectionQuestionSMutationVariables = Exact<{
  questions: Array<InspectionQuestionCreateInput> | InspectionQuestionCreateInput;
}>;

export type CreateInspectionQuestionSMutation = {
  __typename?: 'Mutation';
  createInspectionQuestions?: Array<{
    __typename?: 'InspectionQuestion';
    id: string;
    question: string;
    enabled: boolean;
    order: number;
  }> | null;
};

export type UpdateInspectionQuestionMutationVariables = Exact<{
  question?: InputMaybe<InspectionQuestionUpdateInput>;
}>;

export type UpdateInspectionQuestionMutation = {
  __typename?: 'Mutation';
  updateInspectionQuestion?: {
    __typename?: 'InspectionQuestion';
    id: string;
    question: string;
    enabled: boolean;
    order: number;
  } | null;
};

export type CreateMemberGroupMutationVariables = Exact<{
  memberGroup?: InputMaybe<MemberGroupCreateInput>;
}>;

export type CreateMemberGroupMutation = {
  __typename?: 'Mutation';
  createMemberGroup?: { __typename?: 'MemberGroup'; id: string } | null;
};

export type UpdateMemberGroupMutationVariables = Exact<{
  memberGroup?: InputMaybe<MemberGroupUpdateInput>;
}>;

export type UpdateMemberGroupMutation = {
  __typename?: 'Mutation';
  updateMemberGroup?: { __typename?: 'MemberGroup'; id: string } | null;
};

export type CreateMemberLockMutationVariables = Exact<{
  lock?: InputMaybe<MemberLockCreateInput>;
}>;

export type CreateMemberLockMutation = {
  __typename?: 'Mutation';
  createMemberLock?: {
    __typename?: 'MemberLock';
    id: string;
    effectiveDate?: string | null;
    memberId: string;
    createdOn: string;
  } | null;
};

export type UpdateMemberLockMutationVariables = Exact<{
  lock?: InputMaybe<MemberLockUpdateInput>;
}>;

export type UpdateMemberLockMutation = {
  __typename?: 'Mutation';
  updateMemberLock?: {
    __typename?: 'MemberLock';
    id: string;
    effectiveDate?: string | null;
    memberId: string;
    createdOn: string;
  } | null;
};

export type CreateMemberMutationVariables = Exact<{
  member?: InputMaybe<MemberCreateInput>;
}>;

export type CreateMemberMutation = {
  __typename?: 'Mutation';
  createMember?: {
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
  } | null;
};

export type UpdateMemberMutationVariables = Exact<{
  member?: InputMaybe<MemberUpdateInput>;
}>;

export type UpdateMemberMutation = {
  __typename?: 'Mutation';
  updateMember?: {
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
  } | null;
};

export type UpdateActiveMemberMutationVariables = Exact<{
  member?: InputMaybe<MemberUpdateInput>;
}>;

export type UpdateActiveMemberMutation = {
  __typename?: 'Mutation';
  updateMember?: {
    __typename?: 'Member';
    archivedOn?: string | null;
    canSurvey?: boolean | null;
    certifiedEmail?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    email?: string | null;
    emailVerificationRequired?: boolean | null;
    firstName?: string | null;
    gustoEmployeeId?: string | null;
    id: string;
    imageId?: string | null;
    imageUrl?: string | null;
    lastAccess?: string | null;
    lastName?: string | null;
    memberGroupId?: string | null;
    memberNumber?: string | null;
    memberSettings?: string | null;
    organizationId: string;
    pendingEmail?: string | null;
    phone?: string | null;
    positionId: string;
    submittedOn: string;
    timeLocationRequired?: string | null;
    updatedOn: string;
    username: string;
    usernameUnique: boolean;
    verifiedEmail?: boolean | null;
    memberGroup?: {
      __typename?: 'MemberGroup';
      id: string;
      groupName: string;
      deletedOn?: string | null;
      organizationId: string;
      updatedOn: string;
      createdOn: string;
      submittedOn: string;
    } | null;
    organization: {
      __typename?: 'Organization';
      authoritativeSignature: boolean;
      betaTester?: boolean | null;
      breakPolicy: boolean;
      breakPolicyCalifornia: boolean;
      breakPolicyMessage?: string | null;
      bulletin?: boolean | null;
      busySignInQuestion: boolean;
      createdOn: string;
      customSignOff?: boolean | null;
      defaultBreakDuration?: string | null;
      defaultEndTime?: string | null;
      defaultStartTime?: string | null;
      deletedOn?: string | null;
      demo: boolean;
      disclaimer?: string | null;
      gustoCompanyId?: string | null;
      hideUnassignedSubprojects: boolean;
      id: string;
      imageId?: string | null;
      inRecoveryMode?: string | null;
      lockOnSelfSignature?: boolean | null;
      minimumAppVersion?: string | null;
      organizationSettings?: string | null;
      organizationName: string;
      overtimeFlag: boolean;
      ownedBy: string;
      performanceScores?: boolean | null;
      requireCostCode: boolean;
      requireProject: boolean;
      revokedOn?: string | null;
      safetySignature: boolean;
      safetySignatureMessage?: string | null;
      signatureDate?: string | null;
      stripeAccountCreatedOn?: string | null;
      stripeAccountStatus?: string | null;
      stripeAccountType?: string | null;
      stripeCustomerId?: string | null;
      submittedOn: string;
      timeAccuracy: boolean;
      timeAccuracyMessage?: string | null;
      timeOffRequests: boolean;
      timeRounding: boolean;
      timeRoundingInterval: number;
      timeRoundingType: number;
      totalTimeFlag: number;
      trackBudgetCost?: boolean | null;
      trackBudgetEquipmentCosts?: boolean | null;
      trackBudgetEquipmentHours?: boolean | null;
      trackBudgetProgress?: boolean | null;
      trackBudgetProgressValue?: boolean | null;
      trackBudgets: boolean;
      trackCostCode: boolean;
      trackEquipment: boolean;
      trackPaidBreak: boolean;
      trackProject: boolean;
      updatedOn: string;
      useProjectCostCodeScoping?: boolean | null;
      webGps: boolean;
      minBreakSeconds?: number | null;
      perDiem?: boolean | null;
      perDiemAmountTracking?: boolean | null;
      perDiemRequestPermission?: PerDiemRequestPermission | null;
      employeeAutoInvite: EmployeeAutoInvite;
      organizationInfo?: {
        __typename?: 'OrganizationInfo';
        id: string;
        organizationId: string;
        longitude?: number | null;
        latitude?: number | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        county?: string | null;
        state?: string | null;
        postalCode?: string | null;
        country?: string | null;
        phone?: string | null;
        trade?: string | null;
        currencyCode?: string | null;
        employeeMin?: number | null;
        employeeMax?: number | null;
        createdOn: string;
        submittedOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      } | null;
      organizationPayPeriod?: Array<{
        __typename?: 'OrganizationPayPeriod';
        id: string;
        organizationId: string;
        payPeriodType: number;
        startDate: string;
        dayOne: number;
        dayTwo?: number | null;
        createdOn: string;
        submittedOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      }> | null;
      organizationOvertimePeriods?: Array<{
        __typename?: 'OrganizationOvertimePeriod';
        id: string;
        organizationId: string;
        startTime: string;
        lengthInDays?: number | null;
        weeklyOvertime?: number | null;
        dailyOvertime?: number | null;
        dailyDoubletime?: number | null;
        seventhDayOvertime?: boolean | null;
        seventhDayDoubletime?: number | null;
        saturdayMultiplier?: number | null;
        sundayMultiplier?: number | null;
        createdOn: string;
        submittedOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      }> | null;
      subscriptionStatus?: {
        __typename?: 'OrganizationSubscriptionStatus';
        annualSubscriptionCount?: number | null;
        annualSubscriptionDate?: string | null;
        annualSubscriptionInterval?: number | null;
        annualSubscriptionPendingInvoiceId?: number | null;
        cardLastFour?: string | null;
        cardType?: string | null;
        couponCode?: string | null;
        createdOn: string;
        customerId?: number | null;
        deletedOn?: string | null;
        exempt?: boolean | null;
        hasTrialed?: boolean | null;
        id: string;
        nextAssessmentAt?: string | null;
        organizationId: string;
        periodDay?: number | null;
        pricePointAction?: string | null;
        pricePointHandle?: string | null;
        productHandle?: string | null;
        referralCode?: string | null;
        status?: string | null;
        submittedOn: string;
        subscribedThroughProduct?: boolean | null;
        subscriptionId?: number | null;
        subscriptionUpdatedAt?: string | null;
        trialEndedAt?: string | null;
        updatedOn: string;
        userCanceled?: boolean | null;
      } | null;
    };
    position: {
      __typename?: 'Position';
      canEditPosition: boolean;
      canManageProjectCostCodeQuantity: number;
      canRequestTimeOff: boolean;
      clockInRestriction: ClockInRestrictionType;
      clockInRestrictionStartTime?: string | null;
      createdOn: string;
      dailyProjectPrompts: boolean;
      dailyProjectPromptsUseSubprojects: boolean;
      deletedOn?: string | null;
      equipmentSelectionRequirement: EquipmentSelectionOptions;
      id: string;
      level: number;
      manageBudgets: boolean;
      manageBulletinPosts?: boolean | null;
      manageCompanySettings: boolean;
      manageCostCodes: boolean;
      manageEmployeeGroups: boolean;
      manageEmployees: number;
      manageEquipment: boolean;
      manageGpsApproval: number;
      manageMediaCollection: number;
      managePerDiem: number;
      manageProjectGroups: boolean;
      manageProjects: boolean;
      manageSchedule: number;
      manageTimeEntries: number;
      manageTimeOff: number;
      memberLock: number;
      organizationId: string;
      requestSignatures: number;
      scopeByGroup: boolean;
      submittedOn: string;
      timeEvents: number;
      title: string;
      updatedOn: string;
      viewAllActivityReport?: boolean | null;
      viewBudgets: boolean;
      viewEquipmentDetails: boolean;
      viewEquipmentStatus: boolean;
      viewMediaCollection: number;
      viewMemberActivityReport?: number | null;
      viewMemberExpenses: boolean;
      viewProjectExpenses: boolean;
      viewSchedule: number;
      createIncident?: boolean | null;
      manageIncident?: boolean | null;
      performInspections?: boolean | null;
      manageInspections?: boolean | null;
    };
  } | null;
};

export type CreateMemberOvertimePeriodMutationVariables = Exact<{
  memberOvertimePeriod?: InputMaybe<MemberOvertimePeriodCreateInput>;
}>;

export type CreateMemberOvertimePeriodMutation = {
  __typename?: 'Mutation';
  createMemberOvertimePeriod?: {
    __typename?: 'MemberOvertimePeriod';
    id: string;
    memberId: string;
    changeDate: string;
    adhereToOrg: boolean;
    weeklyOvertime?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateMemberOvertimePeriodMutationVariables = Exact<{
  memberOvertimePeriod?: InputMaybe<MemberOvertimePeriodUpdateInput>;
}>;

export type UpdateMemberOvertimePeriodMutation = {
  __typename?: 'Mutation';
  updateMemberOvertimePeriod?: {
    __typename?: 'MemberOvertimePeriod';
    id: string;
    memberId: string;
    changeDate: string;
    adhereToOrg: boolean;
    weeklyOvertime?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type CreateMemberSignInAnswerMutationVariables = Exact<{
  answer?: InputMaybe<MemberSignInAnswerCreateInput>;
}>;

export type CreateMemberSignInAnswerMutation = {
  __typename?: 'Mutation';
  createMemberSignInAnswer?: { __typename?: 'MemberSignInAnswer'; id: string } | null;
};

export type UpdateMemberSignInAnswerMutationVariables = Exact<{
  answer?: InputMaybe<MemberSignInAnswerUpdateInput>;
}>;

export type UpdateMemberSignInAnswerMutation = {
  __typename?: 'Mutation';
  updateMemberSignInAnswer?: {
    __typename?: 'MemberSignInAnswer';
    id: string;
    flagged: boolean;
    resolverId?: string | null;
  } | null;
};

export type CreateMemberSignInSubmissionMutationVariables = Exact<{
  submission?: InputMaybe<MemberSignInSubmissionCreateInput>;
}>;

export type CreateMemberSignInSubmissionMutation = {
  __typename?: 'Mutation';
  createMemberSignInSubmission?: { __typename?: 'MemberSignInSubmission'; id: string } | null;
};

export type UpdateMemberSignInSubmissionMutationVariables = Exact<{
  submission?: InputMaybe<MemberSignInSubmissionUpdateInput>;
}>;

export type UpdateMemberSignInSubmissionMutation = {
  __typename?: 'Mutation';
  updateMemberSignInSubmission?: { __typename?: 'MemberSignInSubmission'; id: string } | null;
};

export type CreateMemberSignOffAnswerMutationVariables = Exact<{
  answer?: InputMaybe<MemberSignOffAnswerCreateInput>;
}>;

export type CreateMemberSignOffAnswerMutation = {
  __typename?: 'Mutation';
  createMemberSignOffAnswer?: {
    __typename?: 'MemberSignOffAnswer';
    id: string;
    answer?: string | null;
    audienceType: OrganizationSignOffQuestionAudience;
    flagged: boolean;
    memberId: string;
    submissionId: string;
    createdOn: string;
    localTime: string;
    questionTitle?: string | null;
    questionDescription?: string | null;
    questionPrompt?: string | null;
    description?: string | null;
  } | null;
};

export type UpdateMemberSignOffAnswerMutationVariables = Exact<{
  answer?: InputMaybe<MemberSignOffAnswerUpdateInput>;
}>;

export type UpdateMemberSignOffAnswerMutation = {
  __typename?: 'Mutation';
  updateMemberSignOffAnswer?: {
    __typename?: 'MemberSignOffAnswer';
    id: string;
    flagged: boolean;
    resolverId?: string | null;
  } | null;
};

export type CreateMemberTimeDocumentMutationVariables = Exact<{
  timeDocument?: InputMaybe<MemberTimeDocumentCreateInput>;
}>;

export type CreateMemberTimeDocumentMutation = {
  __typename?: 'Mutation';
  createMemberTimeDocument?: { __typename?: 'MemberTimeDocument'; id: string } | null;
};

export type UpdateMemberTimeDocumentMutationVariables = Exact<{
  timeDocument?: InputMaybe<MemberTimeDocumentUpdateInput>;
}>;

export type UpdateMemberTimeDocumentMutation = {
  __typename?: 'Mutation';
  updateMemberTimeDocument?: { __typename?: 'MemberTimeDocument'; id: string } | null;
};

export type OrganizationInfoMutationVariables = Exact<{
  organizationInfo?: InputMaybe<OrganizationInfoUpdateInput>;
}>;

export type OrganizationInfoMutation = {
  __typename?: 'Mutation';
  updateOrganizationInfo?: {
    __typename?: 'OrganizationInfo';
    id: string;
    organizationId: string;
    longitude?: number | null;
    latitude?: number | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    county?: string | null;
    state?: string | null;
    postalCode?: string | null;
    country?: string | null;
    phone?: string | null;
    trade?: string | null;
    currencyCode?: string | null;
    employeeMin?: number | null;
    employeeMax?: number | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type OrganizationMutationVariables = Exact<{
  organization?: InputMaybe<OrganizationUpdateInput>;
}>;

export type OrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'Organization';
    authoritativeSignature: boolean;
    betaTester?: boolean | null;
    breakPolicy: boolean;
    breakPolicyCalifornia: boolean;
    breakPolicyMessage?: string | null;
    bulletin?: boolean | null;
    busySignInQuestion: boolean;
    createdOn: string;
    customSignOff?: boolean | null;
    defaultBreakDuration?: string | null;
    defaultEndTime?: string | null;
    defaultStartTime?: string | null;
    deletedOn?: string | null;
    demo: boolean;
    disclaimer?: string | null;
    gustoCompanyId?: string | null;
    hideUnassignedSubprojects: boolean;
    id: string;
    imageId?: string | null;
    inRecoveryMode?: string | null;
    lockOnSelfSignature?: boolean | null;
    minimumAppVersion?: string | null;
    organizationSettings?: string | null;
    organizationName: string;
    overtimeFlag: boolean;
    ownedBy: string;
    performanceScores?: boolean | null;
    requireCostCode: boolean;
    requireProject: boolean;
    revokedOn?: string | null;
    safetySignature: boolean;
    safetySignatureMessage?: string | null;
    signatureDate?: string | null;
    stripeAccountCreatedOn?: string | null;
    stripeAccountStatus?: string | null;
    stripeAccountType?: string | null;
    stripeCustomerId?: string | null;
    submittedOn: string;
    timeAccuracy: boolean;
    timeAccuracyMessage?: string | null;
    timeOffRequests: boolean;
    timeRounding: boolean;
    timeRoundingInterval: number;
    timeRoundingType: number;
    totalTimeFlag: number;
    trackBudgetCost?: boolean | null;
    trackBudgetEquipmentCosts?: boolean | null;
    trackBudgetEquipmentHours?: boolean | null;
    trackBudgetProgress?: boolean | null;
    trackBudgetProgressValue?: boolean | null;
    trackBudgets: boolean;
    trackCostCode: boolean;
    trackEquipment: boolean;
    trackPaidBreak: boolean;
    trackProject: boolean;
    updatedOn: string;
    useProjectCostCodeScoping?: boolean | null;
    webGps: boolean;
    minBreakSeconds?: number | null;
    perDiem?: boolean | null;
    perDiemAmountTracking?: boolean | null;
    perDiemRequestPermission?: PerDiemRequestPermission | null;
    employeeAutoInvite: EmployeeAutoInvite;
    organizationInfo?: {
      __typename?: 'OrganizationInfo';
      id: string;
      organizationId: string;
      longitude?: number | null;
      latitude?: number | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      county?: string | null;
      state?: string | null;
      postalCode?: string | null;
      country?: string | null;
      phone?: string | null;
      trade?: string | null;
      currencyCode?: string | null;
      employeeMin?: number | null;
      employeeMax?: number | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    } | null;
    organizationPayPeriod?: Array<{
      __typename?: 'OrganizationPayPeriod';
      id: string;
      organizationId: string;
      payPeriodType: number;
      startDate: string;
      dayOne: number;
      dayTwo?: number | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
    organizationOvertimePeriods?: Array<{
      __typename?: 'OrganizationOvertimePeriod';
      id: string;
      organizationId: string;
      startTime: string;
      lengthInDays?: number | null;
      weeklyOvertime?: number | null;
      dailyOvertime?: number | null;
      dailyDoubletime?: number | null;
      seventhDayOvertime?: boolean | null;
      seventhDayDoubletime?: number | null;
      saturdayMultiplier?: number | null;
      sundayMultiplier?: number | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
    subscriptionStatus?: {
      __typename?: 'OrganizationSubscriptionStatus';
      annualSubscriptionCount?: number | null;
      annualSubscriptionDate?: string | null;
      annualSubscriptionInterval?: number | null;
      annualSubscriptionPendingInvoiceId?: number | null;
      cardLastFour?: string | null;
      cardType?: string | null;
      couponCode?: string | null;
      createdOn: string;
      customerId?: number | null;
      deletedOn?: string | null;
      exempt?: boolean | null;
      hasTrialed?: boolean | null;
      id: string;
      nextAssessmentAt?: string | null;
      organizationId: string;
      periodDay?: number | null;
      pricePointAction?: string | null;
      pricePointHandle?: string | null;
      productHandle?: string | null;
      referralCode?: string | null;
      status?: string | null;
      submittedOn: string;
      subscribedThroughProduct?: boolean | null;
      subscriptionId?: number | null;
      subscriptionUpdatedAt?: string | null;
      trialEndedAt?: string | null;
      updatedOn: string;
      userCanceled?: boolean | null;
    } | null;
  } | null;
};

export type OrganizationNotificationPreferencesCreateMutationMutationVariables = Exact<{
  organizationNotificationPreferencesCreateInput: OrganizationNotificationPreferencesCreateInput;
}>;

export type OrganizationNotificationPreferencesCreateMutationMutation = {
  __typename?: 'Mutation';
  createOrganizationNotificationPreferences?: {
    __typename?: 'OrganizationNotificationPreferences';
    id: string;
    memberId: string;
    injuryReport: number;
    inaccurateTimeReport: number;
    breakReport: number;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  } | null;
};

export type OrganizationNotificationPreferencesUpdateMutationMutationVariables = Exact<{
  organizationNotificationPreferencesUpdateInput: OrganizationNotificationPreferencesUpdateInput;
}>;

export type OrganizationNotificationPreferencesUpdateMutationMutation = {
  __typename?: 'Mutation';
  updateOrganizationNotificationPreferences?: {
    __typename?: 'OrganizationNotificationPreferences';
    id: string;
    memberId: string;
    injuryReport: number;
    inaccurateTimeReport: number;
    breakReport: number;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  } | null;
};

export type CreateOrganizationOvertimePeriodMutationVariables = Exact<{
  organizationOvertimePeriod?: InputMaybe<OrganizationOvertimePeriodCreateInput>;
}>;

export type CreateOrganizationOvertimePeriodMutation = {
  __typename?: 'Mutation';
  createOrganizationOvertimePeriod?: {
    __typename?: 'OrganizationOvertimePeriod';
    id: string;
    organizationId: string;
    startTime: string;
    weeklyOvertime?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    lengthInDays?: number | null;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateOrganizationOvertimePeriodMutationVariables = Exact<{
  organizationOvertimePeriod?: InputMaybe<OrganizationOvertimePeriodUpdateInput>;
}>;

export type UpdateOrganizationOvertimePeriodMutation = {
  __typename?: 'Mutation';
  updateOrganizationOvertimePeriod?: {
    __typename?: 'OrganizationOvertimePeriod';
    id: string;
    organizationId: string;
    startTime: string;
    weeklyOvertime?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    lengthInDays?: number | null;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type CreateOrganizationPayPeriodMutationVariables = Exact<{
  organizationPayPeriod?: InputMaybe<OrganizationPayPeriodCreateInput>;
}>;

export type CreateOrganizationPayPeriodMutation = {
  __typename?: 'Mutation';
  createOrganizationPayPeriod?: {
    __typename?: 'OrganizationPayPeriod';
    id: string;
    organizationId: string;
    startDate: string;
    payPeriodType: number;
    dayOne: number;
    dayTwo?: number | null;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateOrganizationPayPeriodMutationVariables = Exact<{
  organizationPayPeriod?: InputMaybe<OrganizationPayPeriodUpdateInput>;
}>;

export type UpdateOrganizationPayPeriodMutation = {
  __typename?: 'Mutation';
  updateOrganizationPayPeriod?: {
    __typename?: 'OrganizationPayPeriod';
    id: string;
    organizationId: string;
    startDate: string;
    payPeriodType: number;
    dayOne: number;
    dayTwo?: number | null;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type CreatePositionMutationVariables = Exact<{
  position?: InputMaybe<PositionCreateInput>;
}>;

export type CreatePositionMutation = {
  __typename?: 'Mutation';
  createPosition?: {
    __typename?: 'Position';
    canEditPosition: boolean;
    canManageProjectCostCodeQuantity: number;
    createdOn: string;
    dailyProjectPrompts: boolean;
    dailyProjectPromptsUseSubprojects: boolean;
    deletedOn?: string | null;
    equipmentSelectionRequirement: EquipmentSelectionOptions;
    id: string;
    level: number;
    manageBudgets: boolean;
    manageBulletinPosts?: boolean | null;
    manageCompanySettings: boolean;
    manageCostCodes: boolean;
    manageEmployeeGroups: boolean;
    manageEmployees: number;
    manageEquipment: boolean;
    manageMediaCollection: number;
    managePerDiem: number;
    manageProjectGroups: boolean;
    manageProjects: boolean;
    manageTimeEntries: number;
    manageGpsApproval: number;
    memberLock: number;
    organizationId: string;
    requestSignatures: number;
    scopeByGroup: boolean;
    submittedOn: string;
    timeEvents: number;
    title: string;
    updatedOn: string;
    viewAllActivityReport?: boolean | null;
    viewBudgets: boolean;
    viewEquipmentDetails: boolean;
    viewEquipmentStatus: boolean;
    viewMemberActivityReport?: number | null;
    viewMemberExpenses: boolean;
    viewProjectExpenses: boolean;
  } | null;
};

export type UpdatePositionMutationVariables = Exact<{
  position?: InputMaybe<PositionUpdateInput>;
}>;

export type UpdatePositionMutation = {
  __typename?: 'Mutation';
  updatePosition?: {
    __typename?: 'Position';
    canEditPosition: boolean;
    canManageProjectCostCodeQuantity: number;
    createdOn: string;
    dailyProjectPrompts: boolean;
    dailyProjectPromptsUseSubprojects: boolean;
    deletedOn?: string | null;
    equipmentSelectionRequirement: EquipmentSelectionOptions;
    id: string;
    level: number;
    manageBudgets: boolean;
    manageCompanySettings: boolean;
    manageCostCodes: boolean;
    manageEmployeeGroups: boolean;
    manageEmployees: number;
    manageEquipment: boolean;
    manageMediaCollection: number;
    managePerDiem: number;
    manageProjectGroups: boolean;
    manageProjects: boolean;
    manageTimeEntries: number;
    manageGpsApproval: number;
    memberLock: number;
    organizationId: string;
    requestSignatures: number;
    scopeByGroup: boolean;
    submittedOn: string;
    timeEvents: number;
    title: string;
    updatedOn: string;
    viewAllActivityReport?: boolean | null;
    viewBudgets: boolean;
    viewEquipmentDetails: boolean;
    viewEquipmentStatus: boolean;
    viewMemberActivityReport?: number | null;
    viewMemberExpenses: boolean;
    viewProjectExpenses: boolean;
  } | null;
};

export type CreateProjectCostCodeQuantityMutationVariables = Exact<{
  projectCostCodeQuantity: ProjectCostCodeQuantityCreateInput;
}>;

export type CreateProjectCostCodeQuantityMutation = {
  __typename?: 'Mutation';
  createProjectCostCodeQuantity?: {
    __typename?: 'ProjectCostCodeQuantity';
    id: string;
    amount: number;
    assignedOn: string;
    costCodeId: string;
    createdOnLocal: string;
    description?: string | null;
    projectId: string;
    submittedBy: string;
    type?: string | null;
    lastEditedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    costCode: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    };
    project: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        canManageProjectCostCodeQuantity: number;
        timeEvents: number;
      };
    };
  } | null;
};

export type UpdateProjectCostCodeQuantityMutationVariables = Exact<{
  projectCostCodeQuantity: ProjectCostCodeQuantityUpdateInput;
}>;

export type UpdateProjectCostCodeQuantityMutation = {
  __typename?: 'Mutation';
  updateProjectCostCodeQuantity?: {
    __typename?: 'ProjectCostCodeQuantity';
    id: string;
    amount: number;
    assignedOn: string;
    costCodeId: string;
    createdOnLocal: string;
    description?: string | null;
    projectId: string;
    submittedBy: string;
    type?: string | null;
    lastEditedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    costCode: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    };
    project: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
  } | null;
};

export type CreateProjectGroupMutationVariables = Exact<{
  projectGroup?: InputMaybe<ProjectGroupCreateInput>;
}>;

export type CreateProjectGroupMutation = {
  __typename?: 'Mutation';
  createProjectGroup?: { __typename?: 'ProjectGroup'; id: string } | null;
};

export type UpdateProjectGroupMutationVariables = Exact<{
  projectGroup?: InputMaybe<ProjectGroupUpdateInput>;
}>;

export type UpdateProjectGroupMutation = {
  __typename?: 'Mutation';
  updateProjectGroup?: { __typename?: 'ProjectGroup'; id: string } | null;
};

export type CreateProject3MutationVariables = Exact<{
  project?: InputMaybe<ProjectCreateInput>;
}>;

export type CreateProject3Mutation = {
  __typename?: 'Mutation';
  createProject?: { __typename?: 'Project'; id: string; title: string } | null;
};

export type UpdateProjectSimpleMutationVariables = Exact<{
  project?: InputMaybe<ProjectUpdateInput>;
}>;

export type UpdateProjectSimpleMutation = {
  __typename?: 'Mutation';
  updateProject?: { __typename?: 'Project'; id: string; title: string } | null;
};

export type CreateRecurringInspectionAssignmentCategoryLinkMutationVariables = Exact<{
  assignmentCategoryLink?: InputMaybe<RecurringInspectionAssignmentCategoryLinkCreateInput>;
}>;

export type CreateRecurringInspectionAssignmentCategoryLinkMutation = {
  __typename?: 'Mutation';
  createRecurringInspectionAssignmentCategoryLink?: {
    __typename?: 'RecurringInspectionAssignmentCategoryLink';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    categoryId: string;
    category: {
      __typename?: 'InspectionCategory';
      id: string;
      checklistId: string;
      name: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    };
  } | null;
};

export type UpdateRecurringInspectionAssignmentCategoryLinkMutationVariables = Exact<{
  assignmentCategoryLink?: InputMaybe<RecurringInspectionAssignmentCategoryLinkUpdateInput>;
}>;

export type UpdateRecurringInspectionAssignmentCategoryLinkMutation = {
  __typename?: 'Mutation';
  updateRecurringInspectionAssignmentCategoryLink?: {
    __typename?: 'RecurringInspectionAssignmentCategoryLink';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    categoryId: string;
    category: {
      __typename?: 'InspectionCategory';
      id: string;
      checklistId: string;
      name: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    };
  } | null;
};

export type CreateRecurringInspectionAssignmentMutationVariables = Exact<{
  assignment?: InputMaybe<RecurringInspectionAssignmentCreateInput>;
}>;

export type CreateRecurringInspectionAssignmentMutation = {
  __typename?: 'Mutation';
  createRecurringInspectionAssignment?: {
    __typename?: 'RecurringInspectionAssignment';
    id: string;
    pausedOn?: string | null;
    resumedOn?: string | null;
    startDate: string;
    endDate?: string | null;
    frequency: RecurringInspectionAssignmentFrequencyTypes;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    dayOfMonth?: number | null;
    monthOfYear?: number | null;
    sendNotification: boolean;
    description?: string | null;
    notifySubmission: boolean;
    notificationTime?: string | null;
    notifyCircumstance?: RecurringInspectionNotifyCircumstanceTypes | null;
    circumstanceValue?: number | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; depth: number; title: string }> | null;
    } | null;
    checklist: {
      __typename?: 'InspectionChecklist';
      id: string;
      name: string;
      categories: Array<{
        __typename?: 'InspectionCategory';
        id: string;
        name: string;
        deletedOn?: string | null;
        questions: Array<{
          __typename?: 'InspectionQuestion';
          id: string;
          question: string;
          enabled: boolean;
          deletedOn?: string | null;
          order: number;
        }>;
      }>;
    };
    limitedCategories: Array<{
      __typename?: 'RecurringInspectionAssignmentCategoryLink';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      assignmentId: string;
      categoryId: string;
      category: {
        __typename?: 'InspectionCategory';
        id: string;
        checklistId: string;
        name: string;
        createdOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      };
    }>;
  } | null;
};

export type UpdateRecurringInspectionAssignmentMutationVariables = Exact<{
  assignment?: InputMaybe<RecurringInspectionAssignmentUpdateInput>;
}>;

export type UpdateRecurringInspectionAssignmentMutation = {
  __typename?: 'Mutation';
  updateRecurringInspectionAssignment?: {
    __typename?: 'RecurringInspectionAssignment';
    id: string;
    pausedOn?: string | null;
    resumedOn?: string | null;
    startDate: string;
    endDate?: string | null;
    frequency: RecurringInspectionAssignmentFrequencyTypes;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    dayOfMonth?: number | null;
    monthOfYear?: number | null;
    sendNotification: boolean;
    description?: string | null;
    notifySubmission: boolean;
    notificationTime?: string | null;
    notifyCircumstance?: RecurringInspectionNotifyCircumstanceTypes | null;
    circumstanceValue?: number | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; depth: number; title: string }> | null;
    } | null;
    checklist: {
      __typename?: 'InspectionChecklist';
      id: string;
      name: string;
      categories: Array<{
        __typename?: 'InspectionCategory';
        id: string;
        name: string;
        deletedOn?: string | null;
        questions: Array<{
          __typename?: 'InspectionQuestion';
          id: string;
          question: string;
          enabled: boolean;
          deletedOn?: string | null;
          order: number;
        }>;
      }>;
    };
    limitedCategories: Array<{
      __typename?: 'RecurringInspectionAssignmentCategoryLink';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      assignmentId: string;
      categoryId: string;
      category: {
        __typename?: 'InspectionCategory';
        id: string;
        checklistId: string;
        name: string;
        createdOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      };
    }>;
  } | null;
};

export type CreateRecurringInspectionAssignmentNotificationLinkMutationVariables = Exact<{
  assignmentNotificationLink?: InputMaybe<RecurringInspectionAssignmentNotificationLinkCreateInput>;
}>;

export type CreateRecurringInspectionAssignmentNotificationLinkMutation = {
  __typename?: 'Mutation';
  createRecurringInspectionAssignmentNotificationLink?: {
    __typename?: 'RecurringInspectionAssignmentNotificationLink';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    memberId: string;
  } | null;
};

export type UpdateRecurringInspectionASsignmentNotificationLinkMutationVariables = Exact<{
  assignmentNotificationLink?: InputMaybe<RecurringInspectionAssignmentNotificationLinkUpdateInput>;
}>;

export type UpdateRecurringInspectionASsignmentNotificationLinkMutation = {
  __typename?: 'Mutation';
  updateRecurringInspectionAssignmentNotificationLink?: {
    __typename?: 'RecurringInspectionAssignmentNotificationLink';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    memberId: string;
  } | null;
};

export type CreateSafetySignatureMutationVariables = Exact<{
  safetySignature?: InputMaybe<SafetySignatureCreateInput>;
}>;

export type CreateSafetySignatureMutation = {
  __typename?: 'Mutation';
  createSafetySignature?: { __typename?: 'SafetySignature'; id: string } | null;
};

export type UpdateSafetySignatureMutationVariables = Exact<{
  safetySignature?: InputMaybe<SafetySignatureUpdateInput>;
}>;

export type UpdateSafetySignatureMutation = {
  __typename?: 'Mutation';
  updateSafetySignature?: { __typename?: 'SafetySignature'; id: string } | null;
};

export type CreateScheduleReminderMutationVariables = Exact<{
  reminder?: InputMaybe<ScheduleReminderCreateInput>;
}>;

export type CreateScheduleReminderMutation = {
  __typename?: 'Mutation';
  createScheduleReminder?: {
    __typename?: 'ScheduleReminder';
    id: string;
    organizationId: string;
    memberId: string;
    startTime: string;
    endTime: string;
    payload?: string | null;
    type: string;
    createdOn: string;
    notifyEmployee?: boolean | null;
    overrideRecurringScheduleId?: string | null;
    links: Array<{
      __typename?: 'ScheduleReminderLink';
      id: string;
      scheduleReminderId: string;
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
      };
    }>;
  } | null;
};

export type UpdateScheduleReminderMutationVariables = Exact<{
  reminder?: InputMaybe<ScheduleReminderUpdateInput>;
}>;

export type UpdateScheduleReminderMutation = {
  __typename?: 'Mutation';
  updateScheduleReminder?: {
    __typename?: 'ScheduleReminder';
    id: string;
    organizationId: string;
    memberId: string;
    startTime: string;
    endTime: string;
    payload?: string | null;
    type: string;
    createdOn: string;
    deletedOn?: string | null;
    notifyEmployee?: boolean | null;
    overrideRecurringScheduleId?: string | null;
    links: Array<{
      __typename?: 'ScheduleReminderLink';
      id: string;
      scheduleReminderId: string;
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
      };
    }>;
  } | null;
};

export type CreateScheduleReminderLinkMutationVariables = Exact<{
  link?: InputMaybe<ScheduleReminderLinkCreateInput>;
}>;

export type CreateScheduleReminderLinkMutation = {
  __typename?: 'Mutation';
  createScheduleReminderLink?: {
    __typename?: 'ScheduleReminderLink';
    id: string;
    memberId: string;
    scheduleReminderId: string;
  } | null;
};

export type UpdateScheduleReminderLinkMutationVariables = Exact<{
  link?: InputMaybe<ScheduleReminderLinkUpdateInput>;
}>;

export type UpdateScheduleReminderLinkMutation = {
  __typename?: 'Mutation';
  updateScheduleReminderLink?: {
    __typename?: 'ScheduleReminderLink';
    id: string;
    memberId: string;
    scheduleReminderId: string;
    deletedOn?: string | null;
  } | null;
};

export type CreateTimeEntryMutationVariables = Exact<{
  entry?: InputMaybe<TimeEntryCreateInput>;
}>;

export type CreateTimeEntryMutation = {
  __typename?: 'Mutation';
  createTimeEntry?: {
    __typename?: 'TimeEntry';
    id: string;
    startTime: string;
    endTime?: string | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    member: { __typename?: 'Member'; id: string };
    project?: { __typename?: 'Project'; id: string } | null;
    costCode?: { __typename?: 'CostCode'; id: string } | null;
    equipment?: { __typename?: 'Equipment'; id: string } | null;
  } | null;
};

export type UpdateTimeEntryMutationVariables = Exact<{
  entry?: InputMaybe<TimeEntryUpdateInput>;
}>;

export type UpdateTimeEntryMutation = {
  __typename?: 'Mutation';
  updateTimeEntry?: {
    __typename?: 'TimeEntry';
    id: string;
    startTime: string;
    endTime?: string | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    member: { __typename?: 'Member'; id: string };
    project?: { __typename?: 'Project'; id: string } | null;
    costCode?: { __typename?: 'CostCode'; id: string } | null;
    equipment?: { __typename?: 'Equipment'; id: string } | null;
  } | null;
};

export type CreateTimeEntriesMutationVariables = Exact<{
  entries: Array<TimeEntryCreateInput> | TimeEntryCreateInput;
}>;

export type CreateTimeEntriesMutation = {
  __typename?: 'Mutation';
  createTimeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    startTime: string;
    endTime?: string | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    member: { __typename?: 'Member'; id: string };
    project?: { __typename?: 'Project'; id: string } | null;
    costCode?: { __typename?: 'CostCode'; id: string } | null;
    equipment?: { __typename?: 'Equipment'; id: string } | null;
  }> | null;
};

export type UpdateTimeEntriesMutationVariables = Exact<{
  entries: Array<TimeEntryUpdateInput> | TimeEntryUpdateInput;
}>;

export type UpdateTimeEntriesMutation = {
  __typename?: 'Mutation';
  updateTimeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    startTime: string;
    endTime?: string | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    member: { __typename?: 'Member'; id: string };
    project?: { __typename?: 'Project'; id: string } | null;
    costCode?: { __typename?: 'CostCode'; id: string } | null;
    equipment?: { __typename?: 'Equipment'; id: string } | null;
  }> | null;
};

export type CreateTimeOffMutationVariables = Exact<{
  timeOff?: InputMaybe<TimeOffCreateInput>;
}>;

export type CreateTimeOffMutation = {
  __typename?: 'Mutation';
  createTimeOff?: { __typename?: 'TimeOff'; id: string } | null;
};

export type CreateTimeOffsMutationVariables = Exact<{
  timeOffs: Array<TimeOffCreateInput> | TimeOffCreateInput;
}>;

export type CreateTimeOffsMutation = {
  __typename?: 'Mutation';
  createTimeOffs?: Array<{ __typename?: 'TimeOff'; id: string }> | null;
};

export type UpdateTimeOffMutationVariables = Exact<{
  timeOff?: InputMaybe<TimeOffUpdateInput>;
}>;

export type UpdateTimeOffMutation = {
  __typename?: 'Mutation';
  updateTimeOff?: { __typename?: 'TimeOff'; id: string } | null;
};

export type UpdateTimeOffsMutationVariables = Exact<{
  timeOffs: Array<TimeOffUpdateInput> | TimeOffUpdateInput;
}>;

export type UpdateTimeOffsMutation = {
  __typename?: 'Mutation';
  updateTimeOffs?: Array<{ __typename?: 'TimeOff'; id: string }> | null;
};

export type UpdateTimeOffRequestsMutationVariables = Exact<{
  timeOffRequests: Array<TimeOffRequestUpdateInput> | TimeOffRequestUpdateInput;
}>;

export type UpdateTimeOffRequestsMutation = {
  __typename?: 'Mutation';
  updateTimeOffRequests?: Array<{
    __typename?: 'TimeOffRequest';
    id: string;
    answeredBy?: string | null;
    answeredOn?: string | null;
    approved?: boolean | null;
    createdOnDst: boolean;
    deletedOn?: string | null;
    description?: string | null;
    paid: boolean;
    dateStamp: string;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    memberId: string;
    rejectionReason?: string | null;
    seconds?: number | null;
    submittedBy: string;
    type: number;
    answeredByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  }> | null;
};

export type UpdateTimeOffRequestMutationVariables = Exact<{
  timeOffRequest?: InputMaybe<TimeOffRequestUpdateInput>;
}>;

export type UpdateTimeOffRequestMutation = {
  __typename?: 'Mutation';
  updateTimeOffRequest?: {
    __typename?: 'TimeOffRequest';
    id: string;
    answeredBy?: string | null;
    answeredOn?: string | null;
    approved?: boolean | null;
    createdOnDst: boolean;
    deletedOn?: string | null;
    description?: string | null;
    paid: boolean;
    dateStamp: string;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    memberId: string;
    rejectionReason?: string | null;
    seconds?: number | null;
    submittedBy: string;
    type: number;
    answeredByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  } | null;
};

export type CreateTimeOffRequestMutationVariables = Exact<{
  timeOffRequest?: InputMaybe<TimeOffRequestCreateInput>;
}>;

export type CreateTimeOffRequestMutation = {
  __typename?: 'Mutation';
  createTimeOffRequest?: {
    __typename?: 'TimeOffRequest';
    id: string;
    answeredBy?: string | null;
    answeredOn?: string | null;
    approved?: boolean | null;
    createdOnDst: boolean;
    deletedOn?: string | null;
    description?: string | null;
    paid: boolean;
    dateStamp: string;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    memberId: string;
    rejectionReason?: string | null;
    seconds?: number | null;
    submittedBy: string;
    type: number;
    answeredByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  } | null;
};

export type CreateTimeOffRequestsMutationVariables = Exact<{
  timeOffRequests: Array<TimeOffRequestCreateInput> | TimeOffRequestCreateInput;
}>;

export type CreateTimeOffRequestsMutation = {
  __typename?: 'Mutation';
  createTimeOffRequests?: Array<{
    __typename?: 'TimeOffRequest';
    id: string;
    answeredBy?: string | null;
    answeredOn?: string | null;
    approved?: boolean | null;
    createdOnDst: boolean;
    deletedOn?: string | null;
    description?: string | null;
    paid: boolean;
    dateStamp: string;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    memberId: string;
    rejectionReason?: string | null;
    seconds?: number | null;
    submittedBy: string;
    type: number;
    answeredByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  }> | null;
};

export type CreateWageHistoryMutationVariables = Exact<{
  wageHistory?: InputMaybe<WageHistoryCreateInput>;
}>;

export type CreateWageHistoryMutation = {
  __typename?: 'Mutation';
  createWageHistory?: {
    __typename?: 'WageHistory';
    id: string;
    memberId: string;
    wage: number;
    wageRate: number;
    workWeek?: number | null;
    effectiveRate?: number | null;
    overburden: number;
    changeDate: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateWageHistoryMutationVariables = Exact<{
  wageHistory?: InputMaybe<WageHistoryUpdateInput>;
}>;

export type UpdateWageHistoryMutation = {
  __typename?: 'Mutation';
  updateWageHistory?: {
    __typename?: 'WageHistory';
    id: string;
    memberId: string;
    wage: number;
    wageRate: number;
    workWeek?: number | null;
    effectiveRate?: number | null;
    overburden: number;
    changeDate: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type BusySignInQuestionsQueryVariables = Exact<{ [key: string]: never }>;

export type BusySignInQuestionsQuery = {
  __typename?: 'Query';
  busySignInQuestions?: Array<{
    __typename?: 'BusySignInQuestion';
    id: string;
    questionTitle: string;
    questionDescription?: string | null;
    questionPrompt?: string | null;
    type: string;
    required: boolean;
    choices?: string | null;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type CostCodeGroupQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CostCodeGroupQuery = {
  __typename?: 'Query';
  costCodeGroups?: Array<{
    __typename?: 'CostCodeGroup';
    id: string;
    organizationId: string;
    groupName: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type CostCodeTimeMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type CostCodeTimeMetricsQueryQuery = {
  __typename?: 'Query';
  costCodeLaborMetrics: Array<{
    __typename?: 'CostCodeLaborMetrics';
    breakDt?: number | null;
    breakOt?: number | null;
    breakRt?: number | null;
    dt?: number | null;
    end: string;
    ot?: number | null;
    rt?: number | null;
    start: string;
  }>;
};

export type CostCodeMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type CostCodeMetricsQueryQuery = {
  __typename?: 'Query';
  costCodeLaborMetrics: Array<{
    __typename?: 'CostCodeLaborMetrics';
    breakDt?: number | null;
    breakOt?: number | null;
    breakRt?: number | null;
    dt?: number | null;
    dtCents?: number | null;
    end: string;
    ot?: number | null;
    otCents?: number | null;
    overburdenRtCents?: number | null;
    overburdenOtCents?: number | null;
    overburdenDtCents?: number | null;
    rt?: number | null;
    rtCents?: number | null;
    start: string;
  }>;
};

export type QueryCostCodesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryCostCodesQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    archivedOn?: string | null;
    costCode: string;
    costCodeGroupId?: string | null;
    createdOn: string;
    cursor: string;
    id: string;
    organizationId: string;
    submittedOn: string;
    title?: string | null;
    unitTitle?: string | null;
    updatedOn: string;
    workersCompId?: string | null;
    costCodeGroup?: {
      __typename?: 'CostCodeGroup';
      createdOn: string;
      cursor: string;
      deletedOn?: string | null;
      groupName: string;
      id: string;
      organizationId: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
  }> | null;
};

export type ValidateProjectCostCodeQueryVariables = Exact<{
  projectId: Scalars['Uuid']['input'];
  costCodeId: Scalars['Uuid']['input'];
}>;

export type ValidateProjectCostCodeQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    archivedOn?: string | null;
    costCode: string;
    costCodeGroupId?: string | null;
    createdOn: string;
    cursor: string;
    id: string;
    organizationId: string;
    submittedOn: string;
    title?: string | null;
    unitTitle?: string | null;
    updatedOn: string;
    workersCompId?: string | null;
  }> | null;
};

export type QueryCostCodesNamesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryCostCodesNamesQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    cursor: string;
    id: string;
    title?: string | null;
    costCode: string;
  }> | null;
};

export type QueryCostCodesNamesWithArchivedQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  sort?: InputMaybe<Array<CostCodeSort> | CostCodeSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryCostCodesNamesWithArchivedQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    cursor: string;
    id: string;
    title?: string | null;
    costCode: string;
    archivedOn?: string | null;
    unitTitle?: string | null;
  }> | null;
};

export type QueryCostCodeIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryCostCodeIdsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{ __typename?: 'CostCode'; cursor: string; id: string }> | null;
};

export type CostCodeClockStateQueryVariables = Exact<{
  filter?: InputMaybe<CostCodeClockStateFilter>;
}>;

export type CostCodeClockStateQuery = {
  __typename?: 'Query';
  costCodeClockState: {
    __typename?: 'CostCodeClockState';
    all: number;
    clockedIn: number;
    clockedOut: number;
    onBreak: number;
  };
};

export type QueryEquipmentHoursQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentHoursFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<EquipmentHoursSort> | EquipmentHoursSort>;
}>;

export type QueryEquipmentHoursQuery = {
  __typename?: 'Query';
  equipmentHours?: Array<{
    __typename?: 'EquipmentHours';
    id: string;
    equipmentId: string;
    runningHours?: number | null;
    submittedByMemberId?: string | null;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type QueryEquipmentHoursReportQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentHoursFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<EquipmentHoursSort> | EquipmentHoursSort>;
}>;

export type QueryEquipmentHoursReportQuery = {
  __typename?: 'Query';
  equipmentHours?: Array<{
    __typename?: 'EquipmentHours';
    id: string;
    equipmentId: string;
    runningHours?: number | null;
    submittedByMemberId?: string | null;
    createdOn: string;
    cursor: string;
    submittedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type QueryEquipmentHoursReportDetailsQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentHoursFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<EquipmentHoursSort> | EquipmentHoursSort>;
}>;

export type QueryEquipmentHoursReportDetailsQuery = {
  __typename?: 'Query';
  equipmentHours?: Array<{
    __typename?: 'EquipmentHours';
    id: string;
    equipmentId: string;
    runningHours?: number | null;
    submittedByMemberId?: string | null;
    createdOn: string;
    cursor: string;
    equipment: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      } | null;
    };
    submittedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type EquipmentTimeMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type EquipmentTimeMetricsQueryQuery = {
  __typename?: 'Query';
  equipmentLaborMetrics: Array<{
    __typename?: 'EquipmentLaborMetrics';
    breakDt?: number | null;
    breakOt?: number | null;
    breakRt?: number | null;
    dt?: number | null;
    end: string;
    ot?: number | null;
    rt?: number | null;
    start: string;
  }>;
};

export type EquipmentMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type EquipmentMetricsQueryQuery = {
  __typename?: 'Query';
  equipmentLaborMetrics: Array<{
    __typename?: 'EquipmentLaborMetrics';
    breakDt?: number | null;
    breakOt?: number | null;
    breakRt?: number | null;
    dt?: number | null;
    dtCents?: number | null;
    end: string;
    ot?: number | null;
    otCents?: number | null;
    overburdenRtCents?: number | null;
    overburdenOtCents?: number | null;
    overburdenDtCents?: number | null;
    rt?: number | null;
    rtCents?: number | null;
    start: string;
    equipmentCents?: number | null;
  }>;
};

export type QueryEquipmentQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryEquipmentQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    equipmentGroupId?: string | null;
    equipmentModelId?: string | null;
    equipmentName: string;
    fuelCapacity?: string | null;
    id: string;
    imageId?: string | null;
    importTtlSeconds?: number | null;
    importType?: string | null;
    lastHoursId?: string | null;
    imageUrl?: string | null;
    lastLocationId?: string | null;
    organizationId: string;
    serialNumber?: string | null;
    submittedOn: string;
    trackManualHours: boolean;
    updatedOn: string;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null; updatedOn: string } | null;
    lastLocation?: { __typename?: 'EquipmentLocation'; id: string; longitude: number; latitude: number } | null;
    model?: {
      __typename?: 'EquipmentModel';
      deletedOn?: string | null;
      equipmentCategoryId: string;
      equipmentMakeId: string;
      id: string;
      imageId?: string | null;
      modelNumber?: string | null;
      submittedOn: string;
      title?: string | null;
      unknown?: boolean | null;
      updatedOn: string;
      year?: number | null;
      category: {
        __typename?: 'EquipmentCategory';
        deletedOn?: string | null;
        equipmentTypeId: string;
        id: string;
        imageId?: string | null;
        review?: boolean | null;
        submittedOn: string;
        title?: string | null;
        updatedOn: string;
        type: { __typename?: 'EquipmentType'; id: string; title?: string | null };
      };
      make: {
        __typename?: 'EquipmentMake';
        deletedOn?: string | null;
        id: string;
        imageId?: string | null;
        review: boolean;
        submittedOn: string;
        title?: string | null;
        unknown?: boolean | null;
        updatedOn: string;
      };
    } | null;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type QueryEquipmentNameQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryEquipmentNameQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    equipmentName: string;
    organizationId: string;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      modelNumber?: string | null;
      title?: string | null;
      year?: number | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
  }> | null;
};

export type QueryBasicEquipmentNameQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryBasicEquipmentNameQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    equipmentName: string;
    organizationId: string;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      modelNumber?: string | null;
      title?: string | null;
      year?: number | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithCategoryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
}>;

export type QueryBasicEquipmentWithCategoryQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    cursor: string;
    id: string;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
    } | null;
  }> | null;
};

export type QueryEquipmentByIdQueryVariables = Exact<{
  equipmentId: Scalars['Uuid']['input'];
}>;

export type QueryEquipmentByIdQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    equipmentGroupId?: string | null;
    equipmentModelId?: string | null;
    equipmentName: string;
    fuelCapacity?: string | null;
    id: string;
    imageId?: string | null;
    imageUrl?: string | null;
    importTtlSeconds?: number | null;
    importType?: string | null;
    lastHoursId?: string | null;
    lastLocationId?: string | null;
    organizationId: string;
    serialNumber?: string | null;
    submittedOn: string;
    trackManualHours: boolean;
    updatedOn: string;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null } | null;
    model?: {
      __typename?: 'EquipmentModel';
      deletedOn?: string | null;
      equipmentCategoryId: string;
      equipmentMakeId: string;
      id: string;
      imageId?: string | null;
      imageUrl?: string | null;
      modelNumber?: string | null;
      submittedOn: string;
      title?: string | null;
      unknown?: boolean | null;
      updatedOn: string;
      year?: number | null;
      category: {
        __typename?: 'EquipmentCategory';
        deletedOn?: string | null;
        equipmentTypeId: string;
        id: string;
        imageId?: string | null;
        imageUrl?: string | null;
        review?: boolean | null;
        submittedOn: string;
        title?: string | null;
        updatedOn: string;
      };
      make: {
        __typename?: 'EquipmentMake';
        deletedOn?: string | null;
        id: string;
        imageId?: string | null;
        review: boolean;
        submittedOn: string;
        title?: string | null;
        unknown?: boolean | null;
        updatedOn: string;
      };
    } | null;
    lastLocation?: { __typename?: 'EquipmentLocation'; id: string; longitude: number; latitude: number } | null;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type QueryManageEquipmentQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
}>;

export type QueryManageEquipmentQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    equipmentGroupId?: string | null;
    equipmentModelId?: string | null;
    equipmentName: string;
    fuelCapacity?: string | null;
    id: string;
    imageId?: string | null;
    imageUrl?: string | null;
    importTtlSeconds?: number | null;
    importType?: string | null;
    lastHoursId?: string | null;
    lastLocationId?: string | null;
    organizationId: string;
    serialNumber?: string | null;
    submittedOn: string;
    trackManualHours: boolean;
    updatedOn: string;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    model?: {
      __typename?: 'EquipmentModel';
      deletedOn?: string | null;
      equipmentCategoryId: string;
      equipmentMakeId: string;
      id: string;
      imageId?: string | null;
      imageUrl?: string | null;
      modelNumber?: string | null;
      submittedOn: string;
      title?: string | null;
      unknown?: boolean | null;
      updatedOn: string;
      year?: number | null;
      category: {
        __typename?: 'EquipmentCategory';
        deletedOn?: string | null;
        equipmentTypeId: string;
        id: string;
        imageId?: string | null;
        imageUrl?: string | null;
        review?: boolean | null;
        submittedOn: string;
        title?: string | null;
        updatedOn: string;
        type: { __typename?: 'EquipmentType'; id: string; title?: string | null };
      };
      make: {
        __typename?: 'EquipmentMake';
        deletedOn?: string | null;
        id: string;
        imageId?: string | null;
        review: boolean;
        submittedOn: string;
        title?: string | null;
        unknown?: boolean | null;
        updatedOn: string;
      };
    } | null;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null } | null;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type QueryEquipmentIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
}>;

export type QueryEquipmentIdsQuery = {
  __typename?: 'Query';
  equipment?: Array<{ __typename?: 'Equipment'; id: string; cursor: string }> | null;
};

export type QueryBasicEquipmentQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
}>;

export type QueryBasicEquipmentQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
  }> | null;
};

export type QueryEquipmentLastLocationQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryEquipmentLastLocationQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    cursor: string;
    deletedOn?: string | null;
    equipmentName: string;
    lastLocationId?: string | null;
    lastLocation?: { __typename?: 'EquipmentLocation'; id: string; longitude: number; latitude: number } | null;
  }> | null;
};

export type EquipmentDisplayQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
}>;

export type EquipmentDisplayQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    equipmentName: string;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      year?: number | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
  }> | null;
};

export type EquipmentClockStateQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentClockStateFilter>;
}>;

export type EquipmentClockStateQuery = {
  __typename?: 'Query';
  equipmentClockState: {
    __typename?: 'EquipmentClockState';
    all: number;
    clockedIn: number;
    clockedOut: number;
    onBreak: number;
  };
};

export type QueryInspectionChecklistsQueryVariables = Exact<{
  filter?: InputMaybe<InspectionChecklistFilter>;
  sort?: InputMaybe<Array<InspectionChecklistSort> | InspectionChecklistSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryInspectionChecklistsQuery = {
  __typename?: 'Query';
  inspectionChecklists?: Array<{
    __typename?: 'InspectionChecklist';
    cursor: string;
    id: string;
    createdOn: string;
    deletedOn?: string | null;
    name: string;
    limitInspectors: boolean;
    categories: Array<{
      __typename?: 'InspectionCategory';
      id: string;
      name: string;
      createdOn: string;
      deletedOn?: string | null;
      checklistId: string;
      questions: Array<{
        __typename?: 'InspectionQuestion';
        id: string;
        question: string;
        order: number;
        enabled: boolean;
        deletedOn?: string | null;
      }>;
    }>;
    memberLinks: Array<{
      __typename?: 'InspectionChecklistMemberLink';
      id: string;
      memberId: string;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type BasicMemberLaborMetricsFragment = {
  __typename?: 'MemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  pto?: number | null;
  rt?: number | null;
  start: string;
  upto?: number | null;
};

export type MemberLaborMetricsFragment = {
  __typename?: 'MemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  pto?: number | null;
  ptoCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  upto?: number | null;
};

export type AllMemberLaborMetricsFragment = {
  __typename?: 'MemberLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  memberId: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  pto?: number | null;
  ptoCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  upto?: number | null;
};

export type BasicOrganizationLaborMetricsFragment = {
  __typename?: 'OrganizationLaborMetrics';
  dt?: number | null;
  end: string;
  ot?: number | null;
  pto?: number | null;
  rt?: number | null;
  start: string;
  upto?: number | null;
};

export type OrganizationLaborMetricsFragment = {
  __typename?: 'OrganizationLaborMetrics';
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  pto?: number | null;
  ptoCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  upto?: number | null;
};

export type AllOrganizationLaborMetricsFragment = {
  __typename?: 'OrganizationLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  pto?: number | null;
  ptoCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  upto?: number | null;
};

type BasicProjectLaborMetrics_CostCodeProjectLaborMetrics_Fragment = {
  __typename: 'CostCodeProjectLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_EquipmentProjectLaborMetrics_Fragment = {
  __typename: 'EquipmentProjectLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename: 'MemberProjectCostCodeEquipmentLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment = {
  __typename: 'MemberProjectCostCodeLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment = {
  __typename: 'MemberProjectEquipmentLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_MemberProjectLaborMetrics_Fragment = {
  __typename: 'MemberProjectLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename: 'ProjectCostCodeEquipmentLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_ProjectCostCodeLaborMetrics_Fragment = {
  __typename: 'ProjectCostCodeLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_ProjectEquipmentLaborMetrics_Fragment = {
  __typename: 'ProjectEquipmentLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_ProjectLaborMetrics_Fragment = {
  __typename: 'ProjectLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicProjectLaborMetrics_ProjectMemberLaborMetrics_Fragment = {
  __typename: 'ProjectMemberLaborMetrics';
  descDt?: number | null;
  descOt?: number | null;
  descRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

export type BasicProjectLaborMetricsFragment =
  | BasicProjectLaborMetrics_CostCodeProjectLaborMetrics_Fragment
  | BasicProjectLaborMetrics_EquipmentProjectLaborMetrics_Fragment
  | BasicProjectLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment
  | BasicProjectLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment
  | BasicProjectLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment
  | BasicProjectLaborMetrics_MemberProjectLaborMetrics_Fragment
  | BasicProjectLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment
  | BasicProjectLaborMetrics_ProjectCostCodeLaborMetrics_Fragment
  | BasicProjectLaborMetrics_ProjectEquipmentLaborMetrics_Fragment
  | BasicProjectLaborMetrics_ProjectLaborMetrics_Fragment
  | BasicProjectLaborMetrics_ProjectMemberLaborMetrics_Fragment;

type ProjectLaborMetrics_CostCodeProjectLaborMetrics_Fragment = {
  __typename?: 'CostCodeProjectLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_EquipmentProjectLaborMetrics_Fragment = {
  __typename?: 'EquipmentProjectLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectEquipmentLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_MemberProjectLaborMetrics_Fragment = {
  __typename?: 'MemberProjectLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_ProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_ProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectEquipmentLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_ProjectLaborMetrics_Fragment = {
  __typename?: 'ProjectLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type ProjectLaborMetrics_ProjectMemberLaborMetrics_Fragment = {
  __typename?: 'ProjectMemberLaborMetrics';
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

export type ProjectLaborMetricsFragment =
  | ProjectLaborMetrics_CostCodeProjectLaborMetrics_Fragment
  | ProjectLaborMetrics_EquipmentProjectLaborMetrics_Fragment
  | ProjectLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment
  | ProjectLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment
  | ProjectLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment
  | ProjectLaborMetrics_MemberProjectLaborMetrics_Fragment
  | ProjectLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment
  | ProjectLaborMetrics_ProjectCostCodeLaborMetrics_Fragment
  | ProjectLaborMetrics_ProjectEquipmentLaborMetrics_Fragment
  | ProjectLaborMetrics_ProjectLaborMetrics_Fragment
  | ProjectLaborMetrics_ProjectMemberLaborMetrics_Fragment;

type AllProjectLaborMetrics_CostCodeProjectLaborMetrics_Fragment = {
  __typename?: 'CostCodeProjectLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_EquipmentProjectLaborMetrics_Fragment = {
  __typename?: 'EquipmentProjectLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectEquipmentLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_MemberProjectLaborMetrics_Fragment = {
  __typename?: 'MemberProjectLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_ProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_ProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectEquipmentLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_ProjectLaborMetrics_Fragment = {
  __typename?: 'ProjectLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllProjectLaborMetrics_ProjectMemberLaborMetrics_Fragment = {
  __typename?: 'ProjectMemberLaborMetrics';
  descBreakDt?: number | null;
  descBreakDtCents?: number | null;
  descBreakOt?: number | null;
  descBreakOtCents?: number | null;
  descBreakOverburdenCents?: number | null;
  descBreakRt?: number | null;
  descBreakUpt?: number | null;
  descDt?: number | null;
  descDtCents?: number | null;
  descOt?: number | null;
  descOtCents?: number | null;
  descOverburdenRtCents?: number | null;
  descOverburdenOtCents?: number | null;
  descOverburdenDtCents?: number | null;
  descRt?: number | null;
  descRtCents?: number | null;
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  breakUpt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

export type AllProjectLaborMetricsFragment =
  | AllProjectLaborMetrics_CostCodeProjectLaborMetrics_Fragment
  | AllProjectLaborMetrics_EquipmentProjectLaborMetrics_Fragment
  | AllProjectLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment
  | AllProjectLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment
  | AllProjectLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment
  | AllProjectLaborMetrics_MemberProjectLaborMetrics_Fragment
  | AllProjectLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment
  | AllProjectLaborMetrics_ProjectCostCodeLaborMetrics_Fragment
  | AllProjectLaborMetrics_ProjectEquipmentLaborMetrics_Fragment
  | AllProjectLaborMetrics_ProjectLaborMetrics_Fragment
  | AllProjectLaborMetrics_ProjectMemberLaborMetrics_Fragment;

type BasicLaborMetrics_CostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'CostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_CostCodeLaborMetrics_Fragment = {
  __typename?: 'CostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_CostCodeMemberLaborMetrics_Fragment = {
  __typename?: 'CostCodeMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_CostCodeProjectLaborMetrics_Fragment = {
  __typename?: 'CostCodeProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_EquipmentCostCodeLaborMetrics_Fragment = {
  __typename?: 'EquipmentCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_EquipmentLaborMetrics_Fragment = {
  __typename?: 'EquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_EquipmentMemberLaborMetrics_Fragment = {
  __typename?: 'EquipmentMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_EquipmentProjectLaborMetrics_Fragment = {
  __typename?: 'EquipmentProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberLaborMetrics_Fragment = {
  __typename?: 'MemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_MemberProjectLaborMetrics_Fragment = {
  __typename?: 'MemberProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_OrganizationLaborMetrics_Fragment = {
  __typename?: 'OrganizationLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_ProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_ProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_ProjectLaborMetrics_Fragment = {
  __typename?: 'ProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

type BasicLaborMetrics_ProjectMemberLaborMetrics_Fragment = {
  __typename?: 'ProjectMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  end: string;
  ot?: number | null;
  rt?: number | null;
  start: string;
};

export type BasicLaborMetricsFragment =
  | BasicLaborMetrics_CostCodeEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_CostCodeLaborMetrics_Fragment
  | BasicLaborMetrics_CostCodeMemberLaborMetrics_Fragment
  | BasicLaborMetrics_CostCodeProjectLaborMetrics_Fragment
  | BasicLaborMetrics_EquipmentCostCodeLaborMetrics_Fragment
  | BasicLaborMetrics_EquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_EquipmentMemberLaborMetrics_Fragment
  | BasicLaborMetrics_EquipmentProjectLaborMetrics_Fragment
  | BasicLaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_MemberCostCodeLaborMetrics_Fragment
  | BasicLaborMetrics_MemberEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_MemberLaborMetrics_Fragment
  | BasicLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment
  | BasicLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_MemberProjectLaborMetrics_Fragment
  | BasicLaborMetrics_OrganizationLaborMetrics_Fragment
  | BasicLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_ProjectCostCodeLaborMetrics_Fragment
  | BasicLaborMetrics_ProjectEquipmentLaborMetrics_Fragment
  | BasicLaborMetrics_ProjectLaborMetrics_Fragment
  | BasicLaborMetrics_ProjectMemberLaborMetrics_Fragment;

type LaborMetrics_CostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'CostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_CostCodeLaborMetrics_Fragment = {
  __typename?: 'CostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_CostCodeMemberLaborMetrics_Fragment = {
  __typename?: 'CostCodeMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_CostCodeProjectLaborMetrics_Fragment = {
  __typename?: 'CostCodeProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_EquipmentCostCodeLaborMetrics_Fragment = {
  __typename?: 'EquipmentCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_EquipmentLaborMetrics_Fragment = {
  __typename?: 'EquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_EquipmentMemberLaborMetrics_Fragment = {
  __typename?: 'EquipmentMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_EquipmentProjectLaborMetrics_Fragment = {
  __typename?: 'EquipmentProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberLaborMetrics_Fragment = {
  __typename?: 'MemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_MemberProjectLaborMetrics_Fragment = {
  __typename?: 'MemberProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_OrganizationLaborMetrics_Fragment = {
  __typename?: 'OrganizationLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_ProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_ProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_ProjectLaborMetrics_Fragment = {
  __typename?: 'ProjectLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type LaborMetrics_ProjectMemberLaborMetrics_Fragment = {
  __typename?: 'ProjectMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

export type LaborMetricsFragment =
  | LaborMetrics_CostCodeEquipmentLaborMetrics_Fragment
  | LaborMetrics_CostCodeLaborMetrics_Fragment
  | LaborMetrics_CostCodeMemberLaborMetrics_Fragment
  | LaborMetrics_CostCodeProjectLaborMetrics_Fragment
  | LaborMetrics_EquipmentCostCodeLaborMetrics_Fragment
  | LaborMetrics_EquipmentLaborMetrics_Fragment
  | LaborMetrics_EquipmentMemberLaborMetrics_Fragment
  | LaborMetrics_EquipmentProjectLaborMetrics_Fragment
  | LaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment
  | LaborMetrics_MemberCostCodeLaborMetrics_Fragment
  | LaborMetrics_MemberEquipmentLaborMetrics_Fragment
  | LaborMetrics_MemberLaborMetrics_Fragment
  | LaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment
  | LaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment
  | LaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment
  | LaborMetrics_MemberProjectLaborMetrics_Fragment
  | LaborMetrics_OrganizationLaborMetrics_Fragment
  | LaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment
  | LaborMetrics_ProjectCostCodeLaborMetrics_Fragment
  | LaborMetrics_ProjectEquipmentLaborMetrics_Fragment
  | LaborMetrics_ProjectLaborMetrics_Fragment
  | LaborMetrics_ProjectMemberLaborMetrics_Fragment;

type AllLaborMetrics_CostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'CostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_CostCodeLaborMetrics_Fragment = {
  __typename?: 'CostCodeLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_CostCodeMemberLaborMetrics_Fragment = {
  __typename?: 'CostCodeMemberLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_CostCodeProjectLaborMetrics_Fragment = {
  __typename?: 'CostCodeProjectLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_EquipmentCostCodeLaborMetrics_Fragment = {
  __typename?: 'EquipmentCostCodeLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_EquipmentLaborMetrics_Fragment = {
  __typename?: 'EquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_EquipmentMemberLaborMetrics_Fragment = {
  __typename?: 'EquipmentMemberLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_EquipmentProjectLaborMetrics_Fragment = {
  __typename?: 'EquipmentProjectLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberLaborMetrics_Fragment = {
  __typename?: 'MemberLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'MemberProjectCostCodeLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberProjectEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_MemberProjectLaborMetrics_Fragment = {
  __typename?: 'MemberProjectLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_OrganizationLaborMetrics_Fragment = {
  __typename?: 'OrganizationLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_ProjectCostCodeLaborMetrics_Fragment = {
  __typename?: 'ProjectCostCodeLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_ProjectEquipmentLaborMetrics_Fragment = {
  __typename?: 'ProjectEquipmentLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_ProjectLaborMetrics_Fragment = {
  __typename?: 'ProjectLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

type AllLaborMetrics_ProjectMemberLaborMetrics_Fragment = {
  __typename?: 'ProjectMemberLaborMetrics';
  breakDt?: number | null;
  breakDtCents?: number | null;
  breakOt?: number | null;
  breakOtCents?: number | null;
  breakOverburdenCents?: number | null;
  breakRt?: number | null;
  breakRtCents?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
};

export type AllLaborMetricsFragment =
  | AllLaborMetrics_CostCodeEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_CostCodeLaborMetrics_Fragment
  | AllLaborMetrics_CostCodeMemberLaborMetrics_Fragment
  | AllLaborMetrics_CostCodeProjectLaborMetrics_Fragment
  | AllLaborMetrics_EquipmentCostCodeLaborMetrics_Fragment
  | AllLaborMetrics_EquipmentLaborMetrics_Fragment
  | AllLaborMetrics_EquipmentMemberLaborMetrics_Fragment
  | AllLaborMetrics_EquipmentProjectLaborMetrics_Fragment
  | AllLaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_MemberCostCodeLaborMetrics_Fragment
  | AllLaborMetrics_MemberEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_MemberLaborMetrics_Fragment
  | AllLaborMetrics_MemberProjectCostCodeEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_MemberProjectCostCodeLaborMetrics_Fragment
  | AllLaborMetrics_MemberProjectEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_MemberProjectLaborMetrics_Fragment
  | AllLaborMetrics_OrganizationLaborMetrics_Fragment
  | AllLaborMetrics_ProjectCostCodeEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_ProjectCostCodeLaborMetrics_Fragment
  | AllLaborMetrics_ProjectEquipmentLaborMetrics_Fragment
  | AllLaborMetrics_ProjectLaborMetrics_Fragment
  | AllLaborMetrics_ProjectMemberLaborMetrics_Fragment;

type BasicEquipmentLaborMetrics_CostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'CostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  equipmentCents?: number | null;
};

type BasicEquipmentLaborMetrics_EquipmentCostCodeLaborMetrics_Fragment = {
  __typename?: 'EquipmentCostCodeLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  equipmentCents?: number | null;
};

type BasicEquipmentLaborMetrics_EquipmentLaborMetrics_Fragment = {
  __typename?: 'EquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  equipmentCents?: number | null;
};

type BasicEquipmentLaborMetrics_EquipmentMemberLaborMetrics_Fragment = {
  __typename?: 'EquipmentMemberLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  equipmentCents?: number | null;
};

type BasicEquipmentLaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberCostCodeEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  equipmentCents?: number | null;
};

type BasicEquipmentLaborMetrics_MemberEquipmentLaborMetrics_Fragment = {
  __typename?: 'MemberEquipmentLaborMetrics';
  breakDt?: number | null;
  breakOt?: number | null;
  breakRt?: number | null;
  dt?: number | null;
  dtCents?: number | null;
  end: string;
  ot?: number | null;
  otCents?: number | null;
  overburdenRtCents?: number | null;
  overburdenOtCents?: number | null;
  overburdenDtCents?: number | null;
  rt?: number | null;
  rtCents?: number | null;
  start: string;
  equipmentCents?: number | null;
};

export type BasicEquipmentLaborMetricsFragment =
  | BasicEquipmentLaborMetrics_CostCodeEquipmentLaborMetrics_Fragment
  | BasicEquipmentLaborMetrics_EquipmentCostCodeLaborMetrics_Fragment
  | BasicEquipmentLaborMetrics_EquipmentLaborMetrics_Fragment
  | BasicEquipmentLaborMetrics_EquipmentMemberLaborMetrics_Fragment
  | BasicEquipmentLaborMetrics_MemberCostCodeEquipmentLaborMetrics_Fragment
  | BasicEquipmentLaborMetrics_MemberEquipmentLaborMetrics_Fragment;

export type QueryMemberGpsStatusesQueryVariables = Exact<{
  filter?: InputMaybe<MemberGpsStatusFilter>;
  sort?: InputMaybe<Array<MemberGpsStatusSort> | MemberGpsStatusSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryMemberGpsStatusesQuery = {
  __typename?: 'Query';
  memberGpsStatuses?: Array<{
    __typename?: 'MemberGpsStatus';
    id: string;
    status: MemberGpsStatusTypes;
    localTime: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      endTime?: string | null;
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
      } | null;
    };
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type MemberGroupQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type MemberGroupQuery = {
  __typename?: 'Query';
  memberGroups?: Array<{
    __typename?: 'MemberGroup';
    id: string;
    organizationId: string;
    groupName: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type QueryMemberLocationsQueryVariables = Exact<{
  filter?: InputMaybe<MemberLocationFilter>;
  sort?: InputMaybe<Array<MemberLocationSort> | MemberLocationSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryMemberLocationsQuery = {
  __typename?: 'Query';
  memberLocations?: Array<{
    __typename?: 'MemberLocation';
    id: string;
    memberId: string;
    longitude: number;
    latitude: number;
    horizontalAccuracy?: number | null;
    altitude?: number | null;
    verticalAccuracy?: number | null;
    timeGmt: string;
    timeLocal: string;
    timeDst: boolean;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type QueryMemberLocationQueryVariables = Exact<{
  memberLocationId: Scalars['Uuid']['input'];
}>;

export type QueryMemberLocationQuery = {
  __typename?: 'Query';
  memberLocations?: Array<{
    __typename?: 'MemberLocation';
    id: string;
    memberId: string;
    longitude: number;
    latitude: number;
    horizontalAccuracy?: number | null;
    altitude?: number | null;
    verticalAccuracy?: number | null;
    timeGmt: string;
    timeLocal: string;
    timeDst: boolean;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type QueryMemberLocksQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MemberLockFilter>;
}>;

export type QueryMemberLocksQuery = {
  __typename?: 'Query';
  memberLocks?: Array<{
    __typename?: 'MemberLock';
    id: string;
    effectiveDate?: string | null;
    updaterMemberId: string;
    memberId: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
  }> | null;
};

export type QueryMemberLockQueryVariables = Exact<{
  memberId: Scalars['Uuid']['input'];
}>;

export type QueryMemberLockQuery = {
  __typename?: 'Query';
  memberLocks?: Array<{
    __typename?: 'MemberLock';
    id: string;
    effectiveDate?: string | null;
    updaterMemberId: string;
    memberId: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
  }> | null;
};

export type MemberTimeMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type MemberTimeMetricsQueryQuery = {
  __typename?: 'Query';
  memberLaborMetrics: Array<{
    __typename?: 'MemberLaborMetrics';
    memberId: string;
    breakDt?: number | null;
    breakOt?: number | null;
    breakRt?: number | null;
    dt?: number | null;
    end: string;
    ot?: number | null;
    pto?: number | null;
    rt?: number | null;
    start: string;
    upto?: number | null;
  }>;
};

export type MemberMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type MemberMetricsQueryQuery = {
  __typename?: 'Query';
  memberLaborMetrics: Array<{
    __typename?: 'MemberLaborMetrics';
    memberId: string;
    breakDt?: number | null;
    breakOt?: number | null;
    breakRt?: number | null;
    dt?: number | null;
    dtCents?: number | null;
    end: string;
    ot?: number | null;
    otCents?: number | null;
    overburdenRtCents?: number | null;
    overburdenOtCents?: number | null;
    overburdenDtCents?: number | null;
    pto?: number | null;
    ptoCents?: number | null;
    rt?: number | null;
    rtCents?: number | null;
    start: string;
    upto?: number | null;
  }>;
};

export type MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type MemberTimeOffOnlyTimeOnlyMetricsQueryQuery = {
  __typename?: 'Query';
  memberLaborMetrics: Array<{
    __typename?: 'MemberLaborMetrics';
    start: string;
    end: string;
    memberId: string;
    pto?: number | null;
    upto?: number | null;
  }>;
};

export type MemberTimeOffOnlyMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type MemberTimeOffOnlyMetricsQueryQuery = {
  __typename?: 'Query';
  memberLaborMetrics: Array<{
    __typename?: 'MemberLaborMetrics';
    start: string;
    end: string;
    memberId: string;
    pto?: number | null;
    ptoCents?: number | null;
    upto?: number | null;
  }>;
};

export type MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type MemberProjectCostCodeEquipmentTimeMetricsQueryQuery = {
  __typename?: 'Query';
  memberProjectCostCodeEquipmentLaborMetrics: Array<{
    __typename: 'MemberProjectCostCodeEquipmentLaborMetrics';
    memberId: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    descDt?: number | null;
    descOt?: number | null;
    descRt?: number | null;
    dt?: number | null;
    end: string;
    ot?: number | null;
    rt?: number | null;
    start: string;
  }>;
};

export type MemberProjectCostCodeEquipmentMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type MemberProjectCostCodeEquipmentMetricsQueryQuery = {
  __typename?: 'Query';
  memberProjectCostCodeEquipmentLaborMetrics: Array<{
    __typename?: 'MemberProjectCostCodeEquipmentLaborMetrics';
    memberId: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    descDt?: number | null;
    descDtCents?: number | null;
    descOt?: number | null;
    descOtCents?: number | null;
    descOverburdenRtCents?: number | null;
    descOverburdenOtCents?: number | null;
    descOverburdenDtCents?: number | null;
    descRt?: number | null;
    descRtCents?: number | null;
    dt?: number | null;
    dtCents?: number | null;
    end: string;
    ot?: number | null;
    otCents?: number | null;
    overburdenRtCents?: number | null;
    overburdenOtCents?: number | null;
    overburdenDtCents?: number | null;
    rt?: number | null;
    rtCents?: number | null;
    start: string;
  }>;
};

export type MemberDetailsFragment = {
  __typename?: 'Member';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  username: string;
  email?: string | null;
  phone?: string | null;
  memberNumber?: string | null;
  archivedOn?: string | null;
  deletedOn?: string | null;
  imageUrl?: string | null;
  positionId: string;
  memberGroupId?: string | null;
  pinCode?: string | null;
  timeLocationRequired?: string | null;
  distantLocationFlaggingDisabled?: boolean | null;
};

export type QueryMembersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryMembersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
  }> | null;
};

export type QueryMembersWithSortQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryMembersWithSortQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    archivedOn?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
  }> | null;
};

export type QueryMembersForTimeMetericsWithMoreQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  safetySignaturesFilter: SafetySignatureFilter;
  safetySignaturesSort?: InputMaybe<Array<SafetySignatureSort> | SafetySignatureSort>;
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersForTimeMetericsWithMoreQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    archivedOn?: string | null;
    memberNumber?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    timeEntries: Array<{
      __typename?: 'TimeEntry';
      id: string;
      openBreak?: { __typename?: 'TimeEntryBreak'; id: string } | null;
    }>;
    memberLaborMetrics: Array<{
      __typename?: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      pto?: number | null;
      rt?: number | null;
      start: string;
      upto?: number | null;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      startTime: string;
      endTime: string;
      edited?: string | null;
      canceled?: string | null;
      regularSeconds: number;
      overtimeSeconds: number;
      doubletimeSeconds: number;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      cursor: string;
      member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      authoritativeSignature?: {
        __typename?: 'AuthoritativeSignature';
        id: string;
        signatureUrl?: string | null;
        createdOnLocal: string;
      } | null;
      selfSignature?: {
        __typename?: 'SelfSignature';
        id: string;
        signatureUrl?: string | null;
        createdOnLocal: string;
      } | null;
    }>;
    safetySignatures: Array<{
      __typename?: 'SafetySignature';
      id: string;
      memberId: string;
      proxyMemberId: string;
      startTime: string;
      startTimeDst: boolean;
      endTime: string;
      endTimeDst: boolean;
      createdOnDst: boolean;
      deviceType?: string | null;
      breakPolicyFollowed?: boolean | null;
      breakPolicyFollowedReason?: string | null;
      injured?: boolean | null;
      injuryDescription?: string | null;
      timeAccurate?: boolean | null;
      timeAccurateReason?: string | null;
      breakPolicyFollowedResolvedOn?: string | null;
      breakPolicyFollowedResolvedBy?: string | null;
      breakPolicyFollowedResolvedComment?: string | null;
      injuredResolvedOn?: string | null;
      injuredResolvedBy?: string | null;
      injuredResolvedComment?: string | null;
      timeAccurateResolvedOn?: string | null;
      timeAccurateResolvedBy?: string | null;
      timeAccurateResolvedComment?: string | null;
      customQuestionsFlagged?: boolean | null;
      customQuestionsResolvedOn?: string | null;
      signatureUrl?: string | null;
      createdOnLocal: string;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      cursor: string;
    }>;
  }> | null;
};

export type QueryMembersWithOpenEntryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryMembersWithOpenEntryQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    timeEntries: Array<{
      __typename?: 'TimeEntry';
      id: string;
      memberId: string;
      startTime: string;
      endTime?: string | null;
      daylightSavingTime: boolean;
      metaDaylightSavingTime?: boolean | null;
      actionType: number;
      description?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      hasLocation: boolean;
      openBreak?: { __typename?: 'TimeEntryBreak'; id: string; startTime: string; startTimeDst: boolean } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          number?: string | null;
          customer?: string | null;
        } | null;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
          projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
        }> | null;
      } | null;
      costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
      equipment?: {
        __typename?: 'Equipment';
        id: string;
        equipmentName: string;
        model?: {
          __typename?: 'EquipmentModel';
          id: string;
          title?: string | null;
          make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
          category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        } | null;
      } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startTimeDst: boolean;
        endTime?: string | null;
        endTimeDst?: boolean | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
        startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
        endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      }>;
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
    }>;
  }> | null;
};

export type QueryMembersWithOpenEntryCountQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type QueryMembersWithOpenEntryCountQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    timeEntries: Array<{
      __typename?: 'TimeEntry';
      id: string;
      openBreak?: { __typename?: 'TimeEntryBreak'; id: string } | null;
    }>;
  }> | null;
};

export type QueryMembersWithTimeDocumentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersWithTimeDocumentsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      startTime: string;
      endTime: string;
      edited?: string | null;
      canceled?: string | null;
      regularSeconds: number;
      overtimeSeconds: number;
      doubletimeSeconds: number;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      cursor: string;
      member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      authoritativeSignature?: {
        __typename?: 'AuthoritativeSignature';
        id: string;
        signatureUrl?: string | null;
        createdOnLocal: string;
      } | null;
      selfSignature?: {
        __typename?: 'SelfSignature';
        id: string;
        signatureUrl?: string | null;
        createdOnLocal: string;
      } | null;
    }>;
    position: { __typename?: 'Position'; id: string; level: number };
  }> | null;
};

export type QueryMemberQueryVariables = Exact<{
  memberId: Scalars['Uuid']['input'];
}>;

export type QueryMemberQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; level: number };
  }> | null;
};

export type QueryMemberNameQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type QueryMemberNameQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
  }> | null;
};

export type QueryFlatMemberNameQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryFlatMemberNameQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename: 'Member';
    cursor: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    email?: string | null;
    phone?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    deletedOn?: string | null;
    imageUrl?: string | null;
    positionId: string;
    memberGroupId?: string | null;
    pinCode?: string | null;
    timeLocationRequired?: string | null;
    distantLocationFlaggingDisabled?: boolean | null;
  }> | null;
};

export type QueryMemberArchivedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type QueryMemberArchivedQuery = {
  __typename?: 'Query';
  members?: Array<{ __typename?: 'Member'; id: string; cursor: string; archivedOn?: string | null }> | null;
};

export type QueryMemberArchivedWithPositionQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type QueryMemberArchivedWithPositionQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    position: { __typename?: 'Position'; id: string; level: number; timeEvents: number; manageTimeEntries: number };
  }> | null;
};

export type QueryMembersWithProjectWithCostCodesMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectWithCostCodesMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectCostCodeLaborMetrics: Array<{
      __typename?: 'MemberProjectCostCodeLaborMetrics';
      costCodeId?: string | null;
      memberId: string;
      projectId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMemberUsageQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  usageStartDate: Scalars['DateTime']['input'];
  usageEndDate: Scalars['DateTime']['input'];
}>;

export type QueryMemberUsageQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    memberUsageInfo?: {
      __typename?: 'MemberUsageInfo';
      firstApiAccess?: any | null;
      firstTimeTracking?: any | null;
    } | null;
  }> | null;
};

export type SimpleSubmissionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberSignInSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberSignInSubmissionSort> | MemberSignInSubmissionSort>;
}>;

export type SimpleSubmissionsQuery = {
  __typename?: 'Query';
  memberSignInSubmissions?: Array<{
    __typename?: 'MemberSignInSubmission';
    id: string;
    memberId: string;
    flagged: boolean;
    cursor: string;
    createdOn: string;
    createdOnLocal: string;
    deviceType?: string | null;
    signatureUrl?: string | null;
    localTime: string;
    restricted?: boolean | null;
    resolverId?: string | null;
    proxyMemberId: string;
  }> | null;
};

export type SubmissionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberSignInSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<MemberSignInSubmissionSort> | MemberSignInSubmissionSort>;
}>;

export type SubmissionsQuery = {
  __typename?: 'Query';
  memberSignInSubmissions?: Array<{
    __typename?: 'MemberSignInSubmission';
    id: string;
    memberId: string;
    flagged: boolean;
    cursor: string;
    createdOn: string;
    createdOnLocal: string;
    deviceType?: string | null;
    signatureUrl?: string | null;
    localTime: string;
    restricted?: boolean | null;
    resolverId?: string | null;
    proxyMemberId: string;
    proxyMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    answers: Array<{
      __typename?: 'MemberSignInAnswer';
      id: string;
      questionTitle?: string | null;
      answer?: string | null;
      flagged: boolean;
      description?: string | null;
      questionDescription?: string | null;
      createdOn: string;
      questionPrompt?: string | null;
      resolverId?: string | null;
      audienceType?: string | null;
      positionId?: string | null;
      resolvedOn?: string | null;
      resolvedComment?: string | null;
      resolverMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      archivedOn?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
  }> | null;
};

export type MemberTimeDocumentFragmentFragment = {
  __typename?: 'MemberTimeDocument';
  id: string;
  startTime: string;
  endTime: string;
  edited?: string | null;
  canceled?: string | null;
  regularSeconds: number;
  overtimeSeconds: number;
  doubletimeSeconds: number;
  createdOn: string;
  deletedOn?: string | null;
  submittedOn: string;
  updatedOn: string;
  cursor: string;
  member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  authoritativeSignature?: {
    __typename?: 'AuthoritativeSignature';
    id: string;
    signatureUrl?: string | null;
    createdOnLocal: string;
  } | null;
  selfSignature?: {
    __typename?: 'SelfSignature';
    id: string;
    signatureUrl?: string | null;
    createdOnLocal: string;
  } | null;
};

export type MemberTimeDocumentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberTimeDocumentFilter>;
  sort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type MemberTimeDocumentsQuery = {
  __typename?: 'Query';
  memberTimeDocuments?: Array<{
    __typename: 'MemberTimeDocument';
    id: string;
    startTime: string;
    endTime: string;
    edited?: string | null;
    canceled?: string | null;
    regularSeconds: number;
    overtimeSeconds: number;
    doubletimeSeconds: number;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    authoritativeSignature?: {
      __typename?: 'AuthoritativeSignature';
      id: string;
      signatureUrl?: string | null;
      createdOnLocal: string;
    } | null;
    selfSignature?: {
      __typename?: 'SelfSignature';
      id: string;
      signatureUrl?: string | null;
      createdOnLocal: string;
    } | null;
  }> | null;
};

export type QueryOrganizationDocumentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationDocumentFilter>;
  sort?: InputMaybe<Array<OrganizationDocumentSort> | OrganizationDocumentSort>;
}>;

export type QueryOrganizationDocumentsQuery = {
  __typename?: 'Query';
  organizationDocuments?: Array<{
    __typename?: 'OrganizationDocument';
    id: string;
    title: string;
    description?: string | null;
    projectId?: string | null;
    lastUpdatedOn?: string | null;
    positionId?: string | null;
    limitAccess?: OrganizationDocumentAccessTypes | null;
    fileUrl?: string | null;
    fileType?: string | null;
    folderId?: string | null;
    createdBy: string;
    createdOn: string;
    cursor: string;
    folder?: { __typename?: 'Folder'; id: string; title: string } | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      ancestors?: Array<{ __typename?: 'Project'; id: string; depth: number; title: string }> | null;
    } | null;
    createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    tagLinks: Array<{
      __typename?: 'TagDocumentLink';
      id: string;
      tagId: string;
      deletedOn?: string | null;
      tag: { __typename?: 'Tag'; id: string; title: string; deletedOn?: string | null };
    }>;
  }> | null;
};

export type CreateOrganizationDocumentMutationVariables = Exact<{
  organizationDocument?: InputMaybe<OrganizationDocumentCreateInput>;
}>;

export type CreateOrganizationDocumentMutation = {
  __typename?: 'Mutation';
  createOrganizationDocument?: { __typename?: 'OrganizationDocument'; id: string } | null;
};

export type UpdateOrganizationDocumentMutationVariables = Exact<{
  organizationDocument?: InputMaybe<OrganizationDocumentUpdateInput>;
}>;

export type UpdateOrganizationDocumentMutation = {
  __typename?: 'Mutation';
  updateOrganizationDocument?: { __typename?: 'OrganizationDocument'; id: string } | null;
};

export type OrganizationNotificationPreferencesQueryQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationNotificationPreferencesFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type OrganizationNotificationPreferencesQueryQuery = {
  __typename?: 'Query';
  organizationNotificationPreferences?: Array<{
    __typename?: 'OrganizationNotificationPreferences';
    id: string;
    memberId: string;
    injuryReport: number;
    inaccurateTimeReport: number;
    breakReport: number;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type OrganizationOvertimePeriodsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrganizationOvertimePeriodFilter>;
}>;

export type OrganizationOvertimePeriodsQuery = {
  __typename?: 'Query';
  organizationOvertimePeriods?: Array<{
    __typename?: 'OrganizationOvertimePeriod';
    id: string;
    startTime: string;
    lengthInDays?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    weeklyOvertime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    updatedOn: string;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type PositionDetailsFragment = {
  __typename?: 'Position';
  canEditPosition: boolean;
  canManageProjectCostCodeQuantity: number;
  canRequestTimeOff: boolean;
  clockInRestriction: ClockInRestrictionType;
  clockInRestrictionStartTime?: string | null;
  createdOn: string;
  cursor: string;
  dailyProjectPrompts: boolean;
  dailyProjectPromptsUseSubprojects: boolean;
  deletedOn?: string | null;
  equipmentSelectionRequirement: EquipmentSelectionOptions;
  id: string;
  jobCostingPrompt: JobCostingPromptOptions;
  level: number;
  manageBudgets: boolean;
  manageBulletinPosts?: boolean | null;
  manageCompanySettings: boolean;
  manageCostCodes: boolean;
  manageEmployeeGroups: boolean;
  manageEmployees: number;
  manageEquipment: boolean;
  manageGpsApproval: number;
  manageMediaCollection: number;
  managePerDiem: number;
  manageProjectGroups: boolean;
  manageProjects: boolean;
  manageSchedule: number;
  manageTimeEntries: number;
  manageTimeOff: number;
  memberLock: number;
  organizationId: string;
  photoVerificationSettings?: string | null;
  requestSignatures: number;
  scopeByGroup: boolean;
  submittedOn: string;
  timeEvents: number;
  title: string;
  updatedOn: string;
  viewAllActivityReport?: boolean | null;
  viewBudgets: boolean;
  viewEquipmentDetails: boolean;
  viewEquipmentStatus: boolean;
  viewMediaCollection: number;
  viewMemberActivityReport?: number | null;
  viewMemberExpenses: boolean;
  viewProjectExpenses: boolean;
  viewSchedule: number;
  createIncident?: boolean | null;
  manageIncident?: boolean | null;
  performInspections?: boolean | null;
  manageInspections?: boolean | null;
};

export type QueryFlatPositionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PositionFilter>;
}>;

export type QueryFlatPositionsQuery = {
  __typename?: 'Query';
  positions?: Array<{
    __typename: 'Position';
    cursor: string;
    canEditPosition: boolean;
    canManageProjectCostCodeQuantity: number;
    canRequestTimeOff: boolean;
    clockInRestriction: ClockInRestrictionType;
    clockInRestrictionStartTime?: string | null;
    createdOn: string;
    dailyProjectPrompts: boolean;
    dailyProjectPromptsUseSubprojects: boolean;
    deletedOn?: string | null;
    equipmentSelectionRequirement: EquipmentSelectionOptions;
    id: string;
    jobCostingPrompt: JobCostingPromptOptions;
    level: number;
    manageBudgets: boolean;
    manageBulletinPosts?: boolean | null;
    manageCompanySettings: boolean;
    manageCostCodes: boolean;
    manageEmployeeGroups: boolean;
    manageEmployees: number;
    manageEquipment: boolean;
    manageGpsApproval: number;
    manageMediaCollection: number;
    managePerDiem: number;
    manageProjectGroups: boolean;
    manageProjects: boolean;
    manageSchedule: number;
    manageTimeEntries: number;
    manageTimeOff: number;
    memberLock: number;
    organizationId: string;
    photoVerificationSettings?: string | null;
    requestSignatures: number;
    scopeByGroup: boolean;
    submittedOn: string;
    timeEvents: number;
    title: string;
    updatedOn: string;
    viewAllActivityReport?: boolean | null;
    viewBudgets: boolean;
    viewEquipmentDetails: boolean;
    viewEquipmentStatus: boolean;
    viewMediaCollection: number;
    viewMemberActivityReport?: number | null;
    viewMemberExpenses: boolean;
    viewProjectExpenses: boolean;
    viewSchedule: number;
    createIncident?: boolean | null;
    manageIncident?: boolean | null;
    performInspections?: boolean | null;
    manageInspections?: boolean | null;
  }> | null;
};

export type QueryPositionWithTitleQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PositionFilter>;
}>;

export type QueryPositionWithTitleQuery = {
  __typename?: 'Query';
  positions?: Array<{ __typename?: 'Position'; cursor: string; id: string; title: string }> | null;
};

export type QueryProjectCostCodeQuantityQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeQuantityFilter>;
  sort?: InputMaybe<Array<ProjectCostCodeQuantitySort> | ProjectCostCodeQuantitySort>;
}>;

export type QueryProjectCostCodeQuantityQuery = {
  __typename?: 'Query';
  projectCostCodeQuantities?: Array<{
    __typename?: 'ProjectCostCodeQuantity';
    id: string;
    amount: number;
    assignedOn: string;
    costCodeId: string;
    createdOnLocal: string;
    description?: string | null;
    projectId: string;
    submittedBy: string;
    type?: string | null;
    cursor: string;
    lastEditedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    costCode: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    };
    project: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
      }> | null;
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        canManageProjectCostCodeQuantity: number;
        timeEvents: number;
      };
    };
  }> | null;
};

export type QueryProjectQuantitySummaryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeQuantityFilter>;
  sort?: InputMaybe<Array<ProjectCostCodeQuantitySort> | ProjectCostCodeQuantitySort>;
}>;

export type QueryProjectQuantitySummaryQuery = {
  __typename?: 'Query';
  projectCostCodeQuantities?: Array<{
    __typename?: 'ProjectCostCodeQuantity';
    id: string;
    projectId: string;
    cursor: string;
    project: { __typename?: 'Project'; id: string; rootProjectId?: string | null };
  }> | null;
};

export type QueryProjectCostCodeQuantitySummaryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeQuantityFilter>;
  sort?: InputMaybe<Array<ProjectCostCodeQuantitySort> | ProjectCostCodeQuantitySort>;
}>;

export type QueryProjectCostCodeQuantitySummaryQuery = {
  __typename?: 'Query';
  projectCostCodeQuantities?: Array<{
    __typename?: 'ProjectCostCodeQuantity';
    id: string;
    amount: number;
    projectId: string;
    costCodeId: string;
    cursor: string;
    costCode: { __typename?: 'CostCode'; id: string; title?: string | null; costCode: string };
  }> | null;
};

export type QueryProjectCostCodeQuantityIdQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeQuantityFilter>;
  sort?: InputMaybe<Array<ProjectCostCodeQuantitySort> | ProjectCostCodeQuantitySort>;
}>;

export type QueryProjectCostCodeQuantityIdQuery = {
  __typename?: 'Query';
  projectCostCodeQuantities?: Array<{
    __typename?: 'ProjectCostCodeQuantity';
    id: string;
    assignedOn: string;
    cursor: string;
  }> | null;
};

export type ProjectGroupQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectGroupFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectGroupQuery = {
  __typename?: 'Query';
  projectGroups?: Array<{
    __typename?: 'ProjectGroup';
    id: string;
    organizationId: string;
    groupName: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type ProjectTimeMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectTimeMetricsQueryQuery = {
  __typename?: 'Query';
  projectLaborMetrics: Array<{
    __typename: 'ProjectLaborMetrics';
    descDt?: number | null;
    descOt?: number | null;
    descRt?: number | null;
    dt?: number | null;
    end: string;
    ot?: number | null;
    rt?: number | null;
    start: string;
  }>;
};

export type ProjectMetricsQueryQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectMetricsQueryQuery = {
  __typename?: 'Query';
  projectLaborMetrics: Array<{
    __typename?: 'ProjectLaborMetrics';
    descDt?: number | null;
    descDtCents?: number | null;
    descOt?: number | null;
    descOtCents?: number | null;
    descOverburdenRtCents?: number | null;
    descOverburdenOtCents?: number | null;
    descOverburdenDtCents?: number | null;
    descRt?: number | null;
    descRtCents?: number | null;
    dt?: number | null;
    dtCents?: number | null;
    end: string;
    ot?: number | null;
    otCents?: number | null;
    overburdenRtCents?: number | null;
    overburdenOtCents?: number | null;
    overburdenDtCents?: number | null;
    rt?: number | null;
    rtCents?: number | null;
    start: string;
  }>;
};

export type ProjectsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    cursor: string;
    projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type ProjectsWithGpsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectsWithGpsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    rootProject?: {
      __typename?: 'Project';
      id: string;
      projectInfo?: { __typename?: 'ProjectInfo'; id: string; requireTimeEntryGps?: string | null } | null;
    } | null;
    projectInfo?: { __typename?: 'ProjectInfo'; id: string; requireTimeEntryGps?: string | null } | null;
  }> | null;
};

export type ProjectsWithChildrenQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectsWithChildrenQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    cursor: string;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      id: string;
      number?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      state?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        number?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        country?: string | null;
        state?: string | null;
        postalCode?: string | null;
        phone?: string | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
    }> | null;
  }> | null;
};

export type ProjectsWithInfoQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectsWithInfoQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    cursor: string;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      id: string;
      number?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      state?: string | null;
      postalCode?: string | null;
      phone?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      additionalInfo?: string | null;
    } | null;
  }> | null;
};

export type SimpleProjectQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SimpleProjectQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    archivedOn?: string | null;
  }> | null;
};

export type SimpleProjectWithTimeQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
}>;

export type SimpleProjectWithTimeQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    archivedOn?: string | null;
  }> | null;
};

export type SimpleProjectWithSubQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SimpleProjectWithSubQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    archivedOn?: string | null;
    cursor: string;
    ancestors?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
  }> | null;
};

export type SimpleProjectWithMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type SimpleProjectWithMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectLaborMetrics: Array<{
      __typename?: 'ProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithTimeMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type SimpleProjectWithTimeMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithMetricQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type SimpleProjectWithMetricQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    archivedOn?: string | null;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename?: 'ProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithTimeOnlyMetricQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type SimpleProjectWithTimeOnlyMetricQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithMemberMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type SimpleProjectWithMemberMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectMemberLaborMetrics: Array<{
      __typename?: 'ProjectMemberLaborMetrics';
      memberId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectMemberCostCodeLaborMetrics: Array<{
      __typename?: 'MemberProjectCostCodeLaborMetrics';
      memberId: string;
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectMemberEquipmentLaborMetrics: Array<{
      __typename?: 'MemberProjectEquipmentLaborMetrics';
      memberId: string;
      equipmentId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type SimpleProjectWithCostCodeMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectCostCodeLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeLaborMetrics';
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectCostCodeEquipmentLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentId?: string | null;
      costCodeId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type SimpleProjectWithEquipmentMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    projectEquipmentLaborMetrics: Array<{
      __typename?: 'ProjectEquipmentLaborMetrics';
      equipmentId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectIdQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectIdQuery = {
  __typename?: 'Query';
  projects?: Array<{ __typename?: 'Project'; id: string; cursor: string }> | null;
};

export type ProjectTitleQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectTitleQuery = {
  __typename?: 'Query';
  projects?: Array<{ __typename?: 'Project'; id: string; title: string; cursor: string }> | null;
};

export type ProjectsAncestorsQueryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectsAncestorsQueryQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    cursor: string;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type QuickSearchProjectsAncestorsQueryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QuickSearchProjectsAncestorsQueryQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    cursor: string;
    archivedOn?: string | null;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    submittedOn: string;
    updatedOn: string;
    createdOn: string;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
      additionalInfo?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  }> | null;
};

export type ProjectClockStateQueryVariables = Exact<{
  filter?: InputMaybe<ProjectClockStateFilter>;
}>;

export type ProjectClockStateQuery = {
  __typename?: 'Query';
  projectClockState: {
    __typename?: 'ProjectClockState';
    all: number;
    clockedIn: number;
    clockedOut: number;
    onBreak: number;
  };
};

export type QueryRecurringInspectionAssignmentCategoryLinksQueryVariables = Exact<{
  filter?: InputMaybe<RecurringInspectionAssignmentCategoryLinkFilter>;
  sort?: InputMaybe<
    Array<RecurringInspectionAssignmentCategoryLinkSort> | RecurringInspectionAssignmentCategoryLinkSort
  >;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryRecurringInspectionAssignmentCategoryLinksQuery = {
  __typename?: 'Query';
  recurringInspectionAssignmentCategoryLinks?: Array<{
    __typename?: 'RecurringInspectionAssignmentCategoryLink';
    cursor: string;
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    categoryId: string;
    category: {
      __typename?: 'InspectionCategory';
      id: string;
      checklistId: string;
      name: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    };
  }> | null;
};

export type QueryRecurringInspectionAssignmentNotificationLinksQueryVariables = Exact<{
  filter?: InputMaybe<RecurringInspectionAssignmentNotificationLinkFilter>;
  sort?: InputMaybe<
    Array<RecurringInspectionAssignmentNotificationLinkSort> | RecurringInspectionAssignmentNotificationLinkSort
  >;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryRecurringInspectionAssignmentNotificationLinksQuery = {
  __typename?: 'Query';
  recurringInspectionAssignmentNotificationLinks?: Array<{
    __typename?: 'RecurringInspectionAssignmentNotificationLink';
    cursor: string;
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    assignmentId: string;
    memberId: string;
  }> | null;
};

export type QueryRecurringInspectionAssignmentsQueryVariables = Exact<{
  filter?: InputMaybe<RecurringInspectionAssignmentFilter>;
  sort?: InputMaybe<Array<RecurringInspectionAssignmentSort> | RecurringInspectionAssignmentSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryRecurringInspectionAssignmentsQuery = {
  __typename?: 'Query';
  recurringInspectionAssignments?: Array<{
    __typename?: 'RecurringInspectionAssignment';
    cursor: string;
    id: string;
    pausedOn?: string | null;
    resumedOn?: string | null;
    startDate: string;
    endDate?: string | null;
    frequency: RecurringInspectionAssignmentFrequencyTypes;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    dayOfMonth?: number | null;
    monthOfYear?: number | null;
    sendNotification: boolean;
    description?: string | null;
    notifySubmission: boolean;
    notificationTime?: string | null;
    notifyCircumstance?: RecurringInspectionNotifyCircumstanceTypes | null;
    circumstanceValue?: number | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; depth: number; title: string }> | null;
    } | null;
    checklist: {
      __typename?: 'InspectionChecklist';
      id: string;
      name: string;
      categories: Array<{
        __typename?: 'InspectionCategory';
        id: string;
        name: string;
        deletedOn?: string | null;
        questions: Array<{
          __typename?: 'InspectionQuestion';
          id: string;
          question: string;
          enabled: boolean;
          deletedOn?: string | null;
          order: number;
        }>;
      }>;
    };
    limitedCategories: Array<{
      __typename?: 'RecurringInspectionAssignmentCategoryLink';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      assignmentId: string;
      categoryId: string;
      category: {
        __typename?: 'InspectionCategory';
        id: string;
        checklistId: string;
        name: string;
        createdOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      };
    }>;
  }> | null;
};

export type SafetyInspectionCategoriesQueryVariables = Exact<{
  reportType: Scalars['String']['input'];
}>;

export type SafetyInspectionCategoriesQuery = {
  __typename?: 'Query';
  safetyInspectionCategories?: Array<{
    __typename?: 'SafetyInspectionCategory';
    reportType: string;
    name: string;
    id: number;
    questions?: Array<{
      __typename?: 'SafetyInspectionCategoryQuestion';
      name: string;
      recommendResponse?: string | null;
      failLinkUrl?: string | null;
      failLinkName?: string | null;
      sort?: number | null;
    }> | null;
  }> | null;
};

export type SafetyInspectionChecklistsQueryVariables = Exact<{ [key: string]: never }>;

export type SafetyInspectionChecklistsQuery = {
  __typename?: 'Query';
  safetyInspectionChecklists?: Array<{
    __typename?: 'SafetyInspectionChecklist';
    name: string;
    reportType: string;
  }> | null;
};

export type BasicSafetySignatureFragmentFragment = {
  __typename?: 'SafetySignature';
  id: string;
  memberId: string;
  proxyMemberId: string;
  startTime: string;
  startTimeDst: boolean;
  endTime: string;
  endTimeDst: boolean;
  createdOnDst: boolean;
  deviceType?: string | null;
  breakPolicyFollowed?: boolean | null;
  breakPolicyFollowedReason?: string | null;
  injured?: boolean | null;
  injuryDescription?: string | null;
  timeAccurate?: boolean | null;
  timeAccurateReason?: string | null;
  breakPolicyFollowedResolvedOn?: string | null;
  breakPolicyFollowedResolvedBy?: string | null;
  breakPolicyFollowedResolvedComment?: string | null;
  injuredResolvedOn?: string | null;
  injuredResolvedBy?: string | null;
  injuredResolvedComment?: string | null;
  timeAccurateResolvedOn?: string | null;
  timeAccurateResolvedBy?: string | null;
  timeAccurateResolvedComment?: string | null;
  customQuestionsFlagged?: boolean | null;
  customQuestionsResolvedOn?: string | null;
  signatureUrl?: string | null;
  createdOnLocal: string;
  createdOn: string;
  deletedOn?: string | null;
  submittedOn: string;
  updatedOn: string;
  cursor: string;
};

export type SafetySignatureFragmentFragment = {
  __typename?: 'SafetySignature';
  id: string;
  memberId: string;
  proxyMemberId: string;
  startTime: string;
  startTimeDst: boolean;
  endTime: string;
  endTimeDst: boolean;
  createdOnDst: boolean;
  deviceType?: string | null;
  breakPolicyFollowed?: boolean | null;
  breakPolicyFollowedReason?: string | null;
  injured?: boolean | null;
  injuryDescription?: string | null;
  timeAccurate?: boolean | null;
  timeAccurateReason?: string | null;
  breakPolicyFollowedResolvedOn?: string | null;
  breakPolicyFollowedResolvedBy?: string | null;
  breakPolicyFollowedResolvedComment?: string | null;
  injuredResolvedOn?: string | null;
  injuredResolvedBy?: string | null;
  injuredResolvedComment?: string | null;
  timeAccurateResolvedOn?: string | null;
  timeAccurateResolvedBy?: string | null;
  timeAccurateResolvedComment?: string | null;
  signatureUrl?: string | null;
  createdOnLocal: string;
  createdOn: string;
  deletedOn?: string | null;
  submittedOn: string;
  updatedOn: string;
  cursor: string;
  customQuestionsFlagged?: boolean | null;
  customQuestionsResolvedOn?: string | null;
  member: {
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    position: { __typename?: 'Position'; id: string; title: string };
  };
  proxyMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  customAnswers: Array<{
    __typename?: 'MemberSignOffAnswer';
    id: string;
    questionTitle?: string | null;
    flagged: boolean;
    answer?: string | null;
    resolvedOn?: string | null;
    createdOn: string;
    audienceType: OrganizationSignOffQuestionAudience;
    positionId?: string | null;
    resolverId?: string | null;
    questionPrompt?: string | null;
    description?: string | null;
  }>;
};

export type SafetySignaturesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetySignatureFilter>;
  sort?: InputMaybe<Array<SafetySignatureSort> | SafetySignatureSort>;
}>;

export type SafetySignaturesQuery = {
  __typename?: 'Query';
  safetySignatures?: Array<{
    __typename: 'SafetySignature';
    id: string;
    memberId: string;
    proxyMemberId: string;
    startTime: string;
    startTimeDst: boolean;
    endTime: string;
    endTimeDst: boolean;
    createdOnDst: boolean;
    deviceType?: string | null;
    breakPolicyFollowed?: boolean | null;
    breakPolicyFollowedReason?: string | null;
    injured?: boolean | null;
    injuryDescription?: string | null;
    timeAccurate?: boolean | null;
    timeAccurateReason?: string | null;
    breakPolicyFollowedResolvedOn?: string | null;
    breakPolicyFollowedResolvedBy?: string | null;
    breakPolicyFollowedResolvedComment?: string | null;
    injuredResolvedOn?: string | null;
    injuredResolvedBy?: string | null;
    injuredResolvedComment?: string | null;
    timeAccurateResolvedOn?: string | null;
    timeAccurateResolvedBy?: string | null;
    timeAccurateResolvedComment?: string | null;
    signatureUrl?: string | null;
    createdOnLocal: string;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
    customQuestionsFlagged?: boolean | null;
    customQuestionsResolvedOn?: string | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      position: { __typename?: 'Position'; id: string; title: string };
    };
    proxyMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    customAnswers: Array<{
      __typename?: 'MemberSignOffAnswer';
      id: string;
      questionTitle?: string | null;
      flagged: boolean;
      answer?: string | null;
      resolvedOn?: string | null;
      createdOn: string;
      audienceType: OrganizationSignOffQuestionAudience;
      positionId?: string | null;
      resolverId?: string | null;
      questionPrompt?: string | null;
      description?: string | null;
    }>;
  }> | null;
};

export type SafetySignaturesDetailsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetySignatureFilter>;
  sort?: InputMaybe<Array<SafetySignatureSort> | SafetySignatureSort>;
}>;

export type SafetySignaturesDetailsQuery = {
  __typename?: 'Query';
  safetySignatures?: Array<{
    __typename?: 'SafetySignature';
    id: string;
    memberId: string;
    proxyMemberId: string;
    startTime: string;
    startTimeDst: boolean;
    endTime: string;
    endTimeDst: boolean;
    createdOnDst: boolean;
    deviceType?: string | null;
    breakPolicyFollowed?: boolean | null;
    breakPolicyFollowedReason?: string | null;
    injured?: boolean | null;
    injuryDescription?: string | null;
    timeAccurate?: boolean | null;
    timeAccurateReason?: string | null;
    breakPolicyFollowedResolvedOn?: string | null;
    breakPolicyFollowedResolvedBy?: string | null;
    breakPolicyFollowedResolvedComment?: string | null;
    injuredResolvedOn?: string | null;
    injuredResolvedBy?: string | null;
    injuredResolvedComment?: string | null;
    timeAccurateResolvedOn?: string | null;
    timeAccurateResolvedBy?: string | null;
    timeAccurateResolvedComment?: string | null;
    signatureUrl?: string | null;
    createdOnLocal: string;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    incidentId?: string | null;
    customQuestionsFlagged?: boolean | null;
    customQuestionsResolvedOn?: string | null;
    incident?: {
      __typename?: 'Incident';
      id: string;
      organizationId: string;
      memberId: string;
      type: IncidentTypes;
      recordable: boolean;
      projectId?: string | null;
      state: IncidentStates;
      illnessType?: IncidentIllnessTypes | null;
      caseNumber: number;
      occurredAt: string;
      occurrenceTimeSet: boolean;
      employeeStartTime?: string | null;
      employeeJobTitle?: string | null;
      employeeBirthday?: string | null;
      employeeHireDate?: string | null;
      employeeSex?: IncidentEmployeeSexes | null;
      employeeAddress1?: string | null;
      employeeCity?: string | null;
      employeeState?: string | null;
      employeePostalCode?: string | null;
      createdOn: string;
      createdOnLocal: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      reviewedOn?: string | null;
      completedOn?: string | null;
      answers: Array<{
        __typename?: 'IncidentAnswer';
        id: string;
        incidentId: string;
        question: string;
        type: IncidentAnswerTypes;
        answer: string;
      }>;
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
        children?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
      } | null;
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
      };
      medicalDetails?: {
        __typename?: 'IncidentMedicalDetails';
        id: string;
        incidentId: string;
        treatmentER?: boolean | null;
        treatmentOvernight?: boolean | null;
        treatmentFirstAid?: boolean | null;
        treatmentDoctor?: boolean | null;
        treatmentNone?: boolean | null;
        treatmentOther?: boolean | null;
        facilityName?: string | null;
        facilityAddress?: string | null;
        facilityCity?: string | null;
        facilityState?: string | null;
        facilityPostalCode?: string | null;
        physicianName?: string | null;
      } | null;
      submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      reviewedByMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      outcomeDetails?: {
        __typename?: 'IncidentOutcomeDetails';
        id: string;
        type: IncidentOutcomeDetailsTypes;
        daysAway?: number | null;
        daysTransfer?: number | null;
        outcomeDate?: string | null;
      } | null;
      completedByMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    } | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    proxyMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    breakPolicyFollowedResolvedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    timeAccurateResolvedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    injuredResolvedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    customAnswers: Array<{
      __typename?: 'MemberSignOffAnswer';
      id: string;
      questionTitle?: string | null;
      questionPrompt?: string | null;
      questionDescription?: string | null;
      description?: string | null;
      flagged: boolean;
      answer?: string | null;
      resolvedOn?: string | null;
      createdOn: string;
      audienceType: OrganizationSignOffQuestionAudience;
      positionId?: string | null;
      resolverId?: string | null;
      resolvedComment?: string | null;
      resolverMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  }> | null;
};

export type ScheduleRemindersQueryVariables = Exact<{
  filter?: InputMaybe<ScheduleReminderFilter>;
  sort?: InputMaybe<Array<ScheduleReminderSort> | ScheduleReminderSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ScheduleRemindersQuery = {
  __typename?: 'Query';
  scheduleReminders?: Array<{
    __typename?: 'ScheduleReminder';
    id: string;
    organizationId: string;
    memberId: string;
    startTime: string;
    endTime: string;
    payload?: string | null;
    type: string;
    createdOn: string;
    cursor: string;
    notifyEmployee?: boolean | null;
    isDraft?: boolean | null;
    overrideRecurringScheduleId?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      parentProjectId?: string | null;
      rootProjectId?: string | null;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        projectGroupId?: string | null;
        archivedOn?: string | null;
      }> | null;
      projectCostCodeLink?: Array<{
        __typename?: 'ProjectCostCodeLink';
        id: string;
        costCodeId: string;
        deletedOn?: string | null;
      }> | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      archivedOn?: string | null;
      costCodeGroupId?: string | null;
      costCode: string;
      title?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      archivedOn?: string | null;
      equipmentGroupId?: string | null;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        equipmentCategoryId: string;
        equipmentMakeId: string;
        id: string;
        modelNumber?: string | null;
        title?: string | null;
        year?: number | null;
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      } | null;
    } | null;
    links: Array<{
      __typename?: 'ScheduleReminderLink';
      id: string;
      scheduleReminderId: string;
      memberId: string;
      member: {
        __typename?: 'Member';
        id: string;
        memberGroupId?: string | null;
        positionId: string;
        archivedOn?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        position: {
          __typename?: 'Position';
          id: string;
          level: number;
          timeEvents: number;
          viewSchedule: number;
          manageSchedule: number;
        };
      };
    }>;
  }> | null;
};

export type ScheduleReminderBasicLinksQueryVariables = Exact<{
  filter?: InputMaybe<ScheduleReminderFilter>;
  sort?: InputMaybe<Array<ScheduleReminderSort> | ScheduleReminderSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ScheduleReminderBasicLinksQuery = {
  __typename?: 'Query';
  scheduleReminders?: Array<{
    __typename?: 'ScheduleReminder';
    id: string;
    organizationId: string;
    memberId: string;
    startTime: string;
    endTime: string;
    payload?: string | null;
    type: string;
    createdOn: string;
    cursor: string;
    notifyEmployee?: boolean | null;
    overrideRecurringScheduleId?: string | null;
    links: Array<{ __typename?: 'ScheduleReminderLink'; id: string; scheduleReminderId: string; memberId: string }>;
  }> | null;
};

export type ClientTimeEntryBreakLogsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClientTimeEntryBreakLogFilter>;
  sort?: InputMaybe<Array<ClientTimeEntryBreakLogSort> | ClientTimeEntryBreakLogSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ClientTimeEntryBreakLogsQuery = {
  __typename?: 'Query';
  clientTimeEntryBreakLogs?: Array<{
    __typename?: 'ClientTimeEntryBreakLog';
    id: string;
    clientTimeEntryLogId: string;
    deviceType?: string | null;
    endLocationId?: string | null;
    startLocationId?: string | null;
    mergedTimeEntryBreakId?: string | null;
    originalTimeEntryBreakId?: string | null;
    timeEntryId: string;
    timeEntryBreakId: string;
    updaterMemberId: string;
    actionType: number;
    endDeviceTime?: string | null;
    endTime?: string | null;
    endTimeTrusted?: number | null;
    startDeviceTime?: string | null;
    startTime: string;
    startTimeTrusted: number;
    timeEntryBreakCreatedOn: string;
    timeEntryBreakDeletedOn?: string | null;
    startTimeDst: boolean;
    endTimeDst?: boolean | null;
    paidBreak?: boolean | null;
    deletedOn?: string | null;
    submittedOn: string;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    timeEntryBreak: {
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      deletedOn?: string | null;
      createdOn: string;
      submittedOn: string;
      updatedOn?: string | null;
      timeEntry: {
        __typename?: 'TimeEntry';
        id: string;
        startTime: string;
        daylightSavingTime: boolean;
        endTime?: string | null;
        metaDaylightSavingTime?: boolean | null;
      };
      clientLogs: Array<{
        __typename?: 'ClientTimeEntryBreakLog';
        id: string;
        startTime: string;
        endTime?: string | null;
        actionType: number;
        deviceType?: string | null;
        createdOn: string;
        startLocationId?: string | null;
        endLocationId?: string | null;
        clientTimeEntryLogId: string;
      }>;
      startLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
      endLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
    };
  }> | null;
};

export type TimeEntryBreakDetailsFragment = {
  __typename?: 'TimeEntryBreak';
  actionType: number;
  createdOn: string;
  cursor: string;
  deletedOn?: string | null;
  endDeviceTime?: string | null;
  endLocationId?: string | null;
  endTime?: string | null;
  endTimeDst?: boolean | null;
  endTimeTrusted?: number | null;
  id: string;
  paidBreak?: boolean | null;
  startDeviceTime: string;
  startLocationId?: string | null;
  startTime: string;
  startTimeDst: boolean;
  startTimeTrusted: number;
  submittedOn: string;
  timeEntryId: string;
  updatedOn?: string | null;
};

export type TimeEntryBreaksQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryBreakFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TimeEntryBreaksQuery = {
  __typename?: 'Query';
  timeEntryBreaks?: Array<{
    __typename?: 'TimeEntryBreak';
    actionType: number;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    endDeviceTime?: string | null;
    endLocationId?: string | null;
    endTime?: string | null;
    endTimeDst?: boolean | null;
    endTimeTrusted?: number | null;
    id: string;
    paidBreak?: boolean | null;
    startDeviceTime: string;
    startLocationId?: string | null;
    startTime: string;
    startTimeDst: boolean;
    startTimeTrusted: number;
    submittedOn: string;
    timeEntryId: string;
    updatedOn?: string | null;
  }> | null;
};

export type TimeEntryLogsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClientTimeEntryLogFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TimeEntryLogsQuery = {
  __typename?: 'Query';
  clientTimeEntryLogs?: Array<{
    __typename?: 'ClientTimeEntryLog';
    id: string;
    costCodeId?: string | null;
    description?: string | null;
    deviceType?: string | null;
    equipmentId?: string | null;
    endLocationId?: string | null;
    memberId: string;
    mergedTimeEntryId?: string | null;
    originalTimeEntryId?: string | null;
    projectId?: string | null;
    startLocationId?: string | null;
    timeEntryId: string;
    updaterMemberId: string;
    actionType: number;
    endDeviceTime?: string | null;
    endTime?: string | null;
    endTimeTrusted?: number | null;
    startDeviceTime?: string | null;
    startTime: string;
    startTimeTrusted: number;
    timeEntryCreatedOn: string;
    timeEntryDeletedOn?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      daylightSavingTime: boolean;
      endTime?: string | null;
      metaDaylightSavingTime?: boolean | null;
      actionType: number;
      description?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
      } | null;
      costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
      equipment?: {
        __typename?: 'Equipment';
        id: string;
        equipmentName: string;
        model?: {
          __typename?: 'EquipmentModel';
          id: string;
          title?: string | null;
          make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
          category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        } | null;
      } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startTimeDst: boolean;
        endTime?: string | null;
        endTimeDst?: boolean | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
      }>;
      startLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
      endLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
    };
    updaterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type TimeEntryLogQueryVariables = Exact<{
  timeEntryLogId: Scalars['Uuid']['input'];
}>;

export type TimeEntryLogQuery = {
  __typename?: 'Query';
  clientTimeEntryLogs?: Array<{
    __typename?: 'ClientTimeEntryLog';
    id: string;
    costCodeId?: string | null;
    description?: string | null;
    deviceType?: string | null;
    equipmentId?: string | null;
    endLocationId?: string | null;
    memberId: string;
    mergedTimeEntryId?: string | null;
    originalTimeEntryId?: string | null;
    projectId?: string | null;
    startLocationId?: string | null;
    timeEntryId: string;
    updaterMemberId: string;
    actionType: number;
    endDeviceTime?: string | null;
    endTime?: string | null;
    endTimeTrusted?: number | null;
    startDeviceTime?: string | null;
    startTime: string;
    startTimeTrusted: number;
    timeEntryCreatedOn: string;
    timeEntryDeletedOn?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      endTime?: string | null;
      actionType: number;
      description?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
      } | null;
      costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
      equipment?: {
        __typename?: 'Equipment';
        id: string;
        equipmentName: string;
        model?: {
          __typename?: 'EquipmentModel';
          id: string;
          title?: string | null;
          make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
          category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        } | null;
      } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        endTime?: string | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
      }>;
      startLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
      endLocation?: {
        __typename?: 'MemberLocation';
        id: string;
        longitude: number;
        latitude: number;
        horizontalAccuracy?: number | null;
        altitude?: number | null;
        verticalAccuracy?: number | null;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn: string;
      } | null;
    };
    updaterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type BasicTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type BasicTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    endTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
  }> | null;
};

export type GetSimpleTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type GetSimpleTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    startTime: string;
    endTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
    }>;
  }> | null;
};

export type GetTimeEntryByIdQueryVariables = Exact<{
  timeEntryId: Scalars['Uuid']['input'];
}>;

export type GetTimeEntryByIdQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    startDeviceTime?: string | null;
    endTime?: string | null;
    endDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    hasLocation: boolean;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      archivedOn?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
      position: { __typename?: 'Position'; id: string; level: number; timeEvents: number; manageTimeEntries: number };
    };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        latitude?: number | null;
        locationRadius?: number | null;
        longitude?: number | null;
      } | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          locationRadius?: number | null;
          longitude?: number | null;
        } | null;
      }> | null;
    } | null;
    costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      endTimeDst?: boolean | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
      timeEntry: {
        __typename?: 'TimeEntry';
        id: string;
        startTime: string;
        daylightSavingTime: boolean;
        endTime?: string | null;
        metaDaylightSavingTime?: boolean | null;
      };
      clientLogs: Array<{ __typename?: 'ClientTimeEntryBreakLog'; id: string }>;
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
    flaggedTimeEntryLocation?: {
      __typename?: 'FlaggedTimeEntryLocation';
      id: string;
      status?: FlaggedTimeEntryLocationStatus | null;
      locationData: string;
    } | null;
    memberGpsStatuses: Array<{
      __typename?: 'MemberGpsStatus';
      id: string;
      status: MemberGpsStatusTypes;
      localTime: string;
    }>;
  }> | null;
};

export type GetTimEntryWithLogsByIdQueryVariables = Exact<{
  timeEntryId: Scalars['Uuid']['input'];
}>;

export type GetTimEntryWithLogsByIdQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    startDeviceTime?: string | null;
    endTime?: string | null;
    endDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      endTimeDst?: boolean | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
      clientLogs: Array<{
        __typename?: 'ClientTimeEntryBreakLog';
        id: string;
        startTime: string;
        endTime?: string | null;
        actionType: number;
        deviceType?: string | null;
        createdOn: string;
        startLocationId?: string | null;
        endLocationId?: string | null;
        clientTimeEntryLogId: string;
      }>;
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    }>;
    clientLogs: Array<{
      __typename?: 'ClientTimeEntryLog';
      id: string;
      createdOn: string;
      actionType: number;
      deviceType?: string | null;
      startLocationId?: string | null;
      endLocationId?: string | null;
      startTime: string;
      daylightSavingTime: boolean;
      endTime?: string | null;
      metaDaylightSavingTime?: boolean | null;
      projectId?: string | null;
      costCodeId?: string | null;
      equipmentId?: string | null;
      description?: string | null;
      originalTimeEntryId?: string | null;
      updaterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    }>;
  }> | null;
};

export type TimeEntryWithMemberQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type TimeEntryWithMemberQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    cursor: string;
    startTime: string;
    endTime?: string | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      archivedOn?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
  }> | null;
};

export type GetTimeEntriesWithLocationHistoryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type GetTimeEntriesWithLocationHistoryQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    endTime?: string | null;
    startDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        latitude?: number | null;
        locationRadius?: number | null;
        longitude?: number | null;
        customer?: string | null;
        number?: string | null;
      } | null;
    } | null;
    costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    flaggedTimeEntryLocation?: {
      __typename?: 'FlaggedTimeEntryLocation';
      id: string;
      status?: FlaggedTimeEntryLocationStatus | null;
      locationData: string;
    } | null;
    memberGpsStatuses: Array<{
      __typename?: 'MemberGpsStatus';
      id: string;
      status: MemberGpsStatusTypes;
      localTime: string;
    }>;
  }> | null;
};

export type GetTimeEntryWithLocationHistoryQueryVariables = Exact<{
  timeEntryId: Scalars['Uuid']['input'];
}>;

export type GetTimeEntryWithLocationHistoryQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    startDeviceTime?: string | null;
    endTime?: string | null;
    endDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        latitude?: number | null;
        locationRadius?: number | null;
        longitude?: number | null;
      } | null;
    } | null;
    costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      endTimeDst?: boolean | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
      timeEntry: {
        __typename?: 'TimeEntry';
        id: string;
        startTime: string;
        daylightSavingTime: boolean;
        endTime?: string | null;
        metaDaylightSavingTime?: boolean | null;
      };
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    flaggedTimeEntryLocation?: {
      __typename?: 'FlaggedTimeEntryLocation';
      id: string;
      status?: FlaggedTimeEntryLocationStatus | null;
      locationData: string;
    } | null;
    memberGpsStatuses: Array<{
      __typename?: 'MemberGpsStatus';
      id: string;
      status: MemberGpsStatusTypes;
      localTime: string;
    }>;
  }> | null;
};

export type GetTimeEntriesIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type GetTimeEntriesIdsQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{ __typename?: 'TimeEntry'; id: string; cursor: string }> | null;
};

export type TimeEntryDetailsFragment = {
  __typename?: 'TimeEntry';
  id: string;
  memberId: string;
  projectId?: string | null;
  costCodeId?: string | null;
  equipmentId?: string | null;
  startTime: string;
  endTime?: string | null;
  startDeviceTime?: string | null;
  daylightSavingTime: boolean;
  metaDaylightSavingTime?: boolean | null;
  actionType: number;
  description?: string | null;
  createdOn: string;
  deletedOn?: string | null;
  submittedOn: string;
  updatedOn: string;
  hasLocation: boolean;
  project?: {
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    parentProjectId?: string | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        customer?: string | null;
        number?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
    }> | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      id: string;
      customer?: string | null;
      number?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      locationRadius?: number | null;
    } | null;
  } | null;
  costCode?: {
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
  } | null;
  equipment?: {
    __typename?: 'Equipment';
    id: string;
    equipmentName: string;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
    } | null;
  } | null;
  breaks: Array<{
    __typename?: 'TimeEntryBreak';
    id: string;
    startTime: string;
    startDeviceTime: string;
    startTimeTrusted: number;
    startTimeDst: boolean;
    endTime?: string | null;
    endTimeDst?: boolean | null;
    endDeviceTime?: string | null;
    endTimeTrusted?: number | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn?: string | null;
  }>;
  startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
  endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
  clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
};

export type GetTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type GetTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    cursor: string;
    id: string;
    memberId: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime?: string | null;
    startDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    hasLocation: boolean;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      rootProjectId?: string | null;
      parentProjectId?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          customer?: string | null;
          number?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        customer?: string | null;
        number?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
  }> | null;
};

export type GetTimeEntriesWithGpsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type GetTimeEntriesWithGpsQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    cursor: string;
    id: string;
    memberId: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime?: string | null;
    startDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    hasLocation: boolean;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    flaggedTimeEntryLocation?: {
      __typename?: 'FlaggedTimeEntryLocation';
      id: string;
      status?: FlaggedTimeEntryLocationStatus | null;
      locationData: string;
    } | null;
    memberGpsStatuses: Array<{
      __typename?: 'MemberGpsStatus';
      id: string;
      status: MemberGpsStatusTypes;
      localTime: string;
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      rootProjectId?: string | null;
      parentProjectId?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          customer?: string | null;
          number?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        customer?: string | null;
        number?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
  }> | null;
};

export type QueryTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type QueryTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename: 'TimeEntry';
    cursor: string;
    id: string;
    memberId: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime?: string | null;
    startDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    hasLocation: boolean;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberNumber?: string | null;
      memberGroupId?: string | null;
      archivedOn?: string | null;
      position: { __typename?: 'Position'; id: string; level: number; timeEvents: number; manageTimeEntries: number };
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    memberGpsStatuses: Array<{
      __typename?: 'MemberGpsStatus';
      id: string;
      status: MemberGpsStatusTypes;
      localTime: string;
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      rootProjectId?: string | null;
      parentProjectId?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          customer?: string | null;
          number?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        customer?: string | null;
        number?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
  }> | null;
};

export type SubprojectDetailsFragment = {
  __typename?: 'TimeEntry';
  project?: {
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      id: string;
      latitude?: number | null;
      longitude?: number | null;
      locationRadius?: number | null;
    } | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
      children?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        archivedOn?: string | null;
        depth: number;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          archivedOn?: string | null;
          depth: number;
          projectInfo?: {
            __typename?: 'ProjectInfo';
            id: string;
            latitude?: number | null;
            longitude?: number | null;
            locationRadius?: number | null;
          } | null;
          children?: Array<{
            __typename?: 'Project';
            id: string;
            title: string;
            archivedOn?: string | null;
            depth: number;
            projectInfo?: {
              __typename?: 'ProjectInfo';
              id: string;
              latitude?: number | null;
              longitude?: number | null;
              locationRadius?: number | null;
            } | null;
            children?: Array<{
              __typename?: 'Project';
              id: string;
              title: string;
              archivedOn?: string | null;
              depth: number;
              projectInfo?: {
                __typename?: 'ProjectInfo';
                id: string;
                latitude?: number | null;
                longitude?: number | null;
                locationRadius?: number | null;
              } | null;
              children?: Array<{
                __typename?: 'Project';
                id: string;
                title: string;
                archivedOn?: string | null;
                depth: number;
                projectInfo?: {
                  __typename?: 'ProjectInfo';
                  id: string;
                  latitude?: number | null;
                  longitude?: number | null;
                  locationRadius?: number | null;
                } | null;
                children?: Array<{
                  __typename?: 'Project';
                  id: string;
                  title: string;
                  archivedOn?: string | null;
                  depth: number;
                  projectInfo?: {
                    __typename?: 'ProjectInfo';
                    id: string;
                    latitude?: number | null;
                    longitude?: number | null;
                    locationRadius?: number | null;
                  } | null;
                }> | null;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      }> | null;
    }> | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
    }> | null;
  } | null;
};

export type QueryMapTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type QueryMapTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    cursor: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
    costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      imageUrl?: string | null;
      lastLocation?: {
        __typename?: 'EquipmentLocation';
        latitude: number;
        longitude: number;
        id: string;
        utc: string;
      } | null;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      endTime?: string | null;
      deletedOn?: string | null;
    }>;
    startLocation?: {
      __typename?: 'MemberLocation';
      id: string;
      latitude: number;
      longitude: number;
      timeLocal: string;
    } | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          archivedOn?: string | null;
          depth: number;
          projectInfo?: {
            __typename?: 'ProjectInfo';
            id: string;
            latitude?: number | null;
            longitude?: number | null;
            locationRadius?: number | null;
          } | null;
          children?: Array<{
            __typename?: 'Project';
            id: string;
            title: string;
            archivedOn?: string | null;
            depth: number;
            projectInfo?: {
              __typename?: 'ProjectInfo';
              id: string;
              latitude?: number | null;
              longitude?: number | null;
              locationRadius?: number | null;
            } | null;
            children?: Array<{
              __typename?: 'Project';
              id: string;
              title: string;
              archivedOn?: string | null;
              depth: number;
              projectInfo?: {
                __typename?: 'ProjectInfo';
                id: string;
                latitude?: number | null;
                longitude?: number | null;
                locationRadius?: number | null;
              } | null;
              children?: Array<{
                __typename?: 'Project';
                id: string;
                title: string;
                archivedOn?: string | null;
                depth: number;
                projectInfo?: {
                  __typename?: 'ProjectInfo';
                  id: string;
                  latitude?: number | null;
                  longitude?: number | null;
                  locationRadius?: number | null;
                } | null;
                children?: Array<{
                  __typename?: 'Project';
                  id: string;
                  title: string;
                  archivedOn?: string | null;
                  depth: number;
                  projectInfo?: {
                    __typename?: 'ProjectInfo';
                    id: string;
                    latitude?: number | null;
                    longitude?: number | null;
                    locationRadius?: number | null;
                  } | null;
                  children?: Array<{
                    __typename?: 'Project';
                    id: string;
                    title: string;
                    archivedOn?: string | null;
                    depth: number;
                    projectInfo?: {
                      __typename?: 'ProjectInfo';
                      id: string;
                      latitude?: number | null;
                      longitude?: number | null;
                      locationRadius?: number | null;
                    } | null;
                  }> | null;
                }> | null;
              }> | null;
            }> | null;
          }> | null;
        }> | null;
      }> | null;
      children?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
    } | null;
  }> | null;
};

export type QueryTimeEntriesMemberIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type QueryTimeEntriesMemberIdsQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{ __typename?: 'TimeEntry'; id: string; memberId: string; cursor: string }> | null;
};

export type QueryTimeEntriesCostCodeIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type QueryTimeEntriesCostCodeIdsQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{ __typename?: 'TimeEntry'; id: string; costCodeId?: string | null; cursor: string }> | null;
};

export type QueryTimeEntriesEquipmentIdsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type QueryTimeEntriesEquipmentIdsQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{ __typename?: 'TimeEntry'; id: string; equipmentId?: string | null; cursor: string }> | null;
};

export type QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type QueryTimeEntriesWithMemberPositionAndGpsStatusQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename: 'TimeEntry';
    cursor: string;
    id: string;
    memberId: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime?: string | null;
    startDeviceTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    hasLocation: boolean;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberNumber?: string | null;
      memberGroupId?: string | null;
      archivedOn?: string | null;
      position: { __typename?: 'Position'; id: string; level: number; timeEvents: number; manageTimeEntries: number };
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    flaggedTimeEntryLocation?: {
      __typename?: 'FlaggedTimeEntryLocation';
      id: string;
      status?: FlaggedTimeEntryLocationStatus | null;
      locationData: string;
    } | null;
    memberGpsStatuses: Array<{
      __typename?: 'MemberGpsStatus';
      id: string;
      status: MemberGpsStatusTypes;
      localTime: string;
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      rootProjectId?: string | null;
      parentProjectId?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          customer?: string | null;
          number?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      }> | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        customer?: string | null;
        number?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
      } | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      title?: string | null;
      unitTitle?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startDeviceTime: string;
      startTimeTrusted: number;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
      endDeviceTime?: string | null;
      endTimeTrusted?: number | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn?: string | null;
    }>;
    startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
    clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string }>;
  }> | null;
};

export type QueryTimeOffQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffFilter>;
  sort?: InputMaybe<Array<TimeOffSort> | TimeOffSort>;
}>;

export type QueryTimeOffQuery = {
  __typename?: 'Query';
  timeOffs?: Array<{
    __typename?: 'TimeOff';
    id: string;
    dateStamp: string;
    type: number;
    description?: string | null;
    paid: boolean;
    seconds?: number | null;
    createdOnLocal: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    memberId: string;
    cursor: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      memberNumber?: string | null;
      position: { __typename?: 'Position'; id: string; level: number; timeEvents: number; manageTimeEntries: number };
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
    submitterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type SingleTimeOffQueryVariables = Exact<{
  timeOffId: Scalars['Uuid']['input'];
}>;

export type SingleTimeOffQuery = {
  __typename?: 'Query';
  timeOffs?: Array<{
    __typename?: 'TimeOff';
    id: string;
    dateStamp: string;
    type: number;
    description?: string | null;
    paid: boolean;
    seconds?: number | null;
    createdOnLocal: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    submitterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type FlatTimeOffQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffFilter>;
  sort?: InputMaybe<Array<TimeOffSort> | TimeOffSort>;
}>;

export type FlatTimeOffQuery = {
  __typename?: 'Query';
  timeOffs?: Array<{
    __typename?: 'TimeOff';
    id: string;
    dateStamp: string;
    type: number;
    description?: string | null;
    paid: boolean;
    seconds?: number | null;
    createdOnLocal: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    memberId: string;
    cursor: string;
  }> | null;
};

export type QueryTimeOffRequestsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffRequestFilter>;
  sort?: InputMaybe<Array<TimeOffRequestSort> | TimeOffRequestSort>;
}>;

export type QueryTimeOffRequestsQuery = {
  __typename?: 'Query';
  timeOffRequests?: Array<{
    __typename?: 'TimeOffRequest';
    id: string;
    answeredBy?: string | null;
    answeredOn?: string | null;
    approved?: boolean | null;
    createdOnDst: boolean;
    cursor: string;
    deletedOn?: string | null;
    description?: string | null;
    paid: boolean;
    dateStamp: string;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    memberId: string;
    rejectionReason?: string | null;
    seconds?: number | null;
    submittedBy: string;
    type: number;
    answeredByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    submittedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  }> | null;
};

export type WageHistoriesQueryVariables = Exact<{
  memberId: Scalars['Uuid']['input'];
}>;

export type WageHistoriesQuery = {
  __typename?: 'Query';
  wageHistories?: Array<{
    __typename?: 'WageHistory';
    id: string;
    memberId: string;
    wage: number;
    wageRate: number;
    workWeek?: number | null;
    effectiveRate?: number | null;
    overburden: number;
    changeDate: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  }> | null;
};

export type QueryWageHistoriesQueryVariables = Exact<{
  filter?: InputMaybe<WageHistoryFilter>;
  sort?: InputMaybe<Array<WageHistorySort> | WageHistorySort>;
}>;

export type QueryWageHistoriesQuery = {
  __typename?: 'Query';
  wageHistories?: Array<{
    __typename?: 'WageHistory';
    id: string;
    memberId: string;
    wage: number;
    wageRate: number;
    workWeek?: number | null;
    effectiveRate?: number | null;
    overburden: number;
    changeDate: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    };
  }> | null;
};

export type QueryWeatherQueryVariables = Exact<{
  startTime: Scalars['DateTime']['input'];
  endTime: Scalars['DateTime']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
}>;

export type QueryWeatherQuery = {
  __typename?: 'Query';
  weather?: {
    __typename?: 'Weather';
    name: string;
    metadata: {
      __typename?: 'WeatherMetadata';
      attributionURL: string;
      expireTime: any;
      latitude: number;
      longitude: number;
      units: string;
      version: number;
    };
    hours: Array<{
      __typename?: 'WeatherHourly';
      forecastStart: any;
      cloudCover: number;
      conditionCode: string;
      daylight: boolean;
      humidity: number;
      precipitationAmount: number;
      precipitationIntensity: number;
      precipitationChance: number;
      precipitationType: string;
      pressure: number;
      pressureTrend: string;
      snowfallIntensity: number;
      snowfallAmount: number;
      temperature: number;
      temperatureApparent: number;
      temperatureDewPoint: number;
      windDirection: number;
      windGust: number;
      windSpeed: number;
    }>;
  } | null;
};

export type PoliciesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyFilter>;
  sort?: InputMaybe<Array<PolicySort> | PolicySort>;
}>;

export type PoliciesQuery = {
  __typename?: 'Query';
  policies?: Array<{
    __typename: 'Policy';
    id: number;
    version?: number | null;
    type?: string | null;
    contentUrl?: string | null;
    effectiveDate: string;
    deletedOn?: string | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
  }> | null;
};

export type PolicyMemberAcceptancesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PolicyMemberAcceptanceFilter>;
  sort?: InputMaybe<Array<PolicyMemberAcceptanceSort> | PolicyMemberAcceptanceSort>;
}>;

export type PolicyMemberAcceptancesQuery = {
  __typename?: 'Query';
  policyMemberAcceptances?: Array<{
    __typename: 'PolicyMemberAcceptance';
    id: string;
    policyId?: number | null;
    memberId: string;
    proxyMemberId: string;
    deviceType?: string | null;
    signatureId?: string | null;
    signatureUrl?: string | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    policy: {
      __typename: 'Policy';
      id: number;
      version?: number | null;
      type?: string | null;
      contentUrl?: string | null;
      effectiveDate: string;
      deletedOn?: string | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      cursor: string;
    };
  }> | null;
};

export type CreatePolicyMemberAcceptanceMutationVariables = Exact<{
  policyMemberAcceptance: PolicyMemberAcceptanceCreateInput;
}>;

export type CreatePolicyMemberAcceptanceMutation = {
  __typename?: 'Mutation';
  createPolicyMemberAcceptance?: {
    __typename?: 'PolicyMemberAcceptance';
    memberId: string;
    policyId?: number | null;
    createdOn: string;
  } | null;
};

export type CostCodeGroupsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeGroupFilter>;
}>;

export type CostCodeGroupsQuery = {
  __typename?: 'Query';
  costCodeGroups?: Array<{ __typename?: 'CostCodeGroup'; id: string; groupName: string; cursor: string }> | null;
};

export type CostCodeGroupByIdQueryVariables = Exact<{
  groupId: Scalars['Uuid']['input'];
}>;

export type CostCodeGroupByIdQuery = {
  __typename?: 'Query';
  costCodeGroups?: Array<{ __typename?: 'CostCodeGroup'; id: string; groupName: string; cursor: string }> | null;
};

export type CostCodesPickerQueryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
}>;

export type CostCodesPickerQueryQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    cursor: string;
    costCode: string;
    title?: string | null;
  }> | null;
};

export type SingleCostCodeQueryQueryVariables = Exact<{
  costCodeId: Scalars['Uuid']['input'];
}>;

export type SingleCostCodeQueryQuery = {
  __typename?: 'Query';
  costCodes?: Array<{ __typename?: 'CostCode'; id: string; costCode: string; title?: string | null }> | null;
};

export type EquipmentCategoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentCategoryFilter>;
}>;

export type EquipmentCategoryQuery = {
  __typename?: 'Query';
  equipmentCategories?: Array<{
    __typename?: 'EquipmentCategory';
    id: string;
    title?: string | null;
    equipmentTypeId: string;
    review?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentCategoryByIdQueryVariables = Exact<{
  categoryId: Scalars['Uuid']['input'];
}>;

export type EquipmentCategoryByIdQuery = {
  __typename?: 'Query';
  equipmentCategories?: Array<{
    __typename?: 'EquipmentCategory';
    id: string;
    title?: string | null;
    equipmentTypeId: string;
    review?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentCategoryWithTypeQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentCategoryFilter>;
}>;

export type EquipmentCategoryWithTypeQuery = {
  __typename?: 'Query';
  equipmentCategories?: Array<{
    __typename?: 'EquipmentCategory';
    id: string;
    title?: string | null;
    equipmentTypeId: string;
    review?: boolean | null;
    cursor: string;
    type: { __typename?: 'EquipmentType'; id: string; title?: string | null };
  }> | null;
};

export type EquipmentMakeQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentMakeFilter>;
}>;

export type EquipmentMakeQuery = {
  __typename?: 'Query';
  equipmentMakes?: Array<{
    __typename?: 'EquipmentMake';
    id: string;
    title?: string | null;
    review: boolean;
    unknown?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentMakeByIdQueryVariables = Exact<{
  makeId: Scalars['Uuid']['input'];
}>;

export type EquipmentMakeByIdQuery = {
  __typename?: 'Query';
  equipmentMakes?: Array<{
    __typename?: 'EquipmentMake';
    id: string;
    title?: string | null;
    review: boolean;
    unknown?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentModelsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentModelFilter>;
}>;

export type EquipmentModelsQuery = {
  __typename?: 'Query';
  equipmentModels?: Array<{
    __typename?: 'EquipmentModel';
    id: string;
    title?: string | null;
    year?: number | null;
    unknown?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentModelByIdQueryVariables = Exact<{
  modelId: Scalars['Uuid']['input'];
}>;

export type EquipmentModelByIdQuery = {
  __typename?: 'Query';
  equipmentModels?: Array<{
    __typename?: 'EquipmentModel';
    id: string;
    title?: string | null;
    year?: number | null;
    unknown?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentModelByCategoryIdQueryVariables = Exact<{
  categoryId: Scalars['Uuid']['input'];
}>;

export type EquipmentModelByCategoryIdQuery = {
  __typename?: 'Query';
  equipmentModels?: Array<{
    __typename?: 'EquipmentModel';
    id: string;
    title?: string | null;
    year?: number | null;
    unknown?: boolean | null;
    cursor: string;
  }> | null;
};

export type EquipmentModelByCategoryMakeIdQueryVariables = Exact<{
  categoryId: Scalars['Uuid']['input'];
  makeId: Scalars['Uuid']['input'];
}>;

export type EquipmentModelByCategoryMakeIdQuery = { __typename?: 'Query'; equipmentModelTitles?: Array<string> | null };

export type EquipmentTypesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentTypeFilter>;
}>;

export type EquipmentTypesQuery = {
  __typename?: 'Query';
  equipmentTypes?: Array<{
    __typename?: 'EquipmentType';
    id: string;
    title?: string | null;
    color?: number | null;
    display: boolean;
    review: boolean;
    cursor: string;
  }> | null;
};

export type EquipmentTypeQueryVariables = Exact<{
  typeId: Scalars['Uuid']['input'];
}>;

export type EquipmentTypeQuery = {
  __typename?: 'Query';
  equipmentTypes?: Array<{
    __typename?: 'EquipmentType';
    id: string;
    title?: string | null;
    color?: number | null;
    display: boolean;
    review: boolean;
    cursor: string;
  }> | null;
};

export type QueryGustoBusyPayrollMemberQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type QueryGustoBusyPayrollMemberQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    gustoEmployeeId?: string | null;
    positionId: string;
    memberGroupId?: string | null;
    cursor: string;
  }> | null;
};

export type MemberGroupsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberGroupFilter>;
}>;

export type MemberGroupsQuery = {
  __typename?: 'Query';
  memberGroups?: Array<{ __typename?: 'MemberGroup'; id: string; groupName: string; cursor: string }> | null;
};

export type MemberGroupByIdQueryVariables = Exact<{
  groupId: Scalars['Uuid']['input'];
}>;

export type MemberGroupByIdQuery = {
  __typename?: 'Query';
  memberGroups?: Array<{ __typename?: 'MemberGroup'; id: string; groupName: string; cursor: string }> | null;
};

export type MembersCsvImportQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type MembersCsvImportQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    imageUrl?: string | null;
    email?: string | null;
    memberNumber?: string | null;
    phone?: string | null;
    timeLocationRequired?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    position: { __typename?: 'Position'; id: string; level: number; title: string };
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      effectiveRate?: number | null;
      overburden: number;
      changeDate: string;
      deletedOn?: string | null;
    }> | null;
  }> | null;
};

export type QueryMemberPickQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryMemberPickQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
  }> | null;
};

export type QueryMemberByIdQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
}>;

export type QueryMemberByIdQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    archivedOn?: string | null;
  }> | null;
};

export type QueryEmployeeTimersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryEmployeeTimersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberLaborMetrics: Array<{
      __typename?: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      pto?: number | null;
      rt?: number | null;
      start: string;
      upto?: number | null;
    }>;
    timeEntries: Array<{
      __typename?: 'TimeEntry';
      id: string;
      memberId: string;
      hasLocation: boolean;
      startTime: string;
      endTime?: string | null;
      daylightSavingTime: boolean;
      metaDaylightSavingTime?: boolean | null;
      actionType: number;
      description?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      flaggedTimeEntryLocation?: {
        __typename?: 'FlaggedTimeEntryLocation';
        id: string;
        status?: FlaggedTimeEntryLocationStatus | null;
        locationData: string;
      } | null;
      memberGpsStatuses: Array<{
        __typename?: 'MemberGpsStatus';
        id: string;
        status: MemberGpsStatusTypes;
        localTime: string;
      }>;
      openBreak?: {
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startDeviceTime: string;
        startTimeDst: boolean;
      } | null;
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          id: string;
          latitude?: number | null;
          locationRadius?: number | null;
          longitude?: number | null;
        } | null;
        children?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
          projectInfo?: {
            __typename?: 'ProjectInfo';
            id: string;
            latitude?: number | null;
            locationRadius?: number | null;
            longitude?: number | null;
          } | null;
        }> | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
          projectInfo?: {
            __typename?: 'ProjectInfo';
            id: string;
            latitude?: number | null;
            locationRadius?: number | null;
            longitude?: number | null;
          } | null;
        }> | null;
      } | null;
      costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
      equipment?: {
        __typename?: 'Equipment';
        id: string;
        equipmentName: string;
        model?: {
          __typename?: 'EquipmentModel';
          id: string;
          title?: string | null;
          make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
          category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        } | null;
      } | null;
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startDeviceTime: string;
        startTimeDst: boolean;
        endTime?: string | null;
        endTimeDst?: boolean | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
      }>;
    }>;
  }> | null;
};

export type RecentPhotosMediaEntriesQueryQueryVariables = Exact<{
  filter?: InputMaybe<MediaEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type RecentPhotosMediaEntriesQueryQuery = {
  __typename?: 'Query';
  mediaEntries?: Array<{
    __typename?: 'MediaEntry';
    id: string;
    mediaCollectionId: string;
    fileUrl?: string | null;
    entryType: number;
    captureDate: string;
    createdOn: string;
    deletedOn?: string | null;
    comment?: string | null;
    location?: {
      __typename?: 'MediaEntryLocation';
      id: string;
      latitude: number;
      longitude: number;
      timeGmt: string;
      timeLocal: string;
      timeDst: boolean;
    } | null;
    collection: {
      __typename?: 'MediaCollection';
      id: string;
      deletedOn?: string | null;
      description?: string | null;
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        archivedOn?: string | null;
        rootProject?: { __typename?: 'Project'; id: string; title: string } | null;
        ancestors?: Array<{
          __typename?: 'Project';
          id: string;
          title: string;
          depth: number;
          archivedOn?: string | null;
        }> | null;
      } | null;
      createdByMember: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        memberGroupId?: string | null;
        position: { __typename?: 'Position'; id: string; level: number };
      };
      tagLinks: Array<{
        __typename?: 'TagMediaCollectionLink';
        id: string;
        tag: { __typename?: 'Tag'; id: string; title: string };
      }>;
    };
  }> | null;
};

export type PositionsFormFieldQueryQueryVariables = Exact<{
  filter: PositionFilter;
}>;

export type PositionsFormFieldQueryQuery = {
  __typename?: 'Query';
  positions?: Array<{ __typename?: 'Position'; id: string; level: number; title: string }> | null;
};

export type ProjectGroupsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectGroupFilter>;
}>;

export type ProjectGroupsQuery = {
  __typename?: 'Query';
  projectGroups?: Array<{ __typename?: 'ProjectGroup'; id: string; groupName: string; cursor: string }> | null;
};

export type ProjectGroupByIdQueryVariables = Exact<{
  groupId: Scalars['Uuid']['input'];
}>;

export type ProjectGroupByIdQuery = {
  __typename?: 'Query';
  projectGroups?: Array<{ __typename?: 'ProjectGroup'; id: string; groupName: string; cursor: string }> | null;
};

export type ProjectOnlyIdReturnQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProjectOnlyIdReturnQuery = {
  __typename?: 'Query';
  projects?: Array<{ __typename?: 'Project'; id: string }> | null;
};

export type ProjectPickerQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProjectPickerQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    cursor: string;
    archivedOn?: string | null;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type ProjectPickerFlatQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
}>;

export type ProjectPickerFlatQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    cursor: string;
    archivedOn?: string | null;
  }> | null;
};

export type ProjectPickerDetailsQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectPickerDetailsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    cursor: string;
    archivedOn?: string | null;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type SubprojectSearchQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
}>;

export type SubprojectSearchQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    cursor: string;
    archivedOn?: string | null;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type ProjectWithChildrenAncestorsQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
}>;

export type ProjectWithChildrenAncestorsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    projectGroupId?: string | null;
    parentProjectId?: string | null;
    archivedOn?: string | null;
    children?: Array<{ __typename?: 'Project'; title: string; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type GetProjectWithSubProjectsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProjectWithSubProjectsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    archivedOn?: string | null;
    ancestors?: Array<{ __typename?: 'Project'; id: string }> | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string }> | null;
      children?: Array<{ __typename?: 'Project'; id: string; title: string; archivedOn?: string | null }> | null;
    }> | null;
  }> | null;
};

export type ProjectsWith1NestedSubprojectsQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
}>;

export type ProjectsWith1NestedSubprojectsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    archivedOn?: string | null;
    title: string;
    depth: number;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      archivedOn?: string | null;
      title: string;
      depth: number;
      children?: Array<{ __typename?: 'Project'; id: string; title: string; archivedOn?: string | null }> | null;
    }> | null;
  }> | null;
};

export type TimeEntryBreakIssuesTableDataMembersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
}>;

export type TimeEntryBreakIssuesTableDataMembersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    archivedOn?: string | null;
    imageUrl?: string | null;
    cursor: string;
    memberGroupId?: string | null;
    position: { __typename?: 'Position'; id: string; level: number; manageTimeEntries: number };
    timeEntries: Array<{
      __typename?: 'TimeEntry';
      id: string;
      createdOn: string;
      daylightSavingTime: boolean;
      deletedOn?: string | null;
      description?: string | null;
      endDeviceTime?: string | null;
      endLocationId?: string | null;
      endTime?: string | null;
      endTimeTrusted?: number | null;
      metaDaylightSavingTime?: boolean | null;
      startDeviceTime?: string | null;
      startLocationId?: string | null;
      startTime: string;
      startTimeTrusted: number;
      memberId: string;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        endTime?: string | null;
        deletedOn?: string | null;
      }>;
    }>;
    openTimeEntries: Array<{
      __typename?: 'TimeEntry';
      id: string;
      createdOn: string;
      daylightSavingTime: boolean;
      deletedOn?: string | null;
      description?: string | null;
      endDeviceTime?: string | null;
      endLocationId?: string | null;
      endTime?: string | null;
      endTimeTrusted?: number | null;
      metaDaylightSavingTime?: boolean | null;
      startDeviceTime?: string | null;
      startLocationId?: string | null;
      startTime: string;
      startTimeTrusted: number;
      memberId: string;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        endTime?: string | null;
        deletedOn?: string | null;
      }>;
    }>;
    safetySignatures: Array<{
      __typename?: 'SafetySignature';
      id: string;
      breakPolicyFollowed?: boolean | null;
      breakPolicyFollowedResolvedOn?: string | null;
      startTime: string;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      submittedOn: string;
      canceled?: string | null;
      edited?: string | null;
    }>;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
  }> | null;
};

export type ProjectsWithTimeQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectsWithTimeQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    cursor: string;
    projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type QueryCostCodeArchivedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
}>;

export type QueryCostCodeArchivedQuery = {
  __typename?: 'Query';
  costCodes?: Array<{ __typename?: 'CostCode'; id: string; cursor: string; archivedOn?: string | null }> | null;
};

export type QueryProjectArchivedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
}>;

export type QueryProjectArchivedQuery = {
  __typename?: 'Query';
  projects?: Array<{ __typename?: 'Project'; id: string; cursor: string; archivedOn?: string | null }> | null;
};

export type QueryEquipmentDeletedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
}>;

export type QueryEquipmentDeletedQuery = {
  __typename?: 'Query';
  equipment?: Array<{ __typename?: 'Equipment'; id: string; cursor: string; deletedOn?: string | null }> | null;
};

export type QueryCostCodesWithMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryCostCodesWithMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeLaborMetrics: Array<{
      __typename?: 'CostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithTimeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryCostCodesWithTimeMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeLaborMetrics: Array<{
      __typename?: 'CostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithEquipmentMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithEquipmentMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeEquipmentLaborMetrics: Array<{
      __typename?: 'CostCodeEquipmentLaborMetrics';
      equipmentId?: string | null;
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithEquipmentTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeEquipmentLaborMetrics: Array<{
      __typename?: 'CostCodeEquipmentLaborMetrics';
      equipmentId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithMemberMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithMemberMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeMemberLaborMetrics: Array<{
      __typename?: 'CostCodeMemberLaborMetrics';
      memberId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithMemberTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeMemberLaborMetrics: Array<{
      __typename?: 'CostCodeMemberLaborMetrics';
      memberId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithProjectMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithProjectMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeProjectLaborMetrics: Array<{
      __typename?: 'CostCodeProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithProjectTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeProjectLaborMetrics: Array<{
      __typename: 'CostCodeProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithProjectAndEquipmentMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeProjectEquipmentLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      projectId?: string | null;
      equipmentId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeProjectLaborMetrics: Array<{
      __typename?: 'CostCodeProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
    costCodeProjectEquipmentLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      projectId?: string | null;
      equipmentId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeProjectEquipmentLaborMetrics: Array<{
      __typename: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      projectId?: string | null;
      equipmentId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeProjectLaborMetrics: Array<{
      __typename?: 'CostCodeProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
    costCodeProjectEquipmentLaborMetrics: Array<{
      __typename: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      projectId?: string | null;
      equipmentId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithMemberProjectMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithMemberProjectMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeMemberProjectLaborMetrics: Array<{
      __typename?: 'MemberProjectCostCodeLaborMetrics';
      projectId?: string | null;
      memberId: string;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeMemberProjectLaborMetrics: Array<{
      __typename: 'MemberProjectCostCodeLaborMetrics';
      projectId?: string | null;
      memberId: string;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithMemberEquipmentMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithMemberEquipmentMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeMemberEquipmentLaborMetrics: Array<{
      __typename?: 'MemberCostCodeEquipmentLaborMetrics';
      memberId: string;
      equipmentId?: string | null;
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    costCodeMemberEquipmentLaborMetrics: Array<{
      __typename?: 'MemberCostCodeEquipmentLaborMetrics';
      memberId: string;
      equipmentId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithMemberMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithMemberMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentMemberLaborMetrics: Array<{
      __typename?: 'EquipmentMemberLaborMetrics';
      equipmentCents?: number | null;
      memberId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentMemberLaborMetrics: Array<{
      __typename?: 'EquipmentMemberLaborMetrics';
      equipmentCents?: number | null;
      memberId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithProjectMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithProjectMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentProjectLaborMetrics: Array<{
      __typename?: 'EquipmentProjectLaborMetrics';
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      projectId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentProjectLaborMetrics: Array<{
      __typename: 'EquipmentProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentProjectCostCodeLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentProjectCostCodeLaborMetrics: Array<{
      __typename: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithCostCodeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithCostCodeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentCostCodeLaborMetrics: Array<{
      __typename?: 'EquipmentCostCodeLaborMetrics';
      equipmentCents?: number | null;
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentCostCodeLaborMetrics: Array<{
      __typename?: 'EquipmentCostCodeLaborMetrics';
      equipmentCents?: number | null;
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryBasicEquipmentWithMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentLaborMetrics: Array<{
      __typename?: 'EquipmentLaborMetrics';
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithTimeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryBasicEquipmentWithTimeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentLaborMetrics: Array<{
      __typename?: 'EquipmentLaborMetrics';
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithMemberCostCodeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentMemberCostCodeLaborMetrics: Array<{
      __typename?: 'MemberCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      memberId: string;
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentMemberCostCodeLaborMetrics: Array<{
      __typename?: 'MemberCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      memberId: string;
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithProjectCostCodeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentProjectCostCodeLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentProjectCostCodeLaborMetrics: Array<{
      __typename: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithMemberProjectMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithMemberProjectMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentMemberProjectLaborMetrics: Array<{
      __typename?: 'MemberProjectEquipmentLaborMetrics';
      projectId?: string | null;
      memberId: string;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    updatedOn: string;
    submittedOn: string;
    equipmentName: string;
    organizationId: string;
    trackManualHours: boolean;
    costHistory: Array<{
      __typename?: 'EquipmentCostHistory';
      id: string;
      machineCostRate?: number | null;
      operatorCostRate?: number | null;
      changeDate: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      title?: string | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
    equipmentMemberProjectLaborMetrics: Array<{
      __typename: 'MemberProjectEquipmentLaborMetrics';
      projectId?: string | null;
      memberId: string;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithTimeMetricOnlyQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersWithTimeMetricOnlyQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberLaborMetrics: Array<{
      __typename?: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      pto?: number | null;
      rt?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type QueryMembersWithMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersWithMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberLaborMetrics: Array<{
      __typename?: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      pto?: number | null;
      ptoCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type QueryMembersWithCostCodeMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithCostCodeMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberCostCodeLaborMetrics: Array<{
      __typename?: 'MemberCostCodeLaborMetrics';
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithCostCodeTimeMetricOnlyQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithCostCodeTimeMetricOnlyQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberCostCodeLaborMetrics: Array<{
      __typename?: 'MemberCostCodeLaborMetrics';
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithCostCodeEquipmentMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithCostCodeEquipmentMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberCostCodeEquipmentLaborMetrics: Array<{
      __typename?: 'MemberCostCodeEquipmentLaborMetrics';
      costCodeId?: string | null;
      equipmentId?: string | null;
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberCostCodeEquipmentLaborMetrics: Array<{
      __typename?: 'MemberCostCodeEquipmentLaborMetrics';
      costCodeId?: string | null;
      equipmentId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithEquipmentMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithEquipmentMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberEquipmentLaborMetrics: Array<{
      __typename?: 'MemberEquipmentLaborMetrics';
      equipmentId?: string | null;
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithEquipmentTimeMetricOnlyQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithEquipmentTimeMetricOnlyQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberEquipmentLaborMetrics: Array<{
      __typename?: 'MemberEquipmentLaborMetrics';
      equipmentId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithProjectMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectLaborMetrics: Array<{
      __typename?: 'MemberProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithProjectTimeMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectTimeMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectLaborMetrics: Array<{
      __typename: 'MemberProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithProjectCostCodeMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectCostCodeMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectCostCodeLaborMetrics: Array<{
      __typename?: 'MemberProjectCostCodeLaborMetrics';
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithProjectCostCodeTimeMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectCostCodeTimeMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectCostCodeLaborMetrics: Array<{
      __typename: 'MemberProjectCostCodeLaborMetrics';
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithProjectEquipmentMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectEquipmentMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectEquipmentLaborMetrics: Array<{
      __typename?: 'MemberProjectEquipmentLaborMetrics';
      projectId?: string | null;
      equipmentId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersWithProjectEquipmentTimeMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMembersWithProjectEquipmentTimeMetricQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    position: { __typename?: 'Position'; id: string; level: number };
    memberProjectEquipmentLaborMetrics: Array<{
      __typename: 'MemberProjectEquipmentLaborMetrics';
      projectId?: string | null;
      equipmentId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectLaborMetrics: Array<{
      __typename?: 'ProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndTimeMetricAndSubCheck2Query = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberMetricAndSubCheck3Query = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberLaborMetrics: Array<{
      __typename?: 'ProjectMemberLaborMetrics';
      memberId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberLaborMetrics: Array<{
      __typename: 'ProjectMemberLaborMetrics';
      memberId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberLaborMetrics: Array<{
      __typename: 'ProjectMemberLaborMetrics';
      memberId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  costCodeId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndCostCodeMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectCostCodeLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeLaborMetrics';
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  costCodeId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectCostCodeLaborMetrics: Array<{
      __typename: 'ProjectCostCodeLaborMetrics';
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectEquipmentLaborMetrics: Array<{
      __typename?: 'ProjectEquipmentLaborMetrics';
      projectId: string;
      equipmentId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndEquipmentMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectEquipmentLaborMetrics: Array<{
      __typename: 'ProjectEquipmentLaborMetrics';
      equipmentId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberCostCodeLaborMetrics: Array<{
      __typename?: 'MemberProjectCostCodeLaborMetrics';
      memberId: string;
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberCostCodeLaborMetrics: Array<{
      __typename: 'MemberProjectCostCodeLaborMetrics';
      memberId: string;
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberEquipmentLaborMetrics: Array<{
      __typename?: 'MemberProjectEquipmentLaborMetrics';
      memberId: string;
      equipmentId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectMemberEquipmentLaborMetrics: Array<{
      __typename: 'MemberProjectEquipmentLaborMetrics';
      memberId: string;
      equipmentId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectCostCodeEquipmentLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentId?: string | null;
      costCodeId?: string | null;
      equipmentCents?: number | null;
      descEquipmentCents?: number | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    children?: Array<{ __typename?: 'Project'; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    projectCostCodeEquipmentLaborMetrics: Array<{
      __typename: 'ProjectCostCodeEquipmentLaborMetrics';
      equipmentId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type AuthOrganizationFragment = {
  __typename?: 'Organization';
  authoritativeSignature: boolean;
  betaTester?: boolean | null;
  breakPolicy: boolean;
  breakPolicyCalifornia: boolean;
  breakPolicyMessage?: string | null;
  bulletin?: boolean | null;
  busySignInQuestion: boolean;
  createdOn: string;
  customSignOff?: boolean | null;
  defaultBreakDuration?: string | null;
  defaultEndTime?: string | null;
  defaultStartTime?: string | null;
  deletedOn?: string | null;
  demo: boolean;
  disclaimer?: string | null;
  gustoCompanyId?: string | null;
  hideUnassignedSubprojects: boolean;
  id: string;
  imageId?: string | null;
  inRecoveryMode?: string | null;
  lockOnSelfSignature?: boolean | null;
  minimumAppVersion?: string | null;
  organizationSettings?: string | null;
  organizationName: string;
  overtimeFlag: boolean;
  ownedBy: string;
  performanceScores?: boolean | null;
  requireCostCode: boolean;
  requireProject: boolean;
  revokedOn?: string | null;
  safetySignature: boolean;
  safetySignatureMessage?: string | null;
  signatureDate?: string | null;
  stripeAccountCreatedOn?: string | null;
  stripeAccountStatus?: string | null;
  stripeAccountType?: string | null;
  stripeCustomerId?: string | null;
  submittedOn: string;
  timeAccuracy: boolean;
  timeAccuracyMessage?: string | null;
  timeOffRequests: boolean;
  timeRounding: boolean;
  timeRoundingInterval: number;
  timeRoundingType: number;
  totalTimeFlag: number;
  trackBudgetCost?: boolean | null;
  trackBudgetEquipmentCosts?: boolean | null;
  trackBudgetEquipmentHours?: boolean | null;
  trackBudgetProgress?: boolean | null;
  trackBudgetProgressValue?: boolean | null;
  trackBudgets: boolean;
  trackCostCode: boolean;
  trackEquipment: boolean;
  trackPaidBreak: boolean;
  trackProject: boolean;
  updatedOn: string;
  useProjectCostCodeScoping?: boolean | null;
  webGps: boolean;
  minBreakSeconds?: number | null;
  perDiem?: boolean | null;
  perDiemAmountTracking?: boolean | null;
  perDiemRequestPermission?: PerDiemRequestPermission | null;
  employeeAutoInvite: EmployeeAutoInvite;
  organizationInfo?: {
    __typename?: 'OrganizationInfo';
    id: string;
    organizationId: string;
    longitude?: number | null;
    latitude?: number | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    county?: string | null;
    state?: string | null;
    postalCode?: string | null;
    country?: string | null;
    phone?: string | null;
    trade?: string | null;
    currencyCode?: string | null;
    employeeMin?: number | null;
    employeeMax?: number | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
  organizationPayPeriod?: Array<{
    __typename?: 'OrganizationPayPeriod';
    id: string;
    organizationId: string;
    payPeriodType: number;
    startDate: string;
    dayOne: number;
    dayTwo?: number | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  }> | null;
  organizationOvertimePeriods?: Array<{
    __typename?: 'OrganizationOvertimePeriod';
    id: string;
    organizationId: string;
    startTime: string;
    lengthInDays?: number | null;
    weeklyOvertime?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  }> | null;
  subscriptionStatus?: {
    __typename?: 'OrganizationSubscriptionStatus';
    annualSubscriptionCount?: number | null;
    annualSubscriptionDate?: string | null;
    annualSubscriptionInterval?: number | null;
    annualSubscriptionPendingInvoiceId?: number | null;
    cardLastFour?: string | null;
    cardType?: string | null;
    couponCode?: string | null;
    createdOn: string;
    customerId?: number | null;
    deletedOn?: string | null;
    exempt?: boolean | null;
    hasTrialed?: boolean | null;
    id: string;
    nextAssessmentAt?: string | null;
    organizationId: string;
    periodDay?: number | null;
    pricePointAction?: string | null;
    pricePointHandle?: string | null;
    productHandle?: string | null;
    referralCode?: string | null;
    status?: string | null;
    submittedOn: string;
    subscribedThroughProduct?: boolean | null;
    subscriptionId?: number | null;
    subscriptionUpdatedAt?: string | null;
    trialEndedAt?: string | null;
    updatedOn: string;
    userCanceled?: boolean | null;
  } | null;
};

export type AuthPositionFragment = {
  __typename?: 'Position';
  canEditPosition: boolean;
  canManageProjectCostCodeQuantity: number;
  canRequestTimeOff: boolean;
  clockInRestriction: ClockInRestrictionType;
  clockInRestrictionStartTime?: string | null;
  createdOn: string;
  dailyProjectPrompts: boolean;
  dailyProjectPromptsUseSubprojects: boolean;
  deletedOn?: string | null;
  equipmentSelectionRequirement: EquipmentSelectionOptions;
  id: string;
  level: number;
  manageBudgets: boolean;
  manageBulletinPosts?: boolean | null;
  manageCompanySettings: boolean;
  manageCostCodes: boolean;
  manageEmployeeGroups: boolean;
  manageEmployees: number;
  manageEquipment: boolean;
  manageGpsApproval: number;
  manageMediaCollection: number;
  managePerDiem: number;
  manageProjectGroups: boolean;
  manageProjects: boolean;
  manageSchedule: number;
  manageTimeEntries: number;
  manageTimeOff: number;
  memberLock: number;
  organizationId: string;
  requestSignatures: number;
  scopeByGroup: boolean;
  submittedOn: string;
  timeEvents: number;
  title: string;
  updatedOn: string;
  viewAllActivityReport?: boolean | null;
  viewBudgets: boolean;
  viewEquipmentDetails: boolean;
  viewEquipmentStatus: boolean;
  viewMediaCollection: number;
  viewMemberActivityReport?: number | null;
  viewMemberExpenses: boolean;
  viewProjectExpenses: boolean;
  viewSchedule: number;
  createIncident?: boolean | null;
  manageIncident?: boolean | null;
  performInspections?: boolean | null;
  manageInspections?: boolean | null;
};

export type AuthMemberFragment = {
  __typename?: 'Member';
  archivedOn?: string | null;
  canSurvey?: boolean | null;
  certifiedEmail?: string | null;
  createdOn: string;
  deletedOn?: string | null;
  email?: string | null;
  emailVerificationRequired?: boolean | null;
  firstName?: string | null;
  gustoEmployeeId?: string | null;
  id: string;
  imageId?: string | null;
  imageUrl?: string | null;
  lastAccess?: string | null;
  lastName?: string | null;
  memberGroupId?: string | null;
  memberNumber?: string | null;
  memberSettings?: string | null;
  organizationId: string;
  pendingEmail?: string | null;
  phone?: string | null;
  positionId: string;
  submittedOn: string;
  timeLocationRequired?: string | null;
  updatedOn: string;
  username: string;
  usernameUnique: boolean;
  verifiedEmail?: boolean | null;
  memberGroup?: {
    __typename?: 'MemberGroup';
    id: string;
    groupName: string;
    deletedOn?: string | null;
    organizationId: string;
    updatedOn: string;
    createdOn: string;
    submittedOn: string;
  } | null;
  organization: {
    __typename?: 'Organization';
    authoritativeSignature: boolean;
    betaTester?: boolean | null;
    breakPolicy: boolean;
    breakPolicyCalifornia: boolean;
    breakPolicyMessage?: string | null;
    bulletin?: boolean | null;
    busySignInQuestion: boolean;
    createdOn: string;
    customSignOff?: boolean | null;
    defaultBreakDuration?: string | null;
    defaultEndTime?: string | null;
    defaultStartTime?: string | null;
    deletedOn?: string | null;
    demo: boolean;
    disclaimer?: string | null;
    gustoCompanyId?: string | null;
    hideUnassignedSubprojects: boolean;
    id: string;
    imageId?: string | null;
    inRecoveryMode?: string | null;
    lockOnSelfSignature?: boolean | null;
    minimumAppVersion?: string | null;
    organizationSettings?: string | null;
    organizationName: string;
    overtimeFlag: boolean;
    ownedBy: string;
    performanceScores?: boolean | null;
    requireCostCode: boolean;
    requireProject: boolean;
    revokedOn?: string | null;
    safetySignature: boolean;
    safetySignatureMessage?: string | null;
    signatureDate?: string | null;
    stripeAccountCreatedOn?: string | null;
    stripeAccountStatus?: string | null;
    stripeAccountType?: string | null;
    stripeCustomerId?: string | null;
    submittedOn: string;
    timeAccuracy: boolean;
    timeAccuracyMessage?: string | null;
    timeOffRequests: boolean;
    timeRounding: boolean;
    timeRoundingInterval: number;
    timeRoundingType: number;
    totalTimeFlag: number;
    trackBudgetCost?: boolean | null;
    trackBudgetEquipmentCosts?: boolean | null;
    trackBudgetEquipmentHours?: boolean | null;
    trackBudgetProgress?: boolean | null;
    trackBudgetProgressValue?: boolean | null;
    trackBudgets: boolean;
    trackCostCode: boolean;
    trackEquipment: boolean;
    trackPaidBreak: boolean;
    trackProject: boolean;
    updatedOn: string;
    useProjectCostCodeScoping?: boolean | null;
    webGps: boolean;
    minBreakSeconds?: number | null;
    perDiem?: boolean | null;
    perDiemAmountTracking?: boolean | null;
    perDiemRequestPermission?: PerDiemRequestPermission | null;
    employeeAutoInvite: EmployeeAutoInvite;
    organizationInfo?: {
      __typename?: 'OrganizationInfo';
      id: string;
      organizationId: string;
      longitude?: number | null;
      latitude?: number | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      county?: string | null;
      state?: string | null;
      postalCode?: string | null;
      country?: string | null;
      phone?: string | null;
      trade?: string | null;
      currencyCode?: string | null;
      employeeMin?: number | null;
      employeeMax?: number | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    } | null;
    organizationPayPeriod?: Array<{
      __typename?: 'OrganizationPayPeriod';
      id: string;
      organizationId: string;
      payPeriodType: number;
      startDate: string;
      dayOne: number;
      dayTwo?: number | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
    organizationOvertimePeriods?: Array<{
      __typename?: 'OrganizationOvertimePeriod';
      id: string;
      organizationId: string;
      startTime: string;
      lengthInDays?: number | null;
      weeklyOvertime?: number | null;
      dailyOvertime?: number | null;
      dailyDoubletime?: number | null;
      seventhDayOvertime?: boolean | null;
      seventhDayDoubletime?: number | null;
      saturdayMultiplier?: number | null;
      sundayMultiplier?: number | null;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
    subscriptionStatus?: {
      __typename?: 'OrganizationSubscriptionStatus';
      annualSubscriptionCount?: number | null;
      annualSubscriptionDate?: string | null;
      annualSubscriptionInterval?: number | null;
      annualSubscriptionPendingInvoiceId?: number | null;
      cardLastFour?: string | null;
      cardType?: string | null;
      couponCode?: string | null;
      createdOn: string;
      customerId?: number | null;
      deletedOn?: string | null;
      exempt?: boolean | null;
      hasTrialed?: boolean | null;
      id: string;
      nextAssessmentAt?: string | null;
      organizationId: string;
      periodDay?: number | null;
      pricePointAction?: string | null;
      pricePointHandle?: string | null;
      productHandle?: string | null;
      referralCode?: string | null;
      status?: string | null;
      submittedOn: string;
      subscribedThroughProduct?: boolean | null;
      subscriptionId?: number | null;
      subscriptionUpdatedAt?: string | null;
      trialEndedAt?: string | null;
      updatedOn: string;
      userCanceled?: boolean | null;
    } | null;
  };
  position: {
    __typename?: 'Position';
    canEditPosition: boolean;
    canManageProjectCostCodeQuantity: number;
    canRequestTimeOff: boolean;
    clockInRestriction: ClockInRestrictionType;
    clockInRestrictionStartTime?: string | null;
    createdOn: string;
    dailyProjectPrompts: boolean;
    dailyProjectPromptsUseSubprojects: boolean;
    deletedOn?: string | null;
    equipmentSelectionRequirement: EquipmentSelectionOptions;
    id: string;
    level: number;
    manageBudgets: boolean;
    manageBulletinPosts?: boolean | null;
    manageCompanySettings: boolean;
    manageCostCodes: boolean;
    manageEmployeeGroups: boolean;
    manageEmployees: number;
    manageEquipment: boolean;
    manageGpsApproval: number;
    manageMediaCollection: number;
    managePerDiem: number;
    manageProjectGroups: boolean;
    manageProjects: boolean;
    manageSchedule: number;
    manageTimeEntries: number;
    manageTimeOff: number;
    memberLock: number;
    organizationId: string;
    requestSignatures: number;
    scopeByGroup: boolean;
    submittedOn: string;
    timeEvents: number;
    title: string;
    updatedOn: string;
    viewAllActivityReport?: boolean | null;
    viewBudgets: boolean;
    viewEquipmentDetails: boolean;
    viewEquipmentStatus: boolean;
    viewMediaCollection: number;
    viewMemberActivityReport?: number | null;
    viewMemberExpenses: boolean;
    viewProjectExpenses: boolean;
    viewSchedule: number;
    createIncident?: boolean | null;
    manageIncident?: boolean | null;
    performInspections?: boolean | null;
    manageInspections?: boolean | null;
  };
};

export type AuthenticationQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['Uuid']['input']>;
}>;

export type AuthenticationQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    archivedOn?: string | null;
    canSurvey?: boolean | null;
    certifiedEmail?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    email?: string | null;
    emailVerificationRequired?: boolean | null;
    firstName?: string | null;
    gustoEmployeeId?: string | null;
    id: string;
    imageId?: string | null;
    imageUrl?: string | null;
    lastAccess?: string | null;
    lastName?: string | null;
    memberGroupId?: string | null;
    memberNumber?: string | null;
    memberSettings?: string | null;
    organizationId: string;
    pendingEmail?: string | null;
    phone?: string | null;
    positionId: string;
    submittedOn: string;
    timeLocationRequired?: string | null;
    updatedOn: string;
    username: string;
    usernameUnique: boolean;
    verifiedEmail?: boolean | null;
    memberGroup?: {
      __typename?: 'MemberGroup';
      id: string;
      groupName: string;
      deletedOn?: string | null;
      organizationId: string;
      updatedOn: string;
      createdOn: string;
      submittedOn: string;
    } | null;
    organization: {
      __typename?: 'Organization';
      authoritativeSignature: boolean;
      betaTester?: boolean | null;
      breakPolicy: boolean;
      breakPolicyCalifornia: boolean;
      breakPolicyMessage?: string | null;
      bulletin?: boolean | null;
      busySignInQuestion: boolean;
      createdOn: string;
      customSignOff?: boolean | null;
      defaultBreakDuration?: string | null;
      defaultEndTime?: string | null;
      defaultStartTime?: string | null;
      deletedOn?: string | null;
      demo: boolean;
      disclaimer?: string | null;
      gustoCompanyId?: string | null;
      hideUnassignedSubprojects: boolean;
      id: string;
      imageId?: string | null;
      inRecoveryMode?: string | null;
      lockOnSelfSignature?: boolean | null;
      minimumAppVersion?: string | null;
      organizationSettings?: string | null;
      organizationName: string;
      overtimeFlag: boolean;
      ownedBy: string;
      performanceScores?: boolean | null;
      requireCostCode: boolean;
      requireProject: boolean;
      revokedOn?: string | null;
      safetySignature: boolean;
      safetySignatureMessage?: string | null;
      signatureDate?: string | null;
      stripeAccountCreatedOn?: string | null;
      stripeAccountStatus?: string | null;
      stripeAccountType?: string | null;
      stripeCustomerId?: string | null;
      submittedOn: string;
      timeAccuracy: boolean;
      timeAccuracyMessage?: string | null;
      timeOffRequests: boolean;
      timeRounding: boolean;
      timeRoundingInterval: number;
      timeRoundingType: number;
      totalTimeFlag: number;
      trackBudgetCost?: boolean | null;
      trackBudgetEquipmentCosts?: boolean | null;
      trackBudgetEquipmentHours?: boolean | null;
      trackBudgetProgress?: boolean | null;
      trackBudgetProgressValue?: boolean | null;
      trackBudgets: boolean;
      trackCostCode: boolean;
      trackEquipment: boolean;
      trackPaidBreak: boolean;
      trackProject: boolean;
      updatedOn: string;
      useProjectCostCodeScoping?: boolean | null;
      webGps: boolean;
      minBreakSeconds?: number | null;
      perDiem?: boolean | null;
      perDiemAmountTracking?: boolean | null;
      perDiemRequestPermission?: PerDiemRequestPermission | null;
      employeeAutoInvite: EmployeeAutoInvite;
      organizationInfo?: {
        __typename?: 'OrganizationInfo';
        id: string;
        organizationId: string;
        longitude?: number | null;
        latitude?: number | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        county?: string | null;
        state?: string | null;
        postalCode?: string | null;
        country?: string | null;
        phone?: string | null;
        trade?: string | null;
        currencyCode?: string | null;
        employeeMin?: number | null;
        employeeMax?: number | null;
        createdOn: string;
        submittedOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      } | null;
      organizationPayPeriod?: Array<{
        __typename?: 'OrganizationPayPeriod';
        id: string;
        organizationId: string;
        payPeriodType: number;
        startDate: string;
        dayOne: number;
        dayTwo?: number | null;
        createdOn: string;
        submittedOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      }> | null;
      organizationOvertimePeriods?: Array<{
        __typename?: 'OrganizationOvertimePeriod';
        id: string;
        organizationId: string;
        startTime: string;
        lengthInDays?: number | null;
        weeklyOvertime?: number | null;
        dailyOvertime?: number | null;
        dailyDoubletime?: number | null;
        seventhDayOvertime?: boolean | null;
        seventhDayDoubletime?: number | null;
        saturdayMultiplier?: number | null;
        sundayMultiplier?: number | null;
        createdOn: string;
        submittedOn: string;
        updatedOn: string;
        deletedOn?: string | null;
      }> | null;
      subscriptionStatus?: {
        __typename?: 'OrganizationSubscriptionStatus';
        annualSubscriptionCount?: number | null;
        annualSubscriptionDate?: string | null;
        annualSubscriptionInterval?: number | null;
        annualSubscriptionPendingInvoiceId?: number | null;
        cardLastFour?: string | null;
        cardType?: string | null;
        couponCode?: string | null;
        createdOn: string;
        customerId?: number | null;
        deletedOn?: string | null;
        exempt?: boolean | null;
        hasTrialed?: boolean | null;
        id: string;
        nextAssessmentAt?: string | null;
        organizationId: string;
        periodDay?: number | null;
        pricePointAction?: string | null;
        pricePointHandle?: string | null;
        productHandle?: string | null;
        referralCode?: string | null;
        status?: string | null;
        submittedOn: string;
        subscribedThroughProduct?: boolean | null;
        subscriptionId?: number | null;
        subscriptionUpdatedAt?: string | null;
        trialEndedAt?: string | null;
        updatedOn: string;
        userCanceled?: boolean | null;
      } | null;
    };
    position: {
      __typename?: 'Position';
      canEditPosition: boolean;
      canManageProjectCostCodeQuantity: number;
      canRequestTimeOff: boolean;
      clockInRestriction: ClockInRestrictionType;
      clockInRestrictionStartTime?: string | null;
      createdOn: string;
      dailyProjectPrompts: boolean;
      dailyProjectPromptsUseSubprojects: boolean;
      deletedOn?: string | null;
      equipmentSelectionRequirement: EquipmentSelectionOptions;
      id: string;
      level: number;
      manageBudgets: boolean;
      manageBulletinPosts?: boolean | null;
      manageCompanySettings: boolean;
      manageCostCodes: boolean;
      manageEmployeeGroups: boolean;
      manageEmployees: number;
      manageEquipment: boolean;
      manageGpsApproval: number;
      manageMediaCollection: number;
      managePerDiem: number;
      manageProjectGroups: boolean;
      manageProjects: boolean;
      manageSchedule: number;
      manageTimeEntries: number;
      manageTimeOff: number;
      memberLock: number;
      organizationId: string;
      requestSignatures: number;
      scopeByGroup: boolean;
      submittedOn: string;
      timeEvents: number;
      title: string;
      updatedOn: string;
      viewAllActivityReport?: boolean | null;
      viewBudgets: boolean;
      viewEquipmentDetails: boolean;
      viewEquipmentStatus: boolean;
      viewMediaCollection: number;
      viewMemberActivityReport?: number | null;
      viewMemberExpenses: boolean;
      viewProjectExpenses: boolean;
      viewSchedule: number;
      createIncident?: boolean | null;
      manageIncident?: boolean | null;
      performInspections?: boolean | null;
      manageInspections?: boolean | null;
    };
  }> | null;
};

export type QueryBreakHoursMembersQueryVariables = Exact<{
  filter?: InputMaybe<MemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryBreakHoursMembersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberGroupId?: string | null;
    cursor: string;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      submittedOn: string;
      canceled?: string | null;
      edited?: string | null;
    }>;
  }> | null;
};

export type QueryBreakHoursTimeEntriesQueryVariables = Exact<{
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryBreakHoursTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    costCodeId?: string | null;
    createdOn: string;
    daylightSavingTime: boolean;
    deletedOn?: string | null;
    description?: string | null;
    endDeviceTime?: string | null;
    endLocationId?: string | null;
    endTime?: string | null;
    endTimeTrusted?: number | null;
    equipmentId?: string | null;
    memberId: string;
    metaDaylightSavingTime?: boolean | null;
    projectId?: string | null;
    startDeviceTime?: string | null;
    startLocationId?: string | null;
    startTime: string;
    startTimeTrusted: number;
    cursor: string;
    clientLogs: Array<{ __typename?: 'ClientTimeEntryLog'; id: string; createdOn: string; deletedOn?: string | null }>;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      timeEntryId: string;
      startTime: string;
      endTime?: string | null;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type QueryBreakHoursSafetySignoffQueryVariables = Exact<{
  filter?: InputMaybe<SafetySignatureFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryBreakHoursSafetySignoffQuery = {
  __typename?: 'Query';
  safetySignatures?: Array<{
    __typename?: 'SafetySignature';
    id: string;
    memberId: string;
    breakPolicyFollowed?: boolean | null;
    breakPolicyFollowedReason?: string | null;
    breakPolicyFollowedResolvedOn?: string | null;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type CreateBudgetCostMutationVariables = Exact<{
  budgetCost: BudgetCostCreateInput;
}>;

export type CreateBudgetCostMutation = {
  __typename?: 'Mutation';
  createBudgetCost?: { __typename?: 'BudgetCost'; id: string } | null;
};

export type CreateBudgetHoursMutationVariables = Exact<{
  budgetHours: BudgetHoursCreateInput;
}>;

export type CreateBudgetHoursMutation = {
  __typename?: 'Mutation';
  createBudgetHours?: { __typename?: 'BudgetHours'; id: string } | null;
};

export type CreateProgressBudgetMutationVariables = Exact<{
  progressBudget: ProgressBudgetCreateInput;
}>;

export type CreateProgressBudgetMutation = {
  __typename?: 'Mutation';
  createProgressBudget?: { __typename?: 'ProgressBudget'; id: string } | null;
};

export type GetProgressBudgetQueryVariables = Exact<{
  filter?: InputMaybe<ProgressBudgetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProgressBudgetSort> | ProgressBudgetSort>;
}>;

export type GetProgressBudgetQuery = {
  __typename?: 'Query';
  progressBudgets?: Array<{
    __typename?: 'ProgressBudget';
    id: string;
    cursor: string;
    quantity?: number | null;
    value?: number | null;
    projectId: string;
    costCodeId: string;
  }> | null;
};

export type UpdateProgressBudgetMutationVariables = Exact<{
  progressBudget: ProgressBudgetUpdateInput;
}>;

export type UpdateProgressBudgetMutation = {
  __typename?: 'Mutation';
  updateProgressBudget?: { __typename?: 'ProgressBudget'; id: string } | null;
};

export type BudgetCostQueryQueryVariables = Exact<{
  filter?: InputMaybe<BudgetCostFilter>;
  sort?: InputMaybe<Array<BudgetCostSort> | BudgetCostSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type BudgetCostQueryQuery = {
  __typename?: 'Query';
  budgetCosts?: Array<{
    __typename?: 'BudgetCost';
    id: string;
    projectId: string;
    memberId?: string | null;
    costBudget: number;
    costCodeId?: string | null;
    equipmentId?: string | null;
    cursor: string;
    equipmentCostBudget?: number | null;
  }> | null;
};

export type GetBudgetProjectReimportQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetBudgetProjectReimportQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    archivedOn?: string | null;
    cursor: string;
    ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
  }> | null;
};

export type BudgetHoursQueryQueryVariables = Exact<{
  filter?: InputMaybe<BudgetHoursFilter>;
  sort?: InputMaybe<Array<BudgetHoursSort> | BudgetHoursSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type BudgetHoursQueryQuery = {
  __typename?: 'Query';
  budgetHours?: Array<{
    __typename?: 'BudgetHours';
    id: string;
    projectId: string;
    memberId?: string | null;
    budgetSeconds: number;
    costCodeId?: string | null;
    equipmentId?: string | null;
    createdOn: string;
    cursor: string;
    equipmentBudgetSeconds?: number | null;
  }> | null;
};

export type BudgetCostWithJoinedProjectQueryVariables = Exact<{
  filter?: InputMaybe<BudgetCostFilter>;
  sort?: InputMaybe<Array<BudgetCostSort> | BudgetCostSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type BudgetCostWithJoinedProjectQuery = {
  __typename?: 'Query';
  budgetCosts?: Array<{
    __typename?: 'BudgetCost';
    id: string;
    projectId: string;
    memberId?: string | null;
    costBudget: number;
    costCodeId?: string | null;
    equipmentId?: string | null;
    cursor: string;
    equipmentCostBudget?: number | null;
    project: { __typename?: 'Project'; id: string; rootProjectId?: string | null };
  }> | null;
};

export type BudgetHoursWithJoinedProjectQueryVariables = Exact<{
  filter?: InputMaybe<BudgetHoursFilter>;
  sort?: InputMaybe<Array<BudgetHoursSort> | BudgetHoursSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type BudgetHoursWithJoinedProjectQuery = {
  __typename?: 'Query';
  budgetHours?: Array<{
    __typename?: 'BudgetHours';
    id: string;
    projectId: string;
    memberId?: string | null;
    budgetSeconds: number;
    costCodeId?: string | null;
    equipmentId?: string | null;
    createdOn: string;
    cursor: string;
    equipmentBudgetSeconds?: number | null;
    project: { __typename?: 'Project'; id: string; rootProjectId?: string | null };
  }> | null;
};

export type BudgetPerformanceProjectCostCodeLaborMetricQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
}>;

export type BudgetPerformanceProjectCostCodeLaborMetricQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    rootProjectId?: string | null;
    depth: number;
    cursor: string;
    projectCostCodeLaborMetrics: Array<{
      __typename?: 'ProjectCostCodeLaborMetrics';
      id?: string | null;
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectLaborMetricsWithTitleQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
}>;

export type ProjectLaborMetricsWithTitleQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    cursor: string;
    depth: number;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      parentProjectId?: string | null;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type ProjectLaborMetricsWithTitleSingleQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
}>;

export type ProjectLaborMetricsWithTitleSingleQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    cursor: string;
    depth: number;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      parentProjectId?: string | null;
      archivedOn?: string | null;
    }> | null;
  }> | null;
};

export type BudgetPerformanceProjectFragment = {
  __typename?: 'Project';
  id: string;
  title: string;
  cursor: string;
  depth: number;
  children?: Array<{
    __typename?: 'Project';
    id: string;
    parentProjectId?: string | null;
    archivedOn?: string | null;
  }> | null;
};

export type QueryForTotalMembersQueryVariables = Exact<{
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryForTotalMembersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type MyBulletinPostsQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  sort?: InputMaybe<Array<PostSort> | PostSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MyBulletinPostsQuery = {
  __typename?: 'Query';
  myPosts?: Array<{
    __typename?: 'Post';
    id: string;
    title: string;
    message?: string | null;
    recipientType: PostRecipientTypes;
    type: PostTypes;
    allowComments: boolean;
    acknowledgementRequired: boolean;
    managementCanAccess: boolean;
    postDate: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    equipment?: { __typename?: 'Equipment'; id: string; equipmentName: string } | null;
    position?: { __typename?: 'Position'; id: string; title: string } | null;
    createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    comments: Array<{
      __typename?: 'PostComment';
      id: string;
      comment?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    }>;
    recipients: Array<{
      __typename?: 'PostRecipient';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      post: { __typename?: 'Post'; id: string };
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        archivedOn?: string | null;
        position: { __typename?: 'Position'; id: string; title: string };
      };
    }>;
    attachments: Array<{
      __typename?: 'PostAttachment';
      id: string;
      title: string;
      fileUrl?: string | null;
      fileType?: string | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      uploadedByMember: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
      };
    }>;
  }> | null;
};

export type BulletinPostsQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  sort?: InputMaybe<Array<PostSort> | PostSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type BulletinPostsQuery = {
  __typename?: 'Query';
  posts?: Array<{
    __typename?: 'Post';
    id: string;
    title: string;
    message?: string | null;
    recipientType: PostRecipientTypes;
    type: PostTypes;
    allowComments: boolean;
    acknowledgementRequired: boolean;
    managementCanAccess: boolean;
    postDate: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    equipment?: { __typename?: 'Equipment'; id: string; equipmentName: string } | null;
    position?: { __typename?: 'Position'; id: string; title: string } | null;
    createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    comments: Array<{
      __typename?: 'PostComment';
      id: string;
      comment?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    }>;
    recipients: Array<{
      __typename?: 'PostRecipient';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      post: { __typename?: 'Post'; id: string };
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        archivedOn?: string | null;
        position: { __typename?: 'Position'; id: string; title: string };
      };
    }>;
    attachments: Array<{
      __typename?: 'PostAttachment';
      id: string;
      title: string;
      fileUrl?: string | null;
      fileType?: string | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      uploadedByMember: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
      };
    }>;
  }> | null;
};

export type BulletinPostAttachmentQueryVariables = Exact<{
  filter?: InputMaybe<PostAttachmentFilter>;
  sort?: InputMaybe<Array<PostAttachmentSort> | PostAttachmentSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type BulletinPostAttachmentQuery = {
  __typename?: 'Query';
  postAttachments?: Array<{
    __typename?: 'PostAttachment';
    id: string;
    title: string;
    fileUrl?: string | null;
    fileType?: string | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    cursor: string;
    uploadedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
  }> | null;
};

export type QueryPostCommentsQueryVariables = Exact<{
  filter?: InputMaybe<PostCommentFilter>;
  sort?: InputMaybe<Array<PostCommentSort> | PostCommentSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryPostCommentsQuery = {
  __typename?: 'Query';
  postComments?: Array<{
    __typename?: 'PostComment';
    id: string;
    comment?: string | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
    post: { __typename?: 'Post'; id: string; title: string; message?: string | null };
  }> | null;
};

export type CreateBulletinPostsMutationVariables = Exact<{
  post?: InputMaybe<PostCreateInput>;
}>;

export type CreateBulletinPostsMutation = {
  __typename?: 'Mutation';
  createPost?: {
    __typename?: 'Post';
    id: string;
    title: string;
    message?: string | null;
    recipientType: PostRecipientTypes;
    type: PostTypes;
    allowComments: boolean;
    acknowledgementRequired: boolean;
    managementCanAccess: boolean;
    postDate: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    equipment?: { __typename?: 'Equipment'; id: string; equipmentName: string } | null;
    position?: { __typename?: 'Position'; id: string; title: string } | null;
    createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    comments: Array<{
      __typename?: 'PostComment';
      id: string;
      comment?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    }>;
    recipients: Array<{
      __typename?: 'PostRecipient';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      post: { __typename?: 'Post'; id: string };
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        archivedOn?: string | null;
        position: { __typename?: 'Position'; id: string; title: string };
      };
    }>;
    attachments: Array<{
      __typename?: 'PostAttachment';
      id: string;
      title: string;
      fileUrl?: string | null;
      fileType?: string | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      uploadedByMember: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
      };
    }>;
  } | null;
};

export type CreatePostViewerMutationVariables = Exact<{
  postViewer?: InputMaybe<PostViewerCreateInput>;
}>;

export type CreatePostViewerMutation = {
  __typename?: 'Mutation';
  createPostViewer?: {
    __typename?: 'PostViewer';
    id: string;
    acknowledged?: boolean | null;
    latestCommentUpdatedOn?: string | null;
    post: { __typename?: 'Post'; id: string };
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
  } | null;
};

export type CreateBulletinPostCommentMutationVariables = Exact<{
  postComment?: InputMaybe<PostCommentCreateInput>;
}>;

export type CreateBulletinPostCommentMutation = {
  __typename?: 'Mutation';
  createPostComment?: {
    __typename?: 'PostComment';
    id: string;
    comment?: string | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    createdByMember: {
      __typename?: 'Member';
      firstName?: string | null;
      lastName?: string | null;
      id: string;
      imageUrl?: string | null;
    };
    post: { __typename?: 'Post'; id: string; title: string; message?: string | null };
  } | null;
};

export type CreatePostRecipientMutationVariables = Exact<{
  postRecipient?: InputMaybe<PostRecipientCreateInput>;
}>;

export type CreatePostRecipientMutation = {
  __typename?: 'Mutation';
  createPostRecipient?: {
    __typename?: 'PostRecipient';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    post: { __typename?: 'Post'; id: string };
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      position: { __typename?: 'Position'; id: string; title: string };
    };
  } | null;
};

export type GetPostViewersQueryVariables = Exact<{
  filter?: InputMaybe<PostViewerFilter>;
  sort?: InputMaybe<Array<PostViewerSort> | PostViewerSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPostViewersQuery = {
  __typename?: 'Query';
  postViewers?: Array<{
    __typename?: 'PostViewer';
    id: string;
    acknowledged?: boolean | null;
    cursor: string;
    latestCommentUpdatedOn?: string | null;
    post: { __typename?: 'Post'; id: string };
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
  }> | null;
};

export type QueryMembersForBulletinQueryVariables = Exact<{
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryMembersForBulletinQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    cursor: string;
    archivedOn?: string | null;
  }> | null;
};

export type UpdateBulletinPostMutationVariables = Exact<{
  post?: InputMaybe<PostUpdateInput>;
}>;

export type UpdateBulletinPostMutation = {
  __typename?: 'Mutation';
  updatePost?: {
    __typename?: 'Post';
    id: string;
    title: string;
    message?: string | null;
    recipientType: PostRecipientTypes;
    type: PostTypes;
    allowComments: boolean;
    acknowledgementRequired: boolean;
    managementCanAccess: boolean;
    postDate: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    equipment?: { __typename?: 'Equipment'; id: string; equipmentName: string } | null;
    position?: { __typename?: 'Position'; id: string; title: string } | null;
    createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    comments: Array<{
      __typename?: 'PostComment';
      id: string;
      comment?: string | null;
      createdOn: string;
      deletedOn?: string | null;
      createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    }>;
    recipients: Array<{
      __typename?: 'PostRecipient';
      id: string;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      post: { __typename?: 'Post'; id: string };
      member: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
        archivedOn?: string | null;
        position: { __typename?: 'Position'; id: string; title: string };
      };
    }>;
    attachments: Array<{
      __typename?: 'PostAttachment';
      id: string;
      title: string;
      fileUrl?: string | null;
      fileType?: string | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      uploadedByMember: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        imageUrl?: string | null;
      };
    }>;
  } | null;
};

export type UpdatePostViewerMutationVariables = Exact<{
  postViewer?: InputMaybe<PostViewerUpdateInput>;
}>;

export type UpdatePostViewerMutation = {
  __typename?: 'Mutation';
  updatePostViewer?: {
    __typename?: 'PostViewer';
    id: string;
    acknowledged?: boolean | null;
    latestCommentUpdatedOn?: string | null;
    post: { __typename?: 'Post'; id: string };
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
  } | null;
};

export type UpdateBulletinPostCommentMutationVariables = Exact<{
  postComment?: InputMaybe<PostCommentUpdateInput>;
}>;

export type UpdateBulletinPostCommentMutation = {
  __typename?: 'Mutation';
  updatePostComment?: {
    __typename?: 'PostComment';
    id: string;
    comment?: string | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    createdByMember: {
      __typename?: 'Member';
      firstName?: string | null;
      lastName?: string | null;
      id: string;
      imageUrl?: string | null;
    };
    post: { __typename?: 'Post'; id: string; title: string; message?: string | null };
  } | null;
};

export type UpdatePostAttachmentMutationVariables = Exact<{
  postAttachment?: InputMaybe<PostAttachmentUpdateInput>;
}>;

export type UpdatePostAttachmentMutation = {
  __typename?: 'Mutation';
  updatePostAttachment?: {
    __typename?: 'PostAttachment';
    id: string;
    title: string;
    fileUrl?: string | null;
    fileType?: string | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    uploadedByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
  } | null;
};

export type UpdatePostRecipientMutationVariables = Exact<{
  postRecipient?: InputMaybe<PostRecipientUpdateInput>;
}>;

export type UpdatePostRecipientMutation = {
  __typename?: 'Mutation';
  updatePostRecipient?: {
    __typename?: 'PostRecipient';
    id: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    post: { __typename?: 'Post'; id: string };
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      position: { __typename?: 'Position'; id: string; title: string };
    };
  } | null;
};

export type DashboardCostCodeQueryVariables = Exact<{
  id: Scalars['Uuid']['input'];
}>;

export type DashboardCostCodeQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    costCodeGroupId?: string | null;
    title?: string | null;
    unitTitle?: string | null;
    createdOn: string;
    updatedOn: string;
    archivedOn?: string | null;
    costCodeGroup?: { __typename?: 'CostCodeGroup'; id: string; groupName: string } | null;
  }> | null;
};

export type QueryCostCodeSidePanelDetailsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryCostCodeSidePanelDetailsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    title?: string | null;
    costCode: string;
    cursor: string;
    createdOn: string;
    updatedOn: string;
    unitTitle?: string | null;
    costCodeGroup?: { __typename?: 'CostCodeGroup'; id: string; groupName: string } | null;
  }> | null;
};

export type DailyProjectReportJoinedQueryVariables = Exact<{
  filter?: InputMaybe<DailyProjectReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DailyProjectReportSort> | DailyProjectReportSort>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type DailyProjectReportJoinedQuery = {
  __typename?: 'Query';
  dailyProjectReports?: Array<{
    __typename?: 'DailyProjectReport';
    id: string;
    cursor: string;
    projectId: string;
    createdBy: string;
    reportDate: string;
    fileUrl?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    project: {
      __typename?: 'Project';
      id: string;
      title: string;
      rootProjectId?: string | null;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
      children?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    };
    createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    template?: {
      __typename?: 'DailyProjectReportTemplate';
      id: string;
      title: string;
      deletedOn?: string | null;
    } | null;
  }> | null;
};

export type SimpleDailyProjectReportJoinedQueryVariables = Exact<{
  filter?: InputMaybe<DailyProjectReportFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<DailyProjectReportSort> | DailyProjectReportSort>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SimpleDailyProjectReportJoinedQuery = {
  __typename?: 'Query';
  dailyProjectReports?: Array<{ __typename?: 'DailyProjectReport'; id: string; deletedOn?: string | null }> | null;
};

export type ProjectQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
}>;

export type ProjectQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      county?: string | null;
      country?: string | null;
      state?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      number?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    children?: Array<{ __typename?: 'Project'; id: string; title: string; archivedOn?: string | null }> | null;
  }> | null;
};

export type TimeEntryWithJoinedQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['Uuid']['input']>;
  startTime?: InputMaybe<Scalars['Timestamp']['input']>;
  startTimeEnd?: InputMaybe<Scalars['Timestamp']['input']>;
}>;

export type TimeEntryWithJoinedQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    endTime?: string | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      } | null;
    } | null;
    costCode?: { __typename?: 'CostCode'; id: string; costCode: string; title?: string | null } | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      startTimeDst: boolean;
      endTime?: string | null;
      endTimeDst?: boolean | null;
    }>;
  }> | null;
};

export type SafetySignatureQueryVariables = Exact<{
  startTime?: InputMaybe<Scalars['Timestamp']['input']>;
  startTimeEnd?: InputMaybe<Scalars['Timestamp']['input']>;
}>;

export type SafetySignatureQuery = {
  __typename?: 'Query';
  safetySignatures?: Array<{
    __typename?: 'SafetySignature';
    id: string;
    startTime: string;
    endTime: string;
    injured?: boolean | null;
    injuryDescription?: string | null;
    timeAccurate?: boolean | null;
    timeAccurateReason?: string | null;
    memberId: string;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type DailyProjectReportTemplateQueryVariables = Exact<{
  filter?: InputMaybe<DailyProjectReportTemplateFilter>;
}>;

export type DailyProjectReportTemplateQuery = {
  __typename?: 'Query';
  dailyProjectReportTemplates?: Array<{
    __typename?: 'DailyProjectReportTemplate';
    id: string;
    positionId?: string | null;
    organizationId: string;
    audienceType: string;
    sections?: string | null;
    title: string;
    appearance?: string | null;
    createdOn: string;
    position?: { __typename?: 'Position'; id: string; title: string } | null;
  }> | null;
};

export type CreateDailyProjectReportTemplateMutationVariables = Exact<{
  dailyProjectReportTemplate: DailyProjectReportTemplateCreateInput;
}>;

export type CreateDailyProjectReportTemplateMutation = {
  __typename?: 'Mutation';
  createDailyProjectReportTemplate?: {
    __typename?: 'DailyProjectReportTemplate';
    id: string;
    audienceType: string;
    title: string;
    appearance?: string | null;
    sections?: string | null;
    createdOn: string;
    updatedOn: string;
    submittedOn: string;
  } | null;
};

export type UpdateDailyProjectReportTemplateMutationVariables = Exact<{
  dailyProjectReportTemplate: DailyProjectReportTemplateUpdateInput;
}>;

export type UpdateDailyProjectReportTemplateMutation = {
  __typename?: 'Mutation';
  updateDailyProjectReportTemplate?: {
    __typename?: 'DailyProjectReportTemplate';
    id: string;
    audienceType: string;
    title: string;
    appearance?: string | null;
    sections?: string | null;
    createdOn: string;
    updatedOn: string;
    submittedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type EquipmentHoursQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentHoursFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type EquipmentHoursQuery = {
  __typename?: 'Query';
  equipmentHours?: Array<{
    __typename?: 'EquipmentHours';
    id: string;
    equipmentId: string;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type EquipmentEquipmentHoursMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type EquipmentEquipmentHoursMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    cursor: string;
    equipmentHourMeterMetrics: Array<{
      __typename?: 'EquipmentHourMeterMetrics';
      start: string;
      end: string;
      hoursDiff?: number | null;
    }>;
  }> | null;
};

export type PhotosAndNotesDashboardCardQueryQueryVariables = Exact<{
  filter?: InputMaybe<MediaCollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type PhotosAndNotesDashboardCardQueryQuery = {
  __typename?: 'Query';
  mediaCollections?: Array<{ __typename?: 'MediaCollection'; id: string; createdOn: string; cursor: string }> | null;
};

export type PhotoVerificationDashboardCardQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryRekognitionFilter>;
}>;

export type PhotoVerificationDashboardCardQuery = {
  __typename?: 'Query';
  timeEntryRekognition?: Array<{
    __typename?: 'TimeEntryRekognition';
    id: string;
    createdOn: string;
    cursor: string;
  }> | null;
};

export type ActiveCostCodesMainDashboardQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ActiveCostCodesMainDashboardQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    title?: string | null;
    cursor: string;
    costCodeLaborMetrics: Array<{
      __typename?: 'CostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ActiveEquipmentMainDashboardCardQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ActiveEquipmentMainDashboardCardQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    cursor: string;
    equipmentLaborMetrics: Array<{
      __typename?: 'EquipmentLaborMetrics';
      equipmentCents?: number | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ActiveMembersQueryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type ActiveMembersQueryQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    cursor: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      pto?: number | null;
      rt?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type ActiveProjectMainDashboardCardQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  memberId?: InputMaybe<UuidComparison>;
  costCodeId?: InputMaybe<UuidComparison>;
  equipmentId?: InputMaybe<UuidComparison>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ActiveProjectMainDashboardCardQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      id?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryTimeOffDashboardDataQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffFilter>;
}>;

export type QueryTimeOffDashboardDataQuery = {
  __typename?: 'Query';
  timeOffs?: Array<{
    __typename?: 'TimeOff';
    id: string;
    paid: boolean;
    seconds?: number | null;
    cursor: string;
  }> | null;
};

export type QueryTimeOffRequestsDashboardDataQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffRequestFilter>;
}>;

export type QueryTimeOffRequestsDashboardDataQuery = {
  __typename?: 'Query';
  timeOffRequests?: Array<{ __typename?: 'TimeOffRequest'; id: string; cursor: string }> | null;
};

export type SignInTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type SignInTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    startTime: string;
    endTime?: string | null;
    updatedOn: string;
    cursor: string;
    member: { __typename?: 'Member'; id: string; positionId: string };
  }> | null;
};

export type DailySignatureCardQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetySignatureFilter>;
  sort?: InputMaybe<Array<SafetySignatureSort> | SafetySignatureSort>;
}>;

export type DailySignatureCardQuery = {
  __typename?: 'Query';
  safetySignatures?: Array<{
    __typename?: 'SafetySignature';
    id: string;
    memberId: string;
    proxyMemberId: string;
    startTime: string;
    startTimeDst: boolean;
    endTime: string;
    endTimeDst: boolean;
    createdOnDst: boolean;
    deviceType?: string | null;
    breakPolicyFollowed?: boolean | null;
    breakPolicyFollowedReason?: string | null;
    injured?: boolean | null;
    injuryDescription?: string | null;
    timeAccurate?: boolean | null;
    timeAccurateReason?: string | null;
    breakPolicyFollowedResolvedOn?: string | null;
    breakPolicyFollowedResolvedBy?: string | null;
    breakPolicyFollowedResolvedComment?: string | null;
    injuredResolvedOn?: string | null;
    injuredResolvedBy?: string | null;
    injuredResolvedComment?: string | null;
    timeAccurateResolvedOn?: string | null;
    timeAccurateResolvedBy?: string | null;
    timeAccurateResolvedComment?: string | null;
    customQuestionsFlagged?: boolean | null;
    customQuestionsResolvedOn?: string | null;
    signatureUrl?: string | null;
    createdOnLocal: string;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    cursor: string;
  }> | null;
};

export type FoldersQueryVariables = Exact<{
  filter?: InputMaybe<FolderFilter>;
}>;

export type FoldersQuery = {
  __typename?: 'Query';
  folders?: Array<{
    __typename?: 'Folder';
    id: string;
    title: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type CreateFolderMutationVariables = Exact<{
  folder?: InputMaybe<FolderCreateInput>;
}>;

export type CreateFolderMutation = {
  __typename?: 'Mutation';
  createFolder?: {
    __typename?: 'Folder';
    id: string;
    title: string;
    type: FolderTypes;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateFolderMutationVariables = Exact<{
  folder: FolderUpdateInput;
}>;

export type UpdateFolderMutation = {
  __typename?: 'Mutation';
  updateFolder?: {
    __typename?: 'Folder';
    id: string;
    title: string;
    type: FolderTypes;
    deletedOn?: string | null;
  } | null;
};

export type TagsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TagFilter>;
}>;

export type TagsQuery = {
  __typename?: 'Query';
  tags?: Array<{
    __typename?: 'Tag';
    id: string;
    title: string;
    type: TagTypes;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type CreateTagMutationVariables = Exact<{
  tag?: InputMaybe<TagCreateInput>;
}>;

export type CreateTagMutation = {
  __typename?: 'Mutation';
  createTag?: {
    __typename?: 'Tag';
    id: string;
    title: string;
    type: TagTypes;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateTagMutationVariables = Exact<{
  tag: TagUpdateInput;
}>;

export type UpdateTagMutation = {
  __typename?: 'Mutation';
  updateTag?: { __typename?: 'Tag'; id: string; title: string; type: TagTypes; deletedOn?: string | null } | null;
};

export type CreateTagDocumentLinkMutationVariables = Exact<{
  tagDocumentLink?: InputMaybe<TagDocumentLinkCreateInput>;
}>;

export type CreateTagDocumentLinkMutation = {
  __typename?: 'Mutation';
  createTagDocumentLink?: {
    __typename?: 'TagDocumentLink';
    id: string;
    tagId: string;
    documentId: string;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateTagDocumentLinkMutationVariables = Exact<{
  tagDocumentLink?: InputMaybe<TagDocumentLinkUpdateInput>;
}>;

export type UpdateTagDocumentLinkMutation = {
  __typename?: 'Mutation';
  updateTagDocumentLink?: {
    __typename?: 'TagDocumentLink';
    id: string;
    tagId: string;
    documentId: string;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type OrganizationDocumentCountQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationDocumentCountQuery = { __typename?: 'Query'; organizationDocumentCount: number };

export type TagDocumentLinkQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TagDocumentLinkFilter>;
}>;

export type TagDocumentLinkQuery = {
  __typename?: 'Query';
  tagDocumentLinks?: Array<{
    __typename?: 'TagDocumentLink';
    id: string;
    tagId: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type TagMediaCollectionLinkQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TagMediaCollectionLinkFilter>;
}>;

export type TagMediaCollectionLinkQuery = {
  __typename?: 'Query';
  tagMediaCollectionLinks?: Array<{
    __typename?: 'TagMediaCollectionLink';
    id: string;
    tagId: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type QueryMemberSidePanelDetailsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryMemberSidePanelDetailsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    email?: string | null;
    memberNumber?: string | null;
    phone?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    createdOn: string;
    updatedOn: string;
    position: { __typename?: 'Position'; id: string; level: number; title: string };
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
  }> | null;
};

export type FlaggedTimeEntryLocationQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FlaggedTimeEntryLocationFilter>;
}>;

export type FlaggedTimeEntryLocationQuery = {
  __typename?: 'Query';
  flaggedTimeEntryLocations?: Array<{
    __typename?: 'FlaggedTimeEntryLocation';
    id: string;
    organizationId: string;
    timeEntryId: string;
    memberId: string;
    status?: FlaggedTimeEntryLocationStatus | null;
    comment?: string | null;
    reviewedByMemberId?: string | null;
    locationData: string;
    edited: boolean;
    lastStatusUpdatedOn?: string | null;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      endTime?: string | null;
      projectId?: string | null;
      project?: {
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
        projectInfo?: {
          __typename?: 'ProjectInfo';
          latitude?: number | null;
          longitude?: number | null;
          locationRadius?: number | null;
        } | null;
      } | null;
    };
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    reviewedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type UpdateFlaggedTimeEntryLocationMutationVariables = Exact<{
  flaggedTimeEntryLocation: FlaggedTimeEntryLocationUpdateInput;
}>;

export type UpdateFlaggedTimeEntryLocationMutation = {
  __typename?: 'Mutation';
  updateFlaggedTimeEntryLocation?: {
    __typename?: 'FlaggedTimeEntryLocation';
    id: string;
    status?: FlaggedTimeEntryLocationStatus | null;
    comment?: string | null;
  } | null;
};

export type TimeEntriesWithRootProjectOnly4QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type TimeEntriesWithRootProjectOnly4Query = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    cursor: string;
    project?: {
      __typename?: 'Project';
      id: string;
      rootProject?: { __typename?: 'Project'; id: string; projectGroupId?: string | null } | null;
    } | null;
  }> | null;
};

export type DailyProjectReportIdOnlyQueryVariables = Exact<{
  filter?: InputMaybe<DailyProjectReportFilter>;
}>;

export type DailyProjectReportIdOnlyQuery = {
  __typename?: 'Query';
  dailyProjectReports?: Array<{ __typename?: 'DailyProjectReport'; id: string }> | null;
};

export type QueryCostCodeLandingCostCodesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<CostCodeSort> | CostCodeSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryCostCodeLandingCostCodesQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    archivedOn?: string | null;
    costCode: string;
    costCodeGroupId?: string | null;
    createdOn: string;
    cursor: string;
    id: string;
    organizationId: string;
    submittedOn: string;
    title?: string | null;
    unitTitle?: string | null;
    updatedOn: string;
    workersCompId?: string | null;
    costCodeGroup?: {
      __typename?: 'CostCodeGroup';
      createdOn: string;
      cursor: string;
      deletedOn?: string | null;
      groupName: string;
      id: string;
      organizationId: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    costCodeLaborMetrics: Array<{
      __typename?: 'CostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type TimeEntriesWithRootProjectOnly2QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type TimeEntriesWithRootProjectOnly2Query = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    cursor: string;
    project?: {
      __typename?: 'Project';
      id: string;
      rootProject?: { __typename?: 'Project'; id: string; projectGroupId?: string | null } | null;
    } | null;
  }> | null;
};

export type DailyProjectReportIdOnly2QueryVariables = Exact<{
  filter?: InputMaybe<DailyProjectReportFilter>;
}>;

export type DailyProjectReportIdOnly2Query = {
  __typename?: 'Query';
  dailyProjectReports?: Array<{ __typename?: 'DailyProjectReport'; id: string }> | null;
};

export type QueryEquipmentLandingQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryEquipmentLandingQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    equipmentGroupId?: string | null;
    equipmentName: string;
    id: string;
    organizationId: string;
    serialNumber?: string | null;
    submittedOn: string;
    trackManualHours: boolean;
    updatedOn: string;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null; updatedOn: string } | null;
    model?: {
      __typename?: 'EquipmentModel';
      id: string;
      modelNumber?: string | null;
      title?: string | null;
      unknown?: boolean | null;
      year?: number | null;
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null; unknown?: boolean | null };
    } | null;
  }> | null;
};

export type ProjectLandingQueryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type ProjectLandingQueryQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    cursor: string;
    projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type TimeEntriesWithRootProjectOnly3QueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type TimeEntriesWithRootProjectOnly3Query = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    cursor: string;
    project?: {
      __typename?: 'Project';
      id: string;
      rootProject?: { __typename?: 'Project'; id: string; projectGroupId?: string | null } | null;
    } | null;
  }> | null;
};

export type DailyProjectReportIdFileQueryVariables = Exact<{
  filter?: InputMaybe<DailyProjectReportFilter>;
}>;

export type DailyProjectReportIdFileQuery = {
  __typename?: 'Query';
  dailyProjectReports?: Array<{ __typename?: 'DailyProjectReport'; id: string; fileUrl?: string | null }> | null;
};

export type QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentHoursFilter>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery = {
  __typename?: 'Query';
  equipmentHours?: Array<{
    __typename?: 'EquipmentHours';
    submittedByMemberId?: string | null;
    runningHours?: number | null;
    createdOn: string;
  }> | null;
};

export type QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables = Exact<{
  filter?: InputMaybe<EquipmentFilter>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type QueryMachineVsOperatorEquipmentDayDetailsLaborQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    equipmentMemberLaborMetrics: Array<{
      __typename?: 'EquipmentMemberLaborMetrics';
      memberId?: string | null;
      start: string;
      end: string;
      dt?: number | null;
      ot?: number | null;
      rt?: number | null;
    }>;
  }> | null;
};

export type QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables = Exact<{
  memberIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
}>;

export type QueryMachineVsOperatorEquipmentDayDetailsMembersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    cursor: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberGroupId?: string | null;
    position: { __typename?: 'Position'; level: number };
  }> | null;
};

export type QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryMachineVsOperatorReportEquipmentByCategoryQuery = {
  __typename?: 'Query';
  equipment?: Array<{ __typename?: 'Equipment'; id: string; cursor: string }> | null;
};

export type QueryMachineVsOperatorReportTimeMetricsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryMachineVsOperatorReportTimeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    equipmentLaborMetrics: Array<{
      __typename?: 'EquipmentLaborMetrics';
      start: string;
      end: string;
      equipmentId?: string | null;
      dt?: number | null;
      ot?: number | null;
      rt?: number | null;
    }>;
  }> | null;
};

export type CostCodeDetailsFragment = {
  __typename?: 'CostCode';
  id: string;
  costCode: string;
  costCodeGroupId?: string | null;
  title?: string | null;
  unitTitle?: string | null;
  workersCompId?: string | null;
  createdOn: string;
  updatedOn: string;
  archivedOn?: string | null;
  costCodeGroup?: { __typename?: 'CostCodeGroup'; id: string; groupName: string } | null;
  updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
};

export type CostCodesQueryVariables = Exact<{
  filter: CostCodeFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<CostCodeSort> | CostCodeSort>;
}>;

export type CostCodesQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    cursor: string;
    id: string;
    costCode: string;
    costCodeGroupId?: string | null;
    title?: string | null;
    unitTitle?: string | null;
    workersCompId?: string | null;
    createdOn: string;
    updatedOn: string;
    archivedOn?: string | null;
    costCodeGroup?: { __typename?: 'CostCodeGroup'; id: string; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  }> | null;
};

export type CreateCostCode2MutationVariables = Exact<{
  costCode: CostCodeCreateInput;
}>;

export type CreateCostCode2Mutation = {
  __typename?: 'Mutation';
  createCostCode?: {
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    costCodeGroupId?: string | null;
    title?: string | null;
    unitTitle?: string | null;
    workersCompId?: string | null;
    createdOn: string;
    updatedOn: string;
    archivedOn?: string | null;
    costCodeGroup?: { __typename?: 'CostCodeGroup'; id: string; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  } | null;
};

export type UpdateCostCodeMutationVariables = Exact<{
  costCode: CostCodeUpdateInput;
}>;

export type UpdateCostCodeMutation = {
  __typename?: 'Mutation';
  updateCostCode?: {
    __typename?: 'CostCode';
    id: string;
    costCode: string;
    costCodeGroupId?: string | null;
    title?: string | null;
    unitTitle?: string | null;
    workersCompId?: string | null;
    createdOn: string;
    updatedOn: string;
    archivedOn?: string | null;
    costCodeGroup?: { __typename?: 'CostCodeGroup'; id: string; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  } | null;
};

export type CreateCostCode3MutationVariables = Exact<{
  costCode: CostCodeCreateInput;
}>;

export type CreateCostCode3Mutation = {
  __typename?: 'Mutation';
  createCostCode?: {
    __typename?: 'CostCode';
    costCode: string;
    title?: string | null;
    unitTitle?: string | null;
  } | null;
};

export type EmployeeDetailsFragment = {
  __typename?: 'Member';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  username: string;
  email?: string | null;
  phone?: string | null;
  memberNumber?: string | null;
  archivedOn?: string | null;
  createdOn: string;
  deletedOn?: string | null;
  updatedOn: string;
  imageUrl?: string | null;
  positionId: string;
  gustoEmployeeId?: string | null;
  gustoContractorId?: string | null;
  isSubContractor?: boolean | null;
  distantLocationFlaggingDisabled?: boolean | null;
  memberGroupId?: string | null;
  pinCode?: string | null;
  timeLocationRequired?: string | null;
  updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
  position: { __typename?: 'Position'; id: string; title: string; level: number; memberLock: number };
  memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
  memberOvertimePeriods: Array<{
    __typename?: 'MemberOvertimePeriod';
    id: string;
    changeDate: string;
    adhereToOrg: boolean;
    weeklyOvertime?: number | null;
    dailyOvertime?: number | null;
    dailyDoubletime?: number | null;
    seventhDayOvertime?: boolean | null;
    seventhDayDoubletime?: number | null;
    saturdayMultiplier?: number | null;
    sundayMultiplier?: number | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  }>;
  wageHistories?: Array<{
    __typename?: 'WageHistory';
    id: string;
    memberId: string;
    wage: number;
    wageRate: number;
    workWeek?: number | null;
    effectiveRate?: number | null;
    overburden: number;
    changeDate: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
  }> | null;
};

export type QueryEmployeeByIdQueryVariables = Exact<{
  memberId: Scalars['Uuid']['input'];
}>;

export type QueryEmployeeByIdQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    cursor: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    email?: string | null;
    phone?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    updatedOn: string;
    imageUrl?: string | null;
    positionId: string;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    distantLocationFlaggingDisabled?: boolean | null;
    memberGroupId?: string | null;
    pinCode?: string | null;
    timeLocationRequired?: string | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; title: string; level: number; memberLock: number };
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberOvertimePeriods: Array<{
      __typename?: 'MemberOvertimePeriod';
      id: string;
      changeDate: string;
      adhereToOrg: boolean;
      weeklyOvertime?: number | null;
      dailyOvertime?: number | null;
      dailyDoubletime?: number | null;
      seventhDayOvertime?: boolean | null;
      seventhDayDoubletime?: number | null;
      saturdayMultiplier?: number | null;
      sundayMultiplier?: number | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      memberId: string;
      wage: number;
      wageRate: number;
      workWeek?: number | null;
      effectiveRate?: number | null;
      overburden: number;
      changeDate: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
  }> | null;
};

export type QueryEmployeesListQueryVariables = Exact<{
  filter: MemberFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type QueryEmployeesListQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    cursor: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    email?: string | null;
    phone?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    updatedOn: string;
    imageUrl?: string | null;
    positionId: string;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    distantLocationFlaggingDisabled?: boolean | null;
    memberGroupId?: string | null;
    pinCode?: string | null;
    timeLocationRequired?: string | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; title: string; level: number; memberLock: number };
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberOvertimePeriods: Array<{
      __typename?: 'MemberOvertimePeriod';
      id: string;
      changeDate: string;
      adhereToOrg: boolean;
      weeklyOvertime?: number | null;
      dailyOvertime?: number | null;
      dailyDoubletime?: number | null;
      seventhDayOvertime?: boolean | null;
      seventhDayDoubletime?: number | null;
      saturdayMultiplier?: number | null;
      sundayMultiplier?: number | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      memberId: string;
      wage: number;
      wageRate: number;
      workWeek?: number | null;
      effectiveRate?: number | null;
      overburden: number;
      changeDate: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
  }> | null;
};

export type CreateMemberWithDetailsMutationVariables = Exact<{
  member: MemberCreateInput;
}>;

export type CreateMemberWithDetailsMutation = {
  __typename?: 'Mutation';
  createMember?: {
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    email?: string | null;
    phone?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    updatedOn: string;
    imageUrl?: string | null;
    positionId: string;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    distantLocationFlaggingDisabled?: boolean | null;
    memberGroupId?: string | null;
    pinCode?: string | null;
    timeLocationRequired?: string | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; title: string; level: number; memberLock: number };
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberOvertimePeriods: Array<{
      __typename?: 'MemberOvertimePeriod';
      id: string;
      changeDate: string;
      adhereToOrg: boolean;
      weeklyOvertime?: number | null;
      dailyOvertime?: number | null;
      dailyDoubletime?: number | null;
      seventhDayOvertime?: boolean | null;
      seventhDayDoubletime?: number | null;
      saturdayMultiplier?: number | null;
      sundayMultiplier?: number | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      memberId: string;
      wage: number;
      wageRate: number;
      workWeek?: number | null;
      effectiveRate?: number | null;
      overburden: number;
      changeDate: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
  } | null;
};

export type BasicUpdateMemberMutationVariables = Exact<{
  member: MemberUpdateInput;
}>;

export type BasicUpdateMemberMutation = {
  __typename?: 'Mutation';
  updateMember?: {
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    username: string;
    email?: string | null;
    phone?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    updatedOn: string;
    imageUrl?: string | null;
    positionId: string;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    distantLocationFlaggingDisabled?: boolean | null;
    memberGroupId?: string | null;
    pinCode?: string | null;
    timeLocationRequired?: string | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    memberLock?: { __typename?: 'MemberLock'; id: string; effectiveDate?: string | null } | null;
    position: { __typename?: 'Position'; id: string; title: string; level: number; memberLock: number };
    memberGroup?: { __typename?: 'MemberGroup'; id: string; groupName: string } | null;
    memberOvertimePeriods: Array<{
      __typename?: 'MemberOvertimePeriod';
      id: string;
      changeDate: string;
      adhereToOrg: boolean;
      weeklyOvertime?: number | null;
      dailyOvertime?: number | null;
      dailyDoubletime?: number | null;
      seventhDayOvertime?: boolean | null;
      seventhDayDoubletime?: number | null;
      saturdayMultiplier?: number | null;
      sundayMultiplier?: number | null;
      createdOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }>;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      memberId: string;
      wage: number;
      wageRate: number;
      workWeek?: number | null;
      effectiveRate?: number | null;
      overburden: number;
      changeDate: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
  } | null;
};

export type ManageEquipmentMapQueryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
}>;

export type ManageEquipmentMapQueryQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    archivedOn?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    equipmentGroupId?: string | null;
    equipmentModelId?: string | null;
    equipmentName: string;
    fuelCapacity?: string | null;
    id: string;
    imageId?: string | null;
    imageUrl?: string | null;
    importTtlSeconds?: number | null;
    importType?: string | null;
    lastHoursId?: string | null;
    lastLocationId?: string | null;
    organizationId: string;
    serialNumber?: string | null;
    submittedOn: string;
    trackManualHours: boolean;
    updatedOn: string;
    lastLocation?: { __typename?: 'EquipmentLocation'; id: string; latitude: number; longitude: number } | null;
    model?: {
      __typename?: 'EquipmentModel';
      deletedOn?: string | null;
      equipmentCategoryId: string;
      equipmentMakeId: string;
      id: string;
      imageId?: string | null;
      imageUrl?: string | null;
      modelNumber?: string | null;
      submittedOn: string;
      title?: string | null;
      unknown?: boolean | null;
      updatedOn: string;
      year?: number | null;
      category: {
        __typename?: 'EquipmentCategory';
        deletedOn?: string | null;
        equipmentTypeId: string;
        id: string;
        imageId?: string | null;
        imageUrl?: string | null;
        review?: boolean | null;
        submittedOn: string;
        title?: string | null;
        updatedOn: string;
      };
      make: {
        __typename?: 'EquipmentMake';
        deletedOn?: string | null;
        id: string;
        imageId?: string | null;
        review: boolean;
        submittedOn: string;
        title?: string | null;
        unknown?: boolean | null;
        updatedOn: string;
      };
    } | null;
    lastHours?: { __typename?: 'EquipmentHours'; id: string; runningHours?: number | null } | null;
  }> | null;
};

export type ManageProjectsMapQueryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ManageProjectsMapQueryQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    title: string;
    archivedOn?: string | null;
    rootProjectId?: string | null;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProject?: {
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      parentProjectId?: string | null;
    } | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      id: string;
      number?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      state?: string | null;
      postalCode?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      locationRadius?: number | null;
      additionalInfo?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
      requireTimeEntryGps?: string | null;
      reminder?: boolean | null;
      phone?: string | null;
      projectId: string;
    } | null;
    parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
      parentProjectId?: string | null;
      rootProjectId?: string | null;
      rootProject?: {
        __typename?: 'Project';
        id: string;
        title: string;
        archivedOn?: string | null;
        parentProjectId?: string | null;
        parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
      } | null;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        id: string;
        number?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        country?: string | null;
        state?: string | null;
        postalCode?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        locationRadius?: number | null;
        additionalInfo?: string | null;
        distantLocationFlaggingDisabled?: boolean | null;
        requireTimeEntryGps?: string | null;
        reminder?: boolean | null;
        phone?: string | null;
        projectId: string;
      } | null;
      parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    }> | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
  }> | null;
};

export type ProjectCostCodeLinksQueryVariables = Exact<{
  filter?: InputMaybe<ProjectCostCodeLinkFilter>;
  sort?: InputMaybe<Array<ProjectCostCodeLinkSort> | ProjectCostCodeLinkSort>;
}>;

export type ProjectCostCodeLinksQuery = {
  __typename?: 'Query';
  projectCostCodeLinks?: Array<{
    __typename?: 'ProjectCostCodeLink';
    id: string;
    costCodeId: string;
    costCode: {
      __typename?: 'CostCode';
      id: string;
      title?: string | null;
      costCode: string;
      unitTitle?: string | null;
      costCodeGroup?: { __typename?: 'CostCodeGroup'; groupName: string; id: string } | null;
    };
  }> | null;
};

export type UnjoinedProjectCostCodeLinksQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectCostCodeLinkFilter>;
  sort?: InputMaybe<Array<ProjectCostCodeLinkSort> | ProjectCostCodeLinkSort>;
}>;

export type UnjoinedProjectCostCodeLinksQuery = {
  __typename?: 'Query';
  projectCostCodeLinks?: Array<{
    __typename?: 'ProjectCostCodeLink';
    id: string;
    costCodeId: string;
    projectId: string;
    cursor: string;
  }> | null;
};

export type UpdateProjectCostCodeLinkMutationVariables = Exact<{
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkUpdateInput>;
}>;

export type UpdateProjectCostCodeLinkMutation = {
  __typename?: 'Mutation';
  updateProjectCostCodeLink?: { __typename?: 'ProjectCostCodeLink'; id: string } | null;
};

export type CreateProjectCostCodeLinkMutationVariables = Exact<{
  projectCostCodeLink?: InputMaybe<ProjectCostCodeLinkCreateInput>;
}>;

export type CreateProjectCostCodeLinkMutation = {
  __typename?: 'Mutation';
  createProjectCostCodeLink?: { __typename?: 'ProjectCostCodeLink'; id: string } | null;
};

export type InfoFragment = {
  __typename?: 'ProjectInfo';
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  customer?: string | null;
  deletedOn?: string | null;
  id: string;
  latitude?: number | null;
  locationRadius?: number | null;
  longitude?: number | null;
  number?: string | null;
  requireTimeEntryGps?: string | null;
  phone?: string | null;
  postalCode?: string | null;
  projectId: string;
  reminder?: boolean | null;
  state?: string | null;
  additionalInfo?: string | null;
  distantLocationFlaggingDisabled?: boolean | null;
};

export type ProjectDisplayDetailsFragment = {
  __typename?: 'Project';
  id: string;
  title: string;
  depth: number;
  ancestors?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    archivedOn?: string | null;
  }> | null;
};

export type ProjectDetailsFragment = {
  __typename?: 'Project';
  id: string;
  archivedOn?: string | null;
  parentProjectId?: string | null;
  projectGroupId?: string | null;
  rootProjectId?: string | null;
  submittedOn: string;
  title: string;
  updatedOn: string;
  depth: number;
  createdOn: string;
  children?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    archivedOn?: string | null;
  }> | null;
  parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
  projectInfo?: {
    __typename?: 'ProjectInfo';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    country?: string | null;
    customer?: string | null;
    deletedOn?: string | null;
    id: string;
    latitude?: number | null;
    locationRadius?: number | null;
    longitude?: number | null;
    number?: string | null;
    requireTimeEntryGps?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    projectId: string;
    reminder?: boolean | null;
    state?: string | null;
    additionalInfo?: string | null;
    distantLocationFlaggingDisabled?: boolean | null;
  } | null;
  projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
  updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
};

export type FlatProjectDetailsFragment = {
  __typename?: 'Project';
  id: string;
  organizationId: string;
  parentProjectId?: string | null;
  projectGroupId?: string | null;
  rootProjectId?: string | null;
  title: string;
  depth: number;
  archivedOn?: string | null;
  createdOn: string;
  updatedOn: string;
  cursor: string;
};

export type JoinedProjectGroupFragment = {
  __typename?: 'Project';
  projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
};

export type JoinedProjectInfoFragment = {
  __typename?: 'Project';
  projectInfo?: {
    __typename?: 'ProjectInfo';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    country?: string | null;
    customer?: string | null;
    deletedOn?: string | null;
    id: string;
    latitude?: number | null;
    locationRadius?: number | null;
    longitude?: number | null;
    number?: string | null;
    requireTimeEntryGps?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    projectId: string;
    reminder?: boolean | null;
    state?: string | null;
    distantLocationFlaggingDisabled?: boolean | null;
  } | null;
};

export type GetProjectsQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    cursor: string;
    id: string;
    archivedOn?: string | null;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    submittedOn: string;
    title: string;
    updatedOn: string;
    depth: number;
    createdOn: string;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
      additionalInfo?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  }> | null;
};

export type ProjectWithDetailsQueryVariables = Exact<{
  filter: ProjectFilter;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProjectWithDetailsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    archivedOn?: string | null;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    submittedOn: string;
    title: string;
    updatedOn: string;
    depth: number;
    createdOn: string;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
      additionalInfo?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  }> | null;
};

export type DuplicateProjectQueryVariables = Exact<{
  filter: ProjectFilter;
}>;

export type DuplicateProjectQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    title: string;
    parentProjectId?: string | null;
    depth: number;
    archivedOn?: string | null;
    projectCostCodeLink?: Array<{
      __typename?: 'ProjectCostCodeLink';
      id: string;
      costCodeId: string;
      deletedOn?: string | null;
    }> | null;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      archivedOn?: string | null;
      projectCostCodeLink?: Array<{
        __typename?: 'ProjectCostCodeLink';
        id: string;
        costCodeId: string;
        deletedOn?: string | null;
      }> | null;
    }> | null;
  }> | null;
};

export type FlatProjectQueryVariables = Exact<{
  filter: ProjectFilter;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type FlatProjectQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    organizationId: string;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    title: string;
    depth: number;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
  }> | null;
};

export type ReimportProjectQueryQueryVariables = Exact<{
  filter: ProjectFilter;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ReimportProjectQueryQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    organizationId: string;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    title: string;
    depth: number;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
      additionalInfo?: string | null;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
  }> | null;
};

export type ProjectThatIsHomelessAndWithoutFamilyQueryVariables = Exact<{
  filter: ProjectFilter;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProjectThatIsHomelessAndWithoutFamilyQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    organizationId: string;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    title: string;
    depth: number;
    archivedOn?: string | null;
    createdOn: string;
    updatedOn: string;
    cursor: string;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
    } | null;
  }> | null;
};

export type CreateProject1MutationVariables = Exact<{
  project: ProjectCreateInput;
}>;

export type CreateProject1Mutation = {
  __typename?: 'Mutation';
  createProject?: {
    __typename?: 'Project';
    id: string;
    archivedOn?: string | null;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    submittedOn: string;
    title: string;
    updatedOn: string;
    depth: number;
    createdOn: string;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
      additionalInfo?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  } | null;
};

export type CreateProjectInfoMutationVariables = Exact<{
  projectInfo: ProjectInfoCreateInput;
}>;

export type CreateProjectInfoMutation = {
  __typename?: 'Mutation';
  createProjectInfo?: {
    __typename?: 'ProjectInfo';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    country?: string | null;
    customer?: string | null;
    deletedOn?: string | null;
    id: string;
    latitude?: number | null;
    locationRadius?: number | null;
    longitude?: number | null;
    number?: string | null;
    requireTimeEntryGps?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    projectId: string;
    reminder?: boolean | null;
    state?: string | null;
    additionalInfo?: string | null;
    distantLocationFlaggingDisabled?: boolean | null;
  } | null;
};

export type CreateProject2MutationVariables = Exact<{
  project: ProjectCreateInput;
}>;

export type CreateProject2Mutation = {
  __typename?: 'Mutation';
  createProject?: { __typename?: 'Project'; id: string; depth: number } | null;
};

export type ProjectInfoOnlyIdMutationMutationVariables = Exact<{
  projectInfo: ProjectInfoCreateInput;
}>;

export type ProjectInfoOnlyIdMutationMutation = {
  __typename?: 'Mutation';
  createProjectInfo?: { __typename?: 'ProjectInfo'; id: string } | null;
};

export type UpdateProjectWithDetailsMutationVariables = Exact<{
  project: ProjectUpdateInput;
}>;

export type UpdateProjectWithDetailsMutation = {
  __typename?: 'Mutation';
  updateProject?: {
    __typename?: 'Project';
    id: string;
    archivedOn?: string | null;
    parentProjectId?: string | null;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    submittedOn: string;
    title: string;
    updatedOn: string;
    depth: number;
    createdOn: string;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
    }> | null;
    parentProject?: { __typename?: 'Project'; id: string; archivedOn?: string | null } | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      deletedOn?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      requireTimeEntryGps?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      state?: string | null;
      additionalInfo?: string | null;
      distantLocationFlaggingDisabled?: boolean | null;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  } | null;
};

export type UpdateProjectInfoWithInfoMutationVariables = Exact<{
  projectInfo: ProjectInfoUpdateInput;
}>;

export type UpdateProjectInfoWithInfoMutation = {
  __typename?: 'Mutation';
  updateProjectInfo?: {
    __typename?: 'ProjectInfo';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    country?: string | null;
    customer?: string | null;
    deletedOn?: string | null;
    id: string;
    latitude?: number | null;
    locationRadius?: number | null;
    longitude?: number | null;
    number?: string | null;
    requireTimeEntryGps?: string | null;
    phone?: string | null;
    postalCode?: string | null;
    projectId: string;
    reminder?: boolean | null;
    state?: string | null;
    additionalInfo?: string | null;
    distantLocationFlaggingDisabled?: boolean | null;
  } | null;
};

export type ProjectWithAncestorsQueryVariables = Exact<{
  filter: ProjectFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
}>;

export type ProjectWithAncestorsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    archivedOn?: string | null;
    parentProjectId?: string | null;
    cursor: string;
    projectGroupId?: string | null;
    rootProjectId?: string | null;
    submittedOn: string;
    title: string;
    createdOn: string;
    updatedOn: string;
    depth: number;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      additionalInfo?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      customer?: string | null;
      id: string;
      latitude?: number | null;
      locationRadius?: number | null;
      longitude?: number | null;
      number?: string | null;
      phone?: string | null;
      postalCode?: string | null;
      projectId: string;
      reminder?: boolean | null;
      requireTimeEntryGps?: string | null;
      state?: string | null;
    } | null;
    ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
  }> | null;
};

export type ProjectWithCostCodeLinksAncestorsChildrenQueryVariables = Exact<{
  filter?: InputMaybe<ProjectFilter>;
}>;

export type ProjectWithCostCodeLinksAncestorsChildrenQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    projectGroupId?: string | null;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    archivedOn?: string | null;
    children?: Array<{ __typename?: 'Project'; title: string; id: string; archivedOn?: string | null }> | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      archivedOn?: string | null;
    }> | null;
    projectCostCodeLink?: Array<{
      __typename?: 'ProjectCostCodeLink';
      id: string;
      costCodeId: string;
      deletedOn?: string | null;
    }> | null;
  }> | null;
};

export type ProjectSubprojectDetailsQueryVariables = Exact<{
  filter: ProjectFilter;
}>;

export type ProjectSubprojectDetailsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    children?: Array<{
      __typename?: 'Project';
      id: string;
      archivedOn?: string | null;
      title: string;
      projectInfo?: {
        __typename?: 'ProjectInfo';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        country?: string | null;
        customer?: string | null;
        deletedOn?: string | null;
        id: string;
        latitude?: number | null;
        locationRadius?: number | null;
        longitude?: number | null;
        number?: string | null;
        requireTimeEntryGps?: string | null;
        phone?: string | null;
        postalCode?: string | null;
        projectId: string;
        reminder?: boolean | null;
        state?: string | null;
        additionalInfo?: string | null;
        distantLocationFlaggingDisabled?: boolean | null;
      } | null;
      projectGroup?: { __typename?: 'ProjectGroup'; id: string; deletedOn?: string | null; groupName: string } | null;
    }> | null;
  }> | null;
};

export type QuickSearchProjectQueryQueryVariables = Exact<{
  filter: ProjectFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
}>;

export type QuickSearchProjectQueryQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    archivedOn?: string | null;
  }> | null;
};

export type QueryPayrollCostsMemberMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryPayrollCostsMemberMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    cursor: string;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      memberId: string;
      wage: number;
      wageRate: number;
      workWeek?: number | null;
      effectiveRate?: number | null;
      overburden: number;
      changeDate: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
      deletedOn?: string | null;
    }> | null;
    memberLaborMetrics: Array<{
      __typename?: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakDtCents?: number | null;
      breakOt?: number | null;
      breakOtCents?: number | null;
      breakOverburdenCents?: number | null;
      breakRt?: number | null;
      breakRtCents?: number | null;
      breakUpt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      memberId: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      pto?: number | null;
      ptoCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberNumber?: string | null;
    cursor: string;
    archivedOn?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    memberCostCodeLaborMetrics: Array<{
      __typename: 'MemberCostCodeLaborMetrics';
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      edited?: string | null;
      canceled?: string | null;
      authoritativeSignatureId?: string | null;
      selfSignatureId?: string | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      changeDate: string;
      effectiveRate?: number | null;
      overburden: number;
      memberId: string;
      submittedOn: string;
      updatedOn: string;
      createdOn: string;
      deletedOn?: string | null;
    }> | null;
    timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
    timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
    perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
  }> | null;
};

export type QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberNumber?: string | null;
    cursor: string;
    archivedOn?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    memberEquipmentLaborMetrics: Array<{
      __typename: 'MemberEquipmentLaborMetrics';
      equipmentId?: string | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      edited?: string | null;
      canceled?: string | null;
      authoritativeSignatureId?: string | null;
      selfSignatureId?: string | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      changeDate: string;
      effectiveRate?: number | null;
      overburden: number;
      memberId: string;
      submittedOn: string;
      updatedOn: string;
      createdOn: string;
      deletedOn?: string | null;
    }> | null;
    timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
    timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
    perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
  }> | null;
};

export type QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberNumber?: string | null;
    cursor: string;
    archivedOn?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    memberProjectCostCodeLaborMetrics: Array<{
      __typename: 'MemberProjectCostCodeLaborMetrics';
      projectId?: string | null;
      costCodeId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      edited?: string | null;
      canceled?: string | null;
      authoritativeSignatureId?: string | null;
      selfSignatureId?: string | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      changeDate: string;
      effectiveRate?: number | null;
      overburden: number;
      memberId: string;
      submittedOn: string;
      updatedOn: string;
      createdOn: string;
      deletedOn?: string | null;
    }> | null;
    timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
    timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
    perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
  }> | null;
};

export type QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersPayrollDailyExportByProjectLaborMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    memberNumber?: string | null;
    cursor: string;
    archivedOn?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    memberProjectLaborMetrics: Array<{
      __typename: 'MemberProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      edited?: string | null;
      canceled?: string | null;
      authoritativeSignatureId?: string | null;
      selfSignatureId?: string | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      changeDate: string;
      effectiveRate?: number | null;
      overburden: number;
      memberId: string;
      submittedOn: string;
      updatedOn: string;
      createdOn: string;
      deletedOn?: string | null;
    }> | null;
    timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
    timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
    perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
  }> | null;
};

export type ProjectAncestorsWithNumbersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectAncestorsWithNumbersQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    rootProjectId?: string | null;
    cursor: string;
    projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      archivedOn?: string | null;
      projectInfo?: { __typename?: 'ProjectInfo'; id: string; number?: string | null } | null;
    }> | null;
  }> | null;
};

export type QueryMembersPayrollDailyExportLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMembersPayrollDailyExportLaborMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    isSubContractor?: boolean | null;
    memberNumber?: string | null;
    cursor: string;
    archivedOn?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      pto?: number | null;
      ptoCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
      upto?: number | null;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      edited?: string | null;
      canceled?: string | null;
      authoritativeSignatureId?: string | null;
      selfSignatureId?: string | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      changeDate: string;
      effectiveRate?: number | null;
      overburden: number;
      memberId: string;
      submittedOn: string;
      updatedOn: string;
      createdOn: string;
      deletedOn?: string | null;
    }> | null;
    timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
    timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
    perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
  }> | null;
};

export type PayrollMemberPredicateMemberFragment = {
  __typename?: 'Member';
  id: string;
  cursor: string;
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: string | null;
  memberGroupId?: string | null;
  memberNumber?: string | null;
  archivedOn?: string | null;
  gustoEmployeeId?: string | null;
  gustoContractorId?: string | null;
  isSubContractor?: boolean | null;
  memberTimeDocuments: Array<{
    __typename?: 'MemberTimeDocument';
    id: string;
    edited?: string | null;
    canceled?: string | null;
    authoritativeSignatureId?: string | null;
    selfSignatureId?: string | null;
  }>;
  memberLock?: {
    __typename?: 'MemberLock';
    id: string;
    effectiveDate?: string | null;
    updaterMemberId: string;
    memberId: string;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
  } | null;
  wageHistories?: Array<{
    __typename?: 'WageHistory';
    id: string;
    wage: number;
    wageRate: number;
    changeDate: string;
    effectiveRate?: number | null;
    overburden: number;
    memberId: string;
    submittedOn: string;
    updatedOn: string;
    createdOn: string;
    deletedOn?: string | null;
  }> | null;
  timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
  timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
  perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
};

export type MemberPayrollExportDataQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  memberTimeDocumentFilter: MemberTimeDocumentFilter;
  memberTimeDocumentSort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type MemberPayrollExportDataQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    imageUrl?: string | null;
    memberGroupId?: string | null;
    memberNumber?: string | null;
    archivedOn?: string | null;
    gustoEmployeeId?: string | null;
    gustoContractorId?: string | null;
    isSubContractor?: boolean | null;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakDtCents?: number | null;
      breakOt?: number | null;
      breakOtCents?: number | null;
      breakOverburdenCents?: number | null;
      breakRt?: number | null;
      breakRtCents?: number | null;
      breakUpt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      memberId: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      pto?: number | null;
      ptoCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
      upto?: number | null;
    }>;
    memberTimeDocuments: Array<{
      __typename?: 'MemberTimeDocument';
      id: string;
      edited?: string | null;
      canceled?: string | null;
      authoritativeSignatureId?: string | null;
      selfSignatureId?: string | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
    wageHistories?: Array<{
      __typename?: 'WageHistory';
      id: string;
      wage: number;
      wageRate: number;
      changeDate: string;
      effectiveRate?: number | null;
      overburden: number;
      memberId: string;
      submittedOn: string;
      updatedOn: string;
      createdOn: string;
      deletedOn?: string | null;
    }> | null;
    timeEntries: Array<{ __typename?: 'TimeEntry'; id: string; endTime?: string | null }>;
    timeOffs: Array<{ __typename?: 'TimeOff'; id: string }>;
    perDiems: Array<{ __typename?: 'PerDiem'; id: string; amount?: number | null }>;
  }> | null;
};

export type GustoMatchBusyMembersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GustoMatchBusyMembersQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    gustoEmployeeId?: string | null;
    cursor: string;
  }> | null;
};

export type BasicGustoContractorIdQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type BasicGustoContractorIdQuery = {
  __typename?: 'Query';
  members?: Array<{ __typename?: 'Member'; id: string; cursor: string; gustoContractorId?: string | null }> | null;
};

export type GustoMatchBusyContractorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GustoMatchBusyContractorsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    gustoContractorId?: string | null;
    cursor: string;
  }> | null;
};

export type MemberPayrollContractorQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type MemberPayrollContractorQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    archivedOn?: string | null;
    gustoContractorId?: string | null;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      pto?: number | null;
      rt?: number | null;
      start: string;
      upto?: number | null;
    }>;
    memberLock?: {
      __typename?: 'MemberLock';
      id: string;
      effectiveDate?: string | null;
      updaterMemberId: string;
      memberId: string;
      createdOn: string;
      submittedOn: string;
      updatedOn: string;
    } | null;
  }> | null;
};

export type BasicGustoEmployeeIdQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type BasicGustoEmployeeIdQuery = {
  __typename?: 'Query';
  members?: Array<{ __typename?: 'Member'; id: string; cursor: string; gustoEmployeeId?: string | null }> | null;
};

export type QueryMembersLaborMetricsGustoSyncQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryMembersLaborMetricsGustoSyncQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    memberNumber?: string | null;
    gustoEmployeeId?: string | null;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      pto?: number | null;
      ptoCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type MemberLaborMetricsGustoPtoEligibilityQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type MemberLaborMetricsGustoPtoEligibilityQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    gustoEmployeeId?: string | null;
    memberLaborMetrics: Array<{ __typename: 'MemberLaborMetrics'; pto?: number | null }>;
  }> | null;
};

export type EmployeesWithTimeJoinedWagesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['Date']['input'];
  endTime: Scalars['Date']['input'];
}>;

export type EmployeesWithTimeJoinedWagesQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberProjectLaborMetrics: Array<{
      __typename?: 'MemberProjectLaborMetrics';
      id?: string | null;
      projectId?: string | null;
      rt?: number | null;
      ot?: number | null;
      dt?: number | null;
      rtCents?: number | null;
      otCents?: number | null;
      dtCents?: number | null;
      descRtCents?: number | null;
      descDtCents?: number | null;
      descOtCents?: number | null;
    }>;
  }> | null;
};

export type ProjectJournalEntryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type ProjectJournalEntryQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    cursor: string;
    ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    projectLaborMetrics: Array<{
      __typename?: 'ProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type ProjectBasicDepthQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProjectBasicDepthQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    depth: number;
    cursor: string;
    title: string;
    ancestors?: Array<{ __typename?: 'Project'; id: string; depth: number; title: string }> | null;
  }> | null;
};

export type MemberProjectJournalEntryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type MemberProjectJournalEntryQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberProjectLaborMetrics: Array<{
      __typename?: 'MemberProjectLaborMetrics';
      id?: string | null;
      memberId: string;
      projectId?: string | null;
      rt?: number | null;
      dt?: number | null;
      ot?: number | null;
      descRt?: number | null;
      descDt?: number | null;
      descOt?: number | null;
    }>;
    memberNoProjectLaborMetrics: Array<{
      __typename?: 'MemberProjectLaborMetrics';
      id?: string | null;
      memberId: string;
      projectId?: string | null;
      rt?: number | null;
      dt?: number | null;
      ot?: number | null;
    }>;
    memberPtoLaborMetrics: Array<{ __typename?: 'MemberLaborMetrics'; id?: string | null; pto?: number | null }>;
  }> | null;
};

export type PayrollScanBreakComplianceTimeEntryFragment = {
  __typename?: 'TimeEntry';
  id: string;
  memberId: string;
  startTime: string;
  endTime?: string | null;
  breaks: Array<{
    __typename?: 'TimeEntryBreak';
    id: string;
    startTime: string;
    endTime?: string | null;
    deletedOn?: string | null;
  }>;
};

export type PayrollScanBreakComplianceTimeEntryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PayrollScanBreakComplianceTimeEntryQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    cursor: string;
    id: string;
    memberId: string;
    startTime: string;
    endTime?: string | null;
    breaks: Array<{
      __typename?: 'TimeEntryBreak';
      id: string;
      startTime: string;
      endTime?: string | null;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type PayrollScanBreakComplianceSafetySignatureQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SafetySignatureFilter>;
}>;

export type PayrollScanBreakComplianceSafetySignatureQuery = {
  __typename?: 'Query';
  safetySignatures?: Array<{
    __typename?: 'SafetySignature';
    id: string;
    startTime: string;
    endTime: string;
    memberId: string;
    breakPolicyFollowed?: boolean | null;
    breakPolicyFollowedResolvedOn?: string | null;
    createdOnLocal: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type QueryPayrollExportClosedTimeEntriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryPayrollExportClosedTimeEntriesQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{ __typename?: 'TimeEntry'; id: string; cursor: string }> | null;
};

export type CreatePerDiemMutationVariables = Exact<{
  perDiem: PerDiemCreateInput;
}>;

export type CreatePerDiemMutation = {
  __typename?: 'Mutation';
  createPerDiem?: {
    __typename?: 'PerDiem';
    id: string;
    organizationId: string;
    memberId: string;
    submittedBy: string;
    dateStamp: string;
    description?: string | null;
    amount?: number | null;
    requestedAmount?: number | null;
    state: PerDiemStateTypes;
    projectId?: string | null;
    createdOnDst: boolean;
    createdOnLocal: string;
    createdOn: string;
    updatedOn: string;
    submittedOn: string;
    answeredOn?: string | null;
  } | null;
};

export type UpdatePerDiemMutationVariables = Exact<{
  perDiem: PerDiemUpdateInput;
}>;

export type UpdatePerDiemMutation = {
  __typename?: 'Mutation';
  updatePerDiem?: {
    __typename?: 'PerDiem';
    id: string;
    organizationId: string;
    memberId: string;
    submittedBy: string;
    dateStamp: string;
    description?: string | null;
    amount?: number | null;
    requestedAmount?: number | null;
    rejectionReason?: string | null;
    state: PerDiemStateTypes;
    answeredBy?: string | null;
    answeredOn?: string | null;
    projectId?: string | null;
    createdOnDst: boolean;
    createdOnLocal: string;
    createdOn: string;
    updatedOn: string;
    submittedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type PerDiemQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PerDiemFilter>;
}>;

export type PerDiemQuery = {
  __typename?: 'Query';
  perDiems?: Array<{
    __typename?: 'PerDiem';
    id: string;
    cursor: string;
    organizationId: string;
    memberId: string;
    submittedBy: string;
    dateStamp: string;
    description?: string | null;
    amount?: number | null;
    requestedAmount?: number | null;
    rejectionReason?: string | null;
    projectId?: string | null;
    createdOn: string;
    createdOnLocal: string;
    answeredOn?: string | null;
    answeredBy?: string | null;
    state: PerDiemStateTypes;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
      children?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    answeredByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type TimeEntryRekognitionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryRekognitionFilter>;
}>;

export type TimeEntryRekognitionQuery = {
  __typename?: 'Query';
  timeEntryRekognition?: Array<{
    __typename?: 'TimeEntryRekognition';
    confidence?: number | null;
    cursor: string;
    id: string;
    imageId?: string | null;
    imageUrl?: string | null;
    referenceImageId?: string | null;
    referenceImageUrl?: string | null;
    createdOnDst?: boolean | null;
    resolvedOn?: string | null;
    similarity?: number | null;
    type: string;
    source?: string | null;
    createdOn: string;
    createdOnLocal: string;
    timeEntry: {
      __typename?: 'TimeEntry';
      id: string;
      startTime: string;
      endTime?: string | null;
      actionType: number;
      deletedOn?: string | null;
      member: {
        __typename?: 'Member';
        id: string;
        imageUrl?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    };
  }> | null;
};

export type CreateMediaEntryCommentMutationVariables = Exact<{
  mediaEntryComment?: InputMaybe<MediaEntryCommentCreateInput>;
}>;

export type CreateMediaEntryCommentMutation = {
  __typename?: 'Mutation';
  createMediaEntryComment?: {
    __typename?: 'MediaEntryComment';
    id: string;
    comment: string;
    mediaEntryId?: string | null;
    createdOn: string;
  } | null;
};

export type CreateTagMediaCollectionLinkMutationVariables = Exact<{
  tagMediaCollectionLink?: InputMaybe<TagMediaCollectionLinkCreateInput>;
}>;

export type CreateTagMediaCollectionLinkMutation = {
  __typename?: 'Mutation';
  createTagMediaCollectionLink?: {
    __typename?: 'TagMediaCollectionLink';
    id: string;
    tagId: string;
    mediaCollectionId: string;
    createdOn: string;
  } | null;
};

export type UpdateTagMediaCollectionLinkMutationVariables = Exact<{
  tagMediaCollectionLink?: InputMaybe<TagMediaCollectionLinkUpdateInput>;
}>;

export type UpdateTagMediaCollectionLinkMutation = {
  __typename?: 'Mutation';
  updateTagMediaCollectionLink?: {
    __typename?: 'TagMediaCollectionLink';
    id: string;
    tagId: string;
    mediaCollectionId: string;
    createdOn: string;
    deletedOn?: string | null;
  } | null;
};

export type MediaEntryCommentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MediaEntryCommentFilter>;
}>;

export type MediaEntryCommentsQuery = {
  __typename?: 'Query';
  mediaEntryComments?: Array<{
    __typename?: 'MediaEntryComment';
    id: string;
    comment: string;
    mediaEntryId?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    updatedOn: string;
    updatedBy?: string | null;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  }> | null;
};

export type UpdateMediaEntryCommentMutationVariables = Exact<{
  mediaEntryComment: MediaEntryCommentUpdateInput;
}>;

export type UpdateMediaEntryCommentMutation = {
  __typename?: 'Mutation';
  updateMediaEntryComment?: {
    __typename?: 'MediaEntryComment';
    id: string;
    comment: string;
    deletedOn?: string | null;
  } | null;
};

export type MediaCollectionsQueryVariables = Exact<{
  filter?: InputMaybe<MediaCollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MediaCollectionSort> | MediaCollectionSort>;
}>;

export type MediaCollectionsQuery = {
  __typename?: 'Query';
  mediaCollections?: Array<{
    __typename?: 'MediaCollection';
    id: string;
    title?: string | null;
    description?: string | null;
    source?: MediaCollectionSourceTypes | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    projectId?: string | null;
    updatedBy?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    } | null;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    mediaEntries: Array<{
      __typename?: 'MediaEntry';
      id: string;
      mediaCollectionId: string;
      fileUrl?: string | null;
      annotationFileUrl?: string | null;
      videoThumbFileUrl?: string | null;
      metaData?: string | null;
      entryType: number;
      captureDate: string;
      createdOn: string;
      deletedOn?: string | null;
      comment?: string | null;
      lastAnnotatedAt?: string | null;
      collection: {
        __typename?: 'MediaCollection';
        id: string;
        createdBy: string;
        createdOn: string;
        updatedOn: string;
        tagLinks: Array<{
          __typename?: 'TagMediaCollectionLink';
          id: string;
          tag: { __typename?: 'Tag'; id: string; title: string };
        }>;
        createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
        updatedByMember?: {
          __typename?: 'Member';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      };
      location?: {
        __typename?: 'MediaEntryLocation';
        id: string;
        latitude: number;
        longitude: number;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
      } | null;
      lastAnnotatedByMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    tagLinks: Array<{
      __typename?: 'TagMediaCollectionLink';
      id: string;
      tagId: string;
      deletedOn?: string | null;
      tag: { __typename?: 'Tag'; id: string; title: string; deletedOn?: string | null };
    }>;
  }> | null;
};

export type SimpleMediaCollectionsQueryVariables = Exact<{
  filter?: InputMaybe<MediaCollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MediaCollectionSort> | MediaCollectionSort>;
}>;

export type SimpleMediaCollectionsQuery = {
  __typename?: 'Query';
  mediaCollections?: Array<{ __typename?: 'MediaCollection'; id: string; deletedOn?: string | null }> | null;
};

export type MediaCollectionsForProjectQueryVariables = Exact<{
  filter?: InputMaybe<MediaCollectionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<MediaCollectionSort> | MediaCollectionSort>;
}>;

export type MediaCollectionsForProjectQuery = {
  __typename?: 'Query';
  mediaCollections?: Array<{
    __typename?: 'MediaCollection';
    id: string;
    title?: string | null;
    description?: string | null;
    source?: MediaCollectionSourceTypes | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    projectId?: string | null;
    updatedBy?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    } | null;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    mediaEntries: Array<{
      __typename?: 'MediaEntry';
      id: string;
      mediaCollectionId: string;
      fileUrl?: string | null;
      annotationFileUrl?: string | null;
      videoThumbFileUrl?: string | null;
      metaData?: string | null;
      entryType: number;
      captureDate: string;
      createdOn: string;
      deletedOn?: string | null;
      comment?: string | null;
      lastAnnotatedAt?: string | null;
      collection: {
        __typename?: 'MediaCollection';
        id: string;
        createdBy: string;
        createdOn: string;
        updatedOn: string;
        tagLinks: Array<{
          __typename?: 'TagMediaCollectionLink';
          id: string;
          tag: { __typename?: 'Tag'; id: string; title: string };
        }>;
        createdByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
        updatedByMember?: {
          __typename?: 'Member';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      };
      location?: {
        __typename?: 'MediaEntryLocation';
        id: string;
        latitude: number;
        longitude: number;
        timeGmt: string;
        timeLocal: string;
        timeDst: boolean;
      } | null;
      lastAnnotatedByMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    tagLinks: Array<{
      __typename?: 'TagMediaCollectionLink';
      id: string;
      tagId: string;
      deletedOn?: string | null;
      tag: { __typename?: 'Tag'; id: string; title: string; deletedOn?: string | null };
    }>;
  }> | null;
};

export type CreateMediaCollectionMutationVariables = Exact<{
  mediaCollection: MediaCollectionCreateInput;
}>;

export type CreateMediaCollectionMutation = {
  __typename?: 'Mutation';
  createMediaCollection?: {
    __typename?: 'MediaCollection';
    id: string;
    title?: string | null;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    projectId?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    } | null;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    mediaEntries: Array<{
      __typename?: 'MediaEntry';
      id: string;
      mediaCollectionId: string;
      fileUrl?: string | null;
      annotationFileUrl?: string | null;
      videoThumbFileUrl?: string | null;
      captureDate: string;
      deletedOn?: string | null;
      comment?: string | null;
      lastAnnotatedAt?: string | null;
      location?: { __typename?: 'MediaEntryLocation'; id: string; latitude: number; longitude: number } | null;
      lastAnnotatedByMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    tagLinks: Array<{
      __typename?: 'TagMediaCollectionLink';
      id: string;
      tagId: string;
      deletedOn?: string | null;
      tag: { __typename?: 'Tag'; id: string; title: string; deletedOn?: string | null };
    }>;
  } | null;
};

export type UpdateMediaCollectionMutationVariables = Exact<{
  mediaCollection: MediaCollectionUpdateInput;
}>;

export type UpdateMediaCollectionMutation = {
  __typename?: 'Mutation';
  updateMediaCollection?: {
    __typename?: 'MediaCollection';
    id: string;
    title?: string | null;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    projectId?: string | null;
    updatedBy?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    } | null;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    mediaEntries: Array<{
      __typename?: 'MediaEntry';
      id: string;
      mediaCollectionId: string;
      fileUrl?: string | null;
      annotationFileUrl?: string | null;
      videoThumbFileUrl?: string | null;
      entryType: number;
      captureDate: string;
      deletedOn?: string | null;
      comment?: string | null;
      lastAnnotatedAt?: string | null;
      metaData?: string | null;
      location?: { __typename?: 'MediaEntryLocation'; id: string; latitude: number; longitude: number } | null;
      lastAnnotatedByMember?: {
        __typename?: 'Member';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
    updatedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
    tagLinks: Array<{
      __typename?: 'TagMediaCollectionLink';
      id: string;
      tagId: string;
      deletedOn?: string | null;
      tag: { __typename?: 'Tag'; id: string; title: string; deletedOn?: string | null };
    }>;
  } | null;
};

export type DeleteMediaEntryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
}>;

export type DeleteMediaEntryMutation = {
  __typename?: 'Mutation';
  updateMediaEntry?: {
    __typename?: 'MediaEntry';
    id: string;
    mediaCollectionId: string;
    fileUrl?: string | null;
    captureDate: string;
    deletedOn?: string | null;
    comment?: string | null;
    location?: { __typename?: 'MediaEntryLocation'; id: string; latitude: number; longitude: number } | null;
  } | null;
};

export type DeleteCollectionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  deletedOn?: InputMaybe<Scalars['Timestamp']['input']>;
}>;

export type DeleteCollectionMutation = {
  __typename?: 'Mutation';
  updateMediaCollection?: {
    __typename?: 'MediaCollection';
    id: string;
    title?: string | null;
    description?: string | null;
    deletedOn?: string | null;
    createdOn: string;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    } | null;
    createdByMember: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    mediaEntries: Array<{
      __typename?: 'MediaEntry';
      id: string;
      mediaCollectionId: string;
      fileUrl?: string | null;
      annotationFileUrl?: string | null;
      videoThumbFileUrl?: string | null;
      captureDate: string;
      createdOn: string;
      deletedOn?: string | null;
      comment?: string | null;
      location?: { __typename?: 'MediaEntryLocation'; id: string; latitude: number; longitude: number } | null;
    }>;
    tagLinks: Array<{
      __typename?: 'TagMediaCollectionLink';
      id: string;
      tagId: string;
      deletedOn?: string | null;
      tag: { __typename?: 'Tag'; id: string; title: string; deletedOn?: string | null };
    }>;
  } | null;
};

export type MediaEntryQueryVariables = Exact<{
  filter?: InputMaybe<MediaEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type MediaEntryQuery = {
  __typename?: 'Query';
  mediaEntries?: Array<{
    __typename?: 'MediaEntry';
    id: string;
    mediaCollectionId: string;
    fileUrl?: string | null;
    annotationFileUrl?: string | null;
    videoThumbFileUrl?: string | null;
    captureDate: string;
    createdOn: string;
    deletedOn?: string | null;
    comment?: string | null;
    location?: {
      __typename?: 'MediaEntryLocation';
      id: string;
      latitude: number;
      longitude: number;
      timeGmt: string;
      timeLocal: string;
      timeDst: boolean;
    } | null;
  }> | null;
};

export type QueryProjectSidePanelDetailsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryProjectSidePanelDetailsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    cursor: string;
    createdOn: string;
    updatedOn: string;
    rootProjectId?: string | null;
    projectInfo?: {
      __typename?: 'ProjectInfo';
      id: string;
      number?: string | null;
      customer?: string | null;
      phone?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      county?: string | null;
      postalCode?: string | null;
      additionalInfo?: string | null;
      updatedOn: string;
    } | null;
    projectGroup?: { __typename?: 'ProjectGroup'; id: string; groupName: string } | null;
  }> | null;
};

export type TimeEntryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type TimeEntryQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    cursor: string;
    startTime: string;
    endTime?: string | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      imageUrl?: string | null;
    };
  }> | null;
};

export type IncidentDetailsFragment = {
  __typename?: 'Incident';
  id: string;
  organizationId: string;
  memberId: string;
  type: IncidentTypes;
  recordable: boolean;
  projectId?: string | null;
  state: IncidentStates;
  illnessType?: IncidentIllnessTypes | null;
  caseNumber: number;
  occurredAt: string;
  occurrenceTimeSet: boolean;
  employeeStartTime?: string | null;
  employeeJobTitle?: string | null;
  employeeBirthday?: string | null;
  employeeHireDate?: string | null;
  employeeSex?: IncidentEmployeeSexes | null;
  employeeAddress1?: string | null;
  employeeCity?: string | null;
  employeeState?: string | null;
  employeePostalCode?: string | null;
  createdOn: string;
  createdOnLocal: string;
  submittedOn: string;
  updatedOn: string;
  deletedOn?: string | null;
  reviewedOn?: string | null;
  completedOn?: string | null;
  answers: Array<{
    __typename?: 'IncidentAnswer';
    id: string;
    incidentId: string;
    question: string;
    type: IncidentAnswerTypes;
    answer: string;
  }>;
  project?: {
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    children?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
  } | null;
  member: {
    __typename?: 'Member';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
  };
  medicalDetails?: {
    __typename?: 'IncidentMedicalDetails';
    id: string;
    incidentId: string;
    treatmentER?: boolean | null;
    treatmentOvernight?: boolean | null;
    treatmentFirstAid?: boolean | null;
    treatmentDoctor?: boolean | null;
    treatmentNone?: boolean | null;
    treatmentOther?: boolean | null;
    facilityName?: string | null;
    facilityAddress?: string | null;
    facilityCity?: string | null;
    facilityState?: string | null;
    facilityPostalCode?: string | null;
    physicianName?: string | null;
  } | null;
  submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  reviewedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
  outcomeDetails?: {
    __typename?: 'IncidentOutcomeDetails';
    id: string;
    type: IncidentOutcomeDetailsTypes;
    daysAway?: number | null;
    daysTransfer?: number | null;
    outcomeDate?: string | null;
  } | null;
  completedByMember?: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null } | null;
};

export type IncidentQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IncidentFilter>;
}>;

export type IncidentQuery = {
  __typename?: 'Query';
  incidents?: Array<{
    __typename?: 'Incident';
    id: string;
    organizationId: string;
    memberId: string;
    type: IncidentTypes;
    recordable: boolean;
    projectId?: string | null;
    state: IncidentStates;
    illnessType?: IncidentIllnessTypes | null;
    caseNumber: number;
    occurredAt: string;
    occurrenceTimeSet: boolean;
    employeeStartTime?: string | null;
    employeeJobTitle?: string | null;
    employeeBirthday?: string | null;
    employeeHireDate?: string | null;
    employeeSex?: IncidentEmployeeSexes | null;
    employeeAddress1?: string | null;
    employeeCity?: string | null;
    employeeState?: string | null;
    employeePostalCode?: string | null;
    createdOn: string;
    createdOnLocal: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    reviewedOn?: string | null;
    completedOn?: string | null;
    answers: Array<{
      __typename?: 'IncidentAnswer';
      id: string;
      incidentId: string;
      question: string;
      type: IncidentAnswerTypes;
      answer: string;
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
      children?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
    };
    medicalDetails?: {
      __typename?: 'IncidentMedicalDetails';
      id: string;
      incidentId: string;
      treatmentER?: boolean | null;
      treatmentOvernight?: boolean | null;
      treatmentFirstAid?: boolean | null;
      treatmentDoctor?: boolean | null;
      treatmentNone?: boolean | null;
      treatmentOther?: boolean | null;
      facilityName?: string | null;
      facilityAddress?: string | null;
      facilityCity?: string | null;
      facilityState?: string | null;
      facilityPostalCode?: string | null;
      physicianName?: string | null;
    } | null;
    submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    reviewedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    outcomeDetails?: {
      __typename?: 'IncidentOutcomeDetails';
      id: string;
      type: IncidentOutcomeDetailsTypes;
      daysAway?: number | null;
      daysTransfer?: number | null;
      outcomeDate?: string | null;
    } | null;
    completedByMember?: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  }> | null;
};

export type CreateIncidentMutationVariables = Exact<{
  incident?: InputMaybe<IncidentCreateInput>;
}>;

export type CreateIncidentMutation = {
  __typename?: 'Mutation';
  createIncident?: { __typename?: 'Incident'; id: string } | null;
};

export type CreateIncidentAnswerMutationVariables = Exact<{
  incidentAnswer?: InputMaybe<IncidentAnswerCreateInput>;
}>;

export type CreateIncidentAnswerMutation = {
  __typename?: 'Mutation';
  createIncidentAnswer?: { __typename?: 'IncidentAnswer'; id: string } | null;
};

export type CreateIncidentMedicalDetailsMutationVariables = Exact<{
  incidentMedicalDetails?: InputMaybe<IncidentMedicalDetailsCreateInput>;
}>;

export type CreateIncidentMedicalDetailsMutation = {
  __typename?: 'Mutation';
  createIncidentMedicalDetails?: { __typename?: 'IncidentMedicalDetails'; id: string } | null;
};

export type CreateIncidentOutcomeDetailsMutationVariables = Exact<{
  incidentOutcomeDetails?: InputMaybe<IncidentOutcomeDetailsCreateInput>;
}>;

export type CreateIncidentOutcomeDetailsMutation = {
  __typename?: 'Mutation';
  createIncidentOutcomeDetails?: { __typename?: 'IncidentOutcomeDetails'; id: string } | null;
};

export type UpdateIncidentAnswerMutationVariables = Exact<{
  incidentAnswer?: InputMaybe<IncidentAnswerUpdateInput>;
}>;

export type UpdateIncidentAnswerMutation = {
  __typename?: 'Mutation';
  updateIncidentAnswer?: { __typename?: 'IncidentAnswer'; id: string } | null;
};

export type UpdateIncidentMedicalDetailsMutationVariables = Exact<{
  incidentMedicalDetails?: InputMaybe<IncidentMedicalDetailsUpdateInput>;
}>;

export type UpdateIncidentMedicalDetailsMutation = {
  __typename?: 'Mutation';
  updateIncidentMedicalDetails?: { __typename?: 'IncidentMedicalDetails'; id: string } | null;
};

export type UpdateIncidentOutcomeDetailsMutationVariables = Exact<{
  incidentOutcomeDetails?: InputMaybe<IncidentOutcomeDetailsUpdateInput>;
}>;

export type UpdateIncidentOutcomeDetailsMutation = {
  __typename?: 'Mutation';
  updateIncidentOutcomeDetails?: { __typename?: 'IncidentOutcomeDetails'; id: string } | null;
};

export type InspectionCategoriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionCategoryFilter>;
  sort?: InputMaybe<Array<InspectionCategorySort> | InspectionCategorySort>;
}>;

export type InspectionCategoriesQuery = {
  __typename?: 'Query';
  inspectionCategories?: Array<{
    __typename?: 'InspectionCategory';
    id: string;
    checklistId: string;
    name: string;
    createdOn: string;
    updatedOn: string;
    submittedOn: string;
    deletedOn?: string | null;
    cursor: string;
    questions: Array<{
      __typename?: 'InspectionQuestion';
      id: string;
      categoryId: string;
      type: InspectionQuestionType;
      order: number;
      enabled: boolean;
      recommendation?: string | null;
    }>;
  }> | null;
};

export type QueryInspectionQuestionsQueryVariables = Exact<{
  filter?: InputMaybe<InspectionQuestionFilter>;
  sort?: InputMaybe<Array<InspectionQuestionSort> | InspectionQuestionSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type QueryInspectionQuestionsQuery = {
  __typename?: 'Query';
  inspectionQuestions?: Array<{
    __typename?: 'InspectionQuestion';
    id: string;
    categoryId: string;
    question: string;
    type: InspectionQuestionType;
    choices?: string | null;
    order: number;
    enabled: boolean;
    recommendation?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    updatedOn: string;
  }> | null;
};

export type InspectionAssignmentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionAssignmentFilter>;
  sort?: InputMaybe<Array<InspectionAssignmentSort> | InspectionAssignmentSort>;
}>;

export type InspectionAssignmentsQuery = {
  __typename?: 'Query';
  inspectionAssignments?: Array<{
    __typename?: 'InspectionAssignment';
    id: string;
    memberId: string;
    checklistId: string;
    limitCategories: boolean;
    projectId?: string | null;
    submittedBy: string;
    description?: string | null;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    date: string;
    sendNotification: boolean;
    notifySubmission: boolean;
    notifyCircumstance?: InspectionNotifyCircumstanceTypes | null;
    circumstanceValue?: number | null;
    notificationTime?: string | null;
    member: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    checklist: { __typename?: 'InspectionChecklist'; id: string; name: string };
    limitedCategories: Array<{
      __typename?: 'InspectionAssignmentCategoryLink';
      id: string;
      categoryId: string;
      deletedOn?: string | null;
      category: { __typename?: 'InspectionCategory'; id: string; name: string };
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    notificationLinks: Array<{
      __typename?: 'InspectionAssignmentNotificationLink';
      id: string;
      memberId: string;
      deletedOn?: string | null;
    }>;
    inspection: Array<{
      __typename?: 'Inspection';
      id: string;
      completedOn?: string | null;
      deletedOn?: string | null;
    }>;
  }> | null;
};

export type InspectionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InspectionFilter>;
  sort?: InputMaybe<Array<InspectionSort> | InspectionSort>;
}>;

export type InspectionsQuery = {
  __typename?: 'Query';
  inspections?: Array<{
    __typename?: 'Inspection';
    id: string;
    score?: number | null;
    assignmentId?: string | null;
    checklistId: string;
    projectId?: string | null;
    inspectedOn: string;
    completedOn?: string | null;
    submittedBy: string;
    createdOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    checklist: {
      __typename?: 'InspectionChecklist';
      id: string;
      name: string;
      categories: Array<{ __typename?: 'InspectionCategory'; id: string; name: string }>;
    };
    answers: Array<{
      __typename?: 'InspectionQuestionAnswer';
      id: string;
      answer?: string | null;
      questionId: string;
      question: string;
      flagged: boolean;
      severity?: InspectionQuestionSeverityType | null;
      comments?: string | null;
      corrected?: boolean | null;
      inspectionQuestion: {
        __typename?: 'InspectionQuestion';
        id: string;
        order: number;
        categoryId: string;
        category: { __typename?: 'InspectionCategory'; id: string; name: string };
      };
      attachments: Array<{
        __typename?: 'InspectionQuestionAnswerAttachment';
        id: string;
        fileUrl?: string | null;
        fileType?: string | null;
      }>;
    }>;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
    submittedByMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
  }> | null;
};

export type TimeOffScheduleOverlapQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffFilter>;
  sort?: InputMaybe<Array<TimeOffSort> | TimeOffSort>;
}>;

export type TimeOffScheduleOverlapQuery = {
  __typename?: 'Query';
  timeOffs?: Array<{ __typename?: 'TimeOff'; id: string; memberId: string; dateStamp: string; cursor: string }> | null;
};

export type SchedulingScheduledMemberFragment = {
  __typename?: 'Member';
  id: string;
  memberGroupId?: string | null;
  positionId: string;
  archivedOn?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  position: {
    __typename?: 'Position';
    id: string;
    level: number;
    timeEvents: number;
    viewSchedule: number;
    manageSchedule: number;
  };
};

export type SchedulingTimeOffFieldsFragment = {
  __typename?: 'TimeOff';
  id: string;
  dateStamp: string;
  type: number;
  description?: string | null;
  paid: boolean;
  seconds?: number | null;
  memberId: string;
  member: {
    __typename?: 'Member';
    id: string;
    memberGroupId?: string | null;
    positionId: string;
    archivedOn?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position: {
      __typename?: 'Position';
      id: string;
      level: number;
      timeEvents: number;
      viewSchedule: number;
      manageSchedule: number;
    };
  };
};

export type RecurringScheduleLinkFieldsFragment = {
  __typename?: 'RecurringScheduleReminderLink';
  id: string;
  scheduleReminderId: string;
  memberId: string;
  member: {
    __typename?: 'Member';
    id: string;
    memberGroupId?: string | null;
    positionId: string;
    archivedOn?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position: {
      __typename?: 'Position';
      id: string;
      level: number;
      timeEvents: number;
      viewSchedule: number;
      manageSchedule: number;
    };
  };
};

export type SchedulingReminderProjectFieldsFragment = {
  __typename?: 'Project';
  id: string;
  title: string;
  depth: number;
  projectGroupId?: string | null;
  parentProjectId?: string | null;
  rootProjectId?: string | null;
  archivedOn?: string | null;
  ancestors?: Array<{
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    projectGroupId?: string | null;
    archivedOn?: string | null;
  }> | null;
  projectCostCodeLink?: Array<{
    __typename?: 'ProjectCostCodeLink';
    id: string;
    costCodeId: string;
    deletedOn?: string | null;
  }> | null;
};

export type SchedulingReminderCostCodeFieldsFragment = {
  __typename?: 'CostCode';
  id: string;
  archivedOn?: string | null;
  costCodeGroupId?: string | null;
  costCode: string;
  title?: string | null;
};

export type SchedulingReminderEquipmentFieldsFragment = {
  __typename?: 'Equipment';
  archivedOn?: string | null;
  equipmentGroupId?: string | null;
  equipmentName: string;
  id: string;
  model?: {
    __typename?: 'EquipmentModel';
    equipmentCategoryId: string;
    equipmentMakeId: string;
    id: string;
    modelNumber?: string | null;
    title?: string | null;
    year?: number | null;
    category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
    make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
  } | null;
};

export type RecurringScheduleFlatFieldsFragment = {
  __typename?: 'RecurringScheduleReminder';
  id: string;
  startTime: string;
  endTime: string;
  type: RecurringScheduleReminderTypes;
  frequency: ScheduleReminderFrequencyTypes;
  frequencyExpiration?: string | null;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  monthOfYear?: number | null;
  dayOfMonth?: number | null;
  notifyEmployee: boolean;
  isDraft: boolean;
  memberId: string;
  payload?: string | null;
};

export type RecurringScheduleFieldsFragment = {
  __typename?: 'RecurringScheduleReminder';
  id: string;
  projectId?: string | null;
  costCodeId?: string | null;
  equipmentId?: string | null;
  startTime: string;
  endTime: string;
  type: RecurringScheduleReminderTypes;
  frequency: ScheduleReminderFrequencyTypes;
  frequencyExpiration?: string | null;
  monday?: boolean | null;
  tuesday?: boolean | null;
  wednesday?: boolean | null;
  thursday?: boolean | null;
  friday?: boolean | null;
  saturday?: boolean | null;
  sunday?: boolean | null;
  monthOfYear?: number | null;
  dayOfMonth?: number | null;
  notifyEmployee: boolean;
  isDraft: boolean;
  memberId: string;
  payload?: string | null;
  project?: {
    __typename?: 'Project';
    id: string;
    title: string;
    depth: number;
    projectGroupId?: string | null;
    parentProjectId?: string | null;
    rootProjectId?: string | null;
    archivedOn?: string | null;
    ancestors?: Array<{
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      archivedOn?: string | null;
    }> | null;
    projectCostCodeLink?: Array<{
      __typename?: 'ProjectCostCodeLink';
      id: string;
      costCodeId: string;
      deletedOn?: string | null;
    }> | null;
  } | null;
  costCode?: {
    __typename?: 'CostCode';
    id: string;
    archivedOn?: string | null;
    costCodeGroupId?: string | null;
    costCode: string;
    title?: string | null;
  } | null;
  equipment?: {
    __typename?: 'Equipment';
    id: string;
    archivedOn?: string | null;
    equipmentGroupId?: string | null;
    equipmentName: string;
    model?: {
      __typename?: 'EquipmentModel';
      equipmentCategoryId: string;
      equipmentMakeId: string;
      id: string;
      modelNumber?: string | null;
      title?: string | null;
      year?: number | null;
      category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
    } | null;
  } | null;
  links: Array<{
    __typename?: 'RecurringScheduleReminderLink';
    id: string;
    scheduleReminderId: string;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      memberGroupId?: string | null;
      positionId: string;
      archivedOn?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        timeEvents: number;
        viewSchedule: number;
        manageSchedule: number;
      };
    };
  }>;
};

export type CreateScheduleTimeOffMutationVariables = Exact<{
  timeOff?: InputMaybe<TimeOffCreateInput>;
}>;

export type CreateScheduleTimeOffMutation = {
  __typename?: 'Mutation';
  createTimeOff?: {
    __typename?: 'TimeOff';
    id: string;
    dateStamp: string;
    type: number;
    description?: string | null;
    paid: boolean;
    seconds?: number | null;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      memberGroupId?: string | null;
      positionId: string;
      archivedOn?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        timeEvents: number;
        viewSchedule: number;
        manageSchedule: number;
      };
    };
  } | null;
};

export type UpdateScheduleTimeOffMutationVariables = Exact<{
  timeOff?: InputMaybe<TimeOffUpdateInput>;
}>;

export type UpdateScheduleTimeOffMutation = {
  __typename?: 'Mutation';
  updateTimeOff?: {
    __typename?: 'TimeOff';
    id: string;
    dateStamp: string;
    type: number;
    description?: string | null;
    paid: boolean;
    seconds?: number | null;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      memberGroupId?: string | null;
      positionId: string;
      archivedOn?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        timeEvents: number;
        viewSchedule: number;
        manageSchedule: number;
      };
    };
  } | null;
};

export type CreateRecurringScheduleMutationVariables = Exact<{
  recurringScheduleReminder?: InputMaybe<RecurringScheduleReminderCreateInput>;
}>;

export type CreateRecurringScheduleMutation = {
  __typename?: 'Mutation';
  createRecurringScheduleReminder?: {
    __typename?: 'RecurringScheduleReminder';
    id: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime: string;
    type: RecurringScheduleReminderTypes;
    frequency: ScheduleReminderFrequencyTypes;
    frequencyExpiration?: string | null;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    monthOfYear?: number | null;
    dayOfMonth?: number | null;
    notifyEmployee: boolean;
    isDraft: boolean;
    memberId: string;
    payload?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      parentProjectId?: string | null;
      rootProjectId?: string | null;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        projectGroupId?: string | null;
        archivedOn?: string | null;
      }> | null;
      projectCostCodeLink?: Array<{
        __typename?: 'ProjectCostCodeLink';
        id: string;
        costCodeId: string;
        deletedOn?: string | null;
      }> | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      archivedOn?: string | null;
      costCodeGroupId?: string | null;
      costCode: string;
      title?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      archivedOn?: string | null;
      equipmentGroupId?: string | null;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        equipmentCategoryId: string;
        equipmentMakeId: string;
        id: string;
        modelNumber?: string | null;
        title?: string | null;
        year?: number | null;
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      } | null;
    } | null;
    links: Array<{
      __typename?: 'RecurringScheduleReminderLink';
      id: string;
      scheduleReminderId: string;
      memberId: string;
      member: {
        __typename?: 'Member';
        id: string;
        memberGroupId?: string | null;
        positionId: string;
        archivedOn?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        position: {
          __typename?: 'Position';
          id: string;
          level: number;
          timeEvents: number;
          viewSchedule: number;
          manageSchedule: number;
        };
      };
    }>;
  } | null;
};

export type UpdateRecurringScheduleMutationVariables = Exact<{
  recurringScheduleReminder?: InputMaybe<RecurringScheduleReminderUpdateInput>;
}>;

export type UpdateRecurringScheduleMutation = {
  __typename?: 'Mutation';
  updateRecurringScheduleReminder?: {
    __typename?: 'RecurringScheduleReminder';
    id: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime: string;
    type: RecurringScheduleReminderTypes;
    frequency: ScheduleReminderFrequencyTypes;
    frequencyExpiration?: string | null;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    monthOfYear?: number | null;
    dayOfMonth?: number | null;
    notifyEmployee: boolean;
    isDraft: boolean;
    memberId: string;
    payload?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      parentProjectId?: string | null;
      rootProjectId?: string | null;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        projectGroupId?: string | null;
        archivedOn?: string | null;
      }> | null;
      projectCostCodeLink?: Array<{
        __typename?: 'ProjectCostCodeLink';
        id: string;
        costCodeId: string;
        deletedOn?: string | null;
      }> | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      archivedOn?: string | null;
      costCodeGroupId?: string | null;
      costCode: string;
      title?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      archivedOn?: string | null;
      equipmentGroupId?: string | null;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        equipmentCategoryId: string;
        equipmentMakeId: string;
        id: string;
        modelNumber?: string | null;
        title?: string | null;
        year?: number | null;
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      } | null;
    } | null;
    links: Array<{
      __typename?: 'RecurringScheduleReminderLink';
      id: string;
      scheduleReminderId: string;
      memberId: string;
      member: {
        __typename?: 'Member';
        id: string;
        memberGroupId?: string | null;
        positionId: string;
        archivedOn?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        position: {
          __typename?: 'Position';
          id: string;
          level: number;
          timeEvents: number;
          viewSchedule: number;
          manageSchedule: number;
        };
      };
    }>;
  } | null;
};

export type CreateRecurringScheduleLinkMutationVariables = Exact<{
  recurringScheduleReminderLink?: InputMaybe<RecurringScheduleReminderLinkCreateInput>;
}>;

export type CreateRecurringScheduleLinkMutation = {
  __typename?: 'Mutation';
  createRecurringScheduleReminderLink?: {
    __typename?: 'RecurringScheduleReminderLink';
    id: string;
    scheduleReminderId: string;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      memberGroupId?: string | null;
      positionId: string;
      archivedOn?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        timeEvents: number;
        viewSchedule: number;
        manageSchedule: number;
      };
    };
  } | null;
};

export type UpdateRecurringScheduleLinkMutationVariables = Exact<{
  recurringScheduleReminderLink?: InputMaybe<RecurringScheduleReminderLinkUpdateInput>;
}>;

export type UpdateRecurringScheduleLinkMutation = {
  __typename?: 'Mutation';
  updateRecurringScheduleReminderLink?: {
    __typename?: 'RecurringScheduleReminderLink';
    id: string;
    scheduleReminderId: string;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      memberGroupId?: string | null;
      positionId: string;
      archivedOn?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        timeEvents: number;
        viewSchedule: number;
        manageSchedule: number;
      };
    };
  } | null;
};

export type ScheduleTimeOffQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeOffFilter>;
  sort?: InputMaybe<Array<TimeOffSort> | TimeOffSort>;
}>;

export type ScheduleTimeOffQuery = {
  __typename?: 'Query';
  timeOffs?: Array<{
    __typename?: 'TimeOff';
    cursor: string;
    id: string;
    dateStamp: string;
    type: number;
    description?: string | null;
    paid: boolean;
    seconds?: number | null;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      memberGroupId?: string | null;
      positionId: string;
      archivedOn?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      position: {
        __typename?: 'Position';
        id: string;
        level: number;
        timeEvents: number;
        viewSchedule: number;
        manageSchedule: number;
      };
    };
  }> | null;
};

export type SchedulingRecurringScheduleReminderQueryVariables = Exact<{
  filter?: InputMaybe<RecurringScheduleReminderFilter>;
  sort?: InputMaybe<Array<RecurringScheduleReminderSort> | RecurringScheduleReminderSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SchedulingRecurringScheduleReminderQuery = {
  __typename?: 'Query';
  recurringScheduleReminders?: Array<{
    __typename?: 'RecurringScheduleReminder';
    id: string;
    cursor: string;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    startTime: string;
    endTime: string;
    type: RecurringScheduleReminderTypes;
    frequency: ScheduleReminderFrequencyTypes;
    frequencyExpiration?: string | null;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    monthOfYear?: number | null;
    dayOfMonth?: number | null;
    notifyEmployee: boolean;
    isDraft: boolean;
    memberId: string;
    payload?: string | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      projectGroupId?: string | null;
      parentProjectId?: string | null;
      rootProjectId?: string | null;
      archivedOn?: string | null;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        projectGroupId?: string | null;
        archivedOn?: string | null;
      }> | null;
      projectCostCodeLink?: Array<{
        __typename?: 'ProjectCostCodeLink';
        id: string;
        costCodeId: string;
        deletedOn?: string | null;
      }> | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      archivedOn?: string | null;
      costCodeGroupId?: string | null;
      costCode: string;
      title?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      archivedOn?: string | null;
      equipmentGroupId?: string | null;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        equipmentCategoryId: string;
        equipmentMakeId: string;
        id: string;
        modelNumber?: string | null;
        title?: string | null;
        year?: number | null;
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
      } | null;
    } | null;
    links: Array<{
      __typename?: 'RecurringScheduleReminderLink';
      id: string;
      scheduleReminderId: string;
      memberId: string;
      member: {
        __typename?: 'Member';
        id: string;
        memberGroupId?: string | null;
        positionId: string;
        archivedOn?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        position: {
          __typename?: 'Position';
          id: string;
          level: number;
          timeEvents: number;
          viewSchedule: number;
          manageSchedule: number;
        };
      };
    }>;
  }> | null;
};

export type SchedulingReminderIdAndLinkIdQueryVariables = Exact<{
  filter?: InputMaybe<ScheduleReminderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SchedulingReminderIdAndLinkIdQuery = {
  __typename?: 'Query';
  scheduleReminders?: Array<{
    __typename?: 'ScheduleReminder';
    id: string;
    cursor: string;
    links: Array<{ __typename?: 'ScheduleReminderLink'; id: string }>;
  }> | null;
};

export type SchedulingReminderOneOffQueryVariables = Exact<{
  filter?: InputMaybe<ScheduleReminderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SchedulingReminderOneOffQuery = {
  __typename?: 'Query';
  scheduleReminders?: Array<{
    __typename?: 'ScheduleReminder';
    id: string;
    type: string;
    startTime: string;
    cursor: string;
    links: Array<{ __typename?: 'ScheduleReminderLink'; id: string }>;
  }> | null;
};

export type SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables = Exact<{
  filter?: InputMaybe<RecurringScheduleReminderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SchedulingRecurringScheduleReminderIdAndLinkIdQuery = {
  __typename?: 'Query';
  recurringScheduleReminders?: Array<{
    __typename?: 'RecurringScheduleReminder';
    id: string;
    cursor: string;
    links: Array<{ __typename?: 'RecurringScheduleReminderLink'; id: string }>;
  }> | null;
};

export type SchedulingRecurringScheduleBasicReminderQueryVariables = Exact<{
  filter?: InputMaybe<RecurringScheduleReminderFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type SchedulingRecurringScheduleBasicReminderQuery = {
  __typename?: 'Query';
  recurringScheduleReminders?: Array<{
    __typename?: 'RecurringScheduleReminder';
    id: string;
    cursor: string;
    startTime: string;
    endTime: string;
    type: RecurringScheduleReminderTypes;
    frequency: ScheduleReminderFrequencyTypes;
    frequencyExpiration?: string | null;
    monday?: boolean | null;
    tuesday?: boolean | null;
    wednesday?: boolean | null;
    thursday?: boolean | null;
    friday?: boolean | null;
    saturday?: boolean | null;
    sunday?: boolean | null;
    monthOfYear?: number | null;
    dayOfMonth?: number | null;
    notifyEmployee: boolean;
    isDraft: boolean;
    memberId: string;
    payload?: string | null;
    links: Array<{ __typename?: 'RecurringScheduleReminderLink'; id: string }>;
  }> | null;
};

export type MembersGridViewQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
}>;

export type MembersGridViewQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberGroupId?: string | null;
    positionId: string;
    archivedOn?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    position: {
      __typename?: 'Position';
      id: string;
      level: number;
      timeEvents: number;
      viewSchedule: number;
      manageSchedule: number;
    };
  }> | null;
};

export type WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TimeEntryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<TimeEntrySort> | TimeEntrySort>;
}>;

export type WeeklyAttendanceReportMemberBreakdownTimeEntryQuery = {
  __typename?: 'Query';
  timeEntries?: Array<{
    __typename?: 'TimeEntry';
    id: string;
    memberId: string;
    startTime: string;
    endTime?: string | null;
    daylightSavingTime: boolean;
    metaDaylightSavingTime?: boolean | null;
    actionType: number;
    description?: string | null;
    createdOn: string;
    deletedOn?: string | null;
    submittedOn: string;
    updatedOn: string;
    hasLocation: boolean;
    cursor: string;
    flaggedTimeEntryLocation?: {
      __typename?: 'FlaggedTimeEntryLocation';
      id: string;
      status?: FlaggedTimeEntryLocationStatus | null;
      deletedOn?: string | null;
    } | null;
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      depth: number;
      ancestors?: Array<{ __typename?: 'Project'; id: string; title: string; depth: number }> | null;
    } | null;
  }> | null;
};

export type OwnerMemberQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type OwnerMemberQuery = {
  __typename?: 'Query';
  members?: Array<{ __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null }> | null;
};

export type ActiveMembersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type ActiveMembersQuery = {
  __typename?: 'Query';
  members?: Array<{ __typename?: 'Member'; id: string; cursor: string }> | null;
};

export type MembersAdminsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
}>;

export type MembersAdminsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    firstName?: string | null;
    lastName?: string | null;
    verifiedEmail?: boolean | null;
    certifiedEmail?: string | null;
  }> | null;
};

export type LogFeedTimeEntryLogsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ClientTimeEntryLogFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;

export type LogFeedTimeEntryLogsQuery = {
  __typename?: 'Query';
  clientTimeEntryLogs?: Array<{
    __typename?: 'ClientTimeEntryLog';
    id: string;
    cursor: string;
    createdOn: string;
    actionType: number;
    deviceType?: string | null;
    startLocationId?: string | null;
    endLocationId?: string | null;
    startTime: string;
    daylightSavingTime: boolean;
    endTime?: string | null;
    metaDaylightSavingTime?: boolean | null;
    projectId?: string | null;
    costCodeId?: string | null;
    equipmentId?: string | null;
    description?: string | null;
    originalTimeEntryId?: string | null;
    timeEntryId: string;
    memberId: string;
    member: {
      __typename?: 'Member';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      memberGroupId?: string | null;
      position: { __typename?: 'Position'; id: string; level: number };
    };
    updaterMember: { __typename?: 'Member'; id: string; firstName?: string | null; lastName?: string | null };
    project?: {
      __typename?: 'Project';
      id: string;
      title: string;
      projectGroupId?: string | null;
      depth: number;
      ancestors?: Array<{
        __typename?: 'Project';
        id: string;
        title: string;
        depth: number;
        archivedOn?: string | null;
      }> | null;
    } | null;
    costCode?: {
      __typename?: 'CostCode';
      id: string;
      costCode: string;
      costCodeGroupId?: string | null;
      title?: string | null;
    } | null;
    equipment?: {
      __typename?: 'Equipment';
      id: string;
      equipmentName: string;
      model?: {
        __typename?: 'EquipmentModel';
        id: string;
        title?: string | null;
        equipmentCategoryId: string;
        make: { __typename?: 'EquipmentMake'; id: string; title?: string | null };
        category: { __typename?: 'EquipmentCategory'; id: string; title?: string | null };
      } | null;
    } | null;
    timeEntry: {
      __typename?: 'TimeEntry';
      startTime: string;
      startDeviceTime?: string | null;
      endTime?: string | null;
      endDeviceTime?: string | null;
      daylightSavingTime: boolean;
      metaDaylightSavingTime?: boolean | null;
      actionType: number;
      createdOn: string;
      deletedOn?: string | null;
      submittedOn: string;
      updatedOn: string;
      startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      breaks: Array<{
        __typename?: 'TimeEntryBreak';
        id: string;
        startTime: string;
        startDeviceTime: string;
        startTimeTrusted: number;
        startTimeDst: boolean;
        endTime?: string | null;
        endDeviceTime?: string | null;
        endTimeTrusted?: number | null;
        endTimeDst?: boolean | null;
        createdOn: string;
        deletedOn?: string | null;
        submittedOn: string;
        updatedOn?: string | null;
        clientLogs: Array<{
          __typename?: 'ClientTimeEntryBreakLog';
          id: string;
          timeEntryBreakId: string;
          startTime: string;
          endTime?: string | null;
          actionType: number;
          deviceType?: string | null;
          createdOn: string;
          startLocationId?: string | null;
          endLocationId?: string | null;
          clientTimeEntryLogId: string;
        }>;
        startLocation?: { __typename?: 'MemberLocation'; id: string } | null;
        endLocation?: { __typename?: 'MemberLocation'; id: string } | null;
      }>;
    };
  }> | null;
};

export type UnjoinedMemberLocationsQueryVariables = Exact<{
  filter?: InputMaybe<MemberLocationFilter>;
  sort?: InputMaybe<Array<MemberLocationSort> | MemberLocationSort>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type UnjoinedMemberLocationsQuery = {
  __typename?: 'Query';
  memberLocations?: Array<{
    __typename?: 'MemberLocation';
    id: string;
    memberId: string;
    longitude: number;
    latitude: number;
    horizontalAccuracy?: number | null;
    altitude?: number | null;
    verticalAccuracy?: number | null;
    timeGmt: string;
    timeLocal: string;
    timeDst: boolean;
    createdOn: string;
    submittedOn: string;
    updatedOn: string;
    deletedOn?: string | null;
    cursor: string;
  }> | null;
};

export type QueryCostCodeTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  sort?: InputMaybe<Array<CostCodeSort> | CostCodeSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryCostCodeTimeMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    cursor: string;
    costCodeLaborMetrics: Array<{
      __typename?: 'CostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryCostCodeLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CostCodeFilter>;
  sort?: InputMaybe<Array<CostCodeSort> | CostCodeSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryCostCodeLaborMetricsQuery = {
  __typename?: 'Query';
  costCodes?: Array<{
    __typename?: 'CostCode';
    id: string;
    cursor: string;
    costCodeLaborMetrics: Array<{
      __typename?: 'CostCodeLaborMetrics';
      costCodeId?: string | null;
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryEquipmentTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryEquipmentTimeMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    cursor: string;
    equipmentLaborMetrics: Array<{
      __typename?: 'EquipmentLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryEquipmentLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EquipmentFilter>;
  sort?: InputMaybe<Array<EquipmentSort> | EquipmentSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryEquipmentLaborMetricsQuery = {
  __typename?: 'Query';
  equipment?: Array<{
    __typename?: 'Equipment';
    id: string;
    cursor: string;
    equipmentLaborMetrics: Array<{
      __typename?: 'EquipmentLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersTimeMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      pto?: number | null;
      rt?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type QueryMembersLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersLaborMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberLaborMetrics: Array<{
      __typename: 'MemberLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      pto?: number | null;
      ptoCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
      upto?: number | null;
    }>;
  }> | null;
};

export type QueryMembersProjectTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersProjectTimeMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberProjectLaborMetrics: Array<{
      __typename: 'MemberProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersProjectMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  projectIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersProjectMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberProjectLaborMetrics: Array<{
      __typename: 'MemberProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersCostCodeTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersCostCodeTimeMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberCostCodeLaborMetrics: Array<{
      __typename: 'MemberCostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersCostCodeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  costCodeIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersCostCodeMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberCostCodeLaborMetrics: Array<{
      __typename: 'MemberCostCodeLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersEquipmentTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersEquipmentTimeMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberEquipmentLaborMetrics: Array<{
      __typename: 'MemberEquipmentLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryMembersEquipmentMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberFilter>;
  sort?: InputMaybe<Array<MemberSort> | MemberSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  equipmentIds?: InputMaybe<Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input']>;
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryMembersEquipmentMetricsQuery = {
  __typename?: 'Query';
  members?: Array<{
    __typename?: 'Member';
    id: string;
    cursor: string;
    memberEquipmentLaborMetrics: Array<{
      __typename: 'MemberEquipmentLaborMetrics';
      breakDt?: number | null;
      breakOt?: number | null;
      breakRt?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryOrganizationTimeMetricsQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryOrganizationTimeMetricsQuery = {
  __typename?: 'Query';
  organizationLaborMetrics: Array<{
    __typename?: 'OrganizationLaborMetrics';
    dt?: number | null;
    end: string;
    ot?: number | null;
    pto?: number | null;
    rt?: number | null;
    start: string;
    upto?: number | null;
  }>;
};

export type QueryOrganizationLaborMetricsQueryVariables = Exact<{
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  metricFilter?: InputMaybe<LaborMetricFilter>;
}>;

export type QueryOrganizationLaborMetricsQuery = {
  __typename?: 'Query';
  organizationLaborMetrics: Array<{
    __typename?: 'OrganizationLaborMetrics';
    dt?: number | null;
    dtCents?: number | null;
    end: string;
    ot?: number | null;
    otCents?: number | null;
    overburdenRtCents?: number | null;
    overburdenOtCents?: number | null;
    overburdenDtCents?: number | null;
    pto?: number | null;
    ptoCents?: number | null;
    rt?: number | null;
    rtCents?: number | null;
    start: string;
    upto?: number | null;
  }>;
};

export type QueryProjectTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryProjectTimeMetricsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryProjectLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
}>;

export type QueryProjectLaborMetricsQuery = {
  __typename?: 'Query';
  projects?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename?: 'ProjectLaborMetrics';
      projectId?: string | null;
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryProjectWithTimeAndTimeMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
}>;

export type QueryProjectWithTimeAndTimeMetricsQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename: 'ProjectLaborMetrics';
      descDt?: number | null;
      descOt?: number | null;
      descRt?: number | null;
      dt?: number | null;
      end: string;
      ot?: number | null;
      rt?: number | null;
      start: string;
    }>;
  }> | null;
};

export type QueryProjectWithTimeAndLaborMetricsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectFilter>;
  sort?: InputMaybe<Array<ProjectSort> | ProjectSort>;
  metricsInterval: LaborMetricsInterval;
  metricsStartDate: Scalars['Date']['input'];
  metricsEndDate: Scalars['Date']['input'];
  startTime: Scalars['Timestamp']['input'];
  endTime: Scalars['Timestamp']['input'];
  includeAncestors: Scalars['Boolean']['input'];
  includeOpenEntry: Scalars['Boolean']['input'];
}>;

export type QueryProjectWithTimeAndLaborMetricsQuery = {
  __typename?: 'Query';
  projectsWithTime?: Array<{
    __typename?: 'Project';
    id: string;
    cursor: string;
    projectLaborMetrics: Array<{
      __typename?: 'ProjectLaborMetrics';
      descDt?: number | null;
      descDtCents?: number | null;
      descOt?: number | null;
      descOtCents?: number | null;
      descOverburdenRtCents?: number | null;
      descOverburdenOtCents?: number | null;
      descOverburdenDtCents?: number | null;
      descRt?: number | null;
      descRtCents?: number | null;
      dt?: number | null;
      dtCents?: number | null;
      end: string;
      ot?: number | null;
      otCents?: number | null;
      overburdenRtCents?: number | null;
      overburdenOtCents?: number | null;
      overburdenDtCents?: number | null;
      rt?: number | null;
      rtCents?: number | null;
      start: string;
    }>;
  }> | null;
};

export type CreateEquipmentCostHistoryMutationVariables = Exact<{
  equipmentCostHistory: EquipmentCostHistoryCreateInput;
}>;

export type CreateEquipmentCostHistoryMutation = {
  __typename?: 'Mutation';
  createEquipmentCostHistory?: {
    __typename?: 'EquipmentCostHistory';
    id: string;
    machineCostRate?: number | null;
    operatorCostRate?: number | null;
    changeDate: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type UpdateEquipmentCostHistoryMutationVariables = Exact<{
  equipmentCostHistory: EquipmentCostHistoryUpdateInput;
}>;

export type UpdateEquipmentCostHistoryMutation = {
  __typename?: 'Mutation';
  updateEquipmentCostHistory?: {
    __typename?: 'EquipmentCostHistory';
    id: string;
    machineCostRate?: number | null;
    operatorCostRate?: number | null;
    changeDate: string;
    updatedOn: string;
    deletedOn?: string | null;
  } | null;
};

export type MemberClockStateQueryVariables = Exact<{
  filter?: InputMaybe<MemberClockStateFilter>;
}>;

export type MemberClockStateQuery = {
  __typename?: 'Query';
  memberClockState: {
    __typename?: 'MemberClockState';
    all: number;
    clockedIn: number;
    clockedOut: number;
    onBreak: number;
  };
};

export type QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MemberTimeDocumentFilter>;
  sort?: InputMaybe<Array<MemberTimeDocumentSort> | MemberTimeDocumentSort>;
}>;

export type QueryMemberTimeDocumentsBasicSignatureDetailsQuery = {
  __typename?: 'Query';
  memberTimeDocuments?: Array<{
    __typename?: 'MemberTimeDocument';
    id: string;
    edited?: string | null;
    canceled?: string | null;
    cursor: string;
    authoritativeSignatureId?: string | null;
    selfSignatureId?: string | null;
  }> | null;
};

export type CreateOrganizationSignInQuestionMutationVariables = Exact<{
  organizationSignInQuestion: OrganizationSignInQuestionCreateInput;
}>;

export type CreateOrganizationSignInQuestionMutation = {
  __typename?: 'Mutation';
  createOrganizationSignInQuestion?: { __typename?: 'OrganizationSignInQuestion'; id: string } | null;
};

export type GetAllOrganizationSignInQuestionsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationSignInQuestionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationSignInQuestionSort> | OrganizationSignInQuestionSort>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAllOrganizationSignInQuestionsQuery = {
  __typename?: 'Query';
  organizationSignInQuestions?: Array<{
    __typename?: 'OrganizationSignInQuestion';
    id: string;
    audienceType: OrganizationSignInQuestionAudience;
    choices?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    organizationId: string;
    positionId?: string | null;
    questionDescription?: string | null;
    questionPrompt?: string | null;
    questionTitle: string;
    required: boolean;
    submittedOn: string;
    type: OrganizationSignInQuestionType;
    updatedOn: string;
    order?: number | null;
  }> | null;
};

export type UpdateOrganizationSignInQuestionMutationVariables = Exact<{
  organizationSignInQuestion: OrganizationSignInQuestionUpdateInput;
}>;

export type UpdateOrganizationSignInQuestionMutation = {
  __typename?: 'Mutation';
  updateOrganizationSignInQuestion?: { __typename?: 'OrganizationSignInQuestion'; id: string } | null;
};

export type GetAllOrganizationSignOffQuestionsQueryVariables = Exact<{
  filter?: InputMaybe<OrganizationSignOffQuestionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<OrganizationSignOffQuestionSort> | OrganizationSignOffQuestionSort>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAllOrganizationSignOffQuestionsQuery = {
  __typename?: 'Query';
  organizationSignOffQuestions?: Array<{
    __typename?: 'OrganizationSignOffQuestion';
    id: string;
    audienceType: OrganizationSignOffQuestionAudience;
    choices?: string | null;
    createdOn: string;
    cursor: string;
    deletedOn?: string | null;
    organizationId: string;
    positionId?: string | null;
    questionDescription?: string | null;
    questionPrompt?: string | null;
    questionTitle: string;
    submittedOn: string;
    type: OrganizationSignOffQuestionType;
    updatedOn: string;
    order?: number | null;
  }> | null;
};

export type UpdateOrganizationSignOffQuestionMutationVariables = Exact<{
  organizationSignOffQuestion: OrganizationSignOffQuestionUpdateInput;
}>;

export type UpdateOrganizationSignOffQuestionMutation = {
  __typename?: 'Mutation';
  updateOrganizationSignOffQuestion?: {
    __typename?: 'OrganizationSignOffQuestion';
    id: string;
    questionTitle: string;
    questionDescription?: string | null;
    audienceType: OrganizationSignOffQuestionAudience;
    organizationId: string;
    createdOn: string;
    type: OrganizationSignOffQuestionType;
    choices?: string | null;
    order?: number | null;
    positionId?: string | null;
  } | null;
};

export type CreateOrganizationSignOffQuestionMutationVariables = Exact<{
  organizationSignOffQuestion: OrganizationSignOffQuestionCreateInput;
}>;

export type CreateOrganizationSignOffQuestionMutation = {
  __typename?: 'Mutation';
  createOrganizationSignOffQuestion?: {
    __typename?: 'OrganizationSignOffQuestion';
    id: string;
    questionTitle: string;
    questionDescription?: string | null;
    audienceType: OrganizationSignOffQuestionAudience;
    organizationId: string;
    createdOn: string;
    type: OrganizationSignOffQuestionType;
    choices?: string | null;
    order?: number | null;
    positionId?: string | null;
  } | null;
};

export const EquipmentDisplayFragmentDoc = gql`
  fragment EquipmentDisplay on Equipment {
    id
    equipmentName
    model {
      id
      category {
        id
        title
      }
      make {
        id
        title
      }
      title
      year
    }
  }
`;
export const InspectionChecklistMemberLinkFragmentFragmentDoc = gql`
  fragment InspectionChecklistMemberLinkFragment on InspectionChecklistMemberLink {
    id
    memberId
    deletedOn
  }
`;
export const InspectionChecklistFragmentFragmentDoc = gql`
  fragment InspectionChecklistFragment on InspectionChecklist {
    id
    createdOn
    deletedOn
    name
    limitInspectors
    categories {
      id
      name
      createdOn
      deletedOn
      checklistId
      questions {
        id
        question
        order
        enabled
        deletedOn
      }
    }
    memberLinks {
      ...InspectionChecklistMemberLinkFragment
    }
  }
  ${InspectionChecklistMemberLinkFragmentFragmentDoc}
`;
export const ProjectDisplayFragmentDoc = gql`
  fragment ProjectDisplay on Project {
    id
    title
    depth
    ancestors {
      id
      title
      depth
    }
  }
`;
export const SimpleProjectFragmentDoc = gql`
  fragment SimpleProject on Project {
    id
    title
    depth
    parentProjectId
    rootProjectId
    cursor
    archivedOn
  }
`;
export const RecurringInspectionAssignmentCategoryLinkFragmentFragmentDoc = gql`
  fragment RecurringInspectionAssignmentCategoryLinkFragment on RecurringInspectionAssignmentCategoryLink {
    id
    createdOn
    updatedOn
    deletedOn
    assignmentId
    categoryId
    category {
      id
      checklistId
      name
      createdOn
      updatedOn
      deletedOn
    }
  }
`;
export const RecurringInspectionAssignmentFragmentFragmentDoc = gql`
  fragment RecurringInspectionAssignmentFragment on RecurringInspectionAssignment {
    id
    pausedOn
    resumedOn
    startDate
    endDate
    frequency
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    dayOfMonth
    monthOfYear
    member {
      id
      firstName
      lastName
    }
    project {
      id
      ancestors {
        depth
        title
      }
      title
      depth
    }
    checklist {
      id
      name
      categories {
        id
        name
        deletedOn
        questions {
          id
          question
          enabled
          deletedOn
          order
        }
      }
    }
    sendNotification
    description
    notifySubmission
    notificationTime
    notifyCircumstance
    circumstanceValue
    limitedCategories {
      ...RecurringInspectionAssignmentCategoryLinkFragment
    }
  }
  ${RecurringInspectionAssignmentCategoryLinkFragmentFragmentDoc}
`;
export const RecurringInspectionAssignmentNotificationLinkFragmentFragmentDoc = gql`
  fragment RecurringInspectionAssignmentNotificationLinkFragment on RecurringInspectionAssignmentNotificationLink {
    id
    createdOn
    updatedOn
    deletedOn
    assignmentId
    memberId
  }
`;
export const BasicMemberLaborMetricsFragmentDoc = gql`
  fragment BasicMemberLaborMetrics on MemberLaborMetrics {
    breakDt
    breakOt
    breakRt
    dt
    end
    ot
    pto
    rt
    start
    upto
  }
`;
export const MemberLaborMetricsFragmentDoc = gql`
  fragment MemberLaborMetrics on MemberLaborMetrics {
    breakDt
    breakOt
    breakRt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;
export const AllMemberLaborMetricsFragmentDoc = gql`
  fragment AllMemberLaborMetrics on MemberLaborMetrics {
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    breakUpt
    dt
    dtCents
    end
    memberId
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;
export const BasicOrganizationLaborMetricsFragmentDoc = gql`
  fragment BasicOrganizationLaborMetrics on OrganizationLaborMetrics {
    dt
    end
    ot
    pto
    rt
    start
    upto
  }
`;
export const OrganizationLaborMetricsFragmentDoc = gql`
  fragment OrganizationLaborMetrics on OrganizationLaborMetrics {
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;
export const AllOrganizationLaborMetricsFragmentDoc = gql`
  fragment AllOrganizationLaborMetrics on OrganizationLaborMetrics {
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    breakUpt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;
export const BasicProjectLaborMetricsFragmentDoc = gql`
  fragment BasicProjectLaborMetrics on ProjectLaborMetricsInterface {
    __typename
    descDt
    descOt
    descRt
    dt
    end
    ot
    rt
    start
  }
`;
export const ProjectLaborMetricsFragmentDoc = gql`
  fragment ProjectLaborMetrics on ProjectLaborMetricsInterface {
    descDt
    descDtCents
    descOt
    descOtCents
    descOverburdenRtCents
    descOverburdenOtCents
    descOverburdenDtCents
    descRt
    descRtCents
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;
export const AllProjectLaborMetricsFragmentDoc = gql`
  fragment AllProjectLaborMetrics on ProjectLaborMetricsInterface {
    descBreakDt
    descBreakDtCents
    descBreakOt
    descBreakOtCents
    descBreakOverburdenCents
    descBreakRt
    descBreakUpt
    descDt
    descDtCents
    descOt
    descOtCents
    descOverburdenRtCents
    descOverburdenOtCents
    descOverburdenDtCents
    descRt
    descRtCents
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    breakUpt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;
export const BasicLaborMetricsFragmentDoc = gql`
  fragment BasicLaborMetrics on LaborMetricsInterface {
    breakDt
    breakOt
    breakRt
    dt
    end
    ot
    rt
    start
  }
`;
export const LaborMetricsFragmentDoc = gql`
  fragment LaborMetrics on LaborMetricsInterface {
    breakDt
    breakOt
    breakRt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;
export const AllLaborMetricsFragmentDoc = gql`
  fragment AllLaborMetrics on LaborMetricsInterface {
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;
export const BasicEquipmentLaborMetricsFragmentDoc = gql`
  fragment BasicEquipmentLaborMetrics on EquipmentLaborMetricsInterface {
    breakDt
    breakOt
    breakRt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
    equipmentCents
  }
`;
export const MemberDetailsFragmentDoc = gql`
  fragment MemberDetails on Member {
    id
    firstName
    lastName
    username
    email
    phone
    memberNumber
    archivedOn
    deletedOn
    imageUrl
    positionId
    memberGroupId
    pinCode
    timeLocationRequired
    distantLocationFlaggingDisabled
  }
`;
export const MemberTimeDocumentFragmentFragmentDoc = gql`
  fragment MemberTimeDocumentFragment on MemberTimeDocument {
    id
    startTime
    endTime
    edited
    canceled
    regularSeconds
    overtimeSeconds
    doubletimeSeconds
    createdOn
    deletedOn
    submittedOn
    updatedOn
    cursor
    member {
      id
      firstName
      lastName
    }
    authoritativeSignature {
      id
      signatureUrl
      createdOnLocal
    }
    selfSignature {
      id
      signatureUrl
      createdOnLocal
    }
  }
`;
export const PositionDetailsFragmentDoc = gql`
  fragment PositionDetails on Position {
    canEditPosition
    canManageProjectCostCodeQuantity
    canRequestTimeOff
    clockInRestriction
    clockInRestrictionStartTime
    createdOn
    cursor
    dailyProjectPrompts
    dailyProjectPromptsUseSubprojects
    deletedOn
    equipmentSelectionRequirement
    id
    jobCostingPrompt
    level
    manageBudgets
    manageBulletinPosts
    manageCompanySettings
    manageCostCodes
    manageEmployeeGroups
    manageEmployees
    manageEquipment
    manageGpsApproval
    manageMediaCollection
    managePerDiem
    manageProjectGroups
    manageProjects
    manageSchedule
    manageTimeEntries
    manageTimeOff
    memberLock
    organizationId
    photoVerificationSettings
    requestSignatures
    scopeByGroup
    submittedOn
    timeEvents
    title
    updatedOn
    viewAllActivityReport
    viewBudgets
    viewEquipmentDetails
    viewEquipmentStatus
    viewMediaCollection
    viewMemberActivityReport
    viewMemberExpenses
    viewProjectExpenses
    viewSchedule
    createIncident
    manageIncident
    performInspections
    manageInspections
  }
`;
export const BasicSafetySignatureFragmentFragmentDoc = gql`
  fragment BasicSafetySignatureFragment on SafetySignature {
    id
    memberId
    proxyMemberId
    startTime
    startTimeDst
    endTime
    endTimeDst
    createdOnDst
    deviceType
    breakPolicyFollowed
    breakPolicyFollowedReason
    injured
    injuryDescription
    timeAccurate
    timeAccurateReason
    breakPolicyFollowedResolvedOn
    breakPolicyFollowedResolvedBy
    breakPolicyFollowedResolvedComment
    injuredResolvedOn
    injuredResolvedBy
    injuredResolvedComment
    timeAccurateResolvedOn
    timeAccurateResolvedBy
    timeAccurateResolvedComment
    customQuestionsFlagged
    customQuestionsResolvedOn
    signatureUrl
    createdOnLocal
    createdOn
    deletedOn
    submittedOn
    updatedOn
    cursor
  }
`;
export const SafetySignatureFragmentFragmentDoc = gql`
  fragment SafetySignatureFragment on SafetySignature {
    id
    memberId
    proxyMemberId
    startTime
    startTimeDst
    endTime
    endTimeDst
    createdOnDst
    deviceType
    breakPolicyFollowed
    breakPolicyFollowedReason
    injured
    injuryDescription
    timeAccurate
    timeAccurateReason
    breakPolicyFollowedResolvedOn
    breakPolicyFollowedResolvedBy
    breakPolicyFollowedResolvedComment
    injuredResolvedOn
    injuredResolvedBy
    injuredResolvedComment
    timeAccurateResolvedOn
    timeAccurateResolvedBy
    timeAccurateResolvedComment
    signatureUrl
    createdOnLocal
    createdOn
    deletedOn
    submittedOn
    updatedOn
    cursor
    member {
      id
      firstName
      lastName
      position {
        id
        title
      }
    }
    proxyMember {
      id
      firstName
      lastName
    }
    customQuestionsFlagged
    customQuestionsResolvedOn
    customAnswers {
      id
      questionTitle
      flagged
      answer
      resolvedOn
      createdOn
      audienceType
      positionId
      resolverId
      questionPrompt
      description
    }
  }
`;
export const TimeEntryBreakDetailsFragmentDoc = gql`
  fragment TimeEntryBreakDetails on TimeEntryBreak {
    actionType
    createdOn
    cursor
    deletedOn
    endDeviceTime
    endLocationId
    endTime
    endTimeDst
    endTimeTrusted
    id
    paidBreak
    startDeviceTime
    startLocationId
    startTime
    startTimeDst
    startTimeTrusted
    submittedOn
    timeEntryId
    updatedOn
  }
`;
export const TimeEntryDetailsFragmentDoc = gql`
  fragment TimeEntryDetails on TimeEntry {
    id
    memberId
    projectId
    costCodeId
    equipmentId
    project {
      id
      title
      depth
      rootProjectId
      parentProjectId
      ancestors {
        id
        title
        depth
        archivedOn
        projectInfo {
          id
          customer
          number
          latitude
          longitude
          locationRadius
        }
      }
      projectInfo {
        id
        customer
        number
        latitude
        longitude
        locationRadius
      }
    }
    costCode {
      id
      costCode
      title
      unitTitle
    }
    equipment {
      id
      equipmentName
      model {
        id
        title
        make {
          id
          title
        }
        category {
          id
          title
        }
      }
    }
    startTime
    endTime
    startDeviceTime
    daylightSavingTime
    metaDaylightSavingTime
    actionType
    description
    createdOn
    deletedOn
    submittedOn
    updatedOn
    breaks {
      id
      startTime
      startDeviceTime
      startTimeTrusted
      startTimeDst
      endTime
      endTimeDst
      endDeviceTime
      endTimeTrusted
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
    hasLocation
    startLocation {
      id
    }
    endLocation {
      id
    }
    clientLogs {
      id
    }
  }
`;
export const SubprojectDetailsFragmentDoc = gql`
  fragment SubprojectDetails on TimeEntry {
    project {
      id
      title
      depth
      projectInfo {
        id
        latitude
        longitude
        locationRadius
      }
      ancestors {
        id
        title
        depth
        archivedOn
        projectInfo {
          id
          latitude
          longitude
          locationRadius
        }
        children {
          id
          title
          archivedOn
          depth
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
          children {
            id
            title
            archivedOn
            depth
            projectInfo {
              id
              latitude
              longitude
              locationRadius
            }
            children {
              id
              title
              archivedOn
              depth
              projectInfo {
                id
                latitude
                longitude
                locationRadius
              }
              children {
                id
                title
                archivedOn
                depth
                projectInfo {
                  id
                  latitude
                  longitude
                  locationRadius
                }
                children {
                  id
                  title
                  archivedOn
                  depth
                  projectInfo {
                    id
                    latitude
                    longitude
                    locationRadius
                  }
                  children {
                    id
                    title
                    archivedOn
                    depth
                    projectInfo {
                      id
                      latitude
                      longitude
                      locationRadius
                    }
                  }
                }
              }
            }
          }
        }
      }
      children {
        id
        title
        archivedOn
        projectInfo {
          id
          latitude
          longitude
          locationRadius
        }
      }
    }
  }
`;
export const AuthOrganizationFragmentDoc = gql`
  fragment AuthOrganization on Organization {
    authoritativeSignature
    betaTester
    breakPolicy
    breakPolicyCalifornia
    breakPolicyMessage
    bulletin
    busySignInQuestion
    createdOn
    customSignOff
    defaultBreakDuration
    defaultEndTime
    defaultStartTime
    deletedOn
    demo
    disclaimer
    gustoCompanyId
    hideUnassignedSubprojects
    id
    imageId
    inRecoveryMode
    lockOnSelfSignature
    minimumAppVersion
    organizationSettings
    organizationInfo {
      id
      organizationId
      longitude
      latitude
      address1
      address2
      city
      county
      state
      postalCode
      country
      phone
      trade
      currencyCode
      employeeMin
      employeeMax
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    organizationPayPeriod {
      id
      organizationId
      payPeriodType
      startDate
      dayOne
      dayTwo
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    organizationOvertimePeriods {
      id
      organizationId
      startTime
      lengthInDays
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    organizationName
    overtimeFlag
    ownedBy
    performanceScores
    requireCostCode
    requireProject
    revokedOn
    safetySignature
    safetySignatureMessage
    signatureDate
    stripeAccountCreatedOn
    stripeAccountStatus
    stripeAccountType
    stripeCustomerId
    submittedOn
    subscriptionStatus {
      annualSubscriptionCount
      annualSubscriptionDate
      annualSubscriptionInterval
      annualSubscriptionPendingInvoiceId
      cardLastFour
      cardType
      couponCode
      createdOn
      customerId
      deletedOn
      exempt
      hasTrialed
      id
      nextAssessmentAt
      organizationId
      periodDay
      pricePointAction
      pricePointHandle
      productHandle
      referralCode
      status
      submittedOn
      subscribedThroughProduct
      subscriptionId
      subscriptionUpdatedAt
      trialEndedAt
      updatedOn
      userCanceled
    }
    timeAccuracy
    timeAccuracyMessage
    timeOffRequests
    timeRounding
    timeRoundingInterval
    timeRoundingType
    totalTimeFlag
    trackBudgetCost
    trackBudgetEquipmentCosts
    trackBudgetEquipmentHours
    trackBudgetProgress
    trackBudgetProgressValue
    trackBudgets
    trackCostCode
    trackEquipment
    trackPaidBreak
    trackProject
    updatedOn
    useProjectCostCodeScoping
    webGps
    minBreakSeconds
    perDiem
    perDiemAmountTracking
    perDiemRequestPermission
    employeeAutoInvite
  }
`;
export const AuthPositionFragmentDoc = gql`
  fragment AuthPosition on Position {
    canEditPosition
    canManageProjectCostCodeQuantity
    canRequestTimeOff
    clockInRestriction
    clockInRestrictionStartTime
    createdOn
    dailyProjectPrompts
    dailyProjectPromptsUseSubprojects
    deletedOn
    equipmentSelectionRequirement
    id
    level
    manageBudgets
    manageBulletinPosts
    manageCompanySettings
    manageCostCodes
    manageEmployeeGroups
    manageEmployees
    manageEquipment
    manageGpsApproval
    manageMediaCollection
    managePerDiem
    manageProjectGroups
    manageProjects
    manageSchedule
    manageTimeEntries
    manageTimeOff
    memberLock
    organizationId
    requestSignatures
    scopeByGroup
    submittedOn
    timeEvents
    title
    updatedOn
    viewAllActivityReport
    viewBudgets
    viewEquipmentDetails
    viewEquipmentStatus
    viewMediaCollection
    viewMemberActivityReport
    viewMemberExpenses
    viewProjectExpenses
    viewSchedule
    createIncident
    manageIncident
    performInspections
    manageInspections
  }
`;
export const AuthMemberFragmentDoc = gql`
  fragment AuthMember on Member {
    archivedOn
    canSurvey
    certifiedEmail
    createdOn
    deletedOn
    email
    emailVerificationRequired
    firstName
    gustoEmployeeId
    id
    imageId
    imageUrl
    lastAccess
    lastName
    memberGroup {
      id
      groupName
      deletedOn
      organizationId
      updatedOn
      createdOn
      submittedOn
    }
    memberGroupId
    memberNumber
    memberSettings
    organization {
      ...AuthOrganization
    }
    organizationId
    pendingEmail
    phone
    position {
      ...AuthPosition
    }
    positionId
    submittedOn
    timeLocationRequired
    updatedOn
    username
    usernameUnique
    verifiedEmail
  }
  ${AuthOrganizationFragmentDoc}
  ${AuthPositionFragmentDoc}
`;
export const BudgetPerformanceProjectFragmentDoc = gql`
  fragment BudgetPerformanceProject on Project {
    id
    title
    cursor
    depth
    children {
      id
      parentProjectId
      archivedOn
    }
  }
`;
export const CostCodeDetailsFragmentDoc = gql`
  fragment CostCodeDetails on CostCode {
    id
    costCode
    costCodeGroup {
      id
      groupName
    }
    costCodeGroupId
    title
    unitTitle
    workersCompId
    createdOn
    updatedOn
    archivedOn
    updatedByMember {
      id
      firstName
      lastName
    }
  }
`;
export const EmployeeDetailsFragmentDoc = gql`
  fragment EmployeeDetails on Member {
    id
    firstName
    lastName
    username
    email
    phone
    memberNumber
    archivedOn
    createdOn
    deletedOn
    updatedOn
    imageUrl
    positionId
    gustoEmployeeId
    gustoContractorId
    isSubContractor
    distantLocationFlaggingDisabled
    updatedByMember {
      id
      firstName
      lastName
    }
    memberLock {
      id
      effectiveDate
    }
    position {
      id
      title
      level
      memberLock
    }
    memberGroupId
    memberGroup {
      id
      groupName
    }
    memberOvertimePeriods {
      id
      changeDate
      adhereToOrg
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      createdOn
      updatedOn
      deletedOn
    }
    wageHistories {
      id
      memberId
      wage
      wageRate
      workWeek
      effectiveRate
      overburden
      changeDate
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    pinCode
    timeLocationRequired
  }
`;
export const InfoFragmentDoc = gql`
  fragment Info on ProjectInfo {
    address1
    address2
    city
    country
    customer
    deletedOn
    id
    latitude
    locationRadius
    longitude
    number
    requireTimeEntryGps
    phone
    postalCode
    projectId
    reminder
    state
    additionalInfo
    distantLocationFlaggingDisabled
  }
`;
export const ProjectDisplayDetailsFragmentDoc = gql`
  fragment ProjectDisplayDetails on Project {
    id
    title
    depth
    ancestors {
      id
      title
      depth
      archivedOn
    }
  }
`;
export const ProjectDetailsFragmentDoc = gql`
  fragment ProjectDetails on Project {
    id
    archivedOn
    children {
      id
      title
      depth
      archivedOn
    }
    parentProject {
      id
      archivedOn
    }
    projectInfo {
      address1
      address2
      city
      country
      customer
      deletedOn
      id
      latitude
      locationRadius
      longitude
      number
      requireTimeEntryGps
      phone
      postalCode
      projectId
      reminder
      state
      additionalInfo
      distantLocationFlaggingDisabled
    }
    parentProjectId
    projectGroup {
      id
      deletedOn
      groupName
    }
    projectGroupId
    rootProjectId
    submittedOn
    title
    updatedOn
    depth
    createdOn
    updatedByMember {
      id
      firstName
      lastName
    }
  }
`;
export const FlatProjectDetailsFragmentDoc = gql`
  fragment FlatProjectDetails on Project {
    id
    organizationId
    parentProjectId
    projectGroupId
    rootProjectId
    title
    depth
    archivedOn
    createdOn
    updatedOn
    cursor
  }
`;
export const JoinedProjectGroupFragmentDoc = gql`
  fragment JoinedProjectGroup on Project {
    projectGroup {
      id
      deletedOn
      groupName
    }
  }
`;
export const JoinedProjectInfoFragmentDoc = gql`
  fragment JoinedProjectInfo on Project {
    projectInfo {
      address1
      address2
      city
      country
      customer
      deletedOn
      id
      latitude
      locationRadius
      longitude
      number
      requireTimeEntryGps
      phone
      postalCode
      projectId
      reminder
      state
      distantLocationFlaggingDisabled
    }
  }
`;
export const PayrollMemberPredicateMemberFragmentDoc = gql`
  fragment PayrollMemberPredicateMember on Member {
    id
    cursor
    firstName
    lastName
    imageUrl
    memberGroupId
    memberNumber
    archivedOn
    gustoEmployeeId
    gustoContractorId
    isSubContractor
    memberTimeDocuments(filter: $memberTimeDocumentFilter, sort: $memberTimeDocumentSort, first: 1) {
      id
      edited
      canceled
      authoritativeSignatureId
      selfSignatureId
    }
    memberLock {
      id
      effectiveDate
      updaterMemberId
      memberId
      createdOn
      submittedOn
      updatedOn
    }
    wageHistories {
      id
      wage
      wageRate
      changeDate
      effectiveRate
      overburden
      memberId
      submittedOn
      updatedOn
      createdOn
      deletedOn
    }
    timeEntries(
      filter: {
        startTime: { lessThanOrEqual: $endTime }
        endTime: { greaterThanOrEqual: $startTime }
        deletedOn: { isNull: true }
      }
    ) {
      id
      endTime
    }
    timeOffs(filter: { dateStamp: { between: { start: $startTime, end: $endTime } }, deletedOn: { isNull: true } }) {
      id
    }
    perDiems(filter: { dateStamp: { between: { start: $startTime, end: $endTime } }, deletedOn: { isNull: true } }) {
      id
      amount
    }
  }
`;
export const PayrollScanBreakComplianceTimeEntryFragmentDoc = gql`
  fragment PayrollScanBreakComplianceTimeEntry on TimeEntry {
    id
    memberId
    startTime
    endTime
    breaks {
      id
      startTime
      endTime
      deletedOn
    }
  }
`;
export const IncidentDetailsFragmentDoc = gql`
  fragment IncidentDetails on Incident {
    id
    organizationId
    memberId
    type
    recordable
    projectId
    state
    illnessType
    caseNumber
    occurredAt
    occurrenceTimeSet
    employeeStartTime
    employeeJobTitle
    employeeBirthday
    employeeHireDate
    employeeSex
    employeeAddress1
    employeeCity
    employeeState
    employeePostalCode
    createdOn
    createdOnLocal
    submittedOn
    updatedOn
    deletedOn
    reviewedOn
    completedOn
    answers {
      id
      incidentId
      question
      type
      answer
    }
    project {
      id
      title
      depth
      ancestors {
        id
        title
        depth
      }
      children {
        id
        title
        depth
      }
    }
    member {
      id
      firstName
      lastName
      phone
    }
    medicalDetails {
      id
      incidentId
      treatmentER
      treatmentOvernight
      treatmentFirstAid
      treatmentDoctor
      treatmentNone
      treatmentOther
      facilityName
      facilityAddress
      facilityCity
      facilityState
      facilityPostalCode
      physicianName
    }
    submittedByMember {
      id
      firstName
      lastName
    }
    reviewedByMember {
      id
      firstName
      lastName
    }
    outcomeDetails {
      id
      type
      daysAway
      daysTransfer
      outcomeDate
    }
    completedByMember {
      id
      firstName
      lastName
    }
  }
`;
export const MemberDisplayFragmentDoc = gql`
  fragment MemberDisplay on Member {
    id
    firstName
    lastName
  }
`;
export const SchedulingScheduledMemberFragmentDoc = gql`
  fragment SchedulingScheduledMember on Member {
    id
    ...MemberDisplay
    memberGroupId
    positionId
    archivedOn
    position {
      id
      level
      timeEvents
      viewSchedule
      manageSchedule
    }
  }
  ${MemberDisplayFragmentDoc}
`;
export const SchedulingTimeOffFieldsFragmentDoc = gql`
  fragment SchedulingTimeOffFields on TimeOff {
    id
    dateStamp
    type
    description
    paid
    seconds
    memberId
    member {
      id
      ...SchedulingScheduledMember
    }
  }
  ${SchedulingScheduledMemberFragmentDoc}
`;
export const RecurringScheduleFlatFieldsFragmentDoc = gql`
  fragment RecurringScheduleFlatFields on RecurringScheduleReminder {
    id
    startTime
    endTime
    type
    frequency
    frequencyExpiration
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    monthOfYear
    dayOfMonth
    notifyEmployee
    isDraft
    memberId
    payload
  }
`;
export const SchedulingReminderProjectFieldsFragmentDoc = gql`
  fragment SchedulingReminderProjectFields on Project {
    id
    title
    depth
    projectGroupId
    parentProjectId
    rootProjectId
    archivedOn
    ancestors {
      id
      title
      depth
      projectGroupId
      archivedOn
    }
    projectCostCodeLink {
      id
      costCodeId
      deletedOn
    }
  }
`;
export const CostCodeDisplayFragmentDoc = gql`
  fragment CostCodeDisplay on CostCode {
    id
    costCode
    title
  }
`;
export const SchedulingReminderCostCodeFieldsFragmentDoc = gql`
  fragment SchedulingReminderCostCodeFields on CostCode {
    ...CostCodeDisplay
    id
    archivedOn
    costCodeGroupId
  }
  ${CostCodeDisplayFragmentDoc}
`;
export const SchedulingReminderEquipmentFieldsFragmentDoc = gql`
  fragment SchedulingReminderEquipmentFields on Equipment {
    archivedOn
    equipmentGroupId
    equipmentName
    id
    model {
      category {
        id
        title
      }
      equipmentCategoryId
      equipmentMakeId
      id
      make {
        id
        title
      }
      modelNumber
      title
      year
    }
  }
`;
export const RecurringScheduleLinkFieldsFragmentDoc = gql`
  fragment RecurringScheduleLinkFields on RecurringScheduleReminderLink {
    id
    scheduleReminderId
    memberId
    member {
      id
      ...SchedulingScheduledMember
    }
  }
  ${SchedulingScheduledMemberFragmentDoc}
`;
export const RecurringScheduleFieldsFragmentDoc = gql`
  fragment RecurringScheduleFields on RecurringScheduleReminder {
    id
    ...RecurringScheduleFlatFields
    projectId
    project {
      id
      ...SchedulingReminderProjectFields
    }
    costCodeId
    costCode {
      id
      ...SchedulingReminderCostCodeFields
    }
    equipmentId
    equipment {
      id
      ...SchedulingReminderEquipmentFields
    }
    links {
      id
      ...RecurringScheduleLinkFields
    }
  }
  ${RecurringScheduleFlatFieldsFragmentDoc}
  ${SchedulingReminderProjectFieldsFragmentDoc}
  ${SchedulingReminderCostCodeFieldsFragmentDoc}
  ${SchedulingReminderEquipmentFieldsFragmentDoc}
  ${RecurringScheduleLinkFieldsFragmentDoc}
`;
export const CreateTimeEntryBreakDocument = gql`
  mutation CreateTimeEntryBreak($entryBreak: TimeEntryBreakCreateInput) {
    createTimeEntryBreak(timeEntryBreak: $entryBreak) {
      id
    }
  }
`;
export type CreateTimeEntryBreakMutationFn = Apollo.MutationFunction<
  CreateTimeEntryBreakMutation,
  CreateTimeEntryBreakMutationVariables
>;

/**
 * __useCreateTimeEntryBreakMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryBreakMutation, { data, loading, error }] = useCreateTimeEntryBreakMutation({
 *   variables: {
 *      entryBreak: // value for 'entryBreak'
 *   },
 * });
 */
export function useCreateTimeEntryBreakMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryBreakMutation, CreateTimeEntryBreakMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntryBreakMutation, CreateTimeEntryBreakMutationVariables>(
    CreateTimeEntryBreakDocument,
    options
  );
}
export type CreateTimeEntryBreakMutationHookResult = ReturnType<typeof useCreateTimeEntryBreakMutation>;
export type CreateTimeEntryBreakMutationResult = Apollo.MutationResult<CreateTimeEntryBreakMutation>;
export type CreateTimeEntryBreakMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntryBreakMutation,
  CreateTimeEntryBreakMutationVariables
>;
export const UpdateTimeEntryBreakDocument = gql`
  mutation UpdateTimeEntryBreak($entryBreak: TimeEntryBreakUpdateInput) {
    updateTimeEntryBreak(timeEntryBreak: $entryBreak) {
      id
    }
  }
`;
export type UpdateTimeEntryBreakMutationFn = Apollo.MutationFunction<
  UpdateTimeEntryBreakMutation,
  UpdateTimeEntryBreakMutationVariables
>;

/**
 * __useUpdateTimeEntryBreakMutation__
 *
 * To run a mutation, you first call `useUpdateTimeEntryBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeEntryBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeEntryBreakMutation, { data, loading, error }] = useUpdateTimeEntryBreakMutation({
 *   variables: {
 *      entryBreak: // value for 'entryBreak'
 *   },
 * });
 */
export function useUpdateTimeEntryBreakMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeEntryBreakMutation, UpdateTimeEntryBreakMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeEntryBreakMutation, UpdateTimeEntryBreakMutationVariables>(
    UpdateTimeEntryBreakDocument,
    options
  );
}
export type UpdateTimeEntryBreakMutationHookResult = ReturnType<typeof useUpdateTimeEntryBreakMutation>;
export type UpdateTimeEntryBreakMutationResult = Apollo.MutationResult<UpdateTimeEntryBreakMutation>;
export type UpdateTimeEntryBreakMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeEntryBreakMutation,
  UpdateTimeEntryBreakMutationVariables
>;
export const CreateTimeEntryBreaksDocument = gql`
  mutation CreateTimeEntryBreaks($entryBreaks: [TimeEntryBreakCreateInput!]!) {
    createTimeEntryBreaks(timeEntryBreaks: $entryBreaks) {
      id
    }
  }
`;
export type CreateTimeEntryBreaksMutationFn = Apollo.MutationFunction<
  CreateTimeEntryBreaksMutation,
  CreateTimeEntryBreaksMutationVariables
>;

/**
 * __useCreateTimeEntryBreaksMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryBreaksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryBreaksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryBreaksMutation, { data, loading, error }] = useCreateTimeEntryBreaksMutation({
 *   variables: {
 *      entryBreaks: // value for 'entryBreaks'
 *   },
 * });
 */
export function useCreateTimeEntryBreaksMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryBreaksMutation, CreateTimeEntryBreaksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntryBreaksMutation, CreateTimeEntryBreaksMutationVariables>(
    CreateTimeEntryBreaksDocument,
    options
  );
}
export type CreateTimeEntryBreaksMutationHookResult = ReturnType<typeof useCreateTimeEntryBreaksMutation>;
export type CreateTimeEntryBreaksMutationResult = Apollo.MutationResult<CreateTimeEntryBreaksMutation>;
export type CreateTimeEntryBreaksMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntryBreaksMutation,
  CreateTimeEntryBreaksMutationVariables
>;
export const UpdateTimeEntryBreaksDocument = gql`
  mutation UpdateTimeEntryBreaks($entryBreaks: [TimeEntryBreakUpdateInput!]!) {
    updateTimeEntryBreaks(timeEntryBreaks: $entryBreaks) {
      id
    }
  }
`;
export type UpdateTimeEntryBreaksMutationFn = Apollo.MutationFunction<
  UpdateTimeEntryBreaksMutation,
  UpdateTimeEntryBreaksMutationVariables
>;

/**
 * __useUpdateTimeEntryBreaksMutation__
 *
 * To run a mutation, you first call `useUpdateTimeEntryBreaksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeEntryBreaksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeEntryBreaksMutation, { data, loading, error }] = useUpdateTimeEntryBreaksMutation({
 *   variables: {
 *      entryBreaks: // value for 'entryBreaks'
 *   },
 * });
 */
export function useUpdateTimeEntryBreaksMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeEntryBreaksMutation, UpdateTimeEntryBreaksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeEntryBreaksMutation, UpdateTimeEntryBreaksMutationVariables>(
    UpdateTimeEntryBreaksDocument,
    options
  );
}
export type UpdateTimeEntryBreaksMutationHookResult = ReturnType<typeof useUpdateTimeEntryBreaksMutation>;
export type UpdateTimeEntryBreaksMutationResult = Apollo.MutationResult<UpdateTimeEntryBreaksMutation>;
export type UpdateTimeEntryBreaksMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeEntryBreaksMutation,
  UpdateTimeEntryBreaksMutationVariables
>;
export const CreateClientTimeEntryBreakLogDocument = gql`
  mutation CreateClientTimeEntryBreakLog($log: ClientTimeEntryBreakLogCreateInput) {
    createClientTimeEntryBreakLog(clientTimeEntryBreakLog: $log) {
      id
      timeEntryBreakId
    }
  }
`;
export type CreateClientTimeEntryBreakLogMutationFn = Apollo.MutationFunction<
  CreateClientTimeEntryBreakLogMutation,
  CreateClientTimeEntryBreakLogMutationVariables
>;

/**
 * __useCreateClientTimeEntryBreakLogMutation__
 *
 * To run a mutation, you first call `useCreateClientTimeEntryBreakLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientTimeEntryBreakLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientTimeEntryBreakLogMutation, { data, loading, error }] = useCreateClientTimeEntryBreakLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *   },
 * });
 */
export function useCreateClientTimeEntryBreakLogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientTimeEntryBreakLogMutation,
    CreateClientTimeEntryBreakLogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientTimeEntryBreakLogMutation, CreateClientTimeEntryBreakLogMutationVariables>(
    CreateClientTimeEntryBreakLogDocument,
    options
  );
}
export type CreateClientTimeEntryBreakLogMutationHookResult = ReturnType<
  typeof useCreateClientTimeEntryBreakLogMutation
>;
export type CreateClientTimeEntryBreakLogMutationResult = Apollo.MutationResult<CreateClientTimeEntryBreakLogMutation>;
export type CreateClientTimeEntryBreakLogMutationOptions = Apollo.BaseMutationOptions<
  CreateClientTimeEntryBreakLogMutation,
  CreateClientTimeEntryBreakLogMutationVariables
>;
export const CreateClientTimeEntryBreakLogsDocument = gql`
  mutation CreateClientTimeEntryBreakLogs($logs: [ClientTimeEntryBreakLogCreateInput!]!) {
    createClientTimeEntryBreakLogs(clientTimeEntryBreakLogs: $logs) {
      id
      timeEntryBreakId
    }
  }
`;
export type CreateClientTimeEntryBreakLogsMutationFn = Apollo.MutationFunction<
  CreateClientTimeEntryBreakLogsMutation,
  CreateClientTimeEntryBreakLogsMutationVariables
>;

/**
 * __useCreateClientTimeEntryBreakLogsMutation__
 *
 * To run a mutation, you first call `useCreateClientTimeEntryBreakLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientTimeEntryBreakLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientTimeEntryBreakLogsMutation, { data, loading, error }] = useCreateClientTimeEntryBreakLogsMutation({
 *   variables: {
 *      logs: // value for 'logs'
 *   },
 * });
 */
export function useCreateClientTimeEntryBreakLogsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientTimeEntryBreakLogsMutation,
    CreateClientTimeEntryBreakLogsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientTimeEntryBreakLogsMutation, CreateClientTimeEntryBreakLogsMutationVariables>(
    CreateClientTimeEntryBreakLogsDocument,
    options
  );
}
export type CreateClientTimeEntryBreakLogsMutationHookResult = ReturnType<
  typeof useCreateClientTimeEntryBreakLogsMutation
>;
export type CreateClientTimeEntryBreakLogsMutationResult =
  Apollo.MutationResult<CreateClientTimeEntryBreakLogsMutation>;
export type CreateClientTimeEntryBreakLogsMutationOptions = Apollo.BaseMutationOptions<
  CreateClientTimeEntryBreakLogsMutation,
  CreateClientTimeEntryBreakLogsMutationVariables
>;
export const CreateTimeEntryLogDocument = gql`
  mutation CreateTimeEntryLog($log: ClientTimeEntryLogCreateInput) {
    createClientTimeEntryLog(clientTimeEntryLog: $log) {
      id
      costCodeId
      description
      deviceType
      equipmentId
      endLocationId
      memberId
      mergedTimeEntryId
      originalTimeEntryId
      projectId
      startLocationId
      timeEntryId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryCreatedOn
      timeEntryDeletedOn
      daylightSavingTime
      metaDaylightSavingTime
      deletedOn
      submittedOn
      updatedOn
      timeEntry {
        id
        member {
          id
          firstName
          lastName
        }
        project {
          id
          title
          depth
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
    }
  }
`;
export type CreateTimeEntryLogMutationFn = Apollo.MutationFunction<
  CreateTimeEntryLogMutation,
  CreateTimeEntryLogMutationVariables
>;

/**
 * __useCreateTimeEntryLogMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryLogMutation, { data, loading, error }] = useCreateTimeEntryLogMutation({
 *   variables: {
 *      log: // value for 'log'
 *   },
 * });
 */
export function useCreateTimeEntryLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryLogMutation, CreateTimeEntryLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntryLogMutation, CreateTimeEntryLogMutationVariables>(
    CreateTimeEntryLogDocument,
    options
  );
}
export type CreateTimeEntryLogMutationHookResult = ReturnType<typeof useCreateTimeEntryLogMutation>;
export type CreateTimeEntryLogMutationResult = Apollo.MutationResult<CreateTimeEntryLogMutation>;
export type CreateTimeEntryLogMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntryLogMutation,
  CreateTimeEntryLogMutationVariables
>;
export const CreateTimeEntryLogsDocument = gql`
  mutation CreateTimeEntryLogs($logs: [ClientTimeEntryLogCreateInput!]!) {
    createClientTimeEntryLogs(clientTimeEntryLogs: $logs) {
      id
      costCodeId
      description
      deviceType
      equipmentId
      endLocationId
      memberId
      mergedTimeEntryId
      originalTimeEntryId
      projectId
      startLocationId
      timeEntryId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryCreatedOn
      timeEntryDeletedOn
      daylightSavingTime
      metaDaylightSavingTime
      deletedOn
      submittedOn
      updatedOn
      timeEntry {
        id
        member {
          id
          firstName
          lastName
        }
        project {
          id
          title
          depth
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
    }
  }
`;
export type CreateTimeEntryLogsMutationFn = Apollo.MutationFunction<
  CreateTimeEntryLogsMutation,
  CreateTimeEntryLogsMutationVariables
>;

/**
 * __useCreateTimeEntryLogsMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryLogsMutation, { data, loading, error }] = useCreateTimeEntryLogsMutation({
 *   variables: {
 *      logs: // value for 'logs'
 *   },
 * });
 */
export function useCreateTimeEntryLogsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryLogsMutation, CreateTimeEntryLogsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntryLogsMutation, CreateTimeEntryLogsMutationVariables>(
    CreateTimeEntryLogsDocument,
    options
  );
}
export type CreateTimeEntryLogsMutationHookResult = ReturnType<typeof useCreateTimeEntryLogsMutation>;
export type CreateTimeEntryLogsMutationResult = Apollo.MutationResult<CreateTimeEntryLogsMutation>;
export type CreateTimeEntryLogsMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntryLogsMutation,
  CreateTimeEntryLogsMutationVariables
>;
export const CreateCostCodeGroupDocument = gql`
  mutation CreateCostCodeGroup($costCodeGroup: CostCodeGroupCreateInput) {
    createCostCodeGroup(costCodeGroup: $costCodeGroup) {
      id
    }
  }
`;
export type CreateCostCodeGroupMutationFn = Apollo.MutationFunction<
  CreateCostCodeGroupMutation,
  CreateCostCodeGroupMutationVariables
>;

/**
 * __useCreateCostCodeGroupMutation__
 *
 * To run a mutation, you first call `useCreateCostCodeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCodeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCodeGroupMutation, { data, loading, error }] = useCreateCostCodeGroupMutation({
 *   variables: {
 *      costCodeGroup: // value for 'costCodeGroup'
 *   },
 * });
 */
export function useCreateCostCodeGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCostCodeGroupMutation, CreateCostCodeGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCostCodeGroupMutation, CreateCostCodeGroupMutationVariables>(
    CreateCostCodeGroupDocument,
    options
  );
}
export type CreateCostCodeGroupMutationHookResult = ReturnType<typeof useCreateCostCodeGroupMutation>;
export type CreateCostCodeGroupMutationResult = Apollo.MutationResult<CreateCostCodeGroupMutation>;
export type CreateCostCodeGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateCostCodeGroupMutation,
  CreateCostCodeGroupMutationVariables
>;
export const UpdateCostCodeGroupDocument = gql`
  mutation UpdateCostCodeGroup($costCodeGroup: CostCodeGroupUpdateInput) {
    updateCostCodeGroup(costCodeGroup: $costCodeGroup) {
      id
    }
  }
`;
export type UpdateCostCodeGroupMutationFn = Apollo.MutationFunction<
  UpdateCostCodeGroupMutation,
  UpdateCostCodeGroupMutationVariables
>;

/**
 * __useUpdateCostCodeGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCostCodeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCodeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCodeGroupMutation, { data, loading, error }] = useUpdateCostCodeGroupMutation({
 *   variables: {
 *      costCodeGroup: // value for 'costCodeGroup'
 *   },
 * });
 */
export function useUpdateCostCodeGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCostCodeGroupMutation, UpdateCostCodeGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCostCodeGroupMutation, UpdateCostCodeGroupMutationVariables>(
    UpdateCostCodeGroupDocument,
    options
  );
}
export type UpdateCostCodeGroupMutationHookResult = ReturnType<typeof useUpdateCostCodeGroupMutation>;
export type UpdateCostCodeGroupMutationResult = Apollo.MutationResult<UpdateCostCodeGroupMutation>;
export type UpdateCostCodeGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostCodeGroupMutation,
  UpdateCostCodeGroupMutationVariables
>;
export const CreateCostCodeDocument = gql`
  mutation CreateCostCode($costCode: CostCodeCreateInput) {
    createCostCode(costCode: $costCode) {
      id
      costCode
      title
    }
  }
`;
export type CreateCostCodeMutationFn = Apollo.MutationFunction<CreateCostCodeMutation, CreateCostCodeMutationVariables>;

/**
 * __useCreateCostCodeMutation__
 *
 * To run a mutation, you first call `useCreateCostCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCodeMutation, { data, loading, error }] = useCreateCostCodeMutation({
 *   variables: {
 *      costCode: // value for 'costCode'
 *   },
 * });
 */
export function useCreateCostCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCostCodeMutation, CreateCostCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCostCodeMutation, CreateCostCodeMutationVariables>(CreateCostCodeDocument, options);
}
export type CreateCostCodeMutationHookResult = ReturnType<typeof useCreateCostCodeMutation>;
export type CreateCostCodeMutationResult = Apollo.MutationResult<CreateCostCodeMutation>;
export type CreateCostCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateCostCodeMutation,
  CreateCostCodeMutationVariables
>;
export const UpdateCostCode2Document = gql`
  mutation UpdateCostCode2($costCode: CostCodeUpdateInput) {
    updateCostCode(costCode: $costCode) {
      id
      costCode
      title
    }
  }
`;
export type UpdateCostCode2MutationFn = Apollo.MutationFunction<
  UpdateCostCode2Mutation,
  UpdateCostCode2MutationVariables
>;

/**
 * __useUpdateCostCode2Mutation__
 *
 * To run a mutation, you first call `useUpdateCostCode2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCode2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCode2Mutation, { data, loading, error }] = useUpdateCostCode2Mutation({
 *   variables: {
 *      costCode: // value for 'costCode'
 *   },
 * });
 */
export function useUpdateCostCode2Mutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCostCode2Mutation, UpdateCostCode2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCostCode2Mutation, UpdateCostCode2MutationVariables>(
    UpdateCostCode2Document,
    options
  );
}
export type UpdateCostCode2MutationHookResult = ReturnType<typeof useUpdateCostCode2Mutation>;
export type UpdateCostCode2MutationResult = Apollo.MutationResult<UpdateCostCode2Mutation>;
export type UpdateCostCode2MutationOptions = Apollo.BaseMutationOptions<
  UpdateCostCode2Mutation,
  UpdateCostCode2MutationVariables
>;
export const UpdateDailyProjectReportDocument = gql`
  mutation UpdateDailyProjectReport($id: ID!, $deletedOn: Timestamp) {
    updateDailyProjectReport(dailyProjectReport: { id: $id, deletedOn: $deletedOn }) {
      id
      deletedOn
    }
  }
`;
export type UpdateDailyProjectReportMutationFn = Apollo.MutationFunction<
  UpdateDailyProjectReportMutation,
  UpdateDailyProjectReportMutationVariables
>;

/**
 * __useUpdateDailyProjectReportMutation__
 *
 * To run a mutation, you first call `useUpdateDailyProjectReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyProjectReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyProjectReportMutation, { data, loading, error }] = useUpdateDailyProjectReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedOn: // value for 'deletedOn'
 *   },
 * });
 */
export function useUpdateDailyProjectReportMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDailyProjectReportMutation, UpdateDailyProjectReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDailyProjectReportMutation, UpdateDailyProjectReportMutationVariables>(
    UpdateDailyProjectReportDocument,
    options
  );
}
export type UpdateDailyProjectReportMutationHookResult = ReturnType<typeof useUpdateDailyProjectReportMutation>;
export type UpdateDailyProjectReportMutationResult = Apollo.MutationResult<UpdateDailyProjectReportMutation>;
export type UpdateDailyProjectReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateDailyProjectReportMutation,
  UpdateDailyProjectReportMutationVariables
>;
export const DailyProjectReportDocument = gql`
  mutation DailyProjectReport($dailyProjectReport: DailyProjectReportCreateInput) {
    createDailyProjectReport(dailyProjectReport: $dailyProjectReport) {
      id
      projectId
      createdBy
      reportDate
      fileUrl
    }
  }
`;
export type DailyProjectReportMutationFn = Apollo.MutationFunction<
  DailyProjectReportMutation,
  DailyProjectReportMutationVariables
>;

/**
 * __useDailyProjectReportMutation__
 *
 * To run a mutation, you first call `useDailyProjectReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDailyProjectReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dailyProjectReportMutation, { data, loading, error }] = useDailyProjectReportMutation({
 *   variables: {
 *      dailyProjectReport: // value for 'dailyProjectReport'
 *   },
 * });
 */
export function useDailyProjectReportMutation(
  baseOptions?: Apollo.MutationHookOptions<DailyProjectReportMutation, DailyProjectReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DailyProjectReportMutation, DailyProjectReportMutationVariables>(
    DailyProjectReportDocument,
    options
  );
}
export type DailyProjectReportMutationHookResult = ReturnType<typeof useDailyProjectReportMutation>;
export type DailyProjectReportMutationResult = Apollo.MutationResult<DailyProjectReportMutation>;
export type DailyProjectReportMutationOptions = Apollo.BaseMutationOptions<
  DailyProjectReportMutation,
  DailyProjectReportMutationVariables
>;
export const CreateEquipmentHoursDocument = gql`
  mutation CreateEquipmentHours($equipmentHours: EquipmentHoursCreateInput) {
    createEquipmentHours(equipmentHours: $equipmentHours) {
      id
      runningHours
    }
  }
`;
export type CreateEquipmentHoursMutationFn = Apollo.MutationFunction<
  CreateEquipmentHoursMutation,
  CreateEquipmentHoursMutationVariables
>;

/**
 * __useCreateEquipmentHoursMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentHoursMutation, { data, loading, error }] = useCreateEquipmentHoursMutation({
 *   variables: {
 *      equipmentHours: // value for 'equipmentHours'
 *   },
 * });
 */
export function useCreateEquipmentHoursMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEquipmentHoursMutation, CreateEquipmentHoursMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEquipmentHoursMutation, CreateEquipmentHoursMutationVariables>(
    CreateEquipmentHoursDocument,
    options
  );
}
export type CreateEquipmentHoursMutationHookResult = ReturnType<typeof useCreateEquipmentHoursMutation>;
export type CreateEquipmentHoursMutationResult = Apollo.MutationResult<CreateEquipmentHoursMutation>;
export type CreateEquipmentHoursMutationOptions = Apollo.BaseMutationOptions<
  CreateEquipmentHoursMutation,
  CreateEquipmentHoursMutationVariables
>;
export const CreateEquipmentDocument = gql`
  mutation CreateEquipment($equipment: EquipmentCreateInput) {
    createEquipment(equipment: $equipment) {
      id
      equipmentName
      equipmentModelId
      trackManualHours
      model {
        id
        title
        year
        make {
          id
          title
        }
        category {
          id
          title
        }
      }
      lastHours {
        id
        runningHours
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;
export type CreateEquipmentMutationFn = Apollo.MutationFunction<
  CreateEquipmentMutation,
  CreateEquipmentMutationVariables
>;

/**
 * __useCreateEquipmentMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentMutation, { data, loading, error }] = useCreateEquipmentMutation({
 *   variables: {
 *      equipment: // value for 'equipment'
 *   },
 * });
 */
export function useCreateEquipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEquipmentMutation, CreateEquipmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEquipmentMutation, CreateEquipmentMutationVariables>(
    CreateEquipmentDocument,
    options
  );
}
export type CreateEquipmentMutationHookResult = ReturnType<typeof useCreateEquipmentMutation>;
export type CreateEquipmentMutationResult = Apollo.MutationResult<CreateEquipmentMutation>;
export type CreateEquipmentMutationOptions = Apollo.BaseMutationOptions<
  CreateEquipmentMutation,
  CreateEquipmentMutationVariables
>;
export const UpdateEquipmentDocument = gql`
  mutation UpdateEquipment($equipment: EquipmentUpdateInput) {
    updateEquipment(equipment: $equipment) {
      id
      equipmentName
      equipmentModelId
      trackManualHours
      model {
        id
        title
        year
        make {
          id
          title
        }
        category {
          id
          title
        }
      }
      lastHours {
        id
        runningHours
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;
export type UpdateEquipmentMutationFn = Apollo.MutationFunction<
  UpdateEquipmentMutation,
  UpdateEquipmentMutationVariables
>;

/**
 * __useUpdateEquipmentMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentMutation, { data, loading, error }] = useUpdateEquipmentMutation({
 *   variables: {
 *      equipment: // value for 'equipment'
 *   },
 * });
 */
export function useUpdateEquipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEquipmentMutation, UpdateEquipmentMutationVariables>(
    UpdateEquipmentDocument,
    options
  );
}
export type UpdateEquipmentMutationHookResult = ReturnType<typeof useUpdateEquipmentMutation>;
export type UpdateEquipmentMutationResult = Apollo.MutationResult<UpdateEquipmentMutation>;
export type UpdateEquipmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateEquipmentMutation,
  UpdateEquipmentMutationVariables
>;
export const UpdateIncidentDocument = gql`
  mutation UpdateIncident($incident: IncidentUpdateInput) {
    updateIncident(incident: $incident) {
      ...IncidentDetails
    }
  }
  ${IncidentDetailsFragmentDoc}
`;
export type UpdateIncidentMutationFn = Apollo.MutationFunction<UpdateIncidentMutation, UpdateIncidentMutationVariables>;

/**
 * __useUpdateIncidentMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentMutation, { data, loading, error }] = useUpdateIncidentMutation({
 *   variables: {
 *      incident: // value for 'incident'
 *   },
 * });
 */
export function useUpdateIncidentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateIncidentMutation, UpdateIncidentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIncidentMutation, UpdateIncidentMutationVariables>(UpdateIncidentDocument, options);
}
export type UpdateIncidentMutationHookResult = ReturnType<typeof useUpdateIncidentMutation>;
export type UpdateIncidentMutationResult = Apollo.MutationResult<UpdateIncidentMutation>;
export type UpdateIncidentMutationOptions = Apollo.BaseMutationOptions<
  UpdateIncidentMutation,
  UpdateIncidentMutationVariables
>;
export const CreateInspectionCategoryDocument = gql`
  mutation CreateInspectionCategory($category: InspectionCategoryCreateInput) {
    createInspectionCategory(category: $category) {
      id
      name
      createdOn
    }
  }
`;
export type CreateInspectionCategoryMutationFn = Apollo.MutationFunction<
  CreateInspectionCategoryMutation,
  CreateInspectionCategoryMutationVariables
>;

/**
 * __useCreateInspectionCategoryMutation__
 *
 * To run a mutation, you first call `useCreateInspectionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionCategoryMutation, { data, loading, error }] = useCreateInspectionCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useCreateInspectionCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInspectionCategoryMutation, CreateInspectionCategoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInspectionCategoryMutation, CreateInspectionCategoryMutationVariables>(
    CreateInspectionCategoryDocument,
    options
  );
}
export type CreateInspectionCategoryMutationHookResult = ReturnType<typeof useCreateInspectionCategoryMutation>;
export type CreateInspectionCategoryMutationResult = Apollo.MutationResult<CreateInspectionCategoryMutation>;
export type CreateInspectionCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionCategoryMutation,
  CreateInspectionCategoryMutationVariables
>;
export const UpdateInspectionCategoryDocument = gql`
  mutation UpdateInspectionCategory($category: InspectionCategoryUpdateInput) {
    updateInspectionCategory(category: $category) {
      id
      name
    }
  }
`;
export type UpdateInspectionCategoryMutationFn = Apollo.MutationFunction<
  UpdateInspectionCategoryMutation,
  UpdateInspectionCategoryMutationVariables
>;

/**
 * __useUpdateInspectionCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionCategoryMutation, { data, loading, error }] = useUpdateInspectionCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useUpdateInspectionCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInspectionCategoryMutation, UpdateInspectionCategoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInspectionCategoryMutation, UpdateInspectionCategoryMutationVariables>(
    UpdateInspectionCategoryDocument,
    options
  );
}
export type UpdateInspectionCategoryMutationHookResult = ReturnType<typeof useUpdateInspectionCategoryMutation>;
export type UpdateInspectionCategoryMutationResult = Apollo.MutationResult<UpdateInspectionCategoryMutation>;
export type UpdateInspectionCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionCategoryMutation,
  UpdateInspectionCategoryMutationVariables
>;
export const DeleteInspectionCategoryDocument = gql`
  mutation DeleteInspectionCategory($category: InspectionCategoryUpdateInput) {
    updateInspectionCategory(category: $category) {
      id
      deletedOn
    }
  }
`;
export type DeleteInspectionCategoryMutationFn = Apollo.MutationFunction<
  DeleteInspectionCategoryMutation,
  DeleteInspectionCategoryMutationVariables
>;

/**
 * __useDeleteInspectionCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteInspectionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInspectionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInspectionCategoryMutation, { data, loading, error }] = useDeleteInspectionCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useDeleteInspectionCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInspectionCategoryMutation, DeleteInspectionCategoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInspectionCategoryMutation, DeleteInspectionCategoryMutationVariables>(
    DeleteInspectionCategoryDocument,
    options
  );
}
export type DeleteInspectionCategoryMutationHookResult = ReturnType<typeof useDeleteInspectionCategoryMutation>;
export type DeleteInspectionCategoryMutationResult = Apollo.MutationResult<DeleteInspectionCategoryMutation>;
export type DeleteInspectionCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteInspectionCategoryMutation,
  DeleteInspectionCategoryMutationVariables
>;
export const CreateInspectionChecklistMemberLinkDocument = gql`
  mutation CreateInspectionChecklistMemberLink($checklistMemberLink: InspectionChecklistMemberLinkCreateInput) {
    createInspectionChecklistMemberLink(checklistMemberLink: $checklistMemberLink) {
      ...InspectionChecklistMemberLinkFragment
    }
  }
  ${InspectionChecklistMemberLinkFragmentFragmentDoc}
`;
export type CreateInspectionChecklistMemberLinkMutationFn = Apollo.MutationFunction<
  CreateInspectionChecklistMemberLinkMutation,
  CreateInspectionChecklistMemberLinkMutationVariables
>;

/**
 * __useCreateInspectionChecklistMemberLinkMutation__
 *
 * To run a mutation, you first call `useCreateInspectionChecklistMemberLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionChecklistMemberLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionChecklistMemberLinkMutation, { data, loading, error }] = useCreateInspectionChecklistMemberLinkMutation({
 *   variables: {
 *      checklistMemberLink: // value for 'checklistMemberLink'
 *   },
 * });
 */
export function useCreateInspectionChecklistMemberLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionChecklistMemberLinkMutation,
    CreateInspectionChecklistMemberLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInspectionChecklistMemberLinkMutation,
    CreateInspectionChecklistMemberLinkMutationVariables
  >(CreateInspectionChecklistMemberLinkDocument, options);
}
export type CreateInspectionChecklistMemberLinkMutationHookResult = ReturnType<
  typeof useCreateInspectionChecklistMemberLinkMutation
>;
export type CreateInspectionChecklistMemberLinkMutationResult =
  Apollo.MutationResult<CreateInspectionChecklistMemberLinkMutation>;
export type CreateInspectionChecklistMemberLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionChecklistMemberLinkMutation,
  CreateInspectionChecklistMemberLinkMutationVariables
>;
export const UpdateInspectionChecklistMemberLinkDocument = gql`
  mutation UpdateInspectionChecklistMemberLink($checklistMemberLink: InspectionChecklistMemberLinkUpdateInput) {
    updateInspectionChecklistMemberLink(checklistMemberLink: $checklistMemberLink) {
      ...InspectionChecklistMemberLinkFragment
    }
  }
  ${InspectionChecklistMemberLinkFragmentFragmentDoc}
`;
export type UpdateInspectionChecklistMemberLinkMutationFn = Apollo.MutationFunction<
  UpdateInspectionChecklistMemberLinkMutation,
  UpdateInspectionChecklistMemberLinkMutationVariables
>;

/**
 * __useUpdateInspectionChecklistMemberLinkMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionChecklistMemberLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionChecklistMemberLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionChecklistMemberLinkMutation, { data, loading, error }] = useUpdateInspectionChecklistMemberLinkMutation({
 *   variables: {
 *      checklistMemberLink: // value for 'checklistMemberLink'
 *   },
 * });
 */
export function useUpdateInspectionChecklistMemberLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInspectionChecklistMemberLinkMutation,
    UpdateInspectionChecklistMemberLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInspectionChecklistMemberLinkMutation,
    UpdateInspectionChecklistMemberLinkMutationVariables
  >(UpdateInspectionChecklistMemberLinkDocument, options);
}
export type UpdateInspectionChecklistMemberLinkMutationHookResult = ReturnType<
  typeof useUpdateInspectionChecklistMemberLinkMutation
>;
export type UpdateInspectionChecklistMemberLinkMutationResult =
  Apollo.MutationResult<UpdateInspectionChecklistMemberLinkMutation>;
export type UpdateInspectionChecklistMemberLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionChecklistMemberLinkMutation,
  UpdateInspectionChecklistMemberLinkMutationVariables
>;
export const CreateInspectionChecklistDocument = gql`
  mutation CreateInspectionChecklist($checklist: InspectionChecklistCreateInput) {
    createInspectionChecklist(checklist: $checklist) {
      ...InspectionChecklistFragment
    }
  }
  ${InspectionChecklistFragmentFragmentDoc}
`;
export type CreateInspectionChecklistMutationFn = Apollo.MutationFunction<
  CreateInspectionChecklistMutation,
  CreateInspectionChecklistMutationVariables
>;

/**
 * __useCreateInspectionChecklistMutation__
 *
 * To run a mutation, you first call `useCreateInspectionChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionChecklistMutation, { data, loading, error }] = useCreateInspectionChecklistMutation({
 *   variables: {
 *      checklist: // value for 'checklist'
 *   },
 * });
 */
export function useCreateInspectionChecklistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionChecklistMutation,
    CreateInspectionChecklistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInspectionChecklistMutation, CreateInspectionChecklistMutationVariables>(
    CreateInspectionChecklistDocument,
    options
  );
}
export type CreateInspectionChecklistMutationHookResult = ReturnType<typeof useCreateInspectionChecklistMutation>;
export type CreateInspectionChecklistMutationResult = Apollo.MutationResult<CreateInspectionChecklistMutation>;
export type CreateInspectionChecklistMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionChecklistMutation,
  CreateInspectionChecklistMutationVariables
>;
export const UpdateInspectionChecklistDocument = gql`
  mutation UpdateInspectionChecklist($checklist: InspectionChecklistUpdateInput) {
    updateInspectionChecklist(checklist: $checklist) {
      ...InspectionChecklistFragment
    }
  }
  ${InspectionChecklistFragmentFragmentDoc}
`;
export type UpdateInspectionChecklistMutationFn = Apollo.MutationFunction<
  UpdateInspectionChecklistMutation,
  UpdateInspectionChecklistMutationVariables
>;

/**
 * __useUpdateInspectionChecklistMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionChecklistMutation, { data, loading, error }] = useUpdateInspectionChecklistMutation({
 *   variables: {
 *      checklist: // value for 'checklist'
 *   },
 * });
 */
export function useUpdateInspectionChecklistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInspectionChecklistMutation,
    UpdateInspectionChecklistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInspectionChecklistMutation, UpdateInspectionChecklistMutationVariables>(
    UpdateInspectionChecklistDocument,
    options
  );
}
export type UpdateInspectionChecklistMutationHookResult = ReturnType<typeof useUpdateInspectionChecklistMutation>;
export type UpdateInspectionChecklistMutationResult = Apollo.MutationResult<UpdateInspectionChecklistMutation>;
export type UpdateInspectionChecklistMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionChecklistMutation,
  UpdateInspectionChecklistMutationVariables
>;
export const UpdateInspectionAssignmentDocument = gql`
  mutation UpdateInspectionAssignment($inspectionAssignment: InspectionAssignmentUpdateInput) {
    updateInspectionAssignment(assignment: $inspectionAssignment) {
      id
      memberId
      checklistId
      projectId
      date
      sendNotification
      notificationTime
      description
      limitCategories
      notifySubmission
      notifyCircumstance
      circumstanceValue
      deletedOn
      createdOn
      updatedOn
      limitedCategories {
        id
        categoryId
        deletedOn
      }
      notificationLinks {
        id
        memberId
        deletedOn
      }
      inspection {
        id
        completedOn
        deletedOn
      }
    }
  }
`;
export type UpdateInspectionAssignmentMutationFn = Apollo.MutationFunction<
  UpdateInspectionAssignmentMutation,
  UpdateInspectionAssignmentMutationVariables
>;

/**
 * __useUpdateInspectionAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionAssignmentMutation, { data, loading, error }] = useUpdateInspectionAssignmentMutation({
 *   variables: {
 *      inspectionAssignment: // value for 'inspectionAssignment'
 *   },
 * });
 */
export function useUpdateInspectionAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInspectionAssignmentMutation,
    UpdateInspectionAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInspectionAssignmentMutation, UpdateInspectionAssignmentMutationVariables>(
    UpdateInspectionAssignmentDocument,
    options
  );
}
export type UpdateInspectionAssignmentMutationHookResult = ReturnType<typeof useUpdateInspectionAssignmentMutation>;
export type UpdateInspectionAssignmentMutationResult = Apollo.MutationResult<UpdateInspectionAssignmentMutation>;
export type UpdateInspectionAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionAssignmentMutation,
  UpdateInspectionAssignmentMutationVariables
>;
export const UpdateInspectionDocument = gql`
  mutation UpdateInspection($inspection: InspectionUpdateInput) {
    updateInspection(inspection: $inspection) {
      id
      organizationId
      checklistId
      assignmentId
      recurringAssignmentId
      projectId
      submittedBy
      updatedBy
      inspectedOn
      description
      score
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
export type UpdateInspectionMutationFn = Apollo.MutationFunction<
  UpdateInspectionMutation,
  UpdateInspectionMutationVariables
>;

/**
 * __useUpdateInspectionMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionMutation, { data, loading, error }] = useUpdateInspectionMutation({
 *   variables: {
 *      inspection: // value for 'inspection'
 *   },
 * });
 */
export function useUpdateInspectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInspectionMutation, UpdateInspectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInspectionMutation, UpdateInspectionMutationVariables>(
    UpdateInspectionDocument,
    options
  );
}
export type UpdateInspectionMutationHookResult = ReturnType<typeof useUpdateInspectionMutation>;
export type UpdateInspectionMutationResult = Apollo.MutationResult<UpdateInspectionMutation>;
export type UpdateInspectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionMutation,
  UpdateInspectionMutationVariables
>;
export const CreateInspectionAssignmentDocument = gql`
  mutation CreateInspectionAssignment($assignment: InspectionAssignmentCreateInput) {
    createInspectionAssignment(assignment: $assignment) {
      id
    }
  }
`;
export type CreateInspectionAssignmentMutationFn = Apollo.MutationFunction<
  CreateInspectionAssignmentMutation,
  CreateInspectionAssignmentMutationVariables
>;

/**
 * __useCreateInspectionAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateInspectionAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionAssignmentMutation, { data, loading, error }] = useCreateInspectionAssignmentMutation({
 *   variables: {
 *      assignment: // value for 'assignment'
 *   },
 * });
 */
export function useCreateInspectionAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionAssignmentMutation,
    CreateInspectionAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInspectionAssignmentMutation, CreateInspectionAssignmentMutationVariables>(
    CreateInspectionAssignmentDocument,
    options
  );
}
export type CreateInspectionAssignmentMutationHookResult = ReturnType<typeof useCreateInspectionAssignmentMutation>;
export type CreateInspectionAssignmentMutationResult = Apollo.MutationResult<CreateInspectionAssignmentMutation>;
export type CreateInspectionAssignmentMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionAssignmentMutation,
  CreateInspectionAssignmentMutationVariables
>;
export const CreateInspectionAssignmentNotificationLinkDocument = gql`
  mutation CreateInspectionAssignmentNotificationLink(
    $assignmentNotificationLink: InspectionAssignmentNotificationLinkCreateInput
  ) {
    createInspectionAssignmentNotificationLink(assignmentNotificationLink: $assignmentNotificationLink) {
      id
    }
  }
`;
export type CreateInspectionAssignmentNotificationLinkMutationFn = Apollo.MutationFunction<
  CreateInspectionAssignmentNotificationLinkMutation,
  CreateInspectionAssignmentNotificationLinkMutationVariables
>;

/**
 * __useCreateInspectionAssignmentNotificationLinkMutation__
 *
 * To run a mutation, you first call `useCreateInspectionAssignmentNotificationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionAssignmentNotificationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionAssignmentNotificationLinkMutation, { data, loading, error }] = useCreateInspectionAssignmentNotificationLinkMutation({
 *   variables: {
 *      assignmentNotificationLink: // value for 'assignmentNotificationLink'
 *   },
 * });
 */
export function useCreateInspectionAssignmentNotificationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionAssignmentNotificationLinkMutation,
    CreateInspectionAssignmentNotificationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInspectionAssignmentNotificationLinkMutation,
    CreateInspectionAssignmentNotificationLinkMutationVariables
  >(CreateInspectionAssignmentNotificationLinkDocument, options);
}
export type CreateInspectionAssignmentNotificationLinkMutationHookResult = ReturnType<
  typeof useCreateInspectionAssignmentNotificationLinkMutation
>;
export type CreateInspectionAssignmentNotificationLinkMutationResult =
  Apollo.MutationResult<CreateInspectionAssignmentNotificationLinkMutation>;
export type CreateInspectionAssignmentNotificationLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionAssignmentNotificationLinkMutation,
  CreateInspectionAssignmentNotificationLinkMutationVariables
>;
export const UpdateInspectionAssignmentNotificationLinkDocument = gql`
  mutation UpdateInspectionAssignmentNotificationLink(
    $assignmentNotificationLink: InspectionAssignmentNotificationLinkUpdateInput
  ) {
    updateInspectionAssignmentNotificationLink(assignmentNotificationLink: $assignmentNotificationLink) {
      id
    }
  }
`;
export type UpdateInspectionAssignmentNotificationLinkMutationFn = Apollo.MutationFunction<
  UpdateInspectionAssignmentNotificationLinkMutation,
  UpdateInspectionAssignmentNotificationLinkMutationVariables
>;

/**
 * __useUpdateInspectionAssignmentNotificationLinkMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionAssignmentNotificationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionAssignmentNotificationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionAssignmentNotificationLinkMutation, { data, loading, error }] = useUpdateInspectionAssignmentNotificationLinkMutation({
 *   variables: {
 *      assignmentNotificationLink: // value for 'assignmentNotificationLink'
 *   },
 * });
 */
export function useUpdateInspectionAssignmentNotificationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInspectionAssignmentNotificationLinkMutation,
    UpdateInspectionAssignmentNotificationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInspectionAssignmentNotificationLinkMutation,
    UpdateInspectionAssignmentNotificationLinkMutationVariables
  >(UpdateInspectionAssignmentNotificationLinkDocument, options);
}
export type UpdateInspectionAssignmentNotificationLinkMutationHookResult = ReturnType<
  typeof useUpdateInspectionAssignmentNotificationLinkMutation
>;
export type UpdateInspectionAssignmentNotificationLinkMutationResult =
  Apollo.MutationResult<UpdateInspectionAssignmentNotificationLinkMutation>;
export type UpdateInspectionAssignmentNotificationLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionAssignmentNotificationLinkMutation,
  UpdateInspectionAssignmentNotificationLinkMutationVariables
>;
export const CreateInspectionAssignmentCategoryLinkDocument = gql`
  mutation CreateInspectionAssignmentCategoryLink(
    $assignmentCategoryLink: InspectionAssignmentCategoryLinkCreateInput
  ) {
    createInspectionAssignmentCategoryLink(assignmentCategoryLink: $assignmentCategoryLink) {
      id
    }
  }
`;
export type CreateInspectionAssignmentCategoryLinkMutationFn = Apollo.MutationFunction<
  CreateInspectionAssignmentCategoryLinkMutation,
  CreateInspectionAssignmentCategoryLinkMutationVariables
>;

/**
 * __useCreateInspectionAssignmentCategoryLinkMutation__
 *
 * To run a mutation, you first call `useCreateInspectionAssignmentCategoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionAssignmentCategoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionAssignmentCategoryLinkMutation, { data, loading, error }] = useCreateInspectionAssignmentCategoryLinkMutation({
 *   variables: {
 *      assignmentCategoryLink: // value for 'assignmentCategoryLink'
 *   },
 * });
 */
export function useCreateInspectionAssignmentCategoryLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionAssignmentCategoryLinkMutation,
    CreateInspectionAssignmentCategoryLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInspectionAssignmentCategoryLinkMutation,
    CreateInspectionAssignmentCategoryLinkMutationVariables
  >(CreateInspectionAssignmentCategoryLinkDocument, options);
}
export type CreateInspectionAssignmentCategoryLinkMutationHookResult = ReturnType<
  typeof useCreateInspectionAssignmentCategoryLinkMutation
>;
export type CreateInspectionAssignmentCategoryLinkMutationResult =
  Apollo.MutationResult<CreateInspectionAssignmentCategoryLinkMutation>;
export type CreateInspectionAssignmentCategoryLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionAssignmentCategoryLinkMutation,
  CreateInspectionAssignmentCategoryLinkMutationVariables
>;
export const UpdateInspectionAssignmentCategoryLinkDocument = gql`
  mutation UpdateInspectionAssignmentCategoryLink(
    $assignmentCategoryLink: InspectionAssignmentCategoryLinkUpdateInput
  ) {
    updateInspectionAssignmentCategoryLink(assignmentCategoryLink: $assignmentCategoryLink) {
      id
    }
  }
`;
export type UpdateInspectionAssignmentCategoryLinkMutationFn = Apollo.MutationFunction<
  UpdateInspectionAssignmentCategoryLinkMutation,
  UpdateInspectionAssignmentCategoryLinkMutationVariables
>;

/**
 * __useUpdateInspectionAssignmentCategoryLinkMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionAssignmentCategoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionAssignmentCategoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionAssignmentCategoryLinkMutation, { data, loading, error }] = useUpdateInspectionAssignmentCategoryLinkMutation({
 *   variables: {
 *      assignmentCategoryLink: // value for 'assignmentCategoryLink'
 *   },
 * });
 */
export function useUpdateInspectionAssignmentCategoryLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInspectionAssignmentCategoryLinkMutation,
    UpdateInspectionAssignmentCategoryLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInspectionAssignmentCategoryLinkMutation,
    UpdateInspectionAssignmentCategoryLinkMutationVariables
  >(UpdateInspectionAssignmentCategoryLinkDocument, options);
}
export type UpdateInspectionAssignmentCategoryLinkMutationHookResult = ReturnType<
  typeof useUpdateInspectionAssignmentCategoryLinkMutation
>;
export type UpdateInspectionAssignmentCategoryLinkMutationResult =
  Apollo.MutationResult<UpdateInspectionAssignmentCategoryLinkMutation>;
export type UpdateInspectionAssignmentCategoryLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionAssignmentCategoryLinkMutation,
  UpdateInspectionAssignmentCategoryLinkMutationVariables
>;
export const CreateInspectionQuestionDocument = gql`
  mutation CreateInspectionQuestion($question: InspectionQuestionCreateInput) {
    createInspectionQuestion(question: $question) {
      id
      question
      enabled
      order
    }
  }
`;
export type CreateInspectionQuestionMutationFn = Apollo.MutationFunction<
  CreateInspectionQuestionMutation,
  CreateInspectionQuestionMutationVariables
>;

/**
 * __useCreateInspectionQuestionMutation__
 *
 * To run a mutation, you first call `useCreateInspectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionQuestionMutation, { data, loading, error }] = useCreateInspectionQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useCreateInspectionQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInspectionQuestionMutation, CreateInspectionQuestionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInspectionQuestionMutation, CreateInspectionQuestionMutationVariables>(
    CreateInspectionQuestionDocument,
    options
  );
}
export type CreateInspectionQuestionMutationHookResult = ReturnType<typeof useCreateInspectionQuestionMutation>;
export type CreateInspectionQuestionMutationResult = Apollo.MutationResult<CreateInspectionQuestionMutation>;
export type CreateInspectionQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionQuestionMutation,
  CreateInspectionQuestionMutationVariables
>;
export const CreateInspectionQuestionSDocument = gql`
  mutation CreateInspectionQuestionS($questions: [InspectionQuestionCreateInput!]!) {
    createInspectionQuestions(questions: $questions) {
      id
      question
      enabled
      order
    }
  }
`;
export type CreateInspectionQuestionSMutationFn = Apollo.MutationFunction<
  CreateInspectionQuestionSMutation,
  CreateInspectionQuestionSMutationVariables
>;

/**
 * __useCreateInspectionQuestionSMutation__
 *
 * To run a mutation, you first call `useCreateInspectionQuestionSMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInspectionQuestionSMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInspectionQuestionSMutation, { data, loading, error }] = useCreateInspectionQuestionSMutation({
 *   variables: {
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useCreateInspectionQuestionSMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInspectionQuestionSMutation,
    CreateInspectionQuestionSMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInspectionQuestionSMutation, CreateInspectionQuestionSMutationVariables>(
    CreateInspectionQuestionSDocument,
    options
  );
}
export type CreateInspectionQuestionSMutationHookResult = ReturnType<typeof useCreateInspectionQuestionSMutation>;
export type CreateInspectionQuestionSMutationResult = Apollo.MutationResult<CreateInspectionQuestionSMutation>;
export type CreateInspectionQuestionSMutationOptions = Apollo.BaseMutationOptions<
  CreateInspectionQuestionSMutation,
  CreateInspectionQuestionSMutationVariables
>;
export const UpdateInspectionQuestionDocument = gql`
  mutation UpdateInspectionQuestion($question: InspectionQuestionUpdateInput) {
    updateInspectionQuestion(question: $question) {
      id
      question
      enabled
      order
    }
  }
`;
export type UpdateInspectionQuestionMutationFn = Apollo.MutationFunction<
  UpdateInspectionQuestionMutation,
  UpdateInspectionQuestionMutationVariables
>;

/**
 * __useUpdateInspectionQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateInspectionQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInspectionQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInspectionQuestionMutation, { data, loading, error }] = useUpdateInspectionQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useUpdateInspectionQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInspectionQuestionMutation, UpdateInspectionQuestionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInspectionQuestionMutation, UpdateInspectionQuestionMutationVariables>(
    UpdateInspectionQuestionDocument,
    options
  );
}
export type UpdateInspectionQuestionMutationHookResult = ReturnType<typeof useUpdateInspectionQuestionMutation>;
export type UpdateInspectionQuestionMutationResult = Apollo.MutationResult<UpdateInspectionQuestionMutation>;
export type UpdateInspectionQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateInspectionQuestionMutation,
  UpdateInspectionQuestionMutationVariables
>;
export const CreateMemberGroupDocument = gql`
  mutation CreateMemberGroup($memberGroup: MemberGroupCreateInput) {
    createMemberGroup(memberGroup: $memberGroup) {
      id
    }
  }
`;
export type CreateMemberGroupMutationFn = Apollo.MutationFunction<
  CreateMemberGroupMutation,
  CreateMemberGroupMutationVariables
>;

/**
 * __useCreateMemberGroupMutation__
 *
 * To run a mutation, you first call `useCreateMemberGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberGroupMutation, { data, loading, error }] = useCreateMemberGroupMutation({
 *   variables: {
 *      memberGroup: // value for 'memberGroup'
 *   },
 * });
 */
export function useCreateMemberGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMemberGroupMutation, CreateMemberGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberGroupMutation, CreateMemberGroupMutationVariables>(
    CreateMemberGroupDocument,
    options
  );
}
export type CreateMemberGroupMutationHookResult = ReturnType<typeof useCreateMemberGroupMutation>;
export type CreateMemberGroupMutationResult = Apollo.MutationResult<CreateMemberGroupMutation>;
export type CreateMemberGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberGroupMutation,
  CreateMemberGroupMutationVariables
>;
export const UpdateMemberGroupDocument = gql`
  mutation UpdateMemberGroup($memberGroup: MemberGroupUpdateInput) {
    updateMemberGroup(memberGroup: $memberGroup) {
      id
    }
  }
`;
export type UpdateMemberGroupMutationFn = Apollo.MutationFunction<
  UpdateMemberGroupMutation,
  UpdateMemberGroupMutationVariables
>;

/**
 * __useUpdateMemberGroupMutation__
 *
 * To run a mutation, you first call `useUpdateMemberGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberGroupMutation, { data, loading, error }] = useUpdateMemberGroupMutation({
 *   variables: {
 *      memberGroup: // value for 'memberGroup'
 *   },
 * });
 */
export function useUpdateMemberGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberGroupMutation, UpdateMemberGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberGroupMutation, UpdateMemberGroupMutationVariables>(
    UpdateMemberGroupDocument,
    options
  );
}
export type UpdateMemberGroupMutationHookResult = ReturnType<typeof useUpdateMemberGroupMutation>;
export type UpdateMemberGroupMutationResult = Apollo.MutationResult<UpdateMemberGroupMutation>;
export type UpdateMemberGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberGroupMutation,
  UpdateMemberGroupMutationVariables
>;
export const CreateMemberLockDocument = gql`
  mutation CreateMemberLock($lock: MemberLockCreateInput) {
    createMemberLock(memberLock: $lock) {
      id
      effectiveDate
      memberId
      createdOn
    }
  }
`;
export type CreateMemberLockMutationFn = Apollo.MutationFunction<
  CreateMemberLockMutation,
  CreateMemberLockMutationVariables
>;

/**
 * __useCreateMemberLockMutation__
 *
 * To run a mutation, you first call `useCreateMemberLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberLockMutation, { data, loading, error }] = useCreateMemberLockMutation({
 *   variables: {
 *      lock: // value for 'lock'
 *   },
 * });
 */
export function useCreateMemberLockMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMemberLockMutation, CreateMemberLockMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberLockMutation, CreateMemberLockMutationVariables>(
    CreateMemberLockDocument,
    options
  );
}
export type CreateMemberLockMutationHookResult = ReturnType<typeof useCreateMemberLockMutation>;
export type CreateMemberLockMutationResult = Apollo.MutationResult<CreateMemberLockMutation>;
export type CreateMemberLockMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberLockMutation,
  CreateMemberLockMutationVariables
>;
export const UpdateMemberLockDocument = gql`
  mutation UpdateMemberLock($lock: MemberLockUpdateInput) {
    updateMemberLock(memberLock: $lock) {
      id
      effectiveDate
      memberId
      createdOn
    }
  }
`;
export type UpdateMemberLockMutationFn = Apollo.MutationFunction<
  UpdateMemberLockMutation,
  UpdateMemberLockMutationVariables
>;

/**
 * __useUpdateMemberLockMutation__
 *
 * To run a mutation, you first call `useUpdateMemberLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberLockMutation, { data, loading, error }] = useUpdateMemberLockMutation({
 *   variables: {
 *      lock: // value for 'lock'
 *   },
 * });
 */
export function useUpdateMemberLockMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberLockMutation, UpdateMemberLockMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberLockMutation, UpdateMemberLockMutationVariables>(
    UpdateMemberLockDocument,
    options
  );
}
export type UpdateMemberLockMutationHookResult = ReturnType<typeof useUpdateMemberLockMutation>;
export type UpdateMemberLockMutationResult = Apollo.MutationResult<UpdateMemberLockMutation>;
export type UpdateMemberLockMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberLockMutation,
  UpdateMemberLockMutationVariables
>;
export const CreateMemberDocument = gql`
  mutation CreateMember($member: MemberCreateInput) {
    createMember(member: $member) {
      id
      firstName
      lastName
      username
    }
  }
`;
export type CreateMemberMutationFn = Apollo.MutationFunction<CreateMemberMutation, CreateMemberMutationVariables>;

/**
 * __useCreateMemberMutation__
 *
 * To run a mutation, you first call `useCreateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberMutation, { data, loading, error }] = useCreateMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useCreateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMemberMutation, CreateMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberMutation, CreateMemberMutationVariables>(CreateMemberDocument, options);
}
export type CreateMemberMutationHookResult = ReturnType<typeof useCreateMemberMutation>;
export type CreateMemberMutationResult = Apollo.MutationResult<CreateMemberMutation>;
export type CreateMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberMutation,
  CreateMemberMutationVariables
>;
export const UpdateMemberDocument = gql`
  mutation updateMember($member: MemberUpdateInput) {
    updateMember(member: $member) {
      id
      firstName
      lastName
      username
    }
  }
`;
export type UpdateMemberMutationFn = Apollo.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useUpdateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, options);
}
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = Apollo.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberMutation,
  UpdateMemberMutationVariables
>;
export const UpdateActiveMemberDocument = gql`
  mutation UpdateActiveMember($member: MemberUpdateInput) {
    updateMember(member: $member) {
      ...AuthMember
    }
  }
  ${AuthMemberFragmentDoc}
`;
export type UpdateActiveMemberMutationFn = Apollo.MutationFunction<
  UpdateActiveMemberMutation,
  UpdateActiveMemberMutationVariables
>;

/**
 * __useUpdateActiveMemberMutation__
 *
 * To run a mutation, you first call `useUpdateActiveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveMemberMutation, { data, loading, error }] = useUpdateActiveMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useUpdateActiveMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateActiveMemberMutation, UpdateActiveMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateActiveMemberMutation, UpdateActiveMemberMutationVariables>(
    UpdateActiveMemberDocument,
    options
  );
}
export type UpdateActiveMemberMutationHookResult = ReturnType<typeof useUpdateActiveMemberMutation>;
export type UpdateActiveMemberMutationResult = Apollo.MutationResult<UpdateActiveMemberMutation>;
export type UpdateActiveMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateActiveMemberMutation,
  UpdateActiveMemberMutationVariables
>;
export const CreateMemberOvertimePeriodDocument = gql`
  mutation createMemberOvertimePeriod($memberOvertimePeriod: MemberOvertimePeriodCreateInput) {
    createMemberOvertimePeriod(memberOvertimePeriod: $memberOvertimePeriod) {
      id
      memberId
      changeDate
      adhereToOrg
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      createdOn
      deletedOn
    }
  }
`;
export type CreateMemberOvertimePeriodMutationFn = Apollo.MutationFunction<
  CreateMemberOvertimePeriodMutation,
  CreateMemberOvertimePeriodMutationVariables
>;

/**
 * __useCreateMemberOvertimePeriodMutation__
 *
 * To run a mutation, you first call `useCreateMemberOvertimePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberOvertimePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberOvertimePeriodMutation, { data, loading, error }] = useCreateMemberOvertimePeriodMutation({
 *   variables: {
 *      memberOvertimePeriod: // value for 'memberOvertimePeriod'
 *   },
 * });
 */
export function useCreateMemberOvertimePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberOvertimePeriodMutation,
    CreateMemberOvertimePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberOvertimePeriodMutation, CreateMemberOvertimePeriodMutationVariables>(
    CreateMemberOvertimePeriodDocument,
    options
  );
}
export type CreateMemberOvertimePeriodMutationHookResult = ReturnType<typeof useCreateMemberOvertimePeriodMutation>;
export type CreateMemberOvertimePeriodMutationResult = Apollo.MutationResult<CreateMemberOvertimePeriodMutation>;
export type CreateMemberOvertimePeriodMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberOvertimePeriodMutation,
  CreateMemberOvertimePeriodMutationVariables
>;
export const UpdateMemberOvertimePeriodDocument = gql`
  mutation updateMemberOvertimePeriod($memberOvertimePeriod: MemberOvertimePeriodUpdateInput) {
    updateMemberOvertimePeriod(memberOvertimePeriod: $memberOvertimePeriod) {
      id
      memberId
      changeDate
      adhereToOrg
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      createdOn
      deletedOn
    }
  }
`;
export type UpdateMemberOvertimePeriodMutationFn = Apollo.MutationFunction<
  UpdateMemberOvertimePeriodMutation,
  UpdateMemberOvertimePeriodMutationVariables
>;

/**
 * __useUpdateMemberOvertimePeriodMutation__
 *
 * To run a mutation, you first call `useUpdateMemberOvertimePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberOvertimePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberOvertimePeriodMutation, { data, loading, error }] = useUpdateMemberOvertimePeriodMutation({
 *   variables: {
 *      memberOvertimePeriod: // value for 'memberOvertimePeriod'
 *   },
 * });
 */
export function useUpdateMemberOvertimePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberOvertimePeriodMutation,
    UpdateMemberOvertimePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberOvertimePeriodMutation, UpdateMemberOvertimePeriodMutationVariables>(
    UpdateMemberOvertimePeriodDocument,
    options
  );
}
export type UpdateMemberOvertimePeriodMutationHookResult = ReturnType<typeof useUpdateMemberOvertimePeriodMutation>;
export type UpdateMemberOvertimePeriodMutationResult = Apollo.MutationResult<UpdateMemberOvertimePeriodMutation>;
export type UpdateMemberOvertimePeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberOvertimePeriodMutation,
  UpdateMemberOvertimePeriodMutationVariables
>;
export const CreateMemberSignInAnswerDocument = gql`
  mutation CreateMemberSignInAnswer($answer: MemberSignInAnswerCreateInput) {
    createMemberSignInAnswer(answer: $answer) {
      id
    }
  }
`;
export type CreateMemberSignInAnswerMutationFn = Apollo.MutationFunction<
  CreateMemberSignInAnswerMutation,
  CreateMemberSignInAnswerMutationVariables
>;

/**
 * __useCreateMemberSignInAnswerMutation__
 *
 * To run a mutation, you first call `useCreateMemberSignInAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberSignInAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberSignInAnswerMutation, { data, loading, error }] = useCreateMemberSignInAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useCreateMemberSignInAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMemberSignInAnswerMutation, CreateMemberSignInAnswerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberSignInAnswerMutation, CreateMemberSignInAnswerMutationVariables>(
    CreateMemberSignInAnswerDocument,
    options
  );
}
export type CreateMemberSignInAnswerMutationHookResult = ReturnType<typeof useCreateMemberSignInAnswerMutation>;
export type CreateMemberSignInAnswerMutationResult = Apollo.MutationResult<CreateMemberSignInAnswerMutation>;
export type CreateMemberSignInAnswerMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberSignInAnswerMutation,
  CreateMemberSignInAnswerMutationVariables
>;
export const UpdateMemberSignInAnswerDocument = gql`
  mutation UpdateMemberSignInAnswer($answer: MemberSignInAnswerUpdateInput) {
    updateMemberSignInAnswer(answer: $answer) {
      id
      flagged
      resolverId
    }
  }
`;
export type UpdateMemberSignInAnswerMutationFn = Apollo.MutationFunction<
  UpdateMemberSignInAnswerMutation,
  UpdateMemberSignInAnswerMutationVariables
>;

/**
 * __useUpdateMemberSignInAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateMemberSignInAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberSignInAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberSignInAnswerMutation, { data, loading, error }] = useUpdateMemberSignInAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useUpdateMemberSignInAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberSignInAnswerMutation, UpdateMemberSignInAnswerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberSignInAnswerMutation, UpdateMemberSignInAnswerMutationVariables>(
    UpdateMemberSignInAnswerDocument,
    options
  );
}
export type UpdateMemberSignInAnswerMutationHookResult = ReturnType<typeof useUpdateMemberSignInAnswerMutation>;
export type UpdateMemberSignInAnswerMutationResult = Apollo.MutationResult<UpdateMemberSignInAnswerMutation>;
export type UpdateMemberSignInAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberSignInAnswerMutation,
  UpdateMemberSignInAnswerMutationVariables
>;
export const CreateMemberSignInSubmissionDocument = gql`
  mutation CreateMemberSignInSubmission($submission: MemberSignInSubmissionCreateInput) {
    createMemberSignInSubmission(submission: $submission) {
      id
    }
  }
`;
export type CreateMemberSignInSubmissionMutationFn = Apollo.MutationFunction<
  CreateMemberSignInSubmissionMutation,
  CreateMemberSignInSubmissionMutationVariables
>;

/**
 * __useCreateMemberSignInSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateMemberSignInSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberSignInSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberSignInSubmissionMutation, { data, loading, error }] = useCreateMemberSignInSubmissionMutation({
 *   variables: {
 *      submission: // value for 'submission'
 *   },
 * });
 */
export function useCreateMemberSignInSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberSignInSubmissionMutation,
    CreateMemberSignInSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberSignInSubmissionMutation, CreateMemberSignInSubmissionMutationVariables>(
    CreateMemberSignInSubmissionDocument,
    options
  );
}
export type CreateMemberSignInSubmissionMutationHookResult = ReturnType<typeof useCreateMemberSignInSubmissionMutation>;
export type CreateMemberSignInSubmissionMutationResult = Apollo.MutationResult<CreateMemberSignInSubmissionMutation>;
export type CreateMemberSignInSubmissionMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberSignInSubmissionMutation,
  CreateMemberSignInSubmissionMutationVariables
>;
export const UpdateMemberSignInSubmissionDocument = gql`
  mutation UpdateMemberSignInSubmission($submission: MemberSignInSubmissionUpdateInput) {
    updateMemberSignInSubmission(submission: $submission) {
      id
    }
  }
`;
export type UpdateMemberSignInSubmissionMutationFn = Apollo.MutationFunction<
  UpdateMemberSignInSubmissionMutation,
  UpdateMemberSignInSubmissionMutationVariables
>;

/**
 * __useUpdateMemberSignInSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateMemberSignInSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberSignInSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberSignInSubmissionMutation, { data, loading, error }] = useUpdateMemberSignInSubmissionMutation({
 *   variables: {
 *      submission: // value for 'submission'
 *   },
 * });
 */
export function useUpdateMemberSignInSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberSignInSubmissionMutation,
    UpdateMemberSignInSubmissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberSignInSubmissionMutation, UpdateMemberSignInSubmissionMutationVariables>(
    UpdateMemberSignInSubmissionDocument,
    options
  );
}
export type UpdateMemberSignInSubmissionMutationHookResult = ReturnType<typeof useUpdateMemberSignInSubmissionMutation>;
export type UpdateMemberSignInSubmissionMutationResult = Apollo.MutationResult<UpdateMemberSignInSubmissionMutation>;
export type UpdateMemberSignInSubmissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberSignInSubmissionMutation,
  UpdateMemberSignInSubmissionMutationVariables
>;
export const CreateMemberSignOffAnswerDocument = gql`
  mutation CreateMemberSignOffAnswer($answer: MemberSignOffAnswerCreateInput) {
    createMemberSignOffAnswer(answer: $answer) {
      id
      answer
      audienceType
      flagged
      memberId
      submissionId
      createdOn
      localTime
      questionTitle
      questionDescription
      questionPrompt
      description
    }
  }
`;
export type CreateMemberSignOffAnswerMutationFn = Apollo.MutationFunction<
  CreateMemberSignOffAnswerMutation,
  CreateMemberSignOffAnswerMutationVariables
>;

/**
 * __useCreateMemberSignOffAnswerMutation__
 *
 * To run a mutation, you first call `useCreateMemberSignOffAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberSignOffAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberSignOffAnswerMutation, { data, loading, error }] = useCreateMemberSignOffAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useCreateMemberSignOffAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMemberSignOffAnswerMutation,
    CreateMemberSignOffAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberSignOffAnswerMutation, CreateMemberSignOffAnswerMutationVariables>(
    CreateMemberSignOffAnswerDocument,
    options
  );
}
export type CreateMemberSignOffAnswerMutationHookResult = ReturnType<typeof useCreateMemberSignOffAnswerMutation>;
export type CreateMemberSignOffAnswerMutationResult = Apollo.MutationResult<CreateMemberSignOffAnswerMutation>;
export type CreateMemberSignOffAnswerMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberSignOffAnswerMutation,
  CreateMemberSignOffAnswerMutationVariables
>;
export const UpdateMemberSignOffAnswerDocument = gql`
  mutation UpdateMemberSignOffAnswer($answer: MemberSignOffAnswerUpdateInput) {
    updateMemberSignOffAnswer(answer: $answer) {
      id
      flagged
      resolverId
    }
  }
`;
export type UpdateMemberSignOffAnswerMutationFn = Apollo.MutationFunction<
  UpdateMemberSignOffAnswerMutation,
  UpdateMemberSignOffAnswerMutationVariables
>;

/**
 * __useUpdateMemberSignOffAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateMemberSignOffAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberSignOffAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberSignOffAnswerMutation, { data, loading, error }] = useUpdateMemberSignOffAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useUpdateMemberSignOffAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberSignOffAnswerMutation,
    UpdateMemberSignOffAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberSignOffAnswerMutation, UpdateMemberSignOffAnswerMutationVariables>(
    UpdateMemberSignOffAnswerDocument,
    options
  );
}
export type UpdateMemberSignOffAnswerMutationHookResult = ReturnType<typeof useUpdateMemberSignOffAnswerMutation>;
export type UpdateMemberSignOffAnswerMutationResult = Apollo.MutationResult<UpdateMemberSignOffAnswerMutation>;
export type UpdateMemberSignOffAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberSignOffAnswerMutation,
  UpdateMemberSignOffAnswerMutationVariables
>;
export const CreateMemberTimeDocumentDocument = gql`
  mutation CreateMemberTimeDocument($timeDocument: MemberTimeDocumentCreateInput) {
    createMemberTimeDocument(memberTimeDocument: $timeDocument) {
      id
    }
  }
`;
export type CreateMemberTimeDocumentMutationFn = Apollo.MutationFunction<
  CreateMemberTimeDocumentMutation,
  CreateMemberTimeDocumentMutationVariables
>;

/**
 * __useCreateMemberTimeDocumentMutation__
 *
 * To run a mutation, you first call `useCreateMemberTimeDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberTimeDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberTimeDocumentMutation, { data, loading, error }] = useCreateMemberTimeDocumentMutation({
 *   variables: {
 *      timeDocument: // value for 'timeDocument'
 *   },
 * });
 */
export function useCreateMemberTimeDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMemberTimeDocumentMutation, CreateMemberTimeDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberTimeDocumentMutation, CreateMemberTimeDocumentMutationVariables>(
    CreateMemberTimeDocumentDocument,
    options
  );
}
export type CreateMemberTimeDocumentMutationHookResult = ReturnType<typeof useCreateMemberTimeDocumentMutation>;
export type CreateMemberTimeDocumentMutationResult = Apollo.MutationResult<CreateMemberTimeDocumentMutation>;
export type CreateMemberTimeDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberTimeDocumentMutation,
  CreateMemberTimeDocumentMutationVariables
>;
export const UpdateMemberTimeDocumentDocument = gql`
  mutation UpdateMemberTimeDocument($timeDocument: MemberTimeDocumentUpdateInput) {
    updateMemberTimeDocument(memberTimeDocument: $timeDocument) {
      id
    }
  }
`;
export type UpdateMemberTimeDocumentMutationFn = Apollo.MutationFunction<
  UpdateMemberTimeDocumentMutation,
  UpdateMemberTimeDocumentMutationVariables
>;

/**
 * __useUpdateMemberTimeDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateMemberTimeDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberTimeDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberTimeDocumentMutation, { data, loading, error }] = useUpdateMemberTimeDocumentMutation({
 *   variables: {
 *      timeDocument: // value for 'timeDocument'
 *   },
 * });
 */
export function useUpdateMemberTimeDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMemberTimeDocumentMutation, UpdateMemberTimeDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMemberTimeDocumentMutation, UpdateMemberTimeDocumentMutationVariables>(
    UpdateMemberTimeDocumentDocument,
    options
  );
}
export type UpdateMemberTimeDocumentMutationHookResult = ReturnType<typeof useUpdateMemberTimeDocumentMutation>;
export type UpdateMemberTimeDocumentMutationResult = Apollo.MutationResult<UpdateMemberTimeDocumentMutation>;
export type UpdateMemberTimeDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberTimeDocumentMutation,
  UpdateMemberTimeDocumentMutationVariables
>;
export const OrganizationInfoDocument = gql`
  mutation OrganizationInfo($organizationInfo: OrganizationInfoUpdateInput) {
    updateOrganizationInfo(organizationInfo: $organizationInfo) {
      id
      organizationId
      longitude
      latitude
      address1
      address2
      city
      county
      state
      postalCode
      country
      phone
      trade
      currencyCode
      employeeMin
      employeeMax
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
export type OrganizationInfoMutationFn = Apollo.MutationFunction<
  OrganizationInfoMutation,
  OrganizationInfoMutationVariables
>;

/**
 * __useOrganizationInfoMutation__
 *
 * To run a mutation, you first call `useOrganizationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationInfoMutation, { data, loading, error }] = useOrganizationInfoMutation({
 *   variables: {
 *      organizationInfo: // value for 'organizationInfo'
 *   },
 * });
 */
export function useOrganizationInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<OrganizationInfoMutation, OrganizationInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrganizationInfoMutation, OrganizationInfoMutationVariables>(
    OrganizationInfoDocument,
    options
  );
}
export type OrganizationInfoMutationHookResult = ReturnType<typeof useOrganizationInfoMutation>;
export type OrganizationInfoMutationResult = Apollo.MutationResult<OrganizationInfoMutation>;
export type OrganizationInfoMutationOptions = Apollo.BaseMutationOptions<
  OrganizationInfoMutation,
  OrganizationInfoMutationVariables
>;
export const OrganizationDocument = gql`
  mutation Organization($organization: OrganizationUpdateInput) {
    updateOrganization(organization: $organization) {
      ...AuthOrganization
    }
  }
  ${AuthOrganizationFragmentDoc}
`;
export type OrganizationMutationFn = Apollo.MutationFunction<OrganizationMutation, OrganizationMutationVariables>;

/**
 * __useOrganizationMutation__
 *
 * To run a mutation, you first call `useOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationMutation, { data, loading, error }] = useOrganizationMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<OrganizationMutation, OrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrganizationMutation, OrganizationMutationVariables>(OrganizationDocument, options);
}
export type OrganizationMutationHookResult = ReturnType<typeof useOrganizationMutation>;
export type OrganizationMutationResult = Apollo.MutationResult<OrganizationMutation>;
export type OrganizationMutationOptions = Apollo.BaseMutationOptions<
  OrganizationMutation,
  OrganizationMutationVariables
>;
export const OrganizationNotificationPreferencesCreateMutationDocument = gql`
  mutation OrganizationNotificationPreferencesCreateMutation(
    $organizationNotificationPreferencesCreateInput: OrganizationNotificationPreferencesCreateInput!
  ) {
    createOrganizationNotificationPreferences(
      notificationPreferences: $organizationNotificationPreferencesCreateInput
    ) {
      id
      memberId
      injuryReport
      inaccurateTimeReport
      breakReport
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;
export type OrganizationNotificationPreferencesCreateMutationMutationFn = Apollo.MutationFunction<
  OrganizationNotificationPreferencesCreateMutationMutation,
  OrganizationNotificationPreferencesCreateMutationMutationVariables
>;

/**
 * __useOrganizationNotificationPreferencesCreateMutationMutation__
 *
 * To run a mutation, you first call `useOrganizationNotificationPreferencesCreateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNotificationPreferencesCreateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationNotificationPreferencesCreateMutationMutation, { data, loading, error }] = useOrganizationNotificationPreferencesCreateMutationMutation({
 *   variables: {
 *      organizationNotificationPreferencesCreateInput: // value for 'organizationNotificationPreferencesCreateInput'
 *   },
 * });
 */
export function useOrganizationNotificationPreferencesCreateMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationNotificationPreferencesCreateMutationMutation,
    OrganizationNotificationPreferencesCreateMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationNotificationPreferencesCreateMutationMutation,
    OrganizationNotificationPreferencesCreateMutationMutationVariables
  >(OrganizationNotificationPreferencesCreateMutationDocument, options);
}
export type OrganizationNotificationPreferencesCreateMutationMutationHookResult = ReturnType<
  typeof useOrganizationNotificationPreferencesCreateMutationMutation
>;
export type OrganizationNotificationPreferencesCreateMutationMutationResult =
  Apollo.MutationResult<OrganizationNotificationPreferencesCreateMutationMutation>;
export type OrganizationNotificationPreferencesCreateMutationMutationOptions = Apollo.BaseMutationOptions<
  OrganizationNotificationPreferencesCreateMutationMutation,
  OrganizationNotificationPreferencesCreateMutationMutationVariables
>;
export const OrganizationNotificationPreferencesUpdateMutationDocument = gql`
  mutation OrganizationNotificationPreferencesUpdateMutation(
    $organizationNotificationPreferencesUpdateInput: OrganizationNotificationPreferencesUpdateInput!
  ) {
    updateOrganizationNotificationPreferences(
      notificationPreferences: $organizationNotificationPreferencesUpdateInput
    ) {
      id
      memberId
      injuryReport
      inaccurateTimeReport
      breakReport
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;
export type OrganizationNotificationPreferencesUpdateMutationMutationFn = Apollo.MutationFunction<
  OrganizationNotificationPreferencesUpdateMutationMutation,
  OrganizationNotificationPreferencesUpdateMutationMutationVariables
>;

/**
 * __useOrganizationNotificationPreferencesUpdateMutationMutation__
 *
 * To run a mutation, you first call `useOrganizationNotificationPreferencesUpdateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNotificationPreferencesUpdateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationNotificationPreferencesUpdateMutationMutation, { data, loading, error }] = useOrganizationNotificationPreferencesUpdateMutationMutation({
 *   variables: {
 *      organizationNotificationPreferencesUpdateInput: // value for 'organizationNotificationPreferencesUpdateInput'
 *   },
 * });
 */
export function useOrganizationNotificationPreferencesUpdateMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationNotificationPreferencesUpdateMutationMutation,
    OrganizationNotificationPreferencesUpdateMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationNotificationPreferencesUpdateMutationMutation,
    OrganizationNotificationPreferencesUpdateMutationMutationVariables
  >(OrganizationNotificationPreferencesUpdateMutationDocument, options);
}
export type OrganizationNotificationPreferencesUpdateMutationMutationHookResult = ReturnType<
  typeof useOrganizationNotificationPreferencesUpdateMutationMutation
>;
export type OrganizationNotificationPreferencesUpdateMutationMutationResult =
  Apollo.MutationResult<OrganizationNotificationPreferencesUpdateMutationMutation>;
export type OrganizationNotificationPreferencesUpdateMutationMutationOptions = Apollo.BaseMutationOptions<
  OrganizationNotificationPreferencesUpdateMutationMutation,
  OrganizationNotificationPreferencesUpdateMutationMutationVariables
>;
export const CreateOrganizationOvertimePeriodDocument = gql`
  mutation createOrganizationOvertimePeriod($organizationOvertimePeriod: OrganizationOvertimePeriodCreateInput) {
    createOrganizationOvertimePeriod(organizationOvertimePeriod: $organizationOvertimePeriod) {
      id
      organizationId
      startTime
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      lengthInDays
      createdOn
      deletedOn
    }
  }
`;
export type CreateOrganizationOvertimePeriodMutationFn = Apollo.MutationFunction<
  CreateOrganizationOvertimePeriodMutation,
  CreateOrganizationOvertimePeriodMutationVariables
>;

/**
 * __useCreateOrganizationOvertimePeriodMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationOvertimePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationOvertimePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationOvertimePeriodMutation, { data, loading, error }] = useCreateOrganizationOvertimePeriodMutation({
 *   variables: {
 *      organizationOvertimePeriod: // value for 'organizationOvertimePeriod'
 *   },
 * });
 */
export function useCreateOrganizationOvertimePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationOvertimePeriodMutation,
    CreateOrganizationOvertimePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationOvertimePeriodMutation,
    CreateOrganizationOvertimePeriodMutationVariables
  >(CreateOrganizationOvertimePeriodDocument, options);
}
export type CreateOrganizationOvertimePeriodMutationHookResult = ReturnType<
  typeof useCreateOrganizationOvertimePeriodMutation
>;
export type CreateOrganizationOvertimePeriodMutationResult =
  Apollo.MutationResult<CreateOrganizationOvertimePeriodMutation>;
export type CreateOrganizationOvertimePeriodMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationOvertimePeriodMutation,
  CreateOrganizationOvertimePeriodMutationVariables
>;
export const UpdateOrganizationOvertimePeriodDocument = gql`
  mutation updateOrganizationOvertimePeriod($organizationOvertimePeriod: OrganizationOvertimePeriodUpdateInput) {
    updateOrganizationOvertimePeriod(organizationOvertimePeriod: $organizationOvertimePeriod) {
      id
      organizationId
      startTime
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      lengthInDays
      createdOn
      deletedOn
    }
  }
`;
export type UpdateOrganizationOvertimePeriodMutationFn = Apollo.MutationFunction<
  UpdateOrganizationOvertimePeriodMutation,
  UpdateOrganizationOvertimePeriodMutationVariables
>;

/**
 * __useUpdateOrganizationOvertimePeriodMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationOvertimePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationOvertimePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationOvertimePeriodMutation, { data, loading, error }] = useUpdateOrganizationOvertimePeriodMutation({
 *   variables: {
 *      organizationOvertimePeriod: // value for 'organizationOvertimePeriod'
 *   },
 * });
 */
export function useUpdateOrganizationOvertimePeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationOvertimePeriodMutation,
    UpdateOrganizationOvertimePeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationOvertimePeriodMutation,
    UpdateOrganizationOvertimePeriodMutationVariables
  >(UpdateOrganizationOvertimePeriodDocument, options);
}
export type UpdateOrganizationOvertimePeriodMutationHookResult = ReturnType<
  typeof useUpdateOrganizationOvertimePeriodMutation
>;
export type UpdateOrganizationOvertimePeriodMutationResult =
  Apollo.MutationResult<UpdateOrganizationOvertimePeriodMutation>;
export type UpdateOrganizationOvertimePeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationOvertimePeriodMutation,
  UpdateOrganizationOvertimePeriodMutationVariables
>;
export const CreateOrganizationPayPeriodDocument = gql`
  mutation createOrganizationPayPeriod($organizationPayPeriod: OrganizationPayPeriodCreateInput) {
    createOrganizationPayPeriod(organizationPayPeriod: $organizationPayPeriod) {
      id
      organizationId
      startDate
      payPeriodType
      dayOne
      dayTwo
      createdOn
      deletedOn
    }
  }
`;
export type CreateOrganizationPayPeriodMutationFn = Apollo.MutationFunction<
  CreateOrganizationPayPeriodMutation,
  CreateOrganizationPayPeriodMutationVariables
>;

/**
 * __useCreateOrganizationPayPeriodMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationPayPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationPayPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationPayPeriodMutation, { data, loading, error }] = useCreateOrganizationPayPeriodMutation({
 *   variables: {
 *      organizationPayPeriod: // value for 'organizationPayPeriod'
 *   },
 * });
 */
export function useCreateOrganizationPayPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationPayPeriodMutation,
    CreateOrganizationPayPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationPayPeriodMutation, CreateOrganizationPayPeriodMutationVariables>(
    CreateOrganizationPayPeriodDocument,
    options
  );
}
export type CreateOrganizationPayPeriodMutationHookResult = ReturnType<typeof useCreateOrganizationPayPeriodMutation>;
export type CreateOrganizationPayPeriodMutationResult = Apollo.MutationResult<CreateOrganizationPayPeriodMutation>;
export type CreateOrganizationPayPeriodMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationPayPeriodMutation,
  CreateOrganizationPayPeriodMutationVariables
>;
export const UpdateOrganizationPayPeriodDocument = gql`
  mutation updateOrganizationPayPeriod($organizationPayPeriod: OrganizationPayPeriodUpdateInput) {
    updateOrganizationPayPeriod(organizationPayPeriod: $organizationPayPeriod) {
      id
      organizationId
      startDate
      payPeriodType
      dayOne
      dayTwo
      createdOn
      deletedOn
    }
  }
`;
export type UpdateOrganizationPayPeriodMutationFn = Apollo.MutationFunction<
  UpdateOrganizationPayPeriodMutation,
  UpdateOrganizationPayPeriodMutationVariables
>;

/**
 * __useUpdateOrganizationPayPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPayPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPayPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPayPeriodMutation, { data, loading, error }] = useUpdateOrganizationPayPeriodMutation({
 *   variables: {
 *      organizationPayPeriod: // value for 'organizationPayPeriod'
 *   },
 * });
 */
export function useUpdateOrganizationPayPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationPayPeriodMutation,
    UpdateOrganizationPayPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationPayPeriodMutation, UpdateOrganizationPayPeriodMutationVariables>(
    UpdateOrganizationPayPeriodDocument,
    options
  );
}
export type UpdateOrganizationPayPeriodMutationHookResult = ReturnType<typeof useUpdateOrganizationPayPeriodMutation>;
export type UpdateOrganizationPayPeriodMutationResult = Apollo.MutationResult<UpdateOrganizationPayPeriodMutation>;
export type UpdateOrganizationPayPeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationPayPeriodMutation,
  UpdateOrganizationPayPeriodMutationVariables
>;
export const CreatePositionDocument = gql`
  mutation CreatePosition($position: PositionCreateInput) {
    createPosition(position: $position) {
      canEditPosition
      canManageProjectCostCodeQuantity
      createdOn
      dailyProjectPrompts
      dailyProjectPromptsUseSubprojects
      deletedOn
      equipmentSelectionRequirement
      id
      level
      manageBudgets
      manageBulletinPosts
      manageCompanySettings
      manageCostCodes
      manageEmployeeGroups
      manageEmployees
      manageEquipment
      manageMediaCollection
      managePerDiem
      manageProjectGroups
      manageProjects
      manageTimeEntries
      manageGpsApproval
      memberLock
      organizationId
      requestSignatures
      scopeByGroup
      submittedOn
      timeEvents
      title
      updatedOn
      viewAllActivityReport
      viewBudgets
      viewEquipmentDetails
      viewEquipmentStatus
      viewMemberActivityReport
      viewMemberExpenses
      viewProjectExpenses
    }
  }
`;
export type CreatePositionMutationFn = Apollo.MutationFunction<CreatePositionMutation, CreatePositionMutationVariables>;

/**
 * __useCreatePositionMutation__
 *
 * To run a mutation, you first call `useCreatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPositionMutation, { data, loading, error }] = useCreatePositionMutation({
 *   variables: {
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreatePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePositionMutation, CreatePositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePositionMutation, CreatePositionMutationVariables>(CreatePositionDocument, options);
}
export type CreatePositionMutationHookResult = ReturnType<typeof useCreatePositionMutation>;
export type CreatePositionMutationResult = Apollo.MutationResult<CreatePositionMutation>;
export type CreatePositionMutationOptions = Apollo.BaseMutationOptions<
  CreatePositionMutation,
  CreatePositionMutationVariables
>;
export const UpdatePositionDocument = gql`
  mutation UpdatePosition($position: PositionUpdateInput) {
    updatePosition(position: $position) {
      canEditPosition
      canManageProjectCostCodeQuantity
      createdOn
      dailyProjectPrompts
      dailyProjectPromptsUseSubprojects
      deletedOn
      equipmentSelectionRequirement
      id
      level
      manageBudgets
      manageCompanySettings
      manageCostCodes
      manageEmployeeGroups
      manageEmployees
      manageEquipment
      manageMediaCollection
      managePerDiem
      manageProjectGroups
      manageProjects
      manageTimeEntries
      manageGpsApproval
      memberLock
      organizationId
      requestSignatures
      scopeByGroup
      submittedOn
      timeEvents
      title
      updatedOn
      viewAllActivityReport
      viewBudgets
      viewEquipmentDetails
      viewEquipmentStatus
      viewMemberActivityReport
      viewMemberExpenses
      viewProjectExpenses
    }
  }
`;
export type UpdatePositionMutationFn = Apollo.MutationFunction<UpdatePositionMutation, UpdatePositionMutationVariables>;

/**
 * __useUpdatePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionMutation, { data, loading, error }] = useUpdatePositionMutation({
 *   variables: {
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdatePositionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePositionMutation, UpdatePositionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePositionMutation, UpdatePositionMutationVariables>(UpdatePositionDocument, options);
}
export type UpdatePositionMutationHookResult = ReturnType<typeof useUpdatePositionMutation>;
export type UpdatePositionMutationResult = Apollo.MutationResult<UpdatePositionMutation>;
export type UpdatePositionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePositionMutation,
  UpdatePositionMutationVariables
>;
export const CreateProjectCostCodeQuantityDocument = gql`
  mutation CreateProjectCostCodeQuantity($projectCostCodeQuantity: ProjectCostCodeQuantityCreateInput!) {
    createProjectCostCodeQuantity(projectCostCodeQuantity: $projectCostCodeQuantity) {
      id
      amount
      assignedOn
      lastEditedByMember {
        id
        firstName
        lastName
      }
      costCode {
        id
        costCode
        title
        unitTitle
      }
      costCodeId
      createdOnLocal
      description
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
          archivedOn
        }
      }
      projectId
      submittedByMember {
        id
        firstName
        lastName
        memberGroupId
        position {
          id
          level
          canManageProjectCostCodeQuantity
          timeEvents
        }
      }
      submittedBy
      type
    }
  }
`;
export type CreateProjectCostCodeQuantityMutationFn = Apollo.MutationFunction<
  CreateProjectCostCodeQuantityMutation,
  CreateProjectCostCodeQuantityMutationVariables
>;

/**
 * __useCreateProjectCostCodeQuantityMutation__
 *
 * To run a mutation, you first call `useCreateProjectCostCodeQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectCostCodeQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectCostCodeQuantityMutation, { data, loading, error }] = useCreateProjectCostCodeQuantityMutation({
 *   variables: {
 *      projectCostCodeQuantity: // value for 'projectCostCodeQuantity'
 *   },
 * });
 */
export function useCreateProjectCostCodeQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectCostCodeQuantityMutation,
    CreateProjectCostCodeQuantityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectCostCodeQuantityMutation, CreateProjectCostCodeQuantityMutationVariables>(
    CreateProjectCostCodeQuantityDocument,
    options
  );
}
export type CreateProjectCostCodeQuantityMutationHookResult = ReturnType<
  typeof useCreateProjectCostCodeQuantityMutation
>;
export type CreateProjectCostCodeQuantityMutationResult = Apollo.MutationResult<CreateProjectCostCodeQuantityMutation>;
export type CreateProjectCostCodeQuantityMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectCostCodeQuantityMutation,
  CreateProjectCostCodeQuantityMutationVariables
>;
export const UpdateProjectCostCodeQuantityDocument = gql`
  mutation updateProjectCostCodeQuantity($projectCostCodeQuantity: ProjectCostCodeQuantityUpdateInput!) {
    updateProjectCostCodeQuantity(projectCostCodeQuantity: $projectCostCodeQuantity) {
      id
      amount
      assignedOn
      lastEditedByMember {
        id
        firstName
        lastName
      }
      costCode {
        id
        costCode
        title
        unitTitle
      }
      costCodeId
      createdOnLocal
      description
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
          archivedOn
        }
      }
      projectId
      submittedByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
      }
      submittedBy
      type
    }
  }
`;
export type UpdateProjectCostCodeQuantityMutationFn = Apollo.MutationFunction<
  UpdateProjectCostCodeQuantityMutation,
  UpdateProjectCostCodeQuantityMutationVariables
>;

/**
 * __useUpdateProjectCostCodeQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCostCodeQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCostCodeQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCostCodeQuantityMutation, { data, loading, error }] = useUpdateProjectCostCodeQuantityMutation({
 *   variables: {
 *      projectCostCodeQuantity: // value for 'projectCostCodeQuantity'
 *   },
 * });
 */
export function useUpdateProjectCostCodeQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectCostCodeQuantityMutation,
    UpdateProjectCostCodeQuantityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectCostCodeQuantityMutation, UpdateProjectCostCodeQuantityMutationVariables>(
    UpdateProjectCostCodeQuantityDocument,
    options
  );
}
export type UpdateProjectCostCodeQuantityMutationHookResult = ReturnType<
  typeof useUpdateProjectCostCodeQuantityMutation
>;
export type UpdateProjectCostCodeQuantityMutationResult = Apollo.MutationResult<UpdateProjectCostCodeQuantityMutation>;
export type UpdateProjectCostCodeQuantityMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectCostCodeQuantityMutation,
  UpdateProjectCostCodeQuantityMutationVariables
>;
export const CreateProjectGroupDocument = gql`
  mutation CreateProjectGroup($projectGroup: ProjectGroupCreateInput) {
    createProjectGroup(projectGroup: $projectGroup) {
      id
    }
  }
`;
export type CreateProjectGroupMutationFn = Apollo.MutationFunction<
  CreateProjectGroupMutation,
  CreateProjectGroupMutationVariables
>;

/**
 * __useCreateProjectGroupMutation__
 *
 * To run a mutation, you first call `useCreateProjectGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectGroupMutation, { data, loading, error }] = useCreateProjectGroupMutation({
 *   variables: {
 *      projectGroup: // value for 'projectGroup'
 *   },
 * });
 */
export function useCreateProjectGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectGroupMutation, CreateProjectGroupMutationVariables>(
    CreateProjectGroupDocument,
    options
  );
}
export type CreateProjectGroupMutationHookResult = ReturnType<typeof useCreateProjectGroupMutation>;
export type CreateProjectGroupMutationResult = Apollo.MutationResult<CreateProjectGroupMutation>;
export type CreateProjectGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectGroupMutation,
  CreateProjectGroupMutationVariables
>;
export const UpdateProjectGroupDocument = gql`
  mutation UpdateProjectGroup($projectGroup: ProjectGroupUpdateInput) {
    updateProjectGroup(projectGroup: $projectGroup) {
      id
    }
  }
`;
export type UpdateProjectGroupMutationFn = Apollo.MutationFunction<
  UpdateProjectGroupMutation,
  UpdateProjectGroupMutationVariables
>;

/**
 * __useUpdateProjectGroupMutation__
 *
 * To run a mutation, you first call `useUpdateProjectGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectGroupMutation, { data, loading, error }] = useUpdateProjectGroupMutation({
 *   variables: {
 *      projectGroup: // value for 'projectGroup'
 *   },
 * });
 */
export function useUpdateProjectGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectGroupMutation, UpdateProjectGroupMutationVariables>(
    UpdateProjectGroupDocument,
    options
  );
}
export type UpdateProjectGroupMutationHookResult = ReturnType<typeof useUpdateProjectGroupMutation>;
export type UpdateProjectGroupMutationResult = Apollo.MutationResult<UpdateProjectGroupMutation>;
export type UpdateProjectGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectGroupMutation,
  UpdateProjectGroupMutationVariables
>;
export const CreateProject3Document = gql`
  mutation CreateProject3($project: ProjectCreateInput) {
    createProject(project: $project) {
      id
      title
    }
  }
`;
export type CreateProject3MutationFn = Apollo.MutationFunction<CreateProject3Mutation, CreateProject3MutationVariables>;

/**
 * __useCreateProject3Mutation__
 *
 * To run a mutation, you first call `useCreateProject3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProject3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProject3Mutation, { data, loading, error }] = useCreateProject3Mutation({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateProject3Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProject3Mutation, CreateProject3MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProject3Mutation, CreateProject3MutationVariables>(CreateProject3Document, options);
}
export type CreateProject3MutationHookResult = ReturnType<typeof useCreateProject3Mutation>;
export type CreateProject3MutationResult = Apollo.MutationResult<CreateProject3Mutation>;
export type CreateProject3MutationOptions = Apollo.BaseMutationOptions<
  CreateProject3Mutation,
  CreateProject3MutationVariables
>;
export const UpdateProjectSimpleDocument = gql`
  mutation UpdateProjectSimple($project: ProjectUpdateInput) {
    updateProject(project: $project) {
      id
      title
    }
  }
`;
export type UpdateProjectSimpleMutationFn = Apollo.MutationFunction<
  UpdateProjectSimpleMutation,
  UpdateProjectSimpleMutationVariables
>;

/**
 * __useUpdateProjectSimpleMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSimpleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSimpleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSimpleMutation, { data, loading, error }] = useUpdateProjectSimpleMutation({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useUpdateProjectSimpleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectSimpleMutation, UpdateProjectSimpleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectSimpleMutation, UpdateProjectSimpleMutationVariables>(
    UpdateProjectSimpleDocument,
    options
  );
}
export type UpdateProjectSimpleMutationHookResult = ReturnType<typeof useUpdateProjectSimpleMutation>;
export type UpdateProjectSimpleMutationResult = Apollo.MutationResult<UpdateProjectSimpleMutation>;
export type UpdateProjectSimpleMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectSimpleMutation,
  UpdateProjectSimpleMutationVariables
>;
export const CreateRecurringInspectionAssignmentCategoryLinkDocument = gql`
  mutation CreateRecurringInspectionAssignmentCategoryLink(
    $assignmentCategoryLink: RecurringInspectionAssignmentCategoryLinkCreateInput
  ) {
    createRecurringInspectionAssignmentCategoryLink(assignmentCategoryLink: $assignmentCategoryLink) {
      ...RecurringInspectionAssignmentCategoryLinkFragment
    }
  }
  ${RecurringInspectionAssignmentCategoryLinkFragmentFragmentDoc}
`;
export type CreateRecurringInspectionAssignmentCategoryLinkMutationFn = Apollo.MutationFunction<
  CreateRecurringInspectionAssignmentCategoryLinkMutation,
  CreateRecurringInspectionAssignmentCategoryLinkMutationVariables
>;

/**
 * __useCreateRecurringInspectionAssignmentCategoryLinkMutation__
 *
 * To run a mutation, you first call `useCreateRecurringInspectionAssignmentCategoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringInspectionAssignmentCategoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringInspectionAssignmentCategoryLinkMutation, { data, loading, error }] = useCreateRecurringInspectionAssignmentCategoryLinkMutation({
 *   variables: {
 *      assignmentCategoryLink: // value for 'assignmentCategoryLink'
 *   },
 * });
 */
export function useCreateRecurringInspectionAssignmentCategoryLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecurringInspectionAssignmentCategoryLinkMutation,
    CreateRecurringInspectionAssignmentCategoryLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecurringInspectionAssignmentCategoryLinkMutation,
    CreateRecurringInspectionAssignmentCategoryLinkMutationVariables
  >(CreateRecurringInspectionAssignmentCategoryLinkDocument, options);
}
export type CreateRecurringInspectionAssignmentCategoryLinkMutationHookResult = ReturnType<
  typeof useCreateRecurringInspectionAssignmentCategoryLinkMutation
>;
export type CreateRecurringInspectionAssignmentCategoryLinkMutationResult =
  Apollo.MutationResult<CreateRecurringInspectionAssignmentCategoryLinkMutation>;
export type CreateRecurringInspectionAssignmentCategoryLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringInspectionAssignmentCategoryLinkMutation,
  CreateRecurringInspectionAssignmentCategoryLinkMutationVariables
>;
export const UpdateRecurringInspectionAssignmentCategoryLinkDocument = gql`
  mutation UpdateRecurringInspectionAssignmentCategoryLink(
    $assignmentCategoryLink: RecurringInspectionAssignmentCategoryLinkUpdateInput
  ) {
    updateRecurringInspectionAssignmentCategoryLink(assignmentCategoryLink: $assignmentCategoryLink) {
      ...RecurringInspectionAssignmentCategoryLinkFragment
    }
  }
  ${RecurringInspectionAssignmentCategoryLinkFragmentFragmentDoc}
`;
export type UpdateRecurringInspectionAssignmentCategoryLinkMutationFn = Apollo.MutationFunction<
  UpdateRecurringInspectionAssignmentCategoryLinkMutation,
  UpdateRecurringInspectionAssignmentCategoryLinkMutationVariables
>;

/**
 * __useUpdateRecurringInspectionAssignmentCategoryLinkMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringInspectionAssignmentCategoryLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringInspectionAssignmentCategoryLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringInspectionAssignmentCategoryLinkMutation, { data, loading, error }] = useUpdateRecurringInspectionAssignmentCategoryLinkMutation({
 *   variables: {
 *      assignmentCategoryLink: // value for 'assignmentCategoryLink'
 *   },
 * });
 */
export function useUpdateRecurringInspectionAssignmentCategoryLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecurringInspectionAssignmentCategoryLinkMutation,
    UpdateRecurringInspectionAssignmentCategoryLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecurringInspectionAssignmentCategoryLinkMutation,
    UpdateRecurringInspectionAssignmentCategoryLinkMutationVariables
  >(UpdateRecurringInspectionAssignmentCategoryLinkDocument, options);
}
export type UpdateRecurringInspectionAssignmentCategoryLinkMutationHookResult = ReturnType<
  typeof useUpdateRecurringInspectionAssignmentCategoryLinkMutation
>;
export type UpdateRecurringInspectionAssignmentCategoryLinkMutationResult =
  Apollo.MutationResult<UpdateRecurringInspectionAssignmentCategoryLinkMutation>;
export type UpdateRecurringInspectionAssignmentCategoryLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringInspectionAssignmentCategoryLinkMutation,
  UpdateRecurringInspectionAssignmentCategoryLinkMutationVariables
>;
export const CreateRecurringInspectionAssignmentDocument = gql`
  mutation CreateRecurringInspectionAssignment($assignment: RecurringInspectionAssignmentCreateInput) {
    createRecurringInspectionAssignment(assignment: $assignment) {
      ...RecurringInspectionAssignmentFragment
    }
  }
  ${RecurringInspectionAssignmentFragmentFragmentDoc}
`;
export type CreateRecurringInspectionAssignmentMutationFn = Apollo.MutationFunction<
  CreateRecurringInspectionAssignmentMutation,
  CreateRecurringInspectionAssignmentMutationVariables
>;

/**
 * __useCreateRecurringInspectionAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateRecurringInspectionAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringInspectionAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringInspectionAssignmentMutation, { data, loading, error }] = useCreateRecurringInspectionAssignmentMutation({
 *   variables: {
 *      assignment: // value for 'assignment'
 *   },
 * });
 */
export function useCreateRecurringInspectionAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecurringInspectionAssignmentMutation,
    CreateRecurringInspectionAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecurringInspectionAssignmentMutation,
    CreateRecurringInspectionAssignmentMutationVariables
  >(CreateRecurringInspectionAssignmentDocument, options);
}
export type CreateRecurringInspectionAssignmentMutationHookResult = ReturnType<
  typeof useCreateRecurringInspectionAssignmentMutation
>;
export type CreateRecurringInspectionAssignmentMutationResult =
  Apollo.MutationResult<CreateRecurringInspectionAssignmentMutation>;
export type CreateRecurringInspectionAssignmentMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringInspectionAssignmentMutation,
  CreateRecurringInspectionAssignmentMutationVariables
>;
export const UpdateRecurringInspectionAssignmentDocument = gql`
  mutation UpdateRecurringInspectionAssignment($assignment: RecurringInspectionAssignmentUpdateInput) {
    updateRecurringInspectionAssignment(assignment: $assignment) {
      ...RecurringInspectionAssignmentFragment
    }
  }
  ${RecurringInspectionAssignmentFragmentFragmentDoc}
`;
export type UpdateRecurringInspectionAssignmentMutationFn = Apollo.MutationFunction<
  UpdateRecurringInspectionAssignmentMutation,
  UpdateRecurringInspectionAssignmentMutationVariables
>;

/**
 * __useUpdateRecurringInspectionAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringInspectionAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringInspectionAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringInspectionAssignmentMutation, { data, loading, error }] = useUpdateRecurringInspectionAssignmentMutation({
 *   variables: {
 *      assignment: // value for 'assignment'
 *   },
 * });
 */
export function useUpdateRecurringInspectionAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecurringInspectionAssignmentMutation,
    UpdateRecurringInspectionAssignmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecurringInspectionAssignmentMutation,
    UpdateRecurringInspectionAssignmentMutationVariables
  >(UpdateRecurringInspectionAssignmentDocument, options);
}
export type UpdateRecurringInspectionAssignmentMutationHookResult = ReturnType<
  typeof useUpdateRecurringInspectionAssignmentMutation
>;
export type UpdateRecurringInspectionAssignmentMutationResult =
  Apollo.MutationResult<UpdateRecurringInspectionAssignmentMutation>;
export type UpdateRecurringInspectionAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringInspectionAssignmentMutation,
  UpdateRecurringInspectionAssignmentMutationVariables
>;
export const CreateRecurringInspectionAssignmentNotificationLinkDocument = gql`
  mutation CreateRecurringInspectionAssignmentNotificationLink(
    $assignmentNotificationLink: RecurringInspectionAssignmentNotificationLinkCreateInput
  ) {
    createRecurringInspectionAssignmentNotificationLink(assignmentNotificationLink: $assignmentNotificationLink) {
      ...RecurringInspectionAssignmentNotificationLinkFragment
    }
  }
  ${RecurringInspectionAssignmentNotificationLinkFragmentFragmentDoc}
`;
export type CreateRecurringInspectionAssignmentNotificationLinkMutationFn = Apollo.MutationFunction<
  CreateRecurringInspectionAssignmentNotificationLinkMutation,
  CreateRecurringInspectionAssignmentNotificationLinkMutationVariables
>;

/**
 * __useCreateRecurringInspectionAssignmentNotificationLinkMutation__
 *
 * To run a mutation, you first call `useCreateRecurringInspectionAssignmentNotificationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringInspectionAssignmentNotificationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringInspectionAssignmentNotificationLinkMutation, { data, loading, error }] = useCreateRecurringInspectionAssignmentNotificationLinkMutation({
 *   variables: {
 *      assignmentNotificationLink: // value for 'assignmentNotificationLink'
 *   },
 * });
 */
export function useCreateRecurringInspectionAssignmentNotificationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecurringInspectionAssignmentNotificationLinkMutation,
    CreateRecurringInspectionAssignmentNotificationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRecurringInspectionAssignmentNotificationLinkMutation,
    CreateRecurringInspectionAssignmentNotificationLinkMutationVariables
  >(CreateRecurringInspectionAssignmentNotificationLinkDocument, options);
}
export type CreateRecurringInspectionAssignmentNotificationLinkMutationHookResult = ReturnType<
  typeof useCreateRecurringInspectionAssignmentNotificationLinkMutation
>;
export type CreateRecurringInspectionAssignmentNotificationLinkMutationResult =
  Apollo.MutationResult<CreateRecurringInspectionAssignmentNotificationLinkMutation>;
export type CreateRecurringInspectionAssignmentNotificationLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringInspectionAssignmentNotificationLinkMutation,
  CreateRecurringInspectionAssignmentNotificationLinkMutationVariables
>;
export const UpdateRecurringInspectionASsignmentNotificationLinkDocument = gql`
  mutation UpdateRecurringInspectionASsignmentNotificationLink(
    $assignmentNotificationLink: RecurringInspectionAssignmentNotificationLinkUpdateInput
  ) {
    updateRecurringInspectionAssignmentNotificationLink(assignmentNotificationLink: $assignmentNotificationLink) {
      ...RecurringInspectionAssignmentNotificationLinkFragment
    }
  }
  ${RecurringInspectionAssignmentNotificationLinkFragmentFragmentDoc}
`;
export type UpdateRecurringInspectionASsignmentNotificationLinkMutationFn = Apollo.MutationFunction<
  UpdateRecurringInspectionASsignmentNotificationLinkMutation,
  UpdateRecurringInspectionASsignmentNotificationLinkMutationVariables
>;

/**
 * __useUpdateRecurringInspectionASsignmentNotificationLinkMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringInspectionASsignmentNotificationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringInspectionASsignmentNotificationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringInspectionASsignmentNotificationLinkMutation, { data, loading, error }] = useUpdateRecurringInspectionASsignmentNotificationLinkMutation({
 *   variables: {
 *      assignmentNotificationLink: // value for 'assignmentNotificationLink'
 *   },
 * });
 */
export function useUpdateRecurringInspectionASsignmentNotificationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecurringInspectionASsignmentNotificationLinkMutation,
    UpdateRecurringInspectionASsignmentNotificationLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRecurringInspectionASsignmentNotificationLinkMutation,
    UpdateRecurringInspectionASsignmentNotificationLinkMutationVariables
  >(UpdateRecurringInspectionASsignmentNotificationLinkDocument, options);
}
export type UpdateRecurringInspectionASsignmentNotificationLinkMutationHookResult = ReturnType<
  typeof useUpdateRecurringInspectionASsignmentNotificationLinkMutation
>;
export type UpdateRecurringInspectionASsignmentNotificationLinkMutationResult =
  Apollo.MutationResult<UpdateRecurringInspectionASsignmentNotificationLinkMutation>;
export type UpdateRecurringInspectionASsignmentNotificationLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringInspectionASsignmentNotificationLinkMutation,
  UpdateRecurringInspectionASsignmentNotificationLinkMutationVariables
>;
export const CreateSafetySignatureDocument = gql`
  mutation CreateSafetySignature($safetySignature: SafetySignatureCreateInput) {
    createSafetySignature(safetySignature: $safetySignature) {
      id
    }
  }
`;
export type CreateSafetySignatureMutationFn = Apollo.MutationFunction<
  CreateSafetySignatureMutation,
  CreateSafetySignatureMutationVariables
>;

/**
 * __useCreateSafetySignatureMutation__
 *
 * To run a mutation, you first call `useCreateSafetySignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSafetySignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSafetySignatureMutation, { data, loading, error }] = useCreateSafetySignatureMutation({
 *   variables: {
 *      safetySignature: // value for 'safetySignature'
 *   },
 * });
 */
export function useCreateSafetySignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSafetySignatureMutation, CreateSafetySignatureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSafetySignatureMutation, CreateSafetySignatureMutationVariables>(
    CreateSafetySignatureDocument,
    options
  );
}
export type CreateSafetySignatureMutationHookResult = ReturnType<typeof useCreateSafetySignatureMutation>;
export type CreateSafetySignatureMutationResult = Apollo.MutationResult<CreateSafetySignatureMutation>;
export type CreateSafetySignatureMutationOptions = Apollo.BaseMutationOptions<
  CreateSafetySignatureMutation,
  CreateSafetySignatureMutationVariables
>;
export const UpdateSafetySignatureDocument = gql`
  mutation UpdateSafetySignature($safetySignature: SafetySignatureUpdateInput) {
    updateSafetySignature(safetySignature: $safetySignature) {
      id
    }
  }
`;
export type UpdateSafetySignatureMutationFn = Apollo.MutationFunction<
  UpdateSafetySignatureMutation,
  UpdateSafetySignatureMutationVariables
>;

/**
 * __useUpdateSafetySignatureMutation__
 *
 * To run a mutation, you first call `useUpdateSafetySignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSafetySignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSafetySignatureMutation, { data, loading, error }] = useUpdateSafetySignatureMutation({
 *   variables: {
 *      safetySignature: // value for 'safetySignature'
 *   },
 * });
 */
export function useUpdateSafetySignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSafetySignatureMutation, UpdateSafetySignatureMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSafetySignatureMutation, UpdateSafetySignatureMutationVariables>(
    UpdateSafetySignatureDocument,
    options
  );
}
export type UpdateSafetySignatureMutationHookResult = ReturnType<typeof useUpdateSafetySignatureMutation>;
export type UpdateSafetySignatureMutationResult = Apollo.MutationResult<UpdateSafetySignatureMutation>;
export type UpdateSafetySignatureMutationOptions = Apollo.BaseMutationOptions<
  UpdateSafetySignatureMutation,
  UpdateSafetySignatureMutationVariables
>;
export const CreateScheduleReminderDocument = gql`
  mutation CreateScheduleReminder($reminder: ScheduleReminderCreateInput) {
    createScheduleReminder(scheduleReminder: $reminder) {
      id
      organizationId
      memberId
      startTime
      endTime
      payload
      type
      createdOn
      notifyEmployee
      overrideRecurringScheduleId
      links {
        id
        scheduleReminderId
        member {
          id
          firstName
          lastName
          memberGroup {
            id
            groupName
          }
        }
      }
    }
  }
`;
export type CreateScheduleReminderMutationFn = Apollo.MutationFunction<
  CreateScheduleReminderMutation,
  CreateScheduleReminderMutationVariables
>;

/**
 * __useCreateScheduleReminderMutation__
 *
 * To run a mutation, you first call `useCreateScheduleReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleReminderMutation, { data, loading, error }] = useCreateScheduleReminderMutation({
 *   variables: {
 *      reminder: // value for 'reminder'
 *   },
 * });
 */
export function useCreateScheduleReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateScheduleReminderMutation, CreateScheduleReminderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateScheduleReminderMutation, CreateScheduleReminderMutationVariables>(
    CreateScheduleReminderDocument,
    options
  );
}
export type CreateScheduleReminderMutationHookResult = ReturnType<typeof useCreateScheduleReminderMutation>;
export type CreateScheduleReminderMutationResult = Apollo.MutationResult<CreateScheduleReminderMutation>;
export type CreateScheduleReminderMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduleReminderMutation,
  CreateScheduleReminderMutationVariables
>;
export const UpdateScheduleReminderDocument = gql`
  mutation UpdateScheduleReminder($reminder: ScheduleReminderUpdateInput) {
    updateScheduleReminder(scheduleReminder: $reminder) {
      id
      organizationId
      memberId
      startTime
      endTime
      payload
      type
      createdOn
      deletedOn
      notifyEmployee
      overrideRecurringScheduleId
      links {
        id
        scheduleReminderId
        member {
          id
          firstName
          lastName
          memberGroup {
            id
            groupName
          }
        }
      }
    }
  }
`;
export type UpdateScheduleReminderMutationFn = Apollo.MutationFunction<
  UpdateScheduleReminderMutation,
  UpdateScheduleReminderMutationVariables
>;

/**
 * __useUpdateScheduleReminderMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleReminderMutation, { data, loading, error }] = useUpdateScheduleReminderMutation({
 *   variables: {
 *      reminder: // value for 'reminder'
 *   },
 * });
 */
export function useUpdateScheduleReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScheduleReminderMutation, UpdateScheduleReminderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateScheduleReminderMutation, UpdateScheduleReminderMutationVariables>(
    UpdateScheduleReminderDocument,
    options
  );
}
export type UpdateScheduleReminderMutationHookResult = ReturnType<typeof useUpdateScheduleReminderMutation>;
export type UpdateScheduleReminderMutationResult = Apollo.MutationResult<UpdateScheduleReminderMutation>;
export type UpdateScheduleReminderMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleReminderMutation,
  UpdateScheduleReminderMutationVariables
>;
export const CreateScheduleReminderLinkDocument = gql`
  mutation CreateScheduleReminderLink($link: ScheduleReminderLinkCreateInput) {
    createScheduleReminderLink(scheduleReminderLink: $link) {
      id
      memberId
      scheduleReminderId
    }
  }
`;
export type CreateScheduleReminderLinkMutationFn = Apollo.MutationFunction<
  CreateScheduleReminderLinkMutation,
  CreateScheduleReminderLinkMutationVariables
>;

/**
 * __useCreateScheduleReminderLinkMutation__
 *
 * To run a mutation, you first call `useCreateScheduleReminderLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleReminderLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleReminderLinkMutation, { data, loading, error }] = useCreateScheduleReminderLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateScheduleReminderLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleReminderLinkMutation,
    CreateScheduleReminderLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateScheduleReminderLinkMutation, CreateScheduleReminderLinkMutationVariables>(
    CreateScheduleReminderLinkDocument,
    options
  );
}
export type CreateScheduleReminderLinkMutationHookResult = ReturnType<typeof useCreateScheduleReminderLinkMutation>;
export type CreateScheduleReminderLinkMutationResult = Apollo.MutationResult<CreateScheduleReminderLinkMutation>;
export type CreateScheduleReminderLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduleReminderLinkMutation,
  CreateScheduleReminderLinkMutationVariables
>;
export const UpdateScheduleReminderLinkDocument = gql`
  mutation UpdateScheduleReminderLink($link: ScheduleReminderLinkUpdateInput) {
    updateScheduleReminderLink(scheduleReminderLink: $link) {
      id
      memberId
      scheduleReminderId
      deletedOn
    }
  }
`;
export type UpdateScheduleReminderLinkMutationFn = Apollo.MutationFunction<
  UpdateScheduleReminderLinkMutation,
  UpdateScheduleReminderLinkMutationVariables
>;

/**
 * __useUpdateScheduleReminderLinkMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleReminderLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleReminderLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleReminderLinkMutation, { data, loading, error }] = useUpdateScheduleReminderLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateScheduleReminderLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleReminderLinkMutation,
    UpdateScheduleReminderLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateScheduleReminderLinkMutation, UpdateScheduleReminderLinkMutationVariables>(
    UpdateScheduleReminderLinkDocument,
    options
  );
}
export type UpdateScheduleReminderLinkMutationHookResult = ReturnType<typeof useUpdateScheduleReminderLinkMutation>;
export type UpdateScheduleReminderLinkMutationResult = Apollo.MutationResult<UpdateScheduleReminderLinkMutation>;
export type UpdateScheduleReminderLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleReminderLinkMutation,
  UpdateScheduleReminderLinkMutationVariables
>;
export const CreateTimeEntryDocument = gql`
  mutation CreateTimeEntry($entry: TimeEntryCreateInput) {
    createTimeEntry(timeEntry: $entry) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;
export type CreateTimeEntryMutationFn = Apollo.MutationFunction<
  CreateTimeEntryMutation,
  CreateTimeEntryMutationVariables
>;

/**
 * __useCreateTimeEntryMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryMutation, { data, loading, error }] = useCreateTimeEntryMutation({
 *   variables: {
 *      entry: // value for 'entry'
 *   },
 * });
 */
export function useCreateTimeEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryMutation, CreateTimeEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntryMutation, CreateTimeEntryMutationVariables>(
    CreateTimeEntryDocument,
    options
  );
}
export type CreateTimeEntryMutationHookResult = ReturnType<typeof useCreateTimeEntryMutation>;
export type CreateTimeEntryMutationResult = Apollo.MutationResult<CreateTimeEntryMutation>;
export type CreateTimeEntryMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntryMutation,
  CreateTimeEntryMutationVariables
>;
export const UpdateTimeEntryDocument = gql`
  mutation UpdateTimeEntry($entry: TimeEntryUpdateInput) {
    updateTimeEntry(timeEntry: $entry) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;
export type UpdateTimeEntryMutationFn = Apollo.MutationFunction<
  UpdateTimeEntryMutation,
  UpdateTimeEntryMutationVariables
>;

/**
 * __useUpdateTimeEntryMutation__
 *
 * To run a mutation, you first call `useUpdateTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeEntryMutation, { data, loading, error }] = useUpdateTimeEntryMutation({
 *   variables: {
 *      entry: // value for 'entry'
 *   },
 * });
 */
export function useUpdateTimeEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeEntryMutation, UpdateTimeEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeEntryMutation, UpdateTimeEntryMutationVariables>(
    UpdateTimeEntryDocument,
    options
  );
}
export type UpdateTimeEntryMutationHookResult = ReturnType<typeof useUpdateTimeEntryMutation>;
export type UpdateTimeEntryMutationResult = Apollo.MutationResult<UpdateTimeEntryMutation>;
export type UpdateTimeEntryMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeEntryMutation,
  UpdateTimeEntryMutationVariables
>;
export const CreateTimeEntriesDocument = gql`
  mutation CreateTimeEntries($entries: [TimeEntryCreateInput!]!) {
    createTimeEntries(timeEntries: $entries) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;
export type CreateTimeEntriesMutationFn = Apollo.MutationFunction<
  CreateTimeEntriesMutation,
  CreateTimeEntriesMutationVariables
>;

/**
 * __useCreateTimeEntriesMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntriesMutation, { data, loading, error }] = useCreateTimeEntriesMutation({
 *   variables: {
 *      entries: // value for 'entries'
 *   },
 * });
 */
export function useCreateTimeEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntriesMutation, CreateTimeEntriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntriesMutation, CreateTimeEntriesMutationVariables>(
    CreateTimeEntriesDocument,
    options
  );
}
export type CreateTimeEntriesMutationHookResult = ReturnType<typeof useCreateTimeEntriesMutation>;
export type CreateTimeEntriesMutationResult = Apollo.MutationResult<CreateTimeEntriesMutation>;
export type CreateTimeEntriesMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntriesMutation,
  CreateTimeEntriesMutationVariables
>;
export const UpdateTimeEntriesDocument = gql`
  mutation UpdateTimeEntries($entries: [TimeEntryUpdateInput!]!) {
    updateTimeEntries(timeEntries: $entries) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;
export type UpdateTimeEntriesMutationFn = Apollo.MutationFunction<
  UpdateTimeEntriesMutation,
  UpdateTimeEntriesMutationVariables
>;

/**
 * __useUpdateTimeEntriesMutation__
 *
 * To run a mutation, you first call `useUpdateTimeEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeEntriesMutation, { data, loading, error }] = useUpdateTimeEntriesMutation({
 *   variables: {
 *      entries: // value for 'entries'
 *   },
 * });
 */
export function useUpdateTimeEntriesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeEntriesMutation, UpdateTimeEntriesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeEntriesMutation, UpdateTimeEntriesMutationVariables>(
    UpdateTimeEntriesDocument,
    options
  );
}
export type UpdateTimeEntriesMutationHookResult = ReturnType<typeof useUpdateTimeEntriesMutation>;
export type UpdateTimeEntriesMutationResult = Apollo.MutationResult<UpdateTimeEntriesMutation>;
export type UpdateTimeEntriesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeEntriesMutation,
  UpdateTimeEntriesMutationVariables
>;
export const CreateTimeOffDocument = gql`
  mutation CreateTimeOff($timeOff: TimeOffCreateInput) {
    createTimeOff(timeOff: $timeOff) {
      id
    }
  }
`;
export type CreateTimeOffMutationFn = Apollo.MutationFunction<CreateTimeOffMutation, CreateTimeOffMutationVariables>;

/**
 * __useCreateTimeOffMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffMutation, { data, loading, error }] = useCreateTimeOffMutation({
 *   variables: {
 *      timeOff: // value for 'timeOff'
 *   },
 * });
 */
export function useCreateTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeOffMutation, CreateTimeOffMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeOffMutation, CreateTimeOffMutationVariables>(CreateTimeOffDocument, options);
}
export type CreateTimeOffMutationHookResult = ReturnType<typeof useCreateTimeOffMutation>;
export type CreateTimeOffMutationResult = Apollo.MutationResult<CreateTimeOffMutation>;
export type CreateTimeOffMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeOffMutation,
  CreateTimeOffMutationVariables
>;
export const CreateTimeOffsDocument = gql`
  mutation CreateTimeOffs($timeOffs: [TimeOffCreateInput!]!) {
    createTimeOffs(timeOffs: $timeOffs) {
      id
    }
  }
`;
export type CreateTimeOffsMutationFn = Apollo.MutationFunction<CreateTimeOffsMutation, CreateTimeOffsMutationVariables>;

/**
 * __useCreateTimeOffsMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffsMutation, { data, loading, error }] = useCreateTimeOffsMutation({
 *   variables: {
 *      timeOffs: // value for 'timeOffs'
 *   },
 * });
 */
export function useCreateTimeOffsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeOffsMutation, CreateTimeOffsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeOffsMutation, CreateTimeOffsMutationVariables>(CreateTimeOffsDocument, options);
}
export type CreateTimeOffsMutationHookResult = ReturnType<typeof useCreateTimeOffsMutation>;
export type CreateTimeOffsMutationResult = Apollo.MutationResult<CreateTimeOffsMutation>;
export type CreateTimeOffsMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeOffsMutation,
  CreateTimeOffsMutationVariables
>;
export const UpdateTimeOffDocument = gql`
  mutation UpdateTimeOff($timeOff: TimeOffUpdateInput) {
    updateTimeOff(timeOff: $timeOff) {
      id
    }
  }
`;
export type UpdateTimeOffMutationFn = Apollo.MutationFunction<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>;

/**
 * __useUpdateTimeOffMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffMutation, { data, loading, error }] = useUpdateTimeOffMutation({
 *   variables: {
 *      timeOff: // value for 'timeOff'
 *   },
 * });
 */
export function useUpdateTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeOffMutation, UpdateTimeOffMutationVariables>(UpdateTimeOffDocument, options);
}
export type UpdateTimeOffMutationHookResult = ReturnType<typeof useUpdateTimeOffMutation>;
export type UpdateTimeOffMutationResult = Apollo.MutationResult<UpdateTimeOffMutation>;
export type UpdateTimeOffMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeOffMutation,
  UpdateTimeOffMutationVariables
>;
export const UpdateTimeOffsDocument = gql`
  mutation UpdateTimeOffs($timeOffs: [TimeOffUpdateInput!]!) {
    updateTimeOffs(timeOffs: $timeOffs) {
      id
    }
  }
`;
export type UpdateTimeOffsMutationFn = Apollo.MutationFunction<UpdateTimeOffsMutation, UpdateTimeOffsMutationVariables>;

/**
 * __useUpdateTimeOffsMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffsMutation, { data, loading, error }] = useUpdateTimeOffsMutation({
 *   variables: {
 *      timeOffs: // value for 'timeOffs'
 *   },
 * });
 */
export function useUpdateTimeOffsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffsMutation, UpdateTimeOffsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeOffsMutation, UpdateTimeOffsMutationVariables>(UpdateTimeOffsDocument, options);
}
export type UpdateTimeOffsMutationHookResult = ReturnType<typeof useUpdateTimeOffsMutation>;
export type UpdateTimeOffsMutationResult = Apollo.MutationResult<UpdateTimeOffsMutation>;
export type UpdateTimeOffsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeOffsMutation,
  UpdateTimeOffsMutationVariables
>;
export const UpdateTimeOffRequestsDocument = gql`
  mutation UpdateTimeOffRequests($timeOffRequests: [TimeOffRequestUpdateInput!]!) {
    updateTimeOffRequests(timeOffRequests: $timeOffRequests) {
      id
      answeredBy
      answeredByMember {
        id
        firstName
        lastName
      }
      answeredOn
      approved
      createdOnDst
      deletedOn
      description
      paid
      dateStamp
      createdOn
      createdOnLocal
      answeredOn
      submittedOn
      updatedOn
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      rejectionReason
      seconds
      submittedBy
      type
      submittedByMember {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
    }
  }
`;
export type UpdateTimeOffRequestsMutationFn = Apollo.MutationFunction<
  UpdateTimeOffRequestsMutation,
  UpdateTimeOffRequestsMutationVariables
>;

/**
 * __useUpdateTimeOffRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffRequestsMutation, { data, loading, error }] = useUpdateTimeOffRequestsMutation({
 *   variables: {
 *      timeOffRequests: // value for 'timeOffRequests'
 *   },
 * });
 */
export function useUpdateTimeOffRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffRequestsMutation, UpdateTimeOffRequestsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeOffRequestsMutation, UpdateTimeOffRequestsMutationVariables>(
    UpdateTimeOffRequestsDocument,
    options
  );
}
export type UpdateTimeOffRequestsMutationHookResult = ReturnType<typeof useUpdateTimeOffRequestsMutation>;
export type UpdateTimeOffRequestsMutationResult = Apollo.MutationResult<UpdateTimeOffRequestsMutation>;
export type UpdateTimeOffRequestsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeOffRequestsMutation,
  UpdateTimeOffRequestsMutationVariables
>;
export const UpdateTimeOffRequestDocument = gql`
  mutation UpdateTimeOffRequest($timeOffRequest: TimeOffRequestUpdateInput) {
    updateTimeOffRequest(timeOffRequest: $timeOffRequest) {
      id
      answeredBy
      answeredByMember {
        id
        firstName
        lastName
      }
      answeredOn
      approved
      createdOnDst
      deletedOn
      description
      paid
      dateStamp
      createdOn
      createdOnLocal
      answeredOn
      submittedOn
      updatedOn
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      rejectionReason
      seconds
      submittedBy
      type
      submittedByMember {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
    }
  }
`;
export type UpdateTimeOffRequestMutationFn = Apollo.MutationFunction<
  UpdateTimeOffRequestMutation,
  UpdateTimeOffRequestMutationVariables
>;

/**
 * __useUpdateTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useUpdateTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeOffRequestMutation, { data, loading, error }] = useUpdateTimeOffRequestMutation({
 *   variables: {
 *      timeOffRequest: // value for 'timeOffRequest'
 *   },
 * });
 */
export function useUpdateTimeOffRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTimeOffRequestMutation, UpdateTimeOffRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTimeOffRequestMutation, UpdateTimeOffRequestMutationVariables>(
    UpdateTimeOffRequestDocument,
    options
  );
}
export type UpdateTimeOffRequestMutationHookResult = ReturnType<typeof useUpdateTimeOffRequestMutation>;
export type UpdateTimeOffRequestMutationResult = Apollo.MutationResult<UpdateTimeOffRequestMutation>;
export type UpdateTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimeOffRequestMutation,
  UpdateTimeOffRequestMutationVariables
>;
export const CreateTimeOffRequestDocument = gql`
  mutation CreateTimeOffRequest($timeOffRequest: TimeOffRequestCreateInput) {
    createTimeOffRequest(timeOffRequest: $timeOffRequest) {
      id
      answeredBy
      answeredByMember {
        id
        firstName
        lastName
      }
      answeredOn
      approved
      createdOnDst
      deletedOn
      description
      paid
      dateStamp
      createdOn
      createdOnLocal
      answeredOn
      submittedOn
      updatedOn
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      rejectionReason
      seconds
      submittedBy
      type
      submittedByMember {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
    }
  }
`;
export type CreateTimeOffRequestMutationFn = Apollo.MutationFunction<
  CreateTimeOffRequestMutation,
  CreateTimeOffRequestMutationVariables
>;

/**
 * __useCreateTimeOffRequestMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffRequestMutation, { data, loading, error }] = useCreateTimeOffRequestMutation({
 *   variables: {
 *      timeOffRequest: // value for 'timeOffRequest'
 *   },
 * });
 */
export function useCreateTimeOffRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeOffRequestMutation, CreateTimeOffRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeOffRequestMutation, CreateTimeOffRequestMutationVariables>(
    CreateTimeOffRequestDocument,
    options
  );
}
export type CreateTimeOffRequestMutationHookResult = ReturnType<typeof useCreateTimeOffRequestMutation>;
export type CreateTimeOffRequestMutationResult = Apollo.MutationResult<CreateTimeOffRequestMutation>;
export type CreateTimeOffRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeOffRequestMutation,
  CreateTimeOffRequestMutationVariables
>;
export const CreateTimeOffRequestsDocument = gql`
  mutation CreateTimeOffRequests($timeOffRequests: [TimeOffRequestCreateInput!]!) {
    createTimeOffRequests(timeOffRequests: $timeOffRequests) {
      id
      answeredBy
      answeredByMember {
        id
        firstName
        lastName
      }
      answeredOn
      approved
      createdOnDst
      deletedOn
      description
      paid
      dateStamp
      createdOn
      createdOnLocal
      answeredOn
      submittedOn
      updatedOn
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      rejectionReason
      seconds
      submittedBy
      type
      submittedByMember {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
    }
  }
`;
export type CreateTimeOffRequestsMutationFn = Apollo.MutationFunction<
  CreateTimeOffRequestsMutation,
  CreateTimeOffRequestsMutationVariables
>;

/**
 * __useCreateTimeOffRequestsMutation__
 *
 * To run a mutation, you first call `useCreateTimeOffRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeOffRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeOffRequestsMutation, { data, loading, error }] = useCreateTimeOffRequestsMutation({
 *   variables: {
 *      timeOffRequests: // value for 'timeOffRequests'
 *   },
 * });
 */
export function useCreateTimeOffRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeOffRequestsMutation, CreateTimeOffRequestsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeOffRequestsMutation, CreateTimeOffRequestsMutationVariables>(
    CreateTimeOffRequestsDocument,
    options
  );
}
export type CreateTimeOffRequestsMutationHookResult = ReturnType<typeof useCreateTimeOffRequestsMutation>;
export type CreateTimeOffRequestsMutationResult = Apollo.MutationResult<CreateTimeOffRequestsMutation>;
export type CreateTimeOffRequestsMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeOffRequestsMutation,
  CreateTimeOffRequestsMutationVariables
>;
export const CreateWageHistoryDocument = gql`
  mutation createWageHistory($wageHistory: WageHistoryCreateInput) {
    createWageHistory(wageHistory: $wageHistory) {
      id
      memberId
      wage
      wageRate
      workWeek
      effectiveRate
      overburden
      changeDate
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
export type CreateWageHistoryMutationFn = Apollo.MutationFunction<
  CreateWageHistoryMutation,
  CreateWageHistoryMutationVariables
>;

/**
 * __useCreateWageHistoryMutation__
 *
 * To run a mutation, you first call `useCreateWageHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWageHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWageHistoryMutation, { data, loading, error }] = useCreateWageHistoryMutation({
 *   variables: {
 *      wageHistory: // value for 'wageHistory'
 *   },
 * });
 */
export function useCreateWageHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWageHistoryMutation, CreateWageHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWageHistoryMutation, CreateWageHistoryMutationVariables>(
    CreateWageHistoryDocument,
    options
  );
}
export type CreateWageHistoryMutationHookResult = ReturnType<typeof useCreateWageHistoryMutation>;
export type CreateWageHistoryMutationResult = Apollo.MutationResult<CreateWageHistoryMutation>;
export type CreateWageHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateWageHistoryMutation,
  CreateWageHistoryMutationVariables
>;
export const UpdateWageHistoryDocument = gql`
  mutation updateWageHistory($wageHistory: WageHistoryUpdateInput) {
    updateWageHistory(wageHistory: $wageHistory) {
      id
      memberId
      wage
      wageRate
      workWeek
      effectiveRate
      overburden
      changeDate
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
export type UpdateWageHistoryMutationFn = Apollo.MutationFunction<
  UpdateWageHistoryMutation,
  UpdateWageHistoryMutationVariables
>;

/**
 * __useUpdateWageHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateWageHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWageHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWageHistoryMutation, { data, loading, error }] = useUpdateWageHistoryMutation({
 *   variables: {
 *      wageHistory: // value for 'wageHistory'
 *   },
 * });
 */
export function useUpdateWageHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWageHistoryMutation, UpdateWageHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWageHistoryMutation, UpdateWageHistoryMutationVariables>(
    UpdateWageHistoryDocument,
    options
  );
}
export type UpdateWageHistoryMutationHookResult = ReturnType<typeof useUpdateWageHistoryMutation>;
export type UpdateWageHistoryMutationResult = Apollo.MutationResult<UpdateWageHistoryMutation>;
export type UpdateWageHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateWageHistoryMutation,
  UpdateWageHistoryMutationVariables
>;
export const BusySignInQuestionsDocument = gql`
  query busySignInQuestions {
    busySignInQuestions(filter: { deletedOn: { isNull: true } }, sort: [{ createdOn: asc }]) {
      id
      questionTitle
      questionDescription
      questionPrompt
      type
      required
      choices
      createdOn
      cursor
    }
  }
`;

/**
 * __useBusySignInQuestionsQuery__
 *
 * To run a query within a React component, call `useBusySignInQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusySignInQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusySignInQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusySignInQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<BusySignInQuestionsQuery, BusySignInQuestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusySignInQuestionsQuery, BusySignInQuestionsQueryVariables>(
    BusySignInQuestionsDocument,
    options
  );
}
export function useBusySignInQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusySignInQuestionsQuery, BusySignInQuestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusySignInQuestionsQuery, BusySignInQuestionsQueryVariables>(
    BusySignInQuestionsDocument,
    options
  );
}
export function useBusySignInQuestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BusySignInQuestionsQuery, BusySignInQuestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BusySignInQuestionsQuery, BusySignInQuestionsQueryVariables>(
    BusySignInQuestionsDocument,
    options
  );
}
export type BusySignInQuestionsQueryHookResult = ReturnType<typeof useBusySignInQuestionsQuery>;
export type BusySignInQuestionsLazyQueryHookResult = ReturnType<typeof useBusySignInQuestionsLazyQuery>;
export type BusySignInQuestionsSuspenseQueryHookResult = ReturnType<typeof useBusySignInQuestionsSuspenseQuery>;
export type BusySignInQuestionsQueryResult = Apollo.QueryResult<
  BusySignInQuestionsQuery,
  BusySignInQuestionsQueryVariables
>;
export const CostCodeGroupDocument = gql`
  query CostCodeGroup($after: String, $filter: CostCodeGroupFilter, $first: Int) {
    costCodeGroups(after: $after, first: $first, filter: $filter, sort: [{ groupName: asc }]) {
      id
      organizationId
      groupName
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useCostCodeGroupQuery__
 *
 * To run a query within a React component, call `useCostCodeGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodeGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodeGroupQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCostCodeGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<CostCodeGroupQuery, CostCodeGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodeGroupQuery, CostCodeGroupQueryVariables>(CostCodeGroupDocument, options);
}
export function useCostCodeGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodeGroupQuery, CostCodeGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodeGroupQuery, CostCodeGroupQueryVariables>(CostCodeGroupDocument, options);
}
export function useCostCodeGroupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodeGroupQuery, CostCodeGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodeGroupQuery, CostCodeGroupQueryVariables>(CostCodeGroupDocument, options);
}
export type CostCodeGroupQueryHookResult = ReturnType<typeof useCostCodeGroupQuery>;
export type CostCodeGroupLazyQueryHookResult = ReturnType<typeof useCostCodeGroupLazyQuery>;
export type CostCodeGroupSuspenseQueryHookResult = ReturnType<typeof useCostCodeGroupSuspenseQuery>;
export type CostCodeGroupQueryResult = Apollo.QueryResult<CostCodeGroupQuery, CostCodeGroupQueryVariables>;
export const CostCodeTimeMetricsQueryDocument = gql`
  query CostCodeTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodeLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicLaborMetrics
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useCostCodeTimeMetricsQueryQuery__
 *
 * To run a query within a React component, call `useCostCodeTimeMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodeTimeMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodeTimeMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useCostCodeTimeMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CostCodeTimeMetricsQueryQuery, CostCodeTimeMetricsQueryQueryVariables> &
    ({ variables: CostCodeTimeMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodeTimeMetricsQueryQuery, CostCodeTimeMetricsQueryQueryVariables>(
    CostCodeTimeMetricsQueryDocument,
    options
  );
}
export function useCostCodeTimeMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodeTimeMetricsQueryQuery, CostCodeTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodeTimeMetricsQueryQuery, CostCodeTimeMetricsQueryQueryVariables>(
    CostCodeTimeMetricsQueryDocument,
    options
  );
}
export function useCostCodeTimeMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodeTimeMetricsQueryQuery, CostCodeTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodeTimeMetricsQueryQuery, CostCodeTimeMetricsQueryQueryVariables>(
    CostCodeTimeMetricsQueryDocument,
    options
  );
}
export type CostCodeTimeMetricsQueryQueryHookResult = ReturnType<typeof useCostCodeTimeMetricsQueryQuery>;
export type CostCodeTimeMetricsQueryLazyQueryHookResult = ReturnType<typeof useCostCodeTimeMetricsQueryLazyQuery>;
export type CostCodeTimeMetricsQuerySuspenseQueryHookResult = ReturnType<
  typeof useCostCodeTimeMetricsQuerySuspenseQuery
>;
export type CostCodeTimeMetricsQueryQueryResult = Apollo.QueryResult<
  CostCodeTimeMetricsQueryQuery,
  CostCodeTimeMetricsQueryQueryVariables
>;
export const CostCodeMetricsQueryDocument = gql`
  query CostCodeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodeLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...LaborMetrics
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useCostCodeMetricsQueryQuery__
 *
 * To run a query within a React component, call `useCostCodeMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodeMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodeMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useCostCodeMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CostCodeMetricsQueryQuery, CostCodeMetricsQueryQueryVariables> &
    ({ variables: CostCodeMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodeMetricsQueryQuery, CostCodeMetricsQueryQueryVariables>(
    CostCodeMetricsQueryDocument,
    options
  );
}
export function useCostCodeMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodeMetricsQueryQuery, CostCodeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodeMetricsQueryQuery, CostCodeMetricsQueryQueryVariables>(
    CostCodeMetricsQueryDocument,
    options
  );
}
export function useCostCodeMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodeMetricsQueryQuery, CostCodeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodeMetricsQueryQuery, CostCodeMetricsQueryQueryVariables>(
    CostCodeMetricsQueryDocument,
    options
  );
}
export type CostCodeMetricsQueryQueryHookResult = ReturnType<typeof useCostCodeMetricsQueryQuery>;
export type CostCodeMetricsQueryLazyQueryHookResult = ReturnType<typeof useCostCodeMetricsQueryLazyQuery>;
export type CostCodeMetricsQuerySuspenseQueryHookResult = ReturnType<typeof useCostCodeMetricsQuerySuspenseQuery>;
export type CostCodeMetricsQueryQueryResult = Apollo.QueryResult<
  CostCodeMetricsQueryQuery,
  CostCodeMetricsQueryQueryVariables
>;
export const QueryCostCodesDocument = gql`
  query QueryCostCodes($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      archivedOn
      costCode
      costCodeGroup {
        createdOn
        cursor
        deletedOn
        groupName
        id
        organizationId
        submittedOn
        updatedOn
      }
      costCodeGroupId
      createdOn
      cursor
      id
      organizationId
      submittedOn
      title
      unitTitle
      updatedOn
      workersCompId
    }
  }
`;

/**
 * __useQueryCostCodesQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryCostCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCostCodesQuery, QueryCostCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesQuery, QueryCostCodesQueryVariables>(QueryCostCodesDocument, options);
}
export function useQueryCostCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodesQuery, QueryCostCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesQuery, QueryCostCodesQueryVariables>(QueryCostCodesDocument, options);
}
export function useQueryCostCodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodesQuery, QueryCostCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesQuery, QueryCostCodesQueryVariables>(QueryCostCodesDocument, options);
}
export type QueryCostCodesQueryHookResult = ReturnType<typeof useQueryCostCodesQuery>;
export type QueryCostCodesLazyQueryHookResult = ReturnType<typeof useQueryCostCodesLazyQuery>;
export type QueryCostCodesSuspenseQueryHookResult = ReturnType<typeof useQueryCostCodesSuspenseQuery>;
export type QueryCostCodesQueryResult = Apollo.QueryResult<QueryCostCodesQuery, QueryCostCodesQueryVariables>;
export const ValidateProjectCostCodeDocument = gql`
  query ValidateProjectCostCode($projectId: Uuid!, $costCodeId: Uuid!) {
    costCodes(
      filter: { projectCostCodeLink: { projectId: { equal: $projectId }, costCodeId: { equal: $costCodeId } } }
      first: 1
    ) {
      archivedOn
      costCode
      costCodeGroupId
      createdOn
      cursor
      id
      organizationId
      submittedOn
      title
      unitTitle
      updatedOn
      workersCompId
    }
  }
`;

/**
 * __useValidateProjectCostCodeQuery__
 *
 * To run a query within a React component, call `useValidateProjectCostCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateProjectCostCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateProjectCostCodeQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      costCodeId: // value for 'costCodeId'
 *   },
 * });
 */
export function useValidateProjectCostCodeQuery(
  baseOptions: Apollo.QueryHookOptions<ValidateProjectCostCodeQuery, ValidateProjectCostCodeQueryVariables> &
    ({ variables: ValidateProjectCostCodeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateProjectCostCodeQuery, ValidateProjectCostCodeQueryVariables>(
    ValidateProjectCostCodeDocument,
    options
  );
}
export function useValidateProjectCostCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateProjectCostCodeQuery, ValidateProjectCostCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateProjectCostCodeQuery, ValidateProjectCostCodeQueryVariables>(
    ValidateProjectCostCodeDocument,
    options
  );
}
export function useValidateProjectCostCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ValidateProjectCostCodeQuery, ValidateProjectCostCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ValidateProjectCostCodeQuery, ValidateProjectCostCodeQueryVariables>(
    ValidateProjectCostCodeDocument,
    options
  );
}
export type ValidateProjectCostCodeQueryHookResult = ReturnType<typeof useValidateProjectCostCodeQuery>;
export type ValidateProjectCostCodeLazyQueryHookResult = ReturnType<typeof useValidateProjectCostCodeLazyQuery>;
export type ValidateProjectCostCodeSuspenseQueryHookResult = ReturnType<typeof useValidateProjectCostCodeSuspenseQuery>;
export type ValidateProjectCostCodeQueryResult = Apollo.QueryResult<
  ValidateProjectCostCodeQuery,
  ValidateProjectCostCodeQueryVariables
>;
export const QueryCostCodesNamesDocument = gql`
  query QueryCostCodesNames($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      cursor
      id
      title
      costCode
    }
  }
`;

/**
 * __useQueryCostCodesNamesQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesNamesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryCostCodesNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCostCodesNamesQuery, QueryCostCodesNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesNamesQuery, QueryCostCodesNamesQueryVariables>(
    QueryCostCodesNamesDocument,
    options
  );
}
export function useQueryCostCodesNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodesNamesQuery, QueryCostCodesNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesNamesQuery, QueryCostCodesNamesQueryVariables>(
    QueryCostCodesNamesDocument,
    options
  );
}
export function useQueryCostCodesNamesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodesNamesQuery, QueryCostCodesNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesNamesQuery, QueryCostCodesNamesQueryVariables>(
    QueryCostCodesNamesDocument,
    options
  );
}
export type QueryCostCodesNamesQueryHookResult = ReturnType<typeof useQueryCostCodesNamesQuery>;
export type QueryCostCodesNamesLazyQueryHookResult = ReturnType<typeof useQueryCostCodesNamesLazyQuery>;
export type QueryCostCodesNamesSuspenseQueryHookResult = ReturnType<typeof useQueryCostCodesNamesSuspenseQuery>;
export type QueryCostCodesNamesQueryResult = Apollo.QueryResult<
  QueryCostCodesNamesQuery,
  QueryCostCodesNamesQueryVariables
>;
export const QueryCostCodesNamesWithArchivedDocument = gql`
  query QueryCostCodesNamesWithArchived($after: String, $filter: CostCodeFilter, $sort: [CostCodeSort!], $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: $sort) {
      cursor
      id
      title
      costCode
      archivedOn
      unitTitle
    }
  }
`;

/**
 * __useQueryCostCodesNamesWithArchivedQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesNamesWithArchivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesNamesWithArchivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesNamesWithArchivedQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryCostCodesNamesWithArchivedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryCostCodesNamesWithArchivedQuery,
    QueryCostCodesNamesWithArchivedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesNamesWithArchivedQuery, QueryCostCodesNamesWithArchivedQueryVariables>(
    QueryCostCodesNamesWithArchivedDocument,
    options
  );
}
export function useQueryCostCodesNamesWithArchivedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesNamesWithArchivedQuery,
    QueryCostCodesNamesWithArchivedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesNamesWithArchivedQuery, QueryCostCodesNamesWithArchivedQueryVariables>(
    QueryCostCodesNamesWithArchivedDocument,
    options
  );
}
export function useQueryCostCodesNamesWithArchivedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesNamesWithArchivedQuery,
    QueryCostCodesNamesWithArchivedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesNamesWithArchivedQuery, QueryCostCodesNamesWithArchivedQueryVariables>(
    QueryCostCodesNamesWithArchivedDocument,
    options
  );
}
export type QueryCostCodesNamesWithArchivedQueryHookResult = ReturnType<typeof useQueryCostCodesNamesWithArchivedQuery>;
export type QueryCostCodesNamesWithArchivedLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesNamesWithArchivedLazyQuery
>;
export type QueryCostCodesNamesWithArchivedSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesNamesWithArchivedSuspenseQuery
>;
export type QueryCostCodesNamesWithArchivedQueryResult = Apollo.QueryResult<
  QueryCostCodesNamesWithArchivedQuery,
  QueryCostCodesNamesWithArchivedQueryVariables
>;
export const QueryCostCodeIdsDocument = gql`
  query QueryCostCodeIds($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first) {
      cursor
      id
    }
  }
`;

/**
 * __useQueryCostCodeIdsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodeIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryCostCodeIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>(QueryCostCodeIdsDocument, options);
}
export function useQueryCostCodeIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>(QueryCostCodeIdsDocument, options);
}
export function useQueryCostCodeIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>(
    QueryCostCodeIdsDocument,
    options
  );
}
export type QueryCostCodeIdsQueryHookResult = ReturnType<typeof useQueryCostCodeIdsQuery>;
export type QueryCostCodeIdsLazyQueryHookResult = ReturnType<typeof useQueryCostCodeIdsLazyQuery>;
export type QueryCostCodeIdsSuspenseQueryHookResult = ReturnType<typeof useQueryCostCodeIdsSuspenseQuery>;
export type QueryCostCodeIdsQueryResult = Apollo.QueryResult<QueryCostCodeIdsQuery, QueryCostCodeIdsQueryVariables>;
export const CostCodeClockStateDocument = gql`
  query CostCodeClockState($filter: CostCodeClockStateFilter) {
    costCodeClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;

/**
 * __useCostCodeClockStateQuery__
 *
 * To run a query within a React component, call `useCostCodeClockStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodeClockStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodeClockStateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostCodeClockStateQuery(
  baseOptions?: Apollo.QueryHookOptions<CostCodeClockStateQuery, CostCodeClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodeClockStateQuery, CostCodeClockStateQueryVariables>(
    CostCodeClockStateDocument,
    options
  );
}
export function useCostCodeClockStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodeClockStateQuery, CostCodeClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodeClockStateQuery, CostCodeClockStateQueryVariables>(
    CostCodeClockStateDocument,
    options
  );
}
export function useCostCodeClockStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodeClockStateQuery, CostCodeClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodeClockStateQuery, CostCodeClockStateQueryVariables>(
    CostCodeClockStateDocument,
    options
  );
}
export type CostCodeClockStateQueryHookResult = ReturnType<typeof useCostCodeClockStateQuery>;
export type CostCodeClockStateLazyQueryHookResult = ReturnType<typeof useCostCodeClockStateLazyQuery>;
export type CostCodeClockStateSuspenseQueryHookResult = ReturnType<typeof useCostCodeClockStateSuspenseQuery>;
export type CostCodeClockStateQueryResult = Apollo.QueryResult<
  CostCodeClockStateQuery,
  CostCodeClockStateQueryVariables
>;
export const QueryEquipmentHoursDocument = gql`
  query queryEquipmentHours($filter: EquipmentHoursFilter, $first: Int, $after: String, $sort: [EquipmentHoursSort!]) {
    equipmentHours(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      equipmentId
      runningHours
      submittedByMemberId
      createdOn
      cursor
    }
  }
`;

/**
 * __useQueryEquipmentHoursQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentHoursQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryEquipmentHoursQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentHoursQuery, QueryEquipmentHoursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentHoursQuery, QueryEquipmentHoursQueryVariables>(
    QueryEquipmentHoursDocument,
    options
  );
}
export function useQueryEquipmentHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentHoursQuery, QueryEquipmentHoursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentHoursQuery, QueryEquipmentHoursQueryVariables>(
    QueryEquipmentHoursDocument,
    options
  );
}
export function useQueryEquipmentHoursSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentHoursQuery, QueryEquipmentHoursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentHoursQuery, QueryEquipmentHoursQueryVariables>(
    QueryEquipmentHoursDocument,
    options
  );
}
export type QueryEquipmentHoursQueryHookResult = ReturnType<typeof useQueryEquipmentHoursQuery>;
export type QueryEquipmentHoursLazyQueryHookResult = ReturnType<typeof useQueryEquipmentHoursLazyQuery>;
export type QueryEquipmentHoursSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentHoursSuspenseQuery>;
export type QueryEquipmentHoursQueryResult = Apollo.QueryResult<
  QueryEquipmentHoursQuery,
  QueryEquipmentHoursQueryVariables
>;
export const QueryEquipmentHoursReportDocument = gql`
  query queryEquipmentHoursReport(
    $filter: EquipmentHoursFilter
    $first: Int
    $after: String
    $sort: [EquipmentHoursSort!]
  ) {
    equipmentHours(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      equipmentId
      runningHours
      submittedByMemberId
      submittedByMember {
        id
        firstName
        lastName
      }
      createdOn
      cursor
    }
  }
`;

/**
 * __useQueryEquipmentHoursReportQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentHoursReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentHoursReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentHoursReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryEquipmentHoursReportQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentHoursReportQuery, QueryEquipmentHoursReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentHoursReportQuery, QueryEquipmentHoursReportQueryVariables>(
    QueryEquipmentHoursReportDocument,
    options
  );
}
export function useQueryEquipmentHoursReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentHoursReportQuery, QueryEquipmentHoursReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentHoursReportQuery, QueryEquipmentHoursReportQueryVariables>(
    QueryEquipmentHoursReportDocument,
    options
  );
}
export function useQueryEquipmentHoursReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentHoursReportQuery, QueryEquipmentHoursReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentHoursReportQuery, QueryEquipmentHoursReportQueryVariables>(
    QueryEquipmentHoursReportDocument,
    options
  );
}
export type QueryEquipmentHoursReportQueryHookResult = ReturnType<typeof useQueryEquipmentHoursReportQuery>;
export type QueryEquipmentHoursReportLazyQueryHookResult = ReturnType<typeof useQueryEquipmentHoursReportLazyQuery>;
export type QueryEquipmentHoursReportSuspenseQueryHookResult = ReturnType<
  typeof useQueryEquipmentHoursReportSuspenseQuery
>;
export type QueryEquipmentHoursReportQueryResult = Apollo.QueryResult<
  QueryEquipmentHoursReportQuery,
  QueryEquipmentHoursReportQueryVariables
>;
export const QueryEquipmentHoursReportDetailsDocument = gql`
  query queryEquipmentHoursReportDetails(
    $filter: EquipmentHoursFilter
    $first: Int
    $after: String
    $sort: [EquipmentHoursSort!]
  ) {
    equipmentHours(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      equipmentId
      equipment {
        id
        equipmentName
        model {
          id
          title
          category {
            id
            title
          }
          make {
            id
            title
          }
        }
      }
      runningHours
      submittedByMemberId
      submittedByMember {
        id
        firstName
        lastName
      }
      createdOn
      cursor
    }
  }
`;

/**
 * __useQueryEquipmentHoursReportDetailsQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentHoursReportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentHoursReportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentHoursReportDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryEquipmentHoursReportDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryEquipmentHoursReportDetailsQuery,
    QueryEquipmentHoursReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentHoursReportDetailsQuery, QueryEquipmentHoursReportDetailsQueryVariables>(
    QueryEquipmentHoursReportDetailsDocument,
    options
  );
}
export function useQueryEquipmentHoursReportDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryEquipmentHoursReportDetailsQuery,
    QueryEquipmentHoursReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentHoursReportDetailsQuery, QueryEquipmentHoursReportDetailsQueryVariables>(
    QueryEquipmentHoursReportDetailsDocument,
    options
  );
}
export function useQueryEquipmentHoursReportDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryEquipmentHoursReportDetailsQuery,
    QueryEquipmentHoursReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentHoursReportDetailsQuery, QueryEquipmentHoursReportDetailsQueryVariables>(
    QueryEquipmentHoursReportDetailsDocument,
    options
  );
}
export type QueryEquipmentHoursReportDetailsQueryHookResult = ReturnType<
  typeof useQueryEquipmentHoursReportDetailsQuery
>;
export type QueryEquipmentHoursReportDetailsLazyQueryHookResult = ReturnType<
  typeof useQueryEquipmentHoursReportDetailsLazyQuery
>;
export type QueryEquipmentHoursReportDetailsSuspenseQueryHookResult = ReturnType<
  typeof useQueryEquipmentHoursReportDetailsSuspenseQuery
>;
export type QueryEquipmentHoursReportDetailsQueryResult = Apollo.QueryResult<
  QueryEquipmentHoursReportDetailsQuery,
  QueryEquipmentHoursReportDetailsQueryVariables
>;
export const EquipmentTimeMetricsQueryDocument = gql`
  query EquipmentTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicLaborMetrics
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useEquipmentTimeMetricsQueryQuery__
 *
 * To run a query within a React component, call `useEquipmentTimeMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentTimeMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentTimeMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useEquipmentTimeMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentTimeMetricsQueryQuery, EquipmentTimeMetricsQueryQueryVariables> &
    ({ variables: EquipmentTimeMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentTimeMetricsQueryQuery, EquipmentTimeMetricsQueryQueryVariables>(
    EquipmentTimeMetricsQueryDocument,
    options
  );
}
export function useEquipmentTimeMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentTimeMetricsQueryQuery, EquipmentTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentTimeMetricsQueryQuery, EquipmentTimeMetricsQueryQueryVariables>(
    EquipmentTimeMetricsQueryDocument,
    options
  );
}
export function useEquipmentTimeMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentTimeMetricsQueryQuery, EquipmentTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentTimeMetricsQueryQuery, EquipmentTimeMetricsQueryQueryVariables>(
    EquipmentTimeMetricsQueryDocument,
    options
  );
}
export type EquipmentTimeMetricsQueryQueryHookResult = ReturnType<typeof useEquipmentTimeMetricsQueryQuery>;
export type EquipmentTimeMetricsQueryLazyQueryHookResult = ReturnType<typeof useEquipmentTimeMetricsQueryLazyQuery>;
export type EquipmentTimeMetricsQuerySuspenseQueryHookResult = ReturnType<
  typeof useEquipmentTimeMetricsQuerySuspenseQuery
>;
export type EquipmentTimeMetricsQueryQueryResult = Apollo.QueryResult<
  EquipmentTimeMetricsQueryQuery,
  EquipmentTimeMetricsQueryQueryVariables
>;
export const EquipmentMetricsQueryDocument = gql`
  query EquipmentMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicEquipmentLaborMetrics
    }
  }
  ${BasicEquipmentLaborMetricsFragmentDoc}
`;

/**
 * __useEquipmentMetricsQueryQuery__
 *
 * To run a query within a React component, call `useEquipmentMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useEquipmentMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentMetricsQueryQuery, EquipmentMetricsQueryQueryVariables> &
    ({ variables: EquipmentMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentMetricsQueryQuery, EquipmentMetricsQueryQueryVariables>(
    EquipmentMetricsQueryDocument,
    options
  );
}
export function useEquipmentMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentMetricsQueryQuery, EquipmentMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentMetricsQueryQuery, EquipmentMetricsQueryQueryVariables>(
    EquipmentMetricsQueryDocument,
    options
  );
}
export function useEquipmentMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentMetricsQueryQuery, EquipmentMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentMetricsQueryQuery, EquipmentMetricsQueryQueryVariables>(
    EquipmentMetricsQueryDocument,
    options
  );
}
export type EquipmentMetricsQueryQueryHookResult = ReturnType<typeof useEquipmentMetricsQueryQuery>;
export type EquipmentMetricsQueryLazyQueryHookResult = ReturnType<typeof useEquipmentMetricsQueryLazyQuery>;
export type EquipmentMetricsQuerySuspenseQueryHookResult = ReturnType<typeof useEquipmentMetricsQuerySuspenseQuery>;
export type EquipmentMetricsQueryQueryResult = Apollo.QueryResult<
  EquipmentMetricsQueryQuery,
  EquipmentMetricsQueryQueryVariables
>;
export const QueryEquipmentDocument = gql`
  query queryEquipment($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      importTtlSeconds
      importType
      lastHoursId
      imageUrl
      lastHours {
        id
        runningHours
        updatedOn
      }
      lastLocationId
      lastLocation {
        id
        longitude
        latitude
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          review
          submittedOn
          title
          type {
            id
            title
          }
          updatedOn
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
    }
  }
`;

/**
 * __useQueryEquipmentQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryEquipmentQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentQuery, QueryEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentQuery, QueryEquipmentQueryVariables>(QueryEquipmentDocument, options);
}
export function useQueryEquipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentQuery, QueryEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentQuery, QueryEquipmentQueryVariables>(QueryEquipmentDocument, options);
}
export function useQueryEquipmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentQuery, QueryEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentQuery, QueryEquipmentQueryVariables>(QueryEquipmentDocument, options);
}
export type QueryEquipmentQueryHookResult = ReturnType<typeof useQueryEquipmentQuery>;
export type QueryEquipmentLazyQueryHookResult = ReturnType<typeof useQueryEquipmentLazyQuery>;
export type QueryEquipmentSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentSuspenseQuery>;
export type QueryEquipmentQueryResult = Apollo.QueryResult<QueryEquipmentQuery, QueryEquipmentQueryVariables>;
export const QueryEquipmentNameDocument = gql`
  query queryEquipmentName($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      id
      equipmentName
      model {
        category {
          id
          title
        }
        id
        make {
          id
          title
        }
        modelNumber
        title
        year
      }
      organizationId
    }
  }
`;

/**
 * __useQueryEquipmentNameQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentNameQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryEquipmentNameQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentNameQuery, QueryEquipmentNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentNameQuery, QueryEquipmentNameQueryVariables>(
    QueryEquipmentNameDocument,
    options
  );
}
export function useQueryEquipmentNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentNameQuery, QueryEquipmentNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentNameQuery, QueryEquipmentNameQueryVariables>(
    QueryEquipmentNameDocument,
    options
  );
}
export function useQueryEquipmentNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentNameQuery, QueryEquipmentNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentNameQuery, QueryEquipmentNameQueryVariables>(
    QueryEquipmentNameDocument,
    options
  );
}
export type QueryEquipmentNameQueryHookResult = ReturnType<typeof useQueryEquipmentNameQuery>;
export type QueryEquipmentNameLazyQueryHookResult = ReturnType<typeof useQueryEquipmentNameLazyQuery>;
export type QueryEquipmentNameSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentNameSuspenseQuery>;
export type QueryEquipmentNameQueryResult = Apollo.QueryResult<
  QueryEquipmentNameQuery,
  QueryEquipmentNameQueryVariables
>;
export const QueryBasicEquipmentNameDocument = gql`
  query queryBasicEquipmentName($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      id
      equipmentName
      model {
        category {
          id
          title
        }
        id
        make {
          id
          title
        }
        modelNumber
        title
        year
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      organizationId
    }
  }
`;

/**
 * __useQueryBasicEquipmentNameQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentNameQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryBasicEquipmentNameQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryBasicEquipmentNameQuery, QueryBasicEquipmentNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBasicEquipmentNameQuery, QueryBasicEquipmentNameQueryVariables>(
    QueryBasicEquipmentNameDocument,
    options
  );
}
export function useQueryBasicEquipmentNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryBasicEquipmentNameQuery, QueryBasicEquipmentNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBasicEquipmentNameQuery, QueryBasicEquipmentNameQueryVariables>(
    QueryBasicEquipmentNameDocument,
    options
  );
}
export function useQueryBasicEquipmentNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryBasicEquipmentNameQuery, QueryBasicEquipmentNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBasicEquipmentNameQuery, QueryBasicEquipmentNameQueryVariables>(
    QueryBasicEquipmentNameDocument,
    options
  );
}
export type QueryBasicEquipmentNameQueryHookResult = ReturnType<typeof useQueryBasicEquipmentNameQuery>;
export type QueryBasicEquipmentNameLazyQueryHookResult = ReturnType<typeof useQueryBasicEquipmentNameLazyQuery>;
export type QueryBasicEquipmentNameSuspenseQueryHookResult = ReturnType<typeof useQueryBasicEquipmentNameSuspenseQuery>;
export type QueryBasicEquipmentNameQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentNameQuery,
  QueryBasicEquipmentNameQueryVariables
>;
export const QueryBasicEquipmentWithCategoryDocument = gql`
  query queryBasicEquipmentWithCategory(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      cursor
      id
      model {
        id
        category {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useQueryBasicEquipmentWithCategoryQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithCategoryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithCategoryQuery,
    QueryBasicEquipmentWithCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBasicEquipmentWithCategoryQuery, QueryBasicEquipmentWithCategoryQueryVariables>(
    QueryBasicEquipmentWithCategoryDocument,
    options
  );
}
export function useQueryBasicEquipmentWithCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithCategoryQuery,
    QueryBasicEquipmentWithCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBasicEquipmentWithCategoryQuery, QueryBasicEquipmentWithCategoryQueryVariables>(
    QueryBasicEquipmentWithCategoryDocument,
    options
  );
}
export function useQueryBasicEquipmentWithCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithCategoryQuery,
    QueryBasicEquipmentWithCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBasicEquipmentWithCategoryQuery, QueryBasicEquipmentWithCategoryQueryVariables>(
    QueryBasicEquipmentWithCategoryDocument,
    options
  );
}
export type QueryBasicEquipmentWithCategoryQueryHookResult = ReturnType<typeof useQueryBasicEquipmentWithCategoryQuery>;
export type QueryBasicEquipmentWithCategoryLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCategoryLazyQuery
>;
export type QueryBasicEquipmentWithCategorySuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCategorySuspenseQuery
>;
export type QueryBasicEquipmentWithCategoryQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithCategoryQuery,
  QueryBasicEquipmentWithCategoryQueryVariables
>;
export const QueryEquipmentByIdDocument = gql`
  query queryEquipmentById($equipmentId: Uuid!) {
    equipment(filter: { id: { equal: $equipmentId } }) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      imageUrl
      importTtlSeconds
      importType
      lastHoursId
      lastLocationId
      lastHours {
        id
        runningHours
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          imageUrl
          review
          submittedOn
          title
          updatedOn
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        imageUrl
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
      lastLocation {
        id
        longitude
        latitude
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;

/**
 * __useQueryEquipmentByIdQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentByIdQuery({
 *   variables: {
 *      equipmentId: // value for 'equipmentId'
 *   },
 * });
 */
export function useQueryEquipmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEquipmentByIdQuery, QueryEquipmentByIdQueryVariables> &
    ({ variables: QueryEquipmentByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentByIdQuery, QueryEquipmentByIdQueryVariables>(
    QueryEquipmentByIdDocument,
    options
  );
}
export function useQueryEquipmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentByIdQuery, QueryEquipmentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentByIdQuery, QueryEquipmentByIdQueryVariables>(
    QueryEquipmentByIdDocument,
    options
  );
}
export function useQueryEquipmentByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentByIdQuery, QueryEquipmentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentByIdQuery, QueryEquipmentByIdQueryVariables>(
    QueryEquipmentByIdDocument,
    options
  );
}
export type QueryEquipmentByIdQueryHookResult = ReturnType<typeof useQueryEquipmentByIdQuery>;
export type QueryEquipmentByIdLazyQueryHookResult = ReturnType<typeof useQueryEquipmentByIdLazyQuery>;
export type QueryEquipmentByIdSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentByIdSuspenseQuery>;
export type QueryEquipmentByIdQueryResult = Apollo.QueryResult<
  QueryEquipmentByIdQuery,
  QueryEquipmentByIdQueryVariables
>;
export const QueryManageEquipmentDocument = gql`
  query queryManageEquipment($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      imageUrl
      importTtlSeconds
      importType
      lastHoursId
      lastLocationId
      updatedByMember {
        id
        firstName
        lastName
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          imageUrl
          review
          submittedOn
          title
          updatedOn
          type {
            id
            title
          }
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        imageUrl
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
      lastHours {
        id
        runningHours
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;

/**
 * __useQueryManageEquipmentQuery__
 *
 * To run a query within a React component, call `useQueryManageEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryManageEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryManageEquipmentQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryManageEquipmentQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables>(
    QueryManageEquipmentDocument,
    options
  );
}
export function useQueryManageEquipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables>(
    QueryManageEquipmentDocument,
    options
  );
}
export function useQueryManageEquipmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables>(
    QueryManageEquipmentDocument,
    options
  );
}
export type QueryManageEquipmentQueryHookResult = ReturnType<typeof useQueryManageEquipmentQuery>;
export type QueryManageEquipmentLazyQueryHookResult = ReturnType<typeof useQueryManageEquipmentLazyQuery>;
export type QueryManageEquipmentSuspenseQueryHookResult = ReturnType<typeof useQueryManageEquipmentSuspenseQuery>;
export type QueryManageEquipmentQueryResult = Apollo.QueryResult<
  QueryManageEquipmentQuery,
  QueryManageEquipmentQueryVariables
>;
export const QueryEquipmentIdsDocument = gql`
  query queryEquipmentIds($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      cursor
    }
  }
`;

/**
 * __useQueryEquipmentIdsQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryEquipmentIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>(QueryEquipmentIdsDocument, options);
}
export function useQueryEquipmentIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>(
    QueryEquipmentIdsDocument,
    options
  );
}
export function useQueryEquipmentIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>(
    QueryEquipmentIdsDocument,
    options
  );
}
export type QueryEquipmentIdsQueryHookResult = ReturnType<typeof useQueryEquipmentIdsQuery>;
export type QueryEquipmentIdsLazyQueryHookResult = ReturnType<typeof useQueryEquipmentIdsLazyQuery>;
export type QueryEquipmentIdsSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentIdsSuspenseQuery>;
export type QueryEquipmentIdsQueryResult = Apollo.QueryResult<QueryEquipmentIdsQuery, QueryEquipmentIdsQueryVariables>;
export const QueryBasicEquipmentDocument = gql`
  query queryBasicEquipment($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useQueryBasicEquipmentQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryBasicEquipmentQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryBasicEquipmentQuery, QueryBasicEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBasicEquipmentQuery, QueryBasicEquipmentQueryVariables>(
    QueryBasicEquipmentDocument,
    options
  );
}
export function useQueryBasicEquipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryBasicEquipmentQuery, QueryBasicEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBasicEquipmentQuery, QueryBasicEquipmentQueryVariables>(
    QueryBasicEquipmentDocument,
    options
  );
}
export function useQueryBasicEquipmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryBasicEquipmentQuery, QueryBasicEquipmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBasicEquipmentQuery, QueryBasicEquipmentQueryVariables>(
    QueryBasicEquipmentDocument,
    options
  );
}
export type QueryBasicEquipmentQueryHookResult = ReturnType<typeof useQueryBasicEquipmentQuery>;
export type QueryBasicEquipmentLazyQueryHookResult = ReturnType<typeof useQueryBasicEquipmentLazyQuery>;
export type QueryBasicEquipmentSuspenseQueryHookResult = ReturnType<typeof useQueryBasicEquipmentSuspenseQuery>;
export type QueryBasicEquipmentQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentQuery,
  QueryBasicEquipmentQueryVariables
>;
export const QueryEquipmentLastLocationDocument = gql`
  query queryEquipmentLastLocation($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      id
      cursor
      deletedOn
      equipmentName
      lastLocationId
      lastLocation {
        id
        longitude
        latitude
      }
    }
  }
`;

/**
 * __useQueryEquipmentLastLocationQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentLastLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentLastLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentLastLocationQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryEquipmentLastLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentLastLocationQuery, QueryEquipmentLastLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentLastLocationQuery, QueryEquipmentLastLocationQueryVariables>(
    QueryEquipmentLastLocationDocument,
    options
  );
}
export function useQueryEquipmentLastLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentLastLocationQuery, QueryEquipmentLastLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentLastLocationQuery, QueryEquipmentLastLocationQueryVariables>(
    QueryEquipmentLastLocationDocument,
    options
  );
}
export function useQueryEquipmentLastLocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryEquipmentLastLocationQuery,
    QueryEquipmentLastLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentLastLocationQuery, QueryEquipmentLastLocationQueryVariables>(
    QueryEquipmentLastLocationDocument,
    options
  );
}
export type QueryEquipmentLastLocationQueryHookResult = ReturnType<typeof useQueryEquipmentLastLocationQuery>;
export type QueryEquipmentLastLocationLazyQueryHookResult = ReturnType<typeof useQueryEquipmentLastLocationLazyQuery>;
export type QueryEquipmentLastLocationSuspenseQueryHookResult = ReturnType<
  typeof useQueryEquipmentLastLocationSuspenseQuery
>;
export type QueryEquipmentLastLocationQueryResult = Apollo.QueryResult<
  QueryEquipmentLastLocationQuery,
  QueryEquipmentLastLocationQueryVariables
>;
export const EquipmentDisplayDocument = gql`
  query EquipmentDisplay($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      ...EquipmentDisplay
    }
  }
  ${EquipmentDisplayFragmentDoc}
`;

/**
 * __useEquipmentDisplayQuery__
 *
 * To run a query within a React component, call `useEquipmentDisplayQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentDisplayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentDisplayQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useEquipmentDisplayQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>(EquipmentDisplayDocument, options);
}
export function useEquipmentDisplayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>(EquipmentDisplayDocument, options);
}
export function useEquipmentDisplaySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>(
    EquipmentDisplayDocument,
    options
  );
}
export type EquipmentDisplayQueryHookResult = ReturnType<typeof useEquipmentDisplayQuery>;
export type EquipmentDisplayLazyQueryHookResult = ReturnType<typeof useEquipmentDisplayLazyQuery>;
export type EquipmentDisplaySuspenseQueryHookResult = ReturnType<typeof useEquipmentDisplaySuspenseQuery>;
export type EquipmentDisplayQueryResult = Apollo.QueryResult<EquipmentDisplayQuery, EquipmentDisplayQueryVariables>;
export const EquipmentClockStateDocument = gql`
  query EquipmentClockState($filter: EquipmentClockStateFilter) {
    equipmentClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;

/**
 * __useEquipmentClockStateQuery__
 *
 * To run a query within a React component, call `useEquipmentClockStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentClockStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentClockStateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEquipmentClockStateQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentClockStateQuery, EquipmentClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentClockStateQuery, EquipmentClockStateQueryVariables>(
    EquipmentClockStateDocument,
    options
  );
}
export function useEquipmentClockStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentClockStateQuery, EquipmentClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentClockStateQuery, EquipmentClockStateQueryVariables>(
    EquipmentClockStateDocument,
    options
  );
}
export function useEquipmentClockStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentClockStateQuery, EquipmentClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentClockStateQuery, EquipmentClockStateQueryVariables>(
    EquipmentClockStateDocument,
    options
  );
}
export type EquipmentClockStateQueryHookResult = ReturnType<typeof useEquipmentClockStateQuery>;
export type EquipmentClockStateLazyQueryHookResult = ReturnType<typeof useEquipmentClockStateLazyQuery>;
export type EquipmentClockStateSuspenseQueryHookResult = ReturnType<typeof useEquipmentClockStateSuspenseQuery>;
export type EquipmentClockStateQueryResult = Apollo.QueryResult<
  EquipmentClockStateQuery,
  EquipmentClockStateQueryVariables
>;
export const QueryInspectionChecklistsDocument = gql`
  query QueryInspectionChecklists(
    $filter: InspectionChecklistFilter
    $sort: [InspectionChecklistSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    inspectionChecklists(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      ...InspectionChecklistFragment
      cursor
    }
  }
  ${InspectionChecklistFragmentFragmentDoc}
`;

/**
 * __useQueryInspectionChecklistsQuery__
 *
 * To run a query within a React component, call `useQueryInspectionChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryInspectionChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryInspectionChecklistsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryInspectionChecklistsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryInspectionChecklistsQuery, QueryInspectionChecklistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryInspectionChecklistsQuery, QueryInspectionChecklistsQueryVariables>(
    QueryInspectionChecklistsDocument,
    options
  );
}
export function useQueryInspectionChecklistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryInspectionChecklistsQuery, QueryInspectionChecklistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryInspectionChecklistsQuery, QueryInspectionChecklistsQueryVariables>(
    QueryInspectionChecklistsDocument,
    options
  );
}
export function useQueryInspectionChecklistsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryInspectionChecklistsQuery, QueryInspectionChecklistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryInspectionChecklistsQuery, QueryInspectionChecklistsQueryVariables>(
    QueryInspectionChecklistsDocument,
    options
  );
}
export type QueryInspectionChecklistsQueryHookResult = ReturnType<typeof useQueryInspectionChecklistsQuery>;
export type QueryInspectionChecklistsLazyQueryHookResult = ReturnType<typeof useQueryInspectionChecklistsLazyQuery>;
export type QueryInspectionChecklistsSuspenseQueryHookResult = ReturnType<
  typeof useQueryInspectionChecklistsSuspenseQuery
>;
export type QueryInspectionChecklistsQueryResult = Apollo.QueryResult<
  QueryInspectionChecklistsQuery,
  QueryInspectionChecklistsQueryVariables
>;
export const QueryMemberGpsStatusesDocument = gql`
  query queryMemberGpsStatuses(
    $filter: MemberGpsStatusFilter
    $sort: [MemberGpsStatusSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    memberGpsStatuses(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      status
      localTime
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      timeEntry {
        id
        startTime
        endTime
        project {
          id
          title
          depth
          ancestors {
            id
            title
            depth
          }
        }
      }
      member {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useQueryMemberGpsStatusesQuery__
 *
 * To run a query within a React component, call `useQueryMemberGpsStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberGpsStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberGpsStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryMemberGpsStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberGpsStatusesQuery, QueryMemberGpsStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberGpsStatusesQuery, QueryMemberGpsStatusesQueryVariables>(
    QueryMemberGpsStatusesDocument,
    options
  );
}
export function useQueryMemberGpsStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberGpsStatusesQuery, QueryMemberGpsStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberGpsStatusesQuery, QueryMemberGpsStatusesQueryVariables>(
    QueryMemberGpsStatusesDocument,
    options
  );
}
export function useQueryMemberGpsStatusesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberGpsStatusesQuery, QueryMemberGpsStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberGpsStatusesQuery, QueryMemberGpsStatusesQueryVariables>(
    QueryMemberGpsStatusesDocument,
    options
  );
}
export type QueryMemberGpsStatusesQueryHookResult = ReturnType<typeof useQueryMemberGpsStatusesQuery>;
export type QueryMemberGpsStatusesLazyQueryHookResult = ReturnType<typeof useQueryMemberGpsStatusesLazyQuery>;
export type QueryMemberGpsStatusesSuspenseQueryHookResult = ReturnType<typeof useQueryMemberGpsStatusesSuspenseQuery>;
export type QueryMemberGpsStatusesQueryResult = Apollo.QueryResult<
  QueryMemberGpsStatusesQuery,
  QueryMemberGpsStatusesQueryVariables
>;
export const MemberGroupDocument = gql`
  query MemberGroup($after: String, $filter: MemberGroupFilter, $first: Int) {
    memberGroups(after: $after, first: $first, filter: $filter, sort: [{ groupName: asc }]) {
      id
      organizationId
      groupName
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useMemberGroupQuery__
 *
 * To run a query within a React component, call `useMemberGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberGroupQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMemberGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberGroupQuery, MemberGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberGroupQuery, MemberGroupQueryVariables>(MemberGroupDocument, options);
}
export function useMemberGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberGroupQuery, MemberGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberGroupQuery, MemberGroupQueryVariables>(MemberGroupDocument, options);
}
export function useMemberGroupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberGroupQuery, MemberGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberGroupQuery, MemberGroupQueryVariables>(MemberGroupDocument, options);
}
export type MemberGroupQueryHookResult = ReturnType<typeof useMemberGroupQuery>;
export type MemberGroupLazyQueryHookResult = ReturnType<typeof useMemberGroupLazyQuery>;
export type MemberGroupSuspenseQueryHookResult = ReturnType<typeof useMemberGroupSuspenseQuery>;
export type MemberGroupQueryResult = Apollo.QueryResult<MemberGroupQuery, MemberGroupQueryVariables>;
export const QueryMemberLocationsDocument = gql`
  query queryMemberLocations($filter: MemberLocationFilter, $sort: [MemberLocationSort!], $first: Int, $after: String) {
    memberLocations(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      memberId
      longitude
      latitude
      horizontalAccuracy
      altitude
      verticalAccuracy
      timeGmt
      timeLocal
      timeDst
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      member {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useQueryMemberLocationsQuery__
 *
 * To run a query within a React component, call `useQueryMemberLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryMemberLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberLocationsQuery, QueryMemberLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberLocationsQuery, QueryMemberLocationsQueryVariables>(
    QueryMemberLocationsDocument,
    options
  );
}
export function useQueryMemberLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberLocationsQuery, QueryMemberLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberLocationsQuery, QueryMemberLocationsQueryVariables>(
    QueryMemberLocationsDocument,
    options
  );
}
export function useQueryMemberLocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberLocationsQuery, QueryMemberLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberLocationsQuery, QueryMemberLocationsQueryVariables>(
    QueryMemberLocationsDocument,
    options
  );
}
export type QueryMemberLocationsQueryHookResult = ReturnType<typeof useQueryMemberLocationsQuery>;
export type QueryMemberLocationsLazyQueryHookResult = ReturnType<typeof useQueryMemberLocationsLazyQuery>;
export type QueryMemberLocationsSuspenseQueryHookResult = ReturnType<typeof useQueryMemberLocationsSuspenseQuery>;
export type QueryMemberLocationsQueryResult = Apollo.QueryResult<
  QueryMemberLocationsQuery,
  QueryMemberLocationsQueryVariables
>;
export const QueryMemberLocationDocument = gql`
  query queryMemberLocation($memberLocationId: Uuid!) {
    memberLocations(filter: { id: { equal: $memberLocationId } }) {
      id
      memberId
      longitude
      latitude
      horizontalAccuracy
      altitude
      verticalAccuracy
      timeGmt
      timeLocal
      timeDst
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      member {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useQueryMemberLocationQuery__
 *
 * To run a query within a React component, call `useQueryMemberLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberLocationQuery({
 *   variables: {
 *      memberLocationId: // value for 'memberLocationId'
 *   },
 * });
 */
export function useQueryMemberLocationQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMemberLocationQuery, QueryMemberLocationQueryVariables> &
    ({ variables: QueryMemberLocationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberLocationQuery, QueryMemberLocationQueryVariables>(
    QueryMemberLocationDocument,
    options
  );
}
export function useQueryMemberLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberLocationQuery, QueryMemberLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberLocationQuery, QueryMemberLocationQueryVariables>(
    QueryMemberLocationDocument,
    options
  );
}
export function useQueryMemberLocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberLocationQuery, QueryMemberLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberLocationQuery, QueryMemberLocationQueryVariables>(
    QueryMemberLocationDocument,
    options
  );
}
export type QueryMemberLocationQueryHookResult = ReturnType<typeof useQueryMemberLocationQuery>;
export type QueryMemberLocationLazyQueryHookResult = ReturnType<typeof useQueryMemberLocationLazyQuery>;
export type QueryMemberLocationSuspenseQueryHookResult = ReturnType<typeof useQueryMemberLocationSuspenseQuery>;
export type QueryMemberLocationQueryResult = Apollo.QueryResult<
  QueryMemberLocationQuery,
  QueryMemberLocationQueryVariables
>;
export const QueryMemberLocksDocument = gql`
  query queryMemberLocks($first: Int, $filter: MemberLockFilter) {
    memberLocks(first: $first, filter: $filter) {
      id
      effectiveDate
      updaterMemberId
      memberId
      createdOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;

/**
 * __useQueryMemberLocksQuery__
 *
 * To run a query within a React component, call `useQueryMemberLocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberLocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberLocksQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryMemberLocksQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>(QueryMemberLocksDocument, options);
}
export function useQueryMemberLocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>(QueryMemberLocksDocument, options);
}
export function useQueryMemberLocksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>(
    QueryMemberLocksDocument,
    options
  );
}
export type QueryMemberLocksQueryHookResult = ReturnType<typeof useQueryMemberLocksQuery>;
export type QueryMemberLocksLazyQueryHookResult = ReturnType<typeof useQueryMemberLocksLazyQuery>;
export type QueryMemberLocksSuspenseQueryHookResult = ReturnType<typeof useQueryMemberLocksSuspenseQuery>;
export type QueryMemberLocksQueryResult = Apollo.QueryResult<QueryMemberLocksQuery, QueryMemberLocksQueryVariables>;
export const QueryMemberLockDocument = gql`
  query queryMemberLock($memberId: Uuid!) {
    memberLocks(filter: { memberId: { equal: $memberId } }) {
      id
      effectiveDate
      updaterMemberId
      memberId
      createdOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;

/**
 * __useQueryMemberLockQuery__
 *
 * To run a query within a React component, call `useQueryMemberLockQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberLockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberLockQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useQueryMemberLockQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMemberLockQuery, QueryMemberLockQueryVariables> &
    ({ variables: QueryMemberLockQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberLockQuery, QueryMemberLockQueryVariables>(QueryMemberLockDocument, options);
}
export function useQueryMemberLockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberLockQuery, QueryMemberLockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberLockQuery, QueryMemberLockQueryVariables>(QueryMemberLockDocument, options);
}
export function useQueryMemberLockSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberLockQuery, QueryMemberLockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberLockQuery, QueryMemberLockQueryVariables>(QueryMemberLockDocument, options);
}
export type QueryMemberLockQueryHookResult = ReturnType<typeof useQueryMemberLockQuery>;
export type QueryMemberLockLazyQueryHookResult = ReturnType<typeof useQueryMemberLockLazyQuery>;
export type QueryMemberLockSuspenseQueryHookResult = ReturnType<typeof useQueryMemberLockSuspenseQuery>;
export type QueryMemberLockQueryResult = Apollo.QueryResult<QueryMemberLockQuery, QueryMemberLockQueryVariables>;
export const MemberTimeMetricsQueryDocument = gql`
  query MemberTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      ...BasicMemberLaborMetrics
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
`;

/**
 * __useMemberTimeMetricsQueryQuery__
 *
 * To run a query within a React component, call `useMemberTimeMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTimeMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTimeMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useMemberTimeMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables> &
    ({ variables: MemberTimeMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables>(
    MemberTimeMetricsQueryDocument,
    options
  );
}
export function useMemberTimeMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables>(
    MemberTimeMetricsQueryDocument,
    options
  );
}
export function useMemberTimeMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberTimeMetricsQueryQuery, MemberTimeMetricsQueryQueryVariables>(
    MemberTimeMetricsQueryDocument,
    options
  );
}
export type MemberTimeMetricsQueryQueryHookResult = ReturnType<typeof useMemberTimeMetricsQueryQuery>;
export type MemberTimeMetricsQueryLazyQueryHookResult = ReturnType<typeof useMemberTimeMetricsQueryLazyQuery>;
export type MemberTimeMetricsQuerySuspenseQueryHookResult = ReturnType<typeof useMemberTimeMetricsQuerySuspenseQuery>;
export type MemberTimeMetricsQueryQueryResult = Apollo.QueryResult<
  MemberTimeMetricsQueryQuery,
  MemberTimeMetricsQueryQueryVariables
>;
export const MemberMetricsQueryDocument = gql`
  query MemberMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      ...MemberLaborMetrics
    }
  }
  ${MemberLaborMetricsFragmentDoc}
`;

/**
 * __useMemberMetricsQueryQuery__
 *
 * To run a query within a React component, call `useMemberMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useMemberMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<MemberMetricsQueryQuery, MemberMetricsQueryQueryVariables> &
    ({ variables: MemberMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberMetricsQueryQuery, MemberMetricsQueryQueryVariables>(
    MemberMetricsQueryDocument,
    options
  );
}
export function useMemberMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberMetricsQueryQuery, MemberMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberMetricsQueryQuery, MemberMetricsQueryQueryVariables>(
    MemberMetricsQueryDocument,
    options
  );
}
export function useMemberMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberMetricsQueryQuery, MemberMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberMetricsQueryQuery, MemberMetricsQueryQueryVariables>(
    MemberMetricsQueryDocument,
    options
  );
}
export type MemberMetricsQueryQueryHookResult = ReturnType<typeof useMemberMetricsQueryQuery>;
export type MemberMetricsQueryLazyQueryHookResult = ReturnType<typeof useMemberMetricsQueryLazyQuery>;
export type MemberMetricsQuerySuspenseQueryHookResult = ReturnType<typeof useMemberMetricsQuerySuspenseQuery>;
export type MemberMetricsQueryQueryResult = Apollo.QueryResult<
  MemberMetricsQueryQuery,
  MemberMetricsQueryQueryVariables
>;
export const MemberTimeOffOnlyTimeOnlyMetricsQueryDocument = gql`
  query MemberTimeOffOnlyTimeOnlyMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      start
      end
      memberId
      pto
      upto
    }
  }
`;

/**
 * __useMemberTimeOffOnlyTimeOnlyMetricsQueryQuery__
 *
 * To run a query within a React component, call `useMemberTimeOffOnlyTimeOnlyMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTimeOffOnlyTimeOnlyMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTimeOffOnlyTimeOnlyMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useMemberTimeOffOnlyTimeOnlyMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
    MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
  > &
    ({ variables: MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
    MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
  >(MemberTimeOffOnlyTimeOnlyMetricsQueryDocument, options);
}
export function useMemberTimeOffOnlyTimeOnlyMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
    MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
    MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
  >(MemberTimeOffOnlyTimeOnlyMetricsQueryDocument, options);
}
export function useMemberTimeOffOnlyTimeOnlyMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
    MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
    MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
  >(MemberTimeOffOnlyTimeOnlyMetricsQueryDocument, options);
}
export type MemberTimeOffOnlyTimeOnlyMetricsQueryQueryHookResult = ReturnType<
  typeof useMemberTimeOffOnlyTimeOnlyMetricsQueryQuery
>;
export type MemberTimeOffOnlyTimeOnlyMetricsQueryLazyQueryHookResult = ReturnType<
  typeof useMemberTimeOffOnlyTimeOnlyMetricsQueryLazyQuery
>;
export type MemberTimeOffOnlyTimeOnlyMetricsQuerySuspenseQueryHookResult = ReturnType<
  typeof useMemberTimeOffOnlyTimeOnlyMetricsQuerySuspenseQuery
>;
export type MemberTimeOffOnlyTimeOnlyMetricsQueryQueryResult = Apollo.QueryResult<
  MemberTimeOffOnlyTimeOnlyMetricsQueryQuery,
  MemberTimeOffOnlyTimeOnlyMetricsQueryQueryVariables
>;
export const MemberTimeOffOnlyMetricsQueryDocument = gql`
  query MemberTimeOffOnlyMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      start
      end
      memberId
      pto
      ptoCents
      upto
    }
  }
`;

/**
 * __useMemberTimeOffOnlyMetricsQueryQuery__
 *
 * To run a query within a React component, call `useMemberTimeOffOnlyMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTimeOffOnlyMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTimeOffOnlyMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useMemberTimeOffOnlyMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberTimeOffOnlyMetricsQueryQuery,
    MemberTimeOffOnlyMetricsQueryQueryVariables
  > &
    ({ variables: MemberTimeOffOnlyMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberTimeOffOnlyMetricsQueryQuery, MemberTimeOffOnlyMetricsQueryQueryVariables>(
    MemberTimeOffOnlyMetricsQueryDocument,
    options
  );
}
export function useMemberTimeOffOnlyMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberTimeOffOnlyMetricsQueryQuery,
    MemberTimeOffOnlyMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberTimeOffOnlyMetricsQueryQuery, MemberTimeOffOnlyMetricsQueryQueryVariables>(
    MemberTimeOffOnlyMetricsQueryDocument,
    options
  );
}
export function useMemberTimeOffOnlyMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MemberTimeOffOnlyMetricsQueryQuery,
    MemberTimeOffOnlyMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberTimeOffOnlyMetricsQueryQuery, MemberTimeOffOnlyMetricsQueryQueryVariables>(
    MemberTimeOffOnlyMetricsQueryDocument,
    options
  );
}
export type MemberTimeOffOnlyMetricsQueryQueryHookResult = ReturnType<typeof useMemberTimeOffOnlyMetricsQueryQuery>;
export type MemberTimeOffOnlyMetricsQueryLazyQueryHookResult = ReturnType<
  typeof useMemberTimeOffOnlyMetricsQueryLazyQuery
>;
export type MemberTimeOffOnlyMetricsQuerySuspenseQueryHookResult = ReturnType<
  typeof useMemberTimeOffOnlyMetricsQuerySuspenseQuery
>;
export type MemberTimeOffOnlyMetricsQueryQueryResult = Apollo.QueryResult<
  MemberTimeOffOnlyMetricsQueryQuery,
  MemberTimeOffOnlyMetricsQueryQueryVariables
>;
export const MemberProjectCostCodeEquipmentTimeMetricsQueryDocument = gql`
  query MemberProjectCostCodeEquipmentTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberProjectCostCodeEquipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      projectId
      costCodeId
      equipmentId
      ...BasicProjectLaborMetrics
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useMemberProjectCostCodeEquipmentTimeMetricsQueryQuery__
 *
 * To run a query within a React component, call `useMemberProjectCostCodeEquipmentTimeMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberProjectCostCodeEquipmentTimeMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberProjectCostCodeEquipmentTimeMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useMemberProjectCostCodeEquipmentTimeMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
    MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
  > &
    ({ variables: MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
    MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
  >(MemberProjectCostCodeEquipmentTimeMetricsQueryDocument, options);
}
export function useMemberProjectCostCodeEquipmentTimeMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
    MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
    MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
  >(MemberProjectCostCodeEquipmentTimeMetricsQueryDocument, options);
}
export function useMemberProjectCostCodeEquipmentTimeMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
    MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
    MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
  >(MemberProjectCostCodeEquipmentTimeMetricsQueryDocument, options);
}
export type MemberProjectCostCodeEquipmentTimeMetricsQueryQueryHookResult = ReturnType<
  typeof useMemberProjectCostCodeEquipmentTimeMetricsQueryQuery
>;
export type MemberProjectCostCodeEquipmentTimeMetricsQueryLazyQueryHookResult = ReturnType<
  typeof useMemberProjectCostCodeEquipmentTimeMetricsQueryLazyQuery
>;
export type MemberProjectCostCodeEquipmentTimeMetricsQuerySuspenseQueryHookResult = ReturnType<
  typeof useMemberProjectCostCodeEquipmentTimeMetricsQuerySuspenseQuery
>;
export type MemberProjectCostCodeEquipmentTimeMetricsQueryQueryResult = Apollo.QueryResult<
  MemberProjectCostCodeEquipmentTimeMetricsQueryQuery,
  MemberProjectCostCodeEquipmentTimeMetricsQueryQueryVariables
>;
export const MemberProjectCostCodeEquipmentMetricsQueryDocument = gql`
  query MemberProjectCostCodeEquipmentMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberProjectCostCodeEquipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      projectId
      costCodeId
      equipmentId
      ...ProjectLaborMetrics
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useMemberProjectCostCodeEquipmentMetricsQueryQuery__
 *
 * To run a query within a React component, call `useMemberProjectCostCodeEquipmentMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberProjectCostCodeEquipmentMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberProjectCostCodeEquipmentMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useMemberProjectCostCodeEquipmentMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberProjectCostCodeEquipmentMetricsQueryQuery,
    MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
  > &
    ({ variables: MemberProjectCostCodeEquipmentMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberProjectCostCodeEquipmentMetricsQueryQuery,
    MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
  >(MemberProjectCostCodeEquipmentMetricsQueryDocument, options);
}
export function useMemberProjectCostCodeEquipmentMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberProjectCostCodeEquipmentMetricsQueryQuery,
    MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberProjectCostCodeEquipmentMetricsQueryQuery,
    MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
  >(MemberProjectCostCodeEquipmentMetricsQueryDocument, options);
}
export function useMemberProjectCostCodeEquipmentMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MemberProjectCostCodeEquipmentMetricsQueryQuery,
    MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberProjectCostCodeEquipmentMetricsQueryQuery,
    MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
  >(MemberProjectCostCodeEquipmentMetricsQueryDocument, options);
}
export type MemberProjectCostCodeEquipmentMetricsQueryQueryHookResult = ReturnType<
  typeof useMemberProjectCostCodeEquipmentMetricsQueryQuery
>;
export type MemberProjectCostCodeEquipmentMetricsQueryLazyQueryHookResult = ReturnType<
  typeof useMemberProjectCostCodeEquipmentMetricsQueryLazyQuery
>;
export type MemberProjectCostCodeEquipmentMetricsQuerySuspenseQueryHookResult = ReturnType<
  typeof useMemberProjectCostCodeEquipmentMetricsQuerySuspenseQuery
>;
export type MemberProjectCostCodeEquipmentMetricsQueryQueryResult = Apollo.QueryResult<
  MemberProjectCostCodeEquipmentMetricsQueryQuery,
  MemberProjectCostCodeEquipmentMetricsQueryQueryVariables
>;
export const QueryMembersDocument = gql`
  query QueryMembers($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberNumber
      archivedOn
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
    }
  }
`;

/**
 * __useQueryMembersQuery__
 *
 * To run a query within a React component, call `useQueryMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMembersQuery, QueryMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersQuery, QueryMembersQueryVariables>(QueryMembersDocument, options);
}
export function useQueryMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersQuery, QueryMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersQuery, QueryMembersQueryVariables>(QueryMembersDocument, options);
}
export function useQueryMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersQuery, QueryMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersQuery, QueryMembersQueryVariables>(QueryMembersDocument, options);
}
export type QueryMembersQueryHookResult = ReturnType<typeof useQueryMembersQuery>;
export type QueryMembersLazyQueryHookResult = ReturnType<typeof useQueryMembersLazyQuery>;
export type QueryMembersSuspenseQueryHookResult = ReturnType<typeof useQueryMembersSuspenseQuery>;
export type QueryMembersQueryResult = Apollo.QueryResult<QueryMembersQuery, QueryMembersQueryVariables>;
export const QueryMembersWithSortDocument = gql`
  query QueryMembersWithSort($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      archivedOn
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
    }
  }
`;

/**
 * __useQueryMembersWithSortQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithSortQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithSortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithSortQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMembersWithSortQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMembersWithSortQuery, QueryMembersWithSortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithSortQuery, QueryMembersWithSortQueryVariables>(
    QueryMembersWithSortDocument,
    options
  );
}
export function useQueryMembersWithSortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersWithSortQuery, QueryMembersWithSortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithSortQuery, QueryMembersWithSortQueryVariables>(
    QueryMembersWithSortDocument,
    options
  );
}
export function useQueryMembersWithSortSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersWithSortQuery, QueryMembersWithSortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithSortQuery, QueryMembersWithSortQueryVariables>(
    QueryMembersWithSortDocument,
    options
  );
}
export type QueryMembersWithSortQueryHookResult = ReturnType<typeof useQueryMembersWithSortQuery>;
export type QueryMembersWithSortLazyQueryHookResult = ReturnType<typeof useQueryMembersWithSortLazyQuery>;
export type QueryMembersWithSortSuspenseQueryHookResult = ReturnType<typeof useQueryMembersWithSortSuspenseQuery>;
export type QueryMembersWithSortQueryResult = Apollo.QueryResult<
  QueryMembersWithSortQuery,
  QueryMembersWithSortQueryVariables
>;
export const QueryMembersForTimeMetericsWithMoreDocument = gql`
  query QueryMembersForTimeMetericsWithMore(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $safetySignaturesFilter: SafetySignatureFilter!
    $safetySignaturesSort: [SafetySignatureSort!]
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      archivedOn
      memberNumber
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        openBreak {
          id
        }
      }
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicMemberLaborMetrics
      }
      memberTimeDocuments(filter: $memberTimeDocumentFilter, sort: $memberTimeDocumentSort) {
        ...MemberTimeDocumentFragment
      }
      safetySignatures(filter: $safetySignaturesFilter, sort: $safetySignaturesSort) {
        ...BasicSafetySignatureFragment
      }
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
  ${MemberTimeDocumentFragmentFragmentDoc}
  ${BasicSafetySignatureFragmentFragmentDoc}
`;

/**
 * __useQueryMembersForTimeMetericsWithMoreQuery__
 *
 * To run a query within a React component, call `useQueryMembersForTimeMetericsWithMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersForTimeMetericsWithMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersForTimeMetericsWithMoreQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      safetySignaturesFilter: // value for 'safetySignaturesFilter'
 *      safetySignaturesSort: // value for 'safetySignaturesSort'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersForTimeMetericsWithMoreQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersForTimeMetericsWithMoreQuery,
    QueryMembersForTimeMetericsWithMoreQueryVariables
  > &
    ({ variables: QueryMembersForTimeMetericsWithMoreQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersForTimeMetericsWithMoreQuery, QueryMembersForTimeMetericsWithMoreQueryVariables>(
    QueryMembersForTimeMetericsWithMoreDocument,
    options
  );
}
export function useQueryMembersForTimeMetericsWithMoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersForTimeMetericsWithMoreQuery,
    QueryMembersForTimeMetericsWithMoreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersForTimeMetericsWithMoreQuery,
    QueryMembersForTimeMetericsWithMoreQueryVariables
  >(QueryMembersForTimeMetericsWithMoreDocument, options);
}
export function useQueryMembersForTimeMetericsWithMoreSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersForTimeMetericsWithMoreQuery,
    QueryMembersForTimeMetericsWithMoreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersForTimeMetericsWithMoreQuery,
    QueryMembersForTimeMetericsWithMoreQueryVariables
  >(QueryMembersForTimeMetericsWithMoreDocument, options);
}
export type QueryMembersForTimeMetericsWithMoreQueryHookResult = ReturnType<
  typeof useQueryMembersForTimeMetericsWithMoreQuery
>;
export type QueryMembersForTimeMetericsWithMoreLazyQueryHookResult = ReturnType<
  typeof useQueryMembersForTimeMetericsWithMoreLazyQuery
>;
export type QueryMembersForTimeMetericsWithMoreSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersForTimeMetericsWithMoreSuspenseQuery
>;
export type QueryMembersForTimeMetericsWithMoreQueryResult = Apollo.QueryResult<
  QueryMembersForTimeMetericsWithMoreQuery,
  QueryMembersForTimeMetericsWithMoreQueryVariables
>;
export const QueryMembersWithOpenEntryDocument = gql`
  query queryMembersWithOpenEntry($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
      }
      position {
        id
        level
      }
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        memberId
        openBreak {
          id
          startTime
          startTimeDst
        }
        project {
          id
          title
          depth
          projectInfo {
            id
            number
            customer
          }
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
            projectInfo {
              id
              number
            }
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        endTime
        daylightSavingTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
          startLocation {
            id
          }
          endLocation {
            id
          }
        }
        hasLocation
        startLocation {
          id
        }
        endLocation {
          id
        }
        clientLogs {
          id
        }
      }
    }
  }
`;

/**
 * __useQueryMembersWithOpenEntryQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithOpenEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithOpenEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithOpenEntryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMembersWithOpenEntryQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMembersWithOpenEntryQuery, QueryMembersWithOpenEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithOpenEntryQuery, QueryMembersWithOpenEntryQueryVariables>(
    QueryMembersWithOpenEntryDocument,
    options
  );
}
export function useQueryMembersWithOpenEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersWithOpenEntryQuery, QueryMembersWithOpenEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithOpenEntryQuery, QueryMembersWithOpenEntryQueryVariables>(
    QueryMembersWithOpenEntryDocument,
    options
  );
}
export function useQueryMembersWithOpenEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersWithOpenEntryQuery, QueryMembersWithOpenEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithOpenEntryQuery, QueryMembersWithOpenEntryQueryVariables>(
    QueryMembersWithOpenEntryDocument,
    options
  );
}
export type QueryMembersWithOpenEntryQueryHookResult = ReturnType<typeof useQueryMembersWithOpenEntryQuery>;
export type QueryMembersWithOpenEntryLazyQueryHookResult = ReturnType<typeof useQueryMembersWithOpenEntryLazyQuery>;
export type QueryMembersWithOpenEntrySuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithOpenEntrySuspenseQuery
>;
export type QueryMembersWithOpenEntryQueryResult = Apollo.QueryResult<
  QueryMembersWithOpenEntryQuery,
  QueryMembersWithOpenEntryQueryVariables
>;
export const QueryMembersWithOpenEntryCountDocument = gql`
  query queryMembersWithOpenEntryCount($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      firstName
      lastName
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        openBreak {
          id
        }
      }
    }
  }
`;

/**
 * __useQueryMembersWithOpenEntryCountQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithOpenEntryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithOpenEntryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithOpenEntryCountQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryMembersWithOpenEntryCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryMembersWithOpenEntryCountQuery,
    QueryMembersWithOpenEntryCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithOpenEntryCountQuery, QueryMembersWithOpenEntryCountQueryVariables>(
    QueryMembersWithOpenEntryCountDocument,
    options
  );
}
export function useQueryMembersWithOpenEntryCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithOpenEntryCountQuery,
    QueryMembersWithOpenEntryCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithOpenEntryCountQuery, QueryMembersWithOpenEntryCountQueryVariables>(
    QueryMembersWithOpenEntryCountDocument,
    options
  );
}
export function useQueryMembersWithOpenEntryCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithOpenEntryCountQuery,
    QueryMembersWithOpenEntryCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithOpenEntryCountQuery, QueryMembersWithOpenEntryCountQueryVariables>(
    QueryMembersWithOpenEntryCountDocument,
    options
  );
}
export type QueryMembersWithOpenEntryCountQueryHookResult = ReturnType<typeof useQueryMembersWithOpenEntryCountQuery>;
export type QueryMembersWithOpenEntryCountLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithOpenEntryCountLazyQuery
>;
export type QueryMembersWithOpenEntryCountSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithOpenEntryCountSuspenseQuery
>;
export type QueryMembersWithOpenEntryCountQueryResult = Apollo.QueryResult<
  QueryMembersWithOpenEntryCountQuery,
  QueryMembersWithOpenEntryCountQueryVariables
>;
export const QueryMembersWithTimeDocumentsDocument = gql`
  query QueryMembersWithTimeDocuments(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberNumber
      archivedOn
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      memberTimeDocuments(filter: $memberTimeDocumentFilter, sort: $memberTimeDocumentSort) {
        ...MemberTimeDocumentFragment
      }
      position {
        id
        level
      }
    }
  }
  ${MemberTimeDocumentFragmentFragmentDoc}
`;

/**
 * __useQueryMembersWithTimeDocumentsQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithTimeDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithTimeDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithTimeDocumentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersWithTimeDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithTimeDocumentsQuery,
    QueryMembersWithTimeDocumentsQueryVariables
  > &
    ({ variables: QueryMembersWithTimeDocumentsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithTimeDocumentsQuery, QueryMembersWithTimeDocumentsQueryVariables>(
    QueryMembersWithTimeDocumentsDocument,
    options
  );
}
export function useQueryMembersWithTimeDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithTimeDocumentsQuery,
    QueryMembersWithTimeDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithTimeDocumentsQuery, QueryMembersWithTimeDocumentsQueryVariables>(
    QueryMembersWithTimeDocumentsDocument,
    options
  );
}
export function useQueryMembersWithTimeDocumentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithTimeDocumentsQuery,
    QueryMembersWithTimeDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithTimeDocumentsQuery, QueryMembersWithTimeDocumentsQueryVariables>(
    QueryMembersWithTimeDocumentsDocument,
    options
  );
}
export type QueryMembersWithTimeDocumentsQueryHookResult = ReturnType<typeof useQueryMembersWithTimeDocumentsQuery>;
export type QueryMembersWithTimeDocumentsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithTimeDocumentsLazyQuery
>;
export type QueryMembersWithTimeDocumentsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithTimeDocumentsSuspenseQuery
>;
export type QueryMembersWithTimeDocumentsQueryResult = Apollo.QueryResult<
  QueryMembersWithTimeDocumentsQuery,
  QueryMembersWithTimeDocumentsQueryVariables
>;
export const QueryMemberDocument = gql`
  query queryMember($memberId: Uuid!) {
    members(filter: { id: { equal: $memberId } }) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
      }
      position {
        id
        level
      }
    }
  }
`;

/**
 * __useQueryMemberQuery__
 *
 * To run a query within a React component, call `useQueryMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useQueryMemberQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMemberQuery, QueryMemberQueryVariables> &
    ({ variables: QueryMemberQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberQuery, QueryMemberQueryVariables>(QueryMemberDocument, options);
}
export function useQueryMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberQuery, QueryMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberQuery, QueryMemberQueryVariables>(QueryMemberDocument, options);
}
export function useQueryMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberQuery, QueryMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberQuery, QueryMemberQueryVariables>(QueryMemberDocument, options);
}
export type QueryMemberQueryHookResult = ReturnType<typeof useQueryMemberQuery>;
export type QueryMemberLazyQueryHookResult = ReturnType<typeof useQueryMemberLazyQuery>;
export type QueryMemberSuspenseQueryHookResult = ReturnType<typeof useQueryMemberSuspenseQuery>;
export type QueryMemberQueryResult = Apollo.QueryResult<QueryMemberQuery, QueryMemberQueryVariables>;
export const QueryMemberNameDocument = gql`
  query queryMemberName($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      firstName
      lastName
      username
    }
  }
`;

/**
 * __useQueryMemberNameQuery__
 *
 * To run a query within a React component, call `useQueryMemberNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberNameQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryMemberNameQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberNameQuery, QueryMemberNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberNameQuery, QueryMemberNameQueryVariables>(QueryMemberNameDocument, options);
}
export function useQueryMemberNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberNameQuery, QueryMemberNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberNameQuery, QueryMemberNameQueryVariables>(QueryMemberNameDocument, options);
}
export function useQueryMemberNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberNameQuery, QueryMemberNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberNameQuery, QueryMemberNameQueryVariables>(QueryMemberNameDocument, options);
}
export type QueryMemberNameQueryHookResult = ReturnType<typeof useQueryMemberNameQuery>;
export type QueryMemberNameLazyQueryHookResult = ReturnType<typeof useQueryMemberNameLazyQuery>;
export type QueryMemberNameSuspenseQueryHookResult = ReturnType<typeof useQueryMemberNameSuspenseQuery>;
export type QueryMemberNameQueryResult = Apollo.QueryResult<QueryMemberNameQuery, QueryMemberNameQueryVariables>;
export const QueryFlatMemberNameDocument = gql`
  query queryFlatMemberName($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      __typename
      ...MemberDetails
      cursor
    }
  }
  ${MemberDetailsFragmentDoc}
`;

/**
 * __useQueryFlatMemberNameQuery__
 *
 * To run a query within a React component, call `useQueryFlatMemberNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFlatMemberNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFlatMemberNameQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryFlatMemberNameQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>(
    QueryFlatMemberNameDocument,
    options
  );
}
export function useQueryFlatMemberNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>(
    QueryFlatMemberNameDocument,
    options
  );
}
export function useQueryFlatMemberNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>(
    QueryFlatMemberNameDocument,
    options
  );
}
export type QueryFlatMemberNameQueryHookResult = ReturnType<typeof useQueryFlatMemberNameQuery>;
export type QueryFlatMemberNameLazyQueryHookResult = ReturnType<typeof useQueryFlatMemberNameLazyQuery>;
export type QueryFlatMemberNameSuspenseQueryHookResult = ReturnType<typeof useQueryFlatMemberNameSuspenseQuery>;
export type QueryFlatMemberNameQueryResult = Apollo.QueryResult<
  QueryFlatMemberNameQuery,
  QueryFlatMemberNameQueryVariables
>;
export const QueryMemberArchivedDocument = gql`
  query queryMemberArchived($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      archivedOn
    }
  }
`;

/**
 * __useQueryMemberArchivedQuery__
 *
 * To run a query within a React component, call `useQueryMemberArchivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberArchivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberArchivedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryMemberArchivedQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberArchivedQuery, QueryMemberArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberArchivedQuery, QueryMemberArchivedQueryVariables>(
    QueryMemberArchivedDocument,
    options
  );
}
export function useQueryMemberArchivedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberArchivedQuery, QueryMemberArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberArchivedQuery, QueryMemberArchivedQueryVariables>(
    QueryMemberArchivedDocument,
    options
  );
}
export function useQueryMemberArchivedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberArchivedQuery, QueryMemberArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberArchivedQuery, QueryMemberArchivedQueryVariables>(
    QueryMemberArchivedDocument,
    options
  );
}
export type QueryMemberArchivedQueryHookResult = ReturnType<typeof useQueryMemberArchivedQuery>;
export type QueryMemberArchivedLazyQueryHookResult = ReturnType<typeof useQueryMemberArchivedLazyQuery>;
export type QueryMemberArchivedSuspenseQueryHookResult = ReturnType<typeof useQueryMemberArchivedSuspenseQuery>;
export type QueryMemberArchivedQueryResult = Apollo.QueryResult<
  QueryMemberArchivedQuery,
  QueryMemberArchivedQueryVariables
>;
export const QueryMemberArchivedWithPositionDocument = gql`
  query queryMemberArchivedWithPosition($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      position {
        id
        level
        timeEvents
        manageTimeEntries
      }
    }
  }
`;

/**
 * __useQueryMemberArchivedWithPositionQuery__
 *
 * To run a query within a React component, call `useQueryMemberArchivedWithPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberArchivedWithPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberArchivedWithPositionQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryMemberArchivedWithPositionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryMemberArchivedWithPositionQuery,
    QueryMemberArchivedWithPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberArchivedWithPositionQuery, QueryMemberArchivedWithPositionQueryVariables>(
    QueryMemberArchivedWithPositionDocument,
    options
  );
}
export function useQueryMemberArchivedWithPositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMemberArchivedWithPositionQuery,
    QueryMemberArchivedWithPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberArchivedWithPositionQuery, QueryMemberArchivedWithPositionQueryVariables>(
    QueryMemberArchivedWithPositionDocument,
    options
  );
}
export function useQueryMemberArchivedWithPositionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMemberArchivedWithPositionQuery,
    QueryMemberArchivedWithPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberArchivedWithPositionQuery, QueryMemberArchivedWithPositionQueryVariables>(
    QueryMemberArchivedWithPositionDocument,
    options
  );
}
export type QueryMemberArchivedWithPositionQueryHookResult = ReturnType<typeof useQueryMemberArchivedWithPositionQuery>;
export type QueryMemberArchivedWithPositionLazyQueryHookResult = ReturnType<
  typeof useQueryMemberArchivedWithPositionLazyQuery
>;
export type QueryMemberArchivedWithPositionSuspenseQueryHookResult = ReturnType<
  typeof useQueryMemberArchivedWithPositionSuspenseQuery
>;
export type QueryMemberArchivedWithPositionQueryResult = Apollo.QueryResult<
  QueryMemberArchivedWithPositionQuery,
  QueryMemberArchivedWithPositionQueryVariables
>;
export const QueryMembersWithProjectWithCostCodesMetricDocument = gql`
  query QueryMembersWithProjectWithCostCodesMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        costCodeId
        memberId
        projectId
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectWithCostCodesMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectWithCostCodesMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectWithCostCodesMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectWithCostCodesMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectWithCostCodesMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectWithCostCodesMetricQuery,
    QueryMembersWithProjectWithCostCodesMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectWithCostCodesMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithProjectWithCostCodesMetricQuery,
    QueryMembersWithProjectWithCostCodesMetricQueryVariables
  >(QueryMembersWithProjectWithCostCodesMetricDocument, options);
}
export function useQueryMembersWithProjectWithCostCodesMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectWithCostCodesMetricQuery,
    QueryMembersWithProjectWithCostCodesMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithProjectWithCostCodesMetricQuery,
    QueryMembersWithProjectWithCostCodesMetricQueryVariables
  >(QueryMembersWithProjectWithCostCodesMetricDocument, options);
}
export function useQueryMembersWithProjectWithCostCodesMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectWithCostCodesMetricQuery,
    QueryMembersWithProjectWithCostCodesMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithProjectWithCostCodesMetricQuery,
    QueryMembersWithProjectWithCostCodesMetricQueryVariables
  >(QueryMembersWithProjectWithCostCodesMetricDocument, options);
}
export type QueryMembersWithProjectWithCostCodesMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectWithCostCodesMetricQuery
>;
export type QueryMembersWithProjectWithCostCodesMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectWithCostCodesMetricLazyQuery
>;
export type QueryMembersWithProjectWithCostCodesMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectWithCostCodesMetricSuspenseQuery
>;
export type QueryMembersWithProjectWithCostCodesMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectWithCostCodesMetricQuery,
  QueryMembersWithProjectWithCostCodesMetricQueryVariables
>;
export const QueryMemberUsageDocument = gql`
  query QueryMemberUsage(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $usageStartDate: DateTime!
    $usageEndDate: DateTime!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberNumber
      archivedOn
      memberUsageInfo(start: $usageStartDate, end: $usageEndDate) {
        firstApiAccess
        firstTimeTracking
      }
    }
  }
`;

/**
 * __useQueryMemberUsageQuery__
 *
 * To run a query within a React component, call `useQueryMemberUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberUsageQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      usageStartDate: // value for 'usageStartDate'
 *      usageEndDate: // value for 'usageEndDate'
 *   },
 * });
 */
export function useQueryMemberUsageQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMemberUsageQuery, QueryMemberUsageQueryVariables> &
    ({ variables: QueryMemberUsageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberUsageQuery, QueryMemberUsageQueryVariables>(QueryMemberUsageDocument, options);
}
export function useQueryMemberUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberUsageQuery, QueryMemberUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberUsageQuery, QueryMemberUsageQueryVariables>(QueryMemberUsageDocument, options);
}
export function useQueryMemberUsageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberUsageQuery, QueryMemberUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberUsageQuery, QueryMemberUsageQueryVariables>(
    QueryMemberUsageDocument,
    options
  );
}
export type QueryMemberUsageQueryHookResult = ReturnType<typeof useQueryMemberUsageQuery>;
export type QueryMemberUsageLazyQueryHookResult = ReturnType<typeof useQueryMemberUsageLazyQuery>;
export type QueryMemberUsageSuspenseQueryHookResult = ReturnType<typeof useQueryMemberUsageSuspenseQuery>;
export type QueryMemberUsageQueryResult = Apollo.QueryResult<QueryMemberUsageQuery, QueryMemberUsageQueryVariables>;
export const SimpleSubmissionsDocument = gql`
  query SimpleSubmissions(
    $after: String
    $filter: MemberSignInSubmissionFilter
    $first: Int
    $sort: [MemberSignInSubmissionSort!]
  ) {
    memberSignInSubmissions(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      flagged
      cursor
      createdOn
      createdOnLocal
      deviceType
      signatureUrl
      localTime
      restricted
      resolverId
      proxyMemberId
    }
  }
`;

/**
 * __useSimpleSubmissionsQuery__
 *
 * To run a query within a React component, call `useSimpleSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleSubmissionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSimpleSubmissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>(SimpleSubmissionsDocument, options);
}
export function useSimpleSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>(
    SimpleSubmissionsDocument,
    options
  );
}
export function useSimpleSubmissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>(
    SimpleSubmissionsDocument,
    options
  );
}
export type SimpleSubmissionsQueryHookResult = ReturnType<typeof useSimpleSubmissionsQuery>;
export type SimpleSubmissionsLazyQueryHookResult = ReturnType<typeof useSimpleSubmissionsLazyQuery>;
export type SimpleSubmissionsSuspenseQueryHookResult = ReturnType<typeof useSimpleSubmissionsSuspenseQuery>;
export type SimpleSubmissionsQueryResult = Apollo.QueryResult<SimpleSubmissionsQuery, SimpleSubmissionsQueryVariables>;
export const SubmissionsDocument = gql`
  query submissions(
    $after: String
    $filter: MemberSignInSubmissionFilter
    $first: Int
    $sort: [MemberSignInSubmissionSort!]
  ) {
    memberSignInSubmissions(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      flagged
      cursor
      createdOn
      createdOnLocal
      deviceType
      signatureUrl
      localTime
      restricted
      resolverId
      proxyMemberId
      proxyMember {
        id
        firstName
        lastName
      }
      answers {
        id
        questionTitle
        answer
        flagged
        description
        questionDescription
        createdOn
        questionPrompt
        resolverId
        audienceType
        positionId
        resolverMember {
          id
          firstName
          lastName
        }
        resolvedOn
        resolvedComment
      }
      member {
        id
        firstName
        lastName
        imageUrl
        archivedOn
        position {
          id
          level
        }
      }
    }
  }
`;

/**
 * __useSubmissionsQuery__
 *
 * To run a query within a React component, call `useSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSubmissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export function useSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export function useSubmissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export type SubmissionsSuspenseQueryHookResult = ReturnType<typeof useSubmissionsSuspenseQuery>;
export type SubmissionsQueryResult = Apollo.QueryResult<SubmissionsQuery, SubmissionsQueryVariables>;
export const MemberTimeDocumentsDocument = gql`
  query MemberTimeDocuments(
    $first: Int
    $after: String
    $filter: MemberTimeDocumentFilter
    $sort: [MemberTimeDocumentSort!]
  ) {
    memberTimeDocuments(after: $after, first: $first, filter: $filter, sort: $sort) {
      __typename
      ...MemberTimeDocumentFragment
    }
  }
  ${MemberTimeDocumentFragmentFragmentDoc}
`;

/**
 * __useMemberTimeDocumentsQuery__
 *
 * To run a query within a React component, call `useMemberTimeDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberTimeDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberTimeDocumentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMemberTimeDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberTimeDocumentsQuery, MemberTimeDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberTimeDocumentsQuery, MemberTimeDocumentsQueryVariables>(
    MemberTimeDocumentsDocument,
    options
  );
}
export function useMemberTimeDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberTimeDocumentsQuery, MemberTimeDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberTimeDocumentsQuery, MemberTimeDocumentsQueryVariables>(
    MemberTimeDocumentsDocument,
    options
  );
}
export function useMemberTimeDocumentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberTimeDocumentsQuery, MemberTimeDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberTimeDocumentsQuery, MemberTimeDocumentsQueryVariables>(
    MemberTimeDocumentsDocument,
    options
  );
}
export type MemberTimeDocumentsQueryHookResult = ReturnType<typeof useMemberTimeDocumentsQuery>;
export type MemberTimeDocumentsLazyQueryHookResult = ReturnType<typeof useMemberTimeDocumentsLazyQuery>;
export type MemberTimeDocumentsSuspenseQueryHookResult = ReturnType<typeof useMemberTimeDocumentsSuspenseQuery>;
export type MemberTimeDocumentsQueryResult = Apollo.QueryResult<
  MemberTimeDocumentsQuery,
  MemberTimeDocumentsQueryVariables
>;
export const QueryOrganizationDocumentsDocument = gql`
  query QueryOrganizationDocuments(
    $first: Int
    $after: String
    $filter: OrganizationDocumentFilter
    $sort: [OrganizationDocumentSort!]
  ) {
    organizationDocuments(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      title
      description
      projectId
      lastUpdatedOn
      positionId
      limitAccess
      fileUrl
      fileType
      folderId
      createdBy
      createdOn
      cursor
      folder {
        id
        title
      }
      project {
        id
        title
        ancestors {
          id
          depth
          title
        }
      }
      createdByMember {
        id
        firstName
        lastName
      }
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

/**
 * __useQueryOrganizationDocumentsQuery__
 *
 * To run a query within a React component, call `useQueryOrganizationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryOrganizationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryOrganizationDocumentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryOrganizationDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryOrganizationDocumentsQuery, QueryOrganizationDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryOrganizationDocumentsQuery, QueryOrganizationDocumentsQueryVariables>(
    QueryOrganizationDocumentsDocument,
    options
  );
}
export function useQueryOrganizationDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryOrganizationDocumentsQuery, QueryOrganizationDocumentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryOrganizationDocumentsQuery, QueryOrganizationDocumentsQueryVariables>(
    QueryOrganizationDocumentsDocument,
    options
  );
}
export function useQueryOrganizationDocumentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryOrganizationDocumentsQuery,
    QueryOrganizationDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryOrganizationDocumentsQuery, QueryOrganizationDocumentsQueryVariables>(
    QueryOrganizationDocumentsDocument,
    options
  );
}
export type QueryOrganizationDocumentsQueryHookResult = ReturnType<typeof useQueryOrganizationDocumentsQuery>;
export type QueryOrganizationDocumentsLazyQueryHookResult = ReturnType<typeof useQueryOrganizationDocumentsLazyQuery>;
export type QueryOrganizationDocumentsSuspenseQueryHookResult = ReturnType<
  typeof useQueryOrganizationDocumentsSuspenseQuery
>;
export type QueryOrganizationDocumentsQueryResult = Apollo.QueryResult<
  QueryOrganizationDocumentsQuery,
  QueryOrganizationDocumentsQueryVariables
>;
export const CreateOrganizationDocumentDocument = gql`
  mutation CreateOrganizationDocument($organizationDocument: OrganizationDocumentCreateInput) {
    createOrganizationDocument(organizationDocument: $organizationDocument) {
      id
    }
  }
`;
export type CreateOrganizationDocumentMutationFn = Apollo.MutationFunction<
  CreateOrganizationDocumentMutation,
  CreateOrganizationDocumentMutationVariables
>;

/**
 * __useCreateOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationDocumentMutation, { data, loading, error }] = useCreateOrganizationDocumentMutation({
 *   variables: {
 *      organizationDocument: // value for 'organizationDocument'
 *   },
 * });
 */
export function useCreateOrganizationDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationDocumentMutation,
    CreateOrganizationDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationDocumentMutation, CreateOrganizationDocumentMutationVariables>(
    CreateOrganizationDocumentDocument,
    options
  );
}
export type CreateOrganizationDocumentMutationHookResult = ReturnType<typeof useCreateOrganizationDocumentMutation>;
export type CreateOrganizationDocumentMutationResult = Apollo.MutationResult<CreateOrganizationDocumentMutation>;
export type CreateOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationDocumentMutation,
  CreateOrganizationDocumentMutationVariables
>;
export const UpdateOrganizationDocumentDocument = gql`
  mutation UpdateOrganizationDocument($organizationDocument: OrganizationDocumentUpdateInput) {
    updateOrganizationDocument(organizationDocument: $organizationDocument) {
      id
    }
  }
`;
export type UpdateOrganizationDocumentMutationFn = Apollo.MutationFunction<
  UpdateOrganizationDocumentMutation,
  UpdateOrganizationDocumentMutationVariables
>;

/**
 * __useUpdateOrganizationDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDocumentMutation, { data, loading, error }] = useUpdateOrganizationDocumentMutation({
 *   variables: {
 *      organizationDocument: // value for 'organizationDocument'
 *   },
 * });
 */
export function useUpdateOrganizationDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationDocumentMutation,
    UpdateOrganizationDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationDocumentMutation, UpdateOrganizationDocumentMutationVariables>(
    UpdateOrganizationDocumentDocument,
    options
  );
}
export type UpdateOrganizationDocumentMutationHookResult = ReturnType<typeof useUpdateOrganizationDocumentMutation>;
export type UpdateOrganizationDocumentMutationResult = Apollo.MutationResult<UpdateOrganizationDocumentMutation>;
export type UpdateOrganizationDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationDocumentMutation,
  UpdateOrganizationDocumentMutationVariables
>;
export const OrganizationNotificationPreferencesQueryDocument = gql`
  query OrganizationNotificationPreferencesQuery(
    $filter: OrganizationNotificationPreferencesFilter
    $first: Int
    $after: String
  ) {
    organizationNotificationPreferences(first: $first, after: $after, filter: $filter) {
      id
      memberId
      injuryReport
      inaccurateTimeReport
      breakReport
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useOrganizationNotificationPreferencesQueryQuery__
 *
 * To run a query within a React component, call `useOrganizationNotificationPreferencesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationNotificationPreferencesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationNotificationPreferencesQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOrganizationNotificationPreferencesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationNotificationPreferencesQueryQuery,
    OrganizationNotificationPreferencesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationNotificationPreferencesQueryQuery,
    OrganizationNotificationPreferencesQueryQueryVariables
  >(OrganizationNotificationPreferencesQueryDocument, options);
}
export function useOrganizationNotificationPreferencesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationNotificationPreferencesQueryQuery,
    OrganizationNotificationPreferencesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationNotificationPreferencesQueryQuery,
    OrganizationNotificationPreferencesQueryQueryVariables
  >(OrganizationNotificationPreferencesQueryDocument, options);
}
export function useOrganizationNotificationPreferencesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizationNotificationPreferencesQueryQuery,
    OrganizationNotificationPreferencesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    OrganizationNotificationPreferencesQueryQuery,
    OrganizationNotificationPreferencesQueryQueryVariables
  >(OrganizationNotificationPreferencesQueryDocument, options);
}
export type OrganizationNotificationPreferencesQueryQueryHookResult = ReturnType<
  typeof useOrganizationNotificationPreferencesQueryQuery
>;
export type OrganizationNotificationPreferencesQueryLazyQueryHookResult = ReturnType<
  typeof useOrganizationNotificationPreferencesQueryLazyQuery
>;
export type OrganizationNotificationPreferencesQuerySuspenseQueryHookResult = ReturnType<
  typeof useOrganizationNotificationPreferencesQuerySuspenseQuery
>;
export type OrganizationNotificationPreferencesQueryQueryResult = Apollo.QueryResult<
  OrganizationNotificationPreferencesQueryQuery,
  OrganizationNotificationPreferencesQueryQueryVariables
>;
export const OrganizationOvertimePeriodsDocument = gql`
  query OrganizationOvertimePeriods($first: Int, $after: String, $filter: OrganizationOvertimePeriodFilter) {
    organizationOvertimePeriods(first: $first, after: $after, filter: $filter, sort: [{ startTime: desc }]) {
      id
      startTime
      lengthInDays
      dailyOvertime
      dailyDoubletime
      weeklyOvertime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      updatedOn
      createdOn
      cursor
    }
  }
`;

/**
 * __useOrganizationOvertimePeriodsQuery__
 *
 * To run a query within a React component, call `useOrganizationOvertimePeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOvertimePeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationOvertimePeriodsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationOvertimePeriodsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationOvertimePeriodsQuery, OrganizationOvertimePeriodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationOvertimePeriodsQuery, OrganizationOvertimePeriodsQueryVariables>(
    OrganizationOvertimePeriodsDocument,
    options
  );
}
export function useOrganizationOvertimePeriodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationOvertimePeriodsQuery, OrganizationOvertimePeriodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationOvertimePeriodsQuery, OrganizationOvertimePeriodsQueryVariables>(
    OrganizationOvertimePeriodsDocument,
    options
  );
}
export function useOrganizationOvertimePeriodsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizationOvertimePeriodsQuery,
    OrganizationOvertimePeriodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrganizationOvertimePeriodsQuery, OrganizationOvertimePeriodsQueryVariables>(
    OrganizationOvertimePeriodsDocument,
    options
  );
}
export type OrganizationOvertimePeriodsQueryHookResult = ReturnType<typeof useOrganizationOvertimePeriodsQuery>;
export type OrganizationOvertimePeriodsLazyQueryHookResult = ReturnType<typeof useOrganizationOvertimePeriodsLazyQuery>;
export type OrganizationOvertimePeriodsSuspenseQueryHookResult = ReturnType<
  typeof useOrganizationOvertimePeriodsSuspenseQuery
>;
export type OrganizationOvertimePeriodsQueryResult = Apollo.QueryResult<
  OrganizationOvertimePeriodsQuery,
  OrganizationOvertimePeriodsQueryVariables
>;
export const QueryFlatPositionsDocument = gql`
  query QueryFlatPositions($first: Int, $after: String, $filter: PositionFilter) {
    positions(first: $first, after: $after, filter: $filter, sort: [{ level: asc }]) {
      cursor
      __typename
      ...PositionDetails
    }
  }
  ${PositionDetailsFragmentDoc}
`;

/**
 * __useQueryFlatPositionsQuery__
 *
 * To run a query within a React component, call `useQueryFlatPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryFlatPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryFlatPositionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryFlatPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryFlatPositionsQuery, QueryFlatPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryFlatPositionsQuery, QueryFlatPositionsQueryVariables>(
    QueryFlatPositionsDocument,
    options
  );
}
export function useQueryFlatPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryFlatPositionsQuery, QueryFlatPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryFlatPositionsQuery, QueryFlatPositionsQueryVariables>(
    QueryFlatPositionsDocument,
    options
  );
}
export function useQueryFlatPositionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryFlatPositionsQuery, QueryFlatPositionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryFlatPositionsQuery, QueryFlatPositionsQueryVariables>(
    QueryFlatPositionsDocument,
    options
  );
}
export type QueryFlatPositionsQueryHookResult = ReturnType<typeof useQueryFlatPositionsQuery>;
export type QueryFlatPositionsLazyQueryHookResult = ReturnType<typeof useQueryFlatPositionsLazyQuery>;
export type QueryFlatPositionsSuspenseQueryHookResult = ReturnType<typeof useQueryFlatPositionsSuspenseQuery>;
export type QueryFlatPositionsQueryResult = Apollo.QueryResult<
  QueryFlatPositionsQuery,
  QueryFlatPositionsQueryVariables
>;
export const QueryPositionWithTitleDocument = gql`
  query queryPositionWithTitle($first: Int, $after: String, $filter: PositionFilter) {
    positions(first: $first, after: $after, filter: $filter, sort: [{ level: asc }]) {
      cursor
      id
      title
    }
  }
`;

/**
 * __useQueryPositionWithTitleQuery__
 *
 * To run a query within a React component, call `useQueryPositionWithTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPositionWithTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPositionWithTitleQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryPositionWithTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryPositionWithTitleQuery, QueryPositionWithTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryPositionWithTitleQuery, QueryPositionWithTitleQueryVariables>(
    QueryPositionWithTitleDocument,
    options
  );
}
export function useQueryPositionWithTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryPositionWithTitleQuery, QueryPositionWithTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryPositionWithTitleQuery, QueryPositionWithTitleQueryVariables>(
    QueryPositionWithTitleDocument,
    options
  );
}
export function useQueryPositionWithTitleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryPositionWithTitleQuery, QueryPositionWithTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryPositionWithTitleQuery, QueryPositionWithTitleQueryVariables>(
    QueryPositionWithTitleDocument,
    options
  );
}
export type QueryPositionWithTitleQueryHookResult = ReturnType<typeof useQueryPositionWithTitleQuery>;
export type QueryPositionWithTitleLazyQueryHookResult = ReturnType<typeof useQueryPositionWithTitleLazyQuery>;
export type QueryPositionWithTitleSuspenseQueryHookResult = ReturnType<typeof useQueryPositionWithTitleSuspenseQuery>;
export type QueryPositionWithTitleQueryResult = Apollo.QueryResult<
  QueryPositionWithTitleQuery,
  QueryPositionWithTitleQueryVariables
>;
export const QueryProjectCostCodeQuantityDocument = gql`
  query QueryProjectCostCodeQuantity(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      amount
      assignedOn
      lastEditedByMember {
        id
        firstName
        lastName
      }
      costCode {
        id
        costCode
        title
        unitTitle
      }
      costCodeId
      createdOnLocal
      description
      project {
        id
        title
        depth
        projectInfo {
          id
          number
        }
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            number
          }
        }
      }
      projectId
      submittedByMember {
        id
        firstName
        lastName
        memberGroupId
        position {
          id
          level
          canManageProjectCostCodeQuantity
          timeEvents
        }
      }
      submittedBy
      type
      cursor
    }
  }
`;

/**
 * __useQueryProjectCostCodeQuantityQuery__
 *
 * To run a query within a React component, call `useQueryProjectCostCodeQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectCostCodeQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectCostCodeQuantityQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryProjectCostCodeQuantityQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryProjectCostCodeQuantityQuery, QueryProjectCostCodeQuantityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectCostCodeQuantityQuery, QueryProjectCostCodeQuantityQueryVariables>(
    QueryProjectCostCodeQuantityDocument,
    options
  );
}
export function useQueryProjectCostCodeQuantityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProjectCostCodeQuantityQuery,
    QueryProjectCostCodeQuantityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectCostCodeQuantityQuery, QueryProjectCostCodeQuantityQueryVariables>(
    QueryProjectCostCodeQuantityDocument,
    options
  );
}
export function useQueryProjectCostCodeQuantitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectCostCodeQuantityQuery,
    QueryProjectCostCodeQuantityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectCostCodeQuantityQuery, QueryProjectCostCodeQuantityQueryVariables>(
    QueryProjectCostCodeQuantityDocument,
    options
  );
}
export type QueryProjectCostCodeQuantityQueryHookResult = ReturnType<typeof useQueryProjectCostCodeQuantityQuery>;
export type QueryProjectCostCodeQuantityLazyQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantityLazyQuery
>;
export type QueryProjectCostCodeQuantitySuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantitySuspenseQuery
>;
export type QueryProjectCostCodeQuantityQueryResult = Apollo.QueryResult<
  QueryProjectCostCodeQuantityQuery,
  QueryProjectCostCodeQuantityQueryVariables
>;
export const QueryProjectQuantitySummaryDocument = gql`
  query QueryProjectQuantitySummary(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      projectId
      project {
        id
        rootProjectId
      }
      cursor
    }
  }
`;

/**
 * __useQueryProjectQuantitySummaryQuery__
 *
 * To run a query within a React component, call `useQueryProjectQuantitySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectQuantitySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectQuantitySummaryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryProjectQuantitySummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryProjectQuantitySummaryQuery, QueryProjectQuantitySummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectQuantitySummaryQuery, QueryProjectQuantitySummaryQueryVariables>(
    QueryProjectQuantitySummaryDocument,
    options
  );
}
export function useQueryProjectQuantitySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryProjectQuantitySummaryQuery, QueryProjectQuantitySummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectQuantitySummaryQuery, QueryProjectQuantitySummaryQueryVariables>(
    QueryProjectQuantitySummaryDocument,
    options
  );
}
export function useQueryProjectQuantitySummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectQuantitySummaryQuery,
    QueryProjectQuantitySummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectQuantitySummaryQuery, QueryProjectQuantitySummaryQueryVariables>(
    QueryProjectQuantitySummaryDocument,
    options
  );
}
export type QueryProjectQuantitySummaryQueryHookResult = ReturnType<typeof useQueryProjectQuantitySummaryQuery>;
export type QueryProjectQuantitySummaryLazyQueryHookResult = ReturnType<typeof useQueryProjectQuantitySummaryLazyQuery>;
export type QueryProjectQuantitySummarySuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectQuantitySummarySuspenseQuery
>;
export type QueryProjectQuantitySummaryQueryResult = Apollo.QueryResult<
  QueryProjectQuantitySummaryQuery,
  QueryProjectQuantitySummaryQueryVariables
>;
export const QueryProjectCostCodeQuantitySummaryDocument = gql`
  query QueryProjectCostCodeQuantitySummary(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      amount
      projectId
      costCodeId
      costCode {
        id
        title
        costCode
      }
      cursor
    }
  }
`;

/**
 * __useQueryProjectCostCodeQuantitySummaryQuery__
 *
 * To run a query within a React component, call `useQueryProjectCostCodeQuantitySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectCostCodeQuantitySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectCostCodeQuantitySummaryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryProjectCostCodeQuantitySummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryProjectCostCodeQuantitySummaryQuery,
    QueryProjectCostCodeQuantitySummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectCostCodeQuantitySummaryQuery, QueryProjectCostCodeQuantitySummaryQueryVariables>(
    QueryProjectCostCodeQuantitySummaryDocument,
    options
  );
}
export function useQueryProjectCostCodeQuantitySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProjectCostCodeQuantitySummaryQuery,
    QueryProjectCostCodeQuantitySummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryProjectCostCodeQuantitySummaryQuery,
    QueryProjectCostCodeQuantitySummaryQueryVariables
  >(QueryProjectCostCodeQuantitySummaryDocument, options);
}
export function useQueryProjectCostCodeQuantitySummarySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectCostCodeQuantitySummaryQuery,
    QueryProjectCostCodeQuantitySummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryProjectCostCodeQuantitySummaryQuery,
    QueryProjectCostCodeQuantitySummaryQueryVariables
  >(QueryProjectCostCodeQuantitySummaryDocument, options);
}
export type QueryProjectCostCodeQuantitySummaryQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantitySummaryQuery
>;
export type QueryProjectCostCodeQuantitySummaryLazyQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantitySummaryLazyQuery
>;
export type QueryProjectCostCodeQuantitySummarySuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantitySummarySuspenseQuery
>;
export type QueryProjectCostCodeQuantitySummaryQueryResult = Apollo.QueryResult<
  QueryProjectCostCodeQuantitySummaryQuery,
  QueryProjectCostCodeQuantitySummaryQueryVariables
>;
export const QueryProjectCostCodeQuantityIdDocument = gql`
  query QueryProjectCostCodeQuantityId(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      assignedOn
      cursor
    }
  }
`;

/**
 * __useQueryProjectCostCodeQuantityIdQuery__
 *
 * To run a query within a React component, call `useQueryProjectCostCodeQuantityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectCostCodeQuantityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectCostCodeQuantityIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryProjectCostCodeQuantityIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryProjectCostCodeQuantityIdQuery,
    QueryProjectCostCodeQuantityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectCostCodeQuantityIdQuery, QueryProjectCostCodeQuantityIdQueryVariables>(
    QueryProjectCostCodeQuantityIdDocument,
    options
  );
}
export function useQueryProjectCostCodeQuantityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProjectCostCodeQuantityIdQuery,
    QueryProjectCostCodeQuantityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectCostCodeQuantityIdQuery, QueryProjectCostCodeQuantityIdQueryVariables>(
    QueryProjectCostCodeQuantityIdDocument,
    options
  );
}
export function useQueryProjectCostCodeQuantityIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectCostCodeQuantityIdQuery,
    QueryProjectCostCodeQuantityIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectCostCodeQuantityIdQuery, QueryProjectCostCodeQuantityIdQueryVariables>(
    QueryProjectCostCodeQuantityIdDocument,
    options
  );
}
export type QueryProjectCostCodeQuantityIdQueryHookResult = ReturnType<typeof useQueryProjectCostCodeQuantityIdQuery>;
export type QueryProjectCostCodeQuantityIdLazyQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantityIdLazyQuery
>;
export type QueryProjectCostCodeQuantityIdSuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectCostCodeQuantityIdSuspenseQuery
>;
export type QueryProjectCostCodeQuantityIdQueryResult = Apollo.QueryResult<
  QueryProjectCostCodeQuantityIdQuery,
  QueryProjectCostCodeQuantityIdQueryVariables
>;
export const ProjectGroupDocument = gql`
  query ProjectGroup($after: String, $filter: ProjectGroupFilter, $first: Int) {
    projectGroups(after: $after, first: $first, filter: $filter, sort: [{ groupName: asc }]) {
      id
      organizationId
      groupName
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useProjectGroupQuery__
 *
 * To run a query within a React component, call `useProjectGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGroupQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectGroupQuery, ProjectGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectGroupQuery, ProjectGroupQueryVariables>(ProjectGroupDocument, options);
}
export function useProjectGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectGroupQuery, ProjectGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectGroupQuery, ProjectGroupQueryVariables>(ProjectGroupDocument, options);
}
export function useProjectGroupSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectGroupQuery, ProjectGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectGroupQuery, ProjectGroupQueryVariables>(ProjectGroupDocument, options);
}
export type ProjectGroupQueryHookResult = ReturnType<typeof useProjectGroupQuery>;
export type ProjectGroupLazyQueryHookResult = ReturnType<typeof useProjectGroupLazyQuery>;
export type ProjectGroupSuspenseQueryHookResult = ReturnType<typeof useProjectGroupSuspenseQuery>;
export type ProjectGroupQueryResult = Apollo.QueryResult<ProjectGroupQuery, ProjectGroupQueryVariables>;
export const ProjectTimeMetricsQueryDocument = gql`
  query ProjectTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projectLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicProjectLaborMetrics
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectTimeMetricsQueryQuery__
 *
 * To run a query within a React component, call `useProjectTimeMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimeMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimeMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectTimeMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectTimeMetricsQueryQuery, ProjectTimeMetricsQueryQueryVariables> &
    ({ variables: ProjectTimeMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTimeMetricsQueryQuery, ProjectTimeMetricsQueryQueryVariables>(
    ProjectTimeMetricsQueryDocument,
    options
  );
}
export function useProjectTimeMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTimeMetricsQueryQuery, ProjectTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTimeMetricsQueryQuery, ProjectTimeMetricsQueryQueryVariables>(
    ProjectTimeMetricsQueryDocument,
    options
  );
}
export function useProjectTimeMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectTimeMetricsQueryQuery, ProjectTimeMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectTimeMetricsQueryQuery, ProjectTimeMetricsQueryQueryVariables>(
    ProjectTimeMetricsQueryDocument,
    options
  );
}
export type ProjectTimeMetricsQueryQueryHookResult = ReturnType<typeof useProjectTimeMetricsQueryQuery>;
export type ProjectTimeMetricsQueryLazyQueryHookResult = ReturnType<typeof useProjectTimeMetricsQueryLazyQuery>;
export type ProjectTimeMetricsQuerySuspenseQueryHookResult = ReturnType<typeof useProjectTimeMetricsQuerySuspenseQuery>;
export type ProjectTimeMetricsQueryQueryResult = Apollo.QueryResult<
  ProjectTimeMetricsQueryQuery,
  ProjectTimeMetricsQueryQueryVariables
>;
export const ProjectMetricsQueryDocument = gql`
  query ProjectMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projectLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...ProjectLaborMetrics
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectMetricsQueryQuery__
 *
 * To run a query within a React component, call `useProjectMetricsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMetricsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMetricsQueryQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectMetricsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectMetricsQueryQuery, ProjectMetricsQueryQueryVariables> &
    ({ variables: ProjectMetricsQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectMetricsQueryQuery, ProjectMetricsQueryQueryVariables>(
    ProjectMetricsQueryDocument,
    options
  );
}
export function useProjectMetricsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectMetricsQueryQuery, ProjectMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectMetricsQueryQuery, ProjectMetricsQueryQueryVariables>(
    ProjectMetricsQueryDocument,
    options
  );
}
export function useProjectMetricsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectMetricsQueryQuery, ProjectMetricsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectMetricsQueryQuery, ProjectMetricsQueryQueryVariables>(
    ProjectMetricsQueryDocument,
    options
  );
}
export type ProjectMetricsQueryQueryHookResult = ReturnType<typeof useProjectMetricsQueryQuery>;
export type ProjectMetricsQueryLazyQueryHookResult = ReturnType<typeof useProjectMetricsQueryLazyQuery>;
export type ProjectMetricsQuerySuspenseQueryHookResult = ReturnType<typeof useProjectMetricsQuerySuspenseQuery>;
export type ProjectMetricsQueryQueryResult = Apollo.QueryResult<
  ProjectMetricsQueryQuery,
  ProjectMetricsQueryQueryVariables
>;
export const ProjectsDocument = gql`
  query Projects($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      projectInfo {
        id
        number
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
    }
  }
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
}
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
}
export function useProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsSuspenseQueryHookResult = ReturnType<typeof useProjectsSuspenseQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ProjectsWithGpsDocument = gql`
  query ProjectsWithGps($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      rootProject {
        id
        projectInfo {
          id
          requireTimeEntryGps
        }
      }
      projectInfo {
        id
        requireTimeEntryGps
      }
      cursor
    }
  }
`;

/**
 * __useProjectsWithGpsQuery__
 *
 * To run a query within a React component, call `useProjectsWithGpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsWithGpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsWithGpsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectsWithGpsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>(ProjectsWithGpsDocument, options);
}
export function useProjectsWithGpsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>(ProjectsWithGpsDocument, options);
}
export function useProjectsWithGpsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>(ProjectsWithGpsDocument, options);
}
export type ProjectsWithGpsQueryHookResult = ReturnType<typeof useProjectsWithGpsQuery>;
export type ProjectsWithGpsLazyQueryHookResult = ReturnType<typeof useProjectsWithGpsLazyQuery>;
export type ProjectsWithGpsSuspenseQueryHookResult = ReturnType<typeof useProjectsWithGpsSuspenseQuery>;
export type ProjectsWithGpsQueryResult = Apollo.QueryResult<ProjectsWithGpsQuery, ProjectsWithGpsQueryVariables>;
export const ProjectsWithChildrenDocument = gql`
  query ProjectsWithChildren($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      projectInfo {
        id
        number
        address1
        address2
        city
        country
        state
        postalCode
        phone
        latitude
        longitude
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      children {
        id
        title
        depth
        projectInfo {
          id
          number
          address1
          address2
          city
          country
          state
          postalCode
          phone
          latitude
          longitude
        }
      }
      cursor
    }
  }
`;

/**
 * __useProjectsWithChildrenQuery__
 *
 * To run a query within a React component, call `useProjectsWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsWithChildrenQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectsWithChildrenQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsWithChildrenQuery, ProjectsWithChildrenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsWithChildrenQuery, ProjectsWithChildrenQueryVariables>(
    ProjectsWithChildrenDocument,
    options
  );
}
export function useProjectsWithChildrenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsWithChildrenQuery, ProjectsWithChildrenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsWithChildrenQuery, ProjectsWithChildrenQueryVariables>(
    ProjectsWithChildrenDocument,
    options
  );
}
export function useProjectsWithChildrenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsWithChildrenQuery, ProjectsWithChildrenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsWithChildrenQuery, ProjectsWithChildrenQueryVariables>(
    ProjectsWithChildrenDocument,
    options
  );
}
export type ProjectsWithChildrenQueryHookResult = ReturnType<typeof useProjectsWithChildrenQuery>;
export type ProjectsWithChildrenLazyQueryHookResult = ReturnType<typeof useProjectsWithChildrenLazyQuery>;
export type ProjectsWithChildrenSuspenseQueryHookResult = ReturnType<typeof useProjectsWithChildrenSuspenseQuery>;
export type ProjectsWithChildrenQueryResult = Apollo.QueryResult<
  ProjectsWithChildrenQuery,
  ProjectsWithChildrenQueryVariables
>;
export const ProjectsWithInfoDocument = gql`
  query ProjectsWithInfo($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      projectInfo {
        id
        number
        address1
        address2
        city
        country
        state
        postalCode
        phone
        latitude
        longitude
        additionalInfo
      }
      cursor
    }
  }
`;

/**
 * __useProjectsWithInfoQuery__
 *
 * To run a query within a React component, call `useProjectsWithInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsWithInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsWithInfoQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectsWithInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>(ProjectsWithInfoDocument, options);
}
export function useProjectsWithInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>(ProjectsWithInfoDocument, options);
}
export function useProjectsWithInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>(
    ProjectsWithInfoDocument,
    options
  );
}
export type ProjectsWithInfoQueryHookResult = ReturnType<typeof useProjectsWithInfoQuery>;
export type ProjectsWithInfoLazyQueryHookResult = ReturnType<typeof useProjectsWithInfoLazyQuery>;
export type ProjectsWithInfoSuspenseQueryHookResult = ReturnType<typeof useProjectsWithInfoSuspenseQuery>;
export type ProjectsWithInfoQueryResult = Apollo.QueryResult<ProjectsWithInfoQuery, ProjectsWithInfoQueryVariables>;
export const SimpleProjectDocument = gql`
  query SimpleProject($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      ...SimpleProject
    }
  }
  ${SimpleProjectFragmentDoc}
`;

/**
 * __useSimpleProjectQuery__
 *
 * To run a query within a React component, call `useSimpleProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSimpleProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<SimpleProjectQuery, SimpleProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleProjectQuery, SimpleProjectQueryVariables>(SimpleProjectDocument, options);
}
export function useSimpleProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleProjectQuery, SimpleProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleProjectQuery, SimpleProjectQueryVariables>(SimpleProjectDocument, options);
}
export function useSimpleProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleProjectQuery, SimpleProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleProjectQuery, SimpleProjectQueryVariables>(SimpleProjectDocument, options);
}
export type SimpleProjectQueryHookResult = ReturnType<typeof useSimpleProjectQuery>;
export type SimpleProjectLazyQueryHookResult = ReturnType<typeof useSimpleProjectLazyQuery>;
export type SimpleProjectSuspenseQueryHookResult = ReturnType<typeof useSimpleProjectSuspenseQuery>;
export type SimpleProjectQueryResult = Apollo.QueryResult<SimpleProjectQuery, SimpleProjectQueryVariables>;
export const SimpleProjectWithTimeDocument = gql`
  query SimpleProjectWithTime(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      ...SimpleProject
    }
  }
  ${SimpleProjectFragmentDoc}
`;

/**
 * __useSimpleProjectWithTimeQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithTimeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *   },
 * });
 */
export function useSimpleProjectWithTimeQuery(
  baseOptions: Apollo.QueryHookOptions<SimpleProjectWithTimeQuery, SimpleProjectWithTimeQueryVariables> &
    ({ variables: SimpleProjectWithTimeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleProjectWithTimeQuery, SimpleProjectWithTimeQueryVariables>(
    SimpleProjectWithTimeDocument,
    options
  );
}
export function useSimpleProjectWithTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleProjectWithTimeQuery, SimpleProjectWithTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleProjectWithTimeQuery, SimpleProjectWithTimeQueryVariables>(
    SimpleProjectWithTimeDocument,
    options
  );
}
export function useSimpleProjectWithTimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleProjectWithTimeQuery, SimpleProjectWithTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleProjectWithTimeQuery, SimpleProjectWithTimeQueryVariables>(
    SimpleProjectWithTimeDocument,
    options
  );
}
export type SimpleProjectWithTimeQueryHookResult = ReturnType<typeof useSimpleProjectWithTimeQuery>;
export type SimpleProjectWithTimeLazyQueryHookResult = ReturnType<typeof useSimpleProjectWithTimeLazyQuery>;
export type SimpleProjectWithTimeSuspenseQueryHookResult = ReturnType<typeof useSimpleProjectWithTimeSuspenseQuery>;
export type SimpleProjectWithTimeQueryResult = Apollo.QueryResult<
  SimpleProjectWithTimeQuery,
  SimpleProjectWithTimeQueryVariables
>;
export const SimpleProjectWithSubDocument = gql`
  query SimpleProjectWithSub($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      archivedOn
      ancestors {
        id
        archivedOn
      }
      children {
        id
        archivedOn
      }
      cursor
    }
  }
`;

/**
 * __useSimpleProjectWithSubQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithSubQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithSubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithSubQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSimpleProjectWithSubQuery(
  baseOptions?: Apollo.QueryHookOptions<SimpleProjectWithSubQuery, SimpleProjectWithSubQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleProjectWithSubQuery, SimpleProjectWithSubQueryVariables>(
    SimpleProjectWithSubDocument,
    options
  );
}
export function useSimpleProjectWithSubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleProjectWithSubQuery, SimpleProjectWithSubQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleProjectWithSubQuery, SimpleProjectWithSubQueryVariables>(
    SimpleProjectWithSubDocument,
    options
  );
}
export function useSimpleProjectWithSubSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleProjectWithSubQuery, SimpleProjectWithSubQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleProjectWithSubQuery, SimpleProjectWithSubQueryVariables>(
    SimpleProjectWithSubDocument,
    options
  );
}
export type SimpleProjectWithSubQueryHookResult = ReturnType<typeof useSimpleProjectWithSubQuery>;
export type SimpleProjectWithSubLazyQueryHookResult = ReturnType<typeof useSimpleProjectWithSubLazyQuery>;
export type SimpleProjectWithSubSuspenseQueryHookResult = ReturnType<typeof useSimpleProjectWithSubSuspenseQuery>;
export type SimpleProjectWithSubQueryResult = Apollo.QueryResult<
  SimpleProjectWithSubQuery,
  SimpleProjectWithSubQueryVariables
>;
export const SimpleProjectWithMetricAndSubCheckDocument = gql`
  query SimpleProjectWithMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useSimpleProjectWithMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithMetricAndSubCheckQuery,
    SimpleProjectWithMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleProjectWithMetricAndSubCheckQuery, SimpleProjectWithMetricAndSubCheckQueryVariables>(
    SimpleProjectWithMetricAndSubCheckDocument,
    options
  );
}
export function useSimpleProjectWithMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithMetricAndSubCheckQuery,
    SimpleProjectWithMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleProjectWithMetricAndSubCheckQuery, SimpleProjectWithMetricAndSubCheckQueryVariables>(
    SimpleProjectWithMetricAndSubCheckDocument,
    options
  );
}
export function useSimpleProjectWithMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithMetricAndSubCheckQuery,
    SimpleProjectWithMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithMetricAndSubCheckQuery,
    SimpleProjectWithMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMetricAndSubCheckQuery
>;
export type SimpleProjectWithMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithMetricAndSubCheckQuery,
  SimpleProjectWithMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithTimeMetricAndSubCheckDocument = gql`
  query SimpleProjectWithTimeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithTimeMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithTimeMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithTimeMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithTimeMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useSimpleProjectWithTimeMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithTimeMetricAndSubCheckQuery,
    SimpleProjectWithTimeMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithTimeMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithTimeMetricAndSubCheckQuery,
    SimpleProjectWithTimeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithTimeMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithTimeMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithTimeMetricAndSubCheckQuery,
    SimpleProjectWithTimeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithTimeMetricAndSubCheckQuery,
    SimpleProjectWithTimeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithTimeMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithTimeMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithTimeMetricAndSubCheckQuery,
    SimpleProjectWithTimeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithTimeMetricAndSubCheckQuery,
    SimpleProjectWithTimeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithTimeMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithTimeMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithTimeMetricAndSubCheckQuery
>;
export type SimpleProjectWithTimeMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithTimeMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithTimeMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithTimeMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithTimeMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithTimeMetricAndSubCheckQuery,
  SimpleProjectWithTimeMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithMetricDocument = gql`
  query SimpleProjectWithMetric(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      archivedOn
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithMetricQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithMetricQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useSimpleProjectWithMetricQuery(
  baseOptions: Apollo.QueryHookOptions<SimpleProjectWithMetricQuery, SimpleProjectWithMetricQueryVariables> &
    ({ variables: SimpleProjectWithMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleProjectWithMetricQuery, SimpleProjectWithMetricQueryVariables>(
    SimpleProjectWithMetricDocument,
    options
  );
}
export function useSimpleProjectWithMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleProjectWithMetricQuery, SimpleProjectWithMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleProjectWithMetricQuery, SimpleProjectWithMetricQueryVariables>(
    SimpleProjectWithMetricDocument,
    options
  );
}
export function useSimpleProjectWithMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleProjectWithMetricQuery, SimpleProjectWithMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleProjectWithMetricQuery, SimpleProjectWithMetricQueryVariables>(
    SimpleProjectWithMetricDocument,
    options
  );
}
export type SimpleProjectWithMetricQueryHookResult = ReturnType<typeof useSimpleProjectWithMetricQuery>;
export type SimpleProjectWithMetricLazyQueryHookResult = ReturnType<typeof useSimpleProjectWithMetricLazyQuery>;
export type SimpleProjectWithMetricSuspenseQueryHookResult = ReturnType<typeof useSimpleProjectWithMetricSuspenseQuery>;
export type SimpleProjectWithMetricQueryResult = Apollo.QueryResult<
  SimpleProjectWithMetricQuery,
  SimpleProjectWithMetricQueryVariables
>;
export const SimpleProjectWithTimeOnlyMetricDocument = gql`
  query SimpleProjectWithTimeOnlyMetric(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithTimeOnlyMetricQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithTimeOnlyMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithTimeOnlyMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithTimeOnlyMetricQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useSimpleProjectWithTimeOnlyMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithTimeOnlyMetricQuery,
    SimpleProjectWithTimeOnlyMetricQueryVariables
  > &
    ({ variables: SimpleProjectWithTimeOnlyMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleProjectWithTimeOnlyMetricQuery, SimpleProjectWithTimeOnlyMetricQueryVariables>(
    SimpleProjectWithTimeOnlyMetricDocument,
    options
  );
}
export function useSimpleProjectWithTimeOnlyMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithTimeOnlyMetricQuery,
    SimpleProjectWithTimeOnlyMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleProjectWithTimeOnlyMetricQuery, SimpleProjectWithTimeOnlyMetricQueryVariables>(
    SimpleProjectWithTimeOnlyMetricDocument,
    options
  );
}
export function useSimpleProjectWithTimeOnlyMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithTimeOnlyMetricQuery,
    SimpleProjectWithTimeOnlyMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleProjectWithTimeOnlyMetricQuery, SimpleProjectWithTimeOnlyMetricQueryVariables>(
    SimpleProjectWithTimeOnlyMetricDocument,
    options
  );
}
export type SimpleProjectWithTimeOnlyMetricQueryHookResult = ReturnType<typeof useSimpleProjectWithTimeOnlyMetricQuery>;
export type SimpleProjectWithTimeOnlyMetricLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithTimeOnlyMetricLazyQuery
>;
export type SimpleProjectWithTimeOnlyMetricSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithTimeOnlyMetricSuspenseQuery
>;
export type SimpleProjectWithTimeOnlyMetricQueryResult = Apollo.QueryResult<
  SimpleProjectWithTimeOnlyMetricQuery,
  SimpleProjectWithTimeOnlyMetricQueryVariables
>;
export const SimpleProjectWithMemberMetricAndSubCheckDocument = gql`
  query SimpleProjectWithMemberMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...ProjectLaborMetrics
        memberId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithMemberMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithMemberMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithMemberMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithMemberMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useSimpleProjectWithMemberMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithMemberMetricAndSubCheckQuery,
    SimpleProjectWithMemberMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithMemberMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithMemberMetricAndSubCheckQuery,
    SimpleProjectWithMemberMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithMemberMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithMemberMetricAndSubCheckQuery,
    SimpleProjectWithMemberMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithMemberMetricAndSubCheckQuery,
    SimpleProjectWithMemberMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithMemberMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithMemberMetricAndSubCheckQuery,
    SimpleProjectWithMemberMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithMemberMetricAndSubCheckQuery,
    SimpleProjectWithMemberMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithMemberMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberMetricAndSubCheckQuery
>;
export type SimpleProjectWithMemberMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithMemberMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithMemberMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithMemberMetricAndSubCheckQuery,
  SimpleProjectWithMemberMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithMemberCostCodeMetricAndSubCheckDocument = gql`
  query SimpleProjectWithMemberCostCodeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        costCodeIds: $costCodeIds
      ) {
        ...ProjectLaborMetrics
        memberId
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithMemberCostCodeMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithMemberCostCodeMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithMemberCostCodeMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithMemberCostCodeMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useSimpleProjectWithMemberCostCodeMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberCostCodeMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithMemberCostCodeMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberCostCodeMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithMemberCostCodeMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberCostCodeMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberCostCodeMetricAndSubCheckQuery
>;
export type SimpleProjectWithMemberCostCodeMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberCostCodeMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithMemberCostCodeMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberCostCodeMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithMemberCostCodeMetricAndSubCheckQuery,
  SimpleProjectWithMemberCostCodeMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithMemberEquipmentMetricAndSubCheckDocument = gql`
  query SimpleProjectWithMemberEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        memberId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithMemberEquipmentMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithMemberEquipmentMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithMemberEquipmentMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithMemberEquipmentMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useSimpleProjectWithMemberEquipmentMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberEquipmentMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithMemberEquipmentMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberEquipmentMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithMemberEquipmentMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithMemberEquipmentMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberEquipmentMetricAndSubCheckQuery
>;
export type SimpleProjectWithMemberEquipmentMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberEquipmentMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithMemberEquipmentMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithMemberEquipmentMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithMemberEquipmentMetricAndSubCheckQuery,
  SimpleProjectWithMemberEquipmentMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithCostCodeMetricAndSubCheckDocument = gql`
  query SimpleProjectWithCostCodeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...ProjectLaborMetrics
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithCostCodeMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithCostCodeMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithCostCodeMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithCostCodeMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useSimpleProjectWithCostCodeMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithCostCodeMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithCostCodeMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithCostCodeMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithCostCodeMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithCostCodeMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
  >(SimpleProjectWithCostCodeMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithCostCodeMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithCostCodeMetricAndSubCheckQuery
>;
export type SimpleProjectWithCostCodeMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithCostCodeMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithCostCodeMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithCostCodeMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithCostCodeMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithCostCodeMetricAndSubCheckQuery,
  SimpleProjectWithCostCodeMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithCostCodeEquipmentMetricAndSubCheckDocument = gql`
  query SimpleProjectWithCostCodeEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        equipmentId
        costCodeId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
  > &
    (
      | { variables: SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithCostCodeEquipmentMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithCostCodeEquipmentMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithCostCodeEquipmentMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery
>;
export type SimpleProjectWithCostCodeEquipmentMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithCostCodeEquipmentMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithCostCodeEquipmentMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQuery,
  SimpleProjectWithCostCodeEquipmentMetricAndSubCheckQueryVariables
>;
export const SimpleProjectWithEquipmentMetricAndSubCheckDocument = gql`
  query SimpleProjectWithEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      cursor
      projectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useSimpleProjectWithEquipmentMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useSimpleProjectWithEquipmentMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleProjectWithEquipmentMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleProjectWithEquipmentMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useSimpleProjectWithEquipmentMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimpleProjectWithEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
  > &
    ({ variables: SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SimpleProjectWithEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithEquipmentMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithEquipmentMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleProjectWithEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleProjectWithEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithEquipmentMetricAndSubCheckDocument, options);
}
export function useSimpleProjectWithEquipmentMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleProjectWithEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SimpleProjectWithEquipmentMetricAndSubCheckQuery,
    SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
  >(SimpleProjectWithEquipmentMetricAndSubCheckDocument, options);
}
export type SimpleProjectWithEquipmentMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useSimpleProjectWithEquipmentMetricAndSubCheckQuery
>;
export type SimpleProjectWithEquipmentMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useSimpleProjectWithEquipmentMetricAndSubCheckLazyQuery
>;
export type SimpleProjectWithEquipmentMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useSimpleProjectWithEquipmentMetricAndSubCheckSuspenseQuery
>;
export type SimpleProjectWithEquipmentMetricAndSubCheckQueryResult = Apollo.QueryResult<
  SimpleProjectWithEquipmentMetricAndSubCheckQuery,
  SimpleProjectWithEquipmentMetricAndSubCheckQueryVariables
>;
export const ProjectIdDocument = gql`
  query ProjectId($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      cursor
    }
  }
`;

/**
 * __useProjectIdQuery__
 *
 * To run a query within a React component, call `useProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectIdQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectIdQuery(baseOptions?: Apollo.QueryHookOptions<ProjectIdQuery, ProjectIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectIdQuery, ProjectIdQueryVariables>(ProjectIdDocument, options);
}
export function useProjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectIdQuery, ProjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectIdQuery, ProjectIdQueryVariables>(ProjectIdDocument, options);
}
export function useProjectIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectIdQuery, ProjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectIdQuery, ProjectIdQueryVariables>(ProjectIdDocument, options);
}
export type ProjectIdQueryHookResult = ReturnType<typeof useProjectIdQuery>;
export type ProjectIdLazyQueryHookResult = ReturnType<typeof useProjectIdLazyQuery>;
export type ProjectIdSuspenseQueryHookResult = ReturnType<typeof useProjectIdSuspenseQuery>;
export type ProjectIdQueryResult = Apollo.QueryResult<ProjectIdQuery, ProjectIdQueryVariables>;
export const ProjectTitleDocument = gql`
  query ProjectTitle($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      cursor
    }
  }
`;

/**
 * __useProjectTitleQuery__
 *
 * To run a query within a React component, call `useProjectTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTitleQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectTitleQuery, ProjectTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectTitleQuery, ProjectTitleQueryVariables>(ProjectTitleDocument, options);
}
export function useProjectTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTitleQuery, ProjectTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectTitleQuery, ProjectTitleQueryVariables>(ProjectTitleDocument, options);
}
export function useProjectTitleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectTitleQuery, ProjectTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectTitleQuery, ProjectTitleQueryVariables>(ProjectTitleDocument, options);
}
export type ProjectTitleQueryHookResult = ReturnType<typeof useProjectTitleQuery>;
export type ProjectTitleLazyQueryHookResult = ReturnType<typeof useProjectTitleLazyQuery>;
export type ProjectTitleSuspenseQueryHookResult = ReturnType<typeof useProjectTitleSuspenseQuery>;
export type ProjectTitleQueryResult = Apollo.QueryResult<ProjectTitleQuery, ProjectTitleQueryVariables>;
export const ProjectsAncestorsQueryDocument = gql`
  query ProjectsAncestorsQuery($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
    }
  }
`;

/**
 * __useProjectsAncestorsQueryQuery__
 *
 * To run a query within a React component, call `useProjectsAncestorsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsAncestorsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsAncestorsQueryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectsAncestorsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsAncestorsQueryQuery, ProjectsAncestorsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsAncestorsQueryQuery, ProjectsAncestorsQueryQueryVariables>(
    ProjectsAncestorsQueryDocument,
    options
  );
}
export function useProjectsAncestorsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsAncestorsQueryQuery, ProjectsAncestorsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsAncestorsQueryQuery, ProjectsAncestorsQueryQueryVariables>(
    ProjectsAncestorsQueryDocument,
    options
  );
}
export function useProjectsAncestorsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsAncestorsQueryQuery, ProjectsAncestorsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsAncestorsQueryQuery, ProjectsAncestorsQueryQueryVariables>(
    ProjectsAncestorsQueryDocument,
    options
  );
}
export type ProjectsAncestorsQueryQueryHookResult = ReturnType<typeof useProjectsAncestorsQueryQuery>;
export type ProjectsAncestorsQueryLazyQueryHookResult = ReturnType<typeof useProjectsAncestorsQueryLazyQuery>;
export type ProjectsAncestorsQuerySuspenseQueryHookResult = ReturnType<typeof useProjectsAncestorsQuerySuspenseQuery>;
export type ProjectsAncestorsQueryQueryResult = Apollo.QueryResult<
  ProjectsAncestorsQueryQuery,
  ProjectsAncestorsQueryQueryVariables
>;
export const QuickSearchProjectsAncestorsQueryDocument = gql`
  query QuickSearchProjectsAncestorsQuery($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      ancestors {
        id
        title
        depth
        archivedOn
      }
      parentProject {
        id
        archivedOn
      }
      cursor
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;

/**
 * __useQuickSearchProjectsAncestorsQueryQuery__
 *
 * To run a query within a React component, call `useQuickSearchProjectsAncestorsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchProjectsAncestorsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchProjectsAncestorsQueryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQuickSearchProjectsAncestorsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuickSearchProjectsAncestorsQueryQuery,
    QuickSearchProjectsAncestorsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuickSearchProjectsAncestorsQueryQuery, QuickSearchProjectsAncestorsQueryQueryVariables>(
    QuickSearchProjectsAncestorsQueryDocument,
    options
  );
}
export function useQuickSearchProjectsAncestorsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuickSearchProjectsAncestorsQueryQuery,
    QuickSearchProjectsAncestorsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuickSearchProjectsAncestorsQueryQuery, QuickSearchProjectsAncestorsQueryQueryVariables>(
    QuickSearchProjectsAncestorsQueryDocument,
    options
  );
}
export function useQuickSearchProjectsAncestorsQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QuickSearchProjectsAncestorsQueryQuery,
    QuickSearchProjectsAncestorsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QuickSearchProjectsAncestorsQueryQuery,
    QuickSearchProjectsAncestorsQueryQueryVariables
  >(QuickSearchProjectsAncestorsQueryDocument, options);
}
export type QuickSearchProjectsAncestorsQueryQueryHookResult = ReturnType<
  typeof useQuickSearchProjectsAncestorsQueryQuery
>;
export type QuickSearchProjectsAncestorsQueryLazyQueryHookResult = ReturnType<
  typeof useQuickSearchProjectsAncestorsQueryLazyQuery
>;
export type QuickSearchProjectsAncestorsQuerySuspenseQueryHookResult = ReturnType<
  typeof useQuickSearchProjectsAncestorsQuerySuspenseQuery
>;
export type QuickSearchProjectsAncestorsQueryQueryResult = Apollo.QueryResult<
  QuickSearchProjectsAncestorsQueryQuery,
  QuickSearchProjectsAncestorsQueryQueryVariables
>;
export const ProjectClockStateDocument = gql`
  query ProjectClockState($filter: ProjectClockStateFilter) {
    projectClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;

/**
 * __useProjectClockStateQuery__
 *
 * To run a query within a React component, call `useProjectClockStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectClockStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectClockStateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectClockStateQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectClockStateQuery, ProjectClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectClockStateQuery, ProjectClockStateQueryVariables>(ProjectClockStateDocument, options);
}
export function useProjectClockStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectClockStateQuery, ProjectClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectClockStateQuery, ProjectClockStateQueryVariables>(
    ProjectClockStateDocument,
    options
  );
}
export function useProjectClockStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectClockStateQuery, ProjectClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectClockStateQuery, ProjectClockStateQueryVariables>(
    ProjectClockStateDocument,
    options
  );
}
export type ProjectClockStateQueryHookResult = ReturnType<typeof useProjectClockStateQuery>;
export type ProjectClockStateLazyQueryHookResult = ReturnType<typeof useProjectClockStateLazyQuery>;
export type ProjectClockStateSuspenseQueryHookResult = ReturnType<typeof useProjectClockStateSuspenseQuery>;
export type ProjectClockStateQueryResult = Apollo.QueryResult<ProjectClockStateQuery, ProjectClockStateQueryVariables>;
export const QueryRecurringInspectionAssignmentCategoryLinksDocument = gql`
  query QueryRecurringInspectionAssignmentCategoryLinks(
    $filter: RecurringInspectionAssignmentCategoryLinkFilter
    $sort: [RecurringInspectionAssignmentCategoryLinkSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    recurringInspectionAssignmentCategoryLinks(
      filter: $filter
      sort: $sort
      first: $first
      pageNumber: $pageNumber
      after: $after
    ) {
      ...RecurringInspectionAssignmentCategoryLinkFragment
      cursor
    }
  }
  ${RecurringInspectionAssignmentCategoryLinkFragmentFragmentDoc}
`;

/**
 * __useQueryRecurringInspectionAssignmentCategoryLinksQuery__
 *
 * To run a query within a React component, call `useQueryRecurringInspectionAssignmentCategoryLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryRecurringInspectionAssignmentCategoryLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryRecurringInspectionAssignmentCategoryLinksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryRecurringInspectionAssignmentCategoryLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryRecurringInspectionAssignmentCategoryLinksQuery,
    QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryRecurringInspectionAssignmentCategoryLinksQuery,
    QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
  >(QueryRecurringInspectionAssignmentCategoryLinksDocument, options);
}
export function useQueryRecurringInspectionAssignmentCategoryLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryRecurringInspectionAssignmentCategoryLinksQuery,
    QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryRecurringInspectionAssignmentCategoryLinksQuery,
    QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
  >(QueryRecurringInspectionAssignmentCategoryLinksDocument, options);
}
export function useQueryRecurringInspectionAssignmentCategoryLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryRecurringInspectionAssignmentCategoryLinksQuery,
    QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryRecurringInspectionAssignmentCategoryLinksQuery,
    QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
  >(QueryRecurringInspectionAssignmentCategoryLinksDocument, options);
}
export type QueryRecurringInspectionAssignmentCategoryLinksQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentCategoryLinksQuery
>;
export type QueryRecurringInspectionAssignmentCategoryLinksLazyQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentCategoryLinksLazyQuery
>;
export type QueryRecurringInspectionAssignmentCategoryLinksSuspenseQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentCategoryLinksSuspenseQuery
>;
export type QueryRecurringInspectionAssignmentCategoryLinksQueryResult = Apollo.QueryResult<
  QueryRecurringInspectionAssignmentCategoryLinksQuery,
  QueryRecurringInspectionAssignmentCategoryLinksQueryVariables
>;
export const QueryRecurringInspectionAssignmentNotificationLinksDocument = gql`
  query QueryRecurringInspectionAssignmentNotificationLinks(
    $filter: RecurringInspectionAssignmentNotificationLinkFilter
    $sort: [RecurringInspectionAssignmentNotificationLinkSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    recurringInspectionAssignmentNotificationLinks(
      filter: $filter
      sort: $sort
      first: $first
      pageNumber: $pageNumber
      after: $after
    ) {
      ...RecurringInspectionAssignmentNotificationLinkFragment
      cursor
    }
  }
  ${RecurringInspectionAssignmentNotificationLinkFragmentFragmentDoc}
`;

/**
 * __useQueryRecurringInspectionAssignmentNotificationLinksQuery__
 *
 * To run a query within a React component, call `useQueryRecurringInspectionAssignmentNotificationLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryRecurringInspectionAssignmentNotificationLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryRecurringInspectionAssignmentNotificationLinksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryRecurringInspectionAssignmentNotificationLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryRecurringInspectionAssignmentNotificationLinksQuery,
    QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryRecurringInspectionAssignmentNotificationLinksQuery,
    QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
  >(QueryRecurringInspectionAssignmentNotificationLinksDocument, options);
}
export function useQueryRecurringInspectionAssignmentNotificationLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryRecurringInspectionAssignmentNotificationLinksQuery,
    QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryRecurringInspectionAssignmentNotificationLinksQuery,
    QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
  >(QueryRecurringInspectionAssignmentNotificationLinksDocument, options);
}
export function useQueryRecurringInspectionAssignmentNotificationLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryRecurringInspectionAssignmentNotificationLinksQuery,
    QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryRecurringInspectionAssignmentNotificationLinksQuery,
    QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
  >(QueryRecurringInspectionAssignmentNotificationLinksDocument, options);
}
export type QueryRecurringInspectionAssignmentNotificationLinksQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentNotificationLinksQuery
>;
export type QueryRecurringInspectionAssignmentNotificationLinksLazyQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentNotificationLinksLazyQuery
>;
export type QueryRecurringInspectionAssignmentNotificationLinksSuspenseQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentNotificationLinksSuspenseQuery
>;
export type QueryRecurringInspectionAssignmentNotificationLinksQueryResult = Apollo.QueryResult<
  QueryRecurringInspectionAssignmentNotificationLinksQuery,
  QueryRecurringInspectionAssignmentNotificationLinksQueryVariables
>;
export const QueryRecurringInspectionAssignmentsDocument = gql`
  query QueryRecurringInspectionAssignments(
    $filter: RecurringInspectionAssignmentFilter
    $sort: [RecurringInspectionAssignmentSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    recurringInspectionAssignments(
      filter: $filter
      sort: $sort
      first: $first
      pageNumber: $pageNumber
      after: $after
    ) {
      ...RecurringInspectionAssignmentFragment
      cursor
    }
  }
  ${RecurringInspectionAssignmentFragmentFragmentDoc}
`;

/**
 * __useQueryRecurringInspectionAssignmentsQuery__
 *
 * To run a query within a React component, call `useQueryRecurringInspectionAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryRecurringInspectionAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryRecurringInspectionAssignmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryRecurringInspectionAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryRecurringInspectionAssignmentsQuery,
    QueryRecurringInspectionAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryRecurringInspectionAssignmentsQuery, QueryRecurringInspectionAssignmentsQueryVariables>(
    QueryRecurringInspectionAssignmentsDocument,
    options
  );
}
export function useQueryRecurringInspectionAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryRecurringInspectionAssignmentsQuery,
    QueryRecurringInspectionAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryRecurringInspectionAssignmentsQuery,
    QueryRecurringInspectionAssignmentsQueryVariables
  >(QueryRecurringInspectionAssignmentsDocument, options);
}
export function useQueryRecurringInspectionAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryRecurringInspectionAssignmentsQuery,
    QueryRecurringInspectionAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryRecurringInspectionAssignmentsQuery,
    QueryRecurringInspectionAssignmentsQueryVariables
  >(QueryRecurringInspectionAssignmentsDocument, options);
}
export type QueryRecurringInspectionAssignmentsQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentsQuery
>;
export type QueryRecurringInspectionAssignmentsLazyQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentsLazyQuery
>;
export type QueryRecurringInspectionAssignmentsSuspenseQueryHookResult = ReturnType<
  typeof useQueryRecurringInspectionAssignmentsSuspenseQuery
>;
export type QueryRecurringInspectionAssignmentsQueryResult = Apollo.QueryResult<
  QueryRecurringInspectionAssignmentsQuery,
  QueryRecurringInspectionAssignmentsQueryVariables
>;
export const SafetyInspectionCategoriesDocument = gql`
  query SafetyInspectionCategories($reportType: String!) {
    safetyInspectionCategories(reportType: $reportType) {
      reportType
      name
      id
      questions {
        name
        recommendResponse
        failLinkUrl
        failLinkName
        sort
      }
    }
  }
`;

/**
 * __useSafetyInspectionCategoriesQuery__
 *
 * To run a query within a React component, call `useSafetyInspectionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetyInspectionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetyInspectionCategoriesQuery({
 *   variables: {
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useSafetyInspectionCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<SafetyInspectionCategoriesQuery, SafetyInspectionCategoriesQueryVariables> &
    ({ variables: SafetyInspectionCategoriesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SafetyInspectionCategoriesQuery, SafetyInspectionCategoriesQueryVariables>(
    SafetyInspectionCategoriesDocument,
    options
  );
}
export function useSafetyInspectionCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SafetyInspectionCategoriesQuery, SafetyInspectionCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SafetyInspectionCategoriesQuery, SafetyInspectionCategoriesQueryVariables>(
    SafetyInspectionCategoriesDocument,
    options
  );
}
export function useSafetyInspectionCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SafetyInspectionCategoriesQuery,
    SafetyInspectionCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SafetyInspectionCategoriesQuery, SafetyInspectionCategoriesQueryVariables>(
    SafetyInspectionCategoriesDocument,
    options
  );
}
export type SafetyInspectionCategoriesQueryHookResult = ReturnType<typeof useSafetyInspectionCategoriesQuery>;
export type SafetyInspectionCategoriesLazyQueryHookResult = ReturnType<typeof useSafetyInspectionCategoriesLazyQuery>;
export type SafetyInspectionCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useSafetyInspectionCategoriesSuspenseQuery
>;
export type SafetyInspectionCategoriesQueryResult = Apollo.QueryResult<
  SafetyInspectionCategoriesQuery,
  SafetyInspectionCategoriesQueryVariables
>;
export const SafetyInspectionChecklistsDocument = gql`
  query SafetyInspectionChecklists {
    safetyInspectionChecklists {
      name
      reportType
    }
  }
`;

/**
 * __useSafetyInspectionChecklistsQuery__
 *
 * To run a query within a React component, call `useSafetyInspectionChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetyInspectionChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetyInspectionChecklistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSafetyInspectionChecklistsQuery(
  baseOptions?: Apollo.QueryHookOptions<SafetyInspectionChecklistsQuery, SafetyInspectionChecklistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SafetyInspectionChecklistsQuery, SafetyInspectionChecklistsQueryVariables>(
    SafetyInspectionChecklistsDocument,
    options
  );
}
export function useSafetyInspectionChecklistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SafetyInspectionChecklistsQuery, SafetyInspectionChecklistsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SafetyInspectionChecklistsQuery, SafetyInspectionChecklistsQueryVariables>(
    SafetyInspectionChecklistsDocument,
    options
  );
}
export function useSafetyInspectionChecklistsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SafetyInspectionChecklistsQuery,
    SafetyInspectionChecklistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SafetyInspectionChecklistsQuery, SafetyInspectionChecklistsQueryVariables>(
    SafetyInspectionChecklistsDocument,
    options
  );
}
export type SafetyInspectionChecklistsQueryHookResult = ReturnType<typeof useSafetyInspectionChecklistsQuery>;
export type SafetyInspectionChecklistsLazyQueryHookResult = ReturnType<typeof useSafetyInspectionChecklistsLazyQuery>;
export type SafetyInspectionChecklistsSuspenseQueryHookResult = ReturnType<
  typeof useSafetyInspectionChecklistsSuspenseQuery
>;
export type SafetyInspectionChecklistsQueryResult = Apollo.QueryResult<
  SafetyInspectionChecklistsQuery,
  SafetyInspectionChecklistsQueryVariables
>;
export const SafetySignaturesDocument = gql`
  query SafetySignatures($first: Int, $after: String, $filter: SafetySignatureFilter, $sort: [SafetySignatureSort!]) {
    safetySignatures(after: $after, first: $first, filter: $filter, sort: $sort) {
      __typename
      ...SafetySignatureFragment
    }
  }
  ${SafetySignatureFragmentFragmentDoc}
`;

/**
 * __useSafetySignaturesQuery__
 *
 * To run a query within a React component, call `useSafetySignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetySignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetySignaturesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSafetySignaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<SafetySignaturesQuery, SafetySignaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SafetySignaturesQuery, SafetySignaturesQueryVariables>(SafetySignaturesDocument, options);
}
export function useSafetySignaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SafetySignaturesQuery, SafetySignaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SafetySignaturesQuery, SafetySignaturesQueryVariables>(SafetySignaturesDocument, options);
}
export function useSafetySignaturesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SafetySignaturesQuery, SafetySignaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SafetySignaturesQuery, SafetySignaturesQueryVariables>(
    SafetySignaturesDocument,
    options
  );
}
export type SafetySignaturesQueryHookResult = ReturnType<typeof useSafetySignaturesQuery>;
export type SafetySignaturesLazyQueryHookResult = ReturnType<typeof useSafetySignaturesLazyQuery>;
export type SafetySignaturesSuspenseQueryHookResult = ReturnType<typeof useSafetySignaturesSuspenseQuery>;
export type SafetySignaturesQueryResult = Apollo.QueryResult<SafetySignaturesQuery, SafetySignaturesQueryVariables>;
export const SafetySignaturesDetailsDocument = gql`
  query SafetySignaturesDetails(
    $first: Int
    $after: String
    $filter: SafetySignatureFilter
    $sort: [SafetySignatureSort!]
  ) {
    safetySignatures(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      memberId
      proxyMemberId
      startTime
      startTimeDst
      endTime
      endTimeDst
      createdOnDst
      deviceType
      breakPolicyFollowed
      breakPolicyFollowedReason
      injured
      injuryDescription
      timeAccurate
      timeAccurateReason
      breakPolicyFollowedResolvedOn
      breakPolicyFollowedResolvedBy
      breakPolicyFollowedResolvedComment
      injuredResolvedOn
      injuredResolvedBy
      injuredResolvedComment
      timeAccurateResolvedOn
      timeAccurateResolvedBy
      timeAccurateResolvedComment
      signatureUrl
      createdOnLocal
      createdOn
      deletedOn
      submittedOn
      updatedOn
      incidentId
      incident {
        ...IncidentDetails
      }
      member {
        id
        firstName
        lastName
      }
      proxyMember {
        id
        firstName
        lastName
      }
      breakPolicyFollowedResolvedByMember {
        id
        firstName
        lastName
      }
      timeAccurateResolvedByMember {
        id
        firstName
        lastName
      }
      injuredResolvedByMember {
        id
        firstName
        lastName
      }
      customQuestionsFlagged
      customQuestionsResolvedOn
      customAnswers {
        id
        questionTitle
        questionPrompt
        questionDescription
        description
        flagged
        answer
        resolvedOn
        createdOn
        audienceType
        positionId
        resolverId
        resolvedComment
        resolverMember {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${IncidentDetailsFragmentDoc}
`;

/**
 * __useSafetySignaturesDetailsQuery__
 *
 * To run a query within a React component, call `useSafetySignaturesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetySignaturesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetySignaturesDetailsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSafetySignaturesDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<SafetySignaturesDetailsQuery, SafetySignaturesDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SafetySignaturesDetailsQuery, SafetySignaturesDetailsQueryVariables>(
    SafetySignaturesDetailsDocument,
    options
  );
}
export function useSafetySignaturesDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SafetySignaturesDetailsQuery, SafetySignaturesDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SafetySignaturesDetailsQuery, SafetySignaturesDetailsQueryVariables>(
    SafetySignaturesDetailsDocument,
    options
  );
}
export function useSafetySignaturesDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SafetySignaturesDetailsQuery, SafetySignaturesDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SafetySignaturesDetailsQuery, SafetySignaturesDetailsQueryVariables>(
    SafetySignaturesDetailsDocument,
    options
  );
}
export type SafetySignaturesDetailsQueryHookResult = ReturnType<typeof useSafetySignaturesDetailsQuery>;
export type SafetySignaturesDetailsLazyQueryHookResult = ReturnType<typeof useSafetySignaturesDetailsLazyQuery>;
export type SafetySignaturesDetailsSuspenseQueryHookResult = ReturnType<typeof useSafetySignaturesDetailsSuspenseQuery>;
export type SafetySignaturesDetailsQueryResult = Apollo.QueryResult<
  SafetySignaturesDetailsQuery,
  SafetySignaturesDetailsQueryVariables
>;
export const ScheduleRemindersDocument = gql`
  query ScheduleReminders(
    $filter: ScheduleReminderFilter
    $sort: [ScheduleReminderSort!]
    $first: Int
    $after: String
  ) {
    scheduleReminders(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      organizationId
      memberId
      startTime
      endTime
      payload
      type
      createdOn
      cursor
      notifyEmployee
      isDraft
      overrideRecurringScheduleId
      project {
        id
        ...SchedulingReminderProjectFields
      }
      costCode {
        id
        ...SchedulingReminderCostCodeFields
      }
      equipment {
        id
        ...SchedulingReminderEquipmentFields
      }
      links {
        id
        scheduleReminderId
        memberId
        member {
          id
          ...SchedulingScheduledMember
        }
      }
    }
  }
  ${SchedulingReminderProjectFieldsFragmentDoc}
  ${SchedulingReminderCostCodeFieldsFragmentDoc}
  ${SchedulingReminderEquipmentFieldsFragmentDoc}
  ${SchedulingScheduledMemberFragmentDoc}
`;

/**
 * __useScheduleRemindersQuery__
 *
 * To run a query within a React component, call `useScheduleRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleRemindersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useScheduleRemindersQuery(
  baseOptions?: Apollo.QueryHookOptions<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>(ScheduleRemindersDocument, options);
}
export function useScheduleRemindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>(
    ScheduleRemindersDocument,
    options
  );
}
export function useScheduleRemindersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>(
    ScheduleRemindersDocument,
    options
  );
}
export type ScheduleRemindersQueryHookResult = ReturnType<typeof useScheduleRemindersQuery>;
export type ScheduleRemindersLazyQueryHookResult = ReturnType<typeof useScheduleRemindersLazyQuery>;
export type ScheduleRemindersSuspenseQueryHookResult = ReturnType<typeof useScheduleRemindersSuspenseQuery>;
export type ScheduleRemindersQueryResult = Apollo.QueryResult<ScheduleRemindersQuery, ScheduleRemindersQueryVariables>;
export const ScheduleReminderBasicLinksDocument = gql`
  query ScheduleReminderBasicLinks(
    $filter: ScheduleReminderFilter
    $sort: [ScheduleReminderSort!]
    $first: Int
    $after: String
  ) {
    scheduleReminders(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      organizationId
      memberId
      startTime
      endTime
      payload
      type
      createdOn
      cursor
      notifyEmployee
      overrideRecurringScheduleId
      links {
        id
        scheduleReminderId
        memberId
      }
    }
  }
`;

/**
 * __useScheduleReminderBasicLinksQuery__
 *
 * To run a query within a React component, call `useScheduleReminderBasicLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleReminderBasicLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleReminderBasicLinksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useScheduleReminderBasicLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<ScheduleReminderBasicLinksQuery, ScheduleReminderBasicLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleReminderBasicLinksQuery, ScheduleReminderBasicLinksQueryVariables>(
    ScheduleReminderBasicLinksDocument,
    options
  );
}
export function useScheduleReminderBasicLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScheduleReminderBasicLinksQuery, ScheduleReminderBasicLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleReminderBasicLinksQuery, ScheduleReminderBasicLinksQueryVariables>(
    ScheduleReminderBasicLinksDocument,
    options
  );
}
export function useScheduleReminderBasicLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ScheduleReminderBasicLinksQuery,
    ScheduleReminderBasicLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScheduleReminderBasicLinksQuery, ScheduleReminderBasicLinksQueryVariables>(
    ScheduleReminderBasicLinksDocument,
    options
  );
}
export type ScheduleReminderBasicLinksQueryHookResult = ReturnType<typeof useScheduleReminderBasicLinksQuery>;
export type ScheduleReminderBasicLinksLazyQueryHookResult = ReturnType<typeof useScheduleReminderBasicLinksLazyQuery>;
export type ScheduleReminderBasicLinksSuspenseQueryHookResult = ReturnType<
  typeof useScheduleReminderBasicLinksSuspenseQuery
>;
export type ScheduleReminderBasicLinksQueryResult = Apollo.QueryResult<
  ScheduleReminderBasicLinksQuery,
  ScheduleReminderBasicLinksQueryVariables
>;
export const ClientTimeEntryBreakLogsDocument = gql`
  query ClientTimeEntryBreakLogs(
    $after: String
    $filter: ClientTimeEntryBreakLogFilter
    $sort: [ClientTimeEntryBreakLogSort!]
    $first: Int
  ) {
    clientTimeEntryBreakLogs(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      clientTimeEntryLogId
      deviceType
      endLocationId
      startLocationId
      mergedTimeEntryBreakId
      originalTimeEntryBreakId
      timeEntryId
      timeEntryBreakId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryBreakCreatedOn
      timeEntryBreakDeletedOn
      startTimeDst
      endTimeDst
      paidBreak
      deletedOn
      submittedOn
      createdOn
      updatedOn
      cursor
      timeEntryBreak {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
        deletedOn
        timeEntry {
          id
          startTime
          daylightSavingTime
          endTime
          metaDaylightSavingTime
        }
        clientLogs {
          id
          startTime
          endTime
          actionType
          deviceType
          createdOn
          startLocationId
          endLocationId
          clientTimeEntryLogId
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        createdOn
        deletedOn
        submittedOn
        updatedOn
      }
    }
  }
`;

/**
 * __useClientTimeEntryBreakLogsQuery__
 *
 * To run a query within a React component, call `useClientTimeEntryBreakLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientTimeEntryBreakLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientTimeEntryBreakLogsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useClientTimeEntryBreakLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<ClientTimeEntryBreakLogsQuery, ClientTimeEntryBreakLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientTimeEntryBreakLogsQuery, ClientTimeEntryBreakLogsQueryVariables>(
    ClientTimeEntryBreakLogsDocument,
    options
  );
}
export function useClientTimeEntryBreakLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientTimeEntryBreakLogsQuery, ClientTimeEntryBreakLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientTimeEntryBreakLogsQuery, ClientTimeEntryBreakLogsQueryVariables>(
    ClientTimeEntryBreakLogsDocument,
    options
  );
}
export function useClientTimeEntryBreakLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ClientTimeEntryBreakLogsQuery, ClientTimeEntryBreakLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ClientTimeEntryBreakLogsQuery, ClientTimeEntryBreakLogsQueryVariables>(
    ClientTimeEntryBreakLogsDocument,
    options
  );
}
export type ClientTimeEntryBreakLogsQueryHookResult = ReturnType<typeof useClientTimeEntryBreakLogsQuery>;
export type ClientTimeEntryBreakLogsLazyQueryHookResult = ReturnType<typeof useClientTimeEntryBreakLogsLazyQuery>;
export type ClientTimeEntryBreakLogsSuspenseQueryHookResult = ReturnType<
  typeof useClientTimeEntryBreakLogsSuspenseQuery
>;
export type ClientTimeEntryBreakLogsQueryResult = Apollo.QueryResult<
  ClientTimeEntryBreakLogsQuery,
  ClientTimeEntryBreakLogsQueryVariables
>;
export const TimeEntryBreaksDocument = gql`
  query TimeEntryBreaks($after: String, $filter: TimeEntryBreakFilter, $first: Int) {
    timeEntryBreaks(after: $after, filter: $filter, first: $first) {
      ...TimeEntryBreakDetails
    }
  }
  ${TimeEntryBreakDetailsFragmentDoc}
`;

/**
 * __useTimeEntryBreaksQuery__
 *
 * To run a query within a React component, call `useTimeEntryBreaksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryBreaksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryBreaksQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTimeEntryBreaksQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>(TimeEntryBreaksDocument, options);
}
export function useTimeEntryBreaksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>(TimeEntryBreaksDocument, options);
}
export function useTimeEntryBreaksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>(TimeEntryBreaksDocument, options);
}
export type TimeEntryBreaksQueryHookResult = ReturnType<typeof useTimeEntryBreaksQuery>;
export type TimeEntryBreaksLazyQueryHookResult = ReturnType<typeof useTimeEntryBreaksLazyQuery>;
export type TimeEntryBreaksSuspenseQueryHookResult = ReturnType<typeof useTimeEntryBreaksSuspenseQuery>;
export type TimeEntryBreaksQueryResult = Apollo.QueryResult<TimeEntryBreaksQuery, TimeEntryBreaksQueryVariables>;
export const TimeEntryLogsDocument = gql`
  query timeEntryLogs($after: String, $filter: ClientTimeEntryLogFilter, $first: Int) {
    clientTimeEntryLogs(after: $after, filter: $filter, first: $first, sort: [{ createdOn: asc }]) {
      id
      costCodeId
      description
      deviceType
      equipmentId
      endLocationId
      memberId
      mergedTimeEntryId
      originalTimeEntryId
      projectId
      startLocationId
      timeEntryId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryCreatedOn
      timeEntryDeletedOn
      daylightSavingTime
      metaDaylightSavingTime
      deletedOn
      submittedOn
      updatedOn
      cursor
      timeEntry {
        id
        member {
          id
          firstName
          lastName
        }
        project {
          id
          title
          depth
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useTimeEntryLogsQuery__
 *
 * To run a query within a React component, call `useTimeEntryLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryLogsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTimeEntryLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>(TimeEntryLogsDocument, options);
}
export function useTimeEntryLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>(TimeEntryLogsDocument, options);
}
export function useTimeEntryLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>(TimeEntryLogsDocument, options);
}
export type TimeEntryLogsQueryHookResult = ReturnType<typeof useTimeEntryLogsQuery>;
export type TimeEntryLogsLazyQueryHookResult = ReturnType<typeof useTimeEntryLogsLazyQuery>;
export type TimeEntryLogsSuspenseQueryHookResult = ReturnType<typeof useTimeEntryLogsSuspenseQuery>;
export type TimeEntryLogsQueryResult = Apollo.QueryResult<TimeEntryLogsQuery, TimeEntryLogsQueryVariables>;
export const TimeEntryLogDocument = gql`
  query timeEntryLog($timeEntryLogId: Uuid!) {
    clientTimeEntryLogs(filter: { id: { equal: $timeEntryLogId } }) {
      id
      costCodeId
      description
      deviceType
      equipmentId
      endLocationId
      memberId
      mergedTimeEntryId
      originalTimeEntryId
      projectId
      startLocationId
      timeEntryId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryCreatedOn
      timeEntryDeletedOn
      daylightSavingTime
      metaDaylightSavingTime
      deletedOn
      submittedOn
      updatedOn
      cursor
      timeEntry {
        id
        member {
          id
          firstName
          lastName
        }
        project {
          id
          title
          depth
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        endTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          endTime
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useTimeEntryLogQuery__
 *
 * To run a query within a React component, call `useTimeEntryLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryLogQuery({
 *   variables: {
 *      timeEntryLogId: // value for 'timeEntryLogId'
 *   },
 * });
 */
export function useTimeEntryLogQuery(
  baseOptions: Apollo.QueryHookOptions<TimeEntryLogQuery, TimeEntryLogQueryVariables> &
    ({ variables: TimeEntryLogQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryLogQuery, TimeEntryLogQueryVariables>(TimeEntryLogDocument, options);
}
export function useTimeEntryLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryLogQuery, TimeEntryLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryLogQuery, TimeEntryLogQueryVariables>(TimeEntryLogDocument, options);
}
export function useTimeEntryLogSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryLogQuery, TimeEntryLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryLogQuery, TimeEntryLogQueryVariables>(TimeEntryLogDocument, options);
}
export type TimeEntryLogQueryHookResult = ReturnType<typeof useTimeEntryLogQuery>;
export type TimeEntryLogLazyQueryHookResult = ReturnType<typeof useTimeEntryLogLazyQuery>;
export type TimeEntryLogSuspenseQueryHookResult = ReturnType<typeof useTimeEntryLogSuspenseQuery>;
export type TimeEntryLogQueryResult = Apollo.QueryResult<TimeEntryLogQuery, TimeEntryLogQueryVariables>;
export const BasicTimeEntriesDocument = gql`
  query BasicTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      startTime
      endTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;

/**
 * __useBasicTimeEntriesQuery__
 *
 * To run a query within a React component, call `useBasicTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useBasicTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>(BasicTimeEntriesDocument, options);
}
export function useBasicTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>(BasicTimeEntriesDocument, options);
}
export function useBasicTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>(
    BasicTimeEntriesDocument,
    options
  );
}
export type BasicTimeEntriesQueryHookResult = ReturnType<typeof useBasicTimeEntriesQuery>;
export type BasicTimeEntriesLazyQueryHookResult = ReturnType<typeof useBasicTimeEntriesLazyQuery>;
export type BasicTimeEntriesSuspenseQueryHookResult = ReturnType<typeof useBasicTimeEntriesSuspenseQuery>;
export type BasicTimeEntriesQueryResult = Apollo.QueryResult<BasicTimeEntriesQuery, BasicTimeEntriesQueryVariables>;
export const GetSimpleTimeEntriesDocument = gql`
  query GetSimpleTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      startTime
      endTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
      }
      cursor
    }
  }
`;

/**
 * __useGetSimpleTimeEntriesQuery__
 *
 * To run a query within a React component, call `useGetSimpleTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetSimpleTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSimpleTimeEntriesQuery, GetSimpleTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSimpleTimeEntriesQuery, GetSimpleTimeEntriesQueryVariables>(
    GetSimpleTimeEntriesDocument,
    options
  );
}
export function useGetSimpleTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSimpleTimeEntriesQuery, GetSimpleTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSimpleTimeEntriesQuery, GetSimpleTimeEntriesQueryVariables>(
    GetSimpleTimeEntriesDocument,
    options
  );
}
export function useGetSimpleTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSimpleTimeEntriesQuery, GetSimpleTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetSimpleTimeEntriesQuery, GetSimpleTimeEntriesQueryVariables>(
    GetSimpleTimeEntriesDocument,
    options
  );
}
export type GetSimpleTimeEntriesQueryHookResult = ReturnType<typeof useGetSimpleTimeEntriesQuery>;
export type GetSimpleTimeEntriesLazyQueryHookResult = ReturnType<typeof useGetSimpleTimeEntriesLazyQuery>;
export type GetSimpleTimeEntriesSuspenseQueryHookResult = ReturnType<typeof useGetSimpleTimeEntriesSuspenseQuery>;
export type GetSimpleTimeEntriesQueryResult = Apollo.QueryResult<
  GetSimpleTimeEntriesQuery,
  GetSimpleTimeEntriesQueryVariables
>;
export const GetTimeEntryByIdDocument = gql`
  query GetTimeEntryById($timeEntryId: Uuid!) {
    timeEntries(filter: { id: { equal: $timeEntryId } }) {
      id
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        archivedOn
        memberLock {
          id
          effectiveDate
        }
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
      }
      project {
        id
        title
        depth
        projectInfo {
          id
          latitude
          locationRadius
          longitude
        }
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            latitude
            locationRadius
            longitude
          }
        }
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      startDeviceTime
      endTime
      endDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        timeEntry {
          id
          startTime
          daylightSavingTime
          endTime
          metaDaylightSavingTime
        }
        clientLogs {
          id
        }
        startTime
        startDeviceTime
        startTimeTrusted
        startTimeDst
        endTime
        endDeviceTime
        endTimeTrusted
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      hasLocation
      startLocation {
        id
      }
      endLocation {
        id
      }
      clientLogs {
        id
      }
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
    }
  }
`;

/**
 * __useGetTimeEntryByIdQuery__
 *
 * To run a query within a React component, call `useGetTimeEntryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeEntryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeEntryByIdQuery({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *   },
 * });
 */
export function useGetTimeEntryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables> &
    ({ variables: GetTimeEntryByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables>(GetTimeEntryByIdDocument, options);
}
export function useGetTimeEntryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables>(GetTimeEntryByIdDocument, options);
}
export function useGetTimeEntryByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables>(
    GetTimeEntryByIdDocument,
    options
  );
}
export type GetTimeEntryByIdQueryHookResult = ReturnType<typeof useGetTimeEntryByIdQuery>;
export type GetTimeEntryByIdLazyQueryHookResult = ReturnType<typeof useGetTimeEntryByIdLazyQuery>;
export type GetTimeEntryByIdSuspenseQueryHookResult = ReturnType<typeof useGetTimeEntryByIdSuspenseQuery>;
export type GetTimeEntryByIdQueryResult = Apollo.QueryResult<GetTimeEntryByIdQuery, GetTimeEntryByIdQueryVariables>;
export const GetTimEntryWithLogsByIdDocument = gql`
  query GetTimEntryWithLogsById($timeEntryId: Uuid!) {
    timeEntries(filter: { id: { equal: $timeEntryId } }) {
      id
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        memberLock {
          id
          effectiveDate
        }
        position {
          id
          level
        }
      }
      startTime
      startDeviceTime
      endTime
      endDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      createdOn
      deletedOn
      submittedOn
      updatedOn
      startLocation {
        id
      }
      endLocation {
        id
      }
      breaks {
        id
        clientLogs {
          id
          startTime
          endTime
          actionType
          deviceType
          createdOn
          startLocationId
          endLocationId
          clientTimeEntryLogId
        }
        startTime
        startDeviceTime
        startTimeTrusted
        startTimeDst
        endTime
        endDeviceTime
        endTimeTrusted
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      clientLogs {
        id
        createdOn
        actionType
        deviceType
        startLocationId
        endLocationId
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        projectId
        costCodeId
        equipmentId
        description
        originalTimeEntryId
        updaterMember {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetTimEntryWithLogsByIdQuery__
 *
 * To run a query within a React component, call `useGetTimEntryWithLogsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimEntryWithLogsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimEntryWithLogsByIdQuery({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *   },
 * });
 */
export function useGetTimEntryWithLogsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTimEntryWithLogsByIdQuery, GetTimEntryWithLogsByIdQueryVariables> &
    ({ variables: GetTimEntryWithLogsByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimEntryWithLogsByIdQuery, GetTimEntryWithLogsByIdQueryVariables>(
    GetTimEntryWithLogsByIdDocument,
    options
  );
}
export function useGetTimEntryWithLogsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTimEntryWithLogsByIdQuery, GetTimEntryWithLogsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimEntryWithLogsByIdQuery, GetTimEntryWithLogsByIdQueryVariables>(
    GetTimEntryWithLogsByIdDocument,
    options
  );
}
export function useGetTimEntryWithLogsByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimEntryWithLogsByIdQuery, GetTimEntryWithLogsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTimEntryWithLogsByIdQuery, GetTimEntryWithLogsByIdQueryVariables>(
    GetTimEntryWithLogsByIdDocument,
    options
  );
}
export type GetTimEntryWithLogsByIdQueryHookResult = ReturnType<typeof useGetTimEntryWithLogsByIdQuery>;
export type GetTimEntryWithLogsByIdLazyQueryHookResult = ReturnType<typeof useGetTimEntryWithLogsByIdLazyQuery>;
export type GetTimEntryWithLogsByIdSuspenseQueryHookResult = ReturnType<typeof useGetTimEntryWithLogsByIdSuspenseQuery>;
export type GetTimEntryWithLogsByIdQueryResult = Apollo.QueryResult<
  GetTimEntryWithLogsByIdQuery,
  GetTimEntryWithLogsByIdQueryVariables
>;
export const TimeEntryWithMemberDocument = gql`
  query timeEntryWithMember($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      cursor
      startTime
      endTime
      member {
        id
        firstName
        lastName
        imageUrl
        archivedOn
        position {
          id
          level
        }
      }
    }
  }
`;

/**
 * __useTimeEntryWithMemberQuery__
 *
 * To run a query within a React component, call `useTimeEntryWithMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryWithMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryWithMemberQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimeEntryWithMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeEntryWithMemberQuery, TimeEntryWithMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryWithMemberQuery, TimeEntryWithMemberQueryVariables>(
    TimeEntryWithMemberDocument,
    options
  );
}
export function useTimeEntryWithMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryWithMemberQuery, TimeEntryWithMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryWithMemberQuery, TimeEntryWithMemberQueryVariables>(
    TimeEntryWithMemberDocument,
    options
  );
}
export function useTimeEntryWithMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryWithMemberQuery, TimeEntryWithMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryWithMemberQuery, TimeEntryWithMemberQueryVariables>(
    TimeEntryWithMemberDocument,
    options
  );
}
export type TimeEntryWithMemberQueryHookResult = ReturnType<typeof useTimeEntryWithMemberQuery>;
export type TimeEntryWithMemberLazyQueryHookResult = ReturnType<typeof useTimeEntryWithMemberLazyQuery>;
export type TimeEntryWithMemberSuspenseQueryHookResult = ReturnType<typeof useTimeEntryWithMemberSuspenseQuery>;
export type TimeEntryWithMemberQueryResult = Apollo.QueryResult<
  TimeEntryWithMemberQuery,
  TimeEntryWithMemberQueryVariables
>;
export const GetTimeEntriesWithLocationHistoryDocument = gql`
  query GetTimeEntriesWithLocationHistory(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      member {
        id
        firstName
        lastName
      }
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        projectInfo {
          id
          latitude
          locationRadius
          longitude
          customer
          number
        }
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      endTime
      startDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      startLocation {
        id
      }
      endLocation {
        id
      }
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
      cursor
    }
  }
`;

/**
 * __useGetTimeEntriesWithLocationHistoryQuery__
 *
 * To run a query within a React component, call `useGetTimeEntriesWithLocationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeEntriesWithLocationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeEntriesWithLocationHistoryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTimeEntriesWithLocationHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTimeEntriesWithLocationHistoryQuery,
    GetTimeEntriesWithLocationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeEntriesWithLocationHistoryQuery, GetTimeEntriesWithLocationHistoryQueryVariables>(
    GetTimeEntriesWithLocationHistoryDocument,
    options
  );
}
export function useGetTimeEntriesWithLocationHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeEntriesWithLocationHistoryQuery,
    GetTimeEntriesWithLocationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeEntriesWithLocationHistoryQuery, GetTimeEntriesWithLocationHistoryQueryVariables>(
    GetTimeEntriesWithLocationHistoryDocument,
    options
  );
}
export function useGetTimeEntriesWithLocationHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTimeEntriesWithLocationHistoryQuery,
    GetTimeEntriesWithLocationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTimeEntriesWithLocationHistoryQuery,
    GetTimeEntriesWithLocationHistoryQueryVariables
  >(GetTimeEntriesWithLocationHistoryDocument, options);
}
export type GetTimeEntriesWithLocationHistoryQueryHookResult = ReturnType<
  typeof useGetTimeEntriesWithLocationHistoryQuery
>;
export type GetTimeEntriesWithLocationHistoryLazyQueryHookResult = ReturnType<
  typeof useGetTimeEntriesWithLocationHistoryLazyQuery
>;
export type GetTimeEntriesWithLocationHistorySuspenseQueryHookResult = ReturnType<
  typeof useGetTimeEntriesWithLocationHistorySuspenseQuery
>;
export type GetTimeEntriesWithLocationHistoryQueryResult = Apollo.QueryResult<
  GetTimeEntriesWithLocationHistoryQuery,
  GetTimeEntriesWithLocationHistoryQueryVariables
>;
export const GetTimeEntryWithLocationHistoryDocument = gql`
  query GetTimeEntryWithLocationHistory($timeEntryId: Uuid!) {
    timeEntries(filter: { id: { equal: $timeEntryId } }) {
      id
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            latitude
            longitude
            locationRadius
          }
        }
        projectInfo {
          id
          latitude
          locationRadius
          longitude
        }
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      startDeviceTime
      endTime
      endDeviceTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      breaks {
        id
        timeEntry {
          id
          startTime
          daylightSavingTime
          endTime
          metaDaylightSavingTime
        }
        startTime
        startDeviceTime
        startTimeTrusted
        startTimeDst
        endTime
        endDeviceTime
        endTimeTrusted
        endTimeDst
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
      }
      startLocation {
        id
      }
      endLocation {
        id
      }
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
    }
  }
`;

/**
 * __useGetTimeEntryWithLocationHistoryQuery__
 *
 * To run a query within a React component, call `useGetTimeEntryWithLocationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeEntryWithLocationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeEntryWithLocationHistoryQuery({
 *   variables: {
 *      timeEntryId: // value for 'timeEntryId'
 *   },
 * });
 */
export function useGetTimeEntryWithLocationHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTimeEntryWithLocationHistoryQuery,
    GetTimeEntryWithLocationHistoryQueryVariables
  > &
    ({ variables: GetTimeEntryWithLocationHistoryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeEntryWithLocationHistoryQuery, GetTimeEntryWithLocationHistoryQueryVariables>(
    GetTimeEntryWithLocationHistoryDocument,
    options
  );
}
export function useGetTimeEntryWithLocationHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimeEntryWithLocationHistoryQuery,
    GetTimeEntryWithLocationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeEntryWithLocationHistoryQuery, GetTimeEntryWithLocationHistoryQueryVariables>(
    GetTimeEntryWithLocationHistoryDocument,
    options
  );
}
export function useGetTimeEntryWithLocationHistorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTimeEntryWithLocationHistoryQuery,
    GetTimeEntryWithLocationHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTimeEntryWithLocationHistoryQuery, GetTimeEntryWithLocationHistoryQueryVariables>(
    GetTimeEntryWithLocationHistoryDocument,
    options
  );
}
export type GetTimeEntryWithLocationHistoryQueryHookResult = ReturnType<typeof useGetTimeEntryWithLocationHistoryQuery>;
export type GetTimeEntryWithLocationHistoryLazyQueryHookResult = ReturnType<
  typeof useGetTimeEntryWithLocationHistoryLazyQuery
>;
export type GetTimeEntryWithLocationHistorySuspenseQueryHookResult = ReturnType<
  typeof useGetTimeEntryWithLocationHistorySuspenseQuery
>;
export type GetTimeEntryWithLocationHistoryQueryResult = Apollo.QueryResult<
  GetTimeEntryWithLocationHistoryQuery,
  GetTimeEntryWithLocationHistoryQueryVariables
>;
export const GetTimeEntriesIdsDocument = gql`
  query GetTimeEntriesIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      cursor
    }
  }
`;

/**
 * __useGetTimeEntriesIdsQuery__
 *
 * To run a query within a React component, call `useGetTimeEntriesIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeEntriesIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeEntriesIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTimeEntriesIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>(GetTimeEntriesIdsDocument, options);
}
export function useGetTimeEntriesIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>(
    GetTimeEntriesIdsDocument,
    options
  );
}
export function useGetTimeEntriesIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>(
    GetTimeEntriesIdsDocument,
    options
  );
}
export type GetTimeEntriesIdsQueryHookResult = ReturnType<typeof useGetTimeEntriesIdsQuery>;
export type GetTimeEntriesIdsLazyQueryHookResult = ReturnType<typeof useGetTimeEntriesIdsLazyQuery>;
export type GetTimeEntriesIdsSuspenseQueryHookResult = ReturnType<typeof useGetTimeEntriesIdsSuspenseQuery>;
export type GetTimeEntriesIdsQueryResult = Apollo.QueryResult<GetTimeEntriesIdsQuery, GetTimeEntriesIdsQueryVariables>;
export const GetTimeEntriesDocument = gql`
  query GetTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      member {
        id
        firstName
        lastName
      }
      cursor
      ...TimeEntryDetails
    }
  }
  ${TimeEntryDetailsFragmentDoc}
`;

/**
 * __useGetTimeEntriesQuery__
 *
 * To run a query within a React component, call `useGetTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>(GetTimeEntriesDocument, options);
}
export function useGetTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>(GetTimeEntriesDocument, options);
}
export function useGetTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>(GetTimeEntriesDocument, options);
}
export type GetTimeEntriesQueryHookResult = ReturnType<typeof useGetTimeEntriesQuery>;
export type GetTimeEntriesLazyQueryHookResult = ReturnType<typeof useGetTimeEntriesLazyQuery>;
export type GetTimeEntriesSuspenseQueryHookResult = ReturnType<typeof useGetTimeEntriesSuspenseQuery>;
export type GetTimeEntriesQueryResult = Apollo.QueryResult<GetTimeEntriesQuery, GetTimeEntriesQueryVariables>;
export const GetTimeEntriesWithGpsDocument = gql`
  query GetTimeEntriesWithGps($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      member {
        id
        firstName
        lastName
      }
      cursor
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
      ...TimeEntryDetails
    }
  }
  ${TimeEntryDetailsFragmentDoc}
`;

/**
 * __useGetTimeEntriesWithGpsQuery__
 *
 * To run a query within a React component, call `useGetTimeEntriesWithGpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeEntriesWithGpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeEntriesWithGpsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTimeEntriesWithGpsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTimeEntriesWithGpsQuery, GetTimeEntriesWithGpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimeEntriesWithGpsQuery, GetTimeEntriesWithGpsQueryVariables>(
    GetTimeEntriesWithGpsDocument,
    options
  );
}
export function useGetTimeEntriesWithGpsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTimeEntriesWithGpsQuery, GetTimeEntriesWithGpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimeEntriesWithGpsQuery, GetTimeEntriesWithGpsQueryVariables>(
    GetTimeEntriesWithGpsDocument,
    options
  );
}
export function useGetTimeEntriesWithGpsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetTimeEntriesWithGpsQuery, GetTimeEntriesWithGpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTimeEntriesWithGpsQuery, GetTimeEntriesWithGpsQueryVariables>(
    GetTimeEntriesWithGpsDocument,
    options
  );
}
export type GetTimeEntriesWithGpsQueryHookResult = ReturnType<typeof useGetTimeEntriesWithGpsQuery>;
export type GetTimeEntriesWithGpsLazyQueryHookResult = ReturnType<typeof useGetTimeEntriesWithGpsLazyQuery>;
export type GetTimeEntriesWithGpsSuspenseQueryHookResult = ReturnType<typeof useGetTimeEntriesWithGpsSuspenseQuery>;
export type GetTimeEntriesWithGpsQueryResult = Apollo.QueryResult<
  GetTimeEntriesWithGpsQuery,
  GetTimeEntriesWithGpsQueryVariables
>;
export const QueryTimeEntriesDocument = gql`
  query QueryTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      __typename
      member {
        id
        firstName
        lastName
        memberNumber
        memberGroupId
        archivedOn
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
        memberLock {
          id
          effectiveDate
        }
      }
      cursor
      ...TimeEntryDetails
      memberGpsStatuses {
        id
        status
        localTime
      }
    }
  }
  ${TimeEntryDetailsFragmentDoc}
`;

/**
 * __useQueryTimeEntriesQuery__
 *
 * To run a query within a React component, call `useQueryTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>(QueryTimeEntriesDocument, options);
}
export function useQueryTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>(QueryTimeEntriesDocument, options);
}
export function useQueryTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>(
    QueryTimeEntriesDocument,
    options
  );
}
export type QueryTimeEntriesQueryHookResult = ReturnType<typeof useQueryTimeEntriesQuery>;
export type QueryTimeEntriesLazyQueryHookResult = ReturnType<typeof useQueryTimeEntriesLazyQuery>;
export type QueryTimeEntriesSuspenseQueryHookResult = ReturnType<typeof useQueryTimeEntriesSuspenseQuery>;
export type QueryTimeEntriesQueryResult = Apollo.QueryResult<QueryTimeEntriesQuery, QueryTimeEntriesQueryVariables>;
export const QueryMapTimeEntriesDocument = gql`
  query QueryMapTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      member {
        id
        firstName
        lastName
        imageUrl
      }
      costCode {
        id
        costCode
        title
      }
      equipment {
        id
        equipmentName
        imageUrl
        lastLocation {
          latitude
          longitude
          id
          utc
        }
        model {
          id
          title
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      startTime
      breaks {
        id
        startTime
        endTime
        deletedOn
      }
      startLocation {
        id
        latitude
        longitude
        timeLocal
      }
      cursor
      ...SubprojectDetails
    }
  }
  ${SubprojectDetailsFragmentDoc}
`;

/**
 * __useQueryMapTimeEntriesQuery__
 *
 * To run a query within a React component, call `useQueryMapTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMapTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMapTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMapTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMapTimeEntriesQuery, QueryMapTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMapTimeEntriesQuery, QueryMapTimeEntriesQueryVariables>(
    QueryMapTimeEntriesDocument,
    options
  );
}
export function useQueryMapTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMapTimeEntriesQuery, QueryMapTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMapTimeEntriesQuery, QueryMapTimeEntriesQueryVariables>(
    QueryMapTimeEntriesDocument,
    options
  );
}
export function useQueryMapTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMapTimeEntriesQuery, QueryMapTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMapTimeEntriesQuery, QueryMapTimeEntriesQueryVariables>(
    QueryMapTimeEntriesDocument,
    options
  );
}
export type QueryMapTimeEntriesQueryHookResult = ReturnType<typeof useQueryMapTimeEntriesQuery>;
export type QueryMapTimeEntriesLazyQueryHookResult = ReturnType<typeof useQueryMapTimeEntriesLazyQuery>;
export type QueryMapTimeEntriesSuspenseQueryHookResult = ReturnType<typeof useQueryMapTimeEntriesSuspenseQuery>;
export type QueryMapTimeEntriesQueryResult = Apollo.QueryResult<
  QueryMapTimeEntriesQuery,
  QueryMapTimeEntriesQueryVariables
>;
export const QueryTimeEntriesMemberIdsDocument = gql`
  query QueryTimeEntriesMemberIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      cursor
    }
  }
`;

/**
 * __useQueryTimeEntriesMemberIdsQuery__
 *
 * To run a query within a React component, call `useQueryTimeEntriesMemberIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeEntriesMemberIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeEntriesMemberIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeEntriesMemberIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeEntriesMemberIdsQuery, QueryTimeEntriesMemberIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeEntriesMemberIdsQuery, QueryTimeEntriesMemberIdsQueryVariables>(
    QueryTimeEntriesMemberIdsDocument,
    options
  );
}
export function useQueryTimeEntriesMemberIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryTimeEntriesMemberIdsQuery, QueryTimeEntriesMemberIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeEntriesMemberIdsQuery, QueryTimeEntriesMemberIdsQueryVariables>(
    QueryTimeEntriesMemberIdsDocument,
    options
  );
}
export function useQueryTimeEntriesMemberIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryTimeEntriesMemberIdsQuery, QueryTimeEntriesMemberIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeEntriesMemberIdsQuery, QueryTimeEntriesMemberIdsQueryVariables>(
    QueryTimeEntriesMemberIdsDocument,
    options
  );
}
export type QueryTimeEntriesMemberIdsQueryHookResult = ReturnType<typeof useQueryTimeEntriesMemberIdsQuery>;
export type QueryTimeEntriesMemberIdsLazyQueryHookResult = ReturnType<typeof useQueryTimeEntriesMemberIdsLazyQuery>;
export type QueryTimeEntriesMemberIdsSuspenseQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesMemberIdsSuspenseQuery
>;
export type QueryTimeEntriesMemberIdsQueryResult = Apollo.QueryResult<
  QueryTimeEntriesMemberIdsQuery,
  QueryTimeEntriesMemberIdsQueryVariables
>;
export const QueryTimeEntriesCostCodeIdsDocument = gql`
  query QueryTimeEntriesCostCodeIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      costCodeId
      cursor
    }
  }
`;

/**
 * __useQueryTimeEntriesCostCodeIdsQuery__
 *
 * To run a query within a React component, call `useQueryTimeEntriesCostCodeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeEntriesCostCodeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeEntriesCostCodeIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeEntriesCostCodeIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeEntriesCostCodeIdsQuery, QueryTimeEntriesCostCodeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeEntriesCostCodeIdsQuery, QueryTimeEntriesCostCodeIdsQueryVariables>(
    QueryTimeEntriesCostCodeIdsDocument,
    options
  );
}
export function useQueryTimeEntriesCostCodeIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryTimeEntriesCostCodeIdsQuery, QueryTimeEntriesCostCodeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeEntriesCostCodeIdsQuery, QueryTimeEntriesCostCodeIdsQueryVariables>(
    QueryTimeEntriesCostCodeIdsDocument,
    options
  );
}
export function useQueryTimeEntriesCostCodeIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryTimeEntriesCostCodeIdsQuery,
    QueryTimeEntriesCostCodeIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeEntriesCostCodeIdsQuery, QueryTimeEntriesCostCodeIdsQueryVariables>(
    QueryTimeEntriesCostCodeIdsDocument,
    options
  );
}
export type QueryTimeEntriesCostCodeIdsQueryHookResult = ReturnType<typeof useQueryTimeEntriesCostCodeIdsQuery>;
export type QueryTimeEntriesCostCodeIdsLazyQueryHookResult = ReturnType<typeof useQueryTimeEntriesCostCodeIdsLazyQuery>;
export type QueryTimeEntriesCostCodeIdsSuspenseQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesCostCodeIdsSuspenseQuery
>;
export type QueryTimeEntriesCostCodeIdsQueryResult = Apollo.QueryResult<
  QueryTimeEntriesCostCodeIdsQuery,
  QueryTimeEntriesCostCodeIdsQueryVariables
>;
export const QueryTimeEntriesEquipmentIdsDocument = gql`
  query QueryTimeEntriesEquipmentIds($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      equipmentId
      cursor
    }
  }
`;

/**
 * __useQueryTimeEntriesEquipmentIdsQuery__
 *
 * To run a query within a React component, call `useQueryTimeEntriesEquipmentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeEntriesEquipmentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeEntriesEquipmentIdsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeEntriesEquipmentIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeEntriesEquipmentIdsQuery, QueryTimeEntriesEquipmentIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeEntriesEquipmentIdsQuery, QueryTimeEntriesEquipmentIdsQueryVariables>(
    QueryTimeEntriesEquipmentIdsDocument,
    options
  );
}
export function useQueryTimeEntriesEquipmentIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryTimeEntriesEquipmentIdsQuery,
    QueryTimeEntriesEquipmentIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeEntriesEquipmentIdsQuery, QueryTimeEntriesEquipmentIdsQueryVariables>(
    QueryTimeEntriesEquipmentIdsDocument,
    options
  );
}
export function useQueryTimeEntriesEquipmentIdsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryTimeEntriesEquipmentIdsQuery,
    QueryTimeEntriesEquipmentIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeEntriesEquipmentIdsQuery, QueryTimeEntriesEquipmentIdsQueryVariables>(
    QueryTimeEntriesEquipmentIdsDocument,
    options
  );
}
export type QueryTimeEntriesEquipmentIdsQueryHookResult = ReturnType<typeof useQueryTimeEntriesEquipmentIdsQuery>;
export type QueryTimeEntriesEquipmentIdsLazyQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesEquipmentIdsLazyQuery
>;
export type QueryTimeEntriesEquipmentIdsSuspenseQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesEquipmentIdsSuspenseQuery
>;
export type QueryTimeEntriesEquipmentIdsQueryResult = Apollo.QueryResult<
  QueryTimeEntriesEquipmentIdsQuery,
  QueryTimeEntriesEquipmentIdsQueryVariables
>;
export const QueryTimeEntriesWithMemberPositionAndGpsStatusDocument = gql`
  query QueryTimeEntriesWithMemberPositionAndGPSStatus(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      __typename
      member {
        id
        firstName
        lastName
        memberNumber
        memberGroupId
        archivedOn
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
        memberLock {
          id
          effectiveDate
        }
      }
      cursor
      flaggedTimeEntryLocation {
        id
        status
        locationData
      }
      memberGpsStatuses {
        id
        status
        localTime
      }
      ...TimeEntryDetails
    }
  }
  ${TimeEntryDetailsFragmentDoc}
`;

/**
 * __useQueryTimeEntriesWithMemberPositionAndGpsStatusQuery__
 *
 * To run a query within a React component, call `useQueryTimeEntriesWithMemberPositionAndGpsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeEntriesWithMemberPositionAndGpsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeEntriesWithMemberPositionAndGpsStatusQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeEntriesWithMemberPositionAndGpsStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
    QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
    QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
  >(QueryTimeEntriesWithMemberPositionAndGpsStatusDocument, options);
}
export function useQueryTimeEntriesWithMemberPositionAndGpsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
    QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
    QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
  >(QueryTimeEntriesWithMemberPositionAndGpsStatusDocument, options);
}
export function useQueryTimeEntriesWithMemberPositionAndGpsStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
    QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
    QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
  >(QueryTimeEntriesWithMemberPositionAndGpsStatusDocument, options);
}
export type QueryTimeEntriesWithMemberPositionAndGpsStatusQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesWithMemberPositionAndGpsStatusQuery
>;
export type QueryTimeEntriesWithMemberPositionAndGpsStatusLazyQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesWithMemberPositionAndGpsStatusLazyQuery
>;
export type QueryTimeEntriesWithMemberPositionAndGpsStatusSuspenseQueryHookResult = ReturnType<
  typeof useQueryTimeEntriesWithMemberPositionAndGpsStatusSuspenseQuery
>;
export type QueryTimeEntriesWithMemberPositionAndGpsStatusQueryResult = Apollo.QueryResult<
  QueryTimeEntriesWithMemberPositionAndGpsStatusQuery,
  QueryTimeEntriesWithMemberPositionAndGpsStatusQueryVariables
>;
export const QueryTimeOffDocument = gql`
  query QueryTimeOff($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      dateStamp
      type
      description
      paid
      seconds
      createdOnLocal
      createdOn
      updatedOn
      deletedOn
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        memberNumber
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
        memberLock {
          id
          effectiveDate
        }
      }
      submitterMember {
        id
        firstName
        lastName
      }
      cursor
    }
  }
`;

/**
 * __useQueryTimeOffQuery__
 *
 * To run a query within a React component, call `useQueryTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeOffQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeOffQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeOffQuery, QueryTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeOffQuery, QueryTimeOffQueryVariables>(QueryTimeOffDocument, options);
}
export function useQueryTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryTimeOffQuery, QueryTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeOffQuery, QueryTimeOffQueryVariables>(QueryTimeOffDocument, options);
}
export function useQueryTimeOffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryTimeOffQuery, QueryTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeOffQuery, QueryTimeOffQueryVariables>(QueryTimeOffDocument, options);
}
export type QueryTimeOffQueryHookResult = ReturnType<typeof useQueryTimeOffQuery>;
export type QueryTimeOffLazyQueryHookResult = ReturnType<typeof useQueryTimeOffLazyQuery>;
export type QueryTimeOffSuspenseQueryHookResult = ReturnType<typeof useQueryTimeOffSuspenseQuery>;
export type QueryTimeOffQueryResult = Apollo.QueryResult<QueryTimeOffQuery, QueryTimeOffQueryVariables>;
export const SingleTimeOffDocument = gql`
  query SingleTimeOff($timeOffId: Uuid!) {
    timeOffs(filter: { id: { equal: $timeOffId } }) {
      id
      dateStamp
      type
      description
      paid
      seconds
      createdOnLocal
      createdOn
      updatedOn
      deletedOn
      member {
        id
        firstName
        lastName
      }
      submitterMember {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useSingleTimeOffQuery__
 *
 * To run a query within a React component, call `useSingleTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleTimeOffQuery({
 *   variables: {
 *      timeOffId: // value for 'timeOffId'
 *   },
 * });
 */
export function useSingleTimeOffQuery(
  baseOptions: Apollo.QueryHookOptions<SingleTimeOffQuery, SingleTimeOffQueryVariables> &
    ({ variables: SingleTimeOffQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleTimeOffQuery, SingleTimeOffQueryVariables>(SingleTimeOffDocument, options);
}
export function useSingleTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SingleTimeOffQuery, SingleTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleTimeOffQuery, SingleTimeOffQueryVariables>(SingleTimeOffDocument, options);
}
export function useSingleTimeOffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SingleTimeOffQuery, SingleTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SingleTimeOffQuery, SingleTimeOffQueryVariables>(SingleTimeOffDocument, options);
}
export type SingleTimeOffQueryHookResult = ReturnType<typeof useSingleTimeOffQuery>;
export type SingleTimeOffLazyQueryHookResult = ReturnType<typeof useSingleTimeOffLazyQuery>;
export type SingleTimeOffSuspenseQueryHookResult = ReturnType<typeof useSingleTimeOffSuspenseQuery>;
export type SingleTimeOffQueryResult = Apollo.QueryResult<SingleTimeOffQuery, SingleTimeOffQueryVariables>;
export const FlatTimeOffDocument = gql`
  query FlatTimeOff($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      dateStamp
      type
      description
      paid
      seconds
      createdOnLocal
      createdOn
      updatedOn
      deletedOn
      memberId
      cursor
    }
  }
`;

/**
 * __useFlatTimeOffQuery__
 *
 * To run a query within a React component, call `useFlatTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlatTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlatTimeOffQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFlatTimeOffQuery(
  baseOptions?: Apollo.QueryHookOptions<FlatTimeOffQuery, FlatTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlatTimeOffQuery, FlatTimeOffQueryVariables>(FlatTimeOffDocument, options);
}
export function useFlatTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlatTimeOffQuery, FlatTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlatTimeOffQuery, FlatTimeOffQueryVariables>(FlatTimeOffDocument, options);
}
export function useFlatTimeOffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FlatTimeOffQuery, FlatTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FlatTimeOffQuery, FlatTimeOffQueryVariables>(FlatTimeOffDocument, options);
}
export type FlatTimeOffQueryHookResult = ReturnType<typeof useFlatTimeOffQuery>;
export type FlatTimeOffLazyQueryHookResult = ReturnType<typeof useFlatTimeOffLazyQuery>;
export type FlatTimeOffSuspenseQueryHookResult = ReturnType<typeof useFlatTimeOffSuspenseQuery>;
export type FlatTimeOffQueryResult = Apollo.QueryResult<FlatTimeOffQuery, FlatTimeOffQueryVariables>;
export const QueryTimeOffRequestsDocument = gql`
  query QueryTimeOffRequests($first: Int, $after: String, $filter: TimeOffRequestFilter, $sort: [TimeOffRequestSort!]) {
    timeOffRequests(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      answeredBy
      answeredByMember {
        id
        firstName
        lastName
      }
      answeredOn
      approved
      createdOnDst
      cursor
      deletedOn
      description
      paid
      dateStamp
      createdOn
      createdOnLocal
      answeredOn
      submittedOn
      updatedOn
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
        position {
          id
          level
        }
        memberGroupId
      }
      rejectionReason
      seconds
      submittedBy
      type
      submittedByMember {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
    }
  }
`;

/**
 * __useQueryTimeOffRequestsQuery__
 *
 * To run a query within a React component, call `useQueryTimeOffRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeOffRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeOffRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryTimeOffRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeOffRequestsQuery, QueryTimeOffRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeOffRequestsQuery, QueryTimeOffRequestsQueryVariables>(
    QueryTimeOffRequestsDocument,
    options
  );
}
export function useQueryTimeOffRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryTimeOffRequestsQuery, QueryTimeOffRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeOffRequestsQuery, QueryTimeOffRequestsQueryVariables>(
    QueryTimeOffRequestsDocument,
    options
  );
}
export function useQueryTimeOffRequestsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryTimeOffRequestsQuery, QueryTimeOffRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeOffRequestsQuery, QueryTimeOffRequestsQueryVariables>(
    QueryTimeOffRequestsDocument,
    options
  );
}
export type QueryTimeOffRequestsQueryHookResult = ReturnType<typeof useQueryTimeOffRequestsQuery>;
export type QueryTimeOffRequestsLazyQueryHookResult = ReturnType<typeof useQueryTimeOffRequestsLazyQuery>;
export type QueryTimeOffRequestsSuspenseQueryHookResult = ReturnType<typeof useQueryTimeOffRequestsSuspenseQuery>;
export type QueryTimeOffRequestsQueryResult = Apollo.QueryResult<
  QueryTimeOffRequestsQuery,
  QueryTimeOffRequestsQueryVariables
>;
export const WageHistoriesDocument = gql`
  query wageHistories($memberId: Uuid!) {
    wageHistories(
      filter: { memberId: { equal: $memberId }, deletedOn: { isNull: true } }
      sort: [{ changeDate: desc }, { updatedOn: desc }]
    ) {
      id
      memberId
      wage
      wageRate
      workWeek
      effectiveRate
      overburden
      changeDate
      createdOn
      submittedOn
      updatedOn
      deletedOn
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      cursor
    }
  }
`;

/**
 * __useWageHistoriesQuery__
 *
 * To run a query within a React component, call `useWageHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWageHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWageHistoriesQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useWageHistoriesQuery(
  baseOptions: Apollo.QueryHookOptions<WageHistoriesQuery, WageHistoriesQueryVariables> &
    ({ variables: WageHistoriesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WageHistoriesQuery, WageHistoriesQueryVariables>(WageHistoriesDocument, options);
}
export function useWageHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WageHistoriesQuery, WageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WageHistoriesQuery, WageHistoriesQueryVariables>(WageHistoriesDocument, options);
}
export function useWageHistoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<WageHistoriesQuery, WageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WageHistoriesQuery, WageHistoriesQueryVariables>(WageHistoriesDocument, options);
}
export type WageHistoriesQueryHookResult = ReturnType<typeof useWageHistoriesQuery>;
export type WageHistoriesLazyQueryHookResult = ReturnType<typeof useWageHistoriesLazyQuery>;
export type WageHistoriesSuspenseQueryHookResult = ReturnType<typeof useWageHistoriesSuspenseQuery>;
export type WageHistoriesQueryResult = Apollo.QueryResult<WageHistoriesQuery, WageHistoriesQueryVariables>;
export const QueryWageHistoriesDocument = gql`
  query queryWageHistories($filter: WageHistoryFilter, $sort: [WageHistorySort!]) {
    wageHistories(filter: $filter, sort: $sort) {
      id
      memberId
      wage
      wageRate
      workWeek
      effectiveRate
      overburden
      changeDate
      createdOn
      submittedOn
      updatedOn
      deletedOn
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
      cursor
    }
  }
`;

/**
 * __useQueryWageHistoriesQuery__
 *
 * To run a query within a React component, call `useQueryWageHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryWageHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryWageHistoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryWageHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryWageHistoriesQuery, QueryWageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryWageHistoriesQuery, QueryWageHistoriesQueryVariables>(
    QueryWageHistoriesDocument,
    options
  );
}
export function useQueryWageHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryWageHistoriesQuery, QueryWageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryWageHistoriesQuery, QueryWageHistoriesQueryVariables>(
    QueryWageHistoriesDocument,
    options
  );
}
export function useQueryWageHistoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryWageHistoriesQuery, QueryWageHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryWageHistoriesQuery, QueryWageHistoriesQueryVariables>(
    QueryWageHistoriesDocument,
    options
  );
}
export type QueryWageHistoriesQueryHookResult = ReturnType<typeof useQueryWageHistoriesQuery>;
export type QueryWageHistoriesLazyQueryHookResult = ReturnType<typeof useQueryWageHistoriesLazyQuery>;
export type QueryWageHistoriesSuspenseQueryHookResult = ReturnType<typeof useQueryWageHistoriesSuspenseQuery>;
export type QueryWageHistoriesQueryResult = Apollo.QueryResult<
  QueryWageHistoriesQuery,
  QueryWageHistoriesQueryVariables
>;
export const QueryWeatherDocument = gql`
  query QueryWeather($startTime: DateTime!, $endTime: DateTime!, $latitude: Float!, $longitude: Float!) {
    weather(startTime: $startTime, endTime: $endTime, latitude: $latitude, longitude: $longitude) {
      name
      metadata {
        attributionURL
        expireTime
        latitude
        longitude
        units
        version
      }
      hours {
        forecastStart
        cloudCover
        conditionCode
        daylight
        humidity
        precipitationAmount
        precipitationIntensity
        precipitationChance
        precipitationType
        pressure
        pressureTrend
        snowfallIntensity
        snowfallAmount
        temperature
        temperatureApparent
        temperatureDewPoint
        windDirection
        windGust
        windSpeed
      }
    }
  }
`;

/**
 * __useQueryWeatherQuery__
 *
 * To run a query within a React component, call `useQueryWeatherQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryWeatherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryWeatherQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useQueryWeatherQuery(
  baseOptions: Apollo.QueryHookOptions<QueryWeatherQuery, QueryWeatherQueryVariables> &
    ({ variables: QueryWeatherQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryWeatherQuery, QueryWeatherQueryVariables>(QueryWeatherDocument, options);
}
export function useQueryWeatherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryWeatherQuery, QueryWeatherQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryWeatherQuery, QueryWeatherQueryVariables>(QueryWeatherDocument, options);
}
export function useQueryWeatherSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryWeatherQuery, QueryWeatherQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryWeatherQuery, QueryWeatherQueryVariables>(QueryWeatherDocument, options);
}
export type QueryWeatherQueryHookResult = ReturnType<typeof useQueryWeatherQuery>;
export type QueryWeatherLazyQueryHookResult = ReturnType<typeof useQueryWeatherLazyQuery>;
export type QueryWeatherSuspenseQueryHookResult = ReturnType<typeof useQueryWeatherSuspenseQuery>;
export type QueryWeatherQueryResult = Apollo.QueryResult<QueryWeatherQuery, QueryWeatherQueryVariables>;
export const PoliciesDocument = gql`
  query Policies($first: Int, $after: String, $filter: PolicyFilter, $sort: [PolicySort!]) {
    policies(first: $first, after: $after, filter: $filter, sort: $sort) {
      __typename
      id
      version
      type
      contentUrl
      effectiveDate
      deletedOn
      createdOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;

/**
 * __usePoliciesQuery__
 *
 * To run a query within a React component, call `usePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoliciesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePoliciesQuery(baseOptions?: Apollo.QueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
}
export function usePoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
}
export function usePoliciesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PoliciesQuery, PoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
}
export type PoliciesQueryHookResult = ReturnType<typeof usePoliciesQuery>;
export type PoliciesLazyQueryHookResult = ReturnType<typeof usePoliciesLazyQuery>;
export type PoliciesSuspenseQueryHookResult = ReturnType<typeof usePoliciesSuspenseQuery>;
export type PoliciesQueryResult = Apollo.QueryResult<PoliciesQuery, PoliciesQueryVariables>;
export const PolicyMemberAcceptancesDocument = gql`
  query PolicyMemberAcceptances(
    $first: Int
    $after: String
    $filter: PolicyMemberAcceptanceFilter
    $sort: [PolicyMemberAcceptanceSort!]
  ) {
    policyMemberAcceptances(first: $first, after: $after, filter: $filter, sort: $sort) {
      __typename
      id
      policyId
      memberId
      proxyMemberId
      deviceType
      signatureId
      signatureUrl
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      policy {
        __typename
        id
        version
        type
        contentUrl
        effectiveDate
        deletedOn
        createdOn
        submittedOn
        updatedOn
        cursor
      }
    }
  }
`;

/**
 * __usePolicyMemberAcceptancesQuery__
 *
 * To run a query within a React component, call `usePolicyMemberAcceptancesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyMemberAcceptancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyMemberAcceptancesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePolicyMemberAcceptancesQuery(
  baseOptions?: Apollo.QueryHookOptions<PolicyMemberAcceptancesQuery, PolicyMemberAcceptancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PolicyMemberAcceptancesQuery, PolicyMemberAcceptancesQueryVariables>(
    PolicyMemberAcceptancesDocument,
    options
  );
}
export function usePolicyMemberAcceptancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PolicyMemberAcceptancesQuery, PolicyMemberAcceptancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PolicyMemberAcceptancesQuery, PolicyMemberAcceptancesQueryVariables>(
    PolicyMemberAcceptancesDocument,
    options
  );
}
export function usePolicyMemberAcceptancesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PolicyMemberAcceptancesQuery, PolicyMemberAcceptancesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PolicyMemberAcceptancesQuery, PolicyMemberAcceptancesQueryVariables>(
    PolicyMemberAcceptancesDocument,
    options
  );
}
export type PolicyMemberAcceptancesQueryHookResult = ReturnType<typeof usePolicyMemberAcceptancesQuery>;
export type PolicyMemberAcceptancesLazyQueryHookResult = ReturnType<typeof usePolicyMemberAcceptancesLazyQuery>;
export type PolicyMemberAcceptancesSuspenseQueryHookResult = ReturnType<typeof usePolicyMemberAcceptancesSuspenseQuery>;
export type PolicyMemberAcceptancesQueryResult = Apollo.QueryResult<
  PolicyMemberAcceptancesQuery,
  PolicyMemberAcceptancesQueryVariables
>;
export const CreatePolicyMemberAcceptanceDocument = gql`
  mutation CreatePolicyMemberAcceptance($policyMemberAcceptance: PolicyMemberAcceptanceCreateInput!) {
    createPolicyMemberAcceptance(policyMemberAcceptance: $policyMemberAcceptance) {
      memberId
      policyId
      createdOn
    }
  }
`;
export type CreatePolicyMemberAcceptanceMutationFn = Apollo.MutationFunction<
  CreatePolicyMemberAcceptanceMutation,
  CreatePolicyMemberAcceptanceMutationVariables
>;

/**
 * __useCreatePolicyMemberAcceptanceMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMemberAcceptanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMemberAcceptanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMemberAcceptanceMutation, { data, loading, error }] = useCreatePolicyMemberAcceptanceMutation({
 *   variables: {
 *      policyMemberAcceptance: // value for 'policyMemberAcceptance'
 *   },
 * });
 */
export function useCreatePolicyMemberAcceptanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyMemberAcceptanceMutation,
    CreatePolicyMemberAcceptanceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyMemberAcceptanceMutation, CreatePolicyMemberAcceptanceMutationVariables>(
    CreatePolicyMemberAcceptanceDocument,
    options
  );
}
export type CreatePolicyMemberAcceptanceMutationHookResult = ReturnType<typeof useCreatePolicyMemberAcceptanceMutation>;
export type CreatePolicyMemberAcceptanceMutationResult = Apollo.MutationResult<CreatePolicyMemberAcceptanceMutation>;
export type CreatePolicyMemberAcceptanceMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyMemberAcceptanceMutation,
  CreatePolicyMemberAcceptanceMutationVariables
>;
export const CostCodeGroupsDocument = gql`
  query CostCodeGroups($first: Int, $after: String, $filter: CostCodeGroupFilter) {
    costCodeGroups(first: $first, after: $after, filter: $filter, sort: [{ groupName: asc }]) {
      id
      groupName
      cursor
    }
  }
`;

/**
 * __useCostCodeGroupsQuery__
 *
 * To run a query within a React component, call `useCostCodeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodeGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostCodeGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>(CostCodeGroupsDocument, options);
}
export function useCostCodeGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>(CostCodeGroupsDocument, options);
}
export function useCostCodeGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>(CostCodeGroupsDocument, options);
}
export type CostCodeGroupsQueryHookResult = ReturnType<typeof useCostCodeGroupsQuery>;
export type CostCodeGroupsLazyQueryHookResult = ReturnType<typeof useCostCodeGroupsLazyQuery>;
export type CostCodeGroupsSuspenseQueryHookResult = ReturnType<typeof useCostCodeGroupsSuspenseQuery>;
export type CostCodeGroupsQueryResult = Apollo.QueryResult<CostCodeGroupsQuery, CostCodeGroupsQueryVariables>;
export const CostCodeGroupByIdDocument = gql`
  query CostCodeGroupById($groupId: Uuid!) {
    costCodeGroups(filter: { id: { equal: $groupId } }) {
      id
      groupName
      cursor
    }
  }
`;

/**
 * __useCostCodeGroupByIdQuery__
 *
 * To run a query within a React component, call `useCostCodeGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodeGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodeGroupByIdQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useCostCodeGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables> &
    ({ variables: CostCodeGroupByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables>(CostCodeGroupByIdDocument, options);
}
export function useCostCodeGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables>(
    CostCodeGroupByIdDocument,
    options
  );
}
export function useCostCodeGroupByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables>(
    CostCodeGroupByIdDocument,
    options
  );
}
export type CostCodeGroupByIdQueryHookResult = ReturnType<typeof useCostCodeGroupByIdQuery>;
export type CostCodeGroupByIdLazyQueryHookResult = ReturnType<typeof useCostCodeGroupByIdLazyQuery>;
export type CostCodeGroupByIdSuspenseQueryHookResult = ReturnType<typeof useCostCodeGroupByIdSuspenseQuery>;
export type CostCodeGroupByIdQueryResult = Apollo.QueryResult<CostCodeGroupByIdQuery, CostCodeGroupByIdQueryVariables>;
export const CostCodesPickerQueryDocument = gql`
  query CostCodesPickerQuery($first: Int, $after: String, $filter: CostCodeFilter) {
    costCodes(first: $first, after: $after, filter: $filter, sort: [{ costCode: asc }, { title: asc }]) {
      id
      cursor
      costCode
      title
    }
  }
`;

/**
 * __useCostCodesPickerQueryQuery__
 *
 * To run a query within a React component, call `useCostCodesPickerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodesPickerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodesPickerQueryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCostCodesPickerQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<CostCodesPickerQueryQuery, CostCodesPickerQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodesPickerQueryQuery, CostCodesPickerQueryQueryVariables>(
    CostCodesPickerQueryDocument,
    options
  );
}
export function useCostCodesPickerQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodesPickerQueryQuery, CostCodesPickerQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodesPickerQueryQuery, CostCodesPickerQueryQueryVariables>(
    CostCodesPickerQueryDocument,
    options
  );
}
export function useCostCodesPickerQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodesPickerQueryQuery, CostCodesPickerQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodesPickerQueryQuery, CostCodesPickerQueryQueryVariables>(
    CostCodesPickerQueryDocument,
    options
  );
}
export type CostCodesPickerQueryQueryHookResult = ReturnType<typeof useCostCodesPickerQueryQuery>;
export type CostCodesPickerQueryLazyQueryHookResult = ReturnType<typeof useCostCodesPickerQueryLazyQuery>;
export type CostCodesPickerQuerySuspenseQueryHookResult = ReturnType<typeof useCostCodesPickerQuerySuspenseQuery>;
export type CostCodesPickerQueryQueryResult = Apollo.QueryResult<
  CostCodesPickerQueryQuery,
  CostCodesPickerQueryQueryVariables
>;
export const SingleCostCodeQueryDocument = gql`
  query SingleCostCodeQuery($costCodeId: Uuid!) {
    costCodes(filter: { id: { equal: $costCodeId } }) {
      id
      costCode
      title
    }
  }
`;

/**
 * __useSingleCostCodeQueryQuery__
 *
 * To run a query within a React component, call `useSingleCostCodeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleCostCodeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleCostCodeQueryQuery({
 *   variables: {
 *      costCodeId: // value for 'costCodeId'
 *   },
 * });
 */
export function useSingleCostCodeQueryQuery(
  baseOptions: Apollo.QueryHookOptions<SingleCostCodeQueryQuery, SingleCostCodeQueryQueryVariables> &
    ({ variables: SingleCostCodeQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SingleCostCodeQueryQuery, SingleCostCodeQueryQueryVariables>(
    SingleCostCodeQueryDocument,
    options
  );
}
export function useSingleCostCodeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SingleCostCodeQueryQuery, SingleCostCodeQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SingleCostCodeQueryQuery, SingleCostCodeQueryQueryVariables>(
    SingleCostCodeQueryDocument,
    options
  );
}
export function useSingleCostCodeQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SingleCostCodeQueryQuery, SingleCostCodeQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SingleCostCodeQueryQuery, SingleCostCodeQueryQueryVariables>(
    SingleCostCodeQueryDocument,
    options
  );
}
export type SingleCostCodeQueryQueryHookResult = ReturnType<typeof useSingleCostCodeQueryQuery>;
export type SingleCostCodeQueryLazyQueryHookResult = ReturnType<typeof useSingleCostCodeQueryLazyQuery>;
export type SingleCostCodeQuerySuspenseQueryHookResult = ReturnType<typeof useSingleCostCodeQuerySuspenseQuery>;
export type SingleCostCodeQueryQueryResult = Apollo.QueryResult<
  SingleCostCodeQueryQuery,
  SingleCostCodeQueryQueryVariables
>;
export const EquipmentCategoryDocument = gql`
  query EquipmentCategory($first: Int, $after: String, $filter: EquipmentCategoryFilter) {
    equipmentCategories(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      equipmentTypeId
      review
      cursor
    }
  }
`;

/**
 * __useEquipmentCategoryQuery__
 *
 * To run a query within a React component, call `useEquipmentCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentCategoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEquipmentCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>(EquipmentCategoryDocument, options);
}
export function useEquipmentCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>(
    EquipmentCategoryDocument,
    options
  );
}
export function useEquipmentCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>(
    EquipmentCategoryDocument,
    options
  );
}
export type EquipmentCategoryQueryHookResult = ReturnType<typeof useEquipmentCategoryQuery>;
export type EquipmentCategoryLazyQueryHookResult = ReturnType<typeof useEquipmentCategoryLazyQuery>;
export type EquipmentCategorySuspenseQueryHookResult = ReturnType<typeof useEquipmentCategorySuspenseQuery>;
export type EquipmentCategoryQueryResult = Apollo.QueryResult<EquipmentCategoryQuery, EquipmentCategoryQueryVariables>;
export const EquipmentCategoryByIdDocument = gql`
  query EquipmentCategoryById($categoryId: Uuid!) {
    equipmentCategories(filter: { id: { equal: $categoryId } }) {
      id
      title
      equipmentTypeId
      review
      cursor
    }
  }
`;

/**
 * __useEquipmentCategoryByIdQuery__
 *
 * To run a query within a React component, call `useEquipmentCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentCategoryByIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useEquipmentCategoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentCategoryByIdQuery, EquipmentCategoryByIdQueryVariables> &
    ({ variables: EquipmentCategoryByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentCategoryByIdQuery, EquipmentCategoryByIdQueryVariables>(
    EquipmentCategoryByIdDocument,
    options
  );
}
export function useEquipmentCategoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentCategoryByIdQuery, EquipmentCategoryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentCategoryByIdQuery, EquipmentCategoryByIdQueryVariables>(
    EquipmentCategoryByIdDocument,
    options
  );
}
export function useEquipmentCategoryByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentCategoryByIdQuery, EquipmentCategoryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentCategoryByIdQuery, EquipmentCategoryByIdQueryVariables>(
    EquipmentCategoryByIdDocument,
    options
  );
}
export type EquipmentCategoryByIdQueryHookResult = ReturnType<typeof useEquipmentCategoryByIdQuery>;
export type EquipmentCategoryByIdLazyQueryHookResult = ReturnType<typeof useEquipmentCategoryByIdLazyQuery>;
export type EquipmentCategoryByIdSuspenseQueryHookResult = ReturnType<typeof useEquipmentCategoryByIdSuspenseQuery>;
export type EquipmentCategoryByIdQueryResult = Apollo.QueryResult<
  EquipmentCategoryByIdQuery,
  EquipmentCategoryByIdQueryVariables
>;
export const EquipmentCategoryWithTypeDocument = gql`
  query EquipmentCategoryWithType($first: Int, $after: String, $filter: EquipmentCategoryFilter) {
    equipmentCategories(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      equipmentTypeId
      type {
        id
        title
      }
      review
      cursor
    }
  }
`;

/**
 * __useEquipmentCategoryWithTypeQuery__
 *
 * To run a query within a React component, call `useEquipmentCategoryWithTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentCategoryWithTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentCategoryWithTypeQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEquipmentCategoryWithTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentCategoryWithTypeQuery, EquipmentCategoryWithTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentCategoryWithTypeQuery, EquipmentCategoryWithTypeQueryVariables>(
    EquipmentCategoryWithTypeDocument,
    options
  );
}
export function useEquipmentCategoryWithTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentCategoryWithTypeQuery, EquipmentCategoryWithTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentCategoryWithTypeQuery, EquipmentCategoryWithTypeQueryVariables>(
    EquipmentCategoryWithTypeDocument,
    options
  );
}
export function useEquipmentCategoryWithTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentCategoryWithTypeQuery, EquipmentCategoryWithTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentCategoryWithTypeQuery, EquipmentCategoryWithTypeQueryVariables>(
    EquipmentCategoryWithTypeDocument,
    options
  );
}
export type EquipmentCategoryWithTypeQueryHookResult = ReturnType<typeof useEquipmentCategoryWithTypeQuery>;
export type EquipmentCategoryWithTypeLazyQueryHookResult = ReturnType<typeof useEquipmentCategoryWithTypeLazyQuery>;
export type EquipmentCategoryWithTypeSuspenseQueryHookResult = ReturnType<
  typeof useEquipmentCategoryWithTypeSuspenseQuery
>;
export type EquipmentCategoryWithTypeQueryResult = Apollo.QueryResult<
  EquipmentCategoryWithTypeQuery,
  EquipmentCategoryWithTypeQueryVariables
>;
export const EquipmentMakeDocument = gql`
  query EquipmentMake($first: Int, $after: String, $filter: EquipmentMakeFilter) {
    equipmentMakes(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      review
      unknown
      cursor
    }
  }
`;

/**
 * __useEquipmentMakeQuery__
 *
 * To run a query within a React component, call `useEquipmentMakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentMakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentMakeQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEquipmentMakeQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentMakeQuery, EquipmentMakeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentMakeQuery, EquipmentMakeQueryVariables>(EquipmentMakeDocument, options);
}
export function useEquipmentMakeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentMakeQuery, EquipmentMakeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentMakeQuery, EquipmentMakeQueryVariables>(EquipmentMakeDocument, options);
}
export function useEquipmentMakeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentMakeQuery, EquipmentMakeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentMakeQuery, EquipmentMakeQueryVariables>(EquipmentMakeDocument, options);
}
export type EquipmentMakeQueryHookResult = ReturnType<typeof useEquipmentMakeQuery>;
export type EquipmentMakeLazyQueryHookResult = ReturnType<typeof useEquipmentMakeLazyQuery>;
export type EquipmentMakeSuspenseQueryHookResult = ReturnType<typeof useEquipmentMakeSuspenseQuery>;
export type EquipmentMakeQueryResult = Apollo.QueryResult<EquipmentMakeQuery, EquipmentMakeQueryVariables>;
export const EquipmentMakeByIdDocument = gql`
  query EquipmentMakeById($makeId: Uuid!) {
    equipmentMakes(filter: { id: { equal: $makeId } }) {
      id
      title
      review
      unknown
      cursor
    }
  }
`;

/**
 * __useEquipmentMakeByIdQuery__
 *
 * To run a query within a React component, call `useEquipmentMakeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentMakeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentMakeByIdQuery({
 *   variables: {
 *      makeId: // value for 'makeId'
 *   },
 * });
 */
export function useEquipmentMakeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables> &
    ({ variables: EquipmentMakeByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables>(EquipmentMakeByIdDocument, options);
}
export function useEquipmentMakeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables>(
    EquipmentMakeByIdDocument,
    options
  );
}
export function useEquipmentMakeByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables>(
    EquipmentMakeByIdDocument,
    options
  );
}
export type EquipmentMakeByIdQueryHookResult = ReturnType<typeof useEquipmentMakeByIdQuery>;
export type EquipmentMakeByIdLazyQueryHookResult = ReturnType<typeof useEquipmentMakeByIdLazyQuery>;
export type EquipmentMakeByIdSuspenseQueryHookResult = ReturnType<typeof useEquipmentMakeByIdSuspenseQuery>;
export type EquipmentMakeByIdQueryResult = Apollo.QueryResult<EquipmentMakeByIdQuery, EquipmentMakeByIdQueryVariables>;
export const EquipmentModelsDocument = gql`
  query EquipmentModels($first: Int, $after: String, $filter: EquipmentModelFilter) {
    equipmentModels(first: $first, after: $after, filter: $filter, sort: [{ title: asc }, { year: desc }]) {
      id
      title
      year
      unknown
      cursor
    }
  }
`;

/**
 * __useEquipmentModelsQuery__
 *
 * To run a query within a React component, call `useEquipmentModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentModelsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEquipmentModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentModelsQuery, EquipmentModelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentModelsQuery, EquipmentModelsQueryVariables>(EquipmentModelsDocument, options);
}
export function useEquipmentModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentModelsQuery, EquipmentModelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentModelsQuery, EquipmentModelsQueryVariables>(EquipmentModelsDocument, options);
}
export function useEquipmentModelsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentModelsQuery, EquipmentModelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentModelsQuery, EquipmentModelsQueryVariables>(EquipmentModelsDocument, options);
}
export type EquipmentModelsQueryHookResult = ReturnType<typeof useEquipmentModelsQuery>;
export type EquipmentModelsLazyQueryHookResult = ReturnType<typeof useEquipmentModelsLazyQuery>;
export type EquipmentModelsSuspenseQueryHookResult = ReturnType<typeof useEquipmentModelsSuspenseQuery>;
export type EquipmentModelsQueryResult = Apollo.QueryResult<EquipmentModelsQuery, EquipmentModelsQueryVariables>;
export const EquipmentModelByIdDocument = gql`
  query EquipmentModelById($modelId: Uuid!) {
    equipmentModels(filter: { id: { equal: $modelId } }) {
      id
      title
      year
      unknown
      cursor
    }
  }
`;

/**
 * __useEquipmentModelByIdQuery__
 *
 * To run a query within a React component, call `useEquipmentModelByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentModelByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentModelByIdQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useEquipmentModelByIdQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentModelByIdQuery, EquipmentModelByIdQueryVariables> &
    ({ variables: EquipmentModelByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentModelByIdQuery, EquipmentModelByIdQueryVariables>(
    EquipmentModelByIdDocument,
    options
  );
}
export function useEquipmentModelByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentModelByIdQuery, EquipmentModelByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentModelByIdQuery, EquipmentModelByIdQueryVariables>(
    EquipmentModelByIdDocument,
    options
  );
}
export function useEquipmentModelByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentModelByIdQuery, EquipmentModelByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentModelByIdQuery, EquipmentModelByIdQueryVariables>(
    EquipmentModelByIdDocument,
    options
  );
}
export type EquipmentModelByIdQueryHookResult = ReturnType<typeof useEquipmentModelByIdQuery>;
export type EquipmentModelByIdLazyQueryHookResult = ReturnType<typeof useEquipmentModelByIdLazyQuery>;
export type EquipmentModelByIdSuspenseQueryHookResult = ReturnType<typeof useEquipmentModelByIdSuspenseQuery>;
export type EquipmentModelByIdQueryResult = Apollo.QueryResult<
  EquipmentModelByIdQuery,
  EquipmentModelByIdQueryVariables
>;
export const EquipmentModelByCategoryIdDocument = gql`
  query EquipmentModelByCategoryId($categoryId: Uuid!) {
    equipmentModels(filter: { equipmentCategoryId: { equal: $categoryId }, unknown: { equal: true } }) {
      id
      title
      year
      unknown
      cursor
    }
  }
`;

/**
 * __useEquipmentModelByCategoryIdQuery__
 *
 * To run a query within a React component, call `useEquipmentModelByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentModelByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentModelByCategoryIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useEquipmentModelByCategoryIdQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentModelByCategoryIdQuery, EquipmentModelByCategoryIdQueryVariables> &
    ({ variables: EquipmentModelByCategoryIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentModelByCategoryIdQuery, EquipmentModelByCategoryIdQueryVariables>(
    EquipmentModelByCategoryIdDocument,
    options
  );
}
export function useEquipmentModelByCategoryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentModelByCategoryIdQuery, EquipmentModelByCategoryIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentModelByCategoryIdQuery, EquipmentModelByCategoryIdQueryVariables>(
    EquipmentModelByCategoryIdDocument,
    options
  );
}
export function useEquipmentModelByCategoryIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EquipmentModelByCategoryIdQuery,
    EquipmentModelByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentModelByCategoryIdQuery, EquipmentModelByCategoryIdQueryVariables>(
    EquipmentModelByCategoryIdDocument,
    options
  );
}
export type EquipmentModelByCategoryIdQueryHookResult = ReturnType<typeof useEquipmentModelByCategoryIdQuery>;
export type EquipmentModelByCategoryIdLazyQueryHookResult = ReturnType<typeof useEquipmentModelByCategoryIdLazyQuery>;
export type EquipmentModelByCategoryIdSuspenseQueryHookResult = ReturnType<
  typeof useEquipmentModelByCategoryIdSuspenseQuery
>;
export type EquipmentModelByCategoryIdQueryResult = Apollo.QueryResult<
  EquipmentModelByCategoryIdQuery,
  EquipmentModelByCategoryIdQueryVariables
>;
export const EquipmentModelByCategoryMakeIdDocument = gql`
  query EquipmentModelByCategoryMakeId($categoryId: Uuid!, $makeId: Uuid!) {
    equipmentModelTitles(equipmentCategoryId: $categoryId, equipmentMakeId: $makeId)
  }
`;

/**
 * __useEquipmentModelByCategoryMakeIdQuery__
 *
 * To run a query within a React component, call `useEquipmentModelByCategoryMakeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentModelByCategoryMakeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentModelByCategoryMakeIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      makeId: // value for 'makeId'
 *   },
 * });
 */
export function useEquipmentModelByCategoryMakeIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    EquipmentModelByCategoryMakeIdQuery,
    EquipmentModelByCategoryMakeIdQueryVariables
  > &
    ({ variables: EquipmentModelByCategoryMakeIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentModelByCategoryMakeIdQuery, EquipmentModelByCategoryMakeIdQueryVariables>(
    EquipmentModelByCategoryMakeIdDocument,
    options
  );
}
export function useEquipmentModelByCategoryMakeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EquipmentModelByCategoryMakeIdQuery,
    EquipmentModelByCategoryMakeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentModelByCategoryMakeIdQuery, EquipmentModelByCategoryMakeIdQueryVariables>(
    EquipmentModelByCategoryMakeIdDocument,
    options
  );
}
export function useEquipmentModelByCategoryMakeIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EquipmentModelByCategoryMakeIdQuery,
    EquipmentModelByCategoryMakeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentModelByCategoryMakeIdQuery, EquipmentModelByCategoryMakeIdQueryVariables>(
    EquipmentModelByCategoryMakeIdDocument,
    options
  );
}
export type EquipmentModelByCategoryMakeIdQueryHookResult = ReturnType<typeof useEquipmentModelByCategoryMakeIdQuery>;
export type EquipmentModelByCategoryMakeIdLazyQueryHookResult = ReturnType<
  typeof useEquipmentModelByCategoryMakeIdLazyQuery
>;
export type EquipmentModelByCategoryMakeIdSuspenseQueryHookResult = ReturnType<
  typeof useEquipmentModelByCategoryMakeIdSuspenseQuery
>;
export type EquipmentModelByCategoryMakeIdQueryResult = Apollo.QueryResult<
  EquipmentModelByCategoryMakeIdQuery,
  EquipmentModelByCategoryMakeIdQueryVariables
>;
export const EquipmentTypesDocument = gql`
  query EquipmentTypes($first: Int, $after: String, $filter: EquipmentTypeFilter) {
    equipmentTypes(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      color
      display
      review
      cursor
    }
  }
`;

/**
 * __useEquipmentTypesQuery__
 *
 * To run a query within a React component, call `useEquipmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentTypesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEquipmentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentTypesQuery, EquipmentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentTypesQuery, EquipmentTypesQueryVariables>(EquipmentTypesDocument, options);
}
export function useEquipmentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentTypesQuery, EquipmentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentTypesQuery, EquipmentTypesQueryVariables>(EquipmentTypesDocument, options);
}
export function useEquipmentTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentTypesQuery, EquipmentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentTypesQuery, EquipmentTypesQueryVariables>(EquipmentTypesDocument, options);
}
export type EquipmentTypesQueryHookResult = ReturnType<typeof useEquipmentTypesQuery>;
export type EquipmentTypesLazyQueryHookResult = ReturnType<typeof useEquipmentTypesLazyQuery>;
export type EquipmentTypesSuspenseQueryHookResult = ReturnType<typeof useEquipmentTypesSuspenseQuery>;
export type EquipmentTypesQueryResult = Apollo.QueryResult<EquipmentTypesQuery, EquipmentTypesQueryVariables>;
export const EquipmentTypeDocument = gql`
  query EquipmentType($typeId: Uuid!) {
    equipmentTypes(filter: { id: { equal: $typeId } }) {
      id
      title
      color
      display
      review
      cursor
    }
  }
`;

/**
 * __useEquipmentTypeQuery__
 *
 * To run a query within a React component, call `useEquipmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentTypeQuery({
 *   variables: {
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useEquipmentTypeQuery(
  baseOptions: Apollo.QueryHookOptions<EquipmentTypeQuery, EquipmentTypeQueryVariables> &
    ({ variables: EquipmentTypeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentTypeQuery, EquipmentTypeQueryVariables>(EquipmentTypeDocument, options);
}
export function useEquipmentTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentTypeQuery, EquipmentTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentTypeQuery, EquipmentTypeQueryVariables>(EquipmentTypeDocument, options);
}
export function useEquipmentTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentTypeQuery, EquipmentTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentTypeQuery, EquipmentTypeQueryVariables>(EquipmentTypeDocument, options);
}
export type EquipmentTypeQueryHookResult = ReturnType<typeof useEquipmentTypeQuery>;
export type EquipmentTypeLazyQueryHookResult = ReturnType<typeof useEquipmentTypeLazyQuery>;
export type EquipmentTypeSuspenseQueryHookResult = ReturnType<typeof useEquipmentTypeSuspenseQuery>;
export type EquipmentTypeQueryResult = Apollo.QueryResult<EquipmentTypeQuery, EquipmentTypeQueryVariables>;
export const QueryGustoBusyPayrollMemberDocument = gql`
  query queryGustoBusyPayrollMember($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      firstName
      lastName
      gustoEmployeeId
      positionId
      memberGroupId
      cursor
    }
  }
`;

/**
 * __useQueryGustoBusyPayrollMemberQuery__
 *
 * To run a query within a React component, call `useQueryGustoBusyPayrollMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGustoBusyPayrollMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGustoBusyPayrollMemberQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryGustoBusyPayrollMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryGustoBusyPayrollMemberQuery, QueryGustoBusyPayrollMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryGustoBusyPayrollMemberQuery, QueryGustoBusyPayrollMemberQueryVariables>(
    QueryGustoBusyPayrollMemberDocument,
    options
  );
}
export function useQueryGustoBusyPayrollMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryGustoBusyPayrollMemberQuery, QueryGustoBusyPayrollMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryGustoBusyPayrollMemberQuery, QueryGustoBusyPayrollMemberQueryVariables>(
    QueryGustoBusyPayrollMemberDocument,
    options
  );
}
export function useQueryGustoBusyPayrollMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryGustoBusyPayrollMemberQuery,
    QueryGustoBusyPayrollMemberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryGustoBusyPayrollMemberQuery, QueryGustoBusyPayrollMemberQueryVariables>(
    QueryGustoBusyPayrollMemberDocument,
    options
  );
}
export type QueryGustoBusyPayrollMemberQueryHookResult = ReturnType<typeof useQueryGustoBusyPayrollMemberQuery>;
export type QueryGustoBusyPayrollMemberLazyQueryHookResult = ReturnType<typeof useQueryGustoBusyPayrollMemberLazyQuery>;
export type QueryGustoBusyPayrollMemberSuspenseQueryHookResult = ReturnType<
  typeof useQueryGustoBusyPayrollMemberSuspenseQuery
>;
export type QueryGustoBusyPayrollMemberQueryResult = Apollo.QueryResult<
  QueryGustoBusyPayrollMemberQuery,
  QueryGustoBusyPayrollMemberQueryVariables
>;
export const MemberGroupsDocument = gql`
  query MemberGroups($first: Int, $after: String, $filter: MemberGroupFilter) {
    memberGroups(first: $first, after: $after, filter: $filter, sort: [{ groupName: asc }]) {
      id
      groupName
      cursor
    }
  }
`;

/**
 * __useMemberGroupsQuery__
 *
 * To run a query within a React component, call `useMemberGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberGroupsQuery, MemberGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberGroupsQuery, MemberGroupsQueryVariables>(MemberGroupsDocument, options);
}
export function useMemberGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberGroupsQuery, MemberGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberGroupsQuery, MemberGroupsQueryVariables>(MemberGroupsDocument, options);
}
export function useMemberGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberGroupsQuery, MemberGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberGroupsQuery, MemberGroupsQueryVariables>(MemberGroupsDocument, options);
}
export type MemberGroupsQueryHookResult = ReturnType<typeof useMemberGroupsQuery>;
export type MemberGroupsLazyQueryHookResult = ReturnType<typeof useMemberGroupsLazyQuery>;
export type MemberGroupsSuspenseQueryHookResult = ReturnType<typeof useMemberGroupsSuspenseQuery>;
export type MemberGroupsQueryResult = Apollo.QueryResult<MemberGroupsQuery, MemberGroupsQueryVariables>;
export const MemberGroupByIdDocument = gql`
  query MemberGroupById($groupId: Uuid!) {
    memberGroups(filter: { id: { equal: $groupId } }) {
      id
      groupName
      cursor
    }
  }
`;

/**
 * __useMemberGroupByIdQuery__
 *
 * To run a query within a React component, call `useMemberGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberGroupByIdQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useMemberGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<MemberGroupByIdQuery, MemberGroupByIdQueryVariables> &
    ({ variables: MemberGroupByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberGroupByIdQuery, MemberGroupByIdQueryVariables>(MemberGroupByIdDocument, options);
}
export function useMemberGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberGroupByIdQuery, MemberGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberGroupByIdQuery, MemberGroupByIdQueryVariables>(MemberGroupByIdDocument, options);
}
export function useMemberGroupByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberGroupByIdQuery, MemberGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberGroupByIdQuery, MemberGroupByIdQueryVariables>(MemberGroupByIdDocument, options);
}
export type MemberGroupByIdQueryHookResult = ReturnType<typeof useMemberGroupByIdQuery>;
export type MemberGroupByIdLazyQueryHookResult = ReturnType<typeof useMemberGroupByIdLazyQuery>;
export type MemberGroupByIdSuspenseQueryHookResult = ReturnType<typeof useMemberGroupByIdSuspenseQuery>;
export type MemberGroupByIdQueryResult = Apollo.QueryResult<MemberGroupByIdQuery, MemberGroupByIdQueryVariables>;
export const MembersCsvImportDocument = gql`
  query MembersCsvImport($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      username
      imageUrl
      email
      memberNumber
      phone
      timeLocationRequired
      memberGroup {
        id
        groupName
      }
      position {
        id
        level
        title
      }
      wageHistories {
        id
        wage
        wageRate
        effectiveRate
        overburden
        changeDate
        deletedOn
      }
    }
  }
`;

/**
 * __useMembersCsvImportQuery__
 *
 * To run a query within a React component, call `useMembersCsvImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersCsvImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersCsvImportQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMembersCsvImportQuery(
  baseOptions?: Apollo.QueryHookOptions<MembersCsvImportQuery, MembersCsvImportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MembersCsvImportQuery, MembersCsvImportQueryVariables>(MembersCsvImportDocument, options);
}
export function useMembersCsvImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MembersCsvImportQuery, MembersCsvImportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MembersCsvImportQuery, MembersCsvImportQueryVariables>(MembersCsvImportDocument, options);
}
export function useMembersCsvImportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MembersCsvImportQuery, MembersCsvImportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MembersCsvImportQuery, MembersCsvImportQueryVariables>(
    MembersCsvImportDocument,
    options
  );
}
export type MembersCsvImportQueryHookResult = ReturnType<typeof useMembersCsvImportQuery>;
export type MembersCsvImportLazyQueryHookResult = ReturnType<typeof useMembersCsvImportLazyQuery>;
export type MembersCsvImportSuspenseQueryHookResult = ReturnType<typeof useMembersCsvImportSuspenseQuery>;
export type MembersCsvImportQueryResult = Apollo.QueryResult<MembersCsvImportQuery, MembersCsvImportQueryVariables>;
export const QueryMemberPickDocument = gql`
  query QueryMemberPick($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
    }
  }
`;

/**
 * __useQueryMemberPickQuery__
 *
 * To run a query within a React component, call `useQueryMemberPickQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberPickQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberPickQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMemberPickQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberPickQuery, QueryMemberPickQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberPickQuery, QueryMemberPickQueryVariables>(QueryMemberPickDocument, options);
}
export function useQueryMemberPickLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberPickQuery, QueryMemberPickQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberPickQuery, QueryMemberPickQueryVariables>(QueryMemberPickDocument, options);
}
export function useQueryMemberPickSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberPickQuery, QueryMemberPickQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberPickQuery, QueryMemberPickQueryVariables>(QueryMemberPickDocument, options);
}
export type QueryMemberPickQueryHookResult = ReturnType<typeof useQueryMemberPickQuery>;
export type QueryMemberPickLazyQueryHookResult = ReturnType<typeof useQueryMemberPickLazyQuery>;
export type QueryMemberPickSuspenseQueryHookResult = ReturnType<typeof useQueryMemberPickSuspenseQuery>;
export type QueryMemberPickQueryResult = Apollo.QueryResult<QueryMemberPickQuery, QueryMemberPickQueryVariables>;
export const QueryMemberByIdDocument = gql`
  query queryMemberById($memberId: Uuid) {
    members(filter: { id: { equal: $memberId } }) {
      id
      firstName
      lastName
      archivedOn
    }
  }
`;

/**
 * __useQueryMemberByIdQuery__
 *
 * To run a query within a React component, call `useQueryMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberByIdQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useQueryMemberByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>(QueryMemberByIdDocument, options);
}
export function useQueryMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>(QueryMemberByIdDocument, options);
}
export function useQueryMemberByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>(QueryMemberByIdDocument, options);
}
export type QueryMemberByIdQueryHookResult = ReturnType<typeof useQueryMemberByIdQuery>;
export type QueryMemberByIdLazyQueryHookResult = ReturnType<typeof useQueryMemberByIdLazyQuery>;
export type QueryMemberByIdSuspenseQueryHookResult = ReturnType<typeof useQueryMemberByIdSuspenseQuery>;
export type QueryMemberByIdQueryResult = Apollo.QueryResult<QueryMemberByIdQuery, QueryMemberByIdQueryVariables>;
export const QueryEmployeeTimersDocument = gql`
  query queryEmployeeTimers(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
      }
      position {
        id
        level
      }
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicMemberLaborMetrics
      }
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        memberId
        hasLocation
        flaggedTimeEntryLocation {
          id
          status
          locationData
        }
        memberGpsStatuses {
          id
          status
          localTime
        }
        openBreak {
          id
          startTime
          startDeviceTime
          startTimeDst
        }
        project {
          id
          title
          depth
          projectInfo {
            id
            latitude
            locationRadius
            longitude
          }
          children {
            id
            title
            depth
            archivedOn
            projectInfo {
              id
              latitude
              locationRadius
              longitude
            }
          }
          ancestors {
            id
            title
            depth
            archivedOn
            projectInfo {
              id
              latitude
              locationRadius
              longitude
            }
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        endTime
        daylightSavingTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
        breaks {
          id
          startTime
          startDeviceTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryEmployeeTimersQuery__
 *
 * To run a query within a React component, call `useQueryEmployeeTimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmployeeTimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmployeeTimersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryEmployeeTimersQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEmployeeTimersQuery, QueryEmployeeTimersQueryVariables> &
    ({ variables: QueryEmployeeTimersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEmployeeTimersQuery, QueryEmployeeTimersQueryVariables>(
    QueryEmployeeTimersDocument,
    options
  );
}
export function useQueryEmployeeTimersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEmployeeTimersQuery, QueryEmployeeTimersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEmployeeTimersQuery, QueryEmployeeTimersQueryVariables>(
    QueryEmployeeTimersDocument,
    options
  );
}
export function useQueryEmployeeTimersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEmployeeTimersQuery, QueryEmployeeTimersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEmployeeTimersQuery, QueryEmployeeTimersQueryVariables>(
    QueryEmployeeTimersDocument,
    options
  );
}
export type QueryEmployeeTimersQueryHookResult = ReturnType<typeof useQueryEmployeeTimersQuery>;
export type QueryEmployeeTimersLazyQueryHookResult = ReturnType<typeof useQueryEmployeeTimersLazyQuery>;
export type QueryEmployeeTimersSuspenseQueryHookResult = ReturnType<typeof useQueryEmployeeTimersSuspenseQuery>;
export type QueryEmployeeTimersQueryResult = Apollo.QueryResult<
  QueryEmployeeTimersQuery,
  QueryEmployeeTimersQueryVariables
>;
export const RecentPhotosMediaEntriesQueryDocument = gql`
  query recentPhotosMediaEntriesQuery($filter: MediaEntryFilter, $first: Int, $after: String) {
    mediaEntries(first: $first, after: $after, filter: $filter, sort: [{ createdOn: desc }]) {
      id
      mediaCollectionId
      fileUrl
      entryType
      location {
        id
        latitude
        longitude
        timeGmt
        timeLocal
        timeDst
      }
      captureDate
      createdOn
      deletedOn
      comment
      collection {
        id
        deletedOn
        description
        project {
          id
          title
          rootProject {
            id
            title
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
          archivedOn
        }
        createdByMember {
          id
          firstName
          lastName
          position {
            id
            level
          }
          memberGroupId
        }
        tagLinks {
          id
          tag {
            id
            title
          }
        }
      }
    }
  }
`;

/**
 * __useRecentPhotosMediaEntriesQueryQuery__
 *
 * To run a query within a React component, call `useRecentPhotosMediaEntriesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentPhotosMediaEntriesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentPhotosMediaEntriesQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useRecentPhotosMediaEntriesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentPhotosMediaEntriesQueryQuery, RecentPhotosMediaEntriesQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentPhotosMediaEntriesQueryQuery, RecentPhotosMediaEntriesQueryQueryVariables>(
    RecentPhotosMediaEntriesQueryDocument,
    options
  );
}
export function useRecentPhotosMediaEntriesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentPhotosMediaEntriesQueryQuery,
    RecentPhotosMediaEntriesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentPhotosMediaEntriesQueryQuery, RecentPhotosMediaEntriesQueryQueryVariables>(
    RecentPhotosMediaEntriesQueryDocument,
    options
  );
}
export function useRecentPhotosMediaEntriesQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RecentPhotosMediaEntriesQueryQuery,
    RecentPhotosMediaEntriesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentPhotosMediaEntriesQueryQuery, RecentPhotosMediaEntriesQueryQueryVariables>(
    RecentPhotosMediaEntriesQueryDocument,
    options
  );
}
export type RecentPhotosMediaEntriesQueryQueryHookResult = ReturnType<typeof useRecentPhotosMediaEntriesQueryQuery>;
export type RecentPhotosMediaEntriesQueryLazyQueryHookResult = ReturnType<
  typeof useRecentPhotosMediaEntriesQueryLazyQuery
>;
export type RecentPhotosMediaEntriesQuerySuspenseQueryHookResult = ReturnType<
  typeof useRecentPhotosMediaEntriesQuerySuspenseQuery
>;
export type RecentPhotosMediaEntriesQueryQueryResult = Apollo.QueryResult<
  RecentPhotosMediaEntriesQueryQuery,
  RecentPhotosMediaEntriesQueryQueryVariables
>;
export const PositionsFormFieldQueryDocument = gql`
  query positionsFormFieldQuery($filter: PositionFilter!) {
    positions(sort: [{ level: asc }], filter: $filter) {
      id
      level
      title
    }
  }
`;

/**
 * __usePositionsFormFieldQueryQuery__
 *
 * To run a query within a React component, call `usePositionsFormFieldQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionsFormFieldQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionsFormFieldQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePositionsFormFieldQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PositionsFormFieldQueryQuery, PositionsFormFieldQueryQueryVariables> &
    ({ variables: PositionsFormFieldQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PositionsFormFieldQueryQuery, PositionsFormFieldQueryQueryVariables>(
    PositionsFormFieldQueryDocument,
    options
  );
}
export function usePositionsFormFieldQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PositionsFormFieldQueryQuery, PositionsFormFieldQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PositionsFormFieldQueryQuery, PositionsFormFieldQueryQueryVariables>(
    PositionsFormFieldQueryDocument,
    options
  );
}
export function usePositionsFormFieldQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PositionsFormFieldQueryQuery, PositionsFormFieldQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PositionsFormFieldQueryQuery, PositionsFormFieldQueryQueryVariables>(
    PositionsFormFieldQueryDocument,
    options
  );
}
export type PositionsFormFieldQueryQueryHookResult = ReturnType<typeof usePositionsFormFieldQueryQuery>;
export type PositionsFormFieldQueryLazyQueryHookResult = ReturnType<typeof usePositionsFormFieldQueryLazyQuery>;
export type PositionsFormFieldQuerySuspenseQueryHookResult = ReturnType<typeof usePositionsFormFieldQuerySuspenseQuery>;
export type PositionsFormFieldQueryQueryResult = Apollo.QueryResult<
  PositionsFormFieldQueryQuery,
  PositionsFormFieldQueryQueryVariables
>;
export const ProjectGroupsDocument = gql`
  query ProjectGroups($first: Int, $after: String, $filter: ProjectGroupFilter) {
    projectGroups(first: $first, after: $after, filter: $filter, sort: [{ groupName: asc }]) {
      id
      groupName
      cursor
    }
  }
`;

/**
 * __useProjectGroupsQuery__
 *
 * To run a query within a React component, call `useProjectGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGroupsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectGroupsQuery, ProjectGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectGroupsQuery, ProjectGroupsQueryVariables>(ProjectGroupsDocument, options);
}
export function useProjectGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectGroupsQuery, ProjectGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectGroupsQuery, ProjectGroupsQueryVariables>(ProjectGroupsDocument, options);
}
export function useProjectGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectGroupsQuery, ProjectGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectGroupsQuery, ProjectGroupsQueryVariables>(ProjectGroupsDocument, options);
}
export type ProjectGroupsQueryHookResult = ReturnType<typeof useProjectGroupsQuery>;
export type ProjectGroupsLazyQueryHookResult = ReturnType<typeof useProjectGroupsLazyQuery>;
export type ProjectGroupsSuspenseQueryHookResult = ReturnType<typeof useProjectGroupsSuspenseQuery>;
export type ProjectGroupsQueryResult = Apollo.QueryResult<ProjectGroupsQuery, ProjectGroupsQueryVariables>;
export const ProjectGroupByIdDocument = gql`
  query ProjectGroupById($groupId: Uuid!) {
    projectGroups(filter: { id: { equal: $groupId } }) {
      id
      groupName
      cursor
    }
  }
`;

/**
 * __useProjectGroupByIdQuery__
 *
 * To run a query within a React component, call `useProjectGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGroupByIdQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useProjectGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables> &
    ({ variables: ProjectGroupByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables>(ProjectGroupByIdDocument, options);
}
export function useProjectGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables>(ProjectGroupByIdDocument, options);
}
export function useProjectGroupByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables>(
    ProjectGroupByIdDocument,
    options
  );
}
export type ProjectGroupByIdQueryHookResult = ReturnType<typeof useProjectGroupByIdQuery>;
export type ProjectGroupByIdLazyQueryHookResult = ReturnType<typeof useProjectGroupByIdLazyQuery>;
export type ProjectGroupByIdSuspenseQueryHookResult = ReturnType<typeof useProjectGroupByIdSuspenseQuery>;
export type ProjectGroupByIdQueryResult = Apollo.QueryResult<ProjectGroupByIdQuery, ProjectGroupByIdQueryVariables>;
export const ProjectOnlyIdReturnDocument = gql`
  query ProjectOnlyIdReturn($filter: ProjectFilter, $first: Int, $after: String) {
    projects(first: $first, after: $after, filter: $filter) {
      id
    }
  }
`;

/**
 * __useProjectOnlyIdReturnQuery__
 *
 * To run a query within a React component, call `useProjectOnlyIdReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectOnlyIdReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectOnlyIdReturnQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectOnlyIdReturnQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectOnlyIdReturnQuery, ProjectOnlyIdReturnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectOnlyIdReturnQuery, ProjectOnlyIdReturnQueryVariables>(
    ProjectOnlyIdReturnDocument,
    options
  );
}
export function useProjectOnlyIdReturnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectOnlyIdReturnQuery, ProjectOnlyIdReturnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectOnlyIdReturnQuery, ProjectOnlyIdReturnQueryVariables>(
    ProjectOnlyIdReturnDocument,
    options
  );
}
export function useProjectOnlyIdReturnSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectOnlyIdReturnQuery, ProjectOnlyIdReturnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectOnlyIdReturnQuery, ProjectOnlyIdReturnQueryVariables>(
    ProjectOnlyIdReturnDocument,
    options
  );
}
export type ProjectOnlyIdReturnQueryHookResult = ReturnType<typeof useProjectOnlyIdReturnQuery>;
export type ProjectOnlyIdReturnLazyQueryHookResult = ReturnType<typeof useProjectOnlyIdReturnLazyQuery>;
export type ProjectOnlyIdReturnSuspenseQueryHookResult = ReturnType<typeof useProjectOnlyIdReturnSuspenseQuery>;
export type ProjectOnlyIdReturnQueryResult = Apollo.QueryResult<
  ProjectOnlyIdReturnQuery,
  ProjectOnlyIdReturnQueryVariables
>;
export const ProjectPickerDocument = gql`
  query ProjectPicker($filter: ProjectFilter, $first: Int, $after: String) {
    projects(first: $first, after: $after, sort: [{ title: asc }], filter: $filter) {
      id
      title
      cursor
      archivedOn
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
    }
  }
`;

/**
 * __useProjectPickerQuery__
 *
 * To run a query within a React component, call `useProjectPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPickerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectPickerQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectPickerQuery, ProjectPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectPickerQuery, ProjectPickerQueryVariables>(ProjectPickerDocument, options);
}
export function useProjectPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectPickerQuery, ProjectPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectPickerQuery, ProjectPickerQueryVariables>(ProjectPickerDocument, options);
}
export function useProjectPickerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectPickerQuery, ProjectPickerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectPickerQuery, ProjectPickerQueryVariables>(ProjectPickerDocument, options);
}
export type ProjectPickerQueryHookResult = ReturnType<typeof useProjectPickerQuery>;
export type ProjectPickerLazyQueryHookResult = ReturnType<typeof useProjectPickerLazyQuery>;
export type ProjectPickerSuspenseQueryHookResult = ReturnType<typeof useProjectPickerSuspenseQuery>;
export type ProjectPickerQueryResult = Apollo.QueryResult<ProjectPickerQuery, ProjectPickerQueryVariables>;
export const ProjectPickerFlatDocument = gql`
  query ProjectPickerFlat($filter: ProjectFilter, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, first: $first, after: $after, sort: $sort) {
      id
      title
      cursor
      archivedOn
    }
  }
`;

/**
 * __useProjectPickerFlatQuery__
 *
 * To run a query within a React component, call `useProjectPickerFlatQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPickerFlatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPickerFlatQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProjectPickerFlatQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>(ProjectPickerFlatDocument, options);
}
export function useProjectPickerFlatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>(
    ProjectPickerFlatDocument,
    options
  );
}
export function useProjectPickerFlatSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>(
    ProjectPickerFlatDocument,
    options
  );
}
export type ProjectPickerFlatQueryHookResult = ReturnType<typeof useProjectPickerFlatQuery>;
export type ProjectPickerFlatLazyQueryHookResult = ReturnType<typeof useProjectPickerFlatLazyQuery>;
export type ProjectPickerFlatSuspenseQueryHookResult = ReturnType<typeof useProjectPickerFlatSuspenseQuery>;
export type ProjectPickerFlatQueryResult = Apollo.QueryResult<ProjectPickerFlatQuery, ProjectPickerFlatQueryVariables>;
export const ProjectPickerDetailsDocument = gql`
  query ProjectPickerDetails($filter: ProjectFilter, $first: Int) {
    projects(first: $first, filter: $filter) {
      id
      title
      depth
      cursor
      archivedOn
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
    }
  }
`;

/**
 * __useProjectPickerDetailsQuery__
 *
 * To run a query within a React component, call `useProjectPickerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPickerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPickerDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectPickerDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectPickerDetailsQuery, ProjectPickerDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectPickerDetailsQuery, ProjectPickerDetailsQueryVariables>(
    ProjectPickerDetailsDocument,
    options
  );
}
export function useProjectPickerDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectPickerDetailsQuery, ProjectPickerDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectPickerDetailsQuery, ProjectPickerDetailsQueryVariables>(
    ProjectPickerDetailsDocument,
    options
  );
}
export function useProjectPickerDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectPickerDetailsQuery, ProjectPickerDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectPickerDetailsQuery, ProjectPickerDetailsQueryVariables>(
    ProjectPickerDetailsDocument,
    options
  );
}
export type ProjectPickerDetailsQueryHookResult = ReturnType<typeof useProjectPickerDetailsQuery>;
export type ProjectPickerDetailsLazyQueryHookResult = ReturnType<typeof useProjectPickerDetailsLazyQuery>;
export type ProjectPickerDetailsSuspenseQueryHookResult = ReturnType<typeof useProjectPickerDetailsSuspenseQuery>;
export type ProjectPickerDetailsQueryResult = Apollo.QueryResult<
  ProjectPickerDetailsQuery,
  ProjectPickerDetailsQueryVariables
>;
export const SubprojectSearchDocument = gql`
  query SubprojectSearch($filter: ProjectFilter) {
    projects(first: 10, sort: [{ title: asc }], filter: $filter) {
      id
      title
      depth
      cursor
      archivedOn
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
    }
  }
`;

/**
 * __useSubprojectSearchQuery__
 *
 * To run a query within a React component, call `useSubprojectSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubprojectSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubprojectSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubprojectSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<SubprojectSearchQuery, SubprojectSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubprojectSearchQuery, SubprojectSearchQueryVariables>(SubprojectSearchDocument, options);
}
export function useSubprojectSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubprojectSearchQuery, SubprojectSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubprojectSearchQuery, SubprojectSearchQueryVariables>(SubprojectSearchDocument, options);
}
export function useSubprojectSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SubprojectSearchQuery, SubprojectSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubprojectSearchQuery, SubprojectSearchQueryVariables>(
    SubprojectSearchDocument,
    options
  );
}
export type SubprojectSearchQueryHookResult = ReturnType<typeof useSubprojectSearchQuery>;
export type SubprojectSearchLazyQueryHookResult = ReturnType<typeof useSubprojectSearchLazyQuery>;
export type SubprojectSearchSuspenseQueryHookResult = ReturnType<typeof useSubprojectSearchSuspenseQuery>;
export type SubprojectSearchQueryResult = Apollo.QueryResult<SubprojectSearchQuery, SubprojectSearchQueryVariables>;
export const ProjectWithChildrenAncestorsDocument = gql`
  query ProjectWithChildrenAncestors($filter: ProjectFilter) {
    projects(filter: $filter) {
      id
      title
      depth
      projectGroupId
      parentProjectId
      archivedOn
      children {
        title
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        projectGroupId
        archivedOn
      }
    }
  }
`;

/**
 * __useProjectWithChildrenAncestorsQuery__
 *
 * To run a query within a React component, call `useProjectWithChildrenAncestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithChildrenAncestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithChildrenAncestorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectWithChildrenAncestorsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectWithChildrenAncestorsQuery, ProjectWithChildrenAncestorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectWithChildrenAncestorsQuery, ProjectWithChildrenAncestorsQueryVariables>(
    ProjectWithChildrenAncestorsDocument,
    options
  );
}
export function useProjectWithChildrenAncestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithChildrenAncestorsQuery,
    ProjectWithChildrenAncestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectWithChildrenAncestorsQuery, ProjectWithChildrenAncestorsQueryVariables>(
    ProjectWithChildrenAncestorsDocument,
    options
  );
}
export function useProjectWithChildrenAncestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithChildrenAncestorsQuery,
    ProjectWithChildrenAncestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectWithChildrenAncestorsQuery, ProjectWithChildrenAncestorsQueryVariables>(
    ProjectWithChildrenAncestorsDocument,
    options
  );
}
export type ProjectWithChildrenAncestorsQueryHookResult = ReturnType<typeof useProjectWithChildrenAncestorsQuery>;
export type ProjectWithChildrenAncestorsLazyQueryHookResult = ReturnType<
  typeof useProjectWithChildrenAncestorsLazyQuery
>;
export type ProjectWithChildrenAncestorsSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithChildrenAncestorsSuspenseQuery
>;
export type ProjectWithChildrenAncestorsQueryResult = Apollo.QueryResult<
  ProjectWithChildrenAncestorsQuery,
  ProjectWithChildrenAncestorsQueryVariables
>;
export const GetProjectWithSubProjectsDocument = gql`
  query GetProjectWithSubProjects($projectId: Uuid, $first: Int, $after: String) {
    projects(filter: { id: { equal: $projectId }, archivedOn: { isNull: true } }, first: $first, after: $after) {
      id
      title
      archivedOn
      ancestors {
        id
      }
      children {
        id
        title
        archivedOn
        ancestors {
          id
          title
        }
        children {
          id
          title
          archivedOn
        }
      }
    }
  }
`;

/**
 * __useGetProjectWithSubProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectWithSubProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectWithSubProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectWithSubProjectsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetProjectWithSubProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProjectWithSubProjectsQuery, GetProjectWithSubProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectWithSubProjectsQuery, GetProjectWithSubProjectsQueryVariables>(
    GetProjectWithSubProjectsDocument,
    options
  );
}
export function useGetProjectWithSubProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectWithSubProjectsQuery, GetProjectWithSubProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectWithSubProjectsQuery, GetProjectWithSubProjectsQueryVariables>(
    GetProjectWithSubProjectsDocument,
    options
  );
}
export function useGetProjectWithSubProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetProjectWithSubProjectsQuery, GetProjectWithSubProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectWithSubProjectsQuery, GetProjectWithSubProjectsQueryVariables>(
    GetProjectWithSubProjectsDocument,
    options
  );
}
export type GetProjectWithSubProjectsQueryHookResult = ReturnType<typeof useGetProjectWithSubProjectsQuery>;
export type GetProjectWithSubProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectWithSubProjectsLazyQuery>;
export type GetProjectWithSubProjectsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectWithSubProjectsSuspenseQuery
>;
export type GetProjectWithSubProjectsQueryResult = Apollo.QueryResult<
  GetProjectWithSubProjectsQuery,
  GetProjectWithSubProjectsQueryVariables
>;
export const ProjectsWith1NestedSubprojectsDocument = gql`
  query ProjectsWith1NestedSubprojects($projectId: Uuid) {
    projects(filter: { id: { equal: $projectId } }) {
      id
      archivedOn
      title
      depth
      children {
        id
        archivedOn
        title
        depth
        children {
          id
          title
          archivedOn
        }
      }
    }
  }
`;

/**
 * __useProjectsWith1NestedSubprojectsQuery__
 *
 * To run a query within a React component, call `useProjectsWith1NestedSubprojectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsWith1NestedSubprojectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsWith1NestedSubprojectsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectsWith1NestedSubprojectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectsWith1NestedSubprojectsQuery,
    ProjectsWith1NestedSubprojectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsWith1NestedSubprojectsQuery, ProjectsWith1NestedSubprojectsQueryVariables>(
    ProjectsWith1NestedSubprojectsDocument,
    options
  );
}
export function useProjectsWith1NestedSubprojectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectsWith1NestedSubprojectsQuery,
    ProjectsWith1NestedSubprojectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsWith1NestedSubprojectsQuery, ProjectsWith1NestedSubprojectsQueryVariables>(
    ProjectsWith1NestedSubprojectsDocument,
    options
  );
}
export function useProjectsWith1NestedSubprojectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectsWith1NestedSubprojectsQuery,
    ProjectsWith1NestedSubprojectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsWith1NestedSubprojectsQuery, ProjectsWith1NestedSubprojectsQueryVariables>(
    ProjectsWith1NestedSubprojectsDocument,
    options
  );
}
export type ProjectsWith1NestedSubprojectsQueryHookResult = ReturnType<typeof useProjectsWith1NestedSubprojectsQuery>;
export type ProjectsWith1NestedSubprojectsLazyQueryHookResult = ReturnType<
  typeof useProjectsWith1NestedSubprojectsLazyQuery
>;
export type ProjectsWith1NestedSubprojectsSuspenseQueryHookResult = ReturnType<
  typeof useProjectsWith1NestedSubprojectsSuspenseQuery
>;
export type ProjectsWith1NestedSubprojectsQueryResult = Apollo.QueryResult<
  ProjectsWith1NestedSubprojectsQuery,
  ProjectsWith1NestedSubprojectsQueryVariables
>;
export const TimeEntryBreakIssuesTableDataMembersDocument = gql`
  query TimeEntryBreakIssuesTableDataMembers(
    $after: String
    $filter: MemberFilter
    $first: Int
    $startTime: Timestamp!
    $endTime: Timestamp!
  ) {
    members(first: $first, after: $after, filter: $filter) {
      id
      firstName
      lastName
      archivedOn
      imageUrl
      cursor
      position {
        id
        level
        manageTimeEntries
      }
      memberGroupId
      timeEntries(
        filter: {
          startTime: { lessThanOrEqual: $endTime }
          endTime: { greaterThanOrEqual: $startTime }
          deletedOn: { isNull: true }
        }
      ) {
        ...PayrollScanBreakComplianceTimeEntry
        id
        createdOn
        daylightSavingTime
        deletedOn
        description
        endDeviceTime
        endLocationId
        endTime
        endTimeTrusted
        metaDaylightSavingTime
        startDeviceTime
        startLocationId
        startTime
        startTimeTrusted
      }
      openTimeEntries: timeEntries(
        filter: { startTime: { lessThanOrEqual: $endTime }, endTime: { isNull: true }, deletedOn: { isNull: true } }
      ) {
        ...PayrollScanBreakComplianceTimeEntry
        id
        createdOn
        daylightSavingTime
        deletedOn
        description
        endDeviceTime
        endLocationId
        endTime
        endTimeTrusted
        metaDaylightSavingTime
        startDeviceTime
        startLocationId
        startTime
        startTimeTrusted
      }
      safetySignatures(
        filter: { startTime: { between: { start: $startTime, end: $endTime } }, deletedOn: { isNull: true } }
      ) {
        id
        breakPolicyFollowed
        breakPolicyFollowedResolvedOn
        startTime
      }
      memberTimeDocuments(
        filter: {
          startTime: { lessThanOrEqual: $endTime }
          endTime: { greaterThanOrEqual: $startTime }
          deletedOn: { isNull: true }
        }
        sort: [{ submittedOn: desc }]
      ) {
        id
        submittedOn
        canceled
        edited
      }
      memberLock {
        id
        effectiveDate
      }
    }
  }
  ${PayrollScanBreakComplianceTimeEntryFragmentDoc}
`;

/**
 * __useTimeEntryBreakIssuesTableDataMembersQuery__
 *
 * To run a query within a React component, call `useTimeEntryBreakIssuesTableDataMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryBreakIssuesTableDataMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryBreakIssuesTableDataMembersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useTimeEntryBreakIssuesTableDataMembersQuery(
  baseOptions: Apollo.QueryHookOptions<
    TimeEntryBreakIssuesTableDataMembersQuery,
    TimeEntryBreakIssuesTableDataMembersQueryVariables
  > &
    ({ variables: TimeEntryBreakIssuesTableDataMembersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryBreakIssuesTableDataMembersQuery, TimeEntryBreakIssuesTableDataMembersQueryVariables>(
    TimeEntryBreakIssuesTableDataMembersDocument,
    options
  );
}
export function useTimeEntryBreakIssuesTableDataMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeEntryBreakIssuesTableDataMembersQuery,
    TimeEntryBreakIssuesTableDataMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimeEntryBreakIssuesTableDataMembersQuery,
    TimeEntryBreakIssuesTableDataMembersQueryVariables
  >(TimeEntryBreakIssuesTableDataMembersDocument, options);
}
export function useTimeEntryBreakIssuesTableDataMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TimeEntryBreakIssuesTableDataMembersQuery,
    TimeEntryBreakIssuesTableDataMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    TimeEntryBreakIssuesTableDataMembersQuery,
    TimeEntryBreakIssuesTableDataMembersQueryVariables
  >(TimeEntryBreakIssuesTableDataMembersDocument, options);
}
export type TimeEntryBreakIssuesTableDataMembersQueryHookResult = ReturnType<
  typeof useTimeEntryBreakIssuesTableDataMembersQuery
>;
export type TimeEntryBreakIssuesTableDataMembersLazyQueryHookResult = ReturnType<
  typeof useTimeEntryBreakIssuesTableDataMembersLazyQuery
>;
export type TimeEntryBreakIssuesTableDataMembersSuspenseQueryHookResult = ReturnType<
  typeof useTimeEntryBreakIssuesTableDataMembersSuspenseQuery
>;
export type TimeEntryBreakIssuesTableDataMembersQueryResult = Apollo.QueryResult<
  TimeEntryBreakIssuesTableDataMembersQuery,
  TimeEntryBreakIssuesTableDataMembersQueryVariables
>;
export const ProjectsWithTimeDocument = gql`
  query ProjectsWithTime(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $pageNumber: Int
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
      pageNumber: $pageNumber
    ) {
      id
      title
      depth
      rootProjectId
      projectInfo {
        id
        number
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
    }
  }
`;

/**
 * __useProjectsWithTimeQuery__
 *
 * To run a query within a React component, call `useProjectsWithTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsWithTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsWithTimeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      costCodeId: // value for 'costCodeId'
 *      equipmentId: // value for 'equipmentId'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useProjectsWithTimeQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables> &
    ({ variables: ProjectsWithTimeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables>(ProjectsWithTimeDocument, options);
}
export function useProjectsWithTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables>(ProjectsWithTimeDocument, options);
}
export function useProjectsWithTimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables>(
    ProjectsWithTimeDocument,
    options
  );
}
export type ProjectsWithTimeQueryHookResult = ReturnType<typeof useProjectsWithTimeQuery>;
export type ProjectsWithTimeLazyQueryHookResult = ReturnType<typeof useProjectsWithTimeLazyQuery>;
export type ProjectsWithTimeSuspenseQueryHookResult = ReturnType<typeof useProjectsWithTimeSuspenseQuery>;
export type ProjectsWithTimeQueryResult = Apollo.QueryResult<ProjectsWithTimeQuery, ProjectsWithTimeQueryVariables>;
export const QueryCostCodeArchivedDocument = gql`
  query queryCostCodeArchived($first: Int, $after: String, $filter: CostCodeFilter) {
    costCodes(first: $first, after: $after, filter: $filter) {
      id
      cursor
      archivedOn
    }
  }
`;

/**
 * __useQueryCostCodeArchivedQuery__
 *
 * To run a query within a React component, call `useQueryCostCodeArchivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodeArchivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodeArchivedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryCostCodeArchivedQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCostCodeArchivedQuery, QueryCostCodeArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodeArchivedQuery, QueryCostCodeArchivedQueryVariables>(
    QueryCostCodeArchivedDocument,
    options
  );
}
export function useQueryCostCodeArchivedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodeArchivedQuery, QueryCostCodeArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodeArchivedQuery, QueryCostCodeArchivedQueryVariables>(
    QueryCostCodeArchivedDocument,
    options
  );
}
export function useQueryCostCodeArchivedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodeArchivedQuery, QueryCostCodeArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodeArchivedQuery, QueryCostCodeArchivedQueryVariables>(
    QueryCostCodeArchivedDocument,
    options
  );
}
export type QueryCostCodeArchivedQueryHookResult = ReturnType<typeof useQueryCostCodeArchivedQuery>;
export type QueryCostCodeArchivedLazyQueryHookResult = ReturnType<typeof useQueryCostCodeArchivedLazyQuery>;
export type QueryCostCodeArchivedSuspenseQueryHookResult = ReturnType<typeof useQueryCostCodeArchivedSuspenseQuery>;
export type QueryCostCodeArchivedQueryResult = Apollo.QueryResult<
  QueryCostCodeArchivedQuery,
  QueryCostCodeArchivedQueryVariables
>;
export const QueryProjectArchivedDocument = gql`
  query queryProjectArchived($first: Int, $after: String, $filter: ProjectFilter) {
    projects(first: $first, after: $after, filter: $filter) {
      id
      cursor
      archivedOn
    }
  }
`;

/**
 * __useQueryProjectArchivedQuery__
 *
 * To run a query within a React component, call `useQueryProjectArchivedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectArchivedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectArchivedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryProjectArchivedQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryProjectArchivedQuery, QueryProjectArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectArchivedQuery, QueryProjectArchivedQueryVariables>(
    QueryProjectArchivedDocument,
    options
  );
}
export function useQueryProjectArchivedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryProjectArchivedQuery, QueryProjectArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectArchivedQuery, QueryProjectArchivedQueryVariables>(
    QueryProjectArchivedDocument,
    options
  );
}
export function useQueryProjectArchivedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryProjectArchivedQuery, QueryProjectArchivedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectArchivedQuery, QueryProjectArchivedQueryVariables>(
    QueryProjectArchivedDocument,
    options
  );
}
export type QueryProjectArchivedQueryHookResult = ReturnType<typeof useQueryProjectArchivedQuery>;
export type QueryProjectArchivedLazyQueryHookResult = ReturnType<typeof useQueryProjectArchivedLazyQuery>;
export type QueryProjectArchivedSuspenseQueryHookResult = ReturnType<typeof useQueryProjectArchivedSuspenseQuery>;
export type QueryProjectArchivedQueryResult = Apollo.QueryResult<
  QueryProjectArchivedQuery,
  QueryProjectArchivedQueryVariables
>;
export const QueryEquipmentDeletedDocument = gql`
  query queryEquipmentDeleted($first: Int, $after: String, $filter: EquipmentFilter) {
    equipment(first: $first, after: $after, filter: $filter) {
      id
      cursor
      deletedOn
    }
  }
`;

/**
 * __useQueryEquipmentDeletedQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentDeletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentDeletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentDeletedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryEquipmentDeletedQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentDeletedQuery, QueryEquipmentDeletedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentDeletedQuery, QueryEquipmentDeletedQueryVariables>(
    QueryEquipmentDeletedDocument,
    options
  );
}
export function useQueryEquipmentDeletedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentDeletedQuery, QueryEquipmentDeletedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentDeletedQuery, QueryEquipmentDeletedQueryVariables>(
    QueryEquipmentDeletedDocument,
    options
  );
}
export function useQueryEquipmentDeletedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentDeletedQuery, QueryEquipmentDeletedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentDeletedQuery, QueryEquipmentDeletedQueryVariables>(
    QueryEquipmentDeletedDocument,
    options
  );
}
export type QueryEquipmentDeletedQueryHookResult = ReturnType<typeof useQueryEquipmentDeletedQuery>;
export type QueryEquipmentDeletedLazyQueryHookResult = ReturnType<typeof useQueryEquipmentDeletedLazyQuery>;
export type QueryEquipmentDeletedSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentDeletedSuspenseQuery>;
export type QueryEquipmentDeletedQueryResult = Apollo.QueryResult<
  QueryEquipmentDeletedQuery,
  QueryEquipmentDeletedQueryVariables
>;
export const QueryCostCodesWithMetricsDocument = gql`
  query QueryCostCodesWithMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...LaborMetrics
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryCostCodesWithMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryCostCodesWithMetricsQuery, QueryCostCodesWithMetricsQueryVariables> &
    ({ variables: QueryCostCodesWithMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesWithMetricsQuery, QueryCostCodesWithMetricsQueryVariables>(
    QueryCostCodesWithMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodesWithMetricsQuery, QueryCostCodesWithMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesWithMetricsQuery, QueryCostCodesWithMetricsQueryVariables>(
    QueryCostCodesWithMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodesWithMetricsQuery, QueryCostCodesWithMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesWithMetricsQuery, QueryCostCodesWithMetricsQueryVariables>(
    QueryCostCodesWithMetricsDocument,
    options
  );
}
export type QueryCostCodesWithMetricsQueryHookResult = ReturnType<typeof useQueryCostCodesWithMetricsQuery>;
export type QueryCostCodesWithMetricsLazyQueryHookResult = ReturnType<typeof useQueryCostCodesWithMetricsLazyQuery>;
export type QueryCostCodesWithMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMetricsSuspenseQuery
>;
export type QueryCostCodesWithMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMetricsQuery,
  QueryCostCodesWithMetricsQueryVariables
>;
export const QueryCostCodesWithTimeMetricsDocument = gql`
  query QueryCostCodesWithTimeMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithTimeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryCostCodesWithTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithTimeMetricsQuery,
    QueryCostCodesWithTimeMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesWithTimeMetricsQuery, QueryCostCodesWithTimeMetricsQueryVariables>(
    QueryCostCodesWithTimeMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithTimeMetricsQuery,
    QueryCostCodesWithTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesWithTimeMetricsQuery, QueryCostCodesWithTimeMetricsQueryVariables>(
    QueryCostCodesWithTimeMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithTimeMetricsQuery,
    QueryCostCodesWithTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesWithTimeMetricsQuery, QueryCostCodesWithTimeMetricsQueryVariables>(
    QueryCostCodesWithTimeMetricsDocument,
    options
  );
}
export type QueryCostCodesWithTimeMetricsQueryHookResult = ReturnType<typeof useQueryCostCodesWithTimeMetricsQuery>;
export type QueryCostCodesWithTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithTimeMetricsLazyQuery
>;
export type QueryCostCodesWithTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithTimeMetricsSuspenseQuery
>;
export type QueryCostCodesWithTimeMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithTimeMetricsQuery,
  QueryCostCodesWithTimeMetricsQueryVariables
>;
export const QueryCostCodesWithEquipmentMetricsDocument = gql`
  query QueryCostCodesWithEquipmentMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...LaborMetrics
        equipmentId
        equipmentCents
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithEquipmentMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithEquipmentMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithEquipmentMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithEquipmentMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryCostCodesWithEquipmentMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithEquipmentMetricsQuery,
    QueryCostCodesWithEquipmentMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithEquipmentMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesWithEquipmentMetricsQuery, QueryCostCodesWithEquipmentMetricsQueryVariables>(
    QueryCostCodesWithEquipmentMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithEquipmentMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithEquipmentMetricsQuery,
    QueryCostCodesWithEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesWithEquipmentMetricsQuery, QueryCostCodesWithEquipmentMetricsQueryVariables>(
    QueryCostCodesWithEquipmentMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithEquipmentMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithEquipmentMetricsQuery,
    QueryCostCodesWithEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithEquipmentMetricsQuery,
    QueryCostCodesWithEquipmentMetricsQueryVariables
  >(QueryCostCodesWithEquipmentMetricsDocument, options);
}
export type QueryCostCodesWithEquipmentMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithEquipmentMetricsQuery
>;
export type QueryCostCodesWithEquipmentMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithEquipmentMetricsLazyQuery
>;
export type QueryCostCodesWithEquipmentMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithEquipmentMetricsSuspenseQuery
>;
export type QueryCostCodesWithEquipmentMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithEquipmentMetricsQuery,
  QueryCostCodesWithEquipmentMetricsQueryVariables
>;
export const QueryCostCodesWithEquipmentTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithEquipmentTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...BasicLaborMetrics
        equipmentId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithEquipmentTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithEquipmentTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithEquipmentTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithEquipmentTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryCostCodesWithEquipmentTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithEquipmentTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithEquipmentTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithEquipmentTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithEquipmentTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithEquipmentTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithEquipmentTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithEquipmentTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithEquipmentTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithEquipmentTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithEquipmentTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithEquipmentTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithEquipmentTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithEquipmentTimeOnlyMetricsQuery,
  QueryCostCodesWithEquipmentTimeOnlyMetricsQueryVariables
>;
export const QueryCostCodesWithMemberMetricsDocument = gql`
  query QueryCostCodesWithMemberMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        memberId
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMemberMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMemberMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMemberMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMemberMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryCostCodesWithMemberMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithMemberMetricsQuery,
    QueryCostCodesWithMemberMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithMemberMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesWithMemberMetricsQuery, QueryCostCodesWithMemberMetricsQueryVariables>(
    QueryCostCodesWithMemberMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithMemberMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithMemberMetricsQuery,
    QueryCostCodesWithMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesWithMemberMetricsQuery, QueryCostCodesWithMemberMetricsQueryVariables>(
    QueryCostCodesWithMemberMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithMemberMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithMemberMetricsQuery,
    QueryCostCodesWithMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesWithMemberMetricsQuery, QueryCostCodesWithMemberMetricsQueryVariables>(
    QueryCostCodesWithMemberMetricsDocument,
    options
  );
}
export type QueryCostCodesWithMemberMetricsQueryHookResult = ReturnType<typeof useQueryCostCodesWithMemberMetricsQuery>;
export type QueryCostCodesWithMemberMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberMetricsLazyQuery
>;
export type QueryCostCodesWithMemberMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberMetricsSuspenseQuery
>;
export type QueryCostCodesWithMemberMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMemberMetricsQuery,
  QueryCostCodesWithMemberMetricsQueryVariables
>;
export const QueryCostCodesWithMemberTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithMemberTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        memberId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMemberTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMemberTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMemberTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMemberTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryCostCodesWithMemberTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithMemberTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithMemberTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithMemberTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithMemberTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithMemberTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithMemberTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithMemberTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithMemberTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithMemberTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithMemberTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithMemberTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithMemberTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMemberTimeOnlyMetricsQuery,
  QueryCostCodesWithMemberTimeOnlyMetricsQueryVariables
>;
export const QueryCostCodesWithProjectMetricsDocument = gql`
  query QueryCostCodesWithProjectMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithProjectMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithProjectMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryCostCodesWithProjectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithProjectMetricsQuery,
    QueryCostCodesWithProjectMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithProjectMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodesWithProjectMetricsQuery, QueryCostCodesWithProjectMetricsQueryVariables>(
    QueryCostCodesWithProjectMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithProjectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithProjectMetricsQuery,
    QueryCostCodesWithProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodesWithProjectMetricsQuery, QueryCostCodesWithProjectMetricsQueryVariables>(
    QueryCostCodesWithProjectMetricsDocument,
    options
  );
}
export function useQueryCostCodesWithProjectMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithProjectMetricsQuery,
    QueryCostCodesWithProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodesWithProjectMetricsQuery, QueryCostCodesWithProjectMetricsQueryVariables>(
    QueryCostCodesWithProjectMetricsDocument,
    options
  );
}
export type QueryCostCodesWithProjectMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectMetricsQuery
>;
export type QueryCostCodesWithProjectMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectMetricsLazyQuery
>;
export type QueryCostCodesWithProjectMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectMetricsSuspenseQuery
>;
export type QueryCostCodesWithProjectMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithProjectMetricsQuery,
  QueryCostCodesWithProjectMetricsQueryVariables
>;
export const QueryCostCodesWithProjectTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithProjectTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithProjectTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithProjectTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithProjectTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithProjectTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryCostCodesWithProjectTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithProjectTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithProjectTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithProjectTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithProjectTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithProjectTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithProjectTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithProjectTimeOnlyMetricsQuery,
  QueryCostCodesWithProjectTimeOnlyMetricsQueryVariables
>;
export const QueryCostCodesWithProjectAndEquipmentMetricsDocument = gql`
  query QueryCostCodesWithProjectAndEquipmentMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        equipmentCents
        descEquipmentCents
        ...ProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithProjectAndEquipmentMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithProjectAndEquipmentMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithProjectAndEquipmentMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithProjectAndEquipmentMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryCostCodesWithProjectAndEquipmentMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithProjectAndEquipmentMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithProjectAndEquipmentMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
  >(QueryCostCodesWithProjectAndEquipmentMetricsDocument, options);
}
export function useQueryCostCodesWithProjectAndEquipmentMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithProjectAndEquipmentMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithProjectAndEquipmentMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
  >(QueryCostCodesWithProjectAndEquipmentMetricsDocument, options);
}
export function useQueryCostCodesWithProjectAndEquipmentMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithProjectAndEquipmentMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithProjectAndEquipmentMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
  >(QueryCostCodesWithProjectAndEquipmentMetricsDocument, options);
}
export type QueryCostCodesWithProjectAndEquipmentMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectAndEquipmentMetricsQuery
>;
export type QueryCostCodesWithProjectAndEquipmentMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectAndEquipmentMetricsLazyQuery
>;
export type QueryCostCodesWithProjectAndEquipmentMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectAndEquipmentMetricsSuspenseQuery
>;
export type QueryCostCodesWithProjectAndEquipmentMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithProjectAndEquipmentMetricsQuery,
  QueryCostCodesWithProjectAndEquipmentMetricsQueryVariables
>;
export const QueryCostCodesWithProjectEquipmentAndProjectMetricsDocument = gql`
  query QueryCostCodesWithProjectEquipmentAndProjectMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
      }
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        equipmentCents
        ...ProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithProjectEquipmentAndProjectMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithProjectEquipmentAndProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithProjectEquipmentAndProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithProjectEquipmentAndProjectMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryCostCodesWithProjectEquipmentAndProjectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
  > &
    (
      | { variables: QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
  >(QueryCostCodesWithProjectEquipmentAndProjectMetricsDocument, options);
}
export function useQueryCostCodesWithProjectEquipmentAndProjectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
  >(QueryCostCodesWithProjectEquipmentAndProjectMetricsDocument, options);
}
export function useQueryCostCodesWithProjectEquipmentAndProjectMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
  >(QueryCostCodesWithProjectEquipmentAndProjectMetricsDocument, options);
}
export type QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectEquipmentAndProjectMetricsQuery
>;
export type QueryCostCodesWithProjectEquipmentAndProjectMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectEquipmentAndProjectMetricsLazyQuery
>;
export type QueryCostCodesWithProjectEquipmentAndProjectMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectEquipmentAndProjectMetricsSuspenseQuery
>;
export type QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithProjectEquipmentAndProjectMetricsQuery,
  QueryCostCodesWithProjectEquipmentAndProjectMetricsQueryVariables
>;
export const QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithProjectAndEquipmentTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        equipmentCents
        ...BasicProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
  > &
    (
      | { variables: QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQuery,
  QueryCostCodesWithProjectAndEquipmentTimeOnlyMetricsQueryVariables
>;
export const QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
      }
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        equipmentCents
        ...BasicProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
  > &
    (
      | { variables: QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQuery,
  QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetricsQueryVariables
>;
export const QueryCostCodesWithMemberProjectMetricsDocument = gql`
  query QueryCostCodesWithMemberProjectMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        memberIds: $memberIds
      ) {
        ...ProjectLaborMetrics
        projectId
        memberId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMemberProjectMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMemberProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMemberProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMemberProjectMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryCostCodesWithMemberProjectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithMemberProjectMetricsQuery,
    QueryCostCodesWithMemberProjectMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithMemberProjectMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithMemberProjectMetricsQuery,
    QueryCostCodesWithMemberProjectMetricsQueryVariables
  >(QueryCostCodesWithMemberProjectMetricsDocument, options);
}
export function useQueryCostCodesWithMemberProjectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithMemberProjectMetricsQuery,
    QueryCostCodesWithMemberProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithMemberProjectMetricsQuery,
    QueryCostCodesWithMemberProjectMetricsQueryVariables
  >(QueryCostCodesWithMemberProjectMetricsDocument, options);
}
export function useQueryCostCodesWithMemberProjectMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithMemberProjectMetricsQuery,
    QueryCostCodesWithMemberProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithMemberProjectMetricsQuery,
    QueryCostCodesWithMemberProjectMetricsQueryVariables
  >(QueryCostCodesWithMemberProjectMetricsDocument, options);
}
export type QueryCostCodesWithMemberProjectMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberProjectMetricsQuery
>;
export type QueryCostCodesWithMemberProjectMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberProjectMetricsLazyQuery
>;
export type QueryCostCodesWithMemberProjectMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberProjectMetricsSuspenseQuery
>;
export type QueryCostCodesWithMemberProjectMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMemberProjectMetricsQuery,
  QueryCostCodesWithMemberProjectMetricsQueryVariables
>;
export const QueryCostCodesWithMemberProjectTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithMemberProjectTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        memberIds: $memberIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        memberId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMemberProjectTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMemberProjectTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMemberProjectTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMemberProjectTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryCostCodesWithMemberProjectTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberProjectTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithMemberProjectTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberProjectTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithMemberProjectTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberProjectTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberProjectTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithMemberProjectTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberProjectTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithMemberProjectTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberProjectTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMemberProjectTimeOnlyMetricsQuery,
  QueryCostCodesWithMemberProjectTimeOnlyMetricsQueryVariables
>;
export const QueryCostCodesWithMemberEquipmentMetricsDocument = gql`
  query QueryCostCodesWithMemberEquipmentMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        memberId
        equipmentId
        equipmentCents
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMemberEquipmentMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMemberEquipmentMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMemberEquipmentMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMemberEquipmentMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryCostCodesWithMemberEquipmentMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithMemberEquipmentMetricsQuery,
    QueryCostCodesWithMemberEquipmentMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithMemberEquipmentMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithMemberEquipmentMetricsQuery,
    QueryCostCodesWithMemberEquipmentMetricsQueryVariables
  >(QueryCostCodesWithMemberEquipmentMetricsDocument, options);
}
export function useQueryCostCodesWithMemberEquipmentMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithMemberEquipmentMetricsQuery,
    QueryCostCodesWithMemberEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithMemberEquipmentMetricsQuery,
    QueryCostCodesWithMemberEquipmentMetricsQueryVariables
  >(QueryCostCodesWithMemberEquipmentMetricsDocument, options);
}
export function useQueryCostCodesWithMemberEquipmentMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithMemberEquipmentMetricsQuery,
    QueryCostCodesWithMemberEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithMemberEquipmentMetricsQuery,
    QueryCostCodesWithMemberEquipmentMetricsQueryVariables
  >(QueryCostCodesWithMemberEquipmentMetricsDocument, options);
}
export type QueryCostCodesWithMemberEquipmentMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberEquipmentMetricsQuery
>;
export type QueryCostCodesWithMemberEquipmentMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberEquipmentMetricsLazyQuery
>;
export type QueryCostCodesWithMemberEquipmentMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberEquipmentMetricsSuspenseQuery
>;
export type QueryCostCodesWithMemberEquipmentMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMemberEquipmentMetricsQuery,
  QueryCostCodesWithMemberEquipmentMetricsQueryVariables
>;
export const QueryCostCodesWithMemberEquipmentTimeOnlyMetricsDocument = gql`
  query QueryCostCodesWithMemberEquipmentTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        memberId
        equipmentId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberEquipmentTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberEquipmentTimeOnlyMetricsDocument, options);
}
export function useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
    QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
  >(QueryCostCodesWithMemberEquipmentTimeOnlyMetricsDocument, options);
}
export type QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery
>;
export type QueryCostCodesWithMemberEquipmentTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsLazyQuery
>;
export type QueryCostCodesWithMemberEquipmentTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodesWithMemberEquipmentTimeOnlyMetricsSuspenseQuery
>;
export type QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQuery,
  QueryCostCodesWithMemberEquipmentTimeOnlyMetricsQueryVariables
>;
export const QueryBasicEquipmentWithMemberMetricsDocument = gql`
  query queryBasicEquipmentWithMemberMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        equipmentCents
        memberId
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithMemberMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithMemberMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithMemberMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithMemberMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithMemberMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithMemberMetricsQuery,
    QueryBasicEquipmentWithMemberMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithMemberMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBasicEquipmentWithMemberMetricsQuery, QueryBasicEquipmentWithMemberMetricsQueryVariables>(
    QueryBasicEquipmentWithMemberMetricsDocument,
    options
  );
}
export function useQueryBasicEquipmentWithMemberMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithMemberMetricsQuery,
    QueryBasicEquipmentWithMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithMemberMetricsQuery,
    QueryBasicEquipmentWithMemberMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithMemberMetricsQuery,
    QueryBasicEquipmentWithMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithMemberMetricsQuery,
    QueryBasicEquipmentWithMemberMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberMetricsDocument, options);
}
export type QueryBasicEquipmentWithMemberMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberMetricsQuery
>;
export type QueryBasicEquipmentWithMemberMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberMetricsLazyQuery
>;
export type QueryBasicEquipmentWithMemberMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithMemberMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithMemberMetricsQuery,
  QueryBasicEquipmentWithMemberMetricsQueryVariables
>;
export const QueryBasicEquipmentWithTimeOnlyMemberMetricsDocument = gql`
  query queryBasicEquipmentWithTimeOnlyMemberMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        equipmentCents
        memberId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithTimeOnlyMemberMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithTimeOnlyMemberMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithTimeOnlyMemberMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithTimeOnlyMemberMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithTimeOnlyMemberMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
  >(QueryBasicEquipmentWithTimeOnlyMemberMetricsDocument, options);
}
export function useQueryBasicEquipmentWithTimeOnlyMemberMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
  >(QueryBasicEquipmentWithTimeOnlyMemberMetricsDocument, options);
}
export function useQueryBasicEquipmentWithTimeOnlyMemberMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
    QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
  >(QueryBasicEquipmentWithTimeOnlyMemberMetricsDocument, options);
}
export type QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithTimeOnlyMemberMetricsQuery
>;
export type QueryBasicEquipmentWithTimeOnlyMemberMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithTimeOnlyMemberMetricsLazyQuery
>;
export type QueryBasicEquipmentWithTimeOnlyMemberMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithTimeOnlyMemberMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithTimeOnlyMemberMetricsQuery,
  QueryBasicEquipmentWithTimeOnlyMemberMetricsQueryVariables
>;
export const QueryBasicEquipmentWithProjectMetricsDocument = gql`
  query queryBasicEquipmentWithProjectMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        equipmentCents
        descEquipmentCents
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithProjectMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithProjectMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithProjectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithProjectMetricsQuery,
    QueryBasicEquipmentWithProjectMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithProjectMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithProjectMetricsQuery,
    QueryBasicEquipmentWithProjectMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithProjectMetricsQuery,
    QueryBasicEquipmentWithProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithProjectMetricsQuery,
    QueryBasicEquipmentWithProjectMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithProjectMetricsQuery,
    QueryBasicEquipmentWithProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithProjectMetricsQuery,
    QueryBasicEquipmentWithProjectMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectMetricsDocument, options);
}
export type QueryBasicEquipmentWithProjectMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectMetricsQuery
>;
export type QueryBasicEquipmentWithProjectMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectMetricsLazyQuery
>;
export type QueryBasicEquipmentWithProjectMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithProjectMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithProjectMetricsQuery,
  QueryBasicEquipmentWithProjectMetricsQueryVariables
>;
export const QueryBasicEquipmentWithProjectTimeOnlyMetricsDocument = gql`
  query queryBasicEquipmentWithProjectTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithProjectTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithProjectTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithProjectTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithProjectTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithProjectTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectTimeOnlyMetricsDocument, options);
}
export type QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectTimeOnlyMetricsQuery
>;
export type QueryBasicEquipmentWithProjectTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectTimeOnlyMetricsLazyQuery
>;
export type QueryBasicEquipmentWithProjectTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectTimeOnlyMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithProjectTimeOnlyMetricsQuery,
  QueryBasicEquipmentWithProjectTimeOnlyMetricsQueryVariables
>;
export const QueryBasicEquipmentWithProjectAndCostCodeMetricsDocument = gql`
  query queryBasicEquipmentWithProjectAndCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        equipmentCents
        descEquipmentCents
        ...ProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithProjectAndCostCodeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithProjectAndCostCodeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithProjectAndCostCodeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithProjectAndCostCodeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithProjectAndCostCodeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectAndCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectAndCostCodeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectAndCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectAndCostCodeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectAndCostCodeMetricsDocument, options);
}
export type QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectAndCostCodeMetricsQuery
>;
export type QueryBasicEquipmentWithProjectAndCostCodeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectAndCostCodeMetricsLazyQuery
>;
export type QueryBasicEquipmentWithProjectAndCostCodeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectAndCostCodeMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithProjectAndCostCodeMetricsQuery,
  QueryBasicEquipmentWithProjectAndCostCodeMetricsQueryVariables
>;
export const QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsDocument = gql`
  query queryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        equipmentCents
        descEquipmentCents
        ...BasicProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
  > &
    (
      | { variables: QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsDocument, options);
}
export type QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery
>;
export type QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsLazyQuery
>;
export type QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQuery,
  QueryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetricsQueryVariables
>;
export const QueryBasicEquipmentWithCostCodeMetricsDocument = gql`
  query queryBasicEquipmentWithCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...LaborMetrics
        equipmentCents
        costCodeId
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithCostCodeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithCostCodeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithCostCodeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithCostCodeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithCostCodeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithCostCodeMetricsQuery,
    QueryBasicEquipmentWithCostCodeMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithCostCodeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithCostCodeMetricsQuery,
    QueryBasicEquipmentWithCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithCostCodeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithCostCodeMetricsQuery,
    QueryBasicEquipmentWithCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithCostCodeMetricsQuery,
    QueryBasicEquipmentWithCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithCostCodeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithCostCodeMetricsQuery,
    QueryBasicEquipmentWithCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithCostCodeMetricsQuery,
    QueryBasicEquipmentWithCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithCostCodeMetricsDocument, options);
}
export type QueryBasicEquipmentWithCostCodeMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCostCodeMetricsQuery
>;
export type QueryBasicEquipmentWithCostCodeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCostCodeMetricsLazyQuery
>;
export type QueryBasicEquipmentWithCostCodeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCostCodeMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithCostCodeMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithCostCodeMetricsQuery,
  QueryBasicEquipmentWithCostCodeMetricsQueryVariables
>;
export const QueryBasicEquipmentWithCostCodeTimeOnlyMetricsDocument = gql`
  query queryBasicEquipmentWithCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...BasicLaborMetrics
        equipmentCents
        costCodeId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithCostCodeTimeOnlyMetricsDocument, options);
}
export type QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery
>;
export type QueryBasicEquipmentWithCostCodeTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsLazyQuery
>;
export type QueryBasicEquipmentWithCostCodeTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithCostCodeTimeOnlyMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQuery,
  QueryBasicEquipmentWithCostCodeTimeOnlyMetricsQueryVariables
>;
export const QueryBasicEquipmentWithMetricsDocument = gql`
  query queryBasicEquipmentWithMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicEquipmentLaborMetrics
        equipmentCents
      }
    }
  }
  ${BasicEquipmentLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithMetricsQuery,
    QueryBasicEquipmentWithMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBasicEquipmentWithMetricsQuery, QueryBasicEquipmentWithMetricsQueryVariables>(
    QueryBasicEquipmentWithMetricsDocument,
    options
  );
}
export function useQueryBasicEquipmentWithMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithMetricsQuery,
    QueryBasicEquipmentWithMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBasicEquipmentWithMetricsQuery, QueryBasicEquipmentWithMetricsQueryVariables>(
    QueryBasicEquipmentWithMetricsDocument,
    options
  );
}
export function useQueryBasicEquipmentWithMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithMetricsQuery,
    QueryBasicEquipmentWithMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBasicEquipmentWithMetricsQuery, QueryBasicEquipmentWithMetricsQueryVariables>(
    QueryBasicEquipmentWithMetricsDocument,
    options
  );
}
export type QueryBasicEquipmentWithMetricsQueryHookResult = ReturnType<typeof useQueryBasicEquipmentWithMetricsQuery>;
export type QueryBasicEquipmentWithMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMetricsLazyQuery
>;
export type QueryBasicEquipmentWithMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithMetricsQuery,
  QueryBasicEquipmentWithMetricsQueryVariables
>;
export const QueryBasicEquipmentWithTimeMetricsDocument = gql`
  query queryBasicEquipmentWithTimeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicLaborMetrics
        equipmentCents
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithTimeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithTimeMetricsQuery,
    QueryBasicEquipmentWithTimeMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBasicEquipmentWithTimeMetricsQuery, QueryBasicEquipmentWithTimeMetricsQueryVariables>(
    QueryBasicEquipmentWithTimeMetricsDocument,
    options
  );
}
export function useQueryBasicEquipmentWithTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithTimeMetricsQuery,
    QueryBasicEquipmentWithTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBasicEquipmentWithTimeMetricsQuery, QueryBasicEquipmentWithTimeMetricsQueryVariables>(
    QueryBasicEquipmentWithTimeMetricsDocument,
    options
  );
}
export function useQueryBasicEquipmentWithTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithTimeMetricsQuery,
    QueryBasicEquipmentWithTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithTimeMetricsQuery,
    QueryBasicEquipmentWithTimeMetricsQueryVariables
  >(QueryBasicEquipmentWithTimeMetricsDocument, options);
}
export type QueryBasicEquipmentWithTimeMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithTimeMetricsQuery
>;
export type QueryBasicEquipmentWithTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithTimeMetricsLazyQuery
>;
export type QueryBasicEquipmentWithTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithTimeMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithTimeMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithTimeMetricsQuery,
  QueryBasicEquipmentWithTimeMetricsQueryVariables
>;
export const QueryBasicEquipmentWithMemberCostCodeMetricsDocument = gql`
  query queryBasicEquipmentWithMemberCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        equipmentCents
        memberId
        costCodeId
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithMemberCostCodeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithMemberCostCodeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithMemberCostCodeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithMemberCostCodeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithMemberCostCodeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberCostCodeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberCostCodeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberCostCodeMetricsDocument, options);
}
export type QueryBasicEquipmentWithMemberCostCodeMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberCostCodeMetricsQuery
>;
export type QueryBasicEquipmentWithMemberCostCodeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberCostCodeMetricsLazyQuery
>;
export type QueryBasicEquipmentWithMemberCostCodeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberCostCodeMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithMemberCostCodeMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithMemberCostCodeMetricsQuery,
  QueryBasicEquipmentWithMemberCostCodeMetricsQueryVariables
>;
export const QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsDocument = gql`
  query queryBasicEquipmentWithMemberCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        equipmentCents
        memberId
        costCodeId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
  > &
    (
      | { variables: QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsDocument, options);
}
export type QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery
>;
export type QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsLazyQuery
>;
export type QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQuery,
  QueryBasicEquipmentWithMemberCostCodeTimeOnlyMetricsQueryVariables
>;
export const QueryBasicEquipmentWithProjectCostCodeMetricsDocument = gql`
  query queryBasicEquipmentWithProjectCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        equipmentCents
        descEquipmentCents
        projectId
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithProjectCostCodeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithProjectCostCodeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithProjectCostCodeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithProjectCostCodeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithProjectCostCodeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectCostCodeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectCostCodeMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectCostCodeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectCostCodeMetricsDocument, options);
}
export type QueryBasicEquipmentWithProjectCostCodeMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectCostCodeMetricsQuery
>;
export type QueryBasicEquipmentWithProjectCostCodeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectCostCodeMetricsLazyQuery
>;
export type QueryBasicEquipmentWithProjectCostCodeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectCostCodeMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithProjectCostCodeMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithProjectCostCodeMetricsQuery,
  QueryBasicEquipmentWithProjectCostCodeMetricsQueryVariables
>;
export const QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsDocument = gql`
  query queryBasicEquipmentWithProjectCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        equipmentCents
        descEquipmentCents
        projectId
        costCodeId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
  > &
    (
      | { variables: QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsDocument, options);
}
export type QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery
>;
export type QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsLazyQuery
>;
export type QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQuery,
  QueryBasicEquipmentWithProjectCostCodeTimeOnlyMetricsQueryVariables
>;
export const QueryBasicEquipmentWithMemberProjectMetricsDocument = gql`
  query queryBasicEquipmentWithMemberProjectMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        projectId
        memberId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithMemberProjectMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithMemberProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithMemberProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithMemberProjectMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithMemberProjectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithMemberProjectMetricsQuery,
    QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
  > &
    ({ variables: QueryBasicEquipmentWithMemberProjectMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithMemberProjectMetricsQuery,
    QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberProjectMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberProjectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithMemberProjectMetricsQuery,
    QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithMemberProjectMetricsQuery,
    QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberProjectMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberProjectMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithMemberProjectMetricsQuery,
    QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithMemberProjectMetricsQuery,
    QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberProjectMetricsDocument, options);
}
export type QueryBasicEquipmentWithMemberProjectMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberProjectMetricsQuery
>;
export type QueryBasicEquipmentWithMemberProjectMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberProjectMetricsLazyQuery
>;
export type QueryBasicEquipmentWithMemberProjectMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberProjectMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithMemberProjectMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithMemberProjectMetricsQuery,
  QueryBasicEquipmentWithMemberProjectMetricsQueryVariables
>;
export const QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsDocument = gql`
  query queryBasicEquipmentWithMemberProjectTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        memberId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery__
 *
 * To run a query within a React component, call `useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      memberIds: // value for 'memberIds'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
  > &
    (
      | { variables: QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsDocument, options);
}
export function useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
    QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
  >(QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsDocument, options);
}
export type QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery
>;
export type QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsLazyQuery
>;
export type QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryBasicEquipmentWithMemberProjectTimeOnlyMetricsSuspenseQuery
>;
export type QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryResult = Apollo.QueryResult<
  QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQuery,
  QueryBasicEquipmentWithMemberProjectTimeOnlyMetricsQueryVariables
>;
export const QueryMembersWithTimeMetricOnlyDocument = gql`
  query QueryMembersWithTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicMemberLaborMetrics
      }
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithTimeMetricOnlyQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithTimeMetricOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithTimeMetricOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithTimeMetricOnlyQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersWithTimeMetricOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithTimeMetricOnlyQuery,
    QueryMembersWithTimeMetricOnlyQueryVariables
  > &
    ({ variables: QueryMembersWithTimeMetricOnlyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithTimeMetricOnlyQuery, QueryMembersWithTimeMetricOnlyQueryVariables>(
    QueryMembersWithTimeMetricOnlyDocument,
    options
  );
}
export function useQueryMembersWithTimeMetricOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithTimeMetricOnlyQuery,
    QueryMembersWithTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithTimeMetricOnlyQuery, QueryMembersWithTimeMetricOnlyQueryVariables>(
    QueryMembersWithTimeMetricOnlyDocument,
    options
  );
}
export function useQueryMembersWithTimeMetricOnlySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithTimeMetricOnlyQuery,
    QueryMembersWithTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithTimeMetricOnlyQuery, QueryMembersWithTimeMetricOnlyQueryVariables>(
    QueryMembersWithTimeMetricOnlyDocument,
    options
  );
}
export type QueryMembersWithTimeMetricOnlyQueryHookResult = ReturnType<typeof useQueryMembersWithTimeMetricOnlyQuery>;
export type QueryMembersWithTimeMetricOnlyLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithTimeMetricOnlyLazyQuery
>;
export type QueryMembersWithTimeMetricOnlySuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithTimeMetricOnlySuspenseQuery
>;
export type QueryMembersWithTimeMetricOnlyQueryResult = Apollo.QueryResult<
  QueryMembersWithTimeMetricOnlyQuery,
  QueryMembersWithTimeMetricOnlyQueryVariables
>;
export const QueryMembersWithMetricDocument = gql`
  query QueryMembersWithMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...MemberLaborMetrics
      }
    }
  }
  ${MemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersWithMetricQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMembersWithMetricQuery, QueryMembersWithMetricQueryVariables> &
    ({ variables: QueryMembersWithMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithMetricQuery, QueryMembersWithMetricQueryVariables>(
    QueryMembersWithMetricDocument,
    options
  );
}
export function useQueryMembersWithMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersWithMetricQuery, QueryMembersWithMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithMetricQuery, QueryMembersWithMetricQueryVariables>(
    QueryMembersWithMetricDocument,
    options
  );
}
export function useQueryMembersWithMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersWithMetricQuery, QueryMembersWithMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithMetricQuery, QueryMembersWithMetricQueryVariables>(
    QueryMembersWithMetricDocument,
    options
  );
}
export type QueryMembersWithMetricQueryHookResult = ReturnType<typeof useQueryMembersWithMetricQuery>;
export type QueryMembersWithMetricLazyQueryHookResult = ReturnType<typeof useQueryMembersWithMetricLazyQuery>;
export type QueryMembersWithMetricSuspenseQueryHookResult = ReturnType<typeof useQueryMembersWithMetricSuspenseQuery>;
export type QueryMembersWithMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithMetricQuery,
  QueryMembersWithMetricQueryVariables
>;
export const QueryMembersWithCostCodeMetricDocument = gql`
  query QueryMembersWithCostCodeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...LaborMetrics
        costCodeId
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithCostCodeMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithCostCodeMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithCostCodeMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithCostCodeMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryMembersWithCostCodeMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithCostCodeMetricQuery,
    QueryMembersWithCostCodeMetricQueryVariables
  > &
    ({ variables: QueryMembersWithCostCodeMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithCostCodeMetricQuery, QueryMembersWithCostCodeMetricQueryVariables>(
    QueryMembersWithCostCodeMetricDocument,
    options
  );
}
export function useQueryMembersWithCostCodeMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithCostCodeMetricQuery,
    QueryMembersWithCostCodeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithCostCodeMetricQuery, QueryMembersWithCostCodeMetricQueryVariables>(
    QueryMembersWithCostCodeMetricDocument,
    options
  );
}
export function useQueryMembersWithCostCodeMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithCostCodeMetricQuery,
    QueryMembersWithCostCodeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithCostCodeMetricQuery, QueryMembersWithCostCodeMetricQueryVariables>(
    QueryMembersWithCostCodeMetricDocument,
    options
  );
}
export type QueryMembersWithCostCodeMetricQueryHookResult = ReturnType<typeof useQueryMembersWithCostCodeMetricQuery>;
export type QueryMembersWithCostCodeMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeMetricLazyQuery
>;
export type QueryMembersWithCostCodeMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeMetricSuspenseQuery
>;
export type QueryMembersWithCostCodeMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithCostCodeMetricQuery,
  QueryMembersWithCostCodeMetricQueryVariables
>;
export const QueryMembersWithCostCodeTimeMetricOnlyDocument = gql`
  query QueryMembersWithCostCodeTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...BasicLaborMetrics
        costCodeId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithCostCodeTimeMetricOnlyQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithCostCodeTimeMetricOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithCostCodeTimeMetricOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithCostCodeTimeMetricOnlyQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryMembersWithCostCodeTimeMetricOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithCostCodeTimeMetricOnlyQuery,
    QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
  > &
    ({ variables: QueryMembersWithCostCodeTimeMetricOnlyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithCostCodeTimeMetricOnlyQuery,
    QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
  >(QueryMembersWithCostCodeTimeMetricOnlyDocument, options);
}
export function useQueryMembersWithCostCodeTimeMetricOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithCostCodeTimeMetricOnlyQuery,
    QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithCostCodeTimeMetricOnlyQuery,
    QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
  >(QueryMembersWithCostCodeTimeMetricOnlyDocument, options);
}
export function useQueryMembersWithCostCodeTimeMetricOnlySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithCostCodeTimeMetricOnlyQuery,
    QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithCostCodeTimeMetricOnlyQuery,
    QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
  >(QueryMembersWithCostCodeTimeMetricOnlyDocument, options);
}
export type QueryMembersWithCostCodeTimeMetricOnlyQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeTimeMetricOnlyQuery
>;
export type QueryMembersWithCostCodeTimeMetricOnlyLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeTimeMetricOnlyLazyQuery
>;
export type QueryMembersWithCostCodeTimeMetricOnlySuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeTimeMetricOnlySuspenseQuery
>;
export type QueryMembersWithCostCodeTimeMetricOnlyQueryResult = Apollo.QueryResult<
  QueryMembersWithCostCodeTimeMetricOnlyQuery,
  QueryMembersWithCostCodeTimeMetricOnlyQueryVariables
>;
export const QueryMembersWithCostCodeEquipmentMetricDocument = gql`
  query QueryMembersWithCostCodeEquipmentMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        equipmentIds: $equipmentIds
      ) {
        ...LaborMetrics
        costCodeId
        equipmentId
        equipmentCents
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithCostCodeEquipmentMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithCostCodeEquipmentMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithCostCodeEquipmentMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithCostCodeEquipmentMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryMembersWithCostCodeEquipmentMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithCostCodeEquipmentMetricQuery,
    QueryMembersWithCostCodeEquipmentMetricQueryVariables
  > &
    ({ variables: QueryMembersWithCostCodeEquipmentMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithCostCodeEquipmentMetricQuery,
    QueryMembersWithCostCodeEquipmentMetricQueryVariables
  >(QueryMembersWithCostCodeEquipmentMetricDocument, options);
}
export function useQueryMembersWithCostCodeEquipmentMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithCostCodeEquipmentMetricQuery,
    QueryMembersWithCostCodeEquipmentMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithCostCodeEquipmentMetricQuery,
    QueryMembersWithCostCodeEquipmentMetricQueryVariables
  >(QueryMembersWithCostCodeEquipmentMetricDocument, options);
}
export function useQueryMembersWithCostCodeEquipmentMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithCostCodeEquipmentMetricQuery,
    QueryMembersWithCostCodeEquipmentMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithCostCodeEquipmentMetricQuery,
    QueryMembersWithCostCodeEquipmentMetricQueryVariables
  >(QueryMembersWithCostCodeEquipmentMetricDocument, options);
}
export type QueryMembersWithCostCodeEquipmentMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeEquipmentMetricQuery
>;
export type QueryMembersWithCostCodeEquipmentMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeEquipmentMetricLazyQuery
>;
export type QueryMembersWithCostCodeEquipmentMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeEquipmentMetricSuspenseQuery
>;
export type QueryMembersWithCostCodeEquipmentMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithCostCodeEquipmentMetricQuery,
  QueryMembersWithCostCodeEquipmentMetricQueryVariables
>;
export const QueryMembersWithCostCodeEquipmentTimeMetricOnlyDocument = gql`
  query QueryMembersWithCostCodeEquipmentTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        equipmentIds: $equipmentIds
      ) {
        ...BasicLaborMetrics
        costCodeId
        equipmentId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      costCodeIds: // value for 'costCodeIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
  > &
    ({ variables: QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
  >(QueryMembersWithCostCodeEquipmentTimeMetricOnlyDocument, options);
}
export function useQueryMembersWithCostCodeEquipmentTimeMetricOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
  >(QueryMembersWithCostCodeEquipmentTimeMetricOnlyDocument, options);
}
export function useQueryMembersWithCostCodeEquipmentTimeMetricOnlySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
    QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
  >(QueryMembersWithCostCodeEquipmentTimeMetricOnlyDocument, options);
}
export type QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery
>;
export type QueryMembersWithCostCodeEquipmentTimeMetricOnlyLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeEquipmentTimeMetricOnlyLazyQuery
>;
export type QueryMembersWithCostCodeEquipmentTimeMetricOnlySuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithCostCodeEquipmentTimeMetricOnlySuspenseQuery
>;
export type QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryResult = Apollo.QueryResult<
  QueryMembersWithCostCodeEquipmentTimeMetricOnlyQuery,
  QueryMembersWithCostCodeEquipmentTimeMetricOnlyQueryVariables
>;
export const QueryMembersWithEquipmentMetricDocument = gql`
  query QueryMembersWithEquipmentMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...LaborMetrics
        equipmentId
        equipmentCents
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithEquipmentMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithEquipmentMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithEquipmentMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithEquipmentMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryMembersWithEquipmentMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithEquipmentMetricQuery,
    QueryMembersWithEquipmentMetricQueryVariables
  > &
    ({ variables: QueryMembersWithEquipmentMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithEquipmentMetricQuery, QueryMembersWithEquipmentMetricQueryVariables>(
    QueryMembersWithEquipmentMetricDocument,
    options
  );
}
export function useQueryMembersWithEquipmentMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithEquipmentMetricQuery,
    QueryMembersWithEquipmentMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithEquipmentMetricQuery, QueryMembersWithEquipmentMetricQueryVariables>(
    QueryMembersWithEquipmentMetricDocument,
    options
  );
}
export function useQueryMembersWithEquipmentMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithEquipmentMetricQuery,
    QueryMembersWithEquipmentMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithEquipmentMetricQuery, QueryMembersWithEquipmentMetricQueryVariables>(
    QueryMembersWithEquipmentMetricDocument,
    options
  );
}
export type QueryMembersWithEquipmentMetricQueryHookResult = ReturnType<typeof useQueryMembersWithEquipmentMetricQuery>;
export type QueryMembersWithEquipmentMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithEquipmentMetricLazyQuery
>;
export type QueryMembersWithEquipmentMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithEquipmentMetricSuspenseQuery
>;
export type QueryMembersWithEquipmentMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithEquipmentMetricQuery,
  QueryMembersWithEquipmentMetricQueryVariables
>;
export const QueryMembersWithEquipmentTimeMetricOnlyDocument = gql`
  query QueryMembersWithEquipmentTimeMetricOnly(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...BasicLaborMetrics
        equipmentId
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithEquipmentTimeMetricOnlyQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithEquipmentTimeMetricOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithEquipmentTimeMetricOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithEquipmentTimeMetricOnlyQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryMembersWithEquipmentTimeMetricOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithEquipmentTimeMetricOnlyQuery,
    QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
  > &
    ({ variables: QueryMembersWithEquipmentTimeMetricOnlyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithEquipmentTimeMetricOnlyQuery,
    QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
  >(QueryMembersWithEquipmentTimeMetricOnlyDocument, options);
}
export function useQueryMembersWithEquipmentTimeMetricOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithEquipmentTimeMetricOnlyQuery,
    QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithEquipmentTimeMetricOnlyQuery,
    QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
  >(QueryMembersWithEquipmentTimeMetricOnlyDocument, options);
}
export function useQueryMembersWithEquipmentTimeMetricOnlySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithEquipmentTimeMetricOnlyQuery,
    QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithEquipmentTimeMetricOnlyQuery,
    QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
  >(QueryMembersWithEquipmentTimeMetricOnlyDocument, options);
}
export type QueryMembersWithEquipmentTimeMetricOnlyQueryHookResult = ReturnType<
  typeof useQueryMembersWithEquipmentTimeMetricOnlyQuery
>;
export type QueryMembersWithEquipmentTimeMetricOnlyLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithEquipmentTimeMetricOnlyLazyQuery
>;
export type QueryMembersWithEquipmentTimeMetricOnlySuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithEquipmentTimeMetricOnlySuspenseQuery
>;
export type QueryMembersWithEquipmentTimeMetricOnlyQueryResult = Apollo.QueryResult<
  QueryMembersWithEquipmentTimeMetricOnlyQuery,
  QueryMembersWithEquipmentTimeMetricOnlyQueryVariables
>;
export const QueryMembersWithProjectMetricDocument = gql`
  query QueryMembersWithProjectMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectMetricQuery,
    QueryMembersWithProjectMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithProjectMetricQuery, QueryMembersWithProjectMetricQueryVariables>(
    QueryMembersWithProjectMetricDocument,
    options
  );
}
export function useQueryMembersWithProjectMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectMetricQuery,
    QueryMembersWithProjectMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithProjectMetricQuery, QueryMembersWithProjectMetricQueryVariables>(
    QueryMembersWithProjectMetricDocument,
    options
  );
}
export function useQueryMembersWithProjectMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectMetricQuery,
    QueryMembersWithProjectMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersWithProjectMetricQuery, QueryMembersWithProjectMetricQueryVariables>(
    QueryMembersWithProjectMetricDocument,
    options
  );
}
export type QueryMembersWithProjectMetricQueryHookResult = ReturnType<typeof useQueryMembersWithProjectMetricQuery>;
export type QueryMembersWithProjectMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectMetricLazyQuery
>;
export type QueryMembersWithProjectMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectMetricSuspenseQuery
>;
export type QueryMembersWithProjectMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectMetricQuery,
  QueryMembersWithProjectMetricQueryVariables
>;
export const QueryMembersWithProjectTimeMetricDocument = gql`
  query QueryMembersWithProjectTimeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectTimeMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectTimeMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectTimeMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectTimeMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectTimeMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectTimeMetricQuery,
    QueryMembersWithProjectTimeMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectTimeMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersWithProjectTimeMetricQuery, QueryMembersWithProjectTimeMetricQueryVariables>(
    QueryMembersWithProjectTimeMetricDocument,
    options
  );
}
export function useQueryMembersWithProjectTimeMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectTimeMetricQuery,
    QueryMembersWithProjectTimeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersWithProjectTimeMetricQuery, QueryMembersWithProjectTimeMetricQueryVariables>(
    QueryMembersWithProjectTimeMetricDocument,
    options
  );
}
export function useQueryMembersWithProjectTimeMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectTimeMetricQuery,
    QueryMembersWithProjectTimeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithProjectTimeMetricQuery,
    QueryMembersWithProjectTimeMetricQueryVariables
  >(QueryMembersWithProjectTimeMetricDocument, options);
}
export type QueryMembersWithProjectTimeMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectTimeMetricQuery
>;
export type QueryMembersWithProjectTimeMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectTimeMetricLazyQuery
>;
export type QueryMembersWithProjectTimeMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectTimeMetricSuspenseQuery
>;
export type QueryMembersWithProjectTimeMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectTimeMetricQuery,
  QueryMembersWithProjectTimeMetricQueryVariables
>;
export const QueryMembersWithProjectCostCodeMetricDocument = gql`
  query QueryMembersWithProjectCostCodeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        ...ProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectCostCodeMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectCostCodeMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectCostCodeMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectCostCodeMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectCostCodeMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectCostCodeMetricQuery,
    QueryMembersWithProjectCostCodeMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectCostCodeMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithProjectCostCodeMetricQuery,
    QueryMembersWithProjectCostCodeMetricQueryVariables
  >(QueryMembersWithProjectCostCodeMetricDocument, options);
}
export function useQueryMembersWithProjectCostCodeMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectCostCodeMetricQuery,
    QueryMembersWithProjectCostCodeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithProjectCostCodeMetricQuery,
    QueryMembersWithProjectCostCodeMetricQueryVariables
  >(QueryMembersWithProjectCostCodeMetricDocument, options);
}
export function useQueryMembersWithProjectCostCodeMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectCostCodeMetricQuery,
    QueryMembersWithProjectCostCodeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithProjectCostCodeMetricQuery,
    QueryMembersWithProjectCostCodeMetricQueryVariables
  >(QueryMembersWithProjectCostCodeMetricDocument, options);
}
export type QueryMembersWithProjectCostCodeMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectCostCodeMetricQuery
>;
export type QueryMembersWithProjectCostCodeMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectCostCodeMetricLazyQuery
>;
export type QueryMembersWithProjectCostCodeMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectCostCodeMetricSuspenseQuery
>;
export type QueryMembersWithProjectCostCodeMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectCostCodeMetricQuery,
  QueryMembersWithProjectCostCodeMetricQueryVariables
>;
export const QueryMembersWithProjectCostCodeTimeMetricDocument = gql`
  query QueryMembersWithProjectCostCodeTimeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectCostCodeTimeMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectCostCodeTimeMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectCostCodeTimeMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectCostCodeTimeMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      costCodeIds: // value for 'costCodeIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectCostCodeTimeMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectCostCodeTimeMetricQuery,
    QueryMembersWithProjectCostCodeTimeMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectCostCodeTimeMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithProjectCostCodeTimeMetricQuery,
    QueryMembersWithProjectCostCodeTimeMetricQueryVariables
  >(QueryMembersWithProjectCostCodeTimeMetricDocument, options);
}
export function useQueryMembersWithProjectCostCodeTimeMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectCostCodeTimeMetricQuery,
    QueryMembersWithProjectCostCodeTimeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithProjectCostCodeTimeMetricQuery,
    QueryMembersWithProjectCostCodeTimeMetricQueryVariables
  >(QueryMembersWithProjectCostCodeTimeMetricDocument, options);
}
export function useQueryMembersWithProjectCostCodeTimeMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectCostCodeTimeMetricQuery,
    QueryMembersWithProjectCostCodeTimeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithProjectCostCodeTimeMetricQuery,
    QueryMembersWithProjectCostCodeTimeMetricQueryVariables
  >(QueryMembersWithProjectCostCodeTimeMetricDocument, options);
}
export type QueryMembersWithProjectCostCodeTimeMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectCostCodeTimeMetricQuery
>;
export type QueryMembersWithProjectCostCodeTimeMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectCostCodeTimeMetricLazyQuery
>;
export type QueryMembersWithProjectCostCodeTimeMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectCostCodeTimeMetricSuspenseQuery
>;
export type QueryMembersWithProjectCostCodeTimeMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectCostCodeTimeMetricQuery,
  QueryMembersWithProjectCostCodeTimeMetricQueryVariables
>;
export const QueryMembersWithProjectEquipmentMetricDocument = gql`
  query QueryMembersWithProjectEquipmentMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        ...ProjectLaborMetrics
        projectId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectEquipmentMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectEquipmentMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectEquipmentMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectEquipmentMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectEquipmentMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectEquipmentMetricQuery,
    QueryMembersWithProjectEquipmentMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectEquipmentMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithProjectEquipmentMetricQuery,
    QueryMembersWithProjectEquipmentMetricQueryVariables
  >(QueryMembersWithProjectEquipmentMetricDocument, options);
}
export function useQueryMembersWithProjectEquipmentMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectEquipmentMetricQuery,
    QueryMembersWithProjectEquipmentMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithProjectEquipmentMetricQuery,
    QueryMembersWithProjectEquipmentMetricQueryVariables
  >(QueryMembersWithProjectEquipmentMetricDocument, options);
}
export function useQueryMembersWithProjectEquipmentMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectEquipmentMetricQuery,
    QueryMembersWithProjectEquipmentMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithProjectEquipmentMetricQuery,
    QueryMembersWithProjectEquipmentMetricQueryVariables
  >(QueryMembersWithProjectEquipmentMetricDocument, options);
}
export type QueryMembersWithProjectEquipmentMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectEquipmentMetricQuery
>;
export type QueryMembersWithProjectEquipmentMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectEquipmentMetricLazyQuery
>;
export type QueryMembersWithProjectEquipmentMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectEquipmentMetricSuspenseQuery
>;
export type QueryMembersWithProjectEquipmentMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectEquipmentMetricQuery,
  QueryMembersWithProjectEquipmentMetricQueryVariables
>;
export const QueryMembersWithProjectEquipmentTimeMetricDocument = gql`
  query QueryMembersWithProjectEquipmentTimeMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersWithProjectEquipmentTimeMetricQuery__
 *
 * To run a query within a React component, call `useQueryMembersWithProjectEquipmentTimeMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersWithProjectEquipmentTimeMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersWithProjectEquipmentTimeMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *      projectIds: // value for 'projectIds'
 *      equipmentIds: // value for 'equipmentIds'
 *   },
 * });
 */
export function useQueryMembersWithProjectEquipmentTimeMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersWithProjectEquipmentTimeMetricQuery,
    QueryMembersWithProjectEquipmentTimeMetricQueryVariables
  > &
    ({ variables: QueryMembersWithProjectEquipmentTimeMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersWithProjectEquipmentTimeMetricQuery,
    QueryMembersWithProjectEquipmentTimeMetricQueryVariables
  >(QueryMembersWithProjectEquipmentTimeMetricDocument, options);
}
export function useQueryMembersWithProjectEquipmentTimeMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersWithProjectEquipmentTimeMetricQuery,
    QueryMembersWithProjectEquipmentTimeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersWithProjectEquipmentTimeMetricQuery,
    QueryMembersWithProjectEquipmentTimeMetricQueryVariables
  >(QueryMembersWithProjectEquipmentTimeMetricDocument, options);
}
export function useQueryMembersWithProjectEquipmentTimeMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersWithProjectEquipmentTimeMetricQuery,
    QueryMembersWithProjectEquipmentTimeMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersWithProjectEquipmentTimeMetricQuery,
    QueryMembersWithProjectEquipmentTimeMetricQueryVariables
  >(QueryMembersWithProjectEquipmentTimeMetricDocument, options);
}
export type QueryMembersWithProjectEquipmentTimeMetricQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectEquipmentTimeMetricQuery
>;
export type QueryMembersWithProjectEquipmentTimeMetricLazyQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectEquipmentTimeMetricLazyQuery
>;
export type QueryMembersWithProjectEquipmentTimeMetricSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersWithProjectEquipmentTimeMetricSuspenseQuery
>;
export type QueryMembersWithProjectEquipmentTimeMetricQueryResult = Apollo.QueryResult<
  QueryMembersWithProjectEquipmentTimeMetricQuery,
  QueryMembersWithProjectEquipmentTimeMetricQueryVariables
>;
export const ProjectWithTimeAndMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      costCodeId: // value for 'costCodeId'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMetricAndSubCheckQuery,
    ProjectWithTimeAndMetricAndSubCheckQueryVariables
  > &
    ({ variables: ProjectWithTimeAndMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectWithTimeAndMetricAndSubCheckQuery, ProjectWithTimeAndMetricAndSubCheckQueryVariables>(
    ProjectWithTimeAndMetricAndSubCheckDocument,
    options
  );
}
export function useProjectWithTimeAndMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMetricAndSubCheckQuery,
    ProjectWithTimeAndMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMetricAndSubCheckQuery,
    ProjectWithTimeAndMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMetricAndSubCheckQuery,
    ProjectWithTimeAndMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMetricAndSubCheckQuery,
    ProjectWithTimeAndMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMetricAndSubCheckQuery,
  ProjectWithTimeAndMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndTimeMetricAndSubCheck2Document = gql`
  query ProjectWithTimeAndTimeMetricAndSubCheck2(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndTimeMetricAndSubCheck2Query__
 *
 * To run a query within a React component, call `useProjectWithTimeAndTimeMetricAndSubCheck2Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndTimeMetricAndSubCheck2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndTimeMetricAndSubCheck2Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      costCodeId: // value for 'costCodeId'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndTimeMetricAndSubCheck2Query(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndTimeMetricAndSubCheck2Query,
    ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
  > &
    ({ variables: ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndTimeMetricAndSubCheck2Query,
    ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
  >(ProjectWithTimeAndTimeMetricAndSubCheck2Document, options);
}
export function useProjectWithTimeAndTimeMetricAndSubCheck2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndTimeMetricAndSubCheck2Query,
    ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndTimeMetricAndSubCheck2Query,
    ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
  >(ProjectWithTimeAndTimeMetricAndSubCheck2Document, options);
}
export function useProjectWithTimeAndTimeMetricAndSubCheck2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndTimeMetricAndSubCheck2Query,
    ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndTimeMetricAndSubCheck2Query,
    ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
  >(ProjectWithTimeAndTimeMetricAndSubCheck2Document, options);
}
export type ProjectWithTimeAndTimeMetricAndSubCheck2QueryHookResult = ReturnType<
  typeof useProjectWithTimeAndTimeMetricAndSubCheck2Query
>;
export type ProjectWithTimeAndTimeMetricAndSubCheck2LazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndTimeMetricAndSubCheck2LazyQuery
>;
export type ProjectWithTimeAndTimeMetricAndSubCheck2SuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndTimeMetricAndSubCheck2SuspenseQuery
>;
export type ProjectWithTimeAndTimeMetricAndSubCheck2QueryResult = Apollo.QueryResult<
  ProjectWithTimeAndTimeMetricAndSubCheck2Query,
  ProjectWithTimeAndTimeMetricAndSubCheck2QueryVariables
>;
export const ProjectWithTimeAndMemberMetricAndSubCheck3Document = gql`
  query ProjectWithTimeAndMemberMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        memberId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberMetricAndSubCheck3Query__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberMetricAndSubCheck3Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberMetricAndSubCheck3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberMetricAndSubCheck3Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberMetricAndSubCheck3Query(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
  > &
    ({ variables: ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndMemberMetricAndSubCheck3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndMemberMetricAndSubCheck3SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberMetricAndSubCheck3Document, options);
}
export type ProjectWithTimeAndMemberMetricAndSubCheck3QueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberMetricAndSubCheck3Query
>;
export type ProjectWithTimeAndMemberMetricAndSubCheck3LazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberMetricAndSubCheck3LazyQuery
>;
export type ProjectWithTimeAndMemberMetricAndSubCheck3SuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberMetricAndSubCheck3SuspenseQuery
>;
export type ProjectWithTimeAndMemberMetricAndSubCheck3QueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberMetricAndSubCheck3Query,
  ProjectWithTimeAndMemberMetricAndSubCheck3QueryVariables
>;
export const ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
  > &
    ({ variables: ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQuery,
  ProjectWithTimeAndMemberTimeOnlyMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckDocument = gql`
  query ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      ancestors {
        id
        title
        depth
        archivedOn
      }
      children {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
  >(ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckDocument, options);
}
export function useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
  >(ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckDocument, options);
}
export function useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
    ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
  >(ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckDocument, options);
}
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery
>;
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckLazyQuery
>;
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckSuspenseQuery
>;
export type ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQuery,
  ProjectWithTimeAndMemberTimeOnlyMetricAndSubAndAncestorsCheckQueryVariables
>;
export const ProjectWithTimeAndCostCodeMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndCostCodeMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndCostCodeMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndCostCodeMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndCostCodeMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndCostCodeMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      costCodeIds: // value for 'costCodeIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      costCodeId: // value for 'costCodeId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndCostCodeMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
  > &
    ({ variables: ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndCostCodeMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndCostCodeMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndCostCodeMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndCostCodeMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndCostCodeMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndCostCodeMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndCostCodeMetricAndSubCheckQuery,
  ProjectWithTimeAndCostCodeMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        costCodeId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      costCodeIds: // value for 'costCodeIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      costCodeId: // value for 'costCodeId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQuery,
  ProjectWithTimeAndCostCodeTimeOnlyMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckDocument = gql`
  query ProjectWithTimeOnlyAndEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        projectId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      equipmentIds: // value for 'equipmentIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
  > &
    ({ variables: ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
  >(ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
  >(ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
  >(ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery
>;
export type ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeOnlyAndEquipmentMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQuery,
  ProjectWithTimeOnlyAndEquipmentMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndEquipmentMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndEquipmentMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        equipmentId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndEquipmentMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndEquipmentMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndEquipmentMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndEquipmentMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      equipmentIds: // value for 'equipmentIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndEquipmentMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
  > &
    ({ variables: ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndEquipmentMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndEquipmentMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndEquipmentMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndEquipmentMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
    ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndEquipmentMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndEquipmentMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndEquipmentMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndEquipmentMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndEquipmentMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndEquipmentMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndEquipmentMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndEquipmentMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndEquipmentMetricAndSubCheckQuery,
  ProjectWithTimeAndEquipmentMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Document = gql`
  query ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        memberId
        costCodeId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      costCodeIds: // value for 'costCodeIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Document, options);
}
export type ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query
>;
export type ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3LazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3LazyQuery
>;
export type ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3SuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberCostCodeMetricAndSubCheck3SuspenseQuery
>;
export type ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3Query,
  ProjectWithTimeAndMemberCostCodeMetricAndSubCheck3QueryVariables
>;
export const ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
        costCodeId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      costCodeIds: // value for 'costCodeIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQuery,
  ProjectWithTimeAndMemberCostCodeTimeOnlyMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Document = gql`
  query ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        memberId
        equipmentId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      equipmentIds: // value for 'equipmentIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Document, options);
}
export type ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query
>;
export type ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3LazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3LazyQuery
>;
export type ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3SuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberEquipmentMetricAndSubCheck3SuspenseQuery
>;
export type ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3Query,
  ProjectWithTimeAndMemberEquipmentMetricAndSubCheck3QueryVariables
>;
export const ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $memberIds: [Uuid!]
    $equipmentIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        memberIds: $memberIds
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        memberId
        equipmentId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      memberIds: // value for 'memberIds'
 *      equipmentIds: // value for 'equipmentIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQuery,
  ProjectWithTimeAndMemberEquipmentTimeOnlyMetricAndSubCheckQueryVariables
>;
export const ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Document = gql`
  query ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        equipmentId
        costCodeId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query__
 *
 * To run a query within a React component, call `useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      equipmentIds: // value for 'equipmentIds'
 *      costCodeIds: // value for 'costCodeIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      costCodeId: // value for 'costCodeId'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Document, options);
}
export function useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
    ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
  >(ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Document, options);
}
export type ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query
>;
export type ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3LazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3LazyQuery
>;
export type ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3SuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3SuspenseQuery
>;
export type ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryResult = Apollo.QueryResult<
  ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3Query,
  ProjectWithTimeAndCostCodeEquipmentMetricAndSubCheck3QueryVariables
>;
export const ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckDocument = gql`
  query ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheck(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $costCodeIds: [Uuid!]
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      children {
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
      projectCostCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        ...BasicProjectLaborMetrics
        equipmentId
        costCodeId
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery__
 *
 * To run a query within a React component, call `useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      equipmentIds: // value for 'equipmentIds'
 *      costCodeIds: // value for 'costCodeIds'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      costCodeId: // value for 'costCodeId'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  > &
    (
      | { variables: ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckDocument, options);
}
export function useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
    ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
  >(ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckDocument, options);
}
export type ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery
>;
export type ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckLazyQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckLazyQuery
>;
export type ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckSuspenseQuery
>;
export type ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryResult = Apollo.QueryResult<
  ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQuery,
  ProjectWithTimeAndCostCodeEquipmentTimeOnlyMetricAndSubCheckQueryVariables
>;
export const AuthenticationDocument = gql`
  query Authentication($memberId: Uuid) {
    members(filter: { id: { equal: $memberId } }) {
      ...AuthMember
    }
  }
  ${AuthMemberFragmentDoc}
`;

/**
 * __useAuthenticationQuery__
 *
 * To run a query within a React component, call `useAuthenticationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticationQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useAuthenticationQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthenticationQuery, AuthenticationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthenticationQuery, AuthenticationQueryVariables>(AuthenticationDocument, options);
}
export function useAuthenticationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthenticationQuery, AuthenticationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthenticationQuery, AuthenticationQueryVariables>(AuthenticationDocument, options);
}
export function useAuthenticationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AuthenticationQuery, AuthenticationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthenticationQuery, AuthenticationQueryVariables>(AuthenticationDocument, options);
}
export type AuthenticationQueryHookResult = ReturnType<typeof useAuthenticationQuery>;
export type AuthenticationLazyQueryHookResult = ReturnType<typeof useAuthenticationLazyQuery>;
export type AuthenticationSuspenseQueryHookResult = ReturnType<typeof useAuthenticationSuspenseQuery>;
export type AuthenticationQueryResult = Apollo.QueryResult<AuthenticationQuery, AuthenticationQueryVariables>;
export const QueryBreakHoursMembersDocument = gql`
  query QueryBreakHoursMembers(
    $filter: MemberFilter
    $first: Int
    $after: String
    $sort: [MemberSort!]
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(filter: $filter, first: $first, after: $after, sort: $sort) {
      id
      firstName
      lastName
      memberGroupId
      cursor
      memberLock {
        id
        effectiveDate
      }
      memberTimeDocuments(filter: $memberTimeDocumentFilter, sort: $memberTimeDocumentSort) {
        id
        submittedOn
        canceled
        edited
      }
    }
  }
`;

/**
 * __useQueryBreakHoursMembersQuery__
 *
 * To run a query within a React component, call `useQueryBreakHoursMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBreakHoursMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBreakHoursMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryBreakHoursMembersQuery(
  baseOptions: Apollo.QueryHookOptions<QueryBreakHoursMembersQuery, QueryBreakHoursMembersQueryVariables> &
    ({ variables: QueryBreakHoursMembersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBreakHoursMembersQuery, QueryBreakHoursMembersQueryVariables>(
    QueryBreakHoursMembersDocument,
    options
  );
}
export function useQueryBreakHoursMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryBreakHoursMembersQuery, QueryBreakHoursMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBreakHoursMembersQuery, QueryBreakHoursMembersQueryVariables>(
    QueryBreakHoursMembersDocument,
    options
  );
}
export function useQueryBreakHoursMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryBreakHoursMembersQuery, QueryBreakHoursMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBreakHoursMembersQuery, QueryBreakHoursMembersQueryVariables>(
    QueryBreakHoursMembersDocument,
    options
  );
}
export type QueryBreakHoursMembersQueryHookResult = ReturnType<typeof useQueryBreakHoursMembersQuery>;
export type QueryBreakHoursMembersLazyQueryHookResult = ReturnType<typeof useQueryBreakHoursMembersLazyQuery>;
export type QueryBreakHoursMembersSuspenseQueryHookResult = ReturnType<typeof useQueryBreakHoursMembersSuspenseQuery>;
export type QueryBreakHoursMembersQueryResult = Apollo.QueryResult<
  QueryBreakHoursMembersQuery,
  QueryBreakHoursMembersQueryVariables
>;
export const QueryBreakHoursTimeEntriesDocument = gql`
  query QueryBreakHoursTimeEntries($filter: TimeEntryFilter, $first: Int, $after: String) {
    timeEntries(filter: $filter, first: $first, after: $after) {
      id
      costCodeId
      createdOn
      daylightSavingTime
      deletedOn
      description
      endDeviceTime
      endLocationId
      endTime
      endTimeTrusted
      equipmentId
      memberId
      metaDaylightSavingTime
      projectId
      startDeviceTime
      startLocationId
      startTime
      startTimeTrusted
      clientLogs {
        id
        createdOn
        deletedOn
      }
      breaks {
        id
        timeEntryId
        startTime
        endTime
        deletedOn
      }
      cursor
    }
  }
`;

/**
 * __useQueryBreakHoursTimeEntriesQuery__
 *
 * To run a query within a React component, call `useQueryBreakHoursTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBreakHoursTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBreakHoursTimeEntriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryBreakHoursTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryBreakHoursTimeEntriesQuery, QueryBreakHoursTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBreakHoursTimeEntriesQuery, QueryBreakHoursTimeEntriesQueryVariables>(
    QueryBreakHoursTimeEntriesDocument,
    options
  );
}
export function useQueryBreakHoursTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryBreakHoursTimeEntriesQuery, QueryBreakHoursTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBreakHoursTimeEntriesQuery, QueryBreakHoursTimeEntriesQueryVariables>(
    QueryBreakHoursTimeEntriesDocument,
    options
  );
}
export function useQueryBreakHoursTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBreakHoursTimeEntriesQuery,
    QueryBreakHoursTimeEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBreakHoursTimeEntriesQuery, QueryBreakHoursTimeEntriesQueryVariables>(
    QueryBreakHoursTimeEntriesDocument,
    options
  );
}
export type QueryBreakHoursTimeEntriesQueryHookResult = ReturnType<typeof useQueryBreakHoursTimeEntriesQuery>;
export type QueryBreakHoursTimeEntriesLazyQueryHookResult = ReturnType<typeof useQueryBreakHoursTimeEntriesLazyQuery>;
export type QueryBreakHoursTimeEntriesSuspenseQueryHookResult = ReturnType<
  typeof useQueryBreakHoursTimeEntriesSuspenseQuery
>;
export type QueryBreakHoursTimeEntriesQueryResult = Apollo.QueryResult<
  QueryBreakHoursTimeEntriesQuery,
  QueryBreakHoursTimeEntriesQueryVariables
>;
export const QueryBreakHoursSafetySignoffDocument = gql`
  query QueryBreakHoursSafetySignoff($filter: SafetySignatureFilter, $first: Int, $after: String) {
    safetySignatures(first: $first, filter: $filter, after: $after, sort: [{ createdOn: desc }]) {
      id
      memberId
      breakPolicyFollowed
      breakPolicyFollowedReason
      breakPolicyFollowedResolvedOn
      createdOn
      cursor
    }
  }
`;

/**
 * __useQueryBreakHoursSafetySignoffQuery__
 *
 * To run a query within a React component, call `useQueryBreakHoursSafetySignoffQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBreakHoursSafetySignoffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBreakHoursSafetySignoffQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryBreakHoursSafetySignoffQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryBreakHoursSafetySignoffQuery, QueryBreakHoursSafetySignoffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryBreakHoursSafetySignoffQuery, QueryBreakHoursSafetySignoffQueryVariables>(
    QueryBreakHoursSafetySignoffDocument,
    options
  );
}
export function useQueryBreakHoursSafetySignoffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryBreakHoursSafetySignoffQuery,
    QueryBreakHoursSafetySignoffQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryBreakHoursSafetySignoffQuery, QueryBreakHoursSafetySignoffQueryVariables>(
    QueryBreakHoursSafetySignoffDocument,
    options
  );
}
export function useQueryBreakHoursSafetySignoffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryBreakHoursSafetySignoffQuery,
    QueryBreakHoursSafetySignoffQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryBreakHoursSafetySignoffQuery, QueryBreakHoursSafetySignoffQueryVariables>(
    QueryBreakHoursSafetySignoffDocument,
    options
  );
}
export type QueryBreakHoursSafetySignoffQueryHookResult = ReturnType<typeof useQueryBreakHoursSafetySignoffQuery>;
export type QueryBreakHoursSafetySignoffLazyQueryHookResult = ReturnType<
  typeof useQueryBreakHoursSafetySignoffLazyQuery
>;
export type QueryBreakHoursSafetySignoffSuspenseQueryHookResult = ReturnType<
  typeof useQueryBreakHoursSafetySignoffSuspenseQuery
>;
export type QueryBreakHoursSafetySignoffQueryResult = Apollo.QueryResult<
  QueryBreakHoursSafetySignoffQuery,
  QueryBreakHoursSafetySignoffQueryVariables
>;
export const CreateBudgetCostDocument = gql`
  mutation CreateBudgetCost($budgetCost: BudgetCostCreateInput!) {
    createBudgetCost(budgetCost: $budgetCost) {
      id
    }
  }
`;
export type CreateBudgetCostMutationFn = Apollo.MutationFunction<
  CreateBudgetCostMutation,
  CreateBudgetCostMutationVariables
>;

/**
 * __useCreateBudgetCostMutation__
 *
 * To run a mutation, you first call `useCreateBudgetCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetCostMutation, { data, loading, error }] = useCreateBudgetCostMutation({
 *   variables: {
 *      budgetCost: // value for 'budgetCost'
 *   },
 * });
 */
export function useCreateBudgetCostMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBudgetCostMutation, CreateBudgetCostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBudgetCostMutation, CreateBudgetCostMutationVariables>(
    CreateBudgetCostDocument,
    options
  );
}
export type CreateBudgetCostMutationHookResult = ReturnType<typeof useCreateBudgetCostMutation>;
export type CreateBudgetCostMutationResult = Apollo.MutationResult<CreateBudgetCostMutation>;
export type CreateBudgetCostMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetCostMutation,
  CreateBudgetCostMutationVariables
>;
export const CreateBudgetHoursDocument = gql`
  mutation CreateBudgetHours($budgetHours: BudgetHoursCreateInput!) {
    createBudgetHours(budgetHours: $budgetHours) {
      id
    }
  }
`;
export type CreateBudgetHoursMutationFn = Apollo.MutationFunction<
  CreateBudgetHoursMutation,
  CreateBudgetHoursMutationVariables
>;

/**
 * __useCreateBudgetHoursMutation__
 *
 * To run a mutation, you first call `useCreateBudgetHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetHoursMutation, { data, loading, error }] = useCreateBudgetHoursMutation({
 *   variables: {
 *      budgetHours: // value for 'budgetHours'
 *   },
 * });
 */
export function useCreateBudgetHoursMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBudgetHoursMutation, CreateBudgetHoursMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBudgetHoursMutation, CreateBudgetHoursMutationVariables>(
    CreateBudgetHoursDocument,
    options
  );
}
export type CreateBudgetHoursMutationHookResult = ReturnType<typeof useCreateBudgetHoursMutation>;
export type CreateBudgetHoursMutationResult = Apollo.MutationResult<CreateBudgetHoursMutation>;
export type CreateBudgetHoursMutationOptions = Apollo.BaseMutationOptions<
  CreateBudgetHoursMutation,
  CreateBudgetHoursMutationVariables
>;
export const CreateProgressBudgetDocument = gql`
  mutation CreateProgressBudget($progressBudget: ProgressBudgetCreateInput!) {
    createProgressBudget(progressBudget: $progressBudget) {
      id
    }
  }
`;
export type CreateProgressBudgetMutationFn = Apollo.MutationFunction<
  CreateProgressBudgetMutation,
  CreateProgressBudgetMutationVariables
>;

/**
 * __useCreateProgressBudgetMutation__
 *
 * To run a mutation, you first call `useCreateProgressBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgressBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgressBudgetMutation, { data, loading, error }] = useCreateProgressBudgetMutation({
 *   variables: {
 *      progressBudget: // value for 'progressBudget'
 *   },
 * });
 */
export function useCreateProgressBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProgressBudgetMutation, CreateProgressBudgetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProgressBudgetMutation, CreateProgressBudgetMutationVariables>(
    CreateProgressBudgetDocument,
    options
  );
}
export type CreateProgressBudgetMutationHookResult = ReturnType<typeof useCreateProgressBudgetMutation>;
export type CreateProgressBudgetMutationResult = Apollo.MutationResult<CreateProgressBudgetMutation>;
export type CreateProgressBudgetMutationOptions = Apollo.BaseMutationOptions<
  CreateProgressBudgetMutation,
  CreateProgressBudgetMutationVariables
>;
export const GetProgressBudgetDocument = gql`
  query GetProgressBudget($filter: ProgressBudgetFilter, $first: Int, $after: String, $sort: [ProgressBudgetSort!]) {
    progressBudgets(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      quantity
      value
      projectId
      costCodeId
    }
  }
`;

/**
 * __useGetProgressBudgetQuery__
 *
 * To run a query within a React component, call `useGetProgressBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgressBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgressBudgetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProgressBudgetQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>(GetProgressBudgetDocument, options);
}
export function useGetProgressBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>(
    GetProgressBudgetDocument,
    options
  );
}
export function useGetProgressBudgetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>(
    GetProgressBudgetDocument,
    options
  );
}
export type GetProgressBudgetQueryHookResult = ReturnType<typeof useGetProgressBudgetQuery>;
export type GetProgressBudgetLazyQueryHookResult = ReturnType<typeof useGetProgressBudgetLazyQuery>;
export type GetProgressBudgetSuspenseQueryHookResult = ReturnType<typeof useGetProgressBudgetSuspenseQuery>;
export type GetProgressBudgetQueryResult = Apollo.QueryResult<GetProgressBudgetQuery, GetProgressBudgetQueryVariables>;
export const UpdateProgressBudgetDocument = gql`
  mutation UpdateProgressBudget($progressBudget: ProgressBudgetUpdateInput!) {
    updateProgressBudget(progressBudget: $progressBudget) {
      id
    }
  }
`;
export type UpdateProgressBudgetMutationFn = Apollo.MutationFunction<
  UpdateProgressBudgetMutation,
  UpdateProgressBudgetMutationVariables
>;

/**
 * __useUpdateProgressBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateProgressBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgressBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgressBudgetMutation, { data, loading, error }] = useUpdateProgressBudgetMutation({
 *   variables: {
 *      progressBudget: // value for 'progressBudget'
 *   },
 * });
 */
export function useUpdateProgressBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProgressBudgetMutation, UpdateProgressBudgetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProgressBudgetMutation, UpdateProgressBudgetMutationVariables>(
    UpdateProgressBudgetDocument,
    options
  );
}
export type UpdateProgressBudgetMutationHookResult = ReturnType<typeof useUpdateProgressBudgetMutation>;
export type UpdateProgressBudgetMutationResult = Apollo.MutationResult<UpdateProgressBudgetMutation>;
export type UpdateProgressBudgetMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgressBudgetMutation,
  UpdateProgressBudgetMutationVariables
>;
export const BudgetCostQueryDocument = gql`
  query budgetCostQuery($filter: BudgetCostFilter, $sort: [BudgetCostSort!], $first: Int, $after: String) {
    budgetCosts(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      projectId
      memberId
      costBudget
      costCodeId
      equipmentId
      cursor
      equipmentCostBudget
    }
  }
`;

/**
 * __useBudgetCostQueryQuery__
 *
 * To run a query within a React component, call `useBudgetCostQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetCostQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetCostQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBudgetCostQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>(BudgetCostQueryDocument, options);
}
export function useBudgetCostQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>(BudgetCostQueryDocument, options);
}
export function useBudgetCostQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>(BudgetCostQueryDocument, options);
}
export type BudgetCostQueryQueryHookResult = ReturnType<typeof useBudgetCostQueryQuery>;
export type BudgetCostQueryLazyQueryHookResult = ReturnType<typeof useBudgetCostQueryLazyQuery>;
export type BudgetCostQuerySuspenseQueryHookResult = ReturnType<typeof useBudgetCostQuerySuspenseQuery>;
export type BudgetCostQueryQueryResult = Apollo.QueryResult<BudgetCostQueryQuery, BudgetCostQueryQueryVariables>;
export const GetBudgetProjectReimportDocument = gql`
  query GetBudgetProjectReimport($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      archivedOn
      cursor
      ancestors {
        id
        title
        depth
      }
    }
  }
`;

/**
 * __useGetBudgetProjectReimportQuery__
 *
 * To run a query within a React component, call `useGetBudgetProjectReimportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetProjectReimportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetProjectReimportQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetBudgetProjectReimportQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBudgetProjectReimportQuery, GetBudgetProjectReimportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBudgetProjectReimportQuery, GetBudgetProjectReimportQueryVariables>(
    GetBudgetProjectReimportDocument,
    options
  );
}
export function useGetBudgetProjectReimportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetProjectReimportQuery, GetBudgetProjectReimportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBudgetProjectReimportQuery, GetBudgetProjectReimportQueryVariables>(
    GetBudgetProjectReimportDocument,
    options
  );
}
export function useGetBudgetProjectReimportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBudgetProjectReimportQuery, GetBudgetProjectReimportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBudgetProjectReimportQuery, GetBudgetProjectReimportQueryVariables>(
    GetBudgetProjectReimportDocument,
    options
  );
}
export type GetBudgetProjectReimportQueryHookResult = ReturnType<typeof useGetBudgetProjectReimportQuery>;
export type GetBudgetProjectReimportLazyQueryHookResult = ReturnType<typeof useGetBudgetProjectReimportLazyQuery>;
export type GetBudgetProjectReimportSuspenseQueryHookResult = ReturnType<
  typeof useGetBudgetProjectReimportSuspenseQuery
>;
export type GetBudgetProjectReimportQueryResult = Apollo.QueryResult<
  GetBudgetProjectReimportQuery,
  GetBudgetProjectReimportQueryVariables
>;
export const BudgetHoursQueryDocument = gql`
  query budgetHoursQuery($filter: BudgetHoursFilter, $sort: [BudgetHoursSort!], $first: Int, $after: String) {
    budgetHours(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      projectId
      memberId
      budgetSeconds
      costCodeId
      equipmentId
      createdOn
      cursor
      equipmentBudgetSeconds
    }
  }
`;

/**
 * __useBudgetHoursQueryQuery__
 *
 * To run a query within a React component, call `useBudgetHoursQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetHoursQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetHoursQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBudgetHoursQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>(BudgetHoursQueryDocument, options);
}
export function useBudgetHoursQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>(BudgetHoursQueryDocument, options);
}
export function useBudgetHoursQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>(
    BudgetHoursQueryDocument,
    options
  );
}
export type BudgetHoursQueryQueryHookResult = ReturnType<typeof useBudgetHoursQueryQuery>;
export type BudgetHoursQueryLazyQueryHookResult = ReturnType<typeof useBudgetHoursQueryLazyQuery>;
export type BudgetHoursQuerySuspenseQueryHookResult = ReturnType<typeof useBudgetHoursQuerySuspenseQuery>;
export type BudgetHoursQueryQueryResult = Apollo.QueryResult<BudgetHoursQueryQuery, BudgetHoursQueryQueryVariables>;
export const BudgetCostWithJoinedProjectDocument = gql`
  query BudgetCostWithJoinedProject($filter: BudgetCostFilter, $sort: [BudgetCostSort!], $first: Int, $after: String) {
    budgetCosts(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      projectId
      project {
        id
        rootProjectId
      }
      memberId
      costBudget
      costCodeId
      equipmentId
      cursor
      equipmentCostBudget
    }
  }
`;

/**
 * __useBudgetCostWithJoinedProjectQuery__
 *
 * To run a query within a React component, call `useBudgetCostWithJoinedProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetCostWithJoinedProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetCostWithJoinedProjectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBudgetCostWithJoinedProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetCostWithJoinedProjectQuery, BudgetCostWithJoinedProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetCostWithJoinedProjectQuery, BudgetCostWithJoinedProjectQueryVariables>(
    BudgetCostWithJoinedProjectDocument,
    options
  );
}
export function useBudgetCostWithJoinedProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BudgetCostWithJoinedProjectQuery, BudgetCostWithJoinedProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetCostWithJoinedProjectQuery, BudgetCostWithJoinedProjectQueryVariables>(
    BudgetCostWithJoinedProjectDocument,
    options
  );
}
export function useBudgetCostWithJoinedProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BudgetCostWithJoinedProjectQuery,
    BudgetCostWithJoinedProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetCostWithJoinedProjectQuery, BudgetCostWithJoinedProjectQueryVariables>(
    BudgetCostWithJoinedProjectDocument,
    options
  );
}
export type BudgetCostWithJoinedProjectQueryHookResult = ReturnType<typeof useBudgetCostWithJoinedProjectQuery>;
export type BudgetCostWithJoinedProjectLazyQueryHookResult = ReturnType<typeof useBudgetCostWithJoinedProjectLazyQuery>;
export type BudgetCostWithJoinedProjectSuspenseQueryHookResult = ReturnType<
  typeof useBudgetCostWithJoinedProjectSuspenseQuery
>;
export type BudgetCostWithJoinedProjectQueryResult = Apollo.QueryResult<
  BudgetCostWithJoinedProjectQuery,
  BudgetCostWithJoinedProjectQueryVariables
>;
export const BudgetHoursWithJoinedProjectDocument = gql`
  query BudgetHoursWithJoinedProject(
    $filter: BudgetHoursFilter
    $sort: [BudgetHoursSort!]
    $first: Int
    $after: String
  ) {
    budgetHours(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      projectId
      project {
        id
        rootProjectId
      }
      memberId
      budgetSeconds
      costCodeId
      equipmentId
      createdOn
      cursor
      equipmentBudgetSeconds
    }
  }
`;

/**
 * __useBudgetHoursWithJoinedProjectQuery__
 *
 * To run a query within a React component, call `useBudgetHoursWithJoinedProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetHoursWithJoinedProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetHoursWithJoinedProjectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBudgetHoursWithJoinedProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<BudgetHoursWithJoinedProjectQuery, BudgetHoursWithJoinedProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BudgetHoursWithJoinedProjectQuery, BudgetHoursWithJoinedProjectQueryVariables>(
    BudgetHoursWithJoinedProjectDocument,
    options
  );
}
export function useBudgetHoursWithJoinedProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetHoursWithJoinedProjectQuery,
    BudgetHoursWithJoinedProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BudgetHoursWithJoinedProjectQuery, BudgetHoursWithJoinedProjectQueryVariables>(
    BudgetHoursWithJoinedProjectDocument,
    options
  );
}
export function useBudgetHoursWithJoinedProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BudgetHoursWithJoinedProjectQuery,
    BudgetHoursWithJoinedProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BudgetHoursWithJoinedProjectQuery, BudgetHoursWithJoinedProjectQueryVariables>(
    BudgetHoursWithJoinedProjectDocument,
    options
  );
}
export type BudgetHoursWithJoinedProjectQueryHookResult = ReturnType<typeof useBudgetHoursWithJoinedProjectQuery>;
export type BudgetHoursWithJoinedProjectLazyQueryHookResult = ReturnType<
  typeof useBudgetHoursWithJoinedProjectLazyQuery
>;
export type BudgetHoursWithJoinedProjectSuspenseQueryHookResult = ReturnType<
  typeof useBudgetHoursWithJoinedProjectSuspenseQuery
>;
export type BudgetHoursWithJoinedProjectQueryResult = Apollo.QueryResult<
  BudgetHoursWithJoinedProjectQuery,
  BudgetHoursWithJoinedProjectQueryVariables
>;
export const BudgetPerformanceProjectCostCodeLaborMetricDocument = gql`
  query BudgetPerformanceProjectCostCodeLaborMetric(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
  ) {
    projectsWithTime(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
      includeAncestors: true
      includeOpenEntry: false
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      rootProjectId
      depth
      cursor
      projectCostCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        id
        projectId
        costCodeId
        descDt
        descDtCents
        descOt
        descOtCents
        descOverburdenRtCents
        descOverburdenOtCents
        descOverburdenDtCents
        descRt
        descRtCents
        dt
        dtCents
        end
        ot
        otCents
        overburdenRtCents
        overburdenOtCents
        overburdenDtCents
        rt
        rtCents
        start
      }
    }
  }
`;

/**
 * __useBudgetPerformanceProjectCostCodeLaborMetricQuery__
 *
 * To run a query within a React component, call `useBudgetPerformanceProjectCostCodeLaborMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetPerformanceProjectCostCodeLaborMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetPerformanceProjectCostCodeLaborMetricQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useBudgetPerformanceProjectCostCodeLaborMetricQuery(
  baseOptions: Apollo.QueryHookOptions<
    BudgetPerformanceProjectCostCodeLaborMetricQuery,
    BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
  > &
    ({ variables: BudgetPerformanceProjectCostCodeLaborMetricQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BudgetPerformanceProjectCostCodeLaborMetricQuery,
    BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
  >(BudgetPerformanceProjectCostCodeLaborMetricDocument, options);
}
export function useBudgetPerformanceProjectCostCodeLaborMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BudgetPerformanceProjectCostCodeLaborMetricQuery,
    BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BudgetPerformanceProjectCostCodeLaborMetricQuery,
    BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
  >(BudgetPerformanceProjectCostCodeLaborMetricDocument, options);
}
export function useBudgetPerformanceProjectCostCodeLaborMetricSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    BudgetPerformanceProjectCostCodeLaborMetricQuery,
    BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BudgetPerformanceProjectCostCodeLaborMetricQuery,
    BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
  >(BudgetPerformanceProjectCostCodeLaborMetricDocument, options);
}
export type BudgetPerformanceProjectCostCodeLaborMetricQueryHookResult = ReturnType<
  typeof useBudgetPerformanceProjectCostCodeLaborMetricQuery
>;
export type BudgetPerformanceProjectCostCodeLaborMetricLazyQueryHookResult = ReturnType<
  typeof useBudgetPerformanceProjectCostCodeLaborMetricLazyQuery
>;
export type BudgetPerformanceProjectCostCodeLaborMetricSuspenseQueryHookResult = ReturnType<
  typeof useBudgetPerformanceProjectCostCodeLaborMetricSuspenseQuery
>;
export type BudgetPerformanceProjectCostCodeLaborMetricQueryResult = Apollo.QueryResult<
  BudgetPerformanceProjectCostCodeLaborMetricQuery,
  BudgetPerformanceProjectCostCodeLaborMetricQueryVariables
>;
export const ProjectLaborMetricsWithTitleDocument = gql`
  query ProjectLaborMetricsWithTitle(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $startTime: Timestamp!
    $endTime: Timestamp!
  ) {
    projectsWithTime(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
      includeAncestors: true
      includeOpenEntry: false
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      ...BudgetPerformanceProject
    }
  }
  ${BudgetPerformanceProjectFragmentDoc}
`;

/**
 * __useProjectLaborMetricsWithTitleQuery__
 *
 * To run a query within a React component, call `useProjectLaborMetricsWithTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLaborMetricsWithTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLaborMetricsWithTitleQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useProjectLaborMetricsWithTitleQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectLaborMetricsWithTitleQuery, ProjectLaborMetricsWithTitleQueryVariables> &
    ({ variables: ProjectLaborMetricsWithTitleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectLaborMetricsWithTitleQuery, ProjectLaborMetricsWithTitleQueryVariables>(
    ProjectLaborMetricsWithTitleDocument,
    options
  );
}
export function useProjectLaborMetricsWithTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectLaborMetricsWithTitleQuery,
    ProjectLaborMetricsWithTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectLaborMetricsWithTitleQuery, ProjectLaborMetricsWithTitleQueryVariables>(
    ProjectLaborMetricsWithTitleDocument,
    options
  );
}
export function useProjectLaborMetricsWithTitleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectLaborMetricsWithTitleQuery,
    ProjectLaborMetricsWithTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectLaborMetricsWithTitleQuery, ProjectLaborMetricsWithTitleQueryVariables>(
    ProjectLaborMetricsWithTitleDocument,
    options
  );
}
export type ProjectLaborMetricsWithTitleQueryHookResult = ReturnType<typeof useProjectLaborMetricsWithTitleQuery>;
export type ProjectLaborMetricsWithTitleLazyQueryHookResult = ReturnType<
  typeof useProjectLaborMetricsWithTitleLazyQuery
>;
export type ProjectLaborMetricsWithTitleSuspenseQueryHookResult = ReturnType<
  typeof useProjectLaborMetricsWithTitleSuspenseQuery
>;
export type ProjectLaborMetricsWithTitleQueryResult = Apollo.QueryResult<
  ProjectLaborMetricsWithTitleQuery,
  ProjectLaborMetricsWithTitleQueryVariables
>;
export const ProjectLaborMetricsWithTitleSingleDocument = gql`
  query ProjectLaborMetricsWithTitleSingle($filter: ProjectFilter) {
    projects(first: 1, filter: $filter) {
      id
      ...BudgetPerformanceProject
    }
  }
  ${BudgetPerformanceProjectFragmentDoc}
`;

/**
 * __useProjectLaborMetricsWithTitleSingleQuery__
 *
 * To run a query within a React component, call `useProjectLaborMetricsWithTitleSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLaborMetricsWithTitleSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLaborMetricsWithTitleSingleQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectLaborMetricsWithTitleSingleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectLaborMetricsWithTitleSingleQuery,
    ProjectLaborMetricsWithTitleSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectLaborMetricsWithTitleSingleQuery, ProjectLaborMetricsWithTitleSingleQueryVariables>(
    ProjectLaborMetricsWithTitleSingleDocument,
    options
  );
}
export function useProjectLaborMetricsWithTitleSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectLaborMetricsWithTitleSingleQuery,
    ProjectLaborMetricsWithTitleSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectLaborMetricsWithTitleSingleQuery, ProjectLaborMetricsWithTitleSingleQueryVariables>(
    ProjectLaborMetricsWithTitleSingleDocument,
    options
  );
}
export function useProjectLaborMetricsWithTitleSingleSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectLaborMetricsWithTitleSingleQuery,
    ProjectLaborMetricsWithTitleSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectLaborMetricsWithTitleSingleQuery,
    ProjectLaborMetricsWithTitleSingleQueryVariables
  >(ProjectLaborMetricsWithTitleSingleDocument, options);
}
export type ProjectLaborMetricsWithTitleSingleQueryHookResult = ReturnType<
  typeof useProjectLaborMetricsWithTitleSingleQuery
>;
export type ProjectLaborMetricsWithTitleSingleLazyQueryHookResult = ReturnType<
  typeof useProjectLaborMetricsWithTitleSingleLazyQuery
>;
export type ProjectLaborMetricsWithTitleSingleSuspenseQueryHookResult = ReturnType<
  typeof useProjectLaborMetricsWithTitleSingleSuspenseQuery
>;
export type ProjectLaborMetricsWithTitleSingleQueryResult = Apollo.QueryResult<
  ProjectLaborMetricsWithTitleSingleQuery,
  ProjectLaborMetricsWithTitleSingleQueryVariables
>;
export const QueryForTotalMembersDocument = gql`
  query QueryForTotalMembers(
    $filter: MemberFilter
    $sort: [MemberSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    members(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      archivedOn
      createdOn
      cursor
    }
  }
`;

/**
 * __useQueryForTotalMembersQuery__
 *
 * To run a query within a React component, call `useQueryForTotalMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryForTotalMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryForTotalMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryForTotalMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryForTotalMembersQuery, QueryForTotalMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryForTotalMembersQuery, QueryForTotalMembersQueryVariables>(
    QueryForTotalMembersDocument,
    options
  );
}
export function useQueryForTotalMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryForTotalMembersQuery, QueryForTotalMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryForTotalMembersQuery, QueryForTotalMembersQueryVariables>(
    QueryForTotalMembersDocument,
    options
  );
}
export function useQueryForTotalMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryForTotalMembersQuery, QueryForTotalMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryForTotalMembersQuery, QueryForTotalMembersQueryVariables>(
    QueryForTotalMembersDocument,
    options
  );
}
export type QueryForTotalMembersQueryHookResult = ReturnType<typeof useQueryForTotalMembersQuery>;
export type QueryForTotalMembersLazyQueryHookResult = ReturnType<typeof useQueryForTotalMembersLazyQuery>;
export type QueryForTotalMembersSuspenseQueryHookResult = ReturnType<typeof useQueryForTotalMembersSuspenseQuery>;
export type QueryForTotalMembersQueryResult = Apollo.QueryResult<
  QueryForTotalMembersQuery,
  QueryForTotalMembersQueryVariables
>;
export const MyBulletinPostsDocument = gql`
  query MyBulletinPosts($filter: PostFilter, $sort: [PostSort!], $first: Int, $pageNumber: Int, $after: String) {
    myPosts(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      title
      message
      recipientType
      type
      allowComments
      acknowledgementRequired
      managementCanAccess
      postDate
      createdOn
      updatedOn
      deletedOn
      cursor
      project {
        id
        title
        ancestors {
          id
          title
          depth
        }
        depth
      }
      equipment {
        id
        equipmentName
      }
      position {
        id
        title
      }
      createdByMember {
        id
        firstName
        lastName
      }
      updatedByMember {
        id
        firstName
        lastName
      }
      comments {
        id
        comment
        createdByMember {
          id
          firstName
          lastName
        }
        createdOn
        deletedOn
      }
      recipients {
        id
        createdOn
        updatedOn
        deletedOn
        post {
          id
        }
        member {
          id
          firstName
          lastName
          imageUrl
          position {
            id
            title
          }
          archivedOn
        }
      }
      attachments {
        id
        title
        fileUrl
        fileType
        createdOn
        updatedOn
        deletedOn
        submittedOn
        uploadedByMember {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

/**
 * __useMyBulletinPostsQuery__
 *
 * To run a query within a React component, call `useMyBulletinPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBulletinPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBulletinPostsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMyBulletinPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>(MyBulletinPostsDocument, options);
}
export function useMyBulletinPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>(MyBulletinPostsDocument, options);
}
export function useMyBulletinPostsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>(MyBulletinPostsDocument, options);
}
export type MyBulletinPostsQueryHookResult = ReturnType<typeof useMyBulletinPostsQuery>;
export type MyBulletinPostsLazyQueryHookResult = ReturnType<typeof useMyBulletinPostsLazyQuery>;
export type MyBulletinPostsSuspenseQueryHookResult = ReturnType<typeof useMyBulletinPostsSuspenseQuery>;
export type MyBulletinPostsQueryResult = Apollo.QueryResult<MyBulletinPostsQuery, MyBulletinPostsQueryVariables>;
export const BulletinPostsDocument = gql`
  query BulletinPosts($filter: PostFilter, $sort: [PostSort!], $first: Int, $after: String) {
    posts(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      title
      message
      recipientType
      type
      allowComments
      acknowledgementRequired
      managementCanAccess
      postDate
      createdOn
      updatedOn
      deletedOn
      cursor
      project {
        id
        title
        ancestors {
          id
          title
          depth
        }
        depth
      }
      equipment {
        id
        equipmentName
      }
      position {
        id
        title
      }
      createdByMember {
        id
        firstName
        lastName
      }
      updatedByMember {
        id
        firstName
        lastName
      }
      comments {
        id
        comment
        createdByMember {
          id
          firstName
          lastName
        }
        createdOn
        deletedOn
      }
      recipients {
        id
        createdOn
        updatedOn
        deletedOn
        post {
          id
        }
        member {
          id
          firstName
          lastName
          imageUrl
          position {
            id
            title
          }
          archivedOn
        }
      }
      attachments {
        id
        title
        fileUrl
        fileType
        createdOn
        updatedOn
        deletedOn
        submittedOn
        uploadedByMember {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;

/**
 * __useBulletinPostsQuery__
 *
 * To run a query within a React component, call `useBulletinPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinPostsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBulletinPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<BulletinPostsQuery, BulletinPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BulletinPostsQuery, BulletinPostsQueryVariables>(BulletinPostsDocument, options);
}
export function useBulletinPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BulletinPostsQuery, BulletinPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BulletinPostsQuery, BulletinPostsQueryVariables>(BulletinPostsDocument, options);
}
export function useBulletinPostsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BulletinPostsQuery, BulletinPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BulletinPostsQuery, BulletinPostsQueryVariables>(BulletinPostsDocument, options);
}
export type BulletinPostsQueryHookResult = ReturnType<typeof useBulletinPostsQuery>;
export type BulletinPostsLazyQueryHookResult = ReturnType<typeof useBulletinPostsLazyQuery>;
export type BulletinPostsSuspenseQueryHookResult = ReturnType<typeof useBulletinPostsSuspenseQuery>;
export type BulletinPostsQueryResult = Apollo.QueryResult<BulletinPostsQuery, BulletinPostsQueryVariables>;
export const BulletinPostAttachmentDocument = gql`
  query BulletinPostAttachment(
    $filter: PostAttachmentFilter
    $sort: [PostAttachmentSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    postAttachments(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      title
      fileUrl
      fileType
      createdOn
      updatedOn
      deletedOn
      submittedOn
      cursor
      uploadedByMember {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

/**
 * __useBulletinPostAttachmentQuery__
 *
 * To run a query within a React component, call `useBulletinPostAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinPostAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinPostAttachmentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBulletinPostAttachmentQuery(
  baseOptions?: Apollo.QueryHookOptions<BulletinPostAttachmentQuery, BulletinPostAttachmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BulletinPostAttachmentQuery, BulletinPostAttachmentQueryVariables>(
    BulletinPostAttachmentDocument,
    options
  );
}
export function useBulletinPostAttachmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BulletinPostAttachmentQuery, BulletinPostAttachmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BulletinPostAttachmentQuery, BulletinPostAttachmentQueryVariables>(
    BulletinPostAttachmentDocument,
    options
  );
}
export function useBulletinPostAttachmentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BulletinPostAttachmentQuery, BulletinPostAttachmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BulletinPostAttachmentQuery, BulletinPostAttachmentQueryVariables>(
    BulletinPostAttachmentDocument,
    options
  );
}
export type BulletinPostAttachmentQueryHookResult = ReturnType<typeof useBulletinPostAttachmentQuery>;
export type BulletinPostAttachmentLazyQueryHookResult = ReturnType<typeof useBulletinPostAttachmentLazyQuery>;
export type BulletinPostAttachmentSuspenseQueryHookResult = ReturnType<typeof useBulletinPostAttachmentSuspenseQuery>;
export type BulletinPostAttachmentQueryResult = Apollo.QueryResult<
  BulletinPostAttachmentQuery,
  BulletinPostAttachmentQueryVariables
>;
export const QueryPostCommentsDocument = gql`
  query QueryPostComments(
    $filter: PostCommentFilter
    $sort: [PostCommentSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    postComments(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      comment
      createdOn
      updatedOn
      deletedOn
      cursor
      createdByMember {
        id
        firstName
        lastName
        imageUrl
      }
      post {
        id
        title
        message
      }
    }
  }
`;

/**
 * __useQueryPostCommentsQuery__
 *
 * To run a query within a React component, call `useQueryPostCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPostCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPostCommentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryPostCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>(QueryPostCommentsDocument, options);
}
export function useQueryPostCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>(
    QueryPostCommentsDocument,
    options
  );
}
export function useQueryPostCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>(
    QueryPostCommentsDocument,
    options
  );
}
export type QueryPostCommentsQueryHookResult = ReturnType<typeof useQueryPostCommentsQuery>;
export type QueryPostCommentsLazyQueryHookResult = ReturnType<typeof useQueryPostCommentsLazyQuery>;
export type QueryPostCommentsSuspenseQueryHookResult = ReturnType<typeof useQueryPostCommentsSuspenseQuery>;
export type QueryPostCommentsQueryResult = Apollo.QueryResult<QueryPostCommentsQuery, QueryPostCommentsQueryVariables>;
export const CreateBulletinPostsDocument = gql`
  mutation CreateBulletinPosts($post: PostCreateInput) {
    createPost(post: $post) {
      id
      title
      message
      recipientType
      type
      allowComments
      acknowledgementRequired
      managementCanAccess
      postDate
      createdOn
      updatedOn
      deletedOn
      project {
        id
        title
        ancestors {
          id
          title
          depth
        }
        depth
      }
      equipment {
        id
        equipmentName
      }
      position {
        id
        title
      }
      createdByMember {
        id
        firstName
        lastName
      }
      updatedByMember {
        id
        firstName
        lastName
      }
      comments {
        id
        comment
        createdByMember {
          id
          firstName
          lastName
        }
        createdOn
        deletedOn
      }
      recipients {
        id
        createdOn
        updatedOn
        deletedOn
        post {
          id
        }
        member {
          id
          firstName
          lastName
          imageUrl
          position {
            id
            title
          }
          archivedOn
        }
      }
      attachments {
        id
        title
        fileUrl
        fileType
        createdOn
        updatedOn
        deletedOn
        submittedOn
        uploadedByMember {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;
export type CreateBulletinPostsMutationFn = Apollo.MutationFunction<
  CreateBulletinPostsMutation,
  CreateBulletinPostsMutationVariables
>;

/**
 * __useCreateBulletinPostsMutation__
 *
 * To run a mutation, you first call `useCreateBulletinPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulletinPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulletinPostsMutation, { data, loading, error }] = useCreateBulletinPostsMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useCreateBulletinPostsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBulletinPostsMutation, CreateBulletinPostsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBulletinPostsMutation, CreateBulletinPostsMutationVariables>(
    CreateBulletinPostsDocument,
    options
  );
}
export type CreateBulletinPostsMutationHookResult = ReturnType<typeof useCreateBulletinPostsMutation>;
export type CreateBulletinPostsMutationResult = Apollo.MutationResult<CreateBulletinPostsMutation>;
export type CreateBulletinPostsMutationOptions = Apollo.BaseMutationOptions<
  CreateBulletinPostsMutation,
  CreateBulletinPostsMutationVariables
>;
export const CreatePostViewerDocument = gql`
  mutation CreatePostViewer($postViewer: PostViewerCreateInput) {
    createPostViewer(postViewer: $postViewer) {
      id
      acknowledged
      post {
        id
      }
      member {
        id
        firstName
        lastName
        imageUrl
      }
      latestCommentUpdatedOn
    }
  }
`;
export type CreatePostViewerMutationFn = Apollo.MutationFunction<
  CreatePostViewerMutation,
  CreatePostViewerMutationVariables
>;

/**
 * __useCreatePostViewerMutation__
 *
 * To run a mutation, you first call `useCreatePostViewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostViewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostViewerMutation, { data, loading, error }] = useCreatePostViewerMutation({
 *   variables: {
 *      postViewer: // value for 'postViewer'
 *   },
 * });
 */
export function useCreatePostViewerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePostViewerMutation, CreatePostViewerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostViewerMutation, CreatePostViewerMutationVariables>(
    CreatePostViewerDocument,
    options
  );
}
export type CreatePostViewerMutationHookResult = ReturnType<typeof useCreatePostViewerMutation>;
export type CreatePostViewerMutationResult = Apollo.MutationResult<CreatePostViewerMutation>;
export type CreatePostViewerMutationOptions = Apollo.BaseMutationOptions<
  CreatePostViewerMutation,
  CreatePostViewerMutationVariables
>;
export const CreateBulletinPostCommentDocument = gql`
  mutation createBulletinPostComment($postComment: PostCommentCreateInput) {
    createPostComment(postComment: $postComment) {
      id
      comment
      createdByMember {
        firstName
        lastName
        id
        imageUrl
      }
      post {
        id
        title
        message
      }
      createdOn
      updatedOn
      deletedOn
    }
  }
`;
export type CreateBulletinPostCommentMutationFn = Apollo.MutationFunction<
  CreateBulletinPostCommentMutation,
  CreateBulletinPostCommentMutationVariables
>;

/**
 * __useCreateBulletinPostCommentMutation__
 *
 * To run a mutation, you first call `useCreateBulletinPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulletinPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulletinPostCommentMutation, { data, loading, error }] = useCreateBulletinPostCommentMutation({
 *   variables: {
 *      postComment: // value for 'postComment'
 *   },
 * });
 */
export function useCreateBulletinPostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBulletinPostCommentMutation,
    CreateBulletinPostCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBulletinPostCommentMutation, CreateBulletinPostCommentMutationVariables>(
    CreateBulletinPostCommentDocument,
    options
  );
}
export type CreateBulletinPostCommentMutationHookResult = ReturnType<typeof useCreateBulletinPostCommentMutation>;
export type CreateBulletinPostCommentMutationResult = Apollo.MutationResult<CreateBulletinPostCommentMutation>;
export type CreateBulletinPostCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateBulletinPostCommentMutation,
  CreateBulletinPostCommentMutationVariables
>;
export const CreatePostRecipientDocument = gql`
  mutation CreatePostRecipient($postRecipient: PostRecipientCreateInput) {
    createPostRecipient(postRecipient: $postRecipient) {
      id
      createdOn
      updatedOn
      deletedOn
      post {
        id
      }
      member {
        id
        firstName
        lastName
        imageUrl
        position {
          id
          title
        }
      }
    }
  }
`;
export type CreatePostRecipientMutationFn = Apollo.MutationFunction<
  CreatePostRecipientMutation,
  CreatePostRecipientMutationVariables
>;

/**
 * __useCreatePostRecipientMutation__
 *
 * To run a mutation, you first call `useCreatePostRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostRecipientMutation, { data, loading, error }] = useCreatePostRecipientMutation({
 *   variables: {
 *      postRecipient: // value for 'postRecipient'
 *   },
 * });
 */
export function useCreatePostRecipientMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePostRecipientMutation, CreatePostRecipientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostRecipientMutation, CreatePostRecipientMutationVariables>(
    CreatePostRecipientDocument,
    options
  );
}
export type CreatePostRecipientMutationHookResult = ReturnType<typeof useCreatePostRecipientMutation>;
export type CreatePostRecipientMutationResult = Apollo.MutationResult<CreatePostRecipientMutation>;
export type CreatePostRecipientMutationOptions = Apollo.BaseMutationOptions<
  CreatePostRecipientMutation,
  CreatePostRecipientMutationVariables
>;
export const GetPostViewersDocument = gql`
  query GetPostViewers(
    $filter: PostViewerFilter
    $sort: [PostViewerSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    postViewers(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      acknowledged
      cursor
      post {
        id
      }
      member {
        id
        firstName
        lastName
        imageUrl
      }
      latestCommentUpdatedOn
    }
  }
`;

/**
 * __useGetPostViewersQuery__
 *
 * To run a query within a React component, call `useGetPostViewersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostViewersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostViewersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPostViewersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPostViewersQuery, GetPostViewersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPostViewersQuery, GetPostViewersQueryVariables>(GetPostViewersDocument, options);
}
export function useGetPostViewersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPostViewersQuery, GetPostViewersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPostViewersQuery, GetPostViewersQueryVariables>(GetPostViewersDocument, options);
}
export function useGetPostViewersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetPostViewersQuery, GetPostViewersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPostViewersQuery, GetPostViewersQueryVariables>(GetPostViewersDocument, options);
}
export type GetPostViewersQueryHookResult = ReturnType<typeof useGetPostViewersQuery>;
export type GetPostViewersLazyQueryHookResult = ReturnType<typeof useGetPostViewersLazyQuery>;
export type GetPostViewersSuspenseQueryHookResult = ReturnType<typeof useGetPostViewersSuspenseQuery>;
export type GetPostViewersQueryResult = Apollo.QueryResult<GetPostViewersQuery, GetPostViewersQueryVariables>;
export const QueryMembersForBulletinDocument = gql`
  query QueryMembersForBulletin(
    $filter: MemberFilter
    $sort: [MemberSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    members(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      firstName
      lastName
      imageUrl
      cursor
      archivedOn
    }
  }
`;

/**
 * __useQueryMembersForBulletinQuery__
 *
 * To run a query within a React component, call `useQueryMembersForBulletinQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersForBulletinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersForBulletinQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      pageNumber: // value for 'pageNumber'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryMembersForBulletinQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMembersForBulletinQuery, QueryMembersForBulletinQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersForBulletinQuery, QueryMembersForBulletinQueryVariables>(
    QueryMembersForBulletinDocument,
    options
  );
}
export function useQueryMembersForBulletinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersForBulletinQuery, QueryMembersForBulletinQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersForBulletinQuery, QueryMembersForBulletinQueryVariables>(
    QueryMembersForBulletinDocument,
    options
  );
}
export function useQueryMembersForBulletinSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersForBulletinQuery, QueryMembersForBulletinQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersForBulletinQuery, QueryMembersForBulletinQueryVariables>(
    QueryMembersForBulletinDocument,
    options
  );
}
export type QueryMembersForBulletinQueryHookResult = ReturnType<typeof useQueryMembersForBulletinQuery>;
export type QueryMembersForBulletinLazyQueryHookResult = ReturnType<typeof useQueryMembersForBulletinLazyQuery>;
export type QueryMembersForBulletinSuspenseQueryHookResult = ReturnType<typeof useQueryMembersForBulletinSuspenseQuery>;
export type QueryMembersForBulletinQueryResult = Apollo.QueryResult<
  QueryMembersForBulletinQuery,
  QueryMembersForBulletinQueryVariables
>;
export const UpdateBulletinPostDocument = gql`
  mutation UpdateBulletinPost($post: PostUpdateInput) {
    updatePost(post: $post) {
      id
      title
      message
      recipientType
      type
      allowComments
      acknowledgementRequired
      managementCanAccess
      postDate
      createdOn
      updatedOn
      deletedOn
      project {
        id
        title
        ancestors {
          id
          title
          depth
        }
        depth
      }
      equipment {
        id
        equipmentName
      }
      position {
        id
        title
      }
      createdByMember {
        id
        firstName
        lastName
      }
      updatedByMember {
        id
        firstName
        lastName
      }
      comments {
        id
        comment
        createdByMember {
          id
          firstName
          lastName
        }
        createdOn
        deletedOn
      }
      recipients {
        id
        createdOn
        updatedOn
        deletedOn
        post {
          id
        }
        member {
          id
          firstName
          lastName
          imageUrl
          position {
            id
            title
          }
          archivedOn
        }
      }
      attachments {
        id
        title
        fileUrl
        fileType
        createdOn
        updatedOn
        deletedOn
        submittedOn
        uploadedByMember {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`;
export type UpdateBulletinPostMutationFn = Apollo.MutationFunction<
  UpdateBulletinPostMutation,
  UpdateBulletinPostMutationVariables
>;

/**
 * __useUpdateBulletinPostMutation__
 *
 * To run a mutation, you first call `useUpdateBulletinPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulletinPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulletinPostMutation, { data, loading, error }] = useUpdateBulletinPostMutation({
 *   variables: {
 *      post: // value for 'post'
 *   },
 * });
 */
export function useUpdateBulletinPostMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBulletinPostMutation, UpdateBulletinPostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBulletinPostMutation, UpdateBulletinPostMutationVariables>(
    UpdateBulletinPostDocument,
    options
  );
}
export type UpdateBulletinPostMutationHookResult = ReturnType<typeof useUpdateBulletinPostMutation>;
export type UpdateBulletinPostMutationResult = Apollo.MutationResult<UpdateBulletinPostMutation>;
export type UpdateBulletinPostMutationOptions = Apollo.BaseMutationOptions<
  UpdateBulletinPostMutation,
  UpdateBulletinPostMutationVariables
>;
export const UpdatePostViewerDocument = gql`
  mutation UpdatePostViewer($postViewer: PostViewerUpdateInput) {
    updatePostViewer(postViewer: $postViewer) {
      id
      acknowledged
      post {
        id
      }
      member {
        id
        firstName
        lastName
        imageUrl
      }
      latestCommentUpdatedOn
    }
  }
`;
export type UpdatePostViewerMutationFn = Apollo.MutationFunction<
  UpdatePostViewerMutation,
  UpdatePostViewerMutationVariables
>;

/**
 * __useUpdatePostViewerMutation__
 *
 * To run a mutation, you first call `useUpdatePostViewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostViewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostViewerMutation, { data, loading, error }] = useUpdatePostViewerMutation({
 *   variables: {
 *      postViewer: // value for 'postViewer'
 *   },
 * });
 */
export function useUpdatePostViewerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostViewerMutation, UpdatePostViewerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostViewerMutation, UpdatePostViewerMutationVariables>(
    UpdatePostViewerDocument,
    options
  );
}
export type UpdatePostViewerMutationHookResult = ReturnType<typeof useUpdatePostViewerMutation>;
export type UpdatePostViewerMutationResult = Apollo.MutationResult<UpdatePostViewerMutation>;
export type UpdatePostViewerMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostViewerMutation,
  UpdatePostViewerMutationVariables
>;
export const UpdateBulletinPostCommentDocument = gql`
  mutation updateBulletinPostComment($postComment: PostCommentUpdateInput) {
    updatePostComment(postComment: $postComment) {
      id
      comment
      createdByMember {
        firstName
        lastName
        id
        imageUrl
      }
      post {
        id
        title
        message
      }
      createdOn
      updatedOn
      deletedOn
    }
  }
`;
export type UpdateBulletinPostCommentMutationFn = Apollo.MutationFunction<
  UpdateBulletinPostCommentMutation,
  UpdateBulletinPostCommentMutationVariables
>;

/**
 * __useUpdateBulletinPostCommentMutation__
 *
 * To run a mutation, you first call `useUpdateBulletinPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulletinPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulletinPostCommentMutation, { data, loading, error }] = useUpdateBulletinPostCommentMutation({
 *   variables: {
 *      postComment: // value for 'postComment'
 *   },
 * });
 */
export function useUpdateBulletinPostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBulletinPostCommentMutation,
    UpdateBulletinPostCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBulletinPostCommentMutation, UpdateBulletinPostCommentMutationVariables>(
    UpdateBulletinPostCommentDocument,
    options
  );
}
export type UpdateBulletinPostCommentMutationHookResult = ReturnType<typeof useUpdateBulletinPostCommentMutation>;
export type UpdateBulletinPostCommentMutationResult = Apollo.MutationResult<UpdateBulletinPostCommentMutation>;
export type UpdateBulletinPostCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateBulletinPostCommentMutation,
  UpdateBulletinPostCommentMutationVariables
>;
export const UpdatePostAttachmentDocument = gql`
  mutation updatePostAttachment($postAttachment: PostAttachmentUpdateInput) {
    updatePostAttachment(postAttachment: $postAttachment) {
      id
      title
      fileUrl
      fileType
      createdOn
      updatedOn
      deletedOn
      submittedOn
      uploadedByMember {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;
export type UpdatePostAttachmentMutationFn = Apollo.MutationFunction<
  UpdatePostAttachmentMutation,
  UpdatePostAttachmentMutationVariables
>;

/**
 * __useUpdatePostAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdatePostAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostAttachmentMutation, { data, loading, error }] = useUpdatePostAttachmentMutation({
 *   variables: {
 *      postAttachment: // value for 'postAttachment'
 *   },
 * });
 */
export function useUpdatePostAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostAttachmentMutation, UpdatePostAttachmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostAttachmentMutation, UpdatePostAttachmentMutationVariables>(
    UpdatePostAttachmentDocument,
    options
  );
}
export type UpdatePostAttachmentMutationHookResult = ReturnType<typeof useUpdatePostAttachmentMutation>;
export type UpdatePostAttachmentMutationResult = Apollo.MutationResult<UpdatePostAttachmentMutation>;
export type UpdatePostAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostAttachmentMutation,
  UpdatePostAttachmentMutationVariables
>;
export const UpdatePostRecipientDocument = gql`
  mutation UpdatePostRecipient($postRecipient: PostRecipientUpdateInput) {
    updatePostRecipient(postRecipient: $postRecipient) {
      id
      createdOn
      updatedOn
      deletedOn
      post {
        id
      }
      member {
        id
        firstName
        lastName
        imageUrl
        position {
          id
          title
        }
      }
    }
  }
`;
export type UpdatePostRecipientMutationFn = Apollo.MutationFunction<
  UpdatePostRecipientMutation,
  UpdatePostRecipientMutationVariables
>;

/**
 * __useUpdatePostRecipientMutation__
 *
 * To run a mutation, you first call `useUpdatePostRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostRecipientMutation, { data, loading, error }] = useUpdatePostRecipientMutation({
 *   variables: {
 *      postRecipient: // value for 'postRecipient'
 *   },
 * });
 */
export function useUpdatePostRecipientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostRecipientMutation, UpdatePostRecipientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostRecipientMutation, UpdatePostRecipientMutationVariables>(
    UpdatePostRecipientDocument,
    options
  );
}
export type UpdatePostRecipientMutationHookResult = ReturnType<typeof useUpdatePostRecipientMutation>;
export type UpdatePostRecipientMutationResult = Apollo.MutationResult<UpdatePostRecipientMutation>;
export type UpdatePostRecipientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostRecipientMutation,
  UpdatePostRecipientMutationVariables
>;
export const DashboardCostCodeDocument = gql`
  query DashboardCostCode($id: Uuid!) {
    costCodes(filter: { id: { equal: $id } }) {
      id
      costCode
      costCodeGroup {
        id
        groupName
      }
      costCodeGroupId
      title
      unitTitle
      createdOn
      updatedOn
      archivedOn
    }
  }
`;

/**
 * __useDashboardCostCodeQuery__
 *
 * To run a query within a React component, call `useDashboardCostCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCostCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCostCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDashboardCostCodeQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardCostCodeQuery, DashboardCostCodeQueryVariables> &
    ({ variables: DashboardCostCodeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardCostCodeQuery, DashboardCostCodeQueryVariables>(DashboardCostCodeDocument, options);
}
export function useDashboardCostCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardCostCodeQuery, DashboardCostCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardCostCodeQuery, DashboardCostCodeQueryVariables>(
    DashboardCostCodeDocument,
    options
  );
}
export function useDashboardCostCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardCostCodeQuery, DashboardCostCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardCostCodeQuery, DashboardCostCodeQueryVariables>(
    DashboardCostCodeDocument,
    options
  );
}
export type DashboardCostCodeQueryHookResult = ReturnType<typeof useDashboardCostCodeQuery>;
export type DashboardCostCodeLazyQueryHookResult = ReturnType<typeof useDashboardCostCodeLazyQuery>;
export type DashboardCostCodeSuspenseQueryHookResult = ReturnType<typeof useDashboardCostCodeSuspenseQuery>;
export type DashboardCostCodeQueryResult = Apollo.QueryResult<DashboardCostCodeQuery, DashboardCostCodeQueryVariables>;
export const QueryCostCodeSidePanelDetailsDocument = gql`
  query queryCostCodeSidePanelDetails($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      title
      costCode
      cursor
      createdOn
      updatedOn
      unitTitle
      costCodeGroup {
        id
        groupName
      }
    }
  }
`;

/**
 * __useQueryCostCodeSidePanelDetailsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodeSidePanelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodeSidePanelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodeSidePanelDetailsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryCostCodeSidePanelDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCostCodeSidePanelDetailsQuery, QueryCostCodeSidePanelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodeSidePanelDetailsQuery, QueryCostCodeSidePanelDetailsQueryVariables>(
    QueryCostCodeSidePanelDetailsDocument,
    options
  );
}
export function useQueryCostCodeSidePanelDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodeSidePanelDetailsQuery,
    QueryCostCodeSidePanelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodeSidePanelDetailsQuery, QueryCostCodeSidePanelDetailsQueryVariables>(
    QueryCostCodeSidePanelDetailsDocument,
    options
  );
}
export function useQueryCostCodeSidePanelDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodeSidePanelDetailsQuery,
    QueryCostCodeSidePanelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodeSidePanelDetailsQuery, QueryCostCodeSidePanelDetailsQueryVariables>(
    QueryCostCodeSidePanelDetailsDocument,
    options
  );
}
export type QueryCostCodeSidePanelDetailsQueryHookResult = ReturnType<typeof useQueryCostCodeSidePanelDetailsQuery>;
export type QueryCostCodeSidePanelDetailsLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodeSidePanelDetailsLazyQuery
>;
export type QueryCostCodeSidePanelDetailsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodeSidePanelDetailsSuspenseQuery
>;
export type QueryCostCodeSidePanelDetailsQueryResult = Apollo.QueryResult<
  QueryCostCodeSidePanelDetailsQuery,
  QueryCostCodeSidePanelDetailsQueryVariables
>;
export const DailyProjectReportJoinedDocument = gql`
  query dailyProjectReportJoined(
    $filter: DailyProjectReportFilter
    $first: Int
    $sort: [DailyProjectReportSort!]
    $after: String
  ) {
    dailyProjectReports(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      cursor
      projectId
      project {
        id
        title
        rootProjectId
        depth
        ancestors {
          id
          title
          depth
        }
        children {
          id
          title
          depth
        }
      }
      createdBy
      createdByMember {
        id
        firstName
        lastName
      }
      reportDate
      fileUrl
      template {
        id
        title
        deletedOn
      }
      createdOn
      deletedOn
    }
  }
`;

/**
 * __useDailyProjectReportJoinedQuery__
 *
 * To run a query within a React component, call `useDailyProjectReportJoinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyProjectReportJoinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyProjectReportJoinedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDailyProjectReportJoinedQuery(
  baseOptions?: Apollo.QueryHookOptions<DailyProjectReportJoinedQuery, DailyProjectReportJoinedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyProjectReportJoinedQuery, DailyProjectReportJoinedQueryVariables>(
    DailyProjectReportJoinedDocument,
    options
  );
}
export function useDailyProjectReportJoinedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyProjectReportJoinedQuery, DailyProjectReportJoinedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyProjectReportJoinedQuery, DailyProjectReportJoinedQueryVariables>(
    DailyProjectReportJoinedDocument,
    options
  );
}
export function useDailyProjectReportJoinedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailyProjectReportJoinedQuery, DailyProjectReportJoinedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyProjectReportJoinedQuery, DailyProjectReportJoinedQueryVariables>(
    DailyProjectReportJoinedDocument,
    options
  );
}
export type DailyProjectReportJoinedQueryHookResult = ReturnType<typeof useDailyProjectReportJoinedQuery>;
export type DailyProjectReportJoinedLazyQueryHookResult = ReturnType<typeof useDailyProjectReportJoinedLazyQuery>;
export type DailyProjectReportJoinedSuspenseQueryHookResult = ReturnType<
  typeof useDailyProjectReportJoinedSuspenseQuery
>;
export type DailyProjectReportJoinedQueryResult = Apollo.QueryResult<
  DailyProjectReportJoinedQuery,
  DailyProjectReportJoinedQueryVariables
>;
export const SimpleDailyProjectReportJoinedDocument = gql`
  query simpleDailyProjectReportJoined(
    $filter: DailyProjectReportFilter
    $first: Int
    $sort: [DailyProjectReportSort!]
    $after: String
  ) {
    dailyProjectReports(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      deletedOn
    }
  }
`;

/**
 * __useSimpleDailyProjectReportJoinedQuery__
 *
 * To run a query within a React component, call `useSimpleDailyProjectReportJoinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleDailyProjectReportJoinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleDailyProjectReportJoinedQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSimpleDailyProjectReportJoinedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SimpleDailyProjectReportJoinedQuery,
    SimpleDailyProjectReportJoinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleDailyProjectReportJoinedQuery, SimpleDailyProjectReportJoinedQueryVariables>(
    SimpleDailyProjectReportJoinedDocument,
    options
  );
}
export function useSimpleDailyProjectReportJoinedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleDailyProjectReportJoinedQuery,
    SimpleDailyProjectReportJoinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleDailyProjectReportJoinedQuery, SimpleDailyProjectReportJoinedQueryVariables>(
    SimpleDailyProjectReportJoinedDocument,
    options
  );
}
export function useSimpleDailyProjectReportJoinedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SimpleDailyProjectReportJoinedQuery,
    SimpleDailyProjectReportJoinedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleDailyProjectReportJoinedQuery, SimpleDailyProjectReportJoinedQueryVariables>(
    SimpleDailyProjectReportJoinedDocument,
    options
  );
}
export type SimpleDailyProjectReportJoinedQueryHookResult = ReturnType<typeof useSimpleDailyProjectReportJoinedQuery>;
export type SimpleDailyProjectReportJoinedLazyQueryHookResult = ReturnType<
  typeof useSimpleDailyProjectReportJoinedLazyQuery
>;
export type SimpleDailyProjectReportJoinedSuspenseQueryHookResult = ReturnType<
  typeof useSimpleDailyProjectReportJoinedSuspenseQuery
>;
export type SimpleDailyProjectReportJoinedQueryResult = Apollo.QueryResult<
  SimpleDailyProjectReportJoinedQuery,
  SimpleDailyProjectReportJoinedQueryVariables
>;
export const ProjectDocument = gql`
  query project($projectId: Uuid) {
    projects(
      filter: { id: { equal: $projectId }, archivedOn: { isNull: true } }
      first: 1
      sort: [{ createdOn: desc }]
    ) {
      id
      title
      projectInfo {
        address1
        address2
        city
        county
        country
        state
        phone
        postalCode
        number
        latitude
        longitude
      }
      children {
        id
        title
        archivedOn
      }
    }
  }
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectQuery(baseOptions?: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export function useProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectSuspenseQueryHookResult = ReturnType<typeof useProjectSuspenseQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const TimeEntryWithJoinedDocument = gql`
  query timeEntryWithJoined($projectId: Uuid, $startTime: Timestamp, $startTimeEnd: Timestamp) {
    timeEntries(
      filter: {
        projectId: { equal: $projectId }
        startTime: { greaterThanOrEqual: $startTime, lessThanOrEqual: $startTimeEnd }
        deletedOn: { isNull: true }
      }
    ) {
      id
      memberId
      member {
        id
        firstName
        lastName
      }
      startTime
      endTime
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
        }
      }
      costCode {
        id
        costCode
        title
      }
      breaks {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
      }
    }
  }
`;

/**
 * __useTimeEntryWithJoinedQuery__
 *
 * To run a query within a React component, call `useTimeEntryWithJoinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryWithJoinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryWithJoinedQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startTime: // value for 'startTime'
 *      startTimeEnd: // value for 'startTimeEnd'
 *   },
 * });
 */
export function useTimeEntryWithJoinedQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeEntryWithJoinedQuery, TimeEntryWithJoinedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryWithJoinedQuery, TimeEntryWithJoinedQueryVariables>(
    TimeEntryWithJoinedDocument,
    options
  );
}
export function useTimeEntryWithJoinedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryWithJoinedQuery, TimeEntryWithJoinedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryWithJoinedQuery, TimeEntryWithJoinedQueryVariables>(
    TimeEntryWithJoinedDocument,
    options
  );
}
export function useTimeEntryWithJoinedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryWithJoinedQuery, TimeEntryWithJoinedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryWithJoinedQuery, TimeEntryWithJoinedQueryVariables>(
    TimeEntryWithJoinedDocument,
    options
  );
}
export type TimeEntryWithJoinedQueryHookResult = ReturnType<typeof useTimeEntryWithJoinedQuery>;
export type TimeEntryWithJoinedLazyQueryHookResult = ReturnType<typeof useTimeEntryWithJoinedLazyQuery>;
export type TimeEntryWithJoinedSuspenseQueryHookResult = ReturnType<typeof useTimeEntryWithJoinedSuspenseQuery>;
export type TimeEntryWithJoinedQueryResult = Apollo.QueryResult<
  TimeEntryWithJoinedQuery,
  TimeEntryWithJoinedQueryVariables
>;
export const SafetySignatureDocument = gql`
  query SafetySignature($startTime: Timestamp, $startTimeEnd: Timestamp) {
    safetySignatures(
      filter: {
        startTime: { greaterThanOrEqual: $startTime, lessThanOrEqual: $startTimeEnd }
        deletedOn: { isNull: true }
      }
    ) {
      id
      startTime
      endTime
      injured
      injuryDescription
      timeAccurate
      timeAccurateReason
      memberId
      member {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useSafetySignatureQuery__
 *
 * To run a query within a React component, call `useSafetySignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useSafetySignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSafetySignatureQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      startTimeEnd: // value for 'startTimeEnd'
 *   },
 * });
 */
export function useSafetySignatureQuery(
  baseOptions?: Apollo.QueryHookOptions<SafetySignatureQuery, SafetySignatureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SafetySignatureQuery, SafetySignatureQueryVariables>(SafetySignatureDocument, options);
}
export function useSafetySignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SafetySignatureQuery, SafetySignatureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SafetySignatureQuery, SafetySignatureQueryVariables>(SafetySignatureDocument, options);
}
export function useSafetySignatureSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SafetySignatureQuery, SafetySignatureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SafetySignatureQuery, SafetySignatureQueryVariables>(SafetySignatureDocument, options);
}
export type SafetySignatureQueryHookResult = ReturnType<typeof useSafetySignatureQuery>;
export type SafetySignatureLazyQueryHookResult = ReturnType<typeof useSafetySignatureLazyQuery>;
export type SafetySignatureSuspenseQueryHookResult = ReturnType<typeof useSafetySignatureSuspenseQuery>;
export type SafetySignatureQueryResult = Apollo.QueryResult<SafetySignatureQuery, SafetySignatureQueryVariables>;
export const DailyProjectReportTemplateDocument = gql`
  query DailyProjectReportTemplate($filter: DailyProjectReportTemplateFilter) {
    dailyProjectReportTemplates(filter: $filter) {
      id
      positionId
      organizationId
      audienceType
      sections
      title
      appearance
      createdOn
      position {
        id
        title
      }
    }
  }
`;

/**
 * __useDailyProjectReportTemplateQuery__
 *
 * To run a query within a React component, call `useDailyProjectReportTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyProjectReportTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyProjectReportTemplateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDailyProjectReportTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<DailyProjectReportTemplateQuery, DailyProjectReportTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyProjectReportTemplateQuery, DailyProjectReportTemplateQueryVariables>(
    DailyProjectReportTemplateDocument,
    options
  );
}
export function useDailyProjectReportTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyProjectReportTemplateQuery, DailyProjectReportTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyProjectReportTemplateQuery, DailyProjectReportTemplateQueryVariables>(
    DailyProjectReportTemplateDocument,
    options
  );
}
export function useDailyProjectReportTemplateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DailyProjectReportTemplateQuery,
    DailyProjectReportTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyProjectReportTemplateQuery, DailyProjectReportTemplateQueryVariables>(
    DailyProjectReportTemplateDocument,
    options
  );
}
export type DailyProjectReportTemplateQueryHookResult = ReturnType<typeof useDailyProjectReportTemplateQuery>;
export type DailyProjectReportTemplateLazyQueryHookResult = ReturnType<typeof useDailyProjectReportTemplateLazyQuery>;
export type DailyProjectReportTemplateSuspenseQueryHookResult = ReturnType<
  typeof useDailyProjectReportTemplateSuspenseQuery
>;
export type DailyProjectReportTemplateQueryResult = Apollo.QueryResult<
  DailyProjectReportTemplateQuery,
  DailyProjectReportTemplateQueryVariables
>;
export const CreateDailyProjectReportTemplateDocument = gql`
  mutation CreateDailyProjectReportTemplate($dailyProjectReportTemplate: DailyProjectReportTemplateCreateInput!) {
    createDailyProjectReportTemplate(dailyProjectReportTemplate: $dailyProjectReportTemplate) {
      id
      audienceType
      title
      appearance
      sections
      createdOn
      updatedOn
      submittedOn
    }
  }
`;
export type CreateDailyProjectReportTemplateMutationFn = Apollo.MutationFunction<
  CreateDailyProjectReportTemplateMutation,
  CreateDailyProjectReportTemplateMutationVariables
>;

/**
 * __useCreateDailyProjectReportTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDailyProjectReportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDailyProjectReportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDailyProjectReportTemplateMutation, { data, loading, error }] = useCreateDailyProjectReportTemplateMutation({
 *   variables: {
 *      dailyProjectReportTemplate: // value for 'dailyProjectReportTemplate'
 *   },
 * });
 */
export function useCreateDailyProjectReportTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDailyProjectReportTemplateMutation,
    CreateDailyProjectReportTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDailyProjectReportTemplateMutation,
    CreateDailyProjectReportTemplateMutationVariables
  >(CreateDailyProjectReportTemplateDocument, options);
}
export type CreateDailyProjectReportTemplateMutationHookResult = ReturnType<
  typeof useCreateDailyProjectReportTemplateMutation
>;
export type CreateDailyProjectReportTemplateMutationResult =
  Apollo.MutationResult<CreateDailyProjectReportTemplateMutation>;
export type CreateDailyProjectReportTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateDailyProjectReportTemplateMutation,
  CreateDailyProjectReportTemplateMutationVariables
>;
export const UpdateDailyProjectReportTemplateDocument = gql`
  mutation UpdateDailyProjectReportTemplate($dailyProjectReportTemplate: DailyProjectReportTemplateUpdateInput!) {
    updateDailyProjectReportTemplate(dailyProjectReportTemplate: $dailyProjectReportTemplate) {
      id
      audienceType
      title
      appearance
      sections
      createdOn
      updatedOn
      submittedOn
      deletedOn
    }
  }
`;
export type UpdateDailyProjectReportTemplateMutationFn = Apollo.MutationFunction<
  UpdateDailyProjectReportTemplateMutation,
  UpdateDailyProjectReportTemplateMutationVariables
>;

/**
 * __useUpdateDailyProjectReportTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDailyProjectReportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyProjectReportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyProjectReportTemplateMutation, { data, loading, error }] = useUpdateDailyProjectReportTemplateMutation({
 *   variables: {
 *      dailyProjectReportTemplate: // value for 'dailyProjectReportTemplate'
 *   },
 * });
 */
export function useUpdateDailyProjectReportTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDailyProjectReportTemplateMutation,
    UpdateDailyProjectReportTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDailyProjectReportTemplateMutation,
    UpdateDailyProjectReportTemplateMutationVariables
  >(UpdateDailyProjectReportTemplateDocument, options);
}
export type UpdateDailyProjectReportTemplateMutationHookResult = ReturnType<
  typeof useUpdateDailyProjectReportTemplateMutation
>;
export type UpdateDailyProjectReportTemplateMutationResult =
  Apollo.MutationResult<UpdateDailyProjectReportTemplateMutation>;
export type UpdateDailyProjectReportTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateDailyProjectReportTemplateMutation,
  UpdateDailyProjectReportTemplateMutationVariables
>;
export const EquipmentHoursDocument = gql`
  query equipmentHours($filter: EquipmentHoursFilter, $first: Int, $after: String) {
    equipmentHours(first: $first, after: $after, filter: $filter) {
      id
      equipmentId
      createdOn
      cursor
    }
  }
`;

/**
 * __useEquipmentHoursQuery__
 *
 * To run a query within a React component, call `useEquipmentHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentHoursQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEquipmentHoursQuery(
  baseOptions?: Apollo.QueryHookOptions<EquipmentHoursQuery, EquipmentHoursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentHoursQuery, EquipmentHoursQueryVariables>(EquipmentHoursDocument, options);
}
export function useEquipmentHoursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EquipmentHoursQuery, EquipmentHoursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentHoursQuery, EquipmentHoursQueryVariables>(EquipmentHoursDocument, options);
}
export function useEquipmentHoursSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<EquipmentHoursQuery, EquipmentHoursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentHoursQuery, EquipmentHoursQueryVariables>(EquipmentHoursDocument, options);
}
export type EquipmentHoursQueryHookResult = ReturnType<typeof useEquipmentHoursQuery>;
export type EquipmentHoursLazyQueryHookResult = ReturnType<typeof useEquipmentHoursLazyQuery>;
export type EquipmentHoursSuspenseQueryHookResult = ReturnType<typeof useEquipmentHoursSuspenseQuery>;
export type EquipmentHoursQueryResult = Apollo.QueryResult<EquipmentHoursQuery, EquipmentHoursQueryVariables>;
export const EquipmentEquipmentHoursMetricsDocument = gql`
  query EquipmentEquipmentHoursMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(after: $after, first: $first, filter: $filter) {
      id
      cursor
      equipmentHourMeterMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        start
        end
        hoursDiff
      }
    }
  }
`;

/**
 * __useEquipmentEquipmentHoursMetricsQuery__
 *
 * To run a query within a React component, call `useEquipmentEquipmentHoursMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentEquipmentHoursMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentEquipmentHoursMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useEquipmentEquipmentHoursMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EquipmentEquipmentHoursMetricsQuery,
    EquipmentEquipmentHoursMetricsQueryVariables
  > &
    ({ variables: EquipmentEquipmentHoursMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EquipmentEquipmentHoursMetricsQuery, EquipmentEquipmentHoursMetricsQueryVariables>(
    EquipmentEquipmentHoursMetricsDocument,
    options
  );
}
export function useEquipmentEquipmentHoursMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EquipmentEquipmentHoursMetricsQuery,
    EquipmentEquipmentHoursMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EquipmentEquipmentHoursMetricsQuery, EquipmentEquipmentHoursMetricsQueryVariables>(
    EquipmentEquipmentHoursMetricsDocument,
    options
  );
}
export function useEquipmentEquipmentHoursMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EquipmentEquipmentHoursMetricsQuery,
    EquipmentEquipmentHoursMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EquipmentEquipmentHoursMetricsQuery, EquipmentEquipmentHoursMetricsQueryVariables>(
    EquipmentEquipmentHoursMetricsDocument,
    options
  );
}
export type EquipmentEquipmentHoursMetricsQueryHookResult = ReturnType<typeof useEquipmentEquipmentHoursMetricsQuery>;
export type EquipmentEquipmentHoursMetricsLazyQueryHookResult = ReturnType<
  typeof useEquipmentEquipmentHoursMetricsLazyQuery
>;
export type EquipmentEquipmentHoursMetricsSuspenseQueryHookResult = ReturnType<
  typeof useEquipmentEquipmentHoursMetricsSuspenseQuery
>;
export type EquipmentEquipmentHoursMetricsQueryResult = Apollo.QueryResult<
  EquipmentEquipmentHoursMetricsQuery,
  EquipmentEquipmentHoursMetricsQueryVariables
>;
export const PhotosAndNotesDashboardCardQueryDocument = gql`
  query photosAndNotesDashboardCardQuery($filter: MediaCollectionFilter, $first: Int, $after: String) {
    mediaCollections(first: $first, after: $after, filter: $filter) {
      id
      createdOn
      cursor
    }
  }
`;

/**
 * __usePhotosAndNotesDashboardCardQueryQuery__
 *
 * To run a query within a React component, call `usePhotosAndNotesDashboardCardQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotosAndNotesDashboardCardQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotosAndNotesDashboardCardQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePhotosAndNotesDashboardCardQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PhotosAndNotesDashboardCardQueryQuery,
    PhotosAndNotesDashboardCardQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhotosAndNotesDashboardCardQueryQuery, PhotosAndNotesDashboardCardQueryQueryVariables>(
    PhotosAndNotesDashboardCardQueryDocument,
    options
  );
}
export function usePhotosAndNotesDashboardCardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhotosAndNotesDashboardCardQueryQuery,
    PhotosAndNotesDashboardCardQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhotosAndNotesDashboardCardQueryQuery, PhotosAndNotesDashboardCardQueryQueryVariables>(
    PhotosAndNotesDashboardCardQueryDocument,
    options
  );
}
export function usePhotosAndNotesDashboardCardQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PhotosAndNotesDashboardCardQueryQuery,
    PhotosAndNotesDashboardCardQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PhotosAndNotesDashboardCardQueryQuery, PhotosAndNotesDashboardCardQueryQueryVariables>(
    PhotosAndNotesDashboardCardQueryDocument,
    options
  );
}
export type PhotosAndNotesDashboardCardQueryQueryHookResult = ReturnType<
  typeof usePhotosAndNotesDashboardCardQueryQuery
>;
export type PhotosAndNotesDashboardCardQueryLazyQueryHookResult = ReturnType<
  typeof usePhotosAndNotesDashboardCardQueryLazyQuery
>;
export type PhotosAndNotesDashboardCardQuerySuspenseQueryHookResult = ReturnType<
  typeof usePhotosAndNotesDashboardCardQuerySuspenseQuery
>;
export type PhotosAndNotesDashboardCardQueryQueryResult = Apollo.QueryResult<
  PhotosAndNotesDashboardCardQueryQuery,
  PhotosAndNotesDashboardCardQueryQueryVariables
>;
export const PhotoVerificationDashboardCardDocument = gql`
  query PhotoVerificationDashboardCard($after: String, $filter: TimeEntryRekognitionFilter) {
    timeEntryRekognition(after: $after, filter: $filter, first: 500) {
      id
      createdOn
      cursor
    }
  }
`;

/**
 * __usePhotoVerificationDashboardCardQuery__
 *
 * To run a query within a React component, call `usePhotoVerificationDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoVerificationDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoVerificationDashboardCardQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePhotoVerificationDashboardCardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PhotoVerificationDashboardCardQuery,
    PhotoVerificationDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhotoVerificationDashboardCardQuery, PhotoVerificationDashboardCardQueryVariables>(
    PhotoVerificationDashboardCardDocument,
    options
  );
}
export function usePhotoVerificationDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhotoVerificationDashboardCardQuery,
    PhotoVerificationDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhotoVerificationDashboardCardQuery, PhotoVerificationDashboardCardQueryVariables>(
    PhotoVerificationDashboardCardDocument,
    options
  );
}
export function usePhotoVerificationDashboardCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PhotoVerificationDashboardCardQuery,
    PhotoVerificationDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PhotoVerificationDashboardCardQuery, PhotoVerificationDashboardCardQueryVariables>(
    PhotoVerificationDashboardCardDocument,
    options
  );
}
export type PhotoVerificationDashboardCardQueryHookResult = ReturnType<typeof usePhotoVerificationDashboardCardQuery>;
export type PhotoVerificationDashboardCardLazyQueryHookResult = ReturnType<
  typeof usePhotoVerificationDashboardCardLazyQuery
>;
export type PhotoVerificationDashboardCardSuspenseQueryHookResult = ReturnType<
  typeof usePhotoVerificationDashboardCardSuspenseQuery
>;
export type PhotoVerificationDashboardCardQueryResult = Apollo.QueryResult<
  PhotoVerificationDashboardCardQuery,
  PhotoVerificationDashboardCardQueryVariables
>;
export const ActiveCostCodesMainDashboardDocument = gql`
  query ActiveCostCodesMainDashboard(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodes(after: $after, filter: $filter, first: $first) {
      id
      costCode
      title
      cursor
      costCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useActiveCostCodesMainDashboardQuery__
 *
 * To run a query within a React component, call `useActiveCostCodesMainDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveCostCodesMainDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveCostCodesMainDashboardQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useActiveCostCodesMainDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveCostCodesMainDashboardQuery, ActiveCostCodesMainDashboardQueryVariables> &
    ({ variables: ActiveCostCodesMainDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveCostCodesMainDashboardQuery, ActiveCostCodesMainDashboardQueryVariables>(
    ActiveCostCodesMainDashboardDocument,
    options
  );
}
export function useActiveCostCodesMainDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveCostCodesMainDashboardQuery,
    ActiveCostCodesMainDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveCostCodesMainDashboardQuery, ActiveCostCodesMainDashboardQueryVariables>(
    ActiveCostCodesMainDashboardDocument,
    options
  );
}
export function useActiveCostCodesMainDashboardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ActiveCostCodesMainDashboardQuery,
    ActiveCostCodesMainDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActiveCostCodesMainDashboardQuery, ActiveCostCodesMainDashboardQueryVariables>(
    ActiveCostCodesMainDashboardDocument,
    options
  );
}
export type ActiveCostCodesMainDashboardQueryHookResult = ReturnType<typeof useActiveCostCodesMainDashboardQuery>;
export type ActiveCostCodesMainDashboardLazyQueryHookResult = ReturnType<
  typeof useActiveCostCodesMainDashboardLazyQuery
>;
export type ActiveCostCodesMainDashboardSuspenseQueryHookResult = ReturnType<
  typeof useActiveCostCodesMainDashboardSuspenseQuery
>;
export type ActiveCostCodesMainDashboardQueryResult = Apollo.QueryResult<
  ActiveCostCodesMainDashboardQuery,
  ActiveCostCodesMainDashboardQueryVariables
>;
export const ActiveEquipmentMainDashboardCardDocument = gql`
  query ActiveEquipmentMainDashboardCard(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      cursor
      equipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicLaborMetrics
        equipmentCents
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useActiveEquipmentMainDashboardCardQuery__
 *
 * To run a query within a React component, call `useActiveEquipmentMainDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEquipmentMainDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEquipmentMainDashboardCardQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useActiveEquipmentMainDashboardCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveEquipmentMainDashboardCardQuery,
    ActiveEquipmentMainDashboardCardQueryVariables
  > &
    ({ variables: ActiveEquipmentMainDashboardCardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveEquipmentMainDashboardCardQuery, ActiveEquipmentMainDashboardCardQueryVariables>(
    ActiveEquipmentMainDashboardCardDocument,
    options
  );
}
export function useActiveEquipmentMainDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveEquipmentMainDashboardCardQuery,
    ActiveEquipmentMainDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveEquipmentMainDashboardCardQuery, ActiveEquipmentMainDashboardCardQueryVariables>(
    ActiveEquipmentMainDashboardCardDocument,
    options
  );
}
export function useActiveEquipmentMainDashboardCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ActiveEquipmentMainDashboardCardQuery,
    ActiveEquipmentMainDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActiveEquipmentMainDashboardCardQuery, ActiveEquipmentMainDashboardCardQueryVariables>(
    ActiveEquipmentMainDashboardCardDocument,
    options
  );
}
export type ActiveEquipmentMainDashboardCardQueryHookResult = ReturnType<
  typeof useActiveEquipmentMainDashboardCardQuery
>;
export type ActiveEquipmentMainDashboardCardLazyQueryHookResult = ReturnType<
  typeof useActiveEquipmentMainDashboardCardLazyQuery
>;
export type ActiveEquipmentMainDashboardCardSuspenseQueryHookResult = ReturnType<
  typeof useActiveEquipmentMainDashboardCardSuspenseQuery
>;
export type ActiveEquipmentMainDashboardCardQueryResult = Apollo.QueryResult<
  ActiveEquipmentMainDashboardCardQuery,
  ActiveEquipmentMainDashboardCardQueryVariables
>;
export const ActiveMembersQueryDocument = gql`
  query ActiveMembersQuery(
    $first: Int
    $after: String
    $filter: MemberFilter
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: [{ firstName: asc }, { lastName: asc }]) {
      cursor
      id
      firstName
      lastName
      memberLaborMetrics(interval: custom, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicMemberLaborMetrics
        __typename
      }
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
`;

/**
 * __useActiveMembersQueryQuery__
 *
 * To run a query within a React component, call `useActiveMembersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMembersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMembersQueryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useActiveMembersQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables> &
    ({ variables: ActiveMembersQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables>(
    ActiveMembersQueryDocument,
    options
  );
}
export function useActiveMembersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables>(
    ActiveMembersQueryDocument,
    options
  );
}
export function useActiveMembersQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables>(
    ActiveMembersQueryDocument,
    options
  );
}
export type ActiveMembersQueryQueryHookResult = ReturnType<typeof useActiveMembersQueryQuery>;
export type ActiveMembersQueryLazyQueryHookResult = ReturnType<typeof useActiveMembersQueryLazyQuery>;
export type ActiveMembersQuerySuspenseQueryHookResult = ReturnType<typeof useActiveMembersQuerySuspenseQuery>;
export type ActiveMembersQueryQueryResult = Apollo.QueryResult<
  ActiveMembersQueryQuery,
  ActiveMembersQueryQueryVariables
>;
export const ActiveProjectMainDashboardCardDocument = gql`
  query ActiveProjectMainDashboardCard(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
    ) {
      id
      title
      cursor
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        id
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useActiveProjectMainDashboardCardQuery__
 *
 * To run a query within a React component, call `useActiveProjectMainDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveProjectMainDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveProjectMainDashboardCardQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      memberId: // value for 'memberId'
 *      costCodeId: // value for 'costCodeId'
 *      equipmentId: // value for 'equipmentId'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useActiveProjectMainDashboardCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveProjectMainDashboardCardQuery,
    ActiveProjectMainDashboardCardQueryVariables
  > &
    ({ variables: ActiveProjectMainDashboardCardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveProjectMainDashboardCardQuery, ActiveProjectMainDashboardCardQueryVariables>(
    ActiveProjectMainDashboardCardDocument,
    options
  );
}
export function useActiveProjectMainDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveProjectMainDashboardCardQuery,
    ActiveProjectMainDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveProjectMainDashboardCardQuery, ActiveProjectMainDashboardCardQueryVariables>(
    ActiveProjectMainDashboardCardDocument,
    options
  );
}
export function useActiveProjectMainDashboardCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ActiveProjectMainDashboardCardQuery,
    ActiveProjectMainDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActiveProjectMainDashboardCardQuery, ActiveProjectMainDashboardCardQueryVariables>(
    ActiveProjectMainDashboardCardDocument,
    options
  );
}
export type ActiveProjectMainDashboardCardQueryHookResult = ReturnType<typeof useActiveProjectMainDashboardCardQuery>;
export type ActiveProjectMainDashboardCardLazyQueryHookResult = ReturnType<
  typeof useActiveProjectMainDashboardCardLazyQuery
>;
export type ActiveProjectMainDashboardCardSuspenseQueryHookResult = ReturnType<
  typeof useActiveProjectMainDashboardCardSuspenseQuery
>;
export type ActiveProjectMainDashboardCardQueryResult = Apollo.QueryResult<
  ActiveProjectMainDashboardCardQuery,
  ActiveProjectMainDashboardCardQueryVariables
>;
export const QueryTimeOffDashboardDataDocument = gql`
  query QueryTimeOffDashboardData($first: Int, $after: String, $filter: TimeOffFilter) {
    timeOffs(first: $first, after: $after, filter: $filter) {
      id
      paid
      seconds
      cursor
    }
  }
`;

/**
 * __useQueryTimeOffDashboardDataQuery__
 *
 * To run a query within a React component, call `useQueryTimeOffDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeOffDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeOffDashboardDataQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryTimeOffDashboardDataQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryTimeOffDashboardDataQuery, QueryTimeOffDashboardDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeOffDashboardDataQuery, QueryTimeOffDashboardDataQueryVariables>(
    QueryTimeOffDashboardDataDocument,
    options
  );
}
export function useQueryTimeOffDashboardDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryTimeOffDashboardDataQuery, QueryTimeOffDashboardDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeOffDashboardDataQuery, QueryTimeOffDashboardDataQueryVariables>(
    QueryTimeOffDashboardDataDocument,
    options
  );
}
export function useQueryTimeOffDashboardDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryTimeOffDashboardDataQuery, QueryTimeOffDashboardDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryTimeOffDashboardDataQuery, QueryTimeOffDashboardDataQueryVariables>(
    QueryTimeOffDashboardDataDocument,
    options
  );
}
export type QueryTimeOffDashboardDataQueryHookResult = ReturnType<typeof useQueryTimeOffDashboardDataQuery>;
export type QueryTimeOffDashboardDataLazyQueryHookResult = ReturnType<typeof useQueryTimeOffDashboardDataLazyQuery>;
export type QueryTimeOffDashboardDataSuspenseQueryHookResult = ReturnType<
  typeof useQueryTimeOffDashboardDataSuspenseQuery
>;
export type QueryTimeOffDashboardDataQueryResult = Apollo.QueryResult<
  QueryTimeOffDashboardDataQuery,
  QueryTimeOffDashboardDataQueryVariables
>;
export const QueryTimeOffRequestsDashboardDataDocument = gql`
  query QueryTimeOffRequestsDashboardData($first: Int, $after: String, $filter: TimeOffRequestFilter) {
    timeOffRequests(first: $first, after: $after, filter: $filter) {
      id
      cursor
    }
  }
`;

/**
 * __useQueryTimeOffRequestsDashboardDataQuery__
 *
 * To run a query within a React component, call `useQueryTimeOffRequestsDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTimeOffRequestsDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTimeOffRequestsDashboardDataQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useQueryTimeOffRequestsDashboardDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryTimeOffRequestsDashboardDataQuery,
    QueryTimeOffRequestsDashboardDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryTimeOffRequestsDashboardDataQuery, QueryTimeOffRequestsDashboardDataQueryVariables>(
    QueryTimeOffRequestsDashboardDataDocument,
    options
  );
}
export function useQueryTimeOffRequestsDashboardDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryTimeOffRequestsDashboardDataQuery,
    QueryTimeOffRequestsDashboardDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryTimeOffRequestsDashboardDataQuery, QueryTimeOffRequestsDashboardDataQueryVariables>(
    QueryTimeOffRequestsDashboardDataDocument,
    options
  );
}
export function useQueryTimeOffRequestsDashboardDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryTimeOffRequestsDashboardDataQuery,
    QueryTimeOffRequestsDashboardDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryTimeOffRequestsDashboardDataQuery,
    QueryTimeOffRequestsDashboardDataQueryVariables
  >(QueryTimeOffRequestsDashboardDataDocument, options);
}
export type QueryTimeOffRequestsDashboardDataQueryHookResult = ReturnType<
  typeof useQueryTimeOffRequestsDashboardDataQuery
>;
export type QueryTimeOffRequestsDashboardDataLazyQueryHookResult = ReturnType<
  typeof useQueryTimeOffRequestsDashboardDataLazyQuery
>;
export type QueryTimeOffRequestsDashboardDataSuspenseQueryHookResult = ReturnType<
  typeof useQueryTimeOffRequestsDashboardDataSuspenseQuery
>;
export type QueryTimeOffRequestsDashboardDataQueryResult = Apollo.QueryResult<
  QueryTimeOffRequestsDashboardDataQuery,
  QueryTimeOffRequestsDashboardDataQueryVariables
>;
export const SignInTimeEntriesDocument = gql`
  query SignInTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      startTime
      endTime
      updatedOn
      cursor
      member {
        id
        positionId
      }
    }
  }
`;

/**
 * __useSignInTimeEntriesQuery__
 *
 * To run a query within a React component, call `useSignInTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignInTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignInTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSignInTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>(SignInTimeEntriesDocument, options);
}
export function useSignInTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>(
    SignInTimeEntriesDocument,
    options
  );
}
export function useSignInTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>(
    SignInTimeEntriesDocument,
    options
  );
}
export type SignInTimeEntriesQueryHookResult = ReturnType<typeof useSignInTimeEntriesQuery>;
export type SignInTimeEntriesLazyQueryHookResult = ReturnType<typeof useSignInTimeEntriesLazyQuery>;
export type SignInTimeEntriesSuspenseQueryHookResult = ReturnType<typeof useSignInTimeEntriesSuspenseQuery>;
export type SignInTimeEntriesQueryResult = Apollo.QueryResult<SignInTimeEntriesQuery, SignInTimeEntriesQueryVariables>;
export const DailySignatureCardDocument = gql`
  query DailySignatureCard($first: Int, $after: String, $filter: SafetySignatureFilter, $sort: [SafetySignatureSort!]) {
    safetySignatures(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      ...BasicSafetySignatureFragment
    }
  }
  ${BasicSafetySignatureFragmentFragmentDoc}
`;

/**
 * __useDailySignatureCardQuery__
 *
 * To run a query within a React component, call `useDailySignatureCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailySignatureCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailySignatureCardQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDailySignatureCardQuery(
  baseOptions?: Apollo.QueryHookOptions<DailySignatureCardQuery, DailySignatureCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailySignatureCardQuery, DailySignatureCardQueryVariables>(
    DailySignatureCardDocument,
    options
  );
}
export function useDailySignatureCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailySignatureCardQuery, DailySignatureCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailySignatureCardQuery, DailySignatureCardQueryVariables>(
    DailySignatureCardDocument,
    options
  );
}
export function useDailySignatureCardSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailySignatureCardQuery, DailySignatureCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailySignatureCardQuery, DailySignatureCardQueryVariables>(
    DailySignatureCardDocument,
    options
  );
}
export type DailySignatureCardQueryHookResult = ReturnType<typeof useDailySignatureCardQuery>;
export type DailySignatureCardLazyQueryHookResult = ReturnType<typeof useDailySignatureCardLazyQuery>;
export type DailySignatureCardSuspenseQueryHookResult = ReturnType<typeof useDailySignatureCardSuspenseQuery>;
export type DailySignatureCardQueryResult = Apollo.QueryResult<
  DailySignatureCardQuery,
  DailySignatureCardQueryVariables
>;
export const FoldersDocument = gql`
  query Folders($filter: FolderFilter) {
    folders(filter: $filter) {
      id
      title
      deletedOn
      cursor
    }
  }
`;

/**
 * __useFoldersQuery__
 *
 * To run a query within a React component, call `useFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFoldersQuery(baseOptions?: Apollo.QueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
}
export function useFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
}
export function useFoldersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FoldersQuery, FoldersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, options);
}
export type FoldersQueryHookResult = ReturnType<typeof useFoldersQuery>;
export type FoldersLazyQueryHookResult = ReturnType<typeof useFoldersLazyQuery>;
export type FoldersSuspenseQueryHookResult = ReturnType<typeof useFoldersSuspenseQuery>;
export type FoldersQueryResult = Apollo.QueryResult<FoldersQuery, FoldersQueryVariables>;
export const CreateFolderDocument = gql`
  mutation CreateFolder($folder: FolderCreateInput) {
    createFolder(folder: $folder) {
      id
      title
      type
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
}
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>;
export const UpdateFolderDocument = gql`
  mutation UpdateFolder($folder: FolderUpdateInput!) {
    updateFolder(folder: $folder) {
      id
      title
      type
      deletedOn
    }
  }
`;
export type UpdateFolderMutationFn = Apollo.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useUpdateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderDocument, options);
}
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>;
export const TagsDocument = gql`
  query Tags($after: String, $filter: TagFilter) {
    tags(after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      type
      deletedOn
      cursor
    }
  }
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagsQuery, TagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const CreateTagDocument = gql`
  mutation CreateTag($tag: TagCreateInput) {
    createTag(tag: $tag) {
      id
      title
      type
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
  mutation UpdateTag($tag: TagUpdateInput!) {
    updateTag(tag: $tag) {
      id
      title
      type
      deletedOn
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const CreateTagDocumentLinkDocument = gql`
  mutation CreateTagDocumentLink($tagDocumentLink: TagDocumentLinkCreateInput) {
    createTagDocumentLink(tagDocumentLink: $tagDocumentLink) {
      id
      tagId
      documentId
      createdOn
      deletedOn
    }
  }
`;
export type CreateTagDocumentLinkMutationFn = Apollo.MutationFunction<
  CreateTagDocumentLinkMutation,
  CreateTagDocumentLinkMutationVariables
>;

/**
 * __useCreateTagDocumentLinkMutation__
 *
 * To run a mutation, you first call `useCreateTagDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagDocumentLinkMutation, { data, loading, error }] = useCreateTagDocumentLinkMutation({
 *   variables: {
 *      tagDocumentLink: // value for 'tagDocumentLink'
 *   },
 * });
 */
export function useCreateTagDocumentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagDocumentLinkMutation, CreateTagDocumentLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagDocumentLinkMutation, CreateTagDocumentLinkMutationVariables>(
    CreateTagDocumentLinkDocument,
    options
  );
}
export type CreateTagDocumentLinkMutationHookResult = ReturnType<typeof useCreateTagDocumentLinkMutation>;
export type CreateTagDocumentLinkMutationResult = Apollo.MutationResult<CreateTagDocumentLinkMutation>;
export type CreateTagDocumentLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateTagDocumentLinkMutation,
  CreateTagDocumentLinkMutationVariables
>;
export const UpdateTagDocumentLinkDocument = gql`
  mutation UpdateTagDocumentLink($tagDocumentLink: TagDocumentLinkUpdateInput) {
    updateTagDocumentLink(tagDocumentLink: $tagDocumentLink) {
      id
      tagId
      documentId
      createdOn
      deletedOn
    }
  }
`;
export type UpdateTagDocumentLinkMutationFn = Apollo.MutationFunction<
  UpdateTagDocumentLinkMutation,
  UpdateTagDocumentLinkMutationVariables
>;

/**
 * __useUpdateTagDocumentLinkMutation__
 *
 * To run a mutation, you first call `useUpdateTagDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagDocumentLinkMutation, { data, loading, error }] = useUpdateTagDocumentLinkMutation({
 *   variables: {
 *      tagDocumentLink: // value for 'tagDocumentLink'
 *   },
 * });
 */
export function useUpdateTagDocumentLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagDocumentLinkMutation, UpdateTagDocumentLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagDocumentLinkMutation, UpdateTagDocumentLinkMutationVariables>(
    UpdateTagDocumentLinkDocument,
    options
  );
}
export type UpdateTagDocumentLinkMutationHookResult = ReturnType<typeof useUpdateTagDocumentLinkMutation>;
export type UpdateTagDocumentLinkMutationResult = Apollo.MutationResult<UpdateTagDocumentLinkMutation>;
export type UpdateTagDocumentLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagDocumentLinkMutation,
  UpdateTagDocumentLinkMutationVariables
>;
export const OrganizationDocumentCountDocument = gql`
  query organizationDocumentCount {
    organizationDocumentCount
  }
`;

/**
 * __useOrganizationDocumentCountQuery__
 *
 * To run a query within a React component, call `useOrganizationDocumentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDocumentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDocumentCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationDocumentCountQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationDocumentCountQuery, OrganizationDocumentCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationDocumentCountQuery, OrganizationDocumentCountQueryVariables>(
    OrganizationDocumentCountDocument,
    options
  );
}
export function useOrganizationDocumentCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationDocumentCountQuery, OrganizationDocumentCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationDocumentCountQuery, OrganizationDocumentCountQueryVariables>(
    OrganizationDocumentCountDocument,
    options
  );
}
export function useOrganizationDocumentCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationDocumentCountQuery, OrganizationDocumentCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OrganizationDocumentCountQuery, OrganizationDocumentCountQueryVariables>(
    OrganizationDocumentCountDocument,
    options
  );
}
export type OrganizationDocumentCountQueryHookResult = ReturnType<typeof useOrganizationDocumentCountQuery>;
export type OrganizationDocumentCountLazyQueryHookResult = ReturnType<typeof useOrganizationDocumentCountLazyQuery>;
export type OrganizationDocumentCountSuspenseQueryHookResult = ReturnType<
  typeof useOrganizationDocumentCountSuspenseQuery
>;
export type OrganizationDocumentCountQueryResult = Apollo.QueryResult<
  OrganizationDocumentCountQuery,
  OrganizationDocumentCountQueryVariables
>;
export const TagDocumentLinkDocument = gql`
  query TagDocumentLink($after: String, $filter: TagDocumentLinkFilter) {
    tagDocumentLinks(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      id
      tagId
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useTagDocumentLinkQuery__
 *
 * To run a query within a React component, call `useTagDocumentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagDocumentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagDocumentLinkQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTagDocumentLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>(TagDocumentLinkDocument, options);
}
export function useTagDocumentLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>(TagDocumentLinkDocument, options);
}
export function useTagDocumentLinkSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>(TagDocumentLinkDocument, options);
}
export type TagDocumentLinkQueryHookResult = ReturnType<typeof useTagDocumentLinkQuery>;
export type TagDocumentLinkLazyQueryHookResult = ReturnType<typeof useTagDocumentLinkLazyQuery>;
export type TagDocumentLinkSuspenseQueryHookResult = ReturnType<typeof useTagDocumentLinkSuspenseQuery>;
export type TagDocumentLinkQueryResult = Apollo.QueryResult<TagDocumentLinkQuery, TagDocumentLinkQueryVariables>;
export const TagMediaCollectionLinkDocument = gql`
  query TagMediaCollectionLink($after: String, $filter: TagMediaCollectionLinkFilter) {
    tagMediaCollectionLinks(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      id
      tagId
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useTagMediaCollectionLinkQuery__
 *
 * To run a query within a React component, call `useTagMediaCollectionLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagMediaCollectionLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagMediaCollectionLinkQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTagMediaCollectionLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<TagMediaCollectionLinkQuery, TagMediaCollectionLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagMediaCollectionLinkQuery, TagMediaCollectionLinkQueryVariables>(
    TagMediaCollectionLinkDocument,
    options
  );
}
export function useTagMediaCollectionLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagMediaCollectionLinkQuery, TagMediaCollectionLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagMediaCollectionLinkQuery, TagMediaCollectionLinkQueryVariables>(
    TagMediaCollectionLinkDocument,
    options
  );
}
export function useTagMediaCollectionLinkSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TagMediaCollectionLinkQuery, TagMediaCollectionLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TagMediaCollectionLinkQuery, TagMediaCollectionLinkQueryVariables>(
    TagMediaCollectionLinkDocument,
    options
  );
}
export type TagMediaCollectionLinkQueryHookResult = ReturnType<typeof useTagMediaCollectionLinkQuery>;
export type TagMediaCollectionLinkLazyQueryHookResult = ReturnType<typeof useTagMediaCollectionLinkLazyQuery>;
export type TagMediaCollectionLinkSuspenseQueryHookResult = ReturnType<typeof useTagMediaCollectionLinkSuspenseQuery>;
export type TagMediaCollectionLinkQueryResult = Apollo.QueryResult<
  TagMediaCollectionLinkQuery,
  TagMediaCollectionLinkQueryVariables
>;
export const QueryMemberSidePanelDetailsDocument = gql`
  query queryMemberSidePanelDetails($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      email
      memberNumber
      phone
      firstName
      lastName
      createdOn
      updatedOn
      position {
        id
        level
        title
      }
      memberGroup {
        id
        groupName
      }
    }
  }
`;

/**
 * __useQueryMemberSidePanelDetailsQuery__
 *
 * To run a query within a React component, call `useQueryMemberSidePanelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberSidePanelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberSidePanelDetailsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMemberSidePanelDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryMemberSidePanelDetailsQuery, QueryMemberSidePanelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMemberSidePanelDetailsQuery, QueryMemberSidePanelDetailsQueryVariables>(
    QueryMemberSidePanelDetailsDocument,
    options
  );
}
export function useQueryMemberSidePanelDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMemberSidePanelDetailsQuery, QueryMemberSidePanelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMemberSidePanelDetailsQuery, QueryMemberSidePanelDetailsQueryVariables>(
    QueryMemberSidePanelDetailsDocument,
    options
  );
}
export function useQueryMemberSidePanelDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMemberSidePanelDetailsQuery,
    QueryMemberSidePanelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMemberSidePanelDetailsQuery, QueryMemberSidePanelDetailsQueryVariables>(
    QueryMemberSidePanelDetailsDocument,
    options
  );
}
export type QueryMemberSidePanelDetailsQueryHookResult = ReturnType<typeof useQueryMemberSidePanelDetailsQuery>;
export type QueryMemberSidePanelDetailsLazyQueryHookResult = ReturnType<typeof useQueryMemberSidePanelDetailsLazyQuery>;
export type QueryMemberSidePanelDetailsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMemberSidePanelDetailsSuspenseQuery
>;
export type QueryMemberSidePanelDetailsQueryResult = Apollo.QueryResult<
  QueryMemberSidePanelDetailsQuery,
  QueryMemberSidePanelDetailsQueryVariables
>;
export const FlaggedTimeEntryLocationDocument = gql`
  query FlaggedTimeEntryLocation($after: String, $filter: FlaggedTimeEntryLocationFilter) {
    flaggedTimeEntryLocations(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      id
      organizationId
      timeEntryId
      memberId
      status
      comment
      reviewedByMemberId
      locationData
      edited
      lastStatusUpdatedOn
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      timeEntry {
        id
        startTime
        endTime
        projectId
        project {
          id
          title
          depth
          ancestors {
            id
            title
            depth
          }
          projectInfo {
            latitude
            longitude
            locationRadius
          }
        }
      }
      member {
        id
        firstName
        lastName
        position {
          id
          level
        }
      }
      reviewedByMember {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useFlaggedTimeEntryLocationQuery__
 *
 * To run a query within a React component, call `useFlaggedTimeEntryLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlaggedTimeEntryLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlaggedTimeEntryLocationQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFlaggedTimeEntryLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<FlaggedTimeEntryLocationQuery, FlaggedTimeEntryLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlaggedTimeEntryLocationQuery, FlaggedTimeEntryLocationQueryVariables>(
    FlaggedTimeEntryLocationDocument,
    options
  );
}
export function useFlaggedTimeEntryLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlaggedTimeEntryLocationQuery, FlaggedTimeEntryLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlaggedTimeEntryLocationQuery, FlaggedTimeEntryLocationQueryVariables>(
    FlaggedTimeEntryLocationDocument,
    options
  );
}
export function useFlaggedTimeEntryLocationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FlaggedTimeEntryLocationQuery, FlaggedTimeEntryLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FlaggedTimeEntryLocationQuery, FlaggedTimeEntryLocationQueryVariables>(
    FlaggedTimeEntryLocationDocument,
    options
  );
}
export type FlaggedTimeEntryLocationQueryHookResult = ReturnType<typeof useFlaggedTimeEntryLocationQuery>;
export type FlaggedTimeEntryLocationLazyQueryHookResult = ReturnType<typeof useFlaggedTimeEntryLocationLazyQuery>;
export type FlaggedTimeEntryLocationSuspenseQueryHookResult = ReturnType<
  typeof useFlaggedTimeEntryLocationSuspenseQuery
>;
export type FlaggedTimeEntryLocationQueryResult = Apollo.QueryResult<
  FlaggedTimeEntryLocationQuery,
  FlaggedTimeEntryLocationQueryVariables
>;
export const UpdateFlaggedTimeEntryLocationDocument = gql`
  mutation UpdateFlaggedTimeEntryLocation($flaggedTimeEntryLocation: FlaggedTimeEntryLocationUpdateInput!) {
    updateFlaggedTimeEntryLocation(flaggedTimeEntryLocation: $flaggedTimeEntryLocation) {
      id
      status
      comment
    }
  }
`;
export type UpdateFlaggedTimeEntryLocationMutationFn = Apollo.MutationFunction<
  UpdateFlaggedTimeEntryLocationMutation,
  UpdateFlaggedTimeEntryLocationMutationVariables
>;

/**
 * __useUpdateFlaggedTimeEntryLocationMutation__
 *
 * To run a mutation, you first call `useUpdateFlaggedTimeEntryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlaggedTimeEntryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlaggedTimeEntryLocationMutation, { data, loading, error }] = useUpdateFlaggedTimeEntryLocationMutation({
 *   variables: {
 *      flaggedTimeEntryLocation: // value for 'flaggedTimeEntryLocation'
 *   },
 * });
 */
export function useUpdateFlaggedTimeEntryLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFlaggedTimeEntryLocationMutation,
    UpdateFlaggedTimeEntryLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFlaggedTimeEntryLocationMutation, UpdateFlaggedTimeEntryLocationMutationVariables>(
    UpdateFlaggedTimeEntryLocationDocument,
    options
  );
}
export type UpdateFlaggedTimeEntryLocationMutationHookResult = ReturnType<
  typeof useUpdateFlaggedTimeEntryLocationMutation
>;
export type UpdateFlaggedTimeEntryLocationMutationResult =
  Apollo.MutationResult<UpdateFlaggedTimeEntryLocationMutation>;
export type UpdateFlaggedTimeEntryLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateFlaggedTimeEntryLocationMutation,
  UpdateFlaggedTimeEntryLocationMutationVariables
>;
export const TimeEntriesWithRootProjectOnly4Document = gql`
  query TimeEntriesWithRootProjectOnly4(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      project {
        id
        rootProject {
          id
          projectGroupId
        }
      }
      cursor
    }
  }
`;

/**
 * __useTimeEntriesWithRootProjectOnly4Query__
 *
 * To run a query within a React component, call `useTimeEntriesWithRootProjectOnly4Query` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntriesWithRootProjectOnly4Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntriesWithRootProjectOnly4Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimeEntriesWithRootProjectOnly4Query(
  baseOptions?: Apollo.QueryHookOptions<
    TimeEntriesWithRootProjectOnly4Query,
    TimeEntriesWithRootProjectOnly4QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntriesWithRootProjectOnly4Query, TimeEntriesWithRootProjectOnly4QueryVariables>(
    TimeEntriesWithRootProjectOnly4Document,
    options
  );
}
export function useTimeEntriesWithRootProjectOnly4LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeEntriesWithRootProjectOnly4Query,
    TimeEntriesWithRootProjectOnly4QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntriesWithRootProjectOnly4Query, TimeEntriesWithRootProjectOnly4QueryVariables>(
    TimeEntriesWithRootProjectOnly4Document,
    options
  );
}
export function useTimeEntriesWithRootProjectOnly4SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TimeEntriesWithRootProjectOnly4Query,
    TimeEntriesWithRootProjectOnly4QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntriesWithRootProjectOnly4Query, TimeEntriesWithRootProjectOnly4QueryVariables>(
    TimeEntriesWithRootProjectOnly4Document,
    options
  );
}
export type TimeEntriesWithRootProjectOnly4QueryHookResult = ReturnType<typeof useTimeEntriesWithRootProjectOnly4Query>;
export type TimeEntriesWithRootProjectOnly4LazyQueryHookResult = ReturnType<
  typeof useTimeEntriesWithRootProjectOnly4LazyQuery
>;
export type TimeEntriesWithRootProjectOnly4SuspenseQueryHookResult = ReturnType<
  typeof useTimeEntriesWithRootProjectOnly4SuspenseQuery
>;
export type TimeEntriesWithRootProjectOnly4QueryResult = Apollo.QueryResult<
  TimeEntriesWithRootProjectOnly4Query,
  TimeEntriesWithRootProjectOnly4QueryVariables
>;
export const DailyProjectReportIdOnlyDocument = gql`
  query dailyProjectReportIdOnly($filter: DailyProjectReportFilter) {
    dailyProjectReports(filter: $filter) {
      id
    }
  }
`;

/**
 * __useDailyProjectReportIdOnlyQuery__
 *
 * To run a query within a React component, call `useDailyProjectReportIdOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyProjectReportIdOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyProjectReportIdOnlyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDailyProjectReportIdOnlyQuery(
  baseOptions?: Apollo.QueryHookOptions<DailyProjectReportIdOnlyQuery, DailyProjectReportIdOnlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyProjectReportIdOnlyQuery, DailyProjectReportIdOnlyQueryVariables>(
    DailyProjectReportIdOnlyDocument,
    options
  );
}
export function useDailyProjectReportIdOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyProjectReportIdOnlyQuery, DailyProjectReportIdOnlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyProjectReportIdOnlyQuery, DailyProjectReportIdOnlyQueryVariables>(
    DailyProjectReportIdOnlyDocument,
    options
  );
}
export function useDailyProjectReportIdOnlySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailyProjectReportIdOnlyQuery, DailyProjectReportIdOnlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyProjectReportIdOnlyQuery, DailyProjectReportIdOnlyQueryVariables>(
    DailyProjectReportIdOnlyDocument,
    options
  );
}
export type DailyProjectReportIdOnlyQueryHookResult = ReturnType<typeof useDailyProjectReportIdOnlyQuery>;
export type DailyProjectReportIdOnlyLazyQueryHookResult = ReturnType<typeof useDailyProjectReportIdOnlyLazyQuery>;
export type DailyProjectReportIdOnlySuspenseQueryHookResult = ReturnType<
  typeof useDailyProjectReportIdOnlySuspenseQuery
>;
export type DailyProjectReportIdOnlyQueryResult = Apollo.QueryResult<
  DailyProjectReportIdOnlyQuery,
  DailyProjectReportIdOnlyQueryVariables
>;
export const QueryCostCodeLandingCostCodesDocument = gql`
  query QueryCostCodeLandingCostCodes(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $sort: [CostCodeSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: $sort) {
      archivedOn
      costCode
      costCodeGroup {
        createdOn
        cursor
        deletedOn
        groupName
        id
        organizationId
        submittedOn
        updatedOn
      }
      costCodeGroupId
      createdOn
      cursor
      id
      organizationId
      submittedOn
      title
      unitTitle
      updatedOn
      workersCompId
      costCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodeLandingCostCodesQuery__
 *
 * To run a query within a React component, call `useQueryCostCodeLandingCostCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodeLandingCostCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodeLandingCostCodesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryCostCodeLandingCostCodesQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCostCodeLandingCostCodesQuery,
    QueryCostCodeLandingCostCodesQueryVariables
  > &
    ({ variables: QueryCostCodeLandingCostCodesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodeLandingCostCodesQuery, QueryCostCodeLandingCostCodesQueryVariables>(
    QueryCostCodeLandingCostCodesDocument,
    options
  );
}
export function useQueryCostCodeLandingCostCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCostCodeLandingCostCodesQuery,
    QueryCostCodeLandingCostCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodeLandingCostCodesQuery, QueryCostCodeLandingCostCodesQueryVariables>(
    QueryCostCodeLandingCostCodesDocument,
    options
  );
}
export function useQueryCostCodeLandingCostCodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryCostCodeLandingCostCodesQuery,
    QueryCostCodeLandingCostCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodeLandingCostCodesQuery, QueryCostCodeLandingCostCodesQueryVariables>(
    QueryCostCodeLandingCostCodesDocument,
    options
  );
}
export type QueryCostCodeLandingCostCodesQueryHookResult = ReturnType<typeof useQueryCostCodeLandingCostCodesQuery>;
export type QueryCostCodeLandingCostCodesLazyQueryHookResult = ReturnType<
  typeof useQueryCostCodeLandingCostCodesLazyQuery
>;
export type QueryCostCodeLandingCostCodesSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodeLandingCostCodesSuspenseQuery
>;
export type QueryCostCodeLandingCostCodesQueryResult = Apollo.QueryResult<
  QueryCostCodeLandingCostCodesQuery,
  QueryCostCodeLandingCostCodesQueryVariables
>;
export const TimeEntriesWithRootProjectOnly2Document = gql`
  query TimeEntriesWithRootProjectOnly2(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      project {
        id
        rootProject {
          id
          projectGroupId
        }
      }
      cursor
    }
  }
`;

/**
 * __useTimeEntriesWithRootProjectOnly2Query__
 *
 * To run a query within a React component, call `useTimeEntriesWithRootProjectOnly2Query` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntriesWithRootProjectOnly2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntriesWithRootProjectOnly2Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimeEntriesWithRootProjectOnly2Query(
  baseOptions?: Apollo.QueryHookOptions<
    TimeEntriesWithRootProjectOnly2Query,
    TimeEntriesWithRootProjectOnly2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntriesWithRootProjectOnly2Query, TimeEntriesWithRootProjectOnly2QueryVariables>(
    TimeEntriesWithRootProjectOnly2Document,
    options
  );
}
export function useTimeEntriesWithRootProjectOnly2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeEntriesWithRootProjectOnly2Query,
    TimeEntriesWithRootProjectOnly2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntriesWithRootProjectOnly2Query, TimeEntriesWithRootProjectOnly2QueryVariables>(
    TimeEntriesWithRootProjectOnly2Document,
    options
  );
}
export function useTimeEntriesWithRootProjectOnly2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TimeEntriesWithRootProjectOnly2Query,
    TimeEntriesWithRootProjectOnly2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntriesWithRootProjectOnly2Query, TimeEntriesWithRootProjectOnly2QueryVariables>(
    TimeEntriesWithRootProjectOnly2Document,
    options
  );
}
export type TimeEntriesWithRootProjectOnly2QueryHookResult = ReturnType<typeof useTimeEntriesWithRootProjectOnly2Query>;
export type TimeEntriesWithRootProjectOnly2LazyQueryHookResult = ReturnType<
  typeof useTimeEntriesWithRootProjectOnly2LazyQuery
>;
export type TimeEntriesWithRootProjectOnly2SuspenseQueryHookResult = ReturnType<
  typeof useTimeEntriesWithRootProjectOnly2SuspenseQuery
>;
export type TimeEntriesWithRootProjectOnly2QueryResult = Apollo.QueryResult<
  TimeEntriesWithRootProjectOnly2Query,
  TimeEntriesWithRootProjectOnly2QueryVariables
>;
export const DailyProjectReportIdOnly2Document = gql`
  query dailyProjectReportIdOnly2($filter: DailyProjectReportFilter) {
    dailyProjectReports(filter: $filter) {
      id
    }
  }
`;

/**
 * __useDailyProjectReportIdOnly2Query__
 *
 * To run a query within a React component, call `useDailyProjectReportIdOnly2Query` and pass it any options that fit your needs.
 * When your component renders, `useDailyProjectReportIdOnly2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyProjectReportIdOnly2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDailyProjectReportIdOnly2Query(
  baseOptions?: Apollo.QueryHookOptions<DailyProjectReportIdOnly2Query, DailyProjectReportIdOnly2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyProjectReportIdOnly2Query, DailyProjectReportIdOnly2QueryVariables>(
    DailyProjectReportIdOnly2Document,
    options
  );
}
export function useDailyProjectReportIdOnly2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyProjectReportIdOnly2Query, DailyProjectReportIdOnly2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyProjectReportIdOnly2Query, DailyProjectReportIdOnly2QueryVariables>(
    DailyProjectReportIdOnly2Document,
    options
  );
}
export function useDailyProjectReportIdOnly2SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailyProjectReportIdOnly2Query, DailyProjectReportIdOnly2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyProjectReportIdOnly2Query, DailyProjectReportIdOnly2QueryVariables>(
    DailyProjectReportIdOnly2Document,
    options
  );
}
export type DailyProjectReportIdOnly2QueryHookResult = ReturnType<typeof useDailyProjectReportIdOnly2Query>;
export type DailyProjectReportIdOnly2LazyQueryHookResult = ReturnType<typeof useDailyProjectReportIdOnly2LazyQuery>;
export type DailyProjectReportIdOnly2SuspenseQueryHookResult = ReturnType<
  typeof useDailyProjectReportIdOnly2SuspenseQuery
>;
export type DailyProjectReportIdOnly2QueryResult = Apollo.QueryResult<
  DailyProjectReportIdOnly2Query,
  DailyProjectReportIdOnly2QueryVariables
>;
export const QueryEquipmentLandingDocument = gql`
  query queryEquipmentLanding($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentName
      id
      lastHours {
        id
        runningHours
        updatedOn
      }
      model {
        id
        make {
          id
          title
          unknown
        }
        modelNumber
        title
        unknown
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
    }
  }
`;

/**
 * __useQueryEquipmentLandingQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentLandingQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryEquipmentLandingQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEquipmentLandingQuery, QueryEquipmentLandingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentLandingQuery, QueryEquipmentLandingQueryVariables>(
    QueryEquipmentLandingDocument,
    options
  );
}
export function useQueryEquipmentLandingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentLandingQuery, QueryEquipmentLandingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentLandingQuery, QueryEquipmentLandingQueryVariables>(
    QueryEquipmentLandingDocument,
    options
  );
}
export function useQueryEquipmentLandingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentLandingQuery, QueryEquipmentLandingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentLandingQuery, QueryEquipmentLandingQueryVariables>(
    QueryEquipmentLandingDocument,
    options
  );
}
export type QueryEquipmentLandingQueryHookResult = ReturnType<typeof useQueryEquipmentLandingQuery>;
export type QueryEquipmentLandingLazyQueryHookResult = ReturnType<typeof useQueryEquipmentLandingLazyQuery>;
export type QueryEquipmentLandingSuspenseQueryHookResult = ReturnType<typeof useQueryEquipmentLandingSuspenseQuery>;
export type QueryEquipmentLandingQueryResult = Apollo.QueryResult<
  QueryEquipmentLandingQuery,
  QueryEquipmentLandingQueryVariables
>;
export const ProjectLandingQueryDocument = gql`
  query ProjectLandingQuery(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      projectInfo {
        id
        number
      }
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectLandingQueryQuery__
 *
 * To run a query within a React component, call `useProjectLandingQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLandingQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLandingQueryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useProjectLandingQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectLandingQueryQuery, ProjectLandingQueryQueryVariables> &
    ({ variables: ProjectLandingQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectLandingQueryQuery, ProjectLandingQueryQueryVariables>(
    ProjectLandingQueryDocument,
    options
  );
}
export function useProjectLandingQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectLandingQueryQuery, ProjectLandingQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectLandingQueryQuery, ProjectLandingQueryQueryVariables>(
    ProjectLandingQueryDocument,
    options
  );
}
export function useProjectLandingQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectLandingQueryQuery, ProjectLandingQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectLandingQueryQuery, ProjectLandingQueryQueryVariables>(
    ProjectLandingQueryDocument,
    options
  );
}
export type ProjectLandingQueryQueryHookResult = ReturnType<typeof useProjectLandingQueryQuery>;
export type ProjectLandingQueryLazyQueryHookResult = ReturnType<typeof useProjectLandingQueryLazyQuery>;
export type ProjectLandingQuerySuspenseQueryHookResult = ReturnType<typeof useProjectLandingQuerySuspenseQuery>;
export type ProjectLandingQueryQueryResult = Apollo.QueryResult<
  ProjectLandingQueryQuery,
  ProjectLandingQueryQueryVariables
>;
export const TimeEntriesWithRootProjectOnly3Document = gql`
  query TimeEntriesWithRootProjectOnly3(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      project {
        id
        rootProject {
          id
          projectGroupId
        }
      }
      cursor
    }
  }
`;

/**
 * __useTimeEntriesWithRootProjectOnly3Query__
 *
 * To run a query within a React component, call `useTimeEntriesWithRootProjectOnly3Query` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntriesWithRootProjectOnly3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntriesWithRootProjectOnly3Query({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimeEntriesWithRootProjectOnly3Query(
  baseOptions?: Apollo.QueryHookOptions<
    TimeEntriesWithRootProjectOnly3Query,
    TimeEntriesWithRootProjectOnly3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntriesWithRootProjectOnly3Query, TimeEntriesWithRootProjectOnly3QueryVariables>(
    TimeEntriesWithRootProjectOnly3Document,
    options
  );
}
export function useTimeEntriesWithRootProjectOnly3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeEntriesWithRootProjectOnly3Query,
    TimeEntriesWithRootProjectOnly3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntriesWithRootProjectOnly3Query, TimeEntriesWithRootProjectOnly3QueryVariables>(
    TimeEntriesWithRootProjectOnly3Document,
    options
  );
}
export function useTimeEntriesWithRootProjectOnly3SuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TimeEntriesWithRootProjectOnly3Query,
    TimeEntriesWithRootProjectOnly3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntriesWithRootProjectOnly3Query, TimeEntriesWithRootProjectOnly3QueryVariables>(
    TimeEntriesWithRootProjectOnly3Document,
    options
  );
}
export type TimeEntriesWithRootProjectOnly3QueryHookResult = ReturnType<typeof useTimeEntriesWithRootProjectOnly3Query>;
export type TimeEntriesWithRootProjectOnly3LazyQueryHookResult = ReturnType<
  typeof useTimeEntriesWithRootProjectOnly3LazyQuery
>;
export type TimeEntriesWithRootProjectOnly3SuspenseQueryHookResult = ReturnType<
  typeof useTimeEntriesWithRootProjectOnly3SuspenseQuery
>;
export type TimeEntriesWithRootProjectOnly3QueryResult = Apollo.QueryResult<
  TimeEntriesWithRootProjectOnly3Query,
  TimeEntriesWithRootProjectOnly3QueryVariables
>;
export const DailyProjectReportIdFileDocument = gql`
  query dailyProjectReportIdFile($filter: DailyProjectReportFilter) {
    dailyProjectReports(filter: $filter) {
      id
      fileUrl
    }
  }
`;

/**
 * __useDailyProjectReportIdFileQuery__
 *
 * To run a query within a React component, call `useDailyProjectReportIdFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyProjectReportIdFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyProjectReportIdFileQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDailyProjectReportIdFileQuery(
  baseOptions?: Apollo.QueryHookOptions<DailyProjectReportIdFileQuery, DailyProjectReportIdFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyProjectReportIdFileQuery, DailyProjectReportIdFileQueryVariables>(
    DailyProjectReportIdFileDocument,
    options
  );
}
export function useDailyProjectReportIdFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyProjectReportIdFileQuery, DailyProjectReportIdFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyProjectReportIdFileQuery, DailyProjectReportIdFileQueryVariables>(
    DailyProjectReportIdFileDocument,
    options
  );
}
export function useDailyProjectReportIdFileSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailyProjectReportIdFileQuery, DailyProjectReportIdFileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyProjectReportIdFileQuery, DailyProjectReportIdFileQueryVariables>(
    DailyProjectReportIdFileDocument,
    options
  );
}
export type DailyProjectReportIdFileQueryHookResult = ReturnType<typeof useDailyProjectReportIdFileQuery>;
export type DailyProjectReportIdFileLazyQueryHookResult = ReturnType<typeof useDailyProjectReportIdFileLazyQuery>;
export type DailyProjectReportIdFileSuspenseQueryHookResult = ReturnType<
  typeof useDailyProjectReportIdFileSuspenseQuery
>;
export type DailyProjectReportIdFileQueryResult = Apollo.QueryResult<
  DailyProjectReportIdFileQuery,
  DailyProjectReportIdFileQueryVariables
>;
export const QueryMachineVsOperatorEquipmentDayDetailsRuntimeDocument = gql`
  query queryMachineVsOperatorEquipmentDayDetailsRuntime($filter: EquipmentHoursFilter, $after: String) {
    equipmentHours(sort: { createdOn: asc }, filter: $filter, after: $after) {
      submittedByMemberId
      runningHours
      createdOn
    }
  }
`;

/**
 * __useQueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery__
 *
 * To run a query within a React component, call `useQueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsRuntimeDocument, options);
}
export function useQueryMachineVsOperatorEquipmentDayDetailsRuntimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsRuntimeDocument, options);
}
export function useQueryMachineVsOperatorEquipmentDayDetailsRuntimeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
    QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsRuntimeDocument, options);
}
export type QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsRuntimeLazyQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsRuntimeLazyQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsRuntimeSuspenseQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsRuntimeSuspenseQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryResult = Apollo.QueryResult<
  QueryMachineVsOperatorEquipmentDayDetailsRuntimeQuery,
  QueryMachineVsOperatorEquipmentDayDetailsRuntimeQueryVariables
>;
export const QueryMachineVsOperatorEquipmentDayDetailsLaborDocument = gql`
  query queryMachineVsOperatorEquipmentDayDetailsLabor(
    $filter: EquipmentFilter
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $after: String
  ) {
    equipment(filter: $filter, after: $after) {
      equipmentMemberLaborMetrics(start: $metricsStartDate, end: $metricsEndDate, interval: $metricsInterval) {
        memberId
        start
        end
        dt
        ot
        rt
      }
    }
  }
`;

/**
 * __useQueryMachineVsOperatorEquipmentDayDetailsLaborQuery__
 *
 * To run a query within a React component, call `useQueryMachineVsOperatorEquipmentDayDetailsLaborQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineVsOperatorEquipmentDayDetailsLaborQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineVsOperatorEquipmentDayDetailsLaborQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useQueryMachineVsOperatorEquipmentDayDetailsLaborQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
    QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
  > &
    ({ variables: QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
    QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsLaborDocument, options);
}
export function useQueryMachineVsOperatorEquipmentDayDetailsLaborLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
    QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
    QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsLaborDocument, options);
}
export function useQueryMachineVsOperatorEquipmentDayDetailsLaborSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
    QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
    QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsLaborDocument, options);
}
export type QueryMachineVsOperatorEquipmentDayDetailsLaborQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsLaborQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsLaborLazyQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsLaborLazyQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsLaborSuspenseQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsLaborSuspenseQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsLaborQueryResult = Apollo.QueryResult<
  QueryMachineVsOperatorEquipmentDayDetailsLaborQuery,
  QueryMachineVsOperatorEquipmentDayDetailsLaborQueryVariables
>;
export const QueryMachineVsOperatorEquipmentDayDetailsMembersDocument = gql`
  query queryMachineVsOperatorEquipmentDayDetailsMembers($memberIds: [Uuid!]) {
    members(filter: { id: { contains: $memberIds } }) {
      cursor
      id
      firstName
      lastName
      memberGroupId
      position {
        level
      }
    }
  }
`;

/**
 * __useQueryMachineVsOperatorEquipmentDayDetailsMembersQuery__
 *
 * To run a query within a React component, call `useQueryMachineVsOperatorEquipmentDayDetailsMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineVsOperatorEquipmentDayDetailsMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineVsOperatorEquipmentDayDetailsMembersQuery({
 *   variables: {
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useQueryMachineVsOperatorEquipmentDayDetailsMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
    QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
    QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsMembersDocument, options);
}
export function useQueryMachineVsOperatorEquipmentDayDetailsMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
    QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
    QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsMembersDocument, options);
}
export function useQueryMachineVsOperatorEquipmentDayDetailsMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
    QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
    QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
  >(QueryMachineVsOperatorEquipmentDayDetailsMembersDocument, options);
}
export type QueryMachineVsOperatorEquipmentDayDetailsMembersQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsMembersQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsMembersLazyQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsMembersLazyQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsMembersSuspenseQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorEquipmentDayDetailsMembersSuspenseQuery
>;
export type QueryMachineVsOperatorEquipmentDayDetailsMembersQueryResult = Apollo.QueryResult<
  QueryMachineVsOperatorEquipmentDayDetailsMembersQuery,
  QueryMachineVsOperatorEquipmentDayDetailsMembersQueryVariables
>;
export const QueryMachineVsOperatorReportEquipmentByCategoryDocument = gql`
  query queryMachineVsOperatorReportEquipmentByCategory($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter) {
      id
      cursor
    }
  }
`;

/**
 * __useQueryMachineVsOperatorReportEquipmentByCategoryQuery__
 *
 * To run a query within a React component, call `useQueryMachineVsOperatorReportEquipmentByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineVsOperatorReportEquipmentByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineVsOperatorReportEquipmentByCategoryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryMachineVsOperatorReportEquipmentByCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryMachineVsOperatorReportEquipmentByCategoryQuery,
    QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMachineVsOperatorReportEquipmentByCategoryQuery,
    QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
  >(QueryMachineVsOperatorReportEquipmentByCategoryDocument, options);
}
export function useQueryMachineVsOperatorReportEquipmentByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMachineVsOperatorReportEquipmentByCategoryQuery,
    QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMachineVsOperatorReportEquipmentByCategoryQuery,
    QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
  >(QueryMachineVsOperatorReportEquipmentByCategoryDocument, options);
}
export function useQueryMachineVsOperatorReportEquipmentByCategorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMachineVsOperatorReportEquipmentByCategoryQuery,
    QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMachineVsOperatorReportEquipmentByCategoryQuery,
    QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
  >(QueryMachineVsOperatorReportEquipmentByCategoryDocument, options);
}
export type QueryMachineVsOperatorReportEquipmentByCategoryQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorReportEquipmentByCategoryQuery
>;
export type QueryMachineVsOperatorReportEquipmentByCategoryLazyQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorReportEquipmentByCategoryLazyQuery
>;
export type QueryMachineVsOperatorReportEquipmentByCategorySuspenseQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorReportEquipmentByCategorySuspenseQuery
>;
export type QueryMachineVsOperatorReportEquipmentByCategoryQueryResult = Apollo.QueryResult<
  QueryMachineVsOperatorReportEquipmentByCategoryQuery,
  QueryMachineVsOperatorReportEquipmentByCategoryQueryVariables
>;
export const QueryMachineVsOperatorReportTimeMetricsDocument = gql`
  query queryMachineVsOperatorReportTimeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      equipmentLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        start
        end
        equipmentId
        dt
        ot
        rt
      }
    }
  }
`;

/**
 * __useQueryMachineVsOperatorReportTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMachineVsOperatorReportTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMachineVsOperatorReportTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMachineVsOperatorReportTimeMetricsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryMachineVsOperatorReportTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMachineVsOperatorReportTimeMetricsQuery,
    QueryMachineVsOperatorReportTimeMetricsQueryVariables
  > &
    ({ variables: QueryMachineVsOperatorReportTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMachineVsOperatorReportTimeMetricsQuery,
    QueryMachineVsOperatorReportTimeMetricsQueryVariables
  >(QueryMachineVsOperatorReportTimeMetricsDocument, options);
}
export function useQueryMachineVsOperatorReportTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMachineVsOperatorReportTimeMetricsQuery,
    QueryMachineVsOperatorReportTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMachineVsOperatorReportTimeMetricsQuery,
    QueryMachineVsOperatorReportTimeMetricsQueryVariables
  >(QueryMachineVsOperatorReportTimeMetricsDocument, options);
}
export function useQueryMachineVsOperatorReportTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMachineVsOperatorReportTimeMetricsQuery,
    QueryMachineVsOperatorReportTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMachineVsOperatorReportTimeMetricsQuery,
    QueryMachineVsOperatorReportTimeMetricsQueryVariables
  >(QueryMachineVsOperatorReportTimeMetricsDocument, options);
}
export type QueryMachineVsOperatorReportTimeMetricsQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorReportTimeMetricsQuery
>;
export type QueryMachineVsOperatorReportTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorReportTimeMetricsLazyQuery
>;
export type QueryMachineVsOperatorReportTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMachineVsOperatorReportTimeMetricsSuspenseQuery
>;
export type QueryMachineVsOperatorReportTimeMetricsQueryResult = Apollo.QueryResult<
  QueryMachineVsOperatorReportTimeMetricsQuery,
  QueryMachineVsOperatorReportTimeMetricsQueryVariables
>;
export const CostCodesDocument = gql`
  query costCodes($filter: CostCodeFilter!, $first: Int, $after: String, $sort: [CostCodeSort!]) {
    costCodes(filter: $filter, first: $first, after: $after, sort: $sort) {
      cursor
      ...CostCodeDetails
    }
  }
  ${CostCodeDetailsFragmentDoc}
`;

/**
 * __useCostCodesQuery__
 *
 * To run a query within a React component, call `useCostCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCostCodesQuery(
  baseOptions: Apollo.QueryHookOptions<CostCodesQuery, CostCodesQueryVariables> &
    ({ variables: CostCodesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostCodesQuery, CostCodesQueryVariables>(CostCodesDocument, options);
}
export function useCostCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostCodesQuery, CostCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostCodesQuery, CostCodesQueryVariables>(CostCodesDocument, options);
}
export function useCostCodesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CostCodesQuery, CostCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CostCodesQuery, CostCodesQueryVariables>(CostCodesDocument, options);
}
export type CostCodesQueryHookResult = ReturnType<typeof useCostCodesQuery>;
export type CostCodesLazyQueryHookResult = ReturnType<typeof useCostCodesLazyQuery>;
export type CostCodesSuspenseQueryHookResult = ReturnType<typeof useCostCodesSuspenseQuery>;
export type CostCodesQueryResult = Apollo.QueryResult<CostCodesQuery, CostCodesQueryVariables>;
export const CreateCostCode2Document = gql`
  mutation createCostCode2($costCode: CostCodeCreateInput!) {
    createCostCode(costCode: $costCode) {
      ...CostCodeDetails
    }
  }
  ${CostCodeDetailsFragmentDoc}
`;
export type CreateCostCode2MutationFn = Apollo.MutationFunction<
  CreateCostCode2Mutation,
  CreateCostCode2MutationVariables
>;

/**
 * __useCreateCostCode2Mutation__
 *
 * To run a mutation, you first call `useCreateCostCode2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCode2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCode2Mutation, { data, loading, error }] = useCreateCostCode2Mutation({
 *   variables: {
 *      costCode: // value for 'costCode'
 *   },
 * });
 */
export function useCreateCostCode2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCostCode2Mutation, CreateCostCode2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCostCode2Mutation, CreateCostCode2MutationVariables>(
    CreateCostCode2Document,
    options
  );
}
export type CreateCostCode2MutationHookResult = ReturnType<typeof useCreateCostCode2Mutation>;
export type CreateCostCode2MutationResult = Apollo.MutationResult<CreateCostCode2Mutation>;
export type CreateCostCode2MutationOptions = Apollo.BaseMutationOptions<
  CreateCostCode2Mutation,
  CreateCostCode2MutationVariables
>;
export const UpdateCostCodeDocument = gql`
  mutation updateCostCode($costCode: CostCodeUpdateInput!) {
    updateCostCode(costCode: $costCode) {
      ...CostCodeDetails
    }
  }
  ${CostCodeDetailsFragmentDoc}
`;
export type UpdateCostCodeMutationFn = Apollo.MutationFunction<UpdateCostCodeMutation, UpdateCostCodeMutationVariables>;

/**
 * __useUpdateCostCodeMutation__
 *
 * To run a mutation, you first call `useUpdateCostCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCodeMutation, { data, loading, error }] = useUpdateCostCodeMutation({
 *   variables: {
 *      costCode: // value for 'costCode'
 *   },
 * });
 */
export function useUpdateCostCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCostCodeMutation, UpdateCostCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCostCodeMutation, UpdateCostCodeMutationVariables>(UpdateCostCodeDocument, options);
}
export type UpdateCostCodeMutationHookResult = ReturnType<typeof useUpdateCostCodeMutation>;
export type UpdateCostCodeMutationResult = Apollo.MutationResult<UpdateCostCodeMutation>;
export type UpdateCostCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostCodeMutation,
  UpdateCostCodeMutationVariables
>;
export const CreateCostCode3Document = gql`
  mutation createCostCode3($costCode: CostCodeCreateInput!) {
    createCostCode(costCode: $costCode) {
      costCode
      title
      unitTitle
    }
  }
`;
export type CreateCostCode3MutationFn = Apollo.MutationFunction<
  CreateCostCode3Mutation,
  CreateCostCode3MutationVariables
>;

/**
 * __useCreateCostCode3Mutation__
 *
 * To run a mutation, you first call `useCreateCostCode3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCode3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCode3Mutation, { data, loading, error }] = useCreateCostCode3Mutation({
 *   variables: {
 *      costCode: // value for 'costCode'
 *   },
 * });
 */
export function useCreateCostCode3Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCostCode3Mutation, CreateCostCode3MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCostCode3Mutation, CreateCostCode3MutationVariables>(
    CreateCostCode3Document,
    options
  );
}
export type CreateCostCode3MutationHookResult = ReturnType<typeof useCreateCostCode3Mutation>;
export type CreateCostCode3MutationResult = Apollo.MutationResult<CreateCostCode3Mutation>;
export type CreateCostCode3MutationOptions = Apollo.BaseMutationOptions<
  CreateCostCode3Mutation,
  CreateCostCode3MutationVariables
>;
export const QueryEmployeeByIdDocument = gql`
  query queryEmployeeById($memberId: Uuid!) {
    members(filter: { id: { equal: $memberId } }) {
      cursor
      ...EmployeeDetails
    }
  }
  ${EmployeeDetailsFragmentDoc}
`;

/**
 * __useQueryEmployeeByIdQuery__
 *
 * To run a query within a React component, call `useQueryEmployeeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmployeeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmployeeByIdQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useQueryEmployeeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables> &
    ({ variables: QueryEmployeeByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables>(QueryEmployeeByIdDocument, options);
}
export function useQueryEmployeeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables>(
    QueryEmployeeByIdDocument,
    options
  );
}
export function useQueryEmployeeByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables>(
    QueryEmployeeByIdDocument,
    options
  );
}
export type QueryEmployeeByIdQueryHookResult = ReturnType<typeof useQueryEmployeeByIdQuery>;
export type QueryEmployeeByIdLazyQueryHookResult = ReturnType<typeof useQueryEmployeeByIdLazyQuery>;
export type QueryEmployeeByIdSuspenseQueryHookResult = ReturnType<typeof useQueryEmployeeByIdSuspenseQuery>;
export type QueryEmployeeByIdQueryResult = Apollo.QueryResult<QueryEmployeeByIdQuery, QueryEmployeeByIdQueryVariables>;
export const QueryEmployeesListDocument = gql`
  query QueryEmployeesList($filter: MemberFilter!, $first: Int, $after: String, $sort: [MemberSort!]) {
    members(filter: $filter, first: $first, after: $after, sort: $sort) {
      cursor
      ...EmployeeDetails
    }
  }
  ${EmployeeDetailsFragmentDoc}
`;

/**
 * __useQueryEmployeesListQuery__
 *
 * To run a query within a React component, call `useQueryEmployeesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmployeesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmployeesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryEmployeesListQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEmployeesListQuery, QueryEmployeesListQueryVariables> &
    ({ variables: QueryEmployeesListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEmployeesListQuery, QueryEmployeesListQueryVariables>(
    QueryEmployeesListDocument,
    options
  );
}
export function useQueryEmployeesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEmployeesListQuery, QueryEmployeesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEmployeesListQuery, QueryEmployeesListQueryVariables>(
    QueryEmployeesListDocument,
    options
  );
}
export function useQueryEmployeesListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEmployeesListQuery, QueryEmployeesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEmployeesListQuery, QueryEmployeesListQueryVariables>(
    QueryEmployeesListDocument,
    options
  );
}
export type QueryEmployeesListQueryHookResult = ReturnType<typeof useQueryEmployeesListQuery>;
export type QueryEmployeesListLazyQueryHookResult = ReturnType<typeof useQueryEmployeesListLazyQuery>;
export type QueryEmployeesListSuspenseQueryHookResult = ReturnType<typeof useQueryEmployeesListSuspenseQuery>;
export type QueryEmployeesListQueryResult = Apollo.QueryResult<
  QueryEmployeesListQuery,
  QueryEmployeesListQueryVariables
>;
export const CreateMemberWithDetailsDocument = gql`
  mutation createMemberWithDetails($member: MemberCreateInput!) {
    createMember(member: $member) {
      ...EmployeeDetails
    }
  }
  ${EmployeeDetailsFragmentDoc}
`;
export type CreateMemberWithDetailsMutationFn = Apollo.MutationFunction<
  CreateMemberWithDetailsMutation,
  CreateMemberWithDetailsMutationVariables
>;

/**
 * __useCreateMemberWithDetailsMutation__
 *
 * To run a mutation, you first call `useCreateMemberWithDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberWithDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberWithDetailsMutation, { data, loading, error }] = useCreateMemberWithDetailsMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useCreateMemberWithDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMemberWithDetailsMutation, CreateMemberWithDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMemberWithDetailsMutation, CreateMemberWithDetailsMutationVariables>(
    CreateMemberWithDetailsDocument,
    options
  );
}
export type CreateMemberWithDetailsMutationHookResult = ReturnType<typeof useCreateMemberWithDetailsMutation>;
export type CreateMemberWithDetailsMutationResult = Apollo.MutationResult<CreateMemberWithDetailsMutation>;
export type CreateMemberWithDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateMemberWithDetailsMutation,
  CreateMemberWithDetailsMutationVariables
>;
export const BasicUpdateMemberDocument = gql`
  mutation basicUpdateMember($member: MemberUpdateInput!) {
    updateMember(member: $member) {
      ...EmployeeDetails
    }
  }
  ${EmployeeDetailsFragmentDoc}
`;
export type BasicUpdateMemberMutationFn = Apollo.MutationFunction<
  BasicUpdateMemberMutation,
  BasicUpdateMemberMutationVariables
>;

/**
 * __useBasicUpdateMemberMutation__
 *
 * To run a mutation, you first call `useBasicUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBasicUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [basicUpdateMemberMutation, { data, loading, error }] = useBasicUpdateMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useBasicUpdateMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<BasicUpdateMemberMutation, BasicUpdateMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BasicUpdateMemberMutation, BasicUpdateMemberMutationVariables>(
    BasicUpdateMemberDocument,
    options
  );
}
export type BasicUpdateMemberMutationHookResult = ReturnType<typeof useBasicUpdateMemberMutation>;
export type BasicUpdateMemberMutationResult = Apollo.MutationResult<BasicUpdateMemberMutation>;
export type BasicUpdateMemberMutationOptions = Apollo.BaseMutationOptions<
  BasicUpdateMemberMutation,
  BasicUpdateMemberMutationVariables
>;
export const ManageEquipmentMapQueryDocument = gql`
  query manageEquipmentMapQuery($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      imageUrl
      importTtlSeconds
      importType
      lastHoursId
      lastLocationId
      lastLocation {
        id
        latitude
        longitude
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          imageUrl
          review
          submittedOn
          title
          updatedOn
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        imageUrl
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
      lastHours {
        id
        runningHours
      }
    }
  }
`;

/**
 * __useManageEquipmentMapQueryQuery__
 *
 * To run a query within a React component, call `useManageEquipmentMapQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageEquipmentMapQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageEquipmentMapQueryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useManageEquipmentMapQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<ManageEquipmentMapQueryQuery, ManageEquipmentMapQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageEquipmentMapQueryQuery, ManageEquipmentMapQueryQueryVariables>(
    ManageEquipmentMapQueryDocument,
    options
  );
}
export function useManageEquipmentMapQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageEquipmentMapQueryQuery, ManageEquipmentMapQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageEquipmentMapQueryQuery, ManageEquipmentMapQueryQueryVariables>(
    ManageEquipmentMapQueryDocument,
    options
  );
}
export function useManageEquipmentMapQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ManageEquipmentMapQueryQuery, ManageEquipmentMapQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ManageEquipmentMapQueryQuery, ManageEquipmentMapQueryQueryVariables>(
    ManageEquipmentMapQueryDocument,
    options
  );
}
export type ManageEquipmentMapQueryQueryHookResult = ReturnType<typeof useManageEquipmentMapQueryQuery>;
export type ManageEquipmentMapQueryLazyQueryHookResult = ReturnType<typeof useManageEquipmentMapQueryLazyQuery>;
export type ManageEquipmentMapQuerySuspenseQueryHookResult = ReturnType<typeof useManageEquipmentMapQuerySuspenseQuery>;
export type ManageEquipmentMapQueryQueryResult = Apollo.QueryResult<
  ManageEquipmentMapQueryQuery,
  ManageEquipmentMapQueryQueryVariables
>;
export const ManageProjectsMapQueryDocument = gql`
  query manageProjectsMapQuery($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, filter: $filter, sort: $sort, first: $first) {
      id
      cursor
      title
      archivedOn
      rootProjectId
      rootProject {
        id
        title
        archivedOn
        parentProjectId
      }
      projectInfo {
        id
        number
        address1
        address2
        city
        country
        state
        postalCode
        latitude
        longitude
        locationRadius
        additionalInfo
        distantLocationFlaggingDisabled
        requireTimeEntryGps
        reminder
        phone
        projectId
      }
      parentProject {
        id
        archivedOn
      }
      children {
        id
        title
        depth
        archivedOn
        parentProjectId
        rootProjectId
        rootProject {
          id
          title
          archivedOn
          parentProjectId
          parentProject {
            id
            archivedOn
          }
        }
        projectInfo {
          id
          number
          address1
          address2
          city
          country
          state
          postalCode
          latitude
          longitude
          locationRadius
          additionalInfo
          distantLocationFlaggingDisabled
          requireTimeEntryGps
          reminder
          phone
          projectId
        }
        parentProject {
          id
          archivedOn
        }
      }
      parentProjectId
      projectGroup {
        id
        deletedOn
        groupName
      }
      projectGroupId
    }
  }
`;

/**
 * __useManageProjectsMapQueryQuery__
 *
 * To run a query within a React component, call `useManageProjectsMapQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageProjectsMapQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageProjectsMapQueryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useManageProjectsMapQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<ManageProjectsMapQueryQuery, ManageProjectsMapQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManageProjectsMapQueryQuery, ManageProjectsMapQueryQueryVariables>(
    ManageProjectsMapQueryDocument,
    options
  );
}
export function useManageProjectsMapQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManageProjectsMapQueryQuery, ManageProjectsMapQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManageProjectsMapQueryQuery, ManageProjectsMapQueryQueryVariables>(
    ManageProjectsMapQueryDocument,
    options
  );
}
export function useManageProjectsMapQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ManageProjectsMapQueryQuery, ManageProjectsMapQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ManageProjectsMapQueryQuery, ManageProjectsMapQueryQueryVariables>(
    ManageProjectsMapQueryDocument,
    options
  );
}
export type ManageProjectsMapQueryQueryHookResult = ReturnType<typeof useManageProjectsMapQueryQuery>;
export type ManageProjectsMapQueryLazyQueryHookResult = ReturnType<typeof useManageProjectsMapQueryLazyQuery>;
export type ManageProjectsMapQuerySuspenseQueryHookResult = ReturnType<typeof useManageProjectsMapQuerySuspenseQuery>;
export type ManageProjectsMapQueryQueryResult = Apollo.QueryResult<
  ManageProjectsMapQueryQuery,
  ManageProjectsMapQueryQueryVariables
>;
export const ProjectCostCodeLinksDocument = gql`
  query ProjectCostCodeLinks($filter: ProjectCostCodeLinkFilter, $sort: [ProjectCostCodeLinkSort!]) {
    projectCostCodeLinks(filter: $filter, first: 500, sort: $sort) {
      id
      costCodeId
      costCode {
        id
        title
        costCode
        unitTitle
        costCodeGroup {
          groupName
          id
        }
      }
    }
  }
`;

/**
 * __useProjectCostCodeLinksQuery__
 *
 * To run a query within a React component, call `useProjectCostCodeLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostCodeLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostCodeLinksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProjectCostCodeLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectCostCodeLinksQuery, ProjectCostCodeLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectCostCodeLinksQuery, ProjectCostCodeLinksQueryVariables>(
    ProjectCostCodeLinksDocument,
    options
  );
}
export function useProjectCostCodeLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectCostCodeLinksQuery, ProjectCostCodeLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectCostCodeLinksQuery, ProjectCostCodeLinksQueryVariables>(
    ProjectCostCodeLinksDocument,
    options
  );
}
export function useProjectCostCodeLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectCostCodeLinksQuery, ProjectCostCodeLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectCostCodeLinksQuery, ProjectCostCodeLinksQueryVariables>(
    ProjectCostCodeLinksDocument,
    options
  );
}
export type ProjectCostCodeLinksQueryHookResult = ReturnType<typeof useProjectCostCodeLinksQuery>;
export type ProjectCostCodeLinksLazyQueryHookResult = ReturnType<typeof useProjectCostCodeLinksLazyQuery>;
export type ProjectCostCodeLinksSuspenseQueryHookResult = ReturnType<typeof useProjectCostCodeLinksSuspenseQuery>;
export type ProjectCostCodeLinksQueryResult = Apollo.QueryResult<
  ProjectCostCodeLinksQuery,
  ProjectCostCodeLinksQueryVariables
>;
export const UnjoinedProjectCostCodeLinksDocument = gql`
  query UnjoinedProjectCostCodeLinks(
    $after: String
    $filter: ProjectCostCodeLinkFilter
    $sort: [ProjectCostCodeLinkSort!]
  ) {
    projectCostCodeLinks(filter: $filter, first: 500, sort: $sort, after: $after) {
      id
      costCodeId
      projectId
      cursor
    }
  }
`;

/**
 * __useUnjoinedProjectCostCodeLinksQuery__
 *
 * To run a query within a React component, call `useUnjoinedProjectCostCodeLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnjoinedProjectCostCodeLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnjoinedProjectCostCodeLinksQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUnjoinedProjectCostCodeLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<UnjoinedProjectCostCodeLinksQuery, UnjoinedProjectCostCodeLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnjoinedProjectCostCodeLinksQuery, UnjoinedProjectCostCodeLinksQueryVariables>(
    UnjoinedProjectCostCodeLinksDocument,
    options
  );
}
export function useUnjoinedProjectCostCodeLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnjoinedProjectCostCodeLinksQuery,
    UnjoinedProjectCostCodeLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnjoinedProjectCostCodeLinksQuery, UnjoinedProjectCostCodeLinksQueryVariables>(
    UnjoinedProjectCostCodeLinksDocument,
    options
  );
}
export function useUnjoinedProjectCostCodeLinksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UnjoinedProjectCostCodeLinksQuery,
    UnjoinedProjectCostCodeLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UnjoinedProjectCostCodeLinksQuery, UnjoinedProjectCostCodeLinksQueryVariables>(
    UnjoinedProjectCostCodeLinksDocument,
    options
  );
}
export type UnjoinedProjectCostCodeLinksQueryHookResult = ReturnType<typeof useUnjoinedProjectCostCodeLinksQuery>;
export type UnjoinedProjectCostCodeLinksLazyQueryHookResult = ReturnType<
  typeof useUnjoinedProjectCostCodeLinksLazyQuery
>;
export type UnjoinedProjectCostCodeLinksSuspenseQueryHookResult = ReturnType<
  typeof useUnjoinedProjectCostCodeLinksSuspenseQuery
>;
export type UnjoinedProjectCostCodeLinksQueryResult = Apollo.QueryResult<
  UnjoinedProjectCostCodeLinksQuery,
  UnjoinedProjectCostCodeLinksQueryVariables
>;
export const UpdateProjectCostCodeLinkDocument = gql`
  mutation UpdateProjectCostCodeLink($projectCostCodeLink: ProjectCostCodeLinkUpdateInput) {
    updateProjectCostCodeLink(projectCostCodeLink: $projectCostCodeLink) {
      id
    }
  }
`;
export type UpdateProjectCostCodeLinkMutationFn = Apollo.MutationFunction<
  UpdateProjectCostCodeLinkMutation,
  UpdateProjectCostCodeLinkMutationVariables
>;

/**
 * __useUpdateProjectCostCodeLinkMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCostCodeLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCostCodeLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCostCodeLinkMutation, { data, loading, error }] = useUpdateProjectCostCodeLinkMutation({
 *   variables: {
 *      projectCostCodeLink: // value for 'projectCostCodeLink'
 *   },
 * });
 */
export function useUpdateProjectCostCodeLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectCostCodeLinkMutation,
    UpdateProjectCostCodeLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectCostCodeLinkMutation, UpdateProjectCostCodeLinkMutationVariables>(
    UpdateProjectCostCodeLinkDocument,
    options
  );
}
export type UpdateProjectCostCodeLinkMutationHookResult = ReturnType<typeof useUpdateProjectCostCodeLinkMutation>;
export type UpdateProjectCostCodeLinkMutationResult = Apollo.MutationResult<UpdateProjectCostCodeLinkMutation>;
export type UpdateProjectCostCodeLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectCostCodeLinkMutation,
  UpdateProjectCostCodeLinkMutationVariables
>;
export const CreateProjectCostCodeLinkDocument = gql`
  mutation CreateProjectCostCodeLink($projectCostCodeLink: ProjectCostCodeLinkCreateInput) {
    createProjectCostCodeLink(projectCostCodeLink: $projectCostCodeLink) {
      id
    }
  }
`;
export type CreateProjectCostCodeLinkMutationFn = Apollo.MutationFunction<
  CreateProjectCostCodeLinkMutation,
  CreateProjectCostCodeLinkMutationVariables
>;

/**
 * __useCreateProjectCostCodeLinkMutation__
 *
 * To run a mutation, you first call `useCreateProjectCostCodeLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectCostCodeLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectCostCodeLinkMutation, { data, loading, error }] = useCreateProjectCostCodeLinkMutation({
 *   variables: {
 *      projectCostCodeLink: // value for 'projectCostCodeLink'
 *   },
 * });
 */
export function useCreateProjectCostCodeLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectCostCodeLinkMutation,
    CreateProjectCostCodeLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectCostCodeLinkMutation, CreateProjectCostCodeLinkMutationVariables>(
    CreateProjectCostCodeLinkDocument,
    options
  );
}
export type CreateProjectCostCodeLinkMutationHookResult = ReturnType<typeof useCreateProjectCostCodeLinkMutation>;
export type CreateProjectCostCodeLinkMutationResult = Apollo.MutationResult<CreateProjectCostCodeLinkMutation>;
export type CreateProjectCostCodeLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectCostCodeLinkMutation,
  CreateProjectCostCodeLinkMutationVariables
>;
export const GetProjectsDocument = gql`
  query GetProjects($filter: ProjectFilter, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, first: $first, after: $after, sort: $sort) {
      cursor
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export function useGetProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsSuspenseQueryHookResult = ReturnType<typeof useGetProjectsSuspenseQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const ProjectWithDetailsDocument = gql`
  query ProjectWithDetails($filter: ProjectFilter!, $after: String) {
    projects(filter: $filter, after: $after) {
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;

/**
 * __useProjectWithDetailsQuery__
 *
 * To run a query within a React component, call `useProjectWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectWithDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectWithDetailsQuery, ProjectWithDetailsQueryVariables> &
    ({ variables: ProjectWithDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectWithDetailsQuery, ProjectWithDetailsQueryVariables>(
    ProjectWithDetailsDocument,
    options
  );
}
export function useProjectWithDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectWithDetailsQuery, ProjectWithDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectWithDetailsQuery, ProjectWithDetailsQueryVariables>(
    ProjectWithDetailsDocument,
    options
  );
}
export function useProjectWithDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectWithDetailsQuery, ProjectWithDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectWithDetailsQuery, ProjectWithDetailsQueryVariables>(
    ProjectWithDetailsDocument,
    options
  );
}
export type ProjectWithDetailsQueryHookResult = ReturnType<typeof useProjectWithDetailsQuery>;
export type ProjectWithDetailsLazyQueryHookResult = ReturnType<typeof useProjectWithDetailsLazyQuery>;
export type ProjectWithDetailsSuspenseQueryHookResult = ReturnType<typeof useProjectWithDetailsSuspenseQuery>;
export type ProjectWithDetailsQueryResult = Apollo.QueryResult<
  ProjectWithDetailsQuery,
  ProjectWithDetailsQueryVariables
>;
export const DuplicateProjectDocument = gql`
  query DuplicateProject($filter: ProjectFilter!) {
    projects(filter: $filter) {
      title
      parentProjectId
      depth
      archivedOn
      projectCostCodeLink {
        id
        costCodeId
        deletedOn
      }
      children {
        id
        archivedOn
        projectCostCodeLink {
          id
          costCodeId
          deletedOn
        }
      }
    }
  }
`;

/**
 * __useDuplicateProjectQuery__
 *
 * To run a query within a React component, call `useDuplicateProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuplicateProjectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDuplicateProjectQuery(
  baseOptions: Apollo.QueryHookOptions<DuplicateProjectQuery, DuplicateProjectQueryVariables> &
    ({ variables: DuplicateProjectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DuplicateProjectQuery, DuplicateProjectQueryVariables>(DuplicateProjectDocument, options);
}
export function useDuplicateProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DuplicateProjectQuery, DuplicateProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DuplicateProjectQuery, DuplicateProjectQueryVariables>(DuplicateProjectDocument, options);
}
export function useDuplicateProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DuplicateProjectQuery, DuplicateProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DuplicateProjectQuery, DuplicateProjectQueryVariables>(
    DuplicateProjectDocument,
    options
  );
}
export type DuplicateProjectQueryHookResult = ReturnType<typeof useDuplicateProjectQuery>;
export type DuplicateProjectLazyQueryHookResult = ReturnType<typeof useDuplicateProjectLazyQuery>;
export type DuplicateProjectSuspenseQueryHookResult = ReturnType<typeof useDuplicateProjectSuspenseQuery>;
export type DuplicateProjectQueryResult = Apollo.QueryResult<DuplicateProjectQuery, DuplicateProjectQueryVariables>;
export const FlatProjectDocument = gql`
  query FlatProject($filter: ProjectFilter!, $sort: [ProjectSort!], $after: String, $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      organizationId
      parentProjectId
      projectGroupId
      rootProjectId
      title
      depth
      archivedOn
      createdOn
      updatedOn
      cursor
    }
  }
`;

/**
 * __useFlatProjectQuery__
 *
 * To run a query within a React component, call `useFlatProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlatProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlatProjectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFlatProjectQuery(
  baseOptions: Apollo.QueryHookOptions<FlatProjectQuery, FlatProjectQueryVariables> &
    ({ variables: FlatProjectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlatProjectQuery, FlatProjectQueryVariables>(FlatProjectDocument, options);
}
export function useFlatProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlatProjectQuery, FlatProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlatProjectQuery, FlatProjectQueryVariables>(FlatProjectDocument, options);
}
export function useFlatProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FlatProjectQuery, FlatProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FlatProjectQuery, FlatProjectQueryVariables>(FlatProjectDocument, options);
}
export type FlatProjectQueryHookResult = ReturnType<typeof useFlatProjectQuery>;
export type FlatProjectLazyQueryHookResult = ReturnType<typeof useFlatProjectLazyQuery>;
export type FlatProjectSuspenseQueryHookResult = ReturnType<typeof useFlatProjectSuspenseQuery>;
export type FlatProjectQueryResult = Apollo.QueryResult<FlatProjectQuery, FlatProjectQueryVariables>;
export const ReimportProjectQueryDocument = gql`
  query reimportProjectQuery($filter: ProjectFilter!, $after: String) {
    projects(filter: $filter, after: $after) {
      ...FlatProjectDetails
      ...JoinedProjectGroup
      ...JoinedProjectInfo
      ancestors {
        id
        title
        depth
      }
      projectInfo {
        ...Info
      }
    }
  }
  ${FlatProjectDetailsFragmentDoc}
  ${JoinedProjectGroupFragmentDoc}
  ${JoinedProjectInfoFragmentDoc}
  ${InfoFragmentDoc}
`;

/**
 * __useReimportProjectQueryQuery__
 *
 * To run a query within a React component, call `useReimportProjectQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReimportProjectQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReimportProjectQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useReimportProjectQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ReimportProjectQueryQuery, ReimportProjectQueryQueryVariables> &
    ({ variables: ReimportProjectQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReimportProjectQueryQuery, ReimportProjectQueryQueryVariables>(
    ReimportProjectQueryDocument,
    options
  );
}
export function useReimportProjectQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReimportProjectQueryQuery, ReimportProjectQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReimportProjectQueryQuery, ReimportProjectQueryQueryVariables>(
    ReimportProjectQueryDocument,
    options
  );
}
export function useReimportProjectQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ReimportProjectQueryQuery, ReimportProjectQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ReimportProjectQueryQuery, ReimportProjectQueryQueryVariables>(
    ReimportProjectQueryDocument,
    options
  );
}
export type ReimportProjectQueryQueryHookResult = ReturnType<typeof useReimportProjectQueryQuery>;
export type ReimportProjectQueryLazyQueryHookResult = ReturnType<typeof useReimportProjectQueryLazyQuery>;
export type ReimportProjectQuerySuspenseQueryHookResult = ReturnType<typeof useReimportProjectQuerySuspenseQuery>;
export type ReimportProjectQueryQueryResult = Apollo.QueryResult<
  ReimportProjectQueryQuery,
  ReimportProjectQueryQueryVariables
>;
export const ProjectThatIsHomelessAndWithoutFamilyDocument = gql`
  query ProjectThatIsHomelessAndWithoutFamily($filter: ProjectFilter!, $after: String) {
    projects(filter: $filter, after: $after) {
      id
      organizationId
      parentProjectId
      projectGroupId
      rootProjectId
      title
      depth
      archivedOn
      createdOn
      updatedOn
      cursor
      projectGroup {
        id
        deletedOn
        groupName
      }
      projectInfo {
        address1
        address2
        city
        country
        customer
        deletedOn
        id
        latitude
        locationRadius
        longitude
        number
        requireTimeEntryGps
        phone
        postalCode
        projectId
        reminder
        state
      }
    }
  }
`;

/**
 * __useProjectThatIsHomelessAndWithoutFamilyQuery__
 *
 * To run a query within a React component, call `useProjectThatIsHomelessAndWithoutFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectThatIsHomelessAndWithoutFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectThatIsHomelessAndWithoutFamilyQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectThatIsHomelessAndWithoutFamilyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectThatIsHomelessAndWithoutFamilyQuery,
    ProjectThatIsHomelessAndWithoutFamilyQueryVariables
  > &
    ({ variables: ProjectThatIsHomelessAndWithoutFamilyQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectThatIsHomelessAndWithoutFamilyQuery,
    ProjectThatIsHomelessAndWithoutFamilyQueryVariables
  >(ProjectThatIsHomelessAndWithoutFamilyDocument, options);
}
export function useProjectThatIsHomelessAndWithoutFamilyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectThatIsHomelessAndWithoutFamilyQuery,
    ProjectThatIsHomelessAndWithoutFamilyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectThatIsHomelessAndWithoutFamilyQuery,
    ProjectThatIsHomelessAndWithoutFamilyQueryVariables
  >(ProjectThatIsHomelessAndWithoutFamilyDocument, options);
}
export function useProjectThatIsHomelessAndWithoutFamilySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectThatIsHomelessAndWithoutFamilyQuery,
    ProjectThatIsHomelessAndWithoutFamilyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectThatIsHomelessAndWithoutFamilyQuery,
    ProjectThatIsHomelessAndWithoutFamilyQueryVariables
  >(ProjectThatIsHomelessAndWithoutFamilyDocument, options);
}
export type ProjectThatIsHomelessAndWithoutFamilyQueryHookResult = ReturnType<
  typeof useProjectThatIsHomelessAndWithoutFamilyQuery
>;
export type ProjectThatIsHomelessAndWithoutFamilyLazyQueryHookResult = ReturnType<
  typeof useProjectThatIsHomelessAndWithoutFamilyLazyQuery
>;
export type ProjectThatIsHomelessAndWithoutFamilySuspenseQueryHookResult = ReturnType<
  typeof useProjectThatIsHomelessAndWithoutFamilySuspenseQuery
>;
export type ProjectThatIsHomelessAndWithoutFamilyQueryResult = Apollo.QueryResult<
  ProjectThatIsHomelessAndWithoutFamilyQuery,
  ProjectThatIsHomelessAndWithoutFamilyQueryVariables
>;
export const CreateProject1Document = gql`
  mutation createProject1($project: ProjectCreateInput!) {
    createProject(project: $project) {
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;
export type CreateProject1MutationFn = Apollo.MutationFunction<CreateProject1Mutation, CreateProject1MutationVariables>;

/**
 * __useCreateProject1Mutation__
 *
 * To run a mutation, you first call `useCreateProject1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProject1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProject1Mutation, { data, loading, error }] = useCreateProject1Mutation({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateProject1Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProject1Mutation, CreateProject1MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProject1Mutation, CreateProject1MutationVariables>(CreateProject1Document, options);
}
export type CreateProject1MutationHookResult = ReturnType<typeof useCreateProject1Mutation>;
export type CreateProject1MutationResult = Apollo.MutationResult<CreateProject1Mutation>;
export type CreateProject1MutationOptions = Apollo.BaseMutationOptions<
  CreateProject1Mutation,
  CreateProject1MutationVariables
>;
export const CreateProjectInfoDocument = gql`
  mutation createProjectInfo($projectInfo: ProjectInfoCreateInput!) {
    createProjectInfo(projectInfo: $projectInfo) {
      ...Info
    }
  }
  ${InfoFragmentDoc}
`;
export type CreateProjectInfoMutationFn = Apollo.MutationFunction<
  CreateProjectInfoMutation,
  CreateProjectInfoMutationVariables
>;

/**
 * __useCreateProjectInfoMutation__
 *
 * To run a mutation, you first call `useCreateProjectInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectInfoMutation, { data, loading, error }] = useCreateProjectInfoMutation({
 *   variables: {
 *      projectInfo: // value for 'projectInfo'
 *   },
 * });
 */
export function useCreateProjectInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectInfoMutation, CreateProjectInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectInfoMutation, CreateProjectInfoMutationVariables>(
    CreateProjectInfoDocument,
    options
  );
}
export type CreateProjectInfoMutationHookResult = ReturnType<typeof useCreateProjectInfoMutation>;
export type CreateProjectInfoMutationResult = Apollo.MutationResult<CreateProjectInfoMutation>;
export type CreateProjectInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectInfoMutation,
  CreateProjectInfoMutationVariables
>;
export const CreateProject2Document = gql`
  mutation createProject2($project: ProjectCreateInput!) {
    createProject(project: $project) {
      id
      depth
    }
  }
`;
export type CreateProject2MutationFn = Apollo.MutationFunction<CreateProject2Mutation, CreateProject2MutationVariables>;

/**
 * __useCreateProject2Mutation__
 *
 * To run a mutation, you first call `useCreateProject2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProject2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProject2Mutation, { data, loading, error }] = useCreateProject2Mutation({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useCreateProject2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProject2Mutation, CreateProject2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProject2Mutation, CreateProject2MutationVariables>(CreateProject2Document, options);
}
export type CreateProject2MutationHookResult = ReturnType<typeof useCreateProject2Mutation>;
export type CreateProject2MutationResult = Apollo.MutationResult<CreateProject2Mutation>;
export type CreateProject2MutationOptions = Apollo.BaseMutationOptions<
  CreateProject2Mutation,
  CreateProject2MutationVariables
>;
export const ProjectInfoOnlyIdMutationDocument = gql`
  mutation projectInfoOnlyIdMutation($projectInfo: ProjectInfoCreateInput!) {
    createProjectInfo(projectInfo: $projectInfo) {
      id
    }
  }
`;
export type ProjectInfoOnlyIdMutationMutationFn = Apollo.MutationFunction<
  ProjectInfoOnlyIdMutationMutation,
  ProjectInfoOnlyIdMutationMutationVariables
>;

/**
 * __useProjectInfoOnlyIdMutationMutation__
 *
 * To run a mutation, you first call `useProjectInfoOnlyIdMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectInfoOnlyIdMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectInfoOnlyIdMutationMutation, { data, loading, error }] = useProjectInfoOnlyIdMutationMutation({
 *   variables: {
 *      projectInfo: // value for 'projectInfo'
 *   },
 * });
 */
export function useProjectInfoOnlyIdMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProjectInfoOnlyIdMutationMutation,
    ProjectInfoOnlyIdMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProjectInfoOnlyIdMutationMutation, ProjectInfoOnlyIdMutationMutationVariables>(
    ProjectInfoOnlyIdMutationDocument,
    options
  );
}
export type ProjectInfoOnlyIdMutationMutationHookResult = ReturnType<typeof useProjectInfoOnlyIdMutationMutation>;
export type ProjectInfoOnlyIdMutationMutationResult = Apollo.MutationResult<ProjectInfoOnlyIdMutationMutation>;
export type ProjectInfoOnlyIdMutationMutationOptions = Apollo.BaseMutationOptions<
  ProjectInfoOnlyIdMutationMutation,
  ProjectInfoOnlyIdMutationMutationVariables
>;
export const UpdateProjectWithDetailsDocument = gql`
  mutation updateProjectWithDetails($project: ProjectUpdateInput!) {
    updateProject(project: $project) {
      ...ProjectDetails
    }
  }
  ${ProjectDetailsFragmentDoc}
`;
export type UpdateProjectWithDetailsMutationFn = Apollo.MutationFunction<
  UpdateProjectWithDetailsMutation,
  UpdateProjectWithDetailsMutationVariables
>;

/**
 * __useUpdateProjectWithDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectWithDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectWithDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectWithDetailsMutation, { data, loading, error }] = useUpdateProjectWithDetailsMutation({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useUpdateProjectWithDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectWithDetailsMutation, UpdateProjectWithDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectWithDetailsMutation, UpdateProjectWithDetailsMutationVariables>(
    UpdateProjectWithDetailsDocument,
    options
  );
}
export type UpdateProjectWithDetailsMutationHookResult = ReturnType<typeof useUpdateProjectWithDetailsMutation>;
export type UpdateProjectWithDetailsMutationResult = Apollo.MutationResult<UpdateProjectWithDetailsMutation>;
export type UpdateProjectWithDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectWithDetailsMutation,
  UpdateProjectWithDetailsMutationVariables
>;
export const UpdateProjectInfoWithInfoDocument = gql`
  mutation updateProjectInfoWithInfo($projectInfo: ProjectInfoUpdateInput!) {
    updateProjectInfo(projectInfo: $projectInfo) {
      ...Info
    }
  }
  ${InfoFragmentDoc}
`;
export type UpdateProjectInfoWithInfoMutationFn = Apollo.MutationFunction<
  UpdateProjectInfoWithInfoMutation,
  UpdateProjectInfoWithInfoMutationVariables
>;

/**
 * __useUpdateProjectInfoWithInfoMutation__
 *
 * To run a mutation, you first call `useUpdateProjectInfoWithInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectInfoWithInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectInfoWithInfoMutation, { data, loading, error }] = useUpdateProjectInfoWithInfoMutation({
 *   variables: {
 *      projectInfo: // value for 'projectInfo'
 *   },
 * });
 */
export function useUpdateProjectInfoWithInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectInfoWithInfoMutation,
    UpdateProjectInfoWithInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectInfoWithInfoMutation, UpdateProjectInfoWithInfoMutationVariables>(
    UpdateProjectInfoWithInfoDocument,
    options
  );
}
export type UpdateProjectInfoWithInfoMutationHookResult = ReturnType<typeof useUpdateProjectInfoWithInfoMutation>;
export type UpdateProjectInfoWithInfoMutationResult = Apollo.MutationResult<UpdateProjectInfoWithInfoMutation>;
export type UpdateProjectInfoWithInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectInfoWithInfoMutation,
  UpdateProjectInfoWithInfoMutationVariables
>;
export const ProjectWithAncestorsDocument = gql`
  query ProjectWithAncestors($filter: ProjectFilter!, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, after: $after, first: $first, sort: $sort) {
      id
      archivedOn
      projectInfo {
        additionalInfo
        address1
        address2
        city
        country
        customer
        id
        latitude
        locationRadius
        longitude
        number
        phone
        postalCode
        projectId
        reminder
        requireTimeEntryGps
        state
      }
      parentProjectId
      ancestors {
        id
        title
        depth
      }
      projectGroup {
        id
        deletedOn
        groupName
      }
      cursor
      projectGroupId
      rootProjectId
      submittedOn
      title
      createdOn
      updatedOn
      depth
    }
  }
`;

/**
 * __useProjectWithAncestorsQuery__
 *
 * To run a query within a React component, call `useProjectWithAncestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithAncestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithAncestorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProjectWithAncestorsQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectWithAncestorsQuery, ProjectWithAncestorsQueryVariables> &
    ({ variables: ProjectWithAncestorsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectWithAncestorsQuery, ProjectWithAncestorsQueryVariables>(
    ProjectWithAncestorsDocument,
    options
  );
}
export function useProjectWithAncestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectWithAncestorsQuery, ProjectWithAncestorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectWithAncestorsQuery, ProjectWithAncestorsQueryVariables>(
    ProjectWithAncestorsDocument,
    options
  );
}
export function useProjectWithAncestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectWithAncestorsQuery, ProjectWithAncestorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectWithAncestorsQuery, ProjectWithAncestorsQueryVariables>(
    ProjectWithAncestorsDocument,
    options
  );
}
export type ProjectWithAncestorsQueryHookResult = ReturnType<typeof useProjectWithAncestorsQuery>;
export type ProjectWithAncestorsLazyQueryHookResult = ReturnType<typeof useProjectWithAncestorsLazyQuery>;
export type ProjectWithAncestorsSuspenseQueryHookResult = ReturnType<typeof useProjectWithAncestorsSuspenseQuery>;
export type ProjectWithAncestorsQueryResult = Apollo.QueryResult<
  ProjectWithAncestorsQuery,
  ProjectWithAncestorsQueryVariables
>;
export const ProjectWithCostCodeLinksAncestorsChildrenDocument = gql`
  query ProjectWithCostCodeLinksAncestorsChildren($filter: ProjectFilter) {
    projects(filter: $filter) {
      id
      title
      depth
      projectGroupId
      parentProjectId
      rootProjectId
      archivedOn
      children {
        title
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        projectGroupId
        archivedOn
      }
      projectCostCodeLink {
        id
        costCodeId
        deletedOn
      }
    }
  }
`;

/**
 * __useProjectWithCostCodeLinksAncestorsChildrenQuery__
 *
 * To run a query within a React component, call `useProjectWithCostCodeLinksAncestorsChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithCostCodeLinksAncestorsChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithCostCodeLinksAncestorsChildrenQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectWithCostCodeLinksAncestorsChildrenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProjectWithCostCodeLinksAncestorsChildrenQuery,
    ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProjectWithCostCodeLinksAncestorsChildrenQuery,
    ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
  >(ProjectWithCostCodeLinksAncestorsChildrenDocument, options);
}
export function useProjectWithCostCodeLinksAncestorsChildrenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectWithCostCodeLinksAncestorsChildrenQuery,
    ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProjectWithCostCodeLinksAncestorsChildrenQuery,
    ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
  >(ProjectWithCostCodeLinksAncestorsChildrenDocument, options);
}
export function useProjectWithCostCodeLinksAncestorsChildrenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectWithCostCodeLinksAncestorsChildrenQuery,
    ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProjectWithCostCodeLinksAncestorsChildrenQuery,
    ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
  >(ProjectWithCostCodeLinksAncestorsChildrenDocument, options);
}
export type ProjectWithCostCodeLinksAncestorsChildrenQueryHookResult = ReturnType<
  typeof useProjectWithCostCodeLinksAncestorsChildrenQuery
>;
export type ProjectWithCostCodeLinksAncestorsChildrenLazyQueryHookResult = ReturnType<
  typeof useProjectWithCostCodeLinksAncestorsChildrenLazyQuery
>;
export type ProjectWithCostCodeLinksAncestorsChildrenSuspenseQueryHookResult = ReturnType<
  typeof useProjectWithCostCodeLinksAncestorsChildrenSuspenseQuery
>;
export type ProjectWithCostCodeLinksAncestorsChildrenQueryResult = Apollo.QueryResult<
  ProjectWithCostCodeLinksAncestorsChildrenQuery,
  ProjectWithCostCodeLinksAncestorsChildrenQueryVariables
>;
export const ProjectSubprojectDetailsDocument = gql`
  query ProjectSubprojectDetails($filter: ProjectFilter!) {
    projects(filter: $filter) {
      id
      children {
        id
        archivedOn
        projectInfo {
          ...Info
        }
        projectGroup {
          id
          deletedOn
          groupName
        }
        title
      }
    }
  }
  ${InfoFragmentDoc}
`;

/**
 * __useProjectSubprojectDetailsQuery__
 *
 * To run a query within a React component, call `useProjectSubprojectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSubprojectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSubprojectDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProjectSubprojectDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectSubprojectDetailsQuery, ProjectSubprojectDetailsQueryVariables> &
    ({ variables: ProjectSubprojectDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectSubprojectDetailsQuery, ProjectSubprojectDetailsQueryVariables>(
    ProjectSubprojectDetailsDocument,
    options
  );
}
export function useProjectSubprojectDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectSubprojectDetailsQuery, ProjectSubprojectDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectSubprojectDetailsQuery, ProjectSubprojectDetailsQueryVariables>(
    ProjectSubprojectDetailsDocument,
    options
  );
}
export function useProjectSubprojectDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectSubprojectDetailsQuery, ProjectSubprojectDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectSubprojectDetailsQuery, ProjectSubprojectDetailsQueryVariables>(
    ProjectSubprojectDetailsDocument,
    options
  );
}
export type ProjectSubprojectDetailsQueryHookResult = ReturnType<typeof useProjectSubprojectDetailsQuery>;
export type ProjectSubprojectDetailsLazyQueryHookResult = ReturnType<typeof useProjectSubprojectDetailsLazyQuery>;
export type ProjectSubprojectDetailsSuspenseQueryHookResult = ReturnType<
  typeof useProjectSubprojectDetailsSuspenseQuery
>;
export type ProjectSubprojectDetailsQueryResult = Apollo.QueryResult<
  ProjectSubprojectDetailsQuery,
  ProjectSubprojectDetailsQueryVariables
>;
export const QuickSearchProjectQueryDocument = gql`
  query QuickSearchProjectQuery($filter: ProjectFilter!, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, after: $after, first: $first, sort: $sort) {
      id
      cursor
      title
      depth
      rootProjectId
      archivedOn
    }
  }
`;

/**
 * __useQuickSearchProjectQueryQuery__
 *
 * To run a query within a React component, call `useQuickSearchProjectQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchProjectQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchProjectQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuickSearchProjectQueryQuery(
  baseOptions: Apollo.QueryHookOptions<QuickSearchProjectQueryQuery, QuickSearchProjectQueryQueryVariables> &
    ({ variables: QuickSearchProjectQueryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuickSearchProjectQueryQuery, QuickSearchProjectQueryQueryVariables>(
    QuickSearchProjectQueryDocument,
    options
  );
}
export function useQuickSearchProjectQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchProjectQueryQuery, QuickSearchProjectQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuickSearchProjectQueryQuery, QuickSearchProjectQueryQueryVariables>(
    QuickSearchProjectQueryDocument,
    options
  );
}
export function useQuickSearchProjectQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QuickSearchProjectQueryQuery, QuickSearchProjectQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QuickSearchProjectQueryQuery, QuickSearchProjectQueryQueryVariables>(
    QuickSearchProjectQueryDocument,
    options
  );
}
export type QuickSearchProjectQueryQueryHookResult = ReturnType<typeof useQuickSearchProjectQueryQuery>;
export type QuickSearchProjectQueryLazyQueryHookResult = ReturnType<typeof useQuickSearchProjectQueryLazyQuery>;
export type QuickSearchProjectQuerySuspenseQueryHookResult = ReturnType<typeof useQuickSearchProjectQuerySuspenseQuery>;
export type QuickSearchProjectQueryQueryResult = Apollo.QueryResult<
  QuickSearchProjectQueryQuery,
  QuickSearchProjectQueryQueryVariables
>;
export const QueryPayrollCostsMemberMetricsDocument = gql`
  query QueryPayrollCostsMemberMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      firstName
      lastName
      cursor
      wageHistories {
        id
        memberId
        wage
        wageRate
        workWeek
        effectiveRate
        overburden
        changeDate
        createdOn
        submittedOn
        updatedOn
        deletedOn
      }
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...AllMemberLaborMetrics
      }
    }
  }
  ${AllMemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryPayrollCostsMemberMetricsQuery__
 *
 * To run a query within a React component, call `useQueryPayrollCostsMemberMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPayrollCostsMemberMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPayrollCostsMemberMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryPayrollCostsMemberMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryPayrollCostsMemberMetricsQuery,
    QueryPayrollCostsMemberMetricsQueryVariables
  > &
    ({ variables: QueryPayrollCostsMemberMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryPayrollCostsMemberMetricsQuery, QueryPayrollCostsMemberMetricsQueryVariables>(
    QueryPayrollCostsMemberMetricsDocument,
    options
  );
}
export function useQueryPayrollCostsMemberMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryPayrollCostsMemberMetricsQuery,
    QueryPayrollCostsMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryPayrollCostsMemberMetricsQuery, QueryPayrollCostsMemberMetricsQueryVariables>(
    QueryPayrollCostsMemberMetricsDocument,
    options
  );
}
export function useQueryPayrollCostsMemberMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryPayrollCostsMemberMetricsQuery,
    QueryPayrollCostsMemberMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryPayrollCostsMemberMetricsQuery, QueryPayrollCostsMemberMetricsQueryVariables>(
    QueryPayrollCostsMemberMetricsDocument,
    options
  );
}
export type QueryPayrollCostsMemberMetricsQueryHookResult = ReturnType<typeof useQueryPayrollCostsMemberMetricsQuery>;
export type QueryPayrollCostsMemberMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryPayrollCostsMemberMetricsLazyQuery
>;
export type QueryPayrollCostsMemberMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryPayrollCostsMemberMetricsSuspenseQuery
>;
export type QueryPayrollCostsMemberMetricsQueryResult = Apollo.QueryResult<
  QueryPayrollCostsMemberMetricsQuery,
  QueryPayrollCostsMemberMetricsQueryVariables
>;
export const QueryMembersPayrollDailyExportByCostCodeLaborMetricsDocument = gql`
  query QueryMembersPayrollDailyExportByCostCodeLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      firstName
      lastName
      memberNumber
      cursor
      ...PayrollMemberPredicateMember
      memberCostCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        costCodeId
        ...BasicLaborMetrics
      }
      archivedOn
    }
  }
  ${PayrollMemberPredicateMemberFragmentDoc}
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
  > &
    (
      | { variables: QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByCostCodeLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByCostCodeLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByCostCodeLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByCostCodeLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByCostCodeLaborMetricsDocument, options);
}
export type QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery
>;
export type QueryMembersPayrollDailyExportByCostCodeLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByCostCodeLaborMetricsLazyQuery
>;
export type QueryMembersPayrollDailyExportByCostCodeLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByCostCodeLaborMetricsSuspenseQuery
>;
export type QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryResult = Apollo.QueryResult<
  QueryMembersPayrollDailyExportByCostCodeLaborMetricsQuery,
  QueryMembersPayrollDailyExportByCostCodeLaborMetricsQueryVariables
>;
export const QueryMembersPayrollDailyExportByEquipmentLaborMetricsDocument = gql`
  query QueryMembersPayrollDailyExportByEquipmentLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      firstName
      lastName
      memberNumber
      cursor
      ...PayrollMemberPredicateMember
      memberEquipmentLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        equipmentId
        dt
        dtCents
        end
        ot
        otCents
        overburdenRtCents
        overburdenOtCents
        overburdenDtCents
        rt
        rtCents
        start
      }
      archivedOn
    }
  }
  ${PayrollMemberPredicateMemberFragmentDoc}
`;

/**
 * __useQueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
  > &
    (
      | { variables: QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByEquipmentLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByEquipmentLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByEquipmentLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByEquipmentLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
    QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByEquipmentLaborMetricsDocument, options);
}
export type QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery
>;
export type QueryMembersPayrollDailyExportByEquipmentLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByEquipmentLaborMetricsLazyQuery
>;
export type QueryMembersPayrollDailyExportByEquipmentLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByEquipmentLaborMetricsSuspenseQuery
>;
export type QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryResult = Apollo.QueryResult<
  QueryMembersPayrollDailyExportByEquipmentLaborMetricsQuery,
  QueryMembersPayrollDailyExportByEquipmentLaborMetricsQueryVariables
>;
export const QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsDocument = gql`
  query QueryMembersPayrollDailyExportByProjectCostCodeLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      firstName
      lastName
      memberNumber
      cursor
      ...PayrollMemberPredicateMember
      memberProjectCostCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        projectId
        costCodeId
        ...BasicProjectLaborMetrics
      }
      archivedOn
    }
  }
  ${PayrollMemberPredicateMemberFragmentDoc}
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
  > &
    (
      | { variables: QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsDocument, options);
}
export type QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery
>;
export type QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsLazyQuery
>;
export type QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsSuspenseQuery
>;
export type QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryResult = Apollo.QueryResult<
  QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQuery,
  QueryMembersPayrollDailyExportByProjectCostCodeLaborMetricsQueryVariables
>;
export const QueryMembersPayrollDailyExportByProjectLaborMetricsDocument = gql`
  query QueryMembersPayrollDailyExportByProjectLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      firstName
      lastName
      memberNumber
      cursor
      ...PayrollMemberPredicateMember
      memberProjectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        projectId
        ...BasicProjectLaborMetrics
      }
      archivedOn
    }
  }
  ${PayrollMemberPredicateMemberFragmentDoc}
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersPayrollDailyExportByProjectLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersPayrollDailyExportByProjectLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersPayrollDailyExportByProjectLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersPayrollDailyExportByProjectLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersPayrollDailyExportByProjectLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
  > &
    (
      | { variables: QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByProjectLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByProjectLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByProjectLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportByProjectLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
    QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportByProjectLaborMetricsDocument, options);
}
export type QueryMembersPayrollDailyExportByProjectLaborMetricsQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByProjectLaborMetricsQuery
>;
export type QueryMembersPayrollDailyExportByProjectLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByProjectLaborMetricsLazyQuery
>;
export type QueryMembersPayrollDailyExportByProjectLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportByProjectLaborMetricsSuspenseQuery
>;
export type QueryMembersPayrollDailyExportByProjectLaborMetricsQueryResult = Apollo.QueryResult<
  QueryMembersPayrollDailyExportByProjectLaborMetricsQuery,
  QueryMembersPayrollDailyExportByProjectLaborMetricsQueryVariables
>;
export const ProjectAncestorsWithNumbersDocument = gql`
  query ProjectAncestorsWithNumbers($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      depth
      rootProjectId
      projectInfo {
        id
        number
      }
      ancestors {
        id
        title
        depth
        archivedOn
        projectInfo {
          id
          number
        }
      }
      cursor
    }
  }
`;

/**
 * __useProjectAncestorsWithNumbersQuery__
 *
 * To run a query within a React component, call `useProjectAncestorsWithNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAncestorsWithNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAncestorsWithNumbersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectAncestorsWithNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectAncestorsWithNumbersQuery, ProjectAncestorsWithNumbersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectAncestorsWithNumbersQuery, ProjectAncestorsWithNumbersQueryVariables>(
    ProjectAncestorsWithNumbersDocument,
    options
  );
}
export function useProjectAncestorsWithNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectAncestorsWithNumbersQuery, ProjectAncestorsWithNumbersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectAncestorsWithNumbersQuery, ProjectAncestorsWithNumbersQueryVariables>(
    ProjectAncestorsWithNumbersDocument,
    options
  );
}
export function useProjectAncestorsWithNumbersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProjectAncestorsWithNumbersQuery,
    ProjectAncestorsWithNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectAncestorsWithNumbersQuery, ProjectAncestorsWithNumbersQueryVariables>(
    ProjectAncestorsWithNumbersDocument,
    options
  );
}
export type ProjectAncestorsWithNumbersQueryHookResult = ReturnType<typeof useProjectAncestorsWithNumbersQuery>;
export type ProjectAncestorsWithNumbersLazyQueryHookResult = ReturnType<typeof useProjectAncestorsWithNumbersLazyQuery>;
export type ProjectAncestorsWithNumbersSuspenseQueryHookResult = ReturnType<
  typeof useProjectAncestorsWithNumbersSuspenseQuery
>;
export type ProjectAncestorsWithNumbersQueryResult = Apollo.QueryResult<
  ProjectAncestorsWithNumbersQuery,
  ProjectAncestorsWithNumbersQueryVariables
>;
export const QueryMembersPayrollDailyExportLaborMetricsDocument = gql`
  query QueryMembersPayrollDailyExportLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      firstName
      lastName
      isSubContractor
      memberNumber
      cursor
      ...PayrollMemberPredicateMember
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        ...MemberLaborMetrics
      }
      archivedOn
    }
  }
  ${PayrollMemberPredicateMemberFragmentDoc}
  ${MemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersPayrollDailyExportLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersPayrollDailyExportLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersPayrollDailyExportLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersPayrollDailyExportLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useQueryMembersPayrollDailyExportLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersPayrollDailyExportLaborMetricsQuery,
    QueryMembersPayrollDailyExportLaborMetricsQueryVariables
  > &
    ({ variables: QueryMembersPayrollDailyExportLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMembersPayrollDailyExportLaborMetricsQuery,
    QueryMembersPayrollDailyExportLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersPayrollDailyExportLaborMetricsQuery,
    QueryMembersPayrollDailyExportLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMembersPayrollDailyExportLaborMetricsQuery,
    QueryMembersPayrollDailyExportLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportLaborMetricsDocument, options);
}
export function useQueryMembersPayrollDailyExportLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersPayrollDailyExportLaborMetricsQuery,
    QueryMembersPayrollDailyExportLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersPayrollDailyExportLaborMetricsQuery,
    QueryMembersPayrollDailyExportLaborMetricsQueryVariables
  >(QueryMembersPayrollDailyExportLaborMetricsDocument, options);
}
export type QueryMembersPayrollDailyExportLaborMetricsQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportLaborMetricsQuery
>;
export type QueryMembersPayrollDailyExportLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportLaborMetricsLazyQuery
>;
export type QueryMembersPayrollDailyExportLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersPayrollDailyExportLaborMetricsSuspenseQuery
>;
export type QueryMembersPayrollDailyExportLaborMetricsQueryResult = Apollo.QueryResult<
  QueryMembersPayrollDailyExportLaborMetricsQuery,
  QueryMembersPayrollDailyExportLaborMetricsQueryVariables
>;
export const MemberPayrollExportDataDocument = gql`
  query MemberPayrollExportData(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      ...PayrollMemberPredicateMember
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        ...AllMemberLaborMetrics
      }
    }
  }
  ${PayrollMemberPredicateMemberFragmentDoc}
  ${AllMemberLaborMetricsFragmentDoc}
`;

/**
 * __useMemberPayrollExportDataQuery__
 *
 * To run a query within a React component, call `useMemberPayrollExportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPayrollExportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPayrollExportDataQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      memberTimeDocumentFilter: // value for 'memberTimeDocumentFilter'
 *      memberTimeDocumentSort: // value for 'memberTimeDocumentSort'
 *   },
 * });
 */
export function useMemberPayrollExportDataQuery(
  baseOptions: Apollo.QueryHookOptions<MemberPayrollExportDataQuery, MemberPayrollExportDataQueryVariables> &
    ({ variables: MemberPayrollExportDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberPayrollExportDataQuery, MemberPayrollExportDataQueryVariables>(
    MemberPayrollExportDataDocument,
    options
  );
}
export function useMemberPayrollExportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberPayrollExportDataQuery, MemberPayrollExportDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberPayrollExportDataQuery, MemberPayrollExportDataQueryVariables>(
    MemberPayrollExportDataDocument,
    options
  );
}
export function useMemberPayrollExportDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberPayrollExportDataQuery, MemberPayrollExportDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberPayrollExportDataQuery, MemberPayrollExportDataQueryVariables>(
    MemberPayrollExportDataDocument,
    options
  );
}
export type MemberPayrollExportDataQueryHookResult = ReturnType<typeof useMemberPayrollExportDataQuery>;
export type MemberPayrollExportDataLazyQueryHookResult = ReturnType<typeof useMemberPayrollExportDataLazyQuery>;
export type MemberPayrollExportDataSuspenseQueryHookResult = ReturnType<typeof useMemberPayrollExportDataSuspenseQuery>;
export type MemberPayrollExportDataQueryResult = Apollo.QueryResult<
  MemberPayrollExportDataQuery,
  MemberPayrollExportDataQueryVariables
>;
export const GustoMatchBusyMembersDocument = gql`
  query gustoMatchBusyMembers($first: Int, $after: String) {
    members(first: $first, after: $after) {
      __typename
      id
      firstName
      lastName
      email
      gustoEmployeeId
      cursor
    }
  }
`;

/**
 * __useGustoMatchBusyMembersQuery__
 *
 * To run a query within a React component, call `useGustoMatchBusyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGustoMatchBusyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGustoMatchBusyMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGustoMatchBusyMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<GustoMatchBusyMembersQuery, GustoMatchBusyMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GustoMatchBusyMembersQuery, GustoMatchBusyMembersQueryVariables>(
    GustoMatchBusyMembersDocument,
    options
  );
}
export function useGustoMatchBusyMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GustoMatchBusyMembersQuery, GustoMatchBusyMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GustoMatchBusyMembersQuery, GustoMatchBusyMembersQueryVariables>(
    GustoMatchBusyMembersDocument,
    options
  );
}
export function useGustoMatchBusyMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GustoMatchBusyMembersQuery, GustoMatchBusyMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GustoMatchBusyMembersQuery, GustoMatchBusyMembersQueryVariables>(
    GustoMatchBusyMembersDocument,
    options
  );
}
export type GustoMatchBusyMembersQueryHookResult = ReturnType<typeof useGustoMatchBusyMembersQuery>;
export type GustoMatchBusyMembersLazyQueryHookResult = ReturnType<typeof useGustoMatchBusyMembersLazyQuery>;
export type GustoMatchBusyMembersSuspenseQueryHookResult = ReturnType<typeof useGustoMatchBusyMembersSuspenseQuery>;
export type GustoMatchBusyMembersQueryResult = Apollo.QueryResult<
  GustoMatchBusyMembersQuery,
  GustoMatchBusyMembersQueryVariables
>;
export const BasicGustoContractorIdDocument = gql`
  query BasicGustoContractorId($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      gustoContractorId
    }
  }
`;

/**
 * __useBasicGustoContractorIdQuery__
 *
 * To run a query within a React component, call `useBasicGustoContractorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicGustoContractorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicGustoContractorIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBasicGustoContractorIdQuery(
  baseOptions?: Apollo.QueryHookOptions<BasicGustoContractorIdQuery, BasicGustoContractorIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BasicGustoContractorIdQuery, BasicGustoContractorIdQueryVariables>(
    BasicGustoContractorIdDocument,
    options
  );
}
export function useBasicGustoContractorIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BasicGustoContractorIdQuery, BasicGustoContractorIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BasicGustoContractorIdQuery, BasicGustoContractorIdQueryVariables>(
    BasicGustoContractorIdDocument,
    options
  );
}
export function useBasicGustoContractorIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BasicGustoContractorIdQuery, BasicGustoContractorIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BasicGustoContractorIdQuery, BasicGustoContractorIdQueryVariables>(
    BasicGustoContractorIdDocument,
    options
  );
}
export type BasicGustoContractorIdQueryHookResult = ReturnType<typeof useBasicGustoContractorIdQuery>;
export type BasicGustoContractorIdLazyQueryHookResult = ReturnType<typeof useBasicGustoContractorIdLazyQuery>;
export type BasicGustoContractorIdSuspenseQueryHookResult = ReturnType<typeof useBasicGustoContractorIdSuspenseQuery>;
export type BasicGustoContractorIdQueryResult = Apollo.QueryResult<
  BasicGustoContractorIdQuery,
  BasicGustoContractorIdQueryVariables
>;
export const GustoMatchBusyContractorsDocument = gql`
  query gustoMatchBusyContractors($first: Int, $after: String) {
    members(first: $first, after: $after) {
      __typename
      id
      firstName
      lastName
      email
      gustoContractorId
      cursor
    }
  }
`;

/**
 * __useGustoMatchBusyContractorsQuery__
 *
 * To run a query within a React component, call `useGustoMatchBusyContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGustoMatchBusyContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGustoMatchBusyContractorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGustoMatchBusyContractorsQuery(
  baseOptions?: Apollo.QueryHookOptions<GustoMatchBusyContractorsQuery, GustoMatchBusyContractorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GustoMatchBusyContractorsQuery, GustoMatchBusyContractorsQueryVariables>(
    GustoMatchBusyContractorsDocument,
    options
  );
}
export function useGustoMatchBusyContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GustoMatchBusyContractorsQuery, GustoMatchBusyContractorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GustoMatchBusyContractorsQuery, GustoMatchBusyContractorsQueryVariables>(
    GustoMatchBusyContractorsDocument,
    options
  );
}
export function useGustoMatchBusyContractorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GustoMatchBusyContractorsQuery, GustoMatchBusyContractorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GustoMatchBusyContractorsQuery, GustoMatchBusyContractorsQueryVariables>(
    GustoMatchBusyContractorsDocument,
    options
  );
}
export type GustoMatchBusyContractorsQueryHookResult = ReturnType<typeof useGustoMatchBusyContractorsQuery>;
export type GustoMatchBusyContractorsLazyQueryHookResult = ReturnType<typeof useGustoMatchBusyContractorsLazyQuery>;
export type GustoMatchBusyContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGustoMatchBusyContractorsSuspenseQuery
>;
export type GustoMatchBusyContractorsQueryResult = Apollo.QueryResult<
  GustoMatchBusyContractorsQuery,
  GustoMatchBusyContractorsQueryVariables
>;
export const MemberPayrollContractorDocument = gql`
  query MemberPayrollContractor(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      archivedOn
      gustoContractorId
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        ...BasicMemberLaborMetrics
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
`;

/**
 * __useMemberPayrollContractorQuery__
 *
 * To run a query within a React component, call `useMemberPayrollContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberPayrollContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberPayrollContractorQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useMemberPayrollContractorQuery(
  baseOptions: Apollo.QueryHookOptions<MemberPayrollContractorQuery, MemberPayrollContractorQueryVariables> &
    ({ variables: MemberPayrollContractorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberPayrollContractorQuery, MemberPayrollContractorQueryVariables>(
    MemberPayrollContractorDocument,
    options
  );
}
export function useMemberPayrollContractorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberPayrollContractorQuery, MemberPayrollContractorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberPayrollContractorQuery, MemberPayrollContractorQueryVariables>(
    MemberPayrollContractorDocument,
    options
  );
}
export function useMemberPayrollContractorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberPayrollContractorQuery, MemberPayrollContractorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberPayrollContractorQuery, MemberPayrollContractorQueryVariables>(
    MemberPayrollContractorDocument,
    options
  );
}
export type MemberPayrollContractorQueryHookResult = ReturnType<typeof useMemberPayrollContractorQuery>;
export type MemberPayrollContractorLazyQueryHookResult = ReturnType<typeof useMemberPayrollContractorLazyQuery>;
export type MemberPayrollContractorSuspenseQueryHookResult = ReturnType<typeof useMemberPayrollContractorSuspenseQuery>;
export type MemberPayrollContractorQueryResult = Apollo.QueryResult<
  MemberPayrollContractorQuery,
  MemberPayrollContractorQueryVariables
>;
export const BasicGustoEmployeeIdDocument = gql`
  query BasicGustoEmployeeId($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      gustoEmployeeId
    }
  }
`;

/**
 * __useBasicGustoEmployeeIdQuery__
 *
 * To run a query within a React component, call `useBasicGustoEmployeeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicGustoEmployeeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicGustoEmployeeIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBasicGustoEmployeeIdQuery(
  baseOptions?: Apollo.QueryHookOptions<BasicGustoEmployeeIdQuery, BasicGustoEmployeeIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BasicGustoEmployeeIdQuery, BasicGustoEmployeeIdQueryVariables>(
    BasicGustoEmployeeIdDocument,
    options
  );
}
export function useBasicGustoEmployeeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BasicGustoEmployeeIdQuery, BasicGustoEmployeeIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BasicGustoEmployeeIdQuery, BasicGustoEmployeeIdQueryVariables>(
    BasicGustoEmployeeIdDocument,
    options
  );
}
export function useBasicGustoEmployeeIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<BasicGustoEmployeeIdQuery, BasicGustoEmployeeIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BasicGustoEmployeeIdQuery, BasicGustoEmployeeIdQueryVariables>(
    BasicGustoEmployeeIdDocument,
    options
  );
}
export type BasicGustoEmployeeIdQueryHookResult = ReturnType<typeof useBasicGustoEmployeeIdQuery>;
export type BasicGustoEmployeeIdLazyQueryHookResult = ReturnType<typeof useBasicGustoEmployeeIdLazyQuery>;
export type BasicGustoEmployeeIdSuspenseQueryHookResult = ReturnType<typeof useBasicGustoEmployeeIdSuspenseQuery>;
export type BasicGustoEmployeeIdQueryResult = Apollo.QueryResult<
  BasicGustoEmployeeIdQuery,
  BasicGustoEmployeeIdQueryVariables
>;
export const QueryMembersLaborMetricsGustoSyncDocument = gql`
  query QueryMembersLaborMetricsGustoSync(
    $first: Int
    $after: String
    $filter: MemberFilter
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      firstName
      lastName
      memberNumber
      gustoEmployeeId
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        __typename
        ...MemberLaborMetrics
      }
    }
  }
  ${MemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersLaborMetricsGustoSyncQuery__
 *
 * To run a query within a React component, call `useQueryMembersLaborMetricsGustoSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersLaborMetricsGustoSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersLaborMetricsGustoSyncQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryMembersLaborMetricsGustoSyncQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersLaborMetricsGustoSyncQuery,
    QueryMembersLaborMetricsGustoSyncQueryVariables
  > &
    ({ variables: QueryMembersLaborMetricsGustoSyncQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersLaborMetricsGustoSyncQuery, QueryMembersLaborMetricsGustoSyncQueryVariables>(
    QueryMembersLaborMetricsGustoSyncDocument,
    options
  );
}
export function useQueryMembersLaborMetricsGustoSyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersLaborMetricsGustoSyncQuery,
    QueryMembersLaborMetricsGustoSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersLaborMetricsGustoSyncQuery, QueryMembersLaborMetricsGustoSyncQueryVariables>(
    QueryMembersLaborMetricsGustoSyncDocument,
    options
  );
}
export function useQueryMembersLaborMetricsGustoSyncSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersLaborMetricsGustoSyncQuery,
    QueryMembersLaborMetricsGustoSyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMembersLaborMetricsGustoSyncQuery,
    QueryMembersLaborMetricsGustoSyncQueryVariables
  >(QueryMembersLaborMetricsGustoSyncDocument, options);
}
export type QueryMembersLaborMetricsGustoSyncQueryHookResult = ReturnType<
  typeof useQueryMembersLaborMetricsGustoSyncQuery
>;
export type QueryMembersLaborMetricsGustoSyncLazyQueryHookResult = ReturnType<
  typeof useQueryMembersLaborMetricsGustoSyncLazyQuery
>;
export type QueryMembersLaborMetricsGustoSyncSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersLaborMetricsGustoSyncSuspenseQuery
>;
export type QueryMembersLaborMetricsGustoSyncQueryResult = Apollo.QueryResult<
  QueryMembersLaborMetricsGustoSyncQuery,
  QueryMembersLaborMetricsGustoSyncQueryVariables
>;
export const MemberLaborMetricsGustoPtoEligibilityDocument = gql`
  query MemberLaborMetricsGustoPtoEligibility(
    $first: Int
    $after: String
    $filter: MemberFilter
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      gustoEmployeeId
      memberLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        pto
        __typename
      }
    }
  }
`;

/**
 * __useMemberLaborMetricsGustoPtoEligibilityQuery__
 *
 * To run a query within a React component, call `useMemberLaborMetricsGustoPtoEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberLaborMetricsGustoPtoEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberLaborMetricsGustoPtoEligibilityQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useMemberLaborMetricsGustoPtoEligibilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    MemberLaborMetricsGustoPtoEligibilityQuery,
    MemberLaborMetricsGustoPtoEligibilityQueryVariables
  > &
    ({ variables: MemberLaborMetricsGustoPtoEligibilityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MemberLaborMetricsGustoPtoEligibilityQuery,
    MemberLaborMetricsGustoPtoEligibilityQueryVariables
  >(MemberLaborMetricsGustoPtoEligibilityDocument, options);
}
export function useMemberLaborMetricsGustoPtoEligibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberLaborMetricsGustoPtoEligibilityQuery,
    MemberLaborMetricsGustoPtoEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberLaborMetricsGustoPtoEligibilityQuery,
    MemberLaborMetricsGustoPtoEligibilityQueryVariables
  >(MemberLaborMetricsGustoPtoEligibilityDocument, options);
}
export function useMemberLaborMetricsGustoPtoEligibilitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MemberLaborMetricsGustoPtoEligibilityQuery,
    MemberLaborMetricsGustoPtoEligibilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MemberLaborMetricsGustoPtoEligibilityQuery,
    MemberLaborMetricsGustoPtoEligibilityQueryVariables
  >(MemberLaborMetricsGustoPtoEligibilityDocument, options);
}
export type MemberLaborMetricsGustoPtoEligibilityQueryHookResult = ReturnType<
  typeof useMemberLaborMetricsGustoPtoEligibilityQuery
>;
export type MemberLaborMetricsGustoPtoEligibilityLazyQueryHookResult = ReturnType<
  typeof useMemberLaborMetricsGustoPtoEligibilityLazyQuery
>;
export type MemberLaborMetricsGustoPtoEligibilitySuspenseQueryHookResult = ReturnType<
  typeof useMemberLaborMetricsGustoPtoEligibilitySuspenseQuery
>;
export type MemberLaborMetricsGustoPtoEligibilityQueryResult = Apollo.QueryResult<
  MemberLaborMetricsGustoPtoEligibilityQuery,
  MemberLaborMetricsGustoPtoEligibilityQueryVariables
>;
export const EmployeesWithTimeJoinedWagesDocument = gql`
  query EmployeesWithTimeJoinedWages($first: Int, $after: String, $startTime: Date!, $endTime: Date!) {
    members(first: $first, after: $after) {
      id
      cursor
      memberProjectLaborMetrics(interval: custom, start: $startTime, end: $endTime) {
        id
        projectId
        rt
        ot
        dt
        rtCents
        otCents
        dtCents
        descRtCents
        descDtCents
        descOtCents
      }
    }
  }
`;

/**
 * __useEmployeesWithTimeJoinedWagesQuery__
 *
 * To run a query within a React component, call `useEmployeesWithTimeJoinedWagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesWithTimeJoinedWagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesWithTimeJoinedWagesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useEmployeesWithTimeJoinedWagesQuery(
  baseOptions: Apollo.QueryHookOptions<EmployeesWithTimeJoinedWagesQuery, EmployeesWithTimeJoinedWagesQueryVariables> &
    ({ variables: EmployeesWithTimeJoinedWagesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmployeesWithTimeJoinedWagesQuery, EmployeesWithTimeJoinedWagesQueryVariables>(
    EmployeesWithTimeJoinedWagesDocument,
    options
  );
}
export function useEmployeesWithTimeJoinedWagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeesWithTimeJoinedWagesQuery,
    EmployeesWithTimeJoinedWagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmployeesWithTimeJoinedWagesQuery, EmployeesWithTimeJoinedWagesQueryVariables>(
    EmployeesWithTimeJoinedWagesDocument,
    options
  );
}
export function useEmployeesWithTimeJoinedWagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EmployeesWithTimeJoinedWagesQuery,
    EmployeesWithTimeJoinedWagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<EmployeesWithTimeJoinedWagesQuery, EmployeesWithTimeJoinedWagesQueryVariables>(
    EmployeesWithTimeJoinedWagesDocument,
    options
  );
}
export type EmployeesWithTimeJoinedWagesQueryHookResult = ReturnType<typeof useEmployeesWithTimeJoinedWagesQuery>;
export type EmployeesWithTimeJoinedWagesLazyQueryHookResult = ReturnType<
  typeof useEmployeesWithTimeJoinedWagesLazyQuery
>;
export type EmployeesWithTimeJoinedWagesSuspenseQueryHookResult = ReturnType<
  typeof useEmployeesWithTimeJoinedWagesSuspenseQuery
>;
export type EmployeesWithTimeJoinedWagesQueryResult = Apollo.QueryResult<
  EmployeesWithTimeJoinedWagesQuery,
  EmployeesWithTimeJoinedWagesQueryVariables
>;
export const ProjectJournalEntryDocument = gql`
  query ProjectJournalEntry(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $metricFilter: LaborMetricFilter
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      id
      title
      depth
      parentProjectId
      rootProjectId
      cursor
      ancestors {
        id
        title
        depth
      }
      projectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useProjectJournalEntryQuery__
 *
 * To run a query within a React component, call `useProjectJournalEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectJournalEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectJournalEntryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useProjectJournalEntryQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectJournalEntryQuery, ProjectJournalEntryQueryVariables> &
    ({ variables: ProjectJournalEntryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectJournalEntryQuery, ProjectJournalEntryQueryVariables>(
    ProjectJournalEntryDocument,
    options
  );
}
export function useProjectJournalEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectJournalEntryQuery, ProjectJournalEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectJournalEntryQuery, ProjectJournalEntryQueryVariables>(
    ProjectJournalEntryDocument,
    options
  );
}
export function useProjectJournalEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectJournalEntryQuery, ProjectJournalEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectJournalEntryQuery, ProjectJournalEntryQueryVariables>(
    ProjectJournalEntryDocument,
    options
  );
}
export type ProjectJournalEntryQueryHookResult = ReturnType<typeof useProjectJournalEntryQuery>;
export type ProjectJournalEntryLazyQueryHookResult = ReturnType<typeof useProjectJournalEntryLazyQuery>;
export type ProjectJournalEntrySuspenseQueryHookResult = ReturnType<typeof useProjectJournalEntrySuspenseQuery>;
export type ProjectJournalEntryQueryResult = Apollo.QueryResult<
  ProjectJournalEntryQuery,
  ProjectJournalEntryQueryVariables
>;
export const ProjectBasicDepthDocument = gql`
  query ProjectBasicDepth($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      depth
      cursor
      title
      ancestors {
        id
        depth
        title
      }
    }
  }
`;

/**
 * __useProjectBasicDepthQuery__
 *
 * To run a query within a React component, call `useProjectBasicDepthQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBasicDepthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBasicDepthQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProjectBasicDepthQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>(ProjectBasicDepthDocument, options);
}
export function useProjectBasicDepthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>(
    ProjectBasicDepthDocument,
    options
  );
}
export function useProjectBasicDepthSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>(
    ProjectBasicDepthDocument,
    options
  );
}
export type ProjectBasicDepthQueryHookResult = ReturnType<typeof useProjectBasicDepthQuery>;
export type ProjectBasicDepthLazyQueryHookResult = ReturnType<typeof useProjectBasicDepthLazyQuery>;
export type ProjectBasicDepthSuspenseQueryHookResult = ReturnType<typeof useProjectBasicDepthSuspenseQuery>;
export type ProjectBasicDepthQueryResult = Apollo.QueryResult<ProjectBasicDepthQuery, ProjectBasicDepthQueryVariables>;
export const MemberProjectJournalEntryDocument = gql`
  query MemberProjectJournalEntry(
    $after: String
    $filter: MemberFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(after: $after, first: $first, filter: $filter) {
      id
      cursor
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: { operationType: and, nullConditions: [{ operator: isNotNull, field: projectId }] }
      ) {
        id
        memberId
        projectId
        rt
        dt
        ot
        descRt
        descDt
        descOt
      }
      memberNoProjectLaborMetrics: memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: { operationType: and, nullConditions: [{ operator: isNull, field: projectId }] }
      ) {
        id
        memberId
        projectId
        rt
        dt
        ot
      }
      memberPtoLaborMetrics: memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
      ) {
        id
        pto
      }
    }
  }
`;

/**
 * __useMemberProjectJournalEntryQuery__
 *
 * To run a query within a React component, call `useMemberProjectJournalEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberProjectJournalEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberProjectJournalEntryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useMemberProjectJournalEntryQuery(
  baseOptions: Apollo.QueryHookOptions<MemberProjectJournalEntryQuery, MemberProjectJournalEntryQueryVariables> &
    ({ variables: MemberProjectJournalEntryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberProjectJournalEntryQuery, MemberProjectJournalEntryQueryVariables>(
    MemberProjectJournalEntryDocument,
    options
  );
}
export function useMemberProjectJournalEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberProjectJournalEntryQuery, MemberProjectJournalEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberProjectJournalEntryQuery, MemberProjectJournalEntryQueryVariables>(
    MemberProjectJournalEntryDocument,
    options
  );
}
export function useMemberProjectJournalEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberProjectJournalEntryQuery, MemberProjectJournalEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberProjectJournalEntryQuery, MemberProjectJournalEntryQueryVariables>(
    MemberProjectJournalEntryDocument,
    options
  );
}
export type MemberProjectJournalEntryQueryHookResult = ReturnType<typeof useMemberProjectJournalEntryQuery>;
export type MemberProjectJournalEntryLazyQueryHookResult = ReturnType<typeof useMemberProjectJournalEntryLazyQuery>;
export type MemberProjectJournalEntrySuspenseQueryHookResult = ReturnType<
  typeof useMemberProjectJournalEntrySuspenseQuery
>;
export type MemberProjectJournalEntryQueryResult = Apollo.QueryResult<
  MemberProjectJournalEntryQuery,
  MemberProjectJournalEntryQueryVariables
>;
export const PayrollScanBreakComplianceTimeEntryDocument = gql`
  query PayrollScanBreakComplianceTimeEntry($after: String, $filter: TimeEntryFilter, $first: Int) {
    timeEntries(after: $after, filter: $filter, first: $first) {
      ...PayrollScanBreakComplianceTimeEntry
      cursor
    }
  }
  ${PayrollScanBreakComplianceTimeEntryFragmentDoc}
`;

/**
 * __usePayrollScanBreakComplianceTimeEntryQuery__
 *
 * To run a query within a React component, call `usePayrollScanBreakComplianceTimeEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollScanBreakComplianceTimeEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollScanBreakComplianceTimeEntryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePayrollScanBreakComplianceTimeEntryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PayrollScanBreakComplianceTimeEntryQuery,
    PayrollScanBreakComplianceTimeEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PayrollScanBreakComplianceTimeEntryQuery, PayrollScanBreakComplianceTimeEntryQueryVariables>(
    PayrollScanBreakComplianceTimeEntryDocument,
    options
  );
}
export function usePayrollScanBreakComplianceTimeEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollScanBreakComplianceTimeEntryQuery,
    PayrollScanBreakComplianceTimeEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollScanBreakComplianceTimeEntryQuery,
    PayrollScanBreakComplianceTimeEntryQueryVariables
  >(PayrollScanBreakComplianceTimeEntryDocument, options);
}
export function usePayrollScanBreakComplianceTimeEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PayrollScanBreakComplianceTimeEntryQuery,
    PayrollScanBreakComplianceTimeEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PayrollScanBreakComplianceTimeEntryQuery,
    PayrollScanBreakComplianceTimeEntryQueryVariables
  >(PayrollScanBreakComplianceTimeEntryDocument, options);
}
export type PayrollScanBreakComplianceTimeEntryQueryHookResult = ReturnType<
  typeof usePayrollScanBreakComplianceTimeEntryQuery
>;
export type PayrollScanBreakComplianceTimeEntryLazyQueryHookResult = ReturnType<
  typeof usePayrollScanBreakComplianceTimeEntryLazyQuery
>;
export type PayrollScanBreakComplianceTimeEntrySuspenseQueryHookResult = ReturnType<
  typeof usePayrollScanBreakComplianceTimeEntrySuspenseQuery
>;
export type PayrollScanBreakComplianceTimeEntryQueryResult = Apollo.QueryResult<
  PayrollScanBreakComplianceTimeEntryQuery,
  PayrollScanBreakComplianceTimeEntryQueryVariables
>;
export const PayrollScanBreakComplianceSafetySignatureDocument = gql`
  query PayrollScanBreakComplianceSafetySignature($first: Int, $after: String, $filter: SafetySignatureFilter) {
    safetySignatures(after: $after, first: $first, filter: $filter) {
      id
      startTime
      endTime
      memberId
      breakPolicyFollowed
      breakPolicyFollowedResolvedOn
      createdOnLocal
      deletedOn
      cursor
    }
  }
`;

/**
 * __usePayrollScanBreakComplianceSafetySignatureQuery__
 *
 * To run a query within a React component, call `usePayrollScanBreakComplianceSafetySignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollScanBreakComplianceSafetySignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollScanBreakComplianceSafetySignatureQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePayrollScanBreakComplianceSafetySignatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PayrollScanBreakComplianceSafetySignatureQuery,
    PayrollScanBreakComplianceSafetySignatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PayrollScanBreakComplianceSafetySignatureQuery,
    PayrollScanBreakComplianceSafetySignatureQueryVariables
  >(PayrollScanBreakComplianceSafetySignatureDocument, options);
}
export function usePayrollScanBreakComplianceSafetySignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayrollScanBreakComplianceSafetySignatureQuery,
    PayrollScanBreakComplianceSafetySignatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PayrollScanBreakComplianceSafetySignatureQuery,
    PayrollScanBreakComplianceSafetySignatureQueryVariables
  >(PayrollScanBreakComplianceSafetySignatureDocument, options);
}
export function usePayrollScanBreakComplianceSafetySignatureSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PayrollScanBreakComplianceSafetySignatureQuery,
    PayrollScanBreakComplianceSafetySignatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PayrollScanBreakComplianceSafetySignatureQuery,
    PayrollScanBreakComplianceSafetySignatureQueryVariables
  >(PayrollScanBreakComplianceSafetySignatureDocument, options);
}
export type PayrollScanBreakComplianceSafetySignatureQueryHookResult = ReturnType<
  typeof usePayrollScanBreakComplianceSafetySignatureQuery
>;
export type PayrollScanBreakComplianceSafetySignatureLazyQueryHookResult = ReturnType<
  typeof usePayrollScanBreakComplianceSafetySignatureLazyQuery
>;
export type PayrollScanBreakComplianceSafetySignatureSuspenseQueryHookResult = ReturnType<
  typeof usePayrollScanBreakComplianceSafetySignatureSuspenseQuery
>;
export type PayrollScanBreakComplianceSafetySignatureQueryResult = Apollo.QueryResult<
  PayrollScanBreakComplianceSafetySignatureQuery,
  PayrollScanBreakComplianceSafetySignatureQueryVariables
>;
export const QueryPayrollExportClosedTimeEntriesDocument = gql`
  query queryPayrollExportClosedTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int) {
    timeEntries(after: $after, filter: $filter, first: $first) {
      id
      cursor
    }
  }
`;

/**
 * __useQueryPayrollExportClosedTimeEntriesQuery__
 *
 * To run a query within a React component, call `useQueryPayrollExportClosedTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryPayrollExportClosedTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryPayrollExportClosedTimeEntriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryPayrollExportClosedTimeEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryPayrollExportClosedTimeEntriesQuery,
    QueryPayrollExportClosedTimeEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryPayrollExportClosedTimeEntriesQuery, QueryPayrollExportClosedTimeEntriesQueryVariables>(
    QueryPayrollExportClosedTimeEntriesDocument,
    options
  );
}
export function useQueryPayrollExportClosedTimeEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryPayrollExportClosedTimeEntriesQuery,
    QueryPayrollExportClosedTimeEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryPayrollExportClosedTimeEntriesQuery,
    QueryPayrollExportClosedTimeEntriesQueryVariables
  >(QueryPayrollExportClosedTimeEntriesDocument, options);
}
export function useQueryPayrollExportClosedTimeEntriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryPayrollExportClosedTimeEntriesQuery,
    QueryPayrollExportClosedTimeEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryPayrollExportClosedTimeEntriesQuery,
    QueryPayrollExportClosedTimeEntriesQueryVariables
  >(QueryPayrollExportClosedTimeEntriesDocument, options);
}
export type QueryPayrollExportClosedTimeEntriesQueryHookResult = ReturnType<
  typeof useQueryPayrollExportClosedTimeEntriesQuery
>;
export type QueryPayrollExportClosedTimeEntriesLazyQueryHookResult = ReturnType<
  typeof useQueryPayrollExportClosedTimeEntriesLazyQuery
>;
export type QueryPayrollExportClosedTimeEntriesSuspenseQueryHookResult = ReturnType<
  typeof useQueryPayrollExportClosedTimeEntriesSuspenseQuery
>;
export type QueryPayrollExportClosedTimeEntriesQueryResult = Apollo.QueryResult<
  QueryPayrollExportClosedTimeEntriesQuery,
  QueryPayrollExportClosedTimeEntriesQueryVariables
>;
export const CreatePerDiemDocument = gql`
  mutation CreatePerDiem($perDiem: PerDiemCreateInput!) {
    createPerDiem(perDiem: $perDiem) {
      id
      organizationId
      memberId
      submittedBy
      dateStamp
      description
      amount
      requestedAmount
      state
      projectId
      createdOnDst
      createdOnLocal
      createdOn
      updatedOn
      submittedOn
      answeredOn
    }
  }
`;
export type CreatePerDiemMutationFn = Apollo.MutationFunction<CreatePerDiemMutation, CreatePerDiemMutationVariables>;

/**
 * __useCreatePerDiemMutation__
 *
 * To run a mutation, you first call `useCreatePerDiemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePerDiemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPerDiemMutation, { data, loading, error }] = useCreatePerDiemMutation({
 *   variables: {
 *      perDiem: // value for 'perDiem'
 *   },
 * });
 */
export function useCreatePerDiemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePerDiemMutation, CreatePerDiemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePerDiemMutation, CreatePerDiemMutationVariables>(CreatePerDiemDocument, options);
}
export type CreatePerDiemMutationHookResult = ReturnType<typeof useCreatePerDiemMutation>;
export type CreatePerDiemMutationResult = Apollo.MutationResult<CreatePerDiemMutation>;
export type CreatePerDiemMutationOptions = Apollo.BaseMutationOptions<
  CreatePerDiemMutation,
  CreatePerDiemMutationVariables
>;
export const UpdatePerDiemDocument = gql`
  mutation UpdatePerDiem($perDiem: PerDiemUpdateInput!) {
    updatePerDiem(perDiem: $perDiem) {
      id
      organizationId
      memberId
      submittedBy
      dateStamp
      description
      amount
      requestedAmount
      rejectionReason
      state
      answeredBy
      answeredOn
      projectId
      createdOnDst
      createdOnLocal
      createdOn
      updatedOn
      submittedOn
      deletedOn
    }
  }
`;
export type UpdatePerDiemMutationFn = Apollo.MutationFunction<UpdatePerDiemMutation, UpdatePerDiemMutationVariables>;

/**
 * __useUpdatePerDiemMutation__
 *
 * To run a mutation, you first call `useUpdatePerDiemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePerDiemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePerDiemMutation, { data, loading, error }] = useUpdatePerDiemMutation({
 *   variables: {
 *      perDiem: // value for 'perDiem'
 *   },
 * });
 */
export function useUpdatePerDiemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePerDiemMutation, UpdatePerDiemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePerDiemMutation, UpdatePerDiemMutationVariables>(UpdatePerDiemDocument, options);
}
export type UpdatePerDiemMutationHookResult = ReturnType<typeof useUpdatePerDiemMutation>;
export type UpdatePerDiemMutationResult = Apollo.MutationResult<UpdatePerDiemMutation>;
export type UpdatePerDiemMutationOptions = Apollo.BaseMutationOptions<
  UpdatePerDiemMutation,
  UpdatePerDiemMutationVariables
>;
export const PerDiemDocument = gql`
  query PerDiem($after: String, $filter: PerDiemFilter) {
    perDiems(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      id
      cursor
      organizationId
      memberId
      submittedBy
      dateStamp
      description
      amount
      requestedAmount
      rejectionReason
      projectId
      createdOn
      createdOnLocal
      answeredOn
      answeredBy
      state
      submittedOn
      updatedOn
      deletedOn
      member {
        id
        firstName
        lastName
        position {
          id
          level
        }
      }
      project {
        id
        title
        ancestors {
          id
          title
          depth
        }
        children {
          id
          title
          depth
        }
      }
      submittedByMember {
        id
        firstName
        lastName
      }
      answeredByMember {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __usePerDiemQuery__
 *
 * To run a query within a React component, call `usePerDiemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerDiemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerDiemQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePerDiemQuery(baseOptions?: Apollo.QueryHookOptions<PerDiemQuery, PerDiemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PerDiemQuery, PerDiemQueryVariables>(PerDiemDocument, options);
}
export function usePerDiemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerDiemQuery, PerDiemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PerDiemQuery, PerDiemQueryVariables>(PerDiemDocument, options);
}
export function usePerDiemSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PerDiemQuery, PerDiemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PerDiemQuery, PerDiemQueryVariables>(PerDiemDocument, options);
}
export type PerDiemQueryHookResult = ReturnType<typeof usePerDiemQuery>;
export type PerDiemLazyQueryHookResult = ReturnType<typeof usePerDiemLazyQuery>;
export type PerDiemSuspenseQueryHookResult = ReturnType<typeof usePerDiemSuspenseQuery>;
export type PerDiemQueryResult = Apollo.QueryResult<PerDiemQuery, PerDiemQueryVariables>;
export const TimeEntryRekognitionDocument = gql`
  query TimeEntryRekognition($after: String, $filter: TimeEntryRekognitionFilter) {
    timeEntryRekognition(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      confidence
      cursor
      id
      imageId
      imageUrl
      referenceImageId
      referenceImageUrl
      createdOnDst
      resolvedOn
      similarity
      type
      source
      timeEntry {
        id
        member {
          id
          imageUrl
          firstName
          lastName
        }
        startTime
        endTime
        actionType
        deletedOn
      }
      createdOn
      createdOnLocal
    }
  }
`;

/**
 * __useTimeEntryRekognitionQuery__
 *
 * To run a query within a React component, call `useTimeEntryRekognitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryRekognitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryRekognitionQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTimeEntryRekognitionQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeEntryRekognitionQuery, TimeEntryRekognitionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryRekognitionQuery, TimeEntryRekognitionQueryVariables>(
    TimeEntryRekognitionDocument,
    options
  );
}
export function useTimeEntryRekognitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryRekognitionQuery, TimeEntryRekognitionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryRekognitionQuery, TimeEntryRekognitionQueryVariables>(
    TimeEntryRekognitionDocument,
    options
  );
}
export function useTimeEntryRekognitionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryRekognitionQuery, TimeEntryRekognitionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryRekognitionQuery, TimeEntryRekognitionQueryVariables>(
    TimeEntryRekognitionDocument,
    options
  );
}
export type TimeEntryRekognitionQueryHookResult = ReturnType<typeof useTimeEntryRekognitionQuery>;
export type TimeEntryRekognitionLazyQueryHookResult = ReturnType<typeof useTimeEntryRekognitionLazyQuery>;
export type TimeEntryRekognitionSuspenseQueryHookResult = ReturnType<typeof useTimeEntryRekognitionSuspenseQuery>;
export type TimeEntryRekognitionQueryResult = Apollo.QueryResult<
  TimeEntryRekognitionQuery,
  TimeEntryRekognitionQueryVariables
>;
export const CreateMediaEntryCommentDocument = gql`
  mutation CreateMediaEntryComment($mediaEntryComment: MediaEntryCommentCreateInput) {
    createMediaEntryComment(mediaEntryComment: $mediaEntryComment) {
      id
      comment
      mediaEntryId
      createdOn
    }
  }
`;
export type CreateMediaEntryCommentMutationFn = Apollo.MutationFunction<
  CreateMediaEntryCommentMutation,
  CreateMediaEntryCommentMutationVariables
>;

/**
 * __useCreateMediaEntryCommentMutation__
 *
 * To run a mutation, you first call `useCreateMediaEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaEntryCommentMutation, { data, loading, error }] = useCreateMediaEntryCommentMutation({
 *   variables: {
 *      mediaEntryComment: // value for 'mediaEntryComment'
 *   },
 * });
 */
export function useCreateMediaEntryCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaEntryCommentMutation, CreateMediaEntryCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMediaEntryCommentMutation, CreateMediaEntryCommentMutationVariables>(
    CreateMediaEntryCommentDocument,
    options
  );
}
export type CreateMediaEntryCommentMutationHookResult = ReturnType<typeof useCreateMediaEntryCommentMutation>;
export type CreateMediaEntryCommentMutationResult = Apollo.MutationResult<CreateMediaEntryCommentMutation>;
export type CreateMediaEntryCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaEntryCommentMutation,
  CreateMediaEntryCommentMutationVariables
>;
export const CreateTagMediaCollectionLinkDocument = gql`
  mutation CreateTagMediaCollectionLink($tagMediaCollectionLink: TagMediaCollectionLinkCreateInput) {
    createTagMediaCollectionLink(tagMediaCollectionLink: $tagMediaCollectionLink) {
      id
      tagId
      mediaCollectionId
      createdOn
    }
  }
`;
export type CreateTagMediaCollectionLinkMutationFn = Apollo.MutationFunction<
  CreateTagMediaCollectionLinkMutation,
  CreateTagMediaCollectionLinkMutationVariables
>;

/**
 * __useCreateTagMediaCollectionLinkMutation__
 *
 * To run a mutation, you first call `useCreateTagMediaCollectionLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMediaCollectionLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMediaCollectionLinkMutation, { data, loading, error }] = useCreateTagMediaCollectionLinkMutation({
 *   variables: {
 *      tagMediaCollectionLink: // value for 'tagMediaCollectionLink'
 *   },
 * });
 */
export function useCreateTagMediaCollectionLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTagMediaCollectionLinkMutation,
    CreateTagMediaCollectionLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMediaCollectionLinkMutation, CreateTagMediaCollectionLinkMutationVariables>(
    CreateTagMediaCollectionLinkDocument,
    options
  );
}
export type CreateTagMediaCollectionLinkMutationHookResult = ReturnType<typeof useCreateTagMediaCollectionLinkMutation>;
export type CreateTagMediaCollectionLinkMutationResult = Apollo.MutationResult<CreateTagMediaCollectionLinkMutation>;
export type CreateTagMediaCollectionLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMediaCollectionLinkMutation,
  CreateTagMediaCollectionLinkMutationVariables
>;
export const UpdateTagMediaCollectionLinkDocument = gql`
  mutation UpdateTagMediaCollectionLink($tagMediaCollectionLink: TagMediaCollectionLinkUpdateInput) {
    updateTagMediaCollectionLink(tagMediaCollectionLink: $tagMediaCollectionLink) {
      id
      tagId
      mediaCollectionId
      createdOn
      deletedOn
    }
  }
`;
export type UpdateTagMediaCollectionLinkMutationFn = Apollo.MutationFunction<
  UpdateTagMediaCollectionLinkMutation,
  UpdateTagMediaCollectionLinkMutationVariables
>;

/**
 * __useUpdateTagMediaCollectionLinkMutation__
 *
 * To run a mutation, you first call `useUpdateTagMediaCollectionLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMediaCollectionLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMediaCollectionLinkMutation, { data, loading, error }] = useUpdateTagMediaCollectionLinkMutation({
 *   variables: {
 *      tagMediaCollectionLink: // value for 'tagMediaCollectionLink'
 *   },
 * });
 */
export function useUpdateTagMediaCollectionLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagMediaCollectionLinkMutation,
    UpdateTagMediaCollectionLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMediaCollectionLinkMutation, UpdateTagMediaCollectionLinkMutationVariables>(
    UpdateTagMediaCollectionLinkDocument,
    options
  );
}
export type UpdateTagMediaCollectionLinkMutationHookResult = ReturnType<typeof useUpdateTagMediaCollectionLinkMutation>;
export type UpdateTagMediaCollectionLinkMutationResult = Apollo.MutationResult<UpdateTagMediaCollectionLinkMutation>;
export type UpdateTagMediaCollectionLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagMediaCollectionLinkMutation,
  UpdateTagMediaCollectionLinkMutationVariables
>;
export const MediaEntryCommentsDocument = gql`
  query MediaEntryComments($after: String, $filter: MediaEntryCommentFilter) {
    mediaEntryComments(after: $after, filter: $filter, sort: [{ createdOn: desc }]) {
      id
      comment
      mediaEntryId
      createdByMember {
        id
        firstName
        lastName
        imageUrl
        position {
          id
          level
        }
      }
      createdOn
      deletedOn
      updatedOn
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useMediaEntryCommentsQuery__
 *
 * To run a query within a React component, call `useMediaEntryCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaEntryCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaEntryCommentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMediaEntryCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<MediaEntryCommentsQuery, MediaEntryCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaEntryCommentsQuery, MediaEntryCommentsQueryVariables>(
    MediaEntryCommentsDocument,
    options
  );
}
export function useMediaEntryCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaEntryCommentsQuery, MediaEntryCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaEntryCommentsQuery, MediaEntryCommentsQueryVariables>(
    MediaEntryCommentsDocument,
    options
  );
}
export function useMediaEntryCommentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MediaEntryCommentsQuery, MediaEntryCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaEntryCommentsQuery, MediaEntryCommentsQueryVariables>(
    MediaEntryCommentsDocument,
    options
  );
}
export type MediaEntryCommentsQueryHookResult = ReturnType<typeof useMediaEntryCommentsQuery>;
export type MediaEntryCommentsLazyQueryHookResult = ReturnType<typeof useMediaEntryCommentsLazyQuery>;
export type MediaEntryCommentsSuspenseQueryHookResult = ReturnType<typeof useMediaEntryCommentsSuspenseQuery>;
export type MediaEntryCommentsQueryResult = Apollo.QueryResult<
  MediaEntryCommentsQuery,
  MediaEntryCommentsQueryVariables
>;
export const UpdateMediaEntryCommentDocument = gql`
  mutation UpdateMediaEntryComment($mediaEntryComment: MediaEntryCommentUpdateInput!) {
    updateMediaEntryComment(mediaEntryComment: $mediaEntryComment) {
      id
      comment
      deletedOn
    }
  }
`;
export type UpdateMediaEntryCommentMutationFn = Apollo.MutationFunction<
  UpdateMediaEntryCommentMutation,
  UpdateMediaEntryCommentMutationVariables
>;

/**
 * __useUpdateMediaEntryCommentMutation__
 *
 * To run a mutation, you first call `useUpdateMediaEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaEntryCommentMutation, { data, loading, error }] = useUpdateMediaEntryCommentMutation({
 *   variables: {
 *      mediaEntryComment: // value for 'mediaEntryComment'
 *   },
 * });
 */
export function useUpdateMediaEntryCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaEntryCommentMutation, UpdateMediaEntryCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaEntryCommentMutation, UpdateMediaEntryCommentMutationVariables>(
    UpdateMediaEntryCommentDocument,
    options
  );
}
export type UpdateMediaEntryCommentMutationHookResult = ReturnType<typeof useUpdateMediaEntryCommentMutation>;
export type UpdateMediaEntryCommentMutationResult = Apollo.MutationResult<UpdateMediaEntryCommentMutation>;
export type UpdateMediaEntryCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaEntryCommentMutation,
  UpdateMediaEntryCommentMutationVariables
>;
export const MediaCollectionsDocument = gql`
  query mediaCollections($filter: MediaCollectionFilter, $first: Int, $after: String, $sort: [MediaCollectionSort!]) {
    mediaCollections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      title
      description
      source
      createdOn
      cursor
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        collection {
          id
          createdBy
          tagLinks {
            id
            tag {
              id
              title
            }
          }
          createdByMember {
            id
            firstName
            lastName
          }
          createdOn
          updatedByMember {
            id
            firstName
            lastName
          }
          updatedOn
        }
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        metaData
        entryType
        location {
          id
          latitude
          longitude
          timeGmt
          timeLocal
          timeDst
        }
        captureDate
        createdOn
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
      }
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

/**
 * __useMediaCollectionsQuery__
 *
 * To run a query within a React component, call `useMediaCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCollectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMediaCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<MediaCollectionsQuery, MediaCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaCollectionsQuery, MediaCollectionsQueryVariables>(MediaCollectionsDocument, options);
}
export function useMediaCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaCollectionsQuery, MediaCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaCollectionsQuery, MediaCollectionsQueryVariables>(MediaCollectionsDocument, options);
}
export function useMediaCollectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MediaCollectionsQuery, MediaCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaCollectionsQuery, MediaCollectionsQueryVariables>(
    MediaCollectionsDocument,
    options
  );
}
export type MediaCollectionsQueryHookResult = ReturnType<typeof useMediaCollectionsQuery>;
export type MediaCollectionsLazyQueryHookResult = ReturnType<typeof useMediaCollectionsLazyQuery>;
export type MediaCollectionsSuspenseQueryHookResult = ReturnType<typeof useMediaCollectionsSuspenseQuery>;
export type MediaCollectionsQueryResult = Apollo.QueryResult<MediaCollectionsQuery, MediaCollectionsQueryVariables>;
export const SimpleMediaCollectionsDocument = gql`
  query simpleMediaCollections(
    $filter: MediaCollectionFilter
    $first: Int
    $after: String
    $sort: [MediaCollectionSort!]
  ) {
    mediaCollections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      deletedOn
    }
  }
`;

/**
 * __useSimpleMediaCollectionsQuery__
 *
 * To run a query within a React component, call `useSimpleMediaCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleMediaCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleMediaCollectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSimpleMediaCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SimpleMediaCollectionsQuery, SimpleMediaCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleMediaCollectionsQuery, SimpleMediaCollectionsQueryVariables>(
    SimpleMediaCollectionsDocument,
    options
  );
}
export function useSimpleMediaCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimpleMediaCollectionsQuery, SimpleMediaCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleMediaCollectionsQuery, SimpleMediaCollectionsQueryVariables>(
    SimpleMediaCollectionsDocument,
    options
  );
}
export function useSimpleMediaCollectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SimpleMediaCollectionsQuery, SimpleMediaCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SimpleMediaCollectionsQuery, SimpleMediaCollectionsQueryVariables>(
    SimpleMediaCollectionsDocument,
    options
  );
}
export type SimpleMediaCollectionsQueryHookResult = ReturnType<typeof useSimpleMediaCollectionsQuery>;
export type SimpleMediaCollectionsLazyQueryHookResult = ReturnType<typeof useSimpleMediaCollectionsLazyQuery>;
export type SimpleMediaCollectionsSuspenseQueryHookResult = ReturnType<typeof useSimpleMediaCollectionsSuspenseQuery>;
export type SimpleMediaCollectionsQueryResult = Apollo.QueryResult<
  SimpleMediaCollectionsQuery,
  SimpleMediaCollectionsQueryVariables
>;
export const MediaCollectionsForProjectDocument = gql`
  query mediaCollectionsForProject(
    $filter: MediaCollectionFilter
    $first: Int
    $after: String
    $sort: [MediaCollectionSort!]
  ) {
    mediaCollections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      title
      description
      source
      createdOn
      cursor
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        collection {
          id
          createdBy
          tagLinks {
            id
            tag {
              id
              title
            }
          }
          createdByMember {
            id
            firstName
            lastName
          }
          createdOn
          updatedByMember {
            id
            firstName
            lastName
          }
          updatedOn
        }
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        metaData
        entryType
        location {
          id
          latitude
          longitude
          timeGmt
          timeLocal
          timeDst
        }
        captureDate
        createdOn
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
      }
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

/**
 * __useMediaCollectionsForProjectQuery__
 *
 * To run a query within a React component, call `useMediaCollectionsForProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCollectionsForProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCollectionsForProjectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMediaCollectionsForProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<MediaCollectionsForProjectQuery, MediaCollectionsForProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaCollectionsForProjectQuery, MediaCollectionsForProjectQueryVariables>(
    MediaCollectionsForProjectDocument,
    options
  );
}
export function useMediaCollectionsForProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaCollectionsForProjectQuery, MediaCollectionsForProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaCollectionsForProjectQuery, MediaCollectionsForProjectQueryVariables>(
    MediaCollectionsForProjectDocument,
    options
  );
}
export function useMediaCollectionsForProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MediaCollectionsForProjectQuery,
    MediaCollectionsForProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaCollectionsForProjectQuery, MediaCollectionsForProjectQueryVariables>(
    MediaCollectionsForProjectDocument,
    options
  );
}
export type MediaCollectionsForProjectQueryHookResult = ReturnType<typeof useMediaCollectionsForProjectQuery>;
export type MediaCollectionsForProjectLazyQueryHookResult = ReturnType<typeof useMediaCollectionsForProjectLazyQuery>;
export type MediaCollectionsForProjectSuspenseQueryHookResult = ReturnType<
  typeof useMediaCollectionsForProjectSuspenseQuery
>;
export type MediaCollectionsForProjectQueryResult = Apollo.QueryResult<
  MediaCollectionsForProjectQuery,
  MediaCollectionsForProjectQueryVariables
>;
export const CreateMediaCollectionDocument = gql`
  mutation createMediaCollection($mediaCollection: MediaCollectionCreateInput!) {
    createMediaCollection(mediaCollection: $mediaCollection) {
      id
      title
      description
      createdOn
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        location {
          id
          latitude
          longitude
        }
        captureDate
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;
export type CreateMediaCollectionMutationFn = Apollo.MutationFunction<
  CreateMediaCollectionMutation,
  CreateMediaCollectionMutationVariables
>;

/**
 * __useCreateMediaCollectionMutation__
 *
 * To run a mutation, you first call `useCreateMediaCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaCollectionMutation, { data, loading, error }] = useCreateMediaCollectionMutation({
 *   variables: {
 *      mediaCollection: // value for 'mediaCollection'
 *   },
 * });
 */
export function useCreateMediaCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaCollectionMutation, CreateMediaCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMediaCollectionMutation, CreateMediaCollectionMutationVariables>(
    CreateMediaCollectionDocument,
    options
  );
}
export type CreateMediaCollectionMutationHookResult = ReturnType<typeof useCreateMediaCollectionMutation>;
export type CreateMediaCollectionMutationResult = Apollo.MutationResult<CreateMediaCollectionMutation>;
export type CreateMediaCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaCollectionMutation,
  CreateMediaCollectionMutationVariables
>;
export const UpdateMediaCollectionDocument = gql`
  mutation updateMediaCollection($mediaCollection: MediaCollectionUpdateInput!) {
    updateMediaCollection(mediaCollection: $mediaCollection) {
      id
      title
      description
      createdOn
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        entryType
        location {
          id
          latitude
          longitude
        }
        captureDate
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
        metaData
      }
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;
export type UpdateMediaCollectionMutationFn = Apollo.MutationFunction<
  UpdateMediaCollectionMutation,
  UpdateMediaCollectionMutationVariables
>;

/**
 * __useUpdateMediaCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateMediaCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaCollectionMutation, { data, loading, error }] = useUpdateMediaCollectionMutation({
 *   variables: {
 *      mediaCollection: // value for 'mediaCollection'
 *   },
 * });
 */
export function useUpdateMediaCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaCollectionMutation, UpdateMediaCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMediaCollectionMutation, UpdateMediaCollectionMutationVariables>(
    UpdateMediaCollectionDocument,
    options
  );
}
export type UpdateMediaCollectionMutationHookResult = ReturnType<typeof useUpdateMediaCollectionMutation>;
export type UpdateMediaCollectionMutationResult = Apollo.MutationResult<UpdateMediaCollectionMutation>;
export type UpdateMediaCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaCollectionMutation,
  UpdateMediaCollectionMutationVariables
>;
export const DeleteMediaEntryDocument = gql`
  mutation DeleteMediaEntry($id: ID!, $deletedOn: Timestamp) {
    updateMediaEntry(mediaEntry: { id: $id, deletedOn: $deletedOn }) {
      id
      mediaCollectionId
      fileUrl
      location {
        id
        latitude
        longitude
      }
      captureDate
      deletedOn
      comment
    }
  }
`;
export type DeleteMediaEntryMutationFn = Apollo.MutationFunction<
  DeleteMediaEntryMutation,
  DeleteMediaEntryMutationVariables
>;

/**
 * __useDeleteMediaEntryMutation__
 *
 * To run a mutation, you first call `useDeleteMediaEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaEntryMutation, { data, loading, error }] = useDeleteMediaEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedOn: // value for 'deletedOn'
 *   },
 * });
 */
export function useDeleteMediaEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMediaEntryMutation, DeleteMediaEntryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMediaEntryMutation, DeleteMediaEntryMutationVariables>(
    DeleteMediaEntryDocument,
    options
  );
}
export type DeleteMediaEntryMutationHookResult = ReturnType<typeof useDeleteMediaEntryMutation>;
export type DeleteMediaEntryMutationResult = Apollo.MutationResult<DeleteMediaEntryMutation>;
export type DeleteMediaEntryMutationOptions = Apollo.BaseMutationOptions<
  DeleteMediaEntryMutation,
  DeleteMediaEntryMutationVariables
>;
export const DeleteCollectionDocument = gql`
  mutation DeleteCollection($id: ID!, $deletedOn: Timestamp) {
    updateMediaCollection(mediaCollection: { id: $id, deletedOn: $deletedOn }) {
      id
      title
      description
      deletedOn
      createdOn
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        captureDate
        location {
          id
          latitude
          longitude
        }
        createdOn
        deletedOn
        comment
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedOn: // value for 'deletedOn'
 *   },
 * });
 */
export function useDeleteCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(
    DeleteCollectionDocument,
    options
  );
}
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollectionMutation,
  DeleteCollectionMutationVariables
>;
export const MediaEntryDocument = gql`
  query mediaEntry($filter: MediaEntryFilter, $first: Int, $after: String) {
    mediaEntries(first: $first, after: $after, filter: $filter, sort: [{ createdOn: desc }]) {
      id
      mediaCollectionId
      fileUrl
      annotationFileUrl
      videoThumbFileUrl
      location {
        id
        latitude
        longitude
        timeGmt
        timeLocal
        timeDst
      }
      captureDate
      createdOn
      deletedOn
      comment
    }
  }
`;

/**
 * __useMediaEntryQuery__
 *
 * To run a query within a React component, call `useMediaEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaEntryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMediaEntryQuery(baseOptions?: Apollo.QueryHookOptions<MediaEntryQuery, MediaEntryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MediaEntryQuery, MediaEntryQueryVariables>(MediaEntryDocument, options);
}
export function useMediaEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MediaEntryQuery, MediaEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MediaEntryQuery, MediaEntryQueryVariables>(MediaEntryDocument, options);
}
export function useMediaEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MediaEntryQuery, MediaEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MediaEntryQuery, MediaEntryQueryVariables>(MediaEntryDocument, options);
}
export type MediaEntryQueryHookResult = ReturnType<typeof useMediaEntryQuery>;
export type MediaEntryLazyQueryHookResult = ReturnType<typeof useMediaEntryLazyQuery>;
export type MediaEntrySuspenseQueryHookResult = ReturnType<typeof useMediaEntrySuspenseQuery>;
export type MediaEntryQueryResult = Apollo.QueryResult<MediaEntryQuery, MediaEntryQueryVariables>;
export const QueryProjectSidePanelDetailsDocument = gql`
  query queryProjectSidePanelDetails($after: String, $filter: ProjectFilter, $sort: [ProjectSort!], $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      title
      cursor
      createdOn
      updatedOn
      rootProjectId
      projectInfo {
        id
        number
        customer
        phone
        address1
        address2
        city
        country
        county
        postalCode
        additionalInfo
        updatedOn
      }
      projectGroup {
        id
        groupName
      }
    }
  }
`;

/**
 * __useQueryProjectSidePanelDetailsQuery__
 *
 * To run a query within a React component, call `useQueryProjectSidePanelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectSidePanelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectSidePanelDetailsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryProjectSidePanelDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryProjectSidePanelDetailsQuery, QueryProjectSidePanelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectSidePanelDetailsQuery, QueryProjectSidePanelDetailsQueryVariables>(
    QueryProjectSidePanelDetailsDocument,
    options
  );
}
export function useQueryProjectSidePanelDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProjectSidePanelDetailsQuery,
    QueryProjectSidePanelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectSidePanelDetailsQuery, QueryProjectSidePanelDetailsQueryVariables>(
    QueryProjectSidePanelDetailsDocument,
    options
  );
}
export function useQueryProjectSidePanelDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectSidePanelDetailsQuery,
    QueryProjectSidePanelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectSidePanelDetailsQuery, QueryProjectSidePanelDetailsQueryVariables>(
    QueryProjectSidePanelDetailsDocument,
    options
  );
}
export type QueryProjectSidePanelDetailsQueryHookResult = ReturnType<typeof useQueryProjectSidePanelDetailsQuery>;
export type QueryProjectSidePanelDetailsLazyQueryHookResult = ReturnType<
  typeof useQueryProjectSidePanelDetailsLazyQuery
>;
export type QueryProjectSidePanelDetailsSuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectSidePanelDetailsSuspenseQuery
>;
export type QueryProjectSidePanelDetailsQueryResult = Apollo.QueryResult<
  QueryProjectSidePanelDetailsQuery,
  QueryProjectSidePanelDetailsQueryVariables
>;
export const TimeEntryDocument = gql`
  query timeEntry($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      cursor
      startTime
      endTime
      member {
        id
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

/**
 * __useTimeEntryQuery__
 *
 * To run a query within a React component, call `useTimeEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimeEntryQuery(baseOptions?: Apollo.QueryHookOptions<TimeEntryQuery, TimeEntryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeEntryQuery, TimeEntryQueryVariables>(TimeEntryDocument, options);
}
export function useTimeEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryQuery, TimeEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeEntryQuery, TimeEntryQueryVariables>(TimeEntryDocument, options);
}
export function useTimeEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeEntryQuery, TimeEntryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeEntryQuery, TimeEntryQueryVariables>(TimeEntryDocument, options);
}
export type TimeEntryQueryHookResult = ReturnType<typeof useTimeEntryQuery>;
export type TimeEntryLazyQueryHookResult = ReturnType<typeof useTimeEntryLazyQuery>;
export type TimeEntrySuspenseQueryHookResult = ReturnType<typeof useTimeEntrySuspenseQuery>;
export type TimeEntryQueryResult = Apollo.QueryResult<TimeEntryQuery, TimeEntryQueryVariables>;
export const IncidentDocument = gql`
  query Incident($after: String, $filter: IncidentFilter) {
    incidents(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      ...IncidentDetails
    }
  }
  ${IncidentDetailsFragmentDoc}
`;

/**
 * __useIncidentQuery__
 *
 * To run a query within a React component, call `useIncidentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIncidentQuery(baseOptions?: Apollo.QueryHookOptions<IncidentQuery, IncidentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IncidentQuery, IncidentQueryVariables>(IncidentDocument, options);
}
export function useIncidentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncidentQuery, IncidentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IncidentQuery, IncidentQueryVariables>(IncidentDocument, options);
}
export function useIncidentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IncidentQuery, IncidentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IncidentQuery, IncidentQueryVariables>(IncidentDocument, options);
}
export type IncidentQueryHookResult = ReturnType<typeof useIncidentQuery>;
export type IncidentLazyQueryHookResult = ReturnType<typeof useIncidentLazyQuery>;
export type IncidentSuspenseQueryHookResult = ReturnType<typeof useIncidentSuspenseQuery>;
export type IncidentQueryResult = Apollo.QueryResult<IncidentQuery, IncidentQueryVariables>;
export const CreateIncidentDocument = gql`
  mutation CreateIncident($incident: IncidentCreateInput) {
    createIncident(incident: $incident) {
      id
    }
  }
`;
export type CreateIncidentMutationFn = Apollo.MutationFunction<CreateIncidentMutation, CreateIncidentMutationVariables>;

/**
 * __useCreateIncidentMutation__
 *
 * To run a mutation, you first call `useCreateIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentMutation, { data, loading, error }] = useCreateIncidentMutation({
 *   variables: {
 *      incident: // value for 'incident'
 *   },
 * });
 */
export function useCreateIncidentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIncidentMutation, CreateIncidentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIncidentMutation, CreateIncidentMutationVariables>(CreateIncidentDocument, options);
}
export type CreateIncidentMutationHookResult = ReturnType<typeof useCreateIncidentMutation>;
export type CreateIncidentMutationResult = Apollo.MutationResult<CreateIncidentMutation>;
export type CreateIncidentMutationOptions = Apollo.BaseMutationOptions<
  CreateIncidentMutation,
  CreateIncidentMutationVariables
>;
export const CreateIncidentAnswerDocument = gql`
  mutation CreateIncidentAnswer($incidentAnswer: IncidentAnswerCreateInput) {
    createIncidentAnswer(incidentAnswer: $incidentAnswer) {
      id
    }
  }
`;
export type CreateIncidentAnswerMutationFn = Apollo.MutationFunction<
  CreateIncidentAnswerMutation,
  CreateIncidentAnswerMutationVariables
>;

/**
 * __useCreateIncidentAnswerMutation__
 *
 * To run a mutation, you first call `useCreateIncidentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentAnswerMutation, { data, loading, error }] = useCreateIncidentAnswerMutation({
 *   variables: {
 *      incidentAnswer: // value for 'incidentAnswer'
 *   },
 * });
 */
export function useCreateIncidentAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateIncidentAnswerMutation, CreateIncidentAnswerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIncidentAnswerMutation, CreateIncidentAnswerMutationVariables>(
    CreateIncidentAnswerDocument,
    options
  );
}
export type CreateIncidentAnswerMutationHookResult = ReturnType<typeof useCreateIncidentAnswerMutation>;
export type CreateIncidentAnswerMutationResult = Apollo.MutationResult<CreateIncidentAnswerMutation>;
export type CreateIncidentAnswerMutationOptions = Apollo.BaseMutationOptions<
  CreateIncidentAnswerMutation,
  CreateIncidentAnswerMutationVariables
>;
export const CreateIncidentMedicalDetailsDocument = gql`
  mutation CreateIncidentMedicalDetails($incidentMedicalDetails: IncidentMedicalDetailsCreateInput) {
    createIncidentMedicalDetails(incidentMedicalDetails: $incidentMedicalDetails) {
      id
    }
  }
`;
export type CreateIncidentMedicalDetailsMutationFn = Apollo.MutationFunction<
  CreateIncidentMedicalDetailsMutation,
  CreateIncidentMedicalDetailsMutationVariables
>;

/**
 * __useCreateIncidentMedicalDetailsMutation__
 *
 * To run a mutation, you first call `useCreateIncidentMedicalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentMedicalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentMedicalDetailsMutation, { data, loading, error }] = useCreateIncidentMedicalDetailsMutation({
 *   variables: {
 *      incidentMedicalDetails: // value for 'incidentMedicalDetails'
 *   },
 * });
 */
export function useCreateIncidentMedicalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIncidentMedicalDetailsMutation,
    CreateIncidentMedicalDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIncidentMedicalDetailsMutation, CreateIncidentMedicalDetailsMutationVariables>(
    CreateIncidentMedicalDetailsDocument,
    options
  );
}
export type CreateIncidentMedicalDetailsMutationHookResult = ReturnType<typeof useCreateIncidentMedicalDetailsMutation>;
export type CreateIncidentMedicalDetailsMutationResult = Apollo.MutationResult<CreateIncidentMedicalDetailsMutation>;
export type CreateIncidentMedicalDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateIncidentMedicalDetailsMutation,
  CreateIncidentMedicalDetailsMutationVariables
>;
export const CreateIncidentOutcomeDetailsDocument = gql`
  mutation CreateIncidentOutcomeDetails($incidentOutcomeDetails: IncidentOutcomeDetailsCreateInput) {
    createIncidentOutcomeDetails(incidentOutcomeDetails: $incidentOutcomeDetails) {
      id
    }
  }
`;
export type CreateIncidentOutcomeDetailsMutationFn = Apollo.MutationFunction<
  CreateIncidentOutcomeDetailsMutation,
  CreateIncidentOutcomeDetailsMutationVariables
>;

/**
 * __useCreateIncidentOutcomeDetailsMutation__
 *
 * To run a mutation, you first call `useCreateIncidentOutcomeDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIncidentOutcomeDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIncidentOutcomeDetailsMutation, { data, loading, error }] = useCreateIncidentOutcomeDetailsMutation({
 *   variables: {
 *      incidentOutcomeDetails: // value for 'incidentOutcomeDetails'
 *   },
 * });
 */
export function useCreateIncidentOutcomeDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIncidentOutcomeDetailsMutation,
    CreateIncidentOutcomeDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIncidentOutcomeDetailsMutation, CreateIncidentOutcomeDetailsMutationVariables>(
    CreateIncidentOutcomeDetailsDocument,
    options
  );
}
export type CreateIncidentOutcomeDetailsMutationHookResult = ReturnType<typeof useCreateIncidentOutcomeDetailsMutation>;
export type CreateIncidentOutcomeDetailsMutationResult = Apollo.MutationResult<CreateIncidentOutcomeDetailsMutation>;
export type CreateIncidentOutcomeDetailsMutationOptions = Apollo.BaseMutationOptions<
  CreateIncidentOutcomeDetailsMutation,
  CreateIncidentOutcomeDetailsMutationVariables
>;
export const UpdateIncidentAnswerDocument = gql`
  mutation UpdateIncidentAnswer($incidentAnswer: IncidentAnswerUpdateInput) {
    updateIncidentAnswer(incidentAnswer: $incidentAnswer) {
      id
    }
  }
`;
export type UpdateIncidentAnswerMutationFn = Apollo.MutationFunction<
  UpdateIncidentAnswerMutation,
  UpdateIncidentAnswerMutationVariables
>;

/**
 * __useUpdateIncidentAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentAnswerMutation, { data, loading, error }] = useUpdateIncidentAnswerMutation({
 *   variables: {
 *      incidentAnswer: // value for 'incidentAnswer'
 *   },
 * });
 */
export function useUpdateIncidentAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateIncidentAnswerMutation, UpdateIncidentAnswerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIncidentAnswerMutation, UpdateIncidentAnswerMutationVariables>(
    UpdateIncidentAnswerDocument,
    options
  );
}
export type UpdateIncidentAnswerMutationHookResult = ReturnType<typeof useUpdateIncidentAnswerMutation>;
export type UpdateIncidentAnswerMutationResult = Apollo.MutationResult<UpdateIncidentAnswerMutation>;
export type UpdateIncidentAnswerMutationOptions = Apollo.BaseMutationOptions<
  UpdateIncidentAnswerMutation,
  UpdateIncidentAnswerMutationVariables
>;
export const UpdateIncidentMedicalDetailsDocument = gql`
  mutation UpdateIncidentMedicalDetails($incidentMedicalDetails: IncidentMedicalDetailsUpdateInput) {
    updateIncidentMedicalDetails(incidentMedicalDetails: $incidentMedicalDetails) {
      id
    }
  }
`;
export type UpdateIncidentMedicalDetailsMutationFn = Apollo.MutationFunction<
  UpdateIncidentMedicalDetailsMutation,
  UpdateIncidentMedicalDetailsMutationVariables
>;

/**
 * __useUpdateIncidentMedicalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentMedicalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentMedicalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentMedicalDetailsMutation, { data, loading, error }] = useUpdateIncidentMedicalDetailsMutation({
 *   variables: {
 *      incidentMedicalDetails: // value for 'incidentMedicalDetails'
 *   },
 * });
 */
export function useUpdateIncidentMedicalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIncidentMedicalDetailsMutation,
    UpdateIncidentMedicalDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIncidentMedicalDetailsMutation, UpdateIncidentMedicalDetailsMutationVariables>(
    UpdateIncidentMedicalDetailsDocument,
    options
  );
}
export type UpdateIncidentMedicalDetailsMutationHookResult = ReturnType<typeof useUpdateIncidentMedicalDetailsMutation>;
export type UpdateIncidentMedicalDetailsMutationResult = Apollo.MutationResult<UpdateIncidentMedicalDetailsMutation>;
export type UpdateIncidentMedicalDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateIncidentMedicalDetailsMutation,
  UpdateIncidentMedicalDetailsMutationVariables
>;
export const UpdateIncidentOutcomeDetailsDocument = gql`
  mutation UpdateIncidentOutcomeDetails($incidentOutcomeDetails: IncidentOutcomeDetailsUpdateInput) {
    updateIncidentOutcomeDetails(incidentOutcomeDetails: $incidentOutcomeDetails) {
      id
    }
  }
`;
export type UpdateIncidentOutcomeDetailsMutationFn = Apollo.MutationFunction<
  UpdateIncidentOutcomeDetailsMutation,
  UpdateIncidentOutcomeDetailsMutationVariables
>;

/**
 * __useUpdateIncidentOutcomeDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateIncidentOutcomeDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncidentOutcomeDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncidentOutcomeDetailsMutation, { data, loading, error }] = useUpdateIncidentOutcomeDetailsMutation({
 *   variables: {
 *      incidentOutcomeDetails: // value for 'incidentOutcomeDetails'
 *   },
 * });
 */
export function useUpdateIncidentOutcomeDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIncidentOutcomeDetailsMutation,
    UpdateIncidentOutcomeDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIncidentOutcomeDetailsMutation, UpdateIncidentOutcomeDetailsMutationVariables>(
    UpdateIncidentOutcomeDetailsDocument,
    options
  );
}
export type UpdateIncidentOutcomeDetailsMutationHookResult = ReturnType<typeof useUpdateIncidentOutcomeDetailsMutation>;
export type UpdateIncidentOutcomeDetailsMutationResult = Apollo.MutationResult<UpdateIncidentOutcomeDetailsMutation>;
export type UpdateIncidentOutcomeDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateIncidentOutcomeDetailsMutation,
  UpdateIncidentOutcomeDetailsMutationVariables
>;
export const InspectionCategoriesDocument = gql`
  query InspectionCategories(
    $first: Int
    $after: String
    $filter: InspectionCategoryFilter
    $sort: [InspectionCategorySort!]
  ) {
    inspectionCategories(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      checklistId
      name
      createdOn
      updatedOn
      submittedOn
      deletedOn
      cursor
      questions {
        id
        categoryId
        type
        order
        enabled
        recommendation
      }
    }
  }
`;

/**
 * __useInspectionCategoriesQuery__
 *
 * To run a query within a React component, call `useInspectionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInspectionCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<InspectionCategoriesQuery, InspectionCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InspectionCategoriesQuery, InspectionCategoriesQueryVariables>(
    InspectionCategoriesDocument,
    options
  );
}
export function useInspectionCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InspectionCategoriesQuery, InspectionCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InspectionCategoriesQuery, InspectionCategoriesQueryVariables>(
    InspectionCategoriesDocument,
    options
  );
}
export function useInspectionCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InspectionCategoriesQuery, InspectionCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InspectionCategoriesQuery, InspectionCategoriesQueryVariables>(
    InspectionCategoriesDocument,
    options
  );
}
export type InspectionCategoriesQueryHookResult = ReturnType<typeof useInspectionCategoriesQuery>;
export type InspectionCategoriesLazyQueryHookResult = ReturnType<typeof useInspectionCategoriesLazyQuery>;
export type InspectionCategoriesSuspenseQueryHookResult = ReturnType<typeof useInspectionCategoriesSuspenseQuery>;
export type InspectionCategoriesQueryResult = Apollo.QueryResult<
  InspectionCategoriesQuery,
  InspectionCategoriesQueryVariables
>;
export const QueryInspectionQuestionsDocument = gql`
  query QueryInspectionQuestions($filter: InspectionQuestionFilter, $sort: [InspectionQuestionSort!], $first: Int) {
    inspectionQuestions(filter: $filter, sort: $sort, first: $first) {
      id
      categoryId
      question
      type
      choices
      order
      enabled
      recommendation
      createdOn
      deletedOn
      updatedOn
    }
  }
`;

/**
 * __useQueryInspectionQuestionsQuery__
 *
 * To run a query within a React component, call `useQueryInspectionQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryInspectionQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryInspectionQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useQueryInspectionQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryInspectionQuestionsQuery, QueryInspectionQuestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryInspectionQuestionsQuery, QueryInspectionQuestionsQueryVariables>(
    QueryInspectionQuestionsDocument,
    options
  );
}
export function useQueryInspectionQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryInspectionQuestionsQuery, QueryInspectionQuestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryInspectionQuestionsQuery, QueryInspectionQuestionsQueryVariables>(
    QueryInspectionQuestionsDocument,
    options
  );
}
export function useQueryInspectionQuestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryInspectionQuestionsQuery, QueryInspectionQuestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryInspectionQuestionsQuery, QueryInspectionQuestionsQueryVariables>(
    QueryInspectionQuestionsDocument,
    options
  );
}
export type QueryInspectionQuestionsQueryHookResult = ReturnType<typeof useQueryInspectionQuestionsQuery>;
export type QueryInspectionQuestionsLazyQueryHookResult = ReturnType<typeof useQueryInspectionQuestionsLazyQuery>;
export type QueryInspectionQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useQueryInspectionQuestionsSuspenseQuery
>;
export type QueryInspectionQuestionsQueryResult = Apollo.QueryResult<
  QueryInspectionQuestionsQuery,
  QueryInspectionQuestionsQueryVariables
>;
export const InspectionAssignmentsDocument = gql`
  query InspectionAssignments(
    $first: Int
    $after: String
    $filter: InspectionAssignmentFilter
    $sort: [InspectionAssignmentSort!]
  ) {
    inspectionAssignments(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      memberId
      member {
        id
        firstName
        lastName
      }
      checklistId
      checklist {
        id
        name
      }
      limitCategories
      limitedCategories {
        id
        categoryId
        category {
          id
          name
        }
        deletedOn
      }
      projectId
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
        }
      }
      submittedBy
      submittedByMember {
        id
        firstName
        lastName
      }
      description
      createdOn
      updatedOn
      deletedOn
      submittedOn
      date
      sendNotification
      notifySubmission
      notifyCircumstance
      circumstanceValue
      notificationTime
      notificationLinks {
        id
        memberId
        deletedOn
      }
      inspection {
        id
        completedOn
        deletedOn
      }
    }
  }
`;

/**
 * __useInspectionAssignmentsQuery__
 *
 * To run a query within a React component, call `useInspectionAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionAssignmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInspectionAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<InspectionAssignmentsQuery, InspectionAssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InspectionAssignmentsQuery, InspectionAssignmentsQueryVariables>(
    InspectionAssignmentsDocument,
    options
  );
}
export function useInspectionAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InspectionAssignmentsQuery, InspectionAssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InspectionAssignmentsQuery, InspectionAssignmentsQueryVariables>(
    InspectionAssignmentsDocument,
    options
  );
}
export function useInspectionAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InspectionAssignmentsQuery, InspectionAssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InspectionAssignmentsQuery, InspectionAssignmentsQueryVariables>(
    InspectionAssignmentsDocument,
    options
  );
}
export type InspectionAssignmentsQueryHookResult = ReturnType<typeof useInspectionAssignmentsQuery>;
export type InspectionAssignmentsLazyQueryHookResult = ReturnType<typeof useInspectionAssignmentsLazyQuery>;
export type InspectionAssignmentsSuspenseQueryHookResult = ReturnType<typeof useInspectionAssignmentsSuspenseQuery>;
export type InspectionAssignmentsQueryResult = Apollo.QueryResult<
  InspectionAssignmentsQuery,
  InspectionAssignmentsQueryVariables
>;
export const InspectionsDocument = gql`
  query Inspections($first: Int, $after: String, $filter: InspectionFilter, $sort: [InspectionSort!]) {
    inspections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      score
      assignmentId
      checklistId
      checklist {
        id
        name
        categories {
          id
          name
        }
      }
      answers {
        id
        answer
        questionId
        question
        flagged
        severity
        comments
        corrected
        inspectionQuestion {
          id
          order
          categoryId
          category {
            id
            name
          }
        }
        attachments {
          id
          fileUrl
          fileType
        }
      }
      projectId
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
        }
      }
      inspectedOn
      completedOn
      submittedBy
      submittedByMember {
        id
        firstName
        lastName
      }
      createdOn
      updatedOn
      deletedOn
    }
  }
`;

/**
 * __useInspectionsQuery__
 *
 * To run a query within a React component, call `useInspectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInspectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<InspectionsQuery, InspectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InspectionsQuery, InspectionsQueryVariables>(InspectionsDocument, options);
}
export function useInspectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InspectionsQuery, InspectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InspectionsQuery, InspectionsQueryVariables>(InspectionsDocument, options);
}
export function useInspectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InspectionsQuery, InspectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InspectionsQuery, InspectionsQueryVariables>(InspectionsDocument, options);
}
export type InspectionsQueryHookResult = ReturnType<typeof useInspectionsQuery>;
export type InspectionsLazyQueryHookResult = ReturnType<typeof useInspectionsLazyQuery>;
export type InspectionsSuspenseQueryHookResult = ReturnType<typeof useInspectionsSuspenseQuery>;
export type InspectionsQueryResult = Apollo.QueryResult<InspectionsQuery, InspectionsQueryVariables>;
export const TimeOffScheduleOverlapDocument = gql`
  query TimeOffScheduleOverlap($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      memberId
      dateStamp
      cursor
    }
  }
`;

/**
 * __useTimeOffScheduleOverlapQuery__
 *
 * To run a query within a React component, call `useTimeOffScheduleOverlapQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffScheduleOverlapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffScheduleOverlapQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useTimeOffScheduleOverlapQuery(
  baseOptions?: Apollo.QueryHookOptions<TimeOffScheduleOverlapQuery, TimeOffScheduleOverlapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeOffScheduleOverlapQuery, TimeOffScheduleOverlapQueryVariables>(
    TimeOffScheduleOverlapDocument,
    options
  );
}
export function useTimeOffScheduleOverlapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimeOffScheduleOverlapQuery, TimeOffScheduleOverlapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeOffScheduleOverlapQuery, TimeOffScheduleOverlapQueryVariables>(
    TimeOffScheduleOverlapDocument,
    options
  );
}
export function useTimeOffScheduleOverlapSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TimeOffScheduleOverlapQuery, TimeOffScheduleOverlapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TimeOffScheduleOverlapQuery, TimeOffScheduleOverlapQueryVariables>(
    TimeOffScheduleOverlapDocument,
    options
  );
}
export type TimeOffScheduleOverlapQueryHookResult = ReturnType<typeof useTimeOffScheduleOverlapQuery>;
export type TimeOffScheduleOverlapLazyQueryHookResult = ReturnType<typeof useTimeOffScheduleOverlapLazyQuery>;
export type TimeOffScheduleOverlapSuspenseQueryHookResult = ReturnType<typeof useTimeOffScheduleOverlapSuspenseQuery>;
export type TimeOffScheduleOverlapQueryResult = Apollo.QueryResult<
  TimeOffScheduleOverlapQuery,
  TimeOffScheduleOverlapQueryVariables
>;
export const CreateScheduleTimeOffDocument = gql`
  mutation CreateScheduleTimeOff($timeOff: TimeOffCreateInput) {
    createTimeOff(timeOff: $timeOff) {
      ...SchedulingTimeOffFields
    }
  }
  ${SchedulingTimeOffFieldsFragmentDoc}
`;
export type CreateScheduleTimeOffMutationFn = Apollo.MutationFunction<
  CreateScheduleTimeOffMutation,
  CreateScheduleTimeOffMutationVariables
>;

/**
 * __useCreateScheduleTimeOffMutation__
 *
 * To run a mutation, you first call `useCreateScheduleTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleTimeOffMutation, { data, loading, error }] = useCreateScheduleTimeOffMutation({
 *   variables: {
 *      timeOff: // value for 'timeOff'
 *   },
 * });
 */
export function useCreateScheduleTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateScheduleTimeOffMutation, CreateScheduleTimeOffMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateScheduleTimeOffMutation, CreateScheduleTimeOffMutationVariables>(
    CreateScheduleTimeOffDocument,
    options
  );
}
export type CreateScheduleTimeOffMutationHookResult = ReturnType<typeof useCreateScheduleTimeOffMutation>;
export type CreateScheduleTimeOffMutationResult = Apollo.MutationResult<CreateScheduleTimeOffMutation>;
export type CreateScheduleTimeOffMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduleTimeOffMutation,
  CreateScheduleTimeOffMutationVariables
>;
export const UpdateScheduleTimeOffDocument = gql`
  mutation UpdateScheduleTimeOff($timeOff: TimeOffUpdateInput) {
    updateTimeOff(timeOff: $timeOff) {
      ...SchedulingTimeOffFields
    }
  }
  ${SchedulingTimeOffFieldsFragmentDoc}
`;
export type UpdateScheduleTimeOffMutationFn = Apollo.MutationFunction<
  UpdateScheduleTimeOffMutation,
  UpdateScheduleTimeOffMutationVariables
>;

/**
 * __useUpdateScheduleTimeOffMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleTimeOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleTimeOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleTimeOffMutation, { data, loading, error }] = useUpdateScheduleTimeOffMutation({
 *   variables: {
 *      timeOff: // value for 'timeOff'
 *   },
 * });
 */
export function useUpdateScheduleTimeOffMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateScheduleTimeOffMutation, UpdateScheduleTimeOffMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateScheduleTimeOffMutation, UpdateScheduleTimeOffMutationVariables>(
    UpdateScheduleTimeOffDocument,
    options
  );
}
export type UpdateScheduleTimeOffMutationHookResult = ReturnType<typeof useUpdateScheduleTimeOffMutation>;
export type UpdateScheduleTimeOffMutationResult = Apollo.MutationResult<UpdateScheduleTimeOffMutation>;
export type UpdateScheduleTimeOffMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduleTimeOffMutation,
  UpdateScheduleTimeOffMutationVariables
>;
export const CreateRecurringScheduleDocument = gql`
  mutation CreateRecurringSchedule($recurringScheduleReminder: RecurringScheduleReminderCreateInput) {
    createRecurringScheduleReminder(recurringScheduleReminder: $recurringScheduleReminder) {
      ...RecurringScheduleFields
    }
  }
  ${RecurringScheduleFieldsFragmentDoc}
`;
export type CreateRecurringScheduleMutationFn = Apollo.MutationFunction<
  CreateRecurringScheduleMutation,
  CreateRecurringScheduleMutationVariables
>;

/**
 * __useCreateRecurringScheduleMutation__
 *
 * To run a mutation, you first call `useCreateRecurringScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringScheduleMutation, { data, loading, error }] = useCreateRecurringScheduleMutation({
 *   variables: {
 *      recurringScheduleReminder: // value for 'recurringScheduleReminder'
 *   },
 * });
 */
export function useCreateRecurringScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRecurringScheduleMutation, CreateRecurringScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRecurringScheduleMutation, CreateRecurringScheduleMutationVariables>(
    CreateRecurringScheduleDocument,
    options
  );
}
export type CreateRecurringScheduleMutationHookResult = ReturnType<typeof useCreateRecurringScheduleMutation>;
export type CreateRecurringScheduleMutationResult = Apollo.MutationResult<CreateRecurringScheduleMutation>;
export type CreateRecurringScheduleMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringScheduleMutation,
  CreateRecurringScheduleMutationVariables
>;
export const UpdateRecurringScheduleDocument = gql`
  mutation UpdateRecurringSchedule($recurringScheduleReminder: RecurringScheduleReminderUpdateInput) {
    updateRecurringScheduleReminder(recurringScheduleReminder: $recurringScheduleReminder) {
      ...RecurringScheduleFields
    }
  }
  ${RecurringScheduleFieldsFragmentDoc}
`;
export type UpdateRecurringScheduleMutationFn = Apollo.MutationFunction<
  UpdateRecurringScheduleMutation,
  UpdateRecurringScheduleMutationVariables
>;

/**
 * __useUpdateRecurringScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringScheduleMutation, { data, loading, error }] = useUpdateRecurringScheduleMutation({
 *   variables: {
 *      recurringScheduleReminder: // value for 'recurringScheduleReminder'
 *   },
 * });
 */
export function useUpdateRecurringScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRecurringScheduleMutation, UpdateRecurringScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRecurringScheduleMutation, UpdateRecurringScheduleMutationVariables>(
    UpdateRecurringScheduleDocument,
    options
  );
}
export type UpdateRecurringScheduleMutationHookResult = ReturnType<typeof useUpdateRecurringScheduleMutation>;
export type UpdateRecurringScheduleMutationResult = Apollo.MutationResult<UpdateRecurringScheduleMutation>;
export type UpdateRecurringScheduleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringScheduleMutation,
  UpdateRecurringScheduleMutationVariables
>;
export const CreateRecurringScheduleLinkDocument = gql`
  mutation CreateRecurringScheduleLink($recurringScheduleReminderLink: RecurringScheduleReminderLinkCreateInput) {
    createRecurringScheduleReminderLink(recurringScheduleReminderLink: $recurringScheduleReminderLink) {
      ...RecurringScheduleLinkFields
    }
  }
  ${RecurringScheduleLinkFieldsFragmentDoc}
`;
export type CreateRecurringScheduleLinkMutationFn = Apollo.MutationFunction<
  CreateRecurringScheduleLinkMutation,
  CreateRecurringScheduleLinkMutationVariables
>;

/**
 * __useCreateRecurringScheduleLinkMutation__
 *
 * To run a mutation, you first call `useCreateRecurringScheduleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringScheduleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringScheduleLinkMutation, { data, loading, error }] = useCreateRecurringScheduleLinkMutation({
 *   variables: {
 *      recurringScheduleReminderLink: // value for 'recurringScheduleReminderLink'
 *   },
 * });
 */
export function useCreateRecurringScheduleLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecurringScheduleLinkMutation,
    CreateRecurringScheduleLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRecurringScheduleLinkMutation, CreateRecurringScheduleLinkMutationVariables>(
    CreateRecurringScheduleLinkDocument,
    options
  );
}
export type CreateRecurringScheduleLinkMutationHookResult = ReturnType<typeof useCreateRecurringScheduleLinkMutation>;
export type CreateRecurringScheduleLinkMutationResult = Apollo.MutationResult<CreateRecurringScheduleLinkMutation>;
export type CreateRecurringScheduleLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateRecurringScheduleLinkMutation,
  CreateRecurringScheduleLinkMutationVariables
>;
export const UpdateRecurringScheduleLinkDocument = gql`
  mutation UpdateRecurringScheduleLink($recurringScheduleReminderLink: RecurringScheduleReminderLinkUpdateInput) {
    updateRecurringScheduleReminderLink(recurringScheduleReminderLink: $recurringScheduleReminderLink) {
      ...RecurringScheduleLinkFields
    }
  }
  ${RecurringScheduleLinkFieldsFragmentDoc}
`;
export type UpdateRecurringScheduleLinkMutationFn = Apollo.MutationFunction<
  UpdateRecurringScheduleLinkMutation,
  UpdateRecurringScheduleLinkMutationVariables
>;

/**
 * __useUpdateRecurringScheduleLinkMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringScheduleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringScheduleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringScheduleLinkMutation, { data, loading, error }] = useUpdateRecurringScheduleLinkMutation({
 *   variables: {
 *      recurringScheduleReminderLink: // value for 'recurringScheduleReminderLink'
 *   },
 * });
 */
export function useUpdateRecurringScheduleLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRecurringScheduleLinkMutation,
    UpdateRecurringScheduleLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRecurringScheduleLinkMutation, UpdateRecurringScheduleLinkMutationVariables>(
    UpdateRecurringScheduleLinkDocument,
    options
  );
}
export type UpdateRecurringScheduleLinkMutationHookResult = ReturnType<typeof useUpdateRecurringScheduleLinkMutation>;
export type UpdateRecurringScheduleLinkMutationResult = Apollo.MutationResult<UpdateRecurringScheduleLinkMutation>;
export type UpdateRecurringScheduleLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecurringScheduleLinkMutation,
  UpdateRecurringScheduleLinkMutationVariables
>;
export const ScheduleTimeOffDocument = gql`
  query ScheduleTimeOff($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      ...SchedulingTimeOffFields
      cursor
    }
  }
  ${SchedulingTimeOffFieldsFragmentDoc}
`;

/**
 * __useScheduleTimeOffQuery__
 *
 * To run a query within a React component, call `useScheduleTimeOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTimeOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTimeOffQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useScheduleTimeOffQuery(
  baseOptions?: Apollo.QueryHookOptions<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>(ScheduleTimeOffDocument, options);
}
export function useScheduleTimeOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>(ScheduleTimeOffDocument, options);
}
export function useScheduleTimeOffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>(ScheduleTimeOffDocument, options);
}
export type ScheduleTimeOffQueryHookResult = ReturnType<typeof useScheduleTimeOffQuery>;
export type ScheduleTimeOffLazyQueryHookResult = ReturnType<typeof useScheduleTimeOffLazyQuery>;
export type ScheduleTimeOffSuspenseQueryHookResult = ReturnType<typeof useScheduleTimeOffSuspenseQuery>;
export type ScheduleTimeOffQueryResult = Apollo.QueryResult<ScheduleTimeOffQuery, ScheduleTimeOffQueryVariables>;
export const SchedulingRecurringScheduleReminderDocument = gql`
  query SchedulingRecurringScheduleReminder(
    $filter: RecurringScheduleReminderFilter
    $sort: [RecurringScheduleReminderSort!]
    $first: Int
    $after: String
  ) {
    recurringScheduleReminders(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      cursor
      ...RecurringScheduleFields
    }
  }
  ${RecurringScheduleFieldsFragmentDoc}
`;

/**
 * __useSchedulingRecurringScheduleReminderQuery__
 *
 * To run a query within a React component, call `useSchedulingRecurringScheduleReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingRecurringScheduleReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingRecurringScheduleReminderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSchedulingRecurringScheduleReminderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchedulingRecurringScheduleReminderQuery,
    SchedulingRecurringScheduleReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchedulingRecurringScheduleReminderQuery, SchedulingRecurringScheduleReminderQueryVariables>(
    SchedulingRecurringScheduleReminderDocument,
    options
  );
}
export function useSchedulingRecurringScheduleReminderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingRecurringScheduleReminderQuery,
    SchedulingRecurringScheduleReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SchedulingRecurringScheduleReminderQuery,
    SchedulingRecurringScheduleReminderQueryVariables
  >(SchedulingRecurringScheduleReminderDocument, options);
}
export function useSchedulingRecurringScheduleReminderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchedulingRecurringScheduleReminderQuery,
    SchedulingRecurringScheduleReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SchedulingRecurringScheduleReminderQuery,
    SchedulingRecurringScheduleReminderQueryVariables
  >(SchedulingRecurringScheduleReminderDocument, options);
}
export type SchedulingRecurringScheduleReminderQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleReminderQuery
>;
export type SchedulingRecurringScheduleReminderLazyQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleReminderLazyQuery
>;
export type SchedulingRecurringScheduleReminderSuspenseQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleReminderSuspenseQuery
>;
export type SchedulingRecurringScheduleReminderQueryResult = Apollo.QueryResult<
  SchedulingRecurringScheduleReminderQuery,
  SchedulingRecurringScheduleReminderQueryVariables
>;
export const SchedulingReminderIdAndLinkIdDocument = gql`
  query SchedulingReminderIdAndLinkId($filter: ScheduleReminderFilter, $first: Int, $after: String) {
    scheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      links {
        id
      }
      cursor
    }
  }
`;

/**
 * __useSchedulingReminderIdAndLinkIdQuery__
 *
 * To run a query within a React component, call `useSchedulingReminderIdAndLinkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingReminderIdAndLinkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingReminderIdAndLinkIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSchedulingReminderIdAndLinkIdQuery(
  baseOptions?: Apollo.QueryHookOptions<SchedulingReminderIdAndLinkIdQuery, SchedulingReminderIdAndLinkIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchedulingReminderIdAndLinkIdQuery, SchedulingReminderIdAndLinkIdQueryVariables>(
    SchedulingReminderIdAndLinkIdDocument,
    options
  );
}
export function useSchedulingReminderIdAndLinkIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingReminderIdAndLinkIdQuery,
    SchedulingReminderIdAndLinkIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchedulingReminderIdAndLinkIdQuery, SchedulingReminderIdAndLinkIdQueryVariables>(
    SchedulingReminderIdAndLinkIdDocument,
    options
  );
}
export function useSchedulingReminderIdAndLinkIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchedulingReminderIdAndLinkIdQuery,
    SchedulingReminderIdAndLinkIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SchedulingReminderIdAndLinkIdQuery, SchedulingReminderIdAndLinkIdQueryVariables>(
    SchedulingReminderIdAndLinkIdDocument,
    options
  );
}
export type SchedulingReminderIdAndLinkIdQueryHookResult = ReturnType<typeof useSchedulingReminderIdAndLinkIdQuery>;
export type SchedulingReminderIdAndLinkIdLazyQueryHookResult = ReturnType<
  typeof useSchedulingReminderIdAndLinkIdLazyQuery
>;
export type SchedulingReminderIdAndLinkIdSuspenseQueryHookResult = ReturnType<
  typeof useSchedulingReminderIdAndLinkIdSuspenseQuery
>;
export type SchedulingReminderIdAndLinkIdQueryResult = Apollo.QueryResult<
  SchedulingReminderIdAndLinkIdQuery,
  SchedulingReminderIdAndLinkIdQueryVariables
>;
export const SchedulingReminderOneOffDocument = gql`
  query SchedulingReminderOneOff($filter: ScheduleReminderFilter, $first: Int, $after: String) {
    scheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      type
      startTime
      links {
        id
      }
      cursor
    }
  }
`;

/**
 * __useSchedulingReminderOneOffQuery__
 *
 * To run a query within a React component, call `useSchedulingReminderOneOffQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingReminderOneOffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingReminderOneOffQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSchedulingReminderOneOffQuery(
  baseOptions?: Apollo.QueryHookOptions<SchedulingReminderOneOffQuery, SchedulingReminderOneOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchedulingReminderOneOffQuery, SchedulingReminderOneOffQueryVariables>(
    SchedulingReminderOneOffDocument,
    options
  );
}
export function useSchedulingReminderOneOffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SchedulingReminderOneOffQuery, SchedulingReminderOneOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchedulingReminderOneOffQuery, SchedulingReminderOneOffQueryVariables>(
    SchedulingReminderOneOffDocument,
    options
  );
}
export function useSchedulingReminderOneOffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SchedulingReminderOneOffQuery, SchedulingReminderOneOffQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SchedulingReminderOneOffQuery, SchedulingReminderOneOffQueryVariables>(
    SchedulingReminderOneOffDocument,
    options
  );
}
export type SchedulingReminderOneOffQueryHookResult = ReturnType<typeof useSchedulingReminderOneOffQuery>;
export type SchedulingReminderOneOffLazyQueryHookResult = ReturnType<typeof useSchedulingReminderOneOffLazyQuery>;
export type SchedulingReminderOneOffSuspenseQueryHookResult = ReturnType<
  typeof useSchedulingReminderOneOffSuspenseQuery
>;
export type SchedulingReminderOneOffQueryResult = Apollo.QueryResult<
  SchedulingReminderOneOffQuery,
  SchedulingReminderOneOffQueryVariables
>;
export const SchedulingRecurringScheduleReminderIdAndLinkIdDocument = gql`
  query SchedulingRecurringScheduleReminderIdAndLinkId(
    $filter: RecurringScheduleReminderFilter
    $first: Int
    $after: String
  ) {
    recurringScheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      links {
        id
      }
      cursor
    }
  }
`;

/**
 * __useSchedulingRecurringScheduleReminderIdAndLinkIdQuery__
 *
 * To run a query within a React component, call `useSchedulingRecurringScheduleReminderIdAndLinkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingRecurringScheduleReminderIdAndLinkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingRecurringScheduleReminderIdAndLinkIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSchedulingRecurringScheduleReminderIdAndLinkIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
    SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
    SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
  >(SchedulingRecurringScheduleReminderIdAndLinkIdDocument, options);
}
export function useSchedulingRecurringScheduleReminderIdAndLinkIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
    SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
    SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
  >(SchedulingRecurringScheduleReminderIdAndLinkIdDocument, options);
}
export function useSchedulingRecurringScheduleReminderIdAndLinkIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
    SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
    SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
  >(SchedulingRecurringScheduleReminderIdAndLinkIdDocument, options);
}
export type SchedulingRecurringScheduleReminderIdAndLinkIdQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleReminderIdAndLinkIdQuery
>;
export type SchedulingRecurringScheduleReminderIdAndLinkIdLazyQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleReminderIdAndLinkIdLazyQuery
>;
export type SchedulingRecurringScheduleReminderIdAndLinkIdSuspenseQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleReminderIdAndLinkIdSuspenseQuery
>;
export type SchedulingRecurringScheduleReminderIdAndLinkIdQueryResult = Apollo.QueryResult<
  SchedulingRecurringScheduleReminderIdAndLinkIdQuery,
  SchedulingRecurringScheduleReminderIdAndLinkIdQueryVariables
>;
export const SchedulingRecurringScheduleBasicReminderDocument = gql`
  query SchedulingRecurringScheduleBasicReminder(
    $filter: RecurringScheduleReminderFilter
    $first: Int
    $after: String
  ) {
    recurringScheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      ...RecurringScheduleFlatFields
      cursor
      startTime
      links {
        id
      }
    }
  }
  ${RecurringScheduleFlatFieldsFragmentDoc}
`;

/**
 * __useSchedulingRecurringScheduleBasicReminderQuery__
 *
 * To run a query within a React component, call `useSchedulingRecurringScheduleBasicReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingRecurringScheduleBasicReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingRecurringScheduleBasicReminderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSchedulingRecurringScheduleBasicReminderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchedulingRecurringScheduleBasicReminderQuery,
    SchedulingRecurringScheduleBasicReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SchedulingRecurringScheduleBasicReminderQuery,
    SchedulingRecurringScheduleBasicReminderQueryVariables
  >(SchedulingRecurringScheduleBasicReminderDocument, options);
}
export function useSchedulingRecurringScheduleBasicReminderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingRecurringScheduleBasicReminderQuery,
    SchedulingRecurringScheduleBasicReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SchedulingRecurringScheduleBasicReminderQuery,
    SchedulingRecurringScheduleBasicReminderQueryVariables
  >(SchedulingRecurringScheduleBasicReminderDocument, options);
}
export function useSchedulingRecurringScheduleBasicReminderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SchedulingRecurringScheduleBasicReminderQuery,
    SchedulingRecurringScheduleBasicReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SchedulingRecurringScheduleBasicReminderQuery,
    SchedulingRecurringScheduleBasicReminderQueryVariables
  >(SchedulingRecurringScheduleBasicReminderDocument, options);
}
export type SchedulingRecurringScheduleBasicReminderQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleBasicReminderQuery
>;
export type SchedulingRecurringScheduleBasicReminderLazyQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleBasicReminderLazyQuery
>;
export type SchedulingRecurringScheduleBasicReminderSuspenseQueryHookResult = ReturnType<
  typeof useSchedulingRecurringScheduleBasicReminderSuspenseQuery
>;
export type SchedulingRecurringScheduleBasicReminderQueryResult = Apollo.QueryResult<
  SchedulingRecurringScheduleBasicReminderQuery,
  SchedulingRecurringScheduleBasicReminderQueryVariables
>;
export const MembersGridViewDocument = gql`
  query MembersGridView($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      ...SchedulingScheduledMember
      cursor
    }
  }
  ${SchedulingScheduledMemberFragmentDoc}
`;

/**
 * __useMembersGridViewQuery__
 *
 * To run a query within a React component, call `useMembersGridViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersGridViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersGridViewQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMembersGridViewQuery(
  baseOptions?: Apollo.QueryHookOptions<MembersGridViewQuery, MembersGridViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MembersGridViewQuery, MembersGridViewQueryVariables>(MembersGridViewDocument, options);
}
export function useMembersGridViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MembersGridViewQuery, MembersGridViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MembersGridViewQuery, MembersGridViewQueryVariables>(MembersGridViewDocument, options);
}
export function useMembersGridViewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MembersGridViewQuery, MembersGridViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MembersGridViewQuery, MembersGridViewQueryVariables>(MembersGridViewDocument, options);
}
export type MembersGridViewQueryHookResult = ReturnType<typeof useMembersGridViewQuery>;
export type MembersGridViewLazyQueryHookResult = ReturnType<typeof useMembersGridViewLazyQuery>;
export type MembersGridViewSuspenseQueryHookResult = ReturnType<typeof useMembersGridViewSuspenseQuery>;
export type MembersGridViewQueryResult = Apollo.QueryResult<MembersGridViewQuery, MembersGridViewQueryVariables>;
export const WeeklyAttendanceReportMemberBreakdownTimeEntryDocument = gql`
  query WeeklyAttendanceReportMemberBreakdownTimeEntry(
    $after: String
    $filter: TimeEntryFilter
    $first: Int
    $sort: [TimeEntrySort!]
  ) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      startTime
      endTime
      daylightSavingTime
      metaDaylightSavingTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
      hasLocation
      flaggedTimeEntryLocation {
        id
        status
        deletedOn
      }
      project {
        id
        ...ProjectDisplay
      }
      cursor
    }
  }
  ${ProjectDisplayFragmentDoc}
`;

/**
 * __useWeeklyAttendanceReportMemberBreakdownTimeEntryQuery__
 *
 * To run a query within a React component, call `useWeeklyAttendanceReportMemberBreakdownTimeEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyAttendanceReportMemberBreakdownTimeEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyAttendanceReportMemberBreakdownTimeEntryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useWeeklyAttendanceReportMemberBreakdownTimeEntryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
    WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
    WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
  >(WeeklyAttendanceReportMemberBreakdownTimeEntryDocument, options);
}
export function useWeeklyAttendanceReportMemberBreakdownTimeEntryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
    WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
    WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
  >(WeeklyAttendanceReportMemberBreakdownTimeEntryDocument, options);
}
export function useWeeklyAttendanceReportMemberBreakdownTimeEntrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
    WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
    WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
  >(WeeklyAttendanceReportMemberBreakdownTimeEntryDocument, options);
}
export type WeeklyAttendanceReportMemberBreakdownTimeEntryQueryHookResult = ReturnType<
  typeof useWeeklyAttendanceReportMemberBreakdownTimeEntryQuery
>;
export type WeeklyAttendanceReportMemberBreakdownTimeEntryLazyQueryHookResult = ReturnType<
  typeof useWeeklyAttendanceReportMemberBreakdownTimeEntryLazyQuery
>;
export type WeeklyAttendanceReportMemberBreakdownTimeEntrySuspenseQueryHookResult = ReturnType<
  typeof useWeeklyAttendanceReportMemberBreakdownTimeEntrySuspenseQuery
>;
export type WeeklyAttendanceReportMemberBreakdownTimeEntryQueryResult = Apollo.QueryResult<
  WeeklyAttendanceReportMemberBreakdownTimeEntryQuery,
  WeeklyAttendanceReportMemberBreakdownTimeEntryQueryVariables
>;
export const OwnerMemberDocument = gql`
  query OwnerMember($first: Int, $filter: MemberFilter) {
    members(first: $first, filter: $filter) {
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useOwnerMemberQuery__
 *
 * To run a query within a React component, call `useOwnerMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerMemberQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOwnerMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<OwnerMemberQuery, OwnerMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerMemberQuery, OwnerMemberQueryVariables>(OwnerMemberDocument, options);
}
export function useOwnerMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerMemberQuery, OwnerMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerMemberQuery, OwnerMemberQueryVariables>(OwnerMemberDocument, options);
}
export function useOwnerMemberSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OwnerMemberQuery, OwnerMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OwnerMemberQuery, OwnerMemberQueryVariables>(OwnerMemberDocument, options);
}
export type OwnerMemberQueryHookResult = ReturnType<typeof useOwnerMemberQuery>;
export type OwnerMemberLazyQueryHookResult = ReturnType<typeof useOwnerMemberLazyQuery>;
export type OwnerMemberSuspenseQueryHookResult = ReturnType<typeof useOwnerMemberSuspenseQuery>;
export type OwnerMemberQueryResult = Apollo.QueryResult<OwnerMemberQuery, OwnerMemberQueryVariables>;
export const ActiveMembersDocument = gql`
  query ActiveMembers($after: String, $filter: MemberFilter) {
    members(after: $after, filter: $filter) {
      id
      cursor
    }
  }
`;

/**
 * __useActiveMembersQuery__
 *
 * To run a query within a React component, call `useActiveMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMembersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useActiveMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveMembersQuery, ActiveMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveMembersQuery, ActiveMembersQueryVariables>(ActiveMembersDocument, options);
}
export function useActiveMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveMembersQuery, ActiveMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveMembersQuery, ActiveMembersQueryVariables>(ActiveMembersDocument, options);
}
export function useActiveMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ActiveMembersQuery, ActiveMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ActiveMembersQuery, ActiveMembersQueryVariables>(ActiveMembersDocument, options);
}
export type ActiveMembersQueryHookResult = ReturnType<typeof useActiveMembersQuery>;
export type ActiveMembersLazyQueryHookResult = ReturnType<typeof useActiveMembersLazyQuery>;
export type ActiveMembersSuspenseQueryHookResult = ReturnType<typeof useActiveMembersSuspenseQuery>;
export type ActiveMembersQueryResult = Apollo.QueryResult<ActiveMembersQuery, ActiveMembersQueryVariables>;
export const MembersAdminsDocument = gql`
  query MembersAdmins($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter, sort: [{ firstName: asc }, { lastName: asc }]) {
      id
      cursor
      firstName
      lastName
      verifiedEmail
      certifiedEmail
    }
  }
`;

/**
 * __useMembersAdminsQuery__
 *
 * To run a query within a React component, call `useMembersAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersAdminsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMembersAdminsQuery(
  baseOptions?: Apollo.QueryHookOptions<MembersAdminsQuery, MembersAdminsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MembersAdminsQuery, MembersAdminsQueryVariables>(MembersAdminsDocument, options);
}
export function useMembersAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MembersAdminsQuery, MembersAdminsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MembersAdminsQuery, MembersAdminsQueryVariables>(MembersAdminsDocument, options);
}
export function useMembersAdminsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MembersAdminsQuery, MembersAdminsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MembersAdminsQuery, MembersAdminsQueryVariables>(MembersAdminsDocument, options);
}
export type MembersAdminsQueryHookResult = ReturnType<typeof useMembersAdminsQuery>;
export type MembersAdminsLazyQueryHookResult = ReturnType<typeof useMembersAdminsLazyQuery>;
export type MembersAdminsSuspenseQueryHookResult = ReturnType<typeof useMembersAdminsSuspenseQuery>;
export type MembersAdminsQueryResult = Apollo.QueryResult<MembersAdminsQuery, MembersAdminsQueryVariables>;
export const LogFeedTimeEntryLogsDocument = gql`
  query LogFeedTimeEntryLogs($after: String, $filter: ClientTimeEntryLogFilter, $first: Int) {
    clientTimeEntryLogs(after: $after, filter: $filter, first: $first, sort: [{ createdOn: desc }]) {
      id
      cursor
      createdOn
      actionType
      deviceType
      startLocationId
      endLocationId
      startTime
      daylightSavingTime
      endTime
      metaDaylightSavingTime
      projectId
      costCodeId
      equipmentId
      description
      originalTimeEntryId
      timeEntryId
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        position {
          id
          level
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
      project {
        id
        title
        projectGroupId
        depth
        ancestors {
          id
          title
          depth
          archivedOn
        }
      }
      costCode {
        id
        costCode
        costCodeGroupId
        title
      }
      equipment {
        id
        equipmentName
        model {
          id
          title
          equipmentCategoryId
          make {
            id
            title
          }
          category {
            id
            title
          }
        }
      }
      timeEntry {
        startTime
        startDeviceTime
        endTime
        endDeviceTime
        daylightSavingTime
        metaDaylightSavingTime
        actionType
        createdOn
        deletedOn
        submittedOn
        updatedOn
        startLocation {
          id
        }
        endLocation {
          id
        }
        breaks {
          id
          clientLogs {
            id
            timeEntryBreakId
            startTime
            endTime
            actionType
            deviceType
            createdOn
            startLocationId
            endLocationId
            clientTimeEntryLogId
          }
          startTime
          startDeviceTime
          startTimeTrusted
          startTimeDst
          endTime
          endDeviceTime
          endTimeTrusted
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
          startLocation {
            id
          }
          endLocation {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useLogFeedTimeEntryLogsQuery__
 *
 * To run a query within a React component, call `useLogFeedTimeEntryLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogFeedTimeEntryLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogFeedTimeEntryLogsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useLogFeedTimeEntryLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>(
    LogFeedTimeEntryLogsDocument,
    options
  );
}
export function useLogFeedTimeEntryLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>(
    LogFeedTimeEntryLogsDocument,
    options
  );
}
export function useLogFeedTimeEntryLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>(
    LogFeedTimeEntryLogsDocument,
    options
  );
}
export type LogFeedTimeEntryLogsQueryHookResult = ReturnType<typeof useLogFeedTimeEntryLogsQuery>;
export type LogFeedTimeEntryLogsLazyQueryHookResult = ReturnType<typeof useLogFeedTimeEntryLogsLazyQuery>;
export type LogFeedTimeEntryLogsSuspenseQueryHookResult = ReturnType<typeof useLogFeedTimeEntryLogsSuspenseQuery>;
export type LogFeedTimeEntryLogsQueryResult = Apollo.QueryResult<
  LogFeedTimeEntryLogsQuery,
  LogFeedTimeEntryLogsQueryVariables
>;
export const UnjoinedMemberLocationsDocument = gql`
  query UnjoinedMemberLocations(
    $filter: MemberLocationFilter
    $sort: [MemberLocationSort!]
    $first: Int
    $after: String
  ) {
    memberLocations(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      memberId
      longitude
      latitude
      horizontalAccuracy
      altitude
      verticalAccuracy
      timeGmt
      timeLocal
      timeDst
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;

/**
 * __useUnjoinedMemberLocationsQuery__
 *
 * To run a query within a React component, call `useUnjoinedMemberLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnjoinedMemberLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnjoinedMemberLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUnjoinedMemberLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<UnjoinedMemberLocationsQuery, UnjoinedMemberLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnjoinedMemberLocationsQuery, UnjoinedMemberLocationsQueryVariables>(
    UnjoinedMemberLocationsDocument,
    options
  );
}
export function useUnjoinedMemberLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnjoinedMemberLocationsQuery, UnjoinedMemberLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnjoinedMemberLocationsQuery, UnjoinedMemberLocationsQueryVariables>(
    UnjoinedMemberLocationsDocument,
    options
  );
}
export function useUnjoinedMemberLocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UnjoinedMemberLocationsQuery, UnjoinedMemberLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UnjoinedMemberLocationsQuery, UnjoinedMemberLocationsQueryVariables>(
    UnjoinedMemberLocationsDocument,
    options
  );
}
export type UnjoinedMemberLocationsQueryHookResult = ReturnType<typeof useUnjoinedMemberLocationsQuery>;
export type UnjoinedMemberLocationsLazyQueryHookResult = ReturnType<typeof useUnjoinedMemberLocationsLazyQuery>;
export type UnjoinedMemberLocationsSuspenseQueryHookResult = ReturnType<typeof useUnjoinedMemberLocationsSuspenseQuery>;
export type UnjoinedMemberLocationsQueryResult = Apollo.QueryResult<
  UnjoinedMemberLocationsQuery,
  UnjoinedMemberLocationsQueryVariables
>;
export const QueryCostCodeTimeMetricsDocument = gql`
  query QueryCostCodeTimeMetrics(
    $first: Int
    $after: String
    $filter: CostCodeFilter
    $sort: [CostCodeSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    costCodes(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      costCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodeTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodeTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodeTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodeTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryCostCodeTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryCostCodeTimeMetricsQuery, QueryCostCodeTimeMetricsQueryVariables> &
    ({ variables: QueryCostCodeTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodeTimeMetricsQuery, QueryCostCodeTimeMetricsQueryVariables>(
    QueryCostCodeTimeMetricsDocument,
    options
  );
}
export function useQueryCostCodeTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodeTimeMetricsQuery, QueryCostCodeTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodeTimeMetricsQuery, QueryCostCodeTimeMetricsQueryVariables>(
    QueryCostCodeTimeMetricsDocument,
    options
  );
}
export function useQueryCostCodeTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodeTimeMetricsQuery, QueryCostCodeTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodeTimeMetricsQuery, QueryCostCodeTimeMetricsQueryVariables>(
    QueryCostCodeTimeMetricsDocument,
    options
  );
}
export type QueryCostCodeTimeMetricsQueryHookResult = ReturnType<typeof useQueryCostCodeTimeMetricsQuery>;
export type QueryCostCodeTimeMetricsLazyQueryHookResult = ReturnType<typeof useQueryCostCodeTimeMetricsLazyQuery>;
export type QueryCostCodeTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodeTimeMetricsSuspenseQuery
>;
export type QueryCostCodeTimeMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodeTimeMetricsQuery,
  QueryCostCodeTimeMetricsQueryVariables
>;
export const QueryCostCodeLaborMetricsDocument = gql`
  query QueryCostCodeLaborMetrics(
    $first: Int
    $after: String
    $filter: CostCodeFilter
    $sort: [CostCodeSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    costCodes(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      costCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        costCodeId
        ...LaborMetrics
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryCostCodeLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryCostCodeLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCostCodeLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCostCodeLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryCostCodeLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryCostCodeLaborMetricsQuery, QueryCostCodeLaborMetricsQueryVariables> &
    ({ variables: QueryCostCodeLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryCostCodeLaborMetricsQuery, QueryCostCodeLaborMetricsQueryVariables>(
    QueryCostCodeLaborMetricsDocument,
    options
  );
}
export function useQueryCostCodeLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCostCodeLaborMetricsQuery, QueryCostCodeLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryCostCodeLaborMetricsQuery, QueryCostCodeLaborMetricsQueryVariables>(
    QueryCostCodeLaborMetricsDocument,
    options
  );
}
export function useQueryCostCodeLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryCostCodeLaborMetricsQuery, QueryCostCodeLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryCostCodeLaborMetricsQuery, QueryCostCodeLaborMetricsQueryVariables>(
    QueryCostCodeLaborMetricsDocument,
    options
  );
}
export type QueryCostCodeLaborMetricsQueryHookResult = ReturnType<typeof useQueryCostCodeLaborMetricsQuery>;
export type QueryCostCodeLaborMetricsLazyQueryHookResult = ReturnType<typeof useQueryCostCodeLaborMetricsLazyQuery>;
export type QueryCostCodeLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryCostCodeLaborMetricsSuspenseQuery
>;
export type QueryCostCodeLaborMetricsQueryResult = Apollo.QueryResult<
  QueryCostCodeLaborMetricsQuery,
  QueryCostCodeLaborMetricsQueryVariables
>;
export const QueryEquipmentTimeMetricsDocument = gql`
  query QueryEquipmentTimeMetrics(
    $first: Int
    $after: String
    $filter: EquipmentFilter
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      equipmentLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryEquipmentTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryEquipmentTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEquipmentTimeMetricsQuery, QueryEquipmentTimeMetricsQueryVariables> &
    ({ variables: QueryEquipmentTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentTimeMetricsQuery, QueryEquipmentTimeMetricsQueryVariables>(
    QueryEquipmentTimeMetricsDocument,
    options
  );
}
export function useQueryEquipmentTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentTimeMetricsQuery, QueryEquipmentTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentTimeMetricsQuery, QueryEquipmentTimeMetricsQueryVariables>(
    QueryEquipmentTimeMetricsDocument,
    options
  );
}
export function useQueryEquipmentTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEquipmentTimeMetricsQuery, QueryEquipmentTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentTimeMetricsQuery, QueryEquipmentTimeMetricsQueryVariables>(
    QueryEquipmentTimeMetricsDocument,
    options
  );
}
export type QueryEquipmentTimeMetricsQueryHookResult = ReturnType<typeof useQueryEquipmentTimeMetricsQuery>;
export type QueryEquipmentTimeMetricsLazyQueryHookResult = ReturnType<typeof useQueryEquipmentTimeMetricsLazyQuery>;
export type QueryEquipmentTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryEquipmentTimeMetricsSuspenseQuery
>;
export type QueryEquipmentTimeMetricsQueryResult = Apollo.QueryResult<
  QueryEquipmentTimeMetricsQuery,
  QueryEquipmentTimeMetricsQueryVariables
>;
export const QueryEquipmentLaborMetricsDocument = gql`
  query QueryEquipmentLaborMetrics(
    $first: Int
    $after: String
    $filter: EquipmentFilter
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      equipmentLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...LaborMetrics
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryEquipmentLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryEquipmentLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEquipmentLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEquipmentLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryEquipmentLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEquipmentLaborMetricsQuery, QueryEquipmentLaborMetricsQueryVariables> &
    ({ variables: QueryEquipmentLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryEquipmentLaborMetricsQuery, QueryEquipmentLaborMetricsQueryVariables>(
    QueryEquipmentLaborMetricsDocument,
    options
  );
}
export function useQueryEquipmentLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEquipmentLaborMetricsQuery, QueryEquipmentLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryEquipmentLaborMetricsQuery, QueryEquipmentLaborMetricsQueryVariables>(
    QueryEquipmentLaborMetricsDocument,
    options
  );
}
export function useQueryEquipmentLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryEquipmentLaborMetricsQuery,
    QueryEquipmentLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryEquipmentLaborMetricsQuery, QueryEquipmentLaborMetricsQueryVariables>(
    QueryEquipmentLaborMetricsDocument,
    options
  );
}
export type QueryEquipmentLaborMetricsQueryHookResult = ReturnType<typeof useQueryEquipmentLaborMetricsQuery>;
export type QueryEquipmentLaborMetricsLazyQueryHookResult = ReturnType<typeof useQueryEquipmentLaborMetricsLazyQuery>;
export type QueryEquipmentLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryEquipmentLaborMetricsSuspenseQuery
>;
export type QueryEquipmentLaborMetricsQueryResult = Apollo.QueryResult<
  QueryEquipmentLaborMetricsQuery,
  QueryEquipmentLaborMetricsQueryVariables
>;
export const QueryMembersTimeMetricsDocument = gql`
  query QueryMembersTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        __typename
        ...BasicMemberLaborMetrics
      }
    }
  }
  ${BasicMemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMembersTimeMetricsQuery, QueryMembersTimeMetricsQueryVariables> &
    ({ variables: QueryMembersTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersTimeMetricsQuery, QueryMembersTimeMetricsQueryVariables>(
    QueryMembersTimeMetricsDocument,
    options
  );
}
export function useQueryMembersTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersTimeMetricsQuery, QueryMembersTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersTimeMetricsQuery, QueryMembersTimeMetricsQueryVariables>(
    QueryMembersTimeMetricsDocument,
    options
  );
}
export function useQueryMembersTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersTimeMetricsQuery, QueryMembersTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersTimeMetricsQuery, QueryMembersTimeMetricsQueryVariables>(
    QueryMembersTimeMetricsDocument,
    options
  );
}
export type QueryMembersTimeMetricsQueryHookResult = ReturnType<typeof useQueryMembersTimeMetricsQuery>;
export type QueryMembersTimeMetricsLazyQueryHookResult = ReturnType<typeof useQueryMembersTimeMetricsLazyQuery>;
export type QueryMembersTimeMetricsSuspenseQueryHookResult = ReturnType<typeof useQueryMembersTimeMetricsSuspenseQuery>;
export type QueryMembersTimeMetricsQueryResult = Apollo.QueryResult<
  QueryMembersTimeMetricsQuery,
  QueryMembersTimeMetricsQueryVariables
>;
export const QueryMembersLaborMetricsDocument = gql`
  query QueryMembersLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        __typename
        ...MemberLaborMetrics
      }
    }
  }
  ${MemberLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMembersLaborMetricsQuery, QueryMembersLaborMetricsQueryVariables> &
    ({ variables: QueryMembersLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersLaborMetricsQuery, QueryMembersLaborMetricsQueryVariables>(
    QueryMembersLaborMetricsDocument,
    options
  );
}
export function useQueryMembersLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersLaborMetricsQuery, QueryMembersLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersLaborMetricsQuery, QueryMembersLaborMetricsQueryVariables>(
    QueryMembersLaborMetricsDocument,
    options
  );
}
export function useQueryMembersLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryMembersLaborMetricsQuery, QueryMembersLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersLaborMetricsQuery, QueryMembersLaborMetricsQueryVariables>(
    QueryMembersLaborMetricsDocument,
    options
  );
}
export type QueryMembersLaborMetricsQueryHookResult = ReturnType<typeof useQueryMembersLaborMetricsQuery>;
export type QueryMembersLaborMetricsLazyQueryHookResult = ReturnType<typeof useQueryMembersLaborMetricsLazyQuery>;
export type QueryMembersLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersLaborMetricsSuspenseQuery
>;
export type QueryMembersLaborMetricsQueryResult = Apollo.QueryResult<
  QueryMembersLaborMetricsQuery,
  QueryMembersLaborMetricsQueryVariables
>;
export const QueryMembersProjectTimeMetricsDocument = gql`
  query QueryMembersProjectTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $projectIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
        filter: $metricFilter
      ) {
        __typename
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersProjectTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersProjectTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersProjectTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersProjectTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      projectIds: // value for 'projectIds'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersProjectTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersProjectTimeMetricsQuery,
    QueryMembersProjectTimeMetricsQueryVariables
  > &
    ({ variables: QueryMembersProjectTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersProjectTimeMetricsQuery, QueryMembersProjectTimeMetricsQueryVariables>(
    QueryMembersProjectTimeMetricsDocument,
    options
  );
}
export function useQueryMembersProjectTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersProjectTimeMetricsQuery,
    QueryMembersProjectTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersProjectTimeMetricsQuery, QueryMembersProjectTimeMetricsQueryVariables>(
    QueryMembersProjectTimeMetricsDocument,
    options
  );
}
export function useQueryMembersProjectTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersProjectTimeMetricsQuery,
    QueryMembersProjectTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersProjectTimeMetricsQuery, QueryMembersProjectTimeMetricsQueryVariables>(
    QueryMembersProjectTimeMetricsDocument,
    options
  );
}
export type QueryMembersProjectTimeMetricsQueryHookResult = ReturnType<typeof useQueryMembersProjectTimeMetricsQuery>;
export type QueryMembersProjectTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersProjectTimeMetricsLazyQuery
>;
export type QueryMembersProjectTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersProjectTimeMetricsSuspenseQuery
>;
export type QueryMembersProjectTimeMetricsQueryResult = Apollo.QueryResult<
  QueryMembersProjectTimeMetricsQuery,
  QueryMembersProjectTimeMetricsQueryVariables
>;
export const QueryMembersProjectMetricsDocument = gql`
  query QueryMembersProjectMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $projectIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
        filter: $metricFilter
      ) {
        __typename
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersProjectMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersProjectMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersProjectMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersProjectMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      projectIds: // value for 'projectIds'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersProjectMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMembersProjectMetricsQuery, QueryMembersProjectMetricsQueryVariables> &
    ({ variables: QueryMembersProjectMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersProjectMetricsQuery, QueryMembersProjectMetricsQueryVariables>(
    QueryMembersProjectMetricsDocument,
    options
  );
}
export function useQueryMembersProjectMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersProjectMetricsQuery, QueryMembersProjectMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersProjectMetricsQuery, QueryMembersProjectMetricsQueryVariables>(
    QueryMembersProjectMetricsDocument,
    options
  );
}
export function useQueryMembersProjectMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersProjectMetricsQuery,
    QueryMembersProjectMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersProjectMetricsQuery, QueryMembersProjectMetricsQueryVariables>(
    QueryMembersProjectMetricsDocument,
    options
  );
}
export type QueryMembersProjectMetricsQueryHookResult = ReturnType<typeof useQueryMembersProjectMetricsQuery>;
export type QueryMembersProjectMetricsLazyQueryHookResult = ReturnType<typeof useQueryMembersProjectMetricsLazyQuery>;
export type QueryMembersProjectMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersProjectMetricsSuspenseQuery
>;
export type QueryMembersProjectMetricsQueryResult = Apollo.QueryResult<
  QueryMembersProjectMetricsQuery,
  QueryMembersProjectMetricsQueryVariables
>;
export const QueryMembersCostCodeTimeMetricsDocument = gql`
  query QueryMembersCostCodeTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        __typename
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersCostCodeTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersCostCodeTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersCostCodeTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersCostCodeTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      costCodeIds: // value for 'costCodeIds'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersCostCodeTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersCostCodeTimeMetricsQuery,
    QueryMembersCostCodeTimeMetricsQueryVariables
  > &
    ({ variables: QueryMembersCostCodeTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersCostCodeTimeMetricsQuery, QueryMembersCostCodeTimeMetricsQueryVariables>(
    QueryMembersCostCodeTimeMetricsDocument,
    options
  );
}
export function useQueryMembersCostCodeTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersCostCodeTimeMetricsQuery,
    QueryMembersCostCodeTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersCostCodeTimeMetricsQuery, QueryMembersCostCodeTimeMetricsQueryVariables>(
    QueryMembersCostCodeTimeMetricsDocument,
    options
  );
}
export function useQueryMembersCostCodeTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersCostCodeTimeMetricsQuery,
    QueryMembersCostCodeTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersCostCodeTimeMetricsQuery, QueryMembersCostCodeTimeMetricsQueryVariables>(
    QueryMembersCostCodeTimeMetricsDocument,
    options
  );
}
export type QueryMembersCostCodeTimeMetricsQueryHookResult = ReturnType<typeof useQueryMembersCostCodeTimeMetricsQuery>;
export type QueryMembersCostCodeTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersCostCodeTimeMetricsLazyQuery
>;
export type QueryMembersCostCodeTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersCostCodeTimeMetricsSuspenseQuery
>;
export type QueryMembersCostCodeTimeMetricsQueryResult = Apollo.QueryResult<
  QueryMembersCostCodeTimeMetricsQuery,
  QueryMembersCostCodeTimeMetricsQueryVariables
>;
export const QueryMembersCostCodeMetricsDocument = gql`
  query QueryMembersCostCodeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        __typename
        ...LaborMetrics
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersCostCodeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersCostCodeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersCostCodeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersCostCodeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      costCodeIds: // value for 'costCodeIds'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersCostCodeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMembersCostCodeMetricsQuery, QueryMembersCostCodeMetricsQueryVariables> &
    ({ variables: QueryMembersCostCodeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersCostCodeMetricsQuery, QueryMembersCostCodeMetricsQueryVariables>(
    QueryMembersCostCodeMetricsDocument,
    options
  );
}
export function useQueryMembersCostCodeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryMembersCostCodeMetricsQuery, QueryMembersCostCodeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersCostCodeMetricsQuery, QueryMembersCostCodeMetricsQueryVariables>(
    QueryMembersCostCodeMetricsDocument,
    options
  );
}
export function useQueryMembersCostCodeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersCostCodeMetricsQuery,
    QueryMembersCostCodeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersCostCodeMetricsQuery, QueryMembersCostCodeMetricsQueryVariables>(
    QueryMembersCostCodeMetricsDocument,
    options
  );
}
export type QueryMembersCostCodeMetricsQueryHookResult = ReturnType<typeof useQueryMembersCostCodeMetricsQuery>;
export type QueryMembersCostCodeMetricsLazyQueryHookResult = ReturnType<typeof useQueryMembersCostCodeMetricsLazyQuery>;
export type QueryMembersCostCodeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersCostCodeMetricsSuspenseQuery
>;
export type QueryMembersCostCodeMetricsQueryResult = Apollo.QueryResult<
  QueryMembersCostCodeMetricsQuery,
  QueryMembersCostCodeMetricsQueryVariables
>;
export const QueryMembersEquipmentTimeMetricsDocument = gql`
  query QueryMembersEquipmentTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        __typename
        ...BasicLaborMetrics
      }
    }
  }
  ${BasicLaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersEquipmentTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersEquipmentTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersEquipmentTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersEquipmentTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      equipmentIds: // value for 'equipmentIds'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersEquipmentTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryMembersEquipmentTimeMetricsQuery,
    QueryMembersEquipmentTimeMetricsQueryVariables
  > &
    ({ variables: QueryMembersEquipmentTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersEquipmentTimeMetricsQuery, QueryMembersEquipmentTimeMetricsQueryVariables>(
    QueryMembersEquipmentTimeMetricsDocument,
    options
  );
}
export function useQueryMembersEquipmentTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersEquipmentTimeMetricsQuery,
    QueryMembersEquipmentTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersEquipmentTimeMetricsQuery, QueryMembersEquipmentTimeMetricsQueryVariables>(
    QueryMembersEquipmentTimeMetricsDocument,
    options
  );
}
export function useQueryMembersEquipmentTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersEquipmentTimeMetricsQuery,
    QueryMembersEquipmentTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersEquipmentTimeMetricsQuery, QueryMembersEquipmentTimeMetricsQueryVariables>(
    QueryMembersEquipmentTimeMetricsDocument,
    options
  );
}
export type QueryMembersEquipmentTimeMetricsQueryHookResult = ReturnType<
  typeof useQueryMembersEquipmentTimeMetricsQuery
>;
export type QueryMembersEquipmentTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersEquipmentTimeMetricsLazyQuery
>;
export type QueryMembersEquipmentTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersEquipmentTimeMetricsSuspenseQuery
>;
export type QueryMembersEquipmentTimeMetricsQueryResult = Apollo.QueryResult<
  QueryMembersEquipmentTimeMetricsQuery,
  QueryMembersEquipmentTimeMetricsQueryVariables
>;
export const QueryMembersEquipmentMetricsDocument = gql`
  query QueryMembersEquipmentMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        __typename
        ...LaborMetrics
      }
    }
  }
  ${LaborMetricsFragmentDoc}
`;

/**
 * __useQueryMembersEquipmentMetricsQuery__
 *
 * To run a query within a React component, call `useQueryMembersEquipmentMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMembersEquipmentMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMembersEquipmentMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      equipmentIds: // value for 'equipmentIds'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryMembersEquipmentMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryMembersEquipmentMetricsQuery, QueryMembersEquipmentMetricsQueryVariables> &
    ({ variables: QueryMembersEquipmentMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryMembersEquipmentMetricsQuery, QueryMembersEquipmentMetricsQueryVariables>(
    QueryMembersEquipmentMetricsDocument,
    options
  );
}
export function useQueryMembersEquipmentMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMembersEquipmentMetricsQuery,
    QueryMembersEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryMembersEquipmentMetricsQuery, QueryMembersEquipmentMetricsQueryVariables>(
    QueryMembersEquipmentMetricsDocument,
    options
  );
}
export function useQueryMembersEquipmentMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMembersEquipmentMetricsQuery,
    QueryMembersEquipmentMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryMembersEquipmentMetricsQuery, QueryMembersEquipmentMetricsQueryVariables>(
    QueryMembersEquipmentMetricsDocument,
    options
  );
}
export type QueryMembersEquipmentMetricsQueryHookResult = ReturnType<typeof useQueryMembersEquipmentMetricsQuery>;
export type QueryMembersEquipmentMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryMembersEquipmentMetricsLazyQuery
>;
export type QueryMembersEquipmentMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMembersEquipmentMetricsSuspenseQuery
>;
export type QueryMembersEquipmentMetricsQueryResult = Apollo.QueryResult<
  QueryMembersEquipmentMetricsQuery,
  QueryMembersEquipmentMetricsQueryVariables
>;
export const QueryOrganizationTimeMetricsDocument = gql`
  query QueryOrganizationTimeMetrics(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    organizationLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicOrganizationLaborMetrics
    }
  }
  ${BasicOrganizationLaborMetricsFragmentDoc}
`;

/**
 * __useQueryOrganizationTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryOrganizationTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryOrganizationTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryOrganizationTimeMetricsQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryOrganizationTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryOrganizationTimeMetricsQuery, QueryOrganizationTimeMetricsQueryVariables> &
    ({ variables: QueryOrganizationTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryOrganizationTimeMetricsQuery, QueryOrganizationTimeMetricsQueryVariables>(
    QueryOrganizationTimeMetricsDocument,
    options
  );
}
export function useQueryOrganizationTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryOrganizationTimeMetricsQuery,
    QueryOrganizationTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryOrganizationTimeMetricsQuery, QueryOrganizationTimeMetricsQueryVariables>(
    QueryOrganizationTimeMetricsDocument,
    options
  );
}
export function useQueryOrganizationTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryOrganizationTimeMetricsQuery,
    QueryOrganizationTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryOrganizationTimeMetricsQuery, QueryOrganizationTimeMetricsQueryVariables>(
    QueryOrganizationTimeMetricsDocument,
    options
  );
}
export type QueryOrganizationTimeMetricsQueryHookResult = ReturnType<typeof useQueryOrganizationTimeMetricsQuery>;
export type QueryOrganizationTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryOrganizationTimeMetricsLazyQuery
>;
export type QueryOrganizationTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryOrganizationTimeMetricsSuspenseQuery
>;
export type QueryOrganizationTimeMetricsQueryResult = Apollo.QueryResult<
  QueryOrganizationTimeMetricsQuery,
  QueryOrganizationTimeMetricsQueryVariables
>;
export const QueryOrganizationLaborMetricsDocument = gql`
  query QueryOrganizationLaborMetrics(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    organizationLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...OrganizationLaborMetrics
    }
  }
  ${OrganizationLaborMetricsFragmentDoc}
`;

/**
 * __useQueryOrganizationLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryOrganizationLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryOrganizationLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryOrganizationLaborMetricsQuery({
 *   variables: {
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      metricFilter: // value for 'metricFilter'
 *   },
 * });
 */
export function useQueryOrganizationLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryOrganizationLaborMetricsQuery,
    QueryOrganizationLaborMetricsQueryVariables
  > &
    ({ variables: QueryOrganizationLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryOrganizationLaborMetricsQuery, QueryOrganizationLaborMetricsQueryVariables>(
    QueryOrganizationLaborMetricsDocument,
    options
  );
}
export function useQueryOrganizationLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryOrganizationLaborMetricsQuery,
    QueryOrganizationLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryOrganizationLaborMetricsQuery, QueryOrganizationLaborMetricsQueryVariables>(
    QueryOrganizationLaborMetricsDocument,
    options
  );
}
export function useQueryOrganizationLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryOrganizationLaborMetricsQuery,
    QueryOrganizationLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryOrganizationLaborMetricsQuery, QueryOrganizationLaborMetricsQueryVariables>(
    QueryOrganizationLaborMetricsDocument,
    options
  );
}
export type QueryOrganizationLaborMetricsQueryHookResult = ReturnType<typeof useQueryOrganizationLaborMetricsQuery>;
export type QueryOrganizationLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryOrganizationLaborMetricsLazyQuery
>;
export type QueryOrganizationLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryOrganizationLaborMetricsSuspenseQuery
>;
export type QueryOrganizationLaborMetricsQueryResult = Apollo.QueryResult<
  QueryOrganizationLaborMetricsQuery,
  QueryOrganizationLaborMetricsQueryVariables
>;
export const QueryProjectTimeMetricsDocument = gql`
  query QueryProjectTimeMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    projects(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryProjectTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryProjectTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryProjectTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryProjectTimeMetricsQuery, QueryProjectTimeMetricsQueryVariables> &
    ({ variables: QueryProjectTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectTimeMetricsQuery, QueryProjectTimeMetricsQueryVariables>(
    QueryProjectTimeMetricsDocument,
    options
  );
}
export function useQueryProjectTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryProjectTimeMetricsQuery, QueryProjectTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectTimeMetricsQuery, QueryProjectTimeMetricsQueryVariables>(
    QueryProjectTimeMetricsDocument,
    options
  );
}
export function useQueryProjectTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryProjectTimeMetricsQuery, QueryProjectTimeMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectTimeMetricsQuery, QueryProjectTimeMetricsQueryVariables>(
    QueryProjectTimeMetricsDocument,
    options
  );
}
export type QueryProjectTimeMetricsQueryHookResult = ReturnType<typeof useQueryProjectTimeMetricsQuery>;
export type QueryProjectTimeMetricsLazyQueryHookResult = ReturnType<typeof useQueryProjectTimeMetricsLazyQuery>;
export type QueryProjectTimeMetricsSuspenseQueryHookResult = ReturnType<typeof useQueryProjectTimeMetricsSuspenseQuery>;
export type QueryProjectTimeMetricsQueryResult = Apollo.QueryResult<
  QueryProjectTimeMetricsQuery,
  QueryProjectTimeMetricsQueryVariables
>;
export const QueryProjectLaborMetricsDocument = gql`
  query QueryProjectLaborMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    projects(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        projectId
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryProjectLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryProjectLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *   },
 * });
 */
export function useQueryProjectLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<QueryProjectLaborMetricsQuery, QueryProjectLaborMetricsQueryVariables> &
    ({ variables: QueryProjectLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectLaborMetricsQuery, QueryProjectLaborMetricsQueryVariables>(
    QueryProjectLaborMetricsDocument,
    options
  );
}
export function useQueryProjectLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryProjectLaborMetricsQuery, QueryProjectLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectLaborMetricsQuery, QueryProjectLaborMetricsQueryVariables>(
    QueryProjectLaborMetricsDocument,
    options
  );
}
export function useQueryProjectLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<QueryProjectLaborMetricsQuery, QueryProjectLaborMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryProjectLaborMetricsQuery, QueryProjectLaborMetricsQueryVariables>(
    QueryProjectLaborMetricsDocument,
    options
  );
}
export type QueryProjectLaborMetricsQueryHookResult = ReturnType<typeof useQueryProjectLaborMetricsQuery>;
export type QueryProjectLaborMetricsLazyQueryHookResult = ReturnType<typeof useQueryProjectLaborMetricsLazyQuery>;
export type QueryProjectLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectLaborMetricsSuspenseQuery
>;
export type QueryProjectLaborMetricsQueryResult = Apollo.QueryResult<
  QueryProjectLaborMetricsQuery,
  QueryProjectLaborMetricsQueryVariables
>;
export const QueryProjectWithTimeAndTimeMetricsDocument = gql`
  query QueryProjectWithTimeAndTimeMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
  ) {
    projectsWithTime(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BasicProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryProjectWithTimeAndTimeMetricsQuery__
 *
 * To run a query within a React component, call `useQueryProjectWithTimeAndTimeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectWithTimeAndTimeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectWithTimeAndTimeMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *   },
 * });
 */
export function useQueryProjectWithTimeAndTimeMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryProjectWithTimeAndTimeMetricsQuery,
    QueryProjectWithTimeAndTimeMetricsQueryVariables
  > &
    ({ variables: QueryProjectWithTimeAndTimeMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectWithTimeAndTimeMetricsQuery, QueryProjectWithTimeAndTimeMetricsQueryVariables>(
    QueryProjectWithTimeAndTimeMetricsDocument,
    options
  );
}
export function useQueryProjectWithTimeAndTimeMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProjectWithTimeAndTimeMetricsQuery,
    QueryProjectWithTimeAndTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryProjectWithTimeAndTimeMetricsQuery, QueryProjectWithTimeAndTimeMetricsQueryVariables>(
    QueryProjectWithTimeAndTimeMetricsDocument,
    options
  );
}
export function useQueryProjectWithTimeAndTimeMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectWithTimeAndTimeMetricsQuery,
    QueryProjectWithTimeAndTimeMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryProjectWithTimeAndTimeMetricsQuery,
    QueryProjectWithTimeAndTimeMetricsQueryVariables
  >(QueryProjectWithTimeAndTimeMetricsDocument, options);
}
export type QueryProjectWithTimeAndTimeMetricsQueryHookResult = ReturnType<
  typeof useQueryProjectWithTimeAndTimeMetricsQuery
>;
export type QueryProjectWithTimeAndTimeMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryProjectWithTimeAndTimeMetricsLazyQuery
>;
export type QueryProjectWithTimeAndTimeMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectWithTimeAndTimeMetricsSuspenseQuery
>;
export type QueryProjectWithTimeAndTimeMetricsQueryResult = Apollo.QueryResult<
  QueryProjectWithTimeAndTimeMetricsQuery,
  QueryProjectWithTimeAndTimeMetricsQueryVariables
>;
export const QueryProjectWithTimeAndLaborMetricsDocument = gql`
  query QueryProjectWithTimeAndLaborMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
  ) {
    projectsWithTime(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${ProjectLaborMetricsFragmentDoc}
`;

/**
 * __useQueryProjectWithTimeAndLaborMetricsQuery__
 *
 * To run a query within a React component, call `useQueryProjectWithTimeAndLaborMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryProjectWithTimeAndLaborMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryProjectWithTimeAndLaborMetricsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      metricsInterval: // value for 'metricsInterval'
 *      metricsStartDate: // value for 'metricsStartDate'
 *      metricsEndDate: // value for 'metricsEndDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      includeAncestors: // value for 'includeAncestors'
 *      includeOpenEntry: // value for 'includeOpenEntry'
 *   },
 * });
 */
export function useQueryProjectWithTimeAndLaborMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryProjectWithTimeAndLaborMetricsQuery,
    QueryProjectWithTimeAndLaborMetricsQueryVariables
  > &
    ({ variables: QueryProjectWithTimeAndLaborMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryProjectWithTimeAndLaborMetricsQuery, QueryProjectWithTimeAndLaborMetricsQueryVariables>(
    QueryProjectWithTimeAndLaborMetricsDocument,
    options
  );
}
export function useQueryProjectWithTimeAndLaborMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryProjectWithTimeAndLaborMetricsQuery,
    QueryProjectWithTimeAndLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryProjectWithTimeAndLaborMetricsQuery,
    QueryProjectWithTimeAndLaborMetricsQueryVariables
  >(QueryProjectWithTimeAndLaborMetricsDocument, options);
}
export function useQueryProjectWithTimeAndLaborMetricsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryProjectWithTimeAndLaborMetricsQuery,
    QueryProjectWithTimeAndLaborMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryProjectWithTimeAndLaborMetricsQuery,
    QueryProjectWithTimeAndLaborMetricsQueryVariables
  >(QueryProjectWithTimeAndLaborMetricsDocument, options);
}
export type QueryProjectWithTimeAndLaborMetricsQueryHookResult = ReturnType<
  typeof useQueryProjectWithTimeAndLaborMetricsQuery
>;
export type QueryProjectWithTimeAndLaborMetricsLazyQueryHookResult = ReturnType<
  typeof useQueryProjectWithTimeAndLaborMetricsLazyQuery
>;
export type QueryProjectWithTimeAndLaborMetricsSuspenseQueryHookResult = ReturnType<
  typeof useQueryProjectWithTimeAndLaborMetricsSuspenseQuery
>;
export type QueryProjectWithTimeAndLaborMetricsQueryResult = Apollo.QueryResult<
  QueryProjectWithTimeAndLaborMetricsQuery,
  QueryProjectWithTimeAndLaborMetricsQueryVariables
>;
export const CreateEquipmentCostHistoryDocument = gql`
  mutation CreateEquipmentCostHistory($equipmentCostHistory: EquipmentCostHistoryCreateInput!) {
    createEquipmentCostHistory(equipmentCostHistory: $equipmentCostHistory) {
      id
      machineCostRate
      operatorCostRate
      changeDate
      updatedOn
      deletedOn
    }
  }
`;
export type CreateEquipmentCostHistoryMutationFn = Apollo.MutationFunction<
  CreateEquipmentCostHistoryMutation,
  CreateEquipmentCostHistoryMutationVariables
>;

/**
 * __useCreateEquipmentCostHistoryMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentCostHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentCostHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentCostHistoryMutation, { data, loading, error }] = useCreateEquipmentCostHistoryMutation({
 *   variables: {
 *      equipmentCostHistory: // value for 'equipmentCostHistory'
 *   },
 * });
 */
export function useCreateEquipmentCostHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEquipmentCostHistoryMutation,
    CreateEquipmentCostHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEquipmentCostHistoryMutation, CreateEquipmentCostHistoryMutationVariables>(
    CreateEquipmentCostHistoryDocument,
    options
  );
}
export type CreateEquipmentCostHistoryMutationHookResult = ReturnType<typeof useCreateEquipmentCostHistoryMutation>;
export type CreateEquipmentCostHistoryMutationResult = Apollo.MutationResult<CreateEquipmentCostHistoryMutation>;
export type CreateEquipmentCostHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateEquipmentCostHistoryMutation,
  CreateEquipmentCostHistoryMutationVariables
>;
export const UpdateEquipmentCostHistoryDocument = gql`
  mutation UpdateEquipmentCostHistory($equipmentCostHistory: EquipmentCostHistoryUpdateInput!) {
    updateEquipmentCostHistory(equipmentCostHistory: $equipmentCostHistory) {
      id
      machineCostRate
      operatorCostRate
      changeDate
      updatedOn
      deletedOn
    }
  }
`;
export type UpdateEquipmentCostHistoryMutationFn = Apollo.MutationFunction<
  UpdateEquipmentCostHistoryMutation,
  UpdateEquipmentCostHistoryMutationVariables
>;

/**
 * __useUpdateEquipmentCostHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentCostHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentCostHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentCostHistoryMutation, { data, loading, error }] = useUpdateEquipmentCostHistoryMutation({
 *   variables: {
 *      equipmentCostHistory: // value for 'equipmentCostHistory'
 *   },
 * });
 */
export function useUpdateEquipmentCostHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEquipmentCostHistoryMutation,
    UpdateEquipmentCostHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEquipmentCostHistoryMutation, UpdateEquipmentCostHistoryMutationVariables>(
    UpdateEquipmentCostHistoryDocument,
    options
  );
}
export type UpdateEquipmentCostHistoryMutationHookResult = ReturnType<typeof useUpdateEquipmentCostHistoryMutation>;
export type UpdateEquipmentCostHistoryMutationResult = Apollo.MutationResult<UpdateEquipmentCostHistoryMutation>;
export type UpdateEquipmentCostHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateEquipmentCostHistoryMutation,
  UpdateEquipmentCostHistoryMutationVariables
>;
export const MemberClockStateDocument = gql`
  query MemberClockState($filter: MemberClockStateFilter) {
    memberClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;

/**
 * __useMemberClockStateQuery__
 *
 * To run a query within a React component, call `useMemberClockStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberClockStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberClockStateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMemberClockStateQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberClockStateQuery, MemberClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberClockStateQuery, MemberClockStateQueryVariables>(MemberClockStateDocument, options);
}
export function useMemberClockStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberClockStateQuery, MemberClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberClockStateQuery, MemberClockStateQueryVariables>(MemberClockStateDocument, options);
}
export function useMemberClockStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberClockStateQuery, MemberClockStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MemberClockStateQuery, MemberClockStateQueryVariables>(
    MemberClockStateDocument,
    options
  );
}
export type MemberClockStateQueryHookResult = ReturnType<typeof useMemberClockStateQuery>;
export type MemberClockStateLazyQueryHookResult = ReturnType<typeof useMemberClockStateLazyQuery>;
export type MemberClockStateSuspenseQueryHookResult = ReturnType<typeof useMemberClockStateSuspenseQuery>;
export type MemberClockStateQueryResult = Apollo.QueryResult<MemberClockStateQuery, MemberClockStateQueryVariables>;
export const QueryMemberTimeDocumentsBasicSignatureDetailsDocument = gql`
  query queryMemberTimeDocumentsBasicSignatureDetails(
    $first: Int
    $after: String
    $filter: MemberTimeDocumentFilter
    $sort: [MemberTimeDocumentSort!]
  ) {
    memberTimeDocuments(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      edited
      canceled
      cursor
      authoritativeSignatureId
      selfSignatureId
    }
  }
`;

/**
 * __useQueryMemberTimeDocumentsBasicSignatureDetailsQuery__
 *
 * To run a query within a React component, call `useQueryMemberTimeDocumentsBasicSignatureDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryMemberTimeDocumentsBasicSignatureDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryMemberTimeDocumentsBasicSignatureDetailsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryMemberTimeDocumentsBasicSignatureDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
    QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
    QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
  >(QueryMemberTimeDocumentsBasicSignatureDetailsDocument, options);
}
export function useQueryMemberTimeDocumentsBasicSignatureDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
    QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
    QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
  >(QueryMemberTimeDocumentsBasicSignatureDetailsDocument, options);
}
export function useQueryMemberTimeDocumentsBasicSignatureDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
    QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
    QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
  >(QueryMemberTimeDocumentsBasicSignatureDetailsDocument, options);
}
export type QueryMemberTimeDocumentsBasicSignatureDetailsQueryHookResult = ReturnType<
  typeof useQueryMemberTimeDocumentsBasicSignatureDetailsQuery
>;
export type QueryMemberTimeDocumentsBasicSignatureDetailsLazyQueryHookResult = ReturnType<
  typeof useQueryMemberTimeDocumentsBasicSignatureDetailsLazyQuery
>;
export type QueryMemberTimeDocumentsBasicSignatureDetailsSuspenseQueryHookResult = ReturnType<
  typeof useQueryMemberTimeDocumentsBasicSignatureDetailsSuspenseQuery
>;
export type QueryMemberTimeDocumentsBasicSignatureDetailsQueryResult = Apollo.QueryResult<
  QueryMemberTimeDocumentsBasicSignatureDetailsQuery,
  QueryMemberTimeDocumentsBasicSignatureDetailsQueryVariables
>;
export const CreateOrganizationSignInQuestionDocument = gql`
  mutation CreateOrganizationSignInQuestion($organizationSignInQuestion: OrganizationSignInQuestionCreateInput!) {
    createOrganizationSignInQuestion(organizationSignInQuestion: $organizationSignInQuestion) {
      id
    }
  }
`;
export type CreateOrganizationSignInQuestionMutationFn = Apollo.MutationFunction<
  CreateOrganizationSignInQuestionMutation,
  CreateOrganizationSignInQuestionMutationVariables
>;

/**
 * __useCreateOrganizationSignInQuestionMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationSignInQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationSignInQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationSignInQuestionMutation, { data, loading, error }] = useCreateOrganizationSignInQuestionMutation({
 *   variables: {
 *      organizationSignInQuestion: // value for 'organizationSignInQuestion'
 *   },
 * });
 */
export function useCreateOrganizationSignInQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationSignInQuestionMutation,
    CreateOrganizationSignInQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationSignInQuestionMutation,
    CreateOrganizationSignInQuestionMutationVariables
  >(CreateOrganizationSignInQuestionDocument, options);
}
export type CreateOrganizationSignInQuestionMutationHookResult = ReturnType<
  typeof useCreateOrganizationSignInQuestionMutation
>;
export type CreateOrganizationSignInQuestionMutationResult =
  Apollo.MutationResult<CreateOrganizationSignInQuestionMutation>;
export type CreateOrganizationSignInQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationSignInQuestionMutation,
  CreateOrganizationSignInQuestionMutationVariables
>;
export const GetAllOrganizationSignInQuestionsDocument = gql`
  query getAllOrganizationSignInQuestions(
    $filter: OrganizationSignInQuestionFilter
    $first: Int
    $sort: [OrganizationSignInQuestionSort!]
    $after: String
  ) {
    organizationSignInQuestions(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      audienceType
      choices
      createdOn
      cursor
      deletedOn
      organizationId
      positionId
      questionDescription
      questionPrompt
      questionTitle
      required
      submittedOn
      type
      updatedOn
      order
    }
  }
`;

/**
 * __useGetAllOrganizationSignInQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationSignInQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationSignInQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationSignInQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllOrganizationSignInQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllOrganizationSignInQuestionsQuery,
    GetAllOrganizationSignInQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllOrganizationSignInQuestionsQuery, GetAllOrganizationSignInQuestionsQueryVariables>(
    GetAllOrganizationSignInQuestionsDocument,
    options
  );
}
export function useGetAllOrganizationSignInQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllOrganizationSignInQuestionsQuery,
    GetAllOrganizationSignInQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllOrganizationSignInQuestionsQuery, GetAllOrganizationSignInQuestionsQueryVariables>(
    GetAllOrganizationSignInQuestionsDocument,
    options
  );
}
export function useGetAllOrganizationSignInQuestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllOrganizationSignInQuestionsQuery,
    GetAllOrganizationSignInQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllOrganizationSignInQuestionsQuery,
    GetAllOrganizationSignInQuestionsQueryVariables
  >(GetAllOrganizationSignInQuestionsDocument, options);
}
export type GetAllOrganizationSignInQuestionsQueryHookResult = ReturnType<
  typeof useGetAllOrganizationSignInQuestionsQuery
>;
export type GetAllOrganizationSignInQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetAllOrganizationSignInQuestionsLazyQuery
>;
export type GetAllOrganizationSignInQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllOrganizationSignInQuestionsSuspenseQuery
>;
export type GetAllOrganizationSignInQuestionsQueryResult = Apollo.QueryResult<
  GetAllOrganizationSignInQuestionsQuery,
  GetAllOrganizationSignInQuestionsQueryVariables
>;
export const UpdateOrganizationSignInQuestionDocument = gql`
  mutation UpdateOrganizationSignInQuestion($organizationSignInQuestion: OrganizationSignInQuestionUpdateInput!) {
    updateOrganizationSignInQuestion(organizationSignInQuestion: $organizationSignInQuestion) {
      id
    }
  }
`;
export type UpdateOrganizationSignInQuestionMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSignInQuestionMutation,
  UpdateOrganizationSignInQuestionMutationVariables
>;

/**
 * __useUpdateOrganizationSignInQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSignInQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSignInQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSignInQuestionMutation, { data, loading, error }] = useUpdateOrganizationSignInQuestionMutation({
 *   variables: {
 *      organizationSignInQuestion: // value for 'organizationSignInQuestion'
 *   },
 * });
 */
export function useUpdateOrganizationSignInQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationSignInQuestionMutation,
    UpdateOrganizationSignInQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationSignInQuestionMutation,
    UpdateOrganizationSignInQuestionMutationVariables
  >(UpdateOrganizationSignInQuestionDocument, options);
}
export type UpdateOrganizationSignInQuestionMutationHookResult = ReturnType<
  typeof useUpdateOrganizationSignInQuestionMutation
>;
export type UpdateOrganizationSignInQuestionMutationResult =
  Apollo.MutationResult<UpdateOrganizationSignInQuestionMutation>;
export type UpdateOrganizationSignInQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSignInQuestionMutation,
  UpdateOrganizationSignInQuestionMutationVariables
>;
export const GetAllOrganizationSignOffQuestionsDocument = gql`
  query getAllOrganizationSignOffQuestions(
    $filter: OrganizationSignOffQuestionFilter
    $first: Int
    $sort: [OrganizationSignOffQuestionSort!]
    $after: String
  ) {
    organizationSignOffQuestions(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      audienceType
      choices
      createdOn
      cursor
      deletedOn
      organizationId
      positionId
      questionDescription
      questionPrompt
      questionTitle
      submittedOn
      type
      updatedOn
      order
    }
  }
`;

/**
 * __useGetAllOrganizationSignOffQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationSignOffQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationSignOffQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationSignOffQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAllOrganizationSignOffQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllOrganizationSignOffQuestionsQuery,
    GetAllOrganizationSignOffQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllOrganizationSignOffQuestionsQuery, GetAllOrganizationSignOffQuestionsQueryVariables>(
    GetAllOrganizationSignOffQuestionsDocument,
    options
  );
}
export function useGetAllOrganizationSignOffQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllOrganizationSignOffQuestionsQuery,
    GetAllOrganizationSignOffQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllOrganizationSignOffQuestionsQuery, GetAllOrganizationSignOffQuestionsQueryVariables>(
    GetAllOrganizationSignOffQuestionsDocument,
    options
  );
}
export function useGetAllOrganizationSignOffQuestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllOrganizationSignOffQuestionsQuery,
    GetAllOrganizationSignOffQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllOrganizationSignOffQuestionsQuery,
    GetAllOrganizationSignOffQuestionsQueryVariables
  >(GetAllOrganizationSignOffQuestionsDocument, options);
}
export type GetAllOrganizationSignOffQuestionsQueryHookResult = ReturnType<
  typeof useGetAllOrganizationSignOffQuestionsQuery
>;
export type GetAllOrganizationSignOffQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetAllOrganizationSignOffQuestionsLazyQuery
>;
export type GetAllOrganizationSignOffQuestionsSuspenseQueryHookResult = ReturnType<
  typeof useGetAllOrganizationSignOffQuestionsSuspenseQuery
>;
export type GetAllOrganizationSignOffQuestionsQueryResult = Apollo.QueryResult<
  GetAllOrganizationSignOffQuestionsQuery,
  GetAllOrganizationSignOffQuestionsQueryVariables
>;
export const UpdateOrganizationSignOffQuestionDocument = gql`
  mutation UpdateOrganizationSignOffQuestion($organizationSignOffQuestion: OrganizationSignOffQuestionUpdateInput!) {
    updateOrganizationSignOffQuestion(organizationSignOffQuestion: $organizationSignOffQuestion) {
      id
      questionTitle
      questionDescription
      audienceType
      organizationId
      createdOn
      type
      choices
      order
      positionId
    }
  }
`;
export type UpdateOrganizationSignOffQuestionMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSignOffQuestionMutation,
  UpdateOrganizationSignOffQuestionMutationVariables
>;

/**
 * __useUpdateOrganizationSignOffQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSignOffQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSignOffQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSignOffQuestionMutation, { data, loading, error }] = useUpdateOrganizationSignOffQuestionMutation({
 *   variables: {
 *      organizationSignOffQuestion: // value for 'organizationSignOffQuestion'
 *   },
 * });
 */
export function useUpdateOrganizationSignOffQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationSignOffQuestionMutation,
    UpdateOrganizationSignOffQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationSignOffQuestionMutation,
    UpdateOrganizationSignOffQuestionMutationVariables
  >(UpdateOrganizationSignOffQuestionDocument, options);
}
export type UpdateOrganizationSignOffQuestionMutationHookResult = ReturnType<
  typeof useUpdateOrganizationSignOffQuestionMutation
>;
export type UpdateOrganizationSignOffQuestionMutationResult =
  Apollo.MutationResult<UpdateOrganizationSignOffQuestionMutation>;
export type UpdateOrganizationSignOffQuestionMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSignOffQuestionMutation,
  UpdateOrganizationSignOffQuestionMutationVariables
>;
export const CreateOrganizationSignOffQuestionDocument = gql`
  mutation CreateOrganizationSignOffQuestion($organizationSignOffQuestion: OrganizationSignOffQuestionCreateInput!) {
    createOrganizationSignOffQuestion(organizationSignOffQuestion: $organizationSignOffQuestion) {
      id
      questionTitle
      questionDescription
      audienceType
      organizationId
      createdOn
      type
      choices
      order
      positionId
    }
  }
`;
export type CreateOrganizationSignOffQuestionMutationFn = Apollo.MutationFunction<
  CreateOrganizationSignOffQuestionMutation,
  CreateOrganizationSignOffQuestionMutationVariables
>;

/**
 * __useCreateOrganizationSignOffQuestionMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationSignOffQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationSignOffQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationSignOffQuestionMutation, { data, loading, error }] = useCreateOrganizationSignOffQuestionMutation({
 *   variables: {
 *      organizationSignOffQuestion: // value for 'organizationSignOffQuestion'
 *   },
 * });
 */
export function useCreateOrganizationSignOffQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationSignOffQuestionMutation,
    CreateOrganizationSignOffQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationSignOffQuestionMutation,
    CreateOrganizationSignOffQuestionMutationVariables
  >(CreateOrganizationSignOffQuestionDocument, options);
}
export type CreateOrganizationSignOffQuestionMutationHookResult = ReturnType<
  typeof useCreateOrganizationSignOffQuestionMutation
>;
export type CreateOrganizationSignOffQuestionMutationResult =
  Apollo.MutationResult<CreateOrganizationSignOffQuestionMutation>;
export type CreateOrganizationSignOffQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationSignOffQuestionMutation,
  CreateOrganizationSignOffQuestionMutationVariables
>;
export type AuthoritativeSignatureKeySpecifier = (
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'proxyMember'
  | 'proxyMemberId'
  | 'signatureId'
  | 'signatureUrl'
  | 'submittedOn'
  | 'updatedOn'
  | AuthoritativeSignatureKeySpecifier
)[];
export type AuthoritativeSignatureFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMember?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BudgetCostKeySpecifier = (
  | 'costBudget'
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipment'
  | 'equipmentCostBudget'
  | 'equipmentId'
  | 'id'
  | 'isLatest'
  | 'member'
  | 'memberId'
  | 'project'
  | 'projectId'
  | 'submittedOn'
  | 'updatedOn'
  | BudgetCostKeySpecifier
)[];
export type BudgetCostFieldPolicy = {
  costBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCostBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isLatest?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BudgetHoursKeySpecifier = (
  | 'budgetSeconds'
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipment'
  | 'equipmentBudgetSeconds'
  | 'equipmentId'
  | 'id'
  | 'isLatest'
  | 'member'
  | 'memberId'
  | 'project'
  | 'projectId'
  | 'submittedOn'
  | 'updatedOn'
  | BudgetHoursKeySpecifier
)[];
export type BudgetHoursFieldPolicy = {
  budgetSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentBudgetSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isLatest?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusySignInQuestionKeySpecifier = (
  | 'choices'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'questionDescription'
  | 'questionPrompt'
  | 'questionTitle'
  | 'required'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | BusySignInQuestionKeySpecifier
)[];
export type BusySignInQuestionFieldPolicy = {
  choices?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  questionDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  questionPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  questionTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  required?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientTimeEntryBreakLogKeySpecifier = (
  | 'actionType'
  | 'clientTimeEntryLogId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'deviceType'
  | 'endDeviceTime'
  | 'endLocationId'
  | 'endTime'
  | 'endTimeDst'
  | 'endTimeTrusted'
  | 'id'
  | 'mergedTimeEntryBreakId'
  | 'originalTimeEntryBreakId'
  | 'paidBreak'
  | 'startDeviceTime'
  | 'startLocationId'
  | 'startTime'
  | 'startTimeDst'
  | 'startTimeTrusted'
  | 'submittedOn'
  | 'timeEntryBreak'
  | 'timeEntryBreakCreatedOn'
  | 'timeEntryBreakDeletedOn'
  | 'timeEntryBreakId'
  | 'timeEntryId'
  | 'updatedOn'
  | 'updaterMemberId'
  | ClientTimeEntryBreakLogKeySpecifier
)[];
export type ClientTimeEntryBreakLogFieldPolicy = {
  actionType?: FieldPolicy<any> | FieldReadFunction<any>;
  clientTimeEntryLogId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  deviceType?: FieldPolicy<any> | FieldReadFunction<any>;
  endDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mergedTimeEntryBreakId?: FieldPolicy<any> | FieldReadFunction<any>;
  originalTimeEntryBreakId?: FieldPolicy<any> | FieldReadFunction<any>;
  paidBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  startDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryBreakCreatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryBreakDeletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryBreakId?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updaterMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientTimeEntryLogKeySpecifier = (
  | 'actionType'
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'daylightSavingTime'
  | 'deletedOn'
  | 'description'
  | 'deviceType'
  | 'endDeviceTime'
  | 'endLocationId'
  | 'endTime'
  | 'endTimeTrusted'
  | 'equipment'
  | 'equipmentId'
  | 'id'
  | 'member'
  | 'memberId'
  | 'mergedTimeEntryId'
  | 'metaDaylightSavingTime'
  | 'originalTimeEntryId'
  | 'project'
  | 'projectId'
  | 'startDeviceTime'
  | 'startLocationId'
  | 'startTime'
  | 'startTimeTrusted'
  | 'submittedOn'
  | 'timeEntry'
  | 'timeEntryCreatedOn'
  | 'timeEntryDeletedOn'
  | 'timeEntryId'
  | 'updatedOn'
  | 'updaterMember'
  | 'updaterMemberId'
  | ClientTimeEntryLogKeySpecifier
)[];
export type ClientTimeEntryLogFieldPolicy = {
  actionType?: FieldPolicy<any> | FieldReadFunction<any>;
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  daylightSavingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  deviceType?: FieldPolicy<any> | FieldReadFunction<any>;
  endDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  mergedTimeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  metaDaylightSavingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  originalTimeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  startDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryCreatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryDeletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updaterMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updaterMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeKeySpecifier = (
  | 'archivedOn'
  | 'costCode'
  | 'costCodeEquipmentLaborMetrics'
  | 'costCodeGroup'
  | 'costCodeGroupId'
  | 'costCodeLaborMetrics'
  | 'costCodeMemberEquipmentLaborMetrics'
  | 'costCodeMemberLaborMetrics'
  | 'costCodeMemberProjectEquipmentLaborMetrics'
  | 'costCodeMemberProjectLaborMetrics'
  | 'costCodeProjectEquipmentLaborMetrics'
  | 'costCodeProjectLaborMetrics'
  | 'createdOn'
  | 'cursor'
  | 'generalLiabilityId'
  | 'id'
  | 'openTimeEntries'
  | 'organizationId'
  | 'submittedOn'
  | 'title'
  | 'unitTitle'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | 'workersCompId'
  | CostCodeKeySpecifier
)[];
export type CostCodeFieldPolicy = {
  archivedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeMemberEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeMemberLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeMemberProjectEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeMemberProjectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeProjectEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeProjectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  generalLiabilityId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  openTimeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  unitTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  workersCompId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeClockStateKeySpecifier = (
  | 'all'
  | 'clockedIn'
  | 'clockedInNotOnBreak'
  | 'clockedOut'
  | 'onBreak'
  | CostCodeClockStateKeySpecifier
)[];
export type CostCodeClockStateFieldPolicy = {
  all?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedIn?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedInNotOnBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedOut?: FieldPolicy<any> | FieldReadFunction<any>;
  onBreak?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | CostCodeEquipmentLaborMetricsKeySpecifier
)[];
export type CostCodeEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeGroupKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'groupName'
  | 'id'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | CostCodeGroupKeySpecifier
)[];
export type CostCodeGroupFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  groupName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | CostCodeLaborMetricsKeySpecifier
)[];
export type CostCodeLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeMemberLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | CostCodeMemberLaborMetricsKeySpecifier
)[];
export type CostCodeMemberLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CostCodeProjectLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | CostCodeProjectLaborMetricsKeySpecifier
)[];
export type CostCodeProjectLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DailyProjectReportKeySpecifier = (
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'fileUrl'
  | 'id'
  | 'project'
  | 'projectId'
  | 'reportDate'
  | 'submittedOn'
  | 'template'
  | 'templateId'
  | 'updatedOn'
  | DailyProjectReportKeySpecifier
)[];
export type DailyProjectReportFieldPolicy = {
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  reportDate?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  template?: FieldPolicy<any> | FieldReadFunction<any>;
  templateId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DailyProjectReportTemplateKeySpecifier = (
  | 'appearance'
  | 'audienceType'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'organizationId'
  | 'position'
  | 'positionId'
  | 'sections'
  | 'submittedOn'
  | 'title'
  | 'updatedOn'
  | DailyProjectReportTemplateKeySpecifier
)[];
export type DailyProjectReportTemplateFieldPolicy = {
  appearance?: FieldPolicy<any> | FieldReadFunction<any>;
  audienceType?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  sections?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentKeySpecifier = (
  | 'archivedOn'
  | 'costHistory'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipmentCostCodeLaborMetrics'
  | 'equipmentGroupId'
  | 'equipmentHourMeterMetrics'
  | 'equipmentLaborMetrics'
  | 'equipmentMemberCostCodeLaborMetrics'
  | 'equipmentMemberLaborMetrics'
  | 'equipmentMemberProjectCostCodeLaborMetrics'
  | 'equipmentMemberProjectLaborMetrics'
  | 'equipmentModelId'
  | 'equipmentName'
  | 'equipmentProjectCostCodeLaborMetrics'
  | 'equipmentProjectLaborMetrics'
  | 'fuelCapacity'
  | 'id'
  | 'imageId'
  | 'imageUrl'
  | 'importTtlSeconds'
  | 'importType'
  | 'lastHours'
  | 'lastHoursId'
  | 'lastLocation'
  | 'lastLocationId'
  | 'lastSync'
  | 'model'
  | 'openTimeEntries'
  | 'organizationId'
  | 'serialNumber'
  | 'submittedOn'
  | 'trackImoId'
  | 'trackManualHours'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | EquipmentKeySpecifier
)[];
export type EquipmentFieldPolicy = {
  archivedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  costHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentHourMeterMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentMemberCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentMemberLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentMemberProjectCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentMemberProjectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentModelId?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentName?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentProjectCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentProjectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  fuelCapacity?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  importTtlSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  importType?: FieldPolicy<any> | FieldReadFunction<any>;
  lastHours?: FieldPolicy<any> | FieldReadFunction<any>;
  lastHoursId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  lastLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastSync?: FieldPolicy<any> | FieldReadFunction<any>;
  model?: FieldPolicy<any> | FieldReadFunction<any>;
  openTimeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  serialNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  trackImoId?: FieldPolicy<any> | FieldReadFunction<any>;
  trackManualHours?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentCategoryKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipmentTypeId'
  | 'id'
  | 'imageId'
  | 'imageUrl'
  | 'organizationId'
  | 'review'
  | 'submittedOn'
  | 'title'
  | 'type'
  | 'updatedOn'
  | EquipmentCategoryKeySpecifier
)[];
export type EquipmentCategoryFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  review?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentClockStateKeySpecifier = (
  | 'all'
  | 'clockedIn'
  | 'clockedInNotOnBreak'
  | 'clockedOut'
  | 'onBreak'
  | EquipmentClockStateKeySpecifier
)[];
export type EquipmentClockStateFieldPolicy = {
  all?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedIn?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedInNotOnBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedOut?: FieldPolicy<any> | FieldReadFunction<any>;
  onBreak?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentCostCodeLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | EquipmentCostCodeLaborMetricsKeySpecifier
)[];
export type EquipmentCostCodeLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentCostHistoryKeySpecifier = (
  | 'changeDate'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipment'
  | 'equipmentId'
  | 'id'
  | 'machineCostRate'
  | 'operatorCostRate'
  | 'submittedOn'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | EquipmentCostHistoryKeySpecifier
)[];
export type EquipmentCostHistoryFieldPolicy = {
  changeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  machineCostRate?: FieldPolicy<any> | FieldReadFunction<any>;
  operatorCostRate?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentHourMeterMetricsKeySpecifier = (
  | 'end'
  | 'equipmentId'
  | 'hoursDiff'
  | 'start'
  | EquipmentHourMeterMetricsKeySpecifier
)[];
export type EquipmentHourMeterMetricsFieldPolicy = {
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  hoursDiff?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentHoursKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipment'
  | 'equipmentId'
  | 'id'
  | 'runningHours'
  | 'source'
  | 'submittedByMember'
  | 'submittedByMemberId'
  | 'submittedOn'
  | 'updatedOn'
  | EquipmentHoursKeySpecifier
)[];
export type EquipmentHoursFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  runningHours?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | EquipmentLaborMetricsKeySpecifier
)[];
export type EquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentLaborMetricsInterfaceKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | EquipmentLaborMetricsInterfaceKeySpecifier
)[];
export type EquipmentLaborMetricsInterfaceFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentLocationKeySpecifier = (
  | 'altitude'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipment'
  | 'equipmentId'
  | 'id'
  | 'latitude'
  | 'longitude'
  | 'submittedOn'
  | 'updatedOn'
  | 'utc'
  | EquipmentLocationKeySpecifier
)[];
export type EquipmentLocationFieldPolicy = {
  altitude?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  utc?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentMakeKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'imageId'
  | 'organizationId'
  | 'review'
  | 'submittedOn'
  | 'title'
  | 'unknown'
  | 'updatedOn'
  | EquipmentMakeKeySpecifier
)[];
export type EquipmentMakeFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  review?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  unknown?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentMemberLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | EquipmentMemberLaborMetricsKeySpecifier
)[];
export type EquipmentMemberLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentModelKeySpecifier = (
  | 'category'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipmentCategoryId'
  | 'equipmentMakeId'
  | 'id'
  | 'imageId'
  | 'imageUrl'
  | 'make'
  | 'modelNumber'
  | 'organizationId'
  | 'submittedOn'
  | 'title'
  | 'unknown'
  | 'updatedOn'
  | 'year'
  | EquipmentModelKeySpecifier
)[];
export type EquipmentModelFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCategoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentMakeId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  make?: FieldPolicy<any> | FieldReadFunction<any>;
  modelNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  unknown?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentProjectLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descEquipmentCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | EquipmentProjectLaborMetricsKeySpecifier
)[];
export type EquipmentProjectLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descEquipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentProjectLaborMetricsInterfaceKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descEquipmentCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | EquipmentProjectLaborMetricsInterfaceKeySpecifier
)[];
export type EquipmentProjectLaborMetricsInterfaceFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descEquipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EquipmentTypeKeySpecifier = (
  | 'color'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'display'
  | 'id'
  | 'organizationId'
  | 'review'
  | 'submittedOn'
  | 'title'
  | 'updatedOn'
  | EquipmentTypeKeySpecifier
)[];
export type EquipmentTypeFieldPolicy = {
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  display?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  review?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FlaggedTimeEntryLocationKeySpecifier = (
  | 'comment'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'edited'
  | 'id'
  | 'lastStatusUpdatedOn'
  | 'locationData'
  | 'member'
  | 'memberId'
  | 'organizationId'
  | 'reviewedByMember'
  | 'reviewedByMemberId'
  | 'status'
  | 'submittedOn'
  | 'timeEntry'
  | 'timeEntryId'
  | 'updatedOn'
  | FlaggedTimeEntryLocationKeySpecifier
)[];
export type FlaggedTimeEntryLocationFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  edited?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastStatusUpdatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  locationData?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewedByMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FolderKeySpecifier = (
  | 'color'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'organizationId'
  | 'submittedOn'
  | 'title'
  | 'type'
  | 'updatedOn'
  | FolderKeySpecifier
)[];
export type FolderFieldPolicy = {
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncidentKeySpecifier = (
  | 'answers'
  | 'caseNumber'
  | 'completedBy'
  | 'completedByMember'
  | 'completedOn'
  | 'createdOn'
  | 'createdOnLocal'
  | 'cursor'
  | 'deletedOn'
  | 'employeeAddress1'
  | 'employeeAddress2'
  | 'employeeBirthday'
  | 'employeeCity'
  | 'employeeHireDate'
  | 'employeeJobTitle'
  | 'employeePostalCode'
  | 'employeeSex'
  | 'employeeStartTime'
  | 'employeeState'
  | 'id'
  | 'illnessType'
  | 'medicalDetails'
  | 'member'
  | 'memberId'
  | 'occurredAt'
  | 'occurrenceTimeSet'
  | 'organizationId'
  | 'outcomeDetails'
  | 'project'
  | 'projectId'
  | 'recordable'
  | 'reviewedBy'
  | 'reviewedByMember'
  | 'reviewedOn'
  | 'state'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | IncidentKeySpecifier
)[];
export type IncidentFieldPolicy = {
  answers?: FieldPolicy<any> | FieldReadFunction<any>;
  caseNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  completedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  completedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  completedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeAddress1?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeAddress2?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeBirthday?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeCity?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeHireDate?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeJobTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  employeePostalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeSex?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeState?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  illnessType?: FieldPolicy<any> | FieldReadFunction<any>;
  medicalDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  occurredAt?: FieldPolicy<any> | FieldReadFunction<any>;
  occurrenceTimeSet?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  outcomeDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  recordable?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  reviewedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncidentAnswerKeySpecifier = (
  | 'answer'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'incident'
  | 'incidentId'
  | 'order'
  | 'question'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | IncidentAnswerKeySpecifier
)[];
export type IncidentAnswerFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incident?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentId?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  question?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncidentMedicalDetailsKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'facilityAddress'
  | 'facilityCity'
  | 'facilityName'
  | 'facilityPostalCode'
  | 'facilityState'
  | 'id'
  | 'incident'
  | 'incidentId'
  | 'physicianName'
  | 'submittedOn'
  | 'treatmentDoctor'
  | 'treatmentER'
  | 'treatmentFirstAid'
  | 'treatmentNone'
  | 'treatmentOther'
  | 'treatmentOvernight'
  | 'updatedOn'
  | IncidentMedicalDetailsKeySpecifier
)[];
export type IncidentMedicalDetailsFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  facilityAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  facilityCity?: FieldPolicy<any> | FieldReadFunction<any>;
  facilityName?: FieldPolicy<any> | FieldReadFunction<any>;
  facilityPostalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  facilityState?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incident?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentId?: FieldPolicy<any> | FieldReadFunction<any>;
  physicianName?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  treatmentDoctor?: FieldPolicy<any> | FieldReadFunction<any>;
  treatmentER?: FieldPolicy<any> | FieldReadFunction<any>;
  treatmentFirstAid?: FieldPolicy<any> | FieldReadFunction<any>;
  treatmentNone?: FieldPolicy<any> | FieldReadFunction<any>;
  treatmentOther?: FieldPolicy<any> | FieldReadFunction<any>;
  treatmentOvernight?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncidentOutcomeDetailsKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'daysAway'
  | 'daysTransfer'
  | 'deletedOn'
  | 'id'
  | 'incident'
  | 'incidentId'
  | 'outcomeDate'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | IncidentOutcomeDetailsKeySpecifier
)[];
export type IncidentOutcomeDetailsFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  daysAway?: FieldPolicy<any> | FieldReadFunction<any>;
  daysTransfer?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incident?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentId?: FieldPolicy<any> | FieldReadFunction<any>;
  outcomeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionKeySpecifier = (
  | 'answers'
  | 'assignmentId'
  | 'checklist'
  | 'checklistId'
  | 'completedOn'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'inspectedOn'
  | 'organizationId'
  | 'project'
  | 'projectId'
  | 'recurringAssignmentId'
  | 'score'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | InspectionKeySpecifier
)[];
export type InspectionFieldPolicy = {
  answers?: FieldPolicy<any> | FieldReadFunction<any>;
  assignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  checklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  completedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringAssignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionAssignmentKeySpecifier = (
  | 'checklist'
  | 'checklistId'
  | 'circumstanceValue'
  | 'createdOn'
  | 'cursor'
  | 'date'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'inspection'
  | 'limitCategories'
  | 'limitedCategories'
  | 'member'
  | 'memberId'
  | 'notificationLinks'
  | 'notificationTime'
  | 'notifyCircumstance'
  | 'notifySubmission'
  | 'organizationId'
  | 'overrideRecurringAssignmentId'
  | 'overrideState'
  | 'project'
  | 'projectId'
  | 'sendNotification'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | InspectionAssignmentKeySpecifier
)[];
export type InspectionAssignmentFieldPolicy = {
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  checklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  circumstanceValue?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inspection?: FieldPolicy<any> | FieldReadFunction<any>;
  limitCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  limitedCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationTime?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyCircumstance?: FieldPolicy<any> | FieldReadFunction<any>;
  notifySubmission?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  overrideRecurringAssignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  overrideState?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  sendNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionAssignmentCategoryLinkKeySpecifier = (
  | 'assignment'
  | 'assignmentId'
  | 'category'
  | 'categoryId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'submittedOn'
  | 'updatedOn'
  | InspectionAssignmentCategoryLinkKeySpecifier
)[];
export type InspectionAssignmentCategoryLinkFieldPolicy = {
  assignment?: FieldPolicy<any> | FieldReadFunction<any>;
  assignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionAssignmentNotificationLinkKeySpecifier = (
  | 'assignment'
  | 'assignmentId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'submittedOn'
  | 'updatedOn'
  | InspectionAssignmentNotificationLinkKeySpecifier
)[];
export type InspectionAssignmentNotificationLinkFieldPolicy = {
  assignment?: FieldPolicy<any> | FieldReadFunction<any>;
  assignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionCategoryKeySpecifier = (
  | 'checklist'
  | 'checklistId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'name'
  | 'questions'
  | 'submittedOn'
  | 'updatedOn'
  | InspectionCategoryKeySpecifier
)[];
export type InspectionCategoryFieldPolicy = {
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  checklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  questions?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionChecklistKeySpecifier = (
  | 'categories'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'limitInspectors'
  | 'memberLinks'
  | 'name'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | InspectionChecklistKeySpecifier
)[];
export type InspectionChecklistFieldPolicy = {
  categories?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  limitInspectors?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionChecklistMemberLinkKeySpecifier = (
  | 'checklist'
  | 'checklistId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'submittedOn'
  | 'updatedOn'
  | InspectionChecklistMemberLinkKeySpecifier
)[];
export type InspectionChecklistMemberLinkFieldPolicy = {
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  checklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionQuestionKeySpecifier = (
  | 'category'
  | 'categoryId'
  | 'choices'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'enabled'
  | 'id'
  | 'order'
  | 'question'
  | 'recommendation'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | InspectionQuestionKeySpecifier
)[];
export type InspectionQuestionFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  choices?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  question?: FieldPolicy<any> | FieldReadFunction<any>;
  recommendation?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionQuestionAnswerKeySpecifier = (
  | 'answer'
  | 'attachments'
  | 'comments'
  | 'corrected'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'flagged'
  | 'id'
  | 'inspection'
  | 'inspectionId'
  | 'inspectionQuestion'
  | 'question'
  | 'questionId'
  | 'severity'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | InspectionQuestionAnswerKeySpecifier
)[];
export type InspectionQuestionAnswerFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  corrected?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  flagged?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inspection?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  question?: FieldPolicy<any> | FieldReadFunction<any>;
  questionId?: FieldPolicy<any> | FieldReadFunction<any>;
  severity?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InspectionQuestionAnswerAttachmentKeySpecifier = (
  | 'answer'
  | 'answerId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'fileType'
  | 'fileUrl'
  | 'id'
  | 'inspection'
  | 'inspectionId'
  | 'submittedOn'
  | 'title'
  | 'updatedOn'
  | 'uploadedBy'
  | 'uploadedByMember'
  | InspectionQuestionAnswerAttachmentKeySpecifier
)[];
export type InspectionQuestionAnswerAttachmentFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  answerId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  fileType?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inspection?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LaborMetricsInterfaceKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | LaborMetricsInterfaceKeySpecifier
)[];
export type LaborMetricsInterfaceFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MediaCollectionKeySpecifier = (
  | 'assignedTo'
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'mediaEntries'
  | 'organizationId'
  | 'project'
  | 'projectId'
  | 'source'
  | 'submittedOn'
  | 'tagLinks'
  | 'title'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | MediaCollectionKeySpecifier
)[];
export type MediaCollectionFieldPolicy = {
  assignedTo?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  tagLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MediaEntryKeySpecifier = (
  | 'annotationFileUrl'
  | 'captureDate'
  | 'collection'
  | 'comment'
  | 'comments'
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'entryType'
  | 'fileUrl'
  | 'id'
  | 'lastAnnotatedAt'
  | 'lastAnnotatedBy'
  | 'lastAnnotatedByMember'
  | 'location'
  | 'mediaCollectionId'
  | 'metaData'
  | 'submittedOn'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | 'videoThumbFileUrl'
  | MediaEntryKeySpecifier
)[];
export type MediaEntryFieldPolicy = {
  annotationFileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  captureDate?: FieldPolicy<any> | FieldReadFunction<any>;
  collection?: FieldPolicy<any> | FieldReadFunction<any>;
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  entryType?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastAnnotatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  lastAnnotatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  lastAnnotatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaCollectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  metaData?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  videoThumbFileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MediaEntryCommentKeySpecifier = (
  | 'comment'
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'mediaEntry'
  | 'mediaEntryId'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | MediaEntryCommentKeySpecifier
)[];
export type MediaEntryCommentFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MediaEntryLocationKeySpecifier = (
  | 'altitude'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'entry'
  | 'horizontalAccuracy'
  | 'id'
  | 'latitude'
  | 'longitude'
  | 'mediaEntryId'
  | 'submittedOn'
  | 'timeDst'
  | 'timeGmt'
  | 'timeLocal'
  | 'updatedOn'
  | 'verticalAccuracy'
  | MediaEntryLocationKeySpecifier
)[];
export type MediaEntryLocationFieldPolicy = {
  altitude?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  entry?: FieldPolicy<any> | FieldReadFunction<any>;
  horizontalAccuracy?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  timeGmt?: FieldPolicy<any> | FieldReadFunction<any>;
  timeLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  verticalAccuracy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberKeySpecifier = (
  | 'archivedOn'
  | 'canSurvey'
  | 'certifiedEmail'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'distantLocationFlaggingDisabled'
  | 'email'
  | 'emailVerificationRequired'
  | 'firstName'
  | 'gustoContractorId'
  | 'gustoEmployeeId'
  | 'id'
  | 'imageId'
  | 'imageUrl'
  | 'isSubContractor'
  | 'lastAccess'
  | 'lastName'
  | 'memberCostCodeEquipmentLaborMetrics'
  | 'memberCostCodeLaborMetrics'
  | 'memberEquipmentLaborMetrics'
  | 'memberGroup'
  | 'memberGroupId'
  | 'memberLaborMetrics'
  | 'memberLock'
  | 'memberNumber'
  | 'memberOvertimePeriods'
  | 'memberProjectCostCodeEquipmentLaborMetrics'
  | 'memberProjectCostCodeLaborMetrics'
  | 'memberProjectEquipmentLaborMetrics'
  | 'memberProjectLaborMetrics'
  | 'memberSettings'
  | 'memberTimeDocuments'
  | 'memberUsageInfo'
  | 'notificationPreferences'
  | 'organization'
  | 'organizationId'
  | 'pendingEmail'
  | 'perDiems'
  | 'phone'
  | 'pinCode'
  | 'position'
  | 'positionId'
  | 'safetySignatures'
  | 'submittedOn'
  | 'timeEntries'
  | 'timeLocationRequired'
  | 'timeOffs'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | 'username'
  | 'usernameUnique'
  | 'verifiedEmail'
  | 'wageHistories'
  | MemberKeySpecifier
)[];
export type MemberFieldPolicy = {
  archivedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  canSurvey?: FieldPolicy<any> | FieldReadFunction<any>;
  certifiedEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  distantLocationFlaggingDisabled?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emailVerificationRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  gustoContractorId?: FieldPolicy<any> | FieldReadFunction<any>;
  gustoEmployeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  isSubContractor?: FieldPolicy<any> | FieldReadFunction<any>;
  lastAccess?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  memberCostCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  memberGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLock?: FieldPolicy<any> | FieldReadFunction<any>;
  memberNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  memberOvertimePeriods?: FieldPolicy<any> | FieldReadFunction<any>;
  memberProjectCostCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberProjectCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberProjectEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberProjectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  memberTimeDocuments?: FieldPolicy<any> | FieldReadFunction<any>;
  memberUsageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationPreferences?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  perDiems?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  pinCode?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  safetySignatures?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  timeLocationRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  timeOffs?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  usernameUnique?: FieldPolicy<any> | FieldReadFunction<any>;
  verifiedEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  wageHistories?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberClockStateKeySpecifier = (
  | 'all'
  | 'clockedIn'
  | 'clockedInNotOnBreak'
  | 'clockedOut'
  | 'onBreak'
  | MemberClockStateKeySpecifier
)[];
export type MemberClockStateFieldPolicy = {
  all?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedIn?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedInNotOnBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedOut?: FieldPolicy<any> | FieldReadFunction<any>;
  onBreak?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberCostCodeEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberCostCodeEquipmentLaborMetricsKeySpecifier
)[];
export type MemberCostCodeEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberCostCodeLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberCostCodeLaborMetricsKeySpecifier
)[];
export type MemberCostCodeLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberEquipmentLaborMetricsKeySpecifier
)[];
export type MemberEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberGpsStatusKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'localTime'
  | 'member'
  | 'memberId'
  | 'organizationId'
  | 'status'
  | 'submittedOn'
  | 'timeEntry'
  | 'timeEntryId'
  | 'updatedOn'
  | MemberGpsStatusKeySpecifier
)[];
export type MemberGpsStatusFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localTime?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberGroupKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'groupName'
  | 'id'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | MemberGroupKeySpecifier
)[];
export type MemberGroupFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  groupName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'pto'
  | 'ptoCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | 'upto'
  | MemberLaborMetricsKeySpecifier
)[];
export type MemberLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  pto?: FieldPolicy<any> | FieldReadFunction<any>;
  ptoCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  upto?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberLocationKeySpecifier = (
  | 'altitude'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'horizontalAccuracy'
  | 'id'
  | 'latitude'
  | 'longitude'
  | 'member'
  | 'memberId'
  | 'submittedOn'
  | 'timeDst'
  | 'timeGmt'
  | 'timeLocal'
  | 'updatedOn'
  | 'verticalAccuracy'
  | MemberLocationKeySpecifier
)[];
export type MemberLocationFieldPolicy = {
  altitude?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  horizontalAccuracy?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  timeGmt?: FieldPolicy<any> | FieldReadFunction<any>;
  timeLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  verticalAccuracy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberLockKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'effectiveDate'
  | 'id'
  | 'member'
  | 'memberId'
  | 'submittedOn'
  | 'updatedOn'
  | 'updaterMember'
  | 'updaterMemberId'
  | MemberLockKeySpecifier
)[];
export type MemberLockFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  effectiveDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updaterMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updaterMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberOvertimePeriodKeySpecifier = (
  | 'adhereToOrg'
  | 'changeDate'
  | 'createdOn'
  | 'cursor'
  | 'dailyDoubletime'
  | 'dailyOvertime'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'saturdayMultiplier'
  | 'seventhDayDoubletime'
  | 'seventhDayOvertime'
  | 'submittedOn'
  | 'sundayMultiplier'
  | 'updatedOn'
  | 'weeklyOvertime'
  | MemberOvertimePeriodKeySpecifier
)[];
export type MemberOvertimePeriodFieldPolicy = {
  adhereToOrg?: FieldPolicy<any> | FieldReadFunction<any>;
  changeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyDoubletime?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyOvertime?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  saturdayMultiplier?: FieldPolicy<any> | FieldReadFunction<any>;
  seventhDayDoubletime?: FieldPolicy<any> | FieldReadFunction<any>;
  seventhDayOvertime?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  sundayMultiplier?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  weeklyOvertime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberProjectCostCodeEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descEquipmentCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberProjectCostCodeEquipmentLaborMetricsKeySpecifier
)[];
export type MemberProjectCostCodeEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descEquipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberProjectCostCodeLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberProjectCostCodeLaborMetricsKeySpecifier
)[];
export type MemberProjectCostCodeLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberProjectEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descEquipmentCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberProjectEquipmentLaborMetricsKeySpecifier
)[];
export type MemberProjectEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descEquipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberProjectLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | MemberProjectLaborMetricsKeySpecifier
)[];
export type MemberProjectLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberSignInAnswerKeySpecifier = (
  | 'answer'
  | 'audienceType'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'flagged'
  | 'id'
  | 'localTime'
  | 'member'
  | 'memberId'
  | 'memberSignInSubmissionId'
  | 'positionId'
  | 'questionDescription'
  | 'questionPrompt'
  | 'questionTitle'
  | 'resolvedComment'
  | 'resolvedOn'
  | 'resolverId'
  | 'resolverMember'
  | 'restricted'
  | 'submission'
  | 'submittedOn'
  | 'updatedOn'
  | MemberSignInAnswerKeySpecifier
)[];
export type MemberSignInAnswerFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  audienceType?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  flagged?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localTime?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  memberSignInSubmissionId?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  questionDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  questionPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  questionTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedComment?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  resolverId?: FieldPolicy<any> | FieldReadFunction<any>;
  resolverMember?: FieldPolicy<any> | FieldReadFunction<any>;
  restricted?: FieldPolicy<any> | FieldReadFunction<any>;
  submission?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberSignInSubmissionKeySpecifier = (
  | 'answers'
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'createdOnOffset'
  | 'cursor'
  | 'deletedOn'
  | 'deviceType'
  | 'flagged'
  | 'id'
  | 'localTime'
  | 'member'
  | 'memberId'
  | 'proxyMember'
  | 'proxyMemberId'
  | 'resolvedOn'
  | 'resolverId'
  | 'restricted'
  | 'signatureId'
  | 'signatureUrl'
  | 'submittedOn'
  | 'updatedOn'
  | MemberSignInSubmissionKeySpecifier
)[];
export type MemberSignInSubmissionFieldPolicy = {
  answers?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnOffset?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  deviceType?: FieldPolicy<any> | FieldReadFunction<any>;
  flagged?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localTime?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMember?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  resolverId?: FieldPolicy<any> | FieldReadFunction<any>;
  restricted?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberSignOffAnswerKeySpecifier = (
  | 'answer'
  | 'audienceType'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'flagged'
  | 'id'
  | 'localTime'
  | 'member'
  | 'memberId'
  | 'positionId'
  | 'questionDescription'
  | 'questionPrompt'
  | 'questionTitle'
  | 'resolvedComment'
  | 'resolvedOn'
  | 'resolverId'
  | 'resolverMember'
  | 'submission'
  | 'submissionId'
  | 'submittedOn'
  | 'updatedOn'
  | MemberSignOffAnswerKeySpecifier
)[];
export type MemberSignOffAnswerFieldPolicy = {
  answer?: FieldPolicy<any> | FieldReadFunction<any>;
  audienceType?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  flagged?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localTime?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  questionDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  questionPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  questionTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedComment?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  resolverId?: FieldPolicy<any> | FieldReadFunction<any>;
  resolverMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submission?: FieldPolicy<any> | FieldReadFunction<any>;
  submissionId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberTimeDocumentKeySpecifier = (
  | 'authoritativeSignature'
  | 'authoritativeSignatureId'
  | 'canceled'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'doubletimeSeconds'
  | 'edited'
  | 'endTime'
  | 'id'
  | 'member'
  | 'memberId'
  | 'overtimeSeconds'
  | 'regularSeconds'
  | 'selfSignature'
  | 'selfSignatureId'
  | 'startTime'
  | 'submittedOn'
  | 'updatedOn'
  | MemberTimeDocumentKeySpecifier
)[];
export type MemberTimeDocumentFieldPolicy = {
  authoritativeSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  authoritativeSignatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  canceled?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  doubletimeSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  edited?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  overtimeSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  regularSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  selfSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  selfSignatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MemberUsageInfoKeySpecifier = ('firstApiAccess' | 'firstTimeTracking' | MemberUsageInfoKeySpecifier)[];
export type MemberUsageInfoFieldPolicy = {
  firstApiAccess?: FieldPolicy<any> | FieldReadFunction<any>;
  firstTimeTracking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'createAuthoritativeSignature'
  | 'createBudgetCost'
  | 'createBudgetHours'
  | 'createClientTimeEntryBreakLog'
  | 'createClientTimeEntryBreakLogs'
  | 'createClientTimeEntryLog'
  | 'createClientTimeEntryLogs'
  | 'createCostCode'
  | 'createCostCodeGroup'
  | 'createDailyProjectReport'
  | 'createDailyProjectReportTemplate'
  | 'createEquipment'
  | 'createEquipmentCategory'
  | 'createEquipmentCostHistory'
  | 'createEquipmentHours'
  | 'createEquipmentMake'
  | 'createEquipmentModel'
  | 'createEquipmentType'
  | 'createFlaggedTimeEntryLocation'
  | 'createFolder'
  | 'createIncident'
  | 'createIncidentAnswer'
  | 'createIncidentMedicalDetails'
  | 'createIncidentOutcomeDetails'
  | 'createInspection'
  | 'createInspectionAssignment'
  | 'createInspectionAssignmentCategoryLink'
  | 'createInspectionAssignmentNotificationLink'
  | 'createInspectionCategory'
  | 'createInspectionChecklist'
  | 'createInspectionChecklistMemberLink'
  | 'createInspectionQuestion'
  | 'createInspectionQuestionAnswer'
  | 'createInspectionQuestionAnswerAttachment'
  | 'createInspectionQuestions'
  | 'createMediaCollection'
  | 'createMediaEntry'
  | 'createMediaEntryComment'
  | 'createMediaEntryLocation'
  | 'createMember'
  | 'createMemberGpsStatus'
  | 'createMemberGroup'
  | 'createMemberLocation'
  | 'createMemberLock'
  | 'createMemberOvertimePeriod'
  | 'createMemberSignInAnswer'
  | 'createMemberSignInSubmission'
  | 'createMemberSignOffAnswer'
  | 'createMemberTimeDocument'
  | 'createObservation'
  | 'createObservationAnswer'
  | 'createObservationAttachment'
  | 'createObservationCategory'
  | 'createOrganizationDocument'
  | 'createOrganizationNotificationPreferences'
  | 'createOrganizationOvertimePeriod'
  | 'createOrganizationPayPeriod'
  | 'createOrganizationSignInQuestion'
  | 'createOrganizationSignOffQuestion'
  | 'createPerDiem'
  | 'createPolicyMemberAcceptance'
  | 'createPosition'
  | 'createPost'
  | 'createPostAttachment'
  | 'createPostComment'
  | 'createPostRecipient'
  | 'createPostViewer'
  | 'createProductRequest'
  | 'createProgressBudget'
  | 'createProject'
  | 'createProjectCostBudget'
  | 'createProjectCostCodeLink'
  | 'createProjectCostCodeQuantity'
  | 'createProjectGroup'
  | 'createProjectHoursBudget'
  | 'createProjectInfo'
  | 'createRecurringInspectionAssignment'
  | 'createRecurringInspectionAssignmentCategoryLink'
  | 'createRecurringInspectionAssignmentNotificationLink'
  | 'createRecurringScheduleReminder'
  | 'createRecurringScheduleReminderLink'
  | 'createSafetyReportsCategory'
  | 'createSafetyReportsSource'
  | 'createSafetyReportsTrainingMaterial'
  | 'createSafetySignature'
  | 'createSafetyTraining'
  | 'createSafetyTrainingAttachment'
  | 'createSafetyTrainingAttendee'
  | 'createScheduleReminder'
  | 'createScheduleReminderLink'
  | 'createSelfSignature'
  | 'createTag'
  | 'createTagDocumentLink'
  | 'createTagMediaCollectionLink'
  | 'createTimeEntries'
  | 'createTimeEntry'
  | 'createTimeEntryBreak'
  | 'createTimeEntryBreaks'
  | 'createTimeOff'
  | 'createTimeOffRequest'
  | 'createTimeOffRequests'
  | 'createTimeOffs'
  | 'createWageHistory'
  | 'updateAuthoritativeSignature'
  | 'updateCostCode'
  | 'updateCostCodeGroup'
  | 'updateDailyProjectReport'
  | 'updateDailyProjectReportTemplate'
  | 'updateEquipment'
  | 'updateEquipmentCategory'
  | 'updateEquipmentCostHistory'
  | 'updateEquipmentMake'
  | 'updateEquipmentModel'
  | 'updateEquipmentType'
  | 'updateFlaggedTimeEntryLocation'
  | 'updateFolder'
  | 'updateIncident'
  | 'updateIncidentAnswer'
  | 'updateIncidentMedicalDetails'
  | 'updateIncidentOutcomeDetails'
  | 'updateInspection'
  | 'updateInspectionAssignment'
  | 'updateInspectionAssignmentCategoryLink'
  | 'updateInspectionAssignmentNotificationLink'
  | 'updateInspectionCategory'
  | 'updateInspectionChecklist'
  | 'updateInspectionChecklistMemberLink'
  | 'updateInspectionQuestion'
  | 'updateInspectionQuestionAnswer'
  | 'updateInspectionQuestionAnswerAttachment'
  | 'updateMediaCollection'
  | 'updateMediaEntry'
  | 'updateMediaEntryComment'
  | 'updateMediaEntryLocation'
  | 'updateMember'
  | 'updateMemberGpsStatus'
  | 'updateMemberGroup'
  | 'updateMemberLocation'
  | 'updateMemberLock'
  | 'updateMemberOvertimePeriod'
  | 'updateMemberSignInAnswer'
  | 'updateMemberSignInSubmission'
  | 'updateMemberSignOffAnswer'
  | 'updateMemberTimeDocument'
  | 'updateObservation'
  | 'updateObservationAnswer'
  | 'updateObservationAttachment'
  | 'updateObservationCategory'
  | 'updateOrganization'
  | 'updateOrganizationDocument'
  | 'updateOrganizationInfo'
  | 'updateOrganizationNotificationPreferences'
  | 'updateOrganizationOvertimePeriod'
  | 'updateOrganizationPayPeriod'
  | 'updateOrganizationSignInQuestion'
  | 'updateOrganizationSignOffQuestion'
  | 'updatePerDiem'
  | 'updatePosition'
  | 'updatePost'
  | 'updatePostAttachment'
  | 'updatePostComment'
  | 'updatePostRecipient'
  | 'updatePostViewer'
  | 'updateProgressBudget'
  | 'updateProject'
  | 'updateProjectCostCodeLink'
  | 'updateProjectCostCodeQuantity'
  | 'updateProjectGroup'
  | 'updateProjectInfo'
  | 'updateRecurringInspectionAssignment'
  | 'updateRecurringInspectionAssignmentCategoryLink'
  | 'updateRecurringInspectionAssignmentNotificationLink'
  | 'updateRecurringScheduleReminder'
  | 'updateRecurringScheduleReminderLink'
  | 'updateSafetyReportsCategory'
  | 'updateSafetyReportsSource'
  | 'updateSafetyReportsTrainingMaterial'
  | 'updateSafetySignature'
  | 'updateSafetyTraining'
  | 'updateSafetyTrainingAttachment'
  | 'updateSafetyTrainingAttendee'
  | 'updateScheduleReminder'
  | 'updateScheduleReminderLink'
  | 'updateSelfSignature'
  | 'updateTag'
  | 'updateTagDocumentLink'
  | 'updateTagMediaCollectionLink'
  | 'updateTimeEntries'
  | 'updateTimeEntry'
  | 'updateTimeEntryBreak'
  | 'updateTimeEntryBreaks'
  | 'updateTimeOff'
  | 'updateTimeOffRequest'
  | 'updateTimeOffRequests'
  | 'updateTimeOffs'
  | 'updateWageHistory'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  createAuthoritativeSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  createBudgetCost?: FieldPolicy<any> | FieldReadFunction<any>;
  createBudgetHours?: FieldPolicy<any> | FieldReadFunction<any>;
  createClientTimeEntryBreakLog?: FieldPolicy<any> | FieldReadFunction<any>;
  createClientTimeEntryBreakLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  createClientTimeEntryLog?: FieldPolicy<any> | FieldReadFunction<any>;
  createClientTimeEntryLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  createCostCode?: FieldPolicy<any> | FieldReadFunction<any>;
  createCostCodeGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  createDailyProjectReport?: FieldPolicy<any> | FieldReadFunction<any>;
  createDailyProjectReportTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipment?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipmentCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipmentCostHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipmentHours?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipmentMake?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipmentModel?: FieldPolicy<any> | FieldReadFunction<any>;
  createEquipmentType?: FieldPolicy<any> | FieldReadFunction<any>;
  createFlaggedTimeEntryLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  createFolder?: FieldPolicy<any> | FieldReadFunction<any>;
  createIncident?: FieldPolicy<any> | FieldReadFunction<any>;
  createIncidentAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  createIncidentMedicalDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  createIncidentOutcomeDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionAssignment?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionAssignmentCategoryLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionAssignmentNotificationLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionChecklist?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionChecklistMemberLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionQuestionAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionQuestionAnswerAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  createInspectionQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  createMediaCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  createMediaEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  createMediaEntryComment?: FieldPolicy<any> | FieldReadFunction<any>;
  createMediaEntryLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  createMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberGpsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberLock?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberOvertimePeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberSignInAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberSignInSubmission?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberSignOffAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  createMemberTimeDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  createObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  createObservationAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  createObservationAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  createObservationCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrganizationDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrganizationNotificationPreferences?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrganizationOvertimePeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrganizationPayPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrganizationSignInQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  createOrganizationSignOffQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  createPerDiem?: FieldPolicy<any> | FieldReadFunction<any>;
  createPolicyMemberAcceptance?: FieldPolicy<any> | FieldReadFunction<any>;
  createPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  createPost?: FieldPolicy<any> | FieldReadFunction<any>;
  createPostAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  createPostComment?: FieldPolicy<any> | FieldReadFunction<any>;
  createPostRecipient?: FieldPolicy<any> | FieldReadFunction<any>;
  createPostViewer?: FieldPolicy<any> | FieldReadFunction<any>;
  createProductRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  createProgressBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  createProject?: FieldPolicy<any> | FieldReadFunction<any>;
  createProjectCostBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  createProjectCostCodeLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createProjectCostCodeQuantity?: FieldPolicy<any> | FieldReadFunction<any>;
  createProjectGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  createProjectHoursBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  createProjectInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  createRecurringInspectionAssignment?: FieldPolicy<any> | FieldReadFunction<any>;
  createRecurringInspectionAssignmentCategoryLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createRecurringInspectionAssignmentNotificationLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createRecurringScheduleReminder?: FieldPolicy<any> | FieldReadFunction<any>;
  createRecurringScheduleReminderLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetyReportsCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetyReportsSource?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetyReportsTrainingMaterial?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetySignature?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetyTraining?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetyTrainingAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  createSafetyTrainingAttendee?: FieldPolicy<any> | FieldReadFunction<any>;
  createScheduleReminder?: FieldPolicy<any> | FieldReadFunction<any>;
  createScheduleReminderLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createSelfSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  createTag?: FieldPolicy<any> | FieldReadFunction<any>;
  createTagDocumentLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createTagMediaCollectionLink?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeEntryBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeEntryBreaks?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeOff?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeOffRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeOffRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  createTimeOffs?: FieldPolicy<any> | FieldReadFunction<any>;
  createWageHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateAuthoritativeSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCostCode?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCostCodeGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDailyProjectReport?: FieldPolicy<any> | FieldReadFunction<any>;
  updateDailyProjectReportTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEquipment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEquipmentCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEquipmentCostHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEquipmentMake?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEquipmentModel?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEquipmentType?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFlaggedTimeEntryLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFolder?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIncident?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIncidentAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIncidentMedicalDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  updateIncidentOutcomeDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspection?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionAssignment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionAssignmentCategoryLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionAssignmentNotificationLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionChecklist?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionChecklistMemberLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionQuestionAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInspectionQuestionAnswerAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMediaCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMediaEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMediaEntryComment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMediaEntryLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberGpsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberLock?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberOvertimePeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberSignInAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberSignInSubmission?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberSignOffAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMemberTimeDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  updateObservation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateObservationAnswer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateObservationAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateObservationCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganization?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationDocument?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationNotificationPreferences?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationOvertimePeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationPayPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationSignInQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganizationSignOffQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePerDiem?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePosition?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePost?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePostAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePostComment?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePostRecipient?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePostViewer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProgressBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProject?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProjectCostCodeLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProjectCostCodeQuantity?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProjectGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProjectInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecurringInspectionAssignment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecurringInspectionAssignmentCategoryLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecurringInspectionAssignmentNotificationLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecurringScheduleReminder?: FieldPolicy<any> | FieldReadFunction<any>;
  updateRecurringScheduleReminderLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetyReportsCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetyReportsSource?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetyReportsTrainingMaterial?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetySignature?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetyTraining?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetyTrainingAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSafetyTrainingAttendee?: FieldPolicy<any> | FieldReadFunction<any>;
  updateScheduleReminder?: FieldPolicy<any> | FieldReadFunction<any>;
  updateScheduleReminderLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateSelfSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTag?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTagDocumentLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTagMediaCollectionLink?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeEntryBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeEntryBreaks?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeOff?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeOffRequest?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeOffRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTimeOffs?: FieldPolicy<any> | FieldReadFunction<any>;
  updateWageHistory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ObservationKeySpecifier = (
  | 'actionDescription'
  | 'answers'
  | 'attachments'
  | 'createdOn'
  | 'cursor'
  | 'date'
  | 'deletedOn'
  | 'id'
  | 'isAnonymous'
  | 'latitude'
  | 'locationDetails'
  | 'longitude'
  | 'observationDescription'
  | 'organizationId'
  | 'project'
  | 'projectId'
  | 'reportedBy'
  | 'reportedByMember'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | ObservationKeySpecifier
)[];
export type ObservationFieldPolicy = {
  actionDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  answers?: FieldPolicy<any> | FieldReadFunction<any>;
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isAnonymous?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  locationDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  observationDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  reportedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  reportedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ObservationAnswerKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'observation'
  | 'observationCategory'
  | 'observationCategoryId'
  | 'observationId'
  | 'processedBy'
  | 'processedByMember'
  | 'processedDescription'
  | 'processedOn'
  | 'severity'
  | 'submittedOn'
  | 'updatedOn'
  | ObservationAnswerKeySpecifier
)[];
export type ObservationAnswerFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  observationCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  observationCategoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  observationId?: FieldPolicy<any> | FieldReadFunction<any>;
  processedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  processedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  processedDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  processedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  severity?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ObservationAttachmentKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'fileType'
  | 'fileUrl'
  | 'id'
  | 'observation'
  | 'observationId'
  | 'submittedOn'
  | 'title'
  | 'updatedOn'
  | 'uploadedBy'
  | 'uploadedByMember'
  | ObservationAttachmentKeySpecifier
)[];
export type ObservationAttachmentFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  fileType?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  observation?: FieldPolicy<any> | FieldReadFunction<any>;
  observationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ObservationCategoryKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'name'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | ObservationCategoryKeySpecifier
)[];
export type ObservationCategoryFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationKeySpecifier = (
  | 'authoritativeSignature'
  | 'betaTester'
  | 'breakPolicy'
  | 'breakPolicyCalifornia'
  | 'breakPolicyMessage'
  | 'bulletin'
  | 'busySignInQuestion'
  | 'createdOn'
  | 'cursor'
  | 'customSignOff'
  | 'defaultBreakDuration'
  | 'defaultEndTime'
  | 'defaultStartTime'
  | 'deletedOn'
  | 'demo'
  | 'disclaimer'
  | 'employeeAutoInvite'
  | 'gustoCompanyId'
  | 'hideUnassignedSubprojects'
  | 'id'
  | 'imageId'
  | 'inRecoveryMode'
  | 'lockOnSelfSignature'
  | 'minBreakSeconds'
  | 'minimumAppVersion'
  | 'organizationInfo'
  | 'organizationName'
  | 'organizationOvertimePeriods'
  | 'organizationPayPeriod'
  | 'organizationSettings'
  | 'overtimeFlag'
  | 'ownedBy'
  | 'perDiem'
  | 'perDiemAmountTracking'
  | 'perDiemRequestPermission'
  | 'performanceScores'
  | 'requireCostCode'
  | 'requireProject'
  | 'revokedOn'
  | 'safetySignature'
  | 'safetySignatureMessage'
  | 'signatureDate'
  | 'stripeAccountCreatedOn'
  | 'stripeAccountStatus'
  | 'stripeAccountType'
  | 'stripeCustomerId'
  | 'submittedOn'
  | 'subscriptionStatus'
  | 'timeAccuracy'
  | 'timeAccuracyMessage'
  | 'timeOffRequests'
  | 'timeRounding'
  | 'timeRoundingInterval'
  | 'timeRoundingType'
  | 'totalTimeFlag'
  | 'trackBudgetCost'
  | 'trackBudgetEquipmentCosts'
  | 'trackBudgetEquipmentHours'
  | 'trackBudgetProgress'
  | 'trackBudgetProgressValue'
  | 'trackBudgets'
  | 'trackCostCode'
  | 'trackEquipment'
  | 'trackPaidBreak'
  | 'trackProject'
  | 'updatedOn'
  | 'useProjectCostCodeScoping'
  | 'webGps'
  | OrganizationKeySpecifier
)[];
export type OrganizationFieldPolicy = {
  authoritativeSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  betaTester?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicy?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyCalifornia?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  bulletin?: FieldPolicy<any> | FieldReadFunction<any>;
  busySignInQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  customSignOff?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultBreakDuration?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultEndTime?: FieldPolicy<any> | FieldReadFunction<any>;
  defaultStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  demo?: FieldPolicy<any> | FieldReadFunction<any>;
  disclaimer?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeAutoInvite?: FieldPolicy<any> | FieldReadFunction<any>;
  gustoCompanyId?: FieldPolicy<any> | FieldReadFunction<any>;
  hideUnassignedSubprojects?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  inRecoveryMode?: FieldPolicy<any> | FieldReadFunction<any>;
  lockOnSelfSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  minBreakSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  minimumAppVersion?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationName?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationOvertimePeriods?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationPayPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  overtimeFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  ownedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  perDiem?: FieldPolicy<any> | FieldReadFunction<any>;
  perDiemAmountTracking?: FieldPolicy<any> | FieldReadFunction<any>;
  perDiemRequestPermission?: FieldPolicy<any> | FieldReadFunction<any>;
  performanceScores?: FieldPolicy<any> | FieldReadFunction<any>;
  requireCostCode?: FieldPolicy<any> | FieldReadFunction<any>;
  requireProject?: FieldPolicy<any> | FieldReadFunction<any>;
  revokedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  safetySignature?: FieldPolicy<any> | FieldReadFunction<any>;
  safetySignatureMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureDate?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeAccountCreatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeAccountStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeAccountType?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeCustomerId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccuracy?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccuracyMessage?: FieldPolicy<any> | FieldReadFunction<any>;
  timeOffRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  timeRounding?: FieldPolicy<any> | FieldReadFunction<any>;
  timeRoundingInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  timeRoundingType?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTimeFlag?: FieldPolicy<any> | FieldReadFunction<any>;
  trackBudgetCost?: FieldPolicy<any> | FieldReadFunction<any>;
  trackBudgetEquipmentCosts?: FieldPolicy<any> | FieldReadFunction<any>;
  trackBudgetEquipmentHours?: FieldPolicy<any> | FieldReadFunction<any>;
  trackBudgetProgress?: FieldPolicy<any> | FieldReadFunction<any>;
  trackBudgetProgressValue?: FieldPolicy<any> | FieldReadFunction<any>;
  trackBudgets?: FieldPolicy<any> | FieldReadFunction<any>;
  trackCostCode?: FieldPolicy<any> | FieldReadFunction<any>;
  trackEquipment?: FieldPolicy<any> | FieldReadFunction<any>;
  trackPaidBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  trackProject?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  useProjectCostCodeScoping?: FieldPolicy<any> | FieldReadFunction<any>;
  webGps?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationDocumentKeySpecifier = (
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'fileType'
  | 'fileUrl'
  | 'folder'
  | 'folderId'
  | 'id'
  | 'lastUpdatedOn'
  | 'limitAccess'
  | 'organizationId'
  | 'position'
  | 'positionId'
  | 'project'
  | 'projectId'
  | 'submittedOn'
  | 'tagLinks'
  | 'title'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | OrganizationDocumentKeySpecifier
)[];
export type OrganizationDocumentFieldPolicy = {
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  fileType?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  folder?: FieldPolicy<any> | FieldReadFunction<any>;
  folderId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  limitAccess?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  tagLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationInfoKeySpecifier = (
  | 'address1'
  | 'address2'
  | 'city'
  | 'country'
  | 'county'
  | 'createdOn'
  | 'currencyCode'
  | 'cursor'
  | 'deletedOn'
  | 'employeeMax'
  | 'employeeMin'
  | 'id'
  | 'latitude'
  | 'longitude'
  | 'organizationId'
  | 'phone'
  | 'postalCode'
  | 'state'
  | 'submittedOn'
  | 'trade'
  | 'updatedOn'
  | OrganizationInfoKeySpecifier
)[];
export type OrganizationInfoFieldPolicy = {
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  county?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyCode?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeMax?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  trade?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'pto'
  | 'ptoCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | 'upto'
  | OrganizationLaborMetricsKeySpecifier
)[];
export type OrganizationLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  pto?: FieldPolicy<any> | FieldReadFunction<any>;
  ptoCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  upto?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationNotificationPreferencesKeySpecifier = (
  | 'breakReport'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'inaccurateTimeReport'
  | 'injuryReport'
  | 'member'
  | 'memberId'
  | 'submittedOn'
  | 'updatedOn'
  | OrganizationNotificationPreferencesKeySpecifier
)[];
export type OrganizationNotificationPreferencesFieldPolicy = {
  breakReport?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inaccurateTimeReport?: FieldPolicy<any> | FieldReadFunction<any>;
  injuryReport?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationOvertimePeriodKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'dailyDoubletime'
  | 'dailyOvertime'
  | 'deletedOn'
  | 'id'
  | 'lengthInDays'
  | 'organizationId'
  | 'saturdayMultiplier'
  | 'seventhDayDoubletime'
  | 'seventhDayOvertime'
  | 'startTime'
  | 'submittedOn'
  | 'sundayMultiplier'
  | 'updatedOn'
  | 'weeklyOvertime'
  | OrganizationOvertimePeriodKeySpecifier
)[];
export type OrganizationOvertimePeriodFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyDoubletime?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyOvertime?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lengthInDays?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  saturdayMultiplier?: FieldPolicy<any> | FieldReadFunction<any>;
  seventhDayDoubletime?: FieldPolicy<any> | FieldReadFunction<any>;
  seventhDayOvertime?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  sundayMultiplier?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  weeklyOvertime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationPayPeriodKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'dayOne'
  | 'dayTwo'
  | 'deletedOn'
  | 'id'
  | 'organizationId'
  | 'payPeriodType'
  | 'startDate'
  | 'submittedOn'
  | 'updatedOn'
  | OrganizationPayPeriodKeySpecifier
)[];
export type OrganizationPayPeriodFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dayOne?: FieldPolicy<any> | FieldReadFunction<any>;
  dayTwo?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  payPeriodType?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationSignInQuestionKeySpecifier = (
  | 'audienceType'
  | 'choices'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'order'
  | 'organizationId'
  | 'positionId'
  | 'questionDescription'
  | 'questionPrompt'
  | 'questionTitle'
  | 'required'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | OrganizationSignInQuestionKeySpecifier
)[];
export type OrganizationSignInQuestionFieldPolicy = {
  audienceType?: FieldPolicy<any> | FieldReadFunction<any>;
  choices?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  questionDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  questionPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  questionTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  required?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationSignOffQuestionKeySpecifier = (
  | 'audienceType'
  | 'choices'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'order'
  | 'organizationId'
  | 'positionId'
  | 'questionDescription'
  | 'questionPrompt'
  | 'questionTitle'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | OrganizationSignOffQuestionKeySpecifier
)[];
export type OrganizationSignOffQuestionFieldPolicy = {
  audienceType?: FieldPolicy<any> | FieldReadFunction<any>;
  choices?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  questionDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  questionPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  questionTitle?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationSubscriptionStatusKeySpecifier = (
  | 'annualSubscriptionCount'
  | 'annualSubscriptionDate'
  | 'annualSubscriptionInterval'
  | 'annualSubscriptionPendingInvoiceId'
  | 'cardLastFour'
  | 'cardType'
  | 'couponCode'
  | 'createdOn'
  | 'customerId'
  | 'deletedOn'
  | 'exempt'
  | 'hasTrialed'
  | 'id'
  | 'nextAssessmentAt'
  | 'organizationId'
  | 'periodDay'
  | 'pricePointAction'
  | 'pricePointHandle'
  | 'productHandle'
  | 'referralCode'
  | 'status'
  | 'submittedOn'
  | 'subscribedThroughProduct'
  | 'subscriptionId'
  | 'subscriptionUpdatedAt'
  | 'trialEndedAt'
  | 'updatedOn'
  | 'userCanceled'
  | OrganizationSubscriptionStatusKeySpecifier
)[];
export type OrganizationSubscriptionStatusFieldPolicy = {
  annualSubscriptionCount?: FieldPolicy<any> | FieldReadFunction<any>;
  annualSubscriptionDate?: FieldPolicy<any> | FieldReadFunction<any>;
  annualSubscriptionInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  annualSubscriptionPendingInvoiceId?: FieldPolicy<any> | FieldReadFunction<any>;
  cardLastFour?: FieldPolicy<any> | FieldReadFunction<any>;
  cardType?: FieldPolicy<any> | FieldReadFunction<any>;
  couponCode?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  exempt?: FieldPolicy<any> | FieldReadFunction<any>;
  hasTrialed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nextAssessmentAt?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  periodDay?: FieldPolicy<any> | FieldReadFunction<any>;
  pricePointAction?: FieldPolicy<any> | FieldReadFunction<any>;
  pricePointHandle?: FieldPolicy<any> | FieldReadFunction<any>;
  productHandle?: FieldPolicy<any> | FieldReadFunction<any>;
  referralCode?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  subscribedThroughProduct?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionUpdatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  trialEndedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  userCanceled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PerDiemKeySpecifier = (
  | 'amount'
  | 'answeredBy'
  | 'answeredByMember'
  | 'answeredOn'
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'dateStamp'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'member'
  | 'memberId'
  | 'organizationId'
  | 'project'
  | 'projectId'
  | 'rejectionReason'
  | 'requestedAmount'
  | 'state'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'updatedOn'
  | PerDiemKeySpecifier
)[];
export type PerDiemFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  answeredBy?: FieldPolicy<any> | FieldReadFunction<any>;
  answeredByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  answeredOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dateStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rejectionReason?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PolicyKeySpecifier = (
  | 'contentUrl'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'effectiveDate'
  | 'id'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | 'version'
  | PolicyKeySpecifier
)[];
export type PolicyFieldPolicy = {
  contentUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  effectiveDate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PolicyMemberAcceptanceKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'deviceType'
  | 'id'
  | 'member'
  | 'memberId'
  | 'policy'
  | 'policyId'
  | 'proxyMember'
  | 'proxyMemberId'
  | 'signatureId'
  | 'signatureUrl'
  | 'submittedOn'
  | 'updatedOn'
  | PolicyMemberAcceptanceKeySpecifier
)[];
export type PolicyMemberAcceptanceFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  deviceType?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  policy?: FieldPolicy<any> | FieldReadFunction<any>;
  policyId?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMember?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PositionKeySpecifier = (
  | 'canEditPosition'
  | 'canManageProjectCostCodeQuantity'
  | 'canRequestTimeOff'
  | 'clockInRestriction'
  | 'clockInRestrictionStartTime'
  | 'createIncident'
  | 'createdOn'
  | 'cursor'
  | 'dailyProjectPrompts'
  | 'dailyProjectPromptsUseSubprojects'
  | 'deletedOn'
  | 'equipmentSelectionRequirement'
  | 'id'
  | 'jobCostingPrompt'
  | 'level'
  | 'manageBudgets'
  | 'manageBulletinPosts'
  | 'manageCompanySettings'
  | 'manageCostCodes'
  | 'manageEmployeeGroups'
  | 'manageEmployees'
  | 'manageEquipment'
  | 'manageGpsApproval'
  | 'manageIncident'
  | 'manageInspections'
  | 'manageMediaCollection'
  | 'manageObservations'
  | 'managePerDiem'
  | 'manageProjectGroups'
  | 'manageProjects'
  | 'manageSchedule'
  | 'manageTimeEntries'
  | 'manageTimeOff'
  | 'manageTraining'
  | 'memberLock'
  | 'organizationId'
  | 'performInspections'
  | 'performTraining'
  | 'photoVerificationSettings'
  | 'reportObservations'
  | 'requestSignatures'
  | 'scopeByGroup'
  | 'submittedOn'
  | 'timeEvents'
  | 'title'
  | 'updatedOn'
  | 'useKiosk'
  | 'viewAllActivityReport'
  | 'viewBudgets'
  | 'viewEquipmentDetails'
  | 'viewEquipmentStatus'
  | 'viewMediaCollection'
  | 'viewMemberActivityReport'
  | 'viewMemberExpenses'
  | 'viewProjectExpenses'
  | 'viewSchedule'
  | PositionKeySpecifier
)[];
export type PositionFieldPolicy = {
  canEditPosition?: FieldPolicy<any> | FieldReadFunction<any>;
  canManageProjectCostCodeQuantity?: FieldPolicy<any> | FieldReadFunction<any>;
  canRequestTimeOff?: FieldPolicy<any> | FieldReadFunction<any>;
  clockInRestriction?: FieldPolicy<any> | FieldReadFunction<any>;
  clockInRestrictionStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  createIncident?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyProjectPrompts?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyProjectPromptsUseSubprojects?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentSelectionRequirement?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  jobCostingPrompt?: FieldPolicy<any> | FieldReadFunction<any>;
  level?: FieldPolicy<any> | FieldReadFunction<any>;
  manageBudgets?: FieldPolicy<any> | FieldReadFunction<any>;
  manageBulletinPosts?: FieldPolicy<any> | FieldReadFunction<any>;
  manageCompanySettings?: FieldPolicy<any> | FieldReadFunction<any>;
  manageCostCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  manageEmployeeGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  manageEmployees?: FieldPolicy<any> | FieldReadFunction<any>;
  manageEquipment?: FieldPolicy<any> | FieldReadFunction<any>;
  manageGpsApproval?: FieldPolicy<any> | FieldReadFunction<any>;
  manageIncident?: FieldPolicy<any> | FieldReadFunction<any>;
  manageInspections?: FieldPolicy<any> | FieldReadFunction<any>;
  manageMediaCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  manageObservations?: FieldPolicy<any> | FieldReadFunction<any>;
  managePerDiem?: FieldPolicy<any> | FieldReadFunction<any>;
  manageProjectGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  manageProjects?: FieldPolicy<any> | FieldReadFunction<any>;
  manageSchedule?: FieldPolicy<any> | FieldReadFunction<any>;
  manageTimeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  manageTimeOff?: FieldPolicy<any> | FieldReadFunction<any>;
  manageTraining?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLock?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  performInspections?: FieldPolicy<any> | FieldReadFunction<any>;
  performTraining?: FieldPolicy<any> | FieldReadFunction<any>;
  photoVerificationSettings?: FieldPolicy<any> | FieldReadFunction<any>;
  reportObservations?: FieldPolicy<any> | FieldReadFunction<any>;
  requestSignatures?: FieldPolicy<any> | FieldReadFunction<any>;
  scopeByGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  useKiosk?: FieldPolicy<any> | FieldReadFunction<any>;
  viewAllActivityReport?: FieldPolicy<any> | FieldReadFunction<any>;
  viewBudgets?: FieldPolicy<any> | FieldReadFunction<any>;
  viewEquipmentDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  viewEquipmentStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  viewMediaCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  viewMemberActivityReport?: FieldPolicy<any> | FieldReadFunction<any>;
  viewMemberExpenses?: FieldPolicy<any> | FieldReadFunction<any>;
  viewProjectExpenses?: FieldPolicy<any> | FieldReadFunction<any>;
  viewSchedule?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostKeySpecifier = (
  | 'acknowledgementRequired'
  | 'allowComments'
  | 'attachments'
  | 'comments'
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'equipment'
  | 'equipmentId'
  | 'id'
  | 'managementCanAccess'
  | 'message'
  | 'organizationId'
  | 'position'
  | 'positionId'
  | 'postDate'
  | 'project'
  | 'projectId'
  | 'recipientType'
  | 'recipients'
  | 'submittedOn'
  | 'title'
  | 'type'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | PostKeySpecifier
)[];
export type PostFieldPolicy = {
  acknowledgementRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  allowComments?: FieldPolicy<any> | FieldReadFunction<any>;
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  comments?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  managementCanAccess?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  positionId?: FieldPolicy<any> | FieldReadFunction<any>;
  postDate?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  recipientType?: FieldPolicy<any> | FieldReadFunction<any>;
  recipients?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostAttachmentKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'fileType'
  | 'fileUrl'
  | 'id'
  | 'organizationId'
  | 'post'
  | 'postId'
  | 'submittedOn'
  | 'title'
  | 'updatedOn'
  | 'uploadedBy'
  | 'uploadedByMember'
  | PostAttachmentKeySpecifier
)[];
export type PostAttachmentFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  fileType?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  post?: FieldPolicy<any> | FieldReadFunction<any>;
  postId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  uploadedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostCommentKeySpecifier = (
  | 'comment'
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'organizationId'
  | 'post'
  | 'postId'
  | 'submittedOn'
  | 'updatedOn'
  | PostCommentKeySpecifier
)[];
export type PostCommentFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  post?: FieldPolicy<any> | FieldReadFunction<any>;
  postId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostRecipientKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'organizationId'
  | 'post'
  | 'postId'
  | 'submittedOn'
  | 'updatedOn'
  | PostRecipientKeySpecifier
)[];
export type PostRecipientFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  post?: FieldPolicy<any> | FieldReadFunction<any>;
  postId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostStatusKeySpecifier = ('unreadComments' | 'unreadPosts' | PostStatusKeySpecifier)[];
export type PostStatusFieldPolicy = {
  unreadComments?: FieldPolicy<any> | FieldReadFunction<any>;
  unreadPosts?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PostViewerKeySpecifier = (
  | 'acknowledged'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'latestCommentUpdatedOn'
  | 'member'
  | 'memberId'
  | 'organizationId'
  | 'post'
  | 'postId'
  | 'submittedOn'
  | 'updatedOn'
  | PostViewerKeySpecifier
)[];
export type PostViewerFieldPolicy = {
  acknowledged?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  latestCommentUpdatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  post?: FieldPolicy<any> | FieldReadFunction<any>;
  postId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProgressBudgetKeySpecifier = (
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'project'
  | 'projectId'
  | 'quantity'
  | 'submittedOn'
  | 'updatedOn'
  | 'value'
  | ProgressBudgetKeySpecifier
)[];
export type ProgressBudgetFieldPolicy = {
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  quantity?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectKeySpecifier = (
  | 'allAccess'
  | 'ancestors'
  | 'archivedOn'
  | 'children'
  | 'createdOn'
  | 'cursor'
  | 'depth'
  | 'id'
  | 'openTimeEntries'
  | 'organizationId'
  | 'parentProject'
  | 'parentProjectId'
  | 'projectCostCodeEquipmentLaborMetrics'
  | 'projectCostCodeLaborMetrics'
  | 'projectCostCodeLink'
  | 'projectEquipmentLaborMetrics'
  | 'projectGroup'
  | 'projectGroupId'
  | 'projectInfo'
  | 'projectLaborMetrics'
  | 'projectMemberCostCodeEquipmentLaborMetrics'
  | 'projectMemberCostCodeLaborMetrics'
  | 'projectMemberEquipmentLaborMetrics'
  | 'projectMemberLaborMetrics'
  | 'rootProject'
  | 'rootProjectId'
  | 'submittedOn'
  | 'taskLoggable'
  | 'timeLoggable'
  | 'title'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | ProjectKeySpecifier
)[];
export type ProjectFieldPolicy = {
  allAccess?: FieldPolicy<any> | FieldReadFunction<any>;
  ancestors?: FieldPolicy<any> | FieldReadFunction<any>;
  archivedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  children?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  depth?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  openTimeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  parentProject?: FieldPolicy<any> | FieldReadFunction<any>;
  parentProjectId?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeLink?: FieldPolicy<any> | FieldReadFunction<any>;
  projectEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  projectGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  projectInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  projectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectMemberCostCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectMemberCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectMemberEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectMemberLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  rootProject?: FieldPolicy<any> | FieldReadFunction<any>;
  rootProjectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  taskLoggable?: FieldPolicy<any> | FieldReadFunction<any>;
  timeLoggable?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectClockStateKeySpecifier = (
  | 'all'
  | 'clockedIn'
  | 'clockedInNotOnBreak'
  | 'clockedOut'
  | 'onBreak'
  | ProjectClockStateKeySpecifier
)[];
export type ProjectClockStateFieldPolicy = {
  all?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedIn?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedInNotOnBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  clockedOut?: FieldPolicy<any> | FieldReadFunction<any>;
  onBreak?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectCostBudgetKeySpecifier = (
  | 'costBudget'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'project'
  | 'projectId'
  | 'submittedOn'
  | 'updatedOn'
  | ProjectCostBudgetKeySpecifier
)[];
export type ProjectCostBudgetFieldPolicy = {
  costBudget?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectCostCodeEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descEquipmentCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | ProjectCostCodeEquipmentLaborMetricsKeySpecifier
)[];
export type ProjectCostCodeEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descEquipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectCostCodeLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'costCodeId'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | ProjectCostCodeLaborMetricsKeySpecifier
)[];
export type ProjectCostCodeLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectCostCodeLinkKeySpecifier = (
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'project'
  | 'projectId'
  | 'submittedOn'
  | 'updatedOn'
  | ProjectCostCodeLinkKeySpecifier
)[];
export type ProjectCostCodeLinkFieldPolicy = {
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectCostCodeQuantityKeySpecifier = (
  | 'amount'
  | 'assignedOn'
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'lastEditedBy'
  | 'lastEditedByMember'
  | 'project'
  | 'projectId'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | ProjectCostCodeQuantityKeySpecifier
)[];
export type ProjectCostCodeQuantityFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  assignedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastEditedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  lastEditedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectEquipmentLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descEquipmentCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'equipmentCents'
  | 'equipmentId'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | ProjectEquipmentLaborMetricsKeySpecifier
)[];
export type ProjectEquipmentLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descEquipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCents?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectGroupKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'groupName'
  | 'id'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | ProjectGroupKeySpecifier
)[];
export type ProjectGroupFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  groupName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectHoursBudgetKeySpecifier = (
  | 'budgetSeconds'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'project'
  | 'projectId'
  | 'submittedOn'
  | 'updatedOn'
  | ProjectHoursBudgetKeySpecifier
)[];
export type ProjectHoursBudgetFieldPolicy = {
  budgetSeconds?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectInfoKeySpecifier = (
  | 'additionalInfo'
  | 'address1'
  | 'address2'
  | 'city'
  | 'country'
  | 'county'
  | 'createdOn'
  | 'cursor'
  | 'customer'
  | 'deletedOn'
  | 'distantLocationFlaggingDisabled'
  | 'id'
  | 'latitude'
  | 'locationRadius'
  | 'longitude'
  | 'number'
  | 'phone'
  | 'postalCode'
  | 'project'
  | 'projectId'
  | 'reminder'
  | 'requireTimeEntryGps'
  | 'state'
  | 'submittedOn'
  | 'updatedOn'
  | ProjectInfoKeySpecifier
)[];
export type ProjectInfoFieldPolicy = {
  additionalInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  address1?: FieldPolicy<any> | FieldReadFunction<any>;
  address2?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  county?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  customer?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  distantLocationFlaggingDisabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  locationRadius?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  number?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  reminder?: FieldPolicy<any> | FieldReadFunction<any>;
  requireTimeEntryGps?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | ProjectLaborMetricsKeySpecifier
)[];
export type ProjectLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectLaborMetricsInterfaceKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'rt'
  | 'rtCents'
  | 'start'
  | ProjectLaborMetricsInterfaceKeySpecifier
)[];
export type ProjectLaborMetricsInterfaceFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProjectMemberLaborMetricsKeySpecifier = (
  | 'breakDt'
  | 'breakDtCents'
  | 'breakOt'
  | 'breakOtCents'
  | 'breakOverburdenCents'
  | 'breakRt'
  | 'breakRtCents'
  | 'breakUpt'
  | 'descBreakDt'
  | 'descBreakDtCents'
  | 'descBreakOt'
  | 'descBreakOtCents'
  | 'descBreakOverburdenCents'
  | 'descBreakRt'
  | 'descBreakRtCents'
  | 'descBreakUpt'
  | 'descDt'
  | 'descDtCents'
  | 'descOt'
  | 'descOtCents'
  | 'descOverburdenDtCents'
  | 'descOverburdenOtCents'
  | 'descOverburdenRtCents'
  | 'descRt'
  | 'descRtCents'
  | 'dt'
  | 'dtCents'
  | 'end'
  | 'id'
  | 'memberId'
  | 'ot'
  | 'otCents'
  | 'overburdenDtCents'
  | 'overburdenOtCents'
  | 'overburdenRtCents'
  | 'projectId'
  | 'rt'
  | 'rtCents'
  | 'start'
  | ProjectMemberLaborMetricsKeySpecifier
)[];
export type ProjectMemberLaborMetricsFieldPolicy = {
  breakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  breakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  breakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakOverburdenCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descBreakUpt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDt?: FieldPolicy<any> | FieldReadFunction<any>;
  descDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOt?: FieldPolicy<any> | FieldReadFunction<any>;
  descOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descOverburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  descRt?: FieldPolicy<any> | FieldReadFunction<any>;
  descRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  dt?: FieldPolicy<any> | FieldReadFunction<any>;
  dtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  ot?: FieldPolicy<any> | FieldReadFunction<any>;
  otCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenDtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenOtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  overburdenRtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  rt?: FieldPolicy<any> | FieldReadFunction<any>;
  rtCents?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'authoritativeSignatures'
  | 'budgetCosts'
  | 'budgetHours'
  | 'busySignInQuestions'
  | 'clientTimeEntryBreakLogs'
  | 'clientTimeEntryLogs'
  | 'costCodeClockState'
  | 'costCodeGroups'
  | 'costCodeLaborMetrics'
  | 'costCodes'
  | 'dailyProjectReportTemplates'
  | 'dailyProjectReports'
  | 'equipment'
  | 'equipmentCategories'
  | 'equipmentClockState'
  | 'equipmentCostHistory'
  | 'equipmentHours'
  | 'equipmentLaborMetrics'
  | 'equipmentLocations'
  | 'equipmentMakes'
  | 'equipmentModelTitles'
  | 'equipmentModels'
  | 'equipmentTypes'
  | 'flaggedTimeEntryLocations'
  | 'folders'
  | 'incidentAnswers'
  | 'incidentMedicalDetails'
  | 'incidentOutcomeDetails'
  | 'incidents'
  | 'inspectionAssignmentCategoryLinks'
  | 'inspectionAssignmentNotificationLinks'
  | 'inspectionAssignments'
  | 'inspectionCategories'
  | 'inspectionChecklistMemberLinks'
  | 'inspectionChecklists'
  | 'inspectionQuestionAnswerAttachments'
  | 'inspectionQuestionAnswers'
  | 'inspectionQuestions'
  | 'inspections'
  | 'mediaCollections'
  | 'mediaEntries'
  | 'mediaEntryComments'
  | 'mediaEntryLocations'
  | 'memberClockState'
  | 'memberGpsStatuses'
  | 'memberGroups'
  | 'memberLaborMetrics'
  | 'memberLocations'
  | 'memberLocks'
  | 'memberOvertimePeriods'
  | 'memberProjectCostCodeEquipmentLaborMetrics'
  | 'memberSignInAnswers'
  | 'memberSignInSubmissions'
  | 'memberSignOffAnswers'
  | 'memberTimeDocuments'
  | 'members'
  | 'myPosts'
  | 'observationAnswers'
  | 'observationAttachments'
  | 'observationCategories'
  | 'observations'
  | 'organization'
  | 'organizationDocumentCount'
  | 'organizationDocuments'
  | 'organizationInfo'
  | 'organizationLaborMetrics'
  | 'organizationNotificationPreferences'
  | 'organizationOvertimePeriods'
  | 'organizationPayPeriods'
  | 'organizationSignInQuestions'
  | 'organizationSignOffQuestions'
  | 'organizationSubscriptionStatus'
  | 'perDiems'
  | 'policies'
  | 'policyMemberAcceptances'
  | 'positions'
  | 'postAttachments'
  | 'postComments'
  | 'postRecipients'
  | 'postStatus'
  | 'postViewers'
  | 'posts'
  | 'progressBudgets'
  | 'projectClockState'
  | 'projectCostCodeEquipmentLaborMetrics'
  | 'projectCostCodeLaborMetrics'
  | 'projectCostCodeLinks'
  | 'projectCostCodeQuantities'
  | 'projectGroups'
  | 'projectInfos'
  | 'projectLaborMetrics'
  | 'projects'
  | 'projectsWithTime'
  | 'recurringInspectionAssignmentCategoryLinks'
  | 'recurringInspectionAssignmentNotificationLinks'
  | 'recurringInspectionAssignments'
  | 'recurringScheduleReminderLinks'
  | 'recurringScheduleReminders'
  | 'safetyInspectionCategories'
  | 'safetyInspectionChecklists'
  | 'safetyReportsCategories'
  | 'safetyReportsSources'
  | 'safetyReportsTrainingMaterials'
  | 'safetySignatures'
  | 'safetyTrainingAttachments'
  | 'safetyTrainingAttendees'
  | 'safetyTrainingCategories'
  | 'safetyTrainingDocuments'
  | 'safetyTrainingIndustries'
  | 'safetyTrainings'
  | 'scheduleReminderLinks'
  | 'scheduleReminders'
  | 'selfSignatures'
  | 'tagDocumentLinks'
  | 'tagMediaCollectionLinks'
  | 'tags'
  | 'timeEntries'
  | 'timeEntryBreaks'
  | 'timeEntryRekognition'
  | 'timeOffRequests'
  | 'timeOffs'
  | 'wageHistories'
  | 'weather'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  authoritativeSignatures?: FieldPolicy<any> | FieldReadFunction<any>;
  budgetCosts?: FieldPolicy<any> | FieldReadFunction<any>;
  budgetHours?: FieldPolicy<any> | FieldReadFunction<any>;
  busySignInQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  clientTimeEntryBreakLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  clientTimeEntryLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeClockState?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyProjectReportTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
  dailyProjectReports?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentClockState?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentCostHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentHours?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentLocations?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentMakes?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentModelTitles?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentModels?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  flaggedTimeEntryLocations?: FieldPolicy<any> | FieldReadFunction<any>;
  folders?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentMedicalDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentOutcomeDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  incidents?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionAssignmentCategoryLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionAssignmentNotificationLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionAssignments?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionChecklistMemberLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionChecklists?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionQuestionAnswerAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionQuestionAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  inspectionQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  inspections?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaCollections?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntryComments?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaEntryLocations?: FieldPolicy<any> | FieldReadFunction<any>;
  memberClockState?: FieldPolicy<any> | FieldReadFunction<any>;
  memberGpsStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  memberGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLocations?: FieldPolicy<any> | FieldReadFunction<any>;
  memberLocks?: FieldPolicy<any> | FieldReadFunction<any>;
  memberOvertimePeriods?: FieldPolicy<any> | FieldReadFunction<any>;
  memberProjectCostCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  memberSignInAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  memberSignInSubmissions?: FieldPolicy<any> | FieldReadFunction<any>;
  memberSignOffAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  memberTimeDocuments?: FieldPolicy<any> | FieldReadFunction<any>;
  members?: FieldPolicy<any> | FieldReadFunction<any>;
  myPosts?: FieldPolicy<any> | FieldReadFunction<any>;
  observationAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  observationAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  observationCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  observations?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationDocumentCount?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationDocuments?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationNotificationPreferences?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationOvertimePeriods?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationPayPeriods?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationSignInQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationSignOffQuestions?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationSubscriptionStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  perDiems?: FieldPolicy<any> | FieldReadFunction<any>;
  policies?: FieldPolicy<any> | FieldReadFunction<any>;
  policyMemberAcceptances?: FieldPolicy<any> | FieldReadFunction<any>;
  positions?: FieldPolicy<any> | FieldReadFunction<any>;
  postAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  postComments?: FieldPolicy<any> | FieldReadFunction<any>;
  postRecipients?: FieldPolicy<any> | FieldReadFunction<any>;
  postStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  postViewers?: FieldPolicy<any> | FieldReadFunction<any>;
  posts?: FieldPolicy<any> | FieldReadFunction<any>;
  progressBudgets?: FieldPolicy<any> | FieldReadFunction<any>;
  projectClockState?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeEquipmentLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  projectCostCodeQuantities?: FieldPolicy<any> | FieldReadFunction<any>;
  projectGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  projectInfos?: FieldPolicy<any> | FieldReadFunction<any>;
  projectLaborMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
  projects?: FieldPolicy<any> | FieldReadFunction<any>;
  projectsWithTime?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringInspectionAssignmentCategoryLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringInspectionAssignmentNotificationLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringInspectionAssignments?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringScheduleReminderLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  recurringScheduleReminders?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyInspectionCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyInspectionChecklists?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyReportsCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyReportsSources?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyReportsTrainingMaterials?: FieldPolicy<any> | FieldReadFunction<any>;
  safetySignatures?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyTrainingAttachments?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyTrainingAttendees?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyTrainingCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyTrainingDocuments?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyTrainingIndustries?: FieldPolicy<any> | FieldReadFunction<any>;
  safetyTrainings?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduleReminderLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduleReminders?: FieldPolicy<any> | FieldReadFunction<any>;
  selfSignatures?: FieldPolicy<any> | FieldReadFunction<any>;
  tagDocumentLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  tagMediaCollectionLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  tags?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntries?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryBreaks?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryRekognition?: FieldPolicy<any> | FieldReadFunction<any>;
  timeOffRequests?: FieldPolicy<any> | FieldReadFunction<any>;
  timeOffs?: FieldPolicy<any> | FieldReadFunction<any>;
  wageHistories?: FieldPolicy<any> | FieldReadFunction<any>;
  weather?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurringInspectionAssignmentKeySpecifier = (
  | 'checklist'
  | 'checklistId'
  | 'circumstanceValue'
  | 'createdOn'
  | 'cursor'
  | 'dayOfMonth'
  | 'deletedOn'
  | 'description'
  | 'endDate'
  | 'frequency'
  | 'friday'
  | 'id'
  | 'limitCategories'
  | 'limitedCategories'
  | 'member'
  | 'memberId'
  | 'monday'
  | 'monthOfYear'
  | 'notificationLinks'
  | 'notificationTime'
  | 'notifyCircumstance'
  | 'notifySubmission'
  | 'organizationId'
  | 'pausedOn'
  | 'project'
  | 'projectId'
  | 'resumedOn'
  | 'saturday'
  | 'sendNotification'
  | 'startDate'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'sunday'
  | 'thursday'
  | 'tuesday'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | 'wednesday'
  | RecurringInspectionAssignmentKeySpecifier
)[];
export type RecurringInspectionAssignmentFieldPolicy = {
  checklist?: FieldPolicy<any> | FieldReadFunction<any>;
  checklistId?: FieldPolicy<any> | FieldReadFunction<any>;
  circumstanceValue?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dayOfMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  frequency?: FieldPolicy<any> | FieldReadFunction<any>;
  friday?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  limitCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  limitedCategories?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  monday?: FieldPolicy<any> | FieldReadFunction<any>;
  monthOfYear?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationLinks?: FieldPolicy<any> | FieldReadFunction<any>;
  notificationTime?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyCircumstance?: FieldPolicy<any> | FieldReadFunction<any>;
  notifySubmission?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  pausedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  resumedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  saturday?: FieldPolicy<any> | FieldReadFunction<any>;
  sendNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  sunday?: FieldPolicy<any> | FieldReadFunction<any>;
  thursday?: FieldPolicy<any> | FieldReadFunction<any>;
  tuesday?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  wednesday?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurringInspectionAssignmentCategoryLinkKeySpecifier = (
  | 'assignment'
  | 'assignmentId'
  | 'category'
  | 'categoryId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'submittedOn'
  | 'updatedOn'
  | RecurringInspectionAssignmentCategoryLinkKeySpecifier
)[];
export type RecurringInspectionAssignmentCategoryLinkFieldPolicy = {
  assignment?: FieldPolicy<any> | FieldReadFunction<any>;
  assignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurringInspectionAssignmentNotificationLinkKeySpecifier = (
  | 'assignment'
  | 'assignmentId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'submittedOn'
  | 'updatedOn'
  | RecurringInspectionAssignmentNotificationLinkKeySpecifier
)[];
export type RecurringInspectionAssignmentNotificationLinkFieldPolicy = {
  assignment?: FieldPolicy<any> | FieldReadFunction<any>;
  assignmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurringScheduleReminderKeySpecifier = (
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'dayOfMonth'
  | 'deletedOn'
  | 'endTime'
  | 'equipment'
  | 'equipmentId'
  | 'frequency'
  | 'frequencyExpiration'
  | 'friday'
  | 'id'
  | 'isDraft'
  | 'links'
  | 'member'
  | 'memberId'
  | 'monday'
  | 'monthOfYear'
  | 'notifyEmployee'
  | 'organizationId'
  | 'payload'
  | 'project'
  | 'projectId'
  | 'saturday'
  | 'startTime'
  | 'submittedOn'
  | 'sunday'
  | 'thursday'
  | 'tuesday'
  | 'type'
  | 'updatedOn'
  | 'wednesday'
  | RecurringScheduleReminderKeySpecifier
)[];
export type RecurringScheduleReminderFieldPolicy = {
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dayOfMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  frequency?: FieldPolicy<any> | FieldReadFunction<any>;
  frequencyExpiration?: FieldPolicy<any> | FieldReadFunction<any>;
  friday?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isDraft?: FieldPolicy<any> | FieldReadFunction<any>;
  links?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  monday?: FieldPolicy<any> | FieldReadFunction<any>;
  monthOfYear?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyEmployee?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  payload?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  saturday?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  sunday?: FieldPolicy<any> | FieldReadFunction<any>;
  thursday?: FieldPolicy<any> | FieldReadFunction<any>;
  tuesday?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  wednesday?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecurringScheduleReminderLinkKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'schedule'
  | 'scheduleReminderId'
  | 'submittedOn'
  | 'updatedOn'
  | RecurringScheduleReminderLinkKeySpecifier
)[];
export type RecurringScheduleReminderLinkFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  schedule?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduleReminderId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyInspectionCategoryKeySpecifier = (
  | 'id'
  | 'name'
  | 'questions'
  | 'reportType'
  | 'sort'
  | SafetyInspectionCategoryKeySpecifier
)[];
export type SafetyInspectionCategoryFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  questions?: FieldPolicy<any> | FieldReadFunction<any>;
  reportType?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyInspectionCategoryQuestionKeySpecifier = (
  | 'categoryId'
  | 'failLinkName'
  | 'failLinkUrl'
  | 'id'
  | 'name'
  | 'recommendResponse'
  | 'sort'
  | SafetyInspectionCategoryQuestionKeySpecifier
)[];
export type SafetyInspectionCategoryQuestionFieldPolicy = {
  categoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  failLinkName?: FieldPolicy<any> | FieldReadFunction<any>;
  failLinkUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  recommendResponse?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyInspectionChecklistKeySpecifier = ('name' | 'reportType' | SafetyInspectionChecklistKeySpecifier)[];
export type SafetyInspectionChecklistFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  reportType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyReportsCategoryKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'name'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | SafetyReportsCategoryKeySpecifier
)[];
export type SafetyReportsCategoryFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyReportsSourceKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'name'
  | 'organizationId'
  | 'submittedOn'
  | 'updatedOn'
  | SafetyReportsSourceKeySpecifier
)[];
export type SafetyReportsSourceFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyReportsTrainingMaterialKeySpecifier = (
  | 'category'
  | 'categoryId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'description'
  | 'fileUrl'
  | 'id'
  | 'source'
  | 'sourceId'
  | 'submittedOn'
  | 'title'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | SafetyReportsTrainingMaterialKeySpecifier
)[];
export type SafetyReportsTrainingMaterialFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  sourceId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetySignatureKeySpecifier = (
  | 'breakPolicyFollowed'
  | 'breakPolicyFollowedReason'
  | 'breakPolicyFollowedResolvedBy'
  | 'breakPolicyFollowedResolvedByMember'
  | 'breakPolicyFollowedResolvedComment'
  | 'breakPolicyFollowedResolvedOn'
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'customAnswers'
  | 'customQuestionsFlagged'
  | 'customQuestionsResolvedOn'
  | 'deletedOn'
  | 'deviceType'
  | 'endTime'
  | 'endTimeDst'
  | 'id'
  | 'incident'
  | 'incidentId'
  | 'injured'
  | 'injuredResolvedBy'
  | 'injuredResolvedByMember'
  | 'injuredResolvedComment'
  | 'injuredResolvedOn'
  | 'injuryDescription'
  | 'member'
  | 'memberId'
  | 'proxyMember'
  | 'proxyMemberId'
  | 'signatureId'
  | 'signatureUrl'
  | 'startTime'
  | 'startTimeDst'
  | 'submittedOn'
  | 'timeAccurate'
  | 'timeAccurateReason'
  | 'timeAccurateResolvedBy'
  | 'timeAccurateResolvedByMember'
  | 'timeAccurateResolvedComment'
  | 'timeAccurateResolvedOn'
  | 'updatedOn'
  | SafetySignatureKeySpecifier
)[];
export type SafetySignatureFieldPolicy = {
  breakPolicyFollowed?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyFollowedReason?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyFollowedResolvedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyFollowedResolvedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyFollowedResolvedComment?: FieldPolicy<any> | FieldReadFunction<any>;
  breakPolicyFollowedResolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  customAnswers?: FieldPolicy<any> | FieldReadFunction<any>;
  customQuestionsFlagged?: FieldPolicy<any> | FieldReadFunction<any>;
  customQuestionsResolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  deviceType?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incident?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentId?: FieldPolicy<any> | FieldReadFunction<any>;
  injured?: FieldPolicy<any> | FieldReadFunction<any>;
  injuredResolvedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  injuredResolvedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  injuredResolvedComment?: FieldPolicy<any> | FieldReadFunction<any>;
  injuredResolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  injuryDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMember?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccurate?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccurateReason?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccurateResolvedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccurateResolvedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccurateResolvedComment?: FieldPolicy<any> | FieldReadFunction<any>;
  timeAccurateResolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyTrainingKeySpecifier = (
  | 'attachments'
  | 'attendees'
  | 'completedOn'
  | 'createdBy'
  | 'createdByMember'
  | 'createdOn'
  | 'cursor'
  | 'date'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'instructor'
  | 'instructorId'
  | 'instructorInformation'
  | 'material'
  | 'materialId'
  | 'notes'
  | 'organizationId'
  | 'project'
  | 'projectId'
  | 'sendNotifications'
  | 'submittedOn'
  | 'title'
  | 'updatedBy'
  | 'updatedByMember'
  | 'updatedOn'
  | SafetyTrainingKeySpecifier
)[];
export type SafetyTrainingFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>;
  attendees?: FieldPolicy<any> | FieldReadFunction<any>;
  completedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  instructor?: FieldPolicy<any> | FieldReadFunction<any>;
  instructorId?: FieldPolicy<any> | FieldReadFunction<any>;
  instructorInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  material?: FieldPolicy<any> | FieldReadFunction<any>;
  materialId?: FieldPolicy<any> | FieldReadFunction<any>;
  notes?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  sendNotifications?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyTrainingAttachmentKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'fileType'
  | 'fileUrl'
  | 'id'
  | 'submittedOn'
  | 'training'
  | 'trainingId'
  | 'updatedOn'
  | SafetyTrainingAttachmentKeySpecifier
)[];
export type SafetyTrainingAttachmentFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  fileType?: FieldPolicy<any> | FieldReadFunction<any>;
  fileUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  training?: FieldPolicy<any> | FieldReadFunction<any>;
  trainingId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyTrainingAttendeeKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'presentOn'
  | 'signatureUrl'
  | 'submittedOn'
  | 'training'
  | 'trainingId'
  | 'updatedOn'
  | SafetyTrainingAttendeeKeySpecifier
)[];
export type SafetyTrainingAttendeeFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  presentOn?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  training?: FieldPolicy<any> | FieldReadFunction<any>;
  trainingId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyTrainingCategoryKeySpecifier = ('id' | 'name' | SafetyTrainingCategoryKeySpecifier)[];
export type SafetyTrainingCategoryFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyTrainingDocumentKeySpecifier = (
  | 'category'
  | 'description'
  | 'id'
  | 'industry'
  | 'spanishUrl'
  | 'title'
  | 'url'
  | SafetyTrainingDocumentKeySpecifier
)[];
export type SafetyTrainingDocumentFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  industry?: FieldPolicy<any> | FieldReadFunction<any>;
  spanishUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SafetyTrainingIndustryKeySpecifier = ('id' | 'name' | SafetyTrainingIndustryKeySpecifier)[];
export type SafetyTrainingIndustryFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScheduleReminderKeySpecifier = (
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'endTime'
  | 'equipment'
  | 'equipmentId'
  | 'id'
  | 'isDraft'
  | 'links'
  | 'member'
  | 'memberId'
  | 'notifyEmployee'
  | 'organizationId'
  | 'overrideRecurringSchedule'
  | 'overrideRecurringScheduleId'
  | 'payload'
  | 'project'
  | 'projectId'
  | 'repeat'
  | 'startTime'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | ScheduleReminderKeySpecifier
)[];
export type ScheduleReminderFieldPolicy = {
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isDraft?: FieldPolicy<any> | FieldReadFunction<any>;
  links?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyEmployee?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  overrideRecurringSchedule?: FieldPolicy<any> | FieldReadFunction<any>;
  overrideRecurringScheduleId?: FieldPolicy<any> | FieldReadFunction<any>;
  payload?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  repeat?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ScheduleReminderLinkKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'schedule'
  | 'scheduleReminderId'
  | 'submittedOn'
  | 'updatedOn'
  | ScheduleReminderLinkKeySpecifier
)[];
export type ScheduleReminderLinkFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  schedule?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduleReminderId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SelfSignatureKeySpecifier = (
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'member'
  | 'memberId'
  | 'proxyMember'
  | 'proxyMemberId'
  | 'signatureId'
  | 'signatureUrl'
  | 'submittedOn'
  | 'updatedOn'
  | SelfSignatureKeySpecifier
)[];
export type SelfSignatureFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMember?: FieldPolicy<any> | FieldReadFunction<any>;
  proxyMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureId?: FieldPolicy<any> | FieldReadFunction<any>;
  signatureUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TagKeySpecifier = (
  | 'color'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'organizationId'
  | 'submittedOn'
  | 'title'
  | 'type'
  | 'updatedOn'
  | TagKeySpecifier
)[];
export type TagFieldPolicy = {
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TagDocumentLinkKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'document'
  | 'documentId'
  | 'id'
  | 'organizationId'
  | 'submittedOn'
  | 'tag'
  | 'tagId'
  | 'updatedOn'
  | TagDocumentLinkKeySpecifier
)[];
export type TagDocumentLinkFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  document?: FieldPolicy<any> | FieldReadFunction<any>;
  documentId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
  tagId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TagMediaCollectionLinkKeySpecifier = (
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'mediaCollection'
  | 'mediaCollectionId'
  | 'organizationId'
  | 'submittedOn'
  | 'tag'
  | 'tagId'
  | 'updatedOn'
  | TagMediaCollectionLinkKeySpecifier
)[];
export type TagMediaCollectionLinkFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaCollection?: FieldPolicy<any> | FieldReadFunction<any>;
  mediaCollectionId?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
  tagId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeEntryKeySpecifier = (
  | 'actionType'
  | 'breaks'
  | 'clientLogs'
  | 'costCode'
  | 'costCodeId'
  | 'createdOn'
  | 'cursor'
  | 'daylightSavingTime'
  | 'deletedOn'
  | 'description'
  | 'endDeviceTime'
  | 'endLocation'
  | 'endLocationId'
  | 'endTime'
  | 'endTimeTrusted'
  | 'equipment'
  | 'equipmentId'
  | 'flaggedTimeEntryLocation'
  | 'hasLocation'
  | 'id'
  | 'member'
  | 'memberGpsStatuses'
  | 'memberId'
  | 'metaDaylightSavingTime'
  | 'openBreak'
  | 'project'
  | 'projectId'
  | 'startDeviceTime'
  | 'startLocation'
  | 'startLocationId'
  | 'startTime'
  | 'startTimeTrusted'
  | 'submittedOn'
  | 'updatedOn'
  | TimeEntryKeySpecifier
)[];
export type TimeEntryFieldPolicy = {
  actionType?: FieldPolicy<any> | FieldReadFunction<any>;
  breaks?: FieldPolicy<any> | FieldReadFunction<any>;
  clientLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  costCode?: FieldPolicy<any> | FieldReadFunction<any>;
  costCodeId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  daylightSavingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  endDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  endLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  equipment?: FieldPolicy<any> | FieldReadFunction<any>;
  equipmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  flaggedTimeEntryLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  hasLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberGpsStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  metaDaylightSavingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  openBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  project?: FieldPolicy<any> | FieldReadFunction<any>;
  projectId?: FieldPolicy<any> | FieldReadFunction<any>;
  startDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  startLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeEntryBreakKeySpecifier = (
  | 'actionType'
  | 'clientLogs'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'endDeviceTime'
  | 'endLocation'
  | 'endLocationId'
  | 'endTime'
  | 'endTimeDst'
  | 'endTimeTrusted'
  | 'id'
  | 'paidBreak'
  | 'startDeviceTime'
  | 'startLocation'
  | 'startLocationId'
  | 'startTime'
  | 'startTimeDst'
  | 'startTimeTrusted'
  | 'submittedOn'
  | 'timeEntry'
  | 'timeEntryId'
  | 'updatedOn'
  | TimeEntryBreakKeySpecifier
)[];
export type TimeEntryBreakFieldPolicy = {
  actionType?: FieldPolicy<any> | FieldReadFunction<any>;
  clientLogs?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  endDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  endLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  endTime?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  endTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paidBreak?: FieldPolicy<any> | FieldReadFunction<any>;
  startDeviceTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startLocation?: FieldPolicy<any> | FieldReadFunction<any>;
  startLocationId?: FieldPolicy<any> | FieldReadFunction<any>;
  startTime?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeDst?: FieldPolicy<any> | FieldReadFunction<any>;
  startTimeTrusted?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeEntryRekognitionKeySpecifier = (
  | 'confidence'
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'deletedOn'
  | 'id'
  | 'imageId'
  | 'imageUrl'
  | 'referenceImageId'
  | 'referenceImageUrl'
  | 'resolvedOn'
  | 'similarity'
  | 'source'
  | 'submittedOn'
  | 'timeEntry'
  | 'timeEntryId'
  | 'type'
  | 'updatedOn'
  | TimeEntryRekognitionKeySpecifier
)[];
export type TimeEntryRekognitionFieldPolicy = {
  confidence?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageId?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceImageId?: FieldPolicy<any> | FieldReadFunction<any>;
  referenceImageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  resolvedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  similarity?: FieldPolicy<any> | FieldReadFunction<any>;
  source?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntry?: FieldPolicy<any> | FieldReadFunction<any>;
  timeEntryId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeOffKeySpecifier = (
  | 'createdOn'
  | 'createdOnDaylightSavingTime'
  | 'createdOnLocal'
  | 'cursor'
  | 'dateStamp'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'member'
  | 'memberId'
  | 'paid'
  | 'seconds'
  | 'submittedOn'
  | 'submitterMember'
  | 'submitterMemberId'
  | 'type'
  | 'updatedOn'
  | TimeOffKeySpecifier
)[];
export type TimeOffFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDaylightSavingTime?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dateStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  paid?: FieldPolicy<any> | FieldReadFunction<any>;
  seconds?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  submitterMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submitterMemberId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeOffRequestKeySpecifier = (
  | 'answeredBy'
  | 'answeredByMember'
  | 'answeredOn'
  | 'approved'
  | 'createdOn'
  | 'createdOnDst'
  | 'createdOnLocal'
  | 'cursor'
  | 'dateStamp'
  | 'deletedOn'
  | 'description'
  | 'id'
  | 'member'
  | 'memberId'
  | 'paid'
  | 'rejectionReason'
  | 'seconds'
  | 'submittedBy'
  | 'submittedByMember'
  | 'submittedOn'
  | 'type'
  | 'updatedOn'
  | TimeOffRequestKeySpecifier
)[];
export type TimeOffRequestFieldPolicy = {
  answeredBy?: FieldPolicy<any> | FieldReadFunction<any>;
  answeredByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  answeredOn?: FieldPolicy<any> | FieldReadFunction<any>;
  approved?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnDst?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOnLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  dateStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  paid?: FieldPolicy<any> | FieldReadFunction<any>;
  rejectionReason?: FieldPolicy<any> | FieldReadFunction<any>;
  seconds?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedByMember?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WageHistoryKeySpecifier = (
  | 'changeDate'
  | 'createdOn'
  | 'cursor'
  | 'deletedOn'
  | 'effectiveRate'
  | 'id'
  | 'member'
  | 'memberId'
  | 'overburden'
  | 'submittedOn'
  | 'updatedOn'
  | 'wage'
  | 'wageRate'
  | 'workWeek'
  | WageHistoryKeySpecifier
)[];
export type WageHistoryFieldPolicy = {
  changeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  effectiveRate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  member?: FieldPolicy<any> | FieldReadFunction<any>;
  memberId?: FieldPolicy<any> | FieldReadFunction<any>;
  overburden?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  wage?: FieldPolicy<any> | FieldReadFunction<any>;
  wageRate?: FieldPolicy<any> | FieldReadFunction<any>;
  workWeek?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WeatherKeySpecifier = ('hours' | 'metadata' | 'name' | WeatherKeySpecifier)[];
export type WeatherFieldPolicy = {
  hours?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WeatherHourlyKeySpecifier = (
  | 'cloudCover'
  | 'conditionCode'
  | 'daylight'
  | 'forecastStart'
  | 'humidity'
  | 'precipitationAmount'
  | 'precipitationChance'
  | 'precipitationIntensity'
  | 'precipitationType'
  | 'pressure'
  | 'pressureTrend'
  | 'snowfallAmount'
  | 'snowfallIntensity'
  | 'temperature'
  | 'temperatureApparent'
  | 'temperatureDewPoint'
  | 'windDirection'
  | 'windGust'
  | 'windSpeed'
  | WeatherHourlyKeySpecifier
)[];
export type WeatherHourlyFieldPolicy = {
  cloudCover?: FieldPolicy<any> | FieldReadFunction<any>;
  conditionCode?: FieldPolicy<any> | FieldReadFunction<any>;
  daylight?: FieldPolicy<any> | FieldReadFunction<any>;
  forecastStart?: FieldPolicy<any> | FieldReadFunction<any>;
  humidity?: FieldPolicy<any> | FieldReadFunction<any>;
  precipitationAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  precipitationChance?: FieldPolicy<any> | FieldReadFunction<any>;
  precipitationIntensity?: FieldPolicy<any> | FieldReadFunction<any>;
  precipitationType?: FieldPolicy<any> | FieldReadFunction<any>;
  pressure?: FieldPolicy<any> | FieldReadFunction<any>;
  pressureTrend?: FieldPolicy<any> | FieldReadFunction<any>;
  snowfallAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  snowfallIntensity?: FieldPolicy<any> | FieldReadFunction<any>;
  temperature?: FieldPolicy<any> | FieldReadFunction<any>;
  temperatureApparent?: FieldPolicy<any> | FieldReadFunction<any>;
  temperatureDewPoint?: FieldPolicy<any> | FieldReadFunction<any>;
  windDirection?: FieldPolicy<any> | FieldReadFunction<any>;
  windGust?: FieldPolicy<any> | FieldReadFunction<any>;
  windSpeed?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WeatherMetadataKeySpecifier = (
  | 'attributionURL'
  | 'expireTime'
  | 'latitude'
  | 'longitude'
  | 'units'
  | 'version'
  | WeatherMetadataKeySpecifier
)[];
export type WeatherMetadataFieldPolicy = {
  attributionURL?: FieldPolicy<any> | FieldReadFunction<any>;
  expireTime?: FieldPolicy<any> | FieldReadFunction<any>;
  latitude?: FieldPolicy<any> | FieldReadFunction<any>;
  longitude?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  AuthoritativeSignature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AuthoritativeSignatureKeySpecifier | (() => undefined | AuthoritativeSignatureKeySpecifier);
    fields?: AuthoritativeSignatureFieldPolicy;
  };
  BudgetCost?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BudgetCostKeySpecifier | (() => undefined | BudgetCostKeySpecifier);
    fields?: BudgetCostFieldPolicy;
  };
  BudgetHours?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BudgetHoursKeySpecifier | (() => undefined | BudgetHoursKeySpecifier);
    fields?: BudgetHoursFieldPolicy;
  };
  BusySignInQuestion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BusySignInQuestionKeySpecifier | (() => undefined | BusySignInQuestionKeySpecifier);
    fields?: BusySignInQuestionFieldPolicy;
  };
  ClientTimeEntryBreakLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClientTimeEntryBreakLogKeySpecifier | (() => undefined | ClientTimeEntryBreakLogKeySpecifier);
    fields?: ClientTimeEntryBreakLogFieldPolicy;
  };
  ClientTimeEntryLog?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClientTimeEntryLogKeySpecifier | (() => undefined | ClientTimeEntryLogKeySpecifier);
    fields?: ClientTimeEntryLogFieldPolicy;
  };
  CostCode?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CostCodeKeySpecifier | (() => undefined | CostCodeKeySpecifier);
    fields?: CostCodeFieldPolicy;
  };
  CostCodeClockState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CostCodeClockStateKeySpecifier | (() => undefined | CostCodeClockStateKeySpecifier);
    fields?: CostCodeClockStateFieldPolicy;
  };
  CostCodeEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CostCodeEquipmentLaborMetricsKeySpecifier
      | (() => undefined | CostCodeEquipmentLaborMetricsKeySpecifier);
    fields?: CostCodeEquipmentLaborMetricsFieldPolicy;
  };
  CostCodeGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CostCodeGroupKeySpecifier | (() => undefined | CostCodeGroupKeySpecifier);
    fields?: CostCodeGroupFieldPolicy;
  };
  CostCodeLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CostCodeLaborMetricsKeySpecifier | (() => undefined | CostCodeLaborMetricsKeySpecifier);
    fields?: CostCodeLaborMetricsFieldPolicy;
  };
  CostCodeMemberLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CostCodeMemberLaborMetricsKeySpecifier
      | (() => undefined | CostCodeMemberLaborMetricsKeySpecifier);
    fields?: CostCodeMemberLaborMetricsFieldPolicy;
  };
  CostCodeProjectLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CostCodeProjectLaborMetricsKeySpecifier
      | (() => undefined | CostCodeProjectLaborMetricsKeySpecifier);
    fields?: CostCodeProjectLaborMetricsFieldPolicy;
  };
  DailyProjectReport?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DailyProjectReportKeySpecifier | (() => undefined | DailyProjectReportKeySpecifier);
    fields?: DailyProjectReportFieldPolicy;
  };
  DailyProjectReportTemplate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DailyProjectReportTemplateKeySpecifier
      | (() => undefined | DailyProjectReportTemplateKeySpecifier);
    fields?: DailyProjectReportTemplateFieldPolicy;
  };
  Equipment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentKeySpecifier | (() => undefined | EquipmentKeySpecifier);
    fields?: EquipmentFieldPolicy;
  };
  EquipmentCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentCategoryKeySpecifier | (() => undefined | EquipmentCategoryKeySpecifier);
    fields?: EquipmentCategoryFieldPolicy;
  };
  EquipmentClockState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentClockStateKeySpecifier | (() => undefined | EquipmentClockStateKeySpecifier);
    fields?: EquipmentClockStateFieldPolicy;
  };
  EquipmentCostCodeLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EquipmentCostCodeLaborMetricsKeySpecifier
      | (() => undefined | EquipmentCostCodeLaborMetricsKeySpecifier);
    fields?: EquipmentCostCodeLaborMetricsFieldPolicy;
  };
  EquipmentCostHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentCostHistoryKeySpecifier | (() => undefined | EquipmentCostHistoryKeySpecifier);
    fields?: EquipmentCostHistoryFieldPolicy;
  };
  EquipmentHourMeterMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EquipmentHourMeterMetricsKeySpecifier
      | (() => undefined | EquipmentHourMeterMetricsKeySpecifier);
    fields?: EquipmentHourMeterMetricsFieldPolicy;
  };
  EquipmentHours?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentHoursKeySpecifier | (() => undefined | EquipmentHoursKeySpecifier);
    fields?: EquipmentHoursFieldPolicy;
  };
  EquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentLaborMetricsKeySpecifier | (() => undefined | EquipmentLaborMetricsKeySpecifier);
    fields?: EquipmentLaborMetricsFieldPolicy;
  };
  EquipmentLaborMetricsInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EquipmentLaborMetricsInterfaceKeySpecifier
      | (() => undefined | EquipmentLaborMetricsInterfaceKeySpecifier);
    fields?: EquipmentLaborMetricsInterfaceFieldPolicy;
  };
  EquipmentLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentLocationKeySpecifier | (() => undefined | EquipmentLocationKeySpecifier);
    fields?: EquipmentLocationFieldPolicy;
  };
  EquipmentMake?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentMakeKeySpecifier | (() => undefined | EquipmentMakeKeySpecifier);
    fields?: EquipmentMakeFieldPolicy;
  };
  EquipmentMemberLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EquipmentMemberLaborMetricsKeySpecifier
      | (() => undefined | EquipmentMemberLaborMetricsKeySpecifier);
    fields?: EquipmentMemberLaborMetricsFieldPolicy;
  };
  EquipmentModel?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentModelKeySpecifier | (() => undefined | EquipmentModelKeySpecifier);
    fields?: EquipmentModelFieldPolicy;
  };
  EquipmentProjectLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EquipmentProjectLaborMetricsKeySpecifier
      | (() => undefined | EquipmentProjectLaborMetricsKeySpecifier);
    fields?: EquipmentProjectLaborMetricsFieldPolicy;
  };
  EquipmentProjectLaborMetricsInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EquipmentProjectLaborMetricsInterfaceKeySpecifier
      | (() => undefined | EquipmentProjectLaborMetricsInterfaceKeySpecifier);
    fields?: EquipmentProjectLaborMetricsInterfaceFieldPolicy;
  };
  EquipmentType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EquipmentTypeKeySpecifier | (() => undefined | EquipmentTypeKeySpecifier);
    fields?: EquipmentTypeFieldPolicy;
  };
  FlaggedTimeEntryLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FlaggedTimeEntryLocationKeySpecifier | (() => undefined | FlaggedTimeEntryLocationKeySpecifier);
    fields?: FlaggedTimeEntryLocationFieldPolicy;
  };
  Folder?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FolderKeySpecifier | (() => undefined | FolderKeySpecifier);
    fields?: FolderFieldPolicy;
  };
  Incident?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IncidentKeySpecifier | (() => undefined | IncidentKeySpecifier);
    fields?: IncidentFieldPolicy;
  };
  IncidentAnswer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IncidentAnswerKeySpecifier | (() => undefined | IncidentAnswerKeySpecifier);
    fields?: IncidentAnswerFieldPolicy;
  };
  IncidentMedicalDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IncidentMedicalDetailsKeySpecifier | (() => undefined | IncidentMedicalDetailsKeySpecifier);
    fields?: IncidentMedicalDetailsFieldPolicy;
  };
  IncidentOutcomeDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IncidentOutcomeDetailsKeySpecifier | (() => undefined | IncidentOutcomeDetailsKeySpecifier);
    fields?: IncidentOutcomeDetailsFieldPolicy;
  };
  Inspection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionKeySpecifier | (() => undefined | InspectionKeySpecifier);
    fields?: InspectionFieldPolicy;
  };
  InspectionAssignment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionAssignmentKeySpecifier | (() => undefined | InspectionAssignmentKeySpecifier);
    fields?: InspectionAssignmentFieldPolicy;
  };
  InspectionAssignmentCategoryLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionAssignmentCategoryLinkKeySpecifier
      | (() => undefined | InspectionAssignmentCategoryLinkKeySpecifier);
    fields?: InspectionAssignmentCategoryLinkFieldPolicy;
  };
  InspectionAssignmentNotificationLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionAssignmentNotificationLinkKeySpecifier
      | (() => undefined | InspectionAssignmentNotificationLinkKeySpecifier);
    fields?: InspectionAssignmentNotificationLinkFieldPolicy;
  };
  InspectionCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionCategoryKeySpecifier | (() => undefined | InspectionCategoryKeySpecifier);
    fields?: InspectionCategoryFieldPolicy;
  };
  InspectionChecklist?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionChecklistKeySpecifier | (() => undefined | InspectionChecklistKeySpecifier);
    fields?: InspectionChecklistFieldPolicy;
  };
  InspectionChecklistMemberLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionChecklistMemberLinkKeySpecifier
      | (() => undefined | InspectionChecklistMemberLinkKeySpecifier);
    fields?: InspectionChecklistMemberLinkFieldPolicy;
  };
  InspectionQuestion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionQuestionKeySpecifier | (() => undefined | InspectionQuestionKeySpecifier);
    fields?: InspectionQuestionFieldPolicy;
  };
  InspectionQuestionAnswer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InspectionQuestionAnswerKeySpecifier | (() => undefined | InspectionQuestionAnswerKeySpecifier);
    fields?: InspectionQuestionAnswerFieldPolicy;
  };
  InspectionQuestionAnswerAttachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InspectionQuestionAnswerAttachmentKeySpecifier
      | (() => undefined | InspectionQuestionAnswerAttachmentKeySpecifier);
    fields?: InspectionQuestionAnswerAttachmentFieldPolicy;
  };
  LaborMetricsInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LaborMetricsInterfaceKeySpecifier | (() => undefined | LaborMetricsInterfaceKeySpecifier);
    fields?: LaborMetricsInterfaceFieldPolicy;
  };
  MediaCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MediaCollectionKeySpecifier | (() => undefined | MediaCollectionKeySpecifier);
    fields?: MediaCollectionFieldPolicy;
  };
  MediaEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MediaEntryKeySpecifier | (() => undefined | MediaEntryKeySpecifier);
    fields?: MediaEntryFieldPolicy;
  };
  MediaEntryComment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MediaEntryCommentKeySpecifier | (() => undefined | MediaEntryCommentKeySpecifier);
    fields?: MediaEntryCommentFieldPolicy;
  };
  MediaEntryLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MediaEntryLocationKeySpecifier | (() => undefined | MediaEntryLocationKeySpecifier);
    fields?: MediaEntryLocationFieldPolicy;
  };
  Member?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberKeySpecifier | (() => undefined | MemberKeySpecifier);
    fields?: MemberFieldPolicy;
  };
  MemberClockState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberClockStateKeySpecifier | (() => undefined | MemberClockStateKeySpecifier);
    fields?: MemberClockStateFieldPolicy;
  };
  MemberCostCodeEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberCostCodeEquipmentLaborMetricsKeySpecifier
      | (() => undefined | MemberCostCodeEquipmentLaborMetricsKeySpecifier);
    fields?: MemberCostCodeEquipmentLaborMetricsFieldPolicy;
  };
  MemberCostCodeLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberCostCodeLaborMetricsKeySpecifier
      | (() => undefined | MemberCostCodeLaborMetricsKeySpecifier);
    fields?: MemberCostCodeLaborMetricsFieldPolicy;
  };
  MemberEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberEquipmentLaborMetricsKeySpecifier
      | (() => undefined | MemberEquipmentLaborMetricsKeySpecifier);
    fields?: MemberEquipmentLaborMetricsFieldPolicy;
  };
  MemberGpsStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberGpsStatusKeySpecifier | (() => undefined | MemberGpsStatusKeySpecifier);
    fields?: MemberGpsStatusFieldPolicy;
  };
  MemberGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberGroupKeySpecifier | (() => undefined | MemberGroupKeySpecifier);
    fields?: MemberGroupFieldPolicy;
  };
  MemberLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberLaborMetricsKeySpecifier | (() => undefined | MemberLaborMetricsKeySpecifier);
    fields?: MemberLaborMetricsFieldPolicy;
  };
  MemberLocation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberLocationKeySpecifier | (() => undefined | MemberLocationKeySpecifier);
    fields?: MemberLocationFieldPolicy;
  };
  MemberLock?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberLockKeySpecifier | (() => undefined | MemberLockKeySpecifier);
    fields?: MemberLockFieldPolicy;
  };
  MemberOvertimePeriod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberOvertimePeriodKeySpecifier | (() => undefined | MemberOvertimePeriodKeySpecifier);
    fields?: MemberOvertimePeriodFieldPolicy;
  };
  MemberProjectCostCodeEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberProjectCostCodeEquipmentLaborMetricsKeySpecifier
      | (() => undefined | MemberProjectCostCodeEquipmentLaborMetricsKeySpecifier);
    fields?: MemberProjectCostCodeEquipmentLaborMetricsFieldPolicy;
  };
  MemberProjectCostCodeLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberProjectCostCodeLaborMetricsKeySpecifier
      | (() => undefined | MemberProjectCostCodeLaborMetricsKeySpecifier);
    fields?: MemberProjectCostCodeLaborMetricsFieldPolicy;
  };
  MemberProjectEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberProjectEquipmentLaborMetricsKeySpecifier
      | (() => undefined | MemberProjectEquipmentLaborMetricsKeySpecifier);
    fields?: MemberProjectEquipmentLaborMetricsFieldPolicy;
  };
  MemberProjectLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MemberProjectLaborMetricsKeySpecifier
      | (() => undefined | MemberProjectLaborMetricsKeySpecifier);
    fields?: MemberProjectLaborMetricsFieldPolicy;
  };
  MemberSignInAnswer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberSignInAnswerKeySpecifier | (() => undefined | MemberSignInAnswerKeySpecifier);
    fields?: MemberSignInAnswerFieldPolicy;
  };
  MemberSignInSubmission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberSignInSubmissionKeySpecifier | (() => undefined | MemberSignInSubmissionKeySpecifier);
    fields?: MemberSignInSubmissionFieldPolicy;
  };
  MemberSignOffAnswer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberSignOffAnswerKeySpecifier | (() => undefined | MemberSignOffAnswerKeySpecifier);
    fields?: MemberSignOffAnswerFieldPolicy;
  };
  MemberTimeDocument?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberTimeDocumentKeySpecifier | (() => undefined | MemberTimeDocumentKeySpecifier);
    fields?: MemberTimeDocumentFieldPolicy;
  };
  MemberUsageInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MemberUsageInfoKeySpecifier | (() => undefined | MemberUsageInfoKeySpecifier);
    fields?: MemberUsageInfoFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Observation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ObservationKeySpecifier | (() => undefined | ObservationKeySpecifier);
    fields?: ObservationFieldPolicy;
  };
  ObservationAnswer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ObservationAnswerKeySpecifier | (() => undefined | ObservationAnswerKeySpecifier);
    fields?: ObservationAnswerFieldPolicy;
  };
  ObservationAttachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ObservationAttachmentKeySpecifier | (() => undefined | ObservationAttachmentKeySpecifier);
    fields?: ObservationAttachmentFieldPolicy;
  };
  ObservationCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ObservationCategoryKeySpecifier | (() => undefined | ObservationCategoryKeySpecifier);
    fields?: ObservationCategoryFieldPolicy;
  };
  Organization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationKeySpecifier | (() => undefined | OrganizationKeySpecifier);
    fields?: OrganizationFieldPolicy;
  };
  OrganizationDocument?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationDocumentKeySpecifier | (() => undefined | OrganizationDocumentKeySpecifier);
    fields?: OrganizationDocumentFieldPolicy;
  };
  OrganizationInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationInfoKeySpecifier | (() => undefined | OrganizationInfoKeySpecifier);
    fields?: OrganizationInfoFieldPolicy;
  };
  OrganizationLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationLaborMetricsKeySpecifier | (() => undefined | OrganizationLaborMetricsKeySpecifier);
    fields?: OrganizationLaborMetricsFieldPolicy;
  };
  OrganizationNotificationPreferences?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationNotificationPreferencesKeySpecifier
      | (() => undefined | OrganizationNotificationPreferencesKeySpecifier);
    fields?: OrganizationNotificationPreferencesFieldPolicy;
  };
  OrganizationOvertimePeriod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationOvertimePeriodKeySpecifier
      | (() => undefined | OrganizationOvertimePeriodKeySpecifier);
    fields?: OrganizationOvertimePeriodFieldPolicy;
  };
  OrganizationPayPeriod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationPayPeriodKeySpecifier | (() => undefined | OrganizationPayPeriodKeySpecifier);
    fields?: OrganizationPayPeriodFieldPolicy;
  };
  OrganizationSignInQuestion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationSignInQuestionKeySpecifier
      | (() => undefined | OrganizationSignInQuestionKeySpecifier);
    fields?: OrganizationSignInQuestionFieldPolicy;
  };
  OrganizationSignOffQuestion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationSignOffQuestionKeySpecifier
      | (() => undefined | OrganizationSignOffQuestionKeySpecifier);
    fields?: OrganizationSignOffQuestionFieldPolicy;
  };
  OrganizationSubscriptionStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationSubscriptionStatusKeySpecifier
      | (() => undefined | OrganizationSubscriptionStatusKeySpecifier);
    fields?: OrganizationSubscriptionStatusFieldPolicy;
  };
  PerDiem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PerDiemKeySpecifier | (() => undefined | PerDiemKeySpecifier);
    fields?: PerDiemFieldPolicy;
  };
  Policy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PolicyKeySpecifier | (() => undefined | PolicyKeySpecifier);
    fields?: PolicyFieldPolicy;
  };
  PolicyMemberAcceptance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PolicyMemberAcceptanceKeySpecifier | (() => undefined | PolicyMemberAcceptanceKeySpecifier);
    fields?: PolicyMemberAcceptanceFieldPolicy;
  };
  Position?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PositionKeySpecifier | (() => undefined | PositionKeySpecifier);
    fields?: PositionFieldPolicy;
  };
  Post?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PostKeySpecifier | (() => undefined | PostKeySpecifier);
    fields?: PostFieldPolicy;
  };
  PostAttachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PostAttachmentKeySpecifier | (() => undefined | PostAttachmentKeySpecifier);
    fields?: PostAttachmentFieldPolicy;
  };
  PostComment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PostCommentKeySpecifier | (() => undefined | PostCommentKeySpecifier);
    fields?: PostCommentFieldPolicy;
  };
  PostRecipient?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PostRecipientKeySpecifier | (() => undefined | PostRecipientKeySpecifier);
    fields?: PostRecipientFieldPolicy;
  };
  PostStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PostStatusKeySpecifier | (() => undefined | PostStatusKeySpecifier);
    fields?: PostStatusFieldPolicy;
  };
  PostViewer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PostViewerKeySpecifier | (() => undefined | PostViewerKeySpecifier);
    fields?: PostViewerFieldPolicy;
  };
  ProgressBudget?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProgressBudgetKeySpecifier | (() => undefined | ProgressBudgetKeySpecifier);
    fields?: ProgressBudgetFieldPolicy;
  };
  Project?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectKeySpecifier | (() => undefined | ProjectKeySpecifier);
    fields?: ProjectFieldPolicy;
  };
  ProjectClockState?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectClockStateKeySpecifier | (() => undefined | ProjectClockStateKeySpecifier);
    fields?: ProjectClockStateFieldPolicy;
  };
  ProjectCostBudget?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectCostBudgetKeySpecifier | (() => undefined | ProjectCostBudgetKeySpecifier);
    fields?: ProjectCostBudgetFieldPolicy;
  };
  ProjectCostCodeEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectCostCodeEquipmentLaborMetricsKeySpecifier
      | (() => undefined | ProjectCostCodeEquipmentLaborMetricsKeySpecifier);
    fields?: ProjectCostCodeEquipmentLaborMetricsFieldPolicy;
  };
  ProjectCostCodeLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectCostCodeLaborMetricsKeySpecifier
      | (() => undefined | ProjectCostCodeLaborMetricsKeySpecifier);
    fields?: ProjectCostCodeLaborMetricsFieldPolicy;
  };
  ProjectCostCodeLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectCostCodeLinkKeySpecifier | (() => undefined | ProjectCostCodeLinkKeySpecifier);
    fields?: ProjectCostCodeLinkFieldPolicy;
  };
  ProjectCostCodeQuantity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectCostCodeQuantityKeySpecifier | (() => undefined | ProjectCostCodeQuantityKeySpecifier);
    fields?: ProjectCostCodeQuantityFieldPolicy;
  };
  ProjectEquipmentLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectEquipmentLaborMetricsKeySpecifier
      | (() => undefined | ProjectEquipmentLaborMetricsKeySpecifier);
    fields?: ProjectEquipmentLaborMetricsFieldPolicy;
  };
  ProjectGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectGroupKeySpecifier | (() => undefined | ProjectGroupKeySpecifier);
    fields?: ProjectGroupFieldPolicy;
  };
  ProjectHoursBudget?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectHoursBudgetKeySpecifier | (() => undefined | ProjectHoursBudgetKeySpecifier);
    fields?: ProjectHoursBudgetFieldPolicy;
  };
  ProjectInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectInfoKeySpecifier | (() => undefined | ProjectInfoKeySpecifier);
    fields?: ProjectInfoFieldPolicy;
  };
  ProjectLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProjectLaborMetricsKeySpecifier | (() => undefined | ProjectLaborMetricsKeySpecifier);
    fields?: ProjectLaborMetricsFieldPolicy;
  };
  ProjectLaborMetricsInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectLaborMetricsInterfaceKeySpecifier
      | (() => undefined | ProjectLaborMetricsInterfaceKeySpecifier);
    fields?: ProjectLaborMetricsInterfaceFieldPolicy;
  };
  ProjectMemberLaborMetrics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProjectMemberLaborMetricsKeySpecifier
      | (() => undefined | ProjectMemberLaborMetricsKeySpecifier);
    fields?: ProjectMemberLaborMetricsFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  RecurringInspectionAssignment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurringInspectionAssignmentKeySpecifier
      | (() => undefined | RecurringInspectionAssignmentKeySpecifier);
    fields?: RecurringInspectionAssignmentFieldPolicy;
  };
  RecurringInspectionAssignmentCategoryLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurringInspectionAssignmentCategoryLinkKeySpecifier
      | (() => undefined | RecurringInspectionAssignmentCategoryLinkKeySpecifier);
    fields?: RecurringInspectionAssignmentCategoryLinkFieldPolicy;
  };
  RecurringInspectionAssignmentNotificationLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurringInspectionAssignmentNotificationLinkKeySpecifier
      | (() => undefined | RecurringInspectionAssignmentNotificationLinkKeySpecifier);
    fields?: RecurringInspectionAssignmentNotificationLinkFieldPolicy;
  };
  RecurringScheduleReminder?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurringScheduleReminderKeySpecifier
      | (() => undefined | RecurringScheduleReminderKeySpecifier);
    fields?: RecurringScheduleReminderFieldPolicy;
  };
  RecurringScheduleReminderLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RecurringScheduleReminderLinkKeySpecifier
      | (() => undefined | RecurringScheduleReminderLinkKeySpecifier);
    fields?: RecurringScheduleReminderLinkFieldPolicy;
  };
  SafetyInspectionCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyInspectionCategoryKeySpecifier | (() => undefined | SafetyInspectionCategoryKeySpecifier);
    fields?: SafetyInspectionCategoryFieldPolicy;
  };
  SafetyInspectionCategoryQuestion?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SafetyInspectionCategoryQuestionKeySpecifier
      | (() => undefined | SafetyInspectionCategoryQuestionKeySpecifier);
    fields?: SafetyInspectionCategoryQuestionFieldPolicy;
  };
  SafetyInspectionChecklist?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SafetyInspectionChecklistKeySpecifier
      | (() => undefined | SafetyInspectionChecklistKeySpecifier);
    fields?: SafetyInspectionChecklistFieldPolicy;
  };
  SafetyReportsCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyReportsCategoryKeySpecifier | (() => undefined | SafetyReportsCategoryKeySpecifier);
    fields?: SafetyReportsCategoryFieldPolicy;
  };
  SafetyReportsSource?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyReportsSourceKeySpecifier | (() => undefined | SafetyReportsSourceKeySpecifier);
    fields?: SafetyReportsSourceFieldPolicy;
  };
  SafetyReportsTrainingMaterial?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SafetyReportsTrainingMaterialKeySpecifier
      | (() => undefined | SafetyReportsTrainingMaterialKeySpecifier);
    fields?: SafetyReportsTrainingMaterialFieldPolicy;
  };
  SafetySignature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetySignatureKeySpecifier | (() => undefined | SafetySignatureKeySpecifier);
    fields?: SafetySignatureFieldPolicy;
  };
  SafetyTraining?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyTrainingKeySpecifier | (() => undefined | SafetyTrainingKeySpecifier);
    fields?: SafetyTrainingFieldPolicy;
  };
  SafetyTrainingAttachment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyTrainingAttachmentKeySpecifier | (() => undefined | SafetyTrainingAttachmentKeySpecifier);
    fields?: SafetyTrainingAttachmentFieldPolicy;
  };
  SafetyTrainingAttendee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyTrainingAttendeeKeySpecifier | (() => undefined | SafetyTrainingAttendeeKeySpecifier);
    fields?: SafetyTrainingAttendeeFieldPolicy;
  };
  SafetyTrainingCategory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyTrainingCategoryKeySpecifier | (() => undefined | SafetyTrainingCategoryKeySpecifier);
    fields?: SafetyTrainingCategoryFieldPolicy;
  };
  SafetyTrainingDocument?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyTrainingDocumentKeySpecifier | (() => undefined | SafetyTrainingDocumentKeySpecifier);
    fields?: SafetyTrainingDocumentFieldPolicy;
  };
  SafetyTrainingIndustry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SafetyTrainingIndustryKeySpecifier | (() => undefined | SafetyTrainingIndustryKeySpecifier);
    fields?: SafetyTrainingIndustryFieldPolicy;
  };
  ScheduleReminder?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ScheduleReminderKeySpecifier | (() => undefined | ScheduleReminderKeySpecifier);
    fields?: ScheduleReminderFieldPolicy;
  };
  ScheduleReminderLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ScheduleReminderLinkKeySpecifier | (() => undefined | ScheduleReminderLinkKeySpecifier);
    fields?: ScheduleReminderLinkFieldPolicy;
  };
  SelfSignature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SelfSignatureKeySpecifier | (() => undefined | SelfSignatureKeySpecifier);
    fields?: SelfSignatureFieldPolicy;
  };
  Tag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagKeySpecifier | (() => undefined | TagKeySpecifier);
    fields?: TagFieldPolicy;
  };
  TagDocumentLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagDocumentLinkKeySpecifier | (() => undefined | TagDocumentLinkKeySpecifier);
    fields?: TagDocumentLinkFieldPolicy;
  };
  TagMediaCollectionLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagMediaCollectionLinkKeySpecifier | (() => undefined | TagMediaCollectionLinkKeySpecifier);
    fields?: TagMediaCollectionLinkFieldPolicy;
  };
  TimeEntry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimeEntryKeySpecifier | (() => undefined | TimeEntryKeySpecifier);
    fields?: TimeEntryFieldPolicy;
  };
  TimeEntryBreak?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimeEntryBreakKeySpecifier | (() => undefined | TimeEntryBreakKeySpecifier);
    fields?: TimeEntryBreakFieldPolicy;
  };
  TimeEntryRekognition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimeEntryRekognitionKeySpecifier | (() => undefined | TimeEntryRekognitionKeySpecifier);
    fields?: TimeEntryRekognitionFieldPolicy;
  };
  TimeOff?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimeOffKeySpecifier | (() => undefined | TimeOffKeySpecifier);
    fields?: TimeOffFieldPolicy;
  };
  TimeOffRequest?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimeOffRequestKeySpecifier | (() => undefined | TimeOffRequestKeySpecifier);
    fields?: TimeOffRequestFieldPolicy;
  };
  WageHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WageHistoryKeySpecifier | (() => undefined | WageHistoryKeySpecifier);
    fields?: WageHistoryFieldPolicy;
  };
  Weather?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WeatherKeySpecifier | (() => undefined | WeatherKeySpecifier);
    fields?: WeatherFieldPolicy;
  };
  WeatherHourly?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WeatherHourlyKeySpecifier | (() => undefined | WeatherHourlyKeySpecifier);
    fields?: WeatherHourlyFieldPolicy;
  };
  WeatherMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WeatherMetadataKeySpecifier | (() => undefined | WeatherMetadataKeySpecifier);
    fields?: WeatherMetadataFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
