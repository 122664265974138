import { Button, DialogHeader, Form, Size } from '@busybusy/webapp-react-ui';
import { Well } from 'components';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { first, isNil } from 'lodash';
import { useState } from 'react';
import IMember from 'types/Member';
import * as arrayUtils from 'utils/arrayUtils';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import {
  EmailField,
  EmployeeGPSField,
  EmployeeIDField,
  GroupField,
  NameField,
  PasswordsField,
  PhoneField,
  PinCodeField,
  PositionField,
  ProfileImageField,
  ProjectLocationFlaggingCheckbox,
  SubContractorCheckbox,
  UsernameField,
} from './../EmployeeFormFields/EmployeeFormFields';
import { useActiveMember } from 'hooks';

export interface IEmployeesEditFormData {
  firstName?: string | null;
  lastName?: string | null;
  positionId?: string | null;
  memberNumber?: string | null;
  email?: string | null;
  phone?: string | null;
  pinCode?: string | null;
  memberGroupId?: string | null;
  username?: string | null;
  timeLocationRequired?: string | null;
  password?: string | null;
  confirmPassword?: string | null;
  archivedOn?: string | null;
  profile?: { file: File | null } | null;
  isSubContractor?: boolean;
  locationFlagging?: boolean;
  distantLocationFlaggingDisabled?: boolean;
}

interface IEmployeesEditDialogProps<T extends IMember> {
  members: T[];
  editKey: string | null;
  onEdit: (formData: IEmployeesEditFormData, members: T[]) => void;
  onClose: () => void;
}

const EmployeesEditDialog = <T extends IMember>({
  onClose,
  editKey,
  onEdit,
  members,
}: IEmployeesEditDialogProps<T>) => {
  const authMember = useActiveMember();
  const ownerId = authMember.organization!.ownedBy!;

  const [message, setMessage] = useState('');
  const [messageTheme, setMessageTheme] = useState<'primary' | 'danger'>('primary');

  useOnMount(() => {
    if (editKey === 'k_position') {
      if (containsId(members, authMember.id!)) {
        setMessageTheme('danger');
        setMessage(t('You are about to change your own position'));
      }
      if (containsId(members, ownerId)) {
        setMessageTheme('primary');
        setMessage(t("The account owner's position will not be updated."));
      }
    } else if (editKey === 'k_username' && containsId(members, authMember.id!)) {
      setMessageTheme('danger');
      setMessage(t('Changing your own username will require you to login again.'));
    } else if (editKey === 'k_password' && containsId(members, authMember.id!)) {
      setMessageTheme('danger');
      setMessage(t('Changing your own password will require you to login again.'));
    }
  });

  // Handle the user submitting the form
  function handleSubmit(formData: IEmployeesEditFormData) {
    if (formData.confirmPassword) {
      delete formData.confirmPassword;
    }

    if (!isNil(formData.locationFlagging)) {
      formData.distantLocationFlaggingDisabled = !formData.locationFlagging;
      delete formData.locationFlagging;
    }

    onEdit(formData, members);
    onClose();
  }

  // Check if an item in the array contains a specified id
  function containsId(arr: Array<{ id: string }>, id: string) {
    return arr.some((e) => e.id === id);
  }

  // Get the value for the form field.
  // If all selected members have the same value, use that value.
  // Otherwise return a fallback value
  function getValue(key: string) {
    return arrayUtils.getObjectValueIfAllSame(members, key);
  }

  // Returns the form field to display
  function renderField(form: any) {
    switch (editKey) {
      case 'k_name':
        return NameField(form, true);
      case 'k_position':
        return PositionField(form, true);
      case 'k_memberNumber':
        return EmployeeIDField(form, true);
      case 'k_email':
        return EmailField(form, true);
      case 'k_phone':
        return PhoneField(form, true);
      case 'k_pinCode':
        return PinCodeField(form, true);
      case 'k_group':
        return GroupField(form, true);
      case 'k_username':
        return UsernameField(form, true, first(members));
      case 'k_password':
        return PasswordsField(form, true);
      case 'k_gps':
        return EmployeeGPSField(form, true);
      case 'k_subcontractor':
        return SubContractorCheckbox(form);
      case 'k_locationFlagging':
        return ProjectLocationFlaggingCheckbox(form);
      case 'k_profile': {
        const member = [...members][0];
        const initialImage = (member && member.imageUrl) || null;
        return ProfileImageField(form, initialImage);
      }
      default:
        return;
    }
  }

  // Set initial values for the form data
  function getFormData() {
    const newData = {} as IEmployeesEditFormData;
    switch (editKey) {
      case 'k_name':
        newData.firstName = getValue('firstName');
        newData.lastName = getValue('lastName');
        break;
      case 'k_position':
        newData.positionId = getValue('positionId');
        break;
      case 'k_memberNumber':
        newData.memberNumber = getValue('memberNumber');
        break;
      case 'k_email':
        newData.email = getValue('email');
        break;
      case 'k_phone':
        newData.phone = getValue('phone');
        break;
      case 'k_pinCode':
        newData.pinCode = null;
        break;
      case 'k_group': {
        const newValue = getValue('memberGroupId');
        newData.memberGroupId = isNilOrEmpty(newValue) ? null : newValue;
        break;
      }
      case 'k_username':
        newData.username = getValue('username');
        break;
      case 'k_gps':
        newData.timeLocationRequired = getValue('timeLocationRequired');
        break;
      case 'k_subcontractor':
        newData.isSubContractor = getValue('isSubContractor');
        break;
      case 'k_locationFlagging':
        newData.locationFlagging = !getValue('distantLocationFlaggingDisabled');
        break;
      default:
        return newData;
    }

    return newData;
  }

  // Renders the form
  function renderForm({ ...form }) {
    return (
      <>
        {renderField(form)}
        <Button type="primary" onClick={form.handleSubmit}>
          {t('Update')}
        </Button>
      </>
    );
  }

  // Render
  return (
    <>
      <div className="px-8 pb-8">
        <DialogHeader size={Size.MEDIUM} />
        <div className="mb-6">
          {message && (
            <Well theme={messageTheme} className="mb-5">
              {message}
            </Well>
          )}
          <Form data={getFormData()} onSubmit={handleSubmit} render={renderForm} />
        </div>
      </div>
    </>
  );
};

export default EmployeesEditDialog;
