import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  employee_id?: string;
  filter_type?: string;
  exitToLegacy?: boolean;
  issue_status?: string;
  signature_status?: string;
  end_date?: string;
  time_range_type?: string;
}

export default function useSignInReportNavigation() {
  return useNavigation<IParams>('/daily-sign-in');
}
