import { ILogFeedTimeGrouping } from 'containers/time-log-feed/types/types';
import { usePermissions } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { IMember } from 'types';
import IClientTimeEntryLog from 'types/ClientTimeEntryLog';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import { dateTimeFromISOKeepZone, localizeDateTimeFromUtcISO } from 'utils/dateUtils';
import { getMemberLocationType } from 'utils/memberLocationsUtils';
import { getSafeArray, getSafeString } from 'utils/optionalUtils';
import { getFormattedPathFromProjectWithFallback } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import { getClockActionTitle } from 'utils/timeEntryLogUtils';
import { Project } from '__generated__/graphql';
import { ITimeLogFeedTableRow } from '../../TimeLogFeedTable/TimeLogFeedTable';

export default function useTimeLogFeedDataToRowConverter() {
  const { hasPermissionToManage } = usePermissions();
  const employeeNameFormatter = useEmployeeNameFormatter();

  return useCallback(
    ({ locationId, breakLogs, timeEntryLog }: ILogFeedTimeGrouping): ITimeLogFeedTableRow => {
      const common = {
        employeeName: employeeNameFormatter(
          getSafeString(timeEntryLog?.member?.firstName),
          getSafeString(timeEntryLog?.member?.lastName)
        ),
        details: {
          project: getFormattedPathFromProjectWithFallback(
            timeEntryLog?.project as Pick<Project, 'ancestors' | 'title' | 'depth'>,
            true
          ),
          costCode: getCostCodeDisplay(timeEntryLog?.costCode),
          equipment: getEquipmentDisplay(timeEntryLog?.equipment),
        },
      };

      const isUserManageable = hasPermissionToManage(timeEntryLog.member as IMember, 'manageTimeEntries');
      const performedDateTime = localizeDateTimeFromUtcISO(timeEntryLog.createdOn);

      return {
        ...common,
        actionType: timeEntryLog.actionType,
        timeEntryLogId: timeEntryLog.id,
        timeEntryId: timeEntryLog.timeEntryId,
        isUserManageable,
        isTimeEntryDeleted: !isNil(timeEntryLog.timeEntry?.deletedOn),
        details: {
          ...common.details,
          startDate: {
            dateTime: dateTimeFromISOKeepZone(timeEntryLog.startTime),
            dst: timeEntryLog.daylightSavingTime,
          },
          endDate: timeEntryLog.endTime
            ? {
                dateTime: dateTimeFromISOKeepZone(timeEntryLog.endTime),
                dst: !!timeEntryLog.metaDaylightSavingTime,
              }
            : null,
        },
        // TODO update utils types
        action: getClockActionTitle(
          timeEntryLog as unknown as IClientTimeEntryLog,
          breakLogs,
          getSafeArray(timeEntryLog.timeEntry?.breaks) as ITimeEntryBreak[]
        ),
        device: timeEntryLog.deviceType ?? null,
        location: locationId
          ? {
              id: locationId,
              type: getMemberLocationType(locationId, timeEntryLog.timeEntry!),
            }
          : null,
        performed: performedDateTime,
        performerName: employeeNameFormatter(
          getSafeString(timeEntryLog.updaterMember?.firstName),
          getSafeString(timeEntryLog.updaterMember?.lastName)
        ),
      };
    },
    [employeeNameFormatter, hasPermissionToManage]
  );
}
