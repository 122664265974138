import { ClockInRestrictionType } from '__generated__/graphql';
import { QUERY_SCHEDULE_REMINDERS } from 'apollo/queries/schedule-reminder-queries';
import useSchedulingRecurringDataQuery from 'containers/scheduling/hooks/useSchedulingRecurringScheduleDataQuery';
import {
  convertPreprocessedScheduleReminderDetailsToScheduleReminderDetails,
  convertRecurringScheduleToRecurringScheduleDetails,
  convertScheduleRemindersToPreprocessedScheduleReminderDetails,
  filterOverriddenRecurringScheduleEvents,
} from 'containers/scheduling/utils/utils';
import { useTimeRounding } from 'hooks/settings';
import { useActiveMember } from 'hooks/store';
import { isEmpty, partition } from 'lodash';
import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import { useFeatureFlags } from 'utils/features';
import useScheduleReminderQuery from '../schedule-reminder/useScheduleReminderQuery';
import useClockInRestrictionTimeConverter from './useClockInRestrictionTimeConverter';

export const CLOCK_IN_RESTRICTION_MINUTE_LENIENCE = 0;

export type ClockInRestrictionReason = 'startTime' | 'noSchedule' | 'beforeSchedule' | 'afterSchedule' | null;

export interface IClockInRestrictionReasonPayload {
  reason: ClockInRestrictionReason;
  futureAllowance: DateTime | null;
}

export type ClockInRestrictionReasonPayloadOptions =
  | {
      reason: 'startTime';
      futureAllowance: DateTime;
    }
  | {
      reason: 'noSchedule';
      futureAllowance: null;
    }
  | {
      reason: 'beforeSchedule';
      futureAllowance: DateTime;
    }
  | {
      reason: 'afterSchedule';
      futureAllowance: null;
    }
  | {
      reason: null;
      futureAllowance: null;
    };

const allowedValue = { reason: null, futureAllowance: null };

export default function useClockInRestricted() {
  const scheduleReminderQuery = useScheduleReminderQuery();
  const converter = useClockInRestrictionTimeConverter();
  const member = useActiveMember();
  const isPro = useFeatureFlags('PRO');
  const clockInRestrictionToggled = useFeatureFlags('CLOCK_IN_RESTRICTION');
  const { roundTime } = useTimeRounding();
  const now = roundTime(DateTime.local(), ClockAction.CLOCK_IN).minus({
    minutes: CLOCK_IN_RESTRICTION_MINUTE_LENIENCE,
  });
  const nowSeconds = now.toSeconds();

  const timeRange: ITimeRange<DateTime> = {
    startTime: now.startOf('day'),
    endTime: now.endOf('day'),
  };

  const recurringQuery = useSchedulingRecurringDataQuery(timeRange);

  async function getDaySchedules(): Promise<Array<ITimeRange<DateTime>>> {
    const nonRecurringSchedulesPromise = scheduleReminderQuery({
      query: QUERY_SCHEDULE_REMINDERS,
      variables: {
        filter: {
          links: {
            member: {
              id: { equal: member.id },
            },
          },
          deletedOn: { isNull: true },
          startTime: { lessThanOrEqual: timeRange.endTime.toISO() },
          endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
          isDraft: { equal: false },
        },
      },
    });

    const recurringSchedulesPromise = recurringQuery({
      links: { memberId: { equal: member.id } },
      isDraft: { equal: false },
    });

    const [nonRecurringSchedulesResult, recurringSchedules] = await Promise.all([
      nonRecurringSchedulesPromise,
      recurringSchedulesPromise,
    ]);

    const nonRecurringSchedules = nonRecurringSchedulesResult?.data?.scheduleReminders ?? [];
    const scheduleReminderDetails =
      convertScheduleRemindersToPreprocessedScheduleReminderDetails(nonRecurringSchedules);
    const processedScheduleReminders =
      convertPreprocessedScheduleReminderDetailsToScheduleReminderDetails(scheduleReminderDetails);

    const recurringSchedulesExpanded = recurringSchedules.flatMap((schedule) =>
      convertRecurringScheduleToRecurringScheduleDetails(schedule, timeRange)
    );

    const processedRecurringSchedules = filterOverriddenRecurringScheduleEvents(
      recurringSchedulesExpanded,
      scheduleReminderDetails
    );

    const allSchedules = [...processedScheduleReminders, ...processedRecurringSchedules];

    return allSchedules.map(({ startTime, endTime }) => ({
      startTime: DateTime.fromISO(startTime!),
      endTime: DateTime.fromISO(endTime!),
    }));
  }

  async function isClockInRestrictionOn() {
    const clockInRestrictionEnabled = isPro && clockInRestrictionToggled;
    if (!clockInRestrictionEnabled) {
      return false;
    }

    const clockInRestrictionTurnedOff =
      !member.position?.clockInRestriction || member.position.clockInRestriction === ClockInRestrictionType.Off;

    if (clockInRestrictionTurnedOff) {
      return false;
    }

    return true;
  }

  async function getFirstUpcomingSchedule(schedules: ITimeRange<DateTime>[]) {
    const anySchedulesUpcoming = schedules.filter(({ startTime }) => {
      return nowSeconds < startTime.toSeconds();
    });

    return anySchedulesUpcoming.reduce(
      (acc, cur) => {
        if (!acc) {
          return cur.startTime;
        }

        return acc.toSeconds() < cur.startTime.toSeconds() ? acc : cur.startTime;
      },
      null as DateTime | null
    );
  }

  async function handleTimeClockInRestriction(): Promise<ClockInRestrictionReasonPayloadOptions> {
    if (!member?.position) {
      return allowedValue;
    }

    const dateTime = converter.fromClockInRestriction(member.position.clockInRestrictionStartTime);
    const startTimeAppeased = nowSeconds >= dateTime.toSeconds();

    if (startTimeAppeased) {
      return allowedValue;
    }

    const schedules = await getDaySchedules();
    const [scheduleAllowance, scheduleDisallowance] = partition(schedules, ({ startTime, endTime }) => {
      return nowSeconds <= endTime.toSeconds() && nowSeconds >= startTime.toSeconds();
    });

    if (!isEmpty(scheduleAllowance)) {
      return allowedValue;
    }

    const firstSchedule = await getFirstUpcomingSchedule(scheduleDisallowance);

    return {
      reason: 'startTime',
      futureAllowance: !firstSchedule || dateTime.toSeconds() < firstSchedule?.toSeconds() ? dateTime : firstSchedule,
    };
  }

  async function handleScheduleRestriction(): Promise<ClockInRestrictionReasonPayloadOptions> {
    const schedules = await getDaySchedules();
    const [scheduleAllowance, scheduleDisallowance] = partition(schedules, ({ startTime, endTime }) => {
      return nowSeconds <= endTime.toSeconds() && nowSeconds >= startTime.toSeconds();
    });

    if (!isEmpty(scheduleAllowance)) {
      return allowedValue;
    }

    if (isEmpty(scheduleDisallowance)) {
      return { reason: 'noSchedule', futureAllowance: null };
    }

    const anySchedulesUpcoming = scheduleDisallowance.filter(({ startTime }) => {
      return nowSeconds < startTime.toSeconds();
    });

    if (!isEmpty(anySchedulesUpcoming)) {
      const firstAllowance = anySchedulesUpcoming.reduce(
        (acc, cur) => {
          if (!acc) {
            return cur.startTime;
          }

          return acc.toSeconds() < cur.startTime.toSeconds() ? acc : cur.startTime;
        },
        null as DateTime | null
      );

      return { reason: 'beforeSchedule', futureAllowance: firstAllowance! };
    }

    return { reason: 'afterSchedule', futureAllowance: null };
  }

  return async function (): Promise<ClockInRestrictionReasonPayloadOptions> {
    if (!isClockInRestrictionOn()) {
      return allowedValue;
    }

    if (member.position?.clockInRestriction === ClockInRestrictionType.Time) {
      return handleTimeClockInRestriction();
    } else if (member.position?.clockInRestriction === ClockInRestrictionType.Schedule) {
      return handleScheduleRestriction();
    } else {
      return allowedValue;
    }
  };
}
