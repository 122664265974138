import { DocumentNode, FetchResult, useMutation } from '@apollo/client';
import { UPDATE_TIME_OFF } from 'apollo/mutations/time-off-mutations';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import TimeOffType from 'types/enum/TimeOffType';
import ITimeOff, { ITimeOffUpdateInput } from 'types/TimeOff';

export default function useTimeOffEdit<U>(updateTimeOffDocumentNode: DocumentNode = UPDATE_TIME_OFF) {
  const [updateTimeOffMutation] = useMutation<U>(updateTimeOffDocumentNode);

  return function (
    timeOff: Pick<ITimeOff, 'id'>,
    paid: boolean,
    type: TimeOffType,
    total: number | null,
    description: string | null,
    date: Date
  ): Promise<FetchResult<U>> {
    const seconds: number | null = paid && !isNull(total) ? total : null;

    const entry: ITimeOffUpdateInput = {
      id: timeOff.id,
      dateStamp: DateTime.fromJSDate(date).startOf('day').toISO({ suppressMilliseconds: true, includeOffset: false }),
      type,
      paid,
      seconds,
      description,
    };

    return updateTimeOffMutation({ variables: { timeOff: entry } });
  };
}
