import gql from 'graphql-tag';

export const equipmentModelsQuery = gql`
  query EquipmentModels($first: Int, $after: String, $filter: EquipmentModelFilter) {
    equipmentModels(first: $first, after: $after, filter: $filter, sort: [{ title: asc }, { year: desc }]) {
      id
      title
      year
      unknown
      cursor
    }
  }
`;

export const equipmentModelQuery = gql`
  query EquipmentModelById($modelId: Uuid!) {
    equipmentModels(filter: { id: { equal: $modelId } }) {
      id
      title
      year
      unknown
      cursor
    }
  }
`;

export const equipmentUnknownModelQuery = gql`
  query EquipmentModelByCategoryId($categoryId: Uuid!) {
    equipmentModels(filter: { equipmentCategoryId: { equal: $categoryId }, unknown: { equal: true } }) {
      id
      title
      year
      unknown
      cursor
    }
  }
`;

export const equipmentModelTitlesQuery = gql`
  query EquipmentModelByCategoryMakeId($categoryId: Uuid!, $makeId: Uuid!) {
    equipmentModelTitles(equipmentCategoryId: $categoryId, equipmentMakeId: $makeId)
  }
`;
