import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { isNil } from 'lodash';
import { createContext, useContext, useReducer } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';
import ITimeEntry from 'types/TimeEntry';

interface ITimeEntryActionBarReducerState {
  entries: ITimeEntry[];
  memberIds: string[];
  formData: ITimeActionsFormData | null;
}

type Action =
  | { type: 'ON_BULK_EDIT_SUBMIT'; payload: ITimeEntry[] }
  | { type: 'ON_DELETE'; payload: string[] }
  | { type: 'ON_TIME_ENTRY_EDIT'; payload: ITimeActionsFormData | null };

export function timeEntryActionBarReducer(
  state: ITimeEntryActionBarReducerState,
  action: Action
): ITimeEntryActionBarReducerState {
  switch (action.type) {
    case 'ON_BULK_EDIT_SUBMIT':
      return {
        ...state,
        entries: action.payload,
      };
    case 'ON_DELETE':
      return {
        ...state,
        memberIds: action.payload,
      };
    case 'ON_TIME_ENTRY_EDIT':
      return {
        ...state,
        formData: action.payload,
      };
  }
}

export const TimeEntryActionBarContext = createContext<
  [ITimeEntryActionBarReducerState, React.Dispatch<Action>] | null
>(null);

export function useTimeEntryActionBarState() {
  const context = useContext(TimeEntryActionBarContext);

  if (isNil(context)) {
    throw new Error('useTimeEntryActionBarState must be nested within a TimeEntryActionBarContext.Provider.');
  }

  return context;
}

export function TimeEntryActionBarContextProvider(props: IChildrenProps) {
  const reducer = useReducer(timeEntryActionBarReducer, {
    entries: [],
    memberIds: [],
    formData: null,
  });

  return <TimeEntryActionBarContext.Provider value={reducer}>{props.children}</TimeEntryActionBarContext.Provider>;
}
