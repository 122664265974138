const DateTimeFormat = {
  date: 'DD',
  dateAtTime: 'DD @ t',
  dateAtTimeDay: 'EEE, DD @ t',
  datePickerPayload: 'M/D/YYYY',
  abbreviatedHourMinute: `H' hrs 'M' min'`,
  abbreviateMinute: `'M' min`,
  monthDay: 'LLL d', // May 25
  weekDayMonthDay: 'ccc, LLL d', // Wed, May 25
  weekDayMonthDayYear: 'ccc, LLL d, yyyy', // Wed, May 25, 2022
  longWeekDayMonthDay: 'cccc, LLL d', // Wednesday, May 25
  monthDayYear: 'LLL d, yyyy', // Oct 16, 2022
  timeSimple: 'h:mm a', // 12:00 PM
  hourMinuteDuration: 'h:mm',
};

export default DateTimeFormat;
