import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { TimeEntryActionBarContextProvider } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import ActivityDetailsDialog from 'containers/activity-reports/activity-report-details/ActivityDetailsDialog/ActivityDetailsDialog';
import ChannelContextProvider from 'contexts/ChannelContext/ChannelContext';
import { useOpenable } from 'hooks';
import { isNull } from 'lodash';
import useActiveCardReportNavigation from '../../hooks/useActiveCardReportNavigation';
import useActivityReportDetailNavigation from '../../hooks/useActivityReportDetailNavigation';
import ActiveEquipmentDashboardCardContent from '../ActiveEquipmentDashboardCardContent/ActiveEquipmentDashboardCardContent';
import { defaultActiveEquipmentDashboardCardTimeFrame } from '../constants/constants';
import useActiveEquipmentDashboard from './hooks/useActiveEquipmentDashboard';

export interface IActiveEquipmentDashboardCardProps {
  theme: Theme.DARK | Theme.LIGHT;
  setting: IDashboardSettingsItem;
  onHide: () => void;
  className?: ClassName;
  memberId?: string | null;
  projectId?: string | null;
  costCodeId?: string | null;
}

const ActiveEquipmentDashboardCard = ({
  setting,
  theme,
  onHide,
  className,
  memberId,
  projectId,
  costCodeId,
}: IActiveEquipmentDashboardCardProps) => {
  const activityReportNavigation = useActiveCardReportNavigation(setting.options?.time, 'by-equipment');
  const { timeRangeType, navRange, getActivityReportFilter, getActivityReportFilterType } =
    useActivityReportDetailNavigation(setting.options?.time, memberId, costCodeId, undefined, projectId);
  const activityReportDetailsDialog = useOpenable();
  const timeFrame = setting.options?.time ?? defaultActiveEquipmentDashboardCardTimeFrame;

  const { data, loading, error, execute } = useActiveEquipmentDashboard(timeFrame, memberId, projectId, costCodeId);

  function handleRightClick() {
    if (isNull(getActivityReportFilter())) {
      activityReportNavigation();
    } else {
      activityReportDetailsDialog.open();
    }
  }

  const classes = classNames('active-equipment-dashboard-card', className);

  return (
    <>
      <ActiveEquipmentDashboardCardContent
        className={classes}
        data={data}
        theme={theme}
        loading={loading}
        error={error}
        onReload={execute}
        onDetailClick={handleRightClick}
        onHide={onHide}
        setting={setting}
      />
      {!isNull(timeRangeType) && !isNull(navRange) && (
        <TimeEntryActionBarContextProvider>
          <ChannelContextProvider>
            <ActivityDetailsDialog
              isOpen={activityReportDetailsDialog.isOpen}
              onClose={activityReportDetailsDialog.close}
              timeRange={navRange}
              timeRangeType={timeRangeType}
              filterType={getActivityReportFilterType()}
              filterId={getActivityReportFilter()}
              initialReportType={ActivityReportType.BY_EQUIPMENT}
            />
          </ChannelContextProvider>
        </TimeEntryActionBarContextProvider>
      )}
    </>
  );
};

export default ActiveEquipmentDashboardCard;
