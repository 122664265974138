import gql from 'graphql-tag';

export const EQUIPMENT_DISPLAY_FRAGMENT = gql`
  fragment EquipmentDisplay on Equipment {
    id
    equipmentName
    model {
      id
      category {
        id
        title
      }
      make {
        id
        title
      }
      title
      year
    }
  }
`;
