import {
  Align,
  Bar,
  ISortPayload,
  ITableColumn,
  Justify,
  Label,
  Loader,
  Row,
  SortDirection,
  Table,
  TextAlign,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import { EQUIPMENT_HOURS_REPORT_QUERY } from 'apollo/queries/equipment-hours-queries';
import classNames from 'classnames';
import HourMeterDetails from 'components/domain/hour-meter/HourMeterDetails/HourMeterDetails';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useApolloPaging, useEquipmentHours, useOpenable } from 'hooks';
import _, { isEmpty, isNil, last, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ClassName } from 'types/ClassName';
import IEquipment from 'types/Equipment';
import IEquipmentHours from 'types/EquipmentHours';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateTimeFromUtcISO, getDateString } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import './HourMeterReportTable.scss';

export interface IHourMeterReportTableProps {
  className?: ClassName;
  equipment: IEquipment;
  timeRange: ITimeRange<DateTime>;
  timeRangeType?: TimeRangeType;
}

interface IHourMeterReportTableData {
  id: string;
  date: DateTime;
  dateString: string;
  employeeName: string;
  hours: number;
  meter: number;
}

const HourMeterReportTable = (props: IHourMeterReportTableProps) => {
  const { className, equipment, timeRange, timeRangeType } = props;

  const { getAll } = useApolloPaging();
  const { getEquipmentHoursBefore } = useEquipmentHours();
  const loaderDetails = useOpenable();
  const [data, setData] = useState<IHourMeterReportTableData[]>([]);
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.DESCENDING);
  const [sortBy, setSortBy] = useState<keyof IHourMeterReportTableData>('date');
  const [sortIsDirty, setSortIsDirty] = useState<boolean>(false);
  const sortedRows = useMemo(() => performSort(data, sortDir, sortBy), [sortDir, sortBy, data]);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [total, setTotal] = useState<string>('---');
  const hourMeterDetails = useOpenable();
  const [selectedHourMeterId, setSelectedHourMeterId] = useState<string | null>(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, equipment]);

  async function getData() {
    try {
      const equipmentHours = await getAll<IEquipmentHours>('equipmentHours', {
        query: EQUIPMENT_HOURS_REPORT_QUERY,
        variables: {
          first: 500,
          filter: {
            equipmentId: { equal: equipment.id },
            deletedOn: { isNull: true },
            createdOn:
              timeRangeType !== TimeRangeType.ALL_TIME
                ? {
                    between: {
                      start: timeRange.startTime
                        .minus(timeRange.startTime.toLocal().offset * 60 * 1000)
                        .toISO({ suppressSeconds: true }),
                      end: timeRange.endTime
                        .minus(timeRange.endTime.toLocal().offset * 60 * 1000)
                        .toISO({ suppressSeconds: true }),
                    },
                  }
                : undefined,
          },
          sort: [{ createdOn: 'asc' }, { runningHours: 'asc' }],
        },
        fetchPolicy: 'network-only',
      });

      const hoursBefore = await getEquipmentHoursBefore(
        timeRange.startTime.minus(timeRange.startTime.toLocal().offset * 60 * 1000),
        equipment.id
      );

      if (isEmpty(equipmentHours)) {
        setData([]);
        return;
      }

      const rows: IHourMeterReportTableData[] = [];
      equipmentHours.forEach((e, i) => {
        let nextHours: IEquipmentHours | undefined = equipmentHours[i - 1];
        if (isNil(nextHours)) {
          nextHours = hoursBefore;
        }
        const hours = e.runningHours - (nextHours?.runningHours ?? 0);
        const item: IHourMeterReportTableData = {
          id: e.id,
          date: dateTimeFromUtcISO(e.createdOn).toLocal(),
          dateString: getDateString(dateTimeFromUtcISO(e.createdOn).toLocal(), 'EEE, LLL d', true) ?? '---',
          employeeName: !isNil(e.submittedByMember) ? fullName(e.submittedByMember) : t('VisionLink'),
          hours: hours === e.runningHours ? 0 : hours,
          meter: e.runningHours,
        };
        rows.push(item);
      });
      setData(rows);
      const recentHours = last(equipmentHours);
      setTotal(recentHours?.runningHours.toFixed(1) ?? '---');
    } catch (error) {
      setErrorToastMessage(t('Server Error'));
      return;
    }
  }

  function performSort(data: IHourMeterReportTableData[], sortDir: SortDirection, sortKey: string) {
    let sortedData;

    if (sortKey === 'employeeName') {
      sortedData = _.sortBy(data, (item) => item.employeeName.toLowerCase());
    } else {
      sortedData = _.sortBy(data, sortKey);
    }

    return sortDir === SortDirection.DESCENDING ? sortedData.reverse() : sortedData;
  }

  function handleSort(sort: ISortPayload<IHourMeterReportTableData[]>) {
    setSortBy(sort.sortBy as any);
    setSortDir(sort.sortDir);
    setSortIsDirty(true);
  }

  function renderEmptyState(): ReactNode {
    return (
      <Row justify={Justify.CENTER} align={Align.CENTER} className="empty-hour-state">
        {t('There are no hours for this date range.')}
      </Row>
    );
  }

  function renderColumns(): Array<ITableColumn<IHourMeterReportTableData>> {
    const columns: Array<ITableColumn<IHourMeterReportTableData>> = [];
    columns.push({
      cell: renderDateColumn,
      title: t('Date'),
      key: 'dateString',
      sort: true,
      align: TextAlign.LEFT,
      size: '100px',
      footer: renderDateColumnFooter,
    });
    columns.push({
      cell: renderEmployeeColumn,
      title: t('Submitted By'),
      key: 'employeeName',
      sort: true,
      align: TextAlign.LEFT,
      size: '100px',
    });
    columns.push({
      cell: renderMeterColumn,
      title: t('Meter'),
      key: 'meter',
      sort: true,
      align: TextAlign.RIGHT,
      size: '100px',
    });
    columns.push({
      cell: renderHoursColumn,
      title: t('Hours'),
      key: 'hours',
      sort: true,
      align: TextAlign.RIGHT,
      size: '100px',
      footer: renderHoursColumnFooter,
    });
    return columns;
  }

  function renderDateColumn(row: IHourMeterReportTableData, _col: ITableColumn<IHourMeterReportTableData>): ReactNode {
    return <Label className="pt-3">{row.dateString}</Label>;
  }

  function renderEmployeeColumn(
    row: IHourMeterReportTableData,
    _col: ITableColumn<IHourMeterReportTableData>
  ): ReactNode {
    return <Label className="pt-3">{row.employeeName}</Label>;
  }

  function renderHoursColumn(row: IHourMeterReportTableData, _col: ITableColumn<IHourMeterReportTableData>): ReactNode {
    return (
      <Label className="pt-3">
        {Math.sign(row.hours) === -1 ? '- ' + (row.hours * -1).toFixed(1) : '+ ' + row.hours.toFixed(1)}
      </Label>
    );
  }

  function renderMeterColumn(row: IHourMeterReportTableData, _col: ITableColumn<IHourMeterReportTableData>): ReactNode {
    return <Label className="pt-3">{row.meter.toFixed(1)}</Label>;
  }

  function renderDateColumnFooter(): ReactNode {
    return <Label>{t('Totals')}</Label>;
  }

  function renderHoursColumnFooter(_col: ITableColumn<IHourMeterReportTableData>): ReactNode {
    let totalText = '0.0';
    if (data.length > 0) {
      totalText = sumBy(data, (d) => d.hours).toFixed(1);
    }
    return <Label>{totalText}</Label>;
  }

  function handleRowClick(row: IHourMeterReportTableData) {
    setSelectedHourMeterId(row.id);
    hourMeterDetails.open();
  }

  function handleErrorToastClose() {
    setErrorToastMessage('');
  }

  function handleDetailsClose() {
    setSelectedHourMeterId(null);
    hourMeterDetails.close();
  }

  const classes = classNames('hour-meter-report-table', className);

  return (
    <>
      <div className={classes}>
        <Bar justify={Justify.SPACE_BETWEEN} className="p-3">
          <Label>{equipment.equipmentName}</Label>
          <div className="bar-actions">
            <Label className="p-2">{total}</Label>
          </div>
        </Bar>
        <Table<IHourMeterReportTableData>
          className={classes}
          cols={renderColumns()}
          data={sortedRows}
          strokeCols={true}
          lazyLoad={false}
          minWidth={`${renderColumns().length * 200}px`}
          onRowClick={handleRowClick}
          sortDir={sortDir}
          sortBy={sortBy}
          onSort={handleSort}
          sortIsDirty={sortIsDirty}
          header="standard"
          footer="standard"
          emptyTemplate={renderEmptyState()}
        />

        <Loader isOpen={loaderDetails.isOpen} overlay={true} />
      </div>
      <Toast isOpen={errorToastMessage.length !== 0} onClose={handleErrorToastClose} theme={Theme.DANGER}>
        {errorToastMessage}
      </Toast>
      <HeaderDialog
        isOpen={hourMeterDetails.isOpen}
        title={t('Hour Meter Entry')}
        onClose={handleDetailsClose}
        className={classes}
      >
        {selectedHourMeterId && <HourMeterDetails equipmentHourId={selectedHourMeterId} />}
      </HeaderDialog>
    </>
  );
};

export default HourMeterReportTable;
