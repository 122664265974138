import gql from 'graphql-tag';

const CostCodesFragments = {
  details: gql`
    fragment CostCodeDetails on CostCode {
      id
      costCode
      costCodeGroup {
        id
        groupName
      }
      costCodeGroupId
      title
      unitTitle
      workersCompId
      createdOn
      updatedOn
      archivedOn
      updatedByMember {
        id
        firstName
        lastName
      }
    }
  `,
};

export const costCodesQuery = gql`
  query costCodes($filter: CostCodeFilter!, $first: Int, $after: String, $sort: [CostCodeSort!]) {
    costCodes(filter: $filter, first: $first, after: $after, sort: $sort) {
      cursor
      ...CostCodeDetails
    }
  }
  ${CostCodesFragments.details}
`;

export const createCostCodeMutation = gql`
  mutation createCostCode2($costCode: CostCodeCreateInput!) {
    createCostCode(costCode: $costCode) {
      ...CostCodeDetails
    }
  }
  ${CostCodesFragments.details}
`;

export const updateCostCodeMutation = gql`
  mutation updateCostCode($costCode: CostCodeUpdateInput!) {
    updateCostCode(costCode: $costCode) {
      ...CostCodeDetails
    }
  }
  ${CostCodesFragments.details}
`;

export const importCostCodeMutation = gql`
  mutation createCostCode3($costCode: CostCodeCreateInput!) {
    createCostCode(costCode: $costCode) {
      costCode
      title
      unitTitle
    }
  }
`;
