import { ILogFeedQueryParams } from 'containers/time-log-feed/types/types';
import { useQueryParams } from 'hooks';
import useTimeRangeTypeTimeRangeConverter from 'hooks/utils/useTimeRangeTypeTimeRangeConverter/useTimeRangeTypeTimeRangeConverter';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import TimeRangeType from 'types/TimeRangeType';
import { QueryParam } from 'utils/constants/queryParam';
import { timeEntryLogActionTypeToQueryParamMap } from 'utils/constants/queryParamConverter';

export function convertTimeLogTypedParamsToQueryStringParams(params: ILogFeedQueryParams) {
  return {
    [QueryParam.ACTION_TYPE]: params.actionType ? timeEntryLogActionTypeToQueryParamMap[params.actionType] : null,
    [QueryParam.EMPLOYEE_ID]: params.memberId,
    [QueryParam.EMPLOYEE_GROUP_ID]: params.memberGroupId,
    [QueryParam.PROJECT_ID]: params.projectId,
    [QueryParam.PROJECT_GROUP_ID]: params.projectGroupId,
    [QueryParam.COST_CODE_ID]: params.costCodeId,
    [QueryParam.COST_CODE_GROUP_ID]: params.costCodeGroupId,
    [QueryParam.EQUIPMENT_ID]: params.equipmentId,
    [QueryParam.EQUIPMENT_CATEGORY_ID]: params.equipmentCategoryId,
    [QueryParam.POSITION_ID]: params.positionId,
    [QueryParam.START_DATE]: params.timeRange?.startTime?.toISODate() ?? undefined,
    [QueryParam.END_DATE]: params.timeRange?.endTime?.toISODate() ?? undefined,
    [QueryParam.PERFORMER_ID]: params.performerId,
    [QueryParam.TIME_RANGE_TYPE]: params.timeRangeType,
    [QueryParam.TIME_ENTRY_STATE]: params.timeEntryState,
  };
}

export default function useTimeLogFeedSetQueryParams() {
  const { updateParams, getParam } = useQueryParams();
  const timeRangeConverter = useTimeRangeTypeTimeRangeConverter();

  return useCallback(
    (params: ILogFeedQueryParams) => {
      const oldTimeRangeType = getParam(QueryParam.TIME_RANGE_TYPE);
      const didTimeRangeTypeChange = oldTimeRangeType !== params.timeRangeType;
      const anchorDate = didTimeRangeTypeChange ? DateTime.local() : params.timeRange?.endTime;

      const updatedTimeRange =
        params.timeRangeType !== TimeRangeType.CUSTOM
          ? timeRangeConverter(params.timeRangeType, 'system', anchorDate)
          : params.timeRange;

      updateParams(
        convertTimeLogTypedParamsToQueryStringParams({
          ...params,
          timeRange: updatedTimeRange,
        })
      );
    },
    [timeRangeConverter, updateParams, getParam]
  );
}
