import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  SIMPLE_PROJECT_WITH_METRIC_QUERY,
  SIMPLE_PROJECT_WITH_TIME_ONLY_METRIC_QUERY,
} from 'apollo/queries/project-queries';
import {
  COST_CODES_WITH_METRICS_QUERY,
  COST_CODES_WITH_TIME_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import {
  SIMPLE_EQUIPMENT_WITH_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import {
  MEMBERS_WITH_METRIC_QUERY,
  MEMBERS_WITH_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { isNil, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { IMember } from 'types';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import {
  getNoCostCodeMetricData,
  getNoEquipmentMetricData,
  getNoProjectMetricData,
  getOrganizationMetrics,
} from 'utils/jitMetricQueries';
import { getMetricLaborTotals } from 'utils/jitMetricUtils';
import { LaborMetricsInterval } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { IDateActivityTableRowInfo } from './useDateActivity';

export interface IDateRangeByDayActivityTableRowInfo extends IDateActivityTableRowInfo {
  day: DateTime | null;
}

export default function useDateActivityDayDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const [data, setData] = useState<IDateRangeByDayActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'startDate',
    SortDirection.ASCENDING
  );

  async function loadData() {
    let metrics: IJitLaborMetric[] | undefined | null;
    let metricsByDay: IJitLaborMetric[] | undefined | null;

    if (filterId === remainingDataItemId) {
      metrics = await loadRemainingMetrics(intervalType);
      metricsByDay = await loadRemainingMetrics(LaborMetricsInterval.Day);
    } else {
      switch (filterType) {
        case ActivityReportType.BY_COST_CODE:
          metrics = await loadCostCodeMetrics(intervalType);
          metricsByDay = await loadCostCodeMetrics(LaborMetricsInterval.Day);
          break;
        case ActivityReportType.BY_PROJECT:
          metrics = await loadProjectMetrics(intervalType);
          metricsByDay = await loadProjectMetrics(LaborMetricsInterval.Day);
          break;
        case ActivityReportType.BY_EQUIPMENT:
          metrics = await loadEquipmentMetrics(intervalType);
          metricsByDay = await loadEquipmentMetrics(LaborMetricsInterval.Day);
          break;
        case ActivityReportType.BY_EMPLOYEE:
          metrics = await loadMemberMetrics(intervalType);
          metricsByDay = await loadMemberMetrics(LaborMetricsInterval.Day);
          break;
        case ActivityReportType.BY_DATE_RANGE:
          metrics = await getOrganizationMetrics(client, timeRange, canViewCost, intervalType);
          metricsByDay = await getOrganizationMetrics(client, timeRange, canViewCost, LaborMetricsInterval.Day);
          break;
        default:
          throw Error('Type of ' + filterType + ' is not supported');
      }
    }

    if (!isNil(metrics)) {
      setData(mapNotNull(metrics, (member) => createTableRowInfo(member, metricsByDay!)));
    } else {
      setData([]);
    }
  }

  async function loadMemberMetrics(metricsInterval: LaborMetricsInterval) {
    const members = await getAll<IMember & ICursorable>('members', {
      query: canViewCost ? MEMBERS_WITH_METRIC_QUERY : MEMBERS_WITH_TIME_ONLY_METRIC_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: filterId },
        },
        metricsInterval,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
      },
      fetchPolicy: 'network-only',
    });

    return members[0].memberLaborMetrics;
  }

  async function loadProjectMetrics(metricsInterval: LaborMetricsInterval) {
    const projects = await getAll<IProject & ICursorable>('projects', {
      query: canViewCost ? SIMPLE_PROJECT_WITH_METRIC_QUERY : SIMPLE_PROJECT_WITH_TIME_ONLY_METRIC_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: filterId },
        },
        metricsInterval,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
      },
      fetchPolicy: 'network-only',
    });

    return projects[0].projectLaborMetrics;
  }

  async function loadCostCodeMetrics(metricsInterval: LaborMetricsInterval) {
    const costCodes = await getAll<ICostCode & ICursorable>('costCodes', {
      query: canViewCost ? COST_CODES_WITH_METRICS_QUERY : COST_CODES_WITH_TIME_METRICS_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: filterId },
        },
        metricsInterval,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
      },
      fetchPolicy: 'network-only',
    });

    return costCodes[0].costCodeLaborMetrics;
  }

  async function loadEquipmentMetrics(metricsInterval: LaborMetricsInterval) {
    const equipment = await getAll<IEquipment & ICursorable>('equipment', {
      query: canViewCost ? SIMPLE_EQUIPMENT_WITH_METRIC_QUERY : SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: filterId },
        },
        metricsInterval,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
      },
      fetchPolicy: 'network-only',
    });

    return equipment[0].equipmentLaborMetrics;
  }

  async function loadRemainingMetrics(metricsInterval: LaborMetricsInterval) {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return getNoProjectMetricData(client, timeRange, canViewCost, metricsInterval);
      case ActivityReportType.BY_COST_CODE:
        return getNoCostCodeMetricData(client, timeRange, canViewCost, metricsInterval);
      case ActivityReportType.BY_EQUIPMENT:
        return getNoEquipmentMetricData(client, timeRange, canViewCost, metricsInterval);
      default:
        throw Error('Type of ' + filterType + ' is not supported for the No Item');
    }
  }

  const createTableRowInfo = (
    metric: IJitLaborMetric,
    metricsByDay: IJitLaborMetric[]
  ): IDateRangeByDayActivityTableRowInfo | null => {
    const metricTotal = getMetricLaborTotals(metric, filterIdWithDescendants === false);
    if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
      return null;
    }

    const startDate = dateTimeFromISOWithoutZone(metric.start);
    const endDate = dateTimeFromISOWithoutZone(metric.end);
    const metricsInRange = metricsByDay.filter((dayMetric) => {
      const day = dateTimeFromISOWithoutZone(dayMetric.start);
      return day >= startDate && day <= endDate;
    });
    const sortedMetrics = sortBy(metricsInRange, (metric) => metric.start);

    const detailRows: IDateRangeByDayActivityTableRowInfo[] = sortedMetrics.map((dayMetric) => {
      const dayMetricTotal = getMetricLaborTotals(dayMetric, filterIdWithDescendants === false);
      return {
        id: metric.start + dayMetric.start,
        startDate,
        endDate,
        regularHours: dayMetricTotal.regularSeconds + dayMetricTotal.paidTimeOffSeconds,
        regularHoursDec: dayMetricTotal.regularSeconds + dayMetricTotal.paidTimeOffSeconds,
        overtimeHours: dayMetricTotal.overtimeSeconds,
        overtimeHoursDec: dayMetricTotal.overtimeSeconds,
        doubleTimeHours: dayMetricTotal.doubleTimeSeconds,
        doubleTimeHoursDec: dayMetricTotal.doubleTimeSeconds,
        totalHours: dayMetricTotal.totalSeconds,
        totalHoursDec: dayMetricTotal.totalSeconds,
        regularCost: dayMetricTotal.regularCost + dayMetricTotal.timeOffCost,
        overtimeCost: dayMetricTotal.overtimeCost,
        doubletimeCost: dayMetricTotal.doubleTimeCost,
        laborBurden: dayMetricTotal.laborBurden,
        totalCost: dayMetricTotal.totalCost,
        day: dateTimeFromISOWithoutZone(dayMetric.start),
      };
    });

    const test = {
      id: metric.start,
      startDate,
      endDate,
      regularHours: metricTotal.regularSeconds + metricTotal.paidTimeOffSeconds,
      regularHoursDec: metricTotal.regularSeconds + metricTotal.paidTimeOffSeconds,
      overtimeHours: metricTotal.overtimeSeconds,
      overtimeHoursDec: metricTotal.overtimeSeconds,
      doubleTimeHours: metricTotal.doubleTimeSeconds,
      doubleTimeHoursDec: metricTotal.doubleTimeSeconds,
      totalHours: metricTotal.totalSeconds,
      totalHoursDec: metricTotal.totalSeconds,
      regularCost: metricTotal.regularCost + metricTotal.timeOffCost,
      overtimeCost: metricTotal.overtimeCost,
      doubletimeCost: metricTotal.doubleTimeCost,
      laborBurden: metricTotal.laborBurden,
      totalCost: metricTotal.totalCost,
      day: null,
      detailRows,
    };

    return test;
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
  };
}
