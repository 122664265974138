import gql from 'graphql-tag';
import { BASIC_PROJECT_LABOR_METRICS_FRAGMENT, BASIC_PROJECT_TIME_METRICS_FRAGMENT } from './jit-project-queries';

export const PROJECT_TIME_METRICS_QUERY = gql`
  query ProjectTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projectLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicProjectLaborMetrics
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_METRICS_QUERY = gql`
  query ProjectMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    projectLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...ProjectLaborMetrics
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;
