import { useApolloClient } from '@apollo/client';
import { MANAGE_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { ITimeEntryDataTableData } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import {
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { useToastOpen } from 'contexts/ToastContext';
import { useLazyLoading } from 'hooks';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import { useMounted } from 'hooks/utils/useMounted';
import { groupBy, isEmpty, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import IEquipment from 'types/Equipment';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { t } from 'utils/localize';

export interface IEquipmentTimeEntryFilter {
  timeRange: ITimeRange<DateTime>;
  includeOpen?: boolean;
  equipmentCategoryId?: string | null;
  equipmentIds?: string[] | null;
  memberIds?: string[];
  projectIds?: string[];
  costCodeIds?: string[];
  excludeDeleted?: boolean;
  projectIdWithDescendants?: boolean;
}

export function useLazyEquipmentTimeData(scroller: HTMLElement | Document | null, filter: IEquipmentTimeEntryFilter) {
  const client = useApolloClient();
  const toast = useToastOpen();
  const getTimeEntries = useTimeEntryQuery();
  const isMounted = useMounted();

  const addTimeData = useCallback(
    async (equipment: Array<IEquipment & ICursorable>) => {
      let groupedEntries: _.Dictionary<ITimeEntry[]> | null = null;
      try {
        const entries = await getTimeEntries(
          filter.timeRange,
          filter.includeOpen,
          filter.memberIds,
          filter.projectIds,
          filter.costCodeIds,
          equipment.map((m) => m.id),
          filter.projectIdWithDescendants,
          undefined,
          [MemberPermission.TIME_EVENTS]
        );
        groupedEntries = groupBy(entries, 'equipmentId');
      } catch (error) {
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      const equipmentWithEntries = equipment.map((e) => {
        const item: ITimeEntryDataTableData<IEquipment> & ICursorable = {
          item: e,
          entries: groupedEntries?.[e.id] ?? [],
          timeOffs: [],
          cursor: e.cursor,
        };
        return item;
      });
      return equipmentWithEntries.filter((e) => !isNilOrEmpty(e.entries));
    },
    [filter]
  );

  const getData = useCallback(
    async (cursor: string | null, first: number) => {
      dispatch({ type: 'SET_LOADING', payload: true });
      try {
        const results = await client.query<{ equipment: Array<IEquipment & ICursorable> }>({
          query: MANAGE_EQUIPMENT_QUERY,
          fetchPolicy: 'network-only',
          variables: getEquipmentVariables(first, cursor),
        });
        const equipment = results.data.equipment ?? [];
        if (isEmpty(equipment)) {
          return [];
        }
        return await addTimeData(equipment);
      } catch (error) {
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      return [];
    },
    [toast, getTimeEntries, addTimeData]
  );

  const getEquipmentVariables = (first: number, cursor?: string | null) => {
    const variables: any = {
      first,
      after: cursor,
      filter: {
        model: filter.equipmentCategoryId ? { category: { id: { equal: filter.equipmentCategoryId } } } : undefined,
        id: graphQLContainsIdOrEmptyItemId(filter.equipmentIds),
        deletedOn: filter.excludeDeleted ? { isNull: filter.excludeDeleted } : undefined,
        equipmentWithTime: {
          startTime: filter.timeRange.startTime
            .startOf('day')
            .toUTC()
            .toISO({ suppressMilliseconds: true, includeOffset: false }),
          endTime: filter.timeRange.endTime
            .endOf('day')
            .toUTC()
            .toISO({ suppressMilliseconds: true, includeOffset: false }),
          includeOpenEntry: filter.includeOpen,
          memberId: graphQLContainsIdOrEmptyItemId(filter.memberIds),
          costCodeId: graphQLContainsIdOrEmptyItemId(filter.costCodeIds),
          ...filterByProjectIdsOrEmptyItemId(filter.projectIds, filter.projectIdWithDescendants),
        },
      },
      sort: [{ equipmentName: 'asc' }],
    };

    return variables;
  };

  const { clearData, data, error, loading, dispatch, loadedAll, loadAll } = useLazyLoading(
    scroller,
    getData,
    undefined,
    5,
    200
  );

  const updateEquipmentIds = useCallback(
    async (equipmentIds: string[]) => {
      const allowedEquipmentIds = filter.equipmentIds
        ? equipmentIds.filter((id) => filter.equipmentIds?.some((equipmentId) => equipmentId === id))
        : equipmentIds;
      const newEquipmentData = allowedEquipmentIds.map(async (id) => {
        const equipment = data.find((datum) => datum.item.id === id);
        if (equipment?.item === undefined) {
          const results = await client.query<{ equipment: Array<IEquipment & ICursorable> }>({
            query: MANAGE_EQUIPMENT_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              filter: {
                id: graphQLContainsIdOrEmptyItemId([id]),
              },
            },
          });

          return await addTimeData(results.data.equipment);
        } else {
          return await addTimeData([{ ...equipment.item, cursor: equipment.cursor }]);
        }
      });

      const allResults = (await Promise.all(newEquipmentData)).flat();
      const newData = data.filter((object) => !equipmentIds.includes(object.item.id));

      dispatch({
        type: 'SET_DATA',
        payload: {
          data: sortBy([...newData, ...allResults], (result) => result.item.equipmentName.toLowerCase()),
          loadedAll: false,
          loading: false,
        },
      });
    },
    [addTimeData, client, data, dispatch, filter.equipmentIds, filter.timeRange]
  );

  useEffect(() => {
    if (isMounted) {
      clearData();
    }
  }, [filter, isMounted]);

  return { clearData, data, error, loading, updateEquipmentIds, loadedAll, loadAll };
}
