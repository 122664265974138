import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useEffectAfterFirstRender(action: EffectCallback, dependencies: DependencyList) {
  const firstRender = useRef(true);
  const actionRef = useRef(action);

  useEffect(() => {
    if (!firstRender.current) {
      return actionRef.current();
    }

    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
