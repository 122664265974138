import useMemberUiSettingsUpdate from 'hooks/models/member-settings/useMemberUiSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { t } from 'utils/localize';

export type EmployeeNameFormat = 'LAST_FIRST' | 'FIRST_LAST' | 'FIRST' | 'LAST';

export const employeeNameFormatLabelLookup: Record<EmployeeNameFormat, string> = {
  FIRST_LAST: t('First Last'),
  LAST_FIRST: t('Last, First'),
  FIRST: t('First'),
  LAST: t('Last'),
};

export default function useEmployeeNameFormat(): [EmployeeNameFormat, (format: EmployeeNameFormat) => Promise<void>] {
  const updateUiMemberSettings = useMemberUiSettingsUpdate();
  const memberSettings = useMemberSettings();

  const employeeNameFormat = memberSettings?.web?.ui?.employeeNameFormat ?? 'FIRST_LAST';

  const updateEmployeeNameFormat = async (format: EmployeeNameFormat) => {
    await updateUiMemberSettings('employeeNameFormat', format);
  };

  return [employeeNameFormat, updateEmployeeNameFormat];
}
