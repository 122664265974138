import { LaborMetricsInterval } from '__generated__/graphql';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { IProjectActivityLoadingData } from '../ProjectActivityTable/ProjectActivityTable';
import { IProjectActivityTableRowInfo } from './useProjectActivity';
import useProjectActivityBasicDetails from './useProjectActivityBasicDetails';
import useProjectActivityCostCodeDetails from './useProjectActivityCostCodeDetails';
import useProjectActivityDateRangeDetails from './useProjectActivityDateRangeDetails';
import useProjectActivityEquipmentDetails from './useProjectActivityEquipmentDetails';
import useProjectActivityMemberDetails from './useProjectActivityMemberDetails';

export default function useProjectActivityDetails(
  filterId: string,
  filterType: ActivityReportType,
  secondaryFilterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  dateRangeReportType?: TimeRangeType
): IProjectActivityLoadingData<IProjectActivityTableRowInfo> {
  const [isLoading, setIsLoading] = useState(false);

  const dateRangeInterval = useMemo(() => {
    if (secondaryFilterType === ActivityReportType.BY_DAY) {
      return LaborMetricsInterval.Day;
    }

    if (dateRangeReportType === TimeRangeType.PAY_PERIOD) {
      return LaborMetricsInterval.PayPeriod;
    }

    if (dateRangeReportType === TimeRangeType.MONTHLY) {
      return LaborMetricsInterval.Month;
    }

    return LaborMetricsInterval.OvertimePeriod;
  }, [secondaryFilterType, dateRangeReportType]);

  const basicDetails = useProjectActivityBasicDetails(filterId, filterType, timeRange);
  const costCodeDetails = useProjectActivityCostCodeDetails(filterId, filterType, timeRange);
  const memberDetails = useProjectActivityMemberDetails(filterId, filterType, timeRange);
  const equipmentDetails = useProjectActivityEquipmentDetails(filterId, filterType, timeRange);
  const dateRangeDetails = useProjectActivityDateRangeDetails(filterId, filterType, timeRange, dateRangeInterval);

  const dataLoader = useMemo(() => {
    if (secondaryFilterType === ActivityReportType.BASIC) {
      return basicDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_EMPLOYEE) {
      return memberDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_DATE_RANGE) {
      return dateRangeDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_DAY) {
      return dateRangeDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_EQUIPMENT) {
      return equipmentDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_COST_CODE) {
      return costCodeDetails;
    } else {
      throw Error('Not supported secondary report type when loading projects: ' + secondaryFilterType);
    }
  }, [basicDetails, costCodeDetails, memberDetails, equipmentDetails, dateRangeDetails]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, filterType, filterId, secondaryFilterType, dateRangeInterval]);

  async function loadData() {
    setIsLoading(true);
    await dataLoader.loadData();
    setIsLoading(false);
  }

  return {
    loadData: dataLoader.loadData,
    isLoading: isLoading,
    sortedData: dataLoader.sortedData,
    onSort: dataLoader.onSort,
    sortedBy: dataLoader.sortedBy as keyof IProjectActivityTableRowInfo,
    sortDirection: dataLoader.sortDirection,
    sortIsDirty: dataLoader.sortIsDirty,
    remainingData: dataLoader.remainingData,
    isProjectOpen: dataLoader.isProjectOpen,
    toggleSubProject: dataLoader.toggleSubProject,
    getExportDataWithSubprojects: dataLoader.getExportDataWithSubprojects,
  };
}
