import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { defaultEquipmentActivityReportsColumns } from 'store/activityReports/ActivityReports';
import { IVisibleTableColumn } from 'types/TableColumn';
import { mergeListsWithOverride } from 'utils/collectionUtils';

export default function useEquipmentActivityReportColumns() {
  const memberSettings = useMemberSettings();
  const [columns, setColumns] = useState<IVisibleTableColumn[]>(
    memberSettings?.web?.features?.activityReport?.equipmentColumnSettings ?? defaultEquipmentActivityReportsColumns
  );

  const mergedColumns = useMemo(
    () => mergeListsWithOverride(defaultEquipmentActivityReportsColumns, columns, 'key', ['position', 'visible']),
    [columns]
  );

  const updateColumn = (checked: boolean, column: IVisibleTableColumn) => {
    setColumns((columns) => {
      return [...columns].map((col) => {
        return col.key === column.key ? { ...col, visible: checked } : col;
      });
    });
  };

  // filter out the columns that aren't available
  const unavailableColumnKeys = Array<string>();
  const canViewCost = useHasCostPermission();

  // hide cost columns to users who can't view cost
  if (!canViewCost) {
    unavailableColumnKeys.push('regularCost');
    unavailableColumnKeys.push('overtimeCost');
    unavailableColumnKeys.push('doubletimeCost');
    unavailableColumnKeys.push('totalCost');
    unavailableColumnKeys.push('laborBurden');
    unavailableColumnKeys.push('equipmentRate');
    unavailableColumnKeys.push('equipmentCost');
    unavailableColumnKeys.push('equipmentTotalCost');
  }

  const availableColumns = sortBy(
    mergedColumns.filter((item) => !unavailableColumnKeys.some((key) => key === item.key)),
    (item) => item.position ?? 0
  );

  return {
    availableColumns,
    updateColumn,
    setColumns,
  };
}
