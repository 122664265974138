import OptionalRender, { IOptionalRenderProps } from 'components/foundation/OptionalRender/OptionalRender';
import { FormattedHoursPayload } from 'utils/timeUtils';
import FormattedHours from '../FormattedHours/FormattedHours';

export interface IOptionallyFormattedHoursProps extends Omit<IOptionalRenderProps<number>, 'nilRender' | 'children'> {
  payload: Omit<FormattedHoursPayload, 'seconds'>;
  nilValue: string;
}

function OptionallyFormattedHours({ payload, nilValue, rendersZero, value }: IOptionallyFormattedHoursProps) {
  function renderNilValue() {
    return nilValue;
  }

  function renderHours(coalescedValue: number) {
    const updatedPayload = { ...payload, seconds: coalescedValue };
    return <FormattedHours payload={updatedPayload} key={coalescedValue} />;
  }

  return (
    <OptionalRender value={value} nilRender={renderNilValue} rendersZero={rendersZero}>
      {renderHours}
    </OptionalRender>
  );
}

OptionallyFormattedHours.defaultProps = {
  nilValue: '---',
  rendersZero: false,
};

export default OptionallyFormattedHours;
