import { Button, IDialogProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import Typography from 'components/foundation/text/Typography/Typography';
import { t } from 'i18next';

export type PerformanceScoringInfoDialogProps = Omit<IDialogProps, 'children'>;

function PerformanceScoringInfoDialog({ className, ...dialogProps }: PerformanceScoringInfoDialogProps) {
  const classes = classNames('performance-scoring-info-dialog', className);

  return (
    <HeaderDialog {...dialogProps} className={classes} title={t('Performance Scoring Q&A')}>
      <div className="dialog-content">
        <div>
          <Typography tag="label" fontWeight="bold">
            {t('What is Performance Scoring?')}
          </Typography>
          <Typography tag="p" className="mt-1" fontWeight="thin">
            {t(
              'Performance scoring provides feedback to employees in the form of a score that measures how well the company performed on a specific cost code and/or projects compared with its corresponding budget. These scores fall into four categories: Excellent, Good, Acceptable and Poor.'
            )}
          </Typography>
          <Typography tag="p" fontWeight="thin">
            {t(
              'For example, let’s say you have a new project and you’ve budgeted 100 units of work on a specific cost code. You then budget 5 hours to complete the work. Now lets say your employees are able to complete that work in 4 hours instead of 5. The employees would see a score of +25 for that cost code on that project, which would then be tagged as “Excellent”. '
            )}
          </Typography>
        </div>
        <div className="mt-5">
          <Typography tag="label" fontWeight="bold">
            {t('What Budgets Are Required?')}
          </Typography>
          <Typography tag="p" className="mt-1" fontWeight="thin">
            {t(
              'Performance scoring requires the Labor Hours and Progress Budgets to be enabled. In order to get weighted scores on the overall project, the Progress Value Budget needs to be enabled as well, otherwise scores will only be assigned to cost codes within the project.'
            )}
          </Typography>
        </div>
        <div className="mt-5">
          <Typography tag="label" fontWeight="bold">
            {t('Will Employees Be Able to See My Budget Information?')}
          </Typography>
          <Typography tag="p" className="mt-1" fontWeight="thin">
            {t(
              'No. Even though a score is based on a project’s budget, the employees won’t be able to view any budget information unless you have already granted them permissions to view budget information.'
            )}
          </Typography>
        </div>
        <div className="mt-5">
          <Typography tag="label" fontWeight="bold">
            {t('Where do Employees View Their Scores?')}
          </Typography>
          <Typography tag="p" className="mt-1" fontWeight="thin">
            {t(
              'All employees will be able to view project performance scores from the performance section of their mobile dashboard. '
            )}
          </Typography>
        </div>
        <div className="mt-5">
          <Typography tag="label" fontWeight="bold">
            {t('Why Are Projects Missing From the Performance Report?')}
          </Typography>
          <Typography tag="p" className="mt-1" fontWeight="thin">
            {t('The Performance Report will only list projects that have time tracked in the report date range.')}
          </Typography>
        </div>
        <Button onClick={dialogProps.onClose} type="secondary">
          {t('Close')}
        </Button>
      </div>
    </HeaderDialog>
  );
}

export default PerformanceScoringInfoDialog;
