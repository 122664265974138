import { Button } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import { t } from 'utils/localize';

export interface IBulkSignOffConfirmDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const BulkSignOffConfirmDialog = (props: IBulkSignOffConfirmDialogProps) => {
  const { className, isOpen, onClose, onSave } = props;

  const classes = classNames('daily-sign-off-resolve-dialog', className);

  function handleSubmit() {
    onSave();
    onClose();
  }

  const cancelButton = () => {
    return (
      <Button type="secondary" onClick={onClose}>
        {t('Cancel')}
      </Button>
    );
  };

  const submitButton = () => {
    return (
      <Button type="primary" className="m-5" onClick={handleSubmit}>
        {t('Submit')}
      </Button>
    );
  };

  return (
    <>
      <HeaderDialog className={classes} isOpen={isOpen} onClose={onClose} divider={false} title={t('Resolve?')}>
        <div className="p-5">
          {t(
            'This employee has earlier inaccurate time issues for today. Continuing will also resolve those earlier issues. Are you sure you want to continue?'
          )}
        </div>
        {submitButton()}
        {cancelButton()}
      </HeaderDialog>
    </>
  );
};

export default BulkSignOffConfirmDialog;
