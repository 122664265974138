import { Row, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import isNil from 'lodash/isNil';
import { FunctionComponent, ReactNode } from 'react';
import './DashboardCardHeader.scss';

export interface IDashboardCardHeaderProps {
  theme: Theme.DARK | Theme.LIGHT;
  header?: ReactNode | null;
  subheaderValue?: string | null;
  rightLabel?: string | null;
  onRightLabelClick?: () => void | null;
  className?: ClassName;
  divider?: boolean;
}

const DashboardCardHeader: FunctionComponent<IDashboardCardHeaderProps> = (props) => {
  const { header, subheaderValue, rightLabel, onRightLabelClick, theme, className, divider } = props;

  const classes = classNames(
    theme,
    'dashboard-card-header',
    {
      'dashboard-card-header': true,
      'dashboard-card-header-divider': divider === true,
    },
    className
  );

  return (
    <Row className={classes}>
      <div className={classNames('dashboard-card-header-left-content', { 'without-header': isNil(header) })}>
        {!isNil(header) && (
          <div className={classNames('dashboard-card-header-left-header', { 'font-small': isNil(subheaderValue) })}>
            {header}
          </div>
        )}

        {!isNil(subheaderValue) && <div className={'dashboard-card-header-left-subheader'}>{subheaderValue}</div>}
      </div>
      <div className="dashboard-card-header-right-content">
        {!isNil(rightLabel) && (
          <div className="dashboard-card-header-right-label" onClick={onRightLabelClick}>
            {rightLabel}
          </div>
        )}
      </div>
    </Row>
  );
};

export default DashboardCardHeader;
