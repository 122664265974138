import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IMemberWebUiSettings } from 'types/MemberWebUiSettings';
import useMemberSettings from '../member/useMemberSettings';
import useMemberWebSettingsUpdate from '../member/useMemberWebSettingsUpdate';

export default function useMemberUiSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateWebSettings = useMemberWebSettingsUpdate();

  return useCallback(
    <K extends keyof IMemberWebUiSettings>(key: K, payload: IMemberWebUiSettings[K]) => {
      const cloned = cloneDeep(memberSettings?.web?.ui);
      return updateWebSettings('ui', { ...cloned, [key]: payload });
    },
    [memberSettings?.web?.ui, updateWebSettings]
  );
}
