import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import { IProjectDateActivityTableRowInfo } from 'containers/activity-reports/project-tables/hooks/useProjectActivityDateRangeDetails';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { first, groupBy, isEmpty, isNil, keys, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { getMemberProjectCostCodeEquipmentMetricData } from 'utils/jitMetricQueries';
import { aggregateMetricAggregate } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import {
  ConditionNullableFieldType,
  LaborMetricsInterval,
  Maybe,
  Project,
  ProjectLaborMetricsInterface,
  Scalars,
} from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { IDateActivityTableRowInfo } from './useDateActivity';

export interface IDateProjectActivityTableRowInfo extends IDateActivityTableRowInfo {
  projectId: string | null;
  project: Project | null;
}

interface ProjectMetricRow extends ProjectLaborMetricsInterface {
  projectId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useDateActivityProjectDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval
) {
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const { getAll } = useApolloPaging();
  const [data, setData] = useState<IDateProjectActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'startDate',
    SortDirection.ASCENDING
  );

  function idFilter() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, true);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function query() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? PROJECT_WITH_TIME_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function loadData() {
    let filter = undefined;

    if (filterType === ActivityReportType.BY_PROJECT) {
      filter = {
        parentProjectId: { equal: filterId },
      };
    }

    const projects = await getAll<Project & ICursorable>('projectsWithTime', {
      query: query(),
      variables: {
        first: 100,
        filter,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: intervalType,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...idFilter(),
        ...hasTimeIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const tableRows = mapNotNull(projects, (project) => createTableRowInfo(project));
    const remainingItem = await createNoProjectRow();

    updateDataprojectData(tableRows, remainingItem);
  }

  const createTableRowInfo = (project: Project): IProjectDateActivityTableRowInfo | null => {
    const metrics = laborMetrics(project);

    const unsortedDateRows = mapNotNull(metrics, (metric) => {
      // ignore descendants so we don't duplicate time up the project hierarchy
      const metricTotal = getGeneratedMetricLaborTotals(metric, true);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: project.id + metric.start,
        startDate: dateTimeFromISOWithoutZone(metric.start),
        endDate: dateTimeFromISOWithoutZone(metric.end),
        project,
        isUnassigned: false,
        descendantData: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const dateRows = sortBy(unsortedDateRows, (row) => row.startDate.toSeconds());

    if (isEmpty(dateRows)) {
      return null;
    } else {
      const projectTotalMetrics = aggregateActivityRows(dateRows);

      return {
        ...projectTotalMetrics,
        id: project.id,
        startDate: null,
        endDate: null,
        project,
        isUnassigned: false,
        descendantData: null,
        detailRows: dateRows,
      };
    }
  };

  async function createNoProjectRow(): Promise<IProjectDateActivityTableRowInfo | undefined> {
    const metrics = await getMemberProjectCostCodeEquipmentMetricData(
      client,
      timeRange,
      canViewCost,
      intervalType,
      filterType === ActivityReportType.BY_EMPLOYEE ? filterId : undefined,
      remainingDataItemId,
      filterType === ActivityReportType.BY_COST_CODE ? filterId : undefined,
      filterType === ActivityReportType.BY_EQUIPMENT ? filterId : undefined
    );

    const metricsByDate = groupBy(metrics, (data) => data.start);
    const dates = keys(metricsByDate);
    const dateRows: IProjectDateActivityTableRowInfo[] = mapNotNull(dates, (date) => {
      const metricsOnDate = metricsByDate[date];
      const dateMetrics = aggregateMetricAggregate(metricsOnDate as IJitLaborMetric[]);

      const firstRow = first(metricsOnDate)!;

      const metricTotal = getGeneratedMetricLaborTotals(dateMetrics);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + date,
        startDate: dateTimeFromISOWithoutZone(firstRow.start),
        endDate: dateTimeFromISOWithoutZone(firstRow.end),
        project: null,
        isUnassigned: false,
        descendantData: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    if (!isEmpty(dateRows)) {
      const noCostCodeData = aggregateActivityRows(dateRows);

      return {
        project: null,
        startDate: null,
        endDate: null,
        ...noCostCodeData,
        detailRows: dateRows,
        isUnassigned: false,
        descendantData: null,
      };
    } else {
      return undefined;
    }
  }

  const laborMetrics = (project: Project): ProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return project.projectCostCodeLaborMetrics;
      case ActivityReportType.BY_EMPLOYEE:
        return project.projectMemberLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return project.projectLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return project.projectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  function updateDataprojectData(
    projectData: IProjectDateActivityTableRowInfo[],
    remainingData: IProjectDateActivityTableRowInfo | undefined
  ) {
    const dataForDates: IDateProjectActivityTableRowInfo[] = projectData.flatMap((row) => {
      const dateRows = row.detailRows as IProjectDateActivityTableRowInfo[] | undefined;
      // add the date to all the cost code rows
      return (
        dateRows?.map((data) => {
          return {
            ...data,
            startDate: data.startDate!,
            endDate: data.endDate!,
            projectId: data.project!.id,
            detailRows: [],
          };
        }) ?? []
      );
    });

    if (!isNil(remainingData)) {
      const dateRows = remainingData.detailRows as IProjectDateActivityTableRowInfo[] | undefined;

      dateRows?.forEach((data) => {
        dataForDates.push({
          ...data,
          startDate: data.startDate!,
          endDate: data.endDate!,
          projectId: remainingDataItemId,
          project: null,
          detailRows: [],
        });
      });
    }

    const dataByDate = groupBy(dataForDates, (data) => data.startDate);
    const dates = keys(dataByDate);

    setData(
      dates.map((date) => {
        const rowData = dataByDate[date];
        const firstRow = first(rowData)!;
        const totalMetrics = aggregateActivityRows(rowData);

        const noProject = rowData.find((row) => isNil(row.project));

        const sortedDetailRows = sortBy(
          rowData.filter((row) => !isNil(row.project)),
          (row) => getFormattedPathFromProject(row.project)
        );

        if (!isNil(noProject)) {
          sortedDetailRows.push(noProject);
        }

        return {
          ...totalMetrics,
          id: date,
          startDate: firstRow.startDate!,
          endDate: firstRow.endDate!,
          projectId: null,
          project: null,
          detailRows: sortedDetailRows,
        };
      })
    );
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
  };
}
