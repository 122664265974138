import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreTimeCardReportSettings {
  showTimeEntries: boolean;
  showProjectSummary: boolean;
  showCostCodeSummary: boolean;
  showEquipmentSummary: boolean;
  showDailySummary: boolean;
  showSignatures: boolean;
}

export const initialState: IStoreTimeCardReportSettings = {
  showTimeEntries: true,
  showProjectSummary: false,
  showCostCodeSummary: false,
  showEquipmentSummary: false,
  showDailySummary: true,
  showSignatures: true,
};

const slice = createSlice({
  name: 'timecardReportSettings',
  initialState,
  reducers: {
    updateReduxTimeCardReportSettings: (state, action: PayloadAction<IStoreTimeCardReportSettings>) => {
      state = action.payload;

      return state;
    },
  },
});

export const { updateReduxTimeCardReportSettings } = slice.actions;

export default slice.reducer;
