import { useApolloClient } from '@apollo/client';
import { Align, ITableColumn, Icon, Justify, Label, Row, Table, TextAlign } from '@busybusy/webapp-react-ui';
import { FlaggedTimeEntryLocation, FlaggedTimeEntryLocationStatus, MemberGpsStatus } from '__generated__/graphql';
import { InfoIcon } from 'assets/icons';
import classNames from 'classnames';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import { FLAGGED_TIME_ENTRY_LOCATION_QUERY } from 'containers/gps-outside-project-report/gps-outside-project-queries';
import { first, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useMemo, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ILocationHistory from 'types/LocationHistory';
import MemberLocationType from 'types/enum/MemberLocationType';
import { memberLocationsUtils } from 'utils';
import { dateTimeFromISOKeepZone, getDateString, isSameDay } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import './LocationHistoryTable.scss';

export interface ILocationTableData {
  locations: ILocationHistory[];
  memberGPSStatuses: Pick<MemberGpsStatus, 'status' | 'localTime'>[];
}

export interface ILocationHistoryTableProps {
  className?: ClassName;
  data: ILocationTableData;
  selectedId?: string;
  canManageGpsForMember?: boolean;
  onRowClick?: (selectedId?: string) => void;
  onFilterChange?: (filter: string) => void;
  onLocationBannerClick: (currentData: FlaggedTimeEntryLocation) => void;
  onGPSDisableInfoClick: () => void;
}

const LocationHistoryTable = (props: ILocationHistoryTableProps) => {
  const {
    className,
    data,
    selectedId,
    canManageGpsForMember,
    onRowClick,
    onFilterChange,
    onLocationBannerClick,
    onGPSDisableInfoClick,
  } = props;

  const classes = classNames('location-history-table', className);
  const today = DateTime.local().startOf('day');
  const isPro = useFeatureFlags('PRO');
  const client = useApolloClient();
  const [locationFilter, setLocationFilter] = useState<string>(MemberLocationType.ALL);
  const isLocationFlaggingEnabled = useFeatureFlags('LOCATION_FLAGGING');
  const gpsStatusDisabledFeature = useFeatureFlags('GPS_STATUS_DISABLED');

  const hasUnauthorizedLocations = useMemo(() => {
    return data.locations.some((item) => item.status === FlaggedTimeEntryLocationStatus.Unauthorized);
  }, [data]);

  const hasAuthorizedLocations = useMemo(() => {
    return data.locations.some((item) => item.status === FlaggedTimeEntryLocationStatus.Authorized);
  }, [data]);

  const noLongerFlagged = useMemo(() => {
    return (hasAuthorizedLocations || hasUnauthorizedLocations) && data.locations.some((item) => !item.project);
  }, [data, hasAuthorizedLocations, hasUnauthorizedLocations]);

  const onLocationBarClick = async (isAuthorized: boolean) => {
    const filter = isAuthorized
      ? FlaggedTimeEntryLocationStatus.Authorized
      : FlaggedTimeEntryLocationStatus.Unauthorized;
    const location = first(data.locations.filter((item) => item.status === filter));
    canManageGpsForMember ? onLocationBannerClick(await getFlaggedLocationData(location!.flaggedLocationId!)) : null;
  };

  const getFlaggedLocationData = async (id: string) => {
    const flaggedLocationResults = await client.query<{ flaggedTimeEntryLocations: FlaggedTimeEntryLocation[] }>({
      query: FLAGGED_TIME_ENTRY_LOCATION_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          id: { equal: id },
        },
      },
    });
    return first(flaggedLocationResults.data.flaggedTimeEntryLocations)!;
  };

  function renderColumns(): Array<ITableColumn<ILocationHistory>> {
    const columns = Array<ITableColumn<ILocationHistory>>();
    columns.push({
      cell: renderLocationColumn,
      key: 'location',
      align: TextAlign.LEFT,
      size: '200px',
      cellClassName: 'location-history-cell',
    });
    return columns;
  }

  function renderLocationColumn(row: ILocationHistory): ReactNode {
    const distance = memberLocationsUtils.getDistanceWhenOutsideProject(row.location, row.project?.projectInfo);
    return (
      <div className={selectedId ? (selectedId === row.location.id ? 'highlighted' : '') : ''}>
        <div className="location-header-container">
          <Label className="location-header">{memberLocationsUtils.getTypeTitle(row.type)}</Label>
          {distance ? (
            <Label
              className={`location-distance ${
                row.status === FlaggedTimeEntryLocationStatus.Unauthorized || !row.status ? 'unauthorized' : ''
              }`}
            >
              {distance} {t('mi')}
            </Label>
          ) : (
            <></>
          )}
        </div>
        <div className="pt-4 location-details">
          <div>
            <TimeAndSuperscript dateTime={dateTimeFromISOKeepZone(row.location.timeLocal)} dst={row.location.timeDst} />
          </div>
          <div className="pt-4">
            {!isSameDay(dateTimeFromISOKeepZone(row.location.timeLocal), today) &&
              getDateString(dateTimeFromISOKeepZone(row.location.timeLocal), 'LLL d', true)}
          </div>
          {row.project ? <p>{row.project.title}</p> : <></>}
        </div>
      </div>
    );
  }

  function renderEmptyState() {
    return (
      <Row justify={Justify.CENTER} align={Align.CENTER} className="empty-entry-state">
        {t('No locations.')}
      </Row>
    );
  }

  function handleRowClick(row: ILocationHistory) {
    if (onRowClick) {
      if (selectedId && selectedId === row.location.id) {
        onRowClick(undefined);
      } else {
        onRowClick(row.location.id);
      }
    }
  }

  function handlePickerSelect(value: string | null) {
    if (value) {
      setLocationFilter(value);
      if (onFilterChange) {
        onFilterChange(value);
        if (onRowClick) {
          if (selectedId) {
            onRowClick(undefined);
          }
        }
      }
    }
  }

  function getFilterTypes() {
    const types = [
      { id: MemberLocationType.ALL, name: t('All Events') },
      { id: MemberLocationType.START, name: t('Start') },
      { id: MemberLocationType.END, name: t('End') },
      { id: MemberLocationType.BREAK_START, name: t('Break Start') },
      { id: MemberLocationType.BREAK_END, name: t('Break End') },
    ];
    if (isPro) {
      types.push({ id: MemberLocationType.LOCATION_UPDATE, name: t('Location Update') });
    }
    return types;
  }

  return (
    <div className={classes}>
      <div className="location-picker py-6 pl-4 pr-4">
        <BasicPicker
          placeholder={t('All Events')}
          onSelect={handlePickerSelect}
          value={locationFilter}
          clearable={false}
          data={getFilterTypes()}
        />
      </div>
      {gpsStatusDisabledFeature && !isEmpty(data.memberGPSStatuses) && (
        <div className="location-bar unauthorized p-2" onClick={onGPSDisableInfoClick}>
          <div className="mt-2 ml-2">{t('GPS Disabled')}</div>
          {canManageGpsForMember && <Icon svg={InfoIcon} className={'mt-2 mr-2'} />}
        </div>
      )}
      {isLocationFlaggingEnabled && hasUnauthorizedLocations && !noLongerFlagged && (
        <div className="location-bar unauthorized p-2" onClick={() => onLocationBarClick(false)}>
          <div className="mt-2 ml-2">{t('Unauthorized Locations')}</div>
          {canManageGpsForMember && <Icon svg={InfoIcon} className={'mt-2 mr-2'} />}
        </div>
      )}
      {isLocationFlaggingEnabled && hasAuthorizedLocations && !noLongerFlagged && (
        <div className="location-bar authorized p-2" onClick={() => onLocationBarClick(true)}>
          <div className="mt-2 ml-2">{t('GPS Outside Project Authorized')}</div>
          {canManageGpsForMember && <Icon svg={InfoIcon} className={'mt-2 mr-2'} />}
        </div>
      )}
      {isLocationFlaggingEnabled && noLongerFlagged && (
        <div className="location-bar no-longer-flagged p-2">
          <div className="mt-2 ml-2">{t('Initially flagged GPS are no longer outside of the project.')}</div>
        </div>
      )}
      <div>
        <Table
          className={classes}
          cols={renderColumns()}
          data={
            locationFilter !== MemberLocationType.ALL
              ? data.locations.filter((item) => item.type === locationFilter)
              : data.locations
          }
          strokeCols={true}
          lazyLoad={false}
          onRowClick={handleRowClick}
          emptyTemplate={renderEmptyState()}
        />
      </div>
    </div>
  );
};

export default LocationHistoryTable;
