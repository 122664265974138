import { t } from 'utils/localize';

export type TimesheetPrintOptionsPositionType = 'at-end-of-document' | 'after-each-section';
export type ExpandedTimeCardsPrintDensityType = 'compact' | 'standard';

export interface ITimesheetPrintOptionsData {
  includeAdditionalSignatureLines: boolean;
  pageBreaks: boolean;
  showAllEmployees: boolean;
  position?: TimesheetPrintOptionsPositionType;
  title?: string;
  signature?: string;
  printDensity?: ExpandedTimeCardsPrintDensityType;
}

export const defaultTimesheetPrintOptions: ITimesheetPrintOptionsData = {
  includeAdditionalSignatureLines: false,
  pageBreaks: true,
  showAllEmployees: false,
  position: 'at-end-of-document',
  title: t('Sign Off'),
  signature: undefined,
  printDensity: 'compact',
};
