import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { ICostCodeLandingTableRowInfo } from 'containers/landing-pages/cost-code-landing-page/CostCodeLandingTable/context/CostCodeLandingDataContext';
import { IVisibleTableColumn } from 'types/TableColumn';

export interface IStoreCostCodeLandingPage {
  costCodeTableClockedInColumns: IVisibleTableColumn[];
  costCodeTableClockedOutColumns: IVisibleTableColumn[];
  costCodeTableColumns: IVisibleTableColumn[];
  costCodeDashboardGridItems: IDashboardSettingsItem[];
  data: ICostCodeLandingTableRowInfo[];
}

export const initialState: IStoreCostCodeLandingPage = {
  costCodeTableClockedInColumns: [],
  costCodeTableClockedOutColumns: [],
  costCodeTableColumns: [],
  costCodeDashboardGridItems: [],
  data: [],
};

const slice = createSlice({
  name: 'CostCodeLandingPage',
  initialState,
  reducers: {
    updateCostCodeTableClockedInColumns: (
      state: IStoreCostCodeLandingPage,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.costCodeTableClockedInColumns = action.payload;

      return state;
    },
    updateCostCodeTableClockedOutColumns: (
      state: IStoreCostCodeLandingPage,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.costCodeTableClockedOutColumns = action.payload;

      return state;
    },
    updateCostCodeTableColumns: (state: IStoreCostCodeLandingPage, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.costCodeTableColumns = action.payload;

      return state;
    },
    updateCostCodeDashboardGridItems: (
      state: IStoreCostCodeLandingPage,
      action: PayloadAction<IDashboardSettingsItem[]>
    ) => {
      state.costCodeDashboardGridItems = action.payload;

      return state;
    },
    setCostCodeLandingPageRowInfo: (
      state: IStoreCostCodeLandingPage,
      action: PayloadAction<ICostCodeLandingTableRowInfo[]>
    ) => {
      state.data = action.payload;
      return state;
    },
    clearCostCodeLandingPageRowInfo: (state: IStoreCostCodeLandingPage) => {
      state.data = [];
      return state;
    },
  },
});

export const {
  updateCostCodeTableClockedInColumns,
  updateCostCodeTableClockedOutColumns,
  updateCostCodeTableColumns,
  updateCostCodeDashboardGridItems,
  setCostCodeLandingPageRowInfo,
  clearCostCodeLandingPageRowInfo,
} = slice.actions;
export default slice.reducer;
