import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionNullableFieldType,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Member,
  Project,
  Scalars,
} from '__generated__/graphql';
import { PROJECT_ANCESTORS_QUERY } from 'apollo/queries/project-queries';
import {
  MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, first, isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { combineGeneratedMetricWithTimeOff, getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  getActivityItemTotal,
  getMemberTimeOffMetricDictionary,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from '../../hooks/useMemberActivityPermission';
import { IMemberActivityTableRowInfo } from './useMemberActivity';

export interface IMemberProjectActivityTableRowInfo extends IMemberActivityTableRowInfo {
  projectId: string | null;
  project: Project | null;
}

interface MemberProjectMetricRow extends LaborMetricsInterface {
  memberId: Scalars['Uuid']['output'];
  projectId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useMemberActivityProjectDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const permission = useMemberActivityPermission();
  const canViewCost = useHasCostPermission();
  const nameFormatted = useEmployeeNameFormatter();
  const remainingItemRef = useRef<IMemberProjectActivityTableRowInfo>();
  const [data, setData] = useState<IMemberProjectActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'member',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_PROJECT_METRIC_QUERY : MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IMemberProjectActivityTableRowInfo, key: keyof IMemberProjectActivityTableRowInfo) {
    if (key === 'member') {
      return item.member ? nameFormatted(item.member.firstName ?? '', item.member.lastName ?? '') : '';
    } else {
      return item[key];
    }
  }

  async function getProjects(projectIds: string[]) {
    if (isEmpty(projectIds)) {
      return [];
    }

    return await getAll<Project & ICursorable>('projects', {
      query: PROJECT_ANCESTORS_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: projectIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    const members = await getAll<Member & ICursorable>('members', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    // we need to get the time off metrics when showing members filtered by the "no project" (or other variant) item
    // because web services won't include it #suckitdrew
    // time off also needs to be included when we're not scoped to a certain item (project/cost code/equipment)
    let timeOffData: Dictionary<ITimeOffLaborMetrics> | undefined = undefined;
    if (isNil(filterId) || filterId === remainingDataItemId) {
      timeOffData = await getMemberTimeOffMetricDictionary(client, timeRange, canViewCost);
    }

    const projectLookup = await getProjectLookup(members);
    const tableRows = mapNotNull(members, (member) =>
      createTableRowInfo(projectLookup, member, timeOffData?.[member.id])
    );

    // calculate total time in order to show `Additional Time` item if the user doesn't have permission to see everyone
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost);
    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        member: null,
        projectId: null,
        project: null,
        ...remainingData,
      };
    } else {
      // don't show no project item when there is no time allocated to no project
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const getProjectLookup = async (data: Member[]) => {
    const metrics = data.flatMap((member) => laborMetrics(member));
    const projectIds = mapNotNil(metrics, (item) => item.projectId);
    const projectList = await getProjects(uniq(projectIds));

    return keyBy(projectList, (project) => project.id);
  };

  const createTableRowInfo = (
    projectLookup: Dictionary<Project>,
    member: Member,
    timeOffData?: ITimeOffLaborMetrics
  ): IMemberProjectActivityTableRowInfo | null => {
    const metrics = laborMetrics(member);
    const hasTimeOff = !isNil(timeOffData) && !isNil(timeOffData.pto) && timeOffData.pto > 0;

    let noProjectRow: IMemberProjectActivityTableRowInfo | undefined = undefined;
    if (hasTimeOff || metrics.some((metric) => isNil(metric.projectId))) {
      // handle case where we have time off, but no `No Project` item to append totals to
      const noProject = first(metrics.filter((metric) => isNil(metric.projectId))) ?? emptyMetricRow(member.id);
      const metricTotal = getGeneratedMetricLaborTotals(
        combineGeneratedMetricWithTimeOff(noProject, timeOffData),
        false
      );

      noProjectRow = {
        id: member.id + remainingDataItemId,
        projectId: remainingDataItemId,
        project: null,
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    }

    const unsortedProjectRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, true);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.projectId)) {
        return null;
      }

      return {
        id: member.id + metric.projectId,
        projectId: metric.projectId,
        project: projectLookup[metric.projectId] ?? null,
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const projectRows = sortByIgnoreCase(unsortedProjectRows, (row) => getFormattedPathFromProject(row.project));

    if (isEmpty(projectRows) && noProjectRow === undefined) {
      return null;
    } else {
      const memberTotalMetrics = aggregateActivityRows(
        noProjectRow === undefined ? projectRows : [...projectRows, noProjectRow]
      );
      return {
        ...memberTotalMetrics,
        id: member.id,
        projectId: null,
        project: null,
        member,
        detailRows: noProjectRow === undefined ? projectRows : [...projectRows, noProjectRow],
      };
    }
  };

  const emptyMetricRow = (memberId: string): MemberProjectMetricRow => {
    return {
      memberId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (member: Member): MemberProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return member.memberProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return member.memberProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberProjectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
