import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import useActivityReportNavigation from 'hooks/navigation/useActivityReportNavigation';
import useDashboardActivityReportTimeRangeDetails from './useDashboardActivityReportTimeRangeDetails';

export default function useActiveCardReportNavigation(
  timeFrame: DashboardTimeFrame,
  filterType: 'by-project' | 'by-employee' | 'by-cost-code' | 'by-equipment'
) {
  const getTimeRangeDetails = useDashboardActivityReportTimeRangeDetails(timeFrame);
  const activityReportNavigation = useActivityReportNavigation();

  function openActivityReport() {
    const { timeRangeType, timeRange } = getTimeRangeDetails();
    activityReportNavigation({
      filter_type: filterType,
      time_range_type: timeRangeType,
      start_date: timeRange.startTime.toISODate(),
      end_date: timeRange.endTime.toISODate(),
    });
  }

  return openActivityReport;
}
