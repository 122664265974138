// tslint:disable: no-bitwise
import { Member, Position } from '__generated__/graphql';
import { t } from 'i18next';
import { StoreAuthMemberProper } from 'store/types';
import { IMember } from 'types';
import IPosition from 'types/Position';
import { Optional } from 'types/util/Optional';
import { IStoreAuthPosition } from '../store/authMember/authPositionType';
/*
  permission : The property on authMember.position you want to check for example ('manageTimeEntries')
  authMember : Currently logged in member
  target : The employee you want to see if you have permission for

  000'1' = 1 bitwise AND Permission checks for self
  00'1'0 = 2 bitwise AND Permission checks for lower
  0'1'00 = 4 bitwise AND Permission checks for same
  '1'000 = 8 bitwise AND permission checks for higher
*/

export type TPermissions = keyof Omit<
  IStoreAuthPosition,
  'organizationId' | 'title' | 'level' | 'updatedOn' | 'createdOn' | 'submittedOn' | 'deletedOn'
>;

export enum PositionPhotoVerificationSettingsActionType {
  CLOCK_IN = 'clock-in',
  CLOCK_OUT = 'clock-out',
  START_BREAK = 'start-break',
  END_BREAK = 'end-break',
}

export enum PositionManageBudgetType {
  OFF = 'off',
  VIEW_ONLY = 'viewOnly',
  MANAGE = 'manage',
}

export function canManagePermissionForPosition(
  permissionKey: TPermissions,
  authPosition: { level: number },
  targetPosition: { level: number }
) {
  const activeLevel = authPosition.level;
  const targetLevel = targetPosition.level;
  const position = authPosition as { [key: string]: any };
  const permission = position[permissionKey.toString()];

  // Handle Levels
  if (targetLevel > activeLevel) {
    // Lower
    return (permission & 2) !== 0;
  } else if (activeLevel === targetLevel) {
    // Same
    return (permission & 4) !== 0;
  } else if (targetLevel < activeLevel) {
    // Higher
    return (permission & 8) !== 0;
  } else {
    return false; // This should never be the case but it stops from returning undefined
  }
}

// TODO should probably have this on a key basis so every member doesn't require having every permission available
export interface IPermissionCheckableMember {
  id: string;
  position?: Optional<Omit<Position, 'organizationId' | 'submittedOn' | 'updatedOn' | 'cursor'>>;
  memberGroupId?: Optional<Member['memberGroupId']>;
}

// TODO remember 2nd auth member argument someday.
export function canManagePermissionForMember(
  permissionKey: TPermissions,
  authMember: StoreAuthMemberProper | { id?: string; position?: IPosition; memberGroupId?: string | null },
  target: IPermissionCheckableMember | IMember
) {
  // Potential problem if the position gets deleted so default to not allowing them to manage
  if (!authMember.position) {
    return false;
  }

  // Exclude anyone not in the group if group scoping is used (don't consider self here)
  if (
    authMember.id !== target.id &&
    authMember.position.scopeByGroup &&
    authMember.memberGroupId !== target.memberGroupId
  ) {
    return false;
  }

  const pos = authMember.position! as { [key: string]: any };
  const permission = pos[permissionKey.toString()];

  // Handle Self first
  if (authMember.id === target.id) {
    return (permission & 1) !== 0;
  } else {
    return canManagePermissionForPosition(permissionKey, authMember.position, target.position!);
  }
}

export function isAdmin(position?: { level: number } | null) {
  return position ? position.level === 1 : false;
}

export interface IPhotoVerificationSettings {
  enabled: boolean;
  actions: PositionPhotoVerificationSettingsActionType[];
}

export function getPhotoVerificationSettings(
  position: Pick<IPosition, 'photoVerificationSettings'>
): IPhotoVerificationSettings {
  const settings: IPhotoVerificationSettings = JSON.parse(position.photoVerificationSettings || '{}');
  return {
    enabled: settings.enabled,
    actions: settings.actions,
  };
}

export function convertPhotoVerificationSettingsToString(settings: IPhotoVerificationSettings): string {
  const actions: string[] = settings.actions.map((action) => {
    switch (action) {
      case PositionPhotoVerificationSettingsActionType.CLOCK_IN:
        return 'clock-in';
      case PositionPhotoVerificationSettingsActionType.CLOCK_OUT:
        return 'clock-out';
      case PositionPhotoVerificationSettingsActionType.START_BREAK:
        return 'start-break';
      case PositionPhotoVerificationSettingsActionType.END_BREAK:
        return 'end-break';
      default:
        return 'clock-in';
    }
  });
  return JSON.stringify({
    actions: actions,
    enabled: settings.enabled,
  });
}

export function getPhotoVerificationSettingsStatusTitle(settings: IPhotoVerificationSettings): string {
  if (settings.enabled) {
    const actions = settings.actions;

    if (
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On');
    }

    if (
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On (Exclude Breaks)');
    }

    if (
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On (Breaks Only)');
    }

    if (
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On (Clock In Only)');
    }

    if (
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On (Clock Out Only)');
    }

    if (
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On (Start Break Only)');
    }

    if (
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      return t('On (End Break Only)');
    }

    let title = t('On (');
    let addComma = false;

    if (
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT)
    ) {
      title += t('Clock In/Out');
      addComma = true;
    } else if (
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT)
    ) {
      title += t('Clock In');
      addComma = true;
    } else if (
      !actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_IN) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.CLOCK_OUT)
    ) {
      title += t('Clock Out');
      addComma = true;
    }

    if (addComma) {
      title += ', ';
    }

    if (
      actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      title += t('Break Start/End');
    } else if (
      actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      !actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      title += t('Start Break');
    } else if (
      !actions.includes(PositionPhotoVerificationSettingsActionType.START_BREAK) &&
      actions.includes(PositionPhotoVerificationSettingsActionType.END_BREAK)
    ) {
      title += t('End Break');
    }
    title += ')';
    return title;
  }
  return t('Off');
}

export function getRequestTimeOffTitleValue(canRequestTimeOff: boolean) {
  if (canRequestTimeOff) {
    return t('On');
  } else {
    return t('Off');
  }
}

export function canManageTimeForAnyone(position: Optional<Pick<IPosition, 'manageTimeEntries'>>): boolean {
  return !!position?.manageTimeEntries && position.manageTimeEntries > 0;
}

export function canManageTimeOffForAnyone(position: Optional<Pick<IPosition, 'manageTimeOff'>>): boolean {
  return !!position?.manageTimeOff && position.manageTimeOff > 0;
}

export function canTimeEventsForAnyone(position: Optional<Pick<IPosition, 'timeEvents'>>): boolean {
  return !!position?.timeEvents && position.timeEvents > 0;
}

export function canManageSchedulesForAnyone(position: Optional<Pick<IPosition, 'manageSchedule'>>): boolean {
  return !!position?.manageSchedule && position.manageSchedule > 0;
}

export function canManageBulletinPosts(position: Optional<Pick<IPosition, 'manageBulletinPosts'>>): boolean {
  return !!position?.manageBulletinPosts;
}

export function canManageProgressForAnyone(
  position: Optional<Pick<IPosition, 'canManageProjectCostCodeQuantity'>>
): boolean {
  return !!position?.canManageProjectCostCodeQuantity && position.canManageProjectCostCodeQuantity > 0;
}

export function canManagePerDiemForAnyone(position: Optional<Pick<IPosition, 'managePerDiem'>>): boolean {
  return !!position?.managePerDiem && position.managePerDiem > 0;
}

export function budgetsStatusString(position: Optional<Pick<IPosition, 'manageBudgets' | 'viewBudgets'>>): string {
  const type = manageBudgetType(position);
  switch (type) {
    case 'manage':
      return t('Yes') + ' (' + t('Manage') + ')';
    case 'viewOnly':
      return t('Yes') + ' (' + t('View Only') + ')';
    case 'off':
      return t('No');
    default:
      return t('No');
  }
}

export function manageBudgetType(
  position: Optional<Pick<IPosition, 'manageBudgets' | 'viewBudgets'>>
): PositionManageBudgetType {
  if (position?.manageBudgets === true && position?.viewBudgets === true) {
    return PositionManageBudgetType.MANAGE;
  } else if (position?.manageBudgets === false && position?.viewBudgets === true) {
    return PositionManageBudgetType.VIEW_ONLY;
  } else {
    return PositionManageBudgetType.OFF;
  }
}
