import { t } from 'utils/localize';

export default function v_fields_match(value: any, args: { matchingField: string; message?: string }, formData: any) {
  return {
    message: args.message || t('Fields must match'),
    validate: () => {
      const matchingValue = formData[args.matchingField];
      return matchingValue !== undefined && matchingValue === value;
    },
  };
}
