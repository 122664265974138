import gql from 'graphql-tag';

export const CREATE_MEMBER_TIME_DOCUMENT = gql`
  mutation CreateMemberTimeDocument($timeDocument: MemberTimeDocumentCreateInput) {
    createMemberTimeDocument(memberTimeDocument: $timeDocument) {
      id
    }
  }
`;

export const UPDATE_MEMBER_TIME_DOCUMENT = gql`
  mutation UpdateMemberTimeDocument($timeDocument: MemberTimeDocumentUpdateInput) {
    updateMemberTimeDocument(memberTimeDocument: $timeDocument) {
      id
    }
  }
`;
