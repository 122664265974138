import { Align, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import { InfoIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Well } from 'components';
import TooltipIcon from 'components/foundation/TooltipIcon/TooltipIcon';
import { ClockInRestrictionReasonPayloadOptions } from 'hooks/models/position/useClockInRestricted';
import i18n from 'i18n';
import { t } from 'utils/localize';
import './ClockInRestrictionBanner.scss';

export interface IClockInRestrictionBannerProps {
  reason: ClockInRestrictionReasonPayloadOptions;
  className?: ClassName;
}

function ClockInRestrictionBanner({ reason, className }: IClockInRestrictionBannerProps) {
  if (!reason.reason) {
    return null;
  }

  return (
    <div className={classNames('clock-in-restriction-banner', 'mb-4', className)}>
      <Well theme={Theme.DANGER}>
        <ClockInRestrictionContent reason={reason} />
      </Well>
    </div>
  );
}

function ClockInRestrictionContent({ reason }: { reason: ClockInRestrictionReasonPayloadOptions }) {
  function getLabels() {
    if (reason.reason === 'startTime') {
      const time = reason.futureAllowance.toFormat('h:mm a');
      return {
        label: `${t('Clock in restricted until')} ${time}`,
        tooltipLabel: `
          ${t('Employees are not permitted to clock in before')} ${time} ${t(
          'unless they have been scheduled to work. If you need to clock in early, contact your supervisor and request a schedule item to use to clock in.'
        )}`,
      };
    } else if (reason.reason === 'beforeSchedule') {
      const time = reason.futureAllowance.toFormat('h:mm a');

      return {
        label: `${t('Clock in restricted until')} ${time}`,
        tooltipLabel: `${t('You are not scheduled to work until')} ${time} ${t(
          'If you need to clock in, contact your supervisor and request a schedule item to use to clock in.'
        )}`,
      };
    } else {
      return {
        label: t('Not scheduled to work'),
        tooltipLabel: t(
          'You are not scheduled to work for the rest of the day. If you need to clock in, contact your supervisor and request a schedule item to use to clock in.'
        ),
      };
    }
  }

  const { label, tooltipLabel } = getLabels();

  return (
    <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
      <div>{label}</div>
      <TooltipIcon svg={InfoIcon} tooltipLabel={tooltipLabel} />
    </Row>
  );
}

export default ClockInRestrictionBanner;
