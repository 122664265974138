import { CreateIncidentAnswerMutation, CreateIncidentAnswerMutationVariables, IncidentAnswerCreateInput } from "__generated__/graphql";
import gql from "graphql-tag";
import useGraphQLClient from "hooks/graphql/useGraphQLClient/useGraphQLClient";
import { isNil } from "lodash";
import { useCallback } from "react";

export const useCreateIncidentAnswer = () => {
    const client = useGraphQLClient();
    const createIncidentAnswer = useCallback(
        async (input: IncidentAnswerCreateInput) => {
            const result = await client.request<CreateIncidentAnswerMutation, CreateIncidentAnswerMutationVariables>({
                document: CREATE_INCIDENT_ANSWER_MUTATION,
                variables: {
                    incidentAnswer: input,
                }
            });

            if (isNil(result.createIncidentAnswer)) {
                throw Error(`result was ${result.createIncidentAnswer}`);
            }

            return result.createIncidentAnswer;
        },
        [client]
    );

    return createIncidentAnswer;
}

const CREATE_INCIDENT_ANSWER_MUTATION = gql`
mutation CreateIncidentAnswer(
    $incidentAnswer: IncidentAnswerCreateInput
  ) {
    createIncidentAnswer(
      incidentAnswer: $incidentAnswer
    ) {
      id
    }
  }`