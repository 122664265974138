import { Button, Dialog, Divider, Icon, Theme, Tooltip, Tray } from '@busybusy/webapp-react-ui';
import { CloseIcon } from 'assets/icons';
import { PremiumFeaturesGraphic, ProFeaturesGraphic } from 'assets/images';
import { Header, Panel } from 'components';
import { PremiumFeatureMarketing } from 'components/foundation/state-templates/PremiumFeatureMarketing/PremiumFeatureMarketing';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { FunctionComponent } from 'react';
import './PremiumTrialDialog.scss';
import WistiaVideoEmbed from './WistiaVideoEmbed/WistiaVideoEmbed';

export const PremiumTrialDialog: FunctionComponent = () => {
  const proFeaturesOpenable = useOpenable();
  const premiumFeaturesOpenable = useOpenable();

  function renderHeader(onClose: () => void) {
    return (
      <Header className="header">
        <Tray>
          <Tooltip label={'Close'}>
            <Button type="icon" theme={Theme.DARK} onClick={onClose}>
              <Icon svg={CloseIcon} />
            </Button>
          </Tooltip>
        </Tray>
      </Header>
    );
  }

  return (
    <div className="premium-trial-dialog">
      <div className="header">
        <h2>{t('Welcome To Your Premium Free Trial!')}</h2>
        <Divider className="my-4" />
        <h5 className="mb-8">{t(`Here's a look at what's included:`)}</h5>
      </div>
      <div className="pro-features">
        <PremiumFeatureMarketing
          image={ProFeaturesGraphic}
          title={t(`All Pro Features`)}
          featureList={[
            t(`Scheduling`),
            t(`Supervisor Tools`),
            t(`Photos & Notes`),
            t(`Kiosk`),
            t(`Safety Sign-Off`),
            t(`Budgets`),
            t(`Activity Reports`),
            t(`Job Costing`),
            t(`Payroll Automation`),
            t(`Over 20+ Additional Features`),
          ]}
          onClick={proFeaturesOpenable.open}
          renderAsPremiumTrial={true}
        />
      </div>
      <div className="premium-features">
        <PremiumFeatureMarketing
          image={PremiumFeaturesGraphic}
          title={t(`All Premium Features`)}
          featureList={[
            t(`Bulletin`),
            t(`Field Reports`),
            t(`Document Sharing`),
            t(`Progress Tracking`),
            t(`API Keys`),
          ]}
          onClick={premiumFeaturesOpenable.open}
          renderAsPremiumTrial={true}
        />
      </div>
      <Dialog
        hasDismiss={false}
        size="full"
        isOpen={proFeaturesOpenable.isOpen}
        onClose={proFeaturesOpenable.close}
        className={'video-fullscreen-viewer'}
      >
        <Panel>
          {renderHeader(proFeaturesOpenable.close)}
          <div className="wistia-container">
            <WistiaVideoEmbed videoId="g3n7zvfdfa" />
          </div>
        </Panel>
      </Dialog>
      <Dialog
        hasDismiss={false}
        size="full"
        isOpen={premiumFeaturesOpenable.isOpen}
        onClose={premiumFeaturesOpenable.close}
        className={'video-fullscreen-viewer'}
      >
        <Panel>
          {renderHeader(premiumFeaturesOpenable.close)}
          <div className="wistia-container">
            <WistiaVideoEmbed videoId="xpuuqea34o" />
          </div>
        </Panel>
      </Dialog>
    </div>
  );
};
