import { Icon, IconSvg } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import './CustomerSupportDetail.scss';

export interface ICustomerSupportDetailProps {
  svg: IconSvg;
  title: string;
  subtitle: string;
  className?: ClassName;
}

export default function CustomerSupportDetail({ svg, title, subtitle, className }: ICustomerSupportDetailProps) {
  return (
    <div className={classNames('customer-support-detail', className)}>
      <Icon svg={svg} size={64} />
      <HeaderText title={title}>{subtitle}</HeaderText>
    </div>
  );
}
