import gql from 'graphql-tag';
import { RECURRING_SCHEDULE_FRAGMENT, SCHEDULING_TIME_OFF_FIELDS_FRAGMENT } from './scheduling-fragments';

export const SCHEDULING_TIME_OFF_QUERY = gql`
  query ScheduleTimeOff($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      ...SchedulingTimeOffFields
      cursor
    }
  }
  ${SCHEDULING_TIME_OFF_FIELDS_FRAGMENT}
`;

export const RECURRING_SCHEDULING_QUERY = gql`
  query SchedulingRecurringScheduleReminder(
    $filter: RecurringScheduleReminderFilter
    $sort: [RecurringScheduleReminderSort!]
    $first: Int
    $after: String
  ) {
    recurringScheduleReminders(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      cursor
      ...RecurringScheduleFields
    }
  }
  ${RECURRING_SCHEDULE_FRAGMENT}
`;

export const SCHEDULING_REMINDER_ID_AND_LINK_ID_QUERY = gql`
  query SchedulingReminderIdAndLinkId($filter: ScheduleReminderFilter, $first: Int, $after: String) {
    scheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      links {
        id
      }
      cursor
    }
  }
`;

export const SCHEDULING_REMINDER_ONE_OFF_QUERY = gql`
  query SchedulingReminderOneOff($filter: ScheduleReminderFilter, $first: Int, $after: String) {
    scheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      type
      startTime
      links {
        id
      }
      cursor
    }
  }
`;

export const RECURRING_SCHEDULING_ID_AND_LINK_ID_QUERY = gql`
  query SchedulingRecurringScheduleReminderIdAndLinkId(
    $filter: RecurringScheduleReminderFilter
    $first: Int
    $after: String
  ) {
    recurringScheduleReminders(filter: $filter, first: $first, after: $after) {
      id
      links {
        id
      }
      cursor
    }
  }
`;
