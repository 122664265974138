import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import { DateTime } from 'luxon';
import { FunctionComponent } from 'react';
import { IMember } from 'types';
import { t } from 'utils/localize';
import DailySignOffReportDetailsForm from '../DailySignOffReportDetailsForm/DailySignOffReportDetailsForm';

export interface IDailySignOffReportDetailsDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  member: IMember;
  currentDate: DateTime;
  onShouldRefresh?: () => void;
}

const DailySignOffReportDetailsDialog: FunctionComponent<IDailySignOffReportDetailsDialogProps> = (props) => {
  const { className, isOpen, onClose, member, currentDate, onShouldRefresh } = props;

  const classes = classNames('sign-off-report-details-dialog', className);

  return (
    <HeaderDialog className={classes} isOpen={isOpen} onClose={onClose} title={t('Daily Sign-Off')}>
      <DailySignOffReportDetailsForm member={member} currentDate={currentDate} onShouldRefresh={onShouldRefresh} />
    </HeaderDialog>
  );
};

export default DailySignOffReportDetailsDialog;
