import { Label, Theme, Toast } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import './EmployeeTimersActionToast.scss';

export type EmployeeTimersActionToastType =
  | 'clock-in'
  | 'clock-in-at'
  | 'clock-out'
  | 'clock-out-at'
  | 'switch'
  | 'switch-at'
  | 'begin-break'
  | 'end-break';

export interface IEmployeeTimersActionToastProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  type: EmployeeTimersActionToastType | null;
  startTime: DateTime | null;
  timeoutInSeconds: number;
}

const EmployeeTimersActionToast = (props: IEmployeeTimersActionToastProps) => {
  const { className, isOpen, onClose, type, startTime, timeoutInSeconds } = props;

  const [clockSeconds, setClockSeconds] = useState<number>(0);

  useEffect(() => {
    if (isOpen) {
      const to = setInterval(() => {
        setClockSeconds((prev) => prev + 1);
      }, 1000);
      return () => {
        window.clearTimeout(to);
      };
    } else if (clockSeconds !== 0) {
      setClockSeconds(0);
    }
  }, [isOpen]);

  function getTimerState(): string {
    if (startTime) {
      return (
        DateTime.local()
          .diff(startTime, ['years', 'months', 'days', 'hours', 'minutes'])
          .toFormat('hh:mm:')
          .toString() +
        '0' +
        clockSeconds
      );
    } else {
      return '00:00:0' + clockSeconds;
    }
  }

  function getToastChildren(): ReactNode {
    switch (type) {
      case 'clock-in':
      case 'clock-in-at':
        return (
          <>
            <div className="row-justify-flex-start pr-10 pt-1 pl-2">
              <Label className="toast-text-left">{t('Clocked In')}</Label>
            </div>

            <div className="row-justify-flex-end pt-1 pr-2">
              <Label className="toast-text-right">{getTimerState()}</Label>
            </div>
          </>
        );
      case 'clock-out':
      case 'clock-out-at':
        return (
          <>
            <Label className="toast-text-left">{t('Clocked Out')}</Label>
          </>
        );
      case 'switch':
      case 'switch-at':
        return (
          <>
            <div className="row-justify-flex-start pr-10 pt-1 pl-2">
              <Label className="toast-text-left">{t('Switched')}</Label>
            </div>

            <div className="row-justify-flex-end pt-1 pr-2">
              <Label className="toast-text-right">{getTimerState()}</Label>
            </div>
          </>
        );
      case 'begin-break':
        return (
          <>
            <div className="row-justify-flex-start pr-10 pt-1 pl-2">
              <Label className="toast-text-left">{t('On Break')}</Label>
            </div>

            <div className="row-justify-flex-end pt-1 pr-2">
              <Label className="toast-text-right">{getTimerState()}</Label>
            </div>
          </>
        );
      case 'end-break':
        return (
          <>
            <Label className="toast-text-left">{t('Ended Break')}</Label>
          </>
        );
      default:
        return null;
    }
  }

  function getToastTheme(): Theme {
    switch (type) {
      case 'clock-in':
      case 'clock-in-at':
        return Theme.PRIMARY;
      case 'clock-out':
      case 'clock-out-at':
        return Theme.DARK;
      case 'switch':
      case 'switch-at':
        return Theme.PRIMARY;
      case 'begin-break':
        return Theme.WARNING;
      case 'end-break':
        return Theme.DARK;
      default:
        return Theme.DARK;
    }
  }

  const classes = classNames(
    {
      'employee-timers-action-toast': true,
    },
    className
  );

  return (
    <Toast
      isOpen={isOpen}
      onClose={onClose}
      theme={getToastTheme()}
      className={classes}
      timeout={timeoutInSeconds * 1000}
    >
      {getToastChildren()}
    </Toast>
  );
};

export default EmployeeTimersActionToast;
