import { ITimeFieldProps, TimeField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useTimeRounding } from 'hooks';
import { DateTime } from 'luxon';
import ClockAction from 'types/enum/ClockAction';
import { Nullable } from 'types/util/Nullable';

export interface IFeatureTimeFieldProps extends Omit<ITimeFieldProps, 'minuteStep' | 'inputs' | 'meridiemEnabled'> {
  /* Used to determine time rounding */
  clockAction: ClockAction;
}

function FeatureTimeField(props: IFeatureTimeFieldProps) {
  const { clockAction, onChange, value, onBlur: onPassedBlur, className, ...featureTimeFieldProps } = props;

  const { roundTime, minuteInterval, enabled } = useTimeRounding();

  function onBlur(currentValue: Nullable<DateTime>) {
    if (currentValue && enabled) {
      const date = roundTime(currentValue, clockAction);

      onChange({
        value: date,
        formatted: date.toFormat('h:mm'),
        isValid: true,
      });

      onPassedBlur?.(date);
    } else {
      onPassedBlur?.(currentValue);
    }
  }

  const classes = classNames('feature-time-field', className);

  return (
    <TimeField
      {...featureTimeFieldProps}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      minuteStep={minuteInterval}
      className={classes}
      meridiemEnabled={true}
    />
  );
}

FeatureTimeField.defaultProps = {
  ...TimeField.defaultProps,
  period: 'AM',
  disabled: false,
  placeholder: 'H:MM',
};

export default FeatureTimeField;
