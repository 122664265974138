import { Align, Button, Checkbox, Form, Justify, Loader, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FormAccordion } from 'components';
import DraggableList, { IUpdatedDraggable } from 'components/foundation/DraggableList/draggable-list/DraggableList';
import { useOpenable } from 'hooks';
import useMemberUiSettingsUpdate from 'hooks/models/member-settings/useMemberUiSettingsUpdate';
import { t } from 'i18next';
import { cloneDeep, indexOf, keyBy, map, sortBy } from 'lodash';
import { ReactNode, useState } from 'react';
import useNavBarSettings, { INavBarSettings } from '../../hooks/useNavBarSettings';
import useNavConfig from '../../hooks/useNavConfig';
import { INavBarLink, INavBarParentLink } from '../../types/types';

export interface IMenuCustomizationsFormProps {
  className?: ClassName;
  onSubmit: () => void;
}

type IDraggableListItem = (INavBarParentLink | INavBarLink) & {
  position: number;
  draggable: true;
  id: string;
  visible: boolean;
};

const MenuCustomizationsForm = (props: IMenuCustomizationsFormProps) => {
  const { className, onSubmit } = props;

  const navConfig = useNavConfig();
  const { getNavBarSettings } = useNavBarSettings();
  const { general, reports } = getNavBarSettings(false);
  const [generalItems, setGeneralItems] = useState<IDraggableListItem[]>(convertNavItems(general));
  const [reportItems, setReportItems] = useState<IDraggableListItem[]>(convertNavItems(reports));
  const updateUiSettings = useMemberUiSettingsUpdate();
  const loaderDetails = useOpenable();

  async function actionApply() {
    const allItems = generalItems
      .map((value) => {
        return { id: value.id, position: value.position, visible: value.visible } as INavBarSettings;
      })
      .concat(
        reportItems.map((value) => {
          return { id: value.id, position: value.position, visible: value.visible } as INavBarSettings;
        })
      );
    const keyed = keyBy(allItems, 'id');
    loaderDetails.open();

    updateUiSettings('navBarSettings', {
      values: keyed,
    });

    onSubmit();
  }

  function renderDraggableItem(item: IDraggableListItem) {
    function onCheck(checked: boolean) {
      const generalIndex = indexOf(generalItems, item);

      if (generalIndex !== -1) {
        const clone = cloneDeep(generalItems);
        clone.splice(generalIndex, 1, { ...item, visible: checked });

        setGeneralItems(clone);
      }

      const reportsIndex = indexOf(reportItems, item);

      if (reportsIndex !== -1) {
        const clone = cloneDeep(reportItems);
        clone.splice(reportsIndex, 1, { ...item, visible: checked });

        setReportItems(clone);
      }
    }

    return (
      <div key={item.label} className="py-2 ml-2 column-item">
        <Checkbox
          data-testid="column_checkbox"
          className="primary-text mr-0"
          checked={item.visible}
          onChange={onCheck}
          label={item.label}
        />
      </div>
    );
  }

  function convertNavItems(items: (INavBarParentLink | INavBarLink)[]): IDraggableListItem[] {
    return map(items, (item) => {
      return { ...item, draggable: true };
    });
  }

  function updateGeneralItems(items: Array<IUpdatedDraggable<IDraggableListItem>>) {
    setGeneralItems(
      items.map((item) => ({
        ...item.payload,
        position: item.index,
      }))
    );
  }

  function updateReportItems(items: Array<IUpdatedDraggable<IDraggableListItem>>) {
    setReportItems(
      items.map((item) => ({
        ...item.payload,
        position: item.index,
      }))
    );
  }

  async function handleReset() {
    const navGeneral = navConfig.top.filter((item) => item.enabled && item.id !== 'dashboard');
    const navReports = navConfig.reports.filter((item) => item.enabled);
    setGeneralItems(convertNavItems(sortBy(navGeneral, 'position')));
    setReportItems(convertNavItems(sortBy(navReports, 'position')));
  }

  function renderContent({ ...form }): ReactNode {
    return (
      <>
        <FormAccordion title={t('General')} form={form} openByDefault={true}>
          <DraggableList items={generalItems} onUpdate={updateGeneralItems} renderItem={renderDraggableItem} />
        </FormAccordion>

        <FormAccordion title={t('Reports')} form={form} openByDefault={false} className={'mt-4'}>
          <DraggableList items={reportItems} onUpdate={updateReportItems} renderItem={renderDraggableItem} />
        </FormAccordion>

        <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER} className="mt-4">
          <div>
            <Button className="mt-4" type="primary" onClick={form.handleSubmit}>
              {t('Apply')}
            </Button>
            <Button className="mt-4" type="link" onClick={handleReset}>
              {t('Reset')}
            </Button>
          </div>
        </Row>
      </>
    );
  }

  const classes = classNames('menu-customizations-form p-7', className);

  return (
    <>
      <Form className={classes} onSubmit={actionApply} render={renderContent} />
      <Loader isOpen={loaderDetails.isOpen} overlay={true} />
    </>
  );
};

export default MenuCustomizationsForm;
