import useReduxSelector from 'hooks/store/useReduxSelector';
import { useSearchParams } from 'react-router-dom';
import { IChildrenProps } from 'types/ChildrenProps';
import { decodeAuthToken } from 'utils/authenticationUtils';
import AuthenticateFromToken from './AuthenticateFromToken/AuthenticateFromToken';
import DotEnvBootstrap from './DotEnvBootstrap';
import Interop from './Interop';

export const BeforeRoutes = ({ children }: IChildrenProps) => {
  const [searchParams] = useSearchParams();
  const { token } = useReduxSelector((state) => state.authentication);
  const session = useReduxSelector((state) => state.session);

  const decodedToken = decodeAuthToken(token || '');
  const { memberId: authMemberId } = decodedToken;

  const { REACT_APP_KEY } = process.env;

  if (searchParams.has('authorize')) {
    return <Interop />;
  } else if (token && (!session.isAuthenticated || authMemberId !== session.memberId)) {
    return <AuthenticateFromToken token={token} memberId={authMemberId} />;
  } else if (!session.isAuthenticated && REACT_APP_KEY) {
    return <DotEnvBootstrap />;
  } else {
    return <>{children}</>;
  }
};
