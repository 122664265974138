import CommandPalletDialog from 'components/layout/CommandPallet/CommandPalletDialog/CommandPalletDialog';
import { useCommandPalletState } from 'contexts/CommandPalletContext/CommandPalletContext';
import { useActiveMember } from 'hooks';
import useQuickSearchGraylog from 'hooks/analytics/useQuickSearchGraylog';
import useIsLoggedIn from 'hooks/auth/useIsLoggedIn/useIsLoggedIn';
import useWhichOS from 'hooks/utils/useWhichOS';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useFeatureFlags } from 'utils/features';

export default function ConnectedCommandPallet() {
  const { isOpen, open, close } = useCommandPalletState();
  const isUserLoggedIn = useIsLoggedIn();
  const isCommandPalletEnabled = useFeatureFlags('COMMAND_PALLET');
  const quickSearchUserEvents = useQuickSearchGraylog();
  const { isMacintosh } = useWhichOS();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k') {
        if ((isMacintosh() && e.ctrlKey) || (!isMacintosh() && e.altKey)) {
          if (isCommandPalletEnabled && isUserLoggedIn) {
            quickSearchUserEvents.searchOpened(true);
            open();
          }
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isUserLoggedIn, isCommandPalletEnabled]);

  return <CommandPalletDialog isOpen={isOpen} onClose={close} />;
}
