import { useApolloClient } from '@apollo/client';
import { CreateMediaEntryCommentMutation, MediaEntryCommentCreateInput } from '__generated__/graphql';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export const useCreateMediaEntryComment = () => {
  const client = useApolloClient();
  return useCallback(
    async (mediaEntryComment: MediaEntryCommentCreateInput) => {
      const result = await client.mutate<{
        createMediaEntryComment: CreateMediaEntryCommentMutation['createMediaEntryComment'];
      }>({
        mutation: CREATE_MEDIA_ENTRY_COMMENT_MUTATION,
        variables: {
          mediaEntryComment,
        },
      });
      return result.data?.createMediaEntryComment;
    },
    [client]
  );
};

export const CREATE_MEDIA_ENTRY_COMMENT_MUTATION = gql`
  mutation CreateMediaEntryComment($mediaEntryComment: MediaEntryCommentCreateInput) {
    createMediaEntryComment(mediaEntryComment: $mediaEntryComment) {
      id
      comment
      mediaEntryId
      createdOn
    }
  }
`;
