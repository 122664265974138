import { DocumentNode } from 'graphql';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { AnyObject } from 'types/util/Object';

export const useCheckForObjects = <T extends AnyObject>(document: DocumentNode, objectKey: string) => {
  const client = useGraphQLClient();
  const getFirstObject = useCallback(async () => {
    const result = await client.request<T>({
      document: document,
      variables: {
        first: 1,
      },
    });

    if (isNil(result[objectKey])) {
      throw Error(`result was ${result[objectKey]}`);
    }

    return result[objectKey];
  }, [client, document, objectKey]);

  return getFirstObject;
};

export const useCheckForMultipleObjects = <T extends AnyObject>(
  document: DocumentNode,
  objectKey: string,
  limit: number,
  filter?: any
) => {
  const client = useGraphQLClient();
  const getObjects = useCallback(async () => {
    const result = await client.request<T>({
      document: document,
      variables: {
        first: limit,
        filter,
      },
    });

    if (isNil(result[objectKey])) {
      throw Error(`result was ${result[objectKey]}`);
    }

    return result[objectKey];
  }, [client, document, filter, limit, objectKey]);

  return getObjects;
};
