import { Icon, TextField } from '@busybusy/webapp-react-ui';
import { CloseIcon, SearchMagnifyingGlass } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import * as React from 'react';
import { IClassName } from 'types/ClassName';
import './SearchBar.scss';

export interface ISearchBarProps {
  className?: ClassName;
  search: string;
  onSearch: (searchString: string) => void;
  inputRef?: ((element: HTMLInputElement) => void) | undefined;
  placeholder?: string;
  enableClear?: boolean;
}

const SearchBar = (props: ISearchBarProps) => {
  const { className, search, placeholder, enableClear, onSearch, inputRef } = props;

  const classes = classNames('search-bar', className);
  const hasSearch = search.length > 0;

  return (
    <TextField
      className={classes}
      onChange={onSearch}
      value={search}
      placeholder={placeholder}
      iconLeft={<Icon svg={SearchMagnifyingGlass} />}
      iconRight={enableClear ? <InputFieldClear onChange={onSearch} hasSearch={hasSearch} /> : null}
      inputRef={inputRef}
    />
  );
};

export interface ISearchFieldClearProps {
  className?: IClassName;
  hasSearch: boolean;
  onChange: (searchString: string) => void;
}

const InputFieldClear: React.FC<ISearchFieldClearProps> = ({ className, hasSearch, onChange }) => {
  const classes = classNames('clear-input', 'icon-container', className);
  const clearInput = () => onChange('');

  return hasSearch ? (
    <div className={classes} onClick={clearInput}>
      <Icon svg={CloseIcon} />
    </div>
  ) : null;
};

export default SearchBar;
