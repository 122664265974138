import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreManageGroups {
  groupId: string | null;
}

export const initialState: IStoreManageGroups = {
  groupId: null,
};

const slice = createSlice({
  name: 'manageGroups',
  initialState,
  reducers: {
    updateGroupId: (state: IStoreManageGroups, action: PayloadAction<string | null>) => {
      state.groupId = action.payload;
      return state;
    },
    clearGroupId: (state: IStoreManageGroups) => {
      state.groupId = null;
      return state;
    },
  },
});

export const { updateGroupId, clearGroupId } = slice.actions;
export default slice.reducer;
