import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { IChildrenProps } from 'types/ChildrenProps';
import { IElementProps } from 'types/ElementProps';
import './DashboardRibbon.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface IDashboardRibbonProps extends IChildrenProps {
  onClick?: IElementProps<HTMLDivElement>['onClick'];
  style?: IElementProps<HTMLDivElement>['style'];
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
}

function DashboardRibbon({ onClick, style, children, theme, className }: IDashboardRibbonProps) {
  const isInIframe = useIsInIframe();

  const classes = classNames(
    'dashboard-ribbon',
    theme,
    { clickable: onClick !== undefined },
    { 'is-in-iframe': isInIframe },
    className
  );

  return (
    <div className={classes} onClick={onClick} style={style}>
      {children}
    </div>
  );
}

export default DashboardRibbon;
