import DashboardEnableCard, {
  IDashboardEnableCardProps,
} from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import useActivePosition from 'hooks/models/position/useActivePosition';
import useLegacyNavigation from 'hooks/navigation/useLegacyNavigation';
import useIsInIframe from 'hooks/store/useIsInIframe';
import useIsPro from 'hooks/store/useIsPro';
import { t } from 'i18next';
import { isAdmin } from 'utils/positionUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDashboardProUpdateCardProps extends Omit<IDashboardEnableCardProps, 'buttonLabel' | 'onClick'> {}

export default function DashboardProUpgradeCard(props: IDashboardProUpdateCardProps) {
  const activePosition = useActivePosition();
  const isInIframe = useIsInIframe();
  const legacyNavigation = useLegacyNavigation();
  const isPro = useIsPro();

  if (!isAdmin(activePosition) || isPro || isInIframe) {
    return null;
  }

  return <DashboardEnableCard {...props} buttonLabel={t('Upgrade to Pro')} onClick={legacyNavigation.subscription} />;
}
