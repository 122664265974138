import {
  Button,
  CheckboxFormField,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  Label,
  Loader,
  TextareaFormField,
  Theme,
  Toast,
  Tray,
} from '@busybusy/webapp-react-ui';
import { requiredDateValidation } from '@busybusy/webapp-react-ui/dist/utilities/validations';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Panel } from 'components';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import useTimeActionsForm from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import TimeOffTypeSelectFormField from 'components/domain/time-off/TimeOffTypeSelectFormField/TimeOffTypeSelectFormField';
import DeleteTimeEntryOrTimeOffDialog from 'components/domain/time/DeleteTimeEntryOrTimeOffDialog/DeleteTimeEntryOrTimeOffDialog';
import TotalTimeFormField from 'components/foundation/form-fields/TotalTimeFormField/TotalTimeFormField';
import { useOpenable } from 'hooks';
import useMemberLock from 'hooks/models/member-lock/useMemberLock';
import useTimeOffEdit from 'hooks/models/time-off/useTimeOffEdit';
import { first } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import ITimeOff from 'types/TimeOff';
import TimeOffType from 'types/enum/TimeOffType';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import './EditTimeOffForm.scss';

export interface IEditTimeOffFormProps {
  className?: ClassName;
  timeOff: ITimeOff;
  onDelete?: (memberIds?: string) => void;
  onEdit?: (memberId?: string) => void;
}

export interface IEditTimeOffFormData {
  date: IDatePickerPayload;
  description: string | null;
  total: number | null;
  paid: boolean;
  type: TimeOffType;
}

function EditTimeOffForm({ className, timeOff, onDelete, onEdit }: IEditTimeOffFormProps) {
  const [isPaid, setIsPaid] = useState(timeOff.paid);
  const datePickerState = useOpenable();
  const errorToastState = useOpenable();
  const deleteConfirmationState = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const editTimeOff = useTimeOffEdit();
  const lockDialogDetails = useOpenable();
  const preValidationFormData = useRef<IEditTimeOffFormData>();
  const [hasLockDate, setHasLockDate] = useState<boolean>(false);
  const [hasSigViolation, setHasSigViolation] = useState<boolean>(false);
  const checkValidationsPayload = useRef<{
    lock: boolean;
    signatureViolation: boolean;
  }>({ lock: true, signatureViolation: true });
  const { inhibitsActionsForMembers } = useMemberLock();
  const { hasSignatureConflicts } = useTimeActionsForm();
  const loaderDetails = useOpenable();
  const signatureValidationDetails = useOpenable();

  const classes = classNames('edit-time-off-form', className);

  const date = DateTime.fromISO(timeOff.dateStamp);
  const defaultFormInfo: IEditTimeOffFormData = {
    date: { value: date.toJSDate(), inputValue: date.toFormat('MM/dd/yyyy') },
    description: timeOff.description,
    total: timeOff.seconds ? timeOff.seconds : 0, // Total field doesn't like 0 seconds. 1 second is esentially zero.
    paid: timeOff.paid,
    type: timeOff.type,
  };
  const [formInfo, setFormInfo] = useState<IEditTimeOffFormData>(defaultFormInfo);

  useEffect(() => {
    inhibitsTimeOffActions();
    signatureConflicts();
  }, [formInfo.date]);

  async function inhibitsTimeOffActions() {
    if (formInfo.date.value) {
      const memberIds = [timeOff.member.id];
      const inhibited = await inhibitsActionsForMembers(memberIds, DateTime.fromJSDate(formInfo.date.value!));
      setHasLockDate(inhibited);
    } else {
      setHasLockDate(true);
    }
  }

  async function signatureConflicts() {
    if (formInfo.date.value) {
      const memberIds = [timeOff.member.id];
      const inhibited = await hasSignatureConflicts(memberIds, {
        startTime: DateTime.fromJSDate(formInfo.date.value!),
        endTime: DateTime.fromJSDate(formInfo.date.value!),
      });
      setHasSigViolation(inhibited);
    } else {
      setHasSigViolation(true);
    }
  }

  function renderFormFields({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return (
      <div>
        {renderPaidCheckbox(form)}
        {renderDate(form)}
        {isPaid ? renderTotals(form) : null}
        {renderType(form)}
        {renderDescription(form)}
        {renderActionButtons(form)}
      </div>
    );
  }

  function renderPaidCheckbox({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return <CheckboxFormField name="paid" label={t('Paid')} form={form} onChange={setIsPaid} />;
  }

  function renderDate({ ...form }: IFormRender<IEditTimeOffFormData>) {
    const oldestDate = DateTime.local().minus(Duration.fromISO('P3Y')); // subtracts 3 years
    const newestDate = DateTime.local().plus(Duration.fromISO('P3Y')); // adds 3 years

    return (
      <div>
        <Label>{t('Date')}</Label>
        <DatePickerFormField
          className="date-picker-item"
          name="date"
          form={form}
          disabledBefore={oldestDate.toJSDate()}
          disabledAfter={newestDate.toJSDate()}
          isOpen={datePickerState.isOpen}
          onOpen={datePickerState.open}
          onClose={datePickerState.close}
          validations={[{ validation: requiredDateValidation }]}
          onChange={(payload) =>
            setFormInfo({
              ...formInfo,
              date: payload,
            })
          }
        />
        {renderDateErrorFooter(form)}
      </div>
    );
  }

  function renderDateErrorFooter({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return (
      <div className="pt-2 pb-4">
        {form.state.data.date.value && hasLockDate && (
          <Label className="start-date-warning">{t('Cannot set a start date older than the lock date.')}</Label>
        )}
      </div>
    );
  }

  function renderTotals({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return (
      <div>
        <Label>{t('Total')}</Label>
        <TotalTimeFormField className="total-time-picker-item" name="total" form={form} maxHours={23} />
      </div>
    );
  }

  function renderType({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return (
      <div>
        <Label>{t('Type')}</Label>
        <TimeOffTypeSelectFormField name="type" placeholder="" form={form} />
      </div>
    );
  }

  function renderDescription({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return (
      <div>
        <Label secondaryLabel={t('Optional')}>{t('Description')}</Label>
        <TextareaFormField name="description" form={form} restrictTo={{ maxLength: 1000 }} />
      </div>
    );
  }

  function renderActionButtons({ ...form }: IFormRender<IEditTimeOffFormData>) {
    return (
      <div>
        <Button type="primary" onClick={form.handleSubmit}>
          {t('Save')}
        </Button>

        <Button className="ml-4 delete-button" type="link" onClick={showDeleteConfirmation}>
          {t('Delete')}
        </Button>
      </div>
    );
  }

  function showToastError(message: string) {
    errorToastMessage.current = message;
    errorToastState.open();
  }

  function showDeleteConfirmation() {
    deleteConfirmationState.open();
  }

  function onSubmit(data: IEditTimeOffFormData) {
    if (checkValidationsPayload.current.lock && hasLockDate) {
      preValidationFormData.current = data;
      lockDialogDetails.open();
      return;
    }
    if (checkValidationsPayload.current.signatureViolation && hasSigViolation) {
      preValidationFormData.current = data;
      signatureValidationDetails.open();
      return;
    }
    loaderDetails.open();
    editTimeOff(timeOff, data.paid, data.type, data.total, data.description, data.date.value!)
      .then(() => {
        loaderDetails.close();
        if (onEdit) {
          onEdit(timeOff.member.id);
        }
      })
      .catch((error) => {
        loaderDetails.close();
        showToastError(t('There was an error editing the time off entry.'));
      });
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    if (preValidationFormData.current) {
      onSubmit(preValidationFormData.current);
    }
  }

  function onFormDataChange(data?: IEditTimeOffFormData) {
    if (data) {
      setFormInfo(data);
    }
  }

  function onSignatureViolationContinue() {
    setHasSigViolation(false);
    checkValidationsPayload.current.signatureViolation = false;
    signatureValidationDetails.close();
    if (preValidationFormData.current) {
      onSubmit(preValidationFormData.current);
    }
  }

  return (
    <div className={classes}>
      <Label className="pl-8">{fullName(timeOff.member)}</Label>
      <div className="mb6 p-8">
        <Form
          data={formInfo}
          render={renderFormFields}
          onSubmit={onSubmit}
          onChange={onFormDataChange}
          allowMultipleSubmissions={true}
        />

        <Toast isOpen={errorToastState.isOpen} onClose={errorToastState.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>

        {formInfo.date.value && (
          <LockDateDialog
            date={DateTime.fromJSDate(formInfo.date.value)}
            type={LockDateDialogType.ADD}
            memberIds={[timeOff.member.id]}
            isOpen={lockDialogDetails.isOpen}
            onClose={lockDialogDetails.close}
            onMoveLockDate={onMoveLockDate}
          />
        )}
        <HeaderDialog
          title={t('Signed Time Card')}
          isOpen={signatureValidationDetails.isOpen}
          onClose={signatureValidationDetails.close}
          divider={false}
        >
          <Panel className={'p-5'}>
            {t(
              'One or more entries belong to a time card that has already been signed. Continuing will require the employee to re-sign.'
            )}
            <Tray className="tray-right pt-6" align="left">
              <Button className="right-button" type="secondary" onClick={signatureValidationDetails.close}>
                {t('Cancel')}
              </Button>
              <Button className="right-button" type="primary" onClick={onSignatureViolationContinue}>
                {t('Continue')}
              </Button>
            </Tray>
          </Panel>
        </HeaderDialog>
        <DeleteTimeEntryOrTimeOffDialog
          isOpen={deleteConfirmationState.isOpen}
          onClose={deleteConfirmationState.close}
          timeOffIds={timeOff ? [timeOff?.id] : null}
          handleDelete={(memberIds) => onDelete?.(first(memberIds))}
        />
        <Loader isOpen={loaderDetails.isOpen} />
      </div>
    </div>
  );
}

export default EditTimeOffForm;
