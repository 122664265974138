import { ITrayProps, Tray } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";

export type ButtonTrayProps = Omit<ITrayProps, 'spacing'>;

function ButtonTray({ className, ...trayProps }: ButtonTrayProps) {
  const classes = classNames('button-tray', className);
  return <Tray spacing={3} className={classes} {...trayProps} />;
}

ButtonTray.defaultProps = {
  ...Tray.defaultProps,
};

export default ButtonTray;
