import { Button, Dialog, IDialogProps, Tray } from '@busybusy/webapp-react-ui';
import { ArrowBackIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Header, HeaderDialog, IconButton } from 'components';
import { useOpenable } from 'hooks';
import { t } from 'utils/localize';
import QuickTimeTemplateList from '../QuickTimeTemplateList/QuickTimeTemplateList';
import QuickTimeTemplateCreateForm from '../forms/QuickTimeTemplateCreateForm/QuickTimeTemplateCreateForm';
import './QuickTimeTemplateListDialog.scss';

export type QuickTimeTemplateListDialogProps = Omit<IDialogProps, 'children'>;

function QuickTimeTemplateListDialog({ onClose, className, ...dialogProps }: QuickTimeTemplateListDialogProps) {
  const createOpenable = useOpenable();
  const classes = classNames('quick-time-template-create-dialog', className);

  return (
    <>
      <Dialog onClose={onClose} className={classes} {...dialogProps}>
        <Header>
          <Tray>
            <IconButton svg={ArrowBackIcon} onClick={onClose} />
            <h2>{t('Time Entry Templates')}</h2>
          </Tray>
        </Header>
        <Header>
          <Button type="secondary" onClick={createOpenable.open}>
            + {t('Create')}
          </Button>
        </Header>
        <QuickTimeTemplateList />
      </Dialog>
      <HeaderDialog title={t('Create Template')} isOpen={createOpenable.isOpen} onClose={createOpenable.close}>
        <QuickTimeTemplateCreateForm onSubmit={createOpenable.close} className="mx-8 my-5" />
      </HeaderDialog>
    </>
  );
}

export default QuickTimeTemplateListDialog;
