import useNavigation from './useNavigation';

interface IParams {
  equipment_id: string;
  equipment_category_id?: string;
}

export default function useEquipmentManagementListNavigation() {
  return useNavigation<IParams>('/manage/equipment');
}
