import { DialogHeader, IDialogHeaderProps, Tray } from '@busybusy/webapp-react-ui';
import { ArrowBackIcon } from 'assets/icons';
import { IconButton } from 'components';
import { ReactNode } from 'react';

export interface IHeaderDialogHeaderProps extends IDialogHeaderProps {
  title: string;
  subtitle?: string;
  onClose?: () => void;
  showBackArrow: boolean;
  rightContent?: ReactNode;
}

export default function HeaderDialogHeader({
  title,
  subtitle,
  rightContent,
  onClose,
  showBackArrow,
  ...dialogHeaderProps
}: IHeaderDialogHeaderProps) {
  return (
    <DialogHeader {...dialogHeaderProps}>
      {showBackArrow ? (
        <Tray>
          <IconButton svg={ArrowBackIcon} onClick={onClose} />
          <HeaderDialogTitle title={title} subtitle={subtitle} />
        </Tray>
      ) : (
        <HeaderDialogTitle title={title} subtitle={subtitle} />
      )}

      {/* Margin to adjust for absolute positioned dialog close */}
      {rightContent && <div className="mr-6">{rightContent}</div>}
    </DialogHeader>
  );
}

interface IHeaderDialogTitleProps {
  title: string;
  subtitle?: string;
}

export function HeaderDialogTitle({ title, subtitle }: IHeaderDialogTitleProps) {
  function getSubtitleStatus() {
    return subtitle !== undefined && subtitle.length !== 0;
  }

  return (
    <div>
      <h2>{title}</h2>{' '}
      {getSubtitleStatus() && (
        <div>
          <h5>{subtitle}</h5>
        </div>
      )}
    </div>
  );
}

HeaderDialogHeader.defaultProps = {
  ...DialogHeader.defaultProps,
};
