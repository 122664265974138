import { QueryResult } from '@apollo/client';
import { Position } from '@busybusy/webapp-react-ui';
import { MemberGroupFilter } from '__generated__/graphql';
import classNames from 'classnames';
import { GroupPicker } from 'components';
import { useActiveMember } from 'hooks';
import useActivePosition from 'hooks/models/position/useActivePosition';
import _ from 'lodash';
import { ClassName } from 'types/ClassName';
import IMemberGroup from 'types/MemberGroup';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';
import { memberGroupQuery, memberGroupsQuery } from './employee-group-picker-queries';

export interface IEmployeeGroupPickerProps {
  value: string | null;
  filterByPermission: boolean;
  onSelect: (memberGroupId: string | null) => void;
  minWidth?: string; // has default
  className?: ClassName;
  placeholder?: string;
  position?: Position;
  client?: any;
  disabled?: boolean;
  error?: boolean;
  fetchPolicy?: string;
  searchArchived?: boolean;
  autofocus?: boolean;
  excludeIds?: IMemberGroup['id'][];
  includeNoneOption?: boolean;
}

function EmployeeGroupPicker({
  className,
  filterByPermission,
  excludeIds,
  includeNoneOption,
  onSelect,
  ...rest
}: IEmployeeGroupPickerProps) {
  const activeMember = useActiveMember();
  const activePosition = useActivePosition();

  function queryResolver(result: QueryResult) {
    return result.data.memberGroups;
  }

  function singleQueryResolver(result: QueryResult) {
    return _.get(result, 'memberGroups[0][groupName]');
  }

  // Return the filter for the server call
  const getFilter = (): MemberGroupFilter => {
    const filter: MemberGroupFilter = {};

    if (filterByPermission && excludeIds) {
      throw new Error('Cannot use filterByPermission and excludeIds at the same time');
    }

    if (filterByPermission && activePosition?.scopeByGroup) {
      filter.id = getGraphQlEqualComparison(activeMember.memberGroupId) ?? null;
    } else if (excludeIds && excludeIds.length > 0) {
      filter.id = { doesNotContain: excludeIds };
    }

    return filter;
  };

  const classes = classNames('employee-picker', className);

  return (
    <GroupPicker
      query={memberGroupsQuery}
      filter={getFilter()}
      queryResolver={queryResolver}
      singleQueryResolver={singleQueryResolver}
      onSelect={onSelect}
      singleQuery={memberGroupQuery}
      className={classes}
      includeNoneOption={includeNoneOption}
      {...rest}
    />
  );
}

EmployeeGroupPicker.defaultProps = {
  filterByPermission: false,
};

export default EmployeeGroupPicker;
