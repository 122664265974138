import { useApolloClient } from '@apollo/client';
import { MEMBER_NAME_QUERY } from 'apollo/queries/member-queries';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { IMember } from 'types';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';

export default function useCheckCanMangeTimeForMembers(memberIds?: string[] | null) {
  const client = useApolloClient();
  const [canManageMembers, setCanManageMembers] = useState<boolean>(true);

  useEffect(() => {
    checkCanManageTimeForMembers();
  }, [memberIds]);

  async function checkCanManageTimeForMembers() {
    if (!isNil(memberIds)) {
      const results = await client.query<{ members: IMember[] }>({
        query: MEMBER_NAME_QUERY,
        variables: {
          filter: {
            id: { contains: memberIds },
            permissions: {
              permissions: [MemberPermission.MANAGE_TIME_ENTRIES],
              operationType: OperationType.AND,
            },
          },
        },
        fetchPolicy: 'network-only',
      });
      setCanManageMembers(results.data.members.length > 0);
    }
  }

  return canManageMembers;
}
