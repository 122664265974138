import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import {
  COST_CODES_WITH_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_TIME_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { first, groupBy, isEmpty, keys, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { getMemberProjectCostCodeEquipmentMetricData } from 'utils/jitMetricQueries';
import { aggregateMetricAggregate } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import {
  ConditionNullableFieldType,
  CostCode,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Scalars,
} from '__generated__/graphql';
import { ICostCodeActivityTableRowInfo } from './useCostCodeActivity';

export interface ICostCodeDateActivityTableRowInfo extends ICostCodeActivityTableRowInfo {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

interface CostCodeMetricRow extends LaborMetricsInterface {
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useCostCodeActivityDateRangeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const remainingItemRef = useRef<ICostCodeDateActivityTableRowInfo>();
  const [data, setData] = useState<ICostCodeDateActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'costCode',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost ? COST_CODES_WITH_PROJECT_METRICS_QUERY : COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost ? COST_CODES_WITH_MEMBER_METRICS_QUERY : COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? COST_CODES_WITH_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? COST_CODES_WITH_METRICS_QUERY : COST_CODES_WITH_TIME_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: ICostCodeDateActivityTableRowInfo, key: keyof ICostCodeDateActivityTableRowInfo) {
    if (key === 'costCode') {
      return getCostCodeDisplay(item.costCode);
    } else {
      return item[key];
    }
  }

  async function loadData() {
    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: intervalType,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const tableRows = mapNotNull(costCodes, (costCode) => createTableRowInfo(costCode));

    remainingItemRef.current = await getNoCostCodeMetrics();

    setData(tableRows);
  }

  const createTableRowInfo = (costCode: CostCode): ICostCodeDateActivityTableRowInfo | null => {
    const metrics = laborMetrics(costCode);

    const unsortedDateRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: costCode.id + metric.start,
        startDate: dateTimeFromISOWithoutZone(metric.start),
        endDate: dateTimeFromISOWithoutZone(metric.end),
        costCode,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const dateRows = sortBy(unsortedDateRows, (row) => row.startDate.toSeconds());

    if (isEmpty(dateRows)) {
      return null;
    } else {
      const costCoderTotalMetrics = aggregateActivityRows(dateRows);
      return {
        ...costCoderTotalMetrics,
        id: costCode.id,
        startDate: null,
        endDate: null,
        costCode,
        detailRows: dateRows,
      };
    }
  };

  async function getNoCostCodeMetrics(): Promise<ICostCodeDateActivityTableRowInfo | undefined> {
    const metrics = await getMemberProjectCostCodeEquipmentMetricData(
      client,
      timeRange,
      canViewCost,
      intervalType,
      filterType === ActivityReportType.BY_EMPLOYEE ? filterId : undefined,
      filterType === ActivityReportType.BY_PROJECT ? filterId : undefined,
      remainingDataItemId,
      filterType === ActivityReportType.BY_EQUIPMENT ? filterId : undefined
    );

    const metricsByDate = groupBy(metrics, (data) => data.start);
    const dates = keys(metricsByDate);
    const dateRows = mapNotNull(dates, (date) => {
      const metricsOnDate = metricsByDate[date];
      const dateMetrics = aggregateMetricAggregate(metricsOnDate as IJitLaborMetric[]);

      const firstRow = first(metricsOnDate)!;

      // needs to igonore decendants when not filtering by a project id so we don't double count sub project data
      const ignoreDecendants = filterType === ActivityReportType.BY_PROJECT ? filterIdWithDescendants === false : true;
      const metricTotal = getGeneratedMetricLaborTotals(dateMetrics, ignoreDecendants);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + date,
        startDate: dateTimeFromISOWithoutZone(firstRow.start),
        endDate: dateTimeFromISOWithoutZone(firstRow.end),
        costCode: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    if (!isEmpty(dateRows)) {
      const noCostCodeData = aggregateActivityRows(dateRows);

      return {
        costCode: null,
        startDate: null,
        endDate: null,
        ...noCostCodeData,
        detailRows: dateRows,
      };
    } else {
      return undefined;
    }
  }

  const laborMetrics = (costCode: CostCode): CostCodeMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return costCode.costCodeProjectLaborMetrics;
      case ActivityReportType.BY_EMPLOYEE:
        return costCode.costCodeMemberLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return costCode.costCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return costCode.costCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
