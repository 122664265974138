import { ApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';
import OrganizationTimeMetrics from 'types/aggregate/OrganizationLaborMetrics';
import { LaborMetricsInterval } from '__generated__/graphql';
import { MetricQueryProvider } from '../useMetricAggregates';
import { ORGANIZATION_LABOR_METRIC_QUERY, ORGANIZATION_TIME_METRIC_QUERY } from './organization-metric-queries';

export function organizationTimeMetricQueryProvider(client: ApolloClient<object>): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const results = await client.query<{ organizationLaborMetrics: OrganizationTimeMetrics[] }>({
      query: ORGANIZATION_TIME_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
      },
    });

    return results.data.organizationLaborMetrics;
  };
}

export function organizationLaborMetricQueryProvider(client: ApolloClient<object>): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const results = await client.query<{ organizationLaborMetrics: OrganizationTimeMetrics[] }>({
      query: ORGANIZATION_LABOR_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
      },
    });

    return results.data.organizationLaborMetrics;
  };
}
