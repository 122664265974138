import { ActionCreator, UnknownAction } from 'redux';

export * from './authMember/authMemberActions';
export * from './authentication/authenticationActions';
export { updateFeatureFlag } from './features';
export * from './organization/organizationActions';
export * from './organizationSubscriptionStatus/organizationSubscriptionStatusActions';
export * from './session/sessionActions';

export interface IStoreLogoutAction extends UnknownAction {
  type: 'LOGOUT';
}

export const logoutAction: ActionCreator<IStoreLogoutAction> = () => ({
  type: 'LOGOUT',
});
