import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { t } from 'utils/localize';
import CaliforniaBreakLawsForm from './CaliforniaBreakLawsForm';

export interface ICaliforniaBreakLawsFormDialogProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  memberId: string;
  isOpen: boolean;
  onClose: () => void;
}

const CaliforniaBreakLawsFormDialog = (props: ICaliforniaBreakLawsFormDialogProps) => {
  const { className, timeRange, memberId, isOpen, onClose } = props;

  const classes = classNames(
    {
      'california-break-law-form-dialog': true,
    },
    className
  );

  return (
    <HeaderDialog
      className={classes}
      isOpen={isOpen}
      title={t('California Break Laws')}
      onClose={onClose}
      divider={true}
    >
      <CaliforniaBreakLawsForm timeRange={timeRange} memberId={memberId} />
    </HeaderDialog>
  );
};

export default CaliforniaBreakLawsFormDialog;
