import createAccountRpcService from 'services/rpc/accountRpcService';
import store from 'store/store';
import { getAxiosClient } from 'utils/axiosClient';
import { t } from 'utils/localize';

export default function v_unique_username(
  value: any,
  args: { previousUsername?: string }
): { validate: () => boolean | Promise<boolean>; message: string } {
  const state = store.getState();
  const authentication = state.authentication;
  const environment = state.environment;
  const axiosClient = getAxiosClient({ authentication, url: environment.REACT_APP_RESTAPI_URL });
  const accountRpc = createAccountRpcService(axiosClient);

  if (args.previousUsername && value === args.previousUsername) {
    return {
      message: t('Username is the same as the previous one'),
      validate: () => true,
    };
  }

  return {
    message: t('Username is already taken'),
    validate: async () => {
      return new Promise((resolve, reject) => {
        accountRpc
          .usernameAvailable({ username: value?.trim() })
          .then((response) => {
            resolve(response.data.result.data === true);
          })
          .catch(() => {
            reject(false);
          });
      }) as Promise<boolean>;
    },
  };
}
