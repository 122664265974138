import {
  DailySignatureCardQuery,
  OrganizationSignOffQuestion,
  OrganizationSignOffQuestionAudience,
} from '__generated__/graphql';
import { BASIC_SAFETY_SIGNATURES_FRAGMENT } from 'apollo/queries/safety-signature-query';
import gql from 'graphql-tag';
import { useApolloPaging, useOrganization } from 'hooks';
import useGetOrganizationSignOffQuestions from 'hooks/models/organization-sign-off-questions/useGetOrganizationSignOffQuestions';
import { difference, groupBy, isNil, some, sumBy, uniq, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { IMember } from 'types';
import ICursorable from 'types/Cursorable';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import MemberPermission from 'types/enum/MemberPermission';
import { NonNullArrayField } from 'types/util/NonNullArrayField';
import { mapNotNull } from 'utils/collectionUtils';
import { dateTimeFromISOKeepZone, getDateTimesBetween } from 'utils/dateUtils';
import { SIGN_IN_TIME_ENTRIES_QUERY } from './useDailySignInData';

export interface IDailySignatureAggregatedInfo {
  injuryCount: number;
  inaccurateTimeCount: number;
  breakPolicyCount: number;
  customFlaggedCount: number;
  missedSignOffs: number;
}

export interface IDailySignatureResolvedViewStatus {
  hideProcessedInjuries: boolean;
  hideResolvedInaccuracies: boolean;
  hideResolvedBreaks: boolean;
  hideResolvedCustomQuestions: boolean;
}

interface SignatureStatusInfo {
  id: string;
  timeCorrect: boolean;
  breakCompliance: boolean;
  injured: boolean;
  customFlagged: boolean;
  injuredResolved: boolean;
  breakComplianceResolved: boolean;
  timeCorrectResolved: boolean;
  customFlaggedResolved: boolean;
}

export default function useDailySignatureAlertsStatus(
  timeRange: ITimeRange<DateTime>,
  viewStatus: IDailySignatureResolvedViewStatus
) {
  const { getAll } = useApolloPaging();
  const [error, setError] = useState<string | null>(null);
  const [aggregatedInfo, setAggregatedInfo] = useState<IDailySignatureAggregatedInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refetch, setRefetch] = useState<boolean>(false);
  const getOrgSignOffQuestions = useGetOrganizationSignOffQuestions();
  const organization = useOrganization();

  function memberHasSignOffQuestions(member: IMember, questions: OrganizationSignOffQuestion[]) {
    if (isNil(member.positionId)) {
      throw Error('Position id nil. Make sure you request the position id when you query for the member');
    }

    const hasCustomQuestions = questions.some((question) => {
      return (
        question.audienceType === OrganizationSignOffQuestionAudience.Everyone ||
        (question.audienceType === OrganizationSignOffQuestionAudience.Position &&
          question.positionId === member.positionId)
      );
    });

    return hasCustomQuestions || organization.safetySignature || organization.timeAccuracy || organization.breakPolicy;
  }

  useMemo(async () => {
    function getSafetySignatures(safetyTimeRange: ITimeRange<DateTime>) {
      const startTime = safetyTimeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false });
      const endTime = safetyTimeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false });

      return getAll<NonNullArrayField<DailySignatureCardQuery['safetySignatures']>>('safetySignatures', {
        query: DAILY_SIGNATURE_CARD_QUERY,
        variables: {
          first: 500,
          filter: {
            endTime: {
              lessThanOrEqual: endTime,
              greaterThanOrEqual: startTime,
            },
            deletedOn: { isNull: true },
            member: {
              permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and', includeSelf: true },
              hasTime: { startTime, endTime, paidTimeOff: false, includeOpenEntry: false },
            },
          },
          sort: [{ startTime: 'asc' }, { createdOn: 'asc' }],
        },
        fetchPolicy: 'network-only',
      });
    }

    function getClosedTimeEntries(safetyTimeRange: ITimeRange<DateTime>) {
      const startTime = safetyTimeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false });
      const endTime = safetyTimeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false });

      return getAll<ITimeEntry & ICursorable>('timeEntries', {
        query: SIGN_IN_TIME_ENTRIES_QUERY,
        variables: {
          first: 500,
          filter: {
            member: {
              permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and', includeSelf: true },
            },
            startTime: { lessThanOrEqual: endTime },
            endTime: { greaterThanOrEqual: startTime },
            deletedOn: { isNull: true },
          },
        },
        fetchPolicy: 'network-only',
      });
    }

    const dates = getDateTimesBetween(timeRange.startTime, timeRange.endTime);
    const signaturePromises = dates.map(async (date) => {
      return await getSafetySignatures({ startTime: date.startOf('day'), endTime: date.endOf('day') });
    });
    const closedTimeEntriesPromises = dates.map(async (date) => {
      return await getClosedTimeEntries({ startTime: date.startOf('day'), endTime: date.endOf('day') });
    });

    const signOffQuery = (await Promise.all([...signaturePromises])).flat();
    const timeEntriesQuery = (await Promise.all([...closedTimeEntriesPromises])).flat();
    const signOffQuestionsQuery = getOrgSignOffQuestions({ deletedOn: { isNull: true } });

    return await Promise.all([signOffQuery, timeEntriesQuery, signOffQuestionsQuery])
      .then(async ([queryResults, closedTimeEntries, signOffQuestions]) => {
        const signatures = queryResults;
        const signOffMap = groupBy(signatures, (entry) => entry.memberId);
        const memberIds = uniq(signatures.map((e) => e.memberId));
        const results = mapNotNull(memberIds, (memberId) => {
          const signOffEntries = signOffMap[memberId] ?? [];

          let injured: boolean | null = null;
          let timeCorrect: boolean | null = null;
          let breakCompliance: boolean | null = null;
          let customFlagged: boolean | null = null;
          let injuredResolved: boolean | null = null;
          let timeCorrectResolved: boolean | null = null;
          let breakComplianceResolved: boolean | null = null;
          let customFlaggedResolved: boolean | null = null;

          if (some(signOffEntries, (item) => item.timeAccurate === false)) {
            timeCorrect = false;
            timeCorrectResolved = !signOffEntries.some(
              (item) => isNil(item.timeAccurateResolvedOn) && item.timeAccurate === false
            );
          } else if (some(signOffEntries, (item) => item.timeAccurate === true)) {
            timeCorrect = true;
          }

          if (some(signOffEntries, (item) => item.breakPolicyFollowed === false)) {
            breakCompliance = false;
            breakComplianceResolved = !signOffEntries.some(
              (item) => isNil(item.breakPolicyFollowedResolvedOn) && item.breakPolicyFollowed === false
            );
          } else if (some(signOffEntries, (item) => item.breakPolicyFollowed === true)) {
            breakCompliance = true;
          }

          if (some(signOffEntries, (item) => item.injured === true)) {
            // there is at least 1 report of an injury
            injured = true;
            injuredResolved = !signOffEntries.some((item) => isNil(item.injuredResolvedOn) && item.injured === true);
          } else if (some(signOffEntries, (item) => item.injured === false)) {
            // there are no injuries and there has been at least 1 time that the user reported that they were not injured
            injured = false;
          }

          if (some(signOffEntries, (item) => item.customQuestionsFlagged === true)) {
            customFlagged = true;
            customFlaggedResolved = !signOffEntries.some(
              (item) => isNil(item.customQuestionsResolvedOn) && item.customQuestionsFlagged === true
            );
          } else if (some(signOffEntries, (item) => item.customQuestionsFlagged === false)) {
            customFlagged = false;
          }

          return {
            id: memberId,
            timeCorrect,
            breakCompliance,
            injured,
            customFlagged,
            injuredResolved,
            breakComplianceResolved,
            timeCorrectResolved,
            customFlaggedResolved,
          } as SignatureStatusInfo;
        });

        const injuryCount = getInjuredCount(results, viewStatus.hideProcessedInjuries);
        const inaccurateTimeCount = getInaccurateTimeCount(results, viewStatus.hideResolvedInaccuracies);
        const breakPolicyCount = getBreakPolicyCount(results, viewStatus.hideResolvedBreaks);
        const customFlaggedCount = getCustomFlaggedCount(results, viewStatus.hideResolvedCustomQuestions);

        const allClosedTimeEntriesByDay = groupBy(closedTimeEntries, (timeEntry) =>
          dateTimeFromISOKeepZone(timeEntry.startTime).toISODate().toString()
        );
        const submissionsByDate = groupBy(signatures, (submission) =>
          dateTimeFromISOKeepZone(submission.startTime).toISODate().toString()
        );

        const missedSignOffs = sumBy(dates, (date) => {
          const dateKey = date.toISODate().toString();
          const timeEntriesInDay = allClosedTimeEntriesByDay[dateKey] ?? [];
          const membersWithTime = uniqBy(
            timeEntriesInDay.map((timeEntry) => timeEntry.member),
            (member) => member.id
          );
          const signOffApplicableMemberIds = mapNotNull(membersWithTime, (member) => {
            // check if the member can sign off
            if (memberHasSignOffQuestions(member, signOffQuestions)) {
              return member.id;
            }

            return null;
          });

          const submissionsOnDate = submissionsByDate[dateKey] ?? [];
          const submittedMemberIds = uniq(submissionsOnDate.map((submission) => submission.memberId));
          const unsignedCount = difference(signOffApplicableMemberIds, submittedMemberIds).length;
          return unsignedCount > 0 ? unsignedCount : 0; // guard against negative in case of member signed off but then deleted time entries
        });

        setAggregatedInfo({
          injuryCount,
          inaccurateTimeCount,
          breakPolicyCount,
          customFlaggedCount,
          missedSignOffs,
        });

        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timeRange.endTime,
    timeRange.startTime,
    viewStatus.hideProcessedInjuries,
    viewStatus.hideResolvedBreaks,
    viewStatus.hideResolvedInaccuracies,
  ]);

  function getInjuredCount(signatures: Array<SignatureStatusInfo>, hideProcessedInjuries: boolean): number {
    let injuries = 0;

    signatures.forEach((sig) => {
      if (hideProcessedInjuries) {
        sig.injuredResolved === false && sig.injured ? injuries++ : null;
      } else {
        sig.injured ? injuries++ : null;
      }
    });

    return injuries;
  }

  function getInaccurateTimeCount(signatures: Array<SignatureStatusInfo>, hideResolvedInaccuracies: boolean): number {
    let inaccurateTimes = 0;

    signatures.forEach((sig) => {
      if (hideResolvedInaccuracies) {
        sig.timeCorrectResolved === false && sig.timeCorrect === false ? inaccurateTimes++ : null;
      } else {
        {
          sig.timeCorrect === false ? inaccurateTimes++ : null;
        }
      }
    });

    return inaccurateTimes;
  }

  function getBreakPolicyCount(signatures: Array<SignatureStatusInfo>, hideResolvedBreakIssues: boolean): number {
    let breakPolicies = 0;

    signatures.forEach((sig) => {
      if (hideResolvedBreakIssues) {
        sig.breakComplianceResolved === false && sig.breakCompliance === false ? breakPolicies++ : null;
      } else {
        sig.breakCompliance === false ? breakPolicies++ : null;
      }
    });

    return breakPolicies;
  }

  function getCustomFlaggedCount(signatures: Array<SignatureStatusInfo>, hideCustomFlagged: boolean): number {
    let customFlagged = 0;

    signatures.forEach((sig) => {
      if (hideCustomFlagged) {
        sig.customFlaggedResolved === false && sig.customFlagged ? customFlagged++ : null;
      } else {
        sig.customFlagged ? customFlagged++ : null;
      }
    });

    return customFlagged;
  }

  return { aggregatedInfo, error, isLoading, setRefetch, refetch };
}

export const DAILY_SIGNATURE_CARD_QUERY = gql`
  query DailySignatureCard($first: Int, $after: String, $filter: SafetySignatureFilter, $sort: [SafetySignatureSort!]) {
    safetySignatures(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      ...BasicSafetySignatureFragment
    }
  }
  ${BASIC_SAFETY_SIGNATURES_FRAGMENT}
`;
