import classNames from 'classnames';
import { IChildrenProps } from 'types/ChildrenProps';
import { ClassName } from "types/ClassName";
import './ThumbnailTray.scss';

interface IProps extends IChildrenProps {
  className?: ClassName;
}

const ThumbnailTray = ( { children, className }: IProps) => {
  const classes = classNames(
    'thumbnail-tray',
    className
  );

  return <div className={classes}>{children}</div>;
};

export default ThumbnailTray;
