import { ApolloClient, useApolloClient } from '@apollo/client';
import { Button, Checkbox, Icon, Row, Size, Theme } from '@busybusy/webapp-react-ui';
import { CommentIcon, DownloadIcon } from 'assets/icons';
import classNames from 'classnames';
import { Thumbnail } from 'components';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/types';
import { ClassName } from 'types/ClassName';
import { IMediaCollection } from 'types/MediaCollection';
import { IMediaEntry, MediaEntryType } from 'types/MediaEntry';
import { getVideoTimeStringfromSeconds } from 'utils/stringUtils';
import { useMediaEntryComments } from '../hooks/useMediaEntryComments';
import PhotoUtilities, {
  getCollectionForMediaFromCollections,
  getPhotoThumbnailUrl,
} from '../photo-utilities/photo-utilities';
import './MediaEntry.scss';
import ProcessingPhoto from './ProcessingPhoto/ProcessingPhoto';

export interface IMediaEntryContainerProps {
  className?: ClassName;
  checkable: boolean;
  mediaEntry: IMediaEntry;
  clickable: boolean;
  onCheck?: (entry: IMediaEntry, isChecked: boolean) => void;
  checked?: boolean;
  onMediaEntryClicked?: (entry: IMediaEntry) => void;
  showDock?: boolean;
  reFetchComments?: boolean;
}

export interface IMediaEntryProps extends Omit<IMediaEntryContainerProps, 'isShifted'> {
  apolloClient: ApolloClient<any>;
  mediaCollections: IMediaCollection[];
}

export const MediaEntryContainer: React.FC<IMediaEntryContainerProps> = (props) => {
  const { ...rest } = props;
  const apolloClient = useApolloClient();
  const mediaCollections = useSelector<IReduxState, IMediaCollection[]>((state) => state.photos.mediaCollections);

  return <MediaEntry {...rest} apolloClient={apolloClient} mediaCollections={mediaCollections} />;
};

export const MediaEntry: React.FC<IMediaEntryProps> = (props) => {
  const {
    checked,
    checkable,
    clickable,
    onMediaEntryClicked,
    mediaEntry,
    mediaCollections,
    onCheck,
    className,
    apolloClient,
    showDock,
    reFetchComments,
  } = props;

  const getComments = useMediaEntryComments(mediaEntry.id);
  const [commentCount, setCommentCount] = useState<number>(0);

  useEffect(() => {
    getCommentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reFetchComments]);

  const getCommentCount = async () => {
    const comments = await getComments();
    setCommentCount(comments.length);
  };

  const classes = classNames('media-entry', className);

  const encompassingCollection = getCollectionForMediaFromCollections(mediaCollections, mediaEntry)!;

  function handleOnClick() {
    if (clickable && onMediaEntryClicked) {
      onMediaEntryClicked(mediaEntry);
    }
  }

  function handleOnCheck(isChecked: boolean) {
    if (checkable && onCheck) {
      onCheck(mediaEntry, isChecked);
    }
  }

  function handleDownload() {
    return PhotoUtilities.loadMediaEntry(apolloClient, mediaEntry.id).then((freshMediaEntries) => {
      const entryCollection = { encompassingCollection, mediaEntry: freshMediaEntries.shift()! };

      return PhotoUtilities.downloadMediaEntry(entryCollection);
    });
  }

  function renderDockTemplate() {
    return (
      <Row className="p-1">
        <Button size={Size.SMALL} theme={Theme.DARK} type="icon" onClick={handleDownload}>
          <Icon svg={DownloadIcon} />
        </Button>
      </Row>
    );
  }

  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
    e.nativeEvent.stopPropagation();
  }

  return (
    <div className={classes}>
      {(mediaEntry.entryType === MediaEntryType.IMAGE || mediaEntry.entryType === MediaEntryType.VIDEO) && (
        <>
          {isNil(mediaEntry.fileUrl) ? (
            <ProcessingPhoto />
          ) : (
            <div className="watermark" onClick={handleOnClick}>
              <Thumbnail
                className={classes}
                key={mediaEntry.id}
                fileUrl={
                  mediaEntry.entryType === MediaEntryType.VIDEO
                    ? mediaEntry.videoThumbFileUrl
                    : mediaEntry.annotationFileUrl
                      ? getPhotoThumbnailUrl(mediaEntry.annotationFileUrl)
                      : getPhotoThumbnailUrl(mediaEntry.fileUrl)
                }
                onClick={handleOnClick}
                dockTemplate={showDock === false ? undefined : renderDockTemplate()}
              >
                {checkable && checked !== undefined && (
                  <Checkbox className="m-1" onChange={handleOnCheck} checked={checked} onClick={stopPropagation} />
                )}
              </Thumbnail>
              {commentCount > 0 && (
                <div className="watermark-text">
                  <Row className={'watermark-text-content'}>
                    <Icon svg={CommentIcon} />
                    <div className="px-1">{commentCount}</div>
                  </Row>
                </div>
              )}
              {mediaEntry.entryType === MediaEntryType.VIDEO && mediaEntry.metaData && (
                <div className={`watermark-timestamp pl-3 ${commentCount > 0 ? 'with-comments' : ''}`}>
                  <Row className={'watermark-timestamp-content'}>
                    {getVideoTimeStringfromSeconds(JSON.parse(mediaEntry.metaData).duration as string)}
                  </Row>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MediaEntryContainer;
