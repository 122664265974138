import { useOrganization } from 'hooks';
import useTimeCardsSettingsUpdate from 'hooks/models/member-settings/useTimeCardsSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import { mergeListsWithOverride, updateItemInList } from 'utils/collectionUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';

export const defaultSummaryColumns: IVisibleTableColumn[] = [
  {
    key: 'member.memberGroup.groupName',
    title: t('Employee Group'),
    visible: true,
    position: 0,
  },
  {
    key: 'employeeSigned',
    title: t('Employee Signed'),
    visible: true,
    position: 1,
  },
  {
    key: 'supervisorSigned',
    title: t('Supervisor Signed'),
    visible: true,
    position: 2,
  },
  {
    key: 'timeAccurate',
    title: t('Time Accurate'),
    visible: true,
    position: 3,
  },
  {
    key: 'breakCompliance',
    title: t('Break Compliance'),
    visible: true,
    position: 4,
  },
  {
    key: 'injured',
    title: t('Injured'),
    visible: true,
    position: 5,
  },
  {
    key: 'regularHours',
    title: t('Regular Hours'),
    visible: true,
    position: 6,
  },
  {
    key: 'regularHoursDec',
    title: t('Regular Hours (Decimal)'),
    visible: false,
    position: 7,
  },
  {
    key: 'overtimeHours',
    title: t('Overtime Hours'),
    visible: true,
    position: 8,
  },
  {
    key: 'overtimeHoursDec',
    title: t('Overtime Hours (Decimal)'),
    visible: false,
    position: 9,
  },
  {
    key: 'doubleTimeHours',
    title: t('Double Time Hours'),
    visible: false,
    position: 10,
  },
  {
    key: 'doubleTimeHoursDec',
    title: t('Double Time Hours (Decimal)'),
    visible: false,
    position: 11,
  },
  {
    key: 'paidTimeOff',
    title: t('Paid Time Off'),
    visible: true,
    position: 12,
  },
  {
    key: 'totalHours',
    title: t('Total Hours'),
    visible: true,
    position: 13,
  },
  {
    key: 'totalHoursDec',
    title: t('Total Hours (Decimal)'),
    visible: false,
    position: 14,
  },
  {
    key: 'flaggedSignIn',
    title: t('Flagged Sign In'),
    visible: false,
    position: 15,
  },
  {
    key: 'flaggedSignOff',
    title: t('Flagged Sign Off'),
    visible: false,
    position: 16,
  },
];

export const defaultTimeEntryColumns: IVisibleTableColumn[] = [
  {
    key: 'total',
    title: t('Total'),
    visible: true,
    position: 0,
  },
  {
    key: 'start',
    title: t('Start'),
    visible: true,
    position: 1,
  },
  {
    key: 'end',
    title: t('End'),
    visible: true,
    position: 2,
  },
  {
    key: 'breaks',
    title: t('Breaks'),
    visible: true,
    position: 3,
  },
  {
    key: 'project',
    title: t('Project'),
    visible: true,
    position: 4,
  },
  {
    key: 'costCode',
    title: t('Cost Code'),
    visible: true,
    position: 5,
  },
  {
    key: 'equipment',
    title: t('Equipment'),
    visible: true,
    position: 6,
  },
  {
    key: 'type',
    title: t('Type'),
    visible: true,
    position: 7,
  },
  {
    key: 'description',
    title: t('Description'),
    visible: true,
    position: 8,
  },
];

export const defaultWeeklyColumns: IVisibleTableColumn[] = [
  {
    key: 'total',
    title: t('Total'),
    visible: true,
    position: 1,
  },
  {
    key: 'ot',
    title: t('OT'),
    visible: false,
    position: 2,
  },
  {
    key: 'dt',
    title: t('DT'),
    visible: false,
    position: 3,
  },
  {
    key: 'breaks',
    title: t('Break'),
    visible: false,
    position: 4,
  },
  {
    key: 'breakCompliance',
    title: t('Break Compliance'),
    visible: false,
    position: 5,
  },
  {
    key: 'timeAccurate',
    title: t('Time Accurate'),
    visible: false,
    position: 6,
  },
  {
    key: 'injured',
    title: t('Injured'),
    visible: false,
    position: 7,
  },
  {
    key: 'pto',
    title: t('Paid Time Off'),
    visible: false,
    position: 8,
  },
];

export const defaultDailyColumns: IVisibleTableColumn[] = [
  {
    key: 'total',
    title: t('Total'),
    visible: true,
    position: 1,
  },
  {
    key: 'ot',
    title: t('OT'),
    visible: false,
    position: 2,
  },
  {
    key: 'dt',
    title: t('DT'),
    visible: false,
    position: 3,
  },
  {
    key: 'breaks',
    title: t('Break'),
    visible: true,
    position: 4,
  },
  {
    key: 'breakCompliance',
    title: t('Break Compliance'),
    visible: false,
    position: 5,
  },
  {
    key: 'timeAccurate',
    title: t('Time Accurate'),
    visible: false,
    position: 6,
  },
  {
    key: 'injured',
    title: t('Injured'),
    visible: false,
    position: 7,
  },
  {
    key: 'pto',
    title: t('Paid Time Off'),
    visible: false,
    position: 8,
  },
];

export function hasDailySignOffColumn(columns: IVisibleTableColumn[]): boolean {
  return columns
    .filter((item) => item.visible)
    .some((item) => item.key === 'injured' || item.key === 'breakCompliance' || item.key === 'timeAccurate');
}

export function hasReportAggregateColumn(columns: IVisibleTableColumn[]): boolean {
  return columns
    .filter((item) => item.visible)
    .some(
      (item) =>
        item.key === 'totalHours' ||
        item.key === 'regularHours' ||
        item.key === 'overtimeHours' ||
        item.key === 'doubleTimeHours'
    );
}

export function hasTimeOffColumn(columns: IVisibleTableColumn[]): boolean {
  return columns.filter((item) => item.visible).some((item) => item.key === 'paidTimeOff');
}

export function hasPayPeriodSignatureColumn(columns: IVisibleTableColumn[]): boolean {
  return columns
    .filter((item) => item.visible)
    .some((item) => item.key === 'employeeSigned' || item.key === 'supervisorSigned');
}

export default function useTimesheetsColumns() {
  const isPro = useFeatureFlags('PRO');
  const memberSettings = useMemberSettings();
  const updateSettings = useTimeCardsSettingsUpdate();

  const [summaryColumns, setSummaryColumns] = useState(
    mergeListsWithOverride(
      defaultSummaryColumns,
      memberSettings?.web?.features?.timeCards?.summary?.columnSettings ?? [],
      'key',
      ['position', 'visible']
    )
  );
  const [timeEntryColumns, setTimeEntryColumns] = useState(
    mergeListsWithOverride(
      defaultTimeEntryColumns,
      memberSettings?.web?.features?.timeCards?.timeEntry?.columnSettings ?? [],
      'key',
      ['position', 'visible']
    )
  );
  const [weeklyColumns, setWeeklyColumns] = useState(
    mergeListsWithOverride(
      defaultWeeklyColumns,
      memberSettings?.web?.features?.timeCards?.weeklyGrid?.weeklyColumns ?? [],
      'key',
      ['position', 'visible']
    )
  );
  const [dailyColumns, setDailyColumns] = useState(
    mergeListsWithOverride(
      defaultDailyColumns,
      memberSettings?.web?.features?.timeCards?.weeklyGrid?.dailyColumns ?? [],
      'key',
      ['position', 'visible']
    )
  );

  const updateSummaryColumn = (checked: boolean, key: string) => {
    setSummaryColumns((columns) => {
      return updateItemInList(columns, key, (prev) => ({ ...prev, visible: checked }), 'key');
    });
  };
  const updateTimeEntryColumn = (checked: boolean, key: string) => {
    setTimeEntryColumns((columns) => {
      return updateItemInList(columns, key, (prev) => ({ ...prev, visible: checked }), 'key');
    });
  };
  const updateWeeklyColumn = (checked: boolean, key: string) => {
    setWeeklyColumns((columns) => {
      return updateItemInList(columns, key, (prev) => ({ ...prev, visible: checked }), 'key');
    });
  };
  const updateDailyColumn = (checked: boolean, key: string) => {
    setDailyColumns((columns) => {
      return updateItemInList(columns, key, (prev) => ({ ...prev, visible: checked }), 'key');
    });
  };

  const saveColumns = () => {
    updateSettings([
      { key: 'summary', payload: { columnSettings: summaryColumns } },
      { key: 'timeEntry', payload: { columnSettings: timeEntryColumns } },
      { key: 'weeklyGrid', payload: { weeklyColumns, dailyColumns } },
    ]);
  };

  // filter out the columns that aren't available due to company settings having it turned off
  const organization = useOrganization();
  const unavailableSummaryColumnKeys = Array<string>();
  const unavailableTimeEntryColumnKeys = Array<string>();
  const unavailableWeekGridColumnKeys = Array<string>();

  let disabledProColumns = Array<IVisibleTableColumn>();
  if (!organization.signatureDate) {
    unavailableSummaryColumnKeys.push('employeeSigned');
    unavailableSummaryColumnKeys.push('supervisorSigned');
  } else if (!organization.authoritativeSignature) {
    unavailableSummaryColumnKeys.push('supervisorSigned');
  }

  if (isPro && !organization.safetySignature) {
    unavailableSummaryColumnKeys.push('injured');
    unavailableWeekGridColumnKeys.push('injured');
  }
  if (isPro && !organization.timeAccuracy) {
    unavailableSummaryColumnKeys.push('timeAccurate');
    unavailableWeekGridColumnKeys.push('timeAccurate');
  }
  if (isPro && !organization.breakPolicy) {
    unavailableSummaryColumnKeys.push('breakCompliance');
    unavailableWeekGridColumnKeys.push('breakCompliance');
  }

  if (!organization.trackCostCode) {
    unavailableTimeEntryColumnKeys.push('costCode');
  }

  if (!organization.trackEquipment) {
    unavailableTimeEntryColumnKeys.push('equipment');
  }

  if (
    !organization.customSignOff &&
    !organization.breakPolicy &&
    !organization.timeAccuracy &&
    !organization.safetySignature
  ) {
    unavailableSummaryColumnKeys.push('flaggedSignOff');
  }

  if (!organization.busySignInQuestion) {
    unavailableSummaryColumnKeys.push('flaggedSignIn');
  }

  const availableSummaryColumns = sortBy(
    summaryColumns.filter((item) => !unavailableSummaryColumnKeys.some((key) => key === item.key)),
    (item) => item.position ?? 0
  );
  const availableTimeEntryColumns = sortBy(
    timeEntryColumns.filter((item) => !unavailableTimeEntryColumnKeys.some((key) => key === item.key)),
    (item) => item.position ?? 0
  );

  const availableWeeklyColumns = sortBy(
    weeklyColumns.filter((item) => !unavailableWeekGridColumnKeys.some((key) => key === item.key)),
    (item) => item.position ?? 0
  );

  const availableDailyColumns = sortBy(
    dailyColumns.filter((item) => !unavailableWeekGridColumnKeys.some((key) => key === item.key)),
    (item) => item.position ?? 0
  );

  // disabled columns to show to free users
  if (!isPro) {
    disabledProColumns = summaryColumns.filter(
      (column) => column.key === 'injured' || column.key === 'timeAccurate' || column.key === 'breakCompliance' || column.key === 'flaggedSignOff' || column.key === 'flaggedSignIn'
    );
  }

  return {
    summaryColumns: availableSummaryColumns,
    updateSummaryColumn,
    setSummaryColumns,
    timeEntryColumns: availableTimeEntryColumns,
    updateTimeEntryColumn,
    setTimeEntryColumns,
    saveColumns,
    disabledProColumns,
    weeklyColumns: availableWeeklyColumns,
    updateWeeklyColumn,
    setWeeklyColumns,
    dailyColumns: availableDailyColumns,
    updateDailyColumn,
    setDailyColumns,
  };
}
