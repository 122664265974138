import { Button, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import { EquipmentIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Panel } from 'components';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardCardBarChart from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/DashboardBarChart';
import {
  dashboardCardBarChartGradientBlueBlue,
  dashboardCardBarChartGradientGreenGreen,
} from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/constants/constants';
import { TimeEntryActionBarContextProvider } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { ActivityReportGraphType } from 'containers/activity-reports/ActivityReportGraph/ActivityReportGraph';
import ActivityDetailsDialog from 'containers/activity-reports/activity-report-details/ActivityDetailsDialog/ActivityDetailsDialog';
import useEquipmentActivityReportColumns from 'containers/activity-reports/equipment-tables/hooks/useEquipmentActivityReportColumns';
import useActivityReportColumns from 'containers/activity-reports/hooks/useActivityReportColumns';
import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import ChannelContextProvider from 'contexts/ChannelContext/ChannelContext';
import {
  useActiveMember,
  useEquipmentManagementListNavigation,
  useOpenable,
  useOrganization,
  useOrganizationUpdate,
  useWeeklyPopulator,
} from 'hooks';
import useActivityReportNavigation from 'hooks/navigation/useActivityReportNavigation';
import { useAfterFirstRenderEffect } from 'hooks/utils/useAfterFirstRenderEffect/useAfterFirstRenderEffect';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { isNil, isNull, last } from 'lodash';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  updateActivityReportTableColumns,
  updateEquipmentActivityReportTableColumns,
} from 'store/activityReports/ActivityReports';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import { ReportType } from 'utils/aggregateUtils';
import { useFeatureFlags } from 'utils/features';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import useActivityReportDetailNavigation from '../hooks/useActivityReportDetailNavigation';
import useDashboardCardTotal from '../hooks/useDashboardCardTotal';
import useEquipmentMetricCardData from './hooks/useEquipmentMetricCardData';

export interface IEquipmentAggregateDashboardCardProps {
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
  dataKey: 'total' | 'overtime' | 'doubletime';
  type: ReportType;
  equipmentId?: string | null;
  memberId?: string | null;
  projectId?: string | null;
  costCodeId?: string | null;
}

const EquipmentAggregateDashboardCard = (props: IEquipmentAggregateDashboardCardProps) => {
  const { theme, className, setting, onHide, dataKey, type, equipmentId, memberId, projectId, costCodeId } = props;

  const [t] = useTranslation();
  const organization = useOrganization();
  const timeFrame = setting.options?.time ?? 'payPeriod';
  const { timeRange, timeRanges } = useDashboardGraphDates(timeFrame)!; // Null force safe for all settings but `clockStatus`
  const navigateToEquipmentActivity = useActivityReportNavigation();
  const navigateToEquipmentManagement = useEquipmentManagementListNavigation();
  const { data, isLoading, error, refetch } = useEquipmentMetricCardData(
    timeRange,
    setting.options?.time ?? 'payPeriod',
    type,
    MemberPermission.TIME_EVENTS,
    true,
    equipmentId,
    memberId,
    projectId,
    costCodeId
  );

  const total = useDashboardCardTotal(timeRanges, type, dataKey, data, timeFrame, isLoading);
  const settingsDialogDetails = useOpenable();
  const [updateKey] = useOrganizationUpdate();
  const activeMember = useActiveMember();
  const dispatch = useDispatch();
  const { availableColumns } = useActivityReportColumns();
  const { availableColumns: availableEquipmentColumns } = useEquipmentActivityReportColumns();

  const isPro = useFeatureFlags('PRO');
  const currentTime = DateTime.utc().startOf('day');
  const weeklyRangeGet = useWeeklyPopulator();
  const { timeRangeType, navRange, getActivityReportFilter, getActivityReportFilterType } =
    useActivityReportDetailNavigation(
      setting.options?.time ?? 'payPeriod',
      memberId,
      costCodeId,
      equipmentId,
      projectId
    );
  const activityReportDetailsDialog = useOpenable();

  useOnMount(() => {
    dispatch(updateActivityReportTableColumns(availableColumns));
    dispatch(updateEquipmentActivityReportTableColumns(availableEquipmentColumns));
  });

  useAfterFirstRenderEffect(() => {
    if (organization.trackEquipment) {
      refetch();
    }
  }, [timeFrame, projectId, memberId, costCodeId, equipmentId]);

  function openEquipmentSettings() {
    updateKey('trackEquipment', true);
    settingsDialogDetails.open();
  }

  function openEquipmentManagement() {
    navigateToEquipmentManagement();
  }

  const classes = classNames('equipment-cost-dashboard-card', className);

  function renderGraph() {
    if (isLoading) {
      return <DashboardCardSpinner />;
    }
    switch (type) {
      case ReportType.SECONDS:
        return (
          <DashboardCardBarChart
            data={data}
            dataKey={dataKey}
            valueType="hour"
            timeFrame={timeFrame}
            theme={theme}
            {...dashboardCardBarChartGradientBlueBlue}
          />
        );
      case ReportType.COST:
        return (
          <DashboardCardBarChart
            data={data}
            dataKey={dataKey}
            valueType="currency"
            timeFrame={timeFrame}
            theme={theme}
            {...dashboardCardBarChartGradientGreenGreen}
          />
        );
    }
  }

  function handleRightClick() {
    if (isNull(getActivityReportFilter())) {
      if (isPro) {
        navigateToEquipmentActivity({
          filter_type: 'by-equipment',
          time_range_type: timeRangeType,
          start_date: navRange.startTime.toISODate(),
          end_date: navRange.endTime.toISODate(),
          graph_type:
            type === ReportType.SECONDS ? ActivityReportGraphType.TOTAL_HOURS : ActivityReportGraphType.TOTAL_COST,
        });
      } else {
        const weeklyRange = last(weeklyRangeGet(1, currentTime.toSeconds())) ?? null;
        if (!isNull(weeklyRange)) {
          navigateToEquipmentActivity({
            filter_type: 'by-equipment',
            time_range_type: TimeRangeType.WEEKLY,
            start_date: weeklyRange.startTime.toISODate(),
            end_date: weeklyRange.endTime.toISODate(),
            graph_type:
              type === ReportType.SECONDS ? ActivityReportGraphType.TOTAL_HOURS : ActivityReportGraphType.TOTAL_COST,
          });
        }
      }
    } else {
      activityReportDetailsDialog.open();
    }
  }

  return (
    <>
      {organization.trackEquipment ? (
        error === null ? (
          <DashboardContentCard
            header={total}
            rightLabel={t('Details')}
            onRightLabelClick={handleRightClick}
            subheaderValue={getDashboardTimeFrameTitle(setting.title, setting.options?.time ?? 'payPeriod')}
            className={classes}
            theme={theme}
          >
            {data && renderGraph()}
          </DashboardContentCard>
        ) : (
          <DashboardErrorCard theme={theme} onClick={refetch} />
        )
      ) : activeMember.position?.manageCompanySettings ? (
        <DashboardEnableCard
          title={setting.title}
          svg={EquipmentIcon}
          buttonLabel={t('Enable Equipment')}
          onClick={openEquipmentSettings}
          theme={theme}
          onHide={onHide}
        />
      ) : null}
      <HeaderDialog
        title={t('Equipment Enabled')}
        isOpen={settingsDialogDetails.isOpen}
        onClose={settingsDialogDetails.close}
        position="center"
      >
        <Panel className="px-8 py-6">
          <div>
            {t(
              'Would you like to visit the equipment management page where you can create and edit equipment for your company?'
            )}
          </div>
          <Row justify={Justify.FLEX_END}>
            <Button type="secondary" onClick={settingsDialogDetails.close} className="mr-4">
              {t('No Thanks')}
            </Button>
            <Button type="primary" onClick={openEquipmentManagement}>
              {t('Manage')}
            </Button>
          </Row>
        </Panel>
      </HeaderDialog>
      {!isNull(timeRangeType) && !isNull(navRange) && (
        <TimeEntryActionBarContextProvider>
          <ChannelContextProvider>
            <ActivityDetailsDialog
              isOpen={activityReportDetailsDialog.isOpen}
              onClose={activityReportDetailsDialog.close}
              timeRange={navRange}
              timeRangeType={timeRangeType}
              filterType={getActivityReportFilterType()}
              filterId={getActivityReportFilter()}
              initialReportType={isNil(equipmentId) ? ActivityReportType.BY_EQUIPMENT : undefined}
            />
          </ChannelContextProvider>
        </TimeEntryActionBarContextProvider>
      )}
    </>
  );
};

export default EquipmentAggregateDashboardCard;
