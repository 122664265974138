// source: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export function lumaCalculation(red: number, green: number, blue: number) {
  return red * 0.299 + green * 0.587 + blue * 0.114;
}

export function lumaObjectCalculation(color: { red: number; green: number; blue: number }) {
  return lumaCalculation(color.red, color.green, color.blue);
}

export function calculateTextColorForBackground(backgroundColor: string): 'light' | 'dark' | null {
  if (backgroundColor.startsWith('#')) {
    return calculateTextColorForHexBackground(backgroundColor);
  } else if (backgroundColor.startsWith('rgb(')) {
    // expected format 'rgb(21, 23, 24)'
    return calculateTextColorForRgbBackground(backgroundColor);
  } else if (backgroundColor.startsWith('rgba(')) {
    return calculateTextColorForRgbaBackground(backgroundColor);
  } else {
    return null;
  }
}

export function calculateTextColorForHexBackground(hexColor: string): 'light' | 'dark' | null {
  const color = getColorFromHex(hexColor);
  if (color !== null) {
    return lumaCalculation(parseInt(color.red, 16), parseInt(color.green, 16), parseInt(color.blue, 16)) > 186
      ? 'dark'
      : 'light';
  } else {
    return null;
  }
}

export function calculateTextColorForRgbBackground(rgbColor: string): 'light' | 'dark' | null {
  const color = getColorFromRgb(rgbColor);
  if (color !== null) {
    return lumaCalculation(parseInt(color.red, 10), parseInt(color.green, 10), parseInt(color.blue, 10)) > 186
      ? 'dark'
      : 'light';
  } else {
    return null;
  }
}

export function calculateTextColorForRgbaBackground(rgbaColor: string): 'light' | 'dark' | null {
  const color = getColorFromRgba(rgbaColor);
  if (color !== null) {
    return lumaCalculation(parseInt(color.red, 10), parseInt(color.green, 10), parseInt(color.blue, 10)) > 186
      ? 'dark'
      : 'light';
  } else {
    return null;
  }
}

export function getColorFromHex(hexColor: string) {
  const withoutHash = hexColor.substring(1, hexColor.length);

  if (withoutHash.length === 3) {
    return {
      red: withoutHash[0] + withoutHash[0],
      green: withoutHash[1] + withoutHash[1],
      blue: withoutHash[2] + withoutHash[2],
    };
  } else if (withoutHash.length === 6) {
    return {
      red: withoutHash[0] + withoutHash[1],
      green: withoutHash[2] + withoutHash[3],
      blue: withoutHash[4] + withoutHash[5],
    };
  } else {
    return null;
  }
}

export function getColorFromRgb(rgbColor: string) {
  const colors = rgbColor.substring(4, rgbColor.length - 1).split(','); // [21, 23, 24]

  return {
    red: colors[0],
    green: colors[1],
    blue: colors[2],
  };
}

export function getColorFromRgba(rgbaColor: string) {
  const colors = rgbaColor.substring(5, rgbaColor.length - 1).split(','); // [21, 23, 24]

  return {
    red: colors[0],
    green: colors[1],
    blue: colors[2],
  };
}
