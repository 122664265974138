import { Button, Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EquipmentPicker, PanelContent } from 'components';
import EquipmentCategoryPicker from 'components/domain/equipment/EquipmentCategoryPicker/EquipmentCategoryPicker';
import { useQueryParams } from 'hooks';
import _ from 'lodash';
import { useState } from 'react';
import { QueryParam } from 'utils/constants/queryParam';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import './ManageEquipmentFilter.scss';

export interface IManageEquipmentFilterProps {
  className?: ClassName;
}

const ManageEquipmentFilter = (props: IManageEquipmentFilterProps) => {
  const { className } = props;

  const { getParam, updateParams } = useQueryParams();
  const equipmentId = getParam(QueryParam.EQUIPMENT_ID);
  const categoryId = getParam(QueryParam.EQUIPMENT_CATEGORY_ID);
  const search = getParam(QueryParam.SEARCH);
  const [localEquipmentId, setLocalEquipmentId] = useState<string | null>(equipmentId);
  const [localCategoryId, setLocalCategoryId] = useState<string | null>(categoryId);
  const isManagementListsSearchEnabled = useFeatureFlags('MANAGEMENT_LISTS_SEARCH');

  function handleEquipmentChange(id: string | null) {
    setLocalEquipmentId(id);
  }

  function handleCategoryChange(id: string | null) {
    setLocalCategoryId(id);
  }

  function handleApply() {
    updateParams({
      [QueryParam.EQUIPMENT_ID]: localEquipmentId ?? null,
      [QueryParam.EQUIPMENT_CATEGORY_ID]: localCategoryId ?? null,
      [QueryParam.SEARCH]: search,
    });
  }

  function handleReset() {
    setLocalEquipmentId(null);
    setLocalCategoryId(null);

    const params: { [key: string]: string | undefined | null } = {};
    params[QueryParam.EQUIPMENT_ID] = null;
    params[QueryParam.EQUIPMENT_CATEGORY_ID] = null;
    updateParams(params);
  }

  function didChange() {
    return localEquipmentId === equipmentId && localCategoryId === categoryId;
  }

  const classes = classNames('manage-equipment-filter', className);

  return (
    <div className={classes}>
      <PanelContent className="p-5" flex={false}>
        <h3>{t('Filter')}</h3>
        <div className="pt-5">
          {!isManagementListsSearchEnabled ? (
            <EquipmentPicker
              placeholder={t('All Equipment')}
              value={localEquipmentId}
              onSelect={handleEquipmentChange}
              position={Position.BOTTOM_END}
              className="mb-5"
            />
          ) : null}
          <EquipmentCategoryPicker
            placeholder={t('All Categories')}
            value={localCategoryId}
            onSelect={handleCategoryChange}
            position={Position.BOTTOM_END}
            className="mb-5"
          />
        </div>
        <div className="pt-5">
          <Button
            type={didChange() ? 'secondary' : 'primary'}
            disabled={didChange()}
            fullWidth={true}
            className={didChange() ? undefined : 'ac-pulse'}
            onClick={handleApply}
          >
            {t('Apply')}
          </Button>
        </div>
        {(!_.isNil(equipmentId) || !_.isNil(categoryId)) && (
          <div className="pt-2 manage-equipment-filter-reset">
            <Button type="link" onClick={handleReset}>
              {t('Reset')}
            </Button>
          </div>
        )}
      </PanelContent>
    </div>
  );
};

export default ManageEquipmentFilter;
