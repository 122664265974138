import { Justify, Row, Theme, Toast } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import _ from 'lodash';
import { Component, ReactNode } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import { logError } from 'utils/testUtils';
import { EmptyState, Spinner } from '../../..';
import './TableLoader.scss';

export interface ITableLoaderProps<T extends { id: string; cursor?: string }> {
  data: T[];
  sectionSize: number | null;
  loadedAll: boolean;
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void;
  offset: number; // has default
  scroller: HTMLElement | 'self';
  loadingTemplate?: ReactNode;
  emptyTemplate?: ReactNode;
  style?: object;
  flex?: boolean;
  className?: ClassName;
  didRender?: () => void;
  willLoad?: () => void;
  render: (data: T[], scroller?: HTMLElement) => ReactNode;
  forwardRef?: (ref: HTMLDivElement) => void;
  refreshIntervalMillis?: number;
}

interface ITableLoaderState {
  loading: boolean;
  errorMessage: string;
}

class TableLoader<T extends { id: string; cursor?: string }> extends Component<
  ITableLoaderProps<T>,
  ITableLoaderState
> {
  public static defaultProps: any;
  public scroller?: HTMLDivElement;
  public state: ITableLoaderState = {
    errorMessage: '',
    loading: false,
  };
  public intervalReference: NodeJS.Timeout | null = null;
  private firstRender = true;

  // Mount
  public componentDidMount() {
    this.getScroller()!.addEventListener('scroll', this.onScroll);

    if (this.shouldLoadMore()) {
      this.loadMoreFromNetwork();
    }

    const { refreshIntervalMillis } = this.props;
    if (refreshIntervalMillis) {
      this.intervalReference = setInterval(() => this.refreshLoadedData(), refreshIntervalMillis);
    }
  }

  public getScroller() {
    return this.props.scroller === 'self' ? this.scroller : this.props.scroller;
  }

  // Unmount
  public componentWillUnmount() {
    this.getScroller()!.removeEventListener('scroll', this.onScroll);

    if (this.intervalReference) {
      clearInterval(this.intervalReference);
      this.intervalReference = null;
    }
  }

  // Update
  public componentDidUpdate(prevProps: ITableLoaderProps<T>) {
    const { data, loadedAll, sectionSize, scroller } = this.props;

    if (prevProps.scroller !== scroller) {
      if (prevProps.scroller === 'self') {
        this.scroller?.removeEventListener('scroll', this.onScroll);
      } else {
        prevProps.scroller.removeEventListener('scroll', this.onScroll);
      }

      this.getScroller()!.addEventListener('scroll', this.onScroll);
    }

    if (!loadedAll && data.length === 0 && !this.state.loading && !this.firstRender) {
      this.loadMoreFromNetwork();
      return;
    }

    // New data was just loaded
    if (prevProps.data !== data) {
      this.setState(
        {
          loading: false,
        },
        () => {
          if (this.shouldLoadMore()) {
            this.loadMoreFromNetwork();
            this.firstRender = false;
            return;
          }
        }
      );
    }

    // Section size changes for printing and selecting all
    if (
      (!loadedAll && prevProps.sectionSize !== null && sectionSize === null) ||
      (prevProps.sectionSize === null && sectionSize !== null) ||
      (prevProps.sectionSize !== null && sectionSize !== null && prevProps.sectionSize < sectionSize)
    ) {
      this.loadMoreFromNetwork();
    }
    this.firstRender = false;
  }

  // Check if we should ask for more results from the server
  public shouldLoadMore() {
    return !this.state.loading && !this.props.loadedAll && this.reachedOffset();
  }

  // content already loaded may no longer show up in the refreshed data, and new content may be added
  // therefore we will just reload up to the same amount of data that is currently showing
  public async refreshLoadedData() {
    const { getData, data, didLoad, sectionSize } = this.props;

    let cursor: string | undefined;
    let loadedAllData = false;
    const updatedData = Array<T>();

    do {
      const result = await getData(cursor);
      cursor = _.last(result)?.cursor;
      updatedData.push(...result);

      // if we got back less results than our page size, then we are done syncing
      loadedAllData = result.length < sectionSize!;
    } while (!loadedAllData && updatedData.length < data.length);

    didLoad(
      _.uniqBy(updatedData, (item) => item.id),
      false,
      loadedAllData
    );
  }

  // Fetch the next section of data
  public loadMoreFromNetwork() {
    const { didLoad, getData, data, willLoad, sectionSize } = this.props;
    this.setState({ loading: true }, () => {
      if (willLoad) {
        willLoad();
      }

      const cursor = this.getLastCursor(data);
      getData(cursor)
        .then((results) => {
          if (results) {
            if (results.length === sectionSize) {
              // Success, not finished
              didLoad(this.patchWithCursors([...data], [...results]), false, false);
            } else if (results.length > 0) {
              // Success, the last results have been loaded
              didLoad(this.patchWithCursors([...data], [...results]), false, true);
            } else {
              // Success, there were no additional results
              didLoad([...data], false, true);
            }
          } else {
            // Fail
            this.setState({ errorMessage: t('Could not load results') });
            didLoad([...data], true, false);
          }
        })
        .catch((err) => {
          logError(err);
          this.setState({ errorMessage: t('Could not load results') });
          didLoad([...data], true, true);
        });
    });
  }

  // Handle mouse scroll
  public onScroll = () => {
    if (this.shouldLoadMore()) {
      this.loadMoreFromNetwork();
    }
  };

  // Check if the user scrolled close enough to the bottom of the page that we
  // need to make another fetch call
  public reachedOffset() {
    const scroller = this.getScroller()!;
    return scroller.scrollTop + this.props.offset >= scroller.scrollHeight - scroller.offsetHeight;
  }

  // Find the last item in the data set that has a cursor
  public getLastCursor(items: T[]) {
    const lastItemWithCursor = [...items].reverse().find((item: T) => {
      return typeof item.cursor === 'string';
    });
    return lastItemWithCursor ? lastItemWithCursor.cursor : undefined;
  }

  // Remove duplicates from the existing data.
  // Items that are created do not have cursors and can re-appear in new fetch results.
  // When we fetch new results, we use the newer data to replace any older duplicates tha// t are already in the table.
  public patchWithCursors(existingArr: T[], newQueryResults: T[]) {
    const newArray = _.map(existingArr, (existing, index) => {
      let match;
      if (!existing.cursor) {
        const matchIndex = newQueryResults.findIndex((result) => {
          return result.id === existing.id;
        });

        if (matchIndex > -1) {
          match = newQueryResults.splice(matchIndex, 1)[0];
        }
      }

      return match ? match : existing;
    });

    return [...newArray, ...newQueryResults];
  }

  // Return the loading template
  public renderLoadingTemplate() {
    return (
      <>
        {this.props.loadingTemplate || (
          <Row justify={Justify.CENTER} className="py-5 no-print">
            <Spinner />
          </Row>
        )}
      </>
    );
  }

  // Render empty state for the table
  public renderEmptyStateTemplate() {
    return (
      <>
        {this.props.emptyTemplate ? (
          this.props.emptyTemplate
        ) : (
          <EmptyState title={t('No items')} subtitle={t('There were no items found')} />
        )}
      </>
    );
  }

  // Close the error toast
  public closeErrorMessage = () => {
    this.setState({ errorMessage: '' });
  };

  // Set the ref
  public setRefs = (el: HTMLDivElement) => {
    if (this.props.scroller === 'self') {
      this.scroller = el;
      if (this.props.forwardRef) {
        this.props.forwardRef(el);
      }
    }
  };

  // Render
  public render() {
    const { className, style, render, data, loadedAll, flex } = this.props;

    const classes = classNames(
      {
        'table-loader': true,
        'table-loader-flex': flex,
      },
      className
    );

    return (
      <>
        <div ref={this.setRefs} style={style} className={classes}>
          {!loadedAll && data.length === 0 && <></>}
          {loadedAll && data.length === 0 && this.renderEmptyStateTemplate()}
          {data.length > 0 && render(data, this.getScroller())}
          {!loadedAll && this.renderLoadingTemplate()}
        </div>
        <Toast isOpen={this.state.errorMessage.length !== 0} onClose={this.closeErrorMessage} theme={Theme.DANGER}>
          {this.state.errorMessage}
        </Toast>
      </>
    );
  }
}

TableLoader.defaultProps = {
  data: [],
  offset: 100,
  scroller: 'self',
};

export default TableLoader;
