import { Button, Form, IFormRender, IFormValidation, ITotalFieldPayload, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import FeatureTimeRangeFormField from 'components/domain/time/FeatureTimeRangeFormField/FeatureTimeRangeFormField';
import TotalTimeField from 'components/foundation/TotalTimeField';
import AdjustingDateRangeFormField from 'components/foundation/form-fields/AdjustingDateRangeFormField/AdjustingDateRangeFormField';
import { useBreakDefaultRange } from 'hooks';
import _, { isNull } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import {
  combineDateWithTime,
  getTotalMillisFromTimeRangeDateTimeType,
  nightShiftAdjustment,
  setZone,
} from 'utils/dateUtils';
import { t } from 'utils/localize';
import { v_require, v_start_time_before_end_time, v_times_outside_range } from 'utils/validations';
import v_time_range_after_time from 'utils/validations/v_time_range_after_time';
import v_time_range_before_time from 'utils/validations/v_time_range_before_time';
import v_times_in_range from 'utils/validations/v_times_in_range';
import './BreakForm.scss';

export * from './BreakFormTypes';

function startBeforeEndAdjusted(dateRange: ITimeRange<DateTime>) {
  return (value: ITimeRange<DateTime | null | undefined> | null) => {
    if (value && value.startTime && value.endTime) {
      const startTime = combineDateWithTime(dateRange.startTime, value.startTime);
      const endTime = combineDateWithTime(dateRange.endTime, value.endTime);
      return v_start_time_before_end_time({ startTime, endTime });
    } else {
      return {
        message: t('Start time must be before end time.'),
        validate: () => true,
      };
    }
  };
}

function timesInRange(dateRange: ITimeRange<DateTime>, timeEntryStart: DateTime, timeEntryEnd: DateTime) {
  return (value: ITimeRange<DateTime | null | undefined> | null) => {
    if (value && value.startTime && value.endTime) {
      const startTime = combineDateWithTime(dateRange.startTime, value.startTime);
      const endTime = combineDateWithTime(dateRange.endTime, value.endTime);
      return v_times_in_range({ startTime, endTime }, { rangeStart: timeEntryStart, rangeEnd: timeEntryEnd });
    } else {
      return {
        message: `${t('Both start time and end time must be in range')} ${dateRange.startTime.toFormat(
          't'
        )} - ${dateRange.endTime.toFormat('t')}.`,
        validate: () => true,
      };
    }
  };
}
export interface IBreakFormProps {
  timeEntryStart: DateTime;
  timeEntryEnd: DateTime;
  timeFormat: string;
  hideDateOnBreakForm?: boolean;
  editingBreak: IBreakMultiPickerItem | null;
  breaks: IBreakMultiPickerItem[];
  onSubmit: (breakRange: Pick<IBreakFormData, 'timeRange'>) => void;
  onDelete: () => void;
  className?: ClassName;
}

export interface IBreakFormData {
  dateRange: ITimeRange<DateTime>;
  timeRange: ITimeRange<DateTime | null | undefined>;
  startDst?: boolean | null;
  endDst?: boolean | null;
}

function useBreakFormValidation(
  breaks: IBreakMultiPickerItem[],
  editingBreak: IBreakMultiPickerItem | null,
  timeEntryStart?: DateTime | null,
  timeEntryEnd?: DateTime | null
) {
  const getInTimeEntryRangeValidation = useCallback((): IFormValidation<any, any> | null => {
    if (timeEntryStart && timeEntryEnd) {
      return {
        validation: v_times_in_range,
        args: {
          rangeStart: timeEntryStart,
          rangeEnd: timeEntryEnd,
          valueDay: timeEntryStart,
        },
      };
    } else if (timeEntryStart) {
      return {
        validation: v_time_range_after_time,
        args: {
          time: timeEntryStart,
          inclusive: true,
        },
      };
    } else if (timeEntryEnd) {
      return {
        validation: v_time_range_before_time,
        args: {
          time: timeEntryEnd,
          inclusive: true,
        },
      };
    } else {
      return null;
    }
  }, [timeEntryEnd, timeEntryStart]);

  const today = DateTime.local().startOf('day');

  return useMemo(() => {
    const validations: Array<IFormValidation<any, any>> = [
      { validation: v_require },
      ...breaks
        .filter((brk) => editingBreak?.id !== brk.id)
        .map((brk) => {
          const nightShifted = nightShiftAdjustment(brk.timeRange);

          return {
            validation: v_times_outside_range,
            args: {
              message: t('Range overlaps with existing breaks.'),
              rangeStart: nightShifted.startTime,
              rangeEnd: nightShifted.endTime,
              startExclusive: false,
              endExclusive: false,
              valueDay: timeEntryStart ?? today,
            },
          };
        }),
    ];

    const rangeValidation = getInTimeEntryRangeValidation();

    if (rangeValidation) {
      return [rangeValidation, ...validations] as Array<IFormValidation<any, any>>;
    }

    return validations;
  }, [breaks, editingBreak, getInTimeEntryRangeValidation, timeEntryStart, today]);
}

const BreakForm = ({
  timeEntryStart,
  timeEntryEnd,
  hideDateOnBreakForm,
  editingBreak,
  onDelete,
  onSubmit,
  timeFormat,
  breaks,
  className,
}: IBreakFormProps) => {
  const [t] = useTranslation();
  const isBoundedByMultidayEntry = !timeEntryStart.hasSame(timeEntryEnd, 'day');
  const breakRange = useBreakDefaultRange();
  const newTimeEntryEnd = (): DateTime => {
    if (dateUtils.isSameDay(timeEntryStart, timeEntryEnd)) {
      return setZone(timeEntryEnd, timeEntryStart.zoneName, false);
    }
    return combineDateWithTime(timeEntryStart, timeEntryStart.endOf('day'));
  };
  const startingFormData = useRef<IBreakFormData>({
    dateRange: editingBreak
      ? {
          startTime: editingBreak.timeRange.startTime,
          endTime: editingBreak.timeRange.endTime,
        }
      : {
          startTime: timeEntryStart,
          endTime: newTimeEntryEnd(),
        },
    timeRange: editingBreak
      ? { ...editingBreak.timeRange }
      : {
          ...breakRange({
            startTime: timeEntryStart,
            endTime: newTimeEntryEnd(),
          }),
        },
    startDst: editingBreak?.startDst,
    endDst: editingBreak?.endDst,
  });

  const [total, setTotal] = useState<number | null>(parseRangeForTotal(startingFormData.current.timeRange));

  function parseRangeForTotal(timeRange: ITimeRange<DateTime | null | undefined> | null | undefined): number | null {
    if (timeRange && timeRange.startTime && timeRange.endTime) {
      const localizedTimeRange = {
        startTime: timeRange.startTime,
        endTime: setZone(setZone(timeRange.endTime, timeRange.startTime.zoneName, true), timeRange.endTime.zoneName),
      }; // for total calculation, we need to make sure the timezones are the same

      const millis = getTotalMillisFromTimeRangeDateTimeType(localizedTimeRange, true);
      if (millis !== null && _.isNumber(millis) && timeRange.startTime) {
        return millis / 1000;
      }
      return null;
    } else {
      return null;
    }
  }

  const validations = useBreakFormValidation(breaks, editingBreak, timeEntryStart, timeEntryEnd);

  function renderFormFields({ ...form }: IFormRender<IBreakFormData>) {
    function onTotalChange(payload?: ITotalFieldPayload) {
      const startTime = form.state.data.timeRange.startTime;
      if (payload && payload !== null && payload.value && startTime) {
        const adjustedEnd = startTime.plus(Duration.fromMillis(payload.value * 1000));
        form.setData('timeRange', { startTime: startTime, endTime: adjustedEnd });
        setTotal(payload.value);
      }
    }

    function onRangeChange(changedRange: ITimeRange<DateTime | null | undefined> | null) {
      if (changedRange?.startTime && changedRange.endTime) {
        const startTime = combineDateWithTime(form.state.data.dateRange.startTime, changedRange.startTime);
        const endTime = combineDateWithTime(form.state.data.dateRange.endTime, changedRange.endTime);

        const seconds = endTime.toSeconds() - startTime.toSeconds();
        if (seconds !== null && _.isNumber(seconds)) {
          setTotal(seconds !== 0 ? seconds : 0);
        }
      } else {
        setTotal(null);
      }
    }

    function onDateRangeChange(dateRange: ITimeRange<DateTime | null | undefined> | null) {
      const formStart = form.state.data.timeRange.startTime;
      const formEnd = form.state.data.timeRange.endTime;
      if (dateRange && dateRange.startTime && dateRange.endTime && formStart && formEnd) {
        const startTime = combineDateWithTime(dateRange.startTime, formStart);
        const endTime = combineDateWithTime(dateRange.endTime, formEnd);
        setTotal(endTime.toSeconds() - startTime.toSeconds());
      } else {
        setTotal(null);
      }
    }

    return (
      <div>
        {isBoundedByMultidayEntry && !hideDateOnBreakForm && (
          <AdjustingDateRangeFormField
            name="dateRange"
            form={form}
            disabledAfter={timeEntryEnd.startOf('day')}
            disabledBefore={timeEntryStart.startOf('day')}
            showCalendarIcon={true}
            onChange={onDateRangeChange}
            startHeader={<Label>{t('Start Date')}</Label>}
            endHeader={<Label>{t('End Date')}</Label>}
            showDashSeparator={false}
            validations={[{ validation: v_require }]}
          />
        )}
        <Label
          secondaryLabel={`${t('Must be between')} ${timeEntryStart.toFormat(timeFormat)} - ${timeEntryEnd.toFormat(
            timeFormat
          )}`}
        >
          {t('Time')}
        </Label>
        <FeatureTimeRangeFormField
          name="timeRange"
          form={form}
          onChange={onRangeChange}
          startDst={!isNull(form.state.data.startDst) ? form.state.data.startDst : undefined}
          endDst={form.state.data.endDst}
          validations={[
            { validation: timesInRange(form.state.data.dateRange, timeEntryStart, timeEntryEnd) },
            { validation: v_require },
            { validation: startBeforeEndAdjusted(form.state.data.dateRange) },
            ...breaks
              .filter((brk) => editingBreak?.id !== brk.id)
              .map((brk) => {
                const nightShifted = nightShiftAdjustment(brk.timeRange);

                return {
                  validation: v_times_outside_range,
                  args: {
                    message: t('Range overlaps with existing breaks.'),
                    rangeStart: nightShifted.startTime,
                    rangeEnd: nightShifted.endTime,
                    startExclusive: false,
                    endExclusive: false,
                    valueDay: timeEntryStart,
                  },
                };
              }),
          ]}
        />

        <Label>{t('Total')}</Label>

        {total && <TotalTimeField value={total} onChange={onTotalChange} maxHours={23} />}

        <div className="buttons-container">
          <Button type="primary" onClick={form.handleSubmit}>
            {editingBreak ? t('Edit') : t('Save')}
          </Button>

          {editingBreak && !editingBreak.isOpen && (
            <Button type="secondary" onClick={onDelete}>
              {t('Delete')}
            </Button>
          )}
        </div>
      </div>
    );
  }

  function onFormSubmit({ dateRange, timeRange }: IBreakFormData) {
    const startTime = combineDateWithTime(dateRange.startTime, timeRange.startTime!);
    const endTime = combineDateWithTime(dateRange.endTime, timeRange.endTime!);
    onSubmit({ timeRange: { startTime, endTime } });
  }

  return (
    <Form
      className={classNames('break-form', className)}
      data={startingFormData.current}
      onSubmit={onFormSubmit}
      render={renderFormFields}
    />
  );
};

export default BreakForm;

BreakForm.defaultProps = {
  timeFormat: 't',
};
