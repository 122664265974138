import { QueryResult } from '@apollo/client';
import { Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { GroupPicker } from 'components';
import _ from 'lodash';
import { Component } from 'react';
import { ClassName } from 'types/ClassName';
import { costCodeGroupQuery, costCodeGroupsQuery } from './cost-code-group-picker-queries';

export interface ICostCodeGroupPickerProps {
  value: string | null;
  onSelect: (costCodeGroupId: string | null) => void;
  minWidth?: string; // has default
  className?: ClassName;
  placeholder?: string;
  position?: Position;
  error?: boolean;
  fetchPolicy?: string;
  searchArchived?: boolean;
  autofocus?: boolean;
  includeNoneOption?: boolean;
}

class CostCodeGroupPicker extends Component<ICostCodeGroupPickerProps> {
  public queryResolver(result: QueryResult) {
    return result.data.costCodeGroups;
  }

  public singleQueryResolver(result: QueryResult) {
    return _.get(result, 'costCodeGroups[0][groupName]');
  }

  // Handle when a value is selected from the list
  public handleSelect = (groupId: string | null) => {
    this.props.onSelect(groupId);
  };

  public render() {
    const { value, className, placeholder, minWidth, position, error, autofocus } = this.props;

    const classes = classNames(
      {
        'cost-code-picker': true,
      },
      className
    );

    return (
      <GroupPicker
        value={value}
        query={costCodeGroupsQuery}
        queryResolver={this.queryResolver}
        singleQueryResolver={this.singleQueryResolver}
        onSelect={this.handleSelect}
        singleQuery={costCodeGroupQuery}
        minWidth={minWidth}
        position={position}
        error={error}
        placeholder={placeholder}
        className={classes}
        fetchPolicy={this.props.fetchPolicy}
        autofocus={autofocus}
        includeNoneOption={this.props.includeNoneOption}
      />
    );
  }
}

export default CostCodeGroupPicker;
