import { Loader } from '@busybusy/webapp-react-ui';
import equipment_pin from 'assets/icons/equipment_pin.svg';
import { equipmentPinDrop, equipmentPlaceholder } from 'assets/images/map-pins';
import classNames from 'classnames';
import { GoogleMap } from 'components';
import { useToastOpen } from 'contexts/ToastContext';
import gql from 'graphql-tag';
import { useApolloPaging, useOpenable, useReduxSelector } from 'hooks';
import useEquipmentDashboardNavigation from 'hooks/navigation/useEquipmentDashboardNavigation';
import 'jimp';
import { compact, isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigationType } from 'react-router';
import { clearManageModelMapsSelectedPin, setManageModelMapsSelectedPin } from 'store/manageModelMaps/ManageModelMaps';
import { ClassName } from 'types/ClassName';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import ILocation, { IClusterGroup } from 'types/Location';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { shiftPins } from 'utils/locationUtils';
import { getEquipmentSearch } from '../ManageEquipment';
import './ManageEquipmentMap.scss';
import ManageEquipmentMapInfoWindow, {
  MapEquipmentMapActionClasses,
} from './ManageEquipmentMapInfoWindow/ManageEquipmentMapInfoWindow';

export interface IManageEquipmentMapProps {
  className?: ClassName;
  onDetailsClick: (equipment: IEquipment) => void;
  onDirectionsClick: (equipment: IEquipment) => void;
  equipmentId?: string | null;
  categoryId?: string | null;
  search?: string | null;
  updatedEquipmentId?: string;
}

const ManageEquipmentMap = (props: IManageEquipmentMapProps) => {
  const { className, onDetailsClick, onDirectionsClick, equipmentId, categoryId, updatedEquipmentId, search } = props;

  const loaderDetails = useOpenable();
  const toast = useToastOpen();
  const { getAll } = useApolloPaging();
  const [pins, setPins] = useState<ILocation[]>([]);
  const mapOptions = useRef<google.maps.MapOptions>({
    center: { lat: 39, lng: -95 },
    gestureHandling: 'greedy',
    zoom: 6,
    maxZoom: 22,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  });
  const equipmentClusterGroup = useRef<IClusterGroup>({
    id: 'project',
    icon: equipment_pin,
  });
  const equipmentNavigation = useEquipmentDashboardNavigation();
  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  const routeConsidered = useRef(false);
  const selectedLocationId = useReduxSelector((state) => state.manageModelMaps.locationId, Object.is);
  const isManagementListsSearchEnabled = useFeatureFlags('MANAGEMENT_LISTS_SEARCH');

  const { Jimp } = window as Window &
    typeof globalThis & {
      Jimp: typeof import('jimp');
    };

  if (!routeConsidered.current && navigationType !== 'POP') {
    routeConsidered.current = true;
    dispatch(clearManageModelMapsSelectedPin());
  }

  useEffect(() => {
    getEquipment();
  }, [equipmentId, categoryId, updatedEquipmentId, search]);

  async function getEquipment() {
    if (isNil(selectedLocationId)) {
      loaderDetails.open();
    }

    const equipment = await getAll<IEquipment & ICursorable>('equipment', {
      query: MANAGE_EQUIPMENT_MAP_QUERY,
      variables: {
        first: 500,
        filter: {
          deletedOn: { isNull: true },
          model: {
            category: {
              id: categoryId ? { equal: categoryId } : undefined,
            },
          },
          id: equipmentId ? { equal: equipmentId } : undefined,
          search: isManagementListsSearchEnabled ? getEquipmentSearch(search ?? '') : undefined,
        },
      },
      fetchPolicy: 'network-only',
    });

    const promisePins = equipment.map(async (e) => {
      if (e.lastLocation?.latitude && e.lastLocation?.longitude) {
        let equipmentPinIcon = equipmentPinDrop;

        let equipmentImage = '';

        const imageToComposite = e.imageUrl ? e.imageUrl : equipmentPlaceholder;

        if (imageToComposite) {
          await Jimp.read(imageToComposite).then((image) => {
            const isLandscape = image.getHeight() < image.getWidth();
            image
              .resize(isLandscape ? Jimp.AUTO : 40, isLandscape ? 40 : Jimp.AUTO)
              .crop(isLandscape ? 10 : 0, 0, 40, 40)
              .circle()
              .getBase64(Jimp.MIME_PNG, (err, img) => {
                equipmentImage = img;
              });
          });

          const equipmentImg = await Jimp.read(equipmentImage);

          await Jimp.read(equipmentPinDrop).then((pin) => {
            pin.composite(equipmentImg, 4, 4).getBase64(Jimp.MIME_PNG, (err, img) => {
              equipmentPinIcon = img;
            });
          });
        }
        const equipmentPin: ILocation = {
          id: e.id,
          lat: e.lastLocation.latitude,
          lng: e.lastLocation.longitude,
          icon: equipmentPinIcon,
          clusterGroup: equipmentClusterGroup.current,
          infoWindowBody: getEquipmentInfoWindow(e),
          actions: getEquipmentActions(e),
        };
        return equipmentPin;
      }
    });

    const shiftedPins = shiftPins(compact(await Promise.all(promisePins)));

    setPins(shiftedPins);
    if (isEmpty(shiftedPins)) {
      toast({
        label: t('No geolocation set.'),
      });
    }
    loaderDetails.close();
  }

  function getEquipmentInfoWindow(equipment: IEquipment) {
    return <ManageEquipmentMapInfoWindow equipment={equipment} locationId={equipment.id} />;
  }

  const getEquipmentActions = useCallback((equipment: IEquipment) => {
    return [
      {
        className: MapEquipmentMapActionClasses.EQUIPMENT_DETAILS + '-' + equipment.id,
        action: () => equipmentNavigation({ equipment_id: equipment.id }),
      },
      {
        className: MapEquipmentMapActionClasses.MANAGE_EQUIPMENT + '-' + equipment.id,
        action: () => onDetailsClick(equipment),
      },
      {
        className: MapEquipmentMapActionClasses.DIRECTIONS + '-' + equipment.id,
        action: () => onDirectionsClick(equipment),
      },
    ];
  }, []);

  function handlePinClick(location: ILocation) {
    getEquipment();
    dispatch(setManageModelMapsSelectedPin(location.id));
  }

  function handleWindowClose() {
    getEquipment();
    dispatch(clearManageModelMapsSelectedPin());
  }

  const classes = classNames('manage-equipment-map', className);

  return (
    <>
      <GoogleMap
        className={classes}
        mapId={'manage-equipment-map'}
        pinLocations={pins}
        options={mapOptions.current}
        selectedId={selectedLocationId}
        onPinClick={handlePinClick}
        onWindowClose={handleWindowClose}
        shouldZoomToMarker={!isNil(categoryId) ? true : false}
      />
      <Loader isOpen={loaderDetails.isOpen} />
    </>
  );
};

export const MANAGE_EQUIPMENT_MAP_QUERY = gql`
  query manageEquipmentMapQuery($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      imageUrl
      importTtlSeconds
      importType
      lastHoursId
      lastLocationId
      lastLocation {
        id
        latitude
        longitude
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          imageUrl
          review
          submittedOn
          title
          updatedOn
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        imageUrl
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
      lastHours {
        id
        runningHours
      }
    }
  }
`;

export default ManageEquipmentMap;
