import useNavigation from './useNavigation';

interface IParams {
  exitToLegacy?: boolean;
  employee_id: string;
}

export default function useEmployeeDashboardNavigation() {
  return useNavigation<IParams>('/employee-dashboard');
}
