import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { timeInRange } from 'utils/dateUtils';
import { t } from 'utils/localize';

export default function v_times_in_range(
  value: ITimeRange<DateTime | undefined | null> | null,
  args: {
    rangeStart: DateTime;
    rangeEnd: DateTime;
    endExclusive?: boolean;
    message?: string;
  }
) {
  return {
    message:
      args?.message ||
      `${t('Both start time and end time must be in range')} ${args.rangeStart.toFormat(
        't'
      )} - ${args.rangeEnd.toFormat('t')}.`,
    validate: () => {
      if (!value || !value.startTime || !value.endTime) {
        return false;
      } else {
        return (
          timeInRange(
            value.startTime,
            { startTime: args.rangeStart, endTime: args.rangeEnd },
            true,
            !args.endExclusive
          ) &&
          timeInRange(value.endTime, { startTime: args.rangeStart, endTime: args.rangeEnd }, true, !args.endExclusive)
        );
      }
    },
  };
}
