import { ITableColumn } from '@busybusy/webapp-react-ui';
import { t } from 'utils/localize';
import { getCreatedOnTableCell, getUpdatedOnTableCell } from 'utils/uiUtils';
import { IManageEquipmentRowInfo } from './ManageEquipment';

export const columns = [
  {
    key: 'name',
    sort: true,
    title: t('Name'),
    visibleByDefault: true,
  },
  {
    key: 'make',
    sort: true,
    title: t('Make'),
    visibleByDefault: true,
  },
  {
    key: 'model',
    sort: true,
    title: t('Model'),
    visibleByDefault: true,
  },
  {
    key: 'year',
    sort: true,
    title: t('Year'),
    visibleByDefault: true,
  },
  {
    key: 'category',
    sort: true,
    title: t('Category'),
    visibleByDefault: true,
  },
  {
    key: 'costHistory',
    sort: true,
    title: t('Hourly Rate'),
    visibleByDefault: true,
  },
  {
    key: 'hourMeter',
    sort: true,
    title: t('Hour Meter'),
    visibleByDefault: true,
  },
  {
    key: 'type',
    sort: true,
    title: t('Type'),
    visibleByDefault: false,
  },
  getCreatedOnTableCell(),
  getUpdatedOnTableCell(),
];

export const defaultVisibleColumns = columns.map((column: ITableColumn<IManageEquipmentRowInfo>, index) => {
  return { key: column.key, visible: column.visibleByDefault, title: column.title, position: index };
});
