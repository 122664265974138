import { Dictionary, cloneDeep, isEqual, isNil, isObject, reduce } from 'lodash';
import { AnyObject } from 'types/util/Object';
import { ObjectEntries } from 'types/util/ObjectEntries';
import { Optional } from 'types/util/Optional';

/**
 * Use instead of Object.keys(), which returns string[]
 * typedObjectKeys(myHash) returns Array<keyof myHash>
 *
 * @returns Array<keyof T>
 */
export function typedObjectKeys<T extends AnyObject>(typedObject: T): Array<keyof T> {
  return Object.keys(typedObject) as Array<keyof T>;
}

export function typedObjectValues<T extends AnyObject>(typedObject: T): Array<T[keyof T]> {
  return Object.values(typedObject) as Array<T[keyof T]>;
}

export function typedObjectEntries<T extends AnyObject>(obj: T): ObjectEntries<T> {
  return Object.entries(obj) as ObjectEntries<T>;
}

export function assignIfDefined<T>(dictionary: { [key: string]: T | null }, key: string, value: T | null | undefined) {
  if (value !== undefined) {
    dictionary[key] = value;
  }
}

export function compactObject<T extends AnyObject>(o: T) {
  return reduce(
    o,
    (acc, cur, key) => {
      if (!isNil(cur)) {
        return { ...acc, [key]: cur };
      }
      return acc;
    },
    {}
  );
}

export function compactObjectUndefined<T extends AnyObject>(o: T) {
  return reduce(
    o,
    (acc, cur, key) => {
      if (cur !== undefined) {
        return { ...acc, [key]: cur };
      }
      return acc;
    },
    {}
  );
}

export function anyFieldSetShallow<T extends AnyObject>(o: T) {
  return Object.keys(compactObject(o)).length > 0;
}

export function anyFieldSetDeep<T extends AnyObject>(o: T): boolean {
  return typedObjectKeys(o).some((key) => {
    const value = o[key];

    if (!isObject(value)) {
      return !isNil(value);
    }

    return anyFieldSetDeep(value);
  });
}

export function setListInDictionary<T>(dict: Dictionary<T[]>, key: string, value: T) {
  if (dict[key]) {
    dict[key].push(value);
  } else {
    dict[key] = [value];
  }

  return dict;
}

export function fieldIsSet<T extends AnyObject, K extends keyof T>(obj: T, key: K) {
  return !isNil(obj[key]);
}

export function fieldIsTruthy<T extends AnyObject, K extends keyof T>(obj: T, key: K) {
  return !!obj[key];
}

export function fieldsAreTruthy<T extends AnyObject, K extends keyof T>(obj: T, ...keys: K[]) {
  return keys.every((key) => fieldIsTruthy(obj, key));
}

export function anyFieldIsTruthy<T extends AnyObject, K extends keyof T>(obj: T, ...keys: K[]) {
  return keys.some((key) => fieldIsTruthy(obj, key));
}

export function getDifferentObjectKeysByValue<T extends AnyObject>(
  first: T,
  second: T,
  equalityFn: (first: any, second: any) => boolean = isEqual
): Array<keyof T> {
  return typedObjectKeys(first).reduce<Array<keyof T>>((acc, cur) => {
    if (!equalityFn(first[cur], second[cur])) {
      acc.push(cur);
    }

    return acc;
  }, []);
}

export function getUnsetObjectKeys<T extends AnyObject>(object: T) {
  return getUnsetObjectKeysFromGivenKeys(object, typedObjectKeys(object));
}

export function getUnsetObjectKeysFromGivenKeys<T>(object: T, keys: Array<keyof T>) {
  return keys.filter((key) => !object[key]);
}

export function setObjectKey<T extends AnyObject, K extends keyof T>(object: T, key: K, value: T[K]): T {
  return {
    ...object,
    [key]: value,
  };
}

export function setObjectKeyCloned<T extends AnyObject, K extends keyof T>(object: T, key: K, value: T[K]): T {
  return {
    ...cloneDeep(object),
    [key]: value,
  };
}

/**
 * For type integrity this requires the given record to be 1:1, if it is not the record cannot be reversed
 */
export function reverseRecord<T extends string | number | symbol, U extends string | number | symbol>(
  record: Record<T, U>
): Record<U, T> {
  return typedObjectKeys(record).reduce(
    (acc, cur) => {
      const currentValue = record[cur];
      return {
        ...acc,
        [currentValue]: cur,
      };
    },
    {} as Record<U, T>
  );
}

export function getOrNull<T extends AnyObject>(obj: T, key: Optional<keyof T>) {
  if (!key) {
    return null;
  }

  return obj[key] ?? null;
}

export function curriedKeyedValueSet<T extends AnyObject, K extends keyof T>(obj: T, key: K) {
  return (value: T[K]) => {
    return setObjectKey(obj, key, value);
  };
}

export function isKeyedValueEqual<T extends AnyObject, U extends AnyObject>(
  first: T,
  second: U,
  key: keyof T & keyof U
) {
  return isEqual(first[key], second[key]);
}

export function areKeyedValuesEqual<T extends AnyObject, U extends AnyObject>(
  first: T,
  second: U,
  ...keys: Array<keyof T & keyof U>
) {
  return keys.every((key) => isKeyedValueEqual(first, second, key));
}

export const jsonParseAs = <T>(jsonString: string): T | null => {
  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    return null;
  }
};
