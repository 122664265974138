import { Align, IListItemProps, Justify, ListItem, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import './DraggableItem.scss';

type ListProps = Omit<IListItemProps, 'style' | 'onDragStart' | 'role' | 'disabled' | 'ripple' | 'ref'>;
export interface IDraggableItemProps extends Omit<ListProps, 'className'> {
  id: string;
  index: number;
  children: ReactNode;
  draggingClassName?: string;
  disabled?: boolean;
  className?: ClassName;
  ripple?: boolean; // disable ripple to make interactive child elements intractable
}

const DraggableItem = ({
  id,
  index,
  children,
  className,
  draggingClassName,
  disabled,
  ripple,
  ...other
}: IDraggableItemProps) => (
  <Draggable key={id} draggableId={id} index={index} isDragDisabled={disabled}>
    {(provided, snapshot) => (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        <ListItem
          ripple={ripple}
          {...other}
          className={classNames(
            'draggable-item',
            { dragging: snapshot.isDragging },
            draggingClassName ? { [draggingClassName]: snapshot.isDragging } : '',
            className
          )}
        >
          <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
            {children}
          </Row>
        </ListItem>
      </div>
    )}
  </Draggable>
);

export default DraggableItem;
