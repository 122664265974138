import { Button, ButtonList } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import * as React from 'react';
import { useCallback } from 'react';
import MoreButton, { IMoreButtonProps } from '../MoreButton/MoreButton';

export interface IMenuButtonOptions {
  label: string;
  onSelect: (event: React.MouseEvent) => void;
}

export interface IMenuButtonProps extends Omit<IMoreButtonProps, 'renderContent'> {
  options: IMenuButtonOptions[];
}

const MenuButton = (props: IMenuButtonProps) => {
  const { options, className, ...moreButtonProps } = props;

  const renderOptions = useCallback(
    (close: () => void) => {
      const functionWithClose = (onSelect: (event: React.MouseEvent) => void) => (event: React.MouseEvent) => {
        close();
        onSelect(event);
      };

      return (
        <ButtonList>
          {options.map((option) => (
            <Button key={option.label} onClick={functionWithClose(option.onSelect)}>
              {option.label}
            </Button>
          ))}
        </ButtonList>
      );
    },
    [options]
  );

  const classes = classNames('menu-button', className);

  return <MoreButton renderContent={renderOptions} {...moreButtonProps} className={classes} />;
};

MenuButton.defaultProps = {
  ...MoreButton.defaultProps,
};

export default MenuButton;
