import gql from 'graphql-tag';
import { BASIC_MEMBER_TIME_METRICS_FRAGMENT } from './jit-member-queries';
import { BASIC_PROJECT_LABOR_METRICS_FRAGMENT } from './jit-project-queries';
import { MEMBER_TIME_DOCUMENT_FRAGMENT } from './member-time-document-query';
import { BASIC_SAFETY_SIGNATURES_FRAGMENT } from './safety-signature-query';

export const MEMBER_FRAGMENT = gql`
  fragment MemberDetails on Member {
    id
    firstName
    lastName
    username
    email
    phone
    memberNumber
    archivedOn
    deletedOn
    imageUrl
    positionId
    memberGroupId
    pinCode
    timeLocationRequired
    distantLocationFlaggingDisabled
  }
`;

export const MEMBERS_QUERY = gql`
  query QueryMembers($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberNumber
      archivedOn
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
    }
  }
`;

export const MEMBERS_QUERY_WITH_SORT = gql`
  query QueryMembersWithSort($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      archivedOn
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
    }
  }
`;

export const MEMBERS_WITH_ALL_JOINED_DATA_QUERY = gql`
  query QueryMembersForTimeMetericsWithMore(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $safetySignaturesFilter: SafetySignatureFilter!
    $safetySignaturesSort: [SafetySignatureSort!]
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      archivedOn
      memberNumber
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        openBreak {
          id
        }
      }
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicMemberLaborMetrics
      }
      memberTimeDocuments(filter: $memberTimeDocumentFilter, sort: $memberTimeDocumentSort) {
        ...MemberTimeDocumentFragment
      }
      safetySignatures(filter: $safetySignaturesFilter, sort: $safetySignaturesSort) {
        ...BasicSafetySignatureFragment
      }
    }
  }
  ${BASIC_MEMBER_TIME_METRICS_FRAGMENT}
  ${MEMBER_TIME_DOCUMENT_FRAGMENT}
  ${BASIC_SAFETY_SIGNATURES_FRAGMENT}
`;

export const MEMBERS_QUERY_WITH_OPEN_ENTRY = gql`
  query queryMembersWithOpenEntry($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
      }
      position {
        id
        level
      }
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        memberId
        openBreak {
          id
          startTime
          startTimeDst
        }
        project {
          id
          title
          depth
          projectInfo {
            id
            number
            customer
          }
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
            projectInfo {
              id
              number
            }
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        endTime
        daylightSavingTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
          startLocation {
            id
          }
          endLocation {
            id
          }
        }
        hasLocation
        startLocation {
          id
        }
        endLocation {
          id
        }
        clientLogs {
          id
        }
      }
    }
  }
`;

export const MEMBERS_WITH_OPEN_ENTRY_COUNT_QUERY = gql`
  query queryMembersWithOpenEntryCount($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      firstName
      lastName
      timeEntries(filter: { endTime: { isNull: true }, deletedOn: { isNull: true } }) {
        id
        openBreak {
          id
        }
      }
    }
  }
`;

export const MEMBERS_WITH_TIME_DOCUMENTS_QUERY = gql`
  query QueryMembersWithTimeDocuments(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $memberTimeDocumentFilter: MemberTimeDocumentFilter!
    $memberTimeDocumentSort: [MemberTimeDocumentSort!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberNumber
      archivedOn
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      memberTimeDocuments(filter: $memberTimeDocumentFilter, sort: $memberTimeDocumentSort) {
        ...MemberTimeDocumentFragment
      }
      position {
        id
        level
      }
    }
  }
  ${MEMBER_TIME_DOCUMENT_FRAGMENT}
`;

export const MEMBER_QUERY = gql`
  query queryMember($memberId: Uuid!) {
    members(filter: { id: { equal: $memberId } }) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
      }
      position {
        id
        level
      }
    }
  }
`;

export const MEMBER_NAME_QUERY = gql`
  query queryMemberName($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      firstName
      lastName
      username
    }
  }
`;

export const MEMBER_FLAT_QUERY = gql`
  query queryFlatMemberName($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      __typename
      ...MemberDetails
      cursor
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const MEMBER_ARCHIVED_QUERY = gql`
  query queryMemberArchived($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      archivedOn
    }
  }
`;

export const MEMBER_POSITION_QUERY = gql`
  query queryMemberArchivedWithPosition($first: Int, $after: String, $filter: MemberFilter) {
    members(first: $first, after: $after, filter: $filter) {
      id
      cursor
      position {
        id
        level
        timeEvents
        manageTimeEntries
      }
    }
  }
`;

export const MEMBERS_WITH_PROJECT_WITH_COST_CODE_METRIC_QUERY = gql`
  query QueryMembersWithProjectWithCostCodesMetric(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberLock {
        id
        effectiveDate
        updaterMemberId
        memberId
        createdOn
        submittedOn
        updatedOn
      }
      position {
        id
        level
      }
      memberProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        costCodeId
        memberId
        projectId
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_USAGE_QUERY = gql`
  query QueryMemberUsage(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $usageStartDate: DateTime!
    $usageEndDate: DateTime!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
      imageUrl
      memberGroupId
      memberNumber
      archivedOn
      memberUsageInfo(start: $usageStartDate, end: $usageEndDate) {
        firstApiAccess
        firstTimeTracking
      }
    }
  }
`;
