import gql from 'graphql-tag';

export const positionsQuery = gql`
  query positionsFormFieldQuery($filter: PositionFilter!) {
    positions(sort: [{ level: asc }], filter: $filter) {
      id
      level
      title
    }
  }
`;
