import { LaborMetricsInterface, MemberLaborMetrics, ProjectLaborMetricsInterface } from '__generated__/graphql';

const laborMetrics: LaborMetricsInterface = {
  breakDt: 0,
  breakDtCents: 0,
  breakOt: 0,
  breakOtCents: 0,
  breakOverburdenCents: 0,
  breakRt: 0,
  breakRtCents: 0,
  breakUpt: 0,
  dt: 0,
  dtCents: 0,
  end: '',
  ot: 0,
  otCents: 0,
  overburdenDtCents: 0,
  overburdenOtCents: 0,
  overburdenRtCents: 0,
  rt: 0,
  rtCents: 0,
  start: '',
};

const memberLaborMetrics: MemberLaborMetrics = {
  breakDt: 0,
  breakDtCents: 0,
  breakOt: 0,
  breakOtCents: 0,
  breakOverburdenCents: 0,
  breakRt: 0,
  breakRtCents: 0,
  breakUpt: 0,
  dt: 0,
  dtCents: 0,
  end: '',
  memberId: '',
  ot: 0,
  otCents: 0,
  overburdenDtCents: 0,
  overburdenOtCents: 0,
  overburdenRtCents: 0,
  pto: 0,
  ptoCents: 0,
  rt: 0,
  rtCents: 0,
  start: '',
  upto: 0,
};

const projectLaborMetrics: ProjectLaborMetricsInterface = {
  breakDt: 0,
  breakDtCents: 0,
  breakOt: 0,
  breakOtCents: 0,
  breakOverburdenCents: 0,
  breakRt: 0,
  breakRtCents: 0,
  breakUpt: 0,
  descBreakDt: 0,
  descBreakDtCents: 0,
  descBreakOt: 0,
  descBreakOtCents: 0,
  descBreakOverburdenCents: 0,
  descBreakRt: 0,
  descBreakRtCents: 0,
  descBreakUpt: 0,
  descDt: 0,
  descDtCents: 0,
  descOt: 0,
  descOtCents: 0,
  descOverburdenDtCents: 0,
  descOverburdenOtCents: 0,
  descOverburdenRtCents: 0,
  descRt: 0,
  descRtCents: 0,
  dt: 0,
  dtCents: 0,
  end: '',
  ot: 0,
  otCents: 0,
  overburdenDtCents: 0,
  overburdenOtCents: 0,
  overburdenRtCents: 0,
  rt: 0,
  rtCents: 0,
  start: '',
};

export const emptyMetrics = {
  laborMetrics,
  memberLaborMetrics,
  projectLaborMetrics,
};
