import { Row, Size } from '@busybusy/webapp-react-ui';
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import BreakForm, { IBreakFormData } from 'components/domain/time-entry-break/BreakForm/BreakForm';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useOpenable } from 'hooks';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import * as React from 'react';
import { useState } from 'react';
import IIdable from 'types/Idable';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import { getTotalMillisFromTimeRangeDateTimeType, nightShiftAdjustment, setZone, timeInRange } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { uuid } from 'utils/uuidUtils';
import MultiPicker from '../../../foundation/pickers/MultiPicker/MultiPicker';
import './BreakMultiPicker.scss';

export interface IBreakMultiPickerProps {
  timeEntryStart?: DateTime | null;
  timeEntryEnd?: DateTime | null;
  timeFormat: string;
  breaks: IBreakMultiPickerItem[];
  displayDateOnEntryDifference?: boolean;
  hideDateOnBreakForm?: boolean;
  displayError?: (item: IBreakMultiPickerItem) => boolean;
  onChange: (breaks: IBreakMultiPickerItem[]) => void;
  className?: ClassName;
}

export interface IBreakMultiPickerItem extends IIdable<string> {
  timeRange: ITimeRange<DateTime>;
  isOpen?: boolean;
  startDst?: boolean | null;
  endDst?: boolean | null;
}

function BreakMultiPicker(props: IBreakMultiPickerProps) {
  const {
    timeEntryStart,
    timeEntryEnd,
    timeFormat,
    breaks,
    displayDateOnEntryDifference,
    displayError,
    onChange,
    hideDateOnBreakForm,
    className,
  } = props;

  const [editingBreak, setEditingBreak] = useState<IBreakMultiPickerItem | null>(null);
  const { isOpen: breakDialogOpen, open: openBreakDialog, close: closeBreakDialog } = useOpenable();
  const sortedBreaks = _.sortBy(breaks, (brk) => brk.timeRange.startTime.toSeconds());

  function renderItem(value: IBreakMultiPickerItem, index: number) {
    function onItemClick() {
      if (value.isOpen) {
        // We don't edit open breaks burh
        return;
      }
      setEditingBreak(value);
      openBreakDialog();
    }

    function onDeleteItem(event: React.MouseEvent) {
      event.stopPropagation();
      onChange(breaks.filter((brk) => brk.id !== value.id));
    }

    const localizedTimeRange = {
      startTime: value.timeRange.startTime,
      endTime: setZone(
        setZone(value.timeRange.endTime, value.timeRange.startTime.zoneName, true),
        value.timeRange.endTime.zoneName
      ),
    }; // for total calculation, we need to make sure the timezones are the same

    const total = getTotalMillisFromTimeRangeDateTimeType(localizedTimeRange, true);
    if (total !== null) {
      let breakItemClasses = 'break-item';
      const nightShiftedAdjustment = nightShiftAdjustment(value.timeRange);
      if (
        (displayError && displayError(value)) ||
        (!displayError &&
          timeEntryStart &&
          timeEntryEnd &&
          !timeInRange(nightShiftedAdjustment.startTime, { startTime: timeEntryStart, endTime: timeEntryEnd }, true) &&
          !timeInRange(nightShiftedAdjustment.endTime, { startTime: timeEntryStart, endTime: timeEntryEnd }, true))
      ) {
        breakItemClasses = 'break-item input-error';
      }

      const withMarginClasses = classNames(breakItemClasses, {
        'mt-4': index !== 0,
      });

      const showDate = (isStart: boolean): boolean => {
        if (!displayDateOnEntryDifference) {
          return false;
        } else if (timeEntryStart && timeEntryEnd && dateUtils.isSameDay(timeEntryStart, timeEntryEnd)) {
          return false;
        } else if (isStart && dateUtils.isSameDay(value?.timeRange.startTime, value?.timeRange.endTime)) {
          return false;
        }
        return true;
      };

      return (
        <div className={withMarginClasses} onClick={onItemClick} key={value.id}>
          <Row className="break-item-left-content">
            <div>{Duration.fromMillis(total).toFormat('h:mm')}</div>
            <div>
              {!showDate(true)
                ? value?.timeRange.startTime.toFormat(timeFormat)
                : value?.timeRange.startTime.toFormat(`${timeFormat} (DD)`)}{' '}
              -{' '}
              {value.isOpen
                ? '?'
                : !showDate(false)
                ? value?.timeRange.endTime.toFormat(timeFormat)
                : value?.timeRange.endTime.toFormat(`${timeFormat} (DD)`)}
            </div>
          </Row>

          {!value.isOpen ? (
            <IconButton onClick={onDeleteItem} svg={CloseIcon} buttonSize={Size.SMALL} size={Size.MEDIUM} />
          ) : (
            <div className="m-3"> </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  function onBreakSubmit(formData: Pick<IBreakFormData, 'timeRange'>) {
    if (editingBreak) {
      onChange(
        breaks.map((brk) => {
          if (brk.id === editingBreak.id) {
            return { ...brk, timeRange: formData.timeRange as ITimeRange<DateTime> };
          } else {
            return brk;
          }
        })
      );
    } else {
      onChange([
        ...breaks,
        { id: uuid(), timeRange: formData.timeRange as ITimeRange<DateTime>, startDst: null, endDst: null },
      ]);
    }

    setEditingBreak(null);
    closeBreakDialog();
  }

  function onDeleteEditingBreak() {
    onChange(breaks.filter((brk) => brk.id !== editingBreak?.id));
    setEditingBreak(null);
    closeBreakDialog();
  }

  const classes = classNames('break-multi-picker', className);

  return (
    <div className={classes}>
      <HeaderDialog
        isOpen={breakDialogOpen}
        onClose={closeBreakDialog}
        title={editingBreak !== null ? t('Edit Break') : t('Create Break')}
      >
        {timeEntryStart && timeEntryEnd && (
          <BreakForm
            className="pt-3 pb-3 px-8"
            timeEntryStart={timeEntryStart}
            timeEntryEnd={timeEntryEnd}
            timeFormat={timeFormat}
            onSubmit={onBreakSubmit}
            onDelete={onDeleteEditingBreak}
            breaks={breaks}
            editingBreak={editingBreak}
            hideDateOnBreakForm={hideDateOnBreakForm}
          />
        )}
      </HeaderDialog>

      <MultiPicker
        onAddClick={() => {
          setEditingBreak(null);
          openBreakDialog();
        }}
        limit={3}
        renderItem={renderItem}
        values={sortedBreaks}
      />
    </div>
  );
}

BreakMultiPicker.defaultProps = {
  timeFormat: 't',
  displayDateOnEntryDifference: true,
};

export default BreakMultiPicker;
