import { Align, ITableColumn, Justify, Label, Row, Table, TextAlign } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import CostCodeTimeEntryDialog from 'components/domain/time-entry/dialog/CostCodeTimeEntryDialog/CostCodeTimeEntryDialog';
import EquipmentTimeEntryDialog from 'components/domain/time-entry/dialog/EquipmentTimeEntryDialog/EquipmentTimeEntryDialog';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import ProjectTimeEntryDialog from 'components/domain/time-entry/dialog/ProjectTimeEntryDialog/ProjectTimeEntryDialog';
import { ExpandedTimeCardsPrintDensityType } from 'containers/timesheets/TimesheetPrintOptionsForm/TimesheetPrintOptions';
import { useOpenable, useOrganization } from 'hooks';
import { t } from 'i18next';
import { first, isEmpty, isNil, isNull, some, sortBy, sumBy, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { mapNotNull } from 'utils/collectionUtils';
import { getDateString } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { getMetricLaborTotals, getRegularMetricLaborTotals } from 'utils/jitMetricUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay, getTotalAsHoursMinutesSeconds } from 'utils/stringUtils';
import { formatTime } from 'utils/timeUtils';
import { ExpandedTimeCardReportMemberData } from '../../context/ExpandedTimeCardReportContext';
import './ExpandedTimeCardsSummaryTable.scss';

export type ExpandedTimeCardsSummaryType = 'project' | 'cost-code' | 'equipment' | 'daily';

export interface IExpandedTimeCardsSummaryTableProps {
  className?: ClassName;
  scroller?: HTMLElement;
  data: ExpandedTimeCardReportMemberData | null;
  type: ExpandedTimeCardsSummaryType;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  onDataChange: (ids: string[]) => void;
  showDecimalFormat?: boolean;
  printDensity?: ExpandedTimeCardsPrintDensityType;
}

export interface IExpandedTimeCardsSummaryRowInfo {
  id: string;
  type: 'project' | 'cost-code' | 'equipment' | 'daily';
  title: string;
  regularHours: number;
  overtimeHours: number;
  doubleTimeHours?: number | null;
  total: number;
  isUnassigned: boolean;
  timeAccurate: boolean | null;
  injured: boolean | null;
  breakCompliance: boolean | null;
  date?: DateTime | null;
}

const ExpandedTimeCardsSummaryTable = (props: IExpandedTimeCardsSummaryTableProps) => {
  const { className, scroller, data, type, timeRange, onDataChange, timeRangeType, showDecimalFormat, printDensity } =
    props;

  const organization = useOrganization();
  const isPro = useFeatureFlags('PRO');
  const isDailySignOffEnabled =
    isPro && (organization.safetySignature || organization.timeAccuracy || organization.breakPolicy);

  const [tableData, setTableData] = useState<IExpandedTimeCardsSummaryRowInfo[]>(getProjectSummaryData());
  const projectTimeEntryDialogDetails = useOpenable();
  const costCodeTimeEntryDialogDetails = useOpenable();
  const equipmentTimeEntryDialogDetails = useOpenable();
  const memberTimeEntryDialogDetails = useOpenable();
  const selectedFilterId = useRef<string | null>(null);
  const selectedDay = useRef<DateTime | null>(null);

  useEffect(() => {
    setTableData(getTableData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function getTableData(): IExpandedTimeCardsSummaryRowInfo[] {
    switch (type) {
      case 'project':
        return getProjectSummaryData();
      case 'cost-code':
        return getCostCodeSummaryData();
      case 'equipment':
        return getEquipmentSummaryData();
      case 'daily':
        return getDailySummaryData();
      default:
        return [];
    }
  }

  function getUnassignedRow(
    rows: IExpandedTimeCardsSummaryRowInfo[],
    title: string,
    type: 'project' | 'cost-code' | 'equipment' | 'daily'
  ): IExpandedTimeCardsSummaryRowInfo | null {
    const totalSeconds = (data?.totalSeconds ?? 0) - sumBy(rows, (row) => row.total);
    if (totalSeconds > 0) {
      const newDTValue = (data?.totalDoubleTimeSeconds ?? 0) - sumBy(rows, (row) => row.doubleTimeHours ?? 0);

      const unassignedRow: IExpandedTimeCardsSummaryRowInfo = {
        id: 'unassigned',
        type: type,
        title: title,
        regularHours: (data?.totalRegularSeconds ?? 0) - sumBy(rows, (row) => row.regularHours),
        overtimeHours: (data?.totalOvertimeSeconds ?? 0) - sumBy(rows, (row) => row.overtimeHours),
        doubleTimeHours: newDTValue <= 0 ? null : newDTValue,
        total: totalSeconds,
        isUnassigned: true,
        timeAccurate: null,
        injured: null,
        breakCompliance: null,
      };
      return unassignedRow;
    }
    return null;
  }

  function getProjectSummaryData(): IExpandedTimeCardsSummaryRowInfo[] {
    if (!isNil(data?.projectSummaryData) && !isEmpty(data?.projectSummaryData)) {
      const rows = mapNotNull(data?.projectSummaryData ?? [], (project) => {
        const aggregates = getRegularMetricLaborTotals(first(project.projectMemberLaborMetrics ?? []));
        const row: IExpandedTimeCardsSummaryRowInfo = {
          id: project.id,
          type: 'project',
          title: getFormattedPathFromProject(project, true),
          regularHours: aggregates.regularSeconds,
          overtimeHours: aggregates.overtimeSeconds,
          doubleTimeHours: aggregates.doubleTimeSeconds <= 0 ? null : aggregates.doubleTimeSeconds,
          total: aggregates.totalSeconds,
          isUnassigned: false,
          timeAccurate: null,
          injured: null,
          breakCompliance: null,
        };
        return row;
      });

      const unassignedRow = getUnassignedRow(rows, t('No Project'), 'project');
      if (!isNull(unassignedRow)) {
        rows.push(unassignedRow);
      }

      return sortBy(rows, (row) => row.title);
    }
    return [];
  }

  function getCostCodeSummaryData(): IExpandedTimeCardsSummaryRowInfo[] {
    if (!isNil(data?.costCodeSummaryData) && !isEmpty(data?.costCodeSummaryData)) {
      const rows = mapNotNull(data?.costCodeSummaryData ?? [], (costCode) => {
        const aggregates = getMetricLaborTotals(first(costCode.costCodeMemberLaborMetrics ?? []));
        const row: IExpandedTimeCardsSummaryRowInfo = {
          id: costCode.id,
          type: 'cost-code',
          title: getCostCodeDisplay(costCode),
          regularHours: aggregates.regularSeconds,
          overtimeHours: aggregates.overtimeSeconds,
          doubleTimeHours: aggregates.doubleTimeSeconds <= 0 ? null : aggregates.doubleTimeSeconds,
          total: aggregates.totalSeconds,
          isUnassigned: false,
          timeAccurate: null,
          injured: null,
          breakCompliance: null,
        };
        return row;
      });

      const unassignedRow = getUnassignedRow(rows, t('No Cost Code'), 'cost-code');
      if (!isNull(unassignedRow)) {
        rows.push(unassignedRow);
      }

      return rows;
    }
    return [];
  }

  function getEquipmentSummaryData(): IExpandedTimeCardsSummaryRowInfo[] {
    if (!isNil(data?.equipmentSummaryData) && !isEmpty(data?.equipmentSummaryData)) {
      const rows = mapNotNull(data?.equipmentSummaryData ?? [], (equipment) => {
        const aggregates = getMetricLaborTotals(first(equipment.equipmentMemberLaborMetrics ?? []));
        const row: IExpandedTimeCardsSummaryRowInfo = {
          id: equipment.id,
          type: 'equipment',
          title: getEquipmentDisplay(equipment),
          regularHours: aggregates.regularSeconds,
          overtimeHours: aggregates.overtimeSeconds,
          doubleTimeHours: aggregates.doubleTimeSeconds <= 0 ? null : aggregates.doubleTimeSeconds,
          total: aggregates.totalSeconds,
          isUnassigned: false,
          timeAccurate: null,
          injured: null,
          breakCompliance: null,
        };
        return row;
      });

      const unassignedRow = getUnassignedRow(rows, t('No Equipment'), 'equipment');
      if (!isNull(unassignedRow)) {
        rows.push(unassignedRow);
      }

      return rows;
    }
    return [];
  }

  function getDailySummaryData(): IExpandedTimeCardsSummaryRowInfo[] {
    if (!isNil(data?.dailySummaryData) && !isEmpty(data?.dailySummaryData)) {
      const rows = mapNotNull(data?.dailySummaryData ?? [], (info) => {
        const doubleTime = info.doubleTimeHours ?? 0;

        const dateString = getDateString(info.date, 'ccc, LLL d', true);

        const row: IExpandedTimeCardsSummaryRowInfo = {
          id: dateString,
          type: 'daily',
          title: dateString,
          regularHours: info.regularHours ?? 0,
          overtimeHours: info.overtimeHours ?? 0,
          doubleTimeHours: doubleTime === 0 ? null : doubleTime,
          total: info.totalHours ?? 0,
          isUnassigned: false,
          timeAccurate: info.timeAccurate,
          injured: info.injured,
          breakCompliance: info.breakCompliance,
          date: info.date,
        };
        return row;
      });

      return rows;
    }
    return [];
  }

  const renderEmptyState = useCallback((): ReactNode => {
    let emptyTitle = t('No Summary Data');
    switch (type) {
      case 'project':
        emptyTitle = t('No Project Summary Data');
        break;
      case 'cost-code':
        emptyTitle = t('No Cost Code Summary Data');
        break;
      case 'equipment':
        emptyTitle = t('No Equipment Summary Data');
        break;
      case 'daily':
        emptyTitle = t('No Daily Summary Data');
        break;
      default:
        break;
    }
    return (
      <Row justify={Justify.CENTER} align={Align.CENTER}>
        <Label>{emptyTitle}</Label>
      </Row>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumns = useCallback((): Array<ITableColumn<IExpandedTimeCardsSummaryRowInfo>> => {
    const totalColumns = [
      {
        cell: renderRegHrsRow,
        key: 'regularHours',
        sort: false,
        title: t('Reg Hrs'),
        align: TextAlign.RIGHT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        footer: renderRegTotalColumnFooter,
        size: '120px',
      },
      {
        cell: renderOTHrsRow,
        key: 'overtimeHours',
        sort: false,
        title: t('OT Hrs'),
        align: TextAlign.RIGHT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        footer: renderOTTotalColumnFooter,
        size: '120px',
      },
    ];

    if (some(tableData, (row) => row.doubleTimeHours !== null)) {
      totalColumns.push({
        cell: renderDTHrsRow,
        key: 'doubleTimeHours',
        sort: false,
        title: t('DT Hrs'),
        align: TextAlign.RIGHT,
        cellClassName: 'table-view-cell-no-border',
        headerClassName: 'table-view-header-no-border',
        footerClassName: 'table-view-cell-no-border',
        footer: renderDTTotalColumnFooter,
        size: '120px',
      });
    }
    totalColumns.push({
      cell: renderTotalHrsRow,
      key: 'total',
      sort: false,
      title: t('Total'),
      align: TextAlign.RIGHT,
      cellClassName: 'table-view-cell-no-border',
      headerClassName: 'table-view-header-no-border',
      footerClassName: 'table-view-cell-no-border',
      footer: renderTotalColumnFooter,
      size: '120px',
    });

    const dailyColumns = Array<ITableColumn<IExpandedTimeCardsSummaryRowInfo>>();

    switch (type) {
      case 'project':
      case 'cost-code':
      case 'equipment':
        return [
          {
            cell: renderNameRow,
            key: 'title',
            sort: false,
            title: t('Name'),
            align: TextAlign.LEFT,
            cellClassName: 'table-view-cell-no-border',
            headerClassName: 'table-view-header-no-border',
            footerClassName: 'table-view-cell-no-border',
            footer: renderTotalsColumnFooter,
          },
          ...totalColumns,
        ];
      case 'daily':
        if (isDailySignOffEnabled) {
          if (organization.timeAccuracy) {
            dailyColumns.push({
              cell: renderAnswer,
              key: 'timeAccurate',
              sort: false,
              title: t('Time Acc.'),
              align: TextAlign.CENTER,
              cellClassName: 'table-view-cell-no-border',
              headerClassName: 'table-view-header-no-border',
              footerClassName: 'table-view-cell-no-border',
              size: '90px',
            });
          }

          if (organization.breakPolicy) {
            dailyColumns.push({
              cell: renderAnswer,
              key: 'breakCompliance',
              sort: false,
              title: t('Break Comp.'),
              align: TextAlign.CENTER,
              cellClassName: 'table-view-cell-no-border',
              headerClassName: 'table-view-header-no-border',
              footerClassName: 'table-view-cell-no-border',
              size: '100px',
            });
          }

          if (organization.safetySignature) {
            dailyColumns.push({
              cell: renderAnswer,
              key: 'injured',
              sort: false,
              title: t('Injured'),
              align: TextAlign.CENTER,
              cellClassName: 'table-view-cell-no-border',
              headerClassName: 'table-view-header-no-border',
              footerClassName: 'table-view-cell-no-border',
              size: '90px',
            });
          }
        }

        return [
          {
            cell: renderNameRow,
            key: 'title',
            sort: false,
            title: t('Name'),
            align: TextAlign.LEFT,
            cellClassName: 'table-view-cell-no-border',
            headerClassName: 'table-view-header-no-border',
            footerClassName: 'table-view-cell-no-border',
            footer: renderTotalsColumnFooter,
          },
          ...dailyColumns,
          ...totalColumns,
        ];
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, tableData]);

  function renderNameRow(
    row: IExpandedTimeCardsSummaryRowInfo,
    _col: ITableColumn<IExpandedTimeCardsSummaryRowInfo>
  ): ReactNode {
    return <div className={'ml-2 mb-0 p-2' + (row.isUnassigned ? ' unassigned-title' : '')}>{row.title}</div>;
  }

  function renderAnswer(
    row: IExpandedTimeCardsSummaryRowInfo,
    col: ITableColumn<IExpandedTimeCardsSummaryRowInfo>
  ): ReactNode {
    let answer: boolean | null = null;
    let answerText = '---';

    switch (col.key) {
      case 'injured':
        answer = row.injured;
        break;
      case 'timeAccurate':
        answer = row.timeAccurate;
        break;
      case 'breakCompliance':
        answer = row.breakCompliance;
        break;
    }

    if (answer === true) {
      answerText = t('Yes');
    } else if (answer === false) {
      answerText = t('No');
    }

    return <div>{answerText}</div>;
  }

  function renderTimeValue(seconds: number, showZeros: boolean): string {
    if (seconds > 0) {
      if (!isNil(showDecimalFormat) && showDecimalFormat) {
        return formatTime({ type: 'DECIMAL', seconds: seconds, places: 2 });
      }
      return getTotalAsHoursMinutesSeconds(seconds);
    }
    if (showZeros && !isNil(showDecimalFormat) && showDecimalFormat) {
      return '0.00';
    }
    return showZeros ? '00:00' : '---';
  }

  function renderRegHrsRow(
    row: IExpandedTimeCardsSummaryRowInfo,
    _col: ITableColumn<IExpandedTimeCardsSummaryRowInfo>
  ): ReactNode {
    return <div className="ml-2 mb-0 p-2">{renderTimeValue(row.regularHours, false)}</div>;
  }

  function renderOTHrsRow(
    row: IExpandedTimeCardsSummaryRowInfo,
    _col: ITableColumn<IExpandedTimeCardsSummaryRowInfo>
  ): ReactNode {
    return <div className="ml-2 mb-0 p-2">{renderTimeValue(row.overtimeHours, false)}</div>;
  }

  function renderDTHrsRow(
    row: IExpandedTimeCardsSummaryRowInfo,
    _col: ITableColumn<IExpandedTimeCardsSummaryRowInfo>
  ): ReactNode {
    return (
      <div className="ml-2 mb-0 p-2">{!isNil(row.doubleTimeHours) && renderTimeValue(row.doubleTimeHours, false)}</div>
    );
  }

  function renderTotalHrsRow(
    row: IExpandedTimeCardsSummaryRowInfo,
    _col: ITableColumn<IExpandedTimeCardsSummaryRowInfo>
  ): ReactNode {
    return <div className="ml-2 mb-0 p-2">{renderTimeValue(row.total, false)}</div>;
  }

  function renderTotalsColumnFooter(): ReactNode {
    return <div className="ml-2 mb-0 p-2">{t('Totals')}</div>;
  }

  function renderRegTotalColumnFooter(): ReactNode {
    return (
      <div className="ml-2 mb-0 p-2">
        {renderTimeValue(
          sumBy(tableData, (row) => row.regularHours),
          true
        )}
      </div>
    );
  }

  function renderOTTotalColumnFooter(): ReactNode {
    return (
      <div className="ml-2 mb-0 p-2">
        {renderTimeValue(
          sumBy(tableData, (row) => row.overtimeHours),
          true
        )}
      </div>
    );
  }

  function renderDTTotalColumnFooter(): ReactNode {
    return (
      <div className="ml-2 mb-0 p-2">
        {renderTimeValue(
          sumBy(tableData, (row) => row.doubleTimeHours ?? 0),
          true
        )}
      </div>
    );
  }

  function renderTotalColumnFooter(): ReactNode {
    return (
      <div className="ml-2 mb-0 p-2">
        {renderTimeValue(
          sumBy(tableData, (row) => row.total),
          true
        )}
      </div>
    );
  }

  const tableWidth = () => {
    const columns = getColumns();
    return sumBy(columns, (column) => toNumber(column.size?.replace('px', '') ?? '0')) + 220;
  };

  function handleRowClick(row: IExpandedTimeCardsSummaryRowInfo) {
    selectedFilterId.current = row.id;
    switch (row.type) {
      case 'project':
        projectTimeEntryDialogDetails.open();
        break;
      case 'cost-code':
        costCodeTimeEntryDialogDetails.open();
        break;
      case 'equipment':
        equipmentTimeEntryDialogDetails.open();
        break;
      case 'daily':
        selectedDay.current = row.date!;
        memberTimeEntryDialogDetails.open();
        break;
    }
  }

  function handleEntryDialogClose() {
    selectedFilterId.current = null;
    selectedDay.current = null;
    projectTimeEntryDialogDetails.close();
    costCodeTimeEntryDialogDetails.close();
    equipmentTimeEntryDialogDetails.close();
    memberTimeEntryDialogDetails.close();
  }

  const classes = classNames(
    'expanded-time-cards-summary-table',
    printDensity === 'standard' ? 'standard' : '',
    className
  );

  return (
    <>
      <Table
        className={classes}
        cols={getColumns()}
        data={tableData}
        strokeCols={true}
        lazyLoad={false}
        emptyTemplate={renderEmptyState()}
        footer="standard"
        header="standard"
        scroller={scroller}
        lazyScrollSectionSize={30}
        minWidth={`${tableWidth()}px`}
        onRowClick={handleRowClick}
      />
      {!isNil(selectedFilterId.current) && !isNil(data) && (
        <>
          {projectTimeEntryDialogDetails.isOpen && (
            <ProjectTimeEntryDialog
              isOpen={projectTimeEntryDialogDetails.isOpen}
              onClose={handleEntryDialogClose}
              timeRange={timeRange}
              projectIds={selectedFilterId.current === 'unassigned' ? ['0'] : [selectedFilterId.current]}
              memberIds={[data.id]}
              timeRangeType={timeRangeType}
              includeOpen={false}
              projectIdWithDescendants={selectedFilterId.current !== 'unassigned'}
              excludeTypeColumn={false}
              onDataChange={onDataChange}
            />
          )}
          {costCodeTimeEntryDialogDetails.isOpen && (
            <CostCodeTimeEntryDialog
              isOpen={costCodeTimeEntryDialogDetails.isOpen}
              onClose={handleEntryDialogClose}
              timeRange={timeRange}
              costCodeIds={selectedFilterId.current === 'unassigned' ? ['0'] : [selectedFilterId.current]}
              memberIds={[data.id]}
              timeRangeType={timeRangeType}
              includeOpen={false}
              projectIdWithDescendants={selectedFilterId.current !== 'unassigned'}
              onDataChange={onDataChange}
            />
          )}
          {equipmentTimeEntryDialogDetails.isOpen && (
            <EquipmentTimeEntryDialog
              isOpen={equipmentTimeEntryDialogDetails.isOpen}
              onClose={handleEntryDialogClose}
              timeRange={timeRange}
              equipmentIds={selectedFilterId.current === 'unassigned' ? ['0'] : [selectedFilterId.current]}
              memberIds={[data.id]}
              timeRangeType={timeRangeType}
              includeOpen={false}
              projectIdWithDescendants={selectedFilterId.current !== 'unassigned'}
              onDataChange={onDataChange}
            />
          )}
          {memberTimeEntryDialogDetails.isOpen && !isNil(selectedDay.current) && (
            <MemberTimeEntryDialog
              isOpen={memberTimeEntryDialogDetails.isOpen}
              onClose={handleEntryDialogClose}
              timeRange={{ startTime: selectedDay.current.startOf('day'), endTime: selectedDay.current.endOf('day') }}
              memberIds={[data.id]}
              timeRangeType={TimeRangeType.DAILY}
              includeOpen={false}
              projectIdWithDescendants={selectedFilterId.current !== 'unassigned'}
              onDataChange={onDataChange}
            />
          )}
        </>
      )}
    </>
  );
};

export default ExpandedTimeCardsSummaryTable;
