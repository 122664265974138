import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import BasicPicker, {
  IBasicPickerProps,
  IBasicPickerValue,
} from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import useQuickTimeTemplates from 'containers/quick-time/hooks/useQuickTimeTemplates';
import map from 'lodash/map';
import { useCallback, useMemo, useRef } from 'react';
import './QuickTimeTemplatePicker.scss';

export type IQuickTimeTemplatePickerProps = Omit<IBasicPickerProps, 'data'>;

function QuickTimeTemplatePicker({ value, onSelect, className, ...basicPickerProps }: IQuickTimeTemplatePickerProps) {
  const { templates } = useQuickTimeTemplates();
  const onSelectRef = useRef(onSelect);
  const options: IBasicPickerValue[] = useMemo(
    () =>
      map(templates, (value) => ({
        id: value.name, // name is unique
        name: value.name,
      })),
    [templates]
  );

  const onValueSelect = useCallback(
    (newValue: string | null) => {
      const currentTemplate = newValue ? templates[newValue].name : null;
      onSelectRef.current(currentTemplate);
    },
    [templates]
  );

  if (options.length === 0) {
    return null;
  }

  const classes = classNames('quick-time-template-picker', className);
  return (
    <BasicPicker data={options} className={classes} onSelect={onValueSelect} value={value} {...basicPickerProps} />
  );
}

export default QuickTimeTemplatePicker;
