import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { useOpenable } from 'hooks';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { ITimeEntryDataTableRow } from '../../TimeEntryDataTable/TimeEntryDataTable';
import TimeEntryDataTableHeader from '../../TimeEntryDataTable/TimeEntryDataTableHeader/TimeEntryDataTableHeader';
import TimeEntryDataReport from '../TimeEntryDataReport';
import useCheckArchivedOfCostCodes from '../hooks/useCheckArchivedOfCostCodes';
import useCheckArchivedOfMembers from '../hooks/useCheckArchivedOfMembers';
import useCheckArchivedOfProject from '../hooks/useCheckArchivedOfProject';
import useCheckCanMangeTimeForMembers from '../hooks/useCheckCanMangeTimeForMembers';
import useCheckDeletedOfEquipment from '../hooks/useCheckDeleteOfEquipment';
import ITimeEntryReportWithHeaderData from '../types/TimeEntryReportWithHeaderData';

export interface IProjectTimeEntryDataReportProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  selectedIds: string[];
  onSelectChange?: (rows: ITimeEntryDataTableRow[]) => void;
  columnSettings: IVisibleTableColumn[];
  data: ITimeEntryReportWithHeaderData[];
  loading: boolean;
  updateProjectIds: (projectIds: string[]) => void;
  memberIds?: string[] | null;
  costCodeId?: string | null;
  equipmentId?: string | null;
  loadedAll: boolean;
  applyPrintOptions: boolean;
  showSelectAll?: boolean;
}

const ProjectTimeEntryDataReport = ({
  className,
  timeRange,
  columnSettings,
  selectedIds,
  onSelectChange,
  data,
  loading,
  updateProjectIds,
  memberIds,
  costCodeId,
  equipmentId,
  loadedAll,
  applyPrintOptions,
  showSelectAll,
}: IProjectTimeEntryDataReportProps) => {
  const timeEntryDialogOpenable = useOpenable();
  const [projectId, setProjectId] = useState<string | null>(null);
  const memberArchived = useCheckArchivedOfMembers(memberIds);
  const canManageMembers = useCheckCanMangeTimeForMembers(memberIds);
  const checkArchivedOfProjects = useCheckArchivedOfProject();
  const checkArchivedOfCostCodes = useCheckArchivedOfCostCodes();
  const [dialogCostCodeId, setDialogCostCodeId] = useState<string | null | undefined>(null);
  const checkDeletedOfEquipment = useCheckDeletedOfEquipment();
  const [dialogEquipmentId, setDialogEquipmentId] = useState<string | null | undefined>(null);

  function renderHeader(item: ITimeEntryReportWithHeaderData) {
    async function handleAddEntry() {
      if (item.id === remainingDataItemId || (await checkArchivedOfProjects([item.id]))) {
        setProjectId(null);
      } else {
        setProjectId(item.id);
      }
      if (await checkArchivedOfCostCodes(!isNil(costCodeId) ? [costCodeId] : null)) {
        setDialogCostCodeId(null);
      } else {
        setDialogCostCodeId(costCodeId);
      }
      if (await checkDeletedOfEquipment(!isNil(equipmentId) ? [equipmentId] : null)) {
        setDialogEquipmentId(null);
      } else {
        setDialogEquipmentId(equipmentId);
      }
      timeEntryDialogOpenable.open();
    }

    return (
      <TimeEntryDataTableHeader
        title={item.title}
        total={item.total}
        openAddEntry={memberArchived === false && canManageMembers ? handleAddEntry : undefined}
      />
    );
  }

  function updateMemberIds(memberIds: string[]) {
    const projectIdsToUpdate: string[] = [];
    data.map((d) => {
      const rowMemberIds = d.rows.map((r) => r.member.id);
      const [matched] = _.partition(rowMemberIds, (rowMemberId) =>
        _.some(memberIds, (memberId) => memberId === rowMemberId)
      );
      if (!_.isEmpty(matched)) {
        projectIdsToUpdate.push(d.id);
      }
    });
    const uniqIds = _.uniq(projectIdsToUpdate);
    updateProjectIds(_.isEmpty(uniqIds) && projectId ? [projectId] : uniqIds);
    setProjectId(null);
  }

  function handleAddEntrySubmit(data: ITimeActionsFormData) {
    updateMemberIds(data.members);
    timeEntryDialogOpenable.close();
  }

  return (
    <>
      <TimeEntryDataReport
        data={data}
        loading={loading}
        columnSettings={columnSettings}
        className={classNames('project-time-entry-data-report', className)}
        selectedIds={selectedIds}
        onSelectChange={onSelectChange}
        timeRange={timeRange}
        renderHeader={renderHeader}
        onDataChange={updateMemberIds}
        loadedAll={loadedAll}
        applyPrintOptions={applyPrintOptions}
        showSelectAll={showSelectAll}
      />
      <TimeActionsFormDialog
        type={'add'}
        projectId={projectId}
        memberIds={memberIds ?? []}
        costCodeId={dialogCostCodeId}
        equipmentId={dialogEquipmentId}
        currentDate={timeRange.endTime}
        isOpen={timeEntryDialogOpenable.isOpen}
        onClose={timeEntryDialogOpenable.close}
        onSubmit={handleAddEntrySubmit}
        onDelete={handleAddEntrySubmit}
      />
    </>
  );
};

ProjectTimeEntryDataReport.defaultProps = {
  applyPrintOptions: true,
};

export default ProjectTimeEntryDataReport;
