import ErrorState from 'components/foundation/state-templates/ErrorState/ErrorState';
import LoadingState from 'components/foundation/state-templates/LoadingState/LoadingState';
import Panel from 'components/layout/Panel/Panel';
import { useToastProblemOpen } from 'contexts/ToastContext';
import { useActiveMember } from 'hooks';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import { lazy, Suspense, type FunctionComponent, type PropsWithChildren } from 'react';
import { isoTimeStampUtc } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { type CreatePolicyMemberAcceptanceMutation } from '__generated__/graphql';
import { updatePolicyLocalStorageCache, type TPolicyCacheData } from './localStorageCache';
import { type PolicyEntryPopulated } from './Policies/queries';
import { isPolicyEntryPopulated } from './Policies/types';
import { useCreatePolicyMemberAcceptance, type PolicyMemberAcceptanceEntry } from './PolicyMemberAcceptances/queries';
import { usePolicyQueries } from './usePolicyQueries';

export const ValidateAgreementsDialog = lazy(() => import('./ValidateAgreementsDialog'));

export const VERIFYING_POLICY_AGREEMENTS = t(`Verifying Policy Agreements`);

export const determineIsAgreementRequired = (
  policyMemberAcceptance: PolicyMemberAcceptanceEntry | undefined,
  effectivePolicy: PolicyEntryPopulated | undefined
) => {
  const effectiveVersion = effectivePolicy?.version;
  const agreedVersion = policyMemberAcceptance?.policy?.version;

  // there is no policy in effect
  if (isNil(effectiveVersion)) return false;

  // user has not agreed to any policy
  if (isNil(agreedVersion)) return true;

  // compare the version of the effective policy to the version of the member's last agreed policy
  return effectiveVersion > agreedVersion;
};

export interface IValidateAgreementsProps {
  setAgreementTerms: (val: TPolicyCacheData) => void;
  setAgreementPrivacy: (val: TPolicyCacheData) => void;
  invalidCacheReasons?: string[];
}
export const ValidateAgreements: FunctionComponent<PropsWithChildren<IValidateAgreementsProps>> = ({
  setAgreementTerms,
  setAgreementPrivacy,
  invalidCacheReasons,
  children,
}) => {
  const member = useActiveMember();
  const toastProblem = useToastProblemOpen();
  const policyQueries = usePolicyQueries();
  const createPolicyMemberAcceptance = useCreatePolicyMemberAcceptance();

  if (policyQueries.isLoading) {
    return <LoadingState title={VERIFYING_POLICY_AGREEMENTS} />;
  } else if (policyQueries.hasError) {
    // eslint-disable-next-line no-console
    console.error(`Error loading policy queries`, policyQueries);
    return (
      <Panel>
        <ErrorState />
      </Panel>
    );
  }

  const memberPolicyTerms = policyQueries.memberPolicyTerms.data;
  const memberPolicyPrivacy = policyQueries.memberPolicyPrivacy.data;
  const effectivePolicyTerms = policyQueries.effectivePolicyTerms.data;
  const effectivePolicyPrivacy = policyQueries.effectivePolicyPrivacy.data;

  if (effectivePolicyTerms && !isPolicyEntryPopulated(effectivePolicyTerms))
    throw new Error(`Invalid Terms and Agreements`);
  if (effectivePolicyPrivacy && !isPolicyEntryPopulated(effectivePolicyPrivacy))
    throw new Error(`Invalid Privacy Policy`);

  const termsAgreementRequired = determineIsAgreementRequired(memberPolicyTerms, effectivePolicyTerms);
  const privacyAgreementRequired = determineIsAgreementRequired(memberPolicyPrivacy, effectivePolicyPrivacy);

  const pendingPolicies: PolicyEntryPopulated[] = [];
  if (termsAgreementRequired && effectivePolicyTerms) pendingPolicies.push(effectivePolicyTerms);
  if (privacyAgreementRequired && effectivePolicyPrivacy) pendingPolicies.push(effectivePolicyPrivacy);

  const updateLocalStorageCache = () => {
    const isoNow = isoTimeStampUtc();
    const nextPolicyTerms = policyQueries.nextPolicyTerms.data;
    const nextPolicyPrivacy = policyQueries.nextPolicyPrivacy.data;

    updatePolicyLocalStorageCache(setAgreementTerms, isoNow, memberPolicyTerms, effectivePolicyTerms, nextPolicyTerms);
    updatePolicyLocalStorageCache(
      setAgreementPrivacy,
      isoNow,
      memberPolicyPrivacy,
      effectivePolicyPrivacy,
      nextPolicyPrivacy
    );
  };

  const resetPolicyLocalStorageCache = () => {
    updatePolicyLocalStorageCache(setAgreementTerms, undefined, undefined, undefined, undefined);
    updatePolicyLocalStorageCache(setAgreementPrivacy, undefined, undefined, undefined, undefined);
  };

  if (pendingPolicies.length) {
    const handleSubmit = () => {
      const promises: Array<Promise<CreatePolicyMemberAcceptanceMutation>> = [];

      if (termsAgreementRequired && effectivePolicyTerms?.id) {
        promises.push(createPolicyMemberAcceptance({ memberId: member.id, policyId: effectivePolicyTerms.id }));
      }

      if (privacyAgreementRequired && effectivePolicyPrivacy?.id) {
        promises.push(createPolicyMemberAcceptance({ memberId: member.id, policyId: effectivePolicyPrivacy.id }));
      }

      // allSettled because we don't want fast fail
      return Promise.allSettled(promises)
        .then((settledResults) => {
          if (every(settledResults, { status: 'fulfilled' })) {
            updateLocalStorageCache();
          } else {
            toastProblem();
            resetPolicyLocalStorageCache();
          }

          policyQueries.refetch();
        })
        .catch(() => {
          toastProblem();
          resetPolicyLocalStorageCache();
        });
    };

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ValidateAgreementsDialog
          invalidCacheReasons={invalidCacheReasons}
          policies={pendingPolicies}
          onSubmit={handleSubmit}
        />
      </Suspense>
    );
  }

  // update the local storage cache when policy data is loaded and ready to use
  updateLocalStorageCache();

  return <>{children}</>;
};
