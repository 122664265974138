import { useCurrencyNumberFormatter } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { Duration } from 'luxon';
import { convertDurationToHoursDecimal, secondsToHmmString } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';

export default function useEquipmentActivityReportExport() {
  const costFormatter = useCurrencyNumberFormatter('usd', { maximumFractionDigits: 2 })!;
  const canViewCost = useHasCostPermission();

  function getExportData<DataType extends IEquipmentActivityTableRowInfo>(
    data: DataType[],
    detailColumns?: (row: DataType) => object
  ) {
    return data.map((row) => {
      const costData: any = {};
      if (canViewCost) {
        costData[t('Regular Cost (Labor)')] = costFormatter.format(row.regularCost);
        costData[t('Overtime Cost (Labor)')] = costFormatter.format(row.overtimeCost);
        costData[t('Double Time Cost (Labor)')] = costFormatter.format(row.doubletimeCost);
        costData[t('Labor Burden')] = costFormatter.format(row.laborBurden);
        costData[t('Total Cost (Labor)')] = costFormatter.format(row.totalCost);
        costData[t('Equip. Rate')] = costFormatter.format(row.equipmentRate);
        costData[t('Equip. Cost')] = costFormatter.format(row.equipmentCost);
        costData[t('Total Cost')] = costFormatter.format(row.equipmentTotalCost);
      }

      return {
        [t('Equipment')]: row.equipment ? getEquipmentDisplay(row.equipment) : t('No Equipment'),
        ...(detailColumns ? detailColumns(row) : {}),
        [t('Regular Time')]: secondsToHmmString(row.regularHours),
        [t('Regular Time (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis(row.regularHoursDec * 1000),
          2
        ),
        [t('Overtime')]: secondsToHmmString(row.overtimeHours),
        [t('Overtime (Decimal)')]: convertDurationToHoursDecimal(Duration.fromMillis(row.overtimeHoursDec * 1000), 2),
        [t('Double Time')]: secondsToHmmString(row.doubleTimeHours),
        [t('Double Time (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis(row.doubleTimeHoursDec * 1000),
          2
        ),
        [t('Total')]: secondsToHmmString(row.totalHours),
        [t('Total (Decimal)')]: convertDurationToHoursDecimal(Duration.fromMillis(row.totalHoursDec * 1000), 2),
        ...costData,
      };
    });
  }

  return getExportData;
}
