import { DateTime } from 'luxon';
import TimeOffType from 'types/enum/TimeOffType';
import { ITimeOffCreateInput } from 'types/TimeOff';
import { getDayRanges, getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { uuid } from 'utils/uuidUtils';
import { ITimeOffFormData } from '../CreateTimeOffForm/CreateTimeOffForm';

export function convertTimeOffFormDataToTimeOffCreateInput(
  { startDate, endDate, members, description, total, paid, type }: ITimeOffFormData,
  activeMemberId: string
): ITimeOffCreateInput[] {
  const dates = !endDate ? [startDate.value!] : getDayRanges(startDate.value!, endDate!.value!);
  return createTimeOffCreateInput(
    dates,
    members,
    description ? description : null,
    total ? total : null,
    paid,
    type!,
    activeMemberId
  );
}

export function createTimeOffCreateInput(
  dates: Date[],
  memberIds: string[],
  description: string | null,
  totals: { [key: string]: number } | null,
  paid: boolean,
  type: TimeOffType,
  activeMemberId: string
): ITimeOffCreateInput[] {
  return createTimeOffCreateInputDayTransform(
    dates,
    memberIds,
    description,
    (day) => (totals && paid ? totals[day.toDateString()] : null),
    paid,
    type,
    activeMemberId
  );
}

export function createTimeOffCreateInputDayTransform(
  dates: Date[],
  memberIds: string[],
  description: string | null,
  getTotal: (date: Date) => number | null,
  paid: boolean,
  type: TimeOffType,
  activeMemberId: string
) {
  const createdOnInDst = DateTime.local().isInDST;

  return dates.flatMap((day) => {
    const seconds = paid ? getTotal(day) : null;

    return memberIds.map((memberId) => {
      const entry: ITimeOffCreateInput = {
        id: uuid(),
        memberId,
        submitterMemberId: activeMemberId,
        dateStamp: DateTime.fromJSDate(day).startOf('day').toISO({ suppressMilliseconds: true, includeOffset: false }),
        type,
        description,
        paid,
        seconds,
        createdOnDaylightSavingTime: createdOnInDst,
        createdOn: getNowIsoAtUtcWithLocalTimeZone(),
      };

      return entry;
    });
  });
}

export function createTimeOffCreateInputNumberTotal(
  dates: Date[],
  memberIds: string[],
  description: string | null,
  total: number | null,
  paid: boolean,
  type: TimeOffType,
  activeMemberId: string
) {
  return createTimeOffCreateInputDayTransform(
    dates,
    memberIds,
    description,
    () => (paid ? total : null),
    paid,
    type,
    activeMemberId
  );
}
