import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { TimeEntryActionBarContextProvider } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import ActivityDetailsDialog from 'containers/activity-reports/activity-report-details/ActivityDetailsDialog/ActivityDetailsDialog';
import ChannelContextProvider from 'contexts/ChannelContext/ChannelContext';
import { useOpenable } from 'hooks';
import { isNull } from 'lodash';
import useActiveCardReportNavigation from '../../hooks/useActiveCardReportNavigation';
import useActivityReportDetailNavigation from '../../hooks/useActivityReportDetailNavigation';
import ActiveCostCodesDashboardCardContent from '../ActiveCostCodesDashboardCardContent/ActiveCostCodeDashboardCardContent';
import { defaultActiveCostCodeDashboardCardTimeFrame } from '../constants/constants';
import useActiveCostCodeDashboard from './hooks/useActiveCostCodeDashboard';

export interface IActiveCostCodesDashboardCardProps {
  setting: IDashboardSettingsItem;
  theme: Theme.DARK | Theme.LIGHT;
  onHide: () => void;
  className?: ClassName;
  memberId?: string | null;
  projectId?: string | null;
  equipmentId?: string | null;
}

const ActiveCostCodesDashboardCard = ({
  setting,
  theme,
  onHide,
  className,
  memberId,
  projectId,
  equipmentId,
}: IActiveCostCodesDashboardCardProps) => {
  const activityReportNavigation = useActiveCardReportNavigation(setting.options?.time, 'by-cost-code');
  const { timeRangeType, navRange, getActivityReportFilter, getActivityReportFilterType } =
    useActivityReportDetailNavigation(setting.options?.time, memberId, undefined, equipmentId, projectId);
  const activityReportDetailsDialog = useOpenable();

  const { data, loading, error, execute } = useActiveCostCodeDashboard(
    setting.options?.time ?? defaultActiveCostCodeDashboardCardTimeFrame,
    memberId,
    projectId,
    equipmentId
  );

  function handleRightClick() {
    if (isNull(getActivityReportFilter())) {
      activityReportNavigation();
    } else {
      activityReportDetailsDialog.open();
    }
  }

  const classes = classNames('active-cost-codes-dashboard-card', className);

  return (
    <>
      <ActiveCostCodesDashboardCardContent
        className={classes}
        data={data}
        loading={loading}
        error={error}
        onReload={execute}
        setting={setting}
        onDetailClick={handleRightClick}
        onHide={onHide}
        theme={theme}
      />
      {!isNull(timeRangeType) && !isNull(navRange) && (
        <TimeEntryActionBarContextProvider>
          <ChannelContextProvider>
            <ActivityDetailsDialog
              isOpen={activityReportDetailsDialog.isOpen}
              onClose={activityReportDetailsDialog.close}
              timeRange={navRange}
              timeRangeType={timeRangeType}
              filterType={getActivityReportFilterType()}
              filterId={getActivityReportFilter()}
              initialReportType={ActivityReportType.BY_COST_CODE}
            />
          </ChannelContextProvider>
        </TimeEntryActionBarContextProvider>
      )}
    </>
  );
};

export default ActiveCostCodesDashboardCard;
