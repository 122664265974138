import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import DashboardAnnouncement from 'components/foundation/dashboard/DashboardAnnouncement/DashboardAnnouncement';
import DashboardGridHeader from 'containers/dashboard/DashboardGridHeader/DashboardGridHeader';
import DashboardSettingsForm from 'containers/dashboard/DashboardSettingsForm/DashboardSettingsForm';
import LockedAccountBanner from 'containers/dashboard/LockedAccountBanner/LockedAccountBanner';
import { useDashboardSettings } from 'containers/dashboard/context/DashboardSettingsContext';
import { useOpenable, useReduxSelector } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from 'utils/features';
import DashboardGridContent from '../DashboardGridContent/DashboardGridContent';
import './DashboardGrid.scss';

export interface IDashboardGridProps {
  className?: ClassName;
}

function DashboardGrid({ className }: IDashboardGridProps) {
  const [t] = useTranslation();
  const settingsOpenable = useOpenable();
  const { settings } = useDashboardSettings();
  const memberSettings = useMemberSettings();
  const enabledBy4 = memberSettings?.web?.ui?.dashboard?.fourCardsPerRow;
  const currentInterval: number = useReduxSelector((state) => state.preferences.dashboardRefreshInterval);
  const [refreshKey, setRefreshKey] = useState(() => DateTime.local().toSeconds());
  const [refreshedMinutesAgo, setRefreshedMinutesAgo] = useState(0);
  const DASHBOARD_WEB_REFRESH = useFeatureFlags('DASHBOARD_WEB_REFRESH');

  useEffect(() => {
    if (refreshedMinutesAgo === 0) {
      setRefreshKey(DateTime.local().toSeconds());
    }
  }, [refreshedMinutesAgo]);

  useEffect(() => {
    if (DASHBOARD_WEB_REFRESH) {
      const maxInterval = currentInterval - 2;
      const interval = setInterval(() => {
        setRefreshedMinutesAgo((currentMinutes) => (currentMinutes > maxInterval ? 0 : currentMinutes + 1));
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [DASHBOARD_WEB_REFRESH, currentInterval]);

  const refresh = () => {
    setRefreshedMinutesAgo(0);
  };

  return (
    <div className={classNames('dashboard-grid-wrapper', className)}>
      <DashboardGridHeader
        onRefreshClick={refresh}
        refreshedMinutesAgo={refreshedMinutesAgo}
        onSettingsClick={settingsOpenable.open}
      />
      <LockedAccountBanner />
      <DashboardAnnouncement theme={Theme.DARK} />
      <div
        className={enabledBy4 ?? false ? 'dashboard-grid-large' : 'dashboard-grid'}
        style={{
          gridTemplateRows:
            !isNil(settings.clockStatus) && settings.clockStatus.visible
              ? '100px repeat(auto-fill, minmax(350px, 100%))'
              : 'minmax(350px, 100%)',
        }}
      >
        <DashboardGridContent key={refreshKey} />
      </div>

      <HeaderDialog title={t('Dashboard Settings')} onClose={settingsOpenable.close} isOpen={settingsOpenable.isOpen}>
        <DashboardSettingsForm
          onSubmit={() => {
            refresh();
            settingsOpenable.close();
          }}
        />
      </HeaderDialog>
    </div>
  );
}

export default DashboardGrid;
