import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { TimestampComparison } from '__generated__/graphql';

export function getApolloArchivedTimestampComparison(archivedStatus: ArchivedStatus): TimestampComparison | undefined {
  switch (archivedStatus) {
    case 'all':
      return undefined;
    case 'archived':
      return { isNull: false };
    case 'unarchived':
      return { isNull: true };
  }
}
