enum ClockActionType {
  CLOCK_IN = 10,
  CLOCK_IN_LOCATION = 11,
  CLOCK_OUT = 20,
  CLOCK_OUT_LOCATION = 21,
  SYSTEM_CLOCK_OUT = 25,
  CLOCK_IN_AT = 30,
  CLOCK_OUT_AT = 40,
  MANUAL = 50,
  EDIT = 60,
  EDIT_BREAKS = 70,
  CONFLICT_RESOLUTION = 80,
  DELETE = 90,
  CONFLICT_RESOLUTION_DELETE = 100,
  LOCK_CONFLICT_RESOLUTION_DELETE = 105,
}

export default ClockActionType;
