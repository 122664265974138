import { useApolloClient } from '@apollo/client';
import { Button, Divider, Icon, TextField } from '@busybusy/webapp-react-ui';
import {
  MemberClockStateType,
  MemberFilterClockState,
  MemberHasScheduleFilter,
  MemberSort,
  PositionFilter,
  QueryFlatMemberNameQuery,
  QueryFlatMemberNameQueryVariables,
} from '__generated__/graphql';
import { MEMBER_FLAT_QUERY } from 'apollo/queries/member-queries';
import { SearchMagnifyingGlass } from 'assets/icons';
import classNames from 'classnames';
import MemberGroupSelect from 'components/domain/member-group/MemberGroupSelect/MemberGroupSelect';
import ImageRowItem from 'components/domain/member/ImageRowItem/ImageRowItem';
import EmptyState from 'components/foundation/state-templates/EmptyState/EmptyState';
import ErrorState from 'components/foundation/state-templates/ErrorState/ErrorState';
import { useDebounce } from 'hooks';
import useApolloMemberNameSearch from 'hooks/models/member/useApolloMemberNameSearch';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import { isEmpty, isNil, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassName } from 'types/ClassName';
import IMemberPermissionType from 'types/MemberPermissionType';
import { NonNullArrayField } from 'types/util/NonNullArrayField';
import { ALL_GROUPS, NO_GROUP } from 'utils/constants/stringConstants';
import LazyLoadCheckList from '../../../foundation/check-list/LazyLoadCheckList/LazyLoadCheckList';
import { IClockedInFilter, IScheduledFilter } from '../EmployeeMultiPicker/EmployeeMultiPicker';
import './EmployeeCheckList.scss';

export interface IEmployeeCheckListProps {
  readonly checkedItems: string[];
  enableGroups: boolean;
  enableSearch: boolean;
  permissions?: IMemberPermissionType;
  blacklistedIds?: string[];
  onSubmit: (members: string[]) => void;
  className?: ClassName;
  includeClockedIn?: boolean;
  includeClockedOut?: boolean;
  includeScheduled?: boolean;
  clockedInFilter?: IClockedInFilter;
  scheduledFilter?: IScheduledFilter;
  positionFilter?: PositionFilter;
}

export type EmployeeCheckListMember = NonNullArrayField<QueryFlatMemberNameQuery['members']>;

const EmployeeCheckList = ({
  checkedItems,
  enableGroups,
  enableSearch,
  permissions,
  onSubmit,
  blacklistedIds,
  className,
  includeClockedIn,
  includeClockedOut,
  includeScheduled,
  clockedInFilter,
  scheduledFilter,
  positionFilter,
}: IEmployeeCheckListProps) => {
  const [t] = useTranslation();

  const { clockedInProjectId, clockedInCostCodeId, clockedInEquipmentId } = clockedInFilter || {};

  const { scheduledProjectId, scheduledCostCodeId, scheduledEquipmentId, scheduledDateRestrictions, scheduledDate } =
    scheduledFilter || {};

  const [selectedGroup, setSelectedGroup] = useState<string | null>(ALL_GROUPS);
  const [members, setMembers] = useState<EmployeeCheckListMember[]>();
  const [error, setError] = useState(false);
  const [sectionSize, setSectionSize] = useState<number | null>(20);

  // Local state held onto for submitting
  const [checkedIds, setCheckedIds] = useState(checkedItems);
  const getApolloMemberNameSort = useApolloMemberNameSort();
  const getApolloMemberNameSearch = useApolloMemberNameSearch();

  function onCheckAll(isChecked: boolean, toCheck: EmployeeCheckListMember[]) {
    if (isChecked) {
      setSectionSize(null);
      const withoutAll = toCheck.map((check) => check.id).filter((checked) => checked !== 'ALL');
      setCheckedIds(uniq([...checkedIds, ...checkedItems, ...withoutAll]));
    } else {
      setCheckedIds([]);
    }
  }

  const [loadedAll, setLoadedAll] = useState(false);
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 250);
  const [checkOnLoad, setCheckOnLoad] = useState(false);

  const client = useApolloClient();

  const headerRef = useRef<HTMLDivElement>();
  const footerRef = useRef<HTMLDivElement>();

  function getClockStateFilter(): MemberFilterClockState | undefined {
    if (includeClockedOut === true) {
      return {
        state: MemberClockStateType.ClockedOut,
      };
    }

    if (includeClockedIn === true) {
      const filter: MemberFilterClockState = {
        state: MemberClockStateType.ClockedIn,
      };

      if (!isNil(clockedInProjectId)) {
        filter['projectIdWithDescendants'] = { equal: clockedInProjectId };
      }
      if (!isNil(clockedInCostCodeId)) {
        filter['costCodeId'] = { equal: clockedInCostCodeId };
      }
      if (!isNil(clockedInEquipmentId)) {
        filter['equipmentId'] = { equal: clockedInEquipmentId };
      }

      return filter;
    }

    return undefined;
  }

  function getHasScheduleFilter() {
    if (
      (isNil(includeScheduled) || !includeScheduled) &&
      isNil(scheduledProjectId) &&
      isNil(scheduledCostCodeId) &&
      isNil(scheduledEquipmentId) &&
      isNil(scheduledDateRestrictions)
    ) {
      return undefined;
    }

    const filter: MemberHasScheduleFilter = {};
    if (!isNil(scheduledProjectId)) {
      filter['projectIdWithDescendants'] = { equal: scheduledProjectId };
    }
    if (!isNil(scheduledCostCodeId)) {
      filter['costCodeId'] = { equal: scheduledCostCodeId };
    }
    if (!isNil(scheduledEquipmentId)) {
      filter['equipmentId'] = { equal: scheduledEquipmentId };
    }
    if (scheduledDateRestrictions === 'date' && scheduledDate?.value) {
      const start = new Date(
        scheduledDate.value.getFullYear(),
        scheduledDate.value.getMonth(),
        scheduledDate.value.getDate(),
        0,
        0,
        0,
        0
      );
      const end = new Date(
        scheduledDate.value.getFullYear(),
        scheduledDate.value.getMonth(),
        scheduledDate.value.getDate(),
        23,
        59,
        59,
        999
      );
      filter['startTime'] = start.toISOString();
      filter['endTime'] = end.toISOString();
    } else if (scheduledDateRestrictions === 'anytime') {
      const currentDate = DateTime.utc().startOf('day');
      const startDate = currentDate.set({ year: currentDate.get('year') - 1 });
      const endDate = currentDate.set({ year: currentDate.get('year') + 1 });
      filter['startTime'] = startDate.toISO();
      filter['endTime'] = endDate.toISO();
    }

    return filter;
  }

  async function getData(after?: string): Promise<EmployeeCheckListMember[]> {
    const results = await client.query<QueryFlatMemberNameQuery, QueryFlatMemberNameQueryVariables>({
      query: MEMBER_FLAT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: sectionSize,
        after,
        filter: {
          id: blacklistedIds ? { doesNotContain: blacklistedIds } : undefined,
          archivedOn: { isNull: true },
          permissions: permissions as NonNullable<QueryFlatMemberNameQueryVariables['filter']>['permissions'],
          search: searchDebounced !== '' ? getApolloMemberNameSearch(searchDebounced) : undefined,
          memberGroupId:
            selectedGroup === ALL_GROUPS
              ? undefined
              : selectedGroup === NO_GROUP
                ? { isNull: true }
                : { equal: selectedGroup },
          clockState: getClockStateFilter(),
          hasSchedule: getHasScheduleFilter(),
          position: positionFilter,
        },
        sort: getApolloMemberNameSort('asc') as unknown as MemberSort,
      },
    });

    return results.data.members ?? [];
  }

  useEffect(() => {
    setLoadedAll(false);
    setMembers([]);
  }, [searchDebounced, includeClockedIn, includeClockedOut, includeScheduled, clockedInFilter, scheduledFilter]);

  function didLoad(memberList: EmployeeCheckListMember[], err: boolean, loadedAllMembers: boolean) {
    setError(err);

    if (!err) {
      setLoadedAll(loadedAllMembers);
      setMembers(memberList);
    }

    if (checkOnLoad && onCheckAll) {
      onCheckAll(true, memberList);
    }
  }

  function onGroupSelectChange(id: string) {
    setCheckOnLoad(false);
    setMembers([]);
    setLoadedAll(false);
    setSelectedGroup(id);
  }

  function onSearchChange(searchString: string) {
    setCheckOnLoad(false);
    setSearch(searchString);
  }

  function handleSubmit() {
    onSubmit(checkedIds);
  }

  function setFooterRef(elt: HTMLDivElement) {
    footerRef.current = elt;
  }

  function setHeaderRef(elt: HTMLDivElement) {
    headerRef.current = elt;
  }

  function renderMemberItem(member: EmployeeCheckListMember) {
    return (
      <ImageRowItem
        key={member.id}
        firstName={member?.firstName ?? ''}
        lastName={member?.lastName ?? ''}
        search={search}
        imageUrl={member.imageUrl ?? undefined}
        numInitials={2}
      />
    );
  }

  function onCheckAllCurrentItems(isChecked: boolean) {
    if (members) {
      if (isChecked) {
        setCheckOnLoad(true);
      }
      onCheckAll(isChecked, members);
    }
  }

  function getMaxHeight() {
    return headerRef.current && footerRef.current
      ? `calc(100vh - ${headerRef.current.clientHeight + footerRef.current.clientHeight + 200}px)`
      : 'calc(100vh - 600px)';
  }

  const classes = classNames('employee-check-list', className);

  return (
    <div className={classes}>
      <div className="employee-check-list-header" ref={setHeaderRef}>
        {enableGroups && (
          <MemberGroupSelect
            className="px-6 pt-4"
            value={selectedGroup ? selectedGroup : 'ALL_GROUPS'}
            onChange={onGroupSelectChange}
          />
        )}
        {enableSearch && (
          <TextField
            className="px-6 py-4"
            onChange={onSearchChange}
            value={search}
            iconLeft={<Icon className="ml-6" svg={SearchMagnifyingGlass} />}
          />
        )}
        {(enableSearch || enableGroups) && <Divider />}
      </div>
      {error ? (
        <ErrorState />
      ) : members && isEmpty(members) && loadedAll ? (
        <EmptyState title={t('No Members')} />
      ) : (
        <div className="employee-check-list-content">
          <LazyLoadCheckList
            style={{ maxHeight: getMaxHeight() }}
            className="employee-check-list-lazy-loader"
            stroke={true}
            hover={true}
            data={members ? members : []}
            renderSize={sectionSize ?? 100}
            loadedAll={loadedAll}
            loadAll={sectionSize === null}
            renderItem={renderMemberItem}
            didLoad={didLoad}
            getData={getData}
            offset={100}
            onCheck={setCheckedIds}
            onCheckAll={onCheckAllCurrentItems}
            isCheckedAll={
              loadedAll &&
              members &&
              members.every((member) => checkedIds.some((checked) => checked === member.id) || member.id === 'ALL')
            }
            checkedItems={checkedIds}
          />
        </div>
      )}
      <div className="employee-check-list-footer" ref={setFooterRef}>
        <Divider />
        <Button type="primary" onClick={handleSubmit} className="my-4 mx-6">
          {t('Submit')}
        </Button>
      </div>
    </div>
  );
};

export default EmployeeCheckList;
