import useMemberGlobalSettingsUpdate from 'hooks/models/member/useMemberGlobalSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { cloneDeep } from 'lodash';
import keyBy from 'lodash/keyBy';
import { Nullable } from 'types/util/Nullable';
import { typedObjectKeys } from 'utils/objectUtils';
import { ITimeEntryTemplate } from '../types/types';

type DeMetaQuickTimeTemplate = Omit<ITimeEntryTemplate, 'position' | 'name'>;

export interface IQuickTimeTemplateKeyAdapter<T> {
  projectKey: keyof T;
  costCodeKey: keyof T;
  equipmentKey: keyof T;
  startTimeKey: keyof T;
  endTimeKey: keyof T;
  membersKey: keyof T;
  descriptionKey: keyof T;
  breakKey: keyof T;
}

export type QuickTimeTemplateAdapter<T> = Record<keyof DeMetaQuickTimeTemplate, keyof T>;

export function createQuickTimeTemplateAdapter<T>(
  adapter: IQuickTimeTemplateKeyAdapter<T>
): QuickTimeTemplateAdapter<T> {
  return {
    projectId: adapter.projectKey,
    costCodeId: adapter.costCodeKey,
    equipmentId: adapter.equipmentKey,
    members: adapter.membersKey,
    startTime: adapter.startTimeKey,
    endTime: adapter.endTimeKey,
    description: adapter.descriptionKey,
    breaks: adapter.breakKey,
  };
}

export function convertQuickTimeTemplate<T>(
  template: DeMetaQuickTimeTemplate,
  adapter: QuickTimeTemplateAdapter<T>
): T {
  return typedObjectKeys(template).reduce(
    (acc, cur) => ({
      ...acc,
      [adapter[cur]]: template[cur],
    }),
    {} as Partial<T>
  ) as T;
}

export default function useQuickTimeTemplates() {
  const memberSettings = useMemberSettings();
  const templateSetting = memberSettings?.global?.timeEntryTemplate;
  const templates = templateSetting?.values ?? {};
  const updateGlobalMemberSettings = useMemberGlobalSettingsUpdate();

  const upsert = (template: ITimeEntryTemplate) => {
    return updateGlobalMemberSettings('timeEntryTemplate', {
      default: templateSetting?.default ?? null,
      values: {
        ...memberSettings?.global?.timeEntryTemplate?.values,
        [template.name]: template,
      },
    });
  };

  const edit = async (name: string, template: Partial<ITimeEntryTemplate> & { name: string }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [name]: previous, ...others } = templates;
    const newName = template.name;
    const current = templates[name];
    const isDefault = name === templateSetting?.default;

    await updateGlobalMemberSettings('timeEntryTemplate', {
      default: isDefault ? newName : templateSetting?.default ?? null,
      values: {
        ...others,
        [newName]: {
          ...current,
          ...template,
        },
      },
    });
  };

  const set = (templates: ITimeEntryTemplate[]) => {
    const keyed = keyBy(templates, 'name');
    return updateGlobalMemberSettings('timeEntryTemplate', {
      default: templateSetting?.default ?? null,
      values: keyed,
    });
  };

  const remove = (template: ITimeEntryTemplate['name']) => {
    const cloned = cloneDeep(memberSettings?.global?.timeEntryTemplate);

    if (cloned && cloned.values[template]) {
      const isDefault = templateSetting?.default === template;
      delete cloned.values[template];
      return updateGlobalMemberSettings('timeEntryTemplate', { ...cloned, default: isDefault ? null : cloned.default });
    }
  };

  const setDefault = (name: Nullable<string>) => {
    return updateGlobalMemberSettings('timeEntryTemplate', {
      default: name,
      values: templates,
    });
  };

  return {
    defaultTemplate: templateSetting?.default ? templates[templateSetting.default] ?? null : null,
    templates,
    upsert,
    set,
    remove,
    edit,
    setDefault,
  };
}
