import { useApolloClient } from '@apollo/client';
import { Theme } from '@busybusy/webapp-react-ui';
import DashboardCardBarChart from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/DashboardBarChart';
import { dashboardCardBarChartGradientBlueBlue } from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/constants/constants';
import useDashboardAggregateData from 'components/domain/dashboard/dashboard-card-chart/hooks/useDashboardAggregateData';
import { TimeEntryActionBarContextProvider } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { ActivityReportGraphType } from 'containers/activity-reports/ActivityReportGraph/ActivityReportGraph';
import ActivityDetailsDialog from 'containers/activity-reports/activity-report-details/ActivityDetailsDialog/ActivityDetailsDialog';
import useEquipmentActivityReportColumns from 'containers/activity-reports/equipment-tables/hooks/useEquipmentActivityReportColumns';
import useActivityReportColumns from 'containers/activity-reports/hooks/useActivityReportColumns';
import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { EMPLOYEE_QUERY } from 'containers/manage-employees/employees-queries';
import ChannelContextProvider from 'contexts/ChannelContext/ChannelContext';
import { useActiveMember, useOpenable, usePermissions, useWeeklyPopulator } from 'hooks';
import useActivityReportNavigation from 'hooks/navigation/useActivityReportNavigation';
import { first, isNil, isNull, last } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateActivityReportTableColumns,
  updateEquipmentActivityReportTableColumns,
} from 'store/activityReports/ActivityReports';
import { IMember } from 'types';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import { ReportType } from 'utils/aggregateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import useDashboardCardTotal from '../hooks/useDashboardCardTotal';
import { getDashboardNavigationRange } from 'containers/scheduling/utils/utils';
import { convertDashboardTimeFrameToTimeRangeType } from 'containers/dashboard/util/utils';
import useEffectAfterFirstRender from 'hooks/utils/useEffectAfterFirstRender/useEffectAfterFirstRender';

export interface IAggregateHoursDashboardCardProps {
  label: string | null;
  theme: Theme.DARK | Theme.LIGHT;
  dataKey: 'total' | 'overtime' | 'doubletime';
  timeFrame: DashboardTimeFrame;
  memberId?: string | null;
  projectId?: string | null;
  costCodeId?: string | null;
  equipmentId?: string | null;
}

export default function AggregateHoursDashboardCard(props: IAggregateHoursDashboardCardProps) {
  const { label, theme, memberId, projectId, costCodeId, equipmentId, timeFrame, dataKey } = props;
  const { timeRange, timeRanges } = useDashboardGraphDates(timeFrame)!;

  const activityReportDetailsDialog = useOpenable();
  const activityReportDetailsTimeRange = useRef<ITimeRange<DateTime> | null>(null);
  const activityReportDetailsTimeRangeType = useRef<TimeRangeType | null>(null);
  const dispatch = useDispatch();
  const { availableColumns } = useActivityReportColumns();
  const { availableColumns: availableEquipmentColumns } = useEquipmentActivityReportColumns();

  useEffect(() => {
    dispatch(updateActivityReportTableColumns(availableColumns));
    dispatch(updateEquipmentActivityReportTableColumns(availableEquipmentColumns));
  }, []);

  const filterType = useMemo(() => {
    if (!isNil(memberId)) {
      return 'employee';
    } else if (!isNil(projectId)) {
      return 'project';
    } else if (!isNil(costCodeId)) {
      return 'costCode';
    } else if (!isNil(equipmentId)) {
      return 'equipment';
    }

    return 'employee';
  }, [memberId, projectId, costCodeId, equipmentId]);

  const filterId = useMemo(() => {
    return memberId ?? projectId ?? costCodeId ?? equipmentId ?? undefined;
  }, [memberId, projectId, costCodeId, equipmentId]);

  const { data, isLoading, error, refetch } = useDashboardAggregateData(
    timeRange,
    timeFrame,
    ReportType.SECONDS,
    MemberPermission.TIME_EVENTS,
    true,
    filterType,
    filterId
  );

  const total = useDashboardCardTotal(timeRanges, ReportType.SECONDS, dataKey, data, timeFrame, isLoading);
  const activeMember = useActiveMember();
  const client = useApolloClient();
  const { hasPermissionToManage } = usePermissions();
  const [canViewDetails, setCanViewDetails] = useState<boolean>(false);
  const isPro = useFeatureFlags('PRO');
  const currentTime = DateTime.utc().startOf('day');
  const weeklyRangeGet = useWeeklyPopulator();
  const activityReportNavigation = useActivityReportNavigation();

  useEffect(() => {
    getData();
  }, [memberId]);

  useEffectAfterFirstRender(() => {
    refetch();
  }, [timeFrame]);

  async function getData() {
    if (!isNil(memberId)) {
      const memberQuery = await client.query<{ members: IMember[] }>({
        query: EMPLOYEE_QUERY,
        variables: {
          memberId,
        },
      });

      const member = first(memberQuery.data.members)!;
      setCanViewDetails(hasPermissionToManage(member, 'manageTimeEntries'));
    } else {
      setCanViewDetails((activeMember.position?.manageTimeEntries ?? 0) > 0);
    }
  }

  function handleRightClick() {
    const navRange = getDashboardNavigationRange(timeRange, timeRanges, timeFrame);
    const timeRangeType = convertDashboardTimeFrameToTimeRangeType(timeFrame);

    if (!isNil(filterId)) {
      if (isPro) {
        activityReportDetailsTimeRangeType.current = timeRangeType;
        activityReportDetailsTimeRange.current = navRange;
        activityReportDetailsDialog.open();
      } else {
        activityReportDetailsTimeRangeType.current = TimeRangeType.WEEKLY;
        activityReportDetailsTimeRange.current = last(weeklyRangeGet(1, currentTime.toSeconds())) ?? null;
        activityReportDetailsDialog.open();
      }
    } else {
      let graphType = ActivityReportGraphType.TOTAL_HOURS;
      switch (dataKey) {
        case 'overtime':
          graphType = ActivityReportGraphType.OVERTIME_HOURS;
          break;
        case 'doubletime':
          graphType = ActivityReportGraphType.DOUBLETIME_HOURS;
          break;
      }

      if (isPro) {
        activityReportNavigation({
          filter_type: 'by-employee',
          time_range_type: timeRangeType,
          start_date: navRange.startTime.toISODate(),
          end_date: navRange.endTime.toISODate(),
          graph_type: graphType,
        });
      } else {
        const newRange = last(weeklyRangeGet(1, currentTime.toSeconds())) ?? null;
        activityReportNavigation({
          filter_type: 'by-employee',
          time_range_type: TimeRangeType.WEEKLY,
          start_date: newRange ? newRange.startTime.toISODate() : undefined,
          end_date: newRange ? newRange.endTime.toISODate() : undefined,
          graph_type: graphType,
        });
      }
    }
  }

  function getActivityReportFilterType() {
    if (!isNil(memberId)) {
      return ActivityReportType.BY_EMPLOYEE;
    } else if (!isNil(projectId)) {
      return ActivityReportType.BY_PROJECT;
    } else if (!isNil(costCodeId)) {
      return ActivityReportType.BY_COST_CODE;
    } else if (!isNil(equipmentId)) {
      return ActivityReportType.BY_EQUIPMENT;
    }

    return ActivityReportType.BY_EMPLOYEE;
  }

  return (
    <>
      {error === null ? (
        <DashboardContentCard
          header={total}
          subheaderValue={label}
          rightLabel={canViewDetails ? t('Details') : null}
          theme={theme}
          onRightLabelClick={canViewDetails ? handleRightClick : undefined}
        >
          {isLoading ? (
            <DashboardCardSpinner />
          ) : (
            <DashboardCardBarChart
              data={data}
              timeFrame={timeFrame}
              dataKey={dataKey}
              theme={theme}
              valueType="hour"
              {...dashboardCardBarChartGradientBlueBlue}
            />
          )}
        </DashboardContentCard>
      ) : (
        <DashboardErrorCard theme={theme} onClick={refetch} />
      )}
      {!isNil(filterId) &&
        !isNull(activityReportDetailsTimeRange.current) &&
        !isNull(activityReportDetailsTimeRangeType.current) && (
          <TimeEntryActionBarContextProvider>
            <ChannelContextProvider>
              {activityReportDetailsTimeRange.current && (
                <ActivityDetailsDialog
                  isOpen={activityReportDetailsDialog.isOpen}
                  onClose={() => {
                    activityReportDetailsTimeRangeType.current = null;
                    activityReportDetailsTimeRange.current = null;
                    activityReportDetailsDialog.close();
                  }}
                  timeRange={activityReportDetailsTimeRange.current}
                  timeRangeType={activityReportDetailsTimeRangeType.current}
                  filterType={getActivityReportFilterType()}
                  filterId={filterId}
                />
              )}
            </ChannelContextProvider>
          </TimeEntryActionBarContextProvider>
        )}
    </>
  );
}
