import _ from 'lodash';
import { DateTime } from 'luxon';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';
import { IUuidComparison } from 'types/UuidComparison';
import { TimestampComparison, UuidComparison } from '__generated__/graphql';
import { wrapElementIfNotArray } from './arrayUtils';

export function getLastCursor<T extends ICursorable>(values: T[]) {
  const last = _.last(values);
  return last ? last.cursor : null;
}

export function getGraphQlEqualComparison(value: Optional<string>): IUuidComparison | undefined {
  return value ? { equal: value } : undefined;
}

export function getUuidEqualComparisonWrapped(id: Optional<string>): { id: UuidComparison } | undefined {
  return id ? { id: getGraphQlEqualComparison(id)! } : undefined;
}

export function getGraphQlContainsComparison<T>(values: Optional<T | T[]>) {
  if (!values) {
    return undefined;
  }

  return { contains: wrapElementIfNotArray(values) };
}

export function getGraphQlDoesNotContainComparison<T>(values: Optional<T | T[]>) {
  if (!values) {
    return undefined;
  }

  return { doesNotContain: wrapElementIfNotArray(values) };
}

export function getGraphQlIdFilterVariables(id: Optional<string>) {
  return {
    filter: {
      id: getGraphQlEqualComparison(id),
    },
  } as const;
}

export const isNullGraphqlArgument = { isNull: true };
export const isNotNullGraphqlArgument = { isNull: false };

export const isArchivedFilter = { archivedOn: isNotNullGraphqlArgument };
export const isUnarchivedFilter = { archivedOn: isNullGraphqlArgument };

export function getGraphQlTimeRangeStartTimeEndTimeArgument(timeRange: ITimeRange<Nullable<DateTime>>) {
  return {
    startTime: timeRange.endTime
      ? { lessThanOrEqual: timeRange.endTime.toISO({ suppressMilliseconds: true }) }
      : undefined,
    endTime: timeRange.startTime
      ? { greaterThanOrEqual: timeRange.startTime.toISO({ suppressMilliseconds: true }) }
      : undefined,
  } as const;
}

export function getGraphQlTimeRangeTimestampArgument(
  timeRange: ITimeRange<Optional<DateTime>>
): Pick<TimestampComparison, 'between' | 'greaterThanOrEqual' | 'lessThanOrEqual'> | undefined {
  if (!timeRange.startTime && !timeRange.endTime) {
    return undefined;
  }

  if (timeRange.startTime && timeRange.endTime) {
    return { between: { start: timeRange.startTime.toISO(), end: timeRange.endTime.toISO() } };
  }

  if (timeRange.startTime) {
    return { greaterThanOrEqual: timeRange.startTime.toISO() };
  }

  if (timeRange.endTime) {
    return { lessThanOrEqual: timeRange.endTime.toISO() };
  }
}
