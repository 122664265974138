import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useMemberGroup } from 'hooks';
import { useEffect, useState } from 'react';
import { ALL_GROUPS, NO_GROUP } from 'utils/constants/stringConstants';
import { t } from 'utils/localize';

export interface IMemberGroupSelectProps extends Omit<ISelectProps, 'options'> {
  includeAllEmployees: boolean;
  includeNoGroup: boolean;
}

const MemberGroupSelect = (props: IMemberGroupSelectProps) => {
  const { className, includeAllEmployees, includeNoGroup, ...selectProps } = props;

  const [options, setOptions] = useState<ISelectOption[]>(
    includeAllEmployees ? [{ label: t('All Employees'), value: ALL_GROUPS }] : []
  );
  const memberGroupActions = useMemberGroup();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeAllEmployees, includeNoGroup]);

  async function getData() {
    const data = await memberGroupActions.getMemberGroups(null);

    if (data) {
      const tempOptions = [];
      if (includeAllEmployees) {
        tempOptions.push({ label: t('All Employees'), value: ALL_GROUPS });
      }

      tempOptions.push(...data.map((group) => ({ value: group.id, label: group.groupName })));

      if (includeNoGroup) {
        tempOptions.push({ label: t('No Group'), value: NO_GROUP });
      }

      setOptions(tempOptions);
    }
  }

  const classes = classNames('member-group-select', className);

  return <Select {...selectProps} options={options} className={classes} />;
};

MemberGroupSelect.defaultProps = {
  ...Select.defaultProps,
  includeAllEmployees: true,
  includeNoGroup: true,
};

export default MemberGroupSelect;
