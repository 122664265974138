/*
  http://docs.launchdarkly.com/docs/js-sdk-reference)
  https://launchdarkly.github.io/js-client-sdk/
*/
import { featuresTemplateKeys } from 'config/features';
import { launchDarklyFeatureKeys, launchDarklyFeatures } from 'config/features/launchDarkly';
import { processKeys } from 'config/features/process';
import { detect } from 'detect-browser';
import { useLocalStorage, useReduxSelector } from 'hooks';
import { initialize, LDClient, LDContext, LDFlagSet, LDFlagValue, LDOptions } from 'launchdarkly-js-client-sdk';
import {
  forEach,
  get,
  includes,
  intersection,
  mapKeys,
  memoize,
  pick,
  pickBy,
  snakeCase,
  toPairs,
  toUpper,
  union,
} from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setLaunchDarklySynced } from 'store/launchDarkly/launchDarkly';
import {
  IStoreAuthMember,
  IStoreEnvironment,
  IStoreOrganization,
  IStoreOrganizationSubscriptionStatus,
  IStoreSession,
  updateFeatureFlag,
} from 'store/store';
import { IFeatureFlags, TFlagKey } from 'types';
import { IChildrenProps } from 'types/ChildrenProps';
import { Nullable } from 'types/util/Nullable';
import { dateTimeFromUtcISO } from 'utils/dateUtils';
import { compactObject } from 'utils/objectUtils';

export interface IGetContextData {
  authMember?: IStoreAuthMember;
  environment?: IStoreEnvironment;
  session?: IStoreSession;
  organization?: IStoreOrganization;
  organizationSubStatus?: IStoreOrganizationSubscriptionStatus;
}

export type TFlagTuple = [string, LDFlagValue];

// create the LaunchDarkly private singleton
// tslint:disable-next-line: variable-name
let __ldclient: LDClient;
export const getClient = (): LDClient => __ldclient;
export const setClient = (client: LDClient): LDClient => {
  __ldclient = client;
  return __ldclient;
};

// the browser only needs to be detected once
const browser = detect();

export function createLdClient(ldContext: LDContext): LDClient {
  const environment = process.env as unknown as IStoreEnvironment;
  const apiKey = get(
    environment,
    environment.REACT_APP_ENV === 'beta'
      ? 'REACT_APP_LAUNCHDARKLY_CLIENTID'
      : 'REACT_APP_LAUNCHDARKLY_CLIENTID_PRODUCTION',
    'REACT_APP_LAUNCHDARKLY_CLIENTID_PRODUCTION'
  );

  const options: LDOptions = {
    streaming: true, // enables the onchange listener
  };

  const client: LDClient = initialize(apiKey, ldContext, options);
  return client;
}

function useReduxHasInitialized() {
  const authMember = useReduxSelector(({ authMember }) => authMember);
  const environment = useReduxSelector(({ environment }) => environment);
  const session = useReduxSelector(({ session }) => session);
  const organization = useReduxSelector(({ organization }) => organization);
  const organizationSubStatus = useReduxSelector(
    ({ organizationSubscriptionStatus }) => organizationSubscriptionStatus
  );

  return authMember && environment && organization && organizationSubStatus && session && session.isAuthenticated;
}

export function generateContext(contextData: IGetContextData): LDContext {
  const { authMember, environment, session, organization, organizationSubStatus } = contextData;

  if (authMember && environment && organization && organizationSubStatus && session && session.isAuthenticated) {
    return {
      key: authMember.id,
      avatar: authMember.imageUrl ?? undefined,
      email: authMember.email ?? undefined,
      firstName: authMember.firstName ?? undefined,
      lastName: authMember.lastName ?? undefined,
      custom: getCustomData({
        authMember: authMember as IStoreAuthMember,
        environment,
        organization,
        organizationSubStatus,
      }),
    };
  } else {
    return {
      anonymous: true,
      key: `logged-out-web-key`,
    };
  }
}

export type ILaunchDarklyCustomData = LDContext['custom'];
// export interface ILaunchDarklyCustomData extends ILDUserCustom {
//   isOwner: boolean;
//   organizationCreatedOn: number;
//   organizationId: string;
//   organizationName: string;
//   organizationNameId: string;

//   subscriptionCouponCode: string;
//   subscriptionCreatedOn: string;
//   subscriptionProductHandle: string;
//   subscriptionStatus: string;

//   device: string;
//   os: string;
//   platform: 'Web' | string;
//   product: 'Time' | string;
//   version: string;
//   webBuildMode: string;
//   webVersionNumber: string;
// }

export function getCustomData({
  authMember,
  environment,
  organization,
  organizationSubStatus,
}: {
  authMember: IStoreAuthMember;
  environment: IStoreEnvironment;
  organization: IStoreOrganization;
  organizationSubStatus: IStoreOrganizationSubscriptionStatus;
}): ILaunchDarklyCustomData {
  const isOwner = authMember.id === organization.ownedBy;
  const organizationName = organization.organizationName ? organization.organizationName : '';
  const organizationId = organization.id ? organization.id : '';
  const organizationNameId = `${organizationName}-${organizationId}`;

  const organizationCreatedOn = dateTimeFromUtcISO(organization.createdOn!).toMillis();

  const subscriptionCouponCode = organizationSubStatus?.couponCode ? organizationSubStatus?.couponCode : '';
  const subscriptionCreatedOn = organizationSubStatus?.createdOn
    ? dateTimeFromUtcISO(organizationSubStatus?.createdOn).toMillis()
    : null;
  const subscriptionProductHandle = organizationSubStatus?.productHandle ? organizationSubStatus?.productHandle : '';
  const subscriptionStatus = organizationSubStatus?.status ? organizationSubStatus?.status : '';

  const device = browser && browser.name ? browser.name : '';
  const os = browser && browser.os ? browser.os : '';

  const customData: ILaunchDarklyCustomData = compactObject({
    isOwner,
    organizationCreatedOn,
    organizationId,
    organizationName,
    organizationNameId,

    subscriptionCouponCode,
    subscriptionCreatedOn,
    subscriptionProductHandle,
    subscriptionStatus,

    device,
    os,
    platform: 'Web',
    product: 'Time',
    version: environment.REACT_APP_VERSION,
    webBuildMode: environment.REACT_APP_ENV,
    webVersionNumber: environment.REACT_APP_VERSION,
  });

  return customData;
}

export async function setUser(contextData: LDContext): Promise<LDFlagSet> {
  return getClient().identify(contextData);
}

// which feature flags should be ignored by launch darkly
// by default, these are keys set in .env, and flags set in config/features
const defaultExclude = [processKeys, featuresTemplateKeys];
export const getExcludedKeys = (keyArrays: TFlagKey[][] = defaultExclude) => union(...keyArrays).sort();

// return an array of feature flags with only the features that should be managed by launch darkly
//  return only the flags configured as implemented, exclude flags with template overrides
export const filterByApplicableFlags = (
  flags: LDFlagSet,
  excludedKeys: TFlagKey[] = getExcludedKeys(),
  implementedFeatures: IFeatureFlags = launchDarklyFeatures
): IFeatureFlags => {
  const implementedFeatureKeys = Object.keys(implementedFeatures) as TFlagKey[];
  const implementedFlags = filterByImplementedFlags(flags, implementedFeatureKeys);
  const applicableFlags = pickBy(implementedFlags, (v, key) => !includes(excludedKeys, key));

  return applicableFlags as IFeatureFlags;
};

export const filterByImplementedFlags = memoize(
  (flags: LDFlagSet, compareKeys: TFlagKey[] = launchDarklyFeatureKeys): IFeatureFlags => {
    const formattedFlags: IFeatureFlags = fromLaunchDarklyFlagFormat(flags);
    const formattedFlagKeys: TFlagKey[] = Object.keys(formattedFlags) as TFlagKey[];

    const implementedKeys: TFlagKey[] = intersection(formattedFlagKeys, compareKeys);
    const implementedFlags: IFeatureFlags = pick(formattedFlags, implementedKeys);

    return implementedFlags;
  }
);

export const fromLaunchDarklyFlagFormat = (flags: LDFlagSet): IFeatureFlags => {
  return mapKeys(flags, (v, key) => keyFormat(key));
};

export const keyFormat = (str: string): TFlagKey => {
  return toUpper(snakeCase(str as string)) as TFlagKey;
};

export const init = (client: LDClient): LDClient => {
  return setClient(client);
};

export function useUpdateFlagsWithLdClient(ldClient: Nullable<LDClient>) {
  const dispatch = useDispatch();
  return useCallback(() => {
    if (!ldClient) {
      return;
    }
    const applicableFlags: IFeatureFlags = filterByApplicableFlags(ldClient.allFlags());
    const tuples: TFlagTuple[] = toPairs(applicableFlags) as TFlagTuple[];

    forEach(tuples, (entry: TFlagTuple) => {
      const [k, v] = entry;
      const flag: IFeatureFlags = createFeatureFlag(k as TFlagKey, v);

      dispatch(updateFeatureFlag(flag));
    });
  }, [ldClient, dispatch]);
}

const createFeatureFlag = (key: TFlagKey, value: LDFlagValue): IFeatureFlags => {
  return { [key]: value };
};

/*
  LD flag defaults are configured in `/config/features/launchDarkly`

  Only flags designated in the configuration as being controlled by LD will be dispatched from here.

  If the flag has a value set in a .env file, this middleware will *NOT* overwrite that setting.
    .env flags must use the `REACT_APP_FEATURE_` prefix, be all caps, and use underscore spacing.
    A launch darkly flag of `photos-react` will map to `REACT_APP_FEATURE_PHOTOS_REACT`
    The list of ignored flags is imported as `processKeys` from `/config/features/process`

  This intentionally does not re-initialize LD managed feature flags.
  An unauthenticated user will already be defaulted to free features enabled and pro features disabled.

  This middleware dispatches updates when the user is authenticated or changed, and when LD sends change events
    change events can be triggered by a subscription state change, or manually targetting users
*/
export function LaunchDarkly({ children }: IChildrenProps) {
  const reduxHasInitialized = useReduxHasInitialized();

  const dispatch = useDispatch();

  const authMember = useReduxSelector(({ authMember }) => authMember);
  const environment = useReduxSelector(({ environment }) => environment);
  const session = useReduxSelector(({ session }) => session);
  const organization = useReduxSelector(({ organization }) => organization);
  const organizationSubStatus = useReduxSelector(
    ({ organizationSubscriptionStatus }) => organizationSubscriptionStatus
  );

  const [userShouldBeAuthenticated] = useLocalStorage('authentication', null);

  const client = useMemo(() => {
    if (reduxHasInitialized || !userShouldBeAuthenticated) {
      const context = generateContext({
        authMember: authMember as IStoreAuthMember,
        environment,
        organization,
        organizationSubStatus,
        session,
      });

      return init(createLdClient(context));
    } else {
      return null;
    }
  }, [
    authMember,
    environment,
    organization,
    organizationSubStatus,
    reduxHasInitialized,
    session,
    userShouldBeAuthenticated,
  ]);

  const updateFlags = useUpdateFlagsWithLdClient(client);

  useEffect(() => {
    client?.on('change', () => {
      updateFlags();
    });
  }, [updateFlags, client, dispatch]);

  useEffect(() => {
    async function initializeClient(initializedClient: LDClient) {
      try {
        await initializedClient.waitForInitialization();
        await initializedClient.waitUntilReady();
        updateFlags();
        dispatch(setLaunchDarklySynced());
      } catch (e) {
        // Need to allow the user to go through if there was an error
        dispatch(setLaunchDarklySynced());
      }
    }

    if (client) {
      initializeClient(client);
    }
  }, [client, dispatch, updateFlags]);

  return <>{children}</>;
}
