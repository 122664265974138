import { ApolloClient, useApolloClient } from '@apollo/client';
import { Align, Button, ButtonList, Dialog, Label, Position, Row, Size, Tray } from '@busybusy/webapp-react-ui';
import { MediaCollectionSourceTypes } from '__generated__/graphql';
import {
  ArrowBackIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  DownloadIcon,
  GpsIcon,
  InfoIcon,
} from 'assets/icons';
import classNames from 'classnames';
import { Header, MoreButton, Panel, PanelContent } from 'components';
import SimpleMapDialog from 'components/domain/map/SimpleMapDialog/SimpleMapDialog';
import ButtonTray from 'components/foundation/ButtonTray/ButtonTray';
import { useLoader } from 'contexts/LoaderContext';
import { useActiveMember, useOpenable } from 'hooks';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStorePhotos } from 'store/photos';
import { IReduxState, StoreAuthMemberProper } from 'store/types';
import { ClassName } from 'types/ClassName';
import { IMediaCollection } from 'types/MediaCollection';
import { IMediaEntry, MediaEntryType } from 'types/MediaEntry';
import IMediaEntryLocation from 'types/MediaEntryLocation';
import { Nullable } from 'types/util/Nullable';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { canManagePermissionForMember } from 'utils/positionUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getFullNameFromMember } from 'utils/stringUtils';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog/DeleteConfirmationDialog';
import PhotoDetailsReadOnly from '../PhotoDetailsReadOnly/PhotoDetailsReadOnly';
import {
  downloadMediaEntry,
  getCollectionForMediaFromCollections,
  getMediaEntriesFromCollections,
  loadMediaEntry,
} from '../photo-utilities/photo-utilities';
import PhotoActionButton from './PhotoActionButton/PhotoActionButton';
import PhotoCommentBar from './PhotoCommentBar/PhotoCommentBar';
import PhotoFullscreen from './PhotoFullscreen/PhotoFullscreen';
import './PhotoFullscreenViewer.scss';
import PhotoNavigationArrow from './PhotoNavigationArrow/PhotoNavigationArrow';
import VideoFullscreen from './VideoFullscreen/VideoFullscreen';

export interface IPhotoFullScreenViewerContainerProps {
  isOpen: boolean;
  currentMediaEntry?: IMediaEntry;
  deleteEntryHandler?: (entry: IMediaEntry, collection: IMediaCollection) => void;
  onDeleteAnnotations?: () => void;
  className?: ClassName;
  onClose: () => void;
}
export interface IPhotoFullScreenViewerProps extends IPhotoFullScreenViewerContainerProps {
  apolloClient: ApolloClient<any>;
  authMember?: StoreAuthMemberProper;
  mediaCollections: IMediaCollection[];
}

export const PhotoFullscreenViewerContainer: React.FC<IPhotoFullScreenViewerContainerProps> = (props) => {
  const apolloClient = useApolloClient();
  const authMember = useActiveMember();
  const { mediaCollections } = useSelector<IReduxState, IStorePhotos>((state) => state.photos);

  return (
    <PhotoFullscreenViewer
      {...props}
      apolloClient={apolloClient}
      authMember={authMember}
      mediaCollections={mediaCollections}
    />
  );
};

export const PhotoFullscreenViewer: React.FC<IPhotoFullScreenViewerProps> = (props) => {
  const {
    apolloClient,
    authMember,
    mediaCollections,
    isOpen,
    currentMediaEntry: currentMediaEntryProp,
    className,
    deleteEntryHandler,
    onDeleteAnnotations,
    onClose,
  } = props;

  const classes = classNames('photo-fullscreen-viewer', className);

  const infoDialog = useOpenable();
  const deleteDialog = useOpenable();
  const gpsDialog = useOpenable();
  const deleteAnnotationsDialog = useOpenable();
  const [performShake, setPerformShake] = useState(false);
  const [mediaEntries, setMediaEntries] = useState<IMediaEntry[]>(getMediaEntriesFromCollections(mediaCollections));
  const [currentMediaEntry, setCurrentMediaEntry] = useState<IMediaEntry | undefined>(currentMediaEntryProp);
  const [collection, setCollection] = useState<IMediaCollection | undefined>(
    currentMediaEntry ? getCollectionForMediaFromCollections(mediaCollections, currentMediaEntry) : undefined
  );
  const [memberCanManageCollection, setMemberCanManageCollection] = useState(false);
  const [isAnnotatedSelected, setIsAnnotatedSelected] = useState(true);
  const { open, close } = useLoader();

  const name = useMemo(() => {
    if (!isNil(collection) && collection.source === MediaCollectionSourceTypes.CompanyCam) {
      return 'CompanyCam';
    } else {
      return collection ? getFullNameFromMember(collection.createdByMember) : undefined;
    }
  }, [collection]);
  const project = collection ? getFormattedPathFromProject(collection.project, true) : undefined;

  useEffect(() => {
    if (currentMediaEntry) {
      setCollection(getCollectionForMediaFromCollections(mediaCollections, currentMediaEntry));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaEntry]);

  useEffect(() => {
    if (collection !== undefined) {
      setMemberCanManageCollection(
        canManagePermissionForMember('manageMediaCollection', authMember!, collection.createdByMember)
      );
    } else {
      setMemberCanManageCollection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  useEffect(() => {
    const newMediaEntries = getMediaEntriesFromCollections(mediaCollections);
    setMediaEntries(newMediaEntries);
  }, [mediaCollections]);

  useEffect(() => {
    if (currentMediaEntry === undefined) {
      setCurrentMediaEntry(currentMediaEntryProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaEntryProp]);

  const isPreviousDisabled = () => {
    return mediaEntries.indexOf(currentMediaEntry!) === 0;
  };

  const isNextDisabled = () => {
    return mediaEntries.indexOf(currentMediaEntry!) === mediaEntries.length - 1;
  };

  function shake() {
    setPerformShake(true);
    setTimeout(() => {
      setPerformShake(false);
    }, 300);
  }

  const previousClickHandler = () => {
    const currentIndex = mediaEntries.indexOf(currentMediaEntry!);
    if (currentIndex > 0) {
      const prevPhoto = mediaEntries[currentIndex - 1];
      return setCurrentMediaEntry(prevPhoto);
    } else {
      shake();
    }
  };

  const nextClickHandler = () => {
    const currentIndex = mediaEntries.indexOf(currentMediaEntry!);
    if (currentIndex < mediaEntries.length - 1) {
      const nextPhoto = mediaEntries[currentIndex + 1];
      return setCurrentMediaEntry(nextPhoto);
    } else {
      shake();
    }
  };

  const onCloseHandler = () => {
    setCurrentMediaEntry(undefined);
    onClose();
  };

  const downloadImageHandler = () => {
    open(t('Downloading Media...'));
    if (currentMediaEntry && collection) {
      // due to CORS issues, we need unused urls before trying to download the photos
      return loadMediaEntry(apolloClient, currentMediaEntry.id).then((freshMediaEntries) => {
        if (freshMediaEntries) {
          return downloadMediaEntry({
            encompassingCollection: collection!,
            mediaEntry: freshMediaEntries.shift()!,
          }).finally(() => {
            close();
          });
        }
      });
    }
  };

  function renderInfoDialog() {
    // TODO validate that the new iso timestamps are correct
    return (
      <>
        {currentMediaEntry && (
          <PhotoDetailsReadOnly
            date={
              currentMediaEntry.captureDate
                ? DateTime.fromISO(currentMediaEntry.captureDate, { zone: 'utc' }).toSeconds()
                : 0
            }
            description={collection ? collection.description : ''}
            isOpen={infoDialog.isOpen}
            layer={2}
            onClose={infoDialog.close}
            photoTaken={
              currentMediaEntry.captureDate
                ? DateTime.fromISO(currentMediaEntry.captureDate, { zone: 'utc' }).toSeconds()
                : 0
            }
            projectPath={project}
            uploadedBy={name ? name : ''}
            lastMarkUpOn={
              currentMediaEntry.lastAnnotatedAt
                ? DateTime.fromISO(currentMediaEntry.lastAnnotatedAt, { zone: 'utc' }).toSeconds()
                : 0
            }
            lastMarkUpBy={
              currentMediaEntry.lastAnnotatedBy ? getFullNameFromMember(currentMediaEntry.lastAnnotatedBy) : undefined
            }
            mediaCollection={currentMediaEntry.collection}
            type={currentMediaEntry.entryType!}
          />
        )}
      </>
    );
  }

  function getInfoWindowBody(location: IMediaEntryLocation, captureDate: Nullable<string>) {
    const body: ReactNode[] = [
      <p key={location.id}>
        {(captureDate ? dateTimeFromISOKeepZone(captureDate) : dateTimeFromISOKeepZone(location.timeLocal)).toFormat(
          DateTimeFormat.dateAtTime
        )}
      </p>,
    ];
    return body;
  }

  function renderMapDialog() {
    if (currentMediaEntry && currentMediaEntry.location) {
      return (
        <SimpleMapDialog
          isOpen={gpsDialog.isOpen}
          onClose={gpsDialog.close}
          size={Size.LARGE}
          pinLocations={[
            {
              id: currentMediaEntry.location.id,
              lat: currentMediaEntry.location.latitude,
              lng: currentMediaEntry.location.longitude,
              infoWindowTitle: project,
              infoWindowBody: getInfoWindowBody(currentMediaEntry.location, currentMediaEntry.captureDate ?? null),
            },
          ]}
        />
      );
    }
  }

  function deleteHandler() {
    currentMediaEntry && collection && deleteEntryHandler
      ? deleteEntryHandler(currentMediaEntry, collection)
      : onCloseHandler();

    if (!isPreviousDisabled()) {
      previousClickHandler();
    } else if (isPreviousDisabled() && !isNextDisabled()) {
      nextClickHandler();
    } else if (isPreviousDisabled() && isNextDisabled()) {
      onCloseHandler();
    }
    deleteDialog.close();
  }

  function handleDeleteAnnotations() {
    if (currentMediaEntry) {
      const newMediaEntries = [...mediaEntries];
      newMediaEntries[mediaEntries.findIndex((entry) => entry.id === currentMediaEntry.id)] = {
        ...currentMediaEntry,
        annotationFileUrl: undefined,
      };
      setCurrentMediaEntry({ ...currentMediaEntry, annotationFileUrl: undefined });
    }
    if (onDeleteAnnotations) {
      onDeleteAnnotations();
    }
    deleteAnnotationsDialog.close();
  }

  function renderDeleteDialog() {
    return (
      <DeleteConfirmationDialog
        isOpen={deleteDialog.isOpen}
        onClose={deleteDialog.close}
        onDelete={deleteHandler}
        title={t('Delete photo') + '?'}
      >
        {t('If you delete this photo it will be gone forever. Are you sure you want to continue?')}
      </DeleteConfirmationDialog>
    );
  }

  function renderHeader() {
    const gpsButton =
      currentMediaEntry && currentMediaEntry.location ? (
        <PhotoActionButton tooltip={t('Location')} svg={GpsIcon} onClick={gpsDialog.open} />
      ) : undefined;
    const deleteButton = memberCanManageCollection ? (
      <PhotoActionButton tooltip={t('Delete')} svg={DeleteIcon} onClick={deleteDialog.open} />
    ) : undefined;
    return (
      <Header className="header">
        <Tray>
          <PhotoActionButton tooltip={t('Close')} svg={ArrowBackIcon} onClick={onCloseHandler} />
        </Tray>
        <Tray>
          {gpsButton}
          {deleteButton}
          <PhotoActionButton tooltip={t('Info')} svg={InfoIcon} onClick={infoDialog.open} />
          <PhotoActionButton tooltip={t('Download')} svg={DownloadIcon} onClick={downloadImageHandler} />
        </Tray>
      </Header>
    );
  }

  const renderAnnotationsDropdown = () => {
    return (
      <ButtonList>
        <Button onClick={deleteAnnotationsDialog.open}>{t('Delete Annotations')}</Button>
      </ButtonList>
    );
  };

  const renderFooter = () => (
    <Header className="footer">
      {currentMediaEntry?.annotationFileUrl && (
        <div className={'annotated-button-tray pt-6'}>
          <ButtonTray spacing={0}>
            <Button type={isAnnotatedSelected ? 'primary' : 'secondary'} onClick={() => setIsAnnotatedSelected(true)}>
              {t('Annotated')}
            </Button>
            <Button type={isAnnotatedSelected ? 'secondary' : 'primary'} onClick={() => setIsAnnotatedSelected(false)}>
              {t('Original')}
            </Button>
            <MoreButton position={Position.BOTTOM_START} renderContent={renderAnnotationsDropdown} />
          </ButtonTray>
        </div>
      )}
      <div className={'photo-info'}>
        <Label className="fw-semi-bold pt-3">{name}</Label>
        <Label className="fw-light">{project}</Label>
      </div>
    </Header>
  );

  const renderMiddlePanel = () => (
    <>
      <PanelContent className="panel-content main">
        <Row align={Align.CENTER} className="middle-row">
          <PhotoNavigationArrow
            direction="left"
            disabled={isPreviousDisabled}
            icon={ChevronLeftIcon}
            onClick={previousClickHandler}
          />
          {currentMediaEntry?.entryType === MediaEntryType.VIDEO ? (
            currentMediaEntry?.fileUrl ? (
              <VideoFullscreen performShake={performShake} mediaEntry={currentMediaEntry} />
            ) : null
          ) : (
            <PhotoFullscreen
              performShake={performShake}
              photo={currentMediaEntry}
              showAnnotations={isAnnotatedSelected}
              annotationFileUrl={currentMediaEntry?.annotationFileUrl}
            />
          )}
          <PhotoNavigationArrow
            direction="right"
            disabled={isNextDisabled}
            icon={ChevronRightIcon}
            onClick={nextClickHandler}
          />
        </Row>
      </PanelContent>
    </>
  );

  const renderDeleteAnnotationsDialog = () => {
    return (
      <DeleteConfirmationDialog
        isOpen={deleteAnnotationsDialog.isOpen}
        onClose={deleteAnnotationsDialog.close}
        onDelete={handleDeleteAnnotations}
        title={t('Delete annotations') + '?'}
      >
        {t('If you delete this item it will be gone forever.  Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
    );
  };

  return (
    <Dialog hasDismiss={false} className={classes} size="full" isOpen={isOpen} onClose={onCloseHandler}>
      <Panel>
        {renderDeleteDialog()}
        {renderMapDialog()}
        {renderInfoDialog()}
        {renderDeleteAnnotationsDialog()}
        {renderHeader()}
        <Panel>
          <PanelContent>
            <Panel>
              {renderMiddlePanel()}
              {renderFooter()}
            </Panel>
            {currentMediaEntry && (
              <Panel strokeLeft={true} className={'side-panel'}>
                <PhotoCommentBar mediaEntryId={currentMediaEntry.id} className="comment-panel" />
              </Panel>
            )}
          </PanelContent>
        </Panel>
      </Panel>
    </Dialog>
  );
};

export default PhotoFullscreenViewerContainer;
