import { useCurrencyNumberFormatter } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { Duration } from 'luxon';
import { convertDurationToHoursDecimal, secondsToHmmString } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { IActivityReportRowInfo } from './ActivityReportData';

export default function useActivityReportExport() {
  const costFormatter = useCurrencyNumberFormatter('usd', { maximumFractionDigits: 2 })!;
  const canViewCost = useHasCostPermission();

  function getExportData<DataType extends IActivityReportRowInfo>(
    data: DataType[],
    customColumns: (row: DataType) => object
  ) {
    return data.map((row) => {
      const costData: any = {};
      if (canViewCost) {
        costData[t('Regular Cost')] = costFormatter.format(row.regularCost);
        costData[t('Overtime Cost')] = costFormatter.format(row.overtimeCost);
        costData[t('Double Time Cost')] = costFormatter.format(row.doubletimeCost);
        costData[t('Labor Burden')] = costFormatter.format(row.laborBurden);
        costData[t('Total Cost')] = costFormatter.format(row.totalCost);
      }
      // If we decide to allow import/reimport we should get rid of the translation
      // due to having to untranslate on reimporting the file.
      return {
        ...customColumns(row),
        [t('Regular Time')]: secondsToHmmString(row.regularHours),
        [t('Regular Time (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis(row.regularHoursDec * 1000),
          2
        ),
        [t('Overtime')]: secondsToHmmString(row.overtimeHours),
        [t('Overtime (Decimal)')]: convertDurationToHoursDecimal(Duration.fromMillis(row.overtimeHoursDec * 1000), 2),
        [t('Double Time')]: secondsToHmmString(row.doubleTimeHours),
        [t('Double Time (Decimal)')]: convertDurationToHoursDecimal(
          Duration.fromMillis(row.doubleTimeHoursDec * 1000),
          2
        ),
        [t('Total')]: secondsToHmmString(row.totalHours),
        [t('Total (Decimal)')]: convertDurationToHoursDecimal(Duration.fromMillis(row.totalHoursDec * 1000), 2),
        ...costData,
      };
    });
  }

  return getExportData;
}
