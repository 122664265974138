import { Reducer } from 'redux';
import { IStorePhotosCheckCollectionAction } from './checkCollection/photosCheckCollectionAction';
import { IStorePhotosCheckEntryAction } from './checkEntry/photosCheckEntryAction';
import { IStorePhotosClearCheckedCollectionsAction } from './clearCheckedCollections/photosClearCheckedCollectionsAction';
import { IStorePhotosClearCheckedEntriesAction } from './clearCheckedEntries/photosClearCheckedEntriesAction';
import { IStorePhotosClearDataAction } from './clearData/photosClearDataAction';
import { IStorePhotosDidLoadAction } from './didLoad/photosDidLoadAction';
import {
  PHOTOS_CHECK_COLLECTION,
  PHOTOS_CHECK_ENTRY,
  PHOTOS_CLEAR_CHECKED_COLLECTIONS,
  PHOTOS_CLEAR_CHECKED_ENTRIES,
  PHOTOS_CLEAR_DATA,
  PHOTOS_DID_LOAD,
  PHOTOS_SET_COLLECTIONS,
  PHOTOS_TOGGLE_GALLERY_VIEW,
} from './photosConstants';
import { IStorePhotos } from './photosType';
import { IStorePhotosSetCollectionsAction } from './setCollections/photosSetCollectionsAction';
import { IStorePhotosToggleGalleryViewAction } from './toggleGalleryView/toggleGalleryViewAction';

export type IPhotosActions =
  | IStorePhotosClearDataAction
  | IStorePhotosSetCollectionsAction
  | IStorePhotosDidLoadAction
  | IStorePhotosCheckCollectionAction
  | IStorePhotosCheckEntryAction
  | IStorePhotosToggleGalleryViewAction
  | IStorePhotosClearCheckedCollectionsAction
  | IStorePhotosClearCheckedEntriesAction;

const initialState: IStorePhotos = {
  checkedMediaCollections: [],
  checkedMediaEntries: [],
  error: false,
  galleryViewOpen: false,
  loadedAll: false,
  mediaCollections: [],
};

const photosReducers: Reducer<IStorePhotos, IPhotosActions> = (state = initialState, action) => {
  switch (action.type) {
    case PHOTOS_CLEAR_DATA:
      return {
        ...state,
        checkedMediaCollections: [],
        checkedMediaEntries: [],
        lastCheckedItem: null,
        error: false,
        loadedAll: false,
        mediaCollections: [],
      };
    case PHOTOS_SET_COLLECTIONS:
      return { ...state, mediaCollections: action.payload.mediaCollections };
    case PHOTOS_DID_LOAD:
      if (action.payload.error) {
        return { ...state, ...action.payload, mediaCollections: [] };
      } else {
        return { ...state, ...action.payload };
      }
    case PHOTOS_CHECK_COLLECTION:
      return {
        ...state,
        checkedMediaCollections: action.payload.mediaCollections,
      };
    case PHOTOS_CHECK_ENTRY:
      return {
        ...state,
        checkedMediaEntries: action.payload.mediaEntries,
      };
    case PHOTOS_TOGGLE_GALLERY_VIEW:
      return {
        ...state,
        ...action.payload,
        checkedMediaCollections: [],
        checkedMediaEntries: [],
        lastCheckedItem: null,
      };
    case PHOTOS_CLEAR_CHECKED_COLLECTIONS:
      return { ...state, checkedMediaCollections: [], lastCheckedItem: null };
    case PHOTOS_CLEAR_CHECKED_ENTRIES:
      return { ...state, checkedMediaEntries: [], lastCheckedItem: null };
    default:
      return state;
  }
};

export default photosReducers;
