import { Dispatch, Reducer, useCallback, useEffect, useReducer } from 'react';
import useOnMount from '../useOnMount/useOnMount';

export interface IAsyncState<T> {
  loading: boolean;
  data: T | null;
  error: string | null;
}

export enum AsyncActionType {
  START_REQUEST = 'START_REQUEST',
  SET_DATA = 'SET_DATA',
  SET_ERROR = 'SET_ERROR',
  CLEAR = 'CLEAR',
}

export type AsyncAction<T> =
  | { type: AsyncActionType.START_REQUEST; payload: { clearData: boolean } }
  | { type: AsyncActionType.SET_DATA; payload: { data: T } }
  | { type: AsyncActionType.SET_ERROR; payload: { error: string } }
  | { type: AsyncActionType.CLEAR };

export function asyncReducer<T>(state: IAsyncState<T>, action: AsyncAction<T>): IAsyncState<T> {
  if (action.type === AsyncActionType.START_REQUEST) {
    return {
      data: action.payload.clearData ? null : state.data, // Might not want to clear based on flashing ui.
      loading: true,
      error: null,
    };
  }

  if (action.type === AsyncActionType.SET_DATA) {
    return {
      data: action.payload.data,
      loading: false,
      error: null,
    };
  }

  if (action.type === AsyncActionType.SET_ERROR) {
    return {
      ...state,
      loading: false,
      error: action.payload.error,
    };
  }

  if (action.type === AsyncActionType.CLEAR) {
    return initial;
  }

  throw Error(`Unknown action: ${action}`);
}

const initial = {
  loading: false,
  data: null,
  error: null,
};

export type AsyncReducer<T> = Reducer<IAsyncState<T>, AsyncAction<T>>;
export interface IUseAsyncValue<T> extends IAsyncState<T> {
  execute: () => Promise<void>;
  dispatch: Dispatch<AsyncAction<T>>;
}

export type AsyncDispatch<T> = Dispatch<AsyncAction<T>>;

// https://usehooks.com/useAsync/
function useAsync<T>(
  asyncFunction: () => Promise<T>,
  immediate: boolean = false,
  reducer: AsyncReducer<T> = asyncReducer,
  clearOnRefetch: boolean = true,
  initialState: IAsyncState<T> = initial
): IUseAsyncValue<T> {
  const [state, dispatch] = useReducer<Reducer<IAsyncState<T>, AsyncAction<T>>>(reducer, initialState);

  const execute = useCallback(() => {
    dispatch({ type: AsyncActionType.START_REQUEST, payload: { clearData: clearOnRefetch } });
    return asyncFunction()
      .then((data) => {
        dispatch({ type: AsyncActionType.SET_DATA, payload: { data } });
      })
      .catch((error) => dispatch({ type: AsyncActionType.SET_ERROR, payload: { error } }));
  }, [dispatch, asyncFunction, clearOnRefetch]);

  useOnMount(() => {
    if (immediate) {
      execute();
    }
  });

  return { ...state, execute, dispatch };
}

export default useAsync;
