import { ClassName } from '@busybusy/webapp-react-ui';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { t } from 'utils/localize';
import EditEquipmentForm from '../EditEquipmentForm/EditEquipmentForm';

export interface IEditEquipmentFormDialogProps {
  equipmentId: string;
  editKey: string | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data?: string) => void;
  className?: ClassName;
}

const EditEquipmentFormDialog = (props: IEditEquipmentFormDialogProps) => {
  const { className, equipmentId, editKey, isOpen, onClose, onSubmit } = props;

  return (
    <HeaderDialog className={className} isOpen={isOpen} title={t('Edit Equipment')} onClose={onClose} divider={false}>
      <EditEquipmentForm equipmentId={equipmentId} editKey={editKey} onSubmit={onSubmit} />
    </HeaderDialog>
  );
};

export default EditEquipmentFormDialog;
