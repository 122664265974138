import gql from 'graphql-tag';

const EmployeesFragments = {
  details: gql`
    fragment EmployeeDetails on Member {
      id
      firstName
      lastName
      username
      email
      phone
      memberNumber
      archivedOn
      createdOn
      deletedOn
      updatedOn
      imageUrl
      positionId
      gustoEmployeeId
      gustoContractorId
      isSubContractor
      distantLocationFlaggingDisabled
      updatedByMember {
        id
        firstName
        lastName
      }
      memberLock {
        id
        effectiveDate
      }
      position {
        id
        title
        level
        memberLock
      }
      memberGroupId
      memberGroup {
        id
        groupName
      }
      memberOvertimePeriods {
        id
        changeDate
        adhereToOrg
        weeklyOvertime
        dailyOvertime
        dailyDoubletime
        seventhDayOvertime
        seventhDayDoubletime
        saturdayMultiplier
        sundayMultiplier
        createdOn
        updatedOn
        deletedOn
      }
      wageHistories {
        id
        memberId
        wage
        wageRate
        workWeek
        effectiveRate
        overburden
        changeDate
        createdOn
        submittedOn
        updatedOn
        deletedOn
      }
      pinCode
      timeLocationRequired
    }
  `,
};

export const EMPLOYEE_QUERY = gql`
  query queryEmployeeById($memberId: Uuid!) {
    members(filter: { id: { equal: $memberId } }) {
      cursor
      ...EmployeeDetails
    }
  }
  ${EmployeesFragments.details}
`;

export const employeesQuery = gql`
  query QueryEmployeesList($filter: MemberFilter!, $first: Int, $after: String, $sort: [MemberSort!]) {
    members(filter: $filter, first: $first, after: $after, sort: $sort) {
      cursor
      ...EmployeeDetails
    }
  }
  ${EmployeesFragments.details}
`;

export const createMemberMutation = gql`
  mutation createMemberWithDetails($member: MemberCreateInput!) {
    createMember(member: $member) {
      ...EmployeeDetails
    }
  }
  ${EmployeesFragments.details}
`;

export const updateMemberMutation = gql`
  mutation basicUpdateMember($member: MemberUpdateInput!) {
    updateMember(member: $member) {
      ...EmployeeDetails
    }
  }
  ${EmployeesFragments.details}
`;
