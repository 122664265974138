import gql from 'graphql-tag';

export const CREATE_MEMBER_GROUP = gql`
  mutation CreateMemberGroup($memberGroup: MemberGroupCreateInput) {
    createMemberGroup(memberGroup: $memberGroup) {
      id
    }
  }
`;

export const UPDATE_MEMBER_GROUP = gql`
  mutation UpdateMemberGroup($memberGroup: MemberGroupUpdateInput) {
    updateMemberGroup(memberGroup: $memberGroup) {
      id
    }
  }
`;
