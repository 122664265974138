import { UnknownAction } from 'redux';
import { isNavigationBarStatus, setNavigationBarStatus } from 'store/ui/NavigationBar/NavigationBar';
import {
  IframeMessageReceiverData,
  IframeMessageSenderData,
  IframeReceiverMessagePayload,
  LifecycleActionType,
  NavigationBarStatusPayload,
  iframeMessageReceiverTypes,
  iframeReceiverMessageActions,
  navigationBarActions,
} from '../types/types';

export function convertIframeMessageDataToAction(data: any): UnknownAction | null {
  if (!validateIframeBaseMessageData(data)) {
    return null;
  }

  const { type, payload } = data ?? { type: undefined, payload: undefined };

  switch (type) {
    case 'navigationBar': {
      if (validateIframeNavigationBarMessageData(payload)) {
        switch (payload.action) {
          case 'setNavigationBarStatus': {
            return setNavigationBarStatus(payload.status);
          }

          default: {
            console.error(`Iframe message action \`${payload.action}\` is not recognized`);
            break;
          }
        }
      }

      return null;
    }

    default:
      console.error(`Iframe message type \`${type}\` is not recognized`);
      return null;
  }
}

export function validateIframeBaseMessageData(data: any): data is IframeMessageReceiverData {
  const { type, payload } = data ?? { type: undefined, payload: undefined };

  if (type === undefined || !iframeMessageReceiverTypes.includes(type)) {
    // Webpack uses messaging events too so they will show up here.
    return false;
  }

  if (payload === undefined) {
    console.error('Iframe message payload is undefined');
    return false;
  }

  if (payload.action === undefined || !iframeReceiverMessageActions.includes(payload.action)) {
    console.error(`Unknown iframe message action ${payload.action}`);
    return false;
  }

  return true;
}

export function validateIframeNavigationBarMessageData<T extends IframeReceiverMessagePayload>(
  payload: T | undefined
): payload is NavigationBarStatusPayload & T {
  if (payload === undefined) {
    console.error('Iframe message payload is undefined');
    return false;
  }

  if (!navigationBarActions.includes(payload.action)) {
    console.error(`Unknown iframe message action ${payload.action}`);
    return false;
  }

  const payloadAsDesiredType = payload as unknown as { status?: any };

  if (payloadAsDesiredType.status === undefined || !isNavigationBarStatus(payloadAsDesiredType.status)) {
    console.error(`Unknown navigation bar status ${payloadAsDesiredType.status}`);
    return false;
  }

  return true;
}

function createIframeReceiverLifecycleAction(action: LifecycleActionType): IframeMessageSenderData {
  return {
    type: 'receiverLifecycle',
    payload: { action },
  };
}

export const iframeReceiverLifecycleInitializedAction = createIframeReceiverLifecycleAction('initialized');
export const iframeReceiverLifecycleDestroyedAction = createIframeReceiverLifecycleAction('destroyed');
