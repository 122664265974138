import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  LaborMetricsInterval,
  Maybe,
  Member,
  MemberLaborMetrics,
  Project,
  ProjectLaborMetricsInterface,
  Scalars,
} from '__generated__/graphql';
import { MEMBER_NAME_QUERY } from 'apollo/queries/member-queries';
import {
  SIMPLE_PROJECT_WITH_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_MEMBER_METRIC_AND_SUB_CHECK_QUERY,
} from 'apollo/queries/project-queries';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  encryptUnassignedProjectId,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from 'containers/activity-reports/hooks/useMemberActivityPermission';
import {
  MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import {
  PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils, flattenForSubProjectExport } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import _, { Dictionary, first, groupBy, isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import { mapNotNil, mapNotNull, sortByIgnoreCase, toggleSelection } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getMemberTimeOffMetric } from 'utils/jitMetricQueries';
import {
  combineMetricFilters,
  getRegularMetricLaborTotals,
  metricConditionFilter,
  metricNullFilter,
} from 'utils/jitMetricUtils';
import { t } from 'utils/localize';
import {
  aggregateLaborMetrics,
  getGeneratedMetricLaborTotals,
  getGeneratedProjectDescendantMetricLaborTotals,
  getGeneratedProjectMetricLaborTotals,
} from 'utils/metricUtils';
import { IProjectActivityTableRowInfo } from './useProjectActivity';

export interface IProjectMemberActivityTableRowInfo extends IProjectActivityTableRowInfo {
  memberId: string | null;
  member: Member | null;
  subprojectData?: IProjectMemberActivityTableRowInfo[];
  detailRows?: IProjectMemberActivityTableRowInfo[];
}

interface ProjectMemberMetricRow extends ProjectLaborMetricsInterface {
  projectId?: Maybe<Scalars['Uuid']['output']>;
  memberId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useProjectActivityCostCodeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>
) {
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const permission = useMemberActivityPermission();
  const { getAll } = useApolloPaging();
  const [data, setData] = useState<IProjectMemberActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IProjectMemberActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'project',
    SortDirection.ASCENDING,
    getSortField
  );
  const selectedProjectIds = useRef<string[]>([]);
  const formatEmployeeName = useEmployeeNameFormatter();

  function idFilter() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, true);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function query() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function subProjectQuery() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return SIMPLE_PROJECT_WITH_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return SIMPLE_PROJECT_WITH_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return SIMPLE_PROJECT_WITH_MEMBER_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getMemberQuery() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_PROJECT_METRIC_QUERY : MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function getMembers(memberIds: string[]) {
    if (isEmpty(memberIds)) {
      return [];
    }

    return await getAll<Member & ICursorable>('members', {
      query: MEMBER_NAME_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: memberIds },
          permissions: { permissions: permission, operationType: 'and' },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  function getSortField(item: IProjectMemberActivityTableRowInfo, key: keyof IProjectMemberActivityTableRowInfo) {
    if (key === 'project') {
      return item.project?.title;
    } else {
      return item[key];
    }
  }

  async function loadData() {
    let filter: object;

    if (filterType === ActivityReportType.BY_PROJECT) {
      filter = {
        parentProjectId: { equal: filterId },
      };
    } else {
      filter = {
        depth: { equal: 1 },
      };
    }

    const projects = await getAll<Project & ICursorable>('projectsWithTime', {
      query: query(),
      variables: {
        first: 100,
        filter,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...idFilter(),
        ...hasTimeIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const memberLookup = await getMemberLookup(projects);
    const tableRows = mapNotNull(projects, (project) => createTableRowInfo(memberLookup, project));

    const remainingData = await createNoProjectRow(tableRows);
    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    if (selectedProjectIds.current && !isEmpty(selectedProjectIds.current)) {
      // if we have subprojects selected then update the data with the sub project info
      setData(await updateDataWithSubprojectInfo(tableRows, selectedProjectIds.current));
    } else {
      setData(tableRows);
    }
  }

  const createTableRowInfo = (
    memberLookup: Dictionary<Member>,
    project: Project
  ): IProjectMemberActivityTableRowInfo | null => {
    const metrics = laborMetrics(project);

    const unsortedMemberRows: IProjectMemberActivityTableRowInfo[] = mapNotNull(metrics, (metric) => {
      const memberId = metric.memberId!; // member id can't be null here
      const metricTotal = getGeneratedProjectMetricLaborTotals(metric);
      const descendantData = getGeneratedProjectDescendantMetricLaborTotals(metric);
      if (
        (metricTotal.totalSeconds === 0 &&
          metricTotal.totalCost === 0 &&
          descendantData.totalSeconds === 0 &&
          descendantData.totalCost === 0) ||
        isNil(memberLookup[memberId])
      ) {
        return null;
      }

      return {
        id: project.id + memberId,
        memberId: memberId,
        member: memberLookup[memberId],
        project,
        isUnassigned: false,
        descendantData,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const memberRows = sortByIgnoreCase(unsortedMemberRows, (row) =>
      formatEmployeeName(row.member!.firstName ?? '', row.member!.lastName ?? '')
    );

    const restrictedMetrics = metrics.filter((metric) => isNil(memberLookup[metric.memberId!]));
    if (!isEmpty(restrictedMetrics)) {
      const restrictedData = restrictedMetrics.map((metric) => getGeneratedProjectMetricLaborTotals(metric));
      const restrictedDescendantData = restrictedMetrics.map((metric) =>
        getGeneratedProjectDescendantMetricLaborTotals(metric)
      );

      const restrictedTotalMetrics = aggregateLaborMetrics(restrictedData);
      const restrictedTotalDescendantMetrics = aggregateLaborMetrics(restrictedDescendantData);

      if (restrictedTotalMetrics.totalSeconds > 0) {
        memberRows.push({
          id: project.id + remainingDataItemId,
          memberId: remainingDataItemId,
          member: null,
          project,
          isUnassigned: false,
          descendantData: restrictedTotalDescendantMetrics,
          ...activityReportUtils.metricToRowInfo(restrictedTotalMetrics),
        });
      }
    }

    if (isEmpty(memberRows)) {
      return null;
    } else {
      const projectTotalMetrics = aggregateActivityRows(memberRows);
      const rowDecendants = mapNotNull(memberRows, (row) => row.descendantData);
      const projectTotalDecendantMetrics = aggregateLaborMetrics(rowDecendants);

      return {
        ...projectTotalMetrics,
        id: project.id,
        memberId: null,
        member: null,
        project,
        isUnassigned: false,
        descendantData: projectTotalDecendantMetrics,
        detailRows: memberRows,
      };
    }
  };

  const createNoProjectRow = async (
    tableRows: IProjectMemberActivityTableRowInfo[]
  ): Promise<IProjectMemberActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost);
    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Project row
    }

    let metricFilter = undefined;
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          filterId === remainingDataItemId
            ? metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull)
            : metricConditionFilter(ConditionFieldType.CostCodeId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_EQUIPMENT:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          filterId === remainingDataItemId
            ? metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull)
            : metricConditionFilter(ConditionFieldType.EquipmentId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        metricFilter = metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull);
        break;
      case ActivityReportType.BY_PROJECT:
        metricFilter = metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId);
        break;
    }

    const members = await getAll<Member & ICursorable>('members', {
      query: getMemberQuery(),
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            ...hasTimeIdFilter(),
            projectId: { isNull: true },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter,
      },
    });

    let timeOffMetrics: Dictionary<MemberLaborMetrics[]> | null = null;
    if (filterId === remainingDataItemId) {
      timeOffMetrics = groupBy(
        await getMemberTimeOffMetric(client, timeRange, canViewCost, LaborMetricsInterval.Custom),
        (metric) => metric.memberId
      );
    }

    const unsortedMemberRows: IProjectMemberActivityTableRowInfo[] = mapNotNull(members, (member) => {
      const memberMetric = getGeneratedMetricLaborTotals(first(memberLaborMetrics(member)));
      const memberTimeOffMetrics = getRegularMetricLaborTotals(
        first(timeOffMetrics?.[member.id]) as IJitLaborMetric | null | undefined
      );

      const metricTotal = aggregateLaborMetrics([memberMetric, memberTimeOffMetrics]);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + member.id,
        memberId: member.id,
        member,
        project: null,
        isUnassigned: false,
        descendantData: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    const memberRows = sortByIgnoreCase(unsortedMemberRows, (row) =>
      formatEmployeeName(row.member!.firstName ?? '', row.member!.lastName ?? '')
    );

    const restrictedMemberData = calculateRemainingActivityData(
      memberRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (restrictedMemberData.totalHours > 0) {
      // No Project Restricted Data Item
      memberRows.push({
        ...restrictedMemberData,
        id: remainingDataItemId + remainingDataItemId,
        memberId: remainingDataItemId,
        member: null,
        project: null,
        isUnassigned: false,
        descendantData: null,
      });
    }

    return {
      memberId: null,
      member: null,
      project: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: memberRows,
      isUnassigned: false,
      descendantData: null,
    };
  };

  const getMemberLookup = async (data: Project[]) => {
    const metrics = data.flatMap((project) => laborMetrics(project));
    const memberIds = mapNotNil(metrics, (item) => item.memberId);
    const members = await getMembers(uniq(memberIds));

    return keyBy(members, (member) => member.id);
  };

  const laborMetrics = (project: Project): ProjectMemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return project.projectMemberCostCodeLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return project.projectMemberLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return project.projectMemberEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectMemberLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const memberLaborMetrics = (member: Member): ProjectMemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return member.memberProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return member.memberProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberProjectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  async function updateDataWithSubprojectInfo(
    projectData: IProjectMemberActivityTableRowInfo[],
    selectedProjectIds: string[]
  ) {
    if (!isEmpty(projectData)) {
      let subprojectResult: Project[] = [];

      if (!_.isEmpty(selectedProjectIds)) {
        const projects = await getAll<Project & ICursorable>('projects', {
          query: subProjectQuery(),
          variables: {
            first: 100,
            filter: {
              parentProjectId: { contains: selectedProjectIds },
            },
            sort: [{ title: 'asc' }],
            metricsInterval: LaborMetricsInterval.Custom,
            metricsStartDate: timeRange.startTime.toISODate(),
            metricsEndDate: timeRange.endTime.toISODate(),
            ...idFilter(),
          },
          fetchPolicy: 'network-only',
        });

        subprojectResult = projects;
      }

      const memberLookup = await getMemberLookup(subprojectResult);
      const subprojectData = mapNotNull(subprojectResult, (project) => createTableRowInfo(memberLookup, project));
      const subprojectDataByParent = _.groupBy(subprojectData, (item) => item.project!.parentProjectId);

      return updateProjectDataWithSubprojectData(projectData, subprojectDataByParent);
    }

    return data;
  }

  function updateProjectDataWithSubprojectData(
    projectData: IProjectMemberActivityTableRowInfo[],
    subprojectDataByParent: Dictionary<IProjectMemberActivityTableRowInfo[]>
  ): IProjectMemberActivityTableRowInfo[] {
    return projectData
      .filter((item) => item.isUnassigned === false)
      .map((item) => {
        const subprojectItems = subprojectDataByParent[item.project!.id];
        if (subprojectItems && !_.isEmpty(subprojectItems)) {
          // the project is expanded, so add data for the project's subs
          // if we have existing subproject data on our item use that because it has the graph built in
          const subprojectData = updateProjectDataWithSubprojectData(
            !_.isEmpty(item.subprojectData) ? item.subprojectData! : subprojectItems,
            subprojectDataByParent
          );
          if (!_.isEmpty(subprojectData)) {
            // add the unassigned item if there are sub projects and the subs don't account for all of the time
            const unassignedItem = getUnassignedItem(item);
            if (unassignedItem.totalHours > 0 || unassignedItem.totalCost > 0) {
              item.subprojectData = subprojectData.concat(unassignedItem);
            } else {
              item.subprojectData = subprojectData;
            }
          } else {
            item.subprojectData = subprojectData;
          }
        } else if (!_.isNil(item.subprojectData)) {
          // the project is collapsed but we have subproject data, so remove it
          clearSubprojectData(item);
        }

        return item;
      });
  }

  const getUnassignedItem = (parentRow: IProjectMemberActivityTableRowInfo): IProjectMemberActivityTableRowInfo => {
    const unassignedKey = encryptUnassignedProjectId(parentRow.project!.id) + parentRow.memberId;
    const descendantData = parentRow.descendantData!;
    const detailRows = parentRow.detailRows
      ? mapNotNull(parentRow.detailRows, (detailRow) => {
        const unassignedRow = getUnassignedItem(detailRow);
        if (unassignedRow.totalHours > 0 || unassignedRow.totalCost > 0) {
          return unassignedRow;
        }

        return null;
      })
      : undefined;

    return {
      ...parentRow,
      id: unassignedKey,
      regularHours: parentRow.regularHours - descendantData.regularSeconds,
      regularHoursDec: parentRow.regularHoursDec - descendantData.regularSeconds,
      overtimeHours: parentRow.overtimeHours - descendantData.overtimeSeconds,
      overtimeHoursDec: parentRow.overtimeHoursDec - descendantData.overtimeSeconds,
      doubleTimeHours: parentRow.doubleTimeHours - descendantData.doubleTimeSeconds,
      doubleTimeHoursDec: parentRow.doubleTimeHoursDec - descendantData.doubleTimeCost,
      totalHours: parentRow.totalHours - descendantData.totalSeconds,
      totalHoursDec: parentRow.totalHoursDec - descendantData.totalSeconds,
      regularCost: parentRow.regularCost - descendantData.regularCost,
      overtimeCost: parentRow.overtimeCost - descendantData.overtimeCost,
      doubletimeCost: parentRow.doubletimeCost - descendantData.doubleTimeCost,
      laborBurden: parentRow.laborBurden - descendantData.laborBurden,
      totalCost: parentRow.totalCost - descendantData.totalCost,
      project: {
        ...parentRow.project!,
        id: encryptUnassignedProjectId(parentRow.project!.id),
        title: t('Unassigned'),
        depth: parentRow.project!.depth + 1,
        children: undefined,
      },
      isUnassigned: true,
      subprojectData: undefined,
      detailRows,
    };
  };

  function clearSubprojectData(projectData: IProjectMemberActivityTableRowInfo) {
    if (!_.isEmpty(projectData.subprojectData)) {
      projectData.subprojectData?.forEach((item) => clearSubprojectData(item));
    }

    // remove from selected ids in case the parent got toggled off and the child project was expanded
    selectedProjectIds.current = selectedProjectIds.current.filter((id) => id !== projectData.project!.id);

    projectData.subprojectData = undefined;
  }

  async function getExportDataWithSubprojects() {
    // in case there are expanded projects, filter them out
    // keep the no project item
    const rootProjects = data.filter((projectData) => isNil(projectData.project) || projectData.project?.depth === 1);
    const projectIds = mapNotNil(rootProjects, (project) => {
      if (!isEmpty(project.project?.children)) {
        return project.project?.id;
      } else {
        return null;
      }
    });

    const subprojectData = flattenForSubProjectExport(await updateDataWithSubprojectInfo(rootProjects, projectIds));
    if (remainingItemRef.current) {
      return subprojectData.concat(remainingItemRef.current);
    }

    return subprojectData;
  }

  const toggleSubProject = async (project: Project) => {
    selectedProjectIds.current = toggleSelection(selectedProjectIds.current, project.id);
    setData(await updateDataWithSubprojectInfo(data, selectedProjectIds.current));
  };

  function isProjectOpen(projectId: string) {
    return selectedProjectIds.current.some((pId) => {
      return pId === projectId;
    });
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    toggleSubProject,
    isProjectOpen,
    remainingData: remainingItemRef.current,
    getExportDataWithSubprojects,
  };
}
