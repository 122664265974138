import { Field, FieldFooter, IFormFieldDefaultProps, IFormRender, IFormValidation, Tray } from "@busybusy/webapp-react-ui";
import classNames from "classnames";
import { ClassName } from "types/ClassName";
import { DateTime } from "luxon";
import { isNil } from "lodash";
import { useState } from "react";
import { MonthNumber } from "types/Date";
import "./DateTrayPickerFormField.scss";
import MonthNonNullPicker from "components/foundation/pickers/MonthNonNullPicker/MonthNonNullPicker";
import NumberTextField from "components/foundation/text-field/NumberTextField/NumberTextField";
import { AnyObject } from "@busybusy/webapp-react-ui/dist/types/Object";

interface IDateTrayPickerFormFieldProps<FormType extends AnyObject>
extends IFormFieldDefaultProps<string, FormType> {
    form: IFormRender<FormType>;
    name: string;
    validations?: Array<IFormValidation<string, any>>;
    onChange?: (value: string | null) => void;
    className?: ClassName;
}

interface IDateTrayPickerFormFieldData {
    month: MonthNumber;
    day?: number;
    year?: number
}

function DateTrayPickerFormField<
    FormType extends {
    [field: string]: any;
    }
>(props: IDateTrayPickerFormFieldProps<FormType>) {
    const { form, name, validations, onChange, revalidateOnNewValidations, className } = props;

    const [data, setData] = useState<IDateTrayPickerFormFieldData>(!isNil(form.state.data[name]) ? {
        month: form.state.data[name]!.month,
        day: form.state.data[name]!.day,
        year: form.state.data[name]!.year
    } : {
        month: 1
    })

    function renderField({ ...fieldProps }) {
        function handleOnChangeMonth(value: MonthNumber) {
            setData({ ...data, month: value })
            if (!isNil(data.day) && !isNil(data.year)) {
                const dateTime: DateTime = DateTime.fromObject({ ...data, month: value })
                fieldProps.handleChange(dateTime, onChange);
            }
        }

        function handleOnChangeDay(value: string) {
            setData({ ...data, day: Number(value) })
            if (!isNil(data.day) && !isNil(data.year)) {
                const dateTime: DateTime = DateTime.fromObject({ ...data, day: Number(value) })
                fieldProps.handleChange(dateTime, onChange);
            }
        }

        function handleOnChangeYear(value: string) {
            setData({ ...data, year: Number(value) })
            if (!isNil(data.day) && !isNil(data.year)) {
                const dateTime: DateTime = DateTime.fromObject({ ...data, year: Number(value) })
                fieldProps.handleChange(dateTime, onChange);
            }
        }

        function isDayValueAllowed(value: string) {
            const isLeapYear = data.year ? (data.year % 4 === 0 && data.year % 100 !== 0) || (data.year % 400 === 0) : false; // default to non leap year days
            const maxDate = data.month === 2
            ? (isLeapYear ? 29 : 28)
            : ((
                data.month === 1 ||
                data.month === 3 ||
                data.month === 5 ||
                data.month === 7 ||
                data.month === 8 ||
                data.month === 10 ||
                data.month === 12)
                ? 31
                : 30);
            return (parseInt(value, 10) >= 1 && parseInt(value, 10) <= maxDate) || value === '';
        }

        function isYearValueAllowed(value: string) {
            return (parseInt(value, 10) >= 1 && parseInt(value, 10) <= 9999) || value === '';
        }

        return (
            <>
                <Tray>
                    <MonthNonNullPicker
                        value={data.month}
                        onSelect={handleOnChangeMonth}
                    />
                    <NumberTextField
                        value={data.day ? data.day.toString() : ''}
                        onChange={handleOnChangeDay}
                        isValueAllowed={isDayValueAllowed}
                        placeholder={'DD'}
                    />
                    <NumberTextField
                        value={data.year ? data.year.toString() : ''}
                        onChange={handleOnChangeYear}
                        isValueAllowed={isYearValueAllowed}
                        placeholder={'YYYY'}
                    />
                </Tray>
                <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
            </>
          );
    }

    const classes = classNames('date-tray-picker-form-field', className)

    return <Field
        className={classes}
        form={form} name={name}
        validations={validations}
        render={renderField}
        revalidateOnNewValidations={revalidateOnNewValidations}
    />;
}

export default DateTrayPickerFormField;