import gql from 'graphql-tag';

export const MEMBER_GROUPS_QUERY = gql`
  query MemberGroup($after: String, $filter: MemberGroupFilter, $first: Int) {
    memberGroups(after: $after, first: $first, filter: $filter, sort: [{ groupName: asc }]) {
      id
      organizationId
      groupName
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
    }
  }
`;
