import DevConfigLoader from 'containers/app/DevConfig/DevConfigLoader';
import { LoaderContextProvider, ToastContextProvider } from 'contexts';
import { IChildrenProps } from 'types/ChildrenProps';
import IntercomWrapper from './Intercom';
import { CommandPalletContextProvider } from 'contexts/CommandPalletContext/CommandPalletContext';

function CustomMiddleware({ children }: IChildrenProps) {
  return (
    <IntercomWrapper>
      <ToastContextProvider>
        <LoaderContextProvider>
          <CommandPalletContextProvider>
            <DevConfigLoader />
            {children}
          </CommandPalletContextProvider>
        </LoaderContextProvider>
      </ToastContextProvider>
    </IntercomWrapper>
  );
}

export default CustomMiddleware;
