import { useApolloClient } from '@apollo/client';
import { Loader, Position } from '@busybusy/webapp-react-ui';
import { SIMPLE_TIME_ENTRIES_QUERY, TIME_ENTRY_BY_ID_QUERY } from 'apollo/queries/time-entry-queries';
import classNames from 'classnames';
import AddTimeEntryForm from 'components/domain/time-entry/time-actions-form/AddTimeEntryForm/AddTimeEntryForm';
import ClockInAtForm from 'components/domain/time-entry/time-actions-form/ClockInAtForm/ClockInAtForm';
import ClockInForm from 'components/domain/time-entry/time-actions-form/ClockInForm/ClockInForm';
import ClockOutAtForm from 'components/domain/time-entry/time-actions-form/ClockOutAtForm/ClockOutAtForm';
import EditTimeEntryForm from 'components/domain/time-entry/time-actions-form/EditTimeEntryForm/EditTimeEntryForm';
import SwitchAtForm from 'components/domain/time-entry/time-actions-form/SwitchAtForm/SwitchAtForm';
import SwitchForm from 'components/domain/time-entry/time-actions-form/SwitchForm/SwitchForm';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import QuickTimeTemplateButton from 'containers/quick-time/QuickTimeTemplateButton/QuickTimeTemplateButton';
import { useQuickTimeTemplateMigration } from 'containers/quick-time/hooks/useQuickTimeTemplateMigration';
import useQuickTimeTemplates from 'containers/quick-time/hooks/useQuickTimeTemplates';
import { ITimeEntryTemplate } from 'containers/quick-time/types/types';
import { useDefaultTimes, useOpenable, useTimeRounding, useTimesheetsGraylog } from 'hooks';
import _, { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import { Nullable } from 'types/util/Nullable';
import { timeEntryUtils } from 'utils';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { dateTimeFromISOKeepZone, getFullTimezone } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import { logError } from 'utils/testUtils';
import { displayEndTime } from 'utils/timeEntryUtils';
import { convertDateTimeToDatePickerPayload } from 'utils/timeUtils';
import { ITimeActionsFormData } from '../hooks/useTimeActionsForm';
import { convertTemplateToTimeActionsFormData, convertTimeActionFormNoSelectionsToNull } from '../utils/utils';

export type EntryFormDialogType = 'add' | 'edit' | 'clock-in' | 'clock-in-at' | 'switch' | 'switch-at' | 'clock-out-at';

export interface ITimeEntryFormDialogProps {
  className?: ClassName;
  type: EntryFormDialogType;
  timeEntryId?: string | null;
  timeEntries: ITimeEntry[];
  memberIds: string[];
  projectId?: string | null;
  costCodeId?: string | null;
  equipmentId?: string | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ITimeActionsFormData) => void;
  onDelete: (data: ITimeActionsFormData) => void;
  currentDate?: DateTime | null;
  startTime?: DateTime | null;
  endTime?: DateTime | null;
}

const TimeActionsFormDialog = (props: ITimeEntryFormDialogProps) => {
  const {
    className,
    type,
    timeEntryId,
    timeEntries,
    memberIds,
    projectId,
    costCodeId,
    equipmentId,
    isOpen,
    onClose,
    onSubmit,
    onDelete,
    currentDate,
    startTime,
    endTime,
  } = props;
  const loaderDetails = useOpenable();
  const today = currentDate
    ? currentDate < DateTime.local().startOf('day')
      ? currentDate
      : DateTime.local().startOf('day')
    : DateTime.local().startOf('day');

  const client = useApolloClient();
  const { defaultTemplate } = useQuickTimeTemplates();
  const [formData, setFormData] = useState<ITimeActionsFormData | undefined>();
  const { roundTime } = useTimeRounding();
  const { defaultStartTime, defaultEndTime } = useDefaultTimes();
  const userEvents = useTimesheetsGraylog();
  const timeEntry = useRef<ITimeEntry | undefined | null>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentEntryAsCollection = useMemo(() => (timeEntry.current ? [timeEntry.current] : []), [timeEntry.current]);
  const quickTimeEnabled = useFeatureFlags('QUICK_TIME_TEMPLATES');
  // Only needed for templates but cannot have templates inside the AddTimeEntry form because it's a button in the header
  // updating via `setFormData` will cause problems with rerendering due to it causing recursion.
  // This field is only to keep track of what the form data looks like currently not to be passed to form.
  // TODO refactor so it's less like this
  const [updatedFormData, setUpdatedFormData] = useState<Nullable<ITimeActionsFormData | undefined>>(null);
  useQuickTimeTemplateMigration();

  useEffect(() => {
    setUpdatedFormData(formData);
  }, [formData]);

  useEffect(() => {
    if (isOpen && _.isNil(timeEntryId)) {
      if (_.isEmpty(memberIds) || memberIds.length === 1) {
        userEvents.events(TimesheetsTypes.events.action_type.ADD_ENTRY);
      } else {
        userEvents.events(TimesheetsTypes.events.action_type.ADD_ENTRIES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && (!_.isNil(timeEntryId) || timeEntries.length === 1)) {
      getFormData();
    } else if (isOpen && (_.isNil(timeEntryId) || _.isEmpty(timeEntries))) {
      getFormData();
    }
    if (!isOpen) {
      setFormData(undefined); // Reset if not open or timeEntryId is not set. For cases when multiple renders happen.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, timeEntryId, timeEntries]);

  async function getFormData() {
    if (!_.isNil(timeEntryId) || timeEntries.length === 1) {
      setFormData(await getTimeEntryFormData());
    } else {
      setFormData(await getDefaultFormData());
    }
  }

  async function getTimeEntryFormData() {
    if (loaderDetails.isOpen) {
      return; // If already loading then don't stack another sync.
    }
    loaderDetails.open();
    const entryId = !_.isNil(timeEntryId) ? timeEntryId : timeEntries[0].id;
    const results = await client
      .query<{ timeEntries: ITimeEntry[] }>({
        query: TIME_ENTRY_BY_ID_QUERY,
        variables: { timeEntryId: entryId },
      })
      .catch(logError);
    loaderDetails.close();
    timeEntry.current = results.data.timeEntries[0];

    const shouldPopulateDescription = type !== 'switch' && type !== 'switch-at';

    const timeEntryFormData: ITimeActionsFormData = {
      members: timeEntry.current?.memberId ? [timeEntry.current?.memberId] : [],
      timeRange: {
        startTime: roundTime(dateTimeFromISOKeepZone(timeEntry.current!.startTime!), ClockAction.CLOCK_IN),
        endTime: !_.isNil(timeEntry.current?.endTime)
          ? roundTime(displayEndTime(timeEntry.current!)!, ClockAction.CLOCK_OUT)
          : type === 'edit'
          ? DateTime.local()
          : roundTime(defaultEndTime, ClockAction.CLOCK_OUT),
      },
      startTime: roundTime(dateTimeFromISOKeepZone(timeEntry.current!.startTime!), ClockAction.CLOCK_IN),
      startDst: timeEntry.current!.daylightSavingTime,
      endDst: timeEntry.current!.metaDaylightSavingTime,
      endTime: !_.isNil(timeEntry.current?.endTime)
        ? roundTime(displayEndTime(timeEntry.current!)!, ClockAction.CLOCK_OUT)
        : roundTime(DateTime.local(), ClockAction.CLOCK_OUT),
      startDate: {
        value: timeEntry.current?.startTime
          ? dateTimeFromISOKeepZone(timeEntry.current.startTime).toJSDate()
          : today.toJSDate(),
        inputValue: today.toFormat('M/D/YYYY'),
      },
      endDate: {
        value: !_.isNil(timeEntry.current?.endTime) ? displayEndTime(timeEntry.current!)!.toJSDate() : today.toJSDate(),
        inputValue: today.toFormat('M/D/YYYY'),
      },
      breaks: timeEntry.current?.breaks
        ? timeEntry
            .current!.breaks!.filter((brk) => {
              return brk.deletedOn === null;
            })
            .flatMap((brk) => {
              return {
                id: brk.id,
                isOpen: isNil(brk.endTime),
                timeRange: {
                  startTime: !isNil(brk.startTime)
                    ? roundTime(dateTimeFromISOKeepZone(brk.startTime!), ClockAction.CLOCK_IN)
                    : roundTime(dateTimeFromISOKeepZone(brk.startTime!), ClockAction.CLOCK_IN),
                  endTime: !isNil(brk.endTime)
                    ? roundTime(dateTimeFromISOKeepZone(brk.endTime!), ClockAction.CLOCK_OUT)
                    : DateTime.local(),
                },
                startDst: brk.startTimeDst,
                endDst: brk.endTimeDst,
              };
            })
        : [],
      project: timeEntry.current?.project?.id,
      costCode: timeEntry.current?.costCode?.id,
      equipment: timeEntry.current?.equipment?.id,
      description: shouldPopulateDescription
        ? timeEntry.current?.description !== null
          ? timeEntry.current?.description
          : undefined
        : undefined,
      total: timeEntry.current ? timeEntryUtils.getTotal(timeEntry.current) : 0,
      timezone: !isNil(timeEntry.current!.startTime)
        ? getFullTimezone(
            dateTimeFromISOKeepZone(timeEntry.current!.startTime!),
            dateTimeFromISOKeepZone(timeEntry.current!.startTime!).offset,
            dateTimeFromISOKeepZone(timeEntry.current!.startTime!).toLocal().isInDST
          )?.name
        : getFullTimezone(DateTime.now(), DateTime.now().offset, DateTime.now().isInDST)?.name,
    };
    return timeEntryFormData;
  }

  async function getDefaultFormData() {
    loaderDetails.open();
    let start = roundTime(defaultStartTime, ClockAction.CLOCK_IN);
    if (memberIds.length === 1) {
      const lastEntry = await getLastClosedEntry(memberIds[0], {
        startTime: today.startOf('day'),
        endTime: today.endOf('day'),
      });
      if (!_.isNull(lastEntry) && lastEntry.endTime) {
        start = DateTime.fromISO(lastEntry.endTime);
      }
    } else if (type === 'clock-in' || type === 'clock-in-at') {
      start = roundTime(DateTime.local(), ClockAction.CLOCK_IN);
    }

    const startTarget = startTime ? startTime : start;
    const defaultTimeRange: ITimeRange<DateTime> = {
      startTime: startTarget,
      endTime: endTime
        ? endTime
        : roundTime(defaultEndTime, ClockAction.CLOCK_OUT).set({
            year: startTarget.year,
            month: startTarget.month,
            day: startTarget.day,
          }),
    };

    const templateFormData = defaultTemplate
      ? convertTemplateToTimeActionsFormData(defaultTemplate, {
          members: memberIds ?? [],
          startDate: convertDateTimeToDatePickerPayload(today),
          endDate: convertDateTimeToDatePickerPayload(today),
        })
      : undefined;

    const isAdding = type === 'add';

    function getTemplateValueIfAdding<K extends keyof ITimeActionsFormData>(key: K, value: ITimeActionsFormData[K]) {
      return isAdding ? templateFormData?.[key] ?? value : value;
    }

    const defaultFormData: ITimeActionsFormData = {
      members: memberIds && !isEmpty(memberIds) ? memberIds : getTemplateValueIfAdding('members', memberIds),
      timeRange: startTime || endTime ? defaultTimeRange : getTemplateValueIfAdding('timeRange', defaultTimeRange),
      startTime: startTime
        ? startTime
        : getTemplateValueIfAdding('startTime', roundTime(DateTime.local(), ClockAction.CLOCK_IN)),
      endTime: endTime
        ? endTime
        : getTemplateValueIfAdding('endTime', roundTime(DateTime.local(), ClockAction.CLOCK_OUT)),
      startDate: { value: today.toJSDate(), inputValue: today.toFormat('M/D/YYYY') },
      endDate: { value: today.toJSDate(), inputValue: today.toFormat('M/D/YYYY') },
      breaks: templateFormData?.breaks ?? [],
      project: isAdding ? projectId ?? templateFormData?.project : undefined,
      costCode: isAdding ? costCodeId ?? templateFormData?.costCode : undefined,
      equipment: isAdding ? equipmentId ?? templateFormData?.equipment : undefined,
      description: isAdding ? templateFormData?.description : undefined,
      total: templateFormData?.total ?? timeEntryUtils.getTotalFromDateTimes(defaultTimeRange, []),
      timezone: getFullTimezone(DateTime.now(), DateTime.now().offset, DateTime.now().isInDST)?.name,
    };

    loaderDetails.close();
    return defaultFormData;
  }

  async function getLastClosedEntry(memberId: string, range: ITimeRange<DateTime>) {
    const results = await client
      .query<{ timeEntries: ITimeEntry[] }>({
        query: SIMPLE_TIME_ENTRIES_QUERY,
        variables: {
          first: 1,
          filter: {
            startTime: {
              lessThanOrEqual: range.endTime
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false, suppressSeconds: true }),
            },
            endTime: {
              greaterThanOrEqual: range.startTime
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false, suppressSeconds: true }),
            },
            memberId: { equal: memberId },
            deletedOn: { isNull: true },
          },
          sort: [{ startTime: 'desc' }, { createdOn: 'desc' }],
        },
        fetchPolicy: 'network-only',
      })
      .catch(logError);
    if (_.isEmpty(results.data.timeEntries)) {
      return null;
    }
    return results.data.timeEntries[0];
  }

  function getTitle(): string {
    switch (type) {
      case 'add':
        return t('Create Time Entry');
      case 'edit':
        return t('Edit Time Entry');
      case 'clock-in':
        return t('Clock In');
      case 'clock-in-at':
        return t('Clock In At');
      case 'switch':
        return t('Switch');
      case 'switch-at':
        return t('Switch At');
      case 'clock-out-at':
        return t('Clock Out At');
    }
  }

  function getSubtitle() {
    switch (type) {
      case 'edit':
        if (!isNil(timeEntry.current)) {
          return fullName(timeEntry.current.member);
        }
        break;
      default:
        if (isMulti()) {
          return timeEntries.length + ' ' + t('selected');
        }
    }
  }

  function isOpenEntry(): boolean {
    return (
      (timeEntryId !== null || timeEntries.length === 1) &&
      !_.isNil(timeEntry.current) &&
      _.isNil(timeEntry.current.endTime)
    );
  }

  function isSingle(): boolean {
    return (
      (timeEntryId !== null && !_.isNil(timeEntry.current)) ||
      (!_.isNil(timeEntry.current) && timeEntries.length === 1) ||
      (timeEntryId === null && timeEntries.length === 0)
    );
  }

  function isMulti(): boolean {
    return timeEntries.length > 1;
  }

  function handleSubmit(formData: ITimeActionsFormData) {
    return onSubmit(convertTimeActionFormNoSelectionsToNull(formData));
  }

  function renderAction() {
    if (formData) {
      switch (type) {
        case 'add':
          return (
            <AddTimeEntryForm
              memberIds={memberIds}
              formData={formData}
              onSubmit={handleSubmit}
              onChange={setUpdatedFormData}
            />
          );
        case 'edit':
          if (isSingle()) {
            return (
              <EditTimeEntryForm
                type={type === 'edit' && isOpenEntry() ? 'edit-open' : type}
                timeEntry={timeEntry.current}
                memberIds={memberIds}
                formData={formData}
                onSubmit={handleSubmit}
                onDelete={onDelete}
              />
            );
          }
          return <></>;
        case 'clock-in':
          return <ClockInForm memberIds={memberIds} formData={formData} onSubmit={handleSubmit} />;
        case 'clock-in-at':
          return <ClockInAtForm memberIds={memberIds} formData={formData} onSubmit={handleSubmit} />;
        case 'switch':
          return (
            <SwitchForm
              memberIds={memberIds}
              formData={formData}
              onSubmit={handleSubmit}
              timeEntries={isSingle() ? currentEntryAsCollection : timeEntries}
            />
          );
        case 'switch-at':
          return (
            <SwitchAtForm
              memberIds={memberIds}
              formData={formData}
              onSubmit={handleSubmit}
              timeEntries={isSingle() ? currentEntryAsCollection : timeEntries}
            />
          );
        case 'clock-out-at':
          return (
            <ClockOutAtForm
              memberIds={memberIds}
              formData={formData}
              onSubmit={handleSubmit}
              timeEntries={isSingle() ? currentEntryAsCollection : timeEntries}
            />
          );
      }
    }
    return <></>;
  }

  function onTemplateSelect(template: ITimeEntryTemplate) {
    const result = convertTemplateToTimeActionsFormData(template, updatedFormData as any);
    setFormData(result);
  }

  const classes = classNames('time-entry-form-dialog', className, {
    'is-loading': loaderDetails.isOpen,
    'is-loaded': !loaderDetails.isOpen,
  });

  return (
    <>
      <Loader isOpen={loaderDetails.isOpen} />
      <HeaderDialog
        isOpen={isOpen}
        title={getTitle()}
        subtitle={getSubtitle()}
        onClose={onClose}
        rightContent={
          type === 'add' && quickTimeEnabled ? (
            <QuickTimeTemplateButton onTemplateSelect={onTemplateSelect} position={Position.BOTTOM_END} />
          ) : undefined
        }
        divider={false}
        className={classes}
      >
        <div>{renderAction()}</div>
      </HeaderDialog>
    </>
  );
};

TimeActionsFormDialog.defaultProps = {
  timeEntryId: null,
  timeEntries: [],
  memberIds: [],
};

export default TimeActionsFormDialog;
