import { Reducer } from 'redux';
import { PROJECTS_SET_SELECTED_PROJECT, IStoreProjects, IStoreProjectsSetSelectedProjectAction } from '../projects';

export type IProjectsActions = IStoreProjectsSetSelectedProjectAction;

const initialState: IStoreProjects = {
  selectedProject: null,
};

const projectsReducers: Reducer<IStoreProjects, IProjectsActions> = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTS_SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.payload.selectedProject,
      };
    default:
      return state;
  }
};

export default projectsReducers;
