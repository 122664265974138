import { Icon, IListProps, List, ListItem } from '@busybusy/webapp-react-ui';
import { DragHandle } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { cloneDeep } from 'lodash';
import { Fragment, ReactNode, useCallback } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import IIdable from 'types/Idable';
import DraggableItem from '../draggable-item/DraggableItem';
import './DraggableList.scss';

export type DraggablePayload<T extends IIdable<Id>, Id extends string = string> = T & {
  draggable: boolean;
  disabled?: boolean;
};

export interface IUpdatedDraggable<T extends IIdable<Id>, Id extends string = string> {
  index: number;
  payload: DraggablePayload<T>;
}

export interface IDraggableListProps<K extends IIdable<Id>, T extends DraggablePayload<K>, Id extends string = string>
  extends Omit<IListProps, 'onClick'> {
  items: T[];
  onClick?: (item: T) => void;
  renderItem: (item: T) => ReactNode;
  onUpdate: (updatedItems: Array<IUpdatedDraggable<T, Id>>) => void;
  itemClass?: ClassName;
  ripple?: boolean; // disable ripple to make interactive child elements intractable
}

function DraggableList<K extends IIdable<Id>, T extends DraggablePayload<K>, Id extends string = string>({
  items,
  onUpdate,
  onClick,
  renderItem,
  className,
  itemClass,
  ripple,
  ...listProps
}: IDraggableListProps<K, T, Id>) {
  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newlyOrderedItems = reorder(items, result.source.index, result.destination.index);

      onUpdate(
        newlyOrderedItems.map((payload, index) => ({
          payload,
          index,
        }))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, onUpdate]
  );

  const onItemClick = (item: T) => {
    return () => {
      onClick?.(item);
    };
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ textOverflow: 'unset' }}
            className={classNames('draggable-list', className)}
          >
            <List {...listProps}>
              {items.map((item, index) => {
                return (
                  <Fragment key={item.id}>
                    {item.draggable ? (
                      <DraggableItem
                        key={item.id}
                        id={item.id}
                        index={index}
                        className={itemClass}
                        onClick={onClick ? onItemClick(item) : undefined}
                        disabled={item.disabled}
                        ripple={ripple}
                      >
                        {renderItem(item)}
                        <Icon
                          svg={DragHandle}
                          className={classNames('drag-handle', { 'disabled-handle': item.disabled })}
                        />
                      </DraggableItem>
                    ) : (
                      <ListItem className={itemClass} key={item.id} onClick={onClick ? onItemClick(item) : undefined}>
                        {renderItem(item)}
                      </ListItem>
                    )}
                  </Fragment>
                );
              })}
            </List>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableList;
