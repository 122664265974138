import { Icon, IIconProps, Size, Theme, Tooltip } from '@busybusy/webapp-react-ui';

export interface ITooltipIconProps extends IIconProps {
  tooltipLabel: string;
}

function TooltipIcon({ tooltipLabel, ...iconProps }: ITooltipIconProps) {
  return (
    <Tooltip label={tooltipLabel}>
      <Icon {...iconProps} />
    </Tooltip>
  );
}

TooltipIcon.defaultProps = {
  size: Size.MEDIUM,
  iconTheme: Theme.DARK,
};

export default TooltipIcon;
