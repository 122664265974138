import { useApolloClient } from '@apollo/client';
import { MediaEntryComment } from '__generated__/graphql';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export const useMediaEntryComments = (mediaEntryId: string) => {
  const client = useApolloClient();

  return useCallback(async () => {
    const results = await client.query<{ mediaEntryComments: MediaEntryComment[] }>({
      query: MEDIA_ENTRY_COMMENT_QUERY,
      variables: { filter: { mediaEntryId: { equal: mediaEntryId }, deletedOn: { isNull: true } } },
      fetchPolicy: 'network-only',
    });
    return results.data.mediaEntryComments ?? [];
  }, [client, mediaEntryId]);
};

export const MEDIA_ENTRY_COMMENT_QUERY = gql`
  query MediaEntryComments($after: String, $filter: MediaEntryCommentFilter) {
    mediaEntryComments(after: $after, filter: $filter, sort: [{ createdOn: desc }]) {
      id
      comment
      mediaEntryId
      createdByMember {
        id
        firstName
        lastName
        imageUrl
        position {
          id
          level
        }
      }
      createdOn
      deletedOn
      updatedOn
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
    }
  }
`;
