import { Align, Justify, KeyCode, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useOpenable } from 'hooks';
import * as React from 'react';
import { ReactNode, useRef } from 'react';
import './StyledAccordion.scss';

export interface IStyledAccordionProps {
  title: string;
  error?: boolean;
  openByDefault?: boolean;
  rightChildren?: ReactNode;
  children: ReactNode;
  className?: ClassName;
}

function StyledAccordion({ title, error, children, openByDefault, rightChildren, className }: IStyledAccordionProps) {
  const toggleDetails = useOpenable({ isOpen: openByDefault });
  const ref = useRef<HTMLDivElement | null>(null);

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.keyCode === KeyCode.ENTER) {
      e.preventDefault();
      e.stopPropagation();
      toggleDetails.toggle();
    }
  }

  const classes = classNames('styled-accordion', { error, open: toggleDetails.isOpen }, className);

  return (
    <>
      <div className={classes} tabIndex={0} onClick={toggleDetails.toggle} onKeyDown={handleKeyDown}>
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
          <span className="styled-accordion-title fw-semi-bold">{title}</span>
          <div>
            {rightChildren}
            <span className="styled-accordion-sign no-text-select">{toggleDetails.isOpen ? <>-</> : <>+</>}</span>
          </div>
        </Row>
      </div>
      <div ref={ref} className="styled-accordion-content">
        {children}
      </div>
    </>
  );
}

export default StyledAccordion;
