import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import * as React from 'react';
import { CSSProperties } from 'react';
import './Spinner.scss';

export interface ISpinnerProps {
  style?: CSSProperties;
  theme?: Theme.LIGHT | Theme.DARK;
  className?: ClassName;
  size?: 'x-sm' | 'sm' | 'md';
}

const Spinner: React.FunctionComponent<ISpinnerProps> = (props) => {
  const { size, className, theme, style } = props;

  const classes = classNames(
    {
      'ac-fade-slide-up': true,
      'spinner': true,
      'light': theme === Theme.LIGHT,
      [`spinner-${size}`]: true,
    },
    className
  );

  return (
    <div className={classes} style={style}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Spinner.defaultProps = {
  size: 'sm',
};

export default Spinner;
