import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import IIdable from 'types/Idable';
import { isType } from 'utils/typeguard';
import { uuid } from 'utils/uuidUtils';

type AnimatedListValueType = string | number | IIdable<string | number> | null;

const nullId = uuid();

interface IAnimatedListProps<T extends AnimatedListValueType> {
  values: T[];
  renderItem: (value: T, index: number) => ReactNode;
  initialX?: number;
  initialY?: number;
}

function AnimatedList<T extends AnimatedListValueType>(props: IAnimatedListProps<T>) {
  const { renderItem, values, initialX, initialY } = props;

  function getKey(value: T) {
    if (!value) {
      return nullId;
    } else if (isType(value, 'id')) {
      return value.id;
    } else {
      return value as number | string;
    }
  }

  function renderValue(value: T, index: number) {
    return (
      <motion.div
        key={getKey(value)}
        initial={{ x: initialX, y: initialY, opacity: 0.4 }}
        animate={{ x: 0, y: 0, opacity: 1.0 }}
      >
        {renderItem(value, index)}
      </motion.div>
    );
  }

  return <>{values.map(renderValue)}</>;
}

export default AnimatedList;
