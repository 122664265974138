import { ChatIcon, FAQIcon, HeadsetIcon, LikeIcon } from 'assets/icons';
import classNames from 'classnames';
import { DetailsCard } from 'components';
import DetailsItemWrapper from 'components/foundation/details-item/DetailsItemWrapper/DetailsItemWrapper';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useFAQNavigation from 'hooks/navigation/useFAQNavigation';
import { ClassName } from 'types/ClassName';
import { executeFunctionsCurried } from 'utils/functionUtils';
import { t } from 'utils/localize';
import CustomerSupportDetail from '../CustomerSupportDetail/CustomerSupportDetail';
import './CustomerSupportActionList.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface ICustomerSupportActionListProps {
  onSelect: () => void;
  className?: ClassName;
}

function CustomerSupportActionList({ onSelect, className }: ICustomerSupportActionListProps) {
  const isInIframe = useIsInIframe();
  const faqNavigation = useFAQNavigation();

  function showIntercom() {
    (window as any).Intercom('show');
  }

  function showIntercomSurvey() {
    (window as any).Intercom('startSurvey', 25451684);
  }

  const brand = useBrandTitle();
  const classes = classNames('customer-support-action-list', className);

  return (
    <div className={classes}>
      {!isInIframe && (
        <DetailsCard>
          <DetailsItemWrapper onClick={executeFunctionsCurried(showIntercom, onSelect)}>
            <CustomerSupportDetail
              svg={ChatIcon}
              title={t('Chat')}
              subtitle={t('Connect with one of our amazing online customer service representatives.')}
            />
          </DetailsItemWrapper>
        </DetailsCard>
      )}
      <CustomerSupportDetail
        className="chat-support-detail"
        svg={HeadsetIcon}
        title={t('Give Us a Call')}
        subtitle={t('We would love to answer any questions you may have. Give us a call us at 1-855-287-9287.')}
      />
      <DetailsCard>
        <DetailsItemWrapper onClick={executeFunctionsCurried(faqNavigation, onSelect)}>
          <CustomerSupportDetail
            svg={FAQIcon}
            title={t('Help Center')}
            subtitle={t(
              'Browse our collections of online articles that cover everything from company settings to clocking in.'
            )}
          />
        </DetailsItemWrapper>
      </DetailsCard>

      <DetailsCard>
        <DetailsItemWrapper onClick={executeFunctionsCurried(showIntercomSurvey, onSelect)}>
          <CustomerSupportDetail
            svg={LikeIcon}
            title={t('Suggest a Feature')}
            subtitle={t('Wish {{brand}} did something more? Tell us what features you would like us to build next.', {
              brand,
            })}
          />
        </DetailsItemWrapper>
      </DetailsCard>
    </div>
  );
}

export default CustomerSupportActionList;
