import { Position, Tooltip } from '@busybusy/webapp-react-ui';
import { isType } from 'utils/typeguard';
import EmberNavItem from '../EmberNavItem/EmberNavItem';
import NavAccordion from '../NavAccordion/NavAccordion';
import NavItem from '../NavItem/NavItem';
import { INavBarParentLink, NavBarSectionConfig } from '../types/types';

export interface INavSectionProps {
  configuration: NavBarSectionConfig;
  isMin: boolean;
}

export default function NavSection({ configuration, isMin }: INavSectionProps) {
  return (
    <>
      {configuration.map((config) => {
        if (isType<INavBarParentLink>(config, 'children')) {
          return <NavAccordion key={config.label} parent={config} links={config.children} isMin={isMin} />;
        } else {
          if (config.isEmberFeature) {
            if (isMin) {
              return (
                <Tooltip label={config.label} delay={0} position={Position.RIGHT} key={config.href}>
                  <EmberNavItem key={config.href} href={config.href} icon={config.icon} enabled={config.enabled} />
                </Tooltip>
              );
            }
            return (
              <EmberNavItem key={config.href} href={config.href} icon={config.icon} enabled={config.enabled}>
                {config.label}
              </EmberNavItem>
            );
          } else {
            if (isMin) {
              return (
                <Tooltip label={config.label} delay={0} position={Position.RIGHT} key={config.href}>
                  <NavItem
                    to={config.href}
                    icon={config.icon}
                    hidden={!config.enabled}
                    isActive={config.isActive}
                    onClick={config.onClick}
                  >
                    <></>
                  </NavItem>
                </Tooltip>
              );
            }
            return (
              <NavItem
                key={config.href}
                to={config.href}
                icon={config.icon}
                hidden={!config.enabled}
                isActive={config.isActive}
                onClick={config.onClick}
              >
                {config.label}
              </NavItem>
            );
          }
        }
      })}
    </>
  );
}
