import announcementText from './announcementText';
import gusto from './gusto';
import navBar from './navBar';
import onboarding from './onboarding';
import payroll from './payroll';
import progress from './progress';
import quickSearch from './quickSearch';
import scheduling from './scheduling';
import subscriptions from './subscriptionEvents';
import timesheets from './timesheets';

const AnalyticsEvent = {
  gusto,
  navBar,
  onboarding,
  payroll,
  progress,
  quickSearch,
  scheduling,
  timesheets,
  announcementText,
  subscriptions,
};

export default AnalyticsEvent;
