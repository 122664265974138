import { Button, Label, Radio, RadioGroup, Textarea, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import EmbeddedLinkedText from 'components/foundation/EmbeddedLinkedText/EmbeddedLinkedText';
import { useToastOpen } from 'contexts/ToastContext';
import useMemberSignIn, {
  IOrganizationSignInQuestionItemData,
  IOrganizationSignInQuestionItemState,
} from 'hooks/models/member-sign-in/useMemberSignIn';
import useGetOrganizationSignInQuestions from 'hooks/models/organization-sign-in-questions/useGetOrganizationSignInQuestions';
import { cloneDeep, first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import { StoreAuthMemberProper } from 'store/types';
import { IMember } from 'types';
import { t } from 'utils/localize';
import { buildChoiceObjectFromJSON, IOrganizationSignInQuestionChoice } from 'utils/organizationSignInQuestionUtils';
import { OrganizationSignInQuestion, OrganizationSignInQuestionAudience } from '__generated__/graphql';
import './SignInQuestionForm.scss';

export interface ISignInQuestionFormProps {
  className?: ClassName;
  member: Partial<IMember> | IMember | StoreAuthMemberProper;
  onSubmit: (submissionId: string) => void;
  submissionDate?: DateTime;
  onClose: () => void;
}

const SignInQuestionForm = (props: ISignInQuestionFormProps) => {
  const { className, member, onSubmit, submissionDate, onClose } = props;

  const fetchQuestions = useGetOrganizationSignInQuestions();
  const [questions, setQuestions] = useState<OrganizationSignInQuestion[]>([]);
  const [data, setData] = useState<IOrganizationSignInQuestionItemState>();
  const toastOpen = useToastOpen();
  const { createSubmissionWithAnswers } = useMemberSignIn();

  useEffect(() => {
    getQuestions();
  }, []);

  async function getQuestions() {
    const allQuestions = await fetchQuestions({
      deletedOn: { isNull: true },
      audienceType: { notEqual: OrganizationSignInQuestionAudience.NoOne },
    });

    setQuestions(
      allQuestions.filter(
        (question) =>
          question.audienceType === OrganizationSignInQuestionAudience.Everyone ||
          (question.audienceType === OrganizationSignInQuestionAudience.Position &&
            question.positionId === member.positionId)
      )
    );
  }

  function onRadioChange(value: string, item: IOrganizationSignInQuestionItemData) {
    const clonedData = cloneDeep(data) ?? { byId: {} };
    clonedData.byId[item.question.id] = {
      ...item,
      answer: value,
    };

    setData(clonedData);
  }

  function renderRadioButton(choice: IOrganizationSignInQuestionChoice, item: IOrganizationSignInQuestionItemData) {
    return (
      <Radio
        key={item.question.id}
        value={choice.choice}
        checked={item.answer ? item.answer === choice.choice : undefined}
        label={choice.choice}
      />
    );
  }

  function onTextAreaChange(value: string, item: IOrganizationSignInQuestionItemData) {
    const clonedData = cloneDeep(data) ?? { byId: {} };
    clonedData.byId[item.question.id] = {
      ...item,
      description: value,
    };

    setData(clonedData);
  }

  function renderTextArea(choices: IOrganizationSignInQuestionChoice[], item: IOrganizationSignInQuestionItemData) {
    let showTextArea = false;
    let questionText = '';

    if (item.answer) {
      showTextArea = choices.some((choice) => {
        return choice.choice === item.answer && !isNil(choice.followup);
      });
      const choice = first(choices.filter((choice) => choice.choice === item.answer));
      if (!isNil(choice)) {
        questionText = choice.followup ?? '';
      }
    }

    return (
      <>
        {showTextArea && (
          <>
            <Label className="section-secondary">{questionText}</Label>
            <Textarea
              className="mb-8"
              value={item.description ? item.description : ''}
              onChange={(value) => onTextAreaChange(value, item)}
              restrictTo={{ maxLength: 500 }}
              key={item.question.id}
            />
          </>
        )}
      </>
    );
  }

  function renderQuestion(question: OrganizationSignInQuestion): ReactNode {
    const choices = buildChoiceObjectFromJSON(question.choices);

    const currentData = data?.byId[question.id] ?? { question };
    return (
      <div className="mb-5" key={question.id}>
        <Label className="section-primary">
          <EmbeddedLinkedText>{question.questionTitle}</EmbeddedLinkedText>
        </Label>
        {question.questionDescription && (
          <Label className="section-secondary">
            <EmbeddedLinkedText>{question.questionDescription}</EmbeddedLinkedText>
          </Label>
        )}
        <RadioGroup
          className="section-options my-4"
          name={currentData.question.id}
          value={currentData.answer}
          onChange={(value) => onRadioChange(value, currentData)}
        >
          {choices.map((choice) => renderRadioButton(choice, currentData))}
        </RadioGroup>

        {renderTextArea(choices, currentData)}
      </div>
    );
  }

  function renderSubmitButton() {
    return (
      <Button className="mt-5" type="primary" onClick={handleSubmit}>
        {t('Submit')}
      </Button>
    );
  }

  function hasAnsweredRequiredQuestions() {
    return questions.every((question) => {
      return !question.required || data?.byId[question.id]?.answer;
    });
  }

  function handleSubmit() {
    if (!data || !hasAnsweredRequiredQuestions()) {
      toastOpen({ label: t('Please answer all of the questions.'), theme: Theme.DANGER });
    } else {
      createSubmissionWithAnswers(member.id!, data, submissionDate).then((submissionId) => {
        onSubmit(submissionId);
        onClose();
      });
    }
  }

  const classes = classNames('sign-in-question-form', 'p-8', className);

  return (
    <div className={classes}>
      {questions.map((question) => renderQuestion(question))}
      {renderSubmitButton()}
    </div>
  );
};

export default SignInQuestionForm;
