import { TIME_ENTRIES_WITH_MEMBER_POSITION_AND_GPS_STATUS } from 'apollo/queries/time-entry-queries';
import { graphQLContainsIdOrEmptyItemId } from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { useApolloPaging } from 'hooks/apollo';
import { isNil, merge, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { onlyHasRemainingItem } from 'utils/collectionUtils';
import { useApolloNestedMemberNameSort } from '../member/useApolloMemberNameSort';

export interface IUseTimeEntryQueryArgs {
  timeRange: ITimeRange<DateTime>;
  includeOpenEntries?: boolean;
  timeEntriesQuery?: any;
  immediateRun?: boolean;
  memberIds?: string[];
  projectIds?: string[];
  costCodeIds?: string[];
  equipmentIds?: string[];
  idWithDescendants?: boolean;
  customSort?: object;
  permissions?: MemberPermission[];
}

function useTimeEntryQuery(timeEntriesQuery?: any) {
  const { getAll } = useApolloPaging();
  const query = timeEntriesQuery ? timeEntriesQuery : TIME_ENTRIES_WITH_MEMBER_POSITION_AND_GPS_STATUS;
  const getMemberNameSort = useApolloNestedMemberNameSort('member');

  const getEntries = useCallback(
    async (
      timeRange: ITimeRange<DateTime>,
      includeOpenEntries?: boolean,
      memberIds?: string[],
      projectIds?: string[],
      costCodeIds?: string[],
      equipmentIds?: string[],
      idWithDescendants?: boolean,
      customSort?: object,
      permissions?: MemberPermission[],
      costCodeGroupId?: string | null,
      projectGroupId?: string | null,
      first: number = 10
    ) => {
      const projectFilter = () => {
        if (onlyHasRemainingItem(projectIds)) {
          return { isNull: true };
        }

        return projectIds ? { contains: projectIds } : undefined;
      };

      async function getTimeEntries() {
        const variables: any = {
          first,
          filter: {
            deletedOn: { isNull: true },
            startTime: {
              lessThanOrEqual: timeRange.endTime
                .endOf('day')
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            endTime: includeOpenEntries
              ? { isNull: includeOpenEntries }
              : {
                  isNull: false,
                  greaterThanOrEqual: timeRange.startTime
                    .startOf('day')
                    .toUTC()
                    .toISO({ suppressMilliseconds: true, includeOffset: false }),
                },
            memberId: memberIds ? { contains: memberIds } : undefined,
            costCodeId: graphQLContainsIdOrEmptyItemId(costCodeIds),
            equipmentId: graphQLContainsIdOrEmptyItemId(equipmentIds),
            costCode: {
              costCodeGroupId: costCodeGroupId ? { equal: costCodeGroupId } : undefined,
            },
            member: permissions
              ? {
                  permissions: {
                    permissions,
                    operationType: OperationType.AND,
                    includeSelf: true,
                  },
                }
              : undefined,
          },
          sort: customSort ?? [{ startTime: 'asc' }, ...getMemberNameSort('asc')],
        };

        if (idWithDescendants === true && onlyHasRemainingItem(projectIds) === false) {
          variables.filter.project = {
            idWithDescendants: projectFilter(),
          };
        } else {
          variables.filter.projectId = projectFilter();
        }

        const mergedProject = merge(variables.filter.project, {
          projectGroupIdWithDescendants: projectGroupId ? { equal: projectGroupId } : undefined,
        });

        variables.filter.project = mergedProject;

        return await getAll<ITimeEntry>('timeEntries', {
          query,
          fetchPolicy: 'network-only',
          variables: variables,
        });
      }

      async function getOpenTimeEntries() {
        const variables: any = {
          first,
          filter: {
            deletedOn: { isNull: true },
            startTime: {
              lessThanOrEqual: timeRange.endTime
                .endOf('day')
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            endTime: {
              isNull: false,
              greaterThanOrEqual: timeRange.startTime
                .startOf('day')
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            memberId: memberIds ? { contains: memberIds } : undefined,
            costCodeId: graphQLContainsIdOrEmptyItemId(costCodeIds),
            equipmentId: graphQLContainsIdOrEmptyItemId(equipmentIds),
            costCode: {
              costCodeGroupId: costCodeGroupId ? { equal: costCodeGroupId } : undefined,
            },
            member: permissions
              ? {
                  permissions: {
                    permissions,
                    operationType: OperationType.AND,
                    includeSelf: true,
                  },
                }
              : undefined,
          },
          sort: customSort ?? [{ startTime: 'asc' }, ...getMemberNameSort('asc')],
        };

        if (idWithDescendants === true && onlyHasRemainingItem(projectIds) === false) {
          variables.filter.project = {
            idWithDescendants: projectFilter(),
            projectGroupIdWithDescendants: projectGroupId ? { equal: projectGroupId } : undefined,
          };
        } else {
          variables.filter.projectId = projectFilter();
        }

        const mergedProject = merge(variables.filter.project, {
          projectGroupIdWithDescendants: projectGroupId ? { equal: projectGroupId } : undefined,
        });

        variables.filter.project = mergedProject;

        return await getAll<ITimeEntry>('timeEntries', {
          query,
          fetchPolicy: 'network-only',
          variables: variables,
        });
      }

      const closedTimeEntries = await getTimeEntries();
      const withOpenEntries = !isNil(includeOpenEntries) ? includeOpenEntries : false;
      const openTimeEntries = withOpenEntries ? await getOpenTimeEntries() : [];
      const combinedEntries = [...closedTimeEntries, ...openTimeEntries];
      return sortBy(combinedEntries, 'startTime', 'createdOn');
    },
    [getAll, query]
  );

  return getEntries;
}

export default useTimeEntryQuery;
