// Will infinite loop if given a non terminating iterator
export function collectIterator<T>(iterator: Generator<T>): Array<T> {
  const collector = new Array<T>();

  let next = iterator.next();

  while (!next.done) {
    collector.push(next.value);
    next = iterator.next();
  }

  return collector;
}
