import gql from 'graphql-tag';

export const PROJECT_CREATE_QUERY = gql`
  mutation CreateProject3($project: ProjectCreateInput) {
    createProject(project: $project) {
      id
      title
    }
  }
`;

export const PROJECT_UPDATE_MUTATION = gql`
  mutation UpdateProjectSimple($project: ProjectUpdateInput) {
    updateProject(project: $project) {
      id
      title
    }
  }
`;
