import { Icon, Tooltip } from '@busybusy/webapp-react-ui';
import { ChevronRightIcon, InfoIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';
import './DetailsItemWrapper.scss';

export interface IDetailsItemWrapperProps
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'> {
  disabled?: boolean;
  hint?: string;
  hideArrow?: boolean;
  rightContent?: ReactNode;
  className?: ClassName;
}

export default function DetailsItemWrapper({
  className,
  disabled,
  hint,
  hideArrow,
  rightContent,
  children,
  onClick,
  ...divProps
}: IDetailsItemWrapperProps) {
  const clickable = onClick && !disabled;

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!disabled) {
      onClick?.(event);
    }
  }

  return (
    <div
      className={classNames('details-item-wrapper', { disabled, clickable }, className)}
      onClick={handleClick}
      {...divProps}
    >
      {children}

      <span className="details-item-arrow">{clickable && <Icon svg={ChevronRightIcon} />}</span>
      <span className="details-item-right">
        {rightContent}
        {hint && (
          <Tooltip label={hint}>
            <span className="details-item-info">
              <Icon svg={InfoIcon} />
            </span>
          </Tooltip>
        )}
      </span>
    </div>
  );
}
