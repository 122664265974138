import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { EquipmentCostHistory, EquipmentCostHistoryUpdateInput } from '__generated__/graphql';

export default function useUpdateEquipmentCostHistory() {
  const [updateEquipmentCostHistory] = useMutation(UPDATE_EQUIPMENT_COST_HISTORY);

  return useCallback(
    (id: string, machineCostRate?: number, operatorCostRate?: number, changeDate?: string) => {
      const input: EquipmentCostHistoryUpdateInput = {
        id,
        changeDate,
        machineCostRate,
        operatorCostRate,
      };

      return new Promise<EquipmentCostHistory>((resolve, reject) => {
        return updateEquipmentCostHistory({ variables: { equipmentCostHistory: input } }).then((results) => {
          if (results.data) {
            resolve(results.data.updateEquipmentCostHistory);
          } else {
            reject();
          }
        });
      });
    },
    [updateEquipmentCostHistory]
  );
}

export const UPDATE_EQUIPMENT_COST_HISTORY = gql`
  mutation UpdateEquipmentCostHistory($equipmentCostHistory: EquipmentCostHistoryUpdateInput!) {
    updateEquipmentCostHistory(equipmentCostHistory: $equipmentCostHistory) {
      id
      machineCostRate
      operatorCostRate
      changeDate
      updatedOn
      deletedOn
    }
  }
`;
