import JSZip from 'jszip';
import readXlsxFile from 'read-excel-file';

export interface IFile {
  fileName: string;
  file: Blob;
}

export function downloadText(text: string, fileName: string) {
  return downloadFile({
    file: new Blob([text]),
    fileName,
  });
}

export function downloadFile({ file, fileName }: IFile) {
  const link = createDownloadLinkDOM({ file, fileName });

  return new Promise((resolve, reject) => {
    link.onclick = () => resolve(link.parentNode!.removeChild(link));
    link.onerror = reject;

    link.click();
  });
}

export function createDownloadLinkDOM({ file, fileName }: IFile) {
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);

  return link;
}

export function downloadZipFile(zipper: JSZip, zipFileName: string) {
  return zipper
    .generateAsync({ type: 'blob' })
    .then((zipperBlob) => downloadFile({ file: zipperBlob, fileName: `${zipFileName}.zip` }));
}

export function addFileToZipper(zipper: JSZip, { file, fileName }: IFile) {
  return zipper.file(fileName, file, { base64: true });
}

export function downloadFilesAsZip(files: IFile[], zipFileName: string) {
  const zipper = new JSZip();
  const wrapper = zipper.folder(zipFileName);

  if (!wrapper) {
    return null;
  }

  files.forEach((file) => addFileToZipper(wrapper, file));
  return downloadZipFile(zipper, zipFileName);
}

export function readFile(file: File, onload: (reader: FileReader) => () => void) {
  const reader = new FileReader();
  reader.onload = onload(reader);
  reader.readAsText(file);
}

async function convertXlsxToCsv(xlsxFile: File) {
  const result: string[][] = await readXlsxFile(xlsxFile);
  const rowsJoined = result.map((rows) =>
    rows.map((row) => (typeof row === 'string' && row?.includes(',') ? `"${row}"` : row)).join(',')
  );
  return rowsJoined.join('\r\n');
}

export function convertBlobToUrl(data: Blob): string {
  return window.URL.createObjectURL(data);
}

export function downloadUrl(url: string, fileName: string) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function readFileAsDataUrl(file: File | Blob) {
  const reader = new FileReader();
  return new Promise(function (resolve) {
    reader.onload = function (event) {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file);
  });
}

export async function downloadBlobFromUrl(url: string) {
  const res = await fetch(url);
  return res.blob();
}

export async function downloadUrlsAsZip(
  urls: string[],
  zipFileName: string,
  getFileName: (url: string, index: number) => string
) {
  const files = urls.map(async (url, index) => {
    const fileName = getFileName(url, index);
    const blob = await downloadBlobFromUrl(url);
    return { fileName, file: blob };
  });

  return downloadFilesAsZip(await Promise.all(files), zipFileName);
}

export function getDataUrlFromUrl(url: RequestInfo) {
  return fetch(url, { mode: 'cors', cache: 'no-cache', headers: { origin: window.location.origin } })
    .then((response) => response.blob())
    .then((blob) => {
      return readFileAsDataUrl(blob);
    });
}

export default {
  convertXlsxToCsv,
  downloadFile,
  downloadZipFile,
  readFile,
  readFileAsDataUrl,
  getDataUrlFromUrl,
};
