import { Duration, DurationUnit } from 'luxon';
import { Optional } from 'types/util/Optional';
import DateTimeFormat from './constants/dateTimeFormat';

export function getDurationFromSeconds(seconds: number) {
  return Duration.fromObject({ seconds });
}

export function getSecondsFromDuration(duration: Duration) {
  return getTotalFromDuration(duration, 'seconds');
}

export function getSecondsFromOptionalDuration(duration: Optional<Duration>) {
  return duration ? getSecondsFromDuration(duration) : 0;
}

export function getTotalFromDuration(duration: Duration, durationUnit: DurationUnit) {
  return duration.shiftTo(durationUnit).get(durationUnit);
}

// Duration will format negative minutes like -8:-30 instead of -8:30
export function formatDurationAsHoursMinutes(duration: Duration) {
  const seconds = getSecondsFromDuration(duration);
  if (seconds < 0) {
    const absoluteSeconds = Math.abs(seconds);
    const absoluteDuration = getDurationFromSeconds(absoluteSeconds);
    return `-${absoluteDuration.toFormat(DateTimeFormat.hourMinuteDuration)}`;
  }

  return duration.toFormat(DateTimeFormat.hourMinuteDuration);
}
