import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useEffect, useState } from 'react';
import './ProgressBar.scss';

export interface IProgressBarProps {
  className?: ClassName;
  barColor: string;
  completed: number; // (0 - 100)
}

const ProgressBar = (props: IProgressBarProps) => {
  const { className, barColor, completed } = props;
  const [safeCompleted, setSafeCompleted] = useState(0);

  useEffect(() => {
    if (completed < 0) {
      setSafeCompleted(0);
    } else if (completed > 100) {
      setSafeCompleted(100);
    } else {
      setSafeCompleted(completed);
    }
  }, [completed]);

  const fillerStyles = {
    width: `${safeCompleted}%`,
    backgroundColor: barColor,
  };

  const classes = classNames('progress-bar', className);

  return (
    <div className={classes}>
      <div className={'filler-bar'} style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
