import {
  BASIC_MEMBER_LABOR_METRICS_FRAGMENT,
  BASIC_MEMBER_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-member-queries';
import {
  BASIC_PROJECT_LABOR_METRICS_FRAGMENT,
  BASIC_PROJECT_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-project-queries';
import { BASIC_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from 'apollo/queries/jit-queries';
import gql from 'graphql-tag';

export const MEMBERS_TIME_METRIC_QUERY = gql`
  query QueryMembersTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        __typename
        ...BasicMemberLaborMetrics
      }
    }
  }
  ${BASIC_MEMBER_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_LABOR_METRIC_QUERY = gql`
  query QueryMembersLaborMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        __typename
        ...MemberLaborMetrics
      }
    }
  }
  ${BASIC_MEMBER_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_PROJECT_TIME_METRIC_QUERY = gql`
  query QueryMembersProjectTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $projectIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
        filter: $metricFilter
      ) {
        __typename
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_PROJECT_METRIC_QUERY = gql`
  query QueryMembersProjectMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $projectIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
        filter: $metricFilter
      ) {
        __typename
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_COST_CODE_TIME_METRIC_QUERY = gql`
  query QueryMembersCostCodeTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        __typename
        ...BasicLaborMetrics
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_COST_CODE_METRIC_QUERY = gql`
  query QueryMembersCostCodeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $costCodeIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        costCodeIds: $costCodeIds
        filter: $metricFilter
      ) {
        __typename
        ...LaborMetrics
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const MEMBERS_EQUIPMENT_TIME_METRIC_QUERY = gql`
  query QueryMembersEquipmentTimeMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        __typename
        ...BasicLaborMetrics
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const MEMBERS_EQUIPMENT_METRIC_QUERY = gql`
  query QueryMembersEquipmentMetrics(
    $first: Int
    $after: String
    $filter: MemberFilter
    $sort: [MemberSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $equipmentIds: [Uuid!]
    $metricFilter: LaborMetricFilter
  ) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      memberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        equipmentIds: $equipmentIds
        filter: $metricFilter
      ) {
        __typename
        ...LaborMetrics
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;
