import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useRef } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import OvertimePayRateType from 'types/enum/OvertimePayRateType';

export interface IOvertimePayRateSelectProps extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  onChange: (value: OvertimePayRateType, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const OvertimePayRateSelect = (props: IOvertimePayRateSelectProps) => {
  const { className, onChange, ...selectProps } = props;

  const [t] = useTranslation();

  function onChangeWage(value: string, event: React.ChangeEvent<HTMLSelectElement>) {
    onChange(value as OvertimePayRateType, event);
  }

  const options = useRef<ISelectOption[]>([
    {
      label: t('1.5'),
      value: OvertimePayRateType.ONE_POINT_FIVE,
    },
    {
      label: t('2.0'),
      value: OvertimePayRateType.TWO_POINT_ZERO,
    },
  ]);

  const classes = classNames('overtime-pay-rate-select', className);

  return (
    <Select
      className={classes}
      placeholder={t('1.5')}
      options={options.current}
      onChange={onChangeWage}
      {...selectProps}
    />
  );
};

OvertimePayRateSelect.defaultProps = {
  ...Select.defaultProps,
};

export default OvertimePayRateSelect;
