import {
  Field,
  FieldFooter,
  IFieldRender,
  IFormFieldDefaultProps,
  ITimeFieldPayload,
  ITimeFieldProps,
  TimeField,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import FeatureTimeField from 'components/domain/time/FeatureTimeField/FeatureTimeField';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import ClockAction from 'types/enum/ClockAction';
import { AnyObject } from 'types/util/Object';
import { getTimezoneSupStringForDate } from 'utils/dateUtils';
import './FeatureTimeFormField.scss';

export interface IFeatureTimeFormFieldProps<FormType extends AnyObject>
  extends Omit<ITimeFieldProps, 'value' | 'minuteStep' | 'error' | 'onChange'>,
    IFormFieldDefaultProps<string | null | undefined, FormType> {
  /* Used to determine time rounding */
  clockAction: ClockAction;
  onChange?: (payload: DateTime | null | undefined) => void;
  timeDST?: boolean;
}

function FeatureTimeFormField<FormType extends AnyObject>(props: IFeatureTimeFormFieldProps<FormType>) {
  const {
    form,
    name,
    validations,
    clockAction,
    onChange,
    className,
    timeDST,
    revalidateOnNewValidations,
    ...featureTimeFieldProps
  } = props;

  function renderField({ ...fieldProps }: IFieldRender<any>) {
    function handleOnChange(val: ITimeFieldPayload) {
      fieldProps.handleChange(val ? val.value : undefined, onChange);
    }

    return (
      <>
        <FeatureTimeField
          {...featureTimeFieldProps}
          onChange={handleOnChange}
          value={form.state.data[name]}
          clockAction={clockAction}
          error={fieldProps.hasError}
        />
        {form.state.data[name] && !isNil(timeDST) && (
          <div className="time-zone-sup">{getTimezoneSupStringForDate(form.state.data[name], timeDST)}</div>
        )}
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames('feature-time-form-field', className);
  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

FeatureTimeFormField.defaultProps = {
  ...TimeField.defaultProps,
  period: 'AM',
  disabled: false,
  placeholder: 'H:MM',
};

export default FeatureTimeFormField;
