import PhotoType from 'types/enum/PhotoType';
import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  end_date?: string;
  time_range_type?: TimeRangeType;
  photo_type?: PhotoType;
}

export default function usePhotoVerificationReportNavigation() {
  return useNavigation<IParams>('/photo-verification-report');
}
