import { merge } from 'lodash';
import { IStoreEnvironment, TEnvironmentKeys } from './index';
import { environmentTemplates } from './templates';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    __HYDRATE__?: {
      env?: Partial<IStoreEnvironment>;
    };
  }
}

const {
  REACT_APP_ENV,
  REACT_APP_VERSION,
  REACT_APP_APP_URL,
  REACT_APP_LEGACY_URL,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_RESTAPI_URL,
  REACT_APP_LEGACY_QBRPC_URL,
  REACT_APP_COMPANY_CAM_URL,
  REACT_APP_COMPANY_CAM_SOCKET_URL,
  REACT_APP_QBD_API_URL,
  REACT_SLACK_URL,
} = process.env as unknown as Partial<IStoreEnvironment>;

if (process.env.REACT_APP_ENV === 'production' && process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.warn(
    [
      `WARNING: Probable misconfiguration detected`,
      `The environment variable REACT_APP_ENV is set to "${REACT_APP_ENV}" on a development build.`,
      `See .env for instructions to configure your local environment using .env.development.local`,
      `If you are intending to connect to the production web services environment for development purposes, this warning may be safely ignored`,
    ].join('\n')
  );
}

// load the base environment config set in the associated ./template
const environmentTemplate = environmentTemplates.get(REACT_APP_ENV as TEnvironmentKeys);

// load any environmental config injected via window.__HYDRATE__
const windowEnv = { ...window?.__HYDRATE__?.env };

// load any environmental config set in either a .env file or from the CLI
// REACT_APP_ENV and REACT_APP_VERSION *must* be defined in .env
const processEnv = {
  REACT_APP_ENV: REACT_APP_ENV as TEnvironmentKeys,
  REACT_APP_VERSION: REACT_APP_VERSION as string,

  REACT_APP_APP_URL,
  REACT_APP_LEGACY_URL,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_RESTAPI_URL,
  REACT_APP_LEGACY_QBRPC_URL,
  REACT_APP_COMPANY_CAM_URL,
  REACT_APP_COMPANY_CAM_SOCKET_URL,
  REACT_APP_QBD_API_URL,
  REACT_SLACK_URL,
};

// merge all sources of environment config using the following priority
//  injected window.__HYDRATE__ config overwrites
//  process.env config overwrites
//  the environment template config
export const initialState: IStoreEnvironment = merge({}, environmentTemplate, processEnv, windowEnv); // right to left

const logStyles = {
  label: 'font-weight: bold; color: #fcbf12;',
  logo: 'font-weight: bold; color: #fcbf12;',
  value: 'color: #ebb977;',
};

const consoleOutput = `%c
    __                     __
   / /_  __  _________  __/ /_  __  _________  __
  / __ \\/ / / / ___/ / / / __ \\/ / / / ___/ / / /
 / /_/ / /_/ (__  ) /_/ / /_/ / /_/ (__  ) /_/ /
/_.___/\\__,_/____/\\__, /_.___/\\__,_/____/\\__, /
                 /____/                 /____/

%cVersion: %c${initialState.REACT_APP_VERSION}
`;

if (process.env.NODE_ENV !== 'test') {
  // eslint-disable-next-line no-console
  console.info(consoleOutput, logStyles.logo, logStyles.label, logStyles.value, logStyles.label, logStyles.value);
}
