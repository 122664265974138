import { INavItemProps } from '../NavItem/NavItem';

export interface INavBarBaseLink {
  label: string;
  enabled: boolean;
  position: number;
  visible: boolean;
}

export interface INavBarParentLink extends INavBarBaseLink {
  id: NavBarId;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  matches: () => boolean;
  children: INavBarChildLink[];
  onClick?: () => void;
}

export interface INavBarLink extends INavBarBaseLink {
  id: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  href: string;
  exact: boolean;
  isActive?: INavItemProps['isActive'];
  isEmberFeature?: boolean;
  onClick?: () => void;
}

export interface INavBarChildLink extends INavBarBaseLink {
  // Cannot be `NavBarId` because field reports have dynamic ids
  id: string;
  href: string;
  exact: boolean;
  isActive?: INavItemProps['isActive'];
  isEmberFeature?: boolean;
  onClick?: () => void;
}

export type NavBarSectionConfig = Array<INavBarParentLink | INavBarLink>;

export const navBarIds = [
  'dashboard',
  'my-status',
  'time-cards',
  'summary',
  'basic-time-cards',
  'expanded-time-cards',
  'time-entries',
  'weekly-grid',
  'event-logs',
  'payroll',
  'employees',
  'projects',
  'cost-codes',
  'equipment',
  'photos',
  'documents',
  'scheduling',
  'scheduling-calendar',
  'scheduling-attendance-report',
  'scheduling',
  'map',
  'budgets',
  'bulletin',
  'management-lists',
  'manage-employees',
  'manage-projects',
  'manage-cost-codes',
  'manage-equipment',
  'activity-report',
  'active-by-project',
  'activity-by-employee',
  'activity-by-cost-code',
  'activity-by-equipment',
  'activity-by-date',
  'activity-by-date-range',
  'legacy',
  'progress',
  'verification-safety',
  'daily-sign-in',
  'daily-sign-off',
  'photo-verification-report',
  'gps-outside-project',
  'daily-report',
  'more',
  'time-off',
  'machine-vs-operator',
  'hour-meter',
  'break-hours',
  'settings',
  'safety',
] as const;

export type NavBarId = (typeof navBarIds)[number];

export const baseNavBlacklistedIds: NavBarId[] = [];

export const iframeBlacklistedIds: NavBarId[] = [];
