import gql from 'graphql-tag';
import { BASIC_EQUIPMENT_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from './jit-queries';

export const EQUIPMENT_TIME_METRICS_QUERY = gql`
  query EquipmentTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicLaborMetrics
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const EQUIPMENT_METRICS_QUERY = gql`
  query EquipmentMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicEquipmentLaborMetrics
    }
  }
  ${BASIC_EQUIPMENT_LABOR_METRICS_FRAGMENT}
`;
