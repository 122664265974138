import { useApolloClient } from '@apollo/client';
import { PROJECT_COST_CODE_LINKS_QUERY } from 'containers/manage-projects/ProjectDetails/ProjectCostCodesTab/project-cost-code-links-queries';
import { useCallback } from 'react';
import { IProjectCostCodeLink } from 'types/ProjectCostCodeLink';

export default function useProjectCostCodeLinkCheck() {
  const client = useApolloClient();

  return useCallback(
    async (projectId: string, costCodeId: string) => {
      const { data, ...result } = await client.query<{ projectCostCodeLinks: IProjectCostCodeLink[] }>({
        query: PROJECT_COST_CODE_LINKS_QUERY,
        variables: {
          filter: {
            projectId: { equal: projectId },
            costCodeId: { equal: costCodeId },
            deletedOn: { isNull: true },
          },
        },
      });

      return { exists: data.projectCostCodeLinks && data.projectCostCodeLinks.length > 0, ...result };
    },
    [client]
  );
}
