import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { EquipmentCostHistory, EquipmentCostHistoryCreateInput } from '__generated__/graphql';

export default function useCreateEquipmentCostHistory() {
  const [createEquipmentCostHistory] = useMutation(CREATE_EQUIPMENT_COST_HISTORY);

  return useCallback(
    (
      equipmentId: string,
      machineCostRate?: number,
      operatorCostRate?: number,
      changeDate: string = DateTime.local().startOf('day').toISO()
    ) => {
      const input: EquipmentCostHistoryCreateInput = {
        equipmentId,
        createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        changeDate,
        machineCostRate,
        operatorCostRate,
      };

      return new Promise<EquipmentCostHistory>((resolve, reject) => {
        return createEquipmentCostHistory({ variables: { equipmentCostHistory: input } }).then((results) => {
          if (results.data) {
            resolve(results.data.createEquipmentCostHistory);
          } else {
            reject();
          }
        });
      });
    },
    [createEquipmentCostHistory]
  );
}

export const CREATE_EQUIPMENT_COST_HISTORY = gql`
  mutation CreateEquipmentCostHistory($equipmentCostHistory: EquipmentCostHistoryCreateInput!) {
    createEquipmentCostHistory(equipmentCostHistory: $equipmentCostHistory) {
      id
      machineCostRate
      operatorCostRate
      changeDate
      updatedOn
      deletedOn
    }
  }
`;
