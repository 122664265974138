import { Align, Button, ButtonList, Divider, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Typography from 'components/foundation/text/Typography/Typography';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import valuesIn from 'lodash/valuesIn';
import { t } from 'utils/localize';
import useQuickTimeTemplates from '../hooks/useQuickTimeTemplates';
import { ITimeEntryTemplate } from '../types/types';
import './QuickTimeSelectionMenu.scss';

export interface IQuickTimeSelectionMenuProps {
  onTemplateSelect: (template: ITimeEntryTemplate) => void;
  onManageClick: () => void;
  onSettingsClick: () => void;
  className?: ClassName;
}

function QuickTimeSelectionMenu({
  onManageClick,
  onSettingsClick,
  onTemplateSelect,
  className,
}: IQuickTimeSelectionMenuProps) {
  const { templates } = useQuickTimeTemplates();
  const classes = classNames('quick-time-selection-menu', className);
  const templateValues = sortBy(valuesIn(templates), 'position');

  function onSelect(template: ITimeEntryTemplate) {
    return () => onTemplateSelect(template);
  }

  return (
    <div className={classes}>
      {isEmpty(templateValues) ? (
        <Row justify={Justify.CENTER} align={Align.CENTER} className="my-8">
          <Typography tag="h4" color="light-gray" fontWeight="bold">
            {t('No Saved Templates')}
          </Typography>
        </Row>
      ) : (
        <div>
          <Typography
            className="mx-5 mt-5 mb-3"
            tag="h4"
            color="light-gray"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {t('Load Template')}
          </Typography>
          <ButtonList className="mb-4 quick-time-selection-list">
            {templateValues.map((template) => (
              <Button key={template.name} onClick={onSelect(template)}>
                <Typography tag="p" className="pl-1">
                  {template.name}
                </Typography>
              </Button>
            ))}
          </ButtonList>
        </div>
      )}
      <Divider />
      <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER} className="m-4">
        <Typography color="primary" tag="span" onClick={onManageClick}>
          {t('Manage')}
        </Typography>
        <Typography color="primary" tag="span" onClick={onSettingsClick}>
          {t('Settings')}
        </Typography>
      </Row>
    </div>
  );
}

export default QuickTimeSelectionMenu;
