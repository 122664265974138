import { useOpenable } from 'hooks';
import { IOpenableHook } from 'hooks/utils/useOpenable';
import { createContext, useContext } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';
import { Nullable } from 'types/util/Nullable';

export const CommandPalletContext = createContext<Nullable<IOpenableHook>>(null);

export function useCommandPalletState() {
  const context = useContext(CommandPalletContext);

  if (context === null || context === undefined) {
    throw new Error('useCommandPalletState must be used within a CommandPalletProvider');
  }

  return context;
}

export function CommandPalletContextProvider({ children }: IChildrenProps) {
  const details = useOpenable();
  return <CommandPalletContext.Provider value={details}>{children}</CommandPalletContext.Provider>;
}
