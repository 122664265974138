import useSignatureSettingsUpdate from 'hooks/models/member-settings/useSignatureSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { isNil } from 'lodash';
import SignatureSettings from 'types/enum/SignatureSettings';
import LocalStore from 'utils/localStorageUtils';
import useOnMount from './useOnMount/useOnMount';

export default function useSignatureSetting() {
  const memberSettings = useMemberSettings();
  const updateSignatureSettings = useSignatureSettingsUpdate();

  useOnMount(() => {
    if (isNil(memberSettings?.web?.features?.signatures?.localStoreMigrated)) {
      const localSignatureSettings = LocalStore.get('signatureSettings');
      updateSignatureSettings([
        { key: 'localStoreMigrated', payload: true },
        { key: 'inputSetting', payload: localSignatureSettings },
      ]);
      LocalStore.remove('signatureSettings');
    }
  });

  function getSignatureSetting() {
    return memberSettings?.web?.features?.signatures?.inputSetting ?? SignatureSettings.MANUAL;
  }

  async function setSignatureSetting(value: SignatureSettings) {
    await updateSignatureSettings([{ key: 'inputSetting', payload: value }]);
  }

  return { getSignatureSetting, setSignatureSetting };
}
