import { CreateIncidentMedicalDetailsMutation, CreateIncidentMedicalDetailsMutationVariables, IncidentMedicalDetailsCreateInput } from "__generated__/graphql";
import gql from "graphql-tag";
import useGraphQLClient from "hooks/graphql/useGraphQLClient/useGraphQLClient";
import { isNil } from "lodash";
import { useCallback } from "react";

export const useCreateIncidentMedicalDetails = () => {
    const client = useGraphQLClient();
    const createIncidentMedicalDetails = useCallback(
        async (input: IncidentMedicalDetailsCreateInput) => {
            const result = await client.request<CreateIncidentMedicalDetailsMutation, CreateIncidentMedicalDetailsMutationVariables>({
                document: CREATE_INCIDENT_MEDICAL_DETAILS,
                variables: {
                    incidentMedicalDetails: input,
                }
            });

            if (isNil(result.createIncidentMedicalDetails)) {
                throw Error(`result was ${result.createIncidentMedicalDetails}`);
            }

            return result.createIncidentMedicalDetails;
        },
        [client]
    );

    return createIncidentMedicalDetails;
}

const CREATE_INCIDENT_MEDICAL_DETAILS = gql`
mutation CreateIncidentMedicalDetails(
    $incidentMedicalDetails: IncidentMedicalDetailsCreateInput
  ) {
    createIncidentMedicalDetails(
      incidentMedicalDetails: $incidentMedicalDetails
    ) {
      id
    }
  }`