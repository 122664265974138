import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionNullableFieldType,
  LaborMetricsInterval,
  Maybe,
  Project,
  ProjectLaborMetricsInterface,
  Scalars,
} from '__generated__/graphql';
import {
  SIMPLE_PROJECT_WITH_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_MEMBER_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_METRIC_AND_SUB_CHECK_QUERY,
} from 'apollo/queries/project-queries';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  encryptUnassignedProjectId,
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils, flattenForSubProjectExport } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import _, { Dictionary, first, groupBy, isEmpty, isNil, keys, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import { mapNotNil, mapNotNull, toggleSelection } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { getMemberProjectCostCodeEquipmentMetricData } from 'utils/jitMetricQueries';
import { aggregateMetricAggregate } from 'utils/jitMetricUtils';
import { t } from 'utils/localize';
import {
  aggregateLaborMetrics,
  getGeneratedMetricLaborTotals,
  getGeneratedProjectDescendantMetricLaborTotals,
  getGeneratedProjectMetricLaborTotals,
} from 'utils/metricUtils';
import { IProjectActivityTableRowInfo } from './useProjectActivity';

export interface IProjectDateActivityTableRowInfo extends IProjectActivityTableRowInfo {
  startDate: DateTime | null;
  endDate: DateTime | null;
  subprojectData?: IProjectDateActivityTableRowInfo[];
  detailRows?: IProjectDateActivityTableRowInfo[];
}

interface ProjectMetricRow extends ProjectLaborMetricsInterface {
  projectId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useProjectActivityDateRangeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval
) {
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const { getAll } = useApolloPaging();
  const [data, setData] = useState<IProjectDateActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IProjectDateActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'project',
    SortDirection.ASCENDING,
    getSortField
  );
  const selectedProjectIds = useRef<string[]>([]);

  function idFilter() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, true);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function query() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? PROJECT_WITH_TIME_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function subProjectQuery() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return SIMPLE_PROJECT_WITH_COST_CODE_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return SIMPLE_PROJECT_WITH_MEMBER_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return SIMPLE_PROJECT_WITH_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return SIMPLE_PROJECT_WITH_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IProjectDateActivityTableRowInfo, key: keyof IProjectDateActivityTableRowInfo) {
    if (key === 'project') {
      return item.project?.title;
    } else {
      return item[key];
    }
  }

  async function loadData() {
    let filter: object;

    if (filterType === ActivityReportType.BY_PROJECT) {
      filter = {
        parentProjectId: { equal: filterId },
      };
    } else {
      filter = {
        depth: { equal: 1 },
      };
    }

    const projects = await getAll<Project & ICursorable>('projectsWithTime', {
      query: query(),
      variables: {
        first: 100,
        filter,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: intervalType,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...idFilter(),
        ...hasTimeIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const tableRows = mapNotNull(projects, (project) => createTableRowInfo(project));

    remainingItemRef.current = await createNoProjectRow();

    if (selectedProjectIds.current && !isEmpty(selectedProjectIds.current)) {
      // if we have subprojects selected then update the data with the sub project info
      setData(await updateDataWithSubprojectInfo(tableRows, selectedProjectIds.current));
    } else {
      setData(tableRows);
    }
  }

  const createTableRowInfo = (project: Project): IProjectDateActivityTableRowInfo | null => {
    const metrics = laborMetrics(project);

    const unsortedDateRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedProjectMetricLaborTotals(metric);
      const descendantData = getGeneratedProjectDescendantMetricLaborTotals(metric);

      if (
        metricTotal.totalSeconds === 0 &&
        metricTotal.totalCost === 0 &&
        descendantData.totalSeconds === 0 &&
        descendantData.totalCost === 0
      ) {
        return null;
      }

      return {
        id: project.id + metric.start,
        startDate: dateTimeFromISOWithoutZone(metric.start),
        endDate: dateTimeFromISOWithoutZone(metric.end),
        project,
        isUnassigned: false,
        descendantData,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const dateRows = sortBy(unsortedDateRows, (row) => row.startDate.toSeconds());

    if (isEmpty(dateRows)) {
      return null;
    } else {
      const projectTotalMetrics = aggregateActivityRows(dateRows);

      const rowDecendants = mapNotNull(dateRows, (row) => row.descendantData);
      const projectTotalDecendantMetrics = aggregateLaborMetrics(rowDecendants);

      return {
        ...projectTotalMetrics,
        id: project.id,
        startDate: null,
        endDate: null,
        project,
        isUnassigned: false,
        descendantData: projectTotalDecendantMetrics,
        detailRows: dateRows,
      };
    }
  };

  async function createNoProjectRow(): Promise<IProjectDateActivityTableRowInfo | undefined> {
    const metrics = await getMemberProjectCostCodeEquipmentMetricData(
      client,
      timeRange,
      canViewCost,
      intervalType,
      filterType === ActivityReportType.BY_EMPLOYEE ? filterId : undefined,
      remainingDataItemId,
      filterType === ActivityReportType.BY_COST_CODE ? filterId : undefined,
      filterType === ActivityReportType.BY_EQUIPMENT ? filterId : undefined
    );

    const metricsByDate = groupBy(metrics, (data) => data.start);
    const dates = keys(metricsByDate);
    const dateRows: IProjectDateActivityTableRowInfo[] = mapNotNull(dates, (date) => {
      const metricsOnDate = metricsByDate[date];
      const dateMetrics = aggregateMetricAggregate(metricsOnDate as IJitLaborMetric[]);

      const firstRow = first(metricsOnDate)!;

      const metricTotal = getGeneratedMetricLaborTotals(dateMetrics);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + date,
        startDate: dateTimeFromISOWithoutZone(firstRow.start),
        endDate: dateTimeFromISOWithoutZone(firstRow.end),
        project: null,
        isUnassigned: false,
        descendantData: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    if (!isEmpty(dateRows)) {
      const noCostCodeData = aggregateActivityRows(dateRows);

      return {
        project: null,
        startDate: null,
        endDate: null,
        ...noCostCodeData,
        detailRows: dateRows,
        isUnassigned: false,
        descendantData: null,
      };
    } else {
      return undefined;
    }
  }

  const laborMetrics = (project: Project): ProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return project.projectCostCodeLaborMetrics;
      case ActivityReportType.BY_EMPLOYEE:
        return project.projectMemberLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return project.projectLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return project.projectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  async function updateDataWithSubprojectInfo(
    projectData: IProjectDateActivityTableRowInfo[],
    selectedProjectIds: string[]
  ) {
    if (!isEmpty(projectData)) {
      let subprojectResult: Project[] = [];

      if (!_.isEmpty(selectedProjectIds)) {
        const projects = await getAll<Project & ICursorable>('projects', {
          query: subProjectQuery(),
          variables: {
            first: 100,
            filter: {
              parentProjectId: { contains: selectedProjectIds },
            },
            sort: [{ title: 'asc' }],
            metricsInterval: intervalType,
            metricsStartDate: timeRange.startTime.toISODate(),
            metricsEndDate: timeRange.endTime.toISODate(),
            ...idFilter(),
          },
          fetchPolicy: 'network-only',
        });

        subprojectResult = projects;
      }

      const subprojectData = mapNotNull(subprojectResult, (project) => createTableRowInfo(project));
      const subprojectDataByParent = _.groupBy(subprojectData, (item) => item.project!.parentProjectId);

      return updateProjectDataWithSubprojectData(projectData, subprojectDataByParent);
    }

    return data;
  }

  function updateProjectDataWithSubprojectData(
    projectData: IProjectDateActivityTableRowInfo[],
    subprojectDataByParent: Dictionary<IProjectDateActivityTableRowInfo[]>
  ): IProjectDateActivityTableRowInfo[] {
    return projectData
      .filter((item) => item.isUnassigned === false)
      .map((item) => {
        const subprojectItems = subprojectDataByParent[item.project!.id];
        if (subprojectItems && !_.isEmpty(subprojectItems)) {
          // the project is expanded, so add data for the project's subs
          // if we have existing subproject data on our item use that because it has the graph built in
          const subprojectData = updateProjectDataWithSubprojectData(
            !_.isEmpty(item.subprojectData) ? item.subprojectData! : subprojectItems,
            subprojectDataByParent
          );
          if (!_.isEmpty(subprojectData)) {
            // add the unassigned item if there are sub projects and the subs don't account for all of the time
            const unassignedItem = getUnassignedItem(item);
            if (unassignedItem.totalHours > 0 || unassignedItem.totalCost > 0) {
              item.subprojectData = subprojectData.concat(unassignedItem);
            } else {
              item.subprojectData = subprojectData;
            }
          } else {
            item.subprojectData = subprojectData;
          }
        } else if (!_.isNil(item.subprojectData)) {
          // the project is collapsed but we have subproject data, so remove it
          clearSubprojectData(item);
        }

        return item;
      });
  }

  const getUnassignedItem = (parentRow: IProjectDateActivityTableRowInfo): IProjectDateActivityTableRowInfo => {
    const unassignedKey = encryptUnassignedProjectId(parentRow.project!.id) + parentRow.startDate;
    const descendantData = parentRow.descendantData!;
    const detailRows = parentRow.detailRows
      ? mapNotNull(parentRow.detailRows, (detailRow) => {
        const unassignedRow = getUnassignedItem(detailRow);
        if (unassignedRow.totalHours > 0 || unassignedRow.totalCost > 0) {
          return unassignedRow;
        }

        return null;
      })
      : undefined;

    return {
      ...parentRow,
      id: unassignedKey,
      regularHours: parentRow.regularHours - descendantData.regularSeconds,
      regularHoursDec: parentRow.regularHoursDec - descendantData.regularSeconds,
      overtimeHours: parentRow.overtimeHours - descendantData.overtimeSeconds,
      overtimeHoursDec: parentRow.overtimeHoursDec - descendantData.overtimeSeconds,
      doubleTimeHours: parentRow.doubleTimeHours - descendantData.doubleTimeSeconds,
      doubleTimeHoursDec: parentRow.doubleTimeHoursDec - descendantData.doubleTimeCost,
      totalHours: parentRow.totalHours - descendantData.totalSeconds,
      totalHoursDec: parentRow.totalHoursDec - descendantData.totalSeconds,
      regularCost: parentRow.regularCost - descendantData.regularCost,
      overtimeCost: parentRow.overtimeCost - descendantData.overtimeCost,
      doubletimeCost: parentRow.doubletimeCost - descendantData.doubleTimeCost,
      laborBurden: parentRow.laborBurden - descendantData.laborBurden,
      totalCost: parentRow.totalCost - descendantData.totalCost,
      project: {
        ...parentRow.project!,
        id: encryptUnassignedProjectId(parentRow.project!.id),
        title: t('Unassigned'),
        depth: parentRow.project!.depth + 1,
        children: undefined,
      },
      isUnassigned: true,
      subprojectData: undefined,
      detailRows,
    };
  };

  function clearSubprojectData(projectData: IProjectDateActivityTableRowInfo) {
    if (!_.isEmpty(projectData.subprojectData)) {
      projectData.subprojectData?.forEach((item) => clearSubprojectData(item));
    }

    // remove from selected ids in case the parent got toggled off and the child project was expanded
    selectedProjectIds.current = selectedProjectIds.current.filter((id) => id !== projectData.project!.id);

    projectData.subprojectData = undefined;
  }

  async function getExportDataWithSubprojects() {
    // in case there are expanded projects, filter them out
    // keep the no project item
    const rootProjects = data.filter((projectData) => isNil(projectData.project) || projectData.project?.depth === 1);
    const projectIds = mapNotNil(rootProjects, (project) => {
      if (!isEmpty(project.project?.children)) {
        return project.project?.id;
      } else {
        return null;
      }
    });

    const subprojectData = flattenForSubProjectExport(await updateDataWithSubprojectInfo(rootProjects, projectIds));
    if (remainingItemRef.current) {
      return subprojectData.concat(remainingItemRef.current);
    }

    return subprojectData;
  }

  const toggleSubProject = async (project: Project) => {
    selectedProjectIds.current = toggleSelection(selectedProjectIds.current, project.id);
    setData(await updateDataWithSubprojectInfo(data, selectedProjectIds.current));
  };

  function isProjectOpen(projectId: string) {
    return selectedProjectIds.current.some((pId) => {
      return pId === projectId;
    });
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    toggleSubProject,
    isProjectOpen,
    remainingData: remainingItemRef.current,
    getExportDataWithSubprojects,
  };
}
