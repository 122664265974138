import _ from 'lodash';
import useActiveMember from '../../store/useActiveMember';

export default function useScopedMemberGroup() {
  const authMember = useActiveMember();
  const scopedByGroup = _.get(authMember, 'position.scopeByGroup', null);

  let scopedMemberGroup = null;
  let scopedMemberGroupId = null;

  if (scopedByGroup) {
    scopedMemberGroup = authMember.memberGroup;
    scopedMemberGroupId = authMember.memberGroupId;
  }

  return { scopedMemberGroup, scopedMemberGroupId };
}
