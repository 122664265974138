import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVisibleTableColumn } from 'types/TableColumn';
import { t } from 'utils/localize';

export interface IStoreActivityReports {
  tableColumns: IVisibleTableColumn[];
  equipmentTableColumns: IVisibleTableColumn[];
}

export const defaultActivityReportsColumns: IVisibleTableColumn[] = [
  {
    key: 'regularHours',
    title: t('Reg Hrs'),
    visible: true,
  },
  {
    key: 'regularHoursDec',
    title: t('Reg Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'overtimeHours',
    title: t('OT Hrs'),
    visible: true,
  },
  {
    key: 'overtimeHoursDec',
    title: t('OT Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'doubleTimeHours',
    title: t('DT Hrs'),
    visible: false,
  },
  {
    key: 'doubleTimeHoursDec',
    title: t('DT Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'totalHours',
    title: t('Total Hrs'),
    visible: true,
  },
  {
    key: 'totalHoursDec',
    title: t('Total Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'regularCost',
    title: t('Reg Cost'),
    visible: true,
  },
  {
    key: 'overtimeCost',
    title: t('OT Cost'),
    visible: true,
  },
  {
    key: 'doubletimeCost',
    title: t('DT Cost'),
    visible: false,
  },
  {
    key: 'totalCost',
    title: t('Total Cost'),
    visible: true,
  },
  {
    key: 'laborBurden',
    title: t('Labor Burden'),
    visible: true,
  },
];

export const defaultEquipmentActivityReportsColumns: IVisibleTableColumn[] = [
  {
    key: 'regularHours',
    title: t('Reg Hrs'),
    visible: true,
  },
  {
    key: 'regularHoursDec',
    title: t('Reg Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'overtimeHours',
    title: t('OT Hrs'),
    visible: true,
  },
  {
    key: 'overtimeHoursDec',
    title: t('OT Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'doubleTimeHours',
    title: t('DT Hrs'),
    visible: false,
  },
  {
    key: 'doubleTimeHoursDec',
    title: t('DT Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'totalHours',
    title: t('Total Hrs'),
    visible: true,
  },
  {
    key: 'totalHoursDec',
    title: t('Total Hrs (Decimal)'),
    visible: false,
  },
  {
    key: 'regularCost',
    title: t('Reg Cost (Labor)'),
    visible: true,
  },
  {
    key: 'overtimeCost',
    title: t('OT Cost (Labor)'),
    visible: true,
  },
  {
    key: 'doubletimeCost',
    title: t('DT Cost (Labor)'),
    visible: false,
  },
  {
    key: 'totalCost',
    title: t('Total Cost (Labor)'),
    visible: true,
  },
  {
    key: 'laborBurden',
    title: t('Labor Burden'),
    visible: true,
  },
  {
    key: 'equipmentRate',
    title: t('Equip. Rate'),
    visible: true,
  },
  {
    key: 'equipmentCost',
    title: t('Equip. Cost'),
    visible: true,
  },
  {
    key: 'equipmentTotalCost',
    title: t('Total Cost'),
    visible: true,
  },
];

export const initialState: IStoreActivityReports = {
  tableColumns: [],
  equipmentTableColumns: [],
};

export const slice = createSlice({
  name: 'activityReports',
  initialState,
  reducers: {
    updateActivityReportTableColumns: (state: IStoreActivityReports, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.tableColumns = action.payload;

      return state;
    },
    updateEquipmentActivityReportTableColumns: (
      state: IStoreActivityReports,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.equipmentTableColumns = action.payload;

      return state;
    },
  },
});

export const { updateActivityReportTableColumns, updateEquipmentActivityReportTableColumns } = slice.actions;
export default slice.reducer;
