import { ClassName } from '@busybusy/webapp-react-ui';
import { useMemo } from 'react';
import ColumnForm, { ColumnSettings, IColumnFormProps } from '../ColumnForm/ColumnForm';
import { IColumnFormItem, ISimpleColumnFormItem } from '../types/types';
import aggregateTableColumns from '../utils/utils';

export interface ISimpleColumnFormProps<K extends string = string>
  extends Omit<IColumnFormProps<K>, 'columns' | 'onSubmit'> {
  onSubmit: (columns: Array<IColumnFormItem<K>>) => void;
  columns: ISimpleColumnFormItem<K>[];
  className?: ClassName;
}

function SimpleColumnForm<K extends string = string>({
  columns: options,
  onSubmit,
  ...rest
}: ISimpleColumnFormProps<K>) {
  const mapped = useMemo(
    () => [
      options.map((option, index) => ({
        ...option,
        id: option.key,
        draggable: true,
        position: option.position ?? index,
      })),
    ],
    [options]
  );

  function onFormSubmit(columns: ColumnSettings<K>) {
    onSubmit(aggregateTableColumns(columns));
  }

  return <ColumnForm {...rest} columns={mapped} onSubmit={onFormSubmit} />;
}

export default SimpleColumnForm;
