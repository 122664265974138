import { TimeEntryLogActionTypePickerAvailableValue } from 'components/domain/time-entry-logs/TimeEntryLogActionTypePicker/TimeEntryLogActionTypePicker';
import { TimeEntryLogActionTypeFilter } from 'containers/time-log-feed/types/types';
import ClockActionType from 'types/enum/ClockActionType';

export const timeEntryLogActionTypeToQueryParamMap: Record<
  TimeEntryLogActionTypePickerAvailableValue,
  TimeEntryLogActionTypeFilter
> = {
  [ClockActionType.CLOCK_IN]: 'clock-in',
  [ClockActionType.CLOCK_OUT]: 'clock-out',
  [ClockActionType.CLOCK_IN_AT]: 'clock-in-at',
  [ClockActionType.CLOCK_OUT_AT]: 'clock-out-at',
  [ClockActionType.DELETE]: 'delete',
  [ClockActionType.EDIT]: 'edit',
  [ClockActionType.EDIT_BREAKS]: 'edit-breaks',
  [ClockActionType.MANUAL]: 'manual',
};

export const timeEntryLogQueryParamToActionTypeMap: Record<
  TimeEntryLogActionTypeFilter,
  TimeEntryLogActionTypePickerAvailableValue
> = {
  'clock-in': ClockActionType.CLOCK_IN,
  'clock-out': ClockActionType.CLOCK_OUT,
  'clock-in-at': ClockActionType.CLOCK_IN_AT,
  'clock-out-at': ClockActionType.CLOCK_OUT_AT,
  'delete': ClockActionType.DELETE,
  'edit': ClockActionType.EDIT,
  'edit-breaks': ClockActionType.EDIT_BREAKS,
  'manual': ClockActionType.MANUAL,
};
