import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { favicons } from 'assets/favicons';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { IStoreEnvironment } from 'store/store';

interface IHtmlHeadProps {
  environment: IStoreEnvironment;
}

const HtmlHead = (props: IHtmlHeadProps) => {
  const { environment } = props;
  const env = environment.REACT_APP_ENV!;

  let favicon = favicons.production;

  const brand = useBrandTitle();
  const titleTemplate = [brand, `%s`];
  const titleTemplateDelimiter = ' - ';

  if (env !== 'production') {
    const version = environment.REACT_APP_VERSION!;

    titleTemplate.unshift(`${version}`);
    favicon = favicons[env];
  }

  return (
    <Helmet titleTemplate={titleTemplate.join(titleTemplateDelimiter)} defaultTitle={brand}>
      <link rel="shortcut icon" href={favicon} />
    </Helmet>
  );
};

const mapStateToProps = ({ environment }: IHtmlHeadProps) => {
  return { environment };
};

export default connect(mapStateToProps)(HtmlHead);
