import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Spinner from 'components/foundation/Spinner/Spinner';
import DashboardRibbon, { IDashboardRibbonProps } from '../DashboardRibbon/DashboardRibbon';
import './DashboardContentRibbon.scss';

export interface IDashboardContentRibbonProps extends Omit<IDashboardRibbonProps, 'children'> {
  title: string;
  subtitle: string;
  value: string;
  subvalue?: string;
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
  isLoading: boolean;
}

const DashboardContentRibbon = (props: IDashboardContentRibbonProps) => {
  const { title, subtitle, value, subvalue, theme, className, isLoading, ...dashboardRibbonProps } = props;

  const classes = classNames('dashboard-content-ribbon', theme, className);

  return (
    <DashboardRibbon className={classes} theme={theme} {...dashboardRibbonProps}>
      <div className="dashboard-ribbon-label">
        <span className="dashboard-ribbon-label-title">{title}</span>
        <span className="dashboard-ribbon-label-subtitle">{subtitle}</span>
      </div>
      <div className="dashboard-ribbon-values">
        <div className={classNames('dashboard-ribbon-value', { subvalue })}>{isLoading ? <Spinner /> : value}</div>
        {subvalue && <div className="dashboard-ribbon-label-subtitle subvalue">{subvalue}</div>}
      </div>
    </DashboardRibbon>
  );
};

export default DashboardContentRibbon;
