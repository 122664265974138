import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import {
  getDefaultDashboardListRow,
  IDashboardListRow,
} from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { useDashboardTimeRange } from 'containers/dashboard/hooks';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useApolloPaging, useAsync, useOrganization } from 'hooks';
import { first, isNil, take } from 'lodash';
import { Duration } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import ICursorable from 'types/Cursorable';
import IProject from 'types/Project';
import { mapNotNull, takeWithPopulate } from 'utils/collectionUtils';
import { getMetricLaborTotals } from 'utils/jitMetricUtils';
import { sortByField } from 'utils/tableUtils';
import { LaborMetricsInterval } from '__generated__/graphql';

export default function useActiveProjectDashboard(
  timeFrame: DashboardTimeFrame,
  memberId?: string | null,
  costCodeId?: string | null,
  equipmentId?: string | null
) {
  const timeRange = useDashboardTimeRange(timeFrame);
  const organization = useOrganization();
  const { getAll } = useApolloPaging();

  const getData = useCallback(() => {
    let query = PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY;
    if (!isNil(memberId)) {
      query = PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
    } else if (!isNil(costCodeId)) {
      query = PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
    } else if (!isNil(equipmentId)) {
      query = PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
    }

    return getAll<IProject & ICursorable>('projectsWithTime', {
      query: query,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        filter: {
          depth: { equal: 1 },
          archivedOn: { isNull: true },
        },
        sort: [{ title: 'asc' }],
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        startTime: timeRange.startTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: timeRange.endTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: LaborMetricsInterval.Custom,
        memberId: !isNil(memberId) ? { equal: memberId } : undefined,
        memberIds: !isNil(memberId) ? [memberId] : undefined,
        costCodeId: !isNil(costCodeId) ? { equal: costCodeId } : undefined,
        costCodeIds: !isNil(costCodeId) ? [costCodeId] : undefined,
        equipmentId: !isNil(equipmentId) ? { equal: equipmentId } : undefined,
        equipmentIds: !isNil(equipmentId) ? [equipmentId] : undefined,
      },
    });
  }, [memberId, costCodeId, equipmentId, getAll, timeRange.startTime, timeRange.endTime]);

  const { execute, loading, error, data } = useAsync(getData, false);
  const rowData: IDashboardListRow[] = useMemo(() => {
    const titleToTotal = mapNotNull(data ?? [], (project) => {
      let total = 0;

      if (!isNil(memberId)) {
        total = getMetricLaborTotals(first(project.projectMemberLaborMetrics))?.totalSeconds ?? 0;
      } else if (!isNil(costCodeId)) {
        total = getMetricLaborTotals(first(project.projectCostCodeLaborMetrics))?.totalSeconds ?? 0;
      } else if (!isNil(equipmentId)) {
        total = getMetricLaborTotals(first(project.projectEquipmentLaborMetrics))?.totalSeconds ?? 0;
      } else {
        total = getMetricLaborTotals(first(project.projectLaborMetrics))?.totalSeconds ?? 0;
      }

      return total > 0
        ? {
            id: project.id,
            title: project.title,
            total,
          }
        : null;
    });

    const activeProjects = take(sortByField(titleToTotal, 'total', 'DESC'), 5).map((item) => {
      return {
        value: Duration.fromMillis(item.total * 1000).toFormat('h:mm'),
        label: item.title,
        id: item.id,
      };
    });

    return takeWithPopulate(activeProjects, 5, getDefaultDashboardListRow);
  }, [data]);

  useEffect(() => {
    if (organization.trackProject) {
      execute();
    }
  }, [timeFrame, organization.trackProject, execute, memberId, costCodeId, equipmentId]);

  return {
    data: rowData,
    loading,
    error,
    execute,
  };
}
