import { IDialogProps } from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import { ITableRowHeightFormData } from 'components/foundation/table/TableRowHeightForm/TableRowHeightForm';
import TableRowsDialog from 'components/foundation/table/TableRowsDialog/TableRowsDialog';
import useTableRowHeightApplyAll from 'hooks/models/member-settings/useTableRowHeightApplyAll';

export interface IRowSettingsDialogProps extends Omit<IDialogProps, 'children'> {
  onSubmit: (height: TableCellHeight) => void;
  initialValue: TableCellHeight;
}

const RowSettingsDialog = (props: IRowSettingsDialogProps) => {
  const { onSubmit, initialValue } = props;

  const setAllCellHeightValues = useTableRowHeightApplyAll();

  function submit(data: ITableRowHeightFormData) {
    if (data.applyToAll) {
      setAllCellHeightValues(data.cellHeight);
    } else {
      onSubmit(data.cellHeight);
    }
  }

  return <TableRowsDialog {...props} initialValue={initialValue} onSubmit={submit} />;
};

export default RowSettingsDialog;
