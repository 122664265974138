import { useMutation } from '@apollo/client';
import { CREATE_MEMBER_TIME_DOCUMENT, UPDATE_MEMBER_TIME_DOCUMENT } from 'apollo/mutations/member-time-document';
import { DateTime } from 'luxon';
import { IMemberTimeDocumentCreateInput, IMemberTimeDocumentUpdateInput } from 'types/MemberTimeDocument';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';

export default function useMemberTimeDocument() {
  const [createDocumentMutation] = useMutation(CREATE_MEMBER_TIME_DOCUMENT);
  const [updateDocumentMutation] = useMutation(UPDATE_MEMBER_TIME_DOCUMENT);

  function createMemberTimeDocument(startTime: DateTime, endTime: DateTime, memberId: string) {
    const entry: IMemberTimeDocumentCreateInput = {
      startTime: startTime.toISO(),
      endTime: endTime.endOf('day').toISO(),
      memberId,
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return createDocumentMutation({ variables: { timeDocument: entry } });
  }

  function updateMemberTimeDocument(
    id: string,
    selfSignatureId?: string,
    authoritativeSignatureId?: string,
    canceled?: string | null,
    deletedOn?: string | null
  ) {
    const entry: IMemberTimeDocumentUpdateInput = {
      id,
      canceled,
      selfSignatureId,
      authoritativeSignatureId,
      deletedOn,
    };

    return updateDocumentMutation({ variables: { timeDocument: entry } });
  }

  return { createMemberTimeDocument, updateMemberTimeDocument };
}
