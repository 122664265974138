import ActivityReportTableDataLoader from 'containers/activity-reports/types/ActivityReportTableDataLoader';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import { LaborMetricsInterval } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { IDateActivityTableRowInfo } from './useDateActivity';
import useDateActivityBasicDetails from './useDateActivityBasicDetails';
import useDateActivityCostCodeDetails from './useDateActivityCostCodeDetails';
import useDateActivityDayDetails from './useDateActivityDayDetails';
import useDateActivityEquipmentDetails from './useDateActivityEquipmentDetails';
import useDateActivityMemberDetails from './useDateActivityMemberDetails';
import useDateActivityProjectDetails from './useDateActivityProjectDetails';

export default function useDateActivityDetails(
  filterId: string,
  filterType: ActivityReportType,
  secondaryFilterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
): ActivityReportTableDataLoader<IDateActivityTableRowInfo> {
  const [isLoading, setIsLoading] = useState(false);

  const basicDetails = useDateActivityBasicDetails(
    filterId,
    filterType,
    timeRange,
    intervalType,
    filterIdWithDescendants
  );
  const memberDetails = useDateActivityMemberDetails(
    filterId,
    filterType,
    timeRange,
    intervalType,
    filterIdWithDescendants
  );
  const costCodeDetails = useDateActivityCostCodeDetails(
    filterId,
    filterType,
    timeRange,
    intervalType,
    filterIdWithDescendants
  );
  const equipmentDetails = useDateActivityEquipmentDetails(
    filterId,
    filterType,
    timeRange,
    intervalType,
    filterIdWithDescendants
  );
  const projectDetails = useDateActivityProjectDetails(filterId, filterType, timeRange, intervalType);
  const dayDetails = useDateActivityDayDetails(filterId, filterType, timeRange, intervalType);

  const dataLoader = useMemo(() => {
    if (secondaryFilterType === ActivityReportType.BASIC) {
      return basicDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_EMPLOYEE) {
      return memberDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_COST_CODE) {
      return costCodeDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_EQUIPMENT) {
      return equipmentDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_PROJECT) {
      return projectDetails;
    } else if (secondaryFilterType === ActivityReportType.BY_DAY) {
      return dayDetails;
    } else {
      throw Error('Not supported secondary report type when loading members: ' + secondaryFilterType);
    }
  }, [basicDetails, memberDetails, costCodeDetails, equipmentDetails, projectDetails]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, filterType, filterId, secondaryFilterType, intervalType]);

  async function loadData() {
    setIsLoading(true);
    await dataLoader.loadData();
    setIsLoading(false);
  }

  const data = useMemo(() => {
    const dataFromLoader = dataLoader.sortedData as IDateActivityTableRowInfo[];
    if (secondaryFilterType === ActivityReportType.BY_EMPLOYEE && !isNil(memberDetails.restrictedData)) {
      return dataFromLoader.concat(memberDetails.restrictedData);
    }

    return dataFromLoader;
  }, [secondaryFilterType, memberDetails.restrictedData, dataLoader.sortedData]);

  return {
    isLoading: isLoading,
    sortedData: data,
    onSort: dataLoader.onSort,
    sortedBy: dataLoader.sortedBy as keyof IDateActivityTableRowInfo,
    sortDirection: dataLoader.sortDirection,
    sortIsDirty: dataLoader.sortIsDirty,
  };
}
