import { useApolloClient } from '@apollo/client';
import { DatePicker, Dialog, Label, Loader, Position, Theme, Toast } from '@busybusy/webapp-react-ui';
import { CREATE_EQUIPMENT, UPDATE_EQUIPMENT } from 'apollo/mutations/equipment-mutations';
import { EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { equipmentCategoriesWithTypeQuery } from 'components/domain/equipment/EquipmentCategoriesForTypePicker/equipment-cateory-picker-queries';
import { equipmentMakesQuery } from 'components/domain/equipment/EquipmentMakesForCategoryPicker/equipment-makes-picker-queries';
import {
  equipmentModelsQuery,
  equipmentUnknownModelQuery,
} from 'components/domain/equipment/EquipmentModelsForMakePicker/equipment-models-picker-queries';
import { useApolloPaging, useEquipmentHours, useOpenable, useOrganization } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useCreateEquipmentCostHistory from 'hooks/models/equipment-cost-history/useCreateEquipmentCostHistory';
import _, { cloneDeep, first, includes, isEmpty, isNil, isNull, keyBy, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ICsvParseResult from 'types/CsvParseResult';
import IEquipment from 'types/Equipment';
import IEquipmentCategory from 'types/EquipmentCategory';
import IEquipmentMake from 'types/EquipmentMake';
import IEquipmentModel from 'types/EquipmentModel';
import { dateUtils } from 'utils';
import { EQUIPMENT_CSV_HEADERS } from 'utils/constants/csvConstants';
import { isoTimeStampUtc } from 'utils/dateUtils';
import { getCurrentEquipmentCostHistory } from 'utils/equipmentUtils';
import fileUtils, { downloadText } from 'utils/fileUtils';
import { t } from 'utils/localize';
import { isNumberString } from 'utils/numberUtils';
import { convertEmptyToNull } from 'utils/stringUtils';
import { uuid } from 'utils/uuidUtils';
import CsvImportDialog from '../../csv-import/CsvImportDialog/CsvImportDialog';
import CsvImportOverview from '../../csv-import/CsvImportOverview/CsvImportOverview';
import CsvImportReview from '../../csv-import/CsvImportReview/CsvImportReview';
import ReimportContent from '../../csv-import/ReimportContent/ReimportContent';
import ICSVEquipment, {
  IEquipmentCsvImportErrorState,
  equipmentImportErrorMap,
} from '../../csv-import/types/ImportEquipmentTypes';
import {
  getCsvIdErrors,
  getErrorMessages,
  parseCsvForReimport,
  parseCsvWithDefaults,
  validateDataStructure,
  validateHeaders,
} from '../../csv-import/utils/utils';
import CSVImportEquipmentOverviewTable from './CSVImportEquipmentOverviewTable/CSVImportEquipmentOverviewTable';
import CSVImportEquipmentReviewTable from './CSVImportEquipmentReviewTable/CSVImportEquipmentReviewTable';
import useEquipmentCSVValidation from './hooks/useEquipmentCSVValidation';
import {
  convertCsvEquipmentToEquipment,
  convertCsvEquipmentToUpdateEquipment,
  getEquipmentCsvHeaders,
} from './utils/utils';

export interface IEquipmentCategoryCSV {
  category: string;
}

export interface IImportedEquipment {
  name: string;
  category: string;
  model: string;
  make: string;
  year: string;
  hourMeter: string;
  modelId: string;
  trackHourMeter: boolean;
  success: boolean;
}

export interface ICSVImportEquipmentContainerProps {
  className?: ClassName;
  importDialogOpen: boolean;
  onImportDialogClose: () => void;
  onImportComplete: () => void;
  onReimportDialogClose: () => void;
  reimportDialogOpen: boolean;
  onDownloadList: () => void;
}

const CSVImportEquipmentContainer = (props: ICSVImportEquipmentContainerProps) => {
  const {
    className,
    importDialogOpen,
    onImportDialogClose,
    onImportComplete,
    onReimportDialogClose,
    reimportDialogOpen,
    onDownloadList,
  } = props;

  const loaderDetails = useOpenable();
  const [loaderMessage, setLoaderMessage] = useState<string>();
  const toastDetails = useOpenable();
  const [toastErrorMessage, setToastErrorMessage] = useState<string | null>(null);
  const [printing, setPrinting] = useState(false);
  const [errors, setErrors] = useState<IEquipmentCsvImportErrorState[]>([]);
  const [prevalidationErrors, setPrevalidationErrors] = useState<string[]>([]);
  const [equipment, setEquipment] = useState<ICSVEquipment[]>([]);
  const [importedEquipment, setImportedEquipment] = useState<IImportedEquipment[]>([]);
  const equipmentOverviewDialogDetails = useOpenable({ onClose: onDialogClose });
  const equipmentReviewDialogDetails = useOpenable({ onClose: onDialogClose });
  const overviewRef = useRef<HTMLElement>();
  const reviewRef = useRef<HTMLElement>();
  const validate = useEquipmentCSVValidation();
  const client = useApolloClient();
  const { getAll } = useApolloPaging();
  const organization = useOrganization();
  const { createEquipmentHours } = useEquipmentHours();
  const [isReimport, setIsReimport] = useState<boolean>(false);
  const createEquipmentCostHistory = useCreateEquipmentCostHistory();
  const datePickerOpenable = useOpenable();
  const [costHistoryEffectiveDate, setCostHistoryEffectiveDate] = useState(DateTime.local().startOf('day').toISO());
  const brand = useBrandTitle();

  async function getAllCategories() {
    const currentEquipmentCategories = await getAll<IEquipmentCategory>('equipmentCategories', {
      fetchPolicy: 'network-only',
      query: equipmentCategoriesWithTypeQuery,
      variables: {
        first: 500,
        filter: {
          deletedOn: { isNull: true },
          review: { equal: false },
        },
      },
    });
    const categories = currentEquipmentCategories.filter((c) => c.type.title !== 'unknown');
    return categories.map((c) => {
      return {
        category: c.title,
      };
    });
  }

  async function getUnknownModel(categoryId: string): Promise<IEquipmentModel | undefined> {
    const results = await client.query<{ equipmentModels: IEquipmentModel[] }>({
      fetchPolicy: 'network-only',
      query: equipmentUnknownModelQuery,
      variables: {
        categoryId,
      },
    });
    return first(results.data.equipmentModels);
  }

  async function getCategory(categoryTitle: string | undefined) {
    if (!isNil(convertEmptyToNull(categoryTitle))) {
      try {
        const results = await client.query<{ equipmentCategories: IEquipmentCategory[] }>({
          query: equipmentCategoriesWithTypeQuery,
          fetchPolicy: 'network-only',
          variables: {
            first: 1,
            filter: {
              title: categoryTitle ? { equal: categoryTitle } : categoryTitle,
              deletedOn: { isNull: true },
            },
          },
        });
        return first(results.data.equipmentCategories.filter((c) => c.type.title !== 'unknown'));
      } catch (error) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  async function getMake(makeTitle: string | undefined) {
    if (!isNil(convertEmptyToNull(makeTitle))) {
      const results = await client.query<{ equipmentMakes: IEquipmentMake[] }>({
        query: equipmentMakesQuery,
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
          filter: {
            title: makeTitle ? { equal: makeTitle } : undefined,
            deletedOn: { isNull: true },
          },
        },
      });
      return first(results.data.equipmentMakes);
    } else {
      return undefined;
    }
  }

  async function getModel(
    modelTitle: string | undefined,
    year: string | undefined,
    makeId?: string | null,
    categoryId?: string | null
  ) {
    if (!isNil(convertEmptyToNull(modelTitle))) {
      try {
        const results = await client.query<{ equipmentModels: IEquipmentModel[] }>({
          query: equipmentModelsQuery,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              equipmentCategoryId: categoryId ? { equal: categoryId } : undefined,
              equipmentMakeId: makeId ? { equal: makeId } : undefined,
              title: modelTitle ? { equal: modelTitle } : undefined,
              deletedOn: { isNull: true },
            },
          },
        });
        return first(
          results.data.equipmentModels
            .filter((model) => !isNil(model.year))
            .filter((model) => includes(model.year.toString(), year))
        );
      } catch (error) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    if (printing) {
      window.print();
      setPrinting(false);
    }
  }, [printing]);

  function onDialogClose() {
    setTimeout(() => {
      setEquipment([]);
      setErrors([]);
    }, 250);
  }

  function closeImportDialog() {
    setErrors([]);
    setPrevalidationErrors([]);
    setEquipment([]);
    onDialogClose();
    onImportDialogClose();
    onReimportDialogClose();
  }

  function openErrorToastWithMessage(message: string) {
    setToastErrorMessage(message);
    toastDetails.open();
  }

  function openLoaderDetailsWithMessage(message: string) {
    setLoaderMessage(message);
    loaderDetails.open();
  }

  function clearData() {
    setEquipment([]);
    setErrors([]);
    setPrevalidationErrors([]);
  }

  function parseCsv(reimport: boolean) {
    return async (file: File) => {
      clearData();

      const csvParseFn = reimport ? parseCsvForReimport : parseCsvWithDefaults;

      try {
        if (file.name.indexOf('.csv') !== -1) {
          openLoaderDetailsWithMessage(t('Parsing...'));
          csvParseFn(file, onCsvParseComplete(reimport));
        } else if (file.name.indexOf('.xlsx') !== -1) {
          openLoaderDetailsWithMessage(t('Parsing...'));
          csvParseFn(await fileUtils.convertXlsxToCsv(file), onCsvParseComplete(reimport));
        } else {
          openErrorToastWithMessage(t('Invalid file format. Expected either csv or xlsx.'));
        }
      } catch (e) {
        openErrorToastWithMessage(t('Invalid file format. Expected either csv or xlsx.'));
      }
    };
  }

  function onCsvParseComplete(reimport: boolean) {
    setIsReimport(reimport);
    return reimport ? csvReimport : csvImport;
  }

  async function csvImport(parseResult: ICsvParseResult<any>) {
    openLoaderDetailsWithMessage(t('Validating...'));

    const validStructure = validateStructure(parseResult, false);
    if (!validStructure) {
      loaderDetails.close();
      return;
    }

    // Cast is safe since it now has an id.
    const csvEquipment = convertParseResult(parseResult).map((csvEquipment) => ({
      id: uuid(),
      ...csvEquipment,
      reimport: false,
    })) as ICSVEquipment[];

    const validData = await validateData(csvEquipment, false);

    if (!validData) {
      loaderDetails.close();
      return;
    }

    setLoaderMessage('Populating Data...');
    await populateData(csvEquipment);
    loaderDetails.close();
  }

  async function csvReimport(parseResult: ICsvParseResult<any>) {
    const reimport = true;
    setLoaderMessage(t('Validating...'));

    const validStructure = validateStructure(parseResult, true);
    if (!validStructure) {
      loaderDetails.close();
      return;
    }

    const csvEquipment = convertParseResult(parseResult).map((csvEquipment) => ({
      ...csvEquipment,
      reimport,
    })) as ICSVEquipment[];

    const errs: IEquipmentCsvImportErrorState[] = getCsvIdErrors(csvEquipment);
    if (!isEmpty(errs)) {
      setErrors(errs);
      loaderDetails.close();
      return null;
    }

    // This needs to happen before the validation so we have all the columns populated for sure.
    const populatedEquipment = await populateReimportData(csvEquipment);

    if (isNull(populatedEquipment)) {
      loaderDetails.close();
      return;
    }

    const validData = await validateData(populatedEquipment ?? [], reimport);

    if (!validData) {
      loaderDetails.close();
      return;
    }

    onReimportDialogClose();
    setErrors([]);
    setPrevalidationErrors([]);

    equipmentOverviewDialogDetails.open();
    setTimeout(() => {
      // Wait for dialog refs to get set
      setEquipment(_.sortBy(populatedEquipment, (e) => _.toLower(e.name)));
    }, 0);

    loaderDetails.close();
  }

  function convertParseResult(parseResult: ICsvParseResult<any>): Array<Partial<ICSVEquipment>> {
    return _.map(parseResult.data, (result) =>
      _.mapKeys(result, (_value, key) => _.camelCase(_.toLower(key)))
    ) as ICSVEquipment[];
  }

  function validateStructure(parseResult: ICsvParseResult<any>, reimport: boolean) {
    const structureError = validateDataStructure(parseResult);

    if (structureError) {
      setPrevalidationErrors([structureError]);
      return false;
    }

    const headers = parseResult.meta.fields?.map((field) => (field === 'id' ? 'DATA KEY' : field));
    const equipmentHeaders = getEquipmentCsvHeaders().split(',');
    const allowedHeaders = reimport ? equipmentHeaders.concat('DATA KEY') : equipmentHeaders;
    const requiredHeaders = reimport ? ['DATA KEY'] : ['Name'];

    const headerErrs = validateHeaders(headers ?? [], allowedHeaders, requiredHeaders);

    if (headerErrs.length !== 0) {
      setPrevalidationErrors(headerErrs);
      return false;
    } else if (parseResult.data.length === 0) {
      setErrors([{ type: 'EMPTY_CSV', lineNumber: null }]);
      return false;
    } else {
      return true;
    }
  }

  async function validateData(csvEquipment: Array<Partial<ICSVEquipment>>, reimport: boolean) {
    const validations = await validate(csvEquipment, reimport);
    if (validations.length > 0) {
      setErrors(validations);
      return false;
    } else {
      return true;
    }
  }

  async function populateData(csvEquipment: ICSVEquipment[]) {
    const equipmentValues = csvEquipment.map(async (equipment) => {
      const temp = { ...equipment };

      const fetchedCategory = await getCategory(temp.category);
      if (fetchedCategory) {
        temp.categoryId = fetchedCategory.id;
        temp.category = fetchedCategory.title;

        const fetchedMake = await getMake(temp.make);
        if (fetchedMake) {
          temp.makeId = fetchedMake.id;
          temp.make = fetchedMake.title;
        } else {
          temp.make = 'unknown'; // Not localized because the "unknown" model object is not.
          temp.makeId = null;
          temp.model = 'unknown'; // Not localized because the "unknown" model object is not.
          temp.year = 'unknown'; // Not localized because the "unknown" model object is not.
          const model = await getUnknownModel(fetchedCategory.id);
          temp.modelId = model?.id;
        }

        const fetchedModel = await getModel(temp.model, temp.year, fetchedMake?.id, fetchedCategory?.id);
        if (fetchedModel) {
          temp.modelId = fetchedModel.id;
          temp.model = fetchedModel.title;
        } else {
          temp.model = 'unknown'; // Not localized because the "unknown" model object is not.
          const model = await getUnknownModel(fetchedCategory.id);
          temp.modelId = model?.id;
          temp.year = 'unknown'; // Not localized because the "unknown" model object is not.
          temp.make = 'unknown'; // Not localized because the "unknown" model object is not.
          temp.makeId = null;
        }
      } else {
        temp.category = 'unknown'; // Not localized because the "unknown" model object is not.
        temp.categoryId = null;
        temp.make = 'unknown'; // Not localized because the "unknown" model object is not.
        temp.makeId = null;
        temp.model = 'unknown'; // Not localized because the "unknown" model object is not.
        temp.year = 'unknown'; // Not localized because the "unknown" model object is not.
        temp.modelId = null;
      }

      return temp;
    });

    Promise.all(equipmentValues).then((values) => {
      // Wait for dialog ref
      setTimeout(() => {
        setEquipment(_.sortBy(values, (e) => _.toLower(e.name)));
      }, 0);

      loaderDetails.close();
      onImportDialogClose();
      onReimportDialogClose();
      setErrors([]);

      equipmentOverviewDialogDetails.open();
    });
  }

  async function populateReimportData(csvEquipment: ICSVEquipment[]) {
    const grouped = keyBy(csvEquipment, 'id');

    try {
      const currentEquipment = await getAll<IEquipment>('equipment', {
        fetchPolicy: 'network-only',
        query: EQUIPMENT_QUERY,
        variables: {
          filter: {
            id: { contains: Object.keys(grouped) },
          },
        },
      });

      const queriedEquipmentMap = keyBy(currentEquipment, 'id');
      if (currentEquipment) {
        const promises = csvEquipment.map(async (equipment) => {
          // This is not necessarily safe yet! This has not validated that the equipment exists for this id yet! Rethink! TODO
          const queried = queriedEquipmentMap[equipment.id];
          const combinedEquipment = cloneDeep(equipment);

          if (isNil(combinedEquipment.name)) {
            combinedEquipment.name = queried.equipmentName;
          }

          if (isNil(combinedEquipment.category)) {
            combinedEquipment.category = queried.model.category.title;
            combinedEquipment.categoryId = queried.model.category.id;
          }

          if (isNil(combinedEquipment.make)) {
            combinedEquipment.make = queried.model.make.title;
            combinedEquipment.makeId = queried.model.make.id;
          }

          if (isNil(combinedEquipment.model)) {
            combinedEquipment.model = queried.model.title;
            combinedEquipment.modelId = queried.model.id;
          }

          if (isNil(combinedEquipment.year)) {
            combinedEquipment.year = queried.model.year.toString();
          }

          const fetchedCategory = await getCategory(combinedEquipment.category);
          if (fetchedCategory) {
            combinedEquipment.categoryId = fetchedCategory.id;
            combinedEquipment.category = fetchedCategory.title;

            const fetchedMake = await getMake(combinedEquipment.make);
            if (fetchedMake) {
              combinedEquipment.makeId = fetchedMake.id;
              combinedEquipment.make = fetchedMake.title;
            } else {
              combinedEquipment.make = 'unknown'; // Not localized because the "unknown" model object is not.
              combinedEquipment.makeId = null;
              combinedEquipment.model = 'unknown'; // Not localized because the "unknown" model object is not.
              combinedEquipment.year = 'unknown'; // Not localized because the "unknown" model object is not.
              const model = await getUnknownModel(fetchedCategory.id);
              combinedEquipment.modelId = model?.id;
            }

            const fetchedModel = await getModel(
              combinedEquipment.model,
              combinedEquipment.year,
              fetchedMake?.id,
              fetchedCategory?.id
            );
            if (fetchedModel) {
              combinedEquipment.modelId = fetchedModel.id;
              combinedEquipment.model = fetchedModel.title;
            } else {
              combinedEquipment.model = 'unknown'; // Not localized because the "unknown" model object is not.
              const model = await getUnknownModel(fetchedCategory.id);
              combinedEquipment.modelId = model?.id;
              combinedEquipment.year = 'unknown'; // Not localized because the "unknown" model object is not.
              combinedEquipment.make = 'unknown'; // Not localized because the "unknown" model object is not.
              combinedEquipment.makeId = null;
            }
          }

          if (isNil(combinedEquipment.hourMeter) && !isNil(queried.lastHours?.runningHours)) {
            combinedEquipment.hourMeter = queried.lastHours!.runningHours.toFixed(1);
          }

          if (
            isNil(combinedEquipment.hourlyRate) &&
            !isNil(getCurrentEquipmentCostHistory(queried.costHistory ?? [])?.operatorCostRate)
          ) {
            combinedEquipment.hourlyRate = (
              getCurrentEquipmentCostHistory(queried.costHistory ?? [])?.operatorCostRate ?? 0
            ).toFixed(2);
          }

          return combinedEquipment;
        });
        return Promise.all(promises);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  function onPrintReview() {
    setPrinting(true);
  }

  function setOverviewRef(ref: HTMLElement | null) {
    if (ref) {
      overviewRef.current = ref;
    }
  }

  function setReviewRef(ref: HTMLElement | null) {
    if (ref) {
      reviewRef.current = ref;
    }
  }

  const createEquipment = async (e: ICSVEquipment) => {
    const object = convertCsvEquipmentToEquipment(e, organization.id!);
    try {
      const equipmentResponse = await client.mutate<{ createEquipment: IEquipment }>({
        mutation: CREATE_EQUIPMENT,
        variables: {
          equipment: { ...object, createdOn: isoTimeStampUtc() },
        },
      });

      let newEquipment = cloneDeep(equipmentResponse.data?.createEquipment);

      if (newEquipment && object.trackManualHours && e.hourMeter && isNumberString(e.hourMeter)) {
        const hourResponse = await createEquipmentHours(object.id, toNumber(e.hourMeter));
        newEquipment = { ...newEquipment, lastHours: hourResponse.data?.createEquipmentHours };
      }

      if (newEquipment && e.hourlyRate && isNumberString(e.hourlyRate)) {
        const costHistoryResponse = await createEquipmentCostHistory(
          object.id,
          undefined,
          toNumber(e.hourlyRate),
          costHistoryEffectiveDate
        );
        newEquipment = { ...newEquipment, costHistory: [costHistoryResponse] };
      }

      return {
        previousEquipment: e,
        mutatedEquipment: { ...equipmentResponse, data: { createEquipment: newEquipment } },
      };
    } catch (error) {
      return {
        previousEquipment: e,
        mutatedEquipment: { data: { createEquipment: undefined } },
      };
    }
  };

  const updateEquipment = async (e: ICSVEquipment) => {
    const object = convertCsvEquipmentToUpdateEquipment(e);

    const equipmentResponse = await client.mutate<{ updateEquipment: IEquipment }>({
      mutation: UPDATE_EQUIPMENT,
      variables: { equipment: object },
    });

    let updatedEquipment = cloneDeep(equipmentResponse.data?.updateEquipment);

    if (updatedEquipment && object.trackManualHours && e.hourMeter && isNumberString(e.hourMeter)) {
      const newHourMeterValue = toNumber(e.hourMeter);
      if (newHourMeterValue !== updatedEquipment.lastHours?.runningHours) {
        const hourResponse = await createEquipmentHours(object.id, toNumber(e.hourMeter));
        updatedEquipment = { ...updatedEquipment, lastHours: hourResponse.data?.createEquipmentHours };
      }
    }

    if (updatedEquipment && e.hourlyRate && isNumberString(e.hourlyRate)) {
      const newCostHistoryValue = toNumber(e.hourlyRate);

      if (
        newCostHistoryValue !== getCurrentEquipmentCostHistory(updatedEquipment.costHistory ?? [])?.operatorCostRate
      ) {
        const costHistoryResponse = await createEquipmentCostHistory(
          object.id,
          undefined,
          toNumber(e.hourlyRate),
          costHistoryEffectiveDate
        );
        updatedEquipment = { ...updatedEquipment, costHistory: [costHistoryResponse] };
      }
    }

    return {
      previousEquipment: e,
      mutatedEquipment: { ...equipmentResponse, data: { updateEquipment: updatedEquipment } },
    };
  };

  async function onSubmit(notifyChecked: boolean = false) {
    openLoaderDetailsWithMessage(t('Saving...'));

    const reimporting = first(equipment)?.reimport;
    const imports = reimporting ? await onReimportSubmit() : await onImportSubmit();

    equipmentOverviewDialogDetails.close();

    // Only show overview on import
    if (!reimporting) {
      // Wait for dialog refs
      setTimeout(() => {
        setImportedEquipment(imports);
      }, 0);

      equipmentReviewDialogDetails.open();
    }

    onImportComplete();
    loaderDetails.close();
  }

  async function onReimportSubmit() {
    const results = await Promise.all(equipment.map(updateEquipment));

    return results.map<IImportedEquipment>((result) => {
      return {
        name: result.mutatedEquipment.data?.updateEquipment?.equipmentName ?? '',
        category: result.mutatedEquipment.data?.updateEquipment?.model.category.title ?? '',
        make: result.mutatedEquipment.data?.updateEquipment?.model.make.title ?? '',
        model: result.mutatedEquipment.data?.updateEquipment?.model.title ?? '',
        year: result.mutatedEquipment.data?.updateEquipment?.model.year
          ? result.mutatedEquipment.data?.updateEquipment?.model.year.toString()
          : 'unknown',
        hourMeter: result.mutatedEquipment.data?.updateEquipment?.lastHours?.runningHours.toFixed(1) ?? '',
        modelId: result.mutatedEquipment.data?.updateEquipment?.equipmentModelId ?? '',
        trackHourMeter: result.mutatedEquipment.data?.updateEquipment?.trackManualHours ?? false,
        success: true,
        hourlyRate:
          (
            getCurrentEquipmentCostHistory(result.mutatedEquipment.data?.updateEquipment?.costHistory ?? [])
              ?.operatorCostRate ?? 0
          ).toFixed(2) ?? '',
      };
    });
  }

  async function onImportSubmit() {
    const results = await Promise.all(equipment.map(createEquipment));
    return results.map<IImportedEquipment>((result) => {
      if (
        (result.mutatedEquipment.errors && result.mutatedEquipment.errors.length) ||
        !result.mutatedEquipment.data ||
        !result.mutatedEquipment.data.createEquipment
      ) {
        return {
          name: result.previousEquipment.name,
          category: result.previousEquipment.category ?? '',
          make: result.previousEquipment.model ?? '',
          model: result.previousEquipment.make ?? '',
          year: result.previousEquipment.year ?? '',
          hourMeter: result.previousEquipment.hourMeter ?? '',
          modelId: result.previousEquipment.modelId ?? '',
          trackHourMeter: !isNil(result.previousEquipment.hourMeter),
          success: false,
          hourlyRate: result.previousEquipment.hourlyRate ?? '',
        };
      } else {
        return {
          name: result.mutatedEquipment.data.createEquipment.equipmentName,
          category: result.mutatedEquipment.data?.createEquipment.model.category.title ?? '',
          make: result.mutatedEquipment.data?.createEquipment.model.make.title ?? '',
          model: result.mutatedEquipment.data?.createEquipment.model.title ?? '',
          year: result.mutatedEquipment.data?.createEquipment.model.year
            ? result.mutatedEquipment.data?.createEquipment.model.year.toString()
            : 'unknown',
          hourMeter: result.mutatedEquipment.data?.createEquipment.lastHours?.runningHours.toFixed(1) ?? '',
          modelId: result.mutatedEquipment.data?.createEquipment.equipmentModelId ?? '',
          trackHourMeter: result.mutatedEquipment.data?.createEquipment.trackManualHours ?? false,
          success: true,
          hourlyRate:
            (
              getCurrentEquipmentCostHistory(result.mutatedEquipment.data?.createEquipment.costHistory ?? [])
                ?.operatorCostRate ?? 0
            ).toFixed(2) ?? '',
        };
      }
    });
  }

  async function onDeleteEquipment(e: ICSVEquipment) {
    setEquipment(equipment.filter((obj) => obj.id !== e.id));
  }

  async function performCategoryListDownload() {
    loaderDetails.open();
    const categoryTitles = await getAllCategories();
    loaderDetails.close();
    const csv = Papa.unparse(categoryTitles);
    downloadText(csv, `${brand}-${'equipment-categories'} -${dateUtils.isoTimeStampLocal()}.csv`);
  }

  const reimporting = _.first(equipment)?.reimport;
  const classes = classNames('csv-import-equipment-container', className);

  return (
    <>
      <CsvImportDialog
        className={classes}
        isOpen={importDialogOpen}
        onClose={closeImportDialog}
        title={t('Import Equipment')}
        onFileSelected={parseCsv(false)}
        requiredFieldText={t('Name and category are required fields.')}
        bodyText={t('Upload a .csv file of all equipment you would like to import.')}
        headers={EQUIPMENT_CSV_HEADERS}
        sampleFileName={`${brand}-${'equipment-csv-headers'} -${dateUtils.isoTimeStampLocal()}.csv`}
        errors={getErrorMessages(errors, equipmentImportErrorMap)}
        prevalidationErrors={prevalidationErrors}
        otherDownloadButtonName={t('Category List')}
        performOtherDownload={performCategoryListDownload}
      />
      <Dialog isOpen={reimportDialogOpen} onClose={closeImportDialog}>
        <ReimportContent
          className="my-8 mx-6"
          prevalidationErrors={prevalidationErrors}
          errors={getErrorMessages(errors, equipmentImportErrorMap)}
          identifier="equipment"
          onDownloadList={onDownloadList}
          onUploadList={parseCsv(true)}
        />
      </Dialog>
      <CsvImportOverview
        dialogTitle={t('Upload Equipment')}
        onSubmit={onSubmit}
        submitText={isReimport ? t('Update All') : t('Create All')}
        isOpen={equipmentOverviewDialogDetails.isOpen}
        onClose={equipmentOverviewDialogDetails.close}
        size={'full'}
        dialogRef={setOverviewRef}
        confirmationPayload={{
          message: reimporting
            ? `${equipment.length} ${t('equipment will be updated. This cannot be undone.')}`
            : `${equipment.length} ${t('equipment will be created. This cannot be undone.')}`,
          submissionText: reimporting ? t('Update') : t('Create'),
          title: reimporting ? t('Update?') : t('Create'),
        }}
      >
        <div className="mx-5 my-6">
          <Label>{t('Hourly Rate Effective Date')}</Label>
          <div className="mb-2">
            {t(
              'The date you would like the rate change to take effect. Setting the date to the past will change past job costing data.'
            )}
          </div>
          <div className="my-6" style={{ width: '300px' }}>
            <DatePicker
              className={'hourly-cost-effective-date'}
              isOpen={datePickerOpenable.isOpen}
              onClose={datePickerOpenable.close}
              onOpen={datePickerOpenable.open}
              position={Position.BOTTOM_END}
              value={DateTime.fromISO(costHistoryEffectiveDate).toJSDate()}
              onChange={(datePickerValue) => {
                if (datePickerValue.value) {
                  setCostHistoryEffectiveDate(DateTime.fromJSDate(datePickerValue.value).startOf('day').toISO());
                }
              }}
            />
          </div>
          <CSVImportEquipmentOverviewTable
            equipment={equipment}
            loading={equipment.length === 0}
            lazyScrollTrigger={overviewRef.current}
            onDelete={onDeleteEquipment}
          />
        </div>
      </CsvImportOverview>
      <CsvImportReview
        isOpen={equipmentReviewDialogDetails.isOpen}
        onClose={equipmentReviewDialogDetails.close}
        size={'full'}
        dialogTitle={t('Uploaded Equipment')}
        onPrint={onPrintReview}
        dialogRef={setReviewRef}
      >
        <h2 className="print mb-6">{t('Imported Equipment')}</h2>
        <div className="mx-5 my-6 table-container">
          <CSVImportEquipmentReviewTable
            equipment={importedEquipment}
            loading={importedEquipment.length === 0}
            lazyScrollTrigger={reviewRef.current}
            lazyLoad={!printing && importedEquipment.length > 200}
          />
        </div>
      </CsvImportReview>
      <Loader isOpen={loaderDetails.isOpen} label={loaderMessage} overlay={true} />
      <Toast isOpen={toastDetails.isOpen} theme={Theme.DANGER} onClose={toastDetails.close} timeout={3000}>
        {toastErrorMessage}
      </Toast>
    </>
  );
};

export default CSVImportEquipmentContainer;
