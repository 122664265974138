import { Align, Button, Card, Icon, Justify, Row, Size } from '@busybusy/webapp-react-ui';
import {
  AdvancedPhotosIcon,
  ApiKeysIcon,
  BulletinIcon,
  DailyReportsOutlinedIcon,
  DocumentsIcon,
  ListIcon,
  ProgressOutlinedIcon,
} from 'assets/icons';
import classNames from 'classnames';
import EnablePremiumForm from 'components/foundation/dashboard/EnablePremiumDialog/EnablePremiumForm/EnablePremiumForm';
import ConfirmationDialog from 'components/foundation/dialogs/ConfirmationDialog/ConfirmationDialog';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { ClassName } from 'types/ClassName';
import { useFeatureFlags } from 'utils/features';
import './PremiumBundleView.scss';

export interface IPremiumBundleViewProps {
  className?: ClassName;
  backgroundColor?: string;
}

const PremiumBundleView = (props: IPremiumBundleViewProps) => {
  const { className, backgroundColor } = props;
  const classes = classNames(className, 'premium-bundle p-6');
  const isPro = useFeatureFlags('PRO');

  const premiumDialogOpenable = useOpenable();
  const premiumRequestCompleteOpenable = useOpenable();

  const onPremiumRequestSubmitted = () => {
    premiumRequestCompleteOpenable.open();
    premiumDialogOpenable.close();
  };

  return (
    <>
      <div className={classes} style={backgroundColor ? { backgroundColor } : {}}>
        <Row justify={Justify.CENTER} className="mt-6">
          <h2 className="subheading">{t('PREMIUM BUNDLE')}</h2>
        </Row>
        <Row justify={Justify.CENTER}>
          <div className="separator my-4"></div>
        </Row>
        <Row justify={Justify.CENTER} align={Align.CENTER} className={'feature-text my-6 mx-12'}>
          {t(
            'Looking to take your business to the next level and save big money at the same time? The Premium bundle gives you access to all the Premium features for only an additional monthly fee of $5 per employee.'
          )}
        </Row>
        <Row justify={Justify.CENTER} align={Align.CENTER}>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={ListIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('All Pro Features')}
            </Row>
          </Card>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={ProgressOutlinedIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('Progress Tracking')}
            </Row>
          </Card>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={DailyReportsOutlinedIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('Field Reports')}
            </Row>
          </Card>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={BulletinIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('Company Bulletin')}
            </Row>
          </Card>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={DocumentsIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('Document Sharing')}
            </Row>
          </Card>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={ApiKeysIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('API Keys')}
            </Row>
          </Card>
          <Card className={'feature-container'}>
            <Row justify={Justify.CENTER}>
              <Icon svg={AdvancedPhotosIcon} size={Size.XL} />
            </Row>
            <Row justify={Justify.CENTER} className={'feature-heading'}>
              {t('Photo Markup & Videos')}
            </Row>
          </Card>
        </Row>
        <Row justify={Justify.CENTER}>
          <Button
            className="go-premium-button my-8"
            type="primary"
            onClick={
              isPro ? premiumDialogOpenable.open : () => window.open('https://calendly.com/busybusy-walkthrough/20min')
            }
            size={Size.LARGE}
          >
            {t('Go Premium')}
          </Button>
        </Row>
      </div>
      <HeaderDialog
        isOpen={premiumDialogOpenable.isOpen}
        onClose={premiumDialogOpenable.close}
        title={t('Premium')}
        divider={false}
      >
        <EnablePremiumForm onRequestSubmitted={onPremiumRequestSubmitted} className="pl-8 pr-8 pb-9" />
      </HeaderDialog>
      <ConfirmationDialog
        title={t('Submitted')}
        message={t(
          'Your request has been submitted and Premium will soon be enabled for your account. We’ll send you a message letting you know when the feature is ready to use. Thank you!'
        )}
        isOpen={premiumRequestCompleteOpenable.isOpen}
        confirmButtonText={t('OK')}
        onConfirm={premiumRequestCompleteOpenable.close}
      />
    </>
  );
};

export default PremiumBundleView;
