import { CreateIncidentOutcomeDetailsMutation, CreateIncidentOutcomeDetailsMutationVariables, IncidentOutcomeDetailsCreateInput } from "__generated__/graphql";
import gql from "graphql-tag";
import useGraphQLClient from "hooks/graphql/useGraphQLClient/useGraphQLClient";
import { isNil } from "lodash";
import { useCallback } from "react";

export const useCreateIncidentOutcomeDetails = () => {
    const client = useGraphQLClient();
    const createIncidentOutcomeDetails = useCallback(
        async (input: IncidentOutcomeDetailsCreateInput) => {
            const result = await client.request<CreateIncidentOutcomeDetailsMutation, CreateIncidentOutcomeDetailsMutationVariables>({
                document: CREATE_INCIDENT_OUTCOME_DETAILS_MUTATION,
                variables: {
                    incidentOutcomeDetails: input,
                }
            });

            if (isNil(result.createIncidentOutcomeDetails)) {
                throw Error(`result was ${result.createIncidentOutcomeDetails}`);
            }

            return result.createIncidentOutcomeDetails;
        },
        [client]
    );

    return createIncidentOutcomeDetails;
}

const CREATE_INCIDENT_OUTCOME_DETAILS_MUTATION = gql`
mutation CreateIncidentOutcomeDetails(
    $incidentOutcomeDetails: IncidentOutcomeDetailsCreateInput
  ) {
    createIncidentOutcomeDetails(
      incidentOutcomeDetails: $incidentOutcomeDetails
    ) {
      id
    }
  }`