import { Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import LazyLoadPicker, { ILazyLoadPickerProps } from '../../LazyLoadPicker/LazyLoadPicker';
import SimplePickerRow from '../../SimplePickerRow/SimplePickerRow';

export interface IBasicPickerValue<K extends string = string> {
  id: K;
  name: string;
}

export interface IBasicPickerProps<K extends string = string, T extends IBasicPickerValue = IBasicPickerValue<K>>
  extends Pick<ILazyLoadPickerProps<T>, 'dropDownButtonRender' | 'closeButtonRender' | 'searchValue' | 'onSearch'> {
  value: K | null;
  data: T[];
  onSelect: (value: K | null) => void;
  minWidth?: string;
  placeholder?: string;
  position?: Position;
  clearable?: boolean;
  error?: boolean;
  className?: ClassName;
  disabled?: boolean;
  autofocus?: boolean;
  onClear?: () => void;
}

const BasicPicker = <K extends string, T extends IBasicPickerValue<K>>({
  value,
  data,
  onSelect,
  searchValue,
  onClear,
  className,
  ...pickerProps
}: IBasicPickerProps<K, T>) => {
  function handleSelect(model: T | null) {
    onSelect(model?.id ?? null);
  }

  function renderValueTemplate(id: string | null) {
    if (id) {
      const item = data.find((model) => model.id === id);
      if (item) {
        return <div className="ellipsis">{item.name}</div>;
      }
    }

    return <></>;
  }

  function renderRow(item: T) {
    return <SimplePickerRow value={item.name} searchValue={searchValue} />;
  }

  const classes = classNames('basic-picker', className);

  return (
    <LazyLoadPicker
      {...pickerProps}
      searchValue={searchValue}
      className={classes}
      value={value}
      onSelect={handleSelect}
      loadedAll={true}
      offset={data.length}
      data={data}
      renderRow={renderRow}
      valueTemplate={renderValueTemplate}
      onClear={onClear}
    />
  );
};

export default BasicPicker;
