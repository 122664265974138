import { AUTH_ORGANIZATION_FRAGMENT } from 'containers/app/beforeRoutes/AuthenticateFromToken/authentication-query';
import gql from 'graphql-tag';

export const ORGANIZATION_UPDATE_MUTATION = gql`
  mutation Organization($organization: OrganizationUpdateInput) {
    updateOrganization(organization: $organization) {
      ...AuthOrganization
    }
  }
  ${AUTH_ORGANIZATION_FRAGMENT}
`;
