import { Button, Tray } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import Typography from 'components/foundation/text/Typography/Typography';
import { isNil } from 'lodash';
import './ConfirmationDialog.scss';

export interface IConfirmationDialogProps {
  className?: ClassName;
  title: string;
  message: string;
  confirmButtonText?: string;
  closeButtonText?: string;
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const ConfirmationDialog = ({
  className,
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmButtonText,
  closeButtonText,
}: IConfirmationDialogProps) => {
  const classes = classNames('confirmation-dialog', className);

  return (
    <HeaderDialog isOpen={isOpen} title={title} onClose={onClose} divider={false} className={classes}>
      <div className="content">
        <Typography tag="p">{message}</Typography>

        <Tray className="tray-right" align="left">
          <ConditionalContainer condition={!isNil(closeButtonText)}>
            <Button type="secondary" onClick={onClose}>
              {closeButtonText}
            </Button>
          </ConditionalContainer>

          <ConditionalContainer condition={!isNil(confirmButtonText)}>
            <Button type="primary" onClick={onConfirm}>
              {confirmButtonText}
            </Button>
          </ConditionalContainer>
        </Tray>
      </div>
    </HeaderDialog>
  );
};

export default ConfirmationDialog;
