import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  CostCode,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Member,
  MemberLaborMetrics,
  Scalars,
} from '__generated__/graphql';
import { MEMBER_NAME_QUERY } from 'apollo/queries/member-queries';
import useMemberActivityPermission from 'containers/activity-reports/hooks/useMemberActivityPermission';
import {
  COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import {
  MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, first, groupBy, isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { getMemberTimeOffMetric } from 'utils/jitMetricQueries';
import {
  combineMetricFilters,
  getRegularMetricLaborTotals,
  metricConditionFilter,
  metricNullFilter,
} from 'utils/jitMetricUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { aggregateLaborMetrics, getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { ICostCodeActivityTableRowInfo } from './useCostCodeActivity';

export interface ICostCodeMemberActivityTableRowInfo extends ICostCodeActivityTableRowInfo {
  memberId: string | null;
  member: Member | null;
}

interface CostCodeMemberMetricRow extends LaborMetricsInterface {
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  memberId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useCostCodeActivityMemberDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const permission = useMemberActivityPermission();
  const [data, setData] = useState<ICostCodeMemberActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<ICostCodeMemberActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'costCode',
    SortDirection.ASCENDING,
    getSortField
  );
  const formatEmployeeName = useEmployeeNameFormatter();

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY
          : COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? COST_CODES_WITH_MEMBER_METRICS_QUERY : COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: ICostCodeMemberActivityTableRowInfo, key: keyof ICostCodeMemberActivityTableRowInfo) {
    if (key === 'costCode') {
      return getCostCodeDisplay(item.costCode);
    } else {
      return item[key];
    }
  }

  async function getMembers(memberIds: string[]) {
    if (isEmpty(memberIds)) {
      return [];
    }

    return await getAll<Member & ICursorable>('members', {
      query: MEMBER_NAME_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: memberIds },
          permissions: { permissions: permission, operationType: 'and' },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  function getMemberQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_COST_CODE_METRIC_QUERY : MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getNoCostCodeMetricFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        if (filterId === remainingDataItemId) {
          return combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          ]);
        }
        return combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId),
        ]);
      case ActivityReportType.BY_EQUIPMENT:
        if (filterId === remainingDataItemId) {
          return combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
          ]);
        }
        return combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.EquipmentId, ConditionOperationType.Equal, filterId),
        ]);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull);
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function loadData() {
    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const memberLookup = await getMemberLookup(costCodes);
    const tableRows = mapNotNull(costCodes, (costCode) => createTableRowInfo(memberLookup, costCode));

    const remainingData = await createNoCostCodeRow(tableRows);

    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  async function getMemberLookup(data: CostCode[]) {
    const metrics = data.flatMap((costCode) => laborMetrics(costCode));
    const memberIds = mapNotNil(metrics, (item) => item.memberId);
    const members = await getMembers(uniq(memberIds));

    return keyBy(members, (member) => member.id);
  }

  const createTableRowInfo = (
    memberLookup: Dictionary<Member>,
    costCode: CostCode
  ): ICostCodeMemberActivityTableRowInfo | null => {
    const metrics = laborMetrics(costCode);

    const unsortedMemberRows: ICostCodeMemberActivityTableRowInfo[] = mapNotNull(metrics, (metric) => {
      const memberId = metric.memberId!; // member id can't be null here
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(memberLookup[memberId])) {
        return null;
      }

      return {
        id: costCode.id + memberId,
        memberId: memberId,
        member: memberLookup[memberId],
        costCode,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const memberRows = sortByIgnoreCase(unsortedMemberRows, (row) =>
      formatEmployeeName(row.member!.firstName ?? '', row.member!.lastName ?? '')
    );

    const restrictedMetrics = metrics.filter((metric) => isNil(memberLookup[metric.memberId!]));
    if (!isEmpty(restrictedMetrics)) {
      const aggregates = restrictedMetrics.map((metric) =>
        getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false)
      );

      const restrictedTotalMetrics = aggregateLaborMetrics(aggregates);

      if (restrictedTotalMetrics.totalSeconds > 0) {
        memberRows.push({
          id: costCode.id + remainingDataItemId,
          memberId: remainingDataItemId,
          member: null,
          costCode,
          ...activityReportUtils.metricToRowInfo(restrictedTotalMetrics),
        });
      }
    }

    if (isEmpty(memberRows)) {
      return null;
    } else {
      const costCodeTotalMetrics = aggregateActivityRows(memberRows);
      return {
        ...costCodeTotalMetrics,
        id: costCode.id,
        memberId: null,
        member: null,
        costCode,
        detailRows: memberRows,
      };
    }
  };

  const createNoCostCodeRow = async (
    tableRows: ICostCodeMemberActivityTableRowInfo[]
  ): Promise<ICostCodeMemberActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Cost Code row
    }

    const members = await getAll<Member & ICursorable>('members', {
      query: getMemberQuery(),
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            costCodeId: { isNull: true },
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: getNoCostCodeMetricFilter(),
      },
    });

    let timeOffMetrics: Dictionary<MemberLaborMetrics[]> | null = null;
    if (filterId === remainingDataItemId) {
      timeOffMetrics = groupBy(
        await getMemberTimeOffMetric(client, timeRange, canViewCost, LaborMetricsInterval.Custom),
        (metric) => metric.memberId
      );
    }

    const unsortedMemberRows: ICostCodeMemberActivityTableRowInfo[] = mapNotNull(members, (member) => {
      const memberMetric = getGeneratedMetricLaborTotals(
        first(memberLaborMetrics(member)),
        filterIdWithDescendants === false
      );
      const memberTimeOffMetrics = getRegularMetricLaborTotals(
        first(timeOffMetrics?.[member.id]) as IJitLaborMetric | null | undefined
      );

      const metricTotal = aggregateLaborMetrics([memberMetric, memberTimeOffMetrics]);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + member.id,
        memberId: member.id,
        member,
        costCode: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    const memberRows = sortByIgnoreCase(unsortedMemberRows, (row) =>
      formatEmployeeName(row.member!.firstName ?? '', row.member!.lastName ?? '')
    );

    const remainingCostCodeData = calculateRemainingActivityData(
      memberRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingCostCodeData.totalHours > 0) {
      // No Cost Code Restricted Data Item
      memberRows.push({
        ...remainingCostCodeData,
        id: remainingDataItemId + remainingDataItemId,
        memberId: remainingDataItemId,
        member: null,
        costCode: null,
      });
    }

    return {
      memberId: null,
      member: null,
      costCode: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: memberRows,
    };
  };

  const laborMetrics = (costCode: CostCode): CostCodeMemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return costCode.costCodeMemberProjectLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return costCode.costCodeMemberEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return costCode.costCodeMemberLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const memberLaborMetrics = (member: Member): CostCodeMemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return member.memberProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return member.memberCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberCostCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
