import { LaborMetricsInterval } from '__generated__/graphql';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { DocumentNode } from 'graphql';
import { ApolloGetAll } from 'hooks/apollo/useApolloPaging';
import { DateTime } from 'luxon';
import { IMember } from 'types';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { MetricQueryProvider } from '../useMetricAggregates';
import {
  MEMBERS_COST_CODE_METRIC_QUERY,
  MEMBERS_COST_CODE_TIME_METRIC_QUERY,
  MEMBERS_EQUIPMENT_METRIC_QUERY,
  MEMBERS_EQUIPMENT_TIME_METRIC_QUERY,
  MEMBERS_LABOR_METRIC_QUERY,
  MEMBERS_PROJECT_METRIC_QUERY,
  MEMBERS_PROJECT_TIME_METRIC_QUERY,
  MEMBERS_TIME_METRIC_QUERY,
} from './member-metric-queries';

export function getMemberTimeMetricQueryProvider(
  getAll: ApolloGetAll<IMember & ICursorable>,
  query: DocumentNode,
  permission?: MemberPermission,
  memberIds?: string[],
  memberGroupId?: string,
  archivedStatus?: ArchivedStatus,
  first: number = 100
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const members = await getAll('members', {
      query,
      fetchPolicy: 'network-only',
      variables: {
        first,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: memberIds ? { contains: memberIds } : undefined,
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          permissions: permission
            ? permission === MemberPermission.TIME_EVENTS
              ? { permissions: permission, operationType: 'and', includeSelf: true }
              : { permissions: permission, operationType: 'and' }
            : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          deletedOn: { isNull: true },
        },
      },
    });

    return members.flatMap((member) => member.memberLaborMetrics ?? []);
  };
}

export function memberLaborMetricQueryProvider(
  getAll: ApolloGetAll<IMember & ICursorable>,
  permission?: MemberPermission,
  memberIds?: string[],
  memberGroupId?: string,
  archivedStatus?: ArchivedStatus,
  timeOnly: boolean = true,
  first: number = 100
): MetricQueryProvider {
  return getMemberTimeMetricQueryProvider(
    getAll,
    timeOnly ? MEMBERS_TIME_METRIC_QUERY : MEMBERS_LABOR_METRIC_QUERY,
    permission,
    memberIds,
    memberGroupId,
    archivedStatus,
    first
  );
}

export function memberProjectMetricQueryProvider(
  getAll: ApolloGetAll<IMember & ICursorable>,
  permission?: MemberPermission,
  memberIds?: string[],
  projectIds?: string[],
  memberGroupId?: string,
  archivedStatus?: ArchivedStatus,
  timeOnly: boolean = true,
  excludeNoProjectTime: boolean = true,
  first: number = 100
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const members = await getAll('members', {
      query: timeOnly ? MEMBERS_PROJECT_TIME_METRIC_QUERY : MEMBERS_PROJECT_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        projectIds,
        filter: {
          id: memberIds ? { contains: memberIds } : undefined,
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          permissions: permission
            ? permission === MemberPermission.TIME_EVENTS
              ? { permissions: permission, operationType: 'and', includeSelf: true }
              : { permissions: permission, operationType: 'and' }
            : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          deletedOn: { isNull: true },
        },
        metricFilter: excludeNoProjectTime
          ? {
              operationType: 'and',
              nullConditions: { operator: 'isNotNull', field: 'projectId' },
            }
          : undefined,
      },
    });

    return members.flatMap((member) => member.memberProjectLaborMetrics ?? []);
  };
}

export function memberCostCodeMetricQueryProvider(
  getAll: ApolloGetAll<IMember & ICursorable>,
  permission?: MemberPermission,
  memberIds?: string[],
  costCodeIds?: string[],
  memberGroupId?: string,
  archivedStatus?: ArchivedStatus,
  timeOnly: boolean = true,
  excludeNoProjectTime: boolean = true,
  first: number = 100
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const members = await getAll('members', {
      query: timeOnly ? MEMBERS_COST_CODE_TIME_METRIC_QUERY : MEMBERS_COST_CODE_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        costCodeIds,
        filter: {
          id: memberIds ? { contains: memberIds } : undefined,
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          permissions: permission
            ? permission === MemberPermission.TIME_EVENTS
              ? { permissions: permission, operationType: 'and', includeSelf: true }
              : { permissions: permission, operationType: 'and' }
            : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          deletedOn: { isNull: true },
        },
        metricFilter: excludeNoProjectTime
          ? {
              operationType: 'and',
              nullConditions: { operator: 'isNotNull', field: 'costCodeId' },
            }
          : undefined,
      },
    });

    return members.flatMap((member) => member.memberCostCodeLaborMetrics ?? []);
  };
}

export function memberEquipmentMetricQueryProvider(
  getAll: ApolloGetAll<IMember & ICursorable>,
  permission?: MemberPermission,
  memberIds?: string[],
  equipmentIds?: string[],
  memberGroupId?: string,
  archivedStatus?: ArchivedStatus,
  timeOnly: boolean = true,
  excludeNoProjectTime: boolean = true,
  first: number = 500
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const members = await getAll('members', {
      query: timeOnly ? MEMBERS_EQUIPMENT_TIME_METRIC_QUERY : MEMBERS_EQUIPMENT_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        equipmentIds,
        filter: {
          id: memberIds ? { contains: memberIds } : undefined,
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          permissions: permission
            ? permission === MemberPermission.TIME_EVENTS
              ? { permissions: permission, operationType: 'and', includeSelf: true }
              : { permissions: permission, operationType: 'and' }
            : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          deletedOn: { isNull: true },
        },
        metricFilter: excludeNoProjectTime
          ? {
              operationType: 'and',
              nullConditions: { operator: 'isNotNull', field: 'equipmentId' },
            }
          : undefined,
      },
    });

    return members.flatMap((member) => member.memberEquipmentLaborMetrics ?? []);
  };
}
