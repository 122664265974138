import { useCallback, useEffect, useRef } from 'react';
import { ChannelIds } from '../ChannelIds';
import { useChannelContext } from '../useChannelContext';

/**
 * Use this hook instead of usePrintChannelListener when the `loadAll` function completing
 * does not mean that all of the data is loaded. Like in the case that each item it loads
 * make more network calls.
 * This hook will observe the actual data to determine when to print, rather than waiting
 * for `loadAll` to finish.
 *
 * @param data the data field to observe (this should be the main data rendered on the view)
 * @param loadedAll boolean that tells us when all of the data is loaded
 * @param loadAll function for loading the remaining data
 */
export function usePrintComplexDataChannelListener(data: object, loadedAll: boolean, loadAll?: () => void) {
  const channel = useChannelContext();
  const shouldPrint = useRef(false);

  const print = () => {
    setTimeout(() => {
      window.print();
    }, 100);
  };

  const onPrint = useCallback(async () => {
    if (!loadedAll && loadAll) {
      // load all the data, and we'll print once the data field is updated
      shouldPrint.current = true;
      loadAll();
    } else {
      print(); // data is already loaded so we can print
    }
  }, [loadAll, loadedAll]);

  useEffect(() => {
    if (shouldPrint.current && loadedAll) {
      shouldPrint.current = false;
      print();
    }
  }, [data]);

  useEffect(() => {
    channel.registerListener(ChannelIds.Print, onPrint);

    return () => {
      channel.unregisterListener(ChannelIds.Print);
    };
  }, [channel, onPrint]);
}
