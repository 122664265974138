import { Button, Label, Tabs } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Nullable } from 'types/util/Nullable';
import './TabBar.scss';

export interface ITabBarData<T> {
  label: string;
  displayValue: Nullable<string>;
  tabValue: T;
  disabled?: boolean;
}

export interface ITabBarProps<T> {
  selectedTab: T;
  tabs: Array<ITabBarData<T>>;
  onTabSelection: (value: T) => void;
  className?: ClassName;
}

export default function TabBar<T>({ tabs, selectedTab, onTabSelection, className }: ITabBarProps<T>) {
  const classes = classNames('tab-bar', className);

  return (
    <Tabs value={selectedTab} sizing="full-width" onChange={onTabSelection} className={classes}>
      {tabs.map(({ label, tabValue, displayValue, disabled }) => (
        <Button key={label} value={tabValue} disabled={disabled}>
          <FlexContainer
            flexDirection="column-reverse"
            className={classNames('tab-container', { disabled: disabled })}
            justifyContent="center"
          >
            <Label className="tab-label">{label}</Label>
            <Label className="tab-count-label">{displayValue}</Label>
          </FlexContainer>
        </Button>
      ))}
    </Tabs>
  );
}
