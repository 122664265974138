import { GetNextPageParamFunction, QueryFunction, QueryKey } from '@tanstack/react-query';
import ICursorable from 'types/Cursorable';
import { KeysOfType } from 'types/util/KeysOfType';
import { Nullable } from 'types/util/Nullable';
import { AnyObject } from 'types/util/Object';
import { Optional } from 'types/util/Optional';
import { getLastCursor } from './apolloUtils';

export function getResolverGraphqlNextPageParam<TQueryFnData = unknown>(
  resolver: (v: TQueryFnData) => Optional<ICursorable[]>,
  pageSize: number = 50
): GetNextPageParamFunction<Nullable<string>, TQueryFnData> {
  return (lastPage: TQueryFnData) => {
    const resolvedValues = resolver(lastPage);
    return getGraphQlNextPageResult(resolvedValues, pageSize);
  };
}

export function getGraphqlNextPageParamWithAccessor<
  TQueryFnData extends AnyObject,
  T extends ICursorable = ICursorable,
>(
  key: KeysOfType<TQueryFnData, Optional<T[]>>,
  pageSize: number = 50
): GetNextPageParamFunction<Nullable<string>, TQueryFnData> {
  return (lastPage: TQueryFnData) => {
    const resolvedValues = lastPage[key];
    return getGraphQlNextPageResult(resolvedValues, pageSize);
  };
}

function getGraphQlNextPageResult<T extends ICursorable>(values: Optional<T[]>, pageSize: number = 50) {
  if (!values) {
    return undefined;
  }

  const hasMoreData = values.length >= pageSize;
  if (!hasMoreData) {
    return undefined;
  }

  return getLastCursor(values) ?? undefined;
}

export function getGraphqlNextPageParam<TQueryFnData extends ICursorable>(
  pageSize: number = 50
): GetNextPageParamFunction<Nullable<string>, TQueryFnData[]> {
  return (lastPage: TQueryFnData[]) => {
    return getGraphQlNextPageResult(lastPage, pageSize);
  };
}

export function convertGetDataToReactQueryPager<T>(
  getData: (after?: Nullable<string>) => Promise<T>
): QueryFunction<T, QueryKey, Nullable<string>> {
  return ({ pageParam }) => getData(pageParam);
}
