import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  Equipment,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Member,
  MemberLaborMetrics,
  Scalars,
} from '__generated__/graphql';
import { MEMBER_NAME_QUERY } from 'apollo/queries/member-queries';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from 'containers/activity-reports/hooks/useMemberActivityPermission';
import {
  SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import {
  MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, first, groupBy, isEmpty, isNil, keyBy, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { getMemberTimeOffMetric } from 'utils/jitMetricQueries';
import {
  combineMetricFilters,
  getRegularMetricLaborTotals,
  metricConditionFilter,
  metricNullFilter,
} from 'utils/jitMetricUtils';
import { aggregateLaborMetrics, getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';

export interface IEquipmentMemberActivityTableRowInfo extends IEquipmentActivityTableRowInfo {
  memberId: string | null;
  member: Member | null;
}

interface EquipmentMemberMetricRow extends LaborMetricsInterface {
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  memberId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useEquipmentActivityMemberDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const permission = useMemberActivityPermission();
  const [data, setData] = useState<IEquipmentMemberActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IEquipmentMemberActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'equipment',
    SortDirection.ASCENDING,
    getSortField
  );
  const formatEmployeeName = useEmployeeNameFormatter();

  function getSortField(item: IEquipmentMemberActivityTableRowInfo, key: keyof IEquipmentMemberActivityTableRowInfo) {
    if (key === 'equipment') {
      return getEquipmentDisplay(item.equipment);
    } else {
      return item[key];
    }
  }

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function getMembers(memberIds: string[]) {
    if (isEmpty(memberIds)) {
      return [];
    }

    return await getAll<Member & ICursorable>('members', {
      query: MEMBER_NAME_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: memberIds },
          permissions: { permissions: permission, operationType: 'and' },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  function getMemberQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_EQUIPMENT_METRIC_QUERY : MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getNoEquipmentMetricFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        if (filterId === remainingDataItemId) {
          return combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          ]);
        }
        return combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId),
        ]);
      case ActivityReportType.BY_COST_CODE:
        if (filterId === remainingDataItemId) {
          return combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          ]);
        }
        return combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.CostCodeId, ConditionOperationType.Equal, filterId),
        ]);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull);
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function getMemberLookup(data: Equipment[]) {
    const metrics = data.flatMap((e) => laborMetrics(e));
    const memberIds = mapNotNil(metrics, (item) => item.memberId);
    const members = await getMembers(uniq(memberIds));

    return keyBy(members, (member) => member.id);
  }

  async function loadData() {
    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const memberLookup = await getMemberLookup(equipment);
    const tableRows = mapNotNull(equipment, (e) => createTableRowInfo(memberLookup, e));

    const remainingData = await createNoEquipmentRow(tableRows);

    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const createTableRowInfo = (
    memberLookup: Dictionary<Member>,
    equipment: Equipment
  ): IEquipmentMemberActivityTableRowInfo | null => {
    const metrics = laborMetrics(equipment);

    const unsortedMemberRows: IEquipmentMemberActivityTableRowInfo[] = mapNotNull(metrics, (metric) => {
      const memberId = metric.memberId!; // member id can't be null here
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(memberLookup[memberId])) {
        return null;
      }

      return {
        id: equipment.id + memberId,
        memberId: memberId,
        member: memberLookup[memberId],
        equipment,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });
    const memberRows = sortByIgnoreCase(unsortedMemberRows, (row) =>
      formatEmployeeName(row.member!.firstName ?? '', row.member!.lastName ?? '')
    );

    const restrictedMetrics = metrics.filter((metric) => isNil(memberLookup[metric.memberId!]));
    if (!isEmpty(restrictedMetrics)) {
      const aggregates = restrictedMetrics.map((metric) =>
        getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false)
      );

      const restrictedTotalMetrics = aggregateLaborMetrics(aggregates);

      if (restrictedTotalMetrics.totalSeconds > 0) {
        memberRows.push({
          id: equipment.id + remainingDataItemId,
          memberId: remainingDataItemId,
          member: null,
          equipment,
          ...activityReportUtils.metricToRowInfo(restrictedTotalMetrics),
          equipmentCost: 0,
          equipmentRate: 0,
          equipmentTotalCost: 0,
        });
      }
    }

    if (isEmpty(memberRows)) {
      return null;
    } else {
      const equipmentTotalMetrics = aggregateActivityRows(memberRows);
      return {
        ...equipmentTotalMetrics,
        id: equipment.id,
        memberId: null,
        member: null,
        equipment,
        detailRows: memberRows,
        equipmentCost: sumBy(memberRows, (row) => row.equipmentCost),
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: sumBy(memberRows, (row) => row.equipmentCost) + sumBy(memberRows, (row) => row.totalCost),
      };
    }
  };

  const createNoEquipmentRow = async (
    tableRows: IEquipmentMemberActivityTableRowInfo[]
  ): Promise<IEquipmentMemberActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Cost Code row
    }

    const members = await getAll<Member & ICursorable>('members', {
      query: getMemberQuery(),
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            equipmentId: { isNull: true },
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: getNoEquipmentMetricFilter(),
      },
    });

    let timeOffMetrics: Dictionary<MemberLaborMetrics[]> | null = null;
    if (filterId === remainingDataItemId) {
      timeOffMetrics = groupBy(
        await getMemberTimeOffMetric(client, timeRange, canViewCost, LaborMetricsInterval.Custom),
        (metric) => metric.memberId
      );
    }

    const unsortedMemberRows: IEquipmentMemberActivityTableRowInfo[] = mapNotNull(members, (member) => {
      const memberMetric = getGeneratedMetricLaborTotals(
        first(memberLaborMetrics(member)),
        filterIdWithDescendants === false
      );
      const memberTimeOffMetrics = getRegularMetricLaborTotals(
        first(timeOffMetrics?.[member.id]) as IJitLaborMetric | null | undefined
      );

      const metricTotal = aggregateLaborMetrics([memberMetric, memberTimeOffMetrics]);

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: remainingDataItemId + member.id,
        memberId: member.id,
        member,
        equipment: null,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
      };
    });

    const memberRows = sortByIgnoreCase(unsortedMemberRows, (row) =>
      formatEmployeeName(row.member!.firstName ?? '', row.member!.lastName ?? '')
    );

    const remainingCostCodeData = calculateRemainingActivityData(
      memberRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingCostCodeData.totalHours > 0) {
      // No Cost Code Restricted Data Item
      memberRows.push({
        ...remainingCostCodeData,
        id: remainingDataItemId + remainingDataItemId,
        memberId: remainingDataItemId,
        member: null,
        equipment: null,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
      });
    }

    return {
      memberId: null,
      member: null,
      equipment: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: memberRows,
      equipmentCost: 0,
      equipmentRate: 0,
      equipmentTotalCost: 0,
    };
  };

  const laborMetrics = (equipment: Equipment): EquipmentMemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return equipment.equipmentMemberProjectLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return equipment.equipmentMemberCostCodeLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return equipment.equipmentMemberLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const memberLaborMetrics = (member: Member): EquipmentMemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return member.memberProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return member.memberCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberEquipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
