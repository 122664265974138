import { useOrganization, useQueryParams } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { isNil, last } from 'lodash';
import { useEffect, useMemo } from 'react';
import { PayPeriodType } from 'types/enum/PayPeriodType';
import TimeRangeType from 'types/TimeRangeType';
import { QueryParam } from 'utils/constants/queryParam';
import { useFeatureFlags } from 'utils/features';
import { useIsLaunchDarklyReady } from 'utils/features/useIsLaunchDarklyReady';
import { ActivityReportType } from '../ActivityReportFilter/ActivityReportFilter';
import { ActivityReportGraphType } from '../ActivityReportGraph/ActivityReportGraph';

export interface IActivityQueryPayload {
  timeRangeType: TimeRangeType;
  reportType: ActivityReportType;
  customStartDate: string | null;
  customEndDate: string | null;
  memberId: string | null;
  memberGroupId: string | null;
  projectId: string | null;
  projectGroupId: string | null;
  costCodeId: string | null;
  costCodeGroupId: string | null;
  equipmentId: string | null;
  equipmentCategoryId: string | null;
  graphType: ActivityReportGraphType | null;
  dateRangeLimit: number | null;
}

export default function useActivityQueryParams(): IActivityQueryPayload {
  const { getParam, updateParams } = useQueryParams();
  const isPro = useFeatureFlags('PRO');
  const isLDClientReady = useIsLaunchDarklyReady();
  const organization = useOrganization();
  const memberSettings = useMemberSettings();

  useEffect(() => {
    // remove the time range params for free users
    if (isLDClientReady && !isPro) {
      updateParams({
        [QueryParam.START_DATE]: null,
        [QueryParam.END_DATE]: null,
      });
    }

    // default range to weekly for the date range report (all other reports will default to by pay period)
    if (
      getParam<ActivityReportType>(QueryParam.FILTER_TYPE) === ActivityReportType.BY_DATE_RANGE &&
      isNil(getParam(QueryParam.TIME_RANGE_TYPE))
    ) {
      updateParams({
        [QueryParam.TIME_RANGE_TYPE]: TimeRangeType.WEEKLY,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLDClientReady, isPro]);

  function defaultTimeRangeLimit(timeRangeType: TimeRangeType) {
    if (timeRangeType === TimeRangeType.MONTHLY) {
      return 12;
    } else if (timeRangeType === TimeRangeType.PAY_PERIOD) {
      switch (last(organization.organizationPayPeriod)?.payPeriodType) {
        case PayPeriodType.BIWEEKLY:
        case PayPeriodType.SEMIMONTHLY:
          return 24;
        case PayPeriodType.MONTHLY:
          return 12;
      }
    }

    return 52;
  }

  return useMemo(() => {
    // prevent free users from changing the url date params
    const isFree = isLDClientReady && !isPro;
    const reportType: ActivityReportType = getParam(QueryParam.FILTER_TYPE) ?? ActivityReportType.BY_EMPLOYEE;
    const defaultRangeType =
      isPro && reportType !== ActivityReportType.BY_DATE_RANGE ? TimeRangeType.PAY_PERIOD : TimeRangeType.WEEKLY;
    const timeRangeType: TimeRangeType =
      getParam(QueryParam.TIME_RANGE_TYPE) ??
      memberSettings?.web?.features?.activityReport?.timeRangeType ??
      defaultRangeType;

    return {
      reportType,
      timeRangeType,
      projectId: getParam(QueryParam.PROJECT_ID) ?? null,
      projectGroupId: getParam(QueryParam.PROJECT_GROUP_ID) ?? null,
      memberId: getParam(QueryParam.EMPLOYEE_ID) ?? null,
      memberGroupId: getParam(QueryParam.EMPLOYEE_GROUP_ID) ?? null,
      equipmentId: getParam(QueryParam.EQUIPMENT_ID) ?? null,
      equipmentCategoryId: getParam(QueryParam.EQUIPMENT_CATEGORY_ID) ?? null,
      costCodeId: getParam(QueryParam.COST_CODE_ID) ?? null,
      costCodeGroupId: getParam(QueryParam.COST_CODE_GROUP_ID) ?? null,
      customStartDate: isFree ? null : getParam(QueryParam.START_DATE),
      customEndDate: isFree ? null : getParam(QueryParam.END_DATE),
      graphType: getParam(QueryParam.GRAPH_TYPE),
      dateRangeLimit:
        reportType === ActivityReportType.BY_DATE_RANGE
          ? getParam(QueryParam.DATE_RANGE_LIMIT)
            ? parseInt(getParam(QueryParam.DATE_RANGE_LIMIT)!)
            : defaultTimeRangeLimit(timeRangeType)
          : null,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParam, isPro]);
}
