import { Icon } from '@busybusy/webapp-react-ui';
import { ReactNode } from 'react';
import * as React from 'react';

export interface IUnconnectedNavItemProps {
  children: ReactNode;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

function NavItemContent({ children, icon }: IUnconnectedNavItemProps) {
  return (
    <>
      {icon && <Icon svg={icon} />}
      <span className="nav-item-label">{children}</span>
    </>
  );
}

export default NavItemContent;
