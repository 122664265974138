import { IDashboardChartPayload } from 'components/domain/dashboard/dashboard-card-chart/types/types';
import { getDefaultDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import { take } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import { DateTime, Duration } from 'luxon';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { takeWithPopulate } from 'utils/collectionUtils';
import { localizeDateTimeFromUtcISOKeepUtc } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { sortByField } from 'utils/tableUtils';
import { LaborMetricsInterval } from '__generated__/graphql';
import { DashboardTimeFrame } from '../types/types';

export function getDashboardTimeFrameTitle(title: string, timeFrame: DashboardTimeFrame): string | null {
  switch (timeFrame) {
    case 'monthly':
      return isEmpty(title) ? `${t('This Month')}` : `${title} - ${t('This Month')}`;
    case 'weekly':
      return isEmpty(title) ? `${t('This Week')}` : `${title} - ${t('This Week')}`;
    case 'payPeriod':
      return isEmpty(title) ? `${t('Current Pay Period')}` : `${title} - ${t('Current Pay Period')}`;
    case 'pastSeven':
      return isEmpty(title) ? `${t('Past 7 Days')}` : `${title} - ${t('Past 7 Days')}`;
    case 'pastFourteen':
      return isEmpty(title) ? `${t('Past 14 Days')}` : `${title} - ${t('Past 14 Days')}`;
    case 'daily':
      return isEmpty(title) ? `${t('Today')}` : `${title} - ${t('Today')}`;
  }
}

export function assignItemsByCreationToRanges<T extends { createdOn: string }>(
  ranges: Array<ITimeRange<number>>,
  items: T[]
) {
  return assignItemsToRanges(ranges, items, (item) => localizeDateTimeFromUtcISOKeepUtc(item.createdOn));
}

export function assignItemsToRanges<T>(
  ranges: Array<ITimeRange<number>>,
  items: T[],
  dateTimeForItem: (item: T) => DateTime
) {
  const byStart: { [key: number]: number } = ranges.reduce((acc, cur) => ({ ...acc, [cur.startTime]: 0 }), {});

  items.forEach((item) => {
    const itemSeconds = dateTimeForItem(item).toSeconds();

    const foundRange = ranges.find((range) => range.startTime <= itemSeconds && range.endTime >= itemSeconds);
    if (foundRange && byStart[foundRange.startTime] !== undefined) {
      byStart[foundRange.startTime] = byStart[foundRange.startTime] + 1;
    } else if (foundRange) {
      byStart[foundRange.startTime] = 0;
    }
  });

  return byStart;
}

export function zipDaysToValue(dayDict: { [key: number]: number }): Array<IDashboardChartPayload & { value: number }> {
  const keys = Object.keys(dayDict);
  return keys.map((key) => ({ day: toNumber(key), value: dayDict[toNumber(key)] }));
}

export const getRandomRefreshInterval = () => {
  //returns a number of minutes between 8 and 12 for the dashboard refresh interval
  return Math.floor(Math.random() * (12 - 8) + 8);
};

export function convertDashboardTimeFrameToTimeRangeType(timeFrame: DashboardTimeFrame): TimeRangeType {
  switch (timeFrame) {
    case 'payPeriod':
      return TimeRangeType.PAY_PERIOD;
    case 'monthly':
      return TimeRangeType.MONTHLY;
    case 'weekly':
      return TimeRangeType.WEEKLY;
    case 'daily':
      return TimeRangeType.DAILY;
    default:
      return TimeRangeType.CUSTOM;
  }
}

export function convertDashboardTimeFrameToLaborMetricsInterval(timeFrame: DashboardTimeFrame): LaborMetricsInterval {
  switch (timeFrame) {
    case 'payPeriod':
      return LaborMetricsInterval.PayPeriod;
    case 'monthly':
      return LaborMetricsInterval.Month;
    case 'daily':
    case 'pastSeven':
    case 'pastFourteen':
      return LaborMetricsInterval.Day;
    // Weekly requires manual aggregation
    case 'weekly':
      return LaborMetricsInterval.Day;
  }
}

export function convertTotalsToDashboardListRow(totals: Array<{ id: string; title: string; total: number }>) {
  const activeRows = take(sortByField(totals, 'total', 'DESC'), 5).map((item) => {
    return {
      value: Duration.fromMillis(item.total * 1000).toFormat('h:mm'),
      label: item.title,
      id: item.id,
    };
  });

  return takeWithPopulate(activeRows, 5, getDefaultDashboardListRow);
}

export function groupItemsToTimeRange<T extends { startTime: number }>(
  timeRanges: Array<ITimeRange<number>>,
  items: T[]
): { [key: number]: T[] } {
  return timeRanges.reduce((acc, { startTime, endTime }) => {
    const itemsInTimeRange = items.filter((item) => item.startTime >= startTime && item.startTime <= endTime);
    return {
      ...acc,
      [startTime]: itemsInTimeRange,
    };
  }, {});
}
