import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreManageModelMaps {
  locationId?: string;
}

export const initialState: IStoreManageModelMaps = {
  locationId: undefined,
};

const slice = createSlice({
  name: 'ManageModelMaps',
  initialState,
  reducers: {
    setManageModelMapsSelectedPin: (state: IStoreManageModelMaps, action: PayloadAction<string | undefined>) => {
      state.locationId = action.payload;
      return state;
    },
    clearManageModelMapsSelectedPin: (state: IStoreManageModelMaps) => {
      state.locationId = undefined;
      return state;
    },
  },
});

export const { setManageModelMapsSelectedPin, clearManageModelMapsSelectedPin } = slice.actions;
export default slice.reducer;
