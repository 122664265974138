import { RequestOptions } from 'graphql-request';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import { IDictionary } from 'types/Dictionary';
import { KeysOfType } from 'types/util/KeysOfType';
import { AnyObject } from 'types/util/Object';
import { Optional } from 'types/util/Optional';
import { mapNotNil } from 'utils/collectionUtils';
import { graphqlPagingLoopShouldBreak, parseCursor } from 'utils/graphQlUtils';
import useGraphQLClient from '../useGraphQLClient/useGraphQLClient';

export default function useGraphQLPaging() {
  const client = useGraphQLClient();

  return useCallback(
    async <T extends AnyObject, V extends IDictionary<any> = AnyObject, M extends ICursorable = ICursorable>(
      options: RequestOptions<V>,
      key: KeysOfType<T, Optional<M[]>>,
      pageSize: number = 50
    ) => {
      const fn = async (after: string | null, first: number): Promise<T> => {
        return client.request<T, V>({ ...options, variables: { ...options.variables, first, after } });
      };

      const pages: T[] = [];
      let after: string | null = null;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const page: T = await fn(after, pageSize);
        const pageData = page[key];
        if (pageData && pageData.length) {
          pages.push(page);
        }

        if (graphqlPagingLoopShouldBreak(pageData, pageSize)) {
          break;
        }

        after = parseCursor(pageData, after);
      }

      return mapNotNil(pages, (page) => page[key] ?? null).flat();
    },
    [client]
  );
}
