import { FetchPolicy, QueryResult, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import LazyLoadPicker from 'components/foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import IEquipmentModel from 'types/EquipmentModel';
import { stringUtils } from 'utils';
import {
  equipmentModelQuery,
  equipmentModelsQuery,
} from '../EquipmentModelsForMakePicker/equipment-models-picker-queries';

export interface IEquipmentYearPickerProps {
  value: string | null;
  equipmentModelTitle: string | null | undefined;
  equipmentCategoryId: string | null | undefined;
  equipmentMakeId: string | null | undefined;
  onSelect: (makeId: string | null) => void;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  fetchPolicy?: FetchPolicy;
  className?: ClassName;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
  autofocus?: boolean;
}

const EquipmentYearPicker = (props: IEquipmentYearPickerProps) => {
  const {
    value,
    equipmentModelTitle,
    equipmentCategoryId,
    equipmentMakeId,
    onSelect,
    minWidth,
    error,
    placeholder,
    position,
    fetchPolicy,
    closeButtonRender,
    dropDownButtonRender,
    autofocus,
    className,
  } = props;

  const client = useApolloClient();

  const searchablePickerData = useRef<IEquipmentModel[]>();
  const [pickerData, setPickerData] = useState<IEquipmentModel[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);
  const [apolloError, setApolloError] = useState(false);
  const firstRender = useRef(true);

  // update list when searching (manually filtering because there is no year search on GraphQL)
  useEffect(() => {
    if (searchDebounce && searchablePickerData.current) {
      // filter loaded results when we have search text and a result from our query
      setPickerData(searchablePickerData.current.filter((model) => _.includes(model.year.toString(), searchDebounce)));
    } else if (searchablePickerData.current) {
      // search was cleared so show all items again
      setPickerData(searchablePickerData.current);
    }
  }, [searchDebounce]);

  // clear list and selected model when the make is changed
  useEffect(() => {
    searchablePickerData.current = undefined;
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setPickerData([]);
    setLoadedAll(false);
    onSelect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentModelTitle]);

  function handleDidLoad(models: IEquipmentModel[], err: boolean, allLoaded: boolean) {
    if (!err) {
      setLoadedAll(allLoaded);
      setPickerData(models);
    }

    setApolloError(err);
  }

  function onSearch(val: string) {
    setSearchValue(val);
  }

  function onClose() {
    setLoadedAll(false);
    setPickerData([]);
    setSearchValue('');
  }

  function handleSelect(model: IEquipmentModel | null) {
    onSelect(model ? model.id : null);
  }

  async function getModels(after?: string): Promise<IEquipmentModel[]> {
    return new Promise((resolve, reject) => {
      if (after) {
        // don't bother paging
        resolve([]);
      } else {
        // load data from server initially
        client
          .query({
            fetchPolicy: fetchPolicy || 'cache-first',
            query: equipmentModelsQuery,
            variables: {
              after,
              filter: {
                title: { equal: equipmentModelTitle },
                equipmentMakeId: { equal: equipmentMakeId },
                equipmentCategoryId: { equal: equipmentCategoryId },
                deletedOn: { isNull: true },
              },
              first: 100, // shouldn't have more than 100 years
            },
          })
          .then((result: { data: { equipmentModels: IEquipmentModel[] } }) => {
            searchablePickerData.current = result.data.equipmentModels;
            resolve(searchablePickerData.current);
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  function renderValueTemplate(id: string | null) {
    if (id) {
      const equipmentModel = _.find(pickerData, (model) => model.id === id);
      if (equipmentModel) {
        return <div className="ellipsis">{equipmentModel.year}</div>;
      } else {
        return (
          <Query query={equipmentModelQuery} variables={{ modelId: id }}>
            {({ data }: QueryResult<{ equipmentModels: IEquipmentModel[] }>) => {
              return <>{data && data.equipmentModels.length ? data.equipmentModels[0].year : <></>}</>;
            }}
          </Query>
        );
      }
    }

    return <></>;
  }

  function renderRow(model: IEquipmentModel) {
    return (
      <Bar size={Size.SMALL} className="px-3">
        <div className="ellipsis">
          {searchValue ? stringUtils.highlight(model.year.toString(), searchValue) : model.year}
        </div>
      </Bar>
    );
  }

  const classes = classNames('equipment-year-picker', className);

  return (
    <LazyLoadPicker
      value={value}
      disabled={_.isNil(equipmentModelTitle)}
      getData={getModels}
      didLoad={handleDidLoad}
      error={error}
      dataError={apolloError}
      data={pickerData}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      valueTemplate={renderValueTemplate}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      searchValue={searchValue}
      onSearch={onSearch}
      placeholder={placeholder}
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      className={classes}
      autofocus={autofocus}
    />
  );
};

EquipmentYearPicker.defaultProps = {
  minWidth: '350px',
};

export default EquipmentYearPicker;
