import { Reducer } from 'redux';
import { IStoreOrganization, IStoreOrganizationSetAction, ORGANIZATION_SET } from './organizationIndex';

export type IOrganizationActions = IStoreOrganizationSetAction;

const initialState: IStoreOrganization = {} as IStoreOrganization;

export const organizationReducers: Reducer<IStoreOrganization, IOrganizationActions> = (
  state: IStoreOrganization = initialState,
  action: IOrganizationActions
): IStoreOrganization => {
  switch (action.type) {
    case ORGANIZATION_SET:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default organizationReducers;
