import { Button, CheckboxFormField, Form, Radio, RadioGroupFormField } from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { t } from 'i18next';
import { useRef } from 'react';

export type SupportedTableRowHeight = 'compact' | 'standard' | 'comfortable';

export interface ITableRowHeightForm {
  initialValue: TableCellHeight;
  options: TableCellHeight[];
  onSubmit: (data: ITableRowHeightFormData) => void;
  className?: ClassName;
}

export interface ITableRowHeightFormData {
  cellHeight: TableCellHeight;
  applyToAll: boolean;
}

const tableRowLabelLookup = {
  compact: t('Compact'),
  cozy: t('Cozy'),
  standard: t('Standard'),
  comfortable: t('Comfortable'),
} as const;

export default function TableRowHeightForm({ initialValue, options, onSubmit, className }: ITableRowHeightForm) {
  const initialFormData = useRef<ITableRowHeightFormData>({
    cellHeight: initialValue,
    applyToAll: false,
  });

  function renderRadioButton(key: TableCellHeight) {
    return <Radio key={key} value={key} label={tableRowLabelLookup[key]} />;
  }

  function handleSubmit(data: ITableRowHeightFormData) {
    onSubmit(data);
  }

  const classes = classNames('table-row-height-form', className);

  return (
    <div className={classes}>
      <h3>{t('Select Height')}</h3>
      <Form
        onSubmit={handleSubmit}
        data={initialFormData.current}
        render={(form) => (
          <>
            <RadioGroupFormField className="section-options my-4" name="cellHeight" form={form}>
              {options.map(renderRadioButton)}
            </RadioGroupFormField>
            <CheckboxFormField name="applyToAll" label={t('Apply to all reports')} form={form} />
            <Button onClick={form.handleSubmit} type="primary">
              {t('Submit')}
            </Button>
          </>
        )}
      />
    </div>
  );
}

TableRowHeightForm.defaultProps = {
  initialValue: 'compact',
  options: ['compact', 'cozy', 'standard', 'comfortable'] as const,
};
