import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreTimeEntryTable {
  checkedTimeEntryIds: string[];
  checkedTimeOffIds: string[];
}

export const initialState: IStoreTimeEntryTable = {
  checkedTimeEntryIds: [],
  checkedTimeOffIds: [],
};

const slice = createSlice({
  name: 'timeEntryTable',
  initialState,
  reducers: {
    updateCheckedTimeEntryIds: (state: IStoreTimeEntryTable, action: PayloadAction<string[]>) => {
      state.checkedTimeEntryIds = action.payload;
      return state;
    },
    clearCheckedTimeEntryIds: (state: IStoreTimeEntryTable) => {
      state.checkedTimeEntryIds = [];
      return state;
    },
    updateCheckedTimeOffIds: (state: IStoreTimeEntryTable, action: PayloadAction<string[]>) => {
      state.checkedTimeOffIds = action.payload;
      return state;
    },
    clearCheckedTimeOffIds: (state: IStoreTimeEntryTable) => {
      state.checkedTimeOffIds = [];
      return state;
    },
  },
});

export const { updateCheckedTimeEntryIds, clearCheckedTimeEntryIds, updateCheckedTimeOffIds, clearCheckedTimeOffIds } =
  slice.actions;
export default slice.reducer;
