import useEmployeeNameFormat, { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { useCallback } from 'react';
import { MemberSearch, MemberSearchFields, SearchType } from '__generated__/graphql';

function getFields(format: EmployeeNameFormat): MemberSearchFields[] {
  switch (format) {
    case 'FIRST':
      return [MemberSearchFields.FirstName];
    case 'FIRST_LAST':
      return [MemberSearchFields.FirstName, MemberSearchFields.LastName];
    case 'LAST':
      return [MemberSearchFields.LastName];
    case 'LAST_FIRST':
      return [MemberSearchFields.LastName, MemberSearchFields.FirstName];
  }
}

export function getApolloMemberNameSearch(format: EmployeeNameFormat, searchValue: string): MemberSearch {
  let updatedSearchValue = searchValue;
  if (format === 'LAST_FIRST') {
    if (searchValue.includes(', ')) {
      updatedSearchValue = updatedSearchValue.split(', ').join(' ');
    } else if (searchValue.includes(',')) {
      updatedSearchValue = updatedSearchValue.split(',').join(' ');
    }
  }
  return { type: SearchType.Contains, value: updatedSearchValue, fields: getFields(format) };
}

export default function useApolloMemberNameSearch() {
  const [employeeNameFormat] = useEmployeeNameFormat();
  return useCallback(
    (searchValue: string) => {
      return getApolloMemberNameSearch(employeeNameFormat, searchValue);
    },
    [employeeNameFormat]
  );
}
