import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import BreakMultiPicker, {
  IBreakMultiPickerItem,
  IBreakMultiPickerProps,
} from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import { AnyObject } from 'types/util/Object';

export interface IBreakMultiPickerFormFieldProps<FormType extends AnyObject>
  extends Omit<IBreakMultiPickerProps, 'breaks' | 'onChange'>,
  IFormFieldDefaultProps<IBreakMultiPickerItem[], FormType> {
  onChange?: (breaks: IBreakMultiPickerItem[]) => void;
}

function BreakMultiPickerFormField<FormType extends AnyObject>({
  form,
  name,
  validations,
  onChange,
  revalidateOnNewValidations,
  className,
  ...breakMultiPickerProps
}: IBreakMultiPickerFormFieldProps<FormType>) {
  function renderField({ ...fieldProps }) {
    function handleOnChange(value: IBreakMultiPickerItem[]) {
      fieldProps.handleChange(value, onChange);
    }

    return (
      <>
        <BreakMultiPicker {...breakMultiPickerProps} onChange={handleOnChange} breaks={form.state.data[name] || []} />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames('break-multi-picker-form-field', className);

  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

BreakMultiPickerFormField.defaultProps = {
  timeFormat: 't',
  displayDateOnEntryDifference: true,
};

export default BreakMultiPickerFormField;
