import { useApolloClient } from '@apollo/client';
import {
  Button,
  Form,
  IFormRender,
  Label,
  TextareaFormField,
  TextFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { equipmentCategoryQuery } from 'components/domain/equipment/EquipmentCategoriesForTypePicker/equipment-cateory-picker-queries';
import { equipmentMakeQuery } from 'components/domain/equipment/EquipmentMakesForCategoryPicker/equipment-makes-picker-queries';
import { useActiveMember, useAxios, useOpenable } from 'hooks';
import _ from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import IEquipmentCategory from 'types/EquipmentCategory';
import IEquipmentMake from 'types/EquipmentMake';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import './EquipmentMissingForm.scss';

export interface IEquipmentMissingFormProps {
  className?: ClassName;
  categoryId?: string;
  makeId?: string;
  modelTitle?: string;
  onSubmit: (data?: IEquipmentMissingFormData) => void;
}

export interface IEquipmentMissingFormData {
  categoryTitle?: string | null;
  makeTitle?: string | null;
  modelTitle?: string | null;
  year?: string | null;
  description?: string | null;
}

const EquipmentMissingForm: React.FC<IEquipmentMissingFormProps> = (props) => {
  const { className, onSubmit, categoryId, makeId, modelTitle } = props;

  const [formData, setFormData] = useState<IEquipmentMissingFormData>();
  const [loading, setLoading] = useState<boolean>(true);
  const activeMember = useActiveMember();
  const axiosClient = useAxios();
  const apolloClient = useApolloClient();
  const errorToastState = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));

  const classes = classNames({ 'equipment-missing-form': true, loading }, className);

  useEffect(() => {
    getPrefilledData().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrefilledData = async () => {
    const [categoryTitle, makeTitle] = await Promise.all([syncCategoryTitle(), syncMakeTitle()]);

    return setFormData({
      makeTitle,
      modelTitle,
      categoryTitle,
    });
  };

  const syncCategoryTitle = async () => {
    if (categoryId) {
      const results = await apolloClient.query<{ equipmentCategories: IEquipmentCategory[] }>({
        query: equipmentCategoryQuery,
        variables: {
          categoryId,
        },
      });

      return _.first(results.data.equipmentCategories)?.title;
    }

    return null;
  };

  const syncMakeTitle = async () => {
    if (makeId) {
      const results = await apolloClient.query<{ equipmentMakes: IEquipmentMake[] }>({
        query: equipmentMakeQuery,
        variables: {
          makeId,
        },
      });

      return _.first(results.data.equipmentMakes)?.title;
    }

    return null;
  };

  function renderFormFields({ ...form }: IFormRender<IEquipmentMissingFormData>): ReactNode {
    return (
      <div>
        <EquipmentMissingMessage />
        <EquipmentMakeField {...form} />
        <EquipmentModelField {...form} />
        <EquipmentYearField {...form} />
        <DescriptionField {...form} />
        <SendButton {...form} />
      </div>
    );
  }

  function onFormSubmit(data: IEquipmentMissingFormData) {
    const description =
      (data.description ?? '') +
      '\n' +
      'Category title: ' +
      data.categoryTitle +
      '\n' +
      (activeMember.email ?? 'No email, sorry breh!');

    const params = {
      requested_by: activeMember.id!,
      organization_id: activeMember.organizationId!,
      make: data.makeTitle!,
      model: data.modelTitle!,
      year: data.year ?? '',
      description,
      created_on: DateTime.utc().toSeconds().toString(),
    };

    return axiosClient
      .post('equipment-missing', params)
      .then(() => onSubmit(data))
      .catch(() => {
        errorToastMessage.current = t('There was an error saving.');
        errorToastState.open();
      });
  }

  return (
    <div className={classes}>
      <div className="mb-6 p-8">
        <Form data={formData} onSubmit={onFormSubmit} render={renderFormFields} className={classes} />

        <Toast isOpen={errorToastState.isOpen} onClose={errorToastState.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>
      </div>
    </div>
  );
};

const EquipmentMissingMessage: React.FC = () => (
  <Label>
    {t(
      'Can’t find what you’re looking for? Let us know. Please try and use all of the fields below to describe the equipment you want to add and we’ll get back to you.'
    )}
  </Label>
);

const EquipmentMakeField: React.FC<IFormRender<IEquipmentMissingFormData>> = (form) => (
  <>
    <Label required={true}>{t('Make')}</Label>
    <TextFormField
      name={'makeTitle'}
      form={form}
      restrictTo={{ maxLength: 64 }}
      validations={[{ validation: v_require }]}
    />
  </>
);

const EquipmentModelField: React.FC<IFormRender<IEquipmentMissingFormData>> = (form) => (
  <>
    <Label required={true}>{t('Model')}</Label>
    <TextFormField
      name={'modelTitle'}
      form={form}
      restrictTo={{ maxLength: 64 }}
      validations={[{ validation: v_require }]}
    />
  </>
);

const EquipmentYearField: React.FC<IFormRender<IEquipmentMissingFormData>> = (form) => (
  <>
    <Label>{t('Year')}</Label>
    <TextFormField className="year-field" type="number" name={'year'} form={form} restrictTo={{ maxLength: 4 }} />
  </>
);

const DescriptionField: React.FC<IFormRender<IEquipmentMissingFormData>> = (form) => (
  <>
    <Label>{t('Description')}</Label>
    <TextareaFormField name={'description'} form={form} restrictTo={{ maxLength: 1200 }} />
  </>
);

const SendButton: React.FC<IFormRender<IEquipmentMissingFormData>> = (form) => (
  <Button className="pl-5 pr-5" type="primary" onClick={form.handleSubmit}>
    {t('Send')}
  </Button>
);

export default EquipmentMissingForm;
