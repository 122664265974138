import { isSupportedDocumentFileExtension } from 'containers/documents/utils/utils';
import { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { t } from 'i18next';
import { isNil, take } from 'lodash';
import { DateTime } from 'luxon';
import { dateTimeFromUtcISO, localizeDateTimeFromUtcISO } from 'utils/dateUtils';
import { sortMembersByFormat } from 'utils/memberUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getFullNameFromMember, isStringInUnionList } from 'utils/stringUtils';
import {
  TSupportedPostAttachmentFileExtensions,
  TSupportedPostAttachmentFileType,
  supportedPostAttachmentFileExtensions,
  supportedPostAttachmentFileType,
} from '../constants/constants';
import { BulletinMembers, BulletinPost, BulletinPostAttachment, RecipientFilter } from '../types/types';

export function isSupportedPostAttachmentFileExtension(value: string): value is TSupportedPostAttachmentFileExtensions {
  return isStringInUnionList<TSupportedPostAttachmentFileExtensions>(value, supportedPostAttachmentFileExtensions);
}

export function isSupportedPostAttachmentFileType(value: string): value is TSupportedPostAttachmentFileType {
  return isStringInUnionList<TSupportedPostAttachmentFileType>(value, supportedPostAttachmentFileType);
}

export function formatPostCommentDate(dateString: string): string {
  const now = DateTime.utc();
  const date = dateTimeFromUtcISO(dateString).toLocal();

  const diff = now.diff(date, 'seconds').seconds;

  if (diff < 60 * 60) {
    const minutes = Math.floor(diff / 60);
    return date.toRelative() ?? `${minutes} minutes ago`;
  } else if (diff < 60 * 60 * 24 && now.get('day') === date.get('day')) {
    const formattedTime = date.toFormat('t');
    return `Today, ${formattedTime}`;
  } else if (diff < 60 * 60 * 24) {
    const formattedTime = date.toFormat('t');
    return `Yesterday, ${formattedTime}`;
  } else {
    const formattedDate = date.toFormat('MMM d, t');
    return formattedDate;
  }
}

export function filterMembersForBulletinPost(
  members: BulletinMembers[],
  filter: RecipientFilter,
  haveAcknowledged: string[]
) {
  if (filter === 'hasNotAcknowledged') {
    return members.filter((item) => !haveAcknowledged.includes(item.id));
  }

  if (filter === 'hasAcknowledged') {
    return members.filter((item) => haveAcknowledged.includes(item.id));
  }

  return members;
}

export function getDisplayedMembers(bulletinPost: BulletinPost, employeeNameFormat: EmployeeNameFormat) {
  const activeRecipients = bulletinPost.recipients.filter((item) => isNil(item.deletedOn));
  if (bulletinPost.recipientType === 'everyone') {
    return 'Everyone';
  } else if (bulletinPost.recipientType === 'position') {
    return bulletinPost.position?.title;
  } else if (bulletinPost.recipientType === 'individuals' && activeRecipients.length > 0) {
    const sortedMembers = sortMembersByFormat<BulletinMembers>(
      activeRecipients.map((item) => item.member),
      employeeNameFormat,
      'asc'
    );
    const memberToShowCount = 2;
    const memberText = take(sortedMembers, memberToShowCount)
      .map((member) => getFullNameFromMember(member))
      .join(', ');
    const otherCount = sortedMembers.length - memberToShowCount;

    if (otherCount <= 0) {
      return memberText;
    }

    const multipleOthers = otherCount > 1;
    return `${memberText}, ${otherCount} ${multipleOthers ? t('Others') : t('Other')}`;
  }
}

export function getDisplayedCreator(bulletinPost: BulletinPost) {
  return `${t('From:')} ${bulletinPost.createdByMember.firstName} ${bulletinPost.createdByMember.lastName}`;
}

export function getDisplayedDate(bulletinPost: BulletinPost) {
  const date = localizeDateTimeFromUtcISO(bulletinPost.createdOn);
  const formattedDate = date.toFormat('ccc, MMM d, t');

  return `${t('Date:')} ${formattedDate}`;
}

export function getDisplayedTags(bulletinPost: BulletinPost) {
  if (bulletinPost.project && bulletinPost.equipment) {
    return `${getFormattedPathFromProject(bulletinPost.project, true)}, ${bulletinPost.equipment.equipmentName}`;
  } else if (bulletinPost.project) {
    return getFormattedPathFromProject(bulletinPost.project, true);
  } else if (bulletinPost.equipment) {
    return bulletinPost.equipment.equipmentName;
  } else {
    return '';
  }
}

export function sortAttachmentsBySubmittedOn(a: BulletinPostAttachment, b: BulletinPostAttachment) {
  const dateA = dateTimeFromUtcISO(a.submittedOn).toLocal();
  const dateB = dateTimeFromUtcISO(b.submittedOn).toLocal();
  return dateA.toMillis() - dateB.toMillis();
}

export function sortAttachmentByFileType(a: BulletinPostAttachment, b: BulletinPostAttachment) {
  if (isNil(a.fileType) || isNil(b.fileType)) {
    throw Error('Undefined File Types');
  }
  if (
    (isSupportedDocumentFileExtension(a.fileType) && isSupportedDocumentFileExtension(b.fileType)) ||
    (!isSupportedDocumentFileExtension(a.fileType) && !isSupportedDocumentFileExtension(b.fileType))
  ) {
    return 0;
  } else if (!isSupportedDocumentFileExtension(a.fileType) && isSupportedDocumentFileExtension(b.fileType)) {
    return -1;
  } else if (isSupportedDocumentFileExtension(a.fileType) && !isSupportedDocumentFileExtension(b.fileType)) {
    return 1;
  } else {
    return 0;
  }
}
