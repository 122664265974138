import { DocumentNode, useQuery } from '@apollo/client';
import { POSITION_QUERY } from 'apollo/queries/position-queries';
import { useMemo } from 'react';
import IPosition from 'types/Position';

export default function usePositionQuery(
  query: DocumentNode = POSITION_QUERY,
  filter: object = {
    deletedOn: { isNull: true },
  }
) {
  const { data, ...rest } = useQuery<{ positions: IPosition[] }>(query, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
  });

  return useMemo(
    () => ({
      positions: data?.positions ?? [],
      ...rest,
    }),
    [data, rest]
  );
}
