import { Button, Divider, Icon, Label } from '@busybusy/webapp-react-ui';
import assert from 'assert';
import { ChevronRightIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import IEquipment from 'types/Equipment';
import { makeModelYearString } from 'utils/equipmentUtils';
import { t } from 'utils/localize';
import './ManageEquipmentMapInfoWindow.scss';

export enum MapEquipmentMapActionClasses {
  EQUIPMENT_DETAILS = 'equipment-details',
  MANAGE_EQUIPMENT = 'manage-equipment',
  DIRECTIONS = 'directions',
}

export interface IManageEquipmentMapInfoWindowProps {
  className?: ClassName;
  equipment: IEquipment;
  locationId: string;
}

const ManageEquipmentMapInfoWindow = (props: IManageEquipmentMapInfoWindowProps) => {
  const { className, equipment, locationId } = props;

  const classes = classNames('manage-equipment-map-info-window', className);

  return (
    <div className={classes}>
      <div className={'row-container pl-5'}>
        <div className="pb-8">
          <Label>{equipment.equipmentName}</Label>
          <div>{makeModelYearString(equipment)}</div>
        </div>
        <div className="pl-10 pr-5">
          <Button
            className={'manage-equipment-' + locationId}
            type={'link'}
            onClick={() => assert(false, 'Should not be called.')}
          >
            {t('Manage')}
          </Button>
          <Button
            className={'equipment-details-' + locationId}
            type={'link'}
            onClick={() => assert(false, 'Should not be called.')}
          >
            {t('View')}
          </Button>
        </div>
      </div>
      <Divider />
      <div className={'row-container pl-5 directions-' + locationId}>
        <div className="pb-8 pt-8">
          <Label>{t('Directions')}</Label>
          <div className="pb-2">{t('Get directions to last known location.')}</div>
        </div>
        <div className="row-disclosure-indicator pb-5 pr-5">
          <Icon svg={ChevronRightIcon} />
        </div>
      </div>
    </div>
  );
};

export default ManageEquipmentMapInfoWindow;
