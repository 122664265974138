import { SortDirection } from '@busybusy/webapp-react-ui';
import { isNil } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { sortByCustomField, sortByField } from 'utils/tableUtils';

export default function useTableSorting<T, K extends keyof T = keyof T>(
  data: T[],
  defaultSortBy?: K,
  defaultSortDir?: SortDirection,
  getCustomSortField?: (item: T, key: K) => any
) {
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(defaultSortDir);
  const [sortedBy, setSortedBy] = useState<K | undefined>(defaultSortBy);
  const [sortIsDirty, setSortIsDirty] = useState(false);
  const getCustomSortFieldStatic = useRef(getCustomSortField);

  const onSort = useCallback(({ sortDir, sortBy }: any) => {
    setSortedBy(sortBy);
    setSortDirection(sortDir);
    setSortIsDirty(true);
  }, []);

  const sorted = useMemo(() => {
    if (!sortedBy || !sortDirection) {
      return data;
    }

    if (isNil(getCustomSortFieldStatic.current)) {
      return sortByField(data, sortedBy, sortDirection);
    } else {
      return sortByCustomField(data, sortDirection, (item) => getCustomSortFieldStatic.current?.(item, sortedBy));
    }
  }, [data, sortDirection, sortedBy]);

  return {
    sorted,
    sortDirection,
    sortedBy,
    onSort,
    sortIsDirty,
  };
}
