import { Field, FieldFooter } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import WageRateSelect from 'components/domain/wage-history/WageRateSelect/WageRateSelect';
import WageRate from 'types/enum/WageRate';

interface IWageRateFormFieldProps {
  className?: ClassName;
  form: any;
  name: string;
  onChange?: (value: WageRate) => void;
  validations?: any;
}

function WageRateFormField(props: IWageRateFormFieldProps) {
  const { form, name, validations, className, onChange, ...other } = props;

  const classes = classNames('wage-rate-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: WageRate) {
      fieldProps.handleChange(val, onChange);
    }

    const formValue = form.state.data[name];
    const value = formValue ? formValue : WageRate.HOURLY;

    return (
      <>
        <WageRateSelect error={fieldProps.hasError} value={value} onChange={handleOnSelect} {...other} />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return <Field form={form} name={name as string} validations={validations} className={classes} render={renderField} />;
}

export default WageRateFormField;
