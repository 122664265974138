import { ITextFieldProps, TextField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ChangeEvent } from 'react';
import './BlockingTextField.scss';

export interface IBlockingTextFieldProps extends Omit<ITextFieldProps, 'onChange'> {
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  isValueAllowed: (v: string, event: ChangeEvent<HTMLInputElement>) => boolean;
}

function BlockingTextField({ isValueAllowed, onChange, className, ...textFieldProps }: IBlockingTextFieldProps) {
  function onTextChange(value: string, event: ChangeEvent<HTMLInputElement>) {
    if (isValueAllowed(value, event)) {
      onChange?.(value, event);
    }
  }

  const classes = classNames('blocking-text-field', className);
  return <TextField className={classes} {...textFieldProps} onChange={onTextChange} />;
}

export default BlockingTextField;
