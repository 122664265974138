import { useEmployeeNameFormat } from 'hooks';
import { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { useCallback } from 'react';

export function formatEmployeeName(firstName: string, lastName: string, nameFormat: EmployeeNameFormat) {
  switch (nameFormat) {
    case 'FIRST':
      return firstName;
    case 'LAST':
      return lastName;
    case 'FIRST_LAST':
      return `${firstName} ${lastName}`;
    case 'LAST_FIRST':
      return `${lastName}, ${firstName}`;
  }
}

export default function useEmployeeNameFormatter() {
  const [employeeNameFormat] = useEmployeeNameFormat();
  return useCallback(
    (firstName: string, lastName: string) => formatEmployeeName(firstName, lastName, employeeNameFormat),
    [employeeNameFormat]
  );
}
