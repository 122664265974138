import break_end_pin from 'assets/icons/break_end_pin.svg';
import break_start_pin from 'assets/icons/break_start_pin.svg';
import green_pin from 'assets/icons/green_pin.svg';
import red_pin from 'assets/icons/red_pin.svg';
import steel_blue_pin from 'assets/icons/steel_blue_pin.svg';
import { isNil, isNull, last } from 'lodash';
import IIdable from 'types/Idable';
import IMemberLocation from 'types/MemberLocation';
import { IProjectInfo } from 'types/ProjectInfo';
import ITimeEntry from 'types/TimeEntry';
import MemberLocationType from 'types/enum/MemberLocationType';
import { Optional } from 'types/util/Optional';
import { locationUtils } from 'utils';
import { dateTimeFromISOKeepZone } from './dateUtils';
import { t } from './localize';
import { TimeEntry, TimeEntryBreak } from '__generated__/graphql';
import { Nullable } from 'types/util/Nullable';

export interface ITimeEntryGetMemberLocationType {
  startLocation?: Optional<IIdable<string>>;
  endLocation?: Optional<IIdable<string>>;
  breaks?: Optional<
    Array<{
      deletedOn?: Optional<string>;
      startLocation?: Optional<IIdable<string>>;
      endLocation?: Optional<IIdable<string>>;
    }>
  >;
}

export function getMemberLocationType(locationId: string, timeEntry: ITimeEntryGetMemberLocationType) {
  return getType(timeEntry, { id: locationId });
}

// Get the type of a location based on a time entry / break.
export function getType(timeEntry: ITimeEntryGetMemberLocationType, location: Pick<IMemberLocation, 'id'>) {
  if (timeEntry.startLocation && timeEntry.startLocation.id === location.id) {
    return MemberLocationType.START;
  }
  if (timeEntry.endLocation && timeEntry.endLocation.id === location.id) {
    return MemberLocationType.END;
  }
  const breakStartIds = timeEntry.breaks
    ?.filter((brk) => isNull(brk.deletedOn) && !isNil(brk.startLocation))
    .map((brk) => brk.startLocation!.id);
  const breakEndIds = timeEntry.breaks
    ?.filter((brk) => isNull(brk.deletedOn) && !isNil(brk.endLocation))
    .map((brk) => brk.endLocation!.id);

  if (breakStartIds?.includes(location.id)) {
    return MemberLocationType.BREAK_START;
  }

  if (breakEndIds?.includes(location.id)) {
    return MemberLocationType.BREAK_END;
  }

  return MemberLocationType.LOCATION_UPDATE;
}

// Get the title of a given type.
export function getTypeTitle(type: MemberLocationType) {
  switch (type) {
    case MemberLocationType.ALL:
      return t('All');
    case MemberLocationType.START:
      return t('Start');
    case MemberLocationType.END:
      return t('End');
    case MemberLocationType.BREAK_START:
      return t('Break Start');
    case MemberLocationType.BREAK_END:
      return t('Break End');
    case MemberLocationType.LOCATION_UPDATE:
      return t('Location Update');
    default:
      return t('All');
  }
}

// Get the map pin icon for a given location type.
export function getTypeIcon(type: MemberLocationType) {
  switch (type) {
    case MemberLocationType.ALL:
      return steel_blue_pin;
    case MemberLocationType.START:
      return green_pin;
    case MemberLocationType.END:
      return red_pin;
    case MemberLocationType.BREAK_START:
      return break_start_pin;
    case MemberLocationType.BREAK_END:
      return break_end_pin;
    case MemberLocationType.LOCATION_UPDATE:
      return steel_blue_pin;
    default:
      return steel_blue_pin;
  }
}

// Get the distance in miles with one decimal places as a string.
// Distance is not null only when outside of project.
// Null means either the project does not have a valid location to compare too or the location is inside the project radius.
export function getDistanceWhenOutsideProject(location: IMemberLocation, projectInfo?: IProjectInfo): string | null {
  if (
    projectInfo &&
    projectInfo.latitude &&
    projectInfo.longitude &&
    projectInfo.latitude !== 0 &&
    projectInfo.longitude !== 0 &&
    projectInfo.locationRadius
  ) {
    const distanceInMiles = locationUtils.distanceWithProjectRadius(
      projectInfo.latitude,
      projectInfo.longitude,
      location.latitude,
      location.longitude,
      'M',
      projectInfo.locationRadius
    );
    const radiusInMiles = locationUtils.getMiles(projectInfo.locationRadius);
    if (distanceInMiles > 0 && distanceInMiles > radiusInMiles) {
      return distanceInMiles.toFixed(1);
    }
  }
  return null;
}

type TimedBreak = { startTime: TimeEntryBreak['startTime']; endTime?: Nullable<TimeEntryBreak['endTime']> };

export function getLocationDateString(
  timeEntry: Pick<TimeEntry, 'startTime'> & { breaks: TimedBreak[] } & ITimeEntryGetMemberLocationType,
  location?: IMemberLocation | null
) {
  let dateString = timeEntry.startTime;
  let statusString = t('Clocked in');
  if (location) {
    const locationType = getType(timeEntry, location);
    switch (locationType) {
      case MemberLocationType.BREAK_START: {
        const startBrk = last(timeEntry.breaks);
        if (startBrk) {
          dateString = startBrk.startTime;
          statusString = t('Break started');
        }
        break;
      }
      case MemberLocationType.BREAK_END: {
        const endBreak = last(timeEntry.breaks);
        if (endBreak?.endTime) {
          dateString = endBreak.endTime;
          statusString = t('Break ended');
        }
        break;
      }
      case MemberLocationType.LOCATION_UPDATE:
        dateString = location.timeLocal;
        statusString = t('Last location');
        break;
    }
  }
  return statusString + dateTimeFromISOKeepZone(dateString).toFormat(' @ t');
}
