import { gql } from '@apollo/client';

export const PROJECT_DISPLAY_FRAGMENT = gql`
  fragment ProjectDisplay on Project {
    id
    title
    depth
    ancestors {
      id
      title
      depth
    }
  }
`;

export const SIMPLE_PROJECT_FRAGMENT = gql`
  fragment SimpleProject on Project {
    id
    title
    depth
    parentProjectId
    rootProjectId
    cursor
    archivedOn
  }
`;
