import { Button, Divider, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import './CsvImportReviewHeader.scss';

export interface ICsvImportReviewHeaderProps {
  onPrintReviewClick: () => void;
  className?: ClassName;
}

const CsvImportReviewHeader: FunctionComponent<ICsvImportReviewHeaderProps> = (props) => {
  const { className, onPrintReviewClick } = props;

  const [t] = useTranslation();

  const classes = classNames(
    {
      'csv-import-review-header': true,
    },
    className
  );

  function onPrintClick() {
    onPrintReviewClick();
  }

  return (
    <>
      <Row className={classes}>
        <Button type="secondary" onClick={onPrintClick}>
          {t('Print')}
        </Button>
      </Row>
      <Divider />
    </>
  );
};

export default CsvImportReviewHeader;
