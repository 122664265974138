import { IListItemProps, ListItem } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import { CloseIcon } from 'assets/icons';
import { IconButton } from 'components';

export interface ClosableListItemProps extends IListItemProps {
  onClose: () => void;
}

export default function ClosableListItem({ children, onClose, ...listItemProps }: ClosableListItemProps) {
  return (
    <ListItem {...listItemProps}>
      <FlexContainer justifyContent="space-between" alignItems="center" className="px-4">
        <FlexContainer.Item flexBasis="90%">{children}</FlexContainer.Item>
        <IconButton onClick={onClose} svg={CloseIcon} />
      </FlexContainer>
    </ListItem>
  );
}

ClosableListItem.defaultProps = {
  ...ListItem.defaultProps,
};
