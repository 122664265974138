import {
  CheckboxFormField,
  Col,
  Divider,
  IFormRender,
  Label,
  Row,
  SelectFormField,
  TextFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import { EmployeeGroupFormField, PositionFormField, ProfileImageFormField, Well } from 'components';
import GustoEmployeeRegistrationRadioGroupFormField from 'components/domain/integration/gusto/form-fields/GustoEmployeeRegistrationRadioGroupFormField/GustoEmployeeRegistrationRadioGroupFormField';
import useGustoIsFullySetup from 'components/domain/integration/gusto/hooks/meta/useGustoIsFullySetup/useGustoIsFullySetup';
import OvertimePayRateFormField from 'components/domain/organization-overtime-period/OvertimePayRateFormField/OvertimePayRateFormField';
import OvertimeRuleFormField from 'components/domain/organization-overtime-period/OvertimeRuleFormField/OvertimeRuleFormField';
import WageRateFormField from 'components/domain/wage-history/WageRateFormField/WageRateFormField';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import CheckItemFormField from 'components/foundation/form-fields/CheckItemFormField/CheckItemFormField';
import GustoPaidContainer from 'containers/payroll/gusto/GustoPaidContainer/GustoPaidContainer';
import useScopedMemberGroup from 'hooks/models/member-group/useScopedMemberGroup';
import { IMember } from 'types';
import OvertimePayPeriodType from 'types/enum/OvertimePayPeriodType';
import WageRate from 'types/enum/WageRate';
import { t } from 'utils/localize';
import {
  v_fields_match,
  v_length,
  v_max_number,
  v_min_length,
  v_money,
  v_numbers_only,
  v_percentages_only,
  v_phone,
  v_require,
  v_unique_username,
  v_valid_email,
} from 'utils/validations';
import { getEstimatedHourlyCostFromValues, getEstimatedHourlyRateFromValues } from 'utils/wageUtils';
import { IEmployeesCreateFormData } from '../EmployeesCreateDialog/EmployeesCreateDialog';

export const NameField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <Row gutter={4}>
    <Col>
      <Label>{t('First Name')}</Label>
      <TextFormField
        name={'firstName'}
        form={form}
        validations={[{ validation: v_require }]}
        autofocus={shouldAutofocus}
      />
    </Col>
    <Col>
      <Label>{t('Last Name')}</Label>
      <TextFormField name={'lastName'} form={form} validations={[{ validation: v_require }]} />
    </Col>
  </Row>
);

export const UsernameField = (
  form: IFormRender<IEmployeesCreateFormData>,
  shouldAutofocus: boolean,
  member?: IMember
) => (
  <>
    <Label>{t('Username')}</Label>
    <TextFormField
      name={'username'}
      form={form}
      validations={[
        { validation: v_require },
        { validation: v_unique_username, args: { previousUsername: member?.username } },
        { validation: v_min_length, args: { min: 2, message: t('Minimum 2 characters') } },
      ]}
      autofocus={shouldAutofocus}
    />
  </>
);

export const EmployeeIDField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <>
    <Label optional={true}>{t('Employee ID')}</Label>
    <TextFormField name={'memberNumber'} form={form} autofocus={shouldAutofocus} />
  </>
);

export const EmailField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <>
    <Label>{t('Email')}</Label>
    <TextFormField
      name={'email'}
      form={form}
      validations={[{ validation: v_valid_email }]}
      autofocus={shouldAutofocus}
    />
  </>
);

export const PhoneField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <>
    <Label>{t('Phone')}</Label>
    <TextFormField name={'phone'} form={form} validations={[{ validation: v_phone }]} autofocus={shouldAutofocus} />
  </>
);

export const PinCodeField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <>
    <Label>{t('PIN Code')}</Label>
    <TextFormField
      name={'pinCode'}
      form={form}
      type="password"
      validations={[{ validation: v_numbers_only }, { validation: v_length, args: { num: 4 } }]}
      autofocus={shouldAutofocus}
    />
  </>
);

export const EmployeeGPSField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <>
    <Label>{t('GPS required')}</Label>
    <SelectFormField
      name="timeLocationRequired"
      form={form}
      validations={[{ validation: v_require }]}
      options={[
        { value: 'YES', label: t('Required') },
        { value: 'AUTO', label: t('Not required') },
        { value: 'NO', label: t('Off') },
      ]}
      autofocus={shouldAutofocus}
    />
  </>
);

export const GroupField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => {
  const { scopedMemberGroupId } = useScopedMemberGroup();

  return (
    <>
      <Label optional={true}>{t('Group')}</Label>
      <EmployeeGroupFormField
        name="memberGroupId"
        form={form}
        disabled={scopedMemberGroupId !== null}
        autofocus={shouldAutofocus}
        filterByPermission={false}
      />
    </>
  );
};

export const PositionField = (form: IFormRender<IEmployeesCreateFormData>, shouldAutofocus: boolean) => (
  <>
    <Label>{t('Position')}</Label>
    <PositionFormField
      name="positionId"
      form={form}
      validations={[{ validation: v_require }]}
      autofocus={shouldAutofocus}
    />
  </>
);

export const SubContractorCheckbox = (form: IFormRender<IEmployeesCreateFormData>) => (
  <>
    <CheckboxFormField
      name="isSubContractor"
      label={
        <>
          <div> {t('Mark as Subcontractor')}</div>
          <div style={{ fontSize: '14px', lineHeight: '14px' }} className={'mb-5'}>
            {t(
              'Employees marked as subcontractors can optionally be excluded from payroll processings and/or have payroll processed separately.'
            )}
          </div>
        </>
      }
      form={form}
    />
  </>
);

export const ProjectLocationFlaggingCheckbox = (form: IFormRender<any>) => (
  <>
    <CheckboxFormField
      name="locationFlagging"
      label={
        <>
          <div> {t('Project Location Flagging')}</div>
          <div style={{ fontSize: '14px', lineHeight: '14px' }} className={'mb-5'}>
            {t('Flag the employee’s location for review if they clock time outside the project geofence.')}
          </div>
        </>
      }
      form={form}
    />
  </>
);

export const PasswordsField = (form: IFormRender<any>, shouldAutofocus: boolean) => (
  <>
    <Label secondaryLabel={t('Minimum 8 characters')}>{t('Password')}</Label>
    <TextFormField
      name="password"
      form={form}
      type="password"
      validations={[
        { validation: v_require },
        { validation: v_min_length, args: { min: 8, message: t('Minimum 8 characters') } },
        { validation: v_fields_match, args: { matchingField: 'confirmPassword', message: t('Passwords must match') } },
      ]}
      autofocus={shouldAutofocus}
    />
    <Label>{t('Confirm Password')}</Label>
    <TextFormField
      name="confirmPassword"
      form={form}
      type="password"
      validations={[
        { validation: v_require },
        { validation: v_fields_match, args: { matchingField: 'password', message: t('Passwords must match') } },
      ]}
      autofocus={false}
    />
  </>
);

export const ProfileImageField = (form: IFormRender<IEmployeesCreateFormData>, initialImage: string | null) => (
  <ProfileImageFormField name="profile" initialImage={initialImage} form={form} />
);

export const GustoRegistrationFields = (form: IFormRender<IEmployeesCreateFormData>) => {
  const shouldShowEmployeeRegistration = useGustoIsFullySetup();

  if (!shouldShowEmployeeRegistration) {
    return null;
  }

  const isSubcontractor = form.state.data.isSubcontractor;

  return (
    <GustoPaidContainer>
      <CheckboxFormField name="registerGusto" form={form} label={<Label>{t('Add Employee to Busy Payroll')}</Label>} />
      <ConditionalContainer condition={!!form.state.data['registerGusto']}>
        {isSubcontractor ? (
          <Well theme="primary" className="mb-5">
            {t(
              'On submission, you will be taken to a subcontractor payroll form. The form is required in order to process payroll for the subcontractor.'
            )}
          </Well>
        ) : (
          <DashboardRibbon theme={Theme.LIGHT} className="mb-5">
            <GustoEmployeeRegistrationRadioGroupFormField name="registerGustoValue" form={form} />
          </DashboardRibbon>
        )}
      </ConditionalContainer>
    </GustoPaidContainer>
  );
};

export const WageField = (
  form: IFormRender<IEmployeesCreateFormData>,
  handleWageRateChange: (value: WageRate) => void,
  currencyFormatter: (value: string | number) => string | null,
  shouldAutofocus: boolean
) => (
  <div>
    <Label>{t('Rate')}</Label>
    <Row gutter={4}>
      <Col size={4}>
        <TextFormField
          name={'wage'}
          form={form}
          placeholder="0.00"
          iconLeft={<div className="icon">$</div>}
          validations={[{ validation: v_money }]}
          autofocus={shouldAutofocus}
        />
      </Col>
      <Col size={5}>
        <WageRateFormField
          name="wageRate"
          form={form}
          onChange={handleWageRateChange}
          validations={[{ validation: v_require }]}
        />
      </Col>
    </Row>
    <Label>{t('Labor Burden %')}</Label>
    <Row gutter={4}>
      <Col size={2}>
        <TextFormField
          name={'overburden'}
          form={form}
          placeholder="0"
          validations={[
            {
              validation: v_percentages_only,
              args: {
                decimalPlaces: 1,
                maxPercent: 1000,
                message: t('Not a valid %'),
              },
            },
          ]}
        />
      </Col>
    </Row>
    {form.state.data.wageRate !== WageRate.HOURLY ? (
      <>
        <Label>{t('Expected Hours Per Week')}</Label>
        <Row gutter={4}>
          <Col size={2}>
            <TextFormField
              name={'workWeek'}
              form={form}
              placeholder="0"
              validations={[
                {
                  validation: v_numbers_only,
                  args: { message: t('Not a valid number.') },
                },
              ]}
            />
          </Col>
        </Row>
      </>
    ) : (
      <></>
    )}
    <div className="pt-6 pb-4">{t('Estimated Hourly Cost')}</div>
    <Row gutter={4} className="pb-6">
      <Col>
        <Label>
          {currencyFormatter(
            getEstimatedHourlyCostFromValues(
              getEstimatedHourlyRateFromValues(
                form.state.data.workWeek ?? 0,
                form.state.data.wageRate,
                Number(form.state.data.wage)
              ),
              form.state.data.overburden
            )
          )}
        </Label>
      </Col>
    </Row>
  </div>
);

export const OvertimeRulesField = (
  form: IFormRender<IEmployeesCreateFormData>,
  handleRuleChange: (value: OvertimePayPeriodType) => void
) => (
  <div className="pb-8">
    <Label>{t('Overtime Rules')}</Label>
    <Row gutter={4}>
      <Col>
        <OvertimeRuleFormField
          name="overtimeRule"
          form={form}
          onChange={handleRuleChange}
          validations={[{ validation: v_require }]}
        />
      </Col>
    </Row>
    {form.state.data && form.state.data.overtimeRule === OvertimePayPeriodType.CUSTOM ? (
      <div>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField name="weeklyOvertimeCheck" form={form} value={form.state.data.weeklyOvertimeCheck}>
                <Label className="pt-2">{t('Weekly Overtime (wage x 1.5)')}</Label>
                <div className="checkbox-children-right">
                  <div className="checkbox-children-right-subtitle ml-1">{t('AFTER')}</div>
                  <TextFormField
                    className="checkbox-children-text-field ml-3"
                    name="weeklyOvertime"
                    form={form}
                    disabled={!form.state.data.weeklyOvertimeCheck}
                    validations={
                      form.state.data.weeklyOvertimeCheck
                        ? [
                            { validation: v_numbers_only, args: { message: t('Not a valid #') } },
                            {
                              validation: v_max_number,
                              args: { num: 168, message: t('Not < 168') },
                            },
                          ]
                        : []
                    }
                  />
                  <div className="checkbox-children-right-subtitle">{t('HRS')}</div>
                </div>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField name="dailyOvertimeCheck" form={form} value={form.state.data.dailyOvertimeCheck}>
                <Label className="pt-2">{t('Daily Overtime (wage x 1.5)')}</Label>
                <div className="checkbox-children-right">
                  <div className="checkbox-children-right-subtitle ml-1">{t('AFTER')}</div>
                  <TextFormField
                    className="checkbox-children-text-field ml-3"
                    name="dailyOvertime"
                    form={form}
                    disabled={!form.state.data.dailyOvertimeCheck}
                    validations={
                      form.state.data.dailyOvertimeCheck
                        ? [
                            { validation: v_numbers_only, args: { message: t('Not a valid #') } },
                            {
                              validation: v_max_number,
                              args:
                                form.state.data.dailyDoubleOvertimeCheck && form.state.data.dailyDoubleOvertime
                                  ? {
                                      num: form.state.data.dailyDoubleOvertime
                                        ? form.state.data.dailyDoubleOvertime
                                        : 25,
                                      message: t('Not < Daily x2'),
                                    }
                                  : {
                                      num: 25,
                                      message: t('Not < 25'),
                                    },
                            },
                          ]
                        : []
                    }
                  />
                  <div className="checkbox-children-right-subtitle">{t('HRS')}</div>
                </div>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField
                name="dailyDoubleOvertimeCheck"
                form={form}
                value={form.state.data.dailyDoubleOvertimeCheck}
              >
                <Label className="pt-2">{t('Daily Overtime (wage x 2.0)')}</Label>
                <div className="checkbox-children-right">
                  <div className="checkbox-children-right-subtitle ml-1">{t('AFTER')}</div>
                  <TextFormField
                    className="checkbox-children-text-field ml-3"
                    name="dailyDoubleOvertime"
                    form={form}
                    disabled={!form.state.data.dailyDoubleOvertimeCheck}
                    validations={
                      form.state.data.dailyDoubleOvertimeCheck
                        ? [
                            { validation: v_numbers_only, args: { message: t('Not a valid #') } },
                            {
                              validation: v_max_number,
                              args: { num: 25, message: t('Not < 25') },
                            },
                          ]
                        : []
                    }
                  />
                  <div className="checkbox-children-right-subtitle">{t('HRS')}</div>
                </div>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField
                name="seventhDayOvertimeCheck"
                form={form}
                value={form.state.data.seventhDayOvertimeCheck}
              >
                <Label className="pt-2">{t('7th Day Overtime (wage x 1.5)')}</Label>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField
                name="seventhDayDoubleOvertimeCheck"
                form={form}
                value={form.state.data.seventhDayDoubleOvertimeCheck}
              >
                <Label className="pt-2">{t('7th Day Overtime (wage x 2.0)')}</Label>
                <div className="checkbox-children-right">
                  <div className="checkbox-children-right-subtitle ml-1">{t('AFTER')}</div>
                  <TextFormField
                    className="checkbox-children-text-field ml-3"
                    name="seventhDayDoubleOvertime"
                    form={form}
                    disabled={!form.state.data.seventhDayDoubleOvertimeCheck}
                    validations={
                      form.state.data.seventhDayDoubleOvertimeCheck
                        ? [
                            { validation: v_numbers_only, args: { message: t('Not a valid #') } },
                            {
                              validation: v_max_number,
                              args: form.state.data.dailyDoubleOvertime
                                ? {
                                    num: form.state.data.dailyDoubleOvertime ? form.state.data.dailyDoubleOvertime : 25,
                                    message: t('Not < Daily x2'),
                                  }
                                : {
                                    num: 25,
                                    message: t('Not < 25'),
                                  },
                            },
                          ]
                        : []
                    }
                  />
                  <div className="checkbox-children-right-subtitle">{t('HRS')}</div>
                </div>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField name="saturdayPayRateCheck" form={form} value={form.state.data.saturdayPayRateCheck}>
                <Label className="pt-2">{t('Saturday Pay Rate')}</Label>
                <div className="checkbox-children-right">
                  <div className="checkbox-children-right-subtitle">{t('WAGE x')}</div>
                  <OvertimePayRateFormField
                    className="checkbox-children-text-field checkbox-children-text-field-pay-rate"
                    name="saturdayPayRate"
                    form={form}
                    disabled={!form.state.data.saturdayPayRateCheck}
                  />
                </div>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
        <Row gutter={4}>
          <Col>
            <div>
              <CheckItemFormField name="sundayPayRateCheck" form={form} value={form.state.data.sundayPayRateCheck}>
                <Label className="pt-2">{t('Sunday Pay Rate')}</Label>
                <div className="checkbox-children-right">
                  <div className="checkbox-children-right-subtitle">{t('WAGE x')}</div>
                  <OvertimePayRateFormField
                    className="checkbox-children-text-field checkbox-children-text-field-pay-rate"
                    name="sundayPayRate"
                    form={form}
                    disabled={!form.state.data.sundayPayRateCheck}
                  />
                </div>
              </CheckItemFormField>
            </div>
          </Col>
        </Row>
      </div>
    ) : (
      <></>
    )}
    <Divider />
  </div>
);
