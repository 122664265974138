import { alignlogo, busybusylogo } from 'assets/images';
import useIsInIframe from 'hooks/store/useIsInIframe';

/**
 * @name useBrandLogoImageAsset
 *
 * @description
 *  This returns raw image data, not an html element.
 *  For a ready to use component, see components/domain/meta/BrandLogo
 *
 */
export default function useBrandLogoImageAsset() {
  const isInIframe = useIsInIframe();

  if (isInIframe) {
    return alignlogo;
  } else {
    return busybusylogo;
  }
}
