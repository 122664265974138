import { IDialogProps } from '@busybusy/webapp-react-ui';
import TimeEntryLogsView from 'components/domain/time-entry-logs/TimeEntryLogs/TimeEntryLogsView';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import TimeEntryView from 'components/domain/time-entry/TimeEntryView/TimeEntryView';
import TimeEntryLogEvent from 'containers/time-log-feed/table/TimeEntryLogEvent/TimeEntryLogEvent';
import { ITimeLogFeedTableRow } from 'containers/time-log-feed/table/TimeLogFeedTable/TimeLogFeedTable';
import { useOpenable } from 'hooks';
import { executeFunctionsCurried } from 'utils/functionUtils';

export interface ITimeEntryLogEventDialogCoordinatorProps extends Omit<IDialogProps, 'children'> {
  onTimeEntryChange: () => void;
  row: ITimeLogFeedTableRow;
}

function TimeEntryLogEventDialogCoordinator({
  onTimeEntryChange,
  row,
  onClose,
  ...dialogProps
}: ITimeEntryLogEventDialogCoordinatorProps) {
  const timeEntryViewOpenable = useOpenable();
  const viewEntryLogsDetails = useOpenable();
  const editEntryDetails = useOpenable();

  function onActiveEntryChange() {
    onTimeEntryChange();
    editEntryDetails.close();
  }

  return (
    <>
      <TimeEntryLogEvent
        logId={row.timeEntryLogId}
        actedOnMemberName={row.employeeName}
        actionType={row.actionType}
        actionTypeTitle={row.action}
        location={row.location}
        details={row.details}
        device={row.device}
        performedBy={row.performerName}
        performedOn={row.performed}
        onClose={onClose}
        onEditEntry={
          !row.isTimeEntryDeleted && row.isUserManageable
            ? executeFunctionsCurried(editEntryDetails.open, onClose)
            : undefined
        }
        onViewEntry={!row.isUserManageable ? executeFunctionsCurried(timeEntryViewOpenable.open, onClose) : undefined}
        onViewEntryLogs={executeFunctionsCurried(viewEntryLogsDetails.open, onClose)}
        isUserManageable={row.isUserManageable}
        {...dialogProps}
      />
      <TimeActionsFormDialog
        isOpen={editEntryDetails.isOpen}
        onClose={editEntryDetails.close}
        onDelete={onActiveEntryChange}
        onSubmit={onActiveEntryChange}
        type="edit"
        timeEntryId={row.timeEntryId}
      />

      <TimeEntryLogsView
        timeEntryId={row.timeEntryId}
        isOpen={viewEntryLogsDetails.isOpen}
        onClose={viewEntryLogsDetails.close}
        showNameAsSubtitle={true}
      />
      <TimeEntryView
        timeEntryId={row.timeEntryId}
        isOpen={timeEntryViewOpenable.isOpen}
        onClose={timeEntryViewOpenable.close}
        onEdit={undefined}
      />
    </>
  );
}

export default TimeEntryLogEventDialogCoordinator;
