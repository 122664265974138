import { useLegacyNavigationPayload, useReduxSelector } from 'hooks';
import { trimEnd } from 'lodash';

export default function useLegacyNavigation() {
  const { token, baseUrl } = useLegacyNavigationPayload();
  const emberEquipmentUrl = useReduxSelector((state) => trimEnd(state.environment.REACT_APP_EQUIPMENT_URL, '/'));

  function subscription() {
    if (baseUrl.charAt(baseUrl.length - 1) === '/') {
      const withoutSlash = baseUrl.substring(0, baseUrl.length - 1);
      window.location.assign(`${withoutSlash}/company-settings?bc_tab=subscription&authorize=${token}`);
    } else {
      window.location.assign(`${baseUrl}/company-settings?bc_tab=subscription&authorize=${token}`);
    }
  }

  function equipmentApp() {
    window.location.assign(`${emberEquipmentUrl}?authorize=${token}`);
  }

  function projectDetailsReportsTab(projectId: string) {
    if (baseUrl.charAt(baseUrl.length - 1) === '/') {
      const withoutSlash = baseUrl.substring(0, baseUrl.length - 1);
      window.location.assign(`${withoutSlash}/project/${projectId}?bc_tab=reports&authorize=${token}`);
    } else {
      window.location.assign(`${baseUrl}/project/${projectId}?bc_tab=reports&authorize=${token}`);
    }
  }

  return { subscription, equipmentApp, projectDetailsReportsTab };
}
