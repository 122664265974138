import { IncidentUpdateInput, UpdateIncidentMutation, UpdateIncidentMutationVariables } from "__generated__/graphql";
import { UPDATE_INCIDENTS } from "apollo/mutations/incident-mutations";
import useGraphQLClient from "hooks/graphql/useGraphQLClient/useGraphQLClient";
import { isNil } from "lodash";
import { useCallback } from "react";

export const useUpdateIncident = () => {
    const client = useGraphQLClient();
    const updateIncident = useCallback(
        async (input: IncidentUpdateInput) => {
            const result = await client.request<UpdateIncidentMutation, UpdateIncidentMutationVariables>({
                document: UPDATE_INCIDENTS,
                variables: {
                    incident: input,
                }
            });

            if (isNil(result.updateIncident)) {
                throw Error(`result was ${result.updateIncident}`);
            }

            return result.updateIncident;
        },
        [client]
    );

    return updateIncident
}