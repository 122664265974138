import {
  BASIC_PROJECT_LABOR_METRICS_FRAGMENT,
  BASIC_PROJECT_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-project-queries';
import { BASIC_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from 'apollo/queries/jit-queries';
import gql from 'graphql-tag';

export const COST_CODES_WITH_METRICS_QUERY = gql`
  query QueryCostCodesWithMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...LaborMetrics
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_TIME_METRICS_QUERY = gql`
  query QueryCostCodesWithTimeMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_EQUIPMENT_METRICS_QUERY = gql`
  query QueryCostCodesWithEquipmentMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...LaborMetrics
        equipmentId
        equipmentCents
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithEquipmentTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
      ) {
        ...BasicLaborMetrics
        equipmentId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_MEMBER_METRICS_QUERY = gql`
  query QueryCostCodesWithMemberMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        memberId
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithMemberTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        memberId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_PROJECT_METRICS_QUERY = gql`
  query QueryCostCodesWithProjectMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithProjectTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY = gql`
  query QueryCostCodesWithProjectAndEquipmentMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        equipmentCents
        descEquipmentCents
        ...ProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_PROJECT_EQUIPMENT_AND_PROJECT_METRICS_QUERY = gql`
  query QueryCostCodesWithProjectEquipmentAndProjectMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $equipmentIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
      }
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        equipmentIds: $equipmentIds
      ) {
        equipmentCents
        ...ProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithProjectAndEquipmentTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        equipmentCents
        ...BasicProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_PROJECT_EQUIPMENT_AND_PROJECT_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithProjectEquipmentAndProjectTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
      }
      costCodeProjectEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        equipmentCents
        ...BasicProjectLaborMetrics
        projectId
        equipmentId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY = gql`
  query QueryCostCodesWithMemberProjectMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        memberIds: $memberIds
      ) {
        ...ProjectLaborMetrics
        projectId
        memberId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithMemberProjectTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        memberIds: $memberIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        memberId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY = gql`
  query QueryCostCodesWithMemberEquipmentMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        memberId
        equipmentId
        equipmentCents
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY = gql`
  query QueryCostCodesWithMemberEquipmentTimeOnlyMetrics(
    $after: String
    $filter: CostCodeFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $equipmentIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      id
      costCode
      title
      unitTitle
      archivedOn
      createdOn
      updatedOn
      cursor
      costCodeMemberEquipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        equipmentIds: $equipmentIds
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        memberId
        equipmentId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;
