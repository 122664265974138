import { useDefaultTimes } from 'hooks/settings';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { Optional } from 'types/util/Optional';

export default function useClockInRestrictionTimeConverter() {
  const { defaultStartTime } = useDefaultTimes();

  function toClockInRestriction(dateTime: Optional<DateTime>) {
    if (isNil(dateTime)) {
      return defaultStartTime.toFormat('HH:mm:ss');
    }

    return dateTime.toFormat('HH:mm:ss');
  }

  function fromClockInRestriction(clockInRestriction: Optional<string>) {
    if (isNil(clockInRestriction)) {
      return defaultStartTime;
    }

    const dateTime = DateTime.fromFormat(clockInRestriction, 'HH:mm:ss');
    if (!dateTime.isValid) {
      throw Error(
        `Invalid clock in restriction value ${clockInRestriction}. Expected format is HH:mm:ss (e.g. 07:00:00).`
      );
    }

    return dateTime;
  }

  return {
    toClockInRestriction,
    fromClockInRestriction,
  };
}
