import { ApolloQueryResult } from '@apollo/client';
import { useAsync } from 'hooks/utils';
import { useCallback, useRef } from 'react';
import ICursorable from 'types/Cursorable';
import { pageGraphQl } from 'utils/graphQlUtils';

export default function useApolloAutoPager() {
  return useCallback(
    // TODO: fix usage of {} as a type
    // eslint-disable-next-line @typescript-eslint/ban-types
    async <T extends ICursorable, D extends { [key: string]: T[] } = {}>(
      apolloFn: (after: string | null, first: number) => Promise<ApolloQueryResult<D>>,
      dataAccessorKey: string,
      first: number = 50,
      firstAfter: string | null = null
    ) => {
      return pageGraphQl(apolloFn, (value) => value.data[dataAccessorKey], first, firstAfter);
    },
    []
  );
}

export function useApolloAsyncAutoPager<T extends ICursorable, D extends { [key: string]: T[] }>(
  apolloFn: (after: string | null, first: number) => Promise<ApolloQueryResult<D>>,
  dataAccessorKey: string,
  first: number = 50,
  firstAfter: string | null
) {
  const fn = useRef(apolloFn);
  const pager = useApolloAutoPager();
  const paging = useCallback(
    () => pager(fn.current, dataAccessorKey, first, firstAfter),
    [pager, dataAccessorKey, first, firstAfter]
  );
  return useAsync(paging);
}
