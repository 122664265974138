import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IDashboardSettings } from 'types/DashboardSettings';
import useMemberSettings from '../member/useMemberSettings';
import useMemberUiSettingsUpdate from './useMemberUiSettingsUpdate';

export default function useDashboardSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateUiSettings = useMemberUiSettingsUpdate();

  return useCallback(
    <K extends keyof IDashboardSettings>(key: K, payload: IDashboardSettings[K]) => {
      const cloned = cloneDeep(memberSettings?.web?.ui?.dashboard);

      return updateUiSettings('dashboard', { ...cloned, [key]: payload });
    },
    [memberSettings, updateUiSettings]
  );
}
