import { useApolloClient } from '@apollo/client';
import { Button, Dialog, Icon, Label, Loader, Size, Tray } from '@busybusy/webapp-react-ui';
import {
  FlaggedTimeEntryLocation,
  FlaggedTimeEntryLocationStatus,
  FlaggedTimeEntryLocationUpdateInput,
  MemberGpsStatus,
} from '__generated__/graphql';
import { MEMBER_LOCATIONS_QUERY } from 'apollo/queries/member-location-queries';
import { MEMBER_QUERY } from 'apollo/queries/member-queries';
import {
  LOCATION_HISTORY_TIME_ENTRIES_QUERY,
  LOCATION_HISTORY_TIME_ENTRY_QUERY,
} from 'apollo/queries/time-entry-queries';
import { ArrowBackIcon, EditIcon, InfoIcon, SettingsIcon } from 'assets/icons';
import project_pin from 'assets/icons/project_pin.svg';
import classNames from 'classnames';
import { HeaderDialog, IconButton } from 'components';
import GoogleMap from 'components/domain/map/GoogleMap/GoogleMap';
import TooltipIcon from 'components/foundation/TooltipIcon/TooltipIcon';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import Header from 'components/layout/Header/Header';
import Panel from 'components/layout/Panel/Panel';
import PanelContent from 'components/layout/PanelContent/PanelContent';
import GpsAuthorizeDialog from 'containers/gps-outside-project-report/dialogs/GpsAuthorizeDialog';
import GpsEditAuthorizationDialog from 'containers/gps-outside-project-report/dialogs/GpsEditAuthorizationDialog';
import GpsUnauthorizeDialog from 'containers/gps-outside-project-report/dialogs/GpsUnathorizeDialog';
import useUpdateFlaggedTimeEntryLocation from 'containers/gps-outside-project-report/hooks/useUpdateFlaggedTimeEntryLocation';
import { useOpenable, usePermissions, useTimesheetsGraylog } from 'hooks';
import useMemberFeatureSettingsUpdate from 'hooks/models/member/useMemberFeatureSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import { capitalize, compact, first, isEmpty, isNil, isNull, sortBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ILocation from 'types/Location';
import ILocationHistory from 'types/LocationHistory';
import IMemberLocation from 'types/MemberLocation';
import IProject from 'types/Project';
import ITimeEntry from 'types/TimeEntry';
import MemberLocationType from 'types/enum/MemberLocationType';
import { dateUtils, memberLocationsUtils, stringUtils } from 'utils';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { dateTimeFromISOKeepZone, localizeDateTimeFromUtcISO } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { getDisabledMemberGpsStatuses, getMaxDistance, shiftPins } from 'utils/locationUtils';
import './LocationHistoryDialog.scss';
import LocationHistorySettingsForm from './LocationHistorySettingsForm/LocationHistorySettingsForm';
import { defaultShowGPSConnections } from './LocationHistorySettingsForm/ShowGPSConnections';
import LocationHistoryTable, { ILocationTableData } from './LocationHistoryTable/LocationHistoryTable';

export interface ILocationHistoryDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  member: Pick<IMember, 'id' | 'firstName' | 'lastName'>;
  scope: string | DateTime;
}

const LocationHistoryDialog = (props: ILocationHistoryDialogProps) => {
  const { className, isOpen, onClose, member, scope } = props;

  const classes = classNames('location-history-dialog', className);

  const updateFlaggedTimeEntryLocation = useUpdateFlaggedTimeEntryLocation();

  const client = useApolloClient();
  const nameFormatter = useEmployeeNameFormatter();
  const isPro = useFeatureFlags('PRO');
  const [timeEntry, setTimeEntry] = useState<ITimeEntry>();
  const [locationData, setLocationData] = useState<ILocationTableData | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>();
  const [locationFilter, setLocationFilter] = useState<string>(MemberLocationType.ALL);
  const userEvents = useTimesheetsGraylog();
  const [mapType, setMapType] = useState<string | google.maps.MapTypeId>('roadmap');
  const loaderOpenable = useOpenable();
  const settingsDetails = useOpenable();
  const authorizeDialog = useOpenable();
  const unauthorizeDialog = useOpenable();
  const editAuthDialog = useOpenable();
  const locationsOutsideProjectDialog = useOpenable();
  const gpsDisabledDialog = useOpenable();
  const memberSettings = useMemberSettings();
  const [showConnections, setShowConnections] = useState(
    memberSettings?.web?.features?.showGpsConnections ?? defaultShowGPSConnections.showConnections
  );
  const [currentLocationData, setCurrentLocationData] = useState<FlaggedTimeEntryLocation>();
  const [memberWithPosition, setMemberWithPosition] = useState<IMember>();
  const updateMemberSettings = useMemberFeatureSettingsUpdate();

  useEffect(() => {
    if (isOpen) {
      userEvents.events(TimesheetsTypes.events.action_type.VIEW_GPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    getMemberWithPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const getMemberWithPosition = async () => {
    const results = await client.query<{ members: IMember[] }>({
      query: MEMBER_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        memberId: member.id,
      },
    });
    setMemberWithPosition(first(results.data.members));
  };

  const { hasPermissionToManage } = usePermissions();
  const canManageGpsForMember = memberWithPosition
    ? hasPermissionToManage(memberWithPosition, 'manageGpsApproval')
    : false;

  const setConnections = (value: boolean) => {
    setShowConnections(value);
    updateMemberSettings('showGpsConnections', value);
  };

  const hasLocationsOutsideProject = useMemo(() => {
    return locationData && locationData.locations.some((item) => !isNil(item.maxDistance) && item.maxDistance !== '---')
      ? locationData.locations.some((item) => isNil(item.status) && !isNil(item.flaggedLocationId))
      : false;
  }, [locationData]);

  const hasPreviousLocationsOutsideProject = useMemo(() => {
    return (
      (locationData &&
        locationData.locations.some((item) => isNil(item.maxDistance) || item.maxDistance === '---') &&
        !hasLocationsOutsideProject) ??
      false
    );
  }, [locationData]);

  async function getData() {
    loaderOpenable.open();
    if (typeof scope === 'string') {
      const entryResults = await client.query<{ timeEntries: ITimeEntry[] }>({
        fetchPolicy: 'network-only',
        query: LOCATION_HISTORY_TIME_ENTRY_QUERY,
        variables: { timeEntryId: scope },
      });
      setTimeEntry(entryResults.data.timeEntries[0]);
      await getLocations([entryResults.data.timeEntries[0]]);
    } else {
      await getLocations(await getTimeEntries());
    }
    loaderOpenable.close();
  }

  async function getLocations(timeEntries: ITimeEntry[]) {
    const newLocations: ILocationHistory[] = [];

    const gpsStatuses: Pick<MemberGpsStatus, 'status' | 'localTime'>[] = [];

    for await (const element of timeEntries) {
      gpsStatuses.push(...getDisabledMemberGpsStatuses(element.memberGpsStatuses, element));

      const startTimeWithLocationAllowance = dateTimeFromISOKeepZone(element.startTime)
        .minus({ minute: 3 })
        .toISO({ suppressMilliseconds: true });
      const endTimeWithLocationAllowance = isNil(element.endTime)
        ? undefined
        : dateTimeFromISOKeepZone(element.endTime).plus({ minute: 3 }).toISO({ suppressMilliseconds: true });

      const entryLocationIds: string[] = [];
      if (element.startLocation?.id) {
        entryLocationIds.push(element.startLocation.id);
      }

      if (element.endLocation?.id) {
        entryLocationIds.push(element.endLocation.id);
      }

      const breakStartIds = element.breaks
        .filter((brk) => isNull(brk.deletedOn) && !isNil(brk.startLocation))
        .map((brk) => brk.startLocation!.id);
      const breakEndIds = element.breaks
        .filter((brk) => isNull(brk.deletedOn) && !isNil(brk.endLocation))
        .map((brk) => brk.endLocation!.id);

      const entryLocations = await client.query<{ memberLocations: IMemberLocation[] }>({
        query: MEMBER_LOCATIONS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          first: 500,
          filter: {
            id: {
              contains: [...entryLocationIds, ...breakStartIds, ...breakEndIds],
            },
          },
        },
      });

      const locationResults = await client.query<{ memberLocations: IMemberLocation[] }>({
        query: MEMBER_LOCATIONS_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          first: 500,
          filter: {
            memberId: {
              equal: element.memberId,
            },
            timeLocal: {
              greaterThanOrEqual: startTimeWithLocationAllowance,
              lessThanOrEqual: endTimeWithLocationAllowance,
            },
          },
        },
      });
      const uniqLocations = uniqBy(
        [...locationResults.data.memberLocations, ...entryLocations.data.memberLocations],
        'id'
      );

      uniqLocations.forEach((e) => {
        const locationType = memberLocationsUtils.getType(element, e);
        if (!isPro) {
          if (locationType !== MemberLocationType.LOCATION_UPDATE) {
            const item: ILocationHistory = {
              location: e,
              type: locationType,
              project: element.project,
              timeEntryId: element.id,
              status: element.flaggedTimeEntryLocation?.status,
              flaggedLocationId: element.flaggedTimeEntryLocation?.id,
              maxDistance: !isNil(element.flaggedTimeEntryLocation)
                ? getMaxDistance(element.flaggedTimeEntryLocation, element.project?.projectInfo ?? undefined)
                : null,
            };
            newLocations.push(item);
          }
        } else {
          const item: ILocationHistory = {
            location: e,
            type: locationType,
            project: element.project,
            timeEntryId: element.id,
            status: element.flaggedTimeEntryLocation?.status,
            flaggedLocationId: element.flaggedTimeEntryLocation?.id,
            maxDistance: !isNil(element.flaggedTimeEntryLocation)
              ? getMaxDistance(element.flaggedTimeEntryLocation, element.project?.projectInfo ?? undefined)
              : null,
          };
          newLocations.push(item);
        }
      });
    }

    setLocationData({ locations: sortBy(newLocations, (lh) => lh.location.timeGmt), memberGPSStatuses: gpsStatuses });
  }

  async function getTimeEntries() {
    const entryResults = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: LOCATION_HISTORY_TIME_ENTRIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          member: { id: { equal: member.id } },
          deletedOn: { isNull: true },
          startTime:
            typeof scope !== 'string'
              ? {
                  lessThanOrEqual: scope.endOf('day').toUTC().toISO({
                    suppressMilliseconds: true,
                    includeOffset: false,
                  }),
                }
              : DateTime.utc().endOf('day').toISO({ suppressMilliseconds: true, includeOffset: false }),
          endTime:
            typeof scope !== 'string'
              ? {
                  greaterThanOrEqual: scope.startOf('day').toUTC().toISO({
                    suppressMilliseconds: true,
                    includeOffset: false,
                  }),
                }
              : DateTime.utc().startOf('day').toISO({ suppressMilliseconds: true, includeOffset: false }),
        },
        sort: [{ startTime: 'asc' }, { createdOn: 'asc' }],
      },
    });
    return entryResults.data.timeEntries.concat(await getOpenTimeEntry());
  }

  async function getOpenTimeEntry() {
    const entryResults = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: LOCATION_HISTORY_TIME_ENTRIES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          member: { id: { equal: member.id } },
          deletedOn: { isNull: true },
          startTime:
            typeof scope !== 'string'
              ? {
                  lessThanOrEqual: scope.endOf('day').toUTC().toISO({
                    suppressMilliseconds: true,
                    includeOffset: false,
                  }),
                }
              : DateTime.utc().endOf('day').toISO({ suppressMilliseconds: true, includeOffset: false }),
          endTime: { isNull: true },
        },
        sort: [{ startTime: 'asc' }, { createdOn: 'asc' }],
      },
    });
    return entryResults.data.timeEntries;
  }

  useEffect(() => {
    if (typeof scope === 'string' && isOpen) {
      getData();
    } else if (typeof scope !== 'string' && isOpen) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, scope]);

  const handleLocationBannerClick = (data: FlaggedTimeEntryLocation) => {
    if (canManageGpsForMember) {
      setCurrentLocationData(data);
      locationsOutsideProjectDialog.open();
    }
  };

  const handleAuthorizeSubmit = (comment: string) => {
    authorizeDialog.close();
    if (locationData && locationData.locations.length > 0) {
      locationData.locations.map((loc) => {
        if (!isNil(loc.flaggedLocationId)) {
          updateFlaggedTimeEntryLocation({
            id: loc.flaggedLocationId,
            status: FlaggedTimeEntryLocationStatus.Authorized,
            comment,
          });
        }
      });
    }
    getData();
  };

  const handleUnauthorizeSubmit = (comment: string) => {
    unauthorizeDialog.close();
    if (locationData && locationData.locations.length > 0) {
      locationData.locations.map((loc) => {
        if (!isNil(loc.flaggedLocationId)) {
          updateFlaggedTimeEntryLocation({
            id: loc.flaggedLocationId,
            status: FlaggedTimeEntryLocationStatus.Unauthorized,
            comment,
          });
        }
      });
    }
    getData();
  };

  const handleEditSubmit = async (saveData: FlaggedTimeEntryLocationUpdateInput) => {
    await updateFlaggedTimeEntryLocation(saveData);
    editAuthDialog.close();
    locationsOutsideProjectDialog.close();
    if (locationData && locationData.locations.length > 0) {
      locationData.locations.map((loc) => {
        if (!isNil(loc.flaggedLocationId)) {
          updateFlaggedTimeEntryLocation({
            id: loc.flaggedLocationId,
            status: saveData.status,
            comment: saveData.comment,
          });
        }
      });
    }
    getData();
  };

  function renderHeader() {
    return (
      <Header size={Size.GROW}>
        <Tray>
          <Button type="icon" onClick={onClose} className="back-arrow">
            <Icon svg={ArrowBackIcon} />
          </Button>
          <div className="pt-4 pb-4">
            <Label className="header-title pt-1">{stringUtils.getFullNameFromMember(member)}</Label>
            {timeEntry ? renderTimeEntryDateTime(timeEntry) : <></>}
            {typeof scope !== 'string' ? <p>{dateUtils.getDateString(scope, 'ccc, LLL d', true)}</p> : <></>}
          </div>
        </Tray>
        <Tray>
          <IconButton
            onClick={settingsDetails.open}
            svg={SettingsIcon}
            tooltipLabel={t('Settings')}
            buttonSize={Size.MEDIUM}
          />
        </Tray>
      </Header>
    );
  }

  function renderTimeEntryDateTime(entry: ITimeEntry) {
    const startTime = dateUtils.dateTimeFromISOKeepZone(entry.startTime);
    let endTime: DateTime | null = null;
    if (entry.endTime) {
      endTime = dateUtils.dateTimeFromISOKeepZone(entry.endTime);
    }
    return (
      <div>
        {endTime ? (
          <div>
            {dateUtils.getDateString(startTime, 't', false)} - {dateUtils.getDateString(endTime, 't', false)}
          </div>
        ) : (
          <div>{dateUtils.getDateString(startTime, 't', false)} - ?</div>
        )}
        {dateUtils.isSameDay(startTime, endTime ? endTime! : DateTime.local()) ? (
          <div>{dateUtils.getDateString(startTime, 'ccc, LLL d', true)}</div>
        ) : (
          <div>
            {dateUtils.getDateString(startTime, 'ccc, LLL d', true)} -{' '}
            {dateUtils.getDateString(endTime ? endTime! : DateTime.local(), 'ccc, LLL d', true)}
          </div>
        )}
      </div>
    );
  }

  function renderTable() {
    return (
      <>
        {locationData && (
          <LocationHistoryTable
            data={locationData}
            onRowClick={handleTableRowClick}
            selectedId={selectedLocation}
            onFilterChange={handleFilterChange}
            canManageGpsForMember={canManageGpsForMember}
            onLocationBannerClick={handleLocationBannerClick}
            onGPSDisableInfoClick={gpsDisabledDialog.open}
          />
        )}
      </>
    );
  }

  function handleFilterChange(filter: string) {
    setLocationFilter(filter);
    userEvents.locationHistoryFilterTypeSet(filter);
  }

  function handleTableRowClick(selectedId?: string) {
    setSelectedLocation(selectedId);
  }

  const mapOptions: google.maps.MapOptions | undefined = useMemo(() => {
    let styles: google.maps.MapTypeStyle[] | undefined = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ];
    switch (mapType) {
      case 'hybrid':
        styles = undefined;
        break;
    }
    return {
      center: { lat: 39, lng: -95 },
      gestureHandling: 'greedy',
      zoom: 16,
      maxZoom: 20,
      styles,
    };
  }, [mapType]);

  const pins = useMemo(() => {
    if (locationData) {
      let newPins = getLocationPins();
      const projectPins = getProjectPins();
      if (!isEmpty(projectPins)) {
        newPins = newPins.concat(projectPins);
      }
      return shiftPins(newPins);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);

  function renderMap() {
    if (locationData) {
      return (
        <GoogleMap
          mapId={'map-' + member.id}
          pinLocations={pins}
          options={mapOptions}
          selectedId={selectedLocation}
          onWindowOpen={setSelectedLocation}
          onWindowClose={handlePinClose}
          onTypeChange={setMapType}
          showRoute={showConnections}
        />
      );
    }
    return <></>;
  }

  const renderLocationOutsideProjectBar = () => (
    <div className="location-outside-project-bar p-5 ">
      <div className="message-content mt-2">
        <span>
          {t('One or more locations appear outside the project geofence. How would you like to tag these locations?')}
        </span>
        <TooltipIcon
          className={'tooltip-icon ml-2'}
          svg={InfoIcon}
          tooltipLabel={t(
            'Authorizing the entry means that the employee was approved to be away from the project while clocked in. Flagging the entry as Unauthorized means the employee should not have been outside the jobsite or should not have been clocked in while away.'
          )}
        />
      </div>
      <div className="button-container">
        <Button className={'button mr-2'} onClick={authorizeDialog.open}>
          {t('Authorized')}
        </Button>
        <Button className={'button'} onClick={unauthorizeDialog.open}>
          {t('Unauthorized')}
        </Button>
      </div>
    </div>
  );

  const renderPreviouslyOutsideProjectBar = () => (
    <div className="location-outside-project-bar p-5 ">
      <div className="message-content mt-2">
        <span>{t('Originally flagged GPS locations are no longer outside of the project.')}</span>
      </div>
    </div>
  );

  const handlePinClose = useCallback(() => {
    setSelectedLocation(undefined);
  }, []);

  function getLocationPins(): ILocation[] {
    if (locationData) {
      const filteredLocations =
        locationFilter !== MemberLocationType.ALL
          ? locationData.locations.filter((item) => item.type === locationFilter)
          : locationData.locations;
      const pins = filteredLocations.map((element) => {
        const item: ILocation = {
          id: element.location.id,
          lat: element.location.latitude,
          lng: element.location.longitude,
          icon: memberLocationsUtils.getTypeIcon(element.type),
          infoWindowTitle: memberLocationsUtils.getTypeTitle(element.type),
          infoWindowBody: getInfoWindowBody(element.location, element.project),
          distance: memberLocationsUtils.getDistanceWhenOutsideProject(element.location, element.project?.projectInfo),
          groupId: element.timeEntryId,
        };
        return item;
      });
      return pins;
    }
    return [];
  }

  function getProjectPins(): ILocation[] {
    if (locationData) {
      const filteredLocations = locationData.locations.filter((location) => !isNil(location.project));
      const uniqueProjectLocations = uniqBy(filteredLocations, (location) => location.project?.id);
      const pins = uniqueProjectLocations.map((element) => {
        if (
          element.project &&
          element.project.projectInfo &&
          element.project.projectInfo.latitude &&
          element.project.projectInfo.longitude &&
          element.project.projectInfo.locationRadius
        ) {
          const projectPin: ILocation = {
            lat: element.project.projectInfo.latitude,
            lng: element.project.projectInfo.longitude,
            icon: project_pin,
            radius: element.project.projectInfo.locationRadius,
          };
          return projectPin;
        }
      });
      return compact(pins);
    }
    return [];
  }

  function getInfoWindowBody(location: IMemberLocation, project?: IProject | null) {
    const body: ReactNode[] = [
      <p key={location.id}>
        <TimeAndSuperscript
          className="pl-5"
          format={'t'}
          dateTime={dateTimeFromISOKeepZone(location.timeLocal)}
          dst={location.timeDst}
        />
      </p>,
    ];
    if (project) {
      body.push(
        <p className="pl-5" key={project.id}>
          {project.title}
        </p>
      );
    }
    return body;
  }

  function renderContent() {
    return (
      <PanelContent>
        {renderTable()}
        <Panel>
          {hasLocationsOutsideProject && canManageGpsForMember && renderLocationOutsideProjectBar()}
          {hasPreviousLocationsOutsideProject && canManageGpsForMember && renderPreviouslyOutsideProjectBar()}
          {renderMap()}
        </Panel>
      </PanelContent>
    );
  }

  function getGPSStatusTimesStrings(): string {
    if (locationData) {
      return sortBy(locationData.memberGPSStatuses, (status) => status.localTime)
        .map((gpsStatus) => {
          return (
            (locationData.memberGPSStatuses.length > 1 ? '\n' : ' ') +
            dateTimeFromISOKeepZone(gpsStatus.localTime).toFormat(DateTimeFormat.timeSimple)
          );
        })
        .join(' ');
    }
    return '---';
  }

  return (
    <Dialog hasDismiss={false} className={classes} size="full" isOpen={isOpen} onClose={onClose}>
      <Panel>
        {renderHeader()}
        {renderContent()}
        <Loader isOpen={loaderOpenable.isOpen} />
        <HeaderDialog title={t('Map Settings')} isOpen={settingsDetails.isOpen} onClose={settingsDetails.close}>
          <LocationHistorySettingsForm
            onSubmit={(value) => {
              settingsDetails.close();
              setConnections(value);
            }}
          />
        </HeaderDialog>
      </Panel>
      <GpsAuthorizeDialog
        isOpen={authorizeDialog.isOpen}
        onClose={authorizeDialog.close}
        onSubmit={handleAuthorizeSubmit}
      />
      <GpsUnauthorizeDialog
        isOpen={unauthorizeDialog.isOpen}
        onClose={unauthorizeDialog.close}
        onSubmit={handleUnauthorizeSubmit}
      />
      {currentLocationData && (
        <>
          <HeaderDialog
            className={'location-outside-project-dialog'}
            divider={false}
            title={t('Locations Outside Project')}
            isOpen={locationsOutsideProjectDialog.isOpen}
            onClose={locationsOutsideProjectDialog.close}
            rightContent={canManageGpsForMember && <IconButton svg={EditIcon} onClick={editAuthDialog.open} />}
          >
            <div className="location-dialog-content m-5">
              <div>
                <Label className={'mb-0'}>{t('Status')}</Label>
                <div className="text">{capitalize(currentLocationData.status!)}</div>
              </div>
              <div className="mt-3">
                <Label className={'mb-0'}>{t('Reviewed By')}</Label>
                <div>
                  <div className="text">
                    {nameFormatter(
                      currentLocationData.reviewedByMember!.firstName!,
                      currentLocationData.reviewedByMember!.lastName!
                    )}
                    <br />
                    <span className={'time-range-string'}>
                      {localizeDateTimeFromUtcISO(currentLocationData!.lastStatusUpdatedOn!).toFormat(
                        DateTimeFormat.dateAtTime
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Label className={'mb-0'}>{t('Max Distance From Project')}</Label>
                <div className="text">{getMaxDistance(currentLocationData)}</div>
              </div>
              <div className="my-3">
                <Label className={'mb-0'}>{t('Comments')}</Label>
                <div className="text">{currentLocationData.comment ?? '---'}</div>
              </div>
            </div>
          </HeaderDialog>
          <GpsEditAuthorizationDialog
            onClose={editAuthDialog.close}
            isOpen={editAuthDialog.isOpen}
            currentData={currentLocationData}
            onSubmit={handleEditSubmit}
          />
        </>
      )}
      {locationData && (
        <HeaderDialog
          isOpen={gpsDisabledDialog.isOpen}
          onClose={gpsDisabledDialog.close}
          divider={false}
          title={t(`GPS Disabled`)}
          className={classNames('gps-disabled-dialog')}
        >
          <div className="mx-8 disabled-gps-alert-message">
            {t(
              `We're unable to detect every time the GPS is turned off, however, we detected GPS was turned off by the employee at`
            ) +
              (locationData.memberGPSStatuses.length > 1
                ? ':\n' + getGPSStatusTimesStrings()
                : getGPSStatusTimesStrings() + '.')}
            <div className="my-5">
              <Button type="secondary" onClick={gpsDisabledDialog.close}>
                {t('Close')}
              </Button>
            </div>
          </div>
        </HeaderDialog>
      )}
    </Dialog>
  );
};

export default LocationHistoryDialog;
