import useGetLegacyNavigationUrl from 'hooks/meta/useGetLegacyNavigationUrl';
import { useCallback } from 'react';

export default function useLegacySettingsNavigation() {
  const navigation = useGetLegacyNavigationUrl('company-settings');

  return useCallback(() => {
    window.location.assign(navigation());
  }, [navigation]);
}
