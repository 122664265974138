import gql from 'graphql-tag';
import { IMediaCollection, IMediaEntry } from 'types';

export interface IMediaCollectionQueryResults {
  mediaCollections: IMediaCollection[];
}

export const mediaCollectionQuery = gql`
  query mediaCollections($filter: MediaCollectionFilter, $first: Int, $after: String, $sort: [MediaCollectionSort!]) {
    mediaCollections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      title
      description
      source
      createdOn
      cursor
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        collection {
          id
          createdBy
          tagLinks {
            id
            tag {
              id
              title
            }
          }
          createdByMember {
            id
            firstName
            lastName
          }
          createdOn
          updatedByMember {
            id
            firstName
            lastName
          }
          updatedOn
        }
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        metaData
        entryType
        location {
          id
          latitude
          longitude
          timeGmt
          timeLocal
          timeDst
        }
        captureDate
        createdOn
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
      }
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

export const SIMPLE_MC_QUERY = gql`
  query simpleMediaCollections(
    $filter: MediaCollectionFilter
    $first: Int
    $after: String
    $sort: [MediaCollectionSort!]
  ) {
    mediaCollections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      deletedOn
    }
  }
`;

export const mediaCollectionsForProjectQuery = gql`
  query mediaCollectionsForProject(
    $filter: MediaCollectionFilter
    $first: Int
    $after: String
    $sort: [MediaCollectionSort!]
  ) {
    mediaCollections(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      title
      description
      source
      createdOn
      cursor
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        collection {
          id
          createdBy
          tagLinks {
            id
            tag {
              id
              title
            }
          }
          createdByMember {
            id
            firstName
            lastName
          }
          createdOn
          updatedByMember {
            id
            firstName
            lastName
          }
          updatedOn
        }
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        metaData
        entryType
        location {
          id
          latitude
          longitude
          timeGmt
          timeLocal
          timeDst
        }
        captureDate
        createdOn
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
      }
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

export const createMediaCollection = gql`
  mutation createMediaCollection($mediaCollection: MediaCollectionCreateInput!) {
    createMediaCollection(mediaCollection: $mediaCollection) {
      id
      title
      description
      createdOn
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        location {
          id
          latitude
          longitude
        }
        captureDate
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

export const updateMediaCollection = gql`
  mutation updateMediaCollection($mediaCollection: MediaCollectionUpdateInput!) {
    updateMediaCollection(mediaCollection: $mediaCollection) {
      id
      title
      description
      createdOn
      deletedOn
      projectId
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        entryType
        location {
          id
          latitude
          longitude
        }
        captureDate
        deletedOn
        comment
        lastAnnotatedAt
        lastAnnotatedByMember {
          id
          firstName
          lastName
        }
        metaData
      }
      updatedBy
      updatedByMember {
        id
        firstName
        lastName
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

export const deleteMediaEntry = gql`
  mutation DeleteMediaEntry($id: ID!, $deletedOn: Timestamp) {
    updateMediaEntry(mediaEntry: { id: $id, deletedOn: $deletedOn }) {
      id
      mediaCollectionId
      fileUrl
      location {
        id
        latitude
        longitude
      }
      captureDate
      deletedOn
      comment
    }
  }
`;

export const deleteCollection = gql`
  mutation DeleteCollection($id: ID!, $deletedOn: Timestamp) {
    updateMediaCollection(mediaCollection: { id: $id, deletedOn: $deletedOn }) {
      id
      title
      description
      deletedOn
      createdOn
      project {
        id
        title
        ancestors {
          id
          title
          depth
          archivedOn
        }
        archivedOn
      }
      createdByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
        memberGroupId
      }
      mediaEntries {
        id
        mediaCollectionId
        fileUrl
        annotationFileUrl
        videoThumbFileUrl
        captureDate
        location {
          id
          latitude
          longitude
        }
        createdOn
        deletedOn
        comment
      }
      tagLinks {
        id
        tagId
        tag {
          id
          title
          deletedOn
        }
        deletedOn
      }
    }
  }
`;

export const mediaEntryQuery = gql`
  query mediaEntry($filter: MediaEntryFilter, $first: Int, $after: String) {
    mediaEntries(first: $first, after: $after, filter: $filter, sort: [{ createdOn: desc }]) {
      id
      mediaCollectionId
      fileUrl
      annotationFileUrl
      videoThumbFileUrl
      location {
        id
        latitude
        longitude
        timeGmt
        timeLocal
        timeDst
      }
      captureDate
      createdOn
      deletedOn
      comment
    }
  }
`;

export interface IMediaEntryQueryResults {
  mediaEntries: IMediaEntry[];
}
