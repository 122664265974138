import { groupBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import IProjectTimeMetrics from 'types/aggregate/ProjectLaborMetrics';
import { add } from './numberUtils';
import { isArrayOfType } from './typeguard';

export interface ILaborGraphData {
  day: number;
  totalHours: number;
  regularHours: number;
  overtimeHours: number;
  doubleTimeHours: number;
  totalCost: number;
  regularCost: number;
  overtimeCost: number;
  doubleTimeCost: number;
  laborBurden: number;
}

export function metricDataToLaborGraphData<T extends IJitLaborMetric | IProjectTimeMetrics>(
  metricData: T[],
  ranges: DateTime[]
): ILaborGraphData[] {
  // check if our data is tied to a project
  if (isArrayOfType<IProjectTimeMetrics>(metricData, 'descRt')) {
    return projectMetricDataToLaborGraphData(metricData, ranges);
  }

  // metric data not tied to a project
  return regularMetricDataToLaborGraphData(metricData, ranges);
}

export function regularMetricDataToLaborGraphData<T extends IJitLaborMetric>(
  metricData: T[],
  ranges: DateTime[]
): ILaborGraphData[] {
  const grouped = groupBy(metricData, (aggregate) => aggregate.start);

  return ranges.map((day) => {
    const value = grouped[day.toISODate()];
    const regularHours = sumBy(value, (data: T) => (data.rt ?? 0) + (data.pto ?? 0));
    const overtimeHours = sumBy(value, (data: T) => data.ot ?? 0);
    const doubleTimeHours = sumBy(value, (data: T) => data.dt ?? 0);
    const regularCost = sumBy(value, (data: T) => (data.rtCents ?? 0) + (data.ptoCents ?? 0)) / 100;
    const overtimeCost = sumBy(value, (data: T) => data.otCents ?? 0) / 100;
    const doubleTimeCost = sumBy(value, (data: T) => data.dtCents ?? 0) / 100;
    const laborBurden =
      sumBy(value, (data: T) => {
        return add(data.overburdenRtCents, data.overburdenOtCents, data.overburdenDtCents);
      }) / 100;

    return {
      day: day.toSeconds(),
      totalHours: regularHours + overtimeHours + doubleTimeHours,
      regularHours,
      overtimeHours,
      doubleTimeHours,
      regularCost,
      overtimeCost,
      doubleTimeCost,
      laborBurden,
      totalCost: regularCost + overtimeCost + doubleTimeCost + laborBurden,
    };
  });
}

export function projectMetricDataToLaborGraphData<T extends IProjectTimeMetrics>(
  metricData: T[],
  ranges: DateTime[]
): ILaborGraphData[] {
  const grouped = groupBy(metricData, (aggregate) => aggregate.start);

  return ranges.map((day) => {
    const value = grouped[day.toISODate()];
    const regularHours = sumBy(value, (data: T) => (data.rt ?? 0) + (data.descRt ?? 0));
    const overtimeHours = sumBy(value, (data: T) => (data.ot ?? 0) + (data.descOt ?? 0));
    const doubleTimeHours = sumBy(value, (data: T) => (data.dt ?? 0) + (data.descDt ?? 0));
    const regularCost = sumBy(value, (data: T) => (data.rtCents ?? 0) + (data.descRtCents ?? 0)) / 100;
    const overtimeCost = sumBy(value, (data: T) => (data.otCents ?? 0) + (data.descOtCents ?? 0)) / 100;
    const doubleTimeCost = sumBy(value, (data: T) => (data.dtCents ?? 0) + (data.descDtCents ?? 0)) / 100;
    const laborBurden =
      sumBy(value, (data: T) => {
        return add(
          data.overburdenRtCents,
          data.overburdenOtCents,
          data.overburdenDtCents,
          data.descOverburdenRtCents,
          data.descOverburdenOtCents,
          data.descOverburdenDtCents
        );
      }) / 100;

    return {
      day: day.toSeconds(),
      totalHours: regularHours + overtimeHours + doubleTimeHours,
      regularHours,
      overtimeHours,
      doubleTimeHours,
      regularCost,
      overtimeCost,
      doubleTimeCost,
      laborBurden,
      totalCost: regularCost + overtimeCost + doubleTimeCost + laborBurden,
    };
  });
}
