import { Button, Icon, IconSvg, Size, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import * as React from 'react';
import { t } from 'utils/localize';
import DashboardCard, { IDashboardCardProps } from '../DashboardCard/DashboardCard';
import './DashboardEnableCard.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface IDashboardEnableCardProps extends Omit<IDashboardCardProps, 'showBlurredBackground'> {
  svg: IconSvg;
  title: string;
  buttonLabel: string;
  onHide?: () => void;
  onClick: (event: React.MouseEvent) => void;
  onLearn?: () => void;
}

function DashboardEnableCard({
  svg,
  title,
  buttonLabel,
  onHide,
  onClick,
  onLearn,
  className,
  theme,
  ...cardProps
}: IDashboardEnableCardProps) {
  const isInIframe = useIsInIframe();
  const classes = classNames('dashboard-enable-card', { 'is-in-iframe': isInIframe }, theme, className);

  function renderLearnMore() {
    return (
      <span className="clickable" onClick={onLearn}>
        {t('Learn more')}
      </span>
    );
  }

  function onHideClick() {
    onHide?.();
  }

  function renderHide() {
    return (
      <span className="clickable" onClick={onHideClick}>
        {t('Hide this card')}
      </span>
    );
  }

  return (
    <DashboardCard className={classes} {...cardProps} theme={theme} showBlurredBackground={theme === Theme.DARK}>
      <div className={'icon-container'}>
        <Icon svg={svg} size={Size.GROW} />
      </div>
      <h2 className="dashboard-enable-title-card">{title}</h2>
      <Button type="primary" onClick={onClick} size={Size.LARGE}>
        {buttonLabel}
      </Button>
      {
        <div className="learn-hide-text">
          {onLearn ? (
            <>
              {renderLearnMore()} | {renderHide()}
            </>
          ) : (
            renderHide()
          )}
        </div>
      }
    </DashboardCard>
  );
}

export default DashboardEnableCard;
