import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useCurrencyNumberFormatter, usePayPeriodTimeRangeGet, useWeeklyTimeRangeGet } from 'hooks';
import { DateTime, Duration } from 'luxon';
import { useCallback } from 'react';
import { IDashboardChartPayloadType } from '../types/types';

export default function useTooltipFormatter(valueType: IDashboardChartPayloadType, timeFrame: DashboardTimeFrame) {
  const formatter = useCurrencyNumberFormatter(null);
  const weeklyRangeGet = useWeeklyTimeRangeGet();
  const payPeriodRangeGet = usePayPeriodTimeRangeGet();

  const titleFormatter = useCallback(
    (day: number) => {
      switch (timeFrame) {
        case 'monthly':
          return DateTime.fromSeconds(day, { zone: 'utc' }).toFormat('MMM');
        case 'pastSeven':
        case 'pastFourteen':
        case 'daily':
          return DateTime.fromSeconds(day, { zone: 'utc' }).toFormat('L/d');
        case 'payPeriod': {
          const payPeriodRange = payPeriodRangeGet(day);
          return `${payPeriodRange.startTime.toFormat('L/d')} - ${payPeriodRange.endTime.toFormat('L/d')}`;
        }
        case 'weekly': {
          const weeklyRange = weeklyRangeGet(day);
          return `${weeklyRange.startTime.toFormat('L/d')} - ${weeklyRange.endTime.toFormat('L/d')}`;
        }
      }
    },
    [payPeriodRangeGet, timeFrame, weeklyRangeGet]
  );

  const valueFormatter = useCallback(
    (tickItem: number) => {
      if (valueType === 'currency') {
        if (formatter) {
          return formatter.format(tickItem);
        } else {
          return '0';
        }
      } else if (valueType === 'hour') {
        // Reason for timesing hours into seconds is better rounding with luxon
        return Duration.fromObject({ seconds: tickItem * 3600 }).toFormat('h:mm');
      } else {
        return tickItem.toString();
      }
    },
    [formatter, valueType]
  );

  return { titleFormatter, valueFormatter };
}
