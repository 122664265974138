import gql from 'graphql-tag';

export const projectGroupsQuery = gql`
  query ProjectGroups($first: Int, $after: String, $filter: ProjectGroupFilter) {
    projectGroups(first: $first, after: $after, filter: $filter, sort: [{ groupName: asc }]) {
      id
      groupName
      cursor
    }
  }
`;

export const projectGroupQuery = gql`
  query ProjectGroupById($groupId: Uuid!) {
    projectGroups(filter: { id: { equal: $groupId } }) {
      id
      groupName
      cursor
    }
  }
`;
