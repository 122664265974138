import { Icon, Label } from '@busybusy/webapp-react-ui';
import { PhotoIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { t } from 'utils/localize';
import './ProcessingPhoto.scss';

export interface IProcessingPhotoProps {
  className?: ClassName;
}

const ProcessingPhoto = (props: IProcessingPhotoProps) => {
  const { className } = props;

  const classes = classNames('processing-photo', className);

  return (
    <div className={classes}>
      <Icon svg={PhotoIcon} size={50} className={'photo-icon'} />
      <Label className={'title-label'}>{t('Processing Image')}</Label>
    </div>
  );
};

export default ProcessingPhoto;
