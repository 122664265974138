import useNoItemData from 'components/domain/time-entry/TimeEntryDataTable/hooks/useNoItemData';
import { useTimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import { NO_PROJECT_OBJECT } from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { useMounted } from 'hooks/utils/useMounted';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { logError } from 'utils/testUtils';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';
import { useLazyProjectTimeData } from './useLazyProjectTimeData';

export default function useProjectTimeEntryDataReportData(
  scroller: HTMLElement | Document | null,
  timeRange: ITimeRange<DateTime>,
  includeOpenEntries?: boolean,
  projectIds?: string[],
  idWithDescendants?: boolean,
  memberIds?: string[],
  costCodeIds?: string[],
  equipmentIds?: string[],
  excludeArchived?: boolean,
  rootProjectsOnly?: boolean,
  showNoItemTable: boolean = false
) {
  const [data, setData] = useState<ITimeEntryReportWithHeaderData[]>([]);
  const [noDataLoading, setNoDataLoading] = useState(false);
  const titleFormatter = useCallback((project: IProject) => project.title, []);

  const filter = useMemo(() => {
    return {
      timeRange,
      includeOpen: includeOpenEntries,
      projectIds,
      idWithDescendants,
      memberIds,
      costCodeIds,
      equipmentIds,
      excludeArchived,
      rootProjectsOnly,
    };
  }, [
    timeRange,
    includeOpenEntries,
    projectIds,
    idWithDescendants,
    memberIds,
    costCodeIds,
    equipmentIds,
    excludeArchived,
    rootProjectsOnly,
  ]);

  const {
    data: groupedData,
    loading: dataLoading,
    error,
    clearData,
    updateProjectIds,
    loadedAll,
    loadAll,
  } = useLazyProjectTimeData(scroller, filter);
  const getRows = useTimeEntryDataTableRow();
  const getNoItemData = useNoItemData<IProject>(NO_PROJECT_OBJECT);
  const isMounted = useMounted();

  useEffect(() => {
    async function getData() {
      let noItemData;
      try {
        if (projectIds?.includes(remainingDataItemId)) {
          setNoDataLoading(true);

          const noProjectData = await getNoItemData(
            timeRange,
            includeOpenEntries,
            memberIds,
            [remainingDataItemId],
            costCodeIds,
            equipmentIds
          );
          noItemData = await getRows(timeRange, noProjectData, () => t('No Project'));

          setNoDataLoading(false);

          if (projectIds.length === 1) {
            setData(noItemData);
            return;
          }
        } else {
          if (isNil(projectIds) && showNoItemTable && loadedAll) {
            setNoDataLoading(true);

            const noProjectData = await getNoItemData(
              timeRange,
              includeOpenEntries,
              memberIds,
              [remainingDataItemId],
              costCodeIds,
              equipmentIds
            );

            setNoDataLoading(false);

            if (
              !isEmpty(noProjectData) &&
              (!isEmpty(noProjectData[0].entries) || !isEmpty(noProjectData[0].timeOffs))
            ) {
              noItemData = await getRows(timeRange, noProjectData, () => t('No Project'));
            }
          }
        }
        let newData = await getRows(timeRange, groupedData, titleFormatter);
        if (noItemData) {
          newData = newData.concat(noItemData);
        }
        setData(newData);
      } catch (e) {
        logError(e as Error);
      }
    }
    if (isMounted) {
      getData();
    }
  }, [groupedData, titleFormatter, filter, isMounted]);

  const loading = useMemo(() => dataLoading || noDataLoading, [dataLoading, noDataLoading]);

  return useMemo(
    () => ({ data, loading, error, clearData, updateProjectIds, loadedAll, loadAll }),
    [data, loading, error, clearData, updateProjectIds, loadedAll, loadAll]
  );
}
