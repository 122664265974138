import {
  Button,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  Label,
  Loader,
  TextareaFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import DeleteTimeEntryOrTimeOffDialog from 'components/domain/time/DeleteTimeEntryOrTimeOffDialog/DeleteTimeEntryOrTimeOffDialog';
import FeatureTimeFormField from 'components/domain/time/FeatureTimeFormField/FeatureTimeFormField';
import TotalTimeFormField from 'components/foundation/form-fields/TotalTimeFormField/TotalTimeFormField';
import { useOpenable, usePermissions } from 'hooks';
import _, { isNil } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import { dateUtils, timeEntryBreakUtils, timeEntryUtils } from 'utils';
import { dateTimeFromISOKeepZone, isSameDay, nightShiftAdjustment, setZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { displayEndTime } from 'utils/timeEntryUtils';
import { v_require } from 'utils/validations';
import EditTimePermissionWell from '../../EditTimePermissionWell/EditTimePermissionWell';
import TimeActionsValidations from '../TimeActionsValidations/TimeActionsValidations';
import useTimeActionsValidations from '../TimeActionsValidations/hooks/useTimeActionsValidations';
import TimeActionFormDetailsSection from '../section/TimeActionFormDetailsSection/TimeActionFormDetailsSection';
import TimeActionsBreakFormField from '../section/TimeActionsBreakFormField/TimeActionsBreakFormField';
import TimeActionsTimeRangeFormFields from '../section/TimeActionsTimeRangeFormFields/TimeActionsTimeRangeFormFields';
import TimezoneFormField from '../section/TimezoneFormField/TimezoneFormField';
import './EditTimeEntryForm.scss';

export interface IEditTimeEntryFormProps {
  className?: ClassName;
  timeEntry?: ITimeEntry | null;
  memberIds: string[];
  formData: ITimeActionsFormData;
  type: 'edit' | 'edit-open';
  onSubmit: (data: ITimeActionsFormData) => void;
  onDelete: (data: ITimeActionsFormData) => void;
  onChange?: (formData: ITimeActionsFormData | undefined) => void;
}

export const INPUT_VALUE_FORMAT = 'D';

const EditTimeEntryForm = (props: IEditTimeEntryFormProps) => {
  const { className, timeEntry, memberIds, formData, type, onSubmit, onDelete, onChange } = props;

  const classes = classNames('edit-time-entry-form', className);

  const today = DateTime.local().startOf('day');
  const dateDetails = useOpenable();
  const endDateDetails = useOpenable();
  const { performUpdateOpenEntry, performUpdateClosedEntry, loaderDetails, timeEntryRange } = useTimeActionsForm();
  const [breakError, setBreakError] = useState<boolean>();
  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const [formInfo, setFormInfo] = useState<ITimeActionsFormData>(formData);
  const lockDialogDetails = useOpenable();
  const conflictDialogDetails = useOpenable();
  const signatureViolationDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeActionsFormData>();
  const {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
  } = useTimeActionsValidations();
  const deleteDialogDetails = useOpenable();
  const { hasPermissionToManage } = usePermissions();

  useEffect(() => {
    inhibitsActions(formInfo);
    conflictWarning(formInfo, timeEntry?.id ? [timeEntry.id] : null);
    signatureWarning(formInfo);
  }, [formInfo, memberIds, timeEntry]);

  function renderFormFields({ ...form }: IFormRender<ITimeActionsFormData>) {
    switch (type) {
      case 'edit':
        return (
          <div>
            {renderDate(form)}
            {renderTimePickers(form)}
            {renderTimeZonePicker(form)}
            {renderBreaks(form)}
            {renderTotal(form)}
            <TimeActionFormDetailsSection form={form} onFormChange={setFormInfo} />
            {renderDescription(form)}
            {renderActionButtons(form)}
          </div>
        );
      case 'edit-open':
        return (
          <div>
            {renderDateAndTime(form)}
            {renderBreaks(form)}
            {renderTotal(form)}
            <TimeActionFormDetailsSection form={form} onFormChange={setFormInfo} />
            {renderDescription(form)}
            {renderActionButtons(form)}
          </div>
        );
    }
  }

  function renderDateAndTime({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        <Label>{t('Start Time')}</Label>
        <div className="date-pickers-container mr-4">
          <div className="mr-4">
            <FeatureTimeFormField
              name="startTime"
              clockAction={ClockAction.CLOCK_IN}
              form={form}
              validations={[{ validation: v_require }]}
              timeDST={form.state.data.startDst}
              onChange={(value) => {
                if (!isNil(value)) {
                  setFormInfo({
                    ...form.state.data,
                    startTime: dateTimeFromISOKeepZone(value.toString()),
                    timeRange: {
                      startTime: dateUtils.combineDateAndTime(
                        DateTime.fromJSDate(form.state.data.startDate.value!),
                        dateTimeFromISOKeepZone(value.toString())
                      ),
                      endTime: form.state.data.timeRange.endTime,
                    },
                  });
                }
              }}
            />
          </div>
          <DatePickerFormField
            name="startDate"
            form={form}
            isOpen={dateDetails.isOpen}
            disabledBefore={today.minus({ day: 1 }).toJSDate()}
            disabledAfter={today.toJSDate()}
            onOpen={dateDetails.open}
            onClose={dateDetails.close}
            validations={[{ validation: v_require }]}
          />
        </div>
      </div>
    );
  }

  function showStartEndDatePickers(data: ITimeActionsFormData) {
    if (data.startDate.value !== null && data.endDate.value !== null) {
      if (timeEntry) {
        return !isSameDay(
          dateTimeFromISOKeepZone(timeEntry.startTime),
          !isNil(timeEntry.endTime) ? displayEndTime(timeEntry) ?? DateTime.local() : DateTime.local()
        );
      }
      return (
        dateUtils.numberOfDaysBetween(
          DateTime.fromJSDate(data.startDate.value),
          DateTime.fromJSDate(data.endDate.value)
        ) > 1
      );
    }
    return false;
  }

  function renderDate({ ...form }: IFormRender<ITimeActionsFormData>) {
    function handleStartDateChange(payload: IDatePickerPayload) {
      if (payload.value) {
        const startDate = DateTime.fromJSDate(payload.value);

        if (!startDate) {
          return;
        }

        const endDate = showStartEndDatePickers(formData)
          ? DateTime.fromJSDate(form.state.data.endDate.value!)
          : startDate;

        const startTime = form.state.data.timeRange.startTime;
        const endTime = form.state.data.timeRange.endTime;
        const start = dateUtils.combineDateAndTime(startDate, startTime!);
        const end = dateUtils.combineDateAndTime(endDate, endTime!);

        const newValue: ITimeRange<DateTime> = nightShiftAdjustment({
          startTime: start,
          endTime: end,
        });
        const total = timeEntryUtils.getTotalFromDateTimes(
          newValue,
          form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
        );
        setFormInfo({
          ...form.state.data,
          startDate: {
            value: start.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          endDate: {
            value: endDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          total,
        });
        const isError = timeEntryBreakUtils.isOutsideRange(
          newValue,
          form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
        );
        if (isError !== breakError) {
          setBreakError(isError);
        }
      }
    }

    function handleEndDateChange(payload: IDatePickerPayload) {
      const startDate = form.state.data.startDate.value ? DateTime.fromJSDate(form.state.data.startDate.value) : null;

      if (!startDate) {
        return;
      }

      const endDateBasis = showStartEndDatePickers({ ...formData, endDate: payload })
        ? payload.value
        : form.state.data.startDate.value!;

      if (!endDateBasis) {
        return;
      }

      const endDate = DateTime.fromJSDate(endDateBasis);
      const startTime = form.state.data.timeRange.startTime;
      const endTime = form.state.data.timeRange.endTime;
      const start = dateUtils.combineDateAndTime(startDate, startTime!);
      const end = dateUtils.combineDateAndTime(endDate, endTime!);

      const newValue: ITimeRange<DateTime> = nightShiftAdjustment({
        startTime: start,
        endTime: end,
      });

      const total = timeEntryUtils.getTotalFromDateTimes(
        showStartEndDatePickers(form.state.data) ? { startTime: start, endTime: end } : newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      setFormInfo({
        ...form.state.data,
        startDate: {
          value: start.toJSDate(),
          inputValue: today.toFormat(INPUT_VALUE_FORMAT),
        },
        endDate: {
          value: endDate.toJSDate(),
          inputValue: today.toFormat(INPUT_VALUE_FORMAT),
        },
        total,
      });
      const isError = timeEntryBreakUtils.isOutsideRange(
        newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      if (isError !== breakError) {
        setBreakError(isError);
      }
    }

    const startDatePicker = (
      <DatePickerFormField
        name="startDate"
        form={form}
        isOpen={dateDetails.isOpen}
        disabledAfter={today.toJSDate()}
        onOpen={dateDetails.open}
        onClose={dateDetails.close}
        onChange={handleStartDateChange}
        validations={[{ validation: v_require }]}
      />
    );

    const endDatePicker = (
      <DatePickerFormField
        name="endDate"
        form={form}
        isOpen={endDateDetails.isOpen}
        disabledAfter={today.toJSDate()}
        onOpen={endDateDetails.open}
        onClose={endDateDetails.close}
        onChange={handleEndDateChange}
        validations={[{ validation: v_require }]}
      />
    );

    if (showStartEndDatePickers(form.state.data)) {
      return (
        <div className="date-pickers-container mr-4">
          <div className="date-picker-item">
            <Label>{t('Start Date')}</Label>
            {startDatePicker}
          </div>
          <div className="date-picker-item ml-4">
            <Label>{t('End Date')}</Label>
            {endDatePicker}
          </div>
          {renderDateErrorFooter(form)}
        </div>
      );
    }
    return (
      <div className="date-pickers">
        <Label>{t('Date')}</Label>
        {startDatePicker}
        {renderDateErrorFooter(form)}
      </div>
    );
  }

  function renderDateErrorFooter({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="pt-2 pb-4">
        {form.state.data.startDate.value && hasLockDate && (
          <Label className="start-date-warning">{t('Cannot set a start date older than the lock date.')}</Label>
        )}
      </div>
    );
  }

  function renderTimePickers({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <TimeActionsTimeRangeFormFields
        form={form}
        hasConflictingEntries={hasConflictingEntries}
        setFormInfo={setFormInfo}
        updateBreakError={setBreakError}
      />
    );
  }

  const renderTimeZonePicker = ({ ...form }: IFormRender<ITimeActionsFormData>) => {
    return <TimezoneFormField form={form} hasConflict={hasConflictingEntries} />;
  };

  function renderBreaks({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <TimeActionsBreakFormField
        form={form}
        onUpdate={(breaks, total, error) => {
          setBreakError(error);
          setFormInfo((formData) => ({ ...formData, total, breaks }));
        }}
        timeRange={form.state.data.timeRange}
        startDate={form.state.data.startDate.value ? DateTime.fromJSDate(form.state.data.startDate.value) : null}
        endDate={form.state.data.endDate.value ? DateTime.fromJSDate(form.state.data.endDate.value) : null}
      />
    );
  }

  function renderTotal({ ...form }: IFormRender<ITimeActionsFormData>) {
    if (type === 'edit-open') {
      return null;
    }
    function handleTotalChange(payload?: number | null | undefined) {
      if (payload && payload !== null) {
        let breakTotal = 0;
        for (const brk of form.state.data.breaks) {
          breakTotal += timeEntryBreakUtils.getTotalFromDateTimes(nightShiftAdjustment(brk.timeRange));
        }
        const totalValue = payload + breakTotal;
        const range = form.state.data.timeRange;
        const adjustedEnd = setZone(
          range.startTime!.plus(Duration.fromMillis(totalValue * 1000)),
          range.endTime!.zoneName,
          false
        );
        const adjustedEndDate = DateTime.fromJSDate(form.state.data.startDate.value!).plus({ seconds: totalValue });
        setFormInfo({
          ...form.state.data,
          timeRange: { startTime: range.startTime, endTime: adjustedEnd },
          total: payload,
          endDate: {
            value: adjustedEndDate.toJSDate(),
            inputValue: today.toFormat(INPUT_VALUE_FORMAT),
          },
        });
      }
    }

    return (
      <div>
        <Label>{t('Total')}</Label>
        <div className="total-field">
          <TotalTimeFormField name="total" form={form} onChange={handleTotalChange} />
        </div>
      </div>
    );
  }

  function renderDescription({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        <Label secondaryLabel={t('Optional')}>{t('Description')}</Label>
        <TextareaFormField
          name="description"
          form={form}
          restrictTo={{ maxLength: 5000 }}
          onChange={(value) => setFormInfo({ ...form.state.data, description: value })}
        />
      </div>
    );
  }

  function renderActionButtons({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="pt-6">
        <Button onClick={form.handleSubmit} type="primary">
          {t('Save')}
        </Button>
        <Button onClick={deleteDialogDetails.open} type="link">
          {t('Delete')}
        </Button>
      </div>
    );
  }

  async function handleDelete(data: ITimeActionsFormData) {
    if (timeEntry) {
      onDelete(data);
    }
  }

  function showToastError(message: string) {
    errorToastMessage.current = message;
    errorToastDetails.open();
  }

  async function handleSubmit(data: ITimeActionsFormData) {
    if (breakError) {
      // If break error don't allow submission. Show toast.
      showToastError(t('One or more break is outside of the time entry.'));
      return;
    }

    if (
      (checkValidationsPayload.current.lock && hasLockDate) ||
      (checkValidationsPayload.current.conflict && hasConflictingEntries) ||
      (checkValidationsPayload.current.signature && hasSignatureViolation)
    ) {
      preValidationFormData.current = data;
      launchValidationAlertsOrSave();
      return;
    }

    if (loaderDetails.isOpen) {
      // Action is already being performed.
      return;
    }

    loaderDetails.open();

    switch (type) {
      case 'edit':
        if (timeEntry && data && !_.isEqual(data, formData)) {
          updateEntryFromForm(data);
        } else {
          onSubmit(data);
        }
        break;
      case 'edit-open':
        if (timeEntry && data && !_.isEqual(data, formData)) {
          updateOpenEntryFromForm(data);
        } else {
          onSubmit(data);
        }
        break;
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onConflictContinue() {
    setHasConflictingEntries(false);
    checkValidationsPayload.current.conflict = false;
    conflictDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onSignatureViolationContinue() {
    setHasSignatureViolation(false);
    checkValidationsPayload.current.signature = false;
    signatureViolationDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function launchValidationAlertsOrSave() {
    // lock date

    if (checkValidationsPayload.current.lock && hasLockDate && preValidationFormData.current) {
      const start =
        preValidationFormData.current.startDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.startDate.value!);
      const end =
        preValidationFormData.current.endDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.endDate.value!);
      timeEntryRange.current = preValidationFormData.current.timezone
        ? {
            startTime: preValidationFormData.current.timeRange.startTime!.set({
              year: start.year,
              month: start.month,
              day: start.day,
            }),
            endTime: preValidationFormData.current.timeRange.endTime!.set({
              year: end.year,
              month: end.month,
              day: end.day,
            }),
          }
        : {
            startTime: preValidationFormData.current.timeRange.startTime!.set({
              year: start.year,
              month: start.month,
              day: start.day,
            }),
            endTime: preValidationFormData.current.timeRange.endTime!.set({
              year: end.year,
              month: end.month,
              day: end.day,
            }),
          };
      lockDialogDetails.open();
      return;
    }

    // conflicts alert

    if (checkValidationsPayload.current.conflict && hasConflictingEntries) {
      conflictDialogDetails.open();
      return;
    }

    // signatures alert

    if (checkValidationsPayload.current.signature && hasSignatureViolation) {
      signatureViolationDialogDetails.open();
      return;
    }
  }

  async function updateEntryFromForm(data: ITimeActionsFormData) {
    if (_.isNil(data.timeRange.startTime)) {
      showToastError(t('Start Time must not be null or undefined.'));
      return;
    }
    if (timeEntry) {
      await performUpdateClosedEntry(data, timeEntry, onSubmit);
    } else {
      onSubmit(data);
    }
  }

  async function updateOpenEntryFromForm(data: ITimeActionsFormData) {
    if (_.isNil(data.timeRange.startTime)) {
      showToastError(t('Start Time must not be null or undefined.'));
      return;
    }
    if (timeEntry) {
      await performUpdateOpenEntry(data, timeEntry, onSubmit);
    } else {
      onSubmit(data);
    }
  }

  const canManage = isNil(timeEntry)
    ? true
    : !isNil(timeEntry) &&
      !isNil(timeEntry?.member.position?.manageTimeEntries) &&
      hasPermissionToManage(timeEntry.member, 'manageTimeEntries');

  return (
    <>
      <div>
        <div className="px-8 pb-8">
          <div className="mb-6">
            <EditTimePermissionWell timeEntries={!isNil(timeEntry) ? [timeEntry] : []} />
            {canManage && (
              <Form
                data={formInfo}
                onSubmit={handleSubmit}
                render={renderFormFields}
                className={classes}
                allowMultipleSubmissions={true}
                onChange={onChange}
              />
            )}

            <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
              {errorToastMessage.current}
            </Toast>
          </div>
        </div>
        <Loader isOpen={loaderDetails.isOpen} />
      </div>
      <TimeActionsValidations
        lockDate={timeEntryRange.current ? timeEntryRange.current.startTime : undefined}
        lockMemberIds={formInfo.members}
        lockDateType={LockDateDialogType.SINGLE_EDIT}
        isLockDetailsOpen={lockDialogDetails.isOpen}
        onLockDetailsClose={lockDialogDetails.close}
        onMoveLockDate={onMoveLockDate}
        isConflictDetailsOpen={conflictDialogDetails.isOpen}
        onConflictDetailsClose={conflictDialogDetails.close}
        onConflictContinue={onConflictContinue}
        isSignatureViolationDetailsOpen={signatureViolationDialogDetails.isOpen}
        onSignatureViolationDetailsClose={signatureViolationDialogDetails.close}
        onSignatureViolationContinue={onSignatureViolationContinue}
      />
      <DeleteTimeEntryOrTimeOffDialog
        isOpen={deleteDialogDetails.isOpen}
        onClose={deleteDialogDetails.close}
        timeEntryIds={timeEntry ? [timeEntry?.id] : null}
        handleDelete={() => handleDelete(formInfo)}
      />
    </>
  );
};

EditTimeEntryForm.defaultProps = {
  timeEntry: null,
  timeEntries: [],
  memberIds: [],
  onDelete: () => void 0,
};

export default EditTimeEntryForm;
