import { useApolloClient } from '@apollo/client';
import { GET_PROJECT_ID_QUERY } from 'apollo/queries/project-queries';
import { FolderIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardActiveList from 'components/foundation/dashboard/dashboard-list/DashboardActiveList/DashboardActiveList';
import { IDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import { DashboardTheme } from 'containers/dashboard/types/types';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { useProjectManagementListNavigation } from 'hooks';
import useProjectDashboardNavigation from 'hooks/navigation/useProjectDashboardNavigation';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import IProject from 'types/Project';
import { colors } from 'utils/constants/colors';
import { t } from 'utils/localize';
import DashboardCardSpinner from '../../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../../DashboardErrorCard/DashboardErrorCard';
import { defaultActiveProjectDashboardCardTimeFrame } from '../constants/constants';

export interface IActiveProjectsDashboardCardContentProps {
  data: IDashboardListRow[];
  loading: boolean;
  onReload: () => void;
  onDetailClick: () => void;
  onHide: () => void;
  theme: DashboardTheme;
  error: string | null;
  setting: IDashboardSettingsItem;
  className?: string | null;
}

const ActiveProjectsDashboardCardContent = ({
  data,
  loading,
  error,
  onReload,
  onDetailClick,
  setting,
  theme,
  onHide,
  className,
}: IActiveProjectsDashboardCardContentProps) => {
  const timeFrame = setting.options?.time ?? defaultActiveProjectDashboardCardTimeFrame;
  const title = getDashboardTimeFrameTitle(setting.title, timeFrame);
  const projectManagementNavigation = useProjectManagementListNavigation();
  const projectDashboardNavigation = useProjectDashboardNavigation();
  const client = useApolloClient();

  const [shouldShowCreateProjects, setShouldShowCreateProjects] = useState(false);

  useEffect(() => {
    client
      .query<{ projects: IProject[] }>({
        query: GET_PROJECT_ID_QUERY,
        variables: {
          first: 1,
        },
      })
      .then((results) => {
        setShouldShowCreateProjects(isEmpty(results.data.projects));
      });
  }, [setting]);

  function openProjectManagementList() {
    projectManagementNavigation();
  }

  function openProjectDashboard(projectId: string) {
    projectDashboardNavigation({ project_id: projectId });
  }

  function renderList() {
    return data && data?.length > 0 ? (
      <DashboardActiveList rowItems={data} onRowClick={openProjectDashboard} theme={theme} />
    ) : null;
  }

  const classes = classNames('active-project-dashboard-card', className);

  return (
    <>
      {!shouldShowCreateProjects || loading ? (
        !error ? (
          <DashboardContentCard
            subheaderValue={title}
            tabColor={colors.primary}
            className={classes}
            theme={theme}
            rightLabel={t('View Report')}
            onRightLabelClick={onDetailClick}
          >
            {loading ? <DashboardCardSpinner /> : renderList()}
          </DashboardContentCard>
        ) : (
          <DashboardErrorCard theme={theme} onClick={onReload} />
        )
      ) : (
        <DashboardEnableCard
          title={setting.title}
          svg={FolderIcon}
          buttonLabel={t('Create Projects')}
          onClick={openProjectManagementList}
          theme={theme}
          onHide={onHide}
        />
      )}
    </>
  );
};

export default ActiveProjectsDashboardCardContent;
