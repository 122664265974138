import { useApolloClient } from '@apollo/client';
import { ProjectSearchFields } from '__generated__/graphql';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import { EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { MEMBERS_QUERY } from 'apollo/queries/member-queries';
import { QUICK_SEARCH_PROJECT_ANCESTORS_QUERY } from 'apollo/queries/project-queries';
import { QUICK_SEARCH_PROJECT_QUERY } from 'containers/manage-projects/projects-queries';
import usePayrollEnabled from 'containers/payroll/hooks/usePayrollEnabled/usePayrollEnabled';
import useTimeLogFeedEnabled from 'containers/time-log-feed/useTimeLogFeedEnabled/useTimeLogFeedEnabled';
import { useActiveMember, useDebounce, useOrganization } from 'hooks';
import useApolloMemberNameSearch from 'hooks/models/member/useApolloMemberNameSearch';
import useBudgetsEnabled from 'hooks/models/organization/useBudgetsEnabled';
import useCanViewBudgets from 'hooks/permission/useCanViewBudgets';
import { useEffect, useState } from 'react';
import { IMember } from 'types';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import SearchType from 'types/enum/SearchType';
import { useFeatureFlags } from 'utils/features';

export interface ISearchableRoute {
  name: string;
  keywords: string[];
  path: string;
}

export type CommandPalletActionType = 'add-time-entry' | 'add-time-off';

export interface ICommandPalletAction {
  name: string;
  keywords: string[];
  type: CommandPalletActionType;
}

export interface ICommandPalletData {
  route?: ISearchableRoute;
  member?: IMember;
  costCode?: ICostCode;
  equipment?: IEquipment;
  project?: IProject;
  action?: ICommandPalletAction;
}

export default function useCommandPalletSearch() {
  const organization = useOrganization();
  const member = useActiveMember();
  // Permissions
  const canManageTimeEventsForOthers = (member.position?.timeEvents ?? 0) > 1;
  const canManageTimeEntriesForAnyone = (member.position?.manageTimeEntries ?? 0) > 0;
  const canManageEmployeesForAnyone = (member.position?.manageEmployees ?? 0) > 0;
  const canManageProjects = member.position?.manageProjects ?? false;
  const canManageCostCodes = member.position?.manageCostCodes ?? false;
  const canManageEquipment = member.position?.manageEquipment ?? false;
  const canViewBudgets = useCanViewBudgets();
  const canViewSchedulesForAnyone = (member.position?.viewSchedule ?? 0) > 0 ?? false;
  const dashboardIsEnabled = useFeatureFlags('DASHBOARD');
  const breakHoursIsEnabled = useFeatureFlags('BREAK_HOURS_REPORT');
  const membersIsEnabled = useFeatureFlags('EMPLOYEE_TIMERS');
  const landingPagesIsEnabled = useFeatureFlags('LANDING_PAGES');
  const photosIsEnabled = useFeatureFlags('PHOTOS_REACT');
  const schedulingIsEnabled = useFeatureFlags('SCHEDULING') && canViewSchedulesForAnyone;
  const managementListIsEnabled = useFeatureFlags('MANAGEMENT_LISTS');
  const employeeManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_EMPLOYEES');
  const projectManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_PROJECTS');
  const costCodesManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_COST_CODES');
  const equipmentManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_EQUIPMENT');
  const photoVerificationReportIsEnabled = useFeatureFlags('KIOSK_REPORT');
  const dailyReportIsEnabled = useFeatureFlags('DAILY_REPORTS');
  const timesheetsIsEnabled = useFeatureFlags('TIMESHEETS') && canManageTimeEntriesForAnyone;
  const isPro = useFeatureFlags('PRO');
  const mapSectionEnabled = useFeatureFlags('MAP_SECTION');
  const myStatusIsEnabled = useFeatureFlags('MY_STATUS') && membersIsEnabled;
  const costCodeEnabled = organization.trackCostCode ?? false;
  const equipmentEnabled = ((member.position?.manageEquipment ?? false) && organization.trackEquipment) ?? false;
  const signInReportOn = organization.busySignInQuestion ?? false;
  const canManageSettings = member.position?.manageCompanySettings ?? false;
  const canTimeEventsForAnyone = (member.position?.timeEvents ?? 0) > 0;
  const payrollEnabled = usePayrollEnabled();
  const activityReportIsEnabled = useFeatureFlags('REACT_ACTIVITY_REPORTS');
  const timeOffReportIsEnabled = useFeatureFlags('TIME_OFF_REPORT');
  const progressReportIsEnabled = useFeatureFlags('WORK_ACCOMPLISHED');
  const machineVsOperatorIsEnabled = useFeatureFlags('MACHINE_VS_OPERATOR_REPORT');
  const canViewProgress =
    progressReportIsEnabled &&
    isPro &&
    (member.position?.canManageProjectCostCodeQuantity ?? 0) > 0 &&
    ((organization.trackCostCode ?? false) || (canManageSettings && canManageCostCodes));
  const isDailySignInEnabled = useFeatureFlags('DAILY_SIGN_IN_QUESTIONS');
  const isPhotoVerificationEnabled = useFeatureFlags('PHOTO_VERIFICATION');
  const isEventLogsEnabled = useTimeLogFeedEnabled();
  const isBudgetsEnabled = useBudgetsEnabled();
  const isReactSubscriptionSettingsEnabled = useFeatureFlags('REACT_SUBSCRIPTION_SETTINGS');
  const isBulletinEnabled = useFeatureFlags('BULLETIN_FEATURE');

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 500);

  const [data, setData] = useState<ICommandPalletData[]>([]);

  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const getApolloMemberNameSearch = useApolloMemberNameSearch();

  const handleSearch = (searchString: string) => {
    setSearch(searchString);
  };

  useEffect(() => {
    if (searchDebounced.length > 0) {
      getData();
    } else {
      setData([]);
    }
  }, [searchDebounced]);

  async function getData() {
    setLoading(true);
    let newData: ICommandPalletData[] = [];

    const results = getRoutes().filter((command) => {
      return command.keywords.some((key) => key.includes(searchDebounced.toLowerCase()));
    });
    newData = newData.concat(
      results.slice(0, 4).map((result) => {
        return {
          route: result,
        } as ICommandPalletData;
      })
    );

    try {
      const employees = await getEmployeeResults();
      newData = newData.concat(
        employees.map((result) => {
          return {
            member: result,
          } as ICommandPalletData;
        })
      );
    } catch {
      /* do nothing */
    }

    if (organization && organization.trackProject) {
      try {
        const projects = await getProjectResults();
        newData = newData.concat(
          projects.map((result) => {
            return {
              project: result,
            } as ICommandPalletData;
          })
        );
      } catch {
        /* do nothing */
      }
    }

    if (organization && organization.trackCostCode) {
      try {
        const costCodes = await getCostCodeResults();
        newData = newData.concat(
          costCodes.map((result) => {
            return {
              costCode: result,
            } as ICommandPalletData;
          })
        );
      } catch {
        /* do nothing */
      }
    }

    if (organization && organization.trackEquipment) {
      try {
        const equipment = await getEquipmentResults();
        newData = newData.concat(
          equipment.map((result) => {
            return {
              equipment: result,
            } as ICommandPalletData;
          })
        );
      } catch {
        /* do nothing */
      }
    }

    const actionResults = getActions().filter((action) => {
      return action.keywords.some((key) => key.includes(searchDebounced.toLowerCase()));
    });
    newData = newData.concat(
      actionResults.slice(0, 4).map((result) => {
        return {
          action: result,
        } as ICommandPalletData;
      })
    );

    setLoading(false);
    setData(newData);
  }

  async function getEmployeeResults() {
    const results = await client.query<{ members: IMember[] }>({
      query: MEMBERS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 3,
        filter: {
          archivedOn: { isNull: true },
          search: searchDebounced !== '' ? getApolloMemberNameSearch(searchDebounced) : undefined,
          permissions: {
            permissions: [MemberPermission.TIME_EVENTS],
            operationType: OperationType.AND,
          },
        },
      },
    });
    return results.data.members;
  }

  async function getProjectResults() {
    const results = await client.query<{ projects: IProject[] }>({
      query: QUICK_SEARCH_PROJECT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 3,
        filter: {
          archivedOn: { isNull: true },
          search:
            searchDebounced !== ''
              ? {
                  type: SearchType.CONTAINS,
                  fields: [ProjectSearchFields.Title, ProjectSearchFields.Number, ProjectSearchFields.Customer],
                  value: searchDebounced,
                }
              : undefined,
        },
      },
    });

    const projectIds = results.data.projects.map((project) => project.id);

    const ancestorResults = await client.query<{ projects: IProject[] }>({
      query: QUICK_SEARCH_PROJECT_ANCESTORS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          id: { contains: projectIds },
        },
      },
    });
    return ancestorResults.data.projects;
  }

  async function getCostCodeResults() {
    const results = await client.query<{ costCodes: ICostCode[] }>({
      query: COST_CODES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 3,
        filter: {
          archivedOn: { isNull: true },
          search:
            searchDebounced !== ''
              ? {
                  type: SearchType.CONTAINS,
                  fields: ['costCode', 'title'],
                  value: searchDebounced,
                }
              : undefined,
        },
      },
    });
    return results.data.costCodes;
  }

  async function getEquipmentResults() {
    const results = await client.query<{ equipment: IEquipment[] }>({
      query: EQUIPMENT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 3,
        filter: {
          deletedOn: { isNull: true },
          search:
            searchDebounced !== ''
              ? {
                  type: SearchType.CONTAINS,
                  fields: ['equipmentName', 'modelName', 'makeName'],
                  value: searchDebounced,
                }
              : undefined,
        },
      },
    });
    return results.data.equipment;
  }

  function getRoutes() {
    const newRoutes: ISearchableRoute[] = [];

    if (dashboardIsEnabled) {
      newRoutes.push({
        name: 'Dashboard',
        keywords: ['home', 'dashboard'],
        path: '/',
      });
    }

    if (myStatusIsEnabled) {
      newRoutes.push({
        name: 'My Status',
        keywords: ['my status'],
        path: '/my-status',
      });
    }

    if (timesheetsIsEnabled) {
      newRoutes.push(
        {
          name: 'Time Cards / Summary',
          keywords: ['timesheets', 'timesheets / summary', 'summary', 'time cards'],
          path: '/time-cards?timesheet_view=Summary',
        },
        {
          name: 'Time Cards / Basic',
          keywords: ['timesheets', 'timesheets / time cards', 'time cards', 'basic'],
          path: '/time-cards?timesheet_view=Time Cards',
        },
        {
          name: 'Time Cards / Expanded',
          keywords: ['timesheets', 'timesheets / time cards', 'time cards', 'expanded'],
          path: '/time-cards?timesheet_view=Time Cards',
        },
        {
          name: 'Time Cards / Entries',
          keywords: ['timesheets', 'timesheets / time entries', 'time entries', 'entries', 'time cards'],
          path: '/time-cards?timesheet_view=Time Entries',
        }
      );
    }

    if (photosIsEnabled && isPro) {
      newRoutes.push({
        name: 'Photos',
        keywords: ['photos', 'notes', 'pictures'],
        path: '/photos',
      });
    }

    if (costCodesManagementIsEnabled && managementListIsEnabled && costCodeEnabled && canManageCostCodes) {
      newRoutes.push({
        name: 'Management Lists / Cost Codes',
        keywords: ['management lists', 'management lists / cost codes', 'cost codes', 'costcodes', 'costing'],
        path: '/manage/cost-codes',
      });
    }

    if (employeeManagementIsEnabled && managementListIsEnabled && canManageEmployeesForAnyone) {
      newRoutes.push({
        name: 'Management Lists / Employees',
        keywords: ['management lists', 'management lists / employees', 'employees'],
        path: '/manage/employees',
      });
    }

    if (projectManagementIsEnabled && managementListIsEnabled && canManageProjects) {
      newRoutes.push({
        name: 'Management Lists / Projects',
        keywords: ['management lists', 'management lists / projects', 'projects', 'jobs'],
        path: '/manage/projects',
      });
    }

    if (equipmentManagementIsEnabled && managementListIsEnabled && equipmentEnabled && canManageEquipment) {
      newRoutes.push({
        name: 'Management Lists / Equipment',
        keywords: ['management lists', 'management lists / equipment', 'equipment', 'machine'],
        path: '/manage/equipment',
      });
    }

    if (membersIsEnabled && canManageTimeEventsForOthers) {
      newRoutes.push({
        name: 'Employees',
        keywords: ['employees', 'timers', 'clock', 'landing pages'],
        path: '/employees',
      });
    }

    if (!!organization.trackProject && canManageProjects) {
      newRoutes.push({
        name: 'Projects',
        keywords: ['projects', 'timers', 'clock', 'landing pages', 'jobs'],
        path: '/projects',
      });
    }

    if (canManageCostCodes && (costCodeEnabled || (landingPagesIsEnabled && canManageSettings === true))) {
      newRoutes.push({
        name: 'Cost Codes',
        keywords: ['cost codes', 'costcodes', 'timers', 'clock', 'landing pages', 'costing'],
        path: '/cost-codes',
      });
    }

    if (canManageEquipment && (equipmentEnabled || (landingPagesIsEnabled && canManageSettings === true))) {
      newRoutes.push({
        name: 'Equipment',
        keywords: ['equipment', 'timers', 'clock', 'landing pages', 'machine'],
        path: '/equipment',
      });
    }

    if (
      activityReportIsEnabled &&
      (member.position?.viewAllActivityReport === true || (member.position?.viewMemberActivityReport ?? 0) > 0)
    ) {
      newRoutes.push(
        {
          name: 'Activity Reports / By Project',
          keywords: ['activity Reports', 'activity reports / by project', 'projects', 'jobs'],
          path: '/activity-report?filter_type=by-project',
        },
        {
          name: 'Activity Reports / By Cost Code',
          keywords: ['activity Reports', 'activity reports / by cost code', 'cost codes', 'costcodes', 'costing'],
          path: '/activity-report?filter_type=by-cost-code',
        },
        {
          name: 'Activity Reports / By Equipment',
          keywords: ['activity Reports', 'activity reports / by equipment', 'equipment'],
          path: '/activity-report?filter_type=by-equipment',
        },
        {
          name: 'Activity Reports / By Employee',
          keywords: ['activity Reports', 'activity reports / by employee', 'employee'],
          path: '/activity-report?filter_type=by-employee',
        },
        {
          name: 'Activity Reports / By Day',
          keywords: ['activity Reports', 'activity reports / by date', 'date', 'day', 'by day'],
          path: '/activity-report?filter_type=by-date',
        },
        {
          name: 'Activity Reports / By Date Range',
          keywords: ['activity Reports', 'activity reports / by date range', 'date range', 'by date range'],
          path: '/activity-report?filter_type=by-date-range',
        }
      );
    }

    if ((photoVerificationReportIsEnabled || isPhotoVerificationEnabled) && isPro) {
      newRoutes.push({
        name: 'Photo Verification',
        keywords: ['kiosk report', 'photos', 'photo verification'],
        path: '/photo-verification-report',
      });
    }

    if (schedulingIsEnabled && isPro) {
      newRoutes.push({
        name: 'Scheduling',
        keywords: ['scheduling', 'schedule', 'calendar'],
        path: '/scheduling',
      });
    }

    if (dailyReportIsEnabled && isPro) {
      newRoutes.push({
        name: 'Field Reports',
        keywords: ['daily report', 'field report', 'dpr', 'field report', 'field reports'],
        path: '/daily-report',
      });
    }

    if (canManageSettings) {
      newRoutes.push(
        {
          name: 'Settings',
          keywords: ['settings', 'company settings'],
          path: '/settings',
        },
        {
          name: 'Settings / Company Profile',
          keywords: [
            'company profile settings',
            'settings / company profile',
            'company name',
            'phone number',
            'address',
            'currency code',
            'account details',
            'transfer ownership',
          ],
          path: '/settings/company-profile',
        },
        {
          name: 'Settings / Time Keeping',
          keywords: [
            'time keeping settings',
            'project tracking',
            'cost code tracking',
            'equipment tracking',
            'time rounding',
            'time off requests',
            'default start time',
            'default end time',
            'default break duration',
            'settings / time keeping',
            'project scoping',
            'unassigned subprojects',
          ],
          path: '/settings/time-keeping',
        },
        {
          name: 'Settings / Daily Sign In / Out',
          keywords: [
            'daily sign in',
            'daily sign out',
            'sign in questions',
            'questions',
            'settings / daily sign in / out',
            'safety',
            'injury',
            'injured',
            'break policy',
          ],
          path: '/settings/daily-sign-in-out',
        },
        {
          name: 'Settings / Time Card Approval',
          keywords: [
            'time card approval',
            'digital signatures',
            'time card disclaimer',
            'settings / time card approval',
            'lock dates',
            'supervisor signatures',
          ],
          path: '/settings/time-card-approval',
        },
        {
          name: 'Settings / Pay Period and Overtime',
          keywords: ['pay period settings', 'overtime settings', 'settings / pay period and overtime'],
          path: '/settings/pay-period-and-overtime',
        },
        {
          name: 'Settings / Budgeting',
          keywords: ['budgeting', 'budgets', 'labor budgets', 'settings / budgeting', 'performance scores'],
          path: '/settings/budgeting',
        },
        {
          name: 'Settings / Bulletin',
          keywords: ['bulletin', 'settings / bulletin', 'posts'],
          path: '/settings/bulletin',
        },
        {
          name: 'Settings / Positions',
          keywords: ['positions', 'create position', 'settings / positions', 'permissions'],
          path: '/settings/positions',
        },
        {
          name: 'Settings / Other',
          keywords: ['other', 'minimum app version', 'settings / other'],
          path: '/settings/other',
        }
      );
      if (isReactSubscriptionSettingsEnabled) {
        newRoutes.push({
          name: 'Settings / Subscriptions',
          keywords: ['subscriptions', 'settings / subscriptions', 'upgrade', 'downgrade', 'cancel', 'pro'],
          path: '/settings/subscription',
        });
      }
    }

    if (equipmentEnabled && canManageEquipment && isPro) {
      newRoutes.push({
        name: 'Hour Meter Report',
        keywords: ['hour meter report'],
        path: '/hour-meter-report',
      });
    }

    if (breakHoursIsEnabled && canManageTimeEntriesForAnyone) {
      newRoutes.push({
        name: 'Break Hours Report',
        keywords: ['break hours report'],
        path: '/break-hours-report',
      });
    }

    if (mapSectionEnabled && canManageTimeEventsForOthers) {
      newRoutes.push({
        name: 'Map',
        keywords: ['maps', 'universal maps'],
        path: '/map',
      });
    }

    if (
      isPro &&
      ((organization.breakPolicy ?? false) ||
        (organization.safetySignature ?? false) ||
        (organization.timeAccuracy ?? false))
    ) {
      newRoutes.push({
        name: 'Daily Sign Off Report',
        keywords: ['daily sign off report', 'verification & safety', 'verification and safety'],
        path: '/daily-sign-off-report',
      });
    }

    if (payrollEnabled) {
      newRoutes.push({
        name: 'Payroll',
        keywords: ['payroll'],
        path: '/payroll',
      });
    }

    if (timeOffReportIsEnabled) {
      newRoutes.push({
        name: 'Time Off Report',
        keywords: ['time off report', 'time off requests', 'sick', 'vacation', 'pto', 'holiday'],
        path: '/time-off-report',
      });
    }

    if (canViewProgress) {
      newRoutes.push({
        name: 'Progress Report',
        keywords: ['progress report', 'work accomplished', 'material tracking'],
        path: '/progress-report',
      });
    }

    if (isDailySignInEnabled && signInReportOn && canTimeEventsForAnyone && isPro) {
      newRoutes.push({
        name: 'Daily Sign-In Report',
        keywords: ['daily sign in', 'covid', 'questions', 'verification & safety', 'verification and safety'],
        path: '/daily-sign-in',
      });
    }

    if (canViewBudgets && (isBudgetsEnabled || canManageSettings === true)) {
      newRoutes.push({
        name: 'Budgets',
        keywords: ['budgets', 'budgeting', 'labor budgets', 'performance scores'],
        path: '/budgets',
      });
    }

    if (machineVsOperatorIsEnabled && equipmentEnabled && canManageEquipment) {
      newRoutes.push({
        name: 'Machine vs Operator Report',
        keywords: ['machine vs operator report', 'machine hours', 'equipment', 'mvo'],
        path: '/machine-vs-operator-report',
      });
    }

    if (isEventLogsEnabled) {
      newRoutes.push({
        name: 'Event Logs',
        keywords: ['event logs', 'logs', 'time log', 'time log feed', 'log feed', 'history'],
        path: '/event-logs',
      });
    }

    newRoutes.push({
      name: 'Support',
      keywords: ['support', 'help', 'faq', 'contact', 'feedback', 'report a bug', 'report a problem'],
      path: '/support',
    });

    if (isBulletinEnabled && organization.bulletin === true) {
      newRoutes.push({
        name: 'Bulletin',
        keywords: ['bulletin', 'announcements', 'news', 'updates', 'posts'],
        path: '/bulletin',
      });
    }

    return newRoutes;
  }

  function getActions() {
    const actions: ICommandPalletAction[] = [];
    if (canManageTimeEntriesForAnyone) {
      actions.push({
        name: 'Add Time Entry',
        keywords: ['add time entry', 'actions'],
        type: 'add-time-entry',
      });
      actions.push({
        name: 'Add Time Off',
        keywords: ['add time off', 'actions'],
        type: 'add-time-off',
      });
    }

    return actions;
  }

  return {
    search,
    setSearch,
    handleSearch,
    data,
    loading,
  };
}
