import _, { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import ITimeEntry from 'types/TimeEntry';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import { dateTimeFromISOKeepZone, isSameHourMinute } from 'utils/dateUtils';
import {
  IBulkEntryInitialState,
  MULTIPLE,
  TCompositeBreak,
  TMultipleField,
  compositeIdSeperator,
} from '../BulkEditEntryForm/BulkEditEntryForm';

export function fieldIsSet(parsedData: IBulkEntryInitialState, key: keyof IBulkEntryInitialState) {
  return parsedData[key] !== MULTIPLE && parsedData[key] !== null && parsedData[key];
}

export function fieldIsSetValue(field: TMultipleField<string>) {
  return field !== null && field !== MULTIPLE;
}

function getNewValue(
  acc: IBulkEntryInitialState,
  newValue: string | null,
  fieldName: keyof IBulkEntryInitialState
): TMultipleField<string> {
  if (newValue) {
    return fieldIsSet(acc, fieldName) && acc[fieldName] === newValue ? (acc[fieldName] as string) : MULTIPLE;
  } else {
    return acc[fieldName] ? MULTIPLE : null;
  }
}

function getNewDateValue(
  acc: IBulkEntryInitialState,
  newValue: DateTime,
  fieldName: keyof IBulkEntryInitialState
): TMultipleField<DateTime> {
  return fieldIsSet(acc, fieldName) && isSameHourMinute(newValue, acc[fieldName] as DateTime)
    ? (acc[fieldName] as DateTime)
    : MULTIPLE;
}

export function doBreaksTimeMatch(start: DateTime, end: DateTime, timeEntryBreak: ITimeEntryBreak) {
  const timeEntryBreakStart = dateTimeFromISOKeepZone(timeEntryBreak.startTime);
  const timeEntryBreakEnd = dateTimeFromISOKeepZone(timeEntryBreak.endTime!);
  return isSameHourMinute(start, timeEntryBreakStart) && isSameHourMinute(timeEntryBreakEnd, end);
}

export function coalesceBulkTimeEntryFields(timeEntries: ITimeEntry[]) {
  return timeEntries.reduce<IBulkEntryInitialState>((acc, entry, index) => {
    const offsetStart = dateTimeFromISOKeepZone(entry.startTime);
    const offsetEnd = dateTimeFromISOKeepZone(entry.endTime!);

    if (index === 0) {
      // If it's the first entry just inherit everything from the entry.
      acc.project = entry?.project?.id ?? null;
      acc.costCode = entry?.costCode?.id ?? null;
      acc.equipment = entry?.equipment?.id ?? null;
      acc.startTime = offsetStart;
      acc.endTime = offsetEnd.isValid ? offsetEnd : null;
      acc.description = entry.description ?? null;
      acc.breaks =
        entry.breaks
          ?.filter((b) => b.deletedOn === null)
          .map((entryBreak) => ({ ...entryBreak, compositeId: entryBreak.id })) ?? null;
    } else {
      acc.startTime = getNewDateValue(acc, offsetStart, 'startTime');
      acc.endTime = offsetEnd.isValid ? getNewDateValue(acc, offsetEnd, 'endTime') : null;

      // If it's not the first entry we need to check each value
      acc.project = getNewValue(acc, entry?.project?.id ?? null, 'project');
      acc.costCode = getNewValue(acc, entry?.costCode?.id ?? null, 'costCode');
      acc.equipment = getNewValue(acc, entry?.equipment?.id ?? null, 'equipment');
      acc.description = getNewValue(
        acc,
        entry?.description && !isEmpty(entry?.description) ? entry.description : null,
        'description'
      );

      const nonDeletedBreaks = entry.breaks.filter((b) => isNil(b.deletedOn));

      if (fieldIsSet(acc, 'breaks') && acc?.breaks?.length === nonDeletedBreaks.length) {
        const matches = (acc.breaks as TCompositeBreak[]).map((accBreak) => {
          const accBreakStart = dateTimeFromISOKeepZone(accBreak.startTime);
          const accBreakEnd = dateTimeFromISOKeepZone(accBreak.endTime!);
          const found = nonDeletedBreaks.find((entryBreak) =>
            doBreaksTimeMatch(accBreakStart, accBreakEnd, entryBreak)
          );
          if (found) {
            return { ...accBreak, compositeId: `${accBreak.compositeId}${compositeIdSeperator}${found.id}` };
          } else {
            return null;
          }
        });

        const missingMatch = matches.some(_.isNull);
        if (missingMatch) {
          acc.breaks = MULTIPLE;
        } else {
          acc.breaks = matches as TCompositeBreak[];
        }
      } else {
        if (acc.breaks === null) {
          acc.breaks = nonDeletedBreaks.length !== 0 ? MULTIPLE : null;
        } else {
          acc.breaks = MULTIPLE;
        }
      }
    }
    return acc;
  }, {} as IBulkEntryInitialState);
}
