import { Button, Form, IFormRender, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import BasicFormField from 'components/foundation/form-fields/BasicFormField/BasicFormField';
import BasicNonNullPicker from 'components/foundation/pickers/basic-picker/BasicNonNullPicker/BasicNonNullPicker';
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { TimeOffRibbonTimeFrame } from 'containers/dashboard/types/types';
import { useRef } from 'react';
import { t } from 'utils/localize';

export interface IDashboardTimeOffCardSettingsFormProps {
  setting: IDashboardSettingsItem;
  onSave: (item: IDashboardSettingsItem) => void;
  className?: ClassName;
}

export interface IDashboardTimeOffCardSettingsFormData {
  timeFrame: TimeOffRibbonTimeFrame;
}

const basicPickerOptions: Array<IBasicPickerValue<TimeOffRibbonTimeFrame>> = [
  { id: 'Upcoming', name: t('Upcoming') },
  { id: '7 days', name: t('Next 7 days') },
  { id: '30 days', name: t('Next 30 days') },
];

function DashboardTimeOffCardSettingsForm({ setting, onSave, className }: IDashboardTimeOffCardSettingsFormProps) {
  const initialData = useRef<IDashboardTimeOffCardSettingsFormData>({
    timeFrame: setting.options?.timeOffTimeFrame ?? 'Upcoming',
  });

  function onSubmit(formData: IDashboardTimeOffCardSettingsFormData) {
    onSave({
      ...setting,
      options: {
        ...setting.options,
        timeOffTimeFrame: formData.timeFrame,
      },
    });
  }

  function renderFormFields(form: IFormRender<IDashboardTimeOffCardSettingsFormData>) {
    return (
      <>
        <Label>{t('Time')}</Label>
        <BasicFormField<TimeOffRibbonTimeFrame, IDashboardTimeOffCardSettingsFormData> form={form} name="timeFrame">
          {(value, onSelect, error) => (
            <BasicNonNullPicker value={value} onSelect={onSelect} error={error} data={basicPickerOptions} />
          )}
        </BasicFormField>
        <Button className="mt-4" type="primary" onClick={form.handleSubmit}>
          {t('Save')}
        </Button>
      </>
    );
  }

  const classes = classNames('dashboard-time-off-card-settings-form', className);

  return <Form className={classes} onSubmit={onSubmit} render={renderFormFields} data={initialData.current} />;
}

export default DashboardTimeOffCardSettingsForm;
