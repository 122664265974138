import { ApolloClient, QueryOptions, useApolloClient } from '@apollo/client';
import { EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { equipmentCategoriesWithTypeQuery } from 'components/domain/equipment/EquipmentCategoriesForTypePicker/equipment-cateory-picker-queries';
import { first, isNil, toNumber } from 'lodash';
import IEquipment from 'types/Equipment';
import IEquipmentCategory from 'types/EquipmentCategory';
import { isNumberString } from 'utils/numberUtils';
import { containsOnlyNumbers, convertEmptyToNull } from 'utils/stringUtils';
import ICSVEquipment, { IEquipmentCsvImportErrorState } from '../../../csv-import/types/ImportEquipmentTypes';
import { validateUUID } from '../../../csv-import/utils/utils';

function getEquipmentSingleArgument(id: string): QueryOptions {
  return {
    fetchPolicy: 'network-only',
    query: EQUIPMENT_QUERY,
    variables: {
      first: 1,
      filter: {
        id: { equal: id },
      },
    },
  };
}

async function getCategory(client: ApolloClient<object>, categoryTitle: string) {
  if (!isNil(convertEmptyToNull(categoryTitle))) {
    try {
      const results = await client.query<{ equipmentCategories: IEquipmentCategory[] }>({
        query: equipmentCategoriesWithTypeQuery,
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
          filter: {
            title: { equal: categoryTitle },
            deletedOn: { isNull: true },
          },
        },
      });
      return first(results.data.equipmentCategories.filter((c) => c.type.title !== 'unknown'));
    } catch (error) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export default function useEquipmentCSVValidation() {
  const client = useApolloClient();

  async function validate(csvEquipment: Array<Partial<ICSVEquipment>>, reimport: boolean) {
    const validations = csvEquipment.flatMap(async (result, index) => {
      const errs: IEquipmentCsvImportErrorState[] = [];
      // Header and 0-based
      const lineNumber = index + 2;

      if (!result.name) {
        errs.push({ type: 'MISSING_NAME', lineNumber });
      }

      if (result.category) {
        const fetchCategory = await getCategory(client, result.category);
        if (isNil(fetchCategory)) {
          errs.push({ type: 'INVALID_CATEGORY', lineNumber });
        }
      } else {
        errs.push({ type: 'MISSING_CATEGORY', lineNumber });
      }

      if (result.make && result.make.length <= 1) {
        errs.push({ type: 'INVALID_MAKE', lineNumber });
      }

      if (result.model && result.model.length <= 1) {
        errs.push({ type: 'INVALID_MODEL', lineNumber });
      }

      if (result.year && result.year !== 'unknown' && (result.year.length !== 4 || !containsOnlyNumbers(result.year))) {
        errs.push({ type: 'INVALID_YEAR', lineNumber });
      }

      if (
        (result.hourMeter && !isNumberString(result.hourMeter)) ||
        (result.hourMeter && isNumberString(result.hourMeter) && toNumber(result.hourMeter) < 0)
      ) {
        errs.push({ type: 'HOUR_METER_MUST_BE_NUMBER', lineNumber });
      }

      if (
        (result.hourlyRate && !isNumberString(result.hourlyRate)) ||
        (result.hourlyRate && isNumberString(result.hourlyRate) && toNumber(result.hourlyRate) < 0)
      ) {
        errs.push({ type: 'HOURLY_COST_MUST_BE_NUMBER', lineNumber });
      }

      if (reimport) {
        if (result.id && result.id.length > 0) {
          if (validateUUID(result.id)) {
            const match = await client.query<{ equipment: IEquipment[] }>(getEquipmentSingleArgument(result.id));
            const oneMatch = match?.data?.equipment?.length === 1;
            if (!oneMatch) {
              errs.push({ type: 'INVALID_ID', lineNumber });
            } else if (oneMatch && first(match.data.equipment)?.deletedOn !== null) {
              errs.push({ type: 'DELETED', lineNumber });
            }
          } else {
            errs.push({ type: 'INVALID_ID', lineNumber });
          }
        } else {
          errs.push({ type: 'MISSING_ID', lineNumber });
        }
      }

      return errs;
    });
    return (await Promise.all(validations)).flat();
  }
  return validate;
}
