import { Field, FieldFooter } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { OrganizationSignInQuestionAudience } from '__generated__/graphql';
import WhomToAskSelect from './WhomToAskSelect/WhomToAskSelect';

export interface IWhomToAskFormFieldProps {
  className?: ClassName;
  form: any;
  name: string;
  onChange?: (value: string) => void;
  validations?: any;
}

const WhomToAskFormField = (props: IWhomToAskFormFieldProps) => {
  const { form, name, validations, className, onChange, ...other } = props;

  const classes = classNames('whom-to-ask-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: string) {
      fieldProps.handleChange(val, onChange);
    }

    const formValue = form.state.data[name];
    const value = formValue ? formValue : OrganizationSignInQuestionAudience.Everyone;

    return (
      <>
        <WhomToAskSelect error={fieldProps.hasError} value={value} onChange={handleOnSelect} {...other} />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
};

export default WhomToAskFormField;
