import { IDialogProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { t } from 'i18next';
import { executeFunctionsCurriedWithArg } from 'utils/functionUtils';
import TableRowHeightForm, { ITableRowHeightForm } from '../TableRowHeightForm/TableRowHeightForm';

export interface ITableRowsDialogProps extends ITableRowHeightForm, Omit<IDialogProps, 'children'> {
  isOpen: boolean;
  title?: string;
}

function TableRowsDialog({ className, isOpen, initialValue, title, onSubmit, onClose }: ITableRowsDialogProps) {
  const classes = classNames('table-rows-dialog', className);

  return (
    <HeaderDialog className={classes} title={title ?? t('Table Rows')} divider={true} isOpen={isOpen} onClose={onClose}>
      <TableRowHeightForm
        className="p-7"
        onSubmit={executeFunctionsCurriedWithArg(onSubmit, () => onClose?.())}
        initialValue={initialValue}
      />
    </HeaderDialog>
  );
}

TableRowsDialog.defaultProps = {
  ...TableRowHeightForm.defaultProps,
};

export default TableRowsDialog;
