import gql from 'graphql-tag';

export const PROJECT_COST_CODE_LINKS_QUERY = gql`
  query ProjectCostCodeLinks($filter: ProjectCostCodeLinkFilter, $sort: [ProjectCostCodeLinkSort!]) {
    projectCostCodeLinks(filter: $filter, first: 500, sort: $sort) {
      id
      costCodeId
      costCode {
        id
        title
        costCode
        unitTitle
        costCodeGroup {
          groupName
          id
        }
      }
    }
  }
`;

export const UNJOINED_PROJECT_COST_CODE_LINKS_QUERY = gql`
  query UnjoinedProjectCostCodeLinks(
    $after: String
    $filter: ProjectCostCodeLinkFilter
    $sort: [ProjectCostCodeLinkSort!]
  ) {
    projectCostCodeLinks(filter: $filter, first: 500, sort: $sort, after: $after) {
      id
      costCodeId
      projectId
      cursor
    }
  }
`;

export const UPDATE_PROJECT_COST_CODE_LINK = gql`
  mutation UpdateProjectCostCodeLink($projectCostCodeLink: ProjectCostCodeLinkUpdateInput) {
    updateProjectCostCodeLink(projectCostCodeLink: $projectCostCodeLink) {
      id
    }
  }
`;

export const CREATE_PROJECT_COST_CODE_LINK = gql`
  mutation CreateProjectCostCodeLink($projectCostCodeLink: ProjectCostCodeLinkCreateInput) {
    createProjectCostCodeLink(projectCostCodeLink: $projectCostCodeLink) {
      id
    }
  }
`;
