import { useApolloClient, useMutation } from '@apollo/client';
import { PROJECT_UPDATE_MUTATION } from 'apollo/mutations/project-mutations';
import { projectsQuery } from 'containers/manage-projects/projects-queries';
import { useApolloPaging } from 'hooks';
import _, { first } from 'lodash';
import ICursorable from 'types/Cursorable';
import IProject, { IProjectUpdateInput } from 'types/Project';

export default function useProject() {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();

  const [updateProjectMutation] = useMutation(PROJECT_UPDATE_MUTATION);

  async function getProject(id: string, query: any = projectsQuery) {
    const results = await client.query<{ projects: IProject[] }>({
      query,
      variables: {
        filter: {
          id: { equal: id },
        },
      },
      fetchPolicy: 'network-only',
    });

    return first(results.data.projects);
  }

  async function getProjects(groupId: string, query: any = projectsQuery) {
    const results = await getAll<IProject & ICursorable>('projects', {
      query,
      variables: {
        first: 500,
        filter: {
          projectGroupId: { equal: groupId },
          archivedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });
    return results;
  }

  async function assignProjectsToGroup(groupId: string, projectIds: string[]) {
    const inputs: IProjectUpdateInput[] = projectIds.map((id) => {
      const item: IProjectUpdateInput = {
        id,
        projectGroupId: groupId,
      };
      return item;
    });

    return await new Promise((resolve, reject) => {
      const promises = inputs.map(async (project) => {
        const result = await updateProjectMutation({ variables: { project } });
        const newProject = result.data?.updateProject;

        if (newProject) {
          return newProject;
        } else {
          return null;
        }
      });

      return Promise.all(promises).then((resolved) => {
        const [failed, successful] = _.partition(resolved, _.isNull);
        if (failed.length === 0) {
          resolve(successful);
        } else {
          reject(failed);
        }
      });
    });
  }

  async function unassignProjectsFromGroup(projectIds: string[]) {
    const inputs: IProjectUpdateInput[] = projectIds.map((id) => {
      const item: IProjectUpdateInput = {
        id,
        projectGroupId: null,
      };
      return item;
    });

    return await new Promise((resolve, reject) => {
      const promises = inputs.map(async (project) => {
        const result = await updateProjectMutation({ variables: { project } });
        const newProject = result.data?.updateProject;

        if (newProject) {
          return newProject;
        } else {
          return null;
        }
      });

      return Promise.all(promises).then((resolved) => {
        const [failed, successful] = _.partition(resolved, _.isNull);
        if (failed.length === 0) {
          resolve(successful);
        } else {
          reject(failed);
        }
      });
    });
  }

  async function unassignAllProjectsFromGroup(groupId: string) {
    const projects = await getProjects(groupId);
    return unassignProjectsFromGroup(projects.flatMap((item) => item.id));
  }

  return { getProject, getProjects, assignProjectsToGroup, unassignProjectsFromGroup, unassignAllProjectsFromGroup };
}
