import { useApolloClient } from '@apollo/client';
import { ISortPayload, SortDirection } from '@busybusy/webapp-react-ui';
import { TIME_OFF_REQUEST_QUERY } from 'apollo/queries/time-off-request-queries';
import { useToastOpen } from 'contexts/ToastContext';
import { useApolloPaging, useLazyLoading } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import _, { isNil } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { clearCheckedTimeOffRequests } from 'store/timeOffReport/TimeOffReport';
import ICursorable from 'types/Cursorable';
import ITimeOffRequest from 'types/TimeOffRequest';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import { TimeOffReportType } from '../TimeOffReportSidePanel/TimeOffReportSidePanel';

export enum TimeOffRequestStatus {
  APPROVED = 'approved',
  DENIED = 'denied',
}

export interface ITimeOffRequestFilter {
  memberIds?: string[] | null;
  paid?: boolean;
  type?: number | null;
  status?: TimeOffRequestStatus | null;
  permissions?: MemberPermission[] | null;
  awaitingApproval?: boolean;
}

export default function useLazyTimeOffRequestData(
  scroller: HTMLElement | Document | null,
  filter: ITimeOffRequestFilter,
  reportType: TimeOffReportType
) {
  const client = useApolloClient();
  const toast = useToastOpen();
  const { getAll } = useApolloPaging();
  const getMemberNameSort = useApolloMemberNameSort();
  const sortDir = useRef<SortDirection>(SortDirection.ASCENDING);
  const sortBy = useRef<string>('date');
  const sortIsDirty = useRef<boolean>(false);
  const reduxDispatch = useDispatch();

  const getTimeOffRequestQueryVariables = useCallback(
    (first?: number, cursor?: string | null) => {
      const sortArgs = () => {
        const direction = sortDir.current === SortDirection.ASCENDING ? 'asc' : 'desc';
        const employeeSortArgs = getMemberNameSort(direction).map((sortObject) => {
          return {
            submittedByMember: sortObject,
          };
        });
        switch (sortBy.current) {
          case 'date':
            return [{ dateStamp: direction }, ...employeeSortArgs];
          case 'type':
            return [{ type: direction }, ...employeeSortArgs];
          case 'paid':
            return [{ paid: direction }, ...employeeSortArgs];
          case 'processedBy': {
            const processedBySortArgs = getMemberNameSort(direction).map((sortObject) => {
              return {
                answeredByMember: sortObject,
              };
            });
            return [...processedBySortArgs, { dateStamp: 'asc' }, ...employeeSortArgs];
          }
          case 'employee':
            return [...employeeSortArgs, { dateStamp: 'asc' }, { createdOn: 'asc' }];
          case 'requestedOn':
            return [{ createdOn: direction }, ...employeeSortArgs];
          case 'answeredOn':
            return [{ createdOn: direction }, ...employeeSortArgs];
          default:
            throw Error(`No supported sort type: ${sortBy.current}`);
        }
      };

      const variables: any = {
        first,
        filter: {
          deletedOn: { isNull: true },
          paid: !isNil(filter.paid) ? { equal: filter.paid } : undefined,
          type: filter.type ? { equal: filter.type } : undefined,
          // eslint-disable-next-line no-extra-boolean-cast
          approved: !isNil(filter.awaitingApproval)
            ? filter.awaitingApproval
              ? { isNull: true }
              : filter.status
              ? { equal: filter.status === TimeOffRequestStatus.APPROVED }
              : { isNull: false }
            : undefined,
          member: {
            permissions: !isNil(filter.permissions)
              ? {
                  permissions: filter.permissions,
                  operationType: OperationType.AND,
                  includeSelf: true,
                }
              : undefined,
            id: !isNilOrEmpty(filter.memberIds) ? { contains: filter.memberIds } : undefined,
            archivedOn: { isNull: true },
          },
        },
        sort: sortArgs(),
      };

      if (cursor) {
        variables.after = cursor;
      }

      return variables;
    },
    [
      filter.paid,
      filter.type,
      filter.awaitingApproval,
      filter.status,
      filter.permissions,
      filter.memberIds,
      getMemberNameSort,
    ]
  );

  const getTimeOffRequestData = async (cursor: string | null, first: number) => {
    const results = await client.query<{ timeOffRequests: Array<ITimeOffRequest & ICursorable> }>({
      query: TIME_OFF_REQUEST_QUERY,
      fetchPolicy: 'network-only',
      variables: getTimeOffRequestQueryVariables(first, cursor),
    });

    return results.data.timeOffRequests ?? [];
  };

  const getData = async (cursor: string | null, first: number) => {
    if (reportType !== TimeOffReportType.TIME_OFF_REQUEST) {
      return [];
    }
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    try {
      return await getTimeOffRequestData(cursor, first);
    } catch (error) {
      toast({ label: t('Something went wrong. Please try again later.') });
    }
    return [];
  };

  const { clearData, data, error, loading, dispatch, loadedAll, getMoreData } = useLazyLoading(
    scroller,
    getData,
    undefined,
    30,
    200
  );

  const updateTimeOffRequestIds = async (timeOffRequestIds: string[]) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });

    const newTimeOffRequestData = await getTimeOffRequestsForIds(timeOffRequestIds);
    const updatedData = data.map((timeOffRequest) => {
      const updatedTimeOffRequest = newTimeOffRequestData.find((newTimeOff) => newTimeOff.id === timeOffRequest.id);

      if (_.isNil(updatedTimeOffRequest)) {
        return timeOffRequest; // this time off request doesn't need to be updated
      } else {
        return updatedTimeOffRequest; // this time off request has been updated
      }
    });

    dispatch({
      type: 'SET_DATA',
      payload: {
        data: updatedData,
        loadedAll,
        loading: false,
      },
    });
  };

  const removeTimeOffRequestIds = async (timeOffRequestIds: string[]) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });

    // removes all time off included in the list of ids
    const updatedData = data.filter((timeOffRequest) => {
      return !timeOffRequestIds.some((removeTimeOffRequestId) => removeTimeOffRequestId === timeOffRequest.id);
    });

    dispatch({
      type: 'SET_DATA',
      payload: {
        data: updatedData,
        loadedAll,
        loading: false,
      },
    });
  };

  const getTimeOffRequestsForIds = async (ids: string[]) => {
    const results = await client.query<{ timeOffRequests: Array<ITimeOffRequest & ICursorable> }>({
      query: TIME_OFF_REQUEST_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          id: { contains: ids },
        },
      },
    });

    return results.data.timeOffRequests ?? [];
  };

  useEffect(clearData, [getTimeOffRequestQueryVariables, clearData]);

  async function forceLoadAll() {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const lastCursor = _.last(data)?.cursor;

    const remainingTimeOffRequests = await getAll<ITimeOffRequest & ICursorable>('timeOffRequests', {
      query: TIME_OFF_REQUEST_QUERY,
      variables: getTimeOffRequestQueryVariables(500, lastCursor),
      fetchPolicy: 'network-only',
    });

    const combinedData = [...data, ...remainingTimeOffRequests];

    dispatch({
      type: 'SET_DATA',
      payload: {
        data: combinedData,
        loadedAll: true,
        loading: false,
      },
    });

    return combinedData;
  }

  // Resets the table with the updated sort configuration each time the user clicks on a sortable column header.
  function handleSort(sort: ISortPayload<ITimeOffRequest>) {
    reduxDispatch(clearCheckedTimeOffRequests());
    sortBy.current = sort.sortBy as any;
    sortDir.current = sort.sortDir;
    sortIsDirty.current = true;
    clearData();
  }

  function processedSort() {
    sortDir.current = SortDirection.DESCENDING;
    clearData();
  }

  function awaitingApprovalSort() {
    sortDir.current = SortDirection.ASCENDING;
    clearData();
  }

  return {
    clearData,
    data,
    error,
    loading,
    forceLoadAll,
    loadedAll,
    updateTimeOffRequestIds,
    removeTimeOffRequestIds,
    getMoreData,
    handleSort,
    sortDir,
    sortBy,
    sortIsDirty,
    awaitingApprovalSort,
    processedSort,
  };
}
