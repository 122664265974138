import { Align, Justify, ListItem, Row, Theme, Tooltip } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import './DashboardListItem.scss';

export interface IDashboardListItemProps {
  label: string;
  value: string;
  theme: Theme.DARK | Theme.LIGHT;
  onClick?: () => void;
  className?: ClassName;
}

export default function DashboardListItem({ label, value, theme, onClick, className }: IDashboardListItemProps) {
  return (
    <ListItem className={classNames(theme, 'dashboard-list-item', { clickable: onClick }, className)} onClick={onClick}>
      <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
        <Tooltip label={label}>
          <div className="dashboard-list-item-label ellipsis">{label}</div>
        </Tooltip>
        <div className="dashboard-list-item-value ml-4">{value}</div>
      </Row>
    </ListItem>
  );
}
