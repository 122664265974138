import { t } from 'utils/localize';

export default function v_length(value: string, args: { num: number; message?: string }) {
  return {
    message: args.message || t('Must be ' + args.num + ' characters long'),
    validate: () => {
      if (!value) {
        return true;
      }
      return value.length === 0 || value.length === args.num;
    },
  };
}
