import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  end_date?: string;
  time_range_type?: TimeRangeType;
  employee_id?: string;
  project_id?: string;
  cost_code_id?: string;
  show_back_button?: boolean;
}

export default function useProgressReportNavigation() {
  return useNavigation<IParams>('/progress-report');
}
