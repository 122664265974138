import { TimeEntryLogActionTypePickerAvailableValue } from 'components/domain/time-entry-logs/TimeEntryLogActionTypePicker/TimeEntryLogActionTypePicker';
import { compact, groupBy, valuesIn } from 'lodash';
import ClockActionType from 'types/enum/ClockActionType';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import { Nullable } from 'types/util/Nullable';
import { getLastCursor } from 'utils/apolloUtils';
import {
  dateTimeFromISOKeepZone,
  formatOptionalDateTimeRange,
  formatOptionalTimeTimeRange,
  getTimeFormatWithYearConsideration,
} from 'utils/dateUtils';
import { compressTimeEntryLogsWithLocationIds } from 'utils/timeEntryLogUtils';
import { InputMaybe, IntComparison, TimeEntryFilter } from '__generated__/graphql';
import { TIME_LOG_FEED_DATE_FORMAT, TIME_LOG_FEED_TIME_FORMAT } from '../constants/constants';
import { LogFeedTimeEntry, LogFeedTimeEntryLog, TimeLogDetailsTimeStatus, TimeLogTimeEntryState } from '../types/types';

export function getTimeEntryLogFeedDateAndTime(timeEntryLog: LogFeedTimeEntryLog, timeEntry: LogFeedTimeEntry) {
  if (timeEntryLog.actionType === ClockActionType.CLOCK_IN_AT) {
    const startTime = dateTimeFromISOKeepZone(timeEntry.startTime);

    return {
      date: null,
      time: startTime.toFormat(TIME_LOG_FEED_TIME_FORMAT),
    };
  } else if (timeEntryLog.actionType === ClockActionType.CLOCK_OUT_AT) {
    const endTime = timeEntry.endTime ? dateTimeFromISOKeepZone(timeEntry.endTime) : null;

    return {
      date: null,
      time: endTime?.toFormat(TIME_LOG_FEED_TIME_FORMAT) ?? null,
    };
  } else if (timeEntryLog.actionType === ClockActionType.EDIT || timeEntryLog.actionType === ClockActionType.MANUAL) {
    const startTime = dateTimeFromISOKeepZone(timeEntry.startTime);
    const endTime = timeEntry.endTime ? dateTimeFromISOKeepZone(timeEntry.endTime) : null;

    return {
      date: formatOptionalDateTimeRange(
        startTime,
        endTime,
        getTimeFormatWithYearConsideration(startTime, TIME_LOG_FEED_DATE_FORMAT),
        endTime ? getTimeFormatWithYearConsideration(endTime, TIME_LOG_FEED_DATE_FORMAT) : TIME_LOG_FEED_DATE_FORMAT
      ),
      time: formatOptionalTimeTimeRange(startTime, endTime, TIME_LOG_FEED_TIME_FORMAT),
    };
  } else {
    return {
      date: null,
      time: null,
    };
  }
}

export function getTimeLogDetailTimeStatus(actionType: ClockActionType): Nullable<TimeLogDetailsTimeStatus> {
  switch (actionType) {
    case ClockActionType.CLOCK_IN_AT:
      return 'start';
    case ClockActionType.CLOCK_OUT_AT:
      return 'end';
    case ClockActionType.EDIT:
    case ClockActionType.MANUAL:
      return 'range';
    default:
      return null;
  }
}

export function shouldShowDateInTimeLogDetailsColumn(actionType: ClockActionType) {
  return actionType === ClockActionType.EDIT || actionType === ClockActionType.MANUAL;
}

export function collapseTimeLogFeedLogsByTimeEntry(
  timeEntryLogsByTimeEntry: LogFeedTimeEntryLog[],
  breaksByTimeEntry: ITimeEntryBreak[]
) {
  const groupings = compressTimeEntryLogsWithLocationIds(timeEntryLogsByTimeEntry, breaksByTimeEntry);

  return {
    cursor: getLastCursor(timeEntryLogsByTimeEntry)!,
    groupings,
  };
}

export function collapseTimeLogFeedLogs(logs: LogFeedTimeEntryLog[]) {
  const groupedByTimeEntry = groupBy(logs, 'timeEntryId');

  const values = valuesIn(groupedByTimeEntry).map((logsByTimeEntry) => {
    return collapseTimeLogFeedLogsByTimeEntry(
      logsByTimeEntry,
      logsByTimeEntry.map((value) => value.timeEntry?.breaks).flat() as ITimeEntryBreak[]
    );
  });

  const compacted = compact(values);
  return compacted.flatMap(({ groupings, cursor }) => {
    return groupings.map((grouping) => ({ ...grouping, cursor }));
  });
}

export function getTimeEntryLogActionTypeGraphQlFilter(
  actionType: TimeEntryLogActionTypePickerAvailableValue
): InputMaybe<IntComparison> {
  switch (actionType) {
    case ClockActionType.MANUAL:
    case ClockActionType.EDIT:
    case ClockActionType.CLOCK_OUT_AT:
    case ClockActionType.CLOCK_IN_AT:
    case ClockActionType.EDIT_BREAKS:
    case ClockActionType.DELETE:
      return { equal: actionType };
    case ClockActionType.CLOCK_IN:
      return { contains: [ClockActionType.CLOCK_IN, ClockActionType.CLOCK_IN_LOCATION] };
    case ClockActionType.CLOCK_OUT:
      return { contains: [ClockActionType.CLOCK_OUT, ClockActionType.CLOCK_OUT_LOCATION] };
  }
}

export function convertTimeLogFeedTimeEntryStateToTimeEntryFilter(
  timeEntryState: TimeLogTimeEntryState
): TimeEntryFilter | undefined {
  if (timeEntryState === 'active') {
    return { deletedOn: { isNull: true } };
  }

  if (timeEntryState === 'deleted') {
    return { deletedOn: { isNull: false } };
  }

  return undefined;
}
