import { ILogFeedTimeGrouping } from 'containers/time-log-feed/types/types';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import IClientTimeEntryLog from 'types/ClientTimeEntryLog';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import { Nullable } from 'types/util/Nullable';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { isSameDay, localizeDateTimeFromUtcISO } from 'utils/dateUtils';
import { getSafeString } from 'utils/optionalUtils';
import { getClockActionTitle } from 'utils/timeEntryLogUtils';

export interface ITimeLogFeedDashboardRow {
  id: string;
  label: Nullable<string>;
  sublabel: Nullable<string>;
  value: string;
  grouping: Nullable<ILogFeedTimeGrouping>;
}

export function useTimeLogFeedDashboardDataMapper() {
  const employeeNameFormatter = useEmployeeNameFormatter();

  return useCallback(
    (grouping: ILogFeedTimeGrouping): ITimeLogFeedDashboardRow => {
      const { timeEntryLog, breakLogs } = grouping;
      const action = getClockActionTitle(
        timeEntryLog as unknown as IClientTimeEntryLog,
        breakLogs,
        timeEntryLog.timeEntry?.breaks as ITimeEntryBreak[]
      );

      const localDay = DateTime.local();
      const time = localizeDateTimeFromUtcISO(timeEntryLog.createdOn);
      const sameDay = isSameDay(localDay, time);
      const timeFormat = sameDay ? DateTimeFormat.timeSimple : DateTimeFormat.weekDayMonthDay;

      const performedBy = employeeNameFormatter(
        getSafeString(timeEntryLog.updaterMember?.firstName),
        getSafeString(timeEntryLog.updaterMember?.lastName)
      );

      const performedOn = employeeNameFormatter(
        getSafeString(timeEntryLog?.member?.firstName),
        getSafeString(timeEntryLog?.member?.lastName)
      );

      const performedByText =
        timeEntryLog.updaterMember?.id !== timeEntryLog.member?.id ? `(${t('Performed by')} ${performedBy})` : '';

      return {
        id: timeEntryLog.id,
        label: action,
        sublabel: `${performedOn} ${performedByText}`,
        value: time.toFormat(timeFormat),
        grouping,
      };
    },
    [employeeNameFormatter]
  );
}
