import { Theme, Toast, Tray } from '@busybusy/webapp-react-ui';
import { DeleteIcon, EditIcon } from 'assets/icons';
import { ActionBar, HeaderDialog, IconButton } from 'components';
import BulkEditEntryForm from 'components/domain/time-entry/bulk-edit/BulkEditEntryForm/BulkEditEntryForm';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import DeleteTimeEntryOrTimeOffDialog from 'components/domain/time/DeleteTimeEntryOrTimeOffDialog/DeleteTimeEntryOrTimeOffDialog';
import { useOpenable } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { clearCheckedTimeEntryIds, clearCheckedTimeOffIds } from 'store/timesheets/Timesheets';
import ITimeEntry from 'types/TimeEntry';

interface ITimesheetsTimeEntryActionBarProps {
  onBulkEditSubmit: (entries: ITimeEntry[]) => void;
  onTimeEntryEdit: (formData: ITimeActionsFormData) => void;
  onDelete: (updatedMembers: string[]) => void;
}

function TimesheetsTimeEntryActionBar(props: ITimesheetsTimeEntryActionBarProps) {
  const { onBulkEditSubmit, onTimeEntryEdit, onDelete } = props;

  const [t] = useTranslation();

  const checkedTimeEntryIds = useSelector<IReduxState, string[]>((state) => state.timesheet.checkedTimeEntryIds);
  const checkedTimeOffIds = useSelector<IReduxState, string[]>((state) => state.timesheet.checkedTimeOffIds);
  const [toastMessage, setToastMessage] = useState('');

  const deleteDialogDetails = useOpenable();
  const timeEntryDialogDetails = useOpenable();
  const bulkEditDialogDetails = useOpenable();

  const dispatch = useDispatch();

  function uncheckTimeIds() {
    dispatch(clearCheckedTimeEntryIds());
    dispatch(clearCheckedTimeOffIds());
  }

  function onDeleteClick() {
    deleteDialogDetails.open();
  }

  async function onDeleteTimeData(memberIds: string[]) {
    deleteDialogDetails.close();

    try {
      // Give me sockets or give me death
      onDelete(memberIds);

      dispatch(clearCheckedTimeEntryIds());
      dispatch(clearCheckedTimeOffIds());
    } catch (e) {
      setToastMessage(t('Something went wrong when deleting your entries'));
    }
  }

  function clearToastMessage() {
    setToastMessage('');
  }

  function onSubmitBulkEdit(entries: ITimeEntry[]) {
    bulkEditDialogDetails.close();
    onBulkEditSubmit(entries);
    dispatch(clearCheckedTimeEntryIds());
  }

  function onTimeEntryFormDataSubmit(formData: ITimeActionsFormData) {
    timeEntryDialogDetails.close();
    onTimeEntryEdit(formData);
    dispatch(clearCheckedTimeEntryIds());
  }

  function onEditSelectionClicked() {
    if (checkedTimeEntryIds.length > 1) {
      bulkEditDialogDetails.open();
    } else {
      timeEntryDialogDetails.open();
    }
  }

  return (
    <>
      <ActionBar selectedCount={checkedTimeEntryIds.length + checkedTimeOffIds.length} onClear={uncheckTimeIds}>
        <Tray spacing={1}>
          {/* If timeOffs are included in the checked list we need to hide edit icon */}
          {checkedTimeOffIds.length === 0 && (
            <IconButton svg={EditIcon} onClick={onEditSelectionClicked} tooltipLabel={t('Edit')} theme={Theme.DARK} />
          )}
          <IconButton svg={DeleteIcon} onClick={onDeleteClick} tooltipLabel={t('Delete')} theme={Theme.DARK} />
        </Tray>
      </ActionBar>

      <TimeActionsFormDialog
        type={checkedTimeEntryIds.length === 1 ? 'edit' : 'add'}
        isOpen={timeEntryDialogDetails.isOpen}
        onClose={timeEntryDialogDetails.close}
        onSubmit={onTimeEntryFormDataSubmit}
        timeEntryId={checkedTimeEntryIds.length === 1 ? checkedTimeEntryIds[0] : null}
        onDelete={onTimeEntryFormDataSubmit}
      />

      <HeaderDialog
        title={t('Edit Multiple Time Entries')}
        isOpen={bulkEditDialogDetails.isOpen}
        onClose={bulkEditDialogDetails.close}
        divider={false}
        subtitle={`${checkedTimeEntryIds.length} ${t('selected')}`}
      >
        <BulkEditEntryForm className="px-8 py-4" timeEntryIds={checkedTimeEntryIds} onSubmit={onSubmitBulkEdit} />
      </HeaderDialog>

      <DeleteTimeEntryOrTimeOffDialog
        isOpen={deleteDialogDetails.isOpen}
        onClose={deleteDialogDetails.close}
        timeEntryIds={checkedTimeEntryIds}
        timeOffIds={checkedTimeOffIds}
        handleDelete={onDeleteTimeData}
      />

      <Toast isOpen={toastMessage.length > 0} onClose={clearToastMessage} theme={Theme.DANGER}>
        {toastMessage}
      </Toast>
    </>
  );
}

export default TimesheetsTimeEntryActionBar;
