import { MEMBERS_QUERY } from 'apollo/queries/member-queries';
import { isWithinSevenDays } from 'containers/login/utils/utils';
import {
  useActiveMember,
  useApolloPaging,
  useOpenable,
  useOrganization,
  useReduxSelector,
  useSubscriptionRpc,
} from 'hooks';
import useIsInIframe from 'hooks/store/useIsInIframe';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IChildrenProps } from 'types/ChildrenProps';
import { Nullable } from 'types/util/Nullable';
import { AnyObject } from 'types/util/Object';
import { isoTimeStampUtc } from 'utils/dateUtils';
import { createScript } from 'utils/headUtils';
import { getFullNameFromMember } from 'utils/stringUtils';

function Intercom({ children }: IChildrenProps) {
  const version = useReduxSelector((state) => state.environment.REACT_APP_VERSION);
  const baseUrl = useReduxSelector((state) => state.environment.REACT_APP_APP_URL);
  const token = useReduxSelector((state) => state.authentication.token);
  const member = useActiveMember();
  const organization = useOrganization();
  const subscription = useReduxSelector((state) => state.organizationSubscriptionStatus);
  const isInIframe = useIsInIframe();

  const subscriptionRpc = useSubscriptionRpc();
  const apolloPageAll = useApolloPaging();
  const anyWindow: any = window;
  const intercomOpenable = useOpenable();
  const successfulInitialUpdate = useRef(false);
  const initialized = useRef(false);
  const [updatePayload, setUpdatePayload] = useState<Nullable<AnyObject>>(null);
  const pathName = useLocation().pathname;

  useOnMount(() => {
    if (!isInIframe) {
      loadIntercom();
    }
  });

  useEffect(() => {
    if (isInIframe) {
      const intercom = anyWindow.Intercom;

      if (intercom) {
        intercomOpenable.close();
        intercom('shutdown');
      }
    }
  }, [isInIframe, anyWindow.intercom]);

  function loadIntercom() {
    function setupIntercom() {
      const intercom = anyWindow.Intercom;

      intercom('onHide', () => {
        setTimeout(() => {
          intercomOpenable.close();
        }, 250);
      });

      intercom('onShow', () => {
        intercomOpenable.open();
      });

      intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_KEY!,
        app_version: version,
      });

      intercom('onUnreadCountChange', (unreadCount: number) => {
        if (unreadCount > 0) {
          intercom('show');
        }
      });

      initialized.current = true;
    }

    createScript(
      'intercom-loader',
      `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_KEY}`,
      setupIntercom
    );
  }

  useEffect(() => {
    const intercom = anyWindow.Intercom;
    if (intercom) {
      intercom('update', {
        ...updatePayload,
        hide_default_launcher: !intercomOpenable.isOpen,
      });
    }
  }, [anyWindow.Intercom, intercomOpenable.isOpen]);

  useEffect(() => {
    async function updateData() {
      const intercom = anyWindow.Intercom;
      if (isNil(member.id) && isNil(organization.id) && intercom && successfulInitialUpdate.current === true) {
        if (intercomOpenable.isOpen) {
          intercomOpenable.close();
        }
        successfulInitialUpdate.current = false;
        if (initialized.current === true) {
          intercom('shutdown');
          intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_KEY!,
            app_version: version,
          });
        }
      }

      if (intercom && member.id && organization.id && !successfulInitialUpdate.current) {
        if (
          (member.firstName === 'John' &&
            member.lastName === 'Doe' &&
            isNil(member.phone) &&
            isWithinSevenDays(organization.createdOn) &&
            member.id === organization.ownedBy) ||
          pathName === `/owner-onboarding`
        ) {
          return;
        }

        const url = token ? `${baseUrl}?authorize=${token}` : '';
        const pricing = await subscriptionRpc.getPricing();
        const memberCount = (
          await apolloPageAll.getAll('members', {
            query: MEMBERS_QUERY,
            variables: {
              first: 500,
              filter: {
                archivedOn: { isNull: true },
              },
            },
          })
        ).length;

        const payload = {
          'hide_default_launcher': !intercomOpenable.isOpen,
          'app_id': process.env.REACT_APP_INTERCOM_KEY!,
          'user_id': member.id,
          'name': getFullNameFromMember(member),
          'email': member.email,
          'phone': member.phone,
          'created_at': member.createdOn,
          'last_request_at': isoTimeStampUtc(),
          'avatar': {
            type: 'avatar',
            imageUrl: member.imageUrl,
          },
          'updated_at': member.updatedOn,
          'app_version': version,
          'app_name': 'busybusy',
          'platform': 'web',
          'username': member.username,
          'userAgent': window.navigator.userAgent,
          'position_id': member.position?.id,
          'position_title': member.position?.title,
          'is_owner': organization.ownedBy === member.id,
          'public_key': token,
          'public key url': url,
          'company': {
            company_id: organization.id!,
            name: organization.organizationName,
            custom_attributes: {
              'remote_created_at': organization.createdOn,
              'updated_at': organization.updatedOn,
              'plan': subscription?.productHandle,
              'users': memberCount,
              'owner id': organization.ownedBy,
              'phone': organization.organizationInfo?.phone || null,
              'chargify subscription id': subscription?.subscriptionId,
              'chargify customer id': subscription?.customerId,
              'chargify plan': subscription?.productHandle,
              'chargify status': subscription?.status,
              'chargify plan price': subscription?.annualSubscriptionCount
                ? `$${pricing.data.result.data?.annually}`
                : `$${pricing.data.result.data?.monthly} per user`,
              'chargify coupon code': subscription?.couponCode || null,
              'chargify referral code': subscription?.referralCode || null,
            },
          },
        };

        intercom('update', payload);
        setUpdatePayload(payload);
        successfulInitialUpdate.current = true;
      }
    }

    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloPageAll, baseUrl, member, organization, subscription, subscriptionRpc, token, version]);

  return <>{children}</>;
}

export default Intercom;
