import { useMemo } from 'react';
import TimeRangeType from 'types/TimeRangeType';
import { IDateActivityTableRowInfo } from '../../date-tables/hooks/useDateActivity';
import useDateActivityPartialRange from './useDateActivityPartialRange';

export default function useDateActivityPartialRangeDetection(
  data: IDateActivityTableRowInfo[],
  dateRangeReportType?: TimeRangeType
) {
  const { isPartial } = useDateActivityPartialRange(dateRangeReportType);

  const partialItems = useMemo(() => {
    return data.filter((item) => isPartial(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dateRangeReportType]);

  return {
    partialItems,
    isPartial,
  };
}
