import {
  BASIC_PROJECT_LABOR_METRICS_FRAGMENT,
  BASIC_PROJECT_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-project-queries';
import gql from 'graphql-tag';

export const PROJECT_TIME_METRIC_QUERY = gql`
  query QueryProjectTimeMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    projects(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_LABOR_METRIC_QUERY = gql`
  query QueryProjectLaborMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    projects(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        projectId
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_TIME_METRIC_QUERY = gql`
  query QueryProjectWithTimeAndTimeMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
  ) {
    projectsWithTime(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const PROJECT_WITH_TIME_AND_LABOR_METRIC_QUERY = gql`
  query QueryProjectWithTimeAndLaborMetrics(
    $first: Int
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
  ) {
    projectsWithTime(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
    ) {
      id
      cursor
      projectLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...ProjectLaborMetrics
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;
