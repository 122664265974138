import gql from 'graphql-tag';
import { BASIC_MEMBER_LABOR_METRICS_FRAGMENT, BASIC_MEMBER_TIME_METRICS_FRAGMENT } from './jit-member-queries';
import { BASIC_PROJECT_LABOR_METRICS_FRAGMENT, BASIC_PROJECT_TIME_METRICS_FRAGMENT } from './jit-project-queries';

export const MEMBER_TIME_METRICS_QUERY = gql`
  query MemberTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      ...BasicMemberLaborMetrics
    }
  }
  ${BASIC_MEMBER_TIME_METRICS_FRAGMENT}
`;

export const MEMBER_METRICS_QUERY = gql`
  query MemberMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      ...MemberLaborMetrics
    }
  }
  ${BASIC_MEMBER_LABOR_METRICS_FRAGMENT}
`;

export const MEMBER_TIME_OFF_TIME_ONLY_METRICS_QUERY = gql`
  query MemberTimeOffOnlyTimeOnlyMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      start
      end
      memberId
      pto
      upto
    }
  }
`;

export const MEMBER_TIME_OFF_METRICS_QUERY = gql`
  query MemberTimeOffOnlyMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      start
      end
      memberId
      pto
      ptoCents
      upto
    }
  }
`;

export const MEMBER_PROJECT_COST_CODE_EQUIPMENT_TIME_METRICS_QUERY = gql`
  query MemberProjectCostCodeEquipmentTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberProjectCostCodeEquipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      projectId
      costCodeId
      equipmentId
      ...BasicProjectLaborMetrics
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const MEMBER_PROJECT_COST_CODE_EQUIPMENT_METRICS_QUERY = gql`
  query MemberProjectCostCodeEquipmentMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    memberProjectCostCodeEquipmentLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      memberId
      projectId
      costCodeId
      equipmentId
      ...ProjectLaborMetrics
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;
