import { gql } from '@apollo/client';

export const BASIC_MEMBER_TIME_METRICS_FRAGMENT = gql`
  fragment BasicMemberLaborMetrics on MemberLaborMetrics {
    breakDt
    breakOt
    breakRt
    dt
    end
    ot
    pto
    rt
    start
    upto
  }
`;

export const BASIC_MEMBER_LABOR_METRICS_FRAGMENT = gql`
  fragment MemberLaborMetrics on MemberLaborMetrics {
    breakDt
    breakOt
    breakRt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;

export const ALL_MEMBER_LABOR_METRICS_FRAGMENT = gql`
  fragment AllMemberLaborMetrics on MemberLaborMetrics {
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    breakUpt
    dt
    dtCents
    end
    memberId
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;
