import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  employee_id?: string;
  filter_status?: string;
  filter_type?: string;
  signature_status?: string;
  exitToLegacy?: boolean;
  end_date?: string;
  time_range_type?: string;
}

export default function useDailySignOffNavigation() {
  return useNavigation<IParams>('/daily-sign-off-report');
}
