import DashboardClockStatus from 'components/foundation/dashboard/DashboardClockStatus/DashboardClockStatus';
import useDashboardClockStatus from 'containers/dashboard/hooks/useDashboardClockStatus';
import { EmployeeTabType } from 'containers/employee-timers/EmployeeTimersActionHeader/EmployeeTimersTabs';
import useEmployeesNavigation from 'hooks/navigation/useEmployeesNavigation';

function DashboardClockStatusContainer() {
  const clockStatus = useDashboardClockStatus();
  const employeesNavigation = useEmployeesNavigation();

  return (
    <>
      <DashboardClockStatus
        value={clockStatus.clockedIn}
        label={'Clocked In'}
        onClick={() => {
          employeesNavigation({ filter_type: EmployeeTabType.CLOCKED_IN, exitToLegacy: false });
        }}
        isLoading={clockStatus.isLoading}
        hasError={clockStatus.error !== null}
      />
      <DashboardClockStatus
        value={clockStatus.onBreak}
        label={'On Break'}
        onClick={() => {
          employeesNavigation({ filter_type: EmployeeTabType.ON_BREAK, exitToLegacy: false });
        }}
        isLoading={clockStatus.isLoading}
        hasError={clockStatus.error !== null}
      />
      <DashboardClockStatus
        value={clockStatus.clockedOut}
        label={'Clocked Out'}
        onClick={() => {
          employeesNavigation({ filter_type: EmployeeTabType.CLOCKED_OUT, exitToLegacy: false });
        }}
        isLoading={clockStatus.isLoading}
        hasError={clockStatus.error !== null}
      />
    </>
  );
}

export default DashboardClockStatusContainer;
