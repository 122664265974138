import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import useOrganizationUpdate from '../../useOrganizationUpdate';
import { IOrganizationSettings } from '../types/OrganizationSettings';
import { IOrganizationWebSettings } from '../types/OrganizationWebSettings';
import { useOrganizationSettings } from '../useOrganizationSettings/useOrganizationSettings';

export default function useOrganizationWebSettingsUpdate() {
  const organizationSettings = useOrganizationSettings();
  const [updateKey] = useOrganizationUpdate();

  return useCallback(
    <K extends keyof IOrganizationWebSettings>(key: K, payload: IOrganizationWebSettings[K]) => {
      const cloned = cloneDeep(organizationSettings);

      const updated: IOrganizationSettings = cloned
        ? {
            ...cloned,
            web: {
              ...cloned?.web,
              [key]: payload,
            },
          }
        : {
            web: {
              [key]: payload,
            },
          };

      return updateKey('organizationSettings', JSON.stringify(updated));
    },
    [organizationSettings, updateKey]
  );
}
