import { useApolloClient } from '@apollo/client';
import { Align, Avatar, Justify, Label, Row, Size } from '@busybusy/webapp-react-ui';
import { MEMBER_QUERY } from 'apollo/queries/member-queries';
import { SIMPLE_TIME_ENTRIES_QUERY } from 'apollo/queries/time-entry-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { IMember } from 'types';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { secondsToHmmString } from 'utils/dateUtils';
import { fullName } from 'utils/memberUtils';
import { breakTotalInRange } from 'utils/timeEntryUtils';
import './CaliforniaBreakLawsForm.scss';
import CaliforniaBreakLawsInfo from './CaliforniaBreakLawsInfo';

export interface ICaliforniaBreakLawsFormProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  memberId: string;
}

interface ICaliforniaInformation {
  hoursLabel: string;
  requirementsLabel: string;
}

interface ICaliforniaHeaderInfo {
  member: IMember;
  totalBreakTime: string;
}

const CaliforniaBreakLawsForm = (props: ICaliforniaBreakLawsFormProps) => {
  const { className, timeRange, memberId } = props;
  const classes = classNames(
    {
      'california-break-laws-form': true,
    },
    className
  );

  const client = useApolloClient();
  const [headerInfo, setHeaderInfo] = useState<ICaliforniaHeaderInfo>();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadData() {
    const [member, timeEntries] = await Promise.all([getMember(), getTimeEntries()]);
    const totalBreakSeconds = _.sumBy(timeEntries, (timeEntry) => breakTotalInRange(timeRange, timeEntry));

    setHeaderInfo({
      member,
      totalBreakTime: secondsToHmmString(totalBreakSeconds),
    });
  }

  async function getMember(): Promise<IMember> {
    const membersQuery = await client.query<{ members: IMember[] }>({
      query: MEMBER_QUERY,
      variables: {
        memberId,
      },
    });

    return membersQuery.data.members[0];
  }

  async function getTimeEntries(): Promise<ITimeEntry[]> {
    const timeEntryResponse = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: SIMPLE_TIME_ENTRIES_QUERY,
      variables: {
        first: 500,
        filter: {
          startTime: { lessThanOrEqual: timeRange.endTime.toISO() },
          endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
          memberId: { equal: memberId },
          deletedOn: { isNull: true },
        },
      },
    });

    return timeEntryResponse.data.timeEntries;
  }

  const renderHeader = (info: ICaliforniaHeaderInfo) => {
    return (
      <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className="mb-6">
        <div>
          <Row align={Align.CENTER}>
            <Avatar
              firstName={info.member.firstName!}
              lastName={info.member.lastName!}
              size={Size.MEDIUM}
              image={info.member.imageUrl ?? undefined}
            />
            <Label className="ml-4">{fullName(info.member)}</Label>
          </Row>
        </div>

        <Label>{info.totalBreakTime}</Label>
      </Row>
    );
  };

  return (
    <div className="mb-6 p-8">
      {headerInfo && renderHeader(headerInfo)}
      <CaliforniaBreakLawsInfo className={classes} />
    </div>
  );
};

export default CaliforniaBreakLawsForm;
