import gql from 'graphql-tag';
import { BASIC_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from './jit-queries';

export const COST_CODE_TIME_METRICS_QUERY = gql`
  query CostCodeTimeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodeLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicLaborMetrics
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const COST_CODE_METRICS_QUERY = gql`
  query CostCodeMetricsQuery(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    costCodeLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...LaborMetrics
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;
