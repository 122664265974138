import { Icon, ITableColumn, Label, Table, Theme, Toast } from '@busybusy/webapp-react-ui';
import { ClockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmptyState } from 'components';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import ContainerWithSpinner from 'components/foundation/ContainerWithSpinner/ContainerWithSpinner';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import useActivityReportDetailColumn, {
  activityReportDetailId,
  timeRangeFromActivityDateItem,
} from 'containers/activity-reports/hooks/useActivityReportDetailColumn';
import useActivityReportDetailTable from 'containers/activity-reports/hooks/useActivityReportDetailTable';
import useActivityReportExport from 'containers/activity-reports/hooks/useActivityReportExport';
import { IMemberActivityTableRowInfo } from 'containers/activity-reports/member-tables/hooks/useMemberActivity';
import useMemberActivityDetails from 'containers/activity-reports/member-tables/hooks/useMemberActivityDetails';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelListener } from 'contexts/ChannelContext/hooks/useChannelListener';
import { useExportChannelListener } from 'contexts/ChannelContext/hooks/useExportChannelListener';
import { useOpenable } from 'hooks';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { t } from 'utils/localize';
import './MemberActivityReportDetailsTable.scss';
import useBrandTitle from 'hooks/meta/useBrandTitle';

export interface IMemberActivityReportDetailsProps {
  className?: ClassName;
  filterId: string;
  filterIdWithDescendants?: boolean;
  filterType: ActivityReportType;
  secondaryReportType: ActivityReportType;
  dateRangeReportType?: TimeRangeType;
  timeRange: ITimeRange<DateTime>;
  onRowSelected: (id: string, secondaryId?: string) => void;
  onDateRowSelected: (id: string, startDate: DateTime, endDate: DateTime) => void;
  scroller: HTMLElement | null;
}

const MemberActivityReportDetailsTable = (props: IMemberActivityReportDetailsProps) => {
  const {
    className,
    filterId,
    filterIdWithDescendants,
    filterType,
    secondaryReportType,
    dateRangeReportType,
    timeRange,
    onRowSelected,
    onDateRowSelected,
    scroller,
  } = props;
  const classes = classNames('member-activity-report-details', className);

  const { sortedData, onSort, sortedBy, sortDirection, sortIsDirty, isLoading, remainingData } =
    useMemberActivityDetails(
      filterId,
      filterType,
      secondaryReportType,
      timeRange,
      filterIdWithDescendants,
      dateRangeReportType
    );

  const data = useMemo(() => {
    if (remainingData) {
      return sortedData.concat(remainingData);
    }
    return sortedData;
  }, [sortedData, remainingData]);

  const [showingPartialDateRange, setShowingPartialDateRange] = useState(false);
  const [lazyLoadingEnabled, setLazyLoadingEnabled] = useState(true);
  useChannelListener(ChannelIds.LazyLoadUpdate, () => setLazyLoadingEnabled(false));

  const getActivityExportData = useActivityReportExport();

  const { aggregateColumns, tableMinWidth } = useActivityReportDetailTable(
    data,
    secondaryReportType,
    ActivityReportType.BY_EMPLOYEE
  );
  const { detailColumn, isShowingPartialDateRange, isDetailRow, exportDetailColumn, getExportData } =
    useActivityReportDetailColumn(secondaryReportType, dateRangeReportType);
  const restrictedAccessToast = useOpenable();

  const flattenedData = useMemo(() => flattenData(data), [data]);

  const partialMessage = useMemo<string | null>(() => {
    if (showingPartialDateRange) {
      switch (dateRangeReportType) {
        case TimeRangeType.MONTHLY:
          return t('* Indicates a partial month');
        case TimeRangeType.PAY_PERIOD:
          return t('* Indicates a partial pay period');
        default:
          return t('* Indicates a partial week');
      }
    }

    return null;
  }, [showingPartialDateRange, dateRangeReportType]);

  const brand = useBrandTitle();

  useExportChannelListener(
    () => exportReport(),
    true,
    `${brand}-employee-activity-report-${dateUtils.isoTimeStampLocal()}`
  );

  function exportReport() {
    const exportData = getExportData(data);
    return getActivityExportData(exportData, (row) => {
      // check for the additional time item
      if (isNil(row.member)) {
        return {
          [t('First Name')]: t('Additional Time'),
          [t('Last Name')]: '',
          ...exportDetailColumn(row),
        };
      }

      return {
        [t('First Name')]: row.member.firstName,
        [t('Last Name')]: row.member.lastName,
        ...exportDetailColumn(row),
      };
    });
  }

  useEffect(() => {
    setShowingPartialDateRange(isShowingPartialDateRange(flattenedData));
  }, [flattenedData]);

  const renderEmployee = (row: IMemberActivityTableRowInfo) => {
    if (secondaryReportType !== ActivityReportType.BASIC && isDetailRow(row)) {
      return null;
    } else if (row.member != null) {
      return <FormattedEmployeeName firstName={row.member.firstName ?? ''} lastName={row.member.lastName ?? ''} />;
    }

    return <>{t('Additional Time')}</>;
  };

  function getColumns(): Array<ITableColumn<IMemberActivityTableRowInfo>> {
    return [
      {
        key: 'member',
        title: t('Employee'),
        sort: true,
        cell: renderEmployee,
        size: '300px',
        footer: renderTotalColumnFooter,
        cellClassName: 'pl-6 name-column',
        headerClassName: 'pl-6 name-column',
        footerClassName: 'pl-6 name-column',
      },
      ...detailColumn,
      ...aggregateColumns,
    ];
  }

  const renderTotalColumnFooter = () => <Label className="cell-title">{t('Totals')}</Label>;

  function rowClickAction(rowItem: IMemberActivityTableRowInfo) {
    // the additional time item can't be clicked
    if (rowItem.member != null) {
      if (
        secondaryReportType === ActivityReportType.BY_DATE_RANGE ||
        secondaryReportType === ActivityReportType.BY_DAY
      ) {
        const selectedTimeRange = timeRangeFromActivityDateItem(rowItem);

        if (!isNil(selectedTimeRange)) {
          onDateRowSelected(rowItem.member.id, selectedTimeRange.startTime, selectedTimeRange.endTime);
          return;
        }
      }

      const secondaryId = activityReportDetailId(secondaryReportType, rowItem);
      onRowSelected(rowItem.member.id, secondaryId);
    } else {
      restrictedAccessToast.open();
    }
  }

  function renderEmptyState() {
    return <EmptyState graphic={<Icon svg={ClockIcon} size={180} />} title={t('No Employee Activity')} />;
  }

  function getRowClassName(rowItem: IMemberActivityTableRowInfo) {
    const totalRow =
      secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
    return isDetailRow(rowItem) ? 'detailRowBackground' : totalRow;
  }

  const tableFooterClassName = useMemo(() => {
    return secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
  }, [secondaryReportType]);

  function flattenData(data: IMemberActivityTableRowInfo[]): IMemberActivityTableRowInfo[] {
    return data.flatMap((item) => {
      if (item.detailRows && !isEmpty(item.detailRows)) {
        return [item, ...item.detailRows];
      } else {
        return [item];
      }
    });
  }

  return (
    <ContainerWithSpinner className={classes} isLoading={isLoading}>
      {partialMessage && <div className="ml-6 mb-2">{partialMessage}</div>}
      <Table
        className={'member-activity-report-details-table'}
        cols={getColumns()}
        data={flattenedData}
        isLoading={isLoading}
        sortDir={sortDirection}
        sortBy={sortedBy}
        strokeCols={true}
        onSort={onSort}
        sortIsDirty={sortIsDirty}
        lazyLoad={lazyLoadingEnabled}
        lazyScrollSectionSize={50}
        lazyScrollTrigger={scroller ?? undefined}
        header="standard"
        footer="standard"
        minWidth={`${tableMinWidth}px`}
        scroller={scroller ?? undefined}
        onRowClick={rowClickAction}
        emptyTemplate={renderEmptyState()}
        getCustomRowClassName={getRowClassName}
        footerClassName={tableFooterClassName}
      />
      <Toast
        isOpen={restrictedAccessToast.isOpen}
        theme={Theme.DANGER}
        onClose={restrictedAccessToast.close}
        timeout={3000}
      >
        {t('Access restricted')}
      </Toast>
    </ContainerWithSpinner>
  );
};

export default MemberActivityReportDetailsTable;
