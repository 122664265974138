import { Button, Container, Label, Loader, TextField } from '@busybusy/webapp-react-ui';
import { SettingsIcon } from 'assets/icons';
import { bitmap2vector } from 'bitmap2vector';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useActiveMember, useOpenable } from 'hooks';
import useSignatureSetting from 'hooks/utils/useSignatureSetting';
import * as htmlToImage from 'html-to-image';
import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import SignaturePad from 'react-signature-pad-wrapper';
import { StoreAuthMemberProper } from 'store/types';
import { IMember } from 'types';
import SignatureDialogReturnType from 'types/enum/SignatureDialogReturnType';
import SignatureSettings from 'types/enum/SignatureSettings';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import './SignatureDialog.scss';
import SignatureSettingsDialog from './SignatureSettingsDialog/SignatureSettingsDialog';
//
// SignaturePad Resources:
//
// https://www.npmjs.com/package/react-signature-pad-wrapper
// https://github.com/szimek/signature_pad
//

export interface ISignatureDialogProps {
  className?: ClassName;
  title: string;
  subtitle?: string;
  returnType: SignatureDialogReturnType;
  isOpen: boolean;
  fontSize?: string;
  maxHeight?: string;
  member?: IMember | Partial<IMember> | StoreAuthMemberProper;
  signatureType?: 'employee' | 'non-employee';
  signatureName?: string;
  onClose: () => void;
  onSubmit: (value: any) => void;
}

const SignatureDialog = (props: ISignatureDialogProps) => {
  const {
    className,
    title,
    subtitle,
    returnType,
    isOpen,
    fontSize,
    maxHeight,
    member,
    signatureType,
    signatureName,
    onClose,
    onSubmit,
  } = props;

  const classes = classNames('signature-container', className);
  const [warning, setWarning] = useState<boolean>(false);
  const [typedSignature, setTypedSignature] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const padRef = useRef<typeof SignaturePad>();
  const activeMember = useActiveMember();
  const signatureSettingsDialog = useOpenable();
  const { getSignatureSetting } = useSignatureSetting();
  const signatureSetting =
    signatureType === 'non-employee'
      ? SignatureSettings.MANUAL
      : getSignatureSetting() ?? SignatureSettings.TYPE_SIGNATURE;
  const isTypedSignature = activeMember.id === member?.id && signatureSetting === SignatureSettings.TYPE_SIGNATURE;
  const isManual = activeMember.id !== member?.id || signatureSetting === SignatureSettings.MANUAL;

  function onTypedSignatureChange(value: string) {
    setTypedSignature(value);
  }

  const clear = () => {
    if (padRef.current) {
      return padRef.current.clear();
    }
    setTypedSignature('');
    setIsLoading(false);
    setWarning(false);
  };

  function submit() {
    const hasManualContent = padRef.current && !padRef.current.isEmpty();
    const hasTypedContent = typedSignature.length > 0;

    if (isManual && hasManualContent) {
      setWarning(false);
      switch (returnType) {
        case SignatureDialogReturnType.PNG:
          onSubmit(padRef.current.toDataURL());
          clear();
          return;
        case SignatureDialogReturnType.JPEG:
          onSubmit(padRef.current.toDataURL('image/jpeg'));
          clear();
          return;
        case SignatureDialogReturnType.SVG:
          onSubmit(window.atob(padRef.current.toDataURL('image/svg+xml').split(',')[1]));
          clear();
          return;
        case SignatureDialogReturnType.BLOB:
          padRef.current.canvas.toBlob(
            (blob: any) => {
              onSubmit(blob);
              clear();
            },
            'image/jpeg',
            0.95
          );
          return;
        case SignatureDialogReturnType.CANVAS:
          onSubmit(padRef.current.canvas);
          clear();
          return;
        default:
          return;
      }
    } else if (isTypedSignature && hasTypedContent) {
      setIsLoading(true);
      setWarning(false);
      const signatureHtml = document.createElement('div');
      const newSignature = renderToStaticMarkup(<>{typedSignature}</>);
      signatureHtml.innerHTML = newSignature;
      switch (returnType) {
        case SignatureDialogReturnType.PNG:
          htmlToImage
            .toPng(signatureHtml!, {
              style: {
                fontFamily: 'Allura',
                fontSize: fontSize ?? '42px',
                maxHeight: maxHeight ?? '50px',
              },
            })
            .then((data) => {
              onSubmit(decodeURIComponent(data));
              clear();
            });
          return;
        case SignatureDialogReturnType.JPEG:
          htmlToImage
            .toJpeg(signatureHtml!, {
              style: {
                fontFamily: 'Allura',
                fontSize: fontSize ?? '42px',
                maxHeight: maxHeight ?? '50px',
              },
            })
            .then((data) => {
              onSubmit(decodeURIComponent(data));
              clear();
            });
          return;
        case SignatureDialogReturnType.SVG:
          htmlToImage
            .toPng(signatureHtml!, {
              style: {
                fontFamily: 'Allura',
                fontSize: fontSize ?? '32px',
                maxHeight: maxHeight ?? '50px',
              },
              height: 36,
              width: 400,
              canvasHeight: 36,
              canvasWidth: 400,
            })
            .then(async (data) => {
              const svg = await convertBitmapToSvg(data);
              onSubmit(svg);
              clear();
            });
          return;
        case SignatureDialogReturnType.BLOB:
          htmlToImage
            .toBlob(signatureHtml!, {
              style: {
                fontFamily: 'Allura',
                fontSize: fontSize ?? '42px',
                maxHeight: maxHeight ?? '50px',
              },
            })
            .then((data) => {
              onSubmit(data);
              clear();
            });
          return;
        case SignatureDialogReturnType.CANVAS:
          htmlToImage
            .toCanvas(signatureHtml!, {
              style: {
                fontFamily: 'Allura',
                fontSize: fontSize ?? '42px',
                maxHeight: maxHeight ?? '50px',
              },
            })
            .then((data) => {
              onSubmit(data);
              clear();
            });
          return;
        default:
          return;
      }
    } else {
      setWarning(true);
      setIsLoading(false);
      clear();
    }
  }

  const convertBitmapToSvg = async (data: string) => {
    const results = await bitmap2vector({ input: data, linefilter: true });
    return results.content;
  };

  function handleClose() {
    setIsLoading(false);
    setWarning(false);
    clear();
    onClose();
  }

  function renderRightContent() {
    if (activeMember.id === member?.id) {
      if (!isNil(signatureType)) {
        if (signatureType === 'employee') {
          return <IconButton svg={SettingsIcon} onClick={signatureSettingsDialog.open} />;
        } else {
          return <></>;
        }
      } else {
        return <IconButton svg={SettingsIcon} onClick={signatureSettingsDialog.open} />;
      }
    } else {
      return <></>;
    }
  }

  function renderTypedSignature() {
    return (
      <>
        <span className={'typed-signature-label'}>{t('Type your full name.')}</span>
        <TextField className={'typed-signature-field'} onChange={onTypedSignatureChange} value={typedSignature} />
      </>
    );
  }

  function renderManualSignature() {
    return (
      <div className={warning ? 'signature warning' : 'signature'}>
        <SignaturePad
          ref={(ref: typeof SignaturePad) => (padRef.current = ref)}
          options={{
            minWidth: 2,
            maxWidth: 2,
            penColor: 'RGB(49, 55, 61)',
            backgroundColor: 'RGB(255, 255, 255)',
            onEnd: () => setWarning(padRef.current.isEmpty()),
          }}
          height={135}
        />
      </div>
    );
  }

  return (
    <div>
      <HeaderDialog
        isOpen={isOpen}
        title={title}
        divider={false}
        subtitle={signatureType === 'non-employee' ? signatureName : member && fullName(member)}
        rightContent={renderRightContent()}
        onClose={handleClose}
      >
        <Container className={classes}>
          <Loader isOpen={isLoading} />
          {subtitle && <div className="mb-4">{subtitle}</div>}
          {isTypedSignature && renderTypedSignature()}
          {isManual && renderManualSignature()}
          {warning && <Label className="warning-text">{t('Required field')}</Label>}
          <Button type="primary" onClick={submit} disabled={isLoading} className="my-4 ">
            {t('Submit')}
          </Button>
          {isManual && (
            <Button type="link" className="mx-6" onClick={clear}>
              {t('Clear Signature')}
            </Button>
          )}
        </Container>
      </HeaderDialog>
      <SignatureSettingsDialog onClose={signatureSettingsDialog.close} isOpen={signatureSettingsDialog.isOpen} />
    </div>
  );
};

SignatureDialog.defaultProps = {
  returnType: SignatureDialogReturnType.JPEG,
};

export default SignatureDialog;
