import { Button, Card, Icon, Loader, Theme, Tooltip, Tray } from '@busybusy/webapp-react-ui';
import {
  IncidentIllnessTypes,
  IncidentOutcomeDetailsTypes,
  IncidentStates,
  IncidentTypes,
} from '__generated__/graphql';
import { InfoIcon } from 'assets/icons';
import classNames from 'classnames';
import { HeaderDialog, Well } from 'components';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { capitalize, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { ClassName } from 'types/ClassName';
import { isoTimeStampUtc } from 'utils/dateUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getFullNameFromMember } from 'utils/stringUtils';
import EmployeeDetailsDialog from '../EmployeeDetailsDialog/EmployeeDetailsDialog';
import { IEmployeeDetailsResult } from '../EmployeeDetailsForm/EmployeeDetailsForm';
import RecordableIncidentsForm from '../RecordableIncidentsForm/RecordableIncidentsForm';
import { ISafetyIncidentsOshaRowItem } from '../SafetyIncidentsOsha300Table/SafetyIncidentsOsha300Table';
import { ISafetyIncidentsReportedRowItem } from '../SafetyIncidentsReportedTable/SafetyIncidentsReportedTable';
import SubmitSafetyIncidentForm from '../SubmitSafetyIncidentForm/SubmitSafetyIncidentForm';
import { useUpdateIncident } from '../hooks/useUpdateIncident/useUpdateIncident';
import './ReviewSafetyIncidentForm.scss';

interface IReviewSafetyIncidentFormProps {
  className?: ClassName;
  isReview: boolean;
  incident: ISafetyIncidentsReportedRowItem | ISafetyIncidentsOshaRowItem;
  onSubmitReview: () => void;
}

export const IllnessTypeMapping: Record<IncidentIllnessTypes, string> = {
  [IncidentIllnessTypes.Hearing]: t('Hearing Loss'),
  [IncidentIllnessTypes.Other]: t('Other'),
  [IncidentIllnessTypes.Poisoning]: t('Poisoning'),
  [IncidentIllnessTypes.Respiratory]: t('Respiratory Condition'),
  [IncidentIllnessTypes.Skin]: t('Skin Disorder'),
};

const ReviewSafetyIncidentForm = (props: IReviewSafetyIncidentFormProps) => {
  const { className, isReview, incident, onSubmitReview } = props;

  const [currentIncident, setCurrentIncident] = useState<ISafetyIncidentsReportedRowItem | ISafetyIncidentsOshaRowItem>(
    incident
  );
  const editIncidentDialog = useOpenable();

  const updateIncident = useUpdateIncident();

  const isRecordable = 'recordable' in currentIncident ? currentIncident.recordable : true;
  const hasAllEmployeeDetails =
    !isNil(currentIncident.employeeJobTitle) &&
    !isNil(currentIncident.employeeBirthday) &&
    !isNil(currentIncident.employeeHireDate) &&
    !isNil(currentIncident.employeeAddress1) &&
    !isNil(currentIncident.employeeCity) &&
    !isNil(currentIncident.employeeSex) &&
    !isNil(currentIncident.employeeState) &&
    !isNil(currentIncident.employeePostalCode);

  const recordableIncidentsDialog = useOpenable();
  const employeeDetailsDialog = useOpenable();
  const loadingOpenable = useOpenable({ isOpen: false });

  const classes = classNames('review-safety-incident-form', className);

  function getDateFromString(isoString?: string) {
    if (isoString) {
      const isoDate = DateTime.fromISO(isoString);
      return isoDate.toFormat('LLL dd, yyyy');
    } else {
      return '---';
    }
  }

  function getTimeFromString(isoString?: string) {
    if (isoString) {
      const isoDate = DateTime.fromISO(isoString);
      return isoDate.toFormat('hh:mm a');
    } else {
      return '---';
    }
  }

  async function handleSubmit() {
    loadingOpenable.open();
    const isIncidentSuccessful = await updateIncident({
      id: currentIncident.id,
      state: isNil(currentIncident.outcomeDetails?.type) ? IncidentStates.Reviewed : IncidentStates.Completed,
      completedOn: isoTimeStampUtc(),
      reviewedOn: isoTimeStampUtc(),
    });
    loadingOpenable.close();

    if (isIncidentSuccessful) {
      onSubmitReview();
    }
  }

  function handleEmployeeDetailsSubmit(result: IEmployeeDetailsResult) {
    employeeDetailsDialog.close();
    setCurrentIncident({
      ...currentIncident,
      employeeAddress1: result.employeeAddress1 ?? undefined,
      employeeBirthday: result.employeeBirthday ?? undefined,
      employeeCity: result.employeeCity ?? undefined,
      employeeHireDate: result.employeeHireDate ?? undefined,
      employeeJobTitle: result.employeeJobTitle ?? undefined,
      employeePostalCode: result.employeePostalCode ?? undefined,
      employeeSex: result.employeeSex ?? undefined,
      employeeState: result.employeeState ?? undefined,
    });
  }

  const handleEditSubmit = () => {
    editIncidentDialog.close();
    onSubmitReview();
  };

  const renderGeneral = () => {
    return (
      <div className={'general p-6'}>
        <HeaderText title={t('GENERAL')} />
        <h5 className={'pt-4'}>{t('Incident Type')}</h5>
        <h6 className={'fw-light fc-2'}>{capitalize(incident.type)}</h6>
        {incident.type === IncidentTypes.Illness && incident.illnessType && (
          <>
            <h5 className={'pt-4'}>{t('Illness Type')}</h5>
            <h6 className={'fw-light fc-2'}>{IllnessTypeMapping[incident.illnessType]}</h6>
          </>
        )}
        <h5 className={'pt-4'}>{t('Employee')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${getFullNameFromMember(currentIncident.member)}`)}</h6>
        <Tray>
          <h5 className={'mt-4'}>{t('Recordable')}</h5>
          <Tooltip label={t('Click for more details')}>
            <Button onClick={recordableIncidentsDialog.open}>
              <span className="recordable-info mt-4">
                <Icon svg={InfoIcon} theme={Theme.PRIMARY} />
              </span>
            </Button>
          </Tooltip>
        </Tray>
        <h6 className={'fw-light fc-2'}>
          {t(
            `${('recordable' in currentIncident && currentIncident.recordable) || !('recordable' in currentIncident) ? 'Yes' : 'No'}`
          )}
        </h6>
        <h5 className={'pt-4'}>{t('Reported By')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${getFullNameFromMember(currentIncident.reportedBy)}`)}</h6>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.createdOn.toFormat('LLL dd, yyyy')}`)}</h6>
        {currentIncident.status === IncidentStates.Completed ? (
          <>
            <h5 className={'pt-4'}>{t('Completed By')}</h5>
            <h6 className={'fw-light fc-2'}>
              {t(`${currentIncident.completedBy ? getFullNameFromMember(currentIncident.completedBy) : '---'}`)}
            </h6>
            <h6 className={'fw-light fc-2'}>{currentIncident.completedOn}</h6>
          </>
        ) : (
          <>
            <h5 className={'pt-4'}>{t('Reviewed By')}</h5>
            <h6 className={'fw-light fc-2'}>
              {t(`${currentIncident.reviewedBy ? getFullNameFromMember(currentIncident.reviewedBy) : '---'}`)}
            </h6>

            <h6 className={'fw-light fc-2'}>{currentIncident.reviewedOn}</h6>
          </>
        )}
      </div>
    );
  };

  const renderEmployeeDetails = () => {
    const details = (
      <>
        <h5 className={'pt-4'}>{t('Job Title')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.employeeJobTitle ?? '---'}`)}</h6>
        <h5 className={'pt-4'}>{t('Birth Date')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${getDateFromString(currentIncident.employeeBirthday)}`)}</h6>
        <h5 className={'pt-4'}>{t('Hire Date')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${getDateFromString(currentIncident.employeeHireDate)}`)}</h6>
        <h5 className={'pt-4'}>{t('Sex')}</h5>
        <h6 className={'fw-light fc-2'}>{capitalize(currentIncident.employeeSex)}</h6>
        <h5 className={'pt-4'}>{t('Address')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.employeeAddress1}`)}</h6>
        <h6 className={'fw-light fc-2'}>
          {t(`${currentIncident.employeeCity}, ${currentIncident.employeeState} ${currentIncident.employeePostalCode}`)}
        </h6>
      </>
    );
    return (
      <div className={'employee-details p-6'}>
        <HeaderText title={t('EMPLOYEE DETAILS')} />
        {isRecordable ? (
          hasAllEmployeeDetails ? (
            details
          ) : (
            <Well className={'mt-4'}>
              <Tray>
                <div className={'pr-4'}>
                  <div>{t('Employee Info Required')}</div>
                  <div className="recordable-info fw-light pt-1">
                    {t(
                      'Recordable incidents require an administrator to provide employee information to complete a Form 301.'
                    )}
                  </div>
                </div>
                <Button type={'primary'} onClick={employeeDetailsDialog.open}>
                  {t('Add')}
                </Button>
              </Tray>
            </Well>
          )
        ) : (
          details
        )}
      </div>
    );
  };

  const renderMedicalCare = () => {
    return (
      <div className={'medical-care p-6'}>
        <HeaderText title={t('MEDICAL CARE')} />
        <h5 className={'pt-4'}>{t('What type of treatment was given?')}</h5>
        {currentIncident.treatment?.treatmentFirstAid && (
          <h6 className={'fw-light fc-2'}>{t(`${'First Aid (On Site)'}`)}</h6>
        )}
        {currentIncident.treatment?.treatmentDoctor && (
          <h6 className={'fw-light fc-2'}>{t(`${'Treatment by Doctor'}`)}</h6>
        )}
        {currentIncident.treatment?.treatmentER && <h6 className={'fw-light fc-2'}>{t(`${'Emergency Room'}`)}</h6>}
        {currentIncident.treatment?.treatmentOvernight && (
          <h6 className={'fw-light fc-2'}>{t(`${'Hospitalized Overnight'}`)}</h6>
        )}
        {currentIncident.treatment?.treatmentNone && <h6 className={'fw-light fc-2'}>{t(`${'None / Refused'}`)}</h6>}
        {currentIncident.treatment?.treatmentOther && <h6 className={'fw-light fc-2'}>{t(`${'Other Treatment'}`)}</h6>}
        {(currentIncident.treatment?.treatmentDoctor ||
          currentIncident.treatment?.treatmentER ||
          currentIncident.treatment?.treatmentOvernight) && (
          <>
            <h5 className={'pt-4'}>{t('If treatment was given away from the worksite, where was it given?')}</h5>
            <h6 className={'fw-light fc-2'}>{t(`${currentIncident.facilityAddress}`)}</h6>
            <h6 className={'fw-light fc-2'}>
              {t(
                `${currentIncident.facilityCity}, ${currentIncident.facilityState} ${currentIncident.facilityPostalCode}`
              )}
            </h6>
            <h5 className={'pt-4'}>{t('Physician/Provider')}</h5>
            <h6 className={'fw-light fc-2'}>{t(`${currentIncident.physicianName}`)}</h6>
          </>
        )}
      </div>
    );
  };

  const renderCaseInformation = () => {
    return (
      <div className={'case-information p-6'}>
        <HeaderText title={t('CASE INFORMATION')} />
        <h5 className={'pt-4'}>{t('Incident Date')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${getDateFromString(currentIncident.occurredAt)}`)}</h6>
        <h5 className={'pt-4'}>{t('Time of Incident')}</h5>
        <h6 className={'fw-light fc-2'}>
          {t(`${currentIncident.occurrenceTimeSet ? getTimeFromString(currentIncident.occurredAt) : '---'}`)}
        </h6>
        <h5 className={'pt-4'}>{t('Time the Employee Began Work')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${getTimeFromString(currentIncident.employeeStartTime)}`)}</h6>
        <h5 className={'pt-4'}>{t('What was the employee doing just before the incident occurred?')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.activityBefore}`)}</h6>
        <h5 className={'pt-4'}>{t('What happened?')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.incidentDescription}`)}</h6>
        <h5 className={'pt-4'}>{t('What was the injury or illness?')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.injuryDescription}`)}</h6>
        <h5 className={'pt-4'}>{t('What object or substance directly harmed the employee?')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.harmfulSubstance}`)}</h6>
        <h5 className={'pt-4'}>{t('Where did the event occur?')}</h5>
        <h6 className={'fw-light fc-2'}>{t(`${currentIncident.locationDetails}`)}</h6>
        <h5 className={'pt-4'}>{t('Project')}</h5>
        <h6 className={'fw-light fc-2'}>
          {t(`${currentIncident.project ? getFormattedPathFromProject(currentIncident.project, true) : '---'}`)}
        </h6>
      </div>
    );
  };

  const renderIncidentOutcome = () => {
    return (
      <div className={'incident-outcome p-6'}>
        <HeaderText title={t('INCIDENT OUTCOME')} />
        <h5 className={'pt-4'}>{t('What was the outcome of the case?')}</h5>
        <h6 className={'fw-light fc-2'}>
          {t(
            `${
              currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.DaysAway
                ? 'Days away from work'
                : currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.Transfer
                  ? 'Job transfer or restriction'
                  : currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.Death
                    ? 'Death'
                    : currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.Other
                      ? 'Other'
                      : '---'
            }`
          )}
        </h6>
        {currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.DaysAway && (
          <>
            <h5 className={'pt-4'}>{t('How many days were spent away from work?')}</h5>
            <h6 className={'fw-light fc-2'}>{t(`${currentIncident.outcomeDetails?.daysAway ?? '---'}`)}</h6>
          </>
        )}
        {currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.Transfer && (
          <>
            <h5 className={'pt-4'}>{t('How many days on job transfer or restriction?')}</h5>
            <h6 className={'fw-light fc-2'}>{t(`${currentIncident.outcomeDetails?.daysTransfer ?? '---'}`)}</h6>
          </>
        )}
        {currentIncident.outcomeDetails?.type === IncidentOutcomeDetailsTypes.Death && (
          <>
            <h5 className={'pt-4'}>{t('Outcome Date')}</h5>
            <h6 className={'fw-light fc-2'}>
              {t(`${getDateFromString(currentIncident.outcomeDetails?.outcomeDate ?? undefined)}`)}
            </h6>
          </>
        )}
      </div>
    );
  };

  const renderSubmitReviewButton = () => {
    const isDisabled = isRecordable ? !hasAllEmployeeDetails : false;

    return (
      <>
        {isReview ? (
          <Button onClick={handleSubmit} type={'primary'} className={'mr-4'} disabled={isDisabled}>
            {t('Submit Review')}
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className={classes}>
      <Tray>
        <div className={'title-content'}>
          <h1 className={'fw-semi-bold mt-9'}>{t('Incident Report')}</h1>
          <h2 className={'fw-light fc-2 mt-3 mb-8'}>{`${t('Case')} #${currentIncident.caseNumber}`}</h2>
        </div>
        {renderSubmitReviewButton()}
        <Button type={'secondary'} onClick={editIncidentDialog.open}>
          {t('Edit')}
        </Button>
      </Tray>
      <Card className={'mb-12'}>
        <div className={'review-safety-incident-form-content'}>
          <div className={'left-content'}>
            {renderGeneral()}
            {isRecordable && renderEmployeeDetails()}
            {renderMedicalCare()}
          </div>
          <div className={'right-content'}>
            {renderCaseInformation()}
            {isRecordable && renderIncidentOutcome()}
          </div>
        </div>
      </Card>
      <HeaderDialog
        isOpen={recordableIncidentsDialog.isOpen}
        onClose={recordableIncidentsDialog.close}
        title={t('Recordable Incidents')}
        divider={false}
      >
        <RecordableIncidentsForm />
      </HeaderDialog>
      <EmployeeDetailsDialog
        isOpen={employeeDetailsDialog.isOpen}
        onClose={employeeDetailsDialog.close}
        incidentId={currentIncident.id}
        handleSubmit={handleEmployeeDetailsSubmit}
      />
      <HeaderDialog
        isOpen={editIncidentDialog.isOpen}
        onClose={editIncidentDialog.close}
        title={t('Edit Incident')}
        divider={false}
      >
        <SubmitSafetyIncidentForm handleCreateIncidentForm={handleEditSubmit} incident={currentIncident} />
      </HeaderDialog>
      <Loader isOpen={loadingOpenable.isOpen} />
    </div>
  );
};

export default ReviewSafetyIncidentForm;
