import { usePayPeriodTimeRangeGet, useWeeklyTimeRangeGet } from 'hooks';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { DashboardTimeFrame } from '../types/types';

export function useDashboardTimeRange(timeFrame: DashboardTimeFrame) {
  const weeklyRangeGet = useWeeklyTimeRangeGet();
  const payPeriodRangeGet = usePayPeriodTimeRangeGet();

  return useMemo(() => {
    const currentTime = DateTime.local().startOf('day'); // use local timezone so ranges matches customers device
    switch (timeFrame) {
      case 'payPeriod':
        return payPeriodRangeGet(currentTime.toSeconds());
      case 'weekly':
        return weeklyRangeGet(currentTime.toSeconds());
      case 'pastSeven':
        return {
          startTime: currentTime.minus({ days: 6 }),
          endTime: currentTime.endOf('day').startOf('second'),
        };
      case 'pastFourteen':
        return {
          startTime: currentTime.minus({ days: 13 }),
          endTime: currentTime.endOf('day').startOf('second'),
        };
      case 'monthly':
      default:
        return {
          startTime: currentTime.startOf('month'),
          endTime: currentTime.endOf('day').startOf('second'),
        };
    }
  }, [payPeriodRangeGet, timeFrame, weeklyRangeGet]);
}
