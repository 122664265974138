import classNames from 'classnames';
import CostCodeCapsules from 'components/domain/cost-code/CostCodeCapsules/CostCodeCapsules';
import EquipmentCapsules from 'components/domain/equipment/EquipmentCapsules/EquipmentCapsules';
import MemberCapsules from 'components/domain/member/MemberCapsules/MemberCapsules';
import ProjectCapsules from 'components/domain/project/ProjectCapsules/ProjectCapsules';
import TimeEntryActionBar from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBar';
import ProjectTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/ProjectTimeEntryDataReport/ProjectTimeEntryDataReport';
import useProjectTimeEntryDataReportData from 'components/domain/time-entry/TimeEntryDataReport/ProjectTimeEntryDataReport/hooks/useProjectTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import { defaultTimeEntryColumns } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { first, isNil, map, partition, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { onlyHasRemainingItem } from 'utils/collectionUtils';
import { areTimeRangesEqual } from 'utils/dateUtils';
import { t } from 'utils/localize';
import TimeEntryFilterBar from '../../TimeEntryFilterBar/TimeEntryFilterBar';
import './ProjectTimeEntryDialog.scss';

export interface IProjectTimeEntryDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  projectIds?: string[] | null;
  memberIds?: string[] | null;
  costCodeIds?: string[] | null;
  equipmentIds?: string[] | null;
  includeOpen?: boolean;
  excludeArchived?: boolean;
  rootProjectsOnly?: boolean;
  projectIdWithDescendants?: boolean;
  timeRangeBackPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  timeRangeForwardPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  excludeTypeColumn?: boolean;
  onDataChange?: (ids: string[]) => void;
}

const ProjectTimeEntryDialog = ({
  className,
  isOpen,
  onClose,
  timeRange,
  timeRangeType,
  projectIds,
  memberIds,
  costCodeIds,
  equipmentIds,
  includeOpen,
  excludeArchived,
  rootProjectsOnly,
  projectIdWithDescendants,
  timeRangeBackPressed,
  timeRangeForwardPressed,
  excludeTypeColumn,
  onDataChange,
}: IProjectTimeEntryDialogProps) => {
  const filterProjectIds = useMemo(() => (projectIds ? projectIds : undefined), [projectIds]);
  const filterMemberIds = useMemo(() => (memberIds ? memberIds : undefined), [memberIds]);
  const filterCostCodeIds = useMemo(() => (costCodeIds ? costCodeIds : undefined), [costCodeIds]);
  const filterEquipmentIds = useMemo(() => (equipmentIds ? equipmentIds : undefined), [equipmentIds]);

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const [scroller, setScroller] = useState<HTMLElement | null>(null);
  const [filterRange, setFilterRange] = useState<ITimeRange<DateTime>>(timeRange);

  const { data, loading, loadedAll, loadAll, updateProjectIds } = useProjectTimeEntryDataReportData(
    scroller,
    filterRange,
    includeOpen,
    filterProjectIds,
    projectIdWithDescendants,
    filterMemberIds,
    filterCostCodeIds,
    filterEquipmentIds,
    excludeArchived,
    rootProjectsOnly,
    false
  );
  const brand = useBrandTitle();

  function renderTimeEntryReport(range: ITimeRange<DateTime>) {
    if (!areTimeRangesEqual(filterRange, range)) {
      setFilterRange(range);
    }
    return (
      <ProjectTimeEntryDataReport
        columnSettings={
          excludeTypeColumn === true ? defaultTimeEntryColumns.filter((c) => c.key !== 'type') : defaultTimeEntryColumns
        }
        timeRange={range}
        selectedIds={selectedIds}
        onSelectChange={onSelect}
        updateProjectIds={handleUpdate}
        data={data}
        loading={loading}
        memberIds={memberIds}
        costCodeId={!onlyHasRemainingItem(costCodeIds) ? first(costCodeIds) : undefined}
        equipmentId={!onlyHasRemainingItem(equipmentIds) ? first(equipmentIds) : undefined}
        loadedAll={loadedAll}
        applyPrintOptions={false}
      />
    );
  }

  function handleUpdate(ids: string[]) {
    updateProjectIds(ids);
    onDataChange?.(ids);
  }

  function renderActionBar(range: ITimeRange<DateTime>) {
    return (
      <TimeEntryActionBar
        onBulkEditSubmit={(entries) => {
          const ids = uniq(entries.map((entry) => entry.project?.id ?? '')).filter((id) => id.length > 0);
          handleUpdate(ids);
        }}
        onDelete={(ids) => {
          handleUpdate(data.map((d) => d.id));
        }}
        onTimeEntryEdit={(data) => {
          const ids = data.members;
          handleUpdate(ids);
        }}
      />
    );
  }

  function renderFilterBar(range: ITimeRange<DateTime>) {
    const exportFileName = `${brand}-${t('project-activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}.csv`;

    return (
      <>
        {(filterMemberIds || filterProjectIds || filterCostCodeIds || filterEquipmentIds) && (
          <TimeEntryFilterBar
            timeRange={timeRange}
            data={data}
            loadedAll={loadedAll}
            loadAll={loadAll}
            exportFileName={exportFileName}
            renderFilters={(forPrint) => (
              <>
                {filterMemberIds && (
                  <MemberCapsules className="ml-2" printVersion={forPrint} memberIds={filterMemberIds} />
                )}
                {filterCostCodeIds && (
                  <CostCodeCapsules className="ml-2" printVersion={forPrint} costCodeIds={filterCostCodeIds} />
                )}
                {filterEquipmentIds && (
                  <EquipmentCapsules className="ml-2" printVersion={forPrint} equipmentIds={filterEquipmentIds} />
                )}
                {/* We're not printing the project capsules because the titles are already on the table headers */}
                {filterProjectIds && !forPrint && (
                  <ProjectCapsules
                    className="ml-2"
                    projectIds={filterProjectIds}
                    printVersion={forPrint}
                    isUnassinged={projectIdWithDescendants === false}
                  />
                )}
              </>
            )}
          />
        )}
      </>
    );
  }

  const classes = classNames('project-time-entry-dialog', className);

  return (
    <ManagedTimeRangeReportDialog
      className={classes}
      isOpen={isOpen}
      onClose={onClose}
      timeRange={timeRange}
      timeRangeType={timeRangeType}
      reportComponent={renderTimeEntryReport}
      title={t('Time Entries')}
      renderActionBar={renderActionBar}
      renderFilterBar={renderFilterBar}
      setRef={setScroller}
      timeRangeBackPressed={timeRangeBackPressed}
      timeRangeForwardPressed={timeRangeForwardPressed}
      headerClassName={'no-print'}
    />
  );
};

export default ProjectTimeEntryDialog;
