import { Align, Button, Dialog, Icon, Label, Row, Size, Tray } from '@busybusy/webapp-react-ui';
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Header, Panel, PanelContent } from 'components';
import { t } from 'i18next';

import IEquipment from 'types/Equipment';
import { getEquipmentDisplay } from 'utils/stringUtils';
import './EquipmentCostHistoryDialog.scss';
import EquipmentCostHistoryTable from './EquipmentCostHistoryTable/EquipmentCostHistoryTable';

export interface IEquipmentCostHistoryDialogProps {
  className?: ClassName;
  equipment: IEquipment;
  isOpen: boolean;
  onClose: () => void;
  onChange: () => void;
}

const EquipmentCostHistoryDialog = (props: IEquipmentCostHistoryDialogProps) => {
  const { className, equipment, isOpen, onClose, onChange } = props;

  function renderHeader() {
    return (
      <Header size={Size.GROW}>
        <Tray>
          <Button type="icon" onClick={onClose} className="close-button">
            <Icon svg={CloseIcon} />
          </Button>
          <div className="pt-4 pb-4">
            <Label className="header-title pt-1">{t('Hourly Rate History')}</Label>
            <div>{getEquipmentDisplay(equipment)}</div>
          </div>
        </Tray>
      </Header>
    );
  }

  function renderReport() {
    return (
      <PanelContent className="panel-content">
        <Row align={Align.CENTER} className="middle-row">
          <EquipmentCostHistoryTable costHistories={equipment.costHistory ?? []} onChange={onChange} />
        </Row>
      </PanelContent>
    );
  }

  const classes = classNames('equipment-cost-history-dialog', className);

  return (
    <Dialog hasDismiss={false} className={classes} size="full" isOpen={isOpen} onClose={onClose}>
      <Panel>
        {renderHeader()}
        {renderReport()}
      </Panel>
    </Dialog>
  );
};

export default EquipmentCostHistoryDialog;
