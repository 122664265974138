import { sha256 } from 'js-sha256';
const {
  atob, // Decodes a string of data which has been encoded using base-64 encoding.
  btoa, // Creates a base-64 encoded ASCII string from a "string" of binary data.
} = window;

const verifierKey = 'codeVerifier';

export function encodeAuthToken(keyAuthorization: string, memberId: string) {
  const decodedString = [keyAuthorization, memberId].join(':');

  const encodedString = btoa(decodedString);

  return encodedString;
}

export function decodeAuthToken(encodedString: string) {
  const decodedString = atob(encodedString);

  const [keyAuthorization, memberId] = decodedString.split(':');

  return {
    keyAuthorization,
    memberId,
  };
}

// Copied from https://github.com/KleeUT/2022_0_dep_pkce_client
export function generateCodeVerifier(): string {
  const code = randomCode();
  const encoded = base64URLEncode(code);
  return encoded;
}

export function generateChallenge(codeVerifierString: string): string {
  const hash = sha256.arrayBuffer(codeVerifierString);
  return bufferToBase64UrlEncoded(hash);
}

export function storeVerifier(verifier: string) {
  localStorage.setItem(verifierKey, verifier);
}

export function getVerifier(): string {
  const codeVerifier = localStorage.getItem(verifierKey);
  if (!codeVerifier) {
    return '';
  }
  return codeVerifier;
}

function randomCode(): string {
  let array = new Uint8Array(32);
  array = crypto.getRandomValues(array);
  return String.fromCharCode.apply(null, Array.from(array));
}

function base64URLEncode(str: string): string {
  const b64 = btoa(str);
  const encoded = b64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  return encoded;
}

function bufferToBase64UrlEncoded(hash: ArrayBuffer): string {
  const uintArray = new Uint8Array(hash);
  const numberArray = Array.from(uintArray);
  const hashString = String.fromCharCode(...numberArray);
  return urlEncodeB64(btoa(hashString));
}

function urlEncodeB64(input: string) {
  const b64Chars: { [index: string]: string } = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m]);
}

export default {
  decodeAuthToken,
  encodeAuthToken,
};
