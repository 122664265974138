import {
  Align,
  Col,
  Icon,
  Image,
  ITableColumn,
  Justify,
  Label,
  Row,
  Table,
  TextAlign,
  Theme,
  Tooltip,
} from '@busybusy/webapp-react-ui';
import { OrganizationSignOffQuestion } from '__generated__/graphql';
import { FlagIcon, SafetyIcon, TimeCardIcon } from 'assets/icons';
import classNames from 'classnames';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import { IDailySignOffReportColumnHeader } from 'containers/daily-sign-off/DailySignOffReportTable/DailySignOffReportTable';
import { useToastOpen } from 'contexts/ToastContext';
import { useOrganization, useTableSorting } from 'hooks';
import useGetOrganizationSignOffQuestions from 'hooks/models/organization-sign-off-questions/useGetOrganizationSignOffQuestions';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import useGetPosition from 'hooks/models/position/useGetPosition';
import { useMounted } from 'hooks/utils/useMounted';
import _, { first, isNil, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import { IMemberSignOffAnswer } from 'types/SafetySignature';
import ITimeRange from 'types/TimeRange';
import { fullName } from 'utils/memberUtils';
import { getAudienceTitle } from 'utils/organizationSignInQuestionUtils';
import { getAnswersForQuestion, getQuestionTitleAndLinks } from 'utils/organizationSignOffQuestionUtils';
import './DailySignOffReportTable.scss';
import { SignOffReportItem, useDailySignOffReportTableData } from './useDailySignOffReportTableData';

export interface IDailySignOffReportTableProps {
  className?: ClassName;
  member: IMember;
  timeRange: ITimeRange<DateTime>;
  onOpenDetails: (member: IMember, date: DateTime, refreshAction: () => void) => void;
  onOpenEntries: (member: string, date: DateTime) => void;
  scroller?: HTMLElement;
}

const DailySignOffReportTable = (props: IDailySignOffReportTableProps) => {
  const { className, member, timeRange, onOpenDetails, onOpenEntries, scroller } = props;

  const organization = useOrganization();
  const [t] = useTranslation();
  const { data, hasDataLoaded, refreshData } = useDailySignOffReportTableData(timeRange, member.id);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(data);
  const getOrganizationSignOffQuestions = useGetOrganizationSignOffQuestions();
  const allQuestions = useRef<OrganizationSignOffQuestion[]>([]);
  const { getPosition } = useGetPosition();
  const toastOpenable = useToastOpen();
  const [columns, setColumns] = useState<Array<ITableColumn<SignOffReportItem>>>([]);
  const isMounted = useMounted();
  const classes = classNames('daily-sign-off-report-table', className, {
    'is-loaded': hasDataLoaded,
    'is-loading': !hasDataLoaded,
  });
  const isCustomSignOffEnabled = useCustomSignOffEnabled();

  useEffect(() => {
    if (isMounted) {
      getColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function renderDateColumn(row: SignOffReportItem, _col: ITableColumn<SignOffReportItem>): ReactNode {
    const date = row.date;

    function handleOpenEntries(event: React.MouseEvent) {
      // prevent row click from getting triggered
      event.stopPropagation();
      event.preventDefault();

      onOpenEntries(member.id, date);
    }

    return (
      <div className="date-cell">
        <IconButton svg={TimeCardIcon} onClick={(event) => handleOpenEntries(event)} tooltipLabel={t('Entries')} />
        <Label className="ml-8">{date.toFormat('ccc, LLL d')}</Label>
      </div>
    );
  }

  function getLastSignature(row: SignOffReportItem) {
    return row.signatures && row.signatures.length > 0 ? row.signatures[row.signatures.length - 1] : undefined;
  }

  function renderSubmitted(row: SignOffReportItem, _col: ITableColumn<SignOffReportItem>): ReactNode {
    const lastSignature = getLastSignature(row);

    if (lastSignature) {
      const date = DateTime.fromISO(lastSignature.createdOnLocal);
      return <Label>{date.toFormat('t D')}</Label>;
    }
    return <Label>---</Label>;
  }

  function renderDeviceType(row: SignOffReportItem, _col: ITableColumn<SignOffReportItem>): ReactNode {
    const lastSignature = getLastSignature(row);

    if (lastSignature) {
      return <Label>{lastSignature.deviceType}</Label>;
    }
    return <Label>---</Label>;
  }

  function renderAnswer(row: SignOffReportItem, col: ITableColumn<SignOffReportItem>): ReactNode {
    let answer: boolean | null = null;
    let answerText = '---';
    let cellClasses = classNames({
      'sign-off-table-cell': true,
    });
    let resolved = false;
    let icon = FlagIcon;
    let showIcon = false;
    const signatures = row.signatures;

    if (signatures && signatures.length > 0) {
      switch (col.key) {
        case 'injured': {
          answer = _.some(signatures, (sig) => sig.injured === true);
          const injuredResolved =
            !answer || !signatures.some((item) => _.isNil(item.injuredResolvedOn) && item.injured === true);
          cellClasses = classNames({
            'sign-off-table-cell': true,
            'red-text': answer === true && !injuredResolved,
          });
          resolved = answer === true && injuredResolved;
          icon = SafetyIcon;
          showIcon = answer === true;
          break;
        }
        case 'timeAccurate': {
          answer = !_.some(signatures, (sig) => sig.timeAccurate === false);
          const timeAccurateResolved =
            answer || !signatures.some((item) => _.isNil(item.timeAccurateResolvedOn) && item.timeAccurate === false);
          cellClasses = classNames({
            'sign-off-table-cell': true,
            'red-text': answer === false && !timeAccurateResolved,
          });
          resolved = answer === false && timeAccurateResolved;
          showIcon = answer === false;
          break;
        }
        case 'breakPolicyFollowed': {
          answer = !_.some(signatures, (sig) => sig.breakPolicyFollowed === false);
          const breakPolicyresolved =
            answer ||
            !signatures.some(
              (item) => _.isNil(item.breakPolicyFollowedResolvedOn) && item.breakPolicyFollowed === false
            );
          cellClasses = classNames({
            'sign-off-table-cell': true,
            'red-text': answer === false && !breakPolicyresolved,
          });
          resolved = answer === false && breakPolicyresolved;
          showIcon = answer === false;
          break;
        }
      }

      if (answer === true) {
        answerText = t('Yes');
      } else if (answer === false) {
        answerText = t('No');
      }
    }

    return (
      <Label className={cellClasses}>
        <span>{answerText}</span>
        {showIcon && <Icon svg={icon} className={resolved ? 'resolved' : ''} />}
      </Label>
    );
  }

  function renderAccountName(row: SignOffReportItem, _col: ITableColumn<SignOffReportItem>): ReactNode {
    const lastSignature = getLastSignature(row);
    return <Label>{lastSignature ? fullName(lastSignature.proxyMember) : '---'}</Label>;
  }

  function renderSignature(row: SignOffReportItem, _col: ITableColumn<SignOffReportItem>): ReactNode {
    const lastSignature = getLastSignature(row);
    if (
      !lastSignature ||
      (lastSignature.signatureUrl === null && lastSignature.memberId !== lastSignature.proxyMemberId)
    ) {
      return <Label>{t('---')}</Label>; // signature required but missing
    } else if (
      lastSignature &&
      lastSignature.signatureUrl === null &&
      lastSignature.memberId === lastSignature.proxyMemberId
    ) {
      return <Label>{t('N/A')}</Label>; // signature not needed
    }

    return <Image src={lastSignature!.signatureUrl!} alt="signature" />;
  }

  function renderNotes(row: SignOffReportItem, _col: ITableColumn<SignOffReportItem>): ReactNode {
    let timeIncorrectDescription = null;
    let injuredDescription = null;
    let breakNotFollowedDescription = null;
    const customDescriptions: Array<ReactNode> = [];

    row.signatures?.forEach((sig) => {
      if (sig.timeAccurateReason && sig.timeAccurateReason.trim().length > 0) {
        timeIncorrectDescription = (
          <div>
            <Label className="notes-label m-0">{t('Why was your time incorrect?')}</Label>
            <p className="m-0">{sig.timeAccurateReason}</p>
          </div>
        );
      }

      if (sig.injuryDescription && sig.injuryDescription.trim().length > 0) {
        injuredDescription = (
          <div>
            <Label className="notes-label m-0">{t('Describe your injury')}</Label>
            <p className="m-0">{sig.injuryDescription}</p>
          </div>
        );
      }

      if (sig.breakPolicyFollowedReason && sig.breakPolicyFollowedReason.trim().length > 0) {
        breakNotFollowedDescription = (
          <div>
            <Label className="notes-label m-0">{t("Why didn't you take your breaks?")}</Label>
            <p className="m-0">{sig.breakPolicyFollowedReason}</p>
          </div>
        );
      }

      sig.customAnswers.forEach((answer) => {
        if (!isNil(answer.description)) {
          customDescriptions.push(
            <div>
              <Label className="notes-label m-0">{answer.questionPrompt}</Label>
              <p className="m-0">{answer.description ?? '---'}</p>
            </div>
          );
        }
      });
    });

    return (
      <div>
        {timeIncorrectDescription ? timeIncorrectDescription : null}
        {injuredDescription ? injuredDescription : null}
        {breakNotFollowedDescription ? breakNotFollowedDescription : null}
        {customDescriptions.map((node) => (
          <>{node}</>
        ))}
      </div>
    );
  }

  const renderCustomAnswer = (
    row: SignOffReportItem,
    col: ITableColumn<SignOffReportItem>,
    header: IDailySignOffReportColumnHeader
  ) => {
    const customAnswers: Array<IMemberSignOffAnswer> = [];

    if (!isNil(row.signatures)) {
      row.signatures.forEach((signature) => {
        const mutableCustomAnswers = [...signature.customAnswers];
        mutableCustomAnswers
          .sort((a, b) => (a.createdOn > b.createdOn ? 1 : b.createdOn > a.createdOn ? -1 : 0))
          .forEach((answer, index) => {
            if (index + 1 > customAnswers.length) {
              customAnswers.push(answer);
            } else if (answer.flagged) {
              customAnswers[index] = answer;
            }
          });
      });
    }

    const answers = getAnswersForQuestion(customAnswers ?? [], header.question, allQuestions.current);

    const answer = first(answers);
    const cellClasses = classNames({
      'answer-table-cell': true,
      'red-text': answer?.flagged && isNil(answer.resolverId),
      'p-4': true,
    });
    return (
      <Label className={cellClasses}>
        {!isNil(answer) ? answer.answer : '---'}
        {answer?.flagged && <Icon svg={FlagIcon} className={!isNil(answer.resolverId) ? 'resolved' : undefined} />}
      </Label>
    );
  };

  function renderCustomColumn(
    col: ITableColumn<SignOffReportItem>,
    header: IDailySignOffReportColumnHeader
  ): ReactNode {
    return (
      <Col className={'m-2'} align="start">
        <Label className={'audience-type mb-0'}>{header.audienceType.toUpperCase()}</Label>
        {header.isDeleted ? (
          <Tooltip label={t('Question has been deleted.')}>
            <Label className={'question-title mb-0 red-text'}>{header.title}</Label>
          </Tooltip>
        ) : (
          <>
            {header.links ? (
              <Tooltip label={header.links.toString()}>
                <Label className={'question-title mb-0'}>{header.title}</Label>
              </Tooltip>
            ) : (
              <Label className={'question-title mb-0'}>{header.title}</Label>
            )}
          </>
        )}
      </Col>
    );
  }

  function renderEmptyState(): ReactNode {
    return (
      <Row justify={Justify.CENTER} align={Align.CENTER} className="empty-sign-off-state">
        {hasDataLoaded ? t('There is no sign-off data for this date range.') : undefined}
      </Row>
    );
  }

  async function getColumns() {
    const signOffAnswerColumns = Array<ITableColumn<SignOffReportItem>>();

    // only include the columns if the setting is turned on in the organization
    if (organization.timeAccuracy) {
      signOffAnswerColumns.push({
        cell: renderAnswer,
        key: 'timeAccurate',
        sort: true,
        title: t('Time Acc.'),
        align: TextAlign.LEFT,
        size: '120px',
      });
    }
    if (organization.breakPolicy) {
      signOffAnswerColumns.push({
        cell: renderAnswer,
        key: 'breakPolicyFollowed',
        sort: true,
        title: t('Break Comp.'),
        align: TextAlign.LEFT,
        size: '130px',
      });
    }
    if (organization.safetySignature) {
      signOffAnswerColumns.push({
        cell: renderAnswer,
        key: 'injured',
        sort: true,
        title: t('Injured'),
        align: TextAlign.LEFT,
        size: '120px',
      });
    }

    if (isCustomSignOffEnabled) {
      try {
        const activeCustomQuestions = await getOrganizationSignOffQuestions({ deletedOn: { isNull: true } });
        const deletedCustomQuestions = await getOrganizationSignOffQuestions({
          deletedOn: timeRange
            ? { between: { start: timeRange.startTime.toISO(), end: timeRange.endTime.toISO() } }
            : undefined,
        });
        allQuestions.current = activeCustomQuestions.concat(deletedCustomQuestions);

        const promises = allQuestions.current.map(async (question) => {
          const audienceType = await getAudienceTitle(question, getPosition);
          const titleAndLink = getQuestionTitleAndLinks(question);
          const header: IDailySignOffReportColumnHeader = {
            id: question.id,
            title: titleAndLink.title,
            audienceType,
            isDeleted: !isNil(question.deletedOn),
            question,
            links: titleAndLink.links,
          };
          return header;
        });
        const headerItems = uniqBy(await Promise.all(promises), (i) => i.title);

        headerItems.forEach((item) => {
          signOffAnswerColumns.push({
            key: item.id,
            title: item.title,
            sort: false,
            header: (col: ITableColumn<SignOffReportItem>) => renderCustomColumn(col, item),
            cell: (row: SignOffReportItem, col: ITableColumn<SignOffReportItem>) => renderCustomAnswer(row, col, item),
            size: '150px',
            headerTextWrap: true,
          });
        });
      } catch (error) {
        toastOpenable({ label: t('An unknown error occurred. Please try again later.'), theme: Theme.DANGER });
      }
    }

    setColumns([
      {
        cell: renderDateColumn,
        key: 'startTime',
        sort: true,
        title: t('Date'),
        align: TextAlign.CENTER,
        size: '180px',
      },
      ...signOffAnswerColumns,
      {
        cell: renderNotes,
        key: 'notes',
        sort: true,
        title: t('Employee Notes'),
        align: TextAlign.LEFT,
        size: '400px',
        cellClassName: 'employee-notes align-left p-4',
      },
      {
        cell: renderSignature,
        key: 'signatureUrl',
        sort: true,
        title: t('Signature'),
        align: TextAlign.LEFT,
        size: '200px',
      },
      {
        cell: renderSubmitted,
        key: 'createdOnLocal',
        sort: true,
        title: t('Submitted'),
        align: TextAlign.LEFT,
        size: '200px',
      },
      {
        cell: renderDeviceType,
        key: 'deviceType',
        sort: true,
        title: t('Device'),
        align: TextAlign.LEFT,
        size: '200px',
      },
      {
        cell: renderAccountName,
        key: 'accountName',
        sort: true,
        title: t('Account'),
        align: TextAlign.LEFT,
        size: '200px',
      },
    ]);
  }

  function handleRowClick(row: SignOffReportItem) {
    onOpenDetails(member, row.date, refreshData);
  }

  return (
    <Table
      className={classes}
      cols={columns}
      data={sorted}
      sortDir={sortDirection}
      sortBy={sortedBy}
      strokeCols={true}
      onSort={onSort}
      sortIsDirty={sortIsDirty}
      header="standard"
      emptyTemplate={renderEmptyState()}
      scroller={scroller}
      onRowClick={handleRowClick}
    />
  );
};

export default DailySignOffReportTable;
