import { useLoader } from 'contexts/LoaderContext';
import { useToastOpen } from 'contexts/ToastContext';
import useTimeOff from 'hooks/models/time-off/useTimeOff';
import { getDayRanges } from 'utils/dateUtils';
import { t } from 'utils/localize';
import CreateTimeOffForm, { ICreateTimeOffFormProps, ITimeOffFormData } from '../CreateTimeOffForm/CreateTimeOffForm';
import './CreateTimeOffFormContainer.scss';

export default function CreateTimeOffFormContainer({ className, formData, onSubmit }: ICreateTimeOffFormProps) {
  const { createTimeOff } = useTimeOff();
  const { open, close } = useLoader();
  const openToast = useToastOpen();

  async function onSubmitTimeOff(data: ITimeOffFormData) {
    open(t('Saving...'));
    try {
      const dates = !data.endDate ? [data.startDate.value!] : getDayRanges(data.startDate.value!, data.endDate!.value!);
      await createTimeOff(
        dates,
        data.members,
        data.description ? data.description : null,
        data.total ? data.total : null,
        data.paid,
        data.type!
      );
      onSubmit(data);
    } catch (error) {
      openToast({ label: t('There was an error creating the time off entry.') });
    } finally {
      close();
    }
  }

  return <CreateTimeOffForm className={className} formData={formData} onSubmit={onSubmitTimeOff} />;
}
