import { useApolloClient } from '@apollo/client';
import { ISortPayload, SortDirection } from '@busybusy/webapp-react-ui';
import { LaborMetricsInterval } from '__generated__/graphql';
import { MEMBERS_WITH_ALL_JOINED_DATA_QUERY } from 'apollo/queries/member-queries';
import useTimesheetsArchivedStatusComparison from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useTimesheetsArchivedStatusComparison';
import { hasTimeOffColumn } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useApolloPaging, useOrganization } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import _, { first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { updateCheckedMemberIds } from 'store/timesheets/Timesheets';
import { IMember } from 'types';
import ICursorable from 'types/Cursorable';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { filterNil } from 'utils/collectionUtils';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { getRegularMetricTimeTotals } from 'utils/jitMetricUtils';
import { fullName } from 'utils/memberUtils';
import EmployeeSummaryDataContext, { IEmployeeSummaryRowInfo } from './EmployeeSummaryDataContext';
import { IMemberSignInSubmission } from 'types/MemberSignInSubmission';
import { SIMPLE_SUBMISSIONS_REPORT_QUERY } from 'apollo/queries/member-sign-in-submission';
import ISafetySignature from 'types/SafetySignature';

interface IEmployeeSummaryDataContextProviderProps {
  memberId?: string | null;
  memberGroupId?: string | null;
  positionId?: string | null;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  children: ReactNode;
}

function EmployeeSummaryDataContextProvider({
  positionId,
  memberId,
  memberGroupId,
  timeRange,
  timeRangeType,
  children,
}: IEmployeeSummaryDataContextProviderProps) {
  const client = useApolloClient();
  const organization = useOrganization();
  const canBeSigned =
    timeRangeType === TimeRangeType.PAY_PERIOD &&
    organization.signatureDate &&
    dateTimeFromISOWithoutZone(organization.signatureDate!) <= timeRange.startTime;
  const columnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.summaryAndTimeCardTableColumns
  );
  const [data, setData] = useState<IEmployeeSummaryRowInfo[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const dispatch = useDispatch();
  const { getAll } = useApolloPaging();
  const getSortWithNameFormat = useApolloMemberNameSort();
  const archivedOn = useTimesheetsArchivedStatusComparison();

  const sortBy = useRef<keyof IEmployeeSummaryRowInfo>('member');
  const sortIsDirty = useRef<boolean>(false);
  const sortDir = useRef<SortDirection>(SortDirection.ASCENDING);

  useEffect(() => {
    clearData();
  }, [timeRange, memberId, memberGroupId, positionId, archivedOn, columnSettings, getSortWithNameFormat]);

  const querySubmissions = async (memberId: string) => {
    const memberSignInSubmissionQuery = await client.query<{ memberSignInSubmissions: IMemberSignInSubmission[] }>({
      query: SIMPLE_SUBMISSIONS_REPORT_QUERY,
      variables: {
        filter: {
          memberId: {
            equal: memberId
          },
          localTime: {
            lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            greaterThanOrEqual: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          flagged: {
            equal: true
          },
          deletedOn: {
            isNull: true
          }
        }
      }
    });

    return memberSignInSubmissionQuery.data.memberSignInSubmissions;
  }

  async function createSummaryRowInfo(member: IMember, submissions: IMemberSignInSubmission[]): Promise<IEmployeeSummaryRowInfo> {
    const signOffItems = member.safetySignatures;
    const memberTimeDocument = _.first(member.memberTimeDocuments);
    let injured: boolean | null = null; // default to null for the case that it hasn't been answered
    let timeAccurate: boolean | null = null;
    let breakPolicyFollowed: boolean | null = null;
    let payPeriodEmployeeSigned: boolean | null = null;
    let payPeriodSupervisorSigned: boolean | null = null;

    const signOffByDay = _.groupBy(signOffItems, (signOff) => {
      dateTimeFromISOWithoutZone(signOff.startTime).startOf('day');
    });

    // check if the latest answer for each day reports time as inaccurate or that the break policy was not followed
    _.forEach(signOffByDay, (items) => {
      const latestForDay = _.maxBy(items, (item) => dateTimeFromISOWithoutZone(item.endTime));

      if (latestForDay) {
        // set time accurate if it hasn't been set yet, otherwise we only change the value if its marked as not accurate
        if (timeAccurate === null) {
          timeAccurate = latestForDay.timeAccurate;
        } else if (latestForDay.timeAccurate === false) {
          timeAccurate = false;
        }

        // set break policy followed if it hasn't been set yet, otherwise we only change the value if its marked as not followed
        if (breakPolicyFollowed === null) {
          breakPolicyFollowed = latestForDay.breakPolicyFollowed;
        } else if (latestForDay.breakPolicyFollowed === false) {
          breakPolicyFollowed = false;
        }
      }
    });

    // NOTE: `injured = _.some(signOffItems, (item) => item.injured)` doesn't work because we want to leave `injured` as null if there is no answer for the sign off
    if (_.some(signOffItems, (item) => item.injured === true)) {
      // there is at least 1 report of an injury
      injured = true;
    } else if (_.some(signOffItems, (item) => item.injured === false)) {
      // there are no injuries and there has been at least 1 time that the user reported that they were not injured
      injured = false;
    }

    const needsToResign = memberTimeDocument && (memberTimeDocument.canceled || memberTimeDocument.edited);

    // check if the pay period has been signed by employee
    if (memberTimeDocument && memberTimeDocument.selfSignature && !needsToResign) {
      payPeriodEmployeeSigned = true;
    } else if (canBeSigned) {
      // we only set pay period as not signed if it can be signed, otherwise it stays null
      payPeriodEmployeeSigned = false;
    }

    // check if the pay period has been signed by supervisor
    if (memberTimeDocument && memberTimeDocument.authoritativeSignature && !needsToResign) {
      payPeriodSupervisorSigned = true;
    } else if (canBeSigned) {
      // we only set pay period as not signed if it can be signed, otherwise it stays null
      payPeriodSupervisorSigned = false;
    }

    const timeMetric = getRegularMetricTimeTotals(_.first(member.memberLaborMetrics));
    const hasTimeEntryTime = (timeMetric?.totalSeconds ?? 0) > 0;

    // we need to subtract paid time off from regular seconds, so its not counted twice
    // we also need to guard against negative values just in case the aggregate is off
    let regularSeconds = timeMetric.regularSeconds ?? 0;
    if (!hasTimeOffColumn(columnSettings) && timeMetric) {
      const updatedRegularTotal = timeMetric.regularSeconds + timeMetric.paidTimeOffSeconds;
      regularSeconds = updatedRegularTotal > 0 ? updatedRegularTotal : 0;
    }

    const flaggedSignIns = submissions.filter((submission) => submission.flagged)

    const flaggedSignOffs = member.safetySignatures?.filter((signature) => (
      !signature.breakPolicyFollowed ||
      !signature.timeAccurate ||
      signature.injured ||
      signature.customQuestionsFlagged
    ));

    function countFlaggedSignOffs(signOffs: ISafetySignature[]): number {
      return signOffs.reduce((count, signature) => {
        if (!signature.breakPolicyFollowed) {
          count ++;
        }
        if (!signature.timeAccurate) {
          count++;
        }
        if (signature.injured) {
          count++;
        }
        if (signature.customQuestionsFlagged) {
          count++;
        }
        return count
      }, 0);
    }

    return {
      id: member.id,
      member,
      payPeriodEmployeeSigned,
      payPeriodSupervisorSigned,
      timeAccurate: hasTimeEntryTime ? timeAccurate : null,
      injured: hasTimeEntryTime ? injured : null,
      breakPolicyFollowed: hasTimeEntryTime ? breakPolicyFollowed : null,
      regularSeconds: timeMetric ? regularSeconds : null,
      overtimeSeconds: timeMetric ? timeMetric.overtimeSeconds : null,
      doubletimeSeconds: timeMetric ? timeMetric.doubleTimeSeconds : null,
      ptoSeconds: timeMetric ? timeMetric.paidTimeOffSeconds : null,
      breakSeconds: null,
      totalSeconds: timeMetric ? timeMetric.totalSeconds : null,
      flaggedSignInAmount: flaggedSignIns ? flaggedSignIns.length : null,
      flaggedSignOffAmount: flaggedSignOffs ? countFlaggedSignOffs(flaggedSignOffs) : null,
      cursor: member.cursor,
    };
  }

  const didLoad = (updatedMembers: IEmployeeSummaryRowInfo[], err: boolean, updatedLoadedAll: boolean) => {
    if (!err) {
      setLoadedAll(updatedLoadedAll);
      setData(updatedMembers);
    } else {
      // Don't continue loading in the event of an error, cursor will keep being the same resulting in looping
      setLoadedAll(true);
    }
  };

  function handleSort(sort: ISortPayload<IMember>) {
    sortBy.current = sort.sortBy as any;
    sortDir.current = sort.sortDir;
    sortIsDirty.current = true;
    setData([]);
    setLoadedAll(false);
  }

  async function getMoreMembers(
    timeRange: ITimeRange<DateTime>,
    first: number,
    cursor?: string
  ): Promise<IEmployeeSummaryRowInfo[]> {
    const membersQuery = await client.query<{ members: IMember[] }>({
      query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
      variables: memberQueryVariables(timeRange, first, cursor),
      fetchPolicy: 'network-only',
    });

    return Promise.all(membersQuery.data.members.map(async (member) => {
      const submissions = await querySubmissions(member.id)
      return createSummaryRowInfo(member, submissions)
    }));
  }

  function memberQueryVariables(timeRange: ITimeRange<DateTime>, first: number, cursor?: string) {
    const sort = getSortWithNameFormat(sortDir.current.toLowerCase() as 'asc' | 'desc');

    const variables: any = {
      first,
      after: cursor ?? undefined,
      filter: {
        permissions: {
          permissions: MemberPermission.MANAGE_TIME_ENTRIES,
          operationType: 'and',
        },
        memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
        positionId: positionId ? { equal: positionId } : undefined,
        id: memberId ? { equal: memberId } : undefined,
        archivedOn,
      },
      sort,
    };

    // if we are not looking at a single member, then we need to filter our members by the ones with time entries or PTO
    if (_.isNil(memberId)) {
      variables.filter.hasTime = {
        startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        paidTimeOff: true,
      };
    }

    variables.metricsInterval = LaborMetricsInterval.Custom;
    variables.metricsStartDate = timeRange.startTime.toUTC().toISODate();
    variables.metricsEndDate = timeRange.endTime.toUTC().toISODate();

    variables.safetySignaturesFilter = {
      startTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
      endTime: { lessThanOrEqual: timeRange.endTime.toISO() },
      deletedOn: { isNull: true },
      member: {
        permissions: {
          permissions: MemberPermission.MANAGE_TIME_ENTRIES,
          operationType: OperationType.AND,
        },
      },
    };
    variables.safetySignaturesSort = [{ startTime: 'asc' }, { createdOn: 'asc' }];

    variables.memberTimeDocumentFilter = {
      startTime: { equal: timeRange.startTime.toISO() },
      endTime: { equal: timeRange.endTime.toISO() },
      deletedOn: { isNull: true },
    };
    variables.memberTimeDocumentSort = [{ submittedOn: 'desc' }];

    return variables;
  }

  async function onCheckAll(checked: boolean) {
    // Component handles checking when the data is loaded and we only need to load when checked
    if (checked) {
      const rows = await forceLoadAll();
      const allMembers = rows.filter((r) => isNil(r.member.archivedOn)).map((r) => r.member.id);
      dispatch(updateCheckedMemberIds(allMembers));
    }
  }

  async function refreshDataForMembers(memberIds: string[]) {
    let members = data.map((item) => item.member);
    const missingMemberIds = memberIds.filter((memberId) => !members.some((member) => member.id === memberId));

    // if we need to update members that weren't loaded in the list
    // (either they didn't have any time or they haven't been scrolled to)
    // then we will load those members and add them to our list
    if (!_.isEmpty(missingMemberIds)) {
      const variables = memberQueryVariables(timeRange, missingMemberIds.length);
      // we're adding this filter separately because we want to also filter by hasTime
      // in case content was created outside of our visible time range
      variables.filter.id = { contains: missingMemberIds };

      const retrievedMembersQuery = await client.query<{ members: IMember[] }>({
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        fetchPolicy: 'network-only',
        variables,
      });

      members = _.orderBy(members.concat(retrievedMembersQuery.data.members), (member) => fullName(member), [
        sortDir.current === SortDirection.ASCENDING ? 'asc' : 'desc',
      ]);

      // if we haven't loaded everything yet, then we want to remove the members that will be loaded later through scrolling
      if (!_.isEmpty(data) && loadedAll === false) {
        const lastLoadedMember = _.last(data)!.member;
        const indexOfLast = _.findIndex(members, (member) => member.id === lastLoadedMember.id);
        members = members.splice(0, indexOfLast + 1);
      }
    }

    const dataResults = await Promise.all(
      members.map(async (member) => {
        if (_.some(memberIds, (id) => id === member.id)) {
          const variables = memberQueryVariables(timeRange, missingMemberIds.length);
          variables.filter.id = { equal: member.id };
          const retrievedMembersQuery = await client.query<{ members: IMember[] }>({
            query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
            fetchPolicy: 'network-only',
            variables,
          });

          const updatedMember = first(retrievedMembersQuery.data.members);
          if (updatedMember) {
            const submissions = await querySubmissions(updatedMember.id)
            return await createSummaryRowInfo(updatedMember, submissions);
          }
          return data.find((item) => item.member.id === member.id);
        } else {
          return data.find((item) => item.member.id === member.id);
        }
      })
    );

    setData(filterNil(dataResults));
  }

  async function forceLoadAll() {
    const currentMembers = data.map((item) => item.member);
    const lastCursor = _.last(currentMembers)?.cursor ?? '';
    try {
      const remainingMembers = await getAll<IMember & ICursorable>('members', {
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        variables: memberQueryVariables(timeRange, 500, lastCursor),
        fetchPolicy: 'network-only',
      });
      const remainingRows = await Promise.all(remainingMembers.map(async (member) => {
        const submissions = await querySubmissions(member.id)
        return createSummaryRowInfo(member, submissions)
      }));
      const allRows = [...data, ...remainingRows];
      setData(allRows);
      return allRows;
    } catch (error) {
      setData([]);
      setLoadedAll(true);
      return [];
    }
  }

  function clearData() {
    setData([]);
    setLoadedAll(false);
  }

  const value = useMemo(
    () => ({
      data,
      loadedAll,
      getMoreMembers,
      onCheckAll,
      handleSort,
      didLoad,
      refreshDataForMembers,
      sortBy: sortBy.current,
      sortDir: sortDir.current,
      sortIsDirty: sortIsDirty.current,
      forceLoadAll,
      clearData,
    }),
    [data, loadedAll, sortBy, sortDir, sortIsDirty, getSortWithNameFormat]
  );

  return <EmployeeSummaryDataContext.Provider value={value}>{children}</EmployeeSummaryDataContext.Provider>;
}

export default EmployeeSummaryDataContextProvider;
