import _, { compact, groupBy, isArray, isEmpty, isNil, keyBy, map, reduce, sortBy, sumBy } from 'lodash';
import { KeysOfType } from 'types/util/KeysOfType';
import { AnyObject } from 'types/util/Object';
import { Optional } from 'types/util/Optional';
import IIdable from '../types/Idable';
import { remainingDataItemId } from './constants/utilConstants';
import { getValueOrExecute } from './functionUtils';
import { getSafeNumber, sumKeys } from './numberUtils';

export const toggleSelection = <T>(data: readonly T[], item: T) => {
  if (_.some(data, (dataItem) => dataItem === item)) {
    // remove the item because it is already in the list
    return data.filter((dataItem) => dataItem !== item);
  } else {
    // add the item because it isn't there
    return [...data, item];
  }
};

export const toggleItemInCollection = <T extends IIdable<any>>(data: readonly T[], item: T, added: boolean) => {
  if (added) {
    if (_.some(data, (idable) => idable.id === item.id)) {
      return data;
    } else {
      return [...data, item];
    }
  } else {
    return data.filter((idable) => idable.id !== item.id);
  }
};

export function isEqualIdableList<T extends IIdable<any>>(first: readonly T[], second: readonly T[]) {
  return first.length === second.length && _.xorWith(first, second, (a: T, b: T) => a.id === b.id).length === 0;
}

export function mapNotNull<T, R>(items: readonly T[], transform: (item: T, index: number) => R | null): R[] {
  return mapWithPredicate(items, transform, (item) => item !== null) as R[];
}

export function mapNotNil<T, R>(items: readonly T[], transform: (item: T, index: number) => R | null | undefined): R[] {
  return mapWithPredicate(items, transform, (item) => !isNil(item)) as R[];
}

export function mapWithPredicate<T, R>(
  items: readonly T[],
  transform: (item: T, index: number) => R,
  predicate: (item: R) => boolean
) {
  const newItems: R[] = [];

  items.forEach((item, index) => {
    const result = transform(item, index);
    if (predicate(result)) {
      newItems.push(result);
    }
  });

  return newItems;
}

export function idInList<T extends IIdable<K>, K>(id: K, items: readonly T[]) {
  return items.some((item) => item.id === id);
}

export function findItemIdFromList<T extends IIdable<K>, K>(id: K, items: readonly T[]) {
  return items.find((item) => item.id === id) ?? null;
}

export function takeWithPopulate<T>(items: T[], count: number, populateValue: T | (() => T)) {
  const lengthDifference = count - items.length;

  if (lengthDifference <= 0) {
    return _.take(items, count);
  }

  const newList = _.cloneDeep(items);
  _.times(lengthDifference, () => {
    newList.push(_.cloneDeep(getValueOrExecute(populateValue)));
  });

  return newList;
}

export function filterNil<T>(items: Array<T | null | undefined>): T[] {
  return items.filter((item) => !_.isNil(item)) as T[];
}

export function uniqueFilter<T, Element>(collection: readonly Element[], map: (value: Element) => T): Element[] {
  const newSet = new Set<T>();
  const arrayOrdered: Element[] = [];
  collection.forEach((e) => {
    const newValue = map(e);
    if (!newSet.has(newValue)) {
      newSet.add(newValue);
      arrayOrdered.push(e);
    }
  });

  return arrayOrdered;
}

export function isNilOrEmpty(collection?: readonly any[] | null) {
  return isNil(collection) || isEmpty(collection);
}

/**
 * Collection is either: undefined, null, empty, or only containing the id for the remaining data item eg. "No Project"
 * @param collection collection of strings
 * @returns true if the collection matches the above description.
 */
export function isNilOrEmptyOrOnlyHasRemainingItem(collection?: readonly string[] | null) {
  return isNil(collection) || isEmpty(collection) || (collection.length === 1 && collection[0] === remainingDataItemId);
}

/**
 * Checks if the array only contains the remaining item id.
 *
 * @param collection collection of strings
 * @returns true if the collection matches the above description.
 */
export function onlyHasRemainingItem(collection?: readonly string[] | null) {
  return collection && collection.length === 1 && collection[0] === remainingDataItemId;
}

/**
 *
 * @param defaults Values from this will be used if `override` does not contain value
 * @param override Values from this will be used if exists
 * @param key key to identify uniqueness *must be unique within the list*
 * @param overrideKeys keys to merge through the defaults
 */
export function mergeListsWithOverride<R extends T, T extends AnyObject, O extends AnyObject>(
  defaults: readonly T[],
  override: readonly O[],
  key: keyof T & keyof O,
  overrideKeys: Array<keyof O>
): R[] {
  return defaults.map((defaultItem) => {
    const overrideItem = override.find((mergeItem) => mergeItem[key] === (defaultItem as any)[key]);
    if (!overrideItem) {
      return defaultItem;
    }

    const merged = overrideKeys.reduce((acc, cur) => ({ ...acc, [cur]: overrideItem[cur] }), {});

    return { ...defaultItem, ...merged };
  }) as R[];
}

export function mergeListsWithOverrideBase<
  R extends T,
  T extends AnyObject = AnyObject,
  O extends AnyObject = AnyObject
>(override: readonly O[], defaults: readonly T[], key: keyof T & keyof O, overrideKeys: Array<keyof O>): R[] {
  const keyedDefaults = keyBy(defaults, key);

  return mapNotNull(override, (overrideItem) => {
    const overrideKey = overrideItem[key];
    const defaultItem = keyedDefaults[overrideKey as any];

    if (!defaultItem) {
      return null;
    }

    const merged = overrideKeys.reduce((acc, cur) => ({ ...acc, [cur]: overrideItem[cur] }), {});

    return { ...defaultItem, ...merged };
  }) as R[];
}

export function addDefaultsIfMissing<T>(items: readonly T[], defaults: readonly T[], key: keyof T) {
  const itemsMap = keyBy(items, key);

  const missingItems = reduce(
    defaults,
    (acc, cur) => {
      const identifier = `${cur[key]}`;
      if (itemsMap[identifier]) {
        return acc;
      }

      return [...acc, cur];
    },
    [] as T[]
  );

  return [...items, ...missingItems];
}

/**
 *
 * @param list list of items
 * @param id id of the item to update
 * @param getUpdatedValue callback of item to be updated
 * @returns new list with the item that has the given id updated via getUpdatedValue
 */
export function updateIdableInList<T extends IIdable<K>, K>(
  list: readonly T[],
  id: K,
  getUpdatedValue: (prev: T) => T
): T[] {
  return updateItemInList(list, id, getUpdatedValue, 'id');
}

/**
 *
 * @param list list of items
 * @param id id of the item to update
 * @param getUpdatedValue callback of item to be updated
 * @param key the key of the item or items to replace
 * @returns new list with the item that has the given id updated via getUpdatedValue
 */
export function updateItemInList<T, K extends keyof T>(
  list: readonly T[],
  id: T[K],
  getUpdatedValue: (prev: T) => T,
  key: K
): T[] {
  return updateItemInListWithSelector(list, (item) => item[key] === id, getUpdatedValue);
}

export function updateItemInListWithSelector<T>(
  list: readonly T[],
  selector: (item: T) => boolean,
  getUpdatedValue: (prev: T) => T
): T[] {
  return list.map((item) => (selector(item) ? getUpdatedValue(item) : item));
}

export function containsDuplicateOfKey<T, K extends keyof T>(list: readonly T[], key: K) {
  return new Set(map(list, key)).size !== list.length;
}

export function containsDuplicateOfGivenKey<T, K extends keyof T>(list: readonly T[], key: K, value: T[K]) {
  const grouped = groupBy(list, key);
  return grouped[`${value}`]?.length > 1;
}

export function shallowCompare<T extends IIdable<any>>(obj1: readonly T[], obj2: readonly T[]) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (let i = 0; i < Object.keys(obj1).length; i++) {
    if (obj1[i] !== obj2[i]) {
      return false;
    }
  }
  return true;
}

export function intersectKeysInList<T, K extends keyof T>(items: readonly T[], keys: Array<T[K]>, key: K) {
  const set = new Set(keys);
  return items.filter((item) => set.has(item[key]));
}

export function intersectIdablesInList<T extends IIdable<K>, K>(items: readonly T[], keys: K[]) {
  return intersectKeysInList(items, keys, 'id');
}

export function filterKeysFromList<T, K extends keyof T>(items: readonly T[], keys: Array<T[K]>, key: K) {
  const set = new Set(keys);
  return items.filter((item) => !set.has(item[key]));
}

export function filterIdablesFromList<T extends IIdable<K>, K>(items: readonly T[], keys: K[]) {
  return filterKeysFromList(items, keys, 'id');
}

/** create an array containing `length` copies of `obj` */
export function duplicateObject<T>(obj: T, length: number): T[] {
  return Array.from({ length }).map(() => ({ ...obj }));
}

/** create a flattened array containing `length` copies of `arr` */
export function duplicateArray<T>(arr: readonly T[], length: number, defaultValue: T[] = []): T[] {
  return Array.from({ length }).reduce((acc: T[]) => acc.concat(...arr.map((item) => ({ ...item }))), defaultValue);
}

export function sortIgnoreCaseSelector<T>(arr: readonly T[], selector: (value: T) => string) {
  return sortBy(arr, (value) => {
    const sortValue = selector(value);
    return sortValue.toLocaleLowerCase();
  });
}

export function sortIgnoreCase(arr: readonly string[]) {
  return sortIgnoreCaseSelector(arr, (v) => v);
}

export function compactFlatMap<T, R>(
  collection: readonly T[],
  transform: (value: T) => Optional<R | Array<Optional<R>>>
): Array<R> {
  return collection.reduce((acc, cur) => {
    const transformed = transform(cur);
    if (isArray(transformed)) {
      acc.push(...compact(transformed));
    } else if (transformed) {
      acc.push(transformed);
    }

    return acc;
  }, new Array<R>());
}

export function sumByKeys<T extends AnyObject>(
  collection: readonly T[],
  ...keys: Readonly<Array<KeysOfType<T, Optional<number>>>>
) {
  return sumBy(collection, (col) => sumKeys(col, ...keys));
}

export function sumByKey<T extends AnyObject>(collection: readonly T[], key: KeysOfType<T, Optional<number>>) {
  return sumBy(collection, (col) => getSafeNumber(col[key]));
}

export function sortByIgnoreCase<T>(data: T[], transform: (item: T) => string): T[] {
  return sortBy(data, (item) => transform(item).toLowerCase());
}

export function mapToSet<T, R>(items: T[], transform: (value: T) => R): Set<R> {
  const set = new Set<R>();

  items.forEach((item) => {
    const transformed = transform(item);
    if (!set.has(transformed)) {
      set.add(transformed);
    }
  });

  return set;
}

// like lodash's keyBy, but returns a Map instead of a Dict
export function mapByKey<V, K extends keyof V>(
  collection: V[] | null | undefined,
  key: K,
  options = { throwOnDuplicate: true }
): Map<V[K], V> {
  const emptyMap = new Map<V[K], V>();

  // quick return on empty collection
  if (isNil(collection) || isEmpty(collection)) return emptyMap;

  return collection.reduce((acc, item) => {
    const keyValue = item[key];

    if (options.throwOnDuplicate && acc.has(keyValue)) throw new Error(`Duplicate key: ${keyValue}`);

    return acc.set(keyValue, item);
  }, emptyMap);
}
