import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useDashboardXAxisDefaults from '../hooks/useDashboardXAxisDefaults';
import useDashboardYAxisFormatter from '../hooks/useDashboardYAxisFormatter';
import useTooltipFormatter from '../hooks/useTooltipFormatter';
import { IDashboardChartPayload, IDashboardChartPayloadType } from '../types/types';
import {
  getDefaultDashboardAreaProps,
  getDefaultDashboardCartesianGridProps,
  getDefaultDashboardYAxisProps,
  getDefaultTooltipProps,
} from '../utils/utils';

type Key<T> = Omit<keyof T, 'day'> & string;

export interface IDashboardCardAreaChartProps<T extends IDashboardChartPayload, K extends Key<T>> {
  data: T[];
  dataKey: K;
  theme: Theme.DARK | Theme.LIGHT;
  timeFrame: DashboardTimeFrame;
  valueType: IDashboardChartPayloadType;
  className?: ClassName;
}

function DashboardCardAreaChart<T extends IDashboardChartPayload, K extends Key<T>>(
  props: IDashboardCardAreaChartProps<T, K>
) {
  const { data, dataKey, theme, timeFrame, valueType, className } = props;

  const { titleFormatter, valueFormatter } = useTooltipFormatter(valueType, timeFrame);

  const xAxisDefaults = useDashboardXAxisDefaults(data, timeFrame, theme);
  const yFormatter = useDashboardYAxisFormatter(valueType);

  const yAxisVisible = useMemo(() => data.some((datum) => datum[dataKey as keyof T]), [data, dataKey]);

  const classes = classNames('dashboard-card-area-chart', theme, className);

  return (
    <ResponsiveContainer width="100%" height="75%" minHeight="250px">
      <AreaChart data={data} className={classes}>
        <CartesianGrid {...getDefaultDashboardCartesianGridProps(theme)} />
        <Area dataKey={dataKey} {...getDefaultDashboardAreaProps(theme)} />
        <XAxis {...xAxisDefaults} />
        {yAxisVisible && (
          <YAxis tickFormatter={yFormatter} {...getDefaultDashboardYAxisProps(theme)} dataKey={dataKey} />
        )}

        <Tooltip
          {...getDefaultTooltipProps()}
          // Second argument is the name which we are not using
          formatter={(value) => [valueFormatter(value as number), '']}
          labelFormatter={titleFormatter as any}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default DashboardCardAreaChart;
