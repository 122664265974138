import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  equipment_id?: string;
  start_date?: string;
  end_date?: string;
  equipment_category_id?: string;
  time_range_type?: TimeRangeType;
}

export default function useMachineVsOperatorReportNavigation() {
  return useNavigation<IParams>('/machine-vs-operator-report');
}
