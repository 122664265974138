import { Button, ClassName, Label, Textarea, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import { useToastOpen } from 'contexts/ToastContext';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { useState } from 'react';
import './GpsEditDialog.scss';

export interface IGpsUnauthorizeDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (comment: string) => void;
}

const GpsUnauthorizeDialog = (props: IGpsUnauthorizeDialogProps) => {
  const { className, isOpen, onClose, onSubmit } = props;

  const [comment, setComment] = useState<string>();
  const toast = useToastOpen();

  const handleSubmit = () => {
    if (isNil(comment)) {
      toast({ label: t('Comment is required'), theme: Theme.DANGER });
    } else {
      onSubmit(comment);
      setComment('');
    }
  };

  const handleClose = () => {
    setComment('');
    onClose();
  };

  const classes = classNames('gps-edit-dialog m-5', className);

  return (
    <HeaderDialog title={t('Mark as Unauthorized')} isOpen={isOpen} onClose={handleClose}>
      <div className={classes}>
        <Label>
          {t('Comments')} {' - '}
          <span className="sub-label-text">{t('Required')}</span>
        </Label>
        <Textarea value={comment ?? ''} onChange={setComment} restrictTo={{ maxLength: 1000 }} />
        <Button type="primary" onClick={handleSubmit} className={'mt-5'}>
          {t('Submit')}
        </Button>
      </div>
    </HeaderDialog>
  );
};

export default GpsUnauthorizeDialog;
