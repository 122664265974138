import { Button, ClassName, Tray } from "@busybusy/webapp-react-ui";
import classNames from "classnames";
import { t } from "i18next";
import { FunctionComponent } from "react";
import './SafetyReportsConfirmationForm.scss';

export interface ISafetyReportsConfirmationFormProps {
    onConfirm: () => void;
    className?: ClassName;
}

export const SafetyReportsConfirmationForm: FunctionComponent<ISafetyReportsConfirmationFormProps> = (props) => {
    const { className, onConfirm } = props;

    const classes = classNames('safety-reports-confirmation-form mx-8 mb-6', className);

    return (
        <div className={classes}>
            <div>
              <div>{t('Someone will be reaching out to you shortly about getting our new safety module turned on.')}</div>
              <div className={'mt-4'}>{t('Thank you!')}</div>
            </div>
            <Tray align="right">
              <Button onClick={onConfirm} type="primary" className={'mt-4'}>{t('OK')}</Button>
            </Tray>
          </div>
    )
}