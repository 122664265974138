import { ApolloClient } from '@apollo/client';
import { VALIDATE_PROJECT_COST_CODE } from 'apollo/queries/cost-code-queries';
import ICostCode from 'types/CostCode';
import IIdable from 'types/Idable';
import { t } from 'utils/localize';

export default function v_project_cost_code(
  costCode: any,
  args: { projectField: string; allowNoCostCodeLink: boolean; client: ApolloClient<any>; message?: string },
  formData: any
) {
  return {
    message: args.message || t('Cost Code must be assigned to Project'),
    validate: async () => {
      return new Promise<boolean>((res) => {
        const project: string | IIdable<string> | undefined | null = formData[args.projectField];

        if (project) {
          if (args.allowNoCostCodeLink && !costCode) {
            res(true);
          } else if (costCode) {
            args.client
              .query<{ costCodes: ICostCode[] }>({
                query: VALIDATE_PROJECT_COST_CODE,
                variables: { projectId: project, costCodeId: costCode },
              })
              .then((currentCostCode) => {
                if (currentCostCode.data.costCodes.length) {
                  res(true);
                } else {
                  res(false);
                }
              });
          } else {
            // Has project, no cost code, and not allowing no cost code link
            res(false);
          }
        } else {
          // No project
          res(costCode ? false : true);
        }
      });
    },
  };
}
