import useNoItemData from 'components/domain/time-entry/TimeEntryDataTable/hooks/useNoItemData';
import { useTimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import { NO_EQUIPMENT_OBJECT } from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import IEquipment from 'types/Equipment';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { logError } from 'utils/testUtils';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';
import { useLazyEquipmentTimeData } from './useLazyEquipmentTimeData';

export default function useEquipmentTimeEntryDataReportData(
  scroller: HTMLElement | Document | null,
  timeRange: ITimeRange<DateTime>,
  includeOpenEntries?: boolean,
  equipmentIds?: string[],
  memberIds?: string[],
  projectIds?: string[],
  costCodeIds?: string[],
  excludeDeleted?: boolean,
  showNoItemTable: boolean = false,
  projectIdWithDescendants?: boolean
) {
  const [data, setData] = useState<ITimeEntryReportWithHeaderData[]>([]);
  const [noDataLoading, setNoDataLoading] = useState(false);
  const titleFormatter = useCallback((equipment: IEquipment) => getEquipmentDisplay(equipment), []);

  const filter = useMemo(() => {
    return {
      timeRange,
      includeOpen: includeOpenEntries,
      equipmentIds,
      memberIds,
      projectIds,
      costCodeIds,
      excludeDeleted,
      projectIdWithDescendants,
    };
  }, [
    includeOpenEntries,
    equipmentIds,
    timeRange,
    titleFormatter,
    memberIds,
    projectIds,
    costCodeIds,
    excludeDeleted,
    projectIdWithDescendants,
  ]);

  const {
    data: groupedData,
    loading: dataLoading,
    error,
    clearData,
    updateEquipmentIds,
    loadedAll,
    loadAll,
  } = useLazyEquipmentTimeData(scroller, filter);

  const getRows = useTimeEntryDataTableRow();
  const getNoItemData = useNoItemData<IEquipment>(NO_EQUIPMENT_OBJECT);

  useEffect(() => {
    async function getData() {
      let noItemData;
      try {
        if (equipmentIds?.includes(remainingDataItemId)) {
          setNoDataLoading(true);

          const noEquipmentData = await getNoItemData(
            timeRange,
            includeOpenEntries,
            memberIds,
            projectIds,
            costCodeIds,
            [remainingDataItemId],
            projectIdWithDescendants
          );
          noItemData = await getRows(timeRange, noEquipmentData, () => t('No Equipment'));

          setNoDataLoading(false);

          if (equipmentIds.length === 1) {
            setData(noItemData);
            return;
          }
        } else {
          if (isNil(equipmentIds) && showNoItemTable && loadedAll) {
            setNoDataLoading(true);

            const noEquipmentData = await getNoItemData(
              timeRange,
              includeOpenEntries,
              memberIds,
              projectIds,
              costCodeIds,
              [remainingDataItemId],
              projectIdWithDescendants
            );

            setNoDataLoading(false);

            if (
              !isEmpty(noEquipmentData) &&
              (!isEmpty(noEquipmentData[0].entries) || !isEmpty(noEquipmentData[0].timeOffs))
            ) {
              noItemData = await getRows(timeRange, noEquipmentData, () => t('No Equipment'));
            }
          }
        }
        let newData = await getRows(timeRange, groupedData, titleFormatter);
        if (noItemData) {
          newData = newData.concat(noItemData);
        }
        setData(newData);
      } catch (e) {
        logError(e);
      }
    }
    getData();
  }, [groupedData, titleFormatter, filter]);

  const loading = useMemo(() => dataLoading || noDataLoading, [dataLoading, noDataLoading]);

  return useMemo(
    () => ({ data, loading, error, clearData, updateEquipmentIds, loadedAll, loadAll }),
    [data, loading, error, clearData, updateEquipmentIds, loadedAll, loadAll]
  );
}
