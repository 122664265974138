import useGraphQLPaging from 'hooks/graphql/useGraphQLPaging/useGraphQLPaging';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ITimeRange from 'types/TimeRange';
import {
  SchedulingRecurringScheduleReminderQuery,
  SchedulingRecurringScheduleReminderQueryVariables,
} from '__generated__/graphql';
import { RECURRING_SCHEDULING_QUERY } from '../apollo/scheduling-queries';

export default function useSchedulingRecurringDataQuery(timeRange: ITimeRange<DateTime>) {
  const paging = useGraphQLPaging();

  return useCallback(
    (filter?: SchedulingRecurringScheduleReminderQueryVariables['filter']) => {
      return paging<SchedulingRecurringScheduleReminderQuery, SchedulingRecurringScheduleReminderQueryVariables>(
        {
          document: RECURRING_SCHEDULING_QUERY,
          variables: {
            filter: {
              ...filter,
              inRange: {
                startTime: timeRange.startTime.toISO(),
                endTime: timeRange.endTime.toISO(),
              },
              deletedOn: { isNull: true },
            },
          },
        },
        'recurringScheduleReminders',
        500
      );
    },
    [paging, timeRange.endTime, timeRange.startTime]
  );
}
