import { Field, FieldFooter } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import EquipmentModelForMakePicker from 'components/domain/equipment/EquipmentModelsForMakePicker/EquipmentModelsForMakePicker';

interface IEquipmentModelFormFieldProps {
  className?: ClassName;
  form: any;
  equipmentCategoryId: string | null | undefined;
  equipmentMakeId: string | null | undefined;
  name: string;
  onChange?: (value: string | null) => void;
  validations?: any;
}

function EquipmentModelFormField(props: IEquipmentModelFormFieldProps) {
  const { form, name, equipmentCategoryId, equipmentMakeId, validations, className, onChange, ...other } = props;

  const classes = classNames(
    {
      'equipment-model-picker-form-field': true,
    },
    className
  );

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: string | null) {
      fieldProps.handleChange(val, onChange);
    }

    return (
      <>
        <EquipmentModelForMakePicker
          error={fieldProps.hasError}
          value={form.state.data[name]}
          onSelect={handleOnSelect}
          equipmentCategoryId={equipmentCategoryId}
          equipmentMakeId={equipmentMakeId}
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
}

export default EquipmentModelFormField;
