import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Spinner } from 'components';
import './DashboardCardSpinner.scss';

export interface IDashboardCardSpinnerProps {
  className?: ClassName;
}

const DashboardCardSpinner = (props: IDashboardCardSpinnerProps) => {
  const { className } = props;

  const classes = classNames('dashboard-card-spinner', className);

  return (
    <div className={classes}>
      <Spinner size={'md'} />
    </div>
  );
};

export default DashboardCardSpinner;
