import { useApolloClient } from '@apollo/client';
import {} from '@busybusy/webapp-react-ui';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import { EQUIPMENT_BY_ID_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EMPLOYEE_QUERY } from 'containers/manage-employees/employees-queries';
import { projectsWithAncestorsQuery } from 'containers/manage-projects/projects-queries';
import { useQueryParams } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { first } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { IMember } from 'types';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import { QueryParam } from 'utils/constants/queryParam';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import './TimesheetPrintHeader.scss';

export interface ITimesheetPrintHeaderProps {
  className?: ClassName;
  title: string;
  timeRange: ITimeRange<DateTime>;
}

const TimesheetPrintHeader = (props: ITimesheetPrintHeaderProps) => {
  const { className, title, timeRange } = props;

  const { getParam } = useQueryParams();
  const memberId = getParam(QueryParam.EMPLOYEE_ID);
  const [member, setMember] = useState<IMember | undefined>(undefined);
  const projectId = getParam(QueryParam.PROJECT_ID);
  const [project, setProject] = useState<IProject | undefined>(undefined);
  const costCodeId = getParam(QueryParam.COST_CODE_ID);
  const [costCode, setCostCode] = useState<ICostCode | undefined>(undefined);
  const equipmentId = getParam(QueryParam.EQUIPMENT_ID);
  const [equipment, setEquipment] = useState<IEquipment | undefined>(undefined);
  const client = useApolloClient();
  const nameFormatter = useEmployeeNameFormatter();

  useEffect(() => {
    const getMember = async () => {
      if (memberId !== null) {
        const results = await client.query<{ members: IMember[] }>({
          query: EMPLOYEE_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            memberId,
          },
        });

        setMember(first(results.data.members));
      } else {
        setMember(undefined);
      }
    };

    getMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  useEffect(() => {
    const getProject = async () => {
      if (projectId !== null) {
        const results = await client.query<{ projects: IProject[] }>({
          query: projectsWithAncestorsQuery,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              id: { equal: projectId },
            },
          },
        });

        setProject(first(results.data.projects));
      } else {
        setProject(undefined);
      }
    };
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const getCostCode = async () => {
      if (costCodeId !== null) {
        const results = await client.query<{ costCodes: ICostCode[] }>({
          query: COST_CODES_QUERY,
          variables: {
            filter: {
              id: { equal: costCodeId },
            },
          },
        });

        setCostCode(first(results.data.costCodes));
      } else {
        setCostCode(undefined);
      }
    };

    getCostCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costCodeId]);

  useEffect(() => {
    const getEquipment = async () => {
      if (equipmentId !== null) {
        const results = await client.query<{ equipment: IEquipment[] }>({
          query: EQUIPMENT_BY_ID_QUERY,
          variables: {
            equipmentId,
          },
        });

        setEquipment(first(results.data.equipment));
      } else {
        setEquipment(undefined);
      }
    };
    getEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentId]);

  function getTimeRangeFormat() {
    const today = DateTime.local();
    const sameYears = today.hasSame(timeRange.startTime, 'year') && today.hasSame(timeRange.endTime, 'year');
    return sameYears ? 'EEE, MMM d' : 'EEE, MMM d, yyyy';
  }

  const classes = classNames('timesheet-print-header', 'print', 'mb-5', 'mt-5', className);

  return (
    <div className={classes}>
      <h2 className="mb-3">{title}</h2>
      <div>
        {timeRange.startTime.toFormat(getTimeRangeFormat()) + ' - ' + timeRange.endTime.toFormat(getTimeRangeFormat())}
      </div>
      {member && <div>{nameFormatter(member.firstName ?? '', member.lastName ?? '')}</div>}
      {project && <div>{getFormattedPathFromProject(project, true)}</div>}
      {costCode && <div>{getCostCodeDisplay(costCode)}</div>}
      {equipment && <div>{getEquipmentDisplay(equipment)}</div>}
    </div>
  );
};

export default TimesheetPrintHeader;
