import { Align, ListItem, Row, Theme } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import classNames from 'classnames';
import OptionalUnwrapper from 'components/foundation/OptionalUnwrapperContainer/OptionalUnwrapper';
import DashboardList from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import Typography from 'components/foundation/text/Typography/Typography';
import TimeEntryLogEventDialogCoordinator from 'containers/time-log-feed/dialogs/TimeEntryLogEventDialogCoordinator/TimeEntryLogEventDialogCoordinator';
import { ITimeLogFeedTableRow } from 'containers/time-log-feed/table/TimeLogFeedTable/TimeLogFeedTable';
import useTimeLogFeedDataToRowConverter from 'containers/time-log-feed/table/hooks/useTimeLogFeedDataToRowConverter/useTimeLogFeedDataToRowConverter';
import { useOpenable } from 'hooks';
import { useState } from 'react';
import { ClassName } from 'types/ClassName';
import { Nullable } from 'types/util/Nullable';
import { executeFunctionsCurried } from 'utils/functionUtils';
import { ITimeLogFeedDashboardRow } from '../hooks/useTimeLogFeedDashboardDataMapper/useTimeLogFeedDashboardDataMapper';
import './TimeLogFeedDashboardCardList.scss';

export interface ITimeLogFeedDashboardCardListProps {
  data: ITimeLogFeedDashboardRow[];
  theme: Theme.DARK | Theme.LIGHT;
  reload: () => Promise<void>;
  className?: ClassName;
}

function TimeLogFeedDashboardCardList({ data, theme, reload, className }: ITimeLogFeedDashboardCardListProps) {
  const timeLogEventDetails = useOpenable();

  const rowConverter = useTimeLogFeedDataToRowConverter();
  const [activeRow, setActiveRow] = useState<Nullable<ITimeLogFeedTableRow>>(null);

  const classes = classNames('time-log-feed-dashboard-card-list', theme, className);

  return (
    <>
      <DashboardList
        className={classes}
        rowItems={data ?? []}
        theme={theme}
        hover={false}
        renderItem={(item) => (
          <ListItem
            className={theme}
            onClick={
              item.grouping
                ? executeFunctionsCurried(() => setActiveRow(rowConverter(item.grouping!)), timeLogEventDetails.open)
                : undefined
            }
          >
            <Row align={Align.CENTER}>
              <FlexContainer className="full-width" justifyContent="space-between">
                <div className="time-log-feed-left-content">
                  <Typography tag="div" fontWeight="semiBold" className="time-log-feed-label">
                    {item.label}
                  </Typography>
                  <OptionalUnwrapper value={item.sublabel}>
                    {(sublabel) => (
                      <Typography tag="div" className="time-log-feed-sublabel">
                        {sublabel}
                      </Typography>
                    )}
                  </OptionalUnwrapper>
                </div>

                <Typography tag="div" className="time-log-feed-value">
                  {item.value}
                </Typography>
              </FlexContainer>
            </Row>
          </ListItem>
        )}
      />
      <OptionalUnwrapper value={activeRow}>
        {(row) => (
          <TimeEntryLogEventDialogCoordinator
            onTimeEntryChange={reload}
            position="center"
            row={row}
            isOpen={timeLogEventDetails.isOpen}
            onClose={timeLogEventDetails.close}
          />
        )}
      </OptionalUnwrapper>
    </>
  );
}

export default TimeLogFeedDashboardCardList;
