import { Image, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { t } from 'utils/localize';
import './TimesheetPrintSignature.scss';

export interface ITimesheetPrintSignatureProps {
  className?: ClassName;
}

const TimesheetPrintSignature = (props: ITimesheetPrintSignatureProps) => {
  const { className } = props;

  const memberSettings = useMemberSettings();
  const printOptions = memberSettings?.web?.features?.timeCards?.printOptions;

  const classes = classNames('timesheet-print-signature', 'print', className);

  return (
    <>
      {printOptions?.includeAdditionalSignatureLines && (
        <div className={classes}>
          {printOptions?.title ? (
            <Label className="ml-4 pt-2">{printOptions.title}</Label>
          ) : (
            <br className="ml-4 mt-2" />
          )}
          <div className="signature-container ml-4 mr-2">
            <div className="signature-layout">
              {printOptions?.signature ? (
                <div className="signature-image-container">
                  <Label className="ml-2">x</Label>
                  <Image className="signature-image" src={printOptions.signature} alt="signature" />
                </div>
              ) : (
                <Label className="ml-2">x</Label>
              )}
            </div>
            <div className="date-layout ml-6">
              <Label className="ml-2">{t('Date')}</Label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TimesheetPrintSignature;
