import {
  Align,
  Button,
  ButtonList,
  ITableColumn,
  Justify,
  Label,
  Loader,
  Position,
  Row,
  Table,
  TextAlign,
} from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Badge } from 'components';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import MoreButton from 'components/foundation/buttons/MoreButton/MoreButton';
import EmptyState from 'components/foundation/state-templates/EmptyState/EmptyState';
import TableLoader from 'components/foundation/table/TableLoader/TableLoader';
import OptionallyFormattedHours from 'components/foundation/text/OptionallyFormattedHours/OptionallyFormattedHours';
import PanelContent from 'components/layout/PanelContent/PanelContent';
import SignOffReportDialog from 'containers/timesheets/SignOffReportDialog/SignOffReportDialog';
import TimeCardReportDialog from 'containers/timesheets/TimeCardReportDialog/TimeCardReportDialog';
import TimesheetPrintHeader from 'containers/timesheets/TimesheetPrintHeader/TimesheetPrintHeader';
import TimesheetPrintSignature from 'containers/timesheets/TimesheetPrintSignature/TimesheetPrintSignature';
import { useOpenable, useOrganization } from 'hooks';
import _, { isNil, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { updateCheckedMemberIds } from 'store/timesheets/Timesheets';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import './EmployeeSummaryTable.scss';
import EmployeeSummaryDataContext, { IEmployeeSummaryRowInfo } from './context/EmployeeSummaryDataContext';

export interface IEmployeeSummaryTableProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  onDataChange?: (members: string[]) => void;
  scroller?: HTMLElement;
  rowHeight: TableCellHeight;
}

const EmployeeSummaryTable = ({
  className,
  timeRange,
  timeRangeType,
  scroller,
  onDataChange,
  rowHeight,
}: IEmployeeSummaryTableProps) => {
  const defaultSectionSize = 10;
  const organization = useOrganization();
  const isPro = useFeatureFlags('PRO');
  const isDailySignOffEnabled = organization.safetySignature || organization.timeAccuracy || organization.breakPolicy || organization.customSignOff;
  const isDailySignInEnabled = organization.busySignInQuestion;
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>();
  const loaderDialog = useOpenable();
  const openTimeEntries = useOpenable();
  const openDailySignOff = useOpenable();

  const openTimeCard = useOpenable();
  const dispatch = useDispatch();
  const canBeSigned =
    timeRangeType === TimeRangeType.PAY_PERIOD &&
    organization.signatureDate &&
    dateTimeFromISOWithoutZone(organization.signatureDate!) <= timeRange.startTime;
  const checkedItems = useSelector<IReduxState, string[]>((state) => state.timesheet.checkedMemberIds);
  const columnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.summaryAndTimeCardTableColumns
  );
  const selectedMembers = useMemo(() => (selectedMemberId ? [selectedMemberId] : []), [selectedMemberId]);

  const {
    data,
    loadedAll,
    getMoreMembers,
    onCheckAll,
    didLoad,
    handleSort,
    clearData,
    refreshDataForMembers,
    sortDir,
    sortIsDirty,
    sortBy,
  } = useContext(EmployeeSummaryDataContext);

  const useSmallIcon = rowHeight === 'cozy' || rowHeight === 'compact';

  // On mount clear data so we refetch
  useEffect(() => {
    clearData();
  }, []);

  function renderEmployeeGroupColumn(row: IEmployeeSummaryRowInfo): ReactNode {
    if (!isNil(row.member.memberGroup) && !isNil(row.member.memberGroup.groupName)) {
      return <Label>{row.member.memberGroup.groupName}</Label>;
    }
    return <Label>{'---'}</Label>;
  }

  function renderEmployeeColumn(row: IEmployeeSummaryRowInfo): ReactNode {
    const renderDropDown = (closeDropdown: () => void) => {
      const handleViewSignOffDropdown = (event: React.MouseEvent) => {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();

        // launch sign off report
        setSelectedMemberId(row.id);
        openDailySignOff.open();
      };

      const handleViewEntriesDropdown = (event: React.MouseEvent) => {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();

        // launch view entries report
        setSelectedMemberId(row.id);
        openTimeEntries.open();
      };

      return (
        <ButtonList>
          <Button onClick={handleViewEntriesDropdown}>{t('View Entries')}</Button>
          {isPro && isDailySignOffEnabled && (
            <Button onClick={handleViewSignOffDropdown}>{t('View Daily Sign-Off')}</Button>
          )}
        </ButtonList>
      );
    };

    return (
      <div className="employee-cell">
        <MoreButton
          className={`no-print button ${useSmallIcon ? 'small-icon' : ''}`}
          position={Position.BOTTOM_START}
          renderContent={renderDropDown}
        />

        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
          <Label className="ml-1">
            <FormattedEmployeeName firstName={row.member.firstName ?? ''} lastName={row.member.lastName ?? ''} />
          </Label>
          {!isNil(row.member.archivedOn) ? (
            <Badge key={row.member.id + '-archived'} className={'ml-6'}>
              {t('ARCHIVED')}
            </Badge>
          ) : undefined}
        </Row>
      </div>
    );
  }

  function renderEmployeeColumnFooter(): ReactNode {
    return <Label>{t('Totals')}</Label>;
  }

  function renderAnswer(row: IEmployeeSummaryRowInfo, col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    let answer: boolean | null = null;
    let answerText = '---';

    switch (col.key) {
      case 'employeeSigned':
        answer = row.payPeriodEmployeeSigned;
        break;
      case 'supervisorSigned':
        answer = row.payPeriodSupervisorSigned;
        break;
      case 'injured':
        answer = row.injured;
        break;
      case 'timeAccurate':
        answer = row.timeAccurate;
        break;
      case 'breakCompliance':
        answer = row.breakPolicyFollowed;
        break;
    }

    if (answer === true) {
      answerText = t('Yes');
    } else if (answer === false) {
      answerText = t('No');
    }

    return <Label>{answerText}</Label>;
  }

  function renderDuration(row: IEmployeeSummaryRowInfo, col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    let answer: number | null = null;
    switch (col.key) {
      case 'regularHours':
      case 'regularHoursDec':
        answer = row.regularSeconds;
        break;
      case 'overtimeHours':
      case 'overtimeHoursDec':
        answer = row.overtimeSeconds;
        break;
      case 'doubleTimeHours':
      case 'doubleTimeHoursDec':
        answer = row.doubletimeSeconds;
        break;
      case 'paidTimeOff':
        answer = row.ptoSeconds;
        break;
      case 'break':
        answer = row.breakSeconds;
        break;
      case 'totalHours':
      case 'totalHoursDec':
        answer = row.totalSeconds;
        break;
    }

    const isDecimal = col.key.includes('Dec');

    return <OptionallyFormattedHours value={answer} payload={{ type: isDecimal ? 'DECIMAL' : 'TIME' }} />;
  }

  function renderDurationFooter(col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    let answer: number | null = null;

    if (data.length > 0) {
      switch (col.key) {
        case 'regularHours':
        case 'regularHoursDec':
          answer = _.sumBy(data, (entry) => entry.regularSeconds ?? 0);
          break;
        case 'overtimeHours':
        case 'overtimeHoursDec':
          answer = _.sumBy(data, (entry) => entry.overtimeSeconds ?? 0);
          break;
        case 'doubleTimeHours':
        case 'doubleTimeHoursDec':
          answer = _.sumBy(data, (entry) => entry.doubletimeSeconds ?? 0);
          break;
        case 'paidTimeOff':
          answer = _.sumBy(data, (entry) => entry.ptoSeconds ?? 0);
          break;
        case 'break':
          answer = _.sumBy(data, (entry) => entry.breakSeconds ?? 0);
          break;
        case 'totalHours':
        case 'totalHoursDec':
          answer = _.sumBy(data, (entry) => entry.totalSeconds ?? 0);
          break;
      }
    }

    const isDecimal = col.key.includes('Dec');

    return <OptionallyFormattedHours value={answer} payload={{ type: isDecimal ? 'DECIMAL' : 'TIME' }} />;
  }

  function renderFlaggedSignIn(row: IEmployeeSummaryRowInfo, col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    return <Label>{row.flaggedSignInAmount}</Label>;
  }

  function renderFlaggedSignInFooter(col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    let totalAmount = 0;
    data.forEach((entry) => {
      if (entry.flaggedSignInAmount) {
        totalAmount = totalAmount + entry.flaggedSignInAmount;
      }
    })
    return <Label>{totalAmount}</Label>;
  }

  function renderFlaggedSignOff(row: IEmployeeSummaryRowInfo, col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    return <Label>{row.flaggedSignOffAmount}</Label>;
  }

  function renderFlaggedSignOffFooter(col: ITableColumn<IEmployeeSummaryRowInfo>): ReactNode {
    let totalAmount = 0;
    data.forEach((entry) => {
      if (entry.flaggedSignOffAmount) {
        totalAmount = totalAmount + entry.flaggedSignOffAmount;
      }
    })
    return <Label>{totalAmount}</Label>;
  }

  function getColumns(): Array<ITableColumn<IEmployeeSummaryRowInfo>> {
    const signatureColumns = Array<ITableColumn<IEmployeeSummaryRowInfo>>();

    // only show the columns if we are viewing a pay period that can be signed
    if (canBeSigned) {
      signatureColumns.push({
        cell: renderAnswer,
        key: 'employeeSigned',
        sort: false,
        title: t('Emp. Signed'),
        tooltip: t('Employee Signed'),
        align: TextAlign.CENTER,
        size: '95px',
        headerTextWrap: true,
      });

      signatureColumns.push({
        cell: renderAnswer,
        key: 'supervisorSigned',
        sort: false,
        title: t('Sup. Signed'),
        tooltip: t('Supervisor Signed'),
        align: TextAlign.CENTER,
        size: '95px',
        headerTextWrap: true,
      });
    }

    const dailySignOffColumns = Array<ITableColumn<IEmployeeSummaryRowInfo>>();
    if (isPro) {
      dailySignOffColumns.push({
        cell: renderAnswer,
        key: 'timeAccurate',
        sort: false,
        title: t('Time Acc.'),
        tooltip: t('Time Accurate'),
        align: TextAlign.CENTER,
        size: '70px',
        headerTextWrap: true,
      });
      dailySignOffColumns.push({
        cell: renderAnswer,
        key: 'breakCompliance',
        sort: false,
        title: t('Break Comp.'),
        tooltip: t('Break Compliance'),
        align: TextAlign.CENTER,
        size: '90px',
        headerTextWrap: true,
      });
      dailySignOffColumns.push({
        cell: renderAnswer,
        key: 'injured',
        sort: false,
        title: t('Injured'),
        align: TextAlign.CENTER,
        size: '90px',
      });
    }

    const columns = [
      {
        cell: renderEmployeeColumn,
        key: 'member', // needs to match data property name for sorting
        sort: true,
        title: t('Employee'),
        align: TextAlign.LEFT,
        footerClassName: `employee-table-cell`,
        footer: renderEmployeeColumnFooter,
      },
      {
        cell: renderEmployeeGroupColumn,
        key: 'member.memberGroup.groupName',
        sort: false,
        title: t('Employee Group'),
        tooltip: t('Employee Group'),
        align: TextAlign.CENTER,
        size: '150px',
        cellClassName: `wrapping-table-cell`,
        headerTextWrap: true,
      },
      ...signatureColumns,
      ...dailySignOffColumns,
      {
        cell: renderDuration,
        key: 'regularHours',
        sort: false,
        title: t('Reg'),
        tooltip: t('Regular Hours'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'regularHoursDec',
        sort: false,
        title: t('Reg (Decimal)'),
        tooltip: t('Regular Hours (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'overtimeHours',
        sort: false,
        title: t('OT'),
        tooltip: t('Overtime Hours'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'overtimeHoursDec',
        sort: false,
        title: t('OT (Decimal)'),
        tooltip: t('Overtime Hours (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'doubleTimeHours',
        sort: false,
        title: t('DT'),
        tooltip: t('Double Time Hours'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'doubleTimeHoursDec',
        sort: false,
        title: t('DT (Decimal)'),
        tooltip: t('Double Time Hours (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'paidTimeOff',
        sort: false,
        title: t('PTO'),
        tooltip: t('Paid Time Off'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'totalHours',
        sort: false,
        title: t('Total'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
      },
      {
        cell: renderDuration,
        key: 'totalHoursDec',
        sort: false,
        title: t('Total (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
      },
    ];

    if (isDailySignInEnabled && isPro) {
      columns.push({
        cell: renderFlaggedSignIn,
        key: 'flaggedSignIn',
        sort: false,
        title: t('Flagged Sign In'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderFlaggedSignInFooter,
      })
    }

    if (isDailySignOffEnabled && isPro) {
      columns.push({
        cell: renderFlaggedSignOff,
        key: 'flaggedSignOff',
        sort: false,
        title: t('Flagged Sign Off'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderFlaggedSignOffFooter,
      })
    }

    const columnSettingMap = _.keyBy(columnSettings, 'key');

    // only show the columns that the user has chosen and always show the employee column
    // this will also filter out columns that aren't available due to company settings having the feature off
    return _.sortBy(
      columns.filter((col) => col.key === 'member' || columnSettingMap[col.key]?.visible === true),
      (col) => columnSettingMap[col.key]?.position ?? 0
    );
  }

  const renderEmptyState = () => {
    return <EmptyState title={t('No employees')} />;
  };

  async function handleCheckAll(checked: boolean) {
    if (checked && !loadedAll) {
      loaderDialog.open();
      await onCheckAll(checked);
      loaderDialog.close();
    }
  }

  const handleEntriesDialogClose = () => {
    openTimeEntries.close();
    setSelectedMemberId(undefined);
  };

  const handleSignOffReportClose = () => {
    openDailySignOff.close();
    setSelectedMemberId(undefined);
  };

  const handleTimeCardReportClose = () => {
    openTimeCard.close();
    setSelectedMemberId(undefined);
  };

  const handleRowClick = (row: IEmployeeSummaryRowInfo) => {
    setSelectedMemberId(row.id);
    openTimeCard.open();
  };

  const handleSelectChange = (newCheckedRows: IEmployeeSummaryRowInfo[]) => {
    dispatch(
      updateCheckedMemberIds(newCheckedRows.filter((item) => isNil(item.member.archivedOn)).map((item) => item.id))
    );
  };

  const updateSignOffMembers = () => {
    onMembersUpdate(selectedMembers);
  };

  const onMembersUpdate = (members: string[]) => {
    if (onDataChange) {
      onDataChange(members);
    }

    setTimeout(() => refreshDataForMembers(members), 1000);
  };

  const tableWidth = () => {
    const columns = getColumns();
    return _.sumBy(columns, (column) => toNumber(column.size?.replace('px', '') ?? '0')) + 300;
  };

  const classes = classNames(
    {
      'employee-summary-table': true,
    },
    className
  );

  return (
    <>
      <TimesheetPrintHeader title={t('Employee Summary')} timeRange={timeRange} />
      <PanelContent className={classes}>
        <Loader isOpen={loaderDialog.isOpen} overlay={true} />
        <TableLoader
          offset={50}
          className="pb-5 pl-1"
          data={data}
          loadedAll={loadedAll}
          getData={(cursor) => getMoreMembers(timeRange, 10, cursor)}
          didLoad={didLoad}
          sectionSize={defaultSectionSize}
          emptyTemplate={renderEmptyState()}
          scroller={scroller ?? 'self'}
          render={(tableData: IEmployeeSummaryRowInfo[], tableLoader?: HTMLElement) => {
            return (
              <Table<IEmployeeSummaryRowInfo>
                cols={getColumns()}
                data={tableData}
                minWidth={`${tableWidth()}px`}
                lazyLoad={false}
                sortBy={sortBy}
                sortDir={sortDir}
                sortIsDirty={sortIsDirty}
                onSort={handleSort}
                strokeCols={true}
                header="standard"
                footer="standard"
                scroller={tableLoader}
                onRowClick={handleRowClick}
                selected={data.filter((item) => _.some(checkedItems, (id) => item.id === id))}
                onSelectChange={handleSelectChange}
                onCheckAllChange={handleCheckAll}
                cellHeight={rowHeight}
              />
            );
          }}
        />
        <MemberTimeEntryDialog
          isOpen={openTimeEntries.isOpen}
          onClose={handleEntriesDialogClose}
          timeRange={timeRange}
          memberIds={selectedMembers}
          timeRangeType={timeRangeType}
          onDataChange={onMembersUpdate}
        />
        <SignOffReportDialog
          isOpen={openDailySignOff.isOpen}
          onClose={handleSignOffReportClose}
          timeRange={timeRange}
          memberIds={selectedMembers}
          timeRangeType={timeRangeType}
          onDataChange={updateSignOffMembers}
        />
        <TimeCardReportDialog
          isOpen={openTimeCard.isOpen}
          onClose={handleTimeCardReportClose}
          timeRange={timeRange}
          memberIds={selectedMembers}
          timeRangeType={timeRangeType}
          onDataChange={onMembersUpdate}
        />
      </PanelContent>
      <TimesheetPrintSignature />
    </>
  );
};

export default EmployeeSummaryTable;
