import gql from 'graphql-tag';
import { useApolloPaging } from 'hooks';
import { isNil, some } from 'lodash';
import ICursorable from 'types/Cursorable';
import IProject from 'types/Project';

export default function useCheckArchivedOfProject() {
  const { getAll } = useApolloPaging();

  async function checkArchivedOfProjects(projectIds?: string[] | null): Promise<boolean> {
    if (!isNil(projectIds)) {
      const results = await getAll<IProject & ICursorable>('projects', {
        fetchPolicy: 'network-only',
        query: PROJECT_ARCHIVED_QUERY,
        variables: { filter: { id: { contains: projectIds } } },
      });
      return some(results, (project) => !isNil(project.archivedOn));
    } else {
      return false;
    }
  }

  return checkArchivedOfProjects;
}

export const PROJECT_ARCHIVED_QUERY = gql`
  query queryProjectArchived($first: Int, $after: String, $filter: ProjectFilter) {
    projects(first: $first, after: $after, filter: $filter) {
      id
      cursor
      archivedOn
    }
  }
`;
