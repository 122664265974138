enum MemberPermission {
  MANAGE_EMPLOYEES = 'manageEmployees',
  MANAGE_MEDIA_COLLECTION = 'manageMediaCollection',
  MANAGE_SCHEDULE = 'manageSchedule',
  MANAGE_TIME_ENTRIES = 'manageTimeEntries',
  MANAGE_TIME_OFF = 'manageTimeOff',
  MEMBER_LOCK = 'memberLock',
  REQUEST_SIGNATURES = 'requestSignatures',
  TIME_EVENTS = 'timeEvents',
  VIEW_MEMBER_ACTIVITY_REPORT = 'viewMemberActivityReport',
  VIEW_SCHEDULE = 'viewSchedule',
  CAN_MANAGE_PROJECT_COST_CODE_QUANTITY = 'canManageProjectCostCodeQuantity',
  MANAGE_PER_DIEM = 'managePerDiem',
}

export default MemberPermission;
