import { Button, Label, Textarea } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import { useState } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import './DailySignOffResolveInjuryDialog.scss';

export interface IDailySignOffResolveInjuryDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  onSave: (notes: string) => void;
}

export interface IDailySignOffInjuryResolutionOptionData {
  notes?: string | null;
}

const DailySignOffResolveInjuryDialog = (props: IDailySignOffResolveInjuryDialogProps) => {
  const { className, isOpen, onClose, onSave } = props;

  const classes = classNames('daily-sign-off-resolve-injury-dialog', className);
  const [data, setData] = useState<IDailySignOffInjuryResolutionOptionData>({
    notes: null,
  });

  function handleSubmit() {
    onSave(data.notes ?? '');
    handleClose();
  }

  const handleClose = () => {
    setData({ notes: null });
    onClose();
  };

  const notesTextArea = () => {
    return (
      <Textarea
        className="m-5 custom-response-text"
        onChange={(notes) => setData({ ...data, notes })}
        value={data.notes ?? ''}
        restrictTo={{ maxLength: 1000 }}
      ></Textarea>
    );
  };

  const submitButton = () => {
    return (
      <Button type="primary" className="m-5" onClick={handleSubmit}>
        {t('Submit')}
      </Button>
    );
  };

  return (
    <HeaderDialog className={classes} isOpen={isOpen} onClose={handleClose} divider={false} title={t('Process Injury')}>
      <Label className="ml-5 mt-5">{t('Notes')}</Label>
      {notesTextArea()}
      {submitButton()}
    </HeaderDialog>
  );
};

export default DailySignOffResolveInjuryDialog;
