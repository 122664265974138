import { IDatePickerPayload } from '@busybusy/webapp-react-ui';
import _ from 'lodash';
import ITimeRange from 'types/TimeRange';
import { t } from 'utils/localize';
import { isType } from 'utils/typeguard';

export default function v_require(value: any, args: { message?: string }) {
  return {
    message: args.message ?? t('Required field'),
    validate: () => {
      if (typeof value === 'string') {
        value = value.trim();
      } else if (value && isType<IDatePickerPayload>(value, 'value')) {
        value = value.value;
      } else if (
        value &&
        (isType<ITimeRange<string | undefined | null>>(value, 'startTime') ||
          isType<ITimeRange<string | undefined | null>>(value, 'endTime'))
      ) {
        return value.startTime && value.endTime;
      }

      return _.isArray(value)
        ? !_.isEmpty(value)
        : ![undefined, null, '', false].includes(value) && !Number.isNaN(value);
    },
  };
}
