import { createSlice } from '@reduxjs/toolkit';
import { featureDefaults } from 'config/features';
import { IFeatureFlags } from 'types/features';

export const featuresSlice = createSlice({
  name: 'features',
  initialState: featureDefaults,
  reducers: {
    updateFeatureFlag(state, action) {
      const { payload } = action;
      const keys = Object.keys(payload) as Array<keyof IFeatureFlags>;

      if (keys.length === 0) {
        throw Error(`No feature flag provided`);
      }

      if (keys.length > 1) {
        throw Error(`One feature flag per update`);
      }

      const [key] = keys;

      if (!state.hasOwnProperty(key)) {
        throw Error(`Unsupported feature flag`);
      }

      state[key] = payload[key] as never;
    },
  },
});

export const { updateFeatureFlag } = featuresSlice.actions;
export default featuresSlice.reducer;
