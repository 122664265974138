import { Toast } from '@busybusy/webapp-react-ui';
import { useToastState } from 'contexts';

export default function ConnectedToast() {
  const [{ theme, isOpen, label, timeout }, dispatch] = useToastState();

  function closeToast() {
    dispatch({ type: 'CLOSE' });
  }

  return (
    <Toast theme={theme} isOpen={isOpen} onClose={closeToast} timeout={timeout}>
      {label}
    </Toast>
  );
}
