import { ITableColumn, Size, Table, TextAlign, Theme } from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import { useTableSorting } from 'hooks';
import { t } from 'utils/localize';
import ICSVEquipment from '../../../csv-import/types/ImportEquipmentTypes';

export interface ICSVImportEquipmentOverviewTableProps {
  className?: ClassName;
  loading: boolean;
  equipment: ICSVEquipment[];
  lazyScrollTrigger?: HTMLElement;
  onDelete: (equipment: ICSVEquipment) => void;
}

const CSVImportEquipmentOverviewTable = (props: ICSVImportEquipmentOverviewTableProps) => {
  const { className, loading, equipment, lazyScrollTrigger, onDelete } = props;

  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(equipment);

  function renderDeleteIcon(row: ICSVEquipment, _col: any) {
    function handleDelete() {
      onDelete(row);
    }

    return (
      <IconButton
        svg={DeleteIcon}
        onClick={handleDelete}
        theme={Theme.LIGHT}
        buttonSize={Size.SMALL}
        tooltipLabel={t('Delete')}
      />
    );
  }

  function getColumns(): Array<ITableColumn<ICSVEquipment>> {
    const columns: Array<ITableColumn<ICSVEquipment>> = [
      { align: TextAlign.CENTER, cell: renderDeleteIcon, key: 'delete', size: '44px', cellClassName: 'px-0' },
      { key: 'name', sort: true, title: t('Name') },
      { key: 'category', sort: true, title: t('Category') },
      { key: 'make', sort: true, title: t('Make') },
      { key: 'model', sort: true, title: t('Model') },
      { key: 'year', sort: true, title: t('Year') },
      { key: 'hourMeter', sort: true, title: t('Hour Meter') },
      { key: 'hourlyRate', sort: true, title: t('Hourly Rate') },
    ];
    return columns;
  }

  const classes = classNames('csv-import-equipment-overview', className);

  return (
    <Table
      className={classes}
      cols={getColumns()}
      data={sorted}
      minWidth={`${getColumns().length * 240}px`}
      sortDir={sortDirection}
      sortBy={sortedBy}
      onSort={onSort}
      lazyScrollSectionSize={50}
      lazyLoad={sorted.length > 200}
      lazyScrollTrigger={lazyScrollTrigger}
      isLoading={loading}
      sortIsDirty={sortIsDirty}
      strokeCols={false}
    />
  );
};

export default CSVImportEquipmentOverviewTable;
