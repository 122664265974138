import {
  Align,
  Button,
  Checkbox,
  CheckboxFormField,
  Form,
  IFormRender,
  Justify,
  Label,
  Row,
  Theme,
} from '@busybusy/webapp-react-ui';
import { InfoIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Well } from 'components';
import TooltipIcon from 'components/foundation/TooltipIcon/TooltipIcon';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import Typography from 'components/foundation/text/Typography/Typography';
import useBudgetPerformanceFeatureEnabled from 'containers/budgets/hooks/useBudgetPerformanceFeatureEnabled';
import PerformanceScoringInfoDialog from 'containers/budgets/performance/PerformanceScoringInfoDialog/PerformanceScoringInfoDialog';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import SectionHeader from 'containers/settings/components/SectionHeader/SectionHeader';
import { useToastDispatch } from 'contexts/ToastContext';
import { useOpenable, useOrganizationUpdate } from 'hooks';
import { cloneDeep } from 'lodash';
import { useRef, useState } from 'react';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import './LaborBudgetsForm.scss';

export interface ILaborBudgetsFormProps {
  trackBudgets: boolean;
  trackBudgetCost: boolean;
  trackBudgetEquipmentHours: boolean;
  trackBudgetEquipmentCosts: boolean;
  trackBudgetProgress: boolean;
  trackBudgetProgressValue: boolean;
  trackCostCode: boolean;
  trackEquipment: boolean;
  performanceScores: boolean;
  onSubmit: (data: ILaborBudgetsFormData) => void;
  className?: ClassName;
}

export interface ILaborBudgetsFormData {
  trackBudgets: boolean;
  trackBudgetCost: boolean;
  trackBudgetEquipmentHours: boolean;
  trackBudgetEquipmentCosts: boolean;
  trackBudgetProgress: boolean;
  trackBudgetProgressValue: boolean;
  performanceScores: boolean;
}

const LaborBudgetsForm = ({
  onSubmit,
  className,
  trackBudgets,
  trackBudgetCost,
  trackBudgetEquipmentHours,
  trackBudgetEquipmentCosts,
  trackBudgetProgress,
  trackBudgetProgressValue,
  trackCostCode,
  trackEquipment,
  performanceScores,
}: ILaborBudgetsFormProps) => {
  const [, update] = useOrganizationUpdate();
  const [budgetsEnabled, setBudgetsEnabled] = useState<boolean>(
    trackBudgets || trackBudgetCost || trackBudgetProgress || trackBudgetEquipmentHours || trackBudgetEquipmentCosts
  );
  const initialFormData = useRef({
    trackBudgets,
    trackBudgetCost,
    trackBudgetProgress,
    trackBudgetProgressValue,
    trackBudgetEquipmentHours,
    trackBudgetEquipmentCosts,
    performanceScores,
  });

  const performanceInfoDialogDetails = useOpenable();
  const toastDispatch = useToastDispatch();
  const workAccomplishedEnabled = useFeatureFlags('WORK_ACCOMPLISHED');
  const isBudgetPerformanceEnabled = useBudgetPerformanceFeatureEnabled();

  const classes = classNames('labor-budget-form', className);

  function renderFormFields(form: IFormRender<ILaborBudgetsFormData>) {
    return (
      <div>
        <Checkbox
          label={
            <HeaderText title={t('Budgets')}>
              <span>{t('Track budget vs actuals in real time.')}</span>
            </HeaderText>
          }
          checked={budgetsEnabled}
          onChange={(newValue) => {
            if (
              newValue &&
              !trackBudgets &&
              !trackBudgetCost &&
              !trackBudgetProgress &&
              !trackBudgetEquipmentHours &&
              !trackBudgetEquipmentCosts
            ) {
              initialFormData.current = {
                trackBudgets: true,
                trackBudgetCost: true,
                trackBudgetEquipmentHours: true,
                trackBudgetEquipmentCosts: true,
                trackBudgetProgress: true,
                trackBudgetProgressValue: true,
                performanceScores: true,
              };
            }
            setBudgetsEnabled(newValue);
          }}
        />
        {budgetsEnabled && (
          <>
            <SectionHeader className={'my-4'}>{t('Budget Types')}</SectionHeader>
            <DashboardRibbon theme={Theme.LIGHT}>
              <CheckboxFormField
                name="trackBudgets"
                form={form}
                onChange={(v) => {
                  if (!v) {
                    form.setData('performanceScores', false);
                  }
                }}
                label={
                  <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                    <Label>{t('Labor Hours')}</Label>
                    <TooltipIcon
                      className={'enable-switch-info ml-2 mb-2'}
                      svg={InfoIcon}
                      tooltipLabel={t('Budget and track the labor hours needed to complete each project.')}
                    />
                  </Row>
                }
              />
              <CheckboxFormField
                name="trackBudgetCost"
                form={form}
                label={
                  <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                    <Label>{t('Labor Cost')}</Label>
                    <TooltipIcon
                      className={'enable-switch-info ml-2 mb-2'}
                      svg={InfoIcon}
                      tooltipLabel={t(
                        'Budget and track the labor costs for your projects. To ensure accurate data, remember to assign a wage to each of your employees. This can be done from the employee management list.'
                      )}
                    />
                  </Row>
                }
              />
              <CheckboxFormField
                name="trackBudgetEquipmentHours"
                form={form}
                label={
                  <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                    <Label>{t('Equipment Hours')}</Label>
                    <TooltipIcon
                      className={'enable-switch-info ml-2 mb-2'}
                      svg={InfoIcon}
                      tooltipLabel={t('Budget and track the equipment hours needed to complete your projects.')}
                    />
                  </Row>
                }
              />
              <CheckboxFormField
                name="trackBudgetEquipmentCosts"
                form={form}
                label={
                  <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                    <Label>{t('Equipment Cost')}</Label>
                    <TooltipIcon
                      className={'enable-switch-info ml-2 mb-2'}
                      svg={InfoIcon}
                      tooltipLabel={t(
                        'Budget and track the equipment costs for your projects. To ensure accurate data, remember to assign an hourly rate to each piece of equipment. This can be done from the equipment management list.'
                      )}
                    />
                  </Row>
                }
              />
              {workAccomplishedEnabled && (
                <CheckboxFormField
                  name="trackBudgetProgress"
                  form={form}
                  onChange={(v) => {
                    if (!v) {
                      form.setData('performanceScores', false);
                    }
                  }}
                  label={
                    <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                      <Label>{t('Progress')}</Label>
                      <TooltipIcon
                        className={'enable-switch-info ml-2 mb-2'}
                        svg={InfoIcon}
                        tooltipLabel={t(
                          'Always know how much work has been completed on a job and how much is remaining.'
                        )}
                      />
                    </Row>
                  }
                />
              )}
              {workAccomplishedEnabled && form.state.data.trackBudgetProgress && (
                <CheckboxFormField
                  className={'ml-4'}
                  name="trackBudgetProgressValue"
                  form={form}
                  label={
                    <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                      <Label>{t('Progress Value')}</Label>
                      <TooltipIcon
                        className={'ml-2 mb-2'}
                        svg={InfoIcon}
                        tooltipLabel={t(
                          'Progress Value is a more precise way to measure how far along you are on a job. Simply enter a progress value (i.e. the gross revenue you expect to earn) for each cost code you add to your budget. As progress is reported for that cost code, the progress value is updated automatically to show the amount of gross revenue that has been earned to date.\n\nViewing progress in this way gives you a more accurate, high-level view of how far along you are on your jobs.'
                        )}
                      />
                    </Row>
                  }
                />
              )}
            </DashboardRibbon>
            {isBudgetPerformanceEnabled && (
              <>
                <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
                  <SectionHeader className={'my-4'}>{t('Performance')}</SectionHeader>
                  <Typography
                    tag="label"
                    color="primary"
                    textDecoration="underline"
                    fontSize="0.8rem"
                    onClick={performanceInfoDialogDetails.open}
                  >
                    {t('Learn More')}
                  </Typography>
                </Row>
                <DashboardRibbon theme={Theme.LIGHT}>
                  <CheckboxFormField
                    className={'ml-4 p-0'}
                    name="performanceScores"
                    disabled={!form.state.data.trackBudgetProgress}
                    form={form}
                    label={
                      <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'enable-switch-info'}>
                        <Label>{t('Performance Score')}</Label>
                      </Row>
                    }
                  />
                </DashboardRibbon>
              </>
            )}
          </>
        )}
        <PerformanceScoringInfoDialog
          isOpen={performanceInfoDialogDetails.isOpen}
          onClose={performanceInfoDialogDetails.close}
        />
        {budgetsEnabled &&
          !form.state.data.trackBudgets &&
          !form.state.data.trackBudgetCost &&
          !form.state.data.trackBudgetEquipmentHours &&
          !form.state.data.trackBudgetEquipmentCosts &&
          !form.state.data.trackBudgetProgress && (
            <>
              <Well theme={Theme.DANGER} className="mt-8">
                <p>{t('At least one budget type must be enabled.')}</p>
              </Well>
            </>
          )}

        {!trackEquipment && (form.state.data.trackBudgetEquipmentHours || form.state.data.trackBudgetEquipmentCosts) && (
          <>
            <Well theme={Theme.PRIMARY} className="mt-8">
              <p className="fw-regular">
                <span className="fw-bold">{t('Important') + ': '}</span>
                {t('By checking Equipment Hours / Cost, equipment will be enabled for your company.')}
              </p>
            </Well>
          </>
        )}

        {!trackCostCode && workAccomplishedEnabled && form.state.data.trackBudgetProgress && (
          <>
            <Well theme={Theme.PRIMARY} className="mt-8">
              <p className="fw-regular">
                <span className="fw-bold">{t('Important') + ': '}</span>
                {t('By checking Progress, cost codes will be enabled for your company.')}
              </p>
            </Well>
          </>
        )}

        <Button className="mt-8" type="primary" onClick={form.handleSubmit}>
          {t('Update')}
        </Button>
      </div>
    );
  }

  async function onFormSubmit(data: ILaborBudgetsFormData) {
    const clonedData = cloneDeep(data);

    if (!workAccomplishedEnabled) {
      // If progress (work accomplished) is disabled than set the progress budget values to false.
      clonedData.trackBudgetProgress = false;
      clonedData.trackBudgetProgressValue = false;
    }

    if (
      budgetsEnabled &&
      !clonedData.trackBudgets &&
      !clonedData.trackBudgetCost &&
      !clonedData.trackBudgetProgress &&
      !clonedData.trackBudgetEquipmentHours &&
      !clonedData.trackBudgetEquipmentCosts
    ) {
      return;
    }

    if (!budgetsEnabled) {
      clonedData.trackBudgets = false;
      clonedData.trackBudgetCost = false;
      clonedData.trackBudgetEquipmentHours = false;
      clonedData.trackBudgetEquipmentCosts = false;
      clonedData.trackBudgetProgress = false;
      clonedData.trackBudgetProgressValue = false;
      clonedData.performanceScores = false;
    }

    if (!clonedData.trackBudgetProgress && clonedData.trackBudgetProgressValue) {
      clonedData.trackBudgetProgressValue = false;
    }

    if (!clonedData.trackBudgetProgress) {
      clonedData.performanceScores = false;
    }

    try {
      await update({
        trackBudgets: clonedData.trackBudgets,
        trackBudgetCost: clonedData.trackBudgetCost,
        trackBudgetEquipmentHours: clonedData.trackBudgetEquipmentHours,
        trackBudgetEquipmentCosts: clonedData.trackBudgetEquipmentCosts,
        trackBudgetProgress: clonedData.trackBudgetProgress,
        trackBudgetProgressValue: clonedData.trackBudgetProgressValue,
        performanceScores: clonedData.performanceScores,
      });

      if (!trackCostCode && workAccomplishedEnabled && clonedData.trackBudgetProgress) {
        await update({ trackCostCode: true });
      }

      if (!trackEquipment && (clonedData.trackBudgetEquipmentHours || clonedData.trackBudgetEquipmentCosts)) {
        await update({ trackEquipment: true });
      }

      onSubmit(clonedData);
    } catch (e) {
      toastDispatch({
        type: 'OPEN',
        payload: { theme: Theme.DANGER, label: t('Unable to update organization. Please try again later.') },
      });
    }
  }

  return (
    <Form
      render={renderFormFields}
      onSubmit={onFormSubmit}
      className={classes}
      data={initialFormData.current}
      allowMultipleSubmissions={true}
    />
  );
};

export default LaborBudgetsForm;
