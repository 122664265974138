import isNil from 'lodash/isNil';
import { t } from 'utils/localize';
import { type PolicyEntry, type PolicyEntryPopulated } from './queries';

export const POLICY_KEY_TERMS = 'termsAndAgreements' as const;
export const POLICY_KEY_PRIVACY = 'privacyPolicy' as const;

export const POLICY_KEY_TERMS_LABEL = 'Terms & Agreements' as const;
export const POLICY_KEY_PRIVACY_LABEL = 'Privacy Policy' as const;

export const POLICY_LABELS = {
  [POLICY_KEY_TERMS]: POLICY_KEY_TERMS_LABEL,
  [POLICY_KEY_PRIVACY]: POLICY_KEY_PRIVACY_LABEL,
};

const defaultPolicyLabel = t(`Policy`);
export const getPolicyLabel = (policyType: string) => {
  return POLICY_LABELS[policyType as TPolicyKey] ?? defaultPolicyLabel;
};

export type TPolicyKey = typeof POLICY_KEY_TERMS | typeof POLICY_KEY_PRIVACY;

export const isPolicyEntryPopulated = (entry: unknown): entry is PolicyEntryPopulated => {
  const hasExpectedKeys =
    typeof entry === 'object' &&
    !isNil(entry) &&
    'id' in entry &&
    'version' in entry &&
    'type' in entry &&
    'contentUrl' in entry &&
    'effectiveDate' in entry;

  const hasExpectedValues =
    hasExpectedKeys &&
    !isNil((entry as PolicyEntry).version) &&
    !isNil((entry as PolicyEntry).type) &&
    !isNil((entry as PolicyEntry).contentUrl);

  return hasExpectedValues;
};
