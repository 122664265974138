import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { t } from 'utils/localize';

export default function v_start_time_before_end_time(
  value: ITimeRange<DateTime | undefined | null> | null,
  args?: { message?: string }
) {
  return {
    message: args?.message || t('Start time must be before end time.'),
    validate: () => {
      if (!value || !value.startTime || !value.endTime) {
        // Potentially non required fields.
        return true;
      } else {
        return value.startTime.toSeconds() < value.endTime.toSeconds();
      }
    },
  };
}
