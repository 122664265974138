import { Button, Dialog, Icon, IDialogProps, Row, Theme } from '@busybusy/webapp-react-ui';
import { CSVIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import FileUploadButton from 'components/foundation/buttons/FileUploadButton/FileUploadButton';
import Well from 'components/foundation/Well/Well';
import Panel from 'components/layout/Panel/Panel';
import FilePickerType from 'types/enum/FilePickerType';
import { downloadFile } from 'utils/fileUtils';
import { t } from 'utils/localize';
import './CsvImportDialog.scss';

export interface ICsvImportDialogProps extends Omit<IDialogProps, 'children'> {
  onFileSelected: (file: File) => void;
  requiredFieldText: string;
  headers: string;
  prevalidationErrors: string[];
  sampleFileName: string;
  title: string;
  errors: Array<string | undefined> | null;
  bodyText: string;
  otherDownloadButtonName?: string | null;
  performOtherDownload?: () => void;
}

export const CsvImportDialog = (props: ICsvImportDialogProps) => {
  const {
    className,
    bodyText,
    onFileSelected,
    requiredFieldText,
    headers,
    prevalidationErrors,
    sampleFileName,
    title,
    errors,
    otherDownloadButtonName,
    performOtherDownload,
    ...other
  } = props;

  function onFileSelect(file: File | undefined) {
    if (!file) {
      return;
    }

    onFileSelected(file);
  }

  function downloadSampleFile() {
    downloadFile({ file: new Blob([headers]), fileName: sampleFileName });
  }

  function downloadOtherDataFile() {
    if (performOtherDownload) {
      performOtherDownload();
    }
  }

  const classes = classNames(
    {
      'csv-import-content': true,
      'px-8': true,
    },
    className
  );

  return (
    <Dialog {...other}>
      <Panel className={classes}>
        <Icon svg={CSVIcon} size={140} />
        <h2>{title}</h2>
        <div className="grouped-text">
          <p>{bodyText}</p>
          <p>{t('Download the sample file to see the required format.')}</p>
        </div>
        <p className={'fc-1'}>{requiredFieldText}</p>

        {errors && errors.length !== 0 && (
          <Well theme={Theme.DANGER} className="mt-3 mx-6">
            {t("We've detected one or more issues. Please fix the following errors and re-upload your file")}:
            <ul className="error-list">
              {errors.map((error) => (
                <li key={error} className="fc-regular">
                  {error}
                </li>
              ))}
            </ul>
          </Well>
        )}

        {prevalidationErrors && prevalidationErrors.length !== 0 && (
          <Well theme={Theme.DANGER} className="mt-3 mx-6">
            {t(
              "We've detected incorrect or missing column names. Please fix the following column errors and re-upload your file"
            )}
            :
            <ul className="error-list">
              {prevalidationErrors.map((error) => (
                <li key={error} className="fc-regular">
                  {error}
                </li>
              ))}
            </ul>
          </Well>
        )}

        <Row className="spaced-buttons">
          {otherDownloadButtonName && (
            <Button type="secondary" onClick={downloadOtherDataFile}>
              {otherDownloadButtonName}
            </Button>
          )}
          <Button type="secondary" onClick={downloadSampleFile}>
            {t('Sample File')}
          </Button>
          <FileUploadButton onFileSelection={onFileSelect} accept={[FilePickerType.CSV, FilePickerType.EXCEL]} />
        </Row>
      </Panel>
    </Dialog>
  );
};

export default CsvImportDialog;
