import { Col, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { CostCode } from '__generated__/graphql';
import './FormattedCostCodeName.scss';

export interface IFormattedCostCodeNameProps {
  className?: ClassName;
  costCode: Pick<CostCode, 'title' | 'costCode'>;
}

export default function FormattedCostCodeName({ className, costCode }: IFormattedCostCodeNameProps) {
  const classes = classNames('formatted-cost-code-name', className);

  return (
    <Col className={classes} align="start">
      <Label className="cost-code mb-0">{costCode.costCode}</Label>
      <Label className="cost-code-title fc-2 mb-0">{costCode.title ?? '-'}</Label>
    </Col>
  );
}
