import { Button, Col, Icon, Justify, Row, Size } from '@busybusy/webapp-react-ui';
import * as React from 'react';

export interface IPhotoNavigationArrowProps {
  direction: 'right' | 'left';
  icon: any;
  disabled: () => boolean;
  onClick: () => void;
}

const PhotoNavigationArrow: React.FunctionComponent<IPhotoNavigationArrowProps> = (props) => {
  const { direction, disabled, icon, onClick } = props;

  return (
    <Col size={1}>
      <Row justify={direction === 'right' ? Justify.FLEX_END : undefined}>
        <Button disabled={disabled()} size={Size.LARGE} type="icon" className="m-5" onClick={onClick}>
          <Icon size={80} svg={icon} />
        </Button>
      </Row>
    </Col>
  );
};

export default PhotoNavigationArrow;
