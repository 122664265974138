import { useApolloClient } from '@apollo/client';
import { Col, Container, Row, Size } from '@busybusy/webapp-react-ui';
import { TIME_ENTRY_BY_ID_QUERY } from 'apollo/queries/time-entry-queries';
import { EditIcon, InfoStroke } from 'assets/icons';
import classNames from 'classnames';
import LocationHistoryDialog from 'components/domain/location/LocationHistory/LocationHistoryDialog';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import TimeEntryLogsView from 'components/domain/time-entry-logs/TimeEntryLogs/TimeEntryLogsView';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import EmbeddedLinkedText from 'components/foundation/EmbeddedLinkedText/EmbeddedLinkedText';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import { useOpenable, useOrganization, usePermissions, useTimesheetsGraylog } from 'hooks';
import _, { isNil, sortBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ITimeEntry from 'types/TimeEntry';
import IBreak from 'types/TimeEntryBreak';
import { dateUtils, projectUtils, stringUtils, timeEntryUtils } from 'utils';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { inhibitsActions } from 'utils/memberLockUtils';
import { getFullNameFromMember } from 'utils/stringUtils';
import { displayEndTime } from 'utils/timeEntryUtils';
import TimeEntryViewBreak from '../../time-entry-break/TimeEntryViewBreak/TimeEntryViewBreak';
import TimeEntryGPSIcon from '../TimeEntryDataTable/TimeEntryGPSIcon';
import './TimeEntryView.scss';

interface ITimeEntryViewProps {
  className?: ClassName;
  timeEntryId?: string;
  isOpen: boolean;
  onEdit?: (timeEntryData: ITimeActionsFormData) => void;
  onClose: () => void;
}

const TimeEntryView = (props: ITimeEntryViewProps) => {
  const { className, timeEntryId, isOpen, onClose, onEdit } = props;

  const classes = classNames(
    {
      column: true,
    },
    className
  );

  const { hasPermissionToManage } = usePermissions();
  const gpsStatusDisabledFeature = useFeatureFlags('GPS_STATUS_DISABLED');
  const locationFlaggingFeature = useFeatureFlags('LOCATION_FLAGGING');

  const [timeEntry, setTimeEntry] = useState<ITimeEntry>();

  const organization = useOrganization();
  const logDetails = useOpenable();
  const editDetails = useOpenable();
  const locationHistoryDetails = useOpenable();
  const userEvents = useTimesheetsGraylog();
  const lockDialogDetails = useOpenable();
  const client = useApolloClient();
  const [loading, setLoading] = useState<number>(0);

  useEffect(() => {
    if (isOpen) {
      userEvents.events(TimesheetsTypes.events.action_type.VIEW_ENTRY);
      getTimeEntry();
    }
  }, [isOpen]);

  async function getTimeEntry() {
    setLoading((_loading) => _loading + 1);
    client
      .query<{ timeEntries: ITimeEntry[] }>({
        query: TIME_ENTRY_BY_ID_QUERY,
        variables: { timeEntryId },
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        if (!isEmpty(result.data.timeEntries)) {
          setTimeEntry(result.data.timeEntries[0]);
        }
      })
      .catch(() => {
        setTimeEntry(undefined);
      })
      .finally(() => setLoading((_loading) => _loading - 1));
  }

  function renderRange() {
    return (
      <Row>
        {renderStart()}
        {renderEnd()}
      </Row>
    );
  }

  function renderStart() {
    let startTime;
    if (timeEntry && timeEntry.startTime) {
      startTime = dateUtils.dateTimeFromISOKeepZone(timeEntry.startTime);
    }
    let endTime;
    if (timeEntry && timeEntry.endTime) {
      endTime = dateUtils.dateTimeFromISOKeepZone(timeEntry.endTime);
    }
    return (
      <Col>
        <div className="col-header">{t('Start')}</div>
        {startTime ? (
          <div>
            <div className="col-value">
              <TimeAndSuperscript dateTime={startTime} dst={timeEntry!.daylightSavingTime} compareDateTime={endTime} />
            </div>
            <div className="col-value">{startTime.toFormat(timeEntryUtils.getEntryDateFormatter(timeEntry!))}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </Col>
    );
  }

  function renderEnd() {
    let startTime;
    if (timeEntry && timeEntry.startTime) {
      startTime = dateUtils.dateTimeFromISOKeepZone(timeEntry.startTime);
    }
    let endTime;
    if (timeEntry && timeEntry.endTime) {
      endTime = displayEndTime(timeEntry);
    }
    return (
      <Col>
        <div className="col-header">{t('End')}</div>
        {endTime ? (
          <div>
            <div className="col-value">
              <TimeAndSuperscript
                dateTime={endTime}
                dst={timeEntry?.metaDaylightSavingTime === undefined ? false : timeEntry!.metaDaylightSavingTime!}
                compareDateTime={startTime}
              />
            </div>
            <div className="col-value">{endTime.toFormat(timeEntryUtils.getEntryDateFormatter(timeEntry!))}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </Col>
    );
  }

  function renderBreaks() {
    return (
      <Row>
        <Col>
          <span className="col-header">{t('Breaks')}</span>
          {timeEntry && timeEntry.breaks && !_.isEmpty(timeEntry.breaks.filter((brk) => brk.deletedOn === null)) ? (
            sortBy(
              timeEntry.breaks.filter((brk) => brk.deletedOn === null),
              'startTime'
            ).map((item: IBreak, index: number) => {
              return (
                <div key={index} className="'col-value'">
                  <TimeEntryViewBreak entryBreak={item} timeEntry={item.timeEntry} />
                </div>
              );
            })
          ) : (
            <div className="col-value">---</div>
          )}
        </Col>
      </Row>
    );
  }

  function renderTotal() {
    if (timeEntry && timeEntry.startTime && timeEntry.endTime) {
      return (
        <Row>
          <Col>
            <div className="col-header">{t('Total')}</div>
            <div className="col-value">
              {stringUtils.getTotalAsHoursMinutesSeconds(timeEntryUtils.getTotal(timeEntry))}
            </div>
          </Col>
        </Row>
      );
    }
  }

  function renderProject() {
    return (
      <Row>
        <Col>
          <div className="col-header">{t('Project')}</div>
          {timeEntry && timeEntry.project ? (
            <div className="col-value">{projectUtils.getFormattedPathFromProject(timeEntry.project, true)}</div>
          ) : (
            <div className="col-value">---</div>
          )}
        </Col>
      </Row>
    );
  }

  function renderCostCode() {
    return (
      <div>
        {organization && organization.trackCostCode && (
          <Row>
            <Col>
              <div className="col-header">{t('Cost Code')}</div>
              {timeEntry && timeEntry.costCode ? (
                <div className="col-value">{stringUtils.getCostCodeDisplay(timeEntry.costCode)}</div>
              ) : (
                <div className="col-value">---</div>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }

  function renderEquipment() {
    return (
      <div>
        {organization && organization.trackEquipment && (
          <Row>
            <Col>
              <div className="col-header">{t('Equipment')}</div>
              {timeEntry && timeEntry.equipment ? (
                <div className="col-value">{stringUtils.getEquipmentDisplay(timeEntry.equipment)}</div>
              ) : (
                <div className="col-value">---</div>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }

  function renderDescription() {
    return (
      <Row>
        <Col>
          <div className="col-header">{t('Description')}</div>
          {timeEntry && timeEntry.description ? (
            <div className="col-value entry-description">
              <EmbeddedLinkedText>{timeEntry.description}</EmbeddedLinkedText>
            </div>
          ) : (
            <div className="col-value">---</div>
          )}
        </Col>
      </Row>
    );
  }

  function renderHeaderButtons() {
    const buttons = [];

    if (timeEntry) {
      buttons.push(
        <TimeEntryGPSIcon
          timeEntry={timeEntry}
          flaggedTimeEntryLocation={timeEntry.flaggedTimeEntryLocation}
          project={timeEntry.project}
          memberGpsStatuses={timeEntry.memberGpsStatuses}
          onClick={locationHistoryDetails.open}
        />
      );

      if (hasPermissionToManage(timeEntry.member, 'manageTimeEntries') && isNil(timeEntry.member.archivedOn)) {
        buttons.push(<IconButton key="edit" size={Size.MEDIUM} onClick={handleEditOpen} svg={EditIcon} />);
      }

      if (timeEntry.clientLogs && !_.isEmpty(timeEntry.clientLogs)) {
        buttons.push(<IconButton key="logs" size={Size.MEDIUM} onClick={logDetails.open} svg={InfoStroke} />);
      }
    }
    return buttons;
  }

  function handleEditOpen() {
    if (timeEntry && timeEntry.member.memberLock?.effectiveDate) {
      const effectiveDate = DateTime.fromISO(timeEntry.member.memberLock?.effectiveDate);
      const start = dateUtils.dateTimeFromISOKeepZone(timeEntry.startTime);
      if (inhibitsActions(effectiveDate, start)) {
        lockDialogDetails.open();
        return;
      }
    }
    editDetails.open();
  }

  function handleMoveLockDate() {
    lockDialogDetails.close();
    editDetails.open();
    getTimeEntry();
  }

  function handleEditSubmit(timeEntryFormData: ITimeActionsFormData) {
    if (onEdit) {
      onEdit(timeEntryFormData);
    }
    editDetails.close();
    getTimeEntry();
  }

  function handleDelete(timeEntryFormData: ITimeActionsFormData) {
    if (onEdit) {
      onEdit(timeEntryFormData);
    }
    editDetails.close();
    onClose();
  }

  return (
    <div className={classNames({ 'time-entry-view': true, loading })}>
      <HeaderDialog
        isOpen={isOpen}
        title={t('Time Entry')}
        subtitle={timeEntry && getFullNameFromMember(timeEntry.member)}
        headerSize={Size.XL}
        onClose={onClose}
        buttons={renderHeaderButtons()}
      >
        <Container className={classes}>
          {renderRange()}
          {renderBreaks()}
          {renderTotal()}
          {renderProject()}
          {renderCostCode()}
          {renderEquipment()}
          {renderDescription()}
        </Container>
      </HeaderDialog>
      <TimeEntryLogsView timeEntryId={timeEntryId} isOpen={logDetails.isOpen} onClose={logDetails.close} />
      <TimeActionsFormDialog
        type={timeEntryId ? 'edit' : 'add'}
        timeEntryId={timeEntryId}
        isOpen={editDetails.isOpen}
        onClose={editDetails.close}
        onSubmit={handleEditSubmit}
        onDelete={handleDelete}
      />
      {timeEntry ? (
        <LocationHistoryDialog
          scope={timeEntry.id}
          isOpen={locationHistoryDetails.isOpen}
          onClose={locationHistoryDetails.close}
          member={timeEntry.member}
        />
      ) : (
        <></>
      )}
      {timeEntry && (
        <LockDateDialog
          date={dateUtils.dateTimeFromISOKeepZone(timeEntry.startTime)}
          type={LockDateDialogType.SINGLE_EDIT}
          memberIds={[timeEntry.member.id]}
          isOpen={lockDialogDetails.isOpen}
          onClose={lockDialogDetails.close}
          onMoveLockDate={handleMoveLockDate}
        />
      )}
    </div>
  );
};

export default TimeEntryView;
