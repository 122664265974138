import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IDashboardGridSettings } from 'types/DashboardGridSettings';
import useMemberSettings from '../member/useMemberSettings';
import useDashboardSettingsUpdate from './useDashboardSettingsUpdate';

export default function useDashboardGridSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateDashboard = useDashboardSettingsUpdate();

  return useCallback(
    <K extends keyof IDashboardGridSettings>(keysAndPayloads: { key: K; payload: IDashboardGridSettings[K] }[]) => {
      const cloned = cloneDeep(memberSettings?.web?.ui?.dashboard?.dashboardGridSettings);

      const mapped = keysAndPayloads.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.key]: cur.payload,
        }),
        cloned
      );
      return updateDashboard('dashboardGridSettings', mapped);
    },
    [memberSettings, updateDashboard]
  );
}
