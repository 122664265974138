import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useScrollBreakpoint } from 'hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import ICursorable from 'types/Cursorable';
import { Nullable } from 'types/util/Nullable';
import { convertGetDataToReactQueryPager, getGraphqlNextPageParam } from 'utils/reactQueryUtils';
import { useClearReactQueryKey } from '../useClearReactQueryKey/useClearReactQueryKey';
import { graphqlPagingLoopShouldBreak } from 'utils/graphQlUtils';
import { last } from 'lodash';

type PassableOptions<T> = Omit<
  UseInfiniteQueryOptions<T, DefaultError, InfiniteData<T>, any, QueryKey, Nullable<string>>,
  'queryKey' | 'queryFn' | 'getNextPageParam' | 'initialPageParam'
>;

export function useReactQueryLazyLoading<T extends ICursorable>(
  scroller: Nullable<HTMLElement>,
  queryKey: QueryKey,
  getData: (after: string | null, pageSize: number) => Promise<T[]>,
  pageSize: number = 50,
  options?: PassableOptions<T[]>
) {
  const { data, ...rest } = useInfiniteQuery({
    ...options,
    queryKey,
    queryFn: convertGetDataToReactQueryPager<T[]>((after) => getData(after ?? null, pageSize)),
    initialPageParam: null,
    getNextPageParam: getGraphqlNextPageParam(pageSize),
  });

  const infiniteScroll = useMemo(
    () => ({
      ...rest,
      data: data?.pages ? data?.pages?.flat() : null,
    }),
    [data?.pages, rest]
  );

  const fetching = useRef(false);
  const getNextPage = useCallback(async () => {
    if (!fetching.current && infiniteScroll.hasNextPage) {
      fetching.current = true;
      await infiniteScroll.fetchNextPage();
      fetching.current = false;
    }
  }, [infiniteScroll]);

  const loadAll = useCallback(async () => {
    let data = infiniteScroll.data ?? [];

    while (infiniteScroll.hasNextPage) {
      // Fetch next page will have all pages attached
      const result = await infiniteScroll.fetchNextPage();
      const lastPage = last(result?.data?.pages);

      if (graphqlPagingLoopShouldBreak(lastPage, pageSize)) {
        data = result?.data?.pages?.flat() ?? [];
        break;
      }
    }

    return data;
  }, [infiniteScroll]);

  useScrollBreakpoint(scroller, getNextPage);

  // Used for when the request payload doesn't result in a render that is scrollable so there's no way to trigger getting more data
  useEffect(() => {
    function getPageIfUnscrollable() {
      if (scroller) {
        const scrollerIsScrollable = scroller.scrollHeight > scroller.clientHeight;
        if (!scrollerIsScrollable && !fetching.current && infiniteScroll.hasNextPage) {
          getNextPage();
        }
      }
    }

    getPageIfUnscrollable();
    window.addEventListener('resize', getPageIfUnscrollable);

    return () => {
      window.removeEventListener('resize', getPageIfUnscrollable);
    };
  }, [scroller, infiniteScroll]);

  return {
    ...infiniteScroll,
    isLoading: infiniteScroll.isLoading || fetching.current,
    loadAll,
    loadedAll: !infiniteScroll.hasNextPage,
    clearData: useClearReactQueryKey(queryKey),
  };
}
