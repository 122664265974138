import { FlaggedTimeEntryLocation, MemberGpsStatus } from '__generated__/graphql';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { usePermissions } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { includes, isEmpty, isNil, some } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useRef } from 'react';
import ISafetySignature from 'types/SafetySignature';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import IMemberTimeMetrics from 'types/aggregate/MemberLaborMetrics';
import { dateUtils, stringUtils, timeEntryUtils } from 'utils';
import { combineDateAndTime, dateTimeFromISOWithoutZone, isDateTimeInTimeRange } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { getDisabledMemberGpsStatuses } from 'utils/locationUtils';
import { getTotalAsHoursMinutesSeconds } from 'utils/stringUtils';
import { displayEndTime, hasLocation, isTimeEntryClosed, timeEntryEndTimeOrNow } from 'utils/timeEntryUtils';
import { ITimeEntryDataTableRow } from '../TimeEntryDataTable';

export default function useTimeEntryToTimeEntryRowMap() {
  const conflicts = useRef<string[]>([]);
  const { hasPermissionToManage } = usePermissions();
  const gpsStatusDisabledFeature = useFeatureFlags('GPS_STATUS_DISABLED');
  const locationFlaggingFeature = useFeatureFlags('LOCATION_FLAGGING');

  const employeeNameFormatter = useEmployeeNameFormatter();

  return useCallback(
    (
      entry: Pick<
        ITimeEntry,
        | 'id'
        | 'startTime'
        | 'endTime'
        | 'member'
        | 'project'
        | 'costCode'
        | 'equipment'
        | 'hasLocation'
        | 'clientLogs'
        | 'breaks'
        | 'startLocation'
        | 'endLocation'
        | 'daylightSavingTime'
        | 'metaDaylightSavingTime'
        | 'description'
        | 'flaggedTimeEntryLocation'
        | 'memberGpsStatuses'
      >,
      timeRange: ITimeRange<DateTime>,
      safetyData?: ISafetySignature[],
      getMetrics?: (timeRange: ITimeRange<DateTime>, memberId: string) => Promise<IMemberTimeMetrics[]>
    ) => {
      function getEntryStartTimeString(
        showMultiDay: boolean,
        isFirstDay: boolean,
        totalString: string,
        date: DateTime
      ) {
        if (showMultiDay) {
          if (totalString === '23:59' || !isFirstDay) {
            return date.startOf('day').toFormat('t*');
          }
          return date.toFormat('t');
        }
        return date.toFormat('t');
      }

      function getEntryEndTimeString(showMultiDay: boolean, isLastDay: boolean, totalString: string, date?: DateTime) {
        if (date) {
          if (showMultiDay) {
            if (totalString === '23:59' || !isLastDay) {
              return date.endOf('day').toFormat('t*');
            }
            return date.toFormat('t');
          }
          return date.toFormat('t');
        }
        return '---';
      }

      function getExportStartISO(showMultiDay: boolean, isFirstDay: boolean, totalString: string, date: DateTime) {
        if (showMultiDay && (totalString === '23:59' || !isFirstDay)) {
          return date.startOf('day').toISO();
        }
        return date.toISO();
      }

      function getExportEndISO(showMultiDay: boolean, isLastDay: boolean, totalString: string, date: DateTime) {
        if (showMultiDay && (totalString === '23:59' || !isLastDay)) {
          return date.endOf('day').toISO();
        }
        return date.toISO();
      }

      function getActions() {
        const disabledStatues: Pick<MemberGpsStatus, 'status' | 'localTime'>[] = gpsStatusDisabledFeature
          ? getDisabledMemberGpsStatuses(entry.memberGpsStatuses, entry)
          : [];
        const flaggedTimeEntryLocation: FlaggedTimeEntryLocation | null | undefined = locationFlaggingFeature
          ? entry.flaggedTimeEntryLocation
          : null;

        const actions = {
          logs: entry.clientLogs && !isEmpty(entry.clientLogs),
          // `hasLocation` returns the location if it's set
          locations: hasLocation(entry) || entry.hasLocation || !isEmpty(disabledStatues),
          conflicts: includes(conflicts.current, entry.id),
          splitEntry: isTimeEntryClosed(entry),
          memberGpsStatuses: disabledStatues,
          flaggedTimeEntryLocation,
        };

        return actions;
      }

      function createTimeEntryRow(day: DateTime, total: number, showMultiDay: boolean) {
        const actions = getActions();
        const startTime = dateUtils.dateTimeFromISOKeepZone(entry.startTime);
        let endTime;
        if (!isNil(entry.endTime)) {
          endTime = displayEndTime(entry);
        }

        const dayStart = day.setZone(startTime.zoneName, { keepLocalTime: true });
        const dayEnd = day.setZone(endTime ? endTime.zoneName : DateTime.local().zoneName, { keepLocalTime: true });

        const breakTotalSeconds = timeEntryUtils.getBreakTotal({
          ...entry,
          startTime: startTime < dayStart.startOf('day') ? dayStart.startOf('day').toISO() : startTime.toISO(),
          endTime:
            (endTime ? endTime : DateTime.local()) > dayEnd.endOf('day')
              ? dayEnd.endOf('day').plus({ minute: 1 }).toISO()
              : (endTime ? endTime : DateTime.local()).toISO(),
        });

        const item: ITimeEntryDataTableRow = {
          id: entry.id,
          key: entry.id + dateUtils.getDateString(day, 'ccc, LLL d', true),
          entry: entry,
          member: entry.member,
          startDate: startTime,
          endDate: endTime ? endTime : DateTime.local(),
          date: dateUtils.getDateString(day, 'ccc, LLL d', true),
          day,
          employee: employeeNameFormatter(entry.member.firstName ?? '', entry.member.lastName ?? ''),
          type: t('Entry'),
          total: endTime ? stringUtils.getTotalAsHoursMinutesSeconds(total) : '---',
          totalSeconds: total,
          start: getEntryStartTimeString(
            showMultiDay,
            startTime.day === dayStart.day,
            stringUtils.getTotalAsHoursMinutesSeconds(total),
            startTime
          ),
          startSup: dateUtils.getTimezoneSupStringForDate(
            startTime,
            isNil(entry.daylightSavingTime) ? false : entry.daylightSavingTime!,
            endTime
          ),
          end: getEntryEndTimeString(
            showMultiDay,
            endTime ? endTime.day === dayEnd.day : true,
            stringUtils.getTotalAsHoursMinutesSeconds(total),
            endTime
          ),
          endSup: endTime
            ? dateUtils.getTimezoneSupStringForDate(
                endTime,
                isNil(entry.metaDaylightSavingTime) ? false : entry.metaDaylightSavingTime!,
                startTime
              )
            : null,
          breaks: stringUtils.getTotalAsHoursMinutesSeconds(
            timeEntryUtils.getBreakTotal({
              ...entry,
              startTime: startTime < dayStart.startOf('day') ? dayStart.startOf('day').toISO() : startTime.toISO(),
              endTime:
                (endTime ? endTime : DateTime.local()) > dayEnd.endOf('day')
                  ? dayEnd.endOf('day').plus({ minute: 1 }).toISO()
                  : (endTime ? endTime : DateTime.local()).toISO(),
            })
          ),
          breakSeconds: breakTotalSeconds,
          project: entry.project,
          costCode: entry.costCode,
          equipment: entry.equipment,
          description: entry.description ? entry.description : '---',
          actions,
          breakCompliance: some(safetyData, (s) => !isNil(s.breakPolicyFollowed))
            ? some(safetyData, (s) => s.breakPolicyFollowed === false && isNil(s.breakPolicyFollowedResolvedOn))
              ? t('No')
              : t('Yes')
            : '---',
          timeAccurate: some(safetyData, (s) => !isNil(s.timeAccurate))
            ? some(safetyData, (s) => s.timeAccurate === false && isNil(s.timeAccurateResolvedOn))
              ? t('No')
              : t('Yes')
            : '---',
          injured: some(safetyData, (s) => !isNil(s.injured))
            ? some(safetyData, (s) => s.injured === true && isNil(s.injuredResolvedOn))
              ? t('Yes')
              : t('No')
            : '---',
          otSeconds: 0,
          dtSeconds: 0,
          ot: '---',
          dt: '---',
          // Metadata not shown in table
          timesheetReportType: TimesheetReportType.ENTRY,
          canManage: entry.member ? hasPermissionToManage(entry.member, 'manageTimeEntries') : false,
          isArchived: !isNil(entry.member.archivedOn),
          exportStartISO: getExportStartISO(
            showMultiDay,
            startTime.day === dayStart.day,
            stringUtils.getTotalAsHoursMinutesSeconds(total),
            combineDateAndTime(day, startTime)
          ),
          exportEndISO: getExportEndISO(
            showMultiDay,
            endTime ? endTime.day === dayEnd.day : true,
            stringUtils.getTotalAsHoursMinutesSeconds(total),
            combineDateAndTime(day, endTime ? endTime : DateTime.local())
          ),
        };

        if (getMetrics) {
          getMetrics({ startTime, endTime: endTime ?? DateTime.local() }, entry.member.id).then((res) => {
            res.map((r) => {
              item.otSeconds += !isNil(r.ot) && r.memberId === entry.member.id ? r.ot : 0;
              item.dtSeconds += !isNil(r.dt) && r.memberId === entry.member.id ? r.dt : 0;
            });
          });

          item.ot = getTotalAsHoursMinutesSeconds(item.otSeconds);
          item.dt = getTotalAsHoursMinutesSeconds(item.dtSeconds);
        }

        return item;
      }

      const timEntryDates = dateUtils.getDateTimesBetween(
        dateTimeFromISOWithoutZone(entry.startTime),
        dateTimeFromISOWithoutZone(timeEntryEndTimeOrNow(entry).toString())
      );

      return timEntryDates
        .filter((e: DateTime) => isDateTimeInTimeRange(e, timeRange, true, true))
        .map((day) => createTimeEntryRow(day, timeEntryUtils.totalForDay(day, entry), timEntryDates.length > 1));
    },
    [employeeNameFormatter, hasPermissionToManage]
  );
}
