import { Incident, IncidentAnswerTypes } from '__generated__/graphql';
import { first } from 'lodash';
import { DateTime } from 'luxon';
import ICursorable from 'types/Cursorable';
import { dateTimeFromISOKeepZone, getDateName, getDateNameWithWeekday } from 'utils/dateUtils';

export function useCreateSafetyIncidentsReportedRowItem() {
  function createRow(incidents: (Incident & ICursorable)[]) {
    return incidents.map((item: Incident) => {
      const locationDetailsAnswer = first(
        item.answers.filter((answer) => answer.type === IncidentAnswerTypes.Location)
      );
      const incidentDescriptionAnswer = first(
        item.answers.filter((answer) => answer.type === IncidentAnswerTypes.WhatHappened)
      );
      const injuryDescriptionAnswer = first(
        item.answers.filter((answer) => answer.type === IncidentAnswerTypes.TypeDescription)
      );
      const harmfulSubstanceAnswer = first(
        item.answers.filter((answer) => answer.type === IncidentAnswerTypes.WhatHarmed)
      );
      const activityBeforeAnswer = first(
        item.answers.filter((answer) => answer.type === IncidentAnswerTypes.ActivityBefore)
      );
      return {
        id: item.id,
        injuryDate: getDateNameWithWeekday(dateTimeFromISOKeepZone(item.occurredAt))!,
        status: item.state,
        caseNumber: item.caseNumber,
        member: item.member,
        type: item.type,
        recordable: item.recordable,
        treatment: item.medicalDetails,
        project: item.project,
        locationDetails: locationDetailsAnswer?.answer,
        incidentDescription: incidentDescriptionAnswer?.answer ?? '---',
        injuryDescription: injuryDescriptionAnswer?.answer ?? '---',
        harmfulSubstance: harmfulSubstanceAnswer?.answer ?? '---',
        activityBefore: activityBeforeAnswer?.answer ?? '---',
        reportedBy: item.submittedByMember!,
        reportedOn: getDateName(dateTimeFromISOKeepZone(item.createdOnLocal))!,
        reviewedBy: item.reviewedByMember ?? undefined,
        reviewedOn: item.reviewedOn ? getDateName(dateTimeFromISOKeepZone(item.reviewedOn)) : '',
        completedBy: item.completedByMember ?? undefined,
        completedOn: item.completedOn ? getDateName(dateTimeFromISOKeepZone(item.completedOn)) : '',
        createdOn: DateTime.fromISO(item.createdOnLocal),
        employeeJobTitle: item.employeeJobTitle ?? undefined,
        employeeBirthday: item.employeeBirthday ?? undefined,
        employeeHireDate: item.employeeHireDate ?? undefined,
        employeeSex: item.employeeSex ?? undefined,
        employeeAddress1: item.employeeAddress1 ?? undefined,
        employeeCity: item.employeeCity ?? undefined,
        employeeState: item.employeeState ?? undefined,
        employeePostalCode: item.employeePostalCode ?? undefined,
        facilityName: item.medicalDetails?.facilityName ?? undefined,
        facilityAddress: item.medicalDetails?.facilityAddress ?? undefined,
        facilityCity: item.medicalDetails?.facilityCity ?? undefined,
        facilityState: item.medicalDetails?.facilityState ?? undefined,
        facilityPostalCode: item.medicalDetails?.facilityPostalCode ?? undefined,
        physicianName: item.medicalDetails?.physicianName ?? undefined,
        occurredAt: item.occurredAt ?? undefined,
        occurrenceTimeSet: item.occurrenceTimeSet,
        employeeStartTime: item.employeeStartTime ?? undefined,
        outcomeDetails: item.outcomeDetails,
        illnessType: item.illnessType,
      };
    });
  }

  return createRow;
}
