import useIsInIframe from 'hooks/store/useIsInIframe';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import useIframeMessageListener from '../useIframeMessageListener/useIframeMessageListener';

export default function useIframeFeatures() {
  const isInIframe = useIsInIframe();

  useOnMount(() => {
    if (isInIframe) {
      // Temporarily used for themeing for align product
      // Sets button primary color to be align's primary color in App.scss
      // Needs to be on the body and not `app` due to dialogs being rendered in a portal
      // outside of the main app.
      document.querySelector('body')?.classList.add('is-in-iframe');
    }
  });

  useIframeMessageListener();
}
