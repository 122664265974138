import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import QuickTimeTemplatePicker, {
  IQuickTimeTemplatePickerProps,
} from 'components/domain/pickers/QuickTimeTemplatePicker/QuickTimeTemplatePicker';
import { AnyObject } from 'types/util/Object';
import './QuickTimeTemplatePickerFormField.scss';

export interface IQuickTimeTemplatePickerFormFieldProps<FormType extends AnyObject>
  extends Omit<IQuickTimeTemplatePickerProps, 'value' | 'data' | 'onSelect'>,
    IFormFieldDefaultProps<string[], FormType> {
  onChange?: (value: string) => void;
}

function QuickTimeTemplatePickerFormField<FormType extends AnyObject>({
  form,
  name,
  validations,
  onChange,
  className,
  revalidateOnNewValidations,
  ...basicPickerProps
}: IQuickTimeTemplatePickerFormFieldProps<FormType>) {
  function renderField({ ...fieldProps }) {
    function handleOnChange(value: string | null) {
      fieldProps.handleChange(value, onChange);
    }

    return (
      <>
        <QuickTimeTemplatePicker value={form.state.data[name]} onSelect={handleOnChange} {...basicPickerProps} />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      className={classNames('quick-time-template-picker-form-field', className)}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

export default QuickTimeTemplatePickerFormField;
