import { useOpenable } from 'hooks';
import { sha256 } from 'js-sha256';
import * as React from 'react';
import { lazy, Suspense, useState } from 'react';
import { IStoreAuthentication, IStoreEnvironment, IStoreSession } from 'store/types';

const DevConfig = lazy(() => import('./DevConfig'));

export interface IBusyAppDevUtils {
  about?: () => {
    token: IStoreAuthentication['token'];
    session: IStoreSession;
    environment: IStoreEnvironment;
  };
  showConfig?: () => void;
}

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    BusyApp: IBusyAppDevUtils;
  }
}

const accessCode = 'f193fa9b348e43160094cc1256166b476ba5e8ed34d4d1d027bb40f561822a76';
const validateAccess = () => sha256(window.prompt('Provide access code') || '') === accessCode;

const DevConfigLoader: React.FC = () => {
  const [allowDevConfig, setAllowDevConfig] = useState(false);
  const configPanelControls = useOpenable();

  window.BusyApp = window.BusyApp ?? {};

  window.BusyApp.showConfig = () => {
    if (allowDevConfig || validateAccess()) {
      setAllowDevConfig(true);
      configPanelControls.open();
    } else {
      setAllowDevConfig(false);
    }
  };

  return allowDevConfig ? (
    <Suspense fallback={<></>}>
      <DevConfig panelControls={configPanelControls} />
    </Suspense>
  ) : null;
};

export default DevConfigLoader;
