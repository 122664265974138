import { ClassName } from '@busybusy/webapp-react-ui';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import { useTimesheetsGraylog } from 'hooks';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { t } from 'utils/localize';
import SignOffReport from '../SignOffReport/SignOffReport';

export interface ISignOffReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  memberIds?: string[] | null;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  onDataChange: () => void;
  className?: ClassName;
}

const SignOffReportDialog = ({
  className,
  isOpen,
  onClose,
  memberIds,
  timeRange,
  timeRangeType,
  onDataChange,
}: ISignOffReportDialogProps) => {
  const userEvents = useTimesheetsGraylog();
  const userEventsStable = useRef(userEvents.events);

  useEffect(() => {
    if (isOpen) {
      userEventsStable.current(TimesheetsTypes.events.action_type.VIEW_DAILY_SIGN_OFF);
    }
  }, [isOpen]);

  function renderSignOffReport(componentTimeRange: ITimeRange<DateTime>): ReactNode {
    return <SignOffReport employeeIds={memberIds} timeRange={componentTimeRange} onDataChange={onDataChange} />;
  }

  return (
    <ManagedTimeRangeReportDialog
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      timeRange={timeRange}
      timeRangeType={timeRangeType}
      reportComponent={renderSignOffReport}
      title={t('Daily Sign-Off')}
    />
  );
};

export default SignOffReportDialog;
