import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVisibleTableColumn } from 'types/TableColumn';

export interface IStoreTimesheets {
  checkedMemberIds: string[];
  checkedTimeEntryIds: string[];
  checkedTimeOffIds: string[];
  summaryAndTimeCardTableColumns: IVisibleTableColumn[];
  timeEntryTableColumns: IVisibleTableColumn[];
  weeklyColumns: IVisibleTableColumn[];
  dailyColumns: IVisibleTableColumn[];
}

export enum TimesheetView {
  SUMMARY = 'Summary',
  TIME_CARDS = 'Time Cards',
  TIME_ENTRIES = 'Time Entries',
  EXPANDED_TIME_CARDS = 'Expanded Time Cards',
  WEEKLY_GRID = 'Weekly Grid',
}

export const initialState: IStoreTimesheets = {
  checkedMemberIds: [],
  checkedTimeEntryIds: [],
  checkedTimeOffIds: [],
  summaryAndTimeCardTableColumns: [],
  timeEntryTableColumns: [],
  weeklyColumns: [],
  dailyColumns: [],
};

const slice = createSlice({
  name: 'timesheets',
  initialState,
  reducers: {
    updateCheckedMemberIds: (state: IStoreTimesheets, action: PayloadAction<string[]>) => {
      state.checkedMemberIds = action.payload;

      return state;
    },
    clearCheckedMemberIds: (state: IStoreTimesheets) => {
      state.checkedMemberIds = [];
      return state;
    },
    updateCheckedTimeEntryIds: (state: IStoreTimesheets, action: PayloadAction<string[]>) => {
      state.checkedTimeEntryIds = action.payload;
      return state;
    },
    clearCheckedTimeEntryIds: (state: IStoreTimesheets) => {
      state.checkedTimeEntryIds = [];
      return state;
    },
    updateCheckedTimeOffIds: (state: IStoreTimesheets, action: PayloadAction<string[]>) => {
      state.checkedTimeOffIds = action.payload;
      return state;
    },
    clearCheckedTimeOffIds: (state: IStoreTimesheets) => {
      state.checkedTimeOffIds = [];
      return state;
    },
    updateSummaryAndTimeCardTableColumns: (state: IStoreTimesheets, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.summaryAndTimeCardTableColumns = action.payload;

      return state;
    },
    updateTimeEntryTableColumns: (state: IStoreTimesheets, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.timeEntryTableColumns = action.payload;

      return state;
    },
    updateWeeklyColumns: (state: IStoreTimesheets, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.weeklyColumns = action.payload;
      return state;
    },
    updateDailyColumns: (state: IStoreTimesheets, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.dailyColumns = action.payload;
      return state;
    },
  },
});

export const {
  updateCheckedMemberIds,
  clearCheckedMemberIds,
  updateCheckedTimeEntryIds,
  clearCheckedTimeEntryIds,
  updateCheckedTimeOffIds,
  clearCheckedTimeOffIds,
  updateSummaryAndTimeCardTableColumns,
  updateTimeEntryTableColumns,
  updateWeeklyColumns,
  updateDailyColumns,
} = slice.actions;
export default slice.reducer;
