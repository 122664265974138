import {} from '@busybusy/webapp-react-ui';
import { isNull } from 'lodash';
import * as React from 'react';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

export interface ICommandPalletItemProps {
  className?: string;
  children: ReactNode;
  focus: boolean;
  setFocus: () => void;
  onEnter: () => void;
  isClickable: boolean;
}

const CommandPalletItem = (props: ICommandPalletItemProps) => {
  const { children, focus, setFocus, onEnter, className, isClickable } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (focus && !isNull(ref.current)) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [focus]);

  const handleSelect = useCallback(() => {
    // setting focus to that element when it is selected
    setFocus();
    onEnter();
  }, [children, setFocus]);

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      onEnter();
    }
  }

  return (
    <div
      className={className}
      tabIndex={focus ? 0 : -1}
      role="button"
      ref={ref}
      onClick={isClickable ? handleSelect : undefined}
      onKeyPress={isClickable ? handleSelect : undefined}
      onKeyDown={handleKeyDown}
    >
      {children}
    </div>
  );
};

export default CommandPalletItem;
