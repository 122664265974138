import { ClassName, Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import { ReactNode } from 'react';
import { AnyObject } from 'types/util/Object';

export interface IBasicFormFieldProps<T, FormType extends { [field: string]: any } = AnyObject>
  extends IFormFieldDefaultProps<T, FormType> {
  onSelect?: (value: T) => void;
  children: (value: T, onSelect: (v: T) => void, error: boolean) => ReactNode;
  className?: ClassName;
}

export default function BasicFormField<T, FormType extends { [field: string]: any } = AnyObject>({
  children,
  onSelect,
  className,
  name,
  form,
  revalidateOnNewValidations,
  ...rest
}: IBasicFormFieldProps<T, FormType>) {
  function renderField({ ...fieldProps }) {
    function handleOnSelect(value: T) {
      fieldProps.handleChange(value, onSelect);
    }

    const value = form.state.data[name];

    return (
      <>
        {children(value, handleOnSelect, fieldProps.hasError)}
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      className={className}
      render={renderField}
      name={name}
      form={form}
      {...rest}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}
