import { Align, Button, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import SecretMenuWarningWell from 'components/domain/preferences/SecretMenuWarningWell/SecretMenuWarningWell';
import ConfirmationDialog from 'components/foundation/dialogs/ConfirmationDialog/ConfirmationDialog';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useActiveMemberUpdate, useOpenable } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { t } from 'i18next';
import { useState } from 'react';
import ReactJson from 'react-json-view';
import { ClassName } from 'types/ClassName';
import { IMemberGlobalSettings } from 'types/MemberGlobalSettings';
import { IMemberWebSettings } from 'types/MemberWebSettings';

export interface IMemberSettingsBlobDetailsProps {
  className?: ClassName;
  onClose: () => void;
}

const MemberSettingsBlobDetails = (props: IMemberSettingsBlobDetailsProps) => {
  const { className, onClose } = props;
  const [updateKey] = useActiveMemberUpdate();
  const memberSettings = useMemberSettings();
  const confirmationDialog = useOpenable();
  const confirmDeleteDialog = useOpenable();
  const [currentWebSettings, setCurrentWebSettings] = useState(memberSettings?.web);
  const [currentGlobalSettings, setCurrentGlobalSettings] = useState(memberSettings?.global);

  const deleteBlob = () => {
    updateKey('memberSettings', null);
    handleClose();
  };

  const updateMemberSettingsBlob = () => {
    updateKey('memberSettings', JSON.stringify({ web: currentWebSettings, global: currentGlobalSettings }));
    handleClose();
  };

  const handleClose = () => {
    confirmationDialog.close();
    onClose();
  };

  const updateWebSettings = (data: { updated_src: IMemberWebSettings | undefined }) => {
    setCurrentWebSettings(data.updated_src);
  };

  const updateGlobalSettings = (data: { updated_src: IMemberGlobalSettings | undefined }) => {
    setCurrentGlobalSettings(data.updated_src);
  };

  const classes = classNames('member-settings-blob m-6', className);

  return (
    <>
      <div className={classes}>
        <div>
          <SecretMenuWarningWell />
          <h3>{t('Member settings')}</h3>
          <ReactJson
            name={'Web Settings'}
            collapsed={true}
            src={currentWebSettings ?? {}}
            onEdit={updateWebSettings}
            onDelete={updateWebSettings}
          />
          <ReactJson
            name={'Global Settings'}
            collapsed={true}
            src={currentGlobalSettings ?? {}}
            onEdit={updateGlobalSettings}
            onDelete={updateGlobalSettings}
          />
        </div>
        <div className="mt-5">
          <h3>{t('Actions')}</h3>
          <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER} className={'mt-4'}>
            <span>
              <Button onClick={confirmationDialog.open} type="primary" className={'mr-4'}>
                {t('Save Settings')}
              </Button>
              <Button onClick={onClose} type="secondary">
                {t('Cancel')}
              </Button>
            </span>
            <span>
              <Button onClick={confirmDeleteDialog.open} type="danger">
                {t('Delete All Settings')}
              </Button>
            </span>
          </Row>
        </div>
      </div>
      <ConfirmationDialog
        title={t('Confirm Changes')}
        message={t('Are you sure you want to make these changes?')}
        isOpen={confirmationDialog.isOpen}
        onClose={confirmationDialog.close}
        onConfirm={updateMemberSettingsBlob}
        confirmButtonText={t('Save')}
        closeButtonText={t('Cancel')}
      />
      <DeleteConfirmationDialog
        title={t('Confirm Delete')}
        onClose={confirmDeleteDialog.close}
        deleteText={t('Delete')}
        onDelete={deleteBlob}
        showHeaderDivider={false}
        isOpen={confirmDeleteDialog.isOpen}
      >
        {t('Are you sure you want to delete all settings?')}
      </DeleteConfirmationDialog>
    </>
  );
};

export default MemberSettingsBlobDetails;
