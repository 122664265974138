import { useCallback } from 'react';
import useGustoGetActiveEmployee from '../useGustoGetActiveEmployee/useGustoGetActiveEmployee';
import useGustoListSignatories from '../../signatory/useGustoListSignatories/useGustoListSignatories';
import useActivePosition from 'hooks/models/position/useActivePosition';
import { isAdmin } from 'utils/positionUtils';
import useGustoCompanyId from '../../company/useGustoCompanyId/useGustoCompanyId';
import { first } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';

export default function useGustoGetActiveEmployeeEmail() {
  const getGustoActiveEmployee = useGustoGetActiveEmployee();
  const activePosition = useActivePosition();
  const gustoCompanyId = useGustoCompanyId();
  const getSignatories = useGustoListSignatories();
  const queryClient = useQueryClient();
  const baseQueryKey = useReactQueryBaseKey();

  return useCallback(async () => {
    return queryClient.fetchQuery({
      queryKey: [...baseQueryKey, gustoCompanyId, 'gusto-active-employee-email'],
      queryFn: async () => {
        if (!gustoCompanyId) {
          return null;
        }

        const activeEmployee = await getGustoActiveEmployee();
        if (activeEmployee?.email) {
          return activeEmployee.email;
        }

        if (isAdmin(activePosition)) {
          const signatories = (await getSignatories(gustoCompanyId))?.data;

          if (!signatories) {
            return null;
          }

          // This seems terrible but the admin never actually has to be an employee in Gusto and matching to admin requires busybusy model changes and in the end when an admin
          // first authenticates we would have to ask which admin they are anyway if their email doesn't match across integration boundaries
          return first(signatories)?.email ?? null;
        }

        return null;
      },
    });
  }, [activePosition, baseQueryKey, getGustoActiveEmployee, getSignatories, gustoCompanyId, queryClient]);
}
