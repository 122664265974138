import { ITableColumn, TextAlign } from '@busybusy/webapp-react-ui';
import OptionallyFormattedHours from 'components/foundation/text/OptionallyFormattedHours/OptionallyFormattedHours';
import { useCurrencyNumberFormatter, useReduxSelector } from 'hooks';
import { isNumber, keyBy, sortBy, sumBy } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { t } from 'utils/localize';
import { ActivityReportType } from '../ActivityReportFilter/ActivityReportFilter';
import { IActivityReportRowInfo } from './ActivityReportData';
import { isActivityReportDetailRow } from './useActivityReportDetailColumn';

export default function useActivityReportDetailTable<RowType extends IActivityReportRowInfo>(
  data: RowType[],
  secondaryReportType: ActivityReportType,
  primaryReportType: ActivityReportType,
  nameColumnWidth: number = 300,
  cellClassName?: string
) {
  const formatter = useCurrencyNumberFormatter('usd', { maximumFractionDigits: 2 })!;
  const availableColumns = useReduxSelector((state) => state.activityReports.tableColumns);
  const availableEquipmentColumns = useReduxSelector((state) => state.activityReports.equipmentTableColumns);
  const columnsMap = useMemo(() => keyBy(availableColumns, 'key'), [availableColumns]);
  const equipmentColumnsMap = useMemo(() => keyBy(availableEquipmentColumns, 'key'), [availableEquipmentColumns]);

  const summaryDataRows = useMemo(() => {
    if (secondaryReportType === ActivityReportType.BASIC) {
      return data;
    }

    return data.filter((row) => !isActivityReportDetailRow(secondaryReportType, row));
  }, [data]);

  const renderReportTimeValue = (row: RowType, col: ITableColumn<RowType>) => {
    let answer: number | null = null;
    switch (col.key) {
      case 'regularHours':
      case 'regularHoursDec':
        answer = row.regularHours;
        break;
      case 'overtimeHours':
      case 'overtimeHoursDec':
        answer = row.overtimeHours;
        break;
      case 'doubleTimeHours':
      case 'doubleTimeHoursDec':
        answer = row.doubleTimeHours;
        break;
      case 'totalHours':
      case 'totalHoursDec':
        answer = row.totalHours;
        break;
    }

    const isDecimal = col.key.includes('Dec');

    return <OptionallyFormattedHours key={row.id} value={answer} payload={{ type: isDecimal ? 'DECIMAL' : 'TIME' }} />;
  };

  const renderReportCostValue = (row: any, col: ITableColumn<any>) => {
    let answer: number | null = null;

    const rowValue = row[col.key] ?? null;

    if (isNumber(rowValue) || rowValue === null) {
      answer = rowValue;
    }

    return answer ? formatter.format(answer) : '---';
  };

  function renderColumnTimeFooter(col: ITableColumn<RowType>): ReactNode {
    let answer: number | null = null;

    if (summaryDataRows) {
      switch (col.key) {
        case 'regularHours':
        case 'regularHoursDec':
          answer = sumBy(summaryDataRows, (entry) => entry.regularHours ?? 0);
          break;
        case 'overtimeHours':
        case 'overtimeHoursDec':
          answer = sumBy(summaryDataRows, (entry) => entry.overtimeHours ?? 0);
          break;
        case 'doubleTimeHours':
        case 'doubleTimeHoursDec':
          answer = sumBy(summaryDataRows, (entry) => entry.doubleTimeHours ?? 0);
          break;
        case 'totalHours':
        case 'totalHoursDec':
          answer = sumBy(summaryDataRows, (entry) => entry.totalHours ?? 0);
          break;
      }
    }

    const isDecimal = col.key.includes('Dec');

    return <OptionallyFormattedHours payload={{ type: isDecimal ? 'DECIMAL' : 'TIME' }} value={answer} />;
  }

  function renderColumnCostFooter(col: ITableColumn<RowType>): ReactNode {
    let answer: number | null = null;

    if (summaryDataRows) {
      answer = sumBy(summaryDataRows, col.key);
    }

    return answer ? formatter.format(answer) : '---';
  }

  const aggregateColumns = useMemo(() => {
    if (
      primaryReportType === ActivityReportType.BY_EQUIPMENT ||
      secondaryReportType === ActivityReportType.BY_EQUIPMENT
    ) {
      return sortBy(
        equipmentColumns().filter((col) => equipmentColumnsMap[col.key]?.visible === true),
        (col) => equipmentColumnsMap[col.key]?.position ?? 0
      );
    }

    return sortBy(
      standardColumns().filter((col) => columnsMap[col.key]?.visible === true),
      (col) => columnsMap[col.key]?.position ?? 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsMap, equipmentColumnsMap, data, secondaryReportType, primaryReportType]);

  function standardColumns() {
    return [
      {
        key: 'regularHours',
        title: t('Reg Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'regularHoursDec',
        title: t('Reg Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'overtimeHours',
        title: t('OT Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'overtimeHoursDec',
        title: t('OT Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'doubleTimeHours',
        title: t('DT Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'doubleTimeHoursDec',
        title: t('DT Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'totalHours',
        title: t('Total Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'totalHoursDec',
        title: t('Total Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'regularCost',
        title: t('Reg Cost'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'overtimeCost',
        title: t('OT Cost'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'doubletimeCost',
        title: t('DT Cost'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'totalCost',
        title: t('Total Cost'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'laborBurden',
        title: t('Labor Burden'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
    ];
  }

  function equipmentColumns() {
    return [
      {
        key: 'regularHours',
        title: t('Reg Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'regularHoursDec',
        title: t('Reg Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'overtimeHours',
        title: t('OT Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'overtimeHoursDec',
        title: t('OT Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'doubleTimeHours',
        title: t('DT Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'doubleTimeHoursDec',
        title: t('DT Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'totalHours',
        title: t('Total Hrs'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'totalHoursDec',
        title: t('Total Hrs (Decimal)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportTimeValue,
        cellClassName,
        footer: renderColumnTimeFooter,
      },
      {
        key: 'regularCost',
        title: t('Reg Cost (Labor)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'overtimeCost',
        title: t('OT Cost (Labor)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'doubletimeCost',
        title: t('DT Cost (Labor)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'totalCost',
        title: t('Total Cost (Labor)'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'laborBurden',
        title: t('Labor Burden'),
        sort: true,
        align: TextAlign.RIGHT,
        headerTextWrap: true,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'equipmentRate',
        title: t('Equip. Rate'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'equipmentCost',
        title: t('Equip. Cost'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
      {
        key: 'equipmentTotalCost',
        title: t('Total Cost'),
        sort: true,
        align: TextAlign.RIGHT,
        cell: renderReportCostValue,
        cellClassName,
        footer: renderColumnCostFooter,
      },
    ];
  }

  const tableMinWidth = useMemo(() => {
    const secondaryColumnWidth = secondaryReportType === ActivityReportType.BASIC ? 0 : 200;
    return aggregateColumns.length * 140 + nameColumnWidth + secondaryColumnWidth;
  }, [aggregateColumns, nameColumnWidth, secondaryReportType]);

  return {
    renderReportTimeValue,
    renderReportCostValue,
    renderColumnTimeFooter,
    renderColumnCostFooter,
    aggregateColumns,
    tableMinWidth,
  };
}
