import { useCurrencyNumberFormatter } from 'hooks';
import { useCallback } from 'react';
import { getAdjustedNumberByComma } from 'utils/numberUtils';
import { IDashboardChartPayloadType } from '../types/types';

const normalNumberFormatter = new Intl.NumberFormat(navigator.language);

export default function useDashboardYAxisFormatter(valueType: IDashboardChartPayloadType) {
  const formatter = useCurrencyNumberFormatter('usd', { maximumSignificantDigits: 2 });

  return useCallback(
    (tickItem: number) => {
      const adjustedNumber = getAdjustedNumberByComma(tickItem, 1);
      const scale = adjustedNumber.scale;
      const scaleSymbol = scale === 'thousands' ? 'k' : scale === 'millions' ? 'M' : scale === 'billions' ? 'B' : '';

      if (valueType === 'raw' || valueType === 'hour') {
        return `${normalNumberFormatter.format(adjustedNumber.value)}${scaleSymbol}`;
      } else if (valueType === 'currency') {
        const result = formatter!.formatToParts(adjustedNumber.value);

        const value = result.reduce((acc, cur) => {
          if (cur.type === 'currency' || cur.type === 'literal') {
            return acc;
          } else if (cur.type === 'fraction') {
            // Only add in cases where there is not a scale
            if (cur.value.length === 1 && scaleSymbol.length === 0) {
              return acc + cur.value + '0';
            } else {
              return acc + cur.value;
            }
          } else {
            return acc + cur.value;
          }
        }, '');

        const currencyIndex = result.findIndex((part) => part.type === 'currency');
        const firstInteger = result.findIndex((part) => part.type === 'integer');
        const literal = result.find((part) => part.type === 'literal')?.value;

        const currencyBeforeValue = currencyIndex <= firstInteger;
        const currency = result[currencyIndex].value ?? '$';

        if (literal) {
          return currencyBeforeValue
            ? currency + '\xa0' + value + scaleSymbol
            : value + scaleSymbol + '\xa0' + currency;
        } else {
          return currencyBeforeValue ? currency + value + scaleSymbol : value + scaleSymbol + currency;
        }
      } else {
        throw Error(`Unexpected valueType ${valueType}`);
      }
    },
    [valueType, formatter]
  );
}
