import isNil from 'lodash/isNil';
import { createContext, useContext } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';
import useDashboardSettingsUpdate from '../hooks/useDashboardSettingsUpdate';

export type DashboardSettingsContextState = ReturnType<typeof useDashboardSettingsUpdate>;

export const DashboardSettingsContext = createContext<DashboardSettingsContextState | null>(null);

export function useDashboardSettings() {
  const context = useContext(DashboardSettingsContext);

  if (isNil(context)) {
    throw new Error('useDashboardSettings must be nested within a DashboardSettingsContext.Provider.');
  }

  return context;
}

export function DashboardSettingsContextProvider({ children }: IChildrenProps) {
  return (
    <DashboardSettingsContext.Provider value={useDashboardSettingsUpdate()}>
      {children}
    </DashboardSettingsContext.Provider>
  );
}
