import _, { isEmpty, isNil, isNull } from 'lodash';
import { DateTime, Duration, WeekdayNumbers } from 'luxon';
import moment from 'moment-timezone';
import { ReactNode } from 'react';
import { DayOfMonthNumber, MonthNumber, WeekDayNumber } from 'types/Date';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import ClockAction from 'types/enum/ClockAction';
import { DayOfWeek, DayOfWeekValue } from 'types/enum/DayOfWeek';
import TimeRoundingType from 'types/enum/TimeRoundingType';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';
import { dateUtils } from 'utils';
import { greaterThan, lessThan } from './compareUtils';
import { DAY, HOUR, MINUTE } from './constants/timeInterval';
import { t } from './localize';
import { clampExtrema } from './numberUtils';
import { getTimezoneStrings } from './timezoneUtils';
import { isType } from './typeguard';

export const localizedDateAtTimeString = (seconds: number, zone: string = 'system') => {
  const dateTime = DateTime.fromSeconds(seconds, { zone });
  const formattedDate = dateTime.toLocaleString(DateTime.DATE_MED);
  const formattedTime = dateTime.toLocaleString(DateTime.TIME_SIMPLE);

  return `${formattedDate} @ ${formattedTime}`;
};

export const localizedMediumDateOnlyString = (seconds: number, zone: string = 'system') => {
  return DateTime.fromSeconds(seconds, { zone }).toLocaleString(DateTime.DATE_MED);
};

export function getDateName(date: DateTime, format: string = 'LLL d') {
  return getDateNameWithYearFormat(date, format, `${format}, yyyy`);
}

export function getDateNameWithYearFormat(date: DateTime, format: string, yearFormat: string) {
  const today = DateTime.local().setZone(date.zoneName, { keepLocalTime: true });
  const yesterday = today.minus({ day: 1 });

  if (!date.isValid) {
    return;
  }

  if (date.hasSame(today, 'day')) {
    return t('Today');
  } else if (date.hasSame(yesterday, 'day')) {
    return t('Yesterday');
  } else {
    const isDifferentYear = !format.includes('yy') && today.year !== date.toObject().year;
    return date.toFormat(isDifferentYear ? yearFormat : format);
  }
}

export function getDateNameWithWeekday(date: DateTime, format: string = 'ccc, LLL d') {
  return getDateNameWithYearFormat(date, format, `${format}, yyyy`);
}

export function getTodayTimeOrDateWhenEarlier(
  date: DateTime,
  todayDateFormat: string = 't',
  earlierDateFormat: string = 'LLL d'
) {
  const day = date;
  const today = DateTime.local().setZone(date.zoneName, { keepLocalTime: true });
  const yesterday = today.minus({ day: 1 });
  let label = '';

  if (!day.isValid) {
    return;
  }

  if (today.year !== day.toObject().year) {
    earlierDateFormat = earlierDateFormat + ', yyyy';
  }

  if (day.hasSame(today, 'day')) {
    label = day.toFormat(todayDateFormat);
  } else if (day.hasSame(yesterday, 'day')) {
    label = 'Yesterday';
  } else {
    label = day.toFormat(earlierDateFormat);
  }

  return label;
}

export function getHourMinuteStringFromSeconds(seconds: number) {
  return Duration.fromMillis(seconds * 1000).toFormat('h:mm');
}

export function getHourMinuteDurationString(dateTime: DateTime, emptyString: string = '---'): string {
  const hasHours = dateTime.hour > 0;
  const hasMinutes = dateTime.minute > 0;

  const hourString = dateTime.hour > 1 ? t('Hours') : t('Hour');
  const minuteString = dateTime.minute !== 1 ? t('Minutes') : t('Minute');

  let format = '';
  if (hasHours) {
    format += `H '${hourString}'`;
  }
  if (hasHours && hasMinutes) {
    format += ' ';
  }
  if (hasMinutes) {
    format += `m '${minuteString}'`;
  }
  return format.length !== 0 ? dateTime.toFormat(format) : emptyString;
}

export const timeStamp = (zone: string) => {
  const dateTime = DateTime.fromObject({}, { zone });
  const utcSeconds = dateTime.toSeconds();
  return Math.trunc(utcSeconds + dateTime.offset * 60);
};

export const timeStampUtc = () => timeStamp('utc');
export const timeStampLocal = () => timeStamp('system');
export const isoTimeStampUtc = () => isoTimeStamp('utc');
export const isoTimeStampLocal = () => isoTimeStamp('system');

export const isoTimeStamp = (zone: string) => {
  const dateTime = DateTime.fromObject({}, { zone });
  return dateTime.startOf('second').toISO({ suppressMilliseconds: true, includeOffset: false });
};

export const isoStartOfDayTimeStamp = (zone: string) => {
  const dateTime = DateTime.fromObject({}, { zone });
  return dateTime.startOf('day').toISO({ suppressMilliseconds: true, includeOffset: false });
};

export const adjustStartDate = (startDate: DateTime, endDate: DateTime) => (startDate > endDate ? endDate : startDate);

export const adjustEndDate = (startDate: DateTime, endDate: DateTime) => (endDate < startDate ? startDate : endDate);

export const getDateFromSecondsString = (secondsString: string | null, zone: string = 'system') => {
  if (secondsString) {
    const trimmed = secondsString.trim();
    if (trimmed !== '') {
      const seconds = parseInt(secondsString, 10);
      if (!isNaN(seconds)) {
        return DateTime.fromSeconds(seconds, { zone });
      }
    }
  }

  return null;
};

export function getTimeRangeFormattedWithYearConsideration(
  timeRange: ITimeRange<DateTime>,
  startFormat: string,
  endFormat: string = startFormat
) {
  const adjustedStartFormat = getTimeFormatWithYearConsideration(timeRange.startTime, startFormat);
  const adjustedEndFormat = getTimeFormatWithYearConsideration(timeRange.startTime, endFormat);
  const startFormatted = timeRange.startTime.toFormat(adjustedStartFormat);
  const endFormatted = timeRange.endTime.toFormat(adjustedEndFormat);

  // There is no range in this case
  if (startFormatted === endFormatted) {
    return startFormatted;
  }

  return `${startFormatted} - ${endFormatted}`;
}

export function getTimeFormatWithYearConsideration(time: DateTime, format: string) {
  const today = DateTime.fromObject({}, { zone: time.zone });
  if (time.hasSame(today, 'year')) {
    return format;
  }

  return `${format}, yyyy`;
}

export function getTimeFromHourMinuteSecondString(value: string) {
  return DateTime.fromFormat(value, 'HH:mm:ss');
}

export function getDurationFromHourMinuteSecondString(value: string) {
  const date = getTimeFromHourMinuteSecondString(value);
  return Duration.fromObject({ hours: date.hour, minute: date.minute });
}

export function createHourMinuteSecondStringFromTime(seconds: number) {
  const duration = Duration.fromMillis(seconds * 1000);
  return duration.toFormat('hh:mm:ss');
}

export function getRangeTimeFromIso(startIso: string, endIso: string, format: string, zone: string = 'system') {
  const startDateTime = DateTime.fromISO(startIso, { zone });
  const endDateTime = DateTime.fromISO(endIso, { zone });
  return getRangeTimeFromDateTime(startDateTime, endDateTime, format);
}

export function getRangedTime(start: number, end: number, format: string, zone: string = 'system') {
  return `${DateTime.fromSeconds(start, { zone }).toFormat(format)} - ${DateTime.fromSeconds(end, { zone }).toFormat(
    format
  )}`;
}

export function getRangeTimeFromDate(start: Date, end: Date, format: string, zone: string = 'system') {
  return `${DateTime.fromJSDate(start, { zone }).toFormat(format)} - ${DateTime.fromJSDate(end, { zone }).toFormat(
    format
  )}`;
}

export function getRangeTimeFromDateTime(start: DateTime, end: DateTime, format: string) {
  return `${start.toFormat(format)} - ${end.toFormat(format)}`;
}

export function convertDateToIso(date: Date) {
  return convertDateTimeToIso(DateTime.fromJSDate(date));
}

export function convertDateTimeToIso(date: DateTime) {
  return date.startOf('second').toISO({ suppressMilliseconds: true, includeOffset: false }); // Suppress milliseconds don't suppress unless they're 0.
}

export function roundTime(
  date: DateTime,
  roundingType: TimeRoundingType,
  roundingInterval: number,
  clockAction?: ClockAction
) {
  if (!isTimeRoundingIntervalAllowed(roundingInterval)) {
    return date;
  }

  switch (roundingType) {
    case TimeRoundingType.ROUND_DOWN:
      return roundTimeDown(date, roundingInterval / 60);
    case TimeRoundingType.ROUND_UP:
      return roundTimeUp(date, roundingInterval / 60);
    case TimeRoundingType.NEAREST:
      return roundTimeNearest(date, roundingInterval / 60);
    case TimeRoundingType.EMPLOYEE_FAVORABLE:
      if (!clockAction) {
        throw Error('Cannot round employee favorable without knowing start or end time');
      }

      return roundEmployeeFavorable(date, roundingInterval / 60, clockAction);
  }
}

export function roundTimeDown(date: DateTime, intervalMinutes: number) {
  if (!isTimeRoundingIntervalAllowed(intervalMinutes * 60)) {
    return date;
  }
  return date.set({ minute: Math.floor(date.get('minute') / intervalMinutes) * intervalMinutes });
}

export function roundTimeUp(date: DateTime, intervalMinutes: number) {
  if (!isTimeRoundingIntervalAllowed(intervalMinutes * 60)) {
    return date;
  }
  return date.set({ minute: Math.ceil(date.get('minute') / intervalMinutes) * intervalMinutes });
}

export function roundTimeNearest(date: DateTime, intervalMinutes: number) {
  if (!isTimeRoundingIntervalAllowed(intervalMinutes * 60)) {
    return date;
  }
  const roundedUp = roundTimeUp(date, intervalMinutes);
  const roundedUpMinute = roundedUp.get('minute') === 0 ? 60 : roundedUp.get('minute');
  if (roundedUpMinute - date.get('minute') > intervalMinutes / 2) {
    return roundTimeDown(date, intervalMinutes);
  } else {
    return roundedUp;
  }
}

export function roundEmployeeFavorable(date: DateTime, intervalMinutes: number, action: ClockAction) {
  if (!isTimeRoundingIntervalAllowed(intervalMinutes * 60)) {
    return date;
  }

  if (action === ClockAction.CLOCK_IN) {
    return roundTimeDown(date, intervalMinutes);
  } else if (action === ClockAction.CLOCK_OUT) {
    return roundTimeUp(date, intervalMinutes);
  } else {
    return roundTimeNearest(date, intervalMinutes);
  }
}

export function isTimeRoundingIntervalAllowed(interval: number) {
  const isIntervalValid = interval > 0;
  const isIntervalAMinuteMarker = interval % MINUTE === 0;
  const isIntervalBelowMax = interval <= HOUR;
  return isIntervalValid && isIntervalAMinuteMarker && isIntervalBelowMax;
}

// TODO
// Returns dates at start of day -- This was originally intended but is not true currently.
// "Fixing" this _could_ have widespread reprocussions so it's a little scary.
export function getDateTimesBetween(start: DateTime, end: DateTime): DateTime[] {
  const secondsBetween = end.toSeconds() - start.startOf('day').toSeconds();
  const daysBetween = secondsBetween / DAY;

  if (daysBetween < 1) {
    return [start];
  }

  return _.times(_.floor(daysBetween) + 1, (n) => start.plus({ day: n }));
}

export function getDateTimesBetweenExcludeWeekends(start: DateTime, end: DateTime): DateTime[] {
  const datesBetween = getDateTimesBetween(start, end);
  return datesBetween.filter(isDateTimeWeekday);
}

/**
 * Will be start of the month for every month except the start and potentially the end
 * depending on what the user specifies as arguments. Timezone agnostic, but DateTime
 *  offset will change going over DST changes.
 *
 * End time day, hour, seconds etc. has no impact on end of range.
 *
 * @param start start of the range
 * @param end end of the range
 */
export function getMonthsBetween(start: DateTime, end: DateTime): DateTime[] {
  const monthsBetween = end.year * 12 + end.month - (start.year * 12 + start.month);

  if (monthsBetween < 1) {
    return [start];
  }

  const monthStart = start.startOf('month');
  return [start, ..._.times(monthsBetween - 1, (n) => monthStart.plus({ month: n + 1 })), end.startOf('month')];
}

/**
 * Generates an array of dates for every day in a time range.
 * @param start the first date in the range
 * @param end the last date in the range
 * @returns an array of dates
 */
export function getDayRanges(start: Date, end: Date): Date[] {
  const result = [];
  const currentDate = new Date(start);
  while (currentDate <= end) {
    result.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
}

/**
 * Gets the iso string for a date. The string displays the local time and has the timezone set to UTC.
 * `date.toISOString()` would return "2020-04-16T06:00:00.000Z" for a date created at MDT.
 * `getLocalISOString()` would return "2020-04-16T00:00:00.000Z" instead.
 * @param date the date set with the local timezone
 * @returns formatted iso string for the date, such as (2020-04-16T00:00:00.000Z)
 */
export function getLocalIsoString(date: Date) {
  // we remove the offset from the date because it gets added back on in `toISOString()`
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString();
}

/**
 * ISO Date String for the Date "2021-01-30"
 * @param date date to format to string
 * @returns iso date string
 */
export function getIsoDate(date: Date): string {
  return date.toISOString().split('T')[0];
}

/**
 * Gets the iso string for the current utc time but it includes the local timezone.
 * This is useful for setting the createdOn value when it should include the createdOnOffset.
 * @returns formatted iso string for the current utc time, such as (2020-04-16T20:10:00:00.000-06:00)
 */
export function getNowIsoAtUtcWithLocalTimeZone() {
  const now = DateTime.local();

  // we remove the offset to have the time be at UTC, but we used the local date object so that we have our local timezone
  return now.minus(now.offset * 60 * 1000).toISO();
}

export function getDayWithTimePickerValue(day: DateTime, time: string) {
  const hourMinutes = getTimeFromTimePickerValue(time);
  if (hourMinutes) {
    return day.set({ hour: hourMinutes.hour, minute: hourMinutes.minute });
  } else {
    throw Error(`Invalid time, ${time}, passed to getDayWithTimePickerValue`);
  }
}

export function getTimeFromTimePickerValue(time: string) {
  if (/^((2{1}[0-4]{1})|(1{1}[0-9]{1})|(0{0,1}[0-9]{1})):[0-5]{1}[0-9]$/g.test(time)) {
    const [hour, minute] = time.split(':').map(_.toNumber);
    return { hour, minute };
  } else if (/^((2{1}[0-4]{1})|(1{1}[0-9]{1})|(0{0,1}[0-9]{1}))$/g.test(time)) {
    return { hour: _.toNumber(time), minute: 0 };
  } else if (/^((2{1}[0-4]{1})|(1{1}[0-9]{1})|(0{0,1}[0-9]{1})):$/g.test(time)) {
    const withoutColon = _.toNumber(_.dropRight(time, 1));
    return { hour: withoutColon, minute: 0 };
  } else {
    return null;
  }
}

export function nightShiftAdjustment(timeRange: ITimeRange<DateTime>): ITimeRange<DateTime> {
  const start = timeRange.startTime;
  const end = timeRange.endTime;

  return { startTime: start, endTime: start.toSeconds() > end.toSeconds() ? end.plus({ day: 1 }) : end };
}

export function isNightshift(timeRange: ITimeRange<string>) {
  const start = getDurationFromHoursMinutesString(timeRange.startTime);
  const end = getDurationFromHoursMinutesString(timeRange.endTime);

  return end.minus(start).as('seconds') >= 0;
}

export function getDayWithTimePickerValueSetToIso(day: DateTime, time: string) {
  return convertDateTimeToIsoForServer(getDayWithTimePickerValue(day, time));
}

export function timeInRange(
  day: DateTime,
  range: ITimeRange<DateTime>,
  startInclusive: boolean = true,
  endInclusive: boolean = false
) {
  const test = day.toSeconds();
  const start = range.startTime.toSeconds();
  const end = range.endTime.toSeconds();
  if (endInclusive) {
    if (startInclusive) {
      return test >= start && test <= end;
    } else {
      return test > start && test <= end;
    }
  } else {
    if (startInclusive) {
      return test >= start && test < end;
    } else {
      return test > start && test < end;
    }
  }
}

export function convertDateTimeToIsoForServer(day: DateTime) {
  return day.startOf('millisecond').toISO({ suppressMilliseconds: true, includeOffset: false });
}

export function isLocalDateBeforeToday(day: Date | DateTime) {
  if (isType<DateTime>(day, 'startOf')) {
    return day.startOf('day').toSeconds() < DateTime.local().startOf('day').toSeconds();
  } else {
    return DateTime.fromJSDate(day).startOf('day').toSeconds() < DateTime.local().startOf('day').toSeconds();
  }
}

export function isUtcDateBeforeToday(day: Date | DateTime) {
  if (isType<DateTime>(day, 'startOf')) {
    return day.startOf('day').toSeconds() < DateTime.utc().startOf('day').toSeconds();
  } else {
    return (
      DateTime.fromJSDate(day, { zone: 'utc' }).startOf('day').toSeconds() < DateTime.utc().startOf('day').toSeconds()
    );
  }
}

export function convertUtcTimeToLocalTimeKeepTime(dateTime: DateTime) {
  return dateTime.setZone('system', { keepLocalTime: true });
}

/// Check if two dates are on the same day.
export function isSameDay(dateOne: DateTime, dateTwo: DateTime) {
  return dateOne.hasSame(dateTwo, 'day') && dateOne.hasSame(dateTwo, 'month') && dateOne.hasSame(dateTwo, 'year');
}

/// Check if two dates are in the same year.
export function isSameYear(dateOne: DateTime, dateTwo: DateTime) {
  return dateOne.year === dateTwo.year;
}

// Have to manually check hour and minutes because it should be true if they're the same but different days
export function isSameHourMinute(dateOne: DateTime, dateTwo: DateTime) {
  return dateOne.hour === dateTwo.hour && dateOne.minute === dateTwo.minute;
}

export function getDateString(date: DateTime, format: string, showYearIfNeeded: boolean) {
  if (!showYearIfNeeded) {
    return date.toFormat(format);
  }
  if (isSameYear(date, DateTime.local())) {
    return date.toFormat(format);
  } else {
    return date.toFormat(format + ', yyyy');
  }
}

export function applyOffsetToDateTime(date: DateTime) {
  return date.plus(date.offset * 60 * 1000);
}

export function revertOffsetToDateTime(date: DateTime) {
  return date.minus(date.offset * 60 * 1000);
}

/// Get timezone representation from offset value.
export function getTimezone(date: DateTime, offset: number, isDST: boolean) {
  const zoneInfo = moment.tz.names().map((z) => {
    const zone = moment.tz(date.toISODate(), z);
    if (isDST === zone.isDST()) {
      return {
        abbr: zone.zoneAbbr(),
        offset: zone.utcOffset(),
      };
    }
  });
  const singleZone = zoneInfo.find((z) => z !== undefined && z.offset === offset);
  return singleZone;
}

/// Get timezone as superscript.
export function getTimezoneSup(dateOne: DateTime, isDST: boolean) {
  const supString = getTimezoneSupString(dateOne, isDST);
  if (!isNull(supString)) {
    return <sup>{supString}</sup>;
  }
}

/// Get timezone superscript string.
export function getTimezoneSupString(dateOne: DateTime, isDST: boolean): string | null {
  const localOffset = DateTime.local().offset;
  if (dateOne.offset !== localOffset) {
    const dateOneZone = dateUtils.getTimezone(dateOne, dateOne.offset, isDST);
    if (dateOneZone !== undefined) {
      return dateOneZone.abbr;
    }
  }
  return null;
}

export function getTimezoneSupStringForDate(
  date: DateTime,
  isDST: boolean,
  otherDate?: DateTime | null
): string | null {
  if (!isNil(otherDate)) {
    const localOffset = DateTime.fromISO(date.toISO()).toLocal().offset;
    if (date.offset !== otherDate.offset || date.offset !== localOffset) {
      const dateZone = dateUtils.getTimezone(date, date.offset, isDST);
      if (dateZone !== undefined) {
        return dateZone.abbr;
      }
    }
    return null;
  }
  return getTimezoneSupString(date, isDST);
}

export function getTimezoneSupForDate(date: DateTime, isDST: boolean, otherDate?: DateTime): ReactNode {
  const supString = getTimezoneSupStringForDate(date, isDST, otherDate);
  if (!isNull(supString)) {
    return <sup>{supString}</sup>;
  }
}

// similar to getTimezone() but returns the full timezone name instead of the abbreviation.
export const getFullTimezone = (date: DateTime, offset: number, isDST: boolean) => {
  const timezones = getTimezoneStrings();
  const zoneInfo = moment.tz.names().map((z) => {
    const zone = moment.tz(date.toISODate(), z);
    if (isDST === zone.isDST()) {
      return {
        name: z,
        offset: zone.utcOffset(),
        shortName: zone.zoneName(),
      };
    }
  });
  const singleZone = zoneInfo.find((z) => z !== undefined && z.offset === offset && timezones?.includes(z.name));

  return singleZone;
};

export function getDurationFromHoursMinutesString(time: string) {
  const dateTime = DateTime.fromISO(time);

  let hourMinutes;
  if (dateTime && dateTime.isValid) {
    hourMinutes = getTimeFromTimePickerValue(dateTime.toFormat('H:mm'));
  } else {
    hourMinutes = getTimeFromTimePickerValue(time);
  }
  if (hourMinutes) {
    return Duration.fromObject({ hour: hourMinutes.hour, minute: hourMinutes.minute });
  } else {
    throw Error(`Invalid time, ${time}, passed to getDayWithTimePickerValue`);
  }
}

export function getSecondsFromHoursMinutesString(time: string) {
  const duration = getDurationFromHoursMinutesString(time);

  return duration.as('seconds');
}

export function getTotalMillisFromTimeRange(
  range: ITimeRange<string | null | undefined>,
  format: string,
  allowNightshift: boolean = false
) {
  if (range.startTime && range.endTime) {
    const startTime = DateTime.fromFormat(range.startTime, format);
    const endTime = DateTime.fromFormat(range.endTime, format);

    if (startTime.isValid && endTime.isValid) {
      if (startTime.toMillis() <= endTime.toMillis()) {
        return endTime.minus(startTime.toMillis()).toMillis();
      } else if (allowNightshift) {
        // Night shift
        return endTime.plus({ day: 1 }).minus(startTime.toMillis()).toMillis();
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getTotalMillisFromTimeRangeDateTimeType(
  range: ITimeRange<DateTime | null | undefined>,
  allowNightshift: boolean = false
) {
  if (range.startTime && range.endTime) {
    if (range.startTime.isValid && range.endTime.isValid) {
      if (range.startTime.toMillis() <= range.endTime.toMillis()) {
        return range.endTime.toMillis() - range.startTime.toMillis();
      } else if (allowNightshift) {
        // Night shift
        return range.endTime.plus({ day: 1 }).toMillis() - range.startTime.toMillis();
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getDateTime(
  time: string | DateTime | Date | null | undefined,
  format: string = 'H:mm'
): DateTime | null {
  if (DateTime.isDateTime(time)) {
    return time;
  } else if (!time) {
    return null;
  } else if (_.isDate(time)) {
    return DateTime.fromJSDate(time);
  } else {
    const dateTime = DateTime.fromString(time, format);
    return dateTime.isValid ? dateTime : null;
  }
}

export function getDateTimesFromTimeRange(
  timeRange: ITimeRange<string | DateTime | Date | null | undefined>,
  format?: string
): ITimeRange<DateTime | null> {
  return { startTime: getDateTime(timeRange.startTime, format), endTime: getDateTime(timeRange.endTime, format) };
}

export function numberOfDaysBetween(date1: DateTime, date2: DateTime) {
  const diff = Math.abs(date1.toMillis() - date2.toMillis());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

export function combineDateWithTime(date: DateTime, time: DateTime): DateTime {
  return date.set({ hour: time.hour, minute: time.minute, second: time.second });
}
export function combineDateAndTime(date: DateTime, time: DateTime): DateTime {
  if (time) {
    // Time can sometimes be null when the time field input is a ':' or non valid time.
    return combineDateWithTime(date, time).setZone(time.zoneName, { keepLocalTime: true });
  }
  return date;
}

export function secondsToHHmmString(value: number) {
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);
  const hDisplay = h > 0 ? (h < 10 ? '0' + h.toString() : h.toString()) : '00';
  const mDisplay = m > 0 ? (m < 10 ? '0' + m.toString() : m.toString()) : '00';
  return hDisplay + ':' + mDisplay;
}

export function secondsToHmmString(value: number) {
  const h = Math.floor(value / 3600);
  const m = Math.floor((value % 3600) / 60);
  const hDisplay = h > 0 ? h.toString() : '0';
  const mDisplay = m > 0 ? (m < 10 ? '0' + m.toString() : m.toString()) : '00';
  return hDisplay + ':' + mDisplay;
}

export function dateTimeFromISOKeepZone(iso: string) {
  const date = DateTime.fromISO(iso, { setZone: true });
  date.setZone(date.zoneName, { keepLocalTime: false });
  return date;
}

/**
 * Creates a date time object from an ISO string. The timezone offset will not be included in the date time.
 * For example iso string of 2020-05-14T00:00+06:00 will give a date time of 2020-05-14T00:00Z
 * @param iso iso string to convert to datetime
 */
export function dateTimeFromISOWithoutZone(iso: string) {
  const date = DateTime.fromISO(iso, { setZone: true });
  return date.toUTC(0, { keepLocalTime: true });
}

/**
 * Creates a date time object from an ISO string that occurs at UTC and has no timezone indicator.
 * For example iso string of 2020-05-14T00:00 will give a date time of 2020-05-14T00:00Z
 * @param iso iso string to convert to datetime
 */
export function dateTimeFromUtcISO(iso: string) {
  return DateTime.fromISO(iso, { zone: 'utc' });
}

/**
 * Creates a date time object from an ISO string that was transleted to UTC (such as createdOn, updatedOn, etc.) and has no timezone indicator.
 * For example iso string of 2020-05-14T21:00 will give a date time of 2020-05-14T15:00-6:00 when local timezone is -6:00
 * @param iso iso string to convert to datetime
 */
export function localizeDateTimeFromUtcISO(iso: string) {
  return DateTime.fromISO(iso, { zone: 'utc' }).toLocal();
}

/**
 * Creates a date time object from an ISO string that was transleted to UTC (such as createdOn, updatedOn, etc.) and has no timezone indicator.
 * This will translate the UTC time back to your local time and also keep the timezone set to UTC.
 * For example iso string of 2020-05-14T21:00 will give a date time of 2020-05-14T15:00Z when at timezone -6:00
 * @param iso iso string to convert to datetime
 */
export function localizeDateTimeFromUtcISOKeepUtc(iso: string) {
  return localizeDateTimeFromUtcISO(iso).toUTC(0, { keepLocalTime: true });
}

/**
 * Get the timezone offset in seconds.
 * @param dateTime the datetime object with the timezone
 */
export function offsetInSeconds(dateTime: DateTime) {
  return dateTime.offset * 60;
}

export function formatTimeRange(timeRange: ITimeRange<DateTime>, interval: TimeRangeType, format: string = 'DD') {
  switch (interval) {
    case TimeRangeType.DAILY:
      return formatDay(timeRange, format);
    case TimeRangeType.MONTHLY:
      return formatMonth(timeRange, format);
    case TimeRangeType.WEEKLY:
      return getRangeTimeFromDateTime(timeRange.startTime, timeRange.endTime, format);
    case TimeRangeType.YEARLY:
      return formatYear(timeRange);
    case TimeRangeType.PAY_PERIOD:
      // Do we need to show
      return getRangeTimeFromDateTime(timeRange.startTime, timeRange.endTime, format);
    case TimeRangeType.CUSTOM:
      return getRangeTimeFromDateTime(timeRange.startTime, timeRange.endTime, format);
    case TimeRangeType.ALL_TIME:
      return t('All Time');
  }
}

export function formatDay(timeRange: ITimeRange<DateTime>, format: string = 'DD') {
  // we're going to remove millis to avoid making the assumption that the range passed to us has already removed them
  const rangeStart = timeRange.startTime.set({ millisecond: 0 });
  const rangeEnd = timeRange.endTime.set({ millisecond: 0 });
  const today = DateTime.local().startOf('day').setZone(timeRange.startTime.zoneName, { keepLocalTime: true });

  if (
    rangeStart.toSeconds() === today.toSeconds() &&
    rangeEnd.toSeconds() === today.endOf('day').set({ millisecond: 0 }).toSeconds()
  ) {
    return t('Today');
  } else if (
    rangeStart.toSeconds() === today.plus({ day: 1 }).toSeconds() &&
    rangeEnd.toSeconds() === today.plus({ day: 1 }).endOf('day').set({ millisecond: 0 }).toSeconds()
  ) {
    return t('Tomorrow');
  } else if (
    rangeStart.toSeconds() === today.minus({ day: 1 }).toSeconds() &&
    rangeEnd.toSeconds() === today.minus({ day: 1 }).endOf('day').set({ millisecond: 0 }).toSeconds()
  ) {
    return t('Yesterday');
  } else {
    return rangeStart.toFormat(format); // Daily is on one day so just need that day formatted not a range.
  }
}

export function formatMonth(timeRange: ITimeRange<DateTime>, format: string = 'DD') {
  const today = DateTime.fromObject({}, { zone: timeRange.startTime.zoneName }).startOf('day');
  if (
    timeRange.startTime.toSeconds() === today.startOf('month').toSeconds() &&
    timeRange.endTime.toSeconds() === today.endOf('month').set({ millisecond: 0 }).toSeconds()
  ) {
    return t('This Month');
  } else {
    // Month is just a month so we just need one day
    return timeRange.startTime.toFormat(format);
  }
}

export function formatYear(timeRange: ITimeRange<DateTime>, format: string = 'yyyy') {
  return timeRange.startTime.toFormat(format);
}

export function getAdjustedStartDateChange(startDateValue: Date, endDate: DateTime | null) {
  const newStartDate = DateTime.fromJSDate(startDateValue).startOf('day');
  const newEndDate = endDate ? adjustEndDate(newStartDate, endDate).endOf('day').set({ millisecond: 0 }) : endDate;

  return { newStartDate, newEndDate };
}

export function getAdjustedEndDateChange(endDateValue: Date, startDate: DateTime | null) {
  const newEndDate = DateTime.fromJSDate(endDateValue).endOf('day').set({ millisecond: 0 });
  const newStartDate = startDate ? adjustStartDate(startDate, newEndDate).startOf('day') : startDate;
  return { newStartDate, newEndDate };
}

export function localizedDateTimeOrNow(dateTime: DateTime | null | undefined): DateTime {
  if (dateTime) {
    return dateTime.toUTC(0, { keepLocalTime: true });
  } else {
    return DateTime.local().toUTC(0, { keepLocalTime: true });
  }
}

export function getWeekDayString(value: number | DayOfWeek) {
  switch (value) {
    case DayOfWeek.MONDAY:
      return DayOfWeekValue.MONDAY;
    case DayOfWeek.TUESDAY:
      return DayOfWeekValue.TUESDAY;
    case DayOfWeek.WEDNESDAY:
      return DayOfWeekValue.WEDNESDAY;
    case DayOfWeek.THURSDAY:
      return DayOfWeekValue.THURSDAY;
    case DayOfWeek.FRIDAY:
      return DayOfWeekValue.FRIDAY;
    case DayOfWeek.SATURDAY:
      return DayOfWeekValue.SATURDAY;
    case DayOfWeek.SUNDAY:
      return DayOfWeekValue.SUNDAY;
  }
}

export function convertRangeToUTC(timeRange: ITimeRange<DateTime>): ITimeRange<DateTime> {
  return {
    startTime: timeRange.startTime.toUTC().startOf('day'),
    endTime: timeRange.endTime.startOf('day').toUTC().endOf('day'),
  };
}

export function convertRangeToUtcWithoutAdjustment(
  timeRange: ITimeRange<Optional<DateTime>>
): ITimeRange<Optional<DateTime>> {
  return {
    startTime: timeRange?.startTime?.toUTC() ?? null,
    endTime: timeRange?.endTime?.toUTC() ?? null,
  };
}

export function getStartOfSundayWeek(currentTime: DateTime) {
  if (currentTime.weekday === 7) {
    return currentTime.startOf('day');
  } else {
    return currentTime.startOf('week').minus({ day: 1 }).startOf('day');
  }
}

// If the desired day is after the current day, we need to subtract a week
export function getStartOfWeek(currentTime: DateTime, startOfWeek: DayOfWeekValue) {
  const dateTimeWeekDay = getDateTimeWeekDayFromDayOfWeekValue(startOfWeek);
  const weekStart = currentTime.set({ weekday: dateTimeWeekDay });

  if (weekStart.startOf('day').toSeconds() > currentTime.startOf('day').toSeconds()) {
    return weekStart.minus({ week: 1 });
  }

  return weekStart;
}

export function getEndOfSundayWeek(currentTime: DateTime) {
  if (currentTime.weekday === 7) {
    return currentTime.plus({ day: 1 }).endOf('week').minus({ day: 1 });
  } else {
    return currentTime.endOf('week').minus({ day: 1 });
  }
}

export function clampMinDate(date: DateTime, minDate: DateTime) {
  return date.toSeconds() < minDate.toSeconds() ? minDate : date;
}

export function clampMaxDate(date: DateTime, maxDate: DateTime) {
  return date.toSeconds() > maxDate.toSeconds() ? maxDate : date;
}

export function getEndOfTodayLocal() {
  return DateTime.local().endOf('day');
}

export function clampMaxDateToEndOfTodayLocal(date: DateTime) {
  return clampMaxDate(date, getEndOfTodayLocal());
}

export function clampDate(date: DateTime, minDate: Optional<DateTime>, maxDate: Optional<DateTime>) {
  const minAdjusted = minDate ? clampMinDate(date, minDate) : date;
  return maxDate ? clampMaxDate(minAdjusted, maxDate) : minAdjusted;
}

export function clampInsideDate(date: DateTime, targetDate: DateTime) {
  return clampDate(date, targetDate.startOf('day'), targetDate.endOf('day'));
}

export function getExtremaFromDateTime<T>(items: T[], selector: (data: T) => DateTime) {
  const range = items.reduce<ITimeRange<DateTime | null>>(
    (acc, curr) => {
      const timeRange: ITimeRange<DateTime | null> = {
        startTime: acc.startTime,
        endTime: acc.endTime,
      };

      const givenRange = selector(curr);

      if (!acc.startTime || acc.startTime.toSeconds() > givenRange.toSeconds()) {
        timeRange.startTime = givenRange;
      }

      if (!acc.endTime || acc.endTime.toSeconds() < givenRange.toSeconds()) {
        timeRange.endTime = givenRange;
      }

      return timeRange;
    },
    { startTime: null, endTime: null }
  );

  return range.startTime && range.endTime ? (range as ITimeRange<DateTime>) : null;
}

export function utcToday(): ITimeRange<DateTime> {
  return {
    startTime: DateTime.local().startOf('day').toUTC(0, { keepLocalTime: true }),
    endTime: DateTime.local().endOf('day').toUTC(0, { keepLocalTime: true }),
  };
}

// 1st 2nd etc. => 1, 2 etc.
export function convertDayToNumber(day: string) {
  const truncDay = day.substring(0, day.length - 2);
  return _.toNumber(truncDay);
}

export function convertDurationToHoursDecimal(duration: Duration, places: number = 2) {
  const hours = duration.as('hours');
  return hours.toFixed(places);
}

export function convertDateTimeRangeToSecondsRange(range: ITimeRange<DateTime>): ITimeRange<number> {
  return {
    startTime: range.startTime.toSeconds(),
    endTime: range.endTime.toSeconds(),
  };
}

export function isValidDateTime(dateTime: DateTime | null | undefined) {
  return dateTime?.isValid === true;
}

export function setZone(dateToUpdate: DateTime, zone: string, keepLocalTime: boolean = false): DateTime {
  return dateToUpdate.zoneName !== zone ? dateToUpdate.setZone(zone, { keepLocalTime }) : dateToUpdate;
}

export function areTimeRangesEqual(timeRange1: ITimeRange<DateTime>, timeRange2: ITimeRange<DateTime>): boolean {
  return (
    timeRange1.startTime.toSeconds() === timeRange2.startTime.toSeconds() &&
    timeRange1.endTime.toSeconds() === timeRange2.endTime.toSeconds()
  );
}

export function isDateTimeInTimeRange(
  dateTime: DateTime,
  timeRange: ITimeRange<DateTime>,
  startInclusive: boolean,
  endInclusive: boolean
) {
  const timeSeconds = dateTime.toSeconds();
  const startSeconds = timeRange.startTime.toSeconds();
  const endSeconds = timeRange.endTime.toSeconds();

  const afterStart = greaterThan(timeSeconds, startSeconds, startInclusive);
  const beforeEnd = lessThan(timeSeconds, endSeconds, endInclusive);

  return afterStart && beforeEnd;
}

export function isDst() {
  return DateTime.local().isInDST;
}

export function getDateTimeIfValidFromIso(iso: string) {
  return getDateTimeIfValid(DateTime.fromISO(iso));
}

export function getDateTimeIfValid(dateTime: Optional<DateTime>) {
  return dateTime?.isValid ? dateTime : null;
}

export function getDateTimeIfValidWithDefault(dateTime: Optional<DateTime>, defaultDateTime: DateTime) {
  return getDateTimeIfValid(dateTime) ?? defaultDateTime;
}

export function formatOptionalDateTimeRange(
  startTime: DateTime,
  endTime: Nullable<DateTime>,
  startFormat: string,
  endFormat: string
) {
  const shouldShowDateRange = endTime ? !isSameDay(startTime, endTime) : false;

  if (endTime && shouldShowDateRange) {
    return `${startTime.toFormat(startFormat)} - ${endTime.toFormat(endFormat)}`;
  }

  return startTime.toFormat(startFormat);
}

export function formatOptionalTimeTimeRange(startTime: DateTime, endTime: Nullable<DateTime>, format: string) {
  return `${startTime.toFormat(format)} - ${endTime?.toFormat(format) ?? '?'}`;
}

export function compareIsoTimeStamps(
  firstIso: string,
  secondIso: string,
  comparator: (firstSeconds: number, secondSeconds: number) => boolean
) {
  const first = DateTime.fromISO(firstIso).toSeconds();
  const second = DateTime.fromISO(secondIso).toSeconds();
  return comparator(first, second);
}

export function getDateTimeWithZone(zone: 'system' | 'utc') {
  return DateTime.fromObject({}, { zone });
}

export function getDayOfWeekValueFromDateTimeWeekDay(weekday: WeekdayNumbers): DayOfWeekValue {
  switch (weekday) {
    case 1:
      return DayOfWeekValue.MONDAY;
    case 2:
      return DayOfWeekValue.TUESDAY;
    case 3:
      return DayOfWeekValue.WEDNESDAY;
    case 4:
      return DayOfWeekValue.THURSDAY;
    case 5:
      return DayOfWeekValue.FRIDAY;
    case 6:
      return DayOfWeekValue.SATURDAY;
    case 7:
      return DayOfWeekValue.SUNDAY;
  }
}

export function getDateTimeWeekDayFromDayOfWeekValue(dayOfWeekValue: DayOfWeekValue): WeekdayNumbers {
  switch (dayOfWeekValue) {
    case DayOfWeekValue.MONDAY:
      return 1;
    case DayOfWeekValue.TUESDAY:
      return 2;
    case DayOfWeekValue.WEDNESDAY:
      return 3;
    case DayOfWeekValue.THURSDAY:
      return 4;
    case DayOfWeekValue.FRIDAY:
      return 5;
    case DayOfWeekValue.SATURDAY:
      return 6;
    case DayOfWeekValue.SUNDAY:
      return 7;
  }
}

export function getDayOfWeekValueFromDateTime({ weekday }: DateTime): DayOfWeekValue {
  return getDayOfWeekValueFromDateTimeWeekDay(weekday);
}

export function isDateTimeWeekend(dateTime: DateTime) {
  return dateTime.weekday === 6 || dateTime.weekday === 7;
}

export function isDateTimeWeekday(dateTime: DateTime) {
  return !isDateTimeWeekend(dateTime);
}

export function getNowMillisUtc() {
  return DateTime.utc().toMillis();
}

export function convertDayOfWeekToDateTimeWeekday(dayOfWeek: DayOfWeek): number {
  switch (dayOfWeek) {
    case DayOfWeek.SUNDAY:
      return 7;
    case DayOfWeek.MONDAY:
      return 1;
    case DayOfWeek.TUESDAY:
      return 2;
    case DayOfWeek.WEDNESDAY:
      return 3;
    case DayOfWeek.THURSDAY:
      return 4;
    case DayOfWeek.FRIDAY:
      return 5;
    case DayOfWeek.SATURDAY:
      return 6;
  }
}

export function convertDateTimeWeekdayToDayOfWeek(weekday: WeekDayNumber): DayOfWeek {
  switch (weekday) {
    case 1:
      return DayOfWeek.MONDAY;
    case 2:
      return DayOfWeek.TUESDAY;
    case 3:
      return DayOfWeek.WEDNESDAY;
    case 4:
      return DayOfWeek.THURSDAY;
    case 5:
      return DayOfWeek.FRIDAY;
    case 6:
      return DayOfWeek.SATURDAY;
    case 7:
      return DayOfWeek.SUNDAY;
  }
}

export function createDateTimeNowWithZone(zone: 'system' | 'utc') {
  return DateTime.fromObject({}, { zone });
}

export function clampDayOfMonth(day: number, zone: 'local' | 'utc' = 'local'): DayOfMonthNumber {
  const today = DateTime.fromObject({}, { zone });
  const maxDay = today.daysInMonth;
  return clampExtrema(day, 1, maxDay) as DayOfMonthNumber;
}

export function clampNumberToDayOfMonth(day: number): DayOfMonthNumber {
  return clampExtrema(day, 1, 31) as DayOfMonthNumber;
}

export function clampMonthOfYear(month: number): MonthNumber {
  return clampExtrema(month, 1, 12) as MonthNumber;
}

export function getEarliestDateTimeAfterTime(eligibleDates: DateTime[], minimumDate: DateTime): Nullable<DateTime> {
  const eligibleDatesAfterMinimum = eligibleDates.filter((date) => date.toSeconds() >= minimumDate.toSeconds());

  if (isEmpty(eligibleDatesAfterMinimum)) {
    return null;
  }

  return eligibleDatesAfterMinimum.reduce((earliest, current) => {
    return earliest.toSeconds() < current.toSeconds() ? earliest : current;
  }, eligibleDatesAfterMinimum[0]);
}

export function getLastDateTimeBeforeTime(eligibleDates: DateTime[], maximumDate: DateTime): Nullable<DateTime> {
  const eligibleDatesBeforeMaximum = eligibleDates.filter((date) => date.toSeconds() < maximumDate.toSeconds());

  if (isEmpty(eligibleDatesBeforeMaximum)) {
    return null;
  }

  return eligibleDatesBeforeMaximum.reduce((latest, current) => {
    return latest.toSeconds() > current.toSeconds() ? latest : current;
  }, eligibleDatesBeforeMaximum[0]);
}

export function toFlooredMinutes(dateTime: DateTime) {
  return Math.floor(dateTime.toSeconds() / MINUTE);
}

export function convertIsoTimeToIsoDate(isoTime: string) {
  return isoTime.substring(0, 10);
}

export function getYearlyDateRange(year: string | number) {
  const yearInt = typeof year === 'string' ? parseInt(year) : year;

  const dateRange: ITimeRange<DateTime> = {
    startTime: DateTime.fromObject({ year: yearInt, month: 1, day: 1 }),
    endTime: DateTime.fromObject({ year: yearInt, month: 12, day: 31 }),
  };
  return dateRange;
}

export function getMilitaryTimeFromISO(isoString: string) {
  return DateTime.fromISO(isoString).toFormat('HH:mm');
}

export function getOshaFormatDateFromISO(isoString: string) {
  return DateTime.fromISO(isoString).toFormat('MM/dd/yyyy');
}

export function getOshaFormatDateFromDateTime(dateTime: DateTime) {
  return dateTime.toFormat('MM/dd/yyyy');
}
