import useNoItemData from 'components/domain/time-entry/TimeEntryDataTable/hooks/useNoItemData';
import { useTimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import { NO_COST_CODE_OBJECT } from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ICostCode from 'types/CostCode';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { getCostCodeDisplay } from 'utils/stringUtils';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';
import { useLazyCostCodeTimeData } from './useLazyCostCodeTimeData';

export default function useCostCodeTimeEntryDataReportData(
  scroller: HTMLElement | Document | null,
  timeRange: ITimeRange<DateTime>,
  includeOpenEntries?: boolean,
  costCodeIds?: string[],
  equipmentIds?: string[],
  memberIds?: string[],
  projectIds?: string[],
  excludeArchived?: boolean,
  showNoItemTable: boolean = false,
  projectIdWithDescendants?: boolean
) {
  const [data, setData] = useState<ITimeEntryReportWithHeaderData[]>([]);
  const titleFormatter = useCallback((costCode: ICostCode) => getCostCodeDisplay(costCode), []);

  const filter = useMemo(() => {
    return {
      timeRange,
      includeOpen: includeOpenEntries,
      costCodeIds,
      equipmentIds,
      memberIds,
      projectIds,
      excludeDeleted: excludeArchived,
      projectIdWithDescendants,
    };
  }, [
    includeOpenEntries,
    costCodeIds,
    timeRange,
    titleFormatter,
    equipmentIds,
    memberIds,
    projectIds,
    excludeArchived,
    projectIdWithDescendants,
  ]);
  const {
    data: groupedData,
    loading,
    error,
    clearData,
    updateCostCodeIds,
    loadedAll,
    loadAll,
  } = useLazyCostCodeTimeData(scroller, filter);
  const getRows = useTimeEntryDataTableRow();
  const getNoItemData = useNoItemData<ICostCode>(NO_COST_CODE_OBJECT);

  useEffect(() => {
    async function getData() {
      let noItemData;
      if (costCodeIds?.includes(remainingDataItemId)) {
        const noCostCodeData = await getNoItemData(
          timeRange,
          includeOpenEntries,
          memberIds,
          projectIds,
          [remainingDataItemId],
          equipmentIds,
          projectIdWithDescendants
        );
        noItemData = await getRows(timeRange, noCostCodeData, () => t('No Cost Code'));
        if (costCodeIds.length === 1) {
          setData(noItemData);
          return;
        }
      } else {
        if (isNil(costCodeIds) && showNoItemTable && loadedAll) {
          const noCostCodeData = await getNoItemData(
            timeRange,
            includeOpenEntries,
            memberIds,
            projectIds,
            [remainingDataItemId],
            equipmentIds,
            projectIdWithDescendants
          );
          if (
            !isEmpty(noCostCodeData) &&
            (!isEmpty(noCostCodeData[0].entries) || !isEmpty(noCostCodeData[0].timeOffs))
          ) {
            noItemData = await getRows(timeRange, noCostCodeData, () => t('No Cost Code'));
          }
        }
      }
      let newData = await getRows(timeRange, groupedData, titleFormatter);
      if (noItemData) {
        newData = newData.concat(noItemData);
      }
      setData(newData);
    }
    getData();
  }, [groupedData, titleFormatter, filter]);

  return useMemo(
    () => ({ data, loading, error, clearData, updateCostCodeIds, loadedAll, loadAll }),
    [data, loading, error, clearData, updateCostCodeIds, loadedAll, loadAll]
  );
}
