import { t } from 'utils/localize';
import { validateEmail } from 'utils/stringUtils';

export default function v_valid_email(value: any, args: { message?: string }) {
  return {
    message: args.message ?? t('Not a valid email'),
    validate: () => {
      if (value) {
        return validateEmail(value);
      }
      return true;
    },
  };
}
