// i18n config options: https://www.i18next.com/overview/configuration-options
// i18n-react config options: https://react.i18next.com/latest/i18next-instance

import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const enableLocizeIntegration = false;

const locizeOptions: InitOptions = {
  backend: {
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    projectId: '5404b661-6909-46c1-8262-49427bea3273',
    referenceLng: 'en',
  },
  partialBundledLanguages: true, // use static translations until backend data is provided
};

const staticResources: InitOptions['resources'] = {
  en: require('./locales/en/translation.json'),
  es: require('./locales/es/translation.json'),
};

const initOptions: InitOptions = {
  ...(enableLocizeIntegration ? locizeOptions : {}),
  resources: staticResources,

  debug: false, // (process.env.NODE_ENV === 'development'),
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  saveMissing: false,
  react: {
    useSuspense: enableLocizeIntegration,
  },
  keySeparator: '&!',
  nsSeparator: '|^',
};

if (enableLocizeIntegration) {
  i18n.use(LocizeBackend);
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(initOptions);

export default i18n;
