import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { Panel } from 'components';
import Helmet from 'react-helmet';
import { DashboardSettingsContextProvider } from '../context/DashboardSettingsContext';
import DashboardGrid from '../dashboard-grid/DashboardGrid/DashboardGrid';
import './Dashboard.scss';
import { t } from 'utils/localize';
import useIsInIframe from 'hooks/store/useIsInIframe';

interface IDashboardProps {
  className?: ClassName;
}

function Dashboard({ className }: IDashboardProps) {
  const isInIframe = useIsInIframe();

  const classes = classNames('dashboard', { 'is-in-iframe': isInIframe }, className);

  return (
    <Panel className={classes}>
      <Helmet>
        <title>{t('Dashboard')}</title>
      </Helmet>
      <DashboardSettingsContextProvider>
        <DashboardGrid />
      </DashboardSettingsContextProvider>
    </Panel>
  );
}

export default Dashboard;
