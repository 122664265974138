import { FetchPolicy, QueryResult, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import LazyLoadPicker from 'components/foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { useDebounce, useOrganization } from 'hooks';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import ICostCode from 'types/CostCode';
import SearchType from 'types/enum/SearchType';
import { stringUtils } from 'utils';
import { getSafeBoolean } from 'utils/optionalUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';

export interface ICostCodePickerProps {
  value: string | null;
  onSelect: (costCodeId: string | null) => void;
  considerProjectSpecificCostCodes?: boolean;
  projectId?: string | null;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  searchArchived?: boolean;
  fetchPolicy?: FetchPolicy;
  className?: ClassName;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
}

const CostCodePicker = (props: ICostCodePickerProps) => {
  const {
    value,
    onSelect,
    minWidth,
    error,
    placeholder,
    position,
    projectId,
    searchArchived,
    fetchPolicy,
    considerProjectSpecificCostCodes,
    closeButtonRender,
    dropDownButtonRender,
    className,
  } = props;

  const client = useApolloClient();
  const organization = useOrganization();

  const [data, setData] = useState<ICostCode[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);
  const [apolloError, setApolloError] = useState(false);

  useEffect(() => {
    setData([]);
    setLoadedAll(false);
  }, [searchDebounce, projectId]);

  function handleDidLoad(models: ICostCode[], err: boolean, allLoaded: boolean) {
    if (!err) {
      setLoadedAll(allLoaded);
      setData(models);
    }

    setApolloError(err);
  }

  function onSearch(val: string) {
    setSearchValue(val);
  }

  function onClose() {
    setLoadedAll(false);
    setData([]);
    setSearchValue('');
  }

  function handleSelect(model: ICostCode | null) {
    onSelect(model ? model.id : null);
  }

  async function getCostCodes(after?: string): Promise<ICostCode[]> {
    return await new Promise<ICostCode[]>(async (res, rej) => {
      if (considerProjectSpecificCostCodes && organization.useProjectCostCodeScoping && !projectId) {
        res([]);
      } else {
        const queried = await client.query<{ costCodes: ICostCode[] }>({
          query: COST_CODES_QUERY,
          fetchPolicy: fetchPolicy || 'cache-first',
          variables: {
            after,
            first: 10,
            filter: {
              archivedOn: searchArchived ? { isNull: false } : { isNull: true },
              search: searchDebounce
                ? {
                    type: SearchType.CONTAINS,
                    fields: ['costCode', 'title'],
                    value: searchDebounce,
                  }
                : undefined,
              projectCostCodeLink:
                considerProjectSpecificCostCodes && organization.useProjectCostCodeScoping
                  ? { projectId: { equal: projectId }, deletedOn: { isNull: true } }
                  : undefined,
            },
          },
        });

        res(queried.data.costCodes);
      }
    });
  }

  function renderValueTemplate(id: string | null) {
    if (id) {
      const costCode = _.find(data, (model) => model.id === id);
      if (costCode) {
        return <div className="ellipsis">{getCostCodeDisplay(costCode)}</div>;
      } else {
        return (
          <Query query={COST_CODES_QUERY} variables={{ filter: { id: { equal: id } } }}>
            {({ data: costCodeData }: QueryResult<{ costCodes: ICostCode[] }>) => {
              return (
                <>
                  {costCodeData && costCodeData.costCodes.length ? (
                    getCostCodeDisplay(costCodeData.costCodes[0])
                  ) : (
                    <></>
                  )}
                </>
              );
            }}
          </Query>
        );
      }
    }

    return <></>;
  }

  function renderRow(costCode: ICostCode) {
    return (
      <Bar size={Size.SMALL} className="px-3">
        <div className="ellipsis">
          {searchValue
            ? stringUtils.highlight(getCostCodeDisplay(costCode), searchValue)
            : getCostCodeDisplay(costCode)}
        </div>
      </Bar>
    );
  }

  const classes = classNames('cost-code-picker', className);

  return organization.trackCostCode ? (
    <LazyLoadPicker
      value={value}
      getData={getCostCodes}
      didLoad={handleDidLoad}
      error={error}
      dataError={apolloError}
      data={data}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      valueTemplate={renderValueTemplate}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      searchValue={searchValue}
      onSearch={onSearch}
      placeholder={placeholder}
      disabled={
        !projectId &&
        getSafeBoolean(organization.useProjectCostCodeScoping) &&
        getSafeBoolean(considerProjectSpecificCostCodes)
      }
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      className={classes}
    />
  ) : null;
};

CostCodePicker.defaultProps = {
  minWidth: '350px',
  considerProjectSpecificCostCodes: true,
};

export default CostCodePicker;
