import Graylog from 'components/domain/analytics/Graylog/Graylog';
import { useActiveMember } from 'hooks';
import AnalyticsEvent from 'utils/constants/analytics/analyticsEvent';
import { logError } from 'utils/testUtils';

export type QuickSearchSelectedType = 'navigation' | 'employee' | 'project' | 'cost_code' | 'equipment' | 'action';

export default function useQuickSearchGraylog() {
  const activeMember = useActiveMember();

  function searchOpened(isKeyboardShortcut: boolean) {
    try {
      Graylog({
        shortMsg: AnalyticsEvent.quickSearch.QUICK_SEARCH,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _is_keyboard_shortcut: isKeyboardShortcut,
        },
      });
    } catch (error) {
      logError(error as Error);
    }
  }

  function searchSelected(selectedType: QuickSearchSelectedType) {
    try {
      Graylog({
        shortMsg: AnalyticsEvent.quickSearch.SEARCH_SELECTED,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _selection_type: selectedType,
        },
      });
    } catch (error) {
      logError(error as Error);
    }
  }

  return { searchOpened, searchSelected };
}
