import { useMutation } from '@apollo/client';
import { CREATE_MEMBER_GROUP, UPDATE_MEMBER_GROUP } from 'apollo/mutations/member-group-mutations';
import { MEMBER_GROUPS_QUERY } from 'apollo/queries/member-group-queries';
import { useApolloPaging } from 'hooks';
import { DateTime } from 'luxon';
import ICursorable from 'types/Cursorable';
import IMemberGroup, { IMemberGroupCreateInput, IMemberGroupUpdateInput } from 'types/MemberGroup';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { uuid } from 'utils/uuidUtils';

export default function useMemberGroup() {
  const { getAll } = useApolloPaging();
  const [createMemberGroupMutation] = useMutation(CREATE_MEMBER_GROUP);
  const [updateMemberGroupMutation] = useMutation(UPDATE_MEMBER_GROUP);

  async function getMemberGroups(groupId: string | null) {
    const results = await getAll<IMemberGroup & ICursorable>('memberGroups', {
      query: MEMBER_GROUPS_QUERY,
      variables: {
        first: 500,
        filter: {
          id: groupId ? { equal: groupId } : undefined,
          deletedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });

    return results;
  }

  async function createMemberGroup(organizationId: string, groupName: string) {
    const createOnUtc = getNowIsoAtUtcWithLocalTimeZone();
    const group: IMemberGroupCreateInput = {
      id: uuid(),
      organizationId,
      groupName,
      createdOn: createOnUtc,
    };

    return new Promise<IMemberGroup>((resolve, reject) => {
      return createMemberGroupMutation({ variables: { memberGroup: group } }).then((result) => {
        const memberGroup = result.data?.createMemberGroup;
        if (memberGroup) {
          resolve(memberGroup);
        } else {
          reject();
        }
      });
    });
  }

  async function updateMemberGroup(memberGroup: IMemberGroup, groupName: string | null) {
    const group: IMemberGroupUpdateInput = {
      id: memberGroup.id,
      groupName,
    };
    return new Promise<IMemberGroup>((resolve, reject) => {
      return updateMemberGroupMutation({ variables: { memberGroup: group } }).then((result) => {
        const memberGroup = result.data?.updateMemberGroup;
        if (memberGroup) {
          resolve(memberGroup);
        } else {
          reject();
        }
      });
    });
  }

  async function deleteMemberGroup(memberGroupId: string) {
    const group: IMemberGroupUpdateInput = {
      id: memberGroupId,
      deletedOn: DateTime.utc().toISO(),
    };
    return new Promise<IMemberGroup>((resolve, reject) => {
      return updateMemberGroupMutation({ variables: { memberGroup: group } }).then((result) => {
        const memberGroup = result.data?.updateMemberGroup;
        if (memberGroup) {
          resolve(memberGroup);
        } else {
          reject();
        }
      });
    });
  }

  return { getMemberGroups, createMemberGroup, updateMemberGroup, deleteMemberGroup };
}
