import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import { useToastOpen } from 'contexts/ToastContext';
import { useBreak } from 'hooks';
import { ICreateBreakData } from 'hooks/models/time-entry-break/useBreak';
import { IEntryAndLog } from 'hooks/models/time-entry/useTimeEntry';
import _, { filter, first, isNil } from 'lodash';
import { useCallback } from 'react';
import ITimeEntry from 'types/TimeEntry';
import { combineDateAndTime, dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { MULTIPLE, TCompositeBreak, TMultipleField } from '../BulkEditEntryForm/BulkEditEntryForm';
import { doBreaksTimeMatch } from '../util/utils';

export default function useBulkBreakSubmit() {
  const { createBreak, deleteBreak, updateBreak } = useBreak();
  const openToast = useToastOpen();

  return useCallback(
    async (
      breaks: IBreakMultiPickerItem[],
      breaksCleared: boolean,
      initialBreaks: TMultipleField<TCompositeBreak[]>,
      entryLogs: IEntryAndLog[],
      timeEntries: ITimeEntry[]
    ) => {
      if ((breaks && breaks.length > 0) || breaksCleared || (initialBreaks !== MULTIPLE && initialBreaks !== null)) {
        const timeEntryBreaks = entryLogs.flatMap(({ entry, log }) => {
          const fetchedEntry = timeEntries.find((timeEntry) => entry.id === timeEntry.id);
          return fetchedEntry!.breaks.map((brk) => ({
            timeEntryBreak: { ...brk, timeEntry: fetchedEntry! },
            entryId: entry.id,
            entryLogId: log.id,
          }));
        });

        const breakLookup = _.keyBy(timeEntryBreaks, (timeEntryBreak) => timeEntryBreak.timeEntryBreak.id);
        if (initialBreaks !== MULTIPLE) {
          //  matched have to be edited. unmatched have to be deleted. new breaks have to be added.
          const [matched, newBreaks] = _.partition(breaks, (sBreak) =>
            _.some(initialBreaks as TCompositeBreak[], (initialBreak) => sBreak.id === initialBreak.compositeId)
          );
          const unmatched = _.filter(
            initialBreaks,
            (initialBreak) => !_.some(breaks, (sBreak) => sBreak.id === initialBreak.compositeId)
          );

          const matchedBreaks = matched
            .flatMap((match) => {
              const brokenId = match.id.split(',');
              const breaks = brokenId.flatMap((id) => breakLookup[id]);
              if (
                !isNil(breaks[0].timeEntryBreak.endTime) &&
                !doBreaksTimeMatch(match.timeRange.startTime, match.timeRange.endTime, breaks[0].timeEntryBreak)
              ) {
                return breaks.map((brk) => ({ ...brk, timeRange: match.timeRange }));
              } else {
                return null;
              }
            })
            .filter((res) => !_.isNull(res));

          const matchedPromises = matchedBreaks.map((parsedBreak) =>
            updateBreak(
              parsedBreak!.entryLogId,
              parsedBreak!.entryId,
              parsedBreak!.timeEntryBreak,
              parsedBreak!.timeRange.startTime,
              parsedBreak!.timeRange.endTime
            )
          );
          const unmatchedBreaksPromises = unmatched.map((unmatch) => {
            const ids = unmatch.compositeId.split(',');
            ids.forEach((id) => {
              const payload = breakLookup[id];
              const breakToDelete = first(
                filter(timeEntryBreaks, (brk) => brk.timeEntryBreak.id === id)
              )?.timeEntryBreak;
              return deleteBreak(payload.entryLogId, payload.entryId, breakToDelete ?? unmatch);
            });
          });
          const newPromises = newBreaks.map((newBreak) => {
            const entryLogIds = entryLogs.map(({ entry, log }) => {
              const item: ICreateBreakData = {
                timeEntryId: entry.id,
                entryLogId: log.id,
                startTime: combineDateAndTime(dateTimeFromISOKeepZone(entry.startTime), newBreak.timeRange.startTime),
                endTime: combineDateAndTime(dateTimeFromISOKeepZone(entry.startTime), newBreak.timeRange.endTime),
              };
              return item;
            });
            return createBreak(entryLogIds);
          });

          await Promise.all(matchedPromises).catch(() =>
            openToast({ label: t('There was a problem editing your breaks.') })
          );
          await Promise.all(unmatchedBreaksPromises).catch(() =>
            openToast({ label: t('There was a problem deleting your breaks.') })
          );
          await Promise.all(newPromises).catch(() =>
            openToast({ label: t('There was a problem creating your breaks.') })
          );
        } else {
          // Since there are breaks, we need to clear out the existing breaks and add the current breaks.
          const deletePromises = timeEntries.map((entry) => {
            return entry.breaks.map((entryBreak) => {
              const payload = breakLookup[entryBreak.id];
              return deleteBreak(payload.entryLogId, payload.entryId, entryBreak);
            });
          });

          const breakPromises = breaks.map((breakItem) => {
            const entryLogIds = entryLogs.map(({ entry, log }) => {
              const item: ICreateBreakData = {
                timeEntryId: entry.id,
                entryLogId: log.id,
                startTime: combineDateAndTime(dateTimeFromISOKeepZone(entry.startTime), breakItem.timeRange.startTime),
                endTime: combineDateAndTime(dateTimeFromISOKeepZone(entry.startTime), breakItem.timeRange.endTime),
              };
              return item;
            });
            return createBreak(entryLogIds);
          });

          await Promise.all(deletePromises).catch(() =>
            openToast({ label: t('There was a problem deleting your breaks.') })
          );
          await Promise.all(breakPromises).catch(() =>
            openToast({ label: t('There was a problem creating your breaks.') })
          );
        }
      } else {
        // Submitted breaks don't exist state stays the same
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
