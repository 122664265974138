import { Button, Form, IFormRender } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import BasicPickerFormField from 'components/foundation/form-fields/BasicPickerFormField/BasicPickerFormField';
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useEmployeeNameFormat, { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { useRef } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';

export interface IEmployeeNameFormatFormProps {
  onSubmit: (formData: IEmployeeNameFormatFormData) => void;
  className?: ClassName;
}

const employeeNameFormatPickerOptions: IBasicPickerValue[] = [
  {
    id: 'FIRST_LAST',
    name: t('First Last (e.g. John Smith)'),
  },
  {
    id: 'LAST_FIRST',
    name: t('Last, First (e.g. Smith, John)'),
  },
  {
    id: 'FIRST',
    name: t('First Name (e.g. John)'),
  },
  {
    id: 'LAST',
    name: t('Last Name (e.g. Smith)'),
  },
];

export interface IEmployeeNameFormatFormData {
  format: EmployeeNameFormat;
}

function EmployeeNameFormatForm({ onSubmit, className }: IEmployeeNameFormatFormProps) {
  const [employeeNameFormat, updateEmployeeNameFormat] = useEmployeeNameFormat();

  const initialFormData = useRef<IEmployeeNameFormatFormData>({
    format: employeeNameFormat,
  });

  const brand = useBrandTitle();
  const classes = classNames('employee-name-format-form', className);

  function onFormSubmit(formData: IEmployeeNameFormatFormData) {
    updateEmployeeNameFormat(formData.format);
    onSubmit(formData);
  }

  function renderFormFields(form: IFormRender<IEmployeeNameFormatFormData>) {
    return (
      <>
        <HeaderText title={t('Employee Name Format')}>
          {t(
            'This determines how employee names should appear in {{brand}} reports. Since many reports are sorted alphabetically by name, the order in which employees appear in a report may change depending on the format.',
            { brand }
          )}
        </HeaderText>
        <BasicPickerFormField name="format" form={form} data={employeeNameFormatPickerOptions} className="mt-4" />
        <Button type="primary" onClick={form.handleSubmit}>
          {t('Update')}
        </Button>
      </>
    );
  }

  return <Form className={classes} onSubmit={onFormSubmit} render={renderFormFields} data={initialFormData.current} />;
}

export default EmployeeNameFormatForm;
