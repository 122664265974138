import { Reducer } from 'redux';
import { EmptyObject } from 'types/util/Object';
import { AUTH_MEMBER_SET, IStoreAuthMemberSetAction } from './authMemberIndex';
import { IStoreAuthMember } from './authMemberType';

export type IAuthMemberActions = IStoreAuthMemberSetAction;

const initialState: IStoreAuthMember | EmptyObject = {};

export const authMemberReducers: Reducer<IStoreAuthMember | EmptyObject, IAuthMemberActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AUTH_MEMBER_SET:
      return { ...state, ...action.payload.member };

    default:
      return state;
  }
};

export default authMemberReducers;
