import { EventImpl } from '@fullcalendar/core/internal';
import {
  RecurringScheduleReminder,
  RecurringScheduleReminderTypes,
  RecurringScheduleReminderUpdateInput,
  ScheduleReminderCreateInput,
  ScheduleReminderFilter,
  ScheduleReminderFrequencyTypes,
  ScheduleReminderUpdateInput,
} from '__generated__/graphql';
import classNames from 'classnames';
import { CalendarView } from 'components/domain/calendar/types/CalendarTypes';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { t } from 'i18next';
import { groupBy, isEmpty, isEqual, last, lowerCase, maxBy, orderBy, times, uniq } from 'lodash';
import partition from 'lodash/partition';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import { DateTime, Duration, WeekdayNumbers } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { DateTimeDayOfWeek, DayOfWeekValue } from 'types/enum/DayOfWeek';
import TimeOffType from 'types/enum/TimeOffType';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';
import { getGraphQlContainsComparison, getGraphQlDoesNotContainComparison } from 'utils/apolloUtils';
import { mapNotNull } from 'utils/collectionUtils';
import { HOUR } from 'utils/constants/timeInterval';
import {
  clampMinDate,
  clampMonthOfYear,
  clampNumberToDayOfMonth,
  dateTimeFromISOWithoutZone,
  getDateTimesBetween,
  getNowIsoAtUtcWithLocalTimeZone,
  getRangeTimeFromDate,
  getRangeTimeFromIso,
  getStartOfWeek,
  isDateTimeInTimeRange,
  isDst,
  isoTimeStampUtc,
  timeInRange,
} from 'utils/dateUtils';
import { collectIterator } from 'utils/iteratorUtils';
import { clampMax, clampMin } from 'utils/numberUtils';
import { fieldIsSet, typedObjectEntries } from 'utils/objectUtils';
import { calculateTextColorForBackground } from 'utils/styleUtils';
import { mapRange } from 'utils/timeRangeUtils';
import { getHourMinuteFormattedString } from 'utils/timeUtils';
import { isType } from 'utils/typeguard';
import { uuid } from 'utils/uuidUtils';
import { ISchedulingFormPrefillReminder } from '../SchedulingDialog/SchedulingDialog';
import { ISchedulingFormData } from '../SchedulingForm/SchedulingForm';
import { IWorkSchedulingFormData } from '../WorkSchedulingForm/WorkSchedulingForm';
import { defaultSchedulingColors } from '../constants/constants';
import { SchedulingQueryParams } from '../hooks/useSchedulingQueryParams';
import {
  IPreprocessedScheduleReminderDetails,
  IRecurringScheduleLinkReminderPayload,
  IScheduleRecurringReminderDetails,
  IScheduleReminderDetails,
  IScheduleReminderDetailsCommon,
  ISchedulingEvent,
  ISchedulingRecurringEvent,
  ISchedulingTimeOffEvent,
  RecurringDate,
  RecurringMonthlyDate,
  RecurringScheduleDay,
  RecurringWeeklyDate,
  RecurringYearlyDate,
  ScheduleCreatePayload,
  ScheduleReminder,
  ScheduleReminderJsonPayload,
  ScheduleReminderLink,
  ScheduleUpdatePayload,
  ScheduledDraftStatus,
  ScheduledStatus,
  SchedulesCreatePayload,
  SchedulingEventType,
  SchedulingRecurringSchedule,
  SchedulingReminderTypeUnion,
  SchedulingTimeOff,
  SchedulingType,
  SchedulingViewSetting,
} from '../types/types';
import { getCostCodeDisplay, getEquipmentDisplay, getFullNameFromMember } from 'utils/stringUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';

export function getSchedulingColor(type: SchedulingReminderTypeUnion, color?: string | null) {
  if (color) {
    return color;
  }

  // If it's not set then we default to the type default.
  return type === 'work' ? defaultSchedulingColors.work : defaultSchedulingColors.break;
}

export function combineOverlappedRanges(dates: Array<{ start: DateTime; end: DateTime }>) {
  const sorted = sortBy(dates, (d) => d.start.toSeconds());
  const first = sorted[0];

  if (!first) {
    return [];
  }

  function combine(date: { start: DateTime; end: DateTime }, index: number): Array<{ start: DateTime; end: DateTime }> {
    const nextIndex = index + 1;
    const next = sorted[nextIndex];
    if (!next) {
      return [date];
    }

    // Next is completely encapsulated, don't include
    if (next.end.toSeconds() <= date.end.toSeconds()) {
      return combine(date, nextIndex);
    } else if (next.start.toSeconds() <= date.end.toSeconds() && next.end.toSeconds() >= date.end.toSeconds()) {
      return combine({ start: date.start, end: next.end }, nextIndex);
    } else {
      return [date, ...combine(next, nextIndex)];
    }
  }

  return combine(first, 0);
}

// This is used for project/cost code/equipment since we just count pure time and overlapping doesnt matter
export function totalWorkSchedulesDatesWithoutOverlap(
  events: Array<{
    start: Date;
    end: Date;
    members: Array<{ id: string }>;
  }>
) {
  return events.reduce((acc, cur) => {
    const total = DateTime.fromJSDate(cur.end).toMillis() - DateTime.fromJSDate(cur.start).toMillis();
    return acc.plus(total * cur.members.length);
  }, Duration.fromMillis(0));
}

export function totalSchedulesDates(
  dates: Array<{
    start: Date;
    end: Date;
    type: 'work' | 'break';
  }>
): Duration {
  const [works, breaks] = partition(
    dates.map((d) => ({
      start: DateTime.fromJSDate(d.start),
      end: DateTime.fromJSDate(d.end),
      type: d.type,
    })),
    (d) => d.type === 'work'
  ).map(combineOverlappedRanges);

  return reduce<{ start: DateTime; end: DateTime }, Duration>(
    works,
    (acc, { start, end }) => {
      const startSeconds = start.toSeconds();
      const endSeconds = end.toSeconds();
      let totalSeconds = endSeconds - startSeconds;

      breaks.forEach(({ start: breakStart, end: breakEnd }) => {
        const breakStartSeconds = breakStart.toSeconds();
        const breakEndSeconds = breakEnd.toSeconds();
        const overlap = breakStartSeconds < endSeconds && breakEndSeconds > startSeconds;

        if (overlap) {
          if (breakStartSeconds >= startSeconds && breakEndSeconds <= endSeconds) {
            totalSeconds -= breakEndSeconds - breakStartSeconds;
          } else if (breakStartSeconds <= startSeconds) {
            // Break encapsulates entire entry
            totalSeconds = 0;
          } else if (breakStartSeconds >= startSeconds) {
            totalSeconds -= endSeconds - breakStartSeconds;
          } else if (breakEndSeconds <= endSeconds) {
            totalSeconds -= breakEndSeconds - breakStartSeconds;
          }
        }
      });

      if (acc === null) {
        return Duration.fromMillis(clampMin(totalSeconds, 0) * 1000);
      } else {
        return acc.plus(clampMin(totalSeconds, 0) * 1000);
      }
    },
    Duration.fromMillis(0)
  );
}

export function createSchedulingPayload({
  projectId,
  costCodeId,
  equipmentId,
  color,
  instructions,
}: {
  projectId: string | null | undefined;
  costCodeId: string | null | undefined;
  equipmentId: string | null | undefined;
  color: string | null | undefined;
  instructions: string | null | undefined;
}) {
  return JSON.stringify({
    project: projectId,
    cost_code: costCodeId,
    equipment: equipmentId,
    instructions,
    color,
  });
}

export function createSchedulingPayloadFromFormData(formData: IWorkSchedulingFormData): string {
  return createSchedulingPayload({
    projectId: formData.project,
    costCodeId: formData.costCode,
    equipmentId: formData.equipment,
    color: formData.color,
    instructions: formData.instructions,
  });
}

export function getAllMemberIdsFromScheduleReminders<T extends { links: Array<{ memberId: string }> }>(reminders: T[]) {
  return reminders.flatMap(getMemberIdsFromScheduleReminder);
}

export function getMemberIdsFromScheduleReminder<T extends { links: Array<{ memberId: string }> }>(reminder: T) {
  return reminder.links.map((link) => link.memberId);
}

export function transformTimeOffToScheduleEvent(timeOff: Omit<SchedulingTimeOff, 'cursor'>): ISchedulingTimeOffEvent {
  return {
    id: timeOff.id,
    start: DateTime.fromISO(timeOff.dateStamp).toJSDate(),
    end: DateTime.fromISO(timeOff.dateStamp).toJSDate(),
    seconds: timeOff.seconds ?? 0,
    type: timeOff.type,
    title: t('Time Off'),
    color: defaultSchedulingColors.timeOff,
    paid: timeOff.paid,
    members: [timeOff.member!],
    description: timeOff.description ?? null,
    allDay: true,
    // TODO punting on drafts maybe future option
    isDraft: false,
  };
}

export function isSchedulingEventTimeOff(event: SchedulingEventType): event is ISchedulingTimeOffEvent {
  return (
    event.end === undefined ||
    [TimeOffType.SICK, TimeOffType.HOLIDAY, TimeOffType.OTHER, TimeOffType.PERSONAL, TimeOffType.VACATION].some(
      (type) => event.type === type
    )
  );
}

export function isSchedulingEventRecurring(event: SchedulingEventType): event is ISchedulingRecurringEvent {
  return isType(event, 'recurringOriginId');
}

export function isSchedulingDetailRecurring(
  detail: IScheduleReminderDetailsCommon
): detail is IScheduleRecurringReminderDetails {
  return isType(detail, 'recurringOriginId');
}

export function isSchedulingFormDataWork(formData: IWorkSchedulingFormData): formData is IWorkSchedulingFormData {
  return isType(formData, 'color');
}

export function getSchedulingEventStyles(time: DateTime, color: Optional<string>) {
  const dayStart = DateTime.local().startOf('day');
  const past = time < dayStart;
  const backgroundColor = color ? color : defaultSchedulingColors.work;
  const textColor = calculateTextColorForBackground(backgroundColor);

  return {
    style: {
      // if it's null or light use white
      color: textColor === 'dark' ? '#000000' : '#ffffff',
      // make the items in the past transparent
      backgroundColor: past ? backgroundColor + '66' : backgroundColor,
    },
    className: classNames({
      dark: textColor === 'dark',
      light: textColor !== 'dark',
    }),
  };
}

export function getSchedulingTimeRangeFromParamString(
  time: string,
  currentView: CalendarView,
  weekStart: DayOfWeekValue
) {
  const currentTimeDay = DateTime.fromISO(time, { zone: 'system' });
  const validDate = currentTimeDay.isValid ? currentTimeDay : DateTime.utc();

  switch (currentView) {
    case 'month':
      // +/- 7 to account for blocked days
      return {
        startTime: validDate.startOf('month').minus({ days: 7 }),
        endTime: validDate.endOf('month').plus({ days: 7 }),
      };
    case 'week':
      return {
        startTime: getStartOfWeek(validDate, weekStart),
        endTime: getStartOfWeek(validDate, weekStart).plus({ day: 6 }).endOf('day'),
      };
    case 'day':
      return { startTime: validDate.startOf('day'), endTime: validDate.endOf('day') };
    default:
      throw Error(`Unsupported calendar view ${currentView}`);
  }
}

export function getSchedulingTimeOffDuration(event: Pick<ISchedulingTimeOffEvent, 'paid' | 'seconds'>) {
  return event.paid ? getHourMinuteFormattedString(event.seconds) : t('All Day');
}

const disallowedTimeOffKeys: Array<keyof SchedulingQueryParams> = [
  'colors',
  'costCodeGroupId',
  'costCodeId',
  'equipmentCategoryId',
  'equipmentId',
  'projectGroupId',
  'projectId',
];

export function shouldShowSchedulingTimeOffs(params: SchedulingQueryParams) {
  if (params.type && params.type !== 'time off') {
    return false;
  }

  if (params.draftStatus && params.draftStatus !== 'published') {
    return false;
  }

  return !disallowedTimeOffKeys.some((key) => fieldIsSet(params, key));
}

export function getSchedulingEventTimeOffInRangePredicate(timeRange: ITimeRange<DateTime>) {
  return (event: Pick<ISchedulingTimeOffEvent, 'start'>) => {
    const timeStamp = DateTime.fromJSDate(event.start).toLocal();
    return timeInRange(timeStamp, timeRange, true, true);
  };
}

export function isSchedulingEventUnpaidTimeOff(event: SchedulingEventType) {
  return isSchedulingEventTimeOff(event) && !event.paid;
}

export function getUniqueUnpaidTimeOffDaysCount(events: SchedulingEventType[]) {
  const uniqueDates = events.reduce((acc, cur) => {
    if (isSchedulingEventUnpaidTimeOff(cur)) {
      acc.add(cur.start.toDateString());
    }

    return acc;
  }, new Set<string>());

  return uniqueDates.size;
}

export function convertTimeOffEventToCreateTimeOffInput(
  event: Pick<ISchedulingTimeOffEvent, 'start' | 'members' | 'paid' | 'seconds' | 'type' | 'description'>,
  activeMemberId: string
) {
  const dateTime = DateTime.fromJSDate(event.start);

  return event.members.map(({ id }) => {
    return {
      dateStamp: dateTime.toISO(),
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
      createdOnDaylightSavingTime: isDst(),
      memberId: id,
      paid: event.paid,
      seconds: event.seconds,
      submitterMemberId: activeMemberId,
      type: event.type,
      description: event.description,
    } as const;
  });
}

export function getSchedulingTypeLabel(type: SchedulingType) {
  switch (type) {
    case 'work':
      return t('Scheduled Work');
    case 'time off':
      return t('Time Off');
    case 'break':
      return t('Scheduled Break');
  }
}

export function getSchedulingTypeFromString(value: Optional<string>): SchedulingReminderTypeUnion {
  if (value !== 'break' && value !== 'work') {
    throw Error('Invalid scheduling type');
  }

  return value;
}

export function getScheduledFilterLabel(type: ScheduledStatus) {
  switch (type) {
    case 'scheduled':
      return t('Scheduled');
    case 'unscheduled':
      return t('Unscheduled');
  }
}
export function getScheduleSettingViewLabel(type: SchedulingViewSetting) {
  switch (type) {
    case 'calendar':
      return t('Calendar');
    case 'weekly-attendance-report':
      return t('Weekly Attendance');
  }
}

export function getSchedulingIdFilterWithScheduledStatusConsideration(
  ids: string[],
  scheduledStatus: Optional<ScheduledStatus>,
  filterId: Optional<string>
) {
  if (!scheduledStatus) {
    return filterId ? { equal: filterId } : undefined;
  }

  const withFilterConsideration = filterId ? ids.filter((memberId) => memberId === filterId) : ids;
  if (scheduledStatus === 'unscheduled') {
    return getGraphQlDoesNotContainComparison(withFilterConsideration);
  } else {
    return getGraphQlContainsComparison(withFilterConsideration);
  }
}

export function convertFullCalendarSchedulingImplIntoScheduleType(event: EventImpl): SchedulingEventType {
  // Extended props is a dictionary with extra fields like project etc. but `EventImpl` is not generic so can't safely type have to cast
  return {
    ...event.extendedProps,
    id: event.id,
    start: event.start,
    end: event.end,
    title: event.title,
    color: event.backgroundColor,
  } as SchedulingEventType;
}

export function convertDayOfWeekToCalendarFirstDay(dayOfWeek: DayOfWeekValue) {
  switch (dayOfWeek) {
    case DayOfWeekValue.SUNDAY:
      return 0;
    case DayOfWeekValue.MONDAY:
      return 1;
    case DayOfWeekValue.TUESDAY:
      return 2;
    case DayOfWeekValue.WEDNESDAY:
      return 3;
    case DayOfWeekValue.THURSDAY:
      return 4;
    case DayOfWeekValue.FRIDAY:
      return 5;
    case DayOfWeekValue.SATURDAY:
      return 6;
  }
}

export function getSchedulingToolbarLabel(view: CalendarView, selectedDate: DateTime, weekStart: DateTime) {
  switch (view) {
    case 'day':
      return selectedDate.toFormat('EEE MMMM dd');
    case 'week': {
      const formatWithMonth = 'MMMM dd';
      const end = weekStart.plus({ day: 6 });
      const sameMonth = weekStart.month === end.month;
      return `${weekStart.toFormat(formatWithMonth)} - ${end.toFormat(sameMonth ? 'dd' : formatWithMonth)}`;
    }
    case 'month':
      return selectedDate.toFormat('MMMM yyyy');
  }
}

export function getMonthDayFromRecurringDate(date: RecurringDate) {
  if (date.frequency !== 'weekly') {
    return date.dayOfMonth;
  }

  return null;
}

export function getMonthFromRecurringDate(date: RecurringDate) {
  if (date.frequency === 'yearly') {
    return date.month;
  }

  return null;
}

export function convertRecurringDateWeekDayToDateTimeWeekDay(day: RecurringScheduleDay) {
  switch (day) {
    case 'sunday':
      return DateTimeDayOfWeek.SUNDAY;
    case 'monday':
      return DateTimeDayOfWeek.MONDAY;
    case 'tuesday':
      return DateTimeDayOfWeek.TUESDAY;
    case 'wednesday':
      return DateTimeDayOfWeek.WEDNESDAY;
    case 'thursday':
      return DateTimeDayOfWeek.THURSDAY;
    case 'friday':
      return DateTimeDayOfWeek.FRIDAY;
    case 'saturday':
      return DateTimeDayOfWeek.SATURDAY;
  }
}

export function convertRecurringDateWeekDayToDayOfWeekValue(day: RecurringScheduleDay): DayOfWeekValue {
  switch (day) {
    case 'sunday':
      return DayOfWeekValue.SUNDAY;
    case 'monday':
      return DayOfWeekValue.MONDAY;
    case 'tuesday':
      return DayOfWeekValue.TUESDAY;
    case 'wednesday':
      return DayOfWeekValue.WEDNESDAY;
    case 'thursday':
      return DayOfWeekValue.THURSDAY;
    case 'friday':
      return DayOfWeekValue.FRIDAY;
    case 'saturday':
      return DayOfWeekValue.SATURDAY;
  }
}

// frequency expiration is exclusive
function getLastDateTimeOfRecurringSchedule(recurringDate: RecurringDate) {
  return recurringDate.frequencyExpiration
    ? adjustFrequencyExpirationDateToBeInclusive(recurringDate.frequencyExpiration)
    : null;
}

// We've changed from frequency expiration being exclusive to being inclusive
export function adjustFrequencyExpirationDateToBeInclusive(expiration: DateTime) {
  return expiration;
}

export function getWeeklyRecurringDatesInRange(timeRange: ITimeRange<DateTime>, recurringDate: RecurringWeeklyDate) {
  const iterator = getWeeklyRecurringDatesInRangeIterator(timeRange, recurringDate);
  return collectIterator(iterator);
}

export function* getWeeklyRecurringDatesInRangeIterator(
  timeRange: ITimeRange<DateTime>,
  recurringDate: RecurringWeeklyDate
) {
  const { startTime, endTime } = timeRange;
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);

  if (lastDateOfRecurringSchedule && lastDateOfRecurringSchedule.toSeconds() < startTime.toSeconds()) {
    return null;
  }

  const days = mapNotNull(typedObjectEntries(recurringDate.days), ([day, value]) => (value ? day : null));
  const dateTimeWeekDays = new Set(days.map(convertRecurringDateWeekDayToDateTimeWeekDay));
  const datesBetween = getDateTimesBetween(startTime, endTime);

  for (let i = 0; i < datesBetween.length; i++) {
    const date = datesBetween[i];

    if (lastDateOfRecurringSchedule && date.toSeconds() > lastDateOfRecurringSchedule.toSeconds()) {
      continue;
    }

    if (dateTimeWeekDays.has(date.weekday)) {
      yield date;
    }
  }
}

export function getMonthlyRecurringDatesInRange(timeRange: ITimeRange<DateTime>, recurringDate: RecurringMonthlyDate) {
  const iterator = getMonthlyRecurringDatesInRangeIterator(timeRange, recurringDate);
  return collectIterator(iterator);
}

export function* getMonthlyRecurringDatesInRangeIterator(
  timeRange: ITimeRange<DateTime>,
  recurringDate: RecurringMonthlyDate
) {
  const { startTime, endTime } = timeRange;
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);

  if (lastDateOfRecurringSchedule && lastDateOfRecurringSchedule.toSeconds() < startTime.toSeconds()) {
    return null;
  }

  const absoluteStartMonth = (startTime.year - 1) * 12 + startTime.month;
  const absoluteEndMonth = (endTime.year - 1) * 12 + endTime.month;
  const monthOffsets = times(absoluteEndMonth - absoluteStartMonth + 1);

  for (let i = 0; i < monthOffsets.length; i++) {
    const date = startTime.plus({ month: monthOffsets[i] });
    const clampedDay = clampMax(recurringDate.dayOfMonth, date.daysInMonth);
    // This needs to be done in here instead of outside because if you set a date to the 31st and the month only has 30 days it will go to the next month
    const adjustedDate = date.set({ day: clampedDay });

    if (
      isDateTimeInTimeRange(adjustedDate, timeRange, true, true) &&
      (!lastDateOfRecurringSchedule || adjustedDate.toSeconds() <= lastDateOfRecurringSchedule.toSeconds())
    ) {
      yield adjustedDate;
    }
  }
}

export function getYearlyRecurringDatesInRange(timeRange: ITimeRange<DateTime>, recurringDate: RecurringYearlyDate) {
  const iterator = getYearlyRecurringDatesInRangeIterator(timeRange, recurringDate);
  return collectIterator(iterator);
}

export function* getYearlyRecurringDatesInRangeIterator(
  timeRange: ITimeRange<DateTime>,
  recurringDate: RecurringYearlyDate
) {
  const { startTime, endTime } = timeRange;
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);

  if (lastDateOfRecurringSchedule && lastDateOfRecurringSchedule.toSeconds() < startTime.toSeconds()) {
    return [];
  }

  const years = times(endTime.year - startTime.year + 1, (year) => startTime.year + year);

  for (let i = 0; i < years.length; i++) {
    const date = DateTime.fromObject({ month: recurringDate.month, day: recurringDate.dayOfMonth, year: years[i] });

    if (
      date.isValid &&
      isDateTimeInTimeRange(date, timeRange, true, true) &&
      (!lastDateOfRecurringSchedule || date.toSeconds() <= lastDateOfRecurringSchedule.toSeconds())
    ) {
      yield date;
    }
  }
}

export function getLastRecurringWeeklyDate(
  recurringDate: RecurringWeeklyDate,
  startDate: DateTime,
  blacklistedIsoDates: Set<string>
) {
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);
  if (!lastDateOfRecurringSchedule) {
    return null;
  }

  const secondsBetween = lastDateOfRecurringSchedule.toSeconds() - startDate.toSeconds();

  if (secondsBetween < 0) {
    return null;
  }

  const maxWeeks = Math.ceil(secondsBetween / (HOUR * 24 * 7));
  const days = mapNotNull(typedObjectEntries(recurringDate.days), ([day, value]) => (value ? day : null));
  const dateTimeWeekDays = days.map(convertRecurringDateWeekDayToDayOfWeekValue);

  for (let currentWeek = maxWeeks; currentWeek >= 0; currentWeek--) {
    const date = startDate.plus({ week: currentWeek });

    // need to go backwards through the list chronologically
    const adjustedDates = orderBy(
      dateTimeWeekDays.map((dateTimeDay) => getStartOfWeek(date, dateTimeDay)),
      (elt) => -elt.toSeconds()
    );

    const dateMatch = adjustedDates.find((adjustedDate) => {
      if (startDate.toISODate() > adjustedDate.toISODate()) {
        return false;
      }

      if (adjustedDate.toISODate() > lastDateOfRecurringSchedule.toISODate()) {
        return false;
      }

      const dateHasBeenDeleted = blacklistedIsoDates.has(adjustedDate.toISODate());
      if (dateHasBeenDeleted) {
        return false;
      }

      return true;
    });

    if (!dateMatch) {
      continue;
    }

    return dateMatch;
  }

  return null;
}

export function getLastRecurringMonthlyDate(
  recurringDate: RecurringMonthlyDate,
  startDate: DateTime,
  blacklistedIsoDates: Set<string>
) {
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);
  if (!lastDateOfRecurringSchedule) {
    return null;
  }

  const lastYear = lastDateOfRecurringSchedule.year;
  const month = lastDateOfRecurringSchedule.month;
  const day = recurringDate.dayOfMonth;

  const maxYears = lastYear - startDate.year + 1;
  const maxIters = (maxYears - 1) * 12 + (month - startDate.month);

  for (let monthOffset = maxIters; monthOffset >= 0; monthOffset--) {
    const date = startDate.plus({ month: monthOffset });

    const adjustedDate = date.daysInMonth < day ? date.endOf('month').startOf('day') : date.set({ day });

    if (adjustedDate.toISODate() > lastDateOfRecurringSchedule.toISODate()) {
      continue;
    }

    if (startDate.toISODate() > adjustedDate.toISODate()) {
      continue;
    }

    const dateHasBeenDeleted = blacklistedIsoDates.has(adjustedDate.toISODate());

    if (dateHasBeenDeleted) {
      continue;
    }

    return adjustedDate;
  }

  return null;
}

export function getLastRecurringYearlyDate(
  recurringDate: RecurringYearlyDate,
  startDate: DateTime,
  blacklistedIsoDates: Set<string>
) {
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);
  if (!lastDateOfRecurringSchedule) {
    return null;
  }

  const lastYear = lastDateOfRecurringSchedule.year;

  for (let year = lastYear; year >= startDate.year; year--) {
    const date = DateTime.fromObject({ month: recurringDate.month, day: recurringDate.dayOfMonth, year });

    if (date.toISODate() > lastDateOfRecurringSchedule.toISODate()) {
      continue;
    }

    if (date.toISODate() < startDate.toISODate()) {
      continue;
    }

    const dateHasBeenDeleted = blacklistedIsoDates.has(date.toISODate());

    if (dateHasBeenDeleted) {
      continue;
    }

    return date;
  }

  return null;
}

// Extra iso dates for if there's a one off after the expiration date :)
export function getLastRecurringDate(
  recurringDate: RecurringDate,
  startDate: DateTime,
  nonDeleteOneOffDateIsos: string[],
  deletedIsoDates: string[]
) {
  // If there's no expiration there's no last item
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);
  if (!lastDateOfRecurringSchedule) {
    return null;
  }

  const oneOffDates = nonDeleteOneOffDateIsos.map((isoDate) => DateTime.fromISO(isoDate));

  const maxDate = maxBy(oneOffDates, (isoDate) => isoDate.toSeconds());
  const oneOffMaxAfterExpiration = maxDate && maxDate.toSeconds() > lastDateOfRecurringSchedule.toSeconds();

  if (oneOffMaxAfterExpiration) {
    return maxDate;
  }

  const blacklistedDates = new Set(deletedIsoDates);

  if (recurringDate.frequency === 'weekly') {
    return getLastRecurringWeeklyDate(recurringDate, startDate, blacklistedDates);
  } else if (recurringDate.frequency === 'monthly') {
    return getLastRecurringMonthlyDate(recurringDate, startDate, blacklistedDates);
  } else if (recurringDate.frequency === 'yearly') {
    return getLastRecurringYearlyDate(recurringDate, startDate, blacklistedDates);
  }

  return null;
}

export function convertRecurringScheduleToRecurringScheduleDetails(
  schedule: SchedulingRecurringSchedule,
  timeRange: ITimeRange<DateTime>
): IScheduleRecurringReminderDetails[] {
  const startDate = DateTime.fromISO(schedule.startTime);

  const timeRangeWithRecurringScheduleStartConsideration = {
    startTime: clampMinDate(startDate, timeRange.startTime).startOf('day'),
    endTime: timeRange.endTime.endOf('day'),
  };

  const recurringDate = convertRecurringScheduleObjectToRecurringDateValue(schedule);
  const dates = convertRecurringDateToDaysInRange(recurringDate, timeRangeWithRecurringScheduleStartConsideration);
  const parsedPayload = schedule.payload ? JSON.parse(schedule.payload) : {};

  return dates.map((date) => {
    const scheduleStart = DateTime.fromISO(schedule.startTime!);
    const scheduleEnd = DateTime.fromISO(schedule.endTime!);

    const startWithTime = date.set({ hour: scheduleStart.hour, minute: scheduleStart.minute });
    const endWithTime = date.set({ hour: scheduleEnd.hour, minute: scheduleEnd.minute });
    const type = schedule.type === RecurringScheduleReminderTypes.Break ? 'break' : 'work';

    return {
      id: uuid(),
      recurringOriginId: schedule.id,
      links: schedule.links,
      notifyEmployee: schedule.notifyEmployee,
      startTime: startWithTime.toISO(),
      endTime: endWithTime.toISO(),
      color: type === 'break' ? defaultSchedulingColors.break : parsedPayload.color ?? defaultSchedulingColors.work,
      instructions: parsedPayload.instructions ?? null,
      type,
      isDraft: schedule.isDraft,
      recurringDate,
      project: schedule.project ?? null,
      costCode: schedule.costCode ?? null,
      equipment: schedule.equipment ?? null,
    };
  });
}

export function convertRecurringScheduleDetailsToRecurringEvent(
  recurringScheduleReminderDetails: IScheduleRecurringReminderDetails
): ISchedulingRecurringEvent {
  const title = getRangeTimeFromIso(
    recurringScheduleReminderDetails.startTime,
    recurringScheduleReminderDetails.endTime,
    't'
  );

  const { links, ...rest } = recurringScheduleReminderDetails;

  return {
    ...rest,
    title,
    start: DateTime.fromISO(recurringScheduleReminderDetails.startTime).toJSDate(),
    end: DateTime.fromISO(recurringScheduleReminderDetails.endTime).toJSDate(),
    members: links.map((link) => link.member),
    recurringStatus: 'recurring',
  };
}

export function convertRecurringDateToDaysInRange(
  recurringDate: RecurringDate,
  timeRange: ITimeRange<DateTime>
): DateTime[] {
  const { frequency, frequencyExpiration } = recurringDate;

  if (frequencyExpiration && frequencyExpiration.toSeconds() < timeRange.startTime.toSeconds()) {
    return [];
  }

  if (frequency === 'yearly') {
    return getYearlyRecurringDatesInRange(timeRange, recurringDate);
  } else if (frequency === 'monthly') {
    return getMonthlyRecurringDatesInRange(timeRange, recurringDate);
  } else if (frequency === 'weekly') {
    return getWeeklyRecurringDatesInRange(timeRange, recurringDate);
  }

  return [];
}

export function getRecurringWeeklyDaysWithDefault(dateTimeWeekDay: WeekdayNumbers): RecurringWeeklyDate['days'] {
  return {
    monday: dateTimeWeekDay === DateTimeDayOfWeek.MONDAY,
    tuesday: dateTimeWeekDay === DateTimeDayOfWeek.TUESDAY,
    wednesday: dateTimeWeekDay === DateTimeDayOfWeek.WEDNESDAY,
    thursday: dateTimeWeekDay === DateTimeDayOfWeek.THURSDAY,
    friday: dateTimeWeekDay === DateTimeDayOfWeek.FRIDAY,
    saturday: dateTimeWeekDay === DateTimeDayOfWeek.SATURDAY,
    sunday: dateTimeWeekDay === DateTimeDayOfWeek.SUNDAY,
  };
}

export function getDefaultSchedulingRecurringDateValue(date: DateTime): RecurringDate {
  return {
    frequency: 'weekly',
    days: getRecurringWeeklyDaysWithDefault(date.weekday),
    frequencyExpiration: null,
  };
}

export function convertRecurringScheduleObjectToRecurringDateValue({
  frequency,
  frequencyExpiration,
  dayOfMonth,
  monthOfYear,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}: Pick<
  SchedulingRecurringSchedule,
  | 'frequency'
  | 'frequencyExpiration'
  | 'dayOfMonth'
  | 'monthOfYear'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
>): RecurringDate {
  switch (frequency) {
    case ScheduleReminderFrequencyTypes.Weekly:
      return {
        frequency: 'weekly',
        days: {
          monday: monday ?? false,
          tuesday: tuesday ?? false,
          wednesday: wednesday ?? false,
          thursday: thursday ?? false,
          friday: friday ?? false,
          saturday: saturday ?? false,
          sunday: sunday ?? false,
        },
        frequencyExpiration: frequencyExpiration ? DateTime.fromISO(frequencyExpiration) : null,
      };
    case ScheduleReminderFrequencyTypes.Monthly:
      return {
        frequency: 'monthly',
        dayOfMonth: clampNumberToDayOfMonth(dayOfMonth!),
        frequencyExpiration: frequencyExpiration ? DateTime.fromISO(frequencyExpiration) : null,
      };
    case ScheduleReminderFrequencyTypes.Yearly:
      return {
        frequency: 'yearly',
        dayOfMonth: clampNumberToDayOfMonth(dayOfMonth!),
        month: clampMonthOfYear(monthOfYear!),
        frequencyExpiration: frequencyExpiration ? DateTime.fromISO(frequencyExpiration) : null,
      };
  }
}

export function convertRecurringDateFrequencyToScheduleReminderFrequency(frequency: RecurringDate['frequency']) {
  switch (frequency) {
    case 'weekly':
      return ScheduleReminderFrequencyTypes.Weekly;
    case 'monthly':
      return ScheduleReminderFrequencyTypes.Monthly;
    case 'yearly':
      return ScheduleReminderFrequencyTypes.Yearly;
  }
}

export function convertSchedulingFormToRecurringSchedule(
  type: SchedulingReminderTypeUnion,
  formData: IWorkSchedulingFormData,
  startDate: DateTime,
  isDraft: boolean,
  reminderId: string = uuid()
): IRecurringScheduleLinkReminderPayload {
  if (type === 'work') {
    return convertCreateWorkSchedulingFormDataToRecurringSchedule(formData, startDate, isDraft, reminderId);
  } else {
    return convertCreateBreakSchedulingFormDataToRecurringSchedule(formData, startDate, isDraft, reminderId);
  }
}

// TODO how to get rid of isDraft without create 4 fns
export function convertCreateWorkSchedulingFormDataToRecurringSchedule(
  formData: IWorkSchedulingFormData,
  startDate: DateTime,
  isDraft: boolean,
  reminderId: string = uuid()
): IRecurringScheduleLinkReminderPayload {
  const { startTime, endTime } = mapRange(formData, (time) => {
    return startDate.set({ hour: time.hour, minute: time.minute, second: 0, millisecond: 0 }).toISO();
  });

  const frequencyExpiration = formData.recurringDate.frequencyExpiration?.endOf('day').toISO() ?? null;
  const stringedInstructions = formData.instructions?.trim() ?? '';
  const instructions = !isEmpty(stringedInstructions) ? stringedInstructions : null;

  return {
    links: formData.members.map((memberId) => ({
      id: uuid(),
      memberId,
      scheduleReminderId: reminderId,
    })),
    recurringReminder: {
      id: reminderId,
      type: RecurringScheduleReminderTypes.Work,
      startTime,
      endTime,
      // Payload does not set project id/cost code id/equipment id on recurring schedules
      payload: JSON.stringify({
        color: formData.color,
        instructions,
      }),
      projectId: formData.project ?? null,
      costCodeId: formData.costCode ?? null,
      equipmentId: formData.equipment ?? null,
      isDraft,
      frequency: convertRecurringDateFrequencyToScheduleReminderFrequency(formData.recurringDate.frequency),
      dayOfMonth: getMonthDayFromRecurringDate(formData.recurringDate),
      monthOfYear: getMonthFromRecurringDate(formData.recurringDate),
      frequencyExpiration: frequencyExpiration ? dateTimeFromISOWithoutZone(frequencyExpiration).toISO() : null,
      notifyEmployee: formData.notifyEmployee,
      ...(formData.recurringDate.frequency === 'weekly' ? formData.recurringDate.days : {}),
    },
  };
}

export function convertCreateBreakSchedulingFormDataToRecurringSchedule(
  formData: ISchedulingFormData,
  startDate: DateTime,
  isDraft: boolean,
  reminderId: string = uuid()
): IRecurringScheduleLinkReminderPayload {
  const { startTime, endTime } = mapRange(formData, (time) => {
    return startDate.set({ hour: time.hour, minute: time.minute }).toISO();
  });

  return {
    links: formData.members.map((memberId) => ({
      id: uuid(),
      memberId,
      scheduleReminderId: reminderId,
    })),
    recurringReminder: {
      id: reminderId,
      type: RecurringScheduleReminderTypes.Break,
      startTime,
      endTime,
      payload: null,
      isDraft,
      frequency: convertRecurringDateFrequencyToScheduleReminderFrequency(formData.recurringDate.frequency),
      frequencyExpiration: formData.recurringDate.frequencyExpiration?.toISO(),
      notifyEmployee: formData.notifyEmployee,
      dayOfMonth: getMonthDayFromRecurringDate(formData.recurringDate),
      monthOfYear: getMonthFromRecurringDate(formData.recurringDate),
      ...(formData.recurringDate.frequency === 'weekly' ? formData.recurringDate.days : {}),
    },
  };
}

export function convertSchedulingFormToScheduleCreatePayload(
  formData: ISchedulingFormData,
  type: SchedulingReminderTypeUnion,
  isDraft: boolean
): Omit<ScheduleCreatePayload, 'overrideRecurringScheduleId' | 'fetchAfter'> {
  const payload = type === 'work' ? createSchedulingPayloadFromFormData(formData) : null;

  return {
    type,
    startTime: formData.startTime.toISO(),
    endTime: formData.endTime.toISO(),
    payload,
    isDraft,
    memberIds: formData.members,
    notifyEmployee: formData.notifyEmployee,
    day: DateTime.fromJSDate(formData.startDate.value!),
  };
}

export function convertSchedulingFormToSchedulesCreatePayload(
  formData: ISchedulingFormData,
  type: SchedulingReminderTypeUnion,
  isDraft: boolean
): Omit<SchedulesCreatePayload, 'overrideRecurringScheduleId' | 'fetchAfter'> {
  const payload = type === 'work' ? createSchedulingPayloadFromFormData(formData) : null;

  return {
    startDate: formData.startDate.value!,
    endDate: formData.endDate ? formData.endDate.value! : undefined,
    startTime: formData.startTime.toISOTime(),
    endTime: formData.endTime.toISOTime(),
    memberIds: formData.members,
    type,
    notifyEmployee: formData.notifyEmployee,
    payload,
    isDraft,
  };
}

export function convertSchedulingFormToScheduleUpdatePayload(
  formData: ISchedulingFormData,
  editingScheduleItem: IScheduleReminderDetails,
  type: SchedulingReminderTypeUnion,
  isDraft: boolean
): ScheduleUpdatePayload {
  return {
    editingScheduleItem,
    memberIds: formData.members,
    startTime: formData.startTime.toISOTime(),
    endTime: formData.endTime.toISOTime(),
    notifyEmployee: formData.notifyEmployee,
    payload: createSchedulingPayloadFromFormData(formData),
    startDate: formData.startDate.value!,
    type,
    isDraft,
  };
}

export function getSchedulingDraftStatusGraphQLArgument(
  draftStatus: Optional<ScheduledDraftStatus>
): Pick<ScheduleReminderFilter, 'isDraft'> | undefined {
  if (draftStatus === 'draft') {
    return { isDraft: { equal: true } };
  }

  if (draftStatus === 'published') {
    return { isDraft: { equalOrNull: false } };
  }

  return undefined;
}

export function filterOverriddenRecurringScheduleEvents<
  T extends Pick<IScheduleRecurringReminderDetails, 'recurringOriginId' | 'startTime'>,
>(
  recurringSchedules: T[],
  schedules: Pick<IScheduleReminderDetails, 'overrideRecurringScheduleId' | 'startTime'>[]
): T[] {
  const overriddenScheduleMap = groupBy(schedules, (schedule) => schedule.overrideRecurringScheduleId);
  const recurringScheduleMap = groupBy(recurringSchedules, (schedule) => schedule.recurringOriginId);

  return typedObjectEntries(recurringScheduleMap).flatMap(([recurringOriginId, recurringSchedules]) => {
    const overriddenSchedules = overriddenScheduleMap[recurringOriginId] ?? [];
    return filterOverriddenRecurringScheduleDetails(recurringSchedules, overriddenSchedules);
  });
}

export function filterOverriddenRecurringScheduleDetails<
  T extends Pick<IScheduleRecurringReminderDetails, 'recurringOriginId' | 'startTime'>,
>(
  recurringSchedules: T[],
  linkedSchedules: Pick<IScheduleReminderDetails, 'overrideRecurringScheduleId' | 'startTime'>[]
) {
  const datesToBeRemoved = new Set(linkedSchedules.map((schedule) => DateTime.fromISO(schedule.startTime).toISODate()));

  return recurringSchedules.filter((schedule) => {
    const date = DateTime.fromISO(schedule.startTime).toISODate();
    return !datesToBeRemoved.has(date);
  });
}

export function convertRecurringScheduleToScheduleCreatePayload(
  recurringSchedule: IScheduleRecurringReminderDetails
): ScheduleReminderCreateInput {
  return {
    type: recurringSchedule.type,
    startTime: recurringSchedule.startTime,
    endTime: recurringSchedule.endTime,
    isDraft: recurringSchedule.isDraft,
    notifyEmployee: recurringSchedule.notifyEmployee,
    createdOn: isoTimeStampUtc(),
    payload: getJSONPayloadFromSchedulingReminder(recurringSchedule),
  };
}

export function convertScheduleReminderDetailsIntoScheduleEvent(schedule: IScheduleReminderDetails): ISchedulingEvent {
  const start = DateTime.fromISO(schedule.startTime).toJSDate();
  const end = DateTime.fromISO(schedule.endTime).toJSDate();

  const title = getRangeTimeFromDate(start, end, 't', 'system');

  let color: string = schedule.color || '';
  if (!color) {
    if (schedule.type === 'work') {
      color = defaultSchedulingColors.work;
    } else {
      color = defaultSchedulingColors.break;
    }
  }

  return {
    ...schedule,
    id: schedule.id,
    title,
    members: schedule.links.map((link) => link.member),
    type: getSchedulingTypeFromString(schedule.type),
    start,
    end,
    color,
    project: schedule.project ?? null,
    costCode: schedule.costCode ?? null,
    equipment: schedule.equipment ?? null,
    instructions: schedule.instructions,
    recurringStatus: schedule.overrideRecurringScheduleId ? 'one-off' : 'non-recurring',
  };
}

export function getJSONPayloadFromSchedulingReminder(
  schedulingReminderEvent: Pick<
    IScheduleReminderDetailsCommon,
    'project' | 'costCode' | 'equipment' | 'color' | 'instructions'
  >
) {
  return createSchedulingPayload({
    projectId: schedulingReminderEvent.project?.id ?? null,
    costCodeId: schedulingReminderEvent.costCode?.id ?? null,
    equipmentId: schedulingReminderEvent.equipment?.id ?? null,
    color: schedulingReminderEvent.color,
    instructions: schedulingReminderEvent.instructions,
  });
}

export function convertScheduleReminderCommonDetailsToScheduleFormPrefill(
  reminder: Omit<IScheduleReminderDetailsCommon, 'id'> & { id: Nullable<string> }
): Omit<ISchedulingFormPrefillReminder, 'isOneOff'> {
  return {
    id: reminder.id,
    memberIds: reminder.links.map(({ member }) => member.id),
    startDate: DateTime.fromISO(reminder.startTime).startOf('day').toISO(),
    startTime: reminder.startTime,
    endTime: reminder.endTime,
    notifyEmployee: reminder.notifyEmployee ?? false,
    projectId: reminder.project?.id ?? null,
    costCodeId: reminder.costCode?.id ?? null,
    equipmentId: reminder.equipment?.id ?? null,
    instructions: reminder.instructions ?? null,
    color: reminder.color ?? null,
    type: reminder.type,
    isDraft: reminder.isDraft,
    recurringDate: null,
  };
}

export function convertScheduleRecurringReminderDetailsToScheduleFormPrefill(
  reminder: Omit<IScheduleRecurringReminderDetails, 'id'> & { id: Nullable<string> },
  recurringStartDateIso: string
): ISchedulingFormPrefillReminder {
  return {
    ...convertScheduleReminderCommonDetailsToScheduleFormPrefill(reminder),
    startDate: recurringStartDateIso,
    recurringDate: reminder.recurringDate,
    isOneOff: false,
  };
}

export function convertScheduleReminderDetailsToScheduleFormPrefill(
  reminder: Omit<IScheduleReminderDetails, 'id'> & { id: Nullable<string> }
): ISchedulingFormPrefillReminder {
  return {
    ...convertScheduleReminderCommonDetailsToScheduleFormPrefill(reminder),
    isOneOff: !!reminder.overrideRecurringScheduleId,
  };
}

export function convertPreprocessedScheduleReminderDetailsToScheduleReminderDetails(
  preprocessedDetails: IPreprocessedScheduleReminderDetails[]
): IScheduleReminderDetails[] {
  return mapNotNull<IPreprocessedScheduleReminderDetails, IScheduleReminderDetails>(
    preprocessedDetails,
    (preprocessedDetail) => {
      if (preprocessedDetail.type === 'deleted') {
        return null;
      }

      return {
        ...preprocessedDetail,
        type: getSchedulingTypeFromString(preprocessedDetail.type),
      };
    }
  );
}

export type DiffedRecurringScheduleValue = {
  startTime: Optional<string>;
  endTime: Optional<string>;
  notifyEmployee: Optional<boolean>;
  projectId: Optional<string>;
  costCodeId: Optional<string>;
  equipmentId: Optional<string>;
  instructions: Optional<string>;
  color: Optional<string>;
  memberIds: Optional<string[]>;
  isDraft: Optional<boolean>;
};

export type RecurringScheduleDiffedKey = keyof DiffedRecurringScheduleValue;
export type DiffableRecurringScheduleReminder = Pick<
  RecurringScheduleReminder,
  'startTime' | 'endTime' | 'notifyEmployee' | 'projectId' | 'costCodeId' | 'equipmentId' | 'payload' | 'isDraft'
> & { links: DiffableRecurringScheduleBasicLink[] };

type DiffableRecurringScheduleBasicLink = {
  memberId: string;
};

export function diffOriginalRecurringReminderWithUpdatedRecurringReminder(
  originalRecurringReminder: DiffableRecurringScheduleReminder,
  updatedRecurringSchedule: DiffableRecurringScheduleReminder
): RecurringScheduleDiffedKey[] {
  const topLevelKeys: Array<keyof DiffedRecurringScheduleValue & keyof DiffableRecurringScheduleReminder> = [
    'notifyEmployee',
    'projectId',
    'costCodeId',
    'equipmentId',
    'isDraft',
  ];

  const topLevelKeyChanges = topLevelKeys.reduce((acc, cur) => {
    if (originalRecurringReminder[cur] !== updatedRecurringSchedule[cur]) {
      acc.push(cur);
    }
    return acc;
  }, new Array<keyof DiffedRecurringScheduleValue & keyof DiffableRecurringScheduleReminder>());

  const timeValues = ['startTime', 'endTime'] as const;

  const timeKeyChanges = timeValues.reduce((acc, cur) => {
    const originalTime = DateTime.fromISO(originalRecurringReminder[cur]).toISOTime();
    const updatedTime = DateTime.fromISO(updatedRecurringSchedule[cur]).toISOTime();

    if (originalTime !== updatedTime) {
      acc.push(cur);
    }
    return acc;
  }, new Array<keyof DiffedRecurringScheduleValue & keyof DiffableRecurringScheduleReminder>());

  const originalPayload = JSON.parse(originalRecurringReminder.payload ?? '{}');
  const newPayload = JSON.parse(updatedRecurringSchedule.payload ?? '{}');

  const payloadKeys = ['color', 'instructions'] as const;

  const payloadKeyChanges = payloadKeys.reduce((acc, cur) => {
    if (lowerCase(originalPayload[cur]) !== lowerCase(newPayload[cur])) {
      acc.push(cur);
    }
    return acc;
  }, new Array<keyof DiffedRecurringScheduleValue>());

  const originalMembers = originalRecurringReminder.links.map((link) => link.memberId);
  const newMembers = updatedRecurringSchedule.links.map((link) => link.memberId);
  const newMemberSet = new Set(newMembers);
  const membersChanged =
    originalMembers.length !== newMembers.length || originalMembers.some((member) => !newMemberSet.has(member));

  const changedKeys = [...topLevelKeyChanges, ...timeKeyChanges, ...payloadKeyChanges];

  if (membersChanged) {
    return [...changedKeys, 'memberIds'];
  }

  return changedKeys;
}

/**
 * We only update the one off schedule reminders based on the things that were changed for the parent recurring schedule
 **/
export function getDiffedScheduleReminderValue(
  updatedRecurringReminder: DiffableRecurringScheduleReminder,
  changedKeys: RecurringScheduleDiffedKey[]
) {
  const updatedPayload = JSON.parse(updatedRecurringReminder.payload ?? '{}');

  return changedKeys.reduce<DiffedRecurringScheduleValue>(
    (acc, cur) => {
      switch (cur) {
        case 'startTime':
          acc.startTime = DateTime.fromISO(updatedRecurringReminder.startTime).toISO();
          break;
        case 'endTime':
          acc.endTime = DateTime.fromISO(updatedRecurringReminder.endTime).toISO();
          break;
        case 'notifyEmployee':
          acc.notifyEmployee = updatedRecurringReminder.notifyEmployee;
          break;
        case 'projectId':
          acc.projectId = updatedRecurringReminder.projectId;
          break;
        case 'costCodeId':
          acc.costCodeId = updatedRecurringReminder.costCodeId;
          break;
        case 'equipmentId':
          acc.equipmentId = updatedRecurringReminder.equipmentId;
          break;
        case 'instructions':
          acc.instructions = updatedPayload.instructions;
          break;
        case 'color':
          acc.color = updatedPayload.color;
          break;
        case 'memberIds':
          acc.memberIds = updatedRecurringReminder.links.map((link) => link.memberId);
          break;
        case 'isDraft':
          acc.isDraft = updatedRecurringReminder.isDraft;
          break;
      }

      return acc;
    },
    {
      startTime: undefined,
      endTime: undefined,
      notifyEmployee: undefined,
      projectId: undefined,
      costCodeId: undefined,
      equipmentId: undefined,
      instructions: undefined,
      color: undefined,
      memberIds: undefined,
      isDraft: undefined,
    }
  );
}

export function anySchedulingDetailIsRecurring(recurringCommonList: IScheduleReminderDetailsCommon[]) {
  return recurringCommonList.some(isSchedulingDetailRecurring);
}

export function getColorFromSchedule(schedule: Pick<ScheduleReminder, 'type' | 'payload'>) {
  if (schedule.type === 'break') {
    return defaultSchedulingColors.break;
  }

  const parsedPayload = schedule.payload ? JSON.parse(schedule.payload) : {};
  return parsedPayload.color ?? defaultSchedulingColors.work;
}

export function partitionSchedulingLinksWithUpdatedMemberIds<T extends Pick<ScheduleReminderLink, 'memberId'>>(
  originalLinks: T[],
  updatedMemberIds: string[]
) {
  const setOfUpdatedMembers = new Set(updatedMemberIds);
  const [toKeep, toDelete] = partition(originalLinks, ({ memberId }) => setOfUpdatedMembers.has(memberId));

  const toKeepMemberIdSet = new Set(toKeep.map((link) => link.memberId));

  const toCreate = updatedMemberIds.filter((id) => !toKeepMemberIdSet.has(id));

  return {
    toKeep,
    toDelete,
    toCreateMemberIds: toCreate,
  };
}

export function getThisAndFutureScheduleReminders<T extends Pick<IScheduleReminderDetails, 'startTime'>>(
  targetDate: DateTime,
  scheduleReminders: T[]
): T[] {
  return scheduleReminders.filter((reminder) => {
    const reminderDate = DateTime.fromISO(reminder.startTime);
    return reminderDate.toSeconds() >= targetDate.toSeconds();
  });
}

export function convertScheduleRemindersToPreprocessedScheduleReminderDetails(reminders: ScheduleReminder[]) {
  const withPayloads = reminders.map((reminder) => {
    const payload = reminder.payload ? JSON.parse(reminder.payload) : undefined;

    const coalescedValue: IPreprocessedScheduleReminderDetails = {
      ...reminder,
      isDraft: reminder.isDraft ?? false,
      overrideRecurringScheduleId: reminder.overrideRecurringScheduleId ?? null,
      type: reminder.type !== 'deleted' ? getSchedulingTypeFromString(reminder.type) : 'deleted',
      notifyEmployee: reminder.notifyEmployee ?? false,
    };

    if (reminder.payload) {
      if (payload?.instructions) {
        coalescedValue.instructions = payload.instructions;
      }

      if (payload?.color && reminder.type !== 'break') {
        coalescedValue.color = payload.color;
      }

      return coalescedValue;
    } else {
      return coalescedValue;
    }
  });

  return withPayloads;
}

export function isDateOnlyRecurringScheduleRemaining({
  targetDate,
  startDate,
  recurringDate,
  deletedIsoDates,
}: {
  targetDate: DateTime;
  startDate: DateTime;
  recurringDate: RecurringDate;
  deletedIsoDates: string[];
}) {
  const lastDateOfRecurringSchedule = getLastDateTimeOfRecurringSchedule(recurringDate);
  if (!lastDateOfRecurringSchedule) {
    return false;
  }

  const targetIsoDate = targetDate.toISODate();
  const isTargetDateDeleted = deletedIsoDates.includes(targetIsoDate);

  if (isTargetDateDeleted) {
    return false;
  }

  const generatedDates = convertRecurringDateToDaysInRange(recurringDate, {
    startTime: startDate,
    endTime: lastDateOfRecurringSchedule,
  });

  const set = new Set(generatedDates.map((date) => date.toISODate()));

  deletedIsoDates.forEach((isoDate) => {
    if (set.has(isoDate)) {
      set.delete(isoDate);
    }
  });

  return set.size === 1 && set.has(targetDate.toISODate());
}

/**
 * @param targetDateIsoDate - Target date refers to when the user is doing the action
 */
export function convertTargetDateToFrequencyExpiration(targetDateIsoDate: string) {
  return dateTimeFromISOWithoutZone(targetDateIsoDate).minus({ days: 1 }).endOf('day');
}

export function getDraftIdsFromScheduleReminderDetailsForPublish(details: IScheduleReminderDetailsCommon[]) {
  const drafts = details.filter((selectedEvent) => selectedEvent.isDraft);
  const [recurringEvents, nonRecurringEvents] = partition(drafts, isSchedulingDetailRecurring);

  // Since the user can highlight multiple of the same recurring event we only need to publish one so we `uniq`
  const recurringDraftsToPublish = uniq(recurringEvents.map((reminder) => reminder.recurringOriginId));
  const nonRecurringDraftsToPublish = nonRecurringEvents.map((reminder) => reminder.id);

  return {
    recurringDraftsToPublish,
    nonRecurringDraftsToPublish,
  };
}

export function partitionScheduleReminderDetailsByRecurring(details: IScheduleReminderDetailsCommon[]) {
  const [recurringEvents, nonRecurringEvents] = partition(details, isSchedulingDetailRecurring);
  return [recurringEvents, nonRecurringEvents as IScheduleReminderDetails[]] as const;
}

export function getCastedParsedPayload(payload: string | null): ScheduleReminderJsonPayload {
  return payload ? JSON.parse(payload) : {};
}

export function convertScheduleRecurringReminderUpdateToScheduleReminderUpdate(
  update: Omit<RecurringScheduleReminderUpdateInput, 'id'>
): Omit<ScheduleReminderUpdateInput, 'id'> {
  const parsedPayload = getCastedParsedPayload(update.payload ?? '{}');

  return {
    startTime: update.startTime,
    endTime: update.endTime,
    type: update.type,
    notifyEmployee: update.notifyEmployee,
    payload: createSchedulingPayload({
      instructions: parsedPayload.instructions,
      color: parsedPayload.color,
      projectId: update.projectId,
      costCodeId: update.costCodeId,
      equipmentId: update.equipmentId,
    }),
    isDraft: update.isDraft,
  };
}

export function getDiffedRecurringReminder({
  originalReminder,
  updatedValues,
}: {
  originalReminder: SchedulingRecurringSchedule;
  updatedValues: IRecurringScheduleLinkReminderPayload;
}) {
  const fullUpdatedRecurringReminder = {
    ...updatedValues.recurringReminder,
    links: updatedValues.links,
  };

  const diffedKeys = diffOriginalRecurringReminderWithUpdatedRecurringReminder(
    originalReminder,
    fullUpdatedRecurringReminder
  );

  const diffedScheduleReminderValue = getDiffedScheduleReminderValue(fullUpdatedRecurringReminder, diffedKeys);
  return diffedScheduleReminderValue;
}

export function mergeDiffedRecurringScheduleAndReminderDetails({
  diffedValue,
  reminderDetails,
}: {
  diffedValue: DiffedRecurringScheduleValue;
  reminderDetails: IPreprocessedScheduleReminderDetails;
}) {
  return {
    memberIds: diffedValue.memberIds ?? reminderDetails.links.map((link) => link.memberId),
    startTime: diffedValue.startTime ?? reminderDetails.startTime,
    endTime: diffedValue.endTime ?? reminderDetails.endTime,
    notifyEmployee: diffedValue.notifyEmployee ?? reminderDetails.notifyEmployee,
    payload:
      reminderDetails.type !== 'break'
        ? createSchedulingPayload({
            projectId: diffedValue.projectId === undefined ? reminderDetails.project?.id : diffedValue.projectId,
            costCodeId: diffedValue.costCodeId === undefined ? reminderDetails.costCode?.id : diffedValue.costCodeId,
            equipmentId:
              diffedValue.equipmentId === undefined ? reminderDetails.equipment?.id : diffedValue.equipmentId,
            instructions:
              diffedValue.instructions === undefined ? reminderDetails.instructions : diffedValue.instructions,
            color: diffedValue.color === undefined ? reminderDetails.color : diffedValue.color,
          })
        : null,
    type: reminderDetails.type,
    isDraft: diffedValue.isDraft ?? reminderDetails.isDraft,
  };
}

export function getSchedulingEndTimeWithNightShiftConsideration(startTime: DateTime, endTime: DateTime) {
  if (startTime.toSeconds() > endTime.toSeconds()) {
    return endTime.plus({ days: 1 });
  }

  return endTime;
}

export function convertScheduleRecurringReminderDetailsToCreatePayload(
  recurringScheduleReminder: IScheduleRecurringReminderDetails
): ScheduleCreatePayload & { fetchAfter: boolean } {
  return {
    startTime: recurringScheduleReminder.startTime,
    endTime: recurringScheduleReminder.endTime,
    type: recurringScheduleReminder.type,
    payload:
      recurringScheduleReminder.type === 'work'
        ? getJSONPayloadFromSchedulingReminder(recurringScheduleReminder)
        : null,
    memberIds: recurringScheduleReminder.links.map((link) => link.member.id),
    notifyEmployee: recurringScheduleReminder.notifyEmployee,
    isDraft: false,
    day: DateTime.fromISO(recurringScheduleReminder.startTime),
    fetchAfter: false,
    overrideRecurringScheduleId: recurringScheduleReminder.recurringOriginId,
  };
}

export function hasScheduleReminderDetailsChangedWithFormData(
  scheduleItem: IScheduleReminderDetails,
  formData: ISchedulingFormData | IWorkSchedulingFormData
) {
  if (scheduleItem.notifyEmployee !== formData.notifyEmployee) {
    return true;
  }

  const currentMembers = scheduleItem.links.map((link) => link.memberId);

  if (currentMembers.length !== formData.members.length) {
    return true;
  }

  const members = new Set(currentMembers);

  if (!formData.members.every((member) => members.has(member))) {
    return true;
  }

  // A single schedule reminder does not have an end date
  if (formData.endDate?.value) {
    return true;
  }

  const startDate = DateTime.fromJSDate(formData.startDate.value!);

  const start = formData.startTime;
  const end = formData.endTime;

  const startTimeWithDate = startDate.set({ hour: start.hour, minute: start.minute });
  const endTimeWithDate = startDate.set({ hour: end.hour, minute: end.minute });

  if (scheduleItem.startTime !== startTimeWithDate.toISO()) {
    return true;
  }

  if (scheduleItem.endTime !== endTimeWithDate.toISO()) {
    return true;
  }

  const formDataIsWork = isSchedulingFormDataWork(formData);

  // Checked all break properties
  if (!formDataIsWork) {
    return false;
  }

  if (scheduleItem.color !== formData.color) {
    return true;
  }

  if (scheduleItem.instructions !== formData.instructions) {
    return true;
  }

  if (scheduleItem.project?.id !== formData.project) {
    return true;
  }

  if (scheduleItem.costCode?.id !== formData.costCode) {
    return true;
  }

  if (scheduleItem.equipment?.id !== formData.equipment) {
    return true;
  }

  return false;
}

export function getDashboardNavigationRange(
  encompassingTimeRange: ITimeRange<DateTime>,
  individualTimeRanges: Array<ITimeRange<number>>,
  timeFrame: DashboardTimeFrame
): ITimeRange<DateTime> {
  if (timeFrame === 'pastSeven' || timeFrame === 'pastFourteen') {
    return encompassingTimeRange;
  }

  const lastOfRanges = last(individualTimeRanges);

  if (lastOfRanges) {
    return {
      startTime: DateTime.fromSeconds(lastOfRanges.startTime, { zone: 'utc' }),
      endTime: DateTime.fromSeconds(lastOfRanges.endTime, { zone: 'utc' }),
    };
  }

  return encompassingTimeRange;
}

export type FlattenedScheduleReminderDetails<T extends IScheduleReminderDetailsCommon> = T & {
  linkMemberId: string;
};

export function flattenScheduleReminderLinksIntoScheduleReminderDetails<T extends IScheduleReminderDetailsCommon>(
  reminder: T
): Array<FlattenedScheduleReminderDetails<T>> {
  return reminder.links.map((link) => ({
    ...reminder,
    id: link.scheduleReminderId,
    linkMemberId: link.memberId,
  }));
}

export function isRecurringDateFrequencyExpirationOnlyDifference(
  recurringDate: RecurringDate,
  recurringDate2: RecurringDate
) {
  if (recurringDate.frequency !== recurringDate2.frequency) {
    return false;
  }

  if (
    recurringDate.frequency === 'weekly' &&
    recurringDate2.frequency === 'weekly' &&
    !isEqual(recurringDate.days, recurringDate2.days)
  ) {
    return false;
  }

  if (
    recurringDate.frequency === 'monthly' &&
    recurringDate2.frequency === 'monthly' &&
    recurringDate.dayOfMonth !== recurringDate2.dayOfMonth
  ) {
    return false;
  }

  if (
    recurringDate.frequency === 'yearly' &&
    recurringDate2.frequency === 'yearly' &&
    recurringDate.dayOfMonth !== recurringDate2.dayOfMonth &&
    recurringDate.month !== recurringDate2.month
  ) {
    return false;
  }

  if (recurringDate.frequencyExpiration && recurringDate2.frequencyExpiration) {
    return recurringDate.frequencyExpiration.toISODate() !== recurringDate2.frequencyExpiration.toISODate();
  }

  // At least one is null
  return recurringDate.frequencyExpiration !== recurringDate2.frequencyExpiration;
}

export function shouldSchedulingMemberBeIncludedArchivedConsideration(archivedOn: Optional<string>, startTime: string) {
  // We show schedules if a single member is non archived or if a member is archived after the scheduled start time
  if (!archivedOn) {
    return true;
  }

  return archivedOn > startTime;
}

export function sortScheduleReminderDetailsCommon(reminderDetails: IScheduleReminderDetailsCommon[]) {
  return sortBy(reminderDetails, [
    (reminder) => DateTime.fromISO(reminder.startTime).toSeconds(),
    (reminder) => {
      const memberNames = reminder.links.map((link) => getFullNameFromMember(link.member));
      return memberNames.join(',');
    },
    (reminder) => DateTime.fromISO(reminder.endTime).toSeconds(),
    (reminder) => {
      if (reminder.project) {
        return getFormattedPathFromProject(reminder.project);
      }

      return null;
    },
    (reminder) => {
      if (reminder.costCode) {
        return getCostCodeDisplay(reminder.costCode);
      }

      return null;
    },
    (reminder) => {
      if (reminder.equipment) {
        return getEquipmentDisplay(reminder.equipment);
      }

      return null;
    },
  ]);
}
