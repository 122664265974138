import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ITimeRange from 'types/TimeRange';

export default function useTimeRangePopulator(getter: (time: number) => ITimeRange<DateTime>) {
  return useCallback(
    (count: number, startTime: number) => {
      let currentTime = startTime;

      return sortBy(
        times(count, () => {
          const value = getter(currentTime);
          currentTime = value.startTime.toSeconds() - 1;
          return value;
        }),
        (date) => date.startTime.toSeconds()
      );
    },
    [getter]
  );
}
