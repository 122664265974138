import { useReduxSelector } from 'hooks';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { IStoreSession } from 'store/types';
import { IAccessControlProps } from './AccessControl';
import { IChildrenProps } from 'types/ChildrenProps';

export interface IValidateAuthenticationProps extends Pick<IAccessControlProps, 'authenticate'>, IChildrenProps {
  fallback?: React.ReactElement;
}

export const ValidateAuthentication = ({
  children,
  authenticate,
  fallback = <Navigate to="/logout" />,
}: IValidateAuthenticationProps) => {
  const session: IStoreSession = useReduxSelector((state) => state.session);
  const isAuthorized = !authenticate || session.isAuthenticated;

  return isAuthorized ? <>{children}</> : fallback;
};
