import {
  Button,
  Form,
  IFormRender,
  IFormValidation,
  Label,
  TextareaFormField,
  TextFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { CostCodePickerFormField, EquipmentPickerFormField, ProjectPickerFormField } from 'components';
import EmployeeMultiPickerFormField from 'components/domain/member/EmployeeMultiPickerFormField/EmployeeMultiPickerFormField';
import TimeActionsBreakFormField from 'components/domain/time-entry/time-actions-form/section/TimeActionsBreakFormField/TimeActionsBreakFormField';
import FeatureTimeFormField from 'components/domain/time/FeatureTimeFormField/FeatureTimeFormField';
import { useToastOpen } from 'contexts/ToastContext';
import { useOrganization } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import ClockAction from 'types/enum/ClockAction';
import { timeEntryBreakUtils } from 'utils';
import { nightShiftAdjustment } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import { IQuickTimeSheetFormData } from '../QuickTimeSheet/QuickTimeSheet';

export interface IQuickTimeTemplateFormProps {
  formData: IQuickTimeTemplateFormData;
  onSubmit: (formData: IQuickTimeTemplateFormData) => void;
  templateNameValidation?: Array<IFormValidation<string | undefined, any>>;
  submissionText: string;
  className?: ClassName;
}

export interface IQuickTimeTemplateFormData extends Partial<IQuickTimeSheetFormData> {
  name: string;
  startTime: DateTime;
  endTime: DateTime;
}

export default function QuickTimeTemplateForm({
  templateNameValidation,
  formData,
  onSubmit,
  submissionText,
  className,
}: IQuickTimeTemplateFormProps) {
  const [breakError, setBreakError] = useState<boolean>(false);
  const organization = useOrganization();
  const openToast = useToastOpen();

  const renderFormFields = useCallback(
    (form: IFormRender<IQuickTimeTemplateFormData>) => {
      return (
        <>
          <Label>{t('Template Name')}</Label>
          <TextFormField form={form} name="name" validations={templateNameValidation} />
          <div>
            <Label>{t('Employees')}</Label>
            <EmployeeMultiPickerFormField name="members" form={form} />
          </div>
          <div>
            <Label>{t('Start Time')}</Label>
            <FeatureTimeFormField
              name="startTime"
              form={form}
              meridiemEnabled={true}
              errorOnEmpty={false}
              validations={[{ validation: v_require }]}
              clockAction={ClockAction.CLOCK_IN}
              onChange={(value) => {
                if (form.state.data.endTime && value) {
                  const dateRange = nightShiftAdjustment({
                    startTime: value,
                    endTime: form.state.data.endTime,
                  });
                  const breakRanges = form.state.data.breaks?.map(({ timeRange }) => timeRange) ?? [];
                  const isBreakError = timeEntryBreakUtils.isOutsideRange(dateRange, breakRanges);
                  setBreakError(isBreakError);
                }
              }}
            />
          </div>
          <div>
            <Label>{t('End Time')}</Label>
            <FeatureTimeFormField
              name="endTime"
              form={form}
              meridiemEnabled={true}
              validations={[{ validation: v_require }]}
              errorOnEmpty={false}
              onChange={(value) => {
                if (form.state.data.startTime && value) {
                  const dateRange = nightShiftAdjustment({
                    startTime: form.state.data.startTime,
                    endTime: value,
                  });
                  const breakRanges = form.state.data.breaks?.map(({ timeRange }) => timeRange) ?? [];
                  const isBreakError = timeEntryBreakUtils.isOutsideRange(dateRange, breakRanges);
                  setBreakError(isBreakError);
                }
              }}
              clockAction={ClockAction.CLOCK_OUT}
            />
          </div>
          <div>
            <TimeActionsBreakFormField
              form={form as any}
              onUpdate={(breaks, _, error) => {
                form.setData('breaks', breaks);
                setBreakError(error);
              }}
              startDate={DateTime.local().startOf('day')}
              endDate={DateTime.local().endOf('day')}
              timeRange={{
                startTime: form.state.data?.startTime,
                endTime: form.state.data?.endTime,
              }}
            />
          </div>
          {organization.trackProject && (
            <div>
              <Label>{t('Project')}</Label>
              <ProjectPickerFormField form={form} name="projectId" />
            </div>
          )}
          {organization.trackCostCode && (
            <div>
              <Label>{t('Cost Code')}</Label>
              <CostCodePickerFormField form={form} name="costCodeId" projectId={form.state.data.projectId} />
            </div>
          )}
          {organization.trackEquipment && (
            <div>
              <Label>{t('Equipment')}</Label>
              <EquipmentPickerFormField name="equipmentId" form={form} />
            </div>
          )}
          <div>
            <Label>{t('Description')}</Label>
            <TextareaFormField name="description" form={form} restrictTo={{ maxLength: 5000 }} />
          </div>
          <Button type="primary" className="form-button" onClick={form.handleSubmit}>
            {submissionText}
          </Button>
        </>
      );
    },
    [
      templateNameValidation,
      organization.trackProject,
      organization.trackCostCode,
      organization.trackEquipment,
      submissionText,
    ]
  );

  function onFormSubmit(data: IQuickTimeTemplateFormData) {
    if (breakError) {
      // If break error don't allow submission. Show toast.
      openToast({ label: t('One or more break is outside of the time entry.'), theme: Theme.DANGER });
      return;
    }

    onSubmit(data);
  }

  const classes = classNames('quick-time-template-form', className);

  return (
    <Form
      onSubmit={onFormSubmit}
      render={renderFormFields}
      data={formData}
      className={classes}
      allowMultipleSubmissions={true}
    />
  );
}
