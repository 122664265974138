import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGustoCompany } from 'components/domain/integration/gusto/types/types';
import { Nullable } from 'types/util/Nullable';

export interface IStoreGusto {
  company: Nullable<IGustoCompany>;
  hasSignedTos: Nullable<boolean>;
}

export const initialState: IStoreGusto = {
  company: null,
  hasSignedTos: null,
};

const slice = createSlice({
  name: 'Gusto',
  initialState,
  reducers: {
    setGustoCompany: (state: IStoreGusto, payload: PayloadAction<Nullable<IGustoCompany>>) => {
      state.company = payload.payload;
      return state;
    },
    setGustoHasSignedTos: (state: IStoreGusto, payload: PayloadAction<boolean>) => {
      state.hasSignedTos = payload.payload;
      return state;
    },
  },
});

export const { setGustoCompany, setGustoHasSignedTos } = slice.actions;
export default slice.reducer;
