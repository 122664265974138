import * as Sentry from '@sentry/react';
import { AccessControl } from 'components/domain/access-control/AccessControl';
import ErrorBoundary from 'components/layout/ErrorBoundary/ErrorBoundary';
import * as React from 'react';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { IPositionBooleanKey, TFlagKey } from 'types';
import { IChildrenProps } from 'types/ChildrenProps';
import { routeConfigs } from './RoutesConfigs';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RouteLoadingFallback = () => <></>;

export interface IRouteSetup {
  Component: React.JSXElementConstructor<any>;
  Layout: React.JSXElementConstructor<any>;
  authenticate: boolean;
  path: string;
  featureFlags?: TFlagKey | TFlagKey[];
  permissions?: IPositionBooleanKey | IPositionBooleanKey[];
}

export interface IFeatureRouteSetup extends IRouteSetup {
  featureFlags: TFlagKey | TFlagKey[]; // non optional variation
}

// the default export
// the main switch containing all the routes
export const AppRoutes: React.FC = () => (
  <SentryRoutes>
    {routeConfigs.map((routeConfig: IRouteSetup) => {
      const { path, authenticate, featureFlags, permissions, Layout, Component } = routeConfig;

      return (
        <Route
          key={path}
          path={path}
          element={
            <RouteLayout Layout={Layout}>
              <AccessControl authenticate={authenticate} featureFlags={featureFlags} permissions={permissions}>
                <Component />
              </AccessControl>
            </RouteLayout>
          }
        />
      );
    })}
  </SentryRoutes>
);

type IRouteLayoutProps = Pick<IRouteSetup, 'Layout'> & IChildrenProps;

const RouteLayout = ({ children, Layout }: IRouteLayoutProps) => (
  <Layout>
    <ErrorBoundary>
      <Suspense fallback={<RouteLoadingFallback />}>{children}</Suspense>
    </ErrorBoundary>
  </Layout>
);
