import { QueryOptions } from '@apollo/client';
import { useApolloPaging } from 'hooks/apollo';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeOff from 'types/TimeOff';
import { TimeOffFilter, TimeOffSort } from '__generated__/graphql';

export type ITimeOffApolloQueryOptions<
  Variables = {
    filter?: TimeOffFilter;
    sort?: TimeOffSort;
    first?: number;
    after?: string | null;
  }
> = QueryOptions<Variables>;

export default function useTimeOffApolloQuery() {
  const { getPage: getApolloPage, getAll: getApolloAll } = useApolloPaging();

  const getPage = useCallback(
    async <T extends ITimeOff & ICursorable, O extends ITimeOffApolloQueryOptions>(
      options: O,
      after?: string | null
    ) => {
      const result = await getApolloPage<T, { timeOffs: T[] }>(options, after);
      return result.data.timeOffs;
    },
    [getApolloPage]
  );

  const getAll = useCallback(
    <T extends ITimeOff & ICursorable, O extends ITimeOffApolloQueryOptions>(options: O) => {
      return getApolloAll<T>('timeOffs', options);
    },
    [getApolloAll]
  );

  return { getPage, getAll };
}
