import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreUniversalMaps {
  locationId?: string;
}

export const initialState: IStoreUniversalMaps = {
  locationId: undefined,
};

const slice = createSlice({
  name: 'UniversalMaps',
  initialState,
  reducers: {
    setUniversalMapsSelectedPin: (state: IStoreUniversalMaps, action: PayloadAction<string | undefined>) => {
      state.locationId = action.payload;
      return state;
    },
    clearUniversalMapsSelectedPin: (state: IStoreUniversalMaps) => {
      state.locationId = undefined;
      return state;
    },
  },
});

export const { setUniversalMapsSelectedPin, clearUniversalMapsSelectedPin } = slice.actions;
export default slice.reducer;
