import filter from 'lodash/filter';
import first from 'lodash/first';
import { DateTime } from 'luxon';
import { SortOrder, usePoliciesQuery } from '__generated__/graphql';
import { type TPolicyKey } from './types';
import { validatePolicyUrl } from './utils';

const getStartOfDay = () => DateTime.local().startOf('day');

export const useEffectivePolicyQuery = (policyType: TPolicyKey, checkDate = getStartOfDay()) => {
  const query = usePoliciesQuery({
    variables: {
      filter: {
        deletedOn: { isNull: true },
        type: { equal: policyType },
      },
      sort: { version: SortOrder.Desc },
    },
  });

  const effectivePolicies = filter(query.data?.policies, (item) => {
    const isValid = validatePolicyUrl(item.contentUrl); // if the contentUrl is not a valid URL, we completely ignore the policy
    const isDateInPast = DateTime.fromISO(item.effectiveDate, { zone: 'local' }) <= checkDate;

    return isValid && isDateInPast;
  });

  const effectivePolicy = first(effectivePolicies);

  return {
    ...query,
    data: effectivePolicy,
  };
};

export const useNextPolicyQuery = (policyType: TPolicyKey, checkDate = getStartOfDay()) => {
  const query = usePoliciesQuery({
    variables: {
      filter: {
        deletedOn: { isNull: true },
        type: { equal: policyType },
      },
      sort: { version: SortOrder.Asc },
    },
  });

  const upcomingPolicies = filter(query.data?.policies, (item) => {
    const isValid = validatePolicyUrl(item.contentUrl); // if the contentUrl is not a valid URL, we completely ignore the policy
    const isDateInFuture = DateTime.fromISO(item.effectiveDate, { zone: 'local' }) > checkDate;

    return isValid && isDateInFuture;
  });

  const nextPolicy = first(upcomingPolicies);

  return {
    ...query,
    data: nextPolicy,
  };
};
