import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { useApolloPaging } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeRange from 'types/TimeRange';
import { getDateTimesBetween, getMonthsBetween } from 'utils/dateUtils';
import { metricDataToTimeGraphData } from 'utils/timeGraphDataUtils';
import {
  memberCostCodeMetricQueryProvider,
  memberEquipmentMetricQueryProvider,
  memberLaborMetricQueryProvider,
  memberProjectMetricQueryProvider,
} from './metrics/MemberMetricQueryProviders';
import useMetricAggregates from './useMetricAggregates';

export interface ITimeGraphData {
  day: number;
  totalHours: number;
  regularHours: number;
  overtimeHours: number;
  doubleTimeHours: number;
}

export default function useMemberGraphAggregates(
  timeRange: ITimeRange<DateTime>,
  memberId?: string | null,
  projectId?: string | null,
  costCodeId?: string | null,
  equipmentId?: string | null,
  memberGroupId?: string | null,
  monthLimitToSwitch: number = 2,
  archivedStatus: ArchivedStatus = 'unarchived'
) {
  const queryProvider = useCallback(() => {
    const memberIds = memberId ? [memberId] : undefined;
    const projectIds = projectId ? [projectId] : undefined;
    const costCodeIds = costCodeId ? [costCodeId] : undefined;
    const equipmentIds = equipmentId ? [equipmentId] : undefined;

    if (projectIds) {
      return memberProjectMetricQueryProvider(
        getAll,
        MemberPermission.MANAGE_TIME_ENTRIES,
        memberIds,
        projectIds,
        memberGroupId ?? undefined,
        archivedStatus,
        true,
        true,
        500
      );
    }

    if (costCodeIds) {
      return memberCostCodeMetricQueryProvider(
        getAll,
        MemberPermission.MANAGE_TIME_ENTRIES,
        memberIds,
        costCodeIds,
        memberGroupId ?? undefined,
        archivedStatus,
        true,
        true,
        500
      );
    }

    if (equipmentIds) {
      return memberEquipmentMetricQueryProvider(
        getAll,
        MemberPermission.MANAGE_TIME_ENTRIES,
        memberIds,
        equipmentIds,
        memberGroupId ?? undefined,
        archivedStatus,
        true,
        true,
        500
      );
    }

    return memberLaborMetricQueryProvider(
      getAll,
      MemberPermission.MANAGE_TIME_ENTRIES,
      memberIds,
      memberGroupId ?? undefined,
      archivedStatus,
      true,
      500
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId, projectId, costCodeId, equipmentId, memberGroupId, archivedStatus]);

  const { getAll } = useApolloPaging();
  const {
    data: metricAggregates,
    error: metricAggregateError,
    execute: refetchMetricAggregates,
  } = useMetricAggregates(timeRange, queryProvider(), monthLimitToSwitch, false, false);

  const [data, setData] = useState<ITimeGraphData[]>([]);

  const getRanges = useCallback(() => {
    const monthRanges = getMonthsBetween(timeRange.startTime, timeRange.endTime);

    return monthRanges.length > monthLimitToSwitch
      ? monthRanges
      : getDateTimesBetween(timeRange.startTime, timeRange.endTime);
  }, [timeRange, monthLimitToSwitch]);

  // trigger sync to get aggregate data
  useEffect(() => {
    refetchMetricAggregates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, memberId, projectId, costCodeId, equipmentId, memberGroupId, archivedStatus]);

  // once we get our aggregate data, then convert it to graph data
  useEffect(() => {
    setData(metricDataToTimeGraphData(metricAggregates ?? [], getRanges()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricAggregates]);

  return { data, error: metricAggregateError, refetchData: refetchMetricAggregates };
}
