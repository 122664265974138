import axios from 'axios';
import { useReduxSelector } from 'hooks';
import { useMemo } from 'react';
import { IStoreAuthentication } from 'store/types';
import { decodeAuthToken } from 'utils/authenticationUtils';
import { compactObject } from 'utils/objectUtils';

const GUSTO_API_VERSION = '2024-04-01';

export default function useGustoAxios() {
  const url = useReduxSelector((state) => state.environment.REACT_APP_GUSTO_URL);
  const token = useReduxSelector((state: { authentication: IStoreAuthentication }) => state.authentication.token);
  const keyAuthorization = decodeAuthToken(token || '').keyAuthorization;

  return useMemo(() => {
    return axios.create({
      baseURL: url,
      headers: compactObject({
        'Content-Type': 'application/json',
        'key-authorization': keyAuthorization,
        'X-Gusto-API-Version': GUSTO_API_VERSION,
      }),
    });
  }, [url, keyAuthorization]);
}

export function useGustoAxiosNoCodeValidate() {
  const url = useReduxSelector((state) => state.environment.REACT_APP_GUSTO_URL);
  const token = useReduxSelector((state: { authentication: IStoreAuthentication }) => state.authentication.token);
  const keyAuthorization = decodeAuthToken(token || '').keyAuthorization;

  return useMemo(() => {
    return axios.create({
      baseURL: url,
      headers: compactObject({
        'Content-Type': 'application/json',
        'key-authorization': keyAuthorization,
        'X-Gusto-API-Version': GUSTO_API_VERSION,
      }),
      validateStatus: () => true,
    });
  }, [url, keyAuthorization]);
}
