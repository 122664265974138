import Axios from 'axios';
import { DateTime } from 'luxon';
import { IReduxState } from 'store/reducers';
import store from 'store/store';

/*
 * code ported from @busy-web/graylog
 */

const USER_AGENTS = [
  {
    regex: /OPR/,
    name: 'Opera',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!OPR)[\s\S])*OPR\/([\s\S]*)$/, '$2'),
  },
  {
    regex: /Edge/,
    name: 'Microsoft Edge',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!Edge)[\s\S])*Edge\/([\s\S]*)$/, '$2'),
  },
  {
    regex: /CriOS/,
    name: 'Chrome',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!CriOS)[\s\S])*CriOS\/([^ ]*)[\s\S]*$/, '$2'),
  },
  {
    regex: /FxiOS/,
    name: 'Firefox',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!FxiOS)[\s\S])*FxiOS\/([^ ]*)[\s\S]*$/, '$2'),
  },
  {
    regex: /Chrome/,
    name: 'Chrome',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!Chrome)[\s\S])*Chrome\/([^ ]*)[\s\S]*$/, '$2'),
  },
  {
    regex: /Safari/,
    name: 'Safari',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!Safari)[\s\S])*Safari\/([\s\S]*)$/, '$2'),
  },
  {
    regex: /MSIE/,
    name: 'Internet Explorer',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!MSIE)[\s\S])*MSIE ([^;]*)[\s\S]*$/, '$2'),
  },
  {
    regex: /Firefox/,
    name: 'Firefox',
    version: (a: { replace: (arg0: RegExp, arg1: string) => void }) =>
      a.replace(/^((?!Firefox)[\s\S])*Firefox\/([\s\S]*)$/, '$2'),
  },
];

const PLATFORMS = [
  { regex: /Android/, name: 'Android' },
  { regex: /iPhone|iPad|iPod/, name: 'iOS' },
  { regex: /Macintosh/, name: 'Mac OS X' },
  { regex: /Windows/, name: 'Windows' },
  { regex: /Linux/, name: 'Linux' },
];

const userAgent = window.navigator.userAgent;

let browser: string;
let version: any;

USER_AGENTS.forEach((br) => {
  if (!browser && br.regex.test(userAgent)) {
    browser = br.name;
    version = br.version(userAgent);
  }
});

version = version || '0';

let platform: string;

PLATFORMS.forEach((pl) => {
  if (!platform && pl.regex.test(userAgent)) {
    platform = pl.name;
  }
});

/*
 * end ported code
 */

const now = Math.floor(DateTime.local().toSeconds());

function sendLog(msg: string, logObject: any) {
  // build default params
  const defaultParams = {
    version: '1.1',
    host: getLogEventHost(),
    timestamp: now,
    short_message: msg,
    full_message: msg,
    level: 6,
    _host: 'busybusy-web',
    _os_version: version,
    _model: platform,
    _platform: browser,
  };

  let params = { ...defaultParams };

  if (logObject) {
    // add the logObject custom params to the default params.
    params = { ...defaultParams, ...logObject };
  }

  Axios.post('https://graylog2.busybusy.io:12310/gelf', params);
}

const LOG_EVENT_HOST_BUSYBUSY = 'app.busybusy.io' as const;
const LOG_EVENT_HOST_ALIGN = 'align-web' as const;
const getLogEventHost = () => (store.getState().iframe.isInIframe ? LOG_EVENT_HOST_ALIGN : LOG_EVENT_HOST_BUSYBUSY);

export default function Graylog(msg: { shortMsg: string; logObject: any }) {
  /*
   * msg object format:
   * {
   *   msg: 'log item name',
   *   logObject: {
   *     _param: 'param',
   *     _param2: 'param2'
   *   }
   * }
   */
  const shortMsg = msg.shortMsg;
  const logObject = msg.logObject;

  const state: IReduxState = store.getState();
  if (state.environment.REACT_APP_ENV === 'production') {
    sendLog(shortMsg, logObject);
  }
}
