enum FilePickerType {
  CSV = '.csv',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  HTML = 'text/html',
  IMAGE = 'image/*',
  PDF = '.pdf',
  TEXT = 'text/plain',
  VIDEO = 'video/*',
}

export default FilePickerType;
