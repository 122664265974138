import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useCurrencyFormatter } from 'hooks';
import { round } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import sumBy from 'lodash/sumBy';
import { Duration } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ITimeRange from 'types/TimeRange';
import { ReportType } from 'utils/aggregateUtils';

export interface IDashboardGraphData {
  day: number;
  value?: number;
  total?: number;
  regular?: number;
  overtime?: number;
  doubletime?: number;
}

export default function useDashboardCardTotal(
  timeRanges: Array<ITimeRange<number>>,
  type: ReportType | null,
  dataKey: 'total' | 'overtime' | 'doubletime' | null,
  data: IDashboardGraphData[],
  timeFrame: DashboardTimeFrame,
  isLoading: boolean
) {
  const formatter = useCurrencyFormatter();
  const { t } = useTranslation();

  return useMemo(() => {
    if (isLoading) {
      return '---';
    }
    let filteredData = data;
    if (timeFrame !== 'pastSeven' && timeFrame !== 'pastFourteen') {
      const lastRange = last(timeRanges);
      if (lastRange) {
        filteredData = data.filter((d) => d.day >= lastRange.startTime && d.day <= lastRange.endTime);
      }
    }

    if (!isEmpty(filteredData)) {
      if (type && dataKey) {
        switch (type) {
          case ReportType.SECONDS:
            // Reason for timesing hours into seconds is better rounding with luxon
            return Duration.fromObject({
              seconds: sumBy(filteredData, (datum) => round((datum[dataKey] ?? 0) * 3600, 0)),
            }).toFormat('h:mm');
          case ReportType.COST:
            return formatter(sumBy(filteredData, dataKey));
        }
      } else {
        return round(
          sumBy(filteredData, (datum) => datum.value ?? 0),
          2
        );
      }
    }

    if (type) {
      switch (type) {
        case ReportType.SECONDS:
          return Duration.fromMillis(0).toFormat('h:mm');
        case ReportType.COST:
          return formatter(0);
      }
    }
    return t('0');
  }, [timeRanges, type, dataKey, data, isLoading]);
}
