import { EffectCallback, useEffect, useRef } from 'react';

export function useAfterFirstRenderEffect(effect: EffectCallback, dependencies: Array<any>) {
  const firstRender = useRef(true);
  useEffect(() => {
    if (!firstRender.current) {
      effect();
    } else {
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
