import FormattedCostCodeName from 'components/domain/cost-code/FormattedCostCodeName/FormattedCostCodeName';
import FormattedEquipmentName from 'components/domain/equipment/FormattedEquipmentName/FormattedEquipmentName';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getTimeFormatWithYearConsideration } from 'utils/dateUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import { isType } from 'utils/typeguard';
import { ActivityReportType } from '../ActivityReportFilter/ActivityReportFilter';
import useDateActivityPartialRange from '../date-range-tables/hooks/useDateActivityPartialRange';
import { IDateRangeByDayActivityTableRowInfo } from './../date-tables/hooks/useDateActivityDayDetails';

export default function useActivityReportDetailColumn(
  reportType: ActivityReportType,
  dateRangeReportType?: TimeRangeType
) {
  const { isPartial: isPartialDateRange } = useDateActivityPartialRange(dateRangeReportType);

  const renderEmployee = (row: any) => {
    if (!isNil(row.member)) {
      return <FormattedEmployeeName firstName={row.member.firstName ?? ''} lastName={row.member.lastName ?? ''} />;
    } else if (row.memberId === remainingDataItemId) {
      return <p className="cell-remaining-data">{t('Additional Time')}</p>;
    }

    return <></>;
  };

  const renderCostCode = (row: any) => {
    if (!isNil(row.costCode)) {
      return <FormattedCostCodeName costCode={row.costCode!} />;
    } else if (row.costCodeId === remainingDataItemId) {
      return <p className="cell-remaining-data">{t('No Cost Code')}</p>;
    }

    return <></>;
  };

  const renderEquipment = (row: any) => {
    if (!isNil(row.equipment)) {
      return <FormattedEquipmentName equipment={row.equipment!} />;
    } else if (row.equipmentId === remainingDataItemId) {
      return <p className="cell-remaining-data">{t('No Equipment')}</p>;
    }

    return <></>;
  };

  const renderProject = (row: any) => {
    if (!isNil(row.project)) {
      return <p>{getFormattedPathFromProject(row.project, true)}</p>;
    } else if (row.projectId === remainingDataItemId) {
      return <p className="cell-remaining-data">{t('No Project')}</p>;
    }

    return <></>;
  };

  const renderDate = (row: any) => {
    if (isType<IDateRangeByDayActivityTableRowInfo>(row, 'day') && !isNil(row.day)) {
      return <>{row.day.toFormat(getTimeFormatWithYearConsideration(row.day, 'LLL d'))}</>;
    }

    if (!isType<IDateRangeByDayActivityTableRowInfo>(row, 'day') && !isNil(row.startDate)) {
      return <>{row.startDate.toFormat(getTimeFormatWithYearConsideration(row.startDate, 'LLL d'))}</>;
    }

    return <></>;
  };

  const renderDateRange = (row: any) => {
    if (!isNil(row.startDate) && !isNil(row.endDate)) {
      const partialItemIndicator = isPartialDateRange(row) ? ' *' : '';

      if (dateRangeReportType === TimeRangeType.MONTHLY) {
        return <>{row.startDate.toFormat('MMMM yyyy') + partialItemIndicator}</>;
      }

      return <>{row.startDate.toFormat('MMM d') + ' - ' + row.endDate.toFormat('MMM d yyyy') + partialItemIndicator}</>;
    }

    return <></>;
  };

  const detailColumn = useMemo(() => {
    const commonProperties = {
      size: '250px',
      cellClassName: 'pl-6 name-column',
      headerClassName: 'pl-6 name-column',
      footerClassName: 'pl-6 name-column',
    };

    switch (reportType) {
      case ActivityReportType.BASIC:
        return [];
      case ActivityReportType.BY_EMPLOYEE:
        return [
          {
            key: 'member',
            title: t('Employee'),
            sort: false,
            cell: renderEmployee,
            ...commonProperties,
          },
        ];
      case ActivityReportType.BY_COST_CODE:
        return [
          {
            key: 'costCode',
            title: t('Cost Code'),
            sort: false,
            textWrap: true,
            cell: renderCostCode,
            ...commonProperties,
          },
        ];
      case ActivityReportType.BY_EQUIPMENT:
        return [
          {
            key: 'equipment',
            title: t('Equipment'),
            sort: false,
            cell: renderEquipment,
            ...commonProperties,
          },
        ];
      case ActivityReportType.BY_PROJECT:
        return [
          {
            key: 'project',
            title: t('Project'),
            sort: false,
            cell: renderProject,
            textWrap: true,
            ...commonProperties,
          },
        ];
      case ActivityReportType.BY_DAY:
        return [
          {
            key: 'dateRange',
            title: t('Date'),
            sort: false,
            cell: renderDate,
            ...commonProperties,
            size: '180px',
          },
        ];
      case ActivityReportType.BY_DATE_RANGE:
        return [
          {
            key: 'dateRange',
            title: t('Date'),
            sort: false,
            cell: renderDateRange,
            ...commonProperties,
          },
        ];
    }
  }, [reportType, dateRangeReportType]);

  function getExportData(rows: any[]) {
    if (reportType === ActivityReportType.BASIC) {
      return rows;
    }

    return rows.flatMap((row) => {
      return !isNil(row.detailRows) ? row.detailRows : [];
    });
  }

  function exportDetailColumn(row: any) {
    switch (reportType) {
      case ActivityReportType.BASIC:
        return {};
      case ActivityReportType.BY_EMPLOYEE: {
        let firstNameValue = '';
        let lastNameValue = '';

        if (!isNil(row.member)) {
          firstNameValue = row.member.firstName;
          lastNameValue = row.member.lastName;
        } else if (row.memberId === remainingDataItemId) {
          firstNameValue = t('Additional Time');
          lastNameValue = '';
        }

        return {
          [t('First Name')]: firstNameValue,
          [t('Last Name')]: lastNameValue,
        };
      }
      case ActivityReportType.BY_COST_CODE: {
        let costCodeValue = '';
        if (!isNil(row.costCode)) {
          costCodeValue = getCostCodeDisplay(row.costCode);
        } else if (row.costCodeId === remainingDataItemId) {
          costCodeValue = t('No Cost Code');
        }

        return {
          [t('Cost Code')]: costCodeValue,
        };
      }
      case ActivityReportType.BY_EQUIPMENT: {
        let equipmentValue = '';
        if (!isNil(row.equipment)) {
          equipmentValue = getEquipmentDisplay(row.equipment);
        } else if (row.equipmentId === remainingDataItemId) {
          equipmentValue = t('No Equipment');
        }

        return {
          [t('Equipment')]: equipmentValue,
        };
      }
      case ActivityReportType.BY_PROJECT: {
        let projectValue = '';
        if (!isNil(row.project)) {
          projectValue = getFormattedPathFromProject(row.project, true);
        } else if (row.projectId === remainingDataItemId) {
          projectValue = t('No Project');
        }

        return {
          [t('Project')]: projectValue,
        };
      }
      case ActivityReportType.BY_DAY: {
        if (isType<IDateRangeByDayActivityTableRowInfo>(row, 'day')) {
          return {
            [t('Date')]: !isNil(row.day) ? row.day.toFormat('MM/dd/yyyy') : '',
          };
        }

        return {
          [t('Date')]: !isNil(row.startDate) ? row.startDate.toFormat('MM/dd/yyyy') : '',
        };
      }
      case ActivityReportType.BY_DATE_RANGE:
        return {
          [t('Start Date')]: !isNil(row.startDate) ? row.startDate.toFormat('MM/dd/yyyy') : '',
          [t('End Date')]: !isNil(row.endDate) ? row.endDate.toFormat('MM/dd/yyyy') : '',
        };
    }
  }

  function isShowingPartialDateRange(data: any[]) {
    if (reportType === ActivityReportType.BY_DATE_RANGE) {
      return data.some((row) => {
        return !isNil(row.startDate) && !isNil(row.endDate) && isPartialDateRange(row);
      });
    }

    return false;
  }

  function isDetailRow(row: any) {
    return isActivityReportDetailRow(reportType, row);
  }

  return {
    detailColumn,
    isShowingPartialDateRange,
    isDetailRow,
    exportDetailColumn,
    getExportData,
  };
}

export function activityReportDetailId(reportType: ActivityReportType, row: any) {
  switch (reportType) {
    case ActivityReportType.BY_EMPLOYEE:
      return row.memberId;
    case ActivityReportType.BY_COST_CODE:
      return row.costCodeId;
    case ActivityReportType.BY_EQUIPMENT:
      return row.equipmentId;
    case ActivityReportType.BY_PROJECT:
      return row.projectId;
    case ActivityReportType.BY_DAY:
    case ActivityReportType.BY_DATE_RANGE:
    case ActivityReportType.BASIC:
      return undefined;
  }
}

export function isActivityRowRestricted(reportType: ActivityReportType, row: any) {
  return reportType === ActivityReportType.BY_EMPLOYEE && row.memberId === remainingDataItemId;
}

export function isActivityReportDetailRow(reportType: ActivityReportType, row: any) {
  switch (reportType) {
    case ActivityReportType.BASIC:
      return false;
    case ActivityReportType.BY_EMPLOYEE:
      return !isNil(row.memberId);
    case ActivityReportType.BY_COST_CODE:
      return !isNil(row.costCodeId);
    case ActivityReportType.BY_EQUIPMENT:
      return !isNil(row.equipmentId);
    case ActivityReportType.BY_PROJECT:
      return !isNil(row.projectId);
    case ActivityReportType.BY_DAY:
      return (!isType<IDateRangeByDayActivityTableRowInfo>(row, 'day') && !isNil(row.startDate)) || !isNil(row.day);
    case ActivityReportType.BY_DATE_RANGE:
      return !isNil(row.startDate);
  }
}

export function timeRangeFromActivityDateItem(item: any): ITimeRange<DateTime> | undefined {
  if (!isNil(item.startDate) && !isNil(item.endDate)) {
    return {
      startTime: item.startDate,
      endTime: item.endDate,
    };
  }

  return undefined;
}
