import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IOrganizationWebUiSettings } from '../types/OrganizationWebUiSettings';
import { useOrganizationSettings } from '../useOrganizationSettings/useOrganizationSettings';
import useOrganizationWebSettingsUpdate from './useOrganizationWebSettingsUpdate';

export default function useOrganizationUiSettingsUpdate() {
  const organizationSettings = useOrganizationSettings();
  const updateWebSettings = useOrganizationWebSettingsUpdate();

  return useCallback(
    <K extends keyof IOrganizationWebUiSettings>(key: K, payload: IOrganizationWebUiSettings[K]) => {
      const cloned = cloneDeep(organizationSettings?.web?.ui);
      return updateWebSettings('ui', { ...cloned, [key]: payload });
    },
    [organizationSettings?.web?.ui, updateWebSettings]
  );
}
