import { t } from 'utils/localize';

export interface IImportError<T> {
  lineNumber: number | null;
  type: T;
}

export type BaseImportError = 'EMPTY_CSV' | 'MISSING_ID' | 'INVALID_ID' | 'DUPLICATE_ID';

export const baseImportErrorLookup: Record<BaseImportError, string> = {
  EMPTY_CSV: t('Unable to parse empty CSV'),
  MISSING_ID: t('Missing DATA KEY'),
  INVALID_ID: t('Invalid DATA KEY'),
  DUPLICATE_ID: t('Duplicate DATA KEY'),
};

Object.freeze(baseImportErrorLookup);
