import { useApolloClient } from '@apollo/client';
import { Field, FieldFooter, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStoreAuthMember } from 'store/types';
import { positionsQuery } from './position-form-field-queries';

interface IPositionFormFieldProps {
  className?: ClassName;
  form: any;
  name: string;
  onChange?: (value: string | '') => void;
  validations?: any;
  autofocus?: boolean;
  showAllPositions?: boolean;
}

export const PositionFormField: FunctionComponent<IPositionFormFieldProps> = (props) => {
  const { form, name, validations, className, onChange, showAllPositions, ...other } = props;

  const authMember = useSelector<any, IStoreAuthMember>((state) => state.authMember);
  const [options, setOptions] = useState<Array<{ value: string; label: string }>>([]);
  const client = useApolloClient();

  // On mount, fetch the company positions and load the results into the field
  useEffect(() => {
    const value = form.state.data[name];

    fetchPositions()
      .then((results: Array<{ id: string; title: string }>) => {
        setOptions(
          results.map((position: { id: string; title: string }) => {
            return { value: position.id, label: position.title };
          })
        );

        if (!form.state.data[name] && results.length) {
          form.handleChange(name, results[results.length - 1].id);
        }
      })
      .catch();
  }, []);

  // Return the variables for the server call
  function getVariables() {
    if (showAllPositions) {
      return {
        filter: {
          deletedOn: { isNull: true },
        },
      };
    } else {
      return {
        filter: {
          deletedOn: { isNull: true },
          level: { greaterThanOrEqual: authMember.position!.level },
        },
      };
    }
  }

  // Fetch company positions from the server
  function fetchPositions(): Promise<Array<{ id: string; title: string }>> {
    return new Promise((resolve, reject) => {
      client
        .query({
          fetchPolicy: 'network-only',
          query: positionsQuery,
          variables: getVariables(),
        })
        .then((result: any) => {
          resolve(result.data.positions);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  // Render
  function renderField({ ...fieldProps }) {
    function handleOnChange(val: string | '') {
      fieldProps.handleChange(val, onChange);
    }

    return (
      <>
        <Select
          error={fieldProps.hasError}
          options={options}
          value={form.state.data[name]}
          onChange={handleOnChange}
          placeholder=""
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames(
    {
      'project-picker-form-field': true,
    },
    className
  );

  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
};

export default PositionFormField;
