export default function useWhichOS() {
  function isMacintosh() {
    return navigator.platform.indexOf('Mac') > -1;
  }

  function isWindows() {
    return navigator.platform.indexOf('Win') > -1;
  }

  function isLinux() {
    return !isMacintosh() && !isWindows();
  }

  return { isWindows, isMacintosh, isLinux };
}
