import { IMemberGlobalTimeEntryTemplateSettings } from 'types/MemberGlobalSettings';

export function getMigratedQuickTimeTemplates(
  templateSetting: IMemberGlobalTimeEntryTemplateSettings
): IMemberGlobalTimeEntryTemplateSettings {
  const entries = Object.entries(templateSetting.values ?? {});

  return entries.reduce(
    (acc, [oldName, template]) => {
      const isCurrentDefault = oldName === templateSetting.default;
      return {
        default: isCurrentDefault ? template.name : acc.default,
        values: {
          ...acc.values,
          [template.name]: template,
        },
      };
    },
    { default: templateSetting.default, values: {} }
  );
}
