import { useActiveMember } from 'hooks/store';
import { useAxios } from 'hooks/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authMemberSetAction } from 'store/store';

export default function useProfileImageUpdate() {
  const axiosClient = useAxios();
  const activeMember = useActiveMember();
  const dispatch = useDispatch();

  function buildFormData(memberId: string, file: File) {
    const params = new FormData();
    params.append('id', memberId);
    params.append('image', file);
    return params;
  }

  return useCallback(
    (memberId: string, file: File | null) => {
      const params = file === null ? { id: memberId, image: null } : buildFormData(memberId, file);
      return new Promise((resolve, reject) => {
        axiosClient
          .patch('member', params)
          .then((result) => {
            const member = result.data.data[0];
            if (memberId === activeMember.id) {
              dispatch(
                authMemberSetAction({
                  ...activeMember,
                  imageThumbUrl: member.image_thumb_url,
                  imageUrl: member.image_url,
                })
              );
            }

            resolve(member);
          })
          .catch(reject);
      });
    },
    [activeMember, axiosClient, dispatch]
  );
}
