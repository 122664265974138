import useFirstTimeEntry, { IFirstTimeEntryArgs } from 'hooks/models/time-entry/useFirstTimeEntry';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';

function useAllTime() {
  const getFirstTimeEntry = useFirstTimeEntry();
  const currentTime = DateTime.local().setZone('utc', { keepLocalTime: true });

  async function getAllTime(args: IFirstTimeEntryArgs): Promise<ITimeRange<DateTime>> {
    const timeEntry = await getFirstTimeEntry(args);

    if (!isNil(timeEntry)) {
      return {
        startTime: dateTimeFromISOWithoutZone(timeEntry.startTime).startOf('day'),
        endTime: currentTime.endOf('day').set({ millisecond: 0 }),
      };
    } else {
      return {
        startTime: currentTime.startOf('day'),
        endTime: currentTime.endOf('day').set({ millisecond: 0 }),
      };
    }
  }

  return getAllTime;
}

export default useAllTime;
