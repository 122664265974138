import { Theme } from '@busybusy/webapp-react-ui';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelContext } from 'contexts/ChannelContext/useChannelContext';
import { useToastOpen } from 'contexts/ToastContext';
import { isEmpty } from 'lodash';
import Papa from 'papaparse';
import { useCallback, useEffect } from 'react';
import { downloadText } from 'utils/fileUtils';
import { t } from 'utils/localize';

export function useExportChannelListener(
  getExportData: () => object[] | undefined,
  loadedAll: boolean,
  fileName: string,
  loadAll?: () => Promise<void>
) {
  const channel = useChannelContext();
  const errorToast = useToastOpen();

  const onExport = useCallback(async () => {
    if (!loadedAll && loadAll) {
      await loadAll();
    }

    const data = getExportData();
    if (data && !isEmpty(data)) {
      const csv = Papa.unparse(data);
      downloadText(csv, `${fileName}.csv`);
    } else {
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExportData, loadAll, loadedAll, fileName]);

  useEffect(() => {
    channel.registerListener(ChannelIds.Export, onExport);

    return () => {
      channel.unregisterListener(ChannelIds.Export);
    };
  }, [channel, onExport]);
}
