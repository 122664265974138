enum BreakActionType {
  BREAK_START = 10,
  BREAK_START_LOCATION = 11,
  BREAK_END = 20,
  BREAK_END_LOCATION = 21,
  SYSTEM_BREAK_END = 25,
  BREAK_ADD_MANUAL = 50,
  BREAK_EDIT_MANUAL = 60,
  BREAK_CONFLICT_RESOLUTION = 80,
  BREAK_DELETE = 90,
  BREAK_CONFLICT_RESOLUTION_DELETE = 100,
}

export default BreakActionType;
