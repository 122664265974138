import { Icon, ITableColumn, Label, Table, Theme, Toast } from '@busybusy/webapp-react-ui';
import { LaborMetricsInterval } from '__generated__/graphql';
import { ClockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmptyState } from 'components';
import ContainerWithSpinner from 'components/foundation/ContainerWithSpinner/ContainerWithSpinner';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import useDateActivityPartialRangeDetection from 'containers/activity-reports/date-range-tables/hooks/useDateActivityPartialRangeDetection';
import { IDateActivityTableRowInfo } from 'containers/activity-reports/date-tables/hooks/useDateActivity';
import { IDateRangeByDayActivityTableRowInfo } from 'containers/activity-reports/date-tables/hooks/useDateActivityDayDetails';
import useDateActivityDetails from 'containers/activity-reports/date-tables/hooks/useDateActivityDetails';
import useActivityReportDetailColumn, {
  activityReportDetailId,
} from 'containers/activity-reports/hooks/useActivityReportDetailColumn';
import useActivityReportDetailTable from 'containers/activity-reports/hooks/useActivityReportDetailTable';
import useActivityReportExport from 'containers/activity-reports/hooks/useActivityReportExport';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelListener } from 'contexts/ChannelContext/hooks/useChannelListener';
import { useExportChannelListener } from 'contexts/ChannelContext/hooks/useExportChannelListener';
import { useOpenable } from 'hooks';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { isType } from 'utils/typeguard';
import './DateRangeActivityReportDetailsTable.scss';
import useBrandTitle from 'hooks/meta/useBrandTitle';

export interface IByDateRangeActivityReportDetailsProps {
  className?: ClassName;
  filterId: string;
  filterIdWithDescendants?: boolean;
  filterType: ActivityReportType;
  secondaryReportType: ActivityReportType;
  timeRange: ITimeRange<DateTime>;
  dateRangeReportType: TimeRangeType;
  onRowSelected: (startDate: DateTime, endDate: DateTime, timeRange: TimeRangeType, secondaryId?: string) => void;
  scroller: HTMLElement | null;
}

const ByDateRangeActivityReportDetailsTable = (props: IByDateRangeActivityReportDetailsProps) => {
  const {
    className,
    filterId,
    filterIdWithDescendants,
    filterType,
    secondaryReportType,
    timeRange,
    dateRangeReportType,
    onRowSelected,
    scroller,
  } = props;
  const classes = classNames('date-range-activity-report-details', className);

  const metricsInterval: LaborMetricsInterval = useMemo(() => {
    switch (dateRangeReportType) {
      case TimeRangeType.MONTHLY:
        return LaborMetricsInterval.Month;
      case TimeRangeType.PAY_PERIOD:
        return LaborMetricsInterval.PayPeriod;
      default:
        return LaborMetricsInterval.OvertimePeriod; // default to week
    }
  }, [dateRangeReportType]);

  const { sortedData, onSort, sortedBy, sortDirection, sortIsDirty, isLoading } = useDateActivityDetails(
    filterId,
    filterType,
    secondaryReportType,
    timeRange,
    metricsInterval,
    filterIdWithDescendants
  );

  const restrictedAccessToast = useOpenable();

  const { aggregateColumns, tableMinWidth } = useActivityReportDetailTable(
    sortedData,
    secondaryReportType,
    ActivityReportType.BY_DATE_RANGE
  );
  const { detailColumn, isDetailRow, exportDetailColumn, getExportData } =
    useActivityReportDetailColumn(secondaryReportType);

  const { partialItems: partialDateItems } = useDateActivityPartialRangeDetection(sortedData, dateRangeReportType);

  const flattenedData = useMemo(() => flattenData(sortedData), [sortedData]);

  const partialMessage = useMemo<string | null>(() => {
    if (partialDateItems.length > 0) {
      switch (dateRangeReportType) {
        case TimeRangeType.MONTHLY:
          return t('* Indicates a partial month');
        case TimeRangeType.PAY_PERIOD:
          return t('* Indicates a partial pay period');
        default:
          return t('* Indicates a partial week');
      }
    }

    return null;
  }, [partialDateItems, dateRangeReportType]);

  const [lazyLoadingEnabled, setLazyLoadingEnabled] = useState(true);
  useChannelListener(ChannelIds.LazyLoadUpdate, () => setLazyLoadingEnabled(false));

  const getActivityExportData = useActivityReportExport();

  const brand = useBrandTitle();

  useExportChannelListener(
    () => exportReport(),
    true,
    `${brand}-date-range-activity-report-${dateUtils.isoTimeStampLocal()}`
  );

  function exportReport() {
    const exportData = getExportData(sortedData);
    return getActivityExportData(exportData, (row) => {
      return {
        [t('Start Date')]: row.startDate.toFormat('MM/dd/yyyy'),
        [t('End Date')]: row.endDate.toFormat('MM/dd/yyyy'),
        ...exportDetailColumn(row),
      };
    });
  }

  const renderDate = (row: IDateActivityTableRowInfo) => {
    if (secondaryReportType !== ActivityReportType.BASIC && isDetailRow(row)) {
      return null;
    } else if (row.id === remainingDataItemId) {
      return <>{t('Additional Time')}</>;
    }

    return <>{getFormattedDate(row)}</>;
  };

  const dateColumnName = useMemo(() => {
    switch (dateRangeReportType) {
      case TimeRangeType.MONTHLY:
        return t('Month');
      case TimeRangeType.PAY_PERIOD:
        return t('Pay Period');
      default:
        return t('Week');
    }
  }, [dateRangeReportType]);

  function getFormattedDate(row: IDateActivityTableRowInfo): string {
    let partialItemIndicator = '';

    if (partialDateItems.some((item) => item.id === row.id)) {
      partialItemIndicator = ' *';
    }

    if (dateRangeReportType === TimeRangeType.MONTHLY) {
      return row.startDate.toFormat('MMMM yyyy') + partialItemIndicator;
    }

    return row.startDate.toFormat('MMM d') + ' - ' + row.endDate.toFormat('MMM d yyyy') + partialItemIndicator;
  }

  function getColumns(): Array<ITableColumn<IDateActivityTableRowInfo>> {
    return [
      {
        key: 'startDate',
        title: dateColumnName,
        sort: true,
        cell: renderDate,
        size: '300px',
        footer: renderTotalColumnFooter,
        cellClassName: 'pl-6  name-column',
        headerClassName: 'pl-6  name-column',
        footerClassName: 'pl-6  name-column',
      },
      ...detailColumn,
      ...aggregateColumns,
    ];
  }

  const renderTotalColumnFooter = () => <Label className="cell-title">{t('Totals')}</Label>;

  function rowClickAction(rowItem: IDateActivityTableRowInfo) {
    if (isType<IDateRangeByDayActivityTableRowInfo>(rowItem, 'day') && !isNil(rowItem.day)) {
      onRowSelected(rowItem.day, rowItem.day, TimeRangeType.DAILY);
    } else if (rowItem.id !== remainingDataItemId) {
      const secondaryId = activityReportDetailId(secondaryReportType, rowItem);
      onRowSelected(rowItem.startDate, rowItem.endDate, dateRangeReportType, secondaryId);
    } else {
      restrictedAccessToast.open();
    }
  }

  function renderEmptyState() {
    return <EmptyState graphic={<Icon svg={ClockIcon} size={180} />} title={t('No Activity')} />;
  }

  function getRowClassName(rowItem: IDateActivityTableRowInfo) {
    const totalRow =
      secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
    return isDetailRow(rowItem) ? 'detailRowBackground' : totalRow;
  }

  const tableFooterClassName = useMemo(() => {
    return secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
  }, [secondaryReportType]);

  function flattenData(data: IDateActivityTableRowInfo[]): IDateActivityTableRowInfo[] {
    return data.flatMap((item) => {
      if (item.detailRows && !isEmpty(item.detailRows)) {
        return [item, ...item.detailRows];
      } else {
        return [item];
      }
    });
  }

  return (
    <ContainerWithSpinner className={classes} isLoading={isLoading}>
      {partialMessage && <div className="ml-6 mb-2">{partialMessage}</div>}
      <Table
        className={'date-activity-report-details-table'}
        cols={getColumns()}
        isLoading={isLoading}
        data={flattenedData}
        sortDir={sortDirection}
        sortBy={sortedBy}
        strokeCols={true}
        onSort={onSort}
        sortIsDirty={sortIsDirty}
        lazyLoad={lazyLoadingEnabled}
        lazyScrollSectionSize={50}
        lazyScrollTrigger={scroller ?? undefined}
        header="standard"
        footer="standard"
        minWidth={`${tableMinWidth}px`}
        scroller={scroller ?? undefined}
        onRowClick={rowClickAction}
        emptyTemplate={renderEmptyState()}
        getCustomRowClassName={getRowClassName}
        footerClassName={tableFooterClassName}
      />

      <Toast
        isOpen={restrictedAccessToast.isOpen}
        theme={Theme.DANGER}
        onClose={restrictedAccessToast.close}
        timeout={3000}
      >
        {t('Access restricted')}
      </Toast>
    </ContainerWithSpinner>
  );
};

export default ByDateRangeActivityReportDetailsTable;
