import { Align, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import { PenIcon } from 'assets/icons';
import classNames from 'classnames';
import { Spinner } from 'components';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardContentRibbon from 'components/foundation/dashboard/DashboardContentRibbon/DashboardContentRibbon';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import DashboardSingleRibbon from 'components/foundation/dashboard/DashboardSingleRibbon/DashboardSingleRibbon';
import {
  DailySignOffIssueStatus,
  DailySignOffIssueType,
  DailySignOffReportStatus,
} from 'containers/daily-sign-off/DailySignOffFilter/DailySignOffReportFilter';
import useDailySignatureAlertsStatus from 'containers/dashboard/hooks/useDailySignatureAlertsStatus';
import { useDashboardTimeRange } from 'containers/dashboard/hooks/useDashboardTimeRange';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { useActiveMember, useDailySignOffNavigation, useDailySignatureSettingNavigation, useOrganization } from 'hooks';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import { t } from 'i18next';
import { sortBy } from 'lodash';
import * as React from 'react';
import { ClassName } from 'types/ClassName';
import TimeRangeType from 'types/TimeRangeType';
import { useFeatureFlags } from 'utils/features';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import DashboardProUpgradeCard from '../DashboardProUpgradeCard/DashboardProUpgradeCard';
import './DailySignatureAlertsCard.scss';

export interface IDailySignatureAlertsCardProps {
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
}

enum OptionsKey {
  INJURY = 'injury',
  INACCURATE_TIME = 'inaccurate-time',
  BREAK_ISSUES = 'break-issues',
  CUSTOM_FLAGS = 'custom-flags',
  MISSING_SIGN_OFFS = 'missing-sign-offs',
}

const DailySignatureAlertsCard = (props: IDailySignatureAlertsCardProps) => {
  const { className, theme, setting, onHide } = props;

  const isPro = useFeatureFlags('PRO');
  const organization = useOrganization();
  const timeRange = useDashboardTimeRange(setting.options?.time ?? 'pastSeven');
  const signatureStatus = useDailySignatureAlertsStatus(timeRange, {
    hideProcessedInjuries: setting.options!.hideProcessedInjuries,
    hideResolvedInaccuracies: setting.options!.hideResolvedInaccurateTime,
    hideResolvedBreaks: setting.options!.hideResolvedBreakIssues,
    hideResolvedCustomQuestions: setting.options!.hideResolvedCustomQuestions,
  });
  const dailySignatureSettingNavigation = useDailySignatureSettingNavigation();
  const dailySignOffNavigation = useDailySignOffNavigation();
  const activeMember = useActiveMember();
  const isCustomSignOffEnabled = useCustomSignOffEnabled();

  function isInjuryEnabled() {
    const injuryKey = 'injury';
    if (setting.options && !setting.options![injuryKey]) {
      return false;
    }
    return organization.safetySignature ?? false;
  }

  function isTimeAccuracyEnabled() {
    const inaccurateTimeKey = 'inaccurateTime';
    if (setting.options && !setting.options![inaccurateTimeKey]) {
      return false;
    }
    return organization.timeAccuracy ?? false;
  }

  function isBreakPolicyEnabled() {
    const breakHoursKey = 'breakHours';
    if (setting.options && !setting.options![breakHoursKey]) {
      return false;
    }
    return organization.breakPolicy ?? false;
  }

  function isCustomQuestionsEnabled() {
    const customQuestionsKey = 'customQuestions';
    if (setting.options && !setting.options![customQuestionsKey]) {
      return false;
    }
    return organization.customSignOff ?? false;
  }

  function isMissingSignOffsEnabled() {
    const missingSignOffsKey = 'missingSignOffs';
    if (setting.options && !setting.options![missingSignOffsKey]) {
      return false;
    }
    return (
      (organization.customSignOff ||
        organization.breakPolicy ||
        organization.timeAccuracy ||
        organization.safetySignature) ??
      false
    );
  }

  const enabledOptions = [
    {
      enabled: isInjuryEnabled(),
      value: signatureStatus.aggregatedInfo?.injuryCount ?? 0,
      title: t('Injuries'),
      key: OptionsKey.INJURY,
      hideResolved: setting.options!.hideProcessedInjuries,
      position: setting.options!.injuriesPosition,
    },
    {
      enabled: isTimeAccuracyEnabled(),
      value: signatureStatus.aggregatedInfo?.inaccurateTimeCount ?? 0,
      title: t('Inaccurate Time'),
      key: OptionsKey.INACCURATE_TIME,
      hideResolved: setting.options!.hideResolvedInaccurateTime,
      position: setting.options!.inaccurateTimePosition,
    },
    {
      enabled: isBreakPolicyEnabled(),
      value: signatureStatus.aggregatedInfo?.breakPolicyCount ?? 0,
      title: t('Break Issues'),
      key: OptionsKey.BREAK_ISSUES,
      hideResolved: setting.options!.hideResolvedBreakIssues,
      position: setting.options!.breakIssuesPosition,
    },
    {
      enabled: isCustomQuestionsEnabled(),
      value: signatureStatus.aggregatedInfo?.customFlaggedCount ?? 0,
      title: t('Custom Flags'),
      key: OptionsKey.CUSTOM_FLAGS,
      hideResolved: setting.options!.hideResolvedCustomQuestions,
      position: setting.options!.customQuestionsPosition,
    },
    {
      enabled: isMissingSignOffsEnabled(),
      value: signatureStatus.aggregatedInfo?.missedSignOffs ?? 0,
      title: t('Missing Sign-Offs'),
      key: OptionsKey.MISSING_SIGN_OFFS,
      hideResolved: false,
      position: setting.options!.missingSignOffsPosition,
    },
  ].filter((item) => item.enabled);

  const subtitle = getDashboardTimeFrameTitle('', setting.options?.time ?? 'payPeriod') ?? '';

  const classes = classNames('daily-signature-alerts-card', className, { 'is-loading': signatureStatus.isLoading });

  function onCardSelection(key: OptionsKey) {
    const lookup: Record<
      OptionsKey,
      {
        filter_type: DailySignOffIssueType | undefined;
        filter_status: DailySignOffIssueStatus | undefined;
        signature_status: DailySignOffReportStatus | undefined;
      }
    > = {
      [OptionsKey.INJURY]: {
        filter_type: DailySignOffIssueType.INJURED,
        filter_status: setting.options!.hideProcessedInjuries ? DailySignOffIssueStatus.OPEN : undefined,
        signature_status: undefined,
      },
      [OptionsKey.INACCURATE_TIME]: {
        filter_type: DailySignOffIssueType.TIME_CORRECT,
        filter_status: setting.options!.hideResolvedInaccurateTime ? DailySignOffIssueStatus.OPEN : undefined,
        signature_status: undefined,
      },
      [OptionsKey.BREAK_ISSUES]: {
        filter_type: DailySignOffIssueType.BREAK_COMPLIANCE,
        filter_status: setting.options!.hideResolvedBreakIssues ? DailySignOffIssueStatus.OPEN : undefined,
        signature_status: undefined,
      },
      [OptionsKey.CUSTOM_FLAGS]: {
        filter_type: DailySignOffIssueType.OTHER_FLAGS,
        filter_status: setting.options!.hideResolvedCustomQuestions ? DailySignOffIssueStatus.OPEN : undefined,
        signature_status: undefined,
      },
      [OptionsKey.MISSING_SIGN_OFFS]: {
        filter_type: undefined,
        filter_status: undefined,
        signature_status: DailySignOffReportStatus.NOT_SIGNED,
      },
    };

    dailySignOffNavigation({
      ...lookup[key],
      time_range_type: setting.options?.time === 'payPeriod' ? TimeRangeType.PAY_PERIOD : TimeRangeType.DAILY,
      start_date: setting.options?.time === 'payPeriod' ? timeRange.startTime.toFormat('yyyy-MM-dd') : undefined,
      end_date: setting.options?.time === 'payPeriod' ? timeRange.endTime.toFormat('yyyy-MM-dd') : undefined,
    });
  }

  if (signatureStatus.isLoading) {
    return <EmptyLoadingCard isLoading={signatureStatus.isLoading} />;
  }
  return (
    <>
      {isPro ? (
        enabledOptions.length > 0 ? (
          <div className={classes}>
            {signatureStatus.error !== null ? (
              <DashboardErrorCard theme={theme} onClick={() => signatureStatus.setRefetch(!signatureStatus.refetch)} />
            ) : (
              sortBy(enabledOptions, 'position').map((option, index) => {
                const filteredText = option.key === OptionsKey.INJURY ? t('Unprocessed - ') : t('Unresolved - ');
                const onClick = () => onCardSelection(option.key);
                if (enabledOptions.length === 1) {
                  return (
                    <DashboardSingleRibbon key={option.title} theme={Theme.DARK} onClick={onClick}>
                      {signatureStatus.isLoading ? (
                        <Row justify={Justify.CENTER} align={Align.CENTER}>
                          <Spinner size="md" />
                        </Row>
                      ) : (
                        <>
                          <h1 className={classNames('mb-5', 'single-ribbon-value')}>{option.value}</h1>
                          <div className={'single-ribbon-title'}>{option.title}</div>
                          <div className="single-ribbon-subtitle">
                            {option.hideResolved ? filteredText + subtitle : subtitle}
                          </div>
                        </>
                      )}
                    </DashboardSingleRibbon>
                  );
                } else {
                  return (
                    <DashboardContentRibbon
                      className={classNames({
                        'mt-6': index !== 0,
                        'py-2': enabledOptions.length === 5,
                        'py-4': enabledOptions.length === 4,
                        'py-6': enabledOptions.length === 3,
                        'py-8': enabledOptions.length === 2,
                      })}
                      key={option.title}
                      onClick={onClick}
                      title={option.title}
                      subtitle={option.hideResolved ? filteredText + subtitle : subtitle}
                      value={option.value.toString()}
                      theme={theme}
                      isLoading={signatureStatus.isLoading}
                    />
                  );
                }
              })
            )}
          </div>
        ) : activeMember.position?.manageCompanySettings && !isCustomSignOffEnabled ? (
          <DashboardEnableCard
            className={`${signatureStatus.isLoading ? 'is-loading' : ''}`}
            title={setting.title}
            svg={PenIcon}
            buttonLabel={t('Enable Daily Sign-Off')}
            onClick={dailySignatureSettingNavigation}
            theme={theme}
            onHide={onHide}
          />
        ) : (
          <EmptyLoadingCard isLoading={signatureStatus.isLoading} />
        )
      ) : (
        <DashboardProUpgradeCard
          className={`${signatureStatus.isLoading ? 'is-loading' : ''}`}
          title={setting.title}
          svg={PenIcon}
          theme={theme}
          onHide={onHide}
        />
      )}
    </>
  );
};

const EmptyLoadingCard: React.FC<{ isLoading: boolean }> = ({ isLoading }) =>
  isLoading ? <div className="is-loading" /> : null;

export default DailySignatureAlertsCard;
