import { castArray } from 'lodash';
import * as React from 'react';
import { lazy } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { type IAccessControlProps } from './AccessControl';

const NotAllowed = lazy(() => import('containers/error/NotAllowed'));

export interface IValidateFeatureFlagsProps extends Pick<IAccessControlProps, 'featureFlags'>, IChildrenProps {
  fallback?: React.ReactElement;
}

export const ValidateFeatureFlags = ({
  children,
  featureFlags,
  fallback = <NotAllowed message={t(`Feature is not available`)} />,
}: IValidateFeatureFlagsProps) => {
  const hookArgs = castArray(featureFlags);
  const isFeatureEnabled = useFeatureFlags(...hookArgs);

  return isFeatureEnabled ? <>{children}</> : fallback;
};
