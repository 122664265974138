import { useQueryParams } from 'hooks';
import MainHeader, { IMainHeaderProps } from '../MainHeader/MainHeader';
import { useFeatureFlags } from 'utils/features';

export type ILegacyMainHeaderProps = Omit<IMainHeaderProps, 'onClick'>;

export default function LegacyMainHeader({ ...mainHeaderProps }: ILegacyMainHeaderProps) {
  const { getParam } = useQueryParams();
  const legacyParam = getParam('exitToLegacy');
  const reactMainApp = useFeatureFlags('REACT_MAIN_APP');

  return (
    <MainHeader
      {...mainHeaderProps}
      onClick={legacyParam === 'true' || !reactMainApp ? 'exitToLegacy' : legacyParam === 'false' ? 'back' : undefined}
    />
  );
}
