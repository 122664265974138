import { useMutation } from '@apollo/client';
import { MEMBER_LOCK_CREATE_QUERY, MEMBER_LOCK_UPDATE_MUTATION } from 'apollo/mutations/member-lock-mutations';
import { MEMBER_LOCKS_QUERY } from 'apollo/queries/member-lock-queries';
import { useApolloPaging } from 'hooks';
import { DateTime } from 'luxon';
import ICursorable from 'types/Cursorable';
import IMemberLock, { IMemberLockCreateInput, IMemberLockUpdateInput } from 'types/MemberLock';
import { dateTimeFromUtcISO, isoTimeStampUtc } from 'utils/dateUtils';
import { inhibitsActions } from 'utils/memberLockUtils';
import { uuid } from 'utils/uuidUtils';

export default function useMemberLock() {
  const [createMemberLockMutation] = useMutation<{ createMemberLock: IMemberLock }>(MEMBER_LOCK_CREATE_QUERY);
  const [updateMemberLockMutation] = useMutation<{ updateMemberLock: IMemberLock }>(MEMBER_LOCK_UPDATE_MUTATION);
  const { getAll } = useApolloPaging();

  async function createLock(date: DateTime, memberId: string) {
    const item: IMemberLockCreateInput = {
      id: uuid(),
      memberId,
      effectiveDate: date.endOf('day').toISO(),
      createdOn: isoTimeStampUtc(),
    };

    const result = await createMemberLockMutation({ variables: { lock: item } });
    const newLock = result.data?.createMemberLock;
    return newLock;
  }

  async function editLock(lock: Pick<IMemberLock, 'id'>, date: DateTime) {
    const item: IMemberLockUpdateInput = {
      id: lock.id,
      effectiveDate: date.endOf('day').toISO(),
    };

    const result = await updateMemberLockMutation({ variables: { lock: item } });
    const newLock = result.data?.updateMemberLock;
    return newLock;
  }

  async function deleteLock(lock: Pick<IMemberLock, 'id'>) {
    const item: IMemberLockUpdateInput = {
      id: lock.id,
      effectiveDate: null,
    };

    const result = await updateMemberLockMutation({ variables: { lock: item } });
    const newLock = result.data?.updateMemberLock;
    return newLock;
  }

  async function inhibitsActionsForMembers(memberIds: string[], date: DateTime) {
    const locks = await getAll<IMemberLock & ICursorable>('memberLocks', {
      query: MEMBER_LOCKS_QUERY,
      variables: {
        first: 500,
        filter: {
          memberId: { contains: memberIds },
        },
      },
      fetchPolicy: 'network-only',
    });

    return locks.some((l) => {
      const isInhibited = inhibitsActions(dateTimeFromUtcISO(l.effectiveDate!), date);
      return isInhibited;
    });
  }

  return { createLock, editLock, deleteLock, inhibitsActionsForMembers };
}
