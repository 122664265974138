import {
  Align,
  Button,
  Col,
  Dialog,
  DialogHeader,
  Divider,
  Icon,
  Justify,
  Menu,
  Position,
  Row,
  Size,
  Tooltip,
} from '@busybusy/webapp-react-ui';
import { ArrowBackIcon, ExpandNavIcon, HiddenIcon, SearchMagnifyingGlass } from 'assets/icons';
import { IconButton } from 'components';
import AccountPreferencesDialog from 'components/domain/account/AccountPreferencesDialog/AccountPreferencesDialog';
import CompanyNavigationMenu from 'components/domain/account/CompanyNavigationMenu/CompanyNavigationMenu';
import ActiveMemberAvatar from 'components/domain/member/ActiveMemberAvatar/ActiveMemberAvatar';
import AccountSettings from 'containers/account-settings/AccountSettings/AccountSettings';
import { useCommandPalletState } from 'contexts/CommandPalletContext/CommandPalletContext';
import { useOpenable, useReduxSelector } from 'hooks';
import useQuickSearchGraylog from 'hooks/analytics/useQuickSearchGraylog';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import useNavBarSettings from '../hooks/useNavBarSettings';
import useIsInIframe from 'hooks/store/useIsInIframe';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';

export interface IMinNavHeaderProps {
  toggleMenu: () => void;
}

const MinNavHeader = (props: IMinNavHeaderProps) => {
  const { toggleMenu } = props;
  const myAccountOpenable = useOpenable();
  const preferencesOpenable = useOpenable();
  const { open: openCommandPallet } = useCommandPalletState();
  const isCommandPalletEnabled = useFeatureFlags('COMMAND_PALLET');
  const isInIframe = useIsInIframe();

  const quickSearchUserEvents = useQuickSearchGraylog();
  const { areAllNavItemsVisible } = useNavBarSettings();

  const shouldShowExpandIcon = !isInIframe;
  const shouldShowHiddenIcon = areAllNavItemsVisible() === false;

  const shouldHideTopSection = !shouldShowExpandIcon && !shouldShowHiddenIcon;

  return (
    <Col align={Align.CENTER}>
      <ConditionalContainer condition={!shouldHideTopSection}>
        <Row align={Align.CENTER} justify={Justify.CENTER} className="my-2">
          {shouldShowExpandIcon ? (
            <Tooltip label={t('Expand Menu')} delay={0} position={Position.RIGHT}>
              <Button
                type="icon"
                size={Size.SMALL}
                onClick={() => {
                  toggleMenu();
                }}
              >
                <Icon svg={ExpandNavIcon} />
              </Button>
            </Tooltip>
          ) : null}
        </Row>
        {shouldShowHiddenIcon && (
          <Row align={Align.CENTER} justify={Justify.CENTER} className="my-2">
            <Tooltip
              label={t(
                'Some menu items have been hidden. To customize which menu items are visible, visit the Preferences section.'
              )}
              delay={0}
              position={Position.RIGHT}
            >
              <Icon svg={HiddenIcon} />
            </Tooltip>
          </Row>
        )}

        <Divider />
      </ConditionalContainer>

      <Row justify={Justify.CENTER}>
        <Menu
          className="side-nav-menu"
          position={Position.BOTTOM_START}
          trigger={(ref: any) => (
            <div ref={ref}>
              <Tooltip label={t('Profile')} delay={0} position={Position.RIGHT}>
                <ActiveMemberAvatar className={'side-nav-menu-button my-4'} size={Size.SMALL} numInitials={2} />
              </Tooltip>
            </div>
          )}
          content={(closeMenu) => (
            <CompanyNavigationMenu
              closeMenu={closeMenu}
              onMyAccountClick={myAccountOpenable.open}
              onPreferencesClick={preferencesOpenable.open}
            />
          )}
          width="300px"
        />
      </Row>

      {isCommandPalletEnabled && (
        <Row align={Align.CENTER} justify={Justify.CENTER} className="mb-2">
          <Tooltip label={t('Quick Search')} delay={0} position={Position.RIGHT}>
            <Button
              type="icon"
              size={Size.SMALL}
              onClick={() => {
                quickSearchUserEvents.searchOpened(false);
                openCommandPallet();
              }}
            >
              <Icon svg={SearchMagnifyingGlass} />
            </Button>
          </Tooltip>
        </Row>
      )}

      <Dialog isOpen={myAccountOpenable.isOpen} onClose={myAccountOpenable.close} size="full">
        <DialogHeader divider={true}>
          <IconButton svg={ArrowBackIcon} onClick={myAccountOpenable.close} />
          <h2>{t('Account Settings')}</h2>
        </DialogHeader>
        <AccountSettings className="my-9 mx-12" />
      </Dialog>

      <AccountPreferencesDialog isOpen={preferencesOpenable.isOpen} onClose={preferencesOpenable.close} size="full" />
    </Col>
  );
};

export default MinNavHeader;
