import classNames from 'classnames';
import ConfirmationDialog from 'components/foundation/dialogs/ConfirmationDialog/ConfirmationDialog';
import useLegacyNavigation from 'hooks/navigation/useLegacyNavigation';
import useIsInIframe from 'hooks/store/useIsInIframe';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';

export interface IProFeatureDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  customMessage?: string;
}

const ProFeatureDialog = (props: IProFeatureDialogProps) => {
  const { className, isOpen, onClose } = props;

  const classes = classNames('pro-feature-dialog', className);
  const legacyNavigation = useLegacyNavigation();
  const isInIframe = useIsInIframe();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t('Pro Feature')}
      message={
        props.customMessage
          ? props.customMessage
          : t('Upgrade to Pro to get access to advanced features that will help you grow your business.')
      }
      closeButtonText={t('Close')}
      confirmButtonText={!isInIframe ? t('Upgrade') : undefined}
      onConfirm={!isInIframe ? legacyNavigation.subscription : undefined}
      onClose={onClose}
      className={classes}
    />
  );
};

export default ProFeatureDialog;
