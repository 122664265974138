import { Dialog, DialogHeader, Divider } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { ClassName } from 'types/ClassName';
import DetailHeaderWithText from '../DetailHeaderWithText/DetailHeaderWithText';
import './DetailsReadOnly.scss';

/*
  This component is intended to be used to create dialog prompts that have key:value rows that are
  grouped into sections. An optional title will provide a header with title.
*/
export interface IDetailsReadOnlyRow {
  title: string;
  detail: string | ReactNode;
}

interface IDetailsReadOnlySection {
  title: string;
  rows: IDetailsReadOnlyRow[];
}

export interface IDetailsReadOnlyProps {
  title?: string;
  data: IDetailsReadOnlySection[];
  isOpen: boolean;
  sectionClassName?: ClassName;
  onClose?: () => void;
  layer?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  className?: ClassName;
}

const DetailsReadOnly: React.FunctionComponent<IDetailsReadOnlyProps> = (props) => {
  const sections = props.data.map((section, sectionKey) => {
    const rows = section.rows.map((row, rowKey) => (
      <DetailHeaderWithText key={rowKey} title={row.title} value={row.detail} />
    ));

    const sectionClasses = classNames(
      'section',
      {
        'bottom-section': sectionKey === props.data.length - 1 ? true : false,
      },
      props.sectionClassName
    );

    return (
      <div className={sectionClasses} key={sectionKey}>
        <h3>{section.title}</h3>
        {rows}
      </div>
    );
  });
  const dividedSections = sections.map((section, index) => {
    return (
      <span key={index}>
        {index !== 0 ? <Divider /> : null}
        {section}
      </span>
    );
  });

  const header = props.title ? (
    <DialogHeader divider={false}>
      <h1>{props.title}</h1>
    </DialogHeader>
  ) : null;

  return (
    <Dialog isOpen={props.isOpen} className={props.className} onClose={props.onClose} layer={props.layer}>
      {header}
      {dividedSections}
    </Dialog>
  );
};

export default DetailsReadOnly;
