import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRandomRefreshInterval } from 'containers/dashboard/util/utils';

export interface IStorePreferences {
  dashboardRefreshInterval: number;
}

export const initialState: IStorePreferences = {
  dashboardRefreshInterval: getRandomRefreshInterval(),
};

const slice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    updateDashboardRefreshInterval: (state: IStorePreferences, action: PayloadAction<number>) => {
      state.dashboardRefreshInterval = action.payload;
      return state;
    },
  },
});

export const { updateDashboardRefreshInterval } = slice.actions;
export default slice.reducer;
