import { t } from 'utils/localize';
import { ErrorState } from 'components';

const FeatureUnavailable = () => (
  <ErrorState
    title={t('Feature Unavailable')}
    subtitle={t('The requested feature is not enabled, or your account does not have access.')}
  />
);

export default FeatureUnavailable;
