import { QueryResult, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Divider, Position } from '@busybusy/webapp-react-ui';
import { EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import LazyLoadPicker from 'components/foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import SimplePickerRow from 'components/foundation/pickers/SimplePickerRow/SimplePickerRow';
import { useDebounce, useOrganization } from 'hooks';
import { t } from 'i18next';
import _, { last } from 'lodash';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import SearchType from 'types/enum/SearchType';
import IEquipment from 'types/Equipment';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { logError } from 'utils/testUtils';

export interface IEquipmentPickerProps {
  value: string | null;
  onSelect: (equipmentId: string | null) => void;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  pickerHeaderItems?: IBasicPickerValue[];
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
  className?: ClassName;
  filteredIds?: string[];
}

type CursoredPickerValue = IBasicPickerValue & { cursor?: string | undefined };

const EquipmentPicker = (props: IEquipmentPickerProps) => {
  const {
    value,
    onSelect,
    minWidth,
    error,
    placeholder,
    position,
    closeButtonRender,
    dropDownButtonRender,
    className,
    pickerHeaderItems,
    filteredIds,
  } = props;

  const [data, setData] = useState<CursoredPickerValue[]>([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [apolloError, setApolloError] = useState(false);
  const organization = useOrganization();
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);
  const client = useApolloClient();

  const defaultData = React.useMemo(
    () => pickerHeaderItems?.map((item) => ({ ...item, cursor: undefined })) ?? [],
    [pickerHeaderItems]
  );

  useEffect(() => {
    setData(defaultData);
    setLoadedAll(false);
  }, [searchDebounce]);

  function handleDidLoad(models: CursoredPickerValue[], err: boolean, allLoaded: boolean) {
    if (!err) {
      setLoadedAll(allLoaded);
      setData(models);
    }

    setApolloError(err);
  }

  function onClose() {
    setSearchValue('');
    setLoadedAll(false);
    setData(defaultData);
  }

  function handleSelect(value: CursoredPickerValue | null) {
    onSelect(value ? value.id : null);
  }

  async function getEquipment(after?: string): Promise<CursoredPickerValue[]> {
    const result = await client
      .query<{ equipment: IEquipment[] }>({
        query: EQUIPMENT_QUERY,
        variables: {
          after,
          first: 10,
          filter: {
            deletedOn: { isNull: true },
            search: searchDebounce
              ? {
                  type: SearchType.CONTAINS,
                  fields: ['equipmentName', 'modelName', 'makeName'],
                  value: searchDebounce,
                }
              : undefined,
            id: filteredIds ? { doesNotContain: filteredIds } : undefined,
          },
        },
      })
      .catch(logError);

    return result.data.equipment.map((equipment) => ({
      id: equipment.id,
      name: getEquipmentDisplay(equipment),
      cursor: equipment.cursor,
    }));
  }

  function renderValueTemplate(id: string | null) {
    if (id) {
      const equipment = _.find(data, (model) => model.id === id);
      if (equipment) {
        return <div className="ellipsis">{equipment ? equipment.name : t('None')}</div>;
      } else {
        return (
          <Query query={EQUIPMENT_QUERY} variables={{ filter: { id: { equal: id } } }}>
            {({ data: equipmentData, error }: QueryResult<{ equipment: IEquipment[] }>) => {
              if (error) {
                logError(error);
              }

              return (
                <>
                  {equipmentData && equipmentData.equipment.length ? (
                    getEquipmentDisplay(equipmentData.equipment[0])
                  ) : (
                    <></>
                  )}
                </>
              );
            }}
          </Query>
        );
      }
    }

    return <></>;
  }

  function renderRow(item: CursoredPickerValue) {
    const isLastHeaderItem = item.id === last(pickerHeaderItems)?.id;

    if (isLastHeaderItem) {
      return (
        <>
          <SimplePickerRow value={item.name} searchValue={searchValue} />
          <Divider className="my-2" />
        </>
      );
    }

    return <SimplePickerRow value={item.name} searchValue={searchValue} />;
  }

  function onSearch(val: string) {
    setSearchValue(val);
  }

  if (!organization.trackEquipment) {
    return null;
  }

  const classes = classNames('equipment-picker', className);

  return (
    <LazyLoadPicker
      value={value}
      getData={getEquipment}
      dataError={apolloError}
      didLoad={handleDidLoad}
      error={error}
      data={data}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      onSearch={onSearch}
      searchValue={searchValue}
      valueTemplate={renderValueTemplate}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      placeholder={placeholder}
      className={classes}
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      defaultFocusIndex={pickerHeaderItems?.length ?? 0}
    />
  );
};

EquipmentPicker.defaultProps = {
  minWidth: '350px',
};

export default EquipmentPicker;
