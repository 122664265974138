import { Dialog, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { DetailsItem, HeaderDialog } from 'components';
import DetailsCard from 'components/foundation/DetailsCard/DetailsCard';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import SectionHeader from 'containers/settings/components/SectionHeader/SectionHeader';
import { useOpenable } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useEmployeeNameFormat, {
  employeeNameFormatLabelLookup,
} from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import DashboardSettingsDetails from './DashboardSettingsDetails/DashboardSettingsDetails';
import EmployeeNameFormatForm from './EmployeeNameFormatForm/EmployeeNameFormatForm';
import MemberSettingsBlobDetails from './MemberSettingsBlobDetails/MemberSettingsBlobDetails';
import MenuCustomizationsForm from './MenuCustomizationsForm';
import OrganizationSettingsDetails from './OrganizationSettingsDetails/OrganizationSettingsDetails';
import './Preferences.scss';

export interface IPreferencesProps {
  className?: ClassName;
}

function Preferences({ className }: IPreferencesProps) {
  const [employeeNameFormat] = useEmployeeNameFormat();
  const employeeNameOpenable = useOpenable();
  const customizeMenuOpenable = useOpenable();
  const memberBlobOpenable = useOpenable();
  const organizationOpenable = useOpenable();
  const dashboardSettingsOpenable = useOpenable();
  const [showSecretMenu, setShowSecretMenu] = useState(false);
  const brand = useBrandTitle();

  //secret menu added Nov '22
  useEffect(() => {
    const keyArray: string[] = [];

    const setSecretMenu = (e: KeyboardEvent) => {
      keyArray.push(e.key);
      const secretArray = [
        'ArrowUp',
        'ArrowUp',
        'ArrowDown',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'ArrowLeft',
        'ArrowRight',
        'Enter',
      ];
      if (isEqual(keyArray, secretArray)) {
        setShowSecretMenu(true);
        keyArray.length = 0;
      }
    };
    window.addEventListener('keydown', setSecretMenu);

    return () => window.removeEventListener('keydown', setSecretMenu);
  }, []);

  return (
    <div className={classNames('preferences', className)}>
      <SectionHeader className={'pb-2'}>{t('Formatting')}</SectionHeader>
      <Row>
        <DetailsCard>
          <DetailsItem
            onClick={employeeNameOpenable.open}
            hint={t(
              'This determines how employee names should appear in {{brand}} reports. Since many reports are sorted alphabetically by name, the order in which employees appear in a report may change depending on the format.',
              { brand }
            )}
          >
            <HeaderText title={t('Employee Name Format')}>
              {employeeNameFormatLabelLookup[employeeNameFormat]}
            </HeaderText>
          </DetailsItem>
        </DetailsCard>
      </Row>
      <Dialog isOpen={employeeNameOpenable.isOpen} onClose={employeeNameOpenable.close}>
        <EmployeeNameFormatForm className="preferences-name-format-dialog-form" onSubmit={employeeNameOpenable.close} />
      </Dialog>
      <SectionHeader className={'pt-8 pb-2'}>{t('Navigation')}</SectionHeader>
      <Row>
        <DetailsCard>
          <DetailsItem onClick={customizeMenuOpenable.open}>
            <HeaderText title={t('Customize Menu')}>{t('Hide and reorder main menu items.')}</HeaderText>
          </DetailsItem>
        </DetailsCard>
      </Row>

      {showSecretMenu && (
        <>
          <SectionHeader className={'pt-8 pb-2'}>{t('Secret Menu')}</SectionHeader>
          <Row>
            <DetailsCard>
              <DetailsItem onClick={memberBlobOpenable.open}>
                <HeaderText title={t('Member Settings')}>{t('View and adjust member settings.')}</HeaderText>
              </DetailsItem>
              <DetailsItem onClick={organizationOpenable.open}>
                <HeaderText title={t('Organization Settings')}>{t('View current organization settings.')}</HeaderText>
              </DetailsItem>
              <DetailsItem onClick={dashboardSettingsOpenable.open}>
                <HeaderText title={t('Dashboard Settings')}>{t('View and adjust dashboard settings.')}</HeaderText>
              </DetailsItem>
            </DetailsCard>
          </Row>
        </>
      )}
      <HeaderDialog
        title={t('Main Menu')}
        divider={false}
        isOpen={customizeMenuOpenable.isOpen}
        onClose={customizeMenuOpenable.close}
      >
        <MenuCustomizationsForm onSubmit={customizeMenuOpenable.close} />
      </HeaderDialog>
      <HeaderDialog
        title={t('Member Settings')}
        divider={false}
        isOpen={memberBlobOpenable.isOpen}
        onClose={memberBlobOpenable.close}
      >
        <MemberSettingsBlobDetails onClose={memberBlobOpenable.close} />
      </HeaderDialog>
      <HeaderDialog
        title={t('Organization Settings')}
        divider={false}
        isOpen={organizationOpenable.isOpen}
        onClose={organizationOpenable.close}
      >
        <OrganizationSettingsDetails onClose={organizationOpenable.close} />
      </HeaderDialog>
      <HeaderDialog
        title={t('Dashboard Settings')}
        divider={false}
        isOpen={dashboardSettingsOpenable.isOpen}
        onClose={dashboardSettingsOpenable.close}
      >
        <DashboardSettingsDetails onClose={dashboardSettingsOpenable.close} />
      </HeaderDialog>
    </div>
  );
}

export default Preferences;
