import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Panel } from 'components';
import { FunctionComponent, ReactNode } from 'react';

import './MultiPanel.scss';

export interface IMultiPanelProps {
  renders: Array<() => ReactNode>;
  stroke: boolean;
  className?: ClassName;
}

const MultiPanel: FunctionComponent<IMultiPanelProps> = (props) => {
  const { className, renders, stroke } = props;

  const classes = classNames(
    {
      'multi-panel': true,
      'stroke': stroke,
    },
    className
  );

  return (
    <div className={classes}>
      {renders.map((render, index) => {
        return (
          <Panel key={index} strokeRight={stroke}>
            <div className={'multi-panel-content'}>{render()}</div>
          </Panel>
        );
      })}
    </div>
  );
};

export default MultiPanel;
