import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { t } from 'i18next';
import { compact } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import BasicNonNullPicker from '../pickers/basic-picker/BasicNonNullPicker/BasicNonNullPicker';
import AdjustingDateRange, { IAdjustingDateRangeProps } from '../ranges/AdjustingDateRange/AdjustingDateRange';
import './SidePanelTimeRangeSection.scss';

export interface ISidePanelTimeRangeSectionProps extends Omit<IAdjustingDateRangeProps, 'onChange' | 'value'> {
  timeRangeType: TimeRangeType;
  includeAllTime: boolean;
  customTimeRange: IAdjustingDateRangeProps['value'];
  onTimeRangeTypeChange: (timeRangeType: TimeRangeType) => void;
  onCustomTimeRangeChange: (timeRange: ITimeRange<Nullable<DateTime>>) => void;
  className?: ClassName;
}

function SidePanelTimeRangeSection({
  timeRangeType,
  includeAllTime,
  onTimeRangeTypeChange,
  onCustomTimeRangeChange,
  customTimeRange,
  className,
  ...adjustingDateRangeProps
}: ISidePanelTimeRangeSectionProps) {
  const timeRangeTypePickerOptions = useMemo(
    () =>
      compact([
        includeAllTime ? { id: TimeRangeType.ALL_TIME, name: t('All Time') } : null,
        { id: TimeRangeType.PAY_PERIOD, name: t('Pay Period') },
        { id: TimeRangeType.MONTHLY, name: t('Monthly') },
        { id: TimeRangeType.WEEKLY, name: t('Weekly') },
        { id: TimeRangeType.DAILY, name: t('Daily') },
        { id: TimeRangeType.CUSTOM, name: t('Custom') },
      ]),
    [includeAllTime]
  );

  if (timeRangeType === TimeRangeType.ALL_TIME && !includeAllTime) {
    throw Error('All Time is excluded and `SidePanelTimeRangeSection` was passed All Time.');
  }

  const classes = classNames('side-panel-time-range-section', className);

  return (
    <div className={classes}>
      <BasicNonNullPicker onSelect={onTimeRangeTypeChange} value={timeRangeType} data={timeRangeTypePickerOptions} />
      {timeRangeType === TimeRangeType.CUSTOM && (
        <AdjustingDateRange
          {...adjustingDateRangeProps}
          onChange={onCustomTimeRangeChange}
          className="mt-4"
          value={customTimeRange}
        />
      )}
    </div>
  );
}

export default SidePanelTimeRangeSection;
