import { AUTH_MEMBER_FRAGMENT } from 'containers/app/beforeRoutes/AuthenticateFromToken/authentication-query';
import gql from 'graphql-tag';

export const MEMBER_CREATE_QUERY = gql`
  mutation CreateMember($member: MemberCreateInput) {
    createMember(member: $member) {
      id
      firstName
      lastName
      username
    }
  }
`;

export const MEMBER_UPDATE_MUTATION = gql`
  mutation updateMember($member: MemberUpdateInput) {
    updateMember(member: $member) {
      id
      firstName
      lastName
      username
    }
  }
`;

export const ACTIVE_MEMBER_UPDATE_MUTATION = gql`
  mutation UpdateActiveMember($member: MemberUpdateInput) {
    updateMember(member: $member) {
      ...AuthMember
    }
  }
  ${AUTH_MEMBER_FRAGMENT}
`;
