import gql from 'graphql-tag';

export const ORGANIZATION_INFO_UPDATE_MUTATION = gql`
  mutation OrganizationInfo($organizationInfo: OrganizationInfoUpdateInput) {
    updateOrganizationInfo(organizationInfo: $organizationInfo) {
      id
      organizationId
      longitude
      latitude
      address1
      address2
      city
      county
      state
      postalCode
      country
      phone
      trade
      currencyCode
      employeeMin
      employeeMax
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
  }
`;
