import { Reducer } from 'redux';

import { ENVIRONMENT_SET_FROM_DOTENV, IStoreEnvironment, IStoreEnvironmentSetFromDotEnvAction } from './index';

import { initialState } from './environmentInitialState';

export type IStoreAuthenticationActions = IStoreEnvironmentSetFromDotEnvAction;

export const environmentReducers: Reducer<IStoreEnvironment, IStoreAuthenticationActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ENVIRONMENT_SET_FROM_DOTENV:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default environmentReducers;
