import { reduce } from 'lodash';
import { MemberLaborMetrics } from '__generated__/graphql';
import { add } from './numberUtils';

export enum ReportType {
  SECONDS = 'seconds',
  COST = 'cost',
}

export function aggregateMemberLaborMetricsTime(metrics: Array<Pick<MemberLaborMetrics, 'rt' | 'pto' | 'dt' | 'ot'>>) {
  return reduce(
    metrics,
    (acc, cur) => {
      return {
        regularTime: add(acc.regularTime, cur.rt),
        pto: add(acc.pto, cur.pto),
        doubleTime: add(acc.doubleTime, cur.dt),
        overtime: add(acc.overtime, cur.ot),
        total: add(acc.total, cur.rt, cur.dt, cur.ot, cur.pto),
      };
    },
    {
      regularTime: 0,
      doubleTime: 0,
      overtime: 0,
      total: 0,
      pto: 0,
    }
  );
}

type TimedModelMetric = Pick<MemberLaborMetrics, 'rt' | 'ot' | 'dt' | 'pto'>;

export function laborMetricHasPaidTime(metric: TimedModelMetric) {
  return metric.rt || metric.dt || metric.ot || metric.pto;
}

export function laborMetricsHasPaidTime(metrics: TimedModelMetric[]) {
  return metrics.some(laborMetricHasPaidTime);
}
