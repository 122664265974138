import { ITimeCardRowInfo } from 'containers/timesheets/TimeCardReportTable/TimeCardReportTable';
import { DateTime } from 'luxon';
import { createContext } from 'react';
import { IMember } from 'types';
import ITimeRange from 'types/TimeRange';
import { PayPeriodSignaturesLoaded } from './TimeCardReportContextProvider';

export type TimeCardReportMemberData = IMember & { rows: ITimeCardRowInfo[] } & { totalSeconds: number };

export interface ITimeCardReportMemberState {
  byId: {
    [key: string]: TimeCardReportMemberData;
  };
}

export interface ITimeCardReportContextPayload {
  members: IMember[];
  data: ITimeCardReportMemberState | null;
  loadedAll: boolean;
  getTimeCardReportDataForMember: (member: IMember) => TimeCardReportMemberData | null;
  getMoreMembers: (
    timeRange: ITimeRange<DateTime>,
    first: number,
    employeeIds: string[] | null,
    cursor?: string
  ) => Promise<IMember[]>;
  refreshMembersData: (memberIds: string[], timeRange: ITimeRange<DateTime>) => Promise<void>;
  populateData: (timeRange: ITimeRange<DateTime>) => Promise<void>;
  forceLoadAll: () => Promise<TimeCardReportMemberData[]>;
  didLoad: (members: IMember[], error: boolean, loadedAll: boolean) => void;
  clearData: () => void;
  signaturesLoaded: PayPeriodSignaturesLoaded[];
  onSignaturesLoaded?: (memberId: string, isLoaded: boolean) => void;
  areAllSignaturesLoaded?: () => boolean;
}

export const TimeCardReportContext = createContext<ITimeCardReportContextPayload>({} as ITimeCardReportContextPayload);
