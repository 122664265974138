import { QueryOptions } from '@apollo/client';
import { useApolloPaging } from 'hooks/apollo';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeOffRequest from 'types/TimeOffRequest';
import { TimeOffRequestFilter, TimeOffRequestSort } from '__generated__/graphql';

export type ITimeOffRequestQueryOptions<
  Variables = {
    filter?: TimeOffRequestFilter;
    sort?: TimeOffRequestSort;
    first?: number;
    after?: string | null;
  }
> = QueryOptions<Variables>;

export default function useTimeOffRequestQuery() {
  const { getAll: getApolloAll, getPage: getApolloPage } = useApolloPaging();

  const getPage = useCallback(
    async <T extends ITimeOffRequest & ICursorable, O extends ITimeOffRequestQueryOptions>(
      options: O,
      after?: string | null
    ) => {
      const result = await getApolloPage<T, { timeOffRequests: T[] }>(options, after);
      return result.data.timeOffRequests;
    },
    [getApolloPage]
  );

  const getAll = useCallback(
    <T extends ITimeOffRequest & ICursorable, O extends ITimeOffRequestQueryOptions>(options: O) => {
      return getApolloAll<T>('timeOffRequests', options);
    },
    [getApolloAll]
  );

  return { getPage, getAll };
}
