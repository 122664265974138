import { Align, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { EmptyState, Spinner } from 'components';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import TimeCardReportMemberHeader from 'containers/timesheets/TimeCardReportMemberHeader/TimeCardReportMemberHeader';
import { useOpenable } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { DateTime } from 'luxon';
import { useContext, useMemo, useState } from 'react';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { numberOfDaysBetween } from 'utils/dateUtils';
import { t } from 'utils/localize';
import SignOffReportDialog from '../SignOffReportDialog/SignOffReportDialog';
import TimesheetPrintSignature from '../TimesheetPrintSignature/TimesheetPrintSignature';
import { TimeCardReportExpandedBreakFormat } from '../TimesheetsSidePanel/hooks/useTimeCardSettings';
import './ExpandedTimeCardReport.scss';
import ExpandedTimeCardsContents from './ExpandedTimeCardsContents/ExpandedTimeCardsContents';
import ExpandedTimeCardsPrintFooter from './ExpandedTimeCardsPrintFooter/ExpandedTimeCardsPrintFooter';
import ExpandedTimeCardsPrintHeader from './ExpandedTimeCardsPrintHeader/ExpandedTimeCardsPrintHeader';
import { ExpandedTimeCardReportContext } from './context/ExpandedTimeCardReportContext';

export interface IExpandedTimeCardReportProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  onDataChange?: (members: string[]) => void;
  timeRangeType: TimeRangeType;
  scroller?: HTMLElement | 'self';
  onSignatureComplete?: (memberId: string) => void;
  showDecimalFormat?: boolean;
  breakFormat: TimeCardReportExpandedBreakFormat;
}

const ExpandedTimeCardReport = (props: IExpandedTimeCardReportProps) => {
  const {
    className,
    timeRange,
    onDataChange,
    timeRangeType,
    scroller,
    onSignatureComplete,
    showDecimalFormat,
    breakFormat,
  } = props;
  const memberSettings = useMemberSettings();

  const renderSize = numberOfDaysBetween(timeRange.startTime, timeRange.endTime) > 20 ? 1 : 3;
  const printOptions = memberSettings?.web?.features?.timeCards?.printOptions;

  const [dialogMember, setDialogMember] = useState<IMember | null>(null);
  const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);

  const memberIds = useMemo(() => (dialogMember ? [dialogMember.id] : []), [dialogMember]);

  const viewEntriesDialog = useOpenable({ onClose: clearDialogSettings });
  const signOffReportDialog = useOpenable({ onClose: clearDialogSettings });

  const { data: members, loadedAll, refreshMembersData, loading } = useContext(ExpandedTimeCardReportContext);

  function clearDialogSettings() {
    setDialogMember(null);
    setSelectedDate(null);
  }

  function onViewMemberEntries(member: IMember) {
    setDialogMember(member);
    viewEntriesDialog.open();
  }

  function onViewSignOffs(member: IMember) {
    setDialogMember(member);
    signOffReportDialog.open();
  }

  function refreshData(memberList: string[]) {
    if (onDataChange) {
      onDataChange(memberList);
    }

    refreshMembersData(memberList, timeRange);
  }

  const onSignOffUpdate = () => {
    refreshData(memberIds);
  };

  const onSignatureCurried = (memberId: string) => () => onSignatureComplete?.(memberId);

  const renderRow = (member: IMember, index: number) => {
    return (
      <div
        key={member.id}
        className={classNames(
          index !== 0 ? 'report-gap' : '',
          'timecard-report-container',
          printOptions?.pageBreaks ? 'page-break' : ''
        )}
        data-testid={member.id}
      >
        <ExpandedTimeCardsPrintHeader member={member} printDensity={printOptions?.printDensity} timeRange={timeRange} />
        <div className="no-print">
          <TimeCardReportMemberHeader
            member={member}
            onViewEntries={onViewMemberEntries}
            onViewSignOff={onViewSignOffs}
            isExpanded={true}
            showDecimalFormat={showDecimalFormat}
          />
        </div>

        <ExpandedTimeCardsContents
          member={member}
          scroller={scroller !== 'self' ? scroller : undefined}
          timeRange={timeRange}
          onDataChange={refreshData}
          timeRangeType={timeRangeType}
          onSignatureComplete={onSignatureCurried(member.id)}
          showDecimalFormat={showDecimalFormat}
          breakFormat={breakFormat}
          printDensity={printOptions?.printDensity}
        />

        {printOptions?.position === 'at-end-of-document' ? (
          members.length - 1 === index && <TimesheetPrintSignature />
        ) : (
          <TimesheetPrintSignature />
        )}

        <ExpandedTimeCardsPrintFooter />

        {printOptions?.pageBreaks && members.length - 1 !== index && <div className="time-card-page-break" />}
      </div>
    );
  };

  const range = useMemo(() => {
    return selectedDate ? { startTime: selectedDate.startOf('day'), endTime: selectedDate.endOf('day') } : timeRange;
  }, [selectedDate, timeRange]);

  const classes = classNames('expanded-time-card-report', className);

  return (
    <>
      <div className={classes}>
        {members.map((member, index) => renderRow(member, index))}
        {loadedAll && members.length === 0 && <EmptyState title={t('No employees')} />}
        {loading && (
          <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8 no-print full-width">
            <Spinner />
          </Row>
        )}

        {viewEntriesDialog.isOpen && (
          <MemberTimeEntryDialog
            isOpen={viewEntriesDialog.isOpen}
            onClose={viewEntriesDialog.close}
            timeRange={range}
            memberIds={memberIds}
            timeRangeType={selectedDate ? TimeRangeType.DAILY : timeRangeType}
            onDataChange={refreshData}
          />
        )}

        <SignOffReportDialog
          isOpen={signOffReportDialog.isOpen}
          onClose={signOffReportDialog.close}
          timeRange={range}
          memberIds={memberIds}
          timeRangeType={selectedDate ? TimeRangeType.DAILY : timeRangeType}
          onDataChange={onSignOffUpdate}
        />
      </div>
    </>
  );
};

export default ExpandedTimeCardReport;
