import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import ClockAction from 'types/enum/ClockAction';
import TimeRoundingInterval from 'types/enum/TimeRoundingInterval';
import TimeRoundingType from 'types/enum/TimeRoundingType';
import IOrganization from 'types/Organization';
import { roundTime as round } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import useOrganization from '../../store/useOrganization';

type ClockActionAgnosticTimeRounding =
  | TimeRoundingType.ROUND_UP
  | TimeRoundingType.ROUND_DOWN
  | TimeRoundingType.NEAREST;

export default function useTimeRounding() {
  const organization = useOrganization();
  const timeRoundingFeatureFlag = useFeatureFlags('TIME_ROUNDING');

  function roundTime(date: DateTime, clockAction: ClockAction) {
    if (timeRoundingEnabled(organization)) {
      return round(date, organization.timeRoundingType, organization.timeRoundingInterval, clockAction);
    } else {
      return date;
    }
  }

  function getMinuteInterval() {
    if (timeRoundingEnabled(organization)) {
      return organization.timeRoundingInterval / 60;
    } else {
      return 1;
    }
  }

  function timeRoundingEnabled(
    org: Partial<IOrganization>
  ): org is { timeRounding: true; timeRoundingInterval: TimeRoundingInterval; timeRoundingType: TimeRoundingType } {
    return (
      timeRoundingFeatureFlag &&
      org.timeRounding === true &&
      !isNil(org.timeRoundingInterval) &&
      (org.timeRoundingInterval as number) > 0 &&
      org.timeRoundingType !== undefined
    );
  }

  function roundTimeWithType(date: DateTime, type: ClockActionAgnosticTimeRounding) {
    if (timeRoundingEnabled(organization)) {
      return round(date, type, organization.timeRoundingInterval);
    } else {
      return date;
    }
  }

  return {
    roundTime,
    minuteInterval: getMinuteInterval(),
    enabled: timeRoundingEnabled(organization),
    roundTimeWithType,
  };
}
