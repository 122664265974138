import useGustoPaidEnabled from 'components/domain/integration/gusto/hooks/meta/useGustoPaidEnabled/useGustoPaidEnabled';
import { ReactNode } from 'react';

export interface IGustoPaidContainerProps {
  children: ReactNode;
}

function GustoPaidContainer({ children }: IGustoPaidContainerProps) {
  const isGustoPaidEnabled = useGustoPaidEnabled();

  if (!isGustoPaidEnabled) {
    return null;
  }

  return <>{children}</>;
}

export default GustoPaidContainer;
