import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IStoreAuthentication, IStoreEnvironment } from 'store/types';
import { getAxiosClient } from 'utils/axiosClient';

export default function useAxios() {
  const environment = useSelector((state: { environment: IStoreEnvironment }) => state.environment);
  const authentication = useSelector((state: { authentication: IStoreAuthentication }) => state.authentication);
  return useMemo(
    () => getAxiosClient({ authentication, url: environment.REACT_APP_RESTAPI_URL }),
    [authentication, environment.REACT_APP_RESTAPI_URL]
  );
}
