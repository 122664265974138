import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  MEMBERS_WITH_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import ICursorable from 'types/Cursorable';
import IMember from 'types/Member';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { combineMetricWithTimeOff, getMetricLaborTotals } from 'utils/jitMetricUtils';
import { ConditionNullableFieldType, LaborMetricsInterval } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  getMemberTimeOffMetricDictionary,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from '../../hooks/useMemberActivityPermission';
import { IMemberActivityTableRowInfo } from './useMemberActivity';

export default function useMemberActivityBasicDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const permission = useMemberActivityPermission();
  const canViewCost = useHasCostPermission();
  const nameFormatted = useEmployeeNameFormatter();
  const remainingItemRef = useRef<IMemberActivityTableRowInfo>();
  const [data, setData] = useState<IMemberActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'member',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return canViewCost ? MEMBERS_WITH_COST_CODE_METRIC_QUERY : MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_PROJECT:
        return canViewCost ? MEMBERS_WITH_PROJECT_METRIC_QUERY : MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost ? MEMBERS_WITH_EQUIPMENT_METRIC_QUERY : MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_METRIC_QUERY : MEMBERS_WITH_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IMemberActivityTableRowInfo, key: keyof IMemberActivityTableRowInfo) {
    if (key === 'member') {
      return item.member ? nameFormatted(item.member.firstName ?? '', item.member.lastName ?? '') : '';
    } else {
      return item[key];
    }
  }

  async function loadData() {
    const members = await getAll<IMember & ICursorable>('members', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    // we need to get the time off metrics when showing members filtered by the "no project" (or other variant) item
    // because web services won't include it #suckitdrew
    let timeOffData: Dictionary<ITimeOffLaborMetrics> | undefined = undefined;
    if (filterId === remainingDataItemId) {
      timeOffData = await getMemberTimeOffMetricDictionary(client, timeRange, canViewCost);
    }

    const tableRows = mapNotNull(members, (member) => createTableRowInfo(member, timeOffData?.[member.id]));

    // calculate total time in order to show `Additional Time` item if the user doesn't have permission to see everyone
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );
    const remainingData = calculateRemainingActivityData(tableRows, totalData);
    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        member: null,
        ...remainingData,
      };
    } else {
      // don't show no equipment item when there is no time allocated to no equipment
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const createTableRowInfo = (
    member: IMember,
    timeOffData?: ITimeOffLaborMetrics
  ): IMemberActivityTableRowInfo | null => {
    const metric = laborMetrics(member);
    if (!isNil(metric) || !isNil(timeOffData)) {
      const metricTotal = getMetricLaborTotals(
        combineMetricWithTimeOff(metric, timeOffData),
        filterIdWithDescendants === false
      );
      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: member.id,
        member,
        regularHours: metricTotal.regularSeconds + metricTotal.paidTimeOffSeconds,
        regularHoursDec: metricTotal.regularSeconds + metricTotal.paidTimeOffSeconds,
        overtimeHours: metricTotal.overtimeSeconds,
        overtimeHoursDec: metricTotal.overtimeSeconds,
        doubleTimeHours: metricTotal.doubleTimeSeconds,
        doubleTimeHoursDec: metricTotal.doubleTimeSeconds,
        totalHours: metricTotal.totalSeconds,
        totalHoursDec: metricTotal.totalSeconds,
        regularCost: metricTotal.regularCost + metricTotal.timeOffCost,
        overtimeCost: metricTotal.overtimeCost,
        doubletimeCost: metricTotal.doubleTimeCost,
        laborBurden: metricTotal.laborBurden,
        totalCost: metricTotal.totalCost,
      };
    }

    return null;
  };

  const laborMetrics = (member: IMember) => {
    switch (filterType) {
      case ActivityReportType.BY_COST_CODE:
        return first(member.memberCostCodeLaborMetrics);
      case ActivityReportType.BY_PROJECT:
        return first(member.memberProjectLaborMetrics);
      case ActivityReportType.BY_EQUIPMENT:
        return first(member.memberEquipmentLaborMetrics);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return first(member.memberLaborMetrics);
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
