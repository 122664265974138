import { QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import IMember from 'types/Member';

export interface IAuthenticationQueryData {
  members: IMember[];
}
export interface IAuthenticationQueryVariables {
  memberId: IMember['id'];
}

export type IAuthenticationQueryResult = QueryResult<IAuthenticationQueryData, IAuthenticationQueryVariables>;

export const AUTH_ORGANIZATION_FRAGMENT = gql`
  fragment AuthOrganization on Organization {
    authoritativeSignature
    betaTester
    breakPolicy
    breakPolicyCalifornia
    breakPolicyMessage
    bulletin
    busySignInQuestion
    createdOn
    customSignOff
    defaultBreakDuration
    defaultEndTime
    defaultStartTime
    deletedOn
    demo
    disclaimer
    gustoCompanyId
    hideUnassignedSubprojects
    id
    imageId
    inRecoveryMode
    lockOnSelfSignature
    minimumAppVersion
    organizationSettings
    organizationInfo {
      id
      organizationId
      longitude
      latitude
      address1
      address2
      city
      county
      state
      postalCode
      country
      phone
      trade
      currencyCode
      employeeMin
      employeeMax
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    organizationPayPeriod {
      id
      organizationId
      payPeriodType
      startDate
      dayOne
      dayTwo
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    organizationOvertimePeriods {
      id
      organizationId
      startTime
      lengthInDays
      weeklyOvertime
      dailyOvertime
      dailyDoubletime
      seventhDayOvertime
      seventhDayDoubletime
      saturdayMultiplier
      sundayMultiplier
      createdOn
      submittedOn
      updatedOn
      deletedOn
    }
    organizationName
    overtimeFlag
    ownedBy
    performanceScores
    requireCostCode
    requireProject
    revokedOn
    safetySignature
    safetySignatureMessage
    signatureDate
    stripeAccountCreatedOn
    stripeAccountStatus
    stripeAccountType
    stripeCustomerId
    submittedOn
    subscriptionStatus {
      annualSubscriptionCount
      annualSubscriptionDate
      annualSubscriptionInterval
      annualSubscriptionPendingInvoiceId
      cardLastFour
      cardType
      couponCode
      createdOn
      customerId
      deletedOn
      exempt
      hasTrialed
      id
      nextAssessmentAt
      organizationId
      periodDay
      pricePointAction
      pricePointHandle
      productHandle
      referralCode
      status
      submittedOn
      subscribedThroughProduct
      subscriptionId
      subscriptionUpdatedAt
      trialEndedAt
      updatedOn
      userCanceled
    }
    timeAccuracy
    timeAccuracyMessage
    timeOffRequests
    timeRounding
    timeRoundingInterval
    timeRoundingType
    totalTimeFlag
    trackBudgetCost
    trackBudgetEquipmentCosts
    trackBudgetEquipmentHours
    trackBudgetProgress
    trackBudgetProgressValue
    trackBudgets
    trackCostCode
    trackEquipment
    trackPaidBreak
    trackProject
    updatedOn
    useProjectCostCodeScoping
    webGps
    minBreakSeconds
    perDiem
    perDiemAmountTracking
    perDiemRequestPermission
    employeeAutoInvite
  }
`;

export const AUTH_POSITION_FRAGMENT = gql`
  fragment AuthPosition on Position {
    canEditPosition
    canManageProjectCostCodeQuantity
    canRequestTimeOff
    clockInRestriction
    clockInRestrictionStartTime
    createdOn
    dailyProjectPrompts
    dailyProjectPromptsUseSubprojects
    deletedOn
    equipmentSelectionRequirement
    id
    level
    manageBudgets
    manageBulletinPosts
    manageCompanySettings
    manageCostCodes
    manageEmployeeGroups
    manageEmployees
    manageEquipment
    manageGpsApproval
    manageMediaCollection
    managePerDiem
    manageProjectGroups
    manageProjects
    manageSchedule
    manageTimeEntries
    manageTimeOff
    memberLock
    organizationId
    requestSignatures
    scopeByGroup
    submittedOn
    timeEvents
    title
    updatedOn
    viewAllActivityReport
    viewBudgets
    viewEquipmentDetails
    viewEquipmentStatus
    viewMediaCollection
    viewMemberActivityReport
    viewMemberExpenses
    viewProjectExpenses
    viewSchedule
    createIncident
    manageIncident
    performInspections
    manageInspections
  }
`;

export const AUTH_MEMBER_FRAGMENT = gql`
  fragment AuthMember on Member {
    archivedOn
    canSurvey
    certifiedEmail
    createdOn
    deletedOn
    email
    emailVerificationRequired
    firstName
    gustoEmployeeId
    id
    imageId
    imageUrl
    lastAccess
    lastName
    memberGroup {
      id
      groupName
      deletedOn
      organizationId
      updatedOn
      createdOn
      submittedOn
    }
    memberGroupId
    memberNumber
    memberSettings
    organization {
      ...AuthOrganization
    }
    organizationId
    pendingEmail
    phone
    position {
      ...AuthPosition
    }
    positionId
    submittedOn
    timeLocationRequired
    updatedOn
    username
    usernameUnique
    verifiedEmail
  }
  ${AUTH_ORGANIZATION_FRAGMENT}
  ${AUTH_POSITION_FRAGMENT}
`;

export const AUTHENTICATION_QUERY = gql`
  query Authentication($memberId: Uuid) {
    members(filter: { id: { equal: $memberId } }) {
      ...AuthMember
    }
  }
  ${AUTH_MEMBER_FRAGMENT}
`;

export const useAuthenticationQuery = (variables: IAuthenticationQueryVariables): IAuthenticationQueryResult =>
  useQuery<IAuthenticationQueryData, IAuthenticationQueryVariables>(AUTHENTICATION_QUERY, { variables });

export default AUTHENTICATION_QUERY;
