import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import _ from 'lodash';
import { useRef } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import WageRate from 'types/enum/WageRate';

export interface IWageRateSelectProps extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  onChange: (value: WageRate, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const WageRateSelect = (props: IWageRateSelectProps) => {
  const { className, onChange, ...selectProps } = props;

  const [t] = useTranslation();

  function onChangeWage(value: string, event: React.ChangeEvent<HTMLSelectElement>) {
    onChange(_.toNumber(value) as WageRate, event);
  }

  const options = useRef<ISelectOption[]>([
    {
      label: t('Hourly'),
      value: WageRate.HOURLY,
    },
    {
      label: t('Weekly'),
      value: WageRate.WEEKLY,
    },
    {
      label: t('Monthly'),
      value: WageRate.MONTHLY,
    },
    {
      label: t('Yearly'),
      value: WageRate.YEARLY,
    },
  ]);

  const classes = classNames(
    {
      'wage-rate-select': true,
    },
    className
  );

  return (
    <Select
      className={classes}
      placeholder={t('Wage Rate')}
      options={options.current}
      onChange={onChangeWage}
      {...selectProps}
    />
  );
};

WageRateSelect.defaultProps = {
  ...Select.defaultProps,
};

export default WageRateSelect;
