import { Field, FieldFooter } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmployeeGroupPicker } from 'components';
import { IEmployeeGroupPickerProps } from '../EmployeeGroupPicker/EmployeeGroupPicker';

interface IEmployeeGroupFormFieldProps extends Omit<IEmployeeGroupPickerProps, 'value' | 'onSelect'> {
  className?: ClassName;
  form: any;
  name: string;
  onChange?: (value: string | null) => void;
  validations?: any;
  disabled?: boolean;
}

function EmployeeGroupFormField(props: IEmployeeGroupFormFieldProps) {
  const { form, name, validations, className, onChange, disabled, ...other } = props;

  const classes = classNames('employee-picker-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: string | null) {
      fieldProps.handleChange(val, onChange);
    }

    return (
      <>
        <EmployeeGroupPicker
          error={fieldProps.hasError}
          value={form.state.data[name]}
          disabled={disabled}
          onSelect={handleOnSelect}
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return <Field form={form} name={name as string} validations={validations} className={classes} render={renderField} />;
}

EmployeeGroupFormField.defaultProps = {
  filterByPermission: false,
};

export default EmployeeGroupFormField;
