import { Bar, Button, Dialog, DialogHeader, Icon, Menu, Position, Size, Tooltip } from '@busybusy/webapp-react-ui';
import { ArrowBackIcon, HiddenIcon, MinimizeNavIcon, SearchMagnifyingGlass } from 'assets/icons';
import { IconButton } from 'components';
import AccountPreferencesDialog from 'components/domain/account/AccountPreferencesDialog/AccountPreferencesDialog';
import CompanyNavigationMenu from 'components/domain/account/CompanyNavigationMenu/CompanyNavigationMenu';
import ActiveMemberAvatar from 'components/domain/member/ActiveMemberAvatar/ActiveMemberAvatar';
import AccountSettings from 'containers/account-settings/AccountSettings/AccountSettings';
import { useCommandPalletState } from 'contexts/CommandPalletContext/CommandPalletContext';
import { useOpenable } from 'hooks';
import useQuickSearchGraylog from 'hooks/analytics/useQuickSearchGraylog';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import useNavBarSettings from '../hooks/useNavBarSettings';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface INavHeaderProps {
  toggleMenu: () => void;
}

const NavHeader = ({ toggleMenu }: INavHeaderProps) => {
  const myAccountOpenable = useOpenable();
  const preferencesOpenable = useOpenable();
  const { open: openCommandPallet } = useCommandPalletState();
  const isCommandPalletEnabled = useFeatureFlags('COMMAND_PALLET');
  const quickSearchUserEvents = useQuickSearchGraylog();
  const { areAllNavItemsVisible } = useNavBarSettings();
  const isInIframe = useIsInIframe();

  return (
    <Bar>
      <Menu
        className="side-nav-menu"
        position={Position.BOTTOM_START}
        trigger={(ref: any) => (
          <div ref={ref}>
            <Tooltip label={t('Profile')}>
              <ActiveMemberAvatar className={'side-nav-menu-button'} size={Size.SMALL} numInitials={2} />
            </Tooltip>
          </div>
        )}
        content={(closeMenu) => (
          <CompanyNavigationMenu
            closeMenu={closeMenu}
            onMyAccountClick={myAccountOpenable.open}
            onPreferencesClick={preferencesOpenable.open}
          />
        )}
        width="300px"
      />
      <div>
        {areAllNavItemsVisible() === false && (
          <Tooltip
            label={t(
              'Some menu items have been hidden. To customize which menu items are visible, visit the Preferences section.'
            )}
          >
            <Icon svg={HiddenIcon} />
          </Tooltip>
        )}
        {!isInIframe && (
          <Tooltip label={t('Collapse Menu')}>
            <Button
              type="icon"
              size={Size.SMALL}
              onClick={() => {
                toggleMenu();
              }}
            >
              <Icon svg={MinimizeNavIcon} />
            </Button>
          </Tooltip>
        )}

        {isCommandPalletEnabled && (
          <Tooltip label={t('Quick Search')}>
            <Button
              type="icon"
              size={Size.SMALL}
              className={'mr-2'}
              onClick={() => {
                quickSearchUserEvents.searchOpened(false);
                openCommandPallet();
              }}
            >
              <Icon svg={SearchMagnifyingGlass} />
            </Button>
          </Tooltip>
        )}
      </div>

      <Dialog isOpen={myAccountOpenable.isOpen} onClose={myAccountOpenable.close} size="full">
        <DialogHeader divider={true}>
          <IconButton svg={ArrowBackIcon} onClick={myAccountOpenable.close} />
          <h2>{t('Account Settings')}</h2>
        </DialogHeader>
        <AccountSettings className="my-9 mx-12" />
      </Dialog>

      <AccountPreferencesDialog isOpen={preferencesOpenable.isOpen} onClose={preferencesOpenable.close} size="full" />
    </Bar>
  );
};

export default NavHeader;
