import { useCallback, useRef } from 'react';
import ICursorable from 'types/Cursorable';
import { useScrollBreakpoint } from '.';
import { ILazyLoadingState } from './useLazyLoading/useLazyLoading';
import usePagedCursorLoader from './usePagedCursorLoader';

export default function useCursorScrollingLoader<T extends ICursorable>(
  scroller: HTMLElement | HTMLDivElement | Document | null | undefined,
  state: ILazyLoadingState<T>,
  getData: (after: string | null, first: number) => Promise<T[]>,
  sectionSize: number = 20,
  scrollOffset: number = 100,
  onLoad: (data: T[], loadedAll: boolean, error: boolean) => void
) {
  const isLoading = useRef(false);
  const { getMoreData, loadAll } = usePagedCursorLoader<T>(state, getData, sectionSize);

  const onBreakpointReached = useCallback(async () => {
    if (!state.loading && !state.loadedAll && !isLoading.current) {
      isLoading.current = true;
      const result = await getMoreData();
      onLoad(result.data, result.loadedAll, result.error);
      isLoading.current = false;
    }
  }, [state.loading, state.loadedAll, getMoreData, onLoad]);

  const loadAllData = useCallback(async () => {
    if (!state.loading && !state.loadedAll && !isLoading.current) {
      const payload = await loadAll();
      const { data, loadedAll, error } = payload;
      onLoad(data, loadedAll, error);
      return payload;
    }
  }, [loadAll, onLoad, state.loadedAll, state.loading]);

  const getMore = useCallback(async () => {
    const payload = await getMoreData();
    const { data, loadedAll, error } = payload;
    onLoad(data, loadedAll, error);
    return payload;
  }, [getMoreData, onLoad]);

  useScrollBreakpoint(scroller ?? document, onBreakpointReached, scrollOffset);

  return { getMoreData: getMore, loadAll: loadAllData };
}
