import {
  ITimeEntryDataTableData,
  useTimeEntryDataTableRow,
} from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';
import MemberPermission from 'types/enum/MemberPermission';
import { isNilOrEmpty, isNilOrEmptyOrOnlyHasRemainingItem } from 'utils/collectionUtils';
import { dateTimeFromISOWithoutZone, getDateString, getDateTimesBetween } from 'utils/dateUtils';
import { timeEntryEndTimeOrNow } from 'utils/timeEntryUtils';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';

export interface IDateTimeWithId {
  id: string;
  date: DateTime;
}

export default function useDateTimeEntryDataReport(
  timeRange: ITimeRange<DateTime>,
  includeOpenEntries?: boolean,
  costCodeIds?: string[],
  equipmentIds?: string[],
  memberIds?: string[],
  projectIds?: string[],
  projectIdWithDescendants?: boolean
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITimeEntryReportWithHeaderData[]>([]);
  const titleFormatter = useCallback((date: IDateTimeWithId) => getDateString(date.date, 'ccc, LLL d', true), []);
  const getRows = useTimeEntryDataTableRow();
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();

  useEffect(() => {
    getData();
  }, [titleFormatter, timeRange, includeOpenEntries, costCodeIds, equipmentIds, memberIds, projectIds]);

  async function getData() {
    setLoading(true);
    const entries = await getTimeEntries(
      timeRange,
      includeOpenEntries,
      memberIds,
      projectIds,
      costCodeIds,
      equipmentIds,
      projectIdWithDescendants,
      undefined,
      [MemberPermission.TIME_EVENTS]
    );
    let timeOffs: ITimeOff[] = [];

    // don't include time off if we are filtering by projects, cost codes, or equipment
    if (
      isNilOrEmptyOrOnlyHasRemainingItem(projectIds) &&
      isNilOrEmptyOrOnlyHasRemainingItem(costCodeIds) &&
      isNilOrEmptyOrOnlyHasRemainingItem(equipmentIds)
    ) {
      timeOffs = await getTimeOffs(timeRange, memberIds, true, [MemberPermission.TIME_EVENTS]);
    }

    const groupedData = getDateTimesBetween(timeRange.startTime, timeRange.endTime).map((date) => {
      const filteredEntries = entries.filter(
        (entry) =>
          date.endOf('day') >= dateTimeFromISOWithoutZone(entry.startTime) &&
          date.startOf('day') <= dateTimeFromISOWithoutZone(timeEntryEndTimeOrNow(entry).toString())
      );

      const filteredTimeOffs = timeOffs.filter(
        (entry) =>
          date.endOf('day') >= dateTimeFromISOWithoutZone(entry.dateStamp) &&
          date.startOf('day') <= dateTimeFromISOWithoutZone(entry.dateStamp)
      );

      const item: ITimeEntryDataTableData<IDateTimeWithId> & ICursorable = {
        item: { id: `${date.toISO()}`, date },
        entries: filteredEntries,
        timeOffs: filteredTimeOffs,
        cursor: '',
      };
      return item;
    });
    const newData = await getRows(
      timeRange,
      groupedData.filter((item) => !isNilOrEmpty(item.entries) || !isNilOrEmpty(item.timeOffs)),
      titleFormatter,
      undefined,
      undefined,
      undefined,
      (row, item) => {
        return row.date === getDateString(item.date, 'ccc, LLL d', true);
      }
    );
    setLoading(false);
    setData(newData);
  }

  return useMemo(() => ({ data, loading, getData }), [data, loading]);
}
