import { gql } from '@apollo/client';

export const PROJECT_COST_CODE_QUANTITY_QUERY = gql`
  query QueryProjectCostCodeQuantity(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      amount
      assignedOn
      lastEditedByMember {
        id
        firstName
        lastName
      }
      costCode {
        id
        costCode
        title
        unitTitle
      }
      costCodeId
      createdOnLocal
      description
      project {
        id
        title
        depth
        projectInfo {
          id
          number
        }
        ancestors {
          id
          title
          depth
          archivedOn
          projectInfo {
            id
            number
          }
        }
      }
      projectId
      submittedByMember {
        id
        firstName
        lastName
        memberGroupId
        position {
          id
          level
          canManageProjectCostCodeQuantity
          timeEvents
        }
      }
      submittedBy
      type
      cursor
    }
  }
`;

export const PROJECT_QUANTITY_SUMMARY_QUERY = gql`
  query QueryProjectQuantitySummary(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      projectId
      project {
        id
        rootProjectId
      }
      cursor
    }
  }
`;

export const PROJECT_COST_CODE_QUANTITY_SUMMARY_QUERY = gql`
  query QueryProjectCostCodeQuantitySummary(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      amount
      projectId
      costCodeId
      costCode {
        id
        title
        costCode
      }
      cursor
    }
  }
`;

export const PROJECT_COST_CODE_QUANTITY_ID_QUERY = gql`
  query QueryProjectCostCodeQuantityId(
    $first: Int
    $after: String
    $filter: ProjectCostCodeQuantityFilter
    $sort: [ProjectCostCodeQuantitySort!]
  ) {
    projectCostCodeQuantities(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      assignedOn
      cursor
    }
  }
`;
