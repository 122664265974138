import {
  Button,
  DatePicker,
  IDatePickerPayload,
  ITimeFieldPayload,
  ITotalFieldPayload,
  Label,
  Loader,
  Position,
  Theme,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import FeatureTimeField from 'components/domain/time/FeatureTimeField/FeatureTimeField';
import TotalTimeField from 'components/foundation/TotalTimeField';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import SectionHeader from 'containers/settings/components/SectionHeader/SectionHeader';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable, useTimeRounding } from 'hooks';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ITimeEntry from 'types/TimeEntry';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { isOutsideRange } from 'utils/timeEntryBreakUtils';
import { breakTotalInRange, getEndTimeSeconds, getTotal, getTotalFromDateTimes } from 'utils/timeEntryUtils';
import { formatTime } from 'utils/timeUtils';
import { uuid } from 'utils/uuidUtils';
import useTimeActionsForm, { ITimeActionsFormData } from '../hooks/useTimeActionsForm';
import './SplitTimeEntryForm.scss';

export interface ISplitTimeEntryFormProps {
  className?: ClassName;
  timeEntry: ITimeEntry;
  isMultiDay: boolean;
  onSubmit: () => void;
}

export interface ISplitBreakObject {
  firstBreak: IBreakMultiPickerItem;
  secondBreak: IBreakMultiPickerItem;
}

function SplitTimeEntryForm(props: ISplitTimeEntryFormProps) {
  const { className, timeEntry, onSubmit, isMultiDay } = props;

  const today = DateTime.local().startOf('day');
  const [splitTime, setSplitTime] = useState<DateTime>(dateTimeFromISOKeepZone(timeEntry.endTime!));
  const [firstEntryTotalTime, setFirstEntryTotalTime] = useState<number>(0);
  const [secondEntryTotalTime, setSecondEntryTotalTime] = useState<number>(0);
  const [firstEntryFormData, setFirstEntryFormData] = useState<ITimeActionsFormData>();
  const [secondEntryFormData, setSecondEntryFormData] = useState<ITimeActionsFormData>();
  const [originalEndTime, setOriginalEndTime] = useState<DateTime>(dateTimeFromISOKeepZone(timeEntry.endTime!));
  const [originalStartTime, setOriginalStartTime] = useState<DateTime>(dateTimeFromISOKeepZone(timeEntry.startTime));
  const [timeFieldHasError, setTimeFieldHasError] = useState(false);
  const [firstEntryFieldHasError, setFirstEntryFieldHasError] = useState(false);
  const [secondEntryFieldHasError, setSecondEntryFieldHasError] = useState(false);
  const { performCreateEntry, performUpdateClosedEntry, loaderDetails } = useTimeActionsForm();
  const { roundTime } = useTimeRounding();
  const datePickerOpenable = useOpenable();
  const toast = useToastOpen();

  useEffect(() => {
    getDefaultNewEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntry]);

  function getDefaultNewEntries() {
    if (timeEntry.endTime) {
      const totalTime = getTotal(timeEntry!);
      const halfTime = Math.round(totalTime / 2 / 3600) * 3600;
      const endTimeSeconds = getEndTimeSeconds(timeEntry!);
      const splitTimeMillis = endTimeSeconds! - halfTime;
      const splitTime = roundTime(DateTime.fromSeconds(splitTimeMillis), ClockAction.CLOCK_OUT);
      const startTimeDateObject = dateTimeFromISOKeepZone(timeEntry.startTime!);
      const endTimeDateObject = dateTimeFromISOKeepZone(timeEntry.endTime!);
      setOriginalEndTime(endTimeDateObject);
      setOriginalStartTime(startTimeDateObject);
      setSplitTime(splitTime);
    }
  }

  useEffect(() => {
    if (splitTime) {
      updateTimeEntries(splitTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitTime]);

  function handleDatePickerChange(data: IDatePickerPayload) {
    const newDate = DateTime.fromJSDate(data.value!);
    let modifiedSplitTime = splitTime.set({ month: newDate.month, day: newDate.day, year: newDate.year });
    const modifiedSplitTimeSeconds = modifiedSplitTime.startOf('day').toSeconds();
    const startTimeSeconds = originalStartTime.startOf('day').toSeconds();
    const endTimeSeconds = originalEndTime.startOf('day').toSeconds();

    if (modifiedSplitTimeSeconds === startTimeSeconds && modifiedSplitTime.hour < originalStartTime.hour) {
      modifiedSplitTime = splitTime.set({
        month: newDate.month,
        day: newDate.day,
        year: newDate.year,
        hour: originalStartTime.hour + 1,
        minute: originalStartTime.minute,
      });
    } else if (modifiedSplitTimeSeconds === endTimeSeconds && modifiedSplitTime.hour > originalEndTime.hour) {
      modifiedSplitTime = splitTime.set({
        month: newDate.month,
        day: newDate.day,
        year: newDate.year,
        hour: originalEndTime.hour - 1,
        minute: originalEndTime.minute,
      });
    }

    handleTimeEntryChange(modifiedSplitTime);
  }

  function handleTimeFieldChange(data: ITimeFieldPayload) {
    handleTimeEntryChange(data.value!);
  }

  function handleTimeEntryChange(newSetTime: DateTime) {
    const startTimeSeconds = DateTime.fromISO(timeEntry.startTime).toSeconds();
    const endTimeSeconds = DateTime.fromISO(timeEntry.endTime!).toSeconds();
    if (newSetTime) {
      const newSetTimeSeconds = newSetTime.toSeconds();
      setTimeFieldHasError(newSetTimeSeconds < startTimeSeconds || newSetTimeSeconds > endTimeSeconds);
      setSplitTime(newSetTime);
    }
  }

  function splitBreak(timeEntryBreak: ITimeEntryBreak, splitTime: DateTime | null) {
    const existingBreak: IBreakMultiPickerItem = {
      id: timeEntryBreak.id,
      timeRange: {
        startTime: roundTime(dateTimeFromISOKeepZone(timeEntryBreak.startTime!), ClockAction.BREAK_START),
        endTime: roundTime(splitTime!, ClockAction.BREAK_END),
      },
    };
    const newBreak: IBreakMultiPickerItem = {
      id: uuid(),
      timeRange: {
        startTime: roundTime(splitTime!, ClockAction.BREAK_START),
        endTime: roundTime(dateTimeFromISOKeepZone(timeEntryBreak.endTime!), ClockAction.BREAK_END),
      },
    };

    const breakObj: ISplitBreakObject = { firstBreak: existingBreak, secondBreak: newBreak };

    return breakObj;
  }

  function updateTimeEntries(newSetTime: DateTime | null) {
    if (newSetTime && newSetTime > originalStartTime && newSetTime < originalEndTime) {
      const firstEntryStart = dateTimeFromISOKeepZone(timeEntry.startTime);
      const firstEntryTimeRange: ITimeRange<DateTime> = {
        startTime: firstEntryStart,
        endTime: roundTime(newSetTime, ClockAction.CLOCK_OUT),
      };

      const secondEntryEnd = dateTimeFromISOKeepZone(timeEntry.endTime!);
      const secondEntryTimeRange: ITimeRange<DateTime> = {
        startTime: roundTime(newSetTime, ClockAction.CLOCK_IN),
        endTime: secondEntryEnd,
      };

      const firstEntryBreaks: IBreakMultiPickerItem[] = [];
      const secondEntryBreaks: IBreakMultiPickerItem[] = [];

      timeEntry.breaks
        .filter((b) => isNull(b.deletedOn))
        .map((b) => {
          const breakSplit: ISplitBreakObject | null =
            dateTimeFromISOKeepZone(b.endTime!).toSeconds() > splitTime.toSeconds() &&
            dateTimeFromISOKeepZone(b.startTime).toSeconds() < splitTime.toSeconds()
              ? splitBreak(b, newSetTime)
              : null;
          const breakTimeRange: ITimeRange<DateTime> = {
            startTime: roundTime(dateTimeFromISOKeepZone(b.startTime), ClockAction.BREAK_START),
            endTime: roundTime(dateTimeFromISOKeepZone(b.endTime!), ClockAction.BREAK_END),
          };

          if (breakSplit) {
            if (!isOutsideRange(firstEntryTimeRange, [breakSplit.firstBreak.timeRange])) {
              firstEntryBreaks.push(breakSplit.firstBreak);
            } else if (!isOutsideRange(secondEntryTimeRange, [breakSplit.firstBreak.timeRange])) {
              secondEntryBreaks.push(breakSplit.firstBreak);
            }

            if (!isOutsideRange(firstEntryTimeRange, [breakSplit.secondBreak.timeRange])) {
              firstEntryBreaks.push(breakSplit.secondBreak);
            } else if (!isOutsideRange(secondEntryTimeRange, [breakSplit.secondBreak.timeRange])) {
              secondEntryBreaks.push(breakSplit.secondBreak);
            }
          } else {
            if (!isOutsideRange(firstEntryTimeRange, [breakTimeRange])) {
              firstEntryBreaks.push({ id: b.id, timeRange: breakTimeRange });
            } else if (!isOutsideRange(secondEntryTimeRange, [breakTimeRange])) {
              secondEntryBreaks.push({ id: b.id, timeRange: breakTimeRange });
            }
          }
        });

      const secondEntryTotal = getTotalFromDateTimes(secondEntryTimeRange, []);
      const firstEntryTotal = getTotalFromDateTimes(firstEntryTimeRange, []);

      const newFirstEntry: ITimeActionsFormData = {
        members: [timeEntry.memberId],
        timeRange: firstEntryTimeRange,
        startTime: dateTimeFromISOKeepZone(timeEntry?.startTime),
        endTime: newSetTime,
        startDate: {
          value: firstEntryStart.toJSDate(),
          inputValue: today.toFormat('M/D/YYYY'),
        },
        endDate: {
          value: newSetTime.toJSDate(),
          inputValue: today.toFormat('M/D/YYYY'),
        },
        breaks: firstEntryBreaks,
        project: timeEntry.project?.id,
        costCode: timeEntry.costCode?.id,
        equipment: timeEntry.equipment?.id,
        description: timeEntry.description,
        total: firstEntryTotal,
      };
      const newSecondEntry: ITimeActionsFormData = {
        members: [timeEntry.memberId],
        timeRange: secondEntryTimeRange,
        startTime: newSetTime,
        endTime: secondEntryEnd,
        startDate: {
          value: newSetTime.toJSDate(),
          inputValue: today.toFormat('M/D/YYYY'),
        },
        endDate: {
          value: secondEntryEnd.toJSDate(),
          inputValue: today.toFormat('M/D/YYYY'),
        },
        breaks: secondEntryBreaks,
        project: timeEntry.project?.id,
        costCode: timeEntry.costCode?.id,
        equipment: timeEntry.equipment?.id,
        description: timeEntry.description,
        total: secondEntryTotal,
      };

      setFirstEntryFormData(newFirstEntry);
      setSecondEntryFormData(newSecondEntry);
      setFirstEntryTotalTime(firstEntryTotal);
      setSecondEntryTotalTime(secondEntryTotal);
    }
  }

  function resetEntryFieldErrors() {
    setFirstEntryFieldHasError(false);
    setSecondEntryFieldHasError(false);
  }

  function handleEntryTotalTimeChange(time: ITotalFieldPayload, isFirstEntry?: boolean) {
    resetEntryFieldErrors();
    const totalTime = getTotal(timeEntry!);
    if (time.isValid && time.value! > totalTime) {
      isFirstEntry ? setFirstEntryFieldHasError(true) : setSecondEntryFieldHasError(true);
      toast({ label: t('New entries must be shorter than existing entry'), theme: Theme.DANGER });
      getDefaultNewEntries();
    } else {
      const startTimeSeconds = DateTime.fromISO(timeEntry.startTime).toSeconds();
      const endTimeSeconds = getEndTimeSeconds(timeEntry!);
      const splitTimeMillis = isFirstEntry ? startTimeSeconds + time.value! : endTimeSeconds! - time.value!;
      const newSplitTime = DateTime.fromSeconds(splitTimeMillis);
      setSplitTime(newSplitTime);
    }
  }

  async function createEntryFromForm(data: ITimeActionsFormData) {
    await performCreateEntry(data, onSubmit, timeEntry.daylightSavingTime, timeEntry.metaDaylightSavingTime, true);
  }

  async function updateEntryFromForm(data: ITimeActionsFormData) {
    await performUpdateClosedEntry(data, timeEntry, onSubmit);
  }

  function handleSubmit() {
    if (timeFieldHasError) {
      toast({ label: t('Split time must be within the existing time entry'), theme: Theme.DANGER });
    } else if (firstEntryFieldHasError || secondEntryFieldHasError) {
      toast({ label: t('Could not split entry. Check your new entry totals and try again.'), theme: Theme.DANGER });
    } else {
      updateEntryFromForm(firstEntryFormData!);
      createEntryFromForm(secondEntryFormData!);
      onSubmit();
    }
  }

  const firstEntryHasBreaks = firstEntryFormData?.breaks && firstEntryFormData?.breaks.length > 0;
  const secondEntryHasBreaks = secondEntryFormData?.breaks && secondEntryFormData?.breaks.length > 0;

  const classes = classNames('split-time-entry-form', className);

  return (
    <div className={classes}>
      <div className={'p-5'}>
        <Label>{t('Split Time')}</Label>
        <span>
          {t(
            'Select where you would like to split the entry. The time must be between the start and end times of the entry you are splitting.'
          )}
        </span>
        <Loader isOpen={loaderDetails.isOpen} />
        <div className={'form py-5'}>
          <div className={'split-time-selectors'}>
            <div className={'split-time-time-field mr-3'}>
              <FeatureTimeField
                value={splitTime}
                onChange={handleTimeFieldChange}
                clockAction={ClockAction.CLOCK_OUT}
                error={timeFieldHasError}
              />
            </div>
            {isMultiDay && (
              <div className={'split-time-date-picker'}>
                <DatePicker
                  value={splitTime.toJSDate()}
                  isOpen={datePickerOpenable.isOpen}
                  onOpen={datePickerOpenable.open}
                  onClose={datePickerOpenable.close}
                  disabledAfter={originalEndTime.toJSDate()}
                  disabledBefore={originalStartTime.toJSDate()}
                  onChange={handleDatePickerChange}
                  position={Position.BOTTOM_END}
                />
              </div>
            )}
          </div>
          <SectionHeader className={'mt-8 mb-5'}>{t('New Entry Totals')}</SectionHeader>
          <DashboardRibbon className={'new-entries mb-'} theme={Theme.LIGHT}>
            <div>
              <Label>
                {t('Entry #1')} {firstEntryHasBreaks && '*'}{' '}
              </Label>

              <TotalTimeField
                value={firstEntryTotalTime}
                className="total-time-picker-item"
                onChange={(time) => handleEntryTotalTimeChange(time, true)}
                maxHours={23}
                error={firstEntryFieldHasError}
              />
              <span className={'new-entry-range'}>
                <TimeAndSuperscript
                  dateTime={dateTimeFromISOKeepZone(firstEntryFormData?.startTime?.toISO() ?? '')}
                  dst={timeEntry.daylightSavingTime}
                />
                {isMultiDay &&
                  ` (${dateTimeFromISOKeepZone(firstEntryFormData?.startTime?.toISO() ?? '').toFormat('LLL dd')})`}
                {' - '}
                <TimeAndSuperscript
                  dateTime={dateTimeFromISOKeepZone(firstEntryFormData?.endTime?.toISO() ?? '')}
                  dst={timeEntry.daylightSavingTime}
                />
                {isMultiDay &&
                  ` (${dateTimeFromISOKeepZone(firstEntryFormData?.endTime?.toISO() ?? '').toFormat('LLL dd')})`}
                {firstEntryHasBreaks && (
                  <>
                    <span className={'px-2'}>
                      {'(-'}
                      {formatTime({
                        type: 'TIME',
                        seconds: breakTotalInRange(
                          {
                            startTime: dateTimeFromISOKeepZone(firstEntryFormData?.startTime?.toISO() ?? ''),
                            endTime: dateTimeFromISOKeepZone(firstEntryFormData?.endTime?.toISO() ?? ''),
                          },
                          timeEntry
                        ),
                      })}
                      {')'}
                    </span>
                  </>
                )}
              </span>

              <Label>
                {t('Entry #2')} {secondEntryHasBreaks && '*'}
              </Label>
              <TotalTimeField
                value={secondEntryTotalTime}
                className="total-time-picker-item"
                onChange={(time) => handleEntryTotalTimeChange(time, false)}
                maxHours={23}
                error={secondEntryFieldHasError}
              />
              <span className={'new-entry-range'}>
                <TimeAndSuperscript
                  dateTime={dateTimeFromISOKeepZone(secondEntryFormData?.startTime?.toISO() ?? '')}
                  dst={timeEntry.daylightSavingTime}
                />
                {isMultiDay &&
                  ` (${dateTimeFromISOKeepZone(secondEntryFormData?.startTime?.toISO() ?? '').toFormat('LLL dd')})`}
                {' - '}
                <TimeAndSuperscript
                  dateTime={dateTimeFromISOKeepZone(secondEntryFormData?.endTime?.toISO() ?? '')}
                  dst={timeEntry.daylightSavingTime}
                />
                {isMultiDay &&
                  ` (${dateTimeFromISOKeepZone(secondEntryFormData?.endTime?.toISO() ?? '').toFormat('LLL dd')})`}
                {secondEntryHasBreaks && (
                  <>
                    <span className={'px-2'}>
                      {'(-'}
                      {formatTime({
                        type: 'TIME',
                        seconds: breakTotalInRange(
                          {
                            startTime: dateTimeFromISOKeepZone(secondEntryFormData?.startTime?.toISO() ?? ''),
                            endTime: dateTimeFromISOKeepZone(secondEntryFormData?.endTime?.toISO() ?? ''),
                          },
                          timeEntry
                        ),
                      })}
                      {')'}
                    </span>
                  </>
                )}
              </span>
            </div>
          </DashboardRibbon>

          {timeEntry.breaks.length > 0 && (
            <div className={'break-disclaimer mt-5'}>
              {t(
                '* Entry will contain one or more breaks. This will cause the total time of the entry to be less than what is currently being shown in the form.'
              )}
            </div>
          )}
          <div>
            <Button onClick={handleSubmit} type="primary" className={'mt-5'}>
              {t('Submit')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplitTimeEntryForm;
