import gql from 'graphql-tag';

export const MEMBER_LOCATIONS_QUERY = gql`
  query queryMemberLocations($filter: MemberLocationFilter, $sort: [MemberLocationSort!], $first: Int, $after: String) {
    memberLocations(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      memberId
      longitude
      latitude
      horizontalAccuracy
      altitude
      verticalAccuracy
      timeGmt
      timeLocal
      timeDst
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      member {
        id
        firstName
        lastName
      }
    }
  }
`;

export const MEMBER_LOCATION_QUERY = gql`
  query queryMemberLocation($memberLocationId: Uuid!) {
    memberLocations(filter: { id: { equal: $memberLocationId } }) {
      id
      memberId
      longitude
      latitude
      horizontalAccuracy
      altitude
      verticalAccuracy
      timeGmt
      timeLocal
      timeDst
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      member {
        id
        firstName
        lastName
      }
    }
  }
`;
