import { Theme } from '@busybusy/webapp-react-ui';
import Typography from 'components/foundation/text/Typography/Typography';
import { useDashboardSettings } from 'containers/dashboard/context/DashboardSettingsContext';
import { DashboardSettingKey } from 'containers/dashboard/types/types';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import DailyProjectReportCard from 'containers/project-dashboard/DailyProjectReportCard/DailyProjectReportCard';
import { useActiveMember } from 'hooks';
import sortBy from 'lodash/sortBy';
import { ReportType } from 'utils/aggregateUtils';
import { useFeatureFlags } from 'utils/features';
import ActiveCostCodesDashboardCard from '../card/active-cost-code-dashboard-card/ActiveCostCodesDashboardCard/ActiveCostCodesDashboardCard';
import ActiveEquipmentDashboardCard from '../card/active-equipment-dashboard-card/ActiveEquipmentDashboardCard/ActiveEquipmentDashboardCard';
import ActiveMembersMainDashboardCard from '../card/active-member-dashboard-card/ActiveMembersMainDashboardCard/ActiveMembersMainDashboardCard';
import ActiveProjectsDashboardCard from '../card/active-project-dashboard-card/ActiveProjectsDashboardCard/ActiveProjectsDashboardCard';
import AggregateCostDashboardCard from '../card/AggregateCostDashboardCard/AggregateCostDashboardCard';
import AggregateHoursDashboardCard from '../card/AggregateHoursDashboardCard/AggregateHoursDashboardCard';
import BudgetsDashboardCard from '../card/BudgetsDashboardCard/BudgetsDashboardCard';
import DailySignatureAlertsCard from '../card/DailySignatureAlertsCard/DailySignatureAlertsCard';
import DailySignInCard from '../card/DailySignInCard/DailySignInCard';
import DashboardClockStatusContainer from '../card/DashboardClockStatusContainer/DashboardClockStatusContainer';
import EquipmentAggregateDashboardCard from '../card/EquipmentAggregateDashboardCard/EquipmentAggregateDashboardCard';
import EquipmentHoursDashboardCard from '../card/EquipmentHoursDashboardCard/EquipmentHoursDashboardCard';
import PhotosAndNotesDashboardCard from '../card/PhotoAndNotesDashboardCard/PhotosAndNotesDashboardCard';
import PhotoVerificationDashboardCard from '../card/PhotoVerificationDashboardCard/PhotoVerificationDashboardCard';
import { PremiumTrialCard } from '../card/PremiumTrialCard/PremiumTrialCard';
import ProgressEntriesDashboardCard from '../card/ProgressEntriesDashboardCard/ProgressEntriesDashboardCard';
import TimeLogFeedDashboardCard from '../card/TimeLogFeedDashboardCard/TimeLogFeedDashboardCard';
import TimeOffDashboardRibbonGroup from '../ribbon-group/time-off-dashboard-ribbon-group/TimeOffDashboardRibbonGroup';

function DashboardGridContent() {
  const { settings, hide } = useDashboardSettings();
  const isPremiumTrial = useFeatureFlags('PREMIUM_TRIAL');
  const activeMember = useActiveMember();

  function curriedHide(key: DashboardSettingKey) {
    return () => hide(key);
  }

  const cardArray = sortBy(Object.values(settings), (s) => s.position)
    .filter((s) => s.visible)
    .map((setting) => {
      const label = setting.options?.time ? getDashboardTimeFrameTitle(setting.title, setting.options.time) : null;

      switch (setting.key as DashboardSettingKey) {
        case 'clockStatus':
          return <DashboardClockStatusContainer key={setting.key} />;
        case 'laborHours':
          return (
            <AggregateHoursDashboardCard
              key={setting.key}
              timeFrame={setting.options?.time ?? 'payPeriod'}
              label={label}
              theme={Theme.DARK}
              dataKey={'total'}
            />
          );
        case 'laborCosts':
          return (
            <AggregateCostDashboardCard
              key={setting.key}
              timeFrame={setting.options?.time ?? 'payPeriod'}
              label={label}
              theme={Theme.DARK}
              dataKey={'total'}
            />
          );
        case 'overtimeHours':
          return (
            <AggregateHoursDashboardCard
              key={setting.key}
              timeFrame={setting.options?.time ?? 'payPeriod'}
              theme={Theme.DARK}
              label={label}
              dataKey={'overtime'}
            />
          );
        case 'overtimeCosts':
          return (
            <AggregateCostDashboardCard
              key={setting.key}
              timeFrame={setting.options?.time ?? 'payPeriod'}
              theme={Theme.DARK}
              label={label}
              dataKey={'overtime'}
            />
          );
        case 'doubleTimeHours':
          return (
            <AggregateHoursDashboardCard
              key={setting.key}
              timeFrame={setting.options?.time ?? 'payPeriod'}
              theme={Theme.DARK}
              label={label}
              dataKey={'doubletime'}
            />
          );
        case 'doubleTimeCosts':
          return (
            <AggregateCostDashboardCard
              key={setting.key}
              timeFrame={setting.options?.time ?? 'payPeriod'}
              theme={Theme.DARK}
              label={label}
              dataKey={'doubletime'}
            />
          );
        case 'dailySignIn':
          return (
            <DailySignInCard
              key={setting.key}
              setting={setting}
              theme={Theme.DARK}
              onHide={curriedHide('dailySignIn')}
            />
          );
        case 'dailySignatureAlerts':
          return (
            <DailySignatureAlertsCard
              key={setting.key}
              setting={setting}
              theme={Theme.DARK}
              onHide={curriedHide('dailySignatureAlerts')}
            />
          );
        case 'hourMeter':
          return (
            <EquipmentHoursDashboardCard
              key={setting.key}
              theme={Theme.DARK}
              setting={settings.hourMeter}
              onHide={curriedHide('hourMeter')}
            />
          );
        case 'equipmentHours':
          return (
            <EquipmentAggregateDashboardCard
              key={setting.key}
              theme={Theme.DARK}
              setting={settings.equipmentHours}
              onHide={curriedHide('equipmentHours')}
              dataKey={'total'}
              type={ReportType.SECONDS}
            />
          );
        case 'equipmentCosts':
          return (
            <EquipmentAggregateDashboardCard
              key={setting.key}
              theme={Theme.DARK}
              setting={settings.equipmentCosts}
              onHide={curriedHide('equipmentCosts')}
              dataKey={'total'}
              type={ReportType.COST}
            />
          );
        case 'photosAndNotes':
          return (
            <PhotosAndNotesDashboardCard
              key={setting.key}
              setting={settings.photosAndNotes}
              theme={Theme.DARK}
              onHide={curriedHide('photosAndNotes')}
              photoLimit={8}
            />
          );
        case 'dailyReports':
          return (
            <DailyProjectReportCard
              key={setting.key}
              setting={settings.dailyReports}
              theme={Theme.DARK}
              onHide={curriedHide('dailyReports')}
            />
          );
        case 'progressEntriesReport':
          return (
            <ProgressEntriesDashboardCard
              key={setting.key}
              setting={settings.progressEntriesReport}
              theme={Theme.DARK}
              onHide={curriedHide('progressEntriesReport')}
            />
          );
        case 'budgets':
          return (
            <BudgetsDashboardCard
              key={setting.key}
              setting={settings.budgets}
              theme={Theme.DARK}
              onHide={curriedHide('budgets')}
            />
          );
        case 'photoVerification':
          return (
            <PhotoVerificationDashboardCard
              key={setting.key}
              setting={settings.photoVerification}
              theme={Theme.DARK}
              onHide={curriedHide('photoVerification')}
            />
          );
        case 'mostActiveEmployees':
          return <ActiveMembersMainDashboardCard key={setting.key} theme={Theme.DARK} setting={setting} />;
        case 'mostActiveCostCodes':
          return (
            <ActiveCostCodesDashboardCard
              key={setting.key}
              theme={Theme.DARK}
              setting={setting}
              onHide={curriedHide('mostActiveCostCodes')}
            />
          );
        case 'mostActiveEquipment':
          return (
            <ActiveEquipmentDashboardCard
              key={setting.key}
              theme={Theme.DARK}
              setting={setting}
              onHide={curriedHide('mostActiveEquipment')}
            />
          );
        case 'mostActiveProjects':
          return (
            <ActiveProjectsDashboardCard
              key={setting.key}
              theme={Theme.DARK}
              setting={setting}
              onHide={curriedHide('mostActiveProjects')}
            />
          );
        case 'timeOff':
          return <TimeOffDashboardRibbonGroup theme={Theme.DARK} setting={setting} key={setting.key} />;
        case 'timeLogs':
          return <TimeLogFeedDashboardCard theme={Theme.DARK} setting={setting} key={setting.key} />;
        default:
          return (
            <div key={setting.key}>
              <Typography tag="p" color="white">
                {JSON.stringify(setting)}
              </Typography>
            </div>
          );
      }
    });

  if (isPremiumTrial && activeMember.position?.manageCompanySettings) {
    const cardArrayWithPremiumTrial = [cardArray[0], <PremiumTrialCard key={'premium-trial'} />, ...cardArray.slice(1)];

    return <>{cardArrayWithPremiumTrial}</>;
  } else {
    return <>{cardArray}</>;
  }
}

export default DashboardGridContent;
