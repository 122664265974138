import { Button, Icon, Tooltip } from '@busybusy/webapp-react-ui';

export interface IPhotoActionButtonProps {
  tooltip: string;
  svg: any;
  onClick?: () => void;
}

const PhotoActionButton: React.FunctionComponent<IPhotoActionButtonProps> = (props) => {
  const { tooltip, svg, onClick } = props;
  return (
    <Tooltip label={tooltip}>
      <Button type="icon" onClick={onClick}>
        <Icon svg={svg} />
      </Button>
    </Tooltip>
  );
};

export default PhotoActionButton;
