import { Theme } from '@busybusy/webapp-react-ui';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { usePayPeriodTimeRangeGet, useWeeklyTimeRangeGet } from 'hooks';
import { DateTime } from 'luxon';
import { XAxisProps } from 'recharts';
import { IDashboardChartPayload } from '../types/types';
import { getDefaultAxisProps } from '../utils/utils';

export default function useDashboardXAxisDefaults<T extends IDashboardChartPayload>(
  data: T[],
  timeFrame: DashboardTimeFrame,
  theme: Theme.DARK | Theme.LIGHT
): XAxisProps {
  const weeklyRangeGet = useWeeklyTimeRangeGet();
  const payPeriodRangeGet = usePayPeriodTimeRangeGet();

  const anyDay = data[0];

  const range = data.reduce(
    (acc, cur) => {
      const newRange = { ...acc };

      // If there are items in the list this will be defined and if we're here there are items in the list
      if (cur.day < acc.startTime!) {
        newRange.startTime = cur.day;
      }

      if (cur.day > acc.endTime!) {
        newRange.endTime = cur.day;
      }

      return newRange;
    },
    { startTime: anyDay?.day, endTime: anyDay?.day }
  );

  const xTicks = range.startTime && range.endTime ? [range.startTime, range.endTime] : [];

  function tickFormatter(tickItem: number, _index: number) {
    switch (timeFrame) {
      case 'monthly':
        return DateTime.fromSeconds(tickItem, { zone: 'utc' }).toFormat('MMM');
      case 'pastSeven':
      case 'pastFourteen':
      case 'daily':
        return DateTime.fromSeconds(tickItem, { zone: 'utc' }).toFormat('L/d');
      case 'payPeriod': {
        const payPeriodRange = payPeriodRangeGet(tickItem);
        return `${payPeriodRange.startTime.toFormat('L/d')} - ${payPeriodRange.endTime.toFormat('L/d')}`;
      }
      case 'weekly': {
        const weeklyRange = weeklyRangeGet(tickItem);
        return `${weeklyRange.startTime.toFormat('L/d')} - ${weeklyRange.endTime.toFormat('L/d')}`;
      }
    }
  }

  return {
    dataKey: 'day',
    tickMargin: 15,
    interval: 'preserveStartEnd',
    ticks: xTicks,
    tickFormatter,
    ...getDefaultAxisProps(theme),
  };
}
