import _, { isEmpty, isNil } from 'lodash';
import RequireTimeEntryGps from 'types/enum/RequireTimeEntryGps';
import { Nullable } from 'types/util/Nullable';
import { t } from 'utils/localize';
import { Project } from '__generated__/graphql';
import IProject from '../types/Project';

export interface IFormattableProject {
  ancestors?: Nullable<Array<{ depth: number; title: string }>>;
  title: string;
  depth: number;
}

// Format a project path based on an IProject with a parents property
export function getFormattedPathFromProject(project?: Nullable<IFormattableProject>, includeProject: boolean = false) {
  let label = '';

  if (project && project.ancestors && project.title) {
    const sorted = _.sortBy([...project.ancestors], 'depth');

    sorted.forEach((parent, index) => {
      label += `${parent.title}`;
      label += index < sorted.length - 1 ? ' / ' : '';
    });

    if (includeProject) {
      label = label ? (label += ' / ') : '';
      label += project.title;
    }

    if (label.length === 0) {
      label = project.title;
    }
  }

  return label;
}

export function getFormattedPathFromProjectWithFallback(
  project?: IFormattableProject,
  includeProject: boolean = false,
  fallback: string = t('No Project')
) {
  const path = getFormattedPathFromProject(project, includeProject);
  if (isEmpty(path)) {
    return fallback;
  }

  return path;
}

export function getProjectAddressString(project: IProject) {
  let str = '';
  const info = project.projectInfo;

  if (info) {
    str += info.address1 ? info.address1 + ' ' : '';
    str += info.address2 ? info.address2 + ' ' : '';
    str += info.city ? info.city + ' ' : '';
    str += info.state ? info.state + ' ' : '';
    str += info.postalCode ? info.postalCode : '';
  }

  return str.trim();
}

export function getRequireTimeEntryGpsLabel(value: RequireTimeEntryGps | null) {
  switch (value) {
    case RequireTimeEntryGps.SELF:
      return t('On');
    case RequireTimeEntryGps.SELF_AND_CHILDREN:
      return t('On - Apply to Subprojects');
    default:
      return t('Off');
  }
}

export function getSelfAndChildrenRecursively(project: IProject): IProject[] {
  const children = project.children ?? [];
  const projectsToReturn: IProject[] = [project];
  return projectsToReturn.concat(children.flatMap((project) => getSelfAndChildrenRecursively(project)));
}

export function hasActiveSubProjects(project: IProject) {
  if (project.children) {
    return project.children.filter((child) => _.isNil(child.archivedOn)).length > 0;
  }

  return false;
}

export function hasActiveSubProjectsWithTime(project: IProject) {
  if (project.children) {
    const hasActiveChildren = project.children.filter((child) => _.isNil(child.archivedOn)).length > 0;
    const hasChildrenWithTime = getFirstSetProjectMetrics(project)?.some(
      (metric) => (metric.descRt ?? 0) + (metric.descOt ?? 0) + (metric.descDt ?? 0) > 0
    );

    return hasActiveChildren && hasChildrenWithTime;
  }

  return false;
}

export const rootProjectHasGpsRequirement = (project: IProject) => {
  const requireGpsProp = project.rootProject.projectInfo?.requireTimeEntryGps;
  if (!isNil(requireGpsProp)) {
    return requireGpsProp;
  }
  return RequireTimeEntryGps.NONE;
};

export const projectHasGpsRequirement = (project: IProject) => {
  const requireGpsProp = project.projectInfo?.requireTimeEntryGps;
  if (!isNil(requireGpsProp)) {
    switch (requireGpsProp) {
      case RequireTimeEntryGps.SELF_AND_CHILDREN:
        return true;
      case RequireTimeEntryGps.SELF:
        return true;
      case RequireTimeEntryGps.CHILDREN:
        return false;
      case RequireTimeEntryGps.NONE:
        return false;
    }
  }
  return false;
};

function getFirstSetProjectMetrics(project: IProject) {
  return (
    project.projectLaborMetrics ??
    project.projectMemberLaborMetrics ??
    project.projectCostCodeLaborMetrics ??
    project.projectEquipmentLaborMetrics
  );
}

export function generatedHasActiveSubProjectsWithTime(project: Project) {
  if (project.children) {
    const hasActiveChildren = project.children.filter((child) => _.isNil(child.archivedOn)).length > 0;
    const hasChildrenWithTime = generatedGetFirstSetProjectMetrics(project)?.some(
      (metric) => (metric.descRt ?? 0) + (metric.descOt ?? 0) + (metric.descDt ?? 0) > 0
    );

    return hasActiveChildren && hasChildrenWithTime;
  }

  return false;
}

function generatedGetFirstSetProjectMetrics(project: Project) {
  return (
    project.projectLaborMetrics ??
    project.projectMemberLaborMetrics ??
    project.projectCostCodeLaborMetrics ??
    project.projectEquipmentLaborMetrics ??
    project.projectCostCodeEquipmentLaborMetrics ??
    project.projectMemberEquipmentLaborMetrics ??
    project.projectMemberCostCodeLaborMetrics
  );
}

export function projectHasChildren(project: { children?: Nullable<any[]> }) {
  return !!project.children && project.children.length > 0;
}
