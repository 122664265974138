import { Icon, ITextFormFieldProps, TextFormField } from '@busybusy/webapp-react-ui';
import { DollarIcon } from 'assets/icons';
import { useMemo } from 'react';
import { AnyObject } from 'types/util/Object';
import { v_money, v_numbers_only } from 'utils/validations';
import v_positive from 'utils/validations/v_positive';

type ICurrencyFormFieldProps<
  FormType extends {
    [field: string]: any;
  } = AnyObject
> = ITextFormFieldProps<FormType>;

const fieldValidation = [{ validation: v_numbers_only }, { validation: v_positive }, { validation: v_money }];

// TODO Would be good to eventually have a currencyCode: icon map to put instead of the dollar icon.
export default function CurrencyFormField<
  FormType extends {
    [field: string]: any;
  } = AnyObject
>({ validations, ...textFieldProps }: ICurrencyFormFieldProps<FormType>) {
  const combinedValidations = useMemo(() => [...fieldValidation, ...(validations ?? [])], [validations]);
  return (
    <TextFormField
      {...textFieldProps}
      validations={combinedValidations}
      iconLeft={<Icon svg={DollarIcon} theme="light-gray" />}
    />
  );
}
