export enum PayPeriodType {
  WEEKLY = 10,
  BIWEEKLY = 20,
  SEMIMONTHLY = 30,
  MONTHLY = 40,
}

export enum PayPeriodTypeValue {
  WEEKLY = 'Weekly',
  BIWEEKLY = 'Biweekly',
  SEMIMONTHLY = 'Semimonthly',
  MONTHLY = 'Monthly',
}
