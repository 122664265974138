import gql from 'graphql-tag';

export const CREATE_MEMBER_SIGN_IN_SUBMISSION = gql`
  mutation CreateMemberSignInSubmission($submission: MemberSignInSubmissionCreateInput) {
    createMemberSignInSubmission(submission: $submission) {
      id
    }
  }
`;

export const UPDATE_MEMBER_SIGN_IN_SUBMISSION = gql`
  mutation UpdateMemberSignInSubmission($submission: MemberSignInSubmissionUpdateInput) {
    updateMemberSignInSubmission(submission: $submission) {
      id
    }
  }
`;
