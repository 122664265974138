import {
  Button,
  DatePickerFormField,
  Form,
  IFormRender,
  Label,
  Loader,
  TextareaFormField,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import EmployeeMultiPickerFormField from 'components/domain/member/EmployeeMultiPickerFormField/EmployeeMultiPickerFormField';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import FeatureTimeFormField from 'components/domain/time/FeatureTimeFormField/FeatureTimeFormField';
import { useActiveMember, useOpenable, useTimeRounding } from 'hooks';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { combineDateAndTime, combineDateWithTime, dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { v_require, v_time_before_time } from 'utils/validations';
import TimeActionsValidations from '../TimeActionsValidations/TimeActionsValidations';
import useTimeActionsValidations from '../TimeActionsValidations/hooks/useTimeActionsValidations';
import TimeActionFormDetailsSection from '../section/TimeActionFormDetailsSection/TimeActionFormDetailsSection';
import './ClockInAtForm.scss';
import { convertTimeActionFormNoSelectionsToNull } from '../utils/utils';

export interface IClockInAtFormProps {
  className?: ClassName;
  memberIds: string[];
  formData: ITimeActionsFormData;
  onSubmit: (data: ITimeActionsFormData) => void;
  onChange?: (formData: ITimeActionsFormData | undefined) => void;
}

const ClockInAtForm = (props: IClockInAtFormProps) => {
  const { className, memberIds, formData, onSubmit, onChange } = props;

  const classes = classNames('clock-in-at-form', className);

  const today = DateTime.local().startOf('day');
  const timeEntryRange = useRef<ITimeRange<DateTime> | undefined>();
  const dateDetails = useOpenable();
  const { performClockInAt, loaderDetails } = useTimeActionsForm();
  const [formInfo, setFormInfo] = useState<ITimeActionsFormData>(formData);
  const lockDialogDetails = useOpenable();
  const conflictDialogDetails = useOpenable();
  const signatureViolationDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeActionsFormData>();
  const {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
  } = useTimeActionsValidations();
  const { roundTime } = useTimeRounding();
  const activeMember = useActiveMember();

  useEffect(() => {
    inhibitsActions(formInfo);
    conflictWarning({
      ...formInfo,
      timeRange: { startTime: formInfo.startTime, endTime: formInfo.startTime },
    });
    signatureWarning(formInfo);
  }, [formInfo, memberIds]);

  function renderFormFields(form: IFormRender<ITimeActionsFormData>) {
    const isActiveMemberOnlyMember =
      form.state.data.members.length === 1 && form.state.data.members[0] === activeMember.id;

    return (
      <div>
        {renderEmployeesPicker(form)}
        {renderClockInAtDateTime(form)}
        <TimeActionFormDetailsSection
          form={form}
          onFormChange={setFormInfo}
          considerEquipmentRequirements={isActiveMemberOnlyMember}
        />
        {renderDescription(form)}
        {renderActionButtons(form)}
      </div>
    );
  }

  function renderEmployeesPicker({ ...form }: IFormRender<ITimeActionsFormData>) {
    function getPermissions() {
      return [MemberPermission.MANAGE_TIME_ENTRIES];
    }

    return (
      <div>
        <Label>{t('Employees')}</Label>
        <EmployeeMultiPickerFormField
          name="members"
          form={form}
          permissions={{
            permissions: getPermissions(),
            operationType: OperationType.AND,
          }}
          validations={[{ validation: v_require }]}
          includeClockedIn={false}
        />
      </div>
    );
  }

  function renderClockInAtDateTime({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        <Label>{t('Start Time')}</Label>
        <div className="date-pickers-container mr-4">
          <div className="mr-4">
            <FeatureTimeFormField
              name="startTime"
              clockAction={ClockAction.CLOCK_IN}
              form={form}
              validations={[
                { validation: v_require },
                {
                  validation: v_time_before_time,
                  args: {
                    time: roundTime(DateTime.local(), ClockAction.CLOCK_IN),
                    inclusive: true,
                    message: 'Invalid time',
                  },
                },
              ]}
              onChange={(value) => {
                if (!isNil(value)) {
                  setFormInfo({
                    ...form.state.data,
                    startTime: dateTimeFromISOKeepZone(value.toString()),
                    timeRange: {
                      startTime: combineDateAndTime(
                        DateTime.fromJSDate(form.state.data.startDate.value!),
                        dateTimeFromISOKeepZone(value.toString())
                      ),
                      endTime: form.state.data.timeRange.endTime,
                    },
                  });
                }
              }}
            />
          </div>
          <DatePickerFormField
            name="startDate"
            form={form}
            isOpen={dateDetails.isOpen}
            disabledBefore={today.minus({ day: 1 }).toJSDate()}
            disabledAfter={today.toJSDate()}
            onOpen={dateDetails.open}
            onClose={dateDetails.close}
            validations={[{ validation: v_require }]}
            onChange={(payload) => {
              if (payload.value) {
                setFormInfo({
                  ...form.state.data,
                  startDate: payload,
                  startTime: combineDateWithTime(DateTime.fromJSDate(payload.value), form.state.data.startTime!),
                  timeRange: {
                    startTime: combineDateWithTime(DateTime.fromJSDate(payload.value), form.state.data.startTime!),
                    endTime: form.state.data.timeRange.endTime,
                  },
                });
              }
            }}
          />
        </div>
        {renderConflictErrorFooter(form)}
      </div>
    );
  }

  function renderConflictErrorFooter({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="pt-2 pb-4">
        {form.state.data.startDate.value && hasConflictingEntries && (
          <Label className="conflicts-warning">{t('This entry conflicts with another entry.')}</Label>
        )}
      </div>
    );
  }

  function renderDescription({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        <Label secondaryLabel={t('Optional')}>{t('Description')}</Label>
        <TextareaFormField name="description" form={form} restrictTo={{ maxLength: 5000 }} />
      </div>
    );
  }

  function renderActionButtons({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="pt-6">
        <Button onClick={form.handleSubmit} type="primary">
          {t('Submit')}
        </Button>
      </div>
    );
  }

  async function handleSubmit(data: ITimeActionsFormData) {
    if (
      (checkValidationsPayload.current.lock && hasLockDate) ||
      (checkValidationsPayload.current.conflict && hasConflictingEntries) ||
      (checkValidationsPayload.current.signature && hasSignatureViolation)
    ) {
      preValidationFormData.current = data;
      launchValidationAlertsOrSave();
      return;
    }

    if (loaderDetails.isOpen) {
      // Action is already being performed.
      return;
    }

    loaderDetails.open();

    if (data) {
      clockInAtFromForm(data);
    } else {
      onSubmit(data);
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onConflictContinue() {
    setHasConflictingEntries(false);
    checkValidationsPayload.current.conflict = false;
    conflictDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onSignatureViolationContinue() {
    setHasSignatureViolation(false);
    checkValidationsPayload.current.signature = false;
    signatureViolationDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function launchValidationAlertsOrSave() {
    // lock date

    if (checkValidationsPayload.current.lock && hasLockDate && preValidationFormData.current) {
      const start =
        preValidationFormData.current.startDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.startDate.value!);
      const end =
        preValidationFormData.current.endDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.endDate.value!);
      timeEntryRange.current = {
        startTime: preValidationFormData.current.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        endTime: preValidationFormData.current.timeRange.endTime!.set({
          year: end.year,
          month: end.month,
          day: end.day,
        }),
      };
      lockDialogDetails.open();
      return;
    }

    // conflicts alert

    if (checkValidationsPayload.current.conflict && hasConflictingEntries) {
      conflictDialogDetails.open();
      return;
    }

    // signatures alert

    if (checkValidationsPayload.current.signature && hasSignatureViolation) {
      signatureViolationDialogDetails.open();
      return;
    }
  }

  async function clockInAtFromForm(data: ITimeActionsFormData) {
    await performClockInAt(convertTimeActionFormNoSelectionsToNull(data), onSubmit);
    loaderDetails.close();
  }

  return (
    <>
      <div>
        <div className="px-8 pb-8">
          <div className="mb-6">
            <Form
              data={formInfo}
              onSubmit={handleSubmit}
              render={renderFormFields}
              className={classes}
              allowMultipleSubmissions={true}
              onChange={onChange}
            />
            <Loader isOpen={loaderDetails.isOpen} />
          </div>
        </div>
      </div>
      <TimeActionsValidations
        lockDate={timeEntryRange.current ? timeEntryRange.current.startTime : undefined}
        lockMemberIds={formInfo.members}
        lockDateType={LockDateDialogType.ADD}
        isLockDetailsOpen={lockDialogDetails.isOpen}
        onLockDetailsClose={lockDialogDetails.close}
        onMoveLockDate={onMoveLockDate}
        isConflictDetailsOpen={conflictDialogDetails.isOpen}
        onConflictDetailsClose={conflictDialogDetails.close}
        onConflictContinue={onConflictContinue}
        isSignatureViolationDetailsOpen={signatureViolationDialogDetails.isOpen}
        onSignatureViolationDetailsClose={signatureViolationDialogDetails.close}
        onSignatureViolationContinue={onSignatureViolationContinue}
      />
    </>
  );
};

ClockInAtForm.defaultProps = {
  memberIds: [],
};

export default ClockInAtForm;
