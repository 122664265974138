import { Accordion, Button, Icon, Menu, Position, Size, Tooltip } from '@busybusy/webapp-react-ui';
import { ExpandIconClosed, ExpandIconOpen } from 'assets/icons';
import classNames from 'classnames';
import SectionHeader from 'containers/settings/components/SectionHeader/SectionHeader';
import { useOpenable } from 'hooks';
import { Fragment } from 'react';
import EmberNavItem from '../EmberNavItem/EmberNavItem';
import NavItem from '../NavItem/NavItem';
import { INavBarChildLink, INavBarParentLink } from '../types/types';

export interface INavAccordionProps {
  parent: INavBarParentLink;
  links: INavBarChildLink[];
  isMin: boolean;
}

const NavAccordion = (props: INavAccordionProps) => {
  const { parent, links, isMin } = props;

  const pathMatchesParent = parent.matches();
  const accordionDetails = useOpenable({ isOpen: pathMatchesParent });
  const triggerWrapperClass = classNames('nav-item', 'no-text-select', { 'active-parent': accordionDetails.isOpen });

  function toggleAccordionDetails() {
    // Don't allow closing the accordion if it has a selected child.
    if (accordionDetails.isOpen && parent.matches()) {
      return;
    } else {
      parent.onClick?.();
      accordionDetails.toggle();
    }
  }

  if (!parent.enabled) {
    return null;
  }

  return (
    <>
      {isMin ? (
        <Menu
          className="side-nav-menu"
          position={Position.BOTTOM_START}
          trigger={(ref: any) => (
            <div ref={ref} className={pathMatchesParent ? 'nav-item active' : 'nav-item'}>
              <Tooltip label={parent.label} delay={0} position={Position.RIGHT}>
                <Button type="icon" size={Size.SMALL}>
                  <Icon svg={parent.icon} />
                </Button>
              </Tooltip>
            </div>
          )}
          content={(closeMenu) => (
            <div className="min-nav-menu">
              <SectionHeader className="m-6">{parent.label}</SectionHeader>
              {links.map((link) => (
                <div key={link.href} className="m-6">
                  {link.isEmberFeature ? (
                    <EmberNavItem href={link.href} enabled={link.enabled}>
                      {link.label}
                    </EmberNavItem>
                  ) : (
                    <NavItem
                      to={link.href}
                      hidden={!link.enabled}
                      className="nested-nav-item"
                      isActive={link.isActive}
                      onClick={() => {
                        link.onClick?.();
                        closeMenu();
                      }}
                    >
                      {link.label}
                    </NavItem>
                  )}
                </div>
              ))}
            </div>
          )}
          width="300px"
        />
      ) : (
        <>
          <div onClick={toggleAccordionDetails} className={triggerWrapperClass}>
            <Icon svg={parent.icon} />
            <div className="nav-item-label">{parent.label}</div>
            <Icon svg={accordionDetails.isOpen ? ExpandIconOpen : ExpandIconClosed} className="expand-icon" />
          </div>
          <Accordion isOpen={accordionDetails.isOpen} className="nav-accordion">
            {links.map((link) => (
              <Fragment key={link.href}>
                {link.isEmberFeature ? (
                  <EmberNavItem href={link.href} enabled={link.enabled}>
                    {link.label}
                  </EmberNavItem>
                ) : (
                  <NavItem
                    to={link.href}
                    hidden={!link.enabled}
                    className="nested-nav-item"
                    isActive={link.isActive}
                    onClick={link.onClick}
                  >
                    {link.label}
                  </NavItem>
                )}
              </Fragment>
            ))}
          </Accordion>
        </>
      )}
    </>
  );
};

export default NavAccordion;
