import { TimeOffRibbonTimeFrame } from 'containers/dashboard/types/types';
import { DateTime } from 'luxon';

export function getTimeOffDashboardRibbonGroupTimeRange(timeFrame: TimeOffRibbonTimeFrame) {
  const start = DateTime.local().startOf('day');
  if (timeFrame === 'Upcoming') {
    const end = start.plus({ day: 365 }).endOf('day');
    return { start, end };
  } else if (timeFrame === '7 days') {
    const end = start.plus({ day: 6 }).endOf('day');
    return { start, end };
  } else if (timeFrame === '30 days') {
    const end = start.plus({ day: 29 }).endOf('day');
    return { start, end };
  } else {
    throw Error('Unsupported argument in `useTimeOffDashboardData`');
  }
}
