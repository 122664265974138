import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import DashboardRibbon, { IDashboardRibbonProps } from '../DashboardRibbon/DashboardRibbon';
import './DashboardSingleRibbon.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface IDashboardSingleRibbonProps extends IDashboardRibbonProps {
  className?: ClassName;
}

function DashboardSingleRibbon({ theme, children, className, ...ribbonProps }: IDashboardSingleRibbonProps) {
  const isInIframe = useIsInIframe();

  return (
    <DashboardRibbon
      theme={theme}
      className={classNames('dashboard-single-ribbon', { 'is-in-iframe': isInIframe }, theme, className)}
      {...ribbonProps}
    >
      {children}
    </DashboardRibbon>
  );
}

export default DashboardSingleRibbon;
