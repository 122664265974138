import { INCIDENT_DETAILS_FRAGMENT } from 'containers/safety-incident-reports/hooks/incident-queries';
import gql from 'graphql-tag';

export const UPDATE_INCIDENTS = gql`
  mutation UpdateIncident($incident: IncidentUpdateInput) {
    updateIncident(incident: $incident) {
      ...IncidentDetails
    }
  }
  ${INCIDENT_DETAILS_FRAGMENT}
`;
