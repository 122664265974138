import isFunction from 'lodash/isFunction';
import { ThunkedValue } from 'types/ThunkedValue';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';

export default function getThunkedValue<T>(value: ThunkedValue<T>, prev: T): T {
  if (isFunction(value)) {
    return value(prev);
  } else {
    return value;
  }
}

export function getValueOrExecute<T>(value: T | (() => T)) {
  if (isFunction(value)) {
    return value();
  } else {
    return value;
  }
}

export function executeFunctions(...fns: Array<Optional<() => void>>) {
  fns.forEach((fn) => fn?.());
}

export function executeFunctionsCurried(...fns: Array<Optional<() => void>>) {
  return () => executeFunctions(...fns);
}

export function executeFunctionsCurriedWithArg<A>(...fns: Array<(arg: A) => void>) {
  return (arg: A) => fns.forEach((fn) => fn(arg));
}

export function currySingle<T, R>(fn: (arg: T) => R, value: T) {
  return () => fn(value);
}

export function curryDouble<T, U, R>(fn: (arg1: T, arg2: U) => R, value1: T, value2: U) {
  return () => fn(value1, value2);
}

export function curryTriple<T, U, V, R>(fn: (arg1: T, arg2: U, arg3: V) => R, value1: T, value2: U, value3: V) {
  return () => fn(value1, value2, value3);
}

export function executeIfSet<T, R>(arg: Optional<T>, fn: (arg: T) => R): Nullable<R> {
  return arg ? fn(arg) : null;
}
