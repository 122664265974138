import {
  Button,
  Checkbox,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  Label,
  Loader,
  TextareaFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DailySignOffFormDialog from 'components/domain/daily-sign-off/DailySignOff/DailySignOffForm/DailySignOffFormDialog';
import { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import EmployeeMultiPickerFormField from 'components/domain/member/EmployeeMultiPickerFormField/EmployeeMultiPickerFormField';
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import TotalTimeFormField from 'components/foundation/form-fields/TotalTimeFormField/TotalTimeFormField';
import { useToastOpen } from 'contexts/ToastContext';
import { useActiveMember, useOpenable } from 'hooks';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { dateUtils, timeEntryBreakUtils, timeEntryUtils } from 'utils';
import { combineDateWithTime, getDayRanges, nightShiftAdjustment } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import TimeActionsValidations from '../TimeActionsValidations/TimeActionsValidations';
import useTimeActionsValidations from '../TimeActionsValidations/hooks/useTimeActionsValidations';
import TimeActionFormDetailsSection from '../section/TimeActionFormDetailsSection/TimeActionFormDetailsSection';
import TimeActionsBreakFormField from '../section/TimeActionsBreakFormField/TimeActionsBreakFormField';
import TimeActionsTimeRangeFormFields from '../section/TimeActionsTimeRangeFormFields/TimeActionsTimeRangeFormFields';
import TimezoneFormField from '../section/TimezoneFormField/TimezoneFormField';
import './AddTimeEntryForm.scss';

export interface IAddTimeEntryFormProps {
  className?: ClassName;
  memberIds: string[];
  formData: ITimeActionsFormData;
  onChange?: (formData: ITimeActionsFormData | undefined) => void;
  onSubmit: (data: ITimeActionsFormData) => void;
}

const AddTimeEntryForm = ({ className, memberIds, formData, onChange, onSubmit }: IAddTimeEntryFormProps) => {
  const classes = classNames('add-time-entry-form', className);

  const today = DateTime.local().startOf('day');
  const activeMember = useActiveMember();
  const dateDetails = useOpenable();
  const endDateDetails = useOpenable();
  const { performCreateEntry, loaderDetails, timeEntryRange, onSignOffComplete, dailySignOffDetails } =
    useTimeActionsForm();
  const [breakError, setBreakError] = useState<boolean>();
  const [formInfo, setFormInfo] = useState<ITimeActionsFormData>(formData);
  const lockDialogDetails = useOpenable();
  const conflictDialogDetails = useOpenable();
  const signatureViolationDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeActionsFormData>();
  const {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
  } = useTimeActionsValidations();
  const addMultiDayEntriesEnabled = useFeatureFlags('ADD_MULTI_DAY_ENTRIES');
  const [multipleDays, setMultipleDays] = useState<boolean>(false);
  const openToast = useToastOpen();

  useEffect(() => {
    updateFormInfo(formData);
  }, [formData]);

  useEffect(() => {
    inhibitsActions(formInfo);
    conflictWarning(formInfo);
    signatureWarning(formInfo);
  }, [formInfo, memberIds]);

  function renderFormFields({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        {renderEmployeesPicker(form)}
        {addMultiDayEntriesEnabled && renderMultiDayCheckBox(form)}
        {renderDate(form)}
        {renderTimePickers(form)}
        {renderTimeZonePicker(form)}
        {renderBreaks(form)}
        {renderTotal(form)}
        <TimeActionFormDetailsSection form={form} onFormChange={updateFormInfo} />
        {renderDescription(form)}
        {renderActionButtons(form)}
      </div>
    );
  }

  function renderMultiDayCheckBox(form: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="mb-5">
        <Checkbox type="basic" label={t('Multiple Days')} checked={multipleDays} onChange={setMultipleDays} />
      </div>
    );
  }

  function renderEmployeesPicker({ ...form }: IFormRender<ITimeActionsFormData>) {
    function getPermissions() {
      return [MemberPermission.MANAGE_TIME_ENTRIES];
    }

    return (
      <div>
        <Label>{t('Employees')}</Label>
        <EmployeeMultiPickerFormField
          name="members"
          form={form}
          permissions={{
            permissions: getPermissions(),
            operationType: OperationType.AND,
          }}
          validations={[{ validation: v_require }]}
          onChange={(values) =>
            updateFormInfo({
              ...form.state.data,
              members: values,
            })
          }
        />
      </div>
    );
  }

  function showStartEndDatePickers(data: ITimeActionsFormData) {
    if (data.startDate.value !== null && data.endDate.value !== null) {
      return (
        dateUtils.numberOfDaysBetween(
          DateTime.fromJSDate(data.startDate.value),
          DateTime.fromJSDate(data.endDate.value)
        ) > 1 || multipleDays
      );
    }

    return multipleDays;
  }

  function getEndDate(formData: ITimeActionsFormData, startDate?: DateTime) {
    if (!multipleDays && showStartEndDatePickers(formData)) {
      return formData.endDate.value ? DateTime.fromJSDate(formData.endDate.value) : null;
    } else {
      return startDate ?? (formData.startDate.value ? DateTime.fromJSDate(formData.startDate.value) : null);
    }
  }

  function renderDate(form: IFormRender<ITimeActionsFormData>) {
    function handleStartDateChange(payload: IDatePickerPayload) {
      if (!payload.value) {
        return;
      }
      const startDate = DateTime.fromJSDate(payload.value);
      const endDate = getEndDate(form.state.data, startDate);

      if (!endDate) {
        return;
      }

      const startTime = form.state.data.timeRange.startTime;
      const endTime = form.state.data.timeRange.endTime;
      const start = dateUtils.combineDateAndTime(startDate, startTime!);
      const end = dateUtils.combineDateAndTime(endDate, endTime!);

      const newValue: ITimeRange<DateTime> = nightShiftAdjustment({
        startTime: start,
        endTime: end,
      });
      const total = timeEntryUtils.getTotalFromDateTimes(
        newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      if (multipleDays && startDate.toJSDate() < form.state.data.endDate.value!) {
        updateFormInfo({
          ...form.state.data,
          startDate: {
            value: startDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          total,
        });
      } else {
        updateFormInfo({
          ...form.state.data,
          startDate: {
            value: startDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          endDate: {
            value: endDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          total,
        });
      }

      const isError = timeEntryBreakUtils.isOutsideRange(
        newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      if (isError !== breakError) {
        setBreakError(isError);
      }
    }

    function handleEndDateChange(payload: IDatePickerPayload) {
      if (!form.state.data.startDate.value) {
        return;
      }
      const startDate = DateTime.fromJSDate(form.state.data.startDate.value);

      if (!payload.value) {
        return;
      }

      const endDate = DateTime.fromJSDate(payload.value);
      const startTime = form.state.data.timeRange.startTime;
      const endTime = form.state.data.timeRange.endTime;
      const start = dateUtils.combineDateAndTime(startDate, startTime!);
      const end = dateUtils.combineDateAndTime(multipleDays ? startDate : endDate, endTime!);

      const newValue: ITimeRange<DateTime> = nightShiftAdjustment({
        startTime: start,
        endTime: end,
      });
      const total = timeEntryUtils.getTotalFromDateTimes(
        newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      if (multipleDays && endDate < startDate) {
        updateFormInfo({
          ...form.state.data,
          startDate: {
            value: endDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          endDate: {
            value: endDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          total,
        });
      } else {
        updateFormInfo({
          ...form.state.data,
          startDate: {
            value: startDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          endDate: {
            value: endDate.toJSDate(),
            inputValue: today.toFormat('M/D/YYYY'),
          },
          total,
        });
      }
      const isError = timeEntryBreakUtils.isOutsideRange(
        newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      if (isError !== breakError) {
        setBreakError(isError);
      }
    }

    const startDatePicker = (
      <DatePickerFormField
        name="startDate"
        form={form}
        isOpen={dateDetails.isOpen}
        disabledAfter={today.toJSDate()}
        onOpen={dateDetails.open}
        onClose={dateDetails.close}
        onChange={handleStartDateChange}
        validations={[{ validation: v_require }]}
      />
    );

    const endDatePicker = (
      <DatePickerFormField
        name="endDate"
        form={form}
        isOpen={endDateDetails.isOpen}
        disabledAfter={today.toJSDate()}
        onOpen={endDateDetails.open}
        onClose={endDateDetails.close}
        onChange={handleEndDateChange}
        validations={[{ validation: v_require }]}
      />
    );

    if (showStartEndDatePickers(form.state.data)) {
      return (
        <div className="date-pickers-container mr-4">
          <div className="date-picker-item">
            <Label>{t('Start Date')}</Label>
            {startDatePicker}
          </div>
          <ConditionalContainer condition={multipleDays}>
            <div className="date-picker-item ml-4">
              <Label>{t('End Date')}</Label>
              {endDatePicker}
            </div>
          </ConditionalContainer>

          {renderDateErrorFooter(form)}
        </div>
      );
    }
    return (
      <div className="date-pickers">
        <Label>{t('Date')}</Label>
        {startDatePicker}
        {renderDateErrorFooter(form)}
      </div>
    );
  }

  function renderDateErrorFooter({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <>
        {form.state.data.startDate.value && hasLockDate && (
          <div className="pt-2 pb-4">
            <Label className="start-date-warning">{t('Cannot set a start date older than the lock date.')}</Label>
          </div>
        )}
      </>
    );
  }

  function renderTimePickers({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <TimeActionsTimeRangeFormFields
        form={form}
        endDate={getEndDate(form.state.data)}
        hasConflictingEntries={hasConflictingEntries}
        setFormInfo={updateFormInfo}
        updateBreakError={setBreakError}
      />
    );
  }

  const renderTimeZonePicker = ({ ...form }: IFormRender<ITimeActionsFormData>) => {
    return <TimezoneFormField form={form} hasConflict={hasConflictingEntries} />;
  };

  function renderBreaks(form: IFormRender<ITimeActionsFormData>) {
    return (
      <TimeActionsBreakFormField
        form={form}
        onUpdate={(breaks, total, error) => {
          setBreakError(error);
          updateFormInfo({ ...form.state.data, total, breaks });
        }}
        timeRange={form.state.data.timeRange}
        startDate={form.state.data.startDate.value ? DateTime.fromJSDate(form.state.data.startDate.value) : null}
        endDate={form.state.data.endDate.value ? DateTime.fromJSDate(form.state.data.endDate.value) : null}
      />
    );
  }

  function renderTotal({ ...form }: IFormRender<ITimeActionsFormData>) {
    function handleTotalChange(payload?: number | null | undefined) {
      if (payload && payload !== null) {
        let breakTotal = 0;
        for (const brk of form.state.data.breaks) {
          breakTotal += timeEntryBreakUtils.getTotalFromDateTimes(nightShiftAdjustment(brk.timeRange));
        }
        const totalValue = payload + breakTotal;
        const range = form.state.data.timeRange;
        const adjustedEnd = range.startTime!.plus(Duration.fromMillis(totalValue * 1000));
        const adjustedEndDate = combineDateWithTime(
          DateTime.fromJSDate(form.state.data.startDate.value!),
          range.startTime!
        ).plus({ seconds: totalValue });
        if (multipleDays) {
          setFormInfo({
            ...form.state.data,
            timeRange: { startTime: range.startTime, endTime: adjustedEnd },
            total: payload,
          });
        } else {
          setFormInfo({
            ...form.state.data,
            timeRange: { startTime: range.startTime, endTime: adjustedEnd },
            total: payload,
            endDate: {
              value: adjustedEndDate.toJSDate(),
              inputValue: today.toFormat('M/D/YYYY'),
            },
          });
        }
      }
    }

    return (
      <div>
        <Label>{t('Total')}</Label>
        <div className="total-field">
          <TotalTimeFormField name="total" form={form} onChange={handleTotalChange} />
        </div>
      </div>
    );
  }

  function renderDescription({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        <Label secondaryLabel={t('Optional')}>{t('Description')}</Label>
        <TextareaFormField
          name="description"
          form={form}
          restrictTo={{ maxLength: 5000 }}
          onChange={(value) => setFormInfo({ ...form.state.data, description: value })}
        />
      </div>
    );
  }

  function renderActionButtons({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="pt-6">
        <Button onClick={form.handleSubmit} type="primary">
          {t('Create')}
        </Button>
      </div>
    );
  }

  async function handleSubmit(data: ITimeActionsFormData) {
    if (breakError) {
      // If break error don't allow submission. Show toast.
      openToast({ label: t('One or more break is outside of the time entry.'), theme: Theme.DANGER });
      return;
    }

    if (
      (checkValidationsPayload.current.lock && hasLockDate) ||
      (checkValidationsPayload.current.conflict && hasConflictingEntries) ||
      (checkValidationsPayload.current.signature && hasSignatureViolation)
    ) {
      preValidationFormData.current = data;
      launchValidationAlertsOrSave();
      return;
    }

    if (loaderDetails.isOpen) {
      // Action is already being performed.
      return;
    }

    if (data) {
      createEntryFromForm(data);
    } else {
      onSubmit(data);
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onConflictContinue() {
    setHasConflictingEntries(false);
    checkValidationsPayload.current.conflict = false;
    conflictDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onSignatureViolationContinue() {
    setHasSignatureViolation(false);
    checkValidationsPayload.current.signature = false;
    signatureViolationDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function launchValidationAlertsOrSave() {
    // lock date

    if (checkValidationsPayload.current.lock && hasLockDate && preValidationFormData.current) {
      const start =
        preValidationFormData.current.startDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.startDate.value!);
      const end =
        preValidationFormData.current.endDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.endDate.value!);
      timeEntryRange.current = {
        startTime: preValidationFormData.current.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        endTime: preValidationFormData.current.timeRange.endTime!.set({
          year: end.year,
          month: end.month,
          day: end.day,
        }),
      };
      lockDialogDetails.open();
      return;
    }

    // conflicts alert

    if (checkValidationsPayload.current.conflict && hasConflictingEntries) {
      conflictDialogDetails.open();
      return;
    }

    // signatures alert

    if (checkValidationsPayload.current.signature && hasSignatureViolation) {
      signatureViolationDialogDetails.open();
      return;
    }
  }

  async function createEntryFromForm(data: ITimeActionsFormData) {
    if (_.isNil(data.timeRange.endTime)) {
      openToast({ label: t('End Time must be not null or undefined.'), theme: Theme.DANGER });
      return;
    }

    if (multipleDays) {
      const dayRanges = getDayRanges(data.startDate.value!, data.endDate.value!);
      const promises = dayRanges.map((day) => {
        return new Promise(async (resolve, reject) => {
          const newData: ITimeActionsFormData = {
            ...data,
            startDate: {
              value: day,
              inputValue: today.toFormat('M/D/YYYY'),
            },
            endDate: {
              value: day,
              inputValue: today.toFormat('M/D/YYYY'),
            },
          };
          return performCreateEntry(newData, resolve);
        });
      });
      await Promise.all(promises);
      onSubmit(data);
    } else {
      await performCreateEntry(data, onSubmit);
    }
  }

  function updateFormInfo(formData: ITimeActionsFormData) {
    setFormInfo(formData);
    onChange?.(formData);
  }

  return (
    <>
      <div>
        <div className="px-8 pb-8">
          <div className="mb-6">
            <Form
              data={formInfo}
              onSubmit={handleSubmit}
              render={renderFormFields}
              className={classes}
              allowMultipleSubmissions={true}
            />
          </div>
        </div>
      </div>
      <Loader isOpen={loaderDetails.isOpen} />
      {timeEntryRange.current && onSignOffComplete.current && (
        <DailySignOffFormDialog
          member={activeMember}
          timeRange={timeEntryRange.current}
          isOpen={dailySignOffDetails.isOpen}
          fromReport={false}
          onClose={dailySignOffDetails.close}
          onComplete={onSignOffComplete.current}
        />
      )}
      <TimeActionsValidations
        lockDate={timeEntryRange.current ? timeEntryRange.current.startTime : undefined}
        lockMemberIds={formInfo.members}
        lockDateType={LockDateDialogType.ADD}
        isLockDetailsOpen={lockDialogDetails.isOpen}
        onLockDetailsClose={lockDialogDetails.close}
        onMoveLockDate={onMoveLockDate}
        isConflictDetailsOpen={conflictDialogDetails.isOpen}
        onConflictDetailsClose={conflictDialogDetails.close}
        onConflictContinue={onConflictContinue}
        isSignatureViolationDetailsOpen={signatureViolationDialogDetails.isOpen}
        onSignatureViolationDetailsClose={signatureViolationDialogDetails.close}
        onSignatureViolationContinue={onSignatureViolationContinue}
      />
    </>
  );
};

AddTimeEntryForm.defaultProps = {
  memberIds: [],
};

export default AddTimeEntryForm;
