import { useApolloClient } from '@apollo/client';
import { TIME_ENTRY_LOG_QUERY } from 'apollo/queries/time-entry-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useTimesheetsGraylog } from 'hooks/';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import _, { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import IClientTimeEntryLog from 'types/ClientTimeEntryLog';
import ITimeEntry from 'types/TimeEntry';
import { timeEntryLogUtils } from 'utils';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { t } from 'utils/localize';
import { getSafeString } from 'utils/optionalUtils';
import { logError } from 'utils/testUtils';
import TimeEntryLogCell from './TimeEntryLogCell/TimeEntryLogCell';

interface ITimeEntryLogsProps {
  className?: ClassName;
  showNameAsSubtitle?: boolean;
  timeEntryId?: string;
  isOpen: boolean;
  onClose: () => void;
}

const TimeEntryLogsView = (props: ITimeEntryLogsProps) => {
  const { className, timeEntryId, isOpen, showNameAsSubtitle, onClose } = props;
  const employeeNameFormatter = useEmployeeNameFormatter();

  const classes = classNames({}, className);

  const userEvents = useTimesheetsGraylog();
  const [timeEntry, setTimeEntry] = useState<ITimeEntry>();
  const client = useApolloClient();

  useEffect(() => {
    if (isOpen) {
      userEvents.events(TimesheetsTypes.events.action_type.VIEW_ENTRY_LOGS);
      getTimeEntry();
    }
  }, [isOpen, timeEntryId]);

  async function getTimeEntry() {
    return client
      .query<{ timeEntries: ITimeEntry[] }>({
        query: TIME_ENTRY_LOG_QUERY,
        variables: { timeEntryId },
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        if (!isEmpty(result.data.timeEntries)) {
          setTimeEntry(result.data.timeEntries[0]);
        }
      })
      .catch((err) => {
        setTimeEntry(undefined);
        return logError(err);
      });
  }

  function renderCells() {
    if (timeEntry) {
      const logs = timeEntryLogUtils
        .compressLocations(timeEntry.clientLogs, timeEntry.breaks)
        .sort((a: IClientTimeEntryLog, b: IClientTimeEntryLog) => (a.createdOn > b.createdOn ? 1 : -1));
      return logs && !_.isEmpty(logs) ? (
        logs.map((log: IClientTimeEntryLog, index: number) => {
          return <TimeEntryLogCell timeEntry={timeEntry} timeEntryLog={log} key={index} />;
        })
      ) : (
        <div className="col-value">---</div>
      );
    }
  }

  return (
    <HeaderDialog
      isOpen={isOpen}
      title={t('Time Entry Log')}
      subtitle={
        showNameAsSubtitle
          ? employeeNameFormatter(
              getSafeString(timeEntry?.member?.firstName),
              getSafeString(timeEntry?.member?.lastName)
            )
          : undefined
      }
      onClose={onClose}
      className={classes}
    >
      {renderCells()}
    </HeaderDialog>
  );
};

export default TimeEntryLogsView;
