import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { t } from 'utils/localize';
import { lessThan } from 'utils/compareUtils';

export default function v_time_range_before_time(
  value: ITimeRange<DateTime | undefined | null>,
  args: {
    time: DateTime;
    inclusive: boolean;
    message: string;
  }
) {
  return {
    message: args.message ?? t('Range must be before {{time}}', { time: args.time.toFormat('h:mm a') }),
    validate: () => {
      const { startTime, endTime } = value;
      if (startTime && endTime) {
        const argSeconds = args.time.toSeconds();
        return (
          lessThan(startTime.toSeconds(), argSeconds, args.inclusive) &&
          lessThan(endTime.toSeconds(), argSeconds, args.inclusive)
        );
      } else {
        return false;
      }
    },
  };
}
