import { Button, Container, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useSignatureSetting from 'hooks/utils/useSignatureSetting';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import SignatureSettings from 'types/enum/SignatureSettings';
import LocalStore from 'utils/localStorageUtils';
import HeaderDialog from '../../HeaderDialog/HeaderDialog';
import './SignatureSettingsDialog.scss';
import SignatureSettingsSelect from './SignatureSettingsSelect/SignatureSettingsSelect';

export interface ISignatureSettingsDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
}

const SignatureSettingsDialog = (props: ISignatureSettingsDialogProps) => {
  const { className, isOpen, onClose } = props;

  const localSignatureSetting = LocalStore.get('signatureSetting');
  const [currentSetting, setCurrentSetting] = useState<SignatureSettings>(SignatureSettings.TYPE_SIGNATURE);
  const { setSignatureSetting } = useSignatureSetting();
  const brand = useBrandTitle();

  useEffect(() => {
    setCurrentSetting(localSignatureSetting);
  }, [localSignatureSetting]);

  function onChange(value: SignatureSettings) {
    setCurrentSetting(value);
  }

  function onSubmit() {
    setSignatureSetting(currentSetting);
    onClose();
  }

  const classes = classNames('signature-settings-dialog', className);

  return (
    <HeaderDialog isOpen={isOpen} title={t('Signature Settings')} onClose={onClose} divider={false} className={classes}>
      <Container className={'py-4 px-8'}>
        <Label>{t('Signature Options')}</Label>
        <span>{t('How would you like to sign off on items in the {{brand}} web app?', { brand })}</span>
        <SignatureSettingsSelect className={'mt-4'} onChange={onChange} value={currentSetting} />
        <Button className={'my-4'} type={'primary'} onClick={onSubmit}>
          {t('Update')}
        </Button>
      </Container>
    </HeaderDialog>
  );
};

export default SignatureSettingsDialog;
