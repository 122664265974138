import { Label, Tray } from '@busybusy/webapp-react-ui';
import { ArrowBackIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Header, IconButton } from 'components';
import ArrowedDateRange, {
  ArrowedDateRangeDateLeft,
  IArrowedDateRangeProps,
} from 'components/foundation/ranges/ArrowedDateRange/ArrowedDateRange/ArrowedDateRange';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import ITimeRange from 'types/TimeRange';
import './TimeRangeDialogHeader.scss';

export interface ITimeRangeDialogHeaderProps extends Omit<IArrowedDateRangeProps, 'order' | 'timeRange'> {
  title: string;
  onBackClick: () => void;
  actionBar: ReactNode;
  timeRange: ITimeRange<DateTime>;
  className?: ClassName;
}

export function TimeRangeDialogHeader({
  actionBar,
  className,
  timeRange,
  title,
  onBackClick,
  ...arrowedDateRangeProps
}: ITimeRangeDialogHeaderProps) {
  return (
    <Header className={classNames('time-range-dialog-header', className)} actionBar={actionBar}>
      <Tray>
        <IconButton svg={ArrowBackIcon} onClick={onBackClick} />
        <Label className="header-title pt-1">{title}</Label>
      </Tray>
      <Tray>
        <ArrowedDateRange timeRange={timeRange} order={ArrowedDateRangeDateLeft} {...arrowedDateRangeProps} />
      </Tray>
    </Header>
  );
}
