import { useActiveMember } from 'hooks';
import { useFeatureFlags } from 'utils/features';

export default function useCanViewBudgets() {
  const activeMember = useActiveMember();
  const isLaborBudgetsEnabled = useFeatureFlags('LABOR_BUDGETS');
  const isPro = useFeatureFlags('PRO');
  return (
    (activeMember.position?.viewBudgets === true || activeMember.position?.manageBudgets === true) &&
    isLaborBudgetsEnabled &&
    isPro
  );
}
