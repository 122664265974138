import { flatten, some, every, compact } from 'lodash';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/store';
import { TFlagKey } from 'types';

type TFlagHookArguments = TFlagKey[] | TFlagKey[][];

/**
 * Test a list of feature flags in the redux store to see if *ALL* of the list are enabled.
 *
 * @returns IFeatureFlags
 *
 * @example
 *
 * const MANAGEMENT_LISTS = useFeatureFlags('MANAGEMENT_LISTS');
 * const isAllEnabled = useFeatureFlags('MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES');
 * const isAllEnabledAlt = useFeatureFlags(['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES']);
 */
export const useFeatureFlags = (...flagKeys: TFlagHookArguments) => {
  const flatFlags: TFlagKey[] = compact(flatten(flagKeys));

  return useSelector<IReduxState, boolean>((state): boolean => {
    const { features } = state;

    return every(flatFlags, (key) => features[key]);
  });
};

/**
 * Test a list of feature flags in the redux store, to see if *ANY* of the features are enabled.
 *
 * @returns IFeatureFlags
 *
 * @example
 *
 * const isAnyEnabled = useAnyFeatureFlags('MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES');
 * const isAnyEnabledAlt = useAnyFeatureFlags(['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES']);
 */
export const useAnyFeatureFlags = (...flagKeys: TFlagHookArguments) => {
  const flatFlags: TFlagKey[] = compact(flatten(flagKeys));

  return useSelector<IReduxState, boolean>((state): boolean => {
    const { features } = state;

    return some(flatFlags, (key) => features[key]);
  });
};

/**
 * Test a list of feature flags in the redux store, to see if *NONE* of the features are enabled.
 *
 * @returns IFeatureFlags
 *
 * @example
 *
 * const isNoneEnabled = useWithoutFeatureFlags('MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES');
 * const isNoneEnabledAlt = useWithoutFeatureFlags(['MANAGEMENT_LISTS', 'MANAGEMENT_LISTS_EMPLOYEES']);
 */
export const useWithoutFeatureFlags = (...flagKeys: TFlagHookArguments) => !useAnyFeatureFlags(...flagKeys);
