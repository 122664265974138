import { Divider, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ExpandedTimeCardsPrintDensityType } from 'containers/timesheets/TimesheetPrintOptionsForm/TimesheetPrintOptions';
import { useOrganization } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import { compact, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { IMember } from 'types';
import ITimeRange from 'types/TimeRange';
import './ExpandedTimeCardsPrintHeader.scss';

export interface IExpandedTimeCardsPrintHeaderProps {
  className?: ClassName;
  member: IMember;
  timeRange: ITimeRange<DateTime>;
  printDensity?: ExpandedTimeCardsPrintDensityType;
}

const ExpandedTimeCardsPrintHeader = (props: IExpandedTimeCardsPrintHeaderProps) => {
  const { className, member, timeRange, printDensity } = props;

  const org = useOrganization();
  const nameFormatter = useEmployeeNameFormatter();

  const address = compact([
    org.organizationInfo?.address1,
    org.organizationInfo?.address2,
    compact([
      org.organizationInfo?.city ? `${org.organizationInfo?.city},` : null,
      org.organizationInfo?.state,
      org.organizationInfo?.postalCode,
    ]).join(' '),
  ]);

  function getTimeRangeFormat() {
    return 'EEE, MMM d, yyyy';
  }

  const classes = classNames(
    'expanded-time-cards-print-header mt-2 mx-5 print',
    printDensity === 'standard' ? 'standard' : '',
    className
  );

  return (
    <div className={classes}>
      <Row justify={Justify.SPACE_BETWEEN}>
        <div>
          <h1 className="mb-2 employee-name">{nameFormatter(member.firstName ?? '', member.lastName ?? '')}</h1>
          <Row className={'employee-info'} justify={Justify.SPACE_BETWEEN}>
            <div className="mr-4">
              <div className="employee-info-section-title">{t('Emp. ID') + ':'}</div>
              <div className="employee-info-section-title">{t('Group') + ':'}</div>
            </div>
            <div className="pr-8">
              <div>{member.memberNumber ?? '---'}</div>
              <div>{!isNil(member.memberGroup) ? member.memberGroup.groupName : '---'}</div>
            </div>
            <div className="mr-4">
              <div className="employee-info-section-title">{t('Start') + ':'}</div>
              <div className="employee-info-section-title">{t('End') + ':'}</div>
            </div>
            <div>
              <div>{timeRange.startTime.toFormat(getTimeRangeFormat())}</div>
              <div>{timeRange.endTime.toFormat(getTimeRangeFormat())}</div>
            </div>
          </Row>
        </div>
        <div>
          <div className="organization-name pt-2">{org.organizationName ?? '---'}</div>
          <div className="address-information">
            {address.map((addressPart) => (
              <div key={addressPart}>{addressPart}</div>
            ))}
          </div>
        </div>
      </Row>
      <Divider className="mt-2 mb-2" />
    </div>
  );
};

export default ExpandedTimeCardsPrintHeader;
