import { Button, Form, IFormRender, Label, Tray } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import QuickTimeTemplatePickerFormField from 'components/domain/time/QuickTimeTemplatePickerFormField/QuickTimeTemplatePickerFormField';
import Typography from 'components/foundation/text/Typography/Typography';
import useQuickTimeTemplates from 'containers/quick-time/hooks/useQuickTimeTemplates';
import { useRef } from 'react';
import { t } from 'utils/localize';
import './QuickTimeTemplateSettingsForm.scss';

export interface IQuickTimeTemplateSettingsFormProps {
  onSubmit?: (template: string | null) => void;
  onManage: () => void;
  className?: ClassName;
}

interface IFormData {
  template: string | null;
}

function QuickTimeTemplateSettingsForm({ onSubmit, onManage, className }: IQuickTimeTemplateSettingsFormProps) {
  const { defaultTemplate, setDefault } = useQuickTimeTemplates();

  const initialFormData = useRef<IFormData>({
    template: defaultTemplate?.name ?? null,
  });

  function onFormSubmit({ template }: IFormData) {
    // TODO save template to default
    setDefault(template);
    onSubmit?.(template);
  }

  function renderFields(form: IFormRender<IFormData>) {
    return (
      <>
        <Label secondaryLabel={t('Optional')}>{t('Default Template')}</Label>
        <Typography tag="p">
          {t('Set a default template that will automatically load whenever the "Create Time Entry" form is presented.')}
        </Typography>
        <QuickTimeTemplatePickerFormField form={form} name="template" placeholder={t('No Default')} />
        <Tray spacing={3} align="left">
          <Button type="primary" onClick={form.handleSubmit}>
            {t('Apply')}
          </Button>
          <Button type="secondary" onClick={onManage}>
            {t('Manage Templates')}
          </Button>
        </Tray>
      </>
    );
  }
  const classes = classNames('quick-time-template-settings-form', className);

  return <Form onSubmit={onFormSubmit} render={renderFields} className={classes} data={initialFormData.current} />;
}

export default QuickTimeTemplateSettingsForm;
