import useIsInIframe from "hooks/store/useIsInIframe";
import { t } from "utils/localize";

export default function useBrandTitle() {
  const isInIframe = useIsInIframe();
  
  if (isInIframe) {
    return t('Align Technologies');
  } else {
    return t('busybusy');
  }
}