import { ClassName } from '@busybusy/webapp-react-ui';
import { DateTime } from 'luxon';
import CreateTimeOffFormDialog from '../../dialog/CreateTimeOffFormDialog/CreateTimeOffFormDialog';
import EditTimeOffFormDialog from '../../dialog/EditTimeOffFormDialog/EditTimeOffFormDialog';
import { ITimeOffFormData } from '../CreateTimeOffForm/CreateTimeOffForm';

export interface ITimeOffFormDialogProps {
  timeOffId?: string | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data?: ITimeOffFormData) => void;
  onDelete?: (memberId?: string) => void;
  onEdit?: (memberId?: string) => void;
  currentDate?: DateTime | null;
  memberIds?: string[] | null;
  className?: ClassName;
}

const TimeOffFormDialog = ({ timeOffId, memberIds, onSubmit, onDelete, onEdit, ...rest }: ITimeOffFormDialogProps) => {
  if (timeOffId) {
    return <EditTimeOffFormDialog onEdit={onEdit} onDelete={onDelete} timeOffId={timeOffId} {...rest} />;
  } else {
    return <CreateTimeOffFormDialog onSubmit={onSubmit} memberIds={memberIds} {...rest} />;
  }
};

export default TimeOffFormDialog;
