const ONBOARDING_START = 'On-Boarding-v4: Start';
const ONBOARDING_CREATE_ACCOUNT = 'On-Boarding-v4: Create Account';
const ONBOARDING_PAY_PERIOD_SCHEDULE_VIEWED = 'On-Boarding-v4: Pay Period Schedule Viewed';
const ONBOARDING_PAY_PERIOD_SCHEDULE_CHANGED = 'On-Boarding-v4: Pay Period Schedule Changed';
const ONBOARDING_ACCOUNT_PROFILE = 'On-Boarding-v4: Account Profile';
const ONBOARDING_COMPANY_INFORMATION = 'On-Boarding-v4: Company Information';
const ONBOARDING_FEATURE_LIST = 'On-Boarding-v4: Feature List';
const ONBOARDING_TIME_TRACKED = 'On-Boarding-v4: Time Tracked';
const ONBOARDING_COMPLETED = 'On-Boarding-v4: Completed';

const onboardingAnalyticsConstants = {
  ONBOARDING_START,
  ONBOARDING_CREATE_ACCOUNT,
  ONBOARDING_PAY_PERIOD_SCHEDULE_VIEWED,
  ONBOARDING_PAY_PERIOD_SCHEDULE_CHANGED,
  ONBOARDING_ACCOUNT_PROFILE,
  ONBOARDING_COMPANY_INFORMATION,
  ONBOARDING_FEATURE_LIST,
  ONBOARDING_TIME_TRACKED,
  ONBOARDING_COMPLETED,
};

export default onboardingAnalyticsConstants;
