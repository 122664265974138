import { ITimeEntryDataReportData } from 'components/domain/time-entry/TimeEntryDataReport/TimeEntryDataReport';
import { isNil } from 'lodash';
import Papa from 'papaparse';
import IProject from 'types/Project';
import { t } from 'utils/localize';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { getTimeOffTypeTitle } from 'utils/timeOffUtils';
import { formatTime } from 'utils/timeUtils';

export function useTimeEntryExport() {
  const getCsv = (data: ITimeEntryDataReportData[], showDecimalFormat?: boolean) => {
    const shouldShowDecimalFormat = showDecimalFormat || false;

    const csv = Papa.unparse(
      data.flatMap((datum) => {
        const parsedResult = datum.rows.map((row) => {
          const isTimeEntry = !row.timeOffType;
          const isPTO = row.type === 'PTO';

          let breakTotal = '';
          let total = '';

          if (isTimeEntry) {
            if (shouldShowDecimalFormat) {
              breakTotal = !isNil(row.breakSeconds)
                ? formatTime({ type: 'DECIMAL', seconds: row.breakSeconds, places: 2 })
                : '0.00';
              total = !isNil(row.totalSeconds)
                ? formatTime({ type: 'DECIMAL', seconds: row.totalSeconds, places: 2 })
                : '0.00';
            } else {
              breakTotal = row.breaks ?? '0:00';
              total = row.total ?? '0:00';
            }
          } else {
            if (shouldShowDecimalFormat) {
              total = isPTO
                ? !isNil(row.totalSeconds)
                  ? formatTime({ type: 'DECIMAL', seconds: row.totalSeconds, places: 2 })
                  : '0.00'
                : '';
            } else {
              total = isPTO ? row.total ?? '0:00' : '';
            }
          }

          const result = {
            [t('Employee ID')]: row.member.memberNumber ?? '',
            [t('First Name')]: row.member.firstName,
            [t('Last Name')]: row.member.lastName,
            [t('Time Off Date')]: !isTimeEntry ? row.startDate.toISODate() : '',
            [t('Start')]: isTimeEntry ? row.exportStartISO : '',
            [t('End')]: isTimeEntry ? row.exportEndISO : '',
            [t('Break Total')]: breakTotal,
            [t('Total')]: total,
            [t('Customer')]: row.project?.projectInfo?.customer ?? '',
            [t('Type')]: row.type ?? '',
            [t('Project #')]: '',
            [t('Project')]: '',
            [t('Subproject 1  #')]: '',
            [t('Subproject 1')]: '',
            [t('Subproject 2 #')]: '',
            [t('Subproject 2')]: '',
            [t('Subproject 3 #')]: '',
            [t('Subproject 3')]: '',
            [t('Subproject 4 #')]: '',
            [t('Subproject 4')]: '',
            [t('Subproject 5 #')]: '',
            [t('Subproject 5')]: '',
            [t('Subproject 6 #')]: '',
            [t('Subproject 6')]: '',
          };

          const projects: IProject[] = row.project?.ancestors
            ? row.project.ancestors.concat(row.project)
            : row.project
            ? [row.project]
            : [];

          projects.forEach((proj) => {
            if (proj.depth === 1) {
              result[t('Project #')] = proj.projectInfo?.number ?? '';
              result[t('Project')] = proj.title;
            } else if (proj.depth === 2) {
              result[t('Subproject 1  #')] = proj.projectInfo?.number ?? '';
              result[t('Subproject 1')] = proj.title;
            } else if (proj.depth === 3) {
              result[t('Subproject 2 #')] = proj.projectInfo?.number ?? '';
              result[t('Subproject 2')] = proj.title;
            } else if (proj.depth === 4) {
              result[t('Subproject 3 #')] = proj.projectInfo?.number ?? '';
              result[t('Subproject 3')] = proj.title;
            } else if (proj.depth === 5) {
              result[t('Subproject 4 #')] = proj.projectInfo?.number ?? '';
              result[t('Subproject 4')] = proj.title;
            } else if (proj.depth === 6) {
              result[t('Subproject 5 #')] = proj.projectInfo?.number ?? '';
              result[t('Subproject 5')] = proj.title;
            } else if (proj.depth === 7) {
              result[t('Subproject 6 #')] = proj.projectInfo?.number ?? '';
              result[t('Subproject 6')] = proj.title;
            }
          });

          result[t('Cost Code')] = row.costCode?.costCode ?? '';
          result[t('Cost Code Desc.')] = row.costCode?.title ?? '';
          result[t('Equipment')] = row.equipment ? getEquipmentDisplay(row.equipment) : '';
          result[t('Description')] =
            isTimeEntry && row.description && row.description !== '---'
              ? row.description
              : !isTimeEntry && row.timeOffType
              ? getTimeOffTypeTitle(row.timeOffType)
              : '';

          return result;
        });

        return parsedResult;
      })
    );

    return csv;
  };

  return getCsv;
}
