import { Justify, Position, Row, Size, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import MenuButton, { IMenuButtonOptions } from 'components/foundation/buttons/MenuButton/MenuButton';
import { AnimatePresence, motion } from 'framer-motion';
import { useOrganization, useTimesheetsGraylog } from 'hooks';
import { ITimeGraphData } from 'hooks/aggregates/useMemberGraphAggregates';
import _, { isNil } from 'lodash';
import { DateTime, Duration } from 'luxon';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { TimesheetView } from 'store/timesheets/Timesheets';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { PayPeriodType } from 'types/enum/PayPeriodType';
import { getMonthsBetween } from 'utils/dateUtils';
import { getAppropriatePayPeriodForTime } from 'utils/payPeriodUtils';
import { formatTime } from 'utils/timeUtils';
import { TimesheetReportType } from '../Timesheets';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import './TimesheetsHeader.scss';

export enum TimesheetsGraphType {
  TOTAL_HOURS = 'total-hours',
  OVERTIME_HOURS = 'overtime-hours',
  DOUBLETIME_HOURS = 'doubletime-hours',
}

export interface ITimesheetsHeaderProps {
  timeRange: ITimeRange<DateTime>;
  graphData: ITimeGraphData[];
  aggregateError: string | null;
  className?: ClassName;
  showDecimalFormat?: boolean;
}

const TimesheetsHeader: FunctionComponent<ITimesheetsHeaderProps> = (props) => {
  const { timeRange, graphData, aggregateError, className, showDecimalFormat } = props;

  const [t] = useTranslation();
  const [label, setLabel] = useState<string>(t('Total Hrs'));
  const {
    timeRangeType,
    timesheetView,
    timesheetGraphType,
    timesheetReportType,
    projectId,
    costCodeId,
    equipmentId,
    projectGroupId,
    costCodeGroupId,
    positionId,
  } = useTimesheetsQueryParams();
  const organization = useOrganization();
  const payPeriod = getAppropriatePayPeriodForTime(
    organization.organizationPayPeriod!,
    timeRange.startTime.toSeconds()
  )?.current;
  const userEvents = useTimesheetsGraylog();

  const getMonthsBetweenRange = useCallback(() => {
    return getMonthsBetween(timeRange.startTime, timeRange.endTime);
  }, [timeRange]);

  const appFont = useRef({
    color: '#30373d',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: '"Nunito Sans", sans-serif',
    lineHeight: '24px',
  });

  const labelStyle = useRef({
    ...appFont.current,
    fontWeight: 600,
  });

  useEffect(() => {
    if (timesheetGraphType) {
      switch (timesheetGraphType) {
        case TimesheetsGraphType.TOTAL_HOURS:
          setLabel('Total Hrs');
          break;
        case TimesheetsGraphType.OVERTIME_HOURS:
          setLabel('Overtime Hrs');
          break;
        case TimesheetsGraphType.DOUBLETIME_HOURS:
          setLabel('Double Time Hrs');
          break;
      }
    }
  }, [timesheetGraphType]);

  const onSelectOption = (value: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLabel(value);
    userEvents.graphTypeSet(value);
  };

  const menuOptions = useRef<IMenuButtonOptions[]>([
    { label: t('Total Hrs'), onSelect: onSelectOption('Total Hrs') },
    { label: t('Regular Hrs'), onSelect: onSelectOption('Regular Hrs') },
    { label: t('Overtime Hrs'), onSelect: onSelectOption('Overtime Hrs') },
    { label: t('Double Time Hrs'), onSelect: onSelectOption('Double Time Hrs') },
  ]);

  function dateFormatted(tickItem: number) {
    const dateTime = DateTime.fromSeconds(tickItem, { zone: 'utc' });
    switch (timeRangeType) {
      case TimeRangeType.WEEKLY:
        return dateTime.toFormat(getDateFormatWithYearConsideration('ccc'));
      case TimeRangeType.MONTHLY:
        return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
      case TimeRangeType.PAY_PERIOD:
        if (payPeriod?.payPeriodType !== PayPeriodType.WEEKLY) {
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
        } else {
          return dateTime.toFormat(getDateFormatWithYearConsideration('ccc'));
        }
      case TimeRangeType.CUSTOM: {
        const duration = timeRange.endTime.toSeconds() - timeRange.startTime.toSeconds();
        if (getMonthsBetweenRange().length > 2) {
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM'));
        } else if (duration > Duration.fromObject({ week: 1 }).as('seconds')) {
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
        } else {
          // This gets weird to use `ccc` because if you have less than a week on a previous week
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
        }
      }
    }

    return '';
  }

  function getDateFormatWithYearConsideration(format: string) {
    const sameYear =
      timeRange.startTime.hasSame(timeRange.endTime, 'year') && timeRange.startTime.hasSame(DateTime.utc(), 'year');
    return sameYear ? format : format + ', yyyy';
  }

  function getTotalTime() {
    let seconds: number = 0;
    switch (label) {
      case t('Total Hrs'):
        seconds = _.sumBy(graphData, (aggregate) => aggregate.totalHours);
        break;
      case t('Regular Hrs'):
        seconds = _.sumBy(graphData, (aggregate) => aggregate.regularHours);
        break;
      case t('Overtime Hrs'):
        seconds = _.sumBy(graphData, (aggregate) => aggregate.overtimeHours);
        break;
      case t('Double Time Hrs'):
        seconds = _.sumBy(graphData, (aggregate) => aggregate.doubleTimeHours);
        break;
    }

    if (timesheetView === TimesheetView.EXPANDED_TIME_CARDS && !isNil(showDecimalFormat) && showDecimalFormat) {
      return formatTime({ type: 'DECIMAL', seconds: seconds, places: 2 });
    }

    return Duration.fromMillis(seconds! * 1000).toFormat('h:mm');
  }

  function getLineDataKey(): keyof ITimeGraphData {
    switch (label) {
      case t('Total Hrs'):
        return 'totalHours';
      case t('Regular Hrs'):
        return 'regularHours';
      case t('Overtime Hrs'):
        return 'overtimeHours';
      case t('Double Time Hrs'):
        return 'doubleTimeHours';
      default:
        return 'totalHours';
    }
  }

  function showGraph() {
    if (
      (!isNil(projectId) && (!isNil(costCodeId) || !isNil(equipmentId))) ||
      (!isNil(costCodeId) && (!isNil(projectId) || !isNil(equipmentId))) ||
      (!isNil(equipmentId) && (!isNil(costCodeId) || !isNil(projectId))) ||
      !isNil(projectGroupId) ||
      !isNil(costCodeGroupId) ||
      !isNil(positionId)
    ) {
      return false;
    }

    const isTimeEntriesView = timesheetView === TimesheetView.TIME_ENTRIES;
    const isViewingEntries = timesheetReportType === null || timesheetReportType === TimesheetReportType.ENTRY;

    return ((isTimeEntriesView && isViewingEntries) || !isTimeEntriesView) && !aggregateError;
  }

  const classes = classNames('timesheets-main-header', className);

  // No way to filter aggregates by timesheet filter type so we hide the header
  return (
    <AnimatePresence>
      {showGraph() && (
        <motion.div
          className={classes}
          initial={{ opacity: 0.2, y: -40 }}
          animate={{ opacity: 1.0, y: 0 }}
          exit={{ opacity: 0.2, y: -40 }}
          transition={{ duration: 0.5 }}
        >
          <Row justify={Justify.SPACE_BETWEEN}>
            <div className="timesheets-text-content">
              <div className="timesheets-main-header-label">{label}</div>
              <div className="timesheets-main-header-total">{getTotalTime()}</div>
            </div>
            {/* Don't show graph on daily just total */}
            {timeRangeType !== TimeRangeType.DAILY &&
              !(timeRangeType === TimeRangeType.CUSTOM && timeRange.startTime.hasSame(timeRange.endTime, 'day')) && (
                <ResponsiveContainer height="100%" width="70%">
                  {/* Animation requires a new set of data, this is broken when you just change the key so reassign data and it'll animate with new data key */}
                  <LineChart data={[...graphData]} margin={{ top: 5, right: 30, left: 30, bottom: 5 }}>
                    <Line
                      type="monotone"
                      dataKey={getLineDataKey()}
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={2.0}
                      stroke={'#FFF'}
                    />
                    <XAxis
                      dataKey={'day'}
                      tickSize={0}
                      tickMargin={15}
                      tickFormatter={dateFormatted}
                      interval={'preserveStartEnd'}
                      stroke={'#FFF'}
                      tick={{ fontSize: 12 }}
                    />
                    <Tooltip
                      // Separator between the name and the value since we aren't using a name set to nothing
                      separator={''}
                      // Second argument is the name which we are not using
                      formatter={(value) => [Duration.fromMillis((value as number) * 1000).toFormat('h:mm'), '']}
                      labelStyle={labelStyle.current}
                      itemStyle={appFont.current}
                      labelFormatter={dateFormatted as any}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
          </Row>
          <MenuButton
            size={Size.MEDIUM}
            buttonSize={Size.XL}
            position={Position.BOTTOM_END}
            theme={Theme.DARK}
            options={menuOptions.current}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

function comparison(previous: ITimesheetsHeaderProps, next: ITimesheetsHeaderProps) {
  return (
    previous.className === next.className &&
    previous.timeRange.startTime.equals(next.timeRange.startTime) &&
    previous.timeRange.endTime.equals(next.timeRange.endTime) &&
    _.isEqual(previous.graphData, next.graphData) &&
    _.isEqual(previous.aggregateError, next.aggregateError) &&
    _.isEqual(previous.showDecimalFormat, next.showDecimalFormat)
  );
}

export default React.memo(TimesheetsHeader, comparison);
