import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { OrganizationSubscriptionStatus } from '__generated__/graphql';

export enum OrganizationSubStatus {
  TRIALING = 'trialing',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
}

export function isFreeAccount(subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'productHandle'>): boolean {
  return subscriptionStatus.productHandle?.includes('free') ?? true;
}

export function isProAccount(subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'productHandle'>): boolean {
  if (isNil(subscriptionStatus.productHandle)) {
    return false;
  }
  return subscriptionStatus.productHandle.includes('pro') || subscriptionStatus.productHandle.includes('enterprise');
}

export function isFreeTrialAccount(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'status' | 'productHandle' | 'trialEndedAt'>
): boolean {
  if (isProAccount(subscriptionStatus)) {
    if (
      subscriptionStatus.status === OrganizationSubStatus.TRIALING &&
      !isNil(subscriptionStatus.trialEndedAt) &&
      DateTime.fromISO(subscriptionStatus.trialEndedAt).toSeconds() > DateTime.local().toSeconds()
    ) {
      return true;
    }
  }
  return false;
}

export function hasCard(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'cardType' | 'cardLastFour'>
): boolean {
  return !isNil(subscriptionStatus.cardType) && !isNil(subscriptionStatus.cardLastFour);
}

export function isFreeTrialUpgraded(
  subscriptionStatus: Pick<
    OrganizationSubscriptionStatus,
    'cardType' | 'cardLastFour' | 'status' | 'productHandle' | 'trialEndedAt'
  >
): boolean {
  return isFreeAccount(subscriptionStatus) && hasCard(subscriptionStatus);
}

export function isTrialEnded(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'hasTrialed' | 'status' | 'productHandle' | 'trialEndedAt'>
): boolean {
  return !isNil(subscriptionStatus.hasTrialed) && subscriptionStatus.hasTrialed && isFreeAccount(subscriptionStatus);
}

export function isTrialAvailable(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'hasTrialed' | 'status' | 'productHandle' | 'trialEndedAt'>
): boolean {
  return !isNil(subscriptionStatus.hasTrialed) && !subscriptionStatus.hasTrialed && isFreeAccount(subscriptionStatus);
}

export function isPastDue(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'status' | 'productHandle' | 'trialEndedAt'>
): boolean {
  return subscriptionStatus.status === OrganizationSubStatus.PAST_DUE && !isFreeTrialAccount(subscriptionStatus);
}

export function isSubscriptionLockedOut(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'status' | 'productHandle' | 'trialEndedAt'>
): boolean {
  return subscriptionStatus.status === OrganizationSubStatus.CANCELED && !isFreeTrialAccount(subscriptionStatus);
}

export function subscriptionPlanString(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'status' | 'productHandle' | 'trialEndedAt'>
): string {
  if (isProAccount(subscriptionStatus)) {
    if (isFreeTrialAccount(subscriptionStatus)) {
      return t('Pro Free Trial');
    }
    return t('Pro');
  }
  return t('Free');
}

export function isSubscriptionAnnual(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'annualSubscriptionCount'>
): boolean {
  return !isNil(subscriptionStatus.annualSubscriptionCount); // if annualSubscriptionCount has a value, the organization is using an annual plan
}

export function isSubscriptionMonthly(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'annualSubscriptionCount'>
): boolean {
  return !isSubscriptionAnnual(subscriptionStatus);
}

export function isInvoice(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'annualSubscriptionPendingInvoiceId'>
): boolean {
  return !isNil(subscriptionStatus.annualSubscriptionPendingInvoiceId);
}

export function firstPaymentDate(
  subscriptionStatus: Pick<
    OrganizationSubscriptionStatus,
    | 'annualSubscriptionCount'
    | 'annualSubscriptionPendingInvoiceId'
    | 'trialEndedAt'
    | 'annualSubscriptionDate'
    | 'nextAssessmentAt'
    | 'status'
    | 'productHandle'
  >
): DateTime | undefined {
  if (isSubscriptionAnnual(subscriptionStatus)) {
    if (isInvoice(subscriptionStatus) && !isNil(subscriptionStatus.trialEndedAt)) {
      return DateTime.fromISO(subscriptionStatus.trialEndedAt).plus({ months: 1 });
    } else if (!isNil(subscriptionStatus.annualSubscriptionDate)) {
      return DateTime.fromISO(subscriptionStatus.annualSubscriptionDate);
    }
  } else if (isFreeTrialAccount(subscriptionStatus) && !isNil(subscriptionStatus.trialEndedAt)) {
    return DateTime.fromISO(subscriptionStatus.trialEndedAt).plus({ months: 1 });
  }

  if (subscriptionStatus.nextAssessmentAt) {
    return DateTime.fromISO(subscriptionStatus.nextAssessmentAt);
  }

  return undefined;
}

export function nextPaymentDate(
  subscriptionStatus: Pick<
    OrganizationSubscriptionStatus,
    | 'annualSubscriptionCount'
    | 'annualSubscriptionPendingInvoiceId'
    | 'trialEndedAt'
    | 'annualSubscriptionDate'
    | 'nextAssessmentAt'
    | 'status'
    | 'productHandle'
  >
): DateTime | undefined {
  if (isSubscriptionMonthly(subscriptionStatus)) {
    return firstPaymentDate(subscriptionStatus);
  }
  return firstPaymentDate(subscriptionStatus)?.plus({ years: 1 });
}

export function getCostPerUser(
  subscriptionStatus: Pick<OrganizationSubscriptionStatus, 'pricePointHandle'>
): number | null {
  if (!isNil(subscriptionStatus.pricePointHandle)) {
    switch (subscriptionStatus.pricePointHandle) {
      case 'standard':
        return 11.99;
      case 'standard-pre-paid':
        return 9.99;
      case 'rate-299':
        return 2.99;
      case 'rate-399':
        return 3.99;
      case 'rate-699':
        return 6.99;
      case 'rate-699-pre-paid':
        return 4.99;
      case 'rate-799':
        return 7.99;
      case 'rate-799-pre-paid':
        return 5.99;
      case 'rate-899':
        return 8.99;
      case 'rate-899-pre-paid':
        return 6.99;
      case 'rate-999':
        return 9.99;
      case 'rate-999-pre-paid':
        return 7.99;
      case 'rate-1099':
        return 10.99;
      case 'rate-1099-pre-paid':
        return 8.99;
      case 'rate-1299':
        return 12.99;
      case 'rate-1299-pre-paid':
        return 10.99;
      case 'rate-1399':
        return 13.99;
      case 'rate-1399-pre-paid':
        return 11.99;
      case 'rate-1499':
        return 14.99;
      case 'rate-1499-pre-paid':
        return 12.99;
      case 'rate-1599':
        return 15.99;
      case 'rate-1599-pre-paid':
        return 13.99;
      case 'rate-1799':
        return 17.99;
      case 'rate-1799-pre-paid':
        return 14.99;
      case 'rate-1899':
        return 18.99;
      case 'rate-1899-pre-paid':
        return 15.99;
      case 'rate-1999':
        return 19.99;
      case 'rate-1999-pre-paid':
        return 16.99;
      case 'rate-2099':
        return 20.99;
      case 'rate-2099-pre-paid':
        return 17.99;
      case 'rate-2199':
        return 21.99;
      case 'rate-2199-pre-paid':
        return 18.99;
      case 'rate-2399':
        return 23.99;
      case 'rate-2399-pre-paid':
        return 19.99;
      case 'rate-2499':
        return 24.99;
      case 'rate-2499-pre-paid':
        return 20.99;
      case 'rate-2599':
        return 25.99;
      case 'rate-2599-pre-paid':
        return 21.99;
      case 'rate-2699':
        return 26.99;
      case 'rate-2699-pre-paid':
        return 22.99;
      case 'rate-2799':
        return 27.99;
      case 'rate-2799-pre-paid':
        return 23.99;
      case 'rate-2999':
        return 29.99;
      case 'rate-2999-pre-paid':
        return 24.99;
      case 'rate-3099':
        return 30.99;
      case 'rate-3099-pre-paid':
        return 25.99;
      case 'rate-3199':
        return 31.99;
      case 'rate-3199-pre-paid':
        return 26.99;
      case 'rate-3299':
        return 32.99;
      case 'rate-3299-pre-paid':
        return 27.99;
      case 'rate-3399':
        return 33.99;
      case 'rate-3399-pre-paid':
        return 28.99;
      case 'rate-3599':
        return 35.99;
      case 'rate-3599-pre-paid':
        return 29.99;
      default:
        return null;
    }
  }

  return null;
}
