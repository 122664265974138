import { Col, Divider } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import Panel from '../../Panel/Panel';
import MinNavHeader from '../MinNavHeader/MinNavHeader';
import MinNavList from '../MinNavList/MinNavList';
import './MinNavigation.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface IMinNavigationProps {
  className?: ClassName;
  open: boolean;
  toggleMenu: () => void;
}

function MinNavigation(props: IMinNavigationProps) {
  const { className, open, toggleMenu } = props;
  const isInIframe = useIsInIframe();
  const classes = classNames('min-navigation', 'no-print', { 'is-in-iframe': isInIframe }, className);

  return (
    <Panel small={true} open={open} className={classes}>
      <Col>
        <MinNavHeader toggleMenu={toggleMenu} />
        <Divider />
        <MinNavList />
      </Col>
    </Panel>
  );
}

export default MinNavigation;
