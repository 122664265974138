import { t } from 'utils/localize';

export default function v_number_decimals_only(
  value: any,
  args: { decimalPlaces?: number; maxDecimalPlaces?: number; maxNumber?: number; message?: string }
) {
  return {
    message:
      args.message || args.maxDecimalPlaces
        ? t(`Must only contain numbers with ${args.maxDecimalPlaces} decimals`)
        : t('Must only contain valid numbers'),
    validate: () => {
      if (!value) {
        return true;
      }

      if (args.maxDecimalPlaces) {
        const decimals = value.toString().split('.')[1]?.length ?? 0;
        if (decimals > args.maxDecimalPlaces) {
          return false;
        }
      }

      const newValue = Number(parseFloat(value).toFixed(args.decimalPlaces ?? 2));
      const max = args.maxNumber ?? Number.MAX_VALUE;
      if (isNaN(newValue) || newValue < 0 || newValue > max) {
        return false;
      }
      return true;
    },
  };
}
