import { useApolloClient } from '@apollo/client';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import { ITimeEntryDataTableData } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import {
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { useToastOpen } from 'contexts/ToastContext';
import { useLazyLoading } from 'hooks';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import { groupBy, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { t } from 'utils/localize';

export interface ICostCodeTimeEntryFilter {
  timeRange: ITimeRange<DateTime>;
  includeOpen?: boolean;
  costCodeIds?: string[] | null;
  equipmentIds?: string[];
  memberIds?: string[];
  projectIds?: string[];
  excludeArchived?: boolean;
  projectIdWithDescendants?: boolean;
}

export function useLazyCostCodeTimeData(scroller: HTMLElement | Document | null, filter: ICostCodeTimeEntryFilter) {
  const client = useApolloClient();
  const toast = useToastOpen();
  const getTimeEntries = useTimeEntryQuery();

  const addTimeData = useCallback(
    async (costCode: Array<ICostCode & ICursorable>) => {
      let groupedEntries: _.Dictionary<ITimeEntry[]> | null = null;
      try {
        const entries = await getTimeEntries(
          filter.timeRange,
          filter.includeOpen,
          filter.memberIds,
          filter.projectIds,
          costCode.map((m) => m.id),
          filter.equipmentIds,
          filter.projectIdWithDescendants,
          undefined,
          [MemberPermission.TIME_EVENTS]
        );
        groupedEntries = groupBy(entries, 'costCodeId');
      } catch (error) {
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      const costCodesWithEntries = costCode.map((e) => {
        const item: ITimeEntryDataTableData<ICostCode> & ICursorable = {
          item: e,
          entries: groupedEntries?.[e.id] ?? [],
          timeOffs: [],
          cursor: e.cursor,
        };
        return item;
      });
      return costCodesWithEntries.filter((item) => !isNilOrEmpty(item.entries));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  const getData = useCallback(
    async (cursor: string | null, first: number) => {
      dispatch({ type: 'SET_LOADING', payload: true });
      try {
        const results = await client.query<{ costCodes: Array<ICostCode & ICursorable> }>({
          query: COST_CODES_QUERY,
          fetchPolicy: 'network-only',
          variables: getCostCodeVariables(first, cursor),
        });
        const costCodes = results.data.costCodes ?? [];
        return await addTimeData(costCodes);
      } catch (error) {
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, getTimeEntries, addTimeData]
  );

  const getCostCodeVariables = (first: number, cursor?: string | null) => {
    const variables: any = {
      first,
      after: cursor,
      filter: {
        id: graphQLContainsIdOrEmptyItemId(filter.costCodeIds),
        archivedOn: filter.excludeArchived ? { isNull: filter.excludeArchived } : undefined,
        costCodesWithTime: {
          startTime: filter.timeRange.startTime
            .startOf('day')
            .toUTC()
            .toISO({ suppressMilliseconds: true, includeOffset: false }),
          endTime: filter.timeRange.endTime
            .endOf('day')
            .toUTC()
            .toISO({ suppressMilliseconds: true, includeOffset: false }),
          includeOpenEntry: filter.includeOpen,
          memberId: graphQLContainsIdOrEmptyItemId(filter.memberIds),
          equipmentId: graphQLContainsIdOrEmptyItemId(filter.equipmentIds),
          ...filterByProjectIdsOrEmptyItemId(filter.projectIds, filter.projectIdWithDescendants),
        },
      },
    };

    return variables;
  };

  const { clearData, data, error, loading, dispatch, loadedAll, loadAll } = useLazyLoading(
    scroller,
    getData,
    undefined,
    5,
    200
  );

  const updateCostCodeIds = useCallback(
    async (costCodeIds: string[]) => {
      const allowedCostCodeIds = filter.costCodeIds
        ? costCodeIds.filter((id) => filter.costCodeIds?.some((costCodeId) => costCodeId === id))
        : costCodeIds;
      const newCostCodeData = allowedCostCodeIds.map(async (id) => {
        const costCode = data.find((datum) => datum.item.id === id);
        if (costCode?.item === undefined) {
          const results = await client.query<{ costCodes: Array<ICostCode & ICursorable> }>({
            query: COST_CODES_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              filter: {
                id: graphQLContainsIdOrEmptyItemId([id]),
              },
            },
          });

          return await addTimeData(results.data.costCodes);
        } else {
          return await addTimeData([{ ...costCode.item, cursor: costCode.cursor }]);
        }
      });

      const allResults = (await Promise.all(newCostCodeData)).flat();
      const newData = data.filter((item) => costCodeIds.some((id) => id !== item.item.id));

      dispatch({
        type: 'SET_DATA',
        payload: {
          data: sortBy([...newData, ...allResults], (result) => result.item.costCode),
          loadedAll: false,
          loading: false,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addTimeData, client, data, dispatch, filter.costCodeIds, filter.timeRange]
  );

  useEffect(() => {
    clearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return { clearData, data, error, loading, loadedAll, loadAll, updateCostCodeIds };
}
