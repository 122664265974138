import { AxiosResponse } from 'axios';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import IBusyApiWrapper from 'types/BusyApiWrapper';
import IMemberLocation from 'types/MemberLocation';
import IBreak from 'types/TimeEntryBreak';
import ITimeRange from 'types/TimeRange';
import TimeEntryBreakStatusType from 'types/enum/TimeEntryBreakStatusType';
import { Optional } from 'types/util/Optional';
import { dateUtils, timeEntryBreakUtils } from 'utils';
import { AxiosInstance } from 'utils/axiosClient';
import ITimeEntry, { IEntryRange } from '../types/TimeEntry';
import { dateTimeFromISOKeepZone, setZone } from './dateUtils';

export function getTotalTimeFromEntryRangeAndBreakRanges(
  entryRange: { startTime: string; endTime?: Optional<string> },
  breakRanges: Array<{ startTime: string; endTime?: Optional<string> }>
) {
  const entryStartTime = dateUtils.dateTimeFromISOWithoutZone(entryRange.startTime);
  const entryEndTime = timeEntryEndTimeOrNow(entryRange);
  let totalEntryTime = entryEndTime.toMillis() - entryStartTime.toMillis();

  breakRanges.forEach((value) => {
    const breakStartTime = dateUtils.dateTimeFromISOWithoutZone(value.startTime);
    const breakEndTime = timeEntryBreakUtils.breakEndTimeOrNow(value);

    const status = timeEntryBreakUtils.getBreakStatus(value, entryRange);
    if (status === TimeEntryBreakStatusType.INSIDE_ENTRY) {
      totalEntryTime -= breakEndTime.toMillis() - breakStartTime.toMillis();
    } else if (status === TimeEntryBreakStatusType.END_TIME_OUTSIDE) {
      totalEntryTime -= entryEndTime.toMillis() - breakStartTime.toMillis();
    } else if (status === TimeEntryBreakStatusType.START_TIME_OUTSIDE) {
      totalEntryTime -= breakEndTime.toMillis() - entryStartTime.toMillis();
    } else if (status === TimeEntryBreakStatusType.CONTAINS_ENTRY) {
      totalEntryTime -= entryEndTime.toMillis() - entryStartTime.toMillis();
    }
  });

  if (totalEntryTime < 0) {
    totalEntryTime = 0;
  }
  return totalEntryTime / 1000;
}
/// Returns total in seconds.
export function getTotal(entry: ITimeEntry) {
  const filteredBreaks = entry.breaks.filter(({ deletedOn }) => deletedOn === null);
  return getTotalTimeFromEntryRangeAndBreakRanges(entry, filteredBreaks);
}

export function getBreakTotal(entry: Pick<ITimeEntry, 'startTime' | 'endTime' | 'breaks'>) {
  const entryStartTime = dateUtils.dateTimeFromISOWithoutZone(entry.startTime);
  const entryEndTime = timeEntryEndTimeOrNow(entry);
  let totalBreakTime = 0;

  entry.breaks
    .filter((brk) => {
      return brk.deletedOn === null;
    })
    .forEach((val) => {
      const breakStartTime = dateUtils.dateTimeFromISOWithoutZone(val.startTime);
      const breakEndTime = timeEntryBreakUtils.breakEndTimeOrNow(val);

      const status = timeEntryBreakUtils.getBreakStatus(val, entry);
      if (status === TimeEntryBreakStatusType.INSIDE_ENTRY) {
        totalBreakTime += breakEndTime.toMillis() - breakStartTime.toMillis();
      } else if (status === TimeEntryBreakStatusType.END_TIME_OUTSIDE) {
        totalBreakTime += entryEndTime.toMillis() - breakStartTime.toMillis();
      } else if (status === TimeEntryBreakStatusType.START_TIME_OUTSIDE) {
        totalBreakTime += breakEndTime.toMillis() - entryStartTime.toMillis();
      } else if (status === TimeEntryBreakStatusType.CONTAINS_ENTRY) {
        totalBreakTime += entryEndTime.toMillis() - entryStartTime.toMillis();
      }
    });
  if (totalBreakTime < 0) {
    totalBreakTime = 0;
  }
  return totalBreakTime / 1000;
}

/**
 * Returns total in seconds for the time range of a time entry and the time ranges of the time entry's breaks.
 * If creating a time range from a time entry/break and using `DateTime.fromIso()` make sure to pass the option
 * `{setZone: true})` so that the total can be calculated appropriately if the data is in a different timezone.
 *
 * @param entryRange the time range that represents the time entry
 * @param breakRanges list of time ranges that represent the time entry's break entries
 */
export function getTotalFromDateTimes(entryRange: ITimeRange<DateTime>, breakRanges: Array<ITimeRange<DateTime>>) {
  const entryStart = dateUtils.dateTimeFromISOWithoutZone(entryRange.startTime.toISO());
  const entryEnd = dateUtils.dateTimeFromISOWithoutZone(entryRange.endTime.toISO());
  let totalEntryTime = Math.abs(entryEnd.toMillis() - entryStart.toMillis());

  for (const range of breakRanges) {
    const breakRangeStart = range.startTime.toUTC(0, { keepLocalTime: true });
    const breakRangeEnd = range.endTime.toUTC(0, { keepLocalTime: true });

    if (breakRangeStart >= entryStart && breakRangeEnd <= entryEnd) {
      // Break is entirely inside entry.
      totalEntryTime -= breakRangeEnd.toMillis() - breakRangeStart.toMillis();
    } else if (breakRangeStart <= entryStart && breakRangeEnd >= entryEnd) {
      // Break startTime and endTime is outside of entry.
      totalEntryTime = 0;
    } else if (breakRangeStart >= entryStart && breakRangeEnd >= entryEnd && breakRangeStart <= entryEnd) {
      // Breaks endTime is outside of entry.
      totalEntryTime -= entryEnd.toMillis() - breakRangeStart.toMillis();
    } else if (breakRangeStart <= entryStart && breakRangeEnd <= entryEnd && breakRangeEnd >= entryStart) {
      // Breaks startTime is outside of entry.
      totalEntryTime -= breakRangeEnd.toMillis() - entryStart.toMillis();
    }
  }

  if (totalEntryTime < 0) {
    totalEntryTime = 0;
  }
  return totalEntryTime / 1000;
}

/// Returns a date formatter like EEE, MMM d or EEE, MMM d, yyyy depending on the start / end time.
export function getEntryDateFormatter(timeEntry: ITimeEntry) {
  let dateFormatter = 'EEE, MMM d';

  const start = dateUtils.dateTimeFromISOWithoutZone(timeEntry.startTime);
  const end = timeEntryEndTimeOrNow(timeEntry);

  if (dateUtils.isSameYear(start, end) === false) {
    dateFormatter += ', yyyy';
  }
  return dateFormatter;
}

/// Returns true or false if the entry and or break has a location.
export function hasLocation(timeEntry: Pick<ITimeEntry, 'startLocation' | 'endLocation' | 'breaks'>) {
  return (
    !_.isNil(timeEntry.startLocation) ||
    !_.isNil(timeEntry.endLocation) ||
    (timeEntry.breaks &&
      !_.isEmpty(timeEntry.breaks) &&
      (timeEntry.breaks.find((b: IBreak) => !_.isNil(b.startLocation)) !== undefined ||
        timeEntry.breaks.find((b: IBreak) => !_.isNil(b.endLocation)) !== undefined))
  );
}

export async function checkIfEntryHasLocation(client: AxiosInstance, memberId: string, start: DateTime, end: DateTime) {
  return await client
    .get('/member-location?_version=3.2', {
      params: {
        'page_size': 1,
        'member_id': memberId,
        '_lte[time_gmt]': end.toSeconds(),
        '_gte[time_gmt]': start.toSeconds(),
      },
    })
    .then((res: AxiosResponse<IBusyApiWrapper<IMemberLocation[]>>) => {
      if (res.data && res.data.data) {
        return !_.isEmpty(res.data.data);
      } else {
        return false;
      }
    });
}

export function totalForDay(day: DateTime, entry: Pick<ITimeEntry, 'startTime' | 'endTime' | 'breaks'>) {
  let start = day.toUTC(0, { keepLocalTime: true }).startOf('day');
  let end = day.toUTC(0, { keepLocalTime: true }).endOf('day');
  const entryStart = dateUtils.dateTimeFromISOWithoutZone(entry.startTime);
  const entryEnd = timeEntryEndTimeOrNow(entry);

  // guard against the time entry being outside the entire day
  if ((entryStart < start && entryEnd < start) || (entryStart > end && entryEnd > end)) {
    return 0;
  }

  if (start < entryStart) {
    start = entryStart;
  }

  if (end > entryEnd) {
    end = entryEnd;
  }

  let totalEntryTime = Math.abs(end.toMillis() - start.toMillis());
  entry.breaks
    .filter((brk) => {
      const isNotDeleted = brk.deletedOn === null;
      const lessThanEnd = dateUtils.dateTimeFromISOWithoutZone(brk.startTime) < end;
      const greaterThanStart = brk.endTime
        ? dateUtils.dateTimeFromISOWithoutZone(brk.endTime!) >= start
        : DateTime.utc() >= start;
      return isNotDeleted && lessThanEnd && greaterThanStart;
    })
    .forEach((value) => {
      const breakStartTime = dateUtils.dateTimeFromISOWithoutZone(value.startTime);
      const breakEndTime = timeEntryBreakUtils.breakEndTimeOrNow(value);

      const status = timeEntryBreakUtils.getBreakStatus(value, {
        ...entry,
        startTime: start.toISO(),
        endTime: end.toISO(),
      });
      if (status === TimeEntryBreakStatusType.INSIDE_ENTRY) {
        totalEntryTime -= breakEndTime.toMillis() - breakStartTime.toMillis();
      } else if (status === TimeEntryBreakStatusType.END_TIME_OUTSIDE) {
        totalEntryTime -= end.toMillis() - breakStartTime.toMillis();
      } else if (status === TimeEntryBreakStatusType.START_TIME_OUTSIDE) {
        totalEntryTime -= breakEndTime.toMillis() - start.toMillis();
      } else if (status === TimeEntryBreakStatusType.CONTAINS_ENTRY) {
        totalEntryTime -= end.toMillis() - start.toMillis();
      }
    });
  if (totalEntryTime < 0) {
    totalEntryTime = 0;
  }
  return Number((totalEntryTime / 1000).toFixed(0));
}

export function breakTotalInRange(timeRange: ITimeRange<DateTime>, entry: ITimeEntry) {
  let start = timeRange.startTime.toUTC(0, { keepLocalTime: true });
  let end = timeRange.endTime.toUTC(0, { keepLocalTime: true });

  const entryStart = dateUtils.dateTimeFromISOWithoutZone(entry.startTime);
  const entryEnd = timeEntryEndTimeOrNow(entry);

  if (entryStart > start) {
    start = entryStart;
  }

  if (entryEnd < end) {
    end = entryEnd;
  }

  const entryInRange = { ...entry, startTime: start.toISO(), endTime: end.toISO() };
  const breakEntries = entry.breaks.filter((brk) => {
    const isNotDeleted = brk.deletedOn === null;
    const lessThanEnd = dateUtils.dateTimeFromISOWithoutZone(brk.startTime) < end;
    const greaterThanStart = brk.endTime
      ? dateUtils.dateTimeFromISOWithoutZone(brk.endTime!) >= start
      : DateTime.utc() >= start;
    return isNotDeleted && lessThanEnd && greaterThanStart;
  });

  return _.sumBy(breakEntries, (value) => {
    const breakStartTime = dateUtils.dateTimeFromISOWithoutZone(value.startTime);
    const breakEndTime = timeEntryBreakUtils.breakEndTimeOrNow(value);

    const status = timeEntryBreakUtils.getBreakStatus(value, entryInRange);
    if (status === TimeEntryBreakStatusType.INSIDE_ENTRY) {
      return (breakEndTime.toMillis() - breakStartTime.toMillis()) / 1000;
    } else if (status === TimeEntryBreakStatusType.END_TIME_OUTSIDE) {
      return (end.toMillis() - breakStartTime.toMillis()) / 1000;
    } else if (status === TimeEntryBreakStatusType.START_TIME_OUTSIDE) {
      return (breakEndTime.toMillis() - start.toMillis()) / 1000;
    } else if (status === TimeEntryBreakStatusType.CONTAINS_ENTRY) {
      return (end.toMillis() - start.toMillis()) / 1000;
    } else {
      return 0;
    }
  });
}

export function timeEntryEndTimeOrNow(entry: Pick<ITimeEntry, 'startTime' | 'endTime'>): DateTime {
  if (!isNil(entry.endTime)) {
    return dateUtils.dateTimeFromISOWithoutZone(entry.endTime);
  } else {
    return DateTime.local()
      .setZone(dateTimeFromISOKeepZone(entry.startTime).zoneName)
      .toUTC(0, { keepLocalTime: true });
  }
}

/**
 *  This function will convert the newEndTime to be localized to the timezone of the start time,
 *  and then it puts back in the end time timezone so the metaoffset can be properly set.
 *
 *
 *  @param existingEntry the entry being modified
 *  @param newEndTime the new end time being set by the user
 */
export function getEndTimeForSubmission(existingEntry: IEntryRange, newEndTime: DateTime, timezone?: string): DateTime {
  const entryStarTime = dateUtils.dateTimeFromISOKeepZone(existingEntry.startTime);
  const entryEndTime =
    existingEntry.endTime !== null ? dateUtils.dateTimeFromISOKeepZone(existingEntry.endTime!) : newEndTime;
  return !isNil(timezone)
    ? setZone(setZone(newEndTime, timezone, false), timezone, false)
    : setZone(setZone(newEndTime, entryStarTime.zoneName, false), entryEndTime.zoneName, true);
}

/**
 *
 * This function will properly localize the end time.
 *
 * It reverses the end time being localized by the start time and then it uses the metaoffset to put end time in its proper timezone.
 *
 *  @param entry start time / end time representing time entry
 *
 */
export function displayEndTime(entry: IEntryRange): DateTime | undefined {
  const startTime = dateUtils.dateTimeFromISOKeepZone(entry.startTime);
  let endTime;
  if (!isNil(entry.endTime)) {
    endTime = setZone(
      setZone(dateUtils.dateTimeFromISOWithoutZone(entry.endTime), startTime.zoneName, true),
      dateUtils.dateTimeFromISOKeepZone(entry.endTime).zoneName
    );
  }
  return endTime;
}

export function isTimeEntryOpen(timeEntry: Pick<ITimeEntry, 'endTime'>) {
  return !timeEntry.endTime;
}

export function isTimeEntryClosed(timeEntry: Pick<ITimeEntry, 'endTime'>) {
  return !isTimeEntryOpen(timeEntry);
}

/**
 * This function will get the end time as a seconds value
 *  @param entry the time entry to get requested end time
 */
export function getEndTimeSeconds(entry: ITimeEntry) {
  return entry.endTime ? DateTime.fromISO(entry.endTime!).toMillis() / 1000 : undefined;
}
