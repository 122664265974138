import gql from 'graphql-tag';

export const COST_CODES_QUERY = gql`
  query QueryCostCodes($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      archivedOn
      costCode
      costCodeGroup {
        createdOn
        cursor
        deletedOn
        groupName
        id
        organizationId
        submittedOn
        updatedOn
      }
      costCodeGroupId
      createdOn
      cursor
      id
      organizationId
      submittedOn
      title
      unitTitle
      updatedOn
      workersCompId
    }
  }
`;

export const VALIDATE_PROJECT_COST_CODE = gql`
  query ValidateProjectCostCode($projectId: Uuid!, $costCodeId: Uuid!) {
    costCodes(
      filter: { projectCostCodeLink: { projectId: { equal: $projectId }, costCodeId: { equal: $costCodeId } } }
      first: 1
    ) {
      archivedOn
      costCode
      costCodeGroupId
      createdOn
      cursor
      id
      organizationId
      submittedOn
      title
      unitTitle
      updatedOn
      workersCompId
    }
  }
`;

export const COST_CODE_NAME_QUERY = gql`
  query QueryCostCodesNames($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: [{ costCode: asc }, { title: asc }]) {
      cursor
      id
      title
      costCode
    }
  }
`;

export const BUDGET_COST_CODE_QUERY = gql`
  query QueryCostCodesNamesWithArchived($after: String, $filter: CostCodeFilter, $sort: [CostCodeSort!], $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first, sort: $sort) {
      cursor
      id
      title
      costCode
      archivedOn
      unitTitle
    }
  }
`;

export const COST_CODE_IDS_QUERY = gql`
  query QueryCostCodeIds($after: String, $filter: CostCodeFilter, $first: Int) {
    costCodes(after: $after, filter: $filter, first: $first) {
      cursor
      id
    }
  }
`;

export const COST_CODE_CLOCK_STATE_QUERY = gql`
  query CostCodeClockState($filter: CostCodeClockStateFilter) {
    costCodeClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;
