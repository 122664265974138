import { Justify, Position, Row, Size, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import MenuButton, { IMenuButtonOptions } from 'components/foundation/buttons/MenuButton/MenuButton';
import { AnimatePresence, motion } from 'framer-motion';
import { useCurrencyNumberFormatter, useOrganization } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { PayPeriodType } from 'types/enum/PayPeriodType';
import { getMonthsBetween } from 'utils/dateUtils';
import { ILaborGraphData } from 'utils/laborGraphDataUtils';
import { getAppropriatePayPeriodForTime } from 'utils/payPeriodUtils';
import useActivityQueryParams from '../hooks/useActivityQueryParams';
import useMemberActivityPermission from '../hooks/useMemberActivityPermission';
import './ActivityReportGraph.scss';
import useActivityGraphAggregates from './useActivityGraphAggregates';

export enum ActivityReportGraphType {
  TOTAL_HOURS = 'total-hours',
  REGULAR_HOURS = 'regular-hours',
  OVERTIME_HOURS = 'overtime-hours',
  DOUBLETIME_HOURS = 'doubletime-hours',
  TOTAL_COST = 'total-cost',
  REGULAR_COST = 'regular-cost',
  OVERTIME_COST = 'overtime-cost',
  DOUBLETIME_COST = 'doubletime-cost',
  LABOR_BURDEN = 'labor_burden',
}
export interface IActivityReportGraphProps {
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  className?: ClassName;
}

const ActivityReportGraph: FunctionComponent<IActivityReportGraphProps> = (props) => {
  const { timeRange, timeRangeType, className } = props;
  const monthLimitToSwitch = 2;
  const [t] = useTranslation();
  const [label, setLabel] = useState<string>(t('Total Hrs'));
  const organization = useOrganization();
  const payPeriod = getAppropriatePayPeriodForTime(
    organization.organizationPayPeriod!,
    timeRange.startTime.toSeconds()
  )?.current;
  const formatter = useCurrencyNumberFormatter('usd', { maximumFractionDigits: 2 })!;
  const canViewCost = useHasCostPermission();
  const memberPermission = useMemberActivityPermission();

  const {
    reportType,
    projectId,
    projectGroupId,
    memberId,
    memberGroupId,
    equipmentId,
    equipmentCategoryId,
    costCodeId,
    costCodeGroupId,
    graphType,
  } = useActivityQueryParams();

  const { data, refetchData } = useActivityGraphAggregates({
    timeRange,
    reportType,
    memberId,
    projectId,
    projectGroupId,
    costCodeId,
    costCodeGroupId,
    equipmentId,
    equipmentCategoryId,
    memberGroupId,
    memberPermission,
    monthLimitToSwitch,
  });

  const getMonthsBetweenRange = useCallback(() => {
    return getMonthsBetween(timeRange.startTime, timeRange.endTime);
  }, [timeRange]);

  const appFont = useRef({
    color: '#30373d',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: '"Nunito Sans", sans-serif',
    lineHeight: '24px',
  });

  const labelStyle = useRef({
    ...appFont.current,
    fontWeight: 600,
  });

  useEffect(() => {
    if (graphType) {
      switch (graphType) {
        case ActivityReportGraphType.TOTAL_HOURS:
          setLabel('Total Hrs');
          break;
        case ActivityReportGraphType.REGULAR_HOURS:
          setLabel('Regular Hrs');
          break;
        case ActivityReportGraphType.OVERTIME_HOURS:
          setLabel('Overtime Hrs');
          break;
        case ActivityReportGraphType.DOUBLETIME_HOURS:
          setLabel('Double Time Hrs');
          break;
        case ActivityReportGraphType.TOTAL_COST:
          setLabel('Total Cost');
          break;
        case ActivityReportGraphType.REGULAR_COST:
          setLabel('Regular Cost');
          break;
        case ActivityReportGraphType.OVERTIME_COST:
          setLabel('Overtime Cost');
          break;
        case ActivityReportGraphType.DOUBLETIME_COST:
          setLabel('Double Time Cost');
          break;
        case ActivityReportGraphType.LABOR_BURDEN:
          setLabel('Labor Burden');
          break;
      }
    }
  }, [graphType]);

  const onSelectOption = (value: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLabel(value);
  };

  const menuOptions = useRef<IMenuButtonOptions[]>(getMenuOptions());

  function getMenuOptions() {
    const timeOptions = [
      { label: t('Total Hrs'), onSelect: onSelectOption('Total Hrs') },
      { label: t('Regular Hrs'), onSelect: onSelectOption('Regular Hrs') },
      { label: t('Overtime Hrs'), onSelect: onSelectOption('Overtime Hrs') },
      { label: t('Double Time Hrs'), onSelect: onSelectOption('Double Time Hrs') },
    ];

    // return cost options if user can see costs
    if (canViewCost) {
      return timeOptions.concat([
        { label: t('Total Cost'), onSelect: onSelectOption('Total Cost') },
        { label: t('Regular Cost'), onSelect: onSelectOption('Regular Cost') },
        { label: t('Overtime Cost'), onSelect: onSelectOption('Overtime Cost') },
        { label: t('Double Time Cost'), onSelect: onSelectOption('Double Time Cost') },
        { label: t('Labor Burden'), onSelect: onSelectOption('Labor Burden') },
      ]);
    }

    return timeOptions;
  }

  function dateFormatted(tickItem: number) {
    const dateTime = DateTime.fromSeconds(tickItem, { zone: 'utc' });
    switch (timeRangeType) {
      case TimeRangeType.WEEKLY:
        return dateTime.toFormat(getDateFormatWithYearConsideration('ccc'));
      case TimeRangeType.MONTHLY:
        return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
      case TimeRangeType.PAY_PERIOD:
        if (payPeriod?.payPeriodType !== PayPeriodType.WEEKLY) {
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
        } else {
          return dateTime.toFormat(getDateFormatWithYearConsideration('ccc'));
        }
      case TimeRangeType.CUSTOM: {
        const duration = timeRange.endTime.toSeconds() - timeRange.startTime.toSeconds();
        if (getMonthsBetweenRange().length > 2) {
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM'));
        } else if (duration > Duration.fromObject({ week: 1 }).as('seconds')) {
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
        } else {
          // This gets weird to use `ccc` because if you have less than a week on a previous week
          return dateTime.toFormat(getDateFormatWithYearConsideration('MMM, d'));
        }
      }
    }

    return '';
  }

  function getDateFormatWithYearConsideration(format: string) {
    const sameYear =
      timeRange.startTime.hasSame(timeRange.endTime, 'year') && timeRange.startTime.hasSame(DateTime.utc(), 'year');
    return sameYear ? format : format + ', yyyy';
  }

  function getTotalValue() {
    let aggregateValue: number = 0;
    switch (label) {
      case t('Total Hrs'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.totalHours);
        return Duration.fromMillis(aggregateValue! * 1000).toFormat('h:mm');
      case t('Regular Hrs'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.regularHours);
        return Duration.fromMillis(aggregateValue! * 1000).toFormat('h:mm');
      case t('Overtime Hrs'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.overtimeHours);
        return Duration.fromMillis(aggregateValue! * 1000).toFormat('h:mm');
      case t('Double Time Hrs'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.doubleTimeHours);
        return Duration.fromMillis(aggregateValue! * 1000).toFormat('h:mm');
      case t('Total Cost'): // TODO: update with cost values once we have them
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.totalCost);
        return formatter.format(aggregateValue);
      case t('Regular Cost'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.regularCost);
        return formatter.format(aggregateValue);
      case t('Overtime Cost'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.overtimeCost);
        return formatter.format(aggregateValue);
      case t('Double Time Cost'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.doubleTimeCost);
        return formatter.format(aggregateValue);
      case t('Labor Burden'):
        aggregateValue = _.sumBy(data, (aggregate) => aggregate.laborBurden);
        return formatter.format(aggregateValue);
    }
  }

  function getLineDataKey(): keyof ILaborGraphData {
    switch (label) {
      case t('Total Hrs'):
        return 'totalHours';
      case t('Regular Hrs'):
        return 'regularHours';
      case t('Overtime Hrs'):
        return 'overtimeHours';
      case t('Double Time Hrs'):
        return 'doubleTimeHours';
      case t('Total Cost'):
        return 'totalCost';
      case t('Regular Cost'):
        return 'regularCost';
      case t('Overtime Cost'):
        return 'overtimeCost';
      case t('Double Time Cost'):
        return 'doubleTimeCost';
      case t('Labor Burden'):
        return 'laborBurden';
      default:
        return 'totalHours';
    }
  }

  function hasTimeOptionSelected() {
    return (
      label === t('Total Hrs') ||
      label === t('Regular Hrs') ||
      label === t('Overtime Hrs') ||
      label === t('Double Time Hrs')
    );
  }

  function tooltipValue(value: number) {
    if (hasTimeOptionSelected()) {
      return [Duration.fromMillis(value * 1000).toFormat('h:mm'), ''];
    } else {
      return [formatter.format(value), ''];
    }
  }

  const classes = classNames('activity-report-graph-header', className);

  return (
    <AnimatePresence>
      <motion.div
        className={classes}
        initial={{ opacity: 0.2, y: -40 }}
        animate={{ opacity: 1.0, y: 0 }}
        exit={{ opacity: 0.2, y: -40 }}
        transition={{ duration: 0.5 }}
      >
        <Row justify={Justify.SPACE_BETWEEN}>
          <div className="activity-report-text-content">
            <div className="activity-report-graph-header-label">{label}</div>
            <div className="activity-report-graph-header-total">{getTotalValue()}</div>
          </div>
          {/* Don't show graph on daily just total */}
          {timeRangeType !== TimeRangeType.DAILY &&
            !(timeRangeType === TimeRangeType.CUSTOM && timeRange.startTime.hasSame(timeRange.endTime, 'day')) && (
              <ResponsiveContainer height="100%" width="70%">
                {/* Animation requires a new set of data, this is broken when you just change the key so reassign data and it'll animate with new data key */}
                <LineChart data={[...data]} margin={{ top: 5, right: 30, left: 30, bottom: 5 }}>
                  <Line
                    type="monotone"
                    dataKey={getLineDataKey()}
                    dot={false}
                    activeDot={{ r: 4 }}
                    strokeWidth={2.0}
                    stroke={'#FFF'}
                  />
                  <XAxis
                    dataKey={'day'}
                    tickSize={0}
                    tickMargin={15}
                    tickFormatter={dateFormatted}
                    interval={'preserveStartEnd'}
                    stroke={'#FFF'}
                    tick={{ fontSize: 12 }}
                  />
                  <Tooltip
                    // Separator between the name and the value since we aren't using a name set to nothing
                    separator={''}
                    // Second argument is the name which we are not using
                    formatter={(value) => tooltipValue(value as number)}
                    labelStyle={labelStyle.current}
                    itemStyle={appFont.current}
                    labelFormatter={dateFormatted as any}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
        </Row>
        <MenuButton
          size={Size.MEDIUM}
          buttonSize={Size.XL}
          position={Position.BOTTOM_END}
          theme={Theme.DARK}
          options={menuOptions.current}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default ActivityReportGraph;
