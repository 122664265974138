import { FormattedHoursPayload, formatTime } from 'utils/timeUtils';

export interface IFormattedHoursProps {
  payload: FormattedHoursPayload;
}

function FormattedHours({ payload }: IFormattedHoursProps) {
  return <>{formatTime(payload)}</>;
}

export default FormattedHours;
