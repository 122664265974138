import { List, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { uuid } from 'utils/uuidUtils';
import './DashboardList.scss';
import React, { ReactNode } from 'react';
import IIdable from 'types/Idable';

export interface IDashboardListRow {
  id: string;
  value: string;
  label: string;
}

export function getDefaultDashboardListRow(): IDashboardListRow {
  return { label: '---', value: '', id: uuid() };
}

export interface IDashboardListProps<T extends IIdable<any>> {
  hover: boolean;
  renderItem: (item: T) => ReactNode;
  rowItems: T[];
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
}

function DashboardList<T extends IIdable<any>>({
  rowItems,
  className,
  hover,
  theme,
  renderItem,
}: IDashboardListProps<T>) {
  const classes = classNames('dashboard-list', theme, className);

  return (
    <List stroke={true} className={classes} hover={hover}>
      {rowItems.map((rowItem) => (
        <React.Fragment key={rowItem.id}>{renderItem(rowItem)}</React.Fragment>
      ))}
    </List>
  );
}

export default DashboardList;
