import { useActiveMember } from 'hooks';
import { DateTime } from 'luxon';
import { fullName } from 'utils/memberUtils';
import useSlackClient from './useSlackClient';

export default function useSlackProgressTrackingAccess() {
  const client = useSlackClient();
  const member = useActiveMember();

  function requestAccess() {
    const metaData = `*MemberId*: ${member.id}
*OrganizationId*: ${member.organizationId}
*OrganizationName*: ${member.organization?.organizationName}
*PositionId*: ${member.positionId}
*PositionName*: ${member.position?.title}
*Source*: annoucement-banner
*Date*: ${DateTime.local().toFormat('MMM dd, yyyy')}
    `;

    const intercomSearchString = `{"predicates":[{"attribute":"user_id","comparison":"eq","type":"string","value":"${member.id}"}]}`;
    const encodedSearchString = btoa(intercomSearchString);
    const intercomLink = `https://app.intercom.com/a/apps/g7z2j2lo/users/segments/all-users:${encodedSearchString}`;

    const requestBody = {
      text: member.organization?.organizationName,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `*${member.organization?.organizationName}*\n${fullName(member)}`,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: metaData,
          },
        },
        {
          type: 'actions',
          elements: [
            {
              type: 'button',
              text: {
                type: 'plain_text',
                text: 'Intercom',
              },
              url: intercomLink,
              action_id: 'actionId-0',
            },
          ],
        },
        {
          type: 'divider',
        },
      ],
    };

    return client.post('T025A2121/B072U20C9RS/y75SmEgfq10mQVHojX10rfaD', JSON.stringify(requestBody));
  }

  return requestAccess;
}
