import useIsBeta from 'hooks/meta/useIsBeta';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setGustoHasSignedTos } from 'store/gusto/gusto';
import useGustoCompanyId from '../../company/useGustoCompanyId/useGustoCompanyId';
import useGustoGetTermsOfService from '../useGustoGetTermsOfService/useGustoGetTermsOfService';
import useGustoGetActiveEmployeeEmail from '../../active-employee/useGustoGetActiveEmployeeEmail/useGustoGetActiveEmployeeEmail';

export default function useGustoUpdateTermsOfServiceStatusState() {
  const gustoCompanyId = useGustoCompanyId();
  const getGustoActiveMemberEmail = useGustoGetActiveEmployeeEmail();
  const getTosStatus = useGustoGetTermsOfService();
  const isBeta = useIsBeta();

  const dispatch = useDispatch();

  return useCallback(async () => {
    if (isBeta) {
      dispatch(setGustoHasSignedTos(true));
    } else if (gustoCompanyId) {
      const email = await getGustoActiveMemberEmail();
      // Cannot accept ToS without an email
      if (!email) {
        dispatch(setGustoHasSignedTos(false));
      } else {
        const result = await getTosStatus(gustoCompanyId, email);
        dispatch(setGustoHasSignedTos(result.data.latest_terms_accepted));
      }
    }
  }, [isBeta, gustoCompanyId, dispatch, getGustoActiveMemberEmail, getTosStatus]);
}
