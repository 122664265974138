import { Image, ITableColumn, Table, TextAlign } from '@busybusy/webapp-react-ui';
import { correct_large_web, incorrect_large_web } from 'assets/images';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useTableSorting } from 'hooks';
import 'i18nMock';
import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IImportedEquipment } from '../CSVImportEquipmentContainer';
import './CSVImportEquipmentReviewTable.scss';

export interface ICSVImportEquipmentReviewTableProps {
  className?: ClassName;
  onClose?: () => void;
  equipment: IImportedEquipment[];
  loading: boolean;
  lazyLoad: boolean;
  lazyScrollTrigger?: HTMLElement;
}

const CSVImportEquipmentReviewTable: FunctionComponent<ICSVImportEquipmentReviewTableProps> = (props) => {
  const { equipment, loading, lazyLoad, lazyScrollTrigger, className } = props;

  const [t] = useTranslation();

  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(equipment);

  function renderSuccessIcon(row: IImportedEquipment, col: any): ReactNode {
    return <Image src={row.success ? correct_large_web : incorrect_large_web} className="p-0" />;
  }

  function getColumns(): Array<ITableColumn<IImportedEquipment>> {
    return [
      {
        cell: renderSuccessIcon,
        key: 'success',
        align: TextAlign.CENTER,
        cellClassName: 'success-icon',
        headerClassName: 'success-icon',
        size: '30px',
      },
      { key: 'name', sort: true, title: t('Name') },
      { key: 'category', sort: true, title: t('Category') },
      { key: 'make', sort: true, title: t('Make') },
      { key: 'model', sort: true, title: t('Model') },
      { key: 'year', sort: true, title: t('Year') },
      { key: 'hourMeter', sort: true, title: t('Hour Meter') },
      { key: 'hourlyRate', sort: true, title: t('Hourly Rate') },
    ];
  }

  const classes = classNames('csv-import-equipment-review-table', className);

  return (
    <Table
      className={classes}
      cols={getColumns()}
      data={sorted}
      minWidth={`${getColumns().length * 240}px`}
      sortDir={sortDirection}
      sortBy={sortedBy}
      lazyScrollSectionSize={50}
      lazyLoad={lazyLoad}
      lazyScrollTrigger={lazyScrollTrigger}
      isLoading={loading}
      strokeCols={false}
      sortIsDirty={sortIsDirty}
      onSort={onSort}
    />
  );
};

export default CSVImportEquipmentReviewTable;
