import { getTimeFromHourMinuteSecondString } from 'utils/dateUtils';
import useOrganization from '../../store/useOrganization';

export default function useDefaultTimes() {
  const organization = useOrganization();

  function getDefaultStartTime() {
    if (organization.defaultStartTime) {
      return getTimeFromHourMinuteSecondString(organization.defaultStartTime);
    } else {
      return getTimeFromHourMinuteSecondString('07:00:00');
    }
  }

  function getDefaultEndTime() {
    if (organization.defaultEndTime) {
      return getTimeFromHourMinuteSecondString(organization.defaultEndTime);
    } else {
      return getTimeFromHourMinuteSecondString('16:00:00');
    }
  }

  function getDefaultBreakDuration() {
    if (organization.defaultBreakDuration) {
      return getTimeFromHourMinuteSecondString(organization.defaultBreakDuration);
    } else {
      return getTimeFromHourMinuteSecondString('00:30:00');
    }
  }

  return {
    defaultStartTime: getDefaultStartTime(),
    defaultEndTime: getDefaultEndTime(),
    defaultBreakDuration: getDefaultBreakDuration(),
  };
}
