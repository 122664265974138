import { usePayPeriodPopulator, useWeeklyPopulator } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import times from 'lodash/times';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import ITimeRange from 'types/TimeRange';
import { utcToday } from 'utils/dateUtils';
import { DashboardTimeFrame } from '../types/types';

export default function useDashboardGraphDates(timeFrame: DashboardTimeFrame) {
  const weeklyRangeGet = useWeeklyPopulator();
  const payPeriodRangeGet = usePayPeriodPopulator();

  const getTimeRange = useCallback((): Array<ITimeRange<DateTime>> => {
    const currentTime = utcToday().startTime;
    switch (timeFrame) {
      case 'daily':
        return [{ startTime: currentTime, endTime: currentTime.endOf('day') }];
      case 'payPeriod':
        return payPeriodRangeGet(6, currentTime.toSeconds());
      case 'weekly':
        return weeklyRangeGet(6, currentTime.toSeconds());
      case 'pastSeven':
        return times(7, (n) => {
          const time = currentTime.minus({ days: 6 - n });
          return {
            startTime: time,
            endTime: time.endOf('day').startOf('second'),
          };
        });
      case 'pastFourteen':
        return times(14, (n) => {
          const time = currentTime.minus({ days: 13 - n });
          return {
            startTime: time,
            endTime: time.endOf('day').startOf('second'),
          };
        });
      case 'monthly':
      default:
        return times(6, (n) => {
          const month = currentTime.startOf('month').minus({ months: 5 - n });
          return {
            startTime: month,
            endTime: month.endOf('month').startOf('second'),
          };
        });
    }
  }, [payPeriodRangeGet, timeFrame, weeklyRangeGet]);

  return useMemo(() => {
    const ranges = getTimeRange();
    if (isEmpty(ranges)) {
      throw Error('`useDashboardGraphDates` resulted in an empty array');
    } else {
      const start = ranges[0];
      const end = ranges[ranges.length - 1];
      return {
        timeRanges: ranges.map((range) => ({
          startTime: range.startTime.toSeconds(),
          endTime: range.endTime.toSeconds(),
        })),
        timeRange: { startTime: start.startTime, endTime: end.endTime },
      };
    }
  }, [getTimeRange]);
}
