import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { parse, stringify } from 'utils/queryStringUtils';
import { useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useQueryParams(initialState?: { [key: string]: string }) {
  const location = useLocation();
  const navigate = useNavigate();
  const initial = useRef(initialState);

  useOnMount(() => {
    if (initial.current) {
      navigate({ search: stringify(initial.current) }, { replace: true });
    }
  });

  const setParam = useCallback(
    (key: string, value?: string | null) => {
      const queryParams = { ...parse(location.search) };

      if (value) {
        queryParams[key] = value;
      } else {
        delete queryParams[key];
      }

      navigate({ search: stringify(queryParams) }, { replace: true });
    },
    [location.search, navigate]
  );

  const setParams = useCallback(
    (params: { [key: string]: string }) => {
      navigate({ search: stringify(params) }, { replace: true });
    },
    [navigate]
  );

  const getParam = useCallback(
    <T = string>(key: string): T | null => {
      const params = parse(location.search);
      const value = params[key];
      return (value as unknown as T | undefined) ?? null;
    },
    [location.search]
  );

  const getParams = useCallback(() => {
    return parse(location.search);
  }, [location.search]);

  const updateParams = useCallback(
    (params: { [key: string]: string | Array<string | null> | undefined | null }) => {
      const queryParams = { ...parse(location.search) };

      Object.keys(params).forEach((k) => {
        const value = params[k];
        if (value) {
          queryParams[k] = value;
        } else {
          delete queryParams[k];
        }
      });

      navigate({ search: stringify(queryParams) }, { replace: true });
    },
    [location.search, navigate]
  );

  return { getParam, getParams, setParam, setParams, updateParams };
}
