import { Button, Col, Field, FieldFooter, Row, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'utils/localize';
import { FileUploader } from '../../..';
import { IFileWithPreview } from '../../../../types/FileUploader';
import './ProfileImageFormField.scss';

interface IProfileImageFormFieldProps {
  className?: ClassName;
  form: any;
  name: string;
  initialImage: string | null;
  onChange?: (file: IFileWithPreview) => void;
  validations?: any;
}

function ProfileImageFormField(props: IProfileImageFormFieldProps) {
  const { form, name, validations, className, onChange, initialImage, ...other } = props;

  const classes = classNames('profile-image-form-field', className);

  const [image, setImage] = useState<string>(initialImage || '');

  // Handle field value change
  function handleOnChange(fieldProps: any, files: IFileWithPreview[]) {
    const file = files.length ? files[0] : null;

    if (file) {
      setImage(file.preview);
      fieldProps.handleChange(file, onChange);
    } else {
      fieldProps.handleChange(null, onChange);
      setImage('');
    }
  }

  // Handle 'Clear' button click
  function handleClear(fieldProps: any) {
    fieldProps.handleChange(null, onChange);
    setImage('');
  }

  // Render the form field
  function renderField({ ...fieldProps }) {
    function onFileChange(files: IFileWithPreview[]) {
      handleOnChange(fieldProps, files);
    }

    function onClear() {
      handleClear(fieldProps);
    }

    const background = image ? image : initialImage ? initialImage : null;
    const imageStyle = background ? { backgroundImage: `url("${background}")` } : {};

    return (
      <>
        {image ? (
          <Row gutter={1} className="profile-image-preview">
            <Col>
              <div className="profile-image-container" style={imageStyle} />
            </Col>
            <Col className="align-right">
              <Button type="secondary" className="mt-2 mr-2" onClick={onClear} size={Size.SMALL}>
                {t('Remove')}
              </Button>
            </Col>
          </Row>
        ) : (
          <FileUploader
            filesWithPreview={[]}
            multiple={false}
            message={
              <Trans>
                <p className="fw-semi-bold">
                  <span>Click</span>&nbsp;or drag to upload profile image
                </p>
              </Trans>
            }
            onChange={onFileChange}
            {...other}
          />
        )}
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  // Render
  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
}

ProfileImageFormField.defaultProps = {
  mediaEntries: [],
};

export default ProfileImageFormField;
