import { first } from 'lodash';

// https://rangle.io/blog/how-to-use-typescript-type-guards/
export const isType = <T>(value: any, propertyToCheck: keyof T): value is T =>
  (value as T)[propertyToCheck] !== undefined;

/**
 *  Checks if a given array is of a certain type if it contains the given property.
 *
 * @param value the array to check the type of
 * @param propertyToCheck a unique property found on the type
 * @returns {@true} if the array is an array of objects that have the given property. {@false} if the array is empty or it objects in the array don't have the given property.
 */
export const isArrayOfType = <T>(value: any[], propertyToCheck: keyof T): value is T[] => {
  const sample = first(value);
  return sample && isType<T>(sample, propertyToCheck);
};
