import { SortDirection } from '@busybusy/webapp-react-ui';
import useCostCodeActivityDateRangeDetails, {
  ICostCodeDateActivityTableRowInfo,
} from 'containers/activity-reports/cost-code-tables/hooks/useCostCodeActivityDateRangeDetails';
import { useTableSorting } from 'hooks';
import { first, groupBy, isNil, keys, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { CostCode, LaborMetricsInterval } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { aggregateActivityRows } from '../../hooks/ActivitySummaryQueryUtils';
import { IDateActivityTableRowInfo } from './useDateActivity';

export interface IDateCostCodeActivityTableRowInfo extends IDateActivityTableRowInfo {
  costCodeId: string | null;
  costCode: CostCode | null;
}

export default function useDateActivityCostCodeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const [data, setData] = useState<IDateCostCodeActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'startDate',
    SortDirection.ASCENDING
  );

  const {
    loadData,
    sortedData: costCodeData,
    remainingData,
  } = useCostCodeActivityDateRangeDetails(filterId, filterType, timeRange, intervalType, filterIdWithDescendants);

  useEffect(() => {
    updateData();
  }, [costCodeData]);

  function updateData() {
    const dataForDates: IDateCostCodeActivityTableRowInfo[] = costCodeData.flatMap((row) => {
      const dateRows = row.detailRows as ICostCodeDateActivityTableRowInfo[] | undefined;
      // add the date to all the cost code rows
      return (
        dateRows?.map((data) => {
          return {
            ...data,
            startDate: data.startDate!,
            endDate: data.endDate!,
            costCodeId: data.costCode!.id,
            detailRows: [],
          };
        }) ?? []
      );
    });

    if (!isNil(remainingData)) {
      const dateRows = remainingData.detailRows as ICostCodeDateActivityTableRowInfo[] | undefined;

      dateRows?.forEach((data) => {
        dataForDates.push({
          ...data,
          startDate: data.startDate!,
          endDate: data.endDate!,
          costCodeId: remainingDataItemId,
          costCode: null,
          detailRows: [],
        });
      });
    }

    const dataByDate = groupBy(dataForDates, (data) => data.startDate);
    const dates = keys(dataByDate);

    setData(
      dates.map((date) => {
        const rowData = dataByDate[date];
        const firstRow = first(rowData)!;
        const totalMetrics = aggregateActivityRows(rowData);

        const noCostCode = rowData.find((row) => isNil(row.costCode));

        const sortedDetailRows = sortBy(
          rowData.filter((row) => !isNil(row.costCode)),
          (row) => getCostCodeDisplay(row.costCode)
        );

        if (!isNil(noCostCode)) {
          sortedDetailRows.push(noCostCode);
        }

        return {
          ...totalMetrics,
          id: date,
          startDate: firstRow.startDate!,
          endDate: firstRow.endDate!,
          costCodeId: null,
          costCode: null,
          detailRows: sortedDetailRows,
        };
      })
    );
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
  };
}
