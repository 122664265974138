import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IEquipmentManagementSettings } from 'types/EquipmentManagementSettings';
import useMemberFeatureSettingsUpdate from '../member/useMemberFeatureSettingsUpdate';
import useMemberSettings from '../member/useMemberSettings';

export default function useEquipmentManagementSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateFeatureSettings = useMemberFeatureSettingsUpdate();

  return useCallback(
    <K extends keyof IEquipmentManagementSettings>(
      keysAndPayloads: { key: K; payload: IEquipmentManagementSettings[K] }[]
    ) => {
      const clonedEquipment = cloneDeep(memberSettings?.web?.features?.managementLists?.equipment);
      const clonedManagementLists = cloneDeep(memberSettings?.web?.features?.managementLists);

      const mapped = keysAndPayloads.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.key]: cur.payload,
        }),
        clonedEquipment
      );

      return updateFeatureSettings('managementLists', { ...clonedManagementLists, equipment: mapped });
    },
    [memberSettings, updateFeatureSettings]
  );
}
