import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { t } from 'utils/localize';
import BasicNonNullPicker, {
  IBasicNonNullPickerProps,
} from '../../../foundation/pickers/basic-picker/BasicNonNullPicker/BasicNonNullPicker';
import { IBasicPickerValue } from '../../../foundation/pickers/basic-picker/BasicPicker/BasicPicker';

export type ArchivedStatus = 'all' | 'unarchived' | 'archived';

export interface IArchivedPickerProps extends Omit<IBasicNonNullPickerProps<ArchivedStatus>, 'data' | 'placeholder'> {
  isProject?: boolean | null;
}

const options: Array<IBasicPickerValue<ArchivedStatus>> = [
  { id: 'all', name: t('All') },
  { id: 'unarchived', name: t('Active') },
  { id: 'archived', name: t('Archived') },
];

const projectOptions: Array<IBasicPickerValue<ArchivedStatus>> = [
  { id: 'unarchived', name: t('Active Projects') },
  { id: 'archived', name: t('Archived Projects') },
  { id: 'all', name: t('All Projects') },
];

function ArchivedPicker({ className, ...pickerProps }: IArchivedPickerProps) {
  return (
    <BasicNonNullPicker
      className={classNames('archived-picker', className)}
      data={pickerProps.isProject ? projectOptions : options}
      {...pickerProps}
    />
  );
}

export default ArchivedPicker;
