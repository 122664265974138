import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IBudgetsSettings } from 'types/BudgetsSettings';
import useMemberFeatureSettingsUpdate from '../member/useMemberFeatureSettingsUpdate';
import useBudgetSettings from './useBudgetSettings';

export default function useBudgetsSettingsUpdate() {
  const budgetSettings = useBudgetSettings();
  const updateFeatureSettings = useMemberFeatureSettingsUpdate();

  return useCallback(
    (settings: Partial<IBudgetsSettings>) => {
      const cloned = cloneDeep(budgetSettings);
      return updateFeatureSettings('budgets', { ...cloned, ...settings });
    },
    [budgetSettings, updateFeatureSettings]
  );
}
