import _, { isNaN, sortBy } from 'lodash';
import { DateTime, Duration } from 'luxon';
import IOrganizationOvertimePeriod from 'types/OrganizationOvertimePeriod';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import { clampInsideDate, formatTimeRange, getEndOfTodayLocal } from './dateUtils';

export function getWeekRangeForTime(
  overtimePeriods: IOrganizationOvertimePeriod[],
  time: number,
  zone: 'system' | 'utc' = 'utc'
) {
  const sortedPeriods = _.sortBy(
    overtimePeriods ? overtimePeriods.filter((p) => _.isNil(p.deletedOn)) : [],
    'startTime',
    'desc'
  );
  const overtimePeriod = sortedPeriods.reduce<{ period: IOrganizationOvertimePeriod; index: number } | null>(
    (acc, cur, index) => {
      const overtimeTime = DateTime.fromISO(cur.startTime).setZone(zone, { keepLocalTime: true }).toSeconds();
      if (!acc && overtimeTime <= time) {
        return { period: cur, index };
      } else if (acc) {
        const accumulatorTime = DateTime.fromISO(acc.period.startTime)
          .setZone(zone, { keepLocalTime: true })
          .toSeconds();
        if (overtimeTime > accumulatorTime && overtimeTime <= time) {
          return { period: cur, index };
        } else {
          return acc;
        }
      } else {
        return null;
      }
    },
    null
  );

  if (overtimePeriod) {
    const timeRange = getTimeRangeForWeek(overtimePeriod.period, time);

    // check for overtime period change in between the week
    if (overtimePeriod.index + 1 < sortedPeriods.length) {
      const next = sortedPeriods[overtimePeriod.index + 1];

      const nextStart = getOvertimeStartOfWeek(
        next,
        DateTime.fromSeconds(timeRange.endTime).plus({ day: 1 }).setZone(zone, { keepLocalTime: true }).toSeconds()
      );

      if (timeRange.endTime > nextStart && timeRange.startTime !== nextStart) {
        timeRange.endTime = nextStart - 1;
      }
    }

    return {
      startTime: DateTime.fromSeconds(timeRange.startTime).toUTC().setZone(zone, { keepLocalTime: true }),
      endTime: DateTime.fromSeconds(timeRange.endTime).toUTC().setZone(zone, { keepLocalTime: true }),
    };
  }

  return null;
}

export function getTimeRangeForWeek(overtimePeriod: IOrganizationOvertimePeriod, time: number): ITimeRange<number> {
  const startTime = getOvertimeStartOfWeek(overtimePeriod, time);
  const date = DateTime.fromSeconds(startTime).toUTC().plus({ days: overtimePeriod.lengthInDays! });
  return { startTime, endTime: date.toSeconds() - 1 };
}

export function getOvertimeStartOfWeek(overtimePeriod: IOrganizationOvertimePeriod, time: number): number {
  const timeStartOfDay = DateTime.fromSeconds(time).toUTC().startOf('day');
  const overtimeStartOfDay = DateTime.fromISO(overtimePeriod.startTime, { zone: 'utc' }).startOf('day');

  const numberOfDays = Duration.fromMillis(
    _.round(timeStartOfDay.toSeconds() - overtimeStartOfDay.toSeconds()) * 1000
  ).as('days');
  const offset = _.floor(numberOfDays % overtimePeriod.lengthInDays!);
  return timeStartOfDay.minus({ days: !isNaN(offset) ? offset : 0 }).toSeconds();
}

export function timeRangesAreEqual(timeRange1: ITimeRange<DateTime>, timeRange2: ITimeRange<DateTime>): boolean {
  return timeRange1.startTime.equals(timeRange2.startTime) && timeRange1.endTime.equals(timeRange2.endTime);
}

export function timeRangeDatesAreEqual(
  timeRange1: ITimeRange<DateTime | null>,
  timeRange2: ITimeRange<DateTime | null>
) {
  return compareTimeRanges(timeRange1, timeRange2, (a, b) => a?.toISODate() === b?.toISODate());
}

export function compareTimeRanges<T>(
  timeRange1: ITimeRange<T>,
  timeRange2: ITimeRange<T>,
  comparison: (date1: T, date2: T) => boolean
) {
  return comparison(timeRange1.startTime, timeRange2.startTime) && comparison(timeRange1.endTime, timeRange2.endTime);
}

export function timeRangesInRange(
  timeRanges: Array<ITimeRange<DateTime>>,
  activeRange: ITimeRange<DateTime>
): Array<ITimeRange<DateTime>> {
  return timeRanges.filter((timeRange) => {
    return timeRange.startTime < activeRange.endTime && timeRange.endTime > activeRange.startTime;
  });
}

export function mapRange<R>(timeRange: ITimeRange<DateTime>, transform: (dateTime: DateTime) => R) {
  return {
    startTime: transform(timeRange.startTime),
    endTime: transform(timeRange.endTime),
  };
}

export function convertDateTimeRangeToIsoRange(timeRange: ITimeRange<DateTime>) {
  return mapRange(timeRange, (date) => date.toISO());
}

export function convertDateTimeRangeToSecondsRange(timeRange: ITimeRange<DateTime>) {
  return mapRange(timeRange, (date) => date.toSeconds());
}

export function getDateRangeFormatByType(timeRange: ITimeRange<DateTime>, timeRangeType: TimeRangeType) {
  const today = DateTime.local();
  const sameYears = today.hasSame(timeRange.startTime, 'year') && today.hasSame(timeRange.endTime, 'year');
  if (timeRangeType === TimeRangeType.MONTHLY) {
    return sameYears ? 'MMMM' : 'MMMM, yyyy';
  } else {
    return sameYears ? 'EEE, MMM d' : 'EEE, MMM d, yyyy';
  }
}

export function getFormattedDateRangeByType(timeRange: ITimeRange<DateTime>, timeRangeType: TimeRangeType) {
  return formatTimeRange(timeRange, timeRangeType, getDateRangeFormatByType(timeRange, timeRangeType));
}

export function getTodayRange(zone: 'system' | 'local') {
  const todayZone = DateTime.fromObject({}, { zone });
  return {
    startTime: todayZone.startOf('day'),
    endTime: todayZone.endOf('day'),
  };
}

export function getSetCustomTimeRange(
  { startTime, endTime }: ITimeRange<Nullable<DateTime>>,
  endTimeIfNotSet = getEndOfTodayLocal(),
  startTimeIfNotSet = (endTime ?? endTimeIfNotSet).startOf('day')
): Nullable<ITimeRange<DateTime>> {
  if (startTime && endTime) {
    return { startTime, endTime };
  } else if (!endTime && startTime) {
    return { startTime, endTime: endTimeIfNotSet };
  } else if (!startTime && endTime) {
    return { startTime: startTimeIfNotSet, endTime };
  } else {
    return null;
  }
}

export function getTimeRangeIso({ startTime, endTime }: ITimeRange<DateTime>) {
  return `${startTime.toISO()} - ${endTime.toISO()}`;
}

export function clampTimeRangeInsideDate(timeRange: ITimeRange<DateTime>, date: DateTime) {
  return {
    startTime: clampInsideDate(timeRange.startTime, date),
    endTime: clampInsideDate(timeRange.endTime, date),
  };
}
export function getTimeRangeDurationOnDay(timeRange: ITimeRange<DateTime>, day: DateTime): Duration {
  const { startTime, endTime } = clampTimeRangeInsideDate(timeRange, day);
  return endTime.diff(startTime, 'seconds');
}

export function getTimeRangeDurationSeconds(timeRange: ITimeRange<DateTime>) {
  return timeRange.endTime.toSeconds() - timeRange.startTime.toSeconds();
}

export function collapseOverlappingRanges(timeRanges: Array<ITimeRange<DateTime>>): Array<ITimeRange<DateTime>> {
  const sortedRanges = sortBy(timeRanges, (timeRange) => timeRange.startTime.toSeconds());

  const ranges: Array<ITimeRange<DateTime>> = [];
  let currentRange: ITimeRange<DateTime> | null = null;

  for (let i = 0; i < sortedRanges.length; i++) {
    const timeEntryRange = sortedRanges[i];

    if (!currentRange) {
      currentRange = timeEntryRange;
      continue;
    }

    // Since it's sorted we only have to check the start time
    const timeEntryRangeStartSeconds = timeEntryRange.startTime.toSeconds();
    const isTimeEntryRangeWithinCurrentRange =
      timeEntryRangeStartSeconds >= currentRange.startTime.toSeconds() &&
      timeEntryRangeStartSeconds < currentRange.endTime.toSeconds();

    if (isTimeEntryRangeWithinCurrentRange) {
      const isTimeEntryRangeEndAfterCurrentRangeEnd =
        timeEntryRange.endTime.toSeconds() > currentRange.endTime.toSeconds();

      if (isTimeEntryRangeEndAfterCurrentRangeEnd) {
        currentRange = { startTime: currentRange.startTime, endTime: timeEntryRange.endTime };
        continue;
      }
    } else {
      ranges.push(currentRange);
      currentRange = timeEntryRange;
    }
  }

  // Push the last element on if it wasn't already included in the range
  if (currentRange !== null) {
    ranges.push(currentRange);
  }

  return ranges;
}
