const SETUP_GUSTO_ACCOUNT = 'Setup Gusto Account';
const REGISTER_GUSTO_EMPLOYEE = 'Register Gusto Employee';
const RUN_GUSTO_PAYROLL = 'Run Gusto Payroll';
const SUBMIT_GUSTO_PAYROLL = 'Submit Gusto Payroll';
const CANCEL_GUSTO_PAYROLL = 'Cancel Gusto Payroll';
const VIEW_GUSTO_PAYROLL = 'View Gusto Payroll';
const EXPAND_GUSTO_MEMBER_BREAKDOWN = 'Expand Gusto Member Breakdown';
const OPEN_GUSTO_MEMBER_MATCHER = 'Open Gusto Member Matcher';
const CREATE_MEMBER_IN_GUSTO_MEMBER_MATCHER = 'Create Member In Gusto Member Matcher';
const SUBMIT_GUSTO_MEMBER_MATCHER = 'Submit Gusto Member Matcher';
const SELECT_GUSTO_COMPANY = 'Select Gusto Company';
const ADD_GUSTO_ADJUSTMENT = 'Add Gusto Adjustment';
const GUSTO_PAY_STUBS_ROW_EVENT = 'Gusto Pay Stubs Row Event';
const GUSTO_PAY_STUBS_CHECK_EVENT = 'Gusto Pay Stubs Check Event';

const gustoAnalyticsConstants = {
  SETUP_GUSTO_ACCOUNT,
  REGISTER_GUSTO_EMPLOYEE,
  RUN_GUSTO_PAYROLL,
  SUBMIT_GUSTO_PAYROLL,
  CANCEL_GUSTO_PAYROLL,
  VIEW_GUSTO_PAYROLL,
  EXPAND_GUSTO_MEMBER_BREAKDOWN,
  OPEN_GUSTO_MEMBER_MATCHER,
  CREATE_MEMBER_IN_GUSTO_MEMBER_MATCHER,
  SUBMIT_GUSTO_MEMBER_MATCHER,
  SELECT_GUSTO_COMPANY,
  ADD_GUSTO_ADJUSTMENT,
  GUSTO_PAY_STUBS_ROW_EVENT,
  GUSTO_PAY_STUBS_CHECK_EVENT,
};

export default gustoAnalyticsConstants;
