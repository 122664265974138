import { QueryResult, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient } from '@apollo/client/react/hoc';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import { PositionFilter } from '__generated__/graphql';
import classNames from 'classnames';
import { getApolloMemberNameSearch } from 'hooks/models/member/useApolloMemberNameSearch';
import { getApolloMemberNameSort } from 'hooks/models/member/useApolloMemberNameSort';
import useEmployeeNameFormat, { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { formatEmployeeName } from 'hooks/ui/useEmployeeNameFormatter';
import _, { isNil } from 'lodash';
import { Component } from 'react';
import { ClassName } from 'types/ClassName';
import IMemberPermissionType from 'types/MemberPermissionType';
import { t } from 'utils/localize';
import { logError } from 'utils/testUtils';
import { IMember } from '../../../../types/Member';
import { stringUtils } from '../../../../utils';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { memberQuery, membersQuery } from './employee-picker-queries';

export interface IEmployeePickerProps {
  value: string | null;
  onSelect: (memberId: string | null) => void;
  employeeNameFormat: EmployeeNameFormat | null;
  // Ids are filtered out at the graphql level
  blacklistedIds?: string[];
  permissions?: IMemberPermissionType;
  positionFilter?: PositionFilter;
  minWidth?: string; // has default
  className?: ClassName;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  client: any;
  fetchPolicy?: string;
  searchArchived?: boolean;
  includeClockedIn?: boolean;
  includedIds?: string[];
}

interface IState {
  searchValue: string;
  members: IMember[];
  loadedAll: boolean;
  dataError: boolean;
}

class EmployeePicker extends Component<WithApolloClient<IEmployeePickerProps>, IState> {
  public static defaultProps: any;
  public sectionSize = 10;
  public searchDebounce?: () => void; // used for debouncing api calls on search
  public state: IState = {
    loadedAll: false,
    members: [],
    searchValue: '',
    dataError: false,
  };

  public componentDidUpdate(prevProps: IEmployeePickerProps) {
    if (
      (!prevProps.blacklistedIds && this.props.blacklistedIds) ||
      (prevProps.blacklistedIds && !this.props.blacklistedIds) ||
      (prevProps.blacklistedIds &&
        this.props.blacklistedIds &&
        _.xor(prevProps.blacklistedIds, this.props.blacklistedIds).length !== 0)
    ) {
      this.clearValues();
    }

    if (prevProps.searchArchived !== this.props.searchArchived) {
      this.setState({
        members: [],
        loadedAll: false,
      });
    }
  }

  public getMemberFilter() {
    const archivedOn = this.props.searchArchived ? { isNull: false } : { isNull: true };
    const filter = { archivedOn, permissions: this.props.permissions, position: this.props.positionFilter } as any;

    // TODO Search support returns unexpected results. (Searching firstName also searches email, lastName, etc.)
    if (this.state.searchValue) {
      filter.search = getApolloMemberNameSearch(this.props.employeeNameFormat ?? 'FIRST_LAST', this.state.searchValue);
    }

    if (this.props.includedIds && this.props.includedIds.length > 0) {
      filter.id = { contains: this.props.includedIds };
    }

    if (this.props.blacklistedIds && this.props.blacklistedIds.length) {
      filter.id = { doesNotContain: this.props.blacklistedIds };
    }

    if (this.props.permissions) {
      filter.permissions = this.props.permissions;
    }

    if (!isNil(this.props.includeClockedIn)) {
      filter.openTimeEntry = { startTime: { isNull: !this.props.includeClockedIn } };
    }

    return filter;
  }

  // Populate the list with all members
  public getEmployees = (after?: string): Promise<IMember[]> => {
    return new Promise((resolve, reject) => {
      this.props.client
        .query({
          query: membersQuery,
          fetchPolicy: this.props.fetchPolicy,
          variables: {
            after,
            filter: this.getMemberFilter(),
            first: this.sectionSize,
            sort: getApolloMemberNameSort(this.props.employeeNameFormat ?? 'FIRST_LAST', 'asc'),
          },
        })
        .then((result: { data: { members: IMember[] } }) => {
          resolve(result.data.members);
        })
        .catch((e: any) => {
          logError(e);
          reject(e);
        });
    });
  };

  public handleDidLoad = (members: IMember[], error: boolean, loadedAll: boolean) => {
    if (!error) {
      this.setState({
        loadedAll,
        dataError: false,
        members,
      });
    } else {
      this.setState({
        dataError: error,
      });
    }
  };

  public renderValueTemplate = (value: string | null) => {
    if (value) {
      const member = _.find(this.state.members, (model) => model.id === value);
      if (member) {
        return <>{this.getFormattedName(member)}</>;
      } else {
        return (
          <Query query={memberQuery} variables={{ memberId: value }}>
            {({ data, error }: QueryResult) => {
              if (error) {
                logError(new Error(error.message));
                return <>{t('Error')}</>;
              }

              return <>{this.getFormattedName(data?.members[0] ?? null)}</>;
            }}
          </Query>
        );
      }
    }
    return <></>;
  };

  public renderRow = (member: IMember) => (
    <Bar size={Size.SMALL} className="px-3">
      <div className="ellipsis">{stringUtils.highlight(this.getFormattedName(member), this.state.searchValue)}</div>
    </Bar>
  );

  public getFormattedName = (member: IMember | null) => {
    return formatEmployeeName(
      member?.firstName ?? '',
      member?.lastName ?? '',
      this.props.employeeNameFormat ?? 'FIRST_LAST'
    );
  };

  public handleSelect = (member: IMember | null) => {
    this.props.onSelect(member ? member.id : null);
  };

  public clearValues = () => {
    this.setState({
      loadedAll: false,
      members: [],
      searchValue: '',
    });
  };

  public getFullName = (member: IMember, lowerCase: boolean = false) => {
    const fullName = stringUtils.getFullNameFromMember(member);

    return lowerCase ? fullName.toLowerCase() : fullName;
  };

  public handleSearchInputChange = (value: string) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        if (!this.searchDebounce) {
          this.searchDebounce =
            this.searchDebounce ||
            _.debounce(() => {
              this.setState({
                loadedAll: false,
                members: [],
              });
            }, 200);
        }
        this.searchDebounce();
      }
    );
  };

  public render() {
    const { className, placeholder, minWidth, position, value, error } = this.props;

    const classes = classNames('employee-picker', className);

    return (
      <LazyLoadPicker
        value={value}
        error={error}
        getData={this.getEmployees}
        didLoad={this.handleDidLoad}
        data={this.state.members}
        loadedAll={this.state.loadedAll}
        onSelect={this.handleSelect}
        onClose={this.clearValues}
        valueTemplate={this.renderValueTemplate}
        renderRow={this.renderRow}
        minWidth={minWidth}
        position={position}
        searchValue={this.state.searchValue}
        onSearch={this.handleSearchInputChange}
        placeholder={placeholder}
        className={classes}
      />
    );
  }
}

EmployeePicker.defaultProps = {
  minWidth: '350px',
  fetchPolicy: 'network-only',
};

export type IEmployeePickerContainerProps = Omit<IEmployeePickerProps, 'client' | 'employeeNameFormat'>;

function EmployeePickerContainer(props: Omit<IEmployeePickerProps, 'client' | 'employeeNameFormat'>) {
  const [employeeNameFormat] = useEmployeeNameFormat();
  const client = useApolloClient();
  return <EmployeePicker client={client} employeeNameFormat={employeeNameFormat} {...props} />;
}

export default EmployeePickerContainer;
