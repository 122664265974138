import { useCallback, useState } from 'react';
import useDebouncedCallback from './useDebouncedCallback/useDebouncedCallback';

export default function useDebounce<T>(value: T, delay: number = 250) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebouncedCallback(
    useCallback(() => {
      setDebouncedValue(value);
    }, [value]),
    delay
  );

  return debouncedValue;
}
