import { Button, ClassName, Radio, RadioGroup } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { t } from 'i18next';
import { useState } from 'react';
import './ProjectActivityExportOptionsDialog.scss';

export interface IProjectActivityExportOptionsDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  onExport: (options: ProjectActivityExportOption) => void;
}

export enum ProjectActivityExportOption {
  RootProjects = 'root-projects',
  SubProjects = 'subprojects',
}

const ProjectActivityExportOptionsDialog = (props: IProjectActivityExportOptionsDialogProps) => {
  const { className, isOpen, onClose, onExport } = props;
  const [currentRadioSelection, setCurrentRadioSelection] = useState<string>(ProjectActivityExportOption.RootProjects);
  const classes = classNames('project-activity-export-options-dialog', className);

  function submit() {
    switch (currentRadioSelection) {
      case ProjectActivityExportOption.RootProjects:
        onExport(ProjectActivityExportOption.RootProjects);
        break;
      case ProjectActivityExportOption.SubProjects:
        onExport(ProjectActivityExportOption.SubProjects);
        break;
    }

    onClose();
  }

  return (
    <HeaderDialog className={classes} title={t('Export')} divider={false} isOpen={isOpen} onClose={onClose}>
      <div className="p-7">
        <RadioGroup name={'options'} value={currentRadioSelection} onChange={setCurrentRadioSelection}>
          <Radio
            key={'1'}
            value={ProjectActivityExportOption.RootProjects}
            checked={currentRadioSelection === ProjectActivityExportOption.RootProjects}
            label={
              <HeaderText title={t('Project Totals')}>{t('Export the totals for each project in the list')}</HeaderText>
            }
          />
          <Radio
            key={'2'}
            value={ProjectActivityExportOption.SubProjects}
            checked={currentRadioSelection === ProjectActivityExportOption.SubProjects}
            label={
              <HeaderText title={t('Subprojects Totals')}>
                {t(
                  'Export the subproject and unassigned totals for each project in the list. Note that this will only export the totals for subprojects directly beneath each project.'
                )}
              </HeaderText>
            }
          />
        </RadioGroup>

        <Button className="my-4" type="primary" onClick={submit}>
          {t('Submit')}
        </Button>
      </div>
    </HeaderDialog>
  );
};

export default ProjectActivityExportOptionsDialog;
