import 'intl-locales-supported';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';

const getTimezoneStrings = () => {
  if (typeof Intl.supportedValuesOf === 'function') {
    return Intl.supportedValuesOf('timeZone');
  }
};

const getTimezoneObjects = () => {
  const today = DateTime.now();
  const zones = moment.tz
    .names()
    .map((z) => {
      const zone = moment.tz(today, z);
      return {
        name: z,
        offset: zone.utcOffset(),
        shortName: zone.zoneName(),
      };
    })
    .filter((value) => !isNil(value));

  return zones;
};

export { getTimezoneObjects, getTimezoneStrings };
