import { NavigationBarStatus } from 'store/ui/NavigationBar/NavigationBar';

type BasicIframeMessagePayload<ActionType extends string> = {
  action: ActionType;
};

const NAVIGATION_BAR_TYPE = 'navigationBar' as const;
export const iframeMessageReceiverTypes = [NAVIGATION_BAR_TYPE] as const;

export type IframeMessageReceiverType = (typeof iframeMessageReceiverTypes)[number];

export const navigationBarActions = ['setNavigationBarStatus'] as const;
export type NavigationBarAction = (typeof navigationBarActions)[number];

export const iframeReceiverMessageActions = [...navigationBarActions] as const;
export type IframeReceiverMessageAction = (typeof iframeReceiverMessageActions)[number];

export type NavigationBarStatusPayload = BasicIframeMessagePayload<NavigationBarAction> & {
  status: NavigationBarStatus;
};

export type IframeReceiverMessagePayload = {
  action: IframeReceiverMessageAction;
};

export type IframeMessageReceiverData<T extends IframeReceiverMessagePayload = IframeReceiverMessagePayload> = {
  type: IframeMessageReceiverType;
  payload: T;
};

const LIFECYCLE_TYPE = 'receiverLifecycle' as const;
const iframeMessageSenderTypes = [LIFECYCLE_TYPE] as const;
type IframeMessageSenderType = (typeof iframeMessageSenderTypes)[number];

const lifecycleActionType = ['initialized', 'destroyed'] as const;
export type LifecycleActionType = (typeof lifecycleActionType)[number];
const iframeMessageSenderActions = [...lifecycleActionType] as const;

type IframeMessageSenderAction = (typeof iframeMessageSenderActions)[number];

export type IframeSenderMessagePayload = {
  action: IframeMessageSenderAction;
};

export type IframeMessageSenderData<T extends IframeSenderMessagePayload = IframeSenderMessagePayload> = {
  type: IframeMessageSenderType;
  payload: T;
};

export type IframeMessageSenderStatusPayload = {
  action: IframeMessageSenderAction;
};
