import gql from 'graphql-tag';

export const PHOTOS_AND_NOTES_DASHBOARD_CARD_QUERY = gql`
  query photosAndNotesDashboardCardQuery($filter: MediaCollectionFilter, $first: Int, $after: String) {
    mediaCollections(first: $first, after: $after, filter: $filter) {
      id
      createdOn
      cursor
    }
  }
`;
