import { useEffect } from 'react';

export default function useDebouncedCallback(callback: () => void, delay: number = 250) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay, callback]);
}
