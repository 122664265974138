import { isNil } from 'lodash';
import { useContext } from 'react';
import { ChannelContext } from './ChannelContext';

export function useChannelContext() {
  const context = useContext(ChannelContext);

  if (isNil(context)) {
    throw new Error('useChannelContext must be nested within a ChannelContext.Provider.');
  }

  return context;
}
