import { useMutation } from '@apollo/client';

import {
  MemberSignOffAnswer,
  MemberSignOffAnswerCreateInput,
  MemberSignOffAnswerUpdateInput,
} from '__generated__/graphql';
import { CREATE_MEMBER_SIGN_OFF_ANSWER, UPDATE_MEMBER_SIGN_OFF_ANSWER } from 'apollo/mutations/member-sign-off-answer';
import { DateTime } from 'luxon';
import { uuid } from 'utils/uuidUtils';

import useActiveMember from '../../store/useActiveMember';

export default function useMemberSignOffAnswer() {
  const [createAnswerMutation] = useMutation(CREATE_MEMBER_SIGN_OFF_ANSWER);
  const [updateAnswerMutation] = useMutation(UPDATE_MEMBER_SIGN_OFF_ANSWER);

  const activeMember = useActiveMember();

  const createSignOffAnswer = async (data: MemberSignOffAnswerCreateInput) => {
    const answerId = uuid();

    const answer: MemberSignOffAnswerCreateInput = {
      ...data,
      id: answerId,
    };

    const results = await createAnswerMutation({ variables: { answer } });
    return results.data.createMemberSignOffAnswer as MemberSignOffAnswer;
  };

  const resolveSignOffAnswer = async (id: string, comment: string) => {
    const answer: MemberSignOffAnswerUpdateInput = {
      id,
      resolverId: activeMember.id,
      resolvedOn: DateTime.local().toISO(),
      resolvedComment: comment,
    };
    const results = await updateAnswerMutation({ variables: { answer } });
    return results.data.updateMemberSignOffAnswer as MemberSignOffAnswer;
  };

  return { createSignOffAnswer, resolveSignOffAnswer };
}
