import alpha from './favicon_alpha.png';
import beta from './favicon_beta.png';
import canary from './favicon_canary.png';
import production from './favicon_production.png';
import staging from './favicon_staging.png';

interface IFavicons {
  [key: string]: string;
}

export const favicons: IFavicons = {
  alpha,
  beta,
  canary,
  production,
  staging,
};

export default production;
