import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';
import NavItemContent from '../NavHeader/NavItemContent/NavItemContent';
import './FakeNavItem.scss';

export interface IFakeNavItemProps {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children: ReactNode;
  onClick: () => void;
  className?: ClassName;
}

function FakeNavItem({ className, icon, children, onClick }: IFakeNavItemProps) {
  return (
    <div
      onClick={onClick}
      className={classNames('fake-nav-item', 'nav-item', 'no-text-select', { clickable: onClick }, className)}
    >
      <NavItemContent icon={icon}>{children}</NavItemContent>
    </div>
  );
}

export default FakeNavItem;
