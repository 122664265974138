import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store/store';
import { LaunchDarkly } from 'utils/features/launchDarkly';
import ApolloProvider from './Apollo';
import HtmlHead from './HtmlHead';
import IframeMiddleware from './IframeMiddleware';
import ReactQueryMiddleware from './ReactQueryMiddleware';
import RoutingMiddleware from './RoutingMiddleware';

export interface IPlainWrapper {
  children: React.ReactNode;
}

const Middleware = ({ children }: IPlainWrapper) => {
  return (
    <ReduxProvider store={store}>
      <ApolloProvider>
        <ReactQueryMiddleware>
          <HtmlHead />
          <LaunchDarkly>
            <IframeMiddleware>
              <Router>
                <RoutingMiddleware>{children}</RoutingMiddleware>
              </Router>
            </IframeMiddleware>
          </LaunchDarkly>
        </ReactQueryMiddleware>
      </ApolloProvider>
    </ReduxProvider>
  );
};

export default Middleware;
