import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import TimeEntryBreakStatusType from 'types/enum/TimeEntryBreakStatusType';
import { default as IBreak, default as ITimeEntryBreak } from '../types/TimeEntryBreak';
import DateTimeFormat from './constants/dateTimeFormat';
import {
  dateTimeFromISOKeepZone,
  dateTimeFromISOWithoutZone,
  localizedDateTimeOrNow,
  nightShiftAdjustment,
  timeInRange,
} from './dateUtils';
import { timeEntryEndTimeOrNow } from './timeEntryUtils';

/**
 * Returns total in seconds for the duration of a time entry break represented as a time range.
 * If creating a time range from a time entry break and using `DateTime.fromIso()` make sure to pass the option
 * `{setZone: true})` so that the total can be calculated appropriately if the data is in a different timezone.
 *
 * @param breakRange the time range that represents the time entry break
 */
export function getTotalFromDateTimes(breakRange: ITimeRange<DateTime>) {
  const breakStartTime = breakRange.startTime.toUTC(0, { keepLocalTime: true });
  const breakEndTime = localizedDateTimeOrNow(breakRange.endTime);
  let totalBreakTime = Math.abs(breakEndTime.toMillis() - breakStartTime.toMillis());
  if (totalBreakTime < 0) {
    totalBreakTime = 0;
  }
  return totalBreakTime / 1000;
}

export function isOutsideRange(entryRange: ITimeRange<DateTime>, breakRanges: Array<ITimeRange<DateTime>>) {
  let isError = false;
  for (const value of breakRanges) {
    const nightShiftedAdjustment = nightShiftAdjustment(value);
    if (
      !timeInRange(
        nightShiftedAdjustment.startTime,
        { startTime: entryRange.startTime, endTime: entryRange.endTime },
        true
      ) &&
      !timeInRange(
        nightShiftedAdjustment.endTime,
        { startTime: entryRange.startTime, endTime: entryRange.endTime },
        true
      )
    ) {
      isError = true;
    }
  }
  return isError;
}

export function getBreakStatus(
  entryBreak: Pick<IBreak, 'startTime' | 'endTime'>,
  entry: Pick<ITimeEntry, 'startTime' | 'endTime'>
) {
  const entryStartTime = dateTimeFromISOWithoutZone(entry.startTime);
  const entryEndTime = timeEntryEndTimeOrNow(entry);
  const breakStartTime = dateTimeFromISOWithoutZone(entryBreak.startTime);
  const breakEndTime = breakEndTimeOrNow(entryBreak);

  if (breakStartTime >= entryStartTime && breakEndTime <= entryEndTime) {
    // Break is entirely inside entry.
    return TimeEntryBreakStatusType.INSIDE_ENTRY;
  } else if (breakStartTime <= entryStartTime && breakEndTime >= entryEndTime) {
    // Break startTime and endTime is outside of entry.
    return TimeEntryBreakStatusType.CONTAINS_ENTRY;
  } else if (breakStartTime >= entryStartTime && breakEndTime >= entryEndTime && breakStartTime <= entryEndTime) {
    // Breaks endTime is outside of entry.
    return TimeEntryBreakStatusType.END_TIME_OUTSIDE;
  } else if (breakStartTime <= entryStartTime && breakEndTime <= entryEndTime && breakEndTime >= entryStartTime) {
    // Breaks startTime is outside of entry.
    return TimeEntryBreakStatusType.START_TIME_OUTSIDE;
  }
  return TimeEntryBreakStatusType.OUTSIDE_ENTRY; // Default to outside to easily catch errors.
}

/**
 * Calculates the duration of a given break within a time entry.
 *
 * @param entryBreak break entry to check duration
 * @param entry the time entry that the break belongs to
 * @returns the number of seconds that the break spans
 */
export function getBreakTotal(
  entryBreak: Pick<ITimeEntryBreak, 'startTime' | 'endTime'>,
  entry: Pick<ITimeEntry, 'startTime' | 'endTime'>
) {
  const entryStartTime = dateTimeFromISOWithoutZone(entry.startTime);
  const entryEndTime = timeEntryEndTimeOrNow(entry);
  const breakStartTime = dateTimeFromISOWithoutZone(entryBreak.startTime);
  const breakEndTime = breakEndTimeOrNow(entryBreak);

  const startTime = entryStartTime < breakStartTime ? breakStartTime : entryStartTime;
  const endTime = entryEndTime > breakEndTime ? breakEndTime : entryEndTime;
  const totalBreakTime = endTime.toMillis() - startTime.toMillis();
  if (totalBreakTime <= 0) {
    return 0;
  } else {
    return totalBreakTime / 1000;
  }
}

export function breakEndTimeOrNow(brk: Pick<IBreak, 'startTime' | 'endTime'>): DateTime {
  if (!isNil(brk.endTime)) {
    return dateTimeFromISOWithoutZone(brk.endTime);
  } else {
    const breakStart = dateTimeFromISOKeepZone(brk.startTime);
    return DateTime.local().setZone(breakStart.zoneName, { keepLocalTime: false });
  }
}

export function breakIsUnderRequiredMinutes(breakStartTime: string, zone: string, breakDurationSeconds: number) {
  return (
    DateTime.now().toSeconds() -
      dateTimeFromISOWithoutZone(breakStartTime).setZone(zone, { keepLocalTime: false }).toSeconds() <
    breakDurationSeconds
  );
}

export function getAvailableBreakEndTime(breakStartDeviceTime: string, zone: string, breakDuration: number) {
  return dateTimeFromISOKeepZone(breakStartDeviceTime)
    .setZone(zone, { keepLocalTime: false })
    .plus({ seconds: breakDuration })
    .toFormat(DateTimeFormat.timeSimple);
}
