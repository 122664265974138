import { Align, Button, Divider, Justify, Label, Row, Switch } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import DashboardSettingsItem, {
  IDashboardSettingsItem,
} from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DraggableList, { IUpdatedDraggable } from 'components/foundation/DraggableList/draggable-list/DraggableList';
import DashboardCardSettingsDialog from 'components/foundation/dashboard/DashboardCardSettingsDialog/DashboardCardSettingsDialog';
import { useOpenable, useOrganization } from 'hooks';
import useMemberUiSettingsUpdate from 'hooks/models/member-settings/useMemberUiSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassName } from 'types/ClassName';
import { IDashboardGridSettings } from 'types/DashboardGridSettings';
import IIdable from 'types/Idable';
import getThunkedValue from 'utils/functionUtils';
import { useDashboardSettings } from '../context/DashboardSettingsContext';
import DashboardTimeOffCardSettingsForm from '../dashboard-grid/ribbon-group/time-off-dashboard-ribbon-group/DashboardTimeOffCardSettingsForm/DashboardTimeOffCardSettingsForm';
import { DashboardSettingKey, DashboardSettings } from '../types/types';
import { getDashboardItemTitleFromKey } from './utils/utils';

export interface IDashboardSettingsFormProps {
  className?: ClassName;
  onSubmit?: () => void;
}

const DashboardSettingsForm = (props: IDashboardSettingsFormProps) => {
  const { className, onSubmit } = props;

  const { t } = useTranslation();
  const settingsDetail = useOpenable();
  const titleRef = useRef<string>();
  const itemRef = useRef<IDashboardSettingsItem>();
  const settingsActions = useDashboardSettings();
  const [settings, setSettings] = useState<(typeof settingsActions)['settings']>(cloneDeep(settingsActions.settings));
  const memberSettings = useMemberSettings();
  const enabledBy4 = memberSettings?.web?.ui?.dashboard?.fourCardsPerRow;
  const updateUiMemberSettings = useMemberUiSettingsUpdate();
  const [localEnabledBy4, setLocalEnabledBy4] = useState(enabledBy4);
  const organization = useOrganization();
  const isCustomSignOffEnabled = useCustomSignOffEnabled();

  function onUpdate(items: Array<IUpdatedDraggable<IDashboardSettingsItem & IIdable<string>>>) {
    const result = _.keyBy(
      items.map((item) => ({
        ...item.payload,
        position: item.index,
      })),
      'key'
    ) as unknown as DashboardSettings;

    setSettings((prev) => ({ ...prev, ...result }));
  }

  function updateCheckState(checked: boolean, itemKey: string) {
    const key = itemKey as DashboardSettingKey;
    const settingsKey = 'visible';
    setSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [settingsKey]: getThunkedValue(checked, prev[key][settingsKey]),
      },
    }));
  }

  function updateSetting(item: IDashboardSettingsItem) {
    const key = item.key as DashboardSettingKey;
    setSettings((prev) => ({ ...prev, [key]: item }));
  }

  const actionItemSetting = (item: IDashboardSettingsItem) => {
    itemRef.current = item;
    titleRef.current = item.title;
    settingsDetail.open();
  };

  const actionApply = async () => {
    let dashboardGridSettings: IDashboardGridSettings = {};

    for (const [key, value] of Object.entries(settings)) {
      dashboardGridSettings = {
        ...dashboardGridSettings,
        [key]: value,
      };
    }

    await updateUiMemberSettings('dashboard', {
      ...memberSettings?.web?.ui?.dashboard,
      fourCardsPerRow: localEnabledBy4,
      dashboardGridSettings,
    });

    onSubmit?.();
  };

  function onCheckChange(key: keyof DashboardSettings) {
    return (checked: boolean) => {
      updateCheckState(checked, key);
    };
  }

  const settingsList = useMemo(
    () =>
      Object.values(_.sortBy(settings, 'position')).map((setting) => ({
        ...setting,
        draggable: setting.key !== 'clockStatus',
        id: setting.key,
        title: getDashboardItemTitleFromKey(setting.key),
      })),
    [settings]
  );

  // temporarily disable the daily sign off card if all 3 settings are off and custom settings are on
  const dailySignOffDisabled = () => {
    return (
      isCustomSignOffEnabled && !organization.timeAccuracy && !organization.breakPolicy && !organization.safetySignature
    );
  };

  const classes = classNames('dashboard-settings-form', 'pt-4', className);

  return (
    <>
      <DraggableList
        hover={true}
        itemClass="px-6 py-2"
        renderItem={(item) => (
          <DashboardSettingsItem
            className="mr-2"
            key={item.key}
            item={item}
            onCheckChange={onCheckChange(item.key as keyof DashboardSettings)}
            onSettingsIconClicked={actionItemSetting}
            disabled={item.key === 'dailySignatureAlerts' && dailySignOffDisabled()}
          />
        )}
        items={settingsList}
        onUpdate={onUpdate}
        className={classes}
      />
      <Divider />
      <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className="m-8">
        <Label>{t('Display 4 cards per row.')}</Label>
        <Switch onChange={(value) => setLocalEnabledBy4(value)} value={localEnabledBy4} />
      </Row>
      <Divider />
      <Button className="my-6 mx-8" type="primary" onClick={actionApply}>
        {t('Apply')}
      </Button>
      {titleRef.current && itemRef.current && itemRef.current.key !== 'timeOff' && (
        <DashboardCardSettingsDialog
          isOpen={settingsDetail.isOpen}
          onClose={settingsDetail.close}
          onSave={updateSetting}
          settingsItem={itemRef.current}
          title={titleRef.current}
        />
      )}
      {itemRef.current && itemRef.current.key === 'timeOff' && (
        <HeaderDialog
          title={t('Time Off')}
          isOpen={settingsDetail.isOpen}
          onClose={settingsDetail.close}
          divider={false}
        >
          <DashboardTimeOffCardSettingsForm
            onSave={(setting) => {
              updateSetting(setting);
              settingsDetail.close();
            }}
            setting={itemRef.current!}
            className="form-dialog-padding"
          />
        </HeaderDialog>
      )}
    </>
  );
};

export default DashboardSettingsForm;
