import { useCallback } from 'react';
import { IGustoTermsOfService } from '../../../types/types';
import useGustoAxios from '../../useGustoAxios/useGustoAxios';

export default function useGustoGetTermsOfService() {
  const axios = useGustoAxios();

  return useCallback(
    (gustoCompanyId: string, gustoEmail: string) => {
      return axios.post<IGustoTermsOfService>(
        `/v1/partner_managed_companies/${gustoCompanyId}/retrieve_terms_of_service`,
        { email: gustoEmail }
      );
    },
    [axios]
  );
}
