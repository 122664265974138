import {
  BASIC_ORGANIZATION_LABOR_METRICS_FRAGMENT,
  BASIC_ORGANIZATION_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-organization-queries';
import gql from 'graphql-tag';

export const ORGANIZATION_TIME_METRIC_QUERY = gql`
  query QueryOrganizationTimeMetrics(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    organizationLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...BasicOrganizationLaborMetrics
    }
  }
  ${BASIC_ORGANIZATION_TIME_METRICS_FRAGMENT}
`;

export const ORGANIZATION_LABOR_METRIC_QUERY = gql`
  query QueryOrganizationLaborMetrics(
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    organizationLaborMetrics(
      interval: $metricsInterval
      start: $metricsStartDate
      end: $metricsEndDate
      filter: $metricFilter
    ) {
      ...OrganizationLaborMetrics
    }
  }
  ${BASIC_ORGANIZATION_LABOR_METRICS_FRAGMENT}
`;
