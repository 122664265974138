import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import IMemberSettings from 'types/MemberSettings';
import { IMemberWebSettings } from 'types/MemberWebSettings';
import { useActiveMemberUpdate } from '..';
import useMemberSettings from './useMemberSettings';

export default function useMemberWebSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const [updateKey] = useActiveMemberUpdate();

  return useCallback(
    <K extends keyof IMemberWebSettings>(key: K, payload: IMemberWebSettings[K]) => {
      const cloned = cloneDeep(memberSettings);

      const updated: IMemberSettings = cloned
        ? {
            ...cloned,
            web: {
              ...cloned?.web,
              [key]: payload,
            },
          }
        : {
            web: {
              [key]: payload,
            },
          };

      return updateKey('memberSettings', JSON.stringify(updated));
    },
    [memberSettings, updateKey]
  );
}
