import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import { Row, Checkbox, ICheckboxProps } from '@busybusy/webapp-react-ui';

import './CheckItem.scss';

export interface ICheckItemProps extends ICheckboxProps {
  children: ReactNode;
}

function CheckItem(props: ICheckItemProps) {
  const { children, className, ...checkboxProps } = props;

  const classes = classNames('check-item', className);

  return (
    <Row className={classes}>
      <Checkbox {...checkboxProps} />
      {children}
    </Row>
  );
}

CheckItem.defaultProps = {
  ...Checkbox.defaultProps,
};

export default CheckItem;
