import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { nightShiftAdjustment, timeInRange } from 'utils/dateUtils';
import { t } from 'utils/localize';

export default function v_times_outside_range(
  value: ITimeRange<DateTime | undefined | null> | null,
  args: { rangeStart: DateTime; rangeEnd: DateTime; startExclusive?: boolean; endExclusive?: boolean; message?: string }
) {
  return {
    message:
      args?.message ||
      `${t('Both start time and end time must be outside range')} ${args.rangeStart.toFormat(
        't'
      )} - ${args.rangeEnd.toFormat('t')}.`,
    validate: () => {
      const nightShiftedAdjustment = nightShiftAdjustment(value as ITimeRange<DateTime>);
      return (
        !timeInRange(
          nightShiftedAdjustment.startTime,
          { startTime: args.rangeStart, endTime: args.rangeEnd },
          !args.startExclusive,
          !args.endExclusive
        ) &&
        !timeInRange(
          nightShiftedAdjustment.endTime,
          { startTime: args.rangeStart, endTime: args.rangeEnd },
          !args.startExclusive,
          !args.endExclusive
        )
      );
    },
  };
}
