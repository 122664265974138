import { useTimeRange } from 'hooks';
import { DateTime } from 'luxon';
import { utcToday } from 'utils/dateUtils';
import TimeRangeReportDialog, { ITimeRangeReportDialogProps } from '../TimeRangeReportDialog/TimeRangeReportDialog';

export type ManagedTimeRangeReportDialogProps = Omit<
  ITimeRangeReportDialogProps,
  'back' | 'forward' | 'backEnabled' | 'forwardEnabled'
> & {
  minimumTime?: DateTime;
  maximumTime?: DateTime;
  zone?: 'system' | 'utc';
};

const ManagedTimeRangeReportDialog = ({
  timeRange: selectedTimeRange,
  timeRangeType,
  minimumTime,
  maximumTime,
  zone,
  ...other
}: ManagedTimeRangeReportDialogProps) => {
  return (
    <TimeRangeReportDialog
      {...useTimeRange(timeRangeType, selectedTimeRange, minimumTime, maximumTime, zone)}
      {...other}
    />
  );
};

ManagedTimeRangeReportDialog.defaultProps = {
  minimumTime: null,
  maximumTime: utcToday().startTime,
  zone: 'utc',
};

export default ManagedTimeRangeReportDialog;
