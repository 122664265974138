import { Field, FieldFooter } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import EquipmentYearPicker, {
  IEquipmentYearPickerProps,
} from 'components/domain/equipment/EquipmentYearsPicker/EquipmentYearsPicker';

interface IEquipmentYearFormFieldProps extends Omit<IEquipmentYearPickerProps, 'value' | 'onSelect'> {
  className?: ClassName;
  form: any;
  name: string;
  onChange?: (value: string | null) => void;
  validations?: any;
}

function EquipmentYearFormField(props: IEquipmentYearFormFieldProps) {
  const {
    form,
    name,
    equipmentCategoryId,
    equipmentMakeId,
    equipmentModelTitle,
    validations,
    className,
    onChange,
    ...other
  } = props;

  const classes = classNames(
    {
      'equipment-year-picker-form-field': true,
    },
    className
  );

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: string | null) {
      fieldProps.handleChange(val, onChange);
    }

    return (
      <>
        <EquipmentYearPicker
          error={fieldProps.hasError}
          value={form.state.data[name]}
          onSelect={handleOnSelect}
          equipmentCategoryId={equipmentCategoryId}
          equipmentMakeId={equipmentMakeId}
          equipmentModelTitle={equipmentModelTitle}
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return <Field form={form} name={name} validations={validations} className={classes} render={renderField} />;
}

export default EquipmentYearFormField;
