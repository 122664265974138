import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import Linkify from 'linkify-react';
import { isNil } from 'lodash';
import { ReactNode } from 'react';
import './EmbeddedLinkedText.scss';

export interface IEmbeddedLinkedTextProps {
  className?: ClassName;
  children: ReactNode;
  options?: any; // https://linkify.js.org/docs/options.html
}

const EmbeddedLinkedText = (props: IEmbeddedLinkedTextProps) => {
  const { className, children, options } = props;

  const classes = classNames('embedded-linked-text', className);

  return (
    <div className={classes}>
      <Linkify
        options={
          !isNil(options)
            ? options
            : {
                target: {
                  url: '_blank',
                },
              }
        }
      >
        {children}
      </Linkify>
    </div>
  );
};

export default EmbeddedLinkedText;
