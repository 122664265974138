import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { IProjectLandingTableRowInfo } from 'containers/landing-pages/project-landing-page/ProjectLandingTable/context/ProjectLandingDataContext';
import { IVisibleTableColumn } from 'types/TableColumn';

export interface IStoreProjectLandingPage {
  projectTableClockedInColumns: IVisibleTableColumn[];
  projectTableClockedOutColumns: IVisibleTableColumn[];
  projectTableColumns: IVisibleTableColumn[];
  projectDashboardGridItems: IDashboardSettingsItem[];
  data: IProjectLandingTableRowInfo[];
}

export const initialState: IStoreProjectLandingPage = {
  projectTableClockedInColumns: [],
  projectTableClockedOutColumns: [],
  projectTableColumns: [],
  projectDashboardGridItems: [],
  data: [],
};

const slice = createSlice({
  name: 'projectLandingPage',
  initialState,
  reducers: {
    updateProjectTableClockedInColumns: (
      state: IStoreProjectLandingPage,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.projectTableClockedInColumns = action.payload;

      return state;
    },
    updateProjectTableClockedOutColumns: (
      state: IStoreProjectLandingPage,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.projectTableClockedOutColumns = action.payload;

      return state;
    },
    updateProjectTableColumns: (state: IStoreProjectLandingPage, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.projectTableColumns = action.payload;

      return state;
    },
    updateProjectDashboardGridItems: (
      state: IStoreProjectLandingPage,
      action: PayloadAction<IDashboardSettingsItem[]>
    ) => {
      state.projectDashboardGridItems = action.payload;

      return state;
    },
    setProjectLandingPageRowInfo: (
      state: IStoreProjectLandingPage,
      action: PayloadAction<IProjectLandingTableRowInfo[]>
    ) => {
      state.data = action.payload;
      return state;
    },
    clearProjectLandingPageRowInfo: (state: IStoreProjectLandingPage) => {
      state.data = [];
      return state;
    },
  },
});

export const {
  updateProjectTableClockedInColumns,
  updateProjectTableClockedOutColumns,
  updateProjectTableColumns,
  updateProjectDashboardGridItems,
  setProjectLandingPageRowInfo,
  clearProjectLandingPageRowInfo,
} = slice.actions;
export default slice.reducer;
