import { Size } from '@busybusy/webapp-react-ui';
import { HeaderDialog } from 'components';
import { IColumnItem } from 'components/foundation/forms/column/ColumnForm/ColumnForm';
import SimpleColumnForm from 'components/foundation/forms/column/SimpleColumnForm/SimpleColumnForm';
import { IColumnFormItem } from 'components/foundation/forms/column/types/types';
import { FunctionComponent } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import { t } from 'utils/localize';

interface IManageEquipmentColumnDialogProps {
  isOpen: boolean;
  columns: IVisibleTableColumn[];
  onChange: (column: IVisibleTableColumn[]) => void;
  onClose: () => void;
}

const ManageEquipmentColumnDialog: FunctionComponent<IManageEquipmentColumnDialogProps> = ({
  isOpen,
  columns,
  onClose,
  onChange,
}) => {
  function handleSubmit(settings: Array<IColumnFormItem>) {
    onChange(settings);
    onClose();
  }

  function isSubmissionEnabled(columns: IColumnItem[]) {
    return !columns.some((col: IVisibleTableColumn) => col.visible);
  }

  return (
    <HeaderDialog isOpen={isOpen} title={t('Table Columns')} headerSize={Size.XL} onClose={onClose}>
      <SimpleColumnForm
        className="p-6"
        columns={columns}
        onSubmit={handleSubmit}
        submissionEnabled={isSubmissionEnabled}
      />
    </HeaderDialog>
  );
};

export default ManageEquipmentColumnDialog;
