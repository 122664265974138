import {
  Button,
  ButtonList,
  Dialog,
  Icon,
  ITableColumn,
  Label,
  Menu,
  Position,
  Size,
  Table,
  TextAlign,
} from '@busybusy/webapp-react-ui';
import { EquipmentCostHistory } from '__generated__/graphql';
import { MoreIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmptyState } from 'components';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useCurrencyFormatter, useOpenable } from 'hooks';
import useDeleteEquipmentCostHistory from 'hooks/models/equipment-cost-history/useDeleteEquipmentCostHistory';
import { t } from 'i18next';
import { isNil, sortBy } from 'lodash';
import { ReactNode, useState } from 'react';
import { dateTimeFromUtcISO, getDateName } from 'utils/dateUtils';
import EquipmentCostHistoryForm from '../EquipmentCostHistoryForm/EquipmentCostHistoryForm';

export interface IEquipmentCostHistoryTableProps {
  className?: ClassName;
  costHistories: EquipmentCostHistory[];
  onChange: () => void;
}

const EquipmentCostHistoryTable = (props: IEquipmentCostHistoryTableProps) => {
  const { className, costHistories, onChange } = props;

  const deleteConfirmationState = useOpenable();
  const equipmentCostHistoryForm = useOpenable();
  const currencyFormatter = useCurrencyFormatter();
  const [selectedCostHistory, setSelectedCostHistory] = useState<EquipmentCostHistory>();
  const deleteEquipmentCostHistory = useDeleteEquipmentCostHistory();

  function renderColumns(): Array<ITableColumn<EquipmentCostHistory>> {
    const columns: Array<ITableColumn<EquipmentCostHistory>> = [
      {
        cell: renderActionsColumn,
        key: 'actions',
        sort: false,
        align: TextAlign.LEFT,
        size: '20px',
      },
      {
        cell: renderDateColumn,
        key: 'effectiveDate',
        sort: false,
        title: t('Effective Date'),
        tooltip: t('Effective Date'),
        align: TextAlign.LEFT,
        size: '100px',
      },
      {
        cell: renderEditDateColumn,
        key: 'editDate',
        sort: false,
        title: t('Edit Date'),
        tooltip: t('Edit Date'),
        align: TextAlign.LEFT,
        size: '100px',
      },
      {
        cell: renderCostColumn,
        key: 'cost',
        sort: false,
        title: t('Rate'),
        tooltip: t('Rate'),
        align: TextAlign.LEFT,
        size: '100px',
      },
    ];
    return columns;
  }

  function renderDropdownTrigger(ref: any) {
    return (
      <Button type="icon" size={Size.SMALL} forwardRef={ref}>
        <Icon svg={MoreIcon} />
      </Button>
    );
  }

  function renderActionsColumn(row: EquipmentCostHistory, _col: ITableColumn<EquipmentCostHistory>): ReactNode {
    function renderDropdownContent(closeDropdown: () => void) {
      function handleDeleteDropdown(event: React.MouseEvent) {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();
        setSelectedCostHistory(row);
        deleteConfirmationState.open();
      }

      return (
        <ButtonList>
          <Button onClick={handleDeleteDropdown}>{t('Delete')}</Button>
        </ButtonList>
      );
    }

    return <Menu position={Position.RIGHT} trigger={renderDropdownTrigger} content={renderDropdownContent} />;
  }

  function renderDateColumn(row: EquipmentCostHistory, _col: ITableColumn<EquipmentCostHistory>): ReactNode {
    return <Label>{getDateName(dateTimeFromUtcISO(row.changeDate))}</Label>;
  }

  function renderEditDateColumn(row: EquipmentCostHistory, _col: ITableColumn<EquipmentCostHistory>): ReactNode {
    return <Label>{row.updatedOn ? getDateName(dateTimeFromUtcISO(row.updatedOn)) : '---'}</Label>;
  }

  function renderCostColumn(row: EquipmentCostHistory, _col: ITableColumn<EquipmentCostHistory>): ReactNode {
    return <Label>{currencyFormatter(row.operatorCostRate ?? 0) + '/' + t('hr')}</Label>;
  }

  function handleRowClick(row: EquipmentCostHistory) {
    setSelectedCostHistory(row);
    equipmentCostHistoryForm.open();
  }

  function renderEmptyState(): ReactNode {
    return <EmptyState title={t('There is no equipment cost history.')} />;
  }

  async function handleDelete() {
    if (!isNil(selectedCostHistory)) {
      await deleteEquipmentCostHistory(selectedCostHistory.id);
    }

    deleteConfirmationState.close();
    setSelectedCostHistory(undefined);
    onChange();
  }

  const classes = classNames('equipment-cost-history-table full-width', className);

  return (
    <div className={classes}>
      <Table
        className={classes}
        cols={renderColumns()}
        data={sortBy(
          costHistories.filter((costHistory) => costHistory.deletedOn === null),
          'changeDate'
        ).reverse()}
        strokeCols={false}
        lazyLoad={false}
        minWidth={`${renderColumns().length * 200}px`}
        emptyTemplate={renderEmptyState()}
        onRowClick={handleRowClick}
        header="standard"
        footer="standard"
      />
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationState.isOpen}
        onClose={deleteConfirmationState.close}
        onDelete={handleDelete}
        title={t('Delete Hourly Rate?')}
        showHeaderDivider={false}
      >
        {t('If you delete this item it will be gone forever. Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
      {selectedCostHistory && (
        <Dialog className={classes} isOpen={equipmentCostHistoryForm.isOpen} onClose={equipmentCostHistoryForm.close}>
          <EquipmentCostHistoryForm
            costHistory={selectedCostHistory}
            allCostHistories={costHistories.filter((costHistory) => costHistory.deletedOn === null)}
            onChange={() => {
              equipmentCostHistoryForm.close();
              onChange();
            }}
          />
        </Dialog>
      )}
    </div>
  );
};

export default EquipmentCostHistoryTable;
