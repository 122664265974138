import { Label } from '@busybusy/webapp-react-ui';
import ProgressBar from 'components/foundation/ProgressBar/ProgressBar';
import { t } from 'i18next';
import { isNil, toNumber } from 'lodash';
import { Optional } from 'types/util/Optional';
import { HOUR } from './constants/timeInterval';
import { isNumberString } from './numberUtils';
import { getTotalAsHoursMinutesSeconds } from './stringUtils';

export const budgetDangerBarColor = '#dc4453';
export const budgetHoursBarColor = '#2690fe';
export const budgetLaborCostBarColor = '#65c2af';
export const budgetEquipmentHoursBarColor = 'rgb(246, 193, 68)';
export const budgetEquipmentCostBarColor = '#75c696';
export const budgetTotalCostBarColor = '#5aad7b';
export const budgetProgressValueBarColor = '#9B7AF5';
export const budgetUnitsCompleteBarColor = '#7872F7';

export enum BudgetCostBarType {
  LABOR = 'labor',
  EQUIPMENT = 'equipment',
  TOTAL = 'total',
}

export function getPercentage(actual: number, budget: number) {
  if (actual === 0 && budget === 0) {
    return 0;
  }
  const rawPercentage = parseFloat(((actual / budget) * 100).toFixed(1));
  return rawPercentage > 999.9 ? 999.9 : rawPercentage;
}

export function renderHoursBarGraph(actual: number, budget?: number | null, title?: string | null) {
  const actualSeconds = actual;
  const budgetSeconds = budget;
  if (isNil(budgetSeconds)) {
    return (
      <>
        {title && <Label className={'bar-graph-title'}>{title}</Label>}
        <span className={actualSeconds > 0 ? 'danger' : undefined}>
          {`${getTotalAsHoursMinutesSeconds(actualSeconds)}`}
        </span>
        <ProgressBar
          className={'mt-3'}
          barColor={`${actualSeconds > 0 ? budgetDangerBarColor : budgetHoursBarColor}`}
          completed={0}
        />
      </>
    );
  }
  const hoursValue = getPercentage(actualSeconds, budgetSeconds);
  const hoursOverBudget = hoursValue > 100;
  return (
    <>
      {title && <Label className={'bar-graph-title'}>{title}</Label>}
      <span className={hoursOverBudget ? 'danger' : undefined}>
        {`${getTotalAsHoursMinutesSeconds(actualSeconds)} / ${getTotalAsHoursMinutesSeconds(budgetSeconds)} (${
          hoursValue === 999.9 ? '> ' + hoursValue : hoursValue
        }%)`}
      </span>
      <ProgressBar
        className={'mt-3'}
        barColor={`${hoursOverBudget ? budgetDangerBarColor : budgetHoursBarColor}`}
        completed={hoursValue}
      />
    </>
  );
}

export function renderHoursBarGraphForCard(actual: number, budget?: number | null) {
  const actualSeconds = actual;
  const budgetSeconds = budget;
  if (isNil(budgetSeconds)) {
    return (
      <div className="budget-dashboard-card-section">
        <div className="dashboard-card-bar">
          <ProgressBar className={'mt-1'} barColor={'#cccccc'} completed={100} />
        </div>
        <div className="dashboard-card-label">{'---'}</div>
      </div>
    );
  }
  const hoursValue = getPercentage(actualSeconds, budgetSeconds);
  const hoursOverBudget = hoursValue > 100;
  return (
    <div className="budget-dashboard-card-section">
      <div className="dashboard-card-bar">
        <ProgressBar
          className={'mt-1'}
          barColor={`${actualSeconds > budgetSeconds ? budgetDangerBarColor : budgetHoursBarColor}`}
          completed={hoursValue}
        />
      </div>
      <div className={hoursOverBudget ? 'danger dashboard-card-label' : 'dashboard-card-label'}>
        {`(${hoursValue === 999.9 ? '> ' + hoursValue : hoursValue}%)`}
      </div>
    </div>
  );
}

export function renderCostBarGraph(
  barType: BudgetCostBarType,
  currencyFormatter: (value: string | number) => string | null,
  actual: number,
  budget?: number | null,
  title?: string | null
) {
  let valueBarColor = '#65c2af';

  switch (barType) {
    case BudgetCostBarType.LABOR:
      valueBarColor = budgetLaborCostBarColor;
      break;
    case BudgetCostBarType.EQUIPMENT:
      valueBarColor = budgetEquipmentCostBarColor;
      break;
    case BudgetCostBarType.TOTAL:
      valueBarColor = budgetTotalCostBarColor;
      break;
  }

  const actualCost = actual;
  const budgetCost = budget;
  if (isNil(budgetCost)) {
    return (
      <>
        {title && <Label className={'bar-graph-title'}>{title}</Label>}
        <span className={actualCost > 0 ? 'danger' : undefined}>{`${currencyFormatter(actualCost)}`}</span>
        <ProgressBar
          className={'mt-3'}
          barColor={`${actualCost > 0 ? budgetDangerBarColor : valueBarColor}`}
          completed={0}
        />
      </>
    );
  }

  const costValue = getPercentage(actualCost, budgetCost);
  const costOverBudet = costValue > 100;
  return (
    <>
      {title && <Label className={'bar-graph-title'}>{title}</Label>}
      <span className={costOverBudet ? 'danger' : undefined}>
        {`${currencyFormatter(actualCost)} / ${currencyFormatter(budgetCost)} (${
          costValue === 999.9 ? '> ' + costValue : costValue
        }%)`}
      </span>

      <ProgressBar
        className={'mt-3'}
        barColor={`${costOverBudet ? budgetDangerBarColor : valueBarColor}`}
        completed={costValue}
      />
    </>
  );
}

export function renderCostBarGraphForCard(barType: BudgetCostBarType, actual: number, budget?: number | null) {
  let valueBarColor = '#65c2af';

  switch (barType) {
    case BudgetCostBarType.LABOR:
      valueBarColor = budgetLaborCostBarColor;
      break;
    case BudgetCostBarType.EQUIPMENT:
      valueBarColor = budgetEquipmentCostBarColor;
      break;
    case BudgetCostBarType.TOTAL:
      valueBarColor = budgetTotalCostBarColor;
      break;
  }

  const actualCost = actual;
  const budgetCost = budget;
  if (isNil(budgetCost)) {
    return (
      <div className="budget-dashboard-card-section">
        <div className="dashboard-card-bar">
          <ProgressBar className={'mt-1'} barColor={'#cccccc'} completed={100} />
        </div>
        <div className="dashboard-card-label">{'---'}</div>
      </div>
    );
  }

  const costValue = getPercentage(actualCost, budgetCost);
  const costOverBudet = costValue > 100;
  return (
    <div className="budget-dashboard-card-section">
      <div className="dashboard-card-bar">
        <ProgressBar
          className={'mt-1'}
          barColor={`${costOverBudet ? budgetDangerBarColor : valueBarColor}`}
          completed={costValue}
        />
      </div>
      <div className={costOverBudet ? 'danger dashboard-card-label' : 'dashboard-card-label'}>
        {`(${costValue === 999.9 ? '> ' + costValue : costValue}%)`}
      </div>
    </div>
  );
}

export function renderProgressValueBarGraph(
  currencyFormatter: (value: string | number) => string | null,
  actual: number,
  budget?: number | null,
  title?: string | null
) {
  if (isNil(budget) || budget === 0) {
    return (
      <>
        {title && <Label className={'bar-graph-title'}>{title}</Label>}
        <span className={actual > 0 ? 'danger' : undefined}>{`${currencyFormatter(actual)}`}</span>
        <ProgressBar
          className={'mt-3'}
          barColor={`${actual > 0 ? budgetDangerBarColor : budgetProgressValueBarColor}`}
          completed={0}
        />
      </>
    );
  }

  const costValue = getPercentage(actual, budget);
  const costOverBudet = costValue > 100;
  return (
    <>
      {title && <Label className={'bar-graph-title'}>{title}</Label>}
      <span className={costOverBudet ? 'danger' : undefined}>
        {`${currencyFormatter(actual)} / ${currencyFormatter(budget)} (${
          costValue === 999.9 ? '> ' + costValue : costValue
        }%)`}
      </span>

      <ProgressBar
        className={'mt-3'}
        barColor={`${costOverBudet ? budgetDangerBarColor : budgetProgressValueBarColor}`}
        completed={costValue}
      />
    </>
  );
}

export function renderProgressValueBarGraphForCard(actual: number, budget?: number | null) {
  if (isNil(budget)) {
    return (
      <div className="budget-dashboard-card-section">
        <div className="dashboard-card-bar">
          <ProgressBar className={'mt-1'} barColor={'#cccccc'} completed={100} />
        </div>
        <div className="dashboard-card-label">{'---'}</div>
      </div>
    );
  }

  const costValue = getPercentage(actual, budget);
  const costOverBudet = costValue > 100;
  return (
    <div className="budget-dashboard-card-section">
      <div className="dashboard-card-bar">
        <ProgressBar
          className={'mt-1'}
          barColor={`${costOverBudet ? budgetDangerBarColor : budgetProgressValueBarColor}`}
          completed={costValue}
        />
      </div>
      <div className={costOverBudet ? 'danger dashboard-card-label' : 'dashboard-card-label'}>
        {`(${costValue === 999.9 ? '> ' + costValue : costValue}%)`}
      </div>
    </div>
  );
}

export function renderUnitsCompleteBarGraph(
  actual: number,
  budget?: number | null,
  unit?: string | null,
  title?: string | null
) {
  const actualUnits = actual.toFixed(2);
  const unitTitle = unit ?? t('Units');
  if (isNil(budget) || budget === 0) {
    return (
      <>
        {title && <Label className={'bar-graph-title'}>{title}</Label>}
        <span className={actual > 0 ? 'danger' : undefined}>{`${actualUnits} ${unitTitle}`}</span>
        <ProgressBar
          className={'mt-3'}
          barColor={`${actual > 0 ? budgetDangerBarColor : budgetUnitsCompleteBarColor}`}
          completed={0}
        />
      </>
    );
  }

  const value = getPercentage(actual, budget);
  const overBudget = value > 100;
  return (
    <>
      {title && <Label className={'bar-graph-title'}>{title}</Label>}
      <span className={overBudget ? 'danger' : undefined}>
        {`${actualUnits} / ${budget} ${unitTitle} (${value === 999.9 ? '> ' + value : value}%)`}
      </span>

      <ProgressBar
        className={'mt-3'}
        barColor={`${overBudget ? budgetDangerBarColor : budgetUnitsCompleteBarColor}`}
        completed={value}
      />
    </>
  );
}

export function renderEquipmentHoursBarGraph(actual: number, budget?: number | null, title?: string | null) {
  const actualSeconds = actual;
  const budgetSeconds = budget;
  if (isNil(budgetSeconds)) {
    return (
      <>
        {title && <Label className={'bar-graph-title'}>{title}</Label>}
        <span className={actualSeconds > 0 ? 'danger' : undefined}>
          {`${getTotalAsHoursMinutesSeconds(actualSeconds)}`}
        </span>
        <ProgressBar
          className={'mt-3'}
          barColor={`${actualSeconds > 0 ? budgetDangerBarColor : budgetEquipmentHoursBarColor}`}
          completed={0}
        />
      </>
    );
  }
  const hoursValue = getPercentage(actualSeconds, budgetSeconds);
  const hoursOverBudget = hoursValue > 100;
  return (
    <>
      {title && <Label className={'bar-graph-title'}>{title}</Label>}
      <span className={hoursOverBudget ? 'danger' : undefined}>
        {`${getTotalAsHoursMinutesSeconds(actualSeconds)} / ${getTotalAsHoursMinutesSeconds(budgetSeconds)} (${
          hoursValue === 999.9 ? '> ' + hoursValue : hoursValue
        }%)`}
      </span>
      <ProgressBar
        className={'mt-3'}
        barColor={`${hoursOverBudget ? budgetDangerBarColor : budgetEquipmentHoursBarColor}`}
        completed={hoursValue}
      />
    </>
  );
}

export function renderEquipmentHoursBarGraphForCard(actual: number, budget?: number | null) {
  const actualSeconds = actual;
  const budgetSeconds = budget;
  if (isNil(budgetSeconds)) {
    return (
      <div className="budget-dashboard-card-section">
        <div className="dashboard-card-bar">
          <ProgressBar className={'mt-1'} barColor={'#cccccc'} completed={100} />
        </div>
        <div className="dashboard-card-label">{'---'}</div>
      </div>
    );
  }
  const hoursValue = getPercentage(actualSeconds, budgetSeconds);
  const hoursOverBudget = hoursValue > 100;
  return (
    <div className="budget-dashboard-card-section">
      <div className="dashboard-card-bar">
        <ProgressBar
          className={'mt-1'}
          barColor={`${actualSeconds > budgetSeconds ? budgetDangerBarColor : budgetEquipmentHoursBarColor}`}
          completed={hoursValue}
        />
      </div>
      <div className={hoursOverBudget ? 'danger dashboard-card-label' : 'dashboard-card-label'}>
        {`(${hoursValue === 999.9 ? '> ' + hoursValue : hoursValue}%)`}
      </div>
    </div>
  );
}

export function convertBudgetStringToHours(hours: string) {
  return Math.round(toNumber(hours) * HOUR);
}

export function hasBudgetHoursChanged(hours: string, oldHours: Optional<number>) {
  return isNumberString(hours) && oldHours !== convertBudgetStringToHours(hours);
}

export function hasBudgetNumbersChanged(value: string, oldValue: Optional<number>) {
  return isNumberString(value) && toNumber(value) !== oldValue;
}
