import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import StyledAccordion, { IStyledAccordionProps } from 'components/foundation/StyledAccordion/StyledAccordion';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import './FormAccordion.scss';

export interface IFormAccordionProps extends IStyledAccordionProps {
  form: any;
  openByDefault?: boolean;
  className?: ClassName;
}

const FormAccordion: FunctionComponent<IFormAccordionProps> = ({ className, form, error, ...accordionProps }) => {
  const [hasError, setHasError] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  // Add an error class to the accordion if any of it's children fail form validation
  useEffect(() => {
    setTimeout(() => {
      let error = false;

      if (ref.current) {
        const el = ref.current as HTMLDivElement;
        error = el.querySelectorAll('.form-field-error').length > 0;
      }
      setHasError(error);
    }, 10);
  }, [form]);

  const classes = classNames('form-accordion', className);

  return <StyledAccordion {...accordionProps} className={classes} error={hasError || error} />;
};

export default FormAccordion;
