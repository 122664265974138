import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useRef } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import OvertimePayPeriodType from 'types/enum/OvertimePayPeriodType';

export interface IOvertimeRuleSelectProps extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  onChange: (value: OvertimePayPeriodType, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const OvertimeRuleSelect = (props: IOvertimeRuleSelectProps) => {
  const { className, onChange, ...selectProps } = props;

  const [t] = useTranslation();

  function onChangeWage(value: string, event: React.ChangeEvent<HTMLSelectElement>) {
    onChange(value as OvertimePayPeriodType, event);
  }

  const options = useRef<ISelectOption[]>([
    {
      label: t('Follow Company'),
      value: OvertimePayPeriodType.FOLLOW_COMPANY,
    },
    {
      label: t('Standard'),
      value: OvertimePayPeriodType.STANDARD,
    },
    {
      label: t('California'),
      value: OvertimePayPeriodType.CALIFORNIA,
    },
    {
      label: t('Custom'),
      value: OvertimePayPeriodType.CUSTOM,
    },
  ]);

  const classes = classNames('overtime-rule-select', className);

  return (
    <Select
      className={classes}
      placeholder={t('Overtime Rule')}
      options={options.current}
      onChange={onChangeWage}
      {...selectProps}
    />
  );
};

OvertimeRuleSelect.defaultProps = {
  ...Select.defaultProps,
};

export default OvertimeRuleSelect;
