import { useEmployeeNameFormat } from 'hooks/settings';
import { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import { useCallback } from 'react';

export function getApolloMemberNameSort(format: EmployeeNameFormat, direction: 'asc' | 'desc') {
  if (format === 'FIRST' || format === 'FIRST_LAST') {
    return [{ firstName: direction }, { lastName: direction }];
  } else {
    return [{ lastName: direction }, { firstName: direction }];
  }
}

export function getApolloNestedMemberNameSort(
  memberFieldName: string,
  format: EmployeeNameFormat,
  direction: 'asc' | 'desc'
) {
  if (format === 'FIRST' || format === 'FIRST_LAST') {
    return [{ [memberFieldName]: { firstName: direction } }, { [memberFieldName]: { lastName: direction } }];
  } else {
    return [{ [memberFieldName]: { lastName: direction } }, { [memberFieldName]: { firstName: direction } }];
  }
}

export default function useApolloMemberNameSort() {
  const [employeeNameFormat] = useEmployeeNameFormat();
  return useCallback(
    (direction: 'asc' | 'desc') => {
      return getApolloMemberNameSort(employeeNameFormat, direction);
    },
    [employeeNameFormat]
  );
}

export function useApolloNestedMemberNameSort(memberFieldName: string) {
  const [employeeNameFormat] = useEmployeeNameFormat();
  return useCallback(
    (direction: 'asc' | 'desc') => {
      return getApolloNestedMemberNameSort(memberFieldName, employeeNameFormat, direction);
    },
    [employeeNameFormat, memberFieldName]
  );
}
