import { Button, Icon, Justify, Row, Theme, Tray } from '@busybusy/webapp-react-ui';
import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { IChildrenProps } from 'types/ChildrenProps';
import { ClassName } from 'types/ClassName';
import './ActionBar.scss';

export interface IActionBarProps extends Partial<IChildrenProps> {
  selectedCount: number;
  onClear: () => void;
  className?: ClassName;
}

const ActionBar = ({ onClear, selectedCount, children, className }: IActionBarProps) => {
  const classes = classNames(
    'action-header',
    'px-5',
    {
      'action-header-visible': selectedCount > 0,
    },
    className
  );

  return (
    <Row className={classes} justify={Justify.SPACE_BETWEEN}>
      {children}
      <Tray align="right">
        <span>{`${selectedCount} selected`}</span>
        <Button type="icon" theme={Theme.DARK} onClick={onClear}>
          <Icon svg={CloseIcon} />
        </Button>
      </Tray>
    </Row>
  );
};

export default ActionBar;
