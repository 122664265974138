import { Button, Tray } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Panel } from 'components';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import { DateTime } from 'luxon';
import { t } from 'utils/localize';

export interface ITimeActionsValidationsProps {
  className?: ClassName;
  onMoveLockDate?: () => void;
  onConflictContinue?: () => void;
  onSignatureViolationContinue?: () => void;
  lockDate?: DateTime;
  lockMemberIds?: string[];
  lockDateType?: LockDateDialogType;
  isLockDetailsOpen?: boolean;
  onLockDetailsClose?: () => void;
  isConflictDetailsOpen?: boolean;
  onConflictDetailsClose?: () => void;
  isSignatureViolationDetailsOpen?: boolean;
  onSignatureViolationDetailsClose?: () => void;
}

const TimeActionsValidations = (props: ITimeActionsValidationsProps) => {
  const {
    className,
    onMoveLockDate,
    onConflictContinue,
    onSignatureViolationContinue,
    lockDate,
    lockMemberIds,
    lockDateType,
    isLockDetailsOpen,
    onLockDetailsClose,
    isConflictDetailsOpen,
    onConflictDetailsClose,
    isSignatureViolationDetailsOpen,
    onSignatureViolationDetailsClose,
  } = props;

  const classes = classNames(
    {
      'time-actions-validations': true,
    },
    className
  );

  return (
    <div className={classes}>
      {lockDate && lockMemberIds && lockDateType && onMoveLockDate && isLockDetailsOpen && onLockDetailsClose && (
        <LockDateDialog
          date={lockDate}
          type={lockDateType}
          memberIds={lockMemberIds}
          isOpen={isLockDetailsOpen}
          onClose={onLockDetailsClose}
          onMoveLockDate={onMoveLockDate}
        />
      )}
      {onConflictContinue && isConflictDetailsOpen && onConflictDetailsClose && (
        <HeaderDialog
          title={t('Overlapping Time')}
          isOpen={isConflictDetailsOpen}
          onClose={onConflictDetailsClose}
          divider={false}
        >
          <Panel className={'p-5'}>
            {t(
              'Continuing with this action will cause one or more Time Entries to overlap. Are you sure you want to continue?'
            )}
            <Tray className="tray-right pt-6" align="left">
              <Button className="right-button" type="secondary" onClick={onConflictDetailsClose}>
                {t('Cancel')}
              </Button>
              <Button className="right-button" type="primary" onClick={onConflictContinue}>
                {t('Continue')}
              </Button>
            </Tray>
          </Panel>
        </HeaderDialog>
      )}
      {onSignatureViolationContinue && isSignatureViolationDetailsOpen && onSignatureViolationDetailsClose && (
        <HeaderDialog
          title={t('Signed Time Card')}
          isOpen={isSignatureViolationDetailsOpen}
          onClose={onSignatureViolationDetailsClose}
          divider={false}
        >
          <Panel className={'p-5'}>
            {t(
              'One or more entries belong to a time card that has already been signed. Continuing will require the employee to re-sign.'
            )}
            <Tray className="tray-right pt-6" align="left">
              <Button className="right-button" type="secondary" onClick={onSignatureViolationDetailsClose}>
                {t('Cancel')}
              </Button>
              <Button className="right-button" type="primary" onClick={onSignatureViolationContinue}>
                {t('Continue')}
              </Button>
            </Tray>
          </Panel>
        </HeaderDialog>
      )}
    </div>
  );
};

export default TimeActionsValidations;
