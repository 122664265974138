import { Card, Label, Size } from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IconButton } from 'components';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useCurrencyFormatter, useOpenable } from 'hooks';
import { t } from 'i18next';

import { EquipmentCostHistory } from '__generated__/graphql';
import { dateTimeFromUtcISO, getDateName } from 'utils/dateUtils';
import './ScheduleEquipmentHourlyCostChange.scss';

export interface IScheduleEquipmentHourlyCostChangeProps {
  className?: ClassName;
  onDelete: () => void;
  costHistory: EquipmentCostHistory;
}

const ScheduleEquipmentHourlyCostChange = (props: IScheduleEquipmentHourlyCostChangeProps) => {
  const { className, onDelete, costHistory } = props;

  const deleteConfirmationState = useOpenable();
  const currencyFormatter = useCurrencyFormatter();

  function handleOnDelete() {
    deleteConfirmationState.close();
    onDelete();
  }

  const classes = classNames('schedule-equipment-hourly-cost-change', className);

  return (
    <div className={classes}>
      <Card type="stroke" leftBorderType="primary">
        <div className="p-4">
          <div className="schedule-equipment-hourly-change-header pb-4">
            <Label className="my-2 fw-bold schedule-equipment-hourly-change-title">
              {t('Scheduled Hourly Cost Change')}
            </Label>
            <div>
              <IconButton
                size={Size.MEDIUM}
                onClick={deleteConfirmationState.open}
                svg={DeleteIcon}
                tooltipLabel={t('Delete')}
              />
            </div>
          </div>
          <div className="schedule-equipment-hourly-change-item-title">{t('Rate')}</div>
          <div className="schedule-equipment-hourly-change-item-value mb-5">
            {currencyFormatter(costHistory.operatorCostRate ?? 0) + ' / ' + t('Hourly')}
          </div>

          <div className="schedule-equipment-hourly-change-item-title">{t('Effective Date')}</div>
          <div className="schedule-equipment-hourly-change-item-value mb-5">
            {getDateName(dateTimeFromUtcISO(costHistory.changeDate), 'LLLL d')}
          </div>
        </div>
      </Card>
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationState.isOpen}
        onClose={deleteConfirmationState.close}
        onDelete={handleOnDelete}
        title={t('Delete Hourly Cost?')}
        showHeaderDivider={false}
      >
        {t('If you delete this item it will be gone forever. Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
    </div>
  );
};

export default ScheduleEquipmentHourlyCostChange;
