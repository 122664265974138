import { OrganizationSignInQuestionAudience } from '__generated__/graphql';
import { SUBMISSIONS_REPORT_QUERY } from 'apollo/queries/member-sign-in-submission';
import { useActiveMember, useApolloPaging, useOrganization } from 'hooks';
import useGetOrganizationSignInQuestions from 'hooks/models/organization-sign-in-questions/useGetOrganizationSignInQuestions';
import { first, isEmpty, isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import ICursorable from 'types/Cursorable';
import { IMemberSignInSubmission } from 'types/MemberSignInSubmission';
import { mapNotNil } from 'utils/collectionUtils';
import { utcToday } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { buildChoiceObjectFromJSON, getAnswersForQuestion } from 'utils/organizationSignInQuestionUtils';

export interface ISignInAnswerCheck {
  complete: boolean;
  flagged: boolean;
  requiredMessage?: string | null;
  warningMessage?: string | null;
  showQuestions: boolean;
}

export default function useSignInAnswerCheck() {
  const { getAll } = useApolloPaging();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const today = utcToday();
  const activeMember = useActiveMember();
  const activeOrg = useOrganization();
  const [submissionId, setSubmissionId] = useState<string | null>(null);
  const [answerCheck, setAnswerCheck] = useState<ISignInAnswerCheck | null>(null);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const fetchQuestions = useGetOrganizationSignInQuestions();
  const isDailySignInEnabled = useFeatureFlags('DAILY_SIGN_IN_QUESTIONS');
  const isPro = useFeatureFlags('PRO');

  useMemo(async () => {
    if (
      isPro &&
      isDailySignInEnabled &&
      activeOrg.busySignInQuestion &&
      memberIds.some((id) => id === activeMember.id)
    ) {
      try {
        const submissions = await getAll<IMemberSignInSubmission & ICursorable>('memberSignInSubmissions', {
          query: SUBMISSIONS_REPORT_QUERY,
          variables: {
            first: 500,
            filter: {
              localTime: {
                lessThanOrEqual: today.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
                greaterThanOrEqual: today.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
              },
              memberId: { equal: activeMember.id },
              deletedOn: { isNull: true },
            },
            sort: [{ localTime: 'asc' }],
          },
          fetchPolicy: 'network-only',
        });

        let requiredMessage: string | null = null;
        let warningMessage: string | null = null;

        const questions = await fetchQuestions({ deletedOn: { isNull: true } });
        const activeQuestions = questions.filter(
          (question) =>
            question.audienceType === OrganizationSignInQuestionAudience.Everyone ||
            (question.audienceType === OrganizationSignInQuestionAudience.Position &&
              question.positionId === activeMember.positionId)
        );

        try {
          const sub = first(submissions.filter((s) => s.id === submissionId)) || first(submissions);
          if (!isNil(sub)) {
            activeQuestions.forEach((question) => {
              const answerObjects = getAnswersForQuestion(sub.answers ?? [], question, activeQuestions);
              if (!isEmpty(answerObjects)) {
                const answers = mapNotNil(answerObjects, (a) => a.answer);
                const choices = buildChoiceObjectFromJSON(question.choices);
                const choice = first(choices.filter((c) => answers.includes(c.choice)));
                if (choice && choice.restrict && !isNil(choice.explanation)) {
                  if (isNil(requiredMessage)) {
                    requiredMessage = '';
                  }
                  requiredMessage += `${choice.explanation}\n\n`;
                } else if (choice && !isNil(choice.explanation)) {
                  if (isNil(warningMessage)) {
                    warningMessage = '';
                  }
                  warningMessage += `${choice.explanation}\n\n`;
                }
              }
            });
          }
        } catch (e: any) {
          setIsLoading(false);
          setError(e);
          setAnswerCheck(null);
        }

        setIsLoading(false);
        setError(null);
        const isRestricted = submissions.some((s) => s.restricted);

        if (isRestricted) {
          if (isNil(requiredMessage)) {
            requiredMessage = '';
          }
          requiredMessage =
            t(
              'Based on your answers, you are not permitted to clock in. If you have questions, please contact your supervisor.'
            ) +
            '\n\n' +
            requiredMessage;
        }
        setAnswerCheck({
          complete: submissions.some((s) => s.id === submissionId) || isRestricted,
          flagged: isRestricted,
          requiredMessage,
          warningMessage,
          showQuestions: !isEmpty(activeQuestions),
        });
      } catch (e: any) {
        setIsLoading(false);
        setError(e);
        setAnswerCheck(null);
      }
    } else {
      setAnswerCheck(null);
    }
  }, [submissionId, memberIds, fetchQuestions]);

  useEffect(() => {
    return () => {
      setAnswerCheck(null);
      setMemberIds([]);
      setSubmissionId(null);
      setError(null);
    };
  }, []);

  return { answerCheck, setSubmissionId, error, isLoading, setMemberIds };
}
