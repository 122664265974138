import axios, { AxiosInstance as Instance } from 'axios';
import { decodeAuthToken } from './authenticationUtils';

import { IStoreAuthentication, IStoreEnvironment } from '../store/store';

interface IGetAxiosInstanceProps {
  authentication: IStoreAuthentication;
  url: string;
}

export type AxiosInstance = Instance;

export const getAxiosClient = ({ authentication, url }: IGetAxiosInstanceProps): AxiosInstance => {
  const { token } = authentication;

  const keyAuthorization = decodeAuthToken(token || '').keyAuthorization;

  const instance = axios.create({
    baseURL: url,
    headers: { 'key-authorization': keyAuthorization },
    params: {
      _debug: 'true',
      _version: '3.2',
    },
  });

  return instance;
};

export const getNonAuthenticatedAxiosClient = (environment: IStoreEnvironment): AxiosInstance => {
  const { REACT_APP_RESTAPI_URL } = environment;

  const instance = axios.create({
    baseURL: REACT_APP_RESTAPI_URL,
    params: {
      _debug: 'true',
      _version: '3.2',
    },
  });

  return instance;
};

export const getAxiosApiClient = (apiKey: string, baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: { 'api-key': apiKey },
    params: {
      _debug: 'true',
      _version: '3.2',
    },
  });

  return instance;
};
