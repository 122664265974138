import { Dialog, Theme, Tooltip } from '@busybusy/webapp-react-ui';
import { BudgetIcon } from 'assets/icons';
import classNames from 'classnames';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import useBudgetReportColumns from 'containers/budgets/hooks/useBudgetReportColumns';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import LaborBudgetsForm from 'containers/settings/budgeting/forms/LaborBudgetsForm';
import { useOpenable, useOrganization } from 'hooks';
import useBudgetsEnabled from 'hooks/models/organization/useBudgetsEnabled';
import useBudgetReportDetailsNavigation from 'hooks/navigation/useBudgetReportDetailsNavigation';
import useBudgetReportNavigation from 'hooks/navigation/useBudgetReportNavigation';
import useCanViewBudgets from 'hooks/permission/useCanViewBudgets';
import { isNil, keyBy, orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { IClassName } from 'types/ClassName';
import {
  BudgetCostBarType,
  renderCostBarGraphForCard,
  renderEquipmentHoursBarGraphForCard,
  renderHoursBarGraphForCard,
  renderProgressValueBarGraphForCard,
} from 'utils/budgetUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import DashboardProUpgradeCard from '../DashboardProUpgradeCard/DashboardProUpgradeCard';
import './BudgetsDashboardCard.scss';
import useBudgetDashboardData, { IBudgetDashboardCardItem } from './hooks/useBudgetDashboard';

export interface IBudgetsDashboardCardProps {
  className?: IClassName;
  theme: Theme.DARK | Theme.LIGHT;
  setting: IDashboardSettingsItem;
  onHide: () => void;
}

const BudgetsDashboardCard = ({ className, theme, setting, onHide }: IBudgetsDashboardCardProps) => {
  const budgetReportNavigation = useBudgetReportNavigation();
  const [filteredData, setFilteredData] = useState<IBudgetDashboardCardItem[]>([]);
  const organization = useOrganization();
  const isPro = useFeatureFlags('PRO');
  const enableBudgetDialog = useOpenable();
  const fetchBudgetData = useBudgetDashboardData(setting);
  const [isLoading, setIsLoading] = useState(true);
  const { savedColumns } = useBudgetReportColumns();
  const budgetReportDetailsNavigation = useBudgetReportDetailsNavigation();
  const isBudgetsEnabled = useBudgetsEnabled();
  const canViewBudgets = useCanViewBudgets();

  const columnKeyMap = useMemo(() => keyBy(savedColumns ?? [], 'key'), [savedColumns]);
  const showHours = columnKeyMap['laborHours']?.visible ?? false;
  const showCost = columnKeyMap['laborCost']?.visible ?? false;
  const showProgress = columnKeyMap['progressValue']?.visible ?? false;
  const showEquipmentHours = columnKeyMap['equipmentHours']?.visible ?? false;
  const showEquipmentCost = columnKeyMap['equipmentCost']?.visible ?? false;
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (isBudgetsEnabled) {
      setData();
    }
  }, [isBudgetsEnabled, setting]);

  const classes = classNames('budget-dashboard-card', className);

  function openBudgetReport() {
    budgetReportNavigation();
  }

  async function setData() {
    setIsLoading(true);
    setError(false);
    const useCustomIds = setting.options?.projectsToDisplay === 'custom';

    try {
      const budgetData = await fetchBudgetData;
      const budgets = await budgetData();
      const filteredBudgets = budgets.filter((d) => {
        return (
          (!isNil(d.budgetSeconds) || !isNil(d.budgetCost)) &&
          (d.actualCost || d.actualSeconds) &&
          d.actualSecondsForRange
        );
      });

      const filteredBudgetData = orderBy(
        filteredBudgets,
        [
          (item) => {
            return item.actualSecondsForRange;
          },
          (item) => {
            return item.project.title;
          },
        ],
        ['desc', 'asc']
      );

      const sortedCustomBudgets = orderBy(budgets, (item) => {
        return item.sortOrder;
      });

      setFilteredData(useCustomIds ? sortedCustomBudgets : filteredBudgetData.slice(0, 4));
    } catch (e) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleBudgetClick(projectId: string) {
    budgetReportDetailsNavigation({ project_id: projectId });
  }

  function RenderBudgetGraph(item: IBudgetDashboardCardItem) {
    return (
      <div key={item.id} className="py-4 budget-dashboard-summary" onClick={() => handleBudgetClick(item.project.id)}>
        <h3 className={'project-name'}>{item.project.title}</h3>

        {showProgress && (
          <Tooltip label={t('Progress Value')} delay={0}>
            {renderProgressValueBarGraphForCard(item.actualProgressValue ?? 0, item.budgetProgressValue)}
          </Tooltip>
        )}
        {showHours && (
          <Tooltip label={t('Labor Hours')} delay={0}>
            {renderHoursBarGraphForCard(item.actualSeconds ?? 0, item.budgetSeconds)}
          </Tooltip>
        )}
        {showCost && (
          <Tooltip label={t('Labor Cost')} delay={0}>
            {renderCostBarGraphForCard(BudgetCostBarType.LABOR, item.actualCost ?? 0, item.budgetCost)}
          </Tooltip>
        )}
        {showEquipmentHours && (
          <Tooltip label={t('Equipment Hours')} delay={0}>
            {renderEquipmentHoursBarGraphForCard(item.equipmentActualSeconds ?? 0, item.equipmentBudgetSeconds)}
          </Tooltip>
        )}
        {showEquipmentCost && (
          <Tooltip label={t('Equipment Cost')} delay={0}>
            {renderCostBarGraphForCard(
              BudgetCostBarType.EQUIPMENT,
              item.equipmentActualCost ?? 0,
              item.equipmentBudgetCost
            )}
          </Tooltip>
        )}
      </div>
    );
  }

  function renderMissingDataSections() {
    switch (filteredData.length) {
      case 0:
        return (
          <>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
          </>
        );
      case 1:
        return (
          <>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
          </>
        );
      case 2:
        return (
          <>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
          </>
        );
      case 3:
        return (
          <>
            <div className="py-4 budget-dashboard-summary">{'---'}</div>
          </>
        );
      case 4:
        return <></>;
    }
  }

  if (!canViewBudgets) {
    return null;
  }

  return (
    <>
      {isPro ? (
        <>
          {isBudgetsEnabled ? (
            !error ? (
              <DashboardContentCard
                subheaderValue={
                  setting.options?.projectsToDisplay === 'custom'
                    ? t('Highlighted Budgets')
                    : getDashboardTimeFrameTitle(t('Most Active Budgets'), setting.options?.time ?? 'pastSeven')
                }
                className={classes}
                theme={theme}
                rightLabel={t('View All')}
                onRightLabelClick={openBudgetReport}
              >
                {isLoading ? (
                  <DashboardCardSpinner />
                ) : (
                  <>
                    {filteredData.map((item) => {
                      return RenderBudgetGraph(item);
                    })}
                    {renderMissingDataSections()}
                  </>
                )}
              </DashboardContentCard>
            ) : (
              <DashboardErrorCard theme={theme} onClick={setData} />
            )
          ) : (
            <>
              <DashboardEnableCard
                title={setting.title}
                svg={BudgetIcon}
                buttonLabel={t('Enable Budgets')}
                theme={theme}
                onHide={onHide}
                onClick={enableBudgetDialog.open}
              />
              <Dialog isOpen={enableBudgetDialog.isOpen} onClose={enableBudgetDialog.close} position="center">
                <LaborBudgetsForm
                  className="p-8"
                  trackBudgets={organization.trackBudgets ?? false}
                  trackBudgetCost={organization.trackBudgetCost ?? false}
                  trackBudgetEquipmentHours={organization.trackBudgetEquipmentHours ?? false}
                  trackBudgetEquipmentCosts={organization.trackBudgetEquipmentCosts ?? false}
                  trackBudgetProgress={organization.trackBudgetProgress ?? false}
                  trackBudgetProgressValue={organization.trackBudgetProgressValue ?? false}
                  trackCostCode={organization.trackCostCode ?? false}
                  trackEquipment={organization.trackEquipment ?? false}
                  performanceScores={organization.performanceScores ?? false}
                  onSubmit={enableBudgetDialog.close}
                />
              </Dialog>
            </>
          )}
        </>
      ) : (
        <DashboardProUpgradeCard title={setting.title} svg={BudgetIcon} theme={theme} onHide={onHide} />
      )}
    </>
  );
};

export default BudgetsDashboardCard;
