import { Button, Icon, Theme, Toast } from '@busybusy/webapp-react-ui';
import useIsInIframe from 'hooks/store/useIsInIframe';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IReduxState, IStoreEnvironment } from 'store/store';
import { t } from 'utils/localize';
import { exitToLegacyApp } from './utilities';

export type TNavButtonHandler = 'exitToLegacy' | 'back' | (() => any);

interface INavButtonContainerProps {
  onClick: TNavButtonHandler;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export interface INavButtonProps extends INavButtonContainerProps {
  goBack: () => void;
  legacyAppUrl: IStoreEnvironment['REACT_APP_LEGACY_URL'];
}

export interface INavButtonUIProps {
  onClick: () => any | Promise<() => any>;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const navigationFailedErrorMessage = t(`Navigation failed. The requestion action is not supported.`);

const NavButtonContainer: React.FC<INavButtonContainerProps> = ({ onClick, icon }) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const legacyAppUrl = useSelector<IReduxState, string>((state) => state.environment.REACT_APP_LEGACY_URL);
  const isInIframe = useIsInIframe();

  if (isInIframe && onClick === 'exitToLegacy') {
    return null;
  }

  return <NavButtonComponent onClick={onClick} icon={icon} goBack={goBack} legacyAppUrl={legacyAppUrl} />;
};

export const NavButtonComponent: React.FC<INavButtonProps> = ({ onClick, icon, goBack, legacyAppUrl }) => {
  const [errorToastMessage, setErrorToastMessage] = useState<string | null>(null);

  const handleClick = () => {
    if (onClick === 'exitToLegacy') {
      return exitToLegacyApp(legacyAppUrl);
    } else if (onClick === 'back') {
      return goBack();
    } else if (typeof onClick === 'function') {
      return onClick();
    } else {
      return setErrorToastMessage(navigationFailedErrorMessage);
    }
  };

  return (
    <>
      <NavButtonUI onClick={handleClick} icon={icon} />

      <Toast isOpen={errorToastMessage !== null} onClose={() => setErrorToastMessage(null)} theme={Theme.DANGER}>
        {errorToastMessage}
      </Toast>
    </>
  );
};

export const NavButtonUI: React.FC<INavButtonUIProps> = ({ onClick, icon }) => (
  <Button type="icon" onClick={onClick}>
    <Icon svg={icon} />
  </Button>
);

export default NavButtonContainer;
