import { useCallback, useEffect, useRef } from 'react';
import { ChannelIds } from '../ChannelIds';
import { useChannelContext } from '../useChannelContext';

export function usePrintChannelListener(loadedAll: boolean, loadAll?: () => Promise<void>) {
  const channel = useChannelContext();
  const shouldPrint = useRef(false);

  const onPrint = useCallback(async () => {
    if (!loadedAll && loadAll) {
      shouldPrint.current = true;
      loadAll();
    } else {
      window.print();
    }
  }, [loadAll, loadedAll]);

  useEffect(() => {
    if (loadedAll && shouldPrint.current) {
      shouldPrint.current = false;
      window.print();
    }
  }, [loadedAll]);

  useEffect(() => {
    channel.registerListener(ChannelIds.Print, onPrint);

    return () => {
      channel.unregisterListener(ChannelIds.Print);
    };
  }, [channel, onPrint]);
}
