import { useApolloClient } from '@apollo/client';
import {
  Button,
  CheckboxFormField,
  DatePickerFormField,
  Divider,
  Form,
  IDatePickerPayload,
  IFormRender,
  Label,
  Link,
  Loader,
  TextFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ProfileImageFormField } from 'components';
import EquipmentCategoryFormField from 'components/domain/equipment/EquipmentCategoryFormField/EquipmentCategoryFormField';
import EquipmentMakeFormField from 'components/domain/equipment/EquipmentMakeFormField/EquipmentMakeFormField';
import EquipmentModelFormField from 'components/domain/equipment/EquipmentModelFormField/EquipmentModelFormField';
import { equipmentUnknownModelQuery } from 'components/domain/equipment/EquipmentModelsForMakePicker/equipment-models-picker-queries';
import EquipmentTypeFormField from 'components/domain/equipment/EquipmentTypeFormField/EquipmentTypeFormField';
import EquipmentYearFormField from 'components/domain/equipment/EquipmentYearFormField/EquipmentYearFormField';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import CurrencyFormField from 'components/foundation/form-fields/CurrencyFormField/CurrencyFormField';
import useEquipmentImageUpdate from 'containers/manage-employees/hooks/useEquipmentImageUpdate';
import { useEquipment, useEquipmentHours, useOpenable } from 'hooks';
import useCreateEquipmentCostHistory from 'hooks/models/equipment-cost-history/useCreateEquipmentCostHistory';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useRef } from 'react';
import IEquipmentModel from 'types/EquipmentModel';
import { t } from 'utils/localize';
import { v_number_decimals_only, v_require } from 'utils/validations';
import EquipmentMissingDialog from '../EquipmentMissingDialog/EquipmentMissingDialog';
import './CreateEquipmentForm.scss';

export interface ICreateEquipmentFormProps {
  className?: ClassName;
  onSubmit: (data?: string) => void;
  onClose: () => void;
  onImportClick: () => void;
}

export interface IEquipmentFormData {
  equipmentName?: string | undefined;
  typeId?: string | undefined;
  categoryId?: string | undefined;
  makeId?: string | undefined;
  modelId?: string | undefined;
  modelTitle?: string | undefined;
  trackHourMeter?: boolean | undefined;
  hourMeter?: number | undefined;
  hourlyCost?: number | undefined;
  hourlyCostEffectiveDate?: IDatePickerPayload | undefined;
  profile?: { file: File | null } | null;
  imageUrl?: string;
}

const CreateEquipmentForm = (props: ICreateEquipmentFormProps) => {
  const { className, onSubmit, onClose, onImportClick } = props;
  const today = DateTime.local().startOf('day');

  const formData = useRef<IEquipmentFormData>({
    hourlyCostEffectiveDate: { value: today.toJSDate(), inputValue: today.toFormat('M/D/YYYY') },
  });

  const { createEquipment } = useEquipment();
  const { createEquipmentHours } = useEquipmentHours();
  const apolloClient = useApolloClient();
  const cantFindDialogDetails = useOpenable();
  const requestSentDialogDetails = useOpenable();
  const errorToastState = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const imageUpdate = useEquipmentImageUpdate();
  const loaderDetails = useOpenable();
  const createEquipmentCostHistory = useCreateEquipmentCostHistory();
  const dateDetails = useOpenable();

  const classes = classNames('create-equipment-form', className);

  const equipmentNameField = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label required={true}>{t('Name')}</Label>
      <TextFormField name={'equipmentName'} form={form} validations={[{ validation: v_require }]} />
    </>
  );

  const equipmentTypePicker = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label required={true}>{t('Type')}</Label>
      <EquipmentTypeFormField name={'typeId'} form={form} validations={[{ validation: v_require }]} />
    </>
  );

  const equipmentCategoryPicker = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label required={true}>{t('Category')}</Label>
      <EquipmentCategoryFormField
        name={'categoryId'}
        equipmentTypeId={form.state.data.typeId}
        form={form}
        validations={[{ validation: v_require }]}
      />
    </>
  );

  const equipmentMakePicker = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Make')}</Label>
      <EquipmentMakeFormField name={'makeId'} equipmentCategoryId={form.state.data.categoryId} form={form} />
    </>
  );

  const equipmentModelPicker = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Model')}</Label>
      <EquipmentModelFormField
        name={'modelTitle'}
        equipmentMakeId={form.state.data.makeId}
        equipmentCategoryId={form.state.data.categoryId}
        form={form}
        validations={form.state.data.makeId ? [{ validation: v_require }] : undefined} // if make is selected then we have to pick a model
      />
    </>
  );

  const equipmentYearPicker = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Year')}</Label>
      <EquipmentYearFormField
        className="year-field"
        name={'modelId'}
        equipmentMakeId={form.state.data.makeId}
        equipmentCategoryId={form.state.data.categoryId}
        equipmentModelTitle={form.state.data.modelTitle}
        form={form}
        validations={form.state.data.makeId ? [{ validation: v_require }] : undefined} // if make is selected then we have to pick a model
      />
    </>
  );

  const equipmentHoursField = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Hour Meter')}</Label>
      <TextFormField
        className="hour-meter-field"
        type="number"
        name={'hourMeter'}
        form={form}
        placeholder={'0.0'}
        restrictTo={{ maxLength: 10 }}
        validations={[{ validation: v_number_decimals_only, args: { maxDecimalPlaces: 2 } }]}
      />
    </>
  );

  const trackHoursCheckbox = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <CheckboxFormField name="trackHourMeter" label={t('Manually Track Hours')} form={form} />
    </>
  );

  const equipmentHourlyCostField = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Divider className={'my-4'} />
      <Label>{t('Hourly Rate')}</Label>
      <div className="mb-2">
        {t(
          'The cost to run the machine for one hour, minus labor costs. This should be the total of all non-operator costs such as fuel, maintanence, etc.'
        )}
      </div>
      <CurrencyFormField
        className="hour-meter-field"
        name="hourlyCost"
        form={form}
        textAlign="right"
        placeholder="0.00"
      />
      <Label>{t('Effective Date')}</Label>
      <DatePickerFormField
        name="hourlyCostEffectiveDate"
        form={form}
        validations={[{ validation: v_require }]}
        isOpen={dateDetails.isOpen}
        onClose={dateDetails.close}
        onOpen={dateDetails.open}
      />
      <Divider className={'my-4'} />
    </>
  );

  const createButton = (form: IFormRender<IEquipmentFormData>) => (
    <Button className="pl-5 pr-5" type="primary" onClick={form.handleSubmit}>
      {t('Create')}
    </Button>
  );

  const cantFindMessage = (form: IFormRender<IEquipmentFormData>) => (
    <div className="mt-9">
      <span className="bottom-link-message">{t('Can’t find the make, model or year you are looking for? ')}</span>
      <Link onClick={() => loadEquipmentMissingDialog(form)} href="#">
        {t('Click here.')}
      </Link>
    </div>
  );

  function handleBulkImportClick() {
    onImportClick();
    onClose();
  }

  const bulkImportMessage = () => (
    <div className="mt-2">
      <span className="bottom-link-message">{t('Need to create several pieces of equipment? Try ')}</span>
      <Link onClick={handleBulkImportClick} href="#">
        {t('bulk import.')}
      </Link>
    </div>
  );

  const imageField = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Equipment Image')}</Label>
      <ProfileImageFormField name="profile" initialImage={null} form={form} />
    </>
  );

  function renderFormFields({ ...form }: IFormRender<IEquipmentFormData>): ReactNode {
    return (
      <div>
        {equipmentNameField(form)}
        {equipmentTypePicker(form)}
        {equipmentCategoryPicker(form)}
        {equipmentMakePicker(form)}
        {equipmentModelPicker(form)}
        {equipmentYearPicker(form)}
        {equipmentHourlyCostField(form)}
        {trackHoursCheckbox(form)}
        {form.state.data.trackHourMeter ? equipmentHoursField(form) : null}
        {imageField(form)}
        {createButton(form)}
        {cantFindMessage(form)}
        {bulkImportMessage()}
      </div>
    );
  }

  function loadEquipmentMissingDialog(form: IFormRender<IEquipmentFormData>) {
    formData.current = form.state.data; // update ref so we can pass some of the information already set
    cantFindDialogDetails.open();
  }

  const getUnknownModel = async (categoryId: string) => {
    const results = await apolloClient.query<{ equipmentModels: IEquipmentModel[] }>({
      query: equipmentUnknownModelQuery,
      variables: {
        categoryId,
      },
    });

    return _.first(results.data.equipmentModels);
  };

  async function onSubmitForm(data: IEquipmentFormData) {
    loaderDetails.open();
    if (validateData(data)) {
      let modelId = data.modelId;

      if (!modelId) {
        const unknownModel = await getUnknownModel(data.categoryId!);
        modelId = unknownModel?.id;
      }

      if (!modelId) {
        // could not find unknown model
        errorToastMessage.current = t('Cannot save without a make, model, and year selected.');
        errorToastState.open();
        return;
      }

      createEquipment(data.equipmentName!, modelId!, data.trackHourMeter === true)
        .then(async (response) => {
          const equipmentId = response.data.createEquipment.id;
          if (data.profile) {
            await imageUpdate(equipmentId, data.profile.file).catch(() => {
              errorToastMessage.current = t('There was an error saving the image.');
              errorToastState.open();
              loaderDetails.close();
            });
          }

          let shouldSubmit = true;

          if (data.hourMeter && data.hourMeter > 0) {
            shouldSubmit = false;
            // save the hour meter if the user input a valid value
            createEquipmentHours(equipmentId, data.hourMeter)
              .then(() => {
                onSubmit(response.data.createEquipment.id);
              })
              .catch(() => {
                errorToastMessage.current = t('There was an error saving the hour meter.');
                errorToastState.open();
              });
          }

          if (data.hourlyCost && data.hourlyCost > 0) {
            shouldSubmit = false;
            // save the hourly cost if the user input a valid value
            createEquipmentCostHistory(
              equipmentId,
              undefined,
              data.hourlyCost,
              data.hourlyCostEffectiveDate?.value
                ? DateTime.fromJSDate(data.hourlyCostEffectiveDate.value).startOf('day').toISO()
                : today.toISO()
            )
              .then(() => {
                onSubmit(response.data.createEquipment.id);
              })
              .catch(() => {
                errorToastMessage.current = t('There was an error saving cost history.');
                errorToastState.open();
              });
          }

          if (shouldSubmit) {
            onSubmit(response.data.createEquipment.id);
          }

          loaderDetails.close();
        })
        .catch(() => {
          errorToastMessage.current = t('There was an error saving the equipment.');
          errorToastState.open();
          loaderDetails.close();
        });
    } else {
      errorToastMessage.current = t('Answer all of the questions and try again.');
      errorToastState.open();
      loaderDetails.close();
    }
  }

  function validateData(data: IEquipmentFormData): boolean {
    if (_.isEmpty(data.equipmentName)) {
      return false;
    } else if (!_.isNil(data.makeId) && _.isNil(data.modelId)) {
      // if make is selected then we also need a model
      return false;
    }

    return true;
  }

  const onEquipmentRequestSent = () => {
    cantFindDialogDetails.close();
    requestSentDialogDetails.open();
  };

  return (
    <div className={classes}>
      <div className="mb-6 p-8">
        <Form data={formData.current} onSubmit={onSubmitForm} render={renderFormFields} className={classes} />

        <EquipmentMissingDialog
          isOpen={cantFindDialogDetails.isOpen}
          onClose={cantFindDialogDetails.close}
          onSubmit={onEquipmentRequestSent}
          categoryId={formData.current.categoryId}
          makeId={formData.current.makeId}
          modelTitle={formData.current.modelTitle}
        />

        <HeaderDialog
          isOpen={requestSentDialogDetails.isOpen}
          title={t('Your request has been sent.')}
          onClose={requestSentDialogDetails.close}
          divider={false}
        >
          <Label className="pl-8 pr-8 pb-9">{t('Requests usually take a few business days to process.')}</Label>
        </HeaderDialog>

        <Toast isOpen={errorToastState.isOpen} onClose={errorToastState.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>
        <Loader isOpen={loaderDetails.isOpen} />
      </div>
    </div>
  );
};

export default CreateEquipmentForm;
