import { Col } from '@busybusy/webapp-react-ui';
import { InspectionQuestionAnswerAttachment } from '__generated__/graphql';
import { BulletinPostAttachment } from 'containers/bulletin/types/types';
import * as React from 'react';
import { useState } from 'react';
import { ErrorState, LoadingState } from '../../../../components';
import { IMediaEntry } from '../../../../types/MediaEntry';
import './PhotoFullscreen.scss';

export interface IPhotoFullScreenProps {
  photo?: IMediaEntry | BulletinPostAttachment | InspectionQuestionAnswerAttachment;
  performShake: boolean;
  showAnnotations?: boolean;
  annotationFileUrl?: string;
}

const PhotoFullScreen: React.FunctionComponent<IPhotoFullScreenProps> = (props) => {
  const { photo, performShake, showAnnotations, annotationFileUrl } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const classes = performShake ? 'image-container ac-shake' : 'image-container';

  function handleImageFinishedLoading() {
    setIsLoading(false);
  }

  function handleImageErrorLoading() {
    setError(true);
  }

  return (
    <Col className={classes}>
      <div className="image">
        {photo && (
          <img src={(showAnnotations && annotationFileUrl) ? annotationFileUrl : photo.fileUrl!} onLoad={handleImageFinishedLoading} onError={handleImageErrorLoading} alt="" />
        )}
      </div>
      {isLoading && !error ? <LoadingState /> : null}
      {error && <ErrorState />}
    </Col>
  );
};

export default PhotoFullScreen;
