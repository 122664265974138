import gql from 'graphql-tag';

export const memberGroupsQuery = gql`
  query MemberGroups($first: Int, $after: String, $filter: MemberGroupFilter) {
    memberGroups(first: $first, after: $after, filter: $filter, sort: [{ groupName: asc }]) {
      id
      groupName
      cursor
    }
  }
`;

export const memberGroupQuery = gql`
  query MemberGroupById($groupId: Uuid!) {
    memberGroups(filter: { id: { equal: $groupId } }) {
      id
      groupName
      cursor
    }
  }
`;
