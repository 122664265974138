import { useRef } from 'react';

type Action<R> = () => R;

type PendingActionTuple<R> = [(action: Action<R>) => void, () => R | null];

function usePendingAction<R>(): PendingActionTuple<R> {
  const pendingAction = useRef<Action<R> | null>(null);

  function register(action: Action<R>) {
    pendingAction.current = action;
  }

  function call() {
    return pendingAction.current?.() ?? null;
  }

  return [register, call];
}

export default usePendingAction;
