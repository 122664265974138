export function lessThan<T>(value: T, other: T, inclusive: boolean) {
  if (inclusive) {
    return value <= other;
  } else {
    return value < other;
  }
}

export function greaterThan<T>(value: T, other: T, inclusive: boolean) {
  if (inclusive) {
    return value >= other;
  } else {
    return value > other;
  }
}
