import { ClassName } from '@busybusy/webapp-react-ui';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { useTimesheetsGraylog } from 'hooks';
import { t } from 'i18next';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { ITimeOffFormData } from '../../form/CreateTimeOffForm/CreateTimeOffForm';
import CreateTimeOffFormContainer from '../../form/CreateTimeOffFormContainer/CreateTimeOffFormContainer';

export interface ICreateTimeOffFormDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data?: ITimeOffFormData) => void;
  currentDate?: DateTime | null;
  memberIds?: string[] | null;
  className?: ClassName;
}

export default function CreateTimeOffFormDialog({
  className,
  isOpen,
  currentDate,
  memberIds,
  onSubmit,
  onClose,
}: ICreateTimeOffFormDialogProps) {
  const userEvents = useTimesheetsGraylog();
  const today = currentDate ? currentDate : DateTime.local().startOf('day');

  const defaultFormData: ITimeOffFormData = {
    members: memberIds ? memberIds : [],
    startDate: { value: today.toJSDate(), inputValue: today.toFormat('M/D/YYYY') },
    paid: false,
  };

  const userEventsStable = useRef(userEvents.events);
  useEffect(() => {
    if (isOpen) {
      if (_.isNil(memberIds) || _.isEmpty(memberIds) || memberIds.length === 1) {
        userEventsStable.current(TimesheetsTypes.events.action_type.ADD_TIME_OFF);
      } else {
        userEventsStable.current(TimesheetsTypes.events.action_type.ADD_TIME_OFFS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <HeaderDialog className={className} isOpen={isOpen} title={t('Create Time Off')} onClose={onClose} divider={false}>
      <CreateTimeOffFormContainer formData={defaultFormData} onSubmit={onSubmit} />
    </HeaderDialog>
  );
}
