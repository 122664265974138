import { ClassName } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ReactNode } from 'react';
import './DetailHeaderWithText.scss';

export interface IDetailHeaderWithTextProps {
  className?: ClassName;
  title: string;
  value: string | ReactNode | undefined;
  removeBottomPadding?: boolean;
}

const DetailHeaderWithText = (props: IDetailHeaderWithTextProps) => {
  const { className, title, value, removeBottomPadding } = props;

  const classes = classNames(`detail-header-with-text ${removeBottomPadding ? '' : 'py-4'}`, className);

  return (
    <>
      <div className={classes}>
        <div className="detail-header">{title}</div>
        <div className="value">{value}</div>
      </div>
    </>
  );
};

export default DetailHeaderWithText;
