import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import './HeaderText.scss';

export interface IHeaderTextProps {
  title: string;
  children?: ReactNode;
  className?: ClassName;
}

const HeaderText = ({ title, children, className }: IHeaderTextProps) => {
  const classes = classNames('header-text', className);

  return (
    <div className={classes}>
      <div className="header-title">{title}</div>
      {children}
    </div>
  );
};

export default HeaderText;
