import { useApolloClient } from '@apollo/client';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import { UNJOINED_MEMBER_LOCATIONS_QUERY } from 'containers/time-log-feed/apollo/time-log-feed-queries';
import { first } from 'lodash';
import { useEffect, useState } from 'react';
import MemberLocationType from 'types/enum/MemberLocationType';
import ILocation from 'types/Location';
import { Nullable } from 'types/util/Nullable';
import { memberLocationsUtils } from 'utils';
import { getGraphQlIdFilterVariables } from 'utils/apolloUtils';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { UnjoinedMemberLocationsQueryResult, UnjoinedMemberLocationsQueryVariables } from '__generated__/graphql';

type MemberLocation = UnjoinedMemberLocationsQueryResult['data'];

export default function useMemberMapLocation(locationId: Nullable<string>, locationType: Nullable<MemberLocationType>) {
  const [location, setLocation] = useState<Nullable<ILocation>>(null);

  const client = useApolloClient();

  useEffect(() => {
    async function getLocation(id: string, type: MemberLocationType) {
      const results = await client.query<MemberLocation, UnjoinedMemberLocationsQueryVariables>({
        query: UNJOINED_MEMBER_LOCATIONS_QUERY,
        variables: getGraphQlIdFilterVariables(id),
      });

      const memberLocations = results.data?.memberLocations ?? [];
      const memberLocation = first(memberLocations);
      if (memberLocation) {
        setLocation({
          id: memberLocation.id,
          lat: memberLocation.latitude,
          lng: memberLocation.longitude,
          icon: memberLocationsUtils.getTypeIcon(type),
          infoWindowTitle: memberLocationsUtils.getTypeTitle(type),
          infoWindowBody: (
            <p className="pl-5" key={memberLocation.id}>
              <TimeAndSuperscript
                dateTime={dateTimeFromISOKeepZone(memberLocation.timeLocal)}
                dst={memberLocation.timeDst}
                format={DateTimeFormat.dateAtTime}
              />
            </p>
          ),
        });
      } else {
        setLocation(null);
      }
    }

    if (locationId && locationType) {
      getLocation(locationId, locationType);
    } else {
      setLocation(null);
    }
  }, [client, locationId, locationType]);

  return location;
}
