import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import OvertimePayRateSelect from 'components/domain/organization-overtime-period/OvertimePayRateSelect/OvertimePayRateSelect';
import OvertimePayRateType from 'types/enum/OvertimePayRateType';
import { AnyObject } from 'types/util/Object';

export interface IOvertimePayRateFormFieldProps<FormType extends AnyObject>
  extends IFormFieldDefaultProps<OvertimePayRateType, FormType> {
  className?: ClassName;
  onChange?: (value: OvertimePayRateType) => void;
  disabled?: boolean;
}

const OvertimePayRateFormField = <FormType extends AnyObject>({
  className,
  form,
  name,
  validations,
  onChange,
  disabled,
  revalidateOnNewValidations,
  ...other
}: IOvertimePayRateFormFieldProps<FormType>) => {
  const classes = classNames('overtime-pay-rate-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: OvertimePayRateType) {
      fieldProps.handleChange(val, onChange);
    }

    const formValue = form.state.data[name];
    const value = formValue ? formValue : OvertimePayRateType.ONE_POINT_FIVE;

    return (
      <>
        <OvertimePayRateSelect
          error={fieldProps.hasError}
          value={value}
          onChange={handleOnSelect}
          disabled={disabled}
          {...other}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      form={form}
      name={name}
      validations={validations}
      className={classes}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
};

export default OvertimePayRateFormField;
