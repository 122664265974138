import { ClassName, IFormValidation } from '@busybusy/webapp-react-ui';
import {
  convertBreakMultipickerItemsToQuickTimeTemplateBreaks,
  convertOffsetIsoTimeRangeToDateTimeTimeRange,
} from 'components/domain/time-entry/time-actions-form/utils/utils';
import useQuickTimeTemplates from 'containers/quick-time/hooks/useQuickTimeTemplates';
import QuickTimeTemplateForm, {
  IQuickTimeTemplateFormData,
} from 'containers/quick-time/QuickTimeTemplateForm/QuickTimeTemplateForm';
import { ITimeEntryTemplate } from 'containers/quick-time/types/types';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import v_does_not_contain from 'utils/validations/v_does_not_contain';
import { uuid } from 'utils/uuidUtils';

export interface IQuickTimeTemplateEditFormProps {
  onSubmit?: (template: ITimeEntryTemplate) => void;
  template: ITimeEntryTemplate;
  className?: ClassName;
}

export default function QuickTimeTemplateEditForm({
  template,
  onSubmit: onFormSubmit,
  className,
}: IQuickTimeTemplateEditFormProps) {
  const { templates, edit } = useQuickTimeTemplates();

  const formData: IQuickTimeTemplateFormData = useMemo(() => {
    const { name, startTime, endTime, breaks, members, projectId, costCodeId, equipmentId, description } = template;

    const startDateTime = startTime ? DateTime.fromISO(startTime) : null;

    return {
      name,
      members: members ?? [],
      projectId: projectId ?? null,
      costCodeId: costCodeId ?? null,
      equipmentId: equipmentId ?? null,
      description: description ?? null,
      startTime: startDateTime as DateTime,
      endTime: DateTime.fromISO(endTime),
      breaks: (breaks ?? []).map((brk) => ({
        id: uuid(),
        timeRange: convertOffsetIsoTimeRangeToDateTimeTimeRange(brk, startDateTime),
      })),
    };
  }, [template]);

  const templateNameValidation: Array<IFormValidation<string | undefined, any>> = useMemo(() => {
    return [
      { validation: v_require },
      {
        validation: v_does_not_contain,
        args: {
          inValues: Object.keys(templates).filter((v) => v !== template.name),
          message: t('Template name already in use.'),
        },
      },
    ];
  }, [templates, template]);

  function onSubmit(formData: IQuickTimeTemplateFormData) {
    const position = template.position;
    const { name, breaks, members, projectId, costCodeId, equipmentId, description, startTime, endTime } = formData;

    const newTemplate = {
      name: name.trim(),
      position,
      members: members ?? null,
      projectId: projectId ?? null,
      costCodeId: costCodeId ?? null,
      equipmentId: equipmentId ?? null,
      description: description ?? null,
      startTime: startTime.toISOTime({ includeOffset: false, suppressMilliseconds: true }) ?? null,
      endTime: endTime.toISOTime({ includeOffset: false, suppressMilliseconds: true }) ?? null,
      breaks: (breaks ?? []).map((brk) =>
        convertBreakMultipickerItemsToQuickTimeTemplateBreaks(brk, formData.startTime)
      ),
    };
    edit(template.name.trim(), newTemplate);
    onFormSubmit?.(newTemplate);
  }

  return (
    <QuickTimeTemplateForm
      templateNameValidation={templateNameValidation}
      onSubmit={onSubmit}
      formData={formData}
      submissionText={t('Save')}
      className={className}
    />
  );
}
