import { type IPositionBooleanKey, type TFlagKey } from 'types';
import { type IChildrenProps } from 'types/ChildrenProps';
import { ValidateAgreementsContainer } from './ValidateAgreements/ValidateAgreementsContainer';
import { ValidateAuthentication } from './ValidateAuthentication';
import { ValidateFeatureFlags } from './ValidateFeatureFlags';
import { ValidatePermission } from './ValidatePermission';

export interface IAccessControlProps extends IChildrenProps {
  authenticate: boolean;
  featureFlags?: TFlagKey | TFlagKey[];
  permissions?: IPositionBooleanKey | IPositionBooleanKey[];
}

export const AccessControl = ({ children, authenticate, featureFlags, permissions }: IAccessControlProps) => {
  return (
    <ValidateAuthentication authenticate={authenticate}>
      <ValidateAgreementsContainer authenticate={authenticate}>
        <ValidateFeatureFlags featureFlags={featureFlags}>
          <ValidatePermission permissions={permissions}>{children}</ValidatePermission>
        </ValidateFeatureFlags>
      </ValidateAgreementsContainer>
    </ValidateAuthentication>
  );
};
