export function createScript(id: string, src: string, onLoad?: () => void) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.id = id;
  script.src = src;
  const node = document.getElementsByTagName('script')[0];
  node.parentNode?.insertBefore(script, node);
  if (onLoad) {
    document.getElementById(id)?.addEventListener('load', onLoad);
  }
}
