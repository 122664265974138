import { Dialog } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import CustomerSupportActionList from 'components/layout/navigation-drawer/NavHeader/CustomerSupportActionList/CustomerSupportActionList';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { ClassName } from 'types/ClassName';
import CommandPallet from '../CommandPallet';

export interface ICommandPalletDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose?: () => void;
}

const CommandPalletDialog = ({ className, isOpen, onClose }: ICommandPalletDialogProps) => {
  const commandPalletOpenable = useOpenable();
  const customerSupportOpenable = useOpenable();

  const timeEntryDialogDetails = useOpenable();
  const timeOffDialogDetails = useOpenable();

  function handleClose() {
    onClose?.();
    commandPalletOpenable.close();
  }

  function handleActionSubmit() {
    handleClose();
    window.location.reload();
  }

  const classes = classNames('command-pallet-dialog', className);

  return (
    <>
      <Dialog isOpen={isOpen} onClose={handleClose} className={classes}>
        {isOpen && (
          <CommandPallet
            onClose={handleClose}
            openSupport={customerSupportOpenable.open}
            onAddTimeEntry={timeEntryDialogDetails.open}
            onAddTimeOff={timeOffDialogDetails.open}
          />
        )}
      </Dialog>
      <HeaderDialog
        title={t('Support')}
        isOpen={customerSupportOpenable.isOpen}
        onClose={customerSupportOpenable.close}
      >
        <CustomerSupportActionList onSelect={customerSupportOpenable.close} className="mx-8 mb-6 pt-2" />
      </HeaderDialog>
      {timeEntryDialogDetails.isOpen && (
        <TimeActionsFormDialog
          type={'add'}
          isOpen={timeEntryDialogDetails.isOpen}
          onClose={timeEntryDialogDetails.close}
          onSubmit={handleActionSubmit}
          onDelete={handleActionSubmit}
        />
      )}

      {timeOffDialogDetails.isOpen && (
        <TimeOffFormDialog
          isOpen={timeOffDialogDetails.isOpen}
          onClose={timeOffDialogDetails.close}
          onSubmit={handleActionSubmit}
        />
      )}
    </>
  );
};

export default CommandPalletDialog;
