import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeOff from 'types/TimeOff';
import ITimeOffRequest from 'types/TimeOffRequest';

export interface IStoreTimeOffReport {
  timeOffReportTableColumns: IVisibleTableColumn[];
  checkedTimeOffs: ITimeOff[];
  timeOffRequestAwaitingApprovalReportTableColumns: IVisibleTableColumn[];
  timeOffRequestProcessedReportTableColumns: IVisibleTableColumn[];
  checkedTimeOffRequests: ITimeOffRequest[];
}

export const initialState: IStoreTimeOffReport = {
  timeOffReportTableColumns: [],
  checkedTimeOffs: [],
  timeOffRequestAwaitingApprovalReportTableColumns: [],
  timeOffRequestProcessedReportTableColumns: [],
  checkedTimeOffRequests: [],
};

const slice = createSlice({
  name: 'TimeOffReport',
  initialState,
  reducers: {
    updateTimeOffReportTableColumns: (state: IStoreTimeOffReport, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.timeOffReportTableColumns = action.payload;

      return state;
    },
    updateCheckedTimeOffs: (state: IStoreTimeOffReport, action: PayloadAction<ITimeOff[]>) => {
      state.checkedTimeOffs = action.payload;

      return state;
    },
    clearCheckedTimeOffs: (state: IStoreTimeOffReport) => {
      state.checkedTimeOffs = [];
      return state;
    },
    updateTimeOffRequestAwaitingApprovalReportTableColumns: (
      state: IStoreTimeOffReport,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.timeOffRequestAwaitingApprovalReportTableColumns = action.payload;

      return state;
    },
    updateTimeOffRequestProcessedReportTableColumns: (
      state: IStoreTimeOffReport,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.timeOffRequestProcessedReportTableColumns = action.payload;

      return state;
    },
    updateCheckedTimeOffRequests: (state: IStoreTimeOffReport, action: PayloadAction<ITimeOffRequest[]>) => {
      state.checkedTimeOffRequests = action.payload;

      return state;
    },
    clearCheckedTimeOffRequests: (state: IStoreTimeOffReport) => {
      state.checkedTimeOffRequests = [];
      return state;
    },
  },
});

export const {
  updateTimeOffReportTableColumns,
  updateCheckedTimeOffs,
  clearCheckedTimeOffs,
  updateTimeOffRequestAwaitingApprovalReportTableColumns,
  updateTimeOffRequestProcessedReportTableColumns,
  updateCheckedTimeOffRequests,
  clearCheckedTimeOffRequests,
} = slice.actions;
export default slice.reducer;
