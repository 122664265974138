import { createSlice } from '@reduxjs/toolkit';

// Normal is mostly visible but sometimes hidden depending on the view e.g. login page has no navbar
// Normal can be collapsed or not collapsed
export const navigationBarStatuses = ['normal', 'expanded', 'collapsed', 'hidden'] as const;

export type NavigationBarStatus = (typeof navigationBarStatuses)[number];

export function isNavigationBarStatus(s: string): s is NavigationBarStatus {
  return navigationBarStatuses.some((status) => status === s);
}

export interface IStoreNavigationBar {
  status: NavigationBarStatus;
}

const initialState: IStoreNavigationBar = {
  status: 'normal',
};

const slice = createSlice({
  name: 'navigationBar',
  initialState,
  reducers: {
    setNavigationBarStatus: (state: IStoreNavigationBar, action: { payload: NavigationBarStatus }) => {
      state.status = action.payload;
      return state;
    },
  },
});

export const { setNavigationBarStatus } = slice.actions;
export default slice.reducer;
