/*
  Wrapper for https://github.com/marcuswestin/store.js/

  Name spaces all local storage keys to prevent collisions
*/

import { reduce } from 'lodash';
import storeJsApi from 'store/dist/store.modern';
import dump from 'store/plugins/dump';
import { IDictionary } from 'types/Dictionary';

export class LocalStore {
  private KEY_PREFIX = 'busybusy::webapp-react::';
  private store: StoreJsAPI;

  constructor(storeImpl: StoreJsAPI) {
    storeImpl.addPlugin(dump);
    this.store = storeImpl;
  }

  public set = (key: string, value: any) => {
    const prefixedKey = this.getPrefixedKey(key);

    return this.store.set(prefixedKey, value);
  };

  public get = (key: string) => {
    const prefixedKey = this.getPrefixedKey(key);

    return this.store.get(prefixedKey);
  };

  public getAll = () => {
    const allValues: IDictionary<any> = (this.store as any).dump();
    return reduce(
      Object.keys(allValues) as string[],
      (acc, cur) => {
        if (cur.startsWith(this.KEY_PREFIX)) {
          return { ...acc, [this.getUnPrefixedKey(cur)]: allValues[cur] };
        }

        return acc;
      },
      {} as IDictionary<any>
    );
  };

  public remove = (key: string) => {
    const prefixedKey = this.getPrefixedKey(key);

    return this.store.remove(prefixedKey);
  };

  public forEach = (fn: (val: any, key: string) => void) => {
    this.store.each((val, key) => {
      if (key.startsWith(this.KEY_PREFIX)) {
        fn(val, key);
      }
    });
  };

  public update = (key: string, value: any) => {
    const updated = {
      ...this.get(key),
      ...value,
    };

    this.set(key, updated);
    return updated;
  };

  public clearAll = () => {
    this.forEach((v, key) => {
      const unPrefixedKey = this.getUnPrefixedKey(key);
      this.remove(unPrefixedKey);
    });
  };

  private getPrefixedKey = (key: string) => `${this.KEY_PREFIX}${key}`;
  private getUnPrefixedKey = (key: string) => key.replace(this.KEY_PREFIX, '');
}

export default new LocalStore(storeJsApi);
