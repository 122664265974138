import _ from 'lodash';
import queryString, { ParsedQuery } from 'query-string';

export function getQueryParam(queryParams: ParsedQuery, key: string, defaultValue: any = '') {
  if (queryParams[key] && typeof queryParams[key] === 'string') {
    return queryParams[key] as string;
  } else {
    return defaultValue;
  }
}

export function getQueryParamByType<T>(queryParams: ParsedQuery, key: string, type: object, defaultValue: T): T {
  const query = getQueryParam(queryParams, key);
  if (query !== '') {
    const formattedQuery = _.toLower(query).replace(/\s/g, '');
    const typed = _.find(Object.values(type) as string[], (value) => {
      return _.toLower(value).replace(/\s/g, '') === formattedQuery;
    });

    if (typed) {
      return typed as unknown as T;
    } else {
      return defaultValue;
    }
  } else {
    return defaultValue;
  }
}

// Recent dependency update no longer exports these directly
export const exclude = queryString.exclude;
export const extract = queryString.extract;
export const parse = queryString.parse;
export const parseUrl = queryString.parseUrl;
export const pick = queryString.pick;
export const stringify = queryString.stringify;
export const stringifyUrl = queryString.stringifyUrl;
