import { useApolloClient } from '@apollo/client';
import { MEMBER_NAME_QUERY } from 'apollo/queries/member-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Capsule from 'components/foundation/Capsule/Capsule';
import { useCallback, useEffect, useState } from 'react';
import IMember from 'types/Member';
import { fullName } from 'utils/memberUtils';

export interface IMemberCapsulesProps {
  className?: ClassName;
  memberIds: string[];
  printVersion?: boolean;
}

const MemberCapsules = ({ className, memberIds, printVersion }: IMemberCapsulesProps) => {
  const classes = classNames('member-capsules', className);
  const client = useApolloClient();
  const [members, setMembers] = useState<IMember[]>([]);

  useEffect(() => {
    getFilterMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds]);

  const getFilterMember = useCallback(async () => {
    const results = await client.query<{ members: IMember[] }>({
      query: MEMBER_NAME_QUERY,
      variables: { filter: { id: { contains: memberIds } } },
      fetchPolicy: 'network-only',
    });
    setMembers(results.data.members ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds]);

  function renderList() {
    if (printVersion) {
      return members.map((item) => (
        <h2 key={item.id} className="mb-3">
          {fullName(item)}
        </h2>
      ));
    } else {
      return members.map((item) => <Capsule key={item.id} className={classes} title={fullName(item)} />);
    }
  }

  return <>{renderList()}</>;
};

export default MemberCapsules;
