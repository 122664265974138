import { useApolloClient, useMutation } from '@apollo/client';
import { CREATE_EQUIPMENT_HOURS } from 'apollo/mutations/equipment-hours-mutation';
import { EQUIPMENT_HOURS_QUERY } from 'apollo/queries/equipment-hours-queries';
import { EQUIPMENT_IDS_QUERY } from 'apollo/queries/equipment-queries';
import { useActiveMember, useApolloPaging } from 'hooks';
import { first, isNil, sum } from 'lodash';
import { DateTime } from 'luxon';
import IEquipment from 'types/Equipment';
import IEquipmentHours, { IEquipmentHoursCreateInput } from 'types/EquipmentHours';
import ITimeRange from 'types/TimeRange';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { uuid } from 'utils/uuidUtils';

export default function useEquipmentHours() {
  const [createEquipmentHoursMutation] = useMutation(CREATE_EQUIPMENT_HOURS);
  const activeMember = useActiveMember();
  const client = useApolloClient();
  const { getAll } = useApolloPaging();

  function createEquipmentHours(equipmentId: string, hours: number) {
    const entry: IEquipmentHoursCreateInput = {
      id: uuid(),
      equipmentId,
      runningHours: Math.round(hours * 10) / 10,
      submittedByMemberId: activeMember.id!,
      source: 'Operator',
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return createEquipmentHoursMutation({ variables: { equipmentHours: entry } });
  }

  async function getEquipmentHours(
    timeRange: ITimeRange<DateTime>,
    equipmentId?: string | null,
    query: any = EQUIPMENT_HOURS_QUERY,
    includeDeleted = false
  ) {
    const equipmentResults = await getAll<IEquipment>('equipment', {
      query: EQUIPMENT_IDS_QUERY,
      variables: {
        filter: {
          id: !isNil(equipmentId) ? { equal: equipmentId } : undefined,
          deletedOn: includeDeleted ? undefined : { isNull: true },
        },
        sort: [{ createdOn: 'asc' }],
      },
      fetchPolicy: 'network-only',
    });
    const equipmentIds = equipmentResults.map((e) => e.id);
    const promises = equipmentIds.map(async (id) => {
      return await client.query<{ equipmentHours: IEquipmentHours[] }>({
        query,
        variables: {
          first: 1,
          filter: {
            equipmentId: { equal: id },
            deletedOn: { isNull: true },
            createdOn: {
              between: {
                start: timeRange.startTime
                  .minus(timeRange.startTime.toLocal().offset * 60 * 1000)
                  .toISO({ suppressSeconds: true }),
                end: timeRange.endTime
                  .minus(timeRange.endTime.toLocal().offset * 60 * 1000)
                  .toISO({ suppressSeconds: true }),
              },
            },
          },
          sort: [{ createdOn: 'desc' }],
        },
        fetchPolicy: 'network-only',
      });
    });
    const hoursResults = await Promise.all(promises);
    return hoursResults.map((h) => first(h.data.equipmentHours));
  }

  async function getEquipmentHoursBefore(date: DateTime, equipmentId: string, query: any = EQUIPMENT_HOURS_QUERY) {
    const results = await client.query<{ equipmentHours: IEquipmentHours[] }>({
      query,
      variables: {
        first: 1,
        filter: {
          equipmentId: { equal: equipmentId },
          deletedOn: { isNull: true },
          createdOn: {
            lessThan: date.toISO({ suppressSeconds: true, includeOffset: false }),
          },
        },
        sort: [{ createdOn: 'desc' }],
      },
      fetchPolicy: 'network-only',
    });
    return first(results.data.equipmentHours);
  }

  async function getMachineHours(
    timeRange: ITimeRange<DateTime>,
    equipmentId?: string | null,
    query: any = EQUIPMENT_HOURS_QUERY
  ) {
    const hoursInRange = await getEquipmentHours(timeRange, equipmentId, query);
    const promises = hoursInRange.map(async (h) => {
      if (h) {
        const hoursBeforeRange = await getEquipmentHoursBefore(timeRange.startTime, h.equipmentId, query);
        if (hoursBeforeRange) {
          return h.runningHours - hoursBeforeRange.runningHours;
        } else {
          return h.runningHours;
        }
      } else {
        return 0;
      }
    });
    const values = await Promise.all(promises);
    return sum(values);
  }

  return { createEquipmentHours, getEquipmentHours, getEquipmentHoursBefore, getMachineHours };
}
