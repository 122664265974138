import { Card } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import PayrollServicesView from 'components/domain/premium-feature-limits/PayrollServicesView/PayrollServicesView';
import PremiumBundleView from 'components/domain/premium-feature-limits/PremiumBundleView/PremiumBundleView';
import { ClassName } from 'types/ClassName';
import './EnablePremiumDialog.scss';

export interface IEnablePremiumDialogProps {
  className?: ClassName;
}

export const EnablePremiumDialog = (props: IEnablePremiumDialogProps) => {
  const { className } = props;
  const classes = classNames('enable-premium-dialog', 'my-10', className);

  return (
    <Card className={classes}>
      <PremiumBundleView />
      <PayrollServicesView backgroundColor={'#f5f5f7'} />
    </Card>
  );
};
