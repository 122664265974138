export const ACCESS_TYPE = 'access_type';
export const ACTION_TYPE = 'action_type';
export const ACTIVITY = 'activity';
export const ARCHIVED_STATUS = 'archived_status';
export const BI_WEEKLY_PAY_PERIOD_DAY = 'bi_weekly_pay_period_day';
export const BULLETIN_FILTER_SETTING = 'bulletin_filter_setting';
export const CODE = 'code';
export const COLORS = 'colors';
export const COMPANY_NAME = 'company_name';
export const COMPANY_SIZE = 'company_size';
export const COST_CODE_GROUP_ID = 'cost_code_group_id';
export const COST_CODE_ID = 'cost_code_id';
export const CREATED_BY_ID = 'created_by_id';
export const DATE_RANGE_LIMIT = 'date_range_limit';
export const DPR_TEMPLATE_ID = 'dpr_template_id';
export const DRAFT_STATUS = 'draft_status';
export const EMAIL = 'email';
export const EMPLOYEES = 'employees';
export const EMPLOYEE_GROUP_ID = 'employee_group_id';
export const EMPLOYEE_ID = 'employee_id';
export const END_DATE = 'end_date';
export const EQUIPMENT = 'equipment';
export const EQUIPMENT_CATEGORY_ID = 'equipment_category_id';
export const EQUIPMENT_ID = 'equipment_id';
export const FILE_TYPE = 'file_type';
export const FILTER_STATUS = 'filter_status';
export const FILTER_TYPE = 'filter_type';
export const FIRST_NAME = 'first_name';
export const GRAPH_TYPE = 'graph_type';
export const INVITATION = 'invitation';
export const LAST_NAME = 'last_name';
export const LOCATION_TYPE = 'location_type';
export const MONTHLY_DAY = 'monthly_day';
export const OVERTIME_START = 'overtime_start';
export const PAY_PERIOD_START = 'pay_period_start';
export const PAY_PERIOD_TYPE = 'pay_period_type';
export const PAY_TYPE = 'pay_type';
export const PERFORMER_ID = 'performer_id';
export const PHONE = 'phone';
export const PHOTO_TYPE = 'photo_type';
export const POSITION_ID = 'position_id';
export const PRINT = 'print';
export const PROGRESS_VIEW_TYPE = 'progress_view_type';
export const PROJECTS = 'projects';
export const PROJECT_GROUP_ID = 'project_group_id';
export const PROJECT_ID = 'project_id';
export const QUERY_PARAM_DATE_FORMAT = 'M-d-yyyy';
export const REALM_ID = 'realmId';
export const RECURRING_STATUS = 'recurring_status';
export const REPORT_TYPE = 'report_type';
export const RESET_PASSWORD = 'reset_password';
export const SCHEDULED_STATUS = 'scheduled_status';
export const SEARCH = 'search';
export const SEMI_MONTHLY_DAY_ONE = 'semi_monthly_day_one';
export const SEMI_MONTHLY_DAY_TWO = 'semi_monthly_day_two';
export const SETTING = 'setting';
export const SIGNATURE_STATUS = 'signature_status';
export const START_DATE = 'start_date';
export const TIME = 'time';
export const TIMECARD_REPORT_VIEW = 'timecard_report_view';
export const TIMESHEET_GRAPH_TYPE = 'timesheet_graph_type';
export const TIMESHEET_REPORT_TYPE = 'timesheet_report_type';
export const TIMESHEET_VIEW = 'timesheet_view';
export const TIME_CARD_ISSUE = 'time_card_issue';
export const TIME_ENTRY_STATE = 'time_entry_state';
export const TIME_OFF_TYPE = 'time_off_type';
export const TIME_RANGE_TYPE = 'time_range_type';
export const TRADE = 'trade';
export const TYPE = 'type';
export const UNSUBSCRIBE = 'unsubscribe';
export const USERNAME = 'username';
export const VERIFY_EMAIL = 'verify_email';
export const VIEW = 'view';
export const FOLDER_ID = 'folder_id';
export const TAG_ID = 'tag_id';
export const VIEW_SETTING = 'view_setting';
export const WAGE_TYPE = 'wage_type';
export const FLAGGED_LOCATION_STATUS = 'flagged_location_status';
export const REVIEWED_BY_MEMBER_ID = 'reviewed_by_member_id';
export const PER_DIEM_TYPE = 'per_diem_type';
export const PER_DIEM_STATUS = 'per_diem_status';
export const PER_DIEM_PROCESSED_STATUS = 'per_diem_processed_status';
export const DPR_FAVORITE_FROM_NAV = 'dpr_favorite_from_nav';
export const INCIDENT_REPORT_TYPE = 'incident_report_type';
export const INCIDENT_RECORDABLE = 'incident_recordable';
export const INCIDENT_TYPE = 'incident_type';
export const INCIDENT_OUTCOME = 'incident_outcome';
export const YEAR = 'year';
export const INSPECTION_REPORT_TYPE = 'inspection_report_type';
export const CHECKLIST_ID = 'checklist_id';
export const SUBPROJECT_ID = 'subproject_id';

export const QueryParam = {
  ACCESS_TYPE,
  ACTION_TYPE,
  ACTIVITY,
  ARCHIVED_STATUS,
  BI_WEEKLY_PAY_PERIOD_DAY,
  BULLETIN_FILTER_SETTING,
  CODE,
  COLORS,
  COMPANY_NAME,
  COMPANY_SIZE,
  COST_CODE_GROUP_ID,
  COST_CODE_ID,
  CREATED_BY_ID,
  DATE_RANGE_LIMIT,
  DPR_TEMPLATE_ID,
  DRAFT_STATUS,
  EMAIL,
  EMPLOYEES,
  EMPLOYEE_GROUP_ID,
  EMPLOYEE_ID,
  END_DATE,
  EQUIPMENT,
  EQUIPMENT_CATEGORY_ID,
  EQUIPMENT_ID,
  FILE_TYPE,
  FILTER_STATUS,
  FILTER_TYPE,
  FIRST_NAME,
  GRAPH_TYPE,
  INVITATION,
  LAST_NAME,
  LOCATION_TYPE,
  MONTHLY_DAY,
  OVERTIME_START,
  PAY_PERIOD_START,
  PAY_PERIOD_TYPE,
  PAY_TYPE,
  PERFORMER_ID,
  PHONE,
  PHOTO_TYPE,
  POSITION_ID,
  PRINT,
  PROGRESS_VIEW_TYPE,
  PROJECTS,
  PROJECT_GROUP_ID,
  PROJECT_ID,
  QUERY_PARAM_DATE_FORMAT,
  REALM_ID,
  RECURRING_STATUS,
  REPORT_TYPE,
  RESET_PASSWORD,
  SCHEDULED_STATUS,
  SEARCH,
  SEMI_MONTHLY_DAY_ONE,
  SEMI_MONTHLY_DAY_TWO,
  SETTING,
  SIGNATURE_STATUS,
  START_DATE,
  TIME,
  TIMECARD_REPORT_VIEW,
  TIMESHEET_GRAPH_TYPE,
  TIMESHEET_REPORT_TYPE,
  TIMESHEET_VIEW,
  TIME_CARD_ISSUE,
  TIME_ENTRY_STATE,
  TIME_OFF_TYPE,
  TIME_RANGE_TYPE,
  TRADE,
  TYPE,
  UNSUBSCRIBE,
  USERNAME,
  VERIFY_EMAIL,
  VIEW,
  FOLDER_ID,
  TAG_ID,
  VIEW_SETTING,
  WAGE_TYPE,
  FLAGGED_LOCATION_STATUS,
  REVIEWED_BY_MEMBER_ID,
  PER_DIEM_TYPE,
  PER_DIEM_STATUS,
  PER_DIEM_PROCESSED_STATUS,
  DPR_FAVORITE_FROM_NAV,
  INCIDENT_REPORT_TYPE,
  INCIDENT_RECORDABLE,
  INCIDENT_TYPE,
  INCIDENT_OUTCOME,
  YEAR,
  INSPECTION_REPORT_TYPE,
  CHECKLIST_ID,
  SUBPROJECT_ID,
};
