import { useApolloClient } from '@apollo/client';
import { GET_PROJECT_TITLE_QUERY } from 'apollo/queries/project-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Capsule from 'components/foundation/Capsule/Capsule';
import { useCallback, useEffect, useMemo, useState } from 'react';
import IProject from 'types/Project';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';

export interface IProjectCapsulesProps {
  className?: ClassName;
  projectIds: string[];
  isUnassinged: boolean;
  printVersion?: boolean;
}

const ProjectCapsules = ({ className, projectIds, isUnassinged, printVersion }: IProjectCapsulesProps) => {
  const classes = classNames('project-capsules', className);
  const client = useApolloClient();
  const [projects, setProject] = useState<IProject[]>([]);
  const includesNoProject = projectIds.includes(remainingDataItemId);
  const postfix = useMemo(() => (isUnassinged ? ' - ' + t('unassigned') : ''), [isUnassinged]);

  useEffect(() => {
    if (!includesNoProject) {
      getFilterProject();
    }
  }, [projectIds]);

  const getFilterProject = useCallback(async () => {
    const results = await client.query<{ projects: IProject[] }>({
      query: GET_PROJECT_TITLE_QUERY,
      variables: { filter: { id: { contains: projectIds } } },
      fetchPolicy: 'network-only',
    });
    setProject(results.data.projects ?? []);
  }, [projectIds]);

  function renderList() {
    if (printVersion) {
      return projects.map((item) => (
        <h2 key={item.id} className="mb-3">
          {item.title + postfix}
        </h2>
      ));
    } else {
      return projects.map((item) => <Capsule key={item.id} className={classes} title={item.title + postfix} />);
    }
  }

  function renderNoProject() {
    if (printVersion) {
      return (
        <h2 key={remainingDataItemId} className="mb-3">
          {t('No Project')}
        </h2>
      );
    } else {
      return <Capsule key={remainingDataItemId} className={classes} title={t('No Project')} />;
    }
  }

  return (
    <>
      {renderList()}
      {includesNoProject && renderNoProject()}
    </>
  );
};

export default ProjectCapsules;
