import { TextField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Component, ReactNode } from 'react';
import * as React from 'react';

import LazyLoadPickerList from '../LazyLoadPickerList/LazyLoadPickerList';

export interface ILazyLoadSearchablePickerListProps<T extends { id: string; cursor?: string }> {
  searchValue: string;
  onSearch: (value: string, e: React.ChangeEvent) => void;

  // LazyLoadPickerList props
  data: T[];
  value: string | null;
  loadedAll: boolean;
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void;
  renderRow: (row: T, index: number, e?: React.KeyboardEvent) => ReactNode;
  closeMenu: (shouldFocus: boolean) => void;
  onSelect: (row: T | null, index?: number, e?: React.MouseEvent) => void;
  renderSize: number; // has default
  offset: number; // has default
  loadingTemplate?: ReactNode;
  className?: ClassName;
  error?: boolean;
  onRightKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  onLeftKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  willLoad?: () => void;
}

export class LazyLoadSearchablePickerList<T extends { id: string; cursor?: string }> extends Component<
  ILazyLoadSearchablePickerListProps<T>
> {
  public static defaultProps: any;
  public searchField?: HTMLInputElement; // ref

  public componentDidMount() {
    if (this.searchField) {
      this.searchField.focus();
    }
  }

  public setSearchRef = (el: HTMLInputElement) => (this.searchField = el);

  public renderHeader = (pickerList: LazyLoadPickerList<T>) => {
    const handleSearchChange = (value: string, e: React.ChangeEvent) => {
      this.props.onSearch(value, e);
      pickerList.setFocusIndex(0);
    };

    const handleKeyDown = (value: string, event: React.KeyboardEvent) => {
      pickerList.handleKeyDown(event);
    };

    return (
      <div className="p-3">
        <TextField
          value={this.props.searchValue}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          inputRef={this.setSearchRef}
        />
      </div>
    );
  };

  public render() {
    const { className, ...rest } = this.props;

    const classes = classNames({}, className);

    return <LazyLoadPickerList className={classes} header={this.renderHeader} {...rest} />;
  }
}

export default LazyLoadSearchablePickerList;
