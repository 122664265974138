import gql from 'graphql-tag';

export const equipmentCategoriesQuery = gql`
  query EquipmentCategory($first: Int, $after: String, $filter: EquipmentCategoryFilter) {
    equipmentCategories(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      equipmentTypeId
      review
      cursor
    }
  }
`;

export const equipmentCategoryQuery = gql`
  query EquipmentCategoryById($categoryId: Uuid!) {
    equipmentCategories(filter: { id: { equal: $categoryId } }) {
      id
      title
      equipmentTypeId
      review
      cursor
    }
  }
`;

export const equipmentCategoriesWithTypeQuery = gql`
  query EquipmentCategoryWithType($first: Int, $after: String, $filter: EquipmentCategoryFilter) {
    equipmentCategories(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      equipmentTypeId
      type {
        id
        title
      }
      review
      cursor
    }
  }
`;
