import { Divider, Label, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { mediaCollectionsForProjectQuery } from 'containers/photos/photos-queries';
import { useApolloPaging } from 'hooks';
import { isEmpty, isNil, sum } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { IMediaCollection } from 'types';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';
import { t } from 'utils/localize';
import './DailyPhotoAndNotesDashboardCardFooter.scss';
import useMediaCollectionViewPermissionCreatedByFilter from 'hooks/models/media-collection/useMediaCollectionViewPermissionCreatedByFilter/useMediaCollectionViewPermissionCreatedByFilter';

interface IDailyPhotoAndNotesData {
  photoCount: number;
  noteCount: number;
}

export interface IDailyPhotoAndNotesDashboardCardFooterProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  memberId?: string;
  projectId?: string;
}

const DailyPhotoAndNotesDashboardCardFooter = (props: IDailyPhotoAndNotesDashboardCardFooterProps) => {
  const { className, timeRange, memberId, projectId } = props;

  const { getAll } = useApolloPaging();
  const [data, setData] = useState<IDailyPhotoAndNotesData>({
    photoCount: 0,
    noteCount: 0,
  });

  const mediaCollectionViewPermissionFilter = useMediaCollectionViewPermissionCreatedByFilter();

  useEffect(() => {
    getData();
  }, [timeRange, memberId, projectId]);

  async function getData() {
    const collectionQuery = await getAll<IMediaCollection & ICursorable>('mediaCollections', {
      query: mediaCollectionsForProjectQuery,
      variables: {
        filter: {
          project: {
            idWithDescendants: !isNil(projectId) ? { equal: projectId } : undefined,
            archivedOn: { isNull: true },
          },
          createdBy: getGraphQlEqualComparison(memberId),
          createdByMember: {
            permissions: mediaCollectionViewPermissionFilter,
          },
          deletedOn: { isNull: true },
          createdOn: {
            between: {
              start: timeRange.startTime
                .minus(timeRange.startTime.toLocal().offset * 60 * 1000)
                .toISO({ suppressSeconds: true }),
              end: timeRange.endTime
                .minus(timeRange.endTime.toLocal().offset * 60 * 1000)
                .toISO({ suppressSeconds: true }),
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    });

    const photoCount = sum(
      collectionQuery.map((collection) => {
        return collection.mediaEntries.filter((entry) => isNil(entry.deletedOn)).length;
      })
    );
    const noteCount = collectionQuery.filter(
      (collection) => !isNil(collection.description) && !isEmpty(collection.description)
    ).length;
    setData({ photoCount, noteCount });
  }

  const classes = classNames('daily-photo-and-notes-dashboard-card-footer', className);

  return (
    <div className={classes}>
      <Divider className={'mb-4 footer-divider'} />
      <Label className={'title-label'}>
        {t('Photos') + ': ' + data.photoCount + ' | ' + t('Notes') + ': ' + data.noteCount}
      </Label>
    </div>
  );
};

export default DailyPhotoAndNotesDashboardCardFooter;
