import { IButtonProps, Icon } from '@busybusy/webapp-react-ui';
import { ChevronRightIcon } from 'assets/icons';
import classNames from 'classnames';
import Spinner from 'components/foundation/Spinner/Spinner';
import useIsInIframe from 'hooks/store/useIsInIframe';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import './DashboardClockStatus.scss';

export interface IDashboardClockStatusProps {
  value: number;
  label: string;
  onClick: IButtonProps['onClick'];
  className?: ClassName;
  isLoading: boolean;
  hasError: boolean;
}

function DashboardClockStatus({ value, label, onClick, className, isLoading, hasError }: IDashboardClockStatusProps) {
  const isInIframe = useIsInIframe();
  const classes = classNames('dashboard-clock-status', { 'is-in-iframe': isInIframe }, className);

  return (
    <div onClick={onClick} className={classes}>
      <div className="dashboard-clock-status-labels">
        <div className="dashboard-clock-status-value">{hasError ? t('Error') : value}</div>
        <div className="dashboard-clock-status-label">{label}</div>
      </div>
      {isLoading ? <Spinner size={'sm'} /> : <Icon svg={ChevronRightIcon} />}
    </div>
  );
}

export default DashboardClockStatus;
