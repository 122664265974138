import gql from 'graphql-tag';

export const CREATE_MEMBER_SIGN_OFF_ANSWER = gql`
  mutation CreateMemberSignOffAnswer($answer: MemberSignOffAnswerCreateInput) {
    createMemberSignOffAnswer(answer: $answer) {
      id
      answer
      audienceType
      flagged
      memberId
      submissionId
      createdOn
      localTime
      questionTitle
      questionDescription
      questionPrompt
      description
    }
  }
`;

export const UPDATE_MEMBER_SIGN_OFF_ANSWER = gql`
  mutation UpdateMemberSignOffAnswer($answer: MemberSignOffAnswerUpdateInput) {
    updateMemberSignOffAnswer(answer: $answer) {
      id
      flagged
      resolverId
    }
  }
`;
