import { useOrganization } from 'hooks';
import useBudgetsSettingsUpdate from 'hooks/models/member-settings/useBudgetsSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useCanViewExpenses from 'hooks/permission/useCanViewExpenses';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { IVisibleTableColumn } from 'types/TableColumn';
import { mergeListsWithOverride, updateItemInList } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import useProgressValueEnabled from '../CreateBudgetForm/hooks/useProgressValueEnabled';

export type BudgetReportColumnKey =
  | 'progressValue'
  | 'laborHours'
  | 'laborCost'
  | 'equipmentHours'
  | 'equipmentCost'
  | 'equipmentTotalCost';

export const defaultBudgetReportColumns: IVisibleTableColumn<BudgetReportColumnKey>[] = [
  {
    key: 'progressValue',
    title: t('Progress Value'),
    visible: true,
    position: 0,
  },
  {
    key: 'laborHours',
    title: t('Labor Hours'),
    visible: true,
    position: 1,
  },
  {
    key: 'laborCost',
    title: t('Labor Cost'),
    visible: true,
    position: 2,
  },
  {
    key: 'equipmentHours',
    title: t('Equipment Hours'),
    visible: true,
    position: 3,
  },
  {
    key: 'equipmentCost',
    title: t('Equipment Cost'),
    visible: true,
    position: 4,
  },
  {
    key: 'equipmentTotalCost',
    title: t('Total Cost'),
    visible: false,
    position: 5,
  },
];

export default function useBudgetReportColumns() {
  const canViewCost = useHasCostPermission();
  const canViewExpenses = useCanViewExpenses();
  const budgetProgressValueEnabled = useProgressValueEnabled();
  const { trackBudgets, trackBudgetCost, trackBudgetEquipmentHours, trackBudgetEquipmentCosts } = useOrganization();
  const memberSettings = useMemberSettings();
  const updateSettings = useBudgetsSettingsUpdate();

  const [budgetReportColumns, setBudgetReportColumns] = useState(
    memberSettings?.web?.features?.budgets?.columnSettings
      ? mergeListsWithOverride(defaultBudgetReportColumns, memberSettings.web.features.budgets.columnSettings, 'key', [
          'position',
          'visible',
        ])
      : defaultBudgetReportColumns
  );

  const updateBudgetReportColumns = (checked: boolean, key: BudgetReportColumnKey) => {
    setBudgetReportColumns((columns) => {
      return updateItemInList(columns, key, (prev) => ({ ...prev, visible: checked }), 'key');
    });
  };

  const saveBudgetReportColumns = () => {
    return updateSettings({ columnSettings: budgetReportColumns });
  };

  return {
    savedColumns: useMemo(() => {
      return sortBy(budgetReportColumns, 'position').filter(({ key }) => {
        if (key === 'laborHours') {
          return trackBudgets;
        }
        if (key === 'laborCost') {
          return canViewCost && trackBudgetCost && canViewExpenses;
        }
        if (key === 'progressValue') {
          return budgetProgressValueEnabled && canViewExpenses;
        }
        if (key === 'equipmentHours') {
          return trackBudgetEquipmentHours;
        }
        if (key === 'equipmentCost') {
          return canViewCost && trackBudgetEquipmentCosts && canViewExpenses;
        }
        if (key === 'equipmentTotalCost') {
          return canViewCost && trackBudgetCost && trackBudgetEquipmentCosts && canViewExpenses;
        }
        return false;
      });
    }, [
      budgetReportColumns,
      trackBudgets,
      canViewCost,
      trackBudgetCost,
      canViewExpenses,
      budgetProgressValueEnabled,
      trackBudgetEquipmentHours,
      trackBudgetEquipmentCosts,
    ]),
    updateBudgetReportColumns,
    saveColumns: saveBudgetReportColumns,
    setBudgetReportColumns,
  };
}
