import { IToastProps, Theme } from '@busybusy/webapp-react-ui';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { createContext, useCallback, useContext, useReducer } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';

type ToastTheme = IToastProps['theme'];

export const ToastContext = createContext<[IToastState, React.Dispatch<Action>] | null>(null);

export function useToastState() {
  const context = useContext(ToastContext);

  if (isNil(context)) {
    throw new Error('useToast must be nested within a ToastContext.Provider.');
  }

  return context;
}

export function useToastDispatch() {
  const [, dispatch] = useToastState();
  return dispatch;
}

export function useToastProblemOpen() {
  const openToast = useToastOpen();
  return useCallback(() => {
    openToast({ label: t('Something went wrong.'), theme: Theme.DARK });
  }, [openToast]);
}

export function useToastOpen() {
  const [{ theme }, dispatch] = useToastState();

  return useCallback(
    (payload: { label: string; theme?: ToastTheme; timeout?: number }) => {
      dispatch({
        type: 'OPEN',
        payload: { label: payload.label, theme: payload.theme ?? theme, timeout: payload.timeout },
      });
    },
    [dispatch, theme]
  );
}

type Action = { type: 'OPEN'; payload: { label: string; theme: ToastTheme; timeout?: number } } | { type: 'CLOSE' };

function toastReducer(state: IToastState, action: Action): IToastState {
  if (action.type === 'OPEN') {
    return { isOpen: true, ...action.payload };
  } else if (action.type === 'CLOSE') {
    return { ...state, isOpen: false };
  }

  return state;
}

interface IToastState {
  isOpen: boolean;
  label: string;
  theme: ToastTheme;
  timeout?: number;
}

export function ToastContextProvider(props: IChildrenProps) {
  const reducer = useReducer(toastReducer, { isOpen: false, label: '', theme: Theme.DARK });

  return <ToastContext.Provider value={reducer}>{props.children}</ToastContext.Provider>;
}
