import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  CostCode,
  Equipment,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Scalars,
} from '__generated__/graphql';
import { BASIC_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { IActivityReportEquipmentRowInfo } from 'containers/activity-reports/hooks/ActivityReportData';
import {
  COST_CODES_WITH_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import {
  SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { Dictionary, first, isEmpty, isNil, keyBy, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { combineMetricFilters, metricConditionFilter, metricNullFilter } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getCostCodeDisplay, getEquipmentDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { ICostCodeActivityTableRowInfo } from './useCostCodeActivity';

export interface ICostCodeEquipmentActivityTableRowInfo
  extends ICostCodeActivityTableRowInfo,
    IActivityReportEquipmentRowInfo {
  equipmentId: string | null;
}

interface CostCodeEquipmentMetricRow extends LaborMetricsInterface {
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
  equipmentId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useCostCodeActivityEquipmentDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const client = useApolloClient();
  const { getAll } = useApolloPaging();
  const canViewCost = useHasCostPermission();
  const [data, setData] = useState<ICostCodeEquipmentActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<ICostCodeEquipmentActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'costCode',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? COST_CODES_WITH_MEMBER_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_MEMBER_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? COST_CODES_WITH_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getNoCostCodeMetricFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        if (filterId === remainingDataItemId) {
          return combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          ]);
        }
        return combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId),
        ]);
      case ActivityReportType.BY_EMPLOYEE:
        return combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.MemberId, ConditionOperationType.Equal, filterId),
        ]);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull);
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(
    item: ICostCodeEquipmentActivityTableRowInfo,
    key: keyof ICostCodeEquipmentActivityTableRowInfo
  ) {
    if (key === 'costCode') {
      return getCostCodeDisplay(item.costCode);
    } else {
      return item[key];
    }
  }

  async function getEquipment(equipmentIds: string[]) {
    if (isEmpty(equipmentIds)) {
      return [];
    }

    return await getAll<Equipment & ICursorable>('equipment', {
      query: BASIC_EQUIPMENT_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: equipmentIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  function getEquipmentQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  async function loadData() {
    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const equipmentLookup = await getEquipmentLookup(costCodes);
    const tableRows = mapNotNil(costCodes, (costCode) => createTableRowInfo(equipmentLookup, costCode));

    const remainingData = await createNoCostCodeRow(tableRows);

    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  async function getEquipmentLookup(data: CostCode[]) {
    const metrics = data.flatMap((costCode) => laborMetrics(costCode));
    const equipmentIds = mapNotNil(metrics, (item) => item.equipmentId);
    const equipment = await getEquipment(uniq(equipmentIds));

    return keyBy(equipment, (e) => e.id);
  }

  const createTableRowInfo = (
    equipmentLookup: Dictionary<Equipment>,
    costCode: CostCode
  ): ICostCodeEquipmentActivityTableRowInfo | null => {
    const metrics = laborMetrics(costCode);

    let noEquipmentRow: ICostCodeEquipmentActivityTableRowInfo | undefined = undefined;
    if (metrics.some((metric) => isNil(metric.equipmentId))) {
      const noCostCode = first(metrics.filter((metric) => isNil(metric.equipmentId))) ?? emptyMetricRow(costCode.id);
      const metricTotal = getGeneratedMetricLaborTotals(noCostCode, filterIdWithDescendants === false);

      noEquipmentRow = {
        id: costCode.id + remainingDataItemId,
        equipmentId: remainingDataItemId,
        equipment: null,
        costCode,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
      };
    }

    const unsortedEquipmentRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.equipmentId)) {
        return null;
      }

      const equipment = equipmentLookup[metric.equipmentId];

      return {
        id: costCode.id + metric.equipmentId,
        equipmentId: metric.equipmentId,
        equipment: equipment ?? null,
        costCode,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment?.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });
    const equipmentRows = sortByIgnoreCase(unsortedEquipmentRows, (row) => getEquipmentDisplay(row.equipment));

    if (isEmpty(equipmentRows) && noEquipmentRow === undefined) {
      return null;
    } else {
      const costCodeTotalMetrics = aggregateActivityRows(
        noEquipmentRow === undefined ? equipmentRows : [...equipmentRows, noEquipmentRow]
      );

      return {
        ...costCodeTotalMetrics,
        id: costCode.id,
        equipmentId: null,
        equipment: null,
        equipmentCost: sumBy(equipmentRows, (row) => row.equipmentCost),
        equipmentRate: 0,
        equipmentTotalCost: sumBy(equipmentRows, (row) => row.equipmentCost) + costCodeTotalMetrics.totalCost,
        costCode,
        detailRows: noEquipmentRow === undefined ? equipmentRows : [...equipmentRows, noEquipmentRow],
      };
    }
  };

  const createNoCostCodeRow = async (
    tableRows: ICostCodeEquipmentActivityTableRowInfo[]
  ): Promise<ICostCodeEquipmentActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Cost Code row
    }

    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getEquipmentQuery(),
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            costCodeId: { isNull: true },
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: getNoCostCodeMetricFilter(),
      },
    });

    const unsortedEquipmentRows: ICostCodeEquipmentActivityTableRowInfo[] = equipment.flatMap((e) => {
      const metrics = equipmentLaborMetrics(e);

      return mapNotNull(metrics, (metric) => {
        const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
        if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
          return null;
        }

        return {
          id: remainingDataItemId + e.id,
          equipmentId: e.id,
          equipment: e,
          equipmentCost: metricTotal.equipmentCost,
          equipmentRate: getCostHistoryForRange(e?.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
          equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
          costCode: null,
          ...activityReportUtils.metricToRowInfo(metricTotal),
        };
      });
    });

    const equipmentRows = sortByIgnoreCase(unsortedEquipmentRows, (row) => getEquipmentDisplay(row.equipment));

    const remainingEquipmentData = calculateRemainingActivityData(
      equipmentRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingEquipmentData.totalHours > 0) {
      equipmentRows.push({
        ...remainingEquipmentData,
        equipmentId: remainingDataItemId,
        equipment: null,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
        costCode: null,
        id: remainingDataItemId + remainingDataItemId,
      });
    }

    return {
      equipmentId: null,
      equipment: null,
      costCode: null,
      equipmentCost: sumBy(equipmentRows, (row) => row.equipmentCost),
      equipmentRate: 0,
      equipmentTotalCost: sumBy(equipmentRows, (row) => row.equipmentCost) + remainingData.totalCost,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: equipmentRows,
    };
  };

  const emptyMetricRow = (costCodeId: string): CostCodeEquipmentMetricRow => {
    return {
      costCodeId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (costCode: CostCode): CostCodeEquipmentMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return costCode.costCodeProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_EMPLOYEE:
        return costCode.costCodeMemberEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return costCode.costCodeEquipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const equipmentLaborMetrics = (equipment: Equipment): CostCodeEquipmentMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return equipment.equipmentProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_EMPLOYEE:
        return equipment.equipmentMemberCostCodeLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return equipment.equipmentCostCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
