import { Reducer } from 'redux';

import {
  IStoreOrganizationSubscriptionStatus,
  IStoreOrganizationSubscriptionStatusSetAction,
  ORGANIZATION_SUBSCRIPTION_STATUS_SET,
} from './organizationSubscriptionStatusIndex';

export type IStoreOrganizationSubscriptionActions = IStoreOrganizationSubscriptionStatusSetAction;

const initialState: IStoreOrganizationSubscriptionStatus = null;

export const organizationSubscriptionStatusReducers: Reducer<
  IStoreOrganizationSubscriptionStatus,
  IStoreOrganizationSubscriptionActions
> = (
  state: IStoreOrganizationSubscriptionStatus = initialState,
  action: IStoreOrganizationSubscriptionActions
): IStoreOrganizationSubscriptionStatus => {
  switch (action.type) {
    case ORGANIZATION_SUBSCRIPTION_STATUS_SET:
      return action.payload;

    default:
      return state;
  }
};

export default organizationSubscriptionStatusReducers;
