import { useApolloClient } from '@apollo/client';
import { IDatePickerPayload } from '@busybusy/webapp-react-ui';
import { MEMBER_TIME_DOCUMENT_QUERY } from 'apollo/queries/member-time-document-query';
import { SIMPLE_TIME_ENTRIES_QUERY } from 'apollo/queries/time-entry-queries';
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import { useActiveMember, useBreak, useMemberLock, useOpenable, useOrganization, useTimeEntry } from 'hooks';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import { ICreateBreakData } from 'hooks/models/time-entry-break/useBreak';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import ClockActionType from 'types/enum/ClockActionType';
import IMemberTimeDocument from 'types/MemberTimeDocument';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { combineDateAndTime } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { isOutsideRange } from 'utils/timeEntryBreakUtils';

export interface ITimeActionsFormData {
  members: string[];
  timeRange: ITimeRange<DateTime | null | undefined>;
  startTime?: DateTime | null;
  startDst?: boolean;
  endTime?: DateTime | null;
  endDst?: boolean | null;
  startDate: IDatePickerPayload;
  endDate: IDatePickerPayload;
  breaks: IBreakMultiPickerItem[];
  project: string | undefined | null;
  costCode: string | undefined | null;
  equipment: string | undefined | null;
  description: string | undefined | null;
  total: number;
  timezone?: string;
}

export default function useTimeActionsForm() {
  const { createEntry, clockIn, switchAction, editEntry, editOpenEntry } = useTimeEntry();
  const { createBreak, updateBreak, deleteBreak } = useBreak();
  const client = useApolloClient();
  const today = DateTime.local().startOf('day');
  const organization = useOrganization();
  const timeEntryRange = useRef<ITimeRange<DateTime> | undefined>();
  const onSignOffComplete = useRef<() => void | undefined>();
  const dailySignOffDetails = useOpenable();
  const activeMember = useActiveMember();
  const loaderDetails = useOpenable();
  const { inhibitsActionsForMembers } = useMemberLock();
  const isPro = useFeatureFlags('PRO');
  const isSafetySignOffEnabled = useFeatureFlags('SAFETY_SIGN_OFF');
  const isCustomSignOffEnabled = useCustomSignOffEnabled();

  async function performClockIn(data: ITimeActionsFormData, onSubmit: (data: ITimeActionsFormData) => void) {
    loaderDetails.open();
    await clockIn(
      DateTime.local(),
      data.members,
      ClockActionType.CLOCK_IN,
      data.description === '' ? null : data.description,
      data.project,
      data.costCode,
      data.equipment
    );
    loaderDetails.close();
    onSubmit(data);
  }

  async function performClockInAt(data: ITimeActionsFormData, onSubmit: (data: ITimeActionsFormData) => void) {
    const start = data.startDate.value === null ? today : DateTime.fromJSDate(data.startDate.value!);
    loaderDetails.open();
    await clockIn(
      data.startTime!.setZone(today.zone).set({
        year: start.year,
        month: start.month,
        day: start.day,
      }),
      data.members,
      ClockActionType.CLOCK_IN_AT,
      data.description === '' ? null : data.description,
      data.project,
      data.costCode,
      data.equipment
    );
    loaderDetails.close();
    onSubmit(data);
  }

  async function performUpdateOpenEntry(
    data: ITimeActionsFormData,
    timeEntry: ITimeEntry,
    onSubmit: (data: ITimeActionsFormData) => void
  ) {
    const save = async () => {
      const start = data.startDate.value === null ? today : DateTime.fromJSDate(data.startDate.value!);

      const updatedEntryAndLog = await editOpenEntry(
        timeEntry,
        data.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        data.description === '' ? null : data.description,
        data.project,
        data.costCode,
        data.equipment
      );

      const newBreaks = data.breaks.filter(
        (item) =>
          !timeEntry.breaks
            .map((brk) => {
              return brk.id;
            })
            .includes(item.id)
      );

      const existingBreaks = timeEntry.breaks.filter((brk) => {
        return brk.deletedOn === null && !isNil(brk.endTime);
      });

      for (const brk of existingBreaks) {
        const updatedBreak = _.find(data.breaks, (obj) => {
          return obj.id === brk.id && !isNil(brk.endTime);
        });
        if (updatedBreak) {
          if (
            updatedBreak.timeRange.startTime.toISO({ suppressMilliseconds: true, suppressSeconds: true }) !==
              brk.startTime ||
            updatedBreak.timeRange.endTime.toISO({ suppressMilliseconds: true, suppressSeconds: true }) !== brk.endTime
          ) {
            await updateBreak(
              updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
              brk.timeEntry.id,
              brk,
              updatedBreak.timeRange.startTime,
              updatedBreak.timeRange.endTime!
            );
          }
        } else {
          await deleteBreak(
            updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
            brk.timeEntry.id,
            brk
          );
        }
      }

      if (_.isEmpty(existingBreaks)) {
        for (const brk of data.breaks.filter((brk) => !brk.isOpen)) {
          await createBreak([
            {
              timeEntryId: updatedEntryAndLog.entry === undefined ? timeEntry.id : updatedEntryAndLog.entry.id,
              entryLogId:
                updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
              startTime: brk.timeRange.startTime,
              endTime: brk.timeRange.endTime!,
            },
          ]);
        }
      } else {
        for (const brk of newBreaks) {
          await createBreak([
            {
              timeEntryId: updatedEntryAndLog.entry === undefined ? timeEntry.id : updatedEntryAndLog.entry.id,
              entryLogId:
                updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
              startTime: brk.timeRange.startTime,
              endTime: brk.timeRange.endTime!,
            },
          ]);
        }
      }

      if (_.isEmpty(data.members)) {
        data.members = [timeEntry.member && timeEntry.member.id ? timeEntry.member.id : timeEntry.memberId];
      }

      onSubmit(data);
    };

    await save();
  }

  async function performSwitch(
    data: ITimeActionsFormData,
    timeEntries: ITimeEntry[],
    onSubmit: (data: ITimeActionsFormData) => void
  ) {
    try {
      loaderDetails.open();
      await switchAction(
        timeEntries,
        DateTime.local(),
        'switch',
        data.description === '' ? null : data.description,
        data.project === undefined ? undefined : data.project,
        data.costCode === undefined ? undefined : data.costCode,
        data.equipment === undefined ? undefined : data.equipment
      );
      loaderDetails.close();
      onSubmit(data);
    } catch (error) {
      throw Error();
    }
  }

  async function performSwitchAt(
    data: ITimeActionsFormData,
    timeEntries: ITimeEntry[],
    onSubmit: (data: ITimeActionsFormData) => void
  ) {
    const start = data.endDate.value === null ? today : DateTime.fromJSDate(data.endDate.value!);

    try {
      loaderDetails.open();
      await switchAction(
        timeEntries,
        data.endTime!.setZone(today.zone).set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        'switch-at',
        data.description === '' ? null : data.description,
        data.project === undefined ? undefined : data.project,
        data.costCode === undefined ? undefined : data.costCode,
        data.equipment === undefined ? undefined : data.equipment
      );
      loaderDetails.close();
      onSubmit(data);
    } catch (error) {
      throw Error();
    }
  }

  async function performCreateEntry(
    data: ITimeActionsFormData,
    onSubmit: (data: ITimeActionsFormData) => void,
    forceStartDst?: boolean,
    forceEndDst?: boolean | null,
    forceSave?: boolean
  ) {
    const save = async () => {
      const start = data.startDate.value === null ? today : DateTime.fromJSDate(data.startDate.value!);
      const entryStartTime = combineDateAndTime(start, data.timeRange.startTime!);
      const end = data.endDate.value === null ? today : DateTime.fromJSDate(data.endDate.value!);
      const entryEndTime = combineDateAndTime(end, data.timeRange.endTime!);
      const createdEntriesAndLogs = await createEntry(
        entryStartTime,
        entryEndTime,
        data.members,
        data.timezone!,
        data.description === '' ? null : data.description,
        data.project,
        data.costCode,
        data.equipment,
        forceStartDst,
        forceEndDst
      );

      const promises = data.breaks.map((brk) => {
        let breakStart = brk.timeRange.startTime;
        let breakEnd = brk.timeRange.endTime;
        if (
          isOutsideRange(
            {
              startTime: entryStartTime,
              endTime:
                entryStartTime.toSeconds() > entryEndTime.toSeconds() ? entryEndTime.plus({ day: 1 }) : entryEndTime,
            },
            [{ startTime: breakStart, endTime: breakEnd }]
          )
        ) {
          breakStart = entryStartTime;
          breakEnd = combineDateAndTime(start, data.timeRange.endTime!);
          if (breakEnd < breakStart) {
            breakEnd =
              entryStartTime.toSeconds() > entryEndTime.toSeconds() ? entryEndTime.plus({ day: 1 }) : entryEndTime;
          }
        }
        const entryLogIds: ICreateBreakData[] = createdEntriesAndLogs.map((entryLog) => {
          return {
            timeEntryId: entryLog.entry.id,
            entryLogId: entryLog.log.id,
            startTime: breakStart,
            endTime: breakEnd,
          };
        });
        return createBreak(entryLogIds);
      });
      await Promise.all(promises);
      onSubmit(data);
    };

    await launchDailySignOffOrSave(data, save, forceSave);
  }

  async function performUpdateClosedEntry(
    data: ITimeActionsFormData,
    timeEntry: ITimeEntry,
    onSubmit: (data: ITimeActionsFormData) => void
  ) {
    const save = async () => {
      const start = data.startDate.value === null ? today : DateTime.fromJSDate(data.startDate.value!);
      const end = data.endDate.value === null ? today : DateTime.fromJSDate(data.endDate.value!);

      const updatedEntryAndLog = await editEntry(
        timeEntry,
        data.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        !isNil(timeEntry.endTime)
          ? data.timeRange.endTime!.set({
              year: end.year,
              month: end.month,
              day: end.day,
            })
          : null,
        data.description === '' ? null : data.description,
        data.project,
        data.costCode,
        data.equipment,
        data.timezone
      );

      const newBreaks = data.breaks.filter(
        (item) =>
          !timeEntry.breaks
            .map((brk) => {
              return brk.id;
            })
            .includes(item.id)
      );

      const existingBreaks = timeEntry.breaks.filter((brk) => {
        return brk.deletedOn === null && !isNil(brk.endTime);
      });

      for (const brk of existingBreaks) {
        const updatedBreak = _.find(data.breaks, (obj) => {
          return obj.id === brk.id && !isNil(brk.endTime);
        });
        if (updatedBreak) {
          if (
            updatedBreak.timeRange.startTime.toISO({ suppressMilliseconds: true, suppressSeconds: true }) !==
              brk.startTime ||
            updatedBreak.timeRange.endTime.toISO({ suppressMilliseconds: true, suppressSeconds: true }) !== brk.endTime
          ) {
            await updateBreak(
              updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
              brk.timeEntry.id,
              brk,
              updatedBreak.timeRange.startTime,
              updatedBreak.timeRange.endTime!
            );
          }
        } else {
          await deleteBreak(
            updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
            brk.timeEntry.id,
            brk
          );
        }
      }

      if (_.isEmpty(existingBreaks)) {
        for (const brk of data.breaks.filter((brk) => !brk.isOpen)) {
          await createBreak([
            {
              timeEntryId: updatedEntryAndLog.entry === undefined ? timeEntry.id : updatedEntryAndLog.entry.id,
              entryLogId:
                updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
              startTime: brk.timeRange.startTime,
              endTime: brk.timeRange.endTime!,
            },
          ]);
        }
      } else {
        for (const brk of newBreaks) {
          await createBreak([
            {
              timeEntryId: updatedEntryAndLog.entry === undefined ? timeEntry.id : updatedEntryAndLog.entry.id,
              entryLogId:
                updatedEntryAndLog.log === undefined ? _.first(timeEntry.clientLogs)!.id : updatedEntryAndLog.log.id,
              startTime: brk.timeRange.startTime,
              endTime: brk.timeRange.endTime!,
            },
          ]);
        }
      }

      if (_.isEmpty(data.members)) {
        data.members = [timeEntry.member && timeEntry.member.id ? timeEntry.member.id : timeEntry.memberId];
      }

      onSubmit(data);
    };

    await save();
  }

  async function inhibitsActionByLockDate(memberIds: string[], date: DateTime) {
    return await inhibitsActionsForMembers(memberIds, date);
  }

  async function hasConflicts(
    memberIds: string[],
    range: ITimeRange<DateTime>,
    ignoreTimeEntryIds: string[] | null = null
  ) {
    const results = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: SIMPLE_TIME_ENTRIES_QUERY,
      variables: {
        first: 500,
        filter: {
          startTime: {
            lessThanOrEqual: range.endTime.minus({ minute: 1 }).toISO({
              suppressMilliseconds: true,
              includeOffset: false,
              suppressSeconds: true,
            }),
          },
          endTime: {
            greaterThanOrEqual: range.startTime.plus({ minute: 1 }).toISO({
              suppressMilliseconds: true,
              includeOffset: false,
              suppressSeconds: true,
            }),
          },
          memberId: { contains: memberIds },
          deletedOn: { isNull: true },
          id: ignoreTimeEntryIds ? { doesNotContain: ignoreTimeEntryIds } : undefined,
        },
      },
      fetchPolicy: 'network-only',
    });
    return !_.isEmpty(results.data.timeEntries);
  }

  async function hasSignatureConflicts(memberIds: string[], range: ITimeRange<DateTime>) {
    const results = await client.query<{
      memberTimeDocuments: IMemberTimeDocument[];
    }>({
      query: MEMBER_TIME_DOCUMENT_QUERY,
      variables: {
        first: 500,
        filter: {
          startTime: { lessThanOrEqual: range.endTime.toISO() },
          endTime: { greaterThanOrEqual: range.startTime.toISO() },
          memberId: { contains: memberIds },
          deletedOn: { isNull: true },
        },
        sort: [{ submittedOn: 'desc' }],
      },
      fetchPolicy: 'network-only',
    });
    return !_.isEmpty(
      results.data.memberTimeDocuments.filter(
        (mtd) => (!isNil(mtd.selfSignature) || !isNil(mtd.authoritativeSignature)) && mtd.edited == null
      )
    );
  }

  async function launchDailySignOffOrSave(data: ITimeActionsFormData, save: () => Promise<void>, forceSave?: boolean) {
    // if logged in member is selected then launch daily sign off, except for on split entry (use forceSave instead)
    if (
      !forceSave &&
      isPro &&
      isSafetySignOffEnabled &&
      (organization.timeAccuracy ||
        organization.safetySignature ||
        organization.breakPolicy ||
        isCustomSignOffEnabled) &&
      data.members.length === 1 &&
      data.members.some((memberId) => memberId === activeMember.id)
    ) {
      const start = data.startDate.value === null ? today : DateTime.fromJSDate(data.startDate.value!);

      timeEntryRange.current = {
        startTime: data.timeRange.startTime!.setZone(today.zone).set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        endTime: data.timeRange.endTime!.setZone(today.zone).set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
      };

      onSignOffComplete.current = () => {
        loaderDetails.open();
        save().then(() => {
          loaderDetails.close();
          dailySignOffDetails.close();
        });
      };
      dailySignOffDetails.open();
    } else {
      loaderDetails.open();
      save().then(() => {
        loaderDetails.close();
      });
    }
  }

  return {
    performClockIn,
    performClockInAt,
    performUpdateOpenEntry,
    performSwitch,
    performSwitchAt,
    performCreateEntry,
    performUpdateClosedEntry,
    inhibitsActionByLockDate,
    hasConflicts,
    hasSignatureConflicts,
    launchDailySignOffOrSave,
    timeEntryRange,
    onSignOffComplete,
    dailySignOffDetails,
    loaderDetails,
  };
}
