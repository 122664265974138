import { Button, Checkbox, Label, Row, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import useSlackProgressTrackingAccess from 'containers/settings/integrations/vendors/slack/hooks/useSlackProgressTrackingAccess';
import { useToastDispatch } from 'contexts/ToastContext';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useOrganizationUiSettingsUpdate from 'hooks/models/organization/settings/hooks/useOrganizationUiSettingsUpdate';
import { FunctionComponent, useState } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import './ProgressTrackingEnableForm.scss';

export interface IProgressTrackingEnableFormProps {
  onRequestSubmitted: () => void;
  className?: ClassName;
}

export const ProgressTrackingEnableForm: FunctionComponent<IProgressTrackingEnableFormProps> = (props) => {
  const { className, onRequestSubmitted } = props;
  const toastDispatch = useToastDispatch();
  const [agreeToCharge, setAgreeToCharge] = useState(false);
  const [showErrorTip, setShowErrorTip] = useState(false);
  const progressTrackingAccess = useSlackProgressTrackingAccess();
  const updateUiSettings = useOrganizationUiSettingsUpdate();
  const brand = useBrandTitle();

  const classes = classNames('progress-tracking-enable-form', className);

  const onLinkClicked = () => {
    window.open('https://fast.wistia.net/embed/channel/ycm5d84606?wchannelid=ycm5d84606&wmediaid=nd2eslmd4y');
  };

  const requestAccess = async () => {
    let requestSuccessful = false;

    try {
      const response = await progressTrackingAccess();
      requestSuccessful = response.status === 200;
    } catch (e) {
      requestSuccessful = false;
    }

    if (requestSuccessful) {
      updateUiSettings('requestedProgressTrackingAccess', true);
      onRequestSubmitted();
    } else {
      toastDispatch({
        type: 'OPEN',
        payload: { label: t('Failed to send request. Try again later.'), theme: Theme.DANGER },
      });
      setShowErrorTip(true);
    }
  };

  const customerSupport = () => {
    (window as any).Intercom('show');
  };

  return (
    <div className={classes}>
      <span className="content">
        {t(
          `Our flexible Progress Tracking, with Performance Scores, is incorporated into your project's budget so you can quickly see overall jobsite progress and the individual cost code progress in relation to your project's labor budget. Want to learn more?`
        )}
        <a className="link ml-1" onClick={() => onLinkClicked()}>
          {t('Click here.')}
        </a>
      </span>

      <Label className="mt-7 mb-2">{t('Getting Started')}</Label>
      {t(
        'Simply agree to the additional charge, then press on the Request Access button below. The activation process usually takes less than 24 hours. We will send you a message to notify you when the feature has been enabled.'
      )}

      <Label className="mt-7 mb-2">{t('Pricing')}</Label>
      {t('$3 per active {{brand}} user per month', { brand })}

      <Checkbox
        className="mt-6"
        type="basic"
        label={t(
          `I understand that by enabling the progress tracking feature, an additional charge of $3 per employee will be charged to my card each month.`
        )}
        checked={agreeToCharge}
        onChange={setAgreeToCharge}
      />

      <Row className="mt-9">
        <Button type="primary" onClick={requestAccess} disabled={!agreeToCharge}>
          {t('Request Access')}
        </Button>

        <Button className="ml-3" type="secondary" onClick={customerSupport}>
          {t('Customer Support')}
        </Button>
      </Row>

      <ConditionalContainer condition={showErrorTip}>
        <br />
        {t('* If you have an ad blocker, disable it and try again.')}
      </ConditionalContainer>
    </div>
  );
};

export default ProgressTrackingEnableForm;
