import { useApolloClient } from '@apollo/client';
import { Align, Justify, List, ListItem, Row, Size, Theme } from '@busybusy/webapp-react-ui';
import { PROJECT_COST_CODE_QUANTITY_QUERY } from 'apollo/queries/project-cost-code-quantity-queries';
import { EditIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, IconButton } from 'components';
import ProjectCostCodeQuantityForm from 'components/domain/project-cost-code-quantities/ProjectCostCodeQuantityForm/ProjectCostCodeQuantityForm';
import ProjectCostCodeQuantityView from 'components/domain/project-cost-code-quantities/ProjectCostCodeQuantityView/ProjectCostCodeQuantityView';
import { useOpenable, usePermissions } from 'hooks';
import useProgressGraylog from 'hooks/analytics/useProgressGraylog';
import useProjectCostCodeQuantity from 'hooks/models/project-cost-code-quantity/useProjectCostCodeQuantity';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ICursorable from 'types/Cursorable';
import IProjectCostCodeQuantity from 'types/ProjectCostCodeQuantity';
import ITimeRange from 'types/TimeRange';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { t } from 'utils/localize';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import './ProgressEntriesDashboardCardList.scss';

export interface IProgressEntriesDashboardCardListProps {
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
  timeRange: ITimeRange<DateTime>;
  memberId?: string | null;
  projectId?: string | null;
  costCodeId?: string | null;
  didUpdate: () => void;
}

const ProgressEntriesDashboardCardList = (props: IProgressEntriesDashboardCardListProps) => {
  const { className, theme, timeRange, memberId, projectId, costCodeId, didUpdate } = props;

  const client = useApolloClient();
  const [rows, setRows] = useState<IProjectCostCodeQuantity[]>([]);
  const viewProgressOpenable = useOpenable();
  const [selectedQuantity, setSelectedQuantity] = useState<IProjectCostCodeQuantity | null>(null);
  const { hasPermissionToManage } = usePermissions();
  const addProgressOpenable = useOpenable();
  const { deleteQuantity } = useProjectCostCodeQuantity();
  const userEvents = useProgressGraylog();

  useEffect(() => {
    getData();
  }, [timeRange, memberId, projectId, costCodeId]);

  async function getData() {
    const results = await client.query<{ projectCostCodeQuantities: Array<IProjectCostCodeQuantity & ICursorable> }>({
      query: PROJECT_COST_CODE_QUANTITY_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 3,
        filter: {
          deletedOn: { isNull: true },
          assignedOn: {
            lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            greaterThanOrEqual: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          submittedByMember: {
            permissions: {
              permissions: MemberPermission.CAN_MANAGE_PROJECT_COST_CODE_QUANTITY,
              operationType: OperationType.AND,
            },
            id: !isNil(memberId) ? { equal: memberId } : undefined,
          },
          project: {
            idWithDescendants: !isNil(projectId) ? { equal: projectId } : undefined,
            archivedOn: { isNull: true },
          },
          costCode: {
            id: !isNil(costCodeId) ? { equal: costCodeId } : undefined,
            archivedOn: { isNull: true },
          },
        },
        sort: [{ assignedOn: 'asc' }, { createdOn: 'asc' }],
      },
    });
    setRows(results.data.projectCostCodeQuantities ?? []);
  }

  function handleClick(id: string) {
    const item = rows.find((row) => row.id === id);
    setSelectedQuantity(item ?? null);
    if (isNil(projectId) && isNil(costCodeId)) {
      userEvents.entryDetailsViewed('dashboard');
    } else if (!isNil(projectId)) {
      userEvents.entryDetailsViewed('project_dashboard');
    } else if (!isNil(costCodeId)) {
      userEvents.entryDetailsViewed('cost_code_dashboard');
    }
    viewProgressOpenable.open();
  }

  function renderViewDialogButtons() {
    if (
      selectedQuantity &&
      hasPermissionToManage(selectedQuantity.submittedByMember, 'canManageProjectCostCodeQuantity')
    ) {
      return [<IconButton key="edit" size={Size.MEDIUM} onClick={addProgressOpenable.open} svg={EditIcon} />];
    }
    return [];
  }

  async function handleProgressEntryDelete() {
    if (selectedQuantity) {
      await deleteQuantity(selectedQuantity.id);
      setSelectedQuantity(null);
      getData();
      didUpdate();
    }
  }

  function handleSubmit() {
    addProgressOpenable.close();
    getData();
  }

  function renderEmptyState() {
    const emptyState = [];
    for (let i = 0; i < 3; i++) {
      emptyState.push(
        <ListItem className={classNames('dashboard-list-item', theme, className)} key={i + 'empty-progress-entry'}>
          <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
            <div className={'my-4'}>
              <br />
              <div className="dashboard-list-item-label ellipsis">{'---'}</div>
              <br />
            </div>
            <div className={'my-4'}>
              <div className="dashboard-list-item-value ml-4">{'---'}</div>
              <br />
            </div>
          </Row>
        </ListItem>
      );
    }
    return emptyState;
  }

  const classes = classNames('progress-entries-dashboard-card-list', className);

  return (
    <>
      <List stroke={true} className={classes} hover={true}>
        {isEmpty(rows) && renderEmptyState()}
        {rows.map((row) => (
          <ListItem
            className={classNames('dashboard-list-item', { clickable: handleClick }, theme, className)}
            onClick={() => handleClick(row.id)}
            key={row.id}
          >
            <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
              <div className={'my-4'}>
                <div className="dashboard-list-item-label ellipsis">{row.costCode.costCode}</div>
                <div className="dashboard-list-item-label ellipsis">{row.costCode.title ?? '---'}</div>
                <div className="dashboard-list-item-label ellipsis">
                  {getFormattedPathFromProject(row.project, true)}
                </div>
              </div>
              <div className={'my-4'}>
                <div className="dashboard-list-item-value ml-4">{row.amount.toString()}</div>
                <div className="dashboard-list-item-value ml-4">
                  {row.costCode.unitTitle ? row.costCode.unitTitle : t('units')}
                </div>
              </div>
            </Row>
          </ListItem>
        ))}
      </List>
      {selectedQuantity && (
        <HeaderDialog
          isOpen={viewProgressOpenable.isOpen}
          title={t('Progress Submission')}
          onClose={() => {
            setSelectedQuantity(null);
            viewProgressOpenable.close();
          }}
          divider={false}
          buttons={renderViewDialogButtons()}
        >
          <ProjectCostCodeQuantityView quantity={selectedQuantity} />
        </HeaderDialog>
      )}
      <HeaderDialog
        isOpen={addProgressOpenable.isOpen}
        title={selectedQuantity ? t('Edit Progress') : t('Add Progress')}
        onClose={() => {
          setSelectedQuantity(null);
          addProgressOpenable.close();
        }}
        divider={false}
      >
        <div className="p-8 mb-6">
          <ProjectCostCodeQuantityForm
            onSubmit={(item) => {
              if (selectedQuantity) {
                setSelectedQuantity(item);
              }

              handleSubmit();
              addProgressOpenable.close();
            }}
            projectId={projectId}
            costCodeId={costCodeId}
            quantity={selectedQuantity}
            onDelete={() => {
              handleProgressEntryDelete();
              setSelectedQuantity(null);
              addProgressOpenable.close();
              viewProgressOpenable.close();
            }}
          />
        </div>
      </HeaderDialog>
    </>
  );
};

export default ProgressEntriesDashboardCardList;
