import { waitFor } from '@testing-library/react';
import { VirtualConsole } from 'jsdom';
import { castArray, isDate, some } from 'lodash';
import { DateTime, Duration, Settings } from 'luxon';
import { ITypeName } from 'types/graphql/TypeName';
import { toErrorWithMessage } from './errorUtils';
import { typedObjectKeys } from './objectUtils';
import { isType } from './typeguard';
import { AnyObject } from 'types/util/Object';

export const select = (selector: string, container: Element = document.body) => container.querySelector(selector);

/**
 * Wait for the specified selector to be in the document and then to be removed from the document
 *
 * @param selector {string} - css selector to watch
 * @param container {Element} [container=document.body] - css selector to watch
 */
export const waitForClassCycleOut = async (selector: string, container: Element = document.body): Promise<void> => {
  await waitFor(() => expect(select(selector, container)).toBeInTheDocument());
  await waitFor(() => expect(select(selector, container)).not.toBeInTheDocument());
};

/**
 * Wait for the specified selector to NOT be in the document and then to be added to the document
 *
 * @param selector {string} - css selector to watch
 * @param container {Element} [container=document.body] - css selector to watch
 */
export const waitForClassCycleIn = async (selector: string, container: Element = document.body): Promise<void> => {
  await waitFor(() => expect(select(selector, container)).not.toBeInTheDocument());
  await waitFor(() => expect(select(selector, container)).toBeInTheDocument());
};

/**
 * Wait for the specified selector to be in the document, returning the matched Element
 * @param selector {string} - css selector to watch
 * @param container {Element} [container=document.body] - css selector to watch
 */
export const findByClass = async (selector: string, container: Element = document.body): Promise<Element> => {
  let element: Element | null;

  await waitFor(() => {
    element = select(selector, container);
    return expect(element).toBeInTheDocument();
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return element!;
};

declare global {
  interface Window {
    _virtualConsole: VirtualConsole;
  }
}

interface IJSDomError {
  type: string;
  message: string;
}

// https://github.com/jsdom/jsdom/issues/2112#issuecomment-673540137
// There should be a single listener which simply prints to the
// console. We will wrap that listener in our own listener.
export const suppressJsDomErrors = (blacklist: IJSDomError[]) => {
  const listeners = window._virtualConsole.listeners('jsdomError');
  const originalListener = listeners && listeners[0];

  if (originalListener && typeof originalListener === 'function') {
    window._virtualConsole.removeAllListeners('jsdomError');

    window._virtualConsole.addListener('jsdomError', (error: IJSDomError) => {
      if (some(blacklist, { type: error.type, message: error.message })) {
        return; // swallow error
      }

      originalListener(error);
    });
  }
};

const findFirstOccurrence = (string: string, searchElements: string[], fromIndex = 0) => {
  let min = string.length;
  for (let i = 0; i < searchElements.length; i += 1) {
    const occ = string.indexOf(searchElements[i], fromIndex);
    if (occ !== -1 && occ < min) {
      min = occ;
    }
  }
  return min === string.length ? -1 : min;
};

const getStackItem = (stack: string, step: number = 0) => {
  const targettedStack = step ? stack.split('\n').slice(step).join('\n') : stack;
  const firstCharacter = targettedStack.indexOf('at ') + 3;
  const lastCharacter = findFirstOccurrence(targettedStack, [' ', '\n'], firstCharacter);
  return targettedStack.slice(firstCharacter, lastCharacter);
};

const ignoreList = ['getStackItem', 'logError', 'getStackList'];

const getStackList = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const stack: string = new Error().stack!;
  const stackList: string[] = [];

  let i = 0;
  let item = getStackItem(stack, i);

  do {
    if (!ignoreList.includes(item)) {
      stackList.push(item);
    }
    item = getStackItem(stack, i++);
  } while (item);

  return stackList;
};

export const logError = async (maybeError: unknown) => {
  const error = toErrorWithMessage(maybeError);

  try {
    if (process.env.NODE_ENV !== 'production') {
      const stackList = getStackList();
      // eslint-disable-next-line no-console
      console.error(`Error Stack: `, JSON.stringify(stackList, null, 2));
    }
  } catch (e) {
    /* do nothing */
  }

  return Promise.reject(error);
};

export function getAllDatePickerElements() {
  // `this` is fumbled when not using lambda
  return getDatePickerElements((value: string) => document.querySelectorAll(value));
}

export function getDatePickerElement() {
  // `this` is fumbled when not using lambda
  return getDatePickerElements((value: string) => document.querySelector(value));
}

function getDatePickerElements(
  selector: ((value: string) => Element | null) | ((value: string) => NodeListOf<any>)
): HTMLInputElement[] | null {
  const selected = selector('.date-picker-shell');

  if (selected === null) {
    return null;
  }

  const elements = isType<Element>(selected, 'querySelector') ? castArray(selected) : Array.from(selected);
  return elements.map((element) => element.querySelector('input') as HTMLInputElement);
}

export function mockTime(isoString = '2021-08-06T13:00:00.000-04:00') {
  Settings.defaultZone = 'UTC-4';
  Settings.now = () => new Date(isoString).valueOf();
}

/**
 * expectValuesOfToBeEqual
 *
 * Nested objects are poorly supported
 */
export function expectValuesOfToBeEqual<T extends AnyObject>(
  collection: T | T[],
  expectedCollection: T | T[]
): void[][] {
  const array = castArray(collection);
  const expectedArray = castArray(expectedCollection);

  if (array.length !== expectedArray.length) {
    throw Error(`Comparisons must be equal size`);
  }

  const keys = typedObjectKeys(array[0]);

  return array.map((item: T, i) => {
    return keys.map((key) => {
      const stringedKey = typeof key === 'symbol' ? String(key) : key;
      if (!(key in item)) {
        throw Error(`Records mismatch: "${stringedKey}"@${i} not in collection object`);
      }

      if (!(i in expectedArray) || !(key in expectedArray[i])) {
        throw Error(`Comparison mismatch: "${stringedKey}"@${i} not in expectedCollection object`);
      }

      const value = getComparableValue(item[stringedKey]);
      const expected = getComparableValue(expectedArray[i][key]);

      return expect(value).toStrictEqual(expected);
    });
  });
}

/**
 * getComparableValue
 *
 * Supported types:
 *  - DateTime
 *  - Duration
 *  - Date
 *  - number
 *  - string
 *  - object
 *    - converted to JSON and are not reliable
 */
export function getComparableValue(item: unknown) {
  if (Duration.isDuration(item) || DateTime.isDateTime(item) || isDate(item)) {
    return item.valueOf();
  } else if (typeof item === 'object') {
    return JSON.stringify(item);
  } else {
    return item;
  }
}

/** does not overwrite existing __typenames */
export function addTypeNameToCollection<T>(collection: T[], __typename: string): Array<ITypeName<T>> {
  return collection.map((item: T) => addTypeNameToObject(item, __typename));
}

/** does not overwrite an existing __typename */
export function addTypeNameToObject<T>(item: T, __typename: string): ITypeName<T> {
  return { __typename, ...item };
}

export const mockRequest = <T,>(response: T, ms: number): Promise<T> =>
  new Promise((resolve) => setTimeout(() => resolve(response), ms));

export const asJestMock = <ReturnType, Args extends unknown[] = any>(mockedFn: (...args: Args) => ReturnType) => {
  if (!jest.isMockFunction(mockedFn)) {
    throw new Error(`Expected a jest mock function`);
  }

  return mockedFn as jest.Mock<ReturnType, Args>;
};
