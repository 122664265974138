import { Justify, Row, Table } from '@busybusy/webapp-react-ui';
import { EmptyState, ErrorState, Spinner } from 'components';
import { ReactNode } from 'react';
import { t } from 'utils/localize';

export interface ITemplatedRenderProps {
  isLoading?: boolean;
  loadingTemplate?: ReactNode;
  isError?: boolean;
  error?: string;
  isEmpty?: boolean;
  emptyTemplate?: ReactNode;
  errorTemplate?: ReactNode;
  children: ReactNode;
}

function TemplatedRender({
  isLoading,
  loadingTemplate,
  emptyTemplate,
  isEmpty,
  isError,
  error,
  errorTemplate,
  children,
}: ITemplatedRenderProps) {
  const dataIsLoading = isLoading ? true : false;

  function renderLoadingTemplate() {
    return loadingTemplate ? (
      loadingTemplate
    ) : (
      <Row justify={Justify.CENTER} className="py-5 no-print">
        <Spinner />
      </Row>
    );
  }
  function renderData(loading: boolean) {
    return (
      <>
        {children}
        {loading && renderLoadingTemplate()}
      </>
    );
  }

  if (isError) {
    return <>{errorTemplate ? errorTemplate : <ErrorState subtitle={error} />}</>;
  }

  if (isEmpty && !isLoading && !isError) {
    return (
      <>
        {emptyTemplate ? emptyTemplate : <EmptyState title={t('No items')} subtitle={t('There were no items found')} />}
      </>
    );
  }

  return <>{renderData(dataIsLoading)}</>;
}

TemplatedRender.defaultProps = {
  ...Table.defaultProps,
};

export default TemplatedRender;
