import { Divider } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import './ErrorLine.scss';

export interface IErrorLineProps {
  className?: ClassName;
}

function ErrorLine({ className }: IErrorLineProps) {
  return <Divider className={classNames('error-line', className)} />;
}

export default ErrorLine;
