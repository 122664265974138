import classNames from 'classnames';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { ReactNode } from 'react';
import { IPositionPickerProps } from '../PositionPicker/PositionPicker';
import usePositionPickerOptions from '../hooks/usePositionPickerOptions';

export default function AutoHidePositionPicker({
  permissions,
  className,
  header,
  ...pickerProps
}: IPositionPickerProps & { header?: ReactNode }) {
  const { data, error } = usePositionPickerOptions(permissions);

  return (
    <>
      {data.length > 1 && (
        <>
          {header ? header : null}
          <BasicPicker
            {...pickerProps}
            className={classNames('position-picker', className)}
            data={data}
            error={error}
          />
        </>
      )}
    </>
  );
}
