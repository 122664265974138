import { Dialog, DialogHeader, IDialogProps } from '@busybusy/webapp-react-ui';
import { ArrowBackIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IconButton } from 'components';
import Preferences from 'components/layout/navigation-drawer/NavHeader/Preferences/Preferences';
import { t } from 'utils/localize';

export type AccountPreferencesDialogProps = Omit<IDialogProps, 'hasDismiss' | 'children'>;

function AccountPreferencesDialog({ className, onClose, ...dialogProps }: AccountPreferencesDialogProps) {
  const classes = classNames('account-preferences-dialog', className);

  return (
    <Dialog {...dialogProps} onClose={onClose} hasDismiss={false} className={classes}>
      <DialogHeader divider={true}>
        <IconButton svg={ArrowBackIcon} onClick={onClose} />
        <h2>{t('Preferences')}</h2>
      </DialogHeader>
      <Preferences className="my-9 mx-12" />
    </Dialog>
  );
}

export default AccountPreferencesDialog;
