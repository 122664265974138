import gql from 'graphql-tag';

export const CREATE_TIME_OFF = gql`
  mutation CreateTimeOff($timeOff: TimeOffCreateInput) {
    createTimeOff(timeOff: $timeOff) {
      id
    }
  }
`;

export const CREATE_TIME_OFFS = gql`
  mutation CreateTimeOffs($timeOffs: [TimeOffCreateInput!]!) {
    createTimeOffs(timeOffs: $timeOffs) {
      id
    }
  }
`;

export const UPDATE_TIME_OFF = gql`
  mutation UpdateTimeOff($timeOff: TimeOffUpdateInput) {
    updateTimeOff(timeOff: $timeOff) {
      id
    }
  }
`;

export const UPDATE_TIME_OFFS = gql`
  mutation UpdateTimeOffs($timeOffs: [TimeOffUpdateInput!]!) {
    updateTimeOffs(timeOffs: $timeOffs) {
      id
    }
  }
`;
