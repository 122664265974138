import { OrganizationLaborMetrics } from '__generated__/graphql';
import { isNil, reduce } from 'lodash';
import {
  EquipmentWithTimeOffLaborMetrics,
  LaborMetricsWithTimeOffInterface,
  ProjectCostCodeEquipmentWithTimeOffLaborMetrics,
  ProjectLaborMetricsWithTimeOffInterface,
} from 'types/aggregate/MetricInterfaceWithTimeOff';
import { KeysOfType } from 'types/util/KeysOfType';
import { ILaborMetric } from './jitMetricUtils';
import { add } from './numberUtils';
import { isType } from './typeguard';

export function aggregateLaborMetrics(aggregates: ILaborMetric[]): Required<ILaborMetric> {
  function add(row1: ILaborMetric, row2: ILaborMetric, key: KeysOfType<ILaborMetric, number | undefined>): number {
    return (row1[key] ?? 0) + (row2[key] ?? 0);
  }
  return reduce(
    aggregates,
    (acc, cur) => {
      return {
        ...acc,
        regularSeconds: add(cur, acc, 'regularSeconds'),
        overtimeSeconds: add(cur, acc, 'overtimeSeconds'),
        doubleTimeSeconds: add(cur, acc, 'doubleTimeSeconds'),
        paidTimeOffSeconds: add(cur, acc, 'paidTimeOffSeconds'),
        timeOffSeconds: add(cur, acc, 'timeOffSeconds'),
        totalSeconds: add(cur, acc, 'totalSeconds'),
        regularCost: add(cur, acc, 'regularCost'),
        overtimeCost: add(cur, acc, 'overtimeCost'),
        doubleTimeCost: add(cur, acc, 'doubleTimeCost'),
        timeOffCost: add(cur, acc, 'timeOffCost'),
        laborBurden: add(cur, acc, 'laborBurden'),
        totalCost: add(cur, acc, 'totalCost'),
        equipmentCost: add(cur, acc, 'equipmentCost'),
      };
    },
    {
      regularSeconds: 0,
      regularSecondsDec: 0,
      overtimeSeconds: 0,
      overtimeHoursDec: 0,
      doubleTimeSeconds: 0,
      paidTimeOffSeconds: 0,
      timeOffSeconds: 0,
      totalSeconds: 0,
      regularCost: 0,
      overtimeCost: 0,
      doubleTimeCost: 0,
      timeOffCost: 0,
      laborBurden: 0,
      totalCost: 0,
      equipmentCost: 0,
    }
  );
}

export function combineGeneratedMetricWithTimeOff<
  T extends LaborMetricsWithTimeOffInterface | ProjectLaborMetricsWithTimeOffInterface
>(
  metricData: T | undefined,
  timeOffData: OrganizationLaborMetrics | undefined
): LaborMetricsWithTimeOffInterface | ProjectLaborMetricsWithTimeOffInterface | undefined {
  if (metricData === undefined && timeOffData === undefined) {
    return undefined;
  } else if (metricData === undefined) {
    const metrics: LaborMetricsWithTimeOffInterface | ProjectLaborMetricsWithTimeOffInterface = {
      start: timeOffData?.start ?? '',
      end: timeOffData?.end ?? '',
      pto: timeOffData?.pto,
      ptoCents: timeOffData?.ptoCents,
      upto: timeOffData?.upto,
    };
    return metrics;
  } else if (timeOffData === undefined) {
    return metricData;
  }

  return {
    ...metricData,
    pto: (metricData.pto ?? 0) + (timeOffData.pto ?? 0),
    ptoCents: (metricData.ptoCents ?? 0) + (timeOffData.ptoCents ?? 0),
    upto: (metricData.upto ?? 0) + (timeOffData.upto ?? 0),
  };
}

export function getGeneratedMetricLaborTotals<
  T extends
    | LaborMetricsWithTimeOffInterface
    | ProjectLaborMetricsWithTimeOffInterface
    | ProjectCostCodeEquipmentWithTimeOffLaborMetrics
>(metricData?: T | null, ignoreDescendants?: boolean): ILaborMetric {
  // check if our data is tied to a project equipment
  if (
    !isNil(metricData) &&
    isType<ProjectCostCodeEquipmentWithTimeOffLaborMetrics>(metricData, 'descRt') &&
    isType<ProjectCostCodeEquipmentWithTimeOffLaborMetrics>(metricData, 'equipmentCents') &&
    ignoreDescendants !== true
  ) {
    return getGeneratedProjectEquipmentMetricLaborTotals(metricData);
  }
  // check if our data is tied to a project
  if (
    !isNil(metricData) &&
    isType<ProjectLaborMetricsWithTimeOffInterface>(metricData, 'descRt') &&
    ignoreDescendants !== true
  ) {
    return getGeneratedProjectMetricLaborTotals(metricData);
  }

  if (!isNil(metricData) && isType<EquipmentWithTimeOffLaborMetrics>(metricData, 'equipmentCents')) {
    return getGeneratedEquipmentMetricLaborTotals(metricData);
  }

  // metric data not tied to a project
  return getGeneratedRegularMetricLaborTotals(metricData);
}

export function getGeneratedRegularMetricLaborTotals(metric?: LaborMetricsWithTimeOffInterface | null): ILaborMetric {
  const regularSeconds = metric?.rt ?? 0;
  const overtimeSeconds = metric?.ot ?? 0;
  const doubleTimeSeconds = metric?.dt ?? 0;
  const regularCost = (metric?.rtCents ?? 0) / 100;
  const overtimeCost = (metric?.otCents ?? 0) / 100;
  const doubleTimeCost = (metric?.dtCents ?? 0) / 100;
  const laborBurden = add(metric?.overburdenRtCents, metric?.overburdenOtCents, metric?.overburdenDtCents) / 100;

  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;

  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + laborBurden + timeOffCost,
    equipmentCost: 0,
  };
}

export function getGeneratedProjectMetricLaborTotals(
  metric?: ProjectLaborMetricsWithTimeOffInterface | null
): ILaborMetric {
  const regularSeconds = add(metric?.rt, metric?.descRt);
  const overtimeSeconds = add(metric?.ot, metric?.descOt);
  const doubleTimeSeconds = add(metric?.dt, metric?.descDt);
  const regularCost = add(metric?.rtCents, metric?.descRtCents) / 100;
  const overtimeCost = add(metric?.otCents, metric?.descOtCents) / 100;
  const doubleTimeCost = add(metric?.dtCents, metric?.descDtCents) / 100;
  const laborBurden =
    add(
      metric?.overburdenRtCents,
      metric?.overburdenOtCents,
      metric?.overburdenDtCents,
      metric?.descOverburdenRtCents,
      metric?.descOverburdenOtCents,
      metric?.descOverburdenDtCents
    ) / 100;

  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;

  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + laborBurden + timeOffCost,
    equipmentCost: 0,
  };
}

export function getGeneratedProjectDescendantMetricLaborTotals(
  metric?: ProjectLaborMetricsWithTimeOffInterface | null
): ILaborMetric {
  const regularSeconds = metric?.descRt ?? 0;
  const overtimeSeconds = metric?.descOt ?? 0;
  const doubleTimeSeconds = metric?.descDt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const regularCost = (metric?.descRtCents ?? 0) / 100;
  const overtimeCost = (metric?.descOtCents ?? 0) / 100;
  const doubleTimeCost = (metric?.descDtCents ?? 0) / 100;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;
  const laborBurden =
    add(metric?.descOverburdenRtCents, metric?.descOverburdenOtCents, metric?.descOverburdenDtCents) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: 0,
  };
}

export function getGeneratedProjectDescendantEquipmentMetricLaborTotals(
  metric?: ProjectCostCodeEquipmentWithTimeOffLaborMetrics | null
): ILaborMetric {
  const regularSeconds = metric?.descRt ?? 0;
  const overtimeSeconds = metric?.descOt ?? 0;
  const doubleTimeSeconds = metric?.descDt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const regularCost = (metric?.descRtCents ?? 0) / 100;
  const overtimeCost = (metric?.descOtCents ?? 0) / 100;
  const doubleTimeCost = (metric?.descDtCents ?? 0) / 100;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;
  const equipmentCost = (metric?.descEquipmentCents ?? 0) / 100;
  const laborBurden =
    add(metric?.descOverburdenRtCents, metric?.descOverburdenOtCents, metric?.descOverburdenDtCents) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost,
  };
}

export function getGeneratedProjectEquipmentMetricLaborTotals(
  metric?: ProjectCostCodeEquipmentWithTimeOffLaborMetrics | null
): ILaborMetric {
  const regularSeconds = add(metric?.rt, metric?.descRt);
  const overtimeSeconds = add(metric?.ot, metric?.descOt);
  const doubleTimeSeconds = add(metric?.dt, metric?.descDt);
  const regularCost = add(metric?.rtCents, metric?.descRtCents) / 100;
  const overtimeCost = add(metric?.otCents, metric?.descOtCents) / 100;
  const doubleTimeCost = add(metric?.dtCents, metric?.descDtCents) / 100;
  const laborBurden =
    add(
      metric?.overburdenRtCents,
      metric?.overburdenOtCents,
      metric?.overburdenDtCents,
      metric?.descOverburdenRtCents,
      metric?.descOverburdenOtCents,
      metric?.descOverburdenDtCents
    ) / 100;
  const equipmentCents = ((metric?.equipmentCents ?? 0) + (metric?.descEquipmentCents ?? 0)) / 100;

  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;

  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + laborBurden + timeOffCost,
    equipmentCost: equipmentCents,
  };
}

export function getGeneratedEquipmentMetricLaborTotals(metric?: EquipmentWithTimeOffLaborMetrics | null): ILaborMetric {
  const regularSeconds = metric?.rt ?? 0;
  const overtimeSeconds = metric?.ot ?? 0;
  const doubleTimeSeconds = metric?.dt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const regularCost = (metric?.rtCents ?? 0) / 100;
  const overtimeCost = (metric?.otCents ?? 0) / 100;
  const doubleTimeCost = (metric?.dtCents ?? 0) / 100;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;
  const laborBurden = add(metric?.overburdenRtCents, metric?.overburdenOtCents, metric?.overburdenDtCents) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: (metric?.equipmentCents ?? 0) / 100,
  };
}
