import { IEmployeeSmartFilterData } from 'components/domain/member/EmployeeMultiPicker/EmployeeMultiPicker';
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { fileTypeToFileExtensionLookup, supportedDocumentFileType } from 'containers/documents/constants/constants';
import gql from 'graphql-tag';
import { t } from 'i18next';
import {
  BulletinFilter,
  BulletinPostRecipientType,
  RecipientFilter,
  SmartFilterDateRestrictionsFilter,
} from '../types/types';

export const supportedPostAttachmentFileExtensions = [
  'pdf',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'txt',
  'rtf',
  'csv',
  'png',
  'jpg',
  'jpeg',
] as const;

export const supportedPostAttachmentFileType = [
  ...supportedDocumentFileType,
  'image/png',
  'image/jpg',
  'image/jpeg',
] as const;

export type TSupportedPostAttachmentFileExtensions = typeof supportedPostAttachmentFileExtensions[number];

export type TSupportedPostAttachmentFileType = typeof supportedPostAttachmentFileType[number];

export const fileTypeToFileExtensionLookupPostAttachment: Record<
  TSupportedPostAttachmentFileType,
  TSupportedPostAttachmentFileExtensions
> = {
  ...fileTypeToFileExtensionLookup,
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
};

export const recipientFilterBasicPickerData: IBasicPickerValue<RecipientFilter>[] = [
  {
    id: 'showAll',
    name: t('Show All'),
  },
  {
    id: 'hasNotAcknowledged',
    name: t('Has Not Acknowledged'),
  },
  {
    id: 'hasAcknowledged',
    name: t('Has Acknowledged'),
  },
];

export const bulletinFilterSettingsData: IBasicPickerValue<BulletinFilter>[] = [
  {
    id: 'allPosts',
    name: t('All Posts'),
  },
  {
    id: 'myPosts',
    name: t('My Board'),
  },
];

export const viewRestrictions: IBasicPickerValue<BulletinPostRecipientType>[] = [
  {
    id: 'everyone',
    name: t('Everyone'),
  },
  {
    id: 'position',
    name: t('Limit to Position'),
  },
  {
    id: 'individuals',
    name: t('Limit to Individuals'),
  },
];

export const smartFilterDateRestrictionsData: IBasicPickerValue<SmartFilterDateRestrictionsFilter>[] = [
  {
    id: 'anytime',
    name: t('Anytime, Present and Future'),
  },
  {
    id: 'date',
    name: t('Date'),
  },
];

export const employeeSmartFilterFormDefaultData: IEmployeeSmartFilterData = {
  showClockedIn: false,
  showClockedOut: false,
  showScheduled: false,
  clockedInProjectId: null,
  clockedInCostCodeId: null,
  clockedInEquipmentId: null,
  scheduledProjectId: null,
  scheduledCostCodeId: null,
  scheduledEquipmentId: null,
  scheduledDateRestrictions: null,
  scheduledDate: null,
};

export const QUERY_FOR_TOTAL_MEMBERS = gql`
  query QueryForTotalMembers(
    $filter: MemberFilter
    $sort: [MemberSort!]
    $first: Int
    $pageNumber: Int
    $after: String
  ) {
    members(filter: $filter, sort: $sort, first: $first, pageNumber: $pageNumber, after: $after) {
      id
      archivedOn
      createdOn
      cursor
    }
  }
`;
