import {
  Align,
  Button,
  ButtonList,
  ClassName,
  ITableColumn,
  Justify,
  Position,
  Row,
  Table,
  TextAlign,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { EmptyState, MoreButton, Spinner } from 'components';
import { useMemberTimeEntryDataReportState } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/contexts/MemberTimeEntryDataReportContext';
import { ITimeEntryDataReportMemberData } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import { useOpenable, useOrganization } from 'hooks';
import { t } from 'i18next';
import { first } from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactNode, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/types';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { useFeatureFlags } from 'utils/features';
import SignOffReportDialog from '../SignOffReportDialog/SignOffReportDialog';
import './WeeklyGridReport.scss';

export interface IWeeklyGridReportProps {
  className?: ClassName;
  onDataChange?: (members: string[]) => void;
  timeRange: ITimeRange<DateTime>;
  isLoadedAll: boolean;
  scroller?: HTMLElement;
}

export interface WeeklyGridReportData {
  id: string;
  employeeName: string;
  weeklyTotal: string;
  weeklyOt: string;
  weeklyDt: string;
}

const WeeklyGridReport = (props: IWeeklyGridReportProps) => {
  const { className, onDataChange, timeRange, scroller } = props;
  const weeklyColumns = useSelector<IReduxState, IVisibleTableColumn[]>((state) => state.timesheet.weeklyColumns);
  const dailyColumns = useSelector<IReduxState, IVisibleTableColumn[]>((state) => state.timesheet.dailyColumns);
  const organization = useOrganization();
  const isPro = useFeatureFlags('PRO');
  const addTimeEntryDialog = useOpenable();
  const addTimeOffDialog = useOpenable();
  const dailySignOffDialog = useOpenable();
  const isDailySignOffEnabled = organization.safetySignature || organization.timeAccuracy || organization.breakPolicy;
  const { data, loading } = useMemberTimeEntryDataReportState();

  const [selectedMemberId, setSelectedMemberId] = useState<string>();

  const selectedMembers = useMemo(() => (selectedMemberId ? [selectedMemberId] : []), [selectedMemberId]);

  const classes = classNames('weekly-grid-container', className);

  const handleAddTimeEntryDialogClose = () => {
    addTimeEntryDialog.close();
    setSelectedMemberId(undefined);
  };

  const handleAddTimeOffDialogClose = () => {
    addTimeOffDialog.close();
    setSelectedMemberId(undefined);
  };

  const handleDailySignOffDialogClose = () => {
    dailySignOffDialog.close();
    setSelectedMemberId(undefined);
  };

  const updateMembers = () => {
    onMembersUpdate(selectedMembers);
  };

  const onMembersUpdate = (members: string[]) => {
    if (onDataChange) {
      onDataChange(members);
    }
  };

  const renderEmployeeCell = (row: ITimeEntryDataReportMemberData) => {
    const renderDropDown = (closeDropdown: () => void) => {
      const handleAddTimeEntryClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        setSelectedMemberId(row.id);
        addTimeEntryDialog.open();
      };
      const handleAddTimeOffClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        setSelectedMemberId(row.id);
        addTimeOffDialog.open();
      };
      const handleViewDailySignOffClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        closeDropdown();
        setSelectedMemberId(row.id);
        dailySignOffDialog.open();
      };

      return (
        <ButtonList>
          <Button onClick={handleAddTimeEntryClick}>{t('Add Time Entry')}</Button>
          <Button onClick={handleAddTimeOffClick}>{t('Add Time Off')}</Button>
          {isPro && isDailySignOffEnabled && (
            <Button onClick={handleViewDailySignOffClick}>{t('View Daily Sign-Off')}</Button>
          )}
        </ButtonList>
      );
    };

    return (
      <div className="employee-cell">
        <MoreButton className={`no-print button`} position={Position.BOTTOM_START} renderContent={renderDropDown} />
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN} className={'pl-2'}>
          {row.title}
        </Row>
      </div>
    );
  };

  const renderDateCell = (row: ITimeEntryDataReportMemberData, col: ITableColumn<ITimeEntryDataReportMemberData>) => {
    const currentDateData = first(row.rows.filter((data) => data.date === col.title));
    const cols = dailyColumns
      .filter((c) => c.visible)
      .map((dailyCol) => {
        const column: ITableColumn<ITimeEntryDataReportMemberData> = {
          ...dailyCol,
        };
        return column;
      });

    return (
      <div className="date-cell-table p-0">
        <div className="date-cell-row p-0">
          {cols.map((c) => {
            const key = c.key;
            return (
              <div className="date-cell-td" key={key}>
                {currentDateData ? `${currentDateData[key as keyof ITimeEntryDataTableRow]}` ?? '---' : '---'}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderEmptyState = (): ReactNode => <EmptyState title={t('No Data')} />;

  const getDaysOfWeekCols = () => {
    let currentDate = timeRange.startTime.startOf('day');
    const endDay = timeRange.endTime.endOf('day');
    const days = [];
    while (currentDate < endDay) {
      days.push(currentDate.toFormat('EEE, MMM d'));
      currentDate = currentDate.plus({ days: 1 });
    }

    const renderHeader = (col: ITableColumn<ITimeEntryDataReportMemberData>) => {
      const cols = dailyColumns
        .filter((c) => c.visible)
        .map((dailyCol) => {
          const column: ITableColumn<ITimeEntryDataReportMemberData> = {
            ...dailyCol,
          };
          return column;
        });
      return (
        <>
          <div className="px-4 py-1">{col.title}</div>
          {cols.length > 0 && (
            <div className="date-cell-row header p-0">
              {cols.map((c) => (
                <div className="date-cell-td pb-2 m-0" key={c.key}>
                  {c.title}
                </div>
              ))}
            </div>
          )}
        </>
      );
    };

    return days.map((day) => {
      const visibleColumns = dailyColumns.filter((col) => col.visible);
      const size = `${visibleColumns.length * 75}px`;
      const column: ITableColumn<ITimeEntryDataReportMemberData> = {
        key: day,
        title: day,
        align: TextAlign.CENTER,
        cellClassName: 'p-0 m-0 date-cell',
        cell: (row: ITimeEntryDataReportMemberData, col: ITableColumn<ITimeEntryDataReportMemberData>) => {
          return renderDateCell(row, col);
        },
        header: renderHeader,
        headerClassName: 'date-cell',
        size,
      };
      return column;
    });
  };

  const getColumns = (): Array<ITableColumn<ITimeEntryDataReportMemberData>> => {
    const cols = [
      {
        key: 'title',
        title: t('Employee'),
        cell: (row: ITimeEntryDataReportMemberData) => renderEmployeeCell(row),
        align: TextAlign.LEFT,
        size: '350px',
        cellClassName: 'p-0',
        headerClassName: 'employee-header',
      },
    ];

    const visibleColumns = weeklyColumns
      .filter((c) => c.visible)
      .map((col) => {
        const column: ITableColumn<ITimeEntryDataReportMemberData> = { ...col, align: TextAlign.CENTER, size: '75px' };
        return column;
      });

    return [...cols, ...visibleColumns, ...getDaysOfWeekCols()];
  };

  const calculateColumnsWidth = () => {
    const dayOverviewColumnsLength = getColumns().length - 2;
    const visibleDailyColumns = dailyColumns.filter((col) => col.visible);
    const dayDetailsColumnsLength = visibleDailyColumns.length * 75;
    const visibleWeeklyColumns = weeklyColumns.filter((col) => col.visible);
    const weeklyColumnsLength = visibleWeeklyColumns.length * 75;
    return dayOverviewColumnsLength * dayDetailsColumnsLength + 350 + weeklyColumnsLength;
  };
  return (
    <>
      <div className={classes}>
        {loading ? (
          <Row justify={Justify.CENTER} align={Align.CENTER} className="py-8">
            <Spinner />
          </Row>
        ) : (
          <Table
            cols={getColumns()}
            data={data}
            emptyTemplate={renderEmptyState()}
            className={'weekly-grid-report'}
            scroller={scroller}
            minWidth={`${calculateColumnsWidth()}px`}
          />
        )}
      </div>

      <TimeActionsFormDialog
        type={'add'}
        isOpen={addTimeEntryDialog.isOpen}
        onClose={handleAddTimeEntryDialogClose}
        memberIds={selectedMembers}
        onSubmit={updateMembers}
        onDelete={updateMembers}
      />
      <TimeOffFormDialog
        isOpen={addTimeOffDialog.isOpen}
        onClose={handleAddTimeOffDialogClose}
        memberIds={selectedMembers}
        onSubmit={updateMembers}
      />
      <SignOffReportDialog
        isOpen={dailySignOffDialog.isOpen}
        onClose={handleDailySignOffDialogClose}
        timeRange={timeRange}
        memberIds={selectedMembers}
        timeRangeType={TimeRangeType.WEEKLY}
        onDataChange={updateMembers}
      />
    </>
  );
};

export default WeeklyGridReport;
