import { useApolloClient } from '@apollo/client';
import { SIMPLE_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Capsule from 'components/foundation/Capsule/Capsule';
import { useCallback, useEffect, useState } from 'react';
import IEquipment from 'types/Equipment';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { getEquipmentDisplay } from 'utils/stringUtils';

export interface IEquipmentCapsulesProps {
  className?: ClassName;
  equipmentIds: string[];
  printVersion?: boolean;
}

const EquipmentCapsules = ({ className, equipmentIds, printVersion }: IEquipmentCapsulesProps) => {
  const classes = classNames('equipment-capsules', className);
  const client = useApolloClient();
  const [equipment, setEquipment] = useState<IEquipment[]>([]);
  const includesNoEquipment = equipmentIds.includes(remainingDataItemId);

  useEffect(() => {
    if (!includesNoEquipment) {
      getFilterEquipment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentIds]);

  const getFilterEquipment = useCallback(async () => {
    const results = await client.query<{ equipment: IEquipment[] }>({
      query: SIMPLE_EQUIPMENT_QUERY,
      variables: { filter: { id: { contains: equipmentIds } } },
      fetchPolicy: 'network-only',
    });
    setEquipment(results.data.equipment ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentIds]);

  function renderList() {
    if (printVersion) {
      return equipment.map((item) => (
        <h2 key={item.id} className="mb-3">
          {getEquipmentDisplay(item)}
        </h2>
      ));
    } else {
      return equipment.map((item) => <Capsule key={item.id} className={classes} title={getEquipmentDisplay(item)} />);
    }
  }

  function renderNoEquipment() {
    if (printVersion) {
      return (
        <h2 key={remainingDataItemId} className="mb-3">
          {t('No Equipment')}
        </h2>
      );
    } else {
      return <Capsule key={remainingDataItemId} className={classes} title={t('No Equipment')} />;
    }
  }

  return (
    <>
      {renderList()}
      {includesNoEquipment && renderNoEquipment()}
    </>
  );
};

export default EquipmentCapsules;
