import { ClassName, ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import * as React from 'react';
import { t } from 'utils/localize';
import { SignOffQuestionResponseType } from './CreateDailySignOffQuestionForm';

export interface ISignOffQuestionResponseSelectProps
  extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  className?: ClassName;
  onChange: (value: string, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SignOffQuestionResponseSelect = (props: ISignOffQuestionResponseSelectProps) => {
  const { className, onChange, ...selectProps } = props;

  function getOptions(): ISelectOption[] {
    return [
      {
        value: SignOffQuestionResponseType.NO_ACTION,
        label: t('No Action'),
      },
      {
        value: SignOffQuestionResponseType.FLAG_RESPONSE,
        label: t('Flag Response'),
      },
    ];
  }

  const classes = classNames('sign-off-question-response-select', className);

  return (
    <Select
      className={classes}
      placeholder={t('Whom To Ask')}
      options={getOptions()}
      onChange={onChange}
      {...selectProps}
    />
  );
};

SignOffQuestionResponseSelect.defaultProps = {
  ...Select.defaultProps,
};

export default SignOffQuestionResponseSelect;
