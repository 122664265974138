import gql from 'graphql-tag';

export const costCodeGroupsQuery = gql`
  query CostCodeGroups($first: Int, $after: String, $filter: CostCodeGroupFilter) {
    costCodeGroups(first: $first, after: $after, filter: $filter, sort: [{ groupName: asc }]) {
      id
      groupName
      cursor
    }
  }
`;

export const costCodeGroupQuery = gql`
  query CostCodeGroupById($groupId: Uuid!) {
    costCodeGroups(filter: { id: { equal: $groupId } }) {
      id
      groupName
      cursor
    }
  }
`;
