import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  Equipment,
  LaborMetricsInterval,
  Maybe,
  Project,
  ProjectLaborMetricsInterface,
  Scalars,
} from '__generated__/graphql';
import { BASIC_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import {
  SIMPLE_PROJECT_WITH_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
} from 'apollo/queries/project-queries';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { IActivityReportEquipmentRowInfo } from 'containers/activity-reports/hooks/ActivityReportData';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  encryptUnassignedProjectId,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import {
  SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_AND_COST_CODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_AND_COST_CODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils, flattenForSubProjectExport } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import _, { Dictionary, first, isEmpty, isNil, keyBy, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNil, mapNotNull, sortByIgnoreCase, toggleSelection } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { combineMetricFilters, metricConditionFilter, metricNullFilter } from 'utils/jitMetricUtils';
import { t } from 'utils/localize';
import {
  aggregateLaborMetrics,
  getGeneratedMetricLaborTotals,
  getGeneratedProjectDescendantEquipmentMetricLaborTotals,
  getGeneratedProjectDescendantMetricLaborTotals,
  getGeneratedProjectEquipmentMetricLaborTotals,
  getGeneratedProjectMetricLaborTotals,
} from 'utils/metricUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { IProjectActivityTableRowInfo } from './useProjectActivity';

export interface IProjectEquipmentActivityTableRowInfo
  extends IProjectActivityTableRowInfo,
    IActivityReportEquipmentRowInfo {
  equipmentId: string | null;
  subprojectData?: IProjectEquipmentActivityTableRowInfo[];
  detailRows?: IProjectEquipmentActivityTableRowInfo[];
}

interface ProjectEquipmentMetricRow extends ProjectLaborMetricsInterface {
  projectId?: Maybe<Scalars['Uuid']['output']>;
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useProjectActivityEquipmentDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>
) {
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const { getAll } = useApolloPaging();
  const [data, setData] = useState<IProjectEquipmentActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IProjectEquipmentActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'project',
    SortDirection.ASCENDING,
    getSortField
  );
  const selectedProjectIds = useRef<string[]>([]);

  function idFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, true);
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function query() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function subProjectQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return SIMPLE_PROJECT_WITH_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return SIMPLE_PROJECT_WITH_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return SIMPLE_PROJECT_WITH_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getEquipmentQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_AND_COST_CODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_AND_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IProjectEquipmentActivityTableRowInfo, key: keyof IProjectEquipmentActivityTableRowInfo) {
    if (key === 'project') {
      return item.project?.title;
    } else {
      return item[key];
    }
  }

  async function getEquipment(equipmentIds: string[]) {
    if (isEmpty(equipmentIds)) {
      return [];
    }

    return await getAll<Equipment & ICursorable>('equipment', {
      query: BASIC_EQUIPMENT_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: equipmentIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    let filter: object;

    if (filterType === ActivityReportType.BY_PROJECT) {
      filter = {
        parentProjectId: { equal: filterId },
      };
    } else {
      filter = {
        depth: { equal: 1 },
      };
    }

    const projects = await getAll<Project & ICursorable>('projectsWithTime', {
      query: query(),
      variables: {
        first: 100,
        filter,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...idFilter(),
        ...hasTimeIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const equipmentLookup = await getEquipmentLookup(projects);
    const tableRows = mapNotNull(projects, (project) => createTableRowInfo(equipmentLookup, project));

    const remainingData = await createNoProjectRow(tableRows);
    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    if (selectedProjectIds.current && !isEmpty(selectedProjectIds.current)) {
      // if we have subprojects selected then update the data with the sub project info
      setData(await updateDataWithSubprojectInfo(tableRows, selectedProjectIds.current));
    } else {
      setData(tableRows);
    }
  }

  const createTableRowInfo = (
    equipmentLookup: Dictionary<Equipment>,
    project: Project
  ): IProjectEquipmentActivityTableRowInfo | null => {
    const metrics = laborMetrics(project);

    let noEquipmentRow: IProjectEquipmentActivityTableRowInfo | undefined = undefined;
    const noEquipment = first(metrics.filter((metric) => isNil(metric.equipmentId)));
    if (!isNil(noEquipment)) {
      const metricTotal = getGeneratedProjectMetricLaborTotals(noEquipment);
      const descendantData = getGeneratedProjectDescendantMetricLaborTotals(noEquipment);

      if (
        metricTotal.totalSeconds > 0 ||
        metricTotal.totalCost > 0 ||
        descendantData.totalSeconds > 0 ||
        descendantData.totalCost > 0
      ) {
        noEquipmentRow = {
          id: project.id + remainingDataItemId,
          equipmentId: remainingDataItemId,
          equipment: null,
          equipmentCost: 0,
          equipmentRate: 0,
          equipmentTotalCost: metricTotal.totalCost,
          project,
          isUnassigned: false,
          descendantData,
          ...activityReportUtils.metricToRowInfo(metricTotal),
        };
      }
    }

    const unsortedEquipmentRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedProjectEquipmentMetricLaborTotals(metric);
      const descendantData = getGeneratedProjectDescendantEquipmentMetricLaborTotals(metric);

      if (
        isNil(metric.equipmentId) ||
        (metricTotal.totalSeconds === 0 &&
          metricTotal.totalCost === 0 &&
          descendantData.totalSeconds === 0 &&
          descendantData.totalCost === 0)
      ) {
        return null;
      }

      const equipment = equipmentLookup[metric.equipmentId];

      return {
        id: project.id + metric.equipmentId,
        equipmentId: metric.equipmentId,
        equipment: equipment ?? null,
        project,
        isUnassigned: false,
        descendantData,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment?.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });
    const equipmentRows = sortByIgnoreCase(unsortedEquipmentRows, (row) => getEquipmentDisplay(row.equipment));

    if (isEmpty(equipmentRows) && noEquipmentRow === undefined) {
      return null;
    } else {
      const projectTotalMetrics = aggregateActivityRows(
        noEquipmentRow === undefined ? equipmentRows : [...equipmentRows, noEquipmentRow]
      );

      const rowDecendants = mapNotNull(equipmentRows, (row) => row.descendantData);
      const projectTotalDecendantMetrics = aggregateLaborMetrics(
        !isNil(noEquipmentRow?.descendantData) ? [...rowDecendants, noEquipmentRow!.descendantData] : rowDecendants
      );

      return {
        ...projectTotalMetrics,
        id: project.id,
        equipmentId: null,
        equipment: null,
        equipmentCost: sumBy(equipmentRows, (row) => row.equipmentCost),
        equipmentRate: 0,
        equipmentTotalCost: sumBy(equipmentRows, (row) => row.equipmentCost) + projectTotalMetrics.totalCost,
        project,
        isUnassigned: false,
        descendantData: projectTotalDecendantMetrics,
        detailRows: noEquipmentRow === undefined ? equipmentRows : [...equipmentRows, noEquipmentRow],
      };
    }
  };

  const createNoProjectRow = async (
    tableRows: IProjectEquipmentActivityTableRowInfo[]
  ): Promise<IProjectEquipmentActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost);
    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Project row
    }

    let metricFilter = undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.MemberId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_COST_CODE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          filterId === remainingDataItemId
            ? metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull)
            : metricConditionFilter(ConditionFieldType.CostCodeId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        metricFilter = metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull);
        break;
      case ActivityReportType.BY_PROJECT:
        metricFilter = metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId);
        break;
    }

    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getEquipmentQuery(),
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            projectId: { isNull: true },
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter,
      },
    });

    const unsortedEquipmentRows: IProjectEquipmentActivityTableRowInfo[] = equipment.flatMap((e) => {
      const metrics = equipmentLaborMetrics(e);

      return mapNotNull(metrics, (metric) => {
        const metricTotal = getGeneratedMetricLaborTotals(metric);
        if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
          return null;
        }

        return {
          id: remainingDataItemId + e.id,
          project: null,
          equipment: e,
          equipmentId: e.id,
          equipmentCost: metricTotal.equipmentCost,
          equipmentRate: getCostHistoryForRange(e?.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
          equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
          isUnassigned: false,
          descendantData: null,
          ...activityReportUtils.metricToRowInfo(metricTotal),
        };
      });
    });

    const equipmentRows = sortByIgnoreCase(unsortedEquipmentRows, (row) => getEquipmentDisplay(row.equipment));
    const remainingEquipmentData = calculateRemainingActivityData(
      equipmentRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingEquipmentData.totalHours > 0) {
      // add the no cost code item under no project details
      equipmentRows.push({
        ...remainingEquipmentData,
        id: remainingDataItemId + remainingDataItemId,
        project: null,
        equipment: null,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
        equipmentId: remainingDataItemId,
        isUnassigned: false,
        descendantData: null,
      });
    }

    return {
      project: null,
      equipment: null,
      equipmentId: null,
      equipmentCost: sumBy(equipmentRows, (row) => row.equipmentCost),
      equipmentRate: sumBy(equipmentRows, (row) => row.equipmentRate),
      equipmentTotalCost: sumBy(equipmentRows, (row) => row.equipmentCost) + remainingData.totalCost,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: equipmentRows,
      isUnassigned: false,
      descendantData: null,
    };
  };

  async function getEquipmentLookup(data: Project[]) {
    const metrics = data.flatMap((project) => laborMetrics(project));
    const equipmentIds = mapNotNil(metrics, (item) => item.equipmentId);
    const equipment = await getEquipment(uniq(equipmentIds));

    return keyBy(equipment, (e) => e.id);
  }

  const laborMetrics = (project: Project): ProjectEquipmentMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return project.projectMemberEquipmentLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return project.projectEquipmentLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return project.projectCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectEquipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const equipmentLaborMetrics = (equipment: Equipment): ProjectEquipmentMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return equipment.equipmentMemberProjectLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return equipment.equipmentProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return equipment.equipmentProjectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  async function updateDataWithSubprojectInfo(
    projectData: IProjectEquipmentActivityTableRowInfo[],
    selectedProjectIds: string[]
  ) {
    if (!isEmpty(projectData)) {
      let subprojectResult: Project[] = [];

      if (!_.isEmpty(selectedProjectIds)) {
        const projects = await getAll<Project & ICursorable>('projects', {
          query: subProjectQuery(),
          variables: {
            first: 100,
            filter: {
              parentProjectId: { contains: selectedProjectIds },
            },
            sort: [{ title: 'asc' }],
            metricsInterval: LaborMetricsInterval.Custom,
            metricsStartDate: timeRange.startTime.toISODate(),
            metricsEndDate: timeRange.endTime.toISODate(),
            ...idFilter(),
          },
          fetchPolicy: 'network-only',
        });

        subprojectResult = projects;
      }

      const equipmentLookup = await getEquipmentLookup(subprojectResult);
      const subprojectData = mapNotNull(subprojectResult, (project) => createTableRowInfo(equipmentLookup, project));
      const subprojectDataByParent = _.groupBy(subprojectData, (item) => item.project!.parentProjectId);

      return updateProjectDataWithSubprojectData(projectData, subprojectDataByParent);
    }

    return data;
  }

  function updateProjectDataWithSubprojectData(
    projectData: IProjectEquipmentActivityTableRowInfo[],
    subprojectDataByParent: Dictionary<IProjectEquipmentActivityTableRowInfo[]>
  ): IProjectEquipmentActivityTableRowInfo[] {
    return projectData
      .filter((item) => item.isUnassigned === false)
      .map((item) => {
        const subprojectItems = subprojectDataByParent[item.project!.id];
        if (subprojectItems && !_.isEmpty(subprojectItems)) {
          // the project is expanded, so add data for the project's subs
          // if we have existing subproject data on our item use that because it has the graph built in
          const subprojectData = updateProjectDataWithSubprojectData(
            !_.isEmpty(item.subprojectData) ? item.subprojectData! : subprojectItems,
            subprojectDataByParent
          );
          if (!_.isEmpty(subprojectData)) {
            // add the unassigned item if there are sub projects and the subs don't account for all of the time
            const unassignedItem = getUnassignedItem(item);
            if (unassignedItem.totalHours > 0 || unassignedItem.totalCost > 0) {
              item.subprojectData = subprojectData.concat(unassignedItem);
            } else {
              item.subprojectData = subprojectData;
            }
          } else {
            item.subprojectData = subprojectData;
          }
        } else if (!_.isNil(item.subprojectData)) {
          // the project is collapsed but we have subproject data, so remove it
          clearSubprojectData(item);
        }

        return item;
      });
  }

  const getUnassignedItem = (
    parentRow: IProjectEquipmentActivityTableRowInfo
  ): IProjectEquipmentActivityTableRowInfo => {
    const unassignedKey = encryptUnassignedProjectId(parentRow.project!.id) + parentRow.equipmentId;
    const descendantData = parentRow.descendantData!;
    const detailRows = parentRow.detailRows
      ? mapNotNull(parentRow.detailRows, (detailRow) => {
        const unassignedRow = getUnassignedItem(detailRow);
        if (unassignedRow.totalHours > 0 || unassignedRow.totalCost > 0) {
          return unassignedRow;
        }

        return null;
      })
      : undefined;

    return {
      ...parentRow,
      id: unassignedKey,
      regularHours: parentRow.regularHours - descendantData.regularSeconds,
      regularHoursDec: parentRow.regularHoursDec - descendantData.regularSeconds,
      overtimeHours: parentRow.overtimeHours - descendantData.overtimeSeconds,
      overtimeHoursDec: parentRow.overtimeHoursDec - descendantData.overtimeSeconds,
      doubleTimeHours: parentRow.doubleTimeHours - descendantData.doubleTimeSeconds,
      doubleTimeHoursDec: parentRow.doubleTimeHoursDec - descendantData.doubleTimeCost,
      totalHours: parentRow.totalHours - descendantData.totalSeconds,
      totalHoursDec: parentRow.totalHoursDec - descendantData.totalSeconds,
      regularCost: parentRow.regularCost - descendantData.regularCost,
      overtimeCost: parentRow.overtimeCost - descendantData.overtimeCost,
      doubletimeCost: parentRow.doubletimeCost - descendantData.doubleTimeCost,
      laborBurden: parentRow.laborBurden - descendantData.laborBurden,
      totalCost: parentRow.totalCost - descendantData.totalCost,
      equipmentCost: parentRow.equipmentCost - descendantData.equipmentCost,
      equipmentTotalCost:
        parentRow.totalCost - descendantData.totalCost + parentRow.equipmentCost - descendantData.equipmentCost,
      project: {
        ...parentRow.project!,
        id: encryptUnassignedProjectId(parentRow.project!.id),
        title: t('Unassigned'),
        depth: parentRow.project!.depth + 1,
        children: undefined,
      },
      isUnassigned: true,
      subprojectData: undefined,
      detailRows,
    };
  };

  function clearSubprojectData(projectData: IProjectEquipmentActivityTableRowInfo) {
    if (!_.isEmpty(projectData.subprojectData)) {
      projectData.subprojectData?.forEach((item) => clearSubprojectData(item));
    }

    // remove from selected ids in case the parent got toggled off and the child project was expanded
    selectedProjectIds.current = selectedProjectIds.current.filter((id) => id !== projectData.project!.id);

    projectData.subprojectData = undefined;
  }

  async function getExportDataWithSubprojects() {
    // in case there are expanded projects, filter them out
    // keep the no project item
    const rootProjects = data.filter((projectData) => isNil(projectData.project) || projectData.project?.depth === 1);
    const projectIds = mapNotNil(rootProjects, (project) => {
      if (!isEmpty(project.project?.children)) {
        return project.project?.id;
      } else {
        return null;
      }
    });

    const subprojectData = flattenForSubProjectExport(await updateDataWithSubprojectInfo(rootProjects, projectIds));
    if (remainingItemRef.current) {
      return subprojectData.concat(remainingItemRef.current);
    }

    return subprojectData;
  }

  const toggleSubProject = async (project: Project) => {
    selectedProjectIds.current = toggleSelection(selectedProjectIds.current, project.id);
    setData(await updateDataWithSubprojectInfo(data, selectedProjectIds.current));
  };

  function isProjectOpen(projectId: string) {
    return selectedProjectIds.current.some((pId) => {
      return pId === projectId;
    });
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    toggleSubProject,
    isProjectOpen,
    remainingData: remainingItemRef.current,
    getExportDataWithSubprojects,
  };
}
