import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import CostCodeConditionalContainer from 'components/domain/cost-code/CostCodeConditionalContainer/CostCodeConditionalContainer';
import EquipmentConditionalContainer from 'components/domain/equipment/EquipmentConditionalContainer/EquipmentConditionalContainer';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import Typography from 'components/foundation/text/Typography/Typography';
import { TIME_LOG_FEED_DATE_FORMAT, TIME_LOG_FEED_TIME_FORMAT } from 'containers/time-log-feed/constants/constants';
import { getTimeLogDetailTimeStatus, shouldShowDateInTimeLogDetailsColumn } from 'containers/time-log-feed/utils/utils';
import ClockActionType from 'types/enum/ClockActionType';
import { formatOptionalDateTimeRange, getTimeFormatWithYearConsideration } from 'utils/dateUtils';
import { ITimeLogFeedTableRow } from '../TimeLogFeedTable/TimeLogFeedTable';
import './TimeLogFeedDetails.scss';

export interface ITimeLogFeedDetailsProps {
  actionType: ClockActionType;
  details: ITimeLogFeedTableRow['details'];
  className?: ClassName;
}

function TimeLogFeedDetails({ actionType, details, className }: ITimeLogFeedDetailsProps) {
  function getTime() {
    const timeStatus = getTimeLogDetailTimeStatus(actionType);
    if (timeStatus === 'start') {
      return (
        <Typography tag="div">
          <TimeAndSuperscript
            dateTime={details.startDate.dateTime}
            dst={details.startDate.dst}
            format={TIME_LOG_FEED_TIME_FORMAT}
          />
        </Typography>
      );
    } else if (timeStatus === 'end') {
      if (!details.endDate) {
        return null;
      }

      return (
        <Typography tag="div">
          <TimeAndSuperscript
            dateTime={details.endDate.dateTime}
            dst={details.endDate.dst}
            format={TIME_LOG_FEED_TIME_FORMAT}
          />
        </Typography>
      );
      // TODO on range need to subtract break totals
    } else if (timeStatus === 'range') {
      return (
        <Typography tag="div">
          <TimeAndSuperscript
            dateTime={details.startDate.dateTime}
            dst={details.startDate.dst}
            format={TIME_LOG_FEED_TIME_FORMAT}
          />{' '}
          -{' '}
          {details.endDate ? (
            <TimeAndSuperscript
              dateTime={details.endDate.dateTime}
              dst={details.endDate.dst}
              format={TIME_LOG_FEED_TIME_FORMAT}
            />
          ) : (
            '?'
          )}
        </Typography>
      );
    } else {
      return null;
    }
  }

  function getDate() {
    const shouldShowDate = shouldShowDateInTimeLogDetailsColumn(actionType);
    if (!shouldShowDate) {
      return null;
    }

    return (
      <Typography tag="div">
        {formatOptionalDateTimeRange(
          details.startDate.dateTime,
          details.endDate?.dateTime ?? null,
          getTimeFormatWithYearConsideration(details.startDate.dateTime, TIME_LOG_FEED_DATE_FORMAT),
          details.endDate
            ? getTimeFormatWithYearConsideration(details.endDate.dateTime, TIME_LOG_FEED_DATE_FORMAT)
            : TIME_LOG_FEED_DATE_FORMAT
        )}
      </Typography>
    );
  }

  return (
    <div className={classNames('time-log-feed-details', className)}>
      {getDate()}
      {getTime()}
      <Typography tag="div">{details.project}</Typography>
      <CostCodeConditionalContainer>
        <Typography tag="div">{details.costCode}</Typography>
      </CostCodeConditionalContainer>
      <EquipmentConditionalContainer>
        <Typography tag="div">{details.equipment}</Typography>
      </EquipmentConditionalContainer>
    </div>
  );
}

export default TimeLogFeedDetails;
