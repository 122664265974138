import { useApolloClient } from '@apollo/client';
import { PROJECT_QUERY } from 'apollo/queries/project-queries';
import { ITimeEntryDataTableData } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import { graphQLContainsIdOrEmptyItemId } from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { useToastOpen } from 'contexts/ToastContext';
import { useLazyLoading } from 'hooks';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import { useMounted } from 'hooks/utils/useMounted';
import { isEmpty, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import IProject from 'types/Project';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import { PROJECTS_WITH_TIME_QUERY } from './projects-with-time-query';

export interface IProjectTimeEntryFilter {
  timeRange: ITimeRange<DateTime>;
  includeOpen?: boolean;
  projectGroupId?: string | null;
  projectIds?: string[] | null;
  idWithDescendants?: boolean;
  memberIds?: string[];
  costCodeIds?: string[];
  equipmentIds?: string[];
  excludeArchived?: boolean;
  rootProjectsOnly?: boolean;
}

export function useLazyProjectTimeData(scroller: HTMLElement | Document | null, filter: IProjectTimeEntryFilter) {
  const client = useApolloClient();
  const toast = useToastOpen();
  const getTimeEntries = useTimeEntryQuery();
  const isMounted = useMounted();

  const addTimeData = useCallback(
    async (projects: Array<IProject & ICursorable>) => {
      const customSort = [{ project: { depth: 'asc' } }, { project: { title: 'asc' } }, { startTime: 'asc' }];
      let entries: ITimeEntry[] = [];
      try {
        entries = await getTimeEntries(
          filter.timeRange,
          filter.includeOpen,
          filter.memberIds,
          projects.map((m) => m.id),
          filter.costCodeIds,
          filter.equipmentIds,
          filter.idWithDescendants,
          customSort,
          [MemberPermission.TIME_EVENTS]
        );
      } catch (error) {
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      const projectWithEntries = projects.map((project) => {
        let projectEntries: ITimeEntry[] = [];
        projectEntries = entries.filter(
          (entry) => entry.projectId === project.id || entry.project?.rootProjectId === project.rootProjectId
        );

        const item: ITimeEntryDataTableData<IProject> & ICursorable = {
          item: project,
          entries: projectEntries,
          timeOffs: [],
          cursor: project.cursor,
        };
        return item;
      });
      return projectWithEntries.filter((e) => !isNilOrEmpty(e.entries));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  const getData = useCallback(
    async (cursor: string | null, first: number) => {
      dispatch({ type: 'SET_LOADING', payload: true });
      try {
        let projects = [];
        if (filter.projectIds) {
          const results = await client.query<{ projects: Array<IProject & ICursorable> }>({
            query: PROJECT_QUERY,
            fetchPolicy: 'network-only',
            variables: getProjectByIdVariables(first, cursor),
          });
          projects = results.data.projects ?? [];
        } else {
          const results = await client.query<{ projectsWithTime: Array<IProject & ICursorable> }>({
            query: PROJECTS_WITH_TIME_QUERY,
            fetchPolicy: 'network-only',
            variables: getProjectVariables(first, cursor),
          });
          projects = results.data.projectsWithTime ?? [];
        }

        if (isEmpty(projects)) {
          return [];
        }
        return await addTimeData(projects);
      } catch (error) {
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, getTimeEntries, addTimeData]
  );

  const getProjectByIdVariables = (first: number, cursor?: string | null) => {
    const variables: any = {
      first,
      after: cursor,
      filter: {
        projectGroupId: filter.projectGroupId ? { equal: filter.projectGroupId } : undefined,
        id: graphQLContainsIdOrEmptyItemId(filter.projectIds),
        archivedOn: filter.excludeArchived ? { isNull: filter.excludeArchived } : undefined,
      },
      sort: [{ title: 'asc' }],
    };

    return variables;
  };

  const getProjectVariables = (first: number, cursor?: string | null) => {
    const variables: any = {
      first,
      after: cursor,
      filter: {
        projectGroupId: filter.projectGroupId ? { equal: filter.projectGroupId } : undefined,
        archivedOn: filter.excludeArchived ? { isNull: filter.excludeArchived } : undefined,
        depth: filter.rootProjectsOnly === true ? { equal: 1 } : undefined,
      },
      sort: [{ title: 'asc' }],
      startTime: filter.timeRange.startTime
        .startOf('day')
        .toUTC()
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
      endTime: filter.timeRange.endTime
        .endOf('day')
        .toUTC()
        .toISO({ suppressMilliseconds: true, includeOffset: false }),
      includeAncestors: filter.idWithDescendants ?? false,
      includeOpenEntry: filter.includeOpen ?? false,
      memberId: graphQLContainsIdOrEmptyItemId(filter.memberIds),
      costCodeId: graphQLContainsIdOrEmptyItemId(filter.costCodeIds),
      equipmentId: graphQLContainsIdOrEmptyItemId(filter.equipmentIds),
    };

    return variables;
  };

  const { clearData, data, error, loading, dispatch, loadedAll, loadAll } = useLazyLoading(
    scroller,
    getData,
    undefined,
    5,
    200
  );

  const updateProjectIds = useCallback(
    async (projectIds: string[]) => {
      const allowedProjectIds = filter.projectIds
        ? projectIds.filter((id) => filter.projectIds?.some((projectId) => projectId === id))
        : projectIds;
      const newProjectData = allowedProjectIds.map(async (id) => {
        const project = data.find((datum) => datum.item.id === id);
        if (project?.item === undefined) {
          const results = await client.query<{ projects: Array<IProject & ICursorable> }>({
            query: PROJECT_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              filter: {
                id: graphQLContainsIdOrEmptyItemId([id]),
              },
            },
          });

          return await addTimeData(results.data.projects);
        } else {
          return await addTimeData([{ ...project.item, cursor: project.cursor }]);
        }
      });

      const allResults = (await Promise.all(newProjectData)).flat();
      const newData = data.filter((object) => !projectIds.includes(object.item.id));

      dispatch({
        type: 'SET_DATA',
        payload: {
          data: sortBy([...newData, ...allResults], (result) => result.item.title.toLowerCase()),
          loadedAll: false,
          loading: false,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addTimeData, client, data, dispatch, filter.projectIds, filter.timeRange]
  );

  useEffect(() => {
    if (isMounted) {
      clearData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, isMounted]);

  return { clearData, data, error, loading, updateProjectIds, loadedAll, loadAll };
}
