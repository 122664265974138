import { useApolloClient } from '@apollo/client';
import { PROJECT_WITH_GPS_QUERY } from 'apollo/queries/project-queries';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { useActiveMember } from 'hooks';
import { first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import RequireTimeEntryGps from 'types/enum/RequireTimeEntryGps';
import TimeLocationRequired from 'types/enum/TimeLocationRequired';
import IProject from 'types/Project';
import { combineDateAndTime, dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { projectHasGpsRequirement, rootProjectHasGpsRequirement } from 'utils/projectUtils';

export default function useTimeActionsValidations() {
  const today = DateTime.local().startOf('day');
  const client = useApolloClient();
  const activeMember = useActiveMember();
  const [hasLockDate, setHasLockDate] = useState<boolean>(false);
  const [hasConflictingEntries, setHasConflictingEntries] = useState<boolean>(false);
  const [hasSignatureViolation, setHasSignatureViolation] = useState<boolean>(false);
  const [hasGpsRestriction, setHasGpsRestriction] = useState<boolean>(false);
  const checkValidationsPayload = useRef<{
    lock: boolean;
    conflict: boolean;
    signature: boolean;
    gpsRestricted: boolean;
  }>({ lock: true, conflict: true, signature: true, gpsRestricted: true });
  const { inhibitsActionByLockDate, hasConflicts, hasSignatureConflicts } = useTimeActionsForm();

  async function inhibitsActions(formInfo: ITimeActionsFormData) {
    checkValidationsPayload.current.lock = true;
    if (formInfo.startDate.value) {
      setHasLockDate(
        await inhibitsActionByLockDate(
          formInfo.members,
          dateTimeFromISOWithoutZone(DateTime.fromJSDate(formInfo.startDate.value).toISO())
        )
      );
    } else {
      setHasLockDate(false);
    }
  }

  async function conflictWarning(formInfo: ITimeActionsFormData, ignoreTimeEntryIds: string[] | null = null) {
    checkValidationsPayload.current.conflict = true;
    if (formInfo.startDate.value && formInfo.timeRange?.startTime) {
      const start = combineDateAndTime(DateTime.fromJSDate(formInfo.startDate.value), formInfo.timeRange.startTime);
      const end =
        formInfo.endDate?.value && formInfo.timeRange?.endTime
          ? combineDateAndTime(DateTime.fromJSDate(formInfo.endDate.value), formInfo.timeRange.endTime)
          : today;
      setHasConflictingEntries(
        await hasConflicts(
          formInfo.members,
          {
            startTime: start,
            endTime: end,
          },
          ignoreTimeEntryIds
        )
      );
    } else {
      setHasConflictingEntries(false);
    }
  }

  async function signatureWarning(formInfo: ITimeActionsFormData) {
    checkValidationsPayload.current.signature = true;
    if (formInfo.startDate.value && formInfo.timeRange?.startTime) {
      const start = combineDateAndTime(DateTime.fromJSDate(formInfo.startDate.value), formInfo.timeRange.startTime);

      const end =
        formInfo.endDate?.value && formInfo.timeRange?.endTime
          ? combineDateAndTime(DateTime.fromJSDate(formInfo.endDate.value), formInfo.timeRange.endTime)
          : today;
      setHasSignatureViolation(
        await hasSignatureConflicts(formInfo.members, {
          startTime: start,
          endTime: end,
        })
      );
    } else {
      setHasSignatureViolation(false);
    }
  }

  const gpsRestriction = async (formInfo: ITimeActionsFormData) => {
    checkValidationsPayload.current.gpsRestricted = true;
    if (!isNil(formInfo.project)) {
      const results = await client.query<{ projects: IProject[] }>({
        query: PROJECT_WITH_GPS_QUERY,
        variables: {
          filter: {
            id: {
              equal: formInfo.project,
            },
          },
        },
      });
      const currentProject = first(results.data.projects);
      let projectOrRootHasGpsRequirement = !isNil(currentProject) && projectHasGpsRequirement(currentProject);

      if (!isNil(currentProject) && !projectOrRootHasGpsRequirement) {
        switch (rootProjectHasGpsRequirement(currentProject)) {
          case RequireTimeEntryGps.SELF_AND_CHILDREN:
            projectOrRootHasGpsRequirement = true;
            break;
        }
      }

      setHasGpsRestriction(
        projectOrRootHasGpsRequirement &&
          formInfo.members.includes(activeMember.id!) &&
          activeMember.timeLocationRequired !== TimeLocationRequired.GPS_OFF
      );
    } else {
      setHasGpsRestriction(false);
    }
  };

  return {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
    gpsRestriction,
    hasGpsRestriction,
  };
}
