import classNames from 'classnames';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { DashboardTheme } from 'containers/dashboard/types/types';
import { AnyObject } from 'types/util/Object';
import useActiveCardReportNavigation from '../../hooks/useActiveCardReportNavigation';
import ActiveMembersDashboardCardContent from '../ActiveMembersDashboardCardContent/ActiveMembersDashboardCardContent';
import { useActiveMemberMainDashboardData } from './hooks/useActiveMembersMainDashboardData';

export interface IActiveMembersMainDashboardCardProps {
  setting: IDashboardSettingsItem;
  theme: DashboardTheme;
  className?: string | AnyObject;
}

export default function ActiveMembersMainDashboardCard({
  setting,
  theme,
  className,
}: IActiveMembersMainDashboardCardProps) {
  const { execute, ...rest } = useActiveMemberMainDashboardData(setting);
  const activityReportNavigation = useActiveCardReportNavigation(setting.options?.time, 'by-employee');
  const classes = classNames('active-members-main-dashboard-card', className);

  return (
    <ActiveMembersDashboardCardContent
      className={classes}
      onReload={execute}
      theme={theme}
      onDetailClick={activityReportNavigation}
      {...rest}
    />
  );
}
