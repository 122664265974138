import { useApolloClient } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogHeader, Label, Loader, Theme, Toast, Tray } from '@busybusy/webapp-react-ui';
import { MEMBER_LOCK_QUERY } from 'apollo/queries/member-lock-queries';
import { MEMBER_TIME_DOCUMENT_QUERY } from 'apollo/queries/member-time-document-query';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import SignatureDialog from 'components/foundation/dialogs/SignatureDialog/SignatureDialog';
import { useActiveMember, useAxios, useOpenable, useOrganization, usePermissions, useTimesheetsGraylog } from 'hooks';
import useMemberLock from 'hooks/models/member-lock/useMemberLock';
import useMemberTimeDocument from 'hooks/models/member-time-document/useMemberTimeDocument';
import _, { isNil, isNull } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { IMember } from 'types';
import IAuthoritativeSignature from 'types/AuthoritativeSignature';
import IBusyApiWrapper from 'types/BusyApiWrapper';
import IMemberLock from 'types/MemberLock';
import IMemberTimeDocument from 'types/MemberTimeDocument';
import ISelfSignature from 'types/SelfSignature';
import ITimeRange from 'types/TimeRange';
import SignatureDialogReturnType from 'types/enum/SignatureDialogReturnType';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { dateTimeFromISOKeepZone, dateTimeFromISOWithoutZone, offsetInSeconds } from 'utils/dateUtils';
import LocalStore from 'utils/localStorageUtils';
import { t } from 'utils/localize';
import { populateOpenTimeEntryField } from 'utils/memberUtils';
import { logError } from 'utils/testUtils';
import './PayPeriodSignatures.scss';

export interface IPayPeriodSignaturesProps {
  className?: ClassName;
  member: IMember;
  timeRange: ITimeRange<DateTime>;
  onComplete?: (newLockDate?: DateTime) => void;
  forceReload?: boolean;
  onSignaturesLoaded?: (memberId: string, isLoaded: boolean) => void;
  payPeriodIsOpen?: boolean;
}

const PayPeriodSignatures = (props: IPayPeriodSignaturesProps) => {
  const { className, member, timeRange, onComplete, forceReload, onSignaturesLoaded, payPeriodIsOpen } = props;

  const errorToastState = useOpenable();
  const employeeSignatureState = useOpenable();
  const supervisorSignatureState = useOpenable();
  const loaderState = useOpenable();
  const openTimeCardWarningDialog = useOpenable();
  const openTimeEntryWarningDialog = useOpenable();
  const organization = useOrganization();
  const { hasPermissionToManage } = usePermissions();
  const activeMember = useActiveMember();
  const canManage = useRef<boolean>(
    activeMember.id === member.id
      ? hasPermissionToManage(member, 'manageTimeEntries')
      : hasPermissionToManage(member, 'timeEvents')
  );
  const axiosClient = useAxios();
  const client = useApolloClient();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const [memberTimeDocuments, setMemberTimeDocuments] = useState<IMemberTimeDocument[] | undefined>();
  const [memberTimeDocumentsLoaded, setMemberTimeDocumentsLoaded] = useState<boolean>(false);
  const { createMemberTimeDocument, updateMemberTimeDocument } = useMemberTimeDocument();
  const { createLock, editLock } = useMemberLock();
  const userEvents = useTimesheetsGraylog();
  const bypassOpenTimeCardWarning = LocalStore.get('bypassOpenTimeCardWarning') ?? false;
  const [localBypass, setLocalBypass] = useState<boolean>(false);
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const [isSupervisor, setIsSupervisor] = useState<boolean>(false);
  const classes = classNames(
    {
      'pay-period-signatures': true,
      'data-loaded': memberTimeDocumentsLoaded,
      'data-loading': !memberTimeDocumentsLoaded,
    },
    className
  );

  useEffect(() => {
    syncMemberTimeDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, member, forceReload]);

  async function syncMemberTimeDocuments() {
    setMemberTimeDocumentsLoaded(false);

    const results = await client
      .query<{
        memberTimeDocuments: IMemberTimeDocument[];
      }>({
        query: MEMBER_TIME_DOCUMENT_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
          filter: {
            startTime: { lessThanOrEqual: timeRange.endTime.endOf('day').toISO() },
            endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
            memberId: { equal: member.id },
            deletedOn: { isNull: true },
          },
          sort: [{ submittedOn: 'desc' }],
        },
      })
      .catch((err) => {
        showToastError(t('Failed to retrieve signatures'));
        return logError(err);
      });

    setMemberTimeDocumentsLoaded(true);

    setMemberTimeDocuments(results.data.memberTimeDocuments);
  }

  function employeeSignAction() {
    employeeSignatureState.open();
    userEvents.events(TimesheetsTypes.events.action_type.EMPLOYEE_SIGN);
  }

  function saveEmployeeSignature(urlData: any) {
    loaderState.open(); // show loader while we save

    axiosClient
      .post('self-signature', signatureParam(urlData))
      .then((result: AxiosResponse<IBusyApiWrapper<ISelfSignature>>) => {
        const createdSignature = result.data.data![0];
        setSignatureOnDocument(createdSignature.id, undefined, () => {
          // hide signature dialog and loader, saving is done
          employeeSignatureState.close();
          onFinishedSave();
        });
      })
      .catch(handleSignatureSaveFail);
  }

  function supervisorSignAction() {
    supervisorSignatureState.open();
    userEvents.events(TimesheetsTypes.events.action_type.SUPERVISOR_SIGN);
  }

  function saveSupervisorSignature(urlData: any) {
    loaderState.open(); // show loader while we save
    axiosClient
      .post('authoritative-signature', signatureParam(urlData))
      .then((result: AxiosResponse<IBusyApiWrapper<IAuthoritativeSignature>>) => {
        const createdSignature = result.data.data![0];
        setSignatureOnDocument(undefined, createdSignature.id, () => {
          // hide signature dialog and loader, saving is done
          supervisorSignatureState.close();
          onFinishedSave();
        });
      })
      .catch(handleSignatureSaveFail);
  }

  async function onFinishedSave() {
    loaderState.close();
    let updatedEffectiveDate: DateTime | undefined;

    if (organization.lockOnSelfSignature) {
      // check if a member lock object exists
      const results = await client.query<{ memberLocks: IMemberLock[] }>({
        query: MEMBER_LOCK_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          memberId: member.id,
        },
      });

      const existingLock = _.first(results.data.memberLocks);
      const newEffectiveDate = timeRange.endTime.endOf('day');
      if (existingLock) {
        // edit existing member lock object only if it's not set or the effective date is earlier than our end of pay period
        if (
          isNil(existingLock.effectiveDate) ||
          dateTimeFromISOKeepZone(existingLock.effectiveDate) < newEffectiveDate
        ) {
          await editLock(existingLock, newEffectiveDate);
          updatedEffectiveDate = newEffectiveDate;
        }
      } else {
        // create new member lock object
        await createLock(newEffectiveDate, member.id);
        updatedEffectiveDate = newEffectiveDate;
      }
    }

    if (onComplete) {
      onComplete(updatedEffectiveDate);
    }
  }

  function signatureParam(urlData: string): FormData {
    const params = new FormData();
    const decodedSignature = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n' + urlData;

    params.append('member_id', member.id);
    params.append('proxy_member_id', activeMember.id!);
    params.append('created_on', DateTime.utc().toSeconds().toFixed(0));
    params.append('created_on_dst', DateTime.local().isInDST.toString());
    params.append('created_on_offset', offsetInSeconds(DateTime.local()).toString());
    params.append('signature', new File([decodedSignature], 'signature.svg', { type: 'image/svg+xml' }));

    return params;
  }

  function setSignatureOnDocument(
    selfSignatureId: string | undefined,
    authoritativeSignatureId: string | undefined,
    onFinish: () => void
  ) {
    const performUpdate = (timeDocument: IMemberTimeDocument) => {
      updateMemberTimeDocument(timeDocument.id, selfSignatureId, authoritativeSignatureId).then(async () => {
        // once our time document is updated we need to update our list of time documents and we're finally done
        syncMemberTimeDocuments();
        onFinish();
      });
    };

    const latestTimeDocument = _.first(memberTimeDocuments);
    if (latestTimeDocument && !latestTimeDocument.canceled && !latestTimeDocument.edited) {
      // we can update the existing document
      performUpdate(latestTimeDocument);
    } else {
      // need to create new document
      createMemberTimeDocument(timeRange.startTime, timeRange.endTime, member.id)
        .then((timeDocumentResponse) => performUpdate(timeDocumentResponse.data!.createMemberTimeDocument))
        .catch(handleSignatureSaveFail);
    }
  }

  const memberHasOpenTimeEntry = () => {
    const memberWithTimeEntryField = populateOpenTimeEntryField([member]);

    return !isNil(memberWithTimeEntryField[0].openTimeEntry) && payPeriodIsOpen;
  };

  const resetBooleans = () => {
    setIsEmployee(false);
    setIsSupervisor(false);
  };

  const handleEmployeeSignClick = () => {
    if (memberHasOpenTimeEntry()) {
      openTimeEntryWarningDialog.open();
    } else {
      if (payPeriodIsOpen && !bypassOpenTimeCardWarning) {
        openTimeCardWarningDialog.open();
        setIsEmployee(true);
        setIsSupervisor(false);
      } else {
        employeeSignAction();
      }
    }
  };

  const handleEmployeeContinue = () => {
    employeeSignAction();
    handleWarningDialogClose();
  };

  const handleSupervisorSignClick = () => {
    if (memberHasOpenTimeEntry()) {
      openTimeEntryWarningDialog.open();
    } else {
      if (payPeriodIsOpen && !bypassOpenTimeCardWarning) {
        openTimeCardWarningDialog.open();
        setIsEmployee(false);
        setIsSupervisor(true);
      } else {
        supervisorSignAction();
      }
    }
  };

  const handleSupervisorContinue = () => {
    supervisorSignAction();
    handleWarningDialogClose();
  };

  const handleWarningDialogClose = () => {
    LocalStore.set('bypassOpenTimeCardWarning', localBypass);
    resetBooleans();
    openTimeCardWarningDialog.close();
  };

  function handleSignatureSaveFail() {
    showToastError(t('Failed to upload signature'));
    loaderState.close();
  }

  function showToastError(message: string) {
    errorToastMessage.current = message;
    errorToastState.open();
  }

  const employeeSignatureLoaded = useRef<boolean | null>(null);
  const supervisorSignatureLoaded = useRef<boolean | null>(null);

  useEffect(() => {
    if (!isNull(employeeSignatureLoaded.current) && !isNull(supervisorSignatureLoaded.current)) {
      onSignaturesLoaded?.(member.id, employeeSignatureLoaded.current && supervisorSignatureLoaded.current);
    } else if (!isNull(employeeSignatureLoaded.current) && isNull(supervisorSignatureLoaded.current)) {
      onSignaturesLoaded?.(member.id, employeeSignatureLoaded.current);
    } else if (isNull(employeeSignatureLoaded.current) && !isNull(supervisorSignatureLoaded.current)) {
      onSignaturesLoaded?.(member.id, supervisorSignatureLoaded.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSignaturesLoaded, employeeSignatureLoaded.current, supervisorSignatureLoaded.current]);

  function renderSignatures(): ReactNode {
    if (!organization.signatureDate) {
      return <></>; // pay period signatures is disabled
    }

    const signatureRequiredDate = dateTimeFromISOWithoutZone(organization.signatureDate!);
    const latestTimeDocument = _.first(memberTimeDocuments);
    const needsToResign = latestTimeDocument && (latestTimeDocument.canceled || latestTimeDocument.edited);
    let employeeSignUI: ReactNode | null = null;
    let supervisorSignUI: ReactNode | null = null;

    if (
      latestTimeDocument &&
      !needsToResign &&
      latestTimeDocument.selfSignature &&
      latestTimeDocument.selfSignature.signatureUrl
    ) {
      const signatureDate = dateTimeFromISOKeepZone(latestTimeDocument.selfSignature.createdOnLocal).toFormat(
        'M/d/yyyy'
      );

      employeeSignUI = (
        <div className="signature-with-date-layout">
          <img
            className="signature-image"
            src={latestTimeDocument.selfSignature.signatureUrl}
            alt="employee signature"
            onLoad={() => {
              employeeSignatureLoaded.current = true;
            }}
          />
          <Label className="date-layout">{signatureDate}</Label>
        </div>
      );
    } else if (signatureRequiredDate <= timeRange.startTime) {
      employeeSignUI = (
        <Button className="no-print" type="link" onClick={handleEmployeeSignClick}>
          {t('Click here to sign')}
        </Button>
      );
    }

    if (
      latestTimeDocument &&
      !needsToResign &&
      latestTimeDocument.authoritativeSignature &&
      latestTimeDocument.authoritativeSignature.signatureUrl
    ) {
      const signatureDate = dateTimeFromISOKeepZone(latestTimeDocument.authoritativeSignature.createdOnLocal).toFormat(
        'M/d/yyyy'
      );

      supervisorSignUI = (
        <div className="signature-with-date-layout">
          <img
            className="signature-image"
            src={latestTimeDocument.authoritativeSignature.signatureUrl}
            alt="supervisor signature"
            onLoad={() => {
              supervisorSignatureLoaded.current = true;
            }}
          />
          <Label className="date-layout">{signatureDate}</Label>
        </div>
      );
    } else if (
      signatureRequiredDate <= timeRange.startTime &&
      organization.authoritativeSignature === true &&
      canManage.current
    ) {
      supervisorSignUI = (
        <Button className="no-print" type="link" onClick={handleSupervisorSignClick}>
          {t('Click here to sign')}
        </Button>
      );
    }

    if (employeeSignUI) {
      return (
        <div className="signatures-footer">
          <div className="employee-signature-layout">
            <Label>{t('Employee')}</Label>

            <Label className="ml-4">x</Label>

            {employeeSignUI}
          </div>
          {supervisorSignUI && (
            <div className="supervisor-signature-layout">
              <Label>{t('Supervisor')}</Label>

              <Label className="ml-4">x</Label>

              {supervisorSignUI}
            </div>
          )}
        </div>
      );
    } else {
      return <></>; // pay period is before the signatures required date
    }
  }

  return (
    <div className={classes}>
      <SignatureDialog
        title={t('Supervisor Approval')}
        isOpen={supervisorSignatureState.isOpen}
        onClose={supervisorSignatureState.close}
        onSubmit={saveSupervisorSignature}
        returnType={SignatureDialogReturnType.SVG}
        member={activeMember as IMember}
      />

      <SignatureDialog
        title={t('Employee Signature')}
        subtitle={organization.disclaimer}
        isOpen={employeeSignatureState.isOpen}
        onClose={employeeSignatureState.close}
        onSubmit={saveEmployeeSignature}
        returnType={SignatureDialogReturnType.SVG}
        member={member}
      />

      <Dialog isOpen={openTimeCardWarningDialog.isOpen} onClose={handleWarningDialogClose}>
        <DialogHeader divider={false}>
          <h1>{t('Open Time Card')}</h1>
        </DialogHeader>
        <div className={'m-5'}>
          {t('This pay period is still in progress. ')}
          {t(
            'Only sign this time card if you are certain that no additional time will be worked during this pay period.'
          )}
          {t(
            'Any changes made to the time card after signing will invalidate your signature and require you to sign again.'
          )}
        </div>
        <div className={'m-5'}>
          <Tray>
            <Checkbox
              checked={localBypass}
              onChange={() => setLocalBypass(!localBypass)}
              label={t("Don't show again")}
            />
            <div className="ml-12">
              <Button type="secondary" className={'mx-2'} onClick={handleWarningDialogClose}>
                {t('Close')}
              </Button>
              <Button
                type="primary"
                onClick={isEmployee ? handleEmployeeContinue : isSupervisor ? handleSupervisorContinue : undefined}
              >
                {t('Continue')}
              </Button>
            </div>
          </Tray>
        </div>
      </Dialog>
      <Dialog isOpen={openTimeEntryWarningDialog.isOpen} onClose={openTimeEntryWarningDialog.close}>
        <DialogHeader divider={false}>
          <h1>{t('Clocked In')}</h1>
        </DialogHeader>
        <div className={'m-5'}>
          {t('This time card has a time entry that is still clocked in. Please clock out before signing.')}
        </div>
        <div className="m-5">
          <Button type="secondary" onClick={openTimeEntryWarningDialog.close}>
            {t('Close')}
          </Button>
        </div>
      </Dialog>

      <Loader isOpen={loaderState.isOpen} />

      <Toast isOpen={errorToastState.isOpen} onClose={errorToastState.close} theme={Theme.DANGER}>
        {errorToastMessage.current}
      </Toast>

      {renderSignatures()}
    </div>
  );
};

export default PayPeriodSignatures;
