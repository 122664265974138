import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionNullableFieldType,
  Equipment,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Member,
  Scalars,
} from '__generated__/graphql';
import { BASIC_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { IActivityReportEquipmentRowInfo } from 'containers/activity-reports/hooks/ActivityReportData';
import {
  MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, first, isEmpty, isNil, keyBy, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { combineGeneratedMetricWithTimeOff, getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  getMemberTimeOffMetricDictionary,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from '../../hooks/useMemberActivityPermission';
import { IMemberActivityTableRowInfo } from './useMemberActivity';

export interface IMemberEquipmentActivityTableRowInfo
  extends IMemberActivityTableRowInfo,
    IActivityReportEquipmentRowInfo {
  equipmentId: string | null;
}

interface MemberEquipmentMetricRow extends LaborMetricsInterface {
  memberId: Scalars['Uuid']['output'];
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useMemberActivityEquipmentDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const permission = useMemberActivityPermission();
  const canViewCost = useHasCostPermission();
  const nameFormatted = useEmployeeNameFormatter();
  const remainingItemRef = useRef<IMemberEquipmentActivityTableRowInfo>();
  const [data, setData] = useState<IMemberEquipmentActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'member',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_EQUIPMENT_METRIC_QUERY : MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IMemberEquipmentActivityTableRowInfo, key: keyof IMemberEquipmentActivityTableRowInfo) {
    if (key === 'member') {
      return item.member ? nameFormatted(item.member.firstName ?? '', item.member.lastName ?? '') : '';
    } else {
      return item[key];
    }
  }

  async function getEquipment(equipmentIds: string[]) {
    if (isEmpty(equipmentIds)) {
      return [];
    }

    return await getAll<Equipment & ICursorable>('equipment', {
      query: BASIC_EQUIPMENT_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: equipmentIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    const members = await getAll<Member & ICursorable>('members', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    // we need to get the time off metrics when showing members filtered by the "no project" (or other variant) item
    // because web services won't include it #suckitdrew
    // time off also needs to be included when we're not scoped to a certain item (project/cost code/equipment)
    let timeOffData: Dictionary<ITimeOffLaborMetrics> | undefined = undefined;
    if (isNil(filterId) || filterId === remainingDataItemId) {
      timeOffData = await getMemberTimeOffMetricDictionary(client, timeRange, canViewCost);
    }

    const equipmentLookup = await getEquipmentLookup(members);
    const tableRows = mapNotNull(members, (member) =>
      createTableRowInfo(equipmentLookup, member, timeOffData?.[member.id])
    );

    // calculate total time in order to show `Additional Time` item if the user doesn't have permission to see everyone
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );
    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        member: null,
        equipmentId: null,
        equipment: null,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: 0,
        ...remainingData,
      };
    } else {
      // don't show no equipment item when there is no time allocated to no equipment
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const getEquipmentLookup = async (data: Member[]) => {
    const metrics = data.flatMap((member) => laborMetrics(member));
    const equipmentIds = mapNotNil(metrics, (item) => item.equipmentId);
    const equipmentList = await getEquipment(uniq(equipmentIds));

    return keyBy(equipmentList, (equipment) => equipment.id);
  };

  const createTableRowInfo = (
    equipmentLookup: Dictionary<Equipment>,
    member: Member,
    timeOffData?: ITimeOffLaborMetrics
  ): IMemberEquipmentActivityTableRowInfo | null => {
    const metrics = laborMetrics(member);
    const hasTimeOff = !isNil(timeOffData) && !isNil(timeOffData.pto) && timeOffData.pto > 0;

    let noEquipmentRow: IMemberEquipmentActivityTableRowInfo | undefined = undefined;
    if (hasTimeOff || metrics.some((metric) => isNil(metric.equipmentId))) {
      // handle case where we have time off, but no `No Equipment` item to append totals to
      const noEquipment = first(metrics.filter((metric) => isNil(metric.equipmentId))) ?? emptyMetricRow(member.id);
      const metricTotal = getGeneratedMetricLaborTotals(
        combineGeneratedMetricWithTimeOff(noEquipment, timeOffData),
        filterIdWithDescendants === false
      );

      noEquipmentRow = {
        id: member.id + remainingDataItemId,
        equipmentId: remainingDataItemId,
        equipment: null,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: metricTotal.totalCost,
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    }

    const unsortedEquipmentRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.equipmentId)) {
        return null;
      }

      const equipment = equipmentLookup[metric.equipmentId];

      return {
        id: member.id + metric.equipmentId,
        equipmentId: metric.equipmentId,
        equipment: equipment ?? null,
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment?.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });
    const equipmentRows = sortByIgnoreCase(unsortedEquipmentRows, (row) => getEquipmentDisplay(row.equipment));

    if (isEmpty(equipmentRows) && noEquipmentRow === undefined) {
      return null;
    } else {
      const memberTotalMetrics = aggregateActivityRows(
        noEquipmentRow === undefined ? equipmentRows : [...equipmentRows, noEquipmentRow]
      );
      return {
        ...memberTotalMetrics,
        id: member.id,
        equipmentId: null,
        equipment: null,
        equipmentCost: sumBy(equipmentRows, (row) => row.equipmentCost),
        equipmentRate: 0,
        equipmentTotalCost: sumBy(equipmentRows, (row) => row.equipmentCost) + memberTotalMetrics.totalCost,
        member,
        detailRows: noEquipmentRow === undefined ? equipmentRows : [...equipmentRows, noEquipmentRow],
      };
    }
  };

  const emptyMetricRow = (memberId: string): MemberEquipmentMetricRow => {
    return {
      memberId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (member: Member): MemberEquipmentMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return member.memberProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return member.memberCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberEquipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
