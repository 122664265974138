import { useOrganization } from 'hooks';
import { useFeatureFlags } from 'utils/features';

export default function useBudgetsEnabled() {
  const workAccomplishedEnabled = useFeatureFlags('WORK_ACCOMPLISHED');

  const {
    trackBudgets,
    trackBudgetCost,
    trackCostCode,
    trackBudgetProgress,
    trackBudgetEquipmentHours,
    trackBudgetEquipmentCosts,
  } = useOrganization();
  return (
    !!trackBudgets ||
    !!trackBudgetCost ||
    (!!trackCostCode && !!trackBudgetProgress && workAccomplishedEnabled) ||
    !!trackBudgetEquipmentHours ||
    !!trackBudgetEquipmentCosts
  );
}
