import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVisibleTableColumn } from 'types/TableColumn';

export interface IStoreDailySignIn {
  tableColumns: IVisibleTableColumn[];
}

export const initialState: IStoreDailySignIn = {
  tableColumns: [],
};

export const slice = createSlice({
  name: 'dailySignIn',
  initialState,
  reducers: {
    updateDailySignInTableColumns: (state: IStoreDailySignIn, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.tableColumns = action.payload;

      return state;
    },
  },
});

export const { updateDailySignInTableColumns } = slice.actions;
export default slice.reducer;
