import { NO_EQUIPMENT_ID } from 'components/domain/equipment/EquipmentClockFormPickerFormField/EquipmentClockFormPickerFormField';
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import {
  convertQuickTimeTemplate,
  createQuickTimeTemplateAdapter,
} from 'containers/quick-time/hooks/useQuickTimeTemplates';
import { ITimeEntryTemplate, OffsetIsoTime } from 'containers/quick-time/types/types';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { Optional } from 'types/util/Optional';
import { nightShiftAdjustment } from 'utils/dateUtils';
import { getTotalTimeFromEntryRangeAndBreakRanges } from 'utils/timeEntryUtils';
import { uuid } from 'utils/uuidUtils';
import { ITimeActionsFormData } from '../hooks/useTimeActionsForm';

const adapter = createQuickTimeTemplateAdapter<ITimeActionsFormData>({
  membersKey: 'members',
  projectKey: 'project',
  costCodeKey: 'costCode',
  equipmentKey: 'equipment',
  startTimeKey: 'startTime',
  endTimeKey: 'endTime',
  descriptionKey: 'description',
  breakKey: 'breaks',
});

export function convertTemplateToTimeActionsFormData(
  template: ITimeEntryTemplate,
  formData: Pick<ITimeActionsFormData, 'members' | 'startDate' | 'endDate'>
): ITimeActionsFormData {
  const { startTime, endTime, members, breaks, ...converted } = convertQuickTimeTemplate(template, adapter);
  const membersIsDefined = !isNil(members) && members.length !== 0;
  const startDateTime = startTime ? DateTime.fromISO(startTime as unknown as string) : null;
  const endDateTime = endTime ? DateTime.fromISO(endTime as unknown as string) : null;

  const formStartDate = formData.startDate.value ? DateTime.fromJSDate(formData.startDate.value) : DateTime.local();

  const parsedBreaks: IBreakMultiPickerItem[] = breaks.map((brk: any) => {
    return { id: uuid(), timeRange: convertOffsetIsoTimeRangeToDateTimeTimeRange(brk, formStartDate), isOpen: false };
  });

  const stringedBreaks =
    template.breaks?.map((brk) => {
      const converted = convertOffsetIsoTimeRangeToDateTimeTimeRange(brk, formStartDate);
      return {
        startTime: converted.startTime.toISO(),
        endTime: converted.endTime.toISO(),
      };
    }) ?? [];

  const timeRange = {
    startTime: startDateTime,
    endTime: endDateTime,
  };

  const nightShifted =
    timeRange.startTime && timeRange.endTime ? nightShiftAdjustment(timeRange as ITimeRange<DateTime>) : null;

  return {
    ...converted,
    members: membersIsDefined ? members : formData.members,
    startDate: formData.startDate,
    endDate: formData.endDate,
    timeRange: timeRange,
    startTime: startDateTime,
    endTime: endDateTime,
    breaks: parsedBreaks,
    total:
      nightShifted?.startTime && nightShifted?.endTime
        ? getTotalTimeFromEntryRangeAndBreakRanges(
            {
              startTime: nightShifted?.startTime?.toISO(),
              endTime: nightShifted?.endTime?.toISO(),
            },
            stringedBreaks
          )
        : 0,
  };
}

export function convertBreakMultipickerItemsToQuickTimeTemplateBreaks(
  brk: IBreakMultiPickerItem,
  day: Optional<DateTime>
) {
  const startOffset = day ? brk.timeRange.startTime.startOf('day').toSeconds() - day.startOf('day').toSeconds() : 0;
  const endOffset = day ? brk.timeRange.endTime.startOf('day').toSeconds() - day.startOf('day').toSeconds() : 0;

  return {
    startTime: {
      time: brk.timeRange.startTime.toISOTime({ includeOffset: false, suppressMilliseconds: true }),
      offset: startOffset,
    },
    endTime: {
      time: brk.timeRange.endTime.toISOTime({ includeOffset: false, suppressMilliseconds: true }),
      offset: endOffset,
    },
  };
}

export function convertOffsetIsoTimeRangeToDateTimeTimeRange(
  offset: ITimeRange<OffsetIsoTime>,
  date: Optional<DateTime>
) {
  const startTime = getDateTimeFromOffsetIsoTime(offset.startTime, date);
  const endTime = getDateTimeFromOffsetIsoTime(offset.endTime, date);
  return { startTime, endTime };
}

export function getDateTimeFromOffsetIsoTime({ time, offset }: OffsetIsoTime, date: Optional<DateTime>) {
  const basisDate = date?.startOf('day') ?? DateTime.local().startOf('day');
  return DateTime.fromISO(time)
    .set({ day: basisDate.day, month: basisDate.month, year: basisDate.year })
    .plus({ seconds: offset });
}

export function convertTimeActionFormNoSelectionsToNull(data: ITimeActionsFormData) {
  return {
    ...data,
    equipment: getActualEquipmentIdFromTimeActionEquipment(data.equipment),
  };
}

export function getActualEquipmentIdFromTimeActionEquipment(equipmentId: Optional<string>) {
  return equipmentId !== NO_EQUIPMENT_ID ? equipmentId : null;
}
