import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/types';
import { IChildrenProps } from 'types/ChildrenProps';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import useMemberTimeEntryDataReportData, {
  IMemberTimeEntryReportDataFilter,
} from '../hooks/useMemberTimeEntryDataReportData';

export interface IMemberTimeEntryDataReportContextState extends ReturnType<typeof useMemberTimeEntryDataReportData> {
  timeRange: ITimeRange<DateTime>;
}

export const MemberTimeEntryDataReportContext = createContext<IMemberTimeEntryDataReportContextState | null>(null);

export function useMemberTimeEntryDataReportState() {
  const context = useContext(MemberTimeEntryDataReportContext);

  if (isNull(context)) {
    throw Error(
      '`useMemberTimeEntryDataReportState` must be nested within a `MemberTimeEntryDataReportContextProvider.`'
    );
  }

  return context!;
}

interface IMemberTimeEntryDataReportContextProviderProps extends IChildrenProps {
  scroller: HTMLElement | Document | null;
  filter: IMemberTimeEntryReportDataFilter;
}

export function MemberTimeEntryDataReportContextProvider({
  children,
  filter,
  scroller,
}: IMemberTimeEntryDataReportContextProviderProps) {
  const result = useMemberTimeEntryDataReportData(scroller, filter);
  const weeklyColumnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.weeklyColumns
  );
  const dailyColumnSettings = useSelector<IReduxState, IVisibleTableColumn[]>((state) => state.timesheet.dailyColumns);
  const value = useMemo(
    () => ({
      ...result,
      timeRange: filter.timeRange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result, filter.timeRange, weeklyColumnSettings, dailyColumnSettings]
  );

  return (
    <MemberTimeEntryDataReportContext.Provider value={value}>{children}</MemberTimeEntryDataReportContext.Provider>
  );
}
