import _, { isEmpty } from 'lodash';
import Papa, { ParseResult } from 'papaparse';
import ICsvParseResult from 'types/CsvParseResult';
import IIdable from 'types/Idable';
import { Optional } from 'types/util/Optional';
import { containsDuplicateOfGivenKey, mapNotNull } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import { createAndedString } from 'utils/stringUtils';
import { BaseImportError, IImportError } from '../types';

export function validateDataStructure(result: ParseResult<any>) {
  const objectKeys = result.data.length !== 0 ? Object.keys(_.first(result.data)) : null;

  if (objectKeys && result?.meta?.fields && result.meta.fields.length !== objectKeys.length) {
    return t('Number of header columns does not match the number of value columns.');
  }

  return null;
}

export function validateColumns(headers: string[], allowedHeaders: string[], requiredHeaders: string[]) {
  const filteredEmpty = headers.filter((c) => c.length !== 0);
  const invalidColumns = _.filter(
    filteredEmpty,
    (c) =>
      !_.find(allowedHeaders, (allowed) => _.toLower(c.replace(/\s+/g, '')) === _.toLower(allowed.replace(/\s+/g, '')))
  );
  const missingColumns = requiredHeaders.filter(
    (requiredColumn) =>
      !_.find(headers, (c) => _.toLower(requiredColumn.replace(/\s+/g, '')) === _.toLower(c.replace(/\s+/g, '')))
  );

  return { missingColumns, invalidColumns };
}

export function validateHeaders(headers: string[], allowedHeaders: string[], requiredHeaders: string[]) {
  const { missingColumns, invalidColumns } = validateColumns(headers, allowedHeaders, requiredHeaders);

  const missingErrors = missingColumns.map((missing) => `${t('Missing Required Column')}: ${missing}`);
  const invalidErrors = invalidColumns.map((invalid) => `${t('Invalid Column')}: ${invalid}`);

  return [...missingErrors, ...invalidErrors];
}

export function validateUUID(test: string) {
  const regexMatchUuidV4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  return regexMatchUuidV4.test(test);
}

export function parseCsvWithDefaults<T>(csv: File | string, onCsvComplete: (result: ICsvParseResult<T>) => void) {
  Papa.parse(csv, {
    header: true,
    transform: (row: string) => row.trim(),
    complete: onCsvComplete,
    skipEmptyLines: true,
  });
}

export function parseCsvForReimport<T>(csv: File | string, onCsvComplete: (result: ICsvParseResult<T>) => void) {
  Papa.parse(csv, {
    header: true,
    transform: (row: string) => row.trim(),
    transformHeader: (header: string) => (header === 'DATA KEY' ? 'id' : header),
    complete: onCsvComplete,
    skipEmptyLines: true,
  });
}

export function getErrorMessages<T extends string>(
  errors: Array<IImportError<T | BaseImportError>>,
  errorMap: Record<T | BaseImportError, string>
) {
  if (errors?.length) {
    const groupedErrors = _.groupBy(errors, (error) => error.type);

    return _.map(groupedErrors, (errs, k) => {
      const key = k as T | BaseImportError;
      if (key === 'EMPTY_CSV') {
        return errorMap[key] + '.';
      }

      const lineNumbers = createAndedString(
        errs.map((err) => _.toString(err.lineNumber)),
        ',',
        true,
        '.'
      );
      const lineTranslation = `${
        errs.length > 1 ? t('on the following lines') : t('on the following line')
      }: ${lineNumbers}`;
      return `${errorMap[key]} ${lineTranslation}`;
    });
  } else {
    return null;
  }
}

export function getCsvIdErrors(
  objects: Array<Partial<IIdable<Optional<string>>>>
): Array<IImportError<BaseImportError>> {
  return mapNotNull(objects, (obj, index) => {
    const lineNumber = index + 2;

    if (!obj.id || isEmpty(obj.id)) {
      return { type: 'MISSING_ID', lineNumber };
    } else if (!validateUUID(obj.id)) {
      return { type: 'INVALID_ID', lineNumber };
    } else if (containsDuplicateOfGivenKey(objects, 'id', obj.id)) {
      return { type: 'DUPLICATE_ID', lineNumber };
    } else {
      return null;
    }
  });
}
