import { ITableProps, Table } from '@busybusy/webapp-react-ui';
import TemplatedRender, {
  ITemplatedRenderProps,
} from 'components/foundation/state-templates/TemplatedRender/TemplatedRender';

export interface ITemplatedTableProps<RowType>
  extends Omit<ITableProps<RowType>, 'emptyTemplate' | 'isLoading'>,
    Omit<ITemplatedRenderProps, 'children' | 'isEmpty'> {}

function TemplatedTable<RowType extends object>({
  emptyTemplate,
  errorTemplate,
  isError,
  error,
  isLoading,
  data,
  loadingTemplate,
  ...tableProps
}: ITemplatedTableProps<RowType>) {
  return (
    <TemplatedRender
      emptyTemplate={emptyTemplate}
      error={error}
      isError={isError}
      isLoading={isLoading}
      errorTemplate={errorTemplate}
      loadingTemplate={loadingTemplate}
      isEmpty={data.length === 0}
    >
      <Table {...tableProps} data={data} isLoading={isLoading} emptyTemplate={emptyTemplate} />
    </TemplatedRender>
  );
}

TemplatedTable.defaultProps = {
  ...Table.defaultProps,
};

export default TemplatedTable;
