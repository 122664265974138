import { useApolloClient } from '@apollo/client';
import { Button, Label, Size, Theme } from '@busybusy/webapp-react-ui';
import { MEMBER_TIME_DOCUMENT_QUERY } from 'apollo/queries/member-time-document-query';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { Well } from 'components';
import TimeCardReportContextProvider from 'containers/timesheets/TimeCardReport/context/TimeCardReportContextProvider';
import TimeCardReportDialog from 'containers/timesheets/TimeCardReportDialog/TimeCardReportDialog';
import useTimesheetsColumns from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable, useOrganization, useTimeRange } from 'hooks';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { first, isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateSummaryAndTimeCardTableColumns, updateTimeEntryTableColumns } from 'store/timesheets/Timesheets';
import IMemberTimeDocument from 'types/MemberTimeDocument';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import { PayPeriodType } from 'types/enum/PayPeriodType';
import { dateTimeFromISOWithoutZone, dateTimeFromUtcISO, getDateString } from 'utils/dateUtils';
import { t } from 'utils/localize';
import {
  createBiweeklyPayPeriod,
  createMonthlyPayPeriod,
  createSemiMonthlyPayPeriod,
  createWeeklyPayPeriod,
  getAppropriatePayPeriodForTime,
} from 'utils/payPeriodUtils';
import './TimeCardReadyToSignWell.scss';

export type TimeCardReadyToSignType = 'my-status' | 'time-card-report';

export interface ITimeCardReadyToSignWellProps {
  className?: ClassName;
  memberId: string;
  type: TimeCardReadyToSignType;
  timeRange?: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  forceReload?: boolean;
}

const TimeCardReadyToSignWell = (props: ITimeCardReadyToSignWellProps) => {
  const { className, memberId, type, timeRange, forceReload, timeRangeType } = props;

  const organization = useOrganization();
  const client = useApolloClient();
  const [memberTimeDocuments, setMemberTimeDocuments] = useState<IMemberTimeDocument[] | undefined>();
  const payPeriodRange = useTimeRange(TimeRangeType.PAY_PERIOD, null, null, null, 'utc');
  const currentPayPeriodStartTime = useMemo<DateTime>(() => payPeriodRange.timeRange.startTime, []);
  const lastClosedPayPeriodRange = useMemo<ITimeRange<DateTime>>(() => {
    const time = currentPayPeriodStartTime.minus({ seconds: 1 }).toSeconds();

    const pair = getAppropriatePayPeriodForTime(organization.organizationPayPeriod!, time, 'utc')!; // Subtract one for the previous pay period
    const date = DateTime.fromSeconds(time, { zone: 'utc' });
    let newRange: ITimeRange<DateTime>;
    switch (pair.current.payPeriodType) {
      case PayPeriodType.WEEKLY:
        newRange = createWeeklyPayPeriod(pair.current, date);
        break;
      case PayPeriodType.BIWEEKLY:
        newRange = createBiweeklyPayPeriod(pair.current, date, 'utc');
        break;
      case PayPeriodType.SEMIMONTHLY:
        newRange = createSemiMonthlyPayPeriod(pair.current, date);
        break;
      case PayPeriodType.MONTHLY:
        newRange = createMonthlyPayPeriod(pair.current, date);
        break;
    }

    if (newRange.startTime < dateTimeFromUtcISO(pair.current.startDate)) {
      newRange.startTime = dateTimeFromUtcISO(pair.current.startDate);
    }
    if (!isNil(pair.next) && dateTimeFromUtcISO(pair.next.startDate) < newRange.endTime) {
      newRange.endTime = dateTimeFromUtcISO(pair.next.startDate).minus({ seconds: 1 });
    }

    return newRange;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const timeCardDetails = useOpenable();
  const completionToast = useToastOpen();

  const { summaryColumns, timeEntryColumns } = useTimesheetsColumns();
  const dispatch = useDispatch();

  useOnMount(() => {
    // update redux with our stored or default columns when first loading the component
    dispatch(updateSummaryAndTimeCardTableColumns(summaryColumns));
    dispatch(updateTimeEntryTableColumns(timeEntryColumns));
  });

  useEffect(() => {
    syncMemberTimeDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId, lastClosedPayPeriodRange, timeRange, forceReload]);

  async function syncMemberTimeDocuments() {
    setIsLoading(true);
    const startTime = timeRange?.startTime ?? lastClosedPayPeriodRange.startTime;
    const endTime = timeRange?.endTime ?? lastClosedPayPeriodRange.endTime;

    const results = await client.query<{
      memberTimeDocuments: IMemberTimeDocument[];
    }>({
      query: MEMBER_TIME_DOCUMENT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 1,
        filter: {
          startTime: { lessThanOrEqual: endTime.endOf('day').toISO() },
          endTime: { greaterThanOrEqual: startTime.toISO() },
          memberId: { equal: memberId },
          deletedOn: { isNull: true },
        },
        sort: [{ submittedOn: 'desc' }],
      },
    });

    setMemberTimeDocuments(isEmpty(results.data.memberTimeDocuments) ? undefined : results.data.memberTimeDocuments);
    setIsLoading(false);
  }

  function renderUI() {
    if (isLoading || isNil(organization.signatureDate) || timeRangeType !== TimeRangeType.PAY_PERIOD) {
      return <></>;
    }

    const startTime = timeRange?.startTime ?? lastClosedPayPeriodRange.startTime;
    const endTime = timeRange?.endTime ?? lastClosedPayPeriodRange.endTime;

    if (startTime.toSeconds() === currentPayPeriodStartTime.toSeconds()) {
      return <></>; // Pay period signatures is disabled for current pay period.
    }

    const signatureRequiredDate = dateTimeFromISOWithoutZone(organization.signatureDate!);
    const latestTimeDocument = first(memberTimeDocuments);
    const needsToResign = latestTimeDocument && (latestTimeDocument.canceled || latestTimeDocument.edited);

    if (
      latestTimeDocument &&
      !needsToResign &&
      latestTimeDocument.selfSignature &&
      latestTimeDocument.selfSignature.signatureUrl
    ) {
      return <></>; // Doesn't need to sign.
    } else if (signatureRequiredDate <= startTime) {
      switch (type) {
        case 'my-status':
          return (
            <Well theme={Theme.PRIMARY} className={classes}>
              <div className="well-contents">
                <div>
                  <Label className="title-label">{t('Time Card Ready to Sign')}</Label>
                  <span className="fw-regular">
                    <Trans values={{ date: getDateString(endTime, 'EEEE, LLL d', true) }}>
                      {'Your time card for the pay period ending on {{ date }} is ready for you to sign.'}
                    </Trans>
                  </span>
                </div>
                <Button type="primary" onClick={timeCardDetails.open} size={Size.LARGE} className="right-contents">
                  {t('Review & Sign')}
                </Button>
              </div>
            </Well>
          );
        case 'time-card-report':
          return (
            <Well theme={Theme.PRIMARY} className={classes}>
              <div className="well-contents">
                <div>
                  <Label className="title-label">{t('Ready to Sign')}</Label>
                  <span className="fw-regular">
                    {t(
                      'After verifying that your time card information is correct, and you are ready to approve it, click the "Click here to sign" link at the bottom of the time card.'
                    )}
                  </span>
                </div>
              </div>
            </Well>
          );
        default:
          return <></>;
      }
    }
  }

  function onCompletion() {
    timeCardDetails.close();
    syncMemberTimeDocuments();
    completionToast({ label: t('Signature Complete!') });
  }

  const classes = classNames('time-card-ready-to-sign-well mb-4 m-3', className);

  return (
    <>
      {renderUI()}
      <TimeCardReportContextProvider
        timeRangeType={TimeRangeType.PAY_PERIOD}
        memberId={memberId}
        timeRange={timeRange ?? lastClosedPayPeriodRange}
        permission={MemberPermission.TIME_EVENTS}
        archivedStatus="unarchived"
      >
        <TimeCardReportDialog
          isOpen={timeCardDetails.isOpen}
          onClose={timeCardDetails.close}
          timeRange={timeRange ?? lastClosedPayPeriodRange}
          memberIds={useMemo(() => [memberId], [memberId])}
          timeRangeType={TimeRangeType.PAY_PERIOD}
          onDataChange={syncMemberTimeDocuments}
          onSignatureComplete={onCompletion}
        />
      </TimeCardReportContextProvider>
    </>
  );
};

TimeCardReadyToSignWell.defaultProps = {
  type: 'my-status',
};

export default TimeCardReadyToSignWell;
