import { useApolloClient } from '@apollo/client';
import { POSITION_QUERY } from 'apollo/queries/position-queries';
import { first } from 'lodash';
import IPosition from 'types/Position';

export default function useGetPosition() {
  const client = useApolloClient();
  async function getPosition(id: string, query: any = POSITION_QUERY): Promise<IPosition | undefined> {
    const results = await client.query<{ positions: IPosition[] }>({
      query,
      variables: {
        filter: {
          id: { equal: id },
        },
      },
      fetchPolicy: 'network-only',
    });
    return first(results.data.positions);
  }
  return { getPosition };
}
