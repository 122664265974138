import { Button, Checkbox, Label, Row, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import useSlackPremiumAccess from 'containers/settings/integrations/vendors/slack/hooks/useSlackPremiumAccess';
import { useToastDispatch } from 'contexts/ToastContext';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useOrganizationUiSettingsUpdate from 'hooks/models/organization/settings/hooks/useOrganizationUiSettingsUpdate';
import { FunctionComponent, useState } from 'react';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import './EnablePremiumForm.scss';

export interface IEnablePremiumFormProps {
  onRequestSubmitted: () => void;
  className?: ClassName;
}

export const EnablePremiumForm: FunctionComponent<IEnablePremiumFormProps> = (props) => {
  const { className, onRequestSubmitted } = props;
  const toastDispatch = useToastDispatch();
  const [agreeToCharge, setAgreeToCharge] = useState(false);
  const [showErrorTip, setShowErrorTip] = useState(false);
  const premiumAccess = useSlackPremiumAccess();
  const updateUiSettings = useOrganizationUiSettingsUpdate();
  const brand = useBrandTitle();

  const classes = classNames('enable-premium-form', className);

  const requestAccess = async () => {
    let requestSuccessful = false;

    try {
      const response = await premiumAccess();
      requestSuccessful = response.status === 200;
    } catch (e) {
      requestSuccessful = false;
    }

    if (requestSuccessful) {
      updateUiSettings('requestedDocumentsAccess', true);
      onRequestSubmitted();
    } else {
      toastDispatch({
        type: 'OPEN',
        payload: { label: t('Failed to send request. Try again later.'), theme: Theme.DANGER },
      });
      setShowErrorTip(true);
    }
  };

  const customerSupport = () => {
    (window as any).Intercom('show');
  };

  return (
    <div className={classes}>
      <span className="content">
        {t('Gain access to all our Premium features, bundled together in one low price!')}
        <br></br>
        {t('Premium includes:')}
        <ul>
          <li>{t('Documents')}</li>
          <li>{t('Field Reports with Checklists')}</li>
          <li>{t('Progress Tracking')}</li>
          <li>{t('API Keys for Zapier Integration')}</li>
          <li>{t('Bulletin')}</li>
        </ul>
      </span>

      <Label className="mt-7 mb-2">{t('Getting Started')}</Label>
      {t(
        'Simply agree to the additional charge, then press on the Request Access button below. The activation process usually takes less than 24 hours. We will send you a message to notify you when the feature has been enabled.'
      )}

      <Label className="mt-7 mb-2">{t('Pricing')}</Label>
      {t('Additional $5 per active {{brand}} user per month', { brand })}

      <Checkbox
        className="mt-6"
        type="basic"
        label={t(
          `I understand that by purchasing the premium bundle, an additional charge of $5 per employee will be charged to my card each month.`
        )}
        checked={agreeToCharge}
        onChange={setAgreeToCharge}
      />

      <Row className="mt-9">
        <Button type="primary" onClick={requestAccess} disabled={!agreeToCharge}>
          {t('Request Access')}
        </Button>

        <Button className="ml-3" type="secondary" onClick={customerSupport}>
          {t('Customer Support')}
        </Button>
      </Row>

      <ConditionalContainer condition={showErrorTip}>
        <br />
        {t('* If you have an ad blocker, disable it and try again.')}
      </ConditionalContainer>
    </div>
  );
};

export default EnablePremiumForm;
