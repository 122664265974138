import { FetchPolicy, useApolloClient } from '@apollo/client';
import { Bar, Position, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useDebounce } from 'hooks';
import _, { isNil } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import ICursorable from 'types/Cursorable';
import IIdable from 'types/Idable';
import { stringUtils } from 'utils';
import LazyLoadPicker from '../../../foundation/pickers/LazyLoadPicker/LazyLoadPicker';
import { equipmentModelTitlesQuery } from './equipment-models-picker-queries';

export interface IEquipmentModelPickerProps {
  value: string | null;
  equipmentCategoryId: string | null | undefined;
  equipmentMakeId: string | null | undefined;
  onSelect: (makeId: string | null) => void;
  minWidth?: string;
  placeholder?: string;
  error?: boolean;
  position?: Position;
  fetchPolicy?: FetchPolicy;
  className?: ClassName;
  dropDownButtonRender?: ReactNode; // Drop down button on the picker
  closeButtonRender?: (handleClear: (event: React.KeyboardEvent | React.MouseEvent) => void) => ReactNode; // Close button render on picker
}

export interface IEquipmentModelTitle extends IIdable<string>, ICursorable {
  title: string;
}

const EquipmentModelForMakePicker = (props: IEquipmentModelPickerProps) => {
  const {
    value,
    equipmentCategoryId,
    equipmentMakeId,
    onSelect,
    minWidth,
    error,
    placeholder,
    position,
    fetchPolicy,
    closeButtonRender,
    dropDownButtonRender,
    className,
  } = props;

  const client = useApolloClient();

  const [pickerData, setPickerData] = useState<IEquipmentModelTitle[]>([]);
  const searchablePickerData = useRef<IEquipmentModelTitle[]>();
  const [loadedAll, setLoadedAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchDebounce = useDebounce(searchValue, 250);
  const [apolloError, setApolloError] = useState(false);
  const firstRender = useRef(true);

  // update list when searching
  useEffect(() => {
    if (searchDebounce && searchablePickerData.current) {
      // filter loaded results when we have search text and a result from our query
      setPickerData(
        searchablePickerData.current.filter((item) =>
          _.includes(item.title.toLowerCase(), searchDebounce.toLowerCase())
        )
      );
    } else if (searchablePickerData.current) {
      // search was cleared so show all items again
      setPickerData(searchablePickerData.current);
    }
  }, [searchDebounce]);

  // clear list and selected model when the make is changed
  useEffect(() => {
    searchablePickerData.current = undefined;
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setPickerData([]);
    setLoadedAll(false);
    onSelect(null);
  }, [equipmentMakeId]);

  function handleDidLoad(models: IEquipmentModelTitle[], err: boolean, allLoaded: boolean) {
    if (!err) {
      setLoadedAll(allLoaded);
      setPickerData(models);
    }

    setApolloError(err);
  }

  function onSearch(val: string) {
    setSearchValue(val);
  }

  function onClose() {
    setLoadedAll(false);
    setPickerData([]);
    setSearchValue('');
  }

  function handleSelect(model: IEquipmentModelTitle | null) {
    onSelect(model?.title ?? null);
  }

  async function getModels(after?: string): Promise<IEquipmentModelTitle[]> {
    return new Promise((resolve, reject) => {
      if (isNil(equipmentMakeId) || isNil(equipmentCategoryId)) {
        return resolve([]);
      }
      if (!after) {
        client
          .query({
            fetchPolicy: fetchPolicy || 'cache-first',
            query: equipmentModelTitlesQuery,
            variables: {
              makeId: equipmentMakeId,
              categoryId: equipmentCategoryId,
            },
          })
          .then((result: { data: { equipmentModelTitles: string[] } }) => {
            searchablePickerData.current = result.data.equipmentModelTitles.map((title) => {
              return {
                id: title,
                title,
                cursor: 'cursor',
              };
            });
            resolve(searchablePickerData.current);
          })
          .catch(() => {
            reject();
          });
      } else {
        resolve([]); // data already loaded, we're not paging
      }
    });
  }

  function renderValueTemplate(title: string | null) {
    if (title) {
      return <>{title}</>;
    }

    return <></>;
  }

  function renderRow(model: IEquipmentModelTitle) {
    return (
      <Bar size={Size.SMALL} className="px-3">
        <div className="ellipsis">{searchValue ? stringUtils.highlight(model.title, searchValue) : model.title}</div>
      </Bar>
    );
  }

  const classes = classNames('equipment-model-picker', className);

  return (
    <LazyLoadPicker
      value={value}
      disabled={_.isNil(equipmentMakeId)}
      getData={getModels}
      didLoad={handleDidLoad}
      error={error}
      dataError={apolloError}
      data={pickerData}
      loadedAll={loadedAll}
      onSelect={handleSelect}
      onClose={onClose}
      valueTemplate={renderValueTemplate}
      renderRow={renderRow}
      minWidth={minWidth}
      position={position}
      searchValue={searchValue}
      onSearch={onSearch}
      placeholder={placeholder}
      closeButtonRender={closeButtonRender}
      dropDownButtonRender={dropDownButtonRender}
      className={classes}
    />
  );
};

EquipmentModelForMakePicker.defaultProps = {
  minWidth: '350px',
};

export default EquipmentModelForMakePicker;
