import { ClassName } from "@busybusy/webapp-react-ui";
import classNames from "classnames";
import { HeaderDialog } from "components";
import EmployeeDetailsForm, { IEmployeeDetailsResult } from "../EmployeeDetailsForm/EmployeeDetailsForm";
import { t } from "i18next";

interface IEmployeeDetailsDialogProps {
    className?: ClassName;
    isOpen: boolean;
    onClose?: () => void;
    incidentId: string;
    handleSubmit?: (result: IEmployeeDetailsResult) => void;
}

const EmployeeDetailsDialog = (props: IEmployeeDetailsDialogProps) => {
    const { className, isOpen, incidentId, handleSubmit, onClose } = props;

    const classes = classNames('employee-details-dialog px-6', className);

    return (
        <HeaderDialog
            isOpen={isOpen}
            onClose={onClose}
            title={t('Employee Details')}
            divider={false}
        >
            <EmployeeDetailsForm incidentId={incidentId} handleSubmit={handleSubmit} className={classes}/>
        </HeaderDialog>
    )
}

export default EmployeeDetailsDialog;