import _ from 'lodash';
import { useCallback } from 'react';
import { emptyObject } from 'types/util/Empty';
import useCurrencyNumberFormatter from './useCurrencyNumberFormatter';

export default function useCurrencyFormatter(
  defaultCode: string | null = null,
  options: Intl.NumberFormatOptions = emptyObject
) {
  const formatter = useCurrencyNumberFormatter(defaultCode, options);

  return useCallback(
    (value: string | number) => {
      if (formatter) {
        if (typeof value === 'string') {
          if (value.length === 0) {
            return null;
          } else {
            return formatter.format(_.toNumber(value));
          }
        } else {
          return formatter.format(value);
        }
      } else {
        return null;
      }
    },
    [formatter]
  );
}
