import { ISortPayload, SortDirection } from '@busybusy/webapp-react-ui';
import { DateTime } from 'luxon';
import { createContext } from 'react';
import { IMember } from 'types';
import ITimeRange from 'types/TimeRange';

export interface IEmployeeSummaryDataContext {
  data: IEmployeeSummaryRowInfo[];
  loadedAll: boolean;
  sortBy: keyof IEmployeeSummaryRowInfo;
  sortDir: SortDirection;
  sortIsDirty: boolean;
  getMoreMembers: (
    timeRange: ITimeRange<DateTime>,
    first: number,
    cursor?: string
  ) => Promise<IEmployeeSummaryRowInfo[]>;
  onCheckAll: (checked: boolean) => Promise<void>;
  handleSort: (sort: ISortPayload<IMember>) => void;
  forceLoadAll: () => Promise<IEmployeeSummaryRowInfo[]>;
  didLoad: (updatedMembers: IEmployeeSummaryRowInfo[], err: boolean, updatedLoadedAll: boolean) => void;
  refreshDataForMembers: (members: string[]) => Promise<void>;
  clearData: () => void;
}

export interface IEmployeeSummaryRowInfo {
  id: string;
  member: IMember;
  payPeriodEmployeeSigned: boolean | null;
  payPeriodSupervisorSigned: boolean | null;
  timeAccurate: boolean | null;
  injured: boolean | null;
  breakPolicyFollowed: boolean | null;
  regularSeconds: number | null;
  overtimeSeconds: number | null;
  doubletimeSeconds: number | null;
  ptoSeconds: number | null;
  breakSeconds: number | null;
  totalSeconds: number | null;
  flaggedSignInAmount: number | null;
  flaggedSignOffAmount: number | null;
  cursor?: string;
}

const EmployeeSummaryDataContext = createContext({} as IEmployeeSummaryDataContext);

export default EmployeeSummaryDataContext;
