import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';
import './PanelContent.scss';

export interface IPanelContentProps
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'> {
  flex: boolean;
  scroll: boolean;
  clear: boolean;
  className?: ClassName;
  forwardRef?: (el: HTMLDivElement) => void;
  children: ReactNode;
}

function PanelContent(props: IPanelContentProps) {
  const { flex, scroll, clear, forwardRef, className, children } = props;

  function setRefs(el: HTMLDivElement) {
    if (forwardRef) {
      forwardRef(el);
    }
  }

  const classes = classNames(
    {
      'panel-content': true,
      'panel-content-flex': flex,
      'panel-content-scroll': scroll,
      'panel-opaque': clear === false,
    },
    className
  );

  return (
    <div className={classes} ref={setRefs}>
      {children}
    </div>
  );
}

PanelContent.defaultProps = {
  clear: true,
  flex: true,
  scroll: true,
};

export default PanelContent;
