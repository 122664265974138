import { useApolloClient } from '@apollo/client';
import { Theme, Toast } from '@busybusy/webapp-react-ui';
import { SIMPLE_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import HourMeterReportTable from 'components/domain/hour-meter/HourMeterReportTable/HourMeterReportTable';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import { first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import IEquipment from 'types/Equipment';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { t } from 'utils/localize';

export interface IHourMeterReportDialogProps {
  className?: ClassName;
  equipmentId: string;
  isOpen: boolean;
  onClose: () => void;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
}

const HourMeterReportDialog = (props: IHourMeterReportDialogProps) => {
  const { className, equipmentId, isOpen, onClose, timeRange, timeRangeType } = props;

  const client = useApolloClient();
  const [equipment, setEquipment] = useState<IEquipment | undefined>(undefined);
  const [errorToastMessage, setErrorToastMessage] = useState('');

  useEffect(() => {
    getData();
  }, [equipmentId]);

  async function getData() {
    const results = await client.query<{ equipment: IEquipment[] }>({
      query: SIMPLE_EQUIPMENT_QUERY,
      variables: { first: 1, filter: { id: { equal: equipmentId } } },
      fetchPolicy: 'network-only',
    });
    if (!isNil(results.errors)) {
      setErrorToastMessage(t('Server Error'));
      setEquipment(undefined);
      return;
    }
    setEquipment(first(results.data.equipment));
  }

  function renderHourMeterReport(range: ITimeRange<DateTime>): ReactNode {
    return (
      <>
        {equipment ? (
          <HourMeterReportTable equipment={equipment} timeRange={range} timeRangeType={timeRangeType} />
        ) : (
          <></>
        )}
      </>
    );
  }

  function handleErrorToastClose() {
    setErrorToastMessage('');
  }

  const classes = classNames('hour-meter-report-dialog', className);

  return (
    <>
      <ManagedTimeRangeReportDialog
        className={classes}
        isOpen={isOpen}
        onClose={onClose}
        timeRange={timeRange}
        timeRangeType={timeRangeType}
        reportComponent={renderHourMeterReport}
        title={t('Hour Meter')}
      />
      <Toast isOpen={errorToastMessage.length !== 0} onClose={handleErrorToastClose} theme={Theme.DANGER}>
        {errorToastMessage}
      </Toast>
    </>
  );
};

export default HourMeterReportDialog;
