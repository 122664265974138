import { Icon, Label, Tooltip } from '@busybusy/webapp-react-ui';
import { InfoIcon } from 'assets/icons';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import TimeRoundingType from 'types/enum/TimeRoundingType';
import IOrganization from 'types/Organization';
import ITimeEntry from 'types/TimeEntry';
import { timeEntryBreakUtils } from 'utils';
import { dateTimeFromISOWithoutZone, secondsToHmmString } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getTotal, totalForDay } from 'utils/timeEntryUtils';

export function renderTimersColumn(
  openTimeEntry: ITimeEntry | undefined | null,
  organization: Partial<IOrganization>,
  infoIconRight: boolean = false
) {
  if (openTimeEntry) {
    let openEntrySeconds = 0;
    const openBreak = _.find(openTimeEntry.breaks, (breakEntry) => breakEntry.endTime === null);

    if (openBreak) {
      openEntrySeconds = timeEntryBreakUtils.getBreakTotal(openBreak, openTimeEntry);
    } else {
      openEntrySeconds = getTotal(openTimeEntry);
    }

    let infoTip: ReactNode = null;

    if (openEntrySeconds <= 0 && organization.timeRounding && organization.timeRoundingInterval) {
      const roundingMinutes = organization.timeRoundingInterval / 60;
      let roundingDirectionText: string = '';
      switch (organization.timeRoundingType) {
        case TimeRoundingType.ROUND_DOWN:
          roundingDirectionText = 'down';
          break;
        case TimeRoundingType.ROUND_UP:
          roundingDirectionText = 'up';
          break;
        case TimeRoundingType.NEAREST:
          roundingDirectionText = '';
          break;
        case TimeRoundingType.EMPLOYEE_FAVORABLE:
          roundingDirectionText = 'down';
          break;
      }

      const infoHint = (
        <Trans
          values={{
            roundingDirectionText: t(roundingDirectionText),
            roundingMinutes: roundingMinutes,
            newlines: '\n\n',
            startTime: dateTimeFromISOWithoutZone(openBreak ? openBreak?.startTime : openTimeEntry.startTime).toFormat(
              'h:mm a'
            ),
          }}
        >
          {
            'Employee time for your company is set to round {{roundingDirectionText}} to the nearest {{roundingMinutes}} minute interval.{{newlines}}Timer will begin at {{startTime}}'
          }
        </Trans>
      );

      infoTip = (
        <Tooltip label={''} labelNode={infoHint}>
          <span className="timers-icon">
            <Icon svg={InfoIcon} />
          </span>
        </Tooltip>
      );
    }

    return (
      <div className="timers-cell" data-testid="timer">
        {!infoIconRight && infoTip}
        <Label>{secondsToHmmString(openEntrySeconds)}</Label>
        {infoIconRight && infoTip}
      </div>
    );
  } else {
    return (
      <div className="timers-cell" data-testid="timer">
        <Label>---</Label>
      </div>
    );
  }
}

export function renderTodayColumn(openTimeEntry: ITimeEntry | undefined | null, timeEntries: ITimeEntry[]) {
  let todayTotal = '---';
  if (openTimeEntry || !_.isEmpty(timeEntries)) {
    const openEntrySeconds = openTimeEntry ? getTotal(openTimeEntry) : 0;
    const closedEntriesForToday = _.sumBy(timeEntries, (entry) => totalForDay(DateTime.local(), entry));
    todayTotal = secondsToHmmString(openEntrySeconds + closedEntriesForToday);
  }

  return (
    <div data-testid="today">
      <Label>{todayTotal}</Label>
    </div>
  );
}

export function renderProjectColumn(timeEntry: ITimeEntry | undefined | null) {
  if (timeEntry && timeEntry.project) {
    return <Label>{getFormattedPathFromProject(timeEntry.project, true)}</Label>;
  } else {
    return <Label>---</Label>;
  }
}

export function renderCostCodeColumn(timeEntry: ITimeEntry | undefined | null) {
  const costCode = timeEntry?.costCode;
  if (costCode) {
    return (
      <div>
        <Label className="truncate p-0 m-0">{costCode.costCode}</Label>
        <div className="truncate p-0 m-0">{costCode.title ? costCode.title : ''}</div>
      </div>
    );
  } else {
    return <Label>---</Label>;
  }
}

// TODO: update employee table to use this
export function renderDescriptionColumn(timeEntry: ITimeEntry | undefined | null) {
  const description = timeEntry?.description;
  return <Label className="description-label">{!_.isEmpty(description) ? description : '---'}</Label>;
}

export function renderEmployeeColumn(timeEntry: ITimeEntry | undefined | null) {
  if (timeEntry?.member) {
    const { firstName, lastName } = timeEntry.member;
    return (
      <Label>
        <FormattedEmployeeName firstName={firstName ?? ''} lastName={lastName ?? ''} />
      </Label>
    );
  } else {
    return <Label className="description-label">---</Label>;
  }
}

export default {
  renderTimersColumn,
  renderTodayColumn,
  renderProjectColumn,
  renderCostCodeColumn,
  renderDescriptionColumn,
  renderEmployeeColumn,
};
