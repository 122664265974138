import { Theme } from '@busybusy/webapp-react-ui';
import { PhotoIcon } from 'assets/icons';
import classNames from 'classnames';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardCardBarChart from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/DashboardBarChart';
import { dashboardCardBarChartGradientBlueLightPurple } from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/constants/constants';
import MediaEntryThumbnailTray from 'components/domain/media-entry/MediaEntryThumbnailTray/MediaEntryThumbnailTray';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { convertDashboardTimeFrameToTimeRangeType, getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { getDashboardNavigationRange } from 'containers/scheduling/utils/utils';
import { usePhotosNavigation } from 'hooks';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { ClassName } from 'types/ClassName';
import { useFeatureFlags } from 'utils/features';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import DashboardProUpgradeCard from '../DashboardProUpgradeCard/DashboardProUpgradeCard';
import useDashboardCardTotal from '../hooks/useDashboardCardTotal';
import DailyPhotoAndNotesDashboardCardFooter from './DailyPhotoAndNotesDashboardCardFooter/DailyPhotoAndNotesDashboardCardFooter';
import useDashboardPhotos from './hooks/useDashboardPhotos';

export interface IPhotosAndNotesDashboardCardProps {
  memberId?: string;
  projectId?: string;
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
  onDelete?: () => void;
  photoLimit: number;
}

const PhotosAndNotesDashboardCard = (props: IPhotosAndNotesDashboardCardProps) => {
  const { memberId, theme, className, setting, onHide, projectId, photoLimit, onDelete } = props;

  const isPro = useFeatureFlags('PRO');

  const navigateToPhotos = usePhotosNavigation();

  const currentTimeFrame: DashboardTimeFrame = setting.options?.time ?? 'payPeriod';

  const { timeRange, timeRanges } = useDashboardGraphDates(currentTimeFrame);
  const { data, execute, loading, error } = useDashboardPhotos(timeRange, timeRanges, memberId, projectId);
  const total = useDashboardCardTotal(timeRanges, null, null, data ?? [], currentTimeFrame, loading);

  const navigate = useCallback(() => {
    const timeRangeType = convertDashboardTimeFrameToTimeRangeType(currentTimeFrame);
    const navRange = getDashboardNavigationRange(timeRange, timeRanges, currentTimeFrame);
    navigateToPhotos({
      employee_id: memberId,
      project_id: projectId,
      exitToLegacy: false,
      time_range_type: timeRangeType,
      start_date: navRange.startTime.toISODate(),
      end_date: navRange.endTime.toISODate(),
    });
  }, [currentTimeFrame, memberId, navigateToPhotos, projectId, timeRange, timeRanges]);

  const classes = classNames('photos-and-notes-dashboard-card', className);

  useEffect(() => {
    if (isPro) {
      execute();
    }
  }, [isPro, memberId, projectId, timeRange]);

  return (
    <>
      {isPro ? (
        error === null ? (
          <DashboardContentCard
            header={total}
            rightLabel={t('View Uploads') as string}
            onRightLabelClick={navigate}
            subheaderValue={getDashboardTimeFrameTitle(setting.title, currentTimeFrame)}
            className={classes}
            theme={theme}
          >
            {loading ? (
              <DashboardCardSpinner />
            ) : (
              <>
                {currentTimeFrame === 'daily' ? (
                  <>
                    <MediaEntryThumbnailTray
                      memberId={memberId}
                      projectId={projectId}
                      photoLimit={photoLimit}
                      handleOnClick={navigate}
                      timeRange={timeRange}
                      className={'mb-4'}
                      onDelete={onDelete}
                    />
                    <DailyPhotoAndNotesDashboardCardFooter
                      timeRange={timeRange}
                      memberId={memberId}
                      projectId={projectId}
                    />
                  </>
                ) : (
                  <DashboardCardBarChart
                    data={data}
                    dataKey="value"
                    valueType="raw"
                    timeFrame={currentTimeFrame}
                    theme={theme}
                    {...dashboardCardBarChartGradientBlueLightPurple}
                  />
                )}
              </>
            )}
          </DashboardContentCard>
        ) : (
          <DashboardErrorCard theme={theme} onClick={execute} />
        )
      ) : (
        <DashboardProUpgradeCard title={setting.title} svg={PhotoIcon} theme={theme} onHide={onHide} />
      )}
    </>
  );
};

export default PhotosAndNotesDashboardCard;
