import { SortDirection } from '@busybusy/webapp-react-ui';
import useMemberActivityDateRangeDetails, {
  IMemberDateActivityTableRowInfo,
} from 'containers/activity-reports/member-tables/hooks/useMemberActivityDateRangeDetails';
import { useTableSorting } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { first, groupBy, isNil, keys, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { LaborMetricsInterval, Member } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { aggregateActivityRows } from '../../hooks/ActivitySummaryQueryUtils';
import { IDateActivityTableRowInfo } from './useDateActivity';

export interface IDateMemberActivityTableRowInfo extends IDateActivityTableRowInfo {
  memberId: string | null;
  member: Pick<Member, 'id' | 'firstName' | 'lastName'> | null;
}

export default function useDateActivityMemberDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const nameFormatted = useEmployeeNameFormatter();
  const [data, setData] = useState<IDateMemberActivityTableRowInfo[]>([]);
  const restrictedData = useRef<IDateMemberActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'startDate',
    SortDirection.ASCENDING
  );

  const {
    loadData,
    sortedData: memberData,
    remainingData,
  } = useMemberActivityDateRangeDetails(filterId, filterType, timeRange, intervalType, filterIdWithDescendants);

  useEffect(() => {
    updateData();
  }, [memberData]);

  function updateData() {
    if (!isNil(remainingData)) {
      restrictedData.current = {
        ...remainingData,
        id: remainingDataItemId,
        memberId: null,
        member: null,
        startDate: timeRange.startTime,
        endDate: timeRange.endTime,
        detailRows: [],
      };
    }

    const dataForDates: IDateMemberActivityTableRowInfo[] = memberData.flatMap((row) => {
      const dateRows = row.detailRows as IMemberDateActivityTableRowInfo[] | undefined;
      // add the date to all the member rows
      return (
        dateRows?.map((data) => {
          return {
            ...data,
            startDate: data.startDate!,
            endDate: data.endDate!,
            memberId: data.member!.id,
            detailRows: [],
          };
        }) ?? []
      );
    });
    const dataByDate = groupBy(dataForDates, (data) => data.startDate);
    const dates = keys(dataByDate);

    setData(
      dates.map((date) => {
        const rowData = dataByDate[date];
        const firstRow = first(rowData)!;
        const totalMetrics = aggregateActivityRows(rowData);

        const sortedDetailRows = sortBy(rowData, (row) =>
          row.member ? nameFormatted(row.member.firstName ?? '', row.member.lastName ?? '') : ''
        );

        return {
          ...totalMetrics,
          id: date,
          startDate: firstRow.startDate!,
          endDate: firstRow.endDate!,
          memberId: null,
          member: null,
          detailRows: sortedDetailRows,
        };
      })
    );
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    restrictedData: restrictedData.current,
  };
}
