import { useState } from 'react';
import IOrganization from 'types/Organization';
import LocalStore from 'utils/localStorageUtils';

export interface ICompany {
  organization: IOrganization;
  token: string;
}

export default function useCompany() {
  const [companies, setCompanies] = useState<ICompany[]>(LocalStore.get('stored_companies') ?? []);

  const addCompany = (newCompany: ICompany) => {
    let updateCompanies: ICompany[];
    if (companies.some((company) => company.organization.id === newCompany.organization.id)) {
      // if the company already exists then lets update the object we have in our list
      updateCompanies = companies.map((company) => {
        if (company.organization.id === newCompany.organization.id) {
          return newCompany;
        } else {
          return company;
        }
      });
    } else {
      // this is a new company so lets add it to our list
      updateCompanies = companies.concat([newCompany]);
    }

    setCompanies(updateCompanies);
    LocalStore.set('stored_companies', updateCompanies);
  };

  return { companies, addCompany };
}
