import { useCallback, useState } from 'react';
import { ThunkedValue } from 'types/ThunkedValue';
import { ThunkedSet } from 'types/util/ThunkedSet';
import getThunkedValue from 'utils/functionUtils';
import LocalStore from 'utils/localStorageUtils';

function useLocalStorage<T>(key: string, defaultValue: T): [T, ThunkedSet<T>] {
  const [storedData, setStoredData] = useState<T>(() => LocalStore.get(key) ?? defaultValue);

  const set = useCallback(
    (value: ThunkedValue<T>) => {
      const result = getThunkedValue(value, storedData);
      LocalStore.set(key, result);
      setStoredData(result);
    },
    [key, storedData]
  );

  return [storedData, set];
}

export default useLocalStorage;
