import { OperationVariables, QueryOptions } from '@apollo/client';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import { asyncReducer, AsyncReducer, useAsync } from '../';
import useApolloPaging from './useApolloPaging';

export default function useAsyncApollo<
  T extends ICursorable,
  // TODO: fix usage of {} as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  D extends { [key: string]: T[] } = {},
  // TODO: fix usage of {} as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  V extends OperationVariables & { after?: string | null; first?: number | null } = {},
  O extends QueryOptions<V> = any
>(dataAccessor: string, options: O, immediate: boolean = false, reducer: AsyncReducer<T[]> = asyncReducer) {
  const { getAll } = useApolloPaging();

  const get = useCallback(() => getAll<T, D, V, O>(dataAccessor, options), [dataAccessor, getAll, options]);

  return useAsync(get, immediate, reducer);
}
