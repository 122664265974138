import gql from 'graphql-tag';

export const EQUIPMENT_HOURS_QUERY = gql`
  query queryEquipmentHours($filter: EquipmentHoursFilter, $first: Int, $after: String, $sort: [EquipmentHoursSort!]) {
    equipmentHours(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      equipmentId
      runningHours
      submittedByMemberId
      createdOn
      cursor
    }
  }
`;

export const EQUIPMENT_HOURS_REPORT_QUERY = gql`
  query queryEquipmentHoursReport(
    $filter: EquipmentHoursFilter
    $first: Int
    $after: String
    $sort: [EquipmentHoursSort!]
  ) {
    equipmentHours(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      equipmentId
      runningHours
      submittedByMemberId
      submittedByMember {
        id
        firstName
        lastName
      }
      createdOn
      cursor
    }
  }
`;

export const EQUIPMENT_HOURS_REPORT_DETAILS_QUERY = gql`
  query queryEquipmentHoursReportDetails(
    $filter: EquipmentHoursFilter
    $first: Int
    $after: String
    $sort: [EquipmentHoursSort!]
  ) {
    equipmentHours(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      equipmentId
      equipment {
        id
        equipmentName
        model {
          id
          title
          category {
            id
            title
          }
          make {
            id
            title
          }
        }
      }
      runningHours
      submittedByMemberId
      submittedByMember {
        id
        firstName
        lastName
      }
      createdOn
      cursor
    }
  }
`;
