import { MemberClockStateFilter, MemberClockStateQuery } from '__generated__/graphql';
import gql from 'graphql-tag';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { useCallback } from 'react';

export const MEMBER_CLOCK_STATE_QUERY = gql`
  query MemberClockState($filter: MemberClockStateFilter) {
    memberClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;

export default function useMemberClockState(filter?: MemberClockStateFilter) {
  const client = useGraphQLClient();
  return useCallback(() => {
    return client.request<MemberClockStateQuery>({
      document: MEMBER_CLOCK_STATE_QUERY,
      variables: {
        filter: filter,
      },
    });
  }, [client]);
}
