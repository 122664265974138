import { IFormRender, SelectFormField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { AnyObject } from 'types/util/Object';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';

export interface IDashboardTimeSelectFormFieldProps<FormType extends AnyObject> {
  className?: ClassName;
  form: IFormRender<FormType>;
  name: string;
  includeDaily?: boolean;
}

export enum DashboardTimeType {
  DAILY = 'daily',
  PAST_7_DAYS = 'pastSeven',
  PAST_14_DAYS = 'pastFourteen',
  WEEKLY = 'weekly',
  PAY_PERIOD = 'payPeriod',
  MONTHLY = 'monthly',
}

const DashboardTimeSelectFormField = <FormType extends AnyObject>(
  props: IDashboardTimeSelectFormFieldProps<FormType>
) => {
  const { className, form, name } = props;

  const classes = classNames('dashboard-time-select-form-field', className);

  function getOptions() {
    const options = [
      { value: DashboardTimeType.PAST_7_DAYS, label: t('Past 7 days') },
      { value: DashboardTimeType.PAST_14_DAYS, label: t('Past 14 days') },
      { value: DashboardTimeType.WEEKLY, label: t('Weekly') },
      { value: DashboardTimeType.MONTHLY, label: t('Monthly') },
      { value: DashboardTimeType.PAY_PERIOD, label: t('Pay Period') },
    ];

    if (props.includeDaily === true) {
      options.unshift({ value: DashboardTimeType.DAILY, label: t('Daily') });
    }
    return options;
  }

  return (
    <SelectFormField
      className={classes}
      name={name}
      form={form}
      validations={[{ validation: v_require }]}
      options={getOptions()}
    />
  );
};

export default DashboardTimeSelectFormField;
