import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IChildrenProps } from 'types/ChildrenProps';

export const reactQueryClient = new QueryClient();

const ReactQueryMiddleware: React.FC<IChildrenProps> = ({ children }) => (
  <QueryClientProvider client={reactQueryClient}>{children}</QueryClientProvider>
);

export default ReactQueryMiddleware;
