import { Align, Bar, Button, ButtonList, Justify, Label, Position, Row, Theme } from '@busybusy/webapp-react-ui';
import { AddCircleIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { IconButton, MoreButton } from 'components';
import { ReactNode } from 'react';
import * as React from 'react';
import { t } from 'utils/localize';
import './TimeEntryDataTableHeader.scss';

export interface ITimeEntryDataTableHeaderProps {
  className?: ClassName;
  title: ReactNode;
  total: string;
  openAddEntry?: () => void;
  openAddTimeOff?: () => void;
  rightTitleNode?: ReactNode;
}

const TimeEntryDataTableHeader = (props: ITimeEntryDataTableHeaderProps) => {
  const { className, title, total, openAddEntry, openAddTimeOff, rightTitleNode } = props;

  const classes = classNames('time-entry-data-table-header', className);

  return (
    <div className={classes}>
      <Bar justify={Justify.SPACE_BETWEEN} className="pr-4 pl-4">
        <Row align={Align.CENTER} justify={Justify.SPACE_BETWEEN}>
          <Label className="pl-3 time-entry-data-table-header-title">{title}</Label>
          {rightTitleNode && rightTitleNode}
        </Row>
        <div className="bar-actions">
          <Label className="px-2">{total}</Label>
          {openAddEntry && (
            <IconButton className="no-print" svg={AddCircleIcon} onClick={openAddEntry} theme={Theme.LIGHT} />
          )}
          {openAddTimeOff && (
            <MoreButton
              className="no-print"
              position={Position.LEFT}
              renderContent={(close: () => void) => {
                return (
                  <ButtonList>
                    <Button
                      onClick={async (e: React.MouseEvent<Element, MouseEvent>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        close();
                        openAddTimeOff?.();
                      }}
                    >
                      {t('Add Time Off')}
                    </Button>
                  </ButtonList>
                );
              }}
            />
          )}
        </div>
      </Bar>
    </div>
  );
};

export default TimeEntryDataTableHeader;
