import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { convertDashboardTimeFrameToTimeRangeType } from 'containers/dashboard/util/utils';
import { getDashboardNavigationRange } from 'containers/scheduling/utils/utils';
import { useWeeklyPopulator } from 'hooks';
import { isNull, last } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import TimeRangeType from 'types/TimeRangeType';
import { useFeatureFlags } from 'utils/features';

export default function useDashboardActivityReportTimeRangeDetails(timeFrame: DashboardTimeFrame) {
  // need to return time range type and nav range
  const { timeRange, timeRanges } = useDashboardGraphDates(timeFrame)!; // Null force safe for all settings but `clockStatus`
  const weeklyRangeGet = useWeeklyPopulator();
  const isPro = useFeatureFlags('PRO');

  return useCallback(() => {
    if (isPro) {
      const timeRangeType = convertDashboardTimeFrameToTimeRangeType(timeFrame);
      const navigationRange = getDashboardNavigationRange(timeRange, timeRanges, timeFrame);
      return { timeRangeType, timeRange: navigationRange };
    } else {
      const currentTime = DateTime.utc().startOf('day');

      const weeklyRange = last(weeklyRangeGet(1, currentTime.toSeconds())) ?? null;
      if (!isNull(weeklyRange)) {
        return {
          timeRangeType: TimeRangeType.WEEKLY,
          timeRange: weeklyRange,
        };
      }
    }

    return { timeRangeType: TimeRangeType.CUSTOM, timeRange };
  }, [isPro, timeFrame, timeRanges, timeRange, weeklyRangeGet]);
}
