import classNames from 'classnames';
import CostCodeCapsules from 'components/domain/cost-code/CostCodeCapsules/CostCodeCapsules';
import EquipmentCapsules from 'components/domain/equipment/EquipmentCapsules/EquipmentCapsules';
import MemberCapsules from 'components/domain/member/MemberCapsules/MemberCapsules';
import ProjectCapsules from 'components/domain/project/ProjectCapsules/ProjectCapsules';
import TimeEntryActionBar from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBar';
import DateTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/DateTimeEntryDataReport/DateTimeEntryDataReport';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import { defaultTimeEntryColumns } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useReduxSelector, useTimeRange } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { isNil, map, partition } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { areTimeRangesEqual, utcToday } from 'utils/dateUtils';
import { t } from 'utils/localize';
import useDateRangeTimeEntryDataReport from '../../TimeEntryDataReport/DateTimeEntryDataReport/hooks/useDateRangeTimeEntryDataReport';
import TimeEntryFilterBar from '../../TimeEntryFilterBar/TimeEntryFilterBar';
import './DateRangeTimeEntryDialog.scss';

export interface IDateRangeTimeEntryDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  clipDatesToTimeRange: boolean;
  changableTimeRange: boolean;
  memberIds?: string[] | null;
  projectIds?: string[] | null;
  costCodeIds?: string[] | null;
  equipmentIds?: string[] | null;
  includeOpen?: boolean;
  projectIdWithDescendants?: boolean;
  timeRangeBackPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  timeRangeForwardPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
}

const DateRangeTimeEntryDialog = (props: IDateRangeTimeEntryDialogProps) => {
  const {
    className,
    isOpen,
    onClose,
    timeRange,
    timeRangeType,
    clipDatesToTimeRange,
    changableTimeRange,
    memberIds,
    projectIds,
    costCodeIds,
    equipmentIds,
    includeOpen,
    projectIdWithDescendants,
    timeRangeBackPressed,
    timeRangeForwardPressed,
  } = props;
  const filterMemberIds = useMemo(() => (memberIds ? memberIds : undefined), [memberIds]);
  const filterProjectIds = useMemo(() => (projectIds ? projectIds : undefined), [projectIds]);
  const filterCostCodeIds = useMemo(() => (costCodeIds ? costCodeIds : undefined), [costCodeIds]);
  const filterEquipmentIds = useMemo(() => (equipmentIds ? equipmentIds : undefined), [equipmentIds]);

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const [filterRange, setFilterRange] = useState<ITimeRange<DateTime>>(timeRange);

  const { data, loading, getData } = useDateRangeTimeEntryDataReport(
    filterRange,
    timeRangeType,
    clipDatesToTimeRange,
    includeOpen,
    filterCostCodeIds,
    filterEquipmentIds,
    filterMemberIds,
    filterProjectIds,
    projectIdWithDescendants
  );

  const brand = useBrandTitle();

  function renderActionBar(range: ITimeRange<DateTime>) {
    return <TimeEntryActionBar onBulkEditSubmit={getData} onDelete={getData} onTimeEntryEdit={getData} />;
  }

  function renderTimeEntryReport(range: ITimeRange<DateTime>) {
    if (!areTimeRangesEqual(filterRange, range)) {
      setFilterRange(range);
    }
    return (
      <DateTimeEntryDataReport
        columnSettings={defaultTimeEntryColumns}
        updateDates={getData}
        timeRange={range}
        selectedIds={selectedIds}
        onSelectChange={onSelect}
        data={data}
        loading={loading}
        loadedAll={!loading}
        applyPrintOptions={false}
      />
    );
  }

  function renderFilterBar(range: ITimeRange<DateTime>) {
    const exportFileName = `${brand}-${t('date-activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}.csv`;

    return (
      <>
        {(filterMemberIds || filterProjectIds || filterCostCodeIds || filterEquipmentIds) && (
          <TimeEntryFilterBar
            data={data}
            loadedAll={!loading}
            loadAll={() => undefined}
            exportFileName={exportFileName}
            renderFilters={(forPrint) => (
              <>
                {filterMemberIds && (
                  <MemberCapsules className="ml-2" printVersion={forPrint} memberIds={filterMemberIds} />
                )}
                {filterProjectIds && (
                  <ProjectCapsules
                    className="ml-2"
                    projectIds={filterProjectIds}
                    printVersion={forPrint}
                    isUnassinged={projectIdWithDescendants === false}
                  />
                )}
                {filterCostCodeIds && (
                  <CostCodeCapsules className="ml-2" printVersion={forPrint} costCodeIds={filterCostCodeIds} />
                )}
                {filterEquipmentIds && (
                  <EquipmentCapsules className="ml-2" printVersion={forPrint} equipmentIds={filterEquipmentIds} />
                )}
              </>
            )}
          />
        )}
      </>
    );
  }

  const classes = classNames('date-time-entry-dialog', className);

  return (
    <ManagedTimeRangeReportDialog
      {...useTimeRange(timeRangeType, filterRange, null, utcToday().startTime, 'utc')}
      className={classes}
      isOpen={isOpen}
      onClose={onClose}
      timeRange={timeRange}
      timeRangeType={changableTimeRange ? timeRangeType : TimeRangeType.CUSTOM} // hide arrows to change date if its not allowed
      reportComponent={renderTimeEntryReport}
      title={t('Time Entries')}
      renderActionBar={renderActionBar}
      renderFilterBar={renderFilterBar}
      timeRangeBackPressed={timeRangeBackPressed}
      timeRangeForwardPressed={timeRangeForwardPressed}
      headerClassName={'no-print'}
    />
  );
};

export default DateRangeTimeEntryDialog;
