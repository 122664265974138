import { Menu, Position } from '@busybusy/webapp-react-ui';
import { TemplateIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, IconButton } from 'components';
import { useOpenable } from 'hooks';
import { executeFunctionsCurried } from 'utils/functionUtils';
import { t } from 'utils/localize';
import QuickTimeSelectionMenu from '../QuickTimeSelectionMenu/QuickTimeSelectionMenu';
import QuickTimeTemplateListDialog from '../QuickTimeTemplateListDialog/QuickTimeTemplateListDialog';
import QuickTimeTemplateSettingsForm from '../forms/QuickTimeTemplateSettingsForm/QuickTimeTemplateSettingsForm';
import { ITimeEntryTemplate } from '../types/types';
import './QuickTimeTemplateButton.scss';

export interface IQuickTimeTemplateButtonProps {
  onTemplateSelect: (template: ITimeEntryTemplate) => void;
  position?: Position;
  className?: ClassName;
}

function QuickTimeTemplateButton({ onTemplateSelect, position, className }: IQuickTimeTemplateButtonProps) {
  const settingsOpenable = useOpenable();
  const manageOpenable = useOpenable();

  function renderTrigger(ref: any) {
    const classes = classNames('quick-time-template-button', className);
    return <IconButton svg={TemplateIcon} forwardRef={ref} className={classes} tooltipLabel={t('Template Menu')} />;
  }

  function renderContent(closeMenu: () => void) {
    function onTemplateClick(template: ITimeEntryTemplate) {
      onTemplateSelect(template);
      closeMenu();
    }

    return (
      <QuickTimeSelectionMenu
        onManageClick={executeFunctionsCurried(manageOpenable.open, closeMenu)}
        onSettingsClick={executeFunctionsCurried(settingsOpenable.open, closeMenu)}
        onTemplateSelect={onTemplateClick}
      />
    );
  }

  return (
    <>
      <Menu trigger={renderTrigger} content={renderContent} width={'300px'} position={position} />
      <HeaderDialog title={t('Template Settings')} isOpen={settingsOpenable.isOpen} onClose={settingsOpenable.close}>
        <QuickTimeTemplateSettingsForm
          className="mx-8 my-6"
          onManage={executeFunctionsCurried(settingsOpenable.close, manageOpenable.open)}
          onSubmit={settingsOpenable.close}
        />
      </HeaderDialog>
      <QuickTimeTemplateListDialog onClose={manageOpenable.close} isOpen={manageOpenable.isOpen} size="full" />
    </>
  );
}

export default QuickTimeTemplateButton;
