import { useApolloClient } from '@apollo/client';
import {
  ActivityReportsIcon,
  BudgetIcon,
  BulletinIcon,
  CostCodeIcon,
  DashboardIcon,
  DocumentIcon,
  DollarIcon,
  EquipmentIcon,
  FolderIcon,
  MoreHorizIcon,
  PasteIcon,
  PeopleIcon,
  PersonIcon,
  PhotoIcon,
  ProgressIcon,
  ReportsIcon,
  SafetyNavIcon,
  SchedulingIcon,
  SettingsIcon,
  TimeCardIcon,
  VerificationIcon,
} from 'assets/icons';
import { DAILY_REPORT_TEMPLATE_QUERY } from 'containers/daily-project-report/daily-report-queries';
import usePayrollEnabled from 'containers/payroll/hooks/usePayrollEnabled/usePayrollEnabled';
import useSchedulingWeeklyAttendanceReportEnabled from 'containers/scheduling/hooks/useSchedulingWeeklyAttendanceReportEnabled';
import useTimeLogFeedEnabled from 'containers/time-log-feed/useTimeLogFeedEnabled/useTimeLogFeedEnabled';
import { useActiveMember, useOrganization, useReduxSelector } from 'hooks';
import useNavListGraylog from 'hooks/analytics/useNavListGraylog';
import useLegacyReportsEnabled from 'hooks/feature/legacy-reports/useLegacyReportsEnabled/useLegacyReportsEnabled';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useBudgetsEnabled from 'hooks/models/organization/useBudgetsEnabled';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import useCanManagePerDiemForAnyone from 'hooks/permission/useCanManagePerDiem';
import useCanViewBudgets from 'hooks/permission/useCanViewBudgets';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useIsInIframe from 'hooks/store/useIsInIframe';
import { isEmpty, kebabCase, sortBy } from 'lodash';
import trimEnd from 'lodash/trimEnd';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { Location, PathMatch, useLocation } from 'react-router-dom';
import { IDailyProjectReportTemplate } from 'types/DailyProjectReport/DailyProjectReportTemplate';
import { IFavoriteTemplate } from 'types/MemberGlobalSettings';
import { mapNotNull } from 'utils/collectionUtils';
import { QueryParam } from 'utils/constants/queryParam';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { isType } from 'utils/typeguard';
import { GpsPinIcon } from '../../../../assets/icons';
import { INavBarChildLink, INavBarParentLink, NavBarSectionConfig } from '../types/types';
import useNavConfigBlacklistedIds from './useNavConfigBlacklistedIds';

const isActive = (queryKey: string, queryValue: string) => {
  return (match: PathMatch<string> | null, location: Location) => {
    if (!match) {
      return false;
    }

    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(queryKey) === queryValue;
  };
};

export default function useNavConfig() {
  const member = useActiveMember();
  // Permissions
  const canManageTimeEventsForOthers = (member.position?.timeEvents ?? 0) > 1;
  const canManageTimeEntriesForAnyone = (member.position?.manageTimeEntries ?? 0) > 0;
  const canManageEmployeesForAnyone = (member.position?.manageEmployees ?? 0) > 0;
  const canManageProjects = member.position?.manageProjects ?? false;
  const canManageCostCodes = member.position?.manageCostCodes ?? false;
  const canManageEquipment = member.position?.manageEquipment ?? false;
  const canViewBudgets = useCanViewBudgets();
  const canViewSchedulesForAnyone = (member.position?.viewSchedule ?? 0) > 0 ?? false;
  const canViewExpenses = useHasCostPermission();
  const canViewAllActivityReports = member.position?.viewAllActivityReport ?? false;
  const canManageGpsApprovalForAnyone = (member.position?.manageGpsApproval ?? 0) > 0;
  const canManagePerDiem = useCanManagePerDiemForAnyone();

  const organization = useOrganization();
  const activeMember = useActiveMember();
  const dashboardIsEnabled = useFeatureFlags('DASHBOARD');
  const breakHoursIsEnabled = useFeatureFlags('BREAK_HOURS_REPORT');
  const membersIsEnabled = useFeatureFlags('EMPLOYEE_TIMERS');
  const landingPagesIsEnabled = useFeatureFlags('LANDING_PAGES');
  const photosIsEnabled = useFeatureFlags('PHOTOS_REACT');
  const schedulingIsEnabled = useFeatureFlags('SCHEDULING') && canViewSchedulesForAnyone;
  const managementListIsEnabled = useFeatureFlags('MANAGEMENT_LISTS');
  const employeeManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_EMPLOYEES');
  const projectManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_PROJECTS');
  const costCodesManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_COST_CODES');
  const equipmentManagementIsEnabled = useFeatureFlags('MANAGEMENT_LISTS_EQUIPMENT');
  const photoVerificationReportIsEnabled = useFeatureFlags('KIOSK_REPORT');
  const legacyReportsEnabled = useLegacyReportsEnabled();
  const timesheetsIsEnabled = useFeatureFlags('TIMESHEETS') && canManageTimeEntriesForAnyone;
  const isPro = useFeatureFlags('PRO');
  const mapSectionEnabled = useFeatureFlags('MAP_SECTION');
  const myStatusIsEnabled = useFeatureFlags('MY_STATUS') && membersIsEnabled;
  const location = useLocation();
  const costCodeEnabled = organization.trackCostCode ?? false;
  const equipmentEnabled = ((member.position?.manageEquipment ?? false) && organization.trackEquipment) ?? false;
  const signInReportOn = organization.busySignInQuestion ?? false;
  const emberLegacyUrl = useReduxSelector((state) => trimEnd(state.environment.REACT_APP_LEGACY_URL, '/'));
  const emberEquipmentUrl = useReduxSelector((state) => trimEnd(state.environment.REACT_APP_EQUIPMENT_URL, '/'));
  const canManageSettings = member.position?.manageCompanySettings ?? false;
  const settingsLegacy = !useFeatureFlags('SETTINGS');
  const token = useReduxSelector((state) => state.authentication.token);
  const payrollEnabled = usePayrollEnabled();
  const activityReportIsEnabled = useFeatureFlags('REACT_ACTIVITY_REPORTS');
  const activityReportByDateRangeIsEnabled = useFeatureFlags('REACT_BY_DATE_RANGE_ACTIVITY_REPORTS');
  const timeOffReportIsEnabled = useFeatureFlags('TIME_OFF_REPORT');
  const progressReportIsEnabled = useFeatureFlags('WORK_ACCOMPLISHED');
  const machineVsOperatorIsEnabled = useFeatureFlags('MACHINE_VS_OPERATOR_REPORT');
  const canViewProgress =
    progressReportIsEnabled &&
    isPro &&
    (member.position?.canManageProjectCostCodeQuantity ?? 0) > 0 &&
    ((organization.trackCostCode ?? false) || (canManageSettings && canManageCostCodes));
  const isDailySignInEnabled = useFeatureFlags('DAILY_SIGN_IN_QUESTIONS');
  const isLaborBudgetsEnabled = useFeatureFlags('LABOR_BUDGETS');
  const isCustomSignOffEnabled = useCustomSignOffEnabled();
  const isDailySignInReportEnabled = isDailySignInEnabled && signInReportOn && isPro;
  const isDailySignOffReportEnabled =
    (isPro &&
      ((organization.breakPolicy ?? false) ||
        (organization.safetySignature ?? false) ||
        (organization.timeAccuracy ?? false))) ||
    isCustomSignOffEnabled;
  const isPhotoVerificationEnabled = useFeatureFlags('PHOTO_VERIFICATION');
  const isProjectDocumentEnabled = useFeatureFlags('DOCUMENTS');
  const isTimeLogFeedEnabled = useTimeLogFeedEnabled();
  const isBudgetsEnabled = useBudgetsEnabled();
  const isBulletinEnabled = useFeatureFlags('BULLETIN_FEATURE') && !!organization.bulletin;
  const client = useApolloClient();
  const memberSettings = useMemberSettings();
  const fieldReportFavoriteIds = memberSettings?.global?.ui?.features?.fieldReportFavorites;
  const [fieldReportFavorites, setFieldReportFavorites] = useState<IDailyProjectReportTemplate[]>([]);
  const isWeeklyAttendanceReportEnabled = useSchedulingWeeklyAttendanceReportEnabled();
  const perDiemIsEnabled = useFeatureFlags('PER_DIEM') && (organization.perDiem ?? false);
  const isWeeklyGridEnabled = useFeatureFlags('WEEKLY_TIME_GRID');
  const isLocationFlaggingEnabled = useFeatureFlags('LOCATION_FLAGGING');
  const isInIframe = useIsInIframe();
  const blacklistedNavBarIds = useNavConfigBlacklistedIds();
  const isSafetyIncidentsEnabled = useFeatureFlags('SAFETY_INCIDENTS');
  const isSafetyInspectionsEnabled = useFeatureFlags('SAFETY_INSPECTIONS');
  const canManageInspections = member.position?.manageInspections ?? false;
  const canManageIncident = member.position?.manageIncident ?? false;
  const isGpsDisabledEnabled = useFeatureFlags('GPS_STATUS_DISABLED');

  const isSafetyEnabled = isSafetyIncidentsEnabled || isSafetyInspectionsEnabled;
  const canManageAnySafety = canManageIncident || canManageInspections;

  useEffect(() => {
    if (fieldReportFavoriteIds) {
      getFieldReportFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldReportFavoriteIds]);

  const getFieldReportFavorites = async () => {
    const results = await client.query<{ dailyProjectReportTemplates: IDailyProjectReportTemplate[] }>({
      query: DAILY_REPORT_TEMPLATE_QUERY,
      variables: { filter: { deletedOn: { isNull: true } } },
      fetchPolicy: 'network-only',
    });

    const filteredResults = sortBy(
      results.data.dailyProjectReportTemplates
        .filter((template) => fieldReportFavoriteIds!.some((t: IFavoriteTemplate) => t.id === template.id))
        .filter((template) => {
          return (
            template.audienceType === 'everyone' ||
            (template.audienceType === 'position' && template.positionId === activeMember.positionId)
          );
        }),
      (t) => t.title.toLowerCase()
    );
    setFieldReportFavorites(filteredResults);
  };

  const fieldReportChildren: INavBarChildLink[] = fieldReportFavorites.map((template, index) => {
    const navChild: INavBarChildLink = {
      id: kebabCase(template.title),
      position: index + 1,
      label: template.title,
      href: `/daily-report?dpr_template_id=${template.id}&time_range_type=Monthly&dpr_favorite_from_nav=true`,
      exact: true,
      isActive: isActive('dpr_template_id', template.id),
      enabled: true,
      visible: true,
    };
    return navChild;
  });
  const userEvents = useNavListGraylog();

  // Maybe a type so we can see what bucket it goes in? Might need to distinguish between ember/react nav
  return useMemo(() => {
    const isOnTimesheets = location.pathname.indexOf('/time-cards') !== -1;
    const timesheetsParams = (
      value: 'Summary' | 'Time Cards' | 'Expanded Time Cards' | 'Time Entries' | 'Event Logs' | 'Weekly Grid'
    ) =>
      qs.stringify({
        ...qs.parse(location.search),
        timesheet_view: value,
        time_range_type: value === 'Weekly Grid' ? 'Weekly' : undefined,
      });

    const isOnActivityReport = location.pathname.indexOf('/activity-report') !== -1;
    const activityReportParams = (
      value: 'by-project' | 'by-employee' | 'by-cost-code' | 'by-equipment' | 'by-date' | 'by-date-range'
    ) =>
      qs.stringify({
        ...qs.parse(location.search),
        filter_type: value,
      });

    const processNavBarSectionConfig = (config: NavBarSectionConfig): NavBarSectionConfig => {
      const predicate = (c: INavBarParentLink | INavBarChildLink) => {
        return c.enabled && !blacklistedNavBarIds.some((blacklistedId) => blacklistedId == c.id);
      };

      return mapNotNull(config, (c) => {
        if (!predicate(c)) {
          return null;
        }

        if (isType<INavBarParentLink>(c, 'children')) {
          const processedChildren = c.children.filter(predicate);
          if (isEmpty(processedChildren)) {
            return null;
          }

          c.children = processedChildren;
        }

        return c;
      });
    };

    const mainNavBarConfig: NavBarSectionConfig = [
      {
        id: 'dashboard',
        position: 0,
        visible: true,
        href: '/',
        icon: DashboardIcon,
        label: t('Dashboard'),
        enabled: dashboardIsEnabled,
        exact: true,
        onClick: userEvents.recordClickDashboard,
      },
      {
        id: 'my-status',
        position: 1,
        visible: true,
        href: '/my-status',
        icon: PersonIcon,
        label: t('My Status'),
        enabled: myStatusIsEnabled,
        exact: true,
        onClick: userEvents.recordClickMyStatus,
      },
      {
        id: 'time-cards',
        position: 2,
        visible: true,
        icon: TimeCardIcon,
        label: t('Time Cards'),
        enabled: timesheetsIsEnabled,
        matches: () =>
          location.pathname.indexOf('/time-cards') !== -1 || location.pathname.indexOf('/event-logs') !== -1,
        onClick: () => userEvents.recordClickTimesheets('timesheets'),
        children: [
          {
            id: 'summary',
            position: 0,
            visible: true,
            href: `/time-cards?${
              isOnTimesheets ? timesheetsParams('Summary') : qs.stringify({ timesheet_view: 'Summary' })
            }`,
            enabled: true,
            label: t('Summary'),
            exact: true,
            isActive: isActive('timesheet_view', 'Summary'),
            onClick: () => userEvents.recordClickTimesheets('summary'),
          },
          {
            id: 'basic-time-cards',
            position: 1,
            visible: true,
            href: `/time-cards?${
              isOnTimesheets ? timesheetsParams('Time Cards') : qs.stringify({ timesheet_view: 'Time Cards' })
            }`,
            enabled: true,
            label: t('Basic'),
            exact: true,
            isActive: isActive('timesheet_view', 'Time Cards'),
            onClick: () => userEvents.recordClickTimesheets('time_cards'),
          },
          {
            id: 'expanded-time-cards',
            position: 2,
            visible: true,
            href: `/time-cards?${
              isOnTimesheets
                ? timesheetsParams('Expanded Time Cards')
                : qs.stringify({ timesheet_view: 'Expanded Time Cards' })
            }`,
            enabled: true,
            label: t('Expanded'),
            exact: true,
            isActive: isActive('timesheet_view', 'Expanded Time Cards'),
            onClick: () => userEvents.recordClickTimesheets('expanded_time_cards'),
          },
          {
            id: 'time-entries',
            position: 3,
            visible: true,
            href: `/time-cards?${
              isOnTimesheets ? timesheetsParams('Time Entries') : qs.stringify({ timesheet_view: 'Time Entries' })
            }`,
            enabled: true,
            label: t('Entries'),
            exact: true,
            isActive: isActive('timesheet_view', 'Time Entries'),
            onClick: () => userEvents.recordClickTimesheets('time_entries'),
          },
          {
            id: 'weekly-grid',
            position: 4,
            visible: true,
            href: `/time-cards?${
              isOnTimesheets ? timesheetsParams('Weekly Grid') : qs.stringify({ timesheet_view: 'Weekly Grid' })
            }`,
            enabled: isWeeklyGridEnabled,
            label: t('Weekly Grid'),
            exact: true,
            isActive: isActive('timesheet_view', 'Weekly Grid'),
            onClick: () => userEvents.recordClickTimesheets('weekly_grid'),
          },
          {
            id: 'event-logs',
            position: 5,
            visible: true,
            href: '/event-logs',
            enabled: isTimeLogFeedEnabled,
            label: t('Event Logs'),
            exact: true,
            isActive: (match: PathMatch<string> | null, location: Location) =>
              location.pathname.indexOf('/event-logs') !== -1,
            onClick: () => userEvents.recordClickTimesheets('event_logs'),
          },
        ],
      },
      {
        id: 'payroll',
        position: 3,
        visible: true,
        href: '/payroll',
        icon: DollarIcon,
        label: t('Payroll'),
        enabled: payrollEnabled,
        exact: true,
        onClick: userEvents.recordClickPayroll,
      },
      {
        id: 'employees',
        position: 4,
        visible: true,
        href: '/employees',
        icon: PeopleIcon,
        label: t('Employees'),
        enabled: membersIsEnabled && canManageTimeEventsForOthers,
        exact: true,
        onClick: userEvents.recordClickEmployees,
      },
      {
        id: 'projects',
        position: 5,
        visible: true,
        href: landingPagesIsEnabled ? `/projects` : `${emberLegacyUrl}/project?authorize=${token}`,
        icon: FolderIcon,
        label: t('Projects'),
        enabled: !!organization.trackProject && canManageProjects && (landingPagesIsEnabled || !isInIframe),
        exact: true,
        isEmberFeature: !landingPagesIsEnabled,
        onClick: userEvents.recordClickProjects,
      },
      {
        id: 'cost-codes',
        position: 6,
        visible: true,
        href: landingPagesIsEnabled ? `/cost-codes` : `${emberLegacyUrl}/cost-code?authorize=${token}`,
        icon: CostCodeIcon,
        label: t('Cost Codes'),
        enabled:
          canManageCostCodes &&
          (costCodeEnabled || (landingPagesIsEnabled && canManageSettings === true)) &&
          (landingPagesIsEnabled || !isInIframe),
        exact: true,
        isEmberFeature: !landingPagesIsEnabled,
        onClick: userEvents.recordClickCostCodes,
      },
      {
        id: 'equipment',
        position: 7,
        visible: true,
        href: landingPagesIsEnabled ? `/equipment` : `${emberEquipmentUrl}?authorize=${token}`,
        icon: EquipmentIcon,
        label: t('Equipment'),
        enabled:
          canManageEquipment &&
          (equipmentEnabled || (landingPagesIsEnabled && canManageSettings === true)) &&
          (landingPagesIsEnabled || !isInIframe),
        exact: true,
        isEmberFeature: !landingPagesIsEnabled,
        onClick: userEvents.recordClickCostCodes,
      },
      {
        id: 'photos',
        position: 8,
        visible: true,
        href: '/photos',
        icon: PhotoIcon,
        label: t('Photos'),
        enabled: true,
        exact: true,
        onClick: userEvents.recordClickPhotos,
      },
      {
        id: 'documents',
        position: 9,
        visible: true,
        href: '/documents',
        icon: DocumentIcon,
        label: t('Documents'),
        enabled: true,
        exact: true,
      },
      isWeeklyAttendanceReportEnabled
        ? {
            id: 'scheduling',
            position: 10,
            visible: true,
            icon: SchedulingIcon,
            label: t('Scheduling'),
            enabled: schedulingIsEnabled && isPro,
            matches: () => location.pathname.indexOf('/scheduling') !== -1,
            children: [
              {
                id: 'scheduling-calendar',
                position: 0,
                visible: true,
                href: '/scheduling?view_setting=calendar',
                enabled: true,
                label: t('Calendar'),
                exact: true,
                isActive: isActive(QueryParam.VIEW_SETTING, 'calendar'),
                onClick: () => userEvents.recordClickScheduling('calendar'),
              },
              {
                id: 'scheduling-attendance-report',
                position: 1,
                visible: true,
                href: '/scheduling?view_setting=weekly-attendance-report',
                enabled: isWeeklyAttendanceReportEnabled,
                label: t('Weekly Attendance'),
                exact: true,
                isActive: isActive(QueryParam.VIEW_SETTING, 'weekly-attendance-report'),
                onClick: () => userEvents.recordClickScheduling('attendance_report'),
              },
            ],
          }
        : {
            id: 'scheduling',
            position: 10,
            visible: true,
            href: '/scheduling',
            icon: SchedulingIcon,
            label: t('Scheduling'),
            enabled: schedulingIsEnabled && isPro,
            exact: true,
          },
      {
        id: 'safety',
        position: 11,
        visible: true,
        icon: SafetyNavIcon,
        label: t('Safety'),
        enabled: isSafetyEnabled && isPro && canManageAnySafety,
        matches: () => location.pathname.indexOf('/safety') !== -1,
        children: [
          {
            id: 'inspections-report',
            position: 0,
            visible: true,
            href: '/safety/inspections',
            enabled: isSafetyInspectionsEnabled && canManageInspections,
            label: t('Inspections'),
            exact: true,
          },
          {
            id: 'incidents-report',
            position: 1,
            visible: true,
            href: '/safety/incidents',
            enabled: isSafetyIncidentsEnabled && canManageIncident,
            label: t('Incidents'),
            exact: true,
          },
        ],
      },
      mapSectionEnabled
        ? {
            id: 'map',
            position: 12,
            visible: true,
            href: `/map`,
            icon: GpsPinIcon,
            label: t('Map'),
            enabled: canManageTimeEventsForOthers,
            exact: true,
            onClick: userEvents.recordClickMap,
          }
        : {
            id: 'map',
            position: 12,
            visible: true,
            href: `${emberLegacyUrl}/map?authorize=${token}`,
            icon: GpsPinIcon,
            label: t('Map'),
            enabled: canManageTimeEventsForOthers && !isInIframe,
            exact: true,
            isEmberFeature: true,
            onClick: userEvents.recordClickMap,
          },
      {
        id: 'budgets',
        position: 13,
        visible: true,
        href: '/budgets',
        icon: BudgetIcon,
        label: t('Budgets'),
        enabled: canViewBudgets && (isBudgetsEnabled || canManageSettings === true),
        exact: true,
        onClick: userEvents.recordClickBudget,
      },
      {
        id: 'bulletin',
        position: 14,
        visible: true,
        href: '/bulletin',
        icon: BulletinIcon,
        label: t('Bulletin'),
        enabled: isBulletinEnabled,
        exact: true,
        onClick: userEvents.recordClickBulletin,
      },
      {
        id: 'management-lists',
        position: 15,
        visible: true,
        icon: PasteIcon,
        label: t('Management Lists'),
        enabled: managementListIsEnabled,
        matches: () => location.pathname.indexOf('/manage') !== -1,
        onClick: () => userEvents.recordClickManagementLists('management_lists'),
        children: [
          {
            id: 'manage-employees',
            position: 0,
            visible: true,
            href: '/manage/employees',
            enabled: employeeManagementIsEnabled && managementListIsEnabled && canManageEmployeesForAnyone,
            label: t('Employees'),
            exact: true,
            onClick: () => userEvents.recordClickManagementLists('employees'),
          },
          {
            id: 'manage-projects',
            position: 1,
            visible: true,
            href: '/manage/projects',
            enabled: projectManagementIsEnabled && managementListIsEnabled && canManageProjects,
            label: t('Projects'),
            exact: true,
            onClick: () => userEvents.recordClickManagementLists('projects'),
          },
          {
            id: 'manage-cost-codes',
            position: 2,
            visible: true,
            href: '/manage/cost-codes',
            enabled: costCodesManagementIsEnabled && managementListIsEnabled && costCodeEnabled && canManageCostCodes,
            label: t('Cost Codes'),
            exact: true,
            onClick: () => userEvents.recordClickManagementLists('cost_codes'),
          },
          {
            id: 'manage-equipment',
            position: 3,
            visible: true,
            href: '/manage/equipment',
            enabled: equipmentManagementIsEnabled && managementListIsEnabled && equipmentEnabled && canManageEquipment,
            label: t('Equipment'),
            exact: true,
            onClick: () => userEvents.recordClickManagementLists('equipment'),
          },
        ],
      },
    ];

    const reportNavBarConfig: NavBarSectionConfig = [
      {
        id: 'activity-report',
        position: 0,
        visible: true,
        href: '/activity-report',
        icon: ActivityReportsIcon,
        enabled:
          activityReportIsEnabled &&
          (member.position?.viewAllActivityReport === true || (member.position?.viewMemberActivityReport ?? 0) > 0),
        label: t('Activity Reports'),
        matches: () => location.pathname.indexOf('/activity-report') !== -1,
        onClick: () => userEvents.recordClickActivityReports('activity_reports'),
        children: [
          {
            id: 'active-by-project',
            position: 0,
            visible: true,
            href: `/activity-report?${
              isOnActivityReport ? activityReportParams('by-project') : qs.stringify({ filter_type: 'by-project' })
            }`,
            enabled: member.position?.viewAllActivityReport === true,
            label: t('By Project'),
            exact: true,
            isActive: isActive('filter_type', 'by-project'),
            onClick: () => userEvents.recordClickActivityReports('by_project'),
          },
          {
            id: 'activity-by-employee',
            position: 1,
            visible: true,
            href: `/activity-report?${
              isOnActivityReport ? activityReportParams('by-employee') : qs.stringify({ filter_type: 'by-employee' })
            }`,
            enabled: true,
            label: t('By Employee'),
            exact: true,
            isActive: isActive('filter_type', 'by-employee'),
            onClick: () => userEvents.recordClickActivityReports('by_employee'),
          },
          {
            id: 'activity-by-cost-code',
            position: 2,
            visible: true,
            href: `/activity-report?${
              isOnActivityReport ? activityReportParams('by-cost-code') : qs.stringify({ filter_type: 'by-cost-code' })
            }`,
            enabled: member.position?.viewAllActivityReport === true,
            label: t('By Cost Code'),
            exact: true,
            isActive: isActive('filter_type', 'by-cost-code'),
            onClick: () => userEvents.recordClickActivityReports('by_cost_code'),
          },
          {
            id: 'activity-by-equipment',
            position: 3,
            visible: true,
            href: `/activity-report?${
              isOnActivityReport ? activityReportParams('by-equipment') : qs.stringify({ filter_type: 'by-equipment' })
            }`,
            enabled: member.position?.viewAllActivityReport === true,
            label: t('By Equipment'),
            exact: true,
            isActive: isActive('filter_type', 'by-equipment'),
            onClick: () => userEvents.recordClickActivityReports('by_equipment'),
          },
          {
            id: 'activity-by-date',
            position: 4,
            visible: true,
            href: `/activity-report?${
              isOnActivityReport ? activityReportParams('by-date') : qs.stringify({ filter_type: 'by-date' })
            }`,
            enabled: member.position?.viewAllActivityReport === true,
            label: t('By Day'),
            exact: true,
            isActive: isActive('filter_type', 'by-date'),
            onClick: () => userEvents.recordClickActivityReports('by_date'),
          },
          {
            id: 'activity-by-date-range',
            position: 5,
            visible: true,
            href: `/activity-report?${
              isOnActivityReport
                ? activityReportParams('by-date-range')
                : qs.stringify({ filter_type: 'by-date-range' })
            }`,
            enabled: activityReportByDateRangeIsEnabled && isPro && member.position?.viewAllActivityReport === true,
            label: t('By Date Range'),
            exact: true,
            isActive: isActive('filter_type', 'by-date-range'),
            onClick: () => userEvents.recordClickActivityReports('by_date_range'),
          },
        ],
      },
      {
        id: 'legacy',
        position: 1,
        visible: true,
        href: `${emberLegacyUrl}/report?authorize=${token}`,
        enabled: legacyReportsEnabled && (canViewExpenses || canViewAllActivityReports) && !isInIframe,
        label: t('Legacy Reports'),
        icon: ReportsIcon,
        exact: true,
        isEmberFeature: true,
        onClick: userEvents.recordClickLegacyReports,
      },
      {
        id: 'progress',
        position: 2,
        visible: true,
        href: '/progress-report',
        icon: ProgressIcon,
        label: t('Progress'),
        enabled: canViewProgress,
        exact: true,
        onClick: userEvents.recordClickProgress,
      },
      {
        id: 'verification-safety',
        position: 3,
        visible: true,
        icon: VerificationIcon,
        label: t('Verification'),
        enabled:
          (isDailySignInReportEnabled ||
            isDailySignOffReportEnabled ||
            photoVerificationReportIsEnabled ||
            isPhotoVerificationEnabled) &&
          isPro,
        matches: () =>
          /\/photo-verification-report|\/daily-sign-in|\/daily-sign-off-report|\/gps-outside-project|\/gps-disabled-report/i.test(
            location.pathname
          ),
        onClick: () => userEvents.recordClickVerificationAndSafety('verification_and_safety'),
        children: [
          {
            id: 'daily-sign-in',
            position: 0,
            visible: true,
            href: '/daily-sign-in',
            enabled: true,
            label: t('Daily Sign-In'),
            exact: true,
            onClick: () => userEvents.recordClickVerificationAndSafety('daily_sign_in'),
          },
          {
            id: 'daily-sign-off',
            position: 1,
            visible: true,
            href: '/daily-sign-off-report',
            enabled: true,
            label: t('Daily Sign-Off'),
            exact: true,
            onClick: () => userEvents.recordClickVerificationAndSafety('daily_sign_off'),
          },
          {
            id: 'photo-verification-report',
            position: 2,
            visible: true,
            href: '/photo-verification-report',
            enabled: (photoVerificationReportIsEnabled || isPhotoVerificationEnabled) && isPro,
            label: t('Photo Verification'),
            exact: true,
            onClick: () => userEvents.recordClickMore('kiosk_photos'),
          },
          {
            id: 'gps-outside-project',
            position: 3,
            visible: true,
            href: '/flagged-location-report',
            enabled: isLocationFlaggingEnabled && canManageGpsApprovalForAnyone,
            label: t('GPS Outside Project'),
            exact: true,
            onClick: () => userEvents.recordClickMore('flagged_location_report'),
          },
          {
            id: 'gps-disabled-report',
            position: 4,
            visible: true,
            href: '/gps-disabled-report',
            enabled: isGpsDisabledEnabled && canManageGpsApprovalForAnyone,
            label: t('GPS Disabled'),
            exact: true,
            onClick: () => userEvents.recordClickMore('gps_disabled'),
          },
        ],
      },
      fieldReportChildren.length > 0
        ? {
            id: 'daily-report',
            position: 4,
            visible: true,
            matches: () => /\/daily-report/i.test(location.pathname),
            enabled: true,
            label: t('Field Reports'),
            exact: true,
            onClick: () => userEvents.recordClickMore('daily_project_reports'),
            icon: ProgressIcon,
            children: [
              {
                id: 'daily-report',
                position: 0,
                visible: true,
                href: '/daily-report/',
                enabled: true,
                label: t('Daily Reports'),
                exact: true,
                onClick: () => userEvents.recordClickMore('daily_project_reports'),
              },
              ...fieldReportChildren,
            ],
          }
        : {
            id: 'daily-report',
            position: 4,
            visible: true,
            href: '/daily-report',
            enabled: true,
            label: t('Field Reports'),
            exact: true,
            onClick: () => userEvents.recordClickMore('daily_project_reports'),
            icon: ProgressIcon,
          },
      {
        id: 'more',
        position: 4,
        visible: true,
        icon: MoreHorizIcon,
        label: t('More'),
        enabled: true,
        // This never matches because it's in the ember app, so we don't need to highlight the child element
        matches: () =>
          /\/time-off-report|\/break-hours|\/sign-in-report|\/machine-vs-operator-report/i.test(location.pathname),
        onClick: () => userEvents.recordClickMore('more'),
        children: [
          {
            id: 'time-off',
            position: 0,
            visible: true,
            href: '/time-off-report',
            label: t('Time Off'),
            enabled: timeOffReportIsEnabled,
            exact: true,
            onClick: () => userEvents.recordClickMore('time_off'),
          },
          {
            id: 'machine-vs-operator',
            position: 2,
            visible: true,
            href: '/machine-vs-operator-report',
            enabled: machineVsOperatorIsEnabled && equipmentEnabled && canManageEquipment,
            label: t('Machine vs Operator'),
            exact: true,
            onClick: () => userEvents.recordClickMore('machine_vs_operator'),
          },
          {
            id: 'hour-meter',
            position: 3,
            visible: true,
            href: '/hour-meter-report',
            enabled: equipmentEnabled && canManageEquipment,
            label: t('Hour Meter'),
            exact: true,
            onClick: () => userEvents.recordClickMore('hour_meter'),
          },
          {
            id: 'break-hours',
            position: 4,
            visible: true,
            href: '/break-hours-report',
            enabled: breakHoursIsEnabled && canManageTimeEntriesForAnyone,
            label: t('Break Hours'),
            exact: true,
            onClick: () => userEvents.recordClickMore('break_hours'),
          },
          {
            id: 'per-diem',
            position: 5,
            visible: true,
            href: '/per-diem-report',
            enabled: perDiemIsEnabled && canManagePerDiem,
            label: t('Per Diem'),
            exact: true,
            onClick: () => userEvents.recordClickMore('per_diem'),
          },
        ],
      },
    ];

    const bottomNavBarConfig: NavBarSectionConfig = [
      {
        id: 'settings',
        position: 0,
        visible: true,
        href: settingsLegacy ? `${emberLegacyUrl}/company-settings?authorize=${token}` : '/settings/company-profile',
        icon: SettingsIcon,
        label: t('Settings'),
        enabled: !!canManageSettings && (!settingsLegacy || !isInIframe),
        isActive: (match: PathMatch<string> | null, location: Location) =>
          location.pathname.indexOf('/settings') !== -1,
        exact: true,
        isEmberFeature: settingsLegacy,
        onClick: userEvents.recordClickSettings,
      },
    ];

    mapNotNull(mainNavBarConfig, (config) => {
      if (!config.enabled) {
        return null;
      }
    });

    return {
      top: processNavBarSectionConfig(mainNavBarConfig),
      reports: processNavBarSectionConfig(reportNavBarConfig),
      bottom: processNavBarSectionConfig(bottomNavBarConfig),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    location.search,
    dashboardIsEnabled,
    userEvents,
    myStatusIsEnabled,
    timesheetsIsEnabled,
    payrollEnabled,
    membersIsEnabled,
    canManageTimeEventsForOthers,
    landingPagesIsEnabled,
    emberLegacyUrl,
    token,
    organization.trackProject,
    canManageProjects,
    canManageCostCodes,
    costCodeEnabled,
    canManageSettings,
    emberEquipmentUrl,
    canManageEquipment,
    equipmentEnabled,
    photosIsEnabled,
    isPro,
    isProjectDocumentEnabled,
    schedulingIsEnabled,
    mapSectionEnabled,
    isLaborBudgetsEnabled,
    canViewBudgets,
    isBudgetsEnabled,
    managementListIsEnabled,
    employeeManagementIsEnabled,
    canManageEmployeesForAnyone,
    projectManagementIsEnabled,
    costCodesManagementIsEnabled,
    equipmentManagementIsEnabled,
    activityReportIsEnabled,
    member.position?.viewAllActivityReport,
    member.position?.viewMemberActivityReport,
    activityReportByDateRangeIsEnabled,
    legacyReportsEnabled,
    canViewExpenses,
    canViewAllActivityReports,
    canViewProgress,
    isDailySignInReportEnabled,
    isDailySignOffReportEnabled,
    photoVerificationReportIsEnabled,
    isPhotoVerificationEnabled,
    timeOffReportIsEnabled,
    machineVsOperatorIsEnabled,
    breakHoursIsEnabled,
    canManageTimeEntriesForAnyone,
    isTimeLogFeedEnabled,
    settingsLegacy,
    isBulletinEnabled,
  ]);
}
