import { useQueryParams } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isNavigationBarStatus, setNavigationBarStatus } from 'store/ui/NavigationBar/NavigationBar';
import { IChildrenProps } from 'types/ChildrenProps';

const SIDE_NAV_STATUS_QUERY_PARAM = 'navigation_bar_status';

export default function RoutingMiddleware({ children }: IChildrenProps) {
  const dispatch = useDispatch();
  const { getParam, setParam } = useQueryParams();

  useEffect(() => {
    const status = getParam(SIDE_NAV_STATUS_QUERY_PARAM);

    if (status != null) {
      setParam(SIDE_NAV_STATUS_QUERY_PARAM, null);

      if (isNavigationBarStatus(status)) {
        dispatch(setNavigationBarStatus(status));
      }
    }
  }, [dispatch, getParam, setParam]);

  return <>{children}</>;
}
