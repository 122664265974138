import RpcMethod from './rpcMethod';

const jsonrpc = '2.0';

export function getRpcParams<T extends object>(
  method: RpcMethod,
  params: T | null,
  id: string = '1'
): {
  jsonrpc: '2.0';
  id: string;
  method: RpcMethod;
  params: T | null;
} {
  return { jsonrpc, id, method, params };
}
