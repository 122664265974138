import { Button, Icon, Image, Size, Theme, Tray } from '@busybusy/webapp-react-ui';
import { CheckboxEmptyState, PlayButton } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DualPanel from 'components/foundation/panels/DualPanel/DualPanel';
import { t } from 'i18next';
import { isNil } from 'lodash';
import './PremiumFeatureMarketing.scss';

export interface IPremiumEmptyStateProps {
  image: string;
  title: string;
  featureList: string[];
  createButtonText?: string;
  onCreateButtonClicked?: () => void;
  videoLink?: string;
  renderAsPremiumTrial?: boolean;
  onClick?: () => void;
  className?: ClassName;
}

export const PremiumFeatureMarketing: React.FunctionComponent<IPremiumEmptyStateProps> = (props) => {
  const {
    image,
    title,
    featureList,
    createButtonText,
    onCreateButtonClicked,
    videoLink,
    renderAsPremiumTrial,
    onClick,
    className,
  } = props;

  const classes = classNames('premium-feature-marketing', className);

  function renderFeatureList() {
    if (featureList.length > 5) {
      const leftContent = featureList.slice(0, 5);
      const rightContent = featureList.slice(5, featureList.length);

      return (
        <DualPanel
          leftContent={() => {
            return (
              <>
                {leftContent.map((text, index) => {
                  return (
                    <div key={index} className="feature-item mb-2">
                      <Icon svg={CheckboxEmptyState} size={Size.SMALL} className={'mr-2'} />
                      <h5 className="fw-light">{text}</h5>
                    </div>
                  );
                })}
              </>
            );
          }}
          rightContent={() => {
            return (
              <>
                {rightContent.map((text, index) => {
                  return (
                    <div key={index} className="feature-item mb-2">
                      <Icon svg={CheckboxEmptyState} size={Size.SMALL} className={'mr-2'} />
                      <h5 className="fw-light">{text}</h5>
                    </div>
                  );
                })}
              </>
            );
          }}
        />
      );
    } else {
      return (
        <>
          {featureList.map((text, index) => {
            return (
              <div key={index} className="feature-item mb-2">
                <Icon svg={CheckboxEmptyState} size={Size.SMALL} className={'mr-2'} />
                <h5 className="fw-light">{text}</h5>
              </div>
            );
          })}
        </>
      );
    }
  }

  return (
    <div className={classes}>
      <Tray className={renderAsPremiumTrial ? 'content-container mt-0' : 'content-container py-0'}>
        <div className="image-container">
          <Image src={image} width={!renderAsPremiumTrial ? '340px' : '290px'} />
        </div>
        <div className="text-container ml-6">
          <div className="mb-6">
            <h1>{title}</h1>
          </div>
          <div className="pt-1">{renderFeatureList()}</div>
          <div className="button-container mt-8">
            {!renderAsPremiumTrial && (
              <Button onClick={onCreateButtonClicked} type="primary">
                {createButtonText}
              </Button>
            )}
            <Button
              onClick={!isNil(onClick) ? onClick : () => window.open(videoLink)}
              type={!renderAsPremiumTrial ? 'secondary' : 'primary'}
              className={!renderAsPremiumTrial && 'ml-4'}
            >
              <Icon
                svg={PlayButton}
                size={Size.SMALL}
                className={'mr-2'}
                theme={!renderAsPremiumTrial ? Theme.PRIMARY : Theme.LIGHT}
              />
              {t(`Watch Video`)}
            </Button>
          </div>
        </div>
      </Tray>
    </div>
  );
};
