import { Theme, Toast } from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import MemberTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/MemberTimeEntryDataReport';
import { ITimeEntryDataReportMemberData } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import EmptyState from 'components/foundation/state-templates/EmptyState/EmptyState';
import PanelContent from 'components/layout/PanelContent/PanelContent';
import TimesheetPrintHeader from 'containers/timesheets/TimesheetPrintHeader/TimesheetPrintHeader';
import { useOpenable, useQueryParams, useReduxSelector } from 'hooks';
import { isEmpty, isNil, map, partition } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/timesheets/Timesheets';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { QueryParam } from 'utils/constants/queryParam';
import './TimeEntryReport.scss';

export interface ITimeEntryReportProps {
  className?: ClassName;
  memberIds?: string[] | null;
  // Callback to handle refreshing previous data set
  onDataChange?: (members: string[]) => void;
  data?: ITimeEntryDataReportMemberData[];
  loading?: boolean;
  timeRange: ITimeRange<DateTime>;
  isLoadedAll: boolean;
  rowHeight?: TableCellHeight;
}

const TimeEntryReport = (props: ITimeEntryReportProps) => {
  const { className, memberIds, data, loading, onDataChange, timeRange, isLoadedAll, rowHeight } = props;

  const [t] = useTranslation();

  const { getParam } = useQueryParams();
  const memberId = getParam(QueryParam.EMPLOYEE_ID);
  const columnSettings = useReduxSelector((state) => state.timesheet.timeEntryTableColumns);
  const selectedIds = useReduxSelector((state) =>
    state.timesheet.checkedTimeEntryIds.concat(state.timesheet.checkedTimeOffIds)
  );
  const dispatch = useDispatch();

  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));

  const onSelectChange = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const classes = classNames('time-entry-report', className);

  function handleDataChange(memberIds: string[]) {
    onDataChange?.(memberIds);
  }

  return (
    <>
      <TimesheetPrintHeader title={t('Time Entries')} timeRange={timeRange} />
      <PanelContent className={classes}>
        <MemberTimeEntryDataReport
          columnSettings={columnSettings}
          selectedIds={selectedIds}
          onSelectChange={onSelectChange}
          onDataChange={handleDataChange}
          data={data ?? []}
          timeRange={timeRange}
          loading={loading}
          rowHeight={rowHeight}
        />
        <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>
      </PanelContent>
      {isLoadedAll && isNil(memberId) && isNilOrEmpty(memberIds) && isEmpty(data) && !loading && (
        <EmptyState className="empty-state" title={t('No time entries in the specified date range.')} />
      )}
    </>
  );
};

TimeEntryReport.defaultProps = {
  type: null,
};

export default TimeEntryReport;
