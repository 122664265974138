import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';
import Spinner from '../../Spinner/Spinner';
import StateTemplate from '../StateTemplate/StateTemplate';

export interface ILoadingStateProps {
  title?: string;
  subtitle?: string;
  footer?: ReactNode;
  className?: ClassName;
}

const LoadingState: React.FunctionComponent<ILoadingStateProps> = (props) => {
  const { title, subtitle, footer, className } = props;

  const classes = classNames('loading-state', className);

  return <StateTemplate graphic={<Spinner />} title={title} subtitle={subtitle} footer={footer} className={classes} />;
};

export default LoadingState;
