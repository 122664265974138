import { GraphQLClient } from 'graphql-request';
import useKeyAuthorization from 'hooks/auth/useKeyAuthorization/useKeyAuthorization';
import useEnvironment from 'hooks/store/useEnvironment';
import { useMemo } from 'react';

export default function useGraphQLClient() {
  const url = useEnvironment().REACT_APP_GRAPHQL_URL;
  const keyAuth = useKeyAuthorization();
  const graphqlClient = useMemo(
    // graphql-request treats [] as a truthy value resulting in an error but we return [] when there are no errors so
    // the error policy must be set
    () => new GraphQLClient(url, { headers: { ['key-authorization']: keyAuth }, errorPolicy: 'all' }),
    [keyAuth, url]
  );
  return graphqlClient;
}
