import { Button, ClassName, Label, Textarea } from '@busybusy/webapp-react-ui';
import {
  FlaggedTimeEntryLocation,
  FlaggedTimeEntryLocationStatus,
  FlaggedTimeEntryLocationUpdateInput,
} from '__generated__/graphql';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import BasicNonNullPicker from 'components/foundation/pickers/basic-picker/BasicNonNullPicker/BasicNonNullPicker';
import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { t } from 'i18next';
import { useState } from 'react';

export interface IGpsEditAuthorizationDialogProps {
  className?: ClassName;
  isOpen: boolean;
  currentData: FlaggedTimeEntryLocation;
  onSubmit: (data: FlaggedTimeEntryLocationUpdateInput) => void;
  onClose: () => void;
}

const GpsEditAuthorizationDialog = (props: IGpsEditAuthorizationDialogProps) => {
  const { className, isOpen, currentData, onSubmit, onClose } = props;

  const [comment, setComment] = useState<string>('');
  const [currentSelection, setCurrentSelection] = useState<FlaggedTimeEntryLocationStatus>(
    FlaggedTimeEntryLocationStatus.Authorized
  );

  const data: Array<IBasicPickerValue<FlaggedTimeEntryLocationStatus>> = [
    { id: FlaggedTimeEntryLocationStatus.Authorized, name: t('Authorized') },
    { id: FlaggedTimeEntryLocationStatus.Unauthorized, name: t('Unauthorized') },
  ];

  const handleSubmit = () => {
    const newLocationData: FlaggedTimeEntryLocationUpdateInput = {
      id: currentData.id,
      status: currentSelection,
      comment,
    };
    onSubmit(newLocationData);
  };

  const handleClose = () => {
    onClose();
  };

  const classes = classNames('gps-edit-dialog m-5', className);

  return (
    <HeaderDialog isOpen={isOpen} title={t('Edit Location Authorization')} onClose={handleClose}>
      <div className={classes}>
        <div className={'mb-5'}>
          <Label>{t('Status')}</Label>
          <BasicNonNullPicker onSelect={setCurrentSelection} value={currentSelection} data={data} />
        </div>
        <Label>
          {t('Comments')} {' - '}
          <span className="sub-label-text">{t('Optional')}</span>
        </Label>
        <Textarea value={comment} onChange={setComment} restrictTo={{ maxLength: 1000 }} />
        <Button type="primary" onClick={handleSubmit} className={'mt-5'}>
          {t('Submit')}
        </Button>
      </div>
    </HeaderDialog>
  );
};

export default GpsEditAuthorizationDialog;
