import { UnknownAction } from 'redux';
import IProject from '../../../types/Project';
import { PROJECTS_SET_SELECTED_PROJECT } from '../projectsConstants';

export interface IStoreProjectsSetSelectedProjectAction extends UnknownAction {
  type: typeof PROJECTS_SET_SELECTED_PROJECT;
  payload: {
    selectedProject: IProject | null;
  };
}

export interface IStoreProjectsSetProjectsProps {
  projects: IProject[];
}

export interface IStoreProjectsSetSelectedProjectProps {
  selectedProject: IProject | null;
}

export const projectsSetSelectedProjectAction = (
  payload: IStoreProjectsSetSelectedProjectProps
): IStoreProjectsSetSelectedProjectAction => ({
  payload,
  type: PROJECTS_SET_SELECTED_PROJECT,
});
