import useActiveMember from 'hooks/store/useActiveMember';
import useLocalStorage from 'hooks/utils/useLocalStorage';
import isNil from 'lodash/isNil';
import { DateTime, Duration } from 'luxon';
import { type StoreAuthMemberProper } from 'store/authMember/authMemberType';
import { type IStoreSessionAuthenticated } from 'store/session/sessionType';
import { dateTimeFromUtcISO } from 'utils/dateUtils';
import { type PolicyEntry } from './Policies/queries';
import { POLICY_KEY_PRIVACY, POLICY_KEY_TERMS, type TPolicyKey } from './Policies/types';
import { type PolicyMemberAcceptanceEntry } from './PolicyMemberAcceptances/queries';

export type TPolicyCacheDataState = Pick<PolicyEntry, 'version' | 'effectiveDate'>;

export type TPolicyCacheData =
  | undefined
  | {
      lastUpdate: string;
      agreedPolicy?: TPolicyCacheDataState | undefined;
      effectivePolicy?: TPolicyCacheDataState | undefined;
      nextPolicy?: TPolicyCacheDataState | undefined;
    };

export const defaultPolicyCacheData: TPolicyCacheData = undefined;

export const POLICY_RECHECK_TIMEOUT_DURATION = Duration.fromObject({ hours: 12 });

export const getAgreementsLocalStorageKey = (key: TPolicyKey, member: StoreAuthMemberProper) => {
  return [`validate-agreements`, member.organizationId, member.id, key].join('::');
};

export const useAgreementLocalStorageTerms = () => {
  const member = useActiveMember();
  const key = getAgreementsLocalStorageKey(POLICY_KEY_TERMS, member);
  return useLocalStorage<TPolicyCacheData>(key, defaultPolicyCacheData);
};

export const useAgreementLocalStoragePrivacy = () => {
  const member = useActiveMember();
  const key = getAgreementsLocalStorageKey(POLICY_KEY_PRIVACY, member);
  return useLocalStorage<TPolicyCacheData>(key, defaultPolicyCacheData);
};

export const CACHE_INVALID_REASON_EMPTY = 'NoCache';
export const CACHE_INVALID_REASON_CACHE = 'InvalidCache';
export const CACHE_INVALID_REASON_LOGIN = 'NewLogin';
export const CACHE_INVALID_REASON_STALE = 'StaleCache';
export const CACHE_INVALID_REASON_NO_AGREEMENT = 'NoAgreement';
export const CACHE_INVALID_REASON_AGREEMENT_STALE = 'NotAgreed';
export const CACHE_INVALID_REASON_NEW_POLICY = 'NewPolicy';

export const determineCacheValid = (
  policyCache: TPolicyCacheData,
  session: IStoreSessionAuthenticated
): { isValid: boolean; reason?: string } => {
  const nowUtc = DateTime.utc();
  const nowLocal = DateTime.local();

  try {
    const effectiveVersion = policyCache?.effectivePolicy?.version;
    const agreedVersion = policyCache?.agreedPolicy?.version;

    // no cache
    if (isNil(policyCache)) return { isValid: false, reason: CACHE_INVALID_REASON_EMPTY };

    const lastUpdateDate = dateTimeFromUtcISO(policyCache.lastUpdate);

    // unexpected data in cache
    if (!lastUpdateDate.isValid) return { isValid: false, reason: CACHE_INVALID_REASON_CACHE };

    // logged in since last check
    const sessionDateTime = DateTime.fromSeconds(session.timestamp, { zone: 'utc' });
    if (sessionDateTime > lastUpdateDate) return { isValid: false, reason: CACHE_INVALID_REASON_LOGIN };

    // cache is stale
    const cacheStaleAt = lastUpdateDate.plus(POLICY_RECHECK_TIMEOUT_DURATION);
    const isCacheStale = cacheStaleAt < nowUtc;
    if (isCacheStale) return { isValid: false, reason: CACHE_INVALID_REASON_STALE };

    // check current policy
    if (!isNil(effectiveVersion)) {
      // not known to have agreed to any policy
      if (isNil(agreedVersion)) return { isValid: false, reason: CACHE_INVALID_REASON_NO_AGREEMENT };

      // has not agreed to the current policy
      const isEffectivePolicyAgreed = agreedVersion >= effectiveVersion;
      if (!isEffectivePolicyAgreed) return { isValid: false, reason: CACHE_INVALID_REASON_AGREEMENT_STALE };
    }

    // check scheduled policy updates
    if (policyCache.nextPolicy?.effectiveDate) {
      // interpret the effective ISO Date as local time
      const nextEffectiveDate = DateTime.fromISO(policyCache.nextPolicy.effectiveDate, { zone: 'local' });
      const newPolicyInEffect = nowLocal >= nextEffectiveDate;
      if (newPolicyInEffect) return { isValid: false, reason: CACHE_INVALID_REASON_NEW_POLICY };
    }

    return { isValid: true };
  } catch (e) {
    return { isValid: false, reason: `ERROR: ${e}` };
  }
};

export const updatePolicyLocalStorageCache = (
  setter: (val: TPolicyCacheData) => void,
  lastUpdate: string | undefined,
  policyMemberAcceptance: PolicyMemberAcceptanceEntry | undefined,
  effectivePolicy: PolicyEntry | undefined,
  nextPolicy: PolicyEntry | undefined
) => {
  if (isNil(lastUpdate)) {
    return setter(undefined);
  }

  const agreedPolicyCache = policyMemberAcceptance
    ? {
        version: policyMemberAcceptance.policy.version,
        effectiveDate: policyMemberAcceptance.policy.effectiveDate,
      }
    : undefined;
  const effectivePolicyCache = effectivePolicy
    ? {
        version: effectivePolicy.version,
        effectiveDate: effectivePolicy.effectiveDate,
      }
    : undefined;

  const nextPolicyCache = nextPolicy
    ? {
        version: nextPolicy.version,
        effectiveDate: nextPolicy.effectiveDate,
      }
    : undefined;

  return setter({
    lastUpdate,
    agreedPolicy: agreedPolicyCache,
    effectivePolicy: effectivePolicyCache,
    nextPolicy: nextPolicyCache,
  });
};
