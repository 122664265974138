import { Button, Loader, Tray } from '@busybusy/webapp-react-ui';
import { TIME_ENTRIES_WITH_MEMBER_POSITION } from 'apollo/queries/time-entry-queries';
import { TIME_OFF_QUERY } from 'apollo/queries/time-off-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Panel } from 'components';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import EditTimePermissionWell from 'components/domain/time-entry/EditTimePermissionWell/EditTimePermissionWell';
import useTimeActionsForm from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { useApolloPaging, useOpenable, usePermissions, useTimeEntry, useTimeOff } from 'hooks';
import { first, isEmpty, isNil, last, sortBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import ITimeEntry from 'types/TimeEntry';
import ITimeOff from 'types/TimeOff';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import './DeleteTimeEntryOrTimeOffDialog.scss';

export interface IDeleteTimeEntryOrTimeOffDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  handleDelete?: (memberIds: string[], timeEntryIds: string[], timeOffIds: string[]) => void;
  handleError?: (err: string) => void;
  timeEntryIds?: string[] | null;
  timeOffIds?: string[] | null;
}

interface IDeleteValidationData {
  startTime: DateTime;
  endTime: DateTime;
  memberId: string;
}

const DeleteTimeEntryOrTimeOffDialog = (props: IDeleteTimeEntryOrTimeOffDialogProps) => {
  const { className, isOpen, onClose, handleDelete, handleError, timeEntryIds, timeOffIds } = props;

  const { deleteEntry } = useTimeEntry();
  const { deleteTimeOff } = useTimeOff();
  const loaderDetails = useOpenable();
  const signatureViolationDetails = useOpenable();
  const deleteDetails = useOpenable();
  const apolloPaging = useApolloPaging();
  const timeEntries = useRef<ITimeEntry[]>([]);
  const timeOffs = useRef<ITimeOff[]>([]);
  const validationData = useRef<IDeleteValidationData[]>([]);
  const { hasSignatureConflicts, inhibitsActionByLockDate } = useTimeActionsForm();
  const lockDetails = useOpenable();
  const { hasPermissionToManage } = usePermissions();

  useEffect(() => {
    if (isOpen) {
      getEntries();
    }
  }, [isOpen, timeEntryIds, timeOffIds]);

  async function getEntries() {
    loaderDetails.open();
    validationData.current = [];
    timeEntries.current = [];
    timeOffs.current = [];

    try {
      if (!isNil(timeEntryIds) && !isEmpty(timeEntryIds)) {
        const result = await apolloPaging.getAll<ITimeEntry>('timeEntries', {
          query: TIME_ENTRIES_WITH_MEMBER_POSITION,
          variables: {
            filter: {
              id: { contains: timeEntryIds },
              deletedOn: { isNull: true },
            },
            sort: [{ startTime: 'asc' }],
          },
        });
        timeEntries.current = result;
        validationData.current = validationData.current.concat(
          result.map((t) => {
            return {
              startTime: dateTimeFromISOKeepZone(t.startTime),
              endTime: t.endTime ? dateTimeFromISOKeepZone(t.endTime) : DateTime.local().endOf('day'),
              memberId: t.memberId,
            };
          })
        );
      }

      if (!isNil(timeOffIds) && !isEmpty(timeOffIds)) {
        const timeOffResult = await apolloPaging.getAll<ITimeOff>('timeOffs', {
          query: TIME_OFF_QUERY,
          variables: {
            filter: {
              id: { contains: timeOffIds },
              deletedOn: { isNull: true },
            },
            sort: [{ dateStamp: 'asc' }, { createdOn: 'asc' }],
          },
        });

        timeOffs.current = timeOffResult;
        validationData.current = validationData.current.concat(
          timeOffResult.map((t) => {
            return {
              startTime: dateTimeFromISOKeepZone(t.dateStamp),
              endTime: dateTimeFromISOKeepZone(t.dateStamp),
              memberId: t.member.id,
            };
          })
        );
      }

      validationData.current = sortBy(validationData.current, 'startTime');
      const firstValidation = first(validationData.current);
      const lastValidation = last(validationData.current);
      if (firstValidation) {
        const memberIds = uniq(validationData.current.map((v) => v.memberId));
        const hasLockDateValidation = await inhibitsActionByLockDate(memberIds, firstValidation.startTime);

        if (hasLockDateValidation) {
          loaderDetails.close();
          lockDetails.open();
          return;
        }

        if (lastValidation) {
          const hasSignatureValidation = await hasSignatureConflicts(memberIds, {
            startTime: firstValidation.startTime,
            endTime: lastValidation.endTime,
          });
          if (hasSignatureValidation) {
            loaderDetails.close();
            signatureViolationDetails.open();
            return;
          }
        }
      }

      if (!isEmpty(validationData.current)) {
        deleteDetails.open();
      }
      loaderDetails.close();
    } catch (e) {
      handleError?.(t('Something went wrong when deleting your entries'));
      loaderDetails.close();
    }
  }

  async function performDelete() {
    loaderDetails.open();

    const manageableEntries = timeEntries.current.filter((entry) =>
      hasPermissionToManage(entry.member, 'manageTimeEntries')
    );
    const manageAbleTimeOffs = timeOffs.current.filter((timeOff) =>
      hasPermissionToManage(timeOff.member, 'manageTimeOff')
    );

    try {
      await Promise.all(manageableEntries.map(deleteEntry));
      await Promise.all(manageAbleTimeOffs.map(deleteTimeOff));
      loaderDetails.close();
      deleteDetails.close();
      handleDelete?.(
        uniq(validationData.current.map((v) => v.memberId)),
        manageableEntries.map(({ id }) => id),
        manageAbleTimeOffs.map(({ id }) => id)
      );
    } catch (e) {
      handleError?.(t('Something went wrong when deleting your entries'));
      loaderDetails.close();
    }
  }

  function handleClose() {
    signatureViolationDetails.close();
    deleteDetails.close();
    lockDetails.close();
    onClose();
  }

  function canManage(): boolean {
    const canManageEntries = timeEntries.current
      .map((entry) => entry.member)
      .some((member) => hasPermissionToManage(member, 'manageTimeEntries'));

    const canManageTimeOffs = timeOffs.current
      .map((entry) => entry.member)
      .some((member) => hasPermissionToManage(member, 'manageTimeOff'));

    return canManageEntries || canManageTimeOffs;
  }

  const classes = classNames('delete-time-entry-or-time-off-dialog', className);

  return (
    <>
      <Loader isOpen={loaderDetails.isOpen} />
      <HeaderDialog
        title={t('Signed Time Card')}
        isOpen={signatureViolationDetails.isOpen}
        onClose={handleClose}
        divider={false}
      >
        <Panel className={'p-5'}>
          {t(
            'One or more entries belong to a time card that has already been signed. Continuing will require the employee to re-sign.'
          )}
          <Tray className="tray-right pt-6" align="left">
            <Button className="right-button" type="secondary" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <Button
              className="right-button"
              type="primary"
              onClick={() => {
                if (signatureViolationDetails.isOpen) {
                  signatureViolationDetails.close();
                  lockDetails.close();
                  deleteDetails.open();
                }
              }}
            >
              {t('Continue')}
            </Button>
          </Tray>
        </Panel>
      </HeaderDialog>
      <LockDateDialog
        date={first(validationData.current)?.startTime ?? DateTime.local().startOf('day')}
        type={validationData.current.length === 1 ? LockDateDialogType.SINGLE_EDIT : LockDateDialogType.MULTIPLE_EDIT}
        memberIds={uniq(validationData.current.map((v) => v.memberId))}
        isOpen={lockDetails.isOpen}
        onClose={handleClose}
        onMoveLockDate={async () => {
          if (lockDetails.isOpen) {
            await getEntries();
            lockDetails.close();
          }
        }}
      />
      <HeaderDialog title={t('Delete time data?')} isOpen={deleteDetails.isOpen} onClose={handleClose}>
        <div className={'p-4'}>
          <EditTimePermissionWell timeEntries={timeEntries.current} timeOffs={timeOffs.current} />
          {canManage() && (
            <div className={classes}>
              {t('Are you sure you want to delete the selected entries?')}
              <Tray className="tray-right" align="left">
                <Button className="right-button" type="secondary" onClick={handleClose}>
                  {t('Cancel')}
                </Button>
                <Button className="right-button" type="danger" onClick={performDelete}>
                  {t('Delete')}
                </Button>
              </Tray>
            </div>
          )}
        </div>
      </HeaderDialog>
    </>
  );
};

export default DeleteTimeEntryOrTimeOffDialog;
