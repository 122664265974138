import { useOrganization } from 'hooks';
import { useMemo } from 'react';
import { IOrganizationSettings } from '../types/OrganizationSettings';

export function useOrganizationSettings() {
  const { organizationSettings } = useOrganization();
  return useMemo(
    () => (organizationSettings ? (JSON.parse(organizationSettings) as IOrganizationSettings) : null),
    [organizationSettings]
  );
}
