import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IMemberGlobalSettings } from 'types/MemberGlobalSettings';
import IMemberSettings from 'types/MemberSettings';
import { useActiveMemberUpdate } from '..';
import useMemberSettings from './useMemberSettings';

export default function useMemberGlobalSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const [updateKey] = useActiveMemberUpdate();

  return useCallback(
    <K extends keyof IMemberGlobalSettings>(key: K, payload: IMemberGlobalSettings[K]) => {
      const cloned = cloneDeep(memberSettings);

      const updated: IMemberSettings = cloned
        ? {
            ...cloned,
            global: {
              ...cloned?.global,
              [key]: payload,
            },
          }
        : {
            global: {
              [key]: payload,
            },
          };

      return updateKey('memberSettings', JSON.stringify(updated));
    },
    [memberSettings, updateKey]
  );
}
