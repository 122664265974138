import { Theme } from '@busybusy/webapp-react-ui';
import {
  IncidentAnswerCreateInput,
  IncidentAnswerTypes,
  IncidentCreateInput,
  IncidentMedicalDetailsCreateInput,
  IncidentOutcomeDetailsCreateInput,
  IncidentStates,
} from '__generated__/graphql';
import { useCreateIncident } from 'containers/safety-incident-reports/hooks/useCreateIncident/useCreateIncident';
import { useCreateIncidentAnswer } from 'containers/safety-incident-reports/hooks/useCreateIncidentAnswer/useCreateIncidentAnswer';
import { useCreateIncidentMedicalDetails } from 'containers/safety-incident-reports/hooks/useCreateIncidentMedicalDetails/useCreateIncidentMedicalDetails';
import { useCreateIncidentOutcomeDetails } from 'containers/safety-incident-reports/hooks/useCreateIncidentOutcomeDetails/useCreateIncidentOutcomeDetails';
import { IncidentAnswerQuestions } from 'containers/safety-incident-reports/types/types';
import { useToastOpen } from 'contexts/ToastContext';
import { useActiveMember, useSafetySignature } from 'hooks';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ISafetySignature from 'types/SafetySignature';
import { dateTimeFromISOWithoutZone, dateTimeFromUtcISO, getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { ISubmitSafetyIncidentFormData } from '../SubmitSafetyIncidentForm';

export function useCreateSafetyIncident() {
  const createIncident = useCreateIncident();
  const createIncidentAnswer = useCreateIncidentAnswer();
  const createIncidentMedicalDetails = useCreateIncidentMedicalDetails();
  const createIncidentOutcomeDetails = useCreateIncidentOutcomeDetails();
  const toast = useToastOpen();
  const { resolveSafetySignature } = useSafetySignature();
  const activeMember = useActiveMember();

  return useCallback(
    async (formData: ISubmitSafetyIncidentFormData, safetySignature?: ISafetySignature): Promise<boolean> => {
      let occurredAt: string = dateTimeFromUtcISO(DateTime.fromJSDate(formData.occurredAtDate!.value!).toISO()).toISO();
      let employeeStartTime: string = dateTimeFromUtcISO(
        DateTime.fromJSDate(formData.occurredAtDate!.value!).toISO()
      ).toISO();

      if (!isNil(formData.occurredAtTime)) {
        const mutableOccurredAtDate = formData.occurredAtDate!.value!;
        mutableOccurredAtDate.setHours(formData.occurredAtTime.hour, formData.occurredAtTime.minute);
        occurredAt = dateTimeFromISOWithoutZone(DateTime.fromJSDate(mutableOccurredAtDate).toISO()).toISO();
      }

      if (!isNil(formData.employeeStartTime)) {
        const mutableOccurredAtDate = formData.occurredAtDate!.value!;
        mutableOccurredAtDate.setHours(formData.employeeStartTime.hour, formData.employeeStartTime.minute);
        employeeStartTime = dateTimeFromISOWithoutZone(DateTime.fromJSDate(mutableOccurredAtDate).toISO()).toISO();
      }

      try {
        const createIncidentInput: IncidentCreateInput = {
          memberId: formData.memberId!,
          type: formData.type!,
          projectId: formData.projectId,
          recordable: formData.recordable,
          state: IncidentStates.Pending,
          illnessType: formData.illnessType,
          occurredAt: occurredAt,
          occurrenceTimeSet: !isNil(formData.occurredAtTime) ? true : false,
          employeeStartTime: employeeStartTime,

          createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        };

        if (formData.recordable) {
          createIncidentInput.employeeJobTitle = formData.employeeJobTitle ?? undefined;
          createIncidentInput.employeeBirthday = formData.employeeBirthday
            ? formData.employeeBirthday.toISODate()
            : undefined;
          createIncidentInput.employeeHireDate = formData.employeeHireDate
            ? formData.employeeHireDate.toISODate()
            : undefined;
          createIncidentInput.employeeSex = formData.employeeSex ?? undefined;
          createIncidentInput.employeeAddress1 = formData.employeeAddress1 ?? undefined;
          createIncidentInput.employeeCity = formData.employeeCity ?? undefined;
          createIncidentInput.employeeState = formData.employeeState ?? undefined;
          createIncidentInput.employeePostalCode = formData.employeePostalCode ?? undefined;
        }

        const createIncidentResult = await createIncident(createIncidentInput);

        const createIncidentAnswerInputs: IncidentAnswerCreateInput[] = [];

        createIncidentAnswerInputs.push({
          incidentId: createIncidentResult.id,
          type: IncidentAnswerTypes.ActivityBefore,
          question: IncidentAnswerQuestions.ActivityBefore,
          answer: formData.activityBefore!,
          order: createIncidentAnswerInputs.length + 1,
          createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        });

        createIncidentAnswerInputs.push({
          incidentId: createIncidentResult.id,
          type: IncidentAnswerTypes.WhatHappened,
          question: IncidentAnswerQuestions.WhatHappened,
          answer: formData.whatHappened!,
          order: createIncidentAnswerInputs.length + 1,
          createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        });

        createIncidentAnswerInputs.push({
          incidentId: createIncidentResult.id,
          type: IncidentAnswerTypes.TypeDescription,
          question: IncidentAnswerQuestions.TypeDescription,
          answer: formData.typeDescription!,
          order: createIncidentAnswerInputs.length + 1,
          createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        });

        if (formData.whatHarmed) {
          createIncidentAnswerInputs.push({
            incidentId: createIncidentResult.id,
            type: IncidentAnswerTypes.WhatHarmed,
            question: IncidentAnswerQuestions.WhatHarmed,
            answer: formData.whatHarmed,
            order: createIncidentAnswerInputs.length + 1,
            createdOn: getNowIsoAtUtcWithLocalTimeZone(),
          });
        }

        createIncidentAnswerInputs.push({
          incidentId: createIncidentResult.id,
          type: IncidentAnswerTypes.Location,
          question: IncidentAnswerQuestions.Location,
          answer: formData.location!,
          order: createIncidentAnswerInputs.length + 1,
          createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        });

        const incidentAnswerPromises = createIncidentAnswerInputs.map(async (input) => {
          return await createIncidentAnswer(input);
        });

        await Promise.all(incidentAnswerPromises);

        const createIncidentMedicalDetailsInput: IncidentMedicalDetailsCreateInput = {
          incidentId: createIncidentResult.id,
          physicianName: formData.physicianName!,
          facilityName: formData.facilityName!,
          facilityAddress: formData.facilityAddress!,
          facilityCity: formData.facilityCity!,
          facilityState: formData.facilityState!,
          facilityPostalCode: formData.facilityPostalCode!,
          treatmentER:
            formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentER')].checked,
          treatmentOvernight:
            formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentOvernight')]
              .checked,
          treatmentFirstAid:
            formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentFirstAid')]
              .checked,
          treatmentDoctor:
            formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentDoctor')]
              .checked,
          treatmentNone:
            formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentNone')]
              .checked,
          treatmentOther:
            formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentOther')]
              .checked,
          createdOn: getNowIsoAtUtcWithLocalTimeZone(),
        };

        await createIncidentMedicalDetails(createIncidentMedicalDetailsInput);

        if (formData.recordable && !isNil(formData.outcomeType)) {
          const createIncidentOutcomeDetailsInput: IncidentOutcomeDetailsCreateInput = {
            incidentId: createIncidentResult.id,
            type: formData.outcomeType,
            daysAway: formData.daysAway ?? undefined,
            daysTransfer: formData.daysTransfer ?? undefined,
            outcomeDate: formData.outcomeDate?.value ? formData.outcomeDate.value.toISOString() : undefined,
            createdOn: getNowIsoAtUtcWithLocalTimeZone(),
          };

          await createIncidentOutcomeDetails(createIncidentOutcomeDetailsInput);
        }

        // If the incident is created from a safety signature then mark the safety signature as resolved.
        if (!isNil(safetySignature)) {
          await resolveSafetySignature(
            safetySignature,
            activeMember.id!,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            createIncidentResult.id
          );
        }

        toast({ label: t('Report Created'), theme: Theme.SUCCESS });
        return true;
      } catch {
        toast({ label: t('Error: Report Not Created'), theme: Theme.DANGER });
        return false;
      }
    },
    [
      activeMember.id,
      createIncident,
      createIncidentAnswer,
      createIncidentMedicalDetails,
      createIncidentOutcomeDetails,
      resolveSafetySignature,
      toast,
    ]
  );
}
