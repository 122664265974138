import { MediaCollectionFilter, MemberPermissions, OperationType } from '__generated__/graphql';
import usePhotosViewCollectionPermissionEnabled from 'containers/photos/hooks/usPhotosViewCollectionPermissionEnabled/usePhotosViewCollectionPermissionEnabled';

const constantPermission: NonNullable<MediaCollectionCreatedByPermissionFilter> = {
  permissions: [MemberPermissions.ViewMediaCollection],
  operationType: OperationType.Or,
};

type MediaCollectionCreatedByPermissionFilter = NonNullable<MediaCollectionFilter['createdByMember']>['permissions'];

export default function useMediaCollectionViewPermissionCreatedByFilter(): NonNullable<
  MediaCollectionFilter['createdByMember']
>['permissions'] {
  const isPermissionToggledOn = usePhotosViewCollectionPermissionEnabled();

  if (!isPermissionToggledOn) {
    return undefined;
  }

  return constantPermission;
}
