import { useCallback, useMemo, useState } from 'react';

export interface IOpenableHook {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export interface IOpenableHookProps {
  onClose?: () => void;
  onOpen?: () => void;
  isOpen?: boolean;
}

export default function useOpenable(hookDetails?: IOpenableHookProps): IOpenableHook {
  const [isOpen, setIsOpen] = useState<boolean>(
    hookDetails && hookDetails.isOpen !== undefined ? hookDetails.isOpen : false
  );

  const open = useCallback(() => {
    setIsOpen(true);
    if (hookDetails && hookDetails.onOpen) {
      hookDetails.onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookDetails?.onOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
    if (hookDetails && hookDetails.onClose) {
      hookDetails.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookDetails?.onClose]);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return useMemo(() => ({ isOpen, open, close, toggle }), [close, isOpen, open, toggle]);
}
