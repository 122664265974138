import useNavigation from './useNavigation';

interface IParams {
  project_id?: string;
  subproject_id?: string;
}

export default function useProjectDashboardNavigation() {
  return useNavigation<IParams>('/project-dashboard');
}
