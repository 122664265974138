import { Optional } from 'types/util/Optional';
import { Maybe } from '__generated__/graphql';

const emptyList: any[] = [];

export function getSafeArray<T>(array: Optional<T[]>): T[] {
  return array ?? (emptyList as T[]);
}

export function getSafeNumber(n: Optional<number>): number {
  return n ?? 0;
}

export function getSafeString(s: Optional<string>): string {
  return s ?? '';
}

export function getSafeBoolean(b: Optional<boolean>): boolean {
  return b ?? false;
}

export function runIfDefined<T, R>(value: Optional<T>, fn: (value: T) => R) {
  if (value) {
    return fn(value);
  }

  return null;
}

export function coalesceMaybe<T>(maybe: Maybe<T> | undefined): maybe is T {
  if (maybe) {
    return !!maybe;
  }

  return false;
}
