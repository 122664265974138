import { EQUIPMENT_DISPLAY_FRAGMENT } from 'apollo/fragments/equipment-fragments';
import gql from 'graphql-tag';

export const EQUIPMENT_QUERY = gql`
  query queryEquipment($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      importTtlSeconds
      importType
      lastHoursId
      imageUrl
      lastHours {
        id
        runningHours
        updatedOn
      }
      lastLocationId
      lastLocation {
        id
        longitude
        latitude
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          review
          submittedOn
          title
          type {
            id
            title
          }
          updatedOn
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
    }
  }
`;

export const EQUIPMENT_NAME_QUERY = gql`
  query queryEquipmentName($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      id
      equipmentName
      model {
        category {
          id
          title
        }
        id
        make {
          id
          title
        }
        modelNumber
        title
        year
      }
      organizationId
    }
  }
`;

export const BASIC_EQUIPMENT_QUERY = gql`
  query queryBasicEquipmentName($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      id
      equipmentName
      model {
        category {
          id
          title
        }
        id
        make {
          id
          title
        }
        modelNumber
        title
        year
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      organizationId
    }
  }
`;

export const EQUIPMENT_WITH_CATEGORY_QUERY = gql`
  query queryBasicEquipmentWithCategory(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      cursor
      id
      model {
        id
        category {
          id
          title
        }
      }
    }
  }
`;

export const EQUIPMENT_BY_ID_QUERY = gql`
  query queryEquipmentById($equipmentId: Uuid!) {
    equipment(filter: { id: { equal: $equipmentId } }) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      imageUrl
      importTtlSeconds
      importType
      lastHoursId
      lastLocationId
      lastHours {
        id
        runningHours
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          imageUrl
          review
          submittedOn
          title
          updatedOn
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        imageUrl
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
      lastLocation {
        id
        longitude
        latitude
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;

export const MANAGE_EQUIPMENT_QUERY = gql`
  query queryManageEquipment($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      archivedOn
      createdOn
      cursor
      deletedOn
      equipmentGroupId
      equipmentModelId
      equipmentName
      fuelCapacity
      id
      imageId
      imageUrl
      importTtlSeconds
      importType
      lastHoursId
      lastLocationId
      updatedByMember {
        id
        firstName
        lastName
      }
      model {
        category {
          deletedOn
          equipmentTypeId
          id
          imageId
          imageUrl
          review
          submittedOn
          title
          updatedOn
          type {
            id
            title
          }
        }
        deletedOn
        equipmentCategoryId
        equipmentMakeId
        id
        imageId
        imageUrl
        make {
          deletedOn
          id
          imageId
          review
          submittedOn
          title
          unknown
          updatedOn
        }
        modelNumber
        submittedOn
        title
        unknown
        updatedOn
        year
      }
      organizationId
      serialNumber
      submittedOn
      trackManualHours
      updatedOn
      lastHours {
        id
        runningHours
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;

export const EQUIPMENT_IDS_QUERY = gql`
  query queryEquipmentIds($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      cursor
    }
  }
`;

export const SIMPLE_EQUIPMENT_QUERY = gql`
  query queryBasicEquipment($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
    }
  }
`;

export const EQUIPMENT_LAST_LOCATION_QUERY = gql`
  query queryEquipmentLastLocation($after: String, $filter: EquipmentFilter, $first: Int) {
    equipment(after: $after, first: $first, filter: $filter, sort: [{ equipmentName: asc }]) {
      id
      cursor
      deletedOn
      equipmentName
      lastLocationId
      lastLocation {
        id
        longitude
        latitude
      }
    }
  }
`;

export const EQUIPMENT_DISPLAY_QUERY = gql`
  query EquipmentDisplay($after: String, $filter: EquipmentFilter, $first: Int, $sort: [EquipmentSort!]) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      ...EquipmentDisplay
    }
  }
  ${EQUIPMENT_DISPLAY_FRAGMENT}
`;

export const EQUIPMENT_CLOCK_STATE_QUERY = gql`
  query EquipmentClockState($filter: EquipmentClockStateFilter) {
    equipmentClockState(filter: $filter) {
      all
      clockedIn
      clockedOut
      onBreak
    }
  }
`;
