import { gql } from '@apollo/client';

export const SIMPLE_SUBMISSIONS_REPORT_QUERY = gql`
  query SimpleSubmissions(
    $after: String
    $filter: MemberSignInSubmissionFilter
    $first: Int
    $sort: [MemberSignInSubmissionSort!]
  ) {
    memberSignInSubmissions(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      flagged
      cursor
      createdOn
      createdOnLocal
      deviceType
      signatureUrl
      localTime
      restricted
      resolverId
      proxyMemberId
    }
  }
`;

export const SUBMISSIONS_REPORT_QUERY = gql`
  query submissions(
    $after: String
    $filter: MemberSignInSubmissionFilter
    $first: Int
    $sort: [MemberSignInSubmissionSort!]
  ) {
    memberSignInSubmissions(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      memberId
      flagged
      cursor
      createdOn
      createdOnLocal
      deviceType
      signatureUrl
      localTime
      restricted
      resolverId
      proxyMemberId
      proxyMember {
        id
        firstName
        lastName
      }
      answers {
        id
        questionTitle
        answer
        flagged
        description
        questionDescription
        createdOn
        questionPrompt
        resolverId
        audienceType
        positionId
        resolverMember {
          id
          firstName
          lastName
        }
        resolvedOn
        resolvedComment
      }
      member {
        id
        firstName
        lastName
        imageUrl
        archivedOn
        position {
          id
          level
        }
      }
    }
  }
`;
