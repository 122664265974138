import { Divider } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { DateTime } from 'luxon';
import IProjectCostCodeQuantity from 'types/ProjectCostCodeQuantity';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { dateTimeFromISOKeepZone, getDateName, getDateNameWithYearFormat } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import './ProjectCostCodeQuantityView.scss';

export interface IProjectCostCodeQuantityViewProps {
  className?: ClassName;
  quantity: IProjectCostCodeQuantity;
}

const ProjectCostCodeQuantityView = ({ className, quantity }: IProjectCostCodeQuantityViewProps) => {
  const classes = classNames('project-cost-code-quantity-view', className);

  return (
    <div className={classes}>
      <div className={'p-8'}>
        <HeaderText title={t('Project')} className={'mb-6'}>
          <span>{getFormattedPathFromProject(quantity.project, true)}</span>
        </HeaderText>
        <HeaderText title={t('Cost Code')} className={'mb-6'}>
          <span>{getCostCodeDisplay(quantity.costCode)}</span>
        </HeaderText>
        <HeaderText title={t('Quantity')} className={'mb-6'}>
          <span>
            {quantity.amount.toString() +
              ' ' +
              (quantity.costCode.unitTitle ? quantity.costCode.unitTitle : t('units'))}
          </span>
        </HeaderText>
        <HeaderText title={t('Progress Date')} className={'mb-6'}>
          <span>{getDateName(DateTime.fromISO(quantity.assignedOn), 'ccc, LLL d')}</span>
        </HeaderText>
        <HeaderText title={t('Note')} className={'mb-6'}>
          <span>{quantity.description ? quantity.description : '---'}</span>
        </HeaderText>
      </div>
      <Divider />
      <div className={'p-8 mb-6'}>
        <HeaderText title={t('Submitted By')} className={'mb-6'}>
          <FormattedEmployeeName
            firstName={quantity.submittedByMember.firstName ?? ''}
            lastName={quantity.submittedByMember.lastName ?? ''}
          />
        </HeaderText>
        <HeaderText title={t('Submitted On')} className={'mt-6 mb-6'}>
          <span>
            {getDateNameWithYearFormat(
              dateTimeFromISOKeepZone(quantity.createdOnLocal),
              'ccc, LLL d @ t',
              DateTimeFormat.dateAtTimeDay
            )}
          </span>
        </HeaderText>
        <HeaderText title={t('Last Edited By')} className={'mb-6'}>
          {quantity.lastEditedByMember ? (
            <FormattedEmployeeName
              firstName={quantity.lastEditedByMember.firstName ?? ''}
              lastName={quantity.lastEditedByMember.lastName ?? ''}
            />
          ) : (
            '---'
          )}
        </HeaderText>
      </div>
    </div>
  );
};

export default ProjectCostCodeQuantityView;
