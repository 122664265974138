import gql from 'graphql-tag';

export const CREATE_PROJECT_COST_CODE_QUANTITY = gql`
  mutation CreateProjectCostCodeQuantity($projectCostCodeQuantity: ProjectCostCodeQuantityCreateInput!) {
    createProjectCostCodeQuantity(projectCostCodeQuantity: $projectCostCodeQuantity) {
      id
      amount
      assignedOn
      lastEditedByMember {
        id
        firstName
        lastName
      }
      costCode {
        id
        costCode
        title
        unitTitle
      }
      costCodeId
      createdOnLocal
      description
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
          archivedOn
        }
      }
      projectId
      submittedByMember {
        id
        firstName
        lastName
        memberGroupId
        position {
          id
          level
          canManageProjectCostCodeQuantity
          timeEvents
        }
      }
      submittedBy
      type
    }
  }
`;

export const UPDATE_PROJECT_COST_CODE_QUANTITY = gql`
  mutation updateProjectCostCodeQuantity($projectCostCodeQuantity: ProjectCostCodeQuantityUpdateInput!) {
    updateProjectCostCodeQuantity(projectCostCodeQuantity: $projectCostCodeQuantity) {
      id
      amount
      assignedOn
      lastEditedByMember {
        id
        firstName
        lastName
      }
      costCode {
        id
        costCode
        title
        unitTitle
      }
      costCodeId
      createdOnLocal
      description
      project {
        id
        title
        depth
        ancestors {
          id
          title
          depth
          archivedOn
        }
      }
      projectId
      submittedByMember {
        id
        firstName
        lastName
        position {
          id
          level
        }
      }
      submittedBy
      type
    }
  }
`;
