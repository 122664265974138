import { Bar, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';

import './Header.scss';

export interface IHeaderProps {
  stroke: boolean;
  actionBar?: ReactNode;
  className?: ClassName;
  size?: Size;
  children?: ReactNode;
}

function Header(props: IHeaderProps) {
  const { actionBar, stroke, size, className, children } = props;

  const classes = classNames(
    {
      'header': true,
      'header-stroke': stroke,
    },
    className
  );

  return (
    <div className={classes}>
      {actionBar && actionBar}
      <Bar size={size}>{children}</Bar>
    </div>
  );
}

Header.defaultProps = {
  stroke: true,
};

export default Header;
