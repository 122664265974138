import { IFormRender, Row } from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import { IconButton } from 'components';
import EquipmentClockFormPickerFormField from 'components/domain/equipment/EquipmentClockFormPickerFormField/EquipmentClockFormPickerFormField';
import Typography from 'components/foundation/text/Typography/Typography';
import { useOrganization } from 'hooks';
import * as React from 'react';
import { FormInfo } from 'types/FormData';
import { t } from 'utils/localize';
import { MULTIPLE } from '../BulkEditEntryForm/BulkEditEntryForm';
import { BulkEditActions, BulkEditActionType, IUndoableClearableFieldState } from '../reducers/reducers';

export interface IEquipmentTrashRowProps<T extends FormInfo> {
  form: IFormRender<T>;
  bulkState: IUndoableClearableFieldState;
  dispatch: React.Dispatch<BulkEditActions>;
  considerEquipmentRequirements?: boolean;
}

function EquipmentTrashRow<T extends FormInfo>({
  form,
  bulkState,
  considerEquipmentRequirements,
  dispatch,
}: IEquipmentTrashRowProps<T>) {
  const { trackEquipment } = useOrganization();
  if (!trackEquipment) {
    return null;
  }

  function clearField() {
    dispatch({ type: BulkEditActionType.CLEAR_CURRENT });
  }

  function onChange(id: string | null) {
    dispatch({ type: BulkEditActionType.SET_CURRENT, payload: { value: id } });
  }

  function onUndo(event: React.MouseEvent) {
    event.stopPropagation();
    dispatch({ type: BulkEditActionType.UNDO_CURRENT });
  }

  function renderUndo() {
    return (
      <Typography className={'mx-2'} onClick={onUndo} color={'primary'} tag={'span'}>
        {t('Undo')}
      </Typography>
    );
  }

  function getPlaceholderOverride() {
    if (considerEquipmentRequirements) {
      return undefined;
    }

    if (bulkState.original === MULTIPLE && bulkState.current === null) {
      return t('Equipment will be set to "No Equipment".');
    }

    return t('Equipment');
  }

  return (
    <Row className="trash-row">
      <EquipmentClockFormPickerFormField
        name="equipment"
        placeholderOverride={getPlaceholderOverride()}
        onSelect={onChange}
        form={form}
        closeButtonRender={bulkState.undoable ? renderUndo : undefined}
        dropDownButtonRender={bulkState.undoable ? renderUndo() : undefined}
        considerEquipmentRequirements={considerEquipmentRequirements}
      />
      {bulkState.clearable && <IconButton className="trash-icon" onClick={clearField} svg={DeleteIcon} />}
    </Row>
  );
}

export default EquipmentTrashRow;
