import { Align, Dialog, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { TimeRangeDialogHeader } from 'components/foundation/headers/TimeRangeDialogHeader/TimeRangeDialogHeader';
import Panel from 'components/layout/Panel/Panel';
import PanelContent from 'components/layout/PanelContent/PanelContent';
import { IUseTimeRangePayload } from 'hooks/utils/useTimeRange';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import './TimeRangeReportDialog.scss';

export interface ITimeRangeReportDialogProps extends IUseTimeRangePayload {
  className?: ClassName;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  reportComponent: (timeRange: ITimeRange<DateTime>) => ReactNode;
  renderActionBar?: (timeRange: ITimeRange<DateTime>) => ReactNode;
  renderFilterBar?: (timeRange: ITimeRange<DateTime>) => ReactNode;
  setRef?: (scroller: HTMLElement | null) => void;
  timeRangeBackPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  timeRangeForwardPressed?: () => boolean; // returns boolean to indicate if the event consumed (true) or if it should perform the default behavior (false)
  headerClassName?: string;
}

export default function TimeRangeReportDialog({
  className,
  title,
  isOpen,
  onClose,
  timeRangeType,
  reportComponent,
  renderActionBar,
  renderFilterBar,
  setRef,
  timeRangeBackPressed,
  timeRangeForwardPressed,
  headerClassName,
  forward,
  back,
  timeRange,
  forwardEnabled,
  backEnabled,
}: ITimeRangeReportDialogProps) {
  function renderOptionalActionBar(range: ITimeRange<DateTime>) {
    return renderActionBar ? renderActionBar(range) : null;
  }

  function renderHeader() {
    return (
      <div>
        <TimeRangeDialogHeader
          className={headerClassName ?? (renderFilterBar ? undefined : 'mb-8')}
          actionBar={renderOptionalActionBar(timeRange)}
          onBackClick={onClose}
          title={title}
          forward={timeActionForward}
          back={timeActionBack}
          backEnabled={backEnabled}
          forwardEnabled={forwardEnabled}
          timeRange={timeRange}
          timeRangeType={timeRangeType}
        />
        {renderFilterBar?.(timeRange)}
      </div>
    );
  }

  function timeActionForward() {
    const eventConsumed = timeRangeForwardPressed?.();

    if (eventConsumed !== true) {
      forward();
    }
  }

  function timeActionBack() {
    const eventConsumed = timeRangeBackPressed?.();

    if (eventConsumed !== true) {
      back();
    }
  }

  const forwardRef = (element: HTMLElement | null) => {
    setRef?.(element);
  };

  function renderReport() {
    return (
      <PanelContent className="panel-content" forwardRef={forwardRef}>
        <Row align={Align.CENTER} className="middle-row">
          <div className="report-container">{reportComponent(timeRange)}</div>
        </Row>
      </PanelContent>
    );
  }

  const classes = classNames('time-range-report-dialog', className);

  return (
    <Dialog hasDismiss={false} className={classes} size="full" isOpen={isOpen} onClose={onClose}>
      <Panel className="time-range-report-dialog-content">
        {renderHeader()}
        {renderReport()}
      </Panel>
    </Dialog>
  );
}
