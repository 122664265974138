import { Divider, Position } from '@busybusy/webapp-react-ui';
import { ArrowDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmployeeGroupPicker, EmployeePicker, IconButton } from 'components';
import AutoHidePositionPicker from 'components/domain/position/position-picker/AutoHidePositionPicker/AutoHidePositionPicker';
import SidePanelFilter from 'components/foundation/FilterSidePanel/FilterSidePanel';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import DateRange from 'components/foundation/ranges/DateRange/DateRange';
import { useOrganization, useQueryParams } from 'hooks';
import useTimeOffReportSettingsUpdate from 'hooks/models/member-settings/useTimeOffReportSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { parse, stringify } from 'utils/queryStringUtils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IDatePickerValue from 'types/DatePickerValue';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import TimeOffType from 'types/enum/TimeOffType';
import { QueryParam } from 'utils/constants/queryParam';
import { getAdjustedEndDateChange, getAdjustedStartDateChange } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { TimeOffRequestStatus } from '../hooks/useLazyTimeOffRequestData';

export enum TimeOffReportType {
  TIME_OFF = 'time_off',
  TIME_OFF_REQUEST = 'time_off_request',
}

export enum TimeOffPayType {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export enum TimeOffRequestReportType {
  AWAITING_APPROVAL = 'awaiting_approval',
  PROCESSED = 'processed',
}

export const REQUEST_TYPE = 'request_type';

interface ILocalDataState {
  customTimeRange: ITimeRange<DateTime | null>;
  reportType: TimeOffReportType;
  requestType: TimeOffRequestReportType;
  timeRangeType: TimeRangeType;
  employeeGroup: string | null;
  employee: string | null;
  position: string | null;
  timeOffType: string | null;
  payType: TimeOffPayType | null;
  status: TimeOffRequestStatus | null;
}

export interface ITimeOffReportSidePanelProps {
  className?: ClassName;
  setCustomTimeRange: (timeRange: ITimeRange<DateTime> | null) => void;
}

const TimeOffReportSidePanel = (props: ITimeOffReportSidePanelProps) => {
  const { className, setCustomTimeRange } = props;
  const memberSettings = useMemberSettings();
  const updateSettings = useTimeOffReportSettingsUpdate();

  const { getParam, updateParams } = useQueryParams();
  const reportType = getParam<TimeOffReportType>(QueryParam.REPORT_TYPE) ?? TimeOffReportType.TIME_OFF;
  const requestType = getParam<TimeOffRequestReportType>(REQUEST_TYPE) ?? TimeOffRequestReportType.AWAITING_APPROVAL;
  const timeRangeType =
    getParam<TimeRangeType>(QueryParam.TIME_RANGE_TYPE) ??
    memberSettings?.web?.features?.timeOffReport?.timeRangeType ??
    TimeRangeType.PAY_PERIOD;
  const timeOffType = getParam<TimeOffType>(QueryParam.TIME_OFF_TYPE);
  const status = getParam<TimeOffRequestStatus>('status');
  const payType = getParam<TimeOffPayType>(QueryParam.PAY_TYPE);
  const customStartDate = getParam(QueryParam.START_DATE) ?? DateTime.local().startOf('day').toISODate();
  const customEndDate = getParam(QueryParam.END_DATE) ?? DateTime.local().endOf('day').plus({ year: 1 }).toISODate();
  const memberGroupId = getParam(QueryParam.EMPLOYEE_GROUP_ID);
  const memberId = getParam(QueryParam.EMPLOYEE_ID);
  const positionId = getParam(QueryParam.POSITION_ID);
  const location = useLocation();
  const navigate = useNavigate();
  const organization = useOrganization();
  const timeOffRequestsEnabled = useFeatureFlags('TIME_OFF_REQUEST') && organization.timeOffRequests;

  const [localData, setLocalData] = useState<ILocalDataState>(getNewLocalState());
  const today = DateTime.local().startOf('day');

  const getDisabledDateRange = useCallback(() => {
    const clampDuration = Duration.fromObject({ years: 3 });
    return {
      disabledAfter: today.plus(clampDuration),
      disabledBefore: today.minus(clampDuration),
    };
  }, [today]);

  const timeRangeTypePickerOptions = useRef([
    { id: TimeRangeType.PAY_PERIOD, name: t('Pay Period') },
    { id: TimeRangeType.MONTHLY, name: t('Monthly') },
    { id: TimeRangeType.WEEKLY, name: t('Weekly') },
    { id: TimeRangeType.DAILY, name: t('Daily') },
    { id: TimeRangeType.CUSTOM, name: t('Custom') },
  ]);

  const reportTypeOptions = useRef([
    { id: TimeOffReportType.TIME_OFF, name: t('Time Off') },
    { id: TimeOffReportType.TIME_OFF_REQUEST, name: t('Time-Off Requests') },
  ]);

  const payOptions = useRef([
    { id: TimeOffPayType.PAID, name: t('Paid') },
    { id: TimeOffPayType.UNPAID, name: t('Unpaid') },
  ]);

  const timeOffTypeOptions = useRef([
    { id: TimeOffType.HOLIDAY.toString(), name: t('Holiday') },
    { id: TimeOffType.PERSONAL.toString(), name: t('Personal') },
    { id: TimeOffType.SICK.toString(), name: t('Sick') },
    { id: TimeOffType.VACATION.toString(), name: t('Vacation') },
    { id: TimeOffType.OTHER.toString(), name: t('Other') },
  ]);

  const requestOptions = useRef([
    { id: TimeOffRequestReportType.AWAITING_APPROVAL, name: t('Awaiting Approval') },
    { id: TimeOffRequestReportType.PROCESSED, name: t('Processed') },
  ]);

  const statusOptions = useRef([
    { id: TimeOffRequestStatus.APPROVED, name: t('Approved') },
    { id: TimeOffRequestStatus.DENIED, name: t('Denied') },
  ]);

  useEffect(() => {
    const newData = getNewLocalState();
    clearCheckedItems(newData);
    setLocalData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function getNewLocalState(): ILocalDataState {
    const newData: ILocalDataState = {
      reportType,
      requestType,
      timeRangeType,
      employeeGroup: memberGroupId,
      employee: memberId,
      position: positionId,
      customTimeRange: {
        startTime: customStartDate ? DateTime.fromISO(customStartDate).startOf('day') : DateTime.local().startOf('day'),
        endTime: customEndDate
          ? DateTime.fromISO(customEndDate).endOf('day').set({ millisecond: 0 })
          : DateTime.local().endOf('day').set({ millisecond: 0 }),
      },
      timeOffType: timeOffType?.toString() ?? null,
      payType,
      status,
    };
    return newData;
  }

  function clearCheckedItems(data: ILocalDataState) {
    // clear checked items
  }

  function onFilterApply() {
    clearCheckedItems(localData);
    applyQueryParams();
    if (localData.timeRangeType !== TimeRangeType.CUSTOM) {
      updateSettings([{ key: 'timeRangeType', payload: localData.timeRangeType }]);
    }
  }

  function applyQueryParams() {
    const params: { [key: string]: string | undefined | null } = {};

    if (localData.reportType) {
      params[QueryParam.REPORT_TYPE] = localData.reportType;
    }

    if (localData.requestType && localData.reportType === TimeOffReportType.TIME_OFF_REQUEST) {
      params[REQUEST_TYPE] = localData.requestType;
    } else {
      params[REQUEST_TYPE] = null;
    }

    if (localData.timeRangeType) {
      params[QueryParam.TIME_RANGE_TYPE] = localData.timeRangeType;
    }

    if (localData.employeeGroup) {
      params[QueryParam.EMPLOYEE_GROUP_ID] = localData.employeeGroup;
    } else {
      params[QueryParam.EMPLOYEE_GROUP_ID] = null;
    }

    if (localData.employee) {
      params[QueryParam.EMPLOYEE_ID] = localData.employee;
    } else {
      params[QueryParam.EMPLOYEE_ID] = null;
    }

    if (localData.position) {
      params[QueryParam.POSITION_ID] = localData.position;
    } else {
      params[QueryParam.POSITION_ID] = null;
    }

    if (localData.status) {
      params['status'] = localData.status;
    } else {
      params['status'] = null;
    }

    if (
      localData.timeRangeType === TimeRangeType.CUSTOM &&
      localData.customTimeRange.startTime &&
      localData.customTimeRange.endTime
    ) {
      params[QueryParam.START_DATE] = localData.customTimeRange.startTime.toISODate();
      params[QueryParam.END_DATE] = localData.customTimeRange.endTime.toISODate();

      // this updates the overriddenTimeRange so that our timeRange object is updated with the latest custom values
      setCustomTimeRange(localData.customTimeRange as ITimeRange<DateTime>);
    }

    if (localData.payType) {
      params[QueryParam.PAY_TYPE] = localData.payType;
    } else {
      params[QueryParam.PAY_TYPE] = null;
    }

    if (localData.timeOffType) {
      params[QueryParam.TIME_OFF_TYPE] = localData.timeOffType;
    } else {
      params[QueryParam.TIME_OFF_TYPE] = null;
    }

    updateParams(params);
  }

  function onClear() {
    setLocalData(getNewLocalState());

    const search = { ...parse(location.search) };
    delete search[QueryParam.EMPLOYEE_GROUP_ID];
    delete search[QueryParam.EMPLOYEE_ID];
    delete search[QueryParam.POSITION_ID];
    delete search[QueryParam.PAY_TYPE];
    delete search[QueryParam.TIME_OFF_TYPE];

    navigate({ search: stringify(search) }, { replace: true });
  }

  function isCustomRangeEqual() {
    return (
      DateTime.fromISO(customStartDate).startOf('day').toSeconds() ===
        localData.customTimeRange.startTime?.toSeconds() &&
      DateTime.fromISO(customEndDate).endOf('day').set({ millisecond: 0 }).toSeconds() ===
        localData.customTimeRange.endTime?.toSeconds()
    );
  }

  function anyFilterSet() {
    if (localData.timeRangeType === TimeRangeType.CUSTOM && !isCustomRangeEqual()) {
      return true;
    } else {
      const filterData = _.pickBy(
        { ...localData, customTimeRange: null, timeRangeType: null, reportType: null, requestType: null },
        _.identity
      );
      return _.some(Object.keys(filterData), (key: keyof ILocalDataState) => localData[key] !== null);
    }
  }

  function appliableChanges() {
    if (localData.timeRangeType === TimeRangeType.CUSTOM && !isCustomRangeEqual()) {
      return true;
    } else {
      const local = _.pickBy({ ...localData, customTimeRange: null }, _.identity);
      const defaultData = _.pickBy({ ...getNewLocalState(), customTimeRange: null }, _.identity);
      return !_.isEqual(defaultData, local);
    }
  }

  const onSelectField =
    <K extends keyof ILocalDataState, V extends ILocalDataState[K]>(key: keyof ILocalDataState) =>
    (value: V) => {
      setLocalData((prev) => ({ ...prev, [key]: value }));
    };

  function handleStartDateChange(value: IDatePickerValue) {
    if (value.value) {
      const { newStartDate, newEndDate } = getAdjustedStartDateChange(value.value, localData.customTimeRange.endTime);

      setLocalData({
        ...localData,
        customTimeRange: {
          startTime: newStartDate,
          endTime: newEndDate,
        },
      });
    } else {
      setLocalData({
        ...localData,
        customTimeRange: {
          ...localData.customTimeRange,
          startTime: null,
        },
      });
    }
  }

  function handleEndDateChange(endDatePickerValue: IDatePickerValue) {
    if (endDatePickerValue.value) {
      const { newStartDate, newEndDate } = getAdjustedEndDateChange(
        endDatePickerValue.value,
        localData.customTimeRange.startTime
      );

      setLocalData({
        ...localData,
        customTimeRange: {
          startTime: newStartDate ?? localData.customTimeRange.startTime,
          endTime: newEndDate,
        },
      });
    } else {
      setLocalData({
        ...localData,
        customTimeRange: {
          ...localData.customTimeRange,
          endTime: null,
        },
      });
    }
  }

  const classes = classNames('time-off-report-side-panel', className);

  return (
    <SidePanelFilter
      onApply={onFilterApply}
      onClear={onClear}
      clearable={anyFilterSet()}
      appliable={appliableChanges()}
      buttonsClassName="px-5"
      className={classes}
    >
      <div className="px-5 py-4">
        <h3 className="mb-4">{t('Settings')}</h3>
        {timeOffRequestsEnabled && (
          <BasicPicker
            onSelect={onSelectField('reportType')}
            value={localData.reportType}
            closeButtonRender={() => (
              <span className="drop-icon">
                <IconButton tabIndex={-1} svg={ArrowDownIcon} />
              </span>
            )}
            data={reportTypeOptions.current}
            className="mb-4"
          />
        )}
        {localData.reportType === TimeOffReportType.TIME_OFF ? (
          <>
            <BasicPicker
              onSelect={onSelectField('timeRangeType')}
              value={localData.timeRangeType}
              closeButtonRender={() => (
                <span className="drop-icon">
                  <IconButton tabIndex={-1} svg={ArrowDownIcon} />
                </span>
              )}
              data={timeRangeTypePickerOptions.current}
              className="mb-4"
            />
            {localData.timeRangeType === TimeRangeType.CUSTOM && (
              <DateRange
                startDate={localData.customTimeRange.startTime}
                endDate={localData.customTimeRange.endTime}
                onEndChange={handleEndDateChange}
                onStartChange={handleStartDateChange}
                {...getDisabledDateRange()}
                className="mb-4"
              />
            )}
          </>
        ) : (
          <BasicPicker
            onSelect={onSelectField('requestType')}
            value={localData.requestType}
            closeButtonRender={() => (
              <span className="drop-icon">
                <IconButton tabIndex={-1} svg={ArrowDownIcon} />
              </span>
            )}
            data={requestOptions.current}
            className="mb-4"
          />
        )}
      </div>
      <Divider className="mb-4" />

      <div className="px-5">
        <h3 className="mb-4">{t('Filter')}</h3>
        <EmployeePicker
          onSelect={onSelectField('employee')}
          value={localData.employee}
          placeholder={t('Employee')}
          permissions={{ permissions: [MemberPermission.TIME_EVENTS], operationType: OperationType.AND }}
          position={Position.BOTTOM_END}
          className="mb-4"
        />
        {localData.reportType === TimeOffReportType.TIME_OFF && (
          <>
            <EmployeeGroupPicker
              onSelect={onSelectField('employeeGroup')}
              value={localData.employeeGroup}
              placeholder={t('Employee Group')}
              position={Position.BOTTOM_END}
              className="mb-4"
              filterByPermission={true}
            />
            <AutoHidePositionPicker
              value={localData.position}
              placeholder={t('Position')}
              onSelect={onSelectField('position')}
              permissions="timeEvents"
              className="mb-4"
            />
          </>
        )}
        {localData.requestType === TimeOffRequestReportType.PROCESSED && (
          <BasicPicker
            onSelect={onSelectField('status')}
            value={localData.status}
            data={statusOptions.current}
            className="mb-4"
            placeholder={t('Status')}
          />
        )}
        <BasicPicker
          onSelect={onSelectField('payType')}
          value={localData.payType}
          data={payOptions.current}
          className="mb-4"
          placeholder={t('Pay')}
        />
        <BasicPicker
          onSelect={onSelectField('timeOffType')}
          value={localData.timeOffType}
          data={timeOffTypeOptions.current}
          className="mb-4"
          placeholder={t('Type')}
          clearable={false}
        />
      </div>
    </SidePanelFilter>
  );
};

export default TimeOffReportSidePanel;
