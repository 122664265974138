const CLICK_DASHBOARD = 'Click Dashboard';
const CLICK_MY_STATUS = 'Click My Status';
const CLICK_TIMESHEETS = 'Click Timesheets';
const CLICK_PAYROLL = 'Click Payroll';
const CLICK_EMPLOYEES = 'Click Employees';
const CLICK_PROJECTS = 'Click Projects';
const CLICK_COST_CODES = 'Click Cost Codes';
const CLICK_EQUIPMENT = 'Click Equipment';
const CLICK_PHOTOS = 'Click Photos';
const CLICK_SCHEDULING = 'Click Scheduling';
const CLICK_MAP = 'Click Map';
const CLICK_BUDGET = 'Click Budget';
const CLICK_BULLETIN = 'Click Bulletin';
const CLICK_MANAGEMENT_LISTS = 'Click Management Lists';
const CLICK_ACTIVITY_REPORTS = 'Click Activity Reports';
const CLICK_LEGACY_REPORTS = 'Click Legacy Reports';
const CLICK_PROGRESS = 'Click Progress';
const CLICK_VERIFICATION_AND_SAFETY = 'Click Verification';
const CLICK_MORE = 'Click More';
const CLICK_SETTINGS = 'Click Settings';
const CLICK_SUPPORT = 'Click Support';

const navBarAnalyticsConstants = {
  CLICK_DASHBOARD,
  CLICK_MY_STATUS,
  CLICK_TIMESHEETS,
  CLICK_PAYROLL,
  CLICK_EMPLOYEES,
  CLICK_PROJECTS,
  CLICK_COST_CODES,
  CLICK_EQUIPMENT,
  CLICK_PHOTOS,
  CLICK_SCHEDULING,
  CLICK_MAP,
  CLICK_BUDGET,
  CLICK_BULLETIN,
  CLICK_MANAGEMENT_LISTS,
  CLICK_ACTIVITY_REPORTS,
  CLICK_LEGACY_REPORTS,
  CLICK_PROGRESS,
  CLICK_VERIFICATION_AND_SAFETY,
  CLICK_MORE,
  CLICK_SETTINGS,
  CLICK_SUPPORT,
};

export default navBarAnalyticsConstants;
