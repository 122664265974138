import { BASIC_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from 'apollo/queries/jit-queries';
import gql from 'graphql-tag';

export const EQUIPMENT_TIME_METRIC_QUERY = gql`
  query QueryEquipmentTimeMetrics(
    $first: Int
    $after: String
    $filter: EquipmentFilter
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      equipmentLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const EQUIPMENT_LABOR_METRIC_QUERY = gql`
  query QueryEquipmentLaborMetrics(
    $first: Int
    $after: String
    $filter: EquipmentFilter
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      equipmentLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...LaborMetrics
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;
