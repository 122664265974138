import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { useTimeEntryActionBarState } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import EquipmentTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/EquipmentTimeEntryDataReport/EquipmentTimeEntryDataReport';
import useEquipmentTimeEntryDataReportData from 'components/domain/time-entry/TimeEntryDataReport/EquipmentTimeEntryDataReport/hooks/useEquipmentTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { defaultTimeEntryColumns } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { usePrintComplexDataChannelListener } from 'contexts/ChannelContext/hooks/usePrintComplexDataChannelListener';
import { useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { useLoaderPromise } from 'hooks/promise';
import { first, isEmpty, isNil, isNull, map, partition, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import { t } from 'utils/localize';
import { useTimeEntryExportChannelListener } from '../hooks/useTimeEntryExportChannelListener';

export interface IEquipmentTimeEntryTableListeningContainerProps {
  className?: ClassName;
  scroller: HTMLElement | Document | null;
  timeRange: ITimeRange<DateTime>;
  equipmentIds?: string[];
  memberIds?: string[];
  projectIds?: string[];
  costCodeIds?: string[];
  projectIdWithDescendants?: boolean;
  excludeDeleted?: boolean;
  applyPrintOptions: boolean;
}

const EquipmentTimeEntryTableListeningContainer = (props: IEquipmentTimeEntryTableListeningContainerProps) => {
  const {
    className,
    scroller,
    timeRange,
    equipmentIds,
    memberIds,
    projectIds,
    costCodeIds,
    projectIdWithDescendants,
    excludeDeleted,
    applyPrintOptions,
  } = props;

  const { data, loading, loadedAll, loadAll, error, clearData, updateEquipmentIds } =
    useEquipmentTimeEntryDataReportData(
      scroller,
      timeRange,
      false,
      equipmentIds,
      memberIds,
      projectIds,
      costCodeIds,
      excludeDeleted,
      true,
      projectIdWithDescendants
    );

  const [timeEntryActionBarState, timeEntryActionBarDispatch] = useTimeEntryActionBarState();

  useEffect(() => {
    if (!isEmpty(timeEntryActionBarState.entries)) {
      const ids = uniq(timeEntryActionBarState.entries.map((entry) => entry.equipment?.id ?? '')).filter(
        (id) => id.length > 0
      );
      updateEquipmentIds(ids);
    }
    if (!isEmpty(timeEntryActionBarState.memberIds)) {
      updateEquipmentIds(data.map((d) => d.id));
    }
    if (!isNull(timeEntryActionBarState.formData)) {
      const ids = timeEntryActionBarState.formData.members;
      updateEquipmentIds(ids);
    }
  }, [timeEntryActionBarState]);

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );
  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const exportWithLoader = useLoaderPromise(loadAll, t('Exporting...'));
  const printWithLoader = useLoaderPromise(loadAll, t('Preparing to print...'));
  const brand = useBrandTitle();

  useTimeEntryExportChannelListener(
    data,
    loadedAll,
    `${brand}-${t('activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}`,
    exportWithLoader
  );
  usePrintComplexDataChannelListener(data, loadedAll, printWithLoader);

  const classes = classNames('equipment-time-entry-table-container', className);

  return (
    <EquipmentTimeEntryDataReport
      className={classes}
      columnSettings={defaultTimeEntryColumns}
      timeRange={timeRange}
      selectedIds={selectedIds}
      onSelectChange={onSelect}
      data={data}
      loading={loading}
      updateEquipmentIds={updateEquipmentIds}
      memberIds={memberIds}
      projectId={first(projectIds)}
      costCodeId={first(costCodeIds)}
      loadedAll={loadedAll}
      applyPrintOptions={applyPrintOptions}
    />
  );
};

export default EquipmentTimeEntryTableListeningContainer;
