import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import { ConditionNullableFieldType, CostCode, LaborMetricsInterval } from '__generated__/graphql';
import {
  COST_CODES_WITH_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_TIME_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { ICostCodeActivityTableRowInfo } from './useCostCodeActivity';

export default function useCostCodeActivityBasicDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const [data, setData] = useState<ICostCodeActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<ICostCodeActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'costCode',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost ? COST_CODES_WITH_MEMBER_METRICS_QUERY : COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_PROJECT:
        return canViewCost ? COST_CODES_WITH_PROJECT_METRICS_QUERY : COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? COST_CODES_WITH_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? COST_CODES_WITH_METRICS_QUERY : COST_CODES_WITH_TIME_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: ICostCodeActivityTableRowInfo, key: keyof ICostCodeActivityTableRowInfo) {
    if (key === 'costCode') {
      return getCostCodeDisplay(item.costCode);
    } else {
      return item[key];
    }
  }

  async function loadData() {
    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const tableRows = mapNotNull(costCodes, (costCode) => createTableRowInfo(costCode));
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );

    const remainingData = calculateRemainingActivityData(tableRows, totalData);
    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        costCode: null,
        ...remainingData,
      };
    } else {
      // don't show no cost code item when there is no time allocated to no cost code
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const createTableRowInfo = (costCode: CostCode): ICostCodeActivityTableRowInfo | null => {
    const metric = laborMetrics(costCode);

    if (!isNil(metric)) {
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: costCode.id,
        costCode,
        regularHours: metricTotal.regularSeconds,
        regularHoursDec: metricTotal.regularSeconds,
        overtimeHours: metricTotal.overtimeSeconds,
        overtimeHoursDec: metricTotal.overtimeSeconds,
        doubleTimeHours: metricTotal.doubleTimeSeconds,
        doubleTimeHoursDec: metricTotal.doubleTimeSeconds,
        totalHours: metricTotal.totalSeconds,
        totalHoursDec: metricTotal.totalSeconds,
        regularCost: metricTotal.regularCost,
        overtimeCost: metricTotal.overtimeCost,
        doubletimeCost: metricTotal.doubleTimeCost,
        laborBurden: metricTotal.laborBurden,
        totalCost: metricTotal.totalCost,
      };
    }

    return null;
  };

  const laborMetrics = (costCode: CostCode) => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return first(costCode.costCodeMemberLaborMetrics);
      case ActivityReportType.BY_PROJECT:
        return first(costCode.costCodeProjectLaborMetrics);
      case ActivityReportType.BY_EQUIPMENT:
        return first(costCode.costCodeEquipmentLaborMetrics);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return first(costCode.costCodeLaborMetrics);
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
