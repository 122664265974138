import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IActivityReportSettings } from 'types/ActivityReportSettings';
import useMemberFeatureSettingsUpdate from '../member/useMemberFeatureSettingsUpdate';
import useMemberSettings from '../member/useMemberSettings';

export default function useActivityReportSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateFeatureSettings = useMemberFeatureSettingsUpdate();

  return useCallback(
    <K extends keyof IActivityReportSettings>(keysAndPayloads: { key: K; payload: IActivityReportSettings[K] }[]) => {
      const cloned = cloneDeep(memberSettings?.web?.features?.activityReport);

      const mapped = keysAndPayloads.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.key]: cur.payload,
        }),
        cloned
      );

      return updateFeatureSettings('activityReport', mapped);
    },
    [memberSettings, updateFeatureSettings]
  );
}
