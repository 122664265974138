import { ApolloClient } from '@apollo/client';
import { COST_CODE_METRICS_QUERY, COST_CODE_TIME_METRICS_QUERY } from 'apollo/queries/cost-code-metric-queries';
import { EQUIPMENT_METRICS_QUERY, EQUIPMENT_TIME_METRICS_QUERY } from 'apollo/queries/equipment-metric-queries';
import {
  MEMBER_METRICS_QUERY,
  MEMBER_PROJECT_COST_CODE_EQUIPMENT_METRICS_QUERY,
  MEMBER_PROJECT_COST_CODE_EQUIPMENT_TIME_METRICS_QUERY,
  MEMBER_TIME_METRICS_QUERY,
  MEMBER_TIME_OFF_METRICS_QUERY,
  MEMBER_TIME_OFF_TIME_ONLY_METRICS_QUERY,
} from 'apollo/queries/member-metric-queries';
import { PROJECT_METRICS_QUERY, PROJECT_TIME_METRICS_QUERY } from 'apollo/queries/project-metric-queries';
import {
  ORGANIZATION_LABOR_METRIC_QUERY,
  ORGANIZATION_TIME_METRIC_QUERY,
} from 'hooks/aggregates/metrics/organization-metric-queries';
import { isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { emptyMetrics } from 'mocks/Metrics/emptyMetrics';
import ICostCodeTimeMetrics from 'types/aggregate/CostCodeLaborMetrics';
import IEquipmentTimeMetrics from 'types/aggregate/EquipmentLaborMetrics';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import IMemberTimeMetrics from 'types/aggregate/MemberLaborMetrics';
import OrganizationTimeMetrics from 'types/aggregate/OrganizationLaborMetrics';
import IProjectTimeMetrics from 'types/aggregate/ProjectLaborMetrics';
import ITimeRange from 'types/TimeRange';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationListType,
  ConditionOperationNullType,
  ConditionOperationType,
  LaborMetricFilter,
  LaborMetricFilterListCondition,
  LaborMetricsInterval,
  MemberLaborMetrics,
  MemberProjectCostCodeEquipmentLaborMetrics,
  OperationType,
} from '__generated__/graphql';
import { remainingDataItemId } from './constants/utilConstants';
import { combineMetricWithTimeOff, metricConditionFilter, metricNullFilter } from './jitMetricUtils';

export async function getMemberMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricFilter: LaborMetricFilter,
  metricsInterval: LaborMetricsInterval
): Promise<IMemberTimeMetrics[]> {
  const members = await client.query<{ memberLaborMetrics: IMemberTimeMetrics[] }>({
    query: canViewCost ? MEMBER_METRICS_QUERY : MEMBER_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter,
    },
    fetchPolicy: 'network-only',
  });

  return members.data.memberLaborMetrics;
}

export async function getProjectMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricFilter: LaborMetricFilter,
  metricsInterval: LaborMetricsInterval
): Promise<IProjectTimeMetrics[]> {
  const projects = await client.query<{ projectLaborMetrics: IProjectTimeMetrics[] }>({
    query: canViewCost ? PROJECT_METRICS_QUERY : PROJECT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter,
    },
    fetchPolicy: 'network-only',
  });

  return projects.data.projectLaborMetrics;
}

export async function getNoProjectMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval = LaborMetricsInterval.Custom
): Promise<IJitLaborMetric[]> {
  const projects = await client.query<{ projectLaborMetrics: IProjectTimeMetrics[] }>({
    query: canViewCost ? PROJECT_METRICS_QUERY : PROJECT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
    },
    fetchPolicy: 'network-only',
  });

  // web services sucks and won't give us pto on project metrics where the project id is null, so we have to make a separate call to get the time off
  // #suckitdrew :~D
  const orgData = await getOrganizationMetrics(
    client,
    timeRange,
    canViewCost,
    metricsInterval,
    metricConditionFilter(ConditionFieldType.Pto, ConditionOperationType.GreaterThan, 0)
  );
  const noProjectData = projects.data.projectLaborMetrics;

  // get all the dates for the time off and no project time
  const days = uniq(orgData?.map((data) => data.start).concat(noProjectData?.map((data) => data.start)));
  const orgDataByDate = keyBy(orgData, (data) => data.start);
  const noProjectDataByDate = keyBy(noProjectData, (data) => data.start);

  return days.map((day) => {
    return combineMetricWithTimeOff(noProjectDataByDate[day], orgDataByDate[day])!;
  });
}

export async function getCostCodeMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricFilter: LaborMetricFilter,
  metricsInterval: LaborMetricsInterval
): Promise<ICostCodeTimeMetrics[]> {
  const costCodes = await client.query<{ costCodeLaborMetrics: ICostCodeTimeMetrics[] }>({
    query: canViewCost ? COST_CODE_METRICS_QUERY : COST_CODE_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter,
    },
    fetchPolicy: 'network-only',
  });

  return costCodes.data.costCodeLaborMetrics;
}

export async function getNoCostCodeMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval = LaborMetricsInterval.Custom
): Promise<IJitLaborMetric[]> {
  const costCodes = await client.query<{ costCodeLaborMetrics: ICostCodeTimeMetrics[] }>({
    query: canViewCost ? COST_CODE_METRICS_QUERY : COST_CODE_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
    },
    fetchPolicy: 'network-only',
  });

  // web services sucks and won't give us pto on cost code metrics where the cost code id is null, so we have to make a separate call to get the time off
  // #suckitdrew :~D
  const orgData = await getOrganizationMetrics(
    client,
    timeRange,
    canViewCost,
    metricsInterval,
    metricConditionFilter(ConditionFieldType.Pto, ConditionOperationType.GreaterThan, 0)
  );
  const noCostCodeData = costCodes.data.costCodeLaborMetrics;

  // get all the dates for the time off and no cost code time
  const days = uniq(orgData?.map((data) => data.start).concat(noCostCodeData?.map((data) => data.start)));
  const orgDataByDate = keyBy(orgData, (data) => data.start);
  const noCostCodeDataByDate = keyBy(noCostCodeData, (data) => data.start);

  return days.map((day) => {
    return combineMetricWithTimeOff(noCostCodeDataByDate[day], orgDataByDate[day])!;
  });
}

export async function getEquipmentMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricFilter: LaborMetricFilter,
  metricsInterval: LaborMetricsInterval
): Promise<IEquipmentTimeMetrics[]> {
  const equipment = await client.query<{ equipmentLaborMetrics: IEquipmentTimeMetrics[] }>({
    query: canViewCost ? EQUIPMENT_METRICS_QUERY : EQUIPMENT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter,
    },
    fetchPolicy: 'network-only',
  });

  return equipment.data.equipmentLaborMetrics;
}

export async function getNoEquipmentMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval = LaborMetricsInterval.Custom
): Promise<IJitLaborMetric[]> {
  const equipment = await client.query<{ equipmentLaborMetrics: IEquipmentTimeMetrics[] }>({
    query: canViewCost ? EQUIPMENT_METRICS_QUERY : EQUIPMENT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
    },
    fetchPolicy: 'network-only',
  });

  // web services sucks and won't give us pto on equipment metrics where the equipment id is null, so we have to make a separate call to get the time off
  // #suckitdrew :~D
  const orgData = await getOrganizationMetrics(
    client,
    timeRange,
    canViewCost,
    metricsInterval,
    metricConditionFilter(ConditionFieldType.Pto, ConditionOperationType.GreaterThan, 0)
  );
  const noEquipmentData = equipment.data.equipmentLaborMetrics;

  // get all the dates for the time off and no cost code time
  const days = uniq(orgData?.map((data) => data.start).concat(noEquipmentData?.map((data) => data.start)));
  const orgDataByDate = keyBy(orgData, (data) => data.start);
  const noEquipmentDataByDate = keyBy(noEquipmentData, (data) => data.start);

  return days.map((day) => {
    return combineMetricWithTimeOff(noEquipmentDataByDate[day], orgDataByDate[day])!;
  });
}

export async function getOrganizationMetrics(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval = LaborMetricsInterval.Custom,
  metricFilter?: LaborMetricFilter
): Promise<OrganizationTimeMetrics[]> {
  const dateMetric = await client.query<{ organizationLaborMetrics: OrganizationTimeMetrics[] }>({
    query: canViewCost ? ORGANIZATION_LABOR_METRIC_QUERY : ORGANIZATION_TIME_METRIC_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter,
    },
    fetchPolicy: 'network-only',
  });

  return dateMetric.data.organizationLaborMetrics;
}

export async function getMemberProjectCostCodeEquipmentMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval,
  memberId?: string,
  projectId?: string,
  costCodeId?: string,
  equipmentId?: string
): Promise<MemberProjectCostCodeEquipmentLaborMetrics[]> {
  const nullConditions = [];
  const conditions = [];

  if (projectId === remainingDataItemId) {
    nullConditions.push({ operator: ConditionOperationNullType.IsNull, field: ConditionNullableFieldType.ProjectId });
  } else if (!isNil(projectId)) {
    conditions.push({
      field: ConditionFieldType.ProjectId,
      operator: ConditionOperationType.Equal,
      value: projectId,
    });
  }

  if (costCodeId === remainingDataItemId) {
    nullConditions.push({ operator: ConditionOperationNullType.IsNull, field: ConditionNullableFieldType.CostCodeId });
  } else if (!isNil(costCodeId)) {
    conditions.push({
      field: ConditionFieldType.CostCodeId,
      operator: ConditionOperationType.Equal,
      value: costCodeId,
    });
  }

  if (equipmentId === remainingDataItemId) {
    nullConditions.push({ operator: ConditionOperationNullType.IsNull, field: ConditionNullableFieldType.EquipmentId });
  } else if (!isNil(equipmentId)) {
    conditions.push({
      field: ConditionFieldType.EquipmentId,
      operator: ConditionOperationType.Equal,
      value: equipmentId,
    });
  }

  if (!isNil(memberId)) {
    conditions.push({
      field: ConditionFieldType.MemberId,
      operator: ConditionOperationType.Equal,
      value: memberId,
    });
  }

  const metricFilter =
    !isEmpty(conditions) || !isEmpty(nullConditions)
      ? {
          operationType: OperationType.And,
          nullConditions,
          conditions,
        }
      : undefined;

  const memberProjectCostCodeEquipmentMetrics = await client.query<{
    memberProjectCostCodeEquipmentLaborMetrics: MemberProjectCostCodeEquipmentLaborMetrics[];
  }>({
    query: canViewCost
      ? MEMBER_PROJECT_COST_CODE_EQUIPMENT_METRICS_QUERY
      : MEMBER_PROJECT_COST_CODE_EQUIPMENT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter,
    },
    fetchPolicy: 'network-only',
  });

  console;

  // query for time off when all conditions set are for filtering by fields being null
  // or when filtering by member Id and all other ids are null or the remaining data id
  if (isEmpty(conditions) || (conditions.length === 1 && conditions[0].field === ConditionFieldType.MemberId)) {
    // make sure to include interval with query
    const timeOffMetrics = (
      await getMemberTimeOffMetric(
        client,
        timeRange,
        canViewCost,
        metricsInterval,
        !isNil(memberId) ? [memberId] : undefined
      )
    ).map((timeOffMetric) => {
      const laborMetrics: MemberProjectCostCodeEquipmentLaborMetrics = {
        ...emptyMetrics.projectLaborMetrics,
        memberId: timeOffMetric.memberId,
        rt: timeOffMetric.pto,
        rtCents: timeOffMetric.ptoCents,
        start: timeOffMetric.start,
        end: timeOffMetric.end,
      };

      return laborMetrics;
    });
    return [
      ...memberProjectCostCodeEquipmentMetrics.data.memberProjectCostCodeEquipmentLaborMetrics,
      ...timeOffMetrics,
    ];
  }

  return memberProjectCostCodeEquipmentMetrics.data.memberProjectCostCodeEquipmentLaborMetrics;
}

export async function getMemberTimeOffMetric(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval,
  memberIds?: [string]
): Promise<MemberLaborMetrics[]> {
  let listConditions: LaborMetricFilterListCondition[] | undefined = undefined;

  if (!isNil(memberIds) && !isEmpty(memberIds)) {
    listConditions = [
      {
        field: ConditionFieldType.MemberId,
        operator: ConditionOperationListType.Contains,
        values: memberIds,
      },
    ];
  }

  const members = await client.query<{ memberLaborMetrics: MemberLaborMetrics[] }>({
    query: canViewCost ? MEMBER_TIME_OFF_METRICS_QUERY : MEMBER_TIME_OFF_TIME_ONLY_METRICS_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: {
        operationType: OperationType.And,
        conditions: [
          {
            field: ConditionFieldType.Pto,
            operator: ConditionOperationType.GreaterThan,
            value: 0,
          },
        ],
        listConditions,
      },
    },
    fetchPolicy: 'network-only',
  });

  return members.data.memberLaborMetrics;
}
