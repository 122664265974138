import { ListIcon } from 'assets/icons';
import { IconButton, Panel, PanelContent } from 'components';
import EmployeeSummaryDataContextProvider from 'components/domain/member/EmployeeSummaryTable/context/EmployeeSummaryDataContextProvider';
import { MemberTimeEntryDataReportContextProvider } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/contexts/MemberTimeEntryDataReportContext';
import { IMemberTimeEntryReportDataFilter } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/hooks/useMemberTimeEntryDataReportData';
import LegacyMainHeader from 'components/layout/LegacyMainHeader/LegacyMainHeader';
import useTimesheetsColumns from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useOpenable, useTimeRangeQueryParams, useTimesheetsGraylog } from 'hooks';
import useTimeCardsSettingsUpdate from 'hooks/models/member-settings/useTimeCardsSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { isNil } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateReduxTimeCardReportSettings } from 'store/TimeCardReportSettings/TimeCardReportSettings';
import {
  TimesheetView,
  updateDailyColumns,
  updateSummaryAndTimeCardTableColumns,
  updateTimeEntryTableColumns,
  updateWeeklyColumns,
} from 'store/timesheets/Timesheets';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { QueryParam } from 'utils/constants/queryParam';
import { utcToday } from 'utils/dateUtils';
import LocalStore from 'utils/localStorageUtils';
import { typedObjectEntries } from 'utils/objectUtils';
import { parse, stringify } from 'utils/queryStringUtils';
import ExpandedTimeCardReportContextProvider from './ExpandedTimeCardsReport/context/ExpandedTimeCardReportContextProvider';
import TimeCardReportContextProvider from './TimeCardReport/context/TimeCardReportContextProvider';
import './Timesheets.scss';
import TimesheetsContent from './TimesheetsContent/TimesheetsContent';
import TimesheetsSidePanel from './TimesheetsSidePanel/TimesheetsSidePanel';
import useTimeCardSettings from './TimesheetsSidePanel/hooks/useTimeCardSettings';
import useTimesheetsQueryParams from './hooks/useTimesheetsQueryParams';

export enum TimesheetReportType {
  ENTRY = 'Entry',
  TIME_OFF_BOTH = 'Time Off Paid and Unpaid',
  TIME_OFF_PAID = 'Time Off Paid',
  TIME_OFF_UNPAID = 'Time Off Unpaid',
  ENTRY_AND_TIME_OFF_PAID = 'Entry and Time Off Paid',
  ENTRY_AND_ALL_TIME_OFF = 'Entry and All Time Off',
}

const Timesheets = () => {
  const {
    timeRangeType,
    timesheetView,
    startDate,
    endDate,
    memberGroupId,
    positionId,
    memberId,
    projectId,
    equipmentId,
    timesheetReportType,
    costCodeId,
    archivedStatus,
    costCodeGroupId,
    projectGroupId,
  } = useTimesheetsQueryParams();

  const memberSettings = useMemberSettings();
  const updateSettings = useTimeCardsSettingsUpdate();

  const userEvents = useTimesheetsGraylog();
  const tableScroller = useRef<HTMLDivElement>();

  const location = useLocation();
  const navigate = useNavigate();

  const overriddenTimeRange = useMemo(() => {
    return !isNil(startDate) && !isNil(endDate) ? { startTime: startDate, endTime: endDate } : null;
  }, [endDate, startDate]);

  const { forward, back, timeRange, forwardEnabled, backEnabled } = useTimeRangeQueryParams(
    timeRangeType,
    overriddenTimeRange,
    null,
    utcToday().startTime,
    'utc'
  );

  const { summaryColumns, timeEntryColumns, weeklyColumns, dailyColumns } = useTimesheetsColumns();
  const { timeCardReportSettings, timeFormatSettings, breakFormatSettings, updateTimeCardReportSettings } =
    useTimeCardSettings();

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const filterDetails = useOpenable({ isOpen: true });

  const memberTimeEntryFilter: IMemberTimeEntryReportDataFilter = useMemo(() => {
    return {
      timeRange,
      includeOpenEntries: false,
      memberIds: memberId ? [memberId] : null,
      memberGroupId,
      projectIds: projectId ? [projectId] : undefined,
      positionId: positionId ?? undefined,
      costCodeIds: costCodeId ? [costCodeId] : undefined,
      equipmentIds: equipmentId ? [equipmentId] : undefined,
      reportType:
        timesheetView === TimesheetView.EXPANDED_TIME_CARDS
          ? TimesheetReportType.ENTRY_AND_ALL_TIME_OFF
          : timesheetReportType ?? undefined,
      projectIdWithDescendants: !isNil(projectId) || !isNil(projectGroupId),
      archivedStatus,
      costCodeGroupId: costCodeGroupId ?? undefined,
      projectGroupId: projectGroupId ?? undefined,
    };
  }, [
    archivedStatus,
    costCodeGroupId,
    costCodeId,
    equipmentId,
    memberGroupId,
    memberId,
    positionId,
    projectGroupId,
    projectId,
    timeRange,
    timesheetReportType,
    timesheetView,
  ]);

  useOnMount(() => {
    if (isNil(memberSettings?.web?.features?.timeCards?.localStoreMigrated)) {
      const localSummaryColumns = LocalStore.get('timesheets_summary_report_column');
      const localTimeEntryColumns = LocalStore.get('timesheets_time_entry_report_columns');
      const localExpandedSettings = LocalStore.get('timecard_expanded_settings');
      const localExpandedTimeFormatSettings = LocalStore.get('timecard_expanded_time_format_settings');
      const localPrintOptions = LocalStore.get('timesheet_print_options');
      const localRowHeight =
        LocalStore.get('table-row-height-settings')?.timeCards ?? memberSettings?.web?.features?.timeCards?.rowHeight;
      updateSettings([
        { key: 'localStoreMigrated', payload: true },
        { key: 'summary', payload: { columnSettings: localSummaryColumns } },
        { key: 'timeEntry', payload: { columnSettings: localTimeEntryColumns } },
        { key: 'timecardExpandedSettings', payload: localExpandedSettings },
        { key: 'timeCardExpandedTimeFormatSettings', payload: localExpandedTimeFormatSettings },
        { key: 'printOptions', payload: localPrintOptions },
        { key: 'rowHeight', payload: localRowHeight },
      ]);
      LocalStore.remove('timesheets_summary_report_column');
      LocalStore.remove('timesheets_time_entry_report_columns');
      LocalStore.remove('timecard_expanded_settings');
      LocalStore.remove('timecard_expanded_time_format_settings');
      LocalStore.remove('timesheet_print_options');

      if (localRowHeight) {
        const isEveryKeyNull = typedObjectEntries(localRowHeight).every(
          ([key, val]) => key === 'timeCards' || isNil(val)
        );
        if (isEveryKeyNull) {
          LocalStore.remove('table-row-height-settings');
        } else {
          LocalStore.set('table-row-height-settings', { ...localRowHeight, timeCards: null });
        }
      }
    }
  });

  useEffect(() => {
    // update redux with our stored or default columns when first loading the component
    dispatch(updateSummaryAndTimeCardTableColumns(summaryColumns));
    dispatch(updateTimeEntryTableColumns(timeEntryColumns));
    dispatch(updateReduxTimeCardReportSettings(timeCardReportSettings));
    dispatch(updateWeeklyColumns(weeklyColumns));
    dispatch(updateDailyColumns(dailyColumns));
  }, []);

  useEffect(() => {
    setDefaultParams();
  }, [timesheetView]);

  function moveRangeForward() {
    forward();
    userEvents.events(TimesheetsTypes.events.action_type.RANGE_CHANGE);
  }

  function moveRangeBackward() {
    back();
    userEvents.events(TimesheetsTypes.events.action_type.RANGE_CHANGE);
  }

  // Set default params on navigation
  function setDefaultParams() {
    const search = { ...parse(location.search) };

    // Send user event on first navigation.
    if (timeRangeType === null || timeRangeType === TimeRangeType.PAY_PERIOD || timesheetView === null) {
      userEvents.reportViewed(TimeRangeType.PAY_PERIOD, TimesheetView.SUMMARY);
    }

    if (timeRangeType === null || timeRangeType === TimeRangeType.PAY_PERIOD) {
      search[QueryParam.TIME_RANGE_TYPE] = TimeRangeType.PAY_PERIOD;
    }

    if (timesheetView === TimesheetView.WEEKLY_GRID) {
      search[QueryParam.TIME_RANGE_TYPE] = TimeRangeType.WEEKLY;
    }

    if (timesheetView === null) {
      search[QueryParam.TIMESHEET_VIEW] = TimesheetView.SUMMARY;
    }

    navigate({ search: stringify(search) }, { replace: true });
  }

  return (
    <>
      <Helmet>
        <title>{t('Time Cards')}</title>
      </Helmet>
      <Panel>
        <LegacyMainHeader
          className="no-print"
          title={t('Time Cards')}
          rightContent={<IconButton onClick={filterDetails.toggle} tooltipLabel={t('Toggle Filter')} svg={ListIcon} />}
        />
        <PanelContent>
          <EmployeeSummaryDataContextProvider
            memberId={memberId}
            memberGroupId={memberGroupId}
            positionId={positionId}
            timeRange={timeRange}
            timeRangeType={timeRangeType}
          >
            <TimeCardReportContextProvider
              timeRangeType={timeRangeType}
              memberId={memberId}
              memberGroupId={memberGroupId}
              timeRange={timeRange}
              positionId={positionId}
              permission={MemberPermission.MANAGE_TIME_ENTRIES}
              archivedStatus={archivedStatus}
            >
              <ExpandedTimeCardReportContextProvider
                scroller={tableScroller.current ?? null}
                timeRangeType={timeRangeType}
                timesheetsView={timesheetView}
                memberId={memberId}
                memberGroupId={memberGroupId}
                timeRange={timeRange}
                positionId={positionId}
                permission={MemberPermission.MANAGE_TIME_ENTRIES}
                archivedStatus={archivedStatus}
              >
                <MemberTimeEntryDataReportContextProvider
                  scroller={tableScroller.current ?? null}
                  filter={memberTimeEntryFilter}
                >
                  <TimesheetsContent
                    timeRange={timeRange}
                    timeRangeType={timeRangeType}
                    forward={moveRangeForward}
                    back={moveRangeBackward}
                    forwardEnabled={forwardEnabled}
                    backEnabled={backEnabled}
                    scrollerCallback={(s) => (tableScroller.current = s)}
                    expandedTimeFormatSettings={timeFormatSettings}
                    breakFormat={breakFormatSettings}
                  />
                </MemberTimeEntryDataReportContextProvider>
              </ExpandedTimeCardReportContextProvider>
            </TimeCardReportContextProvider>
          </EmployeeSummaryDataContextProvider>

          <Panel className="no-print" medium={true} strokeLeft={true} open={filterDetails.isOpen}>
            <PanelContent flex={false}>
              <TimesheetsSidePanel
                timeFormatSettings={timeFormatSettings}
                breakFormatSettings={breakFormatSettings}
                updateTimeCardReportSettings={updateTimeCardReportSettings}
                timeCardReportSettings={timeCardReportSettings}
              />
            </PanelContent>
          </Panel>
        </PanelContent>
      </Panel>
    </>
  );
};

export default Timesheets;
