import { ActionCreator, UnknownAction } from 'redux';

import { timeStampUtc } from '../../../utils/dateUtils';

import { SESSION_CREATE } from '../sessionIndex';

export interface IStoreSessionCreateAction extends UnknownAction {
  type: typeof SESSION_CREATE;
  payload: IStoreSessionCreateActionPayload;
}

interface IStoreSessionCreateActionPayload extends IStoreSessionCreateActionProps {
  isAuthenticated: boolean;
  timestamp: number;
}

export interface IStoreSessionCreateActionProps {
  memberId: string;
  organizationId: string;
  positionId: string;
}

export const sessionCreateAction: ActionCreator<IStoreSessionCreateAction> = ({
  memberId,
  organizationId,
  positionId,
}: IStoreSessionCreateActionProps) => ({
  type: SESSION_CREATE,

  payload: {
    isAuthenticated: true,
    timestamp: timeStampUtc(),

    memberId,
    organizationId,
    positionId,
  },
});
