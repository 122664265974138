import { useMutation } from '@apollo/client';
import { CREATE_SAFETY_SIGNATURES, UPDATE_SAFETY_SIGNATURES } from 'apollo/mutations/safety-signature-mutations';
import { DateTime } from 'luxon';
import ISafetySignature, { ISafetySignatureCreateInput, ISafetySignatureUpdateInput } from 'types/SafetySignature';
import {
  dateTimeFromISOKeepZone,
  getNowIsoAtUtcWithLocalTimeZone,
  isoTimeStampLocal,
  offsetInSeconds,
} from 'utils/dateUtils';
import { t } from 'utils/localize';
import { uuid } from 'utils/uuidUtils';
import useActiveMember from '../../store/useActiveMember';

export default function useSafetySignature() {
  const [createSafetySignatureMutation] = useMutation(CREATE_SAFETY_SIGNATURES);
  const [updateSafetySignatureMutation] = useMutation(UPDATE_SAFETY_SIGNATURES);
  const activeMember = useActiveMember();

  function createSafetySignature(
    startTime: DateTime,
    startTimeDST: boolean,
    endTime: DateTime,
    endTimeDST: boolean,
    memberId: string,
    injured?: boolean,
    injuryDescription?: string,
    timeAccurate?: boolean,
    timeAccurateReason?: string,
    breakPolicyFollowed?: boolean,
    breakPolicyFollowedReason?: string,
    customQuestionsFlagged?: boolean
  ) {
    const now = DateTime.local();
    const start = startTime
      .startOf('day')
      .set({ second: 0, millisecond: 0 })
      .setZone(endTime.zoneName, { keepLocalTime: true }); //make sure startTime zone is same as endTime.
    const end = dateTimeFromISOKeepZone(endTime.toISO()).set({ second: 0, millisecond: 0 });

    const entry: ISafetySignatureCreateInput = {
      id: uuid(),
      memberId,
      proxyMemberId: activeMember.id!,
      startTime: start.toISO(),
      startTimeDst: startTimeDST,
      endTime: end.toISO(),
      endTimeDst: endTimeDST,
      deviceType: t('Web Browser'),
      injured,
      injuryDescription,
      timeAccurate,
      timeAccurateReason,
      breakPolicyFollowed,
      breakPolicyFollowedReason,
      customQuestionsFlagged: customQuestionsFlagged ?? false,
      createdOnDst: now.isInDST,
      createdOnOffset: offsetInSeconds(now),
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return createSafetySignatureMutation({ variables: { safetySignature: entry } });
  }

  function resolveSafetySignature(
    signature?: ISafetySignature,
    injuredResolvedBy?: string,
    injuredResolvedComment?: string,
    timeAccurateResolvedBy?: string,
    timeAccurateResolvedComment?: string,
    breakPolicyFollowedResolvedBy?: string,
    breakPolicyFollowedResolvedComment?: string,
    customQuestionsResolvedBy?: string,
    incidentId?: string,
    signatureId?: string
  ) {
    if (signature || signatureId) {
      const entry: ISafetySignatureUpdateInput = {
        id: signature ? signature.id : signatureId!,
      };

      if (injuredResolvedBy) {
        entry.injuredResolvedBy = injuredResolvedBy;
        entry.injuredResolvedComment = injuredResolvedComment;
        entry.injuredResolvedOn = isoTimeStampLocal();
        entry.incidentId = incidentId;
      }

      if (timeAccurateResolvedBy) {
        entry.timeAccurateResolvedBy = timeAccurateResolvedBy;
        entry.timeAccurateResolvedComment = timeAccurateResolvedComment;
        entry.timeAccurateResolvedOn = isoTimeStampLocal();
      }

      if (breakPolicyFollowedResolvedBy) {
        entry.breakPolicyFollowedResolvedBy = breakPolicyFollowedResolvedBy;
        entry.breakPolicyFollowedResolvedComment = breakPolicyFollowedResolvedComment;
        entry.breakPolicyFollowedResolvedOn = isoTimeStampLocal();
      }

      if (customQuestionsResolvedBy) {
        entry.customQuestionsResolvedOn = isoTimeStampLocal();
      }

      return updateSafetySignatureMutation({ variables: { safetySignature: entry } });
    } else {
      throw Error('signature or signatureId required');
    }
  }

  const deleteSafetySignature = (id: string) => {
    const entry: ISafetySignatureUpdateInput = {
      id,
      deletedOn: DateTime.utc().toISO(),
    };
    return updateSafetySignatureMutation({ variables: { safetySignature: entry } });
  };

  return { createSafetySignature, resolveSafetySignature, deleteSafetySignature };
}
