import { Theme } from '@busybusy/webapp-react-ui';
import {
  MemberPermissions,
  OperationType,
  PhotoVerificationDashboardCardQuery,
  PhotoVerificationDashboardCardQueryVariables,
} from '__generated__/graphql';
import { PhotoIcon } from 'assets/icons';
import classNames from 'classnames';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardCardBarChart from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/DashboardBarChart';
import { dashboardCardBarChartGradientBlueLightPurple } from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/constants/constants';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import {
  assignItemsByCreationToRanges,
  convertDashboardTimeFrameToTimeRangeType,
  getDashboardTimeFrameTitle,
  zipDaysToValue,
} from 'containers/dashboard/util/utils';
import { getDashboardNavigationRange } from 'containers/scheduling/utils/utils';
import { gql } from 'graphql-request';
import useGraphQLPaging from 'hooks/graphql/useGraphQLPaging/useGraphQLPaging';
import usePhotoVerificationReportNavigation from 'hooks/navigation/usePhotoVerificationReportNavigation';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import PhotoType from 'types/enum/PhotoType';
import { NonNullArrayField } from 'types/util/NonNullArrayField';
import { isNullGraphqlArgument } from 'utils/apolloUtils';
import { useFeatureFlags } from 'utils/features';
import DashboardProUpgradeCard from '../DashboardProUpgradeCard/DashboardProUpgradeCard';
import useDashboardCardTotal from '../hooks/useDashboardCardTotal';

export interface IPhotoVerificationDashboardCardProps {
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
  setting: IDashboardSettingsItem;
  onHide: () => void;
}

const PhotoVerificationDashboardCard = (props: IPhotoVerificationDashboardCardProps) => {
  const { className, theme, setting, onHide } = props;
  const timeFrame = setting.options?.time;
  const { timeRange, timeRanges } = useDashboardGraphDates(timeFrame !== 'daily' ? timeFrame : 'pastSeven')!;
  const [failedMatches, setFailedMatches] = useState<PhotoVerificationDashboardCardQueryItem[] | null>(null);

  const zippedValues = zipDaysToValue(assignItemsByCreationToRanges(timeRanges, failedMatches ?? []));

  const total = useDashboardCardTotal(
    timeRanges,
    null,
    null,
    zippedValues,
    setting.options?.time ?? 'payPeriod',
    false
  );
  const photoVerificationNavigation = usePhotoVerificationReportNavigation();
  const isPro = useFeatureFlags('PRO');

  const getData = usePhotoVerificationDashboardCardData(timeRange);

  useEffect(() => {
    getData().then(setFailedMatches);
  }, [getData, setting]);

  const handlePhotoVerificationNavigation = () => {
    const navRange = getDashboardNavigationRange(timeRange, timeRanges, setting.options?.time ?? 'payPeriod');

    photoVerificationNavigation({
      start_date: navRange.startTime.toISODate(),
      end_date: navRange.endTime.toISODate(),
      time_range_type: convertDashboardTimeFrameToTimeRangeType(setting.options?.time ?? 'payPeriod'),
      photo_type: PhotoType.FAILED,
    });
  };

  const classes = classNames('photo-verification-dashboard-card', className);

  return (
    <>
      {isPro ? (
        <DashboardContentCard
          header={total}
          theme={theme}
          subheaderValue={getDashboardTimeFrameTitle(
            t('Photo Verification (Failed Matches)'),
            setting.options?.time ?? 'monthly'
          )}
          className={classes}
          rightLabel={t('Details')}
          onRightLabelClick={handlePhotoVerificationNavigation}
        >
          <DashboardCardBarChart
            data={zippedValues}
            dataKey="value"
            valueType="raw"
            timeFrame={timeFrame}
            theme={theme}
            {...dashboardCardBarChartGradientBlueLightPurple}
          />
        </DashboardContentCard>
      ) : (
        <DashboardProUpgradeCard title={setting.title} svg={PhotoIcon} theme={theme} onHide={onHide} />
      )}
    </>
  );
};

const PHOTO_VERIFICATION_DASHBOARD_CARD_QUERY = gql`
  query PhotoVerificationDashboardCard($after: String, $filter: TimeEntryRekognitionFilter) {
    timeEntryRekognition(after: $after, filter: $filter, first: 500) {
      id
      createdOn
      cursor
    }
  }
`;

type PhotoVerificationDashboardCardQueryItem = NonNullArrayField<
  PhotoVerificationDashboardCardQuery['timeEntryRekognition']
>;

export function usePhotoVerificationDashboardCardData(timeRange: ITimeRange<DateTime>) {
  const getAll = useGraphQLPaging();

  return useCallback(async () => {
    return getAll<PhotoVerificationDashboardCardQuery, PhotoVerificationDashboardCardQueryVariables>(
      {
        document: PHOTO_VERIFICATION_DASHBOARD_CARD_QUERY,
        variables: {
          filter: {
            deletedOn: { isNull: true },
            createdOn: {
              greaterThanOrEqual: timeRange?.startTime?.startOf('day').toUTC().toISO({ suppressMilliseconds: true }),
              lessThanOrEqual: timeRange?.endTime?.endOf('day').toUTC().toISO({ suppressMilliseconds: true }),
            },
            timeEntry: {
              member: {
                permissions: {
                  permissions: [MemberPermissions.TimeEvents],
                  operationType: OperationType.Or,
                  includeSelf: true,
                },
              },
              deletedOn: isNullGraphqlArgument,
            },
            similarity: { lessThan: 90 },
          },
        },
      },
      'timeEntryRekognition'
    );
  }, [getAll, timeRange.endTime, timeRange.startTime]);
}

export default PhotoVerificationDashboardCard;
