import { isNil } from 'lodash';
import { ReactNode } from 'react';

export interface IOptionalRenderProps<T> {
  children: (value: T) => ReactNode;
  value: T | null | undefined;
  nilRender: () => ReactNode;
  rendersZero: boolean;
}

function OptionalRender<T>({ children, value, nilRender, rendersZero }: IOptionalRenderProps<T>) {
  const valueIsDefined = !isNil(value);
  const valueIsZero = typeof value === 'number' && value === 0;
  const valueIsEmpty = typeof value === 'string' && value === '';
  const valueIsRenderable = valueIsDefined && !valueIsEmpty;
  const shouldRender = rendersZero ? valueIsRenderable || valueIsZero : valueIsRenderable && !valueIsZero;
  return <>{shouldRender ? children(value!) : nilRender()}</>;
}

OptionalRender.defaultProps = {
  nilRender: () => '---',
  rendersZero: false,
};

export default OptionalRender;
