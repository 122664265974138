import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  MEMBERS_WITH_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, isEmpty, isNil, keyBy, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { combineGeneratedMetricWithTimeOff, getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import {
  ConditionNullableFieldType,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Member,
  Scalars,
} from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  getMemberTimeOffMetricListDictionary,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from '../../hooks/useMemberActivityPermission';
import { IMemberActivityTableRowInfo } from './useMemberActivity';

export interface IMemberDateActivityTableRowInfo extends IMemberActivityTableRowInfo {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

interface MemberMetricRow extends LaborMetricsInterface {
  memberId: Scalars['Uuid']['output'];
}

export default function useMemberActivityDateRangeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const permission = useMemberActivityPermission();
  const canViewCost = useHasCostPermission();
  const nameFormatted = useEmployeeNameFormatter();
  const remainingItemRef = useRef<IMemberDateActivityTableRowInfo>();
  const [data, setData] = useState<IMemberDateActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'member',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost ? MEMBERS_WITH_PROJECT_METRIC_QUERY : MEMBERS_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost ? MEMBERS_WITH_COST_CODE_METRIC_QUERY : MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost ? MEMBERS_WITH_EQUIPMENT_METRIC_QUERY : MEMBERS_WITH_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_METRIC_QUERY : MEMBERS_WITH_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IMemberDateActivityTableRowInfo, key: keyof IMemberDateActivityTableRowInfo) {
    if (key === 'member') {
      return item.member ? nameFormatted(item.member.firstName ?? '', item.member.lastName ?? '') : '';
    } else {
      return item[key];
    }
  }

  async function loadData() {
    const members = await getAll<Member & ICursorable>('members', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: intervalType,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    // we need to get the time off metrics when showing members filtered by the "no project" (or other variant) item
    // because web services won't include it #suckitdrew
    let timeOffData: Dictionary<ITimeOffLaborMetrics[]> | undefined = undefined;
    if (filterId === remainingDataItemId) {
      timeOffData = await getMemberTimeOffMetricListDictionary(client, timeRange, canViewCost, intervalType);
    }

    const tableRows = mapNotNull(members, (member) => createTableRowInfo(member, timeOffData?.[member.id]));

    // calculate total time in order to show `Additional Time` item if the user doesn't have permission to see everyone
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        member: null,
        startDate: null,
        endDate: null,
        ...remainingData,
      };
    } else {
      // don't show no equipment item when there is no time allocated to no equipment
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const createTableRowInfo = (
    member: Member,
    timeOffData?: ITimeOffLaborMetrics[]
  ): IMemberDateActivityTableRowInfo | null => {
    const metrics = laborMetrics(member);
    const timeOffLookup = timeOffData ? keyBy(timeOffData, (data) => data.start) : undefined;
    const processedTimeOff: ITimeOffLaborMetrics[] = [];

    const unsortedDateRows = mapNotNull(metrics, (metric) => {
      let metricTotal;
      const timeOff = timeOffLookup ? timeOffLookup[metric.start] : undefined;

      if (!isNil(timeOff)) {
        processedTimeOff.push(timeOff);
        metricTotal = getGeneratedMetricLaborTotals(
          combineGeneratedMetricWithTimeOff(metric, timeOff),
          filterIdWithDescendants === false
        );
      } else {
        metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      }

      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }

      return {
        id: member.id + metric.start,
        startDate: dateTimeFromISOWithoutZone(metric.start),
        endDate: dateTimeFromISOWithoutZone(metric.end),
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });

    // account for time off that hasn't been processed
    if (!isNil(timeOffData) && processedTimeOff.length < timeOffData?.length) {
      const remainingTimeOff = timeOffData
        .filter((timeOff) => !processedTimeOff.includes(timeOff))
        .map((timeOff) => {
          const metricTotal = getGeneratedMetricLaborTotals(
            combineGeneratedMetricWithTimeOff(emptyMetricRow(member.id), timeOff),
            filterIdWithDescendants === false
          );

          return {
            id: member.id + timeOff.start,
            startDate: dateTimeFromISOWithoutZone(timeOff.start),
            endDate: dateTimeFromISOWithoutZone(timeOff.end),
            member,
            ...activityReportUtils.metricToRowInfo(metricTotal),
          };
        });

      unsortedDateRows.push(...remainingTimeOff);
    }

    const dateRows = sortBy(unsortedDateRows, (row) => row.startDate.toSeconds());

    if (isEmpty(dateRows)) {
      return null;
    } else {
      const memberTotalMetrics = aggregateActivityRows(dateRows);
      return {
        ...memberTotalMetrics,
        id: member.id,
        startDate: null,
        endDate: null,
        member,
        detailRows: dateRows,
      };
    }
  };

  const emptyMetricRow = (memberId: string): MemberMetricRow => {
    return {
      memberId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (member: Member): MemberMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return member.memberProjectLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return member.memberCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return member.memberEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
