import useActivePosition from 'hooks/models/position/useActivePosition';
import { useFeatureFlags } from 'utils/features';

export default function usePayrollEnabled(): boolean {
  const position = useActivePosition();
  const payrollToggledOn = useFeatureFlags('REACT_PAYROLL');

  if (!position) {
    return false;
  }

  const canManageTimeEntriesForAnyone = position.manageTimeEntries ? position.manageTimeEntries > 0 : false;
  const canViewExpenses = (position.viewMemberExpenses && position.viewProjectExpenses) ?? false;

  return canManageTimeEntriesForAnyone && canViewExpenses && payrollToggledOn;
}
