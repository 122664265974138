import { useOrganization } from 'hooks';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { getCurrentPayPeriodRange } from 'utils/payPeriodUtils';
import { getWeekRangeForTime } from 'utils/timeRangeUtils';

export function useDateRanges(
  timeRange: ITimeRange<DateTime>,
  timeRangeType: TimeRangeType,
  clipToTimeRange: boolean
): ITimeRange<DateTime>[] {
  const organization = useOrganization();

  function createTimeRange(time: DateTime): ITimeRange<DateTime> {
    switch (timeRangeType) {
      case TimeRangeType.YEARLY:
        return { startTime: time.startOf('year'), endTime: time.endOf('year').set({ millisecond: 0 }) };
      case TimeRangeType.MONTHLY:
        return {
          startTime: time.startOf('month'),
          endTime: time.endOf('month').set({ millisecond: 0 }),
        };
      case TimeRangeType.PAY_PERIOD:
        return getCurrentPayPeriodRange(organization.organizationPayPeriod!, time.toSeconds(), 'utc')!;
      case TimeRangeType.WEEKLY:
        return getWeekRangeForTime(organization.organizationOvertimePeriods!, time.toSeconds())!;
      case TimeRangeType.DAILY:
        return {
          startTime: time.startOf('day'),
          endTime: time.endOf('day').set({ millisecond: 0 }),
        };
      default:
        throw Error('Not support time range type: ' + timeRangeType);
    }
  }

  return useMemo(() => {
    let currentTime = timeRange.startTime;
    const timeRanges: ITimeRange<DateTime>[] = [];

    while (currentTime < timeRange.endTime) {
      const newTimeRange = createTimeRange(currentTime);
      if (
        clipToTimeRange &&
        (newTimeRange.startTime < timeRange.startTime || newTimeRange.endTime > timeRange.endTime)
      ) {
        // modify the time range because it is outside of our constrained time frame
        timeRanges.push({
          startTime: newTimeRange.startTime < timeRange.startTime ? timeRange.startTime : newTimeRange.startTime,
          endTime: newTimeRange.endTime > timeRange.endTime ? timeRange.endTime : newTimeRange.endTime,
        });
      } else {
        timeRanges.push(newTimeRange);
      }

      currentTime = newTimeRange.endTime.plus({ hour: 1 });
    }

    return timeRanges;
  }, [timeRange, timeRangeType]);
}
