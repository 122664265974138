import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import CheckItem from 'components/foundation/check-list/CheckList/CheckItem/CheckItem';
import { ReactNode } from 'react';
import { AnyObject } from 'types/util/Object';

export interface ICheckItemFormFieldProps<FormType extends AnyObject>
  extends IFormFieldDefaultProps<boolean, FormType> {
  className?: ClassName;
  onChange?: (value: boolean) => void;
  children: ReactNode;
  value: boolean;
}

const CheckItemFormField = <FormType extends AnyObject>({
  className,
  form,
  name,
  validations,
  onChange,
  children,
  value,
  revalidateOnNewValidations,
  ...other
}: ICheckItemFormFieldProps<FormType>) => {
  const classes = classNames('check-item-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(checked: boolean) {
      fieldProps.handleChange(checked, onChange);
    }

    const formValue = form.state.data[name];
    const newValue = formValue ? formValue : value;

    return (
      <>
        <CheckItem checked={newValue} onChange={handleOnSelect} {...other}>
          {children}
        </CheckItem>
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      form={form}
      name={name}
      validations={validations}
      className={classes}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
};

export default CheckItemFormField;
