import { IDialogProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import ConfirmationDialog from 'components/foundation/dialogs/ConfirmationDialog/ConfirmationDialog';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useOpenable } from 'hooks';
import { FunctionComponent, ReactNode } from 'react';
import { t } from 'utils/localize';
import CsvImportOverviewHeader from '../CsvImportOverviewHeader/CsvImportOverviewHeader';

export interface ICsvImportOverviewProps extends Omit<IDialogProps, 'children'> {
  dialogTitle: string;
  checkTextInformationTitle?: string;
  checkTextInformationBody?: string;
  submitText: string;
  confirmationPayload: {
    title: string;
    message: string;
    submissionText: string;
  };
  onSubmit: (inviteChecked: boolean) => void;
  children: ReactNode;
}

const CsvImportOverview: FunctionComponent<ICsvImportOverviewProps> = (props) => {
  const {
    className,
    dialogTitle,
    checkTextInformationBody,
    checkTextInformationTitle,
    confirmationPayload,
    children,
    submitText,
    onSubmit,
    onClose,
    ...other
  } = props;

  const confirmationDialogDetails = useOpenable();
  const deleteConfirmationDialogDetails = useOpenable();

  function onMainDialogClose() {
    deleteConfirmationDialogDetails.open();
  }

  function handleSubmit() {
    onSubmit(false);
    confirmationDialogDetails.close();
  }

  function onDeleteConfirmationConfirm() {
    onClose?.();
    deleteConfirmationDialogDetails.close();
  }

  const classes = classNames('csv-import-overview', className);

  return (
    <>
      <HeaderDialog className={classes} title={dialogTitle} onClose={onMainDialogClose} {...other}>
        <CsvImportOverviewHeader
          submitText={submitText}
          onSubmit={confirmationDialogDetails.open}
          checkTextInformationTitle={checkTextInformationTitle}
          checkTextInformationBody={checkTextInformationBody}
        />
        {children}
      </HeaderDialog>
      <ConfirmationDialog
        isOpen={confirmationDialogDetails.isOpen}
        onClose={confirmationDialogDetails.close}
        confirmButtonText={confirmationPayload.submissionText}
        message={confirmationPayload.message}
        title={confirmationPayload.title}
        onConfirm={handleSubmit}
        closeButtonText={t('Close')}
      />
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationDialogDetails.isOpen}
        onClose={deleteConfirmationDialogDetails.close}
        deleteText={t('Close')}
        onDelete={onDeleteConfirmationConfirm}
        title={t('Lose progress?')}
      >
        {t('Closing this dialog will result in losing all current import progress.')}
      </DeleteConfirmationDialog>
    </>
  );
};

export default CsvImportOverview;
