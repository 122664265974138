import { ITotalFieldProps, TotalField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useTimeRounding } from 'hooks';
import { DateTime } from 'luxon';
import TimeRoundingType from 'types/enum/TimeRoundingType';
import { dateUtils } from 'utils';

export interface TotalTimeFieldProps extends Omit<ITotalFieldProps, 'minuteStep' | 'inputs' | 'meridiemEnabled'> {}
function TotalTimeField({
  onChange,
  value,
  className,
  maxHours,
  minHours,
  ...totalTimeFieldProps
}: TotalTimeFieldProps) {
  const { roundTimeWithType, minuteInterval, enabled } = useTimeRounding();

  function onBlur() {
    if (value && enabled) {
      const dateValue = DateTime.fromSeconds(value, { zone: 'utc' });
      const roundedValue = roundTimeWithType(dateValue, TimeRoundingType.NEAREST).toSeconds();

      onChange({
        value: roundedValue,
        formatted: dateUtils.secondsToHHmmString(value),
        isValid: true,
      });
    }
  }

  const classes = classNames('total-time-field', className);

  return (
    <TotalField
      {...totalTimeFieldProps}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      minuteStep={minuteInterval}
      className={classes}
      maxHours={maxHours}
      minHours={minHours}
    />
  );
}

TotalTimeField.defaultProps = {
  ...TotalField.defaultProps,
  disabled: false,
  placeholder: 'H:MM',
};

export default TotalTimeField;
