import {
  Checkbox,
  ClassName,
  Field,
  FieldFooter,
  IFormFieldDefaultProps,
  IFormRender,
  IFormValidation,
} from '@busybusy/webapp-react-ui';
import { AnyObject } from '@busybusy/webapp-react-ui/dist/types/Object';
import classNames from 'classnames';
import { useState } from 'react';
import './CheckboxGroupFormField.scss';

interface ICheckboxGroupFormFieldProps<FormType extends AnyObject> extends IFormFieldDefaultProps<string, FormType> {
  checkBoxItems: CheckboxFormItems[];
  form: IFormRender<FormType>;
  name: string;
  validations?: Array<IFormValidation<string, any>>;
  onChange?: (value: string | null) => void;
  className?: ClassName;
}

export type CheckboxFormItems = {
  id: string;
  label: string;
  checked: boolean;
  enabled?: boolean;
  secondaryLabel?: string;
};

interface ICheckboxGroupFormFieldData {
  items: CheckboxFormItems[];
}

function CheckboxGroupFormField<
  FormType extends {
    [field: string]: any;
  },
>(props: ICheckboxGroupFormFieldProps<FormType>) {
  const { checkBoxItems, form, name, validations, onChange, revalidateOnNewValidations, className } = props;

  const [data, setData] = useState<ICheckboxGroupFormFieldData>({
    items: checkBoxItems.map((item) => ({
      id: item.id,
      label: item.label,
      checked: item.checked,
      enabled: item.enabled ?? true,
      secondaryLabel: item.secondaryLabel,
    })),
  });

  function renderFields({ ...fieldProps }) {
    function onChangeCheckbox(checked: boolean, id: string) {
      const mutableItems = [...data.items];
      const indexOfId = data.items.map((item) => item.id).findIndex((item) => item === id);
      mutableItems[indexOfId] = {
        id: mutableItems[indexOfId].id,
        label: mutableItems[indexOfId].label,
        checked: checked,
        enabled: mutableItems[indexOfId].enabled ?? true,
      };
      setData({
        items: mutableItems,
      });
      fieldProps.handleChange(mutableItems, onChange);
    }

    return (
      <>
        {data.items.map((item) => (
          <Checkbox
            key={item.id}
            checked={item.checked}
            label={
              <>
                {item.label}
                {item.secondaryLabel && <span className="secondary-label pl-1">{item.secondaryLabel}</span>}
              </>
            }
            onChange={(checked: boolean) => onChangeCheckbox(checked, item.id)}
            className={'pb-6'}
            disabled={!item.enabled ?? false}
          />
        ))}
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames('checkbox-group-form-field', className);

  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderFields}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

export default CheckboxGroupFormField;
