import { Theme } from '@busybusy/webapp-react-ui';
import { DateTime } from 'luxon';
import { BarProps, TooltipProps, YAxisProps } from 'recharts';
import { Props as CartesianGridProps } from 'recharts/types/cartesian/CartesianGrid';
import { Props as AreaProps } from 'recharts/types/cartesian/Area';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { CSSProperties } from 'react';

export function getDefaultDashboardYAxisProps(theme: Theme.DARK | Theme.LIGHT): Omit<YAxisProps, 'dataKey'> {
  return {
    tickMargin: 20,
    interval: 'preserveStartEnd',
    tickCount: 5,
    width: 80,
    ...getDefaultAxisProps(theme),
  };
}

export function getDefaultDashboardCartesianGridProps(
  theme: Theme.DARK | Theme.LIGHT
): Omit<CartesianGridProps, 'ref'> {
  return {
    stroke: theme === Theme.DARK ? '#828b97cc' : '#a7a7a8cc',
    vertical: false,
    strokeWidth: 0.25,
  };
}

export function getDefaultDashboardAreaProps(theme: Theme.DARK | Theme.LIGHT): Omit<AreaProps, 'dataKey' | 'ref'> {
  return {
    type: 'monotone',
    dot: false,
    activeDot: { r: 4 },
    stroke: theme === Theme.DARK ? '#36c6dd' : '#38e0ac',
    strokeWidth: 2,
    fillOpacity: 1,
    fill: theme === Theme.DARK ? '#305a6e' : '#d7f9ee',
  };
}

export function getDefaultDashboardBarComponentProps(): Omit<BarProps, 'dataKey' | 'ref'> {
  return {
    strokeWidth: 0,
    fillOpacity: 1,
  };
}

export function getDefaultDashboardVisComponentProps() {
  return {
    type: 'monotone',
    dot: false,
    activeDot: { r: 4 },
  };
}

export function getDefaultTooltipProps() {
  return {
    labelStyle,
    itemStyle,
    // Separator between the name and the value since we aren't using a name set to nothing
    separator: '',
  };
}

export function dashboardTooltipDateFormatter(tickItem: number) {
  return DateTime.fromSeconds(tickItem, { zone: 'utc' }).toFormat('MMMM');
}

const itemStyle: CSSProperties = {
  color: '#30373d',
  fontSize: '13px',
  fontWeight: 600,
  fontFamily: '"Nunito Sans", sans-serif',
  lineHeight: '24px',
};

const labelStyle = {
  ...itemStyle,
  fontWeight: 600,
};

function xAxisFormatter(tickItem: number) {
  return DateTime.fromSeconds(tickItem, { zone: 'utc' }).toFormat('MMM');
}

export function getDefaultAxisProps(theme: Theme.DARK | Theme.LIGHT) {
  return {
    tickSize: 0,
    tick: { fontSize: 12, fill: theme === Theme.DARK ? '#828b97' : '#a7a7a8' },
    axisLine: false,
  };
}
