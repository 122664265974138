import gql from 'graphql-tag';

export const CREATE_EQUIPMENT_HOURS = gql`
  mutation CreateEquipmentHours($equipmentHours: EquipmentHoursCreateInput) {
    createEquipmentHours(equipmentHours: $equipmentHours) {
      id
      runningHours
    }
  }
`;
