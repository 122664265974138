import { Icon } from '@busybusy/webapp-react-ui';
import { ArrowDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";

export interface IPickerDropDownIconProps {
  className?: ClassName;
}

function PickerDropDownIcon({ className }: IPickerDropDownIconProps) {
  return (
    <span className={classNames('drop-icon', className)}>
      <Icon svg={ArrowDownIcon} />
    </span>
  );
}

export default PickerDropDownIcon;
