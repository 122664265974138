import { CreateIncidentMutation, CreateIncidentMutationVariables, IncidentCreateInput } from "__generated__/graphql";
import gql from "graphql-tag";
import useGraphQLClient from "hooks/graphql/useGraphQLClient/useGraphQLClient";
import { isNil } from "lodash";
import { useCallback } from "react";

export const useCreateIncident = () => {
    const client = useGraphQLClient();
    const createIncident = useCallback(
        async (input: IncidentCreateInput) => {
            const result = await client.request<CreateIncidentMutation, CreateIncidentMutationVariables>({
                document: CREATE_INCIDENT_MUTATION,
                variables: {
                    incident: input,
                }
            });

            if (isNil(result.createIncident)) {
                throw Error(`result was ${result.createIncident}`);
            }

            return result.createIncident;
        },
        [client]
    );

    return createIncident;
}

const CREATE_INCIDENT_MUTATION = gql`
mutation CreateIncident(
    $incident: IncidentCreateInput
  ) {
    createIncident(
      incident: $incident
    ) {
      id
    }
  }`