import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import CreateEquipmentForm from '../CreateEquipmentForm/CreateEquipmentForm';

export interface ICreateEquipmentFormDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data?: string) => void;
  onImportClick: () => void;
  className?: ClassName;
}

const CreateEquipmentFormDialog = (props: ICreateEquipmentFormDialogProps) => {
  const { className, isOpen, onClose, onSubmit, onImportClick } = props;

  return (
    <HeaderDialog className={className} isOpen={isOpen} title={t('Create Equipment')} onClose={onClose} divider={false}>
      <CreateEquipmentForm onSubmit={onSubmit} onClose={onClose} onImportClick={onImportClick} />
    </HeaderDialog>
  );
};

export default CreateEquipmentFormDialog;
