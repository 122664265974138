import { ActionCreator, UnknownAction } from 'redux';
import { AUTH_MEMBER_SET, IStoreAuthMember } from '../authMemberIndex';

export interface IStoreAuthMemberSetAction extends UnknownAction {
  type: typeof AUTH_MEMBER_SET;
  payload: {
    member: IStoreAuthMember;
  };
}

export interface IStoreAuthMemberSetActionProps {
  member: IStoreAuthMember;
}

export const authMemberSetAction: ActionCreator<IStoreAuthMemberSetAction> = (member: IStoreAuthMember) => ({
  type: AUTH_MEMBER_SET,

  payload: { member },
});
