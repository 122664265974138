import { Tray /*, Avatar*/ } from '@busybusy/webapp-react-ui';
import { ArrowBackIcon } from 'assets/icons';
import classNames from 'classnames';
import { Header } from 'components';
import NavButton, { TNavButtonHandler } from 'components/layout/NavButton/NavButton';
import * as React from 'react';
import { ReactNode } from 'react';
import { ClassName } from 'types/ClassName';

export interface IMainHeaderProps {
  title?: ReactNode;
  rightContent?: ReactNode;
  className?: ClassName;
  onClick?: TNavButtonHandler;
}

export const MainHeader: React.FC<IMainHeaderProps> = ({ title, rightContent, className, onClick }) => (
  <Header className={classNames('main-header', 'px-5', className)}>
    <Tray>
      {onClick && <NavButton onClick={onClick} icon={ArrowBackIcon} />}
      <h1>{title}</h1>
    </Tray>
    <Tray align="right">
      {rightContent}
      {/* <Avatar name={name} size={Size.SMALL} /> */}
    </Tray>
  </Header>
);

export default MainHeader;
