import { assignItemsByCreationToRanges, zipDaysToValue } from 'containers/dashboard/util/utils';
import { useAsyncApollo } from 'hooks';
import { isNil } from 'lodash';
import sumBy from 'lodash/sumBy';
import { DateTime } from 'luxon';
import { IMediaCollection } from 'types';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';
import { PHOTOS_AND_NOTES_DASHBOARD_CARD_QUERY } from '../photos-and-notes-dashboard-card-query';
import useMediaCollectionViewPermissionCreatedByFilter from 'hooks/models/media-collection/useMediaCollectionViewPermissionCreatedByFilter/useMediaCollectionViewPermissionCreatedByFilter';
import { PhotosAndNotesDashboardCardQueryQueryVariables } from '__generated__/graphql';
import { useMemo } from 'react';

export default function useDashboardPhotos(
  timeRange: ITimeRange<DateTime>,
  ranges: Array<ITimeRange<number>>,
  memberId?: string,
  projectId?: string
) {
  const mediaCollectionViewPermissionFilter = useMediaCollectionViewPermissionCreatedByFilter();

  const variables: PhotosAndNotesDashboardCardQueryQueryVariables = useMemo(
    () => ({
      first: 500,
      filter: {
        project: {
          idWithDescendants: !isNil(projectId) ? { equal: projectId } : undefined,
          archivedOn: { isNull: true },
        },
        createdBy: getGraphQlEqualComparison(memberId),
        createdByMember: {
          permissions: mediaCollectionViewPermissionFilter,
        },
        deletedOn: { isNull: true },
        createdOn: {
          between: {
            start: timeRange.startTime
              .minus(timeRange.startTime.toLocal().offset * 60 * 1000)
              .toISO({ suppressSeconds: true }),
            end: timeRange.endTime
              .minus(timeRange.endTime.toLocal().offset * 60 * 1000)
              .toISO({ suppressSeconds: true }),
          },
        },
      },
    }),
    [mediaCollectionViewPermissionFilter, memberId, projectId, timeRange.endTime, timeRange.startTime]
  );

  const fetch = useAsyncApollo<IMediaCollection & ICursorable>(
    'mediaCollections',
    {
      query: PHOTOS_AND_NOTES_DASHBOARD_CARD_QUERY,
      variables,
      fetchPolicy: 'network-only',
    },
    false
  );

  const data = zipDaysToValue(assignItemsByCreationToRanges(ranges, fetch.data ?? []));

  return {
    data,
    total: sumBy(data, (datum) => datum.value),
    loading: fetch.loading,
    error: fetch.error,
    execute: fetch.execute,
  };
}
