import { IDivProps } from '@busybusy/webapp-react-ui/dist/types/props/DivProps';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import './FlatCard.scss';

export interface IFlatCardProps extends Omit<IDivProps, 'className'> {
  className?: ClassName;
}

function FlatCard({ className, ...divProps }: IFlatCardProps) {
  return <div {...divProps} className={classNames('flat-card', className)} />;
}

export default FlatCard;
