import { } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import * as React from 'react';
import { IChildrenProps } from 'types/ChildrenProps';
import { ClassName } from 'types/ClassName';
import './SectionHeader.scss';

export interface ISectionHeaderProps extends IChildrenProps {
  className?: ClassName;
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({ className, children }: ISectionHeaderProps) => {
  const classes = classNames('section-header', className);
  return <header className={classes}>{children}</header>;
};

export default SectionHeader;
