import { useApolloClient } from '@apollo/client';
import { Bar, Button, ButtonList, Icon, Justify, Position, Size } from '@busybusy/webapp-react-ui';
import { COST_CODE_NAME_QUERY } from 'apollo/queries/cost-code-queries';
import { SIMPLE_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { MEMBER_NAME_QUERY } from 'apollo/queries/member-queries';
import { GET_PROJECT_TITLE_QUERY } from 'apollo/queries/project-queries';
import { ArrowDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { MoreButton, Well } from 'components';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import TimeEntryActionBar from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBar';
import { useTimeEntryActionBarState } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import ReportPrintHeader from 'components/foundation/ReportPrintHeader/ReportPrintHeader';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelContext } from 'contexts/ChannelContext/useChannelContext';
import { useOpenable } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IMember } from 'types';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { stringUtils } from 'utils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import ActivityReportSummary from '../ActivityReportSummary/ActivityReportSummary';
import ActivityReportTimeEntries from '../ActivityReportTimeEntries/ActivityReportTimeEntries';
import './ActivityDetailsDialog.scss';

export enum ReportViewType {
  TIME_ENTRIES = 'time-entries',
  SUMMARY = 'summary',
}

export interface IActivityDetailsDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  filterId?: string | null;
  filterType: ActivityReportType;
  filterByUnassignedId?: boolean;
  initialReportType?: ActivityReportType;
}

const ActivityDetailsDialog = ({
  className,
  isOpen,
  onClose,
  timeRange,
  timeRangeType,
  filterId,
  filterType,
  filterByUnassignedId,
  initialReportType,
}: IActivityDetailsDialogProps) => {
  const client = useApolloClient();
  const nameFormatted = useEmployeeNameFormatter();
  const [title, setTitle] = useState<string>('');
  const [viewType, setViewType] = useState<ReportViewType>(ReportViewType.SUMMARY);
  const [reportType, setReportType] = useState<ActivityReportType | undefined>(initialReportType);
  const [secondaryReportType, setSecondaryReportType] = useState<ActivityReportType>(ActivityReportType.BASIC);
  const [byDateRangeReportType, setByDateRangeReportType] = useState<TimeRangeType>(TimeRangeType.WEEKLY);
  const [scroller, setScroller] = useState<HTMLElement | null>(null);
  const proUpgradeDialogDetails = useOpenable();
  const isPro = useFeatureFlags('PRO');
  const hasThirdDimension = useFeatureFlags('ACTIVITY_REPORTS_PHASE_3000');
  const { emit } = useChannelContext();

  const viewTypeOptions = useRef([
    {
      name: t('Summary'),
      id: ReportViewType.SUMMARY,
    },
    {
      name: t('Time Entries'),
      id: ReportViewType.TIME_ENTRIES,
    },
  ]);

  const allOptions = useRef([
    {
      name: t('By Project'),
      id: ActivityReportType.BY_PROJECT,
    },
    {
      name: t('By Employee'),
      id: ActivityReportType.BY_EMPLOYEE,
    },
    {
      name: t('By Cost Code'),
      id: ActivityReportType.BY_COST_CODE,
    },
    {
      name: t('By Equipment'),
      id: ActivityReportType.BY_EQUIPMENT,
    },
    {
      name: t('By Day'),
      id: ActivityReportType.BY_DAY,
    },
    {
      name: t('By Date Range'),
      id: ActivityReportType.BY_DATE_RANGE,
    },
  ]);

  const reportTypeOptions = useMemo(() => {
    return allOptions.current.filter((option) => {
      if (filterType === ActivityReportType.BY_DAY) {
        return option.id !== ActivityReportType.BY_DAY && option.id !== ActivityReportType.BY_DATE_RANGE;
      }

      return option.id !== filterType;
    });
  }, [filterType]);

  const secondaryReportTypeOptions = useMemo(() => {
    const basicItem = {
      name: t('Basic'),
      id: ActivityReportType.BASIC,
    };

    const reportOptions = allOptions.current.filter((option) => {
      if (filterType === ActivityReportType.BY_DAY || reportType === ActivityReportType.BY_DAY) {
        return option.id !== filterType && option.id !== reportType && option.id !== ActivityReportType.BY_DATE_RANGE;
      }

      return option.id !== filterType && option.id !== reportType;
    });

    return [basicItem, ...reportOptions];
  }, [filterType, reportType]);

  const dateRangeOptions = useRef([
    {
      name: t('By Week'),
      id: TimeRangeType.WEEKLY,
    },
    {
      name: t('By Month'),
      id: TimeRangeType.MONTHLY,
    },
    {
      name: t('By Pay Period'),
      id: TimeRangeType.PAY_PERIOD,
    },
  ]);

  const [, timeEntryActionBarDispatch] = useTimeEntryActionBarState();

  useEffect(() => {
    if (isOpen) {
      getDialogName();
      //setting to default when opening new report dialog
      if (isNil(initialReportType)) {
        setReportType(
          filterType === ActivityReportType.BY_EMPLOYEE ? ActivityReportType.BY_PROJECT : ActivityReportType.BY_EMPLOYEE
        );
      }
    } else {
      //resetting to default values when closing dialog
      setReportType(initialReportType);
      setViewType(ReportViewType.SUMMARY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function renderReport(range: ITimeRange<DateTime>) {
    if (viewType === ReportViewType.SUMMARY) {
      return (
        <>
          {!isPro && (
            <Well theme={'primary'} className={'mb-6 mx-6'}>
              {t('Your subscription is limited to view only weekly data.')}
            </Well>
          )}
          <ActivityReportSummary
            filterType={filterType!}
            filterId={filterId}
            filterByUnassignedId={filterByUnassignedId}
            timeRange={range}
            scroller={scroller}
            reportType={reportType!}
            secondaryReportType={secondaryReportType}
            timeRangeType={timeRangeType}
            dateRangeReportType={byDateRangeReportType}
          />
        </>
      );
    }

    return (
      <>
        {!isPro && (
          <Well theme={'primary'} className={'mb-6 mx-6'}>
            {t('Your subscription is limited to view only weekly data.')}
          </Well>
        )}
        <ActivityReportTimeEntries
          filterType={filterType!}
          filterId={filterId}
          filterByUnassignedId={filterByUnassignedId}
          timeRange={range}
          scroller={scroller}
          reportType={reportType!}
          dateRangeReportType={byDateRangeReportType}
        />
      </>
    );
  }

  function renderActionBar() {
    if (reportType) {
      return (
        <TimeEntryActionBar
          onBulkEditSubmit={(entries) => timeEntryActionBarDispatch({ type: 'ON_BULK_EDIT_SUBMIT', payload: entries })}
          onDelete={(ids) => timeEntryActionBarDispatch({ type: 'ON_DELETE', payload: ids })}
          onTimeEntryEdit={(data) => timeEntryActionBarDispatch({ type: 'ON_TIME_ENTRY_EDIT', payload: data })}
        />
      );
    }
    return <></>;
  }

  async function setTitleForMember() {
    const results = await client.query<{ members: IMember[] }>({
      query: MEMBER_NAME_QUERY,
      variables: { filter: { id: { equal: filterId! } } },
      fetchPolicy: 'network-only',
    });
    const member = results.data.members[0];
    setTitle(nameFormatted(member?.firstName ?? '', member?.lastName ?? ''));
  }

  async function setTitleForProject() {
    if (filterId === remainingDataItemId) {
      setTitle(t('No Project'));
    } else {
      const results = await client.query<{ projects: IProject[] }>({
        query: GET_PROJECT_TITLE_QUERY,
        variables: { filter: { id: { equal: filterId! } } },
        fetchPolicy: 'network-only',
      });
      const projectPostfix = filterByUnassignedId ? ' - ' + t('unassigned') : '';
      setTitle(results.data.projects[0]?.title + projectPostfix ?? '');
    }
  }

  async function setTitleForCostCode() {
    if (filterId === remainingDataItemId) {
      setTitle(t('No Cost Code'));
    } else {
      const results = await client.query<{ costCodes: ICostCode[] }>({
        query: COST_CODE_NAME_QUERY,
        variables: { filter: { id: { equal: filterId! } } },
        fetchPolicy: 'network-only',
      });
      const costCode = results.data.costCodes[0];
      setTitle(stringUtils.getCostCodeDisplay(costCode));
    }
  }

  async function setTitleForEquipment() {
    if (filterId === remainingDataItemId) {
      setTitle(t('No Equipment'));
    } else {
      const results = await client.query<{ equipment: IEquipment[] }>({
        query: SIMPLE_EQUIPMENT_QUERY,
        variables: { filter: { id: { equal: filterId! } } },
        fetchPolicy: 'network-only',
      });
      const equipment = results.data.equipment[0];
      setTitle(stringUtils.getEquipmentDisplay(equipment));
    }
  }

  const getDialogName = async () => {
    if (filterType === ActivityReportType.BY_EMPLOYEE) {
      await setTitleForMember();
    } else if (filterType === ActivityReportType.BY_PROJECT) {
      await setTitleForProject();
    } else if (filterType === ActivityReportType.BY_COST_CODE) {
      await setTitleForCostCode();
    } else if (filterType === ActivityReportType.BY_EQUIPMENT) {
      setTitleForEquipment();
    } else if (filterType === ActivityReportType.BY_DAY || filterType === ActivityReportType.BY_DATE_RANGE) {
      setTitle('');
    }
  };

  function renderFilterBar(range: ITimeRange<DateTime>) {
    return (
      <>
        <ReportPrintHeader className="details-print-header" title={title} timeRange={range} />
        <Bar className="filter-bar mb-8 no-print" justify={Justify.SPACE_BETWEEN} size={Size.GROW}>
          <Bar justify={Justify.FLEX_START} size={Size.GROW}>
            <BasicPicker
              data={viewTypeOptions.current}
              onSelect={(type) => {
                setViewType(type!);
              }}
              value={viewType}
              className="type-selector ml-6 my-4"
              clearable={false}
              closeButtonRender={() => (
                <span className="drop-icon">
                  <Icon svg={ArrowDownIcon} />
                </span>
              )}
            />
            <BasicPicker
              data={reportTypeOptions}
              onSelect={(type) => {
                setSecondaryReportType(ActivityReportType.BASIC);
                setReportType(type!);
              }}
              value={reportType!}
              className="type-selector ml-3 my-4"
              clearable={false}
              closeButtonRender={() => (
                <span className="drop-icon">
                  <Icon svg={ArrowDownIcon} />
                </span>
              )}
            />
            <ConditionalContainer condition={reportType === ActivityReportType.BY_DATE_RANGE}>
              <BasicPicker
                data={dateRangeOptions.current}
                onSelect={(type) => {
                  setByDateRangeReportType(type!);
                }}
                value={byDateRangeReportType}
                className="type-selector ml-3 my-4"
                clearable={false}
                closeButtonRender={() => (
                  <span className="drop-icon">
                    <Icon svg={ArrowDownIcon} />
                  </span>
                )}
              />
            </ConditionalContainer>
            <ConditionalContainer condition={hasThirdDimension && viewType === ReportViewType.SUMMARY}>
              <BasicPicker
                data={secondaryReportTypeOptions}
                onSelect={(type) => {
                  setSecondaryReportType(type!);
                }}
                value={secondaryReportType!}
                className="type-selector ml-3 my-4"
                clearable={false}
                closeButtonRender={() => (
                  <span className="drop-icon">
                    <Icon svg={ArrowDownIcon} />
                  </span>
                )}
              />
            </ConditionalContainer>
            <ConditionalContainer condition={secondaryReportType === ActivityReportType.BY_DATE_RANGE}>
              <BasicPicker
                data={dateRangeOptions.current}
                onSelect={(type) => {
                  setByDateRangeReportType(type!);
                }}
                value={byDateRangeReportType}
                className="type-selector ml-3 my-4"
                clearable={false}
                closeButtonRender={() => (
                  <span className="drop-icon">
                    <Icon svg={ArrowDownIcon} />
                  </span>
                )}
              />
            </ConditionalContainer>
          </Bar>

          <MoreButton
            className="mr-2"
            position={Position.BOTTOM_END}
            size={Size.MEDIUM}
            renderContent={renderMoreContent}
          />
        </Bar>
      </>
    );
  }

  function renderMoreContent(close: () => void) {
    const executeWithClose = (fn: () => void) => () => {
      close();
      setImmediate(fn);
    };

    return (
      <ButtonList>
        {isPro && <Button onClick={executeWithClose(exportReport)}>{t('Export')}</Button>}
        <Button onClick={executeWithClose(printReport)}>{t('Print')}</Button>
      </ButtonList>
    );
  }

  function exportReport() {
    emit(ChannelIds.Export);
  }

  function printReport() {
    if (viewType === ReportViewType.SUMMARY) {
      // tell summary tables to not lazy render so we can print everything
      emit(ChannelIds.LazyLoadUpdate);
    }

    emit(ChannelIds.Print);
  }

  function timeActionForword() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return true;
    }

    return false;
  }

  function timeActionBack() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return true;
    }

    return false;
  }

  const classes = classNames(
    'activity-details-dialog',
    {
      'time-entry-report-filtered-by-project': filterType === ActivityReportType.BY_PROJECT,
      'time-entry-report-filtered-by-employee': filterType === ActivityReportType.BY_EMPLOYEE,
      'time-entry-report-filtered-by-cost-code': filterType === ActivityReportType.BY_COST_CODE,
      'time-entry-report-filtered-by-equipment': filterType === ActivityReportType.BY_EQUIPMENT,
      'time-entry-report-filtered-by-date': filterType === ActivityReportType.BY_DAY,
      'time-entry-report-filtered-by-date-range': filterType === ActivityReportType.BY_DATE_RANGE,
    },
    className
  );

  if (isOpen && !isNil(reportType)) {
    return (
      <>
        <ManagedTimeRangeReportDialog
          className={classes}
          isOpen={isOpen}
          onClose={onClose}
          timeRange={timeRange}
          timeRangeType={timeRangeType}
          reportComponent={renderReport}
          title={title}
          renderActionBar={renderActionBar}
          renderFilterBar={renderFilterBar}
          setRef={setScroller}
          timeRangeBackPressed={timeActionBack}
          timeRangeForwardPressed={timeActionForword}
          headerClassName={'no-print'}
        />
        <ProFeatureDialog
          customMessage={t('Viewing historical activity report data is not available in your subscription.')}
          isOpen={proUpgradeDialogDetails.isOpen}
          onClose={proUpgradeDialogDetails.close}
        />
      </>
    );
  } else {
    return <></>;
  }
};

function ActivityDetailsDialogContainer(props: IActivityDetailsDialogProps) {
  if (!props.isOpen) {
    return null;
  } else {
    return <ActivityDetailsDialog {...props} />;
  }
}

export default ActivityDetailsDialogContainer;
