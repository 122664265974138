import { ClassName } from '@busybusy/webapp-react-ui';
import PickerDropDownIcon from 'components/foundation/pickers/Picker/PickerDropDownIcon/PickerDropDownIcon';
import BasicPicker, { IBasicPickerProps } from '../BasicPicker/BasicPicker';

export interface IBasicNonNullPickerProps<T extends string = string>
  extends Omit<IBasicPickerProps<T>, 'closeButtonRender' | 'value' | 'onSelect'> {
  onSelect: (value: T) => void;
  value: T;
  className?: ClassName;
}

function renderCloseButtonIcon() {
  return <PickerDropDownIcon />;
}

function BasicNonNullPicker<T extends string>({ onSelect, ...rest }: IBasicNonNullPickerProps<T>) {
  function onNonNullSelect(value: T | null) {
    if (!value) {
      throw Error('Cannot clear `BasicNonNullPicker` value.');
    }

    onSelect(value);
  }

  return <BasicPicker onSelect={onNonNullSelect} closeButtonRender={renderCloseButtonIcon} {...rest} />;
}

export default BasicNonNullPicker;
