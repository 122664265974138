import { IDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import {
  SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import { useDashboardTimeRange } from 'containers/dashboard/hooks';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { convertTotalsToDashboardListRow } from 'containers/dashboard/util/utils';
import { useApolloPaging, useAsync, useOrganization } from 'hooks';
import { first, isNil } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import { mapNotNull } from 'utils/collectionUtils';
import { getMetricLaborTotals } from 'utils/jitMetricUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { LaborMetricsInterval } from '__generated__/graphql';

export default function useActiveEquipmentDashboard(
  timeFrame: DashboardTimeFrame,
  memberId?: string | null,
  projectId?: string | null,
  costCodeId?: string | null
) {
  const organization = useOrganization();
  const timeRange = useDashboardTimeRange(timeFrame);
  const { getAll } = useApolloPaging();

  const getData = useCallback(() => {
    let query = SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY;
    if (!isNil(memberId)) {
      query = SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY;
    } else if (!isNil(projectId)) {
      query = SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
    } else if (!isNil(costCodeId)) {
      query = SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY;
    }

    return getAll<IEquipment & ICursorable>('equipment', {
      query: query,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ equipmentName: 'asc' }],
        filter: {
          deletedOn: { isNull: true },
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: timeRange.endTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
            memberId: !isNil(memberId) ? { equal: memberId } : undefined,
            projectIdWithDescendants: !isNil(projectId) ? { equal: projectId } : undefined,
            costCodeId: !isNil(costCodeId) ? { equal: costCodeId } : undefined,
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        memberIds: !isNil(memberId) ? [memberId] : undefined,
        projectIds: !isNil(projectId) ? [projectId] : undefined,
        costCodeIds: !isNil(costCodeId) ? [costCodeId] : undefined,
      },
    });
  }, [getAll, timeRange.endTime, timeRange.startTime, memberId, projectId, costCodeId]);

  const { execute, loading, error, data } = useAsync(getData, false);
  const rowData: IDashboardListRow[] = useMemo(() => {
    const titleToTotal = mapNotNull(data ?? [], (equipment) => {
      let total = 0;

      if (!isNil(memberId)) {
        total = getMetricLaborTotals(first(equipment.equipmentMemberLaborMetrics))?.totalSeconds ?? 0;
      } else if (!isNil(projectId)) {
        total = getMetricLaborTotals(first(equipment.equipmentProjectLaborMetrics))?.totalSeconds ?? 0;
      } else if (!isNil(costCodeId)) {
        total = getMetricLaborTotals(first(equipment.equipmentCostCodeLaborMetrics))?.totalSeconds ?? 0;
      } else {
        total = getMetricLaborTotals(first(equipment.equipmentLaborMetrics))?.totalSeconds ?? 0;
      }

      return total > 0
        ? {
            id: equipment.id,
            title: getEquipmentDisplay(equipment),
            total,
          }
        : null;
    });

    return convertTotalsToDashboardListRow(titleToTotal);
  }, [data]);

  useEffect(() => {
    if (organization.trackEquipment) {
      execute();
    }
  }, [timeFrame, organization.trackEquipment, execute, memberId, projectId, costCodeId]);

  return {
    data: rowData,
    loading,
    error,
    execute,
  };
}
