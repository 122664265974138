import {
  Button,
  Checkbox,
  CheckboxFormField,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  ITotalFieldPayload,
  Label,
  TextareaFormField,
  Theme,
  Toast,
  Tray,
} from '@busybusy/webapp-react-ui';
import { requiredDateValidation } from '@busybusy/webapp-react-ui/dist/utilities/validations';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import LockDateDialog, { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import EmployeeMultiPickerFormField from 'components/domain/member/EmployeeMultiPickerFormField/EmployeeMultiPickerFormField';
import useTimeActionsForm from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import TotalTimeField from 'components/foundation/TotalTimeField';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import Panel from 'components/layout/Panel/Panel';
import { useMemberLock, useOpenable } from 'hooks';
import { t } from 'i18next';
import { first } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import TimeOffType from 'types/enum/TimeOffType';
import { getDayRanges } from 'utils/dateUtils';
import { v_require } from 'utils/validations';
import TimeOffTypeSelectFormField from '../../TimeOffTypeSelectFormField/TimeOffTypeSelectFormField';

export interface ICreateTimeOffFormProps {
  className?: ClassName;
  formData?: ITimeOffPropsFormData;
  initialMultipleDays?: boolean;
  renderEmployeePicker?: boolean;
  onSubmit: (data: ITimeOffFormData) => void;
}

export interface ITimeOffPropsFormData {
  members?: string[];
  startDate?: IDatePickerPayload;
  endDate?: IDatePickerPayload;
  paid?: boolean;
  type?: TimeOffType;
  total?: { [key: string]: number };
}

export interface ITimeOffFormData {
  members: string[];
  startDate: IDatePickerPayload;
  endDate?: IDatePickerPayload;
  description?: string;
  total?: { [key: string]: number };
  paid: boolean;
  type?: TimeOffType;
}

export default function CreateTimeOffForm({
  className,
  formData,
  initialMultipleDays,
  renderEmployeePicker,
  onSubmit,
}: ICreateTimeOffFormProps) {
  const today = DateTime.local().startOf('day');
  const defaultFormInfo: ITimeOffFormData = {
    members: formData?.members ? formData.members : [],
    startDate: formData?.startDate
      ? formData.startDate
      : { value: today.toJSDate(), inputValue: today.toFormat('MM/dd/yyyy') },
    endDate: formData?.endDate,
    paid: formData?.paid ? formData.paid : false,
    type: formData?.type ? formData.type : undefined,
    total: formData?.total ? formData.total : undefined,
  };

  const [formInfo, setFormInfo] = useState<ITimeOffFormData>(defaultFormInfo);
  const [multipleDays, setMultipleDays] = useState<boolean>(!!initialMultipleDays);
  const startDatePickerState = useOpenable();
  const endDatePickerState = useOpenable();
  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const lockDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeOffFormData>();
  const [hasLockDate, setHasLockDate] = useState<boolean>(false);
  const [hasSigViolation, setHasSigViolation] = useState<boolean>(false);
  const checkValidationsPayload = useRef<{
    lock: boolean;
    signatureViolation: boolean;
  }>({ lock: true, signatureViolation: true });
  const { inhibitsActionsForMembers } = useMemberLock();
  const signatureValidationDetails = useOpenable();
  const { hasSignatureConflicts } = useTimeActionsForm();
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      // reset the end time whenever the multiple days toggle is changed
      setFormInfo({
        ...formInfo,
        endDate: { ...formInfo.startDate },
      });
    }

    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleDays]);

  useEffect(() => {
    // when paid, startDate, endDate are changed we need to refresh our list of paid days
    if (formInfo.paid) {
      const startDate = formInfo.startDate.value!;
      let endDate = formInfo.endDate ? formInfo.endDate.value! : formInfo.startDate.value!;
      let totalsObject = { ...formInfo.total };

      // if the user moved the start day to be after the end day, then just setup the totals for the start day
      if (endDate < startDate) {
        endDate = startDate;
      }

      // set the default time for days that haven't been set by the user yet
      getDayRanges(startDate, endDate).forEach((day) => {
        const existingValue = totalsObject[day.toDateString()];
        if (!existingValue) {
          // if the day is Saturday or Sunday then default to 0
          if (day.getDay() === 6 || day.getDay() === 0) {
            totalsObject[day.toDateString()] = 0;
          } else {
            totalsObject[day.toDateString()] = 28800;
          }
        }
      });

      if (!multipleDays && formInfo.total) {
        const key = first(Object.keys(formInfo.total));
        if (key) {
          totalsObject = { [startDate.toDateString()]: formInfo.total[key] ?? 0 };
        }
      }

      setFormInfo({
        ...formInfo,
        total: totalsObject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo.paid, formInfo.startDate, formInfo.endDate]);

  useEffect(() => {
    inhibitsTimeOffActions();
    signatureConflicts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo.members, formInfo.startDate]);

  async function inhibitsTimeOffActions() {
    if (formInfo.startDate.value) {
      const memberIds = formInfo.members;
      const inhibited = await inhibitsActionsForMembers(memberIds, DateTime.fromJSDate(formInfo.startDate.value!));
      setHasLockDate(inhibited);
    } else {
      setHasLockDate(true);
    }
  }

  async function signatureConflicts() {
    if (formInfo.startDate.value) {
      const memberIds = formInfo.members;
      const inhibited = await hasSignatureConflicts(memberIds, {
        startTime: DateTime.fromJSDate(formInfo.startDate.value!),
        endTime: DateTime.fromJSDate(formInfo.startDate.value!),
      });
      setHasSigViolation(inhibited);
    } else {
      setHasSigViolation(true);
    }
  }

  const classes = classNames('create-time-off-form', className);

  function onFormDataChange(data?: ITimeOffFormData) {
    if (data && !multipleDays) {
      setFormInfo({
        ...data,
        endDate: { ...data.startDate },
      });
    } else if (data) {
      setFormInfo(data);
    }
  }

  function renderFormFields({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <div>
        {renderEmployeePicker === false ? null : renderEmployeesPicker(form)}
        {renderCheckBoxes(form)}
        {renderDatePickers(form)}
        {formInfo.paid ? renderPaidTotals() : null}
        {renderType(form)}
        {renderDescription(form)}
        {renderCreateButton(form)}
      </div>
    );
  }

  function renderEmployeesPicker({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <div>
        <Label>{t('Employees')}</Label>
        <EmployeeMultiPickerFormField
          name="members"
          form={form}
          permissions={{ permissions: [MemberPermission.MANAGE_TIME_OFF], operationType: OperationType.AND }}
          validations={[{ validation: v_require }]}
          onChange={(values) =>
            setFormInfo({
              ...formInfo,
              members: values,
            })
          }
        />
      </div>
    );
  }

  function renderDatePickers({ ...form }: IFormRender<ITimeOffFormData>) {
    const oldestDate = DateTime.local().minus(Duration.fromISO('P3Y')); // subtracts 3 years
    const newestDate = DateTime.local().plus(Duration.fromISO('P3Y')); // adds 3 years

    const startDatePicker = (
      <DatePickerFormField
        name="startDate"
        form={form}
        disabledBefore={oldestDate.toJSDate()}
        disabledAfter={newestDate.toJSDate()}
        isOpen={startDatePickerState.isOpen}
        onOpen={startDatePickerState.open}
        onClose={startDatePickerState.close}
        validations={[{ validation: requiredDateValidation }]}
        onChange={(payload) =>
          setFormInfo({
            ...formInfo,
            startDate: payload,
          })
        }
      />
    );

    const endDatePicker = (
      <DatePickerFormField
        name="endDate"
        form={form}
        disabledBefore={formInfo.startDate.value!}
        disabledAfter={newestDate.toJSDate()}
        isOpen={endDatePickerState.isOpen}
        onOpen={endDatePickerState.open}
        onClose={endDatePickerState.close}
      />
    );

    if (multipleDays) {
      return (
        <div className="time-off-date-pickers-container mr-4">
          <div className="time-off-date-picker-item">
            <Label>{t('Start Date')}</Label>
            {startDatePicker}
          </div>
          <div className="time-off-date-picker-item ml-4">
            <Label>{t('End Date')}</Label>
            {endDatePicker}
          </div>
          {renderDateErrorFooter(form)}
        </div>
      );
    } else {
      return (
        <div>
          <Label>{t('Date')}</Label>
          {startDatePicker}
          {renderDateErrorFooter(form)}
        </div>
      );
    }
  }

  function renderDateErrorFooter({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <div className="pt-2 pb-4">
        {form.state.data.startDate.value && hasLockDate && (
          <Label className="start-date-warning">{t('Cannot set a start date older than the lock date.')}</Label>
        )}
      </div>
    );
  }

  function renderCheckBoxes({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <div className="time-off-checkboxes">
        <Checkbox type="basic" label={t('Multiple Days')} checked={multipleDays} onChange={setMultipleDays} />

        <CheckboxFormField name="paid" label={t('Paid')} form={form} />
      </div>
    );
  }

  function renderPaidTotals() {
    function onTotalChange(date: Date, value: ITotalFieldPayload) {
      if (value.isValid) {
        setFormInfo({
          ...formInfo,
          total: {
            ...formInfo.total,
            [date.toDateString()]: value.value! !== 1 ? value.value! : 0,
          },
        });
      }
    }

    const totalsObject = formInfo.total;
    const startDate = formInfo.startDate.value;
    const endDate = formInfo.endDate?.value;
    let paidTotalComponents;

    // only render the total time input fields if our total object has been created and we have a starting date
    if (totalsObject && startDate) {
      // totals for date range
      if (endDate && endDate > startDate) {
        // loop through each day in the range to setup a TotalTimeField for each day
        paidTotalComponents = getDayRanges(formInfo.startDate.value!, endDate).map((day) => {
          const existingValue = totalsObject[day.toDateString()];
          let totalTimeField = null;

          if (existingValue) {
            totalTimeField = (
              <div key={day.toString()} className="total-time-container">
                <TotalTimeField
                  className="total-time-picker-item"
                  value={existingValue}
                  onChange={(value) => onTotalChange(day, value)}
                  maxHours={23}
                />
                <Label className="ml-4">{DateTime.fromJSDate(day).toFormat('EEE, MMM d')}</Label>
              </div>
            );
          }

          return totalTimeField;
        });
      } else {
        // total for single day
        const totalValue = totalsObject[startDate.toDateString()];
        paidTotalComponents = (
          <TotalTimeField
            className="total-time-picker-item"
            value={totalValue ? totalValue : 0}
            onChange={(value) => onTotalChange(startDate, value)}
            maxHours={23}
          />
        );
      }
    }

    return (
      <div>
        <Label>{t('Totals')}</Label>
        {paidTotalComponents ? paidTotalComponents : null}
      </div>
    );
  }

  function renderType({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <div>
        <Label>{t('Type')}</Label>
        <TimeOffTypeSelectFormField name="type" placeholder="" form={form} />
      </div>
    );
  }

  function renderDescription({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <div>
        <Label secondaryLabel={t('Optional')}>{t('Description')}</Label>
        <TextareaFormField name="description" form={form} restrictTo={{ maxLength: 1000 }} />
      </div>
    );
  }

  function renderCreateButton({ ...form }: IFormRender<ITimeOffFormData>) {
    return (
      <Button type="primary" onClick={form.handleSubmit}>
        {t('Create')}
      </Button>
    );
  }

  async function onSubmitForm(data?: ITimeOffFormData) {
    if (checkValidationsPayload.current.lock && hasLockDate) {
      preValidationFormData.current = data;
      lockDialogDetails.open();
      return;
    }

    if (checkValidationsPayload.current.signatureViolation && hasSigViolation) {
      preValidationFormData.current = data;
      signatureValidationDetails.open();
      return;
    }

    if (data) {
      onSubmit(data);
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    onSubmitForm(preValidationFormData.current);
  }

  function onSignatureViolationContinue() {
    setHasSigViolation(false);
    checkValidationsPayload.current.signatureViolation = false;
    signatureValidationDetails.close();
    onSubmitForm(preValidationFormData.current);
  }

  return (
    <div className={classes}>
      <div className="mb-6 p-8">
        <Form
          data={formInfo}
          onSubmit={onSubmitForm}
          render={renderFormFields}
          onChange={onFormDataChange}
          className={classes}
          allowMultipleSubmissions={true}
        />

        <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>
        {formInfo.startDate.value && (
          <LockDateDialog
            date={DateTime.fromJSDate(formInfo.startDate.value)}
            type={LockDateDialogType.ADD}
            memberIds={formInfo.members}
            isOpen={lockDialogDetails.isOpen}
            onClose={lockDialogDetails.close}
            onMoveLockDate={onMoveLockDate}
          />
        )}
        <HeaderDialog
          title={t('Signed Time Card')}
          isOpen={signatureValidationDetails.isOpen}
          onClose={signatureValidationDetails.close}
          divider={false}
        >
          <Panel className={'p-5'}>
            {t(
              'One or more entries belong to a time card that has already been signed. Continuing will require the employee to re-sign.'
            )}
            <Tray className="tray-right pt-6" align="left">
              <Button className="right-button" type="secondary" onClick={signatureValidationDetails.close}>
                {t('Cancel')}
              </Button>
              <Button className="right-button" type="primary" onClick={onSignatureViolationContinue}>
                {t('Continue')}
              </Button>
            </Tray>
          </Panel>
        </HeaderDialog>
      </div>
    </div>
  );
}
