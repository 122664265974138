import gql from 'graphql-tag';

export const PROJECTS_WITH_TIME_QUERY = gql`
  query ProjectsWithTime(
    $after: String
    $filter: ProjectFilter
    $sort: [ProjectSort!]
    $first: Int
    $startTime: Timestamp!
    $endTime: Timestamp!
    $includeAncestors: Boolean!
    $includeOpenEntry: Boolean!
    $memberId: UuidComparison
    $costCodeId: UuidComparison
    $equipmentId: UuidComparison
    $pageNumber: Int
  ) {
    projectsWithTime(
      after: $after
      first: $first
      filter: $filter
      sort: $sort
      startTime: $startTime
      endTime: $endTime
      includeAncestors: $includeAncestors
      includeOpenEntry: $includeOpenEntry
      memberId: $memberId
      costCodeId: $costCodeId
      equipmentId: $equipmentId
      pageNumber: $pageNumber
    ) {
      id
      title
      depth
      rootProjectId
      projectInfo {
        id
        number
      }
      ancestors {
        id
        title
        depth
        archivedOn
      }
      cursor
    }
  }
`;
