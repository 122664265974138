import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  end_date?: string;
  employee_id?: string;
  project_id?: string;
  project_group_id?: string;
  employee_group_id?: string;
  position_id?: string;
  exitToLegacy?: boolean;
  time_range_type?: TimeRangeType;
  tag_id?: string;
}

export default function usePhotosNavigation() {
  return useNavigation<IParams>('/photos');
}
