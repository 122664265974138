import gql from 'graphql-tag';

export const MEMBER_TIME_DOCUMENT_FRAGMENT = gql`
  fragment MemberTimeDocumentFragment on MemberTimeDocument {
    id
    startTime
    endTime
    edited
    canceled
    regularSeconds
    overtimeSeconds
    doubletimeSeconds
    createdOn
    deletedOn
    submittedOn
    updatedOn
    cursor
    member {
      id
      firstName
      lastName
    }
    authoritativeSignature {
      id
      signatureUrl
      createdOnLocal
    }
    selfSignature {
      id
      signatureUrl
      createdOnLocal
    }
  }
`;

export const MEMBER_TIME_DOCUMENT_QUERY = gql`
  query MemberTimeDocuments(
    $first: Int
    $after: String
    $filter: MemberTimeDocumentFilter
    $sort: [MemberTimeDocumentSort!]
  ) {
    memberTimeDocuments(after: $after, first: $first, filter: $filter, sort: $sort) {
      __typename
      ...MemberTimeDocumentFragment
    }
  }
  ${MEMBER_TIME_DOCUMENT_FRAGMENT}
`;
