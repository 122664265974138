import gql from 'graphql-tag';

export const equipmentTypesQuery = gql`
  query EquipmentTypes($first: Int, $after: String, $filter: EquipmentTypeFilter) {
    equipmentTypes(first: $first, after: $after, filter: $filter, sort: [{ title: asc }]) {
      id
      title
      color
      display
      review
      cursor
    }
  }
`;

export const equipmentTypeQuery = gql`
  query EquipmentType($typeId: Uuid!) {
    equipmentTypes(filter: { id: { equal: $typeId } }) {
      id
      title
      color
      display
      review
      cursor
    }
  }
`;
