import { useApolloClient } from '@apollo/client';
import {
  LogFeedTimeEntryLogsQuery,
  LogFeedTimeEntryLogsQueryVariables,
  MemberPermissions,
  OperationType,
} from '__generated__/graphql';
import { getDefaultDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import { LOG_FEED_TIME_ENTRY_LOGS_QUERY } from 'containers/time-log-feed/apollo/time-log-feed-queries';
import { collapseTimeLogFeedLogs } from 'containers/time-log-feed/utils/utils';
import { useAsync } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { getGraphQlTimeRangeTimestampArgument } from 'utils/apolloUtils';
import { takeWithPopulate } from 'utils/collectionUtils';
import { convertRangeToUtcWithoutAdjustment } from 'utils/dateUtils';
import { useTimeLogFeedDashboardDataMapper } from '../useTimeLogFeedDashboardDataMapper/useTimeLogFeedDashboardDataMapper';

export default function useTimeLogFeedDashboardData() {
  const dataMapper = useTimeLogFeedDashboardDataMapper();
  const client = useApolloClient();

  const getData = useCallback(async () => {
    const logs =
      (
        await client.query<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>({
          query: LOG_FEED_TIME_ENTRY_LOGS_QUERY,
          variables: {
            // Due to collapsing logs need more than 4.
            first: 8,
            filter: {
              member: {
                permissions: {
                  operationType: OperationType.Or,
                  permissions: [MemberPermissions.TimeEvents],
                  includeSelf: true,
                },
              },
              createdOn: getGraphQlTimeRangeTimestampArgument(
                convertRangeToUtcWithoutAdjustment({
                  startTime: DateTime.local().minus({ days: 60 }).startOf('day'),
                  endTime: DateTime.local(),
                })
              ),
            },
          },
          fetchPolicy: 'network-only',
        })
      ).data?.clientTimeEntryLogs ?? [];

    // Cast due to utils using `ITimeEntryBreak` makes refactoring extremely cumbersome
    const collapsed = collapseTimeLogFeedLogs(logs as any);

    const results = collapsed.map(dataMapper);
    return takeWithPopulate(results, 4, () => ({
      ...getDefaultDashboardListRow(),
      sublabel: null,
      grouping: null,
    }));
  }, [client, dataMapper]);

  return useAsync(getData, true);
}
