import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import {
  ITimeEntryDataTableData,
  useTimeEntryDataTableRow,
} from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { usePermissions } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { first, isNil, keyBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IMember } from 'types';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import MemberPermission from 'types/enum/MemberPermission';
import { isNilOrEmpty } from 'utils/collectionUtils';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';
import useLazyMemberTimeData, { IMemberTimeEntryFilter } from './useLazyMemberTimeData';

export interface IMemberTimeEntryReportDataFilter {
  timeRange: ITimeRange<DateTime>;
  includeOpenEntries?: boolean;
  memberIds?: string[] | null;
  memberGroupId?: string | null;
  projectIds?: string[];
  costCodeIds?: string[];
  equipmentIds?: string[];
  positionId?: string;
  reportType?: TimesheetReportType;
  archivedStatus?: ArchivedStatus;
  projectIdWithDescendants?: boolean;
  permissions?: MemberPermission[];
  costCodeGroupId?: string | null;
  projectGroupId?: string | null;
}

export interface ITimeEntryDataReportMemberData extends ITimeEntryReportWithHeaderData {
  canManage: boolean;
  canManageTimeOff: boolean;
  isArchived: boolean;
}

export default function useMemberTimeEntryDataReportData(
  scroller: HTMLElement | Document | null,
  {
    timeRange,
    includeOpenEntries,
    memberIds,
    memberGroupId,
    projectIds,
    costCodeIds,
    equipmentIds,
    reportType,
    positionId,
    archivedStatus,
    projectIdWithDescendants,
    permissions,
    costCodeGroupId,
    projectGroupId,
  }: IMemberTimeEntryReportDataFilter,
  onDataUpdate?: (memberIds: string[]) => void
) {
  const [data, setData] = useState<ITimeEntryDataReportMemberData[]>([]);
  const nameFormatter = useEmployeeNameFormatter();
  const { hasPermissionToManage } = usePermissions();

  const filter: IMemberTimeEntryFilter = useMemo(() => {
    return {
      timeRange,
      permissions: permissions
        ? permissions
        : !isNilOrEmpty(memberIds) && memberIds?.length === 1
          ? [MemberPermission.TIME_EVENTS]
          : [MemberPermission.MANAGE_TIME_ENTRIES],
      reportType,
      memberIds,
      memberGroupId,
      projectIds,
      costCodeIds,
      equipmentIds,
      positionId,
      includeOpen: includeOpenEntries,
      archivedStatus,
      projectIdWithDescendants,
      costCodeGroupId,
      projectGroupId,
    };
  }, [
    timeRange,
    permissions,
    memberIds,
    reportType,
    memberGroupId,
    projectIds,
    costCodeIds,
    equipmentIds,
    positionId,
    includeOpenEntries,
    archivedStatus,
    projectIdWithDescendants,
    costCodeGroupId,
    projectGroupId,
  ]);

  const memberNameFormatter = useCallback(
    (member: IMember) => nameFormatter(member.firstName ?? '', member.lastName ?? ''),
    [nameFormatter]
  );

  const getSignature = useCallback(
    (member: IMember) => (member.memberTimeDocuments ? first(member.memberTimeDocuments) : null),
    []
  );

  const {
    data: groupedData,
    loading,
    updateMemberIds,
    error,
    forceLoadAll: loadAllGroupedData,
    loadedAll,
    loadAll,
    clearData,
  } = useLazyMemberTimeData(scroller, filter);

  const [loadingState, setLoadingState] = useState(loading);

  const getRows = useTimeEntryDataTableRow();

  const isLoadingAll = useRef<boolean>(false);

  const mapData = useCallback(
    (tableData: Array<ITimeEntryDataTableData<IMember> & ICursorable>, rows: ITimeEntryReportWithHeaderData[]) => {
      const mapped = tableData.map((tableDatum) => tableDatum.item);
      const resultsMap = keyBy(mapped, 'id');
      return rows.map((datum) => ({
        ...datum,
        canManage: hasPermissionToManage(resultsMap[datum.id], 'manageTimeEntries'),
        canManageTimeOff: hasPermissionToManage(resultsMap[datum.id], 'manageTimeOff'),
        isArchived: !isNil(resultsMap[datum.id].archivedOn),
      }));
    },
    [hasPermissionToManage]
  );

  useEffect(() => {
    if (isLoadingAll.current === false) {
      setLoadingState(true);

      const fetchData = async () => {
        try {
          const newData = await getData();
          setData(mapData(groupedData, newData));
          setLoadingState(false);
        } catch (error) {
          setLoadingState(false);
        }
      };

      fetchData();
    }
  }, [groupedData, memberNameFormatter, mapData, filter.timeRange]);

  const getData = async () => {
    try {
      return await getRows(timeRange, groupedData, memberNameFormatter, getSignature);
    } catch (error) {
      return [];
    }
  };
  const onDataChange = useCallback(
    async (ids: string[]) => {
      await updateMemberIds(ids);
      onDataUpdate?.(ids);
    },
    [onDataUpdate, updateMemberIds]
  );

  return useMemo(() => {
    async function loadAllRows() {
      isLoadingAll.current = true;
      setLoadingState(true);
      const results = await loadAllGroupedData();
      const newData = await getRows(timeRange, results, memberNameFormatter, getSignature);
      setLoadingState(false);
      setData(mapData(results, newData));
      isLoadingAll.current = false;
    }
    return {
      data,
      loading: loadingState,
      onDataChange,
      error,
      loadAllRows,
      loadAllGroupedData,
      loadedAll,
      loadAll,
      clearData,
    };
  }, [
    data,
    loadingState,
    onDataChange,
    error,
    loadAllGroupedData,
    loadedAll,
    loadAll,
    mapData,
    clearData,
    getRows,
    timeRange,
    memberNameFormatter,
  ]);
}
