import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBudgetReportTableRow } from 'containers/budgets/hooks/useLazyBudgetReportData';

export interface IStoreBudgetReport {
  reportTableRows: IBudgetReportTableRow[];
}

export const initialState: IStoreBudgetReport = {
  reportTableRows: [],
};

const slice = createSlice({
  name: 'BudgetReports',
  initialState,
  reducers: {
    updateBudgetReportTableRows: (state: IStoreBudgetReport, action: PayloadAction<IBudgetReportTableRow[]>) => {
      state.reportTableRows = action.payload;

      return state;
    },
    clearBudgetReportTableRows: (state: IStoreBudgetReport) => {
      state.reportTableRows = [];

      return state;
    },
  },
});

export const { updateBudgetReportTableRows, clearBudgetReportTableRows } = slice.actions;
export default slice.reducer;
