import { Button, Icon } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../../../assets/icons';
import StateTemplate from '../StateTemplate/StateTemplate';

export interface IErrorStateProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  graphic?: ReactNode;
  footer?: ReactNode;
  className?: ClassName;
}

const ErrorState: React.FC<IErrorStateProps> = (props) => {
  const { graphic, title, footer, subtitle, className } = props;

  const { t } = useTranslation();

  const classes = classNames(
    {
      'error-state': true,
    },
    className
  );

  return (
    <StateTemplate
      graphic={graphic ? graphic : <Icon svg={ErrorIcon} size={180} />}
      title={title ? title : t('An error has occurred')}
      className={classes}
      subtitle={subtitle ? subtitle : t(`Try reloading the page or contact our customer support team so we can help.`)}
      footer={
        footer ? (
          footer
        ) : (
          <Button className="mt-3" type="secondary" onClick={() => window.location.reload()}>
            {t('Reload page')}
          </Button>
        )
      }
    />
  );
};

export default ErrorState;
