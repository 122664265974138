import { QueryManageEquipmentQuery, QueryManageEquipmentQueryVariables } from '__generated__/graphql';
import { MANAGE_EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useReactQueryLazyLoading } from 'hooks/react-query/useReactQueryLazyLoading/useReactQueryLazyLoading';
import { useCallback } from 'react';
import IEquipment from 'types/Equipment';
import { IManageEquipmentRowInfo } from '../ManageEquipment';
import { getCurrentEquipmentCostHistory } from 'utils/equipmentUtils';

export default function useEquipmentManagementData(
  scroller: HTMLElement | null,
  variables: QueryManageEquipmentQueryVariables,
  pageSize: number
) {
  const graphqlClient = useGraphQLClient();
  const baseQueryKey = useReactQueryBaseKey();
  const queryKey = [...baseQueryKey, 'manage-equipment', variables];

  const loadEquipment = useCallback(
    async (after: string | null, pageSize: number) => {
      const equipmentRequest = await graphqlClient.request<
        QueryManageEquipmentQuery,
        QueryManageEquipmentQueryVariables
      >({
        document: MANAGE_EQUIPMENT_QUERY,
        variables: {
          ...variables,
          after,
          first: pageSize,
        },
      });

      if (!equipmentRequest.equipment) {
        return [];
      }

      return equipmentRequest.equipment.map((equipment): IManageEquipmentRowInfo => {
        const latestHistory = getCurrentEquipmentCostHistory(equipment.costHistory ?? []);

        return {
          id: equipment.id,
          name: equipment.equipmentName,
          make: equipment.model && !equipment.model.make.unknown ? equipment.model.make.title ?? '---' : '---',
          model: equipment.model && !equipment.model.unknown ? equipment.model.title ?? '---' : '---',
          year: equipment.model?.year?.toString() ?? '---',
          category: equipment.model?.category.title ?? '---',
          hourMeter: equipment.trackManualHours
            ? equipment.lastHours?.runningHours
              ? equipment.lastHours.runningHours.toString()
              : '---'
            : '---',
          costHistory: latestHistory ? (latestHistory.operatorCostRate ?? 0).toFixed(2) : '---',
          type: equipment.model?.category?.type?.title ?? '---',
          // TODO: Change out type to `ManagementEquipment` if we ever get our nested dialogs to be more generic
          equipment: equipment as unknown as IEquipment,
          cursor: equipment.cursor,
          updatedOn: equipment.updatedOn,
          createdOn: equipment.createdOn,
          updatedByMember: equipment.updatedByMember,
        };
      });
    },
    [graphqlClient, variables, pageSize]
  );

  return useReactQueryLazyLoading<IManageEquipmentRowInfo>(scroller, queryKey, loadEquipment, pageSize);
}
