import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import useMemberSettings from '../member/useMemberSettings';
import useMemberWebSettingsUpdate from '../member/useMemberWebSettingsUpdate';

export default function useTableRowHeightApplyAll() {
  const memberSettings = useMemberSettings();
  const updateWebSettings = useMemberWebSettingsUpdate();

  return useCallback(
    (value: TableCellHeight) => {
      const clonedFeatures = cloneDeep(memberSettings?.web?.features);

      const mappedActivityReport = {
        ...clonedFeatures?.activityReport,
        rowHeight: value,
      };
      const mappedBreakHoursReport = {
        ...clonedFeatures?.breakHoursReport,
        rowHeight: value,
      };
      const mappedCostCodeLandingPage = {
        ...clonedFeatures?.costCodeLandingPage,
        rowHeight: value,
      };
      const mappedDailyProjectReport = {
        ...clonedFeatures?.dailyProjectReport,
        rowHeight: value,
      };
      const mappedDailySignIn = {
        ...clonedFeatures?.dailySignIn,
        rowHeight: value,
      };
      const mappedDailySignOff = {
        ...clonedFeatures?.dailySignOff,
        rowHeight: value,
      };
      const mappedDocuments = {
        ...clonedFeatures?.documents,
        rowHeight: value,
      };
      const mappedEmployeeLandingPage = {
        ...clonedFeatures?.employeeLandingPage,
        rowHeight: value,
      };
      const mappedEquipmentLandingPage = {
        ...clonedFeatures?.equipmentLandingPage,
        rowHeight: value,
      };
      const mappedEventLogsReport = {
        ...clonedFeatures?.eventLogsReport,
        rowHeight: value,
      };
      const mappedHourMeterReport = {
        ...clonedFeatures?.hourMeterReport,
        rowHeight: value,
      };
      const mappedMachineVsOperator = {
        ...clonedFeatures?.machineVsOperator,
        rowHeight: value,
      };
      const mappedManagementLists = {
        ...clonedFeatures?.managementLists,
        rowHeight: value,
      };
      const mappedPayroll = {
        ...clonedFeatures?.payroll,
        rowHeight: value,
      };
      const mappedPhotoVerification = {
        ...clonedFeatures?.photoVerification,
        rowHeight: value,
      };
      const mappedProgress = {
        ...clonedFeatures?.progress,
        rowHeight: value,
      };
      const mappedProjectLandingPage = {
        ...clonedFeatures?.projectLandingPage,
        rowHeight: value,
      };
      const mappedTimeCards = {
        ...clonedFeatures?.timeCards,
        rowHeight: value,
      };
      const mappedTimeOff = {
        ...clonedFeatures?.timeOffReport,
        rowHeight: value,
      };

      return updateWebSettings('features', {
        ...clonedFeatures,
        activityReport: mappedActivityReport,
        breakHoursReport: mappedBreakHoursReport,
        costCodeLandingPage: mappedCostCodeLandingPage,
        dailyProjectReport: mappedDailyProjectReport,
        dailySignIn: mappedDailySignIn,
        dailySignOff: mappedDailySignOff,
        documents: mappedDocuments,
        employeeLandingPage: mappedEmployeeLandingPage,
        equipmentLandingPage: mappedEquipmentLandingPage,
        eventLogsReport: mappedEventLogsReport,
        hourMeterReport: mappedHourMeterReport,
        machineVsOperator: mappedMachineVsOperator,
        managementLists: mappedManagementLists,
        payroll: mappedPayroll,
        photoVerification: mappedPhotoVerification,
        progress: mappedProgress,
        projectLandingPage: mappedProjectLandingPage,
        timeCards: mappedTimeCards,
        timeOffReport: mappedTimeOff,
      });
    },
    [memberSettings, updateWebSettings]
  );
}
