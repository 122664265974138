import { IFormRender, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import BreakMultiPickerFormField from 'components/domain/time-entry-break/BreakMultiPickerFormField/BreakMultiPickerFormField';
import { useDefaultTimes } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ITimeRange from 'types/TimeRange';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';
import { dateUtils, timeEntryBreakUtils, timeEntryUtils } from 'utils';
import { nightShiftAdjustment } from 'utils/dateUtils';
import { t } from 'utils/localize';

export interface ITimeActionsBreakFormFieldProps<T extends IFormData> {
  form: IFormRender<T>;
  startDate?: Optional<DateTime>;
  endDate?: Optional<DateTime>;
  timeRange: Optional<ITimeRange<Optional<DateTime>>>;
  onUpdate: (breaks: IBreakMultiPickerItem[], total: number, error: boolean) => void;
  className?: ClassName;
}

export function useTimeActionsBreakFormTimeRange(
  dateRange: Optional<ITimeRange<Optional<DateTime>>>,
  timeRange: Optional<ITimeRange<Optional<DateTime>>>
) {
  const { defaultStartTime, defaultEndTime } = useDefaultTimes();

  const timeEntryStartTime =
    dateRange?.startTime && timeRange?.startTime
      ? dateUtils.combineDateAndTime(dateRange.startTime, timeRange.startTime).set({ second: 0, millisecond: 0 })
      : defaultStartTime;

  const timeEntryEndTime =
    dateRange?.endTime && timeRange?.endTime
      ? dateUtils.combineDateAndTime(dateRange.endTime, timeRange.endTime).set({ second: 0, millisecond: 0 })
      : defaultEndTime;

  const { startTime, endTime } = nightShiftAdjustment({ startTime: timeEntryStartTime, endTime: timeEntryEndTime });

  return {
    startTime,
    endTime,
  };
}

export function useTimeActionsBreakTotal(timeRange: ITimeRange<DateTime>, endDate?: Nullable<DateTime>) {
  return useCallback(
    (breaks: IBreakMultiPickerItem[]) => {
      const effectiveEndDate = endDate ?? timeRange.endTime;
      const end = endDate ? dateUtils.combineDateAndTime(effectiveEndDate, timeRange.endTime) : timeRange.endTime;
      const range: ITimeRange<DateTime> = nightShiftAdjustment({
        startTime: timeRange.startTime,
        endTime: end,
      });

      const breakRanges = breaks.map((v: IBreakMultiPickerItem) => v.timeRange);
      const total = timeEntryUtils.getTotalFromDateTimes(range, breakRanges);
      const isError = timeEntryBreakUtils.isOutsideRange(range, breakRanges);

      return {
        total,
        error: isError,
      };
    },
    [endDate, timeRange.endTime, timeRange.startTime]
  );
}

interface IFormData {
  breaks: IBreakMultiPickerItem[];
}

function TimeActionsBreakFormField<T extends IFormData>({
  form,
  startDate,
  endDate,
  timeRange: givenTimeRange,
  onUpdate,
  className,
}: ITimeActionsBreakFormFieldProps<T>) {
  const timeRange = useTimeActionsBreakFormTimeRange(
    {
      startTime: startDate,
      endTime: endDate,
    },
    givenTimeRange
  );

  const update = useTimeActionsBreakTotal(timeRange, endDate);

  function onBreaksChanged(breaks: IBreakMultiPickerItem[]) {
    if (timeRange) {
      const { total, error } = update(breaks);
      onUpdate(breaks, total, error);
    }
  }

  return (
    <div className={classNames('time-action-break-form-fields', className)}>
      <Label secondaryLabel={t('Optional')}>{t('Breaks')}</Label>
      <BreakMultiPickerFormField
        name="breaks"
        className="pb-6"
        onChange={onBreaksChanged}
        timeEntryStart={timeRange.startTime}
        timeEntryEnd={timeRange.endTime}
        form={form}
        timeFormat={'t'}
      />
    </div>
  );
}

export default TimeActionsBreakFormField;
