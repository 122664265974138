import { Align, Justify, Row, Tray } from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import classNames from 'classnames';
import { HeaderDialog, IconButton, Panel } from 'components';
import DraggableList, { IUpdatedDraggable } from 'components/foundation/DraggableList/draggable-list/DraggableList';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useConfirmationDialog, useOpenable } from 'hooks';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { ClassName } from 'types/ClassName';
import { Nullable } from 'types/util/Nullable';
import { stopClickThrough } from 'utils/eventUtils';
import { t } from 'utils/localize';
import QuickTimeTemplateEditForm from '../forms/QuickTimeTemplateEditForm/QuickTimeTemplateEditForm';
import useQuickTimeTemplates from '../hooks/useQuickTimeTemplates';
import { ITimeEntryTemplate } from '../types/types';
import './QuickTimeTemplateList.scss';

export interface IQuickTimeTemplateListProps {
  className?: ClassName;
}

type IDraggableListItem = ITimeEntryTemplate & { draggable: true; id: string };

function QuickTimeTemplateList({ className }: IQuickTimeTemplateListProps) {
  const { templates, set, remove } = useQuickTimeTemplates();
  const deleteConfirmation = useConfirmationDialog();
  const [activeTemplate, setActiveTemplate] = useState<ITimeEntryTemplate | null>(null);
  const onEditDialogClose = useRef({ onClose: () => setActiveTemplate(null) });
  const editDialog = useOpenable(onEditDialogClose.current);

  // Template setting is asynchronous so need to have local state so it doesn't flip back after dragging
  const [localTemplates, setLocalTemplates] = useState<Array<IDraggableListItem>>([]);
  const [deletingTemplateName, setDeletingTemplateName] = useState<Nullable<string>>(null);

  useEffect(() => {
    setLocalTemplates(
      sortBy(
        map(templates, (value, key) => {
          return { ...value, draggable: true, id: key };
        }),
        'position'
      )
    );
  }, [templates]);

  function renderDraggableItem(item: IDraggableListItem) {
    function onRowDelete(event: React.MouseEvent) {
      setDeletingTemplateName(item.name);
      stopClickThrough(event);
      deleteConfirmation.setOnConfirm(() => {
        deleteConfirmation.close();
        remove(item.name);
        setDeletingTemplateName(null);
      });
      deleteConfirmation.open();
    }

    return (
      <Row className="template-row" justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
        <Tray spacing={1}>
          <IconButton svg={DeleteIcon} onClick={onRowDelete} />
          <div>{item.name}</div>
        </Tray>
      </Row>
    );
  }

  function onUpdate(items: Array<IUpdatedDraggable<IDraggableListItem>>) {
    const mapped = map(items, (item) => {
      const { draggable, id, ...template } = item.payload;
      return { ...template, position: item.index };
    });

    set(mapped);
    setLocalTemplates(
      map(mapped, (value, index) => {
        return { ...value, draggable: true, id: value.name, position: index };
      })
    );
  }

  function onRowEdit(item: IDraggableListItem) {
    const { draggable, ...template } = item;
    setActiveTemplate(template);
    editDialog.open();
  }

  return (
    <Panel className={classNames('quick-time-template-list', className)}>
      <DraggableList
        className="quick-time-draggable-list"
        items={localTemplates}
        renderItem={renderDraggableItem}
        onUpdate={onUpdate}
        onClick={onRowEdit}
        ripple={false}
      />
      <HeaderDialog isOpen={editDialog.isOpen} onClose={editDialog.close} title={t('Edit Template')}>
        {activeTemplate && (
          <QuickTimeTemplateEditForm template={activeTemplate} className="px-8 py-4" onSubmit={editDialog.close} />
        )}
      </HeaderDialog>
      <DeleteConfirmationDialog
        title={t('Delete Template')}
        isOpen={deleteConfirmation.isOpen}
        onDelete={deleteConfirmation.onConfirm}
        onClose={deleteConfirmation.close}
      >
        <Trans values={{ templateName: deletingTemplateName }}>
          {'Are you sure you want to delete {{ templateName }}?'}
        </Trans>
      </DeleteConfirmationDialog>
    </Panel>
  );
}

export default QuickTimeTemplateList;
