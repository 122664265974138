import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import * as React from 'react';
import { useRef } from 'react';
import SignatureSettings from 'types/enum/SignatureSettings';
import { t } from 'utils/localize';

export interface ISignatureSettingsSelectProps extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  onChange: (value: SignatureSettings, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SignatureSettingsSelect = ({ className, onChange, ...selectProps }: ISignatureSettingsSelectProps) => {
  function onChangeWage(value: string, event: React.ChangeEvent<HTMLSelectElement>) {
    onChange(value as SignatureSettings, event);
  }

  const options = useRef<ISelectOption[]>([
    {
      label: t('Type My Signature'),
      value: SignatureSettings.TYPE_SIGNATURE,
    },
    {
      label: t('Manually Sign'),
      value: SignatureSettings.MANUAL,
    },
  ]);

  const classes = classNames('signature-settings-select', className);

  return (
    <Select
      className={classes}
      placeholder={t('Select')}
      options={options.current}
      onChange={onChangeWage}
      {...selectProps}
    />
  );
};

SignatureSettingsSelect.defaultProps = {
  ...Select.defaultProps,
};

export default SignatureSettingsSelect;
