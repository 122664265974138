import { createSlice } from '@reduxjs/toolkit';
import { isInIframe } from 'utils/windowUtils';

export interface IStoreIframe {
  isInIframe: boolean;
}

const initialState: IStoreIframe = {
  isInIframe: isInIframe(),
};

const slice = createSlice({
  name: 'iframe',
  initialState,
  reducers: {
    setIsInIframe: (state: IStoreIframe, action: { payload: boolean }) => {
      state.isInIframe = action.payload;
      return state;
    },
  },
});

export const { setIsInIframe } = slice.actions;
export default slice.reducer;
