import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { isNil } from 'lodash';
import { FunctionComponent } from 'react';
import TimeEntryBreakStatusType from 'types/enum/TimeEntryBreakStatusType';
import ITimeEntry from 'types/TimeEntry';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import { dateUtils, timeEntryBreakUtils } from 'utils';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getTotalAsHoursMinutesSeconds } from 'utils/stringUtils';
import { formatTime } from 'utils/timeUtils';
import './TimeEntryViewBreak.scss';

interface IProps {
  className?: ClassName;
  entryBreak: Pick<ITimeEntryBreak, 'id' | 'startTime' | 'endTime'>;
  timeEntry: Pick<ITimeEntry, 'id' | 'startTime' | 'endTime' | 'daylightSavingTime' | 'metaDaylightSavingTime'>;
  showDecimalFormat?: boolean;
}

const TimeEntryViewBreak: FunctionComponent<IProps> = (props) => {
  const { className, entryBreak, timeEntry, showDecimalFormat } = props;

  const classes = classNames('time-entry-view-break', className);

  function renderTimeValue(seconds: number): string {
    if (seconds > 0) {
      if (!isNil(showDecimalFormat) && showDecimalFormat) {
        return formatTime({ type: 'DECIMAL', seconds: seconds, places: 2 });
      }
      return getTotalAsHoursMinutesSeconds(seconds);
    }
    return !isNil(showDecimalFormat) && showDecimalFormat
      ? formatTime({ type: 'DECIMAL', seconds: 0, places: 2 })
      : '00:00';
  }

  function renderBreak() {
    if (entryBreak.startTime === undefined) {
      return <div>---</div>;
    }
    const breakStartTime = dateTimeFromISOKeepZone(entryBreak.startTime);
    let breakEndTime;
    let total = 0;
    let formatter = 'h:mm a';
    let startTimeTag = <span>{breakStartTime.toFormat(formatter)}</span>;
    let endTimeTag = <span>{t('(Open)')}</span>;

    if (entryBreak.endTime) {
      breakEndTime = dateTimeFromISOKeepZone(entryBreak.endTime);
      total = timeEntryBreakUtils.getBreakTotal(entryBreak, timeEntry);

      if (dateUtils.isSameDay(breakStartTime, breakEndTime) === false) {
        if (dateUtils.isSameYear(breakStartTime, breakEndTime)) {
          formatter += ' (d, EEE)';
        } else {
          formatter += ' (d, EEE, yyyy)';
        }
      }

      endTimeTag = <span>{breakEndTime.toFormat(formatter)}</span>;

      const status = timeEntryBreakUtils.getBreakStatus(entryBreak, timeEntry);
      if (status === TimeEntryBreakStatusType.END_TIME_OUTSIDE) {
        endTimeTag = <span className="error">{breakEndTime.toFormat(formatter)}</span>;
      } else if (status === TimeEntryBreakStatusType.START_TIME_OUTSIDE) {
        startTimeTag = <span className="error">{breakStartTime.toFormat(formatter)}</span>;
      } else if (status === TimeEntryBreakStatusType.OUTSIDE_ENTRY) {
        startTimeTag = <span className="error">{breakStartTime.toFormat(formatter)}</span>;
        endTimeTag = <span className="error">{breakEndTime.toFormat(formatter)}</span>;
      }
    }

    return (
      <div>
        <div className="display-text tab">{renderTimeValue(total)}</div>
        <div className="display-text">
          {startTimeTag} - {endTimeTag}
        </div>
      </div>
    );
  }

  return <div className={classes}>{renderBreak()}</div>;
};

export default TimeEntryViewBreak;
