import { Button } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ChangeEvent, FunctionComponent, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FilePickerType from 'types/enum/FilePickerType';
import { isType } from 'utils/typeguard';

export interface ISimpleFileUploaderProps {
  onFileSelection: (file: File | undefined) => void;
  children?: ReactNode;
  accept?: FilePickerType | string | FilePickerType[]; // string incase someone wants something custom that doesn't fit in `FilePickerType`
  className?: ClassName;
}

export const FileUploadButton: FunctionComponent<ISimpleFileUploaderProps> = (props) => {
  const { accept, children, onFileSelection, className } = props;

  const [t] = useTranslation();
  const inputFile = useRef<any>(null); // what type is this

  const classes = classNames({}, className);

  function onUploadClick() {
    inputFile.current.click();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;
    onFileSelection(files ? files[0] : undefined);
    event.target.value = ''; // This is required if the same file is attempted to be uploaded
  }

  return (
    <Button type="primary" onClick={onUploadClick} className={classes}>
      {children ? children : t('Upload')}
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onChange}
        accept={isType<any[]>(accept, 'join') ? accept.join(', ') : accept}
      />
    </Button>
  );
};

export default FileUploadButton;
