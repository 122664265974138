import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import useManageablePermissions from 'components/domain/position/position-picker/hooks/useManageablePermissions';
import { sortBy } from 'lodash';
import * as React from 'react';
import { t } from 'utils/localize';
import { OrganizationSignInQuestionAudience } from '__generated__/graphql';

export interface IWhomToAskSelectProps extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  onChange: (value: string, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const WhomToAskSelect = (props: IWhomToAskSelectProps) => {
  const { className, onChange, ...selectProps } = props;

  const { getManageablePermissions } = useManageablePermissions();

  function getOptions(): ISelectOption[] {
    const options: ISelectOption[] = [
      {
        value: OrganizationSignInQuestionAudience.Everyone,
        label: t('Everyone'),
      },
      {
        value: OrganizationSignInQuestionAudience.NoOne,
        label: t('No One'),
      },
    ];
    const positions = getManageablePermissions();
    const mapped = sortBy(
      positions.map((position) => ({ value: position.id, label: position.title })),
      'title'
    );
    return options.concat(mapped);
  }

  const classes = classNames('whom-to-ask-select', className);

  return (
    <Select
      className={classes}
      placeholder={t('Whom To Ask')}
      options={getOptions()}
      onChange={onChange}
      {...selectProps}
    />
  );
};

WhomToAskSelect.defaultProps = {
  ...Select.defaultProps,
};

export default WhomToAskSelect;
