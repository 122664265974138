import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { IEquipmentLandingTableRowInfo } from 'containers/landing-pages/equipment-landing-page/EquipmentLandingTable/context/EquipmentLandingDataContext';
import { IVisibleTableColumn } from 'types/TableColumn';

export interface IStoreEquipmentLandingPage {
  equipmentTableClockedInColumns: IVisibleTableColumn[];
  equipmentTableClockedOutColumns: IVisibleTableColumn[];
  equipmentTableColumns: IVisibleTableColumn[];
  equipmentDashboardGridItems: IDashboardSettingsItem[];
  data: IEquipmentLandingTableRowInfo[];
}

export const initialState: IStoreEquipmentLandingPage = {
  equipmentTableClockedInColumns: [],
  equipmentTableClockedOutColumns: [],
  equipmentTableColumns: [],
  equipmentDashboardGridItems: [],
  data: [],
};

const slice = createSlice({
  name: 'EquipmentLandingPage',
  initialState,
  reducers: {
    updateEquipmentTableClockedInColumns: (
      state: IStoreEquipmentLandingPage,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.equipmentTableClockedInColumns = action.payload;

      return state;
    },
    updateEquipmentTableClockedOutColumns: (
      state: IStoreEquipmentLandingPage,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.equipmentTableClockedOutColumns = action.payload;

      return state;
    },
    updateEquipmentTableColumns: (state: IStoreEquipmentLandingPage, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.equipmentTableColumns = action.payload;

      return state;
    },
    updateEquipmentDashboardGridItems: (
      state: IStoreEquipmentLandingPage,
      action: PayloadAction<IDashboardSettingsItem[]>
    ) => {
      state.equipmentDashboardGridItems = action.payload;

      return state;
    },
    setEquipmentLandingPageRowInfo: (
      state: IStoreEquipmentLandingPage,
      action: PayloadAction<IEquipmentLandingTableRowInfo[]>
    ) => {
      state.data = action.payload;
      return state;
    },
    clearEquipmentLandingPageRowInfo: (state: IStoreEquipmentLandingPage) => {
      state.data = [];
      return state;
    },
  },
});

export const {
  updateEquipmentTableClockedInColumns,
  updateEquipmentTableClockedOutColumns,
  updateEquipmentTableColumns,
  updateEquipmentDashboardGridItems,
  setEquipmentLandingPageRowInfo,
  clearEquipmentLandingPageRowInfo,
} = slice.actions;
export default slice.reducer;
