import { toNumber } from 'lodash';
import { t } from 'utils/localize';

export default function v_positive(value: string, args: { message?: string }) {
  return {
    message: args.message || t('Must be positive'),
    validate: () => {
      if (!value) {
        return true;
      }

      const number = toNumber(value);

      if (number !== 0 && !number) {
        return false;
      }

      return number >= 0;
    },
  };
}
