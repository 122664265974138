import { IFormRender, SelectFormField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import TimeOffType from 'types/enum/TimeOffType';
import { AnyObject } from 'types/util/Object';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';

export interface ITTimeOffTypeSelectFormFieldProps<FormType extends AnyObject> {
  className?: ClassName;
  form: IFormRender<FormType>;
  name: string;
  placeholder: string;
}

const TimeOffTypeSelectFormField = <FormType extends AnyObject>(props: ITTimeOffTypeSelectFormFieldProps<FormType>) => {
  const { className, form, name, placeholder } = props;

  const classes = classNames(
    {
      'time-off-type-select-form-field': true,
    },
    className
  );

  return (
    <SelectFormField
      className={classes}
      name={name}
      form={form}
      placeholder={placeholder}
      validations={[{ validation: v_require }]}
      options={[
        { value: TimeOffType.HOLIDAY, label: t('Holiday') },
        { value: TimeOffType.PERSONAL, label: t('Personal') },
        { value: TimeOffType.SICK, label: t('Sick') },
        { value: TimeOffType.VACATION, label: t('Vacation') },
        { value: TimeOffType.OTHER, label: t('Other') },
      ]}
    />
  );
};

export default TimeOffTypeSelectFormField;
