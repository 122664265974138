import { isNil, last, sortBy } from 'lodash';
import IOrganization from 'types/Organization';
import IOrganizationPayPeriod from 'types/OrganizationPayPeriod';

export function isEmployeeSignatureEnabled(organization: Pick<IOrganization, 'signatureDate'>) {
  return !!organization.signatureDate;
}

export function isSupervisorSignatureEnabled(
  organization: Pick<IOrganization, 'authoritativeSignature' | 'signatureDate'>
) {
  return isEmployeeSignatureEnabled(organization) && !!organization.authoritativeSignature;
}

export function isTimeCardLockEnabled(organization: Pick<IOrganization, 'signatureDate' | 'lockOnSelfSignature'>) {
  return !!organization.signatureDate && !!organization.lockOnSelfSignature;
}

export function isTimeOffRequestEnabled(organization: Pick<IOrganization, 'timeOffRequests'>) {
  return !!organization.timeOffRequests;
}

export function isBreakComplianceEnabled(organization: Pick<IOrganization, 'breakPolicy' | 'breakPolicyCalifornia'>) {
  return !!organization.breakPolicy && !!organization.breakPolicyCalifornia;
}

export function latestPayPeriodDefinition(
  organization: Pick<IOrganization, 'organizationPayPeriod'>
): IOrganizationPayPeriod | undefined {
  return last(
    sortBy(
      organization.organizationPayPeriod?.filter((payPeriod) => isNil(payPeriod.deletedOn)),
      (payPeriod) => payPeriod.startDate
    )
  );
}
