import { Bar, Size } from '@busybusy/webapp-react-ui';
import { stringUtils } from 'utils';

export interface ISimplePickerRowProps {
  value: string;
  searchValue?: string;
}

export default function SimplePickerRow({ value, searchValue }: ISimplePickerRowProps) {
  return (
    <Bar size={Size.SMALL} className="px-3">
      <div className="ellipsis">{searchValue ? stringUtils.highlight(value, searchValue) : value}</div>
    </Bar>
  );
}
