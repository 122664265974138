import { gql } from '@apollo/client';

export const TIME_OFF_QUERY = gql`
  query QueryTimeOff($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      dateStamp
      type
      description
      paid
      seconds
      createdOnLocal
      createdOn
      updatedOn
      deletedOn
      memberId
      member {
        id
        firstName
        lastName
        memberGroupId
        memberNumber
        position {
          id
          level
          timeEvents
          manageTimeEntries
        }
        memberLock {
          id
          effectiveDate
        }
      }
      submitterMember {
        id
        firstName
        lastName
      }
      cursor
    }
  }
`;

export const SINGLE_TIME_OFF_QUERY = gql`
  query SingleTimeOff($timeOffId: Uuid!) {
    timeOffs(filter: { id: { equal: $timeOffId } }) {
      id
      dateStamp
      type
      description
      paid
      seconds
      createdOnLocal
      createdOn
      updatedOn
      deletedOn
      member {
        id
        firstName
        lastName
      }
      submitterMember {
        id
        firstName
        lastName
      }
    }
  }
`;

export const FLAT_TIME_OFF_QUERY = gql`
  query FlatTimeOff($first: Int, $after: String, $filter: TimeOffFilter, $sort: [TimeOffSort!]) {
    timeOffs(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      dateStamp
      type
      description
      paid
      seconds
      createdOnLocal
      createdOn
      updatedOn
      deletedOn
      memberId
      cursor
    }
  }
`;
