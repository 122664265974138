import { ApolloError } from '@apollo/client';
import { ErrorState, LoadingState } from 'components';
import useCompany from 'hooks/models/organization/useCompany';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  authMemberSetAction,
  logoutAction,
  organizationSetAction,
  organizationSubscriptionStatusSetAction,
  sessionCreateAction,
} from 'store/store';
import { IMember } from 'types';
import { decodeAuthToken } from 'utils/authenticationUtils';
import { t } from 'utils/localize';
import { IAuthenticationQueryData, IAuthenticationQueryResult, useAuthenticationQuery } from './authentication-query';

export interface IAuthenticateFromTokenContainerProps {
  token: string;
  memberId: IMember['id'];
}
export interface IAuthenticateFromTokenProps extends Pick<IAuthenticationQueryResult, 'data' | 'loading' | 'error'> {
  token: string;
}

export interface IAuthenticateFromTokenSuccessProps {
  data: IAuthenticationQueryData;
  token: string;
}

export interface IAuthenticateFromTokenFailProps {
  error: ApolloError | undefined;
}

export const AuthenticateFromTokenContainer = ({ memberId, token }: IAuthenticateFromTokenContainerProps) => {
  const queryResults = useAuthenticationQuery({ memberId });

  return <AuthenticateFromToken {...queryResults} token={token} />;
};

export const AuthenticateFromToken = ({ token, data, loading, error }: IAuthenticateFromTokenProps) => {
  if (loading) {
    return <AuthenticateFromTokenProcessing />;
  } else if (!error && data?.members.length) {
    return <AuthenticateFromTokenSuccess token={token} data={data} />;
  } else {
    return <AuthenticateFromTokenFail error={error} />;
  }
};

export const AuthenticateFromTokenProcessing = () => <LoadingState title={t(`Authenticating`)} />;

export const AuthenticateFromTokenFail = ({ error }: IAuthenticateFromTokenFailProps) => {
  const dispatch = useDispatch();

  if (process.env.NODE_ENV === 'development') {
    if (error?.message === 'Network error: Failed to fetch') {
      // tslint:disable:no-console
      console.warn('Could not connect to GraphQL service');
      // tslint:enable:no-console
    }
  }

  useEffect(() => {
    dispatch(logoutAction());
  });

  return <ErrorState title={t('Authentication failed')} subtitle={error?.message} />;
};

export const AuthenticateFromTokenSuccess = ({ data, token }: IAuthenticateFromTokenSuccessProps) => {
  const dispatch = useDispatch();
  const { addCompany } = useCompany();
  const { members } = data;
  const memberData = members[0];

  useEffect(() => {
    const decodedToken = decodeAuthToken(token || '');
    const { organization, ...member } = memberData;

    if (member.id !== decodedToken.memberId) {
      return;
    }

    dispatch(
      sessionCreateAction({
        memberId: member.id,
        organizationId: member.organizationId,
        positionId: member.positionId,
      })
    );

    dispatch(authMemberSetAction(memberData));

    if (organization) {
      const { subscriptionStatus } = organization;
      dispatch(organizationSetAction(organization));
      dispatch(organizationSubscriptionStatusSetAction(subscriptionStatus ?? null));

      addCompany({
        organization,
        token,
      });
    }
    // intentionally not including dispatch and addCompany
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, memberData]);

  return <LoadingState title={t(`Authentication Successful`)} />;
};

export default AuthenticateFromTokenContainer;
