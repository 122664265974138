import { useDefaultTimes, useTimeRounding } from 'hooks';
import { DateTime } from 'luxon';
import ClockAction from 'types/enum/ClockAction';
import ITimeRange from 'types/TimeRange';
import { nightShiftAdjustment, setZone } from 'utils/dateUtils';

export default function useBreakDefaultRange() {
  const { defaultBreakDuration } = useDefaultTimes();
  const { roundTime } = useTimeRounding();

  function getBreakEndTime(startTime: DateTime) {
    const rounded = roundTime(defaultBreakDuration, ClockAction.BREAK_END);

    return DateTime.fromObject({ hour: startTime.hour, minute: startTime.minute }).plus({
      hours: rounded.hour,
      minutes: rounded.minute,
    });
  }

  function breakRange(timeEntryRange?: ITimeRange<DateTime>) {
    if (!timeEntryRange) {
      return {
        startTime: DateTime.fromFormat('12:00', 'H:mm'),
        endTime: getBreakEndTime(DateTime.fromFormat('12:00', 'H:mm')),
      };
    } else {
      const { startTime: timeEntryStart, endTime: timeEntryEnd } = nightShiftAdjustment(timeEntryRange);
      const desiredBreakStart = DateTime.fromFormat('12:00', 'H:mm').set({
        year: timeEntryStart.year,
        month: timeEntryStart.month,
        day: timeEntryStart.day,
      });

      // If the time entry start is after the desired break start time (12:00), set the break start time to the time entry start time
      const adjustedBreakStart =
        desiredBreakStart.toMillis() >= timeEntryStart.toMillis() &&
        desiredBreakStart.toMillis() <= timeEntryEnd.toMillis()
          ? desiredBreakStart
          : timeEntryStart;
      const desiredBreakEnd = getBreakEndTime(adjustedBreakStart).set({
        year: timeEntryStart.year,
        month: timeEntryStart.month,
        day: timeEntryStart.day,
      });

      // If the desired break end is after the time entry end we need to get the next highest rounded time
      const adjustedBreakEnd = desiredBreakEnd.toMillis() < timeEntryEnd.toMillis() ? desiredBreakEnd : timeEntryEnd;
      return {
        startTime: setZone(adjustedBreakStart, timeEntryStart.zoneName, true),
        endTime: setZone(adjustedBreakEnd, timeEntryStart.zoneName, true),
      };
    }
  }

  return breakRange;
}
