import { useMutation } from '@apollo/client';
import {
  OrganizationSignOffQuestion,
  OrganizationSignOffQuestionAudience,
  OrganizationSignOffQuestionCreateInput,
  OrganizationSignOffQuestionType,
} from '__generated__/graphql';
import { ICreateDailySignOffQuestionsFormData } from 'containers/daily-sign-off/CreateDailySignOffQuestionForm/CreateDailySignOffQuestionForm';
import gql from 'graphql-tag';
import { useOrganization } from 'hooks';
import { isNil, trim } from 'lodash';
import { useCallback } from 'react';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { buildChoiceJSONString } from 'utils/organizationSignInQuestionUtils';

export function getAudienceType(type: string): OrganizationSignOffQuestionAudience {
  switch (type) {
    case 'everyone':
      return OrganizationSignOffQuestionAudience.Everyone;
    case 'no_one':
      return OrganizationSignOffQuestionAudience.NoOne;
    default:
      return OrganizationSignOffQuestionAudience.Position;
  }
}

export default function useCreateOrganizationSignOffQuestion() {
  const organization = useOrganization();
  const [createOrganizationSignOffQuestions] = useMutation(CREATE_ORGANIZATION_SIGN_OFF_QUESTION);

  return useCallback(async (formData: ICreateDailySignOffQuestionsFormData, order: number) => {
    const audienceType = getAudienceType(formData.whomToAsk);
    const choiceString = buildChoiceJSONString(formData);

    const questionTitle = !isNil(formData.question) ? trim(formData.question) : '';

    if (questionTitle.length === 0) {
      throw new Error('Question title is required');
    }

    const input: OrganizationSignOffQuestionCreateInput = {
      questionTitle: questionTitle,
      questionDescription: formData.explanation,
      audienceType,
      organizationId: organization.id!,
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
      type: OrganizationSignOffQuestionType.TrueFalse,
      choices: choiceString,
      order,
    };
    if (audienceType === OrganizationSignOffQuestionAudience.Position) {
      input.positionId = formData.whomToAsk;
    }
    return new Promise<OrganizationSignOffQuestion>((resolve, reject) => {
      return createOrganizationSignOffQuestions({ variables: { organizationSignOffQuestion: input } }).then(
        (results) => {
          if (results.data) {
            resolve(results.data.createOrganizationSignOffQuestions);
          } else {
            reject();
          }
        }
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const CREATE_ORGANIZATION_SIGN_OFF_QUESTION = gql`
  mutation CreateOrganizationSignOffQuestion($organizationSignOffQuestion: OrganizationSignOffQuestionCreateInput!) {
    createOrganizationSignOffQuestion(organizationSignOffQuestion: $organizationSignOffQuestion) {
      id
      questionTitle
      questionDescription
      audienceType
      organizationId
      createdOn
      type
      choices
      order
      positionId
    }
  }
`;
