import { IStoreEnvironment, IStoreSession } from 'store/types';

export function logoutUser(isAuthenticated: IStoreSession['isAuthenticated'], dispatchLogout: () => void) {
  if (isAuthenticated) {
    dispatchLogout();
  }
}

export function exitToLegacyApp(legacyUrl: IStoreEnvironment['REACT_APP_LEGACY_URL']) {
  // if the opening window still exists, and this window isn't somehow also the opening window
  // this window can become the same as the opening window if it's previously redirected to the legacy app
  if (window.opener && window.opener !== window) {
    return window.close();
  } else {
    // if the opener window has been closed, redirect this window back to the legacy webapp
    return window.location.assign(legacyUrl);
  }
}

export default {
  logoutUser,
  exitToLegacyApp,
};
