export const SchedulingCalendarActionTypes = {
  event: {
    DRAG_RANGE: 'drag_range',
    SELECT_DAY: 'select_day',
    SCHEDULE: 'schedule',
    DRAG_DROP: 'drag_drop',
    RESIZE: 'resize',
  },
  rangeSelectionAction: {
    COPY_SCHEDULE: 'copy',
    PASTE_SCHEDULE: 'paste',
    SCHEDULE_SCHEDULE: 'schedule',
    DELETE_SCHEDULE: 'delete',
    PUBLISH_SCHEDULE: 'publish',
    DELETE_ALL: 'delete_all',
    DELETE_TIME_OFF: 'delete_time_off',
    SCHEDULE_TIME_OFF: 'schedule_time_off',
    COPY_TIME_OFF: 'copy_time_off',
    PASTE_TIME_OFF: 'paste_time_off',
  },
  popoverAction: {
    OPEN_SCHEDULE: 'open',
    EDIT_SCHEDULE: 'edit',
    COPY_SCHEDULE: 'copy',
    PUBLISH_SCHEDULE: 'publish',
    DELETE_SCHEDULE: 'delete',
    CLOSE: 'close',
    OPEN_TIME_OFF: 'open_time_off',
    EDIT_TIME_OFF: 'edit_time_off',
    DELETE_TIME_OFF: 'delete_time_off',
    COPY_TIME_OFF: 'copy_time_off',
  },
  editFormAction: {
    SAVE: 'save',
    DELETE: 'delete',
    PUBLISH: 'publish',
    EDIT_AND_PUBLISH: 'edit_and_publish',
    SAVE_DRAFT: 'save_draft',
  },
};

export const TimesheetsTypes = {
  reportViewed: {
    view: {
      PAY_PERIOD: 'pay_period',
      MONTHLY: 'monthly',
      WEEKLY: 'weekly',
      DAILY: 'daily',
      CUSTOM: 'custom',
    },
    viewType: {
      SUMMARY: 'summary',
      TIME_CARDS: 'time_cards',
      TIME_ENTRIES: 'time_entries',
    },
  },
  filterSet: {
    filterType: {
      ENTRY: 'entry',
      TIME_OFF_BOTH: 'time_off_both',
      TIME_OFF_PAID: 'time_off_paid',
      TIME_OFF_UNPAID: 'time_off_unpaid',
    },
  },
  tableColumnsSet: {
    type: {
      TOTAL_HOURS: 'total_hours',
      REGULAR_HOURS: 'regular_hours',
      OVERTIME_HOURS: 'overtime_hours',
      DOUBLE_TIME_HOURS: 'double_time_hours',
      PAID_TIME_OFF: 'paid_time_off',
      TIME_ACCURATE: 'time_accurate',
      BREAK_COMPLIANCE: 'break_compliance',
      INJURED: 'injured',
      EMPLOYEE_SIGNED: 'employee_signed',
      SUPERVISOR_SIGNED: 'supervisor_signed',
      TYPE: 'type',
      TOTAL: 'total',
      START: 'start',
      END: 'end',
      BREAKS: 'breaks',
      PROJECT: 'project',
      COST_CODE: 'cost_code',
      EQUIPMENT: 'equipment',
      DESCRIPTION: 'description',
    },
  },
  events: {
    action_type: {
      RANGE_CHANGE: 'range_change',
      ADD_ENTRY: 'add_entry',
      ADD_ENTRIES: 'add_entries',
      ADD_TIME_OFF: 'add_time_off',
      ADD_TIME_OFFS: 'add_time_offs',
      EXPORT: 'export',
      PRINT: 'print',
      DELETE_ENTRY: 'delete_entry',
      DELETE_TIME_OFF: 'delete_time_off',
      BULK_EDIT: 'bulk_edit',
      EMPLOYEE_SIGN: 'employee_sign',
      SUPERVISOR_SIGN: 'supervisor_sign',
      VIEW_DAILY_SIGN_OFF: 'view_daily_sign_off',
      VIEW_ENTRIES: 'view_entries',
      VIEW_ENTRY: 'view_entry',
      VIEW_GPS: 'view_gps',
      VIEW_ENTRY_LOGS: 'view_entry_logs',
      VIEW_TIME_CARDS: 'view_time_cards',
    },
  },
  graphTypeSet: {
    type: {
      TOTAL_HOURS: 'total_hours',
      REGULAR_HOURS: 'regular_hours',
      OVERTIME_HOURS: 'overtime_hours',
      DOUBLE_TIME_HOURS: 'double_time_hours',
    },
  },
  locationHistoryFilterTypeSet: {
    filter_type: {
      ALL_EVENTS: 'all_events',
      START: 'start',
      END: 'end',
      BREAK_START: 'break_start',
      BREAK_END: 'break_end',
      LOCATION_UPDATE: 'location_update',
    },
  },
};
