import { useCallback } from 'react';
import { IGustoCompany } from '../../../types/types';
import useGustoAxios from '../../useGustoAxios/useGustoAxios';

export default function useGustoGetCompany() {
  const axios = useGustoAxios();
  return useCallback(
    async (id: string) => {
      const result = await axios.get<IGustoCompany>(`/v1/companies/${id}`);
      return result.data;
    },
    [axios]
  );
}
