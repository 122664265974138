import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DashboardList, { IDashboardListRow } from '../DashboardList/DashboardList';
import DashboardListItem from '../DashboardListItem/DashboardListItem';
import './DashboardActiveList.scss';

export interface IDashboardActiveListProps {
  onRowClick?: (id: string) => void;
  rowItems: IDashboardListRow[];
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
}

function DashboardActiveList({ onRowClick, rowItems, theme, className }: IDashboardActiveListProps) {
  const classes = classNames('dashboard-active-list', className);

  function onItemClick(id: string) {
    return () => onRowClick?.(id);
  }

  return (
    <DashboardList
      className={classes}
      hover={!!onRowClick}
      rowItems={rowItems}
      theme={theme}
      renderItem={(item) => (
        <DashboardListItem
          onClick={onRowClick && item.value !== '' ? onItemClick(item.id) : undefined}
          key={item.id}
          label={item.label}
          theme={theme}
          value={item.value}
        />
      )}
    ></DashboardList>
  );
}

export default DashboardActiveList;
