import { Container } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { t } from 'utils/localize';
import './TimeEntryConflictDialog.scss';

export interface ITimeEntryConflictDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
}

const TimeEntryConflictDialog = (props: ITimeEntryConflictDialogProps) => {
  const { className, isOpen, onClose } = props;

  const classes = classNames(
    {
      'time-entry-conflict-container': true,
    },
    className
  );

  return (
    <HeaderDialog isOpen={isOpen} title={t('Time Entry Conflict')} onClose={onClose} divider={false}>
      <Container className={classes}>
        <div>
          {
            // tslint:disable-next-line: max-line-length
            t(
              'The time span for this entry overlaps with one or more other time entries for this employee. To correct this, delete the incorrect time entry or edit the times of the entries so that they do not overlap.'
            )
          }
        </div>
      </Container>
    </HeaderDialog>
  );
};

export default TimeEntryConflictDialog;
