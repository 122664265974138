import { Button } from '@busybusy/webapp-react-ui';
import { ErrorState, Panel } from 'components';
import * as React from 'react';
import { Component, ErrorInfo, ReactNode } from 'react';
import { TEnvironmentKeys } from 'store/store';
import { IChildrenProps } from 'types/ChildrenProps';
import { t } from 'utils/localize';

interface IErrorBoundaryProps {
  children: ReactNode;
  nodeEnv: TEnvironmentKeys;
  footerText: string;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

const ErrorHeader: React.FC<{ errorMessage?: string }> = ({ errorMessage }) => (
  <>
    <div>{t(`Try reloading the page or contact our customer support team so we can help.`)}</div>
    <pre>{errorMessage}</pre>
  </>
);

const ErrorFooter: React.FC<{ message?: string; onClick: () => void } & IChildrenProps> = ({ children, onClick }) => (
  <Button className="mt-3" type="secondary" onClick={onClick}>
    {children}
  </Button>
);

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static defaultProps = {
    nodeEnv: process.env.NODE_ENV,
    footerText: t('Reload Page'),
  };

  public state: IErrorBoundaryState = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error, errorInfo };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.nodeEnv === ('development' as TEnvironmentKeys)) {
      // eslint-disable-next-line no-console
      console.error('Uncaught error:', { error, errorInfo });
    }
  }

  public render() {
    if (this.state.hasError) {
      const handleClick = () => (window.location.search = '');

      return (
        <Panel>
          <ErrorState
            subtitle={<ErrorHeader errorMessage={this.state.error?.message} />}
            footer={<ErrorFooter onClick={handleClick}>{this.props.footerText}</ErrorFooter>}
          />
        </Panel>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
