import { ISelectOption, ISelectProps, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import * as React from 'react';
import { t } from 'utils/localize';

export enum SignInQuestionResponseType {
  ALLOW_CLOCK_IN = 'allow-clock-in',
  FLAG_ALLOW_CLOCK_IN = 'flag-allow-clock-in',
  FLAG_NO_CLOCK_IN = 'flag-no-clock-in',
}

export interface ISignInQuestionResponseSelectProps extends Omit<ISelectProps, 'options' | 'placeholder' | 'onChange'> {
  onChange: (value: string, event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SignInQuestionResponseSelect = (props: ISignInQuestionResponseSelectProps) => {
  const { className, onChange, ...selectProps } = props;

  function getOptions(): ISelectOption[] {
    return [
      {
        value: SignInQuestionResponseType.ALLOW_CLOCK_IN,
        label: t('Allow Clock In'),
      },
      {
        value: SignInQuestionResponseType.FLAG_ALLOW_CLOCK_IN,
        label: t('Flag and Allow Clock In'),
      },
      {
        value: SignInQuestionResponseType.FLAG_NO_CLOCK_IN,
        label: t('Flag and Restrict Clock In'),
      },
    ];
  }

  const classes = classNames('sign-in-question-response-select', className);

  return (
    <Select
      className={classes}
      placeholder={t('Whom To Ask')}
      options={getOptions()}
      onChange={onChange}
      {...selectProps}
    />
  );
};

SignInQuestionResponseSelect.defaultProps = {
  ...Select.defaultProps,
};

export default SignInQuestionResponseSelect;
