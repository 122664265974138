import {
  Button,
  CheckboxFormField,
  DatePickerFormField,
  Form,
  IFormRender,
  Icon,
  Label,
  Theme,
} from '@busybusy/webapp-react-ui';
import { ArrowDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import CostCodePickerFormField from 'components/domain/cost-code/CostCodePickerFormField/CostCodePickerFormField';
import EquipmentPickerFormField from 'components/domain/equipment/EquipmentPickerFormField/EquipmentPickerFormField';
import ProjectPickerFormField from 'components/domain/project/ProjectPickerFormField/ProjectPickerFormField';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import BasicPickerFormField from 'components/foundation/form-fields/BasicPickerFormField/BasicPickerFormField';
import { smartFilterDateRestrictionsData } from 'containers/bulletin/constants/constants';
import useOpenable from 'hooks/utils/useOpenable';
import { t } from 'i18next';
import { FunctionComponent } from 'react';
import { v_require } from 'utils/validations';
import { IEmployeeSmartFilterData } from '../EmployeeMultiPicker/EmployeeMultiPicker';

export interface IEmployeeSmartFilterFormProps {
  className?: ClassName;
  getFormData: () => IEmployeeSmartFilterData;
  onFormSubmit: (data: IEmployeeSmartFilterData) => void;
  buttonTitle: string;
  onClearFilters: () => void;
}

export const EmployeeSmartFilterForm: FunctionComponent<IEmployeeSmartFilterFormProps> = (props) => {
  const { className, getFormData, onFormSubmit, buttonTitle, onClearFilters } = props;
  const classes = classNames(className);

  const dateDetails = useOpenable();

  function renderForm(form: IFormRender<IEmployeeSmartFilterData>) {
    return (
      <>
        <CheckboxFormField
          name="showClockedIn"
          form={form}
          label={
            <>
              <Label>{t('Clocked In')}</Label>
              <h4>
                {t(
                  'Only show employees that are currently clocked in. Optionally filter by project, cost code, and/or equipment.'
                )}
              </h4>
            </>
          }
          disabled={form.state.data.showClockedOut || form.state.data.showScheduled}
        />
        {form.state.data.showClockedIn && (
          <div className="pb-5">
            <DashboardRibbon theme={Theme.LIGHT}>
              <ProjectPickerFormField form={form} name="clockedInProjectId" placeholder={t('Project')} />
              <CostCodePickerFormField
                form={form}
                name="clockedInCostCodeId"
                placeholder={t('Cost Code')}
                considerProjectSpecificCostCodes={false}
              />
              <EquipmentPickerFormField form={form} name="clockedInEquipmentId" placeholder={t('Equipment')} />
            </DashboardRibbon>
          </div>
        )}
        <CheckboxFormField
          name="showClockedOut"
          form={form}
          label={
            <>
              <Label>{t('Clocked Out')}</Label>
              <h4>{t('Only show employees that are currently clocked out.')}</h4>
            </>
          }
          disabled={form.state.data.showClockedIn || form.state.data.showScheduled}
        />
        <CheckboxFormField
          name="showScheduled"
          form={form}
          label={
            <>
              <Label>{t('Scheduled')}</Label>
              <h4>
                {t(
                  'Only show employees that are scheduled to work on a specific project, cost code, and/or equipment.'
                )}
              </h4>
            </>
          }
          onChange={(value) => {
            if (value) {
              form.setData('scheduledDateRestrictions', 'anytime');
            }
          }}
          disabled={form.state.data.showClockedIn || form.state.data.showClockedOut}
        />
        {form.state.data.showScheduled && (
          <div className="pb-5">
            <DashboardRibbon theme={Theme.LIGHT}>
              <ProjectPickerFormField form={form} name="scheduledProjectId" placeholder={t('Project')} />
              <CostCodePickerFormField
                form={form}
                name="scheduledCostCodeId"
                placeholder={t('Cost Code')}
                considerProjectSpecificCostCodes={false}
              />
              <EquipmentPickerFormField form={form} name="scheduledEquipmentId" placeholder={t('Equipment')} />
              <BasicPickerFormField
                name="scheduledDateRestrictions"
                form={form}
                data={smartFilterDateRestrictionsData}
                closeButtonRender={() => (
                  <span className="drop-icon">
                    <Icon svg={ArrowDownIcon} />
                  </span>
                )}
                validations={[{ validation: v_require }]}
              />
              {form.state.data.scheduledDateRestrictions === 'date' && (
                <DatePickerFormField
                  name="scheduledDate"
                  form={form}
                  validations={[{ validation: v_require }]}
                  isOpen={dateDetails.isOpen}
                  onClose={dateDetails.close}
                  onOpen={dateDetails.open}
                  placeholder={'Select Date'}
                />
              )}
            </DashboardRibbon>
          </div>
        )}
        <Button type="primary" onClick={form.handleSubmit} className="m-2">
          {buttonTitle}
        </Button>
        <Button type="secondary" onClick={onClearFilters} className="m-2">
          {t('Clear Filters')}
        </Button>
      </>
    );
  }

  return (
    <div className={classes}>
      <Form data={getFormData()} onSubmit={onFormSubmit} render={renderForm} />
    </div>
  );
};
