import { Divider, Justify, List, ListItem, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Component, ReactNode } from 'react';
import * as React from 'react';
import { LazyLoader } from '../../../..';
import { t } from 'i18next';

interface IProps<T extends { id: string; cursor?: string }> {
  data: T[];
  value: string | null; // UUID
  loadedAll: boolean; // The lazy loader will continue to attempt network calls until loadedAll is set to false
  getData: (after?: string) => Promise<T[]>;
  didLoad: (items: T[], error: boolean, loadedAll: boolean) => void; // Needed to update the data and loadedAll props
  renderRow: (row: T, index: number, e?: React.KeyboardEvent) => ReactNode;
  closeMenu: (shouldFocus: boolean) => void;
  onSelect: (row: T | null, index?: number, e?: React.MouseEvent) => void;
  renderSize: number; // has default
  offset: number; // has default
  loadingTemplate?: ReactNode;
  className?: ClassName;
  error?: boolean;
  onRightKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  onLeftKeyDown?: (row: T, e: React.KeyboardEvent) => void;
  willLoad?: () => void;
  header?: (sheet: LazyLoadPickerList<T>) => JSX.Element | JSX.Element[] | HTMLElement;
  defaultFocusIndex?: number;
}

interface IState {
  focusIndex: number;
}

export class LazyLoadPickerList<T extends { id: string; cursor?: string }> extends Component<IProps<T>> {
  public static defaultProps: any;
  public isDirty: boolean = false; // set to true whenever the user uses the keyboard to navigate up or down
  public listContainer?: HTMLElement; // ref
  public scroller?: HTMLElement; // ref
  public state: IState = {
    focusIndex: this.props.defaultFocusIndex ?? -1,
  };

  public componentDidMount() {
    if (this.listContainer) {
      this.listContainer.focus(); // Focus the listContainer to allow for keyboard events
    }
  }

  public componentDidUpdate(prevProps: IProps<T>) {
    if (prevProps.value !== this.props.value) {
      this.isDirty = false;
    }
  }

  public setFocusToValue() {
    const { value } = this.props;
    if (value) {
      const index = this.getIndexOfRowById(value);

      if (index > -1) {
        this.setState({
          focusIndex: index,
        });
      }
    }
  }

  public getIndexOfRowById(id?: string | null) {
    return this.props.data.findIndex((item) => id === item.id);
  }

  // Set the focus to the row at a specific index
  public setFocusIndex(index: number) {
    this.setState(
      {
        focusIndex: index,
      },
      () => {
        if (this.scroller) {
          this.scrollIntoView(this.scroller);
        }
      }
    );
  }

  // Scroll the focused row into view
  public scrollIntoView = (scroller: HTMLElement) => {
    const focused = this.getFocusedRowRef();

    if (focused) {
      const focusedRect = focused.getBoundingClientRect();
      const scrollerRect = scroller.getBoundingClientRect();

      if (focusedRect.bottom > scrollerRect.bottom) {
        focused.scrollIntoView(false);
      } else if (focusedRect.top < scrollerRect.top) {
        focused.scrollIntoView();
      }
    }
  };

  // Return a reference to the focused dom element
  public getFocusedRowRef() {
    if (this.scroller) {
      return this.scroller.querySelector('.focused') || null;
    }
    return null;
  }

  public handleRowClick = (row: any, index: number, e?: React.MouseEvent) => {
    this.props.onSelect(row, index, e);
    this.props.closeMenu(true);
  };

  // Handle keyboard events
  public handleKeyDown = (e: React.KeyboardEvent) => {
    const { data, defaultFocusIndex, onRightKeyDown, onLeftKeyDown, onSelect, closeMenu } = this.props;
    const { focusIndex } = this.state;
    const keyCode = e.keyCode;

    if ([37, 38, 39, 40, 27, 13].includes(keyCode)) {
      const focused = this.getFocusedRowRef();
      e.stopPropagation();
      e.preventDefault();

      switch (keyCode) {
        case 38: // up - Move focus up one unless already at the top or the focused row isn't rendered.
          this.isDirty = true;

          if (focusIndex > 0 && focused) {
            this.setFocusIndex(focusIndex - 1);
          } else if (focusIndex !== 0) {
            this.setFocusIndex(0); // If the row is unrendered, set the focus to the first row.
          }
          break;
        case 40: // down
          this.isDirty = true;

          if (focusIndex < data.length - 1) {
            // Don't allow wrapping to the top.
            if (focused) {
              this.setFocusIndex(focusIndex + 1);
              return;
            }
          }

          if (!focused) {
            // If the row that should be focused is not rendered, set focus to the first row.
            this.setFocusIndex(defaultFocusIndex ?? 0);
          }
          break;
        case 39: // right
          if (onRightKeyDown) {
            this.isDirty = false;
            onRightKeyDown(data[focusIndex], e);
          }
          break;
        case 37: // left
          if (onLeftKeyDown) {
            onLeftKeyDown(data[focusIndex], e);
          }
          break;
        case 27: // esc
          closeMenu(true);
          break;
        case 13: // return
          if (focusIndex > -1 && data[focusIndex]) {
            onSelect(data[focusIndex]);
            closeMenu(true);
          }
          break;
        default:
          return;
      }
    }
  };

  // Render the picker row
  public renderRow = (row: T, index: number) => {
    const classes = classNames({
      'focused': index === this.state.focusIndex,
      'picker-row': true,
    });

    const handleRowClick = (e: React.MouseEvent) => {
      this.handleRowClick(row, index, e);
    };

    return (
      <ListItem key={row.id} className={classes} onClick={handleRowClick}>
        {this.props.renderRow(row, index)}
      </ListItem>
    );
  };

  // Called each time rows are rendered or re-rendered
  public handleDidRender = () => {
    if (!this.isDirty && this.props.value) {
      this.setFocusToValue();
    }

    if (!this.isDirty && !this.props.value) {
      this.setState({ focusIndex: this.props.defaultFocusIndex ?? 0 });
    }
  };

  public renderTemplate = (rows: ReactNode) => <List>{rows}</List>;

  public setListContainerRef = (el: HTMLDivElement) => (this.listContainer = el);
  public setScrollerRef = (el: HTMLElement) => (this.scroller = el);

  public render() {
    const {
      error,
      data,
      getData,
      didLoad,
      loadedAll,
      header,
      renderSize,
      willLoad,
      loadingTemplate,
      offset,
      className,
    } = this.props;

    const classes = classNames('picker-sheet', className);

    return (
      <div className={classes} tabIndex={0} ref={this.setListContainerRef} onKeyDown={this.handleKeyDown}>
        {header && (
          <>
            {header(this)}
            <Divider />
          </>
        )}
        {error && (
          <Row justify={Justify.CENTER} className="p-4 fc-3">
            {t('Network error')}
          </Row>
        )}
        {loadedAll && !error && data.length === 0 && (
          <Row justify={Justify.CENTER} className="p-4 fc-3">
            {t('No results')}
          </Row>
        )}
        {!error && (
          <LazyLoader
            data={data}
            loadedAll={loadedAll}
            getData={getData}
            didLoad={didLoad}
            renderRow={this.renderRow}
            offset={offset}
            renderSize={renderSize}
            didRender={this.handleDidRender}
            willLoad={willLoad}
            loadingTemplate={loadingTemplate}
            className={data.length > 0 ? 'py-3' : ''}
            forwardRef={this.setScrollerRef}
            template={this.renderTemplate}
          />
        )}
      </div>
    );
  }
}

LazyLoadPickerList.defaultProps = {
  offset: 150,
  renderSize: 10,
};

export default LazyLoadPickerList;
