import { first, isNil, orderBy, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import IEquipment from 'types/Equipment';
import ITimeRange from 'types/TimeRange';
import { Optional } from 'types/util/Optional';
import { EquipmentCostHistory } from '__generated__/graphql';
import { dateTimeFromUtcISO } from './dateUtils';
import { logDateTimeTimeRangeToIso } from './debugUtils';

export interface IEquipmentDisplayData {
  equipmentName: string;
  model?: Optional<{
    unknown?: Optional<boolean>;
    title?: Optional<string>;
    year?: Optional<number>;
    make?: Optional<{
      unknown?: Optional<boolean>;
      title?: Optional<string>;
    }>;
  }>;
}

export function makeModelString(equipment: IEquipmentDisplayData, emptyText: string = '---'): string {
  if (equipment.model && equipment.model.make && !equipment.model.unknown && !equipment.model.make?.unknown) {
    return equipment.model.make.title + ' ' + equipment.model.title;
  }
  return emptyText;
}

export function makeModelYearString(equipment: IEquipmentDisplayData): string {
  if (equipment.model && equipment.model.make && !equipment.model.unknown && !equipment.model.make?.unknown) {
    if (equipment.model?.year) {
      return equipment.model.make.title + ' ' + equipment.model.title + ' ' + equipment.model.year.toString();
    } else {
      return makeModelString(equipment);
    }
  }
  return '---';
}

// image
export function equipmentImageUrl(equipment: IEquipment): string | undefined {
  if (equipment.imageUrl) {
    return equipment.imageUrl;
  } else if (equipment.model && equipment.model.imageUrl) {
    return equipment.model.imageUrl;
  } else if (equipment.model && equipment.model.category && equipment.model.category.imageUrl) {
    return equipment.model.category.imageUrl;
  }

  return undefined;
}

export function getCostHistoryForRange(
  costHistories: EquipmentCostHistory[],
  timeRange: ITimeRange<DateTime>
): EquipmentCostHistory | undefined {
  const sortedCostHistories = orderBy(
    costHistories.filter((costHistory) => isNil(costHistory.deletedOn)),
    'changeDate',
    'desc'
  );

  return first(
    sortedCostHistories.filter((costHistory) => dateTimeFromUtcISO(costHistory.changeDate) <= timeRange.endTime)
  );
}

export function getFutureEquipmentCostHistory<T extends Pick<EquipmentCostHistory, 'deletedOn' | 'changeDate'>>(
  costHistories: T[]
): T | undefined {
  return first(
    costHistories.filter((costHistory) => {
      return (
        costHistory?.deletedOn === null &&
        costHistory?.changeDate &&
        DateTime.fromISO(costHistory.changeDate).startOf('day') > DateTime.local().startOf('day')
      );
    })
  );
}

export function getCurrentEquipmentCostHistory<
  T extends Pick<EquipmentCostHistory, 'deletedOn' | 'changeDate' | 'updatedOn'>,
>(costHistories: T[]): T | undefined {
  return first(
    sortBy(
      costHistories.filter((costHistory) => {
        return (
          costHistory?.deletedOn === null &&
          costHistory?.changeDate &&
          DateTime.fromISO(costHistory.changeDate).startOf('day') <= DateTime.local().startOf('day')
        );
      }),
      'changeDate'
    ).reverse()
  );
}
