import { AxiosInstance, AxiosRequestConfig } from 'axios';
import IBusyRpcWrapper from 'types/BusyRpcWrapper';
import RpcMethod from './rpcMethod';
import { getRpcParams } from './rpcUtils';

export interface IGetPricingResponse {
  monthly: number;
  annually: number;
  discount: number;
}

export default function createSubscriptionRpcService(client: AxiosInstance) {
  return {
    startTrial: (config?: AxiosRequestConfig) =>
      client.post('/subscription-rpc', getRpcParams(RpcMethod.UPGRADE_TO_PRO, { trial: true }), config),
    upgradeToPro: (config?: AxiosRequestConfig) =>
      client.post('/subscription-rpc', getRpcParams(RpcMethod.UPGRADE_TO_PRO, { trial: false }), config),
    chargeAnnualSubscription: (numberOfUsers: number, config?: AxiosRequestConfig) =>
      client.post(
        '/subscription-rpc',
        getRpcParams(RpcMethod.CHARGE_ANNUAL_SUBSCRIPTION, { number_of_users: numberOfUsers }),
        config
      ),
    cancelAnnualSubscription: (config?: AxiosRequestConfig) =>
      client.post('/subscription-rpc', getRpcParams(RpcMethod.CANCEL_ANNUAL_SUBSCRIPTION, {}), config),
    invoiceAnnualSubscription: (numberOfUsers: number, config?: AxiosRequestConfig) =>
      client.post(
        '/subscription-rpc',
        getRpcParams(RpcMethod.INVOICE_ANNUAL_SUBSCRIPTION, { number_of_users: numberOfUsers }),
        config
      ),
    getPricing: (config?: AxiosRequestConfig) =>
      client.post<IBusyRpcWrapper<IGetPricingResponse>>(
        '/subscription-rpc',
        getRpcParams(RpcMethod.GET_PRICING, {}),
        config
      ),
    downgrade: (config?: AxiosRequestConfig) =>
      client.post('/subscription-rpc', getRpcParams(RpcMethod.DOWNGRADE, {}), config),
    setCreditCardWithToken: (token: string, config?: AxiosRequestConfig) =>
      client.post('/subscription-rpc', getRpcParams(RpcMethod.SET_CREDIT_CARD_WITH_TOKEN, { token_id: token }), config),
  };
}
