import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { decryptUnassignedProjectId } from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import useActivityReportDetailColumn, {
  activityReportDetailId,
  isActivityRowRestricted,
  timeRangeFromActivityDateItem,
} from 'containers/activity-reports/hooks/useActivityReportDetailColumn';
import useActivityReportExport from 'containers/activity-reports/hooks/useActivityReportExport';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelListener } from 'contexts/ChannelContext/hooks/useChannelListener';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable } from 'hooks';
import { isEmpty, isNil, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import { useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { downloadText } from 'utils/fileUtils';
import { t } from 'utils/localize';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import ProjectActivityExportOptionsDialog, {
  ProjectActivityExportOption,
} from '../ProjectActivityExportOptionsDialog/ProjectActivityExportOptionsDialog';
import { ProjectActivityTable } from '../ProjectActivityTable/ProjectActivityTable';
import { IProjectActivityTableRowInfo } from '../hooks/useProjectActivity';
import useProjectActivityDetails from '../hooks/useProjectActivityDetails';
import useBrandTitle from 'hooks/meta/useBrandTitle';

export interface IProjectActivityReportDetailsProps {
  className?: ClassName;
  filterId: string;
  filterType: ActivityReportType;
  secondaryReportType: ActivityReportType;
  dateRangeReportType?: TimeRangeType;
  timeRange: ITimeRange<DateTime>;
  onRowSelected: (id: string, secondaryId?: string, isUnassignedProjectId?: boolean) => void;
  onDateRowSelected: (id: string, startDate: DateTime, endDate: DateTime, isUnassignedProjectId?: boolean) => void;
  scroller: HTMLElement | null;
}

const ProjectActivityReportDetailsTable = (props: IProjectActivityReportDetailsProps) => {
  const {
    className,
    filterId,
    filterType,
    secondaryReportType,
    dateRangeReportType,
    timeRange,
    onRowSelected,
    onDateRowSelected,
    scroller,
  } = props;
  const classes = classNames('project-activity-report-details', className);

  const brand = useBrandTitle();
  const openToast = useToastOpen();
  const exportDialog = useOpenable();
  const loadingData = useProjectActivityDetails(
    filterId,
    filterType,
    secondaryReportType,
    timeRange,
    dateRangeReportType
  );
  const { exportDetailColumn, getExportData } = useActivityReportDetailColumn(secondaryReportType, dateRangeReportType);
  const getActivityExportData = useActivityReportExport();

  useChannelListener(ChannelIds.Export, exportDialog.open);

  async function exportReport(option: ProjectActivityExportOption) {
    const data = loadingData.remainingData
      ? loadingData.sortedData.concat(loadingData.remainingData)
      : loadingData.sortedData;

    let projectData = [];
    if (option === ProjectActivityExportOption.RootProjects) {
      projectData = getExportData(data);
    } else {
      const expandedData = sortBy(await loadingData.getExportDataWithSubprojects(), (item) => {
        // make sure the No Project item is placed at the end
        if (isNil(item.project)) {
          return Infinity;
        }

        return getFormattedPathFromProject(item.project, true);
      });

      projectData = getExportData(expandedData);
    }

    const exportData = getActivityExportData(projectData, (row) => {
      const project = row.project;
      let title = '';

      if (isNil(project) || project.depth === 1) {
        title = project?.title ?? t('No Project');
      } else {
        title = getFormattedPathFromProject(project, true);
      }

      return {
        [t('Project')]: title,
        ...exportDetailColumn(row),
      };
    });

    if (exportData && !isEmpty(exportData)) {
      const csv = Papa.unparse(exportData);
      downloadText(csv, `${brand}-project-activity-report-${dateUtils.isoTimeStampLocal()}.csv`);
    } else {
      openToast({ label: t('There is no data to export.') });
    }
  }

  const [lazyLoadingEnabled, setLazyLoadingEnabled] = useState(true);
  useChannelListener(ChannelIds.LazyLoadUpdate, () => setLazyLoadingEnabled(false));

  function rowClickAction(rowItem: IProjectActivityTableRowInfo) {
    if (isActivityRowRestricted(secondaryReportType, rowItem)) {
      openToast({ label: t('Access restricted'), timeout: 3000, theme: Theme.DANGER });
      return;
    }

    const noProjectId = {
      id: remainingDataItemId,
      unassigned: false,
    };
    const projectIdResult = isNil(rowItem.project) ? noProjectId : decryptUnassignedProjectId(rowItem.project.id);

    if (secondaryReportType === ActivityReportType.BY_DATE_RANGE || secondaryReportType === ActivityReportType.BY_DAY) {
      const selectedTimeRange = timeRangeFromActivityDateItem(rowItem);

      if (!isNil(selectedTimeRange)) {
        onDateRowSelected(
          projectIdResult.id,
          selectedTimeRange.startTime,
          selectedTimeRange.endTime,
          projectIdResult.unassigned
        );
        return;
      }
    }

    const secondaryId = activityReportDetailId(secondaryReportType, rowItem);

    onRowSelected(projectIdResult.id, secondaryId, projectIdResult.unassigned);
  }

  return (
    <>
      <ProjectActivityTable
        className={classes}
        loadingData={loadingData}
        secondaryReportType={secondaryReportType}
        dateRangeReportType={dateRangeReportType}
        scroller={scroller}
        onRowSelected={rowClickAction}
        showTotalFooter={true}
        lazyLoadingEnabled={lazyLoadingEnabled}
      />
      <ProjectActivityExportOptionsDialog
        isOpen={exportDialog.isOpen}
        onClose={exportDialog.close}
        onExport={exportReport}
      />
    </>
  );
};

export default ProjectActivityReportDetailsTable;
