import Graylog from 'components/domain/analytics/Graylog/Graylog';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import _ from 'lodash';
import { TimesheetView } from 'store/timesheets/Timesheets';
import MemberLocationType from 'types/enum/MemberLocationType';
import TimeRangeType from 'types/TimeRangeType';
import AnalyticsEvent from 'utils/constants/analytics/analyticsEvent';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { useFeatureFlags } from 'utils/features';
import useActiveMember from '../store/useActiveMember';

export default function useTimesheetsGraylog() {
  const activeMember = useActiveMember();
  const isPro = useFeatureFlags('PRO');

  function reportViewed(timeRangeType?: TimeRangeType | null, timesheetView?: TimesheetView | null) {
    let view = TimesheetsTypes.reportViewed.view.PAY_PERIOD;
    let viewType = TimesheetsTypes.reportViewed.viewType.SUMMARY;

    if (!_.isNil(timeRangeType)) {
      switch (timeRangeType) {
        case TimeRangeType.PAY_PERIOD:
          view = TimesheetsTypes.reportViewed.view.PAY_PERIOD;
          break;
        case TimeRangeType.MONTHLY:
          view = TimesheetsTypes.reportViewed.view.MONTHLY;
          break;
        case TimeRangeType.WEEKLY:
          view = TimesheetsTypes.reportViewed.view.WEEKLY;
          break;
        case TimeRangeType.DAILY:
          view = TimesheetsTypes.reportViewed.view.DAILY;
          break;
        case TimeRangeType.CUSTOM:
          view = TimesheetsTypes.reportViewed.view.CUSTOM;
          break;
      }
    }

    if (!_.isNil(timesheetView)) {
      switch (timesheetView) {
        case TimesheetView.SUMMARY:
          viewType = TimesheetsTypes.reportViewed.viewType.SUMMARY;
          break;
        case TimesheetView.TIME_CARDS:
          viewType = TimesheetsTypes.reportViewed.viewType.TIME_CARDS;
          break;
        case TimesheetView.TIME_ENTRIES:
          viewType = TimesheetsTypes.reportViewed.viewType.TIME_ENTRIES;
          break;
      }
    }

    Graylog({
      shortMsg: AnalyticsEvent.timesheets.TIMESHEETS_REPORT_VIEWED,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _is_pro_account: isPro,
        _view: view,
        _view_type: viewType,
      },
    });
  }

  function filterSet(
    memberId: string | null,
    memberGroupId: string | null,
    positionId: string | null,
    projectId: string | null,
    costCodeId: string | null,
    equipmentId: string | null,
    type: TimesheetReportType | null,
    archivedStatus: ArchivedStatus
  ) {
    let typeValue: string | null = null;
    if (!_.isNull(type)) {
      switch (type) {
        case TimesheetReportType.ENTRY:
          typeValue = TimesheetsTypes.filterSet.filterType.ENTRY;
          break;
        case TimesheetReportType.TIME_OFF_BOTH:
          typeValue = TimesheetsTypes.filterSet.filterType.TIME_OFF_BOTH;
          break;
        case TimesheetReportType.TIME_OFF_PAID:
          typeValue = TimesheetsTypes.filterSet.filterType.TIME_OFF_PAID;
          break;
        case TimesheetReportType.TIME_OFF_UNPAID:
          typeValue = TimesheetsTypes.filterSet.filterType.TIME_OFF_UNPAID;
          break;
      }
    }
    Graylog({
      shortMsg: AnalyticsEvent.timesheets.TIMESHEETS_FILTER_SET,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _is_pro_account: isPro,
        _filter_member_id: memberId,
        _filter_member_group_id: memberGroupId,
        _filter_project_id: projectId,
        _filter_cost_code_id: costCodeId,
        _filter_equipment_id: equipmentId,
        _filter_position_id: equipmentId,
        _filter_type: typeValue,
        _archived_status: archivedStatus,
      },
    });
  }

  function tableColumnsSet(columnKey: string, isVisible: boolean, timesheetView: TimesheetView) {
    let viewType = TimesheetsTypes.reportViewed.viewType.SUMMARY;

    switch (timesheetView) {
      case TimesheetView.SUMMARY:
        viewType = TimesheetsTypes.reportViewed.viewType.SUMMARY;
        break;
      case TimesheetView.TIME_CARDS:
        viewType = TimesheetsTypes.reportViewed.viewType.TIME_CARDS;
        break;
      case TimesheetView.TIME_ENTRIES:
        viewType = TimesheetsTypes.reportViewed.viewType.TIME_ENTRIES;
        break;
    }

    let type: string | null = null;
    switch (columnKey) {
      case 'totalHours':
        type = TimesheetsTypes.tableColumnsSet.type.TOTAL_HOURS;
        break;
      case 'regularHours':
        type = TimesheetsTypes.tableColumnsSet.type.REGULAR_HOURS;
        break;
      case 'overtimeHours':
        type = TimesheetsTypes.tableColumnsSet.type.OVERTIME_HOURS;
        break;
      case 'doubleTimeHours':
        type = TimesheetsTypes.tableColumnsSet.type.DOUBLE_TIME_HOURS;
        break;
      case 'paidTimeOff':
        type = TimesheetsTypes.tableColumnsSet.type.PAID_TIME_OFF;
        break;
      case 'timeAccurate':
        type = TimesheetsTypes.tableColumnsSet.type.TIME_ACCURATE;
        break;
      case 'breakCompliance':
        type = TimesheetsTypes.tableColumnsSet.type.BREAK_COMPLIANCE;
        break;
      case 'injured':
        type = TimesheetsTypes.tableColumnsSet.type.INJURED;
        break;
      case 'employeeSigned':
        type = TimesheetsTypes.tableColumnsSet.type.EMPLOYEE_SIGNED;
        break;
      case 'supervisorSigned':
        type = TimesheetsTypes.tableColumnsSet.type.SUPERVISOR_SIGNED;
        break;
      case 'type':
        type = TimesheetsTypes.tableColumnsSet.type.TYPE;
        break;
      case 'total':
        type = TimesheetsTypes.tableColumnsSet.type.TOTAL;
        break;
      case 'start':
        type = TimesheetsTypes.tableColumnsSet.type.START;
        break;
      case 'end':
        type = TimesheetsTypes.tableColumnsSet.type.END;
        break;
      case 'breaks':
        type = TimesheetsTypes.tableColumnsSet.type.BREAKS;
        break;
      case 'project':
        type = TimesheetsTypes.tableColumnsSet.type.PROJECT;
        break;
      case 'costCode':
        type = TimesheetsTypes.tableColumnsSet.type.COST_CODE;
        break;
      case 'equipment':
        type = TimesheetsTypes.tableColumnsSet.type.EQUIPMENT;
        break;
      case 'description':
        type = TimesheetsTypes.tableColumnsSet.type.DESCRIPTION;
        break;
    }

    Graylog({
      shortMsg: AnalyticsEvent.timesheets.TIMESHEETS_TABLE_COLUMNS_SET,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _is_pro_account: isPro,
        _type: type,
        _enabled: isVisible,
        _view_type: viewType,
      },
    });
  }

  function events(type: string) {
    Graylog({
      shortMsg: AnalyticsEvent.timesheets.TIMESHEETS_EVENTS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _is_pro_account: isPro,
        _action_type: type,
      },
    });
  }

  function graphTypeSet(typeTitle: string) {
    let type: string | null = null;
    switch (typeTitle) {
      case 'Total Hrs':
        type = TimesheetsTypes.graphTypeSet.type.TOTAL_HOURS;
        break;
      case 'Regular Hrs':
        type = TimesheetsTypes.graphTypeSet.type.REGULAR_HOURS;
        break;
      case 'Overtime Hrs':
        type = TimesheetsTypes.graphTypeSet.type.OVERTIME_HOURS;
        break;
      case 'Double Time Hrs':
        type = TimesheetsTypes.graphTypeSet.type.DOUBLE_TIME_HOURS;
        break;
    }

    Graylog({
      shortMsg: AnalyticsEvent.timesheets.TIMESHEETS_GRAPH_TYPE_SET,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _is_pro_account: isPro,
        _type: type,
      },
    });
  }

  function locationHistoryFilterTypeSet(type: string) {
    let filterType: string | null = null;
    switch (type) {
      case MemberLocationType.ALL:
        filterType = TimesheetsTypes.locationHistoryFilterTypeSet.filter_type.ALL_EVENTS;
        break;
      case MemberLocationType.START:
        filterType = TimesheetsTypes.locationHistoryFilterTypeSet.filter_type.START;
        break;
      case MemberLocationType.END:
        filterType = TimesheetsTypes.locationHistoryFilterTypeSet.filter_type.END;
        break;
      case MemberLocationType.BREAK_START:
        filterType = TimesheetsTypes.locationHistoryFilterTypeSet.filter_type.BREAK_START;
        break;
      case MemberLocationType.BREAK_END:
        filterType = TimesheetsTypes.locationHistoryFilterTypeSet.filter_type.BREAK_END;
        break;
      case MemberLocationType.LOCATION_UPDATE:
        filterType = TimesheetsTypes.locationHistoryFilterTypeSet.filter_type.LOCATION_UPDATE;
        break;
    }

    Graylog({
      shortMsg: AnalyticsEvent.timesheets.TIMESHEETS_GRAPH_TYPE_SET,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _is_pro_account: isPro,
        _filter_type: filterType,
      },
    });
  }

  return { reportViewed, filterSet, tableColumnsSet, events, graphTypeSet, locationHistoryFilterTypeSet };
}
