import { Avatar, IAvatarProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useActiveMember } from 'hooks';
import { getSafeString } from 'utils/optionalUtils';

export interface IActiveMemberAvatarProps extends Omit<IAvatarProps, 'firstName' | 'lastName' | 'imageUrl'> {
  backupImage?: string;
}

export default function ActiveMemberAvatar({ className, backupImage, ...avatarProps }: IActiveMemberAvatarProps) {
  const { firstName, lastName, imageUrl } = useActiveMember();
  const classes = classNames('active-member-avatar', className);

  return (
    <Avatar
      firstName={getSafeString(firstName)}
      lastName={getSafeString(lastName)}
      image={imageUrl ?? backupImage ?? undefined}
      className={classes}
      {...avatarProps}
    />
  );
}
