import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { Well } from 'components';
import { usePermissions } from 'hooks';
import { ReactNode } from 'react';
import ITimeEntry from 'types/TimeEntry';
import ITimeOff from 'types/TimeOff';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { t } from 'utils/localize';

export interface IEditTimePermissionWellProps {
  timeEntries?: ITimeEntry[];
  timeOffs?: ITimeOff[];
  className?: ClassName;
}

const EditTimePermissionWell = (props: IEditTimePermissionWellProps) => {
  const { timeEntries, timeOffs, className } = props;

  const { hasPermissionToManage } = usePermissions();

  function canNotManage(): boolean {
    const canNotManageEntries =
      timeEntries?.map((entry) => entry.member).some((member) => !hasPermissionToManage(member, 'manageTimeEntries')) ??
      false;

    const canNotManageTimeOffs =
      timeOffs?.map((entry) => entry.member).some((member) => !hasPermissionToManage(member, 'manageTimeOff')) ?? false;

    return canNotManageEntries || canNotManageTimeOffs;
  }

  function canNotManageAllMembers(): boolean {
    const canNotManageEntries =
      timeEntries
        ?.map((entry) => entry.member)
        .every((member) => !hasPermissionToManage(member, 'manageTimeEntries')) ?? false;

    const canNotManageTimeOffs =
      timeOffs?.map((entry) => entry.member).every((member) => !hasPermissionToManage(member, 'manageTimeOff')) ??
      false;

    return canNotManageEntries && canNotManageTimeOffs;
  }

  function optionallyRenderWell(): ReactNode {
    if (isNilOrEmpty(timeEntries) && isNilOrEmpty(timeOffs)) {
      return <></>;
    }
    if (canNotManage()) {
      let message: string = t(
        'You do not have permission to edit one or more of the selected items. These items will not be updated.'
      );
      if (canNotManageAllMembers()) {
        message = t('You do not have permission to edit the selected items.');
      }
      return (
        <Well className={classes} theme="danger">
          {message}
        </Well>
      );
    }
    return <></>;
  }

  const classes = classNames('edit-time-permission-well', 'mb-4', 'ac-fade-slide-down', className);

  return <>{optionallyRenderWell()}</>;
};

export default EditTimePermissionWell;
