import { isNil } from 'lodash';
import { DateTime } from 'luxon';

export function isWithinSevenDays(dateISOString?: string): boolean {
  if (isNil(dateISOString)) {
    return false;
  }

  const today = DateTime.utc().startOf('day');
  const date = DateTime.fromISO(dateISOString);
  const differenceInTime = date.toMillis() - today.toMillis();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays <= 7 && differenceInDays >= 0;
}
