import gql from 'graphql-tag';

export const FLAGGED_TIME_ENTRY_LOCATION_QUERY = gql`
  query FlaggedTimeEntryLocation($after: String, $filter: FlaggedTimeEntryLocationFilter) {
    flaggedTimeEntryLocations(after: $after, filter: $filter, first: 500, sort: [{ createdOn: desc }]) {
      id
      organizationId
      timeEntryId
      memberId
      status
      comment
      reviewedByMemberId
      locationData
      edited
      lastStatusUpdatedOn
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      timeEntry {
        id
        startTime
        endTime
        projectId
        project {
          id
          title
          depth
          ancestors {
            id
            title
            depth
          }
          projectInfo {
            latitude
            longitude
            locationRadius
          }
        }
      }
      member {
        id
        firstName
        lastName
        position {
          id
          level
        }
      }
      reviewedByMember {
        id
        firstName
        lastName
      }
    }
  }
`;
