import { cloneDeep, compact, groupBy, isEmpty, isNil, remove, sortBy } from 'lodash';
import IClientTimeEntryBreakLog from 'types/ClientTimeEntryBreakLog';
import IClientTimeEntryLog from 'types/ClientTimeEntryLog';
import BreakActionType from 'types/enum/BreakActionType';
import ClockActionType from 'types/enum/ClockActionType';
import IIdable from 'types/Idable';
import { default as IBreak, default as ITimeEntryBreak } from 'types/TimeEntryBreak';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';
import { t } from './localize';
import { setObjectKey } from './objectUtils';

// TODO refactor to not use these types
export function getClockActionTitle(
  log: IClientTimeEntryLog,
  breakLogs: IClientTimeEntryBreakLog[] = [],
  entryBreaks: IBreak[]
) {
  switch (log.actionType) {
    case ClockActionType.CLOCK_IN:
    case ClockActionType.CLOCK_IN_LOCATION:
      return t('Clock In');
    case ClockActionType.CLOCK_OUT:
    case ClockActionType.CLOCK_OUT_LOCATION:
      return t('Clock Out');
    case ClockActionType.SYSTEM_CLOCK_OUT:
      return t('System Clock Out');
    case ClockActionType.CLOCK_IN_AT:
      return t('Clock In At');
    case ClockActionType.CLOCK_OUT_AT:
      return t('Clock Out At');
    case ClockActionType.MANUAL:
      return t('Manual');
    case ClockActionType.EDIT:
      return t('Edit');
    case ClockActionType.EDIT_BREAKS: {
      let newBreakLogs = getBreakLogs(log, entryBreaks);
      if (newBreakLogs === null || isEmpty(newBreakLogs)) {
        newBreakLogs = breakLogs;
      }
      if (newBreakLogs) {
        for (const breakLog of newBreakLogs) {
          switch (breakLog.actionType) {
            case BreakActionType.BREAK_START:
            case BreakActionType.BREAK_START_LOCATION:
              return t('Break Start');
            case BreakActionType.BREAK_END:
            case BreakActionType.BREAK_END_LOCATION:
            case BreakActionType.SYSTEM_BREAK_END:
              return t('Break End');
            case BreakActionType.BREAK_CONFLICT_RESOLUTION:
              return t('Conflict Resolution');
          }
        }
      }
      return t('Edit Breaks');
    }
    case ClockActionType.CONFLICT_RESOLUTION:
      return t('Conflict Resolution');
    case ClockActionType.DELETE:
      return t('Delete');
    case ClockActionType.CONFLICT_RESOLUTION_DELETE:
      return t('Conflict Resolution Delete');
    case ClockActionType.LOCK_CONFLICT_RESOLUTION_DELETE:
      return t('Lock Conflict Resolution Delete');
  }
}

export function compressLocations<
  T extends Pick<IClientTimeEntryLog, 'id' | 'actionType' | 'startLocationId' | 'endLocationId' | 'createdOn'>
>(logs: T[], entryBreaks: IBreak[]): T[] {
  const allLogs: T[] = cloneDeep(logs);
  const clockInLog = logs.find((log) => log.actionType === ClockActionType.CLOCK_IN);
  const clockInLocationLog = logs.find((log) => log.actionType === ClockActionType.CLOCK_IN_LOCATION);
  const clockOutLog = logs.find((log) => log.actionType === ClockActionType.CLOCK_OUT);
  const clockOutLocationLog = logs.find((log) => log.actionType === ClockActionType.CLOCK_OUT_LOCATION);

  if (clockInLog && clockInLocationLog) {
    if (isNil(clockInLog.startLocationId)) {
      removeById(allLogs, clockInLog.id);
    } else {
      removeById(allLogs, clockInLocationLog.id);
    }
  }

  if (clockOutLog && clockOutLocationLog) {
    if (isNil(clockOutLog.endLocationId)) {
      removeById(allLogs, clockOutLog.id);
    } else {
      removeById(allLogs, clockOutLocationLog.id);
    }
  }

  return compressBreakLocations(allLogs, entryBreaks);
}

function compressBreakLocations<T extends Pick<IClientTimeEntryLog, 'id' | 'actionType' | 'createdOn'>>(
  allLogs: T[],
  entryBreaks: IBreak[]
) {
  const entryEditBreakLogs = sortBy(
    cloneDeep(allLogs.filter((l) => l.actionType === ClockActionType.EDIT_BREAKS)),
    'createdOn'
  );

  entryEditBreakLogs.forEach((log, index) => {
    const breakLogs = getBreakLogs(log, entryBreaks) ?? [];

    const { startBreakLog, startBreakLocationLog, endBreakLog, endBreakLocationLog } = getBreakLogsByAction(breakLogs);

    const nextLog = entryEditBreakLogs[index + 1];

    if (nextLog) {
      const nextBreakLogs = getBreakLogs(nextLog, entryBreaks) ?? [];

      const {
        startBreakLog: nextStartBreakLog,
        startBreakLocationLog: nextStartBreakLocationLog,
        endBreakLog: nextEndBreakLog,
        endBreakLocationLog: nextEndBreakLocationLog,
      } = getBreakLogsByAction(nextBreakLogs);

      if (!isNil(startBreakLog) && isNil(startBreakLog.startLocationId) && !isNil(nextStartBreakLocationLog)) {
        removeById(allLogs, log.id);
      } else if (!isNil(endBreakLog) && isNil(endBreakLog.endLocationId) && !isNil(nextEndBreakLocationLog)) {
        removeById(allLogs, log.id);
      } else if (
        !isNil(nextStartBreakLog) &&
        isNil(nextStartBreakLog.startLocationId) &&
        !isNil(startBreakLocationLog)
      ) {
        removeById(allLogs, nextLog.id);
      } else if (!isNil(nextEndBreakLog) && isNil(nextEndBreakLog.endLocationId) && !isNil(endBreakLocationLog)) {
        removeById(allLogs, nextLog.id);
      }
    }
  });

  return allLogs;
}

export function getBreakLogs(
  log: Pick<IClientTimeEntryLog, 'id'>,
  entryBreaks: IBreak[]
): IClientTimeEntryBreakLog[] | null {
  if (!isEmpty(entryBreaks)) {
    return entryBreaks.flatMap((item: IBreak) => {
      return (
        item.clientLogs?.filter((breakLog) => breakLog.clientTimeEntryLogId === log.id && isNil(breakLog.deletedOn)) ??
        []
      );
    });
  }
  return null;
}

function getBreakLogsByAction(breakLogs: IClientTimeEntryBreakLog[]) {
  return breakLogs.reduce<{
    startBreakLog: Nullable<IClientTimeEntryBreakLog>;
    startBreakLocationLog: Nullable<IClientTimeEntryBreakLog>;
    endBreakLog: Nullable<IClientTimeEntryBreakLog>;
    endBreakLocationLog: Nullable<IClientTimeEntryBreakLog>;
  }>(
    (acc, cur) => {
      switch (cur?.actionType) {
        case BreakActionType.BREAK_START:
          return setObjectKey(acc, 'startBreakLog', cur);
        case BreakActionType.BREAK_START_LOCATION:
          return setObjectKey(acc, 'startBreakLocationLog', cur);
        case BreakActionType.BREAK_END:
          return setObjectKey(acc, 'endBreakLog', cur);
        case BreakActionType.BREAK_END_LOCATION:
          return setObjectKey(acc, 'endBreakLocationLog', cur);
      }

      return acc;
    },
    {
      startBreakLog: null,
      startBreakLocationLog: null,
      endBreakLog: null,
      endBreakLocationLog: null,
    } as const
  );
}

export function getAppropriateLocationId(
  actionType: IClientTimeEntryLog['actionType'],
  startLocationId: Optional<string>,
  endLocationId: Optional<string>,
  breakLogs: IClientTimeEntryBreakLog[]
) {
  switch (actionType) {
    case ClockActionType.CLOCK_IN:
    case ClockActionType.CLOCK_IN_LOCATION:
      return startLocationId ?? null;
    case ClockActionType.CLOCK_OUT:
    case ClockActionType.CLOCK_OUT_LOCATION:
      return endLocationId ?? null;
    case ClockActionType.EDIT_BREAKS: {
      for (const breakLog of breakLogs) {
        const type = breakLog.actionType;
        switch (type) {
          case BreakActionType.BREAK_START:
          case BreakActionType.BREAK_START_LOCATION:
            if (!isNil(breakLog.startLocationId)) {
              return breakLog.startLocationId;
            }
            break;
          case BreakActionType.BREAK_END:
          case BreakActionType.BREAK_END_LOCATION:
          case BreakActionType.SYSTEM_BREAK_END:
            if (!isNil(breakLog.endLocationId)) {
              return breakLog.endLocationId;
            }
            break;
        }
      }
      break;
    }
  }

  return null;
}

export function compressTimeEntryLogsWithLocationIds<
  T extends Pick<IClientTimeEntryLog, 'actionType' | 'id' | 'createdOn' | 'startLocationId' | 'endLocationId'>
>(
  logs: T[],
  breaks: ITimeEntryBreak[]
): Array<{
  locationId: Nullable<string>;
  breakLogs: IClientTimeEntryBreakLog[];
  timeEntryLog: T;
}> {
  const compressed = compressLocations(logs, breaks);
  const grouped = groupTimeEntryLogsWithBreakLogs(compressed, breaks);
  return mergeLocationIdIntoGroupings(grouped);
}

interface GroupedTimeEntryLog<
  T extends Pick<IClientTimeEntryLog, 'actionType' | 'id' | 'createdOn' | 'startLocationId' | 'endLocationId'>
> {
  breakLogs: IClientTimeEntryBreakLog[];
  timeEntryLog: T;
}

export function groupTimeEntryLogsWithBreakLogs<
  T extends Pick<IClientTimeEntryLog, 'actionType' | 'id' | 'createdOn' | 'startLocationId' | 'endLocationId'>
>(logs: T[], breaks: ITimeEntryBreak[]): Array<GroupedTimeEntryLog<T>> {
  const breakLogs = compact(breaks.flatMap((brk) => brk.clientLogs));
  const breakLogsByClientId = groupBy(breakLogs, (breakLog) => breakLog?.clientTimeEntryLogId ?? '0');

  return logs.map((timeEntryLog) => {
    const breakLogs = breakLogsByClientId[timeEntryLog.id];
    if (breakLogs) {
      return { breakLogs, timeEntryLog };
    } else {
      return { breakLogs: [], timeEntryLog };
    }
  });
}

export function mergeLocationIdIntoGroupings<
  T extends Pick<IClientTimeEntryLog, 'actionType' | 'id' | 'createdOn' | 'startLocationId' | 'endLocationId'>
>(values: Array<GroupedTimeEntryLog<T>>) {
  return values.map((value) => ({
    ...value,
    locationId: getAppropriateLocationId(
      value.timeEntryLog.actionType,
      value.timeEntryLog.startLocationId,
      value.timeEntryLog.endLocationId,
      value.breakLogs
    ),
  }));
}

function removeById<T extends IIdable<string>>(array: T[], id: string) {
  return remove(array, ({ id: eltId }) => eltId === id);
}
