import { Row, Theme } from '@busybusy/webapp-react-ui';
import { AddCircleIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import AnimatedList from 'components/animation/AnimatedList/AnimatedList';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import Panel from 'components/layout/Panel/Panel';
import { t } from 'i18next';
import { take } from 'lodash';
import { ReactNode, useState } from 'react';
import IIdable from 'types/Idable';
import './MultiPicker.scss';

type ValueType = string | number | IIdable<string | number> | null;

export interface IMultiPickerProps<T extends ValueType> {
  readonly values: T[];
  limit: number;
  renderItem: (value: T, index: number) => ReactNode;
  onAddClick: () => void;
  className?: ClassName;
}

function MultiPicker<T extends ValueType>({ values, onAddClick, renderItem, limit, className }: IMultiPickerProps<T>) {
  const [isExpanded, setIsExpanded] = useState(false);

  function expand() {
    setIsExpanded(true);
  }

  const classes = classNames('multi-picker', className);

  return (
    <div className={classes}>
      <Row>
        {values.length > 0 && (
          <Panel className="multi-picker-content">
            <AnimatedList renderItem={renderItem} values={isExpanded ? values : take(values, limit)} initialY={15} />
          </Panel>
        )}
        <IconButton svg={AddCircleIcon} onClick={onAddClick} theme={Theme.LIGHT} />
      </Row>

      {!isExpanded && values.length > limit && (
        <div className="more-text" onClick={expand}>
          + {values.length - limit} {t('more')}
        </div>
      )}
    </div>
  );
}

export default MultiPicker;
