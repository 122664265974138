import { isEmpty, isNil } from 'lodash';
import { ILaborMetric } from 'utils/jitMetricUtils';
import { IActivityReportRowInfo } from '../hooks/ActivityReportData';
import { IProjectActivityTableRowInfo } from '../project-tables/hooks/useProjectActivity';

const metricToRowInfo = (metricTotal: ILaborMetric) => {
  return {
    regularHours: metricTotal.regularSeconds + metricTotal.paidTimeOffSeconds,
    regularHoursDec: metricTotal.regularSeconds + metricTotal.paidTimeOffSeconds,
    overtimeHours: metricTotal.overtimeSeconds,
    overtimeHoursDec: metricTotal.overtimeSeconds,
    doubleTimeHours: metricTotal.doubleTimeSeconds,
    doubleTimeHoursDec: metricTotal.doubleTimeSeconds,
    totalHours: metricTotal.totalSeconds,
    totalHoursDec: metricTotal.totalSeconds,
    regularCost: metricTotal.regularCost + metricTotal.timeOffCost,
    overtimeCost: metricTotal.overtimeCost,
    doubletimeCost: metricTotal.doubleTimeCost,
    laborBurden: metricTotal.laborBurden,
    totalCost: metricTotal.totalCost,
  };
};

const rowInfoToMetric = (rowInfo: IActivityReportRowInfo): ILaborMetric => {
  return {
    regularSeconds: rowInfo.regularHours,
    overtimeSeconds: rowInfo.overtimeHours,
    doubleTimeSeconds: rowInfo.doubleTimeHours,
    totalSeconds: rowInfo.totalHours,
    regularCost: rowInfo.regularCost,
    overtimeCost: rowInfo.overtimeCost,
    doubleTimeCost: rowInfo.doubletimeCost,
    laborBurden: rowInfo.laborBurden,
    totalCost: rowInfo.totalCost,
    paidTimeOffSeconds: 0,
    timeOffSeconds: 0,
    timeOffCost: 0,
    equipmentCost: 0,
  };
};

export const activityReportUtils = {
  metricToRowInfo,
  rowInfoToMetric,
};

export function flattenProjects(data: IProjectActivityTableRowInfo[]): IProjectActivityTableRowInfo[] {
  return data.flatMap((item) => {
    if (item.subprojectData && !isEmpty(item.subprojectData)) {
      return [item, ...flattenProjects(item.subprojectData)];
    } else if (item.detailRows && !isEmpty(item.detailRows)) {
      return [item, ...item.detailRows];
    } else {
      return [item];
    }
  });
}

export function flattenForSubProjectExport(data: IProjectActivityTableRowInfo[]): IProjectActivityTableRowInfo[] {
  return data.flatMap((item) => {
    if (item.subprojectData && !isEmpty(item.subprojectData)) {
      return [...flattenForSubProjectsForProject(item, item.subprojectData)];
    } else {
      return [item];
    }
  });
}

function flattenForSubProjectsForProject(
  parentProject: IProjectActivityTableRowInfo,
  subProjectData: IProjectActivityTableRowInfo[]
): IProjectActivityTableRowInfo[] {
  return subProjectData.flatMap((item) => {
    let project = item.project;

    if (!isNil(project)) {
      project = {
        ...project,
        ancestors: [parentProject.project!],
      };
    }

    item.project = project;

    if (item.subprojectData && !isEmpty(item.subprojectData)) {
      return [...flattenForSubProjectsForProject(item, item.subprojectData)];
    }

    // add parent project to detail rows
    if (item.detailRows && !isEmpty(item.detailRows)) {
      item.detailRows = item.detailRows?.map((row) => {
        return {
          ...row,
          project,
        };
      });
    }

    return [item];
  });
}
