import { Button, Container } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import { FunctionComponent } from 'react';
import { t } from 'utils/localize';

export interface IArchivedEmployeeWarningDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
}

const ArchivedEmployeeWarningDialog: FunctionComponent<IArchivedEmployeeWarningDialogProps> = (props) => {
  const { className, isOpen, onClose } = props;

  const classes = classNames('sign-in-report-details-dialog', className);

  return (
    <HeaderDialog className={classes} isOpen={isOpen} onClose={onClose} title={t('Archived employee')}>
      <Container className="m-5">
        <div>{t('Please unarchive this employee to modify their time entries.')}</div>
        <Button type="primary" onClick={onClose} className="my-5 ">
          {t('OK')}
        </Button>
      </Container>
    </HeaderDialog>
  );
};

export default ArchivedEmployeeWarningDialog;
