import { IPayrollData } from 'containers/onboarding/owner-onboarding/OwnerOnboardingPayrollSettings/OwnerOnboardingPayrollSettings';
import _, { findLastIndex, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { DayOfWeek, DayOfWeekValue } from 'types/enum/DayOfWeek';
import { PayPeriodType, PayPeriodTypeValue } from 'types/enum/PayPeriodType';
import IOrganizationPayPeriod from 'types/OrganizationPayPeriod';
import ITimeRange from 'types/TimeRange';
import { Organization } from '__generated__/graphql';
import { DAY, WEEK } from './constants/timeInterval';
import { convertDayToNumber, dateTimeFromISOWithoutZone, dateTimeFromUtcISO, isSameDay } from './dateUtils';
import { t } from './localize';
import { createNumberWithSuffix } from './numberUtils';
import { getChangeDateString } from './wageUtils';

export function createWeeklyPayPeriod(period: IOrganizationPayPeriod, time: DateTime): ITimeRange<DateTime> {
  const dayStart = time.startOf('day');
  // converts date time to be Sunday(0) - Saturday(6) rather than Monday(1) - Sunday(7)
  const dayOfWeek = dayStart.weekday % 7;
  let dayOffset = 0;
  const periodDayOfWeek = period.dayOne;

  if (dayOfWeek < periodDayOfWeek) {
    dayOffset = 7 - (periodDayOfWeek - dayOfWeek);
  } else if (dayOfWeek > periodDayOfWeek) {
    dayOffset = dayOfWeek - periodDayOfWeek;
  }

  const startTime = dayStart.minus({ day: dayOffset });
  const endTime = startTime.plus({ week: 1 }).minus({ second: 1 });

  return { startTime, endTime };
}

export function createBiweeklyPayPeriod(
  period: IOrganizationPayPeriod,
  time: DateTime,
  zone: 'system' | 'utc' = 'system'
): ITimeRange<DateTime> {
  const dayStart = time.startOf('day');
  const firstSunday = 259200; // First epoch Sunday
  const dayOfWeek = dayStart.weekday % 7;
  let dayOffset = 0;
  const biWeeklyOffset = period.dayOne % 14;
  const biWeeklyStart = DateTime.fromISO(period.startDate, { zone }).plus({ day: biWeeklyOffset });
  const periodDayOfWeek = biWeeklyStart.weekday % 7;

  if (dayOfWeek < periodDayOfWeek) {
    dayOffset = 7 - (periodDayOfWeek - dayOfWeek);
  } else if (dayOfWeek > periodDayOfWeek) {
    dayOffset = dayOfWeek - periodDayOfWeek;
  }

  const whichWeekPeriodStarts = Math.floor((biWeeklyStart.toSeconds() - firstSunday) / WEEK) % 2;
  const whichWeekIsTimeAt = Math.floor((dayStart.toSeconds() - dayOffset * DAY - firstSunday) / WEEK) % 2;

  if (whichWeekPeriodStarts !== whichWeekIsTimeAt) {
    dayOffset += 7;
  }

  const startTime = dayStart.minus({ days: dayOffset });
  const endTime = startTime.plus({ weeks: 2 }).minus({ second: 1 });

  return { startTime, endTime };
}

export function createSemiMonthlyPayPeriod(period: IOrganizationPayPeriod, time: DateTime): ITimeRange<DateTime> {
  const dayStart = time.startOf('day');
  let dayOne = period.dayOne;
  let dayTwo = period.dayTwo!; // This will always be set on semi monthly pay period types

  if (dayOne > dayTwo) {
    dayOne = period.dayTwo!;
    dayTwo = period.dayOne;
  }

  let maxDaysInMonth = dayStart.daysInMonth;
  const currentDayOfMonth = dayStart.day;

  let startTime;
  let endTime;

  if (currentDayOfMonth < dayOne) {
    const dayOneTime = dayStart.set({ day: dayOne });
    const backMonth = dayOneTime.minus({ month: 1 });
    maxDaysInMonth = backMonth.daysInMonth;
    startTime = backMonth.set({ day: dayTwo < maxDaysInMonth ? dayTwo : maxDaysInMonth });
    endTime = dayOneTime.minus({ second: 1 });
  } else if (currentDayOfMonth >= dayTwo || currentDayOfMonth === maxDaysInMonth) {
    startTime = dayStart.set({ day: dayTwo < maxDaysInMonth ? dayTwo : maxDaysInMonth });
    endTime = dayStart.set({ day: dayOne }).plus({ month: 1 }).minus({ second: 1 });
  } else {
    startTime = dayStart.set({ day: dayOne });
    endTime = dayStart.set({ day: dayTwo < maxDaysInMonth ? dayTwo : maxDaysInMonth }).minus({ second: 1 });
  }

  return { startTime, endTime };
}

export function createMonthlyPayPeriod(period: IOrganizationPayPeriod, time: DateTime): ITimeRange<DateTime> {
  const dayStart = time.startOf('day');
  let startTime;
  let endTime;

  if (dayStart.day < period.dayOne && dayStart.day < dayStart.daysInMonth) {
    const date = dayStart.set({ day: period.dayOne < dayStart.daysInMonth ? period.dayOne : dayStart.daysInMonth });
    endTime = date.minus({ second: 1 });
    const lastMonth = date.minus({ month: 1 });
    startTime = lastMonth.set({ day: period.dayOne < lastMonth.daysInMonth ? period.dayOne : lastMonth.daysInMonth });
  } else {
    startTime = dayStart.set({ day: period.dayOne < dayStart.daysInMonth ? period.dayOne : dayStart.daysInMonth });
    const nextMonth = startTime.plus({ month: 1 });
    endTime = nextMonth
      .set({ day: period.dayOne < nextMonth.daysInMonth ? period.dayOne : nextMonth.daysInMonth })
      .minus({ second: 1 });
  }

  return { startTime, endTime };
}

interface IPairOrganizationPayPeriod {
  current: IOrganizationPayPeriod;
  next?: IOrganizationPayPeriod;
}

export function getAppropriatePayPeriodForTime(
  periods: IOrganizationPayPeriod[],
  timeSeconds: number,
  zone: 'system' | 'utc' = 'system'
): IPairOrganizationPayPeriod | null {
  const payPeriods = _.sortBy(
    periods.filter((period) => {
      return _.isNil(period?.deletedOn);
    }),
    ['startDate']
  );

  const lastIndex = findLastIndex(
    payPeriods,
    (period) => getEffectiveDate(period).startOf('day') <= DateTime.fromSeconds(timeSeconds, { zone }).startOf('day')
  );

  if (lastIndex >= 0) {
    return {
      current: payPeriods[lastIndex],
      next: payPeriods[lastIndex + 1],
    };
  }
  return null;
}

export function getFuturePayPeriod(periods: IOrganizationPayPeriod[]) {
  return _.first(
    periods.filter((period) => {
      return _.isNil(period?.deletedOn) && getEffectiveDate(period).startOf('day') > DateTime.local().startOf('day');
    })
  );
}

export function getEffectiveDate(payPeriod: IOrganizationPayPeriod): DateTime {
  const payPeriodStart = dateTimeFromUtcISO(payPeriod.startDate);
  let currentPayPeriod: ITimeRange<DateTime>;

  switch (payPeriod.payPeriodType) {
    case PayPeriodType.WEEKLY:
      currentPayPeriod = createWeeklyPayPeriod(payPeriod, dateTimeFromUtcISO(payPeriod.startDate));
      break;
    case PayPeriodType.BIWEEKLY:
      currentPayPeriod = createBiweeklyPayPeriod(payPeriod, dateTimeFromUtcISO(payPeriod.startDate));
      break;
    case PayPeriodType.SEMIMONTHLY:
      currentPayPeriod = createSemiMonthlyPayPeriod(payPeriod, dateTimeFromUtcISO(payPeriod.startDate));
      break;
    case PayPeriodType.MONTHLY:
      currentPayPeriod = createMonthlyPayPeriod(payPeriod, dateTimeFromUtcISO(payPeriod.startDate));
      break;
  }
  if (isSameDay(currentPayPeriod.startTime, payPeriodStart)) {
    return currentPayPeriod.startTime;
  }
  return currentPayPeriod.endTime.plus({ day: 1 });
}

export function getEffectiveDateString(payPeriod: IOrganizationPayPeriod): string {
  return getChangeDateString(getEffectiveDate(payPeriod).toISO()) ?? '';
}

export function getTypeName(payPeriod: IOrganizationPayPeriod): string {
  switch (payPeriod.payPeriodType) {
    case PayPeriodType.WEEKLY:
      return t('Weekly');
    case PayPeriodType.BIWEEKLY:
      return t('Biweekly');
    case PayPeriodType.SEMIMONTHLY:
      return t('Semimonthly');
    case PayPeriodType.MONTHLY:
      return t('Monthly');
  }
}

export function getStartOverString(payPeriod: IOrganizationPayPeriod): string {
  switch (payPeriod.payPeriodType) {
    case PayPeriodType.WEEKLY:
      return getStartOverWeekDayString(payPeriod.dayOne);
    case PayPeriodType.BIWEEKLY: {
      const startDate = dateTimeFromUtcISO(payPeriod.startDate);
      return getStartOverWeekDayString(startDate.plus({ day: payPeriod.dayOne }).weekday, true);
    }
    case PayPeriodType.SEMIMONTHLY:
      return getStartOverSemimonthlyString(payPeriod);
    case PayPeriodType.MONTHLY:
      return getStartOverMonthlyString(payPeriod);
  }
}

export function getStartOverWeekDayString(dayOfWeek: number, isBiweekly: boolean = false): string {
  switch (dayOfWeek) {
    case 0: // Our API uses 0 for Sunday
      return isBiweekly ? t('Sunday') : t('Sunday of the week');
    case 7: // Luxon uses 7 for Sunday instead of 0
      return isBiweekly ? t('Every Other Sunday') : t('Every Sunday');
    case DayOfWeek.MONDAY:
      return isBiweekly ? t('Every Other Monday') : t('Every Monday');
    case DayOfWeek.TUESDAY:
      return isBiweekly ? t('Every Other Tuesday') : t('Every Tuesday');
    case DayOfWeek.WEDNESDAY:
      return isBiweekly ? t('Every Other Wednesday') : t('Every Wednesday');
    case DayOfWeek.THURSDAY:
      return isBiweekly ? t('Every Other Thursday') : t('Every Thursday');
    case DayOfWeek.FRIDAY:
      return isBiweekly ? t('Every Other Friday') : t('Every Friday');
    case DayOfWeek.SATURDAY:
      return isBiweekly ? t('Every Other Saturday') : t('Every Saturday');
  }
  return '---';
}

export function getStartOverSemimonthlyString(payPeriod: IOrganizationPayPeriod): string {
  return t(
    'The ' +
      createNumberWithSuffix(payPeriod.dayOne) +
      t(' and ') +
      createNumberWithSuffix(payPeriod.dayTwo!) +
      t(' of each month')
  );
}

export function getStartOverMonthlyString(payPeriod: IOrganizationPayPeriod): string {
  return t('The ') + createNumberWithSuffix(payPeriod.dayOne) + t(' of each month');
}

export function getPayPeriodStartAndDayData(
  weeklyStartDay: DayOfWeekValue,
  payPeriodType: PayPeriodTypeValue,
  biWeeklyPayPeriodDay: string,
  semiMonthlyDays: string[],
  monthlyDay: string
) {
  if (payPeriodType === PayPeriodTypeValue.MONTHLY) {
    const day = convertDayToNumber(monthlyDay);
    const start = DateTime.fromObject({ day }, { zone: 'system' })
      .minus({ months: 2 })
      .toUTC(0, { keepLocalTime: true })
      .toSeconds();

    return {
      organization_pay_period_start: start,
      organization_pay_period_day_one: day,
      organization_pay_period_day_two: null,
    };
  } else if (payPeriodType === PayPeriodTypeValue.BIWEEKLY) {
    const effectivePayrollStartDate = _.toNumber(biWeeklyPayPeriodDay);
    const payPeriodStartDate = DateTime.fromSeconds(effectivePayrollStartDate!, { zone: 'system' })
      .minus({ weeks: 8 })
      .toUTC(0, { keepLocalTime: true })
      .toSeconds();
    const day1 = Math.trunc((effectivePayrollStartDate - payPeriodStartDate) / (3600 * 24)) % 14; // Formula from API team.
    return {
      organization_pay_period_start: payPeriodStartDate,
      organization_pay_period_day_one: day1,
      organization_pay_period_day_two: null,
    };
  } else if (payPeriodType === PayPeriodTypeValue.SEMIMONTHLY) {
    const day1 = convertDayToNumber(semiMonthlyDays![0]);
    const start = DateTime.fromObject({ day: day1 }, { zone: 'system' })
      .minus({ months: 2 })
      .toUTC(0, { keepLocalTime: true })
      .toSeconds();
    const day2 = convertDayToNumber(semiMonthlyDays![1]);

    return {
      organization_pay_period_start: start,
      organization_pay_period_day_one: day1,
      organization_pay_period_day_two: day2,
    };
  } else {
    const start = DateTime.fromFormat(weeklyStartDay, 'EEEE', { zone: 'system' })
      .minus({ weeks: 8 })
      .toUTC(0, { keepLocalTime: true })
      .toSeconds();

    return {
      organization_pay_period_start: start,
      organization_pay_period_day_one: getDayOfWeek(weeklyStartDay),
      organization_pay_period_day_two: null,
    };
  }
}

export function getPayPeriodData({
  weeklyStartDay,
  payPeriodType,
  biWeeklyPayPeriodDay,
  semiMonthlyDays,
  monthlyDay,
  overtimeStart,
}: IPayrollData) {
  const sameMembers = {
    organization_pay_period_type: getPayPeriodType(payPeriodType),
  };

  if (payPeriodType === PayPeriodTypeValue.MONTHLY || payPeriodType === PayPeriodTypeValue.SEMIMONTHLY) {
    const weekday = DateTime.fromFormat(overtimeStart!, 'EEEE', { zone: 'utc' }).weekday;
    return {
      ...sameMembers,
      ...getPayPeriodStartAndDayData(weeklyStartDay, payPeriodType, biWeeklyPayPeriodDay, semiMonthlyDays, monthlyDay),
      organization_overtime_period_start: DateTime.fromFormat(overtimeStart!, 'EEEE', { zone: 'utc' })
        .startOf('day')
        .minus({ months: 2 })
        .set({ millisecond: 0, weekday })
        .toSeconds(),
    };
  } else {
    return {
      ...sameMembers,
      ...getPayPeriodStartAndDayData(weeklyStartDay, payPeriodType, biWeeklyPayPeriodDay, semiMonthlyDays, monthlyDay),
      organization_overtime_period_start: DateTime.fromFormat(overtimeStart!, 'EEEE', { zone: 'utc' })
        .startOf('day')
        .set({ millisecond: 0 })
        .minus({ weeks: 8 })
        .toSeconds(),
    };
  }
}

export function getPayPeriodType(payPeriodTypeValue: PayPeriodTypeValue): PayPeriodType {
  switch (payPeriodTypeValue) {
    case PayPeriodTypeValue.WEEKLY:
      return PayPeriodType.WEEKLY;
    case PayPeriodTypeValue.BIWEEKLY:
      return PayPeriodType.BIWEEKLY;
    case PayPeriodTypeValue.SEMIMONTHLY:
      return PayPeriodType.SEMIMONTHLY;
    case PayPeriodTypeValue.MONTHLY:
      return PayPeriodType.MONTHLY;
  }
}

export function getPayPeriodTypeValue(payPeriodTypeValue: PayPeriodType): PayPeriodTypeValue {
  switch (payPeriodTypeValue) {
    case PayPeriodType.WEEKLY:
      return PayPeriodTypeValue.WEEKLY;
    case PayPeriodType.BIWEEKLY:
      return PayPeriodTypeValue.BIWEEKLY;
    case PayPeriodType.SEMIMONTHLY:
      return PayPeriodTypeValue.SEMIMONTHLY;
    case PayPeriodType.MONTHLY:
      return PayPeriodTypeValue.MONTHLY;
  }
}

export function getDayOfWeek(dayValue: DayOfWeekValue): DayOfWeek {
  switch (dayValue) {
    case DayOfWeekValue.MONDAY:
      return DayOfWeek.MONDAY;
    case DayOfWeekValue.TUESDAY:
      return DayOfWeek.TUESDAY;
    case DayOfWeekValue.WEDNESDAY:
      return DayOfWeek.WEDNESDAY;
    case DayOfWeekValue.THURSDAY:
      return DayOfWeek.THURSDAY;
    case DayOfWeekValue.FRIDAY:
      return DayOfWeek.FRIDAY;
    case DayOfWeekValue.SATURDAY:
      return DayOfWeek.SATURDAY;
    case DayOfWeekValue.SUNDAY:
      return DayOfWeek.SUNDAY;
  }
}

export function getDayValue(dayValue: number): DayOfWeekValue {
  switch (dayValue) {
    case DayOfWeek.MONDAY:
      return DayOfWeekValue.MONDAY;
    case DayOfWeek.TUESDAY:
      return DayOfWeekValue.TUESDAY;
    case DayOfWeek.WEDNESDAY:
      return DayOfWeekValue.WEDNESDAY;
    case DayOfWeek.THURSDAY:
      return DayOfWeekValue.THURSDAY;
    case DayOfWeek.FRIDAY:
      return DayOfWeekValue.FRIDAY;
    case DayOfWeek.SATURDAY:
      return DayOfWeekValue.SATURDAY;
    case DayOfWeek.SUNDAY:
      return DayOfWeekValue.SUNDAY;
  }

  return DayOfWeekValue.MONDAY;
}

export function getCurrentPayPeriodRange(
  periods: IOrganizationPayPeriod[],
  timeSeconds: number,
  zone: 'system' | 'utc'
): ITimeRange<DateTime> | null {
  const currentOrganizationPayPeriod = getAppropriatePayPeriodForTime(periods, timeSeconds, zone)?.current;
  const date = DateTime.fromSeconds(timeSeconds, { zone });

  if (!currentOrganizationPayPeriod) {
    return null;
  }

  switch (currentOrganizationPayPeriod.payPeriodType) {
    case PayPeriodType.WEEKLY:
      return createWeeklyPayPeriod(currentOrganizationPayPeriod, date);
    case PayPeriodType.BIWEEKLY:
      return createBiweeklyPayPeriod(currentOrganizationPayPeriod, date);
    case PayPeriodType.SEMIMONTHLY:
      return createSemiMonthlyPayPeriod(currentOrganizationPayPeriod, date);
    case PayPeriodType.MONTHLY:
      return createMonthlyPayPeriod(currentOrganizationPayPeriod, date);
  }
}

export function getNextPayPeriodRange(
  periods: IOrganizationPayPeriod[],
  timeSeconds: number,
  zone: 'system' | 'utc'
): ITimeRange<DateTime> | null {
  const currentPayPeriodRange = getCurrentPayPeriodRange(periods, timeSeconds, zone);

  if (!currentPayPeriodRange) {
    return null;
  }

  const nextStart = currentPayPeriodRange.endTime.toSeconds() + 1;
  return getCurrentPayPeriodRange(periods, nextStart, zone);
}

export function getPayPeriodTypeCountInYear(payPeriodType: PayPeriodType) {
  switch (payPeriodType) {
    case PayPeriodType.WEEKLY:
      return 52;
    case PayPeriodType.MONTHLY:
      return 12;
    case PayPeriodType.SEMIMONTHLY:
      return 24;
    case PayPeriodType.BIWEEKLY:
      return 26;
  }
}

export function getPayPeriodTimeRange(
  payPeriods: IOrganizationPayPeriod[],
  time: number,
  zone: 'utc' | 'system' = 'utc'
) {
  const pair = getAppropriatePayPeriodForTime(payPeriods, time, zone)!; // Subtract one for the previous pay period
  const date = DateTime.fromSeconds(time, { zone });
  let newRange: ITimeRange<DateTime>;
  switch (pair.current.payPeriodType) {
    case PayPeriodType.WEEKLY:
      newRange = createWeeklyPayPeriod(pair.current, date);
      break;
    case PayPeriodType.BIWEEKLY:
      newRange = createBiweeklyPayPeriod(pair.current, date, zone);
      break;
    case PayPeriodType.SEMIMONTHLY:
      newRange = createSemiMonthlyPayPeriod(pair.current, date);
      break;
    case PayPeriodType.MONTHLY:
      newRange = createMonthlyPayPeriod(pair.current, date);
      break;
  }

  if (newRange.startTime < dateTimeFromUtcISO(pair.current.startDate)) {
    newRange.startTime = dateTimeFromUtcISO(pair.current.startDate);
  }
  if (!isNil(pair.next) && dateTimeFromUtcISO(pair.next.startDate) < newRange.endTime) {
    newRange.endTime = dateTimeFromUtcISO(pair.next.startDate).minus({ seconds: 1 });
  }

  return newRange;
}

export function canPayPeriodBeSigned(organization: Pick<Organization, 'signatureDate'>, payPeriodStart: DateTime) {
  return (
    !!organization.signatureDate &&
    dateTimeFromISOWithoutZone(organization.signatureDate!).toSeconds() <= payPeriodStart.toSeconds()
  );
}
