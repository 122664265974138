import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreDailyReportAdditionalSafetyPhotos {
  safetyPhotos: string[] | null | undefined;
}

export const initialState: IStoreDailyReportAdditionalSafetyPhotos = {
  safetyPhotos: [],
};

const slice = createSlice({
  name: 'dailyReportAdditionalSafetyPhotos',
  initialState,
  reducers: {
    setSafetyAdditionalPhotos: (
      state: IStoreDailyReportAdditionalSafetyPhotos,
      action: PayloadAction<string[] | null | undefined>
    ) => {
      state.safetyPhotos = action.payload;
      return state;
    },
  },
});

export const { setSafetyAdditionalPhotos } = slice.actions;
export default slice.reducer;
