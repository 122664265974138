import { isNil, isNull } from 'lodash';
import IEquipment, { IEquipmentCreateInput, IEquipmentEditInput } from 'types/Equipment';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { CSV_ID_COLUMN, EQUIPMENT_CSV_HEADERS } from 'utils/constants/csvConstants';
import { getNowIsoAtUtcWithLocalTimeZone, localizeDateTimeFromUtcISO } from 'utils/dateUtils';
import { getCurrentEquipmentCostHistory } from 'utils/equipmentUtils';
import { convertEmptyToNull } from 'utils/stringUtils';
import { uuid } from 'utils/uuidUtils';
import ICSVEquipment from '../../../csv-import/types/ImportEquipmentTypes';

export function getEquipmentCsvHeaders() {
  return EQUIPMENT_CSV_HEADERS;
}

export function convertCsvEquipmentToEquipment(
  equipment: ICSVEquipment,
  organizationId: string
): IEquipmentCreateInput {
  return {
    id: uuid(),
    equipmentName: equipment.name,
    equipmentModelId: equipment.modelId!,
    trackManualHours: !isNull(convertEmptyToNull(equipment.hourMeter)),
    organizationId,
    createdOn: getNowIsoAtUtcWithLocalTimeZone(),
  };
}

export function convertCsvEquipmentToUpdateEquipment(equipment: ICSVEquipment): IEquipmentEditInput {
  return {
    id: equipment.id,
    equipmentName: equipment.name,
    equipmentModelId: equipment.modelId,
    trackManualHours: !isNull(convertEmptyToNull(equipment.hourMeter)),
  };
}

export function convertEquipmentToCsvEquipment(equipment: IEquipment, reimport: boolean) {
  const equipmentDetails = {
    Name: equipment.equipmentName,
    Category: equipment.model.category.title,
    Make: !equipment.model.make.unknown ? equipment.model.make.title : '',
    Model: !equipment.model.unknown ? equipment.model.title : '',
    Year: equipment.model.year ? equipment.model.year.toString() : '',
  };

  const metricDetails = {
    'Hour Meter': !isNil(equipment.lastHours) ? equipment.lastHours.runningHours.toString() : '',
    'Hourly Rate': !isNilOrEmpty(equipment.costHistory)
      ? (getCurrentEquipmentCostHistory(equipment.costHistory ?? [])?.operatorCostRate ?? 0).toFixed(2)
      : '',
  };

  return reimport
    ? {
        [CSV_ID_COLUMN]: equipment.id,
        ...equipmentDetails,
        ...metricDetails,
      }
    : {
        ...equipmentDetails,
        'Type': equipment.model.category.type.title ?? '',
        ...metricDetails,
        'Created On': equipment.createdOn ? localizeDateTimeFromUtcISO(equipment.createdOn) : '',
        'Last Edited': equipment.updatedOn ? localizeDateTimeFromUtcISO(equipment.updatedOn) : '',
      };
}
