import useActiveMember from 'hooks/store/useActiveMember';
import { type IMember } from 'types/Member';
import {
  SortOrder,
  usePolicyMemberAcceptancesQuery,
  type PolicyMemberAcceptanceFilter,
  type PolicyMemberAcceptanceSort,
  type PolicyMemberAcceptancesQueryVariables,
} from '__generated__/graphql';
import { type TPolicyKey } from '../Policies/types';


export const useMembersPolicyMemberAcceptanceQuery = (memberIds: IMember['id'][], policyType: TPolicyKey) => {
  const filter: PolicyMemberAcceptanceFilter = {
    memberId: { contains: memberIds },
    policy: { type: { equal: policyType } },
    deletedOn: { isNull: true },
  };

  const sort: PolicyMemberAcceptanceSort = { policy: { version: SortOrder.Desc } };

  const queryOptions: PolicyMemberAcceptancesQueryVariables = {
    first: 1,
    filter,
    sort,
  };

  return usePolicyMemberAcceptancesQuery({ variables: queryOptions });
};

export const useActiveMemberPolicyMemberAcceptanceQuery = (policyType: TPolicyKey) => {
  const member = useActiveMember();
  const query = useMembersPolicyMemberAcceptanceQuery([member.id], policyType);

  return {
    ...query,
    data: query.data?.policyMemberAcceptances?.[0],
  };
};
