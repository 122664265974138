import gql from 'graphql-tag';
import { useApolloPaging } from 'hooks';
import { isNil, some } from 'lodash';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';

export default function useCheckDeletedOfEquipment() {
  const { getAll } = useApolloPaging();

  async function checkDeletedOfEquipment(equipmentIds?: string[] | null): Promise<boolean> {
    if (!isNil(equipmentIds)) {
      const results = await getAll<IEquipment & ICursorable>('equipment', {
        fetchPolicy: 'network-only',
        query: EQUIPMENT_DELETED_ON,
        variables: { filter: { id: { contains: equipmentIds } } },
      });
      return some(results, (equip) => !isNil(equip.deletedOn));
    } else {
      return false;
    }
  }

  return checkDeletedOfEquipment;
}

export const EQUIPMENT_DELETED_ON = gql`
  query queryEquipmentDeleted($first: Int, $after: String, $filter: EquipmentFilter) {
    equipment(first: $first, after: $after, filter: $filter) {
      id
      cursor
      deletedOn
    }
  }
`;
