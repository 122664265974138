import {
  Align,
  Button,
  DatePickerFormField,
  Form,
  IFormRender,
  Justify,
  Label,
  Loader,
  Row,
  Size,
  TextareaFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { IconButton, Well } from 'components';
import DailySignOffFormDialog from 'components/domain/daily-sign-off/DailySignOff/DailySignOffForm/DailySignOffFormDialog';
import { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import BreakMultiPickerFormField from 'components/domain/time-entry-break/BreakMultiPickerFormField/BreakMultiPickerFormField';
import {
  IBulkEntryInitialState,
  MULTIPLE,
  TCompositeBreak,
  TMultipleField,
} from 'components/domain/time-entry/bulk-edit/BulkEditEntryForm/BulkEditEntryForm';
import CostCodeTrashRow from 'components/domain/time-entry/bulk-edit/CostCodeTrashRow/CostCodeTrashRow';
import EquipmentTrashRow from 'components/domain/time-entry/bulk-edit/EquipmentTrashRow/EquipmentTrashRow';
import useBulkBreakSubmit from 'components/domain/time-entry/bulk-edit/hooks/useBulkBreakSubmit';
import ProjectTrashRow from 'components/domain/time-entry/bulk-edit/ProjectTrashRow/ProjectTrashRow';
import {
  BulkEditActionType,
  bulkEditReducer,
  reducerBulkEditInitialState,
} from 'components/domain/time-entry/bulk-edit/reducers/reducers';
import { coalesceBulkTimeEntryFields, fieldIsSet } from 'components/domain/time-entry/bulk-edit/util/utils';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import FeatureTimeFormField from 'components/domain/time/FeatureTimeFormField/FeatureTimeFormField';
import Typography from 'components/foundation/text/Typography/Typography';
import { useActiveMember, useBreak, useDefaultTimes, useOpenable, useTimeEntry, useTimeRounding } from 'hooks';
import { IEntryAndBreak } from 'hooks/models/time-entry-break/useBreak';
import _, { compact, isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useReducer, useRef, useState } from 'react';
import ClockAction from 'types/enum/ClockAction';
import ClockActionType from 'types/enum/ClockActionType';
import ITimeEntry from 'types/TimeEntry';
import { mapNotNil } from 'utils/collectionUtils';
import { combineDateAndTime, dateTimeFromISOKeepZone, nightShiftAdjustment } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { v_require, v_time_after_time, v_time_before_time } from 'utils/validations';
import useTimeActionsValidations from '../TimeActionsValidations/hooks/useTimeActionsValidations';
import TimeActionsValidations from '../TimeActionsValidations/TimeActionsValidations';
import { convertTimeActionFormNoSelectionsToNull, getActualEquipmentIdFromTimeActionEquipment } from '../utils/utils';
import './ClockOutAtForm.scss';

export interface IClockOutAtFormProps {
  timeEntry?: ITimeEntry | null;
  timeEntries: ITimeEntry[];
  memberIds: string[];
  formData: ITimeActionsFormData;
  onSubmit: (data: ITimeActionsFormData) => void;
  onChange?: (formData: ITimeActionsFormData | undefined) => void;
  className?: ClassName;
}

const ClockOutAtForm = ({
  className,
  timeEntry,
  timeEntries,
  memberIds,
  formData,
  onSubmit,
  onChange,
}: IClockOutAtFormProps) => {
  const classes = classNames('clock-out-at-form', className);

  const today = DateTime.local().startOf('day');
  const activeMember = useActiveMember();
  const dateDetails = useOpenable();
  const { launchDailySignOffOrSave, loaderDetails, timeEntryRange, onSignOffComplete, dailySignOffDetails } =
    useTimeActionsForm();
  const [formInfo, setFormInfo] = useState<ITimeActionsFormData>(formData);
  const lockDialogDetails = useOpenable();
  const conflictDialogDetails = useOpenable();
  const signatureViolationDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeActionsFormData>();
  const {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
  } = useTimeActionsValidations();

  useEffect(() => {
    inhibitsActions(formInfo);
    conflictWarning(
      { ...formInfo, timeRange: { startTime: formInfo.startTime, endTime: formInfo.endTime } },
      timeEntry?.id ? [timeEntry.id] : timeEntries.map((t) => t.id)
    );
    signatureWarning(formInfo);
  }, [formInfo, memberIds]);

  const [descriptionState, descriptionDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [projectState, projectDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [costCodeState, costCodeDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [equipmentState, equipmentDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const initialData = useRef<IBulkEntryInitialState>();
  const { clockOut } = useTimeEntry();
  const { endBreak } = useBreak();
  const { defaultStartTime, defaultEndTime } = useDefaultTimes();
  const [breaksCleared, setBreaksCleared] = useState(false);
  const { roundTime } = useTimeRounding();
  const handleBulkBreakSubmit = useBulkBreakSubmit();

  useEffect(() => {
    const parsedData = coalesceBulkTimeEntryFields(timeEntries);
    initialData.current = parsedData;

    const breaks = fieldIsSet(parsedData, 'breaks')
      ? (parsedData.breaks as TCompositeBreak[]).map<IBreakMultiPickerItem>((entryBreak) => {
          return {
            id: entryBreak.compositeId,
            timeRange: {
              startTime: dateTimeFromISOKeepZone(entryBreak.startTime),
              endTime: dateTimeFromISOKeepZone(entryBreak.endTime!),
            },
          };
        })
      : [];

    setFormInfo({
      ...formInfo,
      breaks,
    });

    descriptionDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.description } });
    projectDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.project } });
    costCodeDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.costCode } });
    equipmentDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.equipment } });
  }, [timeEntries, formData]);

  function clearBreaks() {
    setBreaksCleared(true);
  }

  function getMaxTimeEntryStart() {
    if (timeEntries && timeEntries.length) {
      const entryStarts = timeEntries.map((entry) => dateTimeFromISOKeepZone(entry.startTime));
      return (_.maxBy(entryStarts, (start) => start.hour * 60 + start.minute) as DateTime).set({
        second: 0,
        millisecond: 0,
      });
    } else {
      return defaultStartTime;
    }
  }

  function renderFormFields({ ...form }: IFormRender<ITimeActionsFormData>) {
    const clearDescription = () => {
      descriptionDispatch({ type: BulkEditActionType.CLEAR_CURRENT });
    };

    const onDescriptionChange = (description: string | null) => {
      descriptionDispatch({ type: BulkEditActionType.SET_CURRENT, payload: { value: description } });
    };

    const renderDescriptionUndo = () => {
      const onUndo = async (event: React.MouseEvent) => {
        event.stopPropagation();
        descriptionDispatch({ type: BulkEditActionType.UNDO_CURRENT });
      };

      return (
        <Typography className={'mx-2'} onClick={onUndo} color={'primary'} tag={'span'}>
          {t('Undo')}
        </Typography>
      );
    };

    function inRange(start: DateTime, end: DateTime, time: DateTime, key: keyof DateTime) {
      const keyedStart = start[key];
      const keyedEnd = end[key];
      const keyedTime = time[key];
      if (keyedStart && keyedEnd && keyedTime) {
        return keyedStart <= keyedTime && keyedTime <= keyedEnd;
      } else {
        return false;
      }
    }

    function validateBreakItem(item: IBreakMultiPickerItem): boolean {
      // Check that the item matches all the entries
      const itemStart = item.timeRange.startTime;
      const itemEnd = item.timeRange.endTime;
      if (!isNil(formInfo.startTime) && !isNil(formInfo.endTime)) {
        const formStart = formInfo.startTime;
        const formEnd = formInfo.endTime;

        return (
          inRange(formStart, formEnd, itemStart, 'hour') &&
          inRange(formStart, formEnd, itemStart, 'minute') &&
          inRange(formStart, formEnd, itemEnd, 'hour') &&
          inRange(formStart, formEnd, itemEnd, 'minute')
        );
      } else {
        // Since the form data is not set we need to check each entry
        return (
          timeEntries.every((entry) => {
            const entryStart = dateTimeFromISOKeepZone(entry.startTime);
            const entryEnd = dateTimeFromISOKeepZone(entry.endTime!);
            return (
              inRange(entryStart, entryEnd, itemStart, 'hour') &&
              inRange(entryStart, entryEnd, itemStart, 'minute') &&
              inRange(entryStart, entryEnd, itemEnd, 'hour') &&
              inRange(entryStart, entryEnd, itemEnd, 'minute')
            );
          }) ?? false
        );
      }
    }

    function getMinTimeEntryEnd() {
      if (timeEntries && timeEntries.length) {
        const entryEnds = timeEntries
          .map((entry) => dateTimeFromISOKeepZone(entry.endTime!))
          .filter((date) => date.isValid);
        if (isEmpty(entryEnds)) {
          return roundTime(DateTime.local(), ClockAction.CLOCK_OUT).set({ second: 0, millisecond: 0 });
        }
        const maxEnd = (_.max(entryEnds) as DateTime).set({
          second: 0,
          millisecond: 0,
        });
        const minEnd = (_.minBy(entryEnds, (end) => end.hour * 60 + end.minute) as DateTime).set({
          second: 0,
          millisecond: 0,
        });
        return combineDateAndTime(maxEnd, minEnd);
      } else {
        return defaultEndTime;
      }
    }

    const anyBreaksSet = !form.state.data.breaks || form.state.data.breaks.length === 0;
    const shouldShowDeleteAllBreaks = anyBreaksSet && initialData.current?.breaks === MULTIPLE && !breaksCleared;

    const isActiveMemberOnlyMember =
      form.state.data.members.length === 1 && form.state.data.members[0] === activeMember.id;

    const { startTime, endTime } = nightShiftAdjustment({
      startTime: getMaxTimeEntryStart(),
      endTime: getMinTimeEntryEnd(),
    });

    return (
      <>
        <Label>{t('End Time')}</Label>
        <div className="date-pickers-container mr-4">
          <div className="mr-4">
            <FeatureTimeFormField
              name="endTime"
              clockAction={ClockAction.CLOCK_OUT}
              form={form}
              validations={compact([
                { validation: v_require },
                {
                  validation: v_time_before_time,
                  args: {
                    time: roundTime(DateTime.local(), ClockAction.CLOCK_IN),
                    inclusive: true,
                    message: 'Invalid time',
                  },
                },
                {
                  validation: v_time_after_time,
                  args: {
                    time: roundTime(getMaxTimeEntryStart(), ClockAction.CLOCK_IN),
                    inclusive: true,
                    message: 'Invalid time',
                  },
                },
              ])}
            />
          </div>
          <DatePickerFormField
            name="endDate"
            form={form}
            isOpen={dateDetails.isOpen}
            disabledAfter={today.toJSDate()}
            onOpen={dateDetails.open}
            onClose={dateDetails.close}
            validations={[{ validation: v_require }]}
            onChange={(date) => {
              if (!isNil(date.value) && !isNil(formInfo.endTime)) {
                const endDate = DateTime.fromJSDate(date.value);

                form.setData('endTime', combineDateAndTime(endDate, formInfo.endTime));
              }
            }}
          />
        </div>

        <Label
          secondaryLabel={
            initialData.current?.breaks === MULTIPLE
              ? t('Adding breaks will remove all existing breaks and replace them.')
              : null
          }
        >
          {t('Breaks')}
        </Label>
        <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
          <BreakMultiPickerFormField
            name="breaks"
            className="pb-0 flex-grow"
            form={form}
            timeEntryStart={startTime}
            timeEntryEnd={endTime}
            hideDateOnBreakForm={true}
            displayDateOnEntryDifference={false}
            displayError={validateBreakItem}
          />
          {shouldShowDeleteAllBreaks ? (
            <Button type="secondary" size={Size.SMALL} onClick={clearBreaks}>
              {t('Delete All')}
            </Button>
          ) : null}
        </Row>

        <Label
          className="pt-5"
          secondaryLabel={
            projectState.clearable || costCodeState.clearable || equipmentState.clearable
              ? t('To clear a field, press the trash icon next to the field.')
              : null
          }
        >
          {t('Details')}
        </Label>

        <ProjectTrashRow
          form={form}
          bulkState={projectState}
          dispatch={projectDispatch}
          costCodeDispatch={costCodeDispatch}
          costCodeState={costCodeState}
        />

        <CostCodeTrashRow
          form={form}
          bulkState={costCodeState}
          dispatch={costCodeDispatch}
          projectState={projectState}
          projectDispatch={projectDispatch}
          projectId={form.state.data.project}
        />

        <EquipmentTrashRow
          form={form}
          bulkState={equipmentState}
          dispatch={equipmentDispatch}
          considerEquipmentRequirements={isActiveMemberOnlyMember}
        />
        <Label
          secondaryLabel={descriptionState.clearable ? t('Press the trash icon to clear all descriptions.') : null}
        >
          {t('Description')}
          {descriptionState.undoable ? renderDescriptionUndo() : undefined}
        </Label>
        <Row className="trash-row">
          <TextareaFormField
            name="description"
            placeholder={
              descriptionState.original === MULTIPLE && descriptionState.current === null
                ? t('All descriptions will be deleted.')
                : undefined
            }
            form={form}
            restrictTo={{ maxLength: 5000 }}
            onChange={onDescriptionChange}
          />
          {descriptionState.clearable && (
            <IconButton className="trash-icon" onClick={clearDescription} svg={DeleteIcon} />
          )}
        </Row>

        <Button type="primary" onClick={form.handleSubmit}>
          {t('Save')}
        </Button>
      </>
    );
  }

  function fieldIsSetValue(field: TMultipleField<string>) {
    return field !== null && field !== MULTIPLE;
  }

  async function handleSubmit(data: ITimeActionsFormData) {
    if (
      (checkValidationsPayload.current.lock && hasLockDate) ||
      (checkValidationsPayload.current.conflict && hasConflictingEntries) ||
      (checkValidationsPayload.current.signature && hasSignatureViolation)
    ) {
      preValidationFormData.current = data;
      launchValidationAlertsOrSave();
      return;
    }

    if (loaderDetails.isOpen) {
      // Action is already being performed.
      return;
    }

    loaderDetails.open();

    if (data) {
      clockOutAtFromForm(data);
    } else {
      onSubmit(data);
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onConflictContinue() {
    setHasConflictingEntries(false);
    checkValidationsPayload.current.conflict = false;
    conflictDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onSignatureViolationContinue() {
    setHasSignatureViolation(false);
    checkValidationsPayload.current.signature = false;
    signatureViolationDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function launchValidationAlertsOrSave() {
    // lock date

    if (checkValidationsPayload.current.lock && hasLockDate && preValidationFormData.current) {
      const start =
        preValidationFormData.current.startDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.startDate.value!);
      const end =
        preValidationFormData.current.endDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.endDate.value!);
      timeEntryRange.current = {
        startTime: preValidationFormData.current.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        endTime: preValidationFormData.current.timeRange.endTime!.set({
          year: end.year,
          month: end.month,
          day: end.day,
        }),
      };
      lockDialogDetails.open();
      return;
    }

    // conflicts alert

    if (checkValidationsPayload.current.conflict && hasConflictingEntries) {
      conflictDialogDetails.open();
      return;
    }

    // signatures alert

    if (checkValidationsPayload.current.signature && hasSignatureViolation) {
      signatureViolationDialogDetails.open();
      return;
    }
  }

  async function clockOutAtFromForm(data: ITimeActionsFormData) {
    const updatedData = convertTimeActionFormNoSelectionsToNull(data);

    if (timeEntry) {
      await performClockOutAt(updatedData, [timeEntry], onSubmit);
    } else if (timeEntries.length > 0) {
      await performClockOutAt(updatedData, timeEntries, onSubmit);
    } else {
      onSubmit(updatedData);
    }
  }

  async function performClockOutAt(
    data: ITimeActionsFormData,
    timeEntries: ITimeEntry[],
    onSubmit: (data: ITimeActionsFormData) => void
  ) {
    const save = async () => {
      const end = data.endDate.value === null ? today : DateTime.fromJSDate(data.endDate.value!);
      const initial = initialData.current as IBulkEntryInitialState;

      const openEntryBreaks = mapNotNil(timeEntries, (entry) => {
        const openBreak = _.findLast(entry.breaks, (brk) => _.isNil(brk.endTime));
        if (openBreak) {
          const item: IEntryAndBreak = {
            entry: entry,
            brk: openBreak,
          };
          return item;
        }
        return null;
      });

      if (!isEmpty(openEntryBreaks)) {
        await endBreak(
          openEntryBreaks,
          data.endTime!.setZone(today.zone).set({
            year: end.year,
            month: end.month,
            day: end.day,
          })
        );
      }

      const entryLogs = await clockOut(
        timeEntries,
        data.endTime!.setZone(today.zone).set({
          year: end.year,
          month: end.month,
          day: end.day,
        }),
        ClockActionType.CLOCK_OUT_AT,
        descriptionState.current !== MULTIPLE ? (descriptionState.current as string) : undefined,
        projectState.current !== MULTIPLE ? (projectState.current as string) : undefined,
        costCodeState.current !== MULTIPLE ? (costCodeState.current as string) : undefined,
        equipmentState.current !== MULTIPLE
          ? getActualEquipmentIdFromTimeActionEquipment(equipmentState.current as string)
          : undefined
      );

      await handleBulkBreakSubmit(data.breaks, breaksCleared, initial.breaks, entryLogs, timeEntries);

      onSubmit(data);
    };
    if (_.isEmpty(data.members)) {
      data.members = timeEntries.map((e) => e.memberId);
    }
    await launchDailySignOffOrSave(data, save);
  }

  function onFormChange(changedData: ITimeActionsFormData | undefined) {
    if (changedData) {
      setFormInfo(changedData);
    }

    onChange?.(changedData);
  }

  return (
    <>
      <div>
        <div className="px-8 pb-8">
          <div className="mb-6">
            {timeEntries.length > 1 && (
              <Well theme={Theme.PRIMARY} className="mb-5">
                {t(
                  'If a field is left blank on the form below, that field will not be changed and will retain whatever value it had before the edit.'
                )}
              </Well>
            )}
            <Form
              data={
                {
                  ...formInfo,
                  description: fieldIsSetValue(descriptionState.current) ? descriptionState.current : null,
                  project: fieldIsSetValue(projectState.current) ? projectState.current : null,
                  costCode: fieldIsSetValue(costCodeState.current) ? costCodeState.current : null,
                  equipment: fieldIsSetValue(equipmentState.current) ? equipmentState.current : null,
                } as ITimeActionsFormData
              }
              onChange={onFormChange}
              onSubmit={handleSubmit}
              render={renderFormFields}
              className={classes}
              allowMultipleSubmissions={true}
            />
          </div>
        </div>
        <Loader isOpen={loaderDetails.isOpen && !dailySignOffDetails.isOpen} />
        {timeEntryRange.current && onSignOffComplete.current && (
          <DailySignOffFormDialog
            member={activeMember}
            timeRange={timeEntryRange.current}
            fromReport={false}
            isOpen={dailySignOffDetails.isOpen}
            onClose={dailySignOffDetails.close}
            onComplete={onSignOffComplete.current}
          />
        )}
      </div>
      <TimeActionsValidations
        lockDate={timeEntryRange.current ? timeEntryRange.current.startTime : undefined}
        lockMemberIds={formInfo.members}
        lockDateType={LockDateDialogType.ADD}
        isLockDetailsOpen={lockDialogDetails.isOpen}
        onLockDetailsClose={lockDialogDetails.close}
        onMoveLockDate={onMoveLockDate}
        isConflictDetailsOpen={conflictDialogDetails.isOpen}
        onConflictDetailsClose={conflictDialogDetails.close}
        onConflictContinue={onConflictContinue}
        isSignatureViolationDetailsOpen={signatureViolationDialogDetails.isOpen}
        onSignatureViolationDetailsClose={signatureViolationDialogDetails.close}
        onSignatureViolationContinue={onSignatureViolationContinue}
      />
    </>
  );
};

ClockOutAtForm.defaultProps = {
  timeEntry: null,
  timeEntries: [],
  memberIds: [],
};

export default ClockOutAtForm;
