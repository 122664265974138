import { Align, Justify, Row, Tray } from '@busybusy/webapp-react-ui';
import { RefreshIcon, SettingsIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IconButton } from 'components';
import { useOrganization } from 'hooks';
import { t } from 'i18next';
import { useFeatureFlags } from 'utils/features';
import './DashboardGridHeader.scss';

export interface IDashboardGridHeaderProps {
  onSettingsClick: () => void;
  onRefreshClick: () => void;
  refreshedMinutesAgo: number;
  className?: ClassName;
}

export default function DashboardGridHeader({
  onSettingsClick,
  onRefreshClick,
  className,
  refreshedMinutesAgo,
}: IDashboardGridHeaderProps) {
  const organization = useOrganization();
  const minutesString = refreshedMinutesAgo === 1 ? 'minute' : 'minutes';
  const DASHBOARD_WEB_REFRESH = useFeatureFlags('DASHBOARD_WEB_REFRESH');

  return (
    <Row
      justify={Justify.SPACE_BETWEEN}
      align={Align.CENTER}
      className={classNames('dashboard-grid-header', className)}
    >
      <h1>{organization.organizationName}</h1>
      <Tray spacing={2}>
        {DASHBOARD_WEB_REFRESH &&
          (refreshedMinutesAgo < 1 ? t('Updated just now') : t(`Updated ${refreshedMinutesAgo} ${minutesString} ago`))}
        <IconButton svg={RefreshIcon} onClick={onRefreshClick} />
        <IconButton svg={SettingsIcon} onClick={onSettingsClick} />
      </Tray>
    </Row>
  );
}
