import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DashboardActiveList from 'components/foundation/dashboard/dashboard-list/DashboardActiveList/DashboardActiveList';
import { IDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import { DashboardTheme } from 'containers/dashboard/types/types';
import { useEmployeeDashboardNavigation } from 'hooks';
import { Nullable } from 'types/util/Nullable';
import { colors } from 'utils/constants/colors';
import { t } from 'utils/localize';
import DashboardCardSpinner from '../../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../../DashboardErrorCard/DashboardErrorCard';

export interface IActiveMembersDashboardCardContentProps {
  data: IDashboardListRow[];
  loading: boolean;
  onReload: () => void;
  onDetailClick: () => void;
  theme: DashboardTheme;
  error: string | null;
  title: Nullable<string>;
  className?: string | null;
}

export default function ActiveMembersDashboardCardContent({
  data,
  loading,
  error,
  title,
  theme,
  onDetailClick,
  className,
}: IActiveMembersDashboardCardContentProps) {
  const employeeDashboardNavigation = useEmployeeDashboardNavigation();

  function onRowClick(id: string) {
    employeeDashboardNavigation({ employee_id: id, exitToLegacy: false });
  }

  function renderList() {
    return data && data?.length > 0 ? (
      <DashboardActiveList rowItems={data} onRowClick={onRowClick} theme={theme} />
    ) : null;
  }

  const classes = classNames('active-members-dashboard-card', className);

  return (
    <>
      {error === null ? (
        <DashboardContentCard
          subheaderValue={title}
          tabColor={colors.primary}
          theme={theme}
          className={classes}
          rightLabel={t('View Report')}
          onRightLabelClick={onDetailClick}
        >
          {loading ? <DashboardCardSpinner /> : renderList()}
        </DashboardContentCard>
      ) : (
        <DashboardErrorCard theme={theme} onClick={onDetailClick} />
      )}
    </>
  );
}
