import { Icon } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { ReactNode } from 'react';
import * as React from 'react';

export interface IEmberNavItemProps {
  children?: ReactNode;
  href: string;
  enabled: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  className?: ClassName;
}

const EmberNavItem = (props: IEmberNavItemProps) => {
  const { children, href, enabled, icon, className } = props;

  const classes = classNames('ember-nav-item', 'nav-item', className);

  if (!enabled) {
    return null;
  }

  return (
    <a href={href} className={classes} rel="noopener noreferrer">
      {icon && <Icon svg={icon} />}
      {children && <span className="nav-item-label">{children}</span>}
    </a>
  );
};

export default EmberNavItem;
