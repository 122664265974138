const TIMESHEETS_REPORT_VIEWED = 'Report Viewed';
const TIMESHEETS_FILTER_SET = 'Filter Set';
const TIMESHEETS_TABLE_COLUMNS_SET = 'Table Columns Set';
const TIMESHEETS_EVENTS = 'Events';
const TIMESHEETS_GRAPH_TYPE_SET = 'Graph Type Set';
const TIMESHEETS_LOCATION_HISTORY_FILTER_TYPE_SET = 'Location History Filter Type Set';

const timesheetsAnalyticsConstants = {
  TIMESHEETS_REPORT_VIEWED,
  TIMESHEETS_FILTER_SET,
  TIMESHEETS_TABLE_COLUMNS_SET,
  TIMESHEETS_EVENTS,
  TIMESHEETS_GRAPH_TYPE_SET,
  TIMESHEETS_LOCATION_HISTORY_FILTER_TYPE_SET,
};

export default timesheetsAnalyticsConstants;
