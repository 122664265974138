import { t } from "i18next";

export function getDashboardItemTitleFromKey(key: string): string {
    switch (key) {
        case 'clockStatus':
            return t('Clock Status Summary');
        case 'laborHours':
            return t('Total Hours');
        case 'laborCosts':
            return t('Total Cost');
        case 'overtimeHours':
            return t('Overtime Hours');
        case 'overtimeCosts':
            return t('Overtime Cost');
        case 'doubleTimeHours':
            return t('Double Time Hours');
        case 'doubleTimeCosts':
            return t('Double Time Cost');
        case 'dailySignIn':
            return t('Daily Sign In');
        case 'dailySignatureAlerts':
            return t('Daily Sign Off');
        case 'hourMeter':
            return t('Machine Hours');
        case 'equipmentHours':
            return t('Equipment Hours');
        case 'equipmentCosts':
            return t('Equipment Cost');
        case 'photosAndNotes':
            return t('Photos & Notes (Uploads)');
        case 'dailyReports':
            return t('Field Reports');
        case 'progressEntriesReport':
            return t('Progress Submissions');
        case 'budgets':
            return t('Budgets');
        case 'photoVerification':
            return t('Photo Verification');
        case 'mostActiveEmployees':
            return t('Most Active Employees');
        case 'mostActiveProjects':
            return t('Most Active Projects');
        case 'mostActiveCostCodes':
            return t('Most Active Cost Codes');
        case 'mostActiveEquipment':
            return t('Most Active Equipment');
        case 'timeOff':
            return t('Time Off');
        case 'timeLogs':
            return t('Recent Activity');
        default:
            throw('dashboard key not recognized')
    }
}