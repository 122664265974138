import qs from 'query-string';
import { useCallback } from 'react';
import { useLegacyNavigationPayload } from '.';

export default function useGetLegacyNavigationUrl(pathname: string) {
  const { baseUrl, token } = useLegacyNavigationPayload();

  return useCallback(
    (params?: object) => {
      const queryString = qs.stringify({ ...params });
      return `${baseUrl}${pathname}?authorize=${token}${queryString ? `&${queryString}` : ''}`;
    },
    [baseUrl, pathname, token]
  );
}
