import { isType } from 'utils/typeguard';
import { ColumnSettings, IColumnFormSection, IColumnItem } from '../ColumnForm/ColumnForm';

export default function aggregateTableColumns<K extends string = string>(settings: ColumnSettings<K>) {
  return settings.flatMap<IColumnItem<K>>((setting): IColumnItem<K> | Array<IColumnItem<K>> => {
    if (isType<IColumnFormSection<K>>(setting, 'columns')) {
      return setting.columns;
    } else {
      return setting;
    }
  });
}
