import gql from 'graphql-tag';

export const RECENT_PHOTOS_QUERY = gql`
  query recentPhotosMediaEntriesQuery($filter: MediaEntryFilter, $first: Int, $after: String) {
    mediaEntries(first: $first, after: $after, filter: $filter, sort: [{ createdOn: desc }]) {
      id
      mediaCollectionId
      fileUrl
      entryType
      location {
        id
        latitude
        longitude
        timeGmt
        timeLocal
        timeDst
      }
      captureDate
      createdOn
      deletedOn
      comment
      collection {
        id
        deletedOn
        description
        project {
          id
          title
          rootProject {
            id
            title
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
          archivedOn
        }
        createdByMember {
          id
          firstName
          lastName
          position {
            id
            level
          }
          memberGroupId
        }
        tagLinks {
          id
          tag {
            id
            title
          }
        }
      }
    }
  }
`;
