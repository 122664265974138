import { useApolloClient } from '@apollo/client';
import { COST_CODE_NAME_QUERY } from 'apollo/queries/cost-code-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Capsule from 'components/foundation/Capsule/Capsule';
import { useCallback, useEffect, useState } from 'react';
import ICostCode from 'types/CostCode';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { getCostCodeDisplay } from 'utils/stringUtils';

export interface ICostCodeCapsulesProps {
  className?: ClassName;
  costCodeIds: string[];
  printVersion?: boolean;
}

const CostCodeCapsules = ({ className, costCodeIds, printVersion }: ICostCodeCapsulesProps) => {
  const classes = classNames('cost-code-capsules', className);
  const client = useApolloClient();
  const [costCodes, setCostCode] = useState<ICostCode[]>([]);
  const includesNoCostCode = costCodeIds.includes(remainingDataItemId);

  useEffect(() => {
    if (!includesNoCostCode) {
      getFilterCostCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costCodeIds]);

  const getFilterCostCode = useCallback(async () => {
    const results = await client.query<{ costCodes: ICostCode[] }>({
      query: COST_CODE_NAME_QUERY,
      variables: { filter: { id: { contains: costCodeIds } } },
      fetchPolicy: 'network-only',
    });
    setCostCode(results.data.costCodes ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costCodeIds]);

  function renderList() {
    if (printVersion) {
      return costCodes.map((item) => (
        <h2 key={item.id} className="mb-3">
          {getCostCodeDisplay(item)}
        </h2>
      ));
    } else {
      return costCodes.map((item) => <Capsule key={item.id} className={classes} title={getCostCodeDisplay(item)} />);
    }
  }

  function renderNoCostCode() {
    if (printVersion) {
      return (
        <h2 key={remainingDataItemId} className="mb-3">
          {t('No Cost Code')}
        </h2>
      );
    } else {
      return <Capsule key={remainingDataItemId} className={classes} title={t('No Cost Code')} />;
    }
  }

  return (
    <>
      {renderList()}
      {includesNoCostCode && renderNoCostCode()}
    </>
  );
};

export default CostCodeCapsules;
