import { Bar, Justify, List, ListItem, Size, useCheckedCollection } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { LazyLoader } from 'components';
import { ILazyLoaderProps } from 'components/foundation/LazyLoader/LazyLoader';
import ClosableListItem from 'components/foundation/list-item/ClosableListItem/ClosableListItem';
import TabBar, { ITabBarProps } from 'components/foundation/TabBar/TabBar';
import { t } from 'i18next';
import { drop, first, isEmpty } from 'lodash';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IIdable from 'types/Idable';
import CheckItem from '../CheckList/CheckItem/CheckItem';
import { ICheckListProps } from '../CheckList/CheckList';
import './LazyLoadCheckList.scss';

export interface ILazyLoadCheckListProps<T extends IIdable<string>>
  extends Omit<ICheckListProps<T, string>, 'items' | 'style' | 'ref'>,
    Omit<ILazyLoaderProps<T & { cursor?: string }>, 'renderRow' | 'sectionSize'> {
  isCheckedAll?: boolean;
  onCheckAll?: (isChecked: boolean) => void;
}

function LazyLoadCheckList<T extends IIdable<string> & { cursor?: string }>(props: ILazyLoadCheckListProps<T>) {
  const {
    hover,
    stroke,
    stripe,
    renderItem,
    onCheck,
    checkedItems,
    onCheckAll,
    isCheckedAll,
    data,
    didLoad,
    className,
    ...lazyLoaderProps
  } = props;

  const [t] = useTranslation();
  const [view, setView] = useState<'all' | 'selected'>('all');

  function onPotentialPartialDataCheck(ids: string[]) {
    // We need to check if there's checked items that are unchecked because `onEnhancedCheck` uses our current data
    const filteredOutChecks = checkedItems.filter((checked) => !data.some((datum) => datum.id === checked));
    onCheck(filteredOutChecks.concat(ids));
  }

  const { onCheck: onEnhancedCheck } = useCheckedCollection(data, checkedItems, 'id', onPotentialPartialDataCheck);

  function preventDefault(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
  }

  function renderRow(value: T) {
    if (value.id === 'ALL') {
      return (
        <ListItem key={'SELECT_ALL'} clickable={true} onClick={onCheckAllListItemClick}>
          <CheckItem
            className="pl-6"
            onChange={onCheckAllChange}
            onClick={preventDefault}
            checked={isCheckedAll ? true : false}
          >
            <Bar size={Size.MEDIUM} justify={Justify.FLEX_START}>
              <span className="lazy-load-check-list-item-text">{t('Select All')}</span>
            </Bar>
          </CheckItem>
        </ListItem>
      );
    } else {
      const isCurrentlyChecked = checkedItems.some((checked) => checked === value.id);
      const onItemCheck = (isChecked: boolean) => {
        onEnhancedCheck(value.id, isChecked);
      };

      const onListItemCheck = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onEnhancedCheck(value.id, !isCurrentlyChecked);
      };

      return (
        <ListItem key={value.id} clickable={true} onClick={onListItemCheck}>
          <CheckItem className="pl-6" onChange={onItemCheck} checked={isCurrentlyChecked}>
            <span className="lazy-load-check-list-item-text">{renderItem(value)}</span>
          </CheckItem>
        </ListItem>
      );
    }
  }

  function onCheckAllListItemClick(e: React.MouseEvent) {
    if (onCheckAll) {
      e.preventDefault();
      e.stopPropagation();
      onCheckAll(!isCheckedAll);
    }
  }

  function onCheckAllChange(isChecked: boolean) {
    if (onCheckAll) {
      onCheckAll(isChecked);
    }
  }

  function onDidLoad(items: T[], err: boolean, loadedAll: boolean) {
    const firstElt = first(items);

    if (firstElt && firstElt.id === 'ALL') {
      didLoad(drop(items), err, loadedAll);
    } else {
      didLoad(items, err, loadedAll);
    }
  }

  const hasCheckAll = onCheckAll !== undefined;
  const dataWithAll = useMemo(() => {
    if (!hasCheckAll) {
      return data;
    }

    return (!isEmpty(data) && data[0].id !== 'ALL' ? [{ id: 'ALL' }, ...data] : data) as T[];
  }, [data, hasCheckAll]);

  const checked = useMemo(() => {
    const checkedSet = new Set(checkedItems);
    return data?.filter((item) => checkedSet.has(item.id)) ?? [];
  }, [checkedItems, data]);

  const classes = classNames('lazy-load-check-list', className);

  return (
    <div className={classes}>
      <TabBar {...useTabBar(view, setView, checkedItems.length)} />
      <List className={classes} hover={hover} stroke={stroke} stripe={stripe}>
        <LazyLoader
          renderRow={renderRow}
          data={dataWithAll}
          didLoad={onDidLoad}
          {...lazyLoaderProps}
          className={view !== 'all' ? 'hidden' : undefined}
        />
      </List>
      <List className={view !== 'selected' ? 'hidden' : undefined} hover={false} stroke={stroke} stripe={stripe}>
        {checked.map((checkedItem) => {
          return (
            <ClosableListItem
              clickable={false}
              key={checkedItem.id}
              onClose={() => {
                // If they're closing the last item in the `Selected` list there's no reason for them to be there
                if (checkedItems.length === 1) {
                  setView('all');
                }
                onEnhancedCheck(checkedItem.id, false);
              }}
            >
              {renderItem(checkedItem)}
            </ClosableListItem>
          );
        })}
      </List>
    </div>
  );
}

type TabBarOption = 'all' | 'selected';

function useTabBar(
  view: TabBarOption,
  setView: (view: TabBarOption) => void,
  checkedItemCount: number
): ITabBarProps<TabBarOption> {
  return useMemo(
    () => ({
      tabs: [
        { label: t('All'), displayValue: null, tabValue: 'all' },
        {
          label: t('Selected'),
          displayValue: checkedItemCount.toString(),
          tabValue: 'selected',
          disabled: checkedItemCount === 0,
        },
      ],
      selectedTab: view,
      onTabSelection: setView,
    }),
    [checkedItemCount, setView, view]
  );
}

LazyLoadCheckList.defaultProps = {
  offset: 100,
  renderSize: 20,
  loadAll: false,
  scroller: 'self',
};

export default LazyLoadCheckList;
