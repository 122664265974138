import { OrganizationSignOffQuestion } from "__generated__/graphql";
import { isEmpty, isNil } from "lodash";
import { IMemberSignOffAnswer } from "types/SafetySignature";
import { IOrganizationQuestionTitleAndLink } from "./organizationSignInQuestionUtils";

export function getAnswersForQuestion(
  answers: IMemberSignOffAnswer[],
  question: OrganizationSignOffQuestion,
  allQuestions: OrganizationSignOffQuestion[]
): IMemberSignOffAnswer[] {
  const newAnswers = answers.filter(
    (a) =>
      a.questionTitle === question.questionTitle &&
      (!isNil(a.audienceType)
        ? (a.audienceType === 'position' && a.positionId === question.positionId) ||
          a.audienceType === question.audienceType
        : true)
  );

  if (isEmpty(newAnswers)) {
    return answers
      .filter((a) => a.questionTitle === question.questionTitle)
      .filter((a) => !hasValidAnswersForQuestion(a, allQuestions));
  } else {
    return newAnswers;
  }
}

export function hasValidAnswersForQuestion(answer: IMemberSignOffAnswer, questions: OrganizationSignOffQuestion[]) {
  return questions.some(
    (question) =>
      answer.questionTitle === question.questionTitle &&
      (!isNil(answer.audienceType)
        ? (answer.audienceType === 'position' && answer.positionId === question.positionId) ||
          answer.audienceType === question.audienceType
        : true)
  );
}

export function getQuestionTitleAndLinks(question: OrganizationSignOffQuestion): IOrganizationQuestionTitleAndLink {
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi;
  const matches = question.questionTitle.match(expression);
  const newTitle = question.questionTitle.replace(expression, '');
  return {
    title: newTitle,
    links: matches?.toString()?.split(' '),
  };
}