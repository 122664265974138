import { ProgressBudget } from '__generated__/graphql';
import { groupBy } from 'lodash';
import IProjectCostCodeQuantity from 'types/ProjectCostCodeQuantity';
import { sumByKey } from 'utils/collectionUtils';
import { getSafeNumber } from 'utils/numberUtils';
import useGetProgressBudget from './useGetProgressBudget';
import useGetProgressBudgetActuals from './useGetProgressBudgetActuals';

export interface IProgressBudgetActuals {
  budget: number;
  actual: number;
  quantityBudget: number;
  quantityActual: number;
}

export default function useGetProgressBudgetActualsData() {
  const getAllProgressBudgetData = useGetProgressBudget();
  const getProgressActualsData = useGetProgressBudgetActuals();

  async function getProgressBudgetActualsData(
    projectId: string,
    costCodeIds: string[],
    includeSubprojects: boolean
  ): Promise<IProgressBudgetActuals | null> {
    const progressBudgets = await getAllProgressBudgetData(projectId, costCodeIds, includeSubprojects);

    const progressActuals = await getProgressActualsData(projectId, costCodeIds, includeSubprojects);

    return aggregateProgressBudgetsToActuals(progressBudgets, progressActuals);
  }

  return getProgressBudgetActualsData;
}

export function aggregateProgressBudgetsToActuals(
  budgets: ProgressBudget[],
  progressActuals: IProjectCostCodeQuantity[]
): IProgressBudgetActuals {
  let budgeted = 0;
  let actual = 0;
  let budgetedQuantity = 0;

  const quantityActual = sumByKey(progressActuals, 'amount') ?? 0;
  const groupedByProjectIdCostCodeId = groupBy(
    progressActuals,
    ({ projectId, costCodeId }) => `${projectId}:${costCodeId}`
  );

  budgets.forEach((budget) => {
    const actuals = groupedByProjectIdCostCodeId[`${budget.projectId}:${budget.costCodeId}`];
    const quantity = sumByKey(actuals, 'amount');

    const budgetValue = getSafeNumber(budget.value);
    const budgetQuantity = getSafeNumber(budget.quantity);

    budgeted += budgetValue;

    const progressActual = getProgressActual({ budgetValue, budgetQuantity, quantity });
    actual += progressActual;

    budgetedQuantity += budgetQuantity;
  });

  return {
    budget: budgeted,
    actual,
    quantityBudget: budgetedQuantity,
    quantityActual,
  } as IProgressBudgetActuals;
}

export function getProgressActual({
  budgetValue,
  budgetQuantity,
  quantity,
}: {
  budgetValue: number;
  budgetQuantity: number;
  quantity: number;
}) {
  const progressRate = budgetQuantity == 0.0 ? 0.0 : budgetValue / budgetQuantity;
  return quantity * progressRate;
}
