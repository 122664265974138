enum TimeRangeType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  PAY_PERIOD = 'Pay Period',
  CUSTOM = 'Custom',
  ALL_TIME = 'All Time',
  YEARLY = 'Yearly',
}

export default TimeRangeType;
