import { Button, CheckboxFormField, Form, IFormRender } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { defaultShowGPSConnections } from './ShowGPSConnections';

export interface ILocationHistorySettingsFormProps {
  className?: ClassName;
  onSubmit: (showConnections: boolean) => void;
}

interface IFormData {
  showConnections: boolean;
}

const LocationHistorySettingsForm = (props: ILocationHistorySettingsFormProps) => {
  const { className, onSubmit } = props;
  const memberSettings = useMemberSettings();
  const [showConnections, setShowConnections] = useState(
    memberSettings?.web?.features?.showGpsConnections ?? defaultShowGPSConnections.showConnections
  );

  const initialFormData = useRef({
    showConnections: showConnections,
  });

  function renderFormFields(form: IFormRender<IFormData>) {
    return (
      <>
        <CheckboxFormField
          name="showConnections"
          form={form}
          label={
            <HeaderText title={t('Show Connections')}>
              <span>
                {t('Draw lines connecting the location pins to show the general route traveled by the employee.')}
              </span>
            </HeaderText>
          }
        />
        <Button className="mt-4" onClick={form.handleSubmit} type="primary">
          {t('Update')}
        </Button>
      </>
    );
  }

  async function onFormSubmit(data: IFormData) {
    setShowConnections(data.showConnections);
    onSubmit(data.showConnections);
  }

  const classes = classNames('location-history-settings-form', 'py-8', 'px-8', className);

  return <Form className={classes} onSubmit={onFormSubmit} render={renderFormFields} data={initialFormData.current} />;
};

export default LocationHistorySettingsForm;
