import {
  ActiveMembersQueryQuery,
  ActiveMembersQueryQueryVariables,
  MemberPermissions,
  OperationType,
} from '__generated__/graphql';
import { BASIC_MEMBER_TIME_METRICS_FRAGMENT } from 'apollo/queries/jit-member-queries';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { IDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import { useDashboardTimeRange } from 'containers/dashboard/hooks';
import { convertTotalsToDashboardListRow, getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import gql from 'graphql-tag';
import { useAsync } from 'hooks';
import useGraphQLPaging from 'hooks/graphql/useGraphQLPaging/useGraphQLPaging';
import { useCallback, useEffect, useMemo } from 'react';
import { mapNotNull, sumByKeys } from 'utils/collectionUtils';
import { getFullNameFromMember } from 'utils/stringUtils';

export const ACTIVE_MEMBERS_QUERY = gql`
  query ActiveMembersQuery(
    $first: Int
    $after: String
    $filter: MemberFilter
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    members(first: $first, after: $after, filter: $filter, sort: [{ firstName: asc }, { lastName: asc }]) {
      cursor
      id
      firstName
      lastName
      memberLaborMetrics(interval: custom, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicMemberLaborMetrics
        __typename
      }
    }
  }
  ${BASIC_MEMBER_TIME_METRICS_FRAGMENT}
`;

export function useActiveMemberMainDashboardData(setting: IDashboardSettingsItem) {
  const getAll = useGraphQLPaging();
  const timeFrame = setting.options?.time ?? 'pastFourteen';
  const timeRange = useDashboardTimeRange(timeFrame);

  const getData = useCallback(() => {
    return getAll<ActiveMembersQueryQuery, ActiveMembersQueryQueryVariables>(
      {
        document: ACTIVE_MEMBERS_QUERY,
        variables: {
          filter: {
            permissions: {
              permissions: [MemberPermissions.TimeEvents],
              operationType: OperationType.Or,
            },
            archivedOn: { isNull: true },
            hasTime: {
              startTime: timeRange.startTime.toISO({
                suppressMilliseconds: true,
                includeOffset: false,
              }),
              endTime: timeRange.endTime.toISO({
                suppressMilliseconds: true,
                includeOffset: false,
              }),
              includeOpenEntry: false,
            },
          },
          metricsStartDate: timeRange.startTime.toISODate(),
          metricsEndDate: timeRange.endTime.toISODate(),
        },
      },
      'members'
    );
  }, [getAll, timeRange.endTime, timeRange.startTime]);

  const { execute, loading, error, data } = useAsync(getData, false);
  const rowData: IDashboardListRow[] = useMemo(() => {
    const titleToTotal = mapNotNull(data ?? [], (member) => {
      // do not include pto
      const total = sumByKeys(member.memberLaborMetrics, 'rt', 'ot', 'dt');

      return total > 0
        ? {
            id: member.id,
            title: getFullNameFromMember(member),
            total,
          }
        : null;
    });

    return convertTotalsToDashboardListRow(titleToTotal);
  }, [data]);

  useEffect(() => {
    execute();
  }, [execute, timeFrame]);

  const title = getDashboardTimeFrameTitle(setting.title, timeFrame);
  return { data: rowData, title, setting, loading, error, execute };
}
