enum MemberLocationType {
  ALL = 'all',
  START = 'start',
  END = 'end',
  BREAK_START = 'break_start',
  BREAK_END = 'break_end',
  LOCATION_UPDATE = 'location_update',
}

export default MemberLocationType;
