import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import useMetricAggregates, { MetricQueryProvider } from 'hooks/aggregates/useMetricAggregates';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ITimeRange from 'types/TimeRange';
import { ReportType } from 'utils/aggregateUtils';
import { getGraphCostDataFromMetrics, getGraphTimeDataFromMetrics } from 'utils/jitMetricUtils';

export default function useAggregateGraphData(
  timeRange: ITimeRange<DateTime>,
  timeFrame: DashboardTimeFrame,
  reportType: ReportType,
  queryProvider: MetricQueryProvider,
  executeImmediately: boolean
) {
  const { timeRanges } = useDashboardGraphDates(timeFrame)!;

  const {
    data,
    execute: refetch,
    loading: isLoading,
    error,
  } = useMetricAggregates(
    timeRange,
    queryProvider,
    timeFrame === 'weekly' ? 4 : 2,
    timeFrame === 'payPeriod',
    executeImmediately
  );

  const parsedData = useMemo(() => {
    if (isNil(data)) {
      return [];
    }

    switch (reportType) {
      case ReportType.SECONDS:
        return getGraphTimeDataFromMetrics(data, timeRanges);
      case ReportType.COST:
        return getGraphCostDataFromMetrics(data, timeRanges);
    }
  }, [data, timeRange, timeFrame]);

  return useMemo(() => ({ data: parsedData, refetch, isLoading, error }), [parsedData, refetch, isLoading, error]);
}
