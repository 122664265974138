import { useOrganization } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { Optional } from 'types/util/Optional';
import { getPayPeriodTimeRange } from 'utils/payPeriodUtils';
import { getWeekRangeForTime } from 'utils/timeRangeUtils';

export interface IUseTimeRangePayload {
  back: () => void;
  forward: () => void;
  backEnabled: () => boolean;
  forwardEnabled: () => boolean;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
}

export default function useTimeRange(
  timeRangeType: TimeRangeType,
  range?: ITimeRange<DateTime> | null,
  minimumTime?: DateTime | null,
  maximumTime?: DateTime | null,
  zone: 'system' | 'utc' = 'system'
): IUseTimeRangePayload {
  const organization = useOrganization();
  const [timeRange, setTimeRange] = useState<ITimeRange<DateTime>>(range ?? getInitialRange(timeRangeType));
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      // Custom time range should be changed at a higher level. Cannot initialize time range with CUSTOM.
    } else if (timeRangeType !== TimeRangeType.CUSTOM && timeRangeType !== TimeRangeType.ALL_TIME) {
      const newRange = getInitialRange(timeRangeType);
      if (
        newRange.startTime.toSeconds() !== timeRange.startTime.toSeconds() ||
        newRange.endTime.toSeconds() !== timeRange.endTime.toSeconds()
      ) {
        setTimeRange(newRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRangeType]);

  useEffect(() => {
    if (
      range &&
      (range.startTime.toSeconds() !== timeRange.startTime.toSeconds() ||
        range.endTime.toSeconds() !== timeRange.endTime.toSeconds())
    ) {
      setTimeRange(range);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const updateRange = useCallback(
    (updatedRange: ITimeRange<DateTime>) => {
      const start = updatedRange.startTime.toSeconds();

      const minimumSafe = (minimumTime && minimumTime.toSeconds() <= start) || !minimumTime;
      // Needs to be start for times that haven't happened yet. if the start is at or before start time
      // e.g. Monthly range on the 8th, still need to be able to select that month regardless of what the end is
      const maximumSafe = (maximumTime && maximumTime.toSeconds() >= start) || !maximumTime;

      if (minimumSafe && maximumSafe) {
        setTimeRange(updatedRange);
      }
    },
    [maximumTime, minimumTime]
  );

  function getInitialRange(type: TimeRangeType): ITimeRange<DateTime> {
    const currentTime = DateTime.local().setZone(zone, { keepLocalTime: true });

    switch (type) {
      case TimeRangeType.DAILY:
        return { startTime: currentTime.startOf('day'), endTime: currentTime.endOf('day').set({ millisecond: 0 }) };
      case TimeRangeType.WEEKLY:
        return getWeekRangeForTime(organization.organizationOvertimePeriods!, currentTime.toSeconds())!;
      case TimeRangeType.MONTHLY:
        return { startTime: currentTime.startOf('month'), endTime: currentTime.endOf('month').set({ millisecond: 0 }) };
      case TimeRangeType.YEARLY:
        return { startTime: currentTime.startOf('year'), endTime: currentTime.endOf('year').set({ millisecond: 0 }) };
      case TimeRangeType.PAY_PERIOD:
        return getTimeRangeForPayPeriod(currentTime.toSeconds());
      case TimeRangeType.CUSTOM:
        // Doesn't make sense to initialize a custom range.
        throw Error('Cannot set initial range without range!');
      case TimeRangeType.ALL_TIME:
        return {
          startTime: currentTime.startOf('day'),
          endTime: currentTime.endOf('day').set({ millisecond: 0 }),
        };
    }
  }

  function getTimeRangeForPayPeriod(time: number) {
    return getPayPeriodTimeRange(organization.organizationPayPeriod!, time, zone);
  }

  return {
    timeRange,
    timeRangeType,
    ...useTimeRangeAdjustment(timeRangeType, timeRange, minimumTime, maximumTime, zone, updateRange),
  };
}

export function useTimeRangeAdjustment(
  timeRangeType: TimeRangeType,
  timeRange: Optional<ITimeRange<DateTime>>,
  minimumTime: Optional<DateTime>,
  maximumTime: Optional<DateTime>,
  zone: 'system' | 'utc',
  onUpdateRange: (timeRange: ITimeRange<DateTime>) => void
) {
  function updateRange(updatedRange: ITimeRange<DateTime>) {
    const start = updatedRange.startTime.toSeconds();

    const minimumSafe = (minimumTime && minimumTime.toSeconds() <= start) || !minimumTime;
    // Needs to be start for times that haven't happened yet. if the start is at or before start time
    // e.g. Monthly range on the 8th, still need to be able to select that month regardless of what the end is
    const maximumSafe = (maximumTime && maximumTime.toSeconds() >= start) || !maximumTime;

    if (minimumSafe && maximumSafe) {
      onUpdateRange(updatedRange);
    }
  }

  return {
    ...useTimeRangeForward(timeRangeType, timeRange, maximumTime, zone, updateRange),
    ...useTimeRangeBackward(timeRangeType, timeRange, minimumTime, zone, updateRange),
  };
}

function useTimeRangeForward(
  timeRangeType: TimeRangeType,
  timeRange: Optional<ITimeRange<DateTime>>,
  maximumTime: Optional<DateTime>,
  zone: 'system' | 'utc',
  onUpdateRange: (timeRange: ITimeRange<DateTime>) => void
) {
  const organization = useOrganization();

  const getForwardRange = useCallback(() => {
    if (!timeRange) {
      throw Error('Cannot go forward on null range');
    }
    switch (timeRangeType) {
      case TimeRangeType.DAILY: {
        const newDayStart = timeRange.startTime.plus({ day: 1 });
        return { startTime: newDayStart, endTime: newDayStart.endOf('day').set({ millisecond: 0 }) };
      }
      case TimeRangeType.WEEKLY: {
        return getWeekRangeForTime(
          organization.organizationOvertimePeriods!,
          timeRange.endTime.plus({ day: 1 }).toSeconds()
        )!;
      }
      case TimeRangeType.MONTHLY: {
        const newMonthStart = timeRange.startTime.plus({ month: 1 });
        return { startTime: newMonthStart, endTime: newMonthStart.endOf('month').set({ millisecond: 0 }) };
      }
      case TimeRangeType.YEARLY: {
        const newYearStart = timeRange.startTime.plus({ year: 1 });
        return { startTime: newYearStart, endTime: newYearStart.endOf('year').set({ millisecond: 0 }) };
      }
      case TimeRangeType.PAY_PERIOD:
        // Plus one to next pay period
        return getPayPeriodTimeRange(
          organization.organizationPayPeriod!,
          timeRange.endTime.plus({ day: 1 }).toSeconds(),
          zone
        );
      case TimeRangeType.CUSTOM:
        // Doesn't make sense to go forward during custom.
        throw Error('Cannot go forward on a custom time range!');
      case TimeRangeType.ALL_TIME:
        throw Error('Cannot go forward on all time range!');
    }
  }, [organization.organizationOvertimePeriods, organization.organizationPayPeriod, timeRange, timeRangeType, zone]);

  const forwardEnabled = useCallback(() => {
    if (timeRangeType === TimeRangeType.CUSTOM || timeRangeType === TimeRangeType.ALL_TIME || !timeRange) {
      return false;
    } else if (!maximumTime) {
      return true;
    } else {
      const forwardRange = getForwardRange();
      return forwardRange.startTime.toSeconds() <= maximumTime.toSeconds();
    }
  }, [getForwardRange, maximumTime, timeRange, timeRangeType]);

  const forward = useCallback(() => {
    const forwardRange = getForwardRange();
    onUpdateRange(forwardRange);
  }, [getForwardRange, onUpdateRange]);

  return { forwardEnabled, forward };
}

function useTimeRangeBackward(
  timeRangeType: TimeRangeType,
  timeRange: Optional<ITimeRange<DateTime>>,
  minimumTime: Optional<DateTime>,
  zone: 'system' | 'utc',
  onUpdateRange: (timeRange: ITimeRange<DateTime>) => void
) {
  const organization = useOrganization();

  const getBackRange = useCallback(() => {
    if (!timeRange) {
      throw Error('Cannot go back on null range');
    }

    switch (timeRangeType) {
      case TimeRangeType.DAILY: {
        const newDayStart = timeRange.startTime.minus({ day: 1 });
        return { startTime: newDayStart, endTime: newDayStart.endOf('day').set({ millisecond: 0 }) };
      }
      case TimeRangeType.WEEKLY:
        return getWeekRangeForTime(
          organization.organizationOvertimePeriods!,
          timeRange.startTime.minus({ day: 1 }).toSeconds()
        )!;
      case TimeRangeType.MONTHLY: {
        const newMonthStart = timeRange.startTime.minus({ month: 1 });
        return { startTime: newMonthStart, endTime: newMonthStart.endOf('month').set({ millisecond: 0 }) };
      }
      case TimeRangeType.YEARLY: {
        const newYearStart = timeRange.startTime.minus({ year: 1 });
        return { startTime: newYearStart, endTime: newYearStart.endOf('year').set({ millisecond: 0 }) };
      }
      case TimeRangeType.PAY_PERIOD:
        // Minus one to previous pay period
        return getPayPeriodTimeRange(
          organization.organizationPayPeriod!,
          timeRange.startTime.minus({ day: 1 }).toSeconds(),
          zone
        );
      case TimeRangeType.CUSTOM:
        // Doesn't make sense to go back during custom.
        throw Error('Cannot go back on a custom time range!');
      case TimeRangeType.ALL_TIME:
        // Doesn't make sense to go back during all time.
        throw Error('Cannot go back on all time range!');
    }
  }, [organization.organizationOvertimePeriods, organization.organizationPayPeriod, timeRange, timeRangeType, zone]);

  const back = useCallback(() => {
    const backRange = getBackRange();
    onUpdateRange(backRange);
  }, [getBackRange, onUpdateRange]);

  const backEnabled = useCallback(() => {
    if (timeRangeType === TimeRangeType.CUSTOM || timeRangeType === TimeRangeType.ALL_TIME || !timeRange) {
      return false;
    } else if (!minimumTime) {
      return true;
    } else {
      const backRange = getBackRange();
      return backRange.startTime.toSeconds() >= minimumTime.toSeconds();
    }
  }, [getBackRange, minimumTime, timeRange, timeRangeType]);

  return { back, backEnabled };
}
