import { IDashboardGraphData } from 'containers/dashboard/dashboard-grid/card/hooks/useDashboardCardTotal';
import { groupBy, isEmpty, isNil, reduce } from 'lodash';
import { DateTime } from 'luxon';
import IEquipmentTimeMetrics from 'types/aggregate/EquipmentLaborMetrics';
import { default as IJitLaborMetric, default as IJitTimeMetric } from 'types/aggregate/JitLaborMetric';
import IProjectEquipmentTimeMetrics from 'types/aggregate/ProjectEquipmentLaborMetrics';
import IProjectTimeMetrics from 'types/aggregate/ProjectLaborMetrics';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import ITimeRange from 'types/TimeRange';
import { KeysOfType } from 'types/util/KeysOfType';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  LaborMetricFilter,
  LaborMetricsInterface,
  OperationType,
  ProjectLaborMetricsInterface,
} from '__generated__/graphql';
import { getDateTimesBetween } from './dateUtils';
import { add } from './numberUtils';
import { anyFieldIsTruthy } from './objectUtils';
import { isArrayOfType, isType } from './typeguard';

export interface ITimeMetric {
  regularSeconds: number;
  overtimeSeconds: number;
  doubleTimeSeconds: number;
  paidTimeOffSeconds: number;
  timeOffSeconds: number;
  totalSeconds: number;
}

export interface ILaborMetric {
  regularSeconds: number;
  overtimeSeconds: number;
  doubleTimeSeconds: number;
  paidTimeOffSeconds: number;
  timeOffSeconds: number;
  totalSeconds: number;
  regularCost: number;
  overtimeCost: number;
  doubleTimeCost: number;
  timeOffCost: number;
  laborBurden: number;
  totalCost: number;
  equipmentCost: number;
}

export function emptyMetricObject(): ILaborMetric {
  return {
    regularSeconds: 0,
    overtimeSeconds: 0,
    doubleTimeSeconds: 0,
    paidTimeOffSeconds: 0,
    timeOffSeconds: 0,
    totalSeconds: 0,
    regularCost: 0,
    overtimeCost: 0,
    doubleTimeCost: 0,
    timeOffCost: 0,
    laborBurden: 0,
    totalCost: 0,
    equipmentCost: 0,
  };
}

export function getGraphTimeDataFromMetrics<T extends IJitTimeMetric | IProjectTimeMetrics>(
  metrics: T[],
  timeRanges: ITimeRange<number>[]
): IDashboardGraphData[] {
  const groupedMetrics = groupBy(metrics, (metric) => metric.start);

  return timeRanges.map((range) => {
    const daysInRange = getDateTimesBetween(
      DateTime.fromMillis(range.startTime * 1000).toUTC(),
      DateTime.fromMillis(range.endTime * 1000).toUTC()
    );

    const metricsInRange = daysInRange.flatMap((day) => {
      return groupedMetrics[day.toISODate()] ?? [];
    });
    const aggregatedMetrics = aggregateMetricAggregate(metricsInRange);
    const totalsForRange = getMetricTimeTotals(aggregatedMetrics);

    return {
      day: range.startTime,
      total: totalsForRange.totalSeconds / 3600,
      regular: (totalsForRange.regularSeconds + totalsForRange.paidTimeOffSeconds) / 3600,
      overtime: totalsForRange.overtimeSeconds / 3600,
      doubletime: totalsForRange.doubleTimeSeconds / 3600,
    };
  });
}

export function getGraphCostDataFromMetrics<T extends IJitTimeMetric | IProjectTimeMetrics>(
  metrics: T[],
  timeRanges: ITimeRange<number>[]
): IDashboardGraphData[] {
  const groupedMetrics = groupBy(metrics, (metric) => metric.start);

  return timeRanges.map((range) => {
    const daysInRange = getDateTimesBetween(
      DateTime.fromMillis(range.startTime * 1000).toUTC(),
      DateTime.fromMillis(range.endTime * 1000).toUTC()
    );

    const metricsInRange = daysInRange.flatMap((day) => {
      return groupedMetrics[day.toISODate()] ?? [];
    });
    const aggregatedMetrics = aggregateMetricAggregate(metricsInRange);
    const totalsForRange = getMetricLaborTotals(aggregatedMetrics);

    return {
      day: range.startTime,
      total: totalsForRange.totalCost,
      regular: totalsForRange.regularCost + totalsForRange.timeOffCost,
      overtime: totalsForRange.overtimeCost,
      doubletime: totalsForRange.doubleTimeCost,
      overburden: totalsForRange.laborBurden,
    };
  });
}

export function combineMetricWithTimeOff<T extends IJitTimeMetric | IProjectTimeMetrics>(
  metricData: T | undefined,
  timeOffData: ITimeOffLaborMetrics | undefined
) {
  if (metricData === undefined && timeOffData === undefined) {
    return undefined;
  } else if (metricData === undefined) {
    const metrics: IJitTimeMetric | IProjectTimeMetrics = {
      start: timeOffData?.start ?? '',
      end: timeOffData?.end ?? '',
      pto: timeOffData?.pto,
      ptoCents: timeOffData?.ptoCents,
      upto: timeOffData?.upto,
    };
    return metrics;
  } else if (timeOffData === undefined) {
    return metricData;
  }

  return {
    ...metricData,
    pto: (metricData.pto ?? 0) + (timeOffData.pto ?? 0),
    ptoCents: (metricData.ptoCents ?? 0) + (timeOffData.ptoCents ?? 0),
    upto: (metricData.upto ?? 0) + (timeOffData.upto ?? 0),
  };
}

export function getMetricLaborTotals<T extends IJitTimeMetric | IProjectTimeMetrics | IProjectEquipmentTimeMetrics>(
  metricData?: T | null,
  ignoreDescendants?: boolean
): ILaborMetric {
  // check if our data is tied to a project equipment
  if (
    !isNil(metricData) &&
    isType<IProjectEquipmentTimeMetrics>(metricData, 'descRt') &&
    isType<IProjectEquipmentTimeMetrics>(metricData, 'equipmentCents') &&
    ignoreDescendants !== true
  ) {
    return getProjectEquipmentMetricLaborTotals(metricData);
  }
  // check if our data is tied to a project
  if (!isNil(metricData) && isType<IProjectTimeMetrics>(metricData, 'descRt') && ignoreDescendants !== true) {
    return getProjectMetricLaborTotals(metricData);
  }

  // metric data not tied to a project
  return getRegularMetricLaborTotals(metricData);
}

export function getRegularMetricLaborTotals(metric?: IJitTimeMetric | null): ILaborMetric {
  const regularSeconds = metric?.rt ?? 0;
  const overtimeSeconds = metric?.ot ?? 0;
  const doubleTimeSeconds = metric?.dt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const regularCost = (metric?.rtCents ?? 0) / 100;
  const overtimeCost = (metric?.otCents ?? 0) / 100;
  const doubleTimeCost = (metric?.dtCents ?? 0) / 100;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;
  const laborBurden = add(metric?.overburdenRtCents, metric?.overburdenOtCents, metric?.overburdenDtCents) / 100;

  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: 0,
  };
}

export function getEquipmentMetricLaborTotals(metric?: IEquipmentTimeMetrics | null): ILaborMetric {
  const regularSeconds = metric?.rt ?? 0;
  const overtimeSeconds = metric?.ot ?? 0;
  const doubleTimeSeconds = metric?.dt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const regularCost = (metric?.rtCents ?? 0) / 100;
  const overtimeCost = (metric?.otCents ?? 0) / 100;
  const doubleTimeCost = (metric?.dtCents ?? 0) / 100;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;
  const laborBurden = add(metric?.overburdenRtCents, metric?.overburdenOtCents, metric?.overburdenDtCents) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: (metric?.equipmentCents ?? 0) / 100,
  };
}

export function getProjectDescendantMetricLaborTotals(metric?: IProjectTimeMetrics | null): ILaborMetric {
  const regularSeconds = metric?.descRt ?? 0;
  const overtimeSeconds = metric?.descOt ?? 0;
  const doubleTimeSeconds = metric?.descDt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  const regularCost = (metric?.descRtCents ?? 0) / 100;
  const overtimeCost = (metric?.descOtCents ?? 0) / 100;
  const doubleTimeCost = (metric?.descDtCents ?? 0) / 100;
  const timeOffCost = (metric?.ptoCents ?? 0) / 100;
  const laborBurden =
    add(metric?.descOverburdenRtCents, metric?.descOverburdenOtCents, metric?.descOverburdenDtCents) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: 0,
  };
}

export function getProjectMetricLaborTotals(metric?: IProjectTimeMetrics | null): ILaborMetric {
  const regularSeconds = add(metric?.rt, metric?.descRt);
  const overtimeSeconds = add(metric?.ot, metric?.descOt);
  const doubleTimeSeconds = add(metric?.dt, metric?.descDt);
  const paidTimeOffSeconds = add(metric?.pto);
  const timeOffSeconds = add(metric?.upto);
  const regularCost = add(metric?.rtCents, metric?.descRtCents) / 100;
  const overtimeCost = add(metric?.otCents, metric?.descOtCents) / 100;
  const doubleTimeCost = add(metric?.dtCents, metric?.descDtCents) / 100;
  const timeOffCost = add(metric?.ptoCents) / 100;
  const laborBurden =
    add(
      metric?.overburdenRtCents,
      metric?.overburdenOtCents,
      metric?.overburdenDtCents,
      metric?.descOverburdenRtCents,
      metric?.descOverburdenOtCents,
      metric?.descOverburdenDtCents
    ) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: 0,
  };
}

export function getProjectEquipmentMetricLaborTotals(metric?: IProjectEquipmentTimeMetrics | null): ILaborMetric {
  const regularSeconds = add(metric?.rt, metric?.descRt);
  const overtimeSeconds = add(metric?.ot, metric?.descOt);
  const doubleTimeSeconds = add(metric?.dt, metric?.descDt);
  const paidTimeOffSeconds = add(metric?.pto);
  const timeOffSeconds = add(metric?.upto);
  const regularCost = add(metric?.rtCents, metric?.descRtCents) / 100;
  const overtimeCost = add(metric?.otCents, metric?.descOtCents) / 100;
  const doubleTimeCost = add(metric?.dtCents, metric?.descDtCents) / 100;
  const timeOffCost = add(metric?.ptoCents) / 100;
  const laborBurden =
    add(
      metric?.overburdenRtCents,
      metric?.overburdenOtCents,
      metric?.overburdenDtCents,
      metric?.descOverburdenRtCents,
      metric?.descOverburdenOtCents,
      metric?.descOverburdenDtCents
    ) / 100;
  const equipmentCents = ((metric?.equipmentCents ?? 0) + (metric?.descEquipmentCents ?? 0)) / 100;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
    regularCost,
    overtimeCost,
    doubleTimeCost,
    timeOffCost,
    laborBurden,
    totalCost: regularCost + overtimeCost + doubleTimeCost + timeOffCost + laborBurden,
    equipmentCost: equipmentCents,
  };
}

export function getMetricTimeTotals<T extends IJitTimeMetric | IProjectTimeMetrics>(
  metricData?: T | null
): ITimeMetric {
  // check if our data is tied to a project
  if (!isNil(metricData) && isType<IProjectTimeMetrics>(metricData, 'descRt')) {
    return getProjectMetricTimeTotals(metricData);
  }

  // metric data not tied to a project
  return getRegularMetricTimeTotals(metricData);
}

export function getRegularMetricTimeTotals(metric?: IJitTimeMetric | null): ITimeMetric {
  const regularSeconds = metric?.rt ?? 0;
  const overtimeSeconds = metric?.ot ?? 0;
  const doubleTimeSeconds = metric?.dt ?? 0;
  const paidTimeOffSeconds = metric?.pto ?? 0;
  const timeOffSeconds = metric?.upto ?? 0;
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds + paidTimeOffSeconds,
  };
}

export function getProjectMetricTimeTotals(metric?: IProjectTimeMetrics | null): ITimeMetric {
  const regularSeconds = add(metric?.rt, metric?.descRt);
  const overtimeSeconds = add(metric?.ot, metric?.descOt);
  const doubleTimeSeconds = add(metric?.dt, metric?.descDt);
  const paidTimeOffSeconds = add(metric?.pto);
  const timeOffSeconds = add(metric?.upto);
  return {
    regularSeconds,
    overtimeSeconds,
    doubleTimeSeconds,
    paidTimeOffSeconds,
    timeOffSeconds,
    totalSeconds: regularSeconds + overtimeSeconds + doubleTimeSeconds,
  };
}

export function aggregateMetricAggregate<T extends IJitTimeMetric | IProjectTimeMetrics>(
  aggregates: T[]
): Required<IJitTimeMetric> {
  function add(
    metric1: IJitTimeMetric,
    metric2: IJitTimeMetric,
    key: KeysOfType<IJitTimeMetric, number | undefined>
  ): number {
    return (metric1[key] ?? 0) + (metric2[key] ?? 0);
  }

  if (isArrayOfType<IProjectTimeMetrics>(aggregates, 'descRt')) {
    return aggregateProjectMetricAggregate(aggregates);
  }

  return reduce(
    aggregates,
    (acc, cur) => {
      return {
        ...acc,
        start: cur.start,
        end: cur.end,
        dt: add(cur, acc, 'dt'),
        ot: add(cur, acc, 'ot'),
        rt: add(cur, acc, 'rt'),
        dtCents: add(cur, acc, 'dtCents'),
        otCents: add(cur, acc, 'otCents'),
        rtCents: add(cur, acc, 'rtCents'),
        overburdenRtCents: add(cur, acc, 'overburdenRtCents'),
        overburdenOtCents: add(cur, acc, 'overburdenOtCents'),
        overburdenDtCents: add(cur, acc, 'overburdenDtCents'),
        pto: add(cur, acc, 'pto'),
        ptoCents: add(cur, acc, 'ptoCents'),
        upto: add(cur, acc, 'upto'),
        breakDt: add(cur, acc, 'breakDt'),
        breakOt: add(cur, acc, 'breakOt'),
        breakRt: add(cur, acc, 'breakRt'),
        breakDtCents: add(cur, acc, 'breakDtCents'),
        breakOtCents: add(cur, acc, 'breakOtCents'),
        breakOverburdenCents: add(cur, acc, 'breakOverburdenCents'),
        breakRtCents: add(cur, acc, 'breakRtCents'),
        breakUpt: add(cur, acc, 'breakUpt'),
      };
    },
    {
      start: '',
      end: '',
      breakRt: 0,
      breakOt: 0,
      breakDt: 0,
      breakRtCents: 0,
      breakDtCents: 0,
      breakOtCents: 0,
      breakOverburdenCents: 0,
      breakUpt: 0,
      rt: 0,
      rtCents: 0,
      ot: 0,
      otCents: 0,
      dt: 0,
      dtCents: 0,
      overburdenRtCents: 0,
      overburdenOtCents: 0,
      overburdenDtCents: 0,
      pto: 0,
      ptoCents: 0,
      upto: 0,
    }
  );
}

export function aggregateProjectMetricAggregate(aggregates: IProjectTimeMetrics[]): Required<IProjectTimeMetrics> {
  function add(
    metric1: IProjectTimeMetrics,
    metric2: IProjectTimeMetrics,
    key: KeysOfType<IProjectTimeMetrics, number | undefined>
  ): number {
    return (metric1[key] ?? 0) + (metric2[key] ?? 0);
  }

  return reduce(
    aggregates,
    (acc, cur) => {
      return {
        ...acc,
        start: cur.start,
        end: cur.end,
        dt: add(cur, acc, 'dt'),
        ot: add(cur, acc, 'ot'),
        rt: add(cur, acc, 'rt'),
        dtCents: add(cur, acc, 'dtCents'),
        otCents: add(cur, acc, 'otCents'),
        rtCents: add(cur, acc, 'rtCents'),
        overburdenRtCents: add(cur, acc, 'overburdenRtCents'),
        overburdenOtCents: add(cur, acc, 'overburdenOtCents'),
        overburdenDtCents: add(cur, acc, 'overburdenDtCents'),
        pto: add(cur, acc, 'pto'),
        ptoCents: add(cur, acc, 'ptoCents'),
        upto: add(cur, acc, 'upto'),
        breakDt: add(cur, acc, 'breakDt'),
        breakOt: add(cur, acc, 'breakOt'),
        breakRt: add(cur, acc, 'breakRt'),
        breakDtCents: add(cur, acc, 'breakDtCents'),
        breakOtCents: add(cur, acc, 'breakOtCents'),
        breakOverburdenCents: add(cur, acc, 'breakOverburdenCents'),
        breakRtCents: add(cur, acc, 'breakRtCents'),
        breakUpt: add(cur, acc, 'breakUpt'),
        descBreakRtCents: add(cur, acc, 'descBreakRtCents'),
        descBreakRt: add(cur, acc, 'descBreakRt'),
        descBreakOtCents: add(cur, acc, 'descBreakOtCents'),
        descBreakOt: add(cur, acc, 'descBreakOt'),
        descBreakDtCents: add(cur, acc, 'descBreakDtCents'),
        descBreakDt: add(cur, acc, 'descBreakDt'),
        descBreakOverburdenCents: add(cur, acc, 'descBreakOverburdenCents'),
        descRt: add(cur, acc, 'descRt'),
        descRtCents: add(cur, acc, 'descRtCents'),
        descOt: add(cur, acc, 'descOt'),
        descOtCents: add(cur, acc, 'descOtCents'),
        descDt: add(cur, acc, 'descDt'),
        descDtCents: add(cur, acc, 'descDtCents'),
        descOverburdenRtCents: add(cur, acc, 'descOverburdenRtCents'),
        descOverburdenOtCents: add(cur, acc, 'descOverburdenOtCents'),
        descOverburdenDtCents: add(cur, acc, 'descOverburdenDtCents'),
      };
    },
    {
      projectId: '',
      start: '',
      end: '',
      breakRt: 0,
      breakOt: 0,
      breakDt: 0,
      breakRtCents: 0,
      breakDtCents: 0,
      breakOtCents: 0,
      breakOverburdenCents: 0,
      breakUpt: 0,
      rt: 0,
      rtCents: 0,
      ot: 0,
      otCents: 0,
      dt: 0,
      dtCents: 0,
      overburdenRtCents: 0,
      overburdenOtCents: 0,
      overburdenDtCents: 0,
      pto: 0,
      ptoCents: 0,
      upto: 0,
      descBreakRtCents: 0,
      descBreakRt: 0,
      descBreakOtCents: 0,
      descBreakOt: 0,
      descBreakDtCents: 0,
      descBreakDt: 0,
      descBreakOverburdenCents: 0,
      descRt: 0,
      descRtCents: 0,
      descOt: 0,
      descOtCents: 0,
      descDt: 0,
      descDtCents: 0,
      descOverburdenRtCents: 0,
      descOverburdenOtCents: 0,
      descOverburdenDtCents: 0,
    }
  );
}

export function aggregateRtDtOt<T extends Pick<LaborMetricsInterface, 'rt' | 'dt' | 'ot'>>(laborMetrics: T[]) {
  return laborMetrics.reduce(
    (acc, cur) => ({
      rt: add(acc.rt, cur.rt),
      dt: add(acc.dt, cur.dt),
      ot: add(acc.ot, cur.ot),
    }),
    {
      rt: 0,
      dt: 0,
      ot: 0,
    }
  );
}

export function metricNullFilter(
  filterFieldName: ConditionNullableFieldType,
  nullOperation: ConditionOperationNullType
): LaborMetricFilter {
  return {
    operationType: OperationType.And,
    nullConditions: [{ operator: nullOperation, field: filterFieldName }],
  };
}

export function metricConditionFilter(
  field: ConditionFieldType,
  operator: ConditionOperationType,
  value: unknown
): LaborMetricFilter {
  return {
    operationType: OperationType.And,
    conditions: [
      {
        field,
        operator,
        value,
      },
    ],
  };
}

export function combineMetricFilters(
  filters: LaborMetricFilter[],
  combineType: OperationType = OperationType.And
): LaborMetricFilter {
  const conditions = filters.flatMap((filter) => filter.conditions ?? []);
  const listConditions = filters.flatMap((filter) => filter.listConditions ?? []);
  const nullConditions = filters.flatMap((filter) => filter.nullConditions ?? []);

  return {
    operationType: combineType,
    conditions: !isEmpty(conditions) ? conditions : undefined,
    listConditions: !isEmpty(listConditions) ? listConditions : undefined,
    nullConditions: !isEmpty(nullConditions) ? nullConditions : undefined,
  };
}

export function laborMetricHasCost(metric: Pick<LaborMetricsInterface, 'rtCents' | 'otCents' | 'dtCents'>) {
  return anyFieldIsTruthy(metric, 'rtCents', 'otCents', 'dtCents');
}

export function projectLaborMetricOrDescendantsHaveCost(
  metric: Pick<
    ProjectLaborMetricsInterface,
    'rtCents' | 'otCents' | 'dtCents' | 'descDtCents' | 'descOtCents' | 'descRtCents'
  >
) {
  return laborMetricHasCost(metric) || anyFieldIsTruthy(metric, 'descRtCents', 'descDtCents', 'descOtCents');
}

export const laborMetricsTimeKeys = ['rt', 'dt', 'ot'] as const;
export const projectLaborMetricsTimeSubKeys = ['descRt', 'descDt', 'descOt'] as const;

export const projectLaborMetricsCostKeys = [
  'rtCents',
  'dtCents',
  'otCents',
  'overburdenRtCents',
  'overburdenOtCents',
  'overburdenDtCents',
] as const;

export const projectLaborMetricsCostSubKeys = [
  'descRtCents',
  'descDtCents',
  'descOtCents',
  'descOverburdenRtCents',
  'descOverburdenOtCents',
  'descOverburdenDtCents',
] as const;

export function getProjectLaborMetricTimeKeys(includeSubprojects: boolean) {
  if (includeSubprojects) {
    return [...laborMetricsTimeKeys, ...projectLaborMetricsTimeSubKeys] as const;
  }

  return laborMetricsTimeKeys;
}

export function getProjectLaborMetricCostKeys(includeSubprojects: boolean) {
  if (includeSubprojects) {
    return [...projectLaborMetricsCostKeys, ...projectLaborMetricsCostSubKeys] as const;
  }

  return projectLaborMetricsCostKeys;
}
