import {
  type CreatePolicyMemberAcceptanceMutation,
  type CreatePolicyMemberAcceptanceMutationVariables,
  type PolicyMemberAcceptanceCreateInput,
  type PolicyMemberAcceptancesQuery,
} from '__generated__/graphql';
import { gql } from 'graphql-tag';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { useCallback } from 'react';
import { type NonNullArrayField } from 'types/util/NonNullArrayField';
import { isoTimeStampUtc } from 'utils/dateUtils';

export type PolicyMemberAcceptanceEntry = NonNullArrayField<PolicyMemberAcceptancesQuery['policyMemberAcceptances']>;

export const POLICY_MEMBER_ACCEPTANCE_QUERY = gql`
  query PolicyMemberAcceptances(
    $first: Int
    $after: String
    $filter: PolicyMemberAcceptanceFilter
    $sort: [PolicyMemberAcceptanceSort!]
  ) {
    policyMemberAcceptances(first: $first, after: $after, filter: $filter, sort: $sort) {
      __typename
      id
      policyId
      memberId
      proxyMemberId
      deviceType
      signatureId
      signatureUrl
      createdOn
      submittedOn
      updatedOn
      deletedOn
      cursor
      policy {
        __typename
        id
        version
        type
        contentUrl
        effectiveDate
        deletedOn
        createdOn
        submittedOn
        updatedOn
        cursor
      }
    }
  }
`;

export const POLICY_MEMBER_ACCEPTANCE_MUTATION = gql`
  mutation CreatePolicyMemberAcceptance($policyMemberAcceptance: PolicyMemberAcceptanceCreateInput!) {
    createPolicyMemberAcceptance(policyMemberAcceptance: $policyMemberAcceptance) {
      memberId
      policyId
      createdOn
    }
  }
`;

export function useCreatePolicyMemberAcceptance() {
  const graphql = useGraphQLClient();

  return useCallback(
    (policyMemberAcceptance: Omit<PolicyMemberAcceptanceCreateInput, 'createdOn'>) => {
      return graphql.request<CreatePolicyMemberAcceptanceMutation, CreatePolicyMemberAcceptanceMutationVariables>({
        document: POLICY_MEMBER_ACCEPTANCE_MUTATION,
        variables: { policyMemberAcceptance: {
          ...policyMemberAcceptance,
          deviceType: 'Web Browser',
          createdOn: isoTimeStampUtc()
        } },
      });
    },
    [graphql]
  );
}
