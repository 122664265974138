import { Theme } from '@busybusy/webapp-react-ui';
import {
  IncidentAnswerTypes,
  IncidentAnswerUpdateInput,
  IncidentMedicalDetailsUpdateInput,
  IncidentOutcomeDetailsCreateInput,
  IncidentOutcomeDetailsUpdateInput,
  IncidentStates,
  IncidentUpdateInput,
} from '__generated__/graphql';
import { useCreateIncidentOutcomeDetails } from 'containers/safety-incident-reports/hooks/useCreateIncidentOutcomeDetails/useCreateIncidentOutcomeDetails';
import { useUpdateIncident } from 'containers/safety-incident-reports/hooks/useUpdateIncident/useUpdateIncident';
import { useUpdateIncidentAnswer } from 'containers/safety-incident-reports/hooks/useUpdateIncidentAnswer/useUpdateIncidentAnswer';
import { useUpdateIncidentMedicalDetails } from 'containers/safety-incident-reports/hooks/useUpdateIncidentMedicalDetails/useUpdateIncidentMedicalDetails';
import { useUpdateIncidentOutcomeDetails } from 'containers/safety-incident-reports/hooks/useUpdateIncidentOutcomeDetails/useUpdateIncidentOutcomeDetails';
import { useToastOpen } from 'contexts/ToastContext';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { dateTimeFromISOWithoutZone, getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { ISubmitSafetyIncidentFormData } from '../SubmitSafetyIncidentForm';

export function useUpdateSafetyIncident() {
  const updateIncident = useUpdateIncident();
  const updateIncidentAnswer = useUpdateIncidentAnswer();
  const updateIncidentMedicalDetails = useUpdateIncidentMedicalDetails();
  const updateIncidentOutcomeDetails = useUpdateIncidentOutcomeDetails();
  const createIncidentOutcomeDetails = useCreateIncidentOutcomeDetails();
  const toast = useToastOpen();

  return useCallback(
    async (incidentId: string, formData: ISubmitSafetyIncidentFormData): Promise<boolean> => {
      let occurredAt: string = dateTimeFromISOWithoutZone(
        DateTime.fromJSDate(formData.occurredAtDate!.value!).toISO()
      ).toISO();
      let employeeStartTime: string = dateTimeFromISOWithoutZone(
        DateTime.fromJSDate(formData.occurredAtDate!.value!).toISO()
      ).toISO();

      if (!isNil(formData.occurredAtTime)) {
        const mutableOccurredAtDate = formData.occurredAtDate!.value!;
        mutableOccurredAtDate.setHours(formData.occurredAtTime.hour, formData.occurredAtTime.minute);
        occurredAt = mutableOccurredAtDate.toISOString();
      }

      if (!isNil(formData.employeeStartTime)) {
        const mutableOccurredAtDate = formData.occurredAtDate!.value!;
        mutableOccurredAtDate.setHours(formData.employeeStartTime.hour, formData.employeeStartTime.minute);
        employeeStartTime = mutableOccurredAtDate.toISOString();
      }

      try {
        const updateIncidentInput: IncidentUpdateInput = {
          id: incidentId,
          type: formData.type!,
          projectId: formData.projectId,
          recordable: formData.recordable,
          state: IncidentStates.Pending,
          illnessType: formData.illnessType,
          occurredAt: occurredAt,
          occurrenceTimeSet: !isNil(formData.occurredAtTime) ? true : false,
          employeeStartTime: employeeStartTime,
        };

        if (formData.recordable) {
          updateIncidentInput.employeeJobTitle = formData.employeeJobTitle ?? undefined;
          updateIncidentInput.employeeBirthday = formData.employeeBirthday
            ? formData.employeeBirthday.toISODate()
            : undefined;
          updateIncidentInput.employeeHireDate = formData.employeeHireDate
            ? formData.employeeHireDate.toISODate()
            : undefined;
          updateIncidentInput.employeeSex = formData.employeeSex ?? undefined;
          updateIncidentInput.employeeAddress1 = formData.employeeAddress1 ?? undefined;
          updateIncidentInput.employeeCity = formData.employeeCity ?? undefined;
          updateIncidentInput.employeeState = formData.employeeState ?? undefined;
          updateIncidentInput.employeePostalCode = formData.employeePostalCode ?? undefined;
        }

        const updateIncidentResult = await updateIncident(updateIncidentInput);

        const getAnswerText = (type: IncidentAnswerTypes) => {
          switch (type) {
            case IncidentAnswerTypes.ActivityBefore:
              return formData.activityBefore;
            case IncidentAnswerTypes.Location:
              return formData.location;
            case IncidentAnswerTypes.TypeDescription:
              return formData.typeDescription;
            case IncidentAnswerTypes.WhatHappened:
              return formData.whatHappened;
            case IncidentAnswerTypes.WhatHarmed:
              return formData.whatHarmed;
            default:
              return '';
          }
        };

        const incidentAnswerPromises = updateIncidentResult.answers.map(async (answer) => {
          const updatedAnswer: IncidentAnswerUpdateInput = {
            id: answer.id,
            answer: getAnswerText(answer.type),
          };
          return await updateIncidentAnswer(updatedAnswer);
        });

        await Promise.all(incidentAnswerPromises);

        if (updateIncidentResult.medicalDetails) {
          const updateIncidentMedicalDetailsInput: IncidentMedicalDetailsUpdateInput = {
            id: updateIncidentResult.medicalDetails.id,
            physicianName: formData.physicianName!,
            facilityName: formData.facilityName!,
            facilityAddress: formData.facilityAddress!,
            facilityCity: formData.facilityCity!,
            facilityState: formData.facilityState!,
            facilityPostalCode: formData.facilityPostalCode!,
            treatmentER:
              formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentER')]
                .checked,
            treatmentOvernight:
              formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentOvernight')]
                .checked,
            treatmentFirstAid:
              formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentFirstAid')]
                .checked,
            treatmentDoctor:
              formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentDoctor')]
                .checked,
            treatmentNone:
              formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentNone')]
                .checked,
            treatmentOther:
              formData.treatments![formData.treatments!.findIndex((treatment) => treatment.id === 'treatmentOther')]
                .checked,
          };

          await updateIncidentMedicalDetails(updateIncidentMedicalDetailsInput);
        }

        if (formData.recordable && !isNil(formData.outcomeType)) {
          if (!isNil(updateIncidentResult.outcomeDetails)) {
            const updateIncidentOutcomeDetailsInput: IncidentOutcomeDetailsUpdateInput = {
              id: updateIncidentResult.outcomeDetails!.id,
              type: formData.outcomeType,
              daysAway: formData.daysAway ?? undefined,
              daysTransfer: formData.daysTransfer ?? undefined,
              outcomeDate: formData.outcomeDate?.value ? formData.outcomeDate.value.toISOString() : undefined,
            };
            await updateIncidentOutcomeDetails(updateIncidentOutcomeDetailsInput);
          } else {
            const outcome: IncidentOutcomeDetailsCreateInput = {
              incidentId,
              type: formData.outcomeType,
              daysAway: formData.daysAway ?? undefined,
              daysTransfer: formData.daysTransfer ?? undefined,
              outcomeDate: formData.outcomeDate?.value ? formData.outcomeDate.value.toISOString() : undefined,
              createdOn: getNowIsoAtUtcWithLocalTimeZone(),
            };

            await createIncidentOutcomeDetails(outcome);
          }
        }

        toast({ label: t('Report Updated'), theme: Theme.SUCCESS });
        return true;
      } catch {
        toast({ label: t('Error: Report Not Updated'), theme: Theme.DANGER });
        return false;
      }
    },
    [
      updateIncident,
      toast,
      updateIncidentAnswer,
      updateIncidentMedicalDetails,
      updateIncidentOutcomeDetails,
      createIncidentOutcomeDetails,
    ]
  );
}
