import {
  Button,
  CheckboxFormField,
  ClassName,
  Divider,
  Form,
  IFormRender,
  Loader,
  TextareaFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import { OrganizationSignOffQuestion, OrganizationSignOffQuestionAudience } from '__generated__/graphql';
import classNames from 'classnames';
import EmbeddedLinkedText from 'components/foundation/EmbeddedLinkedText/EmbeddedLinkedText';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import WhomToAskFormField from 'containers/daily-sign-in/CreateDailySignInQuestionsForm/FormFields/WhomToAskFormField/WhomToAskFormField';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable } from 'hooks';
import useUpdateOrganizationSignOffQuestion from 'hooks/models/organization-sign-off-questions/useUpdateOrganizationSignOffQuestion';
import useCreateOrganizationSignOffQuestion from 'hooks/models/organization-sign-off-questions/userCreateOrganizationSignOffQuestion';
import { isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { t } from 'utils/localize';
import { buildChoiceObjectFromJSON } from 'utils/organizationSignInQuestionUtils';
import { v_require } from 'utils/validations';
import './CreateDailySignOffQuestionsForm.scss';
import SignOffQuestionResponseFormField from './SignOffQuestionResponseFormField';

export enum SignOffQuestionResponseType {
  NO_ACTION = 'no-action',
  FLAG_RESPONSE = 'flag-response',
}

export interface ICreateDailySignOffQuestionsFormData {
  question?: string | null;
  explanation?: string | null;
  whomToAsk: string;
  yesResponses: SignOffQuestionResponseType;
  yesFollowUpQuestion: boolean;
  yesFollowUpQuestionText: string;
  yesExplanation: boolean;
  yesExplanationText: string;
  noResponses: SignOffQuestionResponseType;
  noFollowUpQuestion: boolean;
  noFollowUpQuestionText: string;
  noExplanation: boolean;
  noExplanationText: string;
}

export interface ICreateDailySignOffQuestionsFormProps {
  className?: ClassName;
  onSubmit: (data: ICreateDailySignOffQuestionsFormData, didCopy: boolean) => void;
  onDelete?: (data: ICreateDailySignOffQuestionsFormData) => void;
  question?: OrganizationSignOffQuestion | null;
  newOrder?: number;
  isCopying: boolean;
}

const CreateDailySignOffQuestionsForm = (props: ICreateDailySignOffQuestionsFormProps) => {
  const { className, onSubmit, onDelete, question, newOrder, isCopying } = props;

  const [formData, setFormData] = useState<ICreateDailySignOffQuestionsFormData | null>(null);
  const toastOpen = useToastOpen();
  const createQuestion = useCreateOrganizationSignOffQuestion();
  const deleteConfirmationOpenable = useOpenable();
  const { updateQuestion, deleteQuestion } = useUpdateOrganizationSignOffQuestion();
  const loaderOpenable = useOpenable();

  useEffect(() => {
    buildData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const buildData = async () => {
    if (!isNil(question)) {
      const newData = defaultFormData();
      if (!isNil(question.choices)) {
        newData.question = question.questionTitle;
        newData.explanation = question.questionDescription ?? '';
        const audienceType = question.audienceType;
        switch (audienceType) {
          case OrganizationSignOffQuestionAudience.Position:
            newData.whomToAsk = question.positionId ?? 'everyone';
            break;
          default:
            newData.whomToAsk = question.audienceType;
        }

        const choices = buildChoiceObjectFromJSON(question.choices);
        await Promise.all(
          choices.map(async (choice) => {
            switch (choice.choice) {
              case 'Yes':
                if (choice.flag === true) {
                  newData.yesResponses = SignOffQuestionResponseType.FLAG_RESPONSE;
                } else {
                  newData.yesResponses = SignOffQuestionResponseType.NO_ACTION;
                }

                if (!isNil(choice.followup) && !isEmpty(choice.followup)) {
                  newData.yesFollowUpQuestion = true;
                  newData.yesFollowUpQuestionText = choice.followup;
                }

                if (!isNil(choice.explanation) && !isEmpty(choice.explanation)) {
                  newData.yesExplanation = true;
                  newData.yesExplanationText = choice.explanation;
                }
                break;
              case 'No':
                if (choice.flag === true) {
                  newData.noResponses = SignOffQuestionResponseType.FLAG_RESPONSE;
                } else {
                  newData.noResponses = SignOffQuestionResponseType.NO_ACTION;
                }

                if (!isNil(choice.followup) && !isEmpty(choice.followup)) {
                  newData.noFollowUpQuestion = true;
                  newData.noFollowUpQuestionText = choice.followup;
                }

                if (!isNil(choice.explanation) && !isEmpty(choice.explanation)) {
                  newData.noExplanation = true;
                  newData.noExplanationText = choice.explanation;
                }
                break;
            }
          })
        );
        setFormData(newData);
      } else {
        setFormData(defaultFormData());
      }
    } else {
      setFormData(defaultFormData());
    }
  };

  function defaultFormData() {
    return {
      question: null,
      explanation: null,
      whomToAsk: 'everyone',
      yesResponses: SignOffQuestionResponseType.NO_ACTION,
      yesFollowUpQuestion: false,
      yesFollowUpQuestionText: '',
      yesExplanation: false,
      yesExplanationText: '',
      noResponses: SignOffQuestionResponseType.NO_ACTION,
      noFollowUpQuestion: false,
      noFollowUpQuestionText: '',
      noExplanation: false,
      noExplanationText: '',
    } as ICreateDailySignOffQuestionsFormData;
  }

  async function onSubmitForm(data: ICreateDailySignOffQuestionsFormData) {
    loaderOpenable.open();
    try {
      if (!isNil(question)) {
        if (isCopying) {
          await createQuestion(data, newOrder ?? 1);
          loaderOpenable.close();
          onSubmit(data, true);
        } else {
          await updateQuestion(question.id, data);
          loaderOpenable.close();
          onSubmit(data, false);
        }
      } else {
        await createQuestion(data, newOrder ?? 1);
        loaderOpenable.close();
        onSubmit(data, false);
      }
    } catch (error) {
      loaderOpenable.close();
      toastOpen({ label: t('Error saving question. Please try again later.'), theme: Theme.DANGER });
    }
  }

  async function handleDelete() {
    deleteConfirmationOpenable.close();
    if (!isNil(question)) {
      loaderOpenable.open();
      await deleteQuestion(question.id);
      loaderOpenable.close();
    }
    if (!isNil(formData)) {
      onDelete?.(formData);
    }
  }

  function renderFormFields({ ...form }: IFormRender<ICreateDailySignOffQuestionsFormData>): ReactNode {
    return (
      <>
        <div className="p-8">
          {!isNil(question) && !isCopying ? (
            <HeaderText title={t('Question')} className="title-label mb-6">
              <div className="details-label">
                <EmbeddedLinkedText>{question.questionTitle}</EmbeddedLinkedText>
              </div>
            </HeaderText>
          ) : (
            <QuestionField {...form} />
          )}
          {!isNil(question) && !isCopying ? (
            <HeaderText title={t('Explanation')} className="title-label mb-6">
              <div className="details-label">
                <EmbeddedLinkedText>
                  {!isNil(question.questionDescription) && !isEmpty(question.questionDescription)
                    ? question.questionDescription
                    : '---'}
                </EmbeddedLinkedText>
              </div>
            </HeaderText>
          ) : (
            <ExplanationField {...form} />
          )}
          <WhomToAskField {...form} />
        </div>
        <Divider />
        <div className="p-8">
          {renderHandleResponse('yesResponses', form)}
          {renderFollowUpQuestion('yesFollowUpQuestion', form)}
        </div>
        <Divider />
        <div className="p-8">
          {renderHandleResponse('noResponses', form)}
          {renderFollowUpQuestion('noFollowUpQuestion', form)}
        </div>
        <div className="pb-8 px-8">
          {isNil(question) && <CreateButton {...form} />}
          {!isNil(question) && isCopying && <CreateButton {...form} />}
          {!isNil(question) && !isCopying && (
            <>
              <Button className="pl-5 pr-5" type="primary" onClick={form.handleSubmit}>
                {t('Update')}
              </Button>
              <Button className="ml-5 pr-5" type="secondary" onClick={deleteConfirmationOpenable.open}>
                {t('Delete')}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }

  function renderFollowUpQuestion(name: string, { ...form }: IFormRender<ICreateDailySignOffQuestionsFormData>) {
    return (
      <>
        <CheckboxFormField name={name} label={t('Follow-Up Question')} form={form} />
        <div className={'pb-4'}>
          {t(
            'A follow-up question prompts the employees to provide additional information in a text box before submitting.'
          )}
        </div>
        {form.state.data.yesFollowUpQuestion && name === 'yesFollowUpQuestion' && (
          <DashboardRibbon className="mb-6" theme={Theme.LIGHT}>
            <TextareaFormField name={'yesFollowUpQuestionText'} form={form} restrictTo={{ maxLength: 500 }} />
          </DashboardRibbon>
        )}
        {form.state.data.noFollowUpQuestion && name === 'noFollowUpQuestion' && (
          <DashboardRibbon className="mb-6" theme={Theme.LIGHT}>
            <TextareaFormField name={'noFollowUpQuestionText'} form={form} restrictTo={{ maxLength: 500 }} />
          </DashboardRibbon>
        )}
      </>
    );
  }

  function renderHandleResponse(name: string, { ...form }: IFormRender<ICreateDailySignOffQuestionsFormData>) {
    return (
      <>
        <div className="header-title title-label mb-6">
          {name === 'yesResponses' ? t('Handle “Yes” Response') : t('Handle “No” Response')}
        </div>
        <SignOffQuestionResponseFormField name={name} form={form} />
      </>
    );
  }

  const classes = classNames('create-daily-sign-in-questions-form', className);

  return (
    <div>
      <Form data={formData ?? undefined} onSubmit={onSubmitForm} render={renderFormFields} className={classes} />
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationOpenable.isOpen}
        onClose={deleteConfirmationOpenable.close}
        onDelete={handleDelete}
        title={t('Delete question?')}
        showHeaderDivider={false}
      >
        {t(
          'If you delete this question it will be gone forever. Previously submitted answers will still be shown in the Daily Sign-Off report. Are you sure you want to proceed?'
        )}
      </DeleteConfirmationDialog>
      <Loader isOpen={loaderOpenable.isOpen} />
    </div>
  );
};

const WhomToAskField: React.FC<IFormRender<ICreateDailySignOffQuestionsFormData>> = (form) => (
  <>
    <div className="header-title title-label mb-6">{t('Whom to Ask')}</div>
    <WhomToAskFormField name={'whomToAsk'} form={form} />
  </>
);

const ExplanationField: React.FC<IFormRender<ICreateDailySignOffQuestionsFormData>> = (form) => (
  <>
    <HeaderText title={t('Explanation')} className="title-label mb-6">
      <div className="details-label">{t('Additional text that is displayed below the question.')}</div>
    </HeaderText>
    <TextareaFormField name={'explanation'} form={form} restrictTo={{ maxLength: 500 }} />
  </>
);

const QuestionField: React.FC<IFormRender<ICreateDailySignOffQuestionsFormData>> = (form) => (
  <>
    <HeaderText title={t('Question')} className="title-label mb-6">
      <div className="details-label">
        {t('Make sure to phrase the question so that it can be answered with a “Yes” or “No”.')}
      </div>
    </HeaderText>
    <TextareaFormField
      name={'question'}
      form={form}
      restrictTo={{ maxLength: 200 }}
      validations={[{ validation: v_require }]}
    />
  </>
);

const CreateButton: React.FC<IFormRender<ICreateDailySignOffQuestionsFormData>> = (form) => (
  <Button className="pl-5 pr-5" type="primary" onClick={form.handleSubmit}>
    {t('Create')}
  </Button>
);

export default CreateDailySignOffQuestionsForm;
