import { convertTimeLogTypedParamsToQueryStringParams } from 'containers/time-log-feed/hooks/useTimeLogFeedSetQueryParams/useTimeLogFeedSetQueryParams';
import { ILogFeedQueryParams } from 'containers/time-log-feed/types/types';
import { useCallback } from 'react';
import useNavigation from './useNavigation';

export default function useTimeLogNavigation() {
  const navigate = useNavigation<ReturnType<typeof convertTimeLogTypedParamsToQueryStringParams>>('/event-logs');

  return useCallback(
    (params?: ILogFeedQueryParams) => {
      const convertedToParams = params ? convertTimeLogTypedParamsToQueryStringParams(params) : undefined;
      return navigate(convertedToParams);
    },
    [navigate]
  );
}
