import { useQuery } from '@tanstack/react-query';
import {
  EquipmentEquipmentHoursMetricsQuery,
  EquipmentEquipmentHoursMetricsQueryVariables,
} from '__generated__/graphql';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import {
  convertDashboardTimeFrameToLaborMetricsInterval,
  groupItemsToTimeRange,
} from 'containers/dashboard/util/utils';
import { gql } from 'graphql-request';
import useGraphQLPaging from 'hooks/graphql/useGraphQLPaging/useGraphQLPaging';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import sumBy from 'lodash/sumBy';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import ITimeRange from 'types/TimeRange';
import { Nullable } from 'types/util/Nullable';
import { getGraphQlEqualComparison } from 'utils/apolloUtils';
import { sumByKey } from 'utils/collectionUtils';
import { dateTimeFromUtcISO } from 'utils/dateUtils';
import { roundIgnoreZeroes } from 'utils/numberUtils';

export default function useDashboardEquipmentHours(
  timeRange: ITimeRange<DateTime>,
  timeRanges: ITimeRange<number>[],
  timeFrame: DashboardTimeFrame,
  equipmentId?: Nullable<string>
) {
  const { data, error, isLoading, refetch } = useEquipmentHoursDashboardQuery(
    timeRange,
    timeRanges,
    timeFrame,
    equipmentId
  );

  return {
    data,
    total: data ? sumBy(data, (datum) => datum.value) : 0,
    loading: isLoading,
    error,
    execute: refetch,
  };
}

const EQUIPMENT_EQUIPMENT_HOURS_METRICS_QUERY = gql`
  query EquipmentEquipmentHoursMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    equipment(after: $after, first: $first, filter: $filter) {
      id
      cursor
      equipmentHourMeterMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        start
        end
        hoursDiff
      }
    }
  }
`;

function useEquipmentHoursDashboardQuery(
  timeRange: ITimeRange<DateTime>,
  timeRanges: ITimeRange<number>[],
  timeFrame: DashboardTimeFrame,
  equipmentId?: Nullable<string>
) {
  const pager = useGraphQLPaging();
  const query = useCallback(async () => {
    const result = await pager<EquipmentEquipmentHoursMetricsQuery, EquipmentEquipmentHoursMetricsQueryVariables>(
      {
        document: EQUIPMENT_EQUIPMENT_HOURS_METRICS_QUERY,
        variables: {
          first: 500,
          filter: {
            id: getGraphQlEqualComparison(equipmentId),
            deletedOn: { isNull: true },
          },
          metricsInterval: convertDashboardTimeFrameToLaborMetricsInterval(timeFrame),
          metricsStartDate: timeRange.startTime.toISODate(),
          metricsEndDate: timeRange.endTime.toISODate(),
        },
      },
      'equipment'
    );

    const hourMeters = result.flatMap(({ equipmentHourMeterMetrics }) => {
      return (
        equipmentHourMeterMetrics?.map((hourMeter) => ({
          hoursDiff: hourMeter.hoursDiff,
          startTime: dateTimeFromUtcISO(hourMeter.start).toSeconds(),
        })) ?? []
      );
    });

    const grouped = groupItemsToTimeRange(timeRanges, hourMeters);
    return timeRanges.reduce((acc, cur) => {
      const metrics = grouped[cur.startTime];

      if (metrics) {
        return [
          ...acc,
          {
            day: cur.startTime,
            value: roundIgnoreZeroes(sumByKey(metrics, 'hoursDiff'), 1),
          },
        ];
      } else {
        return [
          ...acc,
          {
            day: cur.startTime,
            value: 0,
          },
        ];
      }
    }, new Array<{ day: number; value: number }>());
  }, [equipmentId, pager, timeFrame, timeRange.endTime, timeRange.startTime, timeRanges]);

  const baseKey = useReactQueryBaseKey();
  return useQuery({ queryKey: [...baseKey, 'equipment-hours-dashboard-card'], queryFn: query });
}
