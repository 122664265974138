import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { PayPeriodSignaturesLoaded } from 'containers/timesheets/TimeCardReport/context/TimeCardReportContextProvider';
import { DateTime } from 'luxon';
import { createContext } from 'react';
import { IMember } from 'types';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';

export interface ExpandedTimeCardReportDailySummaryInfo {
  date: DateTime;
  timeAccurate: boolean | null;
  injured: boolean | null;
  breakCompliance: boolean | null;
  regularHours: number | null;
  overtimeHours: number | null;
  doubleTimeHours: number | null;
  paidTimeOff: number | null;
  totalHours: number | null;
}

export type ExpandedTimeCardReportMemberData = IMember &
  ICursorable & {
    totalSeconds: number;
    totalRegularSeconds: number;
    totalOvertimeSeconds: number;
    totalDoubleTimeSeconds: number;
  } & {
    timeEntryData: ITimeEntryDataTableRow[] | null;
    projectSummaryData: (IProject & ICursorable)[] | null;
    costCodeSummaryData: (ICostCode & ICursorable)[] | null;
    equipmentSummaryData: (IEquipment & ICursorable)[] | null;
    dailySummaryData: ExpandedTimeCardReportDailySummaryInfo[] | null;
  };

export interface IExpandedTimeCardReportContextPayload {
  data: ExpandedTimeCardReportMemberData[];
  loadedAll: boolean;
  getExpandedTimeCardReportDataForMember: (member: IMember) => ExpandedTimeCardReportMemberData | null;
  refreshMembersData: (memberIds: string[], timeRange: ITimeRange<DateTime>) => Promise<void>;
  populateData: (members: IMember[], timeRange: ITimeRange<DateTime>) => Promise<ExpandedTimeCardReportMemberData[]>;
  forceLoadAll: (showAllMembers: boolean) => Promise<ExpandedTimeCardReportMemberData[]>;
  clearData: () => void;
  signaturesLoaded: PayPeriodSignaturesLoaded[];
  onSignaturesLoaded?: (memberId: string, isLoaded: boolean) => void;
  areAllSignaturesLoaded?: () => boolean;
  loading: boolean;
}

export const ExpandedTimeCardReportContext = createContext<IExpandedTimeCardReportContextPayload>(
  {} as IExpandedTimeCardReportContextPayload
);
