import { Form, Label, Link } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import FormAccordion from 'components/foundation/FormAccordion/FormAccordion';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import { t } from 'utils/localize';
import './CaliforniaBreakLawsForm.scss';

export interface ICaliforniaBreakLawsInfoProps {
  className?: ClassName;
}

interface ICaliforniaInformation {
  hoursLabel: string;
  requirementsLabel: string;
}

interface ICaliforniaHeaderInfo {
  member: IMember;
  totalBreakTime: string;
}

const hours = t('hrs');
const mealsInformation: ICaliforniaInformation[] = [
  {
    hoursLabel: '0 - 5 ' + hours,
    requirementsLabel: '0',
  },
  {
    hoursLabel: '5:01 - 10 ' + hours,
    requirementsLabel: '1',
  },
  {
    hoursLabel: '10:01 - 15 ' + hours,
    requirementsLabel: '2',
  },
  {
    hoursLabel: '15:01 - 20 ' + hours,
    requirementsLabel: '3',
  },
  {
    hoursLabel: '20:01 -',
    requirementsLabel: '4',
  },
];

const restInformation: ICaliforniaInformation[] = [
  {
    hoursLabel: '0 - 3:29 ' + hours,
    requirementsLabel: '0',
  },
  {
    hoursLabel: '3:30 - 6 ' + hours,
    requirementsLabel: '1',
  },
  {
    hoursLabel: '6:01 - 10 ' + hours,
    requirementsLabel: '2',
  },
  {
    hoursLabel: '10:01 - 14 ' + hours,
    requirementsLabel: '3',
  },
  {
    hoursLabel: '14:01 - 18 ' + hours,
    requirementsLabel: '4',
  },
  {
    hoursLabel: '18:01 - 22 ' + hours,
    requirementsLabel: '5',
  },
];

const CaliforniaBreakLawsInfo = (props: ICaliforniaBreakLawsInfoProps) => {
  const { className } = props;
  const brand = useBrandTitle();

  const classes = classNames(
    {
      'california-break-laws-form': true,
    },
    className
  );

  const renderDialogContent = ({ ...form }) => {
    return (
      <>
        <FormAccordion className="mt-4" openByDefault={true} title={t('Meal Breaks (Unpaid)')} form={form}>
          {renderMealUi()}
        </FormAccordion>

        <FormAccordion className="mt-4" title={t('Additional Information & Requirements')} form={form}>
          {renderPaidBreaksUi()}
        </FormAccordion>
      </>
    );
  };

  const renderMealUi = () => {
    const mealInformationRows = mealsInformation.map((row) => {
      return (
        <div key={row.hoursLabel} className=" ml-2 row-item">
          <Label className="my-2">{row.hoursLabel}</Label>
          <Label className="my-2">{row.requirementsLabel}</Label>
        </div>
      );
    });

    const header = (
      <div key="meals header" className="ml-2 row-item">
        <Label className="my-2 fw-bold">{t('Hours on the Clock')}</Label>
        <Label className="my-2 fw-bold">{t('# Meal Breaks')}</Label>
      </div>
    );

    return [header, ...mealInformationRows];
  };

  const renderPaidBreaksUi = () => {
    const restInformationRows = restInformation.map((row) => {
      return (
        <div key={row.hoursLabel} className="ml-2 row-item">
          <Label className="my-2">{row.hoursLabel}</Label>
          <Label className="my-2">{row.requirementsLabel}</Label>
        </div>
      );
    });

    const header = (
      <div key="paid breaks header" className="ml-2 ">
        <Label className="section-title mt-4">{t('Rest Breaks (Paid)')}</Label>
        <Label className="subtitle-text">{t("{{brand}} doesn't track rest (paid) breaks.", { brand })}</Label>

        <div className="row-item">
          <Label className="my-2 fw-bold">{t('Hours on the Clock')}</Label>
          <Label className="my-2 fw-bold">{t('# Rest Breaks')}</Label>
        </div>
      </div>
    );

    const footer = (
      <div key="paid breaks footer" className="ml-2 ">
        <Label className="section-title mt-6">{t('Additional Information')}</Label>
        <Label className="subtitle-text ">
          {t('Click the following links for additional information on California break laws.')}
        </Label>
        <Link className="link mt-2" newTab={true} href="https://www.dir.ca.gov/dlse/faq_mealperiods.htm">
          {t('Meal Periods')}
        </Link>
        <br />
        <Link className="link mt-2" newTab={true} href="https://www.dir.ca.gov/dlse/FAQ_RestPeriods.htm">
          {t('Rest Periods')}
        </Link>
      </div>
    );

    return [header, ...restInformationRows, footer];
  };

  return <Form className={classes} onSubmit={() => 0} render={renderDialogContent} />;
};

export default CaliforniaBreakLawsInfo;
