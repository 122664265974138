import { useOrganization } from 'hooks';
import TimeRangeType from 'types/TimeRangeType';
import { getCurrentPayPeriodRange } from 'utils/payPeriodUtils';
import { getWeekRangeForTime } from 'utils/timeRangeUtils';
import { IDateActivityTableRowInfo } from '../../date-tables/hooks/useDateActivity';

export default function useDateActivityPartialRangeDetection(dateRangeReportType?: TimeRangeType) {
  const organization = useOrganization();

  function isPartial(item: IDateActivityTableRowInfo) {
    switch (dateRangeReportType) {
      case TimeRangeType.MONTHLY: {
        const firstDayOfMonth = item.startDate.startOf('month');
        const lastDayOfMonth = item.startDate.endOf('month');
        return !item.startDate.hasSame(firstDayOfMonth, 'day') || !item.endDate.hasSame(lastDayOfMonth, 'day');
      }
      case TimeRangeType.PAY_PERIOD: {
        const payPeriod = getCurrentPayPeriodRange(
          organization.organizationPayPeriod!,
          item.startDate.toSeconds(),
          'utc'
        )!;
        return !item.startDate.hasSame(payPeriod.startTime, 'day') || !item.endDate.hasSame(payPeriod.endTime, 'day');
      }
      default: {
        const week = getWeekRangeForTime(organization.organizationOvertimePeriods!, item.startDate.toSeconds())!;
        return !item.startDate.hasSame(week.startTime, 'day') || !item.endDate.hasSame(week.endTime, 'day');
      }
    }
  }

  return {
    isPartial,
  };
}
