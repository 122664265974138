import gql from 'graphql-tag';

export const DAILY_REPORT_QUERY = gql`
  query dailyProjectReportJoined(
    $filter: DailyProjectReportFilter
    $first: Int
    $sort: [DailyProjectReportSort!]
    $after: String
  ) {
    dailyProjectReports(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      cursor
      projectId
      project {
        id
        title
        rootProjectId
        depth
        ancestors {
          id
          title
          depth
        }
        children {
          id
          title
          depth
        }
      }
      createdBy
      createdByMember {
        id
        firstName
        lastName
      }
      reportDate
      fileUrl
      template {
        id
        title
        deletedOn
      }
      createdOn
      deletedOn
    }
  }
`;

export const SIMPLE_DPR_QUERY = gql`
  query simpleDailyProjectReportJoined(
    $filter: DailyProjectReportFilter
    $first: Int
    $sort: [DailyProjectReportSort!]
    $after: String
  ) {
    dailyProjectReports(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      deletedOn
    }
  }
`;

export const projectQuery = gql`
  query project($projectId: Uuid) {
    projects(
      filter: { id: { equal: $projectId }, archivedOn: { isNull: true } }
      first: 1
      sort: [{ createdOn: desc }]
    ) {
      id
      title
      projectInfo {
        address1
        address2
        city
        county
        country
        state
        phone
        postalCode
        number
        latitude
        longitude
      }
      children {
        id
        title
        archivedOn
      }
    }
  }
`;

export const timeEntriesQuery = gql`
  query timeEntryWithJoined($projectId: Uuid, $startTime: Timestamp, $startTimeEnd: Timestamp) {
    timeEntries(
      filter: {
        projectId: { equal: $projectId }
        startTime: { greaterThanOrEqual: $startTime, lessThanOrEqual: $startTimeEnd }
        deletedOn: { isNull: true }
      }
    ) {
      id
      memberId
      member {
        id
        firstName
        lastName
      }
      startTime
      endTime
      equipment {
        id
        equipmentName
        model {
          id
          title
          make {
            id
            title
          }
        }
      }
      costCode {
        id
        costCode
        title
      }
      breaks {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
      }
    }
  }
`;

export const safetySignaturesQuery = gql`
  query SafetySignature($startTime: Timestamp, $startTimeEnd: Timestamp) {
    safetySignatures(
      filter: {
        startTime: { greaterThanOrEqual: $startTime, lessThanOrEqual: $startTimeEnd }
        deletedOn: { isNull: true }
      }
    ) {
      id
      startTime
      endTime
      injured
      injuryDescription
      timeAccurate
      timeAccurateReason
      memberId
      member {
        id
        firstName
        lastName
      }
    }
  }
`;
export const DAILY_REPORT_TEMPLATE_QUERY = gql`
  query DailyProjectReportTemplate($filter: DailyProjectReportTemplateFilter) {
    dailyProjectReportTemplates(filter: $filter) {
      id
      positionId
      organizationId
      audienceType
      sections
      title
      appearance
      createdOn
      position {
        id
        title
      }
    }
  }
`;
