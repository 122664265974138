import useGustoCompanyId from 'components/domain/integration/gusto/hooks/company/useGustoCompanyId/useGustoCompanyId';
import useGustoGetCompany from 'components/domain/integration/gusto/hooks/company/useGustoGetCompany/useGustoGetCompany';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGustoCompany } from 'store/gusto/gusto';
import { IChildrenProps } from 'types/ChildrenProps';
import useGustoUpdateTermsOfServiceStatusState from 'components/domain/integration/gusto/hooks/terms-of-service/useGustoUpdateTermsOfServiceStatusState/useGustoUpdateTermsOfServiceStatusState';
import useGustoPaidEnabled from 'components/domain/integration/gusto/hooks/meta/useGustoPaidEnabled/useGustoPaidEnabled';

function GustoMiddleware({ children }: IChildrenProps) {
  const getCompany = useGustoGetCompany();
  const gustoCompanyId = useGustoCompanyId();
  const dispatch = useDispatch();
  const updateTos = useGustoUpdateTermsOfServiceStatusState();

  useEffect(() => {
    if (gustoCompanyId) {
      getCompany(gustoCompanyId)
        .then((company) => {
          dispatch(setGustoCompany(company));
        })
        .catch(() => {
          // Catching because of desynced getCompany/gustoCompanyId can cause a 404.
        });
    }
  }, [dispatch, getCompany, gustoCompanyId]);

  useEffect(() => {
    updateTos();
  }, [gustoCompanyId, updateTos]);

  return <>{children}</>;
}

export default function GustoMiddlewareContainer({ children }: IChildrenProps) {
  const gustoIsEnabled = useGustoPaidEnabled();

  if (!gustoIsEnabled) {
    return <>{children}</>;
  }

  return <GustoMiddleware>{children}</GustoMiddleware>;
}
