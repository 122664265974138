import { useApolloClient } from '@apollo/client';
import { Divider, Label, Theme, Toast } from '@busybusy/webapp-react-ui';
import { EQUIPMENT_HOURS_REPORT_DETAILS_QUERY } from 'apollo/queries/equipment-hours-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { useEquipmentHours } from 'hooks';
import { isNil, last } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import IEquipmentHours from 'types/EquipmentHours';
import { dateTimeFromUtcISO } from 'utils/dateUtils';
import { makeModelString } from 'utils/equipmentUtils';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import './HourMeterDetails.scss';

export interface IHourMeterDetailsProps {
  className?: ClassName;
  equipmentHourId: string;
}

interface IHourMeterDetailsData {
  equipmentName: string;
  equipmentMakeModel: string;
  quantity: string;
  submittedOn: string;
  submittedBy: string;
  previousValue: string;
  newValue: string;
}

const HourMeterDetails = (props: IHourMeterDetailsProps) => {
  const { className, equipmentHourId } = props;

  const client = useApolloClient();
  const { getEquipmentHoursBefore } = useEquipmentHours();
  const [data, setData] = useState<IHourMeterDetailsData>();
  const [errorToastMessage, setErrorToastMessage] = useState('');

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentHourId]);

  async function getData() {
    const hoursResult = await client.query<{ equipmentHours: IEquipmentHours[] }>({
      query: EQUIPMENT_HOURS_REPORT_DETAILS_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: equipmentHourId },
          deletedOn: { isNull: true },
        },
        sort: [{ createdOn: 'asc' }, { runningHours: 'asc' }],
      },
      fetchPolicy: 'network-only',
    });

    const currentHours = last(hoursResult.data.equipmentHours);
    if (currentHours) {
      const hoursBefore = await getEquipmentHoursBefore(
        DateTime.fromISO(currentHours.createdOn),
        currentHours.equipment.id
      );

      const quantity = currentHours.runningHours - (hoursBefore?.runningHours ?? 0);

      const item: IHourMeterDetailsData = {
        equipmentName: currentHours.equipment.equipmentName,
        equipmentMakeModel: makeModelString(currentHours.equipment),
        quantity: quantity === currentHours.runningHours ? Number(0).toFixed(1) : getQuantityString(quantity),
        submittedOn: dateTimeFromUtcISO(currentHours.createdOn).toLocal().toFormat('EEE, DD @ t'),
        submittedBy: !isNil(currentHours.submittedByMember)
          ? fullName(currentHours.submittedByMember)
          : t('VisionLink'),
        previousValue: hoursBefore
          ? hoursBefore.runningHours === currentHours.runningHours
            ? Number(0).toFixed(1)
            : hoursBefore.runningHours.toFixed(1)
          : '---',
        newValue: currentHours.runningHours.toFixed(1),
      };
      setData(item);
    } else {
      if (!isNil(hoursResult.errors)) {
        setErrorToastMessage(t('Server Error'));
        return;
      }
    }
  }

  function getQuantityString(num: number) {
    return Math.sign(num) === -1 ? '- ' + (num * -1).toFixed(1) : '+ ' + num.toFixed(1);
  }

  function handleErrorToastClose() {
    setErrorToastMessage('');
  }

  const classes = classNames('hour-meter-details p-8 mb-6', className);

  return (
    <div className={classes}>
      <HeaderText title={t('Machine')} className="title-label mb-6">
        <div className="details-label">{data ? data.equipmentName : '---'}</div>
        <div className="details-label">{data ? data.equipmentMakeModel : '---'}</div>
      </HeaderText>
      <HeaderText title={t('Quantity')} className="title-label mb-6">
        <div className="details-label">{data ? data.quantity + ' ' + t('hrs') : '---'}</div>
      </HeaderText>
      <HeaderText title={t('Submitted On')} className="title-label mb-6">
        <div className="details-label">{data ? data.submittedOn : '---'}</div>
      </HeaderText>
      <HeaderText title={t('Submitted By')} className="title-label mb-6">
        <div className="details-label">{data ? data.submittedBy : '---'}</div>
      </HeaderText>
      <Divider className="mt-6 mb-6" />
      <Label className="heading-label mb-6">{t('HOUR METER')}</Label>
      <HeaderText title={t('Previous Value')} className="title-label mb-6">
        <div className="details-label">{data ? data.previousValue : '---'}</div>
      </HeaderText>
      <HeaderText title={t('New Value')} className="title-label mb-6">
        <div className="details-label">{data ? data.newValue : '---'}</div>
      </HeaderText>
      <Toast isOpen={errorToastMessage.length !== 0} onClose={handleErrorToastClose} theme={Theme.DANGER}>
        {errorToastMessage}
      </Toast>
    </div>
  );
};

export default HourMeterDetails;
