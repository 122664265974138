import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import { Badge } from 'components';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import { useOpenable } from 'hooks';
import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { numberOfDaysBetween } from 'utils/dateUtils';
import { t } from 'utils/localize';
import TimeEntryDataTableHeader from '../../TimeEntryDataTable/TimeEntryDataTableHeader/TimeEntryDataTableHeader';
import TimeEntryDataReport, { ITimeEntryDataReportProps } from '../TimeEntryDataReport';
import useCheckArchivedOfCostCodes from '../hooks/useCheckArchivedOfCostCodes';
import useCheckArchivedOfProject from '../hooks/useCheckArchivedOfProject';
import useCheckDeletedOfEquipment from '../hooks/useCheckDeleteOfEquipment';
import { ITimeEntryDataReportMemberData } from './hooks/useMemberTimeEntryDataReportData';
import './MemberTimeEntryDataReport.scss';
import classNames from 'classnames';

export interface IMemberTimeEntryDataReportProps
  extends Omit<
    ITimeEntryDataReportProps<ITimeEntryDataReportMemberData>,
    'onTimeOffFormDialogOpen' | 'openAddTimeOff' | 'renderHeader'
  > {
  onDataChange: (memberIds: string[]) => void;
  projectId?: string | null;
  costCodeId?: string | null;
  equipmentId?: string | null;
  rowHeight?: TableCellHeight;
}

function MemberTimeEntryDataReport({
  timeRange,
  selectedIds,
  projectId,
  costCodeId,
  equipmentId,
  rowHeight,
  className,
  ...timeEntryDataReportProps
}: IMemberTimeEntryDataReportProps) {
  const timeOffOpenable = useOpenable();
  const timeEntryDialogOpenable = useOpenable();
  const [memberId, setMemberId] = useState<string | null>(null);
  const timeOffId = useRef<string | null>(null);
  const checkArchivedOfProjects = useCheckArchivedOfProject();
  const [dialogProjectId, setDialogProjectId] = useState<string | null | undefined>(null);
  const checkArchivedOfCostCodes = useCheckArchivedOfCostCodes();
  const [dialogCostCodeId, setDialogCostCodeId] = useState<string | null | undefined>(null);
  const checkDeletedOfEquipment = useCheckDeletedOfEquipment();
  const [dialogEquipmentId, setDialogEquipmentId] = useState<string | null | undefined>(null);

  function renderHeader(item: ITimeEntryDataReportMemberData) {
    async function handleAddEntry() {
      setMemberId(item.id);
      if (await checkArchivedOfProjects(!isNil(projectId) ? [projectId] : null)) {
        setDialogProjectId(null);
      } else {
        setDialogProjectId(projectId);
      }
      if (await checkArchivedOfCostCodes(!isNil(costCodeId) ? [costCodeId] : null)) {
        setDialogCostCodeId(null);
      } else {
        setDialogCostCodeId(costCodeId);
      }
      if (await checkDeletedOfEquipment(!isNil(equipmentId) ? [equipmentId] : null)) {
        setDialogEquipmentId(null);
      } else {
        setDialogEquipmentId(equipmentId);
      }
      timeEntryDialogOpenable.open();
    }

    function handleAddtimeOff() {
      setMemberId(item.id);
      timeOffOpenable.open();
    }

    return (
      <TimeEntryDataTableHeader
        title={item.title}
        total={item.total}
        openAddEntry={item.canManage && !item.isArchived ? handleAddEntry : undefined}
        openAddTimeOff={item.canManageTimeOff && !item.isArchived ? handleAddtimeOff : undefined}
        rightTitleNode={
          item.isArchived ? (
            <Badge key={item.id + '-archived'} className={'ml-6'}>
              {t('ARCHIVED')}
            </Badge>
          ) : undefined
        }
      />
    );
  }

  function handleAddEntrySubmit(data: ITimeActionsFormData) {
    timeEntryDataReportProps.onDataChange?.(data.members);
    timeEntryDialogOpenable.close();
  }

  function handleTimeOffEditSubmit(data?: ITimeOffFormData | undefined) {
    if (data) {
      timeEntryDataReportProps.onDataChange?.(data.members);
    }
    timeOffOpenable.close();
  }

  const classes = classNames('member-time-entry-data-report', className);

  return (
    <>
      <TimeEntryDataReport
        {...timeEntryDataReportProps}
        className={classes}
        timeRange={timeRange}
        selectedIds={selectedIds}
        renderHeader={renderHeader}
        rowHeight={rowHeight}
      />

      <TimeActionsFormDialog
        type={'add'}
        memberIds={memberId ? [memberId] : undefined}
        projectId={dialogProjectId}
        costCodeId={dialogCostCodeId}
        equipmentId={dialogEquipmentId}
        currentDate={timeRange.endTime}
        isOpen={timeEntryDialogOpenable.isOpen}
        onClose={timeEntryDialogOpenable.close}
        onSubmit={handleAddEntrySubmit}
        onDelete={handleAddEntrySubmit}
      />

      <TimeOffFormDialog
        memberIds={memberId ? [memberId] : null}
        currentDate={numberOfDaysBetween(timeRange.startTime, timeRange.endTime) === 1 ? timeRange.endTime : null}
        timeOffId={timeOffId.current}
        isOpen={timeOffOpenable.isOpen}
        onClose={timeOffOpenable.close}
        onSubmit={handleTimeOffEditSubmit}
        onEdit={(mId) => {
          if (mId) {
            timeEntryDataReportProps.onDataChange([mId]);
          }
          timeOffOpenable.close();
        }}
      />
    </>
  );
}

MemberTimeEntryDataReport.defaultProps = {
  applyPrintOptions: true,
};

export default MemberTimeEntryDataReport;
