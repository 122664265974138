import {
  Button,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  Label,
  Theme,
} from '@busybusy/webapp-react-ui';
import { EquipmentCostHistory } from '__generated__/graphql';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Well } from 'components';
import CurrencyFormField from 'components/foundation/form-fields/CurrencyFormField/CurrencyFormField';
import { useOpenable } from 'hooks';
import useUpdateEquipmentCostHistory from 'hooks/models/equipment-cost-history/useUpdateEquipmentCostHistory';
import { t } from 'i18next';
import { isNull } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState } from 'react';
import { v_require } from 'utils/validations';

export interface IEquipmentCostHistoryFormProps {
  className?: ClassName;
  costHistory: EquipmentCostHistory;
  allCostHistories: EquipmentCostHistory[];
  onChange: () => void;
}

interface IEquipmentCostHistoryFormData {
  cost: number;
  effectiveDate: IDatePickerPayload;
}

const EquipmentCostHistoryForm = (props: IEquipmentCostHistoryFormProps) => {
  const { className, costHistory, onChange, allCostHistories } = props;

  const dateDetails = useOpenable();
  const [formData, setFormData] = useState<IEquipmentCostHistoryFormData>(getData());
  const updateEquipmentCostHistory = useUpdateEquipmentCostHistory();
  const [validationWarning, setValidationWarning] = useState<string | null>(null);

  useEffect(() => {
    setFormData(getData());
  }, [costHistory]);

  function getData() {
    return {
      cost: costHistory.operatorCostRate ?? 0,
      effectiveDate: {
        value: DateTime.fromISO(costHistory.changeDate).toJSDate(),
        inputValue: DateTime.fromISO(costHistory.changeDate).toFormat('M/D/YYYY'),
      },
    };
  }

  async function handleSubmit(data: IEquipmentCostHistoryFormData) {
    if (!isEffectiveDateValid(data.effectiveDate)) {
      return;
    }

    await updateEquipmentCostHistory(
      costHistory.id,
      undefined,
      data.cost,
      DateTime.fromJSDate(data.effectiveDate.value!).startOf('day').toISO()
    );
    onChange();
  }

  function isEffectiveDateValid(payload: IDatePickerPayload): boolean {
    const existingCostHistory = allCostHistories.find(
      (ch) =>
        DateTime.fromISO(ch.changeDate).startOf('day').toISO() ===
        DateTime.fromJSDate(payload.value!).startOf('day').toISO()
    );

    setValidationWarning(null);
    if (existingCostHistory && existingCostHistory.id !== costHistory.id) {
      setValidationWarning(t('There is already a cost history with this effective date.'));
      return false;
    }
    return true;
  }

  function renderFormFields({ ...form }: IFormRender<IEquipmentCostHistoryFormData>): ReactNode {
    return (
      <div className="pt-9">
        <Label>{t('Hourly Rate')}</Label>
        <CurrencyFormField
          className="hourly-cost-field"
          name="cost"
          form={form}
          validations={[{ validation: v_require }]}
          textAlign="right"
          placeholder="0.00"
          autofocus={true}
        />
        <Label>{t('Effective Date')}</Label>
        <div className="mb-2">
          {t(
            'The date you would like the rate change to take effect. Setting the date to the past will change past job costing data.'
          )}
        </div>
        <DatePickerFormField
          name="effectiveDate"
          form={form}
          validations={[{ validation: v_require }]}
          isOpen={dateDetails.isOpen}
          onClose={dateDetails.close}
          onOpen={dateDetails.open}
          onChange={(payload) => {
            if (payload.value) {
              isEffectiveDateValid(payload);
            }
          }}
        />
        {!isNull(validationWarning) && <Well theme={Theme.DANGER}>{validationWarning}</Well>}
        <div className="pt-6">
          <Button onClick={form.handleSubmit} type="primary">
            {t('Update')}
          </Button>
        </div>
      </div>
    );
  }

  const classes = classNames('equipment-cost-history-form', 'p-5', className);

  return (
    <Form
      className={classes}
      data={formData}
      render={renderFormFields}
      onSubmit={handleSubmit}
      allowMultipleSubmissions={true}
    />
  );
};

export default EquipmentCostHistoryForm;
