import { useActiveMember } from 'hooks';
import { castArray, compact } from 'lodash';
import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { IChildrenProps } from 'types/ChildrenProps';
import { t } from 'utils/localize';
import { IAccessControlProps } from './AccessControl';
const NotAllowed = lazy(() => import('containers/error/NotAllowed'));

export interface IValidatePermissionProps extends Pick<IAccessControlProps, 'permissions'>, IChildrenProps {
  fallback?: React.ReactElement;
}

export const ValidatePermission = ({
  children,
  permissions,
  fallback = <NotAllowed message={t(`Permission denied`)} />,
}: IValidatePermissionProps) => {
  const activeMember = useActiveMember();
  const isPermissionAllowed = compact(castArray(permissions)).every((permission) => {
    return activeMember?.position && activeMember.position[permission];
  });
  const location = useLocation();

  return isPermissionAllowed ? <>{children}</> : location.pathname === '/' ? <Navigate to="/login" /> : fallback;
};
