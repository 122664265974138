import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { isoTimeStampUtc } from 'utils/dateUtils';
import { EquipmentCostHistory, EquipmentCostHistoryUpdateInput } from '__generated__/graphql';
import { UPDATE_EQUIPMENT_COST_HISTORY } from './useUpdateEquipmentCostHistory';

export default function useDeleteEquipmentCostHistory() {
  const [updateEquipmentCostHistory] = useMutation(UPDATE_EQUIPMENT_COST_HISTORY);

  return useCallback(
    (id: string) => {
      const input: EquipmentCostHistoryUpdateInput = {
        id,
        deletedOn: isoTimeStampUtc(),
      };

      return new Promise<EquipmentCostHistory>((resolve, reject) => {
        return updateEquipmentCostHistory({ variables: { equipmentCostHistory: input } }).then((results) => {
          if (results.data) {
            resolve(results.data.updateEquipmentCostHistory);
          } else {
            reject();
          }
        });
      });
    },
    [updateEquipmentCostHistory]
  );
}
