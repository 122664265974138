import { useEffectivePolicyQuery, useNextPolicyQuery } from './Policies/hooks';
import { POLICY_KEY_PRIVACY, POLICY_KEY_TERMS } from './Policies/types';
import { useActiveMemberPolicyMemberAcceptanceQuery } from './PolicyMemberAcceptances/hooks';

export const usePolicyQueries = () => {
  const memberPolicyTerms = useActiveMemberPolicyMemberAcceptanceQuery(POLICY_KEY_TERMS);
  const memberPolicyPrivacy = useActiveMemberPolicyMemberAcceptanceQuery(POLICY_KEY_PRIVACY);
  const effectivePolicyTerms = useEffectivePolicyQuery(POLICY_KEY_TERMS);
  const effectivePolicyPrivacy = useEffectivePolicyQuery(POLICY_KEY_PRIVACY);
  const nextPolicyTerms = useNextPolicyQuery(POLICY_KEY_TERMS);
  const nextPolicyPrivacy = useNextPolicyQuery(POLICY_KEY_PRIVACY);

  const isLoading =
    memberPolicyTerms.loading ||
    memberPolicyPrivacy.loading ||
    effectivePolicyTerms.loading ||
    effectivePolicyPrivacy.loading ||
    nextPolicyTerms.loading ||
    nextPolicyPrivacy.loading;

  const errors = [
    memberPolicyTerms.error,
    memberPolicyPrivacy.error,
    effectivePolicyTerms.error,
    effectivePolicyPrivacy.error,
    nextPolicyTerms.error,
    nextPolicyPrivacy.error,
  ].filter((item) => item);

  const refetch = () => {
    memberPolicyTerms.refetch();
    memberPolicyPrivacy.refetch();
    effectivePolicyTerms.refetch();
    effectivePolicyPrivacy.refetch();
    nextPolicyTerms.refetch();
    nextPolicyPrivacy.refetch();
  };

  return {
    isLoading,
    errors,
    refetch,
    hasError: errors.length > 0,
    memberPolicyTerms,
    memberPolicyPrivacy,
    effectivePolicyTerms,
    effectivePolicyPrivacy,
    nextPolicyTerms,
    nextPolicyPrivacy,
  };
};

export type TUsePolicyQueries = ReturnType<typeof usePolicyQueries>;
