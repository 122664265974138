import { useMutation } from '@apollo/client';
import { FlaggedTimeEntryLocationUpdateInput } from '__generated__/graphql';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export default function useUpdateFlaggedTimeEntryLocation() {
  const [updateFlaggedTimeEntryLocation] = useMutation(UPDATE_FLAGGED_TIME_ENTRY_LOCATION);

  return useCallback((saveData: FlaggedTimeEntryLocationUpdateInput) => {
    return new Promise<FlaggedTimeEntryLocationUpdateInput>(async (resolve, reject) => {
      const results = await updateFlaggedTimeEntryLocation({ variables: { flaggedTimeEntryLocation: saveData } });
      if (results.data) {
        resolve(results.data.updateFlaggedTimeEntryLocation);
      } else {
        reject();
      }
    });
  }, []);
}

export const UPDATE_FLAGGED_TIME_ENTRY_LOCATION = gql`
  mutation UpdateFlaggedTimeEntryLocation($flaggedTimeEntryLocation: FlaggedTimeEntryLocationUpdateInput!) {
    updateFlaggedTimeEntryLocation(flaggedTimeEntryLocation: $flaggedTimeEntryLocation) {
      id
      status
      comment
    }
  }
`;
