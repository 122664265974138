import { t } from 'utils/localize';

export default function v_min_length(value: string, args: { min: number; message?: string }) {
  return {
    message: args.message || t('Not enough characters'),
    validate: () => {
      return value.length >= args.min;
    },
  };
}
