import { ClassName } from '@busybusy/webapp-react-ui';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import ManagedTimeRangeReportDialog from 'components/foundation/dialogs/ManagedTimeRangeReportDialog/ManagedTimeRangeReportDialog';
import TimeCardReadyToSignWell from 'containers/my-status/TimeCardReadyToSignWell/TimeCardReadyToSignWell';
import useTimesheetsGraylog from 'hooks/analytics/useTimesheetsGraylog';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearCheckedMemberIds } from 'store/timesheets/Timesheets';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { t } from 'utils/localize';
import TimesheetsMemberActionBar from '../action-bar/TimesheetsMemberActionBar/TimesheetsMemberActionBar';
import { TimeCardReportContext } from '../TimeCardReport/context/TimeCardReportContext';
import TimeCardReport from '../TimeCardReport/TimeCardReport';

export interface ITimeCardReportDialogProps {
  isOpen: boolean;
  onClose: () => void;
  memberIds?: string[] | null;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  onDataChange: (members: string[]) => void;
  onSignatureComplete?: (member: string) => void;
  className?: ClassName;
}

const TimeCardReportDialog = (props: ITimeCardReportDialogProps) => {
  const { className, isOpen, onClose, memberIds, timeRange, timeRangeType, onDataChange, onSignatureComplete } = props;

  const { refreshMembersData } = useContext(TimeCardReportContext);
  const userEvents = useTimesheetsGraylog();
  const dispatch = useDispatch();
  const [forceReadyToSignReload, setForceReadyToSignReload] = useState<boolean>(false);

  const onUserEvent = useRef(userEvents.events);

  useEffect(() => {
    if (isOpen) {
      onUserEvent.current(TimesheetsTypes.events.action_type.VIEW_TIME_CARDS);
    }
  }, [isOpen]);

  function renderTimeCardReport(componentTimeRange: ITimeRange<DateTime>): ReactNode {
    function onSignComplete(memberId: string) {
      setForceReadyToSignReload(!forceReadyToSignReload);
      onSignatureComplete?.(memberId);
    }
    return (
      <>
        {!isNil(memberIds) && memberIds.length === 1 && (
          <TimeCardReadyToSignWell
            memberId={memberIds[0]}
            type={'time-card-report'}
            timeRange={componentTimeRange}
            timeRangeType={timeRangeType}
            forceReload={forceReadyToSignReload}
          />
        )}
        <TimeCardReport
          employeeIds={memberIds}
          timeRange={componentTimeRange}
          timeRangeType={timeRangeType}
          onDataChange={onDataChange}
          onSignatureComplete={onSignComplete}
        />
      </>
    );
  }

  function renderActionBar(range: ITimeRange<DateTime>) {
    function refreshData(employeeIds: string[]) {
      setTimeout(() => {
        refreshMembersData(employeeIds, range);
      }, 1000);

      onDataChange(employeeIds);
      dispatch(clearCheckedMemberIds());
    }

    function onTimeEntryFormDataSubmit(formData: ITimeActionsFormData) {
      refreshData(formData.members);
    }

    function onTimeOffFormDataSubmit(formData: ITimeOffFormData | undefined) {
      if (formData) {
        refreshData(formData.members);
      }
    }

    return (
      <TimesheetsMemberActionBar
        onTimeEntryFormSubmit={onTimeEntryFormDataSubmit}
        onTimeOffFormSubmit={onTimeOffFormDataSubmit}
      />
    );
  }

  return (
    <ManagedTimeRangeReportDialog
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      timeRange={timeRange}
      timeRangeType={timeRangeType}
      reportComponent={renderTimeCardReport}
      title={t('Time Card')}
      renderActionBar={renderActionBar}
    />
  );
};

export default TimeCardReportDialog;
