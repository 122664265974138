import Graylog from 'components/domain/analytics/Graylog/Graylog';
import { useActiveMember } from 'hooks';
import TimeRangeType from 'types/TimeRangeType';
import AnalyticsEvent from 'utils/constants/analytics/analyticsEvent';

export type AccessProgressTrackingType = 'Projects Summary' | 'Progress Submissions';
export type ProgressDateType = 'Previous' | 'Next' | 'Custom';
export type ProgressRangeViewType = 'All Time' | 'Pay Period' | 'Monthly' | 'Weekly' | 'Daily' | 'Custom';
export type ProgressDetailsType = 'dashboard' | 'project_dashboard' | 'cost_code_dashboard' | 'report';

export default function useProgressGraylog() {
  const activeMember = useActiveMember();

  function reportViewed(reportType: AccessProgressTrackingType) {
    Graylog({
      shortMsg: AnalyticsEvent.progress.ACCESSED_PROGRESS_TRACKING,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _report_type: reportType,
      },
    });
  }

  function projectDetailsViewed(reportType: AccessProgressTrackingType) {
    Graylog({
      shortMsg: AnalyticsEvent.progress.ACCESSED_PROGRESS_PROJECT_DETAILS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _report_type: reportType,
      },
    });
  }

  function dateChanged(actionType: ProgressDateType) {
    Graylog({
      shortMsg: AnalyticsEvent.progress.PROGRESS_TRACKING_DATE_CHANGED,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: actionType,
      },
    });
  }

  function dateTypeChanged(viewType: TimeRangeType) {
    let type: ProgressRangeViewType = 'Weekly';
    switch (viewType) {
      case TimeRangeType.ALL_TIME:
        type = 'All Time';
        break;
      case TimeRangeType.PAY_PERIOD:
        type = 'Pay Period';
        break;
      case TimeRangeType.MONTHLY:
        type = 'Monthly';
        break;
      case TimeRangeType.WEEKLY:
        type = 'Weekly';
        break;
      case TimeRangeType.DAILY:
        type = 'Daily';
        break;
      case TimeRangeType.CUSTOM:
        type = 'Custom';
        break;
    }
    Graylog({
      shortMsg: AnalyticsEvent.progress.PROGRESS_DATE_TYPE_CHANGED,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _view_type: type,
      },
    });
  }

  function entryDetailsViewed(viewType: ProgressDetailsType) {
    Graylog({
      shortMsg: AnalyticsEvent.progress.VIEWED_PROGRESS_ENTRY_DETAILS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _view_type: viewType,
      },
    });
  }

  return { reportViewed, projectDetailsViewed, dateChanged, dateTypeChanged, entryDetailsViewed };
}
