import { OperationVariables, QueryOptions, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { ScheduleReminder, ScheduleReminderFilter, ScheduleReminderSort } from '__generated__/graphql';

interface IArgs {
  filter?: ScheduleReminderFilter;
  sort?: ScheduleReminderSort[];
  first?: number;
  after?: string | null;
}

type VariablesType = OperationVariables & IArgs;

export default function useScheduleReminderQuery() {
  const apolloClient = useApolloClient();

  return useCallback(
    (opts: QueryOptions<VariablesType>) => {
      return apolloClient.query<{ scheduleReminders: ScheduleReminder[] }>(opts);
    },
    [apolloClient]
  );
}
