import { Col, FieldFooter, Icon, Label, Position, Row, TextField } from '@busybusy/webapp-react-ui';
import { ArrowDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import {
  CostCodeGroupPicker,
  CostCodePicker,
  EmployeeGroupPicker,
  EmployeePicker,
  EquipmentPicker,
  PanelContent,
  ProjectGroupPicker,
  ProjectPicker,
} from 'components';
import EquipmentCategoryPicker from 'components/domain/equipment/EquipmentCategoryPicker/EquipmentCategoryPicker';
import SidePanelFilter from 'components/foundation/FilterSidePanel/FilterSidePanel';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import DateRange from 'components/foundation/ranges/DateRange/DateRange';
import { useOrganization, useQueryParams, useTimeRange } from 'hooks';
import useActivityReportSettingsUpdate from 'hooks/models/member-settings/useActivityReportSettingsUpdate';
import useActivePosition from 'hooks/models/position/useActivePosition';
import { compact } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IDatePickerValue from 'types/DatePickerValue';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { PayPeriodType } from 'types/enum/PayPeriodType';
import { AnyObject } from 'types/util/Object';
import { QueryParam } from 'utils/constants/queryParam';
import { getAdjustedEndDateChange, getAdjustedStartDateChange } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { latestPayPeriodDefinition } from 'utils/organizationUtils';
import useActivityQueryParams from '../hooks/useActivityQueryParams';
import './ActivityReportFilter.scss';

export enum ActivityReportType {
  BASIC = 'basic',
  BY_PROJECT = 'by-project',
  BY_EMPLOYEE = 'by-employee',
  BY_COST_CODE = 'by-cost-code',
  BY_EQUIPMENT = 'by-equipment',
  BY_DAY = 'by-date',
  BY_DATE_RANGE = 'by-date-range',
}

export interface IActivityReportFilterProps {
  className?: string | AnyObject;
  setCustomTimeRange: (timeRange: ITimeRange<DateTime> | null) => void;
}

export interface IActivityReportFilterData {
  type: ActivityReportType | null;
  timeRangeType: TimeRangeType | null;
  projectId: string | null;
  memberId: string | null;
  equipmentId: string | null;
  projectGroupId: string | null;
  employeeGroupId: string | null;
  equipmentCategoryId: string | null;
  costCodeId: string | null;
  costCodeGroupId: string | null;
  customTimeRange: ITimeRange<DateTime | null>;
  byDateRangeLimit?: number | null;
}

const ActivityReportFilter: FunctionComponent<IActivityReportFilterProps> = (props) => {
  const { className, setCustomTimeRange } = props;

  const { updateParams } = useQueryParams();
  const location = useLocation();
  const {
    reportType,
    timeRangeType,
    projectId,
    projectGroupId,
    memberId,
    memberGroupId,
    equipmentId,
    equipmentCategoryId,
    costCodeId,
    costCodeGroupId,
    customStartDate,
    customEndDate,
    dateRangeLimit,
  } = useActivityQueryParams();
  const isPro = useFeatureFlags('PRO');
  const payPeriodRange = useTimeRange(isPro ? TimeRangeType.PAY_PERIOD : TimeRangeType.WEEKLY);
  const currentPayPeriodEndTime = useMemo<DateTime>(() => payPeriodRange.timeRange.endTime, [payPeriodRange]);
  const currentCustomStartDate = customStartDate ?? DateTime.local().startOf('day').toISODate();
  const currentCustomEndDate = customEndDate ?? DateTime.local().endOf('day').toISODate();
  const organization = useOrganization();
  const updateSettings = useActivityReportSettingsUpdate();
  const activeMemberPosition = useActivePosition();

  useEffect(() => {
    setData({
      ...data,
      timeRangeType,
      byDateRangeLimit: dateRangeLimit,
      type: reportType as ActivityReportType,
    });
  }, [location]);

  const classes = classNames('activity-report-filter', className);

  const [data, setData] = useState<IActivityReportFilterData>({
    type: reportType,
    timeRangeType: timeRangeType,
    projectId: projectId,
    projectGroupId: projectGroupId,
    memberId: memberId,
    equipmentId: equipmentId,
    employeeGroupId: memberGroupId,
    equipmentCategoryId: equipmentCategoryId,
    costCodeId: costCodeId,
    costCodeGroupId: costCodeGroupId,
    customTimeRange: {
      startTime: customStartDate ? DateTime.fromISO(customStartDate).startOf('day') : DateTime.local().startOf('day'),
      endTime: customEndDate
        ? DateTime.fromISO(customEndDate).endOf('day').set({ millisecond: 0 })
        : DateTime.local().endOf('day').set({ millisecond: 0 }),
    },
    byDateRangeLimit: dateRangeLimit,
  });

  const rangeLimitErrorMessage = useRef<string | undefined>();

  const reportTypeOptions = useRef(
    compact([
      activeMemberPosition?.viewAllActivityReport === true
        ? {
            name: t('By Project'),
            id: ActivityReportType.BY_PROJECT,
          }
        : null,
      {
        name: t('By Employee'),
        id: ActivityReportType.BY_EMPLOYEE,
      },
      activeMemberPosition?.viewAllActivityReport === true
        ? {
            name: t('By Cost Code'),
            id: ActivityReportType.BY_COST_CODE,
          }
        : null,
      activeMemberPosition?.viewAllActivityReport === true
        ? {
            name: t('By Equipment'),
            id: ActivityReportType.BY_EQUIPMENT,
          }
        : null,
      activeMemberPosition?.viewAllActivityReport === true
        ? {
            name: t('By Day'),
            id: ActivityReportType.BY_DAY,
          }
        : null,
      isPro && activeMemberPosition?.viewAllActivityReport === true
        ? {
            name: t('By Date Range'),
            id: ActivityReportType.BY_DATE_RANGE,
          }
        : null,
    ])
  );

  const freeTimeRangeTypePickerOptions = useRef([{ id: TimeRangeType.WEEKLY, name: t('Weekly') }]);
  const freeByDateTimeRangeTypePickerOptions = useRef([{ id: TimeRangeType.WEEKLY, name: t('By Week') }]);

  const proTimeRangeTypePickerOptions = useRef([
    { id: TimeRangeType.PAY_PERIOD, name: t('Pay Period') },
    { id: TimeRangeType.MONTHLY, name: t('Monthly') },
    { id: TimeRangeType.WEEKLY, name: t('Weekly') },
    { id: TimeRangeType.DAILY, name: t('Daily') },
    { id: TimeRangeType.CUSTOM, name: t('Custom') },
  ]);

  const proByDateTimeRangeTypePickerOptions = useRef([
    { id: TimeRangeType.WEEKLY, name: t('By Week') },
    { id: TimeRangeType.MONTHLY, name: t('By Month') },
    { id: TimeRangeType.PAY_PERIOD, name: t('By Pay Period') },
  ]);

  const timeRangeTypePickerOptions = isPro ? proTimeRangeTypePickerOptions : freeTimeRangeTypePickerOptions;
  const byDateTimeRangeTypePickerOptions = isPro
    ? proByDateTimeRangeTypePickerOptions
    : freeByDateTimeRangeTypePickerOptions;

  function handleStartDateChange(value: IDatePickerValue) {
    if (value.value) {
      const { newStartDate, newEndDate } = getAdjustedStartDateChange(value.value, data.customTimeRange.endTime);

      setData({
        ...data,
        customTimeRange: {
          startTime: newStartDate,
          endTime: newEndDate,
        },
      });
    } else {
      setData({
        ...data,
        customTimeRange: {
          ...data.customTimeRange,
          startTime: null,
        },
      });
    }
  }

  function handleEndDateChange(endDatePickerValue: IDatePickerValue) {
    if (endDatePickerValue.value) {
      const { newStartDate, newEndDate } = getAdjustedEndDateChange(
        endDatePickerValue.value,
        data.customTimeRange.startTime
      );

      setData({
        ...data,
        customTimeRange: {
          startTime: newStartDate ?? data.customTimeRange.startTime,
          endTime: newEndDate,
        },
      });
    } else {
      setData({
        ...data,
        customTimeRange: {
          ...data.customTimeRange,
          endTime: null,
        },
      });
    }
  }

  function getDisabledDateRange() {
    const clampDuration = Duration.fromObject({ years: 3 });
    const maxEndTime = currentPayPeriodEndTime.plus(clampDuration);

    return {
      disabledAfter: maxEndTime < currentPayPeriodEndTime ? maxEndTime : currentPayPeriodEndTime,
      disabledBefore: data.customTimeRange.endTime
        ? data.customTimeRange.endTime.minus(clampDuration)
        : currentPayPeriodEndTime.minus(clampDuration),
    };
  }

  function reportTypePicker() {
    return (
      <Row>
        <Col>
          <BasicPicker
            data={reportTypeOptions.current}
            onSelect={(type) => {
              setData({ ...data, type });
            }}
            value={data.type}
            className="status-selector"
            placeholder={t('Type')}
            clearable={false}
            closeButtonRender={() => (
              <span className="drop-icon">
                <Icon svg={ArrowDownIcon} />
              </span>
            )}
          />
        </Col>
      </Row>
    );
  }

  function timeRangePicker() {
    return (
      <BasicPicker
        onSelect={(timeRangeType) => {
          setData({ ...data, timeRangeType });
        }}
        value={isPro ? data.timeRangeType : TimeRangeType.WEEKLY}
        data={timeRangeTypePickerOptions.current}
        clearable={false}
        disabled={!isPro}
        closeButtonRender={() => (
          <span className="drop-icon">
            <Icon svg={ArrowDownIcon} />
          </span>
        )}
      />
    );
  }

  function renderEmployeePicker() {
    return (
      <EmployeePicker
        placeholder={t('Employee')}
        value={data.memberId}
        onSelect={(memberId) => {
          setData({ ...data, memberId });
        }}
        position={Position.BOTTOM_END}
      />
    );
  }

  function renderEmployeeGroupPicker() {
    return (
      <EmployeeGroupPicker
        onSelect={(employeeGroupId) => {
          setData({ ...data, employeeGroupId });
        }}
        value={data.employeeGroupId}
        placeholder={t('Employee Group')}
        position={Position.BOTTOM_END}
        filterByPermission={true}
      />
    );
  }

  function renderProjectPicker() {
    return (
      <ProjectPicker
        placeholder={t('Project')}
        value={data.projectId}
        onSelect={(projectId) => {
          setData({ ...data, projectId });
        }}
        position={Position.BOTTOM_END}
        topLevelOnly={true}
      />
    );
  }

  function renderProjectGroupPicker() {
    return (
      <ProjectGroupPicker
        onSelect={(projectGroupId) => {
          setData({ ...data, projectGroupId });
        }}
        value={data.projectGroupId}
        placeholder={t('Project Group')}
        position={Position.BOTTOM_END}
      />
    );
  }

  function renderCostCodePicker() {
    return (
      <CostCodePicker
        placeholder={t('Cost Code')}
        value={data.costCodeId}
        onSelect={(costCodeId) => {
          setData({ ...data, costCodeId });
        }}
        position={Position.BOTTOM_END}
        considerProjectSpecificCostCodes={false}
      />
    );
  }

  function renderCostCodeGroupPicker() {
    return (
      <CostCodeGroupPicker
        onSelect={(costCodeGroupId) => {
          setData({ ...data, costCodeGroupId });
        }}
        value={data.costCodeGroupId}
        placeholder={t('Cost Code Group')}
        position={Position.BOTTOM_END}
      />
    );
  }

  function renderEquipmentPicker() {
    return (
      <EquipmentPicker
        placeholder={t('Equipment')}
        value={data.equipmentId}
        onSelect={(equipmentId) => {
          setData({ ...data, equipmentId });
        }}
        position={Position.BOTTOM_END}
      />
    );
  }

  function renderEquipmentCategoryPicker() {
    return (
      <EquipmentCategoryPicker
        onSelect={(equipmentCategoryId) => {
          setData({ ...data, equipmentCategoryId });
        }}
        value={data.equipmentCategoryId}
        placeholder={t('Category')}
        position={Position.BOTTOM_END}
      />
    );
  }

  function renderSettingsSection() {
    if (data.type === ActivityReportType.BY_DATE_RANGE) {
      return (
        <>
          <h3>{t('SETTINGS')}</h3>
          <div className="pt-4">{reportTypePicker()}</div>
        </>
      );
    }

    return (
      <>
        <h3>{t('SETTINGS')}</h3>
        <div className="pt-4">{reportTypePicker()}</div>
        <div className="pt-4">{timeRangePicker()}</div>
        {data.timeRangeType === TimeRangeType.CUSTOM && (
          <DateRange
            className="pt-4"
            startDate={data.customTimeRange.startTime}
            endDate={data.customTimeRange.endTime}
            onEndChange={handleEndDateChange}
            onStartChange={handleStartDateChange}
            {...getDisabledDateRange()}
          />
        )}
      </>
    );
  }

  function renderEmployeeFilterSection() {
    return (
      <div className="mt-5">
        <h3>{t('Filter')}</h3>
        <div className="pt-4">{renderEmployeePicker()}</div>
        <div className="pt-4">{renderEmployeeGroupPicker()}</div>
      </div>
    );
  }

  function renderProjectFilterSection() {
    return (
      <div className="mt-5">
        <h3>{t('Filter')}</h3>
        <div className="pt-4">{renderProjectPicker()}</div>
        <div className="pt-4">{renderProjectGroupPicker()}</div>
      </div>
    );
  }

  function renderCostCodeFilterSection() {
    return (
      <div className="mt-5">
        <h3>{t('Filter')}</h3>
        <div className="pt-4">{renderCostCodePicker()}</div>
        <div className="pt-4">{renderCostCodeGroupPicker()}</div>
      </div>
    );
  }

  function renderEquipmentFilterSection() {
    return (
      <div className="mt-5">
        <h3>{t('Filter')}</h3>
        <div className="pt-4">{renderEquipmentPicker()}</div>
        <div className="pt-4">{renderEquipmentCategoryPicker()}</div>
      </div>
    );
  }

  function renderDateRangeFilterSection() {
    let selectedType: TimeRangeType = TimeRangeType.WEEKLY;
    let limitTypeName = t('Weeks');
    const { maxNumber, defaultLimit } = getTimeRangeLimitInfo(data.timeRangeType);

    if (data.timeRangeType === TimeRangeType.MONTHLY) {
      limitTypeName = t('Months');
      selectedType = TimeRangeType.MONTHLY;
    } else if (data.timeRangeType === TimeRangeType.PAY_PERIOD) {
      limitTypeName = t('Pay Periods');
      selectedType = TimeRangeType.PAY_PERIOD;
    }

    return (
      <>
        <BasicPicker
          className="mt-5"
          onSelect={(timeRangeType) => {
            // when changing the date range type, set the number to the default value
            setData({
              ...data,
              timeRangeType,
              byDateRangeLimit: getTimeRangeLimitInfo(timeRangeType).defaultLimit,
            });
          }}
          value={selectedType as TimeRangeType}
          data={byDateTimeRangeTypePickerOptions.current}
          clearable={false}
          disabled={!isPro}
          closeButtonRender={() => (
            <span className="drop-icon">
              <Icon svg={ArrowDownIcon} />
            </span>
          )}
        />
        <Row className="mt-5">
          <TextField
            className="by-date-range-limit"
            value={(data.byDateRangeLimit ?? defaultLimit).toString()}
            error={rangeLimitErrorMessage.current !== undefined}
            type="number"
            restrictTo={{
              integer: true,
              maxLength: 3,
            }}
            onChange={(limit) => {
              const limitAsNumber = parseInt(limit);
              rangeLimitErrorMessage.current =
                limitAsNumber > maxNumber
                  ? t('Must be less than ') + maxNumber
                  : limitAsNumber <= 0
                  ? t('Must be greater than 0')
                  : undefined;

              setData({ ...data, byDateRangeLimit: limitAsNumber });
            }}
          />
          <Label className="ml-4 mt-3">{limitTypeName}</Label>
        </Row>
        <FieldFooter
          errorMessage={rangeLimitErrorMessage.current}
          hasError={rangeLimitErrorMessage.current !== undefined}
        />
      </>
    );
  }

  function getTimeRangeLimitInfo(timeRangeType: TimeRangeType | null) {
    let maxNumber = 104; // 104 weeks
    let defaultLimit = 52;

    if (timeRangeType === TimeRangeType.MONTHLY) {
      maxNumber = 24;
      defaultLimit = 12;
    } else if (timeRangeType === TimeRangeType.PAY_PERIOD) {
      const latestPayPeriod = latestPayPeriodDefinition(organization);
      switch (latestPayPeriod?.payPeriodType) {
        case PayPeriodType.BIWEEKLY:
          maxNumber = 52;
          defaultLimit = 26;
          break;
        case PayPeriodType.SEMIMONTHLY:
          maxNumber = 48;
          defaultLimit = 24;
          break;
        case PayPeriodType.MONTHLY:
          maxNumber = 24;
          defaultLimit = 12;
          break;
      }
    }

    return {
      maxNumber,
      defaultLimit,
    };
  }

  function anyFilterSet() {
    if (data.type === ActivityReportType.BY_PROJECT) {
      return data.projectId !== null || data.projectGroupId !== null;
    } else if (data.type === ActivityReportType.BY_EMPLOYEE) {
      return data.memberId !== null || data.employeeGroupId !== null;
    } else if (data.type === ActivityReportType.BY_COST_CODE) {
      return data.costCodeId !== null || data.costCodeGroupId !== null;
    } else if (data.type === ActivityReportType.BY_EQUIPMENT) {
      return data.equipmentId !== null || data.equipmentCategoryId !== null;
    }

    return false; // no filters for the date report
  }

  function handleApply() {
    const params = {
      [QueryParam.FILTER_TYPE]: data.type ?? null,
      [QueryParam.TIME_RANGE_TYPE]: data.timeRangeType ?? null,
      [QueryParam.EMPLOYEE_ID]: data.memberId ?? null,
      [QueryParam.EMPLOYEE_GROUP_ID]: data.employeeGroupId ?? null,
      [QueryParam.PROJECT_ID]: data.projectId ?? null,
      [QueryParam.PROJECT_GROUP_ID]: data.projectGroupId ?? null,
      [QueryParam.COST_CODE_ID]: data.costCodeId ?? null,
      [QueryParam.COST_CODE_GROUP_ID]: data.costCodeGroupId ?? null,
      [QueryParam.EQUIPMENT_ID]: data.equipmentId ?? null,
      [QueryParam.EQUIPMENT_CATEGORY_ID]: data.equipmentCategoryId ?? null,
    };

    if (data.timeRangeType === TimeRangeType.CUSTOM) {
      params[QueryParam.START_DATE] = data.customTimeRange.startTime?.toISODate() ?? null;
      params[QueryParam.END_DATE] = data.customTimeRange.endTime?.toISODate() ?? null;
    }

    if (data.type === ActivityReportType.BY_DATE_RANGE && rangeLimitErrorMessage.current === undefined) {
      const { defaultLimit } = getTimeRangeLimitInfo(data.timeRangeType);
      params[QueryParam.DATE_RANGE_LIMIT] = (data.byDateRangeLimit ?? defaultLimit).toString();
    } else {
      params[QueryParam.DATE_RANGE_LIMIT] = null;
    }

    updateParams(params);

    updateCustomRange(data);

    const defaultRangeType =
      isPro && reportType !== ActivityReportType.BY_DATE_RANGE ? TimeRangeType.PAY_PERIOD : TimeRangeType.WEEKLY;
    const updatedTimeRangeType: TimeRangeType = data.timeRangeType ?? defaultRangeType;
    if (data.timeRangeType !== TimeRangeType.CUSTOM) {
      updateSettings([{ key: 'timeRangeType', payload: updatedTimeRangeType }]);
    }
  }

  function updateCustomRange(data: IActivityReportFilterData) {
    if (data.timeRangeType === TimeRangeType.CUSTOM && data.customTimeRange.startTime && data.customTimeRange.endTime) {
      setCustomTimeRange(data.customTimeRange as ITimeRange<DateTime>);
    } else if (data.timeRangeType === TimeRangeType.CUSTOM) {
      // They selected a custom date but removed one of the picker values
      const startTime = data.customTimeRange.startTime ?? DateTime.utc().startOf('day');
      const endTime = data.customTimeRange.endTime ?? DateTime.utc().endOf('day');
      setCustomTimeRange({ startTime, endTime });
      setData({ ...data, customTimeRange: { startTime, endTime } });
    } else {
      setCustomTimeRange(null);
    }
  }

  function handleReset() {
    setData((data) => {
      return {
        ...data,
        projectId: null,
        memberId: null,
        equipmentId: null,
        projectGroupId: null,
        employeeGroupId: null,
        equipmentCategoryId: null,
        costCodeId: null,
        costCodeGroupId: null,
      };
    });

    const params: { [key: string]: string | undefined | null } = {
      [QueryParam.EMPLOYEE_ID]: null,
      [QueryParam.EMPLOYEE_GROUP_ID]: null,
      [QueryParam.PROJECT_ID]: null,
      [QueryParam.PROJECT_GROUP_ID]: null,
      [QueryParam.COST_CODE_ID]: null,
      [QueryParam.COST_CODE_GROUP_ID]: null,
      [QueryParam.EQUIPMENT_ID]: null,
      [QueryParam.EQUIPMENT_CATEGORY_ID]: null,
    };

    updateParams(params);
  }

  function hasChanged() {
    const { defaultLimit } = getTimeRangeLimitInfo(data.timeRangeType);

    // for the by date report only check these fields
    if (data.type === ActivityReportType.BY_DATE_RANGE) {
      return (
        reportType !== data.type ||
        timeRangeType !== data.timeRangeType ||
        (data.byDateRangeLimit === null && dateRangeLimit !== defaultLimit) ||
        (data.byDateRangeLimit !== null && data.byDateRangeLimit !== dateRangeLimit)
      );
    }

    return (
      reportType !== data.type ||
      timeRangeType !== data.timeRangeType ||
      memberId !== data.memberId ||
      memberGroupId !== data.employeeGroupId ||
      projectId !== data.projectId ||
      projectGroupId !== data.projectGroupId ||
      costCodeId !== data.costCodeId ||
      costCodeGroupId !== data.costCodeGroupId ||
      equipmentId !== data.equipmentId ||
      equipmentCategoryId !== data.equipmentCategoryId ||
      (timeRangeType === TimeRangeType.CUSTOM && currentCustomEndDate !== data.customTimeRange.endTime?.toISODate()) ||
      (timeRangeType === TimeRangeType.CUSTOM && currentCustomStartDate !== data.customTimeRange.startTime?.toISODate())
    );
  }

  return (
    <SidePanelFilter
      onApply={handleApply}
      onClear={handleReset}
      appliable={hasChanged()}
      clearable={anyFilterSet()}
      className={classes}
    >
      <PanelContent className="p-2 mb-5" flex={false}>
        {renderSettingsSection()}
        {data.type === ActivityReportType.BY_PROJECT && renderProjectFilterSection()}
        {data.type === ActivityReportType.BY_EMPLOYEE && renderEmployeeFilterSection()}
        {data.type === ActivityReportType.BY_COST_CODE && renderCostCodeFilterSection()}
        {data.type === ActivityReportType.BY_EQUIPMENT && renderEquipmentFilterSection()}
        {data.type === ActivityReportType.BY_DATE_RANGE && renderDateRangeFilterSection()}
      </PanelContent>
    </SidePanelFilter>
  );
};

export default ActivityReportFilter;
