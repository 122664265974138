import { gql } from '@apollo/client';

export const BASIC_PROJECT_TIME_METRICS_FRAGMENT = gql`
  fragment BasicProjectLaborMetrics on ProjectLaborMetricsInterface {
    __typename
    descDt
    descOt
    descRt
    dt
    end
    ot
    rt
    start
  }
`;

export const BASIC_PROJECT_LABOR_METRICS_FRAGMENT = gql`
  fragment ProjectLaborMetrics on ProjectLaborMetricsInterface {
    descDt
    descDtCents
    descOt
    descOtCents
    descOverburdenRtCents
    descOverburdenOtCents
    descOverburdenDtCents
    descRt
    descRtCents
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;

export const ALL_PROJECT_LABOR_METRICS_FRAGMENT = gql`
  fragment AllProjectLaborMetrics on ProjectLaborMetricsInterface {
    descBreakDt
    descBreakDtCents
    descBreakOt
    descBreakOtCents
    descBreakOverburdenCents
    descBreakRt
    descBreakUpt
    descDt
    descDtCents
    descOt
    descOtCents
    descOverburdenRtCents
    descOverburdenOtCents
    descOverburdenDtCents
    descRt
    descRtCents
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    breakUpt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;
