import {
  Button,
  Form,
  IFormRender,
  Label,
  Link,
  Radio,
  RadioGroupFormField,
  TextareaFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import {
  MemberSignOffAnswerCreateInput,
  OrganizationSignOffQuestion,
  OrganizationSignOffQuestionAudience,
} from '__generated__/graphql';
import classNames from 'classnames';
import EmbeddedLinkedText from 'components/foundation/EmbeddedLinkedText/EmbeddedLinkedText';
import SignatureDialog from 'components/foundation/dialogs/SignatureDialog/SignatureDialog';
import { useActiveMember, useAxios, useOpenable, useOrganization, useTimeRounding } from 'hooks';
import useMemberSignOffAnswer from 'hooks/models/member-sign-off-answer/useMemberSignOffAnswer';
import useGetOrganizationSignOffQuestions from 'hooks/models/organization-sign-off-questions/useGetOrganizationSignOffQuestions';
import useSafetySignature from 'hooks/models/safety-signature/useSafetySignature';
import { camelCase, first, isNil, some, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { StoreAuthMemberProper } from 'store/types';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import SignatureDialogReturnType from 'types/enum/SignatureDialogReturnType';
import SignatureSettings from 'types/enum/SignatureSettings';
import { filterNil } from 'utils/collectionUtils';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import { IOrganizationSignInQuestionChoice, buildChoiceObjectFromJSON } from 'utils/organizationSignInQuestionUtils';
import CaliforniaBreakLawsFormDialog from '../CaliforniaBreakLawsForm/CaliforniaBreakLawsFormDialog';
import './DailySignOffForm.scss';

export interface IDailySignOffFormProp {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  member: Partial<IMember> | IMember | StoreAuthMemberProper;
  fromReport: boolean;
  onComplete?: () => void;
  currentOpenEntry?: ITimeEntry | null;
}

export interface IDailySignOffFormData {
  injured?: string | undefined;
  injuredDescription?: string | undefined;
  breakPolicyFollowed?: string | undefined;
  breakPolicyFollowedDescription?: string | undefined;
  timeCorrect?: string | undefined;
  timeCorrectDescription?: string | undefined;
}

const DailySignOffForm = (props: IDailySignOffFormProp) => {
  const { className, timeRange, member, fromReport, onComplete, currentOpenEntry } = props;

  const axiosClient = useAxios();
  const activeMember = useActiveMember();
  const organization = useOrganization();
  const californiaRulesDetails = useOpenable();
  const errorToastState = useOpenable();
  const employeeSignatureState = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const savedFormData = useRef<IDailySignOffFormData>();
  const [showInjuredDescription, setShowInjuredDescription] = useState<boolean>(false);
  const [showBreakPolicyDescription, setShowBreakPolicyDescription] = useState<boolean>(false);
  const [showTimeCorrectDescription, setShowTimeCorrectDescription] = useState<boolean>(false);
  const getAllQuestions = useGetOrganizationSignOffQuestions();
  const [customQuestions, setCustomQuestions] = useState<OrganizationSignOffQuestion[]>([]);
  const { createSafetySignature } = useSafetySignature();
  const { roundTime } = useTimeRounding();
  const { createSignOffAnswer } = useMemberSignOffAnswer();
  const [formData] = useState<IDailySignOffFormData>({});

  const classes = classNames(
    {
      'daily-sign-off-form': true,
    },
    className
  );

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestions = async () => {
    const questions = await getAllQuestions({ deletedOn: { isNull: true } });
    const sortedQuestions = sortBy(
      questions.filter(
        (q) =>
          q.audienceType === OrganizationSignOffQuestionAudience.Everyone ||
          (q.audienceType === OrganizationSignOffQuestionAudience.Position && q.positionId === member.position?.id)
      ),
      (q) => q.order
    );
    setCustomQuestions(sortedQuestions);
  };

  function renderInjuredQuestion({ ...form }: IFormRender<IDailySignOffFormData>) {
    let injuryMessage = organization.safetySignatureMessage;
    if (!injuryMessage || injuryMessage.length === 0) {
      injuryMessage = t(
        'If you were injured, please contact your supervisor. If your injury is serious, contact emergency services immediately.'
      );
    }

    const updateAnswer = (selectedName: string) => {
      setShowInjuredDescription(selectedName === 'true');
    };

    return (
      <div>
        <Label className="section-primary">{t('Were you injured?')}</Label>
        <Label className="section-secondary">{injuryMessage}</Label>
        <RadioGroupFormField className="section-options mt-4" name="injured" form={form} onChange={updateAnswer}>
          <Radio value="true" label={t('Yes')} />
          <Radio value="false" label={t('No')} />
        </RadioGroupFormField>

        {showInjuredDescription && (
          <>
            <Label className="section-secondary">{t('Please describe your injury')}</Label>
            <TextareaFormField name="injuredDescription" form={form} restrictTo={{ maxLength: 1000 }} />
          </>
        )}
      </div>
    );
  }

  function renderTimeCorrectQuestion({ ...form }: IFormRender<IDailySignOffFormData>) {
    let timeCorrectMessage = organization.timeAccuracyMessage;
    if (!timeCorrectMessage || timeCorrectMessage.length === 0) {
      timeCorrectMessage = t(
        'If your time is not correct, please notify your supervisor as soon as possible to resolve the issue.'
      );
    }

    const updateAnswer = (selectedName: string) => {
      setShowTimeCorrectDescription(selectedName === 'false');
    };

    return (
      <div>
        <Label className="section-primary">{t('Is your time correct?')}</Label>
        <Label className="section-secondary">{timeCorrectMessage}</Label>
        <RadioGroupFormField className="section-options mt-4" name="timeCorrect" form={form} onChange={updateAnswer}>
          <Radio value="true" label={t('Yes')} />
          <Radio value="false" label={t('No')} />
        </RadioGroupFormField>

        {showTimeCorrectDescription && (
          <>
            <Label className="section-secondary">{t('Why was your time incorrect?')}</Label>
            <TextareaFormField name="timeCorrectDescription" form={form} restrictTo={{ maxLength: 1000 }} />
          </>
        )}
      </div>
    );
  }

  function renderBreakPolicyQuestion({ ...form }: IFormRender<IDailySignOffFormData>) {
    let breakPolicyTitle = t('Did you follow company break policy?');
    let breakPolicyMessageUi: ReactNode = (
      <Label className="section-secondary">{organization.breakPolicyMessage}</Label>
    );

    if (organization.breakPolicyCalifornia) {
      breakPolicyTitle = t('Did you follow California break policy?');

      breakPolicyMessageUi = (
        <div>
          <span className="section-secondary">
            {t(
              'You certify that you took the required breaks or they were waived by mutual consent as outlined in the'
            )}
          </span>
          <Link className="section-link" onClick={() => californiaRulesDetails.open()} href="#">
            {t(' California break laws.')}
          </Link>
        </div>
      );
    } else if (!organization.breakPolicyMessage || organization.breakPolicyMessage.length === 0) {
      breakPolicyMessageUi = (
        <Label className="section-secondary">
          {t(
            'You certify that you either took the required breaks or voluntarily waived them according to company policy.'
          )}
        </Label>
      );
    }

    const updateAnswer = (selectedName: string) => {
      setShowBreakPolicyDescription(selectedName === 'false');
    };

    return (
      <div>
        <Label className="section-primary">{breakPolicyTitle}</Label>
        {breakPolicyMessageUi}
        <RadioGroupFormField
          className="section-options mt-4"
          name="breakPolicyFollowed"
          form={form}
          onChange={updateAnswer}
        >
          <Radio value="true" label={t('Yes')} />
          <Radio value="false" label={t('No')} />
        </RadioGroupFormField>

        {showBreakPolicyDescription && (
          <>
            <Label className="section-secondary">{t("Why didn't you take your breaks?")}</Label>
            <TextareaFormField name="breakPolicyFollowedDescription" form={form} restrictTo={{ maxLength: 1000 }} />
          </>
        )}
      </div>
    );
  }

  const renderCustomQuestions = ({ ...form }: IFormRender<IDailySignOffFormData>) => {
    return (
      <>
        {customQuestions.map((question) => {
          const questionTitle = camelCase(question.questionTitle);
          const choices = buildChoiceObjectFromJSON(question.choices);
          const formItem = form.state.data[questionTitle as keyof IDailySignOffFormData];
          return (
            <div key={question.id}>
              <Label className={'section-primary'}>
                <EmbeddedLinkedText>{question.questionTitle}</EmbeddedLinkedText>
              </Label>
              <EmbeddedLinkedText>{question.questionDescription}</EmbeddedLinkedText>
              <RadioGroupFormField className={'section-option mt-4'} name={questionTitle} form={form}>
                {choices.map((choice) => (
                  <Radio
                    key={choice.choice}
                    value={choice.choice.toLowerCase() === 'yes' ? 'Yes' : 'No'}
                    label={choice.choice}
                    onChange={() => handleCustomQuestionChange(form, questionTitle, choice)}
                  />
                ))}
              </RadioGroupFormField>

              {formItem && choiceHasFollowUp(formItem, choices) && (
                <>
                  <Label className="section-secondary">{getFollowUpPropertyName(choices, formItem)}</Label>
                  <TextareaFormField
                    name={getFollowUpPropertyName(choices, formItem) as string}
                    form={form}
                    restrictTo={{ maxLength: 1000 }}
                  />
                </>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const handleCustomQuestionChange = (form: IFormRender<IDailySignOffFormData>, questionTitle: string, choice: any) => {
    form.setFormData({
      ...form.state.data,
      [questionTitle]: choice.choice.toLowerCase(),
    });
  };

  const choiceHasFollowUp = (formItem: string, choices: IOrganizationSignInQuestionChoice[]) => {
    const selectedChoice = choices.find((choice) => choice.choice.toLowerCase() === formItem.toLowerCase());
    return selectedChoice && selectedChoice.followup !== undefined && selectedChoice.followup !== '';
  };

  const getFollowUpPropertyName = (choices: IOrganizationSignInQuestionChoice[], choiceSelection: string) => {
    const selectedChoice = choices.find((choice) => {
      return (
        (choiceSelection === 'Yes' && choice.choice.toLowerCase() === 'yes') ||
        (choiceSelection === 'No' && choice.choice.toLowerCase() === 'no')
      );
    });

    return selectedChoice ? selectedChoice.followup : '';
  };

  function renderSubmitButton({ ...form }: IFormRender<IDailySignOffFormData>) {
    return (
      <Button type="primary" onClick={form.handleSubmit}>
        {t('Submit')}
      </Button>
    );
  }

  function renderFormFields({ ...form }: IFormRender<IDailySignOffFormData>) {
    return (
      <div>
        {organization.safetySignature ? renderInjuredQuestion(form) : null}
        {organization.breakPolicy ? renderBreakPolicyQuestion(form) : null}
        {organization.timeAccuracy ? renderTimeCorrectQuestion(form) : null}
        {renderCustomQuestions(form)}
        {renderSubmitButton(form)}
      </div>
    );
  }

  function onSubmitForm(data: IDailySignOffFormData) {
    if (!isValid(data)) {
      errorToastMessage.current = t('Answer all of the questions and try again.');
      errorToastState.open();
      return;
    }

    savedFormData.current = data;
    if (activeMember.id === member.id) {
      saveFormData(onComplete); // no signature needed when filling for self
    } else {
      employeeSignatureState.open(); // require signature when filling for someone else
    }
  }

  function saveFormData(onSave?: (id: string) => void) {
    const data = savedFormData.current!;

    let endTime;
    if (fromReport) {
      // don't round the time when performing sign off from report
      endTime = timeRange.endTime;
    } else {
      // round the time when performing sign off after a clock action
      endTime = roundTime(timeRange.endTime, ClockAction.CLOCK_OUT).set({ second: 0, millisecond: 0 });
    }

    const questionTitles = customQuestions.map((q) => camelCase(q.questionTitle));
    const answers = questionTitles.map((q) => {
      return { [q]: data[q as keyof IDailySignOffFormData] };
    });

    const answersFlagged = answers.flatMap((answer) => {
      return customQuestions.map((q) => {
        const camelCaseQuestion = camelCase(q.questionTitle);
        if (camelCaseQuestion in answer) {
          const currentAnswer = answer[camelCaseQuestion];
          const choices = buildChoiceObjectFromJSON(q.choices);
          return choices.some((choice) => choice.choice === currentAnswer && choice.flag);
        } else {
          return false;
        }
      });
    });

    const anyAnswerFlagged = some(answersFlagged);

    createSafetySignature(
      timeRange.startTime,
      !isNil(currentOpenEntry) ? currentOpenEntry.daylightSavingTime : timeRange.startTime.isInDST,
      endTime,
      !isNil(currentOpenEntry) && !isNil(currentOpenEntry.metaDaylightSavingTime)
        ? currentOpenEntry.metaDaylightSavingTime
        : DateTime.local().isInDST,
      member.id!,
      data.injured === undefined ? undefined : data.injured === 'true',
      data.injuredDescription,
      data.timeCorrect === undefined ? undefined : data.timeCorrect === 'true',
      data.timeCorrectDescription,
      data.breakPolicyFollowed === undefined ? undefined : data.breakPolicyFollowed === 'true',
      data.breakPolicyFollowedDescription,
      anyAnswerFlagged
    )
      .then((response) => {
        saveCustomAnswers(data, response.data.createSafetySignature.id);
        if (onSave) {
          onSave(response.data.createSafetySignature.id);
        }
      })
      .catch(() => {
        errorToastMessage.current = t('There was an error saving.');
        errorToastState.open();
      });
  }

  const saveCustomAnswers = async (data: IDailySignOffFormData, signatureId: string) => {
    const questionTitles = customQuestions.map((q) => camelCase(q.questionTitle));
    const answers = questionTitles.map((q) => {
      return { [q]: data[q as keyof IDailySignOffFormData] };
    });
    answers.map((answer) => {
      customQuestions.map(async (q) => {
        const camelCaseQuestion = camelCase(q.questionTitle);
        if (camelCaseQuestion in answer) {
          const currentAnswer = answer[camelCaseQuestion];
          const choices = buildChoiceObjectFromJSON(q.choices);
          const isFlagged =
            choices.filter((choice) => {
              return choice.choice === currentAnswer && choice.flag;
            }).length > 0;
          const date = DateTime.local();
          const createdOnDate = new Date(getNowIsoAtUtcWithLocalTimeZone());
          if (q.order) {
            createdOnDate.setSeconds(createdOnDate.getSeconds() + q.order);
          }
          const newCreatedOnISODate = createdOnDate.toISOString();
          const createdOn = newCreatedOnISODate;
          const localTime = date.toISO();
          const questionPrompt = first(
            filterNil(
              buildChoiceObjectFromJSON(q.choices).flatMap((choice) => {
                if (choice.choice === currentAnswer) {
                  return choice.followup;
                }
                return undefined;
              })
            )
          );
          const currentPropertyFollowUp = getFollowUpPropertyName(choices, currentAnswer!);

          const answerInput: MemberSignOffAnswerCreateInput = {
            answer: currentAnswer,
            audienceType: q.audienceType,
            flagged: isFlagged,
            memberId: member.id!,
            submissionId: signatureId,
            createdOn,
            localTime,
            questionTitle: q.questionTitle,
            questionDescription: q.questionDescription,
            questionPrompt,
            description: currentPropertyFollowUp ? data[currentPropertyFollowUp as keyof IDailySignOffFormData] : null,
          };
          await createSignOffAnswer(answerInput);
        }
      });
    });
  };

  function saveEmployeeSignature(urlData: any) {
    const dataString =
      SignatureSettings.TYPE_SIGNATURE && member.id === activeMember.id
        ? `data:image/svg+xml;base64,${window.btoa(urlData.split('data:image/svg+xml;charset=utf-8,')[1])}`
        : urlData;

    saveFormData((id: string) => {
      axiosClient
        .patch('safety-signature', signatureParam(dataString, id))
        .then(() => {
          employeeSignatureState.close();
          if (onComplete) {
            onComplete();
          }
        })
        .catch(() => {
          errorToastMessage.current = t('There was an error saving.');
          errorToastState.open();
        });
    });
  }

  function signatureParam(svg: string, id: string): FormData {
    const params = new FormData();
    const decodedSignature = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n' + svg;

    params.append('id', id);
    params.append('signature', new File([decodedSignature], 'signature.svg', { type: 'image/svg+xml' }));

    return params;
  }

  function isValid(data: IDailySignOffFormData): boolean {
    if (!data.injured && organization.safetySignature) {
      return false;
    } else if (!data.timeCorrect && organization.timeAccuracy) {
      return false;
    } else if (!data.breakPolicyFollowed && organization.breakPolicy) {
      return false;
    } else if (customQuestions.length > 0 && checkCustomQuestions(data)) {
      return false;
    }

    return true;
  }

  const checkCustomQuestions = (data: IDailySignOffFormData) => {
    const questionTitles = customQuestions.map((q) => camelCase(q.questionTitle));

    const hasUndefinedOrMissingValue = questionTitles.some(
      (key) => data[key as keyof IDailySignOffFormData] === undefined || !(key in data)
    );

    return hasUndefinedOrMissingValue;
  };

  return (
    <div className={classes}>
      <div className="mb-6 p-8">
        <Form
          data={formData}
          onSubmit={onSubmitForm}
          render={renderFormFields}
          className={classes}
          allowMultipleSubmissions={true}
        />
      </div>

      <CaliforniaBreakLawsFormDialog
        isOpen={californiaRulesDetails.isOpen}
        onClose={californiaRulesDetails.close}
        memberId={member.id!}
        timeRange={timeRange}
      />

      <Toast isOpen={errorToastState.isOpen} onClose={errorToastState.close} theme={Theme.DANGER}>
        {errorToastMessage.current}
      </Toast>

      <SignatureDialog
        title={t('Employee Signature')}
        subtitle={fullName(member)}
        isOpen={employeeSignatureState.isOpen}
        onClose={employeeSignatureState.close}
        onSubmit={saveEmployeeSignature}
        returnType={SignatureDialogReturnType.SVG}
      />
    </div>
  );
};

export default DailySignOffForm;
