import { useApolloClient } from '@apollo/client';
import { ClassName } from '@busybusy/webapp-react-ui';
import { SINGLE_TIME_OFF_QUERY } from 'apollo/queries/time-off-queries';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import ITimeOff from 'types/TimeOff';
import EditTimeOffForm from '../../form/EditTimeOffForm/EditTimeOffForm';

export interface IEditTimeOffFormDialogProps {
  timeOffId: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete?: (memberId?: string) => void;
  onEdit?: (memberId?: string) => void;
  className?: ClassName;
}

export default function EditTimeOffFormDialog({
  isOpen,
  timeOffId,
  className,
  onEdit,
  onDelete,
  onClose,
}: IEditTimeOffFormDialogProps) {
  const client = useApolloClient();
  const [timeOff, setTimeOff] = useState<ITimeOff>();

  async function getData() {
    const results = await client.query<{ timeOffs: ITimeOff[] }>({
      query: SINGLE_TIME_OFF_QUERY,
      fetchPolicy: 'network-only',
      variables: { timeOffId },
    });

    setTimeOff(results.data.timeOffs[0]);
  }

  useEffect(() => {
    if (isOpen && !_.isNil(timeOffId)) {
      getData();
    } else {
      setTimeOff(undefined); // Reset if not open or timeOffId is not set. For cases when multiple renders happen.
    }
  }, [isOpen, timeOffId]);

  return (
    <HeaderDialog className={className} isOpen={isOpen} title={t('Edit Time Off')} onClose={onClose} divider={false}>
      {timeOff && <EditTimeOffForm timeOff={timeOff} onEdit={onEdit} onDelete={onDelete} />}
    </HeaderDialog>
  );
}
