import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreDailyReportAdditionalProgressPhotos {
  progressPhotos: string[] | null | undefined;
}

export const initialState: IStoreDailyReportAdditionalProgressPhotos = {
  progressPhotos: [],
};

const slice = createSlice({
  name: 'dailyReportAdditionalProgressPhotos',
  initialState,
  reducers: {
    setProgressAdditionalPhotos: (
      state: IStoreDailyReportAdditionalProgressPhotos,
      action: PayloadAction<string[] | null | undefined>
    ) => {
      state.progressPhotos = action.payload;
      return state;
    },
  },
});

export const { setProgressAdditionalPhotos } = slice.actions;
export default slice.reducer;
