/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useReducer, useRef } from 'react';
import ICursorable from 'types/Cursorable';
import useCursorScrollingLoader from '../useCursorScrollingLoader';
import { getScrollerElement } from '../useScrollBreakpoint';

export interface ILazyLoadingState<T extends ICursorable> {
  loading: boolean;
  loadedAll: boolean;
  data: T[];
  error: boolean;
}

export type LazyLoadingAction<T extends ICursorable> =
  | { type: 'SET_DATA'; payload: { data: T[]; loadedAll: boolean; loading: boolean } | { data: T[] } }
  | { type: 'SET_ERROR'; payload: boolean }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'CLEAR_DATA'; payload: ILazyLoadingState<T> };

export type LazyLoadReducerType<T extends ICursorable> = (
  state: ILazyLoadingState<T>,
  action: LazyLoadingAction<T>
) => ILazyLoadingState<T>;

export function lazyLoadingReducer<T extends ICursorable>(
  state: ILazyLoadingState<T>,
  action: LazyLoadingAction<T>
): ILazyLoadingState<T> {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_DATA':
      return { ...state, ...action.payload };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        loadedAll: true,
        loading: false,
      };
    case 'CLEAR_DATA':
      return { ...action.payload };
    default:
      throw Error(`Unsupported action type.`);
  }
}

export const defaultLazyLoadingReducerState = { loading: false, loadedAll: false, data: [], error: false };

export default function useLazyLoading<T extends ICursorable>(
  scroller: HTMLElement | HTMLDivElement | Document | null | undefined,
  getData: (after: string | null, first: number) => Promise<T[]>,
  initialState: Partial<ILazyLoadingState<T>> = defaultLazyLoadingReducerState,
  sectionSize: number = 20,
  scrollOffset: number = 100,
  reducer: LazyLoadReducerType<T> = lazyLoadingReducer
) {
  const mergedInitialState = useRef({
    loading: false,
    loadedAll: false,
    data: [],
    error: false,
    ...initialState,
  });

  const [state, dispatch] = useReducer(reducer, mergedInitialState.current);

  const initialLoaded = useRef(false);
  const isLoading = useRef(false);
  const element = getScrollerElement(scroller);

  const onLoad = useCallback(
    (data: T[], loadedAll: boolean, error: boolean) => {
      if (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
      } else {
        dispatch({ type: 'SET_DATA', payload: { data, loadedAll, loading: false } });
      }
    },
    [dispatch]
  );

  const { loadAll, getMoreData } = useCursorScrollingLoader(
    scroller,
    state,
    getData,
    sectionSize,
    scrollOffset,
    onLoad
  );

  const clearData = useCallback(() => {
    dispatch({ type: 'CLEAR_DATA', payload: mergedInitialState.current });
    initialLoaded.current = false;
  }, [dispatch]);

  const withLoading = useCallback(<T>(callback: () => Promise<T>) => {
    return () => {
      dispatch({ type: 'SET_LOADING', payload: true });
      return callback();
    };
  }, []);

  const getAdditionalData = useCallback(async () => {
    isLoading.current = true;
    try {
      await getMoreData();
    } finally {
      isLoading.current = false;
    }
  }, [getMoreData]);

  useEffect(() => {
    if (!initialLoaded.current) {
      initialLoaded.current = true;
      getAdditionalData();
    }
  }, [state.data, getMoreData, state.loading, state.loadedAll]);

  useEffect(() => {
    if (element && initialLoaded.current && !isLoading.current && !state.loadedAll) {
      const isScrollable = element.scrollHeight > element.clientHeight;
      if (!isScrollable) {
        getAdditionalData();
      }
    }
  }, [element?.clientHeight]);

  return {
    ...state,
    getMoreData: withLoading(getMoreData),
    clearData,
    loadAll: withLoading(loadAll),
    dispatch,
  };
}
