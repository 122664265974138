import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDailyReportCustomSection } from 'types/DailyProjectReport/DailyReportCustomSection';
import LocalStorage from 'utils/localStorageUtils';

export interface IStoreDailyReportNewSections {
  newSections: IDailyReportCustomSection[] | null | undefined;
}

const localSections = LocalStorage.get('dailyReportNewSections');

const newSections: IDailyReportCustomSection[] = localSections ? localSections.newSections : [];

export const initialState: IStoreDailyReportNewSections = {
  newSections,
};

const slice = createSlice({
  name: 'dailyReportNewSections',
  initialState,
  reducers: {
    setNewSection: (
      state: IStoreDailyReportNewSections,
      action: PayloadAction<IDailyReportCustomSection[] | null | undefined>
    ) => {
      state.newSections = action.payload;
      return state;
    },
  },
});

export const { setNewSection } = slice.actions;
export default slice.reducer;
