import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { useCallback } from 'react';
import { DashboardSettingKey } from '../types/types';
import usePermissionScopedDashboardSettings from './usePermissionScopedDashboardSettings';

export default function useDashboardSettingsUpdate() {
  const [settings, setSettings] = usePermissionScopedDashboardSettings();

  const update = useCallback(
    <K extends keyof IDashboardSettingsItem>(
      key: DashboardSettingKey,
      settingKey: K,
      value: IDashboardSettingsItem[K]
    ) => {
      setSettings(key, { ...settings[key], [settingKey]: value });
    },
    [setSettings, settings]
  );

  const show = useCallback(
    (key: DashboardSettingKey) => {
      update(key, 'visible', true);
    },
    [update]
  );

  const hide = useCallback(
    (key: DashboardSettingKey) => {
      update(key, 'visible', false);
    },
    [update]
  );

  return { settings, setSettings, show, hide };
}
