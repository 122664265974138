import { useQueryClient } from '@tanstack/react-query';
import { useActiveMember } from 'hooks';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { useCallback } from 'react';
import useGustoGetEmployee from '../../employee/useGustoGetEmployee/useGustoGetEmployee';

export default function useGustoGetActiveEmployee() {
  const baseKey = useReactQueryBaseKey();
  const queryClient = useQueryClient();
  const activeMember = useActiveMember();
  const getGustoEmployeeById = useGustoGetEmployee();

  return useCallback(() => {
    if (!activeMember.gustoEmployeeId) {
      return null;
    }

    return queryClient.fetchQuery({
      queryKey: [...baseKey, 'gusto-active-employee'],
      queryFn: () => getGustoEmployeeById(activeMember.gustoEmployeeId!),
    });
  }, [activeMember.gustoEmployeeId, baseKey, getGustoEmployeeById, queryClient]);
}
