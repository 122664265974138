import { usePendingAction } from '..';
import useOpenable, { IOpenableHookProps } from '../utils/useOpenable';

export default function useConfirmationDialog(props?: IOpenableHookProps) {
  const openable = useOpenable(props);
  const [register, call] = usePendingAction();

  return {
    ...openable,
    setOnConfirm: register,
    onConfirm: call,
  };
}
