import { PhoneNumberUtil } from 'google-libphonenumber';
import { t } from 'utils/localize';
import { validatePhone } from 'utils/stringUtils';

export default function v_phone(value: any, args: { message?: string }) {
  return {
    message:
      args?.message ?? t("Not a valid phone number (add '+' before the country code for international numbers)."),
    validate: () => {
      if (!value) {
        return true;
      }

      const util = PhoneNumberUtil.getInstance();
      return validatePhone(util, value);
    },
  };
}
