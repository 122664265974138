import gql from 'graphql-tag';

export const CREATE_TIME_ENTRY = gql`
  mutation CreateTimeEntry($entry: TimeEntryCreateInput) {
    createTimeEntry(timeEntry: $entry) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;

export const UPDATE_TIME_ENTRY = gql`
  mutation UpdateTimeEntry($entry: TimeEntryUpdateInput) {
    updateTimeEntry(timeEntry: $entry) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;

export const CREATE_TIME_ENTRIES = gql`
  mutation CreateTimeEntries($entries: [TimeEntryCreateInput!]!) {
    createTimeEntries(timeEntries: $entries) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;

export const UPDATE_TIME_ENTRIES = gql`
  mutation UpdateTimeEntries($entries: [TimeEntryUpdateInput!]!) {
    updateTimeEntries(timeEntries: $entries) {
      id
      member {
        id
      }
      project {
        id
      }
      costCode {
        id
      }
      equipment {
        id
      }
      startTime
      endTime
      actionType
      description
      createdOn
      deletedOn
      submittedOn
      updatedOn
    }
  }
`;
