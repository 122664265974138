import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import axios from 'axios';
import JSZip from 'jszip';
import { castArray, chunk, cloneDeep, find, flatMap, flatten, forIn, groupBy, map, reject, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { IMediaCollection } from 'types/MediaCollection';
import { IMediaEntry } from 'types/MediaEntry';
import IMediaEntryCollection from 'types/MediaEntryCollection';
import IProject from 'types/Project';
import { IFile, downloadFile, downloadZipFile } from 'utils/fileUtils';
import {
  IMediaCollectionQueryResults,
  IMediaEntryQueryResults,
  mediaCollectionQuery,
  mediaEntryQuery,
} from '../photos-queries';

// Get all the media entries from an array of media collections
export function getMediaEntriesFromCollections(mediaCollections: IMediaCollection[]) {
  return flatMap(mediaCollections, (collection) => {
    return collection.mediaEntries.filter((entry) => !entry.deletedOn);
  });
}

export function getCollectionForMediaFromCollections(mediaCollections: IMediaCollection[], mediaEntry: IMediaEntry) {
  return find(mediaCollections, (collection) => {
    return collection.id === mediaEntry.mediaCollectionId;
  });
}

export function downloadCollection(mediaCollection: IMediaCollection, fileName: string) {
  const zipper = new JSZip();
  const wrapper = zipper.folder(fileName);
  if (!wrapper) {
    return;
  }

  return PhotoUtilities.downloadCollectionBlob(mediaCollection).then((files) => {
    PhotoUtilities.updateSameFileNames(files);
    PhotoUtilities.addMediaFilesToZipper(wrapper, files);
    return downloadZipFile(zipper, fileName);
  });
}

export function downloadCollections(mediaCollections: IMediaCollection[], fileName: string) {
  const zipper = new JSZip();
  const wrapper = zipper.folder(fileName);
  if (!wrapper) {
    return;
  }
  const promisedCollections = map(mediaCollections, (collection) => {
    return PhotoUtilities.downloadCollectionBlob(collection);
  });

  return Promise.all(promisedCollections).then((entryFiles) => {
    const flatEntries = flatten(entryFiles);

    PhotoUtilities.updateSameFileNames(flatEntries);
    PhotoUtilities.addMediaFilesToZipper(wrapper, flatEntries);

    return downloadZipFile(zipper, fileName);
  });
}

export function downloadCollectionBlob(mediaCollection: IMediaCollection) {
  const promises = map(mediaCollection.mediaEntries, (mediaEntry) => {
    return PhotoUtilities.downloadMediaEntryBlob(mediaEntry).then((blob) =>
      PhotoUtilities.convertToFile({ mediaEntry, encompassingCollection: mediaCollection }, blob)
    );
  });

  return Promise.all(promises);
}

export async function downloadMediaEntry(entryCollection: IMediaEntryCollection) {
  const blob = await PhotoUtilities.downloadMediaEntryBlob(entryCollection.mediaEntry);
  const file = PhotoUtilities.convertToFile(entryCollection, blob);
  const fileName = `${file.fileName}${blob.type === 'video/mp4' ? '.mp4' : '.jpg'}`;
  return await downloadFile({ ...file, fileName });
}

export function downloadMediaEntries(entryCollections: IMediaEntryCollection[], fileName: string) {
  const zipper = new JSZip();
  const wrapper = zipper.folder(fileName);
  if (!wrapper) {
    return;
  }
  const promises = map(entryCollections, (entryCollection) => {
    return PhotoUtilities.downloadMediaEntryBlob(entryCollection.mediaEntry).then((blob) =>
      PhotoUtilities.convertToFile(entryCollection, blob)
    );
  });

  return Promise.all(promises).then((files) => {
    PhotoUtilities.updateSameFileNames(files);
    PhotoUtilities.addMediaFilesToZipper(wrapper, files);
    return downloadZipFile(zipper, fileName);
  });
}

export async function downloadMediaEntryBlob(entry: IMediaEntry): Promise<Blob> {
  const imageurl = entry.fileUrl;
  const response = await axios({
    method: 'GET',
    responseType: 'blob', // important
    url: imageurl ? imageurl : '',
  });
  return response.data;
}

export function convertToFile(entryCollection: IMediaEntryCollection, file: Blob): IFile {
  const fileName = PhotoUtilities.getMediaEntryImageName(
    entryCollection.mediaEntry,
    entryCollection.encompassingCollection.project?.rootProject
  );
  return { file, fileName };
}

function addMediaFilesToZipper(zipper: JSZip, files: IFile[]) {
  map(files, ({ file, fileName }) => {
    const name = `${fileName}${file.type === 'video/mp4' ? '.mp4' : '.jpg'}`;
    return zipper.file(name, file, { base64: true });
  });
}

function getMediaEntryImageName(entry: IMediaEntry, rootProject?: IProject | null) {
  // Todo check that timezone is correct
  const date = DateTime.fromISO(entry.captureDate!, { zone: 'utc' });

  // Feb-22-2019
  const localeDateString = date.toLocaleString(DateTime.DATE_MED).replace(/(, | )/g, '-');

  if (rootProject) {
    return `${rootProject.title}-${localeDateString}`;
  } else {
    return localeDateString;
  }
}

export function updateSameFileNames(files: IFile[]) {
  const groupedTitles = groupBy(files, 'fileName');

  forIn(groupedTitles, (matches, fileName) => {
    if (matches.length && matches.length > 1) {
      matches.forEach((match, index) => {
        match.fileName = `${fileName}-${index + 1}`;
      });
    }
  });
}

// temp types for loaders to make declarations less confusing, `IMediaEntry['id'][][]`
type TMediaEntryId = IMediaEntry['id'];
type TMediaCollectionId = IMediaCollection['id'];

const QUERY_BATCH_SIZE = 50; // use the busybusy grapqhl default

export async function loadMediaEntry(
  apolloClient: ApolloClient<any>,
  mediaEntryId: TMediaEntryId | TMediaEntryId[]
): Promise<IMediaEntry[]> {
  const containsIds: TMediaEntryId[] = castArray<TMediaEntryId>(mediaEntryId); // the filter argument must be an array
  const batches: TMediaEntryId[][] = chunk(containsIds, QUERY_BATCH_SIZE);

  // Note: Apollo Client combines up to 10 queries into a single request, so this doesn't generate as many network calls as might be expected
  return Promise.all(
    batches.map((batchIds) =>
      apolloClient
        .query({
          fetchPolicy: 'network-only', // no caching
          query: mediaEntryQuery,
          variables: {
            first: QUERY_BATCH_SIZE,
            filter: {
              id: { contains: batchIds },
              deletedOn: { isNull: true },
            },
          },
        })
        // reduce results to the relevant bits
        .then((queryResult: ApolloQueryResult<IMediaEntryQueryResults>) => queryResult.data.mediaEntries)
    )
    // return the flattened the array of arrays
  ).then(flatten);
}

export async function loadMediaCollection(
  apolloClient: ApolloClient<any>,
  mediaCollectionId: TMediaCollectionId | TMediaCollectionId[]
): Promise<IMediaCollection[]> {
  const containsIds: TMediaCollectionId[] = castArray<TMediaCollectionId>(mediaCollectionId); // the filter argument must be an array
  const batches: TMediaCollectionId[][] = chunk(containsIds, QUERY_BATCH_SIZE);

  return Promise.all(
    batches.map((batch) =>
      apolloClient
        .query({
          fetchPolicy: 'network-only', // no caching
          query: mediaCollectionQuery,
          variables: {
            first: QUERY_BATCH_SIZE,
            filter: {
              id: { contains: batch },
              deletedOn: { isNull: true },
            },
            sort: [{ createdOn: 'desc' }],
          },
        })
        // reduce results to the relevant bits
        .then((apolloResult: ApolloQueryResult<IMediaCollectionQueryResults>) => {
          return apolloResult.data.mediaCollections.map((collection) => {
            const newCollection = cloneDeep(collection);
            // mutate the mediaEntries collection, removing all deleted mediaEntries and sorting the remainder
            newCollection.mediaEntries = reject(newCollection.mediaEntries, 'deletedOn');
            newCollection.mediaEntries = sortBy(newCollection.mediaEntries, ['createdOn', 'captureDate']);

            return newCollection;
          });
        })
    )
    // return the flattened the array of arrays
  ).then(flatten);
}

export function getResizedPhotoUrl(fileUrl: string, size: number) {
  return `${fileUrl}?w=${size}&h=${size}`;
}

export function getPhotoThumbnailUrl(fileUrl: string) {
  return getResizedPhotoUrl(fileUrl, 280);
}

const PhotoUtilities = {
  addMediaFilesToZipper,
  convertToFile,
  downloadCollection,
  downloadCollectionBlob,
  downloadCollections,
  downloadMediaEntries,
  downloadMediaEntry,
  downloadMediaEntryBlob,
  getCollectionForMediaFromCollections,
  getMediaEntriesFromCollections,
  getMediaEntryImageName,
  updateSameFileNames,
  loadMediaEntry,
  loadMediaCollection,
};

export default PhotoUtilities;
