import { BASIC_LABOR_METRICS_FRAGMENT, BASIC_TIME_METRICS_FRAGMENT } from 'apollo/queries/jit-queries';
import gql from 'graphql-tag';

export const COST_CODE_TIME_METRIC_QUERY = gql`
  query QueryCostCodeTimeMetrics(
    $first: Int
    $after: String
    $filter: CostCodeFilter
    $sort: [CostCodeSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    costCodes(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      costCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        ...BasicLaborMetrics
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const COST_CODE_LABOR_METRIC_QUERY = gql`
  query QueryCostCodeLaborMetrics(
    $first: Int
    $after: String
    $filter: CostCodeFilter
    $sort: [CostCodeSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
  ) {
    costCodes(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      costCodeLaborMetrics(interval: $metricsInterval, start: $metricsStartDate, end: $metricsEndDate) {
        costCodeId
        ...LaborMetrics
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;
