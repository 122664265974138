import { useApolloClient } from '@apollo/client';
import {
  Align,
  Button,
  DatePickerFormField,
  Form,
  IDatePickerPayload,
  IFormRender,
  IFormValidation,
  Label,
  Loader,
  Row,
  TextareaFormField,
  TextFormField,
} from '@busybusy/webapp-react-ui';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { CostCodePickerFormField } from 'components';
import ProjectPickerFormField from 'components/domain/project/ProjectPickerFormField/ProjectPickerFormField';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useActiveMember, useOpenable, useOrganization } from 'hooks';
import useProjectCostCodeQuantity from 'hooks/models/project-cost-code-quantity/useProjectCostCodeQuantity';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ICostCode from 'types/CostCode';
import IProjectCostCodeQuantity from 'types/ProjectCostCodeQuantity';
import { t } from 'utils/localize';
import { v_max_number, v_number_decimals_only, v_project_cost_code, v_require } from 'utils/validations';
import './ProjectCostCodeQuantityForm.scss';

export interface IProjectCostCodeQuantityFormData {
  projectId?: string | null;
  costCodeId?: string | null;
  quantity?: number | null;
  date: IDatePickerPayload;
  note?: string | null;
}

export interface IProjectCostCodeQuantityFormProps {
  className?: ClassName;
  date?: DateTime | null;
  projectId?: string | null;
  costCodeId?: string | null;
  onSubmit: (quantity: IProjectCostCodeQuantity) => void;
  onDelete?: (id: string) => void;
  quantity?: IProjectCostCodeQuantity | null;
}

const ProjectCostCodeQuantityForm = (props: IProjectCostCodeQuantityFormProps) => {
  const { className, date, projectId, costCodeId, onSubmit, onDelete, quantity } = props;

  const today = DateTime.local().startOf('day');
  const formInfo = useRef<IProjectCostCodeQuantityFormData>(getInitialState());
  const dateOpenable = useOpenable();
  const organization = useOrganization();
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const activeMember = useActiveMember();
  const { createQuantity, updateQuantity } = useProjectCostCodeQuantity();
  const [selectedCostCode, setSelectedCostCode] = useState<ICostCode | null>(null);
  const deleteConfirmationOpenable = useOpenable();

  function getInitialState(): IProjectCostCodeQuantityFormData {
    if (quantity) {
      const dateTime = DateTime.fromISO(quantity.assignedOn);
      return {
        projectId: quantity.projectId,
        costCodeId: quantity.costCodeId,
        date: {
          value: dateTime.toJSDate(),
          inputValue: dateTime.toFormat('DD'),
        },
        quantity: quantity.amount,
        note: quantity.description,
      };
    }
    return {
      projectId,
      costCodeId,
      date: isNil(date)
        ? {
            value: today.toJSDate(),
            inputValue: today.toFormat('DD'),
          }
        : {
            value: date.toJSDate(),
            inputValue: date.toFormat('DD'),
          },
      quantity: null,
      note: null,
    };
  }

  useEffect(() => {
    if (!isNil(costCodeId)) {
      getCostCode(costCodeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costCodeId]);

  useEffect(() => {
    if (!isNil(quantity) && !isNil(quantity?.costCode.unitTitle)) {
      setSelectedCostCode(quantity.costCode);
    }
  }, [quantity]);

  async function getCostCode(id: string) {
    const results = await client.query<{ costCodes: ICostCode[] }>({
      query: COST_CODES_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: id },
        },
      },
    });
    setSelectedCostCode(results.data.costCodes[0] ?? null);
  }

  function renderFormFields({ ...form }: IFormRender<IProjectCostCodeQuantityFormData>) {
    function onProjectSelect(value: string | null) {
      if (!value) {
        form.setData('costCodeId', null);
      }
    }

    function onCostCodeSelect(value: string | null) {
      if (value) {
        getCostCode(value);
      }
    }

    const costCodeValidations = () => {
      const validations: Array<IFormValidation<string | null, any>> = [{ validation: v_require }];
      if (organization.useProjectCostCodeScoping) {
        validations.push({
          validation: v_project_cost_code,
          args: {
            projectField: 'projectId',
            client,
            allowNoCostCodeLink: false,
          },
        });
      }
      return validations;
    };

    function getQuantityUnits(): ReactNode {
      if (isNil(form.state.data.costCodeId)) {
        return <></>;
      } else {
        return (
          <Label className={'units-label'}>
            {selectedCostCode?.unitTitle ? selectedCostCode.unitTitle : t('units')}
          </Label>
        );
      }
    }

    return (
      <div>
        <Label>{t('Project')}</Label>
        <ProjectPickerFormField
          name="projectId"
          onChange={onProjectSelect}
          form={form}
          placeholder={t('Project')}
          validations={[{ validation: v_require }]}
        />
        <Label>{t('Cost Code')}</Label>
        <CostCodePickerFormField
          name="costCodeId"
          projectId={form.state.data.projectId}
          placeholder={t('Cost Code')}
          form={form}
          validations={costCodeValidations()}
          onSelect={onCostCodeSelect}
        />
        <Label>{t('Quantity')}</Label>
        <Row align={Align.CENTER}>
          <TextFormField
            className="quantity-field"
            type="number"
            name={'quantity'}
            placeholder={'0'}
            form={form}
            validations={[
              { validation: v_require },
              { validation: v_number_decimals_only },
              {
                validation: v_max_number,
                args: { num: 9999999999, message: t('To large of a number.') },
              },
            ]}
          />
          {getQuantityUnits()}
        </Row>
        <Label>{t('Date')}</Label>
        <DatePickerFormField
          className="date-field"
          name="date"
          form={form}
          disabledAfter={DateTime.local().startOf('day').toJSDate()}
          validations={[{ validation: v_require }]}
          isOpen={dateOpenable.isOpen}
          onClose={dateOpenable.close}
          onOpen={dateOpenable.open}
        />
        <Label optional={true}>{t('Note')}</Label>
        <TextareaFormField name="note" form={form} restrictTo={{ maxLength: 2000 }} className="mt-4" />
        <div className="pt-6">
          <Button onClick={form.handleSubmit} type="primary">
            {t('Submit')}
          </Button>
          {quantity && (
            <Button type="secondary" onClick={deleteConfirmationOpenable.open} className="ml-4">
              {t('Delete')}
            </Button>
          )}
        </div>
      </div>
    );
  }

  async function handleDelete() {
    deleteConfirmationOpenable.close();
    if (quantity) {
      setLoading(true);
      onDelete?.(quantity.id);
      setLoading(false);
    }
  }

  async function handleSubmit(data: IProjectCostCodeQuantityFormData) {
    setLoading(true);
    if (quantity) {
      const updatedQuantity = await updateQuantity(
        quantity.id,
        data.quantity!,
        DateTime.fromJSDate(data.date.value!).toISO(),
        data.costCodeId!,
        data.note,
        data.projectId!
      );
      setLoading(false);
      onSubmit(updatedQuantity.data.updateProjectCostCodeQuantity);
    } else {
      const newQuantity = await createQuantity(
        data.projectId!,
        data.costCodeId!,
        data.quantity!,
        DateTime.fromJSDate(data.date.value!),
        activeMember.id!,
        data.note
      );
      setLoading(false);
      onSubmit(newQuantity);
    }
  }

  const classes = classNames('project-cost-code-quantity', className);

  return (
    <>
      <Form className={classes} data={formInfo.current} onSubmit={handleSubmit} render={renderFormFields} />
      <Loader isOpen={loading} />
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationOpenable.isOpen}
        onClose={deleteConfirmationOpenable.close}
        onDelete={handleDelete}
        title={t('Delete progress submission?')}
        showHeaderDivider={false}
      >
        {t('If you delete this item it will be gone forever. Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
    </>
  );
};

export default ProjectCostCodeQuantityForm;
