import { useMutation } from '@apollo/client';
import {
  CREATE_PROJECT_COST_CODE_QUANTITY,
  UPDATE_PROJECT_COST_CODE_QUANTITY,
} from 'apollo/mutations/project-cost-code-quantity-mutations';
import { round } from 'lodash';
import { DateTime } from 'luxon';
import IProjectCostCodeQuantity, {
  IProjectCostCodeQuantityCreateInput,
  IProjectCostCodeQuantityUpdateInput,
} from 'types/ProjectCostCodeQuantity';
import { getNowIsoAtUtcWithLocalTimeZone, isoTimeStampUtc } from 'utils/dateUtils';
import { uuid } from 'utils/uuidUtils';

export default function useProjectCostCodeQuantity() {
  const [createProjectCostCodeQuantityMutation] = useMutation(CREATE_PROJECT_COST_CODE_QUANTITY);
  const [updateProjectCostCodeQuantityMutation] = useMutation(UPDATE_PROJECT_COST_CODE_QUANTITY);

  async function createQuantity(
    projectId: string,
    costCodeId: string,
    amount: number,
    assignedOn: DateTime,
    memberId: string,
    note?: string | null
  ) {
    const now = DateTime.local();
    const quantity: IProjectCostCodeQuantityCreateInput = {
      id: uuid(),
      projectId,
      costCodeId,
      amount: round(amount, 2),
      assignedOn: assignedOn.toISO(),
      submittedBy: memberId,
      description: note,
      createdOnDst: now.isInDST,
      createdOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return new Promise<IProjectCostCodeQuantity>((resolve, reject) => {
      return createProjectCostCodeQuantityMutation({ variables: { projectCostCodeQuantity: quantity } }).then(
        (results) => {
          if (results.data) {
            resolve(results.data.createProjectCostCodeQuantity);
          } else {
            reject();
          }
        }
      );
    });
  }

  async function deleteQuantity(id: string) {
    const quantity: IProjectCostCodeQuantityUpdateInput = {
      id,
      deletedOn: isoTimeStampUtc(),
    };
    return updateProjectCostCodeQuantityMutation({ variables: { projectCostCodeQuantity: quantity } });
  }

  async function updateQuantity(
    id: string,
    amount?: number | null,
    assignedOn?: string | null,
    costCodeId?: string | null,
    description?: string | null,
    projectId?: string | null,
    type?: string | null
  ) {
    const quantity: IProjectCostCodeQuantityUpdateInput = {
      id,
      amount: amount ? round(amount, 2) : amount,
      assignedOn,
      costCodeId,
      description,
      projectId,
      type,
    };
    return updateProjectCostCodeQuantityMutation({ variables: { projectCostCodeQuantity: quantity } });
  }

  return { createQuantity, deleteQuantity, updateQuantity };
}
