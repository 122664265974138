import { Avatar, Button, ButtonList, ClassName, Size } from '@busybusy/webapp-react-ui';
import { MediaEntryComment } from '__generated__/graphql';
import classNames from 'classnames';
import { MoreButton } from 'components';
import { formatPostCommentDate } from 'containers/bulletin/utils/utils';
import { useActiveMember, usePermissions } from 'hooks';
import { t } from 'i18next';
import { useState } from 'react';
import { getFullNameFromMember } from 'utils/stringUtils';
import './PhotoCommentView.scss';

export interface IPhotoCommentViewProps {
  className?: ClassName;
  comment: MediaEntryComment;
  onDelete: (commentId: string) => void;
  onEdit: (comment: MediaEntryComment) => void;
  onViewClick: (comment: MediaEntryComment) => void;
}

const PhotoCommentView = (props: IPhotoCommentViewProps) => {
  const { className, comment, onDelete, onEdit, onViewClick } = props;
  const [isHovered, setIsHovered] = useState(false);
  const activeMember = useActiveMember();
  const { hasPermissionToManage } = usePermissions();
  const canManagePhotosForMember = hasPermissionToManage(comment.createdByMember, 'manageMediaCollection');
  const canEditComment = comment.createdByMember.id === activeMember.id;
  const canDeleteComment = canManagePhotosForMember || canEditComment;

  const handleDelete = (close: () => void) => {
    onDelete(comment.id);
    close();
  };

  const handleEdit = (close: () => void) => {
    onEdit(comment);
    close();
  };

  const handleViewDetails = (close: () => void) => {
    onViewClick(comment);
    close();
  };

  const classes = classNames('photo-comment-view p-2', className);
  return (
    <div className={classes} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className="comment-header-section">
        <Avatar
          firstName={comment.createdByMember.firstName!}
          lastName={comment.createdByMember.lastName!}
          image={comment.createdByMember.imageUrl ?? undefined}
          size={Size.SMALL}
        />
        <div className="comment-header-text ml-4">
          <div className="fw-bold">{getFullNameFromMember(comment.createdByMember)}</div>
          <div className="f-sm comment-time">{formatPostCommentDate(comment.createdOn)}</div>
        </div>
        {isHovered && (
          <MoreButton
            renderContent={(close: () => void) => (
              <ButtonList>
                {canEditComment && (
                  <Button
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleEdit(close);
                    }}
                  >
                    {t('Edit')}
                  </Button>
                )}
                <Button
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleViewDetails(close);
                  }}
                >
                  {t('View Details')}
                </Button>
                {canDeleteComment && (
                  <Button
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleDelete(close);
                    }}
                  >
                    {t('Delete')}
                  </Button>
                )}
              </ButtonList>
            )}
          />
        )}
      </div>
      <div className="comment-body">
        {comment.comment}
        {comment.updatedBy ? <span className="comment-edited">{t(' (edited)')}</span> : <></>}
      </div>
    </div>
  );
};

export default PhotoCommentView;
