import { } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { useTimeEntryActionBarState } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import DateTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/DateTimeEntryDataReport/DateTimeEntryDataReport';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { defaultTimeEntryColumns } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { usePrintComplexDataChannelListener } from 'contexts/ChannelContext/hooks/usePrintComplexDataChannelListener';
import { useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { isEmpty, isNil, isNull, map, partition } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { t } from 'utils/localize';
import useDateRangeTimeEntryDataReport from '../TimeEntryDataReport/DateTimeEntryDataReport/hooks/useDateRangeTimeEntryDataReport';
import { useTimeEntryExportChannelListener } from '../hooks/useTimeEntryExportChannelListener';

export interface IDateRangeTimeEntryTableListeningContainerProps {
  className?: ClassName;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  clipDatesToTimeRange: boolean;
  equipmentIds?: string[];
  memberIds?: string[];
  projectIds?: string[];
  costCodeIds?: string[];
  projectIdWithDescendants?: boolean;
  applyPrintOptions: boolean;
}

const DateRangeTimeEntryTableListeningContainer = (props: IDateRangeTimeEntryTableListeningContainerProps) => {
  const {
    className,
    timeRange,
    timeRangeType,
    clipDatesToTimeRange,
    equipmentIds,
    memberIds,
    projectIds,
    costCodeIds,
    projectIdWithDescendants,
    applyPrintOptions,
  } = props;

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const { data, loading, getData } = useDateRangeTimeEntryDataReport(
    timeRange,
    timeRangeType,
    clipDatesToTimeRange,
    false,
    costCodeIds,
    equipmentIds,
    memberIds,
    projectIds,
    projectIdWithDescendants
  );

  const brand = useBrandTitle();
  const [timeEntryActionBarState, timeEntryActionBarDispatch] = useTimeEntryActionBarState();

  useEffect(() => {
    if (!isEmpty(timeEntryActionBarState.entries)) {
      getData();
    }
    if (!isEmpty(timeEntryActionBarState.memberIds)) {
      getData();
    }
    if (!isNull(timeEntryActionBarState.formData)) {
      getData();
    }
  }, [timeEntryActionBarState]);

  useTimeEntryExportChannelListener(
    data,
    !loading,
    `${brand}-${t('activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}`
  );
  usePrintComplexDataChannelListener(data, !loading);

  const classes = classNames('date-range-time-entry-table-container', 'show-date-print-column', className);

  return (
    <DateTimeEntryDataReport
      className={classes}
      columnSettings={defaultTimeEntryColumns}
      updateDates={getData}
      timeRange={timeRange}
      selectedIds={selectedIds}
      onSelectChange={onSelect}
      data={data}
      loading={loading}
      memberIds={memberIds}
      loadedAll={!loading}
      applyPrintOptions={applyPrintOptions}
    />
  );
};

export default DateRangeTimeEntryTableListeningContainer;
