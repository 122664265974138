import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { ReactNode } from 'react';
import { t } from 'utils/localize';

export interface ITableColumnsDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose?: () => void;
  columnsForm: ReactNode;
  title?: string;
}

const TableColumnsDialog = (props: ITableColumnsDialogProps) => {
  const { className, isOpen, onClose, columnsForm, title } = props;
  const classes = classNames('table-columns-dialog', className);

  return (
    <HeaderDialog
      className={classes}
      title={title ?? t('Table Columns')}
      divider={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="p-7">{columnsForm}</div>
    </HeaderDialog>
  );
};

export default TableColumnsDialog;
