import {
  Button,
  CheckboxFormField,
  Form,
  IFormRender,
  Image,
  Label,
  Radio,
  RadioGroupFormField,
  SelectFormField,
  TextFormField,
  Theme,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import useTimeCardsSettingsUpdate from 'hooks/models/member-settings/useTimeCardsSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { TimesheetView } from 'store/timesheets/Timesheets';
import { t } from 'utils/localize';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import { ITimesheetPrintOptionsData, defaultTimesheetPrintOptions } from './TimesheetPrintOptions';
import './TimesheetPrintOptionsForm.scss';

export interface ITimesheetPrintOptionsFormProps {
  className?: ClassName;
  onPrint: (options: ITimesheetPrintOptionsData) => void;
}

const TimesheetPrintOptionsForm = (props: ITimesheetPrintOptionsFormProps) => {
  const { className, onPrint } = props;
  const memberSettings = useMemberSettings();
  const updateSettings = useTimeCardsSettingsUpdate();

  const mergedOptions = {
    ...defaultTimesheetPrintOptions,
    ...memberSettings?.web?.features?.timeCards?.printOptions,
  };

  const [options, setOptions] = useState(mergedOptions);
  const [formInfo, setFormInfo] = useState<ITimesheetPrintOptionsData>(options);
  const padRef = useRef<typeof SignaturePad>();
  const [showClear, setShowClear] = useState<boolean>(options.signature !== undefined);

  const { timesheetView } = useTimesheetsQueryParams();

  const updateOptions = (newOptions: ITimesheetPrintOptionsData) => {
    setOptions(newOptions);
    onPrint(newOptions);
    if (JSON.stringify(newOptions) !== JSON.stringify(mergedOptions)) {
      updateSettings([{ key: 'printOptions', payload: newOptions }]);
    }
  };

  function clearSignature() {
    if (padRef.current) {
      padRef.current.clear();
    }
    setFormInfo({ ...formInfo, signature: undefined });
    setShowClear(false);
  }

  function renderFormFields(form: IFormRender<ITimesheetPrintOptionsData>) {
    return (
      <div>
        <CheckboxFormField
          name="includeAdditionalSignatureLines"
          form={form}
          label={
            <HeaderText title={t('Include Additional Signature Lines')}>
              <span>
                {t(
                  'Include one or more signatures lines in the document. This is in addition to any digital time card signatures and is used for printing.'
                )}
              </span>
            </HeaderText>
          }
        />
        {formInfo.includeAdditionalSignatureLines && (
          <div className="mb-8">
            <DashboardRibbon theme={Theme.LIGHT}>
              <Label>{t('Position')}</Label>
              <SelectFormField
                name="position"
                form={form}
                placeholder=""
                options={[
                  { label: t('At end of document'), value: 'at-end-of-document' },
                  { label: t('After each section'), value: 'after-each-section' },
                ]}
              />
              <Label>{t('Title')}</Label>
              <TextFormField name={'title'} form={form} placeholder={t('Sign Off')} />
              <Label>{t('Signature')}</Label>
              <div className="signature-box">
                {formInfo.signature ? (
                  <Image className="signature-image" src={formInfo.signature} alt="signature" />
                ) : (
                  <SignaturePad
                    ref={padRef}
                    options={{
                      minWidth: 2,
                      maxWidth: 2,
                      penColor: 'RGB(49, 55, 61)',
                      backgroundColor: 'RGB(255, 255, 255)',
                      onEnd: () => setShowClear(!padRef.current.isEmpty()),
                    }}
                    height={135}
                  />
                )}
              </div>
              <div className="mt-2">
                <span>{t('Leave blank to sign after printing.')}</span>
                {showClear && (
                  <Button type="link" onClick={clearSignature}>
                    {t('Clear Signature')}
                  </Button>
                )}
              </div>
            </DashboardRibbon>
          </div>
        )}
        <CheckboxFormField
          name="pageBreaks"
          form={form}
          label={
            <HeaderText title={t('Page Breaks')}>
              <span>{t('Print each section on its own sheet of paper.')}</span>
            </HeaderText>
          }
        />
        {timesheetView === TimesheetView.EXPANDED_TIME_CARDS && (
          <>
            <CheckboxFormField
              name="showAllEmployees"
              form={form}
              label={
                <HeaderText title={t('Show All Employees')}>
                  <span>
                    {t(
                      "Print a time card for every active employee, even those who don't have any tracked time for the date range."
                    )}
                  </span>
                </HeaderText>
              }
            />
            <HeaderText title={t('Print Density')}>
              <span>
                {t(
                  'Select the print density for the time cards. Compact will print more information per page, while Standard will print less information per page.'
                )}
              </span>
            </HeaderText>
            <RadioGroupFormField className="section-options my-4" name="printDensity" form={form}>
              <Radio key={'compact'} value={'compact'} label={t('Compact')} />
              <Radio key={'standard'} value={'standard'} label={t('Standard')} />
            </RadioGroupFormField>
          </>
        )}
        <Button className="mt-8" type="primary" onClick={form.handleSubmit}>
          {t('Print')}
        </Button>
      </div>
    );
  }

  function onFormSubmit() {
    updateOptions({
      ...formInfo,
      signature:
        padRef.current && !padRef.current.isEmpty()
          ? padRef.current.toDataURL('image/svg+xml')
          : showClear
          ? formInfo.signature
          : undefined,
    });
  }

  const classes = classNames('print-options-form', className);

  return (
    <Form
      render={renderFormFields}
      onSubmit={onFormSubmit}
      className={classes}
      data={formInfo}
      onChange={(data) => data && setFormInfo({ ...data })}
    />
  );
};
export default TimesheetPrintOptionsForm;
