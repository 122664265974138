/*
  New feature flags must be declared on IFeatureFlags and must be set in at least one of the environments
    .env, LaunchDarkly, or production/staging/beta/alpha/localhost

  Flags defined in .env will overwrite flags defined in environment templates

  The LaunchDarkly middleware will ignore flags defined in .env or environment templates and will not overwrite them

  Flags defined in .env files must be prefixed by `REACT_APP_FEATURE_`
*/
import _ from 'lodash';

import { TEnvironmentKeys } from 'store/store';

import { IFeatureFlags, TFeatureTemplates, TFlagKey } from 'types';

import { alpha } from './alpha';
import { beta } from './beta';
import { localhost } from './localhost';
import { production } from './production';
import { staging } from './staging';
import { test } from './test';

import { launchDarklyFeatures } from './launchDarkly';
import { processFlags } from './process';

export const templates: TFeatureTemplates = {
  production,

  alpha,
  beta,
  localhost,
  staging,
  test,
};

const currentEnvironment: TEnvironmentKeys = process.env.REACT_APP_ENV as TEnvironmentKeys;
export const featuresTemplate: IFeatureFlags = templates[currentEnvironment];
export const featuresTemplateKeys = _.keys(featuresTemplate) as TFlagKey[];

// ALL feature flags defined on IFeatureFlags must have values set at this point
export const featureDefaults = Object.assign(
  {},
  launchDarklyFeatures,
  featuresTemplate,
  processFlags
) as Required<IFeatureFlags>;

export const featureKeys: TFlagKey[] = Object.keys(featureDefaults) as TFlagKey[];

Object.freeze(featureDefaults);
Object.freeze(featureKeys);
