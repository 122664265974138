import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import BasicPicker, { IBasicPickerProps } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { AnyObject } from 'types/util/Object';

export interface IBasicPickerFormFieldProps<FormType extends AnyObject>
  extends Omit<IFormFieldDefaultProps<string, FormType>, 'value'>,
    Omit<IBasicPickerProps, 'onSelect' | 'value'> {
  onChange?: (value: string | null) => void;
}

function BasicPickerFormField<FormType extends AnyObject>({
  form,
  name,
  validations,
  onChange,
  className,
  revalidateOnNewValidations,
  ...rest
}: IBasicPickerFormFieldProps<FormType>) {
  const classes = classNames('basic-picker-form-field', className);

  const value = form.state.data[name];

  function renderField({ ...fieldProps }) {
    function handleOnChange(newValue: string | null) {
      fieldProps.handleChange(newValue, onChange);
    }

    return (
      <>
        <BasicPicker value={value} error={fieldProps.hasError} onSelect={handleOnChange} {...rest} />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

export default BasicPickerFormField;
