import gql from 'graphql-tag';

export const membersQuery = gql`
  query QueryMemberPick($first: Int, $after: String, $filter: MemberFilter, $sort: [MemberSort!]) {
    members(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      firstName
      lastName
    }
  }
`;

export const memberQuery = gql`
  query queryMemberById($memberId: Uuid) {
    members(filter: { id: { equal: $memberId } }) {
      id
      firstName
      lastName
      archivedOn
    }
  }
`;
