import useNavigation from './useNavigation';

interface IParams {
  switch?: boolean;
  exitToLegacy?: boolean;
}

export default function useLoginNavigation() {
  return useNavigation<IParams>('/login');
}
