import { gql } from '@apollo/client';
import { useApolloPaging } from 'hooks';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import { OrganizationSignInQuestion, OrganizationSignInQuestionFilter } from '__generated__/graphql';

export default function useGetOrganizationSignInQuestions() {
  const { getAll } = useApolloPaging();

  return useCallback(
    async (filter?: OrganizationSignInQuestionFilter) => {
      return await getAll<ICursorable & OrganizationSignInQuestion>('organizationSignInQuestions', {
        fetchPolicy: 'network-only',
        query: QUERY_ORGANIZATION_SIGN_IN_QUESTIONS,
        variables: {
          filter: {
            ...filter,
          },
          sort: [{ order: 'asc' }],
        },
      });
    },
    [getAll]
  );
}

export const QUERY_ORGANIZATION_SIGN_IN_QUESTIONS = gql`
  query getAllOrganizationSignInQuestions(
    $filter: OrganizationSignInQuestionFilter
    $first: Int
    $sort: [OrganizationSignInQuestionSort!]
    $after: String
  ) {
    organizationSignInQuestions(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      audienceType
      choices
      createdOn
      cursor
      deletedOn
      organizationId
      positionId
      questionDescription
      questionPrompt
      questionTitle
      required
      submittedOn
      type
      updatedOn
      order
    }
  }
`;
