import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { DateTime } from 'luxon';
import IDatePickerValue from 'types/DatePickerValue';
import ITimeRange from 'types/TimeRange';
import { getAdjustedEndDateChange, getAdjustedStartDateChange } from 'utils/dateUtils';
import DateRange, { IDateRangeProps } from '../DateRange/DateRange';

export interface IAdjustingDateRangeProps
  extends Omit<IDateRangeProps, 'startDate' | 'endDate' | 'onStartChange' | 'onEndChange'> {
  onChange: (dateRange: ITimeRange<DateTime | null>) => void;
  value: ITimeRange<DateTime | null>;
  className?: ClassName;
}

function AdjustingDateRange({ value, onChange, className, ...rest }: IAdjustingDateRangeProps) {
  const startTime = value?.startTime ?? null;
  const endTime = value?.endTime ?? null;

  function handleStartDateChange(startDatePickerValue: IDatePickerValue) {
    if (startDatePickerValue.value) {
      const { newStartDate, newEndDate } = getAdjustedStartDateChange(startDatePickerValue.value, endTime);
      onChange({ startTime: newStartDate, endTime: newEndDate });
    } else {
      onChange({ endTime, startTime: null });
    }
  }

  function handleEndDateChange(endDatePickerValue: IDatePickerValue) {
    if (endDatePickerValue.value) {
      const { newStartDate, newEndDate } = getAdjustedEndDateChange(endDatePickerValue.value, startTime);
      onChange({ startTime: newStartDate, endTime: newEndDate });
    } else {
      onChange({ startTime, endTime: null });
    }
  }

  return (
    <DateRange
      className={classNames('adjusting-date-range', className)}
      startDate={startTime}
      endDate={endTime}
      onStartChange={handleStartDateChange}
      onEndChange={handleEndDateChange}
      {...rest}
    />
  );
}

export default AdjustingDateRange;
