import { Icon, ITableColumn, Label, Table, Theme, Toast } from '@busybusy/webapp-react-ui';
import { LaborMetricsInterval } from '__generated__/graphql';
import { ClockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmptyState } from 'components';
import ContainerWithSpinner from 'components/foundation/ContainerWithSpinner/ContainerWithSpinner';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { IDateActivityTableRowInfo } from 'containers/activity-reports/date-tables/hooks/useDateActivity';
import useDateActivityDetails from 'containers/activity-reports/date-tables/hooks/useDateActivityDetails';
import useActivityReportDetailColumn, {
  activityReportDetailId,
} from 'containers/activity-reports/hooks/useActivityReportDetailColumn';
import useActivityReportDetailTable from 'containers/activity-reports/hooks/useActivityReportDetailTable';
import useActivityReportExport from 'containers/activity-reports/hooks/useActivityReportExport';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelListener } from 'contexts/ChannelContext/hooks/useChannelListener';
import { useExportChannelListener } from 'contexts/ChannelContext/hooks/useExportChannelListener';
import { useOpenable } from 'hooks';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getTimeFormatWithYearConsideration } from 'utils/dateUtils';
import { t } from 'utils/localize';
import './DateActivityReportDetailsTable.scss';
import useBrandTitle from 'hooks/meta/useBrandTitle';

export interface IDateActivityReportDetailsProps {
  className?: ClassName;
  filterId: string;
  filterIdWithDescendants?: boolean;
  filterType: ActivityReportType;
  secondaryReportType: ActivityReportType;
  timeRange: ITimeRange<DateTime>;
  onRowSelected: (date: DateTime, secondaryId?: string) => void;
  scroller: HTMLElement | null;
}

const DateActivityReportDetailsTable = (props: IDateActivityReportDetailsProps) => {
  const {
    className,
    filterId,
    filterIdWithDescendants,
    filterType,
    secondaryReportType,
    timeRange,
    onRowSelected,
    scroller,
  } = props;
  const classes = classNames('date-activity-report-details', className);

  const { sortedData, onSort, sortedBy, sortDirection, sortIsDirty, isLoading } = useDateActivityDetails(
    filterId,
    filterType,
    secondaryReportType,
    timeRange,
    LaborMetricsInterval.Day,
    filterIdWithDescendants
  );

  const restrictedAccessToast = useOpenable();

  const { aggregateColumns, tableMinWidth } = useActivityReportDetailTable(
    sortedData,
    secondaryReportType,
    ActivityReportType.BY_DAY
  );
  const { detailColumn, isDetailRow, exportDetailColumn, getExportData } =
    useActivityReportDetailColumn(secondaryReportType);

  const [lazyLoadingEnabled, setLazyLoadingEnabled] = useState(true);
  useChannelListener(ChannelIds.LazyLoadUpdate, () => setLazyLoadingEnabled(false));

  const getActivityExportData = useActivityReportExport();

  const flattenedData = useMemo(() => flattenData(sortedData), [sortedData]);

  const brand = useBrandTitle();

  useExportChannelListener(() => exportReport(), true, `${brand}-day-activity-report-${dateUtils.isoTimeStampLocal()}`);

  function exportReport() {
    const exportData = getExportData(sortedData);
    return getActivityExportData(exportData, (row) => {
      return {
        [t('Date')]: row.startDate.toFormat('MM/dd/yyyy'),
        ...exportDetailColumn(row),
      };
    });
  }

  const renderDate = (row: IDateActivityTableRowInfo) => {
    if (secondaryReportType !== ActivityReportType.BASIC && isDetailRow(row)) {
      return null;
    } else if (row.id === remainingDataItemId) {
      return <>{t('Additional Time')}</>;
    }

    const date = row.startDate;
    return <>{date.toFormat(getTimeFormatWithYearConsideration(date, 'LLL d'))}</>;
  };

  function getColumns(): Array<ITableColumn<IDateActivityTableRowInfo>> {
    return [
      {
        key: 'startDate',
        title: t('Date'),
        sort: true,
        cell: renderDate,
        size: '300px',
        footer: renderTotalColumnFooter,
        cellClassName: 'pl-6  name-column',
        headerClassName: 'pl-6  name-column',
        footerClassName: 'pl-6  name-column',
      },
      ...detailColumn,
      ...aggregateColumns,
    ];
  }

  const renderTotalColumnFooter = () => <Label className="cell-title">{t('Totals')}</Label>;

  function rowClickAction(rowItem: IDateActivityTableRowInfo) {
    if (rowItem.id !== remainingDataItemId) {
      const secondaryId = activityReportDetailId(secondaryReportType, rowItem);
      onRowSelected(rowItem.startDate, secondaryId);
    } else {
      restrictedAccessToast.open();
    }
  }

  function renderEmptyState() {
    return <EmptyState graphic={<Icon svg={ClockIcon} size={180} />} title={t('No Activity')} />;
  }

  function getRowClassName(rowItem: IDateActivityTableRowInfo) {
    const totalRow =
      secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
    return isDetailRow(rowItem) ? 'detailRowBackground' : totalRow;
  }

  const tableFooterClassName = useMemo(() => {
    return secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
  }, [secondaryReportType]);

  function flattenData(data: IDateActivityTableRowInfo[]): IDateActivityTableRowInfo[] {
    return data.flatMap((item) => {
      if (item.detailRows && !isEmpty(item.detailRows)) {
        return [item, ...item.detailRows];
      } else {
        return [item];
      }
    });
  }

  return (
    <ContainerWithSpinner className={classes} isLoading={isLoading}>
      <Table
        className={'date-activity-report-details-table'}
        cols={getColumns()}
        isLoading={isLoading}
        data={flattenedData}
        sortDir={sortDirection}
        sortBy={sortedBy}
        strokeCols={true}
        onSort={onSort}
        sortIsDirty={sortIsDirty}
        lazyLoad={lazyLoadingEnabled}
        lazyScrollSectionSize={50}
        lazyScrollTrigger={scroller ?? undefined}
        header="standard"
        footer="standard"
        minWidth={`${tableMinWidth}px`}
        scroller={scroller ?? undefined}
        onRowClick={rowClickAction}
        emptyTemplate={renderEmptyState()}
        getCustomRowClassName={getRowClassName}
        footerClassName={tableFooterClassName}
      />

      <Toast
        isOpen={restrictedAccessToast.isOpen}
        theme={Theme.DANGER}
        onClose={restrictedAccessToast.close}
        timeout={3000}
      >
        {t('Access restricted')}
      </Toast>
    </ContainerWithSpinner>
  );
};

export default DateActivityReportDetailsTable;
