import {
  Button,
  Form,
  IFormRender,
  Label,
  Loader,
  Row,
  TextareaFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Well } from 'components';
import { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import {
  IBulkEntryInitialState,
  MULTIPLE,
  TMultipleField,
} from 'components/domain/time-entry/bulk-edit/BulkEditEntryForm/BulkEditEntryForm';
import CostCodeTrashRow from 'components/domain/time-entry/bulk-edit/CostCodeTrashRow/CostCodeTrashRow';
import EquipmentTrashRow from 'components/domain/time-entry/bulk-edit/EquipmentTrashRow/EquipmentTrashRow';
import ProjectTrashRow from 'components/domain/time-entry/bulk-edit/ProjectTrashRow/ProjectTrashRow';
import {
  BulkEditActionType,
  bulkEditReducer,
  reducerBulkEditInitialState,
} from 'components/domain/time-entry/bulk-edit/reducers/reducers';
import { fieldIsSetValue } from 'components/domain/time-entry/bulk-edit/util/utils';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import FeatureTimeFormField from 'components/domain/time/FeatureTimeFormField/FeatureTimeFormField';
import { useActiveMember, useOpenable, useTimeRounding } from 'hooks';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useReducer, useRef, useState } from 'react';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import ClockAction from 'types/enum/ClockAction';
import { dateTimeFromISOKeepZone, isSameHourMinute } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { v_require, v_time_before_time } from 'utils/validations';
import TimeActionsValidations from '../TimeActionsValidations/TimeActionsValidations';
import useTimeActionsValidations from '../TimeActionsValidations/hooks/useTimeActionsValidations';
import './SwitchAtForm.scss';
import { convertTimeActionFormNoSelectionsToNull } from '../utils/utils';

export interface ISwitchAtFormProps {
  className?: ClassName;
  timeEntry?: ITimeEntry | null;
  timeEntries: ITimeEntry[];
  memberIds: string[];
  formData: ITimeActionsFormData;
  onSubmit: (data: ITimeActionsFormData) => void;
  onChange?: (formData: ITimeActionsFormData | undefined) => void;
}

const SwitchAtForm = (props: ISwitchAtFormProps) => {
  const { className, timeEntry, timeEntries, memberIds, formData, onSubmit, onChange } = props;

  const classes = classNames('switch-at-form', className);

  const activeMember = useActiveMember();
  const today = DateTime.local().startOf('day');
  const timeEntryRange = useRef<ITimeRange<DateTime> | undefined>();
  const { performSwitchAt, loaderDetails } = useTimeActionsForm();
  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const [formInfo, setFormInfo] = useState<ITimeActionsFormData>(formData);
  const lockDialogDetails = useOpenable();
  const conflictDialogDetails = useOpenable();
  const signatureViolationDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeActionsFormData>();
  const {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
  } = useTimeActionsValidations();
  const { roundTime } = useTimeRounding();

  useEffect(() => {
    inhibitsActions(formInfo);
    conflictWarning(
      { ...formInfo, timeRange: { startTime: formInfo.endTime, endTime: formInfo.timeRange.endTime } },
      timeEntry?.id ? [timeEntry.id] : timeEntries.map((t) => t.id)
    );
    signatureWarning(formInfo);
  }, [formInfo, memberIds]);

  const [projectState, projectDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [costCodeState, costCodeDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const [equipmentState, equipmentDispatch] = useReducer(bulkEditReducer, reducerBulkEditInitialState);
  const initialData = useRef<IBulkEntryInitialState>();

  useEffect(() => {
    function getNewValue(
      acc: IBulkEntryInitialState,
      newValue: string | null,
      fieldName: keyof IBulkEntryInitialState
    ): TMultipleField<string> {
      if (newValue) {
        return fieldIsSet(acc, fieldName) && acc[fieldName] === newValue ? (acc[fieldName] as string) : MULTIPLE;
      } else {
        return acc[fieldName] ? MULTIPLE : null;
      }
    }

    function getNewDateValue(
      acc: IBulkEntryInitialState,
      newValue: DateTime,
      fieldName: keyof IBulkEntryInitialState
    ): TMultipleField<DateTime> {
      return fieldIsSet(acc, fieldName) && isSameHourMinute(newValue, acc[fieldName] as DateTime)
        ? (acc[fieldName] as DateTime)
        : MULTIPLE;
    }

    const parsedData = timeEntries.reduce<IBulkEntryInitialState>((acc, entry, index) => {
      const offsetStart = dateTimeFromISOKeepZone(entry.startTime);
      const offsetEnd = dateTimeFromISOKeepZone(entry.endTime!);

      if (index === 0) {
        // If it's the first entry just inherit everything from the entry.
        acc.project = entry?.project?.id ?? null;
        acc.costCode = entry?.costCode?.id ?? null;
        acc.equipment = entry?.equipment?.id ?? null;
        acc.startTime = offsetStart;
        acc.endTime = offsetEnd.isValid ? offsetEnd : null;
        acc.description = entry.description ?? null;
        acc.breaks =
          entry.breaks
            ?.filter((b) => b.deletedOn === null)
            .map((entryBreak) => ({ ...entryBreak, compositeId: entryBreak.id })) ?? null;
      } else {
        acc.startTime = getNewDateValue(acc, offsetStart, 'startTime');
        acc.endTime = offsetEnd.isValid ? getNewDateValue(acc, offsetEnd, 'endTime') : null;

        // If it's not the first entry we need to check each value
        acc.project = getNewValue(acc, entry?.project?.id ?? null, 'project');
        acc.costCode = getNewValue(acc, entry?.costCode?.id ?? null, 'costCode');
        acc.equipment = getNewValue(acc, entry?.equipment?.id ?? null, 'equipment');
        acc.description = getNewValue(
          acc,
          entry?.description && !isEmpty(entry?.description) ? entry.description : null,
          'description'
        );
      }
      return acc;
    }, {} as IBulkEntryInitialState);

    initialData.current = parsedData;

    projectDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.project } });
    costCodeDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.costCode } });
    equipmentDispatch({ type: BulkEditActionType.SET_ORIGINAL, payload: { value: parsedData.equipment } });
  }, [timeEntries]);

  function fieldIsSet(parsedData: IBulkEntryInitialState, key: keyof IBulkEntryInitialState) {
    return parsedData[key] !== MULTIPLE && parsedData[key] !== null && parsedData[key];
  }

  function renderFormFields({ ...form }: IFormRender<ITimeActionsFormData>) {
    const isActiveMemberOnlyMember =
      form.state.data.members.length === 1 && form.state.data.members[0] === activeMember.id;

    return (
      <>
        <Label>{t('Switch Time')}</Label>
        <div className="date-pickers-container mr-4">
          <div className="mr-4">
            <FeatureTimeFormField
              name="endTime"
              clockAction={ClockAction.SWITCH}
              form={form}
              validations={[
                { validation: v_require },
                {
                  validation: v_time_before_time,
                  args: {
                    time: roundTime(DateTime.local(), ClockAction.SWITCH),
                    inclusive: true,
                    message: 'Invalid time',
                  },
                },
              ]}
            />
          </div>
        </div>

        <Label
          className="pt-5"
          secondaryLabel={
            projectState.clearable || costCodeState.clearable || equipmentState.clearable
              ? t('To clear a field, press the trash icon next to the field.')
              : null
          }
        >
          {t('Details')}
        </Label>

        <ProjectTrashRow
          form={form}
          bulkState={projectState}
          dispatch={projectDispatch}
          costCodeDispatch={costCodeDispatch}
          costCodeState={costCodeState}
        />

        <CostCodeTrashRow
          form={form}
          bulkState={costCodeState}
          dispatch={costCodeDispatch}
          projectState={projectState}
          projectDispatch={projectDispatch}
          projectId={form.state.data.project}
        />

        <EquipmentTrashRow
          form={form}
          bulkState={equipmentState}
          dispatch={equipmentDispatch}
          considerEquipmentRequirements={isActiveMemberOnlyMember}
        />

        <Label>{t('Description')}</Label>
        <Row className="trash-row">
          <TextareaFormField name="description" form={form} restrictTo={{ maxLength: 5000 }} />
        </Row>

        <Button type="primary" onClick={form.handleSubmit}>
          {t('Save')}
        </Button>
      </>
    );
  }

  async function handleSubmit(data: ITimeActionsFormData) {
    if (
      (checkValidationsPayload.current.lock && hasLockDate) ||
      (checkValidationsPayload.current.conflict && hasConflictingEntries) ||
      (checkValidationsPayload.current.signature && hasSignatureViolation)
    ) {
      preValidationFormData.current = data;
      launchValidationAlertsOrSave();
      return;
    }

    if (loaderDetails.isOpen) {
      // Action is already being performed.
      return;
    }

    loaderDetails.open();

    if (data) {
      switchAtFromForm(data);
    } else {
      onSubmit(data);
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onConflictContinue() {
    setHasConflictingEntries(false);
    checkValidationsPayload.current.conflict = false;
    conflictDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onSignatureViolationContinue() {
    setHasSignatureViolation(false);
    checkValidationsPayload.current.signature = false;
    signatureViolationDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function launchValidationAlertsOrSave() {
    // lock date

    if (checkValidationsPayload.current.lock && hasLockDate && preValidationFormData.current) {
      const start =
        preValidationFormData.current.startDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.startDate.value!);
      const end =
        preValidationFormData.current.endDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.endDate.value!);
      timeEntryRange.current = {
        startTime: preValidationFormData.current.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        endTime: preValidationFormData.current.timeRange.endTime!.set({
          year: end.year,
          month: end.month,
          day: end.day,
        }),
      };
      lockDialogDetails.open();
      return;
    }

    // conflicts alert

    if (checkValidationsPayload.current.conflict && hasConflictingEntries) {
      conflictDialogDetails.open();
      return;
    }

    // signatures alert

    if (checkValidationsPayload.current.signature && hasSignatureViolation) {
      signatureViolationDialogDetails.open();
      return;
    }
  }

  async function switchAtFromForm(data: ITimeActionsFormData) {
    const newData: ITimeActionsFormData = convertTimeActionFormNoSelectionsToNull({
      ...data,
      project: projectState.current !== MULTIPLE ? (projectState.current as string) : undefined,
      costCode: costCodeState.current !== MULTIPLE ? (costCodeState.current as string) : undefined,
      equipment: equipmentState.current !== MULTIPLE ? (equipmentState.current as string) : undefined,
    });
    if (timeEntry) {
      await performSwitchAt(newData, [timeEntry], onSubmit);
    } else if (timeEntries.length > 0) {
      await performSwitchAt(newData, timeEntries, onSubmit);
    } else {
      onSubmit(newData);
    }
  }

  function onFormChange(changedData: ITimeActionsFormData | undefined) {
    if (changedData) {
      setFormInfo(changedData);
    }

    onChange?.(changedData);
  }

  return (
    <>
      <div>
        <div className="px-8 pb-8">
          <div className="mb-6">
            {timeEntries.length > 1 && (
              <Well theme={Theme.PRIMARY} className="mb-5">
                {t(
                  'If a field is left blank on the form below, that field will not be changed and will retain whatever value it had before the edit.'
                )}
              </Well>
            )}
            <Form
              data={
                {
                  ...formInfo,
                  project: fieldIsSetValue(projectState.current) ? projectState.current : null,
                  costCode: fieldIsSetValue(costCodeState.current) ? costCodeState.current : null,
                  equipment: fieldIsSetValue(equipmentState.current) ? equipmentState.current : null,
                } as ITimeActionsFormData
              }
              onChange={onFormChange}
              onSubmit={handleSubmit}
              render={renderFormFields}
              className={classes}
              allowMultipleSubmissions={true}
            />
            <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
              {errorToastMessage.current}
            </Toast>
          </div>
        </div>
      </div>
      <Loader isOpen={loaderDetails.isOpen} />
      <TimeActionsValidations
        lockDate={timeEntryRange.current ? timeEntryRange.current.startTime : undefined}
        lockMemberIds={formInfo.members}
        lockDateType={LockDateDialogType.ADD}
        isLockDetailsOpen={lockDialogDetails.isOpen}
        onLockDetailsClose={lockDialogDetails.close}
        onMoveLockDate={onMoveLockDate}
        isConflictDetailsOpen={conflictDialogDetails.isOpen}
        onConflictDetailsClose={conflictDialogDetails.close}
        onConflictContinue={onConflictContinue}
        isSignatureViolationDetailsOpen={signatureViolationDialogDetails.isOpen}
        onSignatureViolationDetailsClose={signatureViolationDialogDetails.close}
        onSignatureViolationContinue={onSignatureViolationContinue}
      />
    </>
  );
};

SwitchAtForm.defaultProps = {
  timeEntry: null,
  timeEntries: [],
  memberIds: [],
};

export default SwitchAtForm;
