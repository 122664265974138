import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import * as React from 'react';
import { CSSProperties, ReactNode } from 'react';
import './Typography.scss';

export interface ITypographyProps {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div' | 'label';
  color: 'default' | 'primary' | 'tertiary' | 'error' | 'light-gray' | 'medium-gray' | string;
  fontSize?: CSSProperties['fontSize'];
  textTransform?: CSSProperties['textTransform'];
  textAlign?: CSSProperties['textAlign'];
  textDecoration?: CSSProperties['textDecoration'];
  fontWeight: keyof typeof fontWeightMap;
  selectable: boolean;
  lineHeight?: CSSProperties['lineHeight'];
  onClick?: (event: React.MouseEvent) => any;
  children: ReactNode;
  className?: ClassName;
}

const fontWeightMap = {
  thin: 100,
  extraLight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
  extraBlack: 950,
};

const presetColor = new Set(['default', 'primary', 'tertiary', 'error', 'light-gray', 'medium-gray']);

function Typography({ color, children, tag, onClick, className, selectable, fontWeight, ...rest }: ITypographyProps) {
  const classes = classNames(
    'typography',
    {
      'clickable': onClick,
      'no-text-select': !selectable,
      [color]: presetColor.has(color),
    },
    className
  );

  const style: CSSProperties = {
    fontWeight: fontWeightMap[fontWeight],
    color: !presetColor.has(color) ? color : undefined,
    ...rest,
  };

  const Tag = tag;

  return (
    <Tag className={classes} onClick={onClick} style={style}>
      {children}
    </Tag>
  );
}

Typography.defaultProps = {
  color: 'default',
  selectable: true,
  fontWeight: 500,
};

export default Typography;
