import { Button, ClassName, Label, Textarea, Theme } from '@busybusy/webapp-react-ui';
import { MediaEntryComment, MediaEntryCommentCreateInput } from '__generated__/graphql';
import classNames from 'classnames';
import { EmptyState, HeaderDialog } from 'components';
import DetailHeaderWithText from 'components/foundation/DetailHeaderWithText/DetailHeaderWithText';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useCreateMediaEntryComment } from 'containers/photos/hooks/useCreateMediaEntryComment';
import { useMediaEntryComments } from 'containers/photos/hooks/useMediaEntryComments';
import useUpdateMediaEntryComment from 'containers/photos/hooks/useUpdateMediaEntryComment';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable } from 'hooks';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { dateTimeFromUtcISO, isoTimeStampUtc } from 'utils/dateUtils';
import { getFullNameFromMember } from 'utils/stringUtils';
import PhotoCommentView from '../PhotoCommentView/PhotoCommentView';
import './PhotoCommentBar.scss';

export interface IPhotoCommentBarProps {
  className?: ClassName;
  mediaEntryId: string;
}

const PhotoCommentBar = (props: IPhotoCommentBarProps) => {
  const { className, mediaEntryId } = props;
  const getComments = useMediaEntryComments(mediaEntryId);
  const createComment = useCreateMediaEntryComment();
  const toast = useToastOpen();
  const deleteCommentDialog = useOpenable();
  const editCommentDialog = useOpenable();
  const commentDetailsDialog = useOpenable();
  const { editMediaEntryComment, deleteMediaEntryComment } = useUpdateMediaEntryComment();

  const [comments, setComments] = useState<MediaEntryComment[]>([]);
  const [newComment, setNewComment] = useState<string>('');
  const [editComment, setEditComment] = useState<string>('');
  const [commentIdToDelete, setCommentIdToDelete] = useState('');
  const [commentToEdit, setCommentToEdit] = useState<MediaEntryComment>();
  const [selectedComment, setSelectedComment] = useState<MediaEntryComment>();

  useEffect(() => {
    getCommentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaEntryId]);

  const getCommentData = async () => {
    setComments(await getComments());
  };

  const resetValues = () => {
    setNewComment('');
    setEditComment('');
    setCommentIdToDelete('');
    setCommentToEdit(undefined);
  };

  const handleCommentSubmit = async () => {
    if (!isEmpty(newComment)) {
      const commentInput: MediaEntryCommentCreateInput = {
        createdOn: isoTimeStampUtc(),
        comment: newComment,
        mediaEntryId,
      };
      createComment(commentInput).then((result) => {
        if (result?.createdOn) {
          resetValues();
          getCommentData();
        }
      });
    } else {
      toast({ label: t('Comment cannot be blank'), theme: Theme.DANGER });
    }
  };

  const handleCommentDeleteClick = (commentId: string) => {
    setCommentIdToDelete(commentId);
    deleteCommentDialog.open();
  };

  const handleCommentDelete = () => {
    deleteMediaEntryComment({ id: commentIdToDelete }).then(() => {
      deleteCommentDialog.close();
      resetValues();
      getCommentData();
    });
  };

  const handleEditClick = (comment: MediaEntryComment) => {
    setCommentToEdit(comment);
    editCommentDialog.open();
    setEditComment(comment.comment!);
  };

  const handleCommentEdit = () => {
    editMediaEntryComment({ id: commentToEdit!.id, comment: editComment }).then(() => {
      editCommentDialog.close();
      resetValues();
      getCommentData();
    });
  };

  const handleViewClick = (comment: MediaEntryComment) => {
    setSelectedComment(comment);
    commentDetailsDialog.open();
  };

  const renderCommentCreator = () => (
    <div className="add-comment-section p-3">
      <Textarea
        restrictTo={{ maxLength: 2000 }}
        value={newComment}
        onChange={setNewComment}
        placeholder={t('Add Comment')}
      />
      <Button
        type="primary"
        fullWidth={true}
        className={'mt-5'}
        onClick={(event) => {
          handleCommentSubmit();
          const buttonElement = event.currentTarget as HTMLButtonElement;
          buttonElement.blur();
        }}
      >
        {t('Submit')}
      </Button>
    </div>
  );

  const classes = classNames('photo-comment-bar', className);

  return (
    <div className={classes}>
      <div>
        <h3 className="m-3">{t('Comments')}</h3>
        <div className="comments-container">
          {comments.length > 0 ? (
            <>
              {comments.map((comment) => (
                <PhotoCommentView
                  key={comment.id}
                  comment={comment}
                  onDelete={handleCommentDeleteClick}
                  onEdit={handleEditClick}
                  onViewClick={handleViewClick}
                />
              ))}
            </>
          ) : (
            <EmptyState title={t('No Comments')} graphic={<></>} />
          )}
        </div>
      </div>
      {renderCommentCreator()}
      <DeleteConfirmationDialog
        isOpen={deleteCommentDialog.isOpen}
        onClose={deleteCommentDialog.close}
        onDelete={handleCommentDelete}
        showHeaderDivider={false}
        title={t('Delete comment?')}
      >
        {t('if you delete this item it will be gone forever. Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
      {commentToEdit && (
        <HeaderDialog title={t('Edit Comment')} isOpen={editCommentDialog.isOpen} onClose={editCommentDialog.close}>
          <div className="p-4">
            <Label>{t('Comment')}</Label>
            <Textarea value={editComment} onChange={setEditComment} restrictTo={{ maxLength: 2000 }} />
            <div className="my-5">
              <Button type="primary" onClick={handleCommentEdit} className={'mr-5'}>
                {t('Submit')}
              </Button>
            </div>
          </div>
        </HeaderDialog>
      )}
      {selectedComment && (
        <HeaderDialog
          title={t('Comment Details')}
          isOpen={commentDetailsDialog.isOpen}
          onClose={commentDetailsDialog.close}
          divider={false}
        >
          <div className="py-4 px-8">
            <DetailHeaderWithText
              title={t('Created By')}
              value={
                <>
                  {getFullNameFromMember(selectedComment.createdByMember)}
                  <br />
                  {dateTimeFromUtcISO(selectedComment.createdOn).toLocal().toFormat('LLL dd, yyyy @ hh:mm a')}
                </>
              }
            />
            {selectedComment.updatedBy && (
              <DetailHeaderWithText
                title={t('Last Edited By')}
                value={
                  <>
                    {getFullNameFromMember(selectedComment.updatedByMember)}
                    <br />
                    {dateTimeFromUtcISO(selectedComment.updatedOn).toLocal().toFormat('LLL dd, yyyy @ hh:mm a')}
                  </>
                }
              />
            )}
          </div>
        </HeaderDialog>
      )}
    </div>
  );
};

export default PhotoCommentBar;
