import { ClassName } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { IMediaCollection, MediaEntryType } from 'types';
import { getFullNameFromMember } from 'utils/stringUtils';
import DetailsReadOnly, { IDetailsReadOnlyRow } from '../../../components/foundation/DetailsReadOnly/DetailsReadOnly';
import { localizedDateAtTimeString } from '../../../utils/dateUtils';
import { descriptionWithFallBack, projectPathWithFallBack } from '../../../utils/defaultMissingSelectors';
import './PhotoDetailsReadOnly.scss';

/*
  A view for displaying the details of a specific media entry that also includes a few items
  from the collection. Based on the DetailsReadOnly component.
*/
export interface IPhotoDetailsReadOnlyProps {
  isOpen: boolean;
  photoTaken: number;
  uploadedBy: string;
  date: number;
  type: MediaEntryType;
  projectPath?: string;
  description?: string;
  lastMarkUpBy?: string;
  lastMarkUpOn?: number;
  mediaCollection?: IMediaCollection;
  onClose?: () => void;
  layer?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  className?: ClassName;
}

const PhotoDetailsReadOnly: React.FunctionComponent<IPhotoDetailsReadOnlyProps> = ({
  isOpen,
  photoTaken,
  uploadedBy,
  date,
  projectPath,
  description,
  lastMarkUpBy,
  lastMarkUpOn,
  mediaCollection,
  onClose,
  layer,
  className,
  type,
}) => {
  const isVideo = type === MediaEntryType.VIDEO;

  const renderEntryUploadedBy = () => (
    <>
      {uploadedBy}
      <br />
      {localizedDateAtTimeString(date, 'utc')}
    </>
  );

  const renderLastMarkUpBy = () => (
    <>
      {lastMarkUpBy && !isNil(lastMarkUpOn) ? (
        <>
          {lastMarkUpBy}
          <br /> {localizedDateAtTimeString(lastMarkUpOn, 'utc')}
        </>
      ) : (
        '---'
      )}
    </>
  );

  const renderCollectionUploadedBy = () => (
    <>
      {mediaCollection ? (
        <>
          {getFullNameFromMember(mediaCollection.createdByMember)}
          <br />
          {localizedDateAtTimeString(DateTime.fromISO(mediaCollection.createdOn, { zone: 'utc' }).toSeconds(), 'utc')}
        </>
      ) : (
        '---'
      )}
    </>
  );

  const renderLastEditedBy = () => (
    <>
      {mediaCollection && mediaCollection.updatedByMember && mediaCollection.updatedOn ? (
        <>
          {getFullNameFromMember(mediaCollection.updatedByMember)}
          <br />
          {localizedDateAtTimeString(DateTime.fromISO(mediaCollection.updatedOn, { zone: 'utc' }).toSeconds(), 'local')}
        </>
      ) : (
        '---'
      )}
    </>
  );

  const renderPhotosRows = () => {
    const rows: IDetailsReadOnlyRow[] = [
      { title: t('Captured on'), detail: localizedDateAtTimeString(photoTaken, 'utc') },
      { title: t('Uploaded by'), detail: renderEntryUploadedBy() },
      { title: isVideo ? t('Video Last Edited By') : t('Last Mark-Up by'), detail: renderLastMarkUpBy() },
    ];
    return rows;
  };

  const renderTags = () => (
    <>
      {mediaCollection &&
        mediaCollection.tagLinks.map((tagLink, index) => (
          <span key={index} className="tag-view mr-2 mb-2 px-2 py-1">
            {tagLink.tag.title}
          </span>
        ))}
    </>
  );

  const classes = classNames('photo-details-read-only', className);

  return (
    <DetailsReadOnly
      className={classes}
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${isVideo ? 'Video' : 'Photo'} Details`)}
      layer={layer}
      data={[
        {
          title: isVideo ? t('Video') : t('Photo'),
          rows: renderPhotosRows(),
        },
        {
          title: t('Collection'),
          rows: [
            { title: t('Uploaded by'), detail: renderCollectionUploadedBy() },
            { title: t('Last Edited by'), detail: renderLastEditedBy() },
            { title: t('Project'), detail: projectPathWithFallBack(projectPath) },
            { title: t('Tags'), detail: renderTags() },
            { title: t('Description'), detail: descriptionWithFallBack(description) },
          ],
        },
      ]}
    />
  );
};

export default PhotoDetailsReadOnly;
