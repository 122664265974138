import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import CookieConsentBanner from 'components/foundation/CookieConsentBanner/CookieConsentBanner';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IReduxState } from 'store/reducers';
import { IChildrenProps } from 'types/ChildrenProps';
import { useFeatureFlags } from 'utils/features';
import { Main } from '../../../components';
import useIsMobile from '../navigation-drawer/hooks/useIsMobile';
import useNavSettings from '../navigation-drawer/hooks/useNavSettings';
import MinNavigation from '../navigation-drawer/MinNavigation/MinNavigation';
import Navigation from '../navigation-drawer/Navigation/Navigation';
import { useReduxSelector } from 'hooks';

const PrimaryLayout = ({ children }: IChildrenProps) => {
  const isAuthenticated = useSelector<IReduxState, boolean>((state) => state.session.isAuthenticated);
  const sideNavStatus = useReduxSelector((state) => state.navigationBar.status);
  const reactIsMainApp = useFeatureFlags('REACT_MAIN_APP');
  const dashboardIsEnabled = useFeatureFlags('DASHBOARD');
  const navigationBarIsVisible = reactIsMainApp && isAuthenticated && dashboardIsEnabled;
  const { collapsedSideNav, setCollapsedSideNav } = useNavSettings();
  const location = useLocation();
  const isSubscriptionsRoute = location.pathname === '/settings/subscription';

  const isMobile = useIsMobile();

  const forceHideSideNav = sideNavStatus === 'hidden';
  const forceCollapseSideNav = sideNavStatus === 'collapsed';
  const forceExpandSideNav = sideNavStatus === 'expanded';
  const showCollapsedSideNav = (forceCollapseSideNav || collapsedSideNav) && !forceExpandSideNav;

  const showSideNav = !(isMobile && isSubscriptionsRoute) && !forceHideSideNav;

  return (
    <Main>
      <CookieConsentBanner />
      <ConditionalContainer condition={showSideNav}>
        {navigationBarIsVisible && showCollapsedSideNav ? (
          <MinNavigation
            open={true}
            toggleMenu={() => {
              setCollapsedSideNav(!collapsedSideNav);
            }}
          />
        ) : (
          <Navigation
            open={true}
            toggleMenu={() => {
              setCollapsedSideNav(!collapsedSideNav);
            }}
          />
        )}
      </ConditionalContainer>
      {children}
    </Main>
  );
};

export default PrimaryLayout;
