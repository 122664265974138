import { toNumber } from 'lodash';
import { t } from 'utils/localize';

export default function v_numbers_only(value: string, args: { message?: string }) {
  return {
    message: args.message || t('Must only contain numbers'),
    validate: () => {
      if (!value) {
        return true;
      }

      const number = toNumber(value);
      return number === 0 || !!number;
    },
  };
}
