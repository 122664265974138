import { Card } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { IChildrenProps } from 'types/ChildrenProps';
import { ClassName } from 'types/ClassName';
import './DetailsCard.scss';

interface IDetailsCardProps extends IChildrenProps {
  title?: string;
  className?: ClassName;
}

const DetailsCard = ({ title, className, children }: IDetailsCardProps) => {
  const classes = classNames('details-card', className);

  return (
    <div className={classes}>
      {title && <h3 className="pb-3">{title}</h3>}
      <Card type="stroke">{children}</Card>
    </div>
  );
};

export default DetailsCard;
