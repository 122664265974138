import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { times, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import { MonthNumber } from 'types/Date';
import BasicNonNullPicker, { IBasicNonNullPickerProps } from '../basic-picker/BasicNonNullPicker/BasicNonNullPicker';

export interface IMonthNonNullPickerProps extends Omit<IBasicNonNullPickerProps, 'data' | 'onSelect' | 'value'> {
  /* 1 January 12 December*/
  value: MonthNumber;
  onSelect: (n: MonthNumber) => void;
}

function MonthNonNullPicker({ value, onSelect, className, ...rest }: IMonthNonNullPickerProps) {
  const pickerOptions = useRef(
    times(12).map((n) => ({
      id: (n + 1).toString(),
      name: DateTime.fromObject({ month: n + 1 }).toFormat('LLLL'),
    }))
  );

  function onPickerSelect(n: string) {
    onSelect(toNumber(n) as MonthNumber);
  }

  const classes = classNames('month-non-null-picker', className);

  return (
    <BasicNonNullPicker
      className={classes}
      data={pickerOptions.current}
      onSelect={onPickerSelect}
      value={value.toString()}
      {...rest}
    />
  );
}

export default MonthNonNullPicker;
