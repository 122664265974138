import { Button, ClassName, Label, Row, Theme } from "@busybusy/webapp-react-ui";
import classNames from "classnames";
import ConditionalContainer from "components/foundation/ConditionalContainer/ConditionalContainer";
import useSlackSafetyReportsAccess from "containers/settings/integrations/vendors/slack/hooks/useSlackSafetyReportsAccess";
import { useToastDispatch } from "contexts/ToastContext";
import useOrganizationUiSettingsUpdate from "hooks/models/organization/settings/hooks/useOrganizationUiSettingsUpdate";
import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import './SafetyReportsEnableForm.scss';

export interface ISafetyReportsEnableFormProps {
    onRequestSubmitted: () => void;
    className?: ClassName;
}

export const SafetyReportsEnableForm: FunctionComponent<ISafetyReportsEnableFormProps> = (props) => {
    const { className, onRequestSubmitted } = props;
    const toastDispatch = useToastDispatch();
    const [showErrorTip, setShowErrorTip] = useState(false);
    const safetyReportsAccess = useSlackSafetyReportsAccess();
    const updateUiSettings = useOrganizationUiSettingsUpdate();

    const classes = classNames('safety-reports-enable-form', className);

    const onLinkClicked = () => {
        window.open('https://busybusy.wistia.com/medias/65v76rdlom');
    };

    const requestAccess = async () => {
        let requestSuccessful = false;

        try {
            const response = await safetyReportsAccess();
            requestSuccessful = response.status === 200;
        } catch (e) {
            requestSuccessful = false;
        }

        if (requestSuccessful) {
            updateUiSettings('requestedSafetyReportsAccess', true);
            onRequestSubmitted();
        } else {
            toastDispatch({
            type: 'OPEN',
            payload: { label: t('Failed to send request. Try again later.'), theme: Theme.DANGER },
            });
            setShowErrorTip(true);
        }
    };

    return (
        <div className={classes}>
            <Label className="mt-3 mb-2">{t('-Now Includes-')}</Label>
            <ul className="pl-4">
                <li>{t('Incident Reporting')}</li>
                <li>{t('Toolbox Talks')}</li>
            </ul>

            <Label className="mt-7 mb-2">{t('-Coming Soon-')}</Label>
            <ul className="pl-4">
                <li>{t('Observations')}</li>
                <li>{t('Inspections')}</li>
                <li>{t('JHAs/JSAs')}</li>
            </ul>

          <Label className="mt-7 mb-2">{t('How to Get Started')}</Label>
            <span className="content">
                {t(
                `Click the button below and a friendly busybusy team member will reach out to you within one business day to confirm that you would like the Safety feature turned on. You will have free access to the Safety suite of features as they become available, until October 2024. At that point, we will reach out to you to see how these features have been working for you. If it has been a success and you have found them helpful, you can decide to start paying for it in October. If you do not want to continue using the Safety features, we will turn it off for you with no charges incurred. Want to learn more?`
                )}
                <a className="link ml-1" onClick={() => onLinkClicked()}>
                {t('Click here.')}
                </a>
            </span>
    
          <Label className="mt-7 mb-2">{t('Pricing')}</Label>
          {t(`$0 - Use Safety Completely Free until October 2024.`)}
    
          <Row className="mt-9">
            <Button type="primary" onClick={requestAccess}>
              {t('Contact')}
            </Button>
          </Row>
    
          <ConditionalContainer condition={showErrorTip}>
            <br />
            {t('* If you have an ad blocker, disable it and try again.')}
          </ConditionalContainer>
        </div>
      );
}

export default SafetyReportsEnableForm;