import { IBasicPickerValue } from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { castArray, sortBy } from 'lodash';
import { useMemo } from 'react';
import { TPermissions } from 'utils/positionUtils';
import useManageablePermissions from './useManageablePermissions';

export default function usePositionPickerOptions(permissions?: TPermissions | TPermissions[]) {
  const { getManageablePermissions, error } = useManageablePermissions();
  const options: IBasicPickerValue[] = useMemo(() => {
    const permissionArray = permissions ? castArray(permissions) : undefined;
    const positions = getManageablePermissions(permissionArray);
    const mapped = positions.map((position) => ({ id: position.id, name: position.title }));
    return sortBy(mapped, 'title');
  }, [getManageablePermissions, permissions]);

  return { data: options, error: error ? true : false };
}
