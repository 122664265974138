import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IVisibleTableColumn } from 'types/TableColumn';
import { t } from 'utils/localize';

export interface IStoreDocumentReport {
  tableColumns: IVisibleTableColumn[];
}

export const defaultDocumentReportColumns: IVisibleTableColumn[] = [
  {
    key: 'uploaded',
    title: t('Uploaded'),
    visible: true,
  },
  {
    key: 'project',
    title: t('Project'),
    visible: true,
  },
  {
    key: 'description',
    title: t('Description'),
    visible: true,
  },
  {
    key: 'uploadedBy',
    title: t('Uploaded By'),
    visible: true,
  },
  {
    key: 'lastEdited',
    title: t('Last Edited'),
    visible: true,
  },
  {
    key: 'access',
    title: t('Access'),
    visible: true,
  },
  {
    key: 'folder',
    title: t('Folder'),
    visible: true,
  },
  {
    key: 'tags',
    title: t('Tags'),
    visible: true,
    position: 8,
  },
];

export const initialState: IStoreDocumentReport = {
  tableColumns: [],
};

export const slice = createSlice({
  name: 'documentReport',
  initialState,
  reducers: {
    updateDocumentReportTableColumns: (state: IStoreDocumentReport, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.tableColumns = action.payload;

      return state;
    },
  },
});

export const { updateDocumentReportTableColumns } = slice.actions;
export default slice.reducer;
