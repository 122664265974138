import { ClassName, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import Well from 'components/foundation/Well/Well';
import { t } from 'i18next';

export interface ISecretMenuWarningWellProps {
  className?: ClassName;
}

const SecretMenuWarningWell = (props: ISecretMenuWarningWellProps) => {
  const { className } = props;

  const classes = classNames('secret-menu-warning-well', className);

  return (
    <Well theme={Theme.DANGER} className={'mb-5'}>
      {t(
        "WARNING: Editing these settings may cause adverse effects in the application. If you aren't Donna, I hope you know what you're doing!"
      )}
    </Well>
  );
};

export default SecretMenuWarningWell;
