import { isNil } from 'lodash';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';

export const LoaderContext = createContext<[ILoaderState, React.Dispatch<Action>] | null>(null);

export function useLoaderState() {
  const context = useContext(LoaderContext);

  if (isNil(context)) {
    throw new Error('useLoader must be nested within a LoaderContext.Provider.');
  }

  return context;
}

type Action = { type: 'OPEN'; payload: { label: string } } | { type: 'CLOSE' };

export function useLoader() {
  const [{ label: currentLabel }, dispatch] = useLoaderState();

  return useMemo(
    () => ({
      open: (label: string) => {
        dispatch({ type: 'OPEN', payload: { label } });
      },
      close: () => {
        dispatch({ type: 'CLOSE' });
      },
    }),
    [dispatch, currentLabel]
  );
}

function loaderReducer(state: ILoaderState, action: Action): ILoaderState {
  if (action.type === 'OPEN') {
    return { isOpen: true, label: action.payload.label };
  } else if (action.type === 'CLOSE') {
    return { ...state, isOpen: false };
  }

  return state;
}

interface ILoaderState {
  isOpen: boolean;
  label: string;
}

export function LoaderContextProvider(props: IChildrenProps) {
  const reducer = useReducer(loaderReducer, { isOpen: false, label: '' });

  return <LoaderContext.Provider value={reducer}>{props.children}</LoaderContext.Provider>;
}
