import { Button, IButtonProps, Icon, IconSvg, IIconProps, Size, Tooltip } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";

export interface IIconButtonProps extends Omit<IButtonProps, 'tag' | 'type'> {
  tooltipLabel?: string;
  buttonSize: Size;
  iconTheme?: IIconProps['theme'];
  svg: IconSvg;
}

const IconButton = ({
  className,
  svg,
  size,
  buttonSize,
  tooltipLabel,
  iconTheme,
  ...buttonProps
}: IIconButtonProps) => {
  function renderButton() {
    return (
      <Button className={classNames('icon-button', className)} type={'icon'} size={buttonSize} {...buttonProps}>
        <Icon svg={svg} size={size} theme={iconTheme} />
      </Button>
    );
  }

  return <>{tooltipLabel ? <Tooltip label={tooltipLabel}>{renderButton()}</Tooltip> : renderButton()}</>;
};

IconButton.defaultProps = {
  size: Size.MEDIUM,
  buttonSize: Size.MEDIUM,
  value: '',
  rounded: false,
  shadow: false,
  fullWidth: false,
  disabled: false,
  ripple: true,
  tabIndex: 0,
};

export default IconButton;
