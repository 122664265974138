import gql from 'graphql-tag';

export const CREATE_SAFETY_SIGNATURES = gql`
  mutation CreateSafetySignature($safetySignature: SafetySignatureCreateInput) {
    createSafetySignature(safetySignature: $safetySignature) {
      id
    }
  }
`;

export const UPDATE_SAFETY_SIGNATURES = gql`
  mutation UpdateSafetySignature($safetySignature: SafetySignatureUpdateInput) {
    updateSafetySignature(safetySignature: $safetySignature) {
      id
    }
  }
`;
