import { DateTime } from 'luxon';
import { IVisibleTableColumn } from 'types/TableColumn';
import TimeRangeType from 'types/TimeRangeType';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { t } from 'utils/localize';
import { TimeLogColumnKey, TimeLogSidePanelSettings } from '../types/types';

export const TIME_LOG_FEED_TIME_FORMAT = DateTimeFormat.timeSimple;
export const TIME_LOG_FEED_DATE_FORMAT = DateTimeFormat.weekDayMonthDay;
export const TIME_LOG_FEED_COLUMN_LOCAL_STORAGE_KEY = 'time-log-feed-column-settings';
export const TIME_LOG_FEED_TABLE_ROW_SETTING_KEY = 'time-log-feed-table-row-setting-key';
export const TIME_LOG_FEED_MAXIMUM_VIEWABLE_DATE = DateTime.local().endOf('day');
export const TIME_LOG_FEED_SIDE_PANEL_SETTINGS_KEY = 'time-log-feed-side-panel-settings';

export const timeLogActionColumn: IVisibleTableColumn<TimeLogColumnKey> = {
  key: 'action',
  title: 'action',
  visible: true,
};

export const defaultTimeLogColumns: IVisibleTableColumn<TimeLogColumnKey>[] = [
  { key: 'performed', title: 'performed', visible: true },
  { key: 'employeeName', title: 'employeeName', visible: true },
  { key: 'details', title: 'details', visible: true },
  { key: 'performerName', title: 'performerName', visible: true },
  { key: 'location', title: 'location', visible: true },
  { key: 'device', title: 'device', visible: true },
];

export const timeLogColumnKeyLabelLookup: Record<TimeLogColumnKey, string> = {
  action: t('Action'),
  performed: t('Performed'),
  employeeName: t('Employee'),
  details: t('Details'),
  performerName: t('Performed By'),
  location: t('GPS'),
  device: t('Device'),
};

export const defaultTimeLogSidePanelSettings: TimeLogSidePanelSettings = {
  timeRangeType: TimeRangeType.ALL_TIME,
  timeRange: null,
};
