import { IOpenableHook } from 'hooks/utils/useOpenable';
import { ReactNode } from 'react';
import { Nullable } from 'types/util/Nullable';
import DateTimeFormat from './constants/dateTimeFormat';
import { localizeDateTimeFromUtcISO } from './dateUtils';
import { t } from './localize';

export function getCreatedOnTableCell<T extends { createdOn: string }>(
  render: (isoString: string) => ReactNode = (isoString) => isoString
) {
  return {
    key: 'createdOn',
    title: t('Created On'),
    sort: true,
    visibleByDefault: false,
    cell: (model: T) => render(localizeDateTimeFromUtcISO(model.createdOn).toFormat(DateTimeFormat.dateAtTime)),
  };
}

export function getUpdatedOnTableCell<T extends { updatedOn: Nullable<string> }>(
  render: (isoString: Nullable<string>) => ReactNode = (isoString) => isoString
) {
  return {
    key: 'updatedOn',
    title: t('Last Edited'),
    sort: true,
    visibleByDefault: false,
    cell: (model: T) =>
      model.updatedOn ? render(localizeDateTimeFromUtcISO(model.updatedOn).toFormat(DateTimeFormat.dateAtTime)) : null,
  };
}

export function convertOpenableToDialogProps(openable: IOpenableHook) {
  return {
    isOpen: openable.isOpen,
    onClose: openable.close,
  };
}
