import {
  Button,
  Form,
  Icon,
  IFormRender,
  Label,
  Loader,
  TextareaFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import { ChevronRightIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Well } from 'components';
import SignInQuestionForm from 'components/domain/member-group/SignInQuestionForm/SignInQuestionForm';
import { LockDateDialogType } from 'components/domain/member-lock/LockDateDialog/LockDateDialog';
import EmployeeMultiPickerFormField from 'components/domain/member/EmployeeMultiPickerFormField/EmployeeMultiPickerFormField';
import useSignInAnswerCheck from 'components/domain/time-entry/time-actions-form/ClockInForm/hooks/useSignInAnswerCheck';
import useTimeActionsForm, {
  ITimeActionsFormData,
} from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import ClockInRestrictionBanner from 'components/domain/time/ClockInRestrictionBanner/ClockInRestrictionBanner';
import { useActiveMember, useOpenable } from 'hooks';
import useClockInRestricted, {
  ClockInRestrictionReasonPayloadOptions,
} from 'hooks/models/position/useClockInRestricted';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import _, { isEqual, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import ITimeRange from 'types/TimeRange';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import TimeActionFormDetailsSection from '../section/TimeActionFormDetailsSection/TimeActionFormDetailsSection';
import useTimeActionsValidations from '../TimeActionsValidations/hooks/useTimeActionsValidations';
import TimeActionsValidations from '../TimeActionsValidations/TimeActionsValidations';
import { convertTimeActionFormNoSelectionsToNull } from '../utils/utils';
import './ClockInForm.scss';

export interface IClockInFormProps {
  className?: ClassName;
  memberIds: string[];
  formData: ITimeActionsFormData;
  onSubmit: (data: ITimeActionsFormData) => void;
  onChange?: (formData: ITimeActionsFormData | undefined) => void;
}

const ClockInForm = (props: IClockInFormProps) => {
  const { className, memberIds, formData, onSubmit, onChange } = props;

  const classes = classNames('clock-in-form', className);

  const today = DateTime.local().startOf('day');
  const activeMember = useActiveMember();
  const timeEntryRange = useRef<ITimeRange<DateTime> | undefined>();
  const { performClockIn, loaderDetails } = useTimeActionsForm();
  const errorToastDetails = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const [formInfo, setFormInfo] = useState<ITimeActionsFormData>(formData);
  const [clockInRestricted, setClockInRestricted] = useState<ClockInRestrictionReasonPayloadOptions | null>();
  const { setSubmissionId, answerCheck, setMemberIds } = useSignInAnswerCheck();
  const signInQuestionDialog = useOpenable();
  const lockDialogDetails = useOpenable();
  const conflictDialogDetails = useOpenable();
  const gpsRestrictedDialog = useOpenable();
  const signatureViolationDialogDetails = useOpenable();
  const preValidationFormData = useRef<ITimeActionsFormData>();
  const checkClockInRestricted = useClockInRestricted();
  const [signInQuestionWarningText, setSignInQuestionWarningText] = useState<string | null>(null);

  const {
    hasLockDate,
    setHasLockDate,
    hasConflictingEntries,
    setHasConflictingEntries,
    hasSignatureViolation,
    setHasSignatureViolation,
    checkValidationsPayload,
    inhibitsActions,
    conflictWarning,
    signatureWarning,
    gpsRestriction,
    hasGpsRestriction,
  } = useTimeActionsValidations();

  useOnMount(() => {
    setMemberIds(memberIds);
  });

  useEffect(() => {
    const selfClockIn = (formInfo.members ?? []).some((otherMemberId) => otherMemberId === activeMember.id);
    if (selfClockIn) {
      checkClockInRestricted().then((res) => {
        if (!isEqual(res, clockInRestricted)) {
          setClockInRestricted(res);
        }
      });
    } else {
      if (!isEqual(clockInRestricted, { reason: null, futureAllowance: null })) {
        setClockInRestricted({ reason: null, futureAllowance: null });
      }
    }
  }, [formInfo.members, activeMember.id, errorToastDetails.open, checkClockInRestricted, clockInRestricted]);

  useEffect(() => {
    inhibitsActions(formInfo);
    conflictWarning(formInfo);
    signatureWarning(formInfo);
    gpsRestriction(formInfo);
  }, [formInfo, memberIds]);

  function renderFormFields(form: IFormRender<ITimeActionsFormData>) {
    const isActiveMemberOnlyMember =
      form.state.data.members.length === 1 && form.state.data.members[0] === activeMember.id;

    return (
      <div>
        {renderClockInValidationBanner()}
        <ClockInRestrictionBanner reason={clockInRestricted ?? { reason: null, futureAllowance: null }} />
        {renderEmployeesPicker(form)}
        <TimeActionFormDetailsSection<ITimeActionsFormData>
          form={form}
          onFormChange={setFormInfo}
          showGpsError={checkValidationsPayload.current.gpsRestricted && hasGpsRestriction}
          considerEquipmentRequirements={isActiveMemberOnlyMember}
        />
        {renderDailySignInCheck()}
        {renderDescription(form)}
        {renderActionButtons(form)}
      </div>
    );
  }

  function renderClockInValidationBanner(): ReactNode {
    const flagged = answerCheck?.flagged;
    return (
      <>
        {flagged && (
          <div className="mb-4">
            <Well className={'submission-required-well'} theme={Theme.DANGER}>
              <Label className="validation-banner-title mb-4">{t('Clock In Restricted')}</Label>
              {answerCheck?.requiredMessage && answerCheck.requiredMessage}
              {!isNil(answerCheck?.warningMessage) && (
                <Button
                  onClick={() => setSignInQuestionWarningText(answerCheck?.warningMessage ?? null)}
                  type="link"
                  className={'mt-5'}
                >
                  {t('View other sign in information.')}
                </Button>
              )}
            </Well>
          </div>
        )}
        {!isNil(answerCheck?.warningMessage) && flagged === false && (
          <div className="mb-4">
            <Well className={'submission-required-well'} theme={Theme.PRIMARY}>
              <Label className="warning-banner-title mb-4">{t('Clock In')}</Label>
              {answerCheck?.warningMessage ?? '---'}
            </Well>
          </div>
        )}
      </>
    );
  }

  function renderDailySignInCheck() {
    const complete = answerCheck?.complete;
    const dailySignInClasses = classNames(
      {
        'sign-in-check-button ': true,
        'complete-item': complete,
        'incomplete-item': !complete,
      },
      className
    );

    function handleDailySignInCheck() {
      if (!complete) {
        signInQuestionDialog.open();
      }
    }

    return (
      <>
        {!_.isNil(answerCheck) && answerCheck.showQuestions && (
          <div>
            <Label>{t('Daily Sign-In')}</Label>
            <div className={dailySignInClasses} onClick={handleDailySignInCheck}>
              {complete ? t('Submitted') : t('Not Submitted (Required)')}
              <Icon svg={ChevronRightIcon} className="covid-chevron" />
            </div>
          </div>
        )}
      </>
    );
  }

  function updateMembers({ ...form }: IFormRender<ITimeActionsFormData>, ids: string[]) {
    setMemberIds(ids);
    setFormInfo({
      ...form.state.data,
      members: ids,
    });
  }

  function renderEmployeesPicker({ ...form }: IFormRender<ITimeActionsFormData>) {
    function getPermissions() {
      return [MemberPermission.TIME_EVENTS];
    }

    return (
      <div>
        <Label>{t('Employees')}</Label>
        <EmployeeMultiPickerFormField
          name="members"
          form={form}
          permissions={{
            permissions: getPermissions(),
            operationType: OperationType.AND,
          }}
          validations={[{ validation: v_require }]}
          onChange={(memberIds) => updateMembers(form, memberIds)}
          includeClockedIn={false}
        />
      </div>
    );
  }

  function renderDescription({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div>
        <Label secondaryLabel={t('Optional')}>{t('Description')}</Label>
        <TextareaFormField name="description" form={form} restrictTo={{ maxLength: 5000 }} />
      </div>
    );
  }

  function renderActionButtons({ ...form }: IFormRender<ITimeActionsFormData>) {
    return (
      <div className="pt-6">
        <Button
          onClick={form.handleSubmit}
          type="primary"
          disabled={clockInRestricted === null || clockInRestricted?.reason !== null}
        >
          {t('Submit')}
        </Button>
      </div>
    );
  }

  function showToastError(message: string) {
    errorToastMessage.current = message;
    errorToastDetails.open();
  }

  async function handleSubmit(data: ITimeActionsFormData) {
    if (!_.isNil(answerCheck) && answerCheck.showQuestions && answerCheck.flagged) {
      showToastError(t('Clock in is restricted for the day.'));
      return;
    }

    if (!_.isNil(answerCheck) && answerCheck.showQuestions && !answerCheck.complete) {
      showToastError(t('Daily Sign-In questions are required.'));
      return;
    }

    if (hasGpsRestriction) {
      gpsRestrictedDialog.open();
      return;
    }

    if (
      (checkValidationsPayload.current.lock && hasLockDate) ||
      (checkValidationsPayload.current.conflict && hasConflictingEntries) ||
      (checkValidationsPayload.current.signature && hasSignatureViolation)
    ) {
      preValidationFormData.current = data;
      launchValidationAlertsOrSave();
      return;
    }

    if (loaderDetails.isOpen) {
      // Action is already being performed.
      return;
    }

    loaderDetails.open();

    if (data) {
      clockInFromForm(data);
    } else {
      onSubmit(data);
    }
  }

  function onMoveLockDate() {
    setHasLockDate(false);
    checkValidationsPayload.current.lock = false;
    lockDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onConflictContinue() {
    setHasConflictingEntries(false);
    checkValidationsPayload.current.conflict = false;
    conflictDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function onSignatureViolationContinue() {
    setHasSignatureViolation(false);
    checkValidationsPayload.current.signature = false;
    signatureViolationDialogDetails.close();
    handleSubmit(preValidationFormData.current!);
  }

  function launchValidationAlertsOrSave() {
    // lock date

    if (checkValidationsPayload.current.lock && hasLockDate && preValidationFormData.current) {
      const start =
        preValidationFormData.current.startDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.startDate.value!);
      const end =
        preValidationFormData.current.endDate.value === null
          ? today
          : DateTime.fromJSDate(preValidationFormData.current.endDate.value!);
      timeEntryRange.current = {
        startTime: preValidationFormData.current.timeRange.startTime!.set({
          year: start.year,
          month: start.month,
          day: start.day,
        }),
        endTime: preValidationFormData.current.timeRange.endTime!.set({
          year: end.year,
          month: end.month,
          day: end.day,
        }),
      };
      lockDialogDetails.open();
      return;
    }

    // conflicts alert

    if (checkValidationsPayload.current.conflict && hasConflictingEntries) {
      conflictDialogDetails.open();
      return;
    }

    // signatures alert

    if (checkValidationsPayload.current.signature && hasSignatureViolation) {
      signatureViolationDialogDetails.open();
      return;
    }
  }

  async function clockInFromForm(data: ITimeActionsFormData) {
    await performClockIn(convertTimeActionFormNoSelectionsToNull(data), onSubmit);
    loaderDetails.close();
  }

  return (
    <>
      <div>
        <div className="px-8 pb-8">
          <div className="mb-6">
            <Form
              data={formInfo}
              onSubmit={handleSubmit}
              render={renderFormFields}
              className={classes}
              allowMultipleSubmissions={true}
              onChange={onChange}
            />
            <Toast isOpen={errorToastDetails.isOpen} onClose={errorToastDetails.close} theme={Theme.DANGER}>
              {errorToastMessage.current}
            </Toast>
          </div>
        </div>
      </div>
      <Loader isOpen={loaderDetails.isOpen} />
      <HeaderDialog
        isOpen={signInQuestionDialog.isOpen}
        title={t('Daily Sign-In')}
        onClose={signInQuestionDialog.close}
        divider={true}
      >
        <SignInQuestionForm
          member={activeMember}
          onSubmit={(submissionId) => setSubmissionId(submissionId)}
          onClose={signInQuestionDialog.close}
        />
      </HeaderDialog>

      <TimeActionsValidations
        lockDate={timeEntryRange.current ? timeEntryRange.current.startTime : undefined}
        lockMemberIds={formInfo.members}
        lockDateType={LockDateDialogType.ADD}
        isLockDetailsOpen={lockDialogDetails.isOpen}
        onLockDetailsClose={lockDialogDetails.close}
        onMoveLockDate={onMoveLockDate}
        isConflictDetailsOpen={conflictDialogDetails.isOpen}
        onConflictDetailsClose={conflictDialogDetails.close}
        onConflictContinue={onConflictContinue}
        isSignatureViolationDetailsOpen={signatureViolationDialogDetails.isOpen}
        onSignatureViolationDetailsClose={signatureViolationDialogDetails.close}
        onSignatureViolationContinue={onSignatureViolationContinue}
      />
      <HeaderDialog
        isOpen={signInQuestionWarningText !== null}
        title={t('Sign-In Information')}
        onClose={() => setSignInQuestionWarningText(null)}
        divider={false}
      >
        <div className={'clocked-in-dialog'}>
          <div className={'p-2 pb-8 pl-8'}>{signInQuestionWarningText}</div>
        </div>
      </HeaderDialog>
      <HeaderDialog
        isOpen={gpsRestrictedDialog.isOpen}
        title={t('Clock In Restricted')}
        onClose={gpsRestrictedDialog.close}
        divider={false}
      >
        <div className={'clocked-in-dialog'}>
          <div className={'p-2 pb-8 pl-8'}>
            {t(
              'You must be on location to clock into this project. Please clock in using a mobile device. You can also create a manual time entry or perform a "Clock-In At" if you have the necessary permissions.'
            )}
          </div>
        </div>
      </HeaderDialog>
    </>
  );
};

ClockInForm.defaultProps = {
  memberIds: [],
};

export default ClockInForm;
