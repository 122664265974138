import { IFormRender, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IBreakMultiPickerItem } from 'components/domain/time-entry-break/BreakMultiPicker/BreakMultiPicker';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import FeatureTimeRangeFormField from 'components/domain/time/FeatureTimeRangeFormField/FeatureTimeRangeFormField';
import isNil from 'lodash/isNil';
import { DateTime, Duration } from 'luxon';
import ITimeRange from 'types/TimeRange';
import { dateUtils, timeEntryBreakUtils, timeEntryUtils } from 'utils';
import { nightShiftAdjustment } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getEndTimeForSubmission } from 'utils/timeEntryUtils';
import { v_require } from 'utils/validations';

export interface ITimeActionsTimeRangeFormFieldsProps {
  setFormInfo: (formInfo: ITimeActionsFormData) => void;
  endDate?: DateTime | null; // Edge case for multipleDays on `AddTimeEntryForm`
  form: IFormRender<ITimeActionsFormData>;
  updateBreakError: (isError: boolean) => void;
  hasConflictingEntries: boolean;
  className?: ClassName;
}

function TimeActionsTimeRangeFormFields({
  form,
  endDate,
  setFormInfo,
  updateBreakError,
  hasConflictingEntries,
  className,
}: ITimeActionsTimeRangeFormFieldsProps) {
  const startTime = form.state.data?.timeRange?.startTime;
  const endTime = form.state.data?.timeRange?.endTime;
  const timeDifference =
    startTime && endTime
      ? Duration.fromMillis(endTime.toMillis()).minus(Duration.fromMillis(startTime.toMillis()))
      : null;
  const isNightShift = timeDifference && timeDifference.as('seconds') < 0;

  function onTimeChange(value: ITimeRange<DateTime | null | undefined> | null) {
    if (
      value &&
      !isNil(value) &&
      !isNil(value.startTime) &&
      !isNil(value.endTime) &&
      !isNil(form.state.data.timeRange)
    ) {
      const start = dateUtils.combineDateAndTime(
        DateTime.fromJSDate(form.state.data.startDate.value!),
        value.startTime!
      );
      const effectiveEndDate = endDate ?? DateTime.fromJSDate(form.state.data.endDate.value!); // Is this safe?
      const end = dateUtils.combineDateAndTime(effectiveEndDate, value.endTime);
      const newValue: ITimeRange<DateTime> = nightShiftAdjustment({
        startTime: start,
        endTime: end,
      });
      let total = timeEntryUtils.getTotalFromDateTimes(
        {
          startTime: newValue.startTime,
          endTime: getEndTimeForSubmission({ startTime: newValue.startTime.toISO(), endTime: null }, newValue.endTime),
        },
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );
      if (total <= 0) {
        total = 1;
      } // For when total needs to start over on the midnight boundary.
      setFormInfo({
        ...form.state.data,
        timeRange: { startTime: start, endTime: end },
        total,
      });
      const isError = timeEntryBreakUtils.isOutsideRange(
        newValue,
        form.state.data.breaks.map((v: IBreakMultiPickerItem) => v.timeRange)
      );

      updateBreakError(isError);
    }
  }

  return (
    <div className={classNames('time-actions-time-range-form-fields', className)}>
      <Label secondaryLabel={isNightShift ? t('Night Shift') : undefined}>{t('Time')}</Label>
      <div className="time-pickers">
        <FeatureTimeRangeFormField
          name="timeRange"
          form={form}
          validations={[{ validation: v_require }]}
          onChange={onTimeChange}
          startDst={form.state.data.startDst}
          endDst={form.state.data.endDst}
        />
      </div>
      {form.state.data.startDate.value && hasConflictingEntries && (
        <div className="pt-2 pb-4">
          <Label className="conflicts-warning">{t('This entry conflicts with another entry.')}</Label>
        </div>
      )}
    </div>
  );
}

export default TimeActionsTimeRangeFormFields;
