import { t } from 'utils/localize';

export default function v_money(value: string, args: { message?: string }) {
  return {
    message: args.message || t('Not a valid currency amount'),
    validate: () => {
      if (!value) {
        return true;
      }
      // Decimals are optional
      return /(?=.*?\d)^(([1-9]\d{0,2})|\d+)?(\.\d{1,2})?$/.test(value);
    },
  };
}
