import { useMutation } from '@apollo/client';
import { MediaEntryCommentUpdateInput, UpdateMediaEntryCommentMutation } from '__generated__/graphql';
import gql from 'graphql-tag';
import { isoTimeStampUtc } from 'utils/dateUtils';

export default function useUpdateMediaEntryComment() {
  const [updateMediaEntryComment] = useMutation(UPDATE_MEDIA_ENTRY_COMMENT_MUTATION);

  const deleteMediaEntryComment = async (saveData: MediaEntryCommentUpdateInput) => {
    return new Promise<UpdateMediaEntryCommentMutation['updateMediaEntryComment']>((resolve, reject) => {
      return updateMediaEntryComment({
        variables: {
          mediaEntryComment: {
            ...saveData,
            deletedOn: isoTimeStampUtc(),
          },
        },
      }).then(async (results) => {
        if (results.data) {
          resolve(results.data.updateMediaEntryComment);
        } else {
          reject();
        }
      });
    });
  };
  const editMediaEntryComment = async (saveData: MediaEntryCommentUpdateInput) => {
    return new Promise<UpdateMediaEntryCommentMutation['updateMediaEntryComment']>((resolve, reject) => {
      return updateMediaEntryComment({
        variables: {
          mediaEntryComment: {
            ...saveData,
          },
        },
      }).then((results) => {
        if (results.data) {
          resolve(results.data.updateMediaEntryComment);
        } else {
          reject();
        }
      });
    });
  };
  return { deleteMediaEntryComment, editMediaEntryComment };
}

export const UPDATE_MEDIA_ENTRY_COMMENT_MUTATION = gql`
  mutation UpdateMediaEntryComment($mediaEntryComment: MediaEntryCommentUpdateInput!) {
    updateMediaEntryComment(mediaEntryComment: $mediaEntryComment) {
      id
      comment
      deletedOn
    }
  }
`;
