import {
  BASIC_PROJECT_LABOR_METRICS_FRAGMENT,
  BASIC_PROJECT_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-project-queries';
import {
  BASIC_EQUIPMENT_LABOR_METRICS_FRAGMENT,
  BASIC_LABOR_METRICS_FRAGMENT,
  BASIC_TIME_METRICS_FRAGMENT,
} from 'apollo/queries/jit-queries';
import gql from 'graphql-tag';

export const SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY = gql`
  query queryBasicEquipmentWithMemberMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        equipmentCents
        memberId
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithTimeOnlyMemberMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        equipmentCents
        memberId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY = gql`
  query queryBasicEquipmentWithProjectMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        equipmentCents
        descEquipmentCents
        ...ProjectLaborMetrics
        projectId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithProjectTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_PROJECT_AND_COST_CODE_METRIC_QUERY = gql`
  query queryBasicEquipmentWithProjectAndCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        equipmentCents
        descEquipmentCents
        ...ProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_PROJECT_AND_COST_CODE_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithProjectAndCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $projectIds: [Uuid!]
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        projectIds: $projectIds
        costCodeIds: $costCodeIds
      ) {
        equipmentCents
        descEquipmentCents
        ...BasicProjectLaborMetrics
        projectId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY = gql`
  query queryBasicEquipmentWithCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...LaborMetrics
        equipmentCents
        costCodeId
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
      ) {
        ...BasicLaborMetrics
        equipmentCents
        costCodeId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_METRIC_QUERY = gql`
  query queryBasicEquipmentWithMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicEquipmentLaborMetrics
        equipmentCents
      }
    }
  }
  ${BASIC_EQUIPMENT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY = gql`
  query queryBasicEquipmentWithTimeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
      ) {
        ...BasicLaborMetrics
        equipmentCents
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_METRIC_QUERY = gql`
  query queryBasicEquipmentWithMemberCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        memberIds: $memberIds
      ) {
        ...LaborMetrics
        equipmentCents
        memberId
        costCodeId
      }
    }
  }
  ${BASIC_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_MEMBER_COSTCODE_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithMemberCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $memberIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        memberIds: $memberIds
      ) {
        ...BasicLaborMetrics
        equipmentCents
        memberId
        costCodeId
      }
    }
  }
  ${BASIC_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY = gql`
  query queryBasicEquipmentWithProjectCostCodeMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        equipmentCents
        descEquipmentCents
        projectId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithProjectCostCodeTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $costCodeIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentProjectCostCodeLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        costCodeIds: $costCodeIds
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        equipmentCents
        descEquipmentCents
        projectId
        costCodeId
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY = gql`
  query queryBasicEquipmentWithMemberProjectMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        projectIds: $projectIds
      ) {
        ...ProjectLaborMetrics
        projectId
        memberId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_LABOR_METRICS_FRAGMENT}
`;

export const SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY = gql`
  query queryBasicEquipmentWithMemberProjectTimeOnlyMetrics(
    $after: String
    $filter: EquipmentFilter
    $first: Int
    $sort: [EquipmentSort!]
    $metricsInterval: LaborMetricsInterval!
    $metricsStartDate: Date!
    $metricsEndDate: Date!
    $metricFilter: LaborMetricFilter
    $memberIds: [Uuid!]
    $projectIds: [Uuid!]
  ) {
    equipment(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      archivedOn
      createdOn
      cursor
      deletedOn
      updatedOn
      submittedOn
      equipmentName
      organizationId
      trackManualHours
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
      model {
        id
        title
        category {
          id
          title
        }
        make {
          id
          title
        }
      }
      equipmentMemberProjectLaborMetrics(
        interval: $metricsInterval
        start: $metricsStartDate
        end: $metricsEndDate
        filter: $metricFilter
        memberIds: $memberIds
        projectIds: $projectIds
      ) {
        ...BasicProjectLaborMetrics
        projectId
        memberId
        equipmentCents
        descEquipmentCents
      }
    }
  }
  ${BASIC_PROJECT_TIME_METRICS_FRAGMENT}
`;
