import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';

export interface IFormattedEmployeeNameProps {
  firstName: string;
  lastName: string;
}

export default function FormattedEmployeeName({ firstName, lastName }: IFormattedEmployeeNameProps) {
  const nameFormatted = useEmployeeNameFormatter();
  return <>{nameFormatted(firstName, lastName)}</>;
}
