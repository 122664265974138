import { ACTIVE_MEMBER_UPDATE_MUTATION } from 'apollo/mutations/member-mutations';
import { useActiveMember } from 'hooks/store';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authMemberSetAction } from 'store/store';
import { IMemberUpdateInput } from 'types';
import useMemberUpdate from './useMemberUpdate';

export default function useActiveMemberUpdate() {
  const [updateKey, update] = useMemberUpdate(ACTIVE_MEMBER_UPDATE_MUTATION);

  const activeMember = useActiveMember();
  const dispatch = useDispatch();

  const updateMemberKey = useCallback(
    async <Key extends keyof IMemberUpdateInput>(key: Key, value: IMemberUpdateInput[Key]) => {
      const result = await updateKey(activeMember.id!, key, value);
      dispatch(authMemberSetAction(result.data!.updateMember!));
      return result;
    },
    [dispatch, activeMember.id, updateKey]
  );

  const updateMember = useCallback(
    async (value: Omit<IMemberUpdateInput, 'id'>) => {
      const result = await update({ ...value, id: activeMember.id! });
      dispatch(authMemberSetAction(result.data!.updateMember!));
      return result;
    },
    [dispatch, activeMember.id, update]
  );

  return [updateMemberKey, updateMember] as const;
}
