import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { findIndex, first, isNil, sortBy } from 'lodash';
import { useCallback } from 'react';
import useNavConfig from './useNavConfig';

export interface INavBarSettings {
  id: string;
  position: number;
  visible: boolean;
}

export default function useNavBarSettings() {
  const navConfig = useNavConfig();
  const memberSettings = useMemberSettings();
  const navBarSettings = memberSettings?.web?.ui?.navBarSettings;

  const getNavBarSettings = useCallback(
    (includeDashboard: boolean) => {
      // Dashboard, Settings, and Support are not customizable.
      let general = navConfig.top.filter((item) => item.enabled !== false);
      if (includeDashboard === false) {
        general = navConfig.top.filter((item) => item.id !== 'dashboard');
      }
      const reports = navConfig.reports.filter((item) => item.enabled !== false);

      if (!isNil(navBarSettings)) {
        const settings = navBarSettings;
        Object.keys(settings.values).forEach((key) => {
          const value = settings.values[key];

          const generalSetting = first(general.filter((item) => item.id === value.id));
          if (!isNil(generalSetting)) {
            const index = findIndex(general, (item) => item.id === value.id);
            if (index !== -1) {
              general.splice(index, 1, { ...generalSetting, position: value.position, visible: value.visible });
            }
          }

          const reportSetting = first(reports.filter((item) => item.id === value.id));
          if (!isNil(reportSetting)) {
            const index = findIndex(reports, (item) => item.id === value.id);
            if (index !== -1) {
              reports.splice(index, 1, { ...reportSetting, position: value.position, visible: value.visible });
            }
          }
        });
      }

      return { general: sortBy(general, 'position'), reports: sortBy(reports, 'position'), misc: navConfig.bottom };
    },
    [navBarSettings, navConfig]
  );

  const getVisibleNavBarSettings = useCallback(() => {
    const settings = getNavBarSettings(true);
    return {
      general: settings.general.filter((item) => item.visible === true),
      reports: settings.reports.filter((item) => item.visible === true),
      misc: settings.misc,
    };
  }, [getNavBarSettings]);

  const areAllNavItemsVisible = useCallback(() => {
    const settings = getNavBarSettings(true);

    const general = settings.general.filter((item) => item.visible === false);
    const reports = settings.reports.filter((item) => item.visible === false);
    const misc = settings.misc.filter((item) => item.visible === false);

    return general.length === 0 && reports.length === 0 && misc.length === 0;
  }, [getNavBarSettings]);

  return { getNavBarSettings, getVisibleNavBarSettings, areAllNavItemsVisible };
}
