import axios from 'axios';
import { useReduxSelector } from 'hooks';
import { useMemo } from 'react';

export default function useSlackClient() {
  const url = useReduxSelector((state) => state.environment.REACT_SLACK_URL);

  return useMemo(() => {
    return axios.create({
      baseURL: url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }, [url]);
}
