import {
  IncidentMedicalDetailsUpdateInput,
  UpdateIncidentMedicalDetailsMutation,
  UpdateIncidentMedicalDetailsMutationVariables,
} from '__generated__/graphql';
import gql from 'graphql-tag';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export const useUpdateIncidentMedicalDetails = () => {
  const client = useGraphQLClient();
  const updateIncidentMedicalDetails = useCallback(
    async (input: IncidentMedicalDetailsUpdateInput) => {
      const result = await client.request<
        UpdateIncidentMedicalDetailsMutation,
        UpdateIncidentMedicalDetailsMutationVariables
      >({
        document: UPDATE_INCIDENT_MEDICAL_DETAILS,
        variables: {
          incidentMedicalDetails: input,
        },
      });

      if (isNil(result.updateIncidentMedicalDetails)) {
        throw Error(`result was ${result.updateIncidentMedicalDetails}`);
      }

      return result.updateIncidentMedicalDetails;
    },
    [client]
  );

  return updateIncidentMedicalDetails;
};

const UPDATE_INCIDENT_MEDICAL_DETAILS = gql`
  mutation UpdateIncidentMedicalDetails($incidentMedicalDetails: IncidentMedicalDetailsUpdateInput) {
    updateIncidentMedicalDetails(incidentMedicalDetails: $incidentMedicalDetails) {
      id
    }
  }
`;
