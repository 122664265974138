import { Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { BrandLogo } from 'components/domain/meta/BrandLogo';
import { useActiveMember } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { ClassName } from 'types/ClassName';
import './ExpandedTimeCardsPrintFooter.scss';

export interface IExpandedTimeCardsPrintFooterProps {
  className?: ClassName;
}

const ExpandedTimeCardsPrintFooter = (props: IExpandedTimeCardsPrintFooterProps) => {
  const { className } = props;

  const member = useActiveMember();
  const nameFormatter = useEmployeeNameFormatter();
  const today = DateTime.local();

  function getTimeRangeFormat() {
    return 't EEE, MMM d, yyyy';
  }

  const classes = classNames('expanded-time-cards-print-footer mt-2 mx-5 print', className);

  return (
    <div className={classes}>
      <Row justify={Justify.SPACE_BETWEEN}>
        <div>
          <div className="section-title">{t('Generated By') + ':'}</div>
          <div className="section-info">{nameFormatter(member.firstName ?? '', member.lastName ?? '')}</div>
          <div className="section-info">{today.toFormat(getTimeRangeFormat())}</div>
        </div>
        <div>
          <div className="section-title">{t('Provided by') + ':'}</div>
          <span className="footer-logo">
            <BrandLogo />
          </span>
        </div>
      </Row>
    </div>
  );
};

export default ExpandedTimeCardsPrintFooter;
