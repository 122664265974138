import { Button, Justify, Link, Snackbar, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { useLocalStorage } from 'hooks';
import { t } from 'utils/localize';
import { CookieConsentStoreKey, defaultCookieConsent } from './CookieConsent';
import './CookieConsentBanner.scss';

export interface ICookieConsentBannerProps {
  className?: ClassName;
}

const CookieConsentBanner = (props: ICookieConsentBannerProps) => {
  const { className } = props;

  const [consentData, updateConsentData] = useLocalStorage(CookieConsentStoreKey, defaultCookieConsent);

  const classes = classNames('cookie-consent-banner no-print', className);

  return (
    <>
      {consentData.consent === false && (
        <Snackbar
          className={classes}
          onClose={() => updateConsentData({ consent: true })}
          isOpen={!consentData.consent}
          justify={Justify.FLEX_START}
          theme={Theme.LIGHT}
        >
          <div>
            <span>{t('This website uses cookies. For more information, view our ')}</span>
            <Link onClick={() => window.open('https://busybusy.com/privacy/')} href="#">
              {t('privacy policy.')}
            </Link>
          </div>
          <div className="ok-button">
            <Button type="primary" onClick={() => updateConsentData({ consent: true })}>
              {t('OK')}
            </Button>
          </div>
        </Snackbar>
      )}
    </>
  );
};

export default CookieConsentBanner;
