import useActivePosition from 'hooks/models/position/useActivePosition';
import { t } from 'i18next';
import { useMemo } from 'react';
import { FormInfo } from 'types/FormData';
import { useFeatureFlags } from 'utils/features';
import { getSafeArray } from 'utils/optionalUtils';
import { v_require } from 'utils/validations';
import { EquipmentSelectionOptions } from '__generated__/graphql';
import EquipmentPickerFormField, {
  IEquipmentPickerFormFieldProps,
} from '../EquipmentPickerFormField/EquipmentPickerFormField';

export interface IEquipmentClockFormPickerFormFieldProps<FormType extends FormInfo>
  extends Omit<IEquipmentPickerFormFieldProps<FormType>, 'placeholder'> {
  considerEquipmentRequirements?: boolean;
  placeholderOverride?: string;
}

export const NO_EQUIPMENT_ID = 'no-equipment';

export default function EquipmentClockFormPickerFormField<FormType extends FormInfo>({
  validations,
  considerEquipmentRequirements,
  placeholderOverride,
  ...pickerProps
}: IEquipmentClockFormPickerFormFieldProps<FormType>) {
  const activePosition = useActivePosition();
  const isEquipmentRequirementEnabled = useFeatureFlags('REQUIRE_EQUIPMENT_POSITION_SETTING');
  const equipmentSelectionRequirement = activePosition.equipmentSelectionRequirement;
  const isEquipmentSelectionRequired =
    isEquipmentRequirementEnabled &&
    considerEquipmentRequirements &&
    equipmentSelectionRequirement &&
    equipmentSelectionRequirement !== EquipmentSelectionOptions.Off;

  const headerItems: IEquipmentClockFormPickerFormFieldProps<FormType>['pickerHeaderItems'] = useMemo(() => {
    // Opt Out
    const showHeaderItems = equipmentSelectionRequirement === EquipmentSelectionOptions.Recommended;

    if (isEquipmentRequirementEnabled && considerEquipmentRequirements && showHeaderItems) {
      return [{ name: t('No Equipment'), id: 'no-equipment' }];
    }

    return [];
  }, [considerEquipmentRequirements, equipmentSelectionRequirement, isEquipmentRequirementEnabled]);

  const updatedValidations: IEquipmentClockFormPickerFormFieldProps<FormType>['validations'] = useMemo(() => {
    const addRequirementValidation =
      equipmentSelectionRequirement === EquipmentSelectionOptions.Required ||
      equipmentSelectionRequirement === EquipmentSelectionOptions.Recommended;

    if (isEquipmentRequirementEnabled && considerEquipmentRequirements && addRequirementValidation) {
      return [...getSafeArray(validations), { validation: v_require }];
    }

    return validations;
  }, [considerEquipmentRequirements, equipmentSelectionRequirement, isEquipmentRequirementEnabled, validations]);

  return (
    <EquipmentPickerFormField
      {...pickerProps}
      pickerHeaderItems={headerItems}
      validations={updatedValidations}
      placeholder={isEquipmentSelectionRequired ? t('Equipment (Required)') : t('Equipment')}
    />
  );
}
