import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FunctionComponent } from 'react';
import './Well.scss';
import { IChildrenProps } from 'types/ChildrenProps';

interface IWellProps extends IChildrenProps {
  className?: ClassName;
  theme?: 'primary' | 'danger' | 'outline';
}

const Well: FunctionComponent<IWellProps> = ({ theme, className, children }) => {
  const wellTheme = theme || 'primary';
  const classes = classNames(
    'well',
    {
      [`well-${wellTheme}`]: true,
    },
    className
  );

  return <div className={classes}>{children}</div>;
};

export default Well;
