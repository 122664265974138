import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  end_date?: string;
  time_range_type?: TimeRangeType;
  member_id?: string;
  project_id?: string;
  exitToLegacy?: boolean;
}

export default function useDailyProjectReportNavigation() {
  return useNavigation<IParams>('/daily-report');
}
