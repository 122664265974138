import { QueryKey } from '@tanstack/react-query';
import { useActiveMember, useOrganization } from 'hooks';
import useActivePosition from 'hooks/models/position/useActivePosition';
import { useMemo } from 'react';

// TODO figure out how to bake this into useQuery effectively?
export default function useReactQueryBaseKey(): QueryKey {
  const activeMember = useActiveMember();
  const activePosition = useActivePosition();
  const organization = useOrganization();

  return useMemo(
    () => [activeMember?.id, activePosition?.id, organization?.id],
    [activeMember?.id, activePosition?.id, organization?.id]
  );
}
