import { DocumentNode, useMutation } from '@apollo/client';
import { CREATE_TIME_OFFS, UPDATE_TIME_OFF } from 'apollo/mutations/time-off-mutations';
import { useTimesheetsGraylog } from 'hooks';
import ITimeOff, { ITimeOffUpdateInput } from 'types/TimeOff';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { isoTimeStampUtc } from 'utils/dateUtils';
import useTimeOffCreate from './useTimeOffCreate';
import useTimeOffEdit from './useTimeOffEdit';

export default function useTimeOff<T, U>(
  createTimeOffDocumentNode: DocumentNode = CREATE_TIME_OFFS,
  updateTimeOffDocumentNode: DocumentNode = UPDATE_TIME_OFF
) {
  const [updateTimeOffMutation] = useMutation<U>(updateTimeOffDocumentNode);
  const userEvents = useTimesheetsGraylog();
  const createTimeOff = useTimeOffCreate<T>(createTimeOffDocumentNode);
  const editTimeOff = useTimeOffEdit<U>(updateTimeOffDocumentNode);

  function deleteTimeOff(timeOff: Pick<ITimeOff, 'id'>) {
    const entry: ITimeOffUpdateInput = {
      id: timeOff.id,
      deletedOn: isoTimeStampUtc(),
    };

    userEvents.events(TimesheetsTypes.events.action_type.DELETE_TIME_OFF);

    return updateTimeOffMutation({ variables: { timeOff: entry } });
  }

  return { createTimeOff, editTimeOff, deleteTimeOff };
}
