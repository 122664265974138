import gql from 'graphql-tag';

export const CREATE_EQUIPMENT = gql`
  mutation CreateEquipment($equipment: EquipmentCreateInput) {
    createEquipment(equipment: $equipment) {
      id
      equipmentName
      equipmentModelId
      trackManualHours
      model {
        id
        title
        year
        make {
          id
          title
        }
        category {
          id
          title
        }
      }
      lastHours {
        id
        runningHours
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation UpdateEquipment($equipment: EquipmentUpdateInput) {
    updateEquipment(equipment: $equipment) {
      id
      equipmentName
      equipmentModelId
      trackManualHours
      model {
        id
        title
        year
        make {
          id
          title
        }
        category {
          id
          title
        }
      }
      lastHours {
        id
        runningHours
      }
      costHistory {
        id
        machineCostRate
        operatorCostRate
        changeDate
        updatedOn
        deletedOn
      }
    }
  }
`;
