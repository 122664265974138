import { useApolloClient } from '@apollo/client';
import { Button, Justify, Row } from '@busybusy/webapp-react-ui';
import { COST_CODE_IDS_QUERY } from 'apollo/queries/cost-code-queries';
import { CostCodeIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog, Panel } from 'components';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import DashboardActiveList from 'components/foundation/dashboard/dashboard-list/DashboardActiveList/DashboardActiveList';
import { IDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import { DashboardTheme } from 'containers/dashboard/types/types';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { useCostCodeManagementListNavigation, useOpenable, useOrganizationUpdate } from 'hooks';
import useActivePosition from 'hooks/models/position/useActivePosition';
import useCostCodeDashboardNavigation from 'hooks/navigation/useCostCodeDashboardNavigation';
import useCostCodeEnabled from 'hooks/settings/useCostCodeEnabled/useCostCodeEnabled';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import ICostCode from 'types/CostCode';
import { colors } from 'utils/constants/colors';
import { t } from 'utils/localize';
import DashboardCardSpinner from '../../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../../DashboardErrorCard/DashboardErrorCard';

export interface IActiveCostCodesDashboardCardContentProps {
  data: IDashboardListRow[];
  loading: boolean;
  onReload: () => void;
  onDetailClick: () => void;
  onHide: () => void;
  theme: DashboardTheme;
  error: string | null;
  setting: IDashboardSettingsItem;
  className?: string | null;
}

export default function ActiveCostCodesDashboardCardContent({
  data,
  loading,
  error,
  setting,
  theme,
  onDetailClick,
  onReload,
  onHide,
  className,
}: IActiveCostCodesDashboardCardContentProps) {
  const navigateToCostCodeDashboard = useCostCodeDashboardNavigation();
  const managementListNavigation = useCostCodeManagementListNavigation();
  const [updateKey] = useOrganizationUpdate();
  const settingsDialogDetails = useOpenable();
  const [shouldShowCreateCostCodes, setShouldShowCreateCostCodes] = useState(false);
  const enabled = useCostCodeEnabled();
  const activePosition = useActivePosition();
  const client = useApolloClient();
  const timeFrame = setting.options?.time ?? 'pastFourteen';
  const title = getDashboardTimeFrameTitle(setting.title, timeFrame);

  useEffect(() => {
    if (enabled) {
      client
        .query<{ costCodes: ICostCode[] }>({
          query: COST_CODE_IDS_QUERY,
          variables: {
            first: 1,
          },
        })
        .then((results) => {
          setShouldShowCreateCostCodes(isEmpty(results.data.costCodes));
        });
    }
  }, [enabled]);

  function openCostCodeManagementList() {
    managementListNavigation();
  }

  function openCostCodeDashboard(costCodeId: string) {
    navigateToCostCodeDashboard({ cost_code_id: costCodeId });
  }

  function openCostCodeEnableDialog() {
    updateKey('trackCostCode', true);
    settingsDialogDetails.open();
  }

  function renderList() {
    return data && data?.length > 0 ? (
      <DashboardActiveList rowItems={data} onRowClick={openCostCodeDashboard} theme={theme} />
    ) : null;
  }

  const classes = classNames('active-members-dashboard-card', className);

  return (
    <>
      {enabled ? (
        !error ? (
          !shouldShowCreateCostCodes || loading ? (
            <DashboardContentCard
              tabColor={colors.primary}
              subheaderValue={title}
              theme={theme}
              className={classes}
              rightLabel={t('View Report')}
              onRightLabelClick={onDetailClick}
            >
              {loading ? <DashboardCardSpinner /> : renderList()}
            </DashboardContentCard>
          ) : (
            <DashboardEnableCard
              title={setting.title}
              svg={CostCodeIcon}
              onHide={onHide}
              buttonLabel={t('Create Cost Codes')}
              onClick={openCostCodeManagementList}
              theme={theme}
            />
          )
        ) : (
          <DashboardErrorCard theme={theme} onClick={onReload} />
        )
      ) : activePosition?.manageCompanySettings ? (
        <DashboardEnableCard
          title={setting.title}
          svg={CostCodeIcon}
          onHide={onHide}
          buttonLabel={t('Enable Cost Codes')}
          onClick={openCostCodeEnableDialog}
          theme={theme}
        />
      ) : null}
      <HeaderDialog
        title={t('Cost Codes Enabled')}
        isOpen={settingsDialogDetails.isOpen}
        onClose={settingsDialogDetails.close}
        position="center"
      >
        <Panel className="px-8 py-6">
          <div>
            {t(
              'Would you like to visit the cost code management page where you can create and edit cost codes for your company?'
            )}
          </div>
          <Row justify={Justify.FLEX_END}>
            <Button type="secondary" onClick={settingsDialogDetails.close} className="mr-4">
              {t('No Thanks')}
            </Button>
            <Button type="primary" onClick={openCostCodeManagementList}>
              {t('Manage')}
            </Button>
          </Row>
        </Panel>
      </HeaderDialog>
    </>
  );
}
