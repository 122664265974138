import { SortDirection } from '@busybusy/webapp-react-ui';
import { LaborMetricsInterval } from '__generated__/graphql';
import useEquipmentActivityDateRangeDetails, {
  IEquipmentDateActivityTableRowInfo,
} from 'containers/activity-reports/equipment-tables/hooks/useEquipmentActivityDateRangeDetails';
import { IActivityReportEquipmentRowInfo } from 'containers/activity-reports/hooks/ActivityReportData';
import { useTableSorting } from 'hooks';
import { first, groupBy, isNil, keys, sortBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import { aggregateActivityRows } from '../../hooks/ActivitySummaryQueryUtils';
import { IDateActivityTableRowInfo } from './useDateActivity';

export interface IDateEquipmentActivityTableRowInfo extends IDateActivityTableRowInfo, IActivityReportEquipmentRowInfo {
  equipmentId: string | null;
}

export default function useDateActivityEquipmentDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  intervalType: LaborMetricsInterval,
  filterIdWithDescendants?: boolean
) {
  const [data, setData] = useState<IDateEquipmentActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'startDate',
    SortDirection.ASCENDING
  );

  const {
    loadData,
    sortedData: equipmentData,
    remainingData,
  } = useEquipmentActivityDateRangeDetails(filterId, filterType, timeRange, intervalType, filterIdWithDescendants);

  useEffect(() => {
    updateData();
  }, [equipmentData]);

  function updateData() {
    const dataForDates: IDateEquipmentActivityTableRowInfo[] = equipmentData.flatMap((row) => {
      const dateRows = row.detailRows as IEquipmentDateActivityTableRowInfo[] | undefined;
      return (
        dateRows?.map((data) => {
          return {
            ...data,
            startDate: data.startDate!,
            endDate: data.endDate!,
            equipmentId: data.equipment!.id,
            detailRows: [],
          };
        }) ?? []
      );
    });

    if (!isNil(remainingData)) {
      const dateRows = remainingData.detailRows as IEquipmentDateActivityTableRowInfo[] | undefined;

      dateRows?.forEach((data) => {
        dataForDates.push({
          ...data,
          startDate: data.startDate!,
          endDate: data.endDate!,
          equipmentId: remainingDataItemId,
          equipment: null,
          detailRows: [],
        });
      });
    }

    const dataByDate = groupBy(dataForDates, (data) => data.startDate);
    const dates = keys(dataByDate);

    setData(
      dates.map((date) => {
        const rowData = dataByDate[date];
        const firstRow = first(rowData)!;
        const totalMetrics = aggregateActivityRows(rowData);

        const noEquipment = rowData.find((row) => isNil(row.equipment));

        const sortedDetailRows = sortBy(
          rowData.filter((row) => !isNil(row.equipment)),
          (row) => getEquipmentDisplay(row.equipment).toLowerCase()
        );

        if (!isNil(noEquipment)) {
          sortedDetailRows.push(noEquipment);
        }

        return {
          ...totalMetrics,
          id: date,
          startDate: firstRow.startDate!,
          endDate: firstRow.endDate!,
          equipmentId: null,
          equipmentCost: sumBy(sortedDetailRows, (row) => row.equipmentCost),
          equipmentRate: 0,
          equipmentTotalCost: sumBy(sortedDetailRows, (row) => row.equipmentCost) + totalMetrics.totalCost,
          equipment: null,
          detailRows: sortedDetailRows,
        };
      })
    );
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
  };
}
