import { DateTime } from 'luxon';
import IMemberLock from 'types/MemberLock';
import { Optional } from 'types/util/Optional';

export function inhibitsActions(memberLockDate: DateTime, endDate?: DateTime): boolean {
  return (endDate && endDate <= memberLockDate) ?? false;
}

export function memberLockInhibitsAction(memberLock: Optional<Pick<IMemberLock, 'effectiveDate'>>, endDate?: DateTime) {
  if (!memberLock?.effectiveDate) {
    return false;
  }

  const dateTime = DateTime.fromISO(memberLock.effectiveDate);

  return inhibitsActions(dateTime, endDate);
}
