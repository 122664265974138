import { Reducer } from 'redux';

import { get, isEmpty } from 'lodash';

import localStorage from '../../utils/localStorageUtils';

import {
  AUTHENTICATION_SET_TOKEN,
  IStoreAuthentication,
  IStoreAuthenticationSetTokenAction,
} from './authenticationIndex';
import { AUTHENTICATION_CLEAR_TOKEN } from './authenticationConstants';
import { IStoreAuthenticationClearTokenAction } from './clearToken/authenticationClearTokenAction';

export type IStoreAuthenticationActions = IStoreAuthenticationSetTokenAction | IStoreAuthenticationClearTokenAction;

const hydratedState: IStoreAuthentication = localStorage.get('authentication');
const defaultState: IStoreAuthentication = {
  token: null,
};

// hydrate the state from LocalStorage if possible, otherwise use the defaultState
const initialState: IStoreAuthentication = !isEmpty(get(hydratedState, 'token')) ? hydratedState : defaultState;

export const authenticationReducers: Reducer<IStoreAuthentication, IStoreAuthenticationActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AUTHENTICATION_SET_TOKEN:
      localStorage.set('authentication', action.payload);
      return { ...state, ...action.payload };
    case AUTHENTICATION_CLEAR_TOKEN:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default authenticationReducers;
