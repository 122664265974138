const SCHEDULING_CALENDAR_VIEW_VIEWED = 'Scheduling Calendar: Calendar View Viewed';
const SCHEDULING_CALENDAR_EVENTS = 'Scheduling Calendar: Events';
const SCHEDULING_CALENDAR_RANGE_SELECTION_ACTIONS = 'Scheduling Calendar: Range Selection Actions';
const SCHEDULING_CALENDAR_POPOVER_ACTIONS = 'Scheduling Calendar: Popover Actions';
const SCHEDULING_CALENDAR_FILTER_SET = 'Scheduling Calendar: Filter Set';

const schedulingAnalyticsConstants = {
  SCHEDULING_CALENDAR_VIEW_VIEWED,
  SCHEDULING_CALENDAR_EVENTS,
  SCHEDULING_CALENDAR_RANGE_SELECTION_ACTIONS,
  SCHEDULING_CALENDAR_POPOVER_ACTIONS,
  SCHEDULING_CALENDAR_FILTER_SET,
};

export default schedulingAnalyticsConstants;
