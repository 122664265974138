import { Button, ClassName, Form, IFormRender, Label, Loader, Radio, RadioGroupFormField, TextFormField, Theme, Tray } from "@busybusy/webapp-react-ui";
import { IncidentEmployeeSexes } from "__generated__/graphql";
import classNames from "classnames";
import { DateTime } from "luxon";
import { useUpdateIncident } from "../hooks/useUpdateIncident/useUpdateIncident";
import { ReactNode, useRef } from "react";
import { useOpenable } from "hooks";
import { DateTrayPickerFormField } from "components";
import DashboardRibbon from "components/foundation/dashboard/DashboardRibbon/DashboardRibbon";
import { t } from "i18next";
import { v_require } from "utils/validations";

interface IEmployeeDetailsFormProps {
    incidentId: string;
    handleSubmit?: (result: IEmployeeDetailsResult) => void;
    className?: ClassName;
}

export interface IEmployeeDetailsFormData {
    employeeJobTitle?: string | null;
    employeeBirthday: DateTime | null;
    employeeHireDate: DateTime | null;
    employeeSex?: IncidentEmployeeSexes;
    employeeAddress1?: string | null;
    employeeCity?: string | null;
    employeeState?: string | null;
    employeePostalCode?: string | null;
}

export interface IEmployeeDetailsResult {
    employeeAddress1?: string | null;
    employeeAddress2?: string | null;
    employeeBirthday?: string | null;
    employeeCity?: string | null;
    employeeHireDate?: string | null;
    employeeJobTitle?: string | null;
    employeePostalCode?: string | null;
    employeeSex?: IncidentEmployeeSexes | null;
    employeeState?: string | null;
    id: string;
}

const EmployeeDetailsForm = (props: IEmployeeDetailsFormProps) => {
    const { incidentId, handleSubmit, className } = props;

    const classes = classNames('employee-details-form', className);
    
    const updateIncident = useUpdateIncident();
    const loadingOpenable = useOpenable({ isOpen: false });
    const formData = useRef<IEmployeeDetailsFormData>({
        employeeBirthday: null,
        employeeHireDate: null
    });

    const onFormSubmit = async (formData: IEmployeeDetailsFormData) => {
        loadingOpenable.open();
        const result = await updateIncident({
            id: incidentId,
            employeeJobTitle: formData.employeeJobTitle ?? undefined,
            employeeBirthday: formData.employeeBirthday ? formData.employeeBirthday.toISODate() : undefined,
            employeeHireDate: formData.employeeHireDate ? formData.employeeHireDate.toISODate() : undefined,
            employeeSex: formData.employeeSex ?? undefined,
            employeeAddress1: formData.employeeAddress1 ?? undefined,
            employeeCity: formData.employeeCity ?? undefined,
            employeeState: formData.employeeState ?? undefined,
            employeePostalCode: formData.employeePostalCode ?? undefined,
        });
        loadingOpenable.close();
        if (result && handleSubmit) {
            handleSubmit(result);
        }
    }

    const submitButton = (form: IFormRender<IEmployeeDetailsFormData>): ReactNode => {
        return (
          <div className={'pb-6'}>
            <Button onClick={form.handleSubmit} type={'primary'}>
              {t('Submit')}
            </Button>
          </div>
        );
      };

    const renderFormField = (form: IFormRender<IEmployeeDetailsFormData>): ReactNode => (
        <>
            <Label className={'pt-4'}>{t('Job Title')}</Label>
            <TextFormField name={'employeeJobTitle'} form={form} validations={[{ validation: v_require }]} />
            <Label className={'pt-4'}>{t('Birth Date')}</Label>
            <DateTrayPickerFormField name={'employeeBirthday'} form={form} validations={[{ validation: v_require }]} />
            <Label className={'pt-4'}>{t('Hire Date')}</Label>
            <DateTrayPickerFormField name={'employeeHireDate'} form={form} validations={[{ validation: v_require }]} />
            <Label className={'pt-4'}>{t('Sex')}</Label>
            <RadioGroupFormField name={'employeeSex'} form={form} validations={[{ validation: v_require }]}>
                <Radio value={IncidentEmployeeSexes.Male} label={t('Male')} />
                <Radio value={IncidentEmployeeSexes.Female} label={t('Female')} />
            </RadioGroupFormField>
            <h3 className={'mb-4'}>{t('Employee Address')}</h3>
            <DashboardRibbon theme={Theme.LIGHT} className={'mb-6'}>
                <Label>{t('Street')}</Label>
                <TextFormField name={'employeeAddress1'} form={form} validations={[{ validation: v_require }]} />
                <div className={'city-state-zip'}>
                <Label>{t('City')}</Label>
                <TextFormField name={'employeeCity'} form={form} validations={[{ validation: v_require }]} />
                <Tray>
                    <div className={'state'}>
                    <Label>{t('State')}</Label>
                    <TextFormField name={'employeeState'} form={form} validations={[{ validation: v_require }]} />
                    </div>
                    <div>
                    <Label>{t('Zip')}</Label>
                    <TextFormField name={'employeePostalCode'} form={form} validations={[{ validation: v_require }]} />
                    </div>
                </Tray>
                </div>
            </DashboardRibbon>
            {submitButton(form)}
        </>
    )

    return (
        <div className={classes}>
            <Form render={renderFormField} onSubmit={onFormSubmit} data={formData.current}/>
            <Loader isOpen={loadingOpenable.isOpen} />
        </div>
    )
}

export default EmployeeDetailsForm;