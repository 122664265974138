import gql from 'graphql-tag';

export const COST_CODE_CREATE_QUERY = gql`
  mutation CreateCostCode($costCode: CostCodeCreateInput) {
    createCostCode(costCode: $costCode) {
      id
      costCode
      title
    }
  }
`;

export const COST_CODE_UPDATE_MUTATION = gql`
  mutation UpdateCostCode2($costCode: CostCodeUpdateInput) {
    updateCostCode(costCode: $costCode) {
      id
      costCode
      title
    }
  }
`;
