import { Button, ClassName, Label, Textarea } from "@busybusy/webapp-react-ui";
import classNames from "classnames";
import { HeaderDialog } from "components";
import { t } from "i18next";
import { useState } from "react";

export interface IDailySignOffResolveCustomQuestionDialogProps {
    className?: ClassName;
    isOpen: boolean;
    onClose: () => void;
    onSave: (comment: string) => void;
}

export interface IDailySignOffResolveCustomQuestionDialogData {
    comment?: string | null;
}

const DailySignOffResolveCustomQuestionDialog = (props: IDailySignOffResolveCustomQuestionDialogProps) => {
    const { className, isOpen, onClose, onSave } = props;

    const classes = classNames('daily-sign-off-resolve-custom-question', className);
    const [data, setData] = useState<IDailySignOffResolveCustomQuestionDialogData>({
        comment: null,
      });

      function handleSubmit() {
        onSave(data.comment ?? '');
        setData({
          comment: null
        })
        onClose();
      }

      const notesTextArea = () => {
        return (
          <Textarea
            className="m-5 custom-response-text"
            onChange={(comment) => setData({ ...data, comment })}
            value={data.comment ?? ''}
            restrictTo={{ maxLength: 1000 }}
          ></Textarea>
        );
      };

      const submitButton = () => {
        return (
          <Button type="primary" className="m-5" onClick={handleSubmit}>
            {t('Submit')}
          </Button>
        );
      };

      return (
        <HeaderDialog className={classes} isOpen={isOpen} onClose={onClose} divider={false} title={t('Process Question')}>
          <Label className="ml-5 mt-5">{t('Comment')}</Label>
          {notesTextArea()}
          {submitButton()}
        </HeaderDialog>
      );
}

export default DailySignOffResolveCustomQuestionDialog;