import useIsInIframe from 'hooks/store/useIsInIframe';
import { useCallback } from 'react';
import { IframeMessageSenderData } from '../types/types';

export default function useIframeMessageSender() {
  const isInIframe = useIsInIframe();

  return useCallback(
    (payload: IframeMessageSenderData) => {
      if (isInIframe) {
        if (window.top) {
          window.top.postMessage(payload, '*');
        } else {
          console.error('Unable to send message to parent');
        }
      }
    },
    [isInIframe]
  );
}
