import { Col, Label } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IEquipmentDisplayData, makeModelString } from 'utils/equipmentUtils';
import './FormattedEquipmentName.scss';

export interface IFormattedEquipmentNameProps {
  className?: ClassName;
  equipment: IEquipmentDisplayData;
}

export default function FormattedEquipmentName({ className, equipment }: IFormattedEquipmentNameProps) {
  const classes = classNames('formatted-equipment-name', className);

  return (
    <Col className={classes} align="start">
      <Label className="equipment mb-0">{equipment.equipmentName}</Label>
      <Label className="equipment-make-model fc-2 mb-0">{makeModelString(equipment, '-')}</Label>
    </Col>
  );
}
