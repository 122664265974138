import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IStoreTableScroll {
  scrollPosition: number | null;
  lastCursor: string | null;
}

export const initialState: IStoreTableScroll = {
  scrollPosition: null,
  lastCursor: null,
};

const slice = createSlice({
  name: 'tableScroll',
  initialState,
  reducers: {
    clearTableScroll: (state: IStoreTableScroll) => {
      state.scrollPosition = null;
      state.lastCursor = null;
      return state;
    },
    setTableScrollPosition: (state: IStoreTableScroll, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
      return state;
    },
  },
});

export const { clearTableScroll, setTableScrollPosition } = slice.actions;
export default slice.reducer;
