import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { type PolicyEntryPopulated } from './queries';

export const validatePolicyUrl = (url: string | undefined | null) => {
  // contentUrl for `photos` can be null, but those are not relevant on web
  if (isNil(url) || isEmpty(url)) return false;

  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const displayPolicyContent = (policy: PolicyEntryPopulated) => {
  return window.open(policy.contentUrl, '_blank', 'noopener noreferrer');
};
