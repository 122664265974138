import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IManagementLists } from 'types/ManagementLists';
import useMemberFeatureSettingsUpdate from '../member/useMemberFeatureSettingsUpdate';
import useMemberSettings from '../member/useMemberSettings';

export default function useManagementSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateFeatureSettings = useMemberFeatureSettingsUpdate();

  return useCallback(
    <K extends keyof IManagementLists>(keysAndPayloads: { key: K; payload: IManagementLists[K] }[]) => {
      const cloned = cloneDeep(memberSettings?.web?.features?.managementLists);

      const mapped = keysAndPayloads.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.key]: cur.payload,
        }),
        cloned
      );

      return updateFeatureSettings('managementLists', mapped);
    },
    [memberSettings, updateFeatureSettings]
  );
}
