import { Theme } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DashboardContentRibbon from 'components/foundation/dashboard/DashboardContentRibbon/DashboardContentRibbon';
import { IDashboardSettingsPayload } from 'containers/dashboard/types/types';
import {
  TimeOffPayType,
  TimeOffReportType,
} from 'containers/time-off-report/TimeOffReportSidePanel/TimeOffReportSidePanel';
import useTimeOffReportNavigation from 'hooks/navigation/useTimeOffNavigation';
import TimeRangeType from 'types/TimeRangeType';
import { compactObject, typedObjectKeys } from 'utils/objectUtils';
import useTimeOffDashboardData, { ITimeOffDashboardData } from './hooks/useTimeOffDashboardData';
import './TimeOffDashboardRibbonGroup.scss';
import { getTimeOffDashboardRibbonGroupTimeRange } from './utils/utils';

export interface ITimeOffDashboardRibbonGroupProps {
  setting: IDashboardSettingsPayload;
  theme: Theme.LIGHT | Theme.DARK;
  className?: ClassName;
}

function TimeOffDashboardRibbonGroup({ setting, theme, className }: ITimeOffDashboardRibbonGroupProps) {
  const timeFrame = setting.options?.timeOffTimeFrame ?? 'Upcoming';
  const { data, loading } = useTimeOffDashboardData(timeFrame);
  const timeOffReportNavigation = useTimeOffReportNavigation();

  const timeRange = getTimeOffDashboardRibbonGroupTimeRange(timeFrame);
  const timeRangeArg = {
    start_date: timeRange.start.toISODate(),
    end_date: timeRange.end!.toISODate(),
    time_range_type: TimeRangeType.CUSTOM,
  };

  const clickHandlers: Record<keyof ITimeOffDashboardData, () => void> = {
    pto: () => timeOffReportNavigation(compactObject({ pay_type: TimeOffPayType.PAID, ...timeRangeArg })),
    nonPto: () => timeOffReportNavigation(compactObject({ pay_type: TimeOffPayType.UNPAID, ...timeRangeArg })),
    requests: () =>
      timeOffReportNavigation(compactObject({ report_type: TimeOffReportType.TIME_OFF_REQUEST, ...timeRangeArg })),
  };

  return (
    <FlexContainer flexDirection="column" className={classNames('time-off-dashboard-ribbon-group', className)}>
      {data &&
        typedObjectKeys(data).map((key, index) => {
          const listItem = data[key];

          if (!listItem) {
            return null;
          }

          return (
            <DashboardContentRibbon
              key={key}
              className={classNames({ 'mt-6': index !== 0 }, 'ribbon')}
              onClick={clickHandlers[key]}
              title={listItem.title}
              subtitle={listItem.subtitle}
              value={listItem.value ?? ''}
              subvalue={listItem.subvalue}
              theme={theme}
              isLoading={loading}
            />
          );
        })}
    </FlexContainer>
  );
}

export default TimeOffDashboardRibbonGroup;
