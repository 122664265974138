import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  CostCode,
  LaborMetricsInterval,
  Maybe,
  Project,
  ProjectLaborMetricsInterface,
  Scalars,
} from '__generated__/graphql';
import { COST_CODE_NAME_QUERY } from 'apollo/queries/cost-code-queries';
import {
  SIMPLE_PROJECT_WITH_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  SIMPLE_PROJECT_WITH_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
} from 'apollo/queries/project-queries';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  encryptUnassignedProjectId,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import {
  COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils, flattenForSubProjectExport } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import _, { Dictionary, first, isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNil, mapNotNull, sortByIgnoreCase, toggleSelection } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { combineMetricFilters, metricConditionFilter, metricNullFilter } from 'utils/jitMetricUtils';
import { t } from 'utils/localize';
import {
  aggregateLaborMetrics,
  getGeneratedMetricLaborTotals,
  getGeneratedProjectDescendantMetricLaborTotals,
  getGeneratedProjectMetricLaborTotals,
} from 'utils/metricUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { IProjectActivityTableRowInfo } from './useProjectActivity';

export interface IProjectCostCodeActivityTableRowInfo extends IProjectActivityTableRowInfo {
  costCodeId: string | null;
  costCode: CostCode | null;
  subprojectData?: IProjectCostCodeActivityTableRowInfo[];
  detailRows?: IProjectCostCodeActivityTableRowInfo[];
}

interface ProjectCostCodeMetricRow extends ProjectLaborMetricsInterface {
  projectId?: Maybe<Scalars['Uuid']['output']>;
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useProjectActivityCostCodeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>
) {
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const { getAll } = useApolloPaging();
  const [data, setData] = useState<IProjectCostCodeActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IProjectCostCodeActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'project',
    SortDirection.ASCENDING,
    getSortField
  );
  const selectedProjectIds = useRef<string[]>([]);

  function idFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, true);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function query() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function subProjectQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return SIMPLE_PROJECT_WITH_MEMBER_COST_CODE_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return SIMPLE_PROJECT_WITH_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return SIMPLE_PROJECT_WITH_COST_CODE_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getCostCodeQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? COST_CODES_WITH_MEMBER_PROJECT_METRICS_QUERY
          : COST_CODES_WITH_MEMBER_PROJECT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? COST_CODES_WITH_PROJECT_AND_EQUIPMENT_METRICS_QUERY
          : COST_CODES_WITH_PROJECT_AND_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
      case ActivityReportType.BY_PROJECT:
        return canViewCost ? COST_CODES_WITH_PROJECT_METRICS_QUERY : COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IProjectCostCodeActivityTableRowInfo, key: keyof IProjectCostCodeActivityTableRowInfo) {
    if (key === 'project') {
      return item.project?.title;
    } else {
      return item[key];
    }
  }

  async function getCostCodes(costCodeIds: string[]) {
    if (isEmpty(costCodeIds)) {
      return [];
    }

    return await getAll<CostCode & ICursorable>('costCodes', {
      query: COST_CODE_NAME_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: costCodeIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    let filter: object;

    if (filterType === ActivityReportType.BY_PROJECT) {
      filter = {
        parentProjectId: { equal: filterId },
      };
    } else {
      filter = {
        depth: { equal: 1 },
      };
    }

    const projects = await getAll<Project & ICursorable>('projectsWithTime', {
      query: query(),
      variables: {
        first: 100,
        filter,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...idFilter(),
        ...hasTimeIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const costCodeLookup = await getCostCodeLookup(projects);
    const tableRows = mapNotNull(projects, (project) => createTableRowInfo(costCodeLookup, project));

    const remainingData = await createNoProjectRow(tableRows);
    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    if (selectedProjectIds.current && !isEmpty(selectedProjectIds.current)) {
      // if we have subprojects selected then update the data with the sub project info
      setData(await updateDataWithSubprojectInfo(tableRows, selectedProjectIds.current));
    } else {
      setData(tableRows);
    }
  }

  const createTableRowInfo = (
    costCodeLookup: Dictionary<CostCode>,
    project: Project
  ): IProjectCostCodeActivityTableRowInfo | null => {
    const metrics = laborMetrics(project);

    let noCostCodeRow: IProjectCostCodeActivityTableRowInfo | undefined = undefined;
    const noCostCode = first(metrics.filter((metric) => isNil(metric.costCodeId)));
    if (!isNil(noCostCode)) {
      const metricTotal = getGeneratedProjectMetricLaborTotals(noCostCode);
      const descendantData = getGeneratedProjectDescendantMetricLaborTotals(noCostCode);

      if (
        metricTotal.totalSeconds > 0 ||
        metricTotal.totalCost > 0 ||
        descendantData.totalSeconds > 0 ||
        descendantData.totalCost > 0
      ) {
        noCostCodeRow = {
          id: project.id + remainingDataItemId,
          costCodeId: remainingDataItemId,
          costCode: null,
          project,
          isUnassigned: false,
          descendantData,
          ...activityReportUtils.metricToRowInfo(metricTotal),
        };
      }
    }

    const unsortedCostCodeRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedProjectMetricLaborTotals(metric);
      const descendantData = getGeneratedProjectDescendantMetricLaborTotals(metric);

      if (
        isNil(metric.costCodeId) ||
        (metricTotal.totalSeconds === 0 &&
          metricTotal.totalCost === 0 &&
          descendantData.totalSeconds === 0 &&
          descendantData.totalCost === 0)
      ) {
        return null;
      }

      return {
        id: project.id + metric.costCodeId,
        costCodeId: metric.costCodeId,
        costCode: costCodeLookup[metric.costCodeId] ?? null,
        project,
        isUnassigned: false,
        descendantData,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const costCodeRows = sortByIgnoreCase(unsortedCostCodeRows, (row) => getCostCodeDisplay(row.costCode));

    if (isEmpty(costCodeRows) && noCostCodeRow === undefined) {
      return null;
    } else {
      const projectTotalMetrics = aggregateActivityRows(
        noCostCodeRow === undefined ? costCodeRows : [...costCodeRows, noCostCodeRow]
      );

      const rowDecendants = mapNotNull(costCodeRows, (row) => row.descendantData);
      const projectTotalDecendantMetrics = aggregateLaborMetrics(
        !isNil(noCostCodeRow?.descendantData) ? [...rowDecendants, noCostCodeRow!.descendantData] : rowDecendants
      );

      return {
        ...projectTotalMetrics,
        id: project.id,
        costCodeId: null,
        costCode: null,
        project,
        isUnassigned: false,
        descendantData: projectTotalDecendantMetrics,
        detailRows: noCostCodeRow === undefined ? costCodeRows : [...costCodeRows, noCostCodeRow],
      };
    }
  };

  const createNoProjectRow = async (
    tableRows: IProjectCostCodeActivityTableRowInfo[]
  ): Promise<IProjectCostCodeActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost);
    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Project row
    }

    let metricFilter = undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.MemberId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_EQUIPMENT:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
          filterId === remainingDataItemId
            ? metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull)
            : metricConditionFilter(ConditionFieldType.EquipmentId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        metricFilter = metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull);
        break;
      case ActivityReportType.BY_PROJECT:
        metricFilter = metricConditionFilter(ConditionFieldType.ProjectId, ConditionOperationType.Equal, filterId);
        break;
    }

    const costCodes = await getAll<CostCode & ICursorable>('costCodes', {
      query: getCostCodeQuery(),
      variables: {
        first: 100,
        filter: {
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
            projectId: { isNull: true },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: metricFilter,
      },
      fetchPolicy: 'network-only',
    });

    const unsortedCostCodeRows: IProjectCostCodeActivityTableRowInfo[] = costCodes.flatMap((costCode) => {
      const metrics = costCodeLaborMetrics(costCode);

      return mapNotNull(metrics, (metric) => {
        const metricTotal = getGeneratedMetricLaborTotals(metric);
        if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
          return null;
        }

        return {
          id: remainingDataItemId + costCode.id,
          project: null,
          costCode: costCode,
          costCodeId: costCode.id,
          isUnassigned: false,
          descendantData: null,
          ...activityReportUtils.metricToRowInfo(metricTotal),
        };
      });
    });

    const costCodeRows = sortByIgnoreCase(unsortedCostCodeRows, (row) => getCostCodeDisplay(row.costCode));
    const remainingCostCodeData = calculateRemainingActivityData(
      costCodeRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingCostCodeData.totalHours > 0) {
      // add the no cost code item under no project details
      costCodeRows.push({
        ...remainingCostCodeData,
        id: remainingDataItemId + remainingDataItemId,
        project: null,
        costCode: null,
        costCodeId: remainingDataItemId,
        isUnassigned: false,
        descendantData: null,
      });
    }

    return {
      project: null,
      costCode: null,
      costCodeId: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: costCodeRows,
      isUnassigned: false,
      descendantData: null,
    };
  };

  const getCostCodeLookup = async (data: Project[]) => {
    const metrics = data.flatMap((project) => laborMetrics(project));
    const codeCodeIds = mapNotNil(metrics, (item) => item.costCodeId);
    const costCodes = await getCostCodes(uniq(codeCodeIds));

    return keyBy(costCodes, (costCode) => costCode.id);
  };

  const laborMetrics = (project: Project): ProjectCostCodeMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return project.projectMemberCostCodeLaborMetrics;
      case ActivityReportType.BY_PROJECT:
        return project.projectCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return project.projectCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectCostCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const costCodeLaborMetrics = (costCode: CostCode): ProjectCostCodeMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return costCode.costCodeMemberProjectLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return costCode.costCodeProjectEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return costCode.costCodeProjectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  async function updateDataWithSubprojectInfo(
    projectData: IProjectCostCodeActivityTableRowInfo[],
    selectedProjectIds: string[]
  ) {
    if (!isEmpty(projectData)) {
      let subprojectResult: Project[] = [];

      if (!_.isEmpty(selectedProjectIds)) {
        const projects = await getAll<Project & ICursorable>('projects', {
          query: subProjectQuery(),
          variables: {
            first: 100,
            filter: {
              parentProjectId: { contains: selectedProjectIds },
            },
            sort: [{ title: 'asc' }],
            metricsInterval: LaborMetricsInterval.Custom,
            metricsStartDate: timeRange.startTime.toISODate(),
            metricsEndDate: timeRange.endTime.toISODate(),
            ...idFilter(),
          },
          fetchPolicy: 'network-only',
        });

        subprojectResult = projects;
      }

      const costCodeLookup = await getCostCodeLookup(subprojectResult);
      const subprojectData = mapNotNull(subprojectResult, (project) => createTableRowInfo(costCodeLookup, project));
      const subprojectDataByParent = _.groupBy(subprojectData, (item) => item.project!.parentProjectId);

      return updateProjectDataWithSubprojectData(projectData, subprojectDataByParent);
    }

    return data;
  }

  function updateProjectDataWithSubprojectData(
    projectData: IProjectCostCodeActivityTableRowInfo[],
    subprojectDataByParent: Dictionary<IProjectCostCodeActivityTableRowInfo[]>
  ): IProjectCostCodeActivityTableRowInfo[] {
    return projectData
      .filter((item) => item.isUnassigned === false)
      .map((item) => {
        const subprojectItems = subprojectDataByParent[item.project!.id];
        if (subprojectItems && !_.isEmpty(subprojectItems)) {
          // the project is expanded, so add data for the project's subs
          // if we have existing subproject data on our item use that because it has the graph built in
          const subprojectData = updateProjectDataWithSubprojectData(
            !_.isEmpty(item.subprojectData) ? item.subprojectData! : subprojectItems,
            subprojectDataByParent
          );
          if (!_.isEmpty(subprojectData)) {
            // add the unassigned item if there are sub projects and the subs don't account for all of the time
            const unassignedItem = getUnassignedItem(item);
            if (unassignedItem.totalHours > 0 || unassignedItem.totalCost > 0) {
              item.subprojectData = subprojectData.concat(unassignedItem);
            } else {
              item.subprojectData = subprojectData;
            }
          } else {
            item.subprojectData = subprojectData;
          }
        } else if (!_.isNil(item.subprojectData)) {
          // the project is collapsed but we have subproject data, so remove it
          clearSubprojectData(item);
        }

        return item;
      });
  }

  const getUnassignedItem = (parentRow: IProjectCostCodeActivityTableRowInfo): IProjectCostCodeActivityTableRowInfo => {
    const unassignedKey = encryptUnassignedProjectId(parentRow.project!.id) + parentRow.costCodeId;
    const descendantData = parentRow.descendantData!;
    const detailRows = parentRow.detailRows
      ? mapNotNull(parentRow.detailRows, (detailRow) => {
        const unassignedRow = getUnassignedItem(detailRow);
        if (unassignedRow.totalHours > 0 || unassignedRow.totalCost > 0) {
          return unassignedRow;
        }

        return null;
      })
      : undefined;

    return {
      ...parentRow,
      id: unassignedKey,
      regularHours: parentRow.regularHours - descendantData.regularSeconds,
      regularHoursDec: parentRow.regularHoursDec - descendantData.regularSeconds,
      overtimeHours: parentRow.overtimeHours - descendantData.overtimeSeconds,
      overtimeHoursDec: parentRow.overtimeHoursDec - descendantData.overtimeSeconds,
      doubleTimeHours: parentRow.doubleTimeHours - descendantData.doubleTimeSeconds,
      doubleTimeHoursDec: parentRow.doubleTimeHoursDec - descendantData.doubleTimeCost,
      totalHours: parentRow.totalHours - descendantData.totalSeconds,
      totalHoursDec: parentRow.totalHoursDec - descendantData.totalSeconds,
      regularCost: parentRow.regularCost - descendantData.regularCost,
      overtimeCost: parentRow.overtimeCost - descendantData.overtimeCost,
      doubletimeCost: parentRow.doubletimeCost - descendantData.doubleTimeCost,
      laborBurden: parentRow.laborBurden - descendantData.laborBurden,
      totalCost: parentRow.totalCost - descendantData.totalCost,
      project: {
        ...parentRow.project!,
        id: encryptUnassignedProjectId(parentRow.project!.id),
        title: t('Unassigned'),
        depth: parentRow.project!.depth + 1,
        children: undefined,
      },
      isUnassigned: true,
      subprojectData: undefined,
      detailRows,
    };
  };

  function clearSubprojectData(projectData: IProjectCostCodeActivityTableRowInfo) {
    if (!_.isEmpty(projectData.subprojectData)) {
      projectData.subprojectData?.forEach((item) => clearSubprojectData(item));
    }

    // remove from selected ids in case the parent got toggled off and the child project was expanded
    selectedProjectIds.current = selectedProjectIds.current.filter((id) => id !== projectData.project!.id);

    projectData.subprojectData = undefined;
  }

  async function getExportDataWithSubprojects() {
    // in case there are expanded projects, filter them out
    // keep the no project item
    const rootProjects = data.filter((projectData) => isNil(projectData.project) || projectData.project?.depth === 1);
    const projectIds = mapNotNil(rootProjects, (project) => {
      if (!isEmpty(project.project?.children)) {
        return project.project?.id;
      } else {
        return null;
      }
    });

    const subprojectData = flattenForSubProjectExport(await updateDataWithSubprojectInfo(rootProjects, projectIds));
    if (remainingItemRef.current) {
      return subprojectData.concat(remainingItemRef.current);
    }

    return subprojectData;
  }

  const toggleSubProject = async (project: Project) => {
    selectedProjectIds.current = toggleSelection(selectedProjectIds.current, project.id);
    setData(await updateDataWithSubprojectInfo(data, selectedProjectIds.current));
  };

  function isProjectOpen(projectId: string) {
    return selectedProjectIds.current.some((pId) => {
      return pId === projectId;
    });
  }

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    toggleSubProject,
    isProjectOpen,
    remainingData: remainingItemRef.current,
    getExportDataWithSubprojects,
  };
}
