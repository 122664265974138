import { Button, Col, Label, Textarea } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { useOpenable } from 'hooks';
import { useRef, useState } from 'react';
import { t } from 'utils/localize';
import BulkSignOffConfirmDialog from './BulkSignOffConfirmDialog/BulkSignOffConfirmDialog';
import './DailySignOffResolveDialog.scss';

export interface IDailySignOffResolveDialogProps {
  className?: ClassName;
  isOpen: boolean;
  onClose: () => void;
  onSave: (comment: string) => void;
  isLatestEntry?: boolean;
}

export enum ResolveIssueOptions {
  TIME_CARD_UPDATED = 'time-card-updated',
  NO_CHANGE = 'no-change',
  REPORTED_TO_MANAGEMENT = 'resported-to-management',
  CUSTOM_RESPONSE = 'custom-response',
}

export interface IDailySignOffResolutionOptionData {
  resolutionOption: ResolveIssueOptions | null;
  customResponse?: string | null;
}

const DailySignOffResolveDialog = (props: IDailySignOffResolveDialogProps) => {
  const { className, isOpen, onClose, onSave, isLatestEntry } = props;

  const classes = classNames('daily-sign-off-resolve-dialog', className);
  const [data, setData] = useState<IDailySignOffResolutionOptionData>({
    resolutionOption: null,
    customResponse: null,
  });

  const bulkConfirmDialog = useOpenable();

  const resolutionOptions = useRef([
    {
      name: t('The employee’s time card was updated.'),
      id: ResolveIssueOptions.TIME_CARD_UPDATED,
    },
    {
      name: t('No change was needed.'),
      id: ResolveIssueOptions.NO_CHANGE,
    },
    {
      name: t('The error was reported to management.'),
      id: ResolveIssueOptions.REPORTED_TO_MANAGEMENT,
    },
    {
      name: t('Enter a custom reponse.'),
      id: ResolveIssueOptions.CUSTOM_RESPONSE,
    },
  ]);

  function handleSubmit() {
    if (isLatestEntry) {
      bulkConfirmDialog.open();
    } else {
      handleSave();
    }
  }

  function handleSave() {
    let comment = '';
    switch (data.resolutionOption) {
      case ResolveIssueOptions.TIME_CARD_UPDATED:
        comment = t('The employee’s time card was updated.');
        break;
      case ResolveIssueOptions.NO_CHANGE:
        comment = t('No change was needed.');
        break;
      case ResolveIssueOptions.REPORTED_TO_MANAGEMENT:
        comment = t('The error was reported to management.');
        break;
      case ResolveIssueOptions.CUSTOM_RESPONSE:
        comment = data.customResponse ?? '';
        break;
      default:
        break;
    }
    onSave(comment);
    onClose();
  }

  const resolutionPicker = () => {
    return (
      <Col className="m-5">
        <BasicPicker
          data={resolutionOptions.current}
          onSelect={(resolutionOption) => {
            setData({ ...data, resolutionOption });
          }}
          value={data.resolutionOption}
          className="status-selector"
          placeholder={t('Select resolution option')}
        />
      </Col>
    );
  };

  const customResponseTextArea = () => {
    return (
      <Textarea
        className="m-5 custom-response-text"
        onChange={(customResponse) => setData({ ...data, customResponse })}
        value={data.customResponse ?? ''}
        restrictTo={{ maxLength: 1000 }}
      ></Textarea>
    );
  };

  const submitButton = () => {
    return (
      <Button type="primary" className="m-5" onClick={handleSubmit}>
        {t('Submit')}
      </Button>
    );
  };

  return (
    <>
      <HeaderDialog className={classes} isOpen={isOpen} onClose={onClose} divider={false} title={t('Resolve Issue')}>
        <Label className="ml-5 mt-5">{t('How was the issue resolved?')}</Label>
        {resolutionPicker()}
        {data.resolutionOption === ResolveIssueOptions.CUSTOM_RESPONSE && customResponseTextArea()}
        {submitButton()}
      </HeaderDialog>
      <BulkSignOffConfirmDialog
        isOpen={bulkConfirmDialog.isOpen}
        onClose={bulkConfirmDialog.close}
        onSave={handleSave}
      />
    </>
  );
};

export default DailySignOffResolveDialog;
