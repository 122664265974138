import { Bar, Button, ButtonList, Justify, Position, Size, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { MoreButton } from 'components';
import { useToastOpen } from 'contexts/ToastContext';
import { useTimeEntryExport } from 'hooks/export/useTimeEntryExport';
import { IUsePagedCursorLoaderPayload } from 'hooks/utils/usePagedCursorLoader';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { useFeatureFlags } from 'utils/features';
import { downloadText } from 'utils/fileUtils';
import { t } from 'utils/localize';
import { ITimeEntryDataReportData } from '../TimeEntryDataReport/TimeEntryDataReport';
import './TimeEntryFilterBar.scss';

export interface ITimeEntryFilterBarProps {
  className?: ClassName;
  timeRange?: ITimeRange<DateTime>;
  data: ITimeEntryDataReportData[];
  loadedAll: boolean;
  loadAll: () => Promise<IUsePagedCursorLoaderPayload<ICursorable> | undefined> | undefined;
  exportFileName: string;
  renderFilters: (forPrint: boolean) => ReactNode | undefined;
}

const TimeEntryFilterBar = (props: ITimeEntryFilterBarProps) => {
  const { className, timeRange, data, loadedAll, loadAll, exportFileName, renderFilters } = props;

  const classes = classNames('time-entry-filter-bar', className);
  const getTimeEntryCsv = useTimeEntryExport();
  const errorToast = useToastOpen();
  const isPro = useFeatureFlags('PRO');

  function getTimeRangeFormat(timeRange: ITimeRange<DateTime>) {
    const today = DateTime.local();
    const sameYears = today.hasSame(timeRange.startTime, 'year') && today.hasSame(timeRange.endTime, 'year');
    return sameYears ? 'EEE, MMM d' : 'EEE, MMM d, yyyy';
  }

  const renderTimeRange = (timeRange: ITimeRange<DateTime>) => {
    const formattedStartTime = timeRange.startTime.toFormat(getTimeRangeFormat(timeRange));
    const formattedEndTime = timeRange.endTime.toFormat(getTimeRangeFormat(timeRange));

    if (formattedStartTime === formattedEndTime) {
      return <div className="time-range">{formattedStartTime}</div>;
    } else {
      return <div className="time-range">{formattedStartTime + ' - ' + formattedEndTime}</div>;
    }
  };

  function renderMoreContent(close: () => void) {
    const executeWithClose = (fn: () => void) => () => {
      fn();
      close();
    };

    return (
      <ButtonList>
        {isPro && <Button onClick={executeWithClose(exportReport)}>{t('Export')}</Button>}
        <Button onClick={executeWithClose(printReport)}>{t('Print')}</Button>
      </ButtonList>
    );
  }

  async function printReport() {
    if (!loadedAll) {
      await loadAll();
    }

    setTimeout(() => {
      window.print();
    }, 100);
  }

  async function exportReport() {
    if (!loadedAll) {
      await loadAll();
    }

    if (data.length !== 0) {
      const csv = getTimeEntryCsv(data);
      downloadText(csv, `${exportFileName}.csv`);
    } else {
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
    }
  }

  return (
    <div className={classes}>
      <Bar className="filter-bar mb-8 no-print" justify={Justify.SPACE_BETWEEN} size={Size.GROW}>
        <Bar justify={Justify.FLEX_START}>{renderFilters(false)}</Bar>
        <MoreButton
          className="mr-2"
          position={Position.BOTTOM_END}
          size={Size.MEDIUM}
          renderContent={renderMoreContent}
        />
      </Bar>
      <div className="print my-5">
        {renderFilters(true)}
        {timeRange && renderTimeRange(timeRange)}
      </div>
    </div>
  );
};

export default TimeEntryFilterBar;
