import { SIMPLE_SUBMISSIONS_REPORT_QUERY } from 'apollo/queries/member-sign-in-submission';
import gql from 'graphql-tag';
import { useApolloPaging } from 'hooks';
import useGetOrganizationSignInQuestions from 'hooks/models/organization-sign-in-questions/useGetOrganizationSignInQuestions';
import { difference, groupBy, sumBy, uniq, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import { IMemberSignInSubmission } from 'types/MemberSignInSubmission';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { dateTimeFromISOKeepZone, getDateTimesBetween } from 'utils/dateUtils';
import { memberHasSignInQuestions } from 'utils/organizationSignInQuestionUtils';

export interface ISignInAggregatedInfo {
  missingSignIns: number;
  flaggedSignIns: number;
}

export default function useDailySignInData(timeRange: ITimeRange<DateTime>) {
  const { getAll } = useApolloPaging();
  const [error, setError] = useState<string | null>(null);
  const [aggregatedInfo, setAggregatedInfo] = useState<ISignInAggregatedInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refetch, setRefetch] = useState<boolean>(false);
  const getOrgSignInQuestions = useGetOrganizationSignInQuestions();

  useMemo(async () => {
    const submissionsQuery = getAll<IMemberSignInSubmission & ICursorable>('memberSignInSubmissions', {
      query: SIMPLE_SUBMISSIONS_REPORT_QUERY,
      variables: {
        first: 500,
        filter: {
          localTime: {
            lessThanOrEqual: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            greaterThanOrEqual: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          member: {
            permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and', includeSelf: true },
          },
          deletedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });

    const timeRangeStart = timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false });
    const timeRangeEnd = timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false });
    const closedTimeEntriesQuery = getAll<ITimeEntry & ICursorable>('timeEntries', {
      query: SIGN_IN_TIME_ENTRIES_QUERY,
      variables: {
        first: 500,
        filter: {
          member: {
            permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and', includeSelf: true },
          },
          startTime: { lessThanOrEqual: timeRangeEnd },
          endTime: { greaterThanOrEqual: timeRangeStart },
          deletedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });

    const openTimeEntriesQuery = getAll<ITimeEntry & ICursorable>('timeEntries', {
      query: SIGN_IN_TIME_ENTRIES_QUERY,
      variables: {
        first: 500,
        filter: {
          member: {
            permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and', includeSelf: true },
          },
          startTime: { greaterThanOrEqual: timeRangeStart },
          endTime: { isNull: true },
          deletedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });

    const signInQuestionsQuery = getOrgSignInQuestions({ deletedOn: { isNull: true } });

    return await Promise.all([submissionsQuery, closedTimeEntriesQuery, openTimeEntriesQuery, signInQuestionsQuery])
      .then(async ([submissions, closedTimeEntries, openTimeEntries, signInQuestions]) => {
        const allTimeEntriesByDay = groupBy(closedTimeEntries.concat(openTimeEntries), (timeEntry) =>
          dateTimeFromISOKeepZone(timeEntry.startTime).toISODate().toString()
        );

        const submissionsByDate = groupBy(submissions, (submission) =>
          dateTimeFromISOKeepZone(submission.localTime).toISODate().toString()
        );
        const dates = getDateTimesBetween(timeRange.startTime, timeRange.endTime);

        const missingSignIns = sumBy(dates, (date) => {
          const dateKey = date.toISODate().toString();
          const timeEntriesInDay = allTimeEntriesByDay[dateKey] ?? [];
          const membersWithTime = uniqBy(
            timeEntriesInDay.map((timeEntry) => timeEntry.member),
            (member) => member.id
          );
          const signInApplicableMemberIds = mapNotNull(membersWithTime, (member) => {
            // check if the member can sign in
            if (memberHasSignInQuestions(member, signInQuestions)) {
              return member.id;
            }

            return null;
          });

          const submissionsOnDate = submissionsByDate[dateKey] ?? [];
          const submittedMemberIds = uniq(submissionsOnDate.map((submission) => submission.memberId));

          const unsignedCount = difference(signInApplicableMemberIds, submittedMemberIds).length;
          return unsignedCount > 0 ? unsignedCount : 0; // guard against negative in case of member signed in but then deleted time entries
        });

        const flaggedSignIns = submissions.filter((submission) => submission.flagged).length;

        setAggregatedInfo({
          missingSignIns,
          flaggedSignIns,
        });
        setError(null);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, timeRange]);

  return { aggregatedInfo, error, isLoading, setRefetch, refetch };
}

export const SIGN_IN_TIME_ENTRIES_QUERY = gql`
  query SignInTimeEntries($after: String, $filter: TimeEntryFilter, $first: Int, $sort: [TimeEntrySort!]) {
    timeEntries(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      startTime
      endTime
      updatedOn
      cursor
      member {
        id
        positionId
      }
    }
  }
`;
