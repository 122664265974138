import { useMutation } from '@apollo/client';
import { CREATE_MEMBER_SIGN_IN_ANSWER, UPDATE_MEMBER_SIGN_IN_ANSWER } from 'apollo/mutations/member-sign-in-answer';
import {
  CREATE_MEMBER_SIGN_IN_SUBMISSION,
  UPDATE_MEMBER_SIGN_IN_SUBMISSION,
} from 'apollo/mutations/member-sign-in-submission';
import { first } from 'lodash';
import { DateTime } from 'luxon';
import { IMemberSignInAnswer } from 'types/MemberSignInAnswer';
import { filterNil } from 'utils/collectionUtils';
import { getNowIsoAtUtcWithLocalTimeZone, offsetInSeconds } from 'utils/dateUtils';
import { buildChoiceObjectFromJSON } from 'utils/organizationSignInQuestionUtils';
import { uuid } from 'utils/uuidUtils';
import {
  MemberSignInAnswerCreateInput,
  MemberSignInAnswerUpdateInput,
  MemberSignInSubmissionCreateInput,
  MemberSignInSubmissionUpdateInput,
  OrganizationSignInQuestion,
  OrganizationSignInQuestionAudience,
} from '__generated__/graphql';
import useActiveMember from '../../store/useActiveMember';

export interface IOrganizationSignInQuestionItemData {
  question: OrganizationSignInQuestion;
  answer?: string;
  description?: string;
}

export interface IOrganizationSignInQuestionItemState {
  byId: {
    [key: string]: IOrganizationSignInQuestionItemData;
  };
}

export interface ISignInQuestionChoice {
  choice: string;
  flag: boolean;
}

export default function useMemberSignIn() {
  const [createSubmissionMutation] = useMutation(CREATE_MEMBER_SIGN_IN_SUBMISSION);
  const [updateSubmissionMutation] = useMutation(UPDATE_MEMBER_SIGN_IN_SUBMISSION);
  const [createAnswerMutation] = useMutation(CREATE_MEMBER_SIGN_IN_ANSWER);
  const [updateAnswerMutation] = useMutation(UPDATE_MEMBER_SIGN_IN_ANSWER);

  const activeMember = useActiveMember();

  async function createSubmissionWithAnswers(
    memberId: string,
    data: IOrganizationSignInQuestionItemState,
    date?: DateTime
  ) {
    const submissionDate = date ?? DateTime.local();
    const createdOn = getNowIsoAtUtcWithLocalTimeZone();
    const createdOnDst = DateTime.local().isInDST;
    const createdOnOffset = offsetInSeconds(DateTime.local());
    const localTime = submissionDate.toISO();
    const submissionId = uuid();

    const answers = Object.keys(data.byId).map((id) => {
      const item = data.byId[id];

      const flagged = buildChoiceObjectFromJSON(item.question.choices).some((choice) => {
        return choice.flag && choice.choice === item.answer;
      });

      const restricted = buildChoiceObjectFromJSON(item.question.choices).some((choice) => {
        return choice.restrict && choice.choice === item.answer;
      });

      const questionPrompt = first(
        filterNil(
          buildChoiceObjectFromJSON(item.question.choices).flatMap((choice) => {
            if (choice.choice === item.answer) {
              return choice.followup;
            }
            return undefined;
          })
        )
      );

      return {
        memberId,
        memberSignInSubmissionId: submissionId,
        description: item.description,
        answer: item.answer,
        questionTitle: item.question.questionTitle,
        questionDescription: item.question.questionDescription,
        questionPrompt,
        flagged,
        localTime,
        createdOn,
        audienceType: item.question.audienceType,
        positionId:
          item.question.audienceType === OrganizationSignInQuestionAudience.Position
            ? item.question.positionId
            : undefined,
        restricted,
      } as MemberSignInAnswerCreateInput;
    });

    const submission: MemberSignInSubmissionCreateInput = {
      id: submissionId,
      memberId,
      proxyMemberId: activeMember.id!,
      flagged: answers.some((answer) => answer.flagged),
      deviceType: 'Web Browser',
      createdOnOffset,
      createdOnDst,
      localTime,
      createdOn,
      restricted: answers.some((answer) => answer.restricted),
    };

    await createSubmissionMutation({ variables: { submission } });

    await Promise.all(
      answers.map((answer) => {
        return createAnswerMutation({ variables: { answer } });
      })
    );
    return submissionId;
  }

  async function resolveAnswer(id: string, comment: string) {
    const answer: MemberSignInAnswerUpdateInput = {
      id,
      resolverId: activeMember.id,
      resolvedOn: DateTime.local().toISO(),
      resolvedComment: comment,
    };
    const results = await updateAnswerMutation({ variables: { answer } });
    return results.data.updateMemberSignInAnswer as IMemberSignInAnswer;
  }

  async function resolveSubmission(id: string) {
    const submission: MemberSignInSubmissionUpdateInput = {
      id,
      resolverId: activeMember.id,
      resolvedOn: DateTime.local().toISO(),
    };
    await updateSubmissionMutation({ variables: { submission } });
  }

  return { createSubmissionWithAnswers, resolveAnswer, resolveSubmission };
}
