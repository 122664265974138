import { useApolloClient } from '@apollo/client';
import { Button, Icon, Label, Size, Theme, Toast } from '@busybusy/webapp-react-ui';
import { ChevronRightIcon, DailyReportsIconOutlined, PDFIconRed, SchedulingIcon } from 'assets/icons';
import { pdf_icon } from 'assets/images';
import classNames from 'classnames';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import StateTemplate from 'components/foundation/state-templates/StateTemplate/StateTemplate';
import { DAILY_REPORT_QUERY } from 'containers/daily-project-report/daily-report-queries';
import DashboardProUpgradeCard from 'containers/dashboard/dashboard-grid/card/DashboardProUpgradeCard/DashboardProUpgradeCard';
import { useToast } from 'hooks';
import useDailyProjectReportNavigation from 'hooks/navigation/useDailyProjectReport';
import { useCheckForObjects } from 'hooks/utils/useCheckForObjects/useCheckForObjects';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { IDailyProjectReport } from 'types';
import { ClassName } from 'types/ClassName';
import TimeRangeType from 'types/TimeRangeType';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import './DailyProjectReportCard.scss';

export interface IDailyProjectReportCardProps {
  className?: ClassName;
  projectId?: string;
  theme: Theme.DARK | Theme.LIGHT;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
}

const DailyProjectReportCard = (props: IDailyProjectReportCardProps) => {
  const { className, projectId, theme, setting, onHide } = props;

  const classes = classNames('daily-project-report-card', theme, className);

  const isPro = useFeatureFlags('PRO');
  const { isOpen: isToastOpen, open: openToast, close: closeToast, message: toastMessage } = useToast();
  const client = useApolloClient();
  const [data, setData] = useState<IDailyProjectReport[]>([]);
  const today = DateTime.local();
  const dailyProjectReportNavigation = useDailyProjectReportNavigation();
  const [showPremiumEmptyState, setShowPremiumEmptyState] = useState(false);
  const getFirstReport = useCheckForObjects(DAILY_REPORT_QUERY, 'dailyProjectReports');

  useEffect(() => {
    checkForReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkForReports() {
    const reports = await getFirstReport();
    if (reports.length === 0) {
      setShowPremiumEmptyState(true);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const getData = async () => {
    const entriesForToday = await client.query<{ dailyProjectReports: IDailyProjectReport[] }>({
      query: DAILY_REPORT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 4,
        filter: {
          project: projectId ? { idWithDescendants: { equal: projectId } } : undefined,
          createdByMember: { permissions: { permissions: 'timeEvents', operationType: 'and', includeSelf: true } },
          deletedOn: { isNull: true },
        },
        sort: [{ reportDate: 'desc' }, { createdOn: 'desc' }],
      },
    });

    if (entriesForToday.errors) {
      openToast(t('Could not load report.'), Theme.DANGER);
    }

    const reports = entriesForToday.data.dailyProjectReports;

    setData(reports);
  };

  const renderReport = (report: IDailyProjectReport) => {
    const reportDate = dateTimeFromISOWithoutZone(report.reportDate!);
    let reportDateString = reportDate.toFormat('EEE, MMM d');

    if (!today.hasSame(reportDate, 'year') && !today.hasSame(reportDate, 'year')) {
      reportDateString = reportDate.toFormat('EEE, MMM d, yyyy');
    }

    function handleClick() {
      window.open(report.fileUrl);
    }

    return (
      <div key={report.id} className="report-item" onClick={handleClick}>
        <div className="report-icon-container">
          <span className="pdf-icon">
            {theme === Theme.LIGHT ? <img src={pdf_icon} alt="" /> : <Icon svg={PDFIconRed} size={Size.GROW} />}
          </span>
        </div>
        <div className="report-information-container">
          <Label className="report-information-item-title">{reportDateString}</Label>
          <Label className="report-information-item">
            {report.project ? getFormattedPathFromProject(report.project, true) : '---'}
          </Label>
          <Label className="report-information-item">
            {report.createdByMember ? fullName(report.createdByMember) : '---'}
          </Label>
        </div>
        <div className="row-disclosure-indicator">
          <Icon svg={ChevronRightIcon} />
        </div>
      </div>
    );
  };

  const renderReports = () => {
    if (data.length > 0) {
      return data.map(renderReport);
    } else {
      if (showPremiumEmptyState) {
        return (
          <StateTemplate
            className="empty-state"
            graphic={<Icon svg={DailyReportsIconOutlined} size={Size.XL} />}
            title={t('No Reports')}
            footer={
              <Button type={'primary'} className={'mt-6'} onClick={openDPRFeed}>
                {t(`Explore Field Reports`)}
              </Button>
            }
          />
        );
      } else {
        return <StateTemplate className="empty-state" title={t('No Reports')} />;
      }
    }
  };

  function openDPRFeed() {
    const timeRangeType = TimeRangeType.ALL_TIME;
    dailyProjectReportNavigation({ time_range_type: timeRangeType });
  }

  return (
    <>
      {isPro ? (
        <DashboardContentCard
          header={<Label className={classNames('daily-reports-card-title', theme)}>{t('Field Reports')}</Label>}
          rightLabel={t('View All')}
          onRightLabelClick={openDPRFeed}
          theme={theme}
          tabColor="#BD5350"
          divider={true}
        >
          <>
            <div className={classNames(classes, 'mb-6')}>{renderReports()}</div>

            <Toast isOpen={isToastOpen} onClose={closeToast} theme={Theme.DANGER}>
              {toastMessage}
            </Toast>
          </>
        </DashboardContentCard>
      ) : (
        <DashboardProUpgradeCard title={setting.title} svg={SchedulingIcon} theme={theme} onHide={onHide} />
      )}
    </>
  );
};

export default DailyProjectReportCard;
