import { Avatar, Button, ButtonList, Divider, Icon, Label, Size } from '@busybusy/webapp-react-ui';
import { AccountIcon, AddCircleIcon, CompanyIcon, LogOutIcon, PreferencesIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { reactQueryClient } from 'components/middleware/ReactQueryMiddleware';
import { useActiveMember, useOrganization } from 'hooks';
import useCompany, { ICompany } from 'hooks/models/organization/useCompany';
import useLegacyLogoutNavigation from 'hooks/navigation/useLegacyLogoutNavigation';
import useLoginNavigation from 'hooks/navigation/useLoginNavigation';
import useLogoutNavigation from 'hooks/navigation/useLogoutNavigation';
import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authenticationClearTokenAction, authenticationSetTokenAction } from 'store/actions';
import { StoreAuthMemberProper } from 'store/types';
import { executeFunctionsCurried } from 'utils/functionUtils';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import { isBuiltLocally } from 'utils/metaUtils';
import './CompanyNavigationMenu.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface ICompanyNavigationMenuProps {
  className?: ClassName;
  onMyAccountClick: () => void;
  onPreferencesClick: () => void;
  closeMenu: () => void;
}

const CompanyNavigationMenu = ({
  className,
  onMyAccountClick,
  onPreferencesClick,
  closeMenu,
}: ICompanyNavigationMenuProps) => {
  const activeMember = useActiveMember();
  const organization = useOrganization();
  const { companies } = useCompany();
  const navigate = useNavigate();
  const loginNavigation = useLoginNavigation();
  const logoutNavigation = useLogoutNavigation();
  const navigateToLegacyLogout = useLegacyLogoutNavigation();
  const dispatch = useDispatch();
  const isInIframe = useIsInIframe();

  const actionLogOut = () => {
    reactQueryClient.invalidateQueries();
    if (isBuiltLocally()) {
      logoutNavigation();
    } else {
      navigateToLegacyLogout();
    }
  };

  const actionAddCompany = () => {
    dispatch(authenticationClearTokenAction());
    loginNavigation({ switch: true, exitToLegacy: false });
    window.location.reload();
  };

  const actionChangeCompany = (company: ICompany) => {
    closeMenu();

    // switch companies if the selected company is different than the one that is currently active
    if (company.organization.id !== organization.id) {
      reactQueryClient.invalidateQueries();
      dispatch(authenticationSetTokenAction(company.token));
      navigate(`/`);
    }
  };

  const renderHeader = (member: StoreAuthMemberProper) => {
    return (
      <div className="company-navigation-menu-header">
        <Avatar
          className="mb-4"
          firstName={member.firstName ?? ''}
          lastName={member.lastName ?? ''}
          size={Size.MEDIUM}
          image={member.imageUrl ?? undefined}
        />
        <Label>{fullName(member)}</Label>
        {organization.organizationName}
      </div>
    );
  };

  const renderActions = () => {
    return (
      <>
        <ButtonList>
          <Button onClick={executeFunctionsCurried(onMyAccountClick, closeMenu)}>
            <Icon className="mr-4" svg={AccountIcon} size={Size.MEDIUM} />
            {t('My Account')}
          </Button>
          <Button onClick={executeFunctionsCurried(onPreferencesClick, closeMenu)}>
            <Icon className="mr-4" svg={PreferencesIcon} size={Size.MEDIUM} />
            {t('Preferences')}
          </Button>
          <Button onClick={actionLogOut}>
            <Icon className="mr-4" svg={LogOutIcon} size={Size.MEDIUM} />
            {t('Log Out')}
          </Button>
        </ButtonList>
      </>
    );
  };

  const renderCompanies = () => {
    const companyButton = (company: ICompany) => {
      return (
        <Button key={company.organization.id} onClick={() => actionChangeCompany(company)}>
          <Icon className="mr-4" svg={CompanyIcon} size={Size.MEDIUM} />
          {company.organization.organizationName}
        </Button>
      );
    };

    return (
      <>
        <Label className="company-text">{t('Companies')}</Label>
        <ButtonList>
          {sortBy(companies, (company) => company.organization.organizationName).map(companyButton)}
          <Button onClick={actionAddCompany}>
            <Icon className="mr-4" svg={AddCircleIcon} size={Size.MEDIUM} />
            {t('Login to Another Company')}
          </Button>
        </ButtonList>
      </>
    );
  };

  const classes = classNames('company-navigation-menu', className);

  return (
    <div className={classes}>
      {activeMember && renderHeader(activeMember)}
      <Divider />
      {renderActions()}
      {!isInIframe && (
        <>
          <Divider />
          {renderCompanies()}
        </>
      )}
    </div>
  );
};

export default CompanyNavigationMenu;
