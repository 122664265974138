import { useApolloClient } from '@apollo/client';
import { TIME_ENTRY_BY_ID_QUERY } from 'apollo/queries/time-entry-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import { first, isNil } from 'lodash';
import { FunctionComponent, useEffect, useState } from 'react';
import ITimeEntry from 'types/TimeEntry';
import { dateUtils } from 'utils';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { fullName } from 'utils/memberUtils';
import { logError } from 'utils/testUtils';
import { breakTotalInRange } from 'utils/timeEntryUtils';
import { formatTime } from 'utils/timeUtils';
import SplitTimeEntryForm from '../time-actions-form/SplitTimeEntryForm/SplitTimeEntryForm';
import './TimeEntrySplitDialog.scss';

export interface ITimeEntrySplitDialogProps {
  className?: ClassName;
  timeEntryId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const TimeEntrySplitDialog: FunctionComponent<ITimeEntrySplitDialogProps> = (props) => {
  const { className, timeEntryId, isOpen, onClose, onSubmit } = props;

  const [timeEntry, setTimeEntry] = useState<ITimeEntry>();
  const [isMultiDay, setIsMultiDay] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    if (!isNil(timeEntryId)) {
      getTimeEntryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntryId]);

  useEffect(() => {
    if (!isNil(timeEntry)) {
      const startTimeDateObject = dateTimeFromISOKeepZone(timeEntry.startTime!);
      const startTimeDay = startTimeDateObject.startOf('day');
      const endTimeDateObject = dateTimeFromISOKeepZone(timeEntry.endTime!);
      const endTimeDay = endTimeDateObject.startOf('day');
      setIsMultiDay(startTimeDay.toSeconds() !== endTimeDay.toSeconds());
    }
  }, [timeEntry]);

  async function getTimeEntryData() {
    const results = await client
      .query<{ timeEntries: ITimeEntry[] }>({
        query: TIME_ENTRY_BY_ID_QUERY,
        variables: { timeEntryId },
        fetchPolicy: 'network-only',
      })
      .catch(logError);

    setTimeEntry(first(results.data.timeEntries));
  }

  const classes = classNames('time-entry-split-dialog', className);

  return (
    <HeaderDialog className={classes} isOpen={isOpen} title={t('Split Entry')} onClose={onClose} divider={false}>
      <div className={'split-dialog-container px-8'}>
        <span className={'member-name'}>{timeEntry ? fullName(timeEntry.member) : undefined}</span>
        <span className={'time-entry-range'}>
          {timeEntry && (
            <>
              <TimeAndSuperscript
                dateTime={dateTimeFromISOKeepZone(timeEntry.startTime)}
                dst={timeEntry.daylightSavingTime}
              />
              {isMultiDay && ` (${dateTimeFromISOKeepZone(timeEntry.startTime).toFormat('LLL dd')})`}
              {timeEntry.endTime ? (
                <>
                  {' - '}
                  <TimeAndSuperscript
                    dateTime={dateTimeFromISOKeepZone(timeEntry.endTime!)}
                    dst={timeEntry.daylightSavingTime}
                  />
                  {isMultiDay && ` (${dateTimeFromISOKeepZone(timeEntry.endTime).toFormat('LLL dd')})`}
                </>
              ) : (
                ''
              )}
              {timeEntry.breaks.length > 0 && (
                <span className={'px-2'}>
                  {'(-'}
                  {formatTime({
                    type: 'TIME',
                    seconds: breakTotalInRange(
                      {
                        startTime: dateUtils.dateTimeFromISOKeepZone(timeEntry.startTime),
                        endTime: dateUtils.dateTimeFromISOKeepZone(timeEntry.endTime!),
                      },
                      timeEntry
                    ),
                  })}
                  {')'}
                </span>
              )}
            </>
          )}
        </span>
      </div>
      <div>{timeEntry && <SplitTimeEntryForm timeEntry={timeEntry} isMultiDay={isMultiDay} onSubmit={onSubmit} />}</div>
    </HeaderDialog>
  );
};

export default TimeEntrySplitDialog;
