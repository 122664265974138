import { reduce } from 'lodash';
import TimeOffType from 'types/enum/TimeOffType';
import ITimeOff from 'types/TimeOff';
import { Nullable } from 'types/util/Nullable';
import { t } from './localize';
import { add } from './numberUtils';

export function getTimeOffTypeTitle(type: TimeOffType) {
  switch (type) {
    case TimeOffType.HOLIDAY:
      return t('Holiday');
    case TimeOffType.PERSONAL:
      return t('Personal');
    case TimeOffType.SICK:
      return t('Sick');
    case TimeOffType.VACATION:
      return t('Vacation');
    case TimeOffType.OTHER:
      return t('Other');
    default:
      return t('Other');
  }
}

export function getPaidSecondsFromTimeOff<T extends Pick<ITimeOff, 'paid' | 'seconds'>>(timeOff: T) {
  if (timeOff.paid) {
    return timeOff.seconds ?? 0;
  }

  return 0;
}

export function getUnpaidSecondsFromTimeOff<T extends Pick<ITimeOff, 'paid' | 'seconds'>>(timeOff: T) {
  if (!timeOff.paid) {
    return timeOff.seconds ?? 0;
  }

  return 0;
}

export function getSecondsFromTimeOff<T extends Pick<ITimeOff, 'paid' | 'seconds'>>(
  timeOff: T,
  paid: Nullable<boolean>
) {
  if (paid === true) {
    return getPaidSecondsFromTimeOff(timeOff);
  } else if (paid === false) {
    return getUnpaidSecondsFromTimeOff(timeOff);
  } else {
    return timeOff.seconds ?? 0;
  }
}

export type AggregatedTimeOff = {
  holidaySeconds: number;
  otherSeconds: number;
  personalSeconds: number;
  sickSeconds: number;
  vacationSeconds: number;
};

export function aggregateTimeOffByType<T extends Pick<ITimeOff, 'paid' | 'seconds' | 'type'>>(
  timeOffs: T[],
  paid: Nullable<boolean>
): AggregatedTimeOff {
  return reduce(
    timeOffs,
    (acc, cur) => ({
      holidaySeconds: add(acc.holidaySeconds, cur.type === TimeOffType.HOLIDAY ? getSecondsFromTimeOff(cur, paid) : 0),
      otherSeconds: add(acc.otherSeconds, cur.type === TimeOffType.OTHER ? getSecondsFromTimeOff(cur, paid) : 0),
      personalSeconds: add(
        acc.personalSeconds,
        cur.type === TimeOffType.PERSONAL ? getSecondsFromTimeOff(cur, paid) : 0
      ),
      sickSeconds: add(acc.sickSeconds, cur.type === TimeOffType.SICK ? getSecondsFromTimeOff(cur, paid) : 0),
      vacationSeconds: add(
        acc.vacationSeconds,
        cur.type === TimeOffType.VACATION ? getSecondsFromTimeOff(cur, paid) : 0
      ),
    }),
    {
      holidaySeconds: 0,
      otherSeconds: 0,
      personalSeconds: 0,
      sickSeconds: 0,
      vacationSeconds: 0,
    }
  );
}
