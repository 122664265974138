import { ClassName } from '@busybusy/webapp-react-ui';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { t } from 'utils/localize';
import EquipmentMissingForm, { IEquipmentMissingFormData } from '../EquipmentMissingForm/EquipmentMissingForm';

export interface IEquipmentMissingDialogProps {
  categoryId?: string;
  makeId?: string;
  modelTitle?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data?: IEquipmentMissingFormData) => void;
  className?: ClassName;
}

const EquipmentMissingDialog = (props: IEquipmentMissingDialogProps) => {
  const { className, categoryId, makeId, modelTitle, isOpen, onClose, onSubmit } = props;

  return (
    <HeaderDialog
      className={className}
      isOpen={isOpen}
      title={t('Request to Add Equipment')}
      onClose={onClose}
      divider={false}
    >
      <div>
        <EquipmentMissingForm categoryId={categoryId} makeId={makeId} modelTitle={modelTitle} onSubmit={onSubmit} />
      </div>
    </HeaderDialog>
  );
};

export default EquipmentMissingDialog;
