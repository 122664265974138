import { useMutation } from '@apollo/client';
import { Theme } from '@busybusy/webapp-react-ui';
import {
  CREATE_TIME_ENTRY_BREAKS,
  UPDATE_TIME_ENTRY_BREAK,
  UPDATE_TIME_ENTRY_BREAKS,
} from 'apollo/mutations/break-mutations';
import {
  CREATE_CLIENT_TIME_ENTRY_BREAK_LOG,
  CREATE_CLIENT_TIME_ENTRY_BREAK_LOGS,
} from 'apollo/mutations/client-time-entry-break-log-mutations';
import { CREATE_TIME_ENTRY_LOGS } from 'apollo/mutations/client-time-entry-log-mutations';
import { useToastOpen } from 'contexts/ToastContext';
import { useTimeRounding } from 'hooks';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import IClientTimeEntryBreakLog, { IClientTimeEntryBreakLogCreateInput } from 'types/ClientTimeEntryBreakLog';
import IClientTimeEntryLog, { IClientTimeEntryLogCreateInput } from 'types/ClientTimeEntryLog';
import BreakActionType from 'types/enum/BreakActionType';
import ClockAction from 'types/enum/ClockAction';
import ClockActionType from 'types/enum/ClockActionType';
import ClockType from 'types/enum/ClockType';
import ITimeEntry from 'types/TimeEntry';
import IBreak, { ITimeEntryBreakCreateInput, ITimeEntryBreakUpdateInput } from 'types/TimeEntryBreak';
import { dateUtils } from 'utils';
import { dateTimeFromISOKeepZone } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { uuid } from 'utils/uuidUtils';
import useActiveMember from '../../store/useActiveMember';

export interface ICreateBreakData {
  timeEntryId: string;
  entryLogId: string;
  startTime: DateTime;
  endTime: DateTime;
}

export interface IEntryAndLogId {
  timeEntryId: string;
  entryLogId: string;
}

export interface IEntryAndBreak {
  entry: ITimeEntry;
  brk: IBreak;
}

export default function useBreak() {
  const [updateEntryBreak] = useMutation(UPDATE_TIME_ENTRY_BREAK);
  const [createEntryBreaks] = useMutation<{ createTimeEntryBreaks: [IBreak] }>(CREATE_TIME_ENTRY_BREAKS);
  const [updateEntryBreaks] = useMutation<{ updateTimeEntryBreaks: [IBreak] }>(UPDATE_TIME_ENTRY_BREAKS);
  const [createBreakLog] = useMutation(CREATE_CLIENT_TIME_ENTRY_BREAK_LOG);
  const [createBreakLogs] = useMutation<{ createClientTimeEntryBreakLogs: [IClientTimeEntryBreakLog] }>(
    CREATE_CLIENT_TIME_ENTRY_BREAK_LOGS
  );
  const [createTimeEntryLogs] = useMutation<{
    createClientTimeEntryLogs: [IClientTimeEntryLog];
  }>(CREATE_TIME_ENTRY_LOGS);
  const member = useActiveMember();
  const { roundTime } = useTimeRounding();
  const errorToast = useToastOpen();
  interface IBreakAndLog {
    brk: IBreak;
    log: IClientTimeEntryBreakLog;
  }

  async function createBreak(entryAndLogIds: ICreateBreakData[]) {
    const currentUTC = DateTime.utc().toISO();
    const createdOn = currentUTC;

    const promises = _.chunk(entryAndLogIds, 50).map(async (chunkedItems) => {
      const breakData: ITimeEntryBreakCreateInput[] = [];
      const logData: IClientTimeEntryBreakLogCreateInput[] = [];

      for (const item of chunkedItems) {
        let start = dateUtils.dateTimeFromISOKeepZone(item.startTime.toISO());
        start = roundTime(start, ClockAction.BREAK_START);
        let end = dateUtils.dateTimeFromISOKeepZone(item.endTime.toISO());
        end = roundTime(end, ClockAction.BREAK_END);

        const entryBreak: ITimeEntryBreakCreateInput = {
          id: uuid(),
          timeEntryId: item.timeEntryId,
          startTime: start.toISO(),
          startDeviceTime: currentUTC,
          startTimeTrusted: ClockType.USER_CUSTOM,
          endTime: start.toSeconds() > end.toSeconds() ? end.plus({ day: 1 }).toISO() : end.toISO(),
          endDeviceTime: currentUTC,
          endTimeTrusted: ClockType.USER_CUSTOM,
          startTimeDst: start.isInDST,
          endTimeDst: end.isInDST,
          startLocationId: null,
          endLocationId: null,
          actionType: BreakActionType.BREAK_ADD_MANUAL,
          paidBreak: false,
          createdOn,
        };

        const log: IClientTimeEntryBreakLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryBreakId: entryBreak.id!,
          timeEntryId: item.timeEntryId,
          originalTimeEntryBreakId: entryBreak.id,
          clientTimeEntryLogId: item.entryLogId,
          startTime: entryBreak.startTime,
          startDeviceTime: _.isNil(entryBreak.startDeviceTime) ? currentUTC : entryBreak.startDeviceTime!,
          startTimeTrusted: _.isNil(entryBreak.startTimeTrusted) ? ClockType.USER_CUSTOM : entryBreak.startTimeTrusted,
          endTime: _.isNil(entryBreak.endTime) ? DateTime.local().toISO() : entryBreak.endTime,
          endDeviceTime: entryBreak.endDeviceTime,
          endTimeTrusted: entryBreak.endTimeTrusted,
          startTimeDst: entryBreak.startTimeDst,
          endTimeDst: entryBreak.endTimeDst,
          startLocationId: null,
          endLocationId: null,
          actionType: BreakActionType.BREAK_ADD_MANUAL,
          paidBreak: false,
          deviceType: t('Web Browser'),
          timeEntryBreakCreatedOn: entryBreak.createdOn,
          createdOn: currentUTC,
        };
        breakData.push(entryBreak);
        logData.push(log);
      }
      const result: Promise<IBreakAndLog[]> = new Promise(async (resolve, reject) => {
        const breakResults = await createEntryBreaks({ variables: { entryBreaks: breakData } });
        if (breakResults && _.isEmpty(breakResults.errors)) {
          const logResults = await createBreakLogs({ variables: { logs: logData } });

          if (logResults && _.isEmpty(logResults.errors)) {
            const logMap = _.groupBy(logResults.data?.createClientTimeEntryBreakLogs, 'timeEntryBreakId');
            const entryWithLogs = breakResults.data?.createTimeEntryBreaks.map((brk) => {
              const log = _.first(logMap[brk.id]);
              if (_.isNil(log)) return null;

              const entryAndLog: IBreakAndLog = {
                brk,
                log,
              };
              return entryAndLog;
            });

            const [failed, successful] = _.partition(entryWithLogs, _.isNull);
            if (failed.length === 0) {
              resolve(successful);
            } else {
              reject(failed);
            }
          } else {
            errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // log failure
            reject([]);
          }
        } else {
          errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // time entry
          reject([]);
        }
      });

      return result;
    });

    return (await Promise.all(promises)).flat();
  }

  async function beginBreak(entries: ITimeEntry[], startTime: DateTime) {
    let start = dateUtils.dateTimeFromISOKeepZone(startTime.toISO());
    start = roundTime(start, ClockAction.BREAK_START);
    const currentUTC = DateTime.utc().toISO();
    const createdOn = currentUTC;

    const promises = _.chunk(entries, 50).map(async (chunkedEntries) => {
      const breakData: ITimeEntryBreakCreateInput[] = [];
      const logData: IClientTimeEntryLogCreateInput[] = [];
      const breakLogData: IClientTimeEntryBreakLogCreateInput[] = [];

      for (const entry of chunkedEntries) {
        const entryBreak: ITimeEntryBreakCreateInput = {
          id: uuid(),
          timeEntryId: entry.id,
          startTime: start.toISO(),
          startDeviceTime: currentUTC,
          startTimeTrusted: ClockType.USER_CUSTOM,
          startTimeDst: start.isInDST,
          startLocationId: null,
          endLocationId: null,
          actionType: BreakActionType.BREAK_START,
          paidBreak: false,
          createdOn,
        };

        const entryLog: IClientTimeEntryLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryId: entry.id!,
          originalTimeEntryId: entry.id,
          memberId: entry.memberId,
          startTime: entry.startTime,
          startDeviceTime: entry.startDeviceTime!,
          startTimeTrusted: entry.startTimeTrusted ? entry.startTimeTrusted : ClockType.USER_CUSTOM,
          endTime: entry.endTime,
          endDeviceTime: entry.endDeviceTime,
          endTimeTrusted: entry.endTimeTrusted,
          daylightSavingTime: entry.daylightSavingTime,
          metaDaylightSavingTime: entry.metaDaylightSavingTime,
          description: entry.description,
          projectId: entry.project?.id,
          costCodeId: entry.costCode?.id,
          equipmentId: entry.equipment?.id,
          actionType: ClockActionType.EDIT_BREAKS,
          deviceType: t('Web Browser'),
          timeEntryCreatedOn: entry.createdOn,
          startLocationId: entry.startLocation?.id,
          timeEntryDeletedOn: isNil(entry.deletedOn) ? null : entry.deletedOn!,
          endLocationId: entry.endLocation?.id,
          createdOn: currentUTC,
        };

        const log: IClientTimeEntryBreakLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryBreakId: entryBreak.id!,
          timeEntryId: entry.id,
          originalTimeEntryBreakId: entryBreak.id,
          clientTimeEntryLogId: entryLog.id,
          startTime: entryBreak.startTime,
          startDeviceTime: _.isNil(entryBreak.startDeviceTime) ? currentUTC : entryBreak.startDeviceTime!,
          startTimeTrusted: _.isNil(entryBreak.startTimeTrusted) ? ClockType.USER_CUSTOM : entryBreak.startTimeTrusted,
          startTimeDst: entryBreak.startTimeDst,
          startLocationId: null,
          endLocationId: null,
          actionType: BreakActionType.BREAK_START,
          paidBreak: false,
          deviceType: t('Web Browser'),
          timeEntryBreakCreatedOn: entryBreak.createdOn,
          createdOn: currentUTC,
        };
        breakData.push(entryBreak);
        logData.push(entryLog);
        breakLogData.push(log);
      }

      const result: Promise<IBreakAndLog[]> = new Promise(async (resolve, reject) => {
        const breakResults = await createEntryBreaks({ variables: { entryBreaks: breakData } });
        const entryLogsResults = await createTimeEntryLogs({ variables: { logs: logData } });
        if (breakResults && _.isEmpty(breakResults.errors) && entryLogsResults) {
          const breakLogResults = await createBreakLogs({ variables: { logs: breakLogData } });

          if (breakLogResults && _.isEmpty(breakLogResults.errors)) {
            const logMap = _.groupBy(breakLogResults.data?.createClientTimeEntryBreakLogs, 'timeEntryBreakId');
            const entryWithLogs = breakResults.data?.createTimeEntryBreaks.map((brk) => {
              const log = _.first(logMap[brk.id]);
              if (_.isNil(log)) return null;

              const entryAndLog: IBreakAndLog = {
                brk,
                log,
              };
              return entryAndLog;
            });

            const [failed, successful] = _.partition(entryWithLogs, _.isNull);
            if (failed.length === 0) {
              resolve(successful);
            } else {
              reject(failed);
            }
          } else {
            errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // log failure
            reject([]);
          }
        } else {
          errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // time entry
          reject([]);
        }
      });

      return result;
    });
    return (await Promise.all(promises)).flat();
  }

  async function endBreak(entryAndBreaks: IEntryAndBreak[], endTime: DateTime) {
    const currentUTC = DateTime.utc().toISO();

    const promises = _.chunk(entryAndBreaks, 50).map(async (chunkedEntries) => {
      const breakData: ITimeEntryBreakUpdateInput[] = [];
      const logData: IClientTimeEntryLogCreateInput[] = [];
      const breakLogData: IClientTimeEntryBreakLogCreateInput[] = [];

      for (const entryAndBreak of chunkedEntries) {
        const currentStartTime = dateTimeFromISOKeepZone(entryAndBreak.brk.startTime).set({
          second: 0,
          millisecond: 0,
        });

        let end = endTime
          .setZone(currentStartTime.zoneName, { keepLocalTime: false })
          .set({ second: 0, millisecond: 0 });
        end = roundTime(end, ClockAction.BREAK_END).set({ second: 0, millisecond: 0 });

        const updateBreak: ITimeEntryBreakUpdateInput = {
          id: entryAndBreak.brk.id,
          startTime: currentStartTime.toISO(),
          endTime: end < currentStartTime ? currentStartTime.toISO() : end.toISO(),
          endDeviceTime: currentUTC,
          endTimeTrusted: ClockType.USER_CUSTOM,
          endTimeDst: end.isInDST,
          actionType: BreakActionType.BREAK_END,
        };
        const entryLog: IClientTimeEntryLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryId: entryAndBreak.entry.id!,
          originalTimeEntryId: entryAndBreak.entry.id,
          memberId: entryAndBreak.entry.memberId,
          startTime: entryAndBreak.entry.startTime,
          startDeviceTime: entryAndBreak.entry.startDeviceTime!,
          startTimeTrusted: entryAndBreak.entry.startTimeTrusted
            ? entryAndBreak.entry.startTimeTrusted
            : ClockType.USER_CUSTOM,
          endTime: entryAndBreak.entry.endTime,
          endDeviceTime: entryAndBreak.entry.endDeviceTime,
          endTimeTrusted: entryAndBreak.entry.endTimeTrusted,
          daylightSavingTime: entryAndBreak.entry.daylightSavingTime,
          metaDaylightSavingTime: entryAndBreak.entry.metaDaylightSavingTime,
          actionType: ClockActionType.EDIT_BREAKS,
          deviceType: t('Web Browser'),
          timeEntryCreatedOn: entryAndBreak.entry.createdOn,
          timeEntryDeletedOn:
            entryAndBreak.entry.deletedOn === undefined || entryAndBreak.entry.deletedOn === null
              ? null
              : entryAndBreak.entry.deletedOn!,
          startLocationId: entryAndBreak.entry.startLocation?.id,
          endLocationId: entryAndBreak.entry.endLocation?.id,
          createdOn: currentUTC,
          description: entryAndBreak.entry.description,
          projectId: entryAndBreak.entry.project?.id,
          costCodeId: entryAndBreak.entry.costCode?.id,
          equipmentId: entryAndBreak.entry.equipment?.id,
        };

        const log: IClientTimeEntryBreakLogCreateInput = {
          id: uuid(),
          updaterMemberId: member.id!,
          timeEntryBreakId: entryAndBreak.brk.id!,
          timeEntryId: entryAndBreak.entry.id,
          originalTimeEntryBreakId: entryAndBreak.brk.id!,
          clientTimeEntryLogId: entryLog.id,
          startTime: entryAndBreak.brk.startTime,
          startDeviceTime: isNil(entryAndBreak.brk.startDeviceTime) ? currentUTC : entryAndBreak.brk.startDeviceTime!,
          startTimeTrusted: isNil(entryAndBreak.brk.startTimeTrusted)
            ? ClockType.USER_CUSTOM
            : entryAndBreak.brk.startTimeTrusted,
          endTime: end < currentStartTime ? entryAndBreak.brk.startTime : end.toISO(),
          endDeviceTime: currentUTC,
          endTimeTrusted: ClockType.USER_CUSTOM,
          startTimeDst: entryAndBreak.brk.startTimeDst,
          endTimeDst: end.isInDST,
          actionType: BreakActionType.BREAK_END,
          paidBreak: false,
          deviceType: t('Web Browser'),
          timeEntryBreakCreatedOn: entryAndBreak.brk.createdOn,
          createdOn: currentUTC,
        };

        breakData.push(updateBreak);
        logData.push(entryLog);
        breakLogData.push(log);
      }

      const result: Promise<IBreakAndLog[]> = new Promise(async (resolve, reject) => {
        const breakResults = await updateEntryBreaks({ variables: { entryBreaks: breakData } });
        const entryLogsResults = await createTimeEntryLogs({ variables: { logs: logData } });
        if (breakResults && _.isEmpty(breakResults.errors) && entryLogsResults) {
          const breakLogResults = await createBreakLogs({ variables: { logs: breakLogData } });

          if (breakLogResults && _.isEmpty(breakLogResults.errors)) {
            const logMap = _.groupBy(breakLogResults.data?.createClientTimeEntryBreakLogs, 'timeEntryBreakId');
            const entryWithLogs = breakResults.data?.updateTimeEntryBreaks.map((brk) => {
              const log = _.first(logMap[brk.id]);
              if (_.isNil(log)) return null;

              const entryAndLog: IBreakAndLog = {
                brk,
                log,
              };
              return entryAndLog;
            });

            const [failed, successful] = _.partition(entryWithLogs, _.isNull);
            if (failed.length === 0) {
              resolve(successful);
            } else {
              reject(failed);
            }
          } else {
            errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // log failure
            reject([]);
          }
        } else {
          errorToast({ label: t('Something went wrong'), theme: Theme.DANGER }); // time entry
          reject([]);
        }
      });

      return result;
    });

    return (await Promise.all(promises)).flat();
  }

  async function deleteBreak(entryLogId: string, timeEntryId: string, entryBreak: IBreak) {
    const currentUTC = DateTime.utc().toISO();

    const updateTimeEntryBreak = updateEntryBreak({
      variables: {
        entryBreak: {
          id: entryBreak.id,
          deletedOn: currentUTC,
          actionType: BreakActionType.BREAK_DELETE,
        },
      },
    });

    const log: IClientTimeEntryBreakLogCreateInput = {
      id: uuid(),
      updaterMemberId: member.id!,
      timeEntryBreakId: entryBreak.id!,
      timeEntryId,
      originalTimeEntryBreakId: entryBreak.id!,
      clientTimeEntryLogId: entryLogId,
      startTime: entryBreak.startTime,
      startDeviceTime: !_.isNil(entryBreak.startDeviceTime) ? entryBreak.startDeviceTime : currentUTC,
      startTimeTrusted: !_.isNil(entryBreak.startTimeTrusted) ? entryBreak.startTimeTrusted : ClockType.USER_CUSTOM,
      endTime: !_.isNil(entryBreak.endTime) ? DateTime.local().toISO() : entryBreak.endTime,
      endDeviceTime: entryBreak.endDeviceTime,
      endTimeTrusted: entryBreak.endTimeTrusted,
      startTimeDst: entryBreak.startTimeDst,
      endTimeDst: entryBreak.endTimeDst,
      startLocationId: null,
      endLocationId: null,
      actionType: BreakActionType.BREAK_DELETE,
      paidBreak: false,
      deviceType: t('Web Browser'),
      timeEntryBreakCreatedOn: entryBreak.createdOn,
      timeEntryBreakDeletedOn: currentUTC,
      createdOn: currentUTC,
    };

    return new Promise<IBreakAndLog>(async (resolve, reject) => {
      const breakResult = await updateTimeEntryBreak;
      const brk = breakResult.data[0];
      const breakLogResult = await createBreakLog({ variables: { log } });
      const brkLog = breakLogResult.data[0];
      const brkAndLog: IBreakAndLog = { brk, log: brkLog };
      resolve(brkAndLog);
    });
  }

  async function updateBreak(
    entryLogId: string,
    timeEntryId: string,
    entryBreak: IBreak,
    startTime: DateTime,
    endTime?: DateTime | null
  ) {
    const entryStarTime = dateUtils.dateTimeFromISOKeepZone(entryBreak.startTime);
    const start = startTime.setZone(entryStarTime.zoneName, { keepLocalTime: false });
    const startWithoutZone = dateUtils.dateTimeFromISOWithoutZone(start.toISO());
    const entryStartHasChanged =
      dateUtils.dateTimeFromISOWithoutZone(entryBreak.startTime).toISO() !== startWithoutZone.toISO();

    const entryEndTime = entryBreak.endTime !== null ? dateUtils.dateTimeFromISOKeepZone(entryBreak.endTime!) : null;
    const end = dateUtils.dateTimeFromISOKeepZone(
      endTime === null
        ? entryEndTime !== null
          ? DateTime.local().setZone(entryEndTime.zoneName, { keepLocalTime: false }).toISO()
          : DateTime.local().toISO()
        : entryEndTime !== null
        ? endTime!.setZone(entryEndTime.zoneName, { keepLocalTime: false }).toISO()
        : endTime!.toISO()
    );
    const endWithoutZone = dateUtils.dateTimeFromISOWithoutZone(end.toISO());
    const entryEndHasChanged =
      entryBreak.endTime !== null
        ? dateUtils.dateTimeFromISOWithoutZone(entryBreak.endTime!).toISO() !== endWithoutZone.toISO()
        : endTime !== null;

    const currentUTC = DateTime.utc().toISO();
    const newEndTime =
      endTime !== null ? (start.toSeconds() > end.toSeconds() ? end.plus({ day: 1 }).toISO() : end.toISO()) : null;

    const updateTimeEntryBreak = updateEntryBreak({
      variables: {
        entryBreak: {
          id: entryBreak.id,
          startTime: entryStartHasChanged ? start.toISO() : entryBreak.startTime,
          startDeviceTime: entryStartHasChanged ? currentUTC : entryBreak.startDeviceTime,
          startTimeTrusted: entryStartHasChanged ? ClockType.USER_CUSTOM : entryBreak.startTimeTrusted,
          endTime: entryEndHasChanged ? newEndTime : entryBreak.endTime,
          endDeviceTime: entryEndHasChanged ? newEndTime : entryBreak.endTime,
          endTimeTrusted: entryEndHasChanged ? ClockType.USER_CUSTOM : entryBreak.endTimeTrusted,
          startTimeDst: entryStartHasChanged ? start.isInDST : entryBreak.startTimeDst,
          endTimeDst: entryEndHasChanged ? end.isInDST : entryBreak.endTimeDst,
          startLocationId: entryBreak.startLocation?.id,
          endLocationId: entryBreak.endLocation?.id,
          paidBreak: false,
          actionType: BreakActionType.BREAK_EDIT_MANUAL,
        },
      },
    });

    const log: IClientTimeEntryBreakLogCreateInput = {
      id: uuid(),
      updaterMemberId: member.id!,
      timeEntryBreakId: entryBreak.id!,
      timeEntryId,
      originalTimeEntryBreakId: entryBreak.id!,
      clientTimeEntryLogId: entryLogId,
      startTime: entryStartHasChanged ? start.toISO() : entryBreak.startTime,
      startDeviceTime: entryStartHasChanged ? currentUTC : entryBreak.startDeviceTime!,
      startTimeTrusted: entryStartHasChanged ? ClockType.USER_CUSTOM : entryBreak.startTimeTrusted,
      endTime: entryEndHasChanged ? newEndTime : entryBreak.endTime,
      endDeviceTime: entryEndHasChanged ? newEndTime : entryBreak.endTime,
      endTimeTrusted: entryEndHasChanged ? ClockType.USER_CUSTOM : entryBreak.endTimeTrusted,
      startTimeDst: entryStartHasChanged ? start.isInDST : entryBreak.startTimeDst,
      endTimeDst: entryEndHasChanged ? end.isInDST : entryBreak.endTimeDst,
      startLocationId: entryBreak.startLocation?.id,
      endLocationId: entryBreak.endLocation?.id,
      actionType: BreakActionType.BREAK_EDIT_MANUAL,
      paidBreak: false,
      deviceType: t('Web Browser'),
      timeEntryBreakCreatedOn: entryBreak.createdOn,
      createdOn: currentUTC,
    };

    return new Promise<IBreakAndLog>(async (resolve, reject) => {
      const breakResult = await updateTimeEntryBreak;
      const brk = breakResult.data[0];
      const breakLogResult = await createBreakLog({ variables: { log } });
      const brkLog = breakLogResult.data[0];
      const brkAndLog: IBreakAndLog = { brk, log: brkLog };
      resolve(brkAndLog);
    });
  }

  return { createBreak, beginBreak, endBreak, deleteBreak, updateBreak };
}
