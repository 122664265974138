import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import './badge.scss';

export interface IBadgeProps {
  color: 'primary' | 'gray' | 'dark-blue';
  className?: ClassName;
  children: ReactNode;
}

const Badge = (props: IBadgeProps) => {
  const { color, className, children } = props;

  const classes = classNames(
    {
      badge: true,
      [`badge-${color}`]: true,
    },
    className
  );

  return <div className={classes}>{children}</div>;
};

Badge.defaultProps = {
  color: 'gray',
};

export default Badge;
