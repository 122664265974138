import { useOrganization } from 'hooks/store';
import { useFeatureFlags } from 'utils/features';

export function useCustomSignOffEnabled() {
  const isCustomSignOffEnabled = useFeatureFlags('CUSTOM_DAILY_SIGN_OFF');

  const { customSignOff } = useOrganization();

  return !!customSignOff && isCustomSignOffEnabled;
}
