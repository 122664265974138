import { PROJECT_COST_CODE_QUANTITY_SUMMARY_QUERY } from 'apollo/queries/project-cost-code-quantity-queries';
import { useApolloPaging } from 'hooks';
import { isEmpty } from 'lodash';
import IProjectCostCodeQuantity from 'types/ProjectCostCodeQuantity';

export default function useGetProgressBudgetActuals() {
  const { getAll } = useApolloPaging();

  async function getProgressBudgetActuals(projectId: string, costCodeIds: string[], includeSubprojects: boolean) {
    const results = await getAll<IProjectCostCodeQuantity>('projectCostCodeQuantities', {
      query: PROJECT_COST_CODE_QUANTITY_SUMMARY_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 500,
        filter: {
          deletedOn: { isNull: true },
          costCode: isEmpty(costCodeIds)
            ? undefined
            : {
                id: { contains: costCodeIds },
              },
          project: {
            idWithDescendants: includeSubprojects ? { equal: projectId } : undefined,
            id: !includeSubprojects ? { equal: projectId } : undefined,
          },
        },
        sort: [{ assignedOn: 'asc' }, { createdOn: 'asc' }],
      },
    });
    return results ?? [];
  }
  return getProgressBudgetActuals;
}
