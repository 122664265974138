import { CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import { isNil } from 'lodash';

import './Capsule.scss';

export interface ICapsuleProps {
  className?: ClassName;
  title: string;
  onClick?: (event: React.MouseEvent) => void;
  onClose?: () => void;
}

const Capsule = (props: ICapsuleProps) => {
  const { className, title, onClose, onClick } = props;

  const classes = classNames('capsule', !isNil(onClick) ? 'selectable' : '', className);

  return (
    <div className={classes} onClick={onClick}>
      <div>{title}</div>
      {onClose && <IconButton className="close-button" svg={CloseIcon} onClick={onClose} />}
    </div>
  );
};

export default Capsule;
