import { useActiveMember } from 'hooks';
import MemberPermission from 'types/enum/MemberPermission';

export default function useMemberActivityPermission() {
  const activeMember = useActiveMember();

  if (activeMember.position!.viewAllActivityReport) {
    return MemberPermission.TIME_EVENTS;
  } else {
    return MemberPermission.VIEW_MEMBER_ACTIVITY_REPORT;
  }
}
