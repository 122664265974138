import qs from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useNavigation<T extends object>(pathname: string) {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (params?: T) => {
      const search = qs.stringify(params as { [key: string]: unknown });
      navigate({ search: search ? search : undefined, pathname });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, pathname]
  );
}
