import { useCallback } from 'react';
import { IGustoSignatory } from '../../../types/types';
import useGustoAxios from '../../useGustoAxios/useGustoAxios';

// GUSTO: Returns company signatories. Currently we only support a single signatory per company.
export default function useGustoListSignatories() {
  const axios = useGustoAxios();

  return useCallback(
    (gustoCompanyId: string) => {
      if (gustoCompanyId) {
        return axios.get<IGustoSignatory[]>(`/v1/companies/${gustoCompanyId}/signatories`);
      } else {
        return null;
      }
    },
    [axios]
  );
}
