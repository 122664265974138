import { useEffect, useState } from 'react';
import { getClient } from '.';

/**
 *
 * @returns true if the LaunchDarkly client is ready to use, or false if it is not.
 */
export const useIsLaunchDarklyReady = () => {
  const [isReady, setIsReady] = useState(false);
  const ldClient = getClient();

  useEffect(() => {
    ldClient.waitUntilReady().then(() => {
      if (!isReady) {
        setIsReady(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldClient]);

  return isReady;
};
