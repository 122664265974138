import { useApolloClient } from '@apollo/client';
import { LOG_FEED_TIME_ENTRY_LOGS_QUERY } from 'containers/time-log-feed/apollo/time-log-feed-queries';
import { useAsync } from 'hooks';
import { first } from 'lodash';
import { useCallback } from 'react';
import ClockActionType from 'types/enum/ClockActionType';
import { getUuidEqualComparisonWrapped, getGraphQlEqualComparison } from 'utils/apolloUtils';
import { LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables } from '__generated__/graphql';

export function usePreviousCurrentLogs(currentLogId: string) {
  const client = useApolloClient();

  return useAsync(
    useCallback(async () => {
      const log = await client.query<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>({
        query: LOG_FEED_TIME_ENTRY_LOGS_QUERY,
        variables: {
          filter: {
            ...getUuidEqualComparisonWrapped(currentLogId),
          },
        },
        fetchPolicy: 'network-only',
      });

      const currentLog = first(log.data?.clientTimeEntryLogs);

      if (currentLog) {
        if (currentLog.actionType === ClockActionType.EDIT) {
          const previousLog = await client.query<LogFeedTimeEntryLogsQuery, LogFeedTimeEntryLogsQueryVariables>({
            query: LOG_FEED_TIME_ENTRY_LOGS_QUERY,
            variables: {
              first: 1,
              filter: {
                createdOn: { lessThan: currentLog.createdOn },
                timeEntryId: getGraphQlEqualComparison(currentLog.timeEntryId),
              },
            },
            fetchPolicy: 'network-only',
          });

          const previous = first(previousLog.data.clientTimeEntryLogs);
          return {
            previousLog: previous ?? null,
            currentLog,
          };
        }
      } else {
        return {
          previousLog: null,
          currentLog: null,
        };
      }
    }, [client, currentLogId]),
    true
  );
}
