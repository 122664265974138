import { Direction, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode, Ref } from 'react';
import './Panel.scss';

export interface IPanelProps {
  open: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  strokeLeft?: boolean;
  strokeRight?: boolean;
  className?: ClassName;
  children?: ReactNode;
  forwardRef?: Ref<any>;
}

function Panel(props: IPanelProps) {
  const { open, children, className, small, medium, large, strokeLeft, strokeRight, forwardRef } = props;

  const classes = classNames(
    {
      'panel': true,
      'panel-closed': !open,
      'panel-medium': medium,
      'panel-small': small,
      'panel-large': large,
      'panel-stroke-left': strokeLeft,
      'panel-stroke-right': strokeRight,
    },
    className
  );

  return (
    <Row direction={Direction.COLUMN} className={classes} forwardRef={forwardRef}>
      {children}
    </Row>
  );
}

Panel.defaultProps = {
  open: true,
};

export default Panel;
