import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import { getLastCursor } from 'utils/apolloUtils';
import { logError } from 'utils/testUtils';
import { ILazyLoadingState } from './useLazyLoading/useLazyLoading';

export interface IUsePagedCursorLoaderPayload<T extends ICursorable> {
  data: T[];
  loadedAll: boolean;
  error: boolean;
}

export default function usePagedCursorLoader<T extends ICursorable>(
  state: Omit<ILazyLoadingState<T>, 'loading'>,
  getData: (after: string | null, first: number) => Promise<T[]>,
  sectionSize: number = 20
) {
  const getDataPayload = useCallback(
    async (cursor: string | null): Promise<IUsePagedCursorLoaderPayload<T>> => {
      try {
        const result = await getData(cursor ?? null, sectionSize);
        return {
          data: [...state.data, ...result],
          loadedAll: result.length < sectionSize,
          error: false,
        };
      } catch (e) {
        logError(e);
        return { data: state.data, loadedAll: true, error: true };
      }
    },
    [getData, sectionSize, state.data]
  );

  const getMoreData = useCallback(async (): Promise<IUsePagedCursorLoaderPayload<T>> => {
    try {
      return getDataPayload(getLastCursor(state.data));
    } catch (e: unknown) {
      logError(e);
      return {
        data: state.data,
        error: true,
        loadedAll: true,
      };
    }
  }, [getDataPayload, state.data]);

  const loadAll = useCallback(async (): Promise<IUsePagedCursorLoaderPayload<T>> => {
    if (state.loadedAll) {
      return { loadedAll: state.loadedAll, error: state.error, data: state.data };
    } else {
      let payload = { loadedAll: false, data: state.data, error: state.error };
      try {
        do {
          const newPayload = await getDataPayload(getLastCursor(payload.data));

          payload = {
            loadedAll: newPayload.loadedAll,
            data: [...newPayload.data],
            error: payload.error || newPayload.error,
          };
        } while (!payload.loadedAll);

        return payload;
      } catch (e) {
        return { data: state.data, loadedAll: true, error: true };
      }
    }
  }, [getDataPayload, state.data, state.error, state.loadedAll]);

  return { getMoreData, loadAll };
}
