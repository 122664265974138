import { Button, ISelectOption, Label, Select } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import SecretMenuWarningWell from 'components/domain/preferences/SecretMenuWarningWell/SecretMenuWarningWell';
import { getRandomRefreshInterval } from 'containers/dashboard/util/utils';
import { useReduxSelector } from 'hooks';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDashboardRefreshInterval } from 'store/preferences/Preferences';
import { ClassName } from 'types/ClassName';

export interface IDashboardSettingsDetailsProps {
  className?: ClassName;
  onClose: () => void;
}

const DashboardSettingsDetails = (props: IDashboardSettingsDetailsProps) => {
  const { className, onClose } = props;

  const dispatch = useDispatch();
  const currentInterval: number = useReduxSelector((state) => state.preferences.dashboardRefreshInterval);
  const [localInterval, setLocalInterval] = useState(5);

  useEffect(() => {
    setLocalInterval(0);
  }, [currentInterval]);

  const options: ISelectOption[] = [
    { value: 0, label: 'Random, between 8 and 12 minutes (default)' },
    { value: 2, label: '2 minutes' },
    { value: 5, label: '5 minutes' },
    { value: 10, label: '10 minutes' },
    { value: 15, label: '15 minutes' },
  ];

  const saveInterval = () => {
    dispatch(updateDashboardRefreshInterval(localInterval === 0 ? getRandomRefreshInterval() : localInterval));
    onClose();
  };

  const classes = classNames('m-6', className);

  return (
    <div className={classes}>
      <div>
        <SecretMenuWarningWell />
        <h3>{t('Current Dashboard refresh interval')}</h3>
        {useMemo(
          () => (
            <Select options={options} onChange={setLocalInterval} value={localInterval} />
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [localInterval]
        )}
        <Label>{t(`Currently set to ${currentInterval} minutes.`)}</Label>
      </div>
      <div className="mt-5">
        <Button onClick={onClose} type="secondary" className={'mt-4'}>
          {t('Close')}
        </Button>
        <Button onClick={saveInterval} type="primary" className={'mt-4 ml-4'}>
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};

export default DashboardSettingsDetails;
