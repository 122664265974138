import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import { IOpenableHook } from 'hooks/utils/useOpenable';
import { DateTime } from 'luxon';
import * as React from 'react';
import TimeRangeType from 'types/TimeRangeType';

export interface IReportTimeEntriesDialogProps {
  date: DateTime;
  memberId: string | null;
  viewEntriesDialog: IOpenableHook;
  onDataChange?: (memberIds: string[]) => void;
}

const ReportTimeEntriesDialog: React.FC<IReportTimeEntriesDialogProps> = ({
  date,
  memberId,
  viewEntriesDialog,
  onDataChange,
}) => {
  const timeRange = {
    startTime: date.toUTC().startOf('day'),
    endTime: date.toUTC().endOf('day'),
  };

  return (
    <>
      {viewEntriesDialog.isOpen && (
        <MemberTimeEntryDialog
          includeOpen={true}
          timeRange={timeRange}
          memberIds={memberId ? [memberId] : undefined}
          isOpen={viewEntriesDialog.isOpen}
          onClose={viewEntriesDialog.close}
          onDataChange={onDataChange}
          timeRangeType={TimeRangeType.DAILY}
        />
      )}
    </>
  );
};

export default ReportTimeEntriesDialog;
