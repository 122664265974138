import { Button, IDialogProps, Theme, Tray } from '@busybusy/webapp-react-ui';
import FlexContainer from '@busybusy/webapp-react-ui/dist/components/FlexContainer/FlexContainer';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import GoogleMap, { googleMapDefaultOptions } from 'components/domain/map/GoogleMap/GoogleMap';
import useMemberMapLocation from 'components/domain/member-location/hooks/useMemberMapLocation';
import TimeEntryLogToFromDetail from 'components/domain/time-entry-logs/TimeEntryLogToFromDetail/TimeEntryLogToFromDetail';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import OptionalUnwrapper from 'components/foundation/OptionalUnwrapperContainer/OptionalUnwrapper';
import DashboardRibbon from 'components/foundation/dashboard/DashboardRibbon/DashboardRibbon';
import Typography from 'components/foundation/text/Typography/Typography';
import SectionHeader from 'containers/settings/components/SectionHeader/SectionHeader';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import ClockActionType from 'types/enum/ClockActionType';
import MemberLocationType from 'types/enum/MemberLocationType';
import { Nullable } from 'types/util/Nullable';
import TimeLogFeedDetails from '../TimeLogFeedDetails/TimeLogFeedDetails';
import { ITimeLogFeedTableRow } from '../TimeLogFeedTable/TimeLogFeedTable';
import './TimeEntryLogEvent.scss';

export interface ITimeEntryLogEventProps extends Omit<IDialogProps, 'children'> {
  logId: string;
  actionType: ClockActionType;
  actionTypeTitle: string;
  actedOnMemberName: string;
  details: ITimeLogFeedTableRow['details'];
  performedOn: DateTime;
  performedBy: string;
  device: Nullable<string>;
  location: Nullable<{
    id: string;
    type: MemberLocationType;
  }>;
  isUserManageable: boolean;
  onEditEntry?: () => void;
  onViewEntry?: () => void;
  onViewEntryLogs?: () => void;
}

function TimeEntryLogEvent({
  logId,
  actionTypeTitle,
  actionType,
  actedOnMemberName,
  details,
  performedOn,
  performedBy,
  device,
  location,
  isUserManageable,
  className,
  onEditEntry,
  onViewEntry,
  onViewEntryLogs,
  ...dialogProps
}: ITimeEntryLogEventProps) {
  const parsedLocation = useMemberMapLocation(location?.id ?? null, location?.type ?? null);
  const pins = useMemo(() => (parsedLocation ? [parsedLocation] : null), [parsedLocation]);

  const classes = classNames('time-entry-log-event', className);
  const contentClasses = classNames('dialog-content', { 'has-location': !!location });

  return (
    <HeaderDialog title={actionTypeTitle} subtitle={actedOnMemberName} className={classes} {...dialogProps}>
      <FlexContainer flexDirection="column" className={contentClasses}>
        <TimeEntryLogToFromDetail id={logId} />
        <SectionHeader className="mb-4">{t('Details')}</SectionHeader>
        <DashboardRibbon theme={Theme.LIGHT}>
          <TimeLogFeedDetails actionType={actionType} details={details} />
        </DashboardRibbon>

        <SectionHeader className="my-4">{t('Submission')}</SectionHeader>
        <DashboardRibbon theme={Theme.LIGHT}>
          <Typography tag="div">{`${t('Recorded:')} ${performedOn.toFormat('h:mm a M/d/yyyy')}`}</Typography>
          <Typography tag="div">{`${t('By:')} ${performedBy}`}</Typography>
          <Typography tag="div">{`${t('Device:')} ${device}`}</Typography>
        </DashboardRibbon>

        <OptionalUnwrapper value={pins}>
          {(pins) => (
            <>
              <SectionHeader className="my-4">{t('GPS')}</SectionHeader>
              <GoogleMap mapId={'time-entry-log-event-map'} pinLocations={pins} options={googleMapDefaultOptions} />
            </>
          )}
        </OptionalUnwrapper>

        <Tray spacing={5} className="mt-6">
          <ConditionalContainer condition={isUserManageable && !!onEditEntry}>
            <Button type="secondary" onClick={onEditEntry}>
              {t('Edit Entry')}
            </Button>
          </ConditionalContainer>
          <ConditionalContainer condition={!isUserManageable && !!onViewEntry}>
            <Button type="secondary" onClick={onViewEntry}>
              {t('View Entry')}
            </Button>
          </ConditionalContainer>
          <OptionalUnwrapper value={onViewEntryLogs}>
            {(onViewLogs) => (
              <Button type="secondary" onClick={onViewLogs}>
                {t('View Entry Logs')}
              </Button>
            )}
          </OptionalUnwrapper>
        </Tray>
      </FlexContainer>
    </HeaderDialog>
  );
}

export default TimeEntryLogEvent;
