import { Align, Button, Checkbox, Form, Justify, Label, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import AccountPreferencesButton from 'components/domain/account/AccountPreferencesButton/AccountPreferencesButton';
import DraggableList, {
  DraggablePayload,
  IUpdatedDraggable,
} from 'components/foundation/DraggableList/draggable-list/DraggableList';
import FormAccordion from 'components/foundation/FormAccordion/FormAccordion';
import useTimesheetsColumns from 'containers/timesheets/hooks/useTimesheetsColumns';
import { first } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  TimesheetView,
  updateDailyColumns,
  updateSummaryAndTimeCardTableColumns,
  updateTimeEntryTableColumns,
  updateWeeklyColumns,
} from 'store/timesheets/Timesheets';
import IIdable from 'types/Idable';
import { IVisibleTableColumn } from 'types/TableColumn';
import { t } from 'utils/localize';
import './TimesheetsColumnsForm.scss';

export interface ITimesheetsColumnsFormProps {
  className?: ClassName;
  onClose: () => void;
  timesheetView: TimesheetView | null;
}

const TimesheetsColumnsForm = (props: ITimesheetsColumnsFormProps) => {
  const { className, onClose, timesheetView } = props;
  const classes = classNames('timesheets-column-form', className);

  const dispatch = useDispatch();
  const {
    summaryColumns,
    updateSummaryColumn,
    setSummaryColumns,
    timeEntryColumns,
    updateTimeEntryColumn,
    setTimeEntryColumns,
    disabledProColumns,
    saveColumns,
    weeklyColumns,
    updateWeeklyColumn,
    dailyColumns,
    updateDailyColumn,
  } = useTimesheetsColumns();

  const actionApply = () => {
    // update member settings blob
    saveColumns();

    // update redux
    dispatch(updateSummaryAndTimeCardTableColumns(summaryColumns));
    dispatch(updateTimeEntryTableColumns(timeEntryColumns));
    dispatch(updateDailyColumns(dailyColumns));
    dispatch(updateWeeklyColumns(weeklyColumns));

    onClose();
  };

  const onWeeklyGridColumnChange = (checked: boolean, isWeekly: boolean, key: string) => {
    if (isWeekly) {
      updateWeeklyColumn(checked, key);
    } else {
      updateDailyColumn(checked, key);
    }
  };

  const renderDialogContent = ({ ...form }) => {
    const openSummaryTimeCard = timesheetView === TimesheetView.SUMMARY || timesheetView === TimesheetView.TIME_CARDS;
    const openTimeEntries = timesheetView === TimesheetView.TIME_ENTRIES;
    const openWeeklyGrid = timesheetView === TimesheetView.WEEKLY_GRID;

    return (
      <>
        <Label>{t('Select which columns you want to show')}:</Label>

        <FormAccordion
          className="pt-4"
          title={t('Summary & Time Cards')}
          form={form}
          openByDefault={openSummaryTimeCard}
        >
          {renderColumns(
            summaryColumns.filter((col) => !disabledProColumns.some((disabledCol) => col.key === disabledCol.key)),
            updateSummaryColumn,
            setSummaryColumns
          )}
        </FormAccordion>

        <FormAccordion title={t('Time Entries')} form={form} openByDefault={openTimeEntries}>
          {renderColumns(timeEntryColumns, updateTimeEntryColumn, setTimeEntryColumns)}
        </FormAccordion>

        <FormAccordion title={t('Weekly Grid')} form={form} openByDefault={openWeeklyGrid}>
          {renderWeeklyGridColumns(
            weeklyColumns.filter((col) => !disabledProColumns.some((disabledCol) => col.key === disabledCol.key)),
            dailyColumns,
            onWeeklyGridColumnChange
          )}
        </FormAccordion>

        <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER} className="mt-4">
          <Button className="mt-4" type="primary" onClick={form.handleSubmit}>
            {t('Apply')}
          </Button>
          <AccountPreferencesButton label={t('Display Preferences')} />
        </Row>
      </>
    );
  };

  const renderWeeklyGridColumns = (
    weeklyColumns: IVisibleTableColumn[],
    dailyColumns: IVisibleTableColumn[],
    onChange: (checked: boolean, isWeekly: boolean, key: string) => void
  ) => {
    const columnsMap = weeklyColumns.map((column) => {
      const dailyCheckboxCol = first(
        dailyColumns.filter((col) => {
          return col.key === column.key;
        })
      );
      return (
        <div key={column.key} className="py-2 ml-2 column-item weekly-grid">
          <div className={`weekly-grid-label p-1`}>
            <Label>{column.title}</Label>
          </div>
          <div className="weekly-grid-checkbox">
            <Checkbox
              data-testid="column_checkbox_weekly"
              className="primary-text mr-0"
              checked={column.visible}
              onChange={(checked) => onChange(checked, true, column.key)}
            />
          </div>
          <div className={`weekly-grid-checkbox daily `}>
            <Checkbox
              data-testid="column_checkbox_weekly"
              className="primary-text mr-0"
              checked={dailyCheckboxCol?.visible ? dailyCheckboxCol?.visible : false}
              onChange={(checked) => onChange(checked, false, dailyCheckboxCol?.key ?? column.key)}
            />
          </div>
        </div>
      );
    });
    return (
      <div className={'weekly-grid-table'}>
        <div className="weekly-grid">
          <div className="weekly-grid-label"> </div>
          <div className="weekly-grid-checkbox">{t('Weekly Total')}</div>
          <div className="weekly-grid-checkbox">{t('Daily Total')}</div>
        </div>
        {columnsMap}
      </div>
    );
  };

  const renderColumn =
    (handleOnChange: (checked: boolean, key: string) => void) =>
    // eslint-disable-next-line react/display-name
    (column: IVisibleTableColumn & DraggablePayload<IIdable<string>>) => {
      function onCheck(checked: boolean) {
        handleOnChange(checked, column.key);
      }

      const summaryOnlyText = (
        <>
          <Label className="primary-text pr-1">-</Label>
          <Label className="secondary-text">{t('Summary view only')}</Label>
        </>
      );

      const payPeriodOnlyText = (
        <>
          <Label className="primary-text pr-1">-</Label>
          <Label className="secondary-text">{t('Summary view only, when viewing pay periods')}</Label>
        </>
      );

      const ptoCheckedText = (
        <>
          <Label className="primary-text pr-1">-</Label>
          <Label className="secondary-text">{t('PTO hours not included in the Regular Hours value')}</Label>
        </>
      );

      const ptoUncheckedText = (
        <>
          <Label className="primary-text pr-1">-</Label>
          <Label className="secondary-text">{t('PTO hours included in the Regular Hours value')}</Label>
        </>
      );

      return (
        <div key={column.key} className="py-2 ml-2 column-item">
          <Checkbox
            data-testid="column_checkbox"
            className="primary-text mr-0"
            checked={column.visible}
            onChange={onCheck}
            label={column.title}
          />

          {column.key === 'member.memberGroup.groupName' || column.key === 'flaggedSignIn' || column.key === 'flaggedSignOff' ? summaryOnlyText : ''}
          {(column.key === 'employeeSigned' || column.key === 'supervisorSigned') && payPeriodOnlyText}
          {column.key === 'paidTimeOff' && (column.visible ? ptoCheckedText : ptoUncheckedText)}
        </div>
      );
    };

  const renderColumns = (
    columns: IVisibleTableColumn[],
    handleOnChange: (checked: boolean, key: string) => void,
    setColumns: (columns: IVisibleTableColumn[]) => void
  ) => {
    const onColumnPositionUpdate = (columns: Array<IUpdatedDraggable<IVisibleTableColumn & IIdable<string>>>) => {
      setColumns(columns.map((column) => ({ ...column.payload, position: column.index })));
    };

    const withIds = columns.map((column) => ({ ...column, id: column.key, draggable: true }));
    return (
      <DraggableList items={withIds} onUpdate={onColumnPositionUpdate} renderItem={renderColumn(handleOnChange)} />
    );
  };

  return <Form className={classes} onSubmit={actionApply} render={renderDialogContent} />;
};

export default TimesheetsColumnsForm;
