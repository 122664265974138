import { IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EquipmentPicker } from 'components';
import { IEquipmentPickerProps } from 'components/domain/equipment/EquipmentPicker/EquipmentPicker';
import { useOrganization } from 'hooks';
import { AnyObject } from 'types/util/Object';
import BasicFormField from '../../../foundation/form-fields/BasicFormField/BasicFormField';

export interface IEquipmentPickerFormFieldProps<FormType extends AnyObject>
  extends Omit<IEquipmentPickerProps, 'value' | 'onSelect'>,
    IFormFieldDefaultProps<string | null, FormType> {
  onSelect?: (value: string | null) => void;
}

const EquipmentPickerFormField = <FormType extends AnyObject>({
  form,
  name,
  validations,
  onSelect,
  className,
  revalidateOnNewValidations,
  ...other
}: IEquipmentPickerFormFieldProps<FormType>) => {
  const organization = useOrganization();
  const classes = classNames('equipment-picker-form-field', className);

  if (!organization.trackEquipment) {
    return null;
  }

  return (
    <BasicFormField
      name={name}
      form={form}
      onSelect={onSelect}
      className={classes}
      validations={validations}
      revalidateOnNewValidations={revalidateOnNewValidations}
    >
      {(value, onFormSelect, error) => (
        <EquipmentPicker error={error} value={value} onSelect={onFormSelect} {...other} />
      )}
    </BasicFormField>
  );
};

export default EquipmentPickerFormField;
