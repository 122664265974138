import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { isNil } from 'lodash';
import useDashboardActivityReportTimeRangeDetails from './useDashboardActivityReportTimeRangeDetails';

export default function useActivityReportDetailNavigation(
  timeFrame: DashboardTimeFrame,
  memberId?: string | null,
  costCodeId?: string | null,
  equipmentId?: string | null,
  projectId?: string | null
) {
  const { timeRange: navRange, timeRangeType } = useDashboardActivityReportTimeRangeDetails(timeFrame)();

  function getActivityReportFilterType() {
    if (!isNil(memberId)) {
      return ActivityReportType.BY_EMPLOYEE;
    } else if (!isNil(costCodeId)) {
      return ActivityReportType.BY_COST_CODE;
    } else if (!isNil(equipmentId)) {
      return ActivityReportType.BY_EQUIPMENT;
    } else if (!isNil(projectId)) {
      return ActivityReportType.BY_PROJECT;
    }

    return ActivityReportType.BY_EMPLOYEE;
  }

  function getActivityReportFilter() {
    if (!isNil(memberId)) {
      return memberId;
    } else if (!isNil(costCodeId)) {
      return costCodeId;
    } else if (!isNil(equipmentId)) {
      return equipmentId;
    } else if (!isNil(projectId)) {
      return projectId;
    }

    return null;
  }

  return { timeRangeType, navRange, getActivityReportFilterType, getActivityReportFilter };
}
