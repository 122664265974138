import { ActionCreator, UnknownAction } from 'redux';

import { ORGANIZATION_SUBSCRIPTION_STATUS_SET } from '../organizationSubscriptionStatusConstants';
import { IStoreOrganizationSubscriptionStatus } from '../organizationSubscriptionStatusType';

export interface IStoreOrganizationSubscriptionStatusSetAction extends UnknownAction {
  type: typeof ORGANIZATION_SUBSCRIPTION_STATUS_SET;
  payload: IStoreOrganizationSubscriptionStatus;
}

export const organizationSubscriptionStatusSetAction: ActionCreator<IStoreOrganizationSubscriptionStatusSetAction> = (
  payload: IStoreOrganizationSubscriptionStatus
) => ({
  payload,
  type: ORGANIZATION_SUBSCRIPTION_STATUS_SET,
});
