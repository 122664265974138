import { DatePicker, Position } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { DateTime } from 'luxon';
import { ReactNode, useState } from 'react';
import IDatePickerValue from 'types/DatePickerValue';
import './DateRange.scss';

export interface IDateRangeProps {
  startDate: DateTime | null;
  endDate: DateTime | null;
  showCalendarIcon?: boolean;
  disabledBefore?: DateTime | null;
  disabledAfter?: DateTime | null;
  className?: ClassName;
  onStartChange: (value: IDatePickerValue) => void;
  onEndChange: (value: IDatePickerValue) => void;
  startHeader?: ReactNode;
  endHeader?: ReactNode;
  startPlaceholder?: string;
  endPlaceholder?: string;
  showDashSeparator?: boolean;
}

export function DateRange(props: IDateRangeProps) {
  const {
    startDate,
    endDate,
    onStartChange,
    onEndChange,
    disabledAfter,
    disabledBefore,
    showCalendarIcon,
    startHeader,
    endHeader,
    showDashSeparator,
    className,
    startPlaceholder,
    endPlaceholder,
  } = props;

  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const onStartOpen = () => {
    setStartDateOpen(true);
    setEndDateOpen(false);
  };

  const onStartClose = () => {
    setStartDateOpen(false);
  };

  const onEndOpen = () => {
    setEndDateOpen(true);
    setStartDateOpen(false);
  };

  const onEndClose = () => {
    setEndDateOpen(false);
  };

  return (
    <div className={classNames('time-range', className)}>
      <div className="time-range-start-time">
        {startHeader}
        <DatePicker
          value={startDate ? startDate.toJSDate() : null}
          isOpen={startDateOpen}
          onOpen={onStartOpen}
          onClose={onStartClose}
          placeholder={startPlaceholder}
          disabledAfter={disabledAfter ? disabledAfter.toJSDate() : undefined}
          disabledBefore={disabledBefore ? disabledBefore.toJSDate() : undefined}
          onChange={onStartChange}
          position={Position.BOTTOM_END}
          showCalendarIcon={showCalendarIcon}
        />
      </div>
      {showDashSeparator && <div className="dash">-</div>}
      <div className="time-range-end-time">
        {endHeader}
        <DatePicker
          value={endDate ? endDate.toJSDate() : null}
          isOpen={endDateOpen}
          onOpen={onEndOpen}
          onClose={onEndClose}
          placeholder={endPlaceholder}
          disabledAfter={disabledAfter ? disabledAfter.toJSDate() : undefined}
          disabledBefore={disabledBefore ? disabledBefore.toJSDate() : undefined}
          onChange={onEndChange}
          position={Position.BOTTOM_END}
          showCalendarIcon={showCalendarIcon}
        />
      </div>
    </div>
  );
}

DateRange.defaultProps = {
  className: '',
  disabledAfter: null,
  disabledBefore: null,
  showCalendarIcon: false,
};

export default DateRange;
