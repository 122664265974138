import Graylog from 'components/domain/analytics/Graylog/Graylog';
import { useActiveMember } from 'hooks';
import { useCallback } from 'react';
import AnalyticsEvent from 'utils/constants/analytics/analyticsEvent';

export type CLICK_TIMESHEETS_USER_EVENT =
  | 'timesheets'
  | 'summary'
  | 'time_cards'
  | 'expanded_time_cards'
  | 'time_entries'
  | 'event_logs'
  | 'weekly_grid';
export type CLICK_MANAGEMENT_LISTS_USER_EVENT =
  | 'management_lists'
  | 'employees'
  | 'projects'
  | 'cost_codes'
  | 'equipment';
export type CLICK_SCHEDULING_USER_EVENT = 'calendar' | 'attendance_report';
export type CLICK_ACTIVITY_REPORTS_USER_EVENT =
  | 'activity_reports'
  | 'by_project'
  | 'by_employee'
  | 'by_cost_code'
  | 'by_equipment'
  | 'by_date'
  | 'by_date_range';
export type CLICK_VERIFICATION_AND_SAFETY_USER_EVENT = 'verification_and_safety' | 'daily_sign_in' | 'daily_sign_off';
export type CLICK_MORE_USER_EVENT =
  | 'more'
  | 'time_off'
  | 'per_diem'
  | 'daily_project_reports'
  | 'machine_vs_operator'
  | 'hour_meter'
  | 'break_hours'
  | 'kiosk_photos'
  | 'event_logs'
  | 'flagged_location_report'
  | 'per_diem'
  | 'gps_disabled';

export default function useNavListGraylog() {
  const activeMember = useActiveMember();

  const recordClickDashboard = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_DASHBOARD,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'dashboard',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickMyStatus = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_MY_STATUS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'my_status',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickTimesheets = useCallback(
    (actionType: CLICK_TIMESHEETS_USER_EVENT) => {
      Graylog({
        shortMsg: AnalyticsEvent.navBar.CLICK_TIMESHEETS,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _action_type: actionType,
        },
      });
    },
    [activeMember.id, activeMember.positionId]
  );

  const recordClickPayroll = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_PAYROLL,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'payroll',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickEmployees = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_EMPLOYEES,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'employees',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickProjects = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_PROJECTS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'projects',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickCostCodes = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_COST_CODES,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'cost_codes',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickEquipment = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_EQUIPMENT,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'equipment',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickPhotos = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_PHOTOS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'photos',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickScheduling = useCallback(
    (actionType: CLICK_SCHEDULING_USER_EVENT) => {
      Graylog({
        shortMsg: AnalyticsEvent.navBar.CLICK_SCHEDULING,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _action_type: actionType,
        },
      });
    },
    [activeMember.id, activeMember.positionId]
  );

  const recordClickMap = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_MAP,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'map',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickBudget = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_BUDGET,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'budget',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickBulletin = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_BULLETIN,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'bulletin',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickManagementLists = useCallback(
    (actionType: CLICK_MANAGEMENT_LISTS_USER_EVENT) => {
      Graylog({
        shortMsg: AnalyticsEvent.navBar.CLICK_MANAGEMENT_LISTS,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _action_type: actionType,
        },
      });
    },
    [activeMember.id, activeMember.positionId]
  );

  const recordClickActivityReports = useCallback(
    (actionType: CLICK_ACTIVITY_REPORTS_USER_EVENT) => {
      Graylog({
        shortMsg: AnalyticsEvent.navBar.CLICK_ACTIVITY_REPORTS,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _action_type: actionType,
        },
      });
    },
    [activeMember.id, activeMember.positionId]
  );

  const recordClickLegacyReports = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_LEGACY_REPORTS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'legacy_reports',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickProgress = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_PROGRESS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'progress',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickVerificationAndSafety = useCallback(
    (actionType: CLICK_VERIFICATION_AND_SAFETY_USER_EVENT) => {
      Graylog({
        shortMsg: AnalyticsEvent.navBar.CLICK_VERIFICATION_AND_SAFETY,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _action_type: actionType,
        },
      });
    },
    [activeMember.id, activeMember.positionId]
  );

  const recordClickMore = useCallback(
    (actionType: CLICK_MORE_USER_EVENT) => {
      Graylog({
        shortMsg: AnalyticsEvent.navBar.CLICK_MORE,
        logObject: {
          _member_id: activeMember.id,
          _member_position_id: activeMember.positionId,
          _action_type: actionType,
        },
      });
    },
    [activeMember.id, activeMember.positionId]
  );

  const recordClickSettings = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_SETTINGS,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'settings',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  const recordClickSupport = useCallback(() => {
    Graylog({
      shortMsg: AnalyticsEvent.navBar.CLICK_SUPPORT,
      logObject: {
        _member_id: activeMember.id,
        _member_position_id: activeMember.positionId,
        _action_type: 'support',
      },
    });
  }, [activeMember.id, activeMember.positionId]);

  return {
    recordClickDashboard,
    recordClickMyStatus,
    recordClickTimesheets,
    recordClickPayroll,
    recordClickEmployees,
    recordClickProjects,
    recordClickCostCodes,
    recordClickEquipment,
    recordClickPhotos,
    recordClickScheduling,
    recordClickMap,
    recordClickBudget,
    recordClickBulletin,
    recordClickManagementLists,
    recordClickActivityReports,
    recordClickLegacyReports,
    recordClickProgress,
    recordClickVerificationAndSafety,
    recordClickMore,
    recordClickSettings,
    recordClickSupport,
  };
}
