import {
  IncidentAnswerUpdateInput,
  UpdateIncidentAnswerMutation,
  UpdateIncidentAnswerMutationVariables,
} from '__generated__/graphql';
import gql from 'graphql-tag';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export const useUpdateIncidentAnswer = () => {
  const client = useGraphQLClient();
  const updateIncidentAnswer = useCallback(
    async (input: IncidentAnswerUpdateInput) => {
      const result = await client.request<UpdateIncidentAnswerMutation, UpdateIncidentAnswerMutationVariables>({
        document: UPDATE_INCIDENT_ANSWER_MUTATION,
        variables: {
          incidentAnswer: input,
        },
      });

      if (isNil(result.updateIncidentAnswer)) {
        throw Error(`result was ${result.updateIncidentAnswer}`);
      }

      return result.updateIncidentAnswer;
    },
    [client]
  );

  return updateIncidentAnswer;
};

const UPDATE_INCIDENT_ANSWER_MUTATION = gql`
  mutation UpdateIncidentAnswer($incidentAnswer: IncidentAnswerUpdateInput) {
    updateIncidentAnswer(incidentAnswer: $incidentAnswer) {
      id
    }
  }
`;
