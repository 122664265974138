import {
  SCHEDULING_REMINDER_COST_CODE_FRAGMENT,
  SCHEDULING_REMINDER_EQUIPMENT_FRAGMENT,
  SCHEDULING_REMINDER_PROJECT_FRAGMENT,
  SCHEDULING_SCHEDULED_MEMBER_FRAGMENT,
} from 'containers/scheduling/apollo/scheduling-fragments';
import gql from 'graphql-tag';

export const QUERY_SCHEDULE_REMINDERS = gql`
  query ScheduleReminders(
    $filter: ScheduleReminderFilter
    $sort: [ScheduleReminderSort!]
    $first: Int
    $after: String
  ) {
    scheduleReminders(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      organizationId
      memberId
      startTime
      endTime
      payload
      type
      createdOn
      cursor
      notifyEmployee
      isDraft
      overrideRecurringScheduleId
      project {
        id
        ...SchedulingReminderProjectFields
      }
      costCode {
        id
        ...SchedulingReminderCostCodeFields
      }
      equipment {
        id
        ...SchedulingReminderEquipmentFields
      }
      links {
        id
        scheduleReminderId
        memberId
        member {
          id
          ...SchedulingScheduledMember
        }
      }
    }
  }
  ${SCHEDULING_SCHEDULED_MEMBER_FRAGMENT}
  ${SCHEDULING_REMINDER_PROJECT_FRAGMENT}
  ${SCHEDULING_REMINDER_COST_CODE_FRAGMENT}
  ${SCHEDULING_REMINDER_EQUIPMENT_FRAGMENT}
`;

export const QUERY_SCHEDULE_REMINDERS_BASIC_LINKS = gql`
  query ScheduleReminderBasicLinks(
    $filter: ScheduleReminderFilter
    $sort: [ScheduleReminderSort!]
    $first: Int
    $after: String
  ) {
    scheduleReminders(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      organizationId
      memberId
      startTime
      endTime
      payload
      type
      createdOn
      cursor
      notifyEmployee
      overrideRecurringScheduleId
      links {
        id
        scheduleReminderId
        memberId
      }
    }
  }
`;
