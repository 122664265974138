import useIframeFeaturesEnabled from 'hooks/meta/useIframeFeaturesEnabled';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { useDispatch } from 'react-redux';
import { setIsInIframe } from 'store/iframe/iframe';
import { IChildrenProps } from 'types/ChildrenProps';
import { isInIframe } from 'utils/windowUtils';

export default function IframeMiddleware({ children }: IChildrenProps) {
  const dispatch = useDispatch();
  const areIframeFeaturesEnabled = useIframeFeaturesEnabled();

  useOnMount(() => {
    if (areIframeFeaturesEnabled) {
      dispatch(setIsInIframe(isInIframe()));
    } else {
      dispatch(setIsInIframe(false));
    }
  });

  return <>{children}</>;
}
