import { Align, Button, Checkbox, Justify, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import AccountPreferencesButton from 'components/domain/account/AccountPreferencesButton/AccountPreferencesButton';
import DraggableList, { IUpdatedDraggable } from 'components/foundation/DraggableList/draggable-list/DraggableList';
import StyledAccordion from 'components/foundation/StyledAccordion/StyledAccordion';
import { cloneDeep } from 'lodash';
import { ReactNode, useState } from 'react';
import IIdable from 'types/Idable';
import { updateIdableInList } from 'utils/collectionUtils';
import { t } from 'utils/localize';
import { isType } from 'utils/typeguard';
import { IColumnFormItem } from '../types/types';
import aggregateTableColumns from '../utils/utils';

type ColumnOption<K extends string = string> = IColumnFormSection<K> | IColumnItem<K>[];
export type ColumnSettings<K extends string = string> = ColumnOption<K>[];

export interface IColumnFormSection<K extends string = string> {
  title: string;
  columns: IColumnItem<K>[];
}

export interface IColumnFormProps<K extends string = string> {
  className?: ClassName;
  columns: ColumnSettings<K>;
  showDisplayPreferences?: boolean;
  renderColumn?: (item: IColumnItem<K>, onCheck: () => void, index: number) => ReactNode;
  submissionEnabled?: (currentOptions: IColumnItem<K>[]) => boolean;
  onSubmit: (data: ColumnSettings<K>) => void;
}
export interface IColumnItem<K extends string = string> extends IColumnFormItem<K>, IIdable<K> {
  draggable: boolean;
}

function ColumnForm<K extends string = string>({
  columns: options,
  renderColumn,
  onSubmit,
  showDisplayPreferences,
  className,
  submissionEnabled,
}: IColumnFormProps<K>) {
  const [unsubmittedColumns, setUnsubmittedColumns] = useState<ColumnSettings<K>>(options);

  function updateColumns(getColumns: (currentColumns: IColumnItem<K>[]) => IColumnItem<K>[], index: number) {
    return (current: ColumnSettings<K>) => {
      const updated = cloneDeep(current);
      const unsubmittedCurrent = current[index];
      if (isType<IColumnFormSection<K>>(unsubmittedCurrent, 'columns')) {
        const newSection = {
          ...unsubmittedCurrent,
          columns: getColumns(unsubmittedCurrent.columns),
        };

        updated[index] = newSection;
        return updated;
      } else {
        updated[index] = getColumns(unsubmittedCurrent);
        return updated;
      }
    };
  }

  function renderColumnRow(index: number) {
    // eslint-disable-next-line react/display-name
    return (column: IColumnItem<K>) => {
      function onCheck() {
        setUnsubmittedColumns(
          updateColumns(
            (currentColumns) =>
              updateIdableInList(currentColumns, column.key, (column) => ({ ...column, visible: !column.visible })),
            index
          )
        );
      }

      if (renderColumn) {
        return renderColumn(column, onCheck, index);
      }

      return (
        <div key={column.key} className="py-2 ml-2 column-item">
          <Checkbox
            data-testid="column_checkbox"
            className="primary-text mr-0"
            checked={column.visible}
            onChange={onCheck}
            label={column.title}
            disabled={column.disabled}
          />
        </div>
      );
    };
  }

  function onUpdate(index: number) {
    return (updatedItems: Array<IUpdatedDraggable<IColumnItem<K>>>) => {
      setUnsubmittedColumns(
        updateColumns(
          () =>
            updatedItems.map<IColumnItem<K>>((item) => {
              return { ...item.payload, position: item.index };
            }),
          index
        )
      );
    };
  }

  function onApply() {
    onSubmit(unsubmittedColumns);
  }

  return (
    <div className={classNames('column-form', className)}>
      {unsubmittedColumns.map((option, index) => {
        if (isType<IColumnFormSection>(option, 'columns')) {
          return (
            <StyledAccordion title={option.title} key={option.title}>
              <DraggableList items={option.columns} renderItem={renderColumnRow(index)} onUpdate={onUpdate(index)} />
            </StyledAccordion>
          );
        } else {
          return (
            <DraggableList
              key={option.map((item) => item.key).toString()}
              items={option}
              renderItem={renderColumnRow(index)}
              onUpdate={onUpdate(index)}
            />
          );
        }
      })}
      <Row justify={Justify.SPACE_BETWEEN} align={Align.CENTER} className="mt-4">
        <Button
          className="mt-4"
          type="primary"
          onClick={onApply}
          disabled={submissionEnabled?.(aggregateTableColumns(unsubmittedColumns))}
        >
          {t('Apply')}
        </Button>
        {showDisplayPreferences && <AccountPreferencesButton label={t('Display Preferences')} />}
      </Row>
    </div>
  );
}

export default ColumnForm;
