import {
  TimeOffPayType,
  TimeOffReportType,
} from 'containers/time-off-report/TimeOffReportSidePanel/TimeOffReportSidePanel';
import TimeOffType from 'types/enum/TimeOffType';
import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  employee_group_id?: string;
  employee_id?: string;
  end_date?: string;
  pay_type?: TimeOffPayType;
  position_id?: string;
  report_type?: TimeOffReportType;
  start_date?: string;
  time_off_type?: TimeOffType;
  time_range_type?: TimeRangeType;
}

export default function useTimeOffReportNavigation() {
  return useNavigation<IParams>('/time-off-report');
}
