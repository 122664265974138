import { MemberPermissions, OperationType } from '__generated__/graphql';
import { TimeOffRibbonTimeFrame } from 'containers/dashboard/types/types';
import gql from 'graphql-tag';
import { asyncReducer, useAsync } from 'hooks';
import useActivePosition from 'hooks/models/position/useActivePosition';
import useTimeOffRequestQuery from 'hooks/models/time-off-requests/useTimeOffRequestQuery';
import useTimeOffApolloQuery from 'hooks/models/time-off/useTimeOffApolloQuery';
import { isNil, partition, sumBy } from 'lodash';
import { Duration } from 'luxon';
import { useCallback } from 'react';
import { t } from 'utils/localize';
import { getTimeOffDashboardRibbonGroupTimeRange } from '../utils/utils';

interface ITimeOffDashboardOption {
  title: string;
  subtitle: string;
  value: string | null;
  subvalue: string;
}

export interface ITimeOffDashboardData {
  pto: ITimeOffDashboardOption;
  nonPto: ITimeOffDashboardOption;
  requests: ITimeOffDashboardOption | null;
}

function getTimeFrameTitle(timeFrame: TimeOffRibbonTimeFrame) {
  if (timeFrame === '30 days') {
    return t('Next 30 Days');
  } else if (timeFrame === '7 days') {
    return t('Next 7 days');
  }

  return t('Upcoming');
}

function getDefaults(timeFrame: TimeOffRibbonTimeFrame, timeOffRequestsEnabled: boolean): ITimeOffDashboardData {
  return {
    pto: {
      title: t('Paid Time Off'),
      subtitle: getTimeFrameTitle(timeFrame),
      value: null,
      subvalue: t('Hours'),
    },
    nonPto: {
      title: t('Unpaid Time Off'),
      subtitle: getTimeFrameTitle(timeFrame),
      value: null,
      subvalue: t('Days'),
    },
    requests: timeOffRequestsEnabled
      ? {
          title: t('Time-Off Requests'),
          subtitle: t('All'),
          value: null,
          subvalue: t('Requests'),
        }
      : null,
  };
}

export default function useTimeOffDashboardData(timeFrame: TimeOffRibbonTimeFrame) {
  const { getAll: getAllTimeOffRequestQuery } = useTimeOffRequestQuery();
  const { getAll: getAllTimeOffQuery } = useTimeOffApolloQuery();
  const position = useActivePosition();

  return useAsync<ITimeOffDashboardData>(
    useCallback(async () => {
      async function getTimeOffRequests() {
        return await getAllTimeOffRequestQuery({
          query: TIME_OFF_REQUEST_DASHBOARD_DATA_QUERY,
          fetchPolicy: 'network-only',
          variables: {
            first: 500,
            filter: {
              approved: { isNull: true },
              deletedOn: { isNull: true },
              member: {
                archivedOn: { isNull: true },
                permissions: {
                  permissions: [MemberPermissions.ManageTimeOff],
                  operationType: OperationType.And,
                },
              },
            },
          },
        });
      }

      const range = getTimeOffDashboardRibbonGroupTimeRange(timeFrame);
      const timeRange = {
        start: range.start.toISO(),
        end: range.end?.toISO(),
      };

      const timeOffs = await getAllTimeOffQuery({
        query: TIME_OFF_DASHBOARD_DATA_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          first: 500,
          filter: {
            deletedOn: { isNull: true },
            dateStamp: timeRange?.end
              ? { between: { start: timeRange.start, end: timeRange.end } }
              : { greaterThanOrEqual: timeRange.start },
            member: {
              archivedOn: { isNull: true },
              permissions: {
                permissions: [MemberPermissions.TimeEvents],
                operationType: OperationType.And,
              },
            },
          },
        },
      });

      const timeOffRequests = await getTimeOffRequests();

      const [pto, nonPto] = partition(timeOffs, (timeOff) => timeOff.paid);
      const ptoSeconds = sumBy(pto, 'seconds');
      const nonPtoCount = nonPto?.length ?? 0;
      const requestCount = timeOffRequests?.length ?? 0;

      return {
        ...getDefaults(timeFrame, (position?.manageTimeEntries ?? 0) > 0),
        pto: {
          title: t('Paid Time Off'),
          subtitle: getTimeFrameTitle(timeFrame),
          value: Duration.fromMillis(ptoSeconds * 1000).toFormat('h:mm'),
          subvalue: t('Hours'),
        },
        nonPto: {
          title: t('Unpaid Time Off'),
          subtitle: getTimeFrameTitle(timeFrame),
          value: nonPtoCount.toString(),
          subvalue: t('Days'),
        },
        requests: !isNil(requestCount)
          ? {
              title: t('Time-Off Requests'),
              subtitle: t('All'),
              value: requestCount.toString(),
              subvalue: t('Requests'),
            }
          : null,
      };
    }, [timeFrame]),
    true,
    asyncReducer,
    false,
    {
      data: getDefaults(timeFrame, (position?.manageTimeOff ?? 0) > 0),
      loading: false,
      error: null,
    }
  );
}

export const TIME_OFF_DASHBOARD_DATA_QUERY = gql`
  query QueryTimeOffDashboardData($first: Int, $after: String, $filter: TimeOffFilter) {
    timeOffs(first: $first, after: $after, filter: $filter) {
      id
      paid
      seconds
      cursor
    }
  }
`;

export const TIME_OFF_REQUEST_DASHBOARD_DATA_QUERY = gql`
  query QueryTimeOffRequestsDashboardData($first: Int, $after: String, $filter: TimeOffRequestFilter) {
    timeOffRequests(first: $first, after: $after, filter: $filter) {
      id
      cursor
    }
  }
`;
