import { Loader, Theme } from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import classNames from 'classnames';
import { HeaderDialog, Panel } from 'components';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import EmployeeSummaryTable from 'components/domain/member/EmployeeSummaryTable/EmployeeSummaryTable';
import EmployeeSummaryDataContext from 'components/domain/member/EmployeeSummaryTable/context/EmployeeSummaryDataContext';
import { useMemberTimeEntryDataReportState } from 'components/domain/time-entry/TimeEntryDataReport/MemberTimeEntryDataReport/contexts/MemberTimeEntryDataReportContext';
import { useTimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import TimeEntryReport from 'components/domain/time-entry/TimeEntryReport/TimeEntryReport';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import RowSettingsDialog from 'components/foundation/dialogs/RowSettingsDialog/RowSettingsDialog';
import TableColumnsDialog from 'components/foundation/table/TableColumnsDialog/TableColumnsDialog';
import TimesheetsColumnsForm from 'containers/timesheets/TimesheetsColumnsForm/TimesheetsColumnsForm';
import { useToastOpen } from 'contexts/ToastContext';
import { useOpenable, useOrganization } from 'hooks';
import useMemberGraphAggregates from 'hooks/aggregates/useMemberGraphAggregates';
import useTimesheetsGraylog from 'hooks/analytics/useTimesheetsGraylog';
import { useTimeEntryExport } from 'hooks/export/useTimeEntryExport';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import useTimeCardsSettingsUpdate from 'hooks/models/member-settings/useTimeCardsSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { isNil, isNull } from 'lodash';
import { DateTime, Duration } from 'luxon';
import Papa from 'papaparse';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { TimesheetView } from 'store/timesheets/Timesheets';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { TimesheetsTypes } from 'utils/constants/graylogActionTypes';
import { convertDurationToHoursDecimal, secondsToHmmString } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import { downloadText } from 'utils/fileUtils';
import ExpandedTimeCardReport from '../ExpandedTimeCardsReport/ExpandedTimeCardReport';
import { ExpandedTimeCardReportContext } from '../ExpandedTimeCardsReport/context/ExpandedTimeCardReportContext';
import TimeCardReport from '../TimeCardReport/TimeCardReport';
import { TimeCardReportContext } from '../TimeCardReport/context/TimeCardReportContext';
import { ITimesheetPrintOptionsData } from '../TimesheetPrintOptionsForm/TimesheetPrintOptions';
import TimesheetPrintOptionsForm from '../TimesheetPrintOptionsForm/TimesheetPrintOptionsForm';
import TimesheetsActionHeader from '../TimesheetsActionHeader/TimesheetsActionHeader';
import TimesheetsHeader from '../TimesheetsHeader/TimesheetsHeader';
import {
  TimeCardReportExpandedBreakFormat,
  TimeCardReportExpandedTimeFormat,
} from '../TimesheetsSidePanel/hooks/useTimeCardSettings';
import WeeklyGridReport from '../WeeklyGridReport/WeeklyGridReport';
import TimesheetsMemberActionBar from '../action-bar/TimesheetsMemberActionBar/TimesheetsMemberActionBar';
import TimesheetsTimeEntryActionBar from '../action-bar/TimesheetsTimeEntryActionBar/TimesheetsTimeEntryActionBar';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import './TimesheetsContent.scss';

interface ITimesheetsContentProps {
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  forward: () => void;
  forwardEnabled: () => boolean;
  back: () => void;
  backEnabled: () => boolean;
  className?: ClassName;
  scrollerCallback: (scroller: HTMLDivElement) => void;
  expandedTimeFormatSettings: TimeCardReportExpandedTimeFormat;
  breakFormat: TimeCardReportExpandedBreakFormat;
}

function TimesheetsContent(props: ITimesheetsContentProps) {
  const {
    timeRange,
    timeRangeType,
    forward,
    back,
    forwardEnabled,
    backEnabled,
    className,
    scrollerCallback,
    expandedTimeFormatSettings,
    breakFormat,
  } = props;

  const { timesheetView, memberGroupId, memberId, projectId, costCodeId, equipmentId, archivedStatus } =
    useTimesheetsQueryParams();
  const memberSettings = useMemberSettings();
  const updateSettings = useTimeCardsSettingsUpdate();
  const brand = useBrandTitle();

  const checkedMemberIds = useSelector<IReduxState, string[]>((state) => state.timesheet.checkedMemberIds);
  const {
    refreshMembersData,
    forceLoadAll: forceLoadTimeCardData,
    areAllSignaturesLoaded,
  } = useContext(TimeCardReportContext);
  const {
    refreshMembersData: refreshExpandedData,
    forceLoadAll: forceLoadExpandedTimeCardData,
    areAllSignaturesLoaded: areAllExpandedSignaturesLoaded,
  } = useContext(ExpandedTimeCardReportContext);
  const {
    data: timeEntryData,
    onDataChange: timeEntryDataChanged,
    loading: timeEntryLoading,
    loadedAll: timeEntryLoadedAll,
    clearData: clearTimeEntryData,
    loadAllGroupedData: loadAllTimeEntryGroupedData,
    loadAllRows: loadAllTimeEntryRows,
  } = useMemberTimeEntryDataReportState();
  const isPro = useFeatureFlags('PRO');

  const timeEntryDialogDetails = useOpenable();
  const timeOffDialogDetails = useOpenable();
  const columnDialogDetails = useOpenable();
  const proUpgradeDialogDetails = useOpenable();
  const printDetails = useOpenable();
  const rowSettingsDialog = useOpenable();

  const [loading, setLoading] = useState(false);
  const errorToast = useToastOpen();
  const [t] = useTranslation();
  const getTimeEntryCsv = useTimeEntryExport();

  const {
    data: graphAggregates,
    error: graphAggregateError,
    refetchData: refetchAggregates,
  } = useMemberGraphAggregates(
    timeRange,
    memberId,
    projectId,
    costCodeId,
    equipmentId,
    memberGroupId,
    2,
    archivedStatus
  );

  const {
    forceLoadAll: forceLoadSummaryData,
    refreshDataForMembers: refreshSummaryData,
    clearData,
  } = useContext(EmployeeSummaryDataContext);

  const nameFormatter = useEmployeeNameFormatter();
  const memberNameFormatter = useCallback(
    (member: IMember) => nameFormatter(member.firstName ?? '', member.lastName ?? ''),
    [nameFormatter]
  );

  const getRows = useTimeEntryDataTableRow();

  const organization = useOrganization();
  const userEvents = useTimesheetsGraylog();
  const tableScroller = useRef<HTMLDivElement>();

  const [rowHeight, setLocalRowHeight] = useState(memberSettings?.web?.features?.timeCards?.rowHeight ?? 'standard');
  const setRowHeight = (newSetting: TableCellHeight) => {
    setLocalRowHeight(newSetting);
    updateSettings([{ key: 'rowHeight', payload: newSetting }]);
  };
  useEffect(() => {
    clearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowHeight]);

  async function exportSummaryView() {
    setLoading(true);
    const rows = await forceLoadSummaryData();

    if (rows.length !== 0) {
      const csv = Papa.unparse(
        rows.map((row) => {
          const result: any = {
            [t('First Name')]: row.member.firstName,
            [t('Last Name')]: row.member.lastName,
          };

          if (!isNil(row.member.memberGroup) && !isNil(row.member.memberGroup.groupName)) {
            result[t('Employee Group')] = row.member.memberGroup.groupName;
          }

          if (organization.breakPolicy) {
            result[t('Break Policy')] = !isNull(row.breakPolicyFollowed)
              ? row.breakPolicyFollowed
                ? t('Yes')
                : t('No')
              : '';
          }

          if (organization.timeAccuracy) {
            result[t('Time Accurate')] = !isNull(row.timeAccurate) ? (row.timeAccurate ? t('Yes') : t('No')) : '';
          }

          if (organization.safetySignature) {
            result[t('Injured')] = !isNull(row.injured) ? (row.injured ? t('Yes') : t('No')) : '';
          }

          // If we decide to allow import/reimport we should get rid of the translation
          // due to having to untranslate on reimporting the file.
          return {
            ...result,
            [t('Regular Time')]: secondsToHmmString(row.regularSeconds ?? 0),
            [t('Regular Time (Decimal)')]: convertDurationToHoursDecimal(
              Duration.fromMillis((row.regularSeconds ?? 0) * 1000),
              2
            ),

            [t('Overtime')]: secondsToHmmString(row.overtimeSeconds ?? 0),
            [t('Overtime (Decimal)')]: convertDurationToHoursDecimal(
              Duration.fromMillis((row.overtimeSeconds ?? 0) * 1000),
              2
            ),

            [t('Double Time')]: secondsToHmmString(row.doubletimeSeconds ?? 0),
            [t('Double Time (Decimal)')]: convertDurationToHoursDecimal(
              Duration.fromMillis((row.doubletimeSeconds ?? 0) * 1000),
              2
            ),
            [t('Paid Time Off')]: secondsToHmmString(row.ptoSeconds ?? 0),
            [t('Total')]: secondsToHmmString(row.totalSeconds ?? 0),
            [t('Total (Decimal)')]: convertDurationToHoursDecimal(
              Duration.fromMillis((row.totalSeconds ?? 0) * 1000),
              2
            ),
          };
        })
      );

      setLoading(false);
      downloadText(csv, `${brand}-${t('timesheets-summary')}-${dateUtils.isoTimeStampLocal()}.csv`);
    } else {
      setLoading(false);
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
    }
  }

  async function exportTimeCardView() {
    setLoading(true);
    const timeCardData = await forceLoadTimeCardData();

    if (timeCardData.length !== 0) {
      const csv = Papa.unparse(
        timeCardData.flatMap((datum) => {
          return datum.rows.map((row) => {
            const result: any = {
              [t('Date')]: row.date.toFormat('ccc, LLL d'),
              [t('First Name')]: datum.firstName,
              [t('Last Name')]: datum.lastName,
            };

            if (organization.breakPolicy) {
              result[t('Break Policy')] = !isNull(row.breakCompliance)
                ? row.breakCompliance
                  ? t('Yes')
                  : t('No')
                : '';
            }

            if (organization.timeAccuracy) {
              result[t('Time Accurate')] = !isNull(row.timeAccurate) ? (row.timeAccurate ? t('Yes') : t('No')) : '';
            }

            if (organization.safetySignature) {
              result[t('Injured')] = !isNull(row.injured) ? (row.injured ? t('Yes') : t('No')) : '';
            }

            // If we decide to allow import/reimport we should get rid of the translation
            // due to having to untranslate on reimporting the file.
            return {
              ...result,
              [t('Regular Time')]: secondsToHmmString(row.regularHours ?? 0),
              [t('Regular Time (Decimal)')]: convertDurationToHoursDecimal(
                Duration.fromMillis((row.regularHours ?? 0) * 1000),
                2
              ),
              [t('Overtime')]: secondsToHmmString(row.overtimeHours ?? 0),
              [t('Overtime (Decimal)')]: convertDurationToHoursDecimal(
                Duration.fromMillis((row.overtimeHours ?? 0) * 1000),
                2
              ),
              [t('Double Time')]: secondsToHmmString(row.doubleTimeHours ?? 0),
              [t('Double Time (Decimal)')]: convertDurationToHoursDecimal(
                Duration.fromMillis((row.doubleTimeHours ?? 0) * 1000),
                2
              ),
              [t('Paid Time Off')]: secondsToHmmString(row.paidTimeOff ?? 0),
              [t('Total')]: secondsToHmmString(row.totalHours ?? 0),
              [t('Total (Decimal)')]: convertDurationToHoursDecimal(
                Duration.fromMillis((row.totalHours ?? 0) * 1000),
                2
              ),
            };
          });
        })
      );

      setLoading(false);
      downloadText(csv, `${brand}-${t('timesheets-time-cards')}-${dateUtils.isoTimeStampLocal()}.csv`);
    } else {
      setLoading(false);
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
    }
  }

  async function exportTimeEntriesView() {
    setLoading(true);
    clearTimeEntryData();
    const groupedData = await loadAllTimeEntryGroupedData();

    if (groupedData === undefined) {
      setLoading(false);
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
      return;
    }

    const timeEntryData = await getRows(timeRange, groupedData, memberNameFormatter, undefined, true, true);

    if (timeEntryData.length !== 0) {
      const showDecimalFormat =
        timesheetView === TimesheetView.EXPANDED_TIME_CARDS &&
        expandedTimeFormatSettings === TimeCardReportExpandedTimeFormat.DECIMAL;
      const csv = getTimeEntryCsv(timeEntryData, showDecimalFormat);

      setLoading(false);
      downloadText(csv, `${brand}-${t('timesheets-time-entries')}-${dateUtils.isoTimeStampLocal()}.csv`);
    } else {
      setLoading(false);
      errorToast({ theme: Theme.DANGER, label: t('There is no data to export.') });
    }
  }

  function onExport() {
    if (isPro) {
      if (timesheetView === TimesheetView.SUMMARY) {
        exportSummaryView();
      } else if (timesheetView === TimesheetView.TIME_CARDS) {
        exportTimeCardView();
      } else {
        exportTimeEntriesView();
      }
      userEvents.events(TimesheetsTypes.events.action_type.EXPORT);
    } else {
      proUpgradeDialogDetails.open();
    }
  }

  function onPrint() {
    printDetails.open();
    userEvents.events(TimesheetsTypes.events.action_type.PRINT);
  }

  function onTimeEntryFormDataSubmit(formData: ITimeActionsFormData) {
    refreshDataForMembers(formData.members);
    timeEntryDialogDetails.close();
  }

  function onTimeOffFormDataSubmit(formData: ITimeOffFormData | undefined) {
    if (formData) {
      refreshDataForMembers(formData.members);
    }
    timeOffDialogDetails.close();
  }

  function refetchAggregatesWithTimeout() {
    setTimeout(refetchAggregates, 1000);
  }

  function refreshDataForMembers(members: string[]) {
    // Timeout because of aggregates... might have to introduce polling.
    if (timesheetView === TimesheetView.SUMMARY || timesheetView === TimesheetView.WEEKLY_GRID) {
      setTimeout(() => refreshSummaryData(members), 1000);
    } else if (timesheetView === TimesheetView.TIME_CARDS) {
      setTimeout(() => refreshMembersData(members, timeRange), 1000);
    } else if (timesheetView === TimesheetView.EXPANDED_TIME_CARDS) {
      setTimeout(() => refreshExpandedData(members, timeRange), 1000);
    } else {
      timeEntryDataChanged(members);
    }

    refetchAggregatesWithTimeout();
  }

  function onBulkEditSubmit(entries: ITimeEntry[]) {
    const memberId = entries[0]?.member?.id;
    if (memberId) {
      refreshDataForMembers([memberId]);
    }
  }

  function setRef(ref: HTMLDivElement) {
    tableScroller.current = ref;
    scrollerCallback(ref);
  }

  function onActionBarTimeEntryEdit(formData: ITimeActionsFormData) {
    refreshDataForMembers(formData.members);
  }

  function renderTimeActionBar() {
    return (
      <TimesheetsTimeEntryActionBar
        onBulkEditSubmit={onBulkEditSubmit}
        onDelete={refreshDataForMembers}
        onTimeEntryEdit={onActionBarTimeEntryEdit}
      />
    );
  }

  function renderMemberActionBar() {
    return (
      <TimesheetsMemberActionBar
        onTimeEntryFormSubmit={onTimeEntryFormDataSubmit}
        onTimeOffFormSubmit={onTimeOffFormDataSubmit}
      />
    );
  }

  async function handlePrint(options: ITimesheetPrintOptionsData, loadAllData: boolean = true) {
    printDetails.close();
    setLoading(true);
    // Need to load everything before doing this
    if (timesheetView === TimesheetView.SUMMARY) {
      await forceLoadSummaryData();
      setLoading(false);
      setTimeout(() => {
        window.print();
      }, 500);
    } else if (timesheetView === TimesheetView.TIME_CARDS) {
      if (loadAllData) {
        await forceLoadTimeCardData();
        setLoading(false);
      }

      setLoading(true);
      setTimeout(() => {
        const loaded = areAllSignaturesLoaded?.();
        if (loaded) {
          setLoading(false);
          setTimeout(() => {
            window.print();
          }, 1000);
        } else {
          handlePrint(options, false);
        }
      }, 2000);
    } else if (timesheetView === TimesheetView.EXPANDED_TIME_CARDS) {
      if (loadAllData) {
        await forceLoadExpandedTimeCardData(options.showAllEmployees);
        setLoading(false);
      }
      setLoading(true);
      setTimeout(() => {
        const loaded = areAllExpandedSignaturesLoaded?.();
        if (loaded) {
          setLoading(false);
          setTimeout(() => {
            window.print();
          }, 1000);
        } else {
          handlePrint(options, false);
        }
      }, 2000);
    } else {
      await loadAllTimeEntryRows();
      setLoading(false);
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }

  const classes = classNames('timesheets-content', className);
  return (
    <Panel className={classes} forwardRef={tableScroller}>
      {loading && <Loader isOpen={loading} />}
      <TimesheetsActionHeader
        className="no-print"
        timeRange={timeRange}
        timeRangeType={timeRangeType}
        onTimeRangeForward={forward}
        onTimeRangeBackward={back}
        forwardDisabled={!forwardEnabled()}
        backwardDisabled={!backEnabled()}
        onTimeEntryAdd={timeEntryDialogDetails.open}
        onTimeOffAdd={timeOffDialogDetails.open}
        onColumnsClick={columnDialogDetails.open}
        onExport={onExport}
        onPrint={onPrint}
        onRowHeightClick={rowSettingsDialog.open}
        actionBar={timesheetView === TimesheetView.TIME_ENTRIES ? renderTimeActionBar() : renderMemberActionBar()}
      />

      <div ref={setRef} className="timesheets-scrollable-content">
        <TimesheetsHeader
          className="m-6 no-print"
          timeRange={timeRange}
          graphData={graphAggregates}
          aggregateError={graphAggregateError}
          showDecimalFormat={expandedTimeFormatSettings === TimeCardReportExpandedTimeFormat.DECIMAL}
        />
        {timesheetView === TimesheetView.SUMMARY && (
          <EmployeeSummaryTable
            timeRange={timeRange}
            timeRangeType={timeRangeType}
            scroller={tableScroller.current}
            onDataChange={refetchAggregatesWithTimeout}
            rowHeight={rowHeight}
          />
        )}
        {timesheetView === TimesheetView.TIME_CARDS && (
          <TimeCardReport
            timeRange={timeRange}
            timeRangeType={timeRangeType}
            scroller={tableScroller.current}
            onDataChange={refetchAggregatesWithTimeout}
            rowHeight={rowHeight}
          />
        )}
        {timesheetView === TimesheetView.EXPANDED_TIME_CARDS && (
          <ExpandedTimeCardReport
            timeRange={timeRange}
            timeRangeType={timeRangeType}
            scroller={tableScroller.current}
            onDataChange={refetchAggregatesWithTimeout}
            showDecimalFormat={expandedTimeFormatSettings === TimeCardReportExpandedTimeFormat.DECIMAL}
            breakFormat={breakFormat}
          />
        )}
        {timesheetView === TimesheetView.TIME_ENTRIES && (
          <TimeEntryReport
            onDataChange={refreshDataForMembers}
            data={timeEntryData}
            loading={timeEntryLoading}
            timeRange={timeRange}
            isLoadedAll={timeEntryLoadedAll}
            rowHeight={rowHeight}
          />
        )}
        {timesheetView === TimesheetView.WEEKLY_GRID && (
          <>
            <WeeklyGridReport
              onDataChange={refreshDataForMembers}
              timeRange={timeRange}
              scroller={tableScroller.current}
              isLoadedAll={timeEntryLoadedAll}
            />
          </>
        )}
      </div>

      <TimeActionsFormDialog
        type={'add'}
        isOpen={timeEntryDialogDetails.isOpen}
        onClose={timeEntryDialogDetails.close}
        onSubmit={onTimeEntryFormDataSubmit}
        onDelete={onTimeEntryFormDataSubmit}
      />

      <TimeOffFormDialog
        isOpen={timeOffDialogDetails.isOpen}
        onClose={timeOffDialogDetails.close}
        memberIds={checkedMemberIds}
        onSubmit={onTimeOffFormDataSubmit}
      />

      <TableColumnsDialog
        isOpen={columnDialogDetails.isOpen}
        onClose={columnDialogDetails.close}
        columnsForm={<TimesheetsColumnsForm timesheetView={timesheetView} onClose={columnDialogDetails.close} />}
      />

      <RowSettingsDialog
        isOpen={rowSettingsDialog.isOpen}
        onClose={rowSettingsDialog.close}
        initialValue={rowHeight}
        onSubmit={setRowHeight}
      />

      <ProFeatureDialog isOpen={proUpgradeDialogDetails.isOpen} onClose={proUpgradeDialogDetails.close} />

      <HeaderDialog
        isOpen={printDetails.isOpen}
        title={t('Print Options')}
        onClose={printDetails.close}
        divider={false}
      >
        <div className="p-8 mb-6">
          <TimesheetPrintOptionsForm onPrint={(options) => handlePrint(options, true)} />
        </div>
      </HeaderDialog>
    </Panel>
  );
}

export default TimesheetsContent;
