import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import * as React from 'react';
import { Location, NavLink, NavLinkProps, PathMatch, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import NavItemContent from '../NavHeader/NavItemContent/NavItemContent';
import { ClassName } from '@busybusy/webapp-react-ui';

export type CustomIsRouteActiveSignature = (match: PathMatch<string> | null, location: Location) => boolean;
export interface INavItemProps extends Omit<NavLinkProps, 'className'> {
  children: ReactNode;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  hidden?: boolean;
  isActive?: boolean | undefined | null | CustomIsRouteActiveSignature;
  to: string;
  className?: ClassName;
}

const NavItem: React.FC<INavItemProps> = ({
  children,
  to,
  icon,
  hidden = false,
  isActive,
  className,
  ...passthroughProps
}) => {
  const location = useLocation();
  const resolvedPath = useResolvedPath(to);
  const match = useMatch(resolvedPath.pathname);
  const isActiveOverride = typeof isActive === 'function' ? isActive(match, location) : isActive;

  const getClassName = ({ isActive: isActiveRoute }: { isActive: boolean }) => {
    const active = !isNil(isActiveOverride) ? isActiveOverride : isActiveRoute;
    return classNames('nav-item', 'no-text-select', className, { active });
  };

  return !hidden ? (
    <NavLink {...passthroughProps} to={to} className={getClassName}>
      <NavItemContent icon={icon}>{children}</NavItemContent>
    </NavLink>
  ) : (
    <></>
  );
};

export default NavItem;
