import { useApolloClient } from '@apollo/client';
import { LaborMetricsInterval } from '__generated__/graphql';
import { MEMBERS_WITH_ALL_JOINED_DATA_QUERY } from 'apollo/queries/member-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { hasTimeOffColumn } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { useApolloPaging, useOrganization } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import _, { some } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { IMember } from 'types';
import ICursorable from 'types/Cursorable';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { dateTimeFromISOWithoutZone, getDateTimesBetween } from 'utils/dateUtils';
import { getRegularMetricTimeTotals } from 'utils/jitMetricUtils';
import { timeRangesAreEqual } from 'utils/timeRangeUtils';
import { ITimeCardReportMemberState, TimeCardReportContext, TimeCardReportMemberData } from './TimeCardReportContext';

export interface PayPeriodSignaturesLoaded {
  memberId: string;
  signaturesLoaded: boolean | null;
}

interface ITimeCardReportContextProviderProps {
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  memberId?: string | null;
  memberGroupId?: string | null;
  positionId?: string | null;
  children: ReactNode | ReactNode[];
  archivedStatus: ArchivedStatus;
  permission: MemberPermission.TIME_EVENTS | MemberPermission.MANAGE_TIME_ENTRIES;
}

export default function TimeCardReportContextProvider({
  memberId,
  memberGroupId,
  positionId,
  timeRange,
  children,
  archivedStatus,
  permission,
  timeRangeType,
}: ITimeCardReportContextProviderProps) {
  const client = useApolloClient();
  const [data, setData] = useState<ITimeCardReportMemberState | null>(null);
  const [members, setMembers] = useState<IMember[]>([]);
  const columnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.summaryAndTimeCardTableColumns
  );
  const archivedOn = useMemo(() => getApolloArchivedTimestampComparison(archivedStatus), [archivedStatus]);
  const { getAll } = useApolloPaging();
  const [loadedAll, setLoadedAll] = useState(false);
  const clearedData = useRef<boolean>(false);
  const getSortWithNameFormat = useApolloMemberNameSort();
  const employeeNameFormatter = useEmployeeNameFormatter();

  const signaturesLoaded = useRef<PayPeriodSignaturesLoaded[]>([]);
  const organization = useOrganization();
  const timeRangeIsPayPeriod = timeRangeType === TimeRangeType.PAY_PERIOD;
  const showSignaturesFooter =
    timeRangeIsPayPeriod && organization.signatureDate && timeRange.endTime < DateTime.local();

  useEffect(() => {
    clearData();
  }, [memberId, memberGroupId, positionId, archivedOn, getSortWithNameFormat, columnSettings]);

  async function populateData(range: ITimeRange<DateTime>) {
    const days = getDateTimesBetween(range.startTime, range.endTime);
    const memberData = members.map<Promise<TimeCardReportMemberData>>((member) => {
      const existingData = data?.byId[member.id];

      // When changing the time range we clear the data but it still has the previous data value when this is called.
      // This is why we need a check to see if the time range has changed. If it has we won't use the cached values.
      // This prevents having the previous data show in the list when changing time ranges.
      // But when scrolling we won't refetch all of the existing data when we load a few more rows.
      if (existingData && timeRangesAreEqual(range, timeRange) && clearedData.current === false) {
        return Promise.resolve(existingData);
      } else {
        return populateMemberData(member, range, days);
      }
    });
    const results = await Promise.all(memberData);
    setData({ byId: _.keyBy(results, (result) => result.id) });
    clearedData.current = false;
  }

  async function populateMemberData<T extends IMember>(
    member: T,
    range: ITimeRange<DateTime>,
    days: DateTime[]
  ): Promise<TimeCardReportMemberData> {
    if (!signaturesLoaded.current.map((item) => item.memberId).includes(member.id)) {
      signaturesLoaded.current.push({ memberId: member.id, signaturesLoaded: null });
    }
    const signOffs = member.safetySignatures ?? [];
    const metricsDictionary = _.keyBy(member.memberLaborMetrics, (daySecond) => daySecond.start);

    const dataRows = days.map((day) => {
      const dayStart = day.startOf('day');
      const dayEnd = day.endOf('day');

      const filteredSignatures = signOffs.filter((signature) => {
        return (
          dateTimeFromISOWithoutZone(signature.startTime) <= dayEnd &&
          dateTimeFromISOWithoutZone(signature.endTime) >= dayStart
        );
      });

      const memberDayMetrics = getRegularMetricTimeTotals(metricsDictionary[dayStart.toISODate()]);
      const orderedSignatures = _.orderBy(filteredSignatures, (item) => item.endTime, ['desc']);

      const hasTimeEntryTime = memberDayMetrics.totalSeconds > 0;

      let regularSeconds = memberDayMetrics.regularSeconds ?? 0;
      if (!hasTimeOffColumn(columnSettings) && memberDayMetrics) {
        const updatedRegularTotal = memberDayMetrics.regularSeconds + memberDayMetrics.paidTimeOffSeconds;
        regularSeconds = updatedRegularTotal > 0 ? updatedRegularTotal : 0;
      }

      return {
        date: day,
        timeAccurate: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures[0].timeAccurate
            : null
          : null,
        injured: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures.filter((signature: any) => signature.injured).length > 0
            : null
          : null,
        breakCompliance: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures[0].breakPolicyFollowed
            : null
          : null,
        regularHours: regularSeconds,
        overtimeHours: memberDayMetrics.overtimeSeconds,
        doubleTimeHours: memberDayMetrics.doubleTimeSeconds,
        paidTimeOff: memberDayMetrics.paidTimeOffSeconds,
        break: 0, // we're not implementing this for now
        totalHours: memberDayMetrics.totalSeconds,
      };
    });

    return {
      ...member,
      totalSeconds: _.sumBy(dataRows, (row) => row.totalHours ?? 0),
      rows: dataRows,
    };
  }

  const getMoreMembers = (
    timeRange: ITimeRange<DateTime>,
    first: number,
    employeeIds: string[] | null,
    cursor?: string
  ): Promise<IMember[]> => {
    const variables: any = {
      ...getMemberVariables(timeRange, first, employeeIds, cursor),
    };

    return new Promise((resolve, reject) => {
      client
        .query<{ members: IMember[] }>({
          fetchPolicy: 'network-only',
          query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
          variables,
        })
        .then((result) => {
          resolve(result.data.members);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  function getTimeCardReportDataForMember(member: IMember) {
    return data?.byId[member.id] ?? null;
  }

  // TODO for some reason this is reloading all the tables.
  async function refreshMembersData(ids: string[], range: ITimeRange<DateTime>) {
    const clonedData = _.cloneDeep(data);
    if (clonedData) {
      const variables = getMemberVariables(range, ids.length, null, null);
      // we're adding this filter separately because we want to also filter by hasTime
      // in case content was created outside of our visible time range
      variables.filter.id = { contains: ids };

      const retrievedMembersQuery = await client.query<{ members: IMember[] }>({
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        fetchPolicy: 'network-only',
        variables,
      });
      const retrievedMembers = retrievedMembersQuery.data.members;

      await updateTimEntryData(clonedData, retrievedMembers, range);
      // setData needs to be called before setMembers because setMembers triggers populateData() to get called which reverts the state of data
      setData(clonedData);

      const clonedMembers = _.cloneDeep(members.filter((m) => !ids.includes(m.id))).concat(retrievedMembers);
      const sortedMembers = _.sortBy(clonedMembers, (member) =>
        employeeNameFormatter(member.firstName ?? '', member.lastName ?? '').toLowerCase()
      );

      // if there were members loaded then we need to check if these missing members would have been loaded later when scrolling
      // we need to do this to prevent having duplicates or items out of sort
      if (!_.isEmpty(members) && loadedAll === false) {
        const lastLoadedMember = _.last(members)!;
        const indexOfLast = _.findIndex(sortedMembers, (member) => member.id === lastLoadedMember.id);
        const newContent = sortedMembers.splice(0, indexOfLast + 1);

        setMembers(newContent); // keep our last loaded member as the last member in our list
        updateSignaturesLoaded(newContent.map((item) => item.id));
      } else {
        setMembers(sortedMembers);
        updateSignaturesLoaded(sortedMembers.map((item) => item.id));
      }
    }
  }

  async function updateTimEntryData(
    clonedData: ITimeCardReportMemberState,
    members: IMember[],
    range: ITimeRange<DateTime>
  ) {
    const days = getDateTimesBetween(range.startTime, range.endTime);
    const updatedData = members
      .filter((member) => !_.isNil(member))
      .map(async (member) => {
        return populateMemberData(member, range, days);
      });

    const results = await Promise.all(updatedData);
    results.forEach((result) => {
      clonedData.byId[result.id] = result;
    });
  }

  function getMemberVariables(
    timeRange: ITimeRange<DateTime>,
    first: number,
    memberIds: string[] | null,
    after?: string | null
  ) {
    const sort = getSortWithNameFormat('asc');
    const variables: any = {
      first,
      after: after ?? undefined,
      filter: {
        permissions:
          permission === MemberPermission.TIME_EVENTS
            ? {
                permissions: permission,
                operationType: OperationType.AND,
                includeSelf: true,
              }
            : {
                permissions: permission,
                operationType: OperationType.AND,
              },
        memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
        id: memberId ? { equal: memberId } : undefined,
        archivedOn,
        positionId: positionId ? { equal: positionId } : undefined,
      },
      sort,
    };

    if (memberIds && _.isNil(memberId)) {
      variables.filter.id = { contains: memberIds };
    }

    // optimize report by only loading the members that will show up
    // no need to optimize if we are scoped to certain members
    if (_.isNil(memberId) && _.isEmpty(memberIds)) {
      variables.filter.hasTime = {
        startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        paidTimeOff: true,
      };
    }

    variables.metricsInterval = LaborMetricsInterval.Day;
    variables.metricsStartDate = timeRange.startTime.toUTC().toISODate();
    variables.metricsEndDate = timeRange.endTime.toUTC().toISODate();

    variables.safetySignaturesFilter = {
      startTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
      endTime: { lessThanOrEqual: timeRange.endTime.toISO() },
      deletedOn: { isNull: true },
      member: {
        permissions: {
          permissions: MemberPermission.MANAGE_TIME_ENTRIES,
          operationType: OperationType.AND,
        },
      },
    };
    variables.safetySignaturesSort = [{ startTime: 'asc' }, { createdOn: 'asc' }];

    variables.memberTimeDocumentFilter = {
      startTime: { lessThanOrEqual: timeRange.endTime.toISO() },
      endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
      deletedOn: { isNull: true },
    };
    variables.memberTimeDocumentSort = [{ submittedOn: 'desc' }];

    return variables;
  }

  async function forceLoadAll(): Promise<TimeCardReportMemberData[]> {
    try {
      // TODO Set members and refactor. Might cause performance problems to have all
      // the rows loaded especially with a line for each member for each day
      const remainingMembers = await getAll<IMember & ICursorable>('members', {
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        variables: getMemberVariables(timeRange, 500, null, _.last(members)?.cursor),
        fetchPolicy: 'network-only',
      });

      const days = getDateTimesBetween(timeRange.startTime, timeRange.endTime);
      const remainingData = remainingMembers.map<Promise<TimeCardReportMemberData>>((member) =>
        populateMemberData(member, timeRange, days)
      );
      const results = await Promise.all(remainingData);
      const cloned = _.cloneDeep(data);

      if (cloned) {
        results.forEach((memberRow) => {
          cloned.byId[memberRow.id] = memberRow;
        });

        setData(cloned);
        setLoadedAll(true);
        setMembers([...members, ...remainingMembers]); // data needs to be set before so that we don't requery data for all the new members
        updateSignaturesLoaded([...members, ...remainingMembers].map((item) => item.id));
        return Object.values(cloned.byId);
      } else {
        setData({ byId: _.keyBy(results, (result) => result.id) });
        setLoadedAll(true);
        setMembers([...members, ...remainingMembers]); // data needs to be set before so that we don't requery data for all the new members
        updateSignaturesLoaded([...members, ...remainingMembers].map((item) => item.id));
        return results;
      }
    } catch (error) {
      setData(null);
      setLoadedAll(true);
      return [];
    }
  }

  function clearData() {
    clearedData.current = true;
    setData(null);
    setMembers([]);
    signaturesLoaded.current = [];
    setLoadedAll(false);
  }

  const didLoad = useCallback((updatedMembers: IMember[], err: boolean, updatedLoadedAll: boolean) => {
    if (!err) {
      setLoadedAll(updatedLoadedAll);
      setMembers(updatedMembers);
      updateSignaturesLoaded(updatedMembers.map((item) => item.id));
    } else {
      // Don't continue loading in the event of an error, cursor will keep being the same resulting in looping
      setLoadedAll(true);
    }
  }, []);

  function areAllSignaturesLoaded(): boolean {
    if (showSignaturesFooter) {
      return !some(signaturesLoaded.current, (item) => item.signaturesLoaded === false);
    }
    return true;
  }

  function onSignaturesLoaded(memberId: string, isLoaded: boolean) {
    const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
    if (index > -1) {
      signaturesLoaded.current[index].signaturesLoaded = isLoaded;
    } else {
      signaturesLoaded.current.push({ memberId, signaturesLoaded: isLoaded });
    }
  }

  function updateSignaturesLoaded(memberIds: string[]) {
    memberIds.forEach((memberId) => {
      const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
      if (index > -1) {
        // If the member is already in the list, do nothing
      } else {
        signaturesLoaded.current.push({ memberId, signaturesLoaded: null });
      }
    });
  }

  const value = useMemo(
    () => ({
      getTimeCardReportDataForMember,
      data,
      members,
      loadedAll,
      getMoreMembers,
      refreshMembersData,
      populateData,
      forceLoadAll,
      didLoad,
      clearData,
      signaturesLoaded: signaturesLoaded.current,
      onSignaturesLoaded,
      areAllSignaturesLoaded,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, members, loadedAll, memberId, memberGroupId, signaturesLoaded.current]
  );

  return <TimeCardReportContext.Provider value={value}>{children}</TimeCardReportContext.Provider>;
}
