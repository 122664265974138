export const dashboardCardBarChartGradientBlueBlue = {
  gradientBottomColor: '#339DFC',
  gradientTopColor: '#03B4FD',
};

export const dashboardCardBarChartGradientYellowYellow = {
  gradientBottomColor: '#FB8315',
  gradientTopColor: '#FFC10A',
};

export const dashboardCardBarChartGradientGreenGreen = {
  gradientBottomColor: '#38E0AC',
  gradientTopColor: '#38E0AC',
};

export const dashboardCardBarChartGradientBlueLightPurple = {
  gradientBottomColor: '#5773FF',
  gradientTopColor: '#7577FF',
};
