import { gql } from '@apollo/client';

export const TIME_OFF_REQUEST_QUERY = gql`
  query QueryTimeOffRequests($first: Int, $after: String, $filter: TimeOffRequestFilter, $sort: [TimeOffRequestSort!]) {
    timeOffRequests(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      answeredBy
      answeredByMember {
        id
        firstName
        lastName
      }
      answeredOn
      approved
      createdOnDst
      cursor
      deletedOn
      description
      paid
      dateStamp
      createdOn
      createdOnLocal
      answeredOn
      submittedOn
      updatedOn
      memberId
      member {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
        position {
          id
          level
        }
        memberGroupId
      }
      rejectionReason
      seconds
      submittedBy
      type
      submittedByMember {
        id
        firstName
        lastName
        memberLock {
          id
          effectiveDate
        }
      }
    }
  }
`;
