import {
  IncidentOutcomeDetailsUpdateInput,
  UpdateIncidentOutcomeDetailsMutation,
  UpdateIncidentOutcomeDetailsMutationVariables,
} from '__generated__/graphql';
import gql from 'graphql-tag';
import useGraphQLClient from 'hooks/graphql/useGraphQLClient/useGraphQLClient';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export const useUpdateIncidentOutcomeDetails = () => {
  const client = useGraphQLClient();
  const updateIncidentOutcomeDetails = useCallback(
    async (input: IncidentOutcomeDetailsUpdateInput) => {
      const result = await client.request<
        UpdateIncidentOutcomeDetailsMutation,
        UpdateIncidentOutcomeDetailsMutationVariables
      >({
        document: UPDATE_INCIDENT_OUTCOME_DETAILS_MUTATION,
        variables: {
          incidentOutcomeDetails: input,
        },
      });

      if (isNil(result.updateIncidentOutcomeDetails)) {
        throw Error(`result was ${result.updateIncidentOutcomeDetails}`);
      }

      return result.updateIncidentOutcomeDetails;
    },
    [client]
  );

  return updateIncidentOutcomeDetails;
};

const UPDATE_INCIDENT_OUTCOME_DETAILS_MUTATION = gql`
  mutation UpdateIncidentOutcomeDetails($incidentOutcomeDetails: IncidentOutcomeDetailsUpdateInput) {
    updateIncidentOutcomeDetails(incidentOutcomeDetails: $incidentOutcomeDetails) {
      id
    }
  }
`;
