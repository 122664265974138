enum RpcMethod {
  CREATE = 'create',
  PASSWORD_RESET_REQUEST = 'password-reset-request',
  PASSWORD_RESET = 'reset-password',
  UNSUBSCRIBE = 'unsubscribe',
  UPGRADE_TO_PRO = 'upgrade-to-pro',
  GET_PRICING = 'get-pricing',
  USERNAME_AVAILABLE = 'username-available',
  GET_BY_ORGANIZATION = 'get-by-organization',
  GET_BY_MEMBER = 'get-by-member',
  DOWNGRADE = 'downgrade-to-free',
  REQUEST_EMAIL_VERIFICATION = 'request-verification-email',
  GET_SUBSCRIPTION_DETAILS = 'get-subscription-details',
  GET_SUBSCRIPTION_PREVIEW = 'get-subscription-preview',
  GET_BILLING_DETAILS = 'get-billing-details',
  UPDATE_BILLING_ADDRESS = 'update-billing-address',
  GET_STATEMENTS = 'get-statements',
  SET_CREDIT_CARD_WITH_TOKEN = 'set-credit-card-with-token',
  GET_PDF_STATEMENT = 'get-pdf-statement',
  CHARGE_ANNUAL_SUBSCRIPTION = 'charge-annual-subscription',
  CANCEL_ANNUAL_SUBSCRIPTION = 'cancel-annual-subscription',
  INVOICE_ANNUAL_SUBSCRIPTION = 'invoice-annual-subscription',
  GET_PDF_INVOICE = 'get-pdf-invoice',
  VERIFY_EMAIL = 'verifyEmail',
  INVITE_MEMBER = 'invite-member',
  WEEKLY_EMAIL_UNSUBSCRIBE = 'weeklyEmailUnsubscribe',
}

export default RpcMethod;
