import gql from 'graphql-tag';

export const CREATE_TIME_ENTRY_BREAK = gql`
  mutation CreateTimeEntryBreak($entryBreak: TimeEntryBreakCreateInput) {
    createTimeEntryBreak(timeEntryBreak: $entryBreak) {
      id
    }
  }
`;

export const UPDATE_TIME_ENTRY_BREAK = gql`
  mutation UpdateTimeEntryBreak($entryBreak: TimeEntryBreakUpdateInput) {
    updateTimeEntryBreak(timeEntryBreak: $entryBreak) {
      id
    }
  }
`;

export const CREATE_TIME_ENTRY_BREAKS = gql`
  mutation CreateTimeEntryBreaks($entryBreaks: [TimeEntryBreakCreateInput!]!) {
    createTimeEntryBreaks(timeEntryBreaks: $entryBreaks) {
      id
    }
  }
`;

export const UPDATE_TIME_ENTRY_BREAKS = gql`
  mutation UpdateTimeEntryBreaks($entryBreaks: [TimeEntryBreakUpdateInput!]!) {
    updateTimeEntryBreaks(timeEntryBreaks: $entryBreaks) {
      id
    }
  }
`;
