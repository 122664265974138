import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY } from 'containers/activity-reports/queries/project-activity-queries';
import { ApolloGetAll } from 'hooks/apollo/useApolloPaging';
import { DateTime } from 'luxon';
import ICursorable from 'types/Cursorable';
import IProject from 'types/Project';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { LaborMetricsInterval } from '__generated__/graphql';
import { MetricQueryProvider } from '../useMetricAggregates';
import {
  PROJECT_LABOR_METRIC_QUERY,
  PROJECT_TIME_METRIC_QUERY,
  PROJECT_WITH_TIME_AND_LABOR_METRIC_QUERY,
  PROJECT_WITH_TIME_AND_TIME_METRIC_QUERY,
} from './project-metric-queries';

export function projectTimeMetricQueryProvider(
  getAll: ApolloGetAll<IProject & ICursorable>,
  projectIds?: string[],
  projectGroupId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const projects = await getAll('projects', {
      query: PROJECT_TIME_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: projectIds ? { contains: projectIds } : undefined,
          projectGroupId: projectGroupId ? { equal: projectGroupId } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          depth: { equal: 1 }, // we only need the top level projects because the children time will be on each object
        },
      },
    });

    return projects.flatMap((project) => project.projectLaborMetrics ?? []);
  };
}

export function projectLaborMetricQueryProvider(
  getAll: ApolloGetAll<IProject & ICursorable>,
  projectIds?: string[],
  projectGroupId?: string,
  archivedStatus?: ArchivedStatus,
  onlyRootProjects?: boolean
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const projects = await getAll('projects', {
      query: PROJECT_LABOR_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        filter: {
          id: projectIds ? { contains: projectIds } : undefined,
          projectGroupId: projectGroupId ? { equal: projectGroupId } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          depth: onlyRootProjects === true ? { equal: 1 } : undefined,
        },
      },
    });

    return projects.flatMap((project) => project.projectLaborMetrics ?? []);
  };
}

export function projectTimeMetricWithTimeFilterQueryProvider(
  getAll: ApolloGetAll<IProject & ICursorable>,
  projectGroupId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const projects = await getAll('projectsWithTime', {
      query: PROJECT_WITH_TIME_AND_TIME_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        startTime: start.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: end.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeAncestors: true,
        includeOpenEntry: false,
        filter: {
          projectGroupId: projectGroupId ? { equal: projectGroupId } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          depth: { equal: 1 }, // we only need the top level projects because the children time will be on each object
        },
      },
    });

    return projects.flatMap((project) => project.projectLaborMetrics ?? []);
  };
}

export function projectLaborMetricWithTimeFilterQueryProvider(
  getAll: ApolloGetAll<IProject & ICursorable>,
  projectGroupId?: string,
  archivedStatus?: ArchivedStatus
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const projects = await getAll('projectsWithTime', {
      query: PROJECT_WITH_TIME_AND_LABOR_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        startTime: start.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: end.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeAncestors: true,
        includeOpenEntry: false,
        filter: {
          projectGroupId: projectGroupId ? { equal: projectGroupId } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
          depth: { equal: 1 },
        },
      },
    });

    return projects.flatMap((project) => project.projectLaborMetrics ?? []);
  };
}

export function projectEquipmentMetricQueryProvider(
  getAll: ApolloGetAll<IProject & ICursorable>,
  projectIds?: string[],
  equipmentIds?: string[],
  archivedStatus?: ArchivedStatus,
  excludeNoProjectTime: boolean = true
): MetricQueryProvider {
  return async (start: DateTime, end: DateTime, interval: LaborMetricsInterval) => {
    const members = await getAll('projectsWithTime', {
      query: PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 100,
        metricsInterval: interval,
        metricsStartDate: start.toISODate(),
        metricsEndDate: end.toISODate(),
        equipmentId: equipmentIds ? { contains: equipmentIds } : undefined,
        equipmentIds,
        filter: {
          id: projectIds ? { contains: projectIds } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(archivedStatus ?? 'all'),
        },
        startTime: start.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: end.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricFilter: excludeNoProjectTime
          ? {
              operationType: 'and',
              nullConditions: { operator: 'isNotNull', field: 'equipmentId' },
            }
          : undefined,
      },
    });

    return members.flatMap((member) => member.projectEquipmentLaborMetrics ?? []);
  };
}
