import { useAxios } from 'hooks';
import { useCallback } from 'react';

export default function useEquipmentImageUpdate() {
  const axiosClient = useAxios();

  function buildFormData(equipmentId: string, file: File) {
    const params = new FormData();
    params.append('id', equipmentId);
    params.append('image', file);
    return params;
  }

  return useCallback(
    (equipmentId: string, file: File | null) => {
      const params = file === null ? { id: equipmentId, image: null } : buildFormData(equipmentId, file);
      return new Promise((resolve, reject) => {
        axiosClient
          .patch('equipment', params)
          .then((result) => {
            const equipment = result.data.data[0];
            resolve(equipment);
          })
          .catch(reject);
      });
    },
    [axiosClient]
  );
}
