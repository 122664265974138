import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import useTimeLogFeedEnabled from 'containers/time-log-feed/useTimeLogFeedEnabled/useTimeLogFeedEnabled';
import { useActiveMember, useOrganization } from 'hooks';
import useDashboardGridSettingsUpdate from 'hooks/models/member-settings/useDashboardGridSettingsUpdate';
import useMemberUiSettingsUpdate from 'hooks/models/member-settings/useMemberUiSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useCanViewBudgets from 'hooks/permission/useCanViewBudgets';
import { cloneDeep, isNil, reduce } from 'lodash';
import { useMemo } from 'react';
import { IDashboardGridSettings } from 'types/DashboardGridSettings';
import { useFeatureFlags } from 'utils/features';
import LocalStore from 'utils/localStorageUtils';
import { DashboardSettingKey, DashboardSettings, defaultDashboardSettings } from '../types/types';

const expenseRelatedDashboardSettings = new Set<DashboardSettingKey>([
  'laborCosts',
  'doubleTimeCosts',
  'overtimeCosts',
  'equipmentCosts',
]);

const equipmentActivityReportsDashboardSettings = new Set<DashboardSettingKey>(['equipmentHours', 'equipmentCosts']);

const timeEventsForNotSelfDashboardSettings = new Set<DashboardSettingKey>(['mostActiveEmployees', 'timeOff']);

const manageProjectDashboardSettings = new Set<DashboardSettingKey>(['mostActiveProjects']);
const manageCostCodesDashboardSettings = new Set<DashboardSettingKey>(['mostActiveCostCodes']);
const manageEquipmentDashboardSettings = new Set<DashboardSettingKey>(['hourMeter', 'mostActiveEquipment']);
const manageBudgetDashboardSettings = new Set<DashboardSettingKey>(['budgets']);

const proSettings = new Set<DashboardSettingKey>(['dailySignatureAlerts', 'photosAndNotes', 'progressEntriesReport']);

const manageProjectCostCodeQuantitySettings = new Set<DashboardSettingKey>(['progressEntriesReport']);
const viewDailyReportSettings = new Set<DashboardSettingKey>(['dailyReports']);
const viewTimeLogFeedSettings = new Set<DashboardSettingKey>(['timeLogs']);

export default function usePermissionScopedDashboardSettings(): [
  DashboardSettings,
  (key: keyof IDashboardGridSettings, payload: IDashboardSettingsItem | undefined) => void,
] {
  const member = useActiveMember();
  const isPro = useFeatureFlags('PRO');
  const memberSettings = useMemberSettings();
  const updateGridSettings = useDashboardGridSettingsUpdate();
  const updateUiMemberSettings = useMemberUiSettingsUpdate();
  const organization = useOrganization();
  const progressReportIsEnabled = useFeatureFlags('WORK_ACCOMPLISHED');
  const dailyReportIsEnabled = useFeatureFlags('DAILY_REPORTS');
  const timeLogFeedEnabled = useTimeLogFeedEnabled();
  const canViewBudgets = useCanViewBudgets();

  function assignNewDailySignatureAlertStatusOptions(settings: DashboardSettings): DashboardSettings {
    if (isNil(settings.dailySignatureAlerts.options?.customQuestions)) {
      return {
        ...settings,
        dailySignatureAlerts: {
          ...settings.dailySignatureAlerts,
          options: {
            ...settings.dailySignatureAlerts.options,
            customQuestions: true,
            customQuestionsPosition: 3,
            hideResolvedCustomQuestions: true,
          },
        },
      };
    } else {
      return settings;
    }
  }

  return useMemo(() => {
    if (isNil(memberSettings?.web?.ui?.dashboard?.localStoreMigrated)) {
      const localDashboardSettings = LocalStore.get('dashboard-settings');
      const localEnabledBy4 = LocalStore.get('dashboardGridBy4');
      updateUiMemberSettings('dashboard', {
        dashboardGridSettings: localDashboardSettings ?? defaultDashboardSettings,
        localStoreMigrated: true,
        fourCardsPerRow: localEnabledBy4 ?? false,
      });
    }

    const mergedSettings = Object.assign(
      cloneDeep(defaultDashboardSettings),
      memberSettings?.web?.ui?.dashboard?.dashboardGridSettings
    );

    const fixedMergedSettings = assignNewDailySignatureAlertStatusOptions(mergedSettings);

    const memberCanManageExpenses = member.position?.viewMemberExpenses ?? false;
    const canManageTimeEventsForOthers = (member.position?.timeEvents ?? 0) > 1;
    const canManageEquipment = member.position?.manageEquipment ?? false;
    const canManageProjects = member.position?.manageProjects ?? false;
    const canManageCostCodes = member.position?.manageCostCodes ?? false;
    const canViewAllActivityReports = member.position?.viewAllActivityReport ?? false;
    const canViewEquipmentActivityReports = canManageEquipment && canViewAllActivityReports;
    const canManageCompanySettings = member.position?.manageCompanySettings ?? false;
    const canViewProgress =
      progressReportIsEnabled &&
      isPro &&
      (member.position?.canManageProjectCostCodeQuantity ?? 0) > 0 &&
      (organization.trackCostCode ?? false);

    const permissionMap = [
      { permission: memberCanManageExpenses, settings: expenseRelatedDashboardSettings },
      { permission: canManageTimeEventsForOthers, settings: timeEventsForNotSelfDashboardSettings },
      { permission: canManageEquipment, settings: manageEquipmentDashboardSettings },
      { permission: canManageProjects, settings: manageProjectDashboardSettings },
      { permission: canManageCostCodes, settings: manageCostCodesDashboardSettings },
      { permission: canManageEquipment, settings: manageEquipmentDashboardSettings },
      { permission: canViewEquipmentActivityReports, settings: equipmentActivityReportsDashboardSettings },
      { permission: isPro || canManageCompanySettings, settings: proSettings },
      { permission: canViewProgress, settings: manageProjectCostCodeQuantitySettings },
      { permission: canViewBudgets, settings: manageBudgetDashboardSettings },
      { permission: dailyReportIsEnabled, settings: viewDailyReportSettings },
      { permission: timeLogFeedEnabled, settings: viewTimeLogFeedSettings },
    ];

    const permissibleSettings = reduce(
      fixedMergedSettings,
      (acc, cur) => {
        const key = cur.key as DashboardSettingKey;
        const included = permissionMap.every(({ permission, settings }) => permission || !settings.has(key));
        if (included) {
          return { ...acc, [key]: cur };
        } else {
          return acc;
        }
      },
      {} as Partial<DashboardSettings>
    ) as DashboardSettings;

    const setSettings = (
      key: keyof IDashboardGridSettings,
      payload: IDashboardGridSettings[keyof IDashboardGridSettings]
    ) => {
      updateGridSettings([{ key, payload }]);
    };

    return [permissibleSettings, setSettings];
  }, [
    memberSettings?.web?.ui?.dashboard?.localStoreMigrated,
    memberSettings?.web?.ui?.dashboard?.dashboardGridSettings,
    member.position?.viewMemberExpenses,
    member.position?.timeEvents,
    member.position?.manageEquipment,
    member.position?.manageProjects,
    member.position?.manageCostCodes,
    member.position?.viewAllActivityReport,
    member.position?.manageCompanySettings,
    member.position?.canManageProjectCostCodeQuantity,
    progressReportIsEnabled,
    canViewBudgets,
    isPro,
    organization.trackCostCode,
    dailyReportIsEnabled,
    timeLogFeedEnabled,
    updateUiMemberSettings,
    updateGridSettings,
  ]);
}
