import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { IEmployeeTimersTableRowInfo } from 'components/domain/member/EmployeeTimersTable/context/EmployeeTimersDataContext';
import { IMember } from 'types';
import { IVisibleTableColumn } from 'types/TableColumn';

export interface IStoreEmployeeTimers {
  checkedMemberRows: IMember[];
  employeeTableClockedInColumns: IVisibleTableColumn[];
  employeeTableClockedOutColumns: IVisibleTableColumn[];
  employeeTableOnBreakColumns: IVisibleTableColumn[];
  employeeTableColumns: IVisibleTableColumn[];
  employeeDetailsGridItems: IDashboardSettingsItem[];
  data: IEmployeeTimersTableRowInfo[];
}

export const initialState: IStoreEmployeeTimers = {
  checkedMemberRows: [],
  employeeTableClockedInColumns: [],
  employeeTableClockedOutColumns: [],
  employeeTableOnBreakColumns: [],
  employeeTableColumns: [],
  employeeDetailsGridItems: [],
  data: [],
};

const slice = createSlice({
  name: 'employeeTimers',
  initialState,
  reducers: {
    updateCheckedEmployeeRows: (state: IStoreEmployeeTimers, action: PayloadAction<IMember[]>) => {
      state.checkedMemberRows = action.payload;

      return state;
    },
    clearCheckedEmployeeRows: (state: IStoreEmployeeTimers) => {
      state.checkedMemberRows = [];
      return state;
    },
    updateEmployeeTableClockedInColumns: (
      state: IStoreEmployeeTimers,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.employeeTableClockedInColumns = action.payload;

      return state;
    },
    updateEmployeeTableClockedOutColumns: (
      state: IStoreEmployeeTimers,
      action: PayloadAction<IVisibleTableColumn[]>
    ) => {
      state.employeeTableClockedOutColumns = action.payload;

      return state;
    },
    updateEmployeeTableOnBreakColumns: (state: IStoreEmployeeTimers, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.employeeTableOnBreakColumns = action.payload;

      return state;
    },
    updateEmployeeTableColumns: (state: IStoreEmployeeTimers, action: PayloadAction<IVisibleTableColumn[]>) => {
      state.employeeTableColumns = action.payload;

      return state;
    },
    updateEmployeeDashboardGridItems: (
      state: IStoreEmployeeTimers,
      action: PayloadAction<IDashboardSettingsItem[]>
    ) => {
      state.employeeDetailsGridItems = action.payload;

      return state;
    },
    setEmployeeTimersRowInfo: (state: IStoreEmployeeTimers, action: PayloadAction<IEmployeeTimersTableRowInfo[]>) => {
      state.data = action.payload;
      return state;
    },
    clearEmployeeTimersRowInfo: (state: IStoreEmployeeTimers) => {
      state.data = [];
      return state;
    },
  },
});

export const {
  updateCheckedEmployeeRows,
  clearCheckedEmployeeRows,
  setEmployeeTimersRowInfo,
  clearEmployeeTimersRowInfo,
  updateEmployeeTableClockedInColumns,
  updateEmployeeTableClockedOutColumns,
  updateEmployeeTableOnBreakColumns,
  updateEmployeeTableColumns,
  updateEmployeeDashboardGridItems,
} = slice.actions;
export default slice.reducer;
