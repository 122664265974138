import { gql } from '@apollo/client';

export const TIME_ENTRY_BREAK_LOGS_QUERY = gql`
  query ClientTimeEntryBreakLogs(
    $after: String
    $filter: ClientTimeEntryBreakLogFilter
    $sort: [ClientTimeEntryBreakLogSort!]
    $first: Int
  ) {
    clientTimeEntryBreakLogs(after: $after, filter: $filter, first: $first, sort: $sort) {
      id
      clientTimeEntryLogId
      deviceType
      endLocationId
      startLocationId
      mergedTimeEntryBreakId
      originalTimeEntryBreakId
      timeEntryId
      timeEntryBreakId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryBreakCreatedOn
      timeEntryBreakDeletedOn
      startTimeDst
      endTimeDst
      paidBreak
      deletedOn
      submittedOn
      createdOn
      updatedOn
      cursor
      timeEntryBreak {
        id
        startTime
        startTimeDst
        endTime
        endTimeDst
        deletedOn
        timeEntry {
          id
          startTime
          daylightSavingTime
          endTime
          metaDaylightSavingTime
        }
        clientLogs {
          id
          startTime
          endTime
          actionType
          deviceType
          createdOn
          startLocationId
          endLocationId
          clientTimeEntryLogId
        }

        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        createdOn
        deletedOn
        submittedOn
        updatedOn
      }
    }
  }
`;
