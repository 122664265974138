import { isNil } from 'lodash';
import { createContext, useRef } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';

export interface IChannelContext {
  emit: (channelId: string, payload?: any) => void;
  registerListener: (channelId: string, collect: (payload?: any) => void) => void;
  unregisterListener: (channelId: string) => void;
}

export const ChannelContext = createContext<IChannelContext | null>(null);

export default function ChannelContextProvider({ children }: IChildrenProps) {
  const register = useRef<{ [channelId: string]: ((payload?: any) => void) | undefined }>({});

  const emit = (channelId: string, payload?: any) => {
    if (isNil(register.current[channelId])) {
      throw Error(`Nobody is listening to channelId ${channelId}`);
    }

    register.current[channelId]?.(payload);
  };

  const registerListener = (channelId: string, collect: (payload?: any) => void) => {
    register.current[channelId] = collect;
  };

  const unregisterListener = (channelId: string) => {
    register.current[channelId] = undefined;
  };

  const channel: IChannelContext = {
    emit,
    registerListener,
    unregisterListener,
  };

  return <ChannelContext.Provider value={channel}>{children}</ChannelContext.Provider>;
}
