import LoadingState from 'components/foundation/state-templates/LoadingState/LoadingState';
import { useEffect } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { authenticationSetTokenAction, IStoreAuthentication } from 'store/store';
import { t } from 'utils/localize';

export const Interop: React.VFC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const dispatchAuthenticationSetToken = (encodedAuthenticationToken: IStoreAuthentication['token']) =>
    dispatch(authenticationSetTokenAction(encodedAuthenticationToken));

  const encodedAuthenticationToken = searchParams.get('authorize');

  useEffect(() => {
    // persist the token to the store and to local storage
    dispatchAuthenticationSetToken(encodedAuthenticationToken);

    searchParams.delete('authorize');
    setSearchParams(searchParams, { replace: true });
  }, []);

  return <LoadingState title={t(`Authentication Successful`)} />;
};

export default Interop;
