import useIsInIframe from 'hooks/store/useIsInIframe';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { useDispatch } from 'react-redux';
import useIframeMessageSender from '../useIframeMessageSender/useIframeMessageSender';
import {
  convertIframeMessageDataToAction,
  iframeReceiverLifecycleDestroyedAction,
  iframeReceiverLifecycleInitializedAction,
} from '../utils/utils';

// Example Payload
// {
//   type: 'navigationBar';
//   payload: {
//     action: 'setNavigationBarStatus';
//     status: 'normal';
//   }
// }
//
export default function useIframeMessageListener() {
  const isInIframe = useIsInIframe();
  const sendMessage = useIframeMessageSender();
  const dispatch = useDispatch();

  useOnMount(() => {
    if (isInIframe) {
      window.onmessage = (event) => {
        if (event.type !== 'message') {
          return false;
        }

        const data = event.data;
        const processedAction = convertIframeMessageDataToAction(data);
        const isValidAction = processedAction !== null;

        if (isValidAction) {
          dispatch(processedAction);
        }
      };

      sendMessage(iframeReceiverLifecycleInitializedAction);

      return () => {
        sendMessage(iframeReceiverLifecycleDestroyedAction);
      };
    }
  });
}
