import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { first, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNull } from 'utils/collectionUtils';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { ConditionNullableFieldType, Equipment, LaborMetricsInterval } from '__generated__/graphql';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';

export default function useEquipmentActivityBasicDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const isLoading = useRef<boolean>(false);
  const [data, setData] = useState<IEquipmentActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IEquipmentActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'equipment',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }
  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? SIMPLE_EQUIPMENT_WITH_METRIC_QUERY : SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IEquipmentActivityTableRowInfo, key: keyof IEquipmentActivityTableRowInfo) {
    if (key === 'equipment') {
      return getEquipmentDisplay(item.equipment);
    } else {
      return item[key];
    }
  }

  async function loadData() {
    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const tableRows = mapNotNull(equipment, (equipment) => createTableRowInfo(equipment));
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );
    const remainingData = calculateRemainingActivityData(tableRows, totalData);
    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        equipment: null,
        equipmentRate: 0,
        equipmentCost: 0,
        equipmentTotalCost: 0,
        ...remainingData,
      };
    } else {
      // don't show no equipment item when there is no time allocated to no equipment
      remainingItemRef.current = undefined;
    }

    isLoading.current = false;
    setData(tableRows);
  }

  const createTableRowInfo = (equipment: Equipment): IEquipmentActivityTableRowInfo | null => {
    const metric = laborMetrics(equipment);

    if (!isNil(metric)) {
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
        return null;
      }
      const equipmentCents = (metric?.equipmentCents ?? 0) / 100;

      return {
        id: equipment.id,
        equipment,
        regularHours: metricTotal.regularSeconds,
        regularHoursDec: metricTotal.regularSeconds,
        overtimeHours: metricTotal.overtimeSeconds,
        overtimeHoursDec: metricTotal.overtimeSeconds,
        doubleTimeHours: metricTotal.doubleTimeSeconds,
        doubleTimeHoursDec: metricTotal.doubleTimeSeconds,
        totalHours: metricTotal.totalSeconds,
        totalHoursDec: metricTotal.totalSeconds,
        regularCost: metricTotal.regularCost,
        overtimeCost: metricTotal.overtimeCost,
        doubletimeCost: metricTotal.doubleTimeCost,
        laborBurden: metricTotal.laborBurden,
        totalCost: metricTotal.totalCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentCost: equipmentCents,
        equipmentTotalCost: equipmentCents + metricTotal.totalCost,
      };
    }

    return null;
  };

  const laborMetrics = (equipment: Equipment) => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return first(equipment.equipmentMemberLaborMetrics);
      case ActivityReportType.BY_PROJECT:
        return first(equipment.equipmentProjectLaborMetrics);
      case ActivityReportType.BY_COST_CODE:
        return first(equipment.equipmentCostCodeLaborMetrics);
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return first(equipment.equipmentLaborMetrics);
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    isLoading: isLoading.current,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
