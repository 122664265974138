import { useApolloClient } from '@apollo/client';
import { ListItem, Tray } from '@busybusy/webapp-react-ui';
import { MEMBERS_QUERY_WITH_OPEN_ENTRY } from 'apollo/queries/member-queries';
import { TIME_ENTRIES_WITH_MEMBER_POSITION } from 'apollo/queries/time-entry-queries';
import { PasteIcon, PeopleIcon, PersonIcon, TimeCardIcon } from 'assets/icons';
import classNames from 'classnames';
import { IconButton } from 'components';
import DailySignOffFormDialog from 'components/domain/daily-sign-off/DailySignOff/DailySignOffForm/DailySignOffFormDialog';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import Capsule from 'components/foundation/Capsule/Capsule';
import { EmployeeDashboardClockState } from 'containers/employee-dashboard/EmployeeDashboardSidePanel/EmployeeDashboardTimeActionsCard/EmployeeDashboardTimeActionsCard';
import EmployeeTimersActionToast from 'containers/employee-timers/EmployeeTimersActionToast/EmployeeTimersActionToast';
import { usePermissions, useTimeActions } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import { isEmpty, isNil, isNull } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ITimeEntry from 'types/TimeEntry';
import './QuickSearchEmployeeCard.scss';

export interface IQuickSearchEmployeeCardProps {
  className?: ClassName;
  memberId: string;
  handleDashboardNavigation: (event: React.MouseEvent) => void;
  handleEmployeeLandingNavigation: (event: React.MouseEvent) => void;
  handleManagementNavigation: (event: React.MouseEvent) => void;
  handleTimeCardsNavigation: (event: React.MouseEvent) => void;
}

const QuickSearchEmployeeCard = (props: IQuickSearchEmployeeCardProps) => {
  const {
    className,
    memberId,
    handleDashboardNavigation,
    handleEmployeeLandingNavigation,
    handleManagementNavigation,
    handleTimeCardsNavigation,
  } = props;

  const client = useApolloClient();
  const [member, setMember] = useState<IMember | null>(null);
  const [timeEntry, setTimeEntry] = useState<ITimeEntry | null>(null);
  const dialogEntry = useRef<ITimeEntry | null>(null);
  const [canBeManaged, setCanBeManaged] = useState<boolean>(false);
  const { hasPermissionToManage } = usePermissions();
  const [status, setStatus] = useState<EmployeeDashboardClockState>(EmployeeDashboardClockState.CLOCKED_OUT);
  const timeActions = useTimeActions(handleTimeActionsCompletion);
  const memberFormatter = useEmployeeNameFormatter();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const result = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: TIME_ENTRIES_WITH_MEMBER_POSITION,
      fetchPolicy: 'network-only',
      variables: {
        first: 1,
        filter: {
          memberId: { equal: memberId },
          deletedOn: { isNull: true },
        },
        sort: [{ startTime: 'desc' }, { updatedOn: 'desc' }],
      },
    });

    if (result.errors) {
      return;
    }

    if (!isEmpty(result.data.timeEntries)) {
      setTimeEntry(null); // force state refresh
      setMember(null);
      const entry = result.data.timeEntries[0];
      setTimeEntry(entry);
      dialogEntry.current = entry;
      setStatus(getStatus(entry));
      setMember(entry.member);
      setCanBeManaged(hasPermissionToManage(entry.member, 'manageTimeEntries'));
    } else {
      const memberResult = await client.query<{ members: IMember[] }>({
        query: MEMBERS_QUERY_WITH_OPEN_ENTRY,
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
          filter: {
            id: { equal: memberId },
            archivedOn: { isNull: true },
          },
        },
      });

      if (memberResult.errors) {
        return;
      }
      setTimeEntry(null); // force state refresh
      dialogEntry.current = null;
      setMember(null);
      setMember(memberResult.data.members[0]);
      setCanBeManaged(hasPermissionToManage(memberResult.data.members[0], 'manageTimeEntries'));
    }
  }

  function handleTimeActionsCompletion(memberIds: string[]) {
    timeActions.loaderDetails.close();
    getData();
  }

  function getStatus(entry: ITimeEntry | null | undefined): EmployeeDashboardClockState {
    if (!isNil(entry) && isNil(entry?.endTime)) {
      if (!isNil(entry?.breaks) && !isEmpty(entry?.breaks.filter((b) => isNil(b.endTime)))) {
        return EmployeeDashboardClockState.ON_BREAK;
      }
      return EmployeeDashboardClockState.CLOCKED_IN;
    } else {
      return EmployeeDashboardClockState.CLOCKED_OUT;
    }
  }

  function getTimerStatusIcon() {
    let statusClass = 'clocked-out-circle';
    switch (status) {
      case EmployeeDashboardClockState.CLOCKED_IN:
        statusClass = 'clocked-in-circle';
        break;
      case EmployeeDashboardClockState.ON_BREAK:
        statusClass = 'on-break-circle';
        break;
    }
    return <div className={statusClass} />;
  }

  function getClockActionButtons() {
    let actionButton: ReactNode = (
      <Capsule className={'capsule-button'} title={t('Clock In')} onClick={(event) => handleClockInAction(event)} />
    );
    if (!isNull(timeEntry) && isNil(timeEntry?.endTime)) {
      if (!isEmpty(timeEntry?.breaks.filter((b) => isNil(b.endTime)))) {
        actionButton = (
          <Capsule
            className={'capsule-button'}
            title={t('End Break')}
            onClick={(event) => handleEndBreakAction(event)}
          />
        );
      } else {
        actionButton = (
          <Capsule
            className={'capsule-button'}
            title={t('Clock Out')}
            onClick={(event) => handleClockOutAction(event)}
          />
        );
      }
    }

    return (
      <Tray align="right" spacing={1}>
        <IconButton svg={PersonIcon} onClick={handleDashboardNavigation} tooltipLabel={t('Dashboard')} />
        <IconButton svg={PeopleIcon} onClick={handleEmployeeLandingNavigation} tooltipLabel={t('Employees')} />
        <IconButton svg={TimeCardIcon} onClick={handleTimeCardsNavigation} tooltipLabel={t('Time Cards')} />
        <IconButton svg={PasteIcon} onClick={handleManagementNavigation} tooltipLabel={t('Management List')} />

        {actionButton}
      </Tray>
    );
  }

  function handleDefaultAction(event: React.MouseEvent, closeDropdown?: () => void) {
    closeDropdown?.();
    event.stopPropagation();
    event.preventDefault();
  }

  async function handleClockOutAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && isNil(timeEntry?.endTime) && !isNil(member)) {
      timeActions.performClockOut([{ ...member, openTimeEntry: timeEntry }]);
    }
  }

  function handleClockInAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNil(member)) {
      timeActions.performClockIn([member]);
    }
  }

  async function handleEndBreakAction(event: React.MouseEvent, closeDropdown?: () => void) {
    handleDefaultAction(event, closeDropdown);
    if (!isNull(timeEntry) && !isEmpty(timeEntry?.breaks.filter((b) => isNil(b.endTime))) && !isNil(member)) {
      timeActions.performEndBreak([{ ...member, openTimeEntry: timeEntry }]);
    }
  }

  const classes = classNames('quick-search-employee-card', className);

  return (
    <>
      <div className="pt-4 pb-2 pl-8 pr-6">
        <div className={classes}>
          <Tray spacing={1}>
            <div className="justify-content">
              <div className="timer-status-left-content">
                {getTimerStatusIcon()}
                <ListItem className={'command-pallet-row-item ml-2'} onClick={handleDashboardNavigation}>
                  {memberFormatter(member?.firstName ?? '', member?.lastName ?? '')}
                </ListItem>
              </div>
            </div>
          </Tray>
          {getClockActionButtons()}
        </div>
      </div>
      {!isNull(timeActions.entryDialogType) && (
        <TimeActionsFormDialog
          type={timeActions.entryDialogType}
          timeEntryId={
            timeActions.entryDialogType === 'edit' ||
            timeActions.entryDialogType === 'switch' ||
            timeActions.entryDialogType === 'switch-at' ||
            timeActions.entryDialogType === 'clock-out-at'
              ? !isNull(dialogEntry.current)
                ? dialogEntry.current?.id
                : null
              : null
          }
          isOpen={timeActions.entryDialogDetails.isOpen}
          onClose={timeActions.closeEntryFormDialog}
          memberIds={!isNil(member) ? [member.id] : []}
          onSubmit={timeActions.onEntryFormDialogSubmit}
          onDelete={timeActions.onEntryFormDialogSubmit}
        />
      )}
      <EmployeeTimersActionToast
        isOpen={timeActions.timerActionsToastDetails.isOpen}
        onClose={timeActions.closeTimerActionsToast}
        type={timeActions.timerActionsToastState?.type ?? null}
        startTime={timeActions.timerActionsToastState?.dateTime ?? null}
        timeoutInSeconds={5}
      />
      {timeActions.timeEntryRange.current &&
        timeActions.onSignOffComplete.current &&
        timeActions.currentOpenEntry.current && (
          <DailySignOffFormDialog
            member={timeActions.activeMember}
            timeRange={timeActions.timeEntryRange.current}
            fromReport={false}
            currentOpenEntry={timeActions.currentOpenEntry.current}
            isOpen={timeActions.dailySignOffDetails.isOpen}
            onClose={timeActions.dailySignOffDetails.close}
            onComplete={timeActions.onSignOffComplete.current}
          />
        )}
    </>
  );
};

export default QuickSearchEmployeeCard;
