import { useApolloClient } from '@apollo/client';
import { SortOrder } from '__generated__/graphql';
import { BASIC_TIME_ENTRIES_QUERY } from 'apollo/queries/time-entry-queries';
import { first } from 'lodash';
import ITimeEntry from 'types/TimeEntry';

export interface IFirstTimeEntryArgs {
  memberIds?: string[];
  projectIds?: string[];
  costCodeIds?: string[];
  equipmentIds?: string[];
}

function useFirstTimeEntry() {
  const client = useApolloClient();

  async function getFirstTimeEntry(args: IFirstTimeEntryArgs): Promise<ITimeEntry | undefined> {
    const { memberIds, projectIds, costCodeIds, equipmentIds } = args;
    const results = await client.query<{ timeEntries: ITimeEntry[] }>({
      query: BASIC_TIME_ENTRIES_QUERY,
      variables: {
        first: 1,
        filter: {
          memberId: memberIds ? { contains: memberIds } : undefined,
          costCodeId: costCodeIds ? { contains: costCodeIds } : undefined,
          equipmentId: equipmentIds ? { contains: equipmentIds } : undefined,
          deletedOn: { isNull: true },
          project: projectIds ? { idWithDescendants: { contains: projectIds } } : undefined,
        },
        sort: [{ startTime: SortOrder.Asc }],
      },
      fetchPolicy: 'network-only',
    });

    return first(results.data.timeEntries);
  }

  return getFirstTimeEntry;
}

export default useFirstTimeEntry;
