import { FlaggedTimeEntryLocation, MemberGpsStatus, MemberGpsStatusTypes, ProjectInfo } from '__generated__/graphql';
import { isEmpty, isNil, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import ILocation from 'types/Location';
import { IProjectInfo } from 'types/ProjectInfo';
import ITimeEntry from 'types/TimeEntry';
import { dateTimeFromISOKeepZone, isDateTimeInTimeRange } from './dateUtils';
import { isCloseTo } from './numberUtils';
import { displayEndTime } from './timeEntryUtils';

export function distanceWithProjectRadius(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string = 'M',
  projectRadiusInMeters: number
): number {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);

    dist = (dist * 180) / Math.PI;

    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist - getMiles(projectRadiusInMeters);
  }
}

export function getMiles(meters: number) {
  return meters * 0.000621371192;
}

export function getMeters(miles: number) {
  return miles * 1609.344;
}

const LOCATION_NEAR_OFFSET = 0.00001;
const LOCATION_MOVE_OFFSET = 0.0001;

export function shiftPins<T extends ILocation>(locations: T[]): T[] {
  const seenIds: string[] = [];
  locations.forEach((location) => {
    seenIds.push(location.id!);
    const closeLocations = locations.filter((loc) => {
      return (
        isEmpty(seenIds.filter((e) => e === loc.id)) &&
        isCloseTo(location.lat, loc.lat, LOCATION_NEAR_OFFSET) &&
        isCloseTo(location.lng, loc.lng, LOCATION_NEAR_OFFSET)
      );
    });
    if (!isEmpty(closeLocations)) {
      const radius = LOCATION_MOVE_OFFSET;
      const angleInc = (2 * Math.PI) / (closeLocations.length + 1);
      closeLocations.forEach((l, index) => {
        seenIds.push(l.id!);
        const angle = angleInc * (index + 1);
        l.lng = l.lng - radius + radius * Math.cos(angle);
        l.lat = l.lat + radius * Math.sin(angle);
      });
    }
  });
  return locations;
}

export function isValidLocation(latitude: number, longitude: number) {
  if (longitude === 0 && latitude === 0) {
    return false;
  }

  const isLatitudeValid = latitude <= 90 && latitude >= -90;
  const isLongitudeValid = longitude >= -180 && longitude <= 180;

  return isLatitudeValid && isLongitudeValid;
}

export const getMaxDistance = (item: FlaggedTimeEntryLocation, projectInfo?: ProjectInfo | IProjectInfo) => {
  const getLocationDistanceInMiles = (lat: number, lng: number, projectInfo: ProjectInfo | IProjectInfo) => {
    if (
      projectInfo &&
      projectInfo.latitude &&
      projectInfo.longitude &&
      projectInfo.latitude !== 0 &&
      projectInfo.longitude !== 0 &&
      projectInfo.locationRadius
    ) {
      const distanceInMiles = distanceWithProjectRadius(
        projectInfo.latitude,
        projectInfo.longitude,
        lat,
        lng,
        'M',
        projectInfo.locationRadius
      );

      if (distanceInMiles > 0) {
        return `${distanceInMiles.toFixed(1)} mi`;
      }
    }
    return '---';
  };
  const locationDataArray: { lat: number; lng: number; memberLocationId: string }[] = JSON.parse(item.locationData);
  const maxDistanceLocation = sortBy(locationDataArray, (loc) => {
    return getLocationDistanceInMiles(loc.lat, loc.lng, projectInfo ?? item.timeEntry.project!.projectInfo!);
  }).reverse()[0];

  const maxDistance = getLocationDistanceInMiles(
    maxDistanceLocation.lat,
    maxDistanceLocation.lng,
    projectInfo ?? item.timeEntry.project!.projectInfo!
  );

  return maxDistance;
};

export const getDisabledMemberGpsStatuses = (
  statuses: Pick<MemberGpsStatus, 'status' | 'localTime'>[],
  entry: Pick<ITimeEntry, 'startTime' | 'endTime'>
): Pick<MemberGpsStatus, 'status' | 'localTime'>[] => {
  return statuses.filter(
    (gpsStatus) =>
      gpsStatus.status === MemberGpsStatusTypes.Disabled &&
      isDateTimeInTimeRange(
        DateTime.fromISO(gpsStatus.localTime),
        {
          startTime: dateTimeFromISOKeepZone(entry.startTime),
          endTime: !isNil(entry.endTime) ? displayEndTime(entry)! : DateTime.local(),
        },
        true,
        true
      )
  );
};

export const shouldShowFlaggedTimeEntryLocationIcon = (
  item: FlaggedTimeEntryLocation,
  projectInfo: ProjectInfo | IProjectInfo
) => {
  return isNil(item.status) && getMaxDistance(item, projectInfo) !== '---';
};
