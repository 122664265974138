import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionNullableFieldType,
  CostCode,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Member,
  Scalars,
} from '__generated__/graphql';
import { COST_CODE_NAME_QUERY } from 'apollo/queries/cost-code-queries';
import {
  MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY,
  MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/member-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, first, isEmpty, isNil, keyBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { combineGeneratedMetricWithTimeOff, getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  filterByProjectIdsOrEmptyItemId,
  getActivityItemTotal,
  getMemberTimeOffMetricDictionary,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import useMemberActivityPermission from '../../hooks/useMemberActivityPermission';
import { IMemberActivityTableRowInfo } from './useMemberActivity';

export interface IMemberCostCodeActivityTableRowInfo extends IMemberActivityTableRowInfo {
  costCodeId: string | null;
  costCode: CostCode | null;
}

interface MemberCostCodeMetricRow extends LaborMetricsInterface {
  memberId: Scalars['Uuid']['output'];
  costCodeId?: Maybe<Scalars['Uuid']['output']>;
}

export default function useMemberActivityCostCodeDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>,
  filterIdWithDescendants?: boolean
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const permission = useMemberActivityPermission();
  const canViewCost = useHasCostPermission();
  const nameFormatted = useEmployeeNameFormatter();
  const remainingItemRef = useRef<IMemberCostCodeActivityTableRowInfo>();
  const [data, setData] = useState<IMemberCostCodeActivityTableRowInfo[]>([]);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'member',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return activityIdFilter(filterId, ConditionNullableFieldType.ProjectId, 'projectIds');
      case ActivityReportType.BY_EQUIPMENT:
        return activityIdFilter(filterId, ConditionNullableFieldType.EquipmentId, 'equipmentIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return filterByProjectIdsOrEmptyItemId(idArray, filterIdWithDescendants);
      case ActivityReportType.BY_EQUIPMENT:
        return {
          equipmentId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return canViewCost
          ? MEMBERS_WITH_PROJECT_COST_CODE_METRIC_QUERY
          : MEMBERS_WITH_PROJECT_COST_CODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_EQUIPMENT:
        return canViewCost
          ? MEMBERS_WITH_COST_CODE_EQUIPMENT_METRIC_QUERY
          : MEMBERS_WITH_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost ? MEMBERS_WITH_COST_CODE_METRIC_QUERY : MEMBERS_WITH_COST_CODE_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IMemberCostCodeActivityTableRowInfo, key: keyof IMemberCostCodeActivityTableRowInfo) {
    if (key === 'member') {
      return item.member ? nameFormatted(item.member.firstName ?? '', item.member.lastName ?? '') : '';
    } else {
      return item[key];
    }
  }

  async function getCostCodes(costCodeIds: string[]) {
    if (isEmpty(costCodeIds)) {
      return [];
    }

    return await getAll<CostCode & ICursorable>('costCodes', {
      query: COST_CODE_NAME_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: costCodeIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    const members = await getAll<Member & ICursorable>('members', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          permissions: { permissions: permission, operationType: 'and' },
          hasTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            paidTimeOff: isNil(filterId) || filterId === remainingDataItemId ? true : null,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    // we need to get the time off metrics when showing members filtered by the "no project" (or other variant) item
    // because web services won't include it #suckitdrew
    // time off also needs to be included when we're not scoped to a certain item (project/cost code/equipment)
    let timeOffData: Dictionary<ITimeOffLaborMetrics> | undefined = undefined;
    if (isNil(filterId) || filterId === remainingDataItemId) {
      timeOffData = await getMemberTimeOffMetricDictionary(client, timeRange, canViewCost);
    }

    const costCodeLookup = await getCostCodeLookup(members);
    const tableRows = mapNotNull(members, (member) =>
      createTableRowInfo(costCodeLookup, member, timeOffData?.[member.id])
    );

    // calculate total time in order to show `Additional Time` item if the user doesn't have permission to see everyone
    const totalData = await getActivityItemTotal(
      client,
      filterType,
      filterId,
      timeRange,
      canViewCost,
      filterIdWithDescendants
    );
    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours > 0) {
      remainingItemRef.current = {
        member: null,
        costCodeId: null,
        costCode: null,
        ...remainingData,
      };
    } else {
      // don't show no equipment item when there is no time allocated to no equipment
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  const getCostCodeLookup = async (data: Member[]) => {
    const metrics = data.flatMap((member) => laborMetrics(member));
    const codeCodeIds = mapNotNil(metrics, (item) => item.costCodeId);
    const costCodes = await getCostCodes(uniq(codeCodeIds));

    return keyBy(costCodes, (costCode) => costCode.id);
  };

  const createTableRowInfo = (
    costCodeLookup: Dictionary<CostCode>,
    member: Member,
    timeOffData?: ITimeOffLaborMetrics
  ): IMemberCostCodeActivityTableRowInfo | null => {
    const metrics = laborMetrics(member);
    const hasTimeOff = !isNil(timeOffData) && !isNil(timeOffData.pto) && timeOffData.pto > 0;

    let noCostCodeRow: IMemberCostCodeActivityTableRowInfo | undefined = undefined;
    if (hasTimeOff || metrics.some((metric) => isNil(metric.costCodeId))) {
      // handle case where we have time off, but no `No Cost Code` item to append totals to
      const noCostCode = first(metrics.filter((metric) => isNil(metric.costCodeId))) ?? emptyMetricRow(member.id);
      const metricTotal = getGeneratedMetricLaborTotals(
        combineGeneratedMetricWithTimeOff(noCostCode, timeOffData),
        filterIdWithDescendants === false
      );

      noCostCodeRow = {
        id: member.id + remainingDataItemId,
        costCodeId: remainingDataItemId,
        costCode: null,
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    }

    const unsortedCostCodeRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, filterIdWithDescendants === false);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.costCodeId)) {
        return null;
      }

      return {
        id: member.id + metric.costCodeId,
        costCodeId: metric.costCodeId,
        costCode: costCodeLookup[metric.costCodeId] ?? null,
        member,
        ...activityReportUtils.metricToRowInfo(metricTotal),
      };
    });
    const costCodeRows = sortByIgnoreCase(unsortedCostCodeRows, (row) => getCostCodeDisplay(row.costCode));

    if (isEmpty(costCodeRows) && noCostCodeRow === undefined) {
      return null;
    } else {
      const memberTotalMetrics = aggregateActivityRows(
        noCostCodeRow === undefined ? costCodeRows : [...costCodeRows, noCostCodeRow]
      );
      return {
        ...memberTotalMetrics,
        id: member.id,
        costCodeId: null,
        costCode: null,
        member,
        detailRows: noCostCodeRow === undefined ? costCodeRows : [...costCodeRows, noCostCodeRow],
      };
    }
  };

  const emptyMetricRow = (memberId: string): MemberCostCodeMetricRow => {
    return {
      memberId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (member: Member): MemberCostCodeMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return member.memberProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_EQUIPMENT:
        return member.memberCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return member.memberCostCodeLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
