export const EMPLOYEE_CSV_HEADERS =
  'Employee ID,First Name,Last Name,Username,Password,Payroll Rate,Payroll Type,Labor Burden,Phone,Email,Group Name,Position,Subcontractor,GPS Setting,Kiosk PIN Code';
// tslint:disable-next-line: max-line-length
export const PROJECT_CSV_HEADERS =
  'Project Number,Customer,Address 1,Address 2,City,State,Postal Code,Phone,Project Name,Project Name Lvl 2,Project Name Lvl 3,Project Name Lvl 4,Project Name Lvl 5,Project Name Lvl 6,Project Name Lvl 7,Group Name,Latitude,Longitude,Radius,Reminders,Onsite Verification,Onsite Verification Include Subprojects,Additional Information';
// tslint:disable-next-line: max-line-length
export const PROJECT_REIMPORT_CSV_HEADERS =
  'Project Number,Customer,Address 1,Address 2,City,State,Postal Code,Phone,Path,Project Name,Group Name,Latitude,Longitude,Radius,Reminders,Onsite Verification,Onsite Verification Include Subprojects,Additional Information';
export const COST_CODE_CSV_HEADERS = 'Cost Code,Description,Units of Measure,Group Name';
export const CSV_ID_COLUMN = 'DATA KEY';
export const EQUIPMENT_CSV_HEADERS = 'Name,Category,Make,Model,Year,Hour Meter,Hourly Rate';
