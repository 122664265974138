import { Align, Button, Justify, Row, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { t } from 'i18next';
import { ClassName } from 'types/ClassName';

export interface IPayrollServicesViewProps {
  className?: ClassName;
  backgroundColor?: string;
}

const PayrollServicesView = (props: IPayrollServicesViewProps) => {
  const { className, backgroundColor } = props;
  const brand = useBrandTitle();

  const classes = classNames('payroll-services p-6', className);

  return (
    <div className={classes} style={backgroundColor ? { backgroundColor } : {}}>
      <Row justify={Justify.CENTER} className="mt-6">
        <h2 className="subheading">{t('PAYROLL SERVICES')}</h2>
      </Row>
      <Row justify={Justify.CENTER}>
        <div className="separator my-4"></div>
      </Row>
      <Row justify={Justify.CENTER} align={Align.CENTER} className={'feature-text my-6 mx-12'}>
        {t(
          'Want to be able to process payroll and instantly send out checks with the press of a button? Pricing is only $45 per month, plus $6 per month for each employee. Talk to a {{brand}} representative about getting started with Busy Payroll.',
          { brand }
        )}
      </Row>
      <Row justify={Justify.CENTER}>
        <Button
          className="schedule-button my-8"
          type="link"
          onClick={() => window.open('https://calendly.com/busypayroll/demo')}
          size={Size.LARGE}
        >
          {t('Schedule Appointment')}
        </Button>
      </Row>
    </div>
  );
};

export default PayrollServicesView;
