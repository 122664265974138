import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { type FunctionComponent, type PropsWithChildren } from 'react';
import { type IStoreSessionAuthenticated } from 'store/session/sessionType';
import {
  determineCacheValid,
  useAgreementLocalStoragePrivacy,
  useAgreementLocalStorageTerms,
} from './localStorageCache';
import { ValidateAgreements } from './ValidateAgreements';

export interface IValidateAgreementsCacheCheckProps {
  session: IStoreSessionAuthenticated;
}
export const ValidateAgreementsCacheCheck: FunctionComponent<PropsWithChildren<IValidateAgreementsCacheCheckProps>> = ({
  session,
  children,
}) => {
  const [agreementTerms, setAgreementTerms] = useAgreementLocalStorageTerms();
  const [agreementPrivacy, setAgreementPrivacy] = useAgreementLocalStoragePrivacy();

  const { isValid: isCacheValidTerms, reason: reasonInvalidCacheTerms } = determineCacheValid(agreementTerms, session);
  const { isValid: isCacheValidPrivacy, reason: reasonInvalidCachePrivacy } = determineCacheValid(
    agreementPrivacy,
    session
  );
  const isCacheValid = isCacheValidTerms && isCacheValidPrivacy;
  const invalidCacheReasons = compact(uniq([reasonInvalidCacheTerms, reasonInvalidCachePrivacy]));

  if (!isCacheValid) {
    return (
      <ValidateAgreements
        setAgreementTerms={setAgreementTerms}
        setAgreementPrivacy={setAgreementPrivacy}
        invalidCacheReasons={invalidCacheReasons}
        children={children}
      />
    );
  }

  return <>{children}</>;
};
