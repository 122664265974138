import { ClassName, IFormValidation } from '@busybusy/webapp-react-ui';
import { convertBreakMultipickerItemsToQuickTimeTemplateBreaks } from 'components/domain/time-entry/time-actions-form/utils/utils';
import useQuickTimeTemplates from 'containers/quick-time/hooks/useQuickTimeTemplates';
import QuickTimeTemplateForm, {
  IQuickTimeTemplateFormData,
} from 'containers/quick-time/QuickTimeTemplateForm/QuickTimeTemplateForm';
import { ITimeEntryTemplate } from 'containers/quick-time/types/types';
import { useDefaultTimes } from 'hooks';
import { useMemo } from 'react';
import { t } from 'utils/localize';
import { v_require } from 'utils/validations';
import v_does_not_contain from 'utils/validations/v_does_not_contain';

export interface IQuickTimeTemplateCreateFormProps {
  onSubmit?: (template: ITimeEntryTemplate) => void;
  className?: ClassName;
}

export default function QuickTimeTemplateCreateForm({
  onSubmit: onFormSubmit,
  className,
}: IQuickTimeTemplateCreateFormProps) {
  const { templates, upsert } = useQuickTimeTemplates();
  const { defaultStartTime, defaultEndTime } = useDefaultTimes();
  const formData = useMemo(
    () => ({
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      name: '',
      members: [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultStartTime.toSeconds(), defaultEndTime.toSeconds()]
  );

  const templateNameValidation: Array<IFormValidation<string | undefined, any>> = useMemo(() => {
    return [
      { validation: v_require },
      {
        validation: v_does_not_contain,
        args: { inValues: Object.keys(templates), message: t('Template name already in use.') },
      },
    ];
  }, [templates]);

  function onSubmit(formData: IQuickTimeTemplateFormData) {
    const position = Object.keys(templates).length;
    const template: ITimeEntryTemplate = {
      name: formData.name.trim(),
      startTime: formData.startTime.toISOTime({ includeOffset: false, suppressMilliseconds: true }),
      endTime: formData.endTime.toISOTime({ includeOffset: false, suppressMilliseconds: true }),
      breaks: (formData.breaks ?? []).map((brk) =>
        convertBreakMultipickerItemsToQuickTimeTemplateBreaks(brk, formData.startTime)
      ),
      position,
      members: formData.members ?? null,
      projectId: formData.projectId ?? null,
      costCodeId: formData.costCodeId ?? null,
      equipmentId: formData.equipmentId ?? null,
      description: formData.description ?? null,
    };
    upsert(template);
    onFormSubmit?.(template);
  }

  return (
    <QuickTimeTemplateForm
      formData={formData}
      templateNameValidation={templateNameValidation}
      onSubmit={onSubmit}
      className={className}
      submissionText={t('Create')}
    />
  );
}
