import { PhoneNumberUtil } from 'google-libphonenumber';
import _, { isNil } from 'lodash';
import AggregateCustomReportType from 'types/enum/AggregateCustomReportType';
import AggregatePlatformReportType from 'types/enum/AggregatePlatformReportType';
import { Optional } from 'types/util/Optional';
import { t } from './localize';
import { stringify } from './queryStringUtils';

// Parse a string into spans with the matching string in a highlighted span
export function highlight(str: string, searchStr: string): JSX.Element {
  const index = str.toLowerCase().indexOf(searchStr.toLowerCase());
  if (index >= 0) {
    // tslint:disable-next-line: max-line-length
    return (
      <>
        <span>{str.substring(0, index)}</span>
        <span className="highlight">{str.substring(index, index + searchStr.length)}</span>
        <span>{str.substring(index + searchStr.length)}</span>
      </>
    );
  }
  return <>{str}</>;
}

// Return the full name of a member
export function getFullNameFromMember<T extends { firstName?: Optional<string>; lastName?: Optional<string> }>(
  member?: T | null
): string {
  let fullName = '';

  if (member && member.firstName && member.lastName) {
    fullName = `${member.firstName} ${member.lastName}`;
  }

  return fullName;
}

export function getSortedNamesFromMembers<T extends { firstName?: Optional<string>; lastName?: Optional<string> }>(
  members: T[]
): string {
  return _.sortBy(
    members.map((member) => getFullNameFromMember(member)),
    (name) => name.toLowerCase()
  ).join(', ');
}

export function getCostCodeDisplay<T extends { costCode: string; title?: Optional<string> }>(
  costCode: T | null | undefined,
  fallback: string = t('No Cost Code')
): string {
  if (costCode) {
    if (costCode.title) {
      return `${costCode.costCode} ${costCode.title}`;
    } else {
      return costCode.costCode;
    }
  } else {
    return fallback;
  }
}

interface IEquipmentDisplayRequirements {
  equipmentName: string;
  model?: Optional<{
    title?: Optional<string>;
    make?: Optional<{
      title?: Optional<string>;
    }>;
  }>;
}

export function getEquipmentDisplay(
  equipment: IEquipmentDisplayRequirements | null | undefined,
  fallback: string = t('No Equipment')
) {
  if (equipment) {
    let display = equipment.equipmentName;

    if (equipment.model && equipment.model.make && equipment.model.make.title !== 'unknown') {
      display += ` ${equipment.model.make.title}`;
    }

    if (equipment.model && equipment.model.title !== 'unknown') {
      display += ` ${equipment.model.title}`;
    }

    return display;
  } else {
    return fallback;
  }
}

export const queryStringNonNull = (item: { [key: string]: any }) => {
  const filtered = _.pickBy(item, (entry) => entry !== null);
  return stringify(filtered);
};

export function formatPhone(phoneNumber: string): string | null {
  const numbered = phoneNumber.replace(/[^0-9]/g, '');
  if (numbered.length === 7) {
    return numbered.replace(/(\d{3})(\d{4})/, '$1-$2');
  } else if (numbered.length === 10) {
    return numbered.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  } else if (numbered.length === 11 && numbered[0] === '1') {
    return numbered.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
  }

  return null;
}

export function createAndedString(
  words: string[],
  separator: string = ',',
  oxford: boolean = true,
  punctuation?: string
) {
  return createSentence(words, t('and'), separator, oxford, punctuation);
}

export function createOrredString(
  words: string[],
  separator: string = ',',
  oxford: boolean = true,
  punctuation?: string
) {
  return createSentence(words, t('or'), separator, oxford, punctuation);
}

function createSentence(
  words: string[],
  conjunction: string,
  separator: string = ',',
  oxford: boolean = true,
  punctuation?: string
) {
  let sentence: string = '';
  if (words.length > 2) {
    sentence = _.reduce(
      words,
      (result, word, index) => {
        if (index === words.length - 2 && oxford) {
          return `${result}${word}${separator} `;
        } else if (index === words.length - 2) {
          return `${result}${word} `;
        } else if (index !== words.length - 1) {
          return `${result}${word}${separator} `;
        } else {
          return `${result}${conjunction} ${word}`;
        }
      },
      ''
    );
  } else if (words.length === 2) {
    sentence = `${words[0]} ${conjunction} ${words[1]}`;
  } else if (words.length === 1) {
    sentence = words[0];
  }

  return punctuation ? sentence + punctuation : sentence;
}

/// Takes seconds and converts the value to a string for display. example: 02:00
export function getTotalAsHoursMinutesSeconds(
  seconds: number,
  includingPrefixPadding: boolean = true,
  includeSeconds: boolean = false
) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  let hoursString = h.toString();
  let minutesString = m.toString();
  let secondsString = s.toString();

  if (includingPrefixPadding && hoursString.length < 2) {
    hoursString = '0' + hoursString;
  }
  if (includingPrefixPadding && minutesString.length < 2) {
    minutesString = '0' + minutesString;
  }
  if (includingPrefixPadding && secondsString.length < 2) {
    secondsString = '0' + secondsString;
  }

  if (includeSeconds) {
    return hoursString + ':' + minutesString + ':' + secondsString;
  }
  return hoursString + ':' + minutesString;
}

export function getLocalizedCurrency(value: string, currencyCode: string) {
  try {
    const parsed = _.toNumber(value);
    if (window.navigator.languages && window.navigator.languages.length) {
      return Intl.NumberFormat(window.navigator.languages[0], {
        style: 'currency',
        currency: currencyCode,
      }).format(parsed);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export function getUSDCurrency(value: number) {
  try {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  } catch (e) {
    return null;
  }
}

export function validateEmail(email: string) {
  return /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/.test(email);
}

export function validatePhone(phoneNumberUtil: PhoneNumberUtil, value: string) {
  try {
    // Supports international inputs e.g. +14356736736 but throws.
    const parse = phoneNumberUtil.parse(value);
    return phoneNumberUtil.isValidNumber(parse);
  } catch (e) {
    try {
      // Default to US if the format cannot be determined from the number
      const parse = phoneNumberUtil.parse(value, 'US');
      return phoneNumberUtil.isValidNumber(parse);
    } catch (e) {
      // Invalid validation if both parse methods throw an exception.
      return false;
    }
  }
}

export function containsOnlyNumbers(value: string) {
  return value.length === 0 ? true : _.every(value.split(''), (c) => !_.isNaN(+c));
}

export function getReportFilterApiPath(member: boolean, project: boolean, costCode: boolean, equipment: boolean) {
  const fields: string[] = [];

  if (member) {
    fields.push('member');
  }

  if (project) {
    fields.push('project');
  }

  if (costCode) {
    fields.push('cost-code');
  }

  if (equipment) {
    fields.push('equipment');
  }

  return fields.length > 0 ? fields.join('-') : '';
}

export function getReportApiPath(
  member: boolean,
  project: boolean,
  costCode: boolean,
  equipment: boolean,
  endpoint: AggregateCustomReportType | AggregatePlatformReportType | string
) {
  const params = getReportFilterApiPath(member, project, costCode, equipment);
  return params.length > 0 ? `${params}-${endpoint}` : endpoint;
}

export function convertEmptyToNull(value: string | null | undefined): string | null {
  return value && value.length !== 0 ? value : null;
}

export function boldText(text: string, shouldBeBold: string) {
  const textArray = text.split(shouldBeBold);
  return (
    <span>
      {textArray.map((item, index) => (
        <>
          {item}
          {index !== textArray.length - 1 && <b>{shouldBeBold}</b>}
        </>
      ))}
    </span>
  );
}

export function getYesNoString(value: boolean) {
  return value ? t('Yes') : t('No');
}

export function getOnOffString(value: boolean) {
  return value ? t('On') : t('Off');
}

export function getStringWithFallback(value: Optional<string>, fallback: string = '---') {
  return value ? value : fallback;
}

export const isValidString = (value: Optional<string>): value is string => {
  return !(isNil(value) || value === '');
};

export function getNumberFromString(value: Optional<string>): number {
  if (!isValidString) {
    return 0;
  }

  return _.toNumber(value);
}

export function getLowerCaseSearchPredicate(value: string, search: string) {
  return value.toLowerCase().includes(search.toLowerCase());
}

export function spaceIfTrue(condition: boolean) {
  return condition ? ' ' : '';
}

export function isStringInUnionList<T extends string>(value: string, supported: readonly T[]): value is T {
  return supported.some((sup) => sup === value);
}

export function mapString<R>(s: string, transform: (char: string) => R) {
  return s.split('').map(transform);
}

export function filterString(s: string, predicate: (char: string) => boolean) {
  return s.split('').filter(predicate);
}

export function everyString(s: string, predicate: (char: string) => boolean) {
  return s.split('').every(predicate);
}

export function someString(s: string, predicate: (char: string) => boolean) {
  return s.split('').some(predicate);
}

export const getVideoTimeStringfromSeconds = (s: string) => {
  const totalSeconds = parseFloat(s);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const formattedMinutes = String(minutes).padStart(1, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
};
