import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import FlatCard from 'components/foundation/FlatCard/FlatCard';
import Typography from 'components/foundation/text/Typography/Typography';
import SectionHeader from 'containers/settings/components/SectionHeader/SectionHeader';
import { t } from 'i18next';
import { isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { Nullable } from 'types/util/Nullable';
import { getDifferentObjectKeysByValue } from 'utils/objectUtils';
import { usePreviousCurrentLogs } from './hooks/usePreviousCurrentLogs/usePreviousCurrentLogs';
import { convertLogFeedTimeEntryLogIntoDisplay, parseLogsForBreakChanges } from './utils/utils';

export interface ITimeEntryLogToFromDetailProps {
  id: string;
  className?: ClassName;
}

const logDisplayLookup: Record<LogDisplayKey, string> = {
  project: t('Project'),
  costCode: t('Cost Code'),
  equipment: t('Equipment'),
  startTime: t('Start Time'),
  endTime: t('End Time'),
};

function TimeEntryLogToFromDetail({ id, className }: ITimeEntryLogToFromDetailProps) {
  const { data } = usePreviousCurrentLogs(id);

  const changedBreaks = useMemo(() => {
    if (!data) {
      return [];
    }

    const { previousLog, currentLog } = data;
    if (!previousLog || !currentLog) {
      return [];
    }

    return parseLogsForBreakChanges(currentLog, previousLog);
  }, [data]);

  if (!data) {
    return null;
  }

  const { previousLog, currentLog } = data;

  if (!previousLog || !currentLog) {
    return null;
  }

  const previousDisplay = convertLogFeedTimeEntryLogIntoDisplay(previousLog);
  const currentDisplay = convertLogFeedTimeEntryLogIntoDisplay(currentLog);
  const diffed = getDifferentObjectKeysByValue(currentDisplay, previousDisplay);

  if (isEmpty(diffed) && isEmpty(changedBreaks)) {
    return null;
  }

  const breaksToMapped = map(changedBreaks, 'to');
  const breaksFromMapped = map(changedBreaks, 'from');

  const classes = classNames('time-entry-log-to-from-detail', className);

  return (
    <div className={classes}>
      <SectionHeader>{t('From')}</SectionHeader>
      <LogDiffDisplay keys={diffed} log={previousDisplay} breakLabels={breaksFromMapped} />
      <SectionHeader>{t('To')}</SectionHeader>
      <LogDiffDisplay keys={diffed} log={currentDisplay} breakLabels={breaksToMapped} />
    </div>
  );
}

type LogDisplay = ReturnType<typeof convertLogFeedTimeEntryLogIntoDisplay>;
type LogDisplayKey = keyof LogDisplay;

interface ILogDisplayProps {
  keys: LogDisplayKey[];
  log: LogDisplay;
  breakLabels: Array<Nullable<string>>;
}

export function LogDiffDisplay({ keys, log, breakLabels }: ILogDisplayProps) {
  return (
    <FlatCard className="my-4">
      {keys.map((key) => (
        <div className="m-4" key={key}>
          <Typography tag="div" fontWeight="semiBold">
            {logDisplayLookup[key]}
          </Typography>
          <Typography tag="div">{log[key]}</Typography>
        </div>
      ))}
      <ConditionalContainer condition={!isEmpty(breakLabels)}>
        <div className="m-4">
          <Typography tag="div" fontWeight="semiBold">
            {t('Breaks')}
          </Typography>
          {breakLabels.map((label) => (
            <Typography tag="div" key={label}>
              {label ?? '---'}
            </Typography>
          ))}
        </div>
      </ConditionalContainer>
    </FlatCard>
  );
}

export default TimeEntryLogToFromDetail;
