import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useMemo, useRef } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ClassName } from 'types/ClassName';
import { uuid } from 'utils/uuidUtils';
import useDashboardXAxisDefaults from '../hooks/useDashboardXAxisDefaults';
import useDashboardYAxisFormatter from '../hooks/useDashboardYAxisFormatter';
import useTooltipFormatter from '../hooks/useTooltipFormatter';
import { IDashboardChartPayload, IDashboardChartPayloadType } from '../types/types';
import {
  getDefaultDashboardBarComponentProps,
  getDefaultDashboardCartesianGridProps,
  getDefaultDashboardYAxisProps,
  getDefaultTooltipProps,
} from '../utils/utils';

type Key<T> = Omit<keyof T, 'day'> & string;

interface IDashboardBarChartProps<T extends IDashboardChartPayload, K extends Key<T>> {
  data: T[];
  dataKey: K;
  timeFrame: DashboardTimeFrame;
  theme: Theme.DARK | Theme.LIGHT;
  valueType: IDashboardChartPayloadType;
  gradientTopColor: string;
  gradientBottomColor: string;
  className?: ClassName;
}

function DashboardCardBarChart<T extends IDashboardChartPayload, K extends Key<T>>({
  data,
  dataKey,
  theme,
  timeFrame,
  valueType,
  gradientTopColor,
  gradientBottomColor,
  className,
}: IDashboardBarChartProps<T, K>) {
  const { titleFormatter, valueFormatter } = useTooltipFormatter(valueType, timeFrame);
  const xAxisDefaults = useDashboardXAxisDefaults(data, timeFrame, theme);
  const yFormatter = useDashboardYAxisFormatter(valueType);
  const yAxisVisible = useMemo(() => data.some((datum) => datum[dataKey as keyof T]), [data, dataKey]);

  // Used to prevent id collision
  const gradientId = useRef(uuid());

  const classes = classNames('dashboard-bar-chart', className);

  return (
    <ResponsiveContainer width="100%" height="75%" minHeight="250px">
      <BarChart data={data} className={classes}>
        <defs>
          <linearGradient id={gradientId.current} x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={gradientTopColor} />
            <stop offset="95%" stopColor={gradientBottomColor} />
          </linearGradient>
        </defs>
        <Tooltip
          {...getDefaultTooltipProps()}
          // Second argument is the name which we are not using
          formatter={(value) => [valueFormatter(value as number), '']}
          labelFormatter={titleFormatter as any}
          cursor={{ fill: theme === Theme.DARK ? '#26343F' : '#eaeaea' }}
        />
        <CartesianGrid {...getDefaultDashboardCartesianGridProps(theme)} />
        <Bar dataKey={dataKey} fill={`url(#${gradientId.current})`} {...getDefaultDashboardBarComponentProps()} />
        <XAxis {...xAxisDefaults} />
        {yAxisVisible && (
          <YAxis tickFormatter={yFormatter} {...getDefaultDashboardYAxisProps(theme)} allowDecimals={false} />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default DashboardCardBarChart;
