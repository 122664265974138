import { useEffect } from 'react';
import { useChannelContext } from '../useChannelContext';

export function useChannelListener(channelId: string, onSubscribe: () => void) {
  const channel = useChannelContext();

  useEffect(() => {
    channel.registerListener(channelId, onSubscribe);

    return () => {
      channel.unregisterListener(channelId);
    };
  }, [channel, channelId, onSubscribe]);
}
