import { Field, FieldFooter, IFormFieldDefaultProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import OvertimeRuleSelect from 'components/domain/organization-overtime-period/OvertimeRuleSelect/OvertimeRuleSelect';
import OvertimePayPeriodType from 'types/enum/OvertimePayPeriodType';
import { AnyObject } from 'types/util/Object';

export interface IOvertimeRuleFormFieldProps<FormType extends AnyObject>
  extends IFormFieldDefaultProps<OvertimePayPeriodType, FormType> {
  className?: ClassName;
  onChange?: (value: OvertimePayPeriodType) => void;
}

const OvertimeRuleFormField = <FormType extends AnyObject>({
  className,
  form,
  name,
  validations,
  onChange,
  revalidateOnNewValidations,
  ...other
}: IOvertimeRuleFormFieldProps<FormType>) => {
  const classes = classNames('overtime-rule-form-field', className);

  function renderField({ ...fieldProps }) {
    function handleOnSelect(val: OvertimePayPeriodType) {
      fieldProps.handleChange(val, onChange);
    }

    const formValue = form.state.data[name];
    const value = formValue ? formValue : OvertimePayPeriodType.FOLLOW_COMPANY;

    return (
      <>
        <OvertimeRuleSelect error={fieldProps.hasError} value={value} onChange={handleOnSelect} {...other} />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  return (
    <Field
      form={form}
      name={name}
      validations={validations}
      className={classes}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
};

export default OvertimeRuleFormField;
