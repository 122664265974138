import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IMember } from 'types';
import IBusyRpcWrapper from 'types/BusyRpcWrapper';
import { IRestOrganizationInfo } from 'types/OrganizationInfo';
import RpcMethod from './rpcMethod';
import { getRpcParams } from './rpcUtils';

export default function createAccountRpcService(client: AxiosInstance) {
  return {
    create: (
      params: {
        username: string;
        email: string;
        password: string;
        organization_name: string;
        first_name: string;
        last_name: string;
      },
      config?: AxiosRequestConfig
    ): Promise<
      AxiosResponse<
        IBusyRpcWrapper<{
          member: IMember;
          organization_info: IRestOrganizationInfo;
        }>
      >
    > => client.post('/account', getRpcParams(RpcMethod.CREATE, params), config),
    resetPassword: (params: { hash: string; password: string }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.PASSWORD_RESET, params), config);
    },
    resetPasswordRequest: (params: { email: string }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.PASSWORD_RESET_REQUEST, params), config);
    },
    usernameAvailable: (params: { username: string }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.USERNAME_AVAILABLE, params), config);
    },
    requestVerificationEmail: (params: { member_id: string }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.REQUEST_EMAIL_VERIFICATION, params), config);
    },
    verifyEmail: (params: { hash: string }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.VERIFY_EMAIL, params), config);
    },
    inviteMember: (params: { member_id: string; email: boolean; sms: boolean }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.INVITE_MEMBER, params), config);
    },
    weeklyEmailUnsubscribe: (params: { hash: string }, config?: AxiosRequestConfig) => {
      return client.post('/account', getRpcParams(RpcMethod.WEEKLY_EMAIL_UNSUBSCRIBE, params), config);
    },
  };
}
