import {
  Field,
  FieldFooter,
  IFieldRender,
  IFormFieldDefaultProps,
  ITotalFieldPayload,
  ITotalFieldProps,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import TotalTimeField from 'components/foundation/TotalTimeField';
import { AnyObject } from 'types/util/Object';

export interface ITotalTimeFormFieldProps<FormType extends AnyObject>
  extends Omit<ITotalFieldProps, 'value' | 'minuteStep' | 'error' | 'onChange'>,
    IFormFieldDefaultProps<number | null | undefined, FormType> {
  type?: 'start' | 'end';
  onChange?: (payload: number | null | undefined) => void;
}

function TotalTimeFormField<FormType extends AnyObject>({
  form,
  name,
  validations,
  type,
  onChange,
  revalidateOnNewValidations,
  className,
  ...featureTimeFieldProps
}: ITotalTimeFormFieldProps<FormType>) {
  function renderField({ ...fieldProps }: IFieldRender<any>) {
    function handleOnChange(val: ITotalFieldPayload) {
      fieldProps.handleChange(val.value, onChange);
    }

    return (
      <>
        <TotalTimeField
          {...featureTimeFieldProps}
          onChange={handleOnChange}
          value={form.state.data[name]}
          error={fieldProps.hasError}
        />
        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </>
    );
  }

  const classes = classNames('total-time-form-field', className);

  return (
    <Field
      className={classes}
      form={form}
      name={name}
      validations={validations}
      render={renderField}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

TotalTimeFormField.defaultProps = {
  ...TotalTimeField.defaultProps,
  disabled: false,
  placeholder: 'H:MM',
};

export default TotalTimeFormField;
