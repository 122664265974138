import _ from 'lodash';
import { DateTime } from 'luxon';
import { PayPeriodType } from 'types/enum/PayPeriodType';
import WageRate from 'types/enum/WageRate';
import ITimeRange from 'types/TimeRange';
import IWageHistory from 'types/WageHistory';
import { dateTimeFromUtcISO, getDateName, isDateTimeInTimeRange } from 'utils/dateUtils';
import { t } from './localize';
import { getPayPeriodTypeCountInYear } from './payPeriodUtils';

export function getWageRateString(wageRate: WageRate): string | null {
  switch (wageRate) {
    case WageRate.HOURLY:
      return t('Hourly');
    case WageRate.WEEKLY:
      return t('Weekly');
    case WageRate.MONTHLY:
      return t('Monthly');
    case WageRate.YEARLY:
      return t('Yearly');
    default:
      return null;
  }
}

export function getWageRateAbbreviatedString(wageRate: WageRate): string {
  switch (wageRate) {
    case WageRate.HOURLY:
      return t('hr');
    case WageRate.WEEKLY:
      return t('wk');
    case WageRate.MONTHLY:
      return t('mo');
    case WageRate.YEARLY:
      return t('yr');
  }
}

export function getWageRate(value: string): WageRate | null {
  switch (_.toLower(value)) {
    case 'hourly':
      return WageRate.HOURLY;
    case 'weekly':
      return WageRate.WEEKLY;
    case 'monthly':
      return WageRate.MONTHLY;
    case 'yearly':
      return WageRate.YEARLY;
    default:
      return null;
  }
}

export function getCurrentWageHistory<T extends Pick<IWageHistory, 'deletedOn' | 'changeDate' | 'updatedOn'>>(
  wageHistoryArray: T[]
) {
  return getWageHistoryForTime(wageHistoryArray, DateTime.local().startOf('day'));
}

export function getWageHistoryForTime<T extends Pick<IWageHistory, 'deletedOn' | 'changeDate' | 'updatedOn'>>(
  wageHistories: T[],
  time: DateTime
): T | undefined {
  return _.maxBy(wageHistories, (wageHistory) =>
    wageHistory?.deletedOn === null &&
    wageHistory?.changeDate &&
    DateTime.fromISO(wageHistory.changeDate).startOf('day') <= time
      ? DateTime.fromISO(wageHistory.changeDate).toSeconds()
      : 0 && wageHistory.updatedOn
  );
}

export function getFutureWageHistory<T extends Pick<IWageHistory, 'deletedOn' | 'changeDate'>>(wageHistories: T[]) {
  return _.first(
    wageHistories.filter((wageHistory) => {
      return (
        wageHistory?.deletedOn === null &&
        wageHistory?.changeDate &&
        DateTime.fromISO(wageHistory.changeDate).startOf('day') > DateTime.local().startOf('day')
      );
    })
  );
}

export function getChangeDateString(changeDate: string) {
  const date = dateTimeFromUtcISO(changeDate);

  if (isChangeDateBeginning(changeDate)) {
    return t('Beginning');
  } else {
    return getDateName(date, 'LLLL d');
  }
}

export function isChangeDateBeginning(changeDate: string) {
  const date = dateTimeFromUtcISO(changeDate);
  if (date.year === 1970) {
    return true;
  } else {
    return false;
  }
}

export function getEstimatedHourlyRate(wageHistory: Pick<IWageHistory, 'workWeek' | 'wageRate' | 'wage'>): number {
  return getEstimatedHourlyRateFromValues(wageHistory.workWeek ?? 0, wageHistory.wageRate, wageHistory.wage);
}

export function getEstimatedHourlyCost(
  wageHistory: Pick<IWageHistory, 'workWeek' | 'wageRate' | 'wage' | 'overburden'>
): number {
  return getEstimatedHourlyCostFromValues(getEstimatedHourlyRate(wageHistory), wageHistory.overburden);
}

export function getEstimatedHourlyRateFromValues(hours: number, rate: number, wage: number): number {
  switch (rate) {
    case WageRate.WEEKLY:
      return Number((wage / hours).toFixed(2));
    case WageRate.MONTHLY:
      return Number(((wage * 12) / 52 / hours).toFixed(2));
    case WageRate.YEARLY:
      return Number((wage / 52 / hours).toFixed(2));
    default:
      return Number(wage.toFixed(2));
  }
}

export function getEstimatedHourlyCostFromValues(estimatedHourlyRate: number, burden: number): number {
  return Number((estimatedHourlyRate + (burden / 100) * estimatedHourlyRate).toFixed(2));
}

export function getApplicableWagesInTimeRange<
  T extends Pick<IWageHistory, 'id' | 'deletedOn' | 'changeDate' | 'updatedOn'>
>(wageHistories: T[], timeRange: ITimeRange<DateTime>) {
  const wageHistoryAtStart = getWageHistoryForTime(wageHistories, timeRange.startTime);
  const allWageHistoriesInRange = wageHistories.filter(({ id, changeDate, deletedOn }) => {
    if (deletedOn || id === wageHistoryAtStart!.id) {
      return false;
    }

    const changeDateTime = DateTime.fromISO(changeDate);
    return isDateTimeInTimeRange(changeDateTime, timeRange, false, false);
  });

  if (wageHistoryAtStart) {
    return [wageHistoryAtStart, ...allWageHistoriesInRange];
  }

  return allWageHistoriesInRange;
}

export function isWageSalary(wageRate: WageRate) {
  return wageRate !== WageRate.HOURLY;
}

export function getSalariedWageIntervalsPerYear(wageRate: WageRate) {
  switch (wageRate) {
    case WageRate.YEARLY:
      return 1;
    case WageRate.MONTHLY:
      return 12;
    case WageRate.WEEKLY:
      return 52;
    default:
      return null;
  }
}

export function getSalariedCostForPayPeriod(wage: number, wageRate: WageRate, payPeriodType: PayPeriodType) {
  const wageIntervalsPerYear = getSalariedWageIntervalsPerYear(wageRate);
  if (!wageIntervalsPerYear) {
    return null;
  }

  const yearlyWage = wage * wageIntervalsPerYear;
  const payPeriodsPerYear = getPayPeriodTypeCountInYear(payPeriodType);
  const wagePerPayPeriod = yearlyWage / payPeriodsPerYear;

  return wagePerPayPeriod;
}

export function convertWageToDoubleTime(wage: number) {
  return wage * 2;
}

export function convertWageToOvertime(wage: number) {
  return wage * 1.5;
}
