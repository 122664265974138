import { Col } from '@busybusy/webapp-react-ui';
import { LoadingState, ErrorState } from 'components';
import { useState } from 'react';
import './VideoFullscreen.scss';
import classNames from 'classnames';
import { IMediaEntry } from 'types';

export interface IVideoFullscreenProps {
  mediaEntry: IMediaEntry;
  performShake: boolean;
}

const VideoFullscreen: React.FunctionComponent<IVideoFullscreenProps> = (props) => {
  const { mediaEntry, performShake } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const classes = classNames('video-container', { 'ac-shake': performShake });

  function handleVideoFinishedLoading() {
    setIsLoading(false);
  }

  function handleVideoErrorLoading() {
    setError(true);
  }

  return (
    <Col className={classes}>
      <div className="video">
        {!error && (
          <video
            controls
            poster={mediaEntry.videoThumbFileUrl}
            onError={handleVideoErrorLoading}
            onLoadedData={handleVideoFinishedLoading}
            src={mediaEntry.fileUrl}
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {isLoading && !error ? <LoadingState /> : null}
      {error && <ErrorState />}
    </Col>
  );
};

export default VideoFullscreen;
