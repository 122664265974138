import { EnhancedStore } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import { IReduxState } from 'store/types';
import localStorage from './localStorageUtils';
import { isType } from './typeguard';
import { DeepPartial } from 'utility-types';

const persistStateBase = (store: EnhancedStore, keys: Array<keyof IReduxState>) => {
  const state: IReduxState = store.getState();

  keys.forEach((key: keyof IReduxState) => {
    const toStore = state[key];

    try {
      localStorage.set(key, toStore);
    } catch {
      // ignore write errors
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(`Local storage write failed`, key, toStore);
      }
    }
  });
};

interface IReadStore<K extends keyof IReduxState, Arg> {
  key: K;
  transform: (value: Arg) => IReduxState[K];
}

export const getPersistedInitialState = (keys: Array<keyof IReduxState | IReadStore<any, any>>) => {
  return keys.reduce((acc, cur) => {
    if (isType(cur, 'key')) {
      const argument = localStorage.get(cur.key);
      return { ...acc, [cur.key]: cur.transform(argument) };
    } else {
      return { ...acc, [cur]: localStorage.get(cur) };
    }
  }, {} as DeepPartial<IReduxState>);
};

export const persistState = throttle(persistStateBase, 0, { leading: true, trailing: true });
