import { useApolloClient } from '@apollo/client';
import { LaborMetricsInterval, MemberHasTimeOff, OperationType } from '__generated__/graphql';
import { MEMBERS_WITH_ALL_JOINED_DATA_QUERY } from 'apollo/queries/member-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import useTimeEntryToTimeEntryRowMap from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryToTimeEntryRowMap';
import { useTimeOffToTimeOffRowMap } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeOffToTimeOffRowMap';
import { COST_CODES_WITH_MEMBER_METRICS_QUERY } from 'containers/activity-reports/queries/cost-code-activity-queries';
import { SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY } from 'containers/activity-reports/queries/equipment-activity-queries';
import { PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY_AND_ANCESTORS } from 'containers/activity-reports/queries/project-activity-queries';
import { PayPeriodSignaturesLoaded } from 'containers/timesheets/TimeCardReport/context/TimeCardReportContextProvider';
import { useToastOpen } from 'contexts/ToastContext';
import { useApolloPaging, useLazyLoading, useOrganization } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { t } from 'i18next';
import _, { groupBy, isEmpty, some, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStoreTimeCardReportSettings } from 'store/TimeCardReportSettings/TimeCardReportSettings';
import { TimesheetView } from 'store/timesheets/Timesheets';
import { IReduxState } from 'store/types';
import { IMember } from 'types';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import { aggregateMemberLaborMetricsTime } from 'utils/aggregateUtils';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { mapNotNil } from 'utils/collectionUtils';
import { dateTimeFromISOWithoutZone, getDateTimesBetween } from 'utils/dateUtils';
import { getRegularMetricTimeTotals } from 'utils/jitMetricUtils';
import { logError } from 'utils/testUtils';
import {
  ExpandedTimeCardReportContext,
  ExpandedTimeCardReportDailySummaryInfo,
  ExpandedTimeCardReportMemberData,
} from './ExpandedTimeCardReportContext';
import { Nullable } from 'types/util/Nullable';

interface IExpandedTimeCardReportContextProviderProps {
  scroller: HTMLElement | Document | null;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  memberId?: string | null;
  memberGroupId?: string | null;
  positionId?: string | null;
  children: ReactNode | ReactNode[];
  archivedStatus: ArchivedStatus;
  permission: MemberPermission.TIME_EVENTS | MemberPermission.MANAGE_TIME_ENTRIES;
  timesheetsView: TimesheetView;
}

export default function ExpandedTimeCardReportContextProvider({
  scroller,
  memberId,
  timeRangeType,
  memberGroupId,
  positionId,
  timeRange,
  children,
  archivedStatus,
  permission,
  timesheetsView,
}: IExpandedTimeCardReportContextProviderProps) {
  const client = useApolloClient();
  const toast = useToastOpen();
  const localRange = useRef<ITimeRange<DateTime>>(timeRange);

  const archivedOn = useMemo(() => getApolloArchivedTimestampComparison(archivedStatus), [archivedStatus]);
  const { getAll } = useApolloPaging();
  const getSortWithNameFormat = useApolloMemberNameSort();
  const employeeNameFormatter = useEmployeeNameFormatter();
  const timeCardReportSettings = useSelector<IReduxState, IStoreTimeCardReportSettings>(
    (state) => state.timeCardReportSettings
  );
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();
  const convertTimeEntryToRow = useTimeEntryToTimeEntryRowMap();
  const convertTimeOffToRow = useTimeOffToTimeOffRowMap();

  const signaturesLoaded = useRef<PayPeriodSignaturesLoaded[]>([]);
  const organization = useOrganization();
  const timeRangeIsPayPeriod = timeRangeType === TimeRangeType.PAY_PERIOD;
  const showSignaturesFooter =
    timeRangeIsPayPeriod &&
    organization.signatureDate &&
    timeRange.endTime < DateTime.local() &&
    timeCardReportSettings.showSignatures;
  const [loading, setLoading] = useState<boolean>(false);

  const getMemberVariables = useCallback(
    (
      timeRange: ITimeRange<DateTime>,
      first: number,
      memberIds: string[] | null,
      after?: string | null,
      showAllMembers: boolean = false
    ) => {
      const sort = getSortWithNameFormat('asc');
      const variables: any = {
        first,
        after: after ?? undefined,
        filter: {
          permissions: {
            permissions: permission,
            operationType: OperationType.And,
          },
          memberGroupId: memberGroupId ? { equal: memberGroupId } : undefined,
          id: memberId ? { equal: memberId } : undefined,
          archivedOn,
          positionId: positionId ? { equal: positionId } : undefined,
        },
        sort,
      };

      if (memberIds && _.isNil(memberId)) {
        variables.filter.id = { contains: memberIds };
      }

      // optimize report by only loading the members that will show up
      // no need to optimize if we are scoped to certain members
      if (_.isNil(memberId) && _.isEmpty(memberIds) && !showAllMembers) {
        variables.filter.hasTime = {
          startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          includeOpenEntry: false,
          hasTimeOff: MemberHasTimeOff.All, // show both paid and unpaid time off
        };
      }

      variables.metricsInterval = LaborMetricsInterval.Day;
      variables.metricsStartDate = timeRange.startTime.toUTC().toISODate();
      variables.metricsEndDate = timeRange.endTime.toUTC().toISODate();

      variables.safetySignaturesFilter = {
        startTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
        endTime: { lessThanOrEqual: timeRange.endTime.toISO() },
        deletedOn: { isNull: true },
        member: {
          permissions: {
            permissions: MemberPermission.MANAGE_TIME_ENTRIES,
            operationType: OperationType.And,
          },
        },
      };
      variables.safetySignaturesSort = [{ startTime: 'asc' }, { createdOn: 'asc' }];

      variables.memberTimeDocumentFilter = {
        startTime: { lessThanOrEqual: timeRange.endTime.toISO() },
        endTime: { greaterThanOrEqual: timeRange.startTime.toISO() },
        deletedOn: { isNull: true },
      };
      variables.memberTimeDocumentSort = [{ submittedOn: 'desc' }];

      return variables;
    },
    [
      getSortWithNameFormat,
      permission,
      timeCardReportSettings,
      memberGroupId,
      memberId,
      archivedOn,
      positionId,
      timesheetsView,
    ]
  );

  async function populateData(
    members: IMember[],
    range: ITimeRange<DateTime>
  ): Promise<ExpandedTimeCardReportMemberData[]> {
    setLoading(true);
    const days = getDateTimesBetween(range.startTime, range.endTime);

    const results = await populateMembersData(members, range, days);
    setLoading(false);
    return results;
  }

  async function populateMembersData<T extends IMember>(
    members: T[],
    range: ITimeRange<DateTime>,
    days: DateTime[]
  ): Promise<Array<ExpandedTimeCardReportMemberData>> {
    let groupedTimeEntryData: Nullable<Record<string, ITimeEntryDataTableRow[] | null>> = null;
    if (timeCardReportSettings.showTimeEntries) {
      const timeData = await getTimeData(range, members);
      groupedTimeEntryData = groupBy(timeData, ({ member }) => member.id);
    }

    return await Promise.all(
      members.map(async (member) => {
        // Can't extract these model into grouped calls because the `withTime` is on the server and we can't
        // remap the returning data back to the correct member
        // TODO: if possible join all these stuff in the initial member call?
        let projectSummaryData: (IProject & ICursorable)[] | null = null;
        if (timeCardReportSettings.showProjectSummary) {
          projectSummaryData = await getProjectSummaryData(range, member);
        }

        let costCodeSummaryData: (ICostCode & ICursorable)[] | null = null;
        if (timeCardReportSettings.showCostCodeSummary) {
          costCodeSummaryData = await getCostCodeSummaryData(range, member);
        }

        let equipmentSummaryData: (IEquipment & ICursorable)[] | null = null;
        if (timeCardReportSettings.showEquipmentSummary) {
          equipmentSummaryData = await getEquipmentSummaryData(range, member);
        }

        let dailySummaryData: ExpandedTimeCardReportDailySummaryInfo[] | null = null;
        if (timeCardReportSettings.showDailySummary) {
          dailySummaryData = getDailySummaryData(member, days);
        }

        const timeEntryDataForMember = groupedTimeEntryData ? groupedTimeEntryData[member.id] : null;
        const metric = aggregateMemberLaborMetricsTime(member.memberLaborMetrics ?? []);
        return {
          ...member,
          cursor: member.cursor!,
          totalSeconds: metric.total,
          totalRegularSeconds: metric.regularTime + metric.pto,
          totalDoubleTimeSeconds: metric.doubleTime,
          totalOvertimeSeconds: metric.overtime,
          timeEntryData: timeEntryDataForMember,
          projectSummaryData: projectSummaryData,
          costCodeSummaryData: costCodeSummaryData,
          equipmentSummaryData: equipmentSummaryData,
          dailySummaryData: dailySummaryData,
        };
      })
    );
  }

  async function getTimeData(range: ITimeRange<DateTime>, members: IMember[]): Promise<ITimeEntryDataTableRow[]> {
    const memberIds = members.map((member) => member.id);
    const entries = await getTimeEntries(
      range,
      false,
      memberIds,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      200
    );
    const timeOffs = await getTimeOffs(range, memberIds, undefined, undefined);

    let rows: ITimeEntryDataTableRow[] = entries.flatMap((e) => convertTimeEntryToRow(e, timeRange));
    if (!isEmpty(timeOffs)) {
      rows = rows.concat(timeOffs.map(convertTimeOffToRow));
    }

    return sortBy(rows, 'startDate');
  }

  async function getProjectSummaryData(
    range: ITimeRange<DateTime>,
    member: IMember
  ): Promise<(IProject & ICursorable)[]> {
    return await getAll<IProject & ICursorable>('projectsWithTime', {
      query: PROJECT_WITH_TIME_AND_MEMBER_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY_AND_ANCESTORS,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ title: 'asc' }],
        metricsStartDate: range.startTime.toISODate(),
        metricsEndDate: range.endTime.toISODate(),
        startTime: range.startTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: range.endTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeOpenEntry: false,
        includeAncestors: false,
        metricsInterval: LaborMetricsInterval.Custom,
        memberId: { equal: member.id },
        memberIds: [member.id],
      },
    });
  }

  async function getCostCodeSummaryData(
    range: ITimeRange<DateTime>,
    member: IMember
  ): Promise<(ICostCode & ICursorable)[]> {
    return await getAll<ICostCode & ICursorable>('costCodes', {
      query: COST_CODES_WITH_MEMBER_METRICS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ costCode: 'asc' }, { title: 'asc' }],
        filter: {
          costCodesWithTime: {
            startTime: range.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: range.endTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
            memberId: { equal: member.id },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: range.startTime.toISODate(),
        metricsEndDate: range.endTime.toISODate(),
        memberIds: [member.id],
      },
    });
  }

  async function getEquipmentSummaryData(
    range: ITimeRange<DateTime>,
    member: IMember
  ): Promise<(IEquipment & ICursorable)[]> {
    return await getAll<IEquipment & ICursorable>('equipment', {
      query: SIMPLE_EQUIPMENT_WITH_MEMBER_TIME_ONLY_METRIC_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ equipmentName: 'asc' }],
        filter: {
          equipmentWithTime: {
            startTime: range.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: range.endTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
            memberId: { equal: member.id },
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: range.startTime.toISODate(),
        metricsEndDate: range.endTime.toISODate(),
        memberIds: [member.id],
      },
    });
  }

  function getDailySummaryData(member: IMember, days: DateTime[]): ExpandedTimeCardReportDailySummaryInfo[] {
    const signOffs = member.safetySignatures ?? [];
    const metricsDictionary = _.keyBy(member.memberLaborMetrics, (daySecond) => daySecond.start);
    const dataRows = mapNotNil(days, (day) => {
      const dayStart = day.startOf('day');
      const dayEnd = day.endOf('day');

      const filteredSignatures = signOffs.filter((signature) => {
        return (
          dateTimeFromISOWithoutZone(signature.startTime) <= dayEnd &&
          dateTimeFromISOWithoutZone(signature.endTime) >= dayStart
        );
      });

      const memberDayMetrics = getRegularMetricTimeTotals(metricsDictionary[dayStart.toISODate()]);
      const orderedSignatures = _.orderBy(filteredSignatures, (item) => item.endTime, ['desc']);

      const hasTimeEntryTime = memberDayMetrics.totalSeconds > 0;

      let regularSeconds = memberDayMetrics.regularSeconds ?? 0;
      if (memberDayMetrics) {
        const updatedRegularTotal = memberDayMetrics.regularSeconds + memberDayMetrics.paidTimeOffSeconds;
        regularSeconds = updatedRegularTotal > 0 ? updatedRegularTotal : 0;
      }

      if (hasTimeEntryTime === false) {
        return null;
      }

      return {
        date: day,
        timeAccurate: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures[0].timeAccurate
            : null
          : null,
        injured: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures.filter((signature: any) => signature.injured).length > 0
            : null
          : null,
        breakCompliance: hasTimeEntryTime
          ? orderedSignatures.length > 0
            ? orderedSignatures[0].breakPolicyFollowed
            : null
          : null,
        regularHours: regularSeconds,
        overtimeHours: memberDayMetrics.overtimeSeconds,
        doubleTimeHours: memberDayMetrics.doubleTimeSeconds,
        paidTimeOff: memberDayMetrics.paidTimeOffSeconds,
        totalHours: memberDayMetrics.totalSeconds,
      };
    });
    return dataRows;
  }

  const getMemberData = useCallback(
    async (cursor: string | null, first: number) => {
      const results = await client.query<{ members: Array<IMember & ICursorable> }>({
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        fetchPolicy: 'network-only',
        variables: getMemberVariables(timeRange, first, null, cursor),
      });

      return results.data.members ?? [];
    },
    [client, getMemberVariables, timeRange]
  );

  const getData = useCallback(
    async (cursor: string | null, first: number) => {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      setLoading(true);
      try {
        localRange.current = timeRange;
        const members = await getMemberData(cursor, first);
        return await populateData(members, timeRange);
      } catch (error) {
        logError(error as Error);
        toast({ label: t('Something went wrong. Please try again later.') });
      } finally {
        setLoading(false);
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMemberData, populateData, toast, timeRange, timeCardReportSettings]
  );

  const {
    clearData: lazyClearData,
    data,
    error,
    dispatch,
    loadedAll,
    loadAll,
  } = useLazyLoading(scroller, getData, undefined, 4, 200);

  function getExpandedTimeCardReportDataForMember(member: IMember) {
    return data.find((m) => m.id === member.id) ?? null;
  }

  async function refreshMembersData(ids: string[], range: ITimeRange<DateTime>) {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    setLoading(true);

    const clonedData = _.cloneDeep(data);
    if (clonedData) {
      const variables = getMemberVariables(range, ids.length, null, null);
      // we're adding this filter separately because we want to also filter by hasTime
      // in case content was created outside of our visible time range
      variables.filter.id = { contains: ids };

      const retrievedMembersQuery = await client.query<{ members: Array<IMember & ICursorable> }>({
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        fetchPolicy: 'network-only',
        variables,
      });
      const results = await populateData(retrievedMembersQuery.data.members, range);
      await updateTimEntryData(results, range);

      const clonedMembers = _.cloneDeep(data.filter((m) => !ids.includes(m.id))).concat(results);
      const sortedMembers = _.sortBy(clonedMembers, (member) =>
        employeeNameFormatter(member.firstName ?? '', member.lastName ?? '').toLowerCase()
      );

      // if there were members loaded then we need to check if these missing members would have been loaded later when scrolling
      // we need to do this to prevent having duplicates or items out of sort
      if (!_.isEmpty(data) && loadedAll === false) {
        const lastLoadedMember = _.last(data)!;
        const indexOfLast = _.findIndex(sortedMembers, (member) => member.id === lastLoadedMember.id);
        const newContent = sortedMembers.splice(0, indexOfLast + 1);

        // keep our last loaded member as the last member in our list
        dispatch({
          type: 'SET_DATA',
          payload: {
            data: newContent,
            loadedAll: false,
            loading: false,
          },
        });
        updateSignaturesLoaded(newContent.map((item) => item.id));
        setLoading(false);
      } else {
        dispatch({
          type: 'SET_DATA',
          payload: {
            data: sortedMembers,
            loadedAll: false,
            loading: false,
          },
        });
        updateSignaturesLoaded(sortedMembers.map((item) => item.id));
        setLoading(false);
      }
    }
  }

  async function updateTimEntryData(clonedData: ExpandedTimeCardReportMemberData[], range: ITimeRange<DateTime>) {
    const days = getDateTimesBetween(range.startTime, range.endTime);
    await populateMembersData(
      clonedData.filter((member) => !_.isNil(member)),
      range,
      days
    );

    //TODO: ??? this is not doing anything
    // results.forEach((result) => {
    //   clonedData.find((item) => item.id === result.id);
    // });
  }

  async function forceLoadAll(showAllMembers: boolean): Promise<ExpandedTimeCardReportMemberData[]> {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    setLoading(true);
    try {
      // TODO Set members and refactor. Might cause performance problems to have all
      // the rows loaded especially with a line for each member for each day
      const allMembers = await getAll<IMember & ICursorable>('members', {
        query: MEMBERS_WITH_ALL_JOINED_DATA_QUERY,
        variables: getMemberVariables(timeRange, 500, null, null, showAllMembers),
        fetchPolicy: 'network-only',
      });

      const days = getDateTimesBetween(timeRange.startTime, timeRange.endTime);
      const allData = await populateMembersData(allMembers, timeRange, days);
      const results = await Promise.all(allData);
      updateSignaturesLoaded(allMembers.map((item) => item.id));
      dispatch({
        type: 'SET_DATA',
        payload: {
          data: results,
          loadedAll: true,
          loading: false,
        },
      });
      setLoading(false);
      return results;
    } catch (error) {
      return [];
    }
  }

  function areAllSignaturesLoaded(): boolean {
    if (showSignaturesFooter) {
      return !some(signaturesLoaded.current, (item) => item.signaturesLoaded === false);
    }
    return true;
  }

  function onSignaturesLoaded(memberId: string, isLoaded: boolean) {
    const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
    if (index > -1) {
      signaturesLoaded.current[index].signaturesLoaded = isLoaded;
    } else {
      signaturesLoaded.current.push({ memberId, signaturesLoaded: isLoaded });
    }
  }

  function updateSignaturesLoaded(memberIds: string[]) {
    memberIds.forEach((memberId) => {
      const index = signaturesLoaded.current.findIndex((item) => item.memberId === memberId);
      if (index > -1) {
        // If the member is already in the list, do nothing
      } else {
        signaturesLoaded.current.push({ memberId, signaturesLoaded: null });
      }
    });
  }

  function clearData() {
    lazyClearData();
  }

  useEffect(() => {
    clearData();
  }, [timeCardReportSettings, memberGroupId, memberId, archivedOn, positionId, timesheetsView]);

  useEffect(() => {
    clearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange.startTime.toSeconds(), timeRange.endTime.toSeconds()]);

  const value = useMemo(
    () => ({
      getExpandedTimeCardReportDataForMember,
      data,
      loadedAll,
      refreshMembersData,
      populateData,
      forceLoadAll,
      clearData,
      signaturesLoaded: signaturesLoaded.current,
      onSignaturesLoaded,
      areAllSignaturesLoaded,
      loading,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, loadedAll, memberId, memberGroupId, signaturesLoaded.current, loading]
  );

  return <ExpandedTimeCardReportContext.Provider value={value}>{children}</ExpandedTimeCardReportContext.Provider>;
}
