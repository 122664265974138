import { DateTime } from 'luxon';

export default function v_time_before_time(
  value: string | undefined | null,
  args: { time: DateTime; inclusive: boolean; message: string }
) {
  return {
    message: args.message,
    validate: () => {
      if (typeof value === 'string') {
        const parsedValue = DateTime.fromFormat(value, 'H:mm').startOf('minute');
        const parsedTime = args.time.startOf('minute');
        return args.inclusive
          ? parsedValue.toSeconds() <= parsedTime.toSeconds()
          : parsedValue.toSeconds() < parsedTime.toSeconds();
      } else if (value && (value as unknown as DateTime)) {
        const parsedValue = (value as unknown as DateTime).startOf('minute');
        const parsedTime = args.time.startOf('minute');
        return args.inclusive
          ? parsedValue.toSeconds() <= parsedTime.toSeconds()
          : parsedValue.toSeconds() < parsedTime.toSeconds();
      } else {
        return false;
      }
    },
  };
}
