import { useApolloClient } from '@apollo/client';
import {
  Button,
  CheckboxFormField,
  DatePickerFormField,
  Form,
  IFormRender,
  Label,
  Loader,
  TextFormField,
  Theme,
  Toast,
} from '@busybusy/webapp-react-ui';
import { EquipmentCostHistory } from '__generated__/graphql';
import { EQUIPMENT_BY_ID_QUERY } from 'apollo/queries/equipment-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ProfileImageFormField } from 'components';
import EquipmentCategoryFormField from 'components/domain/equipment/EquipmentCategoryFormField/EquipmentCategoryFormField';
import EquipmentMakeFormField from 'components/domain/equipment/EquipmentMakeFormField/EquipmentMakeFormField';
import EquipmentModelFormField from 'components/domain/equipment/EquipmentModelFormField/EquipmentModelFormField';
import { equipmentUnknownModelQuery } from 'components/domain/equipment/EquipmentModelsForMakePicker/equipment-models-picker-queries';
import EquipmentTypeFormField from 'components/domain/equipment/EquipmentTypeFormField/EquipmentTypeFormField';
import EquipmentYearFormField from 'components/domain/equipment/EquipmentYearFormField/EquipmentYearFormField';
import CurrencyFormField from 'components/foundation/form-fields/CurrencyFormField/CurrencyFormField';
import useEquipmentImageUpdate from 'containers/manage-employees/hooks/useEquipmentImageUpdate';
import { useEquipment, useEquipmentHours, useOpenable } from 'hooks';
import useCreateEquipmentCostHistory from 'hooks/models/equipment-cost-history/useCreateEquipmentCostHistory';
import useUpdateEquipmentCostHistory from 'hooks/models/equipment-cost-history/useUpdateEquipmentCostHistory';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import _, { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useRef, useState } from 'react';
import IEquipment from 'types/Equipment';
import IEquipmentModel from 'types/EquipmentModel';
import { getCurrentEquipmentCostHistory } from 'utils/equipmentUtils';
import { t } from 'utils/localize';
import { v_number_decimals_only, v_require } from 'utils/validations';
import { IEquipmentFormData } from '../CreateEquipmentForm/CreateEquipmentForm';
import './EditEquipmentForm.scss';

export interface IEditEquipmentFormProps {
  className?: ClassName;
  equipmentId: string;
  editKey: string | null;
  onSubmit: (data?: string) => void;
}

const EditEquipmentForm = (props: IEditEquipmentFormProps) => {
  const { className, equipmentId, editKey, onSubmit } = props;

  const [formData, setFormData] = useState<IEquipmentFormData>();

  const { editEquipment } = useEquipment();
  const { createEquipmentHours } = useEquipmentHours();
  const errorToastState = useOpenable();
  const errorToastMessage = useRef(t('There was an unexpected error.'));
  const apolloClient = useApolloClient();
  const imageUpdate = useEquipmentImageUpdate();
  const loaderDetails = useOpenable();
  const dateDetails = useOpenable();
  const createEquipmentCostHistory = useCreateEquipmentCostHistory();
  const updateEquipmentCostHistory = useUpdateEquipmentCostHistory();
  const today = DateTime.local().startOf('day');
  const latestCostHistory = useRef<EquipmentCostHistory | undefined>(undefined);

  const classes = classNames(
    {
      'edit-equipment-form': true,
    },
    className
  );

  useOnMount(() => {
    getEquipment().then((equipment) => {
      latestCostHistory.current = getCurrentEquipmentCostHistory(equipment!.costHistory ?? []);

      const hourlyCostEffectiveDate = !isNil(latestCostHistory.current?.changeDate)
        ? DateTime.fromISO(latestCostHistory.current!.changeDate)
        : today;

      setFormData({
        equipmentName: equipment!.equipmentName,
        typeId: equipment!.model.category.equipmentTypeId,
        categoryId: equipment!.model.equipmentCategoryId,
        makeId: equipmentMakeId(equipment!),
        modelId: equipmentModelId(equipment!),
        modelTitle: equipmentModelTitle(equipment!),
        trackHourMeter: equipment!.trackManualHours ?? false,
        hourMeter: equipment!.lastHours?.runningHours ?? 0,
        imageUrl: equipment?.imageUrl ?? undefined,
        hourlyCost: latestCostHistory.current?.operatorCostRate ?? 0,
        hourlyCostEffectiveDate: {
          value: hourlyCostEffectiveDate.toJSDate(),
          inputValue: hourlyCostEffectiveDate.toFormat('M/D/YYYY'),
        },
      });
    });
  });

  const equipmentMakeId = (equipment: IEquipment) => {
    if (equipment?.model?.make?.unknown === false) {
      return equipment.model.equipmentMakeId;
    }

    return undefined;
  };

  const equipmentModelId = (equipment: IEquipment) => {
    if (equipment?.model?.unknown === false) {
      return equipment.model.id;
    }
    return undefined;
  };

  const equipmentModelTitle = (equipment: IEquipment) => {
    if (equipment?.model?.unknown === false) {
      return equipment.model.title;
    }

    return undefined;
  };

  const getEquipment = async () => {
    const results = await apolloClient.query<{ equipment: IEquipment[] }>({
      query: EQUIPMENT_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        equipmentId,
      },
    });

    return _.first(results.data.equipment);
  };

  const equipmentNameField = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label required={true}>{t('Name')}</Label>
      <TextFormField name={'equipmentName'} form={form} validations={[{ validation: v_require }]} autofocus={true} />
    </>
  );

  const equipmentTypePicker = (form: IFormRender<IEquipmentFormData>, autofocus: boolean = false) => (
    <>
      <Label>{t('Type')}</Label>
      <EquipmentTypeFormField
        name={'typeId'}
        form={form}
        validations={[{ validation: v_require }]}
        autofocus={autofocus}
      />
    </>
  );

  const equipmentCategoryPicker = (form: IFormRender<IEquipmentFormData>, autofocus: boolean = false) => (
    <>
      <Label>{t('Category')}</Label>
      <EquipmentCategoryFormField
        name={'categoryId'}
        equipmentTypeId={form.state.data.typeId}
        form={form}
        validations={[{ validation: v_require }]}
        autofocus={autofocus}
      />
    </>
  );

  const equipmentMakePicker = (form: IFormRender<IEquipmentFormData>, autofocus: boolean = false) => (
    <>
      <Label>{t('Make')}</Label>
      <EquipmentMakeFormField
        name={'makeId'}
        equipmentCategoryId={form.state.data.categoryId}
        form={form}
        autofocus={autofocus}
      />
    </>
  );

  const equipmentModelPicker = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Model')}</Label>
      <EquipmentModelFormField
        name={'modelTitle'}
        equipmentMakeId={form.state.data.makeId}
        equipmentCategoryId={form.state.data.categoryId}
        form={form}
        validations={form.state.data.makeId ? [{ validation: v_require }] : undefined} // if make is selected then we have to pick a model
      />
    </>
  );

  const equipmentYearPicker = (form: IFormRender<IEquipmentFormData>, autofocus: boolean = false) => (
    <>
      <Label>{t('Year')}</Label>
      <EquipmentYearFormField
        className="year-field"
        name={'modelId'}
        equipmentMakeId={form.state.data.makeId}
        equipmentCategoryId={form.state.data.categoryId}
        equipmentModelTitle={form.state.data.modelTitle}
        form={form}
        validations={form.state.data.makeId ? [{ validation: v_require }] : undefined} // if make is selected then we have to pick a model
        autofocus={autofocus}
      />
    </>
  );

  const equipmentHoursField = (form: IFormRender<IEquipmentFormData>, autofocus: boolean = false) => (
    <>
      <Label>{t('Hour Meter')}</Label>
      <TextFormField
        className="hour-meter-field"
        type="number"
        name={'hourMeter'}
        placeholder={'0.0'}
        form={form}
        restrictTo={{ maxLength: 10 }}
        validations={[{ validation: v_number_decimals_only }]}
        autofocus={autofocus}
      />
    </>
  );

  const trackHoursCheckbox = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <CheckboxFormField name="trackHourMeter" label={t('Manually Track Hours')} form={form} />
    </>
  );

  const equipmentHourlyCostField = (form: IFormRender<IEquipmentFormData>, autofocus: boolean = false) => (
    <>
      <Label>{t('Hourly Rate')}</Label>
      <CurrencyFormField
        className="hourly-cost-field"
        name="hourlyCost"
        form={form}
        validations={[{ validation: v_require }]}
        textAlign="right"
        placeholder="0.00"
        autofocus={autofocus}
      />
      <Label>{t('Effective Date')}</Label>
      <div className="mb-2">
        {t(
          'The date you would like the rate change to take effect. Setting the date to the past will change past job costing data.'
        )}
      </div>
      <DatePickerFormField
        name="hourlyCostEffectiveDate"
        form={form}
        disabledBefore={
          !isNil(latestCostHistory.current?.changeDate)
            ? DateTime.fromISO(latestCostHistory.current!.changeDate).toJSDate()
            : undefined
        }
        validations={[{ validation: v_require }]}
        isOpen={dateDetails.isOpen}
        onClose={dateDetails.close}
        onOpen={dateDetails.open}
      />
    </>
  );

  const editButton = (form: IFormRender<IEquipmentFormData>) => (
    <Button className="pl-5 pr-5" type="primary" onClick={form.handleSubmit}>
      {t('Save')}
    </Button>
  );

  const imageField = (form: IFormRender<IEquipmentFormData>) => (
    <>
      <Label>{t('Equipment Image')}</Label>
      <ProfileImageFormField name="profile" initialImage={form.state.data.imageUrl ?? null} form={form} />
    </>
  );

  function renderFormFields({ ...form }: IFormRender<IEquipmentFormData>): ReactNode {
    switch (editKey) {
      case 'name':
        return (
          <div>
            {equipmentNameField(form)}
            {editButton(form)}
          </div>
        );
      case 'type':
        return (
          <div>
            {equipmentTypePicker(form, true)}
            {equipmentCategoryPicker(form)}
            {equipmentMakePicker(form)}
            {equipmentModelPicker(form)}
            {equipmentYearPicker(form)}
            {editButton(form)}
          </div>
        );
      case 'category':
        return (
          <div>
            {equipmentCategoryPicker(form, true)}
            {equipmentMakePicker(form)}
            {equipmentModelPicker(form)}
            {equipmentYearPicker(form)}
            {editButton(form)}
          </div>
        );
      case 'make':
        return (
          <div>
            {equipmentMakePicker(form, true)}
            {equipmentModelPicker(form)}
            {equipmentYearPicker(form)}
            {editButton(form)}
          </div>
        );
      case 'model':
        return (
          <div>
            {equipmentModelPicker(form)}
            {equipmentYearPicker(form)}
            {editButton(form)}
          </div>
        );
      case 'year':
        return (
          <div>
            {equipmentYearPicker(form, true)}
            {editButton(form)}
          </div>
        );
      case 'hourMeter':
        return (
          <div>
            {trackHoursCheckbox(form)}
            {form.state.data.trackHourMeter ? equipmentHoursField(form, true) : null}
            {editButton(form)}
          </div>
        );
      case 'hourlyCost':
        return (
          <div>
            {equipmentHourlyCostField(form, true)}
            {editButton(form)}
          </div>
        );
      case 'image':
        return (
          <div>
            {imageField(form)}
            {editButton(form)}
          </div>
        );
      case null:
        return (
          <div>
            {equipmentNameField(form)}
            {equipmentTypePicker(form)}
            {equipmentCategoryPicker(form)}
            {equipmentMakePicker(form)}
            {equipmentModelPicker(form)}
            {equipmentYearPicker(form)}
            {trackHoursCheckbox(form)}
            {form.state.data.trackHourMeter ? equipmentHoursField(form) : null}
            {editButton(form)}
          </div>
        );
    }
  }

  const getUnknownModel = async (categoryId: string) => {
    const results = await apolloClient.query<{ equipmentModels: IEquipmentModel[] }>({
      query: equipmentUnknownModelQuery,
      variables: {
        categoryId,
      },
    });

    return _.first(results.data.equipmentModels);
  };

  async function onSubmitForm(data: IEquipmentFormData) {
    loaderDetails.open();
    if (validateData(data)) {
      let modelId = data.modelId;

      if (!modelId) {
        const unknownModel = await getUnknownModel(data.categoryId!);
        modelId = unknownModel?.id;
      }

      if (!modelId) {
        // could not find unknown model
        errorToastMessage.current = t('Cannot save without a make, model, and year selected.');
        errorToastState.open();
        return;
      }

      editEquipment(equipmentId, data.equipmentName!, modelId, data.trackHourMeter === true)
        .then(async (response) => {
          if (data.profile !== formData?.profile) {
            await imageUpdate(equipmentId, data.profile?.file ?? null).catch(() => {
              errorToastMessage.current = t('There was an error saving the image.');
              errorToastState.open();
              loaderDetails.close();
            });
          }

          const equipmentHours = response.data.updateEquipment.id;
          let shouldSubmit = true;

          if (data.hourMeter && data.hourMeter && data.hourMeter !== formData?.hourMeter) {
            shouldSubmit = false;
            // save the hour meter if the user input a valid value
            createEquipmentHours(equipmentHours, data.hourMeter)
              .then(() => {
                onSubmit(response.data.updateEquipment.id);
              })
              .catch(() => {
                errorToastMessage.current = t('There was an error saving the hour meter.');
                errorToastState.open();
              });
          }

          if (data.hourlyCost && data.hourlyCostEffectiveDate) {
            if (
              (isNil(latestCostHistory.current?.changeDate) && data.hourlyCost !== formData?.hourlyCost) ||
              (data.hourlyCostEffectiveDate?.value &&
                !isNil(latestCostHistory.current?.changeDate) &&
                DateTime.fromJSDate(data.hourlyCostEffectiveDate.value).startOf('day').toISO() !==
                  DateTime.fromISO(latestCostHistory.current!.changeDate).startOf('day').toISO())
            ) {
              shouldSubmit = false;
              // save the hourly cost if the user input a valid value
              createEquipmentCostHistory(
                equipmentHours,
                undefined,
                data.hourlyCost,
                DateTime.fromJSDate(data.hourlyCostEffectiveDate.value!).startOf('day').toISO()
              )
                .then(() => {
                  onSubmit(response.data.updateEquipment.id);
                })
                .catch(() => {
                  errorToastMessage.current = t('There was an error saving cost history.');
                  errorToastState.open();
                });
            } else if (!isNil(latestCostHistory.current) && data.hourlyCost !== formData?.hourlyCost) {
              shouldSubmit = false;
              // save the hourly cost if the user input a valid value
              updateEquipmentCostHistory(latestCostHistory.current.id, undefined, data.hourlyCost)
                .then(() => {
                  onSubmit(response.data.updateEquipment.id);
                })
                .catch(() => {
                  errorToastMessage.current = t('There was an error saving cost history.');
                  errorToastState.open();
                });
            }
          }

          if (shouldSubmit) {
            onSubmit(response.data.updateEquipment.id);
          }

          loaderDetails.close();
        })
        .catch(() => {
          errorToastMessage.current = t('There was an error saving the equipment.');
          errorToastState.open();
          loaderDetails.close();
        });
    } else {
      errorToastMessage.current = t('Answer all of the questions and try again.');
      errorToastState.open();
      loaderDetails.close();
    }
  }

  function validateData(data: IEquipmentFormData): boolean {
    if (_.isEmpty(data.equipmentName)) {
      return false;
    } else if (!_.isNil(data.makeId) && _.isNil(data.modelId)) {
      // if make is selected then we also need a model
      return false;
    }

    return true;
  }

  return (
    <div className={classes}>
      <div className="mb-6 p-8">
        {formData && <Form data={formData} onSubmit={onSubmitForm} render={renderFormFields} className={classes} />}

        <Toast isOpen={errorToastState.isOpen} onClose={errorToastState.close} theme={Theme.DANGER}>
          {errorToastMessage.current}
        </Toast>
        <Loader isOpen={loaderDetails.isOpen} />
      </div>
    </div>
  );
};

export default EditEquipmentForm;
