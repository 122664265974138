import { SortDirection } from '@busybusy/webapp-react-ui';
import { every, get, isArray, isNil, isString, orderBy, partition } from 'lodash';
import { Nullable } from 'types/util/Nullable';
import { Optional } from 'types/util/Optional';

export function getObjectValueIfAllSame<T>(arr: T[], key: string, defaultValue: any = '') {
  if (arr.length > 0) {
    const firstValue = get(arr[0], key);
    const allMatch = arr.every((item: T) => {
      return get(item, key) === firstValue;
    });

    return allMatch ? firstValue : defaultValue;
  }

  return defaultValue;
}

/**
 * uses localeCompare to sort the provided collection by the specified key and SortDirection
 *
 * returns a new collection without mutating the original
 */
export function sortByLocaleString<T>(
  collection: T[],
  key: keyof T,
  sortDir: SortDirection,
  sortOptions: Intl.CollatorOptions = { sensitivity: 'base' } // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
): T[] {
  return [...collection].sort((a, b) => {
    if (sortDir === SortDirection.DESCENDING) {
      [b, a] = [a, b]; // in place value swap
    }

    return `${a[key]}`.localeCompare(`${b[key]}`, navigator.language, sortOptions);
  });
}

/**
 * wraps `LoDash.orderBy()`, adding support for busybusy `SortDirection` constants
 */
export function orderCollectionBy<T>(
  collection: T[],
  sortBy: keyof T | Array<keyof T>,
  sortDir: SortDirection | SortDirection[],
  nilLast: boolean = true
): T[] {
  if (Array.isArray(sortBy) !== Array.isArray(sortDir)) {
    throw Error('Invalid arguments: sortBy and sortDir must have equal number of elements');
  } else if (Array.isArray(sortBy) && Array.isArray(sortDir)) {
    if (sortBy.length !== sortDir.length) {
      throw Error('Invalid arguments: sortBy and sortDir must have equal number of elements');
    }

    if (sortBy.length > 1 && sortDir.length > 1) {
      const thisSortBy = sortBy.pop()!;
      const thisSortDir = sortDir.pop()!;
      collection = orderCollectionBy<T>(collection, sortBy, sortDir, nilLast); // recursive
      sortBy = thisSortBy;
      sortDir = thisSortDir;
    } else if (sortBy.length === 1 && sortDir.length === 1) {
      sortBy = sortBy[0];
      sortDir = sortDir[0];
    }
  }

  if (Array.isArray(sortBy) || Array.isArray(sortDir)) {
    throw Error('Something went wrong');
  }

  // split nil values to separate collection, then append it to the end so those values are always last
  const [unsorted, nilValues] = nilLast ? partition<T>(collection, (o: T) => !isNil(get(o, sortBy))) : [collection, []];

  let sorted: T[];

  // sort strings using localeCompare
  if (unsorted.length === 0) {
    sorted = unsorted;
  } else {
    // only use locale sort if every value is a string
    const sortByIsString = every(unsorted, (item) => isString(item[sortBy as keyof T]));

    if (sortByIsString) {
      sorted = sortByLocaleString(unsorted, sortBy, sortDir);
    } else {
      sorted = orderBy<T>(unsorted, sortBy, sortDir === SortDirection.ASCENDING ? 'asc' : 'desc');
    }
  }

  return [...sorted, ...nilValues];
}

export function wrapElementIfNotArray<T>(value: T | T[]): T[] {
  return isArray(value) ? value : [value];
}

export function wrapElementIfSet<T>(value: Optional<T>): Nullable<T[]> {
  return value ? [value] : null;
}
