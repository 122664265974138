import useNavigation from './useNavigation';
import { EmployeeTabType } from 'containers/employee-timers/EmployeeTimersActionHeader/EmployeeTimersTabs';

interface IParams {
  employee_id?: string;
  member_group_id?: string;
  project_id?: string;
  project_group_id?: string;
  cost_code_id?: string;
  cost_code_group_id?: string;
  filter_type?: EmployeeTabType;
  exitToLegacy?: boolean;
}

export default function useEmployeesNavigation() {
  return useNavigation<IParams>('/employees');
}
