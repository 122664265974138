import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  end_date?: string;
  employee_id?: string;
  member_group_id?: string;
  project_id?: string;
  cost_code_id?: string;
  equipment_id?: string;
  time_range_type?: string;
  timesheet_view?: string;
  timesheet_report_type?: string;
  timesheet_graph_type?: string;
  archived_status?: ArchivedStatus;
  exitToLegacy?: boolean;
}

export default function useTimesheetsNavigation() {
  return useNavigation<IParams>('/time-cards');
}
