import gql from 'graphql-tag';

export const CREATE_TIME_ENTRY_LOG = gql`
  mutation CreateTimeEntryLog($log: ClientTimeEntryLogCreateInput) {
    createClientTimeEntryLog(clientTimeEntryLog: $log) {
      id
      costCodeId
      description
      deviceType
      equipmentId
      endLocationId
      memberId
      mergedTimeEntryId
      originalTimeEntryId
      projectId
      startLocationId
      timeEntryId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryCreatedOn
      timeEntryDeletedOn
      daylightSavingTime
      metaDaylightSavingTime
      deletedOn
      submittedOn
      updatedOn
      timeEntry {
        id
        member {
          id
          firstName
          lastName
        }
        project {
          id
          title
          depth
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_TIME_ENTRY_LOGS = gql`
  mutation CreateTimeEntryLogs($logs: [ClientTimeEntryLogCreateInput!]!) {
    createClientTimeEntryLogs(clientTimeEntryLogs: $logs) {
      id
      costCodeId
      description
      deviceType
      equipmentId
      endLocationId
      memberId
      mergedTimeEntryId
      originalTimeEntryId
      projectId
      startLocationId
      timeEntryId
      updaterMemberId
      actionType
      endDeviceTime
      endTime
      endTimeTrusted
      startDeviceTime
      startTime
      startTimeTrusted
      timeEntryCreatedOn
      timeEntryDeletedOn
      daylightSavingTime
      metaDaylightSavingTime
      deletedOn
      submittedOn
      updatedOn
      timeEntry {
        id
        member {
          id
          firstName
          lastName
        }
        project {
          id
          title
          depth
          children {
            id
            title
            depth
            archivedOn
          }
          ancestors {
            id
            title
            depth
            archivedOn
          }
        }
        costCode {
          id
          costCode
          title
        }
        equipment {
          id
          equipmentName
          model {
            id
            title
            make {
              id
              title
            }
            category {
              id
              title
            }
          }
        }
        startTime
        daylightSavingTime
        endTime
        metaDaylightSavingTime
        actionType
        description
        createdOn
        deletedOn
        submittedOn
        updatedOn
        breaks {
          id
          startTime
          startTimeDst
          endTime
          endTimeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        startLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
        endLocation {
          id
          longitude
          latitude
          horizontalAccuracy
          altitude
          verticalAccuracy
          timeGmt
          timeLocal
          timeDst
          createdOn
          deletedOn
          submittedOn
          updatedOn
        }
      }
      updaterMember {
        id
        firstName
        lastName
      }
    }
  }
`;
