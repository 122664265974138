export interface IStoreSession {
  readonly isAuthenticated: boolean;
  readonly memberId?: string;
  readonly organizationId?: string;
  readonly positionId?: string;
  readonly timestamp: number;
}

export interface IStoreSessionAuthenticated extends IStoreSession {
  readonly memberId: string;
  readonly organizationId: string;
  readonly positionId: string;
}

export const isStoreSessionAuthenticated = (session: IStoreSession): session is IStoreSessionAuthenticated =>
  session.isAuthenticated === true &&
  session.memberId !== undefined &&
  session.organizationId !== undefined &&
  session.positionId !== undefined;
