import { MEMBER_UPDATE_MUTATION } from 'apollo/mutations/member-mutations';
import { DocumentNode, ExecutionResult } from 'graphql';
import { useApolloUpdate } from 'hooks';
import { useCallback } from 'react';
import { IMember, IMemberUpdateInput } from 'types';
import { MemberUpdateInput } from '__generated__/graphql';

interface IMutation {
  updateMember: IMember;
}

type MemberSetTuple = [
  <Key extends keyof MemberUpdateInput>(
    id: string,
    key: Key,
    value: MemberUpdateInput[Key]
  ) => Promise<ExecutionResult<IMutation>>,
  (value: IMemberUpdateInput) => Promise<ExecutionResult<IMutation>>,
];

export default function useMemberUpdate(mutation: DocumentNode = MEMBER_UPDATE_MUTATION): MemberSetTuple {
  const [updateKey, update] = useApolloUpdate<MemberUpdateInput, IMutation>(mutation, 'member');

  const updateMemberKey = useCallback(
    <Key extends keyof MemberUpdateInput>(id: string, key: Key, value: MemberUpdateInput[Key]) => {
      return updateKey(id, key, value);
    },
    [updateKey]
  );

  const updateMember = useCallback(
    (value: MemberUpdateInput) => {
      return update(value);
    },
    [update]
  );

  return [updateMemberKey, updateMember];
}
