import gql from 'graphql-tag';

export const CREATE_MEMBER_SIGN_IN_ANSWER = gql`
  mutation CreateMemberSignInAnswer($answer: MemberSignInAnswerCreateInput) {
    createMemberSignInAnswer(answer: $answer) {
      id
    }
  }
`;

export const UPDATE_MEMBER_SIGN_IN_ANSWER = gql`
  mutation UpdateMemberSignInAnswer($answer: MemberSignInAnswerUpdateInput) {
    updateMemberSignInAnswer(answer: $answer) {
      id
      flagged
      resolverId
    }
  }
`;
