import { createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'types/util/Nullable';

export interface IStoreLaunchDarkly {
  hasSynced: Nullable<boolean>;
}

export const initialState: IStoreLaunchDarkly = {
  hasSynced: null,
};

const slice = createSlice({
  name: 'LaunchDarkly',
  initialState,
  reducers: {
    setLaunchDarklySynced: (state: IStoreLaunchDarkly) => {
      state.hasSynced = true;
    },
  },
});

export const { setLaunchDarklySynced } = slice.actions;
export default slice.reducer;
