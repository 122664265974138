import { Size, Tray } from '@busybusy/webapp-react-ui';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { IconButton } from 'components';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import { getFormattedDateRangeByType } from 'utils/timeRangeUtils';

export interface IArrowedDateRangeProps {
  timeRange: Nullable<ITimeRange<DateTime>>;
  forward: () => void;
  back: () => void;
  forwardEnabled: () => boolean;
  backEnabled: () => boolean;
  timeRangeType: TimeRangeType;
  order: ArrowedDateRangeOrder;
  className?: ClassName;
}

export const ArrowedDateRangeDateLeft = ['date', 'back', 'forward'] as const;
export const ArrowedDateRangeDateMiddle = ['back', 'date', 'forward'] as const;
export const ArrowedDateRangeDateRight = ['back', 'forward', 'date'] as const;
type ArrowedDateRangeOrder =
  | typeof ArrowedDateRangeDateLeft
  | typeof ArrowedDateRangeDateMiddle
  | typeof ArrowedDateRangeDateRight;

function ArrowedDateRange({
  timeRange,
  forward,
  back,
  forwardEnabled,
  backEnabled,
  order,
  timeRangeType,
  className,
}: IArrowedDateRangeProps) {
  const shouldRenderArrows = timeRangeType !== TimeRangeType.CUSTOM && timeRangeType !== TimeRangeType.ALL_TIME;

  return (
    <div className={classNames('arrowed-date-range', className)}>
      <Tray spacing={1}>
        {order.map((o) => {
          switch (o) {
            case 'back':
              return (
                <ConditionalContainer condition={shouldRenderArrows} key={o}>
                  <IconButton svg={ChevronLeftIcon} onClick={back} buttonSize={Size.SMALL} disabled={!backEnabled()} />
                </ConditionalContainer>
              );
            case 'forward':
              return (
                <ConditionalContainer condition={shouldRenderArrows} key={o}>
                  <IconButton
                    svg={ChevronRightIcon}
                    onClick={forward}
                    buttonSize={Size.SMALL}
                    disabled={!forwardEnabled()}
                  />
                </ConditionalContainer>
              );
            case 'date':
              return (
                <h2 className="fw-regular px-2" key={o}>
                  <ConditionalContainer condition={timeRangeType === TimeRangeType.ALL_TIME}>
                    {t('All Time')}
                  </ConditionalContainer>
                  <ConditionalContainer condition={timeRangeType !== TimeRangeType.ALL_TIME && !!timeRange}>
                    {timeRange && getFormattedDateRangeByType(timeRange, timeRangeType)}
                  </ConditionalContainer>
                </h2>
              );
            default:
              return null;
          }
        })}
      </Tray>
    </div>
  );
}

ArrowedDateRange.defaultProps = {
  order: ArrowedDateRangeDateRight,
};

export default ArrowedDateRange;
