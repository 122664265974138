import useTimeRangeTypeTimeRangeConverter from 'hooks/utils/useTimeRangeTypeTimeRangeConverter/useTimeRangeTypeTimeRangeConverter';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import { QueryParam } from 'utils/constants/queryParam';
import { getDateTimeIfValid, getDateTimeWithZone } from 'utils/dateUtils';
import useQueryParams from '../useQueryParams';

export default function useTimeRangeTimeRangeTypeQueryParams() {
  const { getParam } = useQueryParams();
  const converter = useTimeRangeTypeTimeRangeConverter();

  return useCallback(
    (
      defaultTimeRangeType: TimeRangeType,
      storedTimeRangeType: Nullable<TimeRangeType> | undefined,
      zone: 'system' | 'utc'
    ) => {
      const startDateParam = getParam(QueryParam.START_DATE);
      const endDateParam = getParam(QueryParam.END_DATE);

      const paramStartDate = startDateParam ? getDateTimeIfValid(DateTime.fromISO(startDateParam, { zone })) : null;
      const paramEndDate = endDateParam ? getDateTimeIfValid(DateTime.fromISO(endDateParam, { zone })) : null;

      const startTime = paramStartDate ?? getDateTimeWithZone(zone).startOf('day');
      const endTime = paramEndDate?.endOf('day') ?? startTime.endOf('day');

      const customRange = { startTime, endTime };
      const timeRangeType =
        getParam<TimeRangeType>(QueryParam.TIME_RANGE_TYPE) ?? storedTimeRangeType ?? defaultTimeRangeType;
      const timeRange =
        timeRangeType !== TimeRangeType.CUSTOM
          ? converter(timeRangeType, zone, paramStartDate ?? getDateTimeWithZone(zone).startOf('day'))
          : customRange;

      return { timeRange, timeRangeType };
    },
    [converter, getParam]
  );
}
