import IDeletable from './Deletable';
import IIdable from './Idable';
import { IMediaCollection } from './MediaCollection';
import IMediaEntryLocation from './MediaEntryLocation';
import IMember from './Member';
import ITimestampable from './Timestampable';

export interface IMediaEntry extends IIdable<string>, IDeletable, ITimestampable {
  mediaCollectionId: string;
  createdBy: string;
  comment?: string;
  captureDate?: string;
  entryType?: number;
  fileUrl?: string;
  annotationFileUrl?: string;
  videoThumbFileUrl?: string;
  metaData?: string;
  fileThumbUrl?: string;
  location?: IMediaEntryLocation;
  collection?: IMediaCollection;
  lastAnnotatedAt?: string;
  lastAnnotatedBy?: IMember;
}

export enum MediaEntryType {
  COMMENT = 10,
  IMAGE = 20,
  FILE = 30,
  VIDEO = 40,
}
