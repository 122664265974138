import { IDialogProps } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import { FunctionComponent, ReactNode } from 'react';
import CsvImportReviewHeader from '../CsvImportReviewHeader/CsvImportReviewHeader';
import './CsvImportReview.scss';

export interface ICsvImportReviewProps extends Omit<IDialogProps, 'children'> {
  dialogTitle: string;
  onPrint: () => void;
  children: ReactNode;
}

const CsvImportReview: FunctionComponent<ICsvImportReviewProps> = (props) => {
  const { className, dialogTitle, onPrint, children, ...other } = props;

  const classes = classNames('csv-import-review', className);

  return (
    <HeaderDialog className={classes} title={dialogTitle} headerClass={'no-print'} {...other}>
      <CsvImportReviewHeader className="no-print" onPrintReviewClick={onPrint} />
      {children}
    </HeaderDialog>
  );
};

export default CsvImportReview;
