import { ReactNode } from 'react';
import { Optional } from 'types/util/Optional';

export interface IOptionalUnwrapperProps<T> {
  value: Optional<T>;
  children: (value: T) => ReactNode;
}

export default function OptionalUnwrapper<T>({ value, children }: IOptionalUnwrapperProps<T>) {
  if (!value) {
    return null;
  }

  return <>{children(value)}</>;
}
