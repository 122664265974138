import { TIME_OFF_QUERY } from 'apollo/queries/time-off-queries';
import { useApolloPaging } from 'hooks';
import { DateTime } from 'luxon';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';

function useTimeOffQuery(timeOffQuery?: any) {
  const { getAll } = useApolloPaging();
  const query = timeOffQuery ? timeOffQuery : TIME_OFF_QUERY;

  function getTimeOffs(
    timeRange: ITimeRange<DateTime>,
    memberIds?: string[],
    showPaidTimeOff?: boolean,
    permissions?: MemberPermission[]
  ) {
    return getAll<ITimeOff>('timeOffs', {
      query,
      fetchPolicy: 'network-only',
      variables: {
        first: 10,
        filter: {
          paid: showPaidTimeOff !== undefined ? { equal: showPaidTimeOff } : undefined, // showPaidTimeOff undefined includes all
          deletedOn: { isNull: true },
          dateStamp: {
            lessThanOrEqual: timeRange.endTime
              .toUTC()
              .set({ millisecond: 0 })
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
            greaterThanOrEqual: timeRange.startTime
              .toUTC()
              .set({ millisecond: 0 })
              .toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          memberId: memberIds ? { contains: memberIds } : undefined,
          member: permissions
            ? {
                permissions: {
                  permissions,
                  operationType: OperationType.AND,
                },
              }
            : undefined,
        },
        sort: [{ dateStamp: 'asc' }],
      },
    });
  }

  return getTimeOffs;
}

export default useTimeOffQuery;
