import { useCallback } from 'react';
import { IGustoEmployee } from '../../../types/types';
import useGustoAxios from '../../useGustoAxios/useGustoAxios';

export default function useGustoGetEmployee() {
  const axios = useGustoAxios();

  return useCallback(
    async (employeeId: string) => {
      const result = await axios.get<IGustoEmployee>(`/v1/employees/${employeeId}`);
      return result.data;
    },
    [axios]
  );
}
