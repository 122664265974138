import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { DateTime } from 'luxon';
import { getTimezoneSupForDate } from 'utils/dateUtils';

export interface ITimeAndSuperscriptProps {
  className?: ClassName;
  dateTime: DateTime;
  format: string;
  dst: boolean;
  compareDateTime?: DateTime;
}

const TimeAndSuperscript = (props: ITimeAndSuperscriptProps) => {
  const { className, dateTime: time, format, dst, compareDateTime } = props;

  const classes = classNames('time-and-superscript', className);

  return (
    <div className={classes}>
      {time.toFormat(format)}
      {getTimezoneSupForDate(time, dst, compareDateTime)}
    </div>
  );
};

TimeAndSuperscript.defaultProps = {
  format: 'h:mm a',
};

export default TimeAndSuperscript;
