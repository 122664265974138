// The IE polyfill must be the first line in the entry file
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as Sentry from '@sentry/react';
import CustomMiddleware from 'components/middleware/CustomMiddleware';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import '../node_modules/@busybusy/webapp-react-ui/dist/styles/index.scss';
import Middleware from './components/middleware/Middleware';
import App from './containers/app/App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';
import './wdyr';

const tagManagerArgs: TagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
};
TagManager.initialize(tagManagerArgs);

const sentryArgs: Sentry.BrowserOptions = {
  dsn: 'https://42391f38446b4ab9bd619b7eadac3e04@o30165.ingest.sentry.io/5257002',
  release: `webapp-react@${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENV,

  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development', // Disable Sentry for localhost. It's causing issues with the hot reload on macOS.
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  ignoreErrors: [
    // Per https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded and a comment
    // from the specification author, this is benign and can be ignored.
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
};
Sentry.init(sentryArgs);

const WrappedApp: React.FunctionComponent = () => (
  <Middleware>
    <CustomMiddleware>
      <App />
    </CustomMiddleware>
  </Middleware>
);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<WrappedApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
