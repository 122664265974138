import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';
import { IChildrenProps } from 'types/ChildrenProps';
import { ClassName } from "types/ClassName";
import './Thumbnail.scss';

interface IProps extends Partial<IChildrenProps> {
  dockTemplate?: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  fileUrl?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: ClassName;
}

const Thumbnail = ({ size, dockTemplate, fileUrl, onClick, children, className }: IProps) => {
  const classes = classNames(
    'thumbnail',
    {
      [`thumbnail-${size}`]: true,
      'thumbnail-clickable': onClick,
    },
    className
  );

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className={classes} onClick={onClick} style={{ backgroundImage: `url("${fileUrl}")` }}>
      {dockTemplate && (
        <div className="thumbnail-dock" onClick={stopPropagation}>
          {dockTemplate}
        </div>
      )}
      {children}
    </div>
  );
};

Thumbnail.defaultProps = {
  size: 'md',
};

export default Thumbnail;
