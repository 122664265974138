import { combineReducers, Reducer, UnknownAction } from 'redux';
import { IFeatureFlags } from 'types';
import { EmptyObject } from 'types/util/Object';
import localStorage from '../utils/localStorageUtils';
import activityReportReducers, { IStoreActivityReports } from './activityReports/ActivityReports';
import authenticationReducers from './authentication/authenticationReducers';
import { IStoreAuthentication } from './authentication/authenticationType';
import authMemberReducers from './authMember/authMemberReducers';
import { IStoreAuthMember } from './authMember/authMemberType';
import budgetReportReducer, { IStoreBudgetReport } from './budgetReports/BudgetReports';
import bulletinPostsReducers, { IStoreBulletinPosts } from './bulletinPosts/bulletinPostsReducers';
import dailyProjectReportReducers from './dailyReport/DailyReport';
import dailyReportAdditionalProgressPhotosReducers, {
  IStoreDailyReportAdditionalProgressPhotos,
} from './dailyReport/DailyReportAdditionalProgressPhotos';
import dailyReportAdditionalSafetyPhotosReducers, {
  IStoreDailyReportAdditionalSafetyPhotos,
} from './dailyReport/DailyReportAdditionalSafetyPhotos';
import dailyReportNewSectionsReducers, { IStoreDailyReportNewSections } from './dailyReport/DailyReportNewSections';
import { IStoreDailyReport } from './dailyReport/dailyReportType';
import dailySignInReducer, { IStoreDailySignIn } from './dailySignIn/DailySignIn';
import documentReportReducers, { IStoreDocumentReport } from './documentReport/DocumentReport';
import employeeTimersReducer, { IStoreEmployeeTimers } from './employeeTimers/EmployeeTimers';
import { IStoreEnvironment } from './environment';
import environmentReducers from './environment/environmentReducers';
import featuresReducers from './features';
import gustoReducer, { IStoreGusto } from './gusto/gusto';
import iframeReducer, { IStoreIframe } from './iframe/iframe';
import costCodeLandingReducer, { IStoreCostCodeLandingPage } from './landingPages/CostCodeLandingPage';
import equipmentLandingReducer, { IStoreEquipmentLandingPage } from './landingPages/EquipmentLandingPage';
import projectLandingReducer, { IStoreProjectLandingPage } from './landingPages/ProjectLandingPage';
import launchDarklyReducer, { IStoreLaunchDarkly } from './launchDarkly/launchDarkly';
import manageGroupsReducer, { IStoreManageGroups } from './manageGroups/ManageGroups';
import manageModelMapsReducer, { IStoreManageModelMaps } from './manageModelMaps/ManageModelMaps';
import organizationReducers from './organization/organizationReducers';
import { IStoreOrganization } from './organization/organizationType';
import orgSubStatusReducers from './organizationSubscriptionStatus/organizationSubscriptionStatusReducers';
import { IStoreOrganizationSubscriptionStatus } from './organizationSubscriptionStatus/organizationSubscriptionStatusType';
import payStubsReportReducer, { IStorePayStubsReport } from './payStubsReport/PayStubsReport';
import { IStorePhotos } from './photos';
import photosReducers from './photos/photosReducers';
import preferencesReducer, { IStorePreferences } from './preferences/Preferences';
import { IStoreProjects } from './projects';
import projectsReducers from './projects/projectsReducers';
import sessionReducers from './session/sessionReducers';
import { IStoreSession } from './session/sessionType';
import tableScroll, { IStoreTableScroll } from './tableScroll/TableScroll';
import timeCardReportSettingsReducers, {
  IStoreTimeCardReportSettings,
} from './TimeCardReportSettings/TimeCardReportSettings';
import timeEntryTable, { IStoreTimeEntryTable } from './TimeEntryTable/TimeEntryTable';
import timeOffReportReducer, { IStoreTimeOffReport } from './timeOffReport/TimeOffReport';
import timesheetReducer, { IStoreTimesheets } from './timesheets/Timesheets';
import universalMapsReducer, { IStoreUniversalMaps } from './universalMaps/UniversalMaps';
import navigationBarReducer, { IStoreNavigationBar } from './ui/NavigationBar/NavigationBar';

export interface IReduxState {
  activityReports: IStoreActivityReports;
  authMember: IStoreAuthMember | EmptyObject;
  authentication: IStoreAuthentication;
  budgetReport: IStoreBudgetReport;
  bulletinPosts: IStoreBulletinPosts;
  costCodeLanding: IStoreCostCodeLandingPage;
  dailyReport: IStoreDailyReport;
  dailyReportAdditionalProgressPhotos: IStoreDailyReportAdditionalProgressPhotos;
  dailyReportAdditionalSafetyPhotos: IStoreDailyReportAdditionalSafetyPhotos;
  dailyReportNewSections: IStoreDailyReportNewSections;
  dailySignIn: IStoreDailySignIn;
  documentReport: IStoreDocumentReport;
  employeeTimers: IStoreEmployeeTimers;
  environment: IStoreEnvironment;
  equipmentLanding: IStoreEquipmentLandingPage;
  features: Required<IFeatureFlags>;
  gusto: IStoreGusto;
  iframe: IStoreIframe;
  launchDarkly: IStoreLaunchDarkly;
  manageGroups: IStoreManageGroups;
  manageModelMaps: IStoreManageModelMaps;
  navigationBar: IStoreNavigationBar;
  organization: IStoreOrganization;
  organizationSubscriptionStatus: IStoreOrganizationSubscriptionStatus;
  payStubsReport: IStorePayStubsReport;
  photos: IStorePhotos;
  preferences: IStorePreferences;
  projectLanding: IStoreProjectLandingPage;
  projects: IStoreProjects;
  session: IStoreSession;
  tableScroll: IStoreTableScroll;
  timeCardReportSettings: IStoreTimeCardReportSettings;
  timeEntryTable: IStoreTimeEntryTable;
  timeOffReport: IStoreTimeOffReport;
  timesheet: IStoreTimesheets;
  universalMaps: IStoreUniversalMaps;
}

export const appReducers = {
  activityReports: activityReportReducers,
  authMember: authMemberReducers,
  authentication: authenticationReducers,
  budgetReport: budgetReportReducer,
  bulletinPosts: bulletinPostsReducers,
  costCodeLanding: costCodeLandingReducer,
  dailyReport: dailyProjectReportReducers,
  dailyReportAdditionalProgressPhotos: dailyReportAdditionalProgressPhotosReducers,
  dailyReportAdditionalSafetyPhotos: dailyReportAdditionalSafetyPhotosReducers,
  dailyReportNewSections: dailyReportNewSectionsReducers,
  dailySignIn: dailySignInReducer,
  documentReport: documentReportReducers,
  employeeTimers: employeeTimersReducer,
  environment: environmentReducers,
  equipmentLanding: equipmentLandingReducer,
  features: featuresReducers,
  gusto: gustoReducer,
  iframe: iframeReducer,
  launchDarkly: launchDarklyReducer,
  manageGroups: manageGroupsReducer,
  manageModelMaps: manageModelMapsReducer,
  navigationBar: navigationBarReducer,
  organization: organizationReducers,
  organizationSubscriptionStatus: orgSubStatusReducers,
  payStubsReport: payStubsReportReducer,
  photos: photosReducers,
  preferences: preferencesReducer,
  projectLanding: projectLandingReducer,
  projects: projectsReducers,
  session: sessionReducers,
  tableScroll,
  timeCardReportSettings: timeCardReportSettingsReducers,
  timeEntryTable,
  timeOffReport: timeOffReportReducer,
  timesheet: timesheetReducer,
  universalMaps: universalMapsReducer,
};

export const combinedReducers = combineReducers(appReducers);

const LOGOUT = 'LOGOUT';
const storageKeysToRemove = ['authentication', 'stored_companies', 'gusto_payroll'];

export const rootReducer: Reducer<IReduxState, UnknownAction> = (state = undefined, action) => {
  // NOTE: This doesn't use the standard switch() syntax
  if (action.type === LOGOUT) {
    storageKeysToRemove.forEach(localStorage.remove);
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default rootReducer;
