import { Button, ButtonList, Position, SegmentedControl, Size, Theme, Tray } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import SearchBar from 'components/foundation/SearchBar/SearchBar';
import DeleteConfirmationDialog from 'containers/photos/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { useDebounce, useOpenable, useQueryParams } from 'hooks';
import { FunctionComponent, useEffect, useState } from 'react';
import IEquipment from 'types/Equipment';
import { IVisibleTableColumn } from 'types/TableColumn';
import { QueryParam } from 'utils/constants/queryParam';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { ColumnIcon, DeleteIcon, PrintIcon, TableRowHeightIcon } from '../../../assets/icons';
import { ActionBar, Header, IconButton, MoreButton } from '../../../components';
import ManageEquipmentColumnDialog from '../ManageEquipmentColumnDialog/ManageEquipmentColumnDialog';

export type ManageEquipmentViewType = 'list' | 'map';

interface IManageEquipmentActionHeaderProps {
  checkedEquipment: IEquipment[];
  clearCheckedEquipment: () => void;
  className?: ClassName;
  openCreateDialog: () => void;
  onPrint: () => void;
  onImportEquipment: () => void;
  onExportEquipment: () => void;
  visibleColumns: IVisibleTableColumn[];
  handleVisibleColumnsChange: (columns: IVisibleTableColumn[]) => void;
  onDelete: (equipment: IEquipment[]) => void;
  onEditUsingSpreadsheetClick: () => void;
  viewType: ManageEquipmentViewType;
  onRowHeightClick: () => void;
}

export const ManageEquipmentActionHeader: FunctionComponent<IManageEquipmentActionHeaderProps> = (props) => {
  const {
    checkedEquipment,
    clearCheckedEquipment,
    className,
    openCreateDialog,
    onPrint,
    onImportEquipment,
    onExportEquipment,
    visibleColumns,
    handleVisibleColumnsChange,
    onDelete,
    onEditUsingSpreadsheetClick,
    viewType,
    onRowHeightClick,
  } = props;

  const deleteDetails = useOpenable();
  const columnDialogDetails = useOpenable();
  const isImportEnabled = useFeatureFlags('EQUIPMENT_IMPORT');
  const isManageEquipmentMap = useFeatureFlags('MANAGE_EQUIPMENT_MAP');
  const { getParam, setParam } = useQueryParams();
  const filterSearch = getParam(QueryParam.SEARCH);
  const isManagementListsSearchEnabled = useFeatureFlags('MANAGEMENT_LISTS_SEARCH');
  const [localSearch, setLocalSearch] = useState<string>(filterSearch ?? '');
  const debouncedLocalSearch = useDebounce(localSearch, 250);

  useEffect(() => {
    broadcastSearchChange(debouncedLocalSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalSearch]);

  function handleDelete() {
    onDelete(checkedEquipment);
    clearCheckedEquipment();
    deleteDetails.close();
  }

  const RenderActionBar = () => (
    <>
      <ActionBar selectedCount={checkedEquipment.length} onClear={clearCheckedEquipment}>
        <Tray spacing={1}>
          <IconButton svg={DeleteIcon} onClick={deleteDetails.open} tooltipLabel={t('Delete')} theme={Theme.DARK} />
          <MoreButton
            theme={Theme.DARK}
            renderContent={(close: () => void) => (
              <ButtonList>
                <Button onClick={onExportClick(close)}>{t('Export Equipment')}</Button>
              </ButtonList>
            )}
          />
        </Tray>
      </ActionBar>
    </>
  );

  const onImportClick = (close: () => void) => () => {
    onImportEquipment();
    close();
  };

  const onExportClick = (close: () => void) => () => {
    onExportEquipment();
    close();
  };

  const onSpreedsheetClick = (close: () => void) => () => {
    onEditUsingSpreadsheetClick();
    close();
  };

  // const handleSearch = (search: string) => {
  //   setParam(QueryParam.SEARCH, search);
  // };

  const handleSearch = (search: string) => setLocalSearch(search);

  const broadcastSearchChange = (search: string | null) => {
    setParam(QueryParam.SEARCH, search);
  };

  const classes = classNames('photo-action-header', className);

  return (
    <>
      <Header className={classes} actionBar={<RenderActionBar />}>
        <span>
          {isManageEquipmentMap && (
            <SegmentedControl
              value={viewType}
              options={[
                {
                  value: 'list',
                  label: t('List'),
                },
                {
                  value: 'map',
                  label: t('Map'),
                },
              ]}
              onChange={(value) => {
                setParam('viewType', value === 'list' ? 'list' : 'map');
              }}
              className="mr-8"
            />
          )}
          {viewType === 'list' && (
            <Button rounded={true} type="secondary" size={Size.SMALL} onClick={openCreateDialog}>
              {t('Create')}
            </Button>
          )}
        </span>
        <Tray spacing={1}>
          {isManagementListsSearchEnabled ? (
            <SearchBar
              className={`management-lists-search`}
              onSearch={handleSearch}
              search={localSearch}
              placeholder={t(`Search name, make, or model`)}
              enableClear={true}
            />
          ) : null}
          {viewType === 'list' && (
            <>
              <IconButton svg={ColumnIcon} onClick={columnDialogDetails.open} tooltipLabel={t('Columns')} />
              <IconButton
                onClick={onRowHeightClick}
                svg={TableRowHeightIcon}
                tooltipLabel={t('Table Row Height')}
                buttonSize={Size.MEDIUM}
              />
              <IconButton svg={PrintIcon} onClick={onPrint} tooltipLabel={t('Print')} />
              <MoreButton
                position={Position.BOTTOM_END}
                size={Size.MEDIUM}
                renderContent={(close: () => void) => (
                  <ButtonList>
                    {isImportEnabled && <Button onClick={onImportClick(close)}>{t('Import Equipment')}</Button>}
                    <Button onClick={onExportClick(close)}>{t('Export Equipment')}</Button>
                    <Button onClick={onSpreedsheetClick(close)}>{t('Edit using Spreadsheet')}</Button>
                  </ButtonList>
                )}
              />
            </>
          )}
        </Tray>
      </Header>
      <ManageEquipmentColumnDialog
        columns={visibleColumns}
        isOpen={columnDialogDetails.isOpen}
        onClose={columnDialogDetails.close}
        onChange={handleVisibleColumnsChange}
      />
      <DeleteConfirmationDialog
        isOpen={deleteDetails.isOpen}
        onClose={deleteDetails.close}
        onDelete={handleDelete}
        title={t('Delete equipment?')}
        showHeaderDivider={false}
      >
        {t('If you delete this item it will be gone forever. Are you sure you want to proceed?')}
      </DeleteConfirmationDialog>
    </>
  );
};

export default ManageEquipmentActionHeader;
