import { ApolloClient } from '@apollo/client';
import { COST_CODE_METRICS_QUERY, COST_CODE_TIME_METRICS_QUERY } from 'apollo/queries/cost-code-metric-queries';
import { EQUIPMENT_METRICS_QUERY, EQUIPMENT_TIME_METRICS_QUERY } from 'apollo/queries/equipment-metric-queries';
import { MEMBER_METRICS_QUERY, MEMBER_TIME_METRICS_QUERY } from 'apollo/queries/member-metric-queries';
import { PROJECT_METRICS_QUERY, PROJECT_TIME_METRICS_QUERY } from 'apollo/queries/project-metric-queries';
import {
  SIMPLE_PROJECT_WITH_METRIC_QUERY,
  SIMPLE_PROJECT_WITH_TIME_ONLY_METRIC_QUERY,
} from 'apollo/queries/project-queries';
import {
  ORGANIZATION_LABOR_METRIC_QUERY,
  ORGANIZATION_TIME_METRIC_QUERY,
} from 'hooks/aggregates/metrics/organization-metric-queries';
import { Dictionary, first, groupBy, isEmpty, keyBy, reduce } from 'lodash';
import { DateTime } from 'luxon';
import { IMember } from 'types';
import ICostCodeTimeMetrics from 'types/aggregate/CostCodeLaborMetrics';
import IEquipmentTimeMetrics from 'types/aggregate/EquipmentLaborMetrics';
import IMemberTimeMetrics from 'types/aggregate/MemberLaborMetrics';
import OrganizationTimeMetrics from 'types/aggregate/OrganizationLaborMetrics';
import IProjectTimeMetrics from 'types/aggregate/ProjectLaborMetrics';
import ITimeOffLaborMetrics from 'types/aggregate/TimeOffLaborMetrics';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import ITimeRange from 'types/TimeRange';
import { KeysOfType } from 'types/util/KeysOfType';
import { isNilOrEmpty } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { combineMetricWithTimeOff, getMetricLaborTotals, ILaborMetric, metricNullFilter } from 'utils/jitMetricUtils';
import { t } from 'utils/localize';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  LaborMetricsInterval,
  OperationType,
  OrganizationLaborMetricsFragmentDoc,
} from '__generated__/graphql';
import { ActivityReportType } from '../ActivityReportFilter/ActivityReportFilter';
import {
  COST_CODES_WITH_METRICS_QUERY,
  COST_CODES_WITH_TIME_METRICS_QUERY,
} from '../queries/cost-code-activity-queries';
import {
  SIMPLE_EQUIPMENT_WITH_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
} from '../queries/equipment-activity-queries';
import { MEMBERS_WITH_METRIC_QUERY, MEMBERS_WITH_TIME_ONLY_METRIC_QUERY } from '../queries/member-activity-queries';
import { IActivityReportRowInfo } from './ActivityReportData';

export const activityIdFilter = (id: string, idField: ConditionNullableFieldType, pluralIdFieldName: string) => {
  if (id === remainingDataItemId) {
    return {
      metricFilter: metricNullFilter(idField, ConditionOperationNullType.IsNull),
    };
  } else {
    return {
      [pluralIdFieldName]: [id],
    };
  }
};

export const encryptUnassignedProjectId = (id: string) => 'unassigned' + id;

export const decryptUnassignedProjectId = (id: string) => {
  if (id.startsWith('unassigned')) {
    return {
      id: id.replace('unassigned', ''),
      unassigned: true,
    };
  } else {
    return {
      id: id,
      unassigned: false,
    };
  }
};

// No equipment object.
export const NO_EQUIPMENT_OBJECT: IEquipment = {
  id: remainingDataItemId,
  equipmentName: t('No Equipment'),
} as IEquipment;

// No cost code object.
export const NO_COST_CODE_OBJECT: ICostCode = {
  id: remainingDataItemId,
  title: t('No Cost Code'),
} as ICostCode;

// No project object.
export const NO_PROJECT_OBJECT: IProject = {
  id: remainingDataItemId,
  title: t('No Project'),
} as IProject;

export function filterByProjectIds(projectIds?: string[] | null, idWithDescendants?: boolean) {
  if (idWithDescendants === true) {
    return {
      project: filterByProjectIdsOrEmptyItemIdInsideProjectFilter(projectIds),
    };
  } else {
    return {
      projectId: graphQLContainsIdOrEmptyItemId(projectIds),
    };
  }
}

/**
 * Gets the object to filter by projectId for the given list of project ids and
 * handles the "no project" item id.
 *
 * @param ids ids to filter by and may contain the "no project" id
 * @returns graphQL filter object
 */
export function filterByProjectIdsOrEmptyItemId(ids?: string[] | null, idWithDescendants?: boolean) {
  if (!isNilOrEmpty(ids)) {
    if (ids!.includes(remainingDataItemId)) {
      return {
        projectId: { isNull: true },
      };
    } else if (idWithDescendants === true) {
      return {
        projectIdWithDescendants: { contains: ids },
      };
    } else {
      return {
        projectId: { contains: ids },
      };
    }
  }

  return undefined;
}

/**
 * Gets the object to filter by projectId for the given list of project ids and
 * handles the "no project" item id for filtering within the project filter block.
 *
 * @param ids ids to filter by and may contain the "no project" id
 * @returns graphQL filter object
 */
export function filterByProjectIdsOrEmptyItemIdInsideProjectFilter(ids?: string[] | null) {
  if (!isNilOrEmpty(ids)) {
    if (ids!.includes(remainingDataItemId)) {
      return {
        id: { isNull: true },
      };
    } else {
      return {
        idWithDescendants: { contains: ids },
      };
    }
  }

  return undefined;
}

/**
 * Gets the graphQL filter for an id property.
 * Example {memberId: graphQLContainsIdOrEmptyItemId(memberIds)}
 * Handles the "no item" id.
 *
 * @param ids list of ids to filter by or the "no item" id.
 * @returns object used for a graphQL field filter value
 */
export function graphQLContainsIdOrEmptyItemId(ids?: string[] | null) {
  if (!isNilOrEmpty(ids)) {
    if (ids!.includes(remainingDataItemId)) {
      return { isNull: true };
    } else {
      return { contains: ids };
    }
  }

  return undefined;
}

export function aggregateActivityRows(aggregates: IActivityReportRowInfo[]): Required<IActivityReportRowInfo> {
  function add(
    row1: IActivityReportRowInfo,
    row2: IActivityReportRowInfo,
    key: KeysOfType<IActivityReportRowInfo, number | undefined>
  ): number {
    return (row1[key] ?? 0) + (row2[key] ?? 0);
  }

  return reduce(
    aggregates,
    (acc, cur) => {
      return {
        ...acc,
        id: acc.id,
        regularHours: add(cur, acc, 'regularHours'),
        regularHoursDec: add(cur, acc, 'regularHoursDec'),
        overtimeHours: add(cur, acc, 'overtimeHours'),
        overtimeHoursDec: add(cur, acc, 'overtimeHoursDec'),
        doubleTimeHours: add(cur, acc, 'doubleTimeHours'),
        doubleTimeHoursDec: add(cur, acc, 'doubleTimeHoursDec'),
        totalHours: add(cur, acc, 'totalHours'),
        totalHoursDec: add(cur, acc, 'totalHoursDec'),
        regularCost: add(cur, acc, 'regularCost'),
        overtimeCost: add(cur, acc, 'overtimeCost'),
        doubletimeCost: add(cur, acc, 'doubletimeCost'),
        laborBurden: add(cur, acc, 'laborBurden'),
        totalCost: add(cur, acc, 'totalCost'),
      };
    },
    {
      id: '',
      regularHours: 0,
      regularHoursDec: 0,
      overtimeHours: 0,
      overtimeHoursDec: 0,
      doubleTimeHours: 0,
      doubleTimeHoursDec: 0,
      totalHours: 0,
      totalHoursDec: 0,
      regularCost: 0,
      overtimeCost: 0,
      doubletimeCost: 0,
      laborBurden: 0,
      totalCost: 0,
    }
  );
}

export function calculateRemainingActivityData(
  metricData: IActivityReportRowInfo[],
  totalMetric: ILaborMetric
): IActivityReportRowInfo {
  const totalReportRow: IActivityReportRowInfo = {
    id: remainingDataItemId,
    regularHours: totalMetric.regularSeconds + totalMetric.paidTimeOffSeconds,
    regularHoursDec: totalMetric.regularSeconds + totalMetric.paidTimeOffSeconds,
    overtimeHours: totalMetric.overtimeSeconds,
    overtimeHoursDec: totalMetric.overtimeSeconds,
    doubleTimeHours: totalMetric.doubleTimeSeconds,
    doubleTimeHoursDec: totalMetric.doubleTimeSeconds,
    totalHours: totalMetric.totalSeconds,
    totalHoursDec: totalMetric.totalSeconds,
    regularCost: totalMetric.regularCost + totalMetric.timeOffCost,
    overtimeCost: totalMetric.overtimeCost,
    doubletimeCost: totalMetric.doubleTimeCost,
    laborBurden: totalMetric.laborBurden,
    totalCost: totalMetric.totalCost,
  };

  const aggregatedTotal = aggregateActivityRows(metricData);

  return {
    id: remainingDataItemId,
    regularHours: totalReportRow.regularHours - aggregatedTotal.regularHours,
    regularHoursDec: totalReportRow.regularHoursDec - aggregatedTotal.regularHoursDec,
    overtimeHours: totalReportRow.overtimeHours - aggregatedTotal.overtimeHours,
    overtimeHoursDec: totalReportRow.overtimeHoursDec - aggregatedTotal.overtimeHoursDec,
    doubleTimeHours: totalReportRow.doubleTimeHours - aggregatedTotal.doubleTimeHours,
    doubleTimeHoursDec: totalReportRow.doubleTimeHoursDec - aggregatedTotal.doubleTimeHoursDec,
    totalHours: totalReportRow.totalHours - aggregatedTotal.totalHours,
    totalHoursDec: totalReportRow.totalHoursDec - aggregatedTotal.totalHoursDec,
    regularCost: totalReportRow.regularCost - aggregatedTotal.regularCost,
    overtimeCost: totalReportRow.overtimeCost - aggregatedTotal.overtimeCost,
    doubletimeCost: totalReportRow.doubletimeCost - aggregatedTotal.doubletimeCost,
    laborBurden: totalReportRow.laborBurden - aggregatedTotal.laborBurden,
    totalCost: totalReportRow.totalCost - aggregatedTotal.totalCost,
  };
}

export async function getActivityItemTotal(
  client: ApolloClient<object>,
  filterType: ActivityReportType,
  filterId: string | null,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  filterIdWithDescendants?: boolean
): Promise<ILaborMetric> {
  if (filterId === remainingDataItemId) {
    switch (filterType) {
      case ActivityReportType.BY_PROJECT:
        return getNoProjectMetricData(client, timeRange, canViewCost, filterIdWithDescendants);
      case ActivityReportType.BY_COST_CODE:
        return getNoCostCodeMetricData(client, timeRange, canViewCost);
      case ActivityReportType.BY_EQUIPMENT:
        return getNoEquipmentMetricData(client, timeRange, canViewCost);
      default:
        throw Error('Type of ' + filterType + ' is not supported for the No Item');
    }
  }

  switch (filterType) {
    case ActivityReportType.BY_EMPLOYEE:
      return getMemberMetricData(client, filterId!, timeRange, canViewCost);
    case ActivityReportType.BY_PROJECT:
      return getProjectMetricData(client, filterId!, timeRange, canViewCost, filterIdWithDescendants);
    case ActivityReportType.BY_COST_CODE:
      return getCostCodeMetricData(client, filterId!, timeRange, canViewCost);
    case ActivityReportType.BY_EQUIPMENT:
      return getEquipmentMetricData(client, filterId!, timeRange, canViewCost);
    case ActivityReportType.BY_DAY:
    case ActivityReportType.BY_DATE_RANGE:
      return getDateMetricData(client, timeRange, canViewCost);
    default:
      throw Error('Type of ' + filterType + ' is not supported');
  }
}

export async function getMemberMetricData(
  client: ApolloClient<object>,
  memberId: string,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<ILaborMetric> {
  const members = await client.query<{ members: IMember[] }>({
    query: canViewCost ? MEMBERS_WITH_METRIC_QUERY : MEMBERS_WITH_TIME_ONLY_METRIC_QUERY,
    variables: {
      first: 1,
      filter: {
        id: { equal: memberId },
      },
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
    },
    fetchPolicy: 'network-only',
  });

  const data = members.data.members;
  const metricData = !isEmpty(data) ? first(data[0].memberLaborMetrics) : undefined;
  return getMetricLaborTotals(metricData);
}

export async function getProjectMetricData(
  client: ApolloClient<object>,
  projectId: string,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  filterIdWithDescendants?: boolean
): Promise<ILaborMetric> {
  const projects = await client.query<{ projects: IProject[] }>({
    query: canViewCost ? SIMPLE_PROJECT_WITH_METRIC_QUERY : SIMPLE_PROJECT_WITH_TIME_ONLY_METRIC_QUERY,
    variables: {
      first: 1,
      filter: {
        id: { equal: projectId },
      },
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
    },
    fetchPolicy: 'network-only',
  });

  const data = projects.data.projects;
  const metricData = !isEmpty(data) ? first(data[0].projectLaborMetrics) : undefined;
  return getMetricLaborTotals(metricData, filterIdWithDescendants === false);
}

export async function getCostCodeMetricData(
  client: ApolloClient<object>,
  costCodeId: string,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<ILaborMetric> {
  const costCodes = await client.query<{ costCodes: ICostCode[] }>({
    query: canViewCost ? COST_CODES_WITH_METRICS_QUERY : COST_CODES_WITH_TIME_METRICS_QUERY,
    variables: {
      first: 1,
      filter: {
        id: { equal: costCodeId },
      },
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
    },
    fetchPolicy: 'network-only',
  });

  const data = costCodes.data.costCodes;
  const metricData = !isEmpty(data) ? first(data[0].costCodeLaborMetrics) : undefined;
  return getMetricLaborTotals(metricData);
}

export async function getEquipmentMetricData(
  client: ApolloClient<object>,
  equipmentId: string,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<ILaborMetric> {
  const equipment = await client.query<{ equipment: IEquipment[] }>({
    query: canViewCost ? SIMPLE_EQUIPMENT_WITH_METRIC_QUERY : SIMPLE_EQUIPMENT_WITH_TIME_METRIC_QUERY,
    variables: {
      first: 1,
      filter: {
        id: { equal: equipmentId },
      },
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
    },
    fetchPolicy: 'network-only',
  });

  const data = equipment.data.equipment;
  const metricData = !isEmpty(data) ? first(data[0].equipmentLaborMetrics) : undefined;
  return getMetricLaborTotals(metricData);
}

export async function getDateMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<ILaborMetric> {
  return getMetricLaborTotals(await getOrganizationMetrics(client, timeRange, canViewCost));
}

async function getNoProjectMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  filterIdWithDescendants?: boolean
): Promise<ILaborMetric> {
  const projects = await client.query<{ projectLaborMetrics: IProjectTimeMetrics[] }>({
    query: canViewCost ? PROJECT_METRICS_QUERY : PROJECT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: metricNullFilter(ConditionNullableFieldType.ProjectId, ConditionOperationNullType.IsNull),
    },
    fetchPolicy: 'network-only',
  });

  // web services sucks and won't give us pto on project metrics where the project id is null, so we have to make a separate call to get the time off
  // #suckitdrew :~D
  const orgData = await getOrganizationMetrics(client, timeRange, canViewCost);
  const noProjectData = first(projects.data.projectLaborMetrics);
  const metricData = combineMetricWithTimeOff(noProjectData, orgData);

  return getMetricLaborTotals(metricData, filterIdWithDescendants === false);
}

async function getNoCostCodeMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<ILaborMetric> {
  const costCodes = await client.query<{ costCodeLaborMetrics: ICostCodeTimeMetrics[] }>({
    query: canViewCost ? COST_CODE_METRICS_QUERY : COST_CODE_TIME_METRICS_QUERY,
    variables: {
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: metricNullFilter(ConditionNullableFieldType.CostCodeId, ConditionOperationNullType.IsNull),
    },
    fetchPolicy: 'network-only',
  });

  // web services sucks and won't give us pto on cost code metrics where the cost code id is null, so we have to make a separate call to get the time off
  // #suckitdrew :~D
  const orgData = await getOrganizationMetrics(client, timeRange, canViewCost);
  const noCostCodeData = first(costCodes.data.costCodeLaborMetrics);
  const metricData = combineMetricWithTimeOff(noCostCodeData, orgData);

  return getMetricLaborTotals(metricData);
}

async function getNoEquipmentMetricData(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<ILaborMetric> {
  const equipment = await client.query<{ equipmentLaborMetrics: IEquipmentTimeMetrics[] }>({
    query: canViewCost ? EQUIPMENT_METRICS_QUERY : EQUIPMENT_TIME_METRICS_QUERY,
    variables: {
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
    },
    fetchPolicy: 'network-only',
  });

  // web services sucks and won't give us pto on equipment metrics where the equipment id is null, so we have to make a separate call to get the time off
  // #suckitdrew :~D
  const orgData = await getOrganizationMetrics(client, timeRange, canViewCost);
  const noEquipmentData = first(equipment.data.equipmentLaborMetrics);
  const metricData = combineMetricWithTimeOff(noEquipmentData, orgData);

  return getMetricLaborTotals(metricData);
}

export async function getOrganizationMetrics(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  metricsInterval: LaborMetricsInterval = LaborMetricsInterval.Custom
): Promise<OrganizationTimeMetrics | undefined> {
  OrganizationLaborMetricsFragmentDoc;
  const dateMetric = await client.query<{ organizationLaborMetrics: OrganizationTimeMetrics[] }>({
    query: canViewCost ? ORGANIZATION_LABOR_METRIC_QUERY : ORGANIZATION_TIME_METRIC_QUERY,
    variables: {
      metricsInterval,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
    },
    fetchPolicy: 'network-only',
  });

  return first(dateMetric.data.organizationLaborMetrics);
}

export async function getMemberTimeOffMetricDictionary(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean
): Promise<Dictionary<ITimeOffLaborMetrics>> {
  const members = await client.query<{ memberLaborMetrics: IMemberTimeMetrics[] }>({
    query: canViewCost ? MEMBER_METRICS_QUERY : MEMBER_TIME_METRICS_QUERY,
    variables: {
      metricsInterval: LaborMetricsInterval.Custom,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: {
        operationType: OperationType.And,
        conditions: [
          {
            field: ConditionFieldType.Pto,
            operator: ConditionOperationType.GreaterThan,
            value: 0,
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
  });

  return keyBy(members.data.memberLaborMetrics, (metric) => metric.memberId);
}

export async function getMemberTimeOffMetricListDictionary(
  client: ApolloClient<object>,
  timeRange: ITimeRange<DateTime>,
  canViewCost: boolean,
  intervalType: LaborMetricsInterval
): Promise<Dictionary<ITimeOffLaborMetrics[]>> {
  const members = await client.query<{ memberLaborMetrics: IMemberTimeMetrics[] }>({
    query: canViewCost ? MEMBER_METRICS_QUERY : MEMBER_TIME_METRICS_QUERY,
    variables: {
      metricsInterval: intervalType,
      metricsStartDate: timeRange.startTime.toISODate(),
      metricsEndDate: timeRange.endTime.toISODate(),
      metricFilter: {
        operationType: OperationType.And,
        conditions: [
          {
            field: ConditionFieldType.Pto,
            operator: ConditionOperationType.GreaterThan,
            value: 0,
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
  });

  return groupBy(members.data.memberLaborMetrics, (metric) => metric.memberId);
}
