import { useApolloClient } from '@apollo/client';
import { LaborMetricsInterval } from '__generated__/graphql';
import { SIMPLE_PROJECT_QUERY } from 'apollo/queries/project-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY } from 'containers/activity-reports/queries/project-activity-queries';
import useGetProgressBudgetActualsData, {
  IProgressBudgetActuals,
} from 'containers/budgets/CreateBudgetForm/hooks/useGetProgressBudgetActualsData';
import useProgressValueEnabled from 'containers/budgets/CreateBudgetForm/hooks/useProgressValueEnabled';
import useBudgetCostData from 'containers/budgets/hooks/useBudgetCostData';
import useHoursBudgetData from 'containers/budgets/hooks/useHoursBudgetData';
import { useDashboardTimeRange } from 'containers/dashboard/hooks';
import { useToastOpen } from 'contexts/ToastContext';
import { useApolloPaging, useOrganization } from 'hooks';
import {
  projectEquipmentMetricQueryProvider,
  projectLaborMetricQueryProvider,
} from 'hooks/aggregates/metrics/ProjectMetricQueryProviders';
import useAllTime from 'hooks/utils/useAllTime';
import { Dictionary, groupBy, indexOf, isEmpty, isNil, isNull, sumBy } from 'lodash';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import IProject from 'types/Project';
import IJitLaborMetric from 'types/aggregate/JitLaborMetric';
import { getMetricLaborTotals } from 'utils/jitMetricUtils';
import { t } from 'utils/localize';

export interface IBudgetReportDataFilter {
  projectIds?: string[];
  archivedStatus: ArchivedStatus;
  rootProjectsOnly: boolean;
  projectGroupId?: string;
}
export interface IBudgetDashboardCardItem {
  id: string;
  project: IProject;
  budgetSeconds: number | null;
  actualSeconds: number | null;
  actualSecondsForRange: number | null;
  budgetCost: number | null;
  budgetProgressValue: number | null;
  actualProgressValue: number | null;
  actualCost: number | null;
  equipmentBudgetSeconds: number | null;
  equipmentActualSeconds: number | null;
  equipmentBudgetCost: number | null;
  equipmentActualCost: number | null;
  isArchived: boolean;
  sortOrder?: number;
}

export default async function useBudgetDashboardData(setting: IDashboardSettingsItem) {
  const toast = useToastOpen();
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const { getLatestBudgetCostData } = useBudgetCostData();
  const { getLatestBudgetHoursData } = useHoursBudgetData();
  const timeFrame = setting.options?.time ?? 'pastSeven';
  const timeRange = useDashboardTimeRange(timeFrame);
  const getAllTime = useAllTime();
  const useCustomIds = setting.options?.projectsToDisplay === 'custom';
  const customIdArray = setting.options?.customProjects
    ? setting.options!.customProjects.filter((p: string) => !isNil(p))
    : null;
  const isProgressValueEnabled = useProgressValueEnabled();
  const getProgressBudgetActualsData = useGetProgressBudgetActualsData();
  const { trackBudgetEquipmentHours, trackBudgetEquipmentCosts } = useOrganization();

  const getProjects = useCallback(async () => {
    const timeResults = await getAll<IProject & ICursorable>('projectsWithTime', {
      query: PROJECT_WITH_TIME_AND_TIME_METRIC_AND_SUB_CHECK_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 4,
        filter: {
          depth: { equal: 1 },
          archivedOn: { isNull: true },
        },
        sort: [{ title: 'asc' }],
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        startTime: timeRange.startTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        endTime: timeRange.endTime.toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
        includeOpenEntry: false,
        includeAncestors: true,
        metricsInterval: LaborMetricsInterval.Custom,
      },
    });

    const customResults = await client.query<{ projects: Array<IProject & ICursorable> }>({
      query: SIMPLE_PROJECT_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: 4,
        filter: {
          depth: { equal: 1 },
          archivedOn: { isNull: true },
          id: { contains: customIdArray ?? undefined },
        },
      },
    });
    return useCustomIds ? customResults.data.projects : timeResults;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAll, timeRange.startTime, timeRange.endTime, useCustomIds, customIdArray]);

  return useCallback(async () => {
    try {
      const projects = await getProjects();
      const projectIds = projects.map((p) => p.id);
      const metricQuery = projectLaborMetricQueryProvider(getAll, projectIds, undefined, 'all');
      const rangedMetrics = await metricQuery(timeRange.startTime, timeRange.endTime, LaborMetricsInterval.Custom);
      const groupRangedMetrics = groupBy(rangedMetrics, 'projectId');
      const allTime = await getAllTime({ projectIds });
      const metrics = await metricQuery(allTime.startTime, allTime.endTime, LaborMetricsInterval.Custom);
      const groupMetrics = groupBy(metrics, 'projectId');

      let projectEquipmentGroupMetrics: Dictionary<IJitLaborMetric[]> | null = null;

      if (trackBudgetEquipmentHours || trackBudgetEquipmentCosts) {
        const projectEquipmentMetricQuery = projectEquipmentMetricQueryProvider(getAll, projectIds, undefined, 'all');
        const projectEquipmentMetrics = await projectEquipmentMetricQuery(
          allTime.startTime,
          allTime.endTime,
          LaborMetricsInterval.Custom
        );
        projectEquipmentGroupMetrics = groupBy(projectEquipmentMetrics, 'projectId');
      }

      const promises = projects.map(async (p) => {
        const hoursBudget = await getLatestBudgetHoursData({
          projectId: { equal: p.id },
          costCodeId: { isNull: true },
          equipmentId: { isNull: true },
        });
        const costBudget = await getLatestBudgetCostData({
          projectId: { equal: p.id },
          costCodeId: { isNull: true },
          equipmentId: { isNull: true },
        });

        let progressBudgetActuals: IProgressBudgetActuals | null = null;
        if (isProgressValueEnabled) {
          progressBudgetActuals = await getProgressBudgetActualsData(p.id, [], true);
        }

        return {
          id: p.id,
          project: p,
          budgetSeconds: !isEmpty(hoursBudget) ? sumBy(hoursBudget, (h) => h.budgetSeconds) : null,
          budgetCost: !isEmpty(costBudget) ? sumBy(costBudget, (h) => h.costBudget ?? 0) : null,
          actualCost: !isEmpty(groupMetrics[p.id])
            ? sumBy(groupMetrics[p.id], (m) => getMetricLaborTotals(m).totalCost)
            : null,
          actualSeconds: !isEmpty(groupMetrics[p.id])
            ? sumBy(groupMetrics[p.id], (m) => getMetricLaborTotals(m).totalSeconds)
            : null,
          actualSecondsForRange: !isEmpty(groupRangedMetrics[p.id])
            ? sumBy(groupRangedMetrics[p.id], (m) => getMetricLaborTotals(m).totalSeconds)
            : null,
          budgetProgressValue: progressBudgetActuals?.budget ?? null,
          actualProgressValue: progressBudgetActuals?.actual ?? null,
          equipmentBudgetSeconds: !isEmpty(hoursBudget)
            ? sumBy(hoursBudget, (h) => h.equipmentBudgetSeconds ?? 0)
            : null,
          equipmentBudgetCost: !isEmpty(costBudget) ? sumBy(costBudget, (h) => h.equipmentCostBudget ?? 0) : null,
          equipmentActualCost:
            !isNull(projectEquipmentGroupMetrics) && !isEmpty(projectEquipmentGroupMetrics[p.id])
              ? sumBy(projectEquipmentGroupMetrics[p.id], (m) => getMetricLaborTotals(m).equipmentCost)
              : null,
          equipmentActualSeconds:
            !isNull(projectEquipmentGroupMetrics) && !isEmpty(projectEquipmentGroupMetrics[p.id])
              ? sumBy(projectEquipmentGroupMetrics[p.id], (m) => getMetricLaborTotals(m).totalSeconds)
              : null,
          isArchived: !isNil(p.archivedOn),
          sortOrder: useCustomIds ? indexOf(customIdArray, p.id) : undefined,
        } as IBudgetDashboardCardItem;
      });
      return await Promise.all(promises).then((queryResults) => {
        return queryResults;
      });
    } catch (error) {
      toast({ label: t('Something went wrong. Please try again later.') });
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getProjects,
    getAll,
    timeRange.startTime,
    timeRange.endTime,
    getAllTime,
    getLatestBudgetHoursData,
    getLatestBudgetCostData,
    toast,
  ]);
}
