import {
  Field,
  FieldFooter,
  IFieldRender,
  IFormFieldDefaultProps,
  ITimeFieldPayload,
  ITimeFieldProps,
  Row,
  TimeField,
} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import FeatureTimeField from 'components/domain/time/FeatureTimeField/FeatureTimeField';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import ClockAction from 'types/enum/ClockAction';
import ITimeRange from 'types/TimeRange';
import { AnyObject } from 'types/util/Object';
import { getTimezoneSupStringForDate } from 'utils/dateUtils';
import './FeatureTimeRangeFormField.scss';

export interface IFeatureTimeRangeFormFieldProps<FormType extends AnyObject>
  extends Omit<ITimeFieldProps, 'value' | 'minuteStep' | 'error' | 'onChange' | 'clockAction'>,
    IFormFieldDefaultProps<ITimeRange<DateTime | null | undefined> | null, FormType> {
  onChange?: (value: ITimeRange<DateTime | null | undefined> | null) => void;
  startClockAction: ClockAction;
  endClockAction: ClockAction;
  name: string;
  startDst?: boolean;
  endDst?: boolean | null;
}

function FeatureTimeRangeFormField<T extends { [field: string]: any }>({
  name,
  form,
  validations,
  startClockAction,
  endClockAction,
  onChange,
  className,
  startDst,
  endDst,
  revalidateOnNewValidations,
  ...sharedTimeFieldProps
}: IFeatureTimeRangeFormFieldProps<T>) {
  function renderFields({ ...fieldProps }: IFieldRender<ITimeRange<DateTime | null | undefined> | null>) {
    const currentValue: ITimeRange<DateTime | null | undefined> | null = form.state.data[name];

    function handleStartChange(val: ITimeFieldPayload) {
      const newValue = { startTime: val.value, endTime: currentValue?.endTime };
      if (newValue.startTime || newValue.endTime) {
        fieldProps.handleChange(newValue, onChange);
      } else {
        // If neither are set set the range to null for requirement validation purposes
        fieldProps.handleChange(null, onChange);
      }
    }

    function handleEndChange(val: ITimeFieldPayload) {
      const newValue = { startTime: currentValue?.startTime, endTime: val.value };
      if (newValue.startTime || newValue.endTime) {
        fieldProps.handleChange(newValue, onChange);
      } else {
        // If neither are set set the range to null for requirement validation purposes
        fieldProps.handleChange(null, onChange);
      }
    }

    return (
      <Row className={classes}>
        <div>
          <FeatureTimeField
            {...sharedTimeFieldProps}
            value={currentValue?.startTime ?? null}
            onChange={handleStartChange}
            clockAction={startClockAction}
          />
          {currentValue?.startTime && !isNil(startDst) && (
            <div className="time-zone-sup">
              {getTimezoneSupStringForDate(currentValue?.startTime, startDst, currentValue?.endTime)}
            </div>
          )}
        </div>
        <div className="dash">-</div>
        <div>
          <FeatureTimeField
            {...sharedTimeFieldProps}
            value={currentValue?.endTime ?? null}
            onChange={handleEndChange}
            clockAction={endClockAction}
          />
          {currentValue?.endTime && !isNil(endDst) && (
            <div className="time-zone-sup">
              {getTimezoneSupStringForDate(currentValue?.endTime, endDst, currentValue?.startTime)}
            </div>
          )}
        </div>

        <FieldFooter errorMessage={fieldProps.errorMessage} hasError={fieldProps.hasError} />
      </Row>
    );
  }

  const classes = classNames('time-range-form-field', className);

  return (
    <Field
      name={name}
      render={renderFields}
      form={form}
      validations={validations}
      className={classes}
      revalidateOnNewValidations={revalidateOnNewValidations}
    />
  );
}

FeatureTimeRangeFormField.defaultProps = {
  ...TimeField.defaultProps,
  startClockAction: ClockAction,
  endClockAction: ClockAction,
};

export default FeatureTimeRangeFormField;
