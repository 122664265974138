import { gql } from '@apollo/client';
import { OrganizationSignOffQuestion, OrganizationSignOffQuestionFilter } from '__generated__/graphql';
import { useApolloPaging } from 'hooks';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';

export default function useGetOrganizationSignOffQuestions() {
  const { getAll } = useApolloPaging();

  return useCallback(
    async (filter?: OrganizationSignOffQuestionFilter) => {
      return await getAll<ICursorable & OrganizationSignOffQuestion>('organizationSignOffQuestions', {
        fetchPolicy: 'network-only',
        query: QUERY_ORGANIZATION_SIGN_OFF_QUESTIONS,
        variables: {
          filter: {
            ...filter,
          },
          sort: [{ order: 'asc' }],
        },
      });
    },
    [getAll]
  );
}

export const QUERY_ORGANIZATION_SIGN_OFF_QUESTIONS = gql`
  query getAllOrganizationSignOffQuestions(
    $filter: OrganizationSignOffQuestionFilter
    $first: Int
    $sort: [OrganizationSignOffQuestionSort!]
    $after: String
  ) {
    organizationSignOffQuestions(filter: $filter, first: $first, sort: $sort, after: $after) {
      id
      audienceType
      choices
      createdOn
      cursor
      deletedOn
      organizationId
      positionId
      questionDescription
      questionPrompt
      questionTitle
      submittedOn
      type
      updatedOn
      order
    }
  }
`;
