import useNavigation from './useNavigation';

interface IParams {
  costCodeId?: string;
  groupId?: string;
  archived?: boolean;
}

export default function useCostCodeManagementListNavigation() {
  return useNavigation<IParams>('/manage/cost-codes');
}
