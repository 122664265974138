import {
  Align,
  Button,
  ButtonList,
  Icon,
  ITableColumn,
  Justify,
  Label,
  Position,
  Row,
  Size,
  Table,
  TextAlign,
  Theme,
} from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import { AddCircleIcon, LockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import PayPeriodSignatures from 'components/domain/pay-period-signature/PayPeriodSignatures/PayPeriodSignatures';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import MoreButton from 'components/foundation/buttons/MoreButton/MoreButton';
import Spinner from 'components/foundation/Spinner/Spinner';
import OptionallyFormattedHours from 'components/foundation/text/OptionallyFormattedHours/OptionallyFormattedHours';
import {
  TimeCardReportContext,
  TimeCardReportMemberData,
} from 'containers/timesheets/TimeCardReport/context/TimeCardReportContext';
import { useOpenable, useOrganization, usePermissions, useTableSorting } from 'hooks';
import _, { differenceWith, isEmpty, isNil, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { IMember } from 'types';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeRange from 'types/TimeRange';
import { memberLockUtils } from 'utils';
import { getDateString, getDateTimesBetween } from 'utils/dateUtils';
import { useFeatureFlags } from 'utils/features';
import './TimeCardReportTable.scss';

export interface ITimeCardReportTableProps {
  className?: ClassName;
  member: IMember;
  timeRange: ITimeRange<DateTime>;
  timeRangeIsPayPeriod: boolean;
  onAddTimeEntry: (selectedDate: DateTime, member: IMember) => void;
  onAddTimeOff: (selectedDate: DateTime, member: IMember) => void;
  onViewSignOff: (selectedDate: DateTime, member: IMember) => void;
  onViewTimeEntries: (selectedDate: DateTime, member: IMember) => void;
  scroller?: HTMLElement;
  onSignatureComplete?: () => void;
  onSignaturesLoaded?: (memberId: string, isLoaded: boolean) => void;
  rowHeight?: TableCellHeight;
}

export interface ITimeCardRowInfo {
  date: DateTime;
  timeAccurate: boolean | null;
  injured: boolean | null;
  breakCompliance: boolean | null;
  regularHours: number | null;
  overtimeHours: number | null;
  doubleTimeHours: number | null;
  paidTimeOff: number | null;
  break: number | null;
  totalHours: number | null;
}

const emptyTableData = (timeRange: ITimeRange<DateTime>) => {
  return getDateTimesBetween(timeRange.startTime, timeRange.endTime).map((day) => {
    return {
      date: day,
      timeAccurate: null,
      injured: null,
      breakCompliance: null,
      regularHours: null,
      overtimeHours: null,
      doubleTimeHours: null,
      paidTimeOff: null,
      break: null,
      totalHours: null,
    };
  });
};

const TimeCardReportTableContainer = (props: ITimeCardReportTableProps) => {
  const { member, ...rest } = props;

  const { data: mappedData, onSignaturesLoaded } = useContext(TimeCardReportContext);
  const data = mappedData?.byId[member.id] ?? null;
  const previousData = useRef<TimeCardReportMemberData | null>(null);

  function isEqual() {
    const previous = previousData.current;
    if (!data || !previous) {
      return false;
    } else if (!isEmpty(differenceWith(data.rows, previous.rows, _.isEqual))) {
      return false;
    }

    return Object.keys(data).every((k: string) => {
      const key = k as keyof TimeCardReportMemberData;

      // Need to do rows manually because luxon/DateTime ruins everything
      if (key === 'rows') {
        const dataRows = data[key];
        const previousRows = data[key];
        return dataRows.every((row, index) => {
          return Object.keys(row).every((rowK) => {
            const rowKey = rowK as keyof ITimeCardRowInfo;
            // Since we're using the time range to determine if each date is equal don't need to compare
            if (rowKey !== 'date') {
              return _.isEqual(row[rowKey], previousRows[index][rowKey]);
            } else {
              return row.date.equals(previousRows[index].date);
            }
          });
        });
      } else {
        return _.isEqual(data[key], previous[key]);
      }
    });
  }

  if ((data !== null && previousData.current === null) || !isEqual()) {
    previousData.current = data;
  }

  return (
    <MemoizedTimeCardReportTable
      data={previousData.current}
      member={member}
      onSignaturesLoaded={onSignaturesLoaded}
      {...rest}
    />
  );
};

const TimeCardReportTable = (props: ITimeCardReportTableProps & { data: TimeCardReportMemberData | null }) => {
  const {
    className,
    member,
    data,
    timeRange,
    timeRangeIsPayPeriod,
    scroller,
    onAddTimeEntry,
    onAddTimeOff,
    onViewSignOff,
    onViewTimeEntries,
    onSignatureComplete,
    onSignaturesLoaded,
    rowHeight,
  } = props;

  const useSmallIcon = rowHeight === 'cozy' || rowHeight === 'compact';

  const organization = useOrganization();
  const { hasPermissionToManage } = usePermissions();
  const canManage = useRef<boolean>(hasPermissionToManage(member, 'manageTimeEntries'));
  const canManageTimeOff = useRef<boolean>(hasPermissionToManage(member, 'manageTimeOff'));
  const isArchived = useRef<boolean>(!isNil(member.archivedOn));
  const [t] = useTranslation();
  const isPro = useFeatureFlags('PRO');
  const isDailySignOffEnabled = organization.safetySignature || organization.timeAccuracy || organization.breakPolicy;

  const emptyData = useMemo(() => emptyTableData(timeRange), [timeRange]); // prevents re-renders from having a new empty state object created for each render which then causes more rendering

  const showSignaturesFooter = timeRangeIsPayPeriod && organization.signatureDate;

  const payPeriodIsOpen = timeRange.endTime > DateTime.local();
  const [lockDate, setLockDate] = useState<string | undefined | null>(member.memberLock?.effectiveDate);
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting<ITimeCardRowInfo>(
    data?.rows ?? emptyData
  );
  const columnSettings = useSelector<IReduxState, IVisibleTableColumn[]>(
    (state) => state.timesheet.summaryAndTimeCardTableColumns
  );
  const forceUpdate = useOpenable();
  useEffect(() => {
    forceUpdate.toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function renderDateColumnFooter(): ReactNode {
    return <Label>{t('Totals')}</Label>;
  }

  function renderDateColumn(row: ITimeCardRowInfo): ReactNode {
    function renderDropdownContent(closeDropdown: () => void) {
      function handleViewSignOffDropdown(event: React.MouseEvent) {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();
        onViewSignOff(row.date, member);
      }

      function handleOpenAddTimeOff(event: React.MouseEvent) {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();
        onAddTimeOff(row.date.plus({ day: 1 }), member);
      }

      return (
        <ButtonList>
          {isPro && isDailySignOffEnabled && (
            <Button onClick={handleViewSignOffDropdown}>{t('View Daily Sign-Off')}</Button>
          )}
          {canManageTimeOff.current && !isArchived.current && (
            <Button onClick={handleOpenAddTimeOff}>{t('Add Time Off')}</Button>
          )}
        </ButtonList>
      );
    }

    function actionAdd(event: React.MouseEvent) {
      event.stopPropagation();
      event.preventDefault();
      onAddTimeEntry(row.date.plus({ day: 1 }), member);
    }

    return (
      <div className="date-cell">
        <div className="date-content">
          {canManage.current && !isArchived.current && (
            <IconButton
              className={`no-print button ${useSmallIcon ? 'small-icon' : ''}`}
              svg={AddCircleIcon}
              onClick={actionAdd}
              theme={Theme.LIGHT}
              size={Size.MEDIUM}
              tooltipLabel={t('Add')}
            />
          )}

          {((isPro && isDailySignOffEnabled) || (canManage.current && !isArchived.current)) && (
            <MoreButton
              className={`no-print button ${useSmallIcon ? 'small-icon' : ''}`}
              position={Position.BOTTOM_START}
              renderContent={renderDropdownContent}
            />
          )}
          <Label className="ml-2 mb-0">{getDateString(row.date, 'ccc, LLL d', true)}</Label>
        </div>

        {lockDate &&
        memberLockUtils.inhibitsActions(DateTime.fromISO(lockDate), row.date.endOf('day').startOf('second')) ? (
          <div className="lock" data-testid="lock_icon">
            <Icon key="lock" size={Size.MEDIUM} svg={LockIcon} className={'lock-button no-print'} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  function renderAnswer(row: ITimeCardRowInfo, col: ITableColumn<ITimeCardRowInfo>): ReactNode {
    let answer: boolean | null = null;
    let answerText = '---';

    switch (col.key) {
      case 'injured':
        answer = row.injured;
        break;
      case 'timeAccurate':
        answer = row.timeAccurate;
        break;
      case 'breakCompliance':
        answer = row.breakCompliance;
        break;
    }

    if (answer === true) {
      answerText = t('Yes');
    } else if (answer === false) {
      answerText = t('No');
    }

    return <Label>{answerText}</Label>;
  }

  function renderDuration(row: ITimeCardRowInfo, col: ITableColumn<ITimeCardRowInfo>): ReactNode {
    const isDecimal = col.key.includes('Dec');
    const columnKey = isDecimal ? col.key.replace('Dec', '') : col.key;

    return (
      <OptionallyFormattedHours
        payload={{ type: isDecimal ? 'DECIMAL' : 'TIME' }}
        value={row[columnKey as keyof ITimeCardRowInfo] as number | null | undefined}
      />
    );
  }

  function renderDurationFooter(col: ITableColumn<ITimeCardRowInfo>): ReactNode {
    let answer: number | null = null;

    if (data) {
      switch (col.key) {
        case 'regularHours':
        case 'regularHoursDec':
          answer = _.sumBy(data.rows, (entry) => entry.regularHours ?? 0);
          break;
        case 'overtimeHours':
        case 'overtimeHoursDec':
          answer = _.sumBy(data.rows, (entry) => entry.overtimeHours ?? 0);
          break;
        case 'doubleTimeHours':
        case 'doubleTimeHoursDec':
          answer = _.sumBy(data.rows, (entry) => entry.doubleTimeHours ?? 0);
          break;
        case 'paidTimeOff':
          answer = _.sumBy(data.rows, (entry) => entry.paidTimeOff ?? 0);
          break;
        case 'break':
          answer = 0;
          break;
        case 'totalHours':
        case 'totalHoursDec':
          answer = answer = _.sumBy(data.rows, (entry) => entry.totalHours ?? 0);
          break;
      }
    }

    const isDecimal = col.key.includes('Dec');

    return <OptionallyFormattedHours payload={{ type: isDecimal ? 'DECIMAL' : 'TIME' }} value={answer} />;
  }

  function onSignComplete(effectiveDate?: DateTime) {
    // if we have a new lock date then we need to update our state
    if (effectiveDate) {
      setLockDate(effectiveDate.toISO());
    }
    onSignatureComplete?.();
  }

  const renderEmptyState = useCallback((): ReactNode => {
    return (
      <Row justify={Justify.CENTER} align={Align.CENTER}>
        <Spinner />
      </Row>
    );
  }, []);

  const getColumns = useCallback((): Array<ITableColumn<ITimeCardRowInfo>> => {
    const dailySignOffColumns = Array<ITableColumn<ITimeCardRowInfo>>();
    if (isPro) {
      dailySignOffColumns.push({
        cell: renderAnswer,
        key: 'timeAccurate',
        sort: true,
        title: t('Time Acc.'),
        tooltip: t('Time Accurate'),
        align: TextAlign.CENTER,
        headerTextWrap: true,
        size: '70px',
        cellClassName: `time-card-table-cell`,
      });
      dailySignOffColumns.push({
        cell: renderAnswer,
        key: 'breakCompliance',
        sort: true,
        title: t('Break Comp.'),
        tooltip: t('Break Compliance'),
        align: TextAlign.CENTER,
        size: '90px',
        headerTextWrap: true,
        cellClassName: `time-card-table-cell`,
      });
      dailySignOffColumns.push({
        cell: renderAnswer,
        key: 'injured',
        sort: true,
        title: t('Injured'),
        align: TextAlign.CENTER,
        size: '90px',
        headerTextWrap: true,
        cellClassName: `time-card-table-cell`,
      });
    }
    const columns = [
      {
        cell: renderDateColumn,
        key: 'date',
        sort: true,
        title: t('Date'),
        align: TextAlign.LEFT,
        footer: renderDateColumnFooter,
        cellClassName: `time-card-table-cell`,
      },
      ...dailySignOffColumns,
      {
        cell: renderDuration,
        key: 'regularHours',
        sort: true,
        title: t('Reg'),
        tooltip: t('Regular Hours'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'regularHoursDec',
        sort: true,
        title: t('Reg (Decimal)'),
        tooltip: t('Regular Hours (Decimal'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'overtimeHours',
        sort: true,
        title: t('OT'),
        tooltip: t('Overtime Hours'),
        align: TextAlign.CENTER,
        size: '80px',
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'overtimeHoursDec',
        sort: true,
        title: t('OT (Decimal)'),
        tooltip: t('Overtime Hours (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'doubleTimeHours',
        sort: true,
        title: t('DT'),
        tooltip: t('Double Time Hours'),
        align: TextAlign.CENTER,
        size: '90px',
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'doubleTimeHoursDec',
        sort: true,
        title: t('DT (Decimal)'),
        tooltip: t('Double Time Hours (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'paidTimeOff',
        sort: true,
        title: t('PTO'),
        tooltip: t('Paid Time Off'),
        align: TextAlign.CENTER,
        size: '90px',
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'totalHours',
        sort: true,
        title: t('Total'),
        align: TextAlign.CENTER,
        size: '100px',
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
      {
        cell: renderDuration,
        key: 'totalHoursDec',
        sort: true,
        title: t('Total (Decimal)'),
        align: TextAlign.CENTER,
        size: '110px',
        headerTextWrap: true,
        footer: renderDurationFooter,
        cellClassName: `time-card-table-cell`,
      },
    ];

    const columnSettingMap = _.keyBy(columnSettings, 'key');

    // only show the columns that the user has chosen and always show the employee column
    //  this will filter out columns that aren't available due to company settings having the feature off
    return _.sortBy(
      columns.filter((col) => col.key === 'date' || columnSettingMap[col.key]?.visible === true),
      (col) => columnSettingMap[col.key]?.position ?? 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnSettings, data, rowHeight]); // we need to observe the data to update the totals when the data changes

  const handleRowClick = useCallback(
    (row: ITimeCardRowInfo) => {
      onViewTimeEntries(row.date, member);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member]
  );

  const classes = classNames(
    {
      'time-card-report-table': true,
    },
    className
  );

  const tableWidth = () => {
    const columns = getColumns();
    return _.sumBy(columns, (column) => toNumber(column.size?.replace('px', '') ?? '0')) + 220;
  };

  return (
    <div className="time-card-report-employee-container">
      <Table
        className={classes}
        cols={getColumns()}
        data={sorted}
        sortDir={sortDirection}
        sortBy={sortedBy}
        strokeCols={true}
        lazyLoad={false}
        onSort={onSort}
        sortIsDirty={sortIsDirty}
        emptyTemplate={renderEmptyState()}
        footer="standard"
        header="standard"
        scroller={scroller}
        lazyScrollSectionSize={30}
        minWidth={`${tableWidth()}px`}
        onRowClick={handleRowClick}
        cellHeight={rowHeight}
      />
      {showSignaturesFooter && sorted.length > 0 ? (
        <PayPeriodSignatures
          member={member}
          timeRange={timeRange}
          onComplete={onSignComplete}
          forceReload={forceUpdate.isOpen}
          onSignaturesLoaded={onSignaturesLoaded}
          payPeriodIsOpen={payPeriodIsOpen}
        />
      ) : null}
    </div>
  );
};

const MemoizedTimeCardReportTable = React.memo(TimeCardReportTable);
export default React.memo(TimeCardReportTableContainer);
