import { UnknownAction } from 'redux';
import { AUTHENTICATION_CLEAR_TOKEN } from '../authenticationConstants';

export interface IStoreAuthenticationClearTokenAction extends UnknownAction {
  type: typeof AUTHENTICATION_CLEAR_TOKEN;
}

export function authenticationClearTokenAction(): IStoreAuthenticationClearTokenAction {
  return { type: AUTHENTICATION_CLEAR_TOKEN };
}
