import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { usePermissions } from 'hooks';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import ITimeOff from 'types/TimeOff';
import { dateUtils, stringUtils } from 'utils';
import { t } from 'utils/localize';
import { ITimeEntryDataTableRow } from '../TimeEntryDataTable';

export function useTimeOffToTimeOffRowMap() {
  const { hasPermissionToManage } = usePermissions();

  const employeeNameFormatter = useEmployeeNameFormatter();

  return useCallback(
    (timeOff: ITimeOff): ITimeEntryDataTableRow => {
      const dateStamp = dateUtils.dateTimeFromISOKeepZone(timeOff.dateStamp);
      return {
        id: timeOff.id,
        key: timeOff.id + dateUtils.getDateString(dateStamp, 'ccc, LLL d', true),
        startDate: dateStamp,
        endDate: dateStamp,
        member: timeOff.member,
        date: dateUtils.getDateString(dateStamp, 'ccc, LLL d', true),
        day: dateStamp,
        employee: employeeNameFormatter(timeOff.member.firstName ?? '', timeOff.member.lastName ?? ''),
        type: timeOff.paid ? t('PTO') : t('Unpaid'),
        total: stringUtils.getTotalAsHoursMinutesSeconds(timeOff.seconds ? timeOff.seconds : 0),
        totalSeconds: timeOff.seconds ? timeOff.seconds : 0,
        start: '---',
        startSup: null,
        end: '---',
        endSup: null,
        description: timeOff.description,
        timeOffType: timeOff.type,
        actions: null,
        injured: '---',
        breakCompliance: '---',
        timeAccurate: '---',
        ot: '0:00',
        otSeconds: 0,
        dt: '0:00',
        dtSeconds: 0,
        // Metadata not shown in table
        timesheetReportType: timeOff.paid ? TimesheetReportType.TIME_OFF_PAID : TimesheetReportType.TIME_OFF_UNPAID,
        canManage: timeOff.member ? hasPermissionToManage(timeOff.member, 'manageTimeOff') : false,
        isArchived: !isNil(timeOff.member.archivedOn),
        exportStartISO: 'dont care',
        exportEndISO: 'dont care',
      };
    },
    [employeeNameFormatter, hasPermissionToManage]
  );
}
