import { useApolloClient } from '@apollo/client';
import { SortDirection } from '@busybusy/webapp-react-ui';
import {
  ConditionFieldType,
  ConditionNullableFieldType,
  ConditionOperationNullType,
  ConditionOperationType,
  Equipment,
  LaborMetricsInterface,
  LaborMetricsInterval,
  Maybe,
  Project,
  Scalars,
} from '__generated__/graphql';
import { PROJECT_ANCESTORS_QUERY } from 'apollo/queries/project-queries';
import {
  SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY,
  SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY,
} from 'containers/activity-reports/queries/equipment-activity-queries';
import {
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY,
  PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY,
} from 'containers/activity-reports/queries/project-activity-queries';
import { activityReportUtils } from 'containers/activity-reports/utils/ActivityReportUtils';
import { useApolloPaging, useTableSorting } from 'hooks';
import useHasCostPermission from 'hooks/permission/useHasCostPermission';
import { Dictionary, first, isEmpty, isNil, keyBy, sumBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeRange from 'types/TimeRange';
import { mapNotNil, mapNotNull, sortByIgnoreCase } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { getCostHistoryForRange } from 'utils/equipmentUtils';
import { combineMetricFilters, metricConditionFilter, metricNullFilter } from 'utils/jitMetricUtils';
import { getGeneratedMetricLaborTotals } from 'utils/metricUtils';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import { getEquipmentDisplay } from 'utils/stringUtils';
import { ActivityReportType } from '../../ActivityReportFilter/ActivityReportFilter';
import {
  activityIdFilter,
  aggregateActivityRows,
  calculateRemainingActivityData,
  getActivityItemTotal,
  graphQLContainsIdOrEmptyItemId,
} from '../../hooks/ActivitySummaryQueryUtils';
import { IEquipmentActivityTableRowInfo } from './useEquipmentActivity';

export interface IEquipmentProjectActivityTableRowInfo extends IEquipmentActivityTableRowInfo {
  projectId: string | null;
  project: Project | null;
}

interface EquipmentProjectMetricRow extends LaborMetricsInterface {
  equipmentId?: Maybe<Scalars['Uuid']['output']>;
  projectId?: Scalars['Uuid']['output'] | Maybe<Scalars['Uuid']['output']>;
}

export default function useEquipmentActivityProjectDetails(
  filterId: string,
  filterType: ActivityReportType,
  timeRange: ITimeRange<DateTime>
) {
  const { getAll } = useApolloPaging();
  const client = useApolloClient();
  const canViewCost = useHasCostPermission();
  const [data, setData] = useState<IEquipmentProjectActivityTableRowInfo[]>([]);
  const remainingItemRef = useRef<IEquipmentProjectActivityTableRowInfo>();
  const { sorted, onSort, sortedBy, sortDirection, sortIsDirty } = useTableSorting(
    data,
    'equipment',
    SortDirection.ASCENDING,
    getSortField
  );

  function getIdFilter() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return activityIdFilter(filterId, ConditionNullableFieldType.MemberId, 'memberIds');
      case ActivityReportType.BY_COST_CODE:
        return activityIdFilter(filterId, ConditionNullableFieldType.CostCodeId, 'costCodeIds');
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function hasTimeIdFilter() {
    const idArray = filterId ? [filterId] : undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return {
          memberId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_COST_CODE:
        return {
          costCodeId: graphQLContainsIdOrEmptyItemId(idArray),
        };
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return {};
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_MEMBER_PROJECT_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_COSTCODE_TIME_ONLY_METRIC_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? SIMPLE_EQUIPMENT_WITH_PROJECT_METRIC_QUERY
          : SIMPLE_EQUIPMENT_WITH_PROJECT_TIME_ONLY_METRIC_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getProjectsQuery() {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_MEMBER_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_COST_CODE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_COST_CODE_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return canViewCost
          ? PROJECT_WITH_TIME_AND_EQUIPMENT_METRIC_AND_SUB_CHECK_QUERY
          : PROJECT_WITH_TIME_AND_EQUIPMENT_TIME_ONLY_METRIC_AND_SUB_CHECK_QUERY;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  }

  function getSortField(item: IEquipmentProjectActivityTableRowInfo, key: keyof IEquipmentProjectActivityTableRowInfo) {
    if (key === 'equipment') {
      return getEquipmentDisplay(item.equipment);
    } else {
      return item[key];
    }
  }

  async function getProjects(projectIds: string[]) {
    if (isEmpty(projectIds)) {
      return [];
    }

    return await getAll<Project & ICursorable>('projects', {
      query: PROJECT_ANCESTORS_QUERY,
      variables: {
        first: 100,
        filter: {
          id: { contains: projectIds },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function loadData() {
    const equipment = await getAll<Equipment & ICursorable>('equipment', {
      query: getQuery(),
      variables: {
        first: 100,
        filter: {
          equipmentWithTime: {
            startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: false,
            ...hasTimeIdFilter(),
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        ...getIdFilter(),
      },
      fetchPolicy: 'network-only',
    });

    const projectLookup = await getProjectLookup(equipment);
    const tableRows = mapNotNull(equipment, (equipment) => createTableRowInfo(projectLookup, equipment));

    const remainingData = await createNoEquipmentRow(tableRows);

    if (!isNil(remainingData)) {
      remainingItemRef.current = remainingData;
    } else {
      remainingItemRef.current = undefined;
    }

    setData(tableRows);
  }

  async function getProjectLookup(data: Equipment[]) {
    const metrics = data.flatMap((e) => laborMetrics(e));
    const projectIds = mapNotNil(metrics, (item) => item.projectId);
    const projects = await getProjects(uniq(projectIds));

    return keyBy(projects, (project) => project.id);
  }

  const createTableRowInfo = (
    projectLookup: Dictionary<Project>,
    equipment: Equipment
  ): IEquipmentProjectActivityTableRowInfo | null => {
    const metrics = laborMetrics(equipment);

    let noProjectRow: IEquipmentProjectActivityTableRowInfo | undefined = undefined;
    if (metrics.some((metric) => isNil(metric.projectId))) {
      const noEquipment = first(metrics.filter((metric) => isNil(metric.projectId))) ?? emptyMetricRow(equipment.id);
      const metricTotal = getGeneratedMetricLaborTotals(noEquipment, true);

      noProjectRow = {
        id: equipment.id + remainingDataItemId,
        projectId: remainingDataItemId,
        project: null,
        equipment,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    }

    const unsortedProjectRows = mapNotNull(metrics, (metric) => {
      const metricTotal = getGeneratedMetricLaborTotals(metric, true);
      if ((metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) || isNil(metric.projectId)) {
        return null;
      }

      return {
        id: equipment.id + metric.projectId,
        projectId: metric.projectId,
        project: projectLookup[metric.projectId] ?? null,
        equipment,
        ...activityReportUtils.metricToRowInfo(metricTotal),
        equipmentCost: metricTotal.equipmentCost,
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: metricTotal.equipmentCost + metricTotal.totalCost,
      };
    });

    const projectRows = sortByIgnoreCase(unsortedProjectRows, (row) => getFormattedPathFromProject(row.project, true));

    if (isEmpty(projectRows) && noProjectRow === undefined) {
      return null;
    } else {
      const equipmentTotalMetrics = aggregateActivityRows(
        noProjectRow === undefined ? projectRows : [...projectRows, noProjectRow]
      );

      const detailRows = noProjectRow === undefined ? projectRows : [...projectRows, noProjectRow];

      return {
        ...equipmentTotalMetrics,
        id: equipment.id,
        projectId: null,
        project: null,
        equipment,
        detailRows,
        equipmentCost: sumBy(detailRows, (row) => row.equipmentCost),
        equipmentRate: getCostHistoryForRange(equipment.costHistory ?? [], timeRange)?.operatorCostRate ?? 0,
        equipmentTotalCost: sumBy(detailRows, (row) => row.equipmentCost) + equipmentTotalMetrics.totalCost,
      };
    }
  };

  const createNoEquipmentRow = async (
    tableRows: IEquipmentProjectActivityTableRowInfo[]
  ): Promise<IEquipmentProjectActivityTableRowInfo | null> => {
    const totalData = await getActivityItemTotal(client, filterType, filterId, timeRange, canViewCost);

    const remainingData = calculateRemainingActivityData(tableRows, totalData);

    if (remainingData.totalHours <= 0) {
      return null; // there is not a No Cost Code row
    }

    let metricFilter = undefined;
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        if (filterId === remainingDataItemId) {
          metricFilter = combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
            metricNullFilter(ConditionNullableFieldType.MemberId, ConditionOperationNullType.IsNull),
          ]);
        } else {
          metricFilter = combineMetricFilters([
            metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
            metricConditionFilter(ConditionFieldType.MemberId, ConditionOperationType.Equal, filterId),
          ]);
        }
        break;
      case ActivityReportType.BY_COST_CODE:
        metricFilter = combineMetricFilters([
          metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull),
          metricConditionFilter(ConditionFieldType.CostCodeId, ConditionOperationType.Equal, filterId),
        ]);
        break;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        metricFilter = metricNullFilter(ConditionNullableFieldType.EquipmentId, ConditionOperationNullType.IsNull);
        break;
    }
    const projects = await getAll<Project & ICursorable>('projectsWithTime', {
      query: getProjectsQuery(),
      variables: {
        first: 100,
        startTime: timeRange.startTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        endTime: timeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
        includeOpenEntry: false,
        includeAncestors: true,
        equipmentId: { isNull: true },
        ...hasTimeIdFilter(),
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        metricFilter: metricFilter,
      },
      fetchPolicy: 'network-only',
    });

    const unsortedProjectRows: IEquipmentProjectActivityTableRowInfo[] = projects.flatMap((project) => {
      const metrics = projectLaborMetrics(project);

      return mapNotNull(metrics, (metric) => {
        const metricTotal = getGeneratedMetricLaborTotals(metric, true);
        if (metricTotal.totalSeconds === 0 && metricTotal.totalCost === 0) {
          return null;
        }

        return {
          id: remainingDataItemId + project.id,
          projectId: project.id,
          project: project,
          equipment: null,
          ...activityReportUtils.metricToRowInfo(metricTotal),
          equipmentCost: 0,
          equipmentRate: 0,
          equipmentTotalCost: metricTotal.totalCost,
        };
      });
    });

    const projectRows = sortByIgnoreCase(unsortedProjectRows, (row) => getFormattedPathFromProject(row.project, true));

    const remainingProjectData = calculateRemainingActivityData(
      projectRows,
      activityReportUtils.rowInfoToMetric(remainingData)
    );

    if (remainingProjectData.totalHours > 0) {
      // add the no cost code item under no project details
      projectRows.push({
        ...remainingProjectData,
        id: remainingDataItemId + remainingDataItemId,
        project: null,
        equipment: null,
        projectId: remainingDataItemId,
        equipmentCost: 0,
        equipmentRate: 0,
        equipmentTotalCost: remainingProjectData.totalCost,
      });
    }

    return {
      projectId: null,
      project: null,
      equipment: null,
      ...remainingData,
      id: remainingDataItemId,
      detailRows: projectRows,
      equipmentCost: 0,
      equipmentRate: 0,
      equipmentTotalCost: sumBy(projectRows, (row) => row.equipmentCost) + remainingData.totalCost,
    };
  };

  const emptyMetricRow = (equipmentId: string): EquipmentProjectMetricRow => {
    return {
      equipmentId,
      start: '',
      end: '',
    };
  };

  const laborMetrics = (equipment: Equipment): EquipmentProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return equipment.equipmentMemberProjectLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return equipment.equipmentProjectCostCodeLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return equipment.equipmentProjectLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  const projectLaborMetrics = (project: Project): EquipmentProjectMetricRow[] => {
    switch (filterType) {
      case ActivityReportType.BY_EMPLOYEE:
        return project.projectMemberEquipmentLaborMetrics;
      case ActivityReportType.BY_COST_CODE:
        return project.projectCostCodeEquipmentLaborMetrics;
      case ActivityReportType.BY_DAY:
      case ActivityReportType.BY_DATE_RANGE:
        return project.projectEquipmentLaborMetrics;
      default:
        throw Error('Type of ' + filterType + ' is not supported');
    }
  };

  return {
    loadData,
    sortedData: sorted,
    onSort,
    sortedBy,
    sortDirection,
    sortIsDirty,
    remainingData: remainingItemRef.current,
  };
}
