import { SAFETY_SIGNATURES_QUERY } from 'apollo/queries/safety-signature-query';
import { TIME_ENTRIES_FOR_MEMBER } from 'apollo/queries/time-entry-queries';
import { useApolloPaging } from 'hooks';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import ISafetySignature from 'types/SafetySignature';
import ITimeEntry from 'types/TimeEntry';
import ITimeRange from 'types/TimeRange';
import { dateTimeFromISOWithoutZone, getDateTimesBetween } from 'utils/dateUtils';
import { logError } from 'utils/testUtils';

export interface SignOffReportItem {
  date: DateTime;
  signatures?: ISafetySignature[];
}

export function useDailySignOffReportTableData(timeRange: ITimeRange<DateTime>, memberId: string) {
  const { getAll } = useApolloPaging();
  const [hasDataLoaded, setDataLoaded] = useState<boolean>(false);
  const [data, setData] = useState<SignOffReportItem[]>([]);
  const localizedTimeRange = useMemo<ITimeRange<DateTime>>(() => {
    return {
      startTime: timeRange.startTime.toUTC(0, { keepLocalTime: true }),
      endTime: timeRange.endTime.toUTC(0, { keepLocalTime: true }),
    };
  }, [timeRange]);

  useEffect(() => {
    refreshData();
  }, [localizedTimeRange]);

  async function refreshData() {
    return getData()
      .then((results) => {
        setData(results);
        return results;
      })
      .catch(logError)
      .finally(() => setDataLoaded(true));
  }

  async function getData(): Promise<SignOffReportItem[]> {
    return await Promise.all([getTimeEntries(), getSafetySignatures()])
      .then((queryResults) => {
        const [allTimeEntries, allSignOffEntries] = queryResults;

        const signOffByDay = _.groupBy(allSignOffEntries, (signOff) => {
          return dateTimeFromISOWithoutZone(signOff.startTime).startOf('day').toUTC().toISO();
        });

        const entryDates = allTimeEntries.flatMap((entry) => {
          return getDateTimesBetween(
            dateTimeFromISOWithoutZone(entry.startTime).startOf('day'),
            dateTimeFromISOWithoutZone(entry.endTime!).endOf('day')
          );
        });
        const dates = _.uniqBy(entryDates, (date) => date.toSeconds())
          .filter((e: DateTime) => {
            return e >= localizedTimeRange.startTime && e <= localizedTimeRange.endTime;
          })
          .sort();

        return dates.map((date) => {
          return {
            date: date.startOf('day'),
            signatures: signOffByDay[date.startOf('day').toISO()],
          };
        });
      })
      .catch(logError);
  }

  async function getTimeEntries(): Promise<ITimeEntry[]> {
    return getAll<ITimeEntry & ICursorable>('timeEntries', {
      query: TIME_ENTRIES_FOR_MEMBER,
      variables: {
        first: 500,
        filter: {
          startTime: {
            lessThanOrEqual: localizedTimeRange.endTime.toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          endTime: {
            greaterThanOrEqual: localizedTimeRange.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
          },
          member: {
            permissions: {
              permissions: MemberPermission.TIME_EVENTS,
              operationType: 'and',
              includeSelf: true,
            },
          },
          memberId: { equal: memberId },
          deletedOn: { isNull: true },
        },
      },
      fetchPolicy: 'network-only',
    });
  }

  async function getSafetySignatures(): Promise<ISafetySignature[]> {
    return getAll<ISafetySignature & ICursorable>('safetySignatures', {
      query: SAFETY_SIGNATURES_QUERY,
      variables: {
        first: 500,
        filter: {
          startTime: {
            lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          endTime: {
            greaterThanOrEqual: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
          },
          memberId: { equal: memberId },
          deletedOn: { isNull: true },
          member: {
            permissions: { permissions: MemberPermission.TIME_EVENTS, operationType: 'and', includeSelf: true },
          },
        },
        sort: [{ startTime: 'asc' }, { createdOn: 'asc' }],
      },
      fetchPolicy: 'network-only',
    });
  }
  return { data, hasDataLoaded, refreshData };
}
