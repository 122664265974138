import { useQuery } from '@tanstack/react-query';
import useMemberClockState from 'hooks/models/member-clock-state/useMemberClockState/useMemberClockState';
import useReactQueryBaseKey from 'hooks/react-query/useReactQueryBaseKey/useReactQueryBaseKey';
import { clampMin } from 'utils/numberUtils';

export default function useDashboardClockStatus() {
  const getMemberClockState = useMemberClockState();
  const baseKeys = useReactQueryBaseKey();
  const { data, isLoading, error } = useQuery({
    queryKey: [...baseKeys, 'dashboard-clock-status'],
    queryFn: getMemberClockState,
  });

  const onBreak = data?.memberClockState?.onBreak ?? 0;
  const clockedIn = clampMin((data?.memberClockState?.clockedIn ?? 0) - onBreak, 0);

  return {
    data: data?.memberClockState,
    clockedIn,
    onBreak,
    clockedOut: data?.memberClockState?.clockedOut ?? 0,
    isLoading,
    error,
  };
}
