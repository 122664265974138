import { IDatePickerPayload } from '@busybusy/webapp-react-ui';
import { t } from 'i18next';
import { DateTime, Duration } from 'luxon';
import DateTimeFormat from './constants/dateTimeFormat';
import { convertDurationToHoursDecimal, secondsToHmmString } from './dateUtils';
import { getDurationFromSeconds } from './durationUtils';

export type FormattedHoursPayload =
  | {
      type: 'TIME';
      seconds: number;
    }
  | {
      type: 'DECIMAL';
      places?: number;
      seconds: number;
    };

export function formatTime(payload: FormattedHoursPayload) {
  return payload.type === 'TIME'
    ? secondsToHmmString(payload.seconds)
    : convertDurationToHoursDecimal(Duration.fromMillis(payload.seconds * 1000), payload.places ?? 2);
}

export function convertDateTimeToDatePickerPayload(dateTime: DateTime): IDatePickerPayload {
  return {
    value: dateTime.toJSDate(),
    inputValue: dateTime.toFormat(DateTimeFormat.datePickerPayload),
  };
}

export function getMinsString(duration: Duration, label: string = 'min') {
  const isPlural = duration.minutes !== 1;
  const labelString = t(`${label}${isPlural ? 's' : ''}`);
  return `${duration.minutes} ${labelString}`;
}

export function getHoursString(duration: Duration, label: string = 'hr') {
  const isPlural = duration.hours !== 1;
  const labelString = t(`${label}${isPlural ? 's' : ''}`);
  return `${duration.hours} ${labelString}`;
}

export function getHourMinuteFormattedString(seconds: number) {
  const duration = getDurationFromSeconds(seconds).shiftTo('hours', 'minutes');
  return `${getHoursString(duration)} ${getMinsString(duration)}`;
}
