import { t } from 'utils/localize';

export default function v_percentages_only(
  value: string,
  args: { decimalPlaces: number; maxPercent?: number; message?: string }
) {
  return {
    message: args.message || t('Must only contain percentages'),
    validate: () => {
      if (!value) {
        return true;
      }
      const newValue = Number(parseFloat(value).toFixed(args.decimalPlaces));
      const max = args.maxPercent ? args.maxPercent : 100;
      if (isNaN(newValue) || newValue < 0 || newValue > max) {
        return false;
      }
      return true;
    },
  };
}
