import { ClassName, Dialog } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { MainHeader, Panel } from 'components';
import { t } from 'i18next';
import ReviewSafetyIncidentForm from '../ReviewSafetyIncidentForm/ReviewSafetyIncidentForm';
import { ISafetyIncidentsOshaRowItem } from '../SafetyIncidentsOsha300Table/SafetyIncidentsOsha300Table';
import { ISafetyIncidentsReportedRowItem } from '../SafetyIncidentsReportedTable/SafetyIncidentsReportedTable';

interface IReviewSafetyIncidentDialogProps {
  className?: ClassName;
  isOpen: boolean;
  isReview: boolean;
  onClose?: () => void;
  onSubmitReview: () => void;
  incident: ISafetyIncidentsReportedRowItem | ISafetyIncidentsOshaRowItem;
}

const ReviewSafetyIncidentDialog = (props: IReviewSafetyIncidentDialogProps) => {
  const { className, isOpen, isReview, onClose, onSubmitReview, incident } = props;

  const classes = classNames('review-safety-incident-dialog', className);

  return (
    <Dialog hasDismiss={false} className={classes} size="full" isOpen={isOpen} onClose={onClose}>
      <Panel>
        <MainHeader onClick={onClose} title={isReview && t('Review Report')} rightContent={null} />
        <ReviewSafetyIncidentForm incident={incident} onSubmitReview={onSubmitReview} isReview={isReview} />
      </Panel>
    </Dialog>
  );
};

export default ReviewSafetyIncidentDialog;
