import { Theme } from '@busybusy/webapp-react-ui';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import { t } from 'utils/localize';

export type DashboardTimeFrame = 'payPeriod' | 'monthly' | 'weekly' | 'pastSeven' | 'pastFourteen' | 'daily';
export type DashboardSettingKey = 'clockStatus' | DashboardSettingWithTimeKey;

export type DashboardSettingWithTimeKey =
  | 'laborHours'
  | 'laborCosts'
  | 'overtimeHours'
  | 'overtimeCosts'
  | 'doubleTimeHours'
  | 'doubleTimeCosts'
  | 'dailySignIn'
  | 'dailySignatureAlerts'
  | 'hourMeter'
  | 'equipmentHours'
  | 'equipmentCosts'
  | 'photosAndNotes'
  | 'dailyReports'
  | 'budgets'
  | 'photoVerification'
  | 'mostActiveEmployees'
  | 'mostActiveProjects'
  | 'mostActiveCostCodes'
  | 'mostActiveEquipment'
  | 'progressEntriesReport'
  | 'timeOff'
  | 'timeLogs';

export const isDashboardSettingsKey = (key: string) => {
  return (
    key === 'laborHours' ||
    key === 'laborCosts' ||
    key === 'overtimeHours' ||
    key === 'overtimeCosts' ||
    key === 'doubleTimeHours' ||
    key === 'doubleTimeCosts' ||
    key === 'dailySignIn' ||
    key === 'dailySignatureAlerts' ||
    key === 'hourMeter' ||
    key === 'equipmentHours' ||
    key === 'equipmentCosts' ||
    key === 'photosAndNotes' ||
    key === 'dailyReports' ||
    key === 'budgets' ||
    key === 'photoVerification' ||
    key === 'mostActiveEmployees' ||
    key === 'mostActiveProjects' ||
    key === 'mostActiveCostCodes' ||
    key === 'mostActiveEquipment' ||
    key === 'progressEntriesReport' ||
    key === 'timeOff' ||
    key === 'timeLogs' ||
    key === 'clockStatus'
  );
};

export const DashboardSettingLocalStorageKey = 'dashboard-settings';

export interface IDashboardSettingsPayload extends IDashboardSettingsItem {
  options?: {
    time?: DashboardTimeFrame;
    injury?: boolean;
    covid?: boolean;
    inaccurateTime?: boolean;
    breakHours?: boolean;
    customQuestions?: boolean;
    missedSignOffs?: boolean;
    timeOffTimeFrame?: TimeOffRibbonTimeFrame;
    hideProcessedInjuries?: boolean;
    hideResolvedInaccurateTime?: boolean;
    hideResolvedBreakIssues?: boolean;
    hideResolvedCustomQuestions?: boolean;
    injuriesPosition?: number;
    inaccurateTimePosition?: number;
    breakIssuesPosition?: number;
    customQuestionsPosition?: number;
    missedSignOffsPosition?: number;
  };
}

export type DashboardSettings = Record<DashboardSettingKey, IDashboardSettingsPayload>;

export const defaultDashboardSettings: DashboardSettings = {
  clockStatus: {
    key: 'clockStatus',
    title: t('Clock Status Summary'),
    visible: true,
    position: 0,
    customizable: false,
  },
  laborHours: {
    key: 'laborHours',
    title: t('Total Hours'),
    visible: true,
    position: 1,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  laborCosts: {
    key: 'laborCosts',
    title: t('Total Cost'),
    visible: true,
    position: 2,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  overtimeHours: {
    key: 'overtimeHours',
    title: t('Overtime Hours'),
    visible: true,
    position: 3,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  overtimeCosts: {
    key: 'overtimeCosts',
    title: t('Overtime Cost'),
    visible: true,
    position: 4,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  doubleTimeHours: {
    key: 'doubleTimeHours',
    title: t('Double Time Hours'),
    visible: false,
    position: 5,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  doubleTimeCosts: {
    key: 'doubleTimeCosts',
    title: t('Double Time Cost'),
    visible: false,
    position: 6,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  dailySignIn: {
    key: 'dailySignIn',
    title: t('Daily Sign In'),
    visible: true,
    position: 7,
    customizable: true,
    options: {
      time: 'pastSeven',
    },
  },
  dailySignatureAlerts: {
    key: 'dailySignatureAlerts',
    title: t('Daily Sign Off'),
    visible: true,
    position: 8,
    customizable: true,
    options: {
      time: 'pastSeven',
      injury: true,
      inaccurateTime: true,
      breakHours: true,
      customQuestions: true,
      missedSignOffs: true,
      hideProcessedInjuries: false,
      hideResolvedBreakIssues: true,
      hideResolvedInaccurateTime: true,
      hideResolvedCustomQuestions: true,
      injuriesPosition: 0,
      inaccurateTimePosition: 1,
      breakIssuesPosition: 2,
      customQuestionsPosition: 3,
      missedSignOffsPosition: 4,
    },
  },
  hourMeter: {
    key: 'hourMeter',
    title: t('Machine Hours'),
    visible: true,
    position: 9,
    customizable: true,
    options: {
      time: 'monthly',
    },
  },
  equipmentHours: {
    key: 'equipmentHours',
    title: t('Equipment Hours'),
    visible: true,
    position: 10,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  equipmentCosts: {
    key: 'equipmentCosts',
    title: t('Equipment Cost'),
    visible: true,
    position: 11,
    customizable: true,
    options: {
      time: 'payPeriod',
    },
  },
  photosAndNotes: {
    key: 'photosAndNotes',
    title: t('Photos & Notes (Uploads)'),
    visible: true,
    position: 12,
    customizable: true,
    options: {
      time: 'monthly',
    },
  },
  dailyReports: {
    key: 'dailyReports',
    title: t('Field Reports'),
    visible: true,
    position: 13,
    customizable: false,
  },
  progressEntriesReport: {
    key: 'progressEntriesReport',
    title: t('Progress Submissions'),
    visible: true,
    position: 14,
    customizable: true,
    options: {
      time: 'monthly',
    },
  },
  budgets: {
    key: 'budgets',
    title: t('Budgets'),
    visible: true,
    position: 15,
    customizable: true,
    options: {
      time: 'pastSeven',
    },
  },
  photoVerification: {
    key: 'photoVerification',
    title: t('Photo Verification'),
    visible: true,
    position: 16,
    customizable: true,
    options: {
      time: 'monthly',
    },
  },
  mostActiveEmployees: {
    key: 'mostActiveEmployees',
    title: t('Most Active Employees'),
    visible: true,
    position: 17,
    customizable: true,
    options: {
      time: 'pastFourteen',
    },
  },
  mostActiveProjects: {
    key: 'mostActiveProjects',
    title: t('Most Active Projects'),
    visible: true,
    position: 18,
    customizable: true,
    options: {
      time: 'pastFourteen',
    },
  },
  mostActiveCostCodes: {
    key: 'mostActiveCostCodes',
    title: t('Most Active Cost Codes'),
    visible: true,
    position: 19,
    customizable: true,
    options: {
      time: 'pastFourteen',
    },
  },
  mostActiveEquipment: {
    key: 'mostActiveEquipment',
    title: t('Most Active Equipment'),
    visible: true,
    position: 20,
    customizable: true,
    options: {
      time: 'pastFourteen',
    },
  },
  timeOff: {
    key: 'timeOff',
    title: t('Time Off'),
    visible: true,
    position: 21,
    customizable: true,
    options: {
      timeOffTimeFrame: 'Upcoming',
    },
  },
  timeLogs: {
    key: 'timeLogs',
    title: t('Recent Activity'),
    visible: true,
    position: 22,
    customizable: false,
  },
};

export type TimeOffRibbonTimeFrame = 'Upcoming' | '7 days' | '30 days';
export type DashboardTheme = Theme.DARK | Theme.LIGHT;
