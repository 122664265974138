import { IDashboardListRow } from 'components/foundation/dashboard/dashboard-list/DashboardList/DashboardList';
import {
  COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY,
  COST_CODES_WITH_TIME_METRICS_QUERY,
} from 'containers/activity-reports/queries/cost-code-activity-queries';
import { useDashboardTimeRange } from 'containers/dashboard/hooks';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { convertTotalsToDashboardListRow } from 'containers/dashboard/util/utils';
import { useApolloPaging, useAsync } from 'hooks';
import useCostCodeEnabled from 'hooks/settings/useCostCodeEnabled/useCostCodeEnabled';
import { first, isNil } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';
import { mapNotNull } from 'utils/collectionUtils';
import { getMetricLaborTotals } from 'utils/jitMetricUtils';
import { getCostCodeDisplay } from 'utils/stringUtils';
import { LaborMetricsInterval } from '__generated__/graphql';

export default function useActiveCostCodeDashboard(
  timeFrame: DashboardTimeFrame,
  memberId?: string | null,
  projectId?: string | null,
  equipmentId?: string | null
) {
  const costCodesEnabled = useCostCodeEnabled();
  const timeRange = useDashboardTimeRange(timeFrame);
  const { getAll } = useApolloPaging();

  const getData = useCallback(() => {
    // TODO this will break with multiple ids passed.
    let query = COST_CODES_WITH_TIME_METRICS_QUERY;
    if (!isNil(memberId)) {
      query = COST_CODES_WITH_MEMBER_TIME_ONLY_METRICS_QUERY;
    } else if (!isNil(projectId)) {
      query = COST_CODES_WITH_PROJECT_TIME_ONLY_METRICS_QUERY;
    } else if (!isNil(equipmentId)) {
      query = COST_CODES_WITH_EQUIPMENT_TIME_ONLY_METRICS_QUERY;
    }

    return getAll<ICostCode & ICursorable>('costCodes', {
      query: query,
      fetchPolicy: 'network-only',
      variables: {
        first: 200,
        sort: [{ costCode: 'asc' }, { title: 'asc' }],
        filter: {
          archivedOn: { isNull: true },
          costCodesWithTime: {
            startTime: timeRange.startTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            endTime: timeRange.endTime.toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
            includeOpenEntry: false,
            memberId: !isNil(memberId) ? { equal: memberId } : undefined,
            projectIdWithDescendants: !isNil(projectId) ? { equal: projectId } : undefined,
            equipmentId: !isNil(equipmentId) ? { equal: equipmentId } : undefined,
          },
        },
        metricsInterval: LaborMetricsInterval.Custom,
        metricsStartDate: timeRange.startTime.toISODate(),
        metricsEndDate: timeRange.endTime.toISODate(),
        memberIds: !isNil(memberId) ? [memberId] : undefined,
        projectIds: !isNil(projectId) ? [projectId] : undefined,
        equipmentIds: !isNil(equipmentId) ? [equipmentId] : undefined,
      },
    });
  }, [memberId, projectId, equipmentId, getAll, timeRange.startTime, timeRange.endTime]);

  const { execute, loading, error, data } = useAsync(getData, false);
  const rowData: IDashboardListRow[] = useMemo(() => {
    const titleToTotal = mapNotNull(data ?? [], (costCode) => {
      let total = 0;

      if (!isNil(memberId)) {
        total = getMetricLaborTotals(first(costCode.costCodeMemberLaborMetrics))?.totalSeconds ?? 0;
      } else if (!isNil(projectId)) {
        total = getMetricLaborTotals(first(costCode.costCodeProjectLaborMetrics))?.totalSeconds ?? 0;
      } else if (!isNil(equipmentId)) {
        total = getMetricLaborTotals(first(costCode.costCodeEquipmentLaborMetrics))?.totalSeconds ?? 0;
      } else {
        total = getMetricLaborTotals(first(costCode.costCodeLaborMetrics))?.totalSeconds ?? 0;
      }

      return total > 0
        ? {
            id: costCode.id,
            title: getCostCodeDisplay(costCode),
            total,
          }
        : null;
    });

    return convertTotalsToDashboardListRow(titleToTotal);
  }, [data]);

  useEffect(() => {
    if (costCodesEnabled) {
      execute();
    }
  }, [costCodesEnabled, execute, timeFrame, memberId, projectId, equipmentId]);

  return {
    data: rowData,
    loading,
    error,
    execute,
  };
}
