import { Maybe, Member, WageHistory } from '__generated__/graphql';
import { EmployeeNameFormat } from 'hooks/settings/local-settings/useEmployeeNameFormat';
import _ from 'lodash';
import random from 'lodash/random';
import { DateTime } from 'luxon';
import IMember from 'types/Member';
import TimeLocationRequired from 'types/enum/TimeLocationRequired';
import { Nullable } from 'types/util/Nullable';
import { t } from 'utils/localize';
import { getWageHistoryForTime, isWageSalary } from './wageUtils';

export function fullName(member: { firstName?: Maybe<string>; lastName?: Maybe<string> }) {
  let name = '';
  if (member.firstName) {
    name += member.firstName + ' ';
  }
  if (member.lastName) {
    name += member.lastName;
  }
  if (_.isEmpty(name)) {
    name = t('Loading...');
  }
  return name;
}

type MemberTitle = {
  id: string;
  position?: {
    title: string;
  };
};

export function getMemberTitle(member?: MemberTitle, ownerId: string | null = null) {
  let title = '';

  if (member && member.position) {
    if (ownerId && ownerId === member.id) {
      title = `${t('Admin')} (${t('Owner')})`;
    } else {
      title = member.position.title;
    }
  }
  return title;
}

// Return the formated name of the locationRequired property
export function parseLocationRequired(value: string) {
  if (value === 'AUTO') {
    return t('Not required');
  } else if (value === 'YES') {
    return t('Required');
  } else {
    return t('Off');
  }
}

export const parseLocationFlagging = (value: boolean) => {
  return value === false ? t('Yes') : t('No');
};

export function getTimeLocationRequiredString(timeLocationRequired: TimeLocationRequired): string | null {
  switch (timeLocationRequired) {
    case TimeLocationRequired.GPS_REQUIRED:
      return t('Required');
    case TimeLocationRequired.GPS_OFF:
      return t('Off');
    case TimeLocationRequired.GPS_NOT_REQUIRED:
      return t('Not Required');
    default:
      return null;
  }
}

/**
 * Because we can't get the open time entry on the member from GraphQL, but we can request it in the time entries list,
 * this will move the time entry from the list into the openTimeEntry property.
 */
export function populateOpenTimeEntryField(members: IMember[]) {
  return members.map((member) => {
    const newMember: IMember = {
      ...member,
      openTimeEntry: _.first(member.timeEntries),
    };

    return newMember;
  });
}

export function generateUsername(firstName: string, lastName: string) {
  return `${_.take(firstName, 4).join('')}${_.take(lastName, 4).join('')}${random(1000, 9999)}`;
}

export function generatePassword(organizationName: string) {
  const digitsAdded = organizationName.length < 6 ? 4 + (6 - organizationName.length) : 4;
  return `${_.take(organizationName, 6).join('')}${random(Math.pow(10, digitsAdded), Math.pow(10, digitsAdded - 1))}`;
}

export function getMemberInitials(member: Partial<IMember>) {
  let initials = '';
  if (member.firstName) {
    initials += member.firstName.charAt(0);
  }

  if (member.lastName) {
    initials += member.lastName.charAt(0);
  }

  return initials;
}
export interface IIsMemberSalariedRequirements {
  wageHistories?: Maybe<Array<Pick<WageHistory, 'changeDate' | 'wageRate' | 'deletedOn' | 'updatedOn'>>>;
}

export function isMemberSalaried(member: IIsMemberSalariedRequirements, dateTime: DateTime) {
  const currentWageHistory = getWageHistoryForTime(member.wageHistories ?? [], dateTime);
  return currentWageHistory ? isWageSalary(currentWageHistory.wageRate) : false;
}

export function isMemberCurrentlySalaried(member: IIsMemberSalariedRequirements) {
  return isMemberSalaried(member, DateTime.local());
}

export function getMembersVerifiedEmail(member: Pick<Member, 'pendingEmail' | 'certifiedEmail'>) {
  return member.pendingEmail === null && member.certifiedEmail ? member.certifiedEmail : null;
}

export function sortMembersByFormat<T extends { firstName?: Nullable<string>; lastName?: Nullable<string> }>(
  members: T[],
  format: EmployeeNameFormat,
  sortingOrder: 'asc' | 'desc'
): T[] {
  const sortedMembers = [...members];

  sortedMembers.sort((a, b) => {
    let nameA = '';
    let nameB = '';

    if (format === 'FIRST' || format === 'FIRST_LAST') {
      nameA = a.firstName ?? '';
      nameB = b.firstName ?? '';
    }

    if (format === 'LAST' || format === 'LAST_FIRST') {
      nameA = a.lastName ?? '';
      nameB = b.lastName ?? '';
    }

    if (sortingOrder === 'asc') {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  return sortedMembers;
}
