import { Optional } from 'types/util/Optional';
import { highlight } from 'utils/stringUtils';

export interface ISearchTextProps {
  text: string;
  search: Optional<string>;
}

function SearchText({ text, search }: ISearchTextProps) {
  if (search) {
    return <>{highlight(text, search)}</>;
  }
  return <>{text}</>;
}

export default SearchText;
