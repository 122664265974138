import useGustoIsAuthenticated from 'containers/payroll/gusto/hooks/useGustoIsAuthenticated/useGustoIsAuthenticated';
import useGustoHasActiveUserSignedTos from '../../terms-of-service/useGustoHasActiveUserSignedTos/useGustoHasActiveUserSignedTos';
import useGustoPaidEnabled from '../useGustoPaidEnabled/useGustoPaidEnabled';

export default function useGustoIsFullySetup() {
  const isGustoPaidEnabled = useGustoPaidEnabled();
  const isGustoCompanyCreated = useGustoIsAuthenticated();
  const hasSignedGustoTos = useGustoHasActiveUserSignedTos();
  return isGustoPaidEnabled && isGustoCompanyCreated && !!hasSignedGustoTos;
}
