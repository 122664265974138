import { useApolloClient } from '@apollo/client';
import { ClassName, IFormRender, IFormValidation, Row } from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import { IconButton } from 'components';
import CostCodePickerFormField from 'components/domain/cost-code/CostCodePickerFormField/CostCodePickerFormField';
import Typography from 'components/foundation/text/Typography/Typography';
import { useOrganization } from 'hooks';
import { useMemo } from 'react';
import * as React from 'react';
import { t } from 'utils/localize';
import { v_project_cost_code, v_require } from 'utils/validations';
import { MULTIPLE } from '../BulkEditEntryForm/BulkEditEntryForm';
import { BulkEditActions, BulkEditActionType, IUndoableClearableFieldState } from '../reducers/reducers';
import { FormInfo } from 'types/FormData';

export interface ICostCodeTrashRowProps<T extends FormInfo> {
  form: IFormRender<T>;
  bulkState: IUndoableClearableFieldState;
  dispatch: React.Dispatch<BulkEditActions>;
  projectDispatch: React.Dispatch<BulkEditActions>;
  projectState: IUndoableClearableFieldState;
  projectId: string | null | undefined;
  className?: ClassName;
}

function CostCodeTrashRow<T extends FormInfo>({
  form,
  bulkState,
  projectState,
  projectId,
  dispatch,
  projectDispatch,
}: ICostCodeTrashRowProps<T>) {
  const organization = useOrganization();
  const client = useApolloClient();

  const withMultipleConsiderationCostCodeValidations = useMemo(() => {
    const validations: Array<IFormValidation<string | null, any>> = [];
    if (bulkState.current === 'MULTIPLE') {
      return [];
    }

    if (organization.requireCostCode) {
      validations.push({ validation: v_require });
    }

    if (
      organization.useProjectCostCodeScoping &&
      projectState.current !== projectState.original &&
      bulkState.original !== bulkState.current
    ) {
      validations.push({
        validation: v_project_cost_code,
        args: {
          projectField: 'project',
          client,
          allowNoCostCodeLink: !organization.requireCostCode,
        },
      });
    }
    return validations;
  }, [client, bulkState, organization.requireCostCode, organization.useProjectCostCodeScoping, projectState]);

  if (!organization.trackCostCode) {
    return null;
  }

  function clearField() {
    dispatch({ type: BulkEditActionType.CLEAR_CURRENT });
    if (organization.useProjectCostCodeScoping && projectState.current === 'MULTIPLE') {
      projectDispatch({ type: BulkEditActionType.CLEAR });
    }
  }

  function onChange(id: string | null) {
    dispatch({ type: BulkEditActionType.SET_CURRENT, payload: { value: id } });
  }

  function onUndo(event: React.MouseEvent) {
    event.stopPropagation();
    dispatch({ type: BulkEditActionType.UNDO_CURRENT });
  }

  function renderUndo() {
    return (
      <Typography className={'mx-2'} onClick={onUndo} color={'primary'} tag={'span'}>
        {t('Undo')}
      </Typography>
    );
  }

  function getCostCodePlaceholder() {
    return bulkState.original === MULTIPLE && bulkState.current === null
      ? organization.requireCostCode
        ? t('Select a cost code (required).')
        : t('Cost codes will be set to "No Cost Code".')
      : t('Cost Code');
  }

  return (
    <Row className="trash-row">
      <CostCodePickerFormField
        name="costCode"
        form={form}
        placeholder={getCostCodePlaceholder()}
        onSelect={onChange}
        projectId={projectId}
        closeButtonRender={bulkState.undoable ? renderUndo : undefined}
        dropDownButtonRender={bulkState.undoable ? renderUndo() : undefined}
        validations={withMultipleConsiderationCostCodeValidations}
      />
      {bulkState.clearable && <IconButton className="trash-icon" onClick={clearField} svg={DeleteIcon} />}
    </Row>
  );
}

export default CostCodeTrashRow;
