import { useApolloClient } from '@apollo/client';
import { MemberHasTimeOff } from '__generated__/graphql';
import { MEMBERS_WITH_TIME_DOCUMENTS_QUERY } from 'apollo/queries/member-queries';
import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { ITimeEntryDataTableData } from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import {
  filterByProjectIdsOrEmptyItemId,
  graphQLContainsIdOrEmptyItemId,
} from 'containers/activity-reports/hooks/ActivitySummaryQueryUtils';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { useToastOpen } from 'contexts/ToastContext';
import { useApolloPaging, useCostCode, useFirstRender, useLazyLoading } from 'hooks';
import useApolloMemberNameSort from 'hooks/models/member/useApolloMemberNameSort';
import useProject from 'hooks/models/project/useProject';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import useEmployeeNameFormatter from 'hooks/ui/useEmployeeNameFormatter';
import { Dictionary, groupBy, isEmpty, isNil, last, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IMember } from 'types';
import ICursorable from 'types/Cursorable';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import ITimeEntry from 'types/TimeEntry';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';
import { getApolloArchivedTimestampComparison } from 'utils/archivedUtils';
import { isNilOrEmpty, isNilOrEmptyOrOnlyHasRemainingItem } from 'utils/collectionUtils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import { logError } from 'utils/testUtils';
import { timeRangesAreEqual } from 'utils/timeRangeUtils';

export interface IMemberTimeEntryFilter {
  timeRange: ITimeRange<DateTime>;
  memberIds?: string[] | null;
  memberGroupId?: string | null;
  projectIds?: string[] | null;
  costCodeIds?: string[] | null;
  equipmentIds?: string[] | null;
  positionId?: string;
  reportType?: TimesheetReportType;
  includeOpen?: boolean;
  permissions: MemberPermission[];
  archivedStatus?: ArchivedStatus;
  projectIdWithDescendants?: boolean;
  costCodeGroupId?: string | null;
  projectGroupId?: string | null;
}

export default function useLazyMemberTimeData(scroller: HTMLElement | Document | null, filter: IMemberTimeEntryFilter) {
  const client = useApolloClient();
  const toast = useToastOpen();
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();
  const { getAll } = useApolloPaging();
  const getMemberNameSort = useApolloMemberNameSort();
  const { getProjects } = useProject();
  const { getCostCodes } = useCostCode();

  const getMemberVariables = useCallback(
    async (timeRange: ITimeRange<DateTime>, first?: number, cursor?: string | null) => {
      setIsCancelled(false);
      const variables: any = {
        first,
        filter: {
          permissions: {
            permissions: filter.permissions,
            operationType: OperationType.AND,
            includeSelf: true,
          },
          memberGroupId: filter.memberGroupId ? { equal: filter.memberGroupId } : undefined,
          positionId: filter.positionId ? { equal: filter.positionId } : undefined,
          id: !isNilOrEmpty(filter.memberIds) ? { contains: filter.memberIds } : undefined,
          archivedOn: getApolloArchivedTimestampComparison(filter.archivedStatus ?? 'unarchived'),
        },
        sort: getMemberNameSort('asc'),
        memberTimeDocumentFilter: {
          startTime: { equal: timeRange.startTime.toISO() },
          endTime: { equal: timeRange.endTime.toISO() },
          deletedOn: { isNull: true },
        },
        memberTimeDocumentSort: [{ submittedOn: 'desc' }],
      };

      // optimize report by only loading the members that will show up
      // no need to optimize if we are scoped to certain members
      if (isNilOrEmpty(filter.memberIds)) {
        if (
          filter.reportType === TimesheetReportType.TIME_OFF_BOTH ||
          filter.reportType === TimesheetReportType.TIME_OFF_PAID ||
          filter.reportType === TimesheetReportType.TIME_OFF_UNPAID
        ) {
          // we're only showing time off
          let paid = undefined;
          // only set paid if we're looking at just paid or just unpaid time off
          if (
            filter.reportType === TimesheetReportType.TIME_OFF_PAID ||
            filter.reportType === TimesheetReportType.TIME_OFF_UNPAID
          ) {
            paid = filter.reportType === TimesheetReportType.TIME_OFF_PAID;
          }

          variables.filter.timeOffs = {
            deletedOn: { isNull: true },
            dateStamp: {
              lessThanOrEqual: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
              greaterThanOrEqual: timeRange.startTime
                .toUTC()
                .toISO({ suppressMilliseconds: true, includeOffset: false }),
            },
            paid: paid !== undefined ? { equal: paid } : undefined,
          };
        } else if (filter.reportType === TimesheetReportType.ENTRY_AND_TIME_OFF_PAID) {
          // we're showing time entries and time off in the activity reports
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(filter.includeOpen) ? filter.includeOpen : false,
            paidTimeOff:
              isNilOrEmptyOrOnlyHasRemainingItem(filter.costCodeIds) &&
              isNilOrEmptyOrOnlyHasRemainingItem(filter.equipmentIds) &&
              isNilOrEmptyOrOnlyHasRemainingItem(filter.projectIds) &&
              !isNil(filter.projectGroupId) &&
              !isNil(filter.costCodeGroupId)
                ? true
                : null,
            costCodeId: graphQLContainsIdOrEmptyItemId(filter.costCodeIds),
            equipmentId: graphQLContainsIdOrEmptyItemId(filter.equipmentIds),
            ...filterByProjectIdsOrEmptyItemId(filter.projectIds, filter.projectIdWithDescendants),
          };
        } else if (filter.reportType === TimesheetReportType.ENTRY_AND_ALL_TIME_OFF) {
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(filter.includeOpen) ? filter.includeOpen : false,
            hasTimeOff: MemberHasTimeOff.All, // show both paid and unpaid time off
          };
        } else if (
          (!isNil(filter.includeOpen) || !filter.includeOpen) &&
          (!isNilOrEmpty(filter.projectIds) ||
            !isNilOrEmpty(filter.costCodeIds) ||
            !isNilOrEmpty(filter.equipmentIds) ||
            !isNil(filter.projectGroupId) ||
            !isNil(filter.costCodeGroupId) ||
            filter.reportType === TimesheetReportType.ENTRY)
        ) {
          // we're only showing time entries
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(filter.includeOpen) ? filter.includeOpen : false,
            equipmentId: graphQLContainsIdOrEmptyItemId(filter.equipmentIds),
            costCodeId: await hasTimeCostCodeFilter(),
            projectId: filter.projectIdWithDescendants !== true ? await hasTimeProjectFilter() : undefined,
            projectIdWithDescendants:
              filter.projectIdWithDescendants === true ? await hasTimeProjectFilter() : undefined,
          };
        } else if (isNil(filter.reportType)) {
          // we're showing time entries and time off
          variables.filter.hasTime = {
            startTime: timeRange.startTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            endTime: timeRange.endTime.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false }),
            includeOpenEntry: !isNil(filter.includeOpen) ? filter.includeOpen : false,
            paidTimeOff: true,
          };
        }
      }

      if (cursor) {
        variables.after = cursor;
      }

      return variables;
    },
    [
      filter.permissions,
      filter.memberGroupId,
      filter.positionId,
      filter.memberIds,
      filter.archivedStatus,
      filter.reportType,
      filter.includeOpen,
      filter.projectIds,
      filter.costCodeIds,
      filter.equipmentIds,
      filter.projectIdWithDescendants,
      getMemberNameSort,
    ]
  );

  const hasTimeProjectFilter = async () => {
    let projectIds: string[] = [];

    if (!isNil(filter.projectGroupId)) {
      const rootGroupProjects = await getProjects(filter.projectGroupId);
      projectIds.push(...rootGroupProjects.map((p) => p.id));
    }

    if (!isNilOrEmpty(filter.projectIds)) {
      projectIds = projectIds.concat(filter.projectIds ?? []);
    }

    if (!isEmpty(projectIds)) {
      if (projectIds.includes(remainingDataItemId)) {
        return { isNull: true };
      }

      return {
        contains: projectIds,
      };
    }

    return undefined;
  };

  const hasTimeCostCodeFilter = async () => {
    let costCodeIds: string[] = [];

    if (!isNil(filter.costCodeGroupId)) {
      const costCodesInGroup = await getCostCodes(filter.costCodeGroupId);
      costCodeIds.push(...costCodesInGroup.map((p) => p.id));
    }

    if (!isNilOrEmpty(filter.costCodeIds)) {
      costCodeIds = costCodeIds.concat(filter.costCodeIds ?? []);
    }

    if (!isEmpty(costCodeIds)) {
      if (costCodeIds.includes(remainingDataItemId)) {
        return { isNull: true };
      }
      return {
        contains: costCodeIds,
      };
    }

    return undefined;
  };

  const getMemberData = useCallback(
    async (cursor: string | null, first: number) => {
      const results = await client.query<{ members: Array<IMember & ICursorable> }>({
        query: MEMBERS_WITH_TIME_DOCUMENTS_QUERY,
        fetchPolicy: 'network-only',
        variables: await getMemberVariables(filter.timeRange, first, cursor),
      });

      return results.data.members ?? [];
    },
    [client, filter, getMemberVariables]
  );

  const addTimeData = useCallback(
    async (members: Array<IMember & ICursorable>) => {
      let groupedEntries: Dictionary<ITimeEntry[]> | null = null;
      let groupedTimeOffs: Dictionary<ITimeOff[]> | null = null;

      const memberIds = members.map((m) => m.id);
      const projectIds = filter.projectIds ? filter.projectIds : undefined;
      const costCodeIds = filter.costCodeIds ? filter.costCodeIds : undefined;
      const equipmentIds = filter.equipmentIds ? filter.equipmentIds : undefined;

      if (isNil(filter.reportType) || filter.reportType === TimesheetReportType.ENTRY_AND_ALL_TIME_OFF) {
        try {
          const entries = await getTimeEntries(
            filter.timeRange,
            filter.includeOpen,
            memberIds,
            projectIds,
            costCodeIds,
            equipmentIds,
            filter.projectIdWithDescendants,
            undefined,
            undefined,
            filter.costCodeGroupId,
            filter.projectGroupId,
            200
          );
          groupedEntries = groupBy(entries, 'memberId');
          if (
            isNilOrEmptyOrOnlyHasRemainingItem(filter.projectIds) &&
            isNilOrEmptyOrOnlyHasRemainingItem(filter.costCodeIds) &&
            isNilOrEmptyOrOnlyHasRemainingItem(filter.equipmentIds) &&
            isNil(filter.projectGroupId) &&
            isNil(filter.costCodeGroupId)
          ) {
            const timeOffs = await getTimeOffs(filter.timeRange, memberIds);
            groupedTimeOffs = groupBy(timeOffs, 'memberId');
          }
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      } else if (filter.reportType === TimesheetReportType.ENTRY) {
        try {
          const entries = await getTimeEntries(
            filter.timeRange,
            filter.includeOpen,
            memberIds,
            projectIds,
            costCodeIds,
            equipmentIds,
            filter.projectIdWithDescendants,
            undefined,
            undefined,
            filter.costCodeGroupId,
            filter.projectGroupId,
            200
          );
          groupedEntries = groupBy(entries, 'memberId');
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      } else if (
        filter.reportType === TimesheetReportType.TIME_OFF_BOTH ||
        filter.reportType === TimesheetReportType.TIME_OFF_PAID ||
        filter.reportType === TimesheetReportType.TIME_OFF_UNPAID
      ) {
        // we're only showing time off
        let paid;
        // only set paid if we're looking at just paid or just unpaid time off
        if (
          filter.reportType === TimesheetReportType.TIME_OFF_PAID ||
          filter.reportType === TimesheetReportType.TIME_OFF_UNPAID
        ) {
          paid = filter.reportType === TimesheetReportType.TIME_OFF_PAID;
        }

        try {
          const timeOffs = await getTimeOffs(filter.timeRange, memberIds, paid);
          groupedTimeOffs = groupBy(timeOffs, 'memberId');
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      } else if (filter.reportType === TimesheetReportType.ENTRY_AND_TIME_OFF_PAID) {
        try {
          const entries = await getTimeEntries(
            filter.timeRange,
            filter.includeOpen,
            memberIds,
            projectIds,
            costCodeIds,
            equipmentIds,
            filter.projectIdWithDescendants,
            undefined,
            undefined,
            filter.costCodeGroupId,
            filter.projectGroupId,
            200
          );
          groupedEntries = groupBy(entries, 'memberId');
          const timeOffs = await getTimeOffs(filter.timeRange, memberIds, true);
          groupedTimeOffs = groupBy(timeOffs, 'memberId');
        } catch (error) {
          logError(error as Error);
          toast({ label: t('Something went wrong. Please try again later.') });
        }
      }

      const allData = members.map((member) => {
        const item: ITimeEntryDataTableData<IMember> & ICursorable = {
          item: member,
          entries: groupedEntries?.[member.id] ?? [],
          timeOffs: groupedTimeOffs?.[member.id] ?? [],
          cursor: member.cursor,
        };
        return item;
      });
      if (!isNilOrEmpty(memberIds) && memberIds.length === 1) {
        return allData;
      }
      return allData.filter((item) => !isEmpty(item.entries) || !isEmpty(item.timeOffs));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  const localRange = useRef<ITimeRange<DateTime>>(filter.timeRange);

  const getData = useCallback(
    async (cursor: string | null, first: number) => {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      try {
        localRange.current = filter.timeRange;
        const members = await getMemberData(cursor, first);
        if (isEmpty(members)) {
          return [];
        }
        const timeData = await addTimeData(members);
        if (!timeRangesAreEqual(localRange.current, filter.timeRange)) {
          return [];
        }
        return timeData;
      } catch (error) {
        logError(error as Error);
        toast({ label: t('Something went wrong. Please try again later.') });
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getMemberData, addTimeData, toast, filter.timeRange]
  );

  const { clearData, data, error, loading, dispatch, loadedAll, loadAll } = useLazyLoading(
    scroller,
    getData,
    undefined,
    20,
    200
  );

  const employeeNameFormatter = useEmployeeNameFormatter();

  const updateMemberIds = useCallback(
    async (memberIds: string[]) => {
      dispatch({
        type: 'SET_LOADING',
        payload: true,
      });
      const allowedMemberIds = filter.memberIds
        ? memberIds.filter((id) => filter.memberIds?.some((memberId) => memberId === id))
        : memberIds;
      const newMemberData = allowedMemberIds.map(async (id) => {
        const member = data.find((datum) => datum.item.id === id);
        if (member?.item === undefined) {
          const variables = await getMemberVariables(filter.timeRange);

          const results = await client.query<{ members: Array<IMember & ICursorable> }>({
            query: MEMBERS_WITH_TIME_DOCUMENTS_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              ...variables,
              filter: {
                ...variables.filter,
                id: { equal: id },
              },
            },
          });

          return await addTimeData(results.data.members);
        } else {
          return await addTimeData([{ ...member.item, cursor: member.cursor }]);
        }
      });

      const allResults = (await Promise.all(newMemberData)).flat();

      const newData = data.filter((object) => !memberIds.includes(object.item.id));

      let allData = [...newData, ...allResults];

      if (isNilOrEmpty(filter.memberIds) && (isNilOrEmpty(memberIds) || memberIds.length >= 1)) {
        allData = allData.filter((item) => !isEmpty(item.entries) || !isEmpty(item.timeOffs));
      }

      const sortedData = sortBy(allData, (result) =>
        employeeNameFormatter(result.item.firstName ?? '', result.item.lastName ?? '')
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      ); // lodash sorts based on the standard ASCII values of characters

      dispatch({
        type: 'SET_DATA',
        payload: {
          data: sortedData,
          loadedAll: false,
          loading: false,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addTimeData, client, data, dispatch, filter.memberIds, filter.timeRange, getMemberVariables]
  );

  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const firstRender = useFirstRender();
  useEffect(() => {
    if (!isCancelled) {
      /* do nothing */
    }
    if (!firstRender) {
      clearData();
    }
    return () => {
      setIsCancelled(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberVariables, clearData, filter.timeRange]);

  async function forceLoadAll() {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const lastCursor = last(data)?.cursor;

    const remainingMembers = await getAll<IMember & ICursorable>('members', {
      query: MEMBERS_WITH_TIME_DOCUMENTS_QUERY,
      variables: await getMemberVariables(filter.timeRange, 500, lastCursor),
      fetchPolicy: 'network-only',
    });

    const newMemberData = await addTimeData(remainingMembers);

    const sortedData = sortBy([...data, ...newMemberData], (result) =>
      employeeNameFormatter(result.item.firstName ?? '', result.item.lastName ?? '')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    ); // lodash sorts based on the standard ASCII values of characters

    dispatch({
      type: 'SET_DATA',
      payload: {
        data: sortedData,
        loadedAll: true,
        loading: false,
      },
    });

    return sortedData;
  }

  return { clearData, data, error, loading, updateMemberIds, forceLoadAll, loadedAll, loadAll };
}
