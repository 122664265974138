import { Button, ClassName, Label, Textarea } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { HeaderDialog } from 'components';
import { t } from 'i18next';
import { useState } from 'react';
import './GpsEditDialog.scss';

export interface IGpsAuthorizeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (comment: string) => void;
  className?: ClassName;
}

const GpsAuthorizeDialog = (props: IGpsAuthorizeDialogProps) => {
  const { className, isOpen, onClose, onSubmit } = props;

  const [comment, setComment] = useState<string>('');

  const handleSubmit = () => {
    onSubmit(comment);
    setComment('');
  };

  const handleClose = () => {
    setComment('');
    onClose();
  };

  const classes = classNames('gps-edit-dialog m-5', className);

  return (
    <HeaderDialog title={t('Mark as Authorized')} isOpen={isOpen} onClose={handleClose}>
      <div className={classes}>
        <Label>
          {t('Comments')} {' - '}
          <span className="sub-label-text">{t('Optional')}</span>
        </Label>
        <Textarea value={comment} onChange={setComment} restrictTo={{ maxLength: 1000 }} />
        <Button type="primary" onClick={handleSubmit} className={'mt-5'}>
          {t('Submit')}
        </Button>
      </div>
    </HeaderDialog>
  );
};

export default GpsAuthorizeDialog;
