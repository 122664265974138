import {
  ITimeEntryDataTableData,
  useTimeEntryDataTableRow,
} from 'components/domain/time-entry/TimeEntryDataTable/hooks/useTimeEntryDataTableRow';
import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ICursorable from 'types/Cursorable';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import { isNilOrEmpty, isNilOrEmptyOrOnlyHasRemainingItem } from 'utils/collectionUtils';
import { dateTimeFromISOWithoutZone } from 'utils/dateUtils';
import { timeEntryEndTimeOrNow } from 'utils/timeEntryUtils';
import ITimeEntryReportWithHeaderData from '../../types/TimeEntryReportWithHeaderData';
import { useDateRanges } from './useDateRanges';

export interface IDateRangeWithId {
  id: string;
  startDate: DateTime;
  endDate: DateTime;
}

export default function useDateRangeTimeEntryDataReport(
  timeRange: ITimeRange<DateTime>,
  timeRangeType: TimeRangeType,
  clipDatesToTimeRange: boolean,
  includeOpenEntries?: boolean,
  costCodeIds?: string[],
  equipmentIds?: string[],
  memberIds?: string[],
  projectIds?: string[],
  projectIdWithDescendants?: boolean
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITimeEntryReportWithHeaderData[]>([]);
  const getRows = useTimeEntryDataTableRow();
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();
  const timeRanges = useDateRanges(timeRange, timeRangeType, clipDatesToTimeRange);
  const titleFormatter = useCallback(
    ({ startDate, endDate }: { startDate: DateTime; endDate: DateTime }) => {
      if (timeRangeType === TimeRangeType.MONTHLY) {
        return startDate.toFormat('MMMM yyyy');
      } else if (timeRangeType === TimeRangeType.DAILY) {
        return startDate.toFormat('MMM d yyyy');
      }

      return startDate.toFormat('MMM d') + ' - ' + endDate.toFormat('MMM d yyyy');
    },
    [timeRangeType]
  );

  useEffect(() => {
    getData();
  }, [titleFormatter, timeRange, includeOpenEntries, costCodeIds, equipmentIds, memberIds, projectIds]);

  async function getData() {
    setLoading(true);
    const entries = await getTimeEntries(
      timeRange,
      includeOpenEntries,
      memberIds,
      projectIds,
      costCodeIds,
      equipmentIds,
      projectIdWithDescendants,
      undefined,
      [MemberPermission.TIME_EVENTS]
    );
    let timeOffs: ITimeOff[] = [];

    // don't include time off if we are filtering by projects, cost codes, or equipment
    if (
      isNilOrEmptyOrOnlyHasRemainingItem(projectIds) &&
      isNilOrEmptyOrOnlyHasRemainingItem(costCodeIds) &&
      isNilOrEmptyOrOnlyHasRemainingItem(equipmentIds)
    ) {
      timeOffs = await getTimeOffs(timeRange, memberIds, true, [MemberPermission.TIME_EVENTS]);
    }

    const groupedData = timeRanges.map(({ startTime, endTime }) => {
      const filteredEntries = entries.filter(
        (entry) =>
          endTime >= dateTimeFromISOWithoutZone(entry.startTime) &&
          startTime <= dateTimeFromISOWithoutZone(timeEntryEndTimeOrNow(entry).toString())
      );

      const filteredTimeOffs = timeOffs.filter(
        (entry) =>
          endTime >= dateTimeFromISOWithoutZone(entry.dateStamp) &&
          startTime <= dateTimeFromISOWithoutZone(entry.dateStamp)
      );

      const item: ITimeEntryDataTableData<IDateRangeWithId> & ICursorable = {
        item: { id: `${startTime.toISO()}`, startDate: startTime, endDate: endTime },
        entries: filteredEntries,
        timeOffs: filteredTimeOffs,
        cursor: '',
      };
      return item;
    });
    const newData = await getRows(
      timeRange,
      groupedData.filter((item) => !isNilOrEmpty(item.entries) || !isNilOrEmpty(item.timeOffs)),
      titleFormatter
    );
    setLoading(false);
    setData(newData);
  }

  return useMemo(() => ({ data, loading, getData }), [data, loading]);
}
