import { Reducer } from 'redux';

import { timeStampUtc } from '../../utils/dateUtils';

import {
  IStoreSession,
  IStoreSessionCreateAction,
  IStoreSessionResetAction,
  SESSION_CREATE,
  SESSION_RESET,
} from './sessionIndex';

// import { sessionResetReducer } from './reset/sessionResetReducer';

export type ISessionActions = IStoreSessionCreateAction | IStoreSessionResetAction;

const initialState: IStoreSession = {
  isAuthenticated: false,
  timestamp: timeStampUtc(),
};

export const sessionReducers: Reducer<IStoreSession, ISessionActions> = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_CREATE:
      return { ...state, ...action.payload };
    case SESSION_RESET:
      return { ...action.payload }; // Reset removes current state.

    default:
      return state;
  }
};

export default sessionReducers;
