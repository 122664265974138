import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { IMemberWebFeatures } from 'types/MemberWebFeatures';
import useMemberSettings from './useMemberSettings';
import useMemberWebSettingsUpdate from './useMemberWebSettingsUpdate';

export default function useMemberFeatureSettingsUpdate() {
  const memberSettings = useMemberSettings();
  const updateWebSettings = useMemberWebSettingsUpdate();

  return useCallback(
    <K extends keyof IMemberWebFeatures>(key: K, payload: IMemberWebFeatures[K]) => {
      const cloned = cloneDeep(memberSettings?.web?.features);
      return updateWebSettings('features', { ...cloned, [key]: payload });
    },
    [memberSettings?.web?.features, updateWebSettings]
  );
}
