import { Icon, Label, Row } from '@busybusy/webapp-react-ui';
import { LockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { HeaderDialog } from 'components';
import CustomerSupportActionList from 'components/layout/navigation-drawer/NavHeader/CustomerSupportActionList/CustomerSupportActionList';
import { useActiveMember, useOpenable, useOrganization } from 'hooks';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useFeatureFlags } from 'utils/features';
import { isSubscriptionLockedOut } from 'utils/organizationSubscriptionStatusUtils';
import { isAdmin } from 'utils/positionUtils';
import './LockedAccountBanner.scss';

export interface ILockedAccountBannerProps {
  className?: ClassName;
}

const LockedAccountBanner = (props: ILockedAccountBannerProps) => {
  const { className } = props;
  const organization = useOrganization();
  const activeMember = useActiveMember();
  const isPro = useFeatureFlags('PRO');
  const customerSupportDialog = useOpenable();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(
      isPro && isAdmin(activeMember.position) && isSubscriptionLockedOut(organization.subscriptionStatus!)
    );
  }, [activeMember.position, isPro, organization, organization.subscriptionStatus?.status]);

  const RenderContactLink = () => {
    return (
      <span className={'contact-link'} onClick={customerSupportDialog.open}>
        {t('contact customer support')}
      </span>
    );
  };

  const classes = classNames('locked-account-banner p-5', className);

  return (
    <>
      {shouldRender && (
        <>
          <div className={classes}>
            <Row>
              <Icon svg={LockIcon} className={'lock'}></Icon>
              <div className={'ml-5'}>
                <Label>{t('Account Locked')}</Label>
                <div>
                  {t(
                    'Your company account has been locked due to a missing payment. Your employees will not be able to log in until the issue is resolved.'
                  )}
                  <br />
                  {t('Please ')}
                  {RenderContactLink()}
                  {t(' as soon as possible so that we can help resolve the issue.')}
                </div>
              </div>
            </Row>
          </div>
          <HeaderDialog
            title={t('Support')}
            isOpen={customerSupportDialog.isOpen}
            onClose={customerSupportDialog.close}
          >
            <CustomerSupportActionList onSelect={customerSupportDialog.close} className="mx-8 mb-6 pt-2" />
          </HeaderDialog>
        </>
      )}
    </>
  );
};

export default LockedAccountBanner;
