import useGustoCustomerType from 'containers/payroll/gusto/hooks/useGustoCustomerType/useGustoCustomerType';
import { useFeatureFlags } from 'utils/features';
import useActivePosition from 'hooks/models/position/useActivePosition';
import { getSafeBoolean } from 'utils/optionalUtils';
import { isNil } from 'lodash';

export default function useGustoPaidEnabled() {
  const gustoToggledOn = useFeatureFlags('GUSTO_PAYROLL');
  const activePosition = useActivePosition();
  const gustoIsEnabled = getSafeBoolean(gustoToggledOn) && !isNil(activePosition) && activePosition.level === 1;

  const gustoPaidState = useGustoCustomerType();
  const gustoIsPaid = gustoPaidState !== 'unpaid';

  return gustoIsEnabled && gustoIsPaid;
}
