import { Button, Icon, Justify, Row, Size, Theme } from '@busybusy/webapp-react-ui';
import { PROJECT_QUANTITY_SUMMARY_QUERY } from 'apollo/queries/project-cost-code-quantity-queries';
import { ProgressIcon, ProgressOutlinedIconWithoutFill } from 'assets/icons';
import classNames from 'classnames';
import { HeaderDialog, Panel } from 'components';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardCardBarChart from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/DashboardBarChart';
import { dashboardCardBarChartGradientBlueLightPurple } from 'components/domain/dashboard/dashboard-card-chart/DashboardBarChart/constants/constants';
import DashboardContentCard from 'components/foundation/dashboard/DashboardContentCard/DashboardContentCard';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import StateTemplate from 'components/foundation/state-templates/StateTemplate/StateTemplate';
import useDashboardGraphDates from 'containers/dashboard/hooks/useDashboardGraphDates';
import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { convertDashboardTimeFrameToTimeRangeType, getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { getDashboardNavigationRange } from 'containers/scheduling/utils/utils';
import {
  useActiveMember,
  useCostCodeManagementListNavigation,
  useOpenable,
  useOrganization,
  useOrganizationUpdate,
} from 'hooks';
import useProgressReportNavigation from 'hooks/navigation/useProgressReportNavigation';
import { useCheckForObjects } from 'hooks/utils/useCheckForObjects/useCheckForObjects';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import { useFeatureFlags } from 'utils/features';
import { isAdmin } from 'utils/positionUtils';
import DashboardCardSpinner from '../DashboardCardSpinner/DashboardCardSpinner';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import DashboardProUpgradeCard from '../DashboardProUpgradeCard/DashboardProUpgradeCard';
import useDashboardCardTotal from '../hooks/useDashboardCardTotal';
import './ProgressEntriesDashboardCard.scss';
import ProgressEntriesDashboardCardList from './ProgressEntriesDashboardCardList/ProgressEntriesDashboardCardList';
import useProgressDashboardCard from './hooks/useProgressDashboardCard';

export interface IProgressEntriesDashboardCardProps {
  memberId?: string;
  projectId?: string;
  costCodeId?: string;
  theme: Theme.DARK | Theme.LIGHT;
  className?: ClassName;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
}

const ProgressEntriesDashboardCard = (props: IProgressEntriesDashboardCardProps) => {
  const { memberId, theme, className, setting, onHide, projectId, costCodeId } = props;

  const isPro = useFeatureFlags('PRO');
  const workAccomplishEnabled = useFeatureFlags('WORK_ACCOMPLISHED');
  const organization = useOrganization();
  const [updateKey] = useOrganizationUpdate();

  const navigateToProgressReport = useProgressReportNavigation();

  const navigateToCostCodeManagement = useCostCodeManagementListNavigation();
  const getFirstProgressEntry = useCheckForObjects(PROJECT_QUANTITY_SUMMARY_QUERY, 'projectCostCodeQuantities');
  const [showPremiumEmptyState, setShowPremiumEmptyState] = useState(false);

  const settingsDialogDetails = useOpenable();

  const currentTimeFrame: DashboardTimeFrame = setting.options?.time ?? 'payPeriod';

  const { timeRange, timeRanges } = useDashboardGraphDates(setting.options?.time)!; // Null force safe for all settings but `clockStatus`
  const { data, execute, loading, error } = useProgressDashboardCard(
    timeRange,
    timeRanges,
    memberId,
    projectId,
    costCodeId
  );
  const total = useDashboardCardTotal(timeRanges, null, null, data ?? [], currentTimeFrame, loading);

  const activeMember = useActiveMember();
  const navigate = useCallback(() => {
    const timeRangeType = convertDashboardTimeFrameToTimeRangeType(currentTimeFrame);
    const navRange = getDashboardNavigationRange(timeRange, timeRanges, currentTimeFrame);

    navigateToProgressReport({
      employee_id: memberId,
      project_id: projectId,
      cost_code_id: costCodeId,
      show_back_button: true,
      time_range_type: timeRangeType,
      start_date: navRange.startTime.toISODate(),
      end_date: navRange.endTime.toISODate(),
    });
  }, [currentTimeFrame, timeRange, timeRanges, navigateToProgressReport, memberId, projectId, costCodeId]);

  useOnMount(() => {
    // We're not rendering anything no reason to try to load
    if (!isPro && !isAdmin(activeMember.position)) {
      return;
    }

    checkForProgressEntries();
  });

  async function checkForProgressEntries() {
    const progressEntries = await getFirstProgressEntry();
    if (progressEntries.length === 0) {
      setShowPremiumEmptyState(true);
    }
  }

  function openCostCodeSettings() {
    updateKey('trackCostCode', true);
    settingsDialogDetails.open();
  }

  function openCostCodeManagement() {
    navigateToCostCodeManagement();
  }

  const classes = classNames('progress-dashboard-card', className);

  useEffect(() => {
    if (isPro) {
      execute();
    }
  }, [isPro, memberId, projectId, timeRange]);

  return (
    <>
      {workAccomplishEnabled && isPro ? (
        organization.trackCostCode ? (
          error === null ? (
            showPremiumEmptyState ? (
              <DashboardContentCard
                rightLabel={t('View All')}
                onRightLabelClick={navigate}
                className={classes}
                theme={theme}
              >
                <StateTemplate
                  className="empty-state"
                  graphic={<Icon svg={ProgressOutlinedIconWithoutFill} size={Size.XL} />}
                  title={t('No Progress Submissions')}
                  footer={
                    <Button type={'primary'} className={'mt-6'} onClick={navigate}>
                      {t(`Explore Progress Tracking`)}
                    </Button>
                  }
                />
              </DashboardContentCard>
            ) : (
              <DashboardContentCard
                header={total}
                rightLabel={t('Details')}
                onRightLabelClick={navigate}
                subheaderValue={getDashboardTimeFrameTitle(setting.title, currentTimeFrame)}
                className={classes}
                theme={theme}
              >
                {loading ? (
                  <DashboardCardSpinner />
                ) : (
                  <>
                    {currentTimeFrame === 'daily' ? (
                      <ProgressEntriesDashboardCardList
                        theme={theme}
                        projectId={projectId}
                        memberId={memberId}
                        costCodeId={costCodeId}
                        timeRange={timeRange}
                        didUpdate={execute}
                      />
                    ) : (
                      <DashboardCardBarChart
                        data={data}
                        dataKey="value"
                        valueType="raw"
                        timeFrame={currentTimeFrame}
                        theme={theme}
                        {...dashboardCardBarChartGradientBlueLightPurple}
                      />
                    )}
                  </>
                )}
              </DashboardContentCard>
            )
          ) : (
            <DashboardErrorCard theme={theme} onClick={execute} />
          )
        ) : activeMember.position?.manageCompanySettings ? (
          <DashboardEnableCard
            title={setting.title}
            svg={ProgressIcon}
            buttonLabel={t('Enable Cost Codes')}
            onClick={openCostCodeSettings}
            theme={theme}
            onHide={onHide}
          />
        ) : null
      ) : workAccomplishEnabled ? (
        <DashboardProUpgradeCard title={setting.title} svg={ProgressIcon} theme={theme} onHide={onHide} />
      ) : null}
      <HeaderDialog
        title={t('Cost Codes Enabled')}
        isOpen={settingsDialogDetails.isOpen}
        onClose={settingsDialogDetails.close}
        position="center"
      >
        <Panel className="px-8 py-6">
          <div>
            {t(
              'Would you like to visit the cost code management page where you can create and edit cost codes for your company?'
            )}
          </div>
          <Row justify={Justify.FLEX_END}>
            <Button type="secondary" onClick={settingsDialogDetails.close} className="mr-4">
              {t('No Thanks')}
            </Button>
            <Button type="primary" onClick={openCostCodeManagement}>
              {t('Manage')}
            </Button>
          </Row>
        </Panel>
      </HeaderDialog>
    </>
  );
};

export default ProgressEntriesDashboardCard;
