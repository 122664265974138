import { useApolloClient } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useApolloPaging } from 'hooks';
import { first, groupBy } from 'lodash';
import { mapNotNil } from 'utils/collectionUtils';
import { BudgetCost, BudgetCostFilter } from '__generated__/graphql';

export default function useBudgetCostData() {
  const client = useApolloClient();
  const { getAll } = useApolloPaging();

  async function getAllBudgetCostData(filter: BudgetCostFilter) {
    const results = await getAll<BudgetCost>('budgetCosts', {
      query: BUDGET_COST_QUERY,
      variables: {
        first: 500,
        filter: {
          ...filter,
          isLatest: { equal: true },
        },
        sort: [{ createdOn: 'desc' }],
      },
      fetchPolicy: 'network-only',
    });
    return results.filter((budget) => budget.costBudget > 0 || (budget.equipmentCostBudget ?? 0) > 0);
  }

  async function getLatestBudgetCostData(filter: BudgetCostFilter) {
    const results = await client.query<{ budgetCosts: BudgetCost[] }>({
      query: BUDGET_COST_QUERY,
      variables: {
        first: 1,
        filter: {
          ...filter,
          isLatest: { equal: true },
        },
        sort: [{ createdOn: 'desc' }],
      },
      fetchPolicy: 'network-only',
    });

    const filteredResults = results.data.budgetCosts.filter(
      (budget) => budget.costBudget > 0 || (budget.equipmentCostBudget ?? 0) > 0
    );

    const groupedResults = groupBy(filteredResults, 'projectId');

    return mapNotNil(Object.keys(groupedResults), (key) => {
      return first(groupedResults[key]);
    });
  }

  return { getAllBudgetCostData, getLatestBudgetCostData };
}

export const BUDGET_COST_QUERY = gql`
  query budgetCostQuery($filter: BudgetCostFilter, $sort: [BudgetCostSort!], $first: Int, $after: String) {
    budgetCosts(filter: $filter, sort: $sort, first: $first, after: $after) {
      id
      projectId
      memberId
      costBudget
      costCodeId
      equipmentId
      cursor
      equipmentCostBudget
    }
  }
`;
