import { ApolloQueryResult, OperationVariables, QueryOptions, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import ICursorable from 'types/Cursorable';
import useApolloAutoPager from './useApolloAutoPager';

export type ApolloGetAll<
  T extends ICursorable,
  // TODO: fix usage of {} as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  V extends OperationVariables & { after?: string | null; first?: number | null } = {},
  O extends QueryOptions<V> = any
> = (dataAccessorKey: string, options: O) => Promise<T[]>;

export default function useApolloPaging() {
  const client = useApolloClient();
  const pager = useApolloAutoPager();

  const getPage = useCallback(
    <
      T extends ICursorable,
      // TODO: fix usage of {} as a type
      // eslint-disable-next-line @typescript-eslint/ban-types
      D extends { [key: string]: T[] } = {},
      // TODO: fix usage of {} as a type
      // eslint-disable-next-line @typescript-eslint/ban-types
      V extends OperationVariables & { after?: string | null; first?: number | null } = {},
      O extends QueryOptions<V> = any
    >(
      options: O,
      after: string | null | undefined = options.variables ? options.variables.after : null
    ): Promise<ApolloQueryResult<D>> => {
      return client.query<D, V>({ ...options, variables: { ...options.variables, after } });
    },
    [client]
  );

  const getAll = useCallback(
    async <
      T extends ICursorable,
      // TODO: fix usage of {} as a type
      // eslint-disable-next-line @typescript-eslint/ban-types
      D extends { [key: string]: T[] } = {},
      // TODO: fix usage of {} as a type
      // eslint-disable-next-line @typescript-eslint/ban-types
      V extends OperationVariables & { after?: string | null; first?: number | null } = {},
      O extends QueryOptions<V> = any
    >(
      dataAccessorKey: string,
      options: O
    ): Promise<T[]> => {
      return pager(
        (after: string | null, first: number) =>
          getPage<T, D, V, O>({ ...options, first }, after ?? options.variables?.after ?? null),
        dataAccessorKey,
        options?.variables?.first ?? 50,
        options?.variables?.after ?? null
      );
    },
    [getPage, pager]
  );

  return { getPage, getAll };
}
