import { Icon, ITableColumn, Label, Table, Theme } from '@busybusy/webapp-react-ui';
import { ClockIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { EmptyState } from 'components';
import FormattedEquipmentName from 'components/domain/equipment/FormattedEquipmentName/FormattedEquipmentName';
import ContainerWithSpinner from 'components/foundation/ContainerWithSpinner/ContainerWithSpinner';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { IEquipmentActivityTableRowInfo } from 'containers/activity-reports/equipment-tables/hooks/useEquipmentActivity';
import useEquipmentActivityReportExport from 'containers/activity-reports/equipment-tables/hooks/useEquipmentActivityReportExport';
import useActivityReportDetailColumn, {
  activityReportDetailId,
  isActivityRowRestricted,
  timeRangeFromActivityDateItem,
} from 'containers/activity-reports/hooks/useActivityReportDetailColumn';
import useActivityReportDetailTable from 'containers/activity-reports/hooks/useActivityReportDetailTable';
import { ChannelIds } from 'contexts/ChannelContext/ChannelIds';
import { useChannelListener } from 'contexts/ChannelContext/hooks/useChannelListener';
import { useExportChannelListener } from 'contexts/ChannelContext/hooks/useExportChannelListener';
import { useToastOpen } from 'contexts/ToastContext';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { dateUtils } from 'utils';
import { remainingDataItemId } from 'utils/constants/utilConstants';
import { t } from 'utils/localize';
import useEquipmentActivityDetails from '../hooks/useEquipmentActivityDetails';
import './EquipmentActivityReportDetailsTable.scss';
import useBrandTitle from 'hooks/meta/useBrandTitle';

export interface IEquipmentActivityReportDetailsProps {
  className?: ClassName;
  filterId: string;
  filterIdWithDescendants?: boolean;
  filterType: ActivityReportType;
  secondaryReportType: ActivityReportType;
  dateRangeReportType?: TimeRangeType;
  timeRange: ITimeRange<DateTime>;
  onRowSelected: (id: string, secondaryId?: string) => void;
  onDateRowSelected: (id: string, startDate: DateTime, endDate: DateTime) => void;
  scroller: HTMLElement | null;
}

const EquipmentActivityReportDetailsTable = (props: IEquipmentActivityReportDetailsProps) => {
  const {
    className,
    filterId,
    filterIdWithDescendants,
    filterType,
    secondaryReportType,
    dateRangeReportType,
    timeRange,
    onRowSelected,
    onDateRowSelected,
    scroller,
  } = props;
  const classes = classNames('equipment-activity-report-details', className);

  const { sortedData, onSort, sortedBy, sortDirection, sortIsDirty, remainingData, isLoading } =
    useEquipmentActivityDetails(
      filterId,
      filterType,
      secondaryReportType,
      timeRange,
      filterIdWithDescendants,
      dateRangeReportType
    );
  const data = useMemo(() => {
    if (remainingData) {
      return sortedData.concat(remainingData);
    }
    return sortedData;
  }, [sortedData, remainingData]);
  const { aggregateColumns, tableMinWidth } = useActivityReportDetailTable(
    data,
    secondaryReportType,
    ActivityReportType.BY_EQUIPMENT
  );
  const { detailColumn, isShowingPartialDateRange, isDetailRow, exportDetailColumn, getExportData } =
    useActivityReportDetailColumn(secondaryReportType, dateRangeReportType);

  const [showingPartialDateRange, setShowingPartialDateRange] = useState(false);
  const [lazyLoadingEnabled, setLazyLoadingEnabled] = useState(true);
  useChannelListener(ChannelIds.LazyLoadUpdate, () => setLazyLoadingEnabled(false));

  const getActivityExportData = useEquipmentActivityReportExport();

  const openToast = useToastOpen();

  const flattenedData = useMemo(() => flattenData(data), [data]);

  const partialMessage = useMemo<string | null>(() => {
    if (showingPartialDateRange) {
      switch (dateRangeReportType) {
        case TimeRangeType.MONTHLY:
          return t('* Indicates a partial month');
        case TimeRangeType.PAY_PERIOD:
          return t('* Indicates a partial pay period');
        default:
          return t('* Indicates a partial week');
      }
    }

    return null;
  }, [showingPartialDateRange, dateRangeReportType]);

  const brand = useBrandTitle();

  useExportChannelListener(
    () => exportReport(),
    true,
    `${brand}-equipment-activity-report-${dateUtils.isoTimeStampLocal()}`
  );

  function exportReport() {
    const exportData = getExportData(data);
    return getActivityExportData(exportData, (row) => {
      return exportDetailColumn(row);
    });
  }

  useEffect(() => {
    setShowingPartialDateRange(isShowingPartialDateRange(flattenedData));
  }, [flattenedData]);

  const renderEquipment = (row: IEquipmentActivityTableRowInfo) => {
    if (secondaryReportType !== ActivityReportType.BASIC && isDetailRow(row)) {
      return null;
    } else if (row.equipment !== null) {
      return <FormattedEquipmentName equipment={row.equipment} />;
    }

    return <Label className="cell-title equipment">{t('No Equipment')}</Label>;
  };

  function getColumns(): Array<ITableColumn<IEquipmentActivityTableRowInfo>> {
    return [
      {
        key: 'equipment',
        title: t('Equipment'),
        sort: true,
        cell: renderEquipment,
        size: '300px',
        footer: renderTotalColumnFooter,
        cellClassName: 'pl-6 name-column',
        headerClassName: 'pl-6 name-column',
        footerClassName: 'pl-6 name-column',
      },
      ...detailColumn,
      ...aggregateColumns,
    ];
  }

  const renderTotalColumnFooter = () => <Label className="cell-title">{t('Totals')}</Label>;

  function rowClickAction(rowItem: IEquipmentActivityTableRowInfo) {
    if (isActivityRowRestricted(secondaryReportType, rowItem)) {
      openToast({ label: t('Access restricted'), timeout: 3000, theme: Theme.DANGER });
      return;
    }

    const equipmentId = rowItem.equipment?.id ?? remainingDataItemId;

    if (secondaryReportType === ActivityReportType.BY_DATE_RANGE || secondaryReportType === ActivityReportType.BY_DAY) {
      const selectedTimeRange = timeRangeFromActivityDateItem(rowItem);

      if (!isNil(selectedTimeRange)) {
        onDateRowSelected(equipmentId, selectedTimeRange.startTime, selectedTimeRange.endTime);
        return;
      }
    }

    const secondaryId = activityReportDetailId(secondaryReportType, rowItem);
    onRowSelected(equipmentId, secondaryId);
  }

  function renderEmptyState() {
    return <EmptyState graphic={<Icon svg={ClockIcon} size={180} />} title={t('No Equipment Activity')} />;
  }

  function getRowClassName(rowItem: IEquipmentActivityTableRowInfo) {
    const totalRow =
      secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
    return isDetailRow(rowItem) ? 'detailRowBackground' : totalRow;
  }

  const tableFooterClassName = useMemo(() => {
    return secondaryReportType === ActivityReportType.BASIC ? 'detailRowBackground' : 'detailTotalRowBackground';
  }, [secondaryReportType]);

  function flattenData(data: IEquipmentActivityTableRowInfo[]): IEquipmentActivityTableRowInfo[] {
    return data.flatMap((item) => {
      if (item.detailRows && !isEmpty(item.detailRows)) {
        return [item, ...item.detailRows];
      } else {
        return [item];
      }
    });
  }

  return (
    <ContainerWithSpinner className={classes} isLoading={isLoading}>
      {partialMessage && <div className="ml-6 mb-2">{partialMessage}</div>}
      <Table
        className={'equipment-activity-report-details-table'}
        cols={getColumns()}
        data={flattenedData}
        isLoading={isLoading}
        sortDir={sortDirection}
        sortBy={sortedBy}
        strokeCols={true}
        onSort={onSort}
        sortIsDirty={sortIsDirty}
        lazyLoad={lazyLoadingEnabled}
        lazyScrollSectionSize={50}
        lazyScrollTrigger={scroller ?? undefined}
        header="standard"
        footer="standard"
        minWidth={`${tableMinWidth}px`}
        scroller={scroller ?? undefined}
        onRowClick={rowClickAction}
        emptyTemplate={renderEmptyState()}
        getCustomRowClassName={getRowClassName}
        footerClassName={tableFooterClassName}
      />
    </ContainerWithSpinner>
  );
};

export default EquipmentActivityReportDetailsTable;
