import { IFormRender, Row } from '@busybusy/webapp-react-ui';
import { DeleteIcon } from 'assets/icons';
import { IconButton, ProjectPickerFormField } from 'components';
import Typography from 'components/foundation/text/Typography/Typography';
import { useOrganization } from 'hooks';
import useProjectCostCodeLinkCheck from 'hooks/models/project-cost-code-link/useProjectCostCodeLinkCheck';
import useTimeEntryProjectValidation from 'hooks/useTimeEntryProjectValidation';
import { useMemo } from 'react';
import * as React from 'react';
import { t } from 'utils/localize';
import { MULTIPLE } from '../BulkEditEntryForm/BulkEditEntryForm';
import { BulkEditActions, BulkEditActionType, IUndoableClearableFieldState } from '../reducers/reducers';
import { FormInfo } from 'types/FormData';

export interface IProjectTrashRowProps<T extends FormInfo> {
  form: IFormRender<T>;
  bulkState: IUndoableClearableFieldState;
  dispatch: React.Dispatch<BulkEditActions>;
  costCodeState: IUndoableClearableFieldState;
  costCodeDispatch: React.Dispatch<BulkEditActions>;
}

function ProjectTrashRow<T extends FormInfo>({
  form,
  bulkState,
  dispatch,
  costCodeState,
  costCodeDispatch,
}: IProjectTrashRowProps<T>) {
  const organization = useOrganization();

  const projectValidations = useTimeEntryProjectValidation();
  const projectCostCodeLinkCheck = useProjectCostCodeLinkCheck();

  const withMultipleConsiderationProjectValidations = useMemo(() => {
    if (bulkState.current === 'MULTIPLE') {
      return [];
    } else {
      return projectValidations;
    }
  }, [bulkState, projectValidations]);

  function clearField() {
    dispatch({ type: BulkEditActionType.CLEAR_CURRENT });
    if (organization.useProjectCostCodeScoping) {
      costCodeDispatch({ type: BulkEditActionType.CLEAR });
    }
  }

  async function onChange(id: string | null) {
    if (!id) {
      clearField();
    } else {
      dispatch({ type: BulkEditActionType.SET_CURRENT, payload: { value: id } });
      if (organization.useProjectCostCodeScoping) {
        if (costCodeState.current === 'MULTIPLE') {
          costCodeDispatch({ type: BulkEditActionType.CLEAR });
        } else {
          if (costCodeState.current && costCodeState.current !== null) {
            const { exists } = await projectCostCodeLinkCheck(id, costCodeState.current);
            if (!exists) {
              costCodeDispatch({ type: BulkEditActionType.CLEAR });
            }
          }

          costCodeDispatch({ type: BulkEditActionType.DISABLE_UNDO });
        }
      }
    }
  }

  async function onUndo(event: React.MouseEvent) {
    event.stopPropagation();
    dispatch({ type: BulkEditActionType.UNDO_CURRENT });
    if (organization.useProjectCostCodeScoping) {
      // If the cost code is the same as it was originally we don't need  to clear it back
      if (
        bulkState.original === null ||
        (bulkState.original === 'MULTIPLE' && costCodeState.current !== costCodeState.original)
      ) {
        costCodeDispatch({ type: BulkEditActionType.CLEAR });
      } else if (bulkState.original === 'MULTIPLE') {
        costCodeDispatch({ type: BulkEditActionType.CLEAR });
      } else if (costCodeState.current && costCodeState.current !== null) {
        const { exists } = await projectCostCodeLinkCheck(bulkState.original, costCodeState.current);
        if (!exists) {
          costCodeDispatch({ type: BulkEditActionType.CLEAR });
        }
      }
    }
  }

  function getProjectPlaceholder() {
    return bulkState.original === MULTIPLE && bulkState.current === null
      ? organization.requireProject
        ? t('Select a project (required).')
        : t('Projects will be set to "No Project".')
      : t('Project');
  }

  function renderUndo() {
    return (
      <Typography className={'mx-2'} onClick={onUndo} color={'primary'} tag={'span'}>
        {t('Undo')}
      </Typography>
    );
  }

  return (
    <Row className="trash-row">
      <ProjectPickerFormField
        name="project"
        form={form}
        placeholder={getProjectPlaceholder()}
        onChange={onChange}
        closeButtonRender={bulkState.undoable ? renderUndo : undefined}
        dropDownButtonRender={bulkState.undoable ? renderUndo() : undefined}
        validations={withMultipleConsiderationProjectValidations}
      />
      {bulkState.clearable && <IconButton className="trash-icon" onClick={clearField} svg={DeleteIcon} />}
    </Row>
  );
}

export default ProjectTrashRow;
