import { Button } from '@busybusy/webapp-react-ui';
import { OrganizationUpdateInput } from '__generated__/graphql';
import classNames from 'classnames';
import SecretMenuWarningWell from 'components/domain/preferences/SecretMenuWarningWell/SecretMenuWarningWell';
import Well from 'components/foundation/Well/Well';
import { useOrganization, useOrganizationInfoUpdate, useOrganizationUpdate } from 'hooks';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useDispatch } from 'react-redux';
import { organizationSetAction } from 'store/actions';
import { ClassName } from 'types/ClassName';
import IOrganization from 'types/Organization';
import { IOrganizationInfo } from 'types/OrganizationInfo';

export interface IOrganizationSettingsDetailsProps {
  className?: ClassName;
  onClose: () => void;
}

type OrgJson = Omit<
  IOrganization,
  '__typename' | 'organizationOvertimePeriods' | 'organizationInfo' | 'organizationPayPeriod'
>;

const OrganizationSettingsDetails = (props: IOrganizationSettingsDetailsProps) => {
  const { className, onClose } = props;

  const organization = useOrganization();
  const dispatch = useDispatch();
  const [, updateOrg] = useOrganizationUpdate();
  const [, updateOrgInfo] = useOrganizationInfoUpdate();

  const [orgJson, setOrgJson] = useState<OrgJson>();
  const [orgInfo, setOrgInfo] = useState<IOrganizationInfo>();
  const [uneditableOrgData, setUneditableOrgData] = useState<any>();

  useEffect(() => {
    if (!orgJson) {
      setOrgJson(filterOrg(organization));
    }
    if (organization.organizationInfo) {
      setOrgInfo(filterOrgInfo(organization.organizationInfo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const filterOrg = (obj: IOrganization): OrgJson => {
    const {
      organizationOvertimePeriods,
      organizationInfo,
      organizationPayPeriod,
      betaTester,
      createdOn,
      deletedOn,
      demo,
      imageId,
      inRecoveryMode,
      revokedOn,
      stripeAccountStatus,
      stripeAccountType,
      stripeCustomerId,
      submittedOn,
      subscriptionStatus,
      updatedOn,
      __typename,
      ...filteredObj
    } = obj as any; //removing fields that can't be updated through graphQL
    setUneditableOrgData({
      betaTester,
      createdOn,
      deletedOn,
      demo,
      imageId,
      inRecoveryMode,
      revokedOn,
      stripeAccountStatus,
      stripeAccountType,
      stripeCustomerId,
      submittedOn,
      subscriptionStatus,
      updatedOn,
    }); //setting those fields in a view only tree
    return filteredObj;
  };

  const filterOrgInfo = (obj: IOrganizationInfo): IOrganizationInfo => {
    const { __typename, organizationId, createdOn, deletedOn, submittedOn, updatedOn, ...filteredInfo } = obj as any; //removing fields that can't be updated through graphQL
    return filteredInfo;
  };
  const handleOrgUpdate = (data: any) => {
    const updatedOrgData = data.updated_src;
    setOrgJson(updatedOrgData);
  };

  const handleOrgInfoUpdate = (data: any) => {
    const updatedOrgInfoData: IOrganizationInfo = data.updated_src;
    setOrgInfo(updatedOrgInfoData);
  };

  const handleSubmit = () => {
    if (orgJson) {
      updateOrg({ ...orgJson } as OrganizationUpdateInput).then((results) => {
        updateOrgInfo({ ...orgInfo }).then((res) => {
          dispatch(
            organizationSetAction({
              ...results.data!.updateOrganization,
              organizationInfo: res.data?.updateOrganizationInfo,
            } as IOrganization)
          );
        });
      });
      onClose();
    }
  };

  const classes = classNames('m-6', className);

  return (
    <div className={classes}>
      <div>
        <SecretMenuWarningWell />
        <h3>{t('Organization settings')}</h3>
        <ReactJson name={'Organization'} collapsed={true} src={orgJson ?? {}} onEdit={handleOrgUpdate} />
        <h3 className="mt-5">{t('Organization Info')}</h3>
        <ReactJson name={t('OrganizationInfo')} collapsed={true} src={orgInfo ?? []} onEdit={handleOrgInfoUpdate} />
        <Well className="mt-5" theme="outline">
          <h3>{t('Read Only Organization Settings')}</h3>
          <ReactJson name={t('ReadOnlyProperties')} collapsed={true} src={uneditableOrgData ?? {}} />
          {t('These settings can only be viewed and are not editable.')}
        </Well>
      </div>
      <div className="mt-5">
        <Button onClick={handleSubmit} type="primary" className={'mr-4'}>
          {t('Save')}
        </Button>
        <Button onClick={onClose} type="secondary" className={'mt-4'}>
          {t('Close')}
        </Button>
      </div>
    </div>
  );
};

export default OrganizationSettingsDetails;
