import { Col, Position, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { EmployeeGroupPicker, EmployeePicker, PanelContent } from 'components';
import AutoHidePositionPicker from 'components/domain/position/position-picker/AutoHidePositionPicker/AutoHidePositionPicker';
import SidePanelFilter from 'components/foundation/FilterSidePanel/FilterSidePanel';
import BasicNonNullPicker from 'components/foundation/pickers/basic-picker/BasicNonNullPicker/BasicNonNullPicker';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { useOrganization, useQueryParams, useTimeRange } from 'hooks';
import useDailySignOffSettingsUpdate from 'hooks/models/member-settings/useDailySignOffSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { useCustomSignOffEnabled } from 'hooks/models/organization/useCustomSignOffEnabled';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ClassName } from 'types/ClassName';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { QueryParam } from 'utils/constants/queryParam';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import './DailySignOffReportFilter.scss';

export interface IDailySignOffReportFilterProps {
  className?: ClassName;
}

export enum DailySignOffIssueStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum DailySignOffIssueType {
  ALL_ISSUE_TYPES = 'all-issue-types',
  TIME_CORRECT = 'time-correct',
  BREAK_COMPLIANCE = 'break-compliance',
  INJURED = 'injured',
  OTHER_FLAGS = 'other-flags',
}

export enum DailySignOffReportStatus {
  SIGNED = 'signed',
  NOT_SIGNED = 'not-signed',
}

export interface IDailySignOffReportFilterData {
  startDate: DateTime;
  endDate: DateTime;
  memberId: string | null;
  memberGroupId: string | null;
  positionId: string | null;
  signatureStatus: DailySignOffReportStatus | null;
  issueStatus: DailySignOffIssueStatus | null;
  issueType: DailySignOffIssueType | null;
  dateRangeType: TimeRangeType;
}

const DailySignOffReportFilter: FunctionComponent<IDailySignOffReportFilterProps> = (props) => {
  const { className } = props;
  const updateSettings = useDailySignOffSettingsUpdate();
  const memberSettings = useMemberSettings();

  const classes = classNames('daily-sign-off-report-filter p-5', className);
  const organization = useOrganization();
  const isCustomSignOffEnabled = useCustomSignOffEnabled();

  const dateRangeOptions = useRef([
    { name: t('Daily'), id: TimeRangeType.DAILY },
    { name: t('Pay Period'), id: TimeRangeType.PAY_PERIOD },
  ]);

  const signatureOptions = useRef([
    {
      name: t('Signed'),
      id: DailySignOffReportStatus.SIGNED,
    },
    {
      name: t('Not Signed'),
      id: DailySignOffReportStatus.NOT_SIGNED,
    },
  ]);

  const issueOptions = useRef([
    {
      name: t('Open'),
      id: DailySignOffIssueStatus.OPEN,
    },
    {
      name: t('Closed'),
      id: DailySignOffIssueStatus.CLOSED,
    },
  ]);

  const today = useRef<DateTime>(DateTime.local().startOf('day'));
  const { getParam, updateParams } = useQueryParams();
  const startDate = getParam(QueryParam.START_DATE);
  const endDate = getParam(QueryParam.END_DATE);
  const currentStartDate = useRef<DateTime>(
    startDate !== null ? DateTime.fromFormat(startDate!, 'M-d-yyyy').startOf('day') : today.current
  );
  const currentEndDate = useRef<DateTime>(
    endDate !== null ? DateTime.fromFormat(endDate!, 'M-d-yyyy').endOf('day') : today.current.endOf('day')
  );
  const payPeriodRange = useTimeRange(TimeRangeType.PAY_PERIOD);
  const currentMemberId = getParam(QueryParam.EMPLOYEE_ID);
  const currentMemberGroupId = getParam(QueryParam.EMPLOYEE_GROUP_ID);
  const currentPositionId = getParam(QueryParam.POSITION_ID);
  const currentIssueType = getParam<DailySignOffIssueType | null>(QueryParam.FILTER_TYPE);
  const currentIssueStatus = getParam<DailySignOffIssueStatus | null>(QueryParam.FILTER_STATUS);
  const currentStatus = getParam<DailySignOffReportStatus | null>(QueryParam.SIGNATURE_STATUS);
  const currentDateRangeType =
    getParam<TimeRangeType>(QueryParam.TIME_RANGE_TYPE) ??
    memberSettings?.web?.features?.dailySignOff?.timeRangeType ??
    TimeRangeType.DAILY;

  const dailySignOffResolutionEnabled = useFeatureFlags('DAILY_SIGN_OFF_RESOLUTION');

  useEffect(() => {
    if (startDate || endDate) {
      currentStartDate.current = DateTime.fromFormat(startDate!, 'M-d-yyyy').startOf('day');
      currentEndDate.current = DateTime.fromFormat(endDate!, 'M-d-yyyy').endOf('day');
      setData((currentData) => {
        return {
          ...currentData,
          startDate: currentStartDate.current,
          endDate: currentEndDate.current,
        };
      });
    }
  }, [startDate, endDate]);

  const [data, setData] = useState<IDailySignOffReportFilterData>({
    signatureStatus: currentStatus,
    startDate: currentStartDate.current,
    endDate: currentEndDate.current,
    memberId: currentMemberId,
    memberGroupId: currentMemberGroupId,
    positionId: currentPositionId,
    issueStatus: currentIssueStatus,
    issueType: currentIssueType,
    dateRangeType: currentDateRangeType,
  });

  const dateRangePicker = () => {
    return (
      <BasicNonNullPicker
        onSelect={(dateRangeType: TimeRangeType) => {
          const startDate =
            dateRangeType === TimeRangeType.PAY_PERIOD ? payPeriodRange.timeRange.startTime : today.current;
          const endDate = dateRangeType === TimeRangeType.PAY_PERIOD ? payPeriodRange.timeRange.endTime : today.current;
          const signatureStatus = dateRangeType === TimeRangeType.PAY_PERIOD ? null : data.signatureStatus; //temporarily disable signature status on pay periods
          setData({ ...data, dateRangeType, startDate, endDate, signatureStatus });
        }}
        value={data.dateRangeType}
        data={dateRangeOptions.current}
      />
    );
  };

  const issueStatusPicker = () => {
    return (
      <Row className="mt-5">
        <Col>
          <BasicPicker
            data={issueOptions.current}
            onSelect={(issueStatus) => {
              setData({ ...data, issueStatus });
            }}
            value={data.issueStatus}
            className="status-selector"
            placeholder={t('Issue Status')}
          />
        </Col>
      </Row>
    );
  };

  const issueTypePicker = () => {
    return (
      <Row className="mt-5">
        <Col>
          <BasicPicker
            data={issueTypeOptions()}
            onSelect={(issueType) => {
              setData({ ...data, issueType });
            }}
            value={data.issueType}
            className="status-selector"
            placeholder={t('Issue Type')}
          />
        </Col>
      </Row>
    );
  };

  const signatureStatusPicker = () => {
    return (
      <Row className="mt-5">
        <Col>
          <BasicPicker
            data={signatureOptions.current}
            onSelect={(signatureStatus) => {
              setData({ ...data, signatureStatus });
            }}
            value={data.signatureStatus}
            className="status-selector"
            placeholder={t('Signature Status')}
          />
        </Col>
      </Row>
    );
  };

  const issueTypeOptions = () => {
    const options = [
      {
        name: t('All'),
        id: DailySignOffIssueType.ALL_ISSUE_TYPES,
      },
    ];
    if (organization.timeAccuracy) {
      options.push({
        name: t('Time Incorrect'),
        id: DailySignOffIssueType.TIME_CORRECT,
      });
    }
    if (organization.breakPolicy) {
      options.push({
        name: t('Not Break Compliant'),
        id: DailySignOffIssueType.BREAK_COMPLIANCE,
      });
    }
    if (organization.safetySignature) {
      options.push({
        name: t('Injured'),
        id: DailySignOffIssueType.INJURED,
      });
    }
    if (isCustomSignOffEnabled) {
      options.push({
        name: 'Other Flags',
        id: DailySignOffIssueType.OTHER_FLAGS,
      });
    }
    return options;
  };

  function handleApply() {
    updateParams({
      [QueryParam.START_DATE]: data.startDate.toFormat('M-d-yyyy') ?? today.current,
      [QueryParam.END_DATE]: data.endDate.toFormat('M-d-yyyy') ?? today.current,
      [QueryParam.FILTER_TYPE]: data.issueType ?? null,
      [QueryParam.EMPLOYEE_ID]: data.memberId ?? null,
      [QueryParam.EMPLOYEE_GROUP_ID]: data.memberGroupId ?? null,
      [QueryParam.POSITION_ID]: data.positionId ?? null,
      [QueryParam.FILTER_STATUS]: data.issueStatus ?? null,
      [QueryParam.SIGNATURE_STATUS]: data.signatureStatus ?? null,
      [QueryParam.TIME_RANGE_TYPE]: data.dateRangeType ?? null,
    });
    currentStartDate.current = data.startDate;
    currentEndDate.current = data.endDate;
    updateSettings([{ key: 'timeRangeType', payload: data.dateRangeType }]);
  }

  function handleReset() {
    setData({
      startDate: today.current,
      endDate: today.current,
      memberId: null,
      memberGroupId: null,
      positionId: null,
      signatureStatus: null,
      issueStatus: null,
      issueType: null,
      dateRangeType: TimeRangeType.DAILY,
    });

    updateParams({
      [QueryParam.START_DATE]: today.current.toFormat('M-d-yyyy'),
      [QueryParam.END_DATE]: today.current.toFormat('M-d-yyyy'),
      [QueryParam.FILTER_TYPE]: null,
      [QueryParam.EMPLOYEE_ID]: null,
      [QueryParam.EMPLOYEE_GROUP_ID]: null,
      [QueryParam.POSITION_ID]: null,
      [QueryParam.FILTER_STATUS]: null,
      [QueryParam.SIGNATURE_STATUS]: null,
      [QueryParam.TIME_RANGE_TYPE]: TimeRangeType.DAILY,
    });
    currentStartDate.current = today.current;
    currentEndDate.current = today.current;
  }

  function hasChanged() {
    return (
      currentStartDate.current !== data.startDate ||
      currentEndDate.current !== data.endDate ||
      currentMemberId !== data.memberId ||
      currentMemberGroupId !== data.memberGroupId ||
      currentPositionId !== data.positionId ||
      currentStatus !== data.signatureStatus ||
      currentIssueType !== data.issueType ||
      currentIssueStatus !== data.issueStatus ||
      currentDateRangeType !== data.dateRangeType
    );
  }

  function anyFilterSet() {
    return _.some(
      Object.keys(data).filter((k) => k !== 'startDate' && k !== 'endDate' && k !== 'dateRangeType'),
      (key: keyof IDailySignOffReportFilterData) => data[key] !== null
    );
  }

  return (
    <SidePanelFilter
      onApply={handleApply}
      onClear={handleReset}
      appliable={hasChanged()}
      clearable={anyFilterSet()}
      className={classes}
    >
      <PanelContent flex={false}>
        <h3>{t('Filter')}</h3>
        <div className="py-5">
          {dateRangePicker()}

          <EmployeePicker
            onSelect={(memberId) => {
              setData({ ...data, memberId });
            }}
            value={data.memberId}
            placeholder={t('Employee')}
            permissions={{
              permissions: [MemberPermission.TIME_EVENTS],
              operationType: OperationType.AND,
            }}
            position={Position.BOTTOM_END}
            className="mt-5"
          />
          <EmployeeGroupPicker
            onSelect={(memberGroupId) => {
              setData({ ...data, memberGroupId });
            }}
            value={data.memberGroupId}
            placeholder={t('Employee Group')}
            position={Position.BOTTOM_END}
            className="mt-5"
            filterByPermission={true}
          />
          <AutoHidePositionPicker
            className="mt-5"
            value={data.positionId}
            placeholder={t('Position')}
            onSelect={(positionId) => {
              setData({ ...data, positionId });
            }}
            permissions="timeEvents"
          />
          {dailySignOffResolutionEnabled && issueStatusPicker()}
          {dailySignOffResolutionEnabled && issueTypePicker()}
          {signatureStatusPicker()}
        </div>
      </PanelContent>
    </SidePanelFilter>
  );
};

export default DailySignOffReportFilter;
