import { useApolloClient } from '@apollo/client';
import { Col, Container, DialogHeader, Divider, Label, Link, Row, Size } from '@busybusy/webapp-react-ui';
import { COST_CODES_QUERY } from 'apollo/queries/cost-code-queries';
import { EQUIPMENT_QUERY } from 'apollo/queries/equipment-queries';
import { MEMBER_LOCATION_QUERY } from 'apollo/queries/member-location-queries';
import { PROJECT_QUERY } from 'apollo/queries/project-queries';
import { TIME_ENTRY_BREAK_LOGS_QUERY } from 'apollo/queries/time-entry-break-log-queries';
import { GpsIcon } from 'assets/icons';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Spinner } from 'components';
import SimpleMapDialog from 'components/domain/map/SimpleMapDialog/SimpleMapDialog';
import TimeEntryViewBreak from 'components/domain/time-entry-break/TimeEntryViewBreak/TimeEntryViewBreak';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import TimeAndSuperscript from 'components/foundation/text/TimeAndSuperscript/TimeAndSuperscript';
import { useOpenable, useOrganization } from 'hooks';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import _, { isEmpty, isEqual, isNil, xor } from 'lodash';
import { ReactNode, useState } from 'react';
import IClientTimeEntryBreakLog from 'types/ClientTimeEntryBreakLog';
import IClientTimeEntryLog from 'types/ClientTimeEntryLog';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import ILocation from 'types/Location';
import IMemberLocation from 'types/MemberLocation';
import IProject from 'types/Project';
import ITimeEntry from 'types/TimeEntry';
import ITimeEntryBreak from 'types/TimeEntryBreak';
import BreakActionType from 'types/enum/BreakActionType';
import ClockActionType from 'types/enum/ClockActionType';
import { memberLocationsUtils, memberUtils, projectUtils, stringUtils, timeEntryLogUtils } from 'utils';
import DateTimeFormat from 'utils/constants/dateTimeFormat';
import { dateTimeFromISOKeepZone, dateTimeFromISOWithoutZone, dateTimeFromUtcISO } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getAppropriateLocationId } from 'utils/timeEntryLogUtils';
import { displayEndTime } from 'utils/timeEntryUtils';
import './TimeEntryLogCell.scss';

export interface ITimeEntryLogCellProps {
  className?: ClassName;
  timeEntry: ITimeEntry;
  timeEntryLog: IClientTimeEntryLog;
  isShowDetails: boolean;
}

interface ITimeEntryLogState {
  startTime?: string;
  startDST?: boolean;
  endTime?: string;
  endDST?: boolean;
  project?: IProject;
  costCode?: ICostCode;
  equipment?: IEquipment;
  description?: string;
  breakLogs: IClientTimeEntryBreakLog[];
}

const TimeEntryLogCell = ({ className, timeEntry, timeEntryLog, isShowDetails }: ITimeEntryLogCellProps) => {
  const classes = classNames('column', className);

  const [showDetails, setShowDetails] = useState(isShowDetails);
  const organization = useOrganization();
  const mapDetails = useOpenable();
  const [mapLocation, setMapLocation] = useState<ILocation>();
  const client = useApolloClient();

  const [currentState, setCurrentState] = useState<ITimeEntryLogState>({ breakLogs: [] });
  const [previousState, setPreviousState] = useState<ITimeEntryLogState>({ breakLogs: [] });
  const loaderDetails = useOpenable();

  useOnMount(() => {
    getLogs();
  });

  async function getLogs() {
    loaderDetails.open();
    if (timeEntryLog && timeEntryLog.actionType) {
      const actionType = timeEntryLog.actionType;
      let previousLog: IClientTimeEntryLog | undefined;

      if (
        actionType &&
        (actionType === ClockActionType.EDIT_BREAKS ||
          actionType === ClockActionType.EDIT ||
          actionType === ClockActionType.CLOCK_OUT_AT)
      ) {
        previousLog = _.last(
          _.sortBy(
            timeEntry.clientLogs.filter((LOG) => {
              return (
                LOG.originalTimeEntryId === timeEntryLog.originalTimeEntryId &&
                LOG.actionType !== ClockActionType.EDIT_BREAKS &&
                LOG.createdOn < timeEntryLog.createdOn
              );
            }),
            'createdOn'
          )
        );
      }

      const curState: ITimeEntryLogState = { breakLogs: [] };
      const prevState: ITimeEntryLogState = { breakLogs: [] };

      if (previousLog) {
        if (previousLog.projectId) {
          const project = await getProject(previousLog.projectId);
          prevState.project = project;
        }

        if (previousLog.costCodeId) {
          const costCode = await getCostCode(previousLog.costCodeId);
          prevState.costCode = costCode;
        }

        if (previousLog.equipmentId) {
          const equipment = await getEquipment(previousLog.equipmentId);
          prevState.equipment = equipment;
        }

        if (previousLog.description) {
          prevState.description = previousLog.description;
        }

        if (previousLog.startTime) {
          prevState.startTime = previousLog.startTime;
        }

        if (previousLog.daylightSavingTime) {
          prevState.startDST = previousLog.daylightSavingTime;
        }

        if (previousLog.metaDaylightSavingTime) {
          prevState.endDST = previousLog.metaDaylightSavingTime;
        }

        if (previousLog.endTime) {
          prevState.endTime = previousLog.endTime;
        }
      }

      if (timeEntryLog.projectId) {
        const project = await getProject(timeEntryLog.projectId);
        curState.project = project;
      }

      if (timeEntryLog.costCodeId) {
        const costCode = await getCostCode(timeEntryLog.costCodeId);
        curState.costCode = costCode;
      }

      if (timeEntryLog.equipmentId) {
        const equipment = await getEquipment(timeEntryLog.equipmentId);
        curState.equipment = equipment;
      }

      if (timeEntryLog.description) {
        curState.description = timeEntryLog.description;
      }

      if (timeEntryLog.startTime) {
        curState.startTime = timeEntryLog.startTime;
      }

      if (timeEntryLog.daylightSavingTime) {
        curState.startDST = timeEntryLog.daylightSavingTime;
      }

      if (timeEntryLog.metaDaylightSavingTime) {
        curState.endDST = timeEntryLog.metaDaylightSavingTime;
      }

      if (timeEntryLog.endTime) {
        curState.endTime = timeEntryLog.endTime;
      }

      if (_.isEmpty(currentState.breakLogs)) {
        const breakLogs = await getBreaks(timeEntryLog.id);
        if (!isEmpty(breakLogs)) {
          curState.breakLogs = breakLogs;
          prevState.breakLogs = await getPreviousBreakLogs(timeEntryLog, breakLogs ?? []);
        }
      }

      setPreviousState(prevState);
      setCurrentState(curState);
      loaderDetails.close();
    } else {
      loaderDetails.close();
    }
  }

  async function getPreviousBreakLogs(
    currentLog: IClientTimeEntryLog,
    logs: IClientTimeEntryBreakLog[]
  ): Promise<IClientTimeEntryBreakLog[]> {
    const results = await client.query<{ clientTimeEntryBreakLogs: IClientTimeEntryBreakLog[] }>({
      query: TIME_ENTRY_BREAK_LOGS_QUERY,
      variables: {
        first: 1,
        filter: {
          timeEntryBreakId: { contains: logs.map((l) => l.timeEntryBreakId) },
          endTime: { isNull: false },
          createdOn: { lessThan: currentLog.createdOn },
        },
        sort: [{ createdOn: 'desc' }],
      },
    });

    return results.data.clientTimeEntryBreakLogs ?? [];
  }

  async function getLocation(memberLocationId: string): Promise<IMemberLocation[]> {
    const results = await client.query<{ memberLocations: IMemberLocation[] }>({
      query: MEMBER_LOCATION_QUERY,
      variables: {
        memberLocationId,
      },
    });
    return results.data.memberLocations;
  }

  async function getProject(projectId: string): Promise<IProject> {
    const results = await client.query<{ projects: IProject[] }>({
      query: PROJECT_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: projectId },
        },
      },
    });
    return results.data.projects[0];
  }

  async function getCostCode(costCodeId: string): Promise<ICostCode> {
    const results = await client.query<{ costCodes: ICostCode[] }>({
      query: COST_CODES_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: costCodeId },
        },
      },
    });
    return results.data.costCodes[0];
  }

  async function getEquipment(equipmentId: string) {
    const results = await client.query<{ equipment: IEquipment[] }>({
      query: EQUIPMENT_QUERY,
      variables: {
        first: 1,
        filter: {
          id: { equal: equipmentId },
        },
      },
    });
    return results.data.equipment[0];
  }

  async function getBreaks(logId: string) {
    const results = await client.query<{ clientTimeEntryBreakLogs: IClientTimeEntryBreakLog[] }>({
      query: TIME_ENTRY_BREAK_LOGS_QUERY,
      variables: {
        first: 100,
        filter: {
          clientTimeEntryLogId: { equal: logId },
        },
        sort: [{ startTime: 'asc' }],
      },
    });

    return results.data.clientTimeEntryBreakLogs ?? [];
  }

  function renderShowDetailsView() {
    if (showDetails === false) {
      if (timeEntryLog && timeEntryLog.actionType) {
        const actionType = timeEntryLog.actionType;

        switch (actionType) {
          case ClockActionType.CLOCK_IN:
          case ClockActionType.CLOCK_IN_LOCATION:
          case ClockActionType.CLOCK_IN_AT:
          case ClockActionType.CLOCK_OUT_AT:
          case ClockActionType.MANUAL:
            return <div className="pt-4">{renderDetails(t('DETAILS'), false)}</div>;
          case ClockActionType.CONFLICT_RESOLUTION:
            return <div className="pt-4">{renderConflicts(timeEntryLog)}</div>;
          default:
            return (
              <div className="pt-4">
                {renderDetails(t('FROM'), true)}
                {renderDetails(t('TO'), false)}
              </div>
            );
        }
      }
    }
    return undefined;
  }

  function handleClick() {
    setShowDetails(!showDetails);
  }

  function renderDetails(title: string, usePreviousForRender: boolean) {
    let previousStartTime;
    if (previousState.startTime) {
      previousStartTime = dateTimeFromISOWithoutZone(previousState.startTime);
    }
    let currentStartTime;
    if (currentState.startTime) {
      currentStartTime = dateTimeFromISOWithoutZone(currentState.startTime);
    }

    let previousEndTime;
    if (previousState.endTime) {
      previousEndTime = dateTimeFromISOWithoutZone(previousState.endTime);
    }
    let currentEndTime;
    if (currentState.endTime) {
      currentEndTime = dateTimeFromISOWithoutZone(currentState.endTime);
    }

    return (
      <div>
        <h4>{title}</h4>
        <div className="details">
          <div className="detailsContents p-6">
            {previousStartTime?.toSeconds() !== currentStartTime?.toSeconds()
              ? renderDetailsStart(usePreviousForRender)
              : undefined}
            {previousEndTime?.toSeconds() !== currentEndTime?.toSeconds()
              ? renderDetailsEnd(usePreviousForRender)
              : undefined}
            {!_.isEmpty(previousState.breakLogs) || !_.isEmpty(currentState.breakLogs)
              ? renderDetailsBreaks(usePreviousForRender)
              : undefined}
            {previousState.project !== currentState.project ? renderDetailsProject(usePreviousForRender) : undefined}
            {previousState.costCode !== currentState.costCode ? renderDetailsCostCode(usePreviousForRender) : undefined}
            {previousState.equipment !== currentState.equipment
              ? renderDetailsEquipment(usePreviousForRender)
              : undefined}
            {previousState.description !== currentState.description
              ? renderDetailsDescription(usePreviousForRender)
              : undefined}
          </div>
        </div>
      </div>
    );
  }

  function renderConflicts(currentLog: IClientTimeEntryLog) {
    const timeCardOneLog = _.first(
      currentLog?.timeEntry?.clientLogs.filter((LOG) => {
        return (
          LOG.originalTimeEntryId === currentLog.originalTimeEntryId ||
          (LOG.originalTimeEntryId === null &&
            currentLog.originalTimeEntryId === null &&
            LOG.createdOn < currentLog.createdOn)
        );
      })
    );
    const timeCardTwoLog = _.first(
      currentLog?.timeEntry?.clientLogs.filter((LOG) => {
        return (
          LOG.mergedTimeEntryId === currentLog.mergedTimeEntryId &&
          LOG.actionType !== ClockActionType.DELETE &&
          LOG.createdOn < currentLog.createdOn
        );
      })
    );

    const timeCardOneBreakLogs: IClientTimeEntryBreakLog[] = [];
    const timeCardTwoBreakLogs: IClientTimeEntryBreakLog[] = [];
    const resultBreakLogs: IClientTimeEntryBreakLog[] = [];
    const breakLogs = timeEntryLogUtils.getBreakLogs(currentLog, timeEntry.breaks);
    if (breakLogs) {
      for (const breakLog of breakLogs) {
        if (breakLog.timeEntryId !== timeCardOneLog?.timeEntryId) {
          timeCardTwoBreakLogs.push(breakLog);
        } else if (breakLog.timeEntryId !== timeCardTwoLog?.timeEntryId) {
          timeCardOneBreakLogs.push(breakLog);
        }

        if (breakLog.timeEntryBreakDeletedOn === null) {
          resultBreakLogs.push(breakLog);
        }
      }
    }

    return (
      <div>
        {timeCardOneLog && renderConflictDetails(t('TIMECARD 1'), timeCardOneLog, timeCardOneBreakLogs)}
        {timeCardTwoLog && renderConflictDetails(t('TIMECARD 2'), timeCardTwoLog, timeCardTwoBreakLogs)}
        {currentLog && renderConflictDetails(t('RESULT'), currentLog, resultBreakLogs)}
      </div>
    );
  }

  function renderConflictDetails(title: string, entryLog: IClientTimeEntryLog, breaksLogs: IClientTimeEntryBreakLog[]) {
    return (
      <div>
        <h4>{title}</h4>
        <div className="details p-6">
          <div className="detailsContents">
            {entryLog.startTime ? renderDetailsStart(false) : undefined}
            {entryLog.endTime ? renderDetailsEnd(false) : undefined}
            {!_.isEmpty(breaksLogs) ? renderDetailsBreaks(false) : undefined}
            {entryLog.projectId ? renderDetailsProject(false) : undefined}
            {entryLog.equipmentId ? renderDetailsEquipment(false) : undefined}
            {entryLog.costCodeId ? renderDetailsCostCode(false) : undefined}
            {entryLog.description ? renderDetailsDescription(false) : undefined}
          </div>
        </div>
      </div>
    );
  }

  function renderDetailsStart(usePreviousForRender: boolean) {
    let startTime;
    let isDST;
    if (usePreviousForRender) {
      if (!isNil(previousState.startTime)) {
        startTime = dateTimeFromISOKeepZone(previousState.startTime);
        isDST = previousState.startDST;
      }
    } else {
      if (!isNil(currentState.startTime)) {
        startTime = dateTimeFromISOKeepZone(currentState.startTime);
        isDST = currentState.startDST;
      }
    }
    return (
      <div className="pb-4">
        <h2>{t('Start')}</h2>
        {startTime ? (
          <div>
            <TimeAndSuperscript dateTime={startTime} dst={isDST ?? false} />
            <div>{startTime.toFormat('EEE, MMM d')}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function renderDetailsEnd(usePreviousForRender: boolean) {
    let endTime;
    let isDST;
    if (usePreviousForRender) {
      if (!isNil(previousState.endTime)) {
        endTime = dateTimeFromISOKeepZone(previousState.endTime);
        isDST = previousState.endDST;
      }
    } else {
      if (!isNil(currentState.endTime)) {
        endTime = dateTimeFromISOKeepZone(currentState.endTime);
        isDST = currentState.endDST;
      }
    }
    return (
      <div className="pb-4">
        <h2>{t('End')}</h2>
        {endTime ? (
          <div>
            <TimeAndSuperscript dateTime={endTime} dst={isDST ?? false} />
            <div>{endTime.toFormat('EEE, MMM d')}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function getShellBreak(log: IClientTimeEntryBreakLog): ITimeEntryBreak {
    return {
      startTime: log.startTime,
      startDeviceTime: log.startDeviceTime,
      startTimeTrusted: log.startTimeTrusted,
      startTimeDst: log.startTimeDst,
      endTime: log.endTime,
      endDeviceTime: log.endDeviceTime,
      endTimeTrusted: log.endTimeTrusted,
      endTimeDst: log.endTimeDst,
      id: log.id,
      timeEntry: log.timeEntryBreak.timeEntry,
      createdOn: log.createdOn,
      deletedOn: log.timeEntryBreakDeletedOn === undefined ? null : log.timeEntryBreakDeletedOn,
      submittedOn: log.submittedOn,
      updatedOn: log.updatedOn,
      clientLogs: [],
    };
  }

  function renderDetailsBreaks(usePreviousForRender: boolean) {
    const breakLogs = usePreviousForRender ? previousState.breakLogs : currentState.breakLogs;

    return (
      <div className="pb-4">
        <h2>{t('Breaks')}</h2>
        {!isEmpty(breakLogs) ? (
          <div>
            {breakLogs.map((item: IClientTimeEntryBreakLog, index: number) => {
              return (
                <div key={index} className="'col-value'">
                  {item.timeEntryBreakDeletedOn === null ? (
                    <TimeEntryViewBreak entryBreak={getShellBreak(item)} timeEntry={getShellBreak(item).timeEntry} />
                  ) : (
                    <div className="col-value">---</div>
                  )}
                </div>
              );
            })}
            {isEmpty(usePreviousForRender ? previousState.breakLogs : currentState.breakLogs) && (
              <div className="col-value">---</div>
            )}
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function renderDetailsProject(usePreviousForRender: boolean) {
    const project = usePreviousForRender ? previousState.project : currentState.project;
    return (
      <div className="pb-4">
        <h2>{t('Project')}</h2>
        {organization && organization.trackProject && !isNil(project) ? (
          <div>
            <div>{projectUtils.getFormattedPathFromProject(project, true)}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function renderDetailsCostCode(usePreviousForRender: boolean) {
    const costCode = usePreviousForRender ? previousState.costCode : currentState.costCode;
    return (
      <div className="pb-4">
        <h2>{t('Cost Code')}</h2>
        {organization && organization.trackCostCode && !isNil(costCode) ? (
          <div>
            <div>{stringUtils.getCostCodeDisplay(costCode)}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function renderDetailsEquipment(usePreviousForRender: boolean) {
    const equipment = usePreviousForRender ? previousState.equipment : currentState.equipment;
    return (
      <div className="pb-4">
        <h2>{t('Equipment')}</h2>
        {organization && organization.trackEquipment && !isNil(equipment) ? (
          <div>
            <div>{stringUtils.getEquipmentDisplay(equipment)}</div>
          </div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function renderDetailsDescription(usePreviousForRender: boolean) {
    const description = usePreviousForRender ? previousState.description : currentState.description;
    return (
      <div className="pb-4">
        <h2>{t('Description')}</h2>
        {!isNil(description) ? (
          <div className="entry-description">{description}</div>
        ) : (
          <div className="col-value">---</div>
        )}
      </div>
    );
  }

  function renderCellTitle(title: string) {
    return (
      <div>
        <h2>{title}</h2>
      </div>
    );
  }

  function renderCellGPSIcon() {
    const actionType = timeEntryLog.actionType;

    const locationId = getAppropriateLocationId(
      actionType,
      timeEntryLog.startLocationId,
      timeEntryLog.endLocationId,
      currentState.breakLogs
    );

    function handleGPSAction() {
      if (timeEntryLog && timeEntryLog.actionType) {
        if (locationId) {
          getLocation(locationId).then((results) => {
            const location = results[0];
            const locationType = memberLocationsUtils.getType(timeEntry, location);
            setMapLocation({
              id: location.id,
              lat: location.latitude,
              lng: location.longitude,
              icon: memberLocationsUtils.getTypeIcon(locationType),
              infoWindowTitle: memberLocationsUtils.getTypeTitle(locationType),
              infoWindowBody: getInfoWindowBody(location),
            });
            mapDetails.open();
          });
        }
      }
    }

    function getInfoWindowBody(location: IMemberLocation) {
      const body: ReactNode[] = [
        <p className="pl-5" key={location.id}>
          <TimeAndSuperscript
            dateTime={dateTimeFromISOKeepZone(location.timeLocal)}
            dst={location.timeDst}
            format={DateTimeFormat.dateAtTime}
          />
        </p>,
      ];
      return body;
    }

    if (timeEntryLog && timeEntryLog.actionType !== ClockActionType.EDIT && locationId) {
      return <IconButton className="cellIcon" size={Size.MEDIUM} onClick={handleGPSAction} svg={GpsIcon} />;
    }
  }

  function renderRecordedLabel() {
    if (timeEntryLog && timeEntryLog.createdOn) {
      const recordedDate = dateTimeFromUtcISO(timeEntryLog.createdOn).setZone(
        dateTimeFromISOKeepZone(timeEntryLog.startTime).zoneName
      );
      return (
        <Label>
          {t('Recorded')}: {recordedDate.toFormat('h:mm a')} {recordedDate.toFormat('MM/d/yy')}
        </Label>
      );
    }
  }

  function renderByLabel() {
    return (
      <Label>
        {t('By')}: {timeEntryLog && timeEntryLog.updaterMember ? memberUtils.fullName(timeEntryLog.updaterMember) : ''}
      </Label>
    );
  }

  function renderDeviceLabel() {
    return (
      <Label>
        {t('Device')}: {timeEntryLog && timeEntryLog.deviceType}
      </Label>
    );
  }

  function showDetailsButton() {
    if (timeEntryLog) {
      if (
        timeEntryLog.actionType === ClockActionType.CLOCK_OUT ||
        timeEntryLog.actionType === ClockActionType.CLOCK_OUT_LOCATION ||
        timeEntryLog.actionType === ClockActionType.SYSTEM_CLOCK_OUT ||
        timeEntryLog.actionType === ClockActionType.DELETE
      ) {
        // do nothing like that guy PJ - TRUE
        return undefined;
      }

      if (timeEntryLog.actionType === ClockActionType.EDIT_BREAKS) {
        const breakLogs = currentState.breakLogs;
        if (breakLogs) {
          for (const breakLog of breakLogs) {
            const type = breakLog.actionType;
            switch (type) {
              case BreakActionType.BREAK_START:
              case BreakActionType.BREAK_START_LOCATION:
              case BreakActionType.BREAK_END:
              case BreakActionType.BREAK_END_LOCATION:
                // do nothing like that guy PJ
                return undefined;
            }
          }
        }
      }

      if (isEqual(currentState, previousState) && xor(currentState.breakLogs, previousState.breakLogs).length === 0) {
        return undefined;
      }
      // details / conflicts show details button
      return (
        <div className="pb-4">
          <Link href={'#'} onClick={() => handleClick()}>
            {showDetails === false ? t('Hide Details') : t('Show Details')}
          </Link>
          {renderShowDetailsView()}
        </div>
      );
    }
  }

  function handleMapClose() {
    setMapLocation(undefined);
    mapDetails.close();
  }

  function renderTimersColumn(timeEntryLog: IClientTimeEntryLog) {
    const actionType = timeEntryLog.actionType;
    switch (actionType) {
      case ClockActionType.CLOCK_IN:
      case ClockActionType.CLOCK_IN_LOCATION:
      case ClockActionType.CLOCK_IN_AT: {
        const startTime = dateTimeFromISOKeepZone(timeEntryLog.startTime);
        return (
          <h2>
            <TimeAndSuperscript dateTime={startTime} dst={timeEntryLog!.daylightSavingTime} />
          </h2>
        );
      }
      case ClockActionType.CLOCK_OUT:
      case ClockActionType.CLOCK_OUT_LOCATION:
      case ClockActionType.CLOCK_OUT_AT: {
        const endTime = displayEndTime(timeEntryLog);
        if (endTime) {
          return (
            <h2>
              <TimeAndSuperscript dateTime={endTime} dst={timeEntryLog!.metaDaylightSavingTime ?? false} />
            </h2>
          );
        }
        break;
      }
      case ClockActionType.EDIT_BREAKS: {
        const breakLogs = currentState.breakLogs;
        if (breakLogs) {
          for (const breakLog of breakLogs) {
            const type = breakLog.actionType;
            switch (type) {
              case BreakActionType.BREAK_START:
              case BreakActionType.BREAK_START_LOCATION: {
                const breakStartTime = dateTimeFromISOKeepZone(breakLog.startTime);
                return (
                  <h2>
                    <TimeAndSuperscript dateTime={breakStartTime} dst={breakLog!.startTimeDst} />
                  </h2>
                );
              }
              case BreakActionType.BREAK_END:
              case BreakActionType.BREAK_END_LOCATION:
              case BreakActionType.SYSTEM_BREAK_END:
                if (breakLog.endTime) {
                  const breakEndTime = dateTimeFromISOKeepZone(breakLog.endTime);
                  return (
                    <h2>
                      <TimeAndSuperscript dateTime={breakEndTime} dst={breakLog!.endTimeDst ?? false} />
                    </h2>
                  );
                }
                break;
            }
          }
        }
        break;
      }
      case ClockActionType.EDIT: {
        const editDetails: string[] = [];
        if (currentState.startTime !== previousState.startTime) {
          editDetails.push(t('Start Time'));
        }
        if (currentState.endTime !== previousState.endTime) {
          editDetails.push(t('End Time'));
        }
        if (currentState.project !== previousState.project) {
          editDetails.push(t('Project'));
        }
        if (currentState.costCode !== previousState.costCode) {
          editDetails.push(t('Cost Code'));
        }
        if (currentState.equipment !== previousState.equipment) {
          editDetails.push(t('Equipment'));
        }
        if (currentState.description !== previousState.description) {
          editDetails.push(t('Description'));
        }
        if (xor(currentState.breakLogs, previousState.breakLogs).length !== 0) {
          editDetails.push(t('Breaks'));
        }
        return <h2>{isEmpty(editDetails) ? null : editDetails.join(', ')}</h2>;
      }
    }

    return null;
  }

  function renderCell() {
    if (timeEntryLog && !loaderDetails.isOpen) {
      return (
        <div>
          <DialogHeader size={Size.MEDIUM}>
            {renderCellTitle(
              timeEntryLogUtils.getClockActionTitle(timeEntryLog, currentState.breakLogs, timeEntry.breaks)
            )}
            {renderCellGPSIcon()}
          </DialogHeader>
          <Container className={classes}>
            {renderTimersColumn(timeEntryLog)}
            <Row>
              <Col>
                {renderRecordedLabel()}
                {renderByLabel()}
                {renderDeviceLabel()}
              </Col>
            </Row>
            {showDetailsButton()}
            <Divider />
          </Container>
          {mapLocation ? (
            <SimpleMapDialog
              isOpen={mapDetails.isOpen}
              onClose={handleMapClose}
              size={Size.LARGE}
              pinLocations={mapLocation ? [mapLocation] : []}
            />
          ) : (
            <></>
          )}
        </div>
      );
    }
    return (
      <Row className="row-justify-center py-8">
        <Spinner />
      </Row>
    );
  }

  return <div>{renderCell()}</div>;
};

TimeEntryLogCell.defaultProps = {
  isShowDetails: true,
};

export default TimeEntryLogCell;
