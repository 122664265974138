import useNavigation from './useNavigation';

interface IParams {
  archived?: boolean;
  employeeId?: string;
  groupId?: string;
  exitToLegacy?: boolean;
}

export default function useEmployeesManagementListNavigation() {
  return useNavigation<IParams>('/manage/employees');
}
