import { Button, Icon, Row, Theme } from '@busybusy/webapp-react-ui';
import { CSVIcon } from 'assets/icons';
import classNames from 'classnames';
import Well from 'components/foundation/Well/Well';
import FileUploadButton from 'components/foundation/buttons/FileUploadButton/FileUploadButton';
import Panel from 'components/layout/Panel/Panel';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import { ClassName } from 'types/ClassName';
import FilePickerType from 'types/enum/FilePickerType';
import { t } from 'utils/localize';
import '../CsvImportDialog/CsvImportDialog.scss';

export interface IReimportContentProps {
  onDownloadList: () => void;
  onUploadList: (file: File) => void;
  identifier: 'employee' | 'cost code' | 'project' | 'equipment';
  prevalidationErrors: string[];
  extraDisclaimer?: string;
  errors: string[] | null;
  className?: ClassName;
}

const ReimportContent: FunctionComponent<IReimportContentProps> = (props) => {
  const {
    identifier,
    errors,
    prevalidationErrors: headerErrors,
    extraDisclaimer,
    onDownloadList,
    onUploadList,
    className,
  } = props;

  const brand = useBrandTitle();
  const classes = classNames('csv-import-content', className);

  function onFileSelect(file: File | undefined) {
    if (!file) {
      return;
    }

    onUploadList(file);
  }

  return (
    <Panel className={classes}>
      <Icon svg={CSVIcon} size={140} />
      <h2>{t('Edit using Spreadsheet')}</h2>
      <div className="grouped-text">
        <p>
          {identifier != 'equipment' ? (
            <Trans values={{ identifier: t(identifier), brand }}>
              {
                'Download the {{ identifier }} list, make edits using your favorite spreadsheet software, then upload the edited list. {{brand}} will then update your {{ identifier }}s based on your changes.'
              }
            </Trans>
          ) : (
            <Trans values={{ identifier: t(identifier), brand }}>
              {
                'Download the {{identifier}} list, make edits using your favorite spreadsheet software, then upload the edited list. {{brand}} will then update your {{ identifier }} based on your changes.'
              }
            </Trans>
          )}
        </p>
      </div>
      <p className="fc-1">
        {`* ${t('Important')}: `}
        {t(
          'Do not edit the values in the column titled "DATA KEY" as this will cause errors when applying the changes.'
        )}
      </p>

      {extraDisclaimer && (
        <p className="fc-1">
          {`* ${t('Important')}: `}
          {extraDisclaimer}
        </p>
      )}

      {errors && errors.length !== 0 && (
        <Well theme={Theme.DANGER} className="mt-3 mx-6">
          {t("We've detected one or more issues. Please fix the following errors and re-upload your file")}:
          <ul className="error-list">
            {errors?.map((error) => (
              <li key={error} className="fc-regular">
                {error}
              </li>
            ))}
          </ul>
        </Well>
      )}

      {headerErrors && headerErrors.length !== 0 && (
        <Well theme={Theme.DANGER} className="mt-3 mx-6">
          {t(
            "We've detected incorrect or missing column names. Please fix the following column errors and re-upload your file"
          )}
          :
          <ul className="error-list">
            {headerErrors.map((error) => (
              <li key={error} className="fc-regular">
                {error}
              </li>
            ))}
          </ul>
        </Well>
      )}

      <Row className="spaced-buttons">
        <Button type="secondary" onClick={onDownloadList}>
          {t('Download List')}
        </Button>
        <FileUploadButton onFileSelection={onFileSelect} accept={[FilePickerType.CSV, FilePickerType.EXCEL]}>
          {t('Upload List')}
        </FileUploadButton>
      </Row>
    </Panel>
  );
};

export default ReimportContent;
