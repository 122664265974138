import { ClassName, Col } from '@busybusy/webapp-react-ui';
import TableSpinner from 'components/foundation/table/TableSpinner/TableSpinner';
import { ReactNode } from 'react';

interface ContainerSpinnerProps {
  isLoading: boolean;
  children: ReactNode;
  className?: ClassName;
}

const ContainerWithSpinner = (props: ContainerSpinnerProps) => {
  const { className, isLoading, children } = props;

  return (
    <Col className={className}>
      <TableSpinner isVisible={isLoading} />
      {!isLoading ? children : null}
    </Col>
  );
};

export default ContainerWithSpinner;
