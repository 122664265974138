import { Checkbox, Divider, Label, Position, Radio, RadioGroup, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import {
  CostCodeGroupPicker,
  CostCodePicker,
  EmployeeGroupPicker,
  EmployeePicker,
  EquipmentPicker,
  ProjectGroupPicker,
  ProjectPicker,
} from 'components';
import ArchivedPicker, { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import AutoHidePositionPicker from 'components/domain/position/position-picker/AutoHidePositionPicker/AutoHidePositionPicker';
import SidePanelFilter from 'components/foundation/FilterSidePanel/FilterSidePanel';
import SidePanelTimeRangeSection from 'components/foundation/SidePanelTimeRangeSection/SidePanelTimeRangeSection';
import BasicNonNullPicker from 'components/foundation/pickers/basic-picker/BasicNonNullPicker/BasicNonNullPicker';
import BasicPicker from 'components/foundation/pickers/basic-picker/BasicPicker/BasicPicker';
import { useOrganization, useQueryParams, useTimeRange, useTimesheetsGraylog } from 'hooks';
import useTimeCardsSettingsUpdate from 'hooks/models/member-settings/useTimeCardsSettingsUpdate';
import useTimeRangeTypeTimeRangeConverter from 'hooks/utils/useTimeRangeTypeTimeRangeConverter/useTimeRangeTypeTimeRangeConverter';
import _, { cloneDeep, compact, findIndex, isNil, keyBy } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  TimesheetView,
  clearCheckedMemberIds,
  clearCheckedTimeEntryIds,
  clearCheckedTimeOffIds,
} from 'store/timesheets/Timesheets';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import MemberPermission from 'types/enum/MemberPermission';
import OperationType from 'types/enum/OperationType';
import { QueryParam } from 'utils/constants/queryParam';
import { useFeatureFlags } from 'utils/features';
import { parse, stringify } from 'utils/queryStringUtils';
import { TimesheetReportType } from '../Timesheets';
import useTimesheetsQueryParams from '../hooks/useTimesheetsQueryParams';
import './TimesheetsSidePanel.scss';
import {
  ITimeCardReportExpandedSettingData,
  ITimeCardReportExpandedSettings,
  TimeCardReportExpandedBreakFormat,
  TimeCardReportExpandedSettingKeys,
  TimeCardReportExpandedTimeFormat,
} from './hooks/useTimeCardSettings';

export interface ITimesheetsSidePanelProps {
  className?: ClassName;
  timeFormatSettings: TimeCardReportExpandedTimeFormat;
  breakFormatSettings: TimeCardReportExpandedBreakFormat;
  timeCardReportSettings: ITimeCardReportExpandedSettings;
  updateTimeCardReportSettings: (
    newReportSettings: {
      showTimeEntries: boolean;
      showProjectSummary: boolean;
      showCostCodeSummary: boolean;
      showEquipmentSummary: boolean;
      showDailySummary: boolean;
      showSignatures: boolean;
    },
    newTimeFormat: TimeCardReportExpandedTimeFormat,
    newBreakFormat: TimeCardReportExpandedBreakFormat
  ) => Promise<void>;
}

export interface ITimesheetsSettings {
  timeRangeType: TimeRangeType;
  timesheetView: TimesheetView;
  timeCardReportExpandedSettings: ITimeCardReportExpandedSettingData[];
  timeFormat: TimeCardReportExpandedTimeFormat;
  breakFormat: TimeCardReportExpandedBreakFormat;
}

export interface ITimesheetsFilter {
  employeeGroup: string | null;
  employee: string | null;
  project: string | null;
  costCode: string | null;
  equipment: string | null;
  type: TimesheetReportType | null;
  position: string | null;
  archivedStatus: ArchivedStatus;
  costCodeGroup: string | null;
  projectGroup: string | null;
}

interface ILocalDataState extends ITimesheetsFilter, ITimesheetsSettings {
  customTimeRange: ITimeRange<DateTime | null>;
}

const TimesheetsSidePanel = ({
  className,
  timeFormatSettings,
  breakFormatSettings,
  timeCardReportSettings,
  updateTimeCardReportSettings,
}: ITimesheetsSidePanelProps) => {
  const updateSettings = useTimeCardsSettingsUpdate();

  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userEvents = useTimesheetsGraylog();

  const { updateParams } = useQueryParams();

  const {
    timeRangeType,
    timesheetView,
    memberId,
    memberGroupId,
    projectId,
    costCodeId,
    costCodeGroupId,
    projectGroupId,
    equipmentId,
    positionId,
    timesheetReportType,
    archivedStatus,
    startDate,
    endDate,
  } = useTimesheetsQueryParams();

  const isWeeklyGridEnabled = useFeatureFlags('WEEKLY_TIME_GRID');

  const converter = useTimeRangeTypeTimeRangeConverter();

  const [localData, setLocalData] = useState<ILocalDataState>(getNewLocalState());
  const payPeriodRange = useTimeRange(TimeRangeType.PAY_PERIOD);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentPayPeriodEndTime = useMemo<DateTime>(() => payPeriodRange.timeRange.endTime, []);
  const org = useOrganization();

  const isWeeklyView = useMemo(() => localData.timesheetView !== TimesheetView.WEEKLY_GRID, [localData.timesheetView]);

  useEffect(() => {
    const newData = getNewLocalState();
    clearCheckedItems(newData);
    setLocalData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function getNewLocalState(): ILocalDataState {
    const newData: ILocalDataState = {
      timeRangeType,
      timesheetView,
      employeeGroup: memberGroupId,
      employee: memberId,
      project: projectId,
      costCode: costCodeId,
      equipment: equipmentId,
      type: timesheetReportType,
      position: positionId,
      customTimeRange: {
        startTime: startDate?.setZone('system', { keepLocalTime: true }) ?? null,
        endTime: endDate?.setZone('system', { keepLocalTime: true }) ?? null,
      },
      archivedStatus,
      costCodeGroup: costCodeGroupId,
      projectGroup: projectGroupId,
      timeCardReportExpandedSettings: getTimeCardReportExpandedSettingsData(),
      timeFormat: timeFormatSettings,
      breakFormat: breakFormatSettings,
    };
    return newData;
  }

  function getTimeCardReportExpandedSettingsData(): Array<ITimeCardReportExpandedSettingData> {
    return [
      {
        key: TimeCardReportExpandedSettingKeys.showTimeEntries,
        title: t('Time Entries'),
        value: timeCardReportSettings.showTimeEntries,
      },
      {
        key: TimeCardReportExpandedSettingKeys.showProjectSummary,
        title: t('Project Summary'),
        value: timeCardReportSettings.showProjectSummary,
      },
      {
        key: TimeCardReportExpandedSettingKeys.showCostCodeSummary,
        title: t('Cost Code Summary'),
        value: timeCardReportSettings.showCostCodeSummary,
      },
      {
        key: TimeCardReportExpandedSettingKeys.showEquipmentSummary,
        title: t('Equipment Summary'),
        value: timeCardReportSettings.showEquipmentSummary,
      },
      {
        key: TimeCardReportExpandedSettingKeys.showDailySummary,
        title: t('Daily Summary'),
        value: timeCardReportSettings.showDailySummary,
      },
      {
        key: TimeCardReportExpandedSettingKeys.showSignatures,
        title: t('Signatures'),
        value: timeCardReportSettings.showSignatures,
      },
    ];
  }

  const entryTypePickerOptions = useRef([
    { id: TimesheetReportType.ENTRY, name: t('Entry') },
    { id: TimesheetReportType.TIME_OFF_BOTH, name: t('Time Off (Paid & Unpaid)') },
    { id: TimesheetReportType.TIME_OFF_PAID, name: t('Time Off (Paid)') },
    { id: TimesheetReportType.TIME_OFF_UNPAID, name: t('Time Off (Unpaid)') },
  ]);

  const timesheetViewPickerOptions = useRef([
    { id: TimesheetView.SUMMARY, name: t('Summary') },
    { id: TimesheetView.TIME_CARDS, name: t('Basic') },
    { id: TimesheetView.EXPANDED_TIME_CARDS, name: t('Expanded') },
    { id: TimesheetView.TIME_ENTRIES, name: t('Entries') },
    ...(isWeeklyGridEnabled ? [{ id: TimesheetView.WEEKLY_GRID, name: t('Weekly Grid') }] : []),
  ]);

  const dispatch = useDispatch();

  const onSelectField =
    <K extends keyof ILocalDataState, V extends ILocalDataState[K]>(key: keyof ILocalDataState) =>
    (value: V) => {
      setLocalData({ ...localData, [key]: value });
    };

  function onSelectTimesheetView(view: string | null) {
    const { startTime, endTime } = getLocalTimeRange();

    switch (view) {
      case TimesheetView.TIME_ENTRIES:
        // All fields have overlap so don't clear data
        setLocalData({ ...localData, timesheetView: view, customTimeRange: { startTime, endTime } });
        break;
      case TimesheetView.SUMMARY:
      case TimesheetView.TIME_CARDS:
      case TimesheetView.EXPANDED_TIME_CARDS:
        // Need to clear nonrelevant data
        setLocalData({
          ...localData,
          project: null,
          costCode: null,
          type: null,
          timesheetView: view,
          customTimeRange: { startTime, endTime },
        });
        break;
      case TimesheetView.WEEKLY_GRID:
        setLocalData({
          ...localData,
          project: null,
          type: null,
          timesheetView: view,
          timeRangeType: TimeRangeType.WEEKLY,
        });
    }
  }

  function isCustomRangeEqual() {
    return (
      startDate?.toSeconds() === localData.customTimeRange.startTime?.toUTC(0, { keepLocalTime: true }).toSeconds() &&
      endDate?.toSeconds() ===
        localData.customTimeRange.endTime?.toUTC(0, { keepLocalTime: true }).set({ millisecond: 999 }).toSeconds()
    );
  }

  function appliableChanges() {
    if (localData.timeRangeType === TimeRangeType.CUSTOM && !isCustomRangeEqual()) {
      return true;
    } else {
      const local = _.pickBy({ ...localData, customTimeRange: null }, _.identity);
      const defaultData = _.pickBy({ ...getNewLocalState(), customTimeRange: null }, _.identity);
      return !_.isEqual(defaultData, local);
    }
  }

  async function onFilterApply() {
    applyQueryParams();
    await applyTimeCardReportSettings(localData);
    clearCheckedItems(localData);
  }

  async function applyTimeCardReportSettings(data: ILocalDataState) {
    if (data.timesheetView === TimesheetView.EXPANDED_TIME_CARDS) {
      const newSettings = data.timeCardReportExpandedSettings;
      const settingsMap = keyBy(newSettings, 'key');
      const newReportSettings = {
        showTimeEntries: settingsMap[TimeCardReportExpandedSettingKeys.showTimeEntries].value,
        showProjectSummary: settingsMap[TimeCardReportExpandedSettingKeys.showProjectSummary].value,
        showCostCodeSummary: settingsMap[TimeCardReportExpandedSettingKeys.showCostCodeSummary].value,
        showEquipmentSummary: settingsMap[TimeCardReportExpandedSettingKeys.showEquipmentSummary].value,
        showDailySummary: settingsMap[TimeCardReportExpandedSettingKeys.showDailySummary].value,
        showSignatures: settingsMap[TimeCardReportExpandedSettingKeys.showSignatures].value,
      };
      const newTimeFormat = data.timeFormat;
      const newBreakFormat = data.breakFormat;
      await updateTimeCardReportSettings(newReportSettings, newTimeFormat, newBreakFormat);
      await updateSettings(
        compact([
          { key: 'timecardExpandedSettings', payload: newReportSettings },
          { key: 'timeCardExpandedTimeFormatSettings', payload: newTimeFormat },
          { key: 'timeCardExpandedBreakFormatSettings', payload: newBreakFormat },
          localData.timeRangeType !== TimeRangeType.CUSTOM && localData.timeRangeType !== timeRangeType
            ? { key: 'timeRangeType', payload: localData.timeRangeType }
            : null,
        ])
      );
    } else if (localData.timeRangeType !== TimeRangeType.CUSTOM && localData.timeRangeType !== timeRangeType) {
      updateSettings([{ key: 'timeRangeType', payload: localData.timeRangeType }]);
    }
  }

  function clearCheckedItems(data: ILocalDataState) {
    // clear checked items when changing the report view
    if (timesheetView !== data.timesheetView) {
      dispatch(clearCheckedTimeEntryIds());
      dispatch(clearCheckedTimeOffIds());
      dispatch(clearCheckedMemberIds());
    }
  }

  function applyQueryParams() {
    const range = getLocalTimeRange();

    const timeRangeTypeChanged =
      localData.timeRangeType !== timeRangeType && localData.timeRangeType !== TimeRangeType.CUSTOM;

    const timeRange = timeRangeTypeChanged
      ? converter(localData.timeRangeType, 'system', DateTime.local().startOf('day'))
      : {
          startTime: range.startTime?.setZone('system', { keepLocalTime: true }),
          endTime: range.endTime?.setZone('system', { keepLocalTime: true }),
        };

    updateParams({
      [QueryParam.TIME_RANGE_TYPE]: localData.timeRangeType,
      [QueryParam.TIMESHEET_VIEW]: localData.timesheetView,
      [QueryParam.EMPLOYEE_GROUP_ID]: localData.employeeGroup,
      [QueryParam.EMPLOYEE_ID]: localData.employee,
      [QueryParam.POSITION_ID]: localData.position,
      [QueryParam.PROJECT_ID]: localData.project,
      [QueryParam.COST_CODE_ID]: localData.costCode,
      [QueryParam.EQUIPMENT_ID]: localData.equipment,
      [QueryParam.TIMESHEET_REPORT_TYPE]: localData.type,
      [QueryParam.ARCHIVED_STATUS]: localData.archivedStatus,
      [QueryParam.START_DATE]: timeRange?.startTime?.toISODate(),
      [QueryParam.END_DATE]: timeRange?.endTime?.toISODate(),
      [QueryParam.COST_CODE_GROUP_ID]: localData.costCodeGroup,
      [QueryParam.PROJECT_GROUP_ID]: localData.projectGroup,
    });

    userEvents.reportViewed(localData.timeRangeType, localData.timesheetView);
    userEvents.filterSet(
      localData.employee,
      localData.employeeGroup,
      localData.position,
      localData.project,
      localData.costCode,
      localData.equipment,
      localData.type,
      localData.archivedStatus
    );
  }

  function getLocalTimeRange() {
    if (localData.timeRangeType === TimeRangeType.CUSTOM) {
      if (localData.customTimeRange.startTime && localData.customTimeRange.endTime) {
        const clampDuration = Duration.fromObject({ days: 425 });

        const difference = localData.customTimeRange.endTime?.diff(localData.customTimeRange.startTime);
        const comparison = clampDuration.as('seconds') - (difference?.as('seconds') ?? 0);

        const startTime =
          comparison > 0 ? localData.customTimeRange.startTime : localData.customTimeRange.endTime.minus(clampDuration);
        const endTime = localData.customTimeRange.endTime;
        return { startTime, endTime };
      } else if (localData.customTimeRange.startTime) {
        return { startTime: localData.customTimeRange.startTime, endTime: localData.customTimeRange.startTime };
      } else if (localData.customTimeRange.endTime) {
        return { startTime: localData.customTimeRange.endTime, endTime: localData.customTimeRange.endTime };
      }
    }

    return { startTime: startDate, endTime: endDate };
  }

  function onClear() {
    const clearedData = { ...localData };

    setLocalData({ ...localData, ...clearedData });

    const search = { ...parse(location.search) };
    delete search[QueryParam.EMPLOYEE_GROUP_ID];
    delete search[QueryParam.EMPLOYEE_ID];
    delete search[QueryParam.PROJECT_ID];
    delete search[QueryParam.COST_CODE_ID];
    delete search[QueryParam.EQUIPMENT_ID];
    delete search[QueryParam.TIMESHEET_REPORT_TYPE];
    delete search[QueryParam.POSITION_ID];
    delete search[QueryParam.COST_CODE_GROUP_ID];
    delete search[QueryParam.PROJECT_GROUP_ID];
    search[QueryParam.ARCHIVED_STATUS] = 'unarchived';

    navigate({ search: stringify(search) }, { replace: true });
  }

  function anyFilterSet() {
    if (localData.timeRangeType === TimeRangeType.CUSTOM && !isCustomRangeEqual()) {
      return true;
    } else {
      const filterData = _.pickBy(
        {
          ...localData,
          customTimeRange: null,
          timeRangeType: null,
          timesheetView: null,
          timeCardReportView: null,
          timeCardReportExpandedSettings: null,
          timeFormat: null,
          breakFormat: null,
        },
        _.identity
      );
      return _.some(
        Object.keys(filterData),
        (key: keyof ITimesheetsFilter) =>
          (key === 'archivedStatus' && localData[key] !== 'unarchived') ||
          (key !== 'archivedStatus' && localData[key] !== null)
      );
    }
  }

  function getDisabledDateRange() {
    const clampDuration = Duration.fromObject({ days: 425 });
    return {
      disabledAfter: currentPayPeriodEndTime.plus(clampDuration),
      disabledBefore: localData.customTimeRange.endTime
        ? localData.customTimeRange.endTime.minus(clampDuration)
        : currentPayPeriodEndTime.minus(clampDuration),
    };
  }

  function getTimeCardExpandedSettings() {
    if (isNil(localData.timeCardReportExpandedSettings)) {
      return <></>;
    }

    return (
      <>
        {localData.timeCardReportExpandedSettings.map((setting) => {
          if (setting.key === TimeCardReportExpandedSettingKeys.showCostCodeSummary && org.trackCostCode === false) {
            return <></>;
          } else if (
            setting.key === TimeCardReportExpandedSettingKeys.showEquipmentSummary &&
            org.trackEquipment === false
          ) {
            return <></>;
          } else if (setting.key === TimeCardReportExpandedSettingKeys.showSignatures && isNil(org.signatureDate)) {
            return <></>;
          }
          return (
            <div className="pt-3 ml-2" key={setting.key}>
              <Checkbox
                checked={setting.value}
                onChange={(checked) => {
                  const newSettings = cloneDeep(localData.timeCardReportExpandedSettings);
                  const index = findIndex(localData.timeCardReportExpandedSettings, (item) => item.key === setting.key);
                  newSettings[index].value = checked;
                  setLocalData({ ...localData, timeCardReportExpandedSettings: newSettings });
                }}
                label={
                  <Row className={'ml-2'}>
                    <Label>{setting.title}</Label>
                  </Row>
                }
              />
            </div>
          );
        })}
        <Label className="pt-3 ml-2">{t('Time Format')}</Label>
        <RadioGroup
          className="pt-3 ml-2"
          name={'expanded-time-format'}
          value={localData.timeFormat}
          onChange={(value) => {
            setLocalData({
              ...localData,
              timeFormat:
                value === 'hours' ? TimeCardReportExpandedTimeFormat.HOURS : TimeCardReportExpandedTimeFormat.DECIMAL,
            });
          }}
        >
          <Radio
            key={'1'}
            value={TimeCardReportExpandedTimeFormat.HOURS}
            checked={localData.timeFormat === TimeCardReportExpandedTimeFormat.HOURS}
            label={t('Hours ') + '(HH:MM)'}
          />
          <Radio
            key={'2'}
            value={TimeCardReportExpandedTimeFormat.DECIMAL}
            checked={localData.timeFormat === TimeCardReportExpandedTimeFormat.DECIMAL}
            label={t('Decimal ') + '(HH.MM)'}
          />
        </RadioGroup>
        <Label className="pt-3 ml-2">{t('Break Format')}</Label>
        <RadioGroup
          className="pt-3 ml-2"
          name={'expanded-break-format'}
          value={localData.breakFormat}
          onChange={(value) => {
            let newBreakFormat = TimeCardReportExpandedBreakFormat.TOTAL;
            switch (value) {
              case 'total':
                newBreakFormat = TimeCardReportExpandedBreakFormat.TOTAL;
                break;
              case 'list':
                newBreakFormat = TimeCardReportExpandedBreakFormat.LIST;
                break;
              case 'totalAndList':
                newBreakFormat = TimeCardReportExpandedBreakFormat.TOTAL_AND_LIST;
                break;
            }

            setLocalData({
              ...localData,
              breakFormat: newBreakFormat,
            });
          }}
        >
          <Radio
            value={TimeCardReportExpandedBreakFormat.TOTAL}
            checked={localData.breakFormat === TimeCardReportExpandedBreakFormat.TOTAL}
            label={t('Total')}
          />
          <Radio
            value={TimeCardReportExpandedBreakFormat.LIST}
            checked={localData.breakFormat === TimeCardReportExpandedBreakFormat.LIST}
            label={t('List')}
          />
          <Radio
            value={TimeCardReportExpandedBreakFormat.TOTAL_AND_LIST}
            checked={localData.breakFormat === TimeCardReportExpandedBreakFormat.TOTAL_AND_LIST}
            label={t('Total and List')}
          />
        </RadioGroup>
      </>
    );
  }

  const classes = classNames('timesheets-side-panel', className);

  return (
    <SidePanelFilter
      onApply={onFilterApply}
      onClear={onClear}
      clearable={anyFilterSet()}
      appliable={appliableChanges()}
      buttonsClassName="px-5"
      className={classes}
    >
      <div className="timesheets-side-panel-settings px-5 py-4">
        <h3>{t('Settings')}</h3>
        {isWeeklyView && (
          <SidePanelTimeRangeSection
            timeRangeType={localData.timeRangeType}
            includeAllTime={false}
            onTimeRangeTypeChange={onSelectField('timeRangeType')}
            onCustomTimeRangeChange={onSelectField('customTimeRange')}
            customTimeRange={localData.customTimeRange}
            {...getDisabledDateRange()}
          />
        )}
        <BasicNonNullPicker
          onSelect={onSelectTimesheetView}
          value={localData.timesheetView}
          data={timesheetViewPickerOptions.current}
        />

        {localData.timesheetView === TimesheetView.EXPANDED_TIME_CARDS && <>{getTimeCardExpandedSettings()}</>}
      </div>
      <Divider className="mb-4" />

      <div className="timesheets-side-panel-filter px-5">
        <h3>{t('Filter')}</h3>
        {localData.timesheetView !== TimesheetView.TIME_ENTRIES ? (
          <>
            <EmployeePicker
              onSelect={onSelectField('employee')}
              value={localData.employee}
              placeholder={t('Employee')}
              permissions={{ permissions: [MemberPermission.MANAGE_TIME_ENTRIES], operationType: OperationType.AND }}
              position={Position.BOTTOM_END}
            />
            <EmployeeGroupPicker
              onSelect={onSelectField('employeeGroup')}
              value={localData.employeeGroup}
              placeholder={t('Employee Group')}
              position={Position.BOTTOM_END}
              filterByPermission={true}
            />
            <AutoHidePositionPicker
              value={localData.position}
              placeholder={t('Position')}
              onSelect={onSelectField('position')}
              permissions="manageTimeEntries"
              position={Position.BOTTOM_END}
            />
            <ArchivedPicker
              value={localData.archivedStatus}
              onSelect={onSelectField('archivedStatus')}
              position={Position.BOTTOM_END}
            />
          </>
        ) : (
          <>
            <EmployeePicker
              onSelect={onSelectField('employee')}
              value={localData.employee}
              placeholder={t('Employee')}
              permissions={{ permissions: [MemberPermission.MANAGE_TIME_ENTRIES], operationType: OperationType.AND }}
              position={Position.BOTTOM_END}
            />
            <EmployeeGroupPicker
              onSelect={onSelectField('employeeGroup')}
              value={localData.employeeGroup}
              placeholder={t('Employee Group')}
              position={Position.BOTTOM_END}
              filterByPermission={true}
            />
            <AutoHidePositionPicker
              value={localData.position}
              placeholder={t('Position')}
              onSelect={onSelectField('position')}
              permissions="manageTimeEntries"
            />
            <ProjectPicker
              onSelect={onSelectField('project')}
              value={localData.project}
              placeholder={t('Project')}
              position={Position.BOTTOM_END}
            />
            <ProjectGroupPicker
              onSelect={onSelectField('projectGroup')}
              value={localData.projectGroup}
              placeholder={t('Project Group')}
              position={Position.BOTTOM_END}
            />
            <CostCodePicker
              onSelect={onSelectField('costCode')}
              value={localData.costCode}
              placeholder={t('Cost Code')}
              considerProjectSpecificCostCodes={false}
              position={Position.BOTTOM_END}
            />
            <CostCodeGroupPicker
              onSelect={onSelectField('costCodeGroup')}
              value={localData.costCodeGroup}
              placeholder={t('Cost Code Group')}
              position={Position.BOTTOM_END}
            />
            <EquipmentPicker
              onSelect={onSelectField('equipment')}
              value={localData.equipment}
              placeholder={t('Equipment')}
              position={Position.BOTTOM_END}
            />
            <ArchivedPicker
              value={localData.archivedStatus}
              onSelect={onSelectField('archivedStatus')}
              position={Position.BOTTOM_END}
            />
            <BasicPicker
              value={localData.type}
              placeholder={t('Type')}
              data={entryTypePickerOptions.current}
              onSelect={onSelectField('type')}
              position={Position.BOTTOM_END}
            />
          </>
        )}
      </div>

      <Divider className="mb-4" />
    </SidePanelFilter>
  );
};

export default TimesheetsSidePanel;
