import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import Typography from 'components/foundation/text/Typography/Typography';
import { useOpenable } from 'hooks';
import AccountPreferencesDialog from '../AccountPreferencesDialog/AccountPreferencesDialog';

export interface IAccountPreferencesButtonProps {
  label?: string;
  className?: ClassName;
}

function AccountPreferencesButton({ label, className }: IAccountPreferencesButtonProps) {
  const openable = useOpenable();
  const classes = classNames('account-preferences-button', className);

  return (
    <>
      <Typography tag="span" color="primary" onClick={openable.open} className={classes}>
        {label}
      </Typography>
      <AccountPreferencesDialog isOpen={openable.isOpen} onClose={openable.close} size="full" />
    </>
  );
}

export default AccountPreferencesButton;
