import _ from 'lodash';

import { IFeatureFlags, TFlagKey } from 'types';

const PREFIX = 'REACT_APP_FEATURE_';

// find the keys for all the environment variables matching the correct prefix
const rawProcessKeys = Object.keys(process.env).filter((key) => _.startsWith(key, PREFIX));

// get only the filtered environment variables with the correct prefix
// then strip the prefix from the keys
//    { REACT_APP_FEATURE_PHOTOS_REACT: 'true' } => { PHOTOS_REACT: 'true' }
// then convert the string values to booleans
//    `{ PHOTOS_REACT: 'true' } => { PHOTOS_REACT: true }
export const processFlags: IFeatureFlags = _.chain(process.env)
  .pick(rawProcessKeys)
  .mapKeys((v, k) => k.replace(PREFIX, ''))
  .mapValues((v) => v === 'true')
  .value();

export const processKeys = Object.keys(processFlags) as TFlagKey[];

// cannot be modified during runtime
Object.freeze(processFlags);
Object.freeze(processKeys);
