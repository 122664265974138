import { configureStore } from '@reduxjs/toolkit';
import { persistState } from 'utils/storeUtils';
import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleWare) => getDefaultMiddleWare(),
});

export * from './actions';
export * from './constants';
export * from './types';

// There are certain conditions this will repersist to the default state, Redux toolkit? Not sure.
store.subscribe(() => persistState(store, ['dailyReport']));

export default store;
