import { gql } from '@apollo/client';

export const BASIC_TIME_METRICS_FRAGMENT = gql`
  fragment BasicLaborMetrics on LaborMetricsInterface {
    breakDt
    breakOt
    breakRt
    dt
    end
    ot
    rt
    start
  }
`;

export const BASIC_LABOR_METRICS_FRAGMENT = gql`
  fragment LaborMetrics on LaborMetricsInterface {
    breakDt
    breakOt
    breakRt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;

export const ALL_LABOR_METRICS_FRAGMENT = gql`
  fragment AllLaborMetrics on LaborMetricsInterface {
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
  }
`;

export const BASIC_EQUIPMENT_LABOR_METRICS_FRAGMENT = gql`
  fragment BasicEquipmentLaborMetrics on EquipmentLaborMetricsInterface {
    breakDt
    breakOt
    breakRt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    rt
    rtCents
    start
    equipmentCents
  }
`;
