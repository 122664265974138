import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import MultiPanel from 'components/foundation/panels/MultiPanel/MultiPanel';
import { ReactNode } from 'react';
import './DualPanel.scss';

export interface IDualPanelProps {
  leftContent: () => ReactNode;
  rightContent: () => ReactNode;
  className?: ClassName;
}

function DualPanel({ leftContent, rightContent, className }: IDualPanelProps) {
  const classes = classNames('dual-panel', className);
  return <MultiPanel className={classes} renders={[leftContent, rightContent]} stroke={true} />;
}

export default DualPanel;
