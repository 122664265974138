import {
  Align,
  Button,
  ButtonList,
  ISortPayload,
  ITableColumn,
  Justify,
  Label,
  Position,
  Row,
  Size,
  SortDirection,
  Table,
  TextAlign,
  Theme,
} from '@busybusy/webapp-react-ui';
import { TableCellHeight } from '@busybusy/webapp-react-ui/dist/components/Table/types/types';
import { ConflictResolutionIcon, InfoStroke, LockIcon } from 'assets/icons';
import classNames from 'classnames';
import { IconButton, MoreButton } from 'components';
import FormattedCostCodeName from 'components/domain/cost-code/FormattedCostCodeName/FormattedCostCodeName';
import FormattedEquipmentName from 'components/domain/equipment/FormattedEquipmentName/FormattedEquipmentName';
import OptionalRender from 'components/foundation/OptionalRender/OptionalRender';
import { TimesheetReportType } from 'containers/timesheets/Timesheets';
import { useOrganization } from 'hooks';
import { compact, isNil, keyBy, some, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ReactNode, useMemo, useState } from 'react';
import { IMember } from 'types';
import { ClassName } from 'types/ClassName';
import ICostCode from 'types/CostCode';
import IEquipment from 'types/Equipment';
import IProject from 'types/Project';
import { IVisibleTableColumn } from 'types/TableColumn';
import ITimeEntry from 'types/TimeEntry';
import TimeOffType from 'types/enum/TimeOffType';
import { memberLockUtils, projectUtils, timeOffUtils } from 'utils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import { getFormattedPathFromProject } from 'utils/projectUtils';
import './TimeEntryDataTable.scss';
import TimeEntryGPSIcon from './TimeEntryGPSIcon';
import { ITimeEntryReportRowActions } from './hooks/useTimeEntryDataTableRow';

export interface ITimeEntryDataTableProps {
  className?: ClassName;
  scroller?: HTMLElement;
  // This is currently only used with `TimesheetReportType.Entries`
  onTimeEntryFormDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onDeleteTimeEntryFormDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onTimeEntryConflictDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onLockDialogOpen: (row: ITimeEntryDataTableRow, isSplit?: boolean, hasSignatureViolation?: boolean) => void;
  onLocationHistoryDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onTimeEntryViewDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onTimeEntryLogViewDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onTimeOffFormDialogOpen?: (row: ITimeEntryDataTableRow) => void;
  onTimeOffViewDialogOpen?: (row: ITimeEntryDataTableRow) => void;
  onDeleteTimeOffFormDialogOpen?: (row: ITimeEntryDataTableRow) => void;
  onSplitEntryViewDialogOpen: (row: ITimeEntryDataTableRow) => void;
  onSignatureDialogOpen?: (row: ITimeEntryDataTableRow) => void;
  columnSettings: IVisibleTableColumn[];
  rows: ITimeEntryDataTableRow[];
  selectedIds: string[];
  onSelectChange?: (selectedRows: ITimeEntryDataTableRow[]) => void;
  loading?: boolean;
  emptyStateMessage?: string;
  showSelectAll?: boolean;
  initialSortKey?: keyof ITimeEntryDataTableRow;
  rowHeight?: TableCellHeight;
  hasSignature?: boolean;
}

export interface ITimeEntryDataTableRow {
  id: string;
  key: string;
  entry?: Pick<
    ITimeEntry,
    | 'id'
    | 'startTime'
    | 'endTime'
    | 'member'
    | 'project'
    | 'costCode'
    | 'equipment'
    | 'hasLocation'
    | 'clientLogs'
    | 'breaks'
    | 'startLocation'
    | 'endLocation'
    | 'daylightSavingTime'
    | 'metaDaylightSavingTime'
    | 'description'
  >;
  member: IMember;
  startDate: DateTime;
  endDate: DateTime;
  date: string;
  day: DateTime;
  employee: string;
  type: string;
  total: string;
  totalSeconds: number;
  start?: string;
  startSup: string | null;
  end?: string;
  endSup: string | null;
  breaks?: string;
  breakSeconds?: number;
  project?: IProject | null;
  costCode?: ICostCode | null;
  equipment?: IEquipment | null;
  description: string;
  timeOffType?: TimeOffType;
  actions: ITimeEntryReportRowActions | null;
  timesheetReportType: Omit<TimesheetReportType, TimesheetReportType.TIME_OFF_BOTH>;
  canManage: boolean;
  isArchived: boolean;
  exportStartISO: string;
  exportEndISO: string;
  breakCompliance: string;
  timeAccurate: string;
  injured: string;
  otSeconds: number;
  dtSeconds: number;
  ot: string;
  dt: string;
}

function TimeEntryDataTable({
  className,
  scroller,
  columnSettings,
  rows,
  selectedIds,
  emptyStateMessage,
  initialSortKey,
  onTimeEntryFormDialogOpen,
  onTimeOffFormDialogOpen,
  onDeleteTimeOffFormDialogOpen,
  onDeleteTimeEntryFormDialogOpen,
  onTimeEntryConflictDialogOpen,
  onLockDialogOpen,
  onLocationHistoryDialogOpen,
  onTimeEntryViewDialogOpen,
  onTimeEntryLogViewDialogOpen,
  onTimeOffViewDialogOpen,
  onSelectChange,
  onSignatureDialogOpen,
  loading,
  showSelectAll,
  onSplitEntryViewDialogOpen,
  rowHeight,
  hasSignature,
}: ITimeEntryDataTableProps) {
  const classes = classNames('time-entry-data-table', className);
  const useSmallIcon = rowHeight === 'cozy' || rowHeight === 'compact';
  const organization = useOrganization();
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASCENDING);
  const [tableSortBy, setTableSortBy] = useState<keyof ITimeEntryDataTableRow>(initialSortKey ?? 'date');
  const [sortIsDirty, setSortIsDirty] = useState<boolean>(false);
  const gpsStatusDisabledFeature = useFeatureFlags('GPS_STATUS_DISABLED');
  const locationFlaggingFeature = useFeatureFlags('LOCATION_FLAGGING');

  const sortedRows = useMemo(() => performSort(rows, sortDirection, tableSortBy), [sortDirection, tableSortBy, rows]);
  const checkedRows = useMemo(
    () => rows.filter((item) => some(selectedIds, (id) => item.id === id)),
    [rows, selectedIds]
  );

  function renderDateColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    function renderDropdownContent(closeDropdown: () => void) {
      function handleSplitEntryDropdown(event: React.MouseEvent) {
        const isBehindLock =
          row.member.memberLock?.effectiveDate &&
          memberLockUtils.inhibitsActions(DateTime.fromISO(row.member.memberLock.effectiveDate), row.endDate);
        finishClickEvent(event);
        const hasSignatureAndEnabled = !isNil(organization.signatureDate) && hasSignature;

        if (isBehindLock) {
          onLockDialogOpen(row, true, hasSignatureAndEnabled);
        } else if (onSignatureDialogOpen && hasSignatureAndEnabled) {
          onSignatureDialogOpen(row);
        } else {
          onSplitEntryViewDialogOpen(row);
        }
      }
      function handleViewEntryDropdown(event: React.MouseEvent) {
        finishClickEvent(event);
        onTimeEntryViewDialogOpen(row);
      }

      function handleViewGPSDropdown(event: React.MouseEvent) {
        finishClickEvent(event);
        onLocationHistoryDialogOpen(row);
      }

      function handleViewEntryLogsDropdown(event: React.MouseEvent) {
        finishClickEvent(event);
        onTimeEntryLogViewDialogOpen(row);
      }

      function handleDeleteDropdown(event: React.MouseEvent) {
        finishClickEvent(event);
        if (row.timeOffType) {
          onDeleteTimeOffFormDialogOpen?.(row);
        } else {
          onDeleteTimeEntryFormDialogOpen(row);
        }
      }

      function finishClickEvent(event: React.MouseEvent) {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();
      }

      function handleViewTimeOffDropdown(event: React.MouseEvent) {
        closeDropdown();
        event.stopPropagation();
        event.preventDefault();
        onTimeOffViewDialogOpen?.(row);
      }

      return (
        <ButtonList>
          {row.canManage && !row.isArchived && <Button onClick={handleDeleteDropdown}>{t('Delete')}</Button>}
          {row.timeOffType && <Button onClick={handleViewTimeOffDropdown}>{t('View Time Off')}</Button>}
          {!row.timeOffType && (
            <div>
              {row.canManage && row.actions?.splitEntry && (
                <Button onClick={handleSplitEntryDropdown}>{t('Split Entry')}</Button>
              )}
              <Button onClick={handleViewEntryDropdown}>{t('View Entry')}</Button>
              {row.actions?.locations && <Button onClick={handleViewGPSDropdown}>{t('View GPS')}</Button>}
              {row.actions?.logs && <Button onClick={handleViewEntryLogsDropdown}>{t('View Entry Logs')}</Button>}
            </div>
          )}
        </ButtonList>
      );
    }

    return (
      <div className="date-cell">
        <div className="menu-date">
          <MoreButton
            className={`no-print button ${useSmallIcon ? 'small-icon' : ''}`}
            position={Position.LEFT_START}
            renderContent={renderDropdownContent}
          />
          <Label className="ml-5 mb-0">{row.date}</Label>
        </div>
        <div className="right-content">
          {row.member.memberLock?.effectiveDate &&
          memberLockUtils.inhibitsActions(DateTime.fromISO(row.member.memberLock.effectiveDate), row.endDate) ? (
            <div className="lock">
              <IconButton
                key="lock"
                size={Size.MEDIUM}
                svg={LockIcon}
                iconTheme="light-gray"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onLockDialogOpen(row);
                }}
                className={`no-print button`}
              />
            </div>
          ) : (
            <></>
          )}
          {row.actions && (
            <TimeEntryGPSIcon
              timeEntry={row.entry!}
              flaggedTimeEntryLocation={row.actions!.flaggedTimeEntryLocation}
              project={row.project}
              memberGpsStatuses={row.actions!.memberGpsStatuses}
              onClick={() => onLocationHistoryDialogOpen(row)}
            />
          )}
        </div>
      </div>
    );
  }

  function renderStartColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    return (
      <div>
        {row.start ? row.start : '---'}
        {row.startSup ? <sup>{row.startSup}</sup> : null}
      </div>
    );
  }

  function renderEndColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    return (
      <div>
        {row.end ? row.end : '---'}
        {row.endSup ? <sup>{row.endSup}</sup> : null}
      </div>
    );
  }

  function renderBreaksColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    return (
      <div>
        <OptionalRender value={row.breaks}>{(breaks) => breaks}</OptionalRender>
      </div>
    );
  }

  function renderProjectColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    return (
      <div>
        <OptionalRender value={row.project}>{(project) => getFormattedPathFromProject(project, true)}</OptionalRender>
      </div>
    );
  }

  function renderCostCodeColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    return (
      <div>
        <OptionalRender value={row.costCode}>
          {(costCode) => <FormattedCostCodeName costCode={costCode} />}
        </OptionalRender>
      </div>
    );
  }

  function renderEquipmentColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    return (
      <div>
        <OptionalRender value={row.equipment}>
          {(equipment) => <FormattedEquipmentName equipment={equipment} />}
        </OptionalRender>
      </div>
    );
  }

  function renderDescriptionColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    if (row.timeOffType) {
      return (
        <div className="description-note">
          <Label>{timeOffUtils.getTimeOffTypeTitle(row.timeOffType)}</Label>
          {row.description ? ' - ' + row.description : ''}
        </div>
      );
    }
    return <div className="description-note">{row.description}</div>;
  }

  function renderActionColumn(row: ITimeEntryDataTableRow, _col: ITableColumn<ITimeEntryDataTableRow>): ReactNode {
    if (!row.actions) {
      return null;
    }

    return (
      <>
        {row.actions.logs && (
          <IconButton
            key="logs"
            size={Size.MEDIUM}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              event.preventDefault();
              onTimeEntryLogViewDialogOpen(row);
            }}
            svg={InfoStroke}
            iconTheme="light-gray"
            tooltipLabel={t('Logs')}
            className={`button`}
          />
        )}
        {row.actions.conflicts && (
          <IconButton
            key="conflict"
            size={Size.MEDIUM}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              event.preventDefault();
              onTimeEntryConflictDialogOpen(row);
            }}
            svg={ConflictResolutionIcon}
            iconTheme={Theme.WARNING}
            tooltipLabel={t('Conflicts')}
            className={`button`}
          />
        )}
      </>
    );
  }

  function renderColumns(): Array<ITableColumn<ITimeEntryDataTableRow>> {
    const baseColumns: Array<ITableColumn<ITimeEntryDataTableRow>> = compact([
      {
        cell: renderDateColumn,
        key: 'date',
        sort: true,
        title: t('Date'),
        size: '250px',
        align: TextAlign.LEFT,
        cellClassName: `table-view-cell-no-left-border time-entry-data-table-date-column`,
        headerClassName: `table-view-cell-no-left-border time-entry-data-table-date-column`,
      },
      {
        key: 'employee',
        sort: true,
        title: t('Employee'),
        align: TextAlign.LEFT,
        size: '200px',
        cellClassName: `wrapping-table-cell fw-semi-bold px-4 time-entry-data-table-employee-column`,
        headerClassName: `time-entry-data-table-employee-column`,
      },
    ]);

    const customizableColumns: Array<ITableColumn<ITimeEntryDataTableRow>> = compact<
      ITableColumn<ITimeEntryDataTableRow>
    >([
      {
        key: 'total',
        sort: true,
        title: t('Total'),
        align: TextAlign.RIGHT,
        size: '110px',
        cellClassName: `fw-semi-bold px-4 time-entry-data-table-total-column`,
        headerClassName: 'time-entry-data-table-total-column',
      },
      {
        cell: renderStartColumn,
        key: 'start',
        sort: true,
        title: t('Start'),
        align: TextAlign.RIGHT,
        size: '110px',
        cellClassName: `fw-semi-bold px-4 time-entry-data-table-start-column`,
        headerClassName: 'time-entry-data-table-start-column',
      },
      {
        cell: renderEndColumn,
        key: 'end',
        sort: true,
        title: t('End'),
        align: TextAlign.RIGHT,
        size: '110px',
        cellClassName: `fw-semi-bold px-4 time-entry-data-table-end-column`,
        headerClassName: 'time-entry-data-table-end-column',
      },
      {
        cell: renderBreaksColumn,
        key: 'breaks',
        sort: true,
        title: t('Breaks'),
        align: TextAlign.RIGHT,
        size: '110px',
        cellClassName: `fw-semi-bold px-4 time-entry-data-table-breaks-column`,
        headerClassName: 'time-entry-data-table-breaks-column',
      },
      {
        cell: renderProjectColumn,
        key: 'project',
        sort: true,
        title: t('Project'),
        align: TextAlign.LEFT,
        size: '200px',
        cellClassName: `wrapping-table-cell fw-semi-bold px-4 time-entry-data-table-project-column`,
        headerClassName: `time-entry-data-table-project-column`,
      },
      organization.trackCostCode && {
        cell: renderCostCodeColumn,
        key: 'costCode',
        sort: true,
        title: t('Cost Code'),
        align: TextAlign.LEFT,
        size: '180px',
        cellClassName: `wrapping-table-cell fw-semi-bold px-4 time-entry-data-table-cost-code-column`,
        headerClassName: 'time-entry-data-table-cost-code-column',
      },
      organization.trackEquipment && {
        cell: renderEquipmentColumn,
        key: 'equipment',
        sort: true,
        title: t('Equipment'),
        align: TextAlign.LEFT,
        size: '200px',
        cellClassName: `wrapping-table-cell fw-semi-bold py-2 px-4 time-entry-data-table-equipment-column`,
        headerClassName: 'time-entry-data-table-equipment-column',
      },
      {
        key: 'type',
        sort: true,
        title: t('Type'),
        align: TextAlign.LEFT,
        size: '100px',
        cellClassName: 'fw-semi-bold px-4 time-entry-data-table-type-column',
        headerClassName: 'time-entry-data-table-type-column',
      },
      {
        cell: renderDescriptionColumn,
        key: 'description',
        sort: true,
        title: t('Description'),
        align: TextAlign.LEFT,
        textWrap: true,
        size: '400px',
        cellClassName: 'wrapping-table-cell px-4 time-entry-data-table-description-column',
        headerClassName: 'time-entry-data-table-description-column',
      },
    ]);

    const columnSettingsMap = keyBy(columnSettings, 'key');

    const visibileColumnsKeys = customizableColumns.reduce<string[]>((acc, cur) => {
      if (columnSettings.some((settingCol) => cur.key === settingCol.key && settingCol.visible === true)) {
        return [...acc, cur.key];
      } else {
        return acc;
      }
    }, []);

    const columns = sortBy(
      [...baseColumns, ...customizableColumns.filter((col) => visibileColumnsKeys.includes(col.key))],
      (col) => columnSettingsMap[col.key]?.position ?? 0
    );
    return [
      ...columns,
      {
        key: 'actions',
        cell: renderActionColumn,
        title: '',
        align: TextAlign.LEFT,
        size: '150px',
        cellClassName: `fw-semi-bold no-print`,
        headerClassName: `no-print`,
      },
    ];
  }

  function handleRowClick(row: ITimeEntryDataTableRow) {
    const isBehindLock =
      row.member.memberLock?.effectiveDate &&
      memberLockUtils.inhibitsActions(DateTime.fromISO(row.member.memberLock.effectiveDate), row.endDate);
    if (row.timeOffType) {
      if (!row.canManage || row.isArchived) {
        onTimeOffViewDialogOpen?.(row);
      } else if (isBehindLock) {
        onLockDialogOpen(row);
      } else {
        onTimeOffFormDialogOpen?.(row);
      }
    } else {
      if (!row.canManage || row.isArchived) {
        onTimeEntryViewDialogOpen(row);
      } else if (isBehindLock) {
        onLockDialogOpen(row);
      } else {
        onTimeEntryFormDialogOpen(row);
      }
    }
  }

  function handleSort(sort: ISortPayload<ITimeEntryDataTableRow>) {
    setTableSortBy(sort.sortBy as any);
    setSortDirection(sort.sortDir);
    setSortIsDirty(true);
  }

  function handleCheckAll(checked: boolean) {
    if (checked) {
      onSelectChange?.(sortedRows);
    } else {
      onSelectChange?.([]);
    }
  }

  return (
    <Table
      className={classes}
      cols={renderColumns()}
      data={sortedRows}
      strokeCols={true}
      lazyLoad={false}
      minWidth={`${renderColumns().length * 200}px`}
      onRowClick={handleRowClick}
      sortDir={sortDirection}
      sortBy={tableSortBy}
      onSort={handleSort}
      sortIsDirty={sortIsDirty}
      selected={checkedRows}
      header="standard"
      onSelectChange={onSelectChange}
      onCheckAllChange={isNil(showSelectAll) || showSelectAll === true ? handleCheckAll : undefined}
      emptyTemplate={renderEmptyState(
        emptyStateMessage ?? t('There are no time entries or time offs for this date range.')
      )}
      scroller={scroller}
      rowKey="key"
      isLoading={loading}
      cellHeight={rowHeight}
    />
  );
}

function renderEmptyState(emptyStateMessage: string): ReactNode {
  return (
    <Row justify={Justify.CENTER} align={Align.CENTER} className="empty-entry-state">
      {emptyStateMessage}
    </Row>
  );
}

function performSort(data: ITimeEntryDataTableRow[], sortDir: SortDirection, sortKey: string) {
  let sortedData = data;
  if (sortKey === 'date') {
    sortedData = sortBy(data, 'startDate');
  } else if (sortKey === 'project') {
    sortedData = sortBy(data, (item) =>
      (item.project ? projectUtils.getFormattedPathFromProject(item.project) : '---').toLowerCase()
    );
  } else if (sortKey === 'costCode') {
    sortedData = sortBy(data, (item) => (item.costCode ? item.costCode.costCode : '---').toLowerCase());
  } else if (sortKey === 'equipment') {
    sortedData = sortBy(data, (item) => (item.equipment ? item.equipment.equipmentName : '---').toLowerCase());
  } else if (sortKey === 'description') {
    sortedData = sortBy(data, (item) => (item.description ? item.description : '---').toLowerCase());
  } else if (sortKey === 'employee') {
    sortedData = sortBy(data, (item) => (item.employee ? item.employee : '---').toLowerCase());
  } else {
    sortedData = sortBy(data, sortKey);
  }

  return sortDir === SortDirection.ASCENDING ? sortedData : sortedData.reverse();
}

export default TimeEntryDataTable;
