import {
  CSVIcon,
  DocIcon,
  DocxIcon,
  PDFIcon,
  PptIcon,
  PptxIcon,
  RtfIcon,
  TextIcon,
  XlsIcon,
  XlsxIcon,
} from 'assets/icons';
import TIcon from 'types/react/Icon';

export const fileTypeToIconLookup: Record<TSupportedDocumentFileType, TIcon> = {
  'text/plain': TextIcon,
  'text/csv': CSVIcon,
  'application/msword': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocxIcon,
  'application/pdf': PDFIcon,
  'application/vnd.ms-powerpoint': PptIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': PptxIcon,
  'application/rtf': RtfIcon,
  'application/vnd.ms-excel': XlsIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': XlsxIcon,
  'text/rtf': RtfIcon,
};

export const supportedDocumentFileType = [
  'text/plain',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/rtf',
] as const;

export type TSupportedDocumentFileType = (typeof supportedDocumentFileType)[number];

export const supportedDocumentFileExtensions = [
  'pdf',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'txt',
  'rtf',
  'csv',
] as const;

export type TSupportedDocumentFileExtensions = (typeof supportedDocumentFileExtensions)[number];

export const fileExtensionToIconLookup: Record<TSupportedDocumentFileExtensions, TIcon> = {
  csv: CSVIcon,
  doc: DocIcon,
  docx: DocxIcon,
  pdf: PDFIcon,
  ppt: PptIcon,
  pptx: PptxIcon,
  rtf: RtfIcon,
  txt: TextIcon,
  xls: XlsIcon,
  xlsx: XlsxIcon,
};

export const fileTypeToFileExtensionLookup: Record<TSupportedDocumentFileType, TSupportedDocumentFileExtensions> = {
  'text/csv': 'csv',
  'text/plain': 'txt',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/pdf': 'pdf',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/rtf': 'rtf',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/rtf': 'rtf',
};

export const fileExtensionToFileTypeLookup: Record<TSupportedDocumentFileExtensions, TSupportedDocumentFileType> = {
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rtf: 'application/rtf',
  txt: 'text/plain',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
