import { t } from 'utils/localize';
import { RecurringScheduleDay } from '../types/types';

export const defaultSchedulingColors = {
  work: '#2196F3',
  break: '#FCED65',
  timeOff: '#D5E8FF',
};

export const recurringScheduleLabelLookup: Record<RecurringScheduleDay, string> = {
  monday: t('Monday'),
  tuesday: t('Tuesday'),
  wednesday: t('Wednesday'),
  thursday: t('Thursday'),
  friday: t('Friday'),
  saturday: t('Saturday'),
  sunday: t('Sunday'),
};
