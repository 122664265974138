import castArray from 'lodash/castArray';
import every from 'lodash/every';
import filter from 'lodash/filter';
import partition from 'lodash/partition';
import some from 'lodash/some';
import { useCallback } from 'react';
import { IMember } from 'types';
import OperationType from 'types/enum/OperationType';
import { canManagePermissionForMember, IPermissionCheckableMember, TPermissions } from 'utils/positionUtils';
import useActiveMember from '../store/useActiveMember';

export default function usePermissions() {
  const activeMember = useActiveMember();

  // TODO eventually remove IMember from here, it's a lot of refactoring and casting
  const hasPermissionToManage = useCallback(
    (member: IPermissionCheckableMember | IMember, permission: TPermissions) => {
      return canManagePermissionForMember(permission, activeMember, member);
    },
    [activeMember]
  );

  const hasPermissionsToManage = useCallback(
    (
      member: IPermissionCheckableMember,
      permissions: TPermissions | TPermissions[],
      operationType: OperationType = OperationType.AND
    ) => {
      const permissionArray = castArray(permissions);
      if (operationType === OperationType.OR) {
        return some(permissionArray, (permission) => hasPermissionToManage(member, permission));
      } else if (operationType === OperationType.AND) {
        return every(permissionArray, (permission) => hasPermissionToManage(member, permission));
      }
    },
    [hasPermissionToManage]
  );

  const partitionManageableMembers = useCallback(
    (
      members: Array<IPermissionCheckableMember>,
      permissions: TPermissions | TPermissions[],
      operationType: OperationType = OperationType.AND
    ) => {
      return partition(members, (member) => hasPermissionsToManage(member, permissions, operationType));
    },
    [hasPermissionsToManage]
  );

  const filterManageableMembers = useCallback(
    (
      members: Array<IPermissionCheckableMember>,
      permissions: TPermissions | TPermissions[],
      operationType: OperationType = OperationType.AND
    ) => {
      return filter(members, (member) => hasPermissionsToManage(member, permissions, operationType));
    },
    [hasPermissionsToManage]
  );

  return { hasPermissionToManage, hasPermissionsToManage, filterManageableMembers, partitionManageableMembers };
}
