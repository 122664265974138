import gql from 'graphql-tag';

export const CREATE_CLIENT_TIME_ENTRY_BREAK_LOG = gql`
  mutation CreateClientTimeEntryBreakLog($log: ClientTimeEntryBreakLogCreateInput) {
    createClientTimeEntryBreakLog(clientTimeEntryBreakLog: $log) {
      id
      timeEntryBreakId
    }
  }
`;

export const CREATE_CLIENT_TIME_ENTRY_BREAK_LOGS = gql`
  mutation CreateClientTimeEntryBreakLogs($logs: [ClientTimeEntryBreakLogCreateInput!]!) {
    createClientTimeEntryBreakLogs(clientTimeEntryBreakLogs: $logs) {
      id
      timeEntryBreakId
    }
  }
`;
