import { Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from 'types/ClassName';
import { CSSProperties } from 'react';
import * as React from 'react';
import './DashboardCard.scss';
import useIsInIframe from 'hooks/store/useIsInIframe';

export interface IDashboardCardProps
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'className'> {
  theme: Theme.DARK | Theme.LIGHT;
  showBlurredBackground: boolean;
  tabColor?: string;
  className?: ClassName;
}

function DashboardCard({
  className,
  children,
  theme,
  tabColor,
  style,
  showBlurredBackground,
  ...divProps
}: IDashboardCardProps) {
  const isInIframe = useIsInIframe();
  const classes = classNames('dashboard-card', theme, { 'is-in-iframe': isInIframe }, className);

  const styles: CSSProperties = Object.assign(
    {},
    style,
    tabColor ? { borderTop: `5px solid ${tabColor}`, borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {}
  );

  return (
    <div className={classes} {...divProps} style={styles}>
      {showBlurredBackground && <div className="blurred-background" />}
      {children}
    </div>
  );
}

DashboardCard.defaultProps = {
  showBlurredBackground: false,
};

export default DashboardCard;
