import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BulletinPost } from 'containers/bulletin/types/types';

const initialState: IStoreBulletinPosts = {
  checkedPosts: [],
  error: false,
  loadedAll: false,
  bulletinPosts: [],
};

export interface IStoreBulletinPosts {
  checkedPosts: BulletinPost[];
  loadedAll: boolean;
  bulletinPosts: BulletinPost[];
  error: boolean;
}

const slice = createSlice({
  name: 'Bulletin',
  initialState,
  reducers: {
    checkPost: (state: IStoreBulletinPosts, payload: PayloadAction<BulletinPost[]>) => {
      state.checkedPosts = payload.payload;
      return state;
    },
    clearCheckedPosts: (state: IStoreBulletinPosts) => {
      state.checkedPosts = [];
      return state;
    },
    deleteCheckedPosts: (state: IStoreBulletinPosts) => {
      state.checkedPosts = [];
      return state;
    },
    createPost: (state: IStoreBulletinPosts, payload: PayloadAction<BulletinPost>) => {
      state.bulletinPosts = [...state.bulletinPosts, payload.payload];
      return state;
    },
  },
});

export const { checkPost, clearCheckedPosts, deleteCheckedPosts, createPost } = slice.actions;
export default slice.reducer;
