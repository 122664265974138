import gql from 'graphql-tag';
import { useApolloPaging } from 'hooks';
import { isNil, some } from 'lodash';
import ICostCode from 'types/CostCode';
import ICursorable from 'types/Cursorable';

export default function useCheckArchivedOfCostCode() {
  const { getAll } = useApolloPaging();

  async function checkArchivedOfCostCodes(costCodeIds?: string[] | null): Promise<boolean> {
    if (!isNil(costCodeIds)) {
      const results = await getAll<ICostCode & ICursorable>('costCodes', {
        fetchPolicy: 'network-only',
        query: COST_CODE_ARCHIVED_QUERY,
        variables: { filter: { id: { contains: costCodeIds } } },
      });
      return some(results, (costCode) => !isNil(costCode.archivedOn));
    } else {
      return false;
    }
  }

  return checkArchivedOfCostCodes;
}

export const COST_CODE_ARCHIVED_QUERY = gql`
  query queryCostCodeArchived($first: Int, $after: String, $filter: CostCodeFilter) {
    costCodes(first: $first, after: $after, filter: $filter) {
      id
      cursor
      archivedOn
    }
  }
`;
