import { useAsync } from 'hooks/utils';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { default as JitLaborMetric } from 'types/aggregate/JitLaborMetric';
import ITimeRange from 'types/TimeRange';
import { getMonthsBetween } from 'utils/dateUtils';
import { LaborMetricsInterval } from '__generated__/graphql';

export type MetricQueryProvider<T extends JitLaborMetric = JitLaborMetric> = (
  start: DateTime,
  end: DateTime,
  interval: LaborMetricsInterval
) => Promise<T[]>;

export default function useMetricAggregates(
  timeRange: ITimeRange<DateTime>,
  queryProvider: MetricQueryProvider,
  monthLimitToSwitch: number = 2,
  usePayPeriods: boolean = false,
  executeImmediate: boolean = true
) {
  const getData = useCallback(async () => {
    if (!usePayPeriods) {
      const months = getMonthsBetween(timeRange.startTime, timeRange.endTime);

      if (months.length > monthLimitToSwitch) {
        // all items in array represent a month
        return await queryProvider(timeRange.startTime, timeRange.endTime, LaborMetricsInterval.Month);
      } else {
        return await queryProvider(timeRange.startTime, timeRange.endTime, LaborMetricsInterval.Day);
      }
    } else {
      // all items in array represent a pay period
      return await queryProvider(timeRange.startTime, timeRange.endTime, LaborMetricsInterval.PayPeriod);
    }
  }, [monthLimitToSwitch, queryProvider, timeRange, usePayPeriods]);

  return useAsync(getData, executeImmediate);
}
