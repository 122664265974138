import { useApolloClient } from '@apollo/client';
import { Row } from '@busybusy/webapp-react-ui';
import { SINGLE_TIME_OFF_QUERY } from 'apollo/queries/time-off-queries';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { Spinner } from 'components';
import FormattedEmployeeName from 'components/domain/member/FormattedEmployeeName/FormattedEmployeeName';
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ITimeOff from 'types/TimeOff';
import { getDateName, getHourMinuteStringFromSeconds } from 'utils/dateUtils';
import { t } from 'utils/localize';
import { getTimeOffTypeTitle } from 'utils/timeOffUtils';
import './TimeOffView.scss';

export interface ITimeOffViewProps {
  className?: ClassName;
  timeOffId: string;
}

const TimeOffView = (props: ITimeOffViewProps) => {
  const { className, timeOffId } = props;

  const client = useApolloClient();
  const [timeOff, setTimeOff] = useState<ITimeOff>();

  useEffect(() => {
    if (timeOffId) {
      getTimeOff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOffId]);

  async function getTimeOff() {
    client
      .query<{ timeOffs: ITimeOff[] }>({
        query: SINGLE_TIME_OFF_QUERY,
        variables: { timeOffId },
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        if (!isEmpty(result.data.timeOffs)) {
          setTimeOff(result.data.timeOffs[0]);
        }
      })
      .catch(() => {
        setTimeOff(undefined);
      });
  }

  const classes = classNames('time-off-view p-8 mb-6', className);

  return (
    <div className={classes}>
      {timeOff ? (
        <>
          <Row className={'mb-6'}>
            <HeaderText title={t('Date')} className={'mr-12'}>
              <span>{getDateName(DateTime.fromISO(timeOff.dateStamp))}</span>
            </HeaderText>
            <HeaderText title={t('Total')}>
              <span>{getHourMinuteStringFromSeconds(timeOff.seconds ? timeOff.seconds : 0)}</span>
            </HeaderText>
          </Row>
          <HeaderText title={t('Type')} className={'mb-6'}>
            <span>
              {getTimeOffTypeTitle(timeOff.type) + (timeOff.paid ? ' (' + t('Paid') + ')' : ' (' + t('Unpaid') + ')')}
            </span>
          </HeaderText>
          <HeaderText title={t('Description')} className={'mb-6'}>
            <span className="entry-description">
              {timeOff.description && !isEmpty(timeOff.description) ? timeOff.description : '---'}
            </span>
          </HeaderText>
          <HeaderText title={t('Created On')} className={'mb-6 mr-12'}>
            <span>{getDateName(DateTime.fromISO(timeOff.createdOn))}</span>
          </HeaderText>
          <HeaderText title={t('Submitted By')} className={'mb-6'}>
            <FormattedEmployeeName
              firstName={timeOff.submitterMember.firstName ?? ''}
              lastName={timeOff.submitterMember.lastName ?? ''}
            />
          </HeaderText>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default TimeOffView;
