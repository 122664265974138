import { Action, ActionCreator, UnknownAction } from 'redux';

import { AUTHENTICATION_SET_TOKEN, IStoreAuthentication } from '../authenticationIndex';

export interface IStoreAuthenticationSetTokenAction extends UnknownAction {
  type: typeof AUTHENTICATION_SET_TOKEN;
  payload: IStoreAuthentication;
}

export const authenticationSetTokenAction: ActionCreator<IStoreAuthenticationSetTokenAction> = (token: string) => ({
  type: AUTHENTICATION_SET_TOKEN,

  payload: { token },
});
