import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FunctionComponent, ReactNode } from 'react';
import './DetailsItem.scss';
import DetailsItemWrapper from './DetailsItemWrapper/DetailsItemWrapper';

export interface IDetailsItemProps {
  onClick: () => void;
  children?: ReactNode;
  disabled?: boolean;
  title?: string;
  hint?: string;
  value?: string | null;
  emptyValue?: string;
  hideArrow?: boolean;
  rightContent?: ReactNode;
  className?: ClassName;
}

const DetailsItem: FunctionComponent<IDetailsItemProps> = (props) => {
  const { title, value, emptyValue = '---', className, children, ...wrapperProps } = props;
  const classes = classNames('details-item', className);

  return (
    <DetailsItemWrapper className={classes} {...wrapperProps}>
      {title && <div className="details-item-title">{title}</div>}
      <div className="details-item-value pr-8">
        {props.hasOwnProperty('value') ? value || emptyValue : ''}
        {children}
      </div>
    </DetailsItemWrapper>
  );
};

export default DetailsItem;
