import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const ProjectFragments: {
  info: DocumentNode | null;
  details: DocumentNode | null;
  createDetails: DocumentNode | null;
  display: DocumentNode | null;
} = {
  info: null,
  details: null,
  createDetails: null,
  display: null,
};

ProjectFragments.info = gql`
  fragment Info on ProjectInfo {
    address1
    address2
    city
    country
    customer
    deletedOn
    id
    latitude
    locationRadius
    longitude
    number
    requireTimeEntryGps
    phone
    postalCode
    projectId
    reminder
    state
    additionalInfo
    distantLocationFlaggingDisabled
  }
`;

ProjectFragments.display = gql`
  fragment ProjectDisplayDetails on Project {
    id
    title
    depth
    ancestors {
      id
      title
      depth
      archivedOn
    }
  }
`;

ProjectFragments.details = gql`
  fragment ProjectDetails on Project {
    id
    archivedOn
    children {
      id
      title
      depth
      archivedOn
    }
    parentProject {
      id
      archivedOn
    }
    projectInfo {
      address1
      address2
      city
      country
      customer
      deletedOn
      id
      latitude
      locationRadius
      longitude
      number
      requireTimeEntryGps
      phone
      postalCode
      projectId
      reminder
      state
      additionalInfo
      distantLocationFlaggingDisabled
    }
    parentProjectId
    projectGroup {
      id
      deletedOn
      groupName
    }
    projectGroupId
    rootProjectId
    submittedOn
    title
    updatedOn
    depth
    createdOn
    updatedByMember {
      id
      firstName
      lastName
    }
  }
`;

export const flatProjectDetails = gql`
  fragment FlatProjectDetails on Project {
    id
    organizationId
    parentProjectId
    projectGroupId
    rootProjectId
    title
    depth
    archivedOn
    createdOn
    updatedOn
    cursor
  }
`;

export const joinedProjectGroup = gql`
  fragment JoinedProjectGroup on Project {
    projectGroup {
      id
      deletedOn
      groupName
    }
  }
`;

export const joinedProjectInfo = gql`
  fragment JoinedProjectInfo on Project {
    projectInfo {
      address1
      address2
      city
      country
      customer
      deletedOn
      id
      latitude
      locationRadius
      longitude
      number
      requireTimeEntryGps
      phone
      postalCode
      projectId
      reminder
      state
      distantLocationFlaggingDisabled
    }
  }
`;

export const projectsQuery = gql`
  query GetProjects($filter: ProjectFilter, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, first: $first, after: $after, sort: $sort) {
      cursor
      ...ProjectDetails
    }
  }
  ${ProjectFragments.details}
`;

export const projectQuery = gql`
  query ProjectWithDetails($filter: ProjectFilter!, $after: String) {
    projects(filter: $filter, after: $after) {
      ...ProjectDetails
    }
  }
  ${ProjectFragments.details}
`;

export const duplicateProjectQuery = gql`
  query DuplicateProject($filter: ProjectFilter!) {
    projects(filter: $filter) {
      title
      parentProjectId
      depth
      archivedOn
      projectCostCodeLink {
        id
        costCodeId
        deletedOn
      }
      children {
        id
        archivedOn
        projectCostCodeLink {
          id
          costCodeId
          deletedOn
        }
      }
    }
  }
`;

export const flatProjectQuery = gql`
  query FlatProject($filter: ProjectFilter!, $sort: [ProjectSort!], $after: String, $first: Int) {
    projects(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      organizationId
      parentProjectId
      projectGroupId
      rootProjectId
      title
      depth
      archivedOn
      createdOn
      updatedOn
      cursor
    }
  }
`;

export const reimportProjectQuery = gql`
  query reimportProjectQuery($filter: ProjectFilter!, $after: String) {
    projects(filter: $filter, after: $after) {
      ...FlatProjectDetails
      ...JoinedProjectGroup
      ...JoinedProjectInfo
      ancestors {
        id
        title
        depth
      }
      projectInfo {
        ...Info
      }
    }
  }
  ${flatProjectDetails}
  ${joinedProjectGroup}
  ${joinedProjectInfo}
  ${ProjectFragments.info}
`;

export const withoutFamilyProjectQuery2 = gql`
  query ProjectThatIsHomelessAndWithoutFamily($filter: ProjectFilter!, $after: String) {
    projects(filter: $filter, after: $after) {
      id
      organizationId
      parentProjectId
      projectGroupId
      rootProjectId
      title
      depth
      archivedOn
      createdOn
      updatedOn
      cursor
      projectGroup {
        id
        deletedOn
        groupName
      }
      projectInfo {
        address1
        address2
        city
        country
        customer
        deletedOn
        id
        latitude
        locationRadius
        longitude
        number
        requireTimeEntryGps
        phone
        postalCode
        projectId
        reminder
        state
      }
    }
  }
`;
export const createProjectMutation = gql`
  mutation createProject1($project: ProjectCreateInput!) {
    createProject(project: $project) {
      ...ProjectDetails
    }
  }
  ${ProjectFragments.details}
`;

export const createProjectInfoMutation = gql`
  mutation createProjectInfo($projectInfo: ProjectInfoCreateInput!) {
    createProjectInfo(projectInfo: $projectInfo) {
      ...Info
    }
  }
  ${ProjectFragments.info}
`;

export const importProjectMutation = gql`
  mutation createProject2($project: ProjectCreateInput!) {
    createProject(project: $project) {
      id
      depth
    }
  }
`;

export const projectInfoOnlyIdMutation = gql`
  mutation projectInfoOnlyIdMutation($projectInfo: ProjectInfoCreateInput!) {
    createProjectInfo(projectInfo: $projectInfo) {
      id
    }
  }
`;

export const updateProjectMutation = gql`
  mutation updateProjectWithDetails($project: ProjectUpdateInput!) {
    updateProject(project: $project) {
      ...ProjectDetails
    }
  }
  ${ProjectFragments.details}
`;

export const updateProjectInfoMutation = gql`
  mutation updateProjectInfoWithInfo($projectInfo: ProjectInfoUpdateInput!) {
    updateProjectInfo(projectInfo: $projectInfo) {
      ...Info
    }
  }
  ${ProjectFragments.info}
`;

export const projectsWithAncestorsQuery = gql`
  query ProjectWithAncestors($filter: ProjectFilter!, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, after: $after, first: $first, sort: $sort) {
      id
      archivedOn
      projectInfo {
        additionalInfo
        address1
        address2
        city
        country
        customer
        id
        latitude
        locationRadius
        longitude
        number
        phone
        postalCode
        projectId
        reminder
        requireTimeEntryGps
        state
      }
      parentProjectId
      ancestors {
        id
        title
        depth
      }
      projectGroup {
        id
        deletedOn
        groupName
      }
      cursor
      projectGroupId
      rootProjectId
      submittedOn
      title
      createdOn
      updatedOn
      depth
    }
  }
`;

export const projectsAncestorsChildrenLinkQuery = gql`
  query ProjectWithCostCodeLinksAncestorsChildren($filter: ProjectFilter) {
    projects(filter: $filter) {
      id
      title
      depth
      projectGroupId
      parentProjectId
      rootProjectId
      archivedOn
      children {
        title
        id
        archivedOn
      }
      ancestors {
        id
        title
        depth
        projectGroupId
        archivedOn
      }
      projectCostCodeLink {
        id
        costCodeId
        deletedOn
      }
    }
  }
`;

export const subProjectsQuery = gql`
  query ProjectSubprojectDetails($filter: ProjectFilter!) {
    projects(filter: $filter) {
      id
      children {
        id
        archivedOn
        projectInfo {
          ...Info
        }
        projectGroup {
          id
          deletedOn
          groupName
        }
        title
      }
    }
  }
  ${ProjectFragments.info}
`;

export const QUICK_SEARCH_PROJECT_QUERY = gql`
  query QuickSearchProjectQuery($filter: ProjectFilter!, $first: Int, $after: String, $sort: [ProjectSort!]) {
    projects(filter: $filter, after: $after, first: $first, sort: $sort) {
      id
      cursor
      title
      depth
      rootProjectId
      archivedOn
    }
  }
`;
