import classNames from 'classnames';
import ProFeatureDialog from 'components/domain/account/ProFeatureDialog/ProFeatureDialog';
import CostCodeTimeEntryDialog from 'components/domain/time-entry/dialog/CostCodeTimeEntryDialog/CostCodeTimeEntryDialog';
import DateRangeTimeEntryDialog from 'components/domain/time-entry/dialog/DateRangeTimeEntryDialog/DateRangeTimeEntryDialog';
import DateTimeEntryDialog from 'components/domain/time-entry/dialog/DateTimeEntryDialog/DateTimeEntryDialog';
import EquipmentTimeEntryDialog from 'components/domain/time-entry/dialog/EquipmentTimeEntryDialog/EquipmentTimeEntryDialog';
import MemberTimeEntryDialog from 'components/domain/time-entry/dialog/MemberTimeEntryDialog/MemberTimeEntryDialog';
import ProjectTimeEntryDialog from 'components/domain/time-entry/dialog/ProjectTimeEntryDialog/ProjectTimeEntryDialog';
import { ActivityReportType } from 'containers/activity-reports/ActivityReportFilter/ActivityReportFilter';
import { usePrintChannelListener } from 'contexts/ChannelContext/hooks/usePrintChannelListener';
import { useOpenable } from 'hooks';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useRef } from 'react';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import CostCodeActivityReportDetailsTable from '../../cost-code-tables/CostCodeActivityReportDetailsTable/CostCodeActivityReportDetailsTable';
import ByDateRangeActivityReportDetailsTable from '../../date-range-tables/DateRangeActivityReportDetailsTable/DateRangeActivityReportDetailsTable';
import DateActivityReportDetailsTable from '../../date-tables/DateActivityReportDetailsTable/DateActivityReportDetailsTable';
import EquipmentActivityReportDetailsTable from '../../equipment-tables/EquipmentActivityReportDetailsTable/EquipmentActivityReportDetailsTable';
import MemberActivityReportDetailsTable from '../../member-tables/MemberActivityReportDetailsTable/MemberActivityReportDetailsTable';
import ProjectActivityReportDetailsTable from '../../project-tables/ProjectActivityReportDetailsTable/ProjectActivityReportDetailsTable';
import './ActivityReportSummary.scss';

export interface IActivityReportSummaryProps {
  className?: ClassName;
  reportType: ActivityReportType;
  secondaryReportType: ActivityReportType;
  timeRange: ITimeRange<DateTime>;
  timeRangeType: TimeRangeType;
  dateRangeReportType: TimeRangeType;
  filterId?: string | null;
  filterType: ActivityReportType;
  filterByUnassignedId?: boolean;
  scroller: HTMLElement | null;
}

const ActivityReportSummary = (props: IActivityReportSummaryProps) => {
  const {
    className,
    reportType,
    secondaryReportType,
    filterType,
    filterId,
    timeRange,
    timeRangeType,
    dateRangeReportType,
    scroller,
    filterByUnassignedId,
  } = props;

  const classes = classNames('activity-report-summary', className);
  const reportDetailsTimeRange = useRef<ITimeRange<DateTime>>(timeRange);
  const reportDetailsTimeRangeType = useRef<TimeRangeType>(timeRangeType);
  const selectedRowId = useRef<string>();
  const selectedRowSecodaryId = useRef<string>();
  const unassignedRowId = useRef<boolean>();
  const reportFilteredDetailsDialog = useOpenable();
  const proUpgradeDialogDetails = useOpenable();
  const isPro = useFeatureFlags('PRO');

  usePrintChannelListener(true);

  let reportTable: ReactNode;
  switch (reportType) {
    case ActivityReportType.BY_PROJECT:
      reportTable = (
        <>
          <ProjectActivityReportDetailsTable
            filterId={filterId!}
            filterType={filterType}
            secondaryReportType={secondaryReportType}
            dateRangeReportType={dateRangeReportType}
            timeRange={timeRange}
            onRowSelected={onProjectRowSelected}
            onDateRowSelected={onSecondaryDateRangeRowSelected}
            scroller={scroller}
          />
          {reportFilteredDetailsDialog.isOpen && (
            <ProjectTimeEntryDialog
              isOpen={reportFilteredDetailsDialog.isOpen}
              onClose={reportFilteredDetailsDialog.close}
              timeRange={reportDetailsTimeRange.current}
              projectIds={selectedRowId.current ? [selectedRowId.current] : undefined}
              memberIds={timeEntryIdFilter(ActivityReportType.BY_EMPLOYEE)}
              costCodeIds={timeEntryIdFilter(ActivityReportType.BY_COST_CODE)}
              equipmentIds={timeEntryIdFilter(ActivityReportType.BY_EQUIPMENT)}
              timeRangeType={reportDetailsTimeRangeType.current}
              includeOpen={false}
              projectIdWithDescendants={!unassignedRowId.current}
              timeRangeForwardPressed={timeActionForword}
              timeRangeBackPressed={timeActionBack}
            />
          )}
        </>
      );
      break;
    case ActivityReportType.BY_EMPLOYEE:
      reportTable = (
        <>
          <MemberActivityReportDetailsTable
            filterId={filterId!}
            filterType={filterType}
            secondaryReportType={secondaryReportType}
            filterIdWithDescendants={!filterByUnassignedId}
            dateRangeReportType={dateRangeReportType}
            timeRange={timeRange}
            onRowSelected={onRowSelected}
            onDateRowSelected={onSecondaryDateRangeRowSelected}
            scroller={scroller}
          />
          {reportFilteredDetailsDialog.isOpen && (
            <MemberTimeEntryDialog
              includeOpen={false}
              timeRange={reportDetailsTimeRange.current}
              memberIds={selectedRowId.current ? [selectedRowId.current] : undefined}
              projectIds={timeEntryIdFilter(ActivityReportType.BY_PROJECT)}
              costCodeIds={timeEntryIdFilter(ActivityReportType.BY_COST_CODE)}
              equipmentIds={timeEntryIdFilter(ActivityReportType.BY_EQUIPMENT)}
              isOpen={reportFilteredDetailsDialog.isOpen}
              onClose={reportFilteredDetailsDialog.close}
              onDataChange={() => onRowSelected}
              timeRangeType={reportDetailsTimeRangeType.current}
              projectIdWithDescendants={!filterByUnassignedId}
              timeRangeForwardPressed={timeActionForword}
              timeRangeBackPressed={timeActionBack}
            />
          )}
        </>
      );
      break;
    case ActivityReportType.BY_COST_CODE:
      reportTable = (
        <>
          <CostCodeActivityReportDetailsTable
            filterId={filterId!}
            filterType={filterType}
            secondaryReportType={secondaryReportType}
            dateRangeReportType={dateRangeReportType}
            timeRange={timeRange}
            onRowSelected={onRowSelected}
            onDateRowSelected={onSecondaryDateRangeRowSelected}
            filterIdWithDescendants={!filterByUnassignedId}
            scroller={scroller}
          />
          {reportFilteredDetailsDialog.isOpen && (
            <CostCodeTimeEntryDialog
              isOpen={reportFilteredDetailsDialog.isOpen}
              onClose={reportFilteredDetailsDialog.close}
              timeRange={reportDetailsTimeRange.current}
              costCodeIds={selectedRowId.current ? [selectedRowId.current] : undefined}
              memberIds={timeEntryIdFilter(ActivityReportType.BY_EMPLOYEE)}
              projectIds={timeEntryIdFilter(ActivityReportType.BY_PROJECT)}
              equipmentIds={timeEntryIdFilter(ActivityReportType.BY_EQUIPMENT)}
              timeRangeType={reportDetailsTimeRangeType.current}
              includeOpen={false}
              projectIdWithDescendants={!filterByUnassignedId}
              timeRangeForwardPressed={timeActionForword}
              timeRangeBackPressed={timeActionBack}
            />
          )}
        </>
      );
      break;
    case ActivityReportType.BY_EQUIPMENT:
      reportTable = (
        <>
          <EquipmentActivityReportDetailsTable
            filterId={filterId!}
            filterType={filterType}
            secondaryReportType={secondaryReportType}
            dateRangeReportType={dateRangeReportType}
            timeRange={timeRange}
            onRowSelected={onRowSelected}
            onDateRowSelected={onSecondaryDateRangeRowSelected}
            filterIdWithDescendants={!filterByUnassignedId}
            scroller={scroller}
          />
          {reportFilteredDetailsDialog.isOpen && (
            <EquipmentTimeEntryDialog
              isOpen={reportFilteredDetailsDialog.isOpen}
              onClose={reportFilteredDetailsDialog.close}
              timeRange={reportDetailsTimeRange.current}
              equipmentIds={selectedRowId.current ? [selectedRowId.current] : undefined}
              memberIds={timeEntryIdFilter(ActivityReportType.BY_EMPLOYEE)}
              projectIds={timeEntryIdFilter(ActivityReportType.BY_PROJECT)}
              costCodeIds={timeEntryIdFilter(ActivityReportType.BY_COST_CODE)}
              projectIdWithDescendants={!filterByUnassignedId}
              timeRangeType={reportDetailsTimeRangeType.current}
              includeOpen={false}
              timeRangeForwardPressed={timeActionForword}
              timeRangeBackPressed={timeActionBack}
            />
          )}
        </>
      );
      break;
    case ActivityReportType.BY_DAY:
      reportTable = (
        <>
          <DateActivityReportDetailsTable
            filterId={filterId!}
            filterType={filterType}
            secondaryReportType={secondaryReportType}
            timeRange={timeRange}
            onRowSelected={onDateRowSelected}
            filterIdWithDescendants={!filterByUnassignedId}
            scroller={scroller}
          />
          {reportFilteredDetailsDialog.isOpen && (
            <DateTimeEntryDialog
              isOpen={reportFilteredDetailsDialog.isOpen}
              onClose={reportFilteredDetailsDialog.close}
              timeRange={reportDetailsTimeRange.current}
              timeRangeType={reportDetailsTimeRangeType.current}
              memberIds={timeEntryIdFilter(ActivityReportType.BY_EMPLOYEE)}
              projectIds={timeEntryIdFilter(ActivityReportType.BY_PROJECT)}
              costCodeIds={timeEntryIdFilter(ActivityReportType.BY_COST_CODE)}
              equipmentIds={timeEntryIdFilter(ActivityReportType.BY_EQUIPMENT)}
              includeOpen={false}
              projectIdWithDescendants={!filterByUnassignedId}
              timeRangeForwardPressed={timeActionForword}
              timeRangeBackPressed={timeActionBack}
            />
          )}
        </>
      );
      break;
    case ActivityReportType.BY_DATE_RANGE:
      reportTable = (
        <>
          <ByDateRangeActivityReportDetailsTable
            filterId={filterId!}
            filterType={filterType}
            secondaryReportType={secondaryReportType}
            timeRange={timeRange}
            dateRangeReportType={dateRangeReportType}
            onRowSelected={onDateRangeRowSelected}
            filterIdWithDescendants={!filterByUnassignedId}
            scroller={scroller}
          />
          {reportFilteredDetailsDialog.isOpen && (
            <DateRangeTimeEntryDialog
              isOpen={reportFilteredDetailsDialog.isOpen}
              onClose={reportFilteredDetailsDialog.close}
              timeRange={reportDetailsTimeRange.current}
              timeRangeType={reportDetailsTimeRangeType.current}
              memberIds={timeEntryIdFilter(ActivityReportType.BY_EMPLOYEE)}
              projectIds={timeEntryIdFilter(ActivityReportType.BY_PROJECT)}
              costCodeIds={timeEntryIdFilter(ActivityReportType.BY_COST_CODE)}
              equipmentIds={timeEntryIdFilter(ActivityReportType.BY_EQUIPMENT)}
              clipDatesToTimeRange={true}
              changableTimeRange={false}
              includeOpen={false}
              projectIdWithDescendants={!filterByUnassignedId}
              timeRangeForwardPressed={timeActionForword}
              timeRangeBackPressed={timeActionBack}
            />
          )}
        </>
      );
      break;
  }

  function timeEntryIdFilter(reportType: ActivityReportType) {
    if (filterType === reportType && !isNil(filterId)) {
      return [filterId];
    } else if (secondaryReportType === reportType && !isNil(selectedRowSecodaryId.current)) {
      return [selectedRowSecodaryId.current];
    }

    return undefined;
  }

  function timeActionForword() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return true;
    }

    return false;
  }

  function timeActionBack() {
    if (!isPro) {
      proUpgradeDialogDetails.open();
      return true;
    }

    return false;
  }

  function onRowSelected(id?: string, secondaryId?: string) {
    selectedRowId.current = id;
    selectedRowSecodaryId.current = secondaryId;

    reportDetailsTimeRange.current = timeRange;
    reportDetailsTimeRangeType.current = timeRangeType;
    reportFilteredDetailsDialog.open();
  }

  function onProjectRowSelected(id?: string, secondaryId?: string, isUnassignedProjectId?: boolean) {
    unassignedRowId.current = isUnassignedProjectId === true;
    onRowSelected(id, secondaryId);
  }

  function onDateRowSelected(date: DateTime, secondaryId?: string) {
    selectedRowId.current = undefined;
    selectedRowSecodaryId.current = secondaryId;
    reportDetailsTimeRange.current = {
      startTime: date.toUTC().startOf('day'),
      endTime: date.toUTC().endOf('day'),
    };
    reportDetailsTimeRangeType.current = TimeRangeType.DAILY;
    reportFilteredDetailsDialog.open();
  }

  function onDateRangeRowSelected(
    startDate: DateTime,
    endDate: DateTime,
    dateRangeType: TimeRangeType,
    secondaryId?: string
  ) {
    selectedRowId.current = undefined;
    selectedRowSecodaryId.current = secondaryId;
    reportDetailsTimeRange.current = {
      startTime: startDate.toUTC().startOf('day'),
      endTime: endDate.toUTC().endOf('day'),
    };
    reportDetailsTimeRangeType.current = dateRangeType;
    reportFilteredDetailsDialog.open();
  }

  function onSecondaryDateRangeRowSelected(
    id: string,
    startDate: DateTime,
    endDate: DateTime,
    isUnassignedProjectId?: boolean
  ) {
    unassignedRowId.current = isUnassignedProjectId === true;
    selectedRowId.current = id;
    selectedRowSecodaryId.current = undefined;
    reportDetailsTimeRange.current = {
      startTime: startDate.toUTC().startOf('day'),
      endTime: endDate.toUTC().endOf('day'),
    };
    reportDetailsTimeRangeType.current =
      secondaryReportType === ActivityReportType.BY_DAY ? TimeRangeType.DAILY : dateRangeReportType;
    reportFilteredDetailsDialog.open();
  }

  return (
    <div className={classes}>
      {reportTable}
      <ProFeatureDialog
        customMessage={t('Viewing historical activity report data is not available in your subscription.')}
        isOpen={proUpgradeDialogDetails.isOpen}
        onClose={proUpgradeDialogDetails.close}
      />
    </div>
  );
};

export default ActivityReportSummary;
