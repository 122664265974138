import { DashboardTimeFrame } from 'containers/dashboard/types/types';
import { useApolloPaging } from 'hooks';
import {
  memberCostCodeMetricQueryProvider,
  memberEquipmentMetricQueryProvider,
  memberLaborMetricQueryProvider,
  memberProjectMetricQueryProvider,
} from 'hooks/aggregates/metrics/MemberMetricQueryProviders';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import MemberPermission from 'types/enum/MemberPermission';
import ITimeRange from 'types/TimeRange';
import { ReportType } from 'utils/aggregateUtils';
import useAggregateGraphData from './useAggregateGraphData';

export default function useDashboardAggregateData(
  timeRange: ITimeRange<DateTime>,
  timeFrame: DashboardTimeFrame,
  reportType: ReportType,
  permissions: MemberPermission,
  executeImmediately: boolean,
  type: 'project' | 'employee' | 'costCode' | 'equipment',
  filterId?: string
) {
  const { getAll } = useApolloPaging();

  const queryProvider = useMemo(() => {
    switch (type) {
      case 'employee':
        return memberLaborMetricQueryProvider(
          getAll,
          permissions,
          filterId ? [filterId] : undefined,
          undefined,
          'unarchived',
          reportType === ReportType.SECONDS
        );
      case 'project':
        return memberProjectMetricQueryProvider(
          getAll,
          permissions,
          undefined,
          filterId ? [filterId] : undefined,
          undefined,
          'unarchived',
          reportType === ReportType.SECONDS
        );
      case 'costCode':
        return memberCostCodeMetricQueryProvider(
          getAll,
          permissions,
          undefined,
          filterId ? [filterId] : undefined,
          undefined,
          'unarchived',
          reportType === ReportType.SECONDS
        );
      case 'equipment':
        return memberEquipmentMetricQueryProvider(
          getAll,
          permissions,
          undefined,
          filterId ? [filterId] : undefined,
          undefined,
          'unarchived',
          reportType === ReportType.SECONDS
        );
    }
  }, [type, filterId, reportType, permissions]);

  return useAggregateGraphData(timeRange, timeFrame, reportType, queryProvider, executeImmediately);
}
