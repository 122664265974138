import useIsInIframe from 'hooks/store/useIsInIframe';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeatureFlags } from 'utils/features';
import useLegacySettingsNavigation from './useLegacySettingsNavigation';

export default function useDailySignatureSettingNavigation() {
  const toggle = useFeatureFlags('SETTINGS');
  const location = useLocation();
  const navigate = useNavigate();
  const isInIframe = useIsInIframe();

  const legacyNavigation = useLegacySettingsNavigation();
  const settingsNavigation = useCallback(() => {
    navigate({ pathname: '/settings/daily-sign-in-out' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (toggle) {
    return settingsNavigation;
  } else if (!isInIframe) {
    return legacyNavigation;
  } else {
    // This only occurs if they have settings turned off (will never happen) and they're in an iframe
    throw Error('No navigation to settings available');
  }
}
