import { useMemo } from 'react';
import { emptyObject } from 'types/util/Empty';
import { useOrganization } from '..';

export default function useCurrencyNumberFormatter(
  defaultCode: string | null = null,
  options: Intl.NumberFormatOptions = emptyObject
) {
  const organization = useOrganization();

  return useMemo(() => {
    return organization.organizationInfo?.currencyCode || defaultCode
      ? new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: organization.organizationInfo?.currencyCode ?? defaultCode!,
          ...options,
        })
      : null;
  }, [defaultCode, options, organization.organizationInfo?.currencyCode]);
}
