import { useOrganization } from 'hooks';
import { useMemo } from 'react';
import { v_require } from 'utils/validations';

export default function useTimeEntryProjectValidation() {
  const organization = useOrganization();

  return useMemo(() => {
    if (organization.requireProject) {
      return [{ validation: v_require }];
    } else {
      return [];
    }
  }, [organization.requireProject]);
}
