import { Align, Button, Dialog, Icon, Row, Theme, Tooltip, Tray } from '@busybusy/webapp-react-ui';
import { BusyBusyIcon, CloseIcon } from 'assets/icons';
import classNames from 'classnames';
import Graylog from 'components/domain/analytics/Graylog/Graylog';
import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import Thumbnail from 'components/foundation/Thumbnail/Thumbnail';
import IconButton from 'components/foundation/buttons/IconButton/IconButton';
import ConfirmationDialog from 'components/foundation/dialogs/ConfirmationDialog/ConfirmationDialog';
import HeaderDialog from 'components/foundation/dialogs/HeaderDialog/HeaderDialog';
import Header from 'components/layout/Header/Header';
import Panel from 'components/layout/Panel/Panel';
import BulletinEnableForm from 'containers/bulletin/BulletinEnableForm/BulletinEnableForm';
import DailyProjectReportEnableForm from 'containers/daily-project-report/DailyProjectReportEnableForm/DailyProjectReportEnableForm';
import WistiaVideoEmbed from 'containers/dashboard/Dashboard/PremiumTrialDialog/WistiaVideoEmbed/WistiaVideoEmbed';
import DocumentEnableForm from 'containers/documents/DocumentEnableForm/DocumentEnableForm';
import ProgressTrackingEnableForm from 'containers/progress-report/ProgressTrackingEnableForm/ProgressTrackingEnableForm';
import LaborBudgetsForm from 'containers/settings/budgeting/forms/LaborBudgetsForm';
import { useActiveMember, useOpenable, useOrganization } from 'hooks';
import useMemberUiSettingsUpdate from 'hooks/models/member-settings/useMemberUiSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { useOrganizationSettings } from 'hooks/models/organization/settings/useOrganizationSettings/useOrganizationSettings';
import useIsInIframe from 'hooks/store/useIsInIframe';
import useOnMount from 'hooks/utils/useOnMount/useOnMount';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { ClassName } from 'types/ClassName';
import AnalyticsEvent from 'utils/constants/analytics/analyticsEvent';
import { useFeatureFlags } from 'utils/features';
import LocalStore from 'utils/localStorageUtils';
import { t } from 'utils/localize';
import { EnablePremiumDialog } from '../EnablePremiumDialog/EnablePremiumDialog';
import './DashboardAnnouncement.scss';
import SafetyReportsEnableForm from './SafetyReportsEnableForm/SafetyReportsEnableForm';
import { SafetyReportsConfirmationForm } from './SafetyReportsConfirmationDialog/SafetyReportsConfirmationForm';
import { VideoThumbnail_2024_6 } from 'assets/images';

export interface IDashboardAnnouncementProps {
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
}

export const latestAnnouncementVersion = '2024.6';
export const latestAnnouncementKey = `announcement-${latestAnnouncementVersion}-content`;

const announcementLinksLookup = {
  allReleases: 'https://fast.wistia.com/embed/channel/fwd5oxatzi',
  release2022Dot10: 'https://fast.wistia.net/embed/channel/fwd5oxatzi?wchannelid=fwd5oxatzi&wmediaid=smg5bapmq7',
  release2023Dot1: 'https://fast.wistia.net/embed/channel/fwd5oxatzi?wchannelid=fwd5oxatzi&wmediaid=hwriddjbot',
  release2023Dot2: 'https://busybusy.wistia.com/medias/0vx391szrs',
  releaseOverview: 'https://busybusy.wistia.com/medias/nlk0xt9sf7',
  release2023Dot2Part1: 'https://busybusy.wistia.com/medias/w96t5camye',
  release2023Dot2Part2: 'https://busybusy.wistia.com/medias/634pqm1bek',
  release2023Dot2Part3: 'https://busybusy.wistia.com/medias/rpf4jxivvi',
  release2023Dot2Part4: 'https://busybusy.wistia.com/medias/pvoqm3vyl7',
  release2023Dot2Part5: 'https://busybusy.wistia.com/medias/208aoiqnrq',
  bulletinOverviewFreeAndPro: 'https://busybusy.com/product-videos/?wchannelid=q3wum81pn9&wmediaid=h80kvnvhvz',
  bulletinOverviewPremium: 'https://fast.wistia.net/embed/channel/nhzxwhi095?wchannelid=nhzxwhi095&wmediaid=0q7k278zad',
  bulletinOverviewPremiumTrial:
    'https://fast.wistia.net/embed/channel/ycm5d84606?wchannelid=ycm5d84606&wmediaid=f7jhx2xvkc',
};

const DashboardAnnouncement = (props: IDashboardAnnouncementProps) => {
  const { className, theme } = props;
  const isInIframe = useIsInIframe();

  const classes = classNames(
    'dashboard-annoucement',
    theme,
    'mt-6',
    'px-8',
    'pt-4',
    'pb-4',
    { 'is-in-iframe': isInIframe },
    className
  );
  const memberSettings = useMemberSettings();
  const organizationSettings = useOrganizationSettings();
  const organization = useOrganization();

  const member = useActiveMember();
  const isPro = useFeatureFlags('PRO');
  const isPremium = useFeatureFlags('PREMIUM');
  const isPremiumTrial = useFeatureFlags('PREMIUM_TRIAL');
  const hasDocumentsAccess = useFeatureFlags('DOCUMENTS');
  const hasBulletinAccess = useFeatureFlags('BULLETIN_FEATURE');
  const hasDailyProjectReportAccess = useFeatureFlags('DAILY_REPORTS');
  const hasProgressTrackingAccess = useFeatureFlags('WORK_ACCOMPLISHED');
  const hasSafetyReportsAccess = useFeatureFlags('SAFETY_INCIDENTS');
  const [showDocumentsAction, setShowDocumentsAction] = useState(false);
  const [showBulletinAction, setShowBulletinAction] = useState(false);
  const [showDailyProjectReportAction, setShowDailyProjectReportAction] = useState(false);
  const [showProgressTrackingAction, setShowProgressTrackingAction] = useState(false);
  const [showSafetyReportsAction, setShowSafetyReportsAction] = useState(false);
  const updateUiSettings = useMemberUiSettingsUpdate();
  const memberSettingsAnnouncementVersion = memberSettings?.web?.ui?.hideAnnouncementTextForVersion;
  const showAnnouncement = memberSettingsAnnouncementVersion !== latestAnnouncementVersion;
  const canManageCompanySettings = member.position?.manageCompanySettings ?? false;

  const documentDialogOpenable = useOpenable();
  const documentRequestCompleteOpenable = useOpenable();
  const bulletinDialogOpenable = useOpenable();
  const bulletinRequestCompleteOpenable = useOpenable();
  const dailyProjectReportDialogOpenable = useOpenable();
  const dailyProjectReportRequestCompleteOpenable = useOpenable();
  const progressTrackingDialogOpenable = useOpenable();
  const progressTrackingRequestCompleteOpenable = useOpenable();
  const safetyReportsDialogOpenable = useOpenable();
  const safetyReportsRequestCompleteOpenable = useOpenable();
  const premiumDialogOpenable = useOpenable();
  const thumbnailVideoOpenable = useOpenable();
  const enableBudgetDialogOpenable = useOpenable();

  function graylogEnableBulletin() {
    Graylog({
      shortMsg: AnalyticsEvent.announcementText.UPGRADE_REQUESTED,
      logObject: {
        _identifier: latestAnnouncementVersion,
        _member_id: member.id,
        _type: 'Enable Bulletin',
      },
    });
  }

  function graylogEnableDocuments() {
    Graylog({
      shortMsg: AnalyticsEvent.announcementText.UPGRADE_REQUESTED,
      logObject: {
        _identifier: latestAnnouncementVersion,
        _member_id: member.id,
        _type: 'Enable Documents',
      },
    });
  }

  function graylogEnableDailyProjectReports() {
    Graylog({
      shortMsg: AnalyticsEvent.announcementText.UPGRADE_REQUESTED,
      logObject: {
        _identifier: latestAnnouncementVersion,
        _member_id: member.id,
        _type: 'Enable Field Reports',
      },
    });
  }

  function graylogEnableProgressTracking() {
    Graylog({
      shortMsg: AnalyticsEvent.announcementText.UPGRADE_REQUESTED,
      logObject: {
        _identifier: latestAnnouncementVersion,
        _member_id: member.id,
        _type: 'Enable Progress Tracking',
      },
    });
  }

  function graylogEnableSafetyReports() {
    Graylog({
      shortMsg: AnalyticsEvent.announcementText.UPGRADE_REQUESTED,
      logObject: {
        _identifier: latestAnnouncementVersion,
        _member_id: member.id,
        _type: 'Enable Safety',
      },
    });
  }

  function graylogUpgradePremium() {
    Graylog({
      shortMsg: AnalyticsEvent.announcementText.UPGRADE_REQUESTED,
      logObject: {
        _identifier: latestAnnouncementVersion,
        _member_id: member.id,
        _type: 'Upgrade Premium',
      },
    });
  }

  const onClose = () => {
    updateUiSettings('hideAnnouncementTextForVersion', latestAnnouncementVersion);
  };

  useOnMount(() => {
    if (
      latestAnnouncementVersion !== memberSettingsAnnouncementVersion &&
      !isNil(LocalStore.get(latestAnnouncementKey)) &&
      LocalStore.get(latestAnnouncementKey) === true
    ) {
      updateUiSettings('hideAnnouncementTextForVersion', latestAnnouncementVersion);
    }
  });

  useEffect(() => {
    setShowDocumentsAction(!hasDocumentsAccess && organizationSettings?.web?.ui?.requestedDocumentsAccess !== true);
  }, [hasDocumentsAccess, organizationSettings]);
  useEffect(() => {
    setShowBulletinAction(!hasBulletinAccess && organizationSettings?.web?.ui?.requestedBulletinAccess !== true);
  }, [hasBulletinAccess, organizationSettings]);
  useEffect(() => {
    setShowDailyProjectReportAction(
      !hasDailyProjectReportAccess && organizationSettings?.web?.ui?.requestedDailyProjectReportsAccess !== true
    );
  }, [hasDailyProjectReportAccess, organizationSettings]);
  useEffect(() => {
    setShowProgressTrackingAction(
      !hasProgressTrackingAccess && organizationSettings?.web?.ui?.requestedProgressTrackingAccess !== true
    );
  }, [hasProgressTrackingAccess, organizationSettings]);
  useEffect(() => {
    setShowSafetyReportsAction(
      !hasSafetyReportsAccess && organizationSettings?.web?.ui?.requestedSafetyReportsAccess !== true
    );
  }, [hasSafetyReportsAccess, organizationSettings]);

  const onDocumentRequestSubmitted = () => {
    setShowDocumentsAction(false);
    documentRequestCompleteOpenable.open();
    documentDialogOpenable.close();
  };

  const onBulletinRequestSubmitted = () => {
    setShowBulletinAction(false);
    bulletinRequestCompleteOpenable.open();
    bulletinDialogOpenable.close();
  };

  const onDailyProjectReportRequestSubmitted = () => {
    setShowDailyProjectReportAction(false);
    dailyProjectReportRequestCompleteOpenable.open();
    dailyProjectReportDialogOpenable.close();
  };

  const onProgressTrackingRequestSubmitted = () => {
    setShowProgressTrackingAction(false);
    progressTrackingRequestCompleteOpenable.open();
    progressTrackingDialogOpenable.close();
  };

  const onSafetyReportsRequestSubmitted = () => {
    setShowSafetyReportsAction(false);
    safetyReportsRequestCompleteOpenable.open();
    safetyReportsDialogOpenable.close();
  };

  const onLinkClicked = (type: keyof typeof announcementLinksLookup) => {
    const link = announcementLinksLookup[type]!;
    window.open(link);
  };

  function renderVideoHeader(onClose: () => void) {
    return (
      <Header className="header">
        <Tray>
          <Tooltip label={'Close'}>
            <Button type="icon" theme={Theme.DARK} onClick={onClose}>
              <Icon svg={CloseIcon} />
            </Button>
          </Tooltip>
        </Tray>
      </Header>
    );
  }

  const renderTitle = () => (
    <Row align={Align.CENTER}>
      <p className="title">{t(`New Release | ${latestAnnouncementVersion}`)}</p>
    </Row>
  );

  const renderIcon = () => <Icon className="announcement-icon" svg={BusyBusyIcon} />;

  const CurrentReleaseDetails = () => (
    <div className="content-column pb-5">
      <Thumbnail className={'my-2'} fileUrl={VideoThumbnail_2024_6} onClick={thumbnailVideoOpenable.open} />
    </div>
  );

  const PreviousReleaseDetails = () => (
    <div className="content-column ml-10">
      <div className="content-title mb-4">{t(`Boost Jobsite Safety with busybusy’s New Safety Inspections!`)}</div>
      <div className="content mb-4">
        {t(
          `We're excited to introduce busybusy's new Safety Inspections feature, designed to streamline your safety protocols. Easily create, customize, and manage inspections directly from the app, ensuring your team stays safe and your projects run smoothly. \n\n`
        )}
        {t(
          `Additionally, you can now export OSHA 5020 Forms with our updated Incident Reports, perfect for California users. \n\n`
        )}
        {t(
          `Our Safety Module, including Incidents, Toolbox Talks, and Inspections, is free to use until October. Watch the video to learn more and click the Safety Link below to get started!`
        )}
      </div>
      <ConditionalContainer
        condition={
          isPro && !isPremium && !isPremiumTrial && canManageCompanySettings && showProgressTrackingAction && false // hide button for now
        }
      >
        <Button
          className="mt-4 mb-8"
          type="primary"
          onClick={() => {
            graylogEnableProgressTracking();
            progressTrackingDialogOpenable.open();
          }}
        >
          {t('Enable Progress Tracking')}
        </Button>
      </ConditionalContainer>
      <ConditionalContainer
        condition={
          isPremium && canManageCompanySettings && !organization.performanceScores && false // hide button for now
        }
      >
        <Button className="mt-4 mb-8" type="secondary" onClick={enableBudgetDialogOpenable.open}>
          {t('Turn On Performance Scores')}
        </Button>
      </ConditionalContainer>
      <ConditionalContainer
        condition={
          canManageCompanySettings &&
          ((isPro && !isPremium) ||
            showDocumentsAction ||
            showDailyProjectReportAction ||
            showSafetyReportsAction ||
            showBulletinAction ||
            showProgressTrackingAction)
        }
      >
        <div className="content-title">{t('Available Upgrades')}</div>
        <Tray>
          <ul className="pl-4">
            {isPro && !isPremium && (
              <li>
                <a
                  className="link ml-1"
                  onClick={() => {
                    graylogUpgradePremium();
                    premiumDialogOpenable.open();
                  }}
                >
                  {t('Premium')}
                </a>
              </li>
            )}
            {isPro && !isPremium && showDocumentsAction && (
              <li>
                <a
                  className="link ml-1"
                  onClick={() => {
                    graylogEnableDocuments();
                    documentDialogOpenable.open();
                  }}
                >
                  {t('Documents')}
                </a>
              </li>
            )}
            {isPro && !isPremium && showDailyProjectReportAction && (
              <li>
                <a
                  className="link ml-1"
                  onClick={() => {
                    graylogEnableDailyProjectReports();
                    dailyProjectReportDialogOpenable.open();
                  }}
                >
                  {'Field Reports'}
                </a>
              </li>
            )}
          </ul>
          <ul className="pl-6">
            {isPro && !isPremium && showBulletinAction && (
              <li>
                <a
                  className="link ml-1"
                  onClick={() => {
                    graylogEnableBulletin();
                    bulletinDialogOpenable.open();
                  }}
                >
                  {'Bulletin'}
                </a>
              </li>
            )}
            {isPro && !isPremium && showProgressTrackingAction && (
              <li>
                <a
                  className="link ml-1"
                  onClick={() => {
                    graylogEnableProgressTracking();
                    progressTrackingDialogOpenable.open();
                  }}
                >
                  {'Progress Tracking'}
                </a>
              </li>
            )}
            {isPro && showSafetyReportsAction && (
              <li>
                <a
                  className="link ml-1"
                  onClick={() => {
                    graylogEnableSafetyReports();
                    safetyReportsDialogOpenable.open();
                  }}
                >
                  {'Safety'}
                </a>
              </li>
            )}
          </ul>
        </Tray>
      </ConditionalContainer>
    </div>
  );

  const renderContent = () => {
    return (
      <Row className="mt-2">
        <CurrentReleaseDetails />
        <PreviousReleaseDetails />
      </Row>
    );
  };

  const renderAnnouncement = () => {
    return (
      <>
        <div className={classes}>
          <IconButton className="close-icon" svg={CloseIcon} onClick={onClose} />
          <Row align={Align.CENTER} className={theme}>
            {renderIcon()}
            {renderTitle()}
          </Row>
          {renderContent()}
        </div>

        <HeaderDialog
          isOpen={documentDialogOpenable.isOpen}
          onClose={documentDialogOpenable.close}
          divider={false}
          title={t('Documents')}
        >
          <DocumentEnableForm className="px-8 pb-9" onRequestSubmitted={onDocumentRequestSubmitted} />
        </HeaderDialog>
        <ConfirmationDialog
          title={t('Submitted')}
          message={t(
            'Your request has been submitted and Documents will soon be enabled for your account. We’ll send you a message letting you know when the feature is ready to use. Thank you!'
          )}
          isOpen={documentRequestCompleteOpenable.isOpen}
          confirmButtonText={t('OK')}
          onConfirm={documentRequestCompleteOpenable.close}
        />

        <HeaderDialog
          isOpen={bulletinDialogOpenable.isOpen}
          onClose={bulletinDialogOpenable.close}
          divider={false}
          title={t('Bulletin')}
        >
          <BulletinEnableForm className="px-8 pb-9" onRequestSubmitted={onBulletinRequestSubmitted} />
        </HeaderDialog>
        <ConfirmationDialog
          title={t('Submitted')}
          message={t(
            'Your request has been submitted and Bulletin will soon be enabled for your account. We’ll send you a message letting you know when the feature is ready to use. Thank you!'
          )}
          isOpen={bulletinRequestCompleteOpenable.isOpen}
          confirmButtonText={t('OK')}
          onConfirm={bulletinRequestCompleteOpenable.close}
        />

        <HeaderDialog
          isOpen={dailyProjectReportDialogOpenable.isOpen}
          onClose={dailyProjectReportDialogOpenable.close}
          divider={false}
          title={t('Field Reports')}
        >
          <DailyProjectReportEnableForm
            className="px-8 pb-9"
            onRequestSubmitted={onDailyProjectReportRequestSubmitted}
          />
        </HeaderDialog>
        <ConfirmationDialog
          title={t('Submitted')}
          message={t(
            'Your request has been submitted and Field Reports will soon be enabled for your account. We’ll send you a message letting you know when the feature is ready to use. Thank you!'
          )}
          isOpen={dailyProjectReportRequestCompleteOpenable.isOpen}
          confirmButtonText={t('OK')}
          onConfirm={dailyProjectReportRequestCompleteOpenable.close}
        />

        <HeaderDialog
          isOpen={progressTrackingDialogOpenable.isOpen}
          onClose={progressTrackingDialogOpenable.close}
          divider={false}
          title={t('Progress Tracking')}
        >
          <ProgressTrackingEnableForm className="px-8 pb-9" onRequestSubmitted={onProgressTrackingRequestSubmitted} />
        </HeaderDialog>
        <ConfirmationDialog
          title={t('Submitted')}
          message={t(
            'Your request has been submitted and Progress Tracking will soon be enabled for your account. We’ll send you a message letting you know when the feature is ready to use. Thank you!'
          )}
          isOpen={progressTrackingRequestCompleteOpenable.isOpen}
          confirmButtonText={t('OK')}
          onConfirm={progressTrackingRequestCompleteOpenable.close}
        />

        <HeaderDialog
          isOpen={safetyReportsDialogOpenable.isOpen}
          onClose={safetyReportsDialogOpenable.close}
          divider={false}
          title={t('Safety')}
        >
          <SafetyReportsEnableForm className="px-8 pb-9" onRequestSubmitted={onSafetyReportsRequestSubmitted} />
        </HeaderDialog>
        <HeaderDialog
          isOpen={safetyReportsRequestCompleteOpenable.isOpen}
          onClose={safetyReportsRequestCompleteOpenable.close}
          divider={false}
          title={'Submitted'}
        >
          <SafetyReportsConfirmationForm onConfirm={safetyReportsRequestCompleteOpenable.close} />
        </HeaderDialog>

        <HeaderDialog
          isOpen={premiumDialogOpenable.isOpen}
          onClose={premiumDialogOpenable.close}
          title={t('Available Services')}
          size={'full'}
        >
          <EnablePremiumDialog />
        </HeaderDialog>

        <Dialog
          hasDismiss={false}
          size="full"
          isOpen={thumbnailVideoOpenable.isOpen}
          onClose={thumbnailVideoOpenable.close}
          className={'video-fullscreen-viewer'}
        >
          <Panel>
            {renderVideoHeader(thumbnailVideoOpenable.close)}
            <div className="wistia-container">
              <WistiaVideoEmbed videoId="9wsry9vjy2" />
            </div>
          </Panel>
        </Dialog>

        <Dialog isOpen={enableBudgetDialogOpenable.isOpen} onClose={enableBudgetDialogOpenable.close} position="center">
          <LaborBudgetsForm
            className="p-8"
            trackBudgets={organization.trackBudgets ?? false}
            trackBudgetCost={organization.trackBudgetCost ?? false}
            trackBudgetEquipmentHours={organization.trackBudgetEquipmentHours ?? false}
            trackBudgetEquipmentCosts={organization.trackBudgetEquipmentCosts ?? false}
            trackBudgetProgress={organization.trackBudgetProgress ?? false}
            trackBudgetProgressValue={organization.trackBudgetProgressValue ?? false}
            trackCostCode={organization.trackCostCode ?? false}
            trackEquipment={organization.trackEquipment ?? false}
            performanceScores={organization.performanceScores ?? false}
            onSubmit={enableBudgetDialogOpenable.close}
          />
        </Dialog>
      </>
    );
  };

  return showAnnouncement ? renderAnnouncement() : <></>;
};

export default DashboardAnnouncement;
