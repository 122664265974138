import { ActivityReportGraphType } from 'containers/activity-reports/ActivityReportGraph/ActivityReportGraph';
import TimeRangeType from 'types/TimeRangeType';
import useNavigation from './useNavigation';

interface IParams {
  start_date?: string;
  end_date?: string;
  time_range_type?: TimeRangeType;
  filter_type?: string;
  graph_type?: ActivityReportGraphType;
  employee_id?: string;
  project_id?: string;
  cost_code_id?: string;
  equipment_id?: string;
}

export default function useActivityReportNavigation() {
  return useNavigation<IParams>('/activity-report');
}
