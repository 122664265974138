import ConditionalContainer from 'components/foundation/ConditionalContainer/ConditionalContainer';
import { isWithinSevenDays } from 'containers/login/utils/utils';
import { useActiveMember, useReduxSelector } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useMemberWebSettingsUpdate from 'hooks/models/member/useMemberWebSettingsUpdate';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalStore from 'utils/localStorageUtils';
import './App.scss';
import ConnectedLoader from './ConnectedLoader/ConnectedLoader';
import ConnectedToast from './ConnectedToast/ConnectedToast';
import { AppRoutes } from './Routes';
import { BeforeRoutes } from './beforeRoutes/BeforeRoutes';
import ConnectedCommandPallet from './ConnectedCommandPallet/ConnectedCommandPallet';
import useIframeFeatures from 'hooks/iframe/useIframeFeatures/useIframeFeatures';
import AuthenticationDependentMiddleware from 'components/middleware/AuthenticationDependentMiddleware';

const App = () => {
  const launchDarklyHasSynced = useReduxSelector((state) => state.launchDarkly.hasSynced);
  const isAuthenticated = useReduxSelector((state) => state.session.isAuthenticated);
  const shouldShowContent = !isAuthenticated || !!launchDarklyHasSynced;
  const memberSettings = useMemberSettings();
  const updateWebSettings = useMemberWebSettingsUpdate();
  const activeMember = useActiveMember();
  const navigate = useNavigate();
  const pathName = useLocation().pathname;

  useIframeFeatures();

  useEffect(() => {
    if (isAuthenticated) {
      if (
        activeMember.firstName === 'John' &&
        activeMember.lastName === 'Doe' &&
        isNil(activeMember.phone) &&
        isWithinSevenDays(activeMember.organization!.createdOn) &&
        activeMember.id === activeMember.organization!.ownedBy &&
        pathName !== `/owner-onboarding`
      ) {
        navigate({ ...location, pathname: `/owner-onboarding` });
      }
      if (isNil(memberSettings?.web?.ui?.uiLocalStoreMigrated)) {
        const currentMemberUiSettings = memberSettings?.web?.ui;
        const localPreferences = LocalStore.get('preferences');
        const localNameFormat = localPreferences ? localPreferences.employeeNameFormat : 'FIRST_LAST';
        updateWebSettings('ui', {
          ...currentMemberUiSettings,
          uiLocalStoreMigrated: true,
          employeeNameFormat: localNameFormat,
        });
        LocalStore.remove('preferences');
      }
    }
  }, [isAuthenticated, memberSettings?.web?.ui]);

  return (
    <div className="app">
      <BeforeRoutes>
        <ConditionalContainer condition={!!shouldShowContent}>
          <AuthenticationDependentMiddleware>
            <AppRoutes />
          </AuthenticationDependentMiddleware>
        </ConditionalContainer>
      </BeforeRoutes>
      <ConnectedToast />
      <ConnectedLoader />
      <ConnectedCommandPallet />
    </div>
  );
};

export default App;
