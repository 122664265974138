import { useApolloClient } from '@apollo/client';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ThumbnailTray } from 'components';
import { RECENT_PHOTOS_QUERY } from 'components/domain/photos/RecentPhotosDashboardCard/Queries/recent-photos-queries';
import StateTemplate from 'components/foundation/state-templates/StateTemplate/StateTemplate';
import { MediaEntry } from 'containers/photos/MediaEntry/MediaEntry';
import { PhotoFullscreenViewer } from 'containers/photos/PhotoFullscreenViewer/PhotoFullscreenViewer';
import { deleteMediaEntry } from 'containers/photos/photos-queries';
import { useActiveMember } from 'hooks';
import { isEmpty, isNil, map, some } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { IMediaCollection, IMediaEntry } from 'types';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import { useFeatureFlags } from 'utils/features';
import { t } from 'utils/localize';
import './MediaEntryThumbnailTray.scss';
import useMediaCollectionViewPermissionCreatedByFilter from 'hooks/models/media-collection/useMediaCollectionViewPermissionCreatedByFilter/useMediaCollectionViewPermissionCreatedByFilter';

export interface IMediaEntryThumbnailTrayProps {
  className?: ClassName;
  memberId?: string | null;
  projectId?: string | null;
  timeRange?: ITimeRange<DateTime> | null;
  photoLimit: number;
  handleOnClick: (e: React.MouseEvent) => void;
  onDelete?: () => void;
}

const MediaEntryThumbnailTray = (props: IMediaEntryThumbnailTrayProps) => {
  const { className, memberId, projectId, photoLimit, handleOnClick, timeRange, onDelete } = props;

  const isPro = useFeatureFlags('PRO');
  const client = useApolloClient();
  const [mediaCollections, setMediaCollections] = useState<IMediaCollection[]>([]);
  const [fullScreenViewerEntry, setFullScreenViewerEntry] = useState<IMediaEntry | undefined>(undefined);
  const activeMember = useActiveMember();
  const mediaCollectionViewPermissionFilter = useMediaCollectionViewPermissionCreatedByFilter();

  useEffect(() => {
    if (isPro) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPro, memberId, projectId, timeRange, photoLimit]);

  async function getData() {
    const mediaEntriesQuery = await client.query<{ mediaEntries: IMediaEntry[] }>({
      query: RECENT_PHOTOS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        first: photoLimit,
        filter: {
          collection: {
            createdBy: isNil(memberId) ? undefined : { equal: memberId },
            createdByMember: {
              permissions: mediaCollectionViewPermissionFilter,
            },
            project: {
              idWithDescendants: !isNil(projectId) ? { equal: projectId } : undefined,
              archivedOn: { isNull: true },
            },
            deletedOn: { isNull: true },
            createdOn: !isNil(timeRange)
              ? {
                  between: {
                    start: timeRange.startTime
                      .minus(timeRange.startTime.toLocal().offset * 60 * 1000)
                      .toISO({ suppressSeconds: true }),
                    end: timeRange.endTime
                      .minus(timeRange.endTime.toLocal().offset * 60 * 1000)
                      .toISO({ suppressSeconds: true }),
                  },
                }
              : undefined,
          },
          deletedOn: { isNull: true },
        },
        sort: [{ createdOn: 'asc' }],
      },
    });
    const newCollections: IMediaCollection[] = [];
    mediaEntriesQuery.data.mediaEntries.map((entry) => {
      if (entry.collection) {
        if (!some(newCollections, entry.collection)) {
          newCollections.push(entry.collection);
        }
      }
    });
    setMediaCollections(
      newCollections.map((collection) => ({
        ...collection,
        mediaEntries: mediaEntriesQuery.data.mediaEntries.filter((e) => e.mediaCollectionId === collection.id),
      }))
    );
  }

  function onMediaEntryClicked(entry: IMediaEntry) {
    setFullScreenViewerEntry(entry);
  }

  function closeFullScreenViewer() {
    setFullScreenViewerEntry(undefined);
  }

  function renderMediaEntries() {
    let mediaEntries: IMediaEntry[] = [];
    mediaCollections.map((collection) => {
      mediaEntries = mediaEntries.concat(collection.mediaEntries.filter((entry) => entry.deletedOn === null));
    });

    if (mediaEntries.length === 0) {
      return <StateTemplate className="empty-state" title={t('No Thumbnails')} />;
    }

    const entries = mediaEntries.map((entry: IMediaEntry, index: number) => {
      if (index < photoLimit) {
        return (
          <MediaEntry
            apolloClient={client}
            mediaCollections={mediaCollections}
            checkable={false}
            clickable={true}
            key={entry.id}
            mediaEntry={entry}
            onMediaEntryClicked={onMediaEntryClicked}
            showDock={false}
          />
        );
      } else {
        return null;
      }
    });
    if (!isEmpty(entries)) {
      entries.push(
        <div key="view-all-cell" className="view-all-cell" onClick={handleOnClick}>
          {t('View All')}
        </div>
      );
    }
    return entries;
  }

  async function handleSingleMediaEntryDelete(entry: IMediaEntry) {
    const mediaEntriesError = await handleDeleteMediaEntries([entry]);

    if (mediaEntriesError) {
      return mediaEntriesError;
    } else {
      return null;
    }
  }

  async function handleDeleteMediaEntries(entries: IMediaEntry[]) {
    const promises = map(entries, (mediaEntry) => {
      return client.mutate({
        mutation: deleteMediaEntry,
        variables: {
          deletedOn: dateUtils.isoTimeStampUtc(),
          id: mediaEntry.id,
        },
      });
    });

    try {
      await Promise.all(promises);
      getData();
      onDelete?.();
      return null;
    } catch (error) {
      return error;
    }
  }

  const classes = classNames('media-entry-thumbnail-tray', className);

  return (
    <div className={classes}>
      <ThumbnailTray className="recent-photo-content">{renderMediaEntries()}</ThumbnailTray>
      <PhotoFullscreenViewer
        authMember={activeMember}
        apolloClient={client}
        mediaCollections={mediaCollections}
        currentMediaEntry={fullScreenViewerEntry}
        deleteEntryHandler={handleSingleMediaEntryDelete}
        isOpen={fullScreenViewerEntry !== undefined}
        onClose={closeFullScreenViewer}
      />
    </div>
  );
};

export default MediaEntryThumbnailTray;
