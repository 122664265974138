import { ProgressBudget } from '__generated__/graphql';
import gql from 'graphql-tag';
import { useApolloPaging } from 'hooks';
import { isEmpty } from 'lodash';

export default function useGetProgressBudget() {
  const { getAll } = useApolloPaging();

  async function getProgressBudgets(projectId: string, costCodeIds: string[], includeSubprojects: boolean) {
    const results = await getAll<ProgressBudget>('progressBudgets', {
      fetchPolicy: 'network-only',
      query: GET_PROGRESS_BUDGET,
      variables: {
        first: 500,
        filter: {
          project: {
            idWithDescendants: includeSubprojects ? { equal: projectId } : undefined,
            id: !includeSubprojects ? { equal: projectId } : undefined,
          },
          costCodeId: isEmpty(costCodeIds) ? { isNull: false } : { contains: costCodeIds },
          deletedOn: { isNull: true },
        },
        sort: [{ createdOn: 'desc' }],
      },
    });
    return results;
  }

  return getProgressBudgets;
}

export const GET_PROGRESS_BUDGET = gql`
  query GetProgressBudget($filter: ProgressBudgetFilter, $first: Int, $after: String, $sort: [ProgressBudgetSort!]) {
    progressBudgets(first: $first, after: $after, filter: $filter, sort: $sort) {
      id
      cursor
      quantity
      value
      projectId
      costCodeId
    }
  }
`;
