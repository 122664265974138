import { Position, Theme, Tray } from '@busybusy/webapp-react-ui';
import { AddCircleIcon } from 'assets/icons';
import { ActionBar, IconButton } from 'components';
import { ITimeActionsFormData } from 'components/domain/time-entry/time-actions-form/hooks/useTimeActionsForm';
import TimeActionsFormDialog from 'components/domain/time-entry/time-actions-form/TimeActionsFormDialog/TimeActionsFormDialog';
import { ITimeOffFormData } from 'components/domain/time-off/form/CreateTimeOffForm/CreateTimeOffForm';
import TimeOffFormDialog from 'components/domain/time-off/form/TimeOffFormDialog/TimeOffFormDialog';
import MenuButton, { IMenuButtonOptions } from 'components/foundation/buttons/MenuButton/MenuButton';
import useCheckCanMangeTimeOffForMembers from 'containers/timesheets/hooks/useCheckCanManageTimeOffForMembers';
import { useOpenable } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'store/reducers';
import { clearCheckedMemberIds } from 'store/timesheets/Timesheets';

interface ITimesheetsMemberActionBarProps {
  onTimeOffFormSubmit: (timeOffData?: ITimeOffFormData) => void;
  onTimeEntryFormSubmit: (timeEntryData: ITimeActionsFormData) => void;
}

function TimesheetsMemberActionBar(props: ITimesheetsMemberActionBarProps) {
  const { onTimeEntryFormSubmit, onTimeOffFormSubmit } = props;

  const [t] = useTranslation();

  const timeEntryDialogDetails = useOpenable();
  const timeOffDialogDetails = useOpenable();

  const checkedMemberIds = useSelector<IReduxState, string[]>((state) => state.timesheet.checkedMemberIds);
  const canManageTimeOff = useCheckCanMangeTimeOffForMembers(checkedMemberIds);
  const dispatch = useDispatch();

  const summaryActionBarOptions = useRef<IMenuButtonOptions[]>([
    { label: t('Add Time Off'), onSelect: timeOffDialogDetails.open },
  ]);

  function uncheckMemberIds() {
    dispatch(clearCheckedMemberIds());
  }

  function onTimeEntrySubmit(timeEntryData: ITimeActionsFormData) {
    timeEntryDialogDetails.close();
    onTimeEntryFormSubmit(timeEntryData);
  }

  function onTimeOffSubmit(timeOffData?: ITimeOffFormData) {
    timeOffDialogDetails.close();
    onTimeOffFormSubmit(timeOffData);
  }

  return (
    <>
      <ActionBar selectedCount={checkedMemberIds.length} onClear={uncheckMemberIds}>
        <Tray spacing={1}>
          <IconButton
            svg={AddCircleIcon}
            onClick={timeEntryDialogDetails.open}
            tooltipLabel={t('Add Entries')}
            theme={Theme.DARK}
          />
          {canManageTimeOff && (
            <MenuButton options={summaryActionBarOptions.current} position={Position.BOTTOM_START} theme={Theme.DARK} />
          )}
        </Tray>
      </ActionBar>
      <TimeActionsFormDialog
        type={'add'}
        isOpen={timeEntryDialogDetails.isOpen}
        onClose={timeEntryDialogDetails.close}
        memberIds={checkedMemberIds}
        onSubmit={onTimeEntrySubmit}
        onDelete={onTimeEntrySubmit}
      />

      <TimeOffFormDialog
        isOpen={timeOffDialogDetails.isOpen}
        onClose={timeOffDialogDetails.close}
        memberIds={checkedMemberIds}
        onSubmit={onTimeOffSubmit}
      />
    </>
  );
}

export default TimesheetsMemberActionBar;
