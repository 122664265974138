import useNavigation from './useNavigation';

interface IParams {
  projectId: string;
  group_id?: string;
  archived?: boolean;
}

export default function useProjectManagementListNavigation() {
  return useNavigation<IParams>('/manage/projects');
}
