import useTimeEntryQuery from 'hooks/models/time-entry/useTimeEntryQuery';
import useTimeOffQuery from 'hooks/models/time-off/useTimeOffQuery';
import { DateTime } from 'luxon';
import MemberPermission from 'types/enum/MemberPermission';
import IIdable from 'types/Idable';
import ITimeOff from 'types/TimeOff';
import ITimeRange from 'types/TimeRange';
import { isNilOrEmptyOrOnlyHasRemainingItem } from 'utils/collectionUtils';
import { ITimeEntryDataTableData } from './useTimeEntryDataTableRow';

export default function useNoItemData<T extends IIdable<string>>(noItem: T) {
  const getTimeEntries = useTimeEntryQuery();
  const getTimeOffs = useTimeOffQuery();

  async function getNoItemData(
    timeRange: ITimeRange<DateTime>,
    includeOpenEntries?: boolean,
    memberIds?: string[],
    projectIds?: string[],
    costCodeIds?: string[],
    equipmentIds?: string[],
    idWithDescendants?: boolean
  ) {
    let timeOffs: ITimeOff[] = [];

    // don't include time off if we are filtering by projects, cost codes, or equipment
    if (
      isNilOrEmptyOrOnlyHasRemainingItem(projectIds) &&
      isNilOrEmptyOrOnlyHasRemainingItem(costCodeIds) &&
      isNilOrEmptyOrOnlyHasRemainingItem(equipmentIds)
    ) {
      timeOffs = await getTimeOffs(timeRange, memberIds, true, [MemberPermission.TIME_EVENTS]);
    }

    const entries = await getTimeEntries(
      timeRange,
      includeOpenEntries,
      memberIds,
      projectIds,
      costCodeIds,
      equipmentIds,
      idWithDescendants,
      undefined,
      [MemberPermission.TIME_EVENTS]
    );

    const item: ITimeEntryDataTableData<T> = {
      item: noItem,
      entries,
      timeOffs,
      cursor: '',
    };

    return [item];
  }

  return getNoItemData;
}
