const ACCESSED_PROGRESS_TRACKING = 'Accessed Progress Tracking';
const ACCESSED_PROGRESS_PROJECT_DETAILS = 'Accessed Progress Project Details';
const PROGRESS_TRACKING_DATE_CHANGED = 'Progress Tracking Date Changed';
const PROGRESS_DATE_TYPE_CHANGED = 'Web Progress Tracking Date Type Change';
const VIEWED_PROGRESS_ENTRY_DETAILS = 'Viewed Progress Entry Details';

const progressAnalyticsConstants = {
  ACCESSED_PROGRESS_TRACKING,
  ACCESSED_PROGRESS_PROJECT_DETAILS,
  PROGRESS_TRACKING_DATE_CHANGED,
  PROGRESS_DATE_TYPE_CHANGED,
  VIEWED_PROGRESS_ENTRY_DETAILS,
};

export default progressAnalyticsConstants;
