import { Dialog, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import cameraImage from 'assets/images/camera.png';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { DetailsCard, DetailsItem, Panel, Well } from 'components';
import ActiveMemberAvatar from 'components/domain/member/ActiveMemberAvatar/ActiveMemberAvatar';
import EmployeesEditDialog, {
  IEmployeesEditFormData,
} from 'containers/manage-employees/EmployeesEditDialog/EmployeesEditDialog';
import { useToastOpen } from 'contexts/ToastContext';
import { useAccountRpc, useActiveMember, useActiveMemberUpdate, useOpenable } from 'hooks';
import useProfileImageUpdate from 'hooks/models/member/useProfileImageUpdate';
import { sha256 } from 'js-sha256';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { IMember } from 'types';
import { t } from 'utils/localize';
import { getFullNameFromMember } from 'utils/stringUtils';
import './AccountSettings.scss';

type AccountSettingsEditField = 'k_name' | 'k_email' | 'k_phone' | 'k_username' | 'k_password' | 'k_profile';

export interface IAccountSettingsProps {
  className?: ClassName;
}

export default function AccountSettings({ className }: IAccountSettingsProps) {
  const [editKey, setEditKey] = useState<AccountSettingsEditField | null>(null);
  const editDialogOpenable = useOpenable();
  const member = useActiveMember() as IMember;
  const memberList = useMemo(() => [member], [member]);
  const [updateKey, update] = useActiveMemberUpdate();
  const patchProfileImage = useProfileImageUpdate();
  const accountRpc = useAccountRpc();
  const toastOpen = useToastOpen();

  function updateMember(formData: IEmployeesEditFormData) {
    switch (editKey) {
      case 'k_email':
        if (formData.email && member.email !== formData.email) {
          updateKey('email', formData.email ?? '');
        }

        break;
      case 'k_name':
        if (
          !isNil(formData.firstName) &&
          !isNil(formData.lastName) &&
          (member.firstName !== formData.firstName || member.lastName !== formData.lastName)
        ) {
          update({ firstName: formData.firstName, lastName: formData.lastName });
        }

        break;
      case 'k_password':
        if (!isNil(formData.password)) {
          updateKey('password', sha256(formData.password));
        }

        break;
      case 'k_phone':
        if (!isNil(formData.phone) && formData.phone !== member.phone) {
          updateKey('phone', formData.phone);
        }

        break;
      case 'k_username':
        if (!isNil(formData.username) && formData.username !== member.username) {
          updateKey('username', formData.username);
        }

        break;

      case 'k_profile':
        if (!isNil(formData.profile)) {
          patchProfileImage(member.id, formData.profile.file);
        }

        break;
    }

    editDialogOpenable.close();
  }

  function onItemClick(key: AccountSettingsEditField) {
    return () => {
      setEditKey(key);
      editDialogOpenable.open();
    };
  }

  function requestVerificationEmail() {
    accountRpc.requestVerificationEmail({ member_id: member.id });
    toastOpen({ label: t('Please check your email.'), theme: Theme.DARK });
  }

  return (
    <Panel className={classNames('account-settings', className)}>
      <Row className="mb-9">
        <ActiveMemberAvatar size="xl" onClick={onItemClick('k_profile')} backupImage={cameraImage} />
        <div className="ml-4">
          <h1>{getFullNameFromMember(member)}</h1>
          <h4>{member.position?.title ?? null}</h4>
        </div>
      </Row>

      {member.pendingEmail && (
        <Well className="verify-email-container mb-9">
          <span>{t("We noticed your email address hasn't been verified.")}</span>{' '}
          <span className="clickable verify-email" onClick={requestVerificationEmail}>
            {t('Verify your email here.')}
          </span>
        </Well>
      )}
      <Row justify={Justify.SPACE_BETWEEN} className="account-settings-content">
        <DetailsCard>
          <DetailsItem title={t('Name')} onClick={onItemClick('k_name')} value={getFullNameFromMember(member)} />
          <DetailsItem title={t('Email')} value={member.email} onClick={onItemClick('k_email')} />
          <DetailsItem title={t('Phone #')} onClick={onItemClick('k_phone')} value={member.phone} />
        </DetailsCard>
        <DetailsCard>
          <DetailsItem title={t('Username')} onClick={onItemClick('k_username')} value={member.username} />
          <DetailsItem title={t('')} onClick={onItemClick('k_password')}>
            <div className="fc-primary">{t('Reset Password')}</div>
          </DetailsItem>
        </DetailsCard>
      </Row>

      <Dialog isOpen={editDialogOpenable.isOpen} onClose={editDialogOpenable.close} hasDismiss={true}>
        <EmployeesEditDialog
          editKey={editKey}
          members={memberList}
          onClose={editDialogOpenable.close}
          onEdit={updateMember}
        />
      </Dialog>
    </Panel>
  );
}
