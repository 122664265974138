import { gql } from '@apollo/client';

export const BASIC_ORGANIZATION_TIME_METRICS_FRAGMENT = gql`
  fragment BasicOrganizationLaborMetrics on OrganizationLaborMetrics {
    dt
    end
    ot
    pto
    rt
    start
    upto
  }
`;

export const BASIC_ORGANIZATION_LABOR_METRICS_FRAGMENT = gql`
  fragment OrganizationLaborMetrics on OrganizationLaborMetrics {
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;

export const ALL_ORGANIZATION_LABOR_METRICS_FRAGMENT = gql`
  fragment AllOrganizationLaborMetrics on OrganizationLaborMetrics {
    breakDt
    breakDtCents
    breakOt
    breakOtCents
    breakOverburdenCents
    breakRt
    breakRtCents
    breakUpt
    dt
    dtCents
    end
    ot
    otCents
    overburdenRtCents
    overburdenOtCents
    overburdenDtCents
    pto
    ptoCents
    rt
    rtCents
    start
    upto
  }
`;
