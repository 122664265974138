import { IToastProps, Theme } from '@busybusy/webapp-react-ui';
import { useCallback, useRef } from 'react';
import useOpenable from '../utils/useOpenable';

export default function useToast() {
  const toast = useOpenable();

  const message = useRef<string | null>(null);
  const theme = useRef<IToastProps['theme']>(Theme.DANGER);
  const openToast = toast.open;

  const open = useCallback(
    (newMessage: string, newTheme: IToastProps['theme']) => {
      message.current = newMessage;
      theme.current = newTheme;
      openToast();
    },
    [openToast]
  );

  return {
    isOpen: toast.isOpen,
    close: toast.close,
    open,
    theme: theme.current,
    message: message.current,
  };
}
