import { POSITION_QUERY } from 'apollo/queries/position-queries';
import { useActiveMember, usePositionQuery } from 'hooks';
import { useCallback } from 'react';
import OperationType from 'types/enum/OperationType';
import { canManagePermissionForPosition, TPermissions } from 'utils/positionUtils';

export default function useManageablePermissions() {
  const { positions, ...rest } = usePositionQuery(POSITION_QUERY, { deletedOn: { isNull: true } });
  const member = useActiveMember();

  const getManageablePermissions = useCallback(
    (permissionKeys?: TPermissions[], operationType?: OperationType) => {
      return positions.filter((position) => {
        if (permissionKeys) {
          const list = permissionKeys.filter((permissionKey) => {
            return canManagePermissionForPosition(permissionKey, member.position!, position);
          });

          return operationType === OperationType.OR ? list.length > 0 : list.length === permissionKeys.length;
        } else {
          return positions;
        }
      });
    },
    [positions, member]
  );

  return { getManageablePermissions, ...rest };
}
