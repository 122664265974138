import {} from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import TimeRangeType from 'types/TimeRangeType';
import './ReportPrintHeader.scss';

export interface IReportPrintHeaderProps {
  className?: ClassName;
  title: string;
  timeRange: ITimeRange<DateTime>;
  timeRangeType?: TimeRangeType;
  children?: React.ReactNode;
}

const ReportPrintHeader = (props: IReportPrintHeaderProps) => {
  const { className, title, timeRange, children, timeRangeType } = props;

  function getTimeRangeFormat() {
    const today = DateTime.local();
    const sameYears = today.hasSame(timeRange.startTime, 'year') && today.hasSame(timeRange.endTime, 'year');
    return sameYears ? 'EEE, MMM d' : 'EEE, MMM d, yyyy';
  }

  const renderTimeRange = () => {
    if (!isNil(timeRangeType) && timeRangeType === TimeRangeType.ALL_TIME) {
      return t('All Time');
    }
    const formattedStartTime = timeRange.startTime.toFormat(getTimeRangeFormat());
    const formattedEndTime = timeRange.endTime.toFormat(getTimeRangeFormat());

    if (formattedStartTime === formattedEndTime) {
      return formattedStartTime;
    } else {
      return formattedStartTime + ' - ' + formattedEndTime;
    }
  };

  const classes = classNames('report-print-header', 'print', 'my-5', className);

  return (
    <div className={classes}>
      <h2 className="mb-3">{title}</h2>
      <div className="time-range">{renderTimeRange()}</div>
      {children && <div>{children}</div>}
    </div>
  );
};

export default ReportPrintHeader;
