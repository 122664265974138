import IIdable from 'types/Idable';
import { t } from 'utils/localize';
import { BaseImportError, baseImportErrorLookup, IImportError } from './ImportError';

export default interface ICSVEquipment extends IIdable<string> {
  equipmentId?: string;

  name: string;

  category?: string;
  categoryId?: string | null;

  make?: string;
  makeId?: string | null;

  model?: string;
  modelId?: string | null;

  year?: string;

  hourMeter?: string;

  hourlyRate?: string;

  reimport?: boolean;
}

// String to work with `groupBy` and debugging.
type EquipmentError =
  | 'MISSING_NAME'
  | 'MISSING_CATEGORY'
  | 'INVALID_CATEGORY'
  | 'INVALID_MAKE'
  | 'INVALID_MODEL'
  | 'INVALID_YEAR'
  | 'HOUR_METER_MUST_BE_NUMBER'
  | 'HOURLY_COST_MUST_BE_NUMBER'
  | 'DELETED';

const equipmentMap: Record<EquipmentError, string> = {
  MISSING_NAME: t('Missing name'),
  MISSING_CATEGORY: t('Missing category'),
  INVALID_CATEGORY: t('Invalid equipment category'),
  INVALID_MAKE: t('Invalid equipment make'),
  INVALID_MODEL: t('Invalid equipment model'),
  INVALID_YEAR: t('Invalid equipment model year'),
  HOUR_METER_MUST_BE_NUMBER: t('Hour meter must be a number'),
  HOURLY_COST_MUST_BE_NUMBER: t('Hourly rate must be a number'),
  DELETED: t('Deleted equipment'),
};

export const equipmentImportErrorMap = Object.assign(equipmentMap, baseImportErrorLookup);
export type EquipmentImportError = EquipmentError | BaseImportError;
export type IEquipmentCsvImportErrorState = IImportError<EquipmentImportError>;
