import { Icon } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import * as React from 'react';
import { EmptyIcon } from '../../../../assets/icons';
import StateTemplate from '../StateTemplate/StateTemplate';

export interface IEmptyStateProps {
  graphic?: ReactNode;
  title?: string;
  subtitle?: string;
  className?: ClassName;
}

const EmptyState: React.FunctionComponent<IEmptyStateProps> = (props) => {
  const { graphic, title, subtitle, className } = props;

  const classes = classNames(
    {
      'ac-fade-slide-up': true,
      'empty-state': true,
    },
    className
  );

  return (
    <StateTemplate
      className={classes}
      graphic={graphic ? graphic : <Icon svg={EmptyIcon} size={180} />}
      title={title}
      subtitle={subtitle}
    />
  );
};

export default EmptyState;
