import { useMutation } from '@apollo/client';
import {
  OrganizationSignOffQuestion,
  OrganizationSignOffQuestionAudience,
  OrganizationSignOffQuestionUpdateInput,
} from '__generated__/graphql';
import { ICreateDailySignOffQuestionsFormData } from 'containers/daily-sign-off/CreateDailySignOffQuestionForm/CreateDailySignOffQuestionForm';
import gql from 'graphql-tag';
import { getNowIsoAtUtcWithLocalTimeZone } from 'utils/dateUtils';
import { buildChoiceJSONString } from 'utils/organizationSignInQuestionUtils';
import { getAudienceType } from './userCreateOrganizationSignOffQuestion';

export default function useUpdateOrganizationSignOffQuestion() {
  const [updateOrganizationSignOffQuestions] = useMutation(UPDATE_ORGANIZATION_SIGN_OFF_QUESTION);

  async function deleteQuestion(id: string) {
    const input: OrganizationSignOffQuestionUpdateInput = {
      id,
      deletedOn: getNowIsoAtUtcWithLocalTimeZone(),
    };

    return new Promise<OrganizationSignOffQuestion>((resolve, reject) => {
      return updateOrganizationSignOffQuestions({ variables: { organizationSignOffQuestion: input } }).then(
        (results) => {
          if (results.data) {
            resolve(results.data.updateOrganizationSignOffQuestions);
          } else {
            reject();
          }
        }
      );
    });
  }

  async function updateQuestion(id: string, formData: ICreateDailySignOffQuestionsFormData) {
    const audienceType = getAudienceType(formData.whomToAsk);
    const choiceString = buildChoiceJSONString(formData);

    const input: OrganizationSignOffQuestionUpdateInput = {
      id,
      audienceType,
      choices: choiceString,
      positionId: audienceType === OrganizationSignOffQuestionAudience.Position ? formData.whomToAsk : null,
    };

    return new Promise<OrganizationSignOffQuestion>((resolve, reject) => {
      return updateOrganizationSignOffQuestions({ variables: { organizationSignOffQuestion: input } }).then(
        (results) => {
          if (results.data) {
            resolve(results.data.updateOrganizationSignOffQuestions);
          } else {
            reject();
          }
        }
      );
    });
  }

  async function updateOrder(id: string, order: number) {
    const input: OrganizationSignOffQuestionUpdateInput = {
      id,
      order,
    };

    return new Promise<OrganizationSignOffQuestion>((resolve, reject) => {
      return updateOrganizationSignOffQuestions({ variables: { organizationSignOffQuestion: input } }).then(
        (results) => {
          if (results.data) {
            resolve(results.data.updateOrganizationSignOffQuestions);
          } else {
            reject();
          }
        }
      );
    });
  }

  return { deleteQuestion, updateQuestion, updateOrder };
}

export const UPDATE_ORGANIZATION_SIGN_OFF_QUESTION = gql`
  mutation UpdateOrganizationSignOffQuestion($organizationSignOffQuestion: OrganizationSignOffQuestionUpdateInput!) {
    updateOrganizationSignOffQuestion(organizationSignOffQuestion: $organizationSignOffQuestion) {
      id
      questionTitle
      questionDescription
      audienceType
      organizationId
      createdOn
      type
      choices
      order
      positionId
    }
  }
`;
