import { IFeatureFlags } from 'types';
import { staging } from './staging';

export const localhost: IFeatureFlags = Object.assign({}, staging, {
  REACT_MAIN_APP: true,
  DASHBOARD: true,
  DAILY_REPORTS: true,
  KIOSK_REPORT: true,
  SCHEDULING: true,
  EMPTY_ROUTE: true,
  GRAPHQL_BATCH: true,
  GROUP_MANAGEMENT: true,
  TIMESHEETS: true,
  MANAGEMENT_LISTS_EQUIPMENT: true,
  EQUIPMENT_IMPORT: true,
  EMPLOYEE_TIMERS: true,
  LANDING_PAGES: true,
  TIME_OFF_REPORT: true,
  HOUR_METER_REPORT: true,
});

Object.freeze(localhost);
