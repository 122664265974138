import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateReduxTimeCardReportSettings } from 'store/TimeCardReportSettings/TimeCardReportSettings';

export enum TimeCardReportExpandedTimeFormat {
  HOURS = 'hours',
  DECIMAL = 'decimal',
}

export enum TimeCardReportExpandedBreakFormat {
  TOTAL = 'total',
  LIST = 'list',
  TOTAL_AND_LIST = 'totalAndList',
}

export interface ITimeCardReportExpandedSettings {
  showTimeEntries: boolean;
  showProjectSummary: boolean;
  showCostCodeSummary: boolean;
  showEquipmentSummary: boolean;
  showDailySummary: boolean;
  showSignatures: boolean;
}

export const defaultTimeCardReportExpandedSettings: ITimeCardReportExpandedSettings = {
  showTimeEntries: true,
  showProjectSummary: false,
  showCostCodeSummary: false,
  showEquipmentSummary: false,
  showDailySummary: true,
  showSignatures: true,
};
export interface ITimeCardReportExpandedSettingData {
  key: string;
  title: string;
  value: boolean;
}

export enum TimeCardReportExpandedSettingKeys {
  showTimeEntries = 'showTimeEntries',
  showProjectSummary = 'showProjectSummary',
  showCostCodeSummary = 'showCostCodeSummary',
  showEquipmentSummary = 'showEquipmentSummary',
  showDailySummary = 'showDailySummary',
  showSignatures = 'showSignatures',
}

export default function useTimeCardSettings() {
  const memberSettings = useMemberSettings();

  const [savedSettings, setSavedSettingsLocal] = useState(
    memberSettings?.web?.features?.timeCards?.timecardExpandedSettings ?? defaultTimeCardReportExpandedSettings
  );
  const [savedTimeFormatSettings, setSavedTimeFormatSettingsLocal] = useState(
    memberSettings?.web?.features?.timeCards?.timeCardExpandedTimeFormatSettings ??
      TimeCardReportExpandedTimeFormat.HOURS
  );
  const [savedBreakFormatSettings, setSavedBreakFormatSettingsLocal] = useState(
    memberSettings?.web?.features?.timeCards?.timeCardExpandedBreakFormatSettings ??
      TimeCardReportExpandedBreakFormat.TOTAL
  );

  const timeCardReportSettings = useRef<ITimeCardReportExpandedSettings>(savedSettings);
  const dispatch = useDispatch();

  const updateTimeCardReportSettings = async (
    newReportSettings: {
      showTimeEntries: boolean;
      showProjectSummary: boolean;
      showCostCodeSummary: boolean;
      showEquipmentSummary: boolean;
      showDailySummary: boolean;
      showSignatures: boolean;
    },
    newTimeFormat: TimeCardReportExpandedTimeFormat,
    newBreakFormat: TimeCardReportExpandedBreakFormat
  ) => {
    timeCardReportSettings.current = newReportSettings;
    dispatch(updateReduxTimeCardReportSettings(newReportSettings));
    setSavedSettingsLocal(newReportSettings);
    setSavedTimeFormatSettingsLocal(newTimeFormat);
    setSavedBreakFormatSettingsLocal(newBreakFormat);
  };

  return {
    timeCardReportSettings: timeCardReportSettings.current,
    updateTimeCardReportSettings,
    timeFormatSettings: savedTimeFormatSettings,
    breakFormatSettings: savedBreakFormatSettings,
  };
}
