import { INCIDENT_DETAILS_FRAGMENT } from 'containers/safety-incident-reports/hooks/incident-queries';
import gql from 'graphql-tag';

export const BASIC_SAFETY_SIGNATURES_FRAGMENT = gql`
  fragment BasicSafetySignatureFragment on SafetySignature {
    id
    memberId
    proxyMemberId
    startTime
    startTimeDst
    endTime
    endTimeDst
    createdOnDst
    deviceType
    breakPolicyFollowed
    breakPolicyFollowedReason
    injured
    injuryDescription
    timeAccurate
    timeAccurateReason
    breakPolicyFollowedResolvedOn
    breakPolicyFollowedResolvedBy
    breakPolicyFollowedResolvedComment
    injuredResolvedOn
    injuredResolvedBy
    injuredResolvedComment
    timeAccurateResolvedOn
    timeAccurateResolvedBy
    timeAccurateResolvedComment
    customQuestionsFlagged
    customQuestionsResolvedOn
    signatureUrl
    createdOnLocal
    createdOn
    deletedOn
    submittedOn
    updatedOn
    cursor
  }
`;

export const SAFETY_SIGNATURES_FRAGMENT = gql`
  fragment SafetySignatureFragment on SafetySignature {
    id
    memberId
    proxyMemberId
    startTime
    startTimeDst
    endTime
    endTimeDst
    createdOnDst
    deviceType
    breakPolicyFollowed
    breakPolicyFollowedReason
    injured
    injuryDescription
    timeAccurate
    timeAccurateReason
    breakPolicyFollowedResolvedOn
    breakPolicyFollowedResolvedBy
    breakPolicyFollowedResolvedComment
    injuredResolvedOn
    injuredResolvedBy
    injuredResolvedComment
    timeAccurateResolvedOn
    timeAccurateResolvedBy
    timeAccurateResolvedComment
    signatureUrl
    createdOnLocal
    createdOn
    deletedOn
    submittedOn
    updatedOn
    cursor
    member {
      id
      firstName
      lastName
      position {
        id
        title
      }
    }
    proxyMember {
      id
      firstName
      lastName
    }
    customQuestionsFlagged
    customQuestionsResolvedOn
    customAnswers {
      id
      questionTitle
      flagged
      answer
      resolvedOn
      createdOn
      audienceType
      positionId
      resolverId
      questionPrompt
      description
    }
  }
`;

export const SAFETY_SIGNATURES_QUERY = gql`
  query SafetySignatures($first: Int, $after: String, $filter: SafetySignatureFilter, $sort: [SafetySignatureSort!]) {
    safetySignatures(after: $after, first: $first, filter: $filter, sort: $sort) {
      __typename
      ...SafetySignatureFragment
    }
  }
  ${SAFETY_SIGNATURES_FRAGMENT}
`;

export const SAFETY_SIGNATURES_DETAILS_QUERY = gql`
  query SafetySignaturesDetails(
    $first: Int
    $after: String
    $filter: SafetySignatureFilter
    $sort: [SafetySignatureSort!]
  ) {
    safetySignatures(after: $after, first: $first, filter: $filter, sort: $sort) {
      id
      memberId
      proxyMemberId
      startTime
      startTimeDst
      endTime
      endTimeDst
      createdOnDst
      deviceType
      breakPolicyFollowed
      breakPolicyFollowedReason
      injured
      injuryDescription
      timeAccurate
      timeAccurateReason
      breakPolicyFollowedResolvedOn
      breakPolicyFollowedResolvedBy
      breakPolicyFollowedResolvedComment
      injuredResolvedOn
      injuredResolvedBy
      injuredResolvedComment
      timeAccurateResolvedOn
      timeAccurateResolvedBy
      timeAccurateResolvedComment
      signatureUrl
      createdOnLocal
      createdOn
      deletedOn
      submittedOn
      updatedOn
      incidentId
      incident {
        ...IncidentDetails
      }
      member {
        id
        firstName
        lastName
      }
      proxyMember {
        id
        firstName
        lastName
      }
      breakPolicyFollowedResolvedByMember {
        id
        firstName
        lastName
      }
      timeAccurateResolvedByMember {
        id
        firstName
        lastName
      }
      injuredResolvedByMember {
        id
        firstName
        lastName
      }
      customQuestionsFlagged
      customQuestionsResolvedOn
      customAnswers {
        id
        questionTitle
        questionPrompt
        questionDescription
        description
        flagged
        answer
        resolvedOn
        createdOn
        audienceType
        positionId
        resolverId
        resolvedComment
        resolverMember {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${INCIDENT_DETAILS_FRAGMENT}
`;
