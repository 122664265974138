import gql from 'graphql-tag';

export const MEMBER_LOCKS_QUERY = gql`
  query queryMemberLocks($first: Int, $filter: MemberLockFilter) {
    memberLocks(first: $first, filter: $filter) {
      id
      effectiveDate
      updaterMemberId
      memberId
      createdOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;

export const MEMBER_LOCK_QUERY = gql`
  query queryMemberLock($memberId: Uuid!) {
    memberLocks(filter: { memberId: { equal: $memberId } }) {
      id
      effectiveDate
      updaterMemberId
      memberId
      createdOn
      submittedOn
      updatedOn
      cursor
    }
  }
`;
