import { ArchivedStatus } from 'components/domain/archived/ArchivedPicker/ArchivedPicker';
import { useQueryParams } from 'hooks';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import useTimeRangeTimeRangeTypeQueryParams from 'hooks/routing/useTimeRangeTimeRangeTypeQueryParams/useTimeRangeTimeRangeTypeQueryParams';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { TimesheetView } from 'store/timesheets/Timesheets';
import TimeRangeType from 'types/TimeRangeType';
import { Nullable } from 'types/util/Nullable';
import { QueryParam } from 'utils/constants/queryParam';
import { TimesheetReportType } from '../Timesheets';
import { TimesheetsGraphType } from '../TimesheetsHeader/TimesheetsHeader';
import { DEFAULT_TIMESHEETS_ARCHIVED_STATUS } from '../utils/utils';

export interface ITimesheetsQueryPayload {
  timeRangeType: TimeRangeType;
  timesheetView: TimesheetView;
  startDate: Nullable<DateTime>;
  endDate: Nullable<DateTime>;
  memberGroupId: Nullable<string>;
  positionId: Nullable<string>;
  memberId: Nullable<string>;
  projectId: Nullable<string>;
  costCodeId: Nullable<string>;
  equipmentId: Nullable<string>;
  timesheetReportType: Nullable<TimesheetReportType>;
  archivedStatus: ArchivedStatus;
  timesheetGraphType: Nullable<TimesheetsGraphType>;
  costCodeGroupId: Nullable<string>;
  projectGroupId: Nullable<string>;
}

export default function useTimesheetsQueryParams(): ITimesheetsQueryPayload {
  const { getParam } = useQueryParams();
  const memberSettings = useMemberSettings();
  const parseTimeRangeTimeRangeType = useTimeRangeTimeRangeTypeQueryParams();

  return useMemo((): ITimesheetsQueryPayload => {
    const { timeRangeType, timeRange } = parseTimeRangeTimeRangeType(
      TimeRangeType.PAY_PERIOD,
      memberSettings?.web?.features?.timeCards?.timeRangeType,
      'utc'
    );

    return {
      timeRangeType: timeRangeType,
      timesheetView: getParam<TimesheetView>(QueryParam.TIMESHEET_VIEW) ?? TimesheetView.SUMMARY,
      startDate: timeRange?.startTime ?? null,
      endDate: timeRange?.endTime ?? null,
      memberGroupId: getParam(QueryParam.EMPLOYEE_GROUP_ID) ?? null,
      positionId: getParam(QueryParam.POSITION_ID) ?? null,
      memberId: getParam(QueryParam.EMPLOYEE_ID) ?? null,
      projectId: getParam(QueryParam.PROJECT_ID) ?? null,
      costCodeId: getParam(QueryParam.COST_CODE_ID) ?? null,
      equipmentId: getParam(QueryParam.EQUIPMENT_ID) ?? null,
      timesheetReportType: getParam<TimesheetReportType>(QueryParam.TIMESHEET_REPORT_TYPE) ?? null,
      archivedStatus: getParam(QueryParam.ARCHIVED_STATUS) ?? DEFAULT_TIMESHEETS_ARCHIVED_STATUS,
      timesheetGraphType: getParam<TimesheetsGraphType>(QueryParam.TIMESHEET_GRAPH_TYPE),
      costCodeGroupId: getParam(QueryParam.COST_CODE_GROUP_ID) ?? null,
      projectGroupId: getParam(QueryParam.PROJECT_GROUP_ID) ?? null,
    };
  }, [getParam, memberSettings?.web?.features?.timeCards?.timeRangeType, parseTimeRangeTimeRangeType]);
}
