import { Button, Size, Theme } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import DashboardCard from 'components/foundation/dashboard/DashboardCard/DashboardCard';
import { t } from 'utils/localize';
import './DashboardErrorCard.scss';

export interface IDashboardErrorCardProps {
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
  onClick: () => void;
}

const DashboardErrorCard = (props: IDashboardErrorCardProps) => {
  const { className, theme, onClick } = props;

  const classes = classNames('dashboard-error-card', className);

  return (
    <DashboardCard className={classes} theme={theme}>
      <h2 className="dashboard-enable-title-card">{t('Error. Could not load data.')}</h2>
      <Button type="link" onClick={onClick} size={Size.LARGE}>
        {t('Try again.')}
      </Button>
    </DashboardCard>
  );
};

export default DashboardErrorCard;
