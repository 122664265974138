import { ButtonList, Divider, Position, Tooltip } from '@busybusy/webapp-react-ui';
import { HeadsetIcon } from 'assets/icons';
import { HeaderDialog } from 'components';
import { useOpenable } from 'hooks';
import useNavListGraylog from 'hooks/analytics/useNavListGraylog';

import { t } from 'utils/localize';
import FakeNavItem from '../FakeNavItem/FakeNavItem';
import CustomerSupportActionList from '../NavHeader/CustomerSupportActionList/CustomerSupportActionList';
import NavSection from '../NavSection/NavSection';
import useNavBarSettings from '../hooks/useNavBarSettings';

const MinNavList = () => {
  const { getVisibleNavBarSettings } = useNavBarSettings();
  const { general: top, reports, misc: bottom } = getVisibleNavBarSettings();

  const customerSupportOpenable = useOpenable();
  const userEvents = useNavListGraylog();

  function handleSupportClick() {
    userEvents.recordClickSupport();
    customerSupportOpenable.open();
  }

  return (
    <ButtonList>
      {top.length > 0 && (
        <>
          <NavSection configuration={top} isMin={true} />
          {reports.length > 0 && bottom.length > 0 && <Divider />}
        </>
      )}

      {reports.length > 0 && (
        <>
          <NavSection configuration={reports} isMin={true} />
          {bottom.length > 0 && <Divider />}
        </>
      )}

      {bottom.length > 0 && (
        <>
          <NavSection configuration={bottom} isMin={true} />
        </>
      )}

      <Tooltip label={t('Support')} delay={0} position={Position.RIGHT}>
        <FakeNavItem onClick={handleSupportClick} icon={HeadsetIcon}>
          <></>
        </FakeNavItem>
      </Tooltip>

      <HeaderDialog
        title={t('Support')}
        isOpen={customerSupportOpenable.isOpen}
        onClose={customerSupportOpenable.close}
      >
        <CustomerSupportActionList onSelect={customerSupportOpenable.close} className="mx-8 mb-6 pt-2" />
      </HeaderDialog>
    </ButtonList>
  );
};

export default MinNavList;
