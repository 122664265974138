import { Align, Justify, Row, Theme } from '@busybusy/webapp-react-ui';
import { PenIcon } from 'assets/icons';
import classNames from 'classnames';
import { Spinner } from 'components';
import { IDashboardSettingsItem } from 'components/domain/dashboard/DashboardSettingsItem/DashboardSettingsItem';
import DashboardEnableCard from 'components/foundation/dashboard/DashboardEnableCard/DashboardEnableCard';
import DashboardSingleRibbon from 'components/foundation/dashboard/DashboardSingleRibbon/DashboardSingleRibbon';
import useDailySignInData from 'containers/dashboard/hooks/useDailySignInData';
import { useDashboardTimeRange } from 'containers/dashboard/hooks/useDashboardTimeRange';
import { getDashboardTimeFrameTitle } from 'containers/dashboard/util/utils';
import { useActiveMember, useOrganization } from 'hooks';
import useSignInReportNavigation from 'hooks/navigation/useSignInReportNavigation';
import useSignInSettingNavigation from 'hooks/navigation/useSignInSettingNavigation';
import { t } from 'i18next';
import * as React from 'react';
import { ClassName } from 'types/ClassName';
import { useFeatureFlags } from 'utils/features';
import DashboardErrorCard from '../DashboardErrorCard/DashboardErrorCard';
import DashboardProUpgradeCard from '../DashboardProUpgradeCard/DashboardProUpgradeCard';
import './DailySignInCard.scss';

export interface IDailySignInCardProps {
  className?: ClassName;
  theme: Theme.DARK | Theme.LIGHT;
  setting: IDashboardSettingsItem;
  onHide?: () => void;
}

enum OptionsKey {
  MISSING = 'missing',
  FLAGGED = 'flagged',
}

const DailySignInCard = (props: IDailySignInCardProps) => {
  const { className, theme, setting, onHide } = props;

  const organization = useOrganization();
  const isPro = useFeatureFlags('PRO');
  const isSignInEnabled = organization.busySignInQuestion ?? false;
  const timeRange = useDashboardTimeRange(setting.options?.time ?? 'pastSeven');
  const signatureStatus = useDailySignInData(timeRange);
  const settingNavigation = useSignInSettingNavigation();
  const signInReportNavigation = useSignInReportNavigation();
  const activeMember = useActiveMember();

  const subtitle = getDashboardTimeFrameTitle('', setting.options?.time ?? 'payPeriod') ?? '';

  const classes = classNames('daily-sign-in-card', className, { 'is-loading': signatureStatus.isLoading });

  const onCardSelection = (key: string) => {
    signInReportNavigation({
      issue_status: key === OptionsKey.FLAGGED ? 'flagged' : undefined,
      signature_status: key === OptionsKey.MISSING ? 'unsigned' : undefined,
      time_range_type: setting.options?.time === 'payPeriod' ? 'pay_period' : 'daily',
      start_date: setting.options?.time === 'payPeriod' ? timeRange.startTime.toISODate() : undefined,
      end_date: setting.options?.time === 'payPeriod' ? timeRange.endTime.toISODate() : undefined,
    });
  };

  if (signatureStatus.isLoading) {
    return <EmptyLoadingCard isLoading={signatureStatus.isLoading} />;
  }

  return (
    <>
      {isPro ? (
        isSignInEnabled ? (
          <div className={classes}>
            {signatureStatus.error !== null ? (
              <DashboardErrorCard theme={theme} onClick={() => signatureStatus.setRefetch(!signatureStatus.refetch)} />
            ) : (
              <>
                <DashboardSingleRibbon theme={Theme.DARK} onClick={() => onCardSelection(OptionsKey.MISSING)}>
                  {signatureStatus.isLoading ? (
                    <Row justify={Justify.CENTER} align={Align.CENTER}>
                      <Spinner size="md" />
                    </Row>
                  ) : (
                    <>
                      <h1 className={classNames('mb-5', 'single-ribbon-value')}>
                        {signatureStatus.aggregatedInfo?.missingSignIns ?? 0}
                      </h1>
                      <div className={'single-ribbon-title'}>{t('Missing Sign-Ins')}</div>
                      <div className="single-ribbon-subtitle">{subtitle}</div>
                    </>
                  )}
                </DashboardSingleRibbon>

                <DashboardSingleRibbon
                  className="mt-5"
                  theme={Theme.DARK}
                  onClick={() => onCardSelection(OptionsKey.FLAGGED)}
                >
                  {signatureStatus.isLoading ? (
                    <Row justify={Justify.CENTER} align={Align.CENTER}>
                      <Spinner size="md" />
                    </Row>
                  ) : (
                    <>
                      <h1 className={classNames('mb-5', 'single-ribbon-value')}>
                        {signatureStatus.aggregatedInfo?.flaggedSignIns ?? 0}
                      </h1>
                      <div className={'single-ribbon-title'}>{t('Flagged Sign-Ins')}</div>
                      <div className="single-ribbon-subtitle">{subtitle}</div>
                    </>
                  )}
                </DashboardSingleRibbon>
              </>
            )}
          </div>
        ) : activeMember.position?.manageCompanySettings ? (
          <DashboardEnableCard
            className={`${signatureStatus.isLoading ? 'is-loading' : ''}`}
            title={setting.title}
            svg={PenIcon}
            buttonLabel={t('Enable Daily Sign-In')}
            onClick={settingNavigation}
            theme={theme}
            onHide={onHide}
          />
        ) : (
          <EmptyLoadingCard isLoading={signatureStatus.isLoading} />
        )
      ) : (
        <DashboardProUpgradeCard
          className={`${signatureStatus.isLoading ? 'is-loading' : ''}`}
          title={setting.title}
          svg={PenIcon}
          theme={theme}
          onHide={onHide}
        />
      )}
    </>
  );
};

const EmptyLoadingCard: React.FC<{ isLoading: boolean }> = ({ isLoading }) =>
  isLoading ? <div className="is-loading" /> : null;

export default DailySignInCard;
