import classNames from 'classnames';
import { useTimeEntryActionBarState } from 'components/domain/time-entry/TimeEntryActionBar/TimeEntryActionBarContext';
import ProjectTimeEntryDataReport from 'components/domain/time-entry/TimeEntryDataReport/ProjectTimeEntryDataReport/ProjectTimeEntryDataReport';
import useProjectTimeEntryDataReportData from 'components/domain/time-entry/TimeEntryDataReport/ProjectTimeEntryDataReport/hooks/useProjectTimeEntryDataReportData';
import { ITimeEntryDataTableRow } from 'components/domain/time-entry/TimeEntryDataTable/TimeEntryDataTable';
import { defaultTimeEntryColumns } from 'containers/timesheets/hooks/useTimesheetsColumns';
import { usePrintComplexDataChannelListener } from 'contexts/ChannelContext/hooks/usePrintComplexDataChannelListener';
import { useReduxSelector } from 'hooks';
import useBrandTitle from 'hooks/meta/useBrandTitle';
import { useLoaderPromise } from 'hooks/promise';
import { first, isEmpty, isNil, isNull, map, partition, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCheckedTimeEntryIds, updateCheckedTimeOffIds } from 'store/TimeEntryTable/TimeEntryTable';
import { ClassName } from 'types/ClassName';
import ITimeRange from 'types/TimeRange';
import { dateUtils } from 'utils';
import { t } from 'utils/localize';
import { useTimeEntryExportChannelListener } from '../hooks/useTimeEntryExportChannelListener';

export interface IProjectTimeEntryTableListeningContainerProps {
  className?: ClassName;
  scroller: HTMLElement | Document | null;
  timeRange: ITimeRange<DateTime>;
  projectIds?: string[];
  memberIds?: string[];
  costCodeIds?: string[];
  equipmentIds?: string[];
  excludeArchived?: boolean;
  rootProjectsOnly?: boolean;
  applyPrintOptions: boolean;
}

const ProjectTimeEntryTableListeningContainer = (props: IProjectTimeEntryTableListeningContainerProps) => {
  const {
    className,
    scroller,
    timeRange,
    projectIds,
    memberIds,
    costCodeIds,
    equipmentIds,
    excludeArchived,
    rootProjectsOnly,
    applyPrintOptions,
  } = props;

  const { data, loading, loadedAll, error, clearData, updateProjectIds, loadAll } = useProjectTimeEntryDataReportData(
    scroller,
    timeRange,
    false,
    projectIds,
    true,
    memberIds,
    costCodeIds,
    equipmentIds,
    excludeArchived,
    rootProjectsOnly,
    true
  );

  const [timeEntryActionBarState, timeEntryActionBarDispatch] = useTimeEntryActionBarState();

  useEffect(() => {
    if (!isEmpty(timeEntryActionBarState.entries)) {
      const ids = uniq(timeEntryActionBarState.entries.map((entry) => entry.project?.id ?? '')).filter(
        (id) => id.length > 0
      );
      updateProjectIds(ids);
    }
    if (!isEmpty(timeEntryActionBarState.memberIds)) {
      updateProjectIds(data.map((d) => d.id));
    }
    if (!isNull(timeEntryActionBarState.formData)) {
      const ids = timeEntryActionBarState.formData.members;
      updateProjectIds(ids);
    }
  }, [timeEntryActionBarState]);

  const dispatch = useDispatch();
  const selectedIds = useReduxSelector((state) =>
    state.timeEntryTable.checkedTimeEntryIds.concat(state.timeEntryTable.checkedTimeOffIds)
  );

  const onSelect = useCallback(
    (rows: ITimeEntryDataTableRow[]) => {
      const [timeEntryIds, timeOffIds] = partition(rows, (row) => isNil(row.timeOffType));
      dispatch(updateCheckedTimeEntryIds(map(timeEntryIds, 'id')));
      dispatch(updateCheckedTimeOffIds(map(timeOffIds, 'id')));
    },
    [dispatch]
  );

  const exportWithLoader = useLoaderPromise(loadAll, t('Exporting...'));
  const printWithLoader = useLoaderPromise(loadAll, t('Preparing to print...'));
  const brand = useBrandTitle();

  useTimeEntryExportChannelListener(
    data,
    loadedAll,
    `${brand}-${t('activity-report-time-entries')}-${dateUtils.isoTimeStampLocal()}`,
    exportWithLoader
  );
  usePrintComplexDataChannelListener(data, loadedAll, printWithLoader);

  const classes = classNames('project-time-entry-table-container', className);

  return (
    <ProjectTimeEntryDataReport
      className={classes}
      columnSettings={defaultTimeEntryColumns}
      timeRange={timeRange}
      selectedIds={selectedIds}
      onSelectChange={onSelect}
      updateProjectIds={updateProjectIds}
      data={data}
      loading={loading}
      memberIds={memberIds}
      costCodeId={first(costCodeIds)}
      equipmentId={first(equipmentIds)}
      loadedAll={loadedAll}
      applyPrintOptions={applyPrintOptions}
    />
  );
};

export default ProjectTimeEntryTableListeningContainer;
