import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FunctionComponent } from 'react';
import DashboardCard, { IDashboardCardProps } from '../DashboardCard/DashboardCard';
import DashboardCardHeader, { IDashboardCardHeaderProps } from '../DashboardCardHeader/DashboardCardHeader';

interface IDashboardContentCardProps
  extends Omit<IDashboardCardProps, 'showBlurredBackground'>,
    Omit<IDashboardCardHeaderProps, 'className'> {}

const DashboardContentCard: FunctionComponent<IDashboardContentCardProps> = (props) => {
  const { className, theme, children, rightLabel, onRightLabelClick, header, subheaderValue, divider, ...cardProps } =
    props;

  const classes = classNames(
    {
      'dashboard-content-card': true,
      [`${divider === true ? 'divider' : 'default'}-padding`]: true,
    },
    className
  );

  let headerMargin = 'mb-8';
  if (divider) {
    headerMargin = 'mb-5';
  }

  return (
    <DashboardCard className={classes} {...cardProps} theme={theme}>
      <DashboardCardHeader
        rightLabel={rightLabel}
        onRightLabelClick={onRightLabelClick}
        header={header}
        subheaderValue={subheaderValue}
        theme={theme}
        divider={divider}
        className={headerMargin}
      />
      {children}
    </DashboardCard>
  );
};

export default DashboardContentCard;
