import { Button, Checkbox, Divider, Label, Row } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { FunctionComponent, useState } from 'react';
import './CsvImportOverviewHeader.scss';

export interface ICsvImportOverviewHeaderProps {
  onSubmit: (checked: boolean) => void;
  submitText: string;
  checkTextInformationTitle?: string;
  checkTextInformationBody?: string;
  className?: ClassName;
}

const CsvImportOverviewHeader: FunctionComponent<ICsvImportOverviewHeaderProps> = (props) => {
  const { onSubmit, checkTextInformationBody, checkTextInformationTitle, submitText, className } = props;

  const [inviteChecked, setInviteChecked] = useState(true);

  function onInviteChecked(checked: boolean) {
    setInviteChecked(checked);
  }

  function onCreateClick() {
    onSubmit(inviteChecked);
  }

  const classes = classNames('csv-import-overview-header', className);

  return (
    <>
      <Row className={classes}>
        <Button type="primary" onClick={onCreateClick}>
          {submitText}
        </Button>
        {(checkTextInformationBody || checkTextInformationTitle) && (
          <>
            <Checkbox checked={inviteChecked} onChange={onInviteChecked} />
            <div>
              {checkTextInformationTitle && <Label>{checkTextInformationTitle}</Label>}
              {checkTextInformationBody && <p className="f-sm">{checkTextInformationBody}</p>}
            </div>
          </>
        )}
      </Row>
      <Divider />
    </>
  );
};

export default CsvImportOverviewHeader;
