import { useApolloClient } from '@apollo/client';
import { IFormValidation } from '@busybusy/webapp-react-ui';
import { useOrganization } from 'hooks';
import { useMemo } from 'react';
import { v_project_cost_code, v_require } from 'utils/validations';

export default function useTimeEntryCostCodeValidation(projectFormFieldName: string = 'project') {
  const client = useApolloClient();
  const organization = useOrganization();

  return useMemo(() => {
    const validations: Array<IFormValidation<string | null, any>> = [];
    if (organization.requireCostCode) {
      validations.push({ validation: v_require });
    }

    if (organization.useProjectCostCodeScoping) {
      validations.push({
        validation: v_project_cost_code,
        args: {
          projectField: projectFormFieldName,
          client,
          allowNoCostCodeLink: !organization.requireCostCode,
        },
      });
    }

    return validations;
  }, [organization.requireCostCode, organization.useProjectCostCodeScoping, projectFormFieldName, client]);
}
