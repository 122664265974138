import { IRadioGroupFormFieldProps, Radio, RadioGroupFormField } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import HeaderText from 'containers/settings/components/HeaderText/HeaderText';
import { t } from 'i18next';
import { AnyObject } from 'types/util/Object';
import { v_require } from 'utils/validations';
import './GustoEmployeeRegistrationRadioGroupFormField.scss';

export type GustoEmployeeRegistrationRadioGroupFormFieldProps<FormType extends AnyObject> = Omit<
  IRadioGroupFormFieldProps<FormType>,
  'label' | 'validations' | 'children'
>;

function GustoEmployeeRegistrationRadioGroupFormField<FormType extends AnyObject>({
  className,
  ...formFieldProps
}: GustoEmployeeRegistrationRadioGroupFormFieldProps<FormType>) {
  const classes = classNames('gusto-employee-registration-radio-group-form-field', className);

  return (
    <RadioGroupFormField className={classes} validations={[{ validation: v_require }]} {...formFieldProps}>
      <Radio
        value="self"
        className="mb-4"
        label={
          <HeaderText title={t('Self Onboarding')}>
            <span>{t('Invite the employee to onboard themselves through the mobile app.')}</span>
          </HeaderText>
        }
      />
      <Radio
        value="register-now"
        className="mb-4"
        label={
          <HeaderText title={t('Register Now')}>
            <span>
              {t(
                'After completing this form, you will be taken to the payroll onboarding section where you can upload the required payroll information and documents. '
              )}
            </span>
          </HeaderText>
        }
      />
      <Radio
        value="register-later"
        label={
          <HeaderText title={t('Register Later')}>
            <span>
              {t(
                'Register the employee later (Remember that you will not be able to process payroll for this employee until their registration is complete).'
              )}
            </span>
          </HeaderText>
        }
      />
    </RadioGroupFormField>
  );
}

GustoEmployeeRegistrationRadioGroupFormField.defaultProps = {
  ...RadioGroupFormField.defaultProps,
};

export default GustoEmployeeRegistrationRadioGroupFormField;
