import { Button, Size, Theme } from '@busybusy/webapp-react-ui';
import { HeaderDialog } from 'components';
import DashboardCard from 'components/foundation/dashboard/DashboardCard/DashboardCard';
import CustomerSupportActionList from 'components/layout/navigation-drawer/NavHeader/CustomerSupportActionList/CustomerSupportActionList';
import { PremiumTrialDialog } from 'containers/dashboard/Dashboard/PremiumTrialDialog/PremiumTrialDialog';
import { useActiveMember, useOpenable } from 'hooks';
import useMemberUiSettingsUpdate from 'hooks/models/member-settings/useMemberUiSettingsUpdate';
import useMemberSettings from 'hooks/models/member/useMemberSettings';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { useFeatureFlags } from 'utils/features';
import './PremiumTrialCard.scss';

export interface IPremiumTrialCardProps {
  key: string;
}

export const PremiumTrialCard = (props: IPremiumTrialCardProps) => {
  const { key } = props;

  const isPremiumTrial = useFeatureFlags('PREMIUM_TRIAL');
  const updateUiSettings = useMemberUiSettingsUpdate();
  const memberSettings = useMemberSettings();
  const activeMember = useActiveMember();
  const premiumTrialDialogOpenable = useOpenable({
    isOpen:
      isPremiumTrial &&
      isNil(memberSettings?.web?.ui?.hidePremiumTrialDialog) &&
      activeMember.position?.manageCompanySettings,
  });
  const customerSupportOpenable = useOpenable();

  const onClosePremiumTrialDialog = () => {
    updateUiSettings('hidePremiumTrialDialog', true);
    premiumTrialDialogOpenable.close();
  };

  return (
    <>
      <DashboardCard key={key} theme={Theme.DARK} showBlurredBackground={true} className={'premium-trial-card'}>
        <h2 className="dashboard-title-card">{t('Premium Free Trial')}</h2>
        <div className="subtitle">
          {t(`We hope you are enjoying your free trial of Premium. If you have any questions, feel free to `)}
          <span>
            <a className="link" onClick={customerSupportOpenable.open}>
              {t('contact us.')}
            </a>
          </span>
        </div>
        <Button type="primary" onClick={premiumTrialDialogOpenable.open} size={Size.LARGE}>
          {t('Features Overview')}
        </Button>
      </DashboardCard>
      <HeaderDialog
        isOpen={premiumTrialDialogOpenable.isOpen}
        title={''}
        onClose={onClosePremiumTrialDialog}
        divider={false}
        size={Size.LARGE}
      >
        <PremiumTrialDialog />
      </HeaderDialog>
      <HeaderDialog
        title={t('Support')}
        isOpen={customerSupportOpenable.isOpen}
        onClose={customerSupportOpenable.close}
      >
        <CustomerSupportActionList onSelect={customerSupportOpenable.close} className="mx-8 mb-6 pt-2" />
      </HeaderDialog>
    </>
  );
};
