import useIsInIframe from 'hooks/store/useIsInIframe';
import { useMemo } from 'react';
import { NavBarId, baseNavBlacklistedIds, iframeBlacklistedIds, navBarIds } from '../types/types';

export default function useNavConfigBlacklistedIds(): Readonly<NavBarId[]> {
  const isInIframe = useIsInIframe();

  return useMemo(() => {
    if (isInIframe) {
      return iframeBlacklistedIds;
    } else {
      return baseNavBlacklistedIds;
    }
  }, [isInIframe]);
}
