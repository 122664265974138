import { Dialog, Divider, IDialogProps, Justify, Size } from '@busybusy/webapp-react-ui';
import classNames from 'classnames';
import { ClassName } from "types/ClassName";
import { ReactNode } from 'react';
import HeaderDialogHeader from '../HeaderDialogHeader/HeaderDialogHeader';

export interface IHeaderDialogProps extends IDialogProps {
  title: string;
  subtitle?: string;
  headerSize?: Size;
  showBackArrowAsClose?: boolean;
  rightContent?: ReactNode;
  headerClass?: string;
  divider?: boolean;
}

const HeaderDialog = ({
  children,
  className,
  title,
  subtitle,
  headerSize,
  headerClass,
  divider,
  showBackArrowAsClose,
  rightContent,
  onClose,
  hasDismiss,
  ...dialogProps
}: IHeaderDialogProps) => {
  function getDividerStatus() {
    return divider === undefined || divider === true;
  }

  const showBackArrow = !!showBackArrowAsClose && !!hasDismiss;

  const classes = classNames('header-dialog', className);

  return (
    <Dialog className={classes} {...dialogProps} onClose={onClose} hasDismiss={!showBackArrowAsClose && hasDismiss}>
      <HeaderDialogHeader
        title={title}
        subtitle={subtitle}
        showBackArrow={showBackArrow}
        rightContent={rightContent}
        onClose={onClose}
        justify={Justify.SPACE_BETWEEN}
        className={headerClass}
        size={headerSize}
      />
      {getDividerStatus() && <Divider />}
      {children}
    </Dialog>
  );
};

HeaderDialog.defaultProps = {
  headerSize: Size.LARGE,
  hasDismiss: true,
  showBackArrowAsClose: false,
};

export default HeaderDialog;
